<template>
<!-- 230420 교체 <iframe style="display:block; width:100vw; height: 100vh;" src="https://indd.adobe.com/view/1d6d62e2-299e-40e7-93e1-05bb8295bff8?allowFullscreen=false" frameborder="0" allowfullscreen=""></iframe>-->
  <iframe style="display:block; width:100vw; height: 100vh;" src="https://indd.adobe.com/view/880d9804-e76b-43ab-a5fe-143de71188bd?startpage=1&allowFullscreen=false" frameborder="0" allowfullscreen=""></iframe>
</template>

<script>
export default {
  name: 'Detail',
  components: {
  },
  data () {
    return {
      nationCode: ''
    }
  },
  created () {
    /* select box */

  },
  async mounted () {
    const _this = this
    _this.nationCode = this.$countryCode // 국가코드
  },
  methods: {
  }
}
</script>
