var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "LX Hausys Carbon Neutrality Roadmap" } },
        [
          _c("OverviewSectionItem", [
            _c("img", {
              staticStyle: { "max-width": "1440px" },
              attrs: {
                src: "images/aboutus/sustainability/net_zero/chart.svg",
              },
            }),
          ]),
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausys reflects the national 2050 Carbon Neutral Nation Declaration and Nationally Determined Contributions (NDC) target in its setup of the expected\nmid-to long-term GHG emissions by 2050 and targets to achieve Net Zero by 2050 in consideration of its GHG emissions characteristic and reduction plan.\nWe employ various means to meet our reduction plan goals and realize carbon neutrality, thereby fulfilling our corporate social responsibility.",
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "GHG Reduction Plan" } },
            [
              _c("div", { staticClass: "reduction-plan-area" }, [
                _c(
                  "div",
                  { staticClass: "card-area" },
                  [
                    _c("GhgReductionPlanCard", {
                      attrs: {
                        title: _vm.card2030.title,
                        desc: _vm.card2030.desc,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "arrow-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/sustainability/net_zero/arrow.svg",
                      alt: "img",
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  [
                    _c("GhgReductionPlanCard", {
                      attrs: {
                        title: _vm.card2050.title,
                        desc: _vm.card2050.desc,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "Carbon Neutrality Strategy" } },
            [
              _c(
                "div",
                { staticClass: "carbon-neutrality-strategy" },
                _vm._l(_vm.strategy, function (it, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "card-area" },
                    [
                      _c("CarbonNeutralityStrategyCard", {
                        attrs: {
                          imageSrc: it.imageSrc,
                          title: it.title,
                          desc: it.desc,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "LX Hausys カーボン ニュートラル ロードマップ" } },
        [
          _c("OverviewSectionItem", [
            _c("img", {
              staticStyle: { "max-width": "1440px" },
              attrs: {
                src: "images/aboutus/sustainability/net_zero/chart.svg",
              },
            }),
          ]),
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausysは、2050年までの中長期温室効果ガス排出量の想定設定において、\n        国家の2050年カーボンニュートラル国家宣言および国別決定貢献（NDC）目標を反映し、温室効果ガス排出特性と削減計画を考慮し、\n        2050年までにネットゼロを達成することを目標としています。当社は、削減計画目標を達成し、\n        カーボンニュートラルを実現するためにさまざまな手段を講じ、企業の社会的責任を果たしています。",
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "温室効果ガス削減計画" } },
            [
              _c("div", { staticClass: "reduction-plan-area" }, [
                _c(
                  "div",
                  { staticClass: "card-area" },
                  [
                    _c("GhgReductionPlanCard", {
                      attrs: {
                        title: _vm.card2030Jp.title,
                        desc: _vm.card2030Jp.desc,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "arrow-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/sustainability/net_zero/arrow.svg",
                      alt: "img",
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  [
                    _c("GhgReductionPlanCard", {
                      attrs: {
                        title: _vm.card2050Jp.title,
                        desc: _vm.card2050Jp.desc,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "カーボンニュートラル戦略" } },
            [
              _c(
                "div",
                { staticClass: "carbon-neutrality-strategy" },
                _vm._l(_vm.strategyJp, function (it, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "card-area" },
                    [
                      _c("CarbonNeutralityStrategyCard", {
                        attrs: {
                          imageSrc: it.imageSrc,
                          title: it.title,
                          desc: it.desc,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: { title: "Дорожная карта углеродной нейтральности LX Hausys" },
        },
        [
          _c("OverviewSectionItem", [
            _c("img", {
              staticStyle: { "max-width": "1440px" },
              attrs: {
                src: "images/aboutus/sustainability/net_zero/chart.svg",
              },
            }),
          ]),
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausys отражает национальную декларацию «Углеродная нейтральность» на 2050 год и цель «Национально определенные вклады» \n        (NDC) при определении ожидаемых средне- и долгосрочных выбросов ПГ к 2050 году и ставит перед собой задачу достичь «Net Zero» к 2050 году с \n        учетом характеристики и плана сокращения выбросов ПГ. Мы используем различные средства для достижения целей нашего плана по \n        сокращению выбросов и реализации углеродной нейтральности, тем самым выполняя свою корпоративную социальную ответственность.",
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            {
              attrs: { title: "План по сокращению выбросов парниковых газов" },
            },
            [
              _c("div", { staticClass: "reduction-plan-area" }, [
                _c(
                  "div",
                  { staticClass: "card-area" },
                  [
                    _c("GhgReductionPlanCard", {
                      attrs: {
                        title: _vm.card2030Ru.title,
                        desc: _vm.card2030Ru.desc,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "arrow-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/sustainability/net_zero/arrow.svg",
                      alt: "img",
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  [
                    _c("GhgReductionPlanCard", {
                      attrs: {
                        title: _vm.card2050Ru.title,
                        desc: _vm.card2050Ru.desc,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "Стратегия углеродной нейтральности" } },
            [
              _c(
                "div",
                { staticClass: "carbon-neutrality-strategy" },
                _vm._l(_vm.strategyRu, function (it, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "card-area" },
                    [
                      _c("CarbonNeutralityStrategyCard", {
                        attrs: {
                          imageSrc: it.imageSrc,
                          title: it.title,
                          desc: it.desc,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "LX Hausys碳中和路线图" } },
        [
          _c("OverviewSectionItem", [
            _c("img", {
              staticStyle: { "max-width": "1440px" },
              attrs: {
                src: "images/aboutus/sustainability/net_zero/chart.svg",
              },
            }),
          ]),
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausys在制定到2050年的预计中长期温室气体排放量时，反映了国家2050年碳中和的国家宣言和国家自主贡献（NDC）目标，\n        并考虑到其温室气体排放特性和减排计划，制定了到2050年实现净零排放的目标。我们采用各种手段来实现我们的减排计划目标，\n        实现碳中和，从而履行我们的企业社会责任。",
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", { attrs: { title: "温室气体减排计划" } }, [
            _c("div", { staticClass: "reduction-plan-area" }, [
              _c(
                "div",
                { staticClass: "card-area" },
                [
                  _c("GhgReductionPlanCard", {
                    attrs: {
                      title: _vm.card2030Cn.title,
                      desc: _vm.card2030Cn.desc,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "arrow-area" }, [
                _c("img", {
                  attrs: {
                    src: "images/aboutus/sustainability/net_zero/arrow.svg",
                    alt: "img",
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "card-area" },
                [
                  _c("GhgReductionPlanCard", {
                    attrs: {
                      title: _vm.card2050Cn.title,
                      desc: _vm.card2050Cn.desc,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("OverviewSectionItem", { attrs: { title: "碳中和战略" } }, [
            _c(
              "div",
              { staticClass: "carbon-neutrality-strategy" },
              _vm._l(_vm.strategyCn, function (it, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "card-area" },
                  [
                    _c("CarbonNeutralityStrategyCard", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        title: it.title,
                        desc: it.desc,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }