<template>
  <div id="wrap">
    <header class="scr-hder on micro">
      <div class="clear">
        <div class="fl-l">
          <div class="logo">
            <a href="homecenter/viatera" class="go_home_for_pc">LX Hausys</a>
            <a href="homecenter/viatera" class="go_home_for_mobile" style="display: none">LX Hausys</a>
          </div>
        </div>
        <div class="fl-c">
        </div>
        <div class="fl-r">
        </div>
      </div>
    </header>

    <!-- content -->
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">{{ $t('home')}}</li>
          <li>{{ $t('search_results')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container  -->
      <div class="container search-result">
        <div class="sch-res-wrap" v-if="searchResult">
          <div class="hd-close">
            <button type="button" class="btn-hd-close" @click="goBack()"><span class="blind">Close Button</span></button>
          </div>
          <div class="w-con-02">
            <div class="sch-top">
              <div class="sch-frm">
                <label class="blind" for="inpHdSch">Input Search Keyword</label>
                <input type="text"
                       class="ipt-hd-sch wrd-on"
                       id="inpHdSch"
                       :placeholder="$t('search.enter_search_word')"
                       v-model="searchWord"
                       v-on:keyup.enter="doSearch"/>
                <button type="button" class="btn-hd-sch" v-on:click="doSearch">
                  <span class="blind">Search Button</span>
                </button>
              </div>
            </div>
          </div>
          <div class="sch-btm inner" v-if="!(showSearchResult && searchHomeWords.length > 0)">
            <div class="w-con">
              <div class="sch-recent" v-if="searchHomeWords.length > 0">
                <p class="sch-rcnt-tit">{{ $t('search.recent_search') }}</p>
                <ul class="sch-rcnt-list">
                  <li v-for="sw in searchHomeWords">
                    <span class="sch-rcnt-keyw" v-on:click="searchWord=sw;doSearch()">{{sw}}</span>
                    <button type="button" class="btn-rcnt-del" v-on:click="deleteRecent(sw)">delete keyword button</button>
                  </li>
                </ul>
              </div>
              <div class="sch-recent sch-popular" v-if="popularWords.length > 0">
                <p class="sch-rcnt-tit">{{ $t('search.popular_search') }}</p>
                <ul class="sch-rcnt-list">
                  <li v-for="sw in popularWords" v-on:click="searchWord=sw;doSearch()">
                    <span class="sch-rcnt-keyw">{{sw}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="w-con-02" v-if="seeContent">
            <h1 class="tit mb-88 tac inner">
              <span>{{totalCount()}}</span> {{ $t('result_for')}} <strong>“{{searchWord2}}”</strong>
            </h1>
          </div>

          <!-- tab-con  -->
          <div class="inner search-inner" v-if="seeContent">
            <router-view
              v-if="searchResult"
              v-bind:searchResult="searchResult"
              v-bind:searchWord="searchWord"
            />
            <!-- 검색 결과가 없을때 시작 -->
            <!--            <div class="no-found-wrap bd-top" v-else>-->
            <!--              <div class="tac">-->
            <!--                <i class="icon-no-result"><img :src="$baseUrl + '/images/icon-no-result-found.png'" alt="no result"></i>-->
            <!--                <div class="msg">-->
            <!--                  <ul>-->
            <!--                    <li class="ctt ctt-t1 result-tit">No Result Found</li>-->
            <!--                    <li class="ctt ctt-t4">Try again using more general search keywords</li>-->
            <!--                  </ul>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!-- 검색 결과가 없을때 끝  -->

          </div>

          <div class="sch-btm" v-else>
            <div class="w-con">
              <div class="sch-no">
                <p class="sch-no-info">
                  <span class="no-re-tit">{{ $t('search.no_search_result') }} "<em class="sch-keyw color-red">{{searchWord2}}</em>" </span>
                  <span class="no-re-desc">{{ $t('search.no_search_guide') }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- //tab-con  -->

        </div>
      </div>
      <!-- //container  -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import SearchService from '@/server/search.service'
import GnbComponent from '@/components/Layout/GNB'
import { mapState } from 'vuex'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'appHDP',
  components: {
    QuickMenuComponent,
    GnbComponent,
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      showSearchResult: false,
      tab: this.$route.name,
      searchWord: '',
      searchWord2: '',
      searchResult: null,
      popularWords: [],
      seeContent: true
    }
  },
  computed: {
    ...mapState({
      searchHomeWords: state => state.search.searchHomeWords
    })
  },
  watch: {
    '$route.params.s': {
      handler: function (s) {
        this.searchWord = this.$route.params.s
        this.doSearch()
      },
      deep: true,
      immediate: true
    },
    '$route.name': {
      handler: function (s) {
        this.tab = this.$route.name
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    const _this = this
    // let observer = new MutationObserver(function(mutations) {
    //   mutations.forEach(function(mutationRecord) {
    //     if($(mutationRecord.target).css('display') === 'none')
    //     {
    //       _this.showSearchResult = false;
    //     }
    //   });
    // });
    //
    // let target = $(".hd-sec-cont.sch-area")[0];
    // observer.observe(target, { attributes : true, attributeFilter : ['style'] });
    //
    // $( "#inpHdSch" ).autocomplete({
    //   source: async function (request, response) {
    //     let res = await SearchService.suggestion(request.term);
    //     response(res.data);
    //   },
    //   minLength: 2
    // });

    const res = await SearchService.popular()
    this.popularWords = res.data
  },
  methods: {
    goBack () {
      history.back()
    },
    deleteRecent (searchWord) {
      this.$store.dispatch('search/deleteSearchHomeWord', { searchWord: searchWord, vm: this })
    },
    productTotalCount () {
      return (
        this.searchResult.himacsHome.totalCount +
        this.searchResult.viateraHome.totalCount)
    },
    totalCount () {
      let totalCount = 0
      for (const search in this.searchResult) {
        totalCount += this.searchResult[search].totalCount
      }

      return totalCount
    },
    async doSearch () {
      this.$route.params.s = this.searchWord
      if (this.searchWord === '') {
        alert('Please enter some search keyword')
        return false
      }
      const res = await SearchService.homedepot({
        s: this.searchWord,
        r: this.$countryCode.substring(1),
        pageIndex: 1,
        pageSize: 4
      })
      this.searchResult = res.data

      if (this.searchWord !== '') {
        this.$store.dispatch('search/addSearchHomeWord', { searchWord: this.searchWord, vm: this })
      }
      if (this.totalCount() === 0) {
        this.seeContent = false
      } else {
        this.seeContent = true
      }
      this.searchWord2 = this.searchWord
    }
  }
}
</script>
