var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content au-view glSite" }, [
        _vm._m(0),
        _c("div", { staticClass: "container factory_list" }, [
          _c("div", { staticClass: "inner_padding showroom" }, [
            _c("h1", [_vm._v("销售网络")]),
            _c("div", { staticClass: "showroom-wrap" }, [
              _c("div", { staticClass: "showroom-tabs" }, [
                _c(
                  "div",
                  {
                    staticClass: "showroom-tab on",
                    on: { click: _vm.moveTab },
                  },
                  [_vm._m(1), _vm._m(2)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(3), _vm._m(4)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(5), _vm._m(6)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(7), _vm._m(8)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(9), _vm._m(10)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(11), _vm._m(12)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(13), _vm._m(14)]
                ),
                _c(
                  "div",
                  { staticClass: "showroom-tab", on: { click: _vm.moveTab } },
                  [_vm._m(15), _vm._m(16)]
                ),
              ]),
              _c("div", { staticClass: "showroom-contents" }, [
                _c("div", { staticClass: "showroom-content" }, [
                  _vm._m(17),
                  _c("div", { staticClass: "showroom-carousel" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "VueSlickCarousel",
                          _vm._b(
                            { attrs: { "swipe-to-slide": true } },
                            "VueSlickCarousel",
                            _vm.showroomSetting,
                            false
                          ),
                          [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png",
                                  alt: "factory image",
                                },
                              }),
                            ]),
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png",
                                  alt: "factory image",
                                },
                              }),
                            ]),
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png",
                                  alt: "factory image",
                                },
                              }),
                            ]),
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png",
                                  alt: "factory image",
                                },
                              }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._m(18),
                _vm._m(19),
                _vm._m(20),
                _vm._m(21),
                _vm._m(22),
                _vm._m(23),
                _vm._m(24),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home ")]),
        _c("li", [_vm._v("关于我们")]),
        _c("li", [_vm._v("LX Hausys 中国")]),
        _c("li", [_vm._v("销售网络")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("无锡 (工厂)")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("无锡 (总部)")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("北京")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("上海")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("广州")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("青岛")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("武汉")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("西安")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-info" }, [
      _c("h4", [_vm._v("无锡 (工厂)")]),
      _c("div", { staticClass: "showroom-info-content" }, [
        _c("div", { staticClass: "showroom-more card1" }, [
          _c("p", [_vm._v("无锡市新吴区鸿山街道鸿祥路2号")]),
          _c("a", { attrs: { href: "javascript:void(0)" } }, [
            _c("span", [_vm._v("Directions")]),
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                alt: "arrow",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "showroom-more card3" }, [
          _c("div", [
            _c("p", [_vm._v("电话 : 86-0510-66686888")]),
            _c("p", [_vm._v("传真 : 86-0510-66686880")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("无锡 (总部)")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [_vm._v("无锡市新吴区和风路26号新发汇融广场C栋13层")]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [
              _c("p", [_vm._v("电话 : 86-0510-82336988")]),
              _c("p", [_vm._v("传真 : 86-0510-82356588")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("北京")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [
              _vm._v("北京市通州区新华北路绿地中央广场一期写字楼903-904室"),
            ]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [_c("p", [_vm._v("电话 : 86-010-5990 4422")])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("上海")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [
              _vm._v("上海市闵行区上坤路77弄16号融信上坤中心T3栋702-703室"),
            ]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [_c("p", [_vm._v("电话 : 86-021-5241-6600")])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("广州")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [_vm._v("广东省广州市黄浦区黄埔大道东路856号(A-2)308室")]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [_c("p", [_vm._v("电话 : 86-020-3877 1620")])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("青岛")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [
              _vm._v("青岛市城阳区黑龙江中路187号盛文大厦一栋C单元602室"),
            ]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [_c("p", [_vm._v("电话 : 86-0532-5557 6408")])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("武汉")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [_vm._v("武汉市江汉区常青路恒大中心1409室")]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [_c("p", [_vm._v("电话 : 86-027-6885 0585")])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "showroom-content" }, [
      _c("div", { staticClass: "showroom-info" }, [
        _c("h4", [_vm._v("西安")]),
        _c("div", { staticClass: "showroom-info-content" }, [
          _c("div", { staticClass: "showroom-more card1" }, [
            _c("p", [
              _vm._v("西安市雁塔区高新区锦业一路56号研祥城市广场B座3026室"),
            ]),
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _c("span", [_vm._v("Directions")]),
              _c("img", {
                attrs: {
                  src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                  alt: "arrow",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "showroom-more card3" }, [
            _c("div", [_c("p", [_vm._v("电话 : 86-029-8187 0551")])]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }