var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-text-area" }, [
    _c("div", { staticClass: "title-area" }, [
      _c("pre", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c("div", [
      _c("pre", { staticClass: "desc", style: _vm.descStyle }, [
        _vm._v(_vm._s(_vm.desc)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }