<template>
  <div class="short-link type_sub">
    <a class="item where" :href="getWheresHref($countryCode)">
      <div class="anchor">
        <span class="text">where to buy</span>
        <span class="icon_arrow"></span>
      </div>
    </a>
    <a class="item contact" :href="'support/contactus'">
      <div class="anchor">
        <span class="text">contact us</span>
        <span class="icon_arrow"></span>
      </div>
    </a>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash.js'
import usService from '@/server/us.service'
import etcService from '@/server/etc.service'
import mailchimpService from '@/server/mailchimp.service'
import { mapState, mapGetters, mapActions } from 'vuex'

let popupId = null;
export default {
  name: 'ShortComponent',
  props: {
    isMicrosite: Boolean
  },
  components: {
  },
  data () {
    return {
      is_show: false,
    }
  },
  async created () {
  },
  async mounted () {
  },
  computed: {
  },
  methods: {
    getWheresHref (country) {
      const lowercaseCountry = country.toLowerCase().replace(/^_/, '')

      if (country === '_US') {
        return `/${lowercaseCountry}/support/where-to-buy/country/us`
      } else {
        return `/${lowercaseCountry}/support/where-to-buy/global`
      }

    },
  },
}




</script>

