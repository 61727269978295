<template>
  <div class="product-strong-point-group-area horizontal">
    <ProductStrongPoint
      v-for="(it, idx) in info"
      :key="idx"
      :iconSrc="it.iconSrc"
      :title="it.title"
      :description="it.description"
      :type="iconType"
    />
  </div>
</template>

<script>
import ProductStrongPoint from './ProductStrongPoint'

export default {
  components: {
    ProductStrongPoint
  },

  props: {
    info: {
      type: Array,
      required: true
    },
    iconType: {
      type: String,
      required: false,
      default: 'vertical'
    }
  }
}
</script>

<style scoped>
.product-strong-point-group-area.horizontal {
  display: block;
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}
@media (min-width: 769px) {
  .product-strong-point-group-area.horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

</style>
