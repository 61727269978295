var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProductLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("ProductTopImage", {
              attrs: {
                title: "Home Appliance Surface Film",
                description:
                  "Film laminated steel surface finishing material of home appliances, UBR and Elevator.",
                imageSrc: "images/industrialFilm/VinylCoatedMaterial/top.png",
              },
            }),
            _c(
              "ProductContent",
              [
                [
                  _c("ProductIntro", {
                    staticClass: "mt-100",
                    attrs: {
                      imageSrc:
                        "images/industrialFilm/VinylCoatedMaterial/intro.png",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                " LX Hausys Home Appliance Surface Film is a high quality surface material with a variety of colors and patterns for refrigerators, washing machines and bathrooms. "
                              ),
                            ]),
                            _c("br"),
                            _c("br"),
                            _c("span", [
                              _vm._v(
                                " Our superb products are already chosen by global appliance companies, because of its excellent anti-pollution, anti-scratch and anti-corrosion characteristics. "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("StrongPointGroup", { staticClass: "mt-100" }),
                  _c("div", { staticClass: "mt-100" }, [
                    _c("img", {
                      attrs: {
                        src: "images/industrialFilm/VinylCoatedMaterial/example-center.jpg",
                        alt: "example",
                      },
                    }),
                  ]),
                  _c("ConstructionArea", { staticClass: "mt-100" }),
                ],
              ],
              2
            ),
            _c("ApplicationArea", { staticClass: "mt-100" }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }