var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    staticStyle: { display: "block", width: "100vw", height: "100vh" },
    attrs: {
      src: "https://indd.adobe.com/view/880d9804-e76b-43ab-a5fe-143de71188bd?startpage=1&allowFullscreen=false",
      frameborder: "0",
      allowfullscreen: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }