<template>
  <iframe style="display:block; width:100vw; height: 100vh;" src="https://indd.adobe.com/embed/407cc825-7bdd-4db6-b6b2-1ba9d79ba094?startpage=1&allowFullscreen=false" frameborder="0" allowfullscreen=""></iframe>
</template>
<script>
export default ({
  name: 'prime-en',
  data () {
    return {}
  },
  mounted () {
  },
  methods: {}
})
</script>
