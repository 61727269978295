<template>
  <div>
    <ProductCarousel :autoplay="true" :speed="1000">
      <FilmItem
        v-for="(it, idx) in itemInfo"
        :key="idx"
        :title="it.title"
        :description="it.description"
        :layerImageSrc="it.layerImageSrc"
        :exampleImageSrc="it.exampleImageSrc"
      />
    </ProductCarousel>
  </div>
</template>

<script>
import ProductCarousel from '@/components/product/carousel'
import FilmItem from './FilmItem'

export default {
  components: {
    ProductCarousel,
    FilmItem
  },

  data: () => ({
    itemInfo: [
      {
        title: 'Eco PET',
        description: 'Printed Hairline on metal pearl with PET base',
        layerImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/eco-pet-layer.png',
        exampleImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/eco-pet-example.png'
      },
      {
        title: 'Metalon',
        description: 'Metalizing gives real metal look on the film.',
        layerImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/metalon-layer.png',
        exampleImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/metalon-example.png'
      },
      {
        title: 'SUS Film',
        description: 'Embossed Hairline technology allows customized pattern including horizontal hairline which enables the exact same look of STS.',
        layerImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/sus-layer.png',
        exampleImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/sus-example.png'
      }
    ]
  })
}
</script>

<style scoped>

</style>
