var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SeatCorverLayout", {
    staticClass: "mt-50",
    attrs: { imageSrc: "images/automotive/skin/SOFINO.png" },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("SOFINO® Green")]),
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [
                _vm._v(
                  "Comply with Class 1 of the OEKO-TEX® Standard 100, which attests that the material is safe for babies and small children¹⁾"
                ),
              ]),
            ]),
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("SOFINO® Bio")]),
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [
                _vm._v("Developing bio leather from bio based fillers"),
              ]),
              _c("br"),
              _c("span", [_vm._v("(e.g., Kenaf, Eucalyptus, and so on)")]),
            ]),
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("SOFINO® Eco")]),
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [
                _vm._v("• RCS (Recycled Claim Standard) certificated"),
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "• Recycled content 35 wt% (Recycled Post-Consumer Polyester)"
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "annotation",
        fn: function () {
          return [
            _c("span", [
              _vm._v(
                "1) Limit values of OEKO-TEX® Standard 100 Class 1 (Products for babies)"
              ),
            ]),
            _c("br"),
            _c("span", [
              _vm._v(
                "• Solvent residues of NMP (1-Methyl-2-pyrrolidone), DMF (Dimethylformamide) ≤ 0.05 wt%"
              ),
            ]),
            _c("br"),
            _c("span", [_vm._v("• Sum of phthalates ≤ 0.05 wt%")]),
            _c("br"),
            _c("span", { staticClass: "spacing" }, [
              _vm._v(
                "(DBP, BBP, DEP, DMP, DEHP, DEMP, DIHP, DHNUP, DHxP, DIDP, DPrP, DHP, DNOP, DNP, DPP)"
              ),
            ]),
            _c("br"),
            _c("span", [
              _vm._v(
                "• Do not contain any of the banned flame retardant substances"
              ),
            ]),
            _c("br"),
            _c("span", { staticClass: "spacing" }, [
              _vm._v(
                "(e.g., Polybrominated Diphenyl Ethers, Antimony Trioxide)"
              ),
            ]),
            _c("br"),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }