var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content au-view newsEventArea" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("newsDetail.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("newsDetail.text.about_us")))]),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.gotolink(
                      `/${_vm.countryCodeSub.toLowerCase()}/aboutus/news-event/list`
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("newsDetail.text.news")) +
                    " & " +
                    _vm._s(_vm.$t("newsDetail.text.event"))
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container au-box bbs-box au-box02 news_detail" },
          [
            _c("div", { staticClass: "bbs-dt-box" }, [
              _c("div", { staticClass: "tit-box" }, [
                _c("div", { staticClass: "txt-box w-con" }, [
                  _c("ul", [
                    _c("li", { staticClass: "t-01 ctt ctt-t4" }, [
                      _vm._v(_vm._s(_vm.topic)),
                    ]),
                    _c("li", { staticClass: "t-02" }, [
                      _c("h2", { staticClass: "title tt-2" }, [
                        _vm._v(_vm._s(_vm.headerTitle)),
                      ]),
                    ]),
                    _c("li", { staticClass: "t-04 ctt ctt-t4" }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.time, "MM.DD.YYYY"))),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "nd-box prod-dt-view" }, [
                _c("div", { staticClass: "w-con dt-box t-type-03" }, [
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.topicText) } }),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
      _c("SampleOrderPopComponent", {
        attrs: { "content-id": _vm.selConId },
        on: { closePopup: _vm.closeSampleOrder },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }