<template>
  <div class="card-box">
    <img :src=iconSrc>
    <div class="title">
      <span class="first-title">{{ firstTitle }}<span>{{ title }}</span></span>
    </div>

    <div class="card-box-content">
      <span>{{ content }}</span>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    firstTitle: {
      type: String
    },
    title: {
      type: String
    },
    content: {
      type: String
    },
    iconSrc: {
      type: String
    }
  }
}
</script>

<style scoped>

.card-box {
  display: flex;
  padding: 70px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  border-radius: 30px;
  border: 1px solid #DCDCDC;
}

.card-box img {
  width: 100px;
  height: 100px;
}

.card-box .title span {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.card-box .title .first-title {
  color: #AA2D29;
}

.card-box .card-box-content span {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 87.5% */
}

</style>
