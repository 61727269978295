var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("COLOR", {
        attrs: { colorList: _vm.dictMap["hue"] },
        model: {
          value: _vm.hue,
          callback: function ($$v) {
            _vm.hue = $$v
          },
          expression: "hue",
        },
      }),
      _c("COLLECTION", {
        attrs: { collectionList: _vm.collectionList, category: _vm.category },
        model: {
          value: _vm.collection,
          callback: function ($$v) {
            _vm.collection = $$v
          },
          expression: "collection",
        },
      }),
      _c("PRD_PATTERN", {
        attrs: { patternList: _vm.dictMap["pattern"] },
        model: {
          value: _vm.pattern,
          callback: function ($$v) {
            _vm.pattern = $$v
          },
          expression: "pattern",
        },
      }),
      _c("IS_RECYCLED", {
        model: {
          value: _vm.isRecycled,
          callback: function ($$v) {
            _vm.isRecycled = $$v
          },
          expression: "isRecycled",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }