<template>
  <OverviewSection v-if="nationCode == '_US' || nationCode == '_GL'">
    <template #header>
      <SustainabilityText :text="topText"/>
    </template>

    <OverviewSectionItem>
      <div class="life-cycle-assessment-content">
        <Lca
          :title="lcaData.title"
          :textCard="lcaData.textCard"
        />
        <img class="arrow-image" src="images/aboutus/sustainability/sustainable_products/arrow-image.png">
        <ScopesOfImpact
          :title="scopesOfImpactData.title"
          :text="scopesOfImpactData.text"
          :description="scopesOfImpactData.description"
        />
      </div>
    </OverviewSectionItem>

  </OverviewSection>
<!-- JP -->
  <OverviewSection v-else-if="nationCode == '_JP'">
    <template #header>
      <SustainabilityText :text="topTextJp"/>
    </template>

    <OverviewSectionItem>
      <div class="life-cycle-assessment-content">
        <Lca
          :title="lcaDataJp.title"
          :textCard="lcaDataJp.textCard"
        />
        <img class="arrow-image" src="images/aboutus/sustainability/sustainable_products/arrow-image.png">
        <ScopesOfImpact
          :title="scopesOfImpactDataJp.title"
          :text="scopesOfImpactDataJp.text"
          :description="scopesOfImpactDataJp.description"
        />
      </div>
    </OverviewSectionItem>

  </OverviewSection>
<!-- RU -->
  <OverviewSection v-else-if="nationCode == '_RU'">
    <template #header>
      <SustainabilityText :text="topTextRu"/>
    </template>

    <OverviewSectionItem>
      <div class="life-cycle-assessment-content">
        <Lca
          :title="lcaDataRu.title"
          :textCard="lcaDataRu.textCard"
        />
        <img class="arrow-image" src="images/aboutus/sustainability/sustainable_products/arrow-image.png">
        <ScopesOfImpact
          :title="scopesOfImpactDataRu.title"
          :text="scopesOfImpactDataRu.text"
          :description="scopesOfImpactDataRu.description"
        />
      </div>
    </OverviewSectionItem>

  </OverviewSection>
<!-- CN -->
  <OverviewSection v-else-if="nationCode == '_CN'">
    <template #header>
      <SustainabilityText :text="topTextCn"/>
    </template>

    <OverviewSectionItem>
      <div class="life-cycle-assessment-content">
        <Lca
          :title="lcaDataCn.title"
          :textCard="lcaDataCn.textCard"
        />
        <img class="arrow-image" src="images/aboutus/sustainability/sustainable_products/arrow-image.png">
        <ScopesOfImpact
          :title="scopesOfImpactDataCn.title"
          :text="scopesOfImpactDataCn.text"
          :description="scopesOfImpactDataCn.description"
        />
      </div>
    </OverviewSectionItem>

  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../components/SustainabilityText.vue'
import Lca from './Lca.vue'
import ScopesOfImpact from './ScopesOfImpact.vue'

export default {
  components: {
    OverviewSection,
    SustainabilityText,
    OverviewSectionItem,
    Lca,
    ScopesOfImpact
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      topText: 'At LX Hausys, we are committed to securing product competitiveness to achieve sustainable development.\n' +
            'To facilitate this, we operate a standard process for product development that is suitable for each trait of\n' +
            'products by enhancing product competitiveness. In addition, we are also operating a product development process that reflects customer\n' +
            'needs and pain points in order to produce products that meet customer needs and desires.\n' +
            'Additionally, we prioritize customer needs and pain points in our product development process to deliver products that meet their\n' +
            'requirements. LX Hausys will continue to thoroughly analyze rapidly changing consumer demands and market environments to produce\n' +
            'sustainable products aligned with our ESG philosophy.',
      topTextJp:'LX Hausysでは、持続可能な開発を実現するために、製品競争力の確保に取り組んでいます。\n' +
                'これを促進するために、製品競争力を高め、製品の各特性に適した製品開発の標準プロセスを運用しています\n' +
                'また、顧客のニーズと要望を満たす製品を生み出すために、\n' +
                '顧客のニーズと問題点を反映した製品開発プロセスも運用しています。\n' +
                'さらに、製品開発プロセスにおいて顧客のニーズと問題点を優先し、顧客の要件を満たす製品をお届けします。\n' +
                'LX Hausysは、急速に変化する消費者の需要と市場環境を徹底的に分析し、\n' +
                'ESG哲学に沿った持続可能な製品を生み出し続けます。',
      topTextRu:'Компания LX Hausys стремится обеспечить конкурентоспособность продукции для достижения устойчивого развития.\n' +
                'Для этого мы используем стандартный процесс разработки продукции, который подходит для каждого вида продукции, \n' +
                'конкурентоспособность. Кроме того, мы используем процесс разработки продуктов, который отражает потребности и болевые точки\n' +
                'клиентов, чтобы производить продукты, отвечающие их потребностям и желаниям.\n' +
                'Кроме того, в процессе разработки продукции мы определяем приоритеты потребностей и болевых точек клиентов, чтобы выпускать \n' +
                'продукцию, отвечающую их требованиям. LX Hausys будет продолжать тщательно анализировать быстро меняющиеся запросы\n' +
                'потребителей и рыночную среду, чтобы производить продукты в соответствии с нашей философией ESG.',
      topTextCn:'在LX Hausys，我们致力于确保产品竞争力，以实现可持续发展。\n' +
                '为了促进这一点，我们通过提高产品竞争力，实施了一个适合产品每种特性的标准产品开发流程\n' +
                '此外，我们还运营着一个反映客户需求和痛点的产品开发流程，以生产出满足客户需求和愿望的产品。\n' +
                '此外，我们在产品开发过程中优先考虑客户的需求和痛点，以交付满足他们要求的产品。\n' +
                'LX Hausys将继续深入分析快速变化的消费者需求和市场环境，生产符合我们ESG理念的可持续产品。',
                
      lcaData: {
        title: 'Life Cycle Assessment',
        textCard: [
          {
            number: '01',
            text: 'Product Development Strategy'
          },
          {
            number: '02',
            text: 'Product R&D'
          },
          {
            number: '03',
            text: 'Development products'
          },
          {
            number: '04',
            text: 'Production'
          },
          {
            number: '05',
            text: 'Distribution&Sales'
          }
        ]
      },
      lcaDataJp: {
        title: 'ライフサイクルアセスメント',
        textCard: [
          {
            number: '01',
            text: '製品開発戦略'
          },
          {
            number: '02',
            text: '製品研究開発'
          },
          {
            number: '03',
            text: '開発製品'
          },
          {
            number: '04',
            text: '生産'
          },
          {
            number: '05',
            text: '流通・販売'
          }
        ]
      },
      lcaDataRu: {
        title: 'Оценка жизненного цикла',
        textCard: [
          {
            number: '01',
            text: 'Стратегия развития продукта'
          },
          {
            number: '02',
            text: 'Исследования и разработки продукции'
          },
          {
            number: '03',
            text: 'Продукты разработки'
          },
          {
            number: '04',
            text: 'Производство'
          },
          {
            number: '05',
            text: 'Распространение и продажа'
          }
        ]
      },
      lcaDataCn: {
        title: '生命周期评估',
        textCard: [
          {
            number: '01',
            text: '产品开发战略'
          },
          {
            number: '02',
            text: '产品研发'
          },
          {
            number: '03',
            text: '开发产品'
          },
          {
            number: '04',
            text: '生产'
          },
          {
            number: '05',
            text: '分销与销售'
          }
        ]
      },
      scopesOfImpactData: {
        title: '7 Scopes of impact by EPD*',
        text: 'Carbon Footprint\nWater Footprint\nImpact of Ozone Layer\n' +
              'Acid Rain\nEutrophication\nPhotochemical Smog\nResource Footprint',
        description: '* Environmental Product Declaration'
      },
      scopesOfImpactDataJp: {
        title: '7 EPDによる影響範囲*',
        text: 'カーボンフットプリント\nウォーターフットプリント\nオゾン層の影響\n' +
              '酸性雨\n富栄養化\n光化学スモッグ\nリソースフットプリント',
        description: '* 環境製品宣言'
      },
      scopesOfImpactDataRu: {
        title: '7 областей воздействия по EPD*',
        text: 'Углеродный след\nВодный след\nВлияние на озоновый слой\n' +
              'Кислотный дождь\nЭвтрофикация\nФотохимический смог\nРесурсный след',
        description: '* Экологическая декларация продукции'
      },
      scopesOfImpactDataCn: {
        title: '7环保署的影响范围*',
        text: '碳足迹\n水足迹\n臭氧层的影响\n' +
              '酸雨\n富营养化\n光化学烟雾\n资源足迹',
        description: '* 环保产品声明'
      },
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.life-cycle-assessment-content {
  display: flex;
  padding: 60px 0px 22px 0px;
  justify-content: center;
  border-radius: 30px;
  background: #F4F4F4;
}

.life-cycle-assessment-content .arrow-image {
  object-fit: none;
  width: fit-content;
}

@media (max-width: 768px) {
  .life-cycle-assessment-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .life-cycle-assessment-content .arrow-image {
    transform: rotate(90deg);
    max-height: 250px;
    object-fit: contain;
  }
}
</style>
