<template>
  <div class="interior-part-area">
    <ProductSectionHeader
      title="Interior Part"
      description="Creating sophisticated designs with natural patterned real wood"
    />

    <div class="content-area mt-50">
      <div class="main-image-area">
        <img src="images/automotive/component/interior-part-img-1.png">
      </div>

      <div class="detail-area">
        <div>
          <p>SRP (Superfine Real Printing)</p>
          <ul>
            <li>Enables precision 3D object printing</li>
            <li>Delivers superior realism and textured effects</li>
            <li>Applicable to a wide range of automotive interiors</li>
            <li>Application : Crash pads, Garnish, Consoles, Steering wheels, etc.</li>
          </ul>
        </div>
        <div>
          <p>Real Materials</p>
          <ul>
            <li>Premium aesthetic from natural wood veneers and carbon fiber</li>
            <li>Exquisite crash pads, garnishes, consoles, and armrests for luxury
              interior parts</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="interior-design">
      <div>
        <h3>SRP Design</h3>
        <div class="interior-column">
          <div>
            <p>Wood</p>
            <img src="images/automotive/interior/srp_wood01.png" alt="SRP-wood-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/srp_wood02.png" alt="SRP-wood-02">
          </div>
          <div>
            <p>Fabric</p>
            <img src="images/automotive/interior/srp_fabric01.png" alt="SRP-fabric-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/srp_fabric02.png" alt="SRP-fabric-02">
          </div>
        </div>
        <div class="interior-column">
          <div>
            <p>Geometric, Technical</p>
            <img src="images/automotive/interior/srp_technical01.png" alt="SRP-geometric-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/srp_technical02.png" alt="SRP-geometric-02">
          </div>
          <div>
            <p>Hair line</p>
            <img src="images/automotive/interior/srp_hair01.png" alt="SRP-hair-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/srp_hair02.png" alt="SRP-hair-02">
          </div>
        </div>
      </div>
      <div>
        <h3>Real Materials</h3>
        <div class="interior-column">
          <div>
            <p>Wood</p>
            <img src="images/automotive/interior/real_wood01.png" alt="Real-wood-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/real_wood02.png" alt="Real-wood-02">
          </div>
          <div>
            <p>Aluminum</p>
            <img src="images/automotive/interior/real_aluminum01.png" alt="Real-aluminum-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/real_aluminum02.png" alt="Real-aluminum-02">
          </div>
        </div>
        <div class="interior-column">
          <div>
            <p>Textile</p>
            <img src="images/automotive/interior/real_rextile.png" alt="Real-textile">
          </div>
          <div>
            <p>Stone</p>
            <img src="images/automotive/interior/real_stone.png" alt="Real-stone">
          </div>
          <div>
            <p>Carbon</p>
            <img src="images/automotive/interior/real_carbon01.png" alt="Real-carbon-01">
          </div>
          <div>
            <p></p>
            <img src="images/automotive/interior/real_carbon02.png" alt="Real-carbon-02">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'

export default {
  components: {
    ProductSectionHeader
  }
}
</script>

<style scoped>
.interior-part-area {
  display: flex;
  flex-direction: column;
}

.interior-part-area .content-area {
  display: block;
  /* flex-direction: row; */
}
@media (min-width: 769px) {
  .interior-part-area .content-area {
    display: flex;
    flex-direction: row;
  }
}

.interior-part-area .content-area .main-image-area {
  flex-basis: 572px;
  margin-right: 0px;
}
@media (min-width: 769px) {
  .interior-part-area .content-area .main-image-area {
    flex-basis: 572px;
    margin-right: 50px;
  }
}

.interior-part-area .content-area .main-image-area img {
  width: 100%;
  object-fit: cover;
}

.interior-part-area .content-area .detail-area {
  display: flex;
  flex-direction: column;
}

.interior-part-area .content-area .detail-area .image-area {
  width: 186px;
  height: 183px;
}

.interior-part-area .content-area .detail-area .image-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.interior-part-area .content-area .detail-area .text-area {
  max-width: 578px;
}

.interior-part-area .content-area .detail-area .text-area p {
  color: #2C2C2D;
  font-family: 'NotoSans' , sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-indent: -17px;
  padding-left: 17px;
}
.interior-part-area .content-area .detail-area .text-area p:before {
  content: "•";
  margin-right: 5px;
}

</style>
