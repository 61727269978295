<template>
  <div class="goal-card">
    <pre class="number">{{ number }}</pre>
    <pre class="text">{{ text }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
.goal-card {
  border-radius: 30px 0px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  display: inline-flex;
  align-items: flex-start;
  gap: 100px;
  padding-left: 47px;
  height: 131px;
  width: 100%;
  align-items: center;
}

@media (max-width: 768px) {
  .goal-card {
    height: 250px;
    padding-left: 0px;
    flex-direction: column;
    gap: 70px;
  }
}

.goal-card .number {
  color: #EFEFEF;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px; /* 36% */
  letter-spacing: -6px;
}

@media (max-width: 768px) {
  .goal-card .number {
    line-height: 100px;
  }
}

.goal-card .text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .goal-card .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
    width: 90%;
    margin-bottom: 3%;
  }
}
</style>
