var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overview-card-icon-area-root" }, [
    _c("div", { staticClass: "overview-card-icon-area" }, [
      _c("span", { staticClass: "text-area" }, [
        _c("span", { staticClass: "title-area" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("span", { staticClass: "main-area" }, [
          _c("span", { staticClass: "main-text-area" }, [
            _vm._v(" " + _vm._s(_vm.mainTxt) + " "),
          ]),
          _c("span", { staticClass: "add-text-area" }, [
            _vm._v(" " + _vm._s(_vm.addTxt) + " "),
          ]),
        ]),
      ]),
      _c("span", { staticClass: "icon-area" }, [
        _c("img", { attrs: { src: _vm.iconSrc } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }