var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content pros-view" }, [
        _vm._m(0),
        _c("div", { staticClass: "container for-box" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
              _c(
                "ul",
                { staticClass: "btns02" },
                _vm._l(_vm.tabCateList, function (tab, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "btn-tab",
                      attrs: { id: "btn-tab0" + (index + 1) },
                    },
                    [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.fnTab(tab)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(tab.name) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "tab-con tab01 on" }, [
                _c("div", { staticClass: "guide-box" }, [
                  _c(
                    "div",
                    { staticClass: "g-list col-02" },
                    _vm._l(_vm.totGalList, function (gal) {
                      return _c("div", { staticClass: "bd-gray" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              gal.attribute.document_pickContent.content.title
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "col-02" }, [
                          _c("div", { staticClass: "txt-box" }, [
                            _c("div", { staticClass: "t-type-01" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    gal.attribute.document_pickContent.attribute
                                      .doctypeText
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(
                                      gal.attribute.document_pickContent
                                        .attribute.doc_file
                                    )
                                  },
                                },
                              },
                              [_vm._v("Download")]
                            ),
                          ]),
                          gal.attribute.document_pickContent.attribute
                            .doc_thumb == null ||
                          gal.attribute.document_pickContent.attribute
                            .doc_thumb == ""
                            ? _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "images/pros/@img_03.png",
                                    alt: "2021 Viatera Fabrication Guide",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: gal.attribute.document_pickContent
                                      .attribute.doc_thumb,
                                  },
                                }),
                              ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "tab-con tab02" }, [
                _c("div", { staticClass: "guide-box" }, [
                  _c(
                    "div",
                    { staticClass: "g-list col-02" },
                    _vm._l(_vm.totGalList, function (gal) {
                      return _c("div", { staticClass: "bd-gray" }, [
                        _vm._v(" " + _vm._s(gal.length) + " "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              gal.attribute.document_pickContent.content.title
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "col-02" }, [
                          _c("div", { staticClass: "txt-box" }, [
                            _c("div", { staticClass: "t-type-01" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    gal.attribute.document_pickContent.attribute
                                      .doctypeText
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(
                                      gal.attribute.document_pickContent
                                        .attribute.doc_file
                                    )
                                  },
                                },
                              },
                              [_vm._v("Download")]
                            ),
                          ]),
                          gal.attribute.document_pickContent.attribute
                            .doc_thumb == null ||
                          gal.attribute.document_pickContent.attribute
                            .doc_thumb == ""
                            ? _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "images/pros/@img_03.png",
                                    alt: "2021 Viatera Fabrication Guide",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: gal.attribute.document_pickContent
                                      .attribute.doc_thumb,
                                  },
                                }),
                              ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
        _c("li", [_vm._v("Pros")]),
        _c("li", [_vm._v("For Installer")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }