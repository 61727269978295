<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content cntu-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("faq.text.home") }}</li>
          <li>{{ $t("faq.text.support") }}</li>
          <li>{{ $t("faq.text.faq") }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (FAQ) -->
      <div class="container cntu-box container-faq">
        <div class="w-con-02">
          <div class="sub-visual-section">
            <h1 class="tt-1 visual-title tac">{{ $t("faq.text.faq") }} <span class="sr-only"> - {{ prdLabel }}</span></h1>
            <!-- <h2 class="sr-only">LX Hausys {{ prdLabel }} FAQ</h2> -->
          </div>
          <div class="faq_search_wrap">
            <span class="ctt ctt-t2 pc-br">{{ $t("faq.text.inner1") }}</span>
            <span class="ctt ctt-t2 pc-br">{{ $t("faq.text.inner2") }} <a class="ctt ctt-t2 link-ctt" href="support/contactus"><strong>{{ $t("faq.text.contactus") }}</strong></a></span>
            <div class="inner">
              <div class="search-box inline">
                  <div class="search">
                    <input type="text" id="search" :placeholder="$t('faq.text.searchBar')" v-on:keyup.enter="search" class="ta-r" />
                    <button type="button" @click="search()">{{ $t("faq.text.search") }}</button>
                  </div>
                </div>
            </div>
          </div>
          <div class="prod-dt-view inspr-view">
            <div class="tab-ui tab-ui-dark">
              <ul class="all-chk-box" data-chk="chk01">
                <li class="btn-tab btn-chk" id="mainTap" @click="tapClick('all')">
                  <button type="button">
                    {{ $t("faq.text.ALL") }}
                  </button>
                </li>
                <li class="btn-tab btn-chk chk-list chk01" v-for="(tap, index) in categoryList" :class="{ 'on': prdLabel === tap.name }" :data-chk="tap.id" name="mainTap" :id="tap.name" @click="tapClick(tap.name)">
                  <button type="button">
                    {{ tap.name }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="inner">
              <!-- 검색창 -->
              <div class="tab-ui tab-ui-nav tab-ui-gray">
                <button type="button" class="arrow disabled tab-arr-prev">
                  <span class="sr-only">이전</span>
                </button>
                <button type="button" class="arrow tab-arr-next">
                  <span class="sr-only">다음</span>
                </button>
                <ul class="all-chk-box" data-chk="chk02">
                  <li class="btn-tab btn-chk inp-chk-every on" id="subTap" name="subTap" @click="subTapClick('ALL')">
                    <button type="button">
                      {{ $t("faq.text.ALL") }}
                    </button>
                  </li>
                  <li class="btn-tab btn-chk chk-list chk02"  name="subTap" :id="'subTap_'+subTap.id.value" v-for="subTap in subCategoryList" :data-chk="subTap.id.value" @click="subTapClick('subTap_'+subTap.id.value)">
                    <button type="button">
                      {{ subTap.text }}
                    </button>
                  </li>
                </ul>
              </div>
              <!-- 질문 리스트 테이블 -->
              <div class="faq-box">
                <div class="drop-list bd-gray">
                  <ul v-if="contentList.length>0">
                    <!-- 기존있던 accordion -->
                    <!-- <li v-for="faq in contentList">
                      <div class="tit" v-if="countryCode == '_US' || countryCode == '_GL'">
                        <strong class="faq-pro-type" v-if="faq.attribute.typeText != null">{{ faq.parents[0].name }} / {{ faq.attribute.typeText }}</strong>
                        <strong class="faq-pro-type" v-if="faq.attribute.typeText == null">{{ faq.parents[0].name }} / ALL</strong>
                        <h3 class="question">{{ faq.content.title }}</h3>
                        <button type="button" class="btn-view">list view</button>
                      </div>
                      <div class="tit" v-else>
                        <strong class="faq-pro-type" v-if="faq.attribute.typeText != null">{{ faq.parents[0].name }} / {{ faq.docType[0].text }}</strong>
                        <strong class="faq-pro-type" v-if="faq.attribute.typeText == null">{{ faq.parents[0].name }} / {{ $t("faq.text.ALL") }}</strong>
                        <h3 class="question">{{ faq.content.title }}</h3>
                        <button type="button" class="btn-view">list view</button>
                      </div>
                      <div class="t-type-01 cont bd-0" v-html="faq.attribute.editor">
                      </div>
                    </li> -->
                    <li class="accordion-wrap" v-for="faq in contentList">
                      <div class="tit" v-if="countryCode === '_US' || countryCode === '_GL'">
                        <div class="faq-pro-type wrap">
                          <div>
                            <p>Q</p>
                            <div class="badges">
                              <template v-if="faq.attribute.typeText != null">
                                <div :class="`badge ${faq.parents[0].name.toLowerCase()}`">
                                  <span class="uppercase">{{ faq.parents[0].name }}</span>
                                </div>
                              </template>
                              <template v-else>
                                <div :class="`badge ${faq.parents[0].name.toLowerCase()}`">
                                  {{ faq.parents[0].name }}
                                </div>
                                <div class="badge etc">
                                  <span>ALL</span>
                                </div>
                              </template>
                              <!-- badge 옆에 class 추가하면 색이변하도록 css추가 해두엇습니다. -->
                              <!-- 예시 ) badge hflor / badge viatera / badge teracanto /
                                badge himacs / badge benif / bedge etc -->
                              <div class="badge etc" v-if="faq.attribute.typeText">
                                <span>{{ faq.attribute.typeText }}</span>
                              </div>
                            </div>
                          </div>
                          <a class="faq-accordion-title">
                            {{ faq.content.title }}
                          </a>
                        </div>
                        <button type="button" class="btn-view">list view</button>
                      </div>
                      <div class="faq_accordion_content">
                        <b>A</b>
                        <div class="faq_more" v-html="faq.attribute.editor"></div>
                      </div>
                    </li>
                  </ul>
                  <div v-else>
                    <!--  검색결과 없음  -->
                    <div class="no_result">
                      <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult">
                      <p>No Result found</p>
                      <span>If you do not find what you are looking for, please</span>
                      <div class="more-box contact">
                        <a href="support/contactus"><button>Contact Us</button></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 지워도 되는지 몰라서 남겨둔부분 -->
                <div class="pagination">
                  <ul>
                    <li class="btn btn-first"><button type="button" @click="hasIndex(firstNumber)">first page</button></li>
                    <li class="btn btn-prev"><button type="button" @click="hasIndex((pageIndex-1))">prev</button></li>
                    <li class="btn-num" :id="'index_'+index" v-for="(index) in pageList" :data-chk="index" @click="hasIndex(index)"><button type="button"><span>{{ index }}</span></button></li>
                    <li class="btn btn-next"><button type="button" @click="hasIndex(pageIndex+1)">next</button></li>
                    <li class="btn btn-last"><button type="button" @click="hasIndex(totalPage)">last page</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (FAQ) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script type="application/ld+json">
</script>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import SupportService from "@/server/support.service";
import categoryService from "@/server/category.service";
import GnbComponent from '@/components/Layout/GNB';
import global from '@/common/global'
import QuickMenuComponent from '@/components/Layout/Quick'

export default {
  name: 'app',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    QuickMenuComponent
  },
  data () {
    return {
      allCategoryId : '',              /* 전체 카테고리 id */
      contentList : [],                /* 콘텐츠 리스트 */
      categoryList : [],               /* 카테고리(분류) 리스트 */
      subCategoryList : [],            /* 두번째 카테고리(분류) 리스트 */
      categoryId : [],                 /* 분류 카테고리 id */
      dictionaryValues: [],            /* 두번째 카테고리 데이터 */
      pageIndex: 1,                    /* 현재 페이지 */
      pageSize: 10,                     /* 게시글 수 */
      prdLabel: ""                    /* 상품 카테고리 이름(hflor) */,
      totalPage: '',
      totalCount: '',
      pageList: [],
      firstNumber: '',
      lastNumber:'',
      countryCode: '',
      isExist : false,
      faqAccordionOpen: false, //아코디언 닫힌 상태
      countryCodeSub: '',
    }
  },
  watch: {
    $route(to,form){
      if (to !== form){
        this.$router.go(0);
      }
    }
  },

  directives:{
    hoverCursor:{
      bind(el){
        el.addEventListener('mouseover', ()=>{
          el.style.cursor = 'pointer';
        });
        el.addEventListener('mouseout', ()=>{
          el.style.cursor = 'default';
        });
      }
    }
  },

  async created () {
    fetch('').then()
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this;
    let countryCode = this.$countryCode;
    _this.countryCode = countryCode;
    const categoryName = this.$route.params.category_name;
    let categoryCode = 'FAQ'+countryCode;
    let category = await categoryService.getCategoryCode(categoryCode);  /* 카테고리 id 탐색 */
    let categoryId = category.data.object.id;                            /* 카테고리 id */
    _this.allCategoryId = categoryId;                                    /* 전체 카테고리 id */
    _this.categoryId.push(categoryId);                                   /* 카테고리 id */

    let categoryList = await categoryService.getCategoryList(categoryId);                /* 카테고리 리스트 탐색 */
    if(countryCode != '_US' && countryCode != '_GL'){
      _this.categoryList = categoryList.data.page.list.filter((element) => element.code !== 'FAQ_VIATERA'+countryCode);
      let Dictionary = await SupportService.getDictionary('FAQ'+countryCode);     /* 데이터사전 탐색 */
      _this.subCategoryList = Dictionary.data;                             /* 테이터 사전 리스트 */
    } else {
      _this.categoryList = categoryList.data.page.list;
      let Dictionary = await SupportService.getDictionary('FAQ');     /* 데이터사전 탐색 */
      _this.subCategoryList = Dictionary.data;                             /* 테이터 사전 리스트 */
    }
    /*  */

    if(categoryName == undefined){
      await _this.tapClick('all');
    } else {
      let firstCategory = _this.categoryList.find(category => {
        return category.name === categoryName || category.name.toLowerCase() === categoryName;
      });

      console.log(_this.categoryList);
      console.log(firstCategory);
      if(firstCategory){
        await _this.tapClick(firstCategory.name);
      }
      else{
        await _this.tapClick('all');
      }
    }

    _this.$nextTick(function(){
      // 처음 열렸을때
      // $("[class*='chk-list chk01']").removeClass('on');
      // $('#mainTap').addClass('on');
      //
      // 첫번째탭 클릭시
      // $('#mainTap').click(function(){
      //   $("[class*='chk-list chk01']").removeClass('on');
      //   $('#mainTap').addClass('on');
      //   _this.tapClick('all');
      // });
      // $("li[name='mainTap']").click(function(){
      //   _this.tapClick();
      // });
      // $('#subTap').click(function(){
      //   $("[class*='chk-list chk02 on']").removeClass('on');
      //   $('#subTap').addClass('on');
      //   _this.subTapClick('all');
      // });

      // $("li[name='subTap']").click(function(){
      //   if($(this).hasClass('on')){
      //     $(this).closest('.btn-chk').removeClass('on');
      //   } else {
      //     $(this).closest('.btn-chk').addClass('on');
      //   }
      //
      //   var total = $("[class*='chk-list chk02']").length;
      //   var tapchk = $("[class*='chk-list chk02 on']").length
      //
      //   if(total != tapchk){
      //     if(tapchk != 0){
      //       _this.subTapClick('');
      //       $('#subTap').removeClass('on');
      //     } else {
      //       _this.subTapClick('all');
      //       $('#subTap').addClass('on');
      //     }
      //   } else {
      //     _this.subTapClick('all');
      //     $("li[name='subTap']").removeClass('on');
      //     $('#subTap').addClass('on');
      //   }
      // });
    })
    activeArrowTab();
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async tapClick(tap){
      const _this = this;
      if(tap === 'all'){
        _this.prdLabel = '';
      } else {
        _this.prdLabel = tap;
      }
      $(".drop-list > ul > li").removeClass("on");
      $(".t-type-01").removeClass('on');
      _this.pageIndex = 1;
      if(tap == 'all'){
        $("[class*='chk-list chk01 on']").removeClass('on');
        $('#mainTap').addClass('on');
        _this.categoryId = [];
        _this.categoryId.push(_this.allCategoryId)
        $('.btn-num').removeClass('on');
      } else {
        $('#mainTap').removeClass('on');
        // $("[class*='chk-list chk01 on']").removeClass('on');
        // $("#"+tap).addClass('on');
        _this.categoryId = [];
        let ids = this.categoryList.map(category => {
          if (category.name === tap) {
            return category.id;
          }
        });
        _this.categoryId = ids;
        $('.btn-num').removeClass('on');
      }

      await _this.pagination();
    },
    async subTapClick(subTap){
      $(".drop-list > ul > li").removeClass("on");
      $(".t-type-01").removeClass('on');
      $("li[name='subTap']").removeClass('on');
      const _this = this;
      _this.pageIndex = 1;
      if(subTap != 'ALL'){
        _this.dictionaryValues = [];
        $('.btn-num').removeClass('on');
        $('#'+subTap).addClass('on')
        _this.dictionaryValues.push('type_'+$("[class*='btn-chk chk-list chk02 on']").attr('data-chk'));
      } else {
        _this.dictionaryValues = [];
        $('.btn-num').removeClass('on');
        $('#subTap').addClass('on')
      }

      await _this.pagination();
    },
    async search(){
      const _this = this;
      _this.pageIndex = 1;
      let word = $('#search').val();
      if(word != ''){
        // $(".drop-list > ul > li").addClass("on");
        // $(".t-type-01").addClass('on');
      } else {
        $(".drop-list > ul > li").removeClass("on");
        $(".t-type-01").removeClass('on');
      }
      $('.btn-num').removeClass('on');
      $('#index_1').addClass('on');

      await _this.pagination();
    },

    async hasIndex(index){
      const _this = this;
      let word = $('#search').val();
      if(index > 1){
        if(index <= _this.totalPage){
          _this.pageIndex = index;
        }
      } else {
        _this.pageIndex = 1;
      }
      await _this.pagination();
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    async pagination(){
      let _this = this;
      let word = $('#search').val();
      let contentList = await SupportService.getContentListTap(_this.categoryId, _this.dictionaryValues, word, _this.pageIndex, _this.pageSize);
      _this.contentList = contentList.data.list;
      _this.totalPage = contentList.data.totalPage;
      _this.totalCount = contentList.data.totalCount;

      _this.contentList.forEach((content, idx) => {
        content.docType = _this.subCategoryList.filter((element) => element.id.value == content.attribute.type);
      })

      let pageGroup = Math.ceil(_this.pageIndex / 5);
      let lastBottomNumber = pageGroup * 5;  //하단 마지막 숫자
      let first = lastBottomNumber - 4;
      if(lastBottomNumber > _this.totalPage) {
        lastBottomNumber = _this.totalPage;  //총 갯수보다 큰 경우 방지
      }
      _this.firstNumber = first;
      _this.lastNumber = lastBottomNumber;
      var list = [];
      for (var i = _this.firstNumber; i <= _this.lastNumber; i++ ) {
        list.push(i);
      }
      _this.pageList = list;

      this.isExist =  word.length > 0;

      $(".accordion-wrap").removeClass('on');
      _this.$nextTick(function(){
        $('.btn-num').removeClass('on');
        $('#index_'+_this.pageIndex).addClass('on');
      })
    }
  }
}

$(document).ready(function(){
  $(".accordion-wrap").on("click", function (e) {
    $(".accordion-wrap").removeClass("on");
    $(this).addClass("on");
    console.log("click");
  });
});
</script>
<style>
.inp-chk-every{
  padding-left: 0px;
}
@media only screen and (max-width: 767px) {
 .ta-r {
   text-align: left !important;
 }
}
</style>
