<template>
  <!-- <div id="wrap" class="himac"> -->
    <div v-if="nationCode === '_US' || nationCode === '_GL'">
      <h-flor-finder_-u-s-g-l :PfContentId="PfContentId"
                              :PfCateCode="PfCateCode"
                              :filterNm="filterNm"
                              :filterVal="filterVal"
                              :filterNm1="filterNm1"
                              :filterVal1="filterVal1"
                              :filterNm2="filterNm2"
                              :filterVal2="filterVal2"
                              :filterSeo="filterSeo"
                              :filterSeo1="filterSeo1"
                              :filterNm3="filterNm3"
                              :filterVal3="filterVal3">
      </h-flor-finder_-u-s-g-l>
    </div>
    <div v-else-if="nationCode === '_EN' || nationCode === '_UK' || nationCode === '_DE' || nationCode === '_FR' || nationCode === '_IT' || nationCode === '_ES'">
    <h-flor-finder_-e-u-k   :PfContentId="PfContentId"
                            :PfCateCode="PfCateCode"
                            :filterNm="filterNm"
                            :filterVal="filterVal"
                            :filterNm1="filterNm1"
                            :filterVal1="filterVal1"
                            :filterNm2="filterNm2"
                            :filterVal2="filterVal2">
      </h-flor-finder_-e-u-k>
    </div>
  <!-- </div> -->
</template>

<script>
import hFlorFinder_USGL from '@/views/products/hFlorFinder_USGL'
import hFlorFinder_EUK from '@/views/products/hFlorFinder_EUK'
import categoryService from '@/server/category.service'

import { mapState } from 'vuex'
export default {
  name: 'Detail',
  components: {
    hFlorFinder_EUK,
    hFlorFinder_USGL
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      nationCode: '',
      PfContentId: '', /* 본 컨텐츠 아이디 */
      PfCateCode: '',
      filterNm: '',
      filterVal: '',
      filterNm1: '',
      filterVal1: '',
      filterNm2: '',
      filterVal2: '',
      filterNm3: '',
      filterVal3: '',
      filterSeo: '',
      filterSeo1: ''
    }
  },
  async created () {
    const _this = this
    if (location.pathname.indexOf('/hflor/hflor-finder') !== -1) {
      // 신규 SEO URL일 경우
      const seoName = _this.$route.params.main_collection_name
      const seoRes = await categoryService.getCategoryBySeo(seoName, 'FLOOR', 'PRD')
      if (seoRes.data.object !== null) {
        _this.PfCateCode = seoRes.data.object.code.slice(0, seoRes.data.object.code.length - 3)
      }

      // --------------- URL 조건 1개 param  start ------------------------------------------------------
      _this.filterNm = _this.$route.params.filterNm
      _this.filterVal = _this.$route.params.filterVal

      if (_this.filterNm === 'collection') {
        _this.filterNm = _this.$route.params.filterNm
        const seoFilterRes = await categoryService.getCategoryBySeo(_this.$route.params.filterVal, 'FLOOR', 'FLOOR')
        _this.filterVal = seoFilterRes.data.object.code
      } else if (_this.filterNm === 'product-type') {
        _this.filterNm = 'prdType'
        if (_this.filterVal === 'spc-click') {
          _this.filterVal = 'lux_001'
        } else if (_this.filterVal === 'glue-down') {
          _this.filterVal = 'lux_002'
        } else if (_this.filterVal === 'looselay') {
          _this.filterVal = 'lux_003'
        } else if (_this.filterVal === 'light-commercial') {
          _this.filterVal = 'het_001'
        } else if (_this.filterVal === 'commercial') {
          _this.filterVal = 'het_002'
        } else if (_this.filterVal === 'heavy-commercial') {
          _this.filterVal = 'het_004'
        } else {
          _this.filterVal = 'het_003'
        }
      } else if (_this.filterNm === 'colors') {
        _this.filterNm = 'isNew'
      }
      // ------------------  URL 조건 1개 param  end ----------------------------------------------------------------

      // ================= URL 조건 2개 param  start ================================================================
      _this.filterNm1 = _this.$route.params.filterNm1
      _this.filterVal1 = _this.$route.params.filterVal1
      _this.filterNm2 = _this.$route.params.filterNm2
      _this.filterVal2 = _this.$route.params.filterVal2
      _this.filterNm3 = _this.$route.params.filterNm3
      _this.filterVal3 = _this.$route.params.filterVal3

      if (_this.filterNm1 === 'collection') {
        _this.filterNm1 = _this.$route.params.filterNm1
        const seoFilterRes = await categoryService.getCategoryBySeo(_this.$route.params.filterVal1, 'FLOOR', 'FLOOR')
        _this.filterVal1 = seoFilterRes.data.object.code
      } else if (_this.filterNm1 === 'product-type') {
        _this.filterNm1 = 'prdType'
        if (_this.filterVal1 === 'spc-click') {
          _this.filterVal1 = 'lux_001'
        } else if (_this.filterVal1 === 'glue-down') {
          _this.filterVal1 = 'lux_002'
        } else if (_this.filterVal1 === 'looselay') {
          _this.filterVal1 = 'lux_003'
        } else if (_this.filterVal1 === 'light-commercial') {
          _this.filterVal1 = 'het_001'
        } else if (_this.filterVal1 === 'commercial') {
          _this.filterVal1 = 'het_002'
        } else if (_this.filterVal1 === 'heavy-commercial') {
          _this.filterVal1 = 'het_004'
        } else {
          _this.filterVal1 = 'het_003'
        }
      } else if (_this.filterNm1 === 'colors') {
        _this.filterNm1 = 'isNew'
      }

      if (_this.filterNm2 === 'collection') {
        _this.filterNm2 = _this.$route.params.filterNm2
        const seoFilterRes = await categoryService.getCategoryBySeo(_this.$route.params.filterVal2, 'FLOOR', 'FLOOR')
        _this.filterVal2 = seoFilterRes.data.object.code
      } else if (_this.filterNm2 === 'product-type') {
        _this.filterNm2 = 'prdType'
        if (_this.filterVal2 === 'spc-click') {
          _this.filterVal2 = 'lux_001'
        } else if (_this.filterVal2 === 'glue-down') {
          _this.filterVal2 = 'lux_002'
        } else if (_this.filterVal2 === 'looselay') {
          _this.filterVal2 = 'lux_003'
        } else if (_this.filterVal2 === 'light-commercial') {
          _this.filterVal2 = 'het_001'
        } else if (_this.filterVal2 === 'commercial') {
          _this.filterVal2 = 'het_002'
        } else if (_this.filterVal2 === 'heavy-commercial') {
          _this.filterVal2 = 'het_004'
        } else {
          _this.filterVal2 = 'het_003'
        }
      } else if (_this.filterNm2 === 'colors') {
        _this.filterNm2 = 'isNew'
      }

      if (_this.filterNm3 === 'collection') {
        _this.filterNm3 = _this.$route.params.filterNm3
        const seoFilterRes = await categoryService.getCategoryBySeo(_this.$route.params.filterVal3, 'FLOOR', 'FLOOR')
        _this.filterVal3 = seoFilterRes.data.object.code
      } else if (_this.filterNm3 === 'product-type') {
        _this.filterNm3 = 'prdType'
        if (_this.filterVal2 === 'spc-click') {
          _this.filterVal3 = 'lux_001'
        } else if (_this.filterVal3 === 'glue-down') {
          _this.filterVal3 = 'lux_002'
        } else if (_this.filterVal3 === 'looselay') {
          _this.filterVal3 = 'lux_003'
        } else if (_this.filterVal3 === 'light-commercial') {
          _this.filterVal3 = 'het_001'
        } else if (_this.filterVal3 === 'commercial') {
          _this.filterVal3 = 'het_002'
        } else if (_this.filterVal3 === 'heavy-commercial') {
          _this.filterVal3 = 'het_004'
        } else {
          _this.filterVal3 = 'het_003'
        }
      } else if (_this.filterNm3 === 'colors') {
        _this.filterNm3 = 'isNew'
      }

      // ================= URL 조건 2개 param  end ================================================================

      _this.$nextTick(function () {
        _this.filterSeo = _this.$route.params.filterVal
        _this.filterSeo1 = _this.$route.params.filterVal1
      })
    } else {
      // 기존 URL일 경우
      _this.PfCateCode = _this.$route.params.categoryCode

      // URL 조건 1개 param
      _this.filterNm = _this.$route.params.filterNm
      _this.filterVal = _this.$route.params.filterVal

      // URL 조건 2개 param
      _this.filterNm1 = _this.$route.params.filterNm1
      _this.filterVal1 = _this.$route.params.filterVal1
      _this.filterNm2 = _this.$route.params.filterNm2
      _this.filterVal2 = _this.$route.params.filterVal2

      _this.filterNm3 = _this.$route.params.filterNm3
      _this.filterVal3 = _this.$route.params.filterVal3
    }

    _this.nationCode = _this.$countryCode /* 국가 코드 */
    _this.contentId = _this.$route.params.contentId /* 본 컨텐츠 아이디 */
  }
}
</script>
