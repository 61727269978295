var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewSection", { staticClass: "mt-64" }, [
        _c("pre", { staticClass: "expanded-waste-recycling-title" }, [
          _vm._v("Expanded Waste Recycling Activities Performance in 2022"),
        ]),
        _c(
          "div",
          { staticClass: "grid-table-area" },
          _vm._l(_vm.gridAndTableCards, function (card, index) {
            return _c("ExpandedWasteRecyclingGridAndTable", {
              key: index,
              attrs: { gridAndTable: card },
            })
          }),
          1
        ),
      ])
    : _vm.nationCode == "_JP"
    ? _c("OverviewSection", { staticClass: "mt-64" }, [
        _c("pre", { staticClass: "expanded-waste-recycling-title" }, [
          _vm._v("2022年の廃棄物リサイクル活動の拡大実績"),
        ]),
        _c(
          "div",
          { staticClass: "grid-table-area" },
          _vm._l(_vm.gridAndTableCardsJp, function (card, index) {
            return _c("ExpandedWasteRecyclingGridAndTable", {
              key: index,
              attrs: { gridAndTable: card },
            })
          }),
          1
        ),
      ])
    : _vm.nationCode == "_RU"
    ? _c("OverviewSection", { staticClass: "mt-64" }, [
        _c("pre", { staticClass: "expanded-waste-recycling-title" }, [
          _vm._v("Расширение деятельности по переработке отходов в 2022 году"),
        ]),
        _c(
          "div",
          { staticClass: "grid-table-area" },
          _vm._l(_vm.gridAndTableCardsRu, function (card, index) {
            return _c("ExpandedWasteRecyclingGridAndTable", {
              key: index,
              attrs: { gridAndTable: card },
            })
          }),
          1
        ),
      ])
    : _vm.nationCode == "_CN"
    ? _c("OverviewSection", { staticClass: "mt-64" }, [
        _c("pre", { staticClass: "expanded-waste-recycling-title" }, [
          _vm._v("2022年扩大废物回收活动绩效"),
        ]),
        _c(
          "div",
          { staticClass: "grid-table-area" },
          _vm._l(_vm.gridAndTableCardsCn, function (card, index) {
            return _c("ExpandedWasteRecyclingGridAndTable", {
              key: index,
              attrs: { gridAndTable: card },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }