<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content inspr-view">
      <!-- container (Inspiration Gallery) -->
      <div class="container inspr-box">
        <div class="sub_title_area full_width">
          <div class="inner_width">
            <div class="location">
              <ul>
                <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCode.toLowerCase()}/index`)">{{
                    $t('gallery.text.home')
                  }}
                </li>
                <li>{{ $t('gallery.text.inspiration') }}</li>
                <li>{{ $t('gallery.text.inspiration_gallery') }}</li>
              </ul>
            </div>
            <h1 class="page_title tt-1">{{ $t('gallery.text.inspiration_gallery') }} </h1>
          </div>
        </div>
        <div class="w-con">
          <!-- gallery -->
          <div class="gallery">
            <!-- 상단 검색영역 -->
            <div class="search-box">
              <div class="filter_area" ref="filterArea">
                <div class="filter_wrap">
                  <button type="button" class="btn_filter" :class="{ 'on': isFilterOpen }"
                          @click="isFilterOpen = !isFilterOpen">
                    <span class="icon icon_filter"></span>
                    <span class="txt p-ui">Filter</span>
                    <span class="txt m-ui">Filter by</span>
                    <span class="icon icon_arrow_down"></span>
                  </button>
                </div>
                <!-- s: filter popup -->
                <transition name="fade">
                  <div class="filter_pop on" v-if="isFilterOpen">
                    <div class="filter_tit">
                      <p>FILTER BY</p>
                      <button type="button" class="btn_close" @click="isFilterOpen = !isFilterOpen"></button>
                    </div>
                    <div class="filter_top">
                      <ul>
                        <!-- product -->
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>PRODUCT</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <input type="checkbox" id="product_checkall" name="product"
                                     v-model="isCheckAllProduct"
                                     ref="productCheckBoxAll"
                                     @change="handleCheckBoxAll($event,'productList','productCheckList')"/>
                              <label for="product_checkall">Check All</label>
                            </span>
                              <span class="checkbox_more product_filter_checkbox">
                              <span v-for=" (item,index) in productList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="productCheckList"
                                       :value="item.value"
                                       ref="checkBox"
                                       @change="handleCheckBox($event,'productList','productCheckList','isCheckAllProduct')"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                          </div>
                        </li>
                        <!-- SEGMENTS -->
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>SEGMENTS</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <span class="checkbox_subtit">Residential</span>
                              <input type="checkbox" id="residential_checkall" name="residential"
                                     v-model="isCheckAllResidential"
                                     ref="residentialCheckBoxAll"
                                     @change="handleCheckBoxAll($event,'residentialList','residentialCheckList')"/>
                              <label for="residential_checkall">Residential</label>
                            </span>
                              <span class="checkbox_more">
                              <span v-for=" (item,index) in residentialList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="residentialCheckList"
                                       :value="item.value"
                                       ref="checkBox"
                                       @change="handleCheckBox($event,'residentialList','residentialCheckList','isCheckAllResidential')"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <span class="checkbox_subtit">Commercial</span>
                              <input type="checkbox" id="commercial_checkall" name="commercial"
                                     v-model="isCheckAllCommercial"
                                     ref="commercialCheckBoxAll"
                                     @change="handleCheckBoxAll($event,'commercialList','commercialCheckList')"/>
                              <label for="commercial_checkall">Commercial</label>
                            </span>
                              <span class="checkbox_more">
                               <span v-for=" (item,index) in commercialList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="commercialCheckList"
                                       :value="item.value"
                                       ref="checkBox"
                                       @change="handleCheckBox($event,'commercialList','commercialCheckList','isCheckAllCommercial')"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                          </div>
                        </li>
                        <!-- application -->
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>APPLICATION</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <input type="checkbox" id="application_checkall" name="application"
                                     v-model="isCheckApplication"
                                     ref="applicationCheckBoxAll"
                                     @change="handleCheckBoxAll($event,'applicationList','applicationCheckList')"/>
                              <label for="application_checkall">Check All</label>
                            </span>
                              <span class="checkbox_more">
                              <span v-for=" (item,index) in applicationList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="applicationCheckList"
                                       :value="item.value"
                                       ref="checkBox"
                                       @change="handleCheckBox($event,'applicationList','applicationCheckList','isCheckApplication')"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="filter_bottom">
                      <div class="btn_box">
                        <button type="button" class="btn_wh" @click="handleCloseBadge('all',1)">Clear All</button>
                        <button type="button" class="btn_bk" @click="handleClickApply()">Apply</button>
                      </div>
                    </div>
                  </div>
                </transition>
                <!-- e: filter popup -->
                <div class="search">
                  <input type="text" id="textSearch" placeholder="Enter Item Number or Name"
                         value=""
                         v-model="searchInfo.searchText"
                         @input="handleSearch"
                         @keyup.enter="handleSearch"
                         ref="search"
                  />
                  <button type="button" class="btn_cancel" v-if=" searchInfo.searchText.length !== 0"
                          @click="handleSearchDelete()"
                  />
                  <button type="button" class="btn_search" @click="handleSearch" v-else/>
                </div>
              </div>

              <div class="sort_area no_bottom_line">
                <template v-if="badgeList.length > 0">
                  <div class="select-view">
                    <ul class="list-filter-key">
                      <li class="sel" v-for=" item in badgeList" style="cursor: pointer"
                          @click="handleCloseBadge(item.value)">
                        <span>{{ item.id }}</span>
                        <button type="button"></button>
                      </li>
                      <li class="sel del_all" v-if="badgeList.length > 0" @click="handleCloseBadge('all')">
                        <button type="button" class="btn-all-delete"><span>Clear All</span></button>
                      </li>
                    </ul>
                  </div>
                </template>
              </div>

              <div class="count_area">
                <div class="filter-cnt">
                  <span class="num">{{ pageInfo.totalCount }}</span>
                  <span>&nbsp;</span>
                  <span class="txt">{{ $t('gallery.text.results') }}</span>
                </div>
              </div>
            </div>

            <template v-if="isLoading">
              <div class="grid-box">
                <div class="no_result">
                  <div class="loading_area">
                    <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="pageList.length === 0">
                <div class="grid-box">
                  <div class="no_result">
                    <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult">
                    <p>No Result found</p>
                    <span>If you do not find what you are looking for, please</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <template>
                  <div v-for="(group, index) in groupedPageList" class="grid-box">
                    <ul class="ins_grid">
                      <li class="gal-grid-cont" v-for="item in group">
                        <button type="button" class="g-box" @click="moveToDetail(item.content)">
                          <div class="img-box">
                            <img :src="$imagePath + item.attribute.mainThumbnail" :alt="item.content.title"/>
                          </div>
                          <div class="txt-box">
                            <p class="txt-cate">
                              <template v-for=" cateItem in removeDuplicates(item.attribute.product_cateText)">
                                <span v-html="cateItem"/>
                              </template>
                            </p>
                            <p class="content_title" v-html="item.content.title"></p>
                            <template v-if="item.attribute.applicationList">
                              <div class="hashtag_group" v-if="item.attribute.applicationList.length !== 0">
                                <template v-for="appItem in item.attribute.applicationList">
                                  <span class="hashtag" v-html="`#${appItem.text}`"></span>
                                </template>
                              </div>
                            </template>
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                </template>

                <div class="btn_area" v-if="pageList.length !== 0 && isLoadMore">
                  <template v-if="isLoadMoreLoading">
                    <div class="loading_area">
                      <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
                    </div>
                  </template>
                  <template v-else>
                    <button type="button" class="btn_load_more" @click="handleClickLoadMore">
                      <span class="txt">Load More</span>
                      <span class="icon icon_plus"></span>
                    </button>
                  </template>
                </div>
              </template>
            </template>
          </div>
          <!-- //gallery -->
        </div>
      </div>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
    <!-- //content -->
  </div>
</template>
<script>
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import InspirationService from '@/server/inspiration.service.js'
import ProductService from '@/server/product.service'
import { debounce, isEmpty } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    QuickMenuComponent,
    ShortComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    }),
    groupedPageList () {
      const chunkSize = 8
      const groups = []
      for (let i = 0; i < this.pageList.length; i += chunkSize) {
        groups.push(this.pageList.slice(i, i + chunkSize))
      }
      return groups
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  data () {
    return {
      productList: [],
      commercialList: [],
      residentialList: [],
      applicationList: [],
      productCheckList: [],
      commercialCheckList: [],
      residentialCheckList: [],
      applicationCheckList: [],
      badgeList: [],
      pageList: [],
      pageInfo: {},
      isLoadMore: true,
      isFilterOpen: false,
      isCheckAllProduct: false,
      isCheckAllCommercial: false,
      isCheckAllResidential: false,
      isCheckApplication: false,
      isLoading: true,
      isLoadMoreLoading: false,
      countryCode: '',
      searchInfo: {
        pageCategotyId: 0,
        categoryidList: [],
        pageSize: 8,
        pageIndex: 1,
        searchText: ''
      },
      relatedCheck: {
        productList: 'productCheckList',
        commercialList: 'commercialCheckList',
        residentialList: 'residentialCheckList',
        applicationList: 'applicationCheckList'
      },
      dictionaryConditionSearchType: {
        productList: 'product_cate',
        commercialList: 'segments',
        residentialList: 'segments',
        applicationList: 'applicationListSelected'
      },
      badgeIdList: {
        productList: 'PRODUCT',
        commercialList: 'COMMERCIAL',
        residentialList: 'RESIDENTIAL',
        applicationList: 'APPLICATION'
      },
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }
        ]
      }
    }
  },
  async created () {
    this.countryCode = this.$countryCode.slice(1)
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  async mounted () {
    const filter = this.$route.params.filter
    const filterType = this.$route.params.filterType

    /* 카테고리 */
    const cateChildren = await InspirationService.getCategoryCode(`${this.countryCode}_INS_GAL_TOBE`)
    this.searchInfo.pageCategotyId = cateChildren.data.object.id
    this.searchInfo.categoryidList.push(cateChildren.data.object.id)

    /* 필터 리스트 */
    const commercialList = await InspirationService.getDictionary(`INSP_IST_CMMR_${this.countryCode}`)
    this.commercialList = this.setFilterList(commercialList.data, 1)

    const residentialList = await InspirationService.getDictionary(`INSP_IST_RSDN_${this.countryCode}`)
    this.residentialList = this.setFilterList(residentialList.data, 1)

    const application = await InspirationService.getDictionary(`INSP_APPL_${this.countryCode}`)
    this.applicationList = this.setFilterList(application.data, 1)

    const product = await ProductService.getDictionary(`INSP_PRODUCT_TYPE_${this.countryCode}`)
    this.productList = this.setFilterList(product.data, 1)

    if (filter !== undefined) {
      if (filterType !== undefined) {
        Object.keys(this.dictionaryConditionSearchType).forEach((item, index) => {
          if (this.dictionaryConditionSearchType[item] === filterType) {
            if (filterType === 'product_cate') {
              if (filter === 'borte-premium-panel') {
                this.dictionaryCondition.list[0].values.push({
                  id: filterType,
                  value: 'BORTE'
                })
                this.productCheckList.push('BORTE')
                this.badgeList.push({
                  id: `${this.badgeIdList[item]}: BORTE`,
                  value: 'BORTE'
                })
              } else {
                this.dictionaryCondition.list[0].values.push({
                  id: filterType,
                  value: filter.toUpperCase().replaceAll('-', ' ')
                })
                this.productCheckList.push(filter.toUpperCase().replaceAll('-', ' '))
                this.badgeList.push({
                  id: `${this.badgeIdList[item]}: ${filter.toUpperCase().replaceAll('-', ' ')}`,
                  value: filter.toUpperCase().replaceAll('-', ' ')
                })
              }
            }
            // if (filterType === 'segments') {
            //   this.dictionaryCondition.list[0].values.push({
            //     id: filterType,
            //     value: filter
            //   })
            //   const residential = this.residentialList.map((item) => item.id)
            //   const commercial = this.commercialList.map((item) => item.id)
            //
            //   if (commercial.includes(filter) && index === 1) {
            //     this[this.relatedCheck[item]].push(filter)
            //     this.badgeList.push({ id: `${this.badgeIdList[item]}: ${filter}`, value: filter })
            //   }
            //   if (residential.includes(filter) && index === 2) {
            //     this[this.relatedCheck[item]].push(filter)
            //     this.badgeList.push({ id: `${this.badgeIdList[item]}: ${filter}`, value: filter })
            //   }
            // }
            if (filterType === 'segments') {
              const lowerFilter = filter.toLowerCase().replaceAll('-', ' ')
              let matchedId = null

              if (index === 1) {
                matchedId = this.commercialList.find(item => item.id.toLowerCase().replaceAll('-', ' ') === lowerFilter)?.id
              } else if (index === 2) {
                matchedId = this.residentialList.find(item => item.id.toLowerCase().replaceAll('-', ' ') === lowerFilter)?.id
              }

              if (matchedId) {
                this.dictionaryCondition.list[0].values.push({
                  id: filterType,
                  value: matchedId
                })

                this[this.relatedCheck[item]].push(matchedId)
                this.badgeList.push({ id: `${this.badgeIdList[item]}: ${matchedId}`, value: matchedId })
              }
            }
            if (filterType === 'applicationListSelected') {
              const lowerFilter = filter.toLowerCase().replaceAll('-', ' ')
              const matchedId = this.applicationList.find(item => item.id.toLowerCase().replaceAll('-', ' ') === lowerFilter)?.id

              if (matchedId) {
                this.dictionaryCondition.list[0].values.push({
                  id: filterType,
                  value: matchedId
                })

                this[this.relatedCheck[item]].push(matchedId)
                this.badgeList.push({ id: `${this.badgeIdList[item]}: ${matchedId}`, value: matchedId })
              }
            }
          }
        })
      } else {
        const filterName = {
          commercial: 'commercialList',
          residential: 'residentialList'
        }
        this[filterName[filter]].forEach((item) => {
          this.badgeList.push({ id: `${this.badgeIdList[filterName[filter]]}: ${item.id}`, value: item.value })
          this[this.relatedCheck[filterName[filter]]].push(item.value)
          this.dictionaryCondition.list[0].values.push({
            id: this.dictionaryConditionSearchType[filterName[filter]],
            value: item.id
          })
        })
        if (filter === 'commercial') {
          this.isCheckAllCommercial = true
        } else {
          this.isCheckAllResidential = true
        }
      }
    }

    /* 리스트 조회 */
    this.getList()
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    handleClickOutside (event) {
      const filterArea = this.$refs.filterArea
      if (filterArea && !filterArea.contains(event.target)) {
        this.isFilterOpen = false
      }
    },
    handleCheckBoxAll (event, checktype, checkList) {
      const { checked } = event.target
      if (checked) {
        this[checkList] = this[checktype].map((item) => item.value)
        const badgeInnerList = this[checkList].map((item) => ({
          id: `${this.badgeIdList[checktype]}: ${item}`,
          value: item
        }))
        this.badgeList = this.badgeList.concat(badgeInnerList)
        const dictionaryConditionValues = this[checktype].map((item) => ({
          id: this.dictionaryConditionSearchType[checktype],
          value: item.id
        }))
        this.dictionaryCondition.list[0].values = this.dictionaryCondition.list[0].values.concat(dictionaryConditionValues)
      } else {
        this[checkList] = []
        const filteredArray = this.badgeList.filter(obj1 => {
          for (const obj2 of this[checktype]) {
            if (obj1.id === `${this.badgeIdList[checktype]}: ${obj2.id}` && obj1.value === obj2.value) {
              return false
            }
          }
          return true
        })
        this.badgeList = filteredArray
        this.dictionaryCondition.list[0].values = this.dictionaryCondition.list[0].values.filter(item =>
          !this[checktype].some(filterItem => filterItem.value === item.value && this.dictionaryConditionSearchType[checktype] === item.id)
        )
      }
    },
    handleCheckBox (event, checktype, checkList, allCheck) {
      const { checked, value } = event.target
      this[allCheck] = this[checktype].length === this[checkList].length
      const checkObject = this[checktype].find((item) => item.value === value)

      if (checked) {
        this.badgeList.push({ id: `${this.badgeIdList[checktype]}: ${checkObject.id}`, value: checkObject.value })
        this.dictionaryCondition.list[0].values.push({
          id: this.dictionaryConditionSearchType[checktype],
          value: checkObject.id
        })
      } else {
        this.badgeList = this.badgeList.filter((item) => item.value !== value)
        this.dictionaryCondition.list[0].values = this.dictionaryCondition.list[0].values.filter((item) => item.value !== checkObject.id)
      }
    },
    handleCloseBadge (clickCode, type) {
      if (clickCode === 'all') {
        this.badgeList = []
        this.productCheckList = []
        this.applicationCheckList = []
        this.commercialCheckList = []
        this.residentialCheckList = []
        this.isCheckAllProduct = false
        this.isCheckAllCommercial = false
        this.isCheckAllResidential = false
        this.isCheckApplication = false
        this.dictionaryCondition.list.forEach((item, index) => {
          this.dictionaryCondition.list[index] = { operator: 'OR', values: [] }
        })
      } else {
        this.badgeList = this.badgeList.filter((item) => item.value !== clickCode)
        this.productCheckList = this.productCheckList.filter((item) => item !== clickCode)
        this.commercialCheckList = this.commercialCheckList.filter((item) => item !== clickCode)
        this.residentialCheckList = this.residentialCheckList.filter((item) => item !== clickCode)
        this.applicationCheckList = this.applicationCheckList.filter((item) => item !== clickCode)
        this.dictionaryCondition.list.forEach((item, index) => {
          const isCorrect = this.dictionaryCondition.list[index].values.some((item) => item.value === clickCode)
          if (isCorrect) {
            this.dictionaryCondition.list[index].values = this.dictionaryCondition.list[index].values.filter((item) => item.value !== clickCode)
          }
        })
      }
      if (type !== 1) {
        this.getList()
      }
    },
    handleSearch: debounce(function () {
      this.getList()
    }, 300),
    handleSearchDelete () {
      this.searchInfo.searchText = ''
      this.getList()
    },
    handleClickApply () {
      this.isFilterOpen = false
      this.getList()
    },
    handleClickLoadMore () {
      this.searchInfo.pageSize += 8
      this.getList('isLoadMoreLoading')
    },
    async getList (loadingType) {
      try {
        this[loadingType === undefined ? 'isLoading' : loadingType] = true
        const { data } = await InspirationService.getContentListTap(this.searchInfo.categoryidList, this.dictionaryCondition, this.searchInfo.searchText, 'search_text', this.searchInfo.pageIndex, this.searchInfo.pageSize)
        data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['applicationList', 'productList', 'subThumbnailList']
            if (exceptList.includes(item)) {
              data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })
        if (data.totalCount > data.list.length) {
          this.isLoadMore = true
        } else {
          this.isLoadMore = false
        }

        this.pageInfo = data
        this.pageList = data.list
      } catch (e) {
        console.log('에러', e)
      } finally {
        this[loadingType === undefined ? 'isLoading' : loadingType] = false
      }
    },
    setFilterList (list, type) {
      let filterList = []
      /*
        1. Dictionary
        2. Category
       */
      if (type === 1) {
        filterList = list.map((item) => ({
          value: String(item.id.value),
          id: item.text
        }))
      } else {
        filterList = list.map((item) => ({
          value: String(item.id),
          id: item.name
        }))
      }
      return filterList
    },
    moveToDetail (content) {
      const contentId = content.id
      const slug = content.slug
      if (isEmpty(slug)) {
        this.$router.push({ name: 'gallery-detail', query: { contentId } })
      } else {
        this.$router.push({ path: `/gallery/detail/${slug}` })
      }
    },
    removeDuplicates (array) {
      return _.uniq(array)
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
