var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    { staticClass: "financial-statement-table-column-area" },
    _vm._l(_vm.dataList, function (item) {
      return _c("tr", { key: item }, [
        _c("td", { style: { background: item.valueBackground } }, [
          _c(
            "div",
            {
              staticClass: "financial-statement-table-column-item",
              style: { "font-weight": item.valueFontWeight },
            },
            [_vm._v(" " + _vm._s(item.item) + " ")]
          ),
        ]),
        _c("td", { style: { background: item.valueBackground } }, [
          _c(
            "div",
            {
              staticClass: "financial-statement-table-column-value",
              style: { "font-weight": item.valueFontWeight },
            },
            [_vm._v(" " + _vm._s(item.value1) + " ")]
          ),
        ]),
        _c("td", { style: { background: item.valueBackground } }, [
          _c(
            "div",
            {
              staticClass: "financial-statement-table-column-value",
              style: { "font-weight": item.valueFontWeight },
            },
            [_vm._v(" " + _vm._s(item.value2) + " ")]
          ),
        ]),
        _c("td", { style: { background: item.valueBackground } }, [
          _c(
            "div",
            {
              staticClass: "financial-statement-table-column-value",
              style: { "font-weight": item.valueFontWeight },
            },
            [_vm._v(" " + _vm._s(item.value3) + " ")]
          ),
        ]),
        _c("td", { style: { background: item.valueBackground } }, [
          _c(
            "div",
            {
              staticClass: "financial-statement-table-column-value",
              style: { "font-weight": item.valueFontWeight },
            },
            [_vm._v(" " + _vm._s(item.value4) + " ")]
          ),
        ]),
        _c("td", { style: { background: item.valueBackground } }, [
          _c(
            "div",
            {
              staticClass: "financial-statement-table-column-value",
              style: { "font-weight": item.valueFontWeight },
            },
            [_vm._v(" " + _vm._s(item.value5) + " ")]
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }