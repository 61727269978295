var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lightweight-component-area" },
    [
      _c("ProductSectionHeader", { attrs: { title: "Lightweight Component" } }),
      _vm._m(0),
      _c("ProductComponentGroup", {
        staticClass: "mt-100 mx-30",
        attrs: { left: _vm.leftComponentInfo, right: _vm.rightComponentInfo },
      }),
      _c("p", { staticClass: "ctt ctt-t4" }, [
        _vm._v(
          "※ Weight reduction is based on simulation results of automotive design analysis programs (CATIA, LS-DYNA), and may vary depending on specifications when the actual product is manufactured."
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-50" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/2024/images/products/light_component-image.png",
          alt: "lightweight",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }