/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  cartList: JSON.parse(localStorage.getItem('cartItem')) || [],
  orderList: JSON.parse(localStorage.getItem('orderItem')) || [],
  memberInfo: JSON.parse(localStorage.getItem('orderMemberInfo')) || {}
}
