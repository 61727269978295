/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const FIND = 'FIND';

export default {
  FIND,
};
