<template>
  <div class="hder-section sch-ui">
    <div class="hd-sec-cont sch-area">
      <div class="sch-top">
        <div class="hd-close">
          <button type="button" class="btn-hd-close" data-ui="sch-ui"><span class="blind">Close Button</span></button>
        </div>
        <div class="w-con">
          <div class="sch-frm">
            <label class="blind" for="inpHdSch">Input Search Keyword</label>
            <input type="text"
                    class="ipt-hd-sch wrd-on"
                    id="inpHdSch"
                    :placeholder="$t('search.enter_search_word')"
                    v-model="searchWord"
                    v-on:keyup.enter="doSearch"/>
            <button type="button" class="btn-hd-sch" v-on:click="doSearch">
              <span class="blind">Search Button</span>
            </button>
          </div>
        </div>
      </div>

      <!-- 처음 header search icon 클릭 했을때 Recent Searches 표시되어야 합니다 -->
      <!-- noSearchResult 화면이 나올때는 Recent Searches & Popular Searches 숨겨야 합니다  -->
      <div class="sch-btm" v-if="!(showSearchResult && !noSearchResult()) && searchWords.length > 0">
        <div class="w-con">
          <div class="sch-recent">
            <p class="sch-rcnt-tit">{{ $t('search.recent_search') }}</p>
            <ul class="sch-rcnt-list">
              <li v-for="sw in searchWords">
                <span class="sch-rcnt-keyw" v-on:click="searchWord=sw;doSearch()">{{sw}}</span>
                <button type="button" class="btn-rcnt-del" v-on:click="deleteRecent(sw)">delete keyword button</button>
              </li>
            </ul>
          </div>
          <div class="sch-recent" v-if="popularWords.length > 0">
            <p class="sch-rcnt-tit">{{ $t('search.popular_search') }}</p>
            <ul class="sch-rcnt-list">
              <li v-for="sw in popularWords" v-on:click="searchWord=sw;doSearch()">
                <span class="sch-rcnt-keyw">{{sw}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sch-btm" v-if="showSearchResult && noSearchResult()">
        <div class="w-con">
          <div class="sch-no">
            <p class="sch-no-info">
              {{ $t('search.no_search_result') }} "<em class="sch-keyw color-red">{{searchWord}}</em>" <br/>
              <span>{{ $t('search.no_search_guide') }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="sch-btm" v-if="showSearchResult && !noSearchResult()">
        <div class="w-con">
          <div class="sch-res-list">
            <div class="sch-res-tab">
              <ul>
                <template v-if="searchResult['hflor'] && searchResult['hflor'].totalCount > 0">
                  <li class="btn-tab on" data-tab="HSRT01" ><a href="javascript:void(0)">HFLOR</a></li>
                  <li class="btn-tab" data-tab="HSRT02" v-if="showViatera()"><a href="javascript:void(0)">VIATERA</a></li>
                  <li class="btn-tab" data-tab="HSRT03"><a href="javascript:void(0)">HIMACS</a></li>
                </template>
                <template v-else-if="searchResult['viatera'] && searchResult['viatera'].totalCount > 0 &&  showViatera()" >
                  <li class="btn-tab on" data-tab="HSRT02"><a href="javascript:void(0)">VIATERA</a></li>
                  <li class="btn-tab" data-tab="HSRT01"><a href="javascript:void(0)">HFLOR</a></li>
                  <li class="btn-tab" data-tab="HSRT03"><a href="javascript:void(0)">HIMACS</a></li>
                </template>
                <template v-else-if="searchResult['himacs'] && searchResult['himacs'].totalCount > 0">
                  <li class="btn-tab on" data-tab="HSRT03"><a href="javascript:void(0)">HIMACS</a></li>
                  <li class="btn-tab" data-tab="HSRT01"><a href="javascript:void(0)">HFLOR</a></li>
                  <li class="btn-tab" data-tab="HSRT02"  v-if="showViatera()"><a href="javascript:void(0)">VIATERA</a></li>
                </template>
              </ul>
            </div>
            <div class="sch-res-data search-product-big-box">
              <template v-if="searchResult['hflor'] && searchResult['hflor'].totalCount > 0">
                <HflorCompoent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['hflor']" v-bind:isOn="true"></HflorCompoent>
                <HimacsCompoent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['himacs']" v-bind:isOn="false"></HimacsCompoent>
                <ViateraCompoent  v-if="showViatera()" @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['viatera']" v-bind:isOn="false"></ViateraCompoent>
              </template>
              <template v-else-if="searchResult['viatera'] && searchResult['viatera'].totalCount > 0 && showViatera()">
                <ViateraCompoent  @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['viatera']" v-bind:isOn="true"></ViateraCompoent>
                <HflorCompoent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['hflor']" v-bind:isOn="false"></HflorCompoent>
                <HimacsCompoent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['himacs']" v-bind:isOn="false"></HimacsCompoent>
              </template>
              <template v-else-if="searchResult['himacs'] && searchResult['himacs'].totalCount > 0">
                <HimacsCompoent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['himacs']" v-bind:isOn="true"></HimacsCompoent>
                <HflorCompoent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['hflor']" v-bind:isOn="false"></HflorCompoent>
                <ViateraCompoent  v-if="showViatera()" @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['viatera']" v-bind:isOn="false"></ViateraCompoent>
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SearchService from "@/server/search.service";
import { mapState } from 'vuex'
import HimacsCompoent from './humacs'
import ViateraCompoent from './viatera'
import HflorCompoent from './hflor'
export default {
  name: 'SearchPopComponent',
  components: {
    HimacsCompoent,
    ViateraCompoent,
    HflorCompoent
  },
  data () {
    return {
      searchWord: '',
      showSearchResult: false,
      searchResult: {},
      popularWords: []
    }
  },
  computed: {
    ...mapState({
      searchWords: state => state.search.searchWords
    }),
  },
  async mounted () {
    let _this = this;
    let observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutationRecord) {
        if($(mutationRecord.target).css('display') === 'none')
        {
          _this.showSearchResult = false;
        }
      });
    });

    let target = $(".hd-sec-cont.sch-area")[0];
    observer.observe(target, { attributes : true, attributeFilter : ['style'] });

    $( "#inpHdSch" ).autocomplete({
      source: async function (request, response) {
        let res = await SearchService.suggestion(request.term);
        response(res.data);
      },
      minLength: 2
    });

    let res = await SearchService.popular();
    this.popularWords = res.data
  },
  methods: {
    showViatera() {
      let country = this.$countryCode.substring(1);
      return country == 'GL' || country == 'US';
    },
    async doSearch () {
      if(this.searchWord == '')
      {
        alert('Please enter some search keyword')
        return;
      }
      location.href = 'search/product/'+this.searchWord;
      // let res = await SearchService.product({
      //   s: this.searchWord,
      //   r: this.$countryCode.substring(1),
      //   pageIndex: 1,
      //   pageSize: 4,
      // });
      // this.searchResult = res.data;
      // this.showSearchResult = true;
      //
      // if(!this.showViatera())
      // {
      //   this.searchResult['viatera'] = { totalCount: 0, list: []};
      // }
      // // GTM - search result
      // let totalCount = 0
      // for (const search in this.searchResult) {
      //   totalCount += this.searchResult[search].totalCount
      // }
      // if (typeof (dataLayer) !== 'undefined') {
      //   dataLayer.push({
      //     event: 'searchComp',
      //     search_keyword: this.searchWord,
      //     search_result_cnt: totalCount
      //   })
      // }
      //
      // this.$store.dispatch('search/addSearchWord', {searchWord: this.searchWord, vm: this});
    },
    deleteRecent (searchWord) {
      this.$store.dispatch('search/deleteSearchWord', {searchWord: searchWord, vm: this});
    },
    noSearchResult(){
      let totalCount = 0;
      for (const search in this.searchResult) {
        totalCount += this.searchResult[search].totalCount;
      }

      return totalCount === 0;
    },
    gotoSearchPage(searchWord){
      document.location.href = `search/product/${searchWord}`;

      $('.btn-hd-close').trigger("click");
    },
    gotoDetail(sr, type) {
      document.location.href = 'products/' + type + '/' + sr.content.title + '/' + sr.content.id;
    },
    gotoHFlorDetail(sr) {
      document.location.href = 'products/hflor/' + sr.parents[1].name + '/' + sr.content.title + '/' + sr.content.id;
    }
  }
}
</script>
<style>
.ui-autocomplete {
  z-index: 100000 !important;
}
</style>
