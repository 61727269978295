import request from './request'
const urlPrefix = ''

const UserService = {
  login (data) {
    return request({
      url: `${urlPrefix}/login`,
      method: 'post',
      data
    })
  },
  login_eu (data) {
    return request({
      url: `${urlPrefix}/login_eu`,
      method: 'post',
      data
    })
  },
  logout (postData) {
    return request({
      url: `${urlPrefix}/logout`,
      method: 'post',
      data: postData
    })
  },
  userInfo () {
    return request({
      url: `${urlPrefix}/loginStatus`,
      method: 'get'
    })
  },
  userInfoEU () {
    return request({
      url: `${urlPrefix}/loginStatusEU`,
      method: 'get'
    })
  },
  exist (memId) {
    return request({
      url: `${urlPrefix}/member/exist?memId=${memId}`,
      method: 'get'
    })
  },
  disable (username) {
    return request({
      url: `${urlPrefix}/user/disable`,
      method: 'post',
      data: username
    })
  },
  disableEU (email) {
    return request({
      url: `${urlPrefix}/user_eu/disable`,
      method: 'post',
      data: email
    })
  },
  getCount (username) {
    return request({
      url: `${urlPrefix}/user/getCount`,
      method: 'post',
      data: username
    })
  },
  setCount (data) {
    return request({
      url: `${urlPrefix}/user/setCount`,
      method: 'post',
      data: data
    })
  },
  getCountEU (email) {
    return request({
      url: `${urlPrefix}/user_eu/getCount`,
      method: 'post',
      data: email
    })
  },
  setCountEU (data) {
    return request({
      url: `${urlPrefix}/user_eu/setCount`,
      method: 'post',
      data: data
    })
  },
  save (user, code) {
    return request({
      url: `${urlPrefix}/user/save?code=${code}`,
      method: 'post',
      data: user
    })
  },
  saveEU (user) {
    return request({
      url: `${urlPrefix}/user_eu/saveEU`,
      method: 'post',
      data: user
    })
  },
  resend (user,code) {
    return request({
      url: `${urlPrefix}/user/resend?code=${code}`,
      method: 'post',
      data: user
    })
  },
  resetPassWord (password, token) {
    return request({
      url: `${urlPrefix}/user/resetPassWord?password=${password}&token=${token}`,
      method: 'post'
    })
  },
  resetPassWordEU (password, token) {
    return request({
      url: `${urlPrefix}/user_eu/resetPassWord?password=${password}&token=${token}`,
      method: 'post'
    })
  },
  sendNewRequestEmailEU (email) {
    return request({
      url: `${urlPrefix}/user_eu/sendNewRequestEmailEU?email=${email}`,
      method: 'post'
    })
  },
  saveMemberDealer(dealerInfo){
    return request({
      url: `${urlPrefix}/user/saveMemberDealer`,
      method: 'post',
      data: dealerInfo
    })
  },
  findId(firstNm, lastNm, email){
    return request({
      url: `${urlPrefix}/user/findId?firstNm=${firstNm}&lastNm=${lastNm}&email=${email}`,
      method: 'get',
    })
  },
  findEmailEU(lastNm, countryCd, email){
    return request({
      url: `${urlPrefix}/user_eu/findEmailEU?lastNm=${lastNm}&countryCd=${countryCd}&email=${email}`,
      method: 'get',
    })
  },
  sendEmailPassExpired(email){
    return request({
      url: `${urlPrefix}/user_eu/sendEmailPassExpired?email=${email}`,
      method: 'get',
    })
  },
  sendEmailWarranty(email, whereInstalled, data){
    return request({
      url: `${urlPrefix}/user/sendEmailWarranty?email=${email}&whereInstalled=${whereInstalled}`,
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default UserService
