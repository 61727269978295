<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content">
      <!-- location -->
      <!-- //location -->

      <!-- container  -->
      <div class="content himacPorta_veiw">
        <div class="location">
          <ul>
            <li class="ico-home">{{ $t("contactus.text.home") }}</li>
            <li>{{ $t("contactus.text.support") }}</li>
            <li>{{ $t("contactus.text.contactus") }}</li>
            <li>{{ $t("contactus.text.completed") }}</li>
          </ul>
        </div>
        <div class="container equest_content ta-c">
          <div class="w-con-02">
            <p class="mb-64"></p>
            <p class="tt-1 mb-64 tac inner">{{ $t("contactus.text.compTitle") }}</p>
            <p class="tt-sub mb-88" v-html="$t('contactus.text.compText')"></p>
            <div class="more-box ">
              <button type="button" class="btn" onclick="location.href='index/'" style="width:inherit">
                <span>{{ $t("contactus.text.goToMain") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- //MODAL : FindId  OK-->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import memberInfoService from '@/server/myPage.service'
import UserService from '@/server/user.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

// test
export default {
  name: 'app',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      mail: '',
      gotEmail: '',
      submitChk: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
      emailsDontMatch: false
    }
  },
  async created () {

  },
  async mounted () {

  },
  methods: {

  }
}
</script>
<style scoped>
.mypg-loginJoin .btn-mypg {
  text-align: center;
  padding-top: 60px;
  position: initial;
}

.noti-02 {
  left: 220px;
  top: 10px;
}

.btn-darkG.btn-myGo:after {
  width: 27px;
  background: url(https://img.lxhausys.com/public/images/arrw_right_15.png) no-repeat center center;
}

.inpt-mypg ul li span {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #a0a0a0;
}
.noti-02 {
  left:0;
  top: 0;
}
.inpt-mypg {
  width: 90%;
  margin: 0 auto;
}

/* Mobile */

@media only screen and (max-width: 480px) {
  .mypg-loginJoin .inpt-mypg ul li label {
    width: 30%;
  }
}

@media only screen and (max-width: 480px){
  .mypg-loginJoin .inpt-mypg ul li span {
    width: 70%;
  }
}

@media only screen and (max-width: 480px){
  .mypg-loginJoin .btn-mypg button {
    width: 50%;
  }
}

@media only screen and (max-width: 480px){
  .btn-darkG {
    height: 100%;
    padding: 10px 40px 10px 20px;
  }
}

@media only screen and (max-width: 480px){
  .btn-darkG span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px){
  h3.tit-04 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px){
  .mypg-loginJoin .inpt-mypg {
    border-top: 2px solid;
  }
}

@media only screen and (max-width: 480px){
  .mypg-loginJoin .inpt-mypg ul{
    padding-top: 10px;;
  }
}

@media only screen and (max-width: 480px){
  .content {
    position: relative;
    margin-top: 85px;
    width: 100%;
    min-height: auto;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 480px){
  .w-con-02 {
    padding-top: 19%;
  }
}

@media only screen and (max-width: 480px){
  .noti-02 {
    left:0;
    top: 0;
  }
}

@media only screen and (max-width: 480px){
  .inpt-mypg {
    width: 90%;
    margin: 0 auto;
  }
}

/* 모달 팝업 */
.pop-modal-s1 .popup .tbl-input colgroup{display:none}
.pop-modal-s1 .popup .tbl-input tr th{width:30%}
.pop-modal-s1 .popup .tbl-input tr td{width:70%}
.pop-modal-s1 .prod-interest > li{width:50%}

.pop-modal-s1 .popup > div{width:750px !important;padding:50px}
.pop-modal-s1 .popup .tit-box{position:relative;padding:0 0 25px;border-bottom:1px solid #cccccc;}
.pop-modal-s1 .popup .tit-box > h4{line-height:1; font-size:16px;color:#000}
.pop-modal-s1 .popup .btn-close {position:absolute;top:-35px;right:0px;width:17px;height:17px;background:url(https://img.lxhausys.com/public/images/btn_close.png) center no-repeat;font-size:0;}
.pop-modal-s1 .popup .frm-box .tbl-input .cell-noti p{color:#ff0000;padding:7px 0;}
.pop-modal-s1 .popup .inp-chk,
.pop-modal-s1 .popup .noti-02{margin-top:10px}
.pop-modal-s1 .popup .inp-chk input+span a{color:#a72b2a}
.pop-modal-s1 .popup .frm-box .tbl-input{border:0 !important}
.pop-modal-s1 .popup .frm-box .tbl-input tr th label span{font-size:15px;color:#555}
.pop-modal-s1 .popup .frm-box .tbl-input tr:last-child td{height:auto;padding-bottom:10px;border-bottom:0}
.pop-modal-s1 .prod-interest{font-size:0}
.pop-modal-s1 .prod-interest > li{display:inline-block;width:calc(100% / 3)}
.pop-modal-s1 .popup .frm-box .tbl-input{border-bottom:1px solid #a0a0a0}
.pop-modal-s1 .popup .ta-r .noti-02{display:inline-block}
.pop-modal-s1 .popup .ta-r .noti-02:before{position:relative;margin-right:5px}
.pop-modal-s1 .popup .cont-box{padding:45px 80px;border-bottom:1px solid #a0a0a0}
.pop-modal-s1 .popup .cont-box .ico-news-res{font-size:24px;color:#111}
.pop-modal-s1 .popup .cont-box .ico-news-res:before{display:block;width:80px;height:80px;margin:0 auto 30px;background:url(https://img.lxhausys.com/public/images/ico_news_result.png) no-repeat center center #f6f6f6;border-radius:50%;content:''}
.pop-modal-s1 .popup .cont-box p{line-height:1.5;font-size:15px;color:#555;margin-top:25px}
.pop-modal-s1 .popup .cont-box p a{display:inline-block;width:auto;font-size:inherit;color:#a72b2a;border-bottom:1px solid #a72b2a;}

.modal-con .check{
  width: 15%;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
}
@media only screen and (max-width: 480px){
  .modal-con .check {
    width: 25%;
    margin-top: 20px;
    width: 400px;
    margin: 0 auto;
  }
}

.modal-con .msg01 {
  text-align: center;
  margin: 10px 10px 50px 10px;
  width: 400px;
  margin: 0 auto;
  padding: 30px 0 30px 0;
}
@media only screen and (max-width: 480px){
  .modal-con .msg01 {
    margin: 10px 0;
  }
}

.modal-con .msg01 .txt01{
  font-family: NotoSansCJKkr;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #555;
  letter-spacing: -1px;
}

@media only screen and (max-width: 480px){
  .modal-con .msg01 .txt01{
    font-size: 0.9em;
    line-height: normal
  ;
  }
}
.modal-con .msg01 .txt01 .txt01-bold{
  font-weight: bold;
  color: #000;
  padding-left: 7px;;
}
.modal-con .msg01 .txt02{
  width: 209px;
  height: 16px;
  font-family: NotoSansCJKkr;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
@media only screen and (max-width: 480px){
  .modal-con .msg01 .txt02 {
    font-size: 1.2em;
    font-weight: bold;
  }
}
.txt03{
  font-size: 20px;
  text-align: center;
}
@media only screen and (max-width: 480px){
  .txt03 {
    font-size: 0.7em;
  }
}

.layer-popup .popup > div, .layer-view .popup > div {
  width: 600px !important;
  overflow: hidden;
}

.layer-popup .popup{
  overflow: initial;
}

.pop-modal-s1 .popup > div {
  width: 600px !important;
  padding: 25px 40px 70px;
}
@media only screen and (max-width: 480px){
  .pop-modal-s1 .popup > div {
    width: 100% !important;
    padding: 25px 25px 50px;
  }
}

@media only screen and (max-width: 480px){
  h3.tit-03 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 480px){
  .mt-type{
    font-size: 0.8em;
    width: 85%;
    margin: 0 auto;
  }
}

.layer-popup{
  background: rgba(0,0,0,0.8) !important;
}

.modalBtn-01{
  margin-top: 30px;
  font-size: 0;
  text-align: center;
}
.modalBtn-01 button {
  width: auto;
  height: 48px;
  padding: 0 26px 0 31px;
  background: #000;
  border-radius: 25px;
}
.modalBtn-01 span {
  display: block;
  padding-right: 50px;
  background: url(https://img.lxhausys.com/public/images/arrw_right_15.png) right center no-repeat;
  font-size: 16px;
  color: #fefefe;
}
@media only screen and (max-width: 480px){
  .modalBtn-01 span {
    font-size: 0.9em;
  }
}

/* style.css 가로길이 px 정해져잇슴*/
.btn-type-large {
  width: auto !important;
}
</style>
