var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "ESG Management System" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("div", { staticClass: "bod-image-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/esg_strategy_system/bod_image.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausys established the ESG Committee in 2022 and the ESG Council and ESG Visions and Strategy System in 2023,\nlaying the foundation of ESG management for the company.\nGoing forward, we will internalize and enhance our ESG management from a long-term perspective by carrying out\nintegrated management of the existing ESG activities and performances based on solid ESG leadership and systems.",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "ESG Management System" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("div", { staticClass: "bod-image-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/esg_strategy_system/bod_image.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausysは、2022年にESG委員会、2023年にESG協議会とESGビジョン・戦略システムを設立し、\n        ESG経営の基盤を整えました。今後は、強固なESGリーダーシップとシステムに基づき、既存のESG活動と実績を統合管理することで、\n        長期的な視点でESG経営を内在化し、強化していきます。",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "ESG Management System" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("div", { staticClass: "bod-image-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/esg_strategy_system/bod_image.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "В 2022 году LX Hausys создала Комитет ESG, а в 2023 году - Совет ESG и Систему видения и стратегии ESG,\nзаложив основу управления в компании.\nВ дальнейшем мы будем внедрять и совершенствовать наше управление ESG в долгосрочной перспективе, осуществляя\nкомплексное управления существующими мероприятиями и результатами ESG на основе надежного руководства и систем ESG.",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "ESG Management System" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("div", { staticClass: "bod-image-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/esg_strategy_system/bod_image.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausys于2022年成立ESG委员会，2023年成立ESG理事会并构建ESG愿景与战略体系，为公司ESG管理奠定了基础。\nLX Hausys将通过在坚实的ESG领导力和系统的基础上对现有的ESG活动和绩效进行综合管理，从长远的角度内化和加强ESG经营。",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }