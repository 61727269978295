var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content content-finder" }, [
        _c(
          "div",
          {
            staticClass: "location",
            class: { type02: _vm.nationCode === "_GL" },
          },
          [
            _c("ul", [
              _c(
                "li",
                {
                  directives: [
                    { name: "hover-cursor", rawName: "v-hover-cursor" },
                  ],
                  staticClass: "ico-home",
                  on: {
                    click: function ($event) {
                      _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("PCD.text.home")))]
              ),
              _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
              _c("li", [_vm._v("ALL COLORS")]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "container prod-fd-view container_pf viatera-pf" },
          [
            _vm.nationCode === "_US"
              ? _c("h1", { staticClass: "title_hidden" }, [
                  _vm._v("All Colors"),
                ])
              : _vm._e(),
            _vm.pcdContentAttribute.main_use_yn === "true" &&
            _vm.nationCode === "_GL"
              ? _c("div", { staticClass: "visual visual-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass: "visual-image full_width center",
                      style: {
                        backgroundImage: `url('${_vm.$imagePath}${_vm.pcdContentAttribute.main_img}')`,
                      },
                    },
                    [
                      _c("div", { staticClass: "inner_width" }, [
                        _c("div", { staticClass: "txt-box" }, [
                          _c("h1", [
                            _c("div", { staticClass: "title" }, [
                              _vm.visualImageLoadError
                                ? _c("span", { staticClass: "txt" }, [
                                    _vm._v(
                                      _vm._s(_vm.pcdContentAttribute.main_title)
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm.visualImageLoadError
                                ? _c("img", {
                                    staticClass: "visual_logo",
                                    attrs: {
                                      src:
                                        _vm.$imagePath +
                                        "/2024/images/main/logo/" +
                                        _vm.pcdMenuType.toLowerCase() +
                                        ".svg",
                                      alt: "",
                                    },
                                    on: {
                                      error: function ($event) {
                                        _vm.visualImageLoadError = true
                                      },
                                      load: function ($event) {
                                        _vm.visualImageLoadError = false
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("p", { staticClass: "txt-02" }, [
                              _vm._v(_vm._s(_vm.pcdContentAttribute.main_desc)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "drop-list inner" }, [
              _c(
                "div",
                { staticClass: "w-con-02" },
                [
                  _c("div", { staticClass: "gallery" }, [
                    _c("div", { staticClass: "search-box" }, [
                      _c("div", { staticClass: "filter_area" }, [
                        _c("div", { staticClass: "filter_wrap" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn_filter",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFilterBtn()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon icon_filter" }),
                              _c("span", { staticClass: "txt" }, [
                                _vm._v("Filter"),
                              ]),
                              _c("span", {
                                staticClass: "icon icon_arrow_down",
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "search" }, [
                          _c("input", {
                            attrs: {
                              type: "text",
                              id: "textSearch",
                              placeholder: this.$t("PF.text.searchText"),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.textSearch($event)
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "icon_search",
                              attrs: { type: "button", id: "searchBtn" },
                              on: {
                                click: function ($event) {
                                  return _vm.textSearchDel()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PF.button.search")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "filter_pop" }, [
                          _c("div", { staticClass: "filter_tit" }, [
                            _c("p", [_vm._v("FILTER BY")]),
                            _c("button", {
                              staticClass: "btn_close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.close_popup()
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "filter_top" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._m(0),
                                _c(
                                  "div",
                                  { staticClass: "filter_checkbox_cont" },
                                  [
                                    _c("div", { staticClass: "checkbox_box" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "checkbox_more no_line",
                                        },
                                        _vm._l(
                                          _vm.filteredBrands,
                                          function (brand) {
                                            return _c("li", { key: brand.id }, [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.selectedBrands,
                                                    expression:
                                                      "selectedBrands",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "brand_" + brand.id,
                                                },
                                                domProps: {
                                                  value: brand.id,
                                                  checked: Array.isArray(
                                                    _vm.selectedBrands
                                                  )
                                                    ? _vm._i(
                                                        _vm.selectedBrands,
                                                        brand.id
                                                      ) > -1
                                                    : _vm.selectedBrands,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.selectedBrands,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = brand.id,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.selectedBrands =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.selectedBrands =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.selectedBrands = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "brand_" + brand.id,
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "round",
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [_vm._v(_vm._s(brand.name))]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("li", [
                                _vm._m(1),
                                _c(
                                  "div",
                                  { staticClass: "filter_checkbox_cont" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "checkbox_box color_round",
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "checkbox_more no_line viewall_list item_10",
                                          },
                                          _vm._l(
                                            _vm.uniqueColors,
                                            function (color) {
                                              return _c(
                                                "li",
                                                { key: color.value },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.selectedHues,
                                                        expression:
                                                          "selectedHues",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "hue_" + color.value,
                                                      name: "color",
                                                    },
                                                    domProps: {
                                                      value: color.value,
                                                      checked: Array.isArray(
                                                        _vm.selectedHues
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedHues,
                                                            color.value
                                                          ) > -1
                                                        : _vm.selectedHues,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.selectedHues,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = color.value,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedHues =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedHues =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.selectedHues = $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: `hue_${color.value}`,
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "round",
                                                        style: {
                                                          backgroundColor:
                                                            "#" + color.value,
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        { staticClass: "text" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(color.text)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "filter_bottom" }, [
                            _c("div", { staticClass: "btn_box" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn_wh",
                                  on: { click: _vm.filterClearAll },
                                },
                                [_vm._v("Clear All")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn_bk",
                                  on: { click: _vm.applyFilter },
                                },
                                [_vm._v("Apply")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "sort_area" }, [
                        _vm.selectedBrands.length > 0 ||
                        _vm.selectedHues.length > 0
                          ? _c("div", { staticClass: "select-view" }, [
                              _c(
                                "ul",
                                { staticClass: "list-filter-key" },
                                [
                                  _vm._l(_vm.selectedBrands, function (filter) {
                                    return _c(
                                      "li",
                                      {
                                        staticClass: "sel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFilterBrand(filter)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "brand" }, [
                                          _vm._v(
                                            _vm._s(_vm.getBrandName(filter))
                                          ),
                                        ]),
                                        _c("button", {
                                          attrs: { type: "button" },
                                        }),
                                      ]
                                    )
                                  }),
                                  _vm._l(_vm.selectedHues, function (filter) {
                                    return _c(
                                      "li",
                                      {
                                        staticClass: "sel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFilterHue(filter)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "color" }, [
                                          _vm._v(
                                            _vm._s(_vm.getColorName(filter))
                                          ),
                                        ]),
                                        _c("button", {
                                          attrs: { type: "button" },
                                        }),
                                      ]
                                    )
                                  }),
                                  _c("li", { staticClass: "sel del_all" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-all-delete",
                                        attrs: { type: "button" },
                                        on: { click: _vm.removeFilterAll },
                                      },
                                      [_c("span", [_vm._v("clear all")])]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "btns" }, [
                          _c("ul", [
                            _c("li", { staticClass: "sel-box" }, [
                              _vm._m(2),
                              _c("div", { staticClass: "sel-list" }, [
                                _c("ul", { staticClass: "box" }, [
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Products A to Z",
                                              "asc",
                                              "title"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.products_a_to_z"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Products Z to A",
                                              "desc",
                                              "title"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.products_z_to_a"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Most Recent",
                                              "desc"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.new_products_first"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._m(3),
                      ]),
                      _c("div", { staticClass: "count_area" }, [
                        _c("div", { staticClass: "filter-cnt" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.contentLength)),
                          ]),
                          _c("span", [_vm._v(" ")]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v("Results"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.isInitialLoad
                    ? [_vm._m(4)]
                    : !_vm.isLoading && _vm.contentList.length === 0
                    ? [_vm._m(5)]
                    : [
                        _c("div", { staticClass: "list-type-view row-04" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.contentList, function (item, index) {
                              return _c("li", { key: index }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.getProductDetailUrl(item),
                                      "data-ec-product":
                                        _vm.makeDataLayerForm(item),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "img-box" }, [
                                      item.attribute.isNew == "true"
                                        ? _c(
                                            "span",
                                            { staticClass: "tag h30 new" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.text.new"))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "image_name_and_tag" },
                                        [
                                          [
                                            _vm
                                              .getProductDetailUrl(item)
                                              .includes("viatera")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tag h22 viatera",
                                                  },
                                                  [_vm._v("VIATERA")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("himacs")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tag h22 himacs",
                                                  },
                                                  [_vm._v("HIMACS")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("hflor")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tag h22 hflor",
                                                  },
                                                  [_vm._v("HFLOR")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("teracanto")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tag h22 teracanto",
                                                  },
                                                  [_vm._v("TERACANTO")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("benif")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tag h22 benif",
                                                  },
                                                  [_vm._v("BENIF")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("deco")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "tag h22 etc",
                                                  },
                                                  [_vm._v("DECO FOIL")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("exterior")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "tag h22 etc",
                                                  },
                                                  [_vm._v("EXTERIOR FOIL")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("borte")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "tag h22 etc",
                                                  },
                                                  [_vm._v("BORTE")]
                                                )
                                              : _vm
                                                  .getProductDetailUrl(item)
                                                  .includes("vizuon")
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "tag h22 etc",
                                                  },
                                                  [_vm._v("VIZUON")]
                                                )
                                              : _vm._e(),
                                          ],
                                          _c(
                                            "span",
                                            { staticClass: "image_name" },
                                            [_vm._v(_vm._s(item.content.title))]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "icon_tag_wrap" },
                                        [
                                          item.attribute.fire_resistance ===
                                          "001"
                                            ? _c(
                                                "i",
                                                { staticClass: "icon-no-fire" },
                                                [
                                                  _c("span", [
                                                    _vm._v("no-fire"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            item.attribute.pf_thumb,
                                          alt: item.attribute.pf_thumb,
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "view" }, [
                                  _vm.nationCode === "_US" && _vm.canOrder(item)
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon_cart",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                item.content.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("PCD.text.cart"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _vm.isLoading
                          ? _c("div", { staticClass: "loading_area" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/public/images/icon/loading.gif",
                                  alt: "loading",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "layer-popup layer-alert" }, [
              _c("div", { staticClass: "popup" }, [
                _c("div", { staticClass: "pop-alert" }, [
                  _c("div", [
                    _c("div", { staticClass: "tit-box" }, [
                      _c("p", { staticClass: "ctt ctt-t4 artTit" }, [
                        _vm._v(_vm._s(_vm.$t("PF.popup.your_compare_is_full"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "txt-box" }, [
                      _vm._m(6),
                      _vm._m(7),
                      _c("div", { staticClass: "btn-box" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button", id: "replace" },
                            on: {
                              click: function ($event) {
                                return _vm.replace()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.popup.replace"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(" close ")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
                staticStyle: { display: "none" },
                attrs: { id: "layer-search-box" },
              },
              [
                _c("div", { staticClass: "popup" }, [
                  _c("div", { staticClass: "scr-y" }, [
                    _c("div", { staticClass: "drop-list" }, [
                      _c("p", { staticClass: "tt-2" }, [_vm._v("Filter By")]),
                      _c("button", {
                        staticClass: "filter-clos",
                        on: {
                          click: function ($event) {
                            return _vm.closeSearchPopup()
                          },
                        },
                      }),
                      _c("ul", [
                        _c("li", { staticClass: "on" }, [
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-color",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.color")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("BRAND")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("COLOR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn-sel", attrs: { type: "button" } }, [
      _c("span", [_vm._v("Most Recent")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gallery_layout_btns" }, [
      _c(
        "button",
        { staticClass: "layout_grid on", attrs: { type: "button" } },
        [_c("span", { staticClass: "icon icon_grid" })]
      ),
      _c("button", { staticClass: "layout_list", attrs: { type: "button" } }, [
        _c("span", { staticClass: "icon icon_list" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_area" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/public/images/icon/loading.gif",
          alt: "loading",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "grid-box" }, [
      _c("div", { staticClass: "no_result" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/public/images/support/no_result.png",
            alt: "noresult",
          },
        }),
        _c("p", [_vm._v("No Result found")]),
        _c("span", [
          _vm._v(
            "If you do not find what you are looking for, please try different search terms or filters."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgwrap" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/images/svg_i\n                    con/icon_alert_pop_check.svg",
          alt: "Your comparator is full",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "txt" }, [
      _c("p", [
        _vm._v(" Do you want to replace the first Products "),
        _c("br"),
        _vm._v(" you added by the one you selected? "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }