<template>
  <div class="component-part-area">
    <ProductSectionHeader
      title="Exterior & Engine Part"
    />

    <div class="mt-50">
      <img src="https://img.lxhausys.com/2024/images/products/auto_part-image.png" alt="autopart">
    </div>

    <ProductComponentGroup
      class="mt-100 mx-30"
      :left="leftComponentInfo"
      :right="rightComponentInfo"
    >
      <template #left-header>
        <div class="part-section-head-area mb-50">
          <div class="icon-area">
            <img src="images/automotive/component/exterior-part-icon-1.png" alt="icon">
          </div>

          <div class="text-area">
            <span>Exterior Part</span>
          </div>
        </div>
      </template>

      <template #right-header>
        <div class="part-section-head-area mb-50">
          <div class="icon-area">
            <img src="images/automotive/component/exterior-part-icon-2.png" alt="icon">
          </div>

          <div class="text-area">
            <span>Engine Part</span>
          </div>
        </div>
      </template>
    </ProductComponentGroup>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductComponentGroup from '@/components/product/ProductComponentGroup'

export default {
  components: {
    ProductSectionHeader,
    ProductComponentGroup
  },

  data: () => ({
    leftComponentInfo: [
      {
        imageSrc: 'images/automotive/component/component-01.png',
        title: 'Structural Bumper System',
        description: [
          '- Front, rear bumper assembly',
          '- Observance of Pedestrian Safety',
          '- Compliance with IIHS, R-Car'
        ]
      },
      {
        imageSrc: 'images/automotive/component/component-03.png',
        title: 'Side Sill',
        description: [
          '- Injection molding + Painting',
          '- Forming technology (Electric Injection molding)'
        ]
      }
    ],
    rightComponentInfo: [
      {
        imageSrc: 'images/automotive/component/component-02.png',
        title: 'Cylinder Head Cover',
        description: [
          '- Replacing Aluminum with Plastic',
          '- Prevention of oil leakage',
          '- Oil-water separating system',
          '- Observance of Euro5 legislation'
        ]
      },
      {
        imageSrc: 'images/automotive/component/component-04.png',
        title: 'Air Intake Manifold',
        description: [
          '- Replacing Aluminum with Plastic',
          '- Variable Induction System'
        ]
      },
      {
        imageSrc: 'images/automotive/component/component-05.png',
        title: 'Transmission Oil Pan',
        description: [
          '- Replacing Plastic with Steel',
          '- Prevention of oil leakage',
          '- System for circulating the oil'
        ]
      }
    ]
  })
}
</script>

<style scoped>
.component-part-area {
  display: flex;
  flex-direction: column;
}

.component-part-area .part-section-head-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component-part-area .part-section-head-area .icon-area {
  width: 75px;
  height: 75px;
  margin-right: 30px;
}

.component-part-area .part-section-head-area .icon-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.component-part-area .part-section-head-area .text-area span {
  color: #000;
  font-family: 'NotoSans' , sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 146.667% */
}
</style>
