<template>
  <div class="lca">
    <SocialResponsibilityBorderTitle
      :title="title"
    />
    <div class="lca-content">
      <div class="lca-text-card-area">
        <div class="lca-text-card"
          v-for="(item, index) in textCard"
          :key="index"
        >
          <pre class="number">{{ item.number }}</pre>
          <pre class="text">{{ item.text }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialResponsibilityBorderTitle from '../../socialResponsibility/components/SocialResponsibilityBorderTitle.vue'

export default {
  components: {
    SocialResponsibilityBorderTitle
  },
  props: {
    title: {
      type: String
    },
    textCard: {
      type: Array
    }
  }
}
</script>

<style scoped>

.lca .lca-content {
  margin-top: 102px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../../../public/images/aboutus/sustainability/sustainable_products/lca-arrow.png");
  background-repeat: no-repeat;
  background-position: center center;
  height: 649px;
}

@media (max-width: 768px) {
  .lca .lca-content {
    margin-top: 0px;
    margin-bottom: 0px;
    background-size: 45%;
  }
}

.lca .lca-content .lca-text-card-area {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px 95px;
}
.lca .lca-content .lca-text-card-area .lca-text-card {
  border: 1px solid #E6E6E6;
  background: rgba(255, 255, 255, 0.50);
  display: flex;
  padding: 20px 30px;
  align-items: center;
  gap: 30px;
}

.lca .lca-content .lca-text-card-area .lca-text-card .number {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lca .lca-content .lca-text-card-area .lca-text-card .text {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .lca .lca-content .lca-text-card-area .lca-text-card .number {
    font-size: 1.2916666667rem;
  }
  .lca .lca-content .lca-text-card-area .lca-text-card .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
</style>
