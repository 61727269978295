<template>
    <div class="financial-statement-unit-area">
        <div class="table-unit">{{ content }}</div>
    </div>
</template>

<script>

export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}

</script>

<style scoped>
.financial-statement-unit-area {
    display: flex;
    width: 1440px;
    flex-direction: column;
    margin: 0 auto;
}

.table-unit {
    color: #000;
    text-align: right;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 54px;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
  .financial-statement-unit-area {
    width: 100%;
  }

  .table-unit {
    font-size: 0.7rem;
  }
}

</style>
