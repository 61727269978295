var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "micro-page", attrs: { id: "wrap" } },
    [
      _c(
        "header",
        { staticClass: "scr-hder on micro" },
        [
          _c("div", { staticClass: "clear homecenter-header" }, [
            _vm._m(0),
            _c("div", { staticClass: "fl-c" }),
            _c("div", { staticClass: "fl-r" }, [
              _c("div", { staticClass: "hd-btns01" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass: "search-line",
                      on: {
                        click: function ($event) {
                          return _vm.openSearch()
                        },
                      },
                    },
                    [
                      _c("input", {
                        staticClass: "inp-text",
                        attrs: {
                          type: "text",
                          placeholder: "Enter Item Name or Color",
                        },
                      }),
                      _c("a", {
                        staticClass: "ico-01",
                        attrs: {
                          href: "javascript:void(0);",
                          title: "Open Search Popup",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "clear homecenter-tab" }, [
            _vm._m(1),
            _c("div", { staticClass: "fl-c" }),
            _c("div", { staticClass: "fl-r" }, [
              _c("div", { staticClass: "virtual-showroom-btn-area" }, [
                _c(
                  "button",
                  {
                    staticClass: "vs-btn",
                    attrs: { type: "button", tabindex: "0" },
                    on: {
                      click: function ($event) {
                        return _vm.goToSchedule()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "upper" }, [
                      _vm._v("SCHEDULE CONSULTATION"),
                    ]),
                    _c("img", {
                      staticClass: "arrow-right",
                      attrs: {
                        src: "https://img.lxhausys.com/public/images/arrw_right_09.png",
                        alt: "arrow icon",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("HDPSearchPopComponent"),
          _c("CartPopComponent"),
        ],
        1
      ),
      _c("h1", { staticClass: "sr-only" }, [
        _vm._v("Home Depot Himacs | LX Hausys"),
      ]),
      _c("div", { staticClass: "content-finder" }, [
        _c(
          "div",
          { staticClass: "container prod-fd-view himacs-finder micro-pf" },
          [
            _c(
              "div",
              { staticClass: "visual homedepot-slider himacs-visual" },
              _vm._l(_vm.bannerList, function (img) {
                return _c("div", { staticClass: "slider" }, [
                  _c(
                    "div",
                    {
                      key: img.content.id,
                      staticClass: "slider-bg",
                      style: {
                        backgroundImage:
                          "url(" + _vm.$imagePath + img.attribute.image + ")",
                      },
                    },
                    [
                      _c("div", { staticClass: "badge collection" }, [
                        _c("span", { staticClass: "ctt ctt-basic" }, [
                          _vm._v(_vm._s(img.content.title)),
                        ]),
                      ]),
                    ]
                  ),
                ])
              }),
              0
            ),
            _vm._m(2),
            _c("div", { staticClass: "col-02 inspr-view fd-view" }, [
              _c(
                "div",
                {
                  staticClass: "search-box p-ui",
                  attrs: { id: "filterSearch" },
                },
                [
                  _c("div", { staticClass: "search" }, [
                    _c("input", {
                      attrs: {
                        id: "textSearch",
                        type: "text",
                        placeholder: "Enter Item Name or Color",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.textSearch($event)
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "icon-search gray",
                        attrs: { type: "button", id: "searchBtn" },
                        on: {
                          click: function ($event) {
                            return _vm.textSearchDel()
                          },
                        },
                      },
                      [_vm._v(" search ")]
                    ),
                  ]),
                  _c("div", { staticClass: "drop-list inner" }, [
                    !_vm.isMobile()
                      ? _c("ul", [
                          _c("li", { staticClass: "on" }, [
                            _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                              ? _c("div", { staticClass: "tit" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view btn-color",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("PF.button.color")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "i-box type02 cont",
                                    staticStyle: { display: "block" },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "i-chk type03" },
                                      _vm._l(_vm.lefthue, function (lefthue) {
                                        return _c("li", [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "inp-chk chk-color",
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "color",
                                                  id: "hue_" + lefthue.id.value,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "hue",
                                                        lefthue.text,
                                                        lefthue.id.value,
                                                        "Color"
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              lefthue.text === "Other"
                                                ? _c("i", [
                                                    _c("span", {
                                                      style:
                                                        "background-image : url(images/products/" +
                                                        lefthue.id.value +
                                                        ".png)",
                                                    }),
                                                    _vm._v(
                                                      _vm._s(lefthue.text)
                                                    ),
                                                  ])
                                                : _c("i", [
                                                    _c("span", {
                                                      style:
                                                        "background-color:#" +
                                                        lefthue.id.value,
                                                    }),
                                                    _vm._v(
                                                      _vm._s(lefthue.text)
                                                    ),
                                                  ]),
                                              _c("span", [
                                                _vm._v(_vm._s(lefthue.text)),
                                              ]),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("li", { staticClass: "on" }, [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-patterns",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.button.patterns")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk type02" },
                                  _vm._l(
                                    _vm.leftpattern,
                                    function (leftpattern) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk chk-thumb" },
                                          [
                                            _c("input", {
                                              staticClass: "pattern",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "pattern_" +
                                                  leftpattern.id.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "pattern",
                                                      leftpattern.text,
                                                      leftpattern.id.value,
                                                      "Pattern"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _vm._v("> "),
                                            _c("span", [
                                              _c("i", {
                                                style:
                                                  "background-image : url(images/products/" +
                                                  leftpattern.id.value +
                                                  ".png)",
                                              }),
                                              _vm._v(_vm._s(leftpattern.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("button", {
                      staticClass: "filter-clos",
                      on: {
                        click: function ($event) {
                          return _vm.clickFilter()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c("div", { staticClass: "prod-list-box" }, [
                _c("div", { staticClass: "fd-view-mb search-area" }, [
                  _c("div", { staticClass: "search" }, [
                    _c("input", {
                      attrs: {
                        id: "textSearchM",
                        type: "text",
                        placeholder: _vm.$t("PF.text.searchText"),
                      },
                    }),
                    _c("i", {
                      staticClass: "icon-search gray",
                      attrs: { id: "searchBtnM" },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "tab-ui inner" }, [
                  _c("div", { staticClass: "filter-arae mb-only" }, [
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openSearchPopup("layer-search-box")
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("PF.button.filter_by"))),
                        _c("i"),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "micro-gal-head" }, [
                    _c("div", { staticClass: "flex-left" }, [
                      _c("p", { staticClass: "color-num" }, [
                        _c("span", { staticClass: "red" }, [
                          _vm._v(_vm._s(_vm.contentLength)),
                        ]),
                        _c("span", { staticClass: "blk" }, [_vm._v("Colors")]),
                      ]),
                    ]),
                    _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                      ? _c(
                          "div",
                          { staticClass: "sort-box micro-sort-box mt-0" },
                          [
                            _c("div", { staticClass: "sel-box" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-sel",
                                  attrs: { type: "button" },
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.sortBy))])]
                              ),
                              _c("div", { staticClass: "sel-list" }, [
                                _c("ul", { staticClass: "box" }, [
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Products A to Z",
                                              "asc",
                                              "title"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.products_a_to_z"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Products Z to A",
                                              "desc",
                                              "title"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.products_z_to_a"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "New Products first",
                                              "desc"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.new_products_first"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm.colorList.length > 0
                    ? _c("div", { staticClass: "select-view" }, [
                        _c(
                          "ul",
                          { staticClass: "list-filter-key" },
                          [
                            _vm._l(_vm.colorList, function (color, index) {
                              return _c(
                                "li",
                                {
                                  staticClass: "sel",
                                  on: {
                                    click: function ($event) {
                                      ;[
                                        _vm.colorDelete(
                                          color.subject,
                                          color.name,
                                          color.id
                                        ),
                                      ]
                                    },
                                  },
                                },
                                [
                                  color.subject == "Collection"
                                    ? _c("span", [
                                        _vm._v(_vm._s(color.subject)),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(color.type) +
                                            " : " +
                                            _vm._s(color.name) +
                                            " "
                                        ),
                                      ]),
                                  _c("button", { attrs: { type: "button" } }),
                                ]
                              )
                            }),
                            _c("li", { staticClass: "sel del_all" }, [
                              _vm.colorList.length > 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-all-delete",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearAll()
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("PF.button.clear_all"))
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "tab-con tab01 on" }, [
                    _c("div", { staticClass: "list-type-view row-04" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.contentList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: { hasNew: item.attribute.isNew == "true" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "homecenter/himacs/" +
                                      _vm.$utils.getSeoName(item, "content") +
                                      "/" +
                                      item.content.id,
                                    "data-ec-product":
                                      _vm.makeDataLayerForm(item),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "img-box over" }, [
                                    item.attribute.isNew === "true"
                                      ? _c(
                                          "span",
                                          { staticClass: "tag h30 new" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.text.new"))
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.attribute.isExclusive == "true"
                                      ? _c(
                                          "span",
                                          { staticClass: "badge-exclusive" },
                                          [_vm._v("exclusive")]
                                        )
                                      : _vm._e(),
                                    _c("img", {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        src:
                                          _vm.$imagePath +
                                          item.attribute.pf_thumb,
                                        alt: item.attribute.pf_thumb,
                                        "data-ec-product":
                                          _vm.makeDataLayerForm(item),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoPD(item)
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "info02" }, [
                                _c("ul", [
                                  _c("li", { staticClass: "txt-02" }, [
                                    _vm._v(
                                      " " + _vm._s(item.content.title) + " "
                                    ),
                                  ]),
                                  item.attribute.code != "" &&
                                  item.attribute.code != undefined &&
                                  item.attribute.code != null
                                    ? _c("li", { staticClass: "txt-01" }, [
                                        _vm._v(_vm._s(item.attribute.code)),
                                      ])
                                    : _c("li", { staticClass: "txt-01" }, [
                                        _vm._v(" "),
                                      ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm.contentLength > 16 && _vm.pageIndex == 1
                      ? _c("div", { staticClass: "more-box ta-c" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-arr-down",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.loadMore(_vm.pageIndex + 1)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.see_more"))),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.contentLength > (_vm.pageIndex / 16) * 16 * 16 &&
                    _vm.pageIndex != 1
                      ? _c("div", { staticClass: "more-box ta-c" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-arr-down",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.loadMore(_vm.pageIndex + 1)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.see_more"))),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
                staticStyle: { display: "none" },
                attrs: { id: "layer-search-box" },
              },
              [
                _c("div", { staticClass: "popup" }, [
                  _c("div", { staticClass: "scr-y" }, [
                    _c("div", { staticClass: "drop-list" }, [
                      _c("p", { staticClass: "tt-2" }, [_vm._v("Filter By")]),
                      _c("button", {
                        staticClass: "filter-clos",
                        on: {
                          click: function ($event) {
                            return _vm.closeSearchPopup()
                          },
                        },
                      }),
                      _c("ul", [
                        _c("li", { staticClass: "on" }, [
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-color",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.color")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk type03" },
                                    _vm._l(_vm.lefthue, function (lefthue) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk chk-color" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                name: "color",
                                                id: "hue_" + lefthue.id.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "hue",
                                                      lefthue.text,
                                                      lefthue.id.value,
                                                      "Color"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            lefthue.text === "Other"
                                              ? _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-image : url(images/products/" +
                                                      lefthue.id.value +
                                                      ".png)",
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ])
                                              : _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-color:#" +
                                                      lefthue.id.value,
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ]),
                                            _c("span", [
                                              _vm._v(_vm._s(lefthue.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-patterns",
                                attrs: { type: "button" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PF.button.patterns")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk type02" },
                                _vm._l(_vm.leftpattern, function (leftpattern) {
                                  return _c("li", [
                                    _c(
                                      "label",
                                      { staticClass: "inp-chk chk-thumb" },
                                      [
                                        _c("input", {
                                          staticClass: "pattern",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              "pattern_" + leftpattern.id.value,
                                          },
                                          on: {
                                            change: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "pattern",
                                                  leftpattern.text,
                                                  leftpattern.id.value,
                                                  "Pattern"
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _vm._v("> "),
                                        _c("span", [
                                          _c("i", {
                                            style:
                                              "background-image : url(images/products/" +
                                              leftpattern.id.value +
                                              ".png)",
                                          }),
                                          _vm._v(_vm._s(leftpattern.text)),
                                        ]),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c("FooterComponent", { attrs: { isMicrosite: true } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fl-l" }, [
      _c("div", { staticClass: "logo" }, [
        _c(
          "a",
          {
            staticClass: "go_home_for_pc",
            attrs: { href: "homecenter/himacs" },
          },
          [_vm._v("LX Hausys")]
        ),
        _c(
          "a",
          {
            staticClass: "go_home_for_mobile",
            staticStyle: { display: "none" },
            attrs: { href: "homecenter/himacs" },
          },
          [_vm._v("LX Hausys")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fl-l" }, [
      _c("div", { staticClass: "header-tab" }, [
        _c("div", { staticClass: "tab-himacs" }, [
          _c("a", { attrs: { href: "homecenter/himacs" } }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/images/HomeDepot/himacs.png",
                alt: "himacs",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "tab-viatera" }, [
          _c("a", { attrs: { href: "homecenter/viatera" } }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/images/HomeDepot/viatera_off.png",
                alt: "viatera",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "micro-visual" }, [
      _c("div", { staticClass: "micro-logo" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/images/products/himacs-copy.png",
            alt: "himacs",
          },
        }),
      ]),
      _c("div", { staticClass: "micro-desc" }, [
        _c("p", { staticClass: "ctt ctt-t2 tac" }, [
          _vm._v(
            " HIMACS is a delicate composition of acrylic, minerals and natural pigments that combine to create a smooth, non-porous, thermoformable surface with inconspicuous seams. Thanks to these qualities and the outstanding flexibility in fabrication and design, HIMACS offers countless advantages over conventional materials. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "micro-contents himacs-mc pc-ver" }, [
        _c("h2", { staticClass: "tt-2 tac mc-title" }, [
          _vm._v("FEATURES & BENEFITS"),
        ]),
        _c("button", {
          staticClass: "btn-close-white",
          attrs: { type: "button" },
        }),
        _c("div", { staticClass: "tab-area" }, [
          _c("div", { staticClass: "tab-ui tab-ui-micro" }, [
            _c("ul", [
              _c(
                "li",
                {
                  staticClass: "btn-tab tab-hyg",
                  attrs: { "data-tab": "tab01" },
                },
                [
                  _c("div", { staticClass: "micro-tab-box" }, [
                    _c("div", { staticClass: "border-box" }),
                    _c("i", { staticClass: "icon-hyg" }),
                    _c("div", { staticClass: "mid-area" }),
                    _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                      _vm._v("Hygienic & "),
                      _c("br"),
                      _vm._v(" Non-porous"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  staticClass: "btn-tab tab-inc",
                  attrs: { "data-tab": "tab02" },
                },
                [
                  _c("div", { staticClass: "micro-tab-box" }, [
                    _c("div", { staticClass: "border-box" }),
                    _c("i", { staticClass: "icon-inc" }),
                    _c("div", { staticClass: "mid-area" }),
                    _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                      _vm._v("Inconspicuous Seams"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  staticClass: "btn-tab tab-eas",
                  attrs: { "data-tab": "tab03" },
                },
                [
                  _c("div", { staticClass: "micro-tab-box" }, [
                    _c("div", { staticClass: "border-box" }),
                    _c("i", { staticClass: "icon-eas" }),
                    _c("div", { staticClass: "mid-area" }),
                    _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                      _vm._v("Easy Care & Maintenance"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  staticClass: "btn-tab tab-rep",
                  attrs: { "data-tab": "tab04" },
                },
                [
                  _c("div", { staticClass: "micro-tab-box" }, [
                    _c("div", { staticClass: "border-box" }),
                    _c("i", { staticClass: "icon-rep" }),
                    _c("div", { staticClass: "mid-area" }),
                    _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                      _vm._v("Repairable & Renewable"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "li",
                {
                  staticClass: "btn-tab tab-war",
                  attrs: { "data-tab": "tab06" },
                },
                [
                  _c("div", { staticClass: "micro-tab-box" }, [
                    _c("div", { staticClass: "border-box" }),
                    _c("i", { staticClass: "icon-war" }),
                    _c("div", { staticClass: "mid-area" }),
                    _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                      _vm._v("Warranty"),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "tab-con tab01" }, [
            _c("div", { staticClass: "balloon-box bb-01" }, [
              _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                _vm._v(
                  "HIMACS is non-porous and safe for food preparation. Certified by the National Sanitation Foundation, it prevents the growth of harmful bacteria and mold."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-con tab02" }, [
            _c("div", { staticClass: "balloon-box bb-02" }, [
              _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                _vm._v(
                  "HIMACS allows for visually seamless installation without joints or edges. When two sheets are combined, HIMACS can look like a continuous material. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-con tab03" }, [
            _c("div", { staticClass: "balloon-box bb-03" }, [
              _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                _vm._v(
                  "HIMACS is easy to clean and does not require sealing, waxing or special products for maintenance."
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-con tab04" }, [
            _c("div", { staticClass: "balloon-box bb-04" }, [
              _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                _vm._v(
                  "HIMACS can be restored to its original state like it is almost new. Deep scratches, cracks, chips or other severe damage can be repaired by a professional fabricator. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-con tab06" }, [
            _c("div", { staticClass: "balloon-box bb-06" }, [
              _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                _vm._v(
                  "HIMACS offers a 15-year fully transferable manufacturer’s limited warranty for products sold at Home Depot."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "micro-contents himacs-mc mobile-ver" }, [
        _c("h2", { staticClass: "tt-2 tac mc-title" }, [
          _vm._v("FEATURES & BENEFITS"),
        ]),
        _c("div", { staticClass: "slider-micro-nav-wrap" }, [
          _c("div", { staticClass: "slider" }, [
            _c("div", { staticClass: "nav-slide-con tab-hyg" }, [
              _c("div", { staticClass: "micro-tab-box" }, [
                _c("div", { staticClass: "border-box" }),
                _c("i", { staticClass: "icon-hyg" }),
                _c("div", { staticClass: "mid-area" }),
                _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                  _vm._v("Hygienic & Non-porous"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "slider" }, [
            _c("div", { staticClass: "nav-slide-con tab-inc" }, [
              _c("div", { staticClass: "micro-tab-box" }, [
                _c("div", { staticClass: "border-box" }),
                _c("i", { staticClass: "icon-inc" }),
                _c("div", { staticClass: "mid-area" }),
                _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                  _vm._v("Inconspicuous Seams"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "slider" }, [
            _c("div", { staticClass: "nav-slide-con tab-eas" }, [
              _c("div", { staticClass: "micro-tab-box" }, [
                _c("div", { staticClass: "border-box" }),
                _c("i", { staticClass: "icon-eas" }),
                _c("div", { staticClass: "mid-area" }),
                _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                  _vm._v("Easy Care & Maintenance"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "slider" }, [
            _c("div", { staticClass: "nav-slide-con tab-rep" }, [
              _c("div", { staticClass: "micro-tab-box" }, [
                _c("div", { staticClass: "border-box" }),
                _c("i", { staticClass: "icon-rep" }),
                _c("div", { staticClass: "mid-area" }),
                _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                  _vm._v("Repairable & Renewable"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "slider" }, [
            _c("div", { staticClass: "nav-slide-con tab-war" }, [
              _c("div", { staticClass: "micro-tab-box" }, [
                _c("div", { staticClass: "border-box" }),
                _c("i", { staticClass: "icon-war" }),
                _c("div", { staticClass: "mid-area" }),
                _c("p", { staticClass: "ctt ctt-t4 tac micro-tab-desc" }, [
                  _vm._v("Warranty"),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "section-micro-pop" }, [
          _c("button", {
            staticClass: "btn-close-white",
            attrs: { type: "button" },
          }),
          _c("div", { staticClass: "slider-micro-wrap" }, [
            _c("div", { staticClass: "slider" }, [
              _c("div", { staticClass: "pop-slide-con" }, [
                _c("div", { staticClass: "micro-tab-box" }, [
                  _c("div", { staticClass: "border-box" }),
                  _c("i", { staticClass: "icon-hyg" }),
                  _c("div", { staticClass: "mid-area" }),
                  _c("p", { staticClass: "ctt ctt-t4 tac" }, [
                    _vm._v("Hygienic & Non-porous"),
                  ]),
                ]),
                _c("div", { staticClass: "pop-btm-txt" }, [
                  _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                    _vm._v(
                      "HIMACS is non-porous and safe for food preparation. Certified by the National Sanitation Foundation, it prevents the growth of harmful bacteria and mold."
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "slider" }, [
              _c("div", { staticClass: "pop-slide-con" }, [
                _c("div", { staticClass: "micro-tab-box" }, [
                  _c("div", { staticClass: "border-box" }),
                  _c("i", { staticClass: "icon-inc" }),
                  _c("div", { staticClass: "mid-area" }),
                  _c("p", { staticClass: "ctt ctt-t4 tac" }, [
                    _vm._v("Inconspicuous Seams"),
                  ]),
                ]),
                _c("div", { staticClass: "pop-btm-txt" }, [
                  _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                    _vm._v(
                      "HIMACS allows for visually seamless installation without joints or edges. When two sheets are combined, HIMACS can look like a continuous material. "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "slider" }, [
              _c("div", { staticClass: "pop-slide-con" }, [
                _c("div", { staticClass: "micro-tab-box" }, [
                  _c("div", { staticClass: "border-box" }),
                  _c("i", { staticClass: "icon-eas" }),
                  _c("div", { staticClass: "mid-area" }),
                  _c("p", { staticClass: "ctt ctt-t4 tac" }, [
                    _vm._v("Easy Care & Maintenance"),
                  ]),
                ]),
                _c("div", { staticClass: "pop-btm-txt" }, [
                  _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                    _vm._v(
                      "HIMACS is easy to clean and does not require sealing, waxing or special products for maintenance."
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "slider" }, [
              _c("div", { staticClass: "pop-slide-con" }, [
                _c("div", { staticClass: "micro-tab-box" }, [
                  _c("div", { staticClass: "border-box" }),
                  _c("i", { staticClass: "icon-rep" }),
                  _c("div", { staticClass: "mid-area" }),
                  _c("p", { staticClass: "ctt ctt-t4 tac" }, [
                    _vm._v("Repairable & Renewable"),
                  ]),
                ]),
                _c("div", { staticClass: "pop-btm-txt" }, [
                  _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                    _vm._v(
                      "HIMACS can be restored to its original state like it is almost new. Deep scratches, cracks, chips or other severe damage can be repaired by a professional fabricator. "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "slider" }, [
              _c("div", { staticClass: "pop-slide-con" }, [
                _c("div", { staticClass: "micro-tab-box" }, [
                  _c("div", { staticClass: "border-box" }),
                  _c("i", { staticClass: "icon-war" }),
                  _c("div", { staticClass: "mid-area" }),
                  _c("p", { staticClass: "ctt ctt-t4 tac" }, [
                    _vm._v("Warranty"),
                  ]),
                ]),
                _c("div", { staticClass: "pop-btm-txt" }, [
                  _c("p", { staticClass: "ctt ctt-t3 tac" }, [
                    _vm._v(
                      "HIMACS offers a 15-year fully transferable manufacturer’s limited warranty for products sold at Home Depot."
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }