<template>
  <div class="pollutant-management-image-card">
    <PollutantManagementImageCardTitle
    :image="imageCardTitle.image"
    :text="imageCardTitle.text"
    />
    <div class="content-area">
      <SustainabilityTitleAndText
        v-for="(item,idx) in imageCardContentList"
        :key="idx"
        :contentTitle="item.contentTitle"
        :contentText="item.contentText"
        :titleColor="item.titleColor"
        :textColor="item.textColor"
      />
    </div>
  </div>
</template>

<script>
import SustainabilityTitleAndText from '../../components/SustainabilityTitleAndText.vue'
import PollutantManagementImageCardTitle from './PollutantManagementImageCardTitle.vue'
export default {
  components: {
    PollutantManagementImageCardTitle,
    SustainabilityTitleAndText
  },
  props: {
    imageCardTitle: {
      type: Object
    },
    imageCardContentList: {
      type: Array
    }
  },
  data: function () {
    return {
      image: this.imageCardTitle.image,
      text: this.imageCardTitle.text
    }
  }

}
</script>

<style>

.pollutant-management-image-card {
  margin: 0px 40px;
}

@media (max-width: 768px) {
  .pollutant-management-image-card {
    margin: auto;
  }
}

.pollutant-management-image-card .content-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-top: 20px;
}
</style>
