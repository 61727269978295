<template>
  <!-- span이면 type은 빈칸, div면 product || 아무거나 -->
  <span class="sns-wrp" v-if="type == 'document'">
    <button type="button" class="btn-share icon-share" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop" style="width: 80px;">
      <ul>
        <li><a href="javascript:;" class="ico-e" @click="email(content, type)">email</a></li>
        <li><a href="javascript:;" class="ico-v" @click="clip(content, type)">url</a></li>
      </ul>
    </div>
  </span>
  <span class="sns-wrp" v-else-if="type == 'warranty'">
    <button type="button" class="btn-share icon-share" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop" style="width: 80px;">
        <ul>
            <li><a href="javascript:;" class="ico-e" @click="email(content, type)">email</a></li>
            <li><a href="javascript:;" class="ico-v" @click="clip(content, type)">url</a></li>
        </ul>
    </div>
  </span>
  <span class="sns-wrp" v-else-if="type === 'care'">
    <button type="button" class="btn-share icon-share" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop" style="width: 80px;">
      <ul>
        <li><a href="javascript:;" class="ico-e" @click="email(content, type)">email</a></li>
        <li><a href="javascript:;" class="ico-v" @click="clip(content, type)">url</a></li>
      </ul>
    </div>
  </span>
  <div class="sns-wrp" v-else-if="type === 'product'">
    <button type="button" class="icon-share-bk" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop">
      <ul>
        <li><a href="javascript:;" class="ico-f" @click="faceBook(childValue)">facebook</a></li>
        <li><a href="javascript:;" class="ico-p" @click="pinterest(childValue)">pinterest</a></li>
        <li><a href="javascript:;" class="ico-l" @click="linkedin(childValue, type)">linkedin</a></li>
        <li><a href="javascript:;" class="ico-t" @click="shareTwitter(childValue)">twitter</a></li>
<!--              <li><a href="javascript:;" class="ico-h" @click="houzz(childValue, type)">houzz</a></li>-->
        <li><a href="javascript:;" class="ico-e" @click="email(childValue, type)">email</a></li>
        <li><a href="javascript:;" class="ico-v" @click="clip(childValue)">url</a></li>
      </ul>
    </div>
  </div>
  <div class="sns-wrp" v-else-if="type == 'news'">
    <button type="button" class="icon-share" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop">
      <ul>
        <li><a href="javascript:;" class="ico-f" @click="faceBook(childValue)">facebook</a></li>
        <li><a href="javascript:;" class="ico-p" @click="pinterest(childValue)">pinterest</a></li>
        <li><a href="javascript:;" class="ico-l" @click="linkedin(childValue, type)">linkedin</a></li>
        <li><a href="javascript:;" class="ico-t" @click="shareTwitter(childValue)">twitter</a></li>
<!--        <li><a href="javascript:;" class="ico-h" @click="houzz(childValue, type)">houzz</a></li>-->
        <li><a href="javascript:;" class="ico-e" @click="email(childValue, type)">email</a></li>
        <li><a href="javascript:;" class="ico-v" @click="clip(childValue)">url</a></li>
      </ul>
    </div>
  </div>
  <div class="sns-wrp" v-else-if="type == 'case' || type == 'gallery'">
    <button type="button" class="icon-share" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop">
      <ul>
        <li><a href="javascript:;" class="ico-f" @click="faceBook(childValue, type)"/></li>
        <li><a href="javascript:;" class="ico-p" @click="pinterest(childValue, type)"/></li>
        <li><a href="javascript:;" class="ico-l" @click="linkedin(childValue, type)"/></li>
        <li><a href="javascript:;" class="ico-t" @click="shareTwitter(childValue, type)"/></li>
<!--        <li><a href="javascript:;" class="ico-h" @click="houzz(childValue, type)"/></li>-->
        <li><a href="javascript:;" class="ico-e" @click="email(childValue, type)"/></li>
        <li><a href="javascript:;" class="ico-v" @click="clip(childValue)"/></li>
      </ul>
    </div>
  </div>

  <div class="sns-wrp" v-else>
    <button type="button" class="btn-share icon-share" @click="clickShareSns()">sharing</button>
    <div class="sns sns_share_pop">
      <ul>
        <li><a href="javascript:;" class="ico-f" @click="faceBook(content, type)">facebook</a></li>
        <li><a href="javascript:;" class="ico-p" @click="pinterest(content, type)">pinterest</a></li>
        <li><a href="javascript:;" class="ico-l" @click="linkedin(content, type)">linkedin</a></li>
        <li><a href="javascript:;" class="ico-t" @click="shareTwitter(content)">twitter</a></li>
<!--        <li><a href="javascript:;" class="ico-h" @click="houzz(content, type)">houzz</a></li>-->
        <li><a href="javascript:;" class="ico-e" @click="email(content, type)">email</a></li>
        <li><a href="javascript:;" class="ico-v" @click="clip(content)">url</a></li>
      </ul>
    </div>
  </div>

</template>

<script>

export default {
  name: 'ShortLinkComponent',
  props: ["childValue", "type"],
  data () {
    return {
      content : this.childValue,
      page : this.type,
    }
  },
  mounted() {
  },
  methods: {
    closeShareSns () {
      $('.sns_share_pop').removeClass('open')
    },
    openShareSns () {
      $('.sns_share_pop').addClass('open')
    },
    clickShareSns () {
      const sharesnsFlag = $('.sns_share_pop').hasClass('open')
      if (sharesnsFlag) {
        this.closeShareSns()
      } else {
        this.openShareSns()
      }
    },
    gotoUrl (url, isNewTab) {
      if (isNewTab) {
        window.open(url, '_blank')
      } else {
        location.href = url
      }
    },
    linkedin(content, type){
      let url = location.href;
      let source = "";
      if(type == 'product' ){
        source = this.$imagePath + content.attribute.pf_thumb;
      } else if(type === 'case' || type == 'gallery'){
        source = this.$imagePath + content.attribute.pc_thumb;
      }
      let linkedin = "https://www.linkedin.com/shareArticle?mini=true&title="+encodeURIComponent(content.content.title)+"&source="+encodeURIComponent(source)+"&url="+encodeURIComponent(url);
      window.open(linkedin, '_blank')
    },
    clip(content, type){
      var url = '';
      if(type == 'document'){
        url = this.$imagePath +content.attribute.doc_file;
      } else if (type == 'warranty'){
        url = this.$imagePath +content.attribute.warranty_file;
      } else if (type === 'care'){
        url = this.$imagePath + content.filePath;
      } else {
        url = location.href;
      }
      var textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.value = url;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert("URL Copied");
    },
    houzz (content, type){
      var popupWidth = 900;
      var popupHeight = 480;
      var popout = 'https://www.houzz.com/imageClipperUpload?';
      if(type == 'product' ) {
        popout += 'imageUrl=' + encodeURIComponent(this.$imagePath + content.attribute.pf_thumb);
      }else if(type === 'case' || type == 'gallery'){
        popout += 'imageUrl=' + encodeURIComponent(this.$imagePath + content.attribute.pc_thumb);
      } else {
        popout += 'imageUrl=' ;
      }
      popout += '&title=' + encodeURIComponent(content.content.title)
      popout += '&link=' + encodeURIComponent(document.location.href)
      var left = Math.floor((screen.width - popupWidth)/2);
      var top = Math.floor((screen.height - popupHeight)/2);
      window.open(popout, 'HOUZZ' + new Date().getTime(), 'status=no,resizable=yes,scrollbars=yes,personalbar=no,directories=no,location=no,toolbar=no,menubar=no,width=' + popupWidth + ',height=' + popupHeight + ',left=' + left + ',top=' + top)
    },
    async email(content, type){
      let title = '';
      let url = '';
      if(type == 'document'){
        title = content.content.title;
        url = this.$imagePath +content.attribute.doc_file;
      } else if (type == 'warranty'){
        title = content.content.title;
        url = this.$imagePath +content.attribute.warranty_file;
      } else if (type === 'care'){
        title = _.split(_.last(_.split(content.filePath,'/')), '.')[0];
        url = this.$imagePath + content.filePath;
      } else {
        title = content.content.title;
        url = location.href;
      }
      var email_url = "mailto:?subject="+ title +"&body=" + url;
      location.href = email_url;
    },
    async faceBook(content, type) {
      let title = '';
      let url = '';
      let image = '';
      let brief = '';

      title = content.content.title;
      url = location.href;
      let title2 = content.content.title + ' | LX Hausys'

      // share description 고정 문구 없음 주석(twitter도) 22/01/27
      // brief = content.attribute.brief

      let countryCode = this.$countryCode

      if (countryCode == '_US' || countryCode == '_GL') {
        if(type === 'case' || type == 'gallery'){
          image = this.$imagePath + content.attribute.pc_thumb;
          brief = "Check out LX Hausys’ latest projects "+ content.content.title
            + " and get inspired in both commercial and residential spaces."

        } else {
          image = this.$imagePath + content.attribute.pf_thumb;
          brief = "Check out LX Hausys’ " + content.content.title
            + " and design solutions in various spaces. Click to find in detail."
        }
      }else { // 22/03/16 - share description 고정 문구로 변경 (EU/UK 만 해당사항)
        if(type === 'case' || type == 'gallery'){
          if (content.parents[0].name == 'HIMACS'){
            title2 = content.content.title +' - '+ content.attribute.segments1Text + ' | LX Hausys'
            image = this.$imagePath + content.attribute.pc_thumb;
            brief = "HIMACS it is the perfect material for a wide range of market segments from architectural  to interior design applications : facades, kitchen, bathroom, furniture, airports, healthcare, education,"
          }else {
            title2 = content.content.title +' - '+ content.attribute.segments1Text + ' | LX Hausys'
            image = this.$imagePath + content.attribute.pc_thumb;
            brief = "Explore HFLOR's versatile flooring solutions. With HFLOR's Luxury Vinyl Tiles, you can create commercial floors that respond to the design, performance, installation and maintenance demands of your project."
          }
        } else { // 22/03/16 - share description 고정 문구로 변경 (EU/UK 만 해당사항)
          if (content.parents[1].name == 'HIMACS') {
            title2 = content.attribute.code +' '+ content.content.title + ' | LX Hausys'
            image = this.$imagePath + content.attribute.pf_thumb;
            brief = "Discover the infinite possibilities of HIMACS, an outstanding solid surface material. A composition of acrylic, minerals and natural pigments that combine to create a smooth, non-porous, thermoformable and visually seamless surface."
          }else {
            title2 = content.attribute.code +' '+ content.content.title + ' | LX Hausys'
            image = this.$imagePath + content.attribute.pf_thumb;
            brief = "Discover the endless possibilities of HFLOR's floorscape. HFLOR's LVT flooring explores a variety of dimensional patterns and colours creating a unique feeling and atmosphere to any floor."
          }
        }
      }

      if (countryCode == '_US') {
        countryCode = 'us'
      }
      if (countryCode == '_GL') {
        countryCode = 'gl'
      }
      if (location.pathname.startsWith('/eu-en/')) {
        countryCode = 'eu-en'
      } else if (location.pathname.startsWith('/eu-fr/')) {
        countryCode = 'eu-fr'
      } else if (location.pathname.startsWith('/eu-de/')) {
        countryCode = 'eu-de'
      } else if (location.pathname.startsWith('/eu-it/')) {
        countryCode = 'eu-it'
      } else if (location.pathname.startsWith('/eu-es/')) {
        countryCode = 'eu-es'
      }

      let shareGateway = location.protocol + '//' + location.host + '/' + countryCode + '/gw/openGraph/share.html?' ;
      shareGateway += 'u=' + encodeURIComponent(url);
      shareGateway += '&t=' + encodeURIComponent(title2);
      shareGateway += '&i=' + encodeURIComponent(image);
      shareGateway += '&d=' + encodeURIComponent(brief);

      let facebookLink = "u="+encodeURIComponent(shareGateway);

      window.open('https://www.facebook.com/sharer/sharer.php?'+facebookLink, 'facebooksharedialog', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    },
    async pinterest(content, type) {
      let title = '';
      let url = '';
      let image = '';
      title = content.content.title;
      url = location.href;
      if(type === 'case' || type == 'gallery'){
        image = this.$imagePath + content.attribute.pc_thumb;
      } else {
        image = this.$imagePath + content.attribute.pf_thumb;
      }

      var link = "http://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(url) + "&media=" + encodeURIComponent(image) + "&description=" + encodeURIComponent(title);
      var a = window.open(link, "pinterest", 'width=800, height=500');
      if(a) {
        a.focus();
      }
    },
    async shareTwitter(content,type) {
      let title = '';
      let url = '';
      let image ='';
      let brief ='';

      url = location.href;
      if(type === 'case' || type == 'gallery'){
        image = this.$imagePath + content.attribute.pc_thumb;
      } else {
        image = this.$imagePath + content.attribute.pf_thumb;
      }

      // brief = content.attribute.brief

      let title2 = content.content.title + ' | LX Hausys'
      let countryCode = this.$countryCode

      if (countryCode == '_US') {
        countryCode = 'us'
      }
      if (countryCode == '_GL') {
        countryCode = 'gl'
      }

      let shareGateway = location.protocol + '//' + location.host + '/' + countryCode + '/gw/openGraph/share.html?' ;
      shareGateway += 'u=' + encodeURIComponent(url);
      shareGateway += '&t=' + encodeURIComponent(title2);
      shareGateway += '&i=' + encodeURIComponent(image);

      // shareGateway += '&d=' + encodeURIComponent(brief);

      let facebookLink = encodeURIComponent(shareGateway);

      window.open("https://twitter.com/intent/tweet?url=" + facebookLink + "&text=" + encodeURIComponent(title2));
    }
  }
}
</script>
