<template>
  <VIATERA_COLOR   :category="category"
                   :collectionList="collectionList"
                   :dictMap="dictMap"
  v-model="selected">
  </VIATERA_COLOR>
</template>

<script>
import { mapState } from 'vuex'
import VIATERA_COLOR from './VIATERA_COLOR.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    VIATERA_COLOR

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: {}
    }
  },
  watch: {
  },
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.selected], val => {
      this.$emit('update', this.selected)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {

  }
}
</script>
