import request from './request'
const urlPrefix = ''

const UsService = {
  getStateList (countrycd) {
    return request({
      url: `${urlPrefix}/us/state/list?countryCd=${countrycd}`,
      method: 'get'
    })
  },
  getTerritory (stateCd) {
    return request({
      url: `${urlPrefix}/us/territory?stateCd=${stateCd}`,
      method: 'get'
    })
  },
  getGLCountryList (areaCd) {
    return request({
      url: `${urlPrefix}/us/glState/list?areaCd=${areaCd}`,
      method: 'get'
    })
  },
  getGLCountryCd (countryName) {
    return request({
      url: `${urlPrefix}/us/glcountryCd/list?countryName=${countryName}`,
      method: 'get'
    })
  },
  getCountryList () {
    return request({
      url: `${urlPrefix}/us/country/list`,
      method: 'get'
    })
  },
  getEUCountryList (menuType) {
    return request({
      url: `${urlPrefix}/us/country/eulist?menuType=${menuType}`,
      method: 'get'
    })
  },
  getWorldCountryList () {
    return request({
      url: `${urlPrefix}/us/country/world`,
      method: 'get'
    })
  },
  getAreaList () {
    return request({
      url: `${urlPrefix}/us/areaList/list`,
      method: 'get'
    })
  },
  getAreaListCode (code) {
    return request({
      url: `${urlPrefix}/us/areaListCode/list?code=${code}`,
      method: 'get'
    })
  }
}

export default UsService
