<template>
  <div class="box-area" :class="isActive ? 'active' : ''" @click="moveByActive">
    <pre class="box-content" :class="isActive ? 'active' : ''">{{ content }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String
    },
    isActive: {
      type: Boolean,
      default: false
    },
    move: {
      type: Function
    }
  },
  methods: {
    moveByActive () {
      this.isActive === false && this.$emit('move')
    }
  }
}
</script>

<style>
.box-area {
  display: flex;
  padding: 30px 80px;
  justify-content: center;
  align-items: center;
  border: 1px solid #393939;
  width: 50%;
  cursor: pointer;
}
@media (max-width: 768px) {
  .box-area {
    padding: 20px;
  }
}

.box-area .box-content {
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 125% */
}
@media (max-width: 768px) {
  .box-area .box-content {
    font-size: 0.8rem;
    white-space: pre-wrap;
    text-align: center;
  }
}

.box-area.active {
  display: flex;
  padding: 30px 80px;
  justify-content: center;
  align-items: center;
  border: 1px solid #393939;
  width: 50%;
  background: #393939;
  cursor: context-menu;
}
@media (max-width: 768px) {
  .box-area.active {
    padding: 20px;
  }
}

.box-area.active .box-content.active {
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 125% */
  color: #fff;
}
@media (max-width: 768px) {
  .box-area.active .box-content.active {
    font-size: 0.8rem;
    white-space: pre-wrap;
  }
}

</style>
