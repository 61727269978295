var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      !_vm.popFlag ? _c("HeaderComponent") : _vm._e(),
      !_vm.popFlag ? _c("GnbComponent") : _vm._e(),
      _c("div", { staticClass: "content cntu-view terms" }, [
        !_vm.popFlag
          ? _c("div", { staticClass: "location" }, [
              _c("ul", [
                _c("li", { staticClass: "ico-home" }, [
                  _vm._v(_vm._s(_vm.$t("terms.text.FF0030_001"))),
                ]),
                _c("li", [_vm._v(_vm._s(_vm.$t("terms.text.FF0030_002")))]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "container cntu-box private-view" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _c("div", { staticClass: "termsWrap inner" }, [
              _c("div", { staticClass: "termsArea" }, [
                _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                  _vm._v(_vm._s(_vm.$t("terms.text.FF0030_002"))),
                ]),
                _c("p", { staticClass: "tt-sub mb-88" }, [
                  _vm._v(_vm._s(_vm.$t("terms.text.FF0030_003"))),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_004"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_005")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_006")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_007")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_008")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_009"))),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "ctt ctt-t4",
                      staticStyle: { "margin-bottom": "0" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("terms.text.FF0030_010")) + " "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.$t("terms.text.FF0030_011")) + " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_012"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_013")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_014")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_015")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_016"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_017")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_018")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_019"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_020")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_021"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_022")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_023"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_024")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_025"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_026")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_027")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_028"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_029")) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "t-type" }, [
                  _c("h2", { staticClass: "ctt ctt-t0 ta-l" }, [
                    _vm._v(_vm._s(_vm.$t("terms.text.FF0030_030"))),
                  ]),
                  _c("p", { staticClass: "ctt ctt-t4" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("terms.text.FF0030_031")) +
                        " provision."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_032")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("terms.text.FF0030_033")) + " "),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      !_vm.popFlag ? _c("FooterComponent") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }