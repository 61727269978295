<template>
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/winning-design-awards/top_image.png"
    title="Winning Design Awards"
    titleColor="#222"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="text"
        />
      </OverviewSection>
      <AchievementsAtDesignAwards />
      <MajorDesignPerformance />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/winning-design-awards/top_image.png"
    title="デザイン賞受賞"
    titleColor="#222"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="textJp"
        />
      </OverviewSection>
      <AchievementsAtDesignAwards />
      <MajorDesignPerformance />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/winning-design-awards/top_image.png"
    title="Награда за лучший дизайн"
    titleColor="#222"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="textRu"
        />
      </OverviewSection>
      <AchievementsAtDesignAwards />
      <MajorDesignPerformance />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/winning-design-awards/top_image.png"
    title="斩获设计大奖"
    titleColor="#222"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="textCn"
        />
      </OverviewSection>
      <AchievementsAtDesignAwards />
      <MajorDesignPerformance />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import OverviewSection from '../../overview/components/OverviewSection.vue'
import SustainabilityText from '../../sustainability/components/SustainabilityText.vue'
import AchievementsAtDesignAwards from './components/AchievementsAtDesignAwards.vue'
import MajorDesignPerformance from './components/MajorDesignPerformance.vue'

export default {
  components: {
    OverviewLayout,
    AchievementsAtDesignAwards,
    SustainabilityText,
    OverviewSection,
    MajorDesignPerformance
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'Our Design Center is dedicated to R&D endeavors that deliver novel value to customers. As a result, our exceptional\n' +
            'design capacity is being recognized by reputable international design awards including iF, Red Dot, and IDEA.',
      textJp:'当社のデザインセンターは、お客様に新たな価値を提供する研究開発に注力しています。\n' +
              'その結果、当社の卓越したデザイン能力は、iF、Red Dot、IDEA などの権威ある国際デザイン賞で認められています。',
      textRu:'Наш центр дизайна занимается исследованиями и разработками, принося клиентам новые ценности.\n' + 
            'В результате наши исключительные дизайнерские способности получают признание авторитетных международных\n' + 
            'премий в области дизайна, таких как iF, Red Dot и IDEA.',
      textCn:'LX Hausys以设计中心为核心，始终致力于为顾客创造全新价值的研究活动，\n' + 
      '突出的设计能力使其在iF, Red Dot, IDEA等世界级的设计大奖中获得高度认可。',
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>

</style>
