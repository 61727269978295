<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content au-view newsEventArea">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{$t('newsDetail.text.home')}}</li>
          <li>{{$t('newsDetail.text.about_us')}}</li>
          <li @click="gotolink(`/${countryCodeSub.toLowerCase()}/aboutus/news-event/list`)">{{$t('newsDetail.text.news')}} & {{$t('newsDetail.text.event')}}</li>
        </ul>
      </div>

      <!-- container (News & Event) -->
      <div class="container au-box bbs-box au-box02 news_detail">

        <div class="bbs-dt-box">
          <!-- 제목 영역 -->
          <div class="tit-box">
            <div class="txt-box w-con">
              <ul>
                <li class="t-01 ctt ctt-t4">{{topic}}</li>
                <li class="t-02"><h2 class="title tt-2">{{headerTitle}}</h2></li>
                <li class="t-04 ctt ctt-t4">{{time | moment('MM.DD.YYYY')}}</li>
              </ul>
            </div>
          </div>

          <!-- 본문 -->
          <div class="nd-box prod-dt-view">
            <!-- cms 내용 -->
            <div class="w-con dt-box t-type-03">
              <div v-html="topicText"></div>
            </div>

            <!-- Latest News 미사용으로 인한 주석처리-->
<!--            <div class=" w-con latest_news_section">
              <div class="tit_area">
                <p class="section_title latest_title tt-2">RELATED NEWS</p>
              </div>
              <div class="bbs-list">
                <div class="slider-news">
                  <div class="slider" v-for="topic in news" v-if="topic.content.id !== detailId">
                    <a :href="'aboutus/news-event-detail/'+ $utils.getSeoName(topic, 'news') + '/' + topic.content.id">
                      <div class="img-box">
                        <img :src="$imagePath + topic.attribute.pcThumbnail" alt="LX Hausys" />
                      </div>
                      <div class="txt-box">
                        <ul>
                          <li class="t-01">{{topic.attribute.topicTypeText}}</li>
                          <li class="t-02"><strong>{{topic.content.title}}</strong></li>
                          <li class="t-04">{{topic.content.createDate | moment("MMM.YYYY")}}</li>
                        </ul>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>-->

          </div>
        </div>
      </div>
      <!-- //container (News & Event) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
    <SampleOrderPopComponent :content-id=selConId @closePopup=closeSampleOrder></SampleOrderPopComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import ProductService from '@/server/product.service'
import AboutUsService from '@/server/aboutus.service'
import GnbComponent from '@/components/Layout/GNB'
import { mapState } from 'vuex'
import InspirationService from '@/server/inspiration.service'
import _ from '@/common/helper/lodash'
import ShareComponent from '@/components/Layout/Share'
import QuickMenuComponent from '@/components/Layout/Quick'

export default {
  name: 'Detail',
  props: ['childValue', 'type'],
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    ShareComponent,
    QuickMenuComponent
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  data () {
    return {
      news: [],
      topicText: '',
      topicType: '',
      headerTitle: '',
      topic: '',
      time: '',
      nationCode: '',
      selConId: '',
      categoryCode: '',
      getSessionContent: [], /* GL : 불러오는 값 */
      contentList: [],
      detailId: 0,
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: [
            ]
          }
        ]
      },
      countryCode: '',
      countryCodeSub: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)

    const detailId = this.$route.params.id
    const contentRes = await ProductService.getContent(detailId)
    this.contentList = contentRes.data
    this.time = contentRes.data.content.createDate
    this.topicType = contentRes.data.attribute.topicType
    this.topicText = contentRes.data.attribute.topicEditor
    this.headerTitle = contentRes.data.content.title
    this.topic = contentRes.data.attribute.topicTypeText
    this.nationCode = this.$countryCode
    this.contentRes = contentRes
    if (this.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          this.getSessionContent.push(o.detailId)
        }
      })
    }
    this.$nextTick(function () {
      $('a').on('click', function (e) {
        if (typeof (dataLayer) !== 'undefined') {
          if (e.currentTarget.name != null && e.currentTarget.name != '') {
            dataLayer.push({ event: e.currentTarget.name })
          }
        }
      })
    })
  },

  async mounted () {
    this.detailId = Number(this.$route.params.id)
    this.categoryCode = this.$route.params.categoryCode + this.nationCode
    this.nationCode = this.$countryCode
    const newsRes = await ProductService.getCategoryCode('NEWS_LIST' + this.nationCode)
    this.dictionaryCondition.list[0].values.push({ id: 'topicType', value: this.topicType })
    const res = await AboutUsService.getContentListTapField(newsRes.data.object.id, this.dictionaryCondition,
      '', 1, 10, 'createdAt', 'desc')
    this.news = res.data.list

    this.$nextTick(function () {
      $('.sns-wrp .sns').each(function (idx, ele) {
        var $this = $(this)
        $(ele).prev().click(function () {
          $this.slideToggle(300)
          $this.closest('tr').siblings().children().find('.sns').hide()
        })
      })

      /* Related Products slider */
      $('.slider-related').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        centerPadding: '35px',
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      })

      /* News slider */
      $('.slider-news').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      })
    })
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    openSampleOrder (detailId) {
      this.selConId = detailId
      this.$root.$refs.sampleOrderView.openPopup(detailId)
    },
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },
    alertAndGoLogin () {
      alert(this.$t('newsDetail.alert.login_likes'))
      this.$utils.goLogin()
    },
    toggleLike (sr, cateType) {
      const t = this
      if (sr.parents[2].name == 'HFLOR') {
        cateType = 'HFLOR'
      }
      if (sr.parents[1].name == 'HIMACS') {
        cateType = 'HIMACS'
      }
      if (sr.parents[1].name == 'VIATERA') {
        cateType = 'Viatera'
      }
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode != '_US') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (o.nationCode == t.nationCode) {
              t.getSessionContent.push(o.detailId)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    }
  }
}
</script>
