<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>COLOR</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box color_round">
        <span class="checkbox_more no_line">
          <li v-for="color in colorList" :key="color.id.value">
            <div class="checkbox-item">
              <input type="checkbox" :id="'hue_'+color.id.value" name="color" v-model="selected" :value="color.id.value" />
              <label :for="`hue_${color.id.value}`">
                <span class="round" :style="{ backgroundColor: '#' + color.id.value }"></span>
                <span class="text">{{ color.text }}</span>
              </label>
            </div>
          </li>
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'ColorFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['colorList', 'value'],
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-color-filter', this.removeColor)
    EventBus.$on('update-color-filters', this.updateColors)

    if (this.$store.state.filterState && this.$store.state.filterState.hue) {
      this.selected = this.$store.state.filterState.hue
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$off('remove-color-filter', this.removeColor)
    EventBus.$off('update-color-filters', this.updateColors)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeColor (colorValue) {
      this.selected = this.selected.filter(value => value !== colorValue)
    },
    updateColors (colors) {
      this.selected = colors
    },
    getColorName (colorId) {
      const color = this.colorList.find(color => color.id.value === colorId)
      return color ? color.text : ''
    }
  }
}
</script>
