var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-cate" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("PCD.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            this.pdCategoryType === "DECO"
              ? _c("li", [_vm._v(" Deco Foil ")])
              : _c("li", [_vm._v(_vm._s(_vm.pdCategoryType))]),
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                on: {
                  click: function ($event) {
                    _vm.gotolink(
                      `/${_vm.countryCodeSub.toLowerCase()}/products/${_vm.getlink()}`
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      `PF.text.allColors.${_vm.$route.meta.parentType.toLowerCase()}`
                    )
                  )
                ),
              ]
            ),
            this.pdCategoryType === "HFLOR"
              ? _c(
                  "li",
                  {
                    directives: [
                      { name: "hover-cursor", rawName: "v-hover-cursor" },
                    ],
                    on: {
                      click: function ($event) {
                        _vm.gotolink(
                          `/${_vm.countryCodeSub.toLowerCase()}/products/${_vm.getSublink()}`
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.prdLabel))]
                )
              : _vm._e(),
            this.pdCategoryType === "VIZUON"
              ? _c(
                  "li",
                  {
                    directives: [
                      { name: "hover-cursor", rawName: "v-hover-cursor" },
                    ],
                    on: {
                      click: function ($event) {
                        _vm.gotolink(
                          `/${_vm.countryCodeSub.toLowerCase()}/products/${_vm.getSublink()}`
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          `PCD.text.sub.${_vm.$route.meta.type.toLowerCase()}`
                        )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _c("li", [_vm._v(_vm._s(_vm.contentName))]),
          ]),
        ]),
        _c("div", { staticClass: "pro-dt-warp viatera-pd" }, [
          _c("div", { class: _vm.pdContainerCssClass }, [
            _c("div", { staticClass: "w-con product_pd_mt" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "prod-img" }, [
                  _c(
                    "div",
                    { staticClass: "slider-prod viartW pd-slider" },
                    _vm._l(_vm.imageInfo.imgPage, function (fileName, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imgLoaded === true,
                              expression: "imgLoaded === true",
                            },
                          ],
                          key: index,
                          staticClass: "slider pdf-inslide03",
                        },
                        [
                          fileName.enlarge
                            ? _c("div", { staticClass: "detail_view_wrap" }, [
                                _vm._m(0, true),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn_detail_view",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.enlargeLayer("open")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "icon icon_detail",
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          fileName.youtube != true
                            ? _c("div", { staticClass: "img_wrap" }, [
                                _c("div", { staticClass: "big_look_wrap" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn_big_look",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.aBigLookLayer(
                                            "open",
                                            fileName,
                                            _vm.$imagePath,
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon icon_look",
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("img", {
                                  staticClass: "pd_product_img",
                                  attrs: {
                                    src: _vm.$imagePath + fileName.image,
                                    alt: "product",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          fileName.youtube == true
                            ? _c(
                                "div",
                                {
                                  staticClass: "yt-video",
                                  style:
                                    "max-width:" +
                                    _vm.youtubeWidth +
                                    ";" +
                                    "margin: 0 auto",
                                },
                                [
                                  _c("iframe", {
                                    staticClass: "yt_player_iframe",
                                    staticStyle: {
                                      width: "auto",
                                      "min-width": "100%",
                                      "min-height": "100%",
                                    },
                                    attrs: {
                                      autoplay: "autoplay",
                                      muted: "muted",
                                      controls: "",
                                      allowscriptaccess: "always",
                                      src: fileName.image + "?enablejsapi=1",
                                      alt: "youtube",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "slider-prod-nav navWide tac" },
                    _vm._l(_vm.imageInfo.imgPage, function (fileName, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imgLoaded === true,
                              expression: "imgLoaded === true",
                            },
                          ],
                          key: index,
                          staticClass: "slider",
                          staticStyle: { position: "relative" },
                        },
                        [
                          !_vm.$_.isEmpty(fileName.image) &&
                          !_vm.$_.isEmpty(fileName.thumb)
                            ? _c("img", {
                                attrs: {
                                  "data-chk": index,
                                  src: _vm.$imagePath + fileName.thumb,
                                  alt: "thumbnail",
                                },
                              })
                            : _vm._e(),
                          fileName.youtube == true
                            ? _c("div", { staticClass: "youtube_thumb" }, [
                                _c("i", { staticClass: "icon_youtube_play" }),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "prod-info" }, [
                  _c("div", { staticClass: "prod_info_head" }, [
                    _c("div", { staticClass: "info-01" }, [
                      _vm.newColor == "true"
                        ? _c("span", { staticClass: "tag h30 new" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.new"))),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "btns" }, [
                      _c("ul", [
                        _vm.showImgDownload()
                          ? _c("li", { attrs: { id: "imgDownload" } }, [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-download-bk",
                                  on: {
                                    click: function ($event) {
                                      return _vm.imgDownload()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("PD.text.image_download_view")
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "li",
                          [
                            _c("ShareComponent", {
                              attrs: {
                                childValue: _vm.contentList,
                                type: "product",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "prod_info_body" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.contentName)),
                    ]),
                    _c("div", { staticClass: "info-01" }, [
                      _vm.colorCode &&
                      _vm.colorCode.trim() !== "" &&
                      _vm.pdCategoryType !== "VIATERA" &&
                      _vm.pdCategoryType !== "TERACANTO" &&
                      _vm.pdCategoryType !== "BENIF"
                        ? _c("span", { staticClass: "prod_code" }, [
                            _vm._v(_vm._s(_vm.colorCode)),
                          ])
                        : _vm._e(),
                      _vm.colorCode &&
                      _vm.colorCode.trim() !== "" &&
                      _vm.pdCategoryType !== "VIATERA" &&
                      _vm.pdCategoryType !== "TERACANTO" &&
                      _vm.pdCategoryType !== "BENIF"
                        ? _c("span", { staticClass: "separate_line" }, [
                            _vm._v("|"),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "prod_collection" }, [
                        _vm._v(
                          _vm._s(_vm.collection) +
                            " " +
                            _vm._s(_vm.$t("PCD.text.collection"))
                        ),
                      ]),
                    ]),
                    _c("p", {
                      staticClass: "info-03 ctt ctt-t4",
                      domProps: { innerHTML: _vm._s(_vm.brief) },
                    }),
                  ]),
                  _c("div", { staticClass: "prod_info_foot" }, [
                    _c("div", { staticClass: "btn_area" }, [
                      _vm.nationCode === "_US"
                        ? _c(
                            "div",
                            {
                              staticClass: "row roomvo-button-area",
                              staticStyle: { visibility: "hidden" },
                              attrs: {
                                "data-product-id": _vm.contentId,
                                "data-sku": _vm.sku,
                              },
                            },
                            [_vm._m(1), _vm._m(2)]
                          )
                        : _vm._e(),
                      _vm.nationCode === "_US" &&
                      !_vm.canOrder(_vm.contentDetail)
                        ? _c("div", [
                            _vm._m(3),
                            _c("span", { staticClass: "ctt ctt-t5 not_yet" }, [
                              _vm._v("Not yet available"),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.nationCode === "_US" &&
                      _vm.canOrder(_vm.contentDetail)
                        ? _c(
                            "button",
                            {
                              staticClass: "button h55 btn_sample",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.openSampleOrder(_vm.contentId)
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "icon icon_sample on",
                              }),
                              _c("span", { staticClass: "txt" }, [
                                _vm._v("Sample Order"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "prod-wrap specs" }, [
              _vm._m(4),
              _c("div", { staticClass: "row_scroll" }, [
                _c("table", [
                  this.pdCategoryType === "BENIF" ||
                  this.pdCategoryType === "EXTERIOR" ||
                  this.pdCategoryType === "BORTE"
                    ? _c("colgroup", [
                        _c("col", { staticStyle: { width: "20%" } }),
                        _c("col", { staticStyle: { width: "20%" } }),
                        _c("col", { staticStyle: { width: "20%" } }),
                        _c("col", { staticStyle: { width: "20%" } }),
                        _c("col", { staticStyle: { width: "20%" } }),
                      ])
                    : this.pdCategoryType === "VIATERA" ||
                      this.pdCategoryType === "HIMACS" ||
                      this.pdCategoryType === "HFLOR" ||
                      this.pdCategoryType === "TERACANTO" ||
                      this.pdCategoryType === "DECO"
                    ? _c("colgroup", [
                        _c("col", { staticStyle: { width: "17%" } }),
                        _c("col", { staticStyle: { width: "17%" } }),
                        _c("col", { staticStyle: { width: "17%" } }),
                        _c("col", { staticStyle: { width: "16%" } }),
                        _c("col", { staticStyle: { width: "16%" } }),
                        _c("col", { staticStyle: { width: "16%" } }),
                      ])
                    : this.pdCategoryType === "VIZUON"
                    ? _c("colgroup", [
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "14%" } }),
                        _c("col", { staticStyle: { width: "15%" } }),
                      ])
                    : _vm._e(),
                  this.pdCategoryType === "VIZUON"
                    ? _c("thead", [
                        _vm.productSpecs.length > 0
                          ? _c("tr", [
                              _c("th"),
                              _vm._m(5),
                              _vm._m(6),
                              _vm._m(7),
                              _vm._m(8),
                              _vm._m(9),
                              _vm._m(10),
                              _c("th", [_vm._v("Liner")]),
                            ])
                          : _vm._e(),
                      ])
                    : _c("thead", [
                        _vm.productSpecs.length > 0
                          ? _c(
                              "tr",
                              _vm._l(_vm.productSpecs[0], function (item) {
                                return _c(
                                  "th",
                                  [
                                    _vm._l(item, function (value, key) {
                                      return [_vm._v(" " + _vm._s(key) + " ")]
                                    }),
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.productSpecs, function (spec) {
                      return _c(
                        "tr",
                        _vm._l(spec, function (item) {
                          return _c(
                            "td",
                            [
                              _vm._l(item, function (value, key) {
                                return [_vm._v(" " + _vm._s(value) + " ")]
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "prod-wrap docu" }, [
              _c("div", { staticClass: "content_tit_area" }, [
                _c("h2", { staticClass: "con_title tt-2" }, [
                  _vm._v("DOCUMENTATIONS & TECHNICAL DETAIL"),
                ]),
                _c("div", { staticClass: "more" }, [
                  _c(
                    "a",
                    {
                      staticClass: "view_more",
                      attrs: {
                        href: `/${_vm.countryCodeSub.toLowerCase()}/pros/documentation/${_vm.getDocName(
                          _vm.pdCategoryType
                        )}`,
                      },
                    },
                    [
                      _c("span", [_vm._v("View more")]),
                      _c("span", { staticClass: "icon" }),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "prod-content docu" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "half_item" }, [
                    _c("div", { staticClass: "docu-accordion" }, [
                      _vm._m(11),
                      _c("div", { staticClass: "docu-contents" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.documentation.catalogueList.list.slice(0, 10),
                              function (cata, index) {
                                return Array.isArray(
                                  _vm.documentation.catalogueList.list
                                ) &&
                                  _vm.documentation.catalogueList.list.length >
                                    0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "docu-content" },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn_download_icon",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(cata)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.button.download"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e()
                              }
                            ),
                            _vm.documentation.catalogueList.list.length == 0
                              ? _c("li", { staticClass: "nodatalist" }, [
                                  _c("span", { staticClass: "nodata" }, [
                                    _vm._v("No Data"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "docu-accordion" }, [
                      _vm._m(12),
                      _c("div", { staticClass: "docu-contents" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.documentation.technicalList.list.slice(0, 10),
                              function (cata, index) {
                                return Array.isArray(
                                  _vm.documentation.technicalList.list
                                ) &&
                                  _vm.documentation.technicalList.list.length >
                                    0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "docu-content" },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn_download_icon",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(cata)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.button.download"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e()
                              }
                            ),
                            _vm.documentation.technicalList.list.length == 0
                              ? _c("li", { staticClass: "nodatalist" }, [
                                  _c("span", { staticClass: "nodata" }, [
                                    _vm._v("No Data"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "docu-accordion" }, [
                      _vm._m(13),
                      _c("div", { staticClass: "docu-contents" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.documentation.warrantyList.list.slice(0, 10),
                              function (cata, index) {
                                return Array.isArray(
                                  _vm.documentation.warrantyList.list
                                ) &&
                                  _vm.documentation.warrantyList.list.length > 0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "docu-content" },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn_download_icon",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(cata)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.button.download"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e()
                              }
                            ),
                            _vm.documentation.warrantyList.list.length == 0
                              ? _c("li", { staticClass: "nodatalist" }, [
                                  _c("span", { staticClass: "nodata" }, [
                                    _vm._v("No Data"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "half_item" }, [
                    _c("div", { staticClass: "docu-accordion" }, [
                      _vm._m(14),
                      _c("div", { staticClass: "docu-contents" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.documentation.certificationList.list.slice(
                                0,
                                10
                              ),
                              function (cata, index) {
                                return Array.isArray(
                                  _vm.documentation.certificationList.list
                                ) &&
                                  _vm.documentation.certificationList.list
                                    .length > 0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "docu-content" },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn_download_icon",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(cata)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.button.download"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e()
                              }
                            ),
                            _vm.documentation.certificationList.list.length == 0
                              ? _c("li", { staticClass: "nodatalist" }, [
                                  _c("span", { staticClass: "nodata" }, [
                                    _vm._v("No Data"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "docu-accordion" }, [
                      _vm._m(15),
                      _c("div", { staticClass: "docu-contents" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.documentation.installationGuideList.list.slice(
                                0,
                                10
                              ),
                              function (cata, index) {
                                return Array.isArray(
                                  _vm.documentation.installationGuideList.list
                                ) &&
                                  _vm.documentation.installationGuideList.list
                                    .length > 0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "docu-content" },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn_download_icon",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(cata)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.button.download"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e()
                              }
                            ),
                            _vm.documentation.installationGuideList.list
                              .length == 0
                              ? _c("li", { staticClass: "nodatalist" }, [
                                  _c("span", { staticClass: "nodata" }, [
                                    _vm._v("No Data"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "docu-accordion" }, [
                      _vm._m(16),
                      _c("div", { staticClass: "docu-contents" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              _vm.documentation.careList.list.slice(0, 10),
                              function (cata, index) {
                                return Array.isArray(
                                  _vm.documentation.careList.list
                                ) && _vm.documentation.careList.list.length > 0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "docu-content" },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "btn_download_icon",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(cata)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.button.download"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e()
                              }
                            ),
                            _vm.documentation.careList.list.length == 0
                              ? _c("li", { staticClass: "nodatalist" }, [
                                  _c("span", { staticClass: "nodata" }, [
                                    _vm._v("No Data"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm.collectionTongTitle && _vm.collectionTongtongImagePath
              ? _c("div", { staticClass: "prod-wrap edge" }, [
                  _c("div", { staticClass: "content_tit_area" }, [
                    _c("h2", {
                      staticClass: "con_title tt-2",
                      domProps: { innerHTML: _vm._s(_vm.collectionTongTitle) },
                    }),
                  ]),
                  _c("div", { staticClass: "row_scroll" }, [
                    _c("div", { staticClass: "prod-content edge" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.$imagePath + _vm.collectionTongtongImagePath,
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.relContentList != null &&
            _vm.relContentList != undefined &&
            _vm.relContentList != ""
              ? _c("div", { staticClass: "prod-wrap related" }, [
                  _vm._m(17),
                  _c("button", { staticClass: "arrow-prev" }, [_vm._v("pr")]),
                  _c("button", { staticClass: "arrow-next" }, [_vm._v("ne")]),
                  _c("div", { staticClass: "row_scroll" }, [
                    _c(
                      "div",
                      { staticClass: "prod-content related mo_pd_rela_slide" },
                      [
                        _vm.isMobile()
                          ? _vm._l(_vm.relContentList, function (item, index) {
                              return _c(
                                "div",
                                { staticClass: "related-content" },
                                [
                                  _c(
                                    "div",
                                    { key: index, staticClass: "thumb" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.makeUrl(item),
                                            "data-ec-product":
                                              _vm.makeDataLayerForm(item),
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "over" }, [
                                            item.attribute.isNew === "true"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "tag h30 new",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("PCD.text.new")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              { staticClass: "content_title" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.content.title)
                                                ),
                                              ]
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.$imagePath +
                                                  item.attribute.pf_thumb,
                                                alt: "thumb",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm.nationCode === "_US" &&
                                      _vm.canOrder(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "icon_cart",
                                              attrs: { tabindex: "0" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSampleOrder(
                                                    item.content.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PCD.text.cart"))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            })
                          : _vm._l(
                              Math.ceil(_vm.relContentList.length / 8),
                              function (i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "related-content" },
                                  _vm._l(
                                    _vm.relContentList.slice(
                                      (i - 1) * 8,
                                      i * 8
                                    ),
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "thumb" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.makeUrl(item),
                                                "data-ec-product":
                                                  _vm.makeDataLayerForm(item),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "over" },
                                                [
                                                  item.attribute.isNew ===
                                                  "true"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tag h30 new",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.new"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "content_title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.content.title
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        _vm.$imagePath +
                                                        item.attribute.pf_thumb,
                                                      alt: "thumb",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.nationCode === "_US" &&
                                          _vm.canOrder(item)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "icon_cart",
                                                  attrs: { tabindex: "0" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openSampleOrder(
                                                        item.content.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("PCD.text.cart")
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              }
                            ),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.pcdContentAttribute.template_use_yn === "true"
              ? _c("div", { staticClass: "why_section" }, [
                  _c("div", { staticClass: "why_top_bg full_width" }, [
                    _c(
                      "div",
                      { staticClass: "inner_width" },
                      [
                        _c("div", { staticClass: "content_tit_area" }, [
                          _c("h2", { staticClass: "con_title tt-2" }, [
                            _vm._v(
                              _vm._s(_vm.pcdContentAttribute.template_title)
                            ),
                          ]),
                        ]),
                        _vm._l(
                          Object.keys(_vm.pcdTemplateFieldData.image),
                          function (images, index) {
                            return _vm._l(
                              Object.keys(
                                _vm.pcdTemplateFieldData.image[index]
                              ),
                              function (imageKey, index2) {
                                return _c(
                                  "div",
                                  { class: `why_row col_0${imageKey}` },
                                  _vm._l(
                                    _vm.pcdTemplateFieldData.image[index][
                                      imageKey
                                    ],
                                    function (imageItem, index3) {
                                      return _c(
                                        "div",
                                        { staticClass: "why_item" },
                                        [
                                          _c("div", { staticClass: "img" }, [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.$imagePath +
                                                  imageItem.img,
                                                alt: imageItem.title,
                                              },
                                            }),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "description_box" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "title tt-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(imageItem.title)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "txt ctt ctt-t3",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(imageItem.desc) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              }
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm.pcdTemplateFieldData.icon.length
                    ? _c("div", { staticClass: "w-con" }, [
                        _c(
                          "div",
                          { staticClass: "row combo_area" },
                          [
                            _vm._l(
                              _vm.pcdTemplateFieldData.icon,
                              function (iconItem, index) {
                                return [
                                  iconItem.img
                                    ? _c(
                                        "div",
                                        { staticClass: "content_item" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "icon_box" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.$imagePath +
                                                    iconItem.img,
                                                  alt: iconItem.title,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "description_box" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "title tt-30" },
                                                [_vm._v(_vm._s(iconItem.title))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "txt ctt ctt-t3",
                                                },
                                                [_vm._v(_vm._s(iconItem.desc))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.pcdContentAttribute.cert_use_yn === "true"
              ? _c("div", { staticClass: "certification_section full_width" }, [
                  _c("div", { staticClass: "inner_width" }, [
                    _c("div", { staticClass: "content_tit_area" }, [
                      _c("h2", { staticClass: "con_title tt-2" }, [
                        _vm._v(_vm._s(_vm.pcdContentAttribute.cert_title)),
                      ]),
                    ]),
                    _c("div", { staticClass: "certi_group" }, [
                      _c(
                        "ul",
                        _vm._l(
                          _vm.pcdCertificationData,
                          function (certItem, index) {
                            return _c("li", [
                              _c("div", { staticClass: "item" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.$imagePath + certItem.image,
                                    alt: certItem.description,
                                  },
                                }),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                      _vm.pcdCertificationData.length &&
                      _vm.checkCertificationDescription("description")
                        ? _c("div", { staticClass: "btn_area" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn_learn_more",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModalCerti()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v("Learn more"),
                                ]),
                                _c("span", {
                                  staticClass: "icon icon_arrow_right",
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.pcdContentAttribute.inpg_use_yn === "true"
              ? _c("div", { staticClass: "inspiration_section full_width" }, [
                  _c("div", { staticClass: "inner_width" }, [
                    _c("div", { staticClass: "content_tit_area" }, [
                      _c("h2", { staticClass: "con_title tt-2" }, [
                        _vm._v(
                          _vm._s(_vm.pcdContentAttribute.inpg_title) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "more" }, [
                        _c(
                          "a",
                          {
                            staticClass: "view_more",
                            attrs: {
                              href: `inspiration/gallery/${_vm.getPcdTypeName(
                                _vm.pdCategoryType
                              )}/product_cate`,
                            },
                          },
                          [
                            _c("span", [_vm._v("View more")]),
                            _c("span", { staticClass: "icon" }),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "gallery" }, [
                      _c("div", { staticClass: "grid-box" }, [
                        _c(
                          "ul",
                          { staticClass: "ins_grid" },
                          _vm._l(
                            _vm.pcdInspirationList,
                            function (inspirationItem, index) {
                              return _c(
                                "li",
                                { staticClass: "gal-grid-cont" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "g-box",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.gotolink(
                                            `/${_vm.countryCodeSub.toLowerCase()}/inspiration/gallery-detail?contentId=${
                                              inspirationItem.content.id
                                            }`
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "img-box" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.$imagePath +
                                              inspirationItem.attribute
                                                .mainThumbnail,
                                            alt: inspirationItem.content.title,
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "txt-box" }, [
                                        _c(
                                          "p",
                                          { staticClass: "txt-cate" },
                                          [
                                            _vm._l(
                                              inspirationItem.attribute
                                                .product_cateText,
                                              function (productItem) {
                                                return [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML:
                                                        _vm._s(productItem),
                                                    },
                                                  }),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "content_title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                inspirationItem.content.title
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "hashtag_group" },
                                          _vm._l(
                                            inspirationItem.attribute
                                              .applicationList,
                                            function (tagItem, tagIndex) {
                                              return _c(
                                                "span",
                                                { staticClass: "hashtag" },
                                                [
                                                  _vm._v(
                                                    "#" + _vm._s(tagItem.text)
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "layer-popup layer-enlarge" }, [
            _c(
              "div",
              { staticClass: "popup" },
              [
                _c("div", { staticClass: "title-box" }, [
                  _c("p", { staticClass: "product-name" }, [
                    _vm._v(_vm._s(_vm.contentName)),
                  ]),
                ]),
                _vm.showDragon
                  ? _c("SeaDragon", {
                      attrs: {
                        imagePath:
                          _vm.$imagePath + _vm.imageInfo.imgPage[0].detail,
                      },
                    })
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn-close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.enlargeLayer()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("PCD.button.close")) + " ")]
                ),
              ],
              1
            ),
          ]),
          _vm._m(18),
          _vm.pcdContentAttribute.cert_use_yn === "true"
            ? _c(
                "div",
                {
                  staticClass:
                    "layer-view layer-pop popup-w1200 modal-certification",
                },
                [
                  _c("div", { staticClass: "popup pop-close" }, [
                    _c("div", { staticClass: "scr-y" }, [
                      _c("h3", { staticClass: "popup-title p-ui" }, [
                        _vm._v("Certification Description"),
                      ]),
                      _vm._m(19),
                      _c("div", { staticClass: "popup-body" }, [
                        _c("div", { staticClass: "certi_pop_con" }, [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.pcdCertificationData,
                              function (certItem, index) {
                                return _c("li", [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "img_wrap" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.$imagePath + certItem.image,
                                          alt: certItem.description,
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "txt_wrap" }, [
                                      _c("div", { staticClass: "tit_box" }, [
                                        _vm._v(_vm._s(certItem.title)),
                                      ]),
                                      _c("div", { staticClass: "txt_box" }, [
                                        _vm._v(_vm._s(certItem.description)),
                                      ]),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.closeModalCerti()
                          },
                        },
                      },
                      [_vm._v("close")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bubble_pop" }, [
      _c("span", [_vm._v("Detailed view")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "roomvo-container" }, [
      _c(
        "button",
        {
          staticClass:
            "button h55 btn_visualizer roomvo-stimr-mobile roomvo-stimr",
          attrs: { type: "button" },
        },
        [
          _c("span", { staticClass: "icon icon_visual" }),
          _c("span", { staticClass: "txt" }, [_vm._v("Visualizer")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "roomvo-container_3d" }, [
      _c(
        "button",
        {
          staticClass:
            "button h55 btn_view3d roomvo-product-display-button-mobile roomvo-product-display-button",
          attrs: { type: "button" },
        },
        [
          _c("span", { staticClass: "icon icon_view3d" }),
          _c("span", { staticClass: "txt" }, [_vm._v("View in 3D")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "button h55 btn_sample not_available",
        attrs: { type: "button" },
      },
      [
        _c("span", { staticClass: "icon icon_sample on" }),
        _c("span", { staticClass: "txt" }, [_vm._v("Sample Order")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_tit_area" }, [
      _c("h2", { staticClass: "con_title tt-2" }, [_vm._v("PRODUCT SPECS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Film Type"), _c("br"), _vm._v("Material")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Film Type"), _c("br"), _vm._v("Thickness")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Film Type"), _c("br"), _vm._v("Color")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Film Type"), _c("br"), _vm._v("Adhesive Type")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Adhesive Type"), _c("br"), _vm._v("Color")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Adhesive Type"), _c("br"), _vm._v("Air Free")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docu-title" }, [
      _c("p", [_vm._v("CATALOG")]),
      _c("div", { staticClass: "docu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docu-title" }, [
      _c("p", [_vm._v("TECHNICAL DATASHEET")]),
      _c("div", { staticClass: "docu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docu-title" }, [
      _c("p", [_vm._v("WARRANTY")]),
      _c("div", { staticClass: "docu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docu-title" }, [
      _c("p", [_vm._v("CERTIFICATION")]),
      _c("div", { staticClass: "docu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docu-title" }, [
      _c("p", [_vm._v("INSTALLATION GUIDE")]),
      _c("div", { staticClass: "docu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docu-title" }, [
      _c("p", [_vm._v("CARE & MAINTENANCE")]),
      _c("div", { staticClass: "docu-arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_tit_area" }, [
      _c("h2", { staticClass: "con_title tt-2" }, [_vm._v("RELATED PRODUCTS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "layer-view layer-pop popup-w1440 modal-big-look" },
      [
        _c(
          "button",
          {
            staticClass: "btn-close m-ui",
            attrs: { type: "button", onClick: "tmpClose()" },
          },
          [_vm._v("close")]
        ),
        _c("div", { staticClass: "popup pop-close" }, [
          _c("div", { staticClass: "scr-y" }, [
            _c("div", { staticClass: "popup-body no_padding" }, [
              _c("div", { staticClass: "popup_slider" }),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-close p-ui",
              attrs: { type: "button", onClick: "tmpClose()" },
            },
            [_vm._v("close")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "popup-title m-ui" }, [
      _vm._v("Certification "),
      _c("br"),
      _vm._v(" Description"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }