var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-tab-area" },
    [
      _c("TabHeader", {
        attrs: { item: _vm.headerTitle },
        on: { change: _vm.onChangeActive },
      }),
      _c("div", { staticClass: "item" }, [_vm._t(_vm.componentName)], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }