var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overview-big-card-icon-area-root" }, [
    _c("div", { staticClass: "overview-big-card-icon-area" }, [
      _c("div", { staticClass: "overview-big-card-area" }, [
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "title-area" }, [
            _vm._v(" " + _vm._s(_vm.title1) + " "),
          ]),
          _c("div", { staticClass: "main-text-area" }, [
            _vm._v(" " + _vm._s(_vm.mainTxt1) + " "),
          ]),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "text-area" }, [
          _c("div", { staticClass: "title-area" }, [
            _vm._v(" " + _vm._s(_vm.title2) + " "),
          ]),
          _c("div", { staticClass: "main-text-area" }, [
            _vm._v(" " + _vm._s(_vm.mainTxt2) + " "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "icon-area" }, [
        _c("img", { attrs: { src: _vm.iconSrc } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }