var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "component-part-area" },
    [
      _c("ProductSectionHeader", {
        attrs: { title: "Exterior & Engine Part" },
      }),
      _vm._m(0),
      _c("ProductComponentGroup", {
        staticClass: "mt-100 mx-30",
        attrs: { left: _vm.leftComponentInfo, right: _vm.rightComponentInfo },
        scopedSlots: _vm._u([
          {
            key: "left-header",
            fn: function () {
              return [
                _c("div", { staticClass: "part-section-head-area mb-50" }, [
                  _c("div", { staticClass: "icon-area" }, [
                    _c("img", {
                      attrs: {
                        src: "images/automotive/component/exterior-part-icon-1.png",
                        alt: "icon",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "text-area" }, [
                    _c("span", [_vm._v("Exterior Part")]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "right-header",
            fn: function () {
              return [
                _c("div", { staticClass: "part-section-head-area mb-50" }, [
                  _c("div", { staticClass: "icon-area" }, [
                    _c("img", {
                      attrs: {
                        src: "images/automotive/component/exterior-part-icon-2.png",
                        alt: "icon",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "text-area" }, [
                    _c("span", [_vm._v("Engine Part")]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-50" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/2024/images/products/auto_part-image.png",
          alt: "autopart",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }