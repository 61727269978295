<template>
  <div class="application-area">
    <ProductContent>
      <template>
        <!-- 제목 -->
        <ProductSectionHeader
          class="mt-100"
          title="Application"
        />

        <div class="carousel-area">
          <!-- 텍스트 영역 -->
          <div class="text-area">
            <span>Home Appliance Surface Film</span><br /><br />
            <span class="title">{{ selectedItem.title }}</span>
          </div>

          <!-- 캐러셀 영역 -->
          <ProductCarousel
            :useDot="false"
            :useArrows="true"
            @change="onChange"
          >
            <div
              class="main-image-area"
              v-for="(it, idx) in item"
              :key="idx"
            >
              <img
                :src="it.main"
                draggable="false"
              />
            </div>

            <template #custom="pageData">
              <ThumbSelector
                :images="thumbImages"
                :data="pageData"
              />
            </template>
          </ProductCarousel>
        </div>
      </template>
    </ProductContent>
  </div>
</template>

<script>
import ProductCarousel from '@/components/product/carousel'
import ProductContent from '@/components/product/ProductContent'
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ThumbSelector from './ThumbSelector'

export default {
  components: {
    ProductSectionHeader,
    ProductCarousel,
    ProductContent,
    ThumbSelector
  },

  data: () => ({
    item: [
      {
        title: 'Pastel Metal',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/pastel-metal.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/pastel-metal-thum.png'
      },
      {
        title: 'Soft Matte',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/soft-matte.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/soft-matte-thum.png'
      },
      {
        title: 'Horizontal Hairline (Silver, Short)',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/horizontal-hairline-silver-short.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/horizontal-hairline-silver-short-thum.png'
      },
      {
        title: 'Horizontal Hairline (Black)',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/horizontal-hairline-black.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/horizontal-hairline-black-thum.png'
      },
      {
        title: 'Iridium Hairline',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/iridium-hairline.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/iridium-hairline-thum.png'
      },
      {
        title: 'Horizontal Hairline (Dark-grey)',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/horizontail-hairline-dark-grey.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/horizontail-hairline-dark-grey-thum.png'
      },
      {
        title: 'Glass Fiber',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/glass-fiber.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/glass-fiber-thum.png'
      },
      {
        title: 'Horizontal Hairline (Silver, Long)',
        main: 'images/industrialFilm/VinylCoatedMaterial/application/horizontal-hairline-silver-long.png',
        thumb: 'images/industrialFilm/VinylCoatedMaterial/application/horizontal-hairline-silver-long-thum.png'
      }
    ],
    selectedIndex: 0
  }),

  computed: {
    selectedItem () {
      return this.item[this.selectedIndex]
    },
    thumbImages () {
      return this.item.map((it) => it.thumb)
    }
  },

  methods: {
    onChange (idx) {
      this.selectedIndex = idx
    }
  }
}
</script>

<style scoped>
.application-area {
  background-color: #F1F1F5;
  margin-bottom: 150px;
}

.application-area .main-image-area {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.application-area .main-image-area img {
  max-width: 942px;
  object-fit: cover;
}

.application-area .carousel-area .text-area {
  max-width: 270px;
  min-height: 120px;
  position: relative;
  margin-top: 50px;
}
@media (min-width: 769px) {
  .application-area .carousel-area .text-area {
    top: 50px;
  }
}

.application-area .carousel-area .text-area span {
  color: #000;
  font-family: 'NotoSans' , sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.application-area .carousel-area .text-area span.title {
  color: #000;
  font-family: 'NotoSans' , sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
