import _ from '@/common/helper/lodash'
import request from './request'
const urlPrefix = ''

const mailchimpService = {
  subscribe (postData) {
    return request({
      url: `${urlPrefix}/mailchimp/subscribe`,
      method: 'post',
      data: postData
    })
  }
}

export default mailchimpService
