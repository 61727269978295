var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hder-section sch-ui" }, [
    _c("div", { staticClass: "hd-sec-cont sch-area" }, [
      _c("div", { staticClass: "sch-top" }, [
        _vm._m(0),
        _c("div", { staticClass: "w-con" }, [
          _c("div", { staticClass: "sch-frm" }, [
            _c("label", { staticClass: "blind", attrs: { for: "inpHdSch" } }, [
              _vm._v("Input Search Keyword"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchWord,
                  expression: "searchWord",
                },
              ],
              staticClass: "ipt-hd-sch wrd-on",
              attrs: {
                type: "text",
                id: "inpHdSch",
                placeholder: _vm.$t("search.enter_search_word"),
              },
              domProps: { value: _vm.searchWord },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.doSearch.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchWord = $event.target.value
                },
              },
            }),
            _c(
              "button",
              {
                staticClass: "btn-hd-sch",
                attrs: { type: "button" },
                on: { click: _vm.doSearch },
              },
              [_c("span", { staticClass: "blind" }, [_vm._v("Search Button")])]
            ),
          ]),
        ]),
      ]),
      !(_vm.showSearchResult && !_vm.noSearchResult()) &&
      _vm.searchHomeWords.length > 0
        ? _c("div", { staticClass: "sch-btm" }, [
            _c("div", { staticClass: "w-con" }, [
              _c("div", { staticClass: "sch-recent" }, [
                _c("p", { staticClass: "sch-rcnt-tit" }, [
                  _vm._v(_vm._s(_vm.$t("search.recent_search"))),
                ]),
                _c(
                  "ul",
                  { staticClass: "sch-rcnt-list" },
                  _vm._l(_vm.searchHomeWords, function (sw) {
                    return _c("li", [
                      _c(
                        "span",
                        {
                          staticClass: "sch-rcnt-keyw",
                          on: {
                            click: function ($event) {
                              _vm.searchWord = sw
                              _vm.doSearch()
                            },
                          },
                        },
                        [_vm._v(_vm._s(sw))]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn-rcnt-del",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteRecent(sw)
                            },
                          },
                        },
                        [_vm._v("delete keyword button")]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm.popularWords.length > 0
                ? _c("div", { staticClass: "sch-recent" }, [
                    _c("p", { staticClass: "sch-rcnt-tit" }, [
                      _vm._v(_vm._s(_vm.$t("search.popular_search"))),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "sch-rcnt-list" },
                      _vm._l(_vm.popularWords, function (sw) {
                        return _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                _vm.searchWord = sw
                                _vm.doSearch()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "sch-rcnt-keyw" }, [
                              _vm._v(_vm._s(sw)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.showSearchResult && _vm.noSearchResult()
        ? _c("div", { staticClass: "sch-btm" }, [
            _c("div", { staticClass: "w-con" }, [
              _c("div", { staticClass: "sch-no" }, [
                _c("p", { staticClass: "sch-no-info" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("search.no_search_result")) + ' "'
                  ),
                  _c("em", { staticClass: "sch-keyw color-red" }, [
                    _vm._v(_vm._s(_vm.searchWord)),
                  ]),
                  _vm._v('" '),
                  _c("br"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("search.no_search_guide"))),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.showSearchResult && !_vm.noSearchResult()
        ? _c("div", { staticClass: "sch-btm" }, [
            _c("div", { staticClass: "w-con" }, [
              _c("div", { staticClass: "sch-res-list" }, [
                _c("div", { staticClass: "sch-res-tab" }, [
                  _c(
                    "ul",
                    [
                      _vm.searchResult["viateraHome"] &&
                      _vm.searchResult["viateraHome"].totalCount > 0 &&
                      _vm.showViatera()
                        ? [_vm._m(1), _vm._m(2)]
                        : _vm.searchResult["himacsHome"] &&
                          _vm.searchResult["himacsHome"].totalCount > 0
                        ? [
                            _vm._m(3),
                            _vm.showViatera()
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "btn-tab",
                                    attrs: { "data-tab": "HSRT02" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      { attrs: { href: "javascript:void(0)" } },
                                      [_vm._v("VIATERA")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "sch-res-data search-product-big-box" },
                  [
                    _vm.searchResult["viateraHome"] &&
                    _vm.searchResult["viateraHome"].totalCount > 0 &&
                    _vm.showViatera()
                      ? [
                          _c("ViateraHomeComponent", {
                            attrs: {
                              searchResult: _vm.searchResult["viateraHome"],
                              isOn: true,
                            },
                            on: {
                              "search-page-event": function ($event) {
                                return _vm.gotoSearchPage(_vm.searchWord)
                              },
                            },
                          }),
                          _c("HimacsHomeComponent", {
                            attrs: {
                              searchResult: _vm.searchResult["himacsHome"],
                              isOn: false,
                            },
                            on: {
                              "search-page-event": function ($event) {
                                return _vm.gotoSearchPage(_vm.searchWord)
                              },
                            },
                          }),
                        ]
                      : _vm.searchResult["himacsHome"] &&
                        _vm.searchResult["himacsHome"].totalCount > 0
                      ? [
                          _c("HimacsHomeComponent", {
                            attrs: {
                              searchResult: _vm.searchResult["himacsHome"],
                              isOn: true,
                            },
                            on: {
                              "search-page-event": function ($event) {
                                return _vm.gotoSearchPage(_vm.searchWord)
                              },
                            },
                          }),
                          _vm.showViatera()
                            ? _c("ViateraHomeComponent", {
                                attrs: {
                                  searchResult: _vm.searchResult["viateraHome"],
                                  isOn: false,
                                },
                                on: {
                                  "search-page-event": function ($event) {
                                    return _vm.gotoSearchPage(_vm.searchWord)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hd-close" }, [
      _c(
        "button",
        {
          staticClass: "btn-hd-close",
          attrs: { type: "button", "data-ui": "sch-ui" },
        },
        [_c("span", { staticClass: "blind" }, [_vm._v("Close Button")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "btn-tab on", attrs: { "data-tab": "HSRT02" } },
      [_c("a", { attrs: { href: "javascript:void(0)" } }, [_vm._v("VIATERA")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "btn-tab", attrs: { "data-tab": "HSRT03" } },
      [_c("a", { attrs: { href: "javascript:void(0)" } }, [_vm._v("HIMACS")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "btn-tab on", attrs: { "data-tab": "HSRT03" } },
      [_c("a", { attrs: { href: "javascript:void(0)" } }, [_vm._v("HIMACS")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }