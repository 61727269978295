<template>
  <OverviewSection
    title="Materiality Assessment"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem>
      <MaterialityAssessmentImage/>
    </OverviewSectionItem>

    <OverviewSectionItem title="10 Core Material Issues">
      <div class="materiality-assessment-listcard-area">
        <MaterialityAssessmentListCard
          v-for="(content, index) in contentList"
          :key="index"
          :contentList="content"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="重要性評価"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem>
      <MaterialityAssessmentImage/>
    </OverviewSectionItem>

    <OverviewSectionItem title="10 の主要重要課題">
      <div class="materiality-assessment-listcard-area">
        <MaterialityAssessmentListCard
          v-for="(content, index) in contentListJp"
          :key="index"
          :contentList="content"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Оценка существенности"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem>
      <MaterialityAssessmentImage/>
    </OverviewSectionItem>

    <OverviewSectionItem title="10 の主要重要課題">
      <div class="materiality-assessment-listcard-area">
        <MaterialityAssessmentListCard
          v-for="(content, index) in contentListRu"
          :key="index"
          :contentList="content"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="重要性评估"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem>
      <MaterialityAssessmentImage/>
    </OverviewSectionItem>

    <OverviewSectionItem title="10个核心材料问题">
      <div class="materiality-assessment-listcard-area">
        <MaterialityAssessmentListCard
          v-for="(content, index) in contentListCn"
          :key="index"
          :contentList="content"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import MaterialityAssessmentImage from './MaterialityAssessmentImage.vue'
import MaterialityAssessmentListCard from './MaterialityAssessmentListCard.vue'

export default {
  components: {
    OverviewSection,
    MaterialityAssessmentImage,
    MaterialityAssessmentListCard,
    OverviewSectionItem
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      contentList: [
        [
          {
            content: 'Operation of <b>Eco-friendly businesss</b>'
          },
          {
            content: 'Minimizing <b>Environmental impact</b>'
          },
          {
            content: '<b>Resource</b> management'
          },
          {
            content: 'Responses to <b>Climate change</b>'
          },
          {
            content: 'Products & services in consideration of <b>Social values</b>'
          }
        ],
        [
          {
            content: 'Integrated management of <b>ESG risks</b>'
          },
          {
            content: '<b>Ethical/Compliance</b> management'
          },
          {
            content: '<b>Environmental management</b> system'
          },
          {
            content: 'Management of <b>Human resources</b>'
          },
          {
            content: 'ESG <b>leadership and system</b>'
          }
        ]
      ],
      contentListJp: [
        [
          {
            content: '環境に配慮した事業の運営'
          },
          {
            content: '環境負荷の最小化'
          },
          {
            content: '資源管理'
          },
          {
            content: '应对气候变化'
          },
          {
            content: '社会的価値を考慮した製品とサービス</b>'
          }
        ],
        [
          {
            content: 'ESG リスクの統合管理'
          },
          {
            content: '倫理・コンプライアンス管理'
          },
          {
            content: '環境マネジメントシステム'
          },
          {
            content: '人材管理</b>'
          },
          {
            content: 'ESG リーダーシップとシステム</b>'
          }
        ]
      ],
      contentListRu: [
        [
          {
            content: 'Ведение экологически чистого бизнеса'
          },
          {
            content: 'Минимизация воздействия на окружающую среду'
          },
          {
            content: 'Управление ресурсами'
          },
          {
            content: 'Реакция на изменение климата</b>'
          },
          {
            content: 'Продукты и услуги с учетом социальных ценностей</b>'
          }
        ],
        [
          {
            content: 'Интегрированное управление рисками ESG</b>'
          },
          {
            content: 'Управление соблюдением этических норм и требований'
          },
          {
            content: 'Система экологического менеджмента'
          },
          {
            content: 'Управление человеческими ресурсами</b>'
          },
          {
            content: 'Лидерство и система ESG</b>'
          }
        ]
      ],
      contentListCn: [
        [
          {
            content: '生态友好型企业的运营</b>'
          },
          {
            content: '尽量减少环境影响</b>'
          },
          {
            content: '资源管理'
          },
          {
            content: '应对气候变化'
          },
          {
            content: '考虑社会价值的产品和服务</b>'
          }
        ],
        [
          {
            content: 'ESG风险的综合管理</b>'
          },
          {
            content: '道德/合规管理'
          },
          {
            content: '环境管理体系'
          },
          {
            content: '人力资源管理</b>'
          },
          {
            content: 'ESG领导力和体系</b>'
          }
        ]
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.materiality-assessment-listcard-area {
  gap: 30px;
  display: flex;
  border: 1px solid #CCC;
  padding: 40px;
  width: 1440px;
  justify-content: space-around;
  flex-wrap: wrap;
}
@media (max-width: 1440px) {
  .materiality-assessment-listcard-area {
    width: 100%;
  }
}
@media (max-width: 1075px) {
  .materiality-assessment-listcard-area {
    justify-content: left;
    align-items: center;
  }
}
</style>
