<template>
  <div id="wrap">
   <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home"></li>
          <!-- <li>Footer</li> -->
          <li>Do Not Sell My Personal Information</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Do Not Sell My Personal Information) -->
      <div class="container">
        <div class="w-con-02 inner">
          <div class="sub-visual-section">
            <h1 class="tt-1 visual-title tac">Do Not Sell My Personal Information</h1>
            <h2 class="sr-only">LX Hausys Do Not Sell My Personal Information</h2>
            <p class="ctt ctt-t2 visual-desc tac">
              Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services that you do not want them to track your online activities. Website does not currently support Do Not Track requests.
            </p>
          </div>
          <dl class="privacy">
            <dt class="ctt ctt-t1">Manage Your Personal Information</dt>
            <dd class="ctt ctt-t4">
              The California Consumer Privacy Act (CCPA) is the law that provides privacy rights to consumer.
              At LX Hausys, we extend CCPA privacy rights to all US residents.
            </dd>
            <dt class="ctt ctt-t1">Your Privacy Rights</dt>
            <dd>
              <ul class="dotList">
                <li class="ctt ctt-t4">ACCESS
                  <ul class="lineList">
                    <li class="ctt ctt-t5">YOU CAN REQUEST ACCESS TO THE PERSONAL INFORMATION THAT LX HAUSYS HAS COLLETED ABOUT YOU</li>
                  </ul>
                </li>
                <li class="ctt ctt-t4">Deletion
                  <ul class="lineList">
                    <li class="ctt ctt-t5">YOU CAN REQUEST LX Hausys to delete personal information abut you</li>
                  </ul>
                </li>
                <li class="ctt ctt-t4">Do Not Sell
                  <ul class="lineList">
                    <li class="ctt ctt-t5">You can opt out of having your personal information shared with third parties</li>
                  </ul>
                </li>
              </ul>
            </dd>
            <dt class="ctt ctt-t1">Do Not Sell My Personal Information</dt>
            <dd class="donotsell-info">
              <div class="col-02">
                <div class="tbl-01">
                  <div class="tbl-input type03">
                    <table>
                      <colgroup>
                        <col style="width:170px">
                        <col style="width:auto">
                      </colgroup>
                      <tbody>
                      <tr>
                        <th scope="row">Download Form</th>
                        <td class="cell-noti">
                          <button class="btn btn-down-line" @click="fileDown()">
                            <span>Download</span>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tbl-02">
                  <div class="tbl-input type03">
                    <table>
                      <colgroup>
                        <col style="width:250px">
                        <col style="width:auto">
                      </colgroup>
                      <tbody>
                      <tr>
                        <th scope="row">Upload File</th>
                        <td class="cell-noti">
<!--                          <button class="btn btn-line-gray no-icon">-->
<!--                            <span>Upload</span>-->
<!--                          </button>-->
                          <span class="inp-file">
                            <label for="file01">Upload</label>
                            <input type="file" id="file01" @change="fileUpload()" ref="submitImage" multiple="multiple"/>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Attach document</th>
                        <td class="cell-noti">
                          <ul class="list-upload-file">
                            <li v-for="(file, index) in data.fileList">
                              <span title="filename">{{file.name}}</span>
                              <button type="button" @click="fileDel(file.name)">del</button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="submit-box ta-c">
                <button class="btn" type="submit" id="submitForm" @click="submitForm()"><span>submit</span></button>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- //container (Do Not Sell My Personal Information) -->

    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import SupportService from '@/server/support.service'
import EtcService from '@/server/etc.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'app',
  //   mixins: [axiosService],
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      data: {
        fileList: [],
        appId: ''
      }

    }
  },
  async created () {
  },
  mounted () {
    // alert('Customer Support')
  },
  methods: {
    async fileDown () {
      const url = this.$imagePath + '/lxhausys/03_US/09_Main/Privacy Rights Request Form_v0.1.pptx'
      try {
        const element = await document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        // element.setAttribute('download', file);
        element.click()
      } catch (error) {
        console.log(error)
      }
    },
    async submitForm () {
      const formData = new FormData()
      // let now = new Date();
      // formData.append("appId", '');
      if (this.data.fileList.length > 0) {
        for (let i = 0; i < this.data.fileList.length; i++) {
          const imageForm = this.data.fileList[i]
          formData.append('receipt', imageForm)
        }
      } else {
        alert('Enter Attach document')
        return
      }
      const result = await EtcService.saveDonotsell(formData)
      if (result.data.success === true) {
        alert('Thank you for contacting us,\n' +
          "we'll be in touch very soon")
        location.href = 'index'
      } else {
        alert('Fail')
      }
    },
    fileDel (file) {
      const _this = this
      _this.data.fileList = _this.data.fileList.filter((element) => element.name !== file)
    },
    fileUpload () {
      if (this.$refs.submitImage.files.length + this.data.fileList.length > 10) {
        alert('Up to 10 files can be attached.')
        return false
      } else {
        for (var i = 0; i < this.$refs.submitImage.files.length; i++) {
          if (this.$refs.submitImage.files[i].size > 5000000) {
            alert('The file size can be up to 5MB.')
            return false
          } else {
            this.data.fileList.push(this.$refs.submitImage.files[i])
          }
        }
      }
    }
  }
}
</script>
