var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Eco-Friendly Products",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSection: "" } },
            [
              _c("EcoFriendlyProductsTab", {
                attrs: { tabDatas: _vm.tabDatas },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function ({ picture, icon, text }) {
                        return [
                          _c("EcoFriendlyProductsContent", {
                            attrs: { picture: picture, icon: icon, text: text },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2927689182
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "環境に優しい製品", size: "56px", color: "#222" } },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSection: "" } },
            [
              _c("EcoFriendlyProductsTab", {
                attrs: { tabDatas: _vm.tabDatasJp },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ picture, icon, text }) {
                      return [
                        _c("EcoFriendlyProductsContent", {
                          attrs: { picture: picture, icon: icon, text: text },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Экологически чистые продукты",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSection: "" } },
            [
              _c("EcoFriendlyProductsTab", {
                attrs: { tabDatas: _vm.tabDatasRu },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ picture, icon, text }) {
                      return [
                        _c("EcoFriendlyProductsContent", {
                          attrs: { picture: picture, icon: icon, text: text },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "环保产品", size: "56px", color: "#222" } },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSection: "" } },
            [
              _c("EcoFriendlyProductsTab", {
                attrs: { tabDatas: _vm.tabDatasCn },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ picture, icon, text }) {
                      return [
                        _c("EcoFriendlyProductsContent", {
                          attrs: { picture: picture, icon: icon, text: text },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }