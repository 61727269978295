import { render, staticRenderFns } from "./other.vue?vue&type=template&id=6ba75c83"
import script from "./other.vue?vue&type=script&lang=js"
export * from "./other.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/STG_US_FRONT/synccms-front/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ba75c83')) {
      api.createRecord('6ba75c83', component.options)
    } else {
      api.reload('6ba75c83', component.options)
    }
    module.hot.accept("./other.vue?vue&type=template&id=6ba75c83", function () {
      api.rerender('6ba75c83', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/search/other.vue"
export default component.exports