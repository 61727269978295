<template>
  <OverviewSection
    title="ESG Management System"
    v-if="nationCode == '_US' || nationCode == '_GL'"
 >
  <OverviewSectionItem isUnderSectionItem>
    <div class="bod-image-area">
      <img src="images/aboutus/sustainability/esg_strategy_system/bod_image.png" alt="img"/>
    </div>
  </OverviewSectionItem>

  <OverviewSectionItem isUnderSectionItem>
    <OverviewText
      type="ft-20"
      text="LX Hausys established the ESG Committee in 2022 and the ESG Council and ESG Visions and Strategy System in 2023,
laying the foundation of ESG management for the company.
Going forward, we will internalize and enhance our ESG management from a long-term perspective by carrying out
integrated management of the existing ESG activities and performances based on solid ESG leadership and systems."
    />
  </OverviewSectionItem>

  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="ESG Management System"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="bod-image-area">
        <img src="images/aboutus/sustainability/esg_strategy_system/bod_image.png" alt="img"/>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <OverviewText
        type="ft-20"
        text="LX Hausysは、2022年にESG委員会、2023年にESG協議会とESGビジョン・戦略システムを設立し、
        ESG経営の基盤を整えました。今後は、強固なESGリーダーシップとシステムに基づき、既存のESG活動と実績を統合管理することで、
        長期的な視点でESG経営を内在化し、強化していきます。"
      />
    </OverviewSectionItem>

  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="ESG Management System"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="bod-image-area">
        <img src="images/aboutus/sustainability/esg_strategy_system/bod_image.png" alt="img"/>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <OverviewText
        type="ft-20"
        text="В 2022 году LX Hausys создала Комитет ESG, а в 2023 году - Совет ESG и Систему видения и стратегии ESG,
заложив основу управления в компании.
В дальнейшем мы будем внедрять и совершенствовать наше управление ESG в долгосрочной перспективе, осуществляя
комплексное управления существующими мероприятиями и результатами ESG на основе надежного руководства и систем ESG."
      />
    </OverviewSectionItem>

  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="ESG Management System"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="bod-image-area">
        <img src="images/aboutus/sustainability/esg_strategy_system/bod_image.png" alt="img"/>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <OverviewText
        type="ft-20"
        text="LX Hausys于2022年成立ESG委员会，2023年成立ESG理事会并构建ESG愿景与战略体系，为公司ESG管理奠定了基础。
LX Hausys将通过在坚实的ESG领导力和系统的基础上对现有的ESG活动和绩效进行综合管理，从长远的角度内化和加强ESG经营。"
      />
    </OverviewSectionItem>

  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    OverviewText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      circleTitle: 'BoD',
      esgManagementSystemContentList: [
        {
          category: 'ESG Committee',
          arrowContent: {
            upContent: {
              image: 'images/aboutus/sustainability/esg_strategy_system/arrow-up.png',
              text: 'Report'
            },
            downContent: {
              image: 'images/aboutus/sustainability/esg_strategy_system/arrow-down.png',
              text: 'Monitoring'
            }
          }
        },
        {
          category: 'ESG Bureau',
          arrowContent: {
            upContent: {
              image: 'images/aboutus/sustainability/esg_strategy_system/arrow-up.png',
              text: 'Discussion'
            },
            downContent: {
              image: 'images/aboutus/sustainability/esg_strategy_system/arrow-down.png',
              text: 'Review Support'
            }
          }
        },
        {
          category: 'ESG Council'
        }
      ],
      mainText: 'LX Hausys established the ESG Committee in 2022 and the ESG Council and ESG Visions and Strategy System in 2023,\n' +
                'laying the foundation of ESG management for the company.\n' +
                'Going forward, we will internalize and enhance our ESG management from a long-term perspective by carrying out\n' +
                'integrated management of the existing ESG activities and performances based on solid ESG leadership and systems.'
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.bod-image-area {
  max-width: 1183px;
}

.bod-image-area img {
  width: 100%;
}

.main-content {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
  width: 1183px;
}

.bod-area {
  display: flex;
  margin-top: 64px;
  gap: 20px;
  justify-content: center;
}

.bod-area .bod-card-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bod-list-area {
  display: flex;
  flex-direction: column;
}

.bod-list-area .square-list1 {
  margin-top: 209px;
  list-style: square;
  flex-direction: column;
  display: inline-grid;
  width: 455px;
}

.bod-list-area .square-list2 {
  margin-top: 75px;
  list-style: square;
  flex-direction: column;
  display: inline-grid;
  width: 455px;
}

.bod-list-area .square-list3 {
  margin-top: 64px;
  list-style: square;
  flex-direction: column;
  display: inline-grid;
  width: 455px;
}

li::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: black;
  margin-right: 10px;
  margin-bottom: 3px;
}

.square-list-item {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 200% */
}
</style>
