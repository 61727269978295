<template>
  <OverviewSmallSection title="Industrial Film" fontWeight="700" fontFamily="Lato" v-if="nationCode == '_US' || nationCode == '_GL'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItems" :desc="desc" :from="from" :color="color"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
  <!-- JP -->
  <OverviewSmallSection title="産業用フィルム" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_JP'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItemsJp" :desc="descJp" :from="from" :color="color"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
  <!-- RU -->
  <OverviewSmallSection title="Пленки для ламинации" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_RU'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItemsRu" :desc="descRu" :from="from" :color="color"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
  <!-- CN -->
  <OverviewSmallSection title="产业用膜" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_CN'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItemsCn" :desc="descCn" :from="from" :color="color"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
</template>

<script>
import OverviewSmallSection from '../../components/OverviewSmallSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import BusinessAreaCarousel from '../components/BusinessAreaCarousel.vue'

export default {
  components: {
    OverviewSmallSection,
    OverviewSectionItem,
    BusinessAreaCarousel
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      carouselItems: [
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-1.jpg', title: 'Interior Film (BENIF)' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-2.jpg', title: 'Deco Foil', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-3.jpg', title: 'Exterior Film' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-4.jpg', title: 'Sign and Graphic (VIZUON)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-5.jpg', title: 'Home Appliance Surface Material' }
      ],
      carouselItemsJp: [
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-1.jpg', title: 'インテリアフィルム (BENIF)' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-2.jpg', title: '装飾フィルム', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-3.jpg', title: 'エクステリアフィルム' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-4.jpg', title: 'サイン＆グラフィック (VIZUON)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-5.jpg', title: '家電表面材' }
      ],
      carouselItemsRu: [
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-1.jpg', title: 'Пленки для интерьера (BENIF)' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-2.jpg', title: 'Пленки для мебели и декора', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-3.jpg', title: 'Пленки для ламинации' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-4.jpg', title: 'Материалы для рекламы (VIZUON)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-5.jpg', title: 'Материал поверхности бытовой техники' }
      ],
      carouselItemsCn: [
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-1.jpg', title: '装饰贴膜 (BENIF)' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-2.jpg', title: '家具膜', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-3.jpg', title: '户外膜' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-4.jpg', title: '广告材 (VIZUON)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/industrial-film-5.jpg', title: '家电膜' }
      ],
      desc: 'Our Interior Film and Deco Foil possess environmentally friendly qualities, offer a wide design spectrum, and demonstrate excellent functionality.\n' +
  'We also create the special finishing touches to space by delivering home appliance films and sign & graphic materials with outstanding designs.\n' +
  'Based on our material processing and surface treatment technology, we have successfully materialized various designs,\n' +
  'providing excellent quality in the fields of stain and scratch resistance.',
      descJp:'インテリアフィルムやデコフィルムは、環境に優しく、デザイン性も高く、機能性にも優れています。また、デザイン性に優れた家電用フィルムやサイン・グラフィック資材をお届けし、\n' + 
      '空間の特別な仕上げを演出します。素材加工技術や表面処理技術をベースに、さまざまなデザインを実現し、汚れや傷に強い品質を実現しています。',
      descRu:'Наши пленки для ламинации обладают экологичностью, широким дизайнерским спектром и отличной функциональностью. \n' +
'Мы также создаем особые штрихи в оформлении помещений, поставляя пленки для бытовой техники и материалы для вывесок и графики с выдающимся дизайном. \n' +
'Опираясь на наши технологии обработки материалов и поверхностей, мы успешно воплощаем в жизнь различные дизайнерские решения, \n' +
'обеспечивая превосходное качество и устойчивость к пятнам и царапинам.',
      descCn:'我们的装饰膜和家具膜具有环保特性，提供广泛的设计范围，并展现出卓越的功能。\n' +
'此外，我们提供具有出色设计的家电膜、广告材料，为空间创造特殊的装饰。\n' +
'基于我们的材料加工和表面处理技术，我们成功地实现了各种设计，在防污和防刮擦领域提供了卓越的质量。',
      from: 'IndustrialFilm'
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>
