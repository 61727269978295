var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Industrial Film",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.carouselItems,
                  desc: _vm.desc,
                  from: _vm.from,
                  color: _vm.color,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "産業用フィルム",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.carouselItemsJp,
                  desc: _vm.descJp,
                  from: _vm.from,
                  color: _vm.color,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Пленки для ламинации",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.carouselItemsRu,
                  desc: _vm.descRu,
                  from: _vm.from,
                  color: _vm.color,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSmallSection",
        { attrs: { title: "产业用膜", fontWeight: "700", fontFamily: "Lato" } },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.carouselItemsCn,
                  desc: _vm.descCn,
                  from: _vm.from,
                  color: _vm.color,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }