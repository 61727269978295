/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import LikeService from '@/server/like.service'
import sampleOrderService from '@/server/sampleOrder.service'
import _ from '@/common/helper/lodash.js'
import { $utils } from '@/common/helper'

export const getTotalLikeCnt = async function (storeContext, payload) {
  let cnt = 0

  const countryCode = $utils.getCountryCode()
  if (countryCode == 'US') {
    const res = await LikeService.getUsTotalCount()
    cnt = res.data
  } else {
    cnt = await LikeService.getGLTotalCount(countryCode)
  }

  storeContext.commit('setLikeCount', { likeCnt: cnt })
}

export const getTotalImgLibCnt = async function (storeContext, payload) {
  let cnt = 0
  const imgLibArr = payload.vm.$store.getters['imglib/getImgLibItemArr']
  const countryCode = $utils.getCountryCode()

  const countryLikeList = imgLibArr.filter((element) => element.nationCode == '_' + countryCode)
  cnt = countryLikeList.length

  storeContext.commit('setImgLibCount', cnt)
}

export default {
  getTotalLikeCnt,
  getTotalImgLibCnt
}
