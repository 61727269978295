/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import Vue from 'vue'
import UserService from '@/server/user.service'
import Cookies from 'js-cookie'

import {
  CHECK,
  LOGIN,
  LOGOUT
} from './mutation-types'

/* eslint-disable no-param-reassign */
export default {
  [CHECK] (state) {
    // state.authenticated = !!Cookies.get('SYNCCMS_USER')
    state.authenticated = !!sessionStorage.getItem('auth_token')
  },

  [LOGIN] (state, token) {
    state.authenticated = true
    sessionStorage.setItem('auth_token', token)
  },

  async [LOGOUT] (state, payload) {
    state.authenticated = false
    sessionStorage.removeItem('auth_token')
    Cookies.remove('SYNCCMS_USER')
    payload.vm.$store.dispatch('account/find')
    payload.vm.$store.dispatch('auth/check')
    payload.vm.$store.dispatch('header/getTotalLikeCnt')
  }
}
