<template>
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/esg_strategy_system/top_image.png"
    title="ESG Strategy System"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
  <template #content>
    <WeCreate />
    <InnovationForCustomers/>
    <MaterialityAssessment />
    <EsgManagementSystem />
  </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/esg_strategy_system/top_image.png"
    title="ESG戦略システム"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <WeCreate />
      <InnovationForCustomers/>
      <MaterialityAssessment />
      <EsgManagementSystem />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/esg_strategy_system/top_image.png"
    title="Стратегия ESG"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <WeCreate />
      <InnovationForCustomers/>
      <MaterialityAssessment />
      <EsgManagementSystem />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/esg_strategy_system/top_image.png"
    title="可持续发展战略"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <WeCreate />
      <InnovationForCustomers/>
      <MaterialityAssessment />
      <EsgManagementSystem />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import EsgManagementSystem from './components/EsgManagementSystem.vue'
import InnovationForCustomers from './components/InnovationForCustomers.vue'
import MaterialityAssessment from './components/MaterialityAssessment.vue'
import WeCreate from './components/weCreate.vue'

export default {
  components: {
    OverviewLayout,
    WeCreate,
    InnovationForCustomers,
    MaterialityAssessment,
    EsgManagementSystem
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>

</style>
