<template>
  <div class="hder-section sch-ui">
    <div class="hd-sec-cont sch-area">
      <div class="sch-top">
        <div class="hd-close">
          <button type="button" class="btn-hd-close" data-ui="sch-ui"><span class="blind">Close Button</span></button>
        </div>
        <div class="w-con">
          <div class="sch-frm">
            <label class="blind" for="inpHdSch">Input Search Keyword</label>
            <input type="text"
                    class="ipt-hd-sch wrd-on"
                    id="inpHdSch"
                    :placeholder="$t('search.enter_search_word')"
                    v-model="searchWord"
                    v-on:keyup.enter="doSearch"/>
            <button type="button" class="btn-hd-sch" v-on:click="doSearch">
              <span class="blind">Search Button</span>
            </button>
          </div>
        </div>
      </div>

      <!-- 처음 header search icon 클릭 했을때 Recent Searches 표시되어야 합니다 -->
      <!-- noSearchResult 화면이 나올때는 Recent Searches & Popular Searches 숨겨야 합니다  -->
      <div class="sch-btm" v-if="!(showSearchResult && !noSearchResult()) && searchHomeWords.length > 0">
        <div class="w-con">
          <div class="sch-recent">
            <p class="sch-rcnt-tit">{{ $t('search.recent_search') }}</p>
            <ul class="sch-rcnt-list">
              <li v-for="sw in searchHomeWords">
                <span class="sch-rcnt-keyw" v-on:click="searchWord=sw;doSearch()">{{sw}}</span>
                <button type="button" class="btn-rcnt-del" v-on:click="deleteRecent(sw)">delete keyword button</button>
              </li>
            </ul>
          </div>
          <div class="sch-recent" v-if="popularWords.length > 0">
            <p class="sch-rcnt-tit">{{ $t('search.popular_search') }}</p>
            <ul class="sch-rcnt-list">
              <li v-for="sw in popularWords" v-on:click="searchWord=sw;doSearch()">
                <span class="sch-rcnt-keyw">{{sw}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sch-btm" v-if="showSearchResult && noSearchResult()">
        <div class="w-con">
          <div class="sch-no">
            <p class="sch-no-info">
              {{ $t('search.no_search_result') }} "<em class="sch-keyw color-red">{{searchWord}}</em>" <br/>
              <span>{{ $t('search.no_search_guide') }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="sch-btm" v-if="showSearchResult && !noSearchResult()">
        <div class="w-con">
          <div class="sch-res-list">
            <div class="sch-res-tab">
              <ul>
                <template v-if="searchResult['viateraHome'] && searchResult['viateraHome'].totalCount > 0 &&  showViatera()" >
                  <li class="btn-tab on" data-tab="HSRT02"><a href="javascript:void(0)">VIATERA</a></li>
                  <li class="btn-tab" data-tab="HSRT03"><a href="javascript:void(0)">HIMACS</a></li>
                </template>
                <template v-else-if="searchResult['himacsHome'] && searchResult['himacsHome'].totalCount > 0">
                  <li class="btn-tab on" data-tab="HSRT03"><a href="javascript:void(0)">HIMACS</a></li>
                  <li class="btn-tab" data-tab="HSRT02"  v-if="showViatera()"><a href="javascript:void(0)">VIATERA</a></li>
                </template>
              </ul>
            </div>
            <div class="sch-res-data search-product-big-box">
              <template v-if="searchResult['viateraHome'] && searchResult['viateraHome'].totalCount > 0 && showViatera()">
                <ViateraHomeComponent  @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['viateraHome']" v-bind:isOn="true"></ViateraHomeComponent>
                <HimacsHomeComponent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['himacsHome']" v-bind:isOn="false"></HimacsHomeComponent>
              </template>
              <template v-else-if="searchResult['himacsHome'] && searchResult['himacsHome'].totalCount > 0">
                <HimacsHomeComponent @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['himacsHome']" v-bind:isOn="true"></HimacsHomeComponent>
                <ViateraHomeComponent  v-if="showViatera()" @search-page-event="gotoSearchPage(searchWord)" v-bind:searchResult="searchResult['viateraHome']" v-bind:isOn="false"></ViateraHomeComponent>
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'
import { mapState } from 'vuex'
import HimacsHomeComponent from './himacsHome'
import ViateraHomeComponent from './viateraHome'
export default {
  name: 'HDPSearchPopComponent',
  components: {
    HimacsHomeComponent,
    ViateraHomeComponent
  },
  data () {
    return {
      searchWord: '',
      showSearchResult: false,
      searchResult: {},
      popularWords: []
    }
  },
  computed: {
    ...mapState({
      searchHomeWords: state => state.search.searchHomeWords
    })
  },
  async mounted () {
    const _this = this
    // let observer = new MutationObserver(function(mutations) {
    //   mutations.forEach(function(mutationRecord) {
    //     if($(mutationRecord.target).css('display') === 'none')
    //     {
    //       _this.showSearchResult = false;
    //     }
    //   });
    // });
    //
    // let target = $(".hd-sec-cont.sch-area")[0];
    // observer.observe(target, { attributes : true, attributeFilter : ['style'] });
    //
    // $( "#inpHdSch" ).autocomplete({
    //   source: async function (request, response) {
    //     let res = await SearchService.suggestion(request.term);
    //     response(res.data);
    //   },
    //   minLength: 2
    // });

    const res = await SearchService.popular()
    this.popularWords = res.data
  },
  methods: {
    showViatera () {
      const country = this.$countryCode.substring(1)
      return country == 'GL' || country == 'US'
    },
    async doSearch () {
      if (this.searchWord == '') {
        alert('Please enter some search keyword')
        return
      }
      location.href = 'search/homedepot/' + this.searchWord
    },
    deleteRecent (searchWord) {
      this.$store.dispatch('search/deleteSearchHomeWord', { searchWord: searchWord, vm: this })
    },
    noSearchResult () {
      let totalCount = 0
      for (const search in this.searchResult) {
        totalCount += this.searchResult[search].totalCount
      }

      return totalCount === 0
    },
    gotoSearchPage (searchWord) {
      document.location.href = `search/homedepot/${searchWord}`

      $('.btn-hd-close').trigger('click')
    },
    gotoDetail (sr, type) {
      document.location.href = 'homecenter/' + type + '/' + sr.content.title + '/' + sr.content.id
    }
  }
}
</script>
<style>
.ui-autocomplete {
  z-index: 100000 !important;
}
</style>
