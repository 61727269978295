var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _vm._m(0),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content cntu-view" }, [
      _c("div", { staticClass: "location" }, [
        _c("ul", [
          _c("li", { staticClass: "ico-home" }),
          _c("li", [_vm._v("Disclaimer")]),
        ]),
      ]),
      _c("div", { staticClass: "container cntu-box" }, [
        _c("div", { staticClass: "w-con-02" }, [
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "sub-visual-section" }, [
              _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                _vm._v("RESPIRABLE SILICA WARNING"),
              ]),
              _c("div", { staticClass: "tableArea tbl-box type09" }, [
                _c("table", [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "20%" } }),
                    _c("col", { staticStyle: { width: "80%" } }),
                  ]),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Product"),
                      ]),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Chemical"),
                      ]),
                    ]),
                  ]),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v("VIATERA and TERACANTO"),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v(
                          "Silica, crystalline (airborne particles of respirable size)"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "t-type" }, [
              _c("p", { staticClass: "ctt ctt-t2 tac" }, [
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v("[WARNING]"),
                ]),
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v(
                    "Grinding, cutting, and polishing this material can release airborne respirable (breathable) silica which is a known health hazard. See OSHA/NIOSH Hazard Alert "
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { all: "unset", cursor: "pointer" },
                      attrs: {
                        href: "https://www.osha.gov/silica-crystalline/general-industry-maritime#gi_maritime_resources",
                      },
                    },
                    [
                      _vm._v(
                        "https://www.osha.gov/silica-crystalline/general-industry-maritime#gi_maritime_resources"
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "sub-visual-section" }, [
              _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                _vm._v("PROPOSITION 65"),
              ]),
              _c("div", { staticClass: "tableArea tbl-box type09" }, [
                _c("table", [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "20%" } }),
                    _c("col", { staticStyle: { width: "80%" } }),
                  ]),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Product"),
                      ]),
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v("Chemical"),
                      ]),
                    ]),
                  ]),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v("HIMACS and VIATERA"),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v(
                          "Carbon black (airborne, unbound particles of respirable size)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v("HIMACS and VIATERA"),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v(
                          "Titanium dioxide (airborne, unbound particles of respirable size)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v("VIATERA and TERACANTO"),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t3" }, [
                        _vm._v(
                          "Silica, crystalline (airborne particles of respirable size)"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "t-type" }, [
              _c("p", { staticClass: "ctt ctt-t2 tac" }, [
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v("[WARNING]"),
                ]),
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v(
                    "This product can expose you to chemicals including carbon black, titanium dioxide, and crystalline silica which are known to the State of California to cause cancer. For more information go to "
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { all: "unset", cursor: "pointer" },
                      attrs: { href: "http://www.P65Warnings.ca.gov" },
                    },
                    [_vm._v("http://www.P65Warnings.ca.gov")]
                  ),
                  _vm._v("."),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "ctt ctt-t2 pc-br",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [_vm._v("[ADVERTENCIA]")]
                ),
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v(
                    "Este producto puede exponerle a químicos incluyendo negro de carbón, dióxido de titanio y sílice cristalina, que son conocidos por el Estado de California como causantes de cáncer. Para mayor información, visite "
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { all: "unset", cursor: "pointer" },
                      attrs: { href: "http://www.P65Warnings.ca.gov" },
                    },
                    [_vm._v("http://www.P65Warnings.ca.gov")]
                  ),
                  _vm._v("."),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "w-con-02 mb-150" }, [
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "sub-visual-section" }, [
              _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                _vm._v("Disclaimer"),
              ]),
            ]),
            _c("div", { staticClass: "t-type" }, [
              _c("p", { staticClass: "ctt ctt-t2 tac" }, [
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v(
                    "CGI renderings are shown for illustration purpose only."
                  ),
                ]),
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v(
                    "Digital representation of color may vary from the actual product."
                  ),
                ]),
                _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                  _vm._v("Please view the actual product prior to purchase."),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }