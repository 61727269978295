var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabCount > 0
    ? _c("div", { staticClass: "tab-con on" }, [
        _c("div", { staticClass: "gallery sch-res-prod prod-fd-view pd-0" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("Inspiration Galleries ("),
              _c("em", [_vm._v(_vm._s(_vm.INSP.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-search-collection" }, [
            _vm.INSP && _vm.INSP.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.INSP.list, function (sr) {
                    return _c("li", [
                      _c("div", { staticClass: "img-box" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoInspAll(sr)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.$imagePath + _vm.getImage(sr),
                                alt: sr.title,
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("ul", [
                          _c("li", { staticClass: "txt-01" }, [
                            _c(
                              "a",
                              { attrs: { href: "javascript:void(0);" } },
                              [
                                _vm._v("["),
                                _c("span", [
                                  _vm._v(_vm._s(sr.attribute.segmentsText)),
                                ]),
                                _vm._v("] "),
                                _c("span", [_vm._v(_vm._s(sr.content.title))]),
                              ]
                            ),
                          ]),
                          _c("li", { staticClass: "txt-02" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoInspAll(sr)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("learn_more_collection")))]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.INSP.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn_load_more",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreInsp()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("Load More")]),
                    _c("span", { staticClass: "icon icon_plus" }),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "sch-res-prod prod-fd-view inspr-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("Case Studies ("),
              _c("em", [_vm._v(_vm._s(_vm.CASE.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "articles-list" }, [
            _vm.CASE && _vm.CASE.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.CASE.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "col-02 search-ins-cont-box" },
                      [
                        _c("div", { staticClass: "txt-box" }, [
                          sr.attribute.segmentsText
                            ? _c("p", [
                                _vm._v(_vm._s(sr.attribute.segmentsText)),
                              ])
                            : _vm._e(),
                          _c("p", { staticClass: "tt-3" }, [
                            _vm._v(_vm._s(sr.content.title)),
                          ]),
                          sr.attribute.location
                            ? _c("p", [_vm._v(_vm._s(sr.attribute.location))])
                            : _vm._e(),
                          _c("div", { staticClass: "text ctt ctt-t4" }, [
                            _vm._v(_vm._s(sr.attribute.description)),
                          ]),
                          _c("div", { staticClass: "more-box" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn_view_more",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoCaseDetail(sr)
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-box" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.$imagePath + _vm.getImage(sr),
                              alt: sr.title,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.gotoCaseDetail(sr)
                              },
                            },
                          }),
                          _vm.authenticated == true && _vm.nationCode == "_US"
                            ? _c(
                                "a",
                                {
                                  staticClass: "icon-like-shadow",
                                  class: { on: sr.content.liked },
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleLike(sr, "CS")
                                    },
                                  },
                                },
                                [_vm._v("wish list // 11")]
                              )
                            : _vm._e(),
                          _vm.authenticated == false && _vm.nationCode == "_US"
                            ? _c(
                                "a",
                                {
                                  staticClass: "icon-like-shadow",
                                  attrs: { href: "login/" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.loginalertLike()
                                    },
                                  },
                                },
                                [_vm._v("wish list // 22")]
                              )
                            : _vm._e(),
                          _vm.getPJSessionContent != null &&
                          _vm.nationCode != "_US"
                            ? _c(
                                "a",
                                {
                                  staticClass: "icon-like-shadow",
                                  class: {
                                    on: _vm.getPJSessionContent.includes(
                                      sr.content.id
                                    ),
                                  },
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleLike(sr, "CS")
                                    },
                                  },
                                },
                                [_vm._v("wish list // 33")]
                              )
                            : _vm._e(),
                          _vm.getPJSessionContent == null &&
                          _vm.nationCode != "_US"
                            ? _c(
                                "a",
                                {
                                  staticClass: "icon-like-shadow",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleLike(sr, "CS")
                                    },
                                  },
                                },
                                [_vm._v("wish list // 44")]
                              )
                            : _vm._e(),
                          _c("span", { staticClass: "badge badge-cat" }, [
                            _vm._v("HIMACS"),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.CASE.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn_load_more",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreCase()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("Load More")]),
                    _c("span", { staticClass: "icon icon_plus" }),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _c("div", { staticClass: "no-found-wrap bd-top" }, [_vm._m(0)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tac" }, [
      _c("i", { staticClass: "icon-no-result" }, [
        _c("img", {
          attrs: { src: "images/icon-no-result-found.png", alt: "no result" },
        }),
      ]),
      _c("div", { staticClass: "msg" }, [
        _c("ul", [
          _c("li", { staticClass: "ctt ctt-t1 result-tit" }, [
            _vm._v("No Result Found"),
          ]),
          _c("li", { staticClass: "ctt ctt-t4" }, [
            _vm._v("Try again using more general search keywords"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }