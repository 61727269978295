var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expanded-waste-recycling-table-header" },
    _vm._l(_vm.headerList, function (item) {
      return _c("pre", { key: item, staticClass: "header-text" }, [
        _vm._v(_vm._s(item)),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }