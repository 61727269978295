var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("h1", { staticClass: "sr-only" }, [_vm._v("LX Hausys")]),
      _c("div", { staticClass: "content cntu-view" }, [
        _vm.keyVisual
          ? _c("div", {
              staticClass: "keyvisual-hei-750",
              domProps: { innerHTML: _vm._s(_vm.keyVisual) },
            })
          : _vm._e(),
        _vm.featuresBenefits
          ? _c("div", [
              _c("p", { staticClass: "tt-4 tac mb-80" }, [
                _vm._v("FEATURES & BENEFITS"),
              ]),
              _c("div", { staticClass: "wrapper-cms-cnm type-col-03" }, [
                _c("div", {
                  staticClass: "ico-box",
                  domProps: { innerHTML: _vm._s(_vm.featuresBenefits) },
                }),
              ]),
            ])
          : _vm._e(),
        _c("p", { staticClass: "tt-4 tac mb-80" }, [_vm._v("GET INSPIRED")]),
        _vm.inspired
          ? _c("div", { staticClass: "google-ads-inspr inner" }, [
              _c("div", { staticClass: "slide-wrapper" }, [
                _c("button", { staticClass: "arrow-prev" }, [_vm._v("Prev")]),
                _c("button", { staticClass: "arrow-next" }, [_vm._v("Next")]),
                _c("div", { staticClass: "slick-container" }, [
                  _vm.inspired.airports
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.airports"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.airports,
                                  alt: _vm.$t("google_ads.airports"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.airports"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.bathroom
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/inspirationGallery/" +
                                  _vm.$t("google_ads.bathroom"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.bathroom,
                                  alt: _vm.$t("google_ads.bathroom"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.bathroom"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.education
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/inspiration/inspirationGallery/" +
                                  _vm.$t("google_ads.education"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.education,
                                  alt: _vm.$t("google_ads.education"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.education"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.façade
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/inspiration/inspirationGallery/" +
                                  _vm.$t("google_ads.façade"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.façade,
                                  alt: _vm.$t("google_ads.façade"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.façade"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.furniture
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/inspiration/inspirationGallery/" +
                                  _vm.$t("google_ads.furniture"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.furniture,
                                  alt: _vm.$t("google_ads.furniture"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.furniture"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.healthcare
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.healthcare"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.healthcare,
                                  alt: "$t('google_ads.healthcare')",
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.healthcare"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.hospitality
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.hospitality"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.$imagePath + _vm.inspired.hospitality,
                                  alt: _vm.$t("google_ads.hospitality"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("google_ads.hospitality"))
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.kitchen
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.kitchen"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.kitchen,
                                  alt: _vm.$t("google_ads.kitchen"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.kitchen"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.offices
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.offices"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.offices,
                                  alt: _vm.$t("google_ads.offices"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.offices"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.public_buildings
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.public_buildings"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.$imagePath +
                                    _vm.inspired.public_buildings,
                                  alt: _vm.$t("google_ads.public_buildings"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("google_ads.public_buildings"))
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.retail
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.retail"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + _vm.inspired.retail,
                                  alt: _vm.$t("google_ads.retail"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(_vm._s(_vm.$t("google_ads.retail"))),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.inspired.marine_transport
                    ? _c("div", { staticClass: "slider" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _c(
                            "a",
                            {
                              staticStyle: { width: "240px", height: "360px" },
                              attrs: {
                                href:
                                  "inspiration/case-studies/INS_CAS/segment/" +
                                  _vm.$t("google_ads.marine_transport"),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.$imagePath +
                                    _vm.inspired.marine_transport,
                                  alt: _vm.$t("google_ads.marine_transport"),
                                },
                              }),
                              _c("span", { staticClass: "ads-inspr-tit" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("google_ads.marine_transport"))
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.colours
          ? _c("div", {
              staticClass: "wrap-kv-colours",
              domProps: { innerHTML: _vm._s(_vm.colours) },
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "google-ads-cntu" },
          [
            _c("p", { staticClass: "tt-4 tac mb-80 inner" }, [
              _vm._v("Contact Us"),
            ]),
            _vm.productType
              ? _c("ContactEUForm", {
                  attrs: {
                    "product-type": _vm.productType,
                    "is-google-ads": true,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }