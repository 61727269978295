var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode === "_US" || _vm.nationCode === "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/business_area/top_image.png",
          title: "Business Area",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("CustomerOrientedProducts"),
                  _c("BuildingDecorativeMaterials"),
                  _c("IndustrialFilm"),
                  _c("Automotive"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2484422610
        ),
      })
    : _vm.nationCode === "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/business_area/top_image.png",
          title: "事業領域",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("CustomerOrientedProducts"),
                _c("BuildingDecorativeMaterials"),
                _c("IndustrialFilm"),
                _c("Automotive"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode === "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/business_area/top_image.png",
          title: "Сфера деятельности",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("CustomerOrientedProducts"),
                _c("BuildingDecorativeMaterials"),
                _c("IndustrialFilm"),
                _c("Automotive"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode === "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/business_area/top_image.png",
          title: "事业领域",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("CustomerOrientedProducts"),
                _c("BuildingDecorativeMaterials"),
                _c("IndustrialFilm"),
                _c("Automotive"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }