var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Core Values", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  description:
                    "LX Hausys shares the four core values of “sensitivity to customers”,\n“teamwork based on individual strength”, “world-class expertise”,and “challenge\nbased on creativity and autonomy” with our stakeholders\nin order to fulfill its management philosophy and vision.",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "LX Hausys shares the four core values of “sensitivity to customers”,\n“teamwork based on individual strength”, “world-class expertise”,and “challenge\nbased on creativity and autonomy” with our stakeholders\nin order to fulfill its management philosophy and vision."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardData, function (it, idx) {
                return _c("CoreValuesCard", {
                  key: idx,
                  attrs: {
                    title: it.title,
                    titleDesc: it.titleDesc,
                    desc: it.desc,
                    iconSrc: it.iconSrc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "主な価値", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  description:
                    "LXハウシスは、経営理念とビジョンを実現するために、「顧客への敏感さ」\n「個の力に基づくチームワーク」「世界トップクラスの専門性」「創造性と自律性に基づく挑戦」\nという4つのコアバリューをステークホルダーと共有しています。",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "LXハウシスは、経営理念とビジョンを実現するために、「顧客への敏感さ」「個の力に基づくチームワーク」「世界トップクラスの専門性」「創造性と自律性に基づく挑戦」という4つのコアバリューをステークホルダーと共有しています。"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardData_jp, function (it, idx) {
                return _c("CoreValuesCard", {
                  key: idx,
                  attrs: {
                    title: it.title,
                    titleDesc: it.titleDesc,
                    desc: it.desc,
                    iconSrc: it.iconSrc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Главные ценности", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  description:
                    "LX Hausys разделяет четыре основные ценности: «чуткое отношение к клиентам»,\n«командная работа, основанная на индивидуальной силе», «экспертиза мирового уровня» и «вызов, \nоснованный на творчестве и автономии», с нашими заинтересованными сторонами для того, чтобы \nвоплотить в жизнь философию и видение.",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "LX Hausys разделяет четыре основные ценности: «чуткое отношение к клиентам», «командная работа, основанная на индивидуальной силе», «экспертиза мирового уровня» и «вызов, основанный на творчестве и автономии», с нашими заинтересованными сторонами для того, чтобы воплотить в жизнь философию и видение."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardData_ru, function (it, idx) {
                return _c("CoreValuesCard", {
                  key: idx,
                  attrs: {
                    title: it.title,
                    titleDesc: it.titleDesc,
                    desc: it.desc,
                    iconSrc: it.iconSrc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "核心价值", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  description:
                    "LX Hausys为了实现自身的经营理念与愿景，始终坚持“与顾客共情”，\n“基于个人优势的团队合作”，“世界级的专业性”以及“以创新与自由为基础的挑战精神”等4条核心价值。",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/core-values-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "LX Hausys为了实现自身的经营理念与愿景，始终坚持“与顾客共情”，“基于个人优势的团队合作”，“世界级的专业性”以及“以创新与自由为基础的挑战精神”等4条核心价值。"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardData_cn, function (it, idx) {
                return _c("CoreValuesCard", {
                  key: idx,
                  attrs: {
                    title: it.title,
                    titleDesc: it.titleDesc,
                    desc: it.desc,
                    iconSrc: it.iconSrc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }