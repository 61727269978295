<template>
  <SeatCorverLayout
    class="mt-50"
    imageSrc="images/automotive/skin/SOFINO.png"
  >
    <template #text>
      <div class="title">
        <span>SOFINO® Green</span>
      </div>
      <div class="description">
        <span>Comply with Class 1 of the OEKO-TEX® Standard 100, which attests that the material is safe for babies and small children¹⁾</span>
      </div>

      <div class="title">
        <span>SOFINO® Bio</span>
      </div>
      <div class="description">
        <span>Developing bio leather from bio based fillers</span>
        <br />
        <span>(e.g., Kenaf, Eucalyptus, and so on)</span>
      </div>

      <div class="title">
        <span>SOFINO® Eco</span>
      </div>
      <div class="description">
        <span>• RCS (Recycled Claim Standard) certificated</span>
        <br />
        <span>• Recycled content 35 wt% (Recycled Post-Consumer Polyester)</span>
      </div>

    </template>

    <template #annotation>
      <span>1) Limit values of OEKO-TEX® Standard 100 Class 1 (Products for babies)</span>
      <br />
      <span>• Solvent residues of NMP (1-Methyl-2-pyrrolidone), DMF (Dimethylformamide) ≤ 0.05 wt%</span>
      <br />
      <span>• Sum of phthalates ≤ 0.05 wt%</span>
      <br />
      <span class="spacing">(DBP, BBP, DEP, DMP, DEHP, DEMP, DIHP, DHNUP, DHxP, DIDP, DPrP, DHP, DNOP, DNP, DPP)</span>
      <br />
      <span>• Do not contain any of the banned flame retardant substances</span>
      <br />
      <span class="spacing">(e.g., Polybrominated Diphenyl Ethers, Antimony Trioxide)</span>
      <br />
    </template>
  </SeatCorverLayout>
</template>

<script>
import SeatCorverLayout from './SeatCorverLayout.vue'

export default {
  components: {
    SeatCorverLayout
  }
}
</script>
