<template>
  <div class="scopes-of-impact">
    <SocialResponsibilityBorderTitle
      :title="title"
    />
    <div class="scopes-of-impact-content">
      <div class="circle-area">
        <pre class="text">{{ text }}</pre>
      </div>
      <pre class="description">{{ description }}</pre>
    </div>
  </div>
</template>

<script>
import SocialResponsibilityBorderTitle from '../../socialResponsibility/components/SocialResponsibilityBorderTitle.vue'

export default {
  components: {
    SocialResponsibilityBorderTitle
  },
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>

<style scoped>
.scopes-of-impact {
  padding: 0px 56px;
}

@media (max-width: 768px) {
  .scopes-of-impact {
    width: 100%;
    padding: 0px;
  }
}

.scopes-of-impact .scopes-of-impact-content {
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .scopes-of-impact .scopes-of-impact-content {
    margin-top: 60px;
  }
}

.scopes-of-impact .scopes-of-impact-content .description {
  color: #222;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 200% */
  margin-top: 45px;
}

@media (max-width: 768px) {
  .scopes-of-impact .scopes-of-impact-content .description {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }
}

.scopes-of-impact .scopes-of-impact-content .circle-area {
  width: 475px;
  height: 475px;
  border-radius: 475px;
  background: rgba(221, 221, 221, 0.50);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .scopes-of-impact .scopes-of-impact-content .circle-area {
    width: 90%;
    height: 90%;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
  }
}

.scopes-of-impact .scopes-of-impact-content .circle-area .text {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .scopes-of-impact .scopes-of-impact-content .circle-area .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
</style>
