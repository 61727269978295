var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "eco-friendly-products-tab-header" },
    _vm._l(_vm.headerInfo, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "header-item",
          class: item.isActive ? "active" : "",
          on: {
            click: function ($event) {
              return _vm.onClickHeader(index)
            },
          },
        },
        [_c("pre", { staticClass: "text" }, [_vm._v(_vm._s(item.title))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }