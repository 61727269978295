var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-box" }, [
    _c("img", { attrs: { src: _vm.iconSrc } }),
    _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "first-title" }, [
        _vm._v(_vm._s(_vm.firstTitle)),
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ]),
    ]),
    _c("div", { staticClass: "card-box-content" }, [
      _c("span", [_vm._v(_vm._s(_vm.content))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }