/* ============
 * Getters for the order module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export default {
  getCartItemList (state) {
    return state.cartList
  },
  getOrderItemList (state) {
    return state.orderList
  },
  getMemberInfo (state) {
    return state.memberInfo
  }
}
