var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content inspr-view" }, [
        _c(
          "div",
          { staticClass: "container inspr-box case-studies-detail" },
          [
            _c("div", { staticClass: "gv-side-header main p-ui" }, [
              _c("div", [
                _c("div", { staticClass: "location" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        staticClass: "ico-home",
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/index`
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("caseStudies.text.home")) + " "
                        ),
                      ]
                    ),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$t("caseStudies.text.inspiration"))),
                    ]),
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/inspiration/case-studies`
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("caseStudies.text.caseStudies")) +
                            " "
                        ),
                      ]
                    ),
                    _c("li", [
                      _vm._v(
                        _vm._s(_vm.$t("caseStudies.text.caseStudies_detail"))
                      ),
                    ]),
                  ]),
                ]),
                _c("h1", {
                  staticClass: "con_title tt-3",
                  domProps: {
                    innerHTML: _vm._s(_vm.contentDetail.content.title),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "gv-side-root" },
                  [
                    _vm._l(
                      _vm.getSeletedSegmentsName(_vm.contentDetail.attribute),
                      function (item) {
                        return [
                          _c("span", { staticClass: "ctt" }, [
                            _vm._v(_vm._s(item.text)),
                          ]),
                          _c("span"),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
            _c("article", { staticClass: "gv-wrap" }, [
              _c(
                "section",
                { staticClass: "gv-view" },
                [
                  _c("div", { staticClass: "gv-side-header m-ui" }, [
                    _c("p", {
                      staticClass: "tt-3",
                      domProps: {
                        innerHTML: _vm._s(_vm.contentDetail.content.title),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "gv-side-root" },
                      [
                        _vm._l(
                          _vm.getSeletedSegmentsName(
                            _vm.contentDetail.attribute
                          ),
                          function (item) {
                            return [
                              _c("span", { staticClass: "ctt" }, [
                                _vm._v(_vm._s(item.text)),
                              ]),
                              _c("span"),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm.contentDetail.attribute
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "case_study_temp_first",
                            on: {
                              mouseover: _vm.onMouseOver,
                              mouseout: _vm.onMouseOut,
                            },
                          },
                          [
                            _vm.contentDetail.attribute.mainThumbnail !== ""
                              ? _c("div", { staticClass: "img-wrap first" }, [
                                  _c("img", {
                                    attrs: {
                                      src: `${_vm.$imagePath}${_vm.contentDetail.attribute.mainThumbnail}`,
                                      alt: _vm.contentDetail.attribute
                                        .metaTitle,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "btn-group" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn_download_large",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.imgDownload(
                                                `${_vm.$imagePath}${_vm.contentDetail.attribute.mainThumbnail}`
                                              )
                                            },
                                          },
                                        },
                                        [_c("span", { staticClass: "icon" })]
                                      ),
                                      _c("ShareComponent", {
                                        attrs: {
                                          childValue: _vm.contentDetail,
                                          type: "product",
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn_view_large m-ui",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isLayerModal = true
                                            },
                                          },
                                        },
                                        [_c("span", { staticClass: "icon" })]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "center_btn",
                                      class: { on: _vm.isHovered },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn_view_large",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isLayerModal = true
                                            },
                                          },
                                        },
                                        [_c("span", { staticClass: "icon" })]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.contentDetail.attribute.lead !== ""
                              ? _c("div", { staticClass: "btm_tit" }, [
                                  _c("h2", {
                                    staticClass: "title",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.contentDetail.attribute.lead
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "btm_desc" }, [
                              _c("p", {
                                staticClass: "ctt ctt-t4",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.contentDetail.attribute.description
                                  ),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.contentDetail.attribute
                    ? [
                        _vm._l(
                          _vm.contentDetail.attribute,
                          function (key, value) {
                            return [
                              value === "templateList"
                                ? [
                                    _vm._l(key, function (item, index) {
                                      return [
                                        item.templateType === 1
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "case_study_temp1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "flex_wrap",
                                                    },
                                                    [
                                                      item.templateThumbnail !==
                                                      ""
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-wrap",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: `${_vm.$imagePath}${item.templateThumbnail}`,
                                                                  alt: item.templateThumbnail,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleClickTemplateThumbnail(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "txt-wrap",
                                                        },
                                                        [
                                                          item.templateTitle !==
                                                          ""
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btm_tit",
                                                                },
                                                                [
                                                                  _c("p", {
                                                                    staticClass:
                                                                      "title",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item.templateTitle
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.templateDescription !==
                                                          ""
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btm_desc",
                                                                },
                                                                [
                                                                  _c("p", {
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item.templateDescription
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : item.templateType === 2
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "case_study_temp2",
                                                },
                                                [
                                                  item.templateThumbnail !== ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "img-wrap",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: `${_vm.$imagePath}${item.templateThumbnail}`,
                                                              alt: item.templateThumbnail,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleClickTemplateThumbnail(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.templateTitle !== ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "btm_tit",
                                                        },
                                                        [
                                                          _c("p", {
                                                            staticClass:
                                                              "title",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.templateTitle
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.templateDescription !==
                                                  ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "btm_desc",
                                                        },
                                                        [
                                                          _c("p", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.templateDescription
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          : item.templateType === 3
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "case_study_temp3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex_wrap reverse",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "txt-wrap",
                                                        },
                                                        [
                                                          item.templateTitle !==
                                                          ""
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btm_tit",
                                                                },
                                                                [
                                                                  _c("p", {
                                                                    staticClass:
                                                                      "title",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item.templateTitle
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.templateDescription !==
                                                          ""
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btm_desc",
                                                                },
                                                                [
                                                                  _c("p", {
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item.templateDescription
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      item.templateThumbnail !==
                                                      ""
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-wrap",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: `${_vm.$imagePath}${item.templateThumbnail}`,
                                                                  alt: item.templateThumbnail,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleClickTemplateThumbnail(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : item.templateType === "4-1"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "case_study_temp4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "flex_wrap",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "min_cont",
                                                        },
                                                        [
                                                          item.templateThumbnail !==
                                                          ""
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-wrap",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: `${_vm.$imagePath}${item.templateThumbnail}`,
                                                                      alt: item.templateThumbnail,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickTemplateThumbnail(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c("div", [
                                                            item.templateTitle !==
                                                            ""
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "btm_tit",
                                                                  },
                                                                  [
                                                                    _c("p", {
                                                                      staticClass:
                                                                        "title",
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              item.templateTitle
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            item.templateDescription !==
                                                            ""
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "btm_desc",
                                                                  },
                                                                  [
                                                                    _c("p", {
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              item.templateDescription
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "min_cont",
                                                        },
                                                        [
                                                          key[index + 1]
                                                            .templateThumbnail !==
                                                          ""
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-wrap",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: `${
                                                                        _vm.$imagePath
                                                                      }${
                                                                        key[
                                                                          index +
                                                                            1
                                                                        ]
                                                                          .templateThumbnail
                                                                      }`,
                                                                      alt: key[
                                                                        index +
                                                                          1
                                                                      ]
                                                                        .templateThumbnail,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickTemplateThumbnail(
                                                                            index +
                                                                              1
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c("div", [
                                                            key[index + 1]
                                                              .templateTitle !==
                                                            ""
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "btm_tit",
                                                                  },
                                                                  [
                                                                    _c("p", {
                                                                      staticClass:
                                                                        "title",
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              key[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                .templateTitle
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            key[index + 1]
                                                              .templateDescription !==
                                                            ""
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "btm_desc",
                                                                  },
                                                                  [
                                                                    _c("p", {
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              key[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                .templateDescription
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          }
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.contentDetail.attribute
                ? _c("section", { staticClass: "gv-side show" }, [
                    _c(
                      "div",
                      { staticClass: "gv-side-wrap" },
                      [
                        _c("div", { staticClass: "gv-side-table" }, [
                          _vm.contentDetail.attribute.metaDataList
                            ? _c(
                                "ul",
                                { staticClass: "gv-side-tablewrap" },
                                [
                                  _vm._l(
                                    _vm.contentDetail.attribute.metaDataList,
                                    function (item) {
                                      return [
                                        item.content || item.content !== ""
                                          ? [
                                              _c("li", [
                                                _c("div", {
                                                  staticClass: "tit",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.name
                                                    ),
                                                  },
                                                }),
                                                _c("div", {
                                                  staticClass: "cont",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.content
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _vm.contentDetail.attribute.usedMaterialList
                          ? [
                              _c("div", { staticClass: "gv-side-header" }, [
                                _vm.contentDetail.attribute.usedMaterialList
                                  .length > 0
                                  ? _c("p", { staticClass: "tt-3" }, [
                                      _vm._v("Used Material"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "gv-side-list" },
                                [
                                  _vm._l(
                                    _vm.contentDetail.attribute
                                      .sideUsedMaterialList,
                                    function (itemList) {
                                      return _vm.contentDetail.attribute
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gv-side-title_area",
                                              },
                                              [
                                                itemList[0].detail.parents
                                                  ? _c("span", {
                                                      staticClass:
                                                        "gv-side-title",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.getCategoryName(
                                                            itemList[0].detail
                                                              .parents,
                                                            1
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                itemList[0].detail.parents
                                                  ? _c("span", {
                                                      staticClass:
                                                        "gv-side-txt",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.getCategoryName(
                                                            itemList[0].detail
                                                              .parents,
                                                            2
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "ul",
                                              _vm._l(
                                                itemList,
                                                function (itemList, index) {
                                                  return _c(
                                                    "li",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "thumb on",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.moveToContentDetail(
                                                                itemList
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          itemList.detail
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "javascript:void(0)",
                                                                  },
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: `${_vm.$imagePath}${itemList.detail.attribute.pf_thumb}`,
                                                                      alt: "product",
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          itemList.detail
                                                            ? [
                                                                itemList.detail
                                                                  .attribute
                                                                  .discontinued_color ===
                                                                  "true" ||
                                                                itemList.detail
                                                                  .attribute
                                                                  .dictClr ===
                                                                  "true"
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "tag",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Discontinued"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "info" },
                                                        [
                                                          _c("strong", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                itemList.title
                                                              ),
                                                            },
                                                          }),
                                                          _c("strong"),
                                                          _c("span"),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm.contentDetail.attribute.relatedContentsList
              ? [
                  _vm.contentDetail.attribute.relatedContentsList.length !== 0
                    ? [
                        _c("div", { staticClass: "ins-box" }, [
                          _c("p", { staticClass: "prod-title" }, [
                            _vm._v("Related Projects"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "related_slider" },
                            [
                              _c(
                                "VueSlickCarousel",
                                _vm._b(
                                  {},
                                  "VueSlickCarousel",
                                  _vm.slickSettings,
                                  false
                                ),
                                _vm._l(
                                  _vm.contentDetail.attribute
                                    .relatedContentsList,
                                  function (item, index) {
                                    return index <= 2
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "imgwrap",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleClickrelatedContent(
                                                      item.content
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `${_vm.$imagePath}${item.attribute.mainThumbnail}`,
                                                    alt: item.content.title,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "txtwrap" },
                                              [
                                                _c("ul", [
                                                  _c(
                                                    "li",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._l(
                                                        _vm.getSeletedSegmentsName(
                                                          item.attribute
                                                        ),
                                                        function (
                                                          segmentsItem
                                                        ) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    segmentsItem.text
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "li",
                                                    { staticClass: "tit" },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.content.title
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "tagwrap" },
                                              [
                                                item.attribute.productList
                                                  ? [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.attribute
                                                              .productList.value
                                                          ),
                                                        },
                                                      }),
                                                      _c("span", {
                                                        staticClass: "line",
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.isLayerModal
                ? _c(
                    "div",
                    {
                      staticClass: "layer-popup layer-gallery-img",
                      style: { display: "block" },
                    },
                    [
                      _c("div", { staticClass: "popup pop-close" }, [
                        _c(
                          "div",
                          { staticClass: "ins-box" },
                          [
                            _c(
                              "VueSlickCarousel",
                              _vm._b(
                                { ref: "slickModal" },
                                "VueSlickCarousel",
                                _vm.slickPopSettings,
                                false
                              ),
                              [
                                _vm.contentDetail.attribute
                                  ? [
                                      _c("div", { staticClass: "slider" }, [
                                        _c("div", { staticClass: "img-box" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: `${_vm.$imagePath}${_vm.contentDetail.attribute.mainThumbnail}`,
                                                  alt: "thumb",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._l(
                                        _vm.contentDetail.attribute
                                          .templateList,
                                        function (item) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "slider" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "img-box" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: `${_vm.$imagePath}${item.templateThumbnail}`,
                                                            alt: "thumb",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        }
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn-close",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.isLayerModal = false
                              },
                            },
                          },
                          [_vm._v(" close")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }