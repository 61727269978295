<template>
  <!-- Cart -->
  <div class="hder-section cart-ui">
    <!-- itemList -->
    <div class="hd-sec-cont cart-area">
      <div class="w-con">

        <p class="tt-1 tac">Cart</p>
        <div class="cart-con cart-prod-list">
          <div class="tab-ui tab-ui-dark">
            <ul>
              <li class="btn-tab" :class="{'on' : category.all.show === true}">
                <button type="button" @click="switchShow('all')">
                  All ({{ category.all.list.length }})
                </button>
              </li>
              <li class="btn-tab" :class="{'on' : category.hflor.show === true}">
                <button type="button" @click="switchShow('hflor')">
                  HFLOR ({{ category.hflor.list.length }})
                </button>
              </li>
              <li class="btn-tab" :class="{'on' : category.viatera.show === true}">
                <button type="button" @click="switchShow('viatera')">
                  Viatera ({{ category.viatera.list.length }})
                </button>
              </li>
              <li class="btn-tab" :class="{'on' : category.himacs.show === true}">
                <button type="button" @click="switchShow('himacs')">
                  HIMACS ({{ category.himacs.list.length }})
                </button>
              </li>
              <li class="btn-tab" :class="{'on' : category.teracanto.show === true}">
                <button type="button" @click="switchShow('teracanto')">
                  TERACANTO ({{ category.teracanto.list.length }})
                </button>
              </li>
            </ul>
          </div>

          <div class="tab-con on">

            <div class="cart-wrap">
              <section class="col">
                <p class="tt-3">{{ tabTitle }}</p>
                <div class="tbl-box type05">
                  <table>
                    <caption>Shipping form</caption>
                    <colgroup>
                      <col style="width:63px">
                      <col style="width:auto">
                      <col style="width:130px">
                      <col style="width:90px">
                      <col style="width:140px">
                    </colgroup>
                    <thead>
                    <tr>
                      <th class="">
                        <label class="inp-chk single">
                          <input type="checkbox" id="allChk" v-model="allChecked" @change="checkAll()">
                          <span>All Select</span>
                        </label>
                      </th>
                      <th class="">Product info</th>
                      <th class="">Qty</th>
                      <th class="">Each</th>
                      <th class="">Total</th>
                    </tr>
                    </thead>
                    <tbody id="itemList">
                    <tr v-for="(item, index) in viewList" :key="index">
                      <a :href="fullUrl" :data-ec-view-cart="makeDataLayerForm(item)" style="display: none"></a>
                      <td class="">
                        <label class="inp-chk single">
                          <input type="checkbox" v-model="checkedArr" :value="item.cartId" :id="item.cartId">
                          <span>{{ item.content.content.title }}</span>
                        </label>
                      </td>
                      <td>
                        <div class="cart-pd-detail">
                          <div class="img-box">
                            <img :src="$imagePath + item.content.orgContent.cover" alt="image">
                          </div>
                          <div class="info">
                            <ul>
                              <li class="txt-code">{{ item.content.attribute.code }}</li>
                              <li class="txt-prod">
                                <span>{{ item.content.content.title }}</span>
                              </li>
                              <li class="txt-opt">
                                <div class="selOpt"><span>Size</span></div>
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel disable" disabled>
                                    <span>{{ item.sampleInfo.size }}</span>
                                  </button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li>
                                        {{ item.sampleInfo.size }}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="spin-ui">
                          <button v-if="item.qty === 1" type="button" class="btn-m disable" @click="modifyQty(item, 'minus', $event)">
                            <span>minus-{{ item.qty }}</span></button>
                          <button v-else type="button" class="btn-m" @click="modifyQty(item, 'minus', $event)">
                            <span>minus-{{ item.qty }}</span></button>
                          <input type="text" class="inp-qty" title="input quantity" :value="item.qty">
                          <button type="button" class="btn-p" @click="modifyQty(item, 'plus', $event)"><span>plus</span>
                          </button>
                        </div>
                      </td>
                      <td class="txt-price">
                        <div>$ {{ item.sampleInfo.price }}</div>
                      </td>
                      <td class="txt-tot">
                        <div>$ {{ calcOrder(item.sampleInfo.price, item.qty) }}</div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="more-box type02-03 mt-30">
                  <button type="button" class="btn btn-line btn-delete" @click="deleteCart()">
                    <span>Select Delete</span>
                  </button>
                </div>
                <div class="t-type-03">
                  Refunds will not be provided for any sample purchase. We do not provide credit, refunds, or prorated
                  billing for any sample purchases.
                </div>
              </section>

              <section class="col">
                <p class="tt-3">Checkout</p>
                <div class="tbl-01">
                  <div class="tbl-box type03">
                    <table>
                      <caption>Checkout form</caption>
                      <colgroup>
                        <!-- <col style="width:220px">
                        <col style="width:auto"> -->
                      </colgroup>
                      <tbody>
                      <tr>
                        <th scope="row">Subtotal</th>
                        <td class="ta-r">$ {{ calcSubTotal() }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Shipping &amp; Handling</th>
                        <td class="ta-r">FedEx Ground</td>
                      </tr>
                      <tr>
                        <th scope="row">Grand Total</th>
                        <td class="ta-r txt-gTot">$ {{ calcGrandTotal() }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>

            <div class="more-box ta-c btn-cart-buy inner">
              <button type="button" class="btn" @click="buy()"><span>Checkout</span></button>
            </div>

          </div>
        </div>

        <!-- 주문확인 -->
        <div class="mypg-checkOrd">
          <p class="tt-2 tac">Check Your Order Status</p>
          <div class="inpt-mypg">
            <ul>
              <li>
                <label for="inp05">Order Number</label>
                <span><input type="text" class="inp-text" id="inp05" v-model="orderNum"
                  placeholder="Order Number"/></span>
              </li>
              <li>
                <label for="inp06">Order Customer Email</label>
                <span><input type="text" class="inp-text" id="inp06" v-model="orderEmail"
                  placeholder="Order Customer Email"/></span>
              </li>
            </ul>
          </div>
          <div class="btn-mypg tac">
            <button type="button" @click="orderStatus" class="button h55 btn_submit">
              <span class="txt">Order Status</span>
            </button>
          </div>
        </div>

      </div>
      <div class="hd-close">
        <button type="button" @click="closePopup(chkHome)" class="btn-hd-close" data-ui="cart-ui"><span class="blind">Close Button</span>
        </button>
      </div>
    </div>
    <!-- //itemList -->
  </div>
  <!-- //Cart -->
</template>

<script>
import _ from '@/common/helper/lodash.js'
import { $utils } from '@/common/helper'
import memberInfoService from '@/server/myPage.service'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'CartPopComponent',
  computed: {
    ...mapState({
      cartList: state => state.order.cartList
    }),
    showFlag () {
      const mapped = _.map(this.category, function (v, k) { return v.show ? k : '' })
      return _.find(mapped, function (o) { return o.length > 0 })
    }
  },
  data () {
    return {
      // 제품별 속성정의 (제품별목록, 구분 카테고리 id)
      category: {
        all: {
          name: 'PRD',
          id: 0,
          list: [],
          show: true
        },
        hflor: {
          name: 'PRD_FLOORING',
          id: 0,
          list: [],
          show: false
        },
        himacs: {
          name: 'HIMACS_COLOR',
          id: 0,
          list: [],
          show: false
        },
        viatera: {
          name: 'VIATERA_COLOR',
          id: 0,
          list: [],
          show: false
        },
        teracanto: {
          name: 'TERACANTO_COLOR',
          id: 0,
          list: [],
          show: false
        }
      },
      allList: [],
      viewList: [], // 화면에 보여줄 목록
      fullUrl: document.URL,
      checkedArr: [], // checkbox 목록
      allChecked: false,
      componentOn: false,
      nationCode: '',
      tabTitle: '',
      chkHome: '',
      orderNum: '',
      orderEmail: ''
    }
  },
  watch: {
    checkedArr () {
      this.setAllChecked()
    }
  },
  async created () {
    // 다른곳에서 부를 수 있도록 지정.
    this.$root.$refs.cartView = this
  },
  mounted () {
    $.fn.fnMainOP_ON = function () {
      const _this = $(this)
      _this.on('mouseover', function (e) {
        e.preventDefault()
        const $this = $(this)
        const n = $this.parent().index()
        $this.parent().addClass('on')
        $this.parent().siblings('.item').addClass('off')
        $this.parent().parent().siblings('.ourprod-view').children('.item').removeClass('show')
        $this.parent().parent().siblings('.ourprod-view').children('.item:eq(' + n + ')').addClass('show').animate({
          width: '100%', left: '0'
        }, 1000, 'easeInOutQuint')
      })
    }
    $.fn.fnMainOP_OFF = function (w) {
      const _this = $(this)
      _this.on('mouseleave', function (e) {
        e.preventDefault()
        const $this = $(this)
        const n = $this.parent().index()
        $this.parent().removeClass('on')
        $this.parent().siblings('.item').removeClass('off')
        $this.parent().parent().siblings('.ourprod-view').children('.item:eq(' + n + ')').stop()
        $this.parent().parent().siblings('.ourprod-view').children('.item:eq(' + n + ')').css('left', 'calc( (100% / 3) * ' + n + ')')
        $this.parent().parent().siblings('.ourprod-view').children('.item').removeClass('show').css({
          width: 'calc(100% / 3)'
        })
      })
    }
    if ($('.ourprod-wrap').length) {
      $('.ourprod-wrap.p-ui .ourprod-content .item .title').fnMainOP_ON('calc(100%)')
      $('.ourprod-wrap.p-ui .ourprod-content .item .title').fnMainOP_OFF('calc(100%)')
    }
  },
  methods: {
    async openPopup (home) {
      this.chkHome = home
      this.category.himacs.id = $utils.getPrdCateId('himacsId')
      this.category.viatera.id = $utils.getPrdCateId('viateraId')
      this.category.hflor.id = $utils.getPrdCateId('hflorId')
      this.category.teracanto.id = $utils.getPrdCateId('teracantoId')
      await this.init()
    },
    async init () {
      this.clearData()
      if (!this.componentOn) {
        this.componentOn = true// 다중 동작 방지
        // 초기화(선택 유지의 경우 없앨 수 있음)
        this.category.all.show = true
        this.category.hflor.show = false
        this.category.viatera.show = false
        this.category.himacs.show = false
        this.category.teracanto.show = false
      }
      await this.setData()
    },
    setAllChecked () {
      const _this = this

      const itemList = $('#itemList input:checkbox')
      _this.allChecked = itemList.length > 0
      itemList.each(function () {
        const __this = this
        if (_.isUndefined(_.find(_this.checkedArr, function (n) { return $(__this).val() == n }))) {
          _this.allChecked = false
        }
      })
    },
    splitByCategory () {
      const _this = this

      const cateProps = ['hflor', 'himacs', 'viatera', 'teracanto']
      _.forEach(_this.allList, function (v) {
        const parents = _.flatMap(v.content.parents, function (o) { return o.id })

        _.forEach(cateProps, function (prop) {
          if (_.indexOf(parents, _this.category[prop].id) !== -1) {
            v.categoryId = _this.category[prop].id
            _this.category[prop].list.push(v)
          }
        })
      })

      _this.category.all.list = _this.allList
    },
    async setData () {
      const _this = this
      const reduced = _this.cartList.reduce(function (result, item) {
        const contentId = item.content.content.id
        const size = item.sampleInfo.size

        const key = contentId + '_' + size

        if (!result[key]) {
          result[key] = _.cloneDeep(item)
        } else {
          const qty = result[key].qty + item.qty
          result[key].qty = qty > 10 ? 10 : qty
        }

        return result
      }, {})

      _this.allList = Object.values(reduced)
      _this.splitByCategory()

      _this.checkedArr = _this.allList.map(i => i.cartId)
      _this.$nextTick(function () {
        // popup open
        hdSec.open('.cart-ui')

        this.switchShow(this.showFlag)
      })
    },
    makeDataLayerForm (data) {
      let prdVariant = ''
      let prdPrice = ''
      let cateCode = ''
      let cateName = ''
      let type = ''
      let brandName = ''
      if ((data.content.attribute.price != null || undefined) && data.content.attribute.price.length < 10) {
        prdVariant = _.join(_.flatMap(data.content.attribute.price, function (v) {
          if (v.price == data.price) {
            return v.size
          }
        }))
        prdVariant = prdVariant.replace(',', '')
        prdPrice = _.join(_.flatMap(data.content.attribute.price, function (v) {
          return v.price
        }), '|')
      }
      if (_.includes(data.content.content.modelId, 'HFLOR')) {
        // HFLOR
        brandName = data.content.parents[2].name
        cateCode = data.content.parents[1].code
        cateName = data.content.parents[1].name
        if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
          type = data.content.attribute.prdTypeText
        }
      } else {
        brandName = data.content.parents[1].name
        cateName = ''
      }
      return JSON.stringify({
        ecom_prd_id: data.content.attribute.code == '' ? data.content.content.id : data.content.attribute.code,
        ecom_prd_name: data.content.content.title,
        ecom_prd_brand: brandName,
        ecom_prd_category: cateName, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: prdVariant, // price over 1 items
        ecom_prd_type: type, // HFLOR only
        ecom_prd_collection: data.content.parents[0].name,
        ecom_prd_color: data.content.attribute.hueText,
        ecom_prd_price: data.price, // price over 1 items
        ecom_currency_code: 'USD'
      })
    },
    switchShow (key) {
      const _this = this
      // 탭 변경 기능
      _this.viewList = []
      _this.tabTitle = key.toUpperCase()
      _.forIn(this.category, (v, k) => {
        if (k === key) {
          v.show = true
          _this.viewList = v.list
        } else {
          v.show = false
        }
      })
      _this.$nextTick(function () {
        _this.setAllChecked()

        if (typeof (dataLayer) !== 'undefined') {
          dataLayer.push({ event: 'ecViewCart' })
        }
      })
    },
    modifyQty (item, mode, event) {
      // 수량 변경
      if (mode === 'plus') {
        if (item.qty < 10) {
          item.qty++
        } else {
          alert('You can not order up to 10 samples')
        }
      } else {
        if (item.qty !== 1) {
          item.qty--
        }
      }

      // 버튼 요소 찾기
      const buttonElement = event.currentTarget
      const spinUiElement = buttonElement.closest('.spin-ui')
      const plusButton = spinUiElement.querySelector('.btn-p')
      const minusButton = spinUiElement.querySelector('.btn-m')

      // Disable/enable buttons based on quantity
      if (item.qty >= 10) {
        plusButton.classList.add('disable')
      } else {
        plusButton.classList.remove('disable')
      }

      if (item.qty <= 1) {
        minusButton.classList.add('disable')
      } else {
        minusButton.classList.remove('disable')
      }
    },
    calcOrder (price, qty) {
      // sample 당 가격산출
      return Number(price) * Number(qty)
    },
    calcSubTotal () {
      const _this = this
      // 총 가격 산출
      let tot = 0.0
      _.forEach(this.viewList, (v) => {
        if (!_.isUndefined(_.find(_this.checkedArr, function (n) { return n === v.cartId }))) {
          const qty = v.qty
          const price = v.sampleInfo.price
          tot += Number(qty) * Number(price)
        }
      })
      return tot.toFixed(2)
    },
    calcGrandTotal () {
      const _this = this
      // 총 가격 산출
      let tot = 0.0
      _.forEach(this.allList, (v) => {
        if (!_.isUndefined(_.find(_this.checkedArr, function (n) { return n === v.cartId }))) {
          const qty = v.qty
          const price = v.sampleInfo.price
          tot += Number(qty) * Number(price)
        }
      })
      return tot.toFixed(2)
    },
    async setDeleteItemForGtm (item) {
      const obj = {}
      let brand = ''
      let category = ''
      let size = ''
      let type = ''
      let color = ''
      let collection = ''
      let cateCode = ''

      if (_.includes(item.content.content.modelId, 'HFLOR')) {
        // HFLOR
        brand = item.content.parents[2].name
        category = item.content.parents[1].name
        collection = item.content.parents[0].name
        cateCode = item.content.parents[1].code
        if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
          type = item.content.attribute.prdTypeText
        }
      } else {
        // Viatera, Himacs
        brand = item.content.parents[1].name
        category = ''
        collection = item.content.parents[0].name
        color = item.content.attribute.hueText // himacs, viatera only
      }
      size = item.sampleInfo.size
      obj.ecom_prd_id = item.content.attribute.code == '' ? item.content.content.id : item.content.attribute.code
      obj.ecom_prd_name = item.content.content.title
      obj.ecom_prd_brand = brand
      obj.ecom_prd_category = category
      obj.ecom_prd_variant = size
      obj.ecom_prd_type = type
      obj.ecom_prd_collection = collection
      obj.ecom_prd_color = color
      obj.ecom_prd_price = item.sampleInfo.price
      obj.ecom_prd_qty = item.qty
      digitalData.removeFromCart.products.push(obj)
    },
    async deleteCart () {
      const _this = this
      if (_this.checkedArr.length === 0) {
        alert('No selected data')
        return
      }

      digitalData.removeFromCart = {
        ecom_currency_code: 'USD',
        products: []
      }

      _.forEach(_this.checkedArr, function (v) {
        const found = _.find(_this.viewList, function (o) { return v === o.cartId })

        if (!_.isUndefined(found)) {
          console.log('found', found)
          _this.setDeleteItemForGtm(found)
          // remove from array
          for (const key in _this.category) {
            if (_.isArray(_this.category[key].list)) {
              _.remove(_this.category[key].list, function (o) { return v === o.cartId })
            }
          }
          _.remove(_this.allList, function (o) { return v === o.cartId })
          _.remove(_this.viewList, function (o) { return v === o.cartId })
        }
      })

      _this.$store.dispatch('order/setCartItem', this.allList)

      _this.$forceUpdate()

      // GTM removecart dataLayer push
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({ event: 'ecRemoveCart' })
      }
    },
    checkAll () {
      const _this = this
      if (this.allChecked) {
        $('#itemList input:checkbox').each(function () {
          _this.checkedArr.push($(this).val())
        })
      } else {
        $('#itemList input:checkbox').each(function () {
          const __this = this
          _.remove(_this.checkedArr, function (n) {
            return n === $(__this).val()
          })
        })
      }
    },
    buy () {
      const that = this
      // 구매페이지 이동
      if (this.checkedArr.length === 0) {
        alert('No selected data')
        return
      }
      const orderList = []
      _.forEach(this.checkedArr, (v) => {
        const obj = _.find(that.allList, { cartId: v })
        orderList.push(obj)
      })
      this.$store.dispatch('order/setOrderItem', orderList)
      location.href = 'order/info'
    },
    clearData () {
      // 데이터 클리어
      _.forIn(this.category, (v, k) => {
        v.list = []
      })
      this.viewList = []
      this.checkedArr = []
      this.allList = []
    },
    closePopup () {
      this.clearData()
      this.componentOn = false
      if (this.chkHome) {
        this.$router.go(-1)
      }
    },
    gotoUrl (url) {
      if (url !== null) {
        this.closePopup()
        hdSec.close('.cart-ui')
        window.location.href = url
      }
    },
    async orderStatus () {
      const orderRes = await memberInfoService.getOrder(this.orderNum)
      if (orderRes.data === '') {
        alert('Not exist Order Info')
      } else {
        const order = orderRes.data
        if (order.email !== this.orderEmail) {
          alert('Not exist Order Info')
        } else {
          const url = location.href
          const index = url.indexOf('login')
          const sub = url.substring(0, index)
          const resultUrl = url.replace(sub, '')

          Cookies.set('orderStatus', resultUrl)
          location.href = 'myPage/orderHistory/detail/' + this.orderNum
        }
      }
    }
  }
}

</script>

<style scoped>
.tab-ui-dark ul li.btn-tab.on button > span {
  color: #fff;
}
</style>
