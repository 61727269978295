<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content order-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Header</li>
          <li>Order Sample</li>
          <li>Success</li>
        </ul>
      </div>
      <!-- //location -->

      <div class="container order-inp-box order-sample-info">
        <div class="w-con-02 inner" style=" width: 100%; height: 100%;">
          <p class="tt-2" style="text-align: center;">Successfully Ordered</p>

          <div class="more-box ta-c mb-20">
            <button type="button" class="btn" @click="goHome()"><span>Home</span></button>
          </div>

        </div>
      </div>
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import GnbComponent from '@/components/Layout/GNB/index.vue'
import FooterComponent from '@/components/Layout/Footer/index.vue'
import HeaderComponent from '@/components/Layout/Header/index.vue'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'success',
  components: { QuickMenuComponent, HeaderComponent, FooterComponent, GnbComponent },
}
</script>
