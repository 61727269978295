<template>
  <OverviewSection
    type="major"
    title="Social Contribution Activities"
    color="#222"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem>
      <div class="social-contribution-activities-card-div">
        <SocialContributionActivitiesCard
          v-for="(item, index) in cardItems"
          :key="index"
          :title="item.title"
          :text="item.text"
          :image="item.image"
          :flexDirection="item.flexDirection"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    type="major"
    title="社会貢献活動"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem>
      <div class="social-contribution-activities-card-div">
        <SocialContributionActivitiesCard
          v-for="(item, index) in cardItems_jp"
          :key="index"
          :title="item.title"
          :text="item.text"
          :image="item.image"
          :flexDirection="item.flexDirection"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    type="major"
    title="Социальный вклад"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem>
      <div class="social-contribution-activities-card-div">
        <SocialContributionActivitiesCard
          v-for="(item, index) in cardItems_ru"
          :key="index"
          :title="item.title"
          :text="item.text"
          :image="item.image"
          :flexDirection="item.flexDirection"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    type="major"
    title="社会贡献活动"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem>
      <div class="social-contribution-activities-card-div">
        <SocialContributionActivitiesCard
          v-for="(item, index) in cardItems_cn"
          :key="index"
          :title="item.title"
          :text="item.text"
          :image="item.image"
          :flexDirection="item.flexDirection"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SocialContributionActivitiesCard from './SocialContributionActivitiesCard.vue'
export default {
  components: {
    SocialContributionActivitiesCard,
    OverviewSection,
    OverviewSectionItem
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardItems: [
        {
          title: 'Renovating Memorial Centers',
          text: 'LX Hausys renovated a considerable number of centers which server as memorials for Korean\n' +
                'independence activists (Seo Jaepil, Yun Bonggil, Lee Hwayeong, Ahn Junggun, Han Yongwun, Ahn\n' +
                'Changho, Kim Chang suk, Shin Chaeho, and Son Byunghee), in addition to renovating the Yi Yuksa\n' +
                'Literary Museum, using our outstanding windows and flooring materials, among other\n' +
                'products.We also supported the restoration of twelve historic domestic and overseas sites,\n' +
                'including the Chongqing Provisional Government Complex and the former Embassy of the Korean\n' +
                'Empire in the U.S.',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image1.png'
        },
        {
          title: 'Renovating remembrance facilities and improving\nresidence for people of national merit',
          text: 'LX Hausys renovated a considerable number of centers which server as memorials for Korean\n' +
                'independence activists (Seo Jaepil, Yun Bonggil, Lee Hwayeong, Ahn Junggun, Han Yongwun,\n' +
                'Ahn Changho, Kim Chang suk, Shin Chaeho, and Son Byunghee), in addition to renovating the\n' +
                'Yi Yuksa Literary Museum, using our outstanding windows and flooring materials, among other products.\n' +
                'We also supported the restoration of twelve historic domestic and overseas sites, including the\n' +
                'Chongqing Provisional Government Complex and the former Embassy of the Korean Empire in the U.S.',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image2.png',
          flexDirection: 'row-reverse'
        },
        {
          title: 'Community Sharing Activities',
          text: 'We are carrying out activities that contribute to regional development, such as\n' +
                'improving social welfare facilities for youth, improving facilities at local children’s\n' +
                'centers, purifying and supporting facilities around each business site in Korea and\n' +
                'abroad, and recovering fl ood-damaged areas.\n' +
                '(Creating a pleasant space together with employees)',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image3.png'
        },
        {
          title: 'Creation of community value through public-private partnership',
          text: 'We are taking the lead in creating values for the local community by working in cooperation with the\n' +
                'Ministry of Land, Infrastructure and Transport and the Ministry of Environment in the green remodeling\n' +
                'project, residential environment improvement project for the socially vulnerable, indoor environment\n' +
                'diagnosis/improvement support, indoor environment improvement project for green daycare centers, etc.\n' +
                '(Business agreement with the Ministry of Environment to improve the indoor environment for vulnerable\n' +
                'social groups)',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image4.png',
          flexDirection: 'row-reverse'
        }
      ],
      cardItems_jp: [
        {
          title: '記念館の改修',
          text: 'LX Hausysは、Yi Yuksa文学博物館の改修に加え、朝鮮独立運動家(Seo Jaepil, Yun Bonggil, \n'+
          'Lee Hwayeong, Ahn Junggun, Han Yongwun, Ahn Changho, Kim Chang suk, Shin Chaeho, \n'+
          'and Son Byunghee) の記念館を多数改修し、当社の優れた窓材や床材などを使用してきました。\n'+
          'また、重慶臨時政府庁舎や旧大韓帝国駐米大使館など、国内外の12の歴史的建造物の修復\n'+
          'も支援しました。',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image1.png'
        },
        {
          title: '国家功労者慰霊施設の改修と\n住宅の整備',
          text: 'LX Hausysは、 Yi Yuksa 文学博物館の改修工事をはじめ、李陸史文学博物館の改修工事にも\n'+
          '当社の優れた窓材や床材などを活用し、韓国独立運動家(Seo Jaepil, Yun Bonggil, Lee Hwayeong, \n'+
          'Ahn Junggun, Han Yongwun, Ahn Changho, Kim Chang suk, Shin Chaeho, and Son Byunghee)\n'+
          'の記念館を多数改修しました。また、重慶臨時政府庁舎や旧大韓帝国駐米大使館など、国内外の\n'+
          '12か所の歴史遺産の復元も支援しました。',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image2.png',
          flexDirection: 'row-reverse'
        },
        {
          title: 'コミュニティ共有活動',
          text: '青少年福祉施設の整備、地域児童センターの設備改善、韓国・海外各事業\n'+
          '所周辺の浄化・支援施設、洪水被害地域の復旧など、地域振興に貢献する活動を\n'+
          '行っています。（社員とともに快適な空間づくり）',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image3.png'
        },
        {
          title: '官民連携による地域価値の創造',
          text: '国土交通省、環境省と連携し、グリーンリフォーム事業、社会的弱者向け住環境\n'+
          '改善事業、室内環境診断・改善支援、グリーン保育所室内環境改善事業等に取り組み、\n'+
          '地域社会の価値創造をリードしています。（環境省と社会的弱者向け室内環境改善に\n'+
          '関する業務協定）',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image4.png',
          flexDirection: 'row-reverse'
        }
      ],
      cardItems_ru: [
        {
          title: 'Обновление мемориальных центров',
          text: 'Компания LX Hausys отремонтировала значительное количество центров, которые \n'+
          'служили мемориалами борцам за независимость Кореи (Со Чжэпиль, Юн Бонгиль, Ли Хвэён, \n'+
          'Ан Чжунгун, Хан Ёнвун, Ан Чханхо, Ким Чан Сук, Шин Чэхо и Сон Бёнхи), а также \n'+
          'отреставрировала литературный музей И Юкса, используя наши окна и напольные покрытия. \n'+
          'Мы также оказали поддержку в реставрации двенадцати исторических объектов в стране и за \n'+
          'рубежом, включая комплекс временного правительства в Чунцине и бывшее посольство \n'+
          'Корейской империи в США.',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image1.png'
        },
        {
          title: 'Обновление памятных мест и \nулучшение условий проживания для \nлюдей с национальными заслугами',
          text: 'Компания LX Hausys отремонтировала значительное количество центров, которые \n'+
          'служили мемориалами борцам за независимость Кореи (Со Чжэпиль, Юн Бонгиль, Ли Хвэён, \n'+
          'Ан Чжунгун, Хан Ёнвун, Ан Чханхо, Ким Чан Сук, Шин Чэхо и Сон Бёнхи), а также \n'+
          'отреставрировала литературный музей И Юкса, используя наши окна и напольные покрытия. \n'+
          'Мы также оказали поддержку в реставрации двенадцати исторических объектов в стране \n'+
          'и за рубежом, включая комплекс временного правительства в Чунцине и бывшее посольство \n'+
          'Корейской империи в США.',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image2.png',
          flexDirection: 'row-reverse'
        },
        {
          title: 'Мероприятия по обмену опытом',
          text: 'Мы проводим мероприятия, способствующие региональному развитию, \n'+
          'такие как улучшение социальных условий для молодежи, улучшение условий \n'+
          'в местных детских центрах, очистка и поддержка объектов вокруг каждого \n'+
          'предприятия в Корее и за рубежом, а также восстановление пострадавших \n'+
          'от пожара территорий. \n'+
          '(Создание приятного пространства вместе с сотрудниками).',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image3.png'
        },
        {
          title: 'Создание общественной ценности \nгосударственно-частное партнерство',
          text: 'Мы играем ведущую роль в создании ценностей для местного сообщества, сотрудничая \n'+
          'с Министерством земельных ресурсов, инфраструктуры и транспорта и Министерством \n'+
          'окружающей среды в рамках проекта "зеленой" реконструкции, проекта по улучшению жилой \n'+
          'среды для социально уязвимых слоев населения, проекта по улучшению внутренней среды \n'+
          'диагностика/улучшение, проекта по улучшению внутренней среды для детских садов и т. д. \n'+
          '(деловое соглашение с Министерством окружающей среды по улучшению внутренней среды для \n'+
          'уязвимых социальных групп).',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image4.png',
          flexDirection: 'row-reverse'
        }
      ],
      cardItems_cn: [
        {
          title: '改善显忠设施环境',
          text: 'LX Hausys翻修了很多纪念中心和国家功臣居住环境（徐在丕、尹邦吉、李华英、\n'+
          '安俊根、韩永武、安昌浩、金昌硕、申昭浩和孙秉熙）。此外，使用我们出色的\n'+
          '门窗和地板材料等产品翻修了易书社文学博物馆。我们持续修复了12个国内外\n'+
          '历史遗址，包括重庆临时政府大楼和大韩帝国驻美公使馆。',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image1.png'
        },
        {
          title: '改善纪念设施及国家功臣居住环境',
          text: 'LX Hausys翻修了很多纪念中心和国家功臣居住环境（徐在丕、尹邦吉、李华英、\n'+
          '安俊根、韩永武、安昌浩、金昌硕、申昭浩和孙秉熙）。此外，使用我们出色的门窗和\n'+
          '地板材料等产品翻修了易书社文学博物馆。我们持续修复了12个国内外历史遗址，\n'+
          '包括重庆临时政府大楼和大韩帝国驻美公使馆。',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image2.png',
          flexDirection: 'row-reverse'
        },
        {
          title: '社会贡献活动',
          text: '我们正在开展有助于区域发展的活动，例如改善青年社会福利设施，\n'+
          '改善当地儿童中心的设施，在韩国和国外的每个商业场所周围净化\n'+
          '和配套设施，以及恢复受损地区。（携手员工创造一个愉快的空间）',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image3.png'
        },
        {
          title: '政企携手，创造社会价值',
          text: '我们与土地、基础设施和交通部以及环境部合作，在绿色改造项目、社会弱势群体\n'+
          '住宅环境改善项目、室内环境诊断/改善支持、绿色日托中心室内环境改善项目等方面\n'+
          '率先为当地社区创造价值。（与环境部签订商业协议，改善弱势社会群体的室内环境）',
          image: 'images/aboutus/sustainability/social_responsibility/social-contribution-activities-image4.png',
          flexDirection: 'row-reverse'
        }
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style>
.social-contribution-activities-card-div {
  display: flex;
  gap: 64px;
  flex-direction: column;
}

@media (max-width: 768px) {
  .social-contribution-activities-card-div {
    width: 100%;
  }
}
</style>
