var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _vm._m(0),
    _c("div", { staticClass: "filter_checkbox_cont" }, [
      _c("div", { staticClass: "checkbox_box" }, [
        _c(
          "ul",
          { staticClass: "checkbox_more no_line" },
          _vm._l(_vm.categories, function (cate, index) {
            return _c("li", { key: index }, [
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "rdo" + index,
                  name: "rdo" + index,
                },
                domProps: { checked: _vm.checked(cate.code) },
              }),
              _c(
                "label",
                {
                  staticClass: "inp-rdo",
                  attrs: { for: "rdo" + index },
                  on: {
                    click: function ($event) {
                      return _vm.clickCate(cate.code)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t(cate.label)))])]
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("HFLOR")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }