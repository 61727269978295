<template>
    <table>
        <thead>
            <tr>
                <th><div class="header">{{ title }}</div></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="table-body">
                        <img :src="iconSrc1"/><span>{{ mainTxt1 }}</span>
                    </div>
                </td>
            </tr>
             <tr>
                <td>
                    <div class="table-body">
                        <img :src="iconSrc2"/><span>{{ mainTxt2 }}</span>
                    </div>
            </td>
            </tr>
            <tr>
                <td>
                    <div class="table-body">
                        <img :src="iconSrc3"/><span>{{ mainTxt3 }}</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    mainTxt1: {
      type: String,
      default: ''
    },
    iconSrc1: {
      type: String,
      default: ''
    },
    mainTxt2: {
      type: String,
      default: ''
    },
    iconSrc2: {
      type: String,
      default: ''
    },
    mainTxt3: {
      type: String,
      default: ''
    },
    iconSrc3: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
table {
    display: inline;
}

thead {
    border-top: 2px solid #000;
    border-bottom: 1px solid #000;
}

.header {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;
    text-align: center;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}

td {
    border-bottom: 1px solid #EAEAEA;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
}

.table-body {
    display: flex;
    align-items: center;
    height: 114px;
    width: 642px;
}

pre {
    color: #000;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-left: 45px;
}

img {
    width: 134px;
    height: 114px;
}
</style>
