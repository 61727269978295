<template>
  <OverviewSmallSection
  title="Direction of Strategy"
  fontFamily="Lato"
  fontWeight="700"
  v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem>
      <div class="border-card-area">
        <DirectionOfStrategyBorderCard :txt=borderCardItem.txt />
        <div class="card-area">
          <DirectionOfStrategyCard
            v-for="(it, idx) in cardItem"
            :key="idx"
            :idx="idx"
            :txt="it.txt"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- JP -->
  <OverviewSmallSection
    title="戦略の方向性"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem>
      <div class="border-card-area">
        <DirectionOfStrategyBorderCard :txt=borderCardItem_jp.txt />
        <div class="card-area">
          <DirectionOfStrategyCard
            v-for="(it, idx) in cardItem_jp"
            :key="idx"
            :idx="idx"
            :txt="it.txt"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- RU -->
  <OverviewSmallSection
    title="Направление стратегии"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem>
      <div class="border-card-area">
        <DirectionOfStrategyBorderCard :txt=borderCardItem_ru.txt />
        <div class="card-area">
          <DirectionOfStrategyCard
            v-for="(it, idx) in cardItem_ru"
            :key="idx"
            :idx="idx"
            :txt="it.txt"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- CN -->
  <OverviewSmallSection
    title="战略方向"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem>
      <div class="border-card-area">
        <DirectionOfStrategyBorderCard :txt=borderCardItem_cn.txt />
        <div class="card-area">
          <DirectionOfStrategyCard
            v-for="(it, idx) in cardItem_cn"
            :key="idx"
            :idx="idx"
            :txt="it.txt"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
</template>

<script>
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import OverviewSmallSection from '../../components/OverviewSmallSection.vue'
import DirectionOfStrategyBorderCard from './DirectionOfStrategyBorderCard.vue'
import DirectionOfStrategyCard from './DirectionOfStrategyCard.vue'

export default {
  components: {
    OverviewSmallSection,
    OverviewSectionItem,
    DirectionOfStrategyCard,
    DirectionOfStrategyBorderCard
  },

  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      borderCardItem:
        {
          txt: 'Advancement of Business Portfolio andContinued Strengthening of Business Structure'
        },
      cardItem: [
        {
          txt: 'Expansion of domestic B2C business and high value-added products for\nbuilding & decorative materials business'
        },
        {
          txt: 'Securing profitability through strengthened competitiveness in\nautomotive materials & components and industrial film businesses'
        }
      ],
      borderCardItem_jp:
        {
          txt: '事業ポートフォリオの高度化と事業構造の継続的な強化'
        },
      cardItem_jp: [
        {
          txt: '国内B2C事業の拡大と建装資材事業の高付加価値化'
        },
        {
          txt: '自動車用部材・産業用フィルム事業の競争力強化による収益確保'
        }
      ],
      borderCardItem_ru:
        {
          txt: 'Развитие бизнес-портфеля и дальнейшее укрепление структуры бизнеса'
        },
      cardItem_ru: [
        {
          txt: 'Расширение бизнеса B2C на внутреннем рынке и производство продукции с высокой добавленной стоимостью для строительных материалов и декора'
        },
        {
          txt: 'Обеспечение прибыльности за счет усиления конкурентоспособности производства автомобильных материалов и компонентов, а таже промышленной пленки'
        }
      ],
      borderCardItem_cn:
        {
          txt: '业务资产高度化及业务素质持续强化'
        },
      cardItem_cn: [
        {
          txt: '扩大韩国建筑装饰材料B2C事业及高附加值产品'
        },
        {
          txt: '通过提升汽车配件材料及产业用薄膜的产品竞争力确保收益性'
        }
      ]
    }
  },

  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.border-card-area {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
}

.card-area {
  display: flex;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  width: 1368px;
}
@media (max-width: 768px) {
  .card-area {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .border-card-area {
    align-items: center;
  }
  .card-area {
    flex-direction: column;
  }
}
</style>
