var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content cust-spr-wrap" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }),
            _c("li", [_vm._v(_vm._s(_vm.$t("imprint.FF0050_001")))]),
          ]),
        ]),
        _c("div", { staticClass: "container imprint" }, [
          _c("div", { staticClass: "w-con-02 inner" }, [
            _c("h1", { staticClass: "tt-1 mb-64 tac" }, [
              _vm._v(_vm._s(_vm.$t("imprint.FF0050_001"))),
            ]),
            _c("div", { staticClass: "cust-box" }, [
              _c("p", { staticClass: "ctt ctt-t1" }, [
                _vm._v(_vm._s(_vm.$t("imprint.FF0050_002"))),
              ]),
              _c("p", { staticClass: "ctt ctt-t4" }, [
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_003"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_004"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_005"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_006")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "cust-box" }, [
              _c("p", { staticClass: "ctt ctt-t1" }, [
                _vm._v(_vm._s(_vm.$t("imprint.FF0050_007"))),
              ]),
              _c("p", { staticClass: "ctt ctt-t4" }, [
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_008")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "cust-box" }, [
              _c("p", { staticClass: "ctt ctt-t1" }, [
                _vm._v(_vm._s(_vm.$t("imprint.FF0050_009"))),
              ]),
              _c("p", { staticClass: "ctt ctt-t4" }, [
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_010"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_011"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_012"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_013"))),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_014"))),
                _c("br"),
              ]),
            ]),
            _c("div", { staticClass: "cust-box" }, [
              _c("p", {
                staticClass: "ctt ctt-t1",
                domProps: { innerHTML: _vm._s(_vm.$t("imprint.FF0050_015")) },
              }),
            ]),
            _c("div", { staticClass: "cust-box" }, [
              _c("p", { staticClass: "ctt ctt-t1" }, [
                _vm._v(_vm._s(_vm.$t("imprint.FF0050_016"))),
              ]),
              _c("p", { staticClass: "ctt ctt-t4" }, [
                _vm._v(" " + _vm._s(_vm.$t("imprint.FF0050_017"))),
                _c("br"),
                _vm._v(_vm._s(_vm.$t("imprint.FF0050_018")) + " "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }