import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

Vue.mixin({
  data () {
    return {
    }
  },

  computed: {
    runningEnvironment: function () {
      return process.env.VUE_APP_ENV
    }
  },

  methods: {
    goHome () {
      this.gotoUrl('/')
    },
    gotoUrl (url) {
      const urls = window.location.pathname.split('/').filter(element => element)
      const base = urls.length > 0 ? '/' + urls[0] : ''

      window.location.href = base + url
    }
  }
})
