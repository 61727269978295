<template>
  <ProductLayout>
    <template #content>
      <!-- 상단 이미지 -->
      <ProductTopImage
        title="Automotive Component"
        description="Various components applied to the exterior and interior of automobiles"
        imageSrc="images/automotive/component/top.png"
      >
      </ProductTopImage>

      <!-- 제품 상세 영역 -->
      <ProductContent>
        <template>
          <!-- 소개 영역 -->
          <ProductSectionHeader
            class="mt-80"
            subTitle="We make innovative automotive components with a multitude of material formulas. Our cutting-edge technology improve energy efficiency and reduce weight of autos."
            subTitleWidth="1050px"
          />

          <!-- 제품 강점 영역 -->
          <ProductStrongGroup
            class="mt-100"
            iconType="vertical"
            :info="[
              {
                iconSrc: 'images/automotive/lightweight/strong-point-icon-1.png',
                title: 'Energy Efficiency',
                description: 'Reducing weight to <br/> improve energy efficiency'
              },
              {
                iconSrc: 'images/automotive/lightweight/strong-point-icon-2.png',
                title: 'High Quality',
                description: 'Excellent quality by a <br/> cutting edge technology'
              },
              {
                iconSrc: 'images/automotive/lightweight/strong-point-icon-3.png',
                title: 'Superior Design',
                description: 'Realizing the sophiscated and luxury patterns for your driving space'
              }
            ]"
          />

          <PartGroup class="mt-100" />

          <InteriorPart class="mt-100" />

          <!-- 하단 소개 페이지 -->
          <!-- <FooterNavigation /> -->
        </template>
      </ProductContent>
    </template>
  </ProductLayout>
</template>

<script>
import ProductLayout from '@/components/Layout/ProductLayout'
import ProductTopImage from '@/components/product/ProductTopImage'
import ProductContent from '@/components/product/ProductContent'
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductStrongGroup from '@/components/product/ProductStrongGroup'
import PartGroup from './components/PartGroup'
import InteriorPart from './components/InteriorPart'
import FooterNavigation from '@/views/products/automotive/AutomotiveSkin/components/FooterNavigation.vue'

export default {
  components: {
    FooterNavigation,
    ProductLayout,
    ProductTopImage,
    ProductContent,
    ProductSectionHeader,
    ProductStrongGroup,
    PartGroup,
    InteriorPart
  }
}

</script>
