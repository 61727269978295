<template>
  <ul>
    <COMPARE></COMPARE>
    <HFLOOR :category="category" v-on:category-changed="handleCategoryChanged"></HFLOOR>
    <COLLECTION :collectionList="collectionList" v-model="collection" :category="category"></COLLECTION>
    <COLOR :colorList="dictMap['hue']" v-model="hue"></COLOR>
    <FORMAT :formatList="dictMap['format']" v-model="format"></FORMAT>
    <PRD_PATTERN :patternList="dictMap['pattern']" v-model="pattern"></PRD_PATTERN>
    <THICKNESS :thickList="dictMap['thickness']" v-model="thickness"></THICKNESS>
    <WEARLAYER :wearLayerList="dictMap['wear_layer']" v-model="wear_layer"></WEARLAYER>
    <DIMENSIONS :dimList="dictMap['dimension']" v-model="dimension"></DIMENSIONS>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import COMPARE from './component/COMPARE.vue'
import PRD_PATTERN from './component/PATTERN.vue'
import COLLECTION from './component/COLLECTION.vue'
import HFLOOR from './component/HFLOOR.vue'
import DIMENSIONS from './component/DIMENSIONS.vue'
import THICKNESS from './component/THICKNESS.vue'
import WEARLAYER from './component/WEARLAYER.vue'
import FORMAT from '@/views/products/finder/component/FORMAT.vue'
import COLOR from '@/views/products/finder/component/COLOR.vue'
import PRODUCTTYPE from '@/views/products/finder/component/PRODUCTTYPE.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap',
    // 'nationCode'
  ],
  components: {
    PRODUCTTYPE,
    COLOR,
    FORMAT,
    DIMENSIONS,
    THICKNESS,
    WEARLAYER,
    COMPARE,
    PRD_PATTERN,
    COLLECTION,
    HFLOOR
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      collection: [],
      hue: [],
      pattern: [],
      dimension: [],
      prdType: [],
      thickness: [],
      wear_layer: [],
      format: [],
      thick: []
    }
  },
  watch: {},
  async created () {
    this.nationCode = this.$countryCode
  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.collection, vm.pattern, vm.prdType, vm.thickness, vm.wear_layer, vm.dimension, vm.format], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {
    handleCategoryChanged (cateCode) {
      this.$emit('category-changed', cateCode)
    }
  }
}
</script>
