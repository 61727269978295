<template>
  <div class="intro-area">
    <div class="text-area">
      <div class="text">
        <slot name="text">
        </slot>
      </div>
    </div>
    <div class="content-area" v-if="!isEmpty(youtubeId) || useImage">
      <iframe v-if="!isEmpty(youtubeId)" :src="`https://www.youtube.com/embed/${youtubeId}?enablejsapi=1&origin=http%3A%2F%2Flocalhost%3A3000&widgetid=1`" width="100%" frameborder="0"></iframe>
      <img
        v-if="useImage"
        :src="imageSrc"
      />
    </div>
  </div>
</template>

<script>
import YouTubePlayer from 'youtube-player'
import isEmpty from 'lodash/isEmpty'

export default {
  props: {
    youtubeId: {
      type: String,
      requried: false
    },
    imageSrc: {
      type: String,
      requried: false
    }
  },

  data: () => ({
    player: {},
    playerRefId: 'intro-youtube'
  }),

  mounted () {
    if (this.useYoutube) {
      this.initYoutubePlayer()
    }
  },

  computed: {
    useYoutube () {
      return this.youtubeId
    },
    useImage () {
      return this.imageSrc
    }
  },

  methods: {
    isEmpty,
    async initYoutubePlayer () {
      this.player = YouTubePlayer(this.playerRefId, {
        videoId: this.youtubeId
        // width: 600,
        // height: 337
      })
    }
  }
}

</script>

<style scoped>
.intro-area {
  height: 367px;
}
@media (max-width: 768px) {
  .intro-area {
    height: 100%;
  }
}

.intro-area .text-area {
  position: relative;
  top: 30px;
  width: calc(100% - 56px);
  height: calc(100% - 37px);
  background: #F1F1F5;
  padding-left: 50px;
  padding-top: 50px;
}
@media (max-width: 768px) {
  .intro-area .text-area {
    position: inherit;
    top: 0px;
    width: 100%;
    padding: 10px;
  }
}

.intro-area .text-area .text {
  max-width: 520px;
}

.intro-area .text-area .text span {
  color: #222;
  font-family: 'NotoSans' , sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .intro-area .text-area .text span {
    font-size: 20px;
    max-width: 219px;
  }
}

.intro-area .text-area span.bold {
  color: #222;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .intro-area .text-area span.bold {
    font-size: 36px;
    line-height: 38px;
  }
}

.intro-area .content-area {
  position: relative;
  left: 601px;
  top: -337px;
  width: 600px;
  height: 337px;
}
@media (max-width: 768px) {
  .intro-area .content-area {
    left: 0px;
    top: 0px;
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
}

.intro-area .content-area video {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .intro-area .content-area iframe {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 769px) {
  .intro-area .content-area iframe {
    width: 600px;
    height: 337px;
  }
}

.intro-area .content-area img {
  width: 100%;
  object-fit: cover;
}
</style>
