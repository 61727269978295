var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/esg_strategy_system/top_image.png",
          title: "ESG Strategy System",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("WeCreate"),
                  _c("InnovationForCustomers"),
                  _c("MaterialityAssessment"),
                  _c("EsgManagementSystem"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2821339321
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/esg_strategy_system/top_image.png",
          title: "ESG戦略システム",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("WeCreate"),
                _c("InnovationForCustomers"),
                _c("MaterialityAssessment"),
                _c("EsgManagementSystem"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/esg_strategy_system/top_image.png",
          title: "Стратегия ESG",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("WeCreate"),
                _c("InnovationForCustomers"),
                _c("MaterialityAssessment"),
                _c("EsgManagementSystem"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/esg_strategy_system/top_image.png",
          title: "可持续发展战略",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("WeCreate"),
                _c("InnovationForCustomers"),
                _c("MaterialityAssessment"),
                _c("EsgManagementSystem"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }