var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content content-finder" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("PCD.text.home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.hflor")))]),
          ]),
        ]),
        _c("div", { staticClass: "container prod-fd-view hflor-finder" }, [
          _c("div", { staticClass: "visual visual-detail" }, [
            _vm.pfBanImg
              ? _c(
                  "div",
                  {
                    staticClass: "visual-image",
                    style: {
                      backgroundImage:
                        "url( " + _vm.$imagePath + _vm.pfBanImg + ")",
                    },
                  },
                  [
                    _c("div", { staticClass: "txt-box" }, [
                      _c("h1", { staticClass: "tt-26" }, [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("HFLOR - "),
                        ]),
                        _vm._v(" " + _vm._s(_vm.subject) + " "),
                      ]),
                      _vm.brief != null
                        ? _c("p", {
                            staticClass: "t-type-05",
                            domProps: { innerHTML: _vm._s(_vm.brief) },
                          })
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-more-point",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.goLink()
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("PCD.button.learnMore"))),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "bgdim" }),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.nationCode != "_US" && _vm.nationCode != "_GL"
            ? _c("p", { staticClass: "ctt photo-credits ta-r" }, [
                _vm.photoCredit != null &&
                _vm.photoCredit != undefined &&
                _vm.photoCredit != ""
                  ? _c("span", {
                      staticClass: "pc-br",
                      domProps: { innerHTML: _vm._s(_vm.photoCredit) },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "col-02 inspr-view fd-view" }, [
            _c(
              "div",
              { staticClass: "search-box", attrs: { id: "filterSearch" } },
              [
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    attrs: {
                      id: "textSearch",
                      type: "text",
                      placeholder: this.$t("PF.text.searchText"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.textSearch($event)
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "icon-search gray",
                    attrs: { id: "searchBtn" },
                    on: {
                      click: function ($event) {
                        return _vm.textSearchDel()
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "drop-list inner" }, [
                  !_vm.isMobile()
                    ? _c("ul", [
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", { staticClass: "tt-sub" }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.hflor"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(_vm.$t("PD.button.list_view")))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type01 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("label", { staticClass: "inp-rdo" }, [
                                    _c("input", {
                                      attrs: { type: "radio", name: "rdo00" },
                                      domProps: {
                                        checked:
                                          _vm.categoryCode ===
                                          "FLOOR_LUX" + _vm.nationCode,
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("PF.button.lux"))),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", { staticClass: "tt-sub" }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-collection",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(_vm.$t("PCD.text.collection")))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                _vm._l(_vm.leftCltList, function (clt) {
                                  return _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        staticClass: "Collection",
                                        attrs: {
                                          type: "checkbox",
                                          id: clt.category.code,
                                        },
                                        on: {
                                          change: function ($event) {
                                            ;[
                                              _vm.fnCateSearch(
                                                clt.category.name,
                                                clt.category.id,
                                                "",
                                                clt.category.code
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(clt.category.name)),
                                      ]),
                                    ]),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _vm.leftPattns.length > 0
                          ? _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-patterns",
                                    attrs: { type: "button" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PF.text.patterns")))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk type02" },
                                    _vm._l(_vm.leftPattns, function (pattn) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk chk-thumb" },
                                          [
                                            _c("input", {
                                              staticClass: "pattern",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  _vm.cateCode +
                                                  "_pattern_" +
                                                  pattn.id.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "pattern",
                                                      pattn.text,
                                                      pattn.id.value,
                                                      "Patterns",
                                                      _vm.cateCode
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _vm._v("> "),
                                            _c("span", [
                                              _c("i", {
                                                style:
                                                  "background-image : url(images/products/" +
                                                  _vm.cateCode +
                                                  "_" +
                                                  pattn.id.value +
                                                  ".jpg)",
                                              }),
                                              _vm._v(_vm._s(pattn.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", { staticClass: "tt-sub" }, [
                              _vm._v(_vm._s(_vm.$t("PF.text.new"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-new",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(_vm.$t("PF.text.new")))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c(
                                    "label",
                                    { staticClass: "inp-chk type03" },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          id: _vm.cateCode + "_isNew_true",
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "isNew",
                                                "NEW COLORS",
                                                "true",
                                                "New",
                                                _vm.cateCode
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("PF.text.new_colors"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _vm._m(0),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk" },
                                _vm._l(_vm.leftInstall, function (install) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "Install",
                                      attrs: { "data-chk": install.id.value },
                                    },
                                    [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "ins_Method",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              _vm.cateCode +
                                              "_ins_Method_" +
                                              install.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "ins_Method",
                                                  install.text,
                                                  install.id.value,
                                                  "Installation Method",
                                                  _vm.cateCode
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(install.text)),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", { staticClass: "tt-sub" }, [
                              _vm._v(_vm._s(_vm.$t("PF.text.thickness"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-thickness",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(_vm.$t("PF.text.thickness")))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk" },
                                _vm._l(_vm.leftThick, function (thick) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "Thickness",
                                      attrs: { "data-chk": thick.id.value },
                                    },
                                    [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "thick",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              _vm.cateCode +
                                              "_thick_" +
                                              thick.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "thick",
                                                  thick.text,
                                                  thick.id.value,
                                                  "Thickness",
                                                  _vm.cateCode
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(thick.text)),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _vm.leftWear.length != 0
                          ? _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PF.text.wear_layer"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-wareLayer",
                                    attrs: { type: "button" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PF.text.wear_layer")))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(_vm.leftWear, function (wear) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass: "WearLayer",
                                          attrs: { "data-chk": wear.id.value },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "inp-chk" },
                                            [
                                              _c("input", {
                                                staticClass: "wearLayer",
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    _vm.cateCode +
                                                    "_wearLayer_" +
                                                    wear.id.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "wearLayer",
                                                        wear.text,
                                                        wear.id.value,
                                                        "WearLayer",
                                                        _vm.cateCode
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(wear.text)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("li", { staticClass: "on" }, [
                          _vm._m(1),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk" },
                                _vm._l(_vm.leftFormat, function (format) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "Format",
                                      attrs: { "data-chk": format.id.value },
                                    },
                                    [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "format",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              _vm.cateCode +
                                              "_format_" +
                                              format.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "format",
                                                  format.text,
                                                  format.id.value,
                                                  "Format",
                                                  _vm.cateCode
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(format.text)),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", { staticClass: "tt-sub" }, [
                              _vm._v(_vm._s(_vm.$t("PF.text.dimension"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-dimension",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(_vm.$t("PF.text.dimension")))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk" },
                                _vm._l(_vm.leftDimen, function (dimen) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "Dimension",
                                      attrs: { "data-chk": dimen.id.value },
                                    },
                                    [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "dimension",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              _vm.cateCode +
                                              "_dimension_" +
                                              dimen.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "dimension",
                                                  dimen.text,
                                                  dimen.id.value,
                                                  "Dimension",
                                                  _vm.cateCode
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(dimen.text)),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _vm._m(2),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk" },
                                _vm._l(_vm.leftPerform, function (perform) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "Performance",
                                      attrs: { "data-chk": perform.id.value },
                                    },
                                    [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "performance",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              _vm.cateCode +
                                              "_performance_" +
                                              perform.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "performance",
                                                  perform.text,
                                                  perform.id.value,
                                                  "Performance",
                                                  _vm.cateCode
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(perform.text)),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _c("div", { staticClass: "prod-list-box" }, [
              _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab on",
                      attrs: { id: "btn-tab01", "data-tab": "tab01" },
                    },
                    [
                      _c("button", { attrs: { type: "button" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("PF.button.all_products")) +
                            " (" +
                            _vm._s(_vm.contentLength) +
                            ")"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab",
                      attrs: { id: "btn-tab02", "data-tab": "tab02" },
                    },
                    [
                      _c("button", { attrs: { type: "button" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("PF.button.grouped_by_collections")) +
                            " (" +
                            _vm._s(_vm.collectionLength) +
                            ")"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "fd-view-mb search-area inner" }, [
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    attrs: {
                      id: "textSearchM",
                      type: "text",
                      placeholder: this.$t("PF.text.searchText"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.textSearch($event)
                      },
                    },
                  }),
                  _c("button", {
                    staticClass: "icon-search gray",
                    attrs: { id: "searchBtnM" },
                    on: {
                      click: function ($event) {
                        return _vm.textSearchDel()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "tab-ui inner" }, [
                _c("div", { staticClass: "filter-arae" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openSearchPopup("layer-search-box")
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("PF.button.filter_by"))),
                      _c("i"),
                    ]
                  ),
                ]),
                _vm.colorList.length > 0
                  ? _c("div", { staticClass: "select-view" }, [
                      _c(
                        "ul",
                        { staticClass: "list-filter-key" },
                        [
                          _vm._l(_vm.colorList, function (color, index) {
                            return _c(
                              "li",
                              {
                                staticClass: "sel",
                                on: {
                                  click: function ($event) {
                                    ;[
                                      _vm.colorDelete(
                                        color.subject,
                                        color.name,
                                        color.id,
                                        color.code
                                      ),
                                    ]
                                  },
                                },
                              },
                              [
                                color.subject == "Collection"
                                  ? _c("span", [_vm._v(_vm._s(color.subject))])
                                  : _c("span", [_vm._v(_vm._s(color.type))]),
                                _vm._v(" : "),
                                _c("span", [
                                  _vm._v(" " + _vm._s(color.name) + " "),
                                ]),
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v("✕"),
                                ]),
                              ]
                            )
                          }),
                          _c("li", { staticClass: "sel del_all" }, [
                            _vm.colorList.length > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-all-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearAll()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("PF.button.clear_all")) +
                                          " "
                                      ),
                                      _c("em", [_vm._v("✕")]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "sort-box" }, [
                  _c("div", { staticClass: "sel-box" }, [
                    _c(
                      "button",
                      { staticClass: "btn-sel", attrs: { type: "button" } },
                      [_c("span", [_vm._v(_vm._s(_vm.sortBy))])]
                    ),
                    _c("div", { staticClass: "sel-list" }, [
                      _c("ul", { staticClass: "box" }, [
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.sortByChange(
                                    "Popularity",
                                    "desc",
                                    "clicks"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("PF.button.Popularity")))]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.sortByChange(
                                    "New Products first",
                                    "desc"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("PF.button.new_products_first"))
                              ),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.sortByChange(
                                    "Products A to Z",
                                    "asc",
                                    "title"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("PF.button.products_a_to_z"))
                              ),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.sortByChange(
                                    "Products Z to A",
                                    "desc",
                                    "title"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("PF.button.products_z_to_a"))
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "more-box type03 comprae-box" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: { type: "button", id: "compare" },
                        on: {
                          click: function ($event) {
                            return _vm.getCompareList()
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("PF.button.compare_your_colors")) +
                              " (" +
                              _vm._s(_vm.compareArr.length) +
                              ")"
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "tab-con tab01 on" }, [
                  _c("div", { staticClass: "list-type-view row-04" }, [
                    _c(
                      "ul",
                      {},
                      _vm._l(_vm.contentList, function (item, idx) {
                        return _c("li", { key: idx }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "products/hflor/" +
                                  _vm.categoryCode +
                                  "/" +
                                  item.content.title +
                                  "/" +
                                  item.content.id,
                                tabindex: "0",
                              },
                            },
                            [
                              _c("div", { staticClass: "over" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  item.attribute.isNew === "true"
                                    ? _c("span", { staticClass: "new" }, [
                                        _vm._v(_vm._s(_vm.$t("PF.text.new"))),
                                      ])
                                    : _vm._e(),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath +
                                        item.attribute.pf_thumb,
                                      alt: item.content.title,
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "view" }, [
                            _vm.nationCode === "_US" || _vm.nationCode === "_UK"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-cart-shadow",
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openSampleOrder(
                                          item.content.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.cart")))]
                                )
                              : _vm._e(),
                            _vm.authenticated == true && _vm.nationCode == "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    class: { on: item.content.liked },
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLike(item, "HFLOR")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm._e(),
                            _vm.authenticated == false &&
                            _vm.nationCode == "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    attrs: {
                                      tabindex: "0",
                                      href: "javascript:void(0)",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.alertAndGoLogin()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm._e(),
                            _vm.getSessionContent != null &&
                            _vm.nationCode != "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    class: {
                                      on: _vm.getSessionContent.includes(
                                        item.content.id
                                      ),
                                    },
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLike(item, "HFLOR")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm.getSessionContent == null &&
                                _vm.nationCode != "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLike(item, "HFLOR")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "info02" }, [
                            _c("ul", [
                              _c(
                                "li",
                                { staticClass: "txt-02" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/" +
                                          "hflor/" +
                                          _vm.categoryCode +
                                          "/" +
                                          item.content.title +
                                          "/" +
                                          item.content.id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.content.title) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              item.attribute.disp_code != undefined
                                ? _c("li", { staticClass: "txt-01" }, [
                                    _vm._v(_vm._s(item.attribute.disp_code)),
                                  ])
                                : _c("li", { staticClass: "txt-01" }, [
                                    _vm._v(_vm._s(item.attribute.code)),
                                  ]),
                              _c("li", { staticClass: "txt-03" }, [
                                _vm._v(_vm._s(item.parents[0].name)),
                              ]),
                              _c("li", { staticClass: "txt-04" }, [
                                _vm._v(_vm._s(item.parents[1].name)),
                              ]),
                            ]),
                            _c("label", { staticClass: "inp-chk" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.compareArr,
                                    expression: "compareArr",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: item.content.id,
                                },
                                domProps: {
                                  value: item.content.id,
                                  checked: Array.isArray(_vm.compareArr)
                                    ? _vm._i(_vm.compareArr, item.content.id) >
                                      -1
                                    : _vm.compareArr,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.compareArr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.content.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.compareArr = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.compareArr = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.compareArr = $$c
                                      }
                                    },
                                    function ($event) {
                                      return _vm.addCompare(item.content.id)
                                    },
                                  ],
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.compare"))),
                              ]),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _vm.contentLength > 16 && _vm.pageIndex == 1
                    ? _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-arr-down",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.loadMore(_vm.pageIndex + 1)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.button.see_more"))),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.contentLength > (_vm.pageIndex / 16) * 16 * 16 &&
                  _vm.pageIndex != 1
                    ? _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-arr-down",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.loadMore(_vm.pageIndex + 1)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.button.see_more"))),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "tab-con tab02" },
                  _vm._l(_vm.checkCollectionList, function (coll, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "list-type-col" },
                      [
                        _c("ul", [
                          _c("li", { staticClass: "col-02" }, [
                            _c("div", { staticClass: "img-box" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.$imagePath +
                                    coll.categoryAttribute.image,
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "txt-box hf-txt-box" }, [
                              !coll.category.name.includes("Collection")
                                ? _c("p", { staticClass: "tt-3 mb-20" }, [
                                    _vm._v(
                                      _vm._s(coll.category.name) +
                                        " " +
                                        _vm._s(_vm.$t("PCD.text.collection"))
                                    ),
                                  ])
                                : _c("p", { staticClass: "tt-3 mb-20" }, [
                                    _vm._v(_vm._s(coll.category.name)),
                                  ]),
                              _c("div", { staticClass: "scroll" }, [
                                _c("div", { staticClass: "ctt ctt-t4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(coll.categoryAttribute.Des) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "more-box type02-02" }, [
                                _vm.categoryUsId[0] == _vm.categoryId
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-second",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fnCateSearch(
                                              coll.category.name,
                                              coll.category.id,
                                              "click",
                                              coll.category.code
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PF.button.see_all_colors")
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.categoryUsId[0] != _vm.categoryId
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-second",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fnCateSearch()
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PF.button.see_all_colors")
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.nationCode === "_US" &&
                                coll.categoryAttribute.onlineBook != null
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-line",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goLink(
                                              "book",
                                              coll.categoryAttribute.onlineBook
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PCD.text.online_book")
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "short-link type02 mb-64 inner" }, [
                _c("a", { attrs: { href: "support/contactus" } }, [
                  _c("span", { staticClass: "ico-07" }, [
                    _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                  ]),
                ]),
                _c("a", { attrs: { href: "pros/documentation/hflor" } }, [
                  _c("span", { staticClass: "ico-02" }, [
                    _vm._v(_vm._s(_vm.$t("PCD.text.documentation"))),
                  ]),
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "support/sales-representative/continenteu/hflor",
                    },
                  },
                  [
                    _c("span", { staticClass: "ico-19" }, [
                      _vm._v(_vm._s(_vm.$t("PCD.button.find_a_sales_rep"))),
                    ]),
                  ]
                ),
                _c("a", { attrs: { href: "inspiration/virtual-showroom" } }, [
                  _c("span", { staticClass: "ico-06" }, [
                    _vm._v(_vm._s(_vm.$t("PD.button.virtual_Showroom"))),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "layer-popup layer-alert type02" }, [
            _c("div", { staticClass: "popup pop-close" }, [
              _c("div", { staticClass: "pop-alert inner" }, [
                _c("div", { staticClass: "t-wrap" }, [
                  _c("div", { staticClass: "tit-box" }, [
                    _c("p", { staticClass: "ctt ctt-t4 artTit" }, [
                      _vm._v(_vm._s(_vm.$t("PF.popup.your_compare_is_full"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "txt-box" }, [
                    _c("i", { staticClass: "ico-alert" }),
                    _c("p", { staticClass: "tt-2" }, [
                      _vm._v(_vm._s(_vm.$t("PF.popup.compare_tool"))),
                    ]),
                    _c("div", { staticClass: "ctt ctt-t2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("PF.popup.compare_alert1"))),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.$t("PF.popup.compare_alert2")) + " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "more-box" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button", id: "replace" },
                      on: {
                        click: function ($event) {
                          return _vm.replace()
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("PF.popup.replace")))])]
                  ),
                ]),
                _c("div", { staticClass: "alert_txt" }, [
                  _c(
                    "button",
                    {
                      attrs: { id: "button editCompare" },
                      on: {
                        click: function ($event) {
                          return _vm.getCompareList()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("PF.popup.edit_products_in_compare"))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn-close btn",
                    attrs: { type: "button", onclick: "LayerPopup.close();" },
                  },
                  [_vm._v(_vm._s(_vm.$t("PF.popup.replace")))]
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "layer-popup layer-compare euSite",
              attrs: { id: "pdf_wrap" },
            },
            [
              _c("div", { staticClass: "popup pop-close" }, [
                _c("div", { staticClass: "pop-compare scr-y" }, [
                  _c("p", { staticClass: "tt-sub-gray" }, [
                    _vm._v(_vm._s(_vm.$t("PF.popup.compare_hflor_products"))),
                  ]),
                  _c("p", { staticClass: "ta-c" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("PF.popup.characteristics_technical_performance")
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "prod-list tbl-compare" }, [
                    _c("table", [
                      _c("caption", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "PF.popup.characteristics_technical_performance"
                            )
                          )
                        ),
                      ]),
                      _vm._m(3),
                      _c("tbody", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.compareList, function (item, idx) {
                              return _c("td", [
                                _c("div", { staticClass: "prod-box" }, [
                                  _c("div", { staticClass: "prod" }, [
                                    _c("div", { staticClass: "img-box over" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            item.attribute.pf_thumb,
                                          alt: item.content.title,
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "view" }, [
                                      _vm.getSessionContent != null &&
                                      _vm.nationCode != "_US"
                                        ? _c("a", {
                                            staticClass: "icon-wish-shadow",
                                            class: {
                                              on: _vm.getSessionContent.includes(
                                                item.content.id
                                              ),
                                            },
                                            attrs: { tabindex: "0" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleLike(
                                                  item,
                                                  "HFLOR"
                                                )
                                              },
                                            },
                                          })
                                        : _vm.getSessionContent == null &&
                                          _vm.nationCode != "_US"
                                        ? _c("a", {
                                            staticClass: "icon-wish-shadow",
                                            attrs: { tabindex: "0" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleLike(
                                                  item,
                                                  "HFLOR"
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "popinfo" }, [
                                    _c("ul", [
                                      _c("li", { staticClass: "tt-sub" }, [
                                        _vm._v(_vm._s(item.content.title)),
                                      ]),
                                      _c("li", { staticClass: "ctt ctt-t4" }, [
                                        _vm._v(_vm._s(item.attribute.code)),
                                      ]),
                                      _c("li", { staticClass: "txt-01" }, [
                                        _vm._v(_vm._s(item.parents[0].name)),
                                      ]),
                                      _c("li", { staticClass: "txt-01" }, [
                                        _vm._v(_vm._s(item.parents[1].name)),
                                      ]),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-remove02",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCompare(
                                              item.content.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("PF.button.remove"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tbl-box type02" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "PF.popup.characteristics_technical_performance"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._m(4, true),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.dimension"))
                                          ),
                                        ]),
                                      ]),
                                      item.attribute.dimensionText != undefined
                                        ? _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.attribute.dimensionText
                                                )
                                              ),
                                            ]),
                                          ])
                                        : _c("td", [_vm._v(_vm._s())]),
                                      _vm._m(5, true),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(item.parents[0].name)),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PD.text.total_thickness")
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        item.attribute.thickWearLayer !=
                                        undefined
                                          ? _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .getThick(
                                                        item.attribute
                                                          .thickWearLayer
                                                      )
                                                      .join(", ")
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.attribute.thickText.join(
                                                    ", "
                                                  )
                                                )
                                              ),
                                            ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.wear_layer"))
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        item.attribute.thickWearLayer !=
                                        undefined
                                          ? _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .getWearLayer(
                                                        item.attribute
                                                          .thickWearLayer
                                                      )
                                                      .join(", ")
                                                  )
                                              ),
                                            ])
                                          : _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.attribute.wearLayerText
                                                )
                                              ),
                                            ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.pattern"))
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        item.attribute.patternText != null
                                          ? _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.attribute.patternText.join(
                                                    ", "
                                                  )
                                                )
                                              ),
                                            ])
                                          : _c("td", [_vm._v(_vm._s())]),
                                      ]),
                                      _vm._m(6, true),
                                      _c("tr", [
                                        item.attribute.pack_pcsText != null &&
                                        item.attribute.pack_pcsText != null
                                          ? _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.attribute
                                                      .pack_pcsText +
                                                      "pcs / Box [" +
                                                      item.attribute
                                                        .pack_sqmText +
                                                      " sq.m]"
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("td", [_vm._v(_vm._s())]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ])
                            }),
                            _vm.compareList.length < 4
                              ? _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "btn-add hf-btn-add" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            onclick: "LayerPopup.close();",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("PF.button.add_more")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "btn-download" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn dwn load",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.makePDF()
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("PF.button.download"))),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(_vm._s(_vm.$t("PCD.button.close")))]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
              staticStyle: { display: "none" },
              attrs: { id: "layer-search-box" },
            },
            [
              _c("div", { staticClass: "popup" }, [
                _c("div", { staticClass: "scr-y" }, [
                  _c("div", { staticClass: "drop-list" }, [
                    _c("p", { staticClass: "tt-2" }, [
                      _vm._v(_vm._s(_vm.$t("PF.button.filter_by"))),
                    ]),
                    _c("button", {
                      staticClass: "filter-clos",
                      on: {
                        click: function ($event) {
                          return _vm.closeSearchPopup()
                        },
                      },
                    }),
                    _c("ul", [
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", { staticClass: "tt-sub" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.hflor"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PD.button.list_view")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type01 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c("ul", [
                              _c("li", [
                                _c("label", { staticClass: "inp-rdo" }, [
                                  _c("input", {
                                    attrs: { type: "radio", name: "rdo01" },
                                    domProps: {
                                      checked:
                                        _vm.categoryCode ===
                                        "FLOOR_LUX" + _vm.nationCode,
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("PF.button.lux"))),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", { staticClass: "tt-sub" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-collection",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PCD.text.collection")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.leftCltList, function (clt) {
                                return _c("li", [
                                  _c("label", { staticClass: "inp-chk" }, [
                                    _c("input", {
                                      staticClass: "Collection",
                                      attrs: {
                                        type: "checkbox",
                                        id: clt.category.code,
                                      },
                                      on: {
                                        change: function ($event) {
                                          ;[
                                            _vm.fnCateSearch(
                                              clt.category.name,
                                              clt.category.id,
                                              "",
                                              clt.category.code
                                            ),
                                          ]
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(clt.category.name)),
                                    ]),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm.leftPattns.length > 0
                        ? _c("li", { staticClass: "on" }, [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", { staticClass: "tt-sub" }, [
                                _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-patterns",
                                  attrs: { type: "button" },
                                },
                                [_vm._v(_vm._s(_vm.$t("PF.text.patterns")))]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk type02" },
                                  _vm._l(_vm.leftPattns, function (pattn) {
                                    return _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "inp-chk chk-thumb" },
                                        [
                                          _c("input", {
                                            staticClass: "pattern",
                                            attrs: {
                                              type: "checkbox",
                                              id:
                                                _vm.cateCode +
                                                "_pattern_" +
                                                pattn.id.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                ;[
                                                  _vm.fnSearch(
                                                    "pattern",
                                                    pattn.text,
                                                    pattn.id.value,
                                                    "Patterns",
                                                    _vm.cateCode
                                                  ),
                                                ]
                                              },
                                            },
                                          }),
                                          _vm._v("> "),
                                          _c("span", [
                                            _c("i", {
                                              style:
                                                "background-image : url(images/products/" +
                                                _vm.cateCode +
                                                "_" +
                                                pattn.id.value +
                                                ".jpg)",
                                            }),
                                            _vm._v(_vm._s(pattn.text)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", { staticClass: "tt-sub" }, [
                            _vm._v(_vm._s(_vm.$t("PF.text.new"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-new",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PF.text.new")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c("ul", [
                              _c("li", [
                                _c("label", { staticClass: "inp-chk type03" }, [
                                  _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      id: _vm.cateCode + "_isNew_true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        ;[
                                          _vm.fnSearch(
                                            "isNew",
                                            "NEW COLORS",
                                            "true",
                                            "New",
                                            _vm.cateCode
                                          ),
                                        ]
                                      },
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PF.text.new_colors"))
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _vm._m(7),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk" },
                              _vm._l(_vm.leftInstall, function (install) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "Install",
                                    attrs: { "data-chk": install.id.value },
                                  },
                                  [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        staticClass: "ins_Method",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            _vm.cateCode +
                                            "_ins_Method_" +
                                            install.id.value,
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "ins_Method",
                                                install.text,
                                                install.id.value,
                                                "Installation Method",
                                                _vm.cateCode
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(install.text)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", { staticClass: "tt-sub" }, [
                            _vm._v(_vm._s(_vm.$t("PF.text.thickness"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-thickness",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PF.text.thickness")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk" },
                              _vm._l(_vm.leftThick, function (thick) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "Thickness",
                                    attrs: { "data-chk": thick.id.value },
                                  },
                                  [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        staticClass: "thick",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            _vm.cateCode +
                                            "_thick_" +
                                            thick.id.value,
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "thick",
                                                thick.text,
                                                thick.id.value,
                                                "Thickness",
                                                _vm.cateCode
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [_vm._v(_vm._s(thick.text))]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm.leftWear.length != 0
                        ? _c("li", { staticClass: "on" }, [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", { staticClass: "tt-sub" }, [
                                _vm._v(_vm._s(_vm.$t("PF.text.wear_layer"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-wareLayer",
                                  attrs: { type: "button" },
                                },
                                [_vm._v(_vm._s(_vm.$t("PF.text.wear_layer")))]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(_vm.leftWear, function (wear) {
                                    return _c(
                                      "li",
                                      {
                                        staticClass: "WearLayer",
                                        attrs: { "data-chk": wear.id.value },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "wearLayer",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  _vm.cateCode +
                                                  "_wearLayer_" +
                                                  wear.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "wearLayer",
                                                      wear.text,
                                                      wear.id.value,
                                                      "WearLayer",
                                                      _vm.cateCode
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(wear.text)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("li", { staticClass: "on" }, [
                        _vm._m(8),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk" },
                              _vm._l(_vm.leftFormat, function (format) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "Format",
                                    attrs: { "data-chk": format.id.value },
                                  },
                                  [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        staticClass: "format",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            _vm.cateCode +
                                            "_format_" +
                                            format.id.value,
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "format",
                                                format.text,
                                                format.id.value,
                                                "Format",
                                                _vm.cateCode
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [_vm._v(_vm._s(format.text))]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", { staticClass: "tt-sub" }, [
                            _vm._v(_vm._s(_vm.$t("PF.text.dimension"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-dimension",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PF.text.dimension")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk" },
                              _vm._l(_vm.leftDimen, function (dimen) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "Dimension",
                                    attrs: { "data-chk": dimen.id.value },
                                  },
                                  [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        staticClass: "dimension",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            _vm.cateCode +
                                            "_dimension_" +
                                            dimen.id.value,
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "dimension",
                                                dimen.text,
                                                dimen.id.value,
                                                "Dimension",
                                                _vm.cateCode
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [_vm._v(_vm._s(dimen.text))]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _vm._m(9),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk" },
                              _vm._l(_vm.leftPerform, function (perform) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "Performance",
                                    attrs: { "data-chk": perform.id.value },
                                  },
                                  [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        staticClass: "performance",
                                        attrs: {
                                          type: "checkbox",
                                          id:
                                            _vm.cateCode +
                                            "_performance_" +
                                            perform.id.value,
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "performance",
                                                perform.text,
                                                perform.id.value,
                                                "Performance",
                                                _vm.cateCode
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(perform.text)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("span", { staticClass: "tt-sub" }, [_vm._v("Installation Method")]),
      _c(
        "button",
        { staticClass: "btn-view btn-install", attrs: { type: "button" } },
        [_vm._v("Installation Method")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("span", { staticClass: "tt-sub" }, [_vm._v("Format")]),
      _c(
        "button",
        { staticClass: "btn-view btn-format", attrs: { type: "button" } },
        [_vm._v("Format")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("span", { staticClass: "tt-sub" }, [_vm._v("Performance")]),
      _c(
        "button",
        { staticClass: "btn-view btn-performance", attrs: { type: "button" } },
        [_vm._v("Performance")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Installation Method")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Packaging")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("span", { staticClass: "tt-sub" }, [_vm._v("Installation Method")]),
      _c(
        "button",
        { staticClass: "btn-view btn-install", attrs: { type: "button" } },
        [_vm._v("Installation Method")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("span", { staticClass: "tt-sub" }, [_vm._v("Format")]),
      _c(
        "button",
        { staticClass: "btn-view btn-format", attrs: { type: "button" } },
        [_vm._v("Format")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("span", { staticClass: "tt-sub" }, [_vm._v("Performance")]),
      _c(
        "button",
        { staticClass: "btn-view btn-performance", attrs: { type: "button" } },
        [_vm._v("Performance")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }