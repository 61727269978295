<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- S : complete popup-->
    <div class="layer-view layer-result popup-v2">
      <div class="popup pop-close">
        <div class="scr-y">
          <p class="popup-title">Warranty Claim Registration</p>
          <div class="popup-body">
            <div class="frow">
              <div class="fcol tac">
                <i class="pcon pcon-check"></i>
                <strong class="err-title">Warranty Claim Registration Complete!</strong>
                <p class="msg">Installation Date: {{data.installMonth}}/{{data.installDay}}/{{data.installYear}}</p>
                <p class="msg">Registration Date: {{$moment().format('MM/DD/YYYY')}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- close -->
        <button type="button" class="btn-close" onClick="tmpClose('warranty')">close</button>
      </div>
    </div>
    <!-- E : complete popup-->

    <div class="content cntu-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("warranty.text.home") }}</li>
          <li>{{ $t("contactus.text.contactus") }}</li>
          <li v-if="nationCode !== '_GL' && nationCode !== '_US'">{{ $t("warranty.text.support") }}</li>
          <li>{{ $t("warranty.text.warranty") }} Claim</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- US container (Warranty) -->
      <div class="container cntu-box wa-box us-container-warranty">
        <div class="w-con-02">
          <div class="inner">
            <div class="sub-visual-section">
              <h1 class="tt-1 visual-title tac">{{ $t("warranty.text.warranty") }} Claim <span class="sr-only"> - {{ prdLabel }}</span></h1>
              <h2 class="sr-only">LX Hausys Warranty {{ prdLabel }}</h2>
              <div class="ctt ctt-t2 tac visual-desc">
                <span class="ctt ctt-t2 pc-br">{{ $t("warranty.text.mainTxt1") }}</span>
                <span class="ctt ctt-t2 pc-br">{{ $t("warranty.text.mainTxt2") }}</span>
              </div>
            </div>
          </div>

          <div class="care-box">
            <!--S : inner-->
            <div class="inner">
              <!-- Warranty to Register 레지스터-->
              <!--            <div class="tbl-input-box mt-140 wtr-area" v-show="(nationCode != '_GL') && (selectedContentNm == 'Viatera') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS') || warrantyToRegister === true">-->
              <div class="tbl-input-box mt-80 wtr-area">
                <form id="warrantyClaim" @submit.prevent="submitForm" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
                    <!--  these lines if you wish to test in debug mode.                          -->
<!--                    <input type="hidden" name="debug" value=1>-->
                    <input type=hidden name="orgid" value="00D410000005ywy"/>
                    <input type=hidden name="retURL" value="https://www.lxhausys.com/us/support/warranty-claim"/>
                    <input type=hidden id="00N2M00000fYBuu" name="00N2M00000fYBuu" v-model="data.color"/>
                    <input type=hidden id="00N2M00000fYBuw" name="00N2M00000fYBuw" v-model="data.product"/>
                    <input type=hidden id="00N2M00000fYBuj" name="00N2M00000fYBuj" v-model="data.firstNm"/>
                    <input type=hidden id="00N2M00000fYBun" name="00N2M00000fYBun" v-model="data.lastNm"/>
                    <input type=hidden id="00N2M00000fYBuX" name="00N2M00000fYBuX" v-model="data.countryCd"/>
                    <input type=hidden id="00N2M00000fYBv5" name="00N2M00000fYBv5" v-model="data.stateCd"/>
                    <input type=hidden id="00N2M00000fYBv6" name="00N2M00000fYBv6" v-model="data.addr1"/>
                    <input type=hidden id="00N2M00000fYBur" name="00N2M00000fYBur" v-model="data.zipCd"/>
                    <input type=hidden id="00N2M00000fYBut" name="00N2M00000fYBut" v-model="data.phone"/>
                    <input type=hidden id="00N2M00000fYBup" name="00N2M00000fYBup" v-model="data.mobile"/>
                    <input type=hidden id="00N2M00000fYBuc" name="00N2M00000fYBuc" v-model="data.email"/>
                    <input type=hidden id="00N2M00000fYBuR" name="00N2M00000fYBuR" v-model="data.city"/>
                    <input type=hidden id="00N2M00000fYBuh" name="00N2M00000fYBuh" v-model="data.fabricator"/>
                    <input type=hidden id="00N2M00000fYBue" name="00N2M00000fYBue" v-model="data.fabricatorContactNm"/>
                    <input type=hidden id="00N2M00000fYBug" name="00N2M00000fYBug" v-model="data.fabricatorPhone"/>
                    <input type=hidden id="00N2M00000fYBuf" name="00N2M00000fYBuf" v-model="data.fabricatorEmail"/>
                    <input type=hidden id="00N2M00000fYBuo" name="00N2M00000fYBuo" v-model="data.location"/>
                    <input type=hidden id="00N2M00000fYBv2" name="00N2M00000fYBv2" v-model="data.retailer"/>
                    <input type=hidden id="00N2M00000fYBuz" name="00N2M00000fYBuz" v-model="data.retailerContactNm"/>
                    <input type=hidden id="00N2M00000fYBv1" name="00N2M00000fYBv1" v-model="data.retailerPhone"/>
                    <input type=hidden id="00N2M00000fYBv0" name="00N2M00000fYBv0" v-model="data.retailerEmail"/>
                    <input type=hidden id="00N2M00000fYBul" name="00N2M00000fYBul" v-model="data.initialCompliant"/>
                    <input type=hidden id="00N2M00000fYBum" name="00N2M00000fYBum" v-model="data.installMonth + '/' + data.installDay + '/' + data.installYear"/>
                    <input type=hidden id="00N2M00000fYBuU" name="00N2M00000fYBuU" v-model="data.colorMatchAvailable"/>
                    <input type=hidden id="00N2M00000fYBv4" name="00N2M00000fYBv4" v-model="data.sinkType"/>
                    <input type=hidden id="00N2M00000fYBv3" name="00N2M00000fYBv3" v-model="data.sinkColor"/>
                    <input type=hidden id="00N2M00000fYBv8" name="00N2M00000fYBv8" v-model="data.sinkThick"/>
                    <input type=hidden id="00N2M00000fYmpx" name="00N2M00000fYmpx" v-model="data.other"/>
                  <fieldset>
                    <legend>Warranty Claim Registration</legend>

                    <div class="reg-tbl">
                      <p class="tt-2 mb-40">{{ $t("warranty.text.personal_info") }}</p>

                      <div class="col-02">
                        <div class="tbl-01">
                          <div class="tbl-input">
                            <table>
                              <caption>{{ $t("warranty.text.personal_info_form") }}</caption>
                              <!-- <colgroup>
                                <col style="width:185px">
                                <col style="width:auto">
                              </colgroup> -->
                              <tbody>
                              <tr>
                                <th scope="row"><span class="must">{{ $t("warranty.text.country") }}</span></th>
                                <td class="cell-noti">
                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{countryTxt}}</span></button>
                                    <div class="sel-list">
                                      <ul class="box">
                                        <li v-for="(cnt, idx) in countryList" :key="idx">
                                          <button type="button" @click="clickSelectBox(cnt.countryNm== 'CANADA'?'Canada':'United States', cnt.countryCd, 'country')">{{cnt.countryNm == 'CANADA'?'Canada':'United States'}}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p v-if="data.countryCd == '' && submitChk == 'Y'">{{ $t("warranty.text.select_country") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp01">{{ $t("warranty.text.first_name") }}</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="255" class="inp-text" id="inp01" v-model="data.firstNm" :placeholder="$t('warranty.text.input_text')" />
                                  <p v-if="data.firstNm == '' && submitChk == 'Y'">{{ $t("warranty.text.Enter_First_Name") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp02">{{ $t("warranty.text.last_name") }}</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="255" class="inp-text" id="inp02" v-model="data.lastNm" :placeholder="$t('warranty.text.input_text')" />
                                  <p v-if="data.lastNm == '' && submitChk == 'Y'">{{ $t("warranty.text.Enter_Last_Name") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp03">Street Address</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="255" class="inp-text" id="inp03" v-model="data.addr1" :placeholder="$t('warranty.text.input_text')" />
                                  <p v-if="data.addr1 == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_address") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp08">{{ $t("warranty.text.city") }}</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="100" class="inp-text" id="inp18" v-model="data.city" :placeholder="$t('warranty.text.input_text')" />
                                  <p v-if="data.city == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_city") }}</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="tbl-02">
                          <div class="tbl-input">
                            <table>
                              <caption>{{ $t("warranty.text.personal_info_form") }}</caption>
                              <!-- <colgroup>
                                <col style="width:185px">
                                <col style="width:auto">
                              </colgroup> -->
                              <tbody>
                              <tr>
                                <th scope="row"><span class="must">State/Province</span></th>
                                <td class="cell-noti">
                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{ stateTxt }}</span></button>
                                    <div class="sel-list">
                                      <ul class="box">
                                        <li v-for="state in stateList">
                                          <button type="button" @click="clickSelectBox(state.stateNm, state.id.stateCd, 'state')">{{ state.stateNm }}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p v-if="data.stateCd == '' && submitChk == 'Y'">{{ $t("warranty.text.select_state") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp07">{{ $t("warranty.text.zip_code") }}</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="7" class="inp-text" id="inp07" v-model="data.zipCd" :placeholder="$t('warranty.text.input_text')" />
                                  <p v-if="data.zipCd == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_zip_code") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp05">Primary Phone</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="40" class="inp-text" id="inp05" v-model="data.phone" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');" @change="checkValidAlert('phone',data.phone)"/>
                                  <p v-if="data.phone == '' && submitChk == 'Y' || !checkValid('phone',data.phone)">{{ $t("warranty.text.enter_telephone") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label for="inp05">Mobile</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="40" class="inp-text" id="inp05" v-model="data.mobile" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');" @change="checkValidAlert('phone',data.mobile)"/>
                                  <p v-if="data.mobile != '' && !checkValid('phone',data.mobile)">{{ $t("warranty.text.enter_telephone") }}</p>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row"><label class="must" for="inp06">{{ $t("warranty.text.email") }}</label></th>
                                <td class="cell-noti">
                                  <input type="text" maxlength="80" class="inp-text" id="inp06" v-model="data.email" :placeholder="$t('warranty.text.input_text')" @change="checkValidAlert('email',data.email)"/>
                                  <p v-if="(data.email == '' && submitChk == 'Y') || !checkValid('email',data.email)">Enter Email Address – This is how you will be contacted. Claim cannot process without it.</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                                    <!--                  <div class="tbl t-01">-->
                  <!--                    <p class="tt-2 mb-40">{{ $t("warranty.text.personal_info") }}</p>-->
                  <!--                    <div class="col-02">-->
                  <!--                      <div class="tbl-01">-->
                  <!--                        <div class="tbl-input">-->
                  <!--                          <table>-->
                  <!--                            <caption>{{ $t("warranty.text.personal_info_form") }}</caption>-->
                  <!--                            <colgroup>-->
                  <!--                              <col style="width:160px" class="p-ui">-->
                  <!--                              <col style="width:30%" class="m-ui">-->
                  <!--                              <col style="width:auto">-->
                  <!--                            </colgroup>-->
                  <!--                            <tbody>-->

                  <!--                            </tbody>-->
                  <!--                          </table>-->
                  <!--                        </div>-->
                  <!--                      </div>-->

                  <!--                      <div class="tbl-02">-->
                  <!--                        <div class="tbl-input type03">-->
                  <!--                          <table>-->
                  <!--                            <caption>{{ $t("warranty.text.personal_info_form") }}</caption>-->
                  <!--                            <colgroup>-->
                  <!--                              <col style="width:130px">-->
                  <!--                              <col style="width:auto">-->
                  <!--                            </colgroup>-->
                  <!--                            <tbody>-->

                  <!--                            </tbody>-->
                  <!--                          </table>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div class="tbl">
                    <p class="tt-2 mb-40">Fabricator/Retailer Information</p>

                    <div class="col-02">
                      <div class="tbl-01">
                        <div class="tbl-input">
                          <table>
                            <caption>Fabricator/Retailer Information form</caption>
                            <!-- <colgroup>
                              <col style="width:220px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must" for="inp11">Installer</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="255" class="inp-text" id="inp11" v-model="data.fabricator" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.fabricator == '' && submitChk == 'Y'">Enter Fabricator</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp11">Fabricator Contact Name</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="255" class="inp-text" id="inp11" v-model="data.fabricatorContactNm" :placeholder="$t('warranty.text.input_text')" />
                                <!--                                <p v-if="data.fabricatorContactNm == '' && submitChk == 'Y'">Enter Fabricator Contact Name</p>-->
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp12">Fabricator Phone</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="40" class="inp-text" id="inp12" v-model="data.fabricatorPhone" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');" @change="checkValidAlert('phone',data.fabricatorPhone)"/>
                                <p v-if="data.fabricatorPhone != '' && !checkValid('phone',data.fabricatorPhone)">Enter Fabricator Phone</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp06">Fabricator Email</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="80" class="inp-text" id="inp06" v-model="data.fabricatorEmail" :placeholder="$t('warranty.text.input_text')" @change="checkValidAlert('email',data.fabricatorEmail)"/>
                                <p v-if="data.fabricatorEmail != '' && !checkValid('email',data.fabricatorEmail)">Enter Fabricator Email</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp11">Location</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="255"  class="inp-text" id="inp11" v-model="data.location" :placeholder="$t('warranty.text.input_text')" />
                                <!--                                <p v-if="data.location == '' && submitChk == 'Y'">Enter Location</p>-->
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="tbl-02">
                        <div class="tbl-input type03">
                          <table>
                            <caption>Fabricator/Retailer Information</caption>
                            <!-- <colgroup>
                              <col style="width:260px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must" for="inp08">Retailer purchased from</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="255" class="inp-text" id="inp08" v-model="data.retailer" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.retailer == '' && submitChk == 'Y'">Enter Retailer</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp09">Retailer Contact Name</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="255" class="inp-text" id="inp09" v-model="data.retailerContactNm" :placeholder="$t('warranty.text.input_text')" />
                                <!--                                <p v-if="data.retailerContactNm == '' && submitChk == 'Y'">Enter Retailer Contact Name</p>-->
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp10">Retailer Phone</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="40" class="inp-text" id="inp10" v-model="data.retailerPhone" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');" @change="checkValidAlert('phone',data.retailerPhone)"/>
                                <p v-if="data.retailerPhone != '' && !checkValid('phone',data.retailerPhone)">Enter Retailer Phone</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp06">Retailer Email</label></th>
                              <td class="cell-noti">
                                <input type="text" maxlength="80" class="inp-text" id="inp06" v-model="data.retailerEmail" :placeholder="$t('warranty.text.input_text')" @change="checkValidAlert('email',data.retailerEmail)"/>
                                <p v-if="data.retailerEmail != '' && !checkValid('email',data.retailerEmail)">Enter Retailer Email</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="ta-box">-->
                    <!--                      <textarea :placeholder="$t('warranty.text.comments')" v-model="data.memo"></textarea>-->
                    <!--                    </div>-->
                  </div>

                  <div class="tbl product_info_wrap">
                    <p class="tt-2 mb-40">{{ $t("warranty.text.product_info") }}</p>

                    <div class="col-02">
                      <div class="tbl-01">
                        <div class="tbl-input">
                          <table>
                            <caption>{{ $t("warranty.text.product_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:320px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr class="warranty_tbl_tr">
                              <td>
                                <tr>
                                  <th scope="row"><span class="must">{{ $t("warranty.text.date_of_installation") }}</span></th>
                                  <td class="cell-noti">
                                    <div class="sel-day">
                                      <div class="sel-box type02">
                                        <button type="button" class="btn-sel"><span>{{installMonthTxt}}</span></button>
                                        <div class="sel-list">
                                          <ul class="box">
                                            <li v-for="(mon, idx) in installMonthList" :key="idx">
                                              <button type="button" @click="clickSelectBox(mon,'', 'month')">{{mon}}</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="sel-box type02">
                                        <button type="button" class="btn-sel"><span>{{installDayTxt}}</span></button>
                                        <div class="sel-list">
                                          <ul class="box">
                                            <li v-for="(day, idx) in installDayList" :key="idx">
                                              <button type="button" @click="clickSelectBox(day,'', 'day')">{{day}}</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="sel-box type02">
                                        <button type="button" class="btn-sel"><span>{{installYearTxt}}</span></button>
                                        <div class="sel-list">
                                          <ul class="box" >
                                            <li v-for="(year, idx) in installYearList" :key="idx">
                                              <button type="button" @click="clickSelectBox(year,'', 'year')">{{year}}</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <p v-if="data.installMonth == '' || data.installDay == '' || data.installYear == '' && submitChk == 'Y'">{{ $t("warranty.text.select_date") }}</p>
                                  </td>
                                </tr>
                              </td>
                            </tr>
                            <tr class="warranty_tbl_tr">
                              <td>
                                <tr>
                                  <th scope="row"><span class="must">{{ $t("warranty.text.categoryTxt") }}</span></th>
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel"><span>{{productTxt}}</span></button>
                                      <div class="sel-list">
                                        <ul class="box">
                                          <li v-for="(prd, idx) in productList" :key="idx">
                                            <button type="button" @click="clickSelectBox(prd.text,prd.id.value, 'product')">{{prd.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="data.product == '' && submitChk == 'Y'">{{ $t("warranty.text.select_registering") }}</p>
                                  </td>
                                </tr>
                              </td>
                              <td>
                                <tr>
                                  <th scope="row" v-if="!sinkRequired"><label class="must">{{ $t("warranty.text.color") }}</label></th>
                                  <th scope="row" v-else>{{ $t("warranty.text.color") }}</th>
                                  <td class="cell-noti">
                                    <input type="text" class="inp-text" id="color" v-if="!sinkRequired" :disabled="sinkRequired" v-model="data.color" :placeholder="$t('warranty.text.input_text')"/>
                                    <p v-if="!sinkRequired && data.color == '' && submitChk == 'Y'">Enter Product Color</p>
                                  </td>
                                </tr>
                              </td>
                            </tr>
                            <tr class="warranty_tbl_tr warranty_tbl_tr_big">
                              <td colspan="2">
                                <tr>
                                  <th scope="row"><label class="must" for="inp11">Initial Compliant</label></th>
                                  <td class="cell-noti">
                                    <!--                                <input type="text" class="inp-text" id="inp11" v-model="data.initialCompliant" :placeholder="$t('warranty.text.input_text')" />-->
                                    <textarea :placeholder="$t('warranty.text.input_text')" id="inp11" v-model="data.initialCompliant"></textarea>
                                    <p v-if="data.initialCompliant == '' && submitChk == 'Y'">Enter Initial Compliant</p>
                                  </td>
                                </tr>
                              </td>
                            </tr>
                            <tr class="warranty_tbl_tr">
                              <td v-if="!sinkRequired && !otherThick">
                                <tr>
                                  <th scope="row"><span class="must">Thickness</span></th>
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel" :disabled="sinkRequired || otherThick" :style="[sinkRequired || otherThick ? {'background-color': '#E4E4E4'} : {'background-color': 'transparent'}]">
                                        <span :style="[sinkRequired || otherThick ? {'color': '#a0a0a0'} : {'color': 'initial'}]">
                                          {{sinkThickTxt}}
                                        </span>
                                      </button>
                                      <div class="sel-list">
                                        <ul class="box" v-if="!sinkRequired && !otherThick && showHimacsBox">
                                          <li v-for="(thick, idx) in hmThickSelBox" :key="idx">
                                            <button type="button" @click="clickSelectBox(thick.text,thick.id.value,'sinkThick')">{{thick.text}}</button>
                                          </li>
                                        </ul>
                                        <ul class="box" v-else-if="!sinkRequired && !otherThick && showViateraBox">
                                          <li v-for="(thick, idx) in vtThickSelBox" :key="idx">
                                            <button type="button" @click="clickSelectBox(thick.text,thick.id.value, 'sinkThick')">{{thick.text}}</button>
                                          </li>
                                        </ul>
                                        <ul class="box" v-else-if="!sinkRequired && !otherThick && showTeracantoBox">
                                          <li v-for="(thick, idx) in tcThickSelBox" :key="idx">
                                            <button type="button" @click="clickSelectBox(thick.text,thick.id.value, 'sinkThick')">{{thick.text}}</button>
                                          </li>
                                        </ul>
                                        <ul class="box" v-else>
                                          <li v-for="(thick, idx) in sinkThickList" :key="idx">
                                            <button type="button" @click="clickSelectBox(thick.text,thick.id.value, 'sinkThick')">{{thick.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="!sinkRequired && !otherThick &&  data.sinkThick == '' && submitChk == 'Y'">Enter Thickness</p>
                                  </td>
                                </tr>
                              </td>
                              <td v-if="sinkRequired">
                                <tr>
                                  <th scope="row"><span class="must">Sink Type</span></th>
<!--                                  <th scope="row" v-else>Sink Type</th>-->
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel"><span>{{sinkTypeTxt}}</span></button>
                                      <div class="sel-list">
                                        <ul class="box">
                                          <li v-for="(sinktype, idx) in sinkTypeList" :key="idx">
                                            <button type="button" @click="clickSelectBox(sinktype.text,sinktype.id.value, 'sinkType')">{{sinktype.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="sinkRequired && data.sinkType == '' && submitChk == 'Y'">Enter Sink Type</p>
                                  </td>
                                </tr>
                              </td>

                              <td v-if="showHimacsBox">
                                <tr>
                                  <th scope="row"><label class="must" for="inp09">Color Match Available<br/>(HIMACS Only)</label></th>
<!--                                  <th scope="row" v-else>Color Match Available<br/>(HIMACS Only)</th>-->
                                  <td class="cell-noti">
                                    <div class="sel-box type02" v-if="showHimacsBox">
                                      <button type="button" class="btn-sel"><span>{{colorMatchTxt}}</span></button>
                                      <div class="sel-list">
                                        <ul class="box">
                                          <li v-for="(match, idx) in colorMatchList" :key="idx">
                                            <button type="button" @click="clickSelectBox(match.text,match.id.value, 'colorMatch')">{{match.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
<!--                                    <div v-else>
                                      <input type="text" class="inp-text" id="inp09" v-model="data.colorMatchAvailable" :placeholder="$t('warranty.text.input_text')" />
                                    </div>-->
                                    <p v-if="showHimacsBox && data.colorMatchAvailable == '' && submitChk == 'Y'">Enter Color Match Available</p>
                                  </td>
                                </tr>
                              </td>
                              <td v-if="sinkRequired && data.sinkType != 'Other'">
                                <tr>
                                  <th scope="row"><span class="must">Sink Color</span></th>
<!--                                  <th scope="row" v-else>Sink Color</th>-->
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel"><span>{{sinkColorTxt}}</span></button>
                                      <div class="sel-list">
                                        <ul class="box">
                                          <li v-for="(sink, idx) in sinkColorList" :key="idx">
                                            <button type="button" @click="clickSelectBox(sink.text,sink.id.value, 'sinkColor')">{{sink.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="sinkRequired && data.sinkColor == '' && submitChk == 'Y' && data.sinkType != 'Other'">Enter Sink Color</p>
                                  </td>
                                </tr>
                              </td>
                              <tr class="warranty_tbl_tr warranty_tbl_tr_big" v-if="data.sinkType == 'Other'">
                                <td colspan="2">
                                  <tr>
                                    <th scope="row" v-if="data.product == 'Sink' && data.sinkType == 'Other'"><label class="must" for="inp11">Other</label></th>
<!--                                    <th scope="row" v-else>Other</th>-->
                                    <td class="cell-noti">
                                      <textarea maxlength="255" :placeholder="$t('warranty.text.input_text')" id="inp11" v-model="data.other"></textarea>
                                      <p v-if="data.product == 'Sink' && data.sinkType == 'Other' && data.other == ''">Enter Other</p>
                                    </td>
                                  </tr>
                                </td>
                              </tr>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- <div class="tbl-02">
                        <div class="tbl-input type03">
                          <table>
                            <caption>{{ $t("warranty.text.product_info_form") }}</caption>
                            <colgroup>
                              <col style="width:410px">
                              <col style="width:auto">
                            </colgroup>
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must">{{ $t("warranty.text.color") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="color" v-model="data.color" :placeholder="$t('warranty.text.input_text')"/>
                                <p v-if="data.color == '' && submitChk == 'Y'">Enter Product Color</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" v-if="sinkRequired"><span class="must">Sink Type</span></th>
                                <th scope="row" v-else>Sink Type</th>
                                <td class="cell-noti">
                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{sinkTypeTxt}}</span></button>
                                    <div class="sel-list">
                                      <ul class="box">
                                        <li v-for="(sinktype, idx) in sinkTypeList" :key="idx">
                                          <button type="button" @click="clickSelectBox(sinktype.text,sinktype.id.value, 'sinkType')">{{sinktype.text}}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p v-if="sinkRequired && data.sinkType == '' && submitChk == 'Y'">Enter Sink Type</p>
                                </td>
                            </tr>
                            <tr>
                              <th scope="row" v-if="sinkRequired"><span class="must">Sink Color</span></th>
                                <th scope="row" v-else>Sink Color</th>
                                <td class="cell-noti">
                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{sinkColorTxt}}</span></button>
                                    <div class="sel-list">
                                      <ul class="box">
                                        <li v-for="(sink, idx) in sinkColorList" :key="idx">
                                          <button type="button" @click="clickSelectBox(sink.text,sink.id.value, 'sinkColor')">{{sink.text}}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p v-if="sinkRequired && data.sinkColor == '' && submitChk == 'Y'">Enter Sink Color</p>
                                </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="mt-64 ta-c">
                    <button class="btn"><span>{{ $t("warranty.button.submit") }}</span></button>
                  </div>
                </fieldset>
               </form>
              </div>
              <!-- //Warranty to Register -->
            </div>
            <!--E : inner-->
          </div>
        </div>
      </div>
      <!-- //US container (Warranty) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
import ShareComponent from '@/components/Layout/Share'
import ProductService from '@/server/product.service'
import getCountryList from '@/server/us.service'

export default {
  name: 'warranty',
  computed: {
    _ () {
      return _
    }
  },
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    ShareComponent,
    GnbComponent
  },

  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  data () {
    return {
      selectedContentNm: '',
      contentNeeded: '',

      warrantyList: [],
      tabCateList: [],
      vinylCateId: 0,

      pageIndex: 1, /* 현재 페이지 */
      pageSize: 5, /* 게시글 수 */
      prdLabel: '' /* 상품 카테고리 이름(hflor) */,
      pageList: [],
      firstNumber: '',
      lastNumber: '',
      totalPage: '',
      totalCount: '',
      cateCode: '',

      // warranty to register
      submitChk: 'N',
      productList: [],
      countryList: [],
      sinkTypeList: [],
      sinkColorList: [],
      sinkThickList: [],
      colorMatchList: [],

      stateList: [],
      data: {
        orgid: '',
        retURL: '',
        product: '',
        color: '',
        countryCd: '',
        stateCd: '',
        sinkType: '',
        sinkColor: '',
        sinkThick: '',

        installYear: '',
        installMonth: '',
        installDay: '',

        chkMaterials: [],

        firstNm: '',
        lastNm: '',
        addr1: '',
        addr2: '',
        phone: '',
        mobile: '',
        email: '',
        zipCd: '',
        city: '',
        fabricator: '',
        fabricatorContactNm: '',
        fabricatorPhone: '',
        fabricatorEmail: '',
        retailer: '',
        retailerContactNm: '',
        retailerPhone: '',
        retailerEmail: '',
        initialCompliant: '',
        location: '',
        colorMatchAvailable: '',
        other: '', // product sink 및 sink Type에서 other 선택 시에만
        // overallCost: '',
        // describe: '',
        memo: '',
        image: [],
        fileName: 'Upload'
      },

      productTxt: 'Select',
      sinkTypeTxt: 'Select',
      sinkColorTxt: 'Select',
      sinkThickTxt: 'Select',
      countryTxt: 'Select',
      installMonthTxt: 'mm',
      installDayTxt: 'dd',
      installYearTxt: 'yyyy',
      stateTxt: 'Select',
      // 년월일 selectBox
      installYearList: [],
      installMonthList: [],
      installDayList: [],
      hflorVinylCateId: [],
      dicValue: [],
      showHimacsBox: false,
      showViateraBox: false,
      showTeracantoBox: false,
      sinkRequired: false,
      otherThick: false,
      colorMatchTxt: 'Select',
      hmThickSelBox: [],
      vtThickSelBox: [],
      tcThickSelBox: [],

      nationCode: '',
      countryCodeSub: '',
      warrantyToRegister: false,
      seoCheck: false,
      seoName: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)
    if (t.nationCode == '_GL' || t.nationCode == '_US') {
      const dicList = await ProductService.getDictionaryValueList(['WRNTY_CLM_PRD', 'WRNTY_CLM_SINK_TYPE', 'WRNTY_CLM_SINK_CLR', 'WRNTY_CLM_THICK', 'WRNTY_CLM_CMA',
        'WRNTY_CLM_THICK_HM', 'WRNTY_CLM_THICK_VT', 'WRNTY_CLM_THICK_TC'])
      // register
      this.productList = dicList.data.dicList[0].list
      this.sinkTypeList = dicList.data.dicList[1].list
      this.sinkColorList = dicList.data.dicList[2].list
      this.sinkThickList = dicList.data.dicList[3].list
      this.colorMatchList = dicList.data.dicList[4].list
      this.hmThickSelBox = dicList.data.dicList[5].list
      this.vtThickSelBox = dicList.data.dicList[6].list
      this.tcThickSelBox = dicList.data.dicList[7].list

      const countryList = await getCountryList.getCountryList()
      this.countryList = countryList.data
      this.countryList.map(country => country.countryCd === '99' ? this.countryList.splice(this.countryList.indexOf(country)) : null)
      const dt = new Date()
      const com_year = dt.getFullYear()

      // 올해 기준으로 -50년부터 +1년을 보여준다.
      for (let year = (com_year - 15); year <= (com_year + 1); year++) {
        this.installYearList.push(year)
      }

      // 월 뿌려주기(1월부터 12월)
      for (let month = 1; month <= 12; month++) {
        this.installMonthList.push(month)
      }

      // 일 뿌려주기(1일부터 31일)
      for (let day = 1; day <= 31; day++) {
        this.installDayList.push(day)
      }
    }
  },
  async mounted () {
    const _this = this
    activeArrowTab()// 화살표 탭 인터렉션

    _this.retURL = 'https://www.lxhausys.com/us/support/warranty-claim' // return할 페이지

    _this.$nextTick(function () {
      $('.wa-box .btn-box .btn-reg').click(function () {
        var offset = $('.wtr-area').offset().top - $('header').height() - 70
        $('html, body').animate({ scrollTop: offset }, 400)
      })
    })

    _this.$nextTick(function () {
      $('.sns-wrp .sns').each(function (idx, ele) {
        var $this = $(this)
        $(ele).prev().click(function () {
          $this.slideToggle(300)
          $this.closest('tr').siblings().children().find('.sns').hide()
        })
      })
    })
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    imageDel (file) {
      const _this = this
      _this.data.image = _this.data.image.filter((element) => element.name !== file)
    },
    onInputImage () {
      if (this.$refs.submitImage.files.length + this.data.image.length > 10) {
        alert(this.$t('warranty.alert.file1'))
        return false
      } else {
        for (var i = 0; i < this.$refs.submitImage.files.length; i++) {
          if (this.$refs.submitImage.files[i].size > 5000000) {
            alert(this.$t('warranty.alert.file2'))
            return false
          } else {
            this.data.image.push(this.$refs.submitImage.files[i])
          }
        }
      }
    },
    async clickSelectBox (txt, id, type) {
      if (type === 'product') {
        // himacs, sink 선택 시 해당 셀렉박스 노출 및 required 초기화
        if (id === 'himacs') {
          this.data.colorMatchAvailable = ''
          this.showHimacsBox = true
          this.sinkTypeTxt = 'Select'
          this.data.sinkType = ''
          this.sinkThickTxt = 'Select'
          this.data.sinkThick = ''
          this.sinkColorTxt = 'Select'
          this.data.sinkColor = ''
          this.data.other = ''
          this.sinkRequired = false
          this.otherThick = false
          this.showViateraBox = false
          this.showTeracantoBox = false
        } else if (id === 'viatera') {
          this.data.colorMatchAvailable = ''
          this.showViateraBox = true
          this.sinkTypeTxt = 'Select'
          this.data.sinkType = ''
          this.sinkThickTxt = 'Select'
          this.data.sinkThick = ''
          this.sinkColorTxt = 'Select'
          this.data.sinkColor = ''
          this.data.other = ''
          this.sinkRequired = false
          this.otherThick = false
          this.showHimacsBox = false
          this.showTeracantoBox = false
        } else if (id === 'teracanto') {
          this.data.colorMatchAvailable = ''
          this.showTeracantoBox = true
          this.sinkTypeTxt = 'Select'
          this.data.sinkType = ''
          this.sinkThickTxt = 'Select'
          this.data.sinkThick = ''
          this.sinkColorTxt = 'Select'
          this.data.sinkColor = ''
          this.data.other = ''
          this.sinkRequired = false
          this.otherThick = false
          this.showHimacsBox = false
          this.showViateraBox = false
        } else if (id === 'sink') {
          this.sinkRequired = true
          this.colorMatchTxt = 'Select'
          this.data.colorMatchAvailable = ''
          this.sinkThickTxt = 'Select'
          this.data.sinkThick = ''
          this.data.other = ''
          this.otherThick = false
          this.showHimacsBox = false
          this.showViateraBox = false
          this.showTeracantoBox = false
        } else {
          this.otherThick = true
          this.colorMatchTxt = 'Select'
          this.data.colorMatchAvailable = ''
          this.sinkTypeTxt = 'Select'
          this.data.sinkType = ''
          this.sinkThickTxt = 'Select'
          this.data.sinkThick = ''
          this.sinkColorTxt = 'Select'
          this.data.sinkColor = ''
          this.data.other = ''
          this.showHimacsBox = false
          this.showViateraBox = false
          this.showTeracantoBox = false
          this.sinkRequired = false
        }
        //
        this.productTxt = txt
        this.data.product = txt
      } else if (type === 'country') {
        this.countryTxt = txt
        this.data.countryCd = txt
        const stateList = await getCountryList.getStateList(id)
        this.stateList = stateList.data
        this.data.zipCd = ''
        if (id === 'US') {
          $("input:text[id='inp07']").off('keyup')
          $("input:text[id='inp07']").on('keyup', function () {
            $(this).val($(this).val().replace(/[^0-9]/g, ''))
          })
        } else {
          $("input:text[id='inp07']").off('keyup')
          $("input:text[id='inp07']").on('keyup', function () {
            $(this).val($(this).val().replace(/\s/g, ''))
          })
        }
      } else if (type === 'sinkType') {
        this.data.other = ''
        this.sinkColorTxt = 'Select'
        this.data.sinkColor = ''
        this.sinkTypeTxt = txt
        this.data.sinkType = txt
      } else if (type === 'sinkColor') {
        this.sinkColorTxt = txt
        this.data.sinkColor = txt
      } else if (type === 'month') {
        this.installMonthTxt = txt
        this.data.installMonth = txt
      } else if (type === 'day') {
        this.installDayTxt = txt
        this.data.installDay = txt
      } else if (type === 'year') {
        this.installYearTxt = txt
        this.data.installYear = txt
        const nowDate = new Date()
        const nowYear = nowDate.getFullYear()
        if (nowYear - txt >= 15) {
          alert('Please note this claim is outside of our 15-year warranty coverage, but we will review the claim, please verify the dates before submitting.')
        }
      } else if (type === 'state') {
        this.stateTxt = txt
        this.data.stateCd = id
      } else if (type === 'sinkThick') {
        if (txt != null && txt != undefined && txt != '' && this.productTxt == 'HIMACS') {
          this.sinkThickTxt = txt
          this.data.sinkThick = _.split(txt, ' (')[0] // 하이막스만 다르게 표기하길 원함.
        } else {
          this.sinkThickTxt = txt
          this.data.sinkThick = txt
        }
      } else if (type === 'colorMatch') {
        this.colorMatchTxt = txt
        this.data.colorMatchAvailable = txt
      }

      /* select box mobile */
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
    },
    checkValidAlert (type, data) {
      const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (type == 'email') {
        if (!emailRule.test(data)) {
          alert('Please enter a valid e-mail address')
        }
      } else {
        if (data.length < 10) {
          alert('Please enter a valid 10-digit phone number.')
        }
      }
    },
    checkValid (type, data) {
      const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (type == 'email') {
        if (!emailRule.test(data)) {
          return false
        } else {
          return true
        }
      } else {
        if (data.length < 10) {
          return false
        } else {
          return true
        }
      }
    },
    validate () {
      const _this = this
      _this.submitChk = 'Y'
      const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // 이메일 정규식

      if (_this.data.firstNm == '') {
        return false
      }
      if (_this.data.lastNm == '') {
        return false
      }
      if (_this.data.addr1 == '') {
        return false
      }
      if (_this.data.city == '') {
        return false
      }
      if (_this.data.stateCd == '') {
        return false
      }
      if (_this.data.zipCd == '') {
        return false
      }
      if (_this.data.phone == '' || _this.data.phone.length < 10) {
        return false
      }
      if (_this.data.mobile != '' && _this.data.mobile.length < 10) { // 필수는 아니지만 값이 있을 경우 유효성 검사
        return false
      }
      if (_this.data.email == '' || !emailRule.test(_this.data.email)) {
        return false
      }
      if (_this.data.fabricatorEmail != '' && !emailRule.test(_this.data.fabricatorEmail)) { // 필수는 아니지만 값이 있을 경우 유효성 검사
        return false
      }
      if (_this.data.fabricatorPhone != '' && _this.data.fabricatorPhone.length < 10) { // 필수는 아니지만 값이 있을 경우 유효성 검사
        return false
      }
      if (_this.data.retailerEmail != '' && !emailRule.test(_this.data.retailerEmail)) { // 필수는 아니지만 값이 있을 경우 유효성 검사
        return false
      }
      if (_this.data.retailerPhone != '' && _this.data.retailerPhone.length < 10) { // 필수는 아니지만 값이 있을 경우 유효성 검사
        return false
      }
      if (_this.data.countryCd == '') {
        return false
      }
      if (_this.data.fabricator == '') {
        return false
      }
      if (_this.data.retailer == '') {
        return false
      }
      if (_this.data.installMonth == '' || _this.data.installDay == '' || _this.data.installYear == '') {
        return false
      }
      if (_this.data.product == '') {
        return false
      }
      if (!_this.sinkRequired && _this.data.color == '') {
        return false
      }
      if (_this.data.initialCompliant == '') {
        return false
      }
      if (!_this.sinkRequired && !_this.otherThick && _this.data.sinkThick == '') {
        return false
      }
      if (_this.showHimacsBox && _this.data.colorMatchAvailable == '') {
        return false
      }
      if (_this.sinkRequired && _this.data.sinkType == '') {
        return false
      }
      if (_this.sinkRequired && _this.data.sinkColor == '' && _this.data.sinkType != 'Other') {
        return false
      }
      if (_this.data.product == 'Sink' && _this.sinkType == 'Other' && _this.data.other == '') {
        return false
      }
      return true
    },
    async submitForm () {
      const _this = this
      if (_this.validate()) {
        $('#warrantyClaim').submit()
        tmpPopup('layer-result')// 완료 팝업 호출;
      }
    }
  }
}
</script>
