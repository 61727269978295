var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Major Design Performance",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData1.icon,
                  headerText: _vm.cardData1.headerText,
                  pictures: _vm.cardData1.pictures,
                  texts: _vm.cardData1.texts,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData2.icon,
                  headerText: _vm.cardData2.headerText,
                  pictures: _vm.cardData2.pictures,
                  texts: _vm.cardData2.texts,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "主要な設計パフォーマンス",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData1Jp.icon,
                  headerText: _vm.cardData1Jp.headerText,
                  pictures: _vm.cardData1Jp.pictures,
                  texts: _vm.cardData1Jp.texts,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData2Jp.icon,
                  headerText: _vm.cardData2Jp.headerText,
                  pictures: _vm.cardData2Jp.pictures,
                  texts: _vm.cardData2Jp.texts,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Основные характеристики дизайна",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData1Ru.icon,
                  headerText: _vm.cardData1Ru.headerText,
                  pictures: _vm.cardData1Ru.pictures,
                  texts: _vm.cardData1Ru.texts,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData2Ru.icon,
                  headerText: _vm.cardData2Ru.headerText,
                  pictures: _vm.cardData2Ru.pictures,
                  texts: _vm.cardData2Ru.texts,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "主要设计性能", color: "#222", size: "56px" } },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData1Cn.icon,
                  headerText: _vm.cardData1Cn.headerText,
                  pictures: _vm.cardData1Cn.pictures,
                  texts: _vm.cardData1Cn.texts,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("MajorDesignPerformanceCard", {
                attrs: {
                  icon: _vm.cardData2Cn.icon,
                  headerText: _vm.cardData2Cn.headerText,
                  pictures: _vm.cardData2Cn.pictures,
                  texts: _vm.cardData2Cn.texts,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }