<template>
  <div id="wrap">
    <HeaderComponent v-if="!popFlag"></HeaderComponent>
    <GnbComponent v-if="!popFlag"></GnbComponent>

    <!-- content -->
    <div class="content cntu-view">
      <!-- location -->
      <div class="location" v-if="!popFlag">
        <ul>
          <li class="ico-home">{{$t('privacy.text.FF0020_001')}}</li>
          <li>{{$t('privacy.text.FF0020_002')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Private Policy) -->
      <div class="container cntu-box private-view">
        <div class="w-con-02">
          <div class="termsWrap inner privacyWrap">
            <!-- US 일때 privacy 내용 -->
            <div class="privateArea" v-if="$countryCode === '_US'">
              <h1 class="tt-1 visual-title tac">{{$t('privacy.text.FF0020_002')}}</h1>
              <!-- BASIC-106 업데이트 날짜 태그 추가 2022-08-23 -->
              <p class="tt-sub mb-88">Last updated: January 28, 2022</p>
              <div class="t-type">
                <p class="ctt ctt-t4">
                  {{$t('privacy.text.FF0020_003')}}<br>{{$t('privacy.text.FF0020_004')}}
                </p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_005')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_006')}}</p>
              </div>
              <!-- BASIC-106 테이블 추가 -->
              <div class="t-type">
                <p class="ctt ctt-t0">Source of Personal information</p>
                <div class="tableArea tbl-box type09">
                  <table>
                    <colgroup>
                      <col style="width:25%">
                      <col style="width:50%">
                      <col style="width:25%">
                    </colgroup>
                    <thead>
                    <tr>
                      <th scope="row">Category</th>
                      <th scope="row">Examples</th>
                      <th scope="row">Source</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="ctt ctt-t3">Identifiers</td>
                      <td class="ctt ctt-t3">name, home/phone number, e-mail, address, city, country, state, zip code, ID</td>
                      <td class="ctt ctt-t3">Consumer</td>
                    </tr>
                    <tr>
                      <td class="ctt ctt-t3">Protected classifications</td>
                      <td class="ctt ctt-t3">age,gender</td>
                      <td class="ctt ctt-t3">Consumer</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <ul class="list-policy mt-30">
                  <li>{{$t('privacy.text.FF0020_007')}}</li>
                  <li>{{$t('privacy.text.FF0020_008')}}</li>
                  <li>{{$t('privacy.text.FF0020_009')}}</li>
                  <li>{{$t('privacy.text.FF0020_010')}}</li>
                  <li>{{$t('privacy.text.FF0020_011')}}</li>
                  <li>{{$t('privacy.text.FF0020_012')}}</li>
                </ul>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_013')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_014')}}
                  <ul class="list-policy mt-30">
                    <li>{{$t('privacy.text.FF0020_015')}}</li>
                    <li>{{$t('privacy.text.FF0020_016')}}</li>
                    <li>{{$t('privacy.text.FF0020_017')}}</li>
                    <li>{{$t('privacy.text.FF0020_018')}}</li>
                    <li>{{$t('privacy.text.FF0020_019')}}</li>
                    <li>{{$t('privacy.text.FF0020_020')}}</li>
                    <li>{{$t('privacy.text.FF0020_021')}}</li>
                    <li>{{$t('privacy.text.FF0020_022')}}</li>
                    <li>{{$t('privacy.text.FF0020_023')}}</li>
                  </ul>
                </p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_024')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_025')}}
                  <ul class="list-policy mt-30">
                    <li>{{$t('privacy.text.FF0020_026')}}</li>
                    <li>{{$t('privacy.text.FF0020_027')}}</li>
                    <li>{{$t('privacy.text.FF0020_028')}}</li>
                    <li>{{$t('privacy.text.FF0020_029')}}</li>
                  </ul>
                  <span class="d-block mt-30" v-html="$t('privacy.text.FF0020_030')">
                  </span>
                </p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_031')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_032')}}
                  <ul class="list-policy mt-30">
                    <li>{{$t('privacy.text.FF0020_033')}}</li>
                    <li>{{$t('privacy.text.FF0020_034')}}</li>
                    <li>{{$t('privacy.text.FF0020_035')}}</li>
                    <li>{{$t('privacy.text.FF0020_036')}}</li>
                    <li>{{$t('privacy.text.FF0020_037')}}</li>
                  </ul>
                </p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_038')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_039')}}<br><br>
                {{$t('privacy.text.FF0020_040')}}<br><br>
                {{$t('privacy.text.FF0020_041')}}
                </p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_042')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_043')}}
                  <ul class="list-policy mt-30">
                    <li>{{$t('privacy.text.FF0020_044')}}</li>
                    <li>{{$t('privacy.text.FF0020_045')}}</li>
                    <li>{{$t('privacy.text.FF0020_046')}}</li>
                    <li>{{$t('privacy.text.FF0020_047')}}</li>
                  </ul>
                  <br><br>
                </p>
                <p class="ctt ctt-t4" v-html="$t('privacy.text.FF0020_048')"></p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_051')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_052')}}</p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_053')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_054')}}</p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_055')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_056')}}</p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_057')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_058')}}</p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_059')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_060')}}</p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_061')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_062')}}</p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_063')}}</p>
                <p class="ctt ctt-t4" v-html="$t('privacy.text.FF0020_064')"></p>
              </div>
              <div class="t-type">
                <p class="ctt ctt-t0">{{$t('privacy.text.FF0020_065')}}</p>
                <p class="ctt ctt-t4">{{$t('privacy.text.FF0020_066')}}</p>
              </div>
            </div>
            <!-- US 일때 privacy 내용 끝 -->

            <!-- GL 일때 privacy 내용 -->
            <div class="privateArea" v-if="$countryCode === '_GL'">
              <h1 class="tt-1 visual-title tac">Privacy Policy</h1>
              <div class="sel-box">
                <button type="button" class="btn-sel"><span>Versions</span></button>
                <div class="sel-list">
                  <ul class="box">
                    <li><button type="button" @click="goLink('current');">2023. 02.01 ~ </button></li>
                    <li><button type="button" @click="goLink('2022');">2022.01.28 ~ 2023. 01.31</button></li>
                  </ul>
                </div>
              </div>
              <!-- BASIC-227 업데이트 날짜 태그 추가 2023-02-02 -->
              <p class="tt-sub mb-88">Last updated: February 01, 2023</p>
              <p class="ctt ctt-t2 mb-88">LX Hausys, Ltd. (the “Company”, "our") values users’ personal information and complies with laws and regulations including  『PERSONAL INFORMATION PROTECTION ACT』. The Company provides the purpose and method of users’ personal information and the measures taken for personal information protection through Privacy Notice. If this Privacy Notice is revised, we will inform you through the notice on our website (or individual notice).</p>
              <div class="t-type">
                <p class="tt-24">1. Purpose of collection and use of personal information</p>
                <p class="ctt ctt-t2">
                  The Company collects the minimum amount of personal information necessary to provide services.<br>
                  <br>
                  ① Items to be collected and purpose of use of personal information<br>
                  1.Newsletter Subscriptions<br>
                  - Required item : e-mail address, Country, Job, Interested product<br>
                  2.The information which can be created and collected while using the service<br>
                  - Access IP information, cookies, geographic information, records on use of services, access records, etc.<br>
                  3. Add purpose of use related to Eloqua and Google Analytics<br>
                  3-1. Optimized Service<br>
                  - Provides you with convenience via optimization of the product's program in use<br>
                  3-2. Enhanced Compatibility<br>
                  Leads maximized performance of the product via analysis of various user environments.<br>
                  ② Collection method : Directly entered by users through our page
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">2. Retention and use period of personal information</p>
                <p class="ctt ctt-t2">
                The Company disposes of any personal information immediately after the purposes of use are achieved.<br>
                - Newsletter subscription : Until the user cancels the subscription<br>
                <br>
                However, as below, the personal information shall be retained for a specified period of time and proceed to disposal process according to company rules and regulations.<br>
                <br>
                1. According to 『PERSONAL INFORMATION PROTECTION ACT Criteria for technical, administrative protection of personal information』 Article 5<br>
                - Storage of access records : 1 year<br>
                2. According to Article 6 of the『ACT ON THE CONSUMER PROTECTION IN ELECTRONIC COMMERCE, ETC.』 and Article 6 of the『ENFORCEMENT DECREE』 of the same Act<br>
                - Records of indications and advertisements: 6 months<br>
                - Records of withdrawal of a contract or subscription : 5 years<br>
                3. Storage of communication fact confirmation data pursuant to Article 15-2 of the『PROTECTION OF COMMUNICATIONS SECRETS ACT』and Article 41 of the 『ENFORCEMENT DECREE』of the same Act<br>
                - Computer communication, Internet log records, and access tracking data : 3 months<br>
                4. If personal information is preserved without destroying it, the personal information is stored separately from the DB where the existing personal information is stored.<br>
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">3. Procedure and method of disposal of personal information</p>
                <p class="ctt ctt-t2">
                When the retention and use period of personal information expire, the Company will promptly dispose related personal information. The procedure of disposal is as follows:<br><br>
                ① Procedure of disposal<br>
                - When the purpose of personal information that users entered is fulfilled, the information is stored for a certain period of time and disposed of under the cases of the reason specified by law (refer to “2. the retention and use period of personal information”).<br>
                - The above personal informations are not used for other purposes, except as required by law or internal regulations.<br>
                ② Disposal method<br>
                - The personal information stored in electronic file is removed with a technological method that prevents the recovery of deleted files.<br>
                - The personal information printed on paper is disposed by shredder or incineration.
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">4. Entrustment of collected personal information</p>
                <p class="ctt ctt-t2">
                  The Company entrusts and operates personal information processing work to external companies specialized for website management and quality assurance as follows.<br>
                  In order to ensure the safety of personal information protection for entrustment, the company complies personal information related regulations, prohibits providing personal information to third parties, and take responsibility in case of accidents. And these are stipulated at the contract and stored electronically or in writing. If the company changes, the Company will notify the changed company name through the website notice or personal information processing policy.<br>
                  1. System Operation and Maintenance: LG CNS<br>
                  * When using the service that is consigned (provided), it is separately agreed through each individual page.
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">5. Provision of personal information to third parties</p>
                <p class="ctt ctt-t2">
                  The Company uses users’ personal information within the scope specified in “1. Purpose of collection and use of personal information”and does not abuse it in excess of the established range nor provides it to a third party.
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">6. Rights of users and methods to exercise such rights</p>
                <p class="ctt ctt-t2">
                As the subject of the personal information, users may exercise the following rights :<br><br>
                ① Right to have access to personal information : According to 『PERSONAL INFORMATION PROTECTION ACT』 Article 35(Access to Personal Information), If users request to have access to their personal information stored in the Company database, the Company will immediately take action upon receipt of your contacting a personal information manager by e-mail.  However, access may be restricted in the following cases.<br>
                A. When access is prohibited or restricted by law<br>
                B. When there is a risk of harming another person's life and body or unfairly infringing on another person's property and other interests<br>
                C. When the relevant personal information is specified as the object of collection in accordance with the law<br>
                ② Right to request correction·deletion of personal information: According to『PERSONAL INFORMATION PROTECTION ACT』 Article 36(Rectification or Erasure of Personal Information), If users want to correct or delete their personal information registered in the Company, please proceed directly through the "Leave Membership" item on the website or contact the data protection officer by e-mail or phone.  However, if the personal information is specified as the subject of collection in other laws and regulations, correction or deletion cannot be requested.<br>
                ③ Right to request discontinuance of personal information processing: According to  『PERSONAL INFORMATION PROTECTION ACT』 Article 37(Suspension of Processing of Personal Information), If users request to discontinue personal information processing stored in the Company database,  The "Personal Information Processing Suspension Request" prescribed by Personal Information Protection Commission(PIPC) shall be submitted to the data protection officer by mail, Fax, or e-mail, and the following cases may be rejected at the request of discontinuance of such processing.<br>
                A. When access is prohibited or restricted by law<br>
                B. When there is a risk of harming another person's life and body or unfairly infringing on another person's property and other interests<br>
                C. When it is difficult to perform a contract, such as not being able to provide services agreed upon with the information subject because personal information is not processed, and if the information subject does not clearly express his/her intention to terminate the contract<br>
                ④ As for the request of having access, correcting, deleting, and discontinuing personal information, the Company takes action to respond to such request within 10 days. <br>
                If there is no action taken within 10 days, the Company will notify the delay of processing to the user by e-mail or phone. If users request to have access, correct, delete, and discontinue personal information, you can submit such request to the department in charge.<br>
                ⑤ The website does not collect personal information about minors under the age of 14.
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">7. Matters concerning the installation, operation, and rejection of automatic personal information collection</p>
                <p class="ctt ctt-t2">
                  - What is a cookie?<br>
                  A cookie is a small text file sent to user’s browser by the server used for operating Website and it is also stored in a user's HardDisk.<br>
                  - Purpose of using cookies<br>
                  By storing users’ preferential setting, the Company can provide a prompt Website environment and improve services.<br>
                  - Set cookies<br>
                  Users have the choice to install cookies. Accordingly, users can set the options of Website to allow all cookies, to confirm each installation of cookie, or to refuse all cookies.<br>
                  - How to set cookie settings<br>
                  Internet Explorer : Tools > Internet Options > Privacy >Advanced > Cookies<br>
                  Chrome : Tools > Options > Under the Hood > Privacy > Clear browsing data<br>
                  However, if users refuse the cookie setting, they may not be entitled to use some services.
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">8. Measures to secure safety of personal information</p>
                <p class="ctt ctt-t2">
                  The Company takes the following technical, administrative and physical measures to prevent personal information from being lost, stolen, leaked, altered or damaged in processing users’ personal information :<br>
                  Establishing and implementing internal management plan, Minimizing number of staff handling personal information and training, Storage of access records and prevention of forging or falsifying logs, Encryption of personal information, etc.
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">9. Personal information management director</p>
                <p class="ctt ctt-t2">
                  Data protection officer (IT Division / JaeYong Jang / 02-6390-1410)<br><!-- 230202 고객 요청으로 스타일 없앰 -->
                  Department in charge of data protection (Security Team / <a style="all: unset; cursor: pointer;" href="mailto:security@lxhausys.com">security@lxhausys.com</a> / 02-6390-1410)
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">10. Duty of notification</p>
                <p class="ctt ctt-t2">
                  This Privacy Notice will take effect from the effective date, and if there are changes such as addition,deletion or correction of the Notice, the Company will announce 7 days prior to the changes taking effect by our page.
                  <br>Announcement date : 2022. 03. 04
                  <br>Effective date : 2022. 03. 04
                </p>
              </div>
              <div class="t-type">
                <p class="tt-24">11. Ways to remedy infringement of rights and interests</p>
                <p class="ctt ctt-t2">
                  In order to receive relief from personal information infringement, the data subject may apply for dispute resolution or consultation with the Personal Information Dispute Mediation Committee, the Korea Internet & Security Agency, and the Personal Information Infringement Report Center.
                  <br>
                  In addition, please contact the institution below for reports and counseling of other personal information infringement.
                  <br>
                  ① The Personal Information Infringement Report Center : (+82) 118 (privacy.kisa.or.kr)
                  <br>
                  ② The Personal Information Dispute Mediation Committee : (+82) 1833-6972 (www.kopico.go.kr)
                  <br>
                  ③ Cyber Investigation Department at the Supreme Prosecutors' Office. : (+82) 1301, cid@spo.go.kr (www.spo.go.kr)
                  <br>
                  ④ The National Police Agency's cyber safety guard : (+82) 182 (ecrm.cyber.go.kr)
                </p>
              </div>
            </div>
            <!-- GL 일때 privacy 내용 끝 -->

          </div>
        </div>
      </div>
      <!-- //container (Private Policy) -->
    </div>
    <!-- //content -->
    <FooterComponent v-if="!popFlag"></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import ProductService from '@/server/product.service'

export default {
  name: 'policy',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      popFlag: false,
      doc: ''
    }
  },
  async mounted () {
    const pop = this.$route.query.pop
    if (pop && pop === 'Y') {
      this.popFlag = true
    }
    const contentResInfo = await ProductService.getCategoryCode('PRIVACY_POLICY' + this.$countryCode)
    const contentRes = await ProductService.getContentList(contentResInfo.data.object.id)
    this.doc = contentRes.data.list[0].attribute.doc
  },
  methods: {
    async downloadFile (file) {
      const url = this.$imagePath + file

      try {
        function toDataURL (url, callback) {
          var xhr = new XMLHttpRequest()
          xhr.onload = function () {
            var reader = new FileReader()
            reader.onloadend = function () {
              callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
          }
          xhr.open('GET', url)
          xhr.responseType = 'blob'
          xhr.send()
        }

        toDataURL(url, function (dataUrl) {
          const element = document.createElement('a')
          element.setAttribute('href', dataUrl)
          element.setAttribute('download', file)
          document.body.appendChild(element)
          element.click()
        })
      } catch (error) {
        console.log(error)
      }
    },
    goLink (url) {
      if (url === '2022') {
        location.href = 'etc/privacy/2022'
      } else {
        location.href = 'etc/privacy'
      }
    }
  }
}
</script>
<style scoped>
.sel-box {
  display: inline-block;
  width: 260px;
  height: 50px;
  vertical-align: top;
  margin: 0;
  float: right;
}
.sel-box .btn-sel{
  height: 50px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: #fff;
}
.btn-sel span {
  display: block;
  padding-right: 22px;
  font-size: 16px;
  text-align: left;
  background: url(https://img.lxhausys.com/public/images/icon/select_arrow_down.png) right center no-repeat;
  background-position: right 20px center;
}
.btn-sel.on span {
  background-image: url(https://img.lxhausys.com/public/images/icon/select_arrow_up.png);
  background-position: right 20px center;
}
.sel-box .btn-sel span.placeholder{
  color:#222;
  font-size: 16px;
  padding:0 19px 0 24px;
}
.sel-box .btn-sel span{
  color:#222;
  font-size: 16px;
  padding:0 19px 0 24px;
}
.sel-box {
  position: relative;
}
.sel-box .sel-list {
  display: none;
  position: absolute;
  top: 49px;
  left: 0;
  z-index: 100;
  width: 100%;
}
.sel-box .sel-list .box {
  overflow-y: auto;
  max-height: 230px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #fff;
}
.sel-box .sel-list .box > li{
  padding: 0 24px;
  height: 50px;
  display: flex;
  align-items: center;
}
.sel-box .sel-list .box > li + li{
  border-top: 1px solid #e8e8e8;
}
.sel-box .sel-list .box > li:hover{
  background-color: #f8f8f8;
}
</style>
