/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */
import _ from '@/common/helper/lodash.js'

export default {
  authenticated (state) {
    if (_.isUndefined(state.authenticated)) {
      return false
    }
    return state.authenticated
  }
}
