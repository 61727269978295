<template>
  <OverviewSection>
    <div class="korea-company-area">
      <div class="glBuiz">
      <h3 class="tt-30">{{ $t('globalBusiness.text.tab_02_title') }}</h3>
      <div class="tableArea tbl-box type04  field-01">
        <table>
          <colgroup>
            <col style="width:30%">
            <col style="width:auto%">
          </colgroup>
          <thead>
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_01_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_01_brief') }}</td>
            </tr>
          </thead>
          <tbody id="">
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_02_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_02_brief') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_03_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_03_brief') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_04_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_04_brief') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_05_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_05_brief') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_06_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_06_brief') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('globalBusiness.text.tab_02_info_07_title') }}</th>
              <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_07_brief') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    </div>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'

export default {
  components: {
    OverviewSection
  }
}
</script>

<style scoped>
.korea-company-area {
  display: flex;
  justify-content: center;font-family: "NotoSans" , sans-serif;
}

.glBuiz {
  max-width: 1440px;
}

.glBuiz .tt-30 {
  margin-bottom: 15px;
}
</style>
