import request from './request'
const urlPrefix = ''
export function getCategoryList (parentId) {
  return request({
    url: `${urlPrefix}/directive/categoryList?parentId=${parentId}`,
    method: 'get'
  })
}
export function getCategoryId (id) {
  return request({
    url: `${urlPrefix}/category/id/${id}`,
    method: 'get'
  })
}
export function getCategoryCode (code) {
  return request({
    url: `${urlPrefix}/category/code/${code}`,
    method: 'get'
  })
}
export function getCategoryListAttr (id) {
  return request({
    url: `${urlPrefix}/category/id/${id}?containsAttribute=true`,
    method: 'get'
  })
}
export function getCategoryAttributeList (id) {
  return request({
    url: `${urlPrefix}/category/list`,
    method: 'post',
    data: {
      parentId: id
    }
  })
}

export function getCollectionList (id) {
  return request({
    url: `${urlPrefix}/category/collectionList`,
    method: 'post',
    data: {
      parentId: id,
      containChild: true
    }
  })
}
