var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content au-view" }, [
        _c("div", { staticClass: "location" }, [
          _vm.nationCode == "_US" || _vm.nationCode == "_GL"
            ? _c("ul", [
                _c(
                  "li",
                  {
                    directives: [
                      { name: "hover-cursor", rawName: "v-hover-cursor" },
                    ],
                    staticClass: "ico-home",
                    on: {
                      click: function ($event) {
                        _vm.gotolink(
                          `/${_vm.countryCodeSub.toLowerCase()}/index`
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("csrReport.text.home")))]
                ),
                _c("li", [_vm._v(_vm._s(_vm.$t("csrReport.text.about_us")))]),
                _c("li", [_vm._v("Our Sustainability")]),
                _c("li", [_vm._v("ESG Reports")]),
                _c("li"),
              ])
            : _vm.nationCode == "_JP"
            ? _c("ul", [
                _c(
                  "li",
                  {
                    directives: [
                      { name: "hover-cursor", rawName: "v-hover-cursor" },
                    ],
                    staticClass: "ico-home",
                    on: {
                      click: function ($event) {
                        _vm.gotolink(
                          `/${_vm.countryCodeSub.toLowerCase()}/index`
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("csrReport.text.home")))]
                ),
                _c("li", [_vm._v(_vm._s(_vm.$t("csrReport.text.about_us")))]),
                _c("li", [_vm._v("Our Sustainability")]),
                _c("li", [_vm._v("ESG Reports")]),
                _c("li"),
              ])
            : _vm.nationCode == "_RU"
            ? _c("ul", [
                _c(
                  "li",
                  {
                    directives: [
                      { name: "hover-cursor", rawName: "v-hover-cursor" },
                    ],
                    staticClass: "ico-home",
                    on: {
                      click: function ($event) {
                        _vm.gotolink(
                          `/${_vm.countryCodeSub.toLowerCase()}/index`
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("csrReport.text.home")))]
                ),
                _c("li", [_vm._v(_vm._s(_vm.$t("csrReport.text.about_us")))]),
                _c("li", [_vm._v("Our Sustainability")]),
                _c("li", [_vm._v("ESG Reports")]),
                _c("li"),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "container au-box inner container_esg_reports" },
          [
            _c("div", { staticClass: "w-con-02 ba-text" }, [
              _c("div", { staticClass: "sub-visual-section" }, [
                _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                  ? _c("h1", { staticClass: "visual-title tt-1 tac" }, [
                      _vm._v("ESG Reports"),
                    ])
                  : _vm.nationCode == "_JP"
                  ? _c("h1", { staticClass: "visual-title tt-1 tac" }, [
                      _vm._v("ESG Reports"),
                    ])
                  : _vm.nationCode == "_RU"
                  ? _c("h1", { staticClass: "visual-title tt-1 tac" }, [
                      _vm._v("ESG Reports"),
                    ])
                  : _vm._e(),
              ]),
              _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                ? _c("div", { staticClass: "t-list-box" }, [
                    _c("h2", { staticClass: "tit-04" }, [
                      _vm._v("Sustainability Report"),
                    ]),
                  ])
                : _vm._e(),
              _vm.nationCode == "_JP"
                ? _c("div", { staticClass: "t-list-box" }, [
                    _c("h2", { staticClass: "tit-04" }, [
                      _vm._v("サステナビリティレポート"),
                    ]),
                  ])
                : _vm._e(),
              _vm.nationCode == "_RU"
                ? _c("div", { staticClass: "t-list-box" }, [
                    _c("h2", { staticClass: "tit-04" }, [
                      _vm._v("Отчет об устойчивом развитии"),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "crs-box" }, [
              _c("div", { staticClass: "w-con col-02" }, [
                _c("div", { staticClass: "img-box bd-gray" }, [
                  _c("img", {
                    attrs: { src: _vm.mainReportImage, alt: "report" },
                  }),
                ]),
                _c("div", { staticClass: "txt-box bd-gray" }, [
                  _c("div", { staticClass: "tit-box" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.mainReportYear))]),
                    _c("p", [_vm._v(_vm._s(_vm.mainReportYearTitle))]),
                  ]),
                  _c("div", { staticClass: "txt ta-c" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.mainReportDescHeader))]),
                    _c("div", { staticClass: "t-type-03" }, [
                      _vm._v(" " + _vm._s(_vm.mainReportDesc) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(_vm.mainReportEngFile)
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("csrReport.text.english"))),
                        ]),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(_vm.mainReportCnFile)
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("csrReport.text.chinese"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "w-con crs-list" }, [
              _c(
                "ul",
                _vm._l(_vm.reports, function (report) {
                  return _c("li", [
                    _c("div", { staticClass: "bd-gray" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("strong", [_vm._v(_vm._s(report.attribute.year))]),
                        _c("p", [
                          _vm._v(" " + _vm._s(report.attribute.title) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "img-box" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.$imagePath + report.attribute.image,
                            alt: "2020 Sustainability Report",
                          },
                        }),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn-down",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadFile(report.attribute.file)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("csrReport.text.download")))]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }