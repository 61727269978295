var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainable_products/top_image.png",
          title: "Sustainable Products",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("LifeCycleAssessment"),
                  _c("EcoFriendlyCertification"),
                  _c("EcoFriendlyProducts"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          793053953
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainable_products/top_image.png",
          title: "サステナブル製品",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("LifeCycleAssessment"),
                _c("EcoFriendlyCertification"),
                _c("EcoFriendlyProducts"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainable_products/top_image.png",
          title: "Экологически чистые продукты",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("LifeCycleAssessment"),
                _c("EcoFriendlyCertification"),
                _c("EcoFriendlyProducts"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainable_products/top_image.png",
          title: "可持续产品",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("LifeCycleAssessment"),
                _c("EcoFriendlyCertification"),
                _c("EcoFriendlyProducts"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }