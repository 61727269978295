var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-area" },
    _vm._l(_vm.headerInfo, function (it, idx) {
      return _c(
        "div",
        {
          key: idx,
          staticClass: "item",
          class: it.active ? "active" : "",
          on: {
            click: function ($event) {
              return _vm.onHeaderClick(idx)
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(it.title))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }