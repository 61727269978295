var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("SustainabilityText", { attrs: { text: _vm.topText } }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            446279240
          ),
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "life-cycle-assessment-content" },
              [
                _c("Lca", {
                  attrs: {
                    title: _vm.lcaData.title,
                    textCard: _vm.lcaData.textCard,
                  },
                }),
                _c("img", {
                  staticClass: "arrow-image",
                  attrs: {
                    src: "images/aboutus/sustainability/sustainable_products/arrow-image.png",
                  },
                }),
                _c("ScopesOfImpact", {
                  attrs: {
                    title: _vm.scopesOfImpactData.title,
                    text: _vm.scopesOfImpactData.text,
                    description: _vm.scopesOfImpactData.description,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("SustainabilityText", { attrs: { text: _vm.topTextJp } }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "life-cycle-assessment-content" },
              [
                _c("Lca", {
                  attrs: {
                    title: _vm.lcaDataJp.title,
                    textCard: _vm.lcaDataJp.textCard,
                  },
                }),
                _c("img", {
                  staticClass: "arrow-image",
                  attrs: {
                    src: "images/aboutus/sustainability/sustainable_products/arrow-image.png",
                  },
                }),
                _c("ScopesOfImpact", {
                  attrs: {
                    title: _vm.scopesOfImpactDataJp.title,
                    text: _vm.scopesOfImpactDataJp.text,
                    description: _vm.scopesOfImpactDataJp.description,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("SustainabilityText", { attrs: { text: _vm.topTextRu } }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "life-cycle-assessment-content" },
              [
                _c("Lca", {
                  attrs: {
                    title: _vm.lcaDataRu.title,
                    textCard: _vm.lcaDataRu.textCard,
                  },
                }),
                _c("img", {
                  staticClass: "arrow-image",
                  attrs: {
                    src: "images/aboutus/sustainability/sustainable_products/arrow-image.png",
                  },
                }),
                _c("ScopesOfImpact", {
                  attrs: {
                    title: _vm.scopesOfImpactDataRu.title,
                    text: _vm.scopesOfImpactDataRu.text,
                    description: _vm.scopesOfImpactDataRu.description,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("SustainabilityText", { attrs: { text: _vm.topTextCn } }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "life-cycle-assessment-content" },
              [
                _c("Lca", {
                  attrs: {
                    title: _vm.lcaDataCn.title,
                    textCard: _vm.lcaDataCn.textCard,
                  },
                }),
                _c("img", {
                  staticClass: "arrow-image",
                  attrs: {
                    src: "images/aboutus/sustainability/sustainable_products/arrow-image.png",
                  },
                }),
                _c("ScopesOfImpact", {
                  attrs: {
                    title: _vm.scopesOfImpactDataCn.title,
                    text: _vm.scopesOfImpactDataCn.text,
                    description: _vm.scopesOfImpactDataCn.description,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }