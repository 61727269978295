var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { staticClass: "mt-64", attrs: { title: "Pollutant Management" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("pre", { staticClass: "targets" }, [_vm._v("2023 Targets")]),
          ]),
          _c("OverviewSectionItem", [_c("PollutantManagementBorderBox")], 1),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area1" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard1.imageCardTitle,
                    imageCardContentList: _vm.imageCard1.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard2.imageCardTitle,
                    imageCardContentList: _vm.imageCard2.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("PollutantManagementImage", {
                attrs: {
                  image1: _vm.pollutantManagementImage.image1,
                  image2: _vm.pollutantManagementImage.image2,
                  text: _vm.pollutantManagementImage.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area2" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard3.imageCardTitle,
                    imageCardContentList: _vm.imageCard3.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard4.imageCardTitle,
                    imageCardContentList: _vm.imageCard4.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { staticClass: "mt-64", attrs: { title: "汚染物質管理" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("pre", { staticClass: "targets" }, [_vm._v("2023年の目標")]),
          ]),
          _c("OverviewSectionItem", [_c("PollutantManagementBorderBox")], 1),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area1" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard1Jp.imageCardTitle,
                    imageCardContentList: _vm.imageCard1Jp.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard2Jp.imageCardTitle,
                    imageCardContentList: _vm.imageCard2Jp.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("PollutantManagementImage", {
                attrs: {
                  image1: _vm.pollutantManagementImage.image1,
                  image2: _vm.pollutantManagementImage.image2,
                  text: _vm.pollutantManagementImage.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area2" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard3Jp.imageCardTitle,
                    imageCardContentList: _vm.imageCard3Jp.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard4Jp.imageCardTitle,
                    imageCardContentList: _vm.imageCard4Jp.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          staticClass: "mt-64",
          attrs: { title: "Регулирование выбросов загрязняющих веществ" },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("pre", { staticClass: "targets" }, [
              _vm._v("Целевые показатели на 2023 год"),
            ]),
          ]),
          _c("OverviewSectionItem", [_c("PollutantManagementBorderBox")], 1),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area1" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard1Ru.imageCardTitle,
                    imageCardContentList: _vm.imageCard1Ru.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard2Ru.imageCardTitle,
                    imageCardContentList: _vm.imageCard2Ru.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("PollutantManagementImage", {
                attrs: {
                  image1: _vm.pollutantManagementImage.image1,
                  image2: _vm.pollutantManagementImage.image2,
                  text: _vm.pollutantManagementImage.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area2" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard3Ru.imageCardTitle,
                    imageCardContentList: _vm.imageCard3Ru.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard4Ru.imageCardTitle,
                    imageCardContentList: _vm.imageCard4Ru.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { staticClass: "mt-64", attrs: { title: "污染物管理" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("pre", { staticClass: "targets" }, [_vm._v("2023 目标")]),
          ]),
          _c("OverviewSectionItem", [_c("PollutantManagementBorderBox")], 1),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area1" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard1Cn.imageCardTitle,
                    imageCardContentList: _vm.imageCard1Cn.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard2Cn.imageCardTitle,
                    imageCardContentList: _vm.imageCard2Cn.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("PollutantManagementImage", {
                attrs: {
                  image1: _vm.pollutantManagementImage.image1,
                  image2: _vm.pollutantManagementImage.image2,
                  text: _vm.pollutantManagementImage.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area2" },
              [
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard3Cn.imageCardTitle,
                    imageCardContentList: _vm.imageCard3Cn.imageCardContentList,
                  },
                }),
                _c("div", { staticClass: "line-area" }, [
                  _c("div", { staticClass: "line" }),
                ]),
                _c("PollutantManagementImageCard", {
                  attrs: {
                    imageCardTitle: _vm.imageCard4Cn.imageCardTitle,
                    imageCardContentList: _vm.imageCard4Cn.imageCardContentList,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }