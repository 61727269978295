<template>
  <div>
    <div class="open-line-area">
        <img class="open-line" src="images/aboutus/sustainability/esg_strategy_system/esg_open_line.png">
    </div>
    <slot></slot>

    <div class="close-line-area">
      <img class="close-line" src="images/aboutus/sustainability/esg_strategy_system/esg_close_line.png">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.open-line-area {
  display: flex;
  justify-content: center;
}

.open-line {
  max-width: 979px;
}

.close-line-area {
  display: flex;
  justify-content: center;
}

.close-line {
  max-width: 979px;
}

</style>
