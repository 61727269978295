<template>
  <BORTE_COLOR   :category="category"
                   :collectionList="collectionList"
                   :dictMap="dictMap"
                   v-model="selected">
  </BORTE_COLOR>
</template>

<script>
import { mapState } from 'vuex'
import BORTE_COLOR from './BORTE_COLOR.vue'

export default {
  name: 'BorteFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    BORTE_COLOR

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: {}
    }
  },
  watch: {},
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.selected], val => {
      this.$emit('update', this.selected)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {}
}
</script>
