import { render, staticRenderFns } from "./SoftwherePointImage.vue?vue&type=template&id=3ef4c246&scoped=true"
import script from "./SoftwherePointImage.vue?vue&type=script&lang=js"
export * from "./SoftwherePointImage.vue?vue&type=script&lang=js"
import style0 from "./SoftwherePointImage.vue?vue&type=style&index=0&id=3ef4c246&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef4c246",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/STG_US_FRONT/synccms-front/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ef4c246')) {
      api.createRecord('3ef4c246', component.options)
    } else {
      api.reload('3ef4c246', component.options)
    }
    module.hot.accept("./SoftwherePointImage.vue?vue&type=template&id=3ef4c246&scoped=true", function () {
      api.rerender('3ef4c246', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/aboutus/rndCenter/marketLeadingDesign/components/SoftwherePointImage.vue"
export default component.exports