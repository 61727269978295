<template>
  <OverviewSection
    type="major"
    title="Environmental Investment"
    color="black"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <EnvironmentalInvestmentTextCard
        :year="textCard.year"
        :unit="textCard.unit"
        :text="textCard.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area">
        <EnvironmentalInvestmentImageCard
          v-for="(card, index) in imageCards"
          :key="index"
          :icon="card.icon"
          :title="card.title"
          :imageCardContentList="card.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    type="major"
    title="環境投資"
    color="black"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <EnvironmentalInvestmentTextCard
        :year="textCardJp.year"
        :unit="textCardJp.unit"
        :text="textCardJp.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area">
        <EnvironmentalInvestmentImageCard
          v-for="(card, index) in imageCardsJp"
          :key="index"
          :icon="card.icon"
          :title="card.title"
          :imageCardContentList="card.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    type="major"
    title="Инвестиции в экологию"
    color="black"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <EnvironmentalInvestmentTextCard
        :year="textCardRu.year"
        :unit="textCardRu.unit"
        :text="textCardRu.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area">
        <EnvironmentalInvestmentImageCard
          v-for="(card, index) in imageCardsRu"
          :key="index"
          :icon="card.icon"
          :title="card.title"
          :imageCardContentList="card.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    type="major"
    title="环境投资"
    color="black"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <EnvironmentalInvestmentTextCard
        :year="textCardCn.year"
        :unit="textCardCn.unit"
        :text="textCardCn.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area">
        <EnvironmentalInvestmentImageCard
          v-for="(card, index) in imageCardsCn"
          :key="index"
          :icon="card.icon"
          :title="card.title"
          :imageCardContentList="card.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import EnvironmentalInvestmentImageCard from './EnvironmentalInvestmentImageCard.vue'
import EnvironmentalInvestmentTextCard from './EnvironmentalInvestmentTextCard.vue'
export default {
  components: {
    EnvironmentalInvestmentTextCard,
    EnvironmentalInvestmentImageCard,
    OverviewSection,
    OverviewSectionItem
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      textCard: { 
        year: '2022 Total',
        unit: '$6.3 million',
        text: 'We invested $3.2 million in the Cheongju Plant to install more RTOs (Regenerative Thermal Oxidizers)\n' +
              'while also making investments worth $3..1 million for the savings of energy consumption.'
      },
      textCardJp: { 
        year: '2022 Total',
        unit: '$6.3 million',
        text: '当社は清州工場に320万ドルを投資し、RTO（再生熱酸化装置）をさらに設置しました。\n' +
              'また、エネルギー消費の節約のために310万ドル相当の投資も行いました。'
      },
      textCardRu: { 
        year: '2022 Total',
        unit: '$6.3 million',
        text: 'Мы инвестировали 3,2 миллиона долларов в завод в Чхонджу,\n' +
              'Мчтобы установить больше регенеративных термических окислителей (RTO).\n' +
              'а также вложили 3,1 миллиона долларов в экономию энергопотребления.'
      },
      textCardCn: { 
        year: '2022 Total',
        unit: '$6.3 million',
        text: 'LX Hausys在清州工厂投资320万美元，安装了更多的RTO（再生式热氧化器），\n' +
              '同时还投资310万美元用于节能。'
      },
      imageCards: [
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon1.png',
          title: 'Air Quality',
          imageCardContentList: [
            {
              contentTitle: 'Cheongju Plant',
              contentText: 'Newly installed electric precipitator\n' +
              'for flooring materials LVT no.1 and 2'
            },
            {
              contentTitle: 'Ulsan Plant',
              contentText: 'Newly installed boiler and RTO smokestack\n' +
              'automated measuring device'
            }
          ]
        },
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon2.png',
          title: 'GHG Emissions',
          imageCardContentList: [
            {
              contentTitle: 'Cheongju Plant',
              contentText: 'Improved air compressor controller\n' +
              'at boiler facility'
            },
            {
              contentTitle: 'Ulsan Plant',
              contentText: 'Investment in pipe boiler\n' +
              'efficiency improvements'
            }
          ]
        }
      ],
      imageCardsJp: [
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon1.png',
          title: '空気の質',
          imageCardContentList: [
            {
              contentTitle: '清州工場',
              contentText: '床材LVT No.1、2用電気集塵機新設'
            },
            {
              contentTitle: '蔚山工場',
              contentText: 'ボイラー・RTO煙突自動計測装置新設'
            }
          ]
        },
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon2.png',
          title: '温室効果ガス排出量',
          imageCardContentList: [
            {
              contentTitle: '清州工場',
              contentText: 'ボイラー施設の空気圧縮機コントローラーの改良'
            },
            {
              contentTitle: '蔚山工場',
              contentText: 'パイプボイラーの効率改善への投資'
            }
          ]
        }
      ],
      imageCardsRu: [
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon1.png',
          title: 'Качество воздуха',
          imageCardContentList: [
            {
              contentTitle: 'Завод в Чхонджу',
              contentText: 'Новый электрофильтр\n' +
              'для напольных покрытий LVT № 1 и 2'
            },
            {
              contentTitle: 'Завод в Ульсане',
              contentText: 'Вновь установленный котел и дымовая труба RTO\n' +
              'автоматизированное измерительное устройство'
            }
          ]
        },
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon2.png',
          title: 'Выбросы парниковых\n' + 
                  'газов',
          imageCardContentList: [
            {
              contentTitle: 'Завод в Чхонджу',
              contentText: 'Улучшенный контроллер воздушного\n' +
              'компрессора на котельной'
            },
            {
              contentTitle: 'Завод в Ульсане',
              contentText: 'Инвестиции в трубные котлы\n' +
              'повышение эффективности'
            }
          ]
        }
      ],
      imageCardsCn: [
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon1.png',
          title: '空气质量',
          imageCardContentList: [
            {
              contentTitle: '清州工厂',
              contentText: '新安装的地板材料LVT 1号和2号电除尘器'
            },
            {
              contentTitle: '蔚山工厂',
              contentText: '新安装的锅炉和RTO烟囱自动测量装置'
            }
          ]
        },
        {
          icon: 'images/aboutus/sustainability/sustainability_management/environmental-investment-icon2.png',
          title: '温室气体排放',
          imageCardContentList: [
            {
              contentTitle: '清州工厂',
              contentText:
              '改进了锅炉设施的空气压缩机控制器'
            },
            {
              contentTitle: '蔚山工厂',
              contentText:
              '提高对管道锅炉效率的投资'
            }
          ]
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },


}
</script>

<style scoped>
.image-card-area {
  display: flex;
  gap: 70px;
  width: 1440px;
}

@media (max-width: 768px) {
  .image-card-area {
    width: 100%;
    flex-direction: column;
  }
}
</style>
