var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-100" },
    [
      _c("ProductSectionHeader", {
        attrs: {
          title: "Commercial Vegan Leather",
          description:
            "We have applied automotive seat fabric technology to achieve excellent surface quality in terms of durability, resistance to contamination, and other factors. We are also able to provide products using bio-based materials such as Kenaf.",
        },
      }),
      _c("ProductTab", {
        staticClass: "mt-50",
        attrs: { item: [{ title: "LXELLO™", component: "LXELLO" }] },
        scopedSlots: _vm._u([
          {
            key: "LXELLO",
            fn: function () {
              return [
                _c("Seatcorverlayout", {
                  attrs: { imageSrc: "images/automotive/skin/LXELLO.png" },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "title" }, [
                            _c("span", [_vm._v("LXELLO™ Green")]),
                          ]),
                          _c("div", { staticClass: "description" }, [
                            _c("span", [
                              _vm._v(
                                "Comply with Class 1 of the OEKO-TEX® Standard 100, which attests that the material is safe for babies and small children"
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _c("span", [_vm._v("LXELLO™ Bio")]),
                          ]),
                          _c("div", { staticClass: "description" }, [
                            _c("span", [
                              _vm._v(
                                "The skin and foam layers contain bio based materials such as kenaf"
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _c("span", [_vm._v("LXELLO™ Eco")]),
                          ]),
                          _c("div", { staticClass: "description" }, [
                            _c("span", [
                              _vm._v(
                                "• RCS (Recycled Claim Standard) certificated"
                              ),
                            ]),
                            _c("br"),
                            _c("span", [
                              _vm._v(
                                "• Recycled content 35 wt% (Recycled Post-Consumer Polyester)"
                              ),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "annotation",
                      fn: function () {
                        return [
                          _c("span", [
                            _vm._v(
                              "• The skin and foam layers contain bio based materials such as kenaf"
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v("- Kenaf is one of the highest CO"),
                            _c("sub", [_vm._v("2")]),
                            _vm._v(
                              " absorption rates among plants 3 to 10 times higher than average ¹⁾"
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "• Designed to meet automotive interior demands including that of stain resistance"
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "- Excellent anti-staining performance enables bright interior design"
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "1) Source: Ministry of Environment's 'Analytic Report on Eco-friendly Crops' (2019)"
                            ),
                          ]),
                          _c("br"),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }