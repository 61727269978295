/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

/* eslint-disable no-param-reassign */

import {
  ADD_CART_ITEM,
  ADD_ORDER_ITEM, CLEAR_CART_ITEM, CLEAR_MEMBER_INFO,
  CLEAR_ORDER_ITEM,
  SET_CART_ITEM,
  SET_MEMBER_INFO,
  SET_ORDER_ITEM
} from './mutation-types'

export default {
  [ADD_CART_ITEM] (state, cartItemList) {
    const cartList = state.cartList || []
    cartList.push(...cartItemList)

    localStorage.setItem('cartItem', JSON.stringify(cartList))
    state.cartList = cartList
  },
  [SET_CART_ITEM] (state, cartItemList) {
    localStorage.setItem('cartItem', JSON.stringify(cartItemList))
    state.cartList = cartItemList
  },
  [CLEAR_CART_ITEM] (state) {
    localStorage.setItem('cartItem', [])
    state.cartList = []
  },
  [ADD_ORDER_ITEM] (state, orderItemList) {
    const orderList = state.orderList || []
    orderList.push(...orderItemList)

    localStorage.setItem('orderItem', JSON.stringify(orderList))
    state.orderList = orderList
  },
  [SET_ORDER_ITEM] (state, orderItemList) {
    localStorage.setItem('orderItem', JSON.stringify(orderItemList))
    state.orderList = orderItemList
  },
  [CLEAR_ORDER_ITEM] (state) {
    localStorage.setItem('orderItem', JSON.stringify([]))
    state.orderList = []
  },
  [SET_MEMBER_INFO] (state, memberInfo) {
    localStorage.setItem('orderMemberInfo', JSON.stringify(memberInfo))
    state.memberInfo = memberInfo
  },
  [CLEAR_MEMBER_INFO] (state) {
    localStorage.setItem('orderMemberInfo', JSON.stringify({}))
    state.memberInfo = {}
  }
}
