var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-cate" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("other.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.viatera")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.votherPD")))]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "container prod-dt-view prod-op-view viatera-op container_pf",
          },
          [
            _c("div", { staticClass: "visual visual-detail" }, [
              _vm.bannerList[0].attribute.pc_img !== undefined
                ? _c(
                    "div",
                    {
                      staticClass: "visual-image full_width center",
                      style: {
                        "background-image":
                          "url( " +
                          _vm.$imagePath +
                          _vm.bannerList[0].attribute.pc_img +
                          ")",
                      },
                    },
                    [
                      _c("div", { staticClass: "inner_width" }, [
                        _c("div", { staticClass: "txt-box" }, [
                          _c("h1", { staticClass: "title" }, [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.bannerList[0].attribute.description_1
                                )
                              ),
                            ]),
                          ]),
                          _c("p", {
                            staticClass: "txt-02",
                            staticStyle: { color: "white" },
                            attrs: { id: "text" },
                            domProps: { innerHTML: _vm._s(_vm.subText) },
                          }),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "inspr-view prod-fd-view w-con inner" }, [
              _c("div"),
              _c("div", { staticClass: "tab-ui" }, [
                _c("div", { staticClass: "list-type-view row-04" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.contentList, function (content, index) {
                      return _c("li", [
                        _c("div", { staticClass: "img-box" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.$imagePath + content.attribute.pc_thunb,
                              alt: "other product img",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.popUpView(content)
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "info02" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                { attrs: { href: "javascript:void(0);" } },
                                [_vm._v(_vm._s(content.content.title))]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "layer-popup layer-other" }, [
              _c("div", { staticClass: "popup pop-close" }, [
                _c("div", { staticClass: "pop-other scr-y" }, [
                  _c(
                    "div",
                    { staticClass: "inspr-view prod-fd-view prod-op-view" },
                    [
                      _c("div", { staticClass: "list-type-view col-02" }, [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "img-box tab-con other01 on" },
                            [
                              _vm.contentAttribute.pc_thunb !== undefined
                                ? _c("img", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      src:
                                        _vm.$imagePath +
                                        _vm.contentAttribute.pc_thunb,
                                      alt: "images",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _vm._m(0),
                          _c("p", { staticClass: "tt-1" }, [
                            _vm._v(_vm._s(_vm.contentContent.title)),
                          ]),
                          _c("div", { staticClass: "tbl-box" }, [
                            _c("table", [
                              _c("caption", [_vm._v("Product Specs")]),
                              _vm._m(1),
                              _c("tbody", [
                                _vm.contentAttribute.overall !== undefined
                                  ? _c("tr", [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("other.text.overall"))
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.contentAttribute.overall)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.contentAttribute.inner !== undefined
                                  ? _c("tr", [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("other.text.inner"))
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.contentAttribute.inner)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.contentAttribute.option !== undefined
                                  ? _c("tr", [
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v(
                                          _vm._s(_vm.contentAttribute.option)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.contentAttribute.caution !== undefined
                                  ? _c("tr", [
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v(
                                          _vm._s(_vm.contentAttribute.caution)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "btns dwonarea" }, [
                            _c("p", { staticClass: "ctt ctt-t2" }, [
                              _vm._v("Documentation"),
                            ]),
                            _c("div", [
                              _vm.contentAttribute.pdf
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-down",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.contentAttribute.pdf
                                          )
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("pdf")])]
                                  )
                                : _vm._e(),
                              _vm.contentAttribute.dxf
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-down",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.contentAttribute.dxf
                                          )
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("DXF")])]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-line",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.contactUs()
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("other.text.contactus"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(_vm._s(_vm.$t("other.text.close")))]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wide-proDeteail-label" }, [
      _c("span", { staticClass: "pro" }, [_vm._v("Kitchen")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }