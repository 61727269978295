var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "major-design-performance-card" }, [
    _c("div", { staticClass: "header" }, [
      _c("img", { staticClass: "header-icon", attrs: { src: _vm.icon } }),
      _c("pre", { staticClass: "header-text" }, [
        _vm._v(_vm._s(_vm.headerText)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "picture-area" },
      _vm._l(_vm.pictures, function (item, index) {
        return _c("img", {
          key: index,
          staticClass: "picture",
          attrs: { src: item },
          on: {
            mouseover: function ($event) {
              return _vm.selectPicture(index)
            },
          },
        })
      }),
      0
    ),
    _c("div", { staticClass: "text-area" }, [
      _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }