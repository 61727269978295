/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

/* eslint-disable no-param-reassign */
export default {
  pushItem (state, payload) {
    sessionStorage.setItem('imgLibItem', JSON.stringify(payload.pushArr))
  },
  clearItem (state) {
    sessionStorage.removeItem('imgLibItem')
  }
}