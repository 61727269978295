<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{$t('orderHistory.detail.text.home')}}</li>
          <li>{{$t('orderHistory.detail.text.header')}}</li>
          <li>{{$t('orderHistory.detail.text.order_history')}}</li>
        </ul>
      </div>

      <!-- container  -->
      <div class="container mypg_order_history">
        <div class="w-con">
          <!-- mypg-tab-con Order History -->
          <div class="mypg-tab-con">
            <div class="mypg-ordhstry">

              <div class="mypg-oh-dtinfo">
                <ul>
                  <li><span>{{$t('orderHistory.detail.text.order_number')}}<em>{{orderId}}</em></span></li>
                  <li><span>{{$t('orderHistory.detail.text.order_date')}}<em>{{order.registDate | moment('MM.DD.YYYY HH:mm:ss')}}</em></span></li>
                  <li><span>{{$t('orderHistory.detail.text.status')}}<em>{{selectedStatus.text}}</em></span></li>
                </ul>
              </div>
              <div class="tbl-box type05">
                <table>
                  <caption>{{$t('orderHistory.detail.text.order_history_list')}}</caption>
                  <colgroup>
                    <col style="width:auto">
                    <col style="width:150px">
                    <col style="width:150px">
                    <col style="width:150px">
                    <col style="width:150px">
                  </colgroup>
                  <thead>
                  <tr>
                    <th class="ta-c">{{$t('orderHistory.detail.text.product_info')}}</th>
                    <th class="ta-c">{{$t('orderHistory.detail.text.option')}}</th>
                    <th class="ta-c">{{$t('orderHistory.detail.text.quantity')}}</th>
                    <th class="ta-c">{{$t('orderHistory.detail.text.each')}}</th>
                    <th class="ta-c">{{$t('orderHistory.detail.text.total')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in itemList">
                    <td>
                      <div class="mypg-oh-detail">
                        <div class="img-box">
                          <img v-if="item.image" v-bind:src="$imagePath + item.image" alt="Product image" style="width: 150px;">
                        </div>
                        <div class="info">
                          <ul>
                            <li class="txt-comp" v-if="!item.category"></li>
                            <li class="txt-comp" v-else>{{item.category +"®"}}</li>
                            <li class="txt-code">{{item.colorCode}}</li>
                            <li class="txt-prod">{{item.colorNm}}</li>
                            <li class="txt-cate">{{item.collection}}</li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td class="ta-c">{{item.sampleSize}}</td>
                    <td class="ta-c">{{item.qty}}</td>
                    <td class="ta-c">{{item.price}}</td>
                    <td class="ta-c txt-tot" v-if="!item.totalPrice"></td>
                    <td class="ta-c txt-tot" v-else>{{"$ "+item.totalPrice}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="mypg-ohdt-info">
                <p class="tt-4">{{$t('orderHistory.detail.text.payment_info')}}</p>
                <div class="tbl-01">
                  <div class="tbl-box type03">
                    <table>
                      <caption>{{$t('orderHistory.detail.text.shipping_form')}}</caption>
                      <colgroup>
                        <col style="width:185px">
                        <col style="width:auto">
                        <col style="width:185px">
                        <col style="width:auto">
                      </colgroup>
                      <tbody>
                      <tr>
                        <th scope="row">{{$t('orderHistory.detail.text.order_subtotal')}}</th>
                        <td class="ta-r">{{"$"+grandTotal}}</td>
                        <th scope="row" rowspan="2">{{$t('orderHistory.detail.text.grand_total')}}</th>
                        <td class="ta-r txt-tot" rowspan="2">{{"$"+grandTotal}}</td>
                      </tr>
<!--                      <tr>-->
<!--                        <th scope="row">{{$t('orderHistory.detail.text.credit_used')}}</th>-->
<!--                        <td class="ta-r">$0</td>-->
<!--                      </tr>-->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="mypg-ohdt-info">
                <p class="tt-4">{{$t('orderHistory.detail.text.shipping_loc_info')}}</p>
                <div class="tbl-box type02">
                  <table>
                    <caption>{{$t('orderHistory.detail.text.product_specs')}}</caption>
                    <colgroup>
                      <col style="width:185px">
                      <col style="width:auto">
                      <col style="width:185px">
                      <col style="width:auto">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th scope="row">{{$t('orderHistory.detail.text.first_name')}}</th>
                      <td>{{order.firstNm}}</td>
                      <th scope="row">{{$t('orderHistory.detail.text.last_name')}}</th>
                      <td>{{order.lastNm}}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{$t('orderHistory.detail.text.email')}}</th>
                      <td>{{order.email}}</td>
                      <th scope="row">{{$t('orderHistory.detail.text.phone')}}</th>
                      <td>{{order.phone}}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{$t('orderHistory.detail.text.zip')}}</th>
                      <td colspan="3">{{order.zipCd}}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{$t('orderHistory.detail.text.country_n_state')}}</th>
                      <td v-if="!selectedCountry.countryNm || !selectedState.stateNm"></td>
                      <td v-else>{{selectedCountry.countryNm + " / " + selectedState.stateNm}}</td>
                      <th scope="row">{{$t('orderHistory.detail.text.city')}}</th>
                      <td>{{order.city}}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{$t('orderHistory.detail.text.addr1')}}</th>
                      <td colspan="3">{{order.addr1}}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{$t('orderHistory.detail.text.addr2')}}</th>
                      <td colspan="3">{{order.addr2}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <!-- //mypg-tab-con Order History -->
        </div>
      </div>
      <!-- //container  -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import memberInfoService from '@/server/myPage.service'
import ProductService from '@/server/product.service'
import UsService from '@/server/us.service'
import { mapState } from 'vuex'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      headerName: '',
      orderId: '',
      itemList: [],
      member: '',
      order: '',
      like: '',
      totalCredit: '',
      state: [],
      content: [],
      titleName: '',
      selectedGroup: '',
      selectedStatus: '',
      selectedCountry: '',
      selectedState: '',
      contentIds: [],
      grandTotal: 0,
      countryCode: '',
      countryCodeSub: ''
    }
  },
  computed: {
    ...mapState({
      likeCnt: state => state.header.likeCnt
    })
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)

    const prevUrl = Cookies.get('orderStatus')

    this.orderId = this.$route.params.id
    const orderRes = await memberInfoService.getOrder(this.orderId)

    Cookies.remove('orderStatus')
    this.headerName = 'Guest'

    const itemRes = await memberInfoService.getListOrderItem(this.orderId)
    const statusRes = await ProductService.getDictionaryList('ORDER_STATUS')
    const countryRes = await UsService.getCountryList()
    const statesRs = await UsService.getStateList(orderRes.data.country)

    this.order = orderRes.data
    this.itemList = itemRes.data
    this.status = statusRes.data
    this.country = countryRes.data
    this.state = statesRs.data

    if (this.headerName != '') {
      this.titleName = this.headerName
    } else {
      this.titleName = this.order.firstNm + ' ' + this.order.lastNm
    }
    for (let i = 0; i < this.itemList.length; i++) {
      this.contentIds.push(this.itemList[i].contentId)
    }
    this.content = await this.getContents(this.contentIds)

    // status start
    for (let i = 0; i < this.status.length; i++) {
      if (this.order.status === this.status[i].id.value) {
        this.selectedStatus = this.status[i]
      }
    }
    // status end

    for (let k = 0; k < this.itemList.length; k++) {
      for (let a = 0; a < this.content.length; a++) {
        if (this.itemList[k].contentId === this.content[a].content.id) {
          this.itemList[k].image = this.content[a].attribute.image
          this.itemList[k].modelId = this.content[a].content.modelId
          this.itemList[k].totalPrice = this.itemList[k].qty * this.itemList[k].price
          this.itemList[k].colorCode = this.content[a].attribute.code
          if (this.content[a].content.modelId === 'HFLOR') {
            this.itemList[k].collection = this.content[a].parents[1].name
            this.itemList[k].category = this.content[a].parents[2].name
          } else {
            this.itemList[k].collection = this.content[a].parents[0].name
            this.itemList[k].category = this.content[a].parents[1].name
          }
        }
      }
    }
    for (let i = 0; i < this.itemList.length; i++) {
      this.grandTotal += this.itemList[i].totalPrice
    }
    for (let i = 0; i < this.country.length; i++) {
      if (this.order.country === this.country[i].countryCd) {
        this.selectedCountry = this.country[i]
      }
    }
    for (let i = 0; i < this.state.length; i++) {
      if (this.order.stateCd === this.state[i].id.stateCd) {
        this.selectedState = this.state[i]
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async init () {
      this.$store.dispatch('header/getTotalLikeCnt', { vm: this })
    },
    async getContents (contentIds) {
      const postData = {}
      postData.contentIds = contentIds
      const content = await memberInfoService.getContents(postData)
      return content.data
    }
  }
}
</script>
