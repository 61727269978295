<template xmlns="http://www.w3.org/1999/html">
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content inspr-view">
      <div class="container inspr-box gallery-detail" v-if="contentDetail.content">

        <div class="gv-side-header main p-ui">
          <div>
            <!-- location -->
            <div class="location">
              <ul>
                <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCode.toLowerCase()}/index`)">{{
                    $t('gallery.text.home')
                  }}
                </li>
                <li>
                  {{ $t('gallery.text.inspiration') }}
                </li>
                <li v-hover-cursor @click="gotolink(`/${countryCode.toLowerCase()}/inspiration/gallery`)">
                  {{ $t('gallery.text.inspiration_gallery') }}
                </li>
                <li>{{ $t('gallery.text.inspiration_gallery_detail') }}</li>
              </ul>
            </div>
            <!-- //location -->
            <h1 class="con_title tt-3">{{ contentDetail.content.title }}</h1>
            <div class="gv-side-root">
              <template>
                <template v-if="segmentsType">
                  <span class="ctt" v-html="segmentsType.type"/>
                </template>
                <span></span>
                <span class="ctt" v-html="contentDetail.attribute.segments"/>
                <span></span>
              </template>
            </div>
          </div>
        </div>

        <article class="gv-wrap">
          <section class="gv-view">
            <div class="gv-side-header m-ui">
              <p class="tt-3" v-html="contentDetail.content.title"/>
              <div class="gv-side-root">
                <!-- <template v-for="item in categoryTree">
                  <span class="ctt">{{ item }}</span>
                  <span></span>
                </template> -->
                <template>
                  <template v-if="segmentsType">
                    <span class="ctt" v-html="segmentsType.type"/>
                  </template>
                  <span></span>
                  <span class="ctt" v-html="contentDetail.attribute.segments"/>
                  <span></span>
                </template>
              </div>
            </div>
            <div class="img-wrap first">
              <img v-if="contentDetail.attribute" :src="`${$imagePath}${contentDetail.attribute.mainThumbnail}`"
                   :alt="contentDetail.content.title">
              <div class="num_group" v-if="contentDetail.attribute">
                <template
                  v-if="item.contentMarkerIndex !=='none' || item.contentMarkerIndex === undefined || item.contentMarkerIndex === null "
                  v-for=" item in contentDetail.attribute.productList">

                  <div class="num_btn_wrap" :style="{top: item.contentTop ,left: item.contentLeft}"
                       @mouseleave="selectMarkerNumber=null">
                    <button type="button" class="on"
                            :style="{ background: selectMarkerNumber === item.contentMarkerIndex ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.5)' }"
                            @click="selectMarkerNumber= item.contentMarkerIndex"
                    > <!-- on 클래스 토글로 버블 on/off -->
                      <span>{{ item.contentMarkerIndex }}</span>
                    </button>
                    <transition name="fade">
                      <div class="btn_bubble_wrap" v-if="item.contentMarkerIndex === selectMarkerNumber">
                        <div class="top">
                          <div class="left">
                            <div class="img_wrap" v-if="item.detail">
                              <img :src="`${$imagePath}${item.detail.attribute.pf_thumb}`" alt="thumb">
                            </div>
                          </div>
                          <div class="right">
                            <div class="txt_wrap">
                              <template v-if="item.detail">
                              <span class="tag"
                                    v-if="item.detail.attribute.discontinued_color ==='true'
                                       || item.detail.attribute.dictClr ==='true' "
                                    v-html="'Discontinued'"/>
                              </template>
                              <span class="name" v-if="item.detail" v-html="getCategoryName(item.detail.parents, 1)"/>
                              <span class="gray" v-html="item.title"/>
                              <span class="gray" v-if="item.detail" v-html="item.detail.attribute.code"/>
                            </div>
                          </div>
                        </div>
                        <div class="bottom" @click="moveToContentDetail(item)">
                          <a href="javascript:void(0)" class="btn_wrap">
                            <span>View more</span>
                          </a>
                        </div>
                      </div>
                    </transition>
                  </div>

                </template>
              </div>
              <div class="btn-group">
                <button type="button" class="btn_view_large" @click="isLayerModal=true"><span class="icon"></span>
                </button>
              </div>
            </div>
            <template v-if="contentDetail.attribute">
              <div class="img-wrap" v-for="(item,index) in contentDetail.attribute.subThumbnailList">
                <img :src="`${$imagePath}${item.subThumbnail}`" alt="thumb" @click="handleClickSubThumbnail(index)">
              </div>
            </template>
            <div class="btm_desc" v-if="contentDetail.attribute">
              <p class="ctt ctt-t4">{{ contentDetail.attribute.description }}</p>
            </div>

            <div class="inspiration_gallery_hash m-ui" v-if="contentDetail.attribute">
              <ul>
                <template v-for="item in contentDetail.attribute.applicationList">
                  <li>
                    <div>
                      <a href="javascript:void(0)" @click="handleClcikBadge(item)" v-html="item.text"/>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </section>
          <section class="gv-side show" v-if="contentDetail.attribute">
            <div class="gv-side-wrap">
              <div class="gv-side-list">
                <template v-if="contentDetail.attribute" v-for="itemList in contentDetail.attribute.sideProductList">
                  <div class="gv-side-title_area">
                    <span class="gv-side-title" v-if="itemList[0].detail"
                          v-html="getCategoryName(itemList[0].detail.parents, 1)"/>
                    <span class="gv-side-txt" v-if="itemList[0].detail"
                          v-html="getCategoryName(itemList[0].detail.parents, 2)"/>
                  </div>
                  <ul>
                    <li v-for="(itemList, index) in itemList" v-bind:key="index">
                      <div class="thumb on" @click="moveToContentDetail(itemList)">
                        <a href="javascript:void(0)" v-if="itemList.detail">
                          <img :src="`${$imagePath}${itemList.detail.attribute.pf_thumb}`" alt="thumb">
                        </a>
                        <template v-if="itemList.detail">
                          <span class="tag"
                                v-if="itemList.detail.attribute.discontinued_color === 'true' ||
                                      itemList.detail.attribute.dictClr ==='true' "
                                v-html="'Discontinued'"/>
                        </template>
                      </div>

                      <div class="info">
                        <strong v-html="itemList.title"/>
                        <strong/>
                        <span/>
                        <!--                        <template v-if="itemList.detail">
                                                  <span v-html="itemList.detail.attribute.code"/>
                                                </template>-->
                      </div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </section>
        </article>
        <div class="inspiration_gallery_hash p-ui" v-if="contentDetail.attribute">
          <ul>
            <template v-for="item in contentDetail.attribute.applicationList">
              <li>
                <div>
                  <a href="javascript:void(0)" @click="handleClcikBadge(item)" v-html="item.text"/>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <!-- s: slide -->
        <template v-if="contentDetail.related">
          <div class="ins-box">
            <h2 class="prod-title">Related Contents</h2>
            <div class="related_slider">
              <VueSlickCarousel v-bind="slickSettings">
                <a href="javascript:void(0)" class="item" v-for="(item, index) in contentDetail.related"
                   v-if="index <= 2">
                  <div class="imgwrap" @click="handleClickrelatedContent(item.content)">
                    <img :src="`${$imagePath}${item.attribute.mainThumbnail}`" :alt="item.content.title"/>
                  </div>
                  <div class="txtwrap">
                    <ul>
                      <li class="type">
                        <template v-for="productItem in item.attribute.product_cateText">
                          <span v-html="productItem"/>
                        </template>
                      </li>
                      <li class="tit">
                        <span v-html="item.content.title"/>
                      </li>
                      <li class="tag">
                        <template v-for="appItem in item.attribute.applicationList">
                          <span v-html="`#${appItem.text}`"/>
                        </template>
                      </li>
                    </ul>
                  </div>
                </a>
              </VueSlickCarousel>
            </div>
          </div>
        </template>

        <!-- s: popup -->
        <transition name="fade">
          <div v-if="isLayerModal" class="layer-popup layer-gallery-img" :style="{display : 'block'}">
            <div class="popup pop-close">
              <div class="ins-box">
                <VueSlickCarousel v-bind="slickPopSettings" ref="slickModal">
                  <template v-if="contentDetail.attribute">
                    <div class="item">
                      <a href="javascript:void(0)">
                        <img :src="`${$imagePath}${contentDetail.attribute.mainThumbnail}`" alt="thumb">
                      </a>
                    </div>
                    <div class="item" v-for=" item in contentDetail.attribute.subThumbnailList">
                      <a href="javascript:void(0)">
                        <img :src="`${$imagePath}${item.subThumbnail}`" alt="thumb">
                      </a>
                    </div>
                  </template>
                </VueSlickCarousel>
              </div>
              <button type="button" @click="isLayerModal=false" class="btn-close"> close</button>
            </div>
          </div>
        </transition>
        <!-- e: popup -->
      </div>
      <SampleOrderPopComponent></SampleOrderPopComponent>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
    <!-- //content -->
  </div>
</template>

<script>
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import ShareComponent from '@/components/Layout/Share'
import SampleOrderPopComponent from '@/components/Popup/SampleOrder'
import InspirationService from '@/server/inspiration.service.js'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex'

export default {
  name: 'GalleryDetail',
  components: {
    HeaderComponent,
    QuickMenuComponent,
    ShortComponent,
    FooterComponent,
    GnbComponent,
    ShareComponent,
    SampleOrderPopComponent,
    VueSlickCarousel
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },

  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  data () {
    return {
      slug: '',
      cateCode: '',
      contentId: '',
      isLayerModal: false,
      contentDetail: {},
      categoryTree: [],
      selectMarkerNumber: null,
      countryCode: '',
      slickSettings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              variableWidth: true,
              infinite: false
            }
          }
        ]
      },
      slickPopSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        draggable: false
      },
      segmentsType: '',
      commercialList: [],
      residentialList: [],
      comparisonlist: []
    }
  },
  async created () {
    this.countryCode = this.$countryCode.slice(1)

    this.cateCode = this.countryCode + '_' + this.$route.meta.code
  },
  async mounted () {
    this.contentId = this.$route.query.contentId
    this.slug = this.$route.params.slug
    this.commercialList = await InspirationService.getDictionary(`INSP_IST_CMMR_${this.countryCode}`)
    this.residentialList = await InspirationService.getDictionary(`INSP_IST_RSDN_${this.countryCode}`)
    this.commercialList = this.commercialList.data.map((item) => ({
      id: item.text,
      type: 'Commercial'
    }))
    this.residentialList = this.residentialList.data.map((item) => ({
      id: item.text,
      type: 'Residential'
    }))
    this.comparisonlist = this.comparisonlist.concat(this.commercialList)
    this.comparisonlist = this.comparisonlist.concat(this.residentialList)

    await this.getDetail()
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    handleClickrelatedContent (content) {
      const { id } = content
      this.$router.push({ name: 'gallery-detail', query: { contentId: id } })
    },
    handleClcikBadge (item) {
      this.$router.push({
        name: 'inspirationGallery (seo)',
        params: { filter: item.id.value, filterType: 'applicationListSelected' }
      })
    },
    handleClickSubThumbnail (index) {
      this.isLayerModal = true
      this.$nextTick(function () {
        this.$refs.slickModal.goTo(index + 1)
      })
    },
    async getDetail () {
      const _this = this
      try {
        let data
        if ((isUndefined(_this.contentId) || isEmpty(_this.contentId)) && !isUndefined(_this.slug)) {
          const cateCode = _this.countryCode + '_' + _this.$route.meta.code
          const contentRes = await InspirationService.getInspirationDetailBySlugAndCategory(cateCode, encodeURIComponent(_this.slug))
          data = contentRes.data
        } else {
          const contentRes = await InspirationService.getInspirationDetail(_this.contentId)
          data = contentRes.data
        }

        const exceptList = ['applicationList', 'productList', 'subThumbnailList']
        Object.keys(data.attribute).forEach((item, index) => {
          if (exceptList.includes(item)) {
            data.attribute[item] = JSON.parse(data.attribute[item])
          }
        })
        console.log('data.attribute.productList', data.attribute.productList)
        if (data.attribute.productList !== undefined) {
          const markerIdList = data.attribute?.productList.map((item) => item.id)
          const res = await InspirationService.getContentByIds(markerIdList)

          data.attribute.sideProductList = data.attribute?.productList.reduce((result, item) => {
            const findItem = res.data.find((findItem) => findItem.content.id === item.id)
            item.detail = findItem
            item.detailName = _this.getCategoryName(findItem.parents, 1)
            result.push(item)
            return result
          }, [])
          data.attribute.sideProductList = _.groupBy(data.attribute?.productList, 'detailName')
        }

        const relatedRes = await InspirationService.getContentListTap([data.content.categoryId], {
          operator: 'AND',
          list: [{ operator: 'OR', values: [{ id: 'segments', value: data.attribute.segments }] }]
        }, '', 'search_text', 1, 4)
        relatedRes.data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            if (exceptList.includes(item)) {
              relatedRes.data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })

        data.related = relatedRes.data.list.filter((item) => item.content.id !== data.content.id)

        this.contentDetail = data
        this.segmentsType = this.comparisonlist.find((item) => item.id === this.contentDetail.attribute.segments)
      } catch (e) {
        console.error('inspirationGalleryDetailError : ', e)
        this.$router.push({ name: 'notFound' })
      } finally {

      }
    },
    getCategoryName (list, type) {
      let categoryName = ''
      let categorySideName = ''
      const categoryType = [
        'HFLOR',
        'HIMACS',
        'VIATERA',
        'TERACANTO',
        'BENIF',
        'VIZUON',
        'Deco Foil',
        'Exterior Foil',
        'BORTE'
      ]
      list?.forEach((item) => {
        if (categoryType.includes(item?.name)) {
          categoryName = item?.name
        }
        if (categoryType.includes(item?.name)) {
          const sideItem = {
            HFLOR: 'Resilient Flooring',
            HIMACS: 'Solid Surface',
            VIATERA: 'Quartz Surface',
            BENIF: 'Interior Film',
            VIZUON: 'Sign & Graphic'
          }
          categorySideName = sideItem[item?.name]
        }
      })
      return type === 1 ? categoryName : categorySideName
    },
    removeDuplicates (array) {
      return _.uniq(array)
    },
    moveToContentDetail (item) {
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      if (item.detail.attribute.discontinued_color === 'true' || item.detail.attribute.dictClr === 'true') {
        return false
      }

      const urlList = {
        HFLOR: 'hflor',
        HIMACS: 'himacs-solid-surface',
        VIATERA: 'viatera-quartz-surface',
        TERACANTO: 'teracanto-porcelain-surface',
        BENIF: 'benif-interior-film',
        VIZUON: 'Sign & Graphic',
        DecoFoil: 'deco-foil',
        ExteriorFoil: 'exterior-foil',
        BORTE: 'borte-premium-panel'
      }

      const hflorType = {
        luxuryvinyltile: 'lvt-flooring',
        homogeneoussheet: 'homogeneous-sheet',
        heterogeneoussheet: 'heterogeneous-sheet',
        looselay: 'looselay',
        spc: 'spc-click',
        specialty: 'specialty'
      }
      const vizuonType = {
        VehiclesandTransportation: 'vehicles-and-transportation',
        SignsandDisplays: 'signs-and-displays',
        IlluminatedSign: 'illuminated-sign',
        Architecture: 'architecture',
        CorporateBranding: 'corporate-branding'
      }

      const productType = this.getCategoryName(item.detail.parents, 1)
      const productTypeUrl = urlList[productType.replace(/\s+/g, '')]
      const title = item.title.toLowerCase()
      const id = item.id

      if (['HFLOR', 'VIZUON'].includes(productType)) {
        const productTypeObject = productType === 'HFLOR' ? hflorType : vizuonType
        let productTypeDetail = ''
        item.detail.parents.forEach((item) => {
          const itemName = item.name.replaceAll(' ', '').toLowerCase()
          if (productTypeObject[itemName] !== undefined) {
            productTypeDetail = productTypeObject[itemName]
          }
        })
        location.href = `/${countryCode}/products/${productTypeUrl}/${productTypeDetail}/${title}/${id}`
      } else {
        location.href = `/${countryCode}/products/${productTypeUrl}/${title}/${id}`
      }
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
