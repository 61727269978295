<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>AIR FREE</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
          <span class="checkbox_more no_line">
            <li v-for="airfree in airfreeList" :data-chk="airfree.id.value">
              <input type="checkbox" :id="'air_free_'+airfree.id.value" name="Airfree" v-model="selected" :value="airfree.id.value" />
              <label :for="'air_free_'+`${airfree.id.value}`">
              <span></span>
              {{ airfree.text }}
              </label>
            </li>
          </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'AirFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'airfreeList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-air-filter', this.removeAir)
    EventBus.$on('update-air-filters', this.updateAirs)
    if (this.$store.state.filterState && this.$store.state.filterState.air_free) {
      this.selected = this.$store.state.filterState.air_free
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-air-filter', this.removeAir)
    EventBus.$on('update-air-filters', this.updateAirs)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeAir (airValue) {
      this.selected = this.selected.filter(value => value !== airValue)
    },
    updateAirs (airs) {
      this.selected = airs
    },
  }
}
</script>
