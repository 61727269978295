/* ============
 * Mutations for the search module
 * ============
 *
 * The mutations that are available on the
 * search module.
 */

import {
  ADD_SEARCH_WORD,
  DELETE_SEARCH_WORD,
  ADD_SEARCH_HOME_WORD,
  DELETE_SEARCH_HOME_WORD
} from './mutation-types';

import getters from './getters';

/* eslint-disable no-param-reassign */
export default {
  [ADD_SEARCH_WORD](state, searchWord) {

    let searchWords = state.searchWords || [];
    if(!searchWords.includes(searchWord)) {
      searchWords.push(searchWord);
      while(searchWords.length > 10)
      {
        searchWords.shift()
      }

      localStorage.setItem('searchWord', JSON.stringify(searchWords));
      state.searchWords = searchWords;
    }
  },
  [DELETE_SEARCH_WORD](state, searchWord) {
    let searchWords = state.searchWords || [];
    searchWords = searchWords.filter(sw => sw !== searchWord)
    localStorage.setItem('searchWord', JSON.stringify(searchWords));
    state.searchWords = searchWords;
  },

  [ADD_SEARCH_HOME_WORD](state, searchWord) {

    let searchHomeWords = state.searchHomeWords || [];
    if(!searchHomeWords.includes(searchWord)) {
      searchHomeWords.push(searchWord);
      while(searchHomeWords.length > 10)
      {
        searchHomeWords.shift()
      }

      localStorage.setItem('searchHomeWords', JSON.stringify(searchHomeWords));
      state.searchHomeWords = searchHomeWords;
    }
  },
  [DELETE_SEARCH_HOME_WORD](state, searchWord) {
    let searchHomeWords = state.searchHomeWords || [];
    searchHomeWords = searchHomeWords.filter(sw => sw !== searchWord)
    localStorage.setItem('searchHomeWords', JSON.stringify(searchHomeWords));
    state.searchHomeWords = searchHomeWords;
  },
};
