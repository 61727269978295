<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content au-view glSite">
      <!-- container (global Business) -->
      <div class="container au-box glob-box">
        <div class="gloabalBuni visual-detail">
          <div class="visual-image full_width center">
            <div class="inner_width">
              <!-- location -->
              <div class="location type02">
                <ul>
                  <li class="ico-home">{{ $t('globalBusiness.text.home')}}</li>
                  <li>{{ $t('globalBusiness.text.about_us')}}</li>
                  <li>{{ $t('globalBusiness.text.overview')}}</li>
                  <li>{{ $t('globalBusiness.text.global_business')}}</li>
                </ul>
              </div>
              <!-- //location -->
              <h1 class="txt-box">
                <p class="img" v-if="nationCode == '_DE'"><img src="images/aboutus/about_global-business_DE.png" alt="globalbusiness"></p>
                <p class="img" v-else-if="nationCode == '_ES'"><img src="images/aboutus/about_global-business_ES.png" alt="globalbusiness"></p>
                <p class="img" v-else-if="nationCode == '_IT'"><img src="images/aboutus/about_global-business_IT.png" alt="globalbusiness"></p>
                <p class="img" v-else-if="nationCode == '_FR'"><img src="images/aboutus/about_global-business_FR.png" alt="globalbusiness"></p>
                <p class="img" v-else><img src="images/aboutus/about_global-business.png" alt="globalbusiness"></p>
              </h1>
            </div>
          </div>
        </div>

        <!-- 본문 시작 -->
        <div class="w-con-02 ba-text inner">
          <div class="t-list-box">
            <h2 class="tt-sub-gray ta-c">{{ $t('globalBusiness.text.tab_01_title')}}</h2>
            <p class="ctt ctt-t4 mb-64">{{ $t('globalBusiness.text.tab_01_brief')}}</p>
            <article class="">
                <p><img src="images/aboutus/map.png" alt="map"></p>
            </article>
          </div>
          <ul class="glmap-List frow frow-between">
            <li>
             <dl class="country">
               <dt>
                 <span class="gb_part02_circle">A</span>
                 {{ $t('globalBusiness.text.tab_01_info_01_A_title')}}
               </dt>
                <dd class="ctt ctt-t5">
                  {{ $t('globalBusiness.text.tab_01_info_01_A_brief')}}
                </dd>
             </dl>
            </li>
            <li>
             <dl class="country">
               <dt>
                 <span class="gb_part02_circle">B</span>
                 {{ $t('globalBusiness.text.tab_01_info_01_B_title')}}
               </dt>
                <dd class="ctt ctt-t5">
                  {{ $t('globalBusiness.text.tab_01_info_01_B_brief')}}
                </dd>
             </dl>
            </li>
            <li>
             <dl class="country">
               <dt>
                 <span class="gb_part02_circle">C</span>
                 {{ $t('globalBusiness.text.tab_01_info_01_C_title')}}
               </dt>
                <dd class="ctt ctt-t5">
                  {{ $t('globalBusiness.text.tab_01_info_01_C_brief')}}
                </dd>
             </dl>
            </li>
            <li>
             <dl class="country">
               <dt>
                 <span class="gb_part02_circle">D</span>
                 {{ $t('globalBusiness.text.tab_01_info_01_D_title')}}
               </dt>
                <dd class="ctt ctt-t5">
                  {{ $t('globalBusiness.text.tab_01_info_01_D_brief')}}
                </dd>
             </dl>
            </li>
            <!-- Other Regions -->
            <li>
             <dl class="country">
               <dt>
                 <span class="gb_part02_circle">E</span>
                 {{ $t('globalBusiness.text.tab_01_info_01_E_title')}}
               </dt>
                <dd class="ctt ctt-t5">
                  <!-- {{ $t('globalBusiness.text.tab_01_info_01_E_brief')}} -->
                  In emerging countries in the Middle East and Southeast Asia, acrylic solid surface, flooring, and industrial film businesses are growing in the market. LX Hausys provides products that meet the needs of various markets through differentiated marketing activities for each country.
                </dd>
             </dl>
            </li>
          </ul>

          <div class="glBuiz">
            <h3 class="tt-30">{{ $t('globalBusiness.text.tab_02_title')}}</h3>
            <div class="tableArea tbl-box type04  field-01">
              <table>
<!--                <caption>{{ $t('globalBusiness.text.tab_02_title')}}</caption>-->
                <colgroup>
                  <col style="width:30%">
                  <col style="width:auto%">
                </colgroup>
                <thead>
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_01_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_01_brief')}}</td>
                </tr>
                </thead>
                <tbody id="">
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_02_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_02_brief')}}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_03_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_03_brief')}}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_04_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_04_brief')}}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_05_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_05_brief')}}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_06_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_06_brief')}}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('globalBusiness.text.tab_02_info_07_title')}}</th>
                  <td class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_02_info_07_brief')}}</td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div class="glBuiz_imgbox">
              <img src="images/aboutus/img_global01.png" alt="global_business">
              <p class="ctt ctt-t3 ta-r" >{{ $t('globalBusiness.text.tab_03_title')}}</p>
          </div>

        </div>

        <div class="overseas">
          <h2 class="tt-sub-gray">{{ $t('globalBusiness.text.tab_04_title')}}</h2>

          <!-- start -->
          <div class="slide-wrapper slide-pd">
            <button class="arrow-prev">Prev</button>
            <button class="arrow-next">Next</button>
            <div class="slick-container">
              <!-- item -->
              <div class="slider">
                <div class="listU">
                  <section class="overseas-map">
                    <p class="ctt ctt-t2">{{ $t('globalBusiness.text.tab_04_info_01_title')}}</p>
                    <p class="sub-coment">{{ $t('globalBusiness.text.tab_04_info_01_brief')}}</p>
                    <p class="img"><img src="images/aboutus/us-overseas.png" alt="us-overseas"></p>
                  </section>
                  <section class="overseas-lis">
                    <dl>
                      <div class="ctt ctt-t3">{{ $t('globalBusiness.text.tab_05_title')}}</div>
                      <div class="overseas-line"></div>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_05_info_01_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_05_info_01_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_05_info_01_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_05_info_01_brief_03')}}
                      </dd>
                      <!-- <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_05_info_02_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_05_info_02_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_05_info_02_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_05_info_02_brief_03')}}
                      </dd> -->
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_05_info_03_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_05_info_03_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_05_info_03_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_05_info_03_brief_03')}}
                      </dd>
                    </dl>
                  </section>
                </div>
              </div>
              <!-- end item -->

                <!-- item -->
              <div class="slider">
                <div class="listU">
                  <section class="overseas-map">
                    <p class="ctt ctt-t2">{{ $t('globalBusiness.text.tab_04_info_02_title')}}</p>
                    <p class="sub-coment">{{ $t('globalBusiness.text.tab_04_info_02_brief')}}</p>
                    <p class="img"><img src="images/aboutus/eu-overseas.png" alt="eu-overseas"></p>
                  </section>
                  <section class="overseas-lis">
                    <dl>
                      <div class="ctt ctt-t3">{{ $t('globalBusiness.text.tab_06_title')}}</div>
                      <div class="overseas-line"></div>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_01_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_01_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_01_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_01_brief_03')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_02_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_02_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_02_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_02_brief_03')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_02_title_01')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_02_brief_01_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_02_brief_02_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_02_brief_03_01')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_03_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_03_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_03_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_03_brief_03')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_03_brief_04')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_04_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_04_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_04_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_04_brief_03')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_04_brief_04')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_05_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_05_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_05_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_05_brief_03')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_06_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_06_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_06_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_06_brief_03')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_06_brief_04')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_06_info_07_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_06_info_07_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_07_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_07_brief_03')}}<br>
                        {{ $t('globalBusiness.text.tab_06_info_07_brief_04')}}
                      </dd>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_07_info_02_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_07_info_02_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_07_info_02_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_07_info_02_brief_03')}}
                      </dd>
                    </dl>
                  </section>
                </div>
              </div>

                <!-- item -->
              <div class="slider">
                <div class="listU">
                  <section class="overseas-map">
                    <p class="ctt ctt-t2">{{ $t('globalBusiness.text.tab_04_info_03_title')}}</p>
                    <p class="sub-coment">{{ $t('globalBusiness.text.tab_04_info_03_brief')}} </p>
                    <p class="img"><img src="images/aboutus/china-overseas.png" alt="china-overseas"></p>
                  </section>
                  <section class="overseas-lis">
                    <dl>
                      <div class="ctt ctt-t3">{{ $t('globalBusiness.text.tab_07_title')}}</div>
                      <div class="overseas-line"></div>
                      <dt class="ctt ctt-t4">{{ $t('globalBusiness.text.tab_07_info_01_title')}}</dt>
                      <dd class="ctt ctt-t5">
                        {{ $t('globalBusiness.text.tab_07_info_01_brief_01')}}<br>
                        {{ $t('globalBusiness.text.tab_07_info_01_brief_02')}}<br>
                        {{ $t('globalBusiness.text.tab_07_info_01_brief_03')}}<br>
                        {{ $t('globalBusiness.text.tab_07_info_01_brief_04')}}
                      </dd>
                      <dt class="ctt ctt-t4">Australia</dt>
                      <dd class="ctt ctt-t5">
                        BLACK FOX GROUP PTY LTD.<br>
                        17 James Street, South Windsor NSW, Australia<br>
                        tel. 61-438-532-226
                      </dd>
                      <dt class="ctt ctt-t4">Taiwan</dt>
                      <dd class="ctt ctt-t5">
                        TAODE ENTERPRISE LTD.<br>
                        1F., No. 25, Ln. 101, Sec. 2, Roosevelt Rd., Da’an Dist., Taipei City<br>
                        , Taiwan (ZIP code: 106012)<br>
                        tel: 886-2-2368-6618
                      </dd>
                      <dt class="ctt ctt-t4">Thailand</dt>
                      <dd class="ctt ctt-t5">
                        CK Coopperation Co., Ltd<br>
                        77/3 Village No.4, Bueng Kham Phroi Sub-District, Lam Luk Ka District,<br>
                        Pathum Thani Province 12150, Thailand<br>
                        tel. 6695-7982565
                      </dd>
                      <dt class="ctt ctt-t4">Vietnam</dt>
                      <dd class="ctt ctt-t5">
                        TARA VIET NAM CONSULTING AND DEVELOPMENT.,LTD<br>
                        Room 302, Sunlight house building, 12 street 1,<br>
                        Tan Phu ward, District 7, Ho Chi Minh city, Viet Nam<br>
                        tel. 84-28-36200047 (Office)<br>
                        tel. 84-9762-10411 (Mobile)
                      </dd>
                    </dl>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
      <!-- //container (global Business) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    GnbComponent,
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      categoryId: [],
      globalBusiness: '',
      nationCode: ''
    }
  },
  async mounted () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */

    const _this = this
    this.$nextTick(function () {
      $('.slide-pd .slick-container').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        draggable: false,
        // centerPadding: '35px',
        prevArrow: $('.slide-pd .arrow-prev'),
		      nextArrow: $('.slide-pd .arrow-next'),
        responsive: [

          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      })
    })
  },
  methods: {
  }
}
</script>
<style scoped>
.wrapper-cokkie  {

    display: none !important;
}
</style>
