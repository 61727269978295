<template>
  <div class="social-responsibility-table-body-category">
    <pre class="text">{{ text }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }
}
</script>

<style>
.social-responsibility-table-body-category {
  background: #F6F6F6;
  align-items: center;
  display: flex;
  width: 100%;
}

.social-responsibility-table-body-category .text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  margin-left: 15px;
}
</style>
