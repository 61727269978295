<template>
<div class="overview-big-card-icon-area-root">
    <div class="overview-big-card-icon-area">
        <div class="overview-big-card-area">
            <div class="text-area">
                <div class="title-area">
                    {{ title1 }}
                </div>
                <div class="main-text-area">
                    {{ mainTxt1 }}
                </div>
            </div>
            <div class="line"></div>
            <div class="text-area">
                <div class="title-area">
                    {{ title2 }}
                </div>
                <div class="main-text-area">
                    {{ mainTxt2 }}
                </div>
            </div>
        </div>

        <div class="icon-area">
            <img :src="iconSrc" />
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    title1: {
      type: String,
      default: ''
    },
    mainTxt1: {
      type: String,
      default: ''
    },
    title2: {
      type: String,
      default: ''
    },
    mainTxt2: {
      type: String,
      default: ''
    },
    iconSrc: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.overview-big-card-icon-area-root {
    display: flex;
    width: 1440px;
    padding: 50px 0px 50px 90px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border: 5px solid #A09C9B;
}

@media (max-width: 768px) {
    .overview-big-card-icon-area-root {
        width: 100%;
        padding-left: 0px;
        align-items: center;
    }
}

.overview-big-card-icon-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
}

@media (max-width: 768px) {
    .overview-big-card-icon-area {
        align-items: center;
    }
}

.overview-big-card-area {
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
}

@media (max-width: 768px) {
    .overview-big-card-area {
        flex-direction: column;
        align-items: center;
    }
}

.text-area {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    height: 85px;
    width: auto;
}

@media (max-width: 768px) {
    .text-area {
        align-items: center;
        height: 100%;
    }
}

.line {
  border: 1px solid #D0CECD;
  width: 1px;
  height: 67px;
  margin-left: 79px;
  margin-right: 79px;
}

@media (max-width: 768px) {
    .line {
        width: 50%;
        height: 1px;
        margin: 15% 0px;
    }
}

.title-area {
    color: #222;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .title-area {
        text-align: center;
        font-size: 1.46rem;
        white-space: pre-wrap;
    }
}

.main-text-area {
    color: #AA2D29;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
}

@media (max-width: 768px) {
    .main-text-area {
        text-align: center;
        font-size: 1.7rem;
    }
}

.icon-area {
    display: flex;
    width: 140px;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 490px;
}
:lang(ru) .icon-area {
    display: flex;
    width: 140px;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 300px;
}

@media (max-width: 768px) {
    .icon-area {
        margin-left: 5%;
        width: 120px;
    }
}
</style>
