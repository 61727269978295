<template>
  <OverviewSection
    title="Major Design Performance"
    color="#222"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData1.icon"
        :headerText="cardData1.headerText"
        :pictures="cardData1.pictures"
        :texts="cardData1.texts"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData2.icon"
        :headerText="cardData2.headerText"
        :pictures="cardData2.pictures"
        :texts="cardData2.texts"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="主要な設計パフォーマンス"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData1Jp.icon"
        :headerText="cardData1Jp.headerText"
        :pictures="cardData1Jp.pictures"
        :texts="cardData1Jp.texts"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData2Jp.icon"
        :headerText="cardData2Jp.headerText"
        :pictures="cardData2Jp.pictures"
        :texts="cardData2Jp.texts"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Основные характеристики дизайна"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData1Ru.icon"
        :headerText="cardData1Ru.headerText"
        :pictures="cardData1Ru.pictures"
        :texts="cardData1Ru.texts"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData2Ru.icon"
        :headerText="cardData2Ru.headerText"
        :pictures="cardData2Ru.pictures"
        :texts="cardData2Ru.texts"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="主要设计性能"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData1Cn.icon"
        :headerText="cardData1Cn.headerText"
        :pictures="cardData1Cn.pictures"
        :texts="cardData1Cn.texts"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <MajorDesignPerformanceCard
        :icon="cardData2Cn.icon"
        :headerText="cardData2Cn.headerText"
        :pictures="cardData2Cn.pictures"
        :texts="cardData2Cn.texts"
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import MajorDesignPerformanceCard from './MajorDesignPerformanceCard.vue'
export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    MajorDesignPerformanceCard
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardData1: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon1.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/quartz.png',
          'images/aboutus/r-and-d-center/winning-design-awards/door-handles.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wallpaper.png'
        ],
        texts: [
          'Quartz, an engineered stone widely used for countertops, lacks the versatility in surface finishing that natural stone possesses.\n' +
          'Unlike natural stone, which easily takes on various finishing techniques and color patterns, quartz struggles with this aspect.\n' +
          'To tackle this limitation, this design incorporates a synchronized embossed pattern on the quartz surface, creating a natural stone-like appearance.',

          'THE LEVER represents a user-friendly handle designed specifically for sliding interior doors. It enables effortless door opening through leverage,\n' +
          'requiring minimal force. Crafted from a singular aluminum unibody stick, the design emphasizes simplicity and a sleek profile.\n' +
          'The handle is available in four colors, harmonizing with the door\'s hue to maintain consistent interior aesthetics.',

          'This sample book for wallpapers is user-friendly and customer-focused, aiming to showcase interior decor concepts along with space and color combinations.\n' +
          'The guiding theme "Enjoy at home" encourages individuals to embellish their living spaces, which remain a sanctuary from COVID-19.\n' +
          'The book introduces an innovative approach to presenting wallpaper samples, aiding consumers in effortlessly choosing products and design themes for their\n' +
          'home interiors. Within the design style sections, designer suggestions and popular color choices are highlighted, offering insight into contemporary trends.'
        ]
      },
      cardData1Jp: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon1.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/quartz.png',
          'images/aboutus/r-and-d-center/winning-design-awards/door-handles.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wallpaper.png'
        ],
        texts: [
          'カウンタートップに広く使用されている人工石であるクォーツには、天然石が持つ表面仕上げの汎用性がありません。\n' +
          'さまざまな仕上げ技術や色パターンを簡単に取り入れることができる天然石とは異なり、クォーツはこの点で苦労しています。\n' +
          'この制限に対処するために、このデザインではクォーツの表面に同期されたエンボスパターンを組み込み、天然石のような外観を作り出しています。',

          'THE LEVER は、室内の引き戸用に特別に設計された、使いやすいハンドルです。てこの力で簡単にドアを開けることができ、最小限の力で済みます。\n' +
          'アルミニウム製の一体型スティックで作られたこのデザインは、シンプルさと洗練された外観を強調しています。\n' +
          'ハンドルは 4 色から選択でき、ドアの色調と調和して、室内の美しさを一定に保ちます。',

          'この壁紙のサンプルブックは、ユーザーフレンドリーで顧客中心であり、空間と色の組み合わせとともにインテリア装飾のコンセプトを紹介することを目的としています。\n' +
          '「家で楽しむ」というテーマは、COVID-19から逃れられる聖域である生活空間を飾ることを個人に奨励しています。\n' +
          'この本は、壁紙のサンプルを提示するための革新的なアプローチを紹介し、消費者が家のインテリア用の製品とデザインテーマを簡単に選択できるように支援します。\n' +
          'デザインスタイルのセクションでは、デザイナーの提案と人気の色の選択が強調されており、現代のトレンドについての予想も提供します。'
        ]
      },
      cardData1Ru: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon1.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/quartz.png',
          'images/aboutus/r-and-d-center/winning-design-awards/door-handles.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wallpaper.png'
        ],
        texts: [
          'Кварц, искусственный камень, широко используемый для изготовления столешниц, не обладает той универсальностью в отделке поверхности,\n' +
          'которой обладает натуральный камень. В отличие от натурального камня, который легко принимает различные техники отделки и цветовые узоры, \n' +
          'при применении кварца возникнут трудности в этом аспекте.Чтобы решить эту проблему,\n' +
          'в данном дизайне на поверхность кварца нанесен синхронизированный рельефный рисунок, создающий впечатление натурального камня.',

          'THE LEVER представляет собой удобную ручку, разработанную специально для раздвижных межкомнатных дверей.\n' +
          'Она обеспечивает легкое открывание двери за счет рычага,требуя минимального усилия.\n' +
          'Изготовленная из цельного алюминиевого стержня, конструкция подчеркивает простоту и гладкий профиль.\n' +
          'Ручка доступна в четырех цветах, гармонично сочетающихся с оттенком двери, что позволяет сохранить эстетику интерьера.',

          'Этот каталог удобен и ориентирован на клиента. Он демонстрирует концепции декора интерьера, а также сочетания пространства и цветов.\n' +
          'Основная тематика «Наслаждайтесь дома» побуждает людей украшать свои жилые помещения, которые были убежищем в COVID-19.\n' +
          'Каталог представляет инновационный подход к представлению образцов обоев, помогая потребителям без труда выбрать продукцию и дизайн для своих\n' +
          'домашних интерьеров. В разделах, посвященных стилям оформления, выделены предложения дизайнеров и популярные цветовые решения,\n' +
          'что дает представление о современных тенденциях.'
        ]
      },
      cardData1Cn: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon1.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/quartz.png',
          'images/aboutus/r-and-d-center/winning-design-awards/door-handles.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wallpaper.png'
        ],
        texts: [
          '石英是一种广泛用于台面的工程石材，在表面处理方面缺乏天然石材的多功能性。\n' +
          '与天然石材不同，天然石材很容易采用各种精加工技术和颜色图案，石英在这方面很困难。\n' +
          '为了解决这一限制，这种设计在石英表面上采用了同步的压花图案，创造了一种天然的石头般的外观。',

          '“THE LEVER代表了一种用户友好的手柄，专为滑动式室内门而设计。它通过杠杆作用轻松打开门，\n' +
          '只需要最小的力。该设计由一个独特的铝制一体式杆制成，强调简洁和光滑的轮廓。\n' +
          '把手有四种颜色可供选择，与门的色调相协调，以保持一致的内部美学。',

          '用户友好型墙纸样本书，以客户为中心，旨在展示室内装饰概念以及空间和颜色组合。\n' +
          '指导主题“在家享受”鼓励个人装饰自己的生活空间，这仍然是新冠肺炎的避难所。\n' +
          '这本书介绍了一种展示墙纸样品的创新方法，帮助消费者毫不费力地为他们的家居选择产品和设计主题。\n' +
          '在设计风格部分，突出了设计师的建议和流行的颜色选择，提供了对当代趋势的洞察。'
        ]
      },
      cardData2: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon2.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/ceramic.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wood-metal.png'
        ],
        texts: [
          'These premium vinyl tiles boast a captivating design that prioritizes both aesthetics and long-lasting performance. Heritage Ceramic offers a contemporary\n' +
          'industrial appearance, making it an excellent choice for commercial spaces like restaurants and shops. Leveraging the innovative C-EIR technology,\n' +
          'these tiles can showcase multiple ceramic-inspired patterns and textures on a single tile. Their versatile arrangement at different angles produces\n' +
          'contrasts that enhance the interplay of matte and glossy finishes. These effects are further accentuated when illuminated by natural light.',

          'Artistry presents itself as a sophisticated vinyl tile flooring option that mimics the appearance of handcrafted wood and metal pieces of varying shapes and sizes.\n' +
          'Employing the manufacturer\'s exclusive advanced method called Continuous EIR (Embossed in Register), Artistry achieves lifelike visuals and textures.\n' +
          'Through C-EIR, texture is seamlessly applied to the printed design using an embossment cylinder, resulting in a consistent yet seemingly random pattern\n' +
          'that authentically captures a natural essence.'
        ]
      },
      cardData2Jp: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon2.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/ceramic.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wood-metal.png'
        ],
        texts: [
          'これらの高級ビニール タイルは、美しさと長持ちする性能の両方を優先した魅力的なデザインを誇ります。Heritage Ceramic は、\n' +
          '現代的で工業的な外観を提供し、レストランやショップなどの商業スペースに最適です。革新的な C-EIR テクノロジーを活用したこれらのタイルは、\n' +
          '1 つのタイルに複数のセラミック風のパターンとテクスチャを表現できます。さまざまな角度で多様に配置することで、\n' +
          'マット仕上げと光沢仕上げの相互作用を高めるコントラストが生まれます。これらの効果は、自然光で照らされるとさらに強調されます。',

          'Artistry は、さまざまな形や大きさの手作りの木材や金属片の外観を模倣した、洗練されたビニール タイル フローリング オプションです。\n' +
          'メーカー独自の高度な手法である Continuous EIR (Embossed in Register) を採用した Artistry は、リアルなビジュアルとテクスチャを実現します。\n' +
          'C-EIR により、エンボス シリンダーを使用してテクスチャが印刷されたデザインにシームレスに適用され、\n' +
          '一貫性がありながらも一見ランダムなパターンが生まれ、自然のエッセンスを忠実に捉えます。'
        ]
      },
      cardData2Ru: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon2.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/ceramic.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wood-metal.png'
        ],
        texts: [
          'Эта виниловая плитка премиум-класса может похвастаться захватывающим дизайном, в котором приоритет отдается эстетике и долговечности.\n' +
          'Heritage Ceramic предлагает современный Керамическая плитка Heritage Ceramic имеет современный индустриальный вид,\n' +
          'таких как рестораны и магазины. Благодаря инновационной технологии C-EIR, эта плитка может демонстрировать несколько узоров и текстур,\n' +
          'вдохновленных керамикой, на одной плитке. Их разнообразное расположение под разными углами создает контрасты, \n' +
          'которые усиливают взаимодействие матовых и глянцевых поверхностей. Эти эффекты еще больше подчеркиваются при естественном освещении.',

          'Artistry - это изысканная виниловая плитка, имитирующая деревянные и металлические изделия ручной работы различных форм и размеров.\n' +
          'Используя эксклюзивный передовой метод производителя под названием Continuous EIR (Embossed in Register),\n' +
          'Artistry достигает реалистичных визуальных эффектов и текстур. Благодаря C-EIR текстура плавно наносится на напечатанный дизайн с помощью\n' +
          'цилиндра для тиснения, в результате чего получается последовательный, но кажущийся случайным узор,\n' +
          'который достоверно передает природную сущность.'
        ]
      },
      cardData2Cn: {
        icon: 'images/aboutus/r-and-d-center/winning-design-awards/major-design-performance-icon2.png',
        pictures: [
          'images/aboutus/r-and-d-center/winning-design-awards/ceramic.png',
          'images/aboutus/r-and-d-center/winning-design-awards/wood-metal.png'
        ],
        texts: [
          '这些优质乙烯基瓷砖拥有迷人的设计，既注重美观，又注重持久性能。\n' +
          'Heritage Ceramic具有现代工业外观，是餐厅和商店等商业空间的绝佳选择。利用创新的C-EIR技术，\n' +
          '这些瓷砖可以在一块瓷砖上展示多种陶瓷风格的图案和纹理。它们在不同角度的多功能排列产生了对比，\n' +
          '增强了哑光和光泽饰面的相互作用。当自然光照射时，这些效果会进一步突出。',

          'Artistry将自己呈现为一种复杂的乙烯基瓷砖地板选择，模仿不同形状和尺寸的手工木材和金属件的外观。\n' +
          'Artistry采用制造商独有的先进方法Continuous EIR（压印在寄存器中），实现了逼真的视觉效果和纹理。\n' +
          '通过C-EIR，使用浮雕圆柱体将纹理无缝地应用于印刷设计，从而形成一致但看似随机的图案，真实地捕捉到自然的精髓。'
        ]
      },
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>

</style>
