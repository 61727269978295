<template>
  <div class="mt-100">
    <ProductSectionHeader
      class="mt-100"
      title="Seat Cover"
      description="LX Hausys automotive skin delivers innovative designs through our best-in-class technology so as to bring emotional qualities to the indoor driving space."
    />

    <ProductTab
      class="mt-50"
      :item="[
        { title: 'SOFINO®', component: 'SOFINO' },
        { title: 'VERNO®', component: 'VERNO' },
        { title: 'Calfpelle®', component: 'Calfpelle' },
        { title: 'LUSSINO™', component: 'LUSSINO' },
      ]"
    >
      <template #SOFINO>
        <Sofino></Sofino>
      </template>

      <template #VERNO>
        <Verno></Verno>
      </template>

      <template #Calfpelle>
        <Calfpelle></Calfpelle>
      </template>

      <template #LUSSINO>
        <Lussino></Lussino>
      </template>
    </ProductTab>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductTab from '@/components/product/tab'
import Sofino from './seatCorver/Sofino'
import Verno from './seatCorver/Verno'
import Calfpelle from './seatCorver/calfpelle.vue'
import Lussino from './seatCorver/Lussino'

export default {
  components: {
    ProductSectionHeader,
    ProductTab,
    Sofino,
    Verno,
    Calfpelle,
    Lussino
  }
}
</script>

<style scoped>
</style>
