<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>COLLECTION</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
        <span class="checkbox_more no_line">
          <li v-for="coll in filteredCollectionList" :key="coll.category.id">
            <input
              type="checkbox"
              :id="coll.category.name.replaceAll(' ', '_').toLowerCase()"
              name="collection"
              v-model="selected"
              :value="coll.category.id"
            />
            <label :for="coll.category.name.replaceAll(' ', '_').toLowerCase()">
              <span></span>
              {{ coll.category.name }}
            </label>
          </li>
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'CollectionFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['collectionList', 'value', 'category'],
  data () {
    return {
      selected: [],
      previousCategory: null
    }
  },
  computed: {
    filteredCollectionList() {
      if (!this.category || !this.category.code) {
        return this.collectionList
      }
      if (this.category.code.includes('PRD_FLOORING') || this.category.code.includes('COLOR')) {
        return this.collectionList
      }

      const isUS = this.$countryCode === '_US'
      const isGL = this.$countryCode === '_GL'

      if (this.category.code.includes('FLOOR_LOS')) {
        if (isUS) {
          // US일 때는 FLOOR_LOS_STYLE_US만 표시
          return this.collectionList.filter(coll =>
            coll.category.code.includes('FLOOR_LOS_STYLE_US')
          )
        } else if (isGL) {
          // GL일 때는 FLOOR_LUX_DECOLEY_GL만 표시
          return this.collectionList.filter(coll =>
            coll.category.code.includes('FLOOR_LUX_DECOLEY_GL')
          )
        }
      }

      if (this.category.code.includes('FLOOR_LUX')) {
        // FLOOR_LUX일 때는 DECOLAY를 제외한 모든 FLOOR_LUX 관련 컬렉션 표시
        return this.collectionList.filter(coll =>
          coll.category.code.includes('FLOOR_LUX') &&
          !coll.category.code.includes('FLOOR_LUX_DECOLEY')
        )
      }

      // 그 외의 경우 기존 로직 유지
      return this.collectionList.filter(coll =>
        coll.category.code.includes(this.category.code.replace(this.$countryCode, ''))
      )
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    },
    category: {
      handler (newCategory, oldCategory) {
        if (this.previousCategory && this.previousCategory.code !== newCategory.code) {
          // 카테고리가 변경되면 selected 초기화
          this.selected = []
          this.$emit('input', [])
        }
        this.previousCategory = newCategory
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.selected = this.value
    this.previousCategory = this.category

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-collection-filter', this.removeCollection)
    EventBus.$on('update-collection-filters', this.updateCollections)

    if (this.$store.state.filterState && this.$store.state.filterState.collection) {
      this.selected = this.$store.state.filterState.collection
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$off('remove-collection-filter', this.removeCollection)
    EventBus.$off('update-collection-filters', this.updateCollections)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
      this.$emit('input', [])
    },
    removeCollection (collectionId) {
      this.selected = this.selected.filter(id => id !== collectionId)
      this.$emit('input', this.selected)
    },
    updateCollections (collections) {
      this.selected = collections
      this.$emit('input', collections)
    }
  }
}
</script>
