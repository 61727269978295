<template>
  <div id="wrap" class="himac">
    <div class="w-con-02">
      <div class="sub-visual-section">
        <h1 class="tt-1 visual-title tac">{{ $t("contactus.text.contactus") }}</h1>
        <p class="ctt ctt-t2 visual-desc tac inner glSite" v-html="$t('contactus.text.inner')"></p>
      </div>

      <ContactusEUForm></ContactusEUForm>
    </div>
  </div>
</template>

<script>
import ContactusEUForm from './contactus_EUForm'

export default {
  name: 'contactus_EU',
  components: {
    ContactusEUForm
  },
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  async mounted () {
  },
  methods: {

  }
}
</script>
