<template>
  <div class="tab-con on" v-if="tabCount > 0">

    <!-- HFLOR -->
    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt">
        <span>HFLOR (<em>{{FLOOR.totalCount}}</em>)</span>
      </div>
      <div class="list-search-collection">
        <ul v-if="FLOOR && FLOOR.totalCount > 0">
          <li class="search-collection-cont-box" v-for="sr in FLOOR.list">
            <div class="img-box">
              <a href="javascript:void(0);" @click="gotoHflorDetail(sr)"><img :src="$imagePath + sr.cover" :alt="sr.title"></a>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01"><a href="javascript:void(0);">[<span>{{sr.categories[1].name}}</span>] <span>{{sr.title}}</span></a></li>
                <li class="txt-02">
                  <a href="javascript:void(0);" @click="gotoHflorDetail(sr)">{{ $t('learn_more_collection') }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!FLOOR.lastPage">
        <button type="button" class="btn btn-arr-down" @click="loadMoreHflor()">
          <span>{{ $t('see_more')}}</span>
        </button>
      </div>
    </div>

    <!-- Viatera -->
    <div class="sch-res-prod prod-fd-view"  v-if="showViatera()">
      <div class="res-prod-cnt">
        <span>VIATERA (<em>{{VIATERA.totalCount}}</em>)</span>
      </div>
      <div class="list-search-collection">
        <ul v-if="VIATERA && VIATERA.totalCount > 0">
          <li class="search-collection-cont-box" v-for="sr in VIATERA.list">
            <div class="img-box">
              <a href="javascript:void(0);" @click="gotoViateraDetail(sr)"><img v-if="sr.cover" :src="$imagePath + sr.cover" :alt="sr.title"></a>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01"><a href="javascript:void(0);">[<span>{{sr.categories[1].name}}</span>] <span>{{sr.title}}</span></a></li>
                <li class="txt-02"><a href="javascript:void(0);" @click="gotoViateraDetail(sr)">{{ $t('see_all_colors') }}</a><a href="javascript:void(0);" @click="gotoViateraDetail(sr)">{{ $t('learn_more_collection') }}</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!VIATERA.lastPage">
        <button type="button" class="btn btn-arr-down" @click="loadMoreViatera()">
          <span>{{ $t('see_more')}}</span>
        </button>
      </div>
    </div>

    <!-- HIMACS -->
    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt">
        <span>HIMACS (<em>{{HIMACS.totalCount}}</em>)</span>
      </div>
      <div class="list-search-collection">
        <ul v-if="HIMACS && HIMACS.totalCount > 0">
          <li class="search-collection-cont-box" v-for="sr in HIMACS.list">
            <div class="img-box">
              <a href="javascript:void(0);" @click="gotoHimacsDetail(sr)"><img :src="$imagePath + sr.cover" :alt="sr.title"></a>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01"><a href="javascript:void(0);">[<span>{{sr.categories[1].name}}</span>] <span>{{sr.title}}</span></a></li>
                <li class="txt-02"><a href="javascript:void(0);" @click="gotoHimacsDetail(sr)">{{ $t('see_all_colors')}}</a><a href="javascript:void(0);" @click="gotoHimacsDetail(sr)">{{ $t('learn_more_collection')}}</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!HIMACS.lastPage">
        <button type="button" class="btn btn-arr-down" @click="loadMoreHimacs()">
          <span>{{ $t('see_more')}}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="no-found-wrap bd-top" v-else>
    <div class="tac">
      <i class="icon-no-result"><img src="images/icon-no-result-found.png" alt="no result"></i>
      <div class="msg">
        <ul>
          <li class="ctt ctt-t1 result-tit">No Result Found</li>
          <li class="ctt ctt-t4">Try again using more general search keywords</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'

import { mapState } from 'vuex'

export default {
  name: 'SearchCollection',
  props: ['searchResult', 'searchWord'],
  watch: {
    searchResult () {
      this.initSearchResult()
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  data () {
    return {
      tabCount: 0,
      seoCheck: false,
      nationCode: '',
      FLOOR: { totalCount: 0 },
      HIMACS: { totalCount: 0 },
      VIATERA: { totalCount: 0 }
    }
  },
  async mounted () {
    const _this = this
    _this.nationCode = _this.$countryCode
    if (_this.nationCode == '_US' || _this.nationCode == '_GL') {
      _this.seoCheck = true
    }
    this.initSearchResult()
  },
  methods: {
    showViatera () {
      const country = this.$countryCode.substring(1)
      return country == 'GL' || country == 'US'
    },
    gotoHimacsDetail (c) {
      if (this.seoCheck) {
        const filtered1 = c.title.replace(/\s/gi, '-')
        const filtered2 = filtered1.toLowerCase()
        document.location.href = `products/himacs-solid-surface/himacs-finder/filter/collection/${filtered2}`
      } else {
        document.location.href = `products/himacs/himacsFinder/filter/collection/${c.title}`
      }
    },
    loadMoreHimacs () {
      this.loadMore('HIMACS')
    },
    gotoViateraDetail (c) {
      if (this.seoCheck) {
        const filtered1 = c.title.replace(/\s/gi, '-')
        const filtered2 = filtered1.toLowerCase()
        document.location.href = `products/viatera-quartz-surface/viatera-finder/filter/collection/${filtered2}`
      } else {
        document.location.href = `products/viatera/viaterafinder/filter/collection/${c.title}`
      }
    },
    loadMoreViatera () {
      this.loadMore('VIATERA')
    },
    gotoHflorDetail (c) {
      let code = c.code.substr(0, c.code.lastIndexOf('_'))
      code = code.substr(0, code.lastIndexOf('_'))
      if (this.seoCheck) {
        const filtered1 = c.title.replace(/\s/gi, '-')
        const filtered2 = filtered1.toLowerCase()
        let main_collectionNm = ''
        if (code === 'FLOOR_LUX') {
          main_collectionNm = 'lvt-flooring'
        } else if (code === 'FLOOR_HOM') {
          main_collectionNm = 'homogeneous-sheet'
        } else if (code === 'FLOOR_HET') {
          main_collectionNm = 'heterogeneous-sheet'
        } else {
          main_collectionNm = 'sports-flooring'
        }
        document.location.href = `products/hflor/hflor-finder/${main_collectionNm}/filter/collection/${filtered2}`
      } else {
        document.location.href = `products/hflor/hFlorFinder/${code}/filter/collection/${c.code}`
      }
    },
    loadMoreHflor () {
      this.loadMore('FLOOR')
    },
    async loadMore (type) {
      const search = this.searchResult[type]
      const pageIndex = search.pageIndex + 1
      const pageSize = search.pageSize
      const s = this.searchWord
      const r = this.$countryCode.substring(1)

      const { data } = await SearchService.collectionByType({ pageIndex, pageSize, s, r, type })

      search.lastPage = data.lastPage
      search.pageIndex = data.pageIndex
      search.list.push(...data.list)
    },
    initSearchResult () {
      this.FLOOR = this.searchResult.FLOOR
      this.HIMACS = this.searchResult.HIMACS
      this.VIATERA = this.searchResult.VIATERA
      this.tabCount = this.searchResult.FLOOR.totalCount + this.searchResult.HIMACS.totalCount + this.searchResult.VIATERA.totalCount
    }
  }
}
</script>
