<template>
    <table>
        <thead>
            <tr>
                <th><div class="header">{{ title }}</div></th>
            </tr>
        </thead>
       <tbody>
            <tr>
                <td>
                    <div class="table-body">
                         <ul>
                            <li v-for="item in textList1" :key="item">{{ item }}</li>
                        </ul>
                    </div>
                </td>
            </tr>
             <tr>
                <td>
                    <div class="table-body">
                        <ul>
                            <li v-for="item in textList2" :key="item">{{ item }}</li>
                        </ul>
                    </div>
            </td>
            </tr>
            <tr>
                <td>
                    <div class="table-body">
                        <ul>
                            <li v-for="item in textList3" :key="item">{{ item }}</li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    textList1: {
      type: Array,
      default: () => []
    },
    textList2: {
      type: Array,
      default: () => []
    },
    textList3: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
table {
    display: inline;
}

thead {
    border-top: 2px solid #000;
    border-bottom: 1px solid #000;
}

.header {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;
    text-align: center;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}

td {
    border-bottom: 1px solid #EAEAEA;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
}

.table-body {
    display: flex;
    align-items: center;
    height: 114px;
    width: 642px;
}

li {
    color: #000;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    position: relative;
    margin-left: 30px;
}

li::before {
    content: "•";
    color: #000;
    margin-right: 5px;
}

</style>
