var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "Innovation for Customers, ESG for the Future",
            titleMarginTop: "54px",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "esg-vision" }, [
                      _c("img", {
                        attrs: {
                          src: "images/aboutus/sustainability/esg_strategy_system/esg_vision.png",
                          alt: "img",
                        },
                      }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            22525388
          ),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("StrategicDirections", [
                _c(
                  "div",
                  { staticClass: "contents-area" },
                  _vm._l(_vm.cardData, function (it, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "contents" },
                      [
                        _c("EsgImageCard", {
                          attrs: {
                            firstTitle: it.firstTitle,
                            title: it.title,
                            content: it.content,
                            iconSrc: it.iconSrc,
                          },
                        }),
                        _vm._l(it.textCardData, function (item, index) {
                          return _c("EsgTextCard", {
                            key: index,
                            attrs: { text: item.text },
                          })
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "顧客のためのイノベーション、未来のためのESG",
            titleMarginTop: "54px",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "esg-vision" }, [
                    _c("img", {
                      attrs: {
                        src: "images/aboutus/sustainability/esg_strategy_system/esg_vision.png",
                        alt: "img",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("StrategicDirections", [
                _c(
                  "div",
                  { staticClass: "contents-area" },
                  _vm._l(_vm.cardDataJp, function (it, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "contents" },
                      [
                        _c("EsgImageCard", {
                          attrs: {
                            firstTitle: it.firstTitle,
                            title: it.title,
                            content: it.content,
                            iconSrc: it.iconSrc,
                          },
                        }),
                        _vm._l(it.textCardData, function (item, index) {
                          return _c("EsgTextCard", {
                            key: index,
                            attrs: { text: item.text },
                          })
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "Инновации для клиентов, ESG для будущего",
            titleMarginTop: "54px",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "esg-vision" }, [
                    _c("img", {
                      attrs: {
                        src: "images/aboutus/sustainability/esg_strategy_system/esg_vision.png",
                        alt: "img",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("StrategicDirections", [
                _c(
                  "div",
                  { staticClass: "contents-area" },
                  _vm._l(_vm.cardDataRu, function (it, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "contents" },
                      [
                        _c("EsgImageCard", {
                          attrs: {
                            firstTitle: it.firstTitle,
                            title: it.title,
                            content: it.content,
                            iconSrc: it.iconSrc,
                          },
                        }),
                        _vm._l(it.textCardData, function (item, index) {
                          return _c("EsgTextCard", {
                            key: index,
                            attrs: { text: item.text },
                          })
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "为客户创新，ESG面向未来",
            titleMarginTop: "54px",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "esg-vision" }, [
                    _c("img", {
                      attrs: {
                        src: "images/aboutus/sustainability/esg_strategy_system/esg_vision.png",
                        alt: "img",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("StrategicDirections", [
                _c(
                  "div",
                  { staticClass: "contents-area" },
                  _vm._l(_vm.cardDataCn, function (it, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "contents" },
                      [
                        _c("EsgImageCard", {
                          attrs: {
                            firstTitle: it.firstTitle,
                            title: it.title,
                            content: it.content,
                            iconSrc: it.iconSrc,
                          },
                        }),
                        _vm._l(it.textCardData, function (item, index) {
                          return _c("EsgTextCard", {
                            key: index,
                            attrs: { text: item.text },
                          })
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }