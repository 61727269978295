<template>
  <div class="social-contribution-strategy-system-vision">
    <SocialResponsibilityBorderTitle
      :title="borderTitle"
      padding="15px 80px"
    />
    <img class="point" :src="point">
    <div class="border-box-area">
      <div class="content-area">
        <div class="border-circle">
          <div class="red-circle">
            <pre class="title">{{ title }}</pre>
          </div>
        </div>
        <div class="image-area">
          <img :src="image">
        </div>
        <div class="text-area">
          <pre class="text">{{ text }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialResponsibilityBorderTitle from './SocialResponsibilityBorderTitle.vue'
export default {
  components: {
    SocialResponsibilityBorderTitle
  },
  props: {
    point: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    image: {
      type: String
    },
    borderTitle: {
      type: String
    }
  }

}
</script>

<style>
.social-contribution-strategy-system-vision {
  display: grid;
  justify-items: center;
  position: relative;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision {
    width: 100%;
  }
}

.social-contribution-strategy-system-vision .point {
  width: fit-content;
  object-fit: none;
  position: absolute;
  top: 74px;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .point {
    top: 10%;
  }
}

.social-contribution-strategy-system-vision .border-box-area {
  width: 1073px;
  height: 419px;
  flex-shrink: 0;
  border-radius: 209.5px;
  border: 1px dashed #B4B4B4;
  background: #F4F4F4;
  display: flex;
  margin-top: 74px;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .border-box-area {
    width: 100%;
    height: 100%;
    justify-content: center;
    max-width: 272px;
  }
}

.social-contribution-strategy-system-vision .border-box-area .content-area {
  display: inline-flex;
  align-items: center;
  gap: 32px;
  margin: 32px 0px 38px 38px;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .border-box-area .content-area {
    margin: 0px;
    flex-direction: column;
  }
}

.social-contribution-strategy-system-vision .border-box-area .content-area .border-circle {
  width: 349px;
  height: 349px;
  flex-shrink: 0;
  border-radius: 349px;
  border: 1px dashed #907E72;
  background: rgba(255, 255, 255, 0.51);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .border-box-area .content-area .border-circle {
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    max-width: 220px;
    max-height: 220px;
    margin-top: 10%;
  }
}

.social-contribution-strategy-system-vision .border-box-area .content-area .border-circle .red-circle {
  width: 289px;
  height: 289px;
  flex-shrink: 0;
  border-radius: 289px;
  background: #AA2D29;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .border-box-area .content-area .border-circle .red-circle {
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    width: 90%;
    height: 90%;
  }
}

.social-contribution-strategy-system-vision .border-box-area .content-area .border-circle .red-circle .title {
  text-align: center;
  color: #FFF;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.5px;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .border-box-area .content-area .border-circle .red-circle .title {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.social-contribution-strategy-system-vision .content-area .image-area {
  display: flex;
  align-items: center;
}

.social-contribution-strategy-system-vision .content-area .image-area img {
  width: 80px;
  height: 80px;
}

.social-contribution-strategy-system-vision .content-area .text-area {
  display: flex;
  align-items: center;
}

.social-contribution-strategy-system-vision .content-area .text-area .text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-vision .content-area .text-area .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
}
</style>
