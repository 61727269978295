<template>
  <div class="lightweight-material-area">
    <ProductSectionHeader title="Lightweight Material" />

    <!-- <div class="item-area mt-30">
      <div
        v-for="(it, idx) in items"
        :key="idx"
        class="item"
      >
        <div class="image-area">
          <img :src="it.img">
        </div>

        <div class="text-area mt-20">
          <span class="title">{{ it.title }}</span>
        </div>

        <div
          class="text-area mt-20"
          v-if="it.titleDesc"
        >
          <span class="title-desc">{{ it.titleDesc }}</span>
        </div>

        <div
          class="text-area mt-20"
          v-if="it.desc"
        >
          <span class="desc">{{ it.desc }}</span>
        </div>

      </div>
    </div> -->

    <div class="lwm_wrap mt-30">
      <div class="tab-ui tab-ui-nav type_line type_full">
        <ul>
          <li data-tab="litemax" class="btn-tab on">
            <button type="button">LiteMax</button>
          </li>
          <li data-tab="lcorepanel" class="btn-tab">
            <button type="button">L-Core Panel</button>
          </li>
        </ul>
        <div class="tab-con litemax on">
          <div class="lwm_content">
            <div class="prod_img_area">
              <div class="img_wrap">
                <img src="images/automotive/lightweight/litemax.png" alt="litemax">
              </div>
              <div class="tit_area">
                <p class="lwm_title">LiteMax</p>
                <span class="lwm_desc">LX Hausys' Thermoplastic Continuous Glass Fiber Sheet</span>
              </div>
            </div>
            <table class="lwm_table">
              <colgroup>
                <col style="width:15.25%">
                <col style="width:auto">
              </colgroup>
              <tr>
                <th>LiteMax PPGF60</th>
                <td>
                  <p>- Polypropylene impregnated glass fiber sheet</p>
                  <p>- Glass fiber content : 60wt%</p>
                  <p>- Best for lightweight designs</p>
                  <p>- Application : Rear bumper beam</p>
                </td>
              </tr>
              <tr>
                <th>LiteMax PPGF80</th>
                <td>
                  <p>- Polypropylene impregnated glass fiber sheet</p>
                  <p>- Glass fiber content : 80wt%</p>
                  <p>- High glass fiber content for high stiffness</p>
                  <p>- Application : Bettery pack bottom panel</p>
                </td>
              </tr>
              <tr>
                <th>LiteMax PAGF60</th>
                <td>
                  <p>- Polyamide 6 impregnated glass fiber sheet</p>
                  <p>- Glass fiber content : 60wt%</p>
                  <p>- Excellent thermal and impact resistance</p>
                  <p>- Application : Seat back frame</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="tab-con lcorepanel">
          <div class="lwm_content">
            <div class="prod_img_area">
              <div class="img_wrap">
                <img src="images/automotive/lightweight/lcorepanel.png" alt="l-core panel">
              </div>
              <div class="tit_area">
                <p class="lwm_title">L-Core Panel</p>
                <span class="lwm_desc">Lightweight sandwich panels with fiber core are used as a wall for trailers in North America.
                  L-core panels are lighter, more corrosion-resistant, and more resilient than HDPE Core panels.</span>
              </div>
            </div>
            <table class="lcore_table">
              <thead>
                <tr>
                  <th></th>
                  <td colspan="2">HDPE Panel</td>
                  <td colspan="2">L-Core Panel</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th></th>
                  <td class="steel_wrap" colspan="2">
                    <div>
                      <img src="images/automotive/lightweight/hdpepanel_steel.png" alt="HOPE Panel">
                    </div>
                  </td>
                  <td class="steel_wrap" colspan="2">
                    <div>
                      <img src="images/automotive/lightweight/lcorepanel_steel.png" alt="L-Core Panel">
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Thickness</th>
                  <td>6.5 mm</td>
                  <td>0.256 in</td>
                  <td>6.5 mm</td>
                  <td>0.256 in</td>
                </tr>
                <tr>
                  <th>Unit weight</th>
                  <td><p>11.03 kg/m<sup>2</sup></p></td>
                  <td>2.26 lbs/ft<sup>2</sup></td>
                  <td>9.30 kg/m<sup>2</sup></td>
                  <td>1.90 lbs/ft<sup>2</sup></td>
                </tr>
                <tr>
                  <th>Weight reduction</th>
                  <td>-</td>
                  <td>-</td>
                  <td>12.8%</td>
                  <td>12.8%</td>
                </tr>
                <tr>
                  <th>Corrosion resistance</th>
                  <td>-</td>
                  <td>-</td>
                  <td>Excellent</td>
                  <td>Excellent</td>
                </tr>
                <tr>
                  <th>Deformation<br>
                    before and after loading</th>
                  <td>10.0 mm</td>
                  <td>0.394 in</td>
                  <td>3.5 mm</td>
                  <td>0.138 in</td>
                </tr>
              </tbody>
            </table>
            <span>*Based on our high temperature and humidity accelerated life test</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSectionHeader from "@/components/product/ProductSectionHeader";

export default {
  components: {
    ProductSectionHeader,
  },
  data: () => ({
    items: [
      {
        img: "images/automotive/lightweight/material-01.png",
        title: "LFT",
        titleDesc: "(Long Fiber Thermoplastic)",
      },
      {
        img: "images/automotive/lightweight/material-02.png",
        title: "CFT",
        titleDesc: "(Continuous Fiber Thermoplastic)",
        desc: "The lightweight material whichLX Hausys has originally developed",
      },
      {
        img: "images/automotive/lightweight/material-03.png",
        title: "SMC",
      },
      {
        img: "images/automotive/lightweight/material-04.png",
        title: "L-core Panel",
      },
    ],
  }),
};
</script>

<style scoped>
.lightweight-material-area {
  display: flex;
  flex-direction: column;
}

.lightweight-material-area .item-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.lightweight-material-area .item-area .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 280px;
}

.lightweight-material-area .item-area .item .image-area {
  margin-bottom: 30px;
}

.lightweight-material-area .item-area .item .image-area img {
  width: 190px;
  height: 190px;
  object-fit: cover;
}

.lightweight-material-area .item-area .item .text-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}

.title {
  color: #2c2c2d;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.title-desc {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.desc {
  color: #555;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
