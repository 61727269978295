<template>
  <iframe style="display:block; width:100vw; height: 100vh;" src="https://indd.adobe.com/view/c152b365-7140-4a7e-9f51-cde5fcd311d3?startpage=1&allowFullscreen=false" frameborder="0" allowfullscreen=""></iframe>
</template>

<script>
export default {
  name: 'Detail',
  components: {
  },
  data () {
    return {
      nationCode: ''
    }
  },
  created () {
    /* select box */

  },
  async mounted () {
    const _this = this
    _this.nationCode = this.$countryCode // 국가코드
  },
  methods: {
  }
}
</script>
