var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "interior-part-area" },
    [
      _c("ProductSectionHeader", {
        attrs: {
          title: "Interior Part",
          description:
            "Creating sophisticated designs with natural patterned real wood",
        },
      }),
      _vm._m(0),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-area mt-50" }, [
      _c("div", { staticClass: "main-image-area" }, [
        _c("img", {
          attrs: { src: "images/automotive/component/interior-part-img-1.png" },
        }),
      ]),
      _c("div", { staticClass: "detail-area" }, [
        _c("div", [
          _c("p", [_vm._v("SRP (Superfine Real Printing)")]),
          _c("ul", [
            _c("li", [_vm._v("Enables precision 3D object printing")]),
            _c("li", [
              _vm._v("Delivers superior realism and textured effects"),
            ]),
            _c("li", [
              _vm._v("Applicable to a wide range of automotive interiors"),
            ]),
            _c("li", [
              _vm._v(
                "Application : Crash pads, Garnish, Consoles, Steering wheels, etc."
              ),
            ]),
          ]),
        ]),
        _c("div", [
          _c("p", [_vm._v("Real Materials")]),
          _c("ul", [
            _c("li", [
              _vm._v(
                "Premium aesthetic from natural wood veneers and carbon fiber"
              ),
            ]),
            _c("li", [
              _vm._v(
                "Exquisite crash pads, garnishes, consoles, and armrests for luxury interior parts"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "interior-design" }, [
      _c("div", [
        _c("h3", [_vm._v("SRP Design")]),
        _c("div", { staticClass: "interior-column" }, [
          _c("div", [
            _c("p", [_vm._v("Wood")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_wood01.png",
                alt: "SRP-wood-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_wood02.png",
                alt: "SRP-wood-02",
              },
            }),
          ]),
          _c("div", [
            _c("p", [_vm._v("Fabric")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_fabric01.png",
                alt: "SRP-fabric-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_fabric02.png",
                alt: "SRP-fabric-02",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "interior-column" }, [
          _c("div", [
            _c("p", [_vm._v("Geometric, Technical")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_technical01.png",
                alt: "SRP-geometric-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_technical02.png",
                alt: "SRP-geometric-02",
              },
            }),
          ]),
          _c("div", [
            _c("p", [_vm._v("Hair line")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_hair01.png",
                alt: "SRP-hair-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/srp_hair02.png",
                alt: "SRP-hair-02",
              },
            }),
          ]),
        ]),
      ]),
      _c("div", [
        _c("h3", [_vm._v("Real Materials")]),
        _c("div", { staticClass: "interior-column" }, [
          _c("div", [
            _c("p", [_vm._v("Wood")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_wood01.png",
                alt: "Real-wood-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_wood02.png",
                alt: "Real-wood-02",
              },
            }),
          ]),
          _c("div", [
            _c("p", [_vm._v("Aluminum")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_aluminum01.png",
                alt: "Real-aluminum-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_aluminum02.png",
                alt: "Real-aluminum-02",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "interior-column" }, [
          _c("div", [
            _c("p", [_vm._v("Textile")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_rextile.png",
                alt: "Real-textile",
              },
            }),
          ]),
          _c("div", [
            _c("p", [_vm._v("Stone")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_stone.png",
                alt: "Real-stone",
              },
            }),
          ]),
          _c("div", [
            _c("p", [_vm._v("Carbon")]),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_carbon01.png",
                alt: "Real-carbon-01",
              },
            }),
          ]),
          _c("div", [
            _c("p"),
            _c("img", {
              attrs: {
                src: "images/automotive/interior/real_carbon02.png",
                alt: "Real-carbon-02",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }