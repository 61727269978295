var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "visual-image-area", style: _vm.backgroundStyle },
    [
      _vm._t("content"),
      _c("div", { staticClass: "visual-image-area-title-box" }, [
        _c("h1", { staticClass: "title" }, [
          _c(
            "span",
            { staticClass: "txt title-font", style: { color: _vm.titleColor } },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ]),
        _c("div", { staticClass: "description-font" }, [
          _vm._v(_vm._s(_vm.description)),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }