<template>
  <div id="wrap" class="mainpage">
<!--    <progress class="lx_prog"  max="100" value="100"></progress>-->
<!--    <progressComponent></progressComponent>-->
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- 스크린리더에만 읽힘 페이지에 안보임 -->
    <h1 class="sr-only">LX Hausys</h1>

    <!-- content -->
    <div class="main content prod-dt-cate">
      <div class="container prod-dt-view">
        <!-- visual slide -->
        <VisualComponent></VisualComponent>
        <!-- visual slide -->

        <!-- New Event -->
        <NewEventComponent></NewEventComponent>
        <!-- New Event -->

        <!-- Our products -->
        <OurproductsComponent></OurproductsComponent>
        <!-- //Our products -->

        <!-- INSPIRATION GALLERIES -->
        <INSPIREDComponent></INSPIREDComponent>
        <!-- //INSPIRATION GALLERIES -->

        <BannerComponent></BannerComponent>

        <!-- CASE STUDIES -->
        <div class="main-box main_case_studies_section" v-if="nationCode ==='_GL'">
          <div class="title_area">
            <h2 class="tit-main">CASE STUDIES</h2>
            <div class="more" @click="handelClickViewMore">
              <a href="javascript:void(0)" class="view_more">
                <span>View more</span>
                <span class="icon"></span>
              </a>
            </div>
          </div>
          <div class="case_studies_slider">
            <VueSlickCarousel v-bind="slickSettings" v-if="pageList.length">
              <div class="item" v-for=" item in pageList">
                <div class="img_wrap">
                  <img :src="`${$imagePath}${item.attribute.mainThumbnail}`" :alt="item.content.title" @click="moveToDetail(item)">
                </div>
                <div class="contents">
                  <div class="link_area">
                    <span class="title" v-html="item.content.title"/>
                    <span class="icon"></span>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <!-- <button class="slick-next slick-arrow ca_next" aria-label="Next" type="button" @click="caseNext()">Next</button> -->
          </div>
        </div>

        <!-- short cut -->
        <!-- US -->
        <div class="short_cut_box full_width" v-if="nationCode !=='_GL'">
          <div class="short-link">
            <a class="item" href="javascript:roomvo.startStandaloneVisualizer('','');" v-if="nationCode !== '_GL'">
              <span class="icon icon_visualiser m-ui"></span>
              <div class="icon_group short_visualiser_area p-ui">
                <span class="icon icon_visual_cube"></span>
                <span class="icon icon_visual_btm"></span>
              </div>
              <span class="txt">Visualizer</span>
            </a>
            <a class="item" href="/us/support/ourshowroom" v-if="nationCode !== '_GL'">
              <span class="icon icon_showroom m-ui"></span>
              <div class="icon_group short_showroom_area p-ui">
                <span class="icon icon_showroom_arrow"></span>
                <span class="icon icon_showroom_btm"></span>
              </div>
              <span class="txt">Showroom</span>
            </a>
            <a class="item" href="/us/support/contactus" v-if="nationCode !== '_GL'">
              <span class="icon icon_contactus m-ui"></span>
              <div class="icon_group short_contact_area p-ui">
                <span class="icon icon_contact_mail"></span>
                <span class="icon icon_contact_btm"></span>
              </div>
              <span class="txt">Contact us</span>
            </a>
            <a class="item" href="/us/support/where-to-buy/country/us" v-if="nationCode !== '_GL'">
              <span class="icon icon_wheretobuy m-ui"></span>
              <div class="icon_group short_where_area p-ui">
                <span class="icon icon_where_dot"></span>
                <span class="icon icon_where_btm"></span>
              </div>
              <span class="txt">{{ $t("main.text.where_to_buy") }}</span>
            </a>
          </div>
        </div>

        <!--GL-->
        <div class="short_cut_box full_width" v-if="nationCode ==='_GL'">
          <div class="short-link">
            <a class="item" href="/gl/support/contactus" v-if="nationCode === '_GL'">
              <span class="icon icon_contactus m-ui"></span>
              <div class="icon_group short_contact_area p-ui">
                <span class="icon icon_contact_mail"></span>
                <span class="icon icon_contact_btm"></span>
              </div>
              <span class="txt">Contact us</span>
            </a>
            <a class="item" href="/gl/support/where-to-buy/country/global" v-if="nationCode === '_GL'">
              <span class="icon icon_wheretobuy m-ui"></span>
              <div class="icon_group short_where_area p-ui">
                <span class="icon icon_where_dot"></span>
                <span class="icon icon_where_btm"></span>
              </div>
              <span class="txt">{{ $t("main.text.where_to_buy") }}</span>
            </a>
          </div>
        </div>
        <!-- //short cut -->

      </div>
      <!-- //container (Category) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import InspirationService from '@/server/inspiration.service'
import BannerComponent from '@/views/index/component/bannerComponent.vue'
import INSPIREDComponent from '@/views/index/component/inspiredComponent'
import NewEventComponent from '@/views/index/component/neweventComponent'
import OurproductsComponent from '@/views/index/component/ourproductsComponent'
import PRODUCTSComponent from '@/views/index/component/productsComponent'
import progressComponent from '@/views/index/component/progressComponent.vue'
import SEGMENTSComponent from '@/views/index/component/segmentsComponent'
import VisualComponent from '@/views/index/component/visualComponent.vue'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'app',
  components: {
    HeaderComponent,
    progressComponent,
    QuickMenuComponent,
    FooterComponent,
    GnbComponent,
    BannerComponent,
    VisualComponent,
    NewEventComponent,
    OurproductsComponent,
    PRODUCTSComponent,
    INSPIREDComponent,
    SEGMENTSComponent,
    VueSlickCarousel
  },
  data () {
    return {
      caseStudiesList: [],
      pageCategotyId: [],
      pageList: '',
      nationCode: '',
      seo: false,
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          },
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      slickSettings: {
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        arrows: true,
        draggable: true,
        infinite: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              infinite: false
            }
          }
        ]
      }
    }
  },
  async mounted () {
    this.nationCode = this.$countryCode
    if (this.nationCode === '_US' || this.nationCode === '_GL') {
      this.seo = true
    }

    $('header').removeClass('on')
    this.$nextTick(function () {
      $.fn.fnMainOP_ON = function () {
        var _this = $(this)
        _this.on('mouseenter', function (e) {
          e.preventDefault()
          var $this = $(this)
          var n = $this.index()
          $this.addClass('on')
          $this.siblings('.item').addClass('off')
          $this.parent().siblings('.ourprod-view').children('.item').removeClass('show')
          $this.parent().siblings('.ourprod-view').children('.item:eq(' + n + ')').addClass('show').animate({
            width: '100%', left: '0'
          }, 1000, 'easeInOutQuint')
        })
      }

      $.fn.fnMainOP_OFF = function (length) {
        var _this = $(this)
        _this.on('mouseleave', function (e) {
          e.preventDefault()
          var $this = $(this)
          var n = $this.index()
          $this.removeClass('on')
          $this.siblings('.item').removeClass('off')
          $this.parent().siblings('.ourprod-view').children('.item:eq(' + n + ')').stop()
          $this.parent().siblings('.ourprod-view').children('.item:eq(' + n + ')').css('left', 'calc( (100% / ' + length + ') * ' + n + ')')

          $this.parent().siblings('.ourprod-view').children('.item').removeClass('show').css({
            width: 'calc(100% /' + length + ')'
          })
        })
      }

      function activeArrowTab () {
        var tabInstance = $('.tab-ui-nav')
        $.each(tabInstance, function (key, value) {
          var windowWidth = $(window).width()
          var arrows = $(tabInstance[key]).find('.arrow')
          var prevArrow = arrows.filter('.tab-arr-prev')
          var nextArrow = arrows.filter('.tab-arr-next')
          var box = $(tabInstance[key]).find('ul')
          var x = 0
          var mx = 0
          var maxScrollWidth = box[0].scrollWidth - windowWidth / 2 - box.width() / 2

          $(arrows).on('click', function () {
            if ($(this).hasClass('tab-arr-next')) {
              x = box.width() / 2 + box.scrollLeft()
              box.animate({
                scrollLeft: x
              })
            } else {
              x = box.width() / 2 - box.scrollLeft()
              box.animate({
                scrollLeft: -x
              })
            }
          })

          $(box).on({
            mousemove: function (e) {
              var mx2 = e.pageX - this.offsetLeft
              if (mx) this.scrollLeft = this.sx + mx - mx2
            },
            mousedown: function (e) {
              this.sx = this.scrollLeft
              mx = e.pageX - this.offsetLeft
            },
            scroll: function () {
              toggleArrows()
            }
          })

          $(document).on('mouseup', function () {
            mx = 0
          })

          function toggleArrows () {
            if (box.scrollLeft() > maxScrollWidth) {
              nextArrow.addClass('disabled')
            } else if (box.scrollLeft() < 5) {
              prevArrow.addClass('disabled')
            } else {
              // both are enabled
              nextArrow.removeClass('disabled')
              prevArrow.removeClass('disabled')
            }
          }
        })
      }

      if ($('.ourprod-box').length) {
        const boxes_count = $('.box-counter .item').length
        $('.ourprod-wrap .ourprod-content .visual-item').fnMainOP_ON()
        $('.ourprod-wrap .ourprod-content .visual-item').fnMainOP_OFF(boxes_count)
      }
    })
    activeArrowTab()

    // case study 슬라이더 버튼
    $('.ca_next').click(function () {
      $('.case_studies_slider').slick('slickNext')
    })
    $('.ca_prev').click(function () {
      $('.case_studies_slider').slick('slickPrev')
    })

    // design trend slider 실행
    // $('.design_trend_slider').slick({
    //   autoplay: true,
    //   autoplaySpeed: 4500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   pauseOnHover: false,
    //   fade: true,
    //   speed: 1000,
    //   cssEase: 'linear',
    //   dots: true,
    //   draggable: true
    // })

    /* 카테고리 */
    const countryCode = this.$countryCode.slice(1)
    const cateChildren = await InspirationService.getCategoryCode(`${countryCode}_INS_CAS_TOBE`)
    this.pageCategotyId.push(cateChildren.data.object.id)
    this.getList()

    // 스크롤 다운시 header on
    // const pubHead = $('.scr-hder');
    // $(window).scroll(function(){
    //   if ($(window).scrollTop() == 0){
    //     scr-hder.addClass('on');
    //   }else {
    //     scr-hder.removeClass('on');
    //   }
    // })
  },
  methods: {
    async getList () {
      const { data } = await InspirationService.getContentListTap(this.pageCategotyId, this.dictionaryCondition, '', '', 1, 10)

      data.list.forEach((listItem, listIndex) => {
        Object.keys(listItem.attribute).forEach((item, index) => {
          const exceptList = ['productList', 'commercialList', 'residentialList', 'templateList', 'usedMaterialList', 'relatedContentsList']
          if (exceptList.includes(item)) {
            data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
          }
        })
      })
      this.pageList = data.list
    },
    moveToDetail (item) {
      const { id, title } = item.content
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      location.href = `/${countryCode}/inspiration/case-studies/${title}/${id}`
    },
    handelClickViewMore () {
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      location.href = `/${countryCode}/inspiration/case-studies`
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    }
    // caseNext() {
    //   this.$refs.case_slider.next()
    // },
    // casePrev() {
    //   this.$refs.case_slider.prev()
    // },
  }
}
</script>
