var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _vm._m(0),
    _c("div", { staticClass: "filter_checkbox_cont" }, [
      _c("div", { staticClass: "checkbox_box" }, [
        _c("span", { staticClass: "checkbox_more no_line" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isNewSelected,
                  expression: "isNewSelected",
                },
              ],
              attrs: { type: "checkbox", id: "isNew_true" },
              domProps: {
                checked: Array.isArray(_vm.isNewSelected)
                  ? _vm._i(_vm.isNewSelected, null) > -1
                  : _vm.isNewSelected,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isNewSelected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isNewSelected = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isNewSelected = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isNewSelected = $$c
                  }
                },
              },
            }),
            _c("label", { attrs: { for: "isNew_true" } }, [
              _c("span"),
              _vm._v(" " + _vm._s(_vm.$t("PF.text.new_colors")) + " "),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("NEW")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }