/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const ADD_SEARCH_WORD = 'ADD_SEARCH_WORD';
export const DELETE_SEARCH_WORD = 'DELETE_SEARCH_WORD';
export const ADD_SEARCH_HOME_WORD = 'ADD_SEARCH_HOME_WORD';
export const DELETE_SEARCH_HOME_WORD = 'DELETE_SEARCH_HOME_WORD';

export default {
  ADD_SEARCH_WORD,
  DELETE_SEARCH_WORD,
  ADD_SEARCH_HOME_WORD,
  DELETE_SEARCH_HOME_WORD,
};
