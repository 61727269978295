<template>
  <OverviewLayout
    imageSrc="images/aboutus/overview/performance/top_image.png"
    title="Performance"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <template #content>
      <FinancialPerformance />
      <KeyPerformance />
      <FinancialStatement />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/performance/top_image.png"
    title="経営成果"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <FinancialPerformance />
      <KeyPerformance />
      <FinancialStatement />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/performance/top_image.png"
    title="Показатели"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <FinancialPerformance />
      <KeyPerformance />
      <FinancialStatement />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/performance/top_image.png"
    title="业绩"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <FinancialPerformance />
      <KeyPerformance />
      <FinancialStatement />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../components/OverviewLayout.vue'
import FinancialPerformance from './components/FinancialPerformance.vue'
import FinancialStatement from './components/FinancialStatement.vue'
import KeyPerformance from './components/KeyPerformance.vue'

export default {
  components: {
    OverviewLayout,
    FinancialPerformance,
    KeyPerformance,
    FinancialStatement
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
</style>
