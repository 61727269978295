
function wildcard (text, pattern) {
  const n = text.length
  const m = pattern.length

  if (m === 0) {
    return n === 0
  }

  let i = 0
  let j = 0
  let textPointer = -1
  let pattPointer = -1
  while (i < n) {
    if (text[i] === pattern[j]) {
      i++
      j++
    } else if (j < m && pattern[j] === '?') {
      i++
      j++
    } else if (j < m && pattern[j] === '*') {
      textPointer = i
      pattPointer = j
      j++
    } else if (pattPointer !== -1) {
      j = pattPointer + 1
      i = textPointer + 1
      textPointer++
    } else {
      return false
    }
  }

  while (j < m && pattern[j] === '*') {
    j++
  }

  return j === m
}

const pathSnipet = {
  'viatera-finder': { name: 'VIATERA', cateCode: 'VIATERA_COLOR', pcdCateCode: 'PCD_VIATERA', url: '/viatera-quartz-surface/' },
  'himacs-finder': { name: 'HIMACS', cateCode: 'HIMACS_COLOR', pcdCateCode: 'PCD_HIMACS', url: '/himacs-solid-surface/' },
  'hflor-finder/all': { name: 'HFLOR', cateCode: 'PRD_FLOORING', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'hflor-finder/lvt-flooring': { name: 'HFLOR', cateCode: 'FLOOR_LUX', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'hflor-finder/homogeneous-sheet': { name: 'HFLOR', cateCode: 'FLOOR_HOM', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'hflor-finder/heterogeneous-sheet': { name: 'HFLOR', cateCode: 'FLOOR_HET', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'hflor-finder/specialty': { name: 'HFLOR', cateCode: 'FLOOR_SPT', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'hflor-finder/spc-click': { name: 'HFLOR', cateCode: 'FLOOR_SPC', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'hflor-finder/looselay': { name: 'HFLOR', cateCode: 'FLOOR_LOS', pcdCateCode: 'PCD_HFLOR', url: '/hflor/' },
  'teracanto-finder': { name: 'TERACANTO', cateCode: 'TERACANTO_COLOR', pcdCateCode: 'PCD_TERACANTO', url: '/teracanto-porcelain-surface/' },
  'benif-finder': { name: 'BENIF', cateCode: 'BENIF_COLOR', pcdCateCode: 'PCD_BENIF', url: '/benif-interior-film/' },
  'vizuon-finder/vehicles-and-transportation': { name: 'VIZUON', cateCode: 'VIZUON_VT', pcdCateCode: 'PCD_VIZUON_A', url: '/vizuon-sign-graphic/' },
  'vizuon-finder/signs-and-displays': { name: 'VIZUON', cateCode: 'VIZUON_SD', pcdCateCode: 'PCD_VIZUON_A', url: '/vizuon-sign-graphic/' },
  'vizuon-finder/illuminated-sign': { name: 'VIZUON', cateCode: 'VIZUON_IS', pcdCateCode: 'PCD_VIZUON_A', url: '/vizuon-sign-graphic/' },
  'vizuon-finder/architecture': { name: 'VIZUON', cateCode: 'VIZUON_AR', pcdCateCode: 'PCD_VIZUON_A', url: '/vizuon-sign-graphic/' },
  'vizuon-finder/corporate-branding': { name: 'VIZUON', cateCode: 'VIZUON_CB', pcdCateCode: 'PCD_VIZUON_A', url: '/vizuon-sign-graphic/' },
  'vizuon-finder/eco-solutions': { name: 'VIZUON', cateCode: 'VIZUON_ES', pcdCateCode: 'PCD_VIZUON_A', url: '/vizuon-sign-graphic/' },
  'deco-finder': { name: 'DECO FOIL', cateCode: 'DECO_COLOR', pcdCateCode: 'PCD_DECO', url: '/deco-foil/' },
  'exterior-finder': { name: 'EXTERIOR FOIL', cateCode: 'EXTERIOR_COLOR', pcdCateCode: 'PCD_EXTERIOR', url: '/exterior-foil/' },
  'borte-finder': { name: 'BORTE', cateCode: 'BORTE_COLOR', pcdCateCode: 'PCD_BORTE', url: '/borte-premium-panel/' }
}

export function getProductInfoByPath (currentPath) {
  for (const path in pathSnipet) {
    if (currentPath.includes(path)) {
      return pathSnipet[path]
    }
  }

  return null
}

export function getProductNameByPath (currentPath, nationCode) {
  for (const path in pathSnipet) {
    if (currentPath.includes(path)) {
      return pathSnipet[path].name
    }
  }

  return null
}

export function getCategoryCodeByPath (currentPath, nationCode) {
  for (const path in pathSnipet) {
    if (currentPath.includes(path)) {
      return pathSnipet[path].cateCode + nationCode
    }
  }

  return null
}

export function getPcdCategoryCodeByPath (currentPath, nationCode) {
  const newNatCode = nationCode.replace('_', '')

  for (const path in pathSnipet) {
    if (currentPath.includes(path)) {
      return newNatCode + '_' + pathSnipet[path].pcdCateCode
    }
  }

  return null
}

export function getDictionaryMapByCateCode (cateCode, nationCode) {
  const dictMap = {
    VIATERA_COLOR_GL: [
      { code: 'VTR_HUE', type: 'hue' },
      { code: 'VTR_FNSH' + nationCode, type: 'finish' },
      { code: 'VTR_PATTERN' + nationCode, type: 'pattern' },
      { code: 'VTR_THICK' + nationCode, type: 'thickness' },
      { code: 'VTR_SLAB_SIZE' + nationCode, type: 'slab_size' }
    ],
    VIATERA_COLOR_CN: [
      { code: 'VTR_PATTERN' + nationCode, type: 'pattern' },
    ],
    VIATERA_COLOR_US: [
      { code: 'VTR_HUE', type: 'hue' },
      { code: 'VTR_FNSH' + nationCode, type: 'finish' },
      { code: 'VTR_PATTERN' + nationCode, type: 'pattern' },
      { code: 'VTR_THICK' + nationCode, type: 'thickness' },
      { code: 'VTR_SLAB_SIZE' + nationCode, type: 'slab_size' }
    ],
    '*VIATERA_COLOR*': [
      { code: 'VTR_HUE' + nationCode, type: 'hue' },
      { code: 'VTR_FNSH' + nationCode, type: 'finish' },
      { code: 'VTR_PATTERN' + nationCode, type: 'pattern' },
      { code: 'VTR_THICK' + nationCode, type: 'thickness' },
      { code: 'VTR_SLAB_SIZE' + nationCode, type: 'slab_size' }
    ],
    HIMACS_COLOR_GL: [
      { code: 'HIMACS_HUE', type: 'hue' },
      { code: 'HIMACS_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HIMACS_CONTY', type: 'country' }
    ],
    HIMACS_COLOR_US: [
      { code: 'HIMACS_HUE' + nationCode, type: 'hue' },
      { code: 'HIMACS_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HIMACS_CONTY', type: 'country' }
    ],
    '*HIMACS_COLOR*': [
      { code: 'HIMACS_HUE' + nationCode, type: 'hue' },
      { code: 'HIMACS_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HIMACS_CONTY', type: 'country' }
    ],
    PRD_FLOORING: [
      { code: 'HFLOR_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_DIMEN' + nationCode, type: 'dimension' },
      { code: 'HFLOR_PRDTYPE' + nationCode, type: 'prdType' }
    ],
    PRD_FLOORING_US: [
      { code: 'HFLOR_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_DIMEN' + nationCode, type: 'dimension' },
      { code: 'HFLOR_PRDTYPE' + nationCode, type: 'prdType' }
    ],
    FLOOR_LUX_CN: [
      { code: 'HFLOR_LUX_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_LUX_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_LUX_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_LUX_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_LUX_DIMEN' + nationCode, type: 'dimension' },
    ],
    '*LUX*': [
      { code: 'HFLOR_LUX_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_LUX_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_LUX_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_LUX_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_LUX_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_LUX_DIMEN' + nationCode, type: 'dimension' },
      { code: 'HFLOR_LUX_PRDTYPE' + nationCode, type: 'prdType' }
    ],
    '*LOS*': [
      { code: 'HFLOR_LOS_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_LOS_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_LOS_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_LOS_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_LOS_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_LOS_DIMEN' + nationCode, type: 'dimension' },
      { code: 'HFLOR_LOS_PRDTYPE' + nationCode, type: 'prdType' }
    ],
    FLOOR_SPC_CN: [
      { code: 'HFLOR_SPC_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_SPC_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_SPC_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_SPC_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_SPC_INSTALL' + nationCode, type: 'prdType' },
      { code: 'HFLOR_SPC_DIMEN' + nationCode, type: 'dimension' }
    ],
    '*SPC*': [
      { code: 'HFLOR_SPC_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_SPC_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_SPC_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_SPC_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_SPC_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_SPC_DIMEN' + nationCode, type: 'dimension' },
      { code: 'HFLOR_SPC_PRDTYPE' + nationCode, type: 'prdType' }
    ],
    FLOOR_HOM_CN: [
      { code: 'HFLOR_HOM_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_HOM_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_HOM_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_HOM_THICKNESS' + nationCode, type: 'thickness' },
      // { code: 'HFLOR_HOM_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_HOM_DIMEN' + nationCode, type: 'dimension' }
    ],
    '*HOM*': [
      { code: 'HFLOR_HOM_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_HOM_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_HOM_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_HOM_THICKNESS' + nationCode, type: 'thickness' },
      // { code: 'HFLOR_HOM_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_HOM_DIMEN' + nationCode, type: 'dimension' }
    ],
    FLOOR_HET_CN: [
      { code: 'HFLOR_HET_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_HET_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_HET_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_HET_DIMEN' + nationCode, type: 'dimension' }
    ],
    '*HET*': [
      { code: 'HFLOR_HET_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_HET_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_HET_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_HET_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_HET_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_HET_DIMEN' + nationCode, type: 'dimension' }
    ],
    FLOOR_SPT_CN: [
      { code: 'HFLOR_SPT_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_SPT_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_SPT_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_SPT_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_SPT_DIMEN' + nationCode, type: 'dimension' }
    ],
    '*SPT*': [
      { code: 'HFLOR_SPT_COLOR' + nationCode, type: 'hue' },
      { code: 'HFLOR_SPT_FORMAT' + nationCode, type: 'format' },
      { code: 'HFLOR_SPT_PATTERN' + nationCode, type: 'pattern' },
      { code: 'HFLOR_SPT_THICKNESS' + nationCode, type: 'thickness' },
      { code: 'HFLOR_SPT_WEAR' + nationCode, type: 'wear_layer' },
      { code: 'HFLOR_SPT_DIMEN' + nationCode, type: 'dimension' }
    ],
    TERACANTO_COLOR_GL: [
      { code: 'TCT_HUE', type: 'hue' },
      { code: 'TCT_PATTERN' + nationCode, type: 'pattern' },
      { code: 'TCT_FNSH', type: 'finish' },
      { code: 'TERACANTO_THICK_NEW' + nationCode, type: 'thickness' }
    ],
    TERACANTO_COLOR_US: [
      { code: 'TCT_HUE', type: 'hue' },
      { code: 'TCT_PATTERN' + nationCode, type: 'pattern' },
      { code: 'TCT_FNSH', type: 'finish' },
      { code: 'TERACANTO_THICK_NEW', type: 'thickness' },
      { code: 'TERACANTO_BOOKMATCH', type: 'bookmatch' },
      { code: 'TCT_REMARK' + nationCode, type: 'remark' }
    ],
    '*TERACANTO_COLOR*': [
      { code: 'TCT_HUE' + nationCode, type: 'hue' },
      { code: 'TCT_PATTERN' + nationCode, type: 'pattern' },
      { code: 'TCT_FNSH' + nationCode, type: 'finish' },
      { code: 'TERACANTO_THICK_NEW' + nationCode, type: 'thickness' }
    ],
    BENIF_COLOR_GL: [
      { code: 'BENIF_HUE', type: 'hue' },
      { code: 'BENIF_HUE_W', type: 'hue_w' },
      { code: 'BENIF_PATTERN_W', type: 'pattern_w' },
      { code: 'BENIF_PATTERN_M', type: 'pattern_m' },
      { code: 'BENIF_TYPE_S', type: 'type_s' },
      { code: 'BENIF_TYPE_T', type: 'type_t' },
      { code: 'BENIF_TREE_SPECIES', type: 'tree_species' },
      { code: 'BENIF_FIRE', type: 'fire_resistance' }
    ],
    '*BENIF_COLOR*': [
      { code: 'BENIF_HUE' + nationCode, type: 'hue' },
      { code: 'BENIF_HUE_W' + nationCode, type: 'hue_w' },
      { code: 'BENIF_PATTERN_W' + nationCode, type: 'pattern_w' },
      { code: 'BENIF_PATTERN_M' + nationCode, type: 'pattern_m' },
      { code: 'BENIF_TYPE_S' + nationCode, type: 'type_s' },
      { code: 'BENIF_TYPE_T' + nationCode, type: 'type_t' },
      { code: 'BENIF_TREE_SPECIES' + nationCode, type: 'tree_species' },
      { code: 'BENIF_FIRE' + nationCode, type: 'fire_resistance' }
    ],
    '*VT*': [
      { code: 'VIZUON_VT' + '_APP', type: 'application' },
      { code: 'VIZUON_VT' + '_PT', type: 'prdType' },
      { code: 'VIZUON_VT' + '_AT', type: 'adhesive_type' },
      { code: 'VIZUON_VT' + '_AIR', type: 'air_free' },
      { code: 'VIZUON_VT' + '_PC', type: 'prd_class' }
    ],
    '*AR*': [
      { code: 'VIZUON_AR' + '_APP', type: 'application' },
      { code: 'VIZUON_AR' + '_PT', type: 'prdType' },
      { code: 'VIZUON_AR' + '_AT', type: 'adhesive_type' },
      { code: 'VIZUON_AR' + '_AIR', type: 'air_free' },
      { code: 'VIZUON_AR' + '_PC', type: 'prd_class' }
    ],
    '*CB*': [
      { code: 'VIZUON_CB' + '_APP', type: 'application' },
      { code: 'VIZUON_CB' + '_PT', type: 'prdType' },
      { code: 'VIZUON_CB' + '_AT', type: 'adhesive_type' },
      { code: 'VIZUON_CB' + '_AIR', type: 'air_free' },
      { code: 'VIZUON_CB' + '_PC', type: 'prd_class' }
    ],
    '*ES*': [
      { code: 'VIZUON_ES' + '_APP', type: 'application' },
      { code: 'VIZUON_ES' + '_PT', type: 'prdType' },
      { code: 'VIZUON_ES' + '_AT', type: 'adhesive_type' },
      { code: 'VIZUON_ES' + '_AIR', type: 'air_free' },
      { code: 'VIZUON_ES' + '_PC', type: 'prd_class' }
    ],
    '*IS*': [
      { code: 'VIZUON_IS' + '_APP', type: 'application' },
      { code: 'VIZUON_IS' + '_PT', type: 'prdType' },
      { code: 'VIZUON_IS' + '_AT', type: 'adhesive_type' },
      { code: 'VIZUON_IS' + '_AIR', type: 'air_free' },
      { code: 'VIZUON_IS' + '_PC', type: 'prd_class' }
    ],
    '*SD*': [
      { code: 'VIZUON_SD' + '_APP', type: 'application' },
      { code: 'VIZUON_SD' + '_PT', type: 'prdType' },
      { code: 'VIZUON_SD' + '_AT', type: 'adhesive_type' },
      { code: 'VIZUON_SD' + '_AIR', type: 'air_free' },
      { code: 'VIZUON_SD' + '_PC', type: 'prd_class' }
    ],
    DECO_COLOR_GL: [
      { code: 'DECO_HUE_SLD', type: 'hue_sld' },
      { code: 'DECO_HUE_M', type: 'hue_m' },
      { code: 'DECO_HUE_W', type: 'hue_w' },
      { code: 'DECO_HUE_STN', type: 'hue_stn' },
      { code: 'DECO_PATTERN_SLD', type: 'pattern_sld' },
      { code: 'DECO_PATTERN_M', type: 'pattern_m' },
      { code: 'DECO_PATTERN_W', type: 'pattern_w' },
      { code: 'DECO_PATTERN_STN', type: 'pattern_stn' },
      { code: 'DECO_PVC', type: 'pvc' },
      { code: 'DECO_PET', type: 'pet' },
      { code: 'DECO_ACRYLIC', type: 'acrylic' }
    ],
    '*DECO_COLOR*': [
      { code: 'DECO_HUE_SLD' + nationCode, type: 'hue_sld' },
      { code: 'DECO_HUE_M' + nationCode, type: 'hue_m' },
      { code: 'DECO_HUE_W' + nationCode, type: 'hue_w' },
      { code: 'DECO_HUE_STN' + nationCode, type: 'hue_stn' },
      { code: 'DECO_PATTERN_SLD' + nationCode, type: 'pattern_sld' },
      { code: 'DECO_PATTERN_M' + nationCode, type: 'pattern_m' },
      { code: 'DECO_PATTERN_W' + nationCode, type: 'pattern_w' },
      { code: 'DECO_PATTERN_STN' + nationCode, type: 'pattern_stn' },
      { code: 'DECO_PVC' + nationCode, type: 'pvc' },
      { code: 'DECO_PET' + nationCode, type: 'pet' },
      { code: 'DECO_ACRYLIC' + nationCode, type: 'acrylic' }
    ],
    EXTERIOR_COLOR_GL: [
      { code: 'EXTERIOR_HUE', type: 'hue' },
      { code: 'EXTERIOR_PATTERN', type: 'pattern' },
      { code: 'EXTERIOR_SPECIES', type: 'tree_species' },
      { code: 'EXTERIOR_WARRANTY', type: 'warranty' }
    ],
    '*EXTERIOR_COLOR*': [
      { code: 'EXTERIOR_HUE' + nationCode, type: 'hue' },
      { code: 'EXTERIOR_PATTERN' + nationCode, type: 'pattern' },
      { code: 'EXTERIOR_SPECIES' + nationCode, type: 'tree_species' },
      { code: 'EXTERIOR_WARRANTY' + nationCode, type: 'warranty' }
    ],
    BORTE_COLOR_GL: [
      { code: 'BORTE_COLOR' + nationCode, type: 'hue' },
      { code: 'BORTE_PATTERN' + nationCode, type: 'pattern' },
      { code: 'BORTE_FINISH' + nationCode, type: 'finish' }
    ],
    BORTE_COLOR_US: [
      { code: 'BORTE_COLOR' + nationCode, type: 'hue' },
      { code: 'BORTE_PATTERN' + nationCode, type: 'pattern' },
      { code: 'BORTE_FINISH' + nationCode, type: 'finish' }
    ]
  }
  for (const pattern in dictMap) {
    if (wildcard(cateCode, pattern)) {
      return dictMap[pattern]
    }
  }

  return null
}
