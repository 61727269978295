<template>
  <!-- tab-con Products -->
  <div class="tab-con on" v-if="tabCount > 0">

    <!-- News & Event -->
    <div class="sch-res-prod prod-fd-view" v-if="!isEuUk()">
      <div class="res-prod-cnt">
        <span>{{ $t('news_event')}} (<em>{{NEWS.totalCount}}</em>)</span>
      </div>
      <div class="list-type-news">
        <ul v-if="NEWS && NEWS.totalCount > 0">
          <li v-for="sr in NEWS.list">
            <a href="javascript:void(0)"  @click="gotoNewsDetail(sr)">
              <b class="ctt ctt-t1">{{sr.content.title}}</b>
              <p v-html="sr.attribute.topicEditor" class="ctt ctt-t4" style="overflow-y: hidden; max-height: 55px;"  v-plaintext>
              </p>
            </a>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!NEWS.lastPage">
        <button type="button" class="btn btn-arr-down" @click="loadMoreNEWS()">
          <span>{{ $t('see_more')}}</span>
        </button>
      </div>
    </div>

    <!-- FAQ -->
    <div class="sch-res-prod prod-dt-view">
      <div class="res-prod-cnt">
        <span>FAQ (<em>{{FAQ.totalCount}}</em>)</span>
      </div>
      <div class="faq-box">
        <div class="search-result drop-list bd-gray">
          <ul  v-if="FAQ && FAQ.totalCount > 0">
            <li v-for="faq in FAQ.list">
              <div class="tit">
                <strong v-if="faq.attribute.typeText != null"><span class="product-type">{{ faq.parents[0].name }}</span> / <span class="category-name">{{ faq.attribute.typeText }}</span></strong>
                <strong v-if="faq.attribute.typeText == null"><span class="product-type">{{ faq.parents[0].name }}</span> / <span class="category-name">ALL</span></strong>
                <span class="faq-title">{{ faq.content.title }}</span>
                <button type="button" class="btn-view" @click="toggleDetail($event)">list view</button>
              </div>
              <div class="t-type-01 cont" v-html="faq.attribute.editor">
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="no-found-wrap bd-top" v-else>
    <div class="tac">
      <i class="icon-no-result"><img src="images/icon-no-result-found.png" alt="no result"></i>
      <div class="msg">
        <ul>
          <li class="ctt ctt-t1 result-tit">No Result Found</li>
          <li class="ctt ctt-t4">Try again using more general search keywords</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'

import { mapState } from 'vuex'

export default {
  name: 'SearchProduct',
  props: ['searchResult', 'searchWord'],
  watch: {
    searchResult () {
      this.initSearchResult()
    }
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      tabCount: 0,
      NEWS: { totalCount: 0 },
      CSR: { totalCount: 0 },
      FAQ: { totalCount: 0 }
    }
  },
  created () {},
  async mounted () {
    this.initSearchResult()

    /* visual slide */
    this.$nextTick(function () {
      $('.slide-report').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            // breakpoint 별 추가
          }
        ]
      })
    })
  },
  methods: {
    isEuUk () {
      const country = this.$countryCode.substring(1)
      return country != 'GL' && country != 'US'
    },
    toggleDetail (event) {
      $(event.target).closest('.tit').siblings('.cont').slideToggle(450)
    },
    async loadMoreNEWS () {
      this.loadMore('NEWS')
    },
    gotoNewsDetail (sr) {
      const content = this.$utils.getSeoName(sr, 'news')
      document.location.href =
        'aboutus/news-event-detail/' + content + '/' + sr.content.id
    },
    gotoFaqDetail (sr) {
      document.location.href =
        'support/faq/' + sr.parents[sr.parents.length - 1].code + '/' + sr.content.id
    },
    async loadMore (subType) {
      const search = this.searchResult[subType]
      const pageIndex = search.pageIndex + 1
      const pageSize = search.pageSize
      const s = this.searchWord
      const r = this.$countryCode.substring(1)

      const { data } = await SearchService.byType({
        pageIndex,
        pageSize,
        s,
        r,
        type: 'other',
        subType
      })

      search.lastPage = data.lastPage
      search.pageIndex = data.pageIndex
      search.list.push(...data.list)
    },

    async initSearchResult () {
      this.NEWS = this.searchResult.NEWS
      this.CSR = this.searchResult.CSR
      this.FAQ = this.searchResult.FAQ
      this.tabCount = this.searchResult.NEWS.totalCount + this.searchResult.CSR.totalCount + this.searchResult.FAQ.totalCount
    },
    async downloadFile (file) {
      const url = this.$imagePath + file
      try {
        const element = await document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        // element.setAttribute('download', file);
        element.click()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
