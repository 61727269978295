<template>
  <OverviewSection
    class="mt-64"
    title="GHG Emission Reduction"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem
      :isUnderSectionItem=true
    >
      <div class="graph-area">
        <img src="images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png" alt="img">
      </div>
    </OverviewSectionItem>

    <div class="border-box-div">
      <GhgEmissionReductionBorderBox
        v-for="(data, index) in borderBoxDatas" :key="index"
        :title="data.title"
        :content="data.content"
      />
    </div>

    <OverviewSectionItem title="Key Performance 2022">
      <KeyPerformanceTable />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    class="mt-64"
    title="温室効果ガス排出削減"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem
      :isUnderSectionItem=true
    >
      <div class="graph-area">
        <img src="images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png" alt="img">
      </div>
    </OverviewSectionItem>

    <div class="border-box-div">
      <GhgEmissionReductionBorderBox
        v-for="(data, index) in borderBoxDatasJp" :key="index"
        :title="data.title"
        :content="data.content"
      />
    </div>

    <OverviewSectionItem title="2022年の主な業績">
      <KeyPerformanceTable />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    class="mt-64"
    title="Сокращение выбросов парниковых газов"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem
      :isUnderSectionItem=true
    >
      <div class="graph-area">
        <img src="images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png" alt="img">
      </div>
    </OverviewSectionItem>

    <div class="border-box-div">
      <GhgEmissionReductionBorderBox
        v-for="(data, index) in borderBoxDatasRu" :key="index"
        :title="data.title"
        :content="data.content"
      />
    </div>

    <OverviewSectionItem title="Ключевые показатели 2022 года">
      <KeyPerformanceTable />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    class="mt-64"
    title="温室气体减排"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem
      :isUnderSectionItem=true
    >
      <div class="graph-area">
        <img src="images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png" alt="img">
      </div>
    </OverviewSectionItem>

    <div class="border-box-div">
      <GhgEmissionReductionBorderBox
        v-for="(data, index) in borderBoxDatasCn" :key="index"
        :title="data.title"
        :content="data.content"
      />
    </div>

    <OverviewSectionItem title="2022年关键业绩">
      <KeyPerformanceTable />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import GhgEmissionReductionBorderBox from './GhgEmissionReductionBorderBox.vue'
import KeyPerformanceTable from './KeyPerformanceTable.vue'

export default {
  components: {
    GhgEmissionReductionBorderBox,
    OverviewSection,
    OverviewSectionItem,
    KeyPerformanceTable
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      borderBoxDatas: [
        {
          title: 'Carbon Disclosure Project',
          content: 'Achieving grade B in the CDP,which is <b>higher than the global\naverage</b> in 2022.'
        },
        {
          title: 'Emission Trading Rights',
          content: 'We have reduced GHG emissions for <b>five consecutive years</b>,\n' +
                   'resulting in the <b>sale of 14,763 tons of surplus emission</b> allowances\nthrough the exchange.'
        }
      ],
      borderBoxDatasJp: [
        {
          title: 'Carbon Disclosure Project',
          content: '2022年に世界平均を上回るCDPグレードBを達成。'
        },
        {
          title: '排出権取引権',
          content: '当社は5年連続で温室効果ガス排出量を削減し、\n' +
                   'その結果、取引所を通じて14,763トンの余剰排出枠を売却しました。'
        }
      ],
      borderBoxDatasRu: [
        {
          title: 'Carbon Disclosure Project',
          content: 'Достижение оценки B в CDP, что выше среднемирового показателя в 2022 году'
        },
        {
          title: 'Права на торговлю квотами на выбросы',
          content: 'Мы сокращаем выбросы парниковых газов уже пять лет подряд,\n' +
                   'в результате мы продали 14 763 тонны избыточных квот на выбросычерез биржу.'
        }
      ],
      borderBoxDatasCn: [
        {
          title: '碳信息披露项目',
          content: '在CDP中获得B级，高于2022年的全球平均水平。'
        },
        {
          title: '排污权交易',
          content: '连续五年减少温室气体排放，\n' +
                   '通过交易所出售了14,763吨剩余排放配额。'
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
.border-box-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 39px;
  margin-top: 64px;
  flex-wrap: wrap;
}

.graph-area {
  justify-content: center;
  display: flex;
}

.graph-area img {
  max-width: 1066px;
}
</style>
