<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content cntu-view reques">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Professionals</li>
          <li>HIMACS Partners Portal</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container  -->
      <div class="container cntu-box inner">
        <div class="w-con-02">
          <h1 class="tt-1 mb-64 tac ">{{$t('partnersPortal.login.himacs_partners_portal')}}</h1>
          <p class="ctt ctt-t2 ta-c">
            <span class="pc-br ctt ctt-t2">Welcome to the Partner Section, a restricted area dedicated to our affiliates.</span>
            <span class="pc-br ctt ctt-t2">Already a HIMACS partner? Login to your account.</span>
          </p>
          <div class="mypg-loginJoin">
            <!-- 로그인창 -->
            <div class="mypg-login">
              <div class="login-box">
                <ul>
                  <li>
                    <label for="inp01">Email</label>
                    <input type="text" class="inp-text" id="inp01" placeholder="" v-model="email" />
                  </li>
                  <li>
                    <label for="inp02">Password</label>
                    <input type="password" class="inp-text" id="inp02" placeholder="" v-model="password" />
                  </li>
                </ul>
              </div>
              <label class="inp-chk w100p" for="chk01">
                <input type="checkbox" id="chk01" checked="checked" v-model="rememberMe">
                <span>Remember me</span>
                <button type="button" class="btn btn-text forgot-email">
                  <span class="txt-idfind-btn" onclick="location.href='himacsPartnersPortal/findYourEmail'">Forgot Your Email?</span>
                </button>
              </label>
              <div class="ta-c mt-40">
                <button type="button" class="btn" @click="login()"><span>Login</span></button>
              </div>
            </div>
            <!-- // 로그인창 -->

            <div class="mypg-sign01">
              <p class="tit-04 hpp-title">Request a partner account </p>
              <div class="t-type flex-btm-align">
                <span class="ctt ctt-t4">Only for existing partners</span>
                <button type="button" onclick="location.href='himacsPartnersPortal/requestPartnerAccount'" class="btn fl-r">
                  <span>Request</span>
                </button>
              </div>
            </div>

            <div class="mypg-forgotPW tyhima bd-btm">
              <p class="tit-04 hpp-title-type-02">Forgot Your Password?</p>
              <div class="inpt-mypg flex-space-between">
                <ul>
                  <li>
                    <label for="inp04">Email</label>
                    <span class="w390">
                      <input type="text" class="inp-text"  id="inp04" placeholder="Enter your email address" v-model="forgotPassEmail"/>
                    </span>
                    <div class="btn-mypg02 dis-in-bloc">
                      <button type="button" class="btn fl-r" @click="forgotPass()"><span>Go</span></button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="mypg-forgotPW tyhima">
              <div class="t-type-03 mt-0 ctt ctt-t4">
                <span class="ctt ctt-t4 color-gray">Not a partner yet?</span>&nbsp;
                <a class="ctt ctt-t4 color-black underline" href="support.html#/contactUs">Contact Us</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- //container  -->
    </div>
    <!-- content -->
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import dateMoment from '@/common/filters'
import memberInfoService from '@/server/myPage.service'
import getDictionaryList from '@/server/support.service'
import UserService from '@/server/user.service'

export default {
  name: 'app',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
      forgotEmail: '',

      forgotPassEmail: ''
    }
  },
  async created () {
  },
  mounted () {

  },
  methods: {
    async login () {
      const myStorage = window.localStorage
      if (this.rememberMe === true) {
        myStorage.setItem('email', this.email)
      } else {
        myStorage.clear()
      }
      const result = await this.$store.dispatch('auth/login_eu', {
        vm: this,
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      })

      if (result) {
        this.email = ''
        this.password = ''
      }
    },
    async loginSuccess () {
      const resAcc = await this.$store.dispatch('account/find')
      // GTM - login
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({
          event: 'loginAction',
          user_login_id: resAcc.data.gtmId,
          user_login_email: resAcc.data.gtmEmail,
          user_login_group: resAcc.data.gtmGroup,
          user_login_marketing_agree: resAcc.data.gtmAgree,
          user_login_registed_date: '',
          user_last_login_date: dateMoment.dateConvert(resAcc.data.gtmLoginDate)
        })
      }

      location.href = 'himacsPartnersPortal/partnersPortal'
    },
    async forgotPass () {
      const { data } = await memberInfoService.getByMemIdEU(this.forgotPassEmail)

      if (data.success == true) {
        const result = await getDictionaryList.getSendEmailEU(this.forgotPassEmail)

        if (result.data.Type == true) {
          alert(this.$t('partnersPortal.login.alertbox.alert1') + this.forgotPassEmail + this.$t('partnersPortal.login.alertbox.alert2'))
          location.reload()
        } else {
          alert('error')
        }
      } else {
        alert(data.message)
        return false
      }
    }
  }
}
</script>

<style scoped>
.inp-chk input+span {
  display: inline-block;
}

@media only screen and (max-width: 480px){
  .btn-darkG {
    bottom: 36%;
    padding: 0 45px 0 25px;
  }

}
</style>
