<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

      <div class="content himacPorta_veiw">
        <div class="location">
          <ul>
            <li class="ico-home">Professionals</li>
            <li>HIMACS Partners Portal</li>
            <li>Request a Partner Account</li>
            <li>Completed</li>
          </ul>
        </div>
        <div class="container equest_content ta-c">
          <div class="w-con-02">
            <p class="mb-64"></p>
            <p class="tt-1 mb-64 tac inner">Thank you!</p>
            <p class="tt-sub mb-88">Thank you for applying for an account.<br>
              Your account is currently pending approval by the site Administrator.<br>
              In the meantime, a welcome message with further instructions<br>
              has been sent to your e-mail address.
            </p>
            <div class="more-box ">
              <button type="button" class="btn" @click="goToMain">
                  <span>Go Back to the Homepage</span>
                </button>
            </div>
          </div>
        </div>
      </div>

    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
    }
  },
  async created () {
  },
  mounted () {
  },
  methods: {
    goToMain () {
      location.href = 'index'
    }
  }
}
</script>
