/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

export default {
  getLoginStatus (state) {
    return state
  }
}
