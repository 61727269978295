import { render, staticRenderFns } from "./DECO_COLOR_GL.vue?vue&type=template&id=27701e2b"
import script from "./DECO_COLOR_GL.vue?vue&type=script&lang=js"
export * from "./DECO_COLOR_GL.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/STG_US_FRONT/synccms-front/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27701e2b')) {
      api.createRecord('27701e2b', component.options)
    } else {
      api.reload('27701e2b', component.options)
    }
    module.hot.accept("./DECO_COLOR_GL.vue?vue&type=template&id=27701e2b", function () {
      api.rerender('27701e2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/finder/DECO_COLOR_GL.vue"
export default component.exports