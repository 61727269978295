var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("thead", { staticClass: "financial-statement-table-thead" }, [
    _c(
      "tr",
      {},
      _vm._l(_vm.categoryList, function (item) {
        return _c("th", { key: item }, [
          _c("div", { staticClass: "financial-statement-table-category" }, [
            _vm._v(_vm._s(item)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }