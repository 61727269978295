<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content inspr-view">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('Document.Text.Home')}}</li>
          <li>{{ $t('all.text.pros') }}</li>
          <li>{{ $t('all.text.designLab')}}</li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container (Design Lab) -->

      <div class="container prod-dt-view">
        <!-- visual -->
        <!-- v-html 임시 비활성 : 아래 CMS markup 적용 필요 -->
         <!-- <div v-html="content"></div> -->

        <!-- CMS markup Example 220531 -->
        <div>
          <div class="prod-img ins-at-dt ins-dl-dt">
          <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/US/image/inspiration/DesignLab&amp;ShowRoom/@img_design_01.png" alt="images"></div>
          <div class="img-box m-ui"><img src="https://img.lxhausys.com/hausys/US/image/inspiration/DesignLab&amp;ShowRoom/@img_articles_07.png" alt="images"></div>
          <div class="dt-text w-con inner">
          <h1 class="tt-1">Design Lab</h1>
          <div class="text ctt ctt-t3">HIMACS&nbsp;Design Lab provides you the option to choose. Simply select a color and submit the color matching request.<br>We will match the color and produce your personalized HIMACS.
          <h2 class="t-02"><strong>HIMACS</strong>Design Lab Benefits</h2>
          <div class="ico-box">
          <ul>
          <li class="ico-01">
          <h3 class="design-lab-circle-tit">Low Minimum Order Quantity</h3>
          <p class="ctt ctt-t5">The minimum order quantity requirement is only 40 sheets.</p>
          </li>
          <li class="ico-02">
          <h3 class="design-lab-circle-tit">Quick Turnaround</h3>
          <p class="ctt ctt-t5">You will receive the product within 6 weeks of order placement.</p>
          </li>
          <li class="ico-03">
          <h3 class="design-lab-circle-tit">Unlimited Possibility</h3>
          <p class="ctt ctt-t5">We can match any solid color imaginable</p>
          </li>
          </ul>
          </div>
          </div>
          </div>
          </div>
          <!-- //visual -->
          <p></p>
          <!-- info text -->
          <h3 class="sr-only">How to Use LX Hausys Desgin Lab</h3>
          <div class="ins-dl-text w-con col-02 inner">
          <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/US/image/inspiration/DesignLab&amp;ShowRoom/@img_design_02.png" alt="img"></div>
          <div class="text-box">
          <ul>
          <li>
          <div class="step"><span>Step</span> 01</div>
          <div class="text"><strong>Choose Your Color</strong>
          <p>You may choose any&nbsp;solid color&nbsp;from an available color resource or color swatch. <br>You also have an option to provide a color sample (such as paint, cloth or other <br>materials with solid color) you want us to match</p>
          </div>
          </li>
          <li>
          <div class="step"><span>Step</span> 02</div>
          <div class="text"><strong>Color Matching &amp; Sample Delivery</strong>
          <p>Request a sample&nbsp;by contacting LX Hausys territory manager or specification manager. <br>We will start the color matching process and deliver the sample for you to review. <br>There is no charge for color matching.</p>
          </div>
          </li>
          <li>
          <div class="step"><span>Step</span> 03</div>
          <div class="text"><strong>Purchase Order</strong>
          <p>Confirm your color&nbsp;upon receiving your sample.<br>Process your order&nbsp;with LX Hausys territory manager or specification manager. <br>Once the order is placed, allow up to six weeks to receive the ordered product.</p>
          </div>
          </li>
          </ul>
          <div class="btn btn-blank">Have Questions or Ready to Order?<a :href="'/' + countryCodeSub.toLowerCase() + '/support/contactus'"><span>Contact Us</span></a></div>
          </div>
          </div>
        </div>
        <!-- END: CMS markup Example 220531 -->

        <!-- short cut -->
        <!-- <div class="short-link">
          <a v-if="nationCode === '_US'" href="javascript:void(0);" @click="openCart()"><span class="ico-01">{{ $t("all.text.orderSample") }}</span></a>
          <a href="pros/documentation/all-products"><span class="ico-02">{{ $t("all.text.document") }}</span></a>
          <a href="support/care-and-maintenance/hflor"><span class="ico-03">{{ $t("all.text.care_main") }}</span></a>
          <a href="support/warranty/hflor"><span class="ico-04">{{ $t("all.text.warranty") }}</span></a>
          <a v-if="nationCode === '_US'" :href="'support/where-to-buy/country/us'"><span class="ico-05">{{ $t("all.text.where_to_buy") }}</span></a>
          <a v-if="nationCode === '_GL'" :href="'support/where-to-buy/country/gl'"><span class="ico-05">{{ $t("all.text.where_to_buy") }}</span></a>
        </div> -->
        <!-- //short cut -->
      </div>
      <!-- //container (Design Lab) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import InspirationService from '@/server/inspiration.service'
import GnbComponent from '@/components/Layout/GNB'
import global from '@/common/global'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    FooterComponent,
    GnbComponent
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  data () {
    return {
      categoryId: [],
      content: '',
      nationCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this
    const added = this.$countryCode
    _this.nationCode = added
    const categoryCode = 'INS_DES' + added
    const category = await InspirationService.getCategoryCode(categoryCode)
    const categoryId = category.data.object.id
    _this.categoryId = categoryId
    const content = await InspirationService.getContentList(_this.categoryId)
    _this.content = content.data.list[0].attribute.editor

    _this.$nextTick(function () {
      var width = window.innerWidth
      if (width < 1280) {
        var $slider = $('.slider-prod.m-ui')

        /* Our Products slider */
        $('.ourprod-wrap.m-ui .ourprod-slide').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          draggable: true
        })

        /* Get Inspired tab menu slider */
        $('.main-box.insp-box .btns.type-main > ul').slick({
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          draggable: true,
          variableWidth: true
          // centerPadding: '35px',
        })
      } else {
        var $slider = $('.slider-prod.p-ui')
      }
      // console.log(width);

      if ($slider.length) {
        if (width >= 1280) {
          var currentSlide
          var slidesCount
          var sliderCounter = document.createElement('div')
          sliderCounter.classList.add('slider__counter')

          var updateSliderCounter = function (slick, currentIndex) {
            currentSlide = slick.slickCurrentSlide() + 1
            slidesCount = slick.slideCount
            $(sliderCounter).text(currentSlide + '/' + slidesCount)
          }

          $slider.on('init', function (event, slick) {
            $slider.append(sliderCounter)
            updateSliderCounter(slick)
          })

          $slider.on('afterChange', function (event, slick, currentSlide) {
            updateSliderCounter(slick, currentSlide)
          })

          $slider.slick({
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true
          })

          /* visual slide > button pause */
          $('.slick-dots').append('<li class="btn-pause"><button type="button">pause</button></li>')
          $('.slick-dots').append('<li class="btn-play"><button type="button">pause</button></li>')

          $('.slick-dots .btn-play').hide()

          $('.btn-pause').click(function () {
            $slider.slick('slickPause')
            $(this).hide()
            $('.slick-dots .btn-play').show()
          })

          $('.btn-play').click(function () {
            $slider.slick('slickPlay')
            $(this).hide()
            $('.slick-dots .btn-pause').show()
          })
        } else {
          $slider.slick({
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true
          })
        }
      }
    })
  },
  methods: {
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    gotolink (url) {
      location.href = url
    }
  }
}
</script>
