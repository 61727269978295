var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SeatCorverLayout", {
    staticClass: "mt-50",
    attrs: { imageSrc: "images/automotive/skin/Calfpelle.png" },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("Calfpelle®")]),
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [_vm._v("• Suede with excellent surface quality")]),
              _c("br"),
              _c("span", [_vm._v("• Soft haptic and superior durability")]),
            ]),
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("Calfpelle® Bio")]),
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [
                _vm._v(
                  "• Environmental Claim Validation of UL-Solutions for biobased content"
                ),
              ]),
              _c("br"),
              _c("span", [_vm._v("• Bio-based content ≥ 10%")]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }