<template>
  <OverviewSection
    title="Trendship 2023"
    italicTitle=" 'SOFTWHERE'"
    size="56px"
    color="#222"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <div class="softwhere-point-image-area">
        <SoftwherePointImage
          v-for="(item, index) in pointImages"
          :key="index"
          :image="item.image"
          :point="item.point"
          :title="item.title"
          :text="item.text"
          :direction="item.direction"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="2023年のトレンド"
    italicTitle="「SOFTWHERE」"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="textJp"
      />
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <div class="softwhere-point-image-area">
        <SoftwherePointImage
          v-for="(item, index) in pointImagesJp"
          :key="index"
          :image="item.image"
          :point="item.point"
          :title="item.title"
          :text="item.text"
          :direction="item.direction"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Trendship 2023"
    italicTitle=" 'SOFTWHERE'"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="textRu"
      />
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <div class="softwhere-point-image-area">
        <SoftwherePointImage
          v-for="(item, index) in pointImagesRu"
          :key="index"
          :image="item.image"
          :point="item.point"
          :title="item.title"
          :text="item.text"
          :direction="item.direction"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="Trendship 2023"
    italicTitle=" 'SOFTWHERE'"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="textCn"
      />
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <div class="softwhere-point-image-area">
        <SoftwherePointImage
          v-for="(item, index) in pointImagesCn"
          :key="index"
          :image="item.image"
          :point="item.point"
          :title="item.title"
          :text="item.text"
          :direction="item.direction"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../../sustainability/components/SustainabilityText.vue'
import SoftwherePointImage from './SoftwherePointImage.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    SustainabilityText,
    SoftwherePointImage
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'During the pandemic period, as indoor activities increased, homes have evolved from being merely refined spaces for\n' +
            'relaxation into multifunctional areas where anything can be done. Alongside this evolution, highly advanced technology now acts as a\n' +
            'mediator between the environment and humans, gently entering our homes and lives to care for our bodies and minds.\n' +
            'While seeking liberation from the fatigue induced by the digital environment, current spatial trends encompass the\n' +
            'duality of utilizing technology to enhance our senses and bring nature closer, embracing both aspects.\n' +
            'We draw attention to these changes and introduce a design trend\n' +
            '\'SOFTWHERE\' where technology, environment, and people are fluidly interconnected.',
      textJp:'パンデミック期、室内での活動が増えるにつれ、家は単なるくつろぎの場から、何でもできる多機能な空間へと進化しました。\n' + 
            'この進化とともに、高度なテクノロジーが環境と人間の仲介役となり、\n' + 
            '私たちの家や暮らしにそっと入り込み、心身をケアするようになりました。\n' +
            'デジタル環境による疲労からの解放を求めながら、現在の空間トレンドは、テクノロジーを活用して感覚を高め、\n' +
            '自然を身近にするという二面性を持ち、その両方を取り入れています。私たちは、こうした変化に注目し、\n' +
            'テクノロジー、環境、人が流動的に繋がるデザイントレンド「SOFTWHERE」を紹介します。',
      textRu:'В период пандемии, по мере роста активности, дома превратились из просто изысканных помещений для отдыха в многофункциональные зоны,\n' + 
      'где можно делать все, что угодно. Наряду с этой эволюцией высокоразвитые \n' + 
      'технологии теперь выступают в роли посредника между окружающей средой и человеком,\n' + 
      'мягко проникая в наши дома и жизни, чтобы заботиться о нас. Стремясь освободиться от усталости, вызванной цифровым окружением, \n' + 
      'современные тенденции в области пространственных решений охватывают двойственность использования технологий для улучшения наших \n' + 
      'чувств и приближения к природе, охватывая оба аспекта. Мы обращаем внимание на эти изменения и представляем дизайнерский тренд \n' +
      '«SOFTWHERE», где технологии, окружающая среда и люди тесно взаимосвязаны.',
      textCn:'近几年，随着疫情期间居家活动的增加，家已经演变成一个多功能空间，而不只是一个停滞不前的休息空间。\n' + 
      '目前，高度发达的科技充当了环境与人之间的媒介，进入房屋和我们的生活，呵护我们的身心。\n' + 
      '现在的趋势是兼顾两者，例如利用技术增强感官和描绘自然，同时寻求从数字环境的疲劳中解脱出来。\n' + 
      '今年，LX Hausys关注这些变化，推出了室内装修的关键词“SOFTWHERE”，这是一种将技术、环境和人类平滑连接的多感官设计趋势。',
      pointImages: [
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image1.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'Scenesory',
          text: 'A space for emotional relaxation\nexplored through the senses',
          direction: 'left'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image2.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'Monologue',
          text: 'A sturdy sanctuary\nholding unchanging values',
          direction: 'right'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image3.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'White Night',
          text: 'A dreamy refuge where\nreality and the virtual coexist',
          direction: 'left'
        }
      ],
      pointImagesJp: [
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image1.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'シーンソリー',
          text: '感覚を通して探求される感情的なリラ\nクゼーションのための空間',
          direction: 'left'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image2.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'モノローグ',
          text: '変わらない価値観を\n保つ堅固な聖域',
          direction: 'right'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image3.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'ホワイトナイト',
          text: '現実と仮想が共存する\n夢のような隠れ家',
          direction: 'left'
        }
      ],
      pointImagesRu: [
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image1.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'Сценарии',
          text: 'Пространство для эмоциональной релаксации\nисследуется через органы чувств',
          direction: 'left'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image2.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'Монолог',
          text: 'Прочное убежище,\nхранящее неизменные ценности',
          direction: 'right'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image3.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: 'Белая ночь',
          text: 'Мечтательное убежище, где\nреальность и виртуальность сосуществуют',
          direction: 'left'
        }
      ],
      pointImagesCn: [
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image1.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: '五感之所',
          text: '通过感官探索的情感放松空间',
          direction: 'left'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image2.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: '独自',
          text: '一个坚守不变价值观的坚固避难所',
          direction: 'right'
        },
        {
          image: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-image3.png',
          point: 'images/aboutus/r-and-d-center/marketLeadingDesign/softwhere-point.png',
          title: '白夜',
          text: '现实与虚拟共存的梦幻避难所',
          direction: 'left'
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
.softwhere-point-image-area {
  display: flex;
  flex-direction: column;
  gap: 85px;
  width: 1125px;
}

@media (max-width: 768px) {
  .softwhere-point-image-area {
    width: 100%;
    gap: 30px;
  }
}
</style>
