var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-component-group-area" }, [
    _c(
      "div",
      { staticClass: "sec" },
      [
        _vm._t("left-header"),
        _vm._l(_vm.left, function (it, idx) {
          return _c(
            "div",
            { key: idx, staticClass: "item" },
            [_c("ProductComponent", { attrs: { info: it } })],
            1
          )
        }),
      ],
      2
    ),
    _c("div", { staticClass: "divider" }),
    _c(
      "div",
      { staticClass: "sec" },
      [
        _vm._t("right-header"),
        _vm._l(_vm.right, function (it, idx) {
          return _c(
            "div",
            { key: idx, staticClass: "item" },
            [_c("ProductComponent", { attrs: { info: it } })],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }