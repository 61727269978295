<template>
  <div class="product-component-group-area">
    <div class="sec">
      <slot name="left-header"></slot>

      <div
        v-for="(it, idx) in left"
        :key="idx"
        class="item"
      >
        <ProductComponent :info="it" />
      </div>
    </div>
    <div class="divider"></div>
    <div class="sec">
      <slot name="right-header"></slot>

      <div
        v-for="(it, idx) in right"
        :key="idx"
        class="item"
      >
        <ProductComponent :info="it" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductComponent from '@/components/product/ProductComponent'

export default {
  components: {
    ProductComponent
  },

  props: {
    left: {
      type: Array,
      required: true
    },
    right: {
      type: Array,
      required: true
    }
  }
}

</script>

<style scoped>
.product-component-group-area {
  display: block;
  /* flex-direction: row; */
  /* justify-content: space-between; */
}
@media (min-width: 769px) {
  .product-component-group-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .product-component-group-area.mt-100.mx-30{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.product-component-group-area .sec {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.product-component-group-area .divider {
  flex-basis: 60px;
}

.product-component-group-area .sec .item {
  margin-bottom: 20px;
}
@media (min-width: 769px) {
  .product-component-group-area .sec .item {
    margin-bottom: 50px;
  }
}

</style>
