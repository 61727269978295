<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>FORMAT</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
        <span class="checkbox_more no_line">
          <li v-for="format in formatList" :key="format.id.value" :data-chk="format.id.value">
            <input
              type="checkbox"
              :id="'format_'+format.id.value"
              name="Format"
              v-model="selected"
              :value="format.id.value"
            />
            <label :for="'format_'+format.id.value">
              <span></span>
              {{ format.text }}
            </label>
          </li>
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'FormatFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['formatList', 'value'],
  computed: {
    ...mapState({
      // 필요한 state가 있다면 여기에 추가
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        const selectedFormat = newSelected.map(formatId => {
          const format = this.formatList.find(format => format.id.value === formatId)
          return format ? format.id.value : null
        }).filter(formatId => formatId !== null)

        if (JSON.stringify(selectedFormat) !== JSON.stringify(this.value)) {
          this.$emit('input', selectedFormat)
        }
      },
      deep: true
    },
    value: {
      handler (newValue) {
        if (JSON.stringify(this.selected) !== JSON.stringify(newValue)) {
          this.selected = [...newValue]
        }
      },
      deep: true
    }
  },
  mounted () {
    this.selected = [...this.value]

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-format-filter', this.removeFormat)
    EventBus.$on('update-format-filters', this.updateFormats)

    if (this.$store.state.filterState && this.$store.state.filterState.format) {
      this.selected = [...this.$store.state.filterState.format]
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$off('remove-format-filter', this.removeFormat)
    EventBus.$off('update-format-filters', this.updateFormats)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeFormat (formatValue) {
      this.selected = this.selected.filter(value => value !== formatValue)
    },
    updateFormats (formats) {
      this.selected = [...formats]
    }
  }
}
</script>
