var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scopes-of-impact" },
    [
      _c("SocialResponsibilityBorderTitle", { attrs: { title: _vm.title } }),
      _c("div", { staticClass: "scopes-of-impact-content" }, [
        _c("div", { staticClass: "circle-area" }, [
          _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
        ]),
        _c("pre", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.description)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }