<template>
  <div class="list-area">
    <ul class="square-list">
      <li v-for="item in contentList" :key="item.index">
        <span v-html="item.content"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    contentList: {
      type: Array
    }
  }
}
</script>

<style scoped>

.list-area .square-list {
  gap: 10px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
}

.list-area .square-list li {
  list-style: square;
}

.list-area .square-list span {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
@media (max-width: 1440px) {
  .list-area .square-list span {
    font-size: 14px;
    line-height: 1.4;
  }
}
@media (max-width: 768px) {
  .list-area .square-list span {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.list-area .square-list span b {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
}
@media (max-width: 1440px) {
  .list-area .square-list span b {
    font-size: 14px;
    line-height: 1.4;
  }
}
@media (max-width: 768px) {
  .list-area .square-list span b {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

</style>
