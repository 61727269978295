var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("h1", { staticClass: "sr-only" }, [
        _vm._v("Sustainability Management System"),
      ]),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.sustainabilityManagementSystem) },
      }),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }