<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content">

      <!-- container (Case Studies Finder) -->
      <div class="container bbs-box inspr-view case-studies">
        <div class="sub_title_area full_width">
          <div class="inner_width">
            <!-- location -->
            <div class="location">
              <ul>
                <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">
                  {{ $t('caseStudies.text.home') }}
                </li>
                <li>{{ $t('caseStudies.text.inspiration') }}</li>
                <li>{{ $t('caseStudies.text.caseStudies') }}</li>
              </ul>
            </div>
            <!-- //location -->
            <h1 class="page_title tt-1">{{ $t('caseStudies.text.caseStudies') }} </h1>
          </div>
        </div>
        <div class="w-con">
          <div class="gallery">
            <!-- //featured Project List-->
            <div class="casestudy_sliderwrap">
              <VueSlickCarousel v-bind="slickSettings" v-if="topImageSlideList.length">
                <div class="item" v-for=" item in topImageSlideList" @click="moveToDetail(item.content)">
                  <div class="imgwrap">
                    <img :src="`${$imagePath}${item.attribute.mainThumbnail}`" :alt="item.content.title"/>
                  </div>
                  <div class="txtwrap">
                    <ul>
                      <li class="type">
                        <template v-for="segmentsItem in getSeletedSegmentsName(item.attribute)">
                          <span v-html="segmentsItem.text"></span>
                        </template>
                      </li>
                      <li class="tit">
                        <span class="tit" v-html="item.content.title"/>
                      </li>
                      <li class="location">
                        <span class="where" v-html="item.attribute.location"/>
                      </li>
                    </ul>
                  </div>
                  <div class="tagwrap" v-if="item.attribute.productList">
                    <span v-html="item.attribute.productList.value"/>
                    <span class="line"/>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>

            <!-- s: 상단 검색영역 -->
            <div class="search-box">
              <div class="filter_area" ref="filterArea">
                <div class="filter_wrap">
                  <button type="button" class="btn_filter" :class="{ 'on': isFilterOpen }"
                          @click="isFilterOpen = !isFilterOpen">
                    <span class="icon icon_filter"></span>
                    <span class="txt p-ui">Filter</span>
                    <span class="txt m-ui">Filter by</span>
                    <span class="icon icon_arrow_down"></span>
                  </button>
                </div>
                <!-- s: filter popup -->
                <transition name="fade">
                  <div class="filter_pop on" v-if="isFilterOpen">
                    <div class="filter_tit">
                      <p>FILTER BY</p>
                      <button type="button" class="btn_close" @click="isFilterOpen = !isFilterOpen"></button>
                    </div>
                    <div class="filter_top">
                      <ul>
                        <!-- product -->
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>PRODUCT</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <input type="checkbox" id="product_checkall" name="product"
                                     v-model="isCheckAllProduct"
                                     @change="handleCheckBoxAll($event,'productList','productCheckList',0)"/>
                              <label for="product_checkall">Check All</label>
                            </span>
                              <span class="checkbox_more">
                            <span v-for=" (item,index) in productList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="productCheckList"
                                       :value="item.value"
                                       @change="handleCheckBox($event,'productList','productCheckList','isCheckAllProduct',0)"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                          </div>
                        </li>
                        <!-- segments -->
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>SEGMENTS</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <span class="checkbox_subtit">Residential</span>
                              <input type="checkbox" id="residential_checkall" name="residential"
                                     v-model="isCheckAllResidential"
                                     @change="handleCheckBoxAll($event,'residentialList','residentialCheckList',2)"/>
                              <label for="residential_checkall">Check All</label>
                            </span>
                              <span class="checkbox_more">
                              <span v-for=" (item,index) in residentialList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="residentialCheckList"
                                       :value="item.value"
                                       @change="handleCheckBox($event,'residentialList','residentialCheckList','isCheckAllResidential',2)"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                            <div class="checkbox_box">
                            <span class="checkbox_all">
                              <span class="checkbox_subtit">Commercial</span>
                              <input type="checkbox" id="commercial_checkall" name="commercial"
                                     v-model="isCheckAllCommercial"
                                     @change="handleCheckBoxAll($event,'commercialList','commercialCheckList',1)"/>
                              <label for="commercial_checkall">Check All</label>
                            </span>
                              <span class="checkbox_more">
                              <span v-for=" (item,index)  in commercialList">
                                <input type="checkbox" :id="`${item.id}${index}`" v-model="commercialCheckList"
                                       :value="item.value"
                                       @change="handleCheckBox($event,'commercialList','commercialCheckList','isCheckAllCommercial',1)"/>
                                <label :for="`${item.id}${index}`" v-html="item.id"/>
                              </span>
                            </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="filter_bottom">
                      <div class="btn_box">
                        <button href="javascript:void(0);" class="btn_wh" @click="handleCloseBadge('all')">Clear All
                        </button>
                        <button type="button" class="btn_bk" @click="handleClickApply">Apply</button>
                      </div>
                    </div>
                  </div>
                </transition>
                <!-- e: filter popup -->
                <div class="search">
                  <input type="text" id="textSearch" placeholder="Enter Item Number or Name"
                         value=""
                         v-model="searchInfo.searchText"
                         @input="handleSearch"
                         @keyup.enter="handleSearch"
                         ref="search"
                  />
                  <button type="button" class="btn_cancel" v-if=" searchInfo.searchText.length !== 0"
                          @click="handleSearchDelete()"
                  />
                  <button type="button" class="btn_search" @click="handleSearch" v-else/>
                </div>
              </div>
              <div class="sort_area no_bottom_line">
                <template v-if="badgeList.length > 0">
                  <div class="select-view">
                    <ul class="list-filter-key">
                      <li class="sel" v-for=" item in badgeList" style="cursor: pointer"
                          @click="handleCloseBadge(item.value)">
                        <span>{{ item.id }}</span>
                        <button type="button"></button>
                      </li>
                      <li class="sel del_all" v-if="badgeList.length > 0" @click="handleCloseBadge('all')">
                        <button type="button" class="btn-all-delete"><span>Clear All</span></button>
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
              <div class="count_area">
                <div class="filter-cnt">
                  <span class="num">{{ pageInfo.totalCount }}</span>
                  <span>&nbsp;</span>
                  <span class="txt">{{ $t('gallery.text.results') }}</span>
                </div>
              </div>
            </div>
            <template v-if="isLoading">
              <div class="grid-box">
                <div class="no_result">
                  <div class="loading_area">
                    <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- e: 상단 검색영역 -->
              <template v-if="pageList.length === 0">
                <div class="grid-box">
                  <div class="no_result">
                    <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult">
                    <p>No Result found</p>
                    <span>If you do not find what you are looking for, please</span>
                  </div>
                </div>
              </template>
              <template>
                <!-- s: content -->
                <div class="grid-box">
                  <ul class="ins_grid">
                    <template v-for="(item, index) in pageList">
                      <li class="cs-grid-cont">
                        <button type="button" class="g-box" @click="moveToDetail(item.content)" :data-case="makeDataLayerForm(item)">
                          <div class="img-box">
                            <img :src="`${$imagePath}${item.attribute.mainThumbnail}`" alt="thumb"/>
                          </div>
                          <div class="txt-box">
                            <p class="txt-cate" v-if="item.attribute">
                              <span v-html="item.attribute.location"></span>
                            </p>
                            <p class="content_title" v-html="item.attribute.lead"/>
                          </div>
                          <div class="tagwrap" v-if="item.attribute.productList">
                            <span>{{ item.attribute.productList.value }}</span>
                            <span class="line"></span>
                          </div>
                        </button>

                        <div class="cont_name">
                          <div class="tag">
                            <template v-if="item.attribute">
                              <template v-for=" segmentsItem in getSeletedSegmentsName(item.attribute) ">
                                <span v-html="segmentsItem.text"></span>
                              </template>
                            </template>
                          </div>
                          <div class="name">
                            <span v-html="item.content.title"/>
                          </div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="btn_area" v-if="pageList.length !== 0 && isLoadMore">
                  <template v-if="isLoadMoreLoading">
                    <div class="loading_area">
                      <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
                    </div>
                  </template>
                  <template v-else>
                    <button type="button" class="btn_load_more" @click="handleClickLoadMore">
                      <span class="txt">Load More</span>
                      <span class="icon icon_plus"></span>
                    </button>
                  </template>
                </div>
              </template>
              <!-- e: content -->
            </template>
          </div>
        </div>

      </div>
      <!-- //container (Case Studies Finder) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import InspirationService from '@/server/inspiration.service'
import ProductService from '@/server/product.service'
import { debounce, isEmpty } from 'lodash'
import InfiniteLoading from 'vue-infinite-loading'
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex'

export default {
  name: 'Detail',
  components: {
    GnbComponent,
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    VueSlickCarousel,
    FooterComponent,
    InfiniteLoading
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    }),
    groupedPageList () {
      const chunkSize = 8
      const groups = []
      for (let i = 0; i < this.pageList.length; i += chunkSize) {
        groups.push(this.pageList.slice(i, i + chunkSize))
      }
      return groups
    }
  },
  data () {
    return {
      productList: [],
      commercialList: [],
      residentialList: [],
      productCheckList: [],
      commercialCheckList: [],
      residentialCheckList: [],
      badgeList: [],
      pageList: [],
      pageInfo: {},
      isLoadMore: true,
      isFilterOpen: false,
      isCheckAllProduct: false,
      isCheckAllCommercial: false,
      isCheckAllResidential: false,
      isCheckApplication: false,
      isLoading: true,
      isLoadMoreLoading: false,
      topImageSlideList: [],
      countryCode: '',
      countryCodeSub: '',
      searchInfo: {
        pageCategotyId: 0,
        categoryidList: [],
        pageSize: 8,
        pageIndex: 1,
        searchText: ''
      },
      relatedCheck: {
        productList: 'productCheckList',
        commercialList: 'commercialCheckList',
        residentialList: 'residentialCheckList'
      },
      dictionaryConditionSearchType: {
        productList: 'productListSelected',
        commercialList: 'commercialListSelected',
        residentialList: 'residentialListSelected'
      },
      badgeIdList: {
        productList: 'PRODUCT',
        commercialList: 'COMMERCIAL',
        residentialList: 'RESIDENTIAL'
      },
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      slickSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        draggable: true,
        infinite: false,
        dotsClass: 'casestudy_dotswrap',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              infinite: false
            }
          }
        ]
      },
      nationCode: ''
    }
  },
  async created () {
    this.countryCode = this.$countryCode.slice(1)
    document.addEventListener('click', this.handleClickOutside)

    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  async mounted () {
    const filter = this.$route.params.filter
    const filterType = this.$route.params.filterType

    /* 카테고리 */
    const cateChildren = await InspirationService.getCategoryCode(`${this.countryCode}_INS_CAS_TOBE`)
    this.searchInfo.pageCategotyId = cateChildren.data.object.id
    this.searchInfo.categoryidList.push(cateChildren.data.object.id)
    /* 필터 리스트 */

    const commercialList = await InspirationService.getDictionary(`CASE_CMMR_${this.countryCode}`)
    this.commercialList = this.setFilterList(commercialList.data, 1)

    const residentialList = await InspirationService.getDictionary(`CASE_RSDN_${this.countryCode}`)
    this.residentialList = this.setFilterList(residentialList.data, 1)

    const product = await ProductService.getDictionary(`CASE_PRODUCT_TYPE_${this.countryCode}`)
    this.productList = this.setFilterList(product.data, 1)

    const res = await InspirationService.getTopImageSlideList(this.searchInfo.pageCategotyId)

    res.data.result.forEach((listItem, listIndex) => {
      Object.keys(listItem.attribute).forEach((item, index) => {
        const exceptList = ['commercialList', 'residentialList', 'productList', 'metaDataList', 'usedMaterialList', 'templateList']
        if (exceptList.includes(item)) {
          res.data.result[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
        }
      })
    })

    this.topImageSlideList = res.data.result

    if (filter !== undefined) {
      if (filterType !== undefined) {
        Object.keys(this.dictionaryConditionSearchType).forEach((item) => {
          if (this.dictionaryConditionSearchType[item] === filterType) {
            if (filterType === 'productListSelected') {
              this.dictionaryCondition.list[0].values.push({
                id: filterType,
                value: filter.toUpperCase()
              })
              this[this.relatedCheck[item]].push(filter.toUpperCase())
              this.badgeList.push({ id: `${this.badgeIdList[item]}: ${filter.toUpperCase()}`, value: filter.toUpperCase() })
            } else {
              this.dictionaryCondition.list[0].values.push({
                id: filterType,
                value: filter
              })
              this[this.relatedCheck[item]].push(filter)
              this.badgeList.push({ id: `${this.badgeIdList[item]}: ${filter}`, value: filter })
            }
          }
        })
      } else {
        const filterName = {
          commercial: 'commercialList',
          residential: 'residentialList'
        }
        this[filterName[filter]].forEach((item) => {
          this.badgeList.push({ id: `${this.badgeIdList[filterName[filter]]}: ${item.id}`, value: item.id })
          this[this.relatedCheck[filterName[filter]]].push(item.value)
          this.dictionaryCondition.list[0].values.push({
            id: this.dictionaryConditionSearchType[filterName[filter]],
            value: item.id
          })
        })
        if (filter === 'commercial') {
          this.isCheckAllCommercial = true
        } else {
          this.isCheckAllResidential = true
        }
      }
    }

    /* 리스트 조회 */
    this.getList()
  },
  methods: {
    makeDataLayerForm (data) {
      return JSON.stringify({
        case_title: data.content?.title ?? '',
        case_segment: Array.isArray(data.attribute.commercialList)
          ? data.attribute.commercialList.map(item => item.value).join(' | ')
          : (data.attribute.commercialList?.value || ''),
        case_prd_brand: data.attribute.productList?.value || '',
        // 로케이션 협의 이후 진행 현재 텍스트로 입력하게 되어있음
        // case_location2: data.attribute?.location,
        case_location3: data.attribute?.location ?? ''
      })
    },
    gotolink (url) {
      location.href = url
    },
    handleClickOutside (event) {
      const filterArea = this.$refs.filterArea
      if (filterArea && !filterArea.contains(event.target)) {
        this.isFilterOpen = false
      }
    },
    handleCheckBoxAll (event, checktype, checkList) {
      const { checked } = event.target
      if (checked) {
        this[checkList] = this[checktype].map((item) => item.value)
        const badgeInnerList = this[checkList].map((item) => ({
          id: `${this.badgeIdList[checktype]}: ${item}`,
          value: item
        }))
        this.badgeList = this.badgeList.concat(badgeInnerList)
        const dictionaryConditionValues = this[checktype].map((item) => ({
          id: this.dictionaryConditionSearchType[checktype],
          value: item.id
        }))
        this.dictionaryCondition.list[0].values = this.dictionaryCondition.list[0].values.concat(dictionaryConditionValues)
      } else {
        this[checkList] = []
        const filteredArray = this.badgeList.filter(obj1 => {
          for (const obj2 of this[checktype]) {
            if (obj1.id === `${this.badgeIdList[checktype]}: ${obj2.id}` && obj1.value === obj2.value) {
              return false
            }
          }
          return true
        })
        this.badgeList = filteredArray

        this.dictionaryCondition.list[0].values = this.dictionaryCondition.list[0].values.filter(item =>
          !this[checktype].some(filterItem => filterItem.value === item.value && this.dictionaryConditionSearchType[checktype] === item.id)
        )
      }
    },
    handleCheckBox (event, checktype, checkList, allCheck) {
      const { checked, value } = event.target
      this[allCheck] = this[checktype].length === this[checkList].length
      const checkObject = this[checktype].find((item) => item.value === value)
      if (checked) {
        this.badgeList.push({ id: `${this.badgeIdList[checktype]}: ${checkObject.id}`, value: checkObject.value })
        this.dictionaryCondition.list[0].values.push({
          id: this.dictionaryConditionSearchType[checktype],
          value: checkObject.id
        })
      } else {
        this.badgeList = this.badgeList.filter((item) => item.value !== value)
        this.dictionaryCondition.list[0].values = this.dictionaryCondition.list[0].values.filter((item) => item.value !== checkObject.id)
      }
    },
    handleCloseBadge (clickCode) {
      if (clickCode === 'all') {
        this.badgeList = []
        this.productCheckList = []
        this.commercialCheckList = []
        this.residentialCheckList = []
        this.isCheckAllProduct = false
        this.isCheckAllCommercial = false
        this.isCheckAllResidential = false
        this.isCheckApplication = false
        this.dictionaryCondition.list.forEach((item, index) => {
          this.dictionaryCondition.list[index] = { operator: 'OR', values: [] }
        })
        this.isFilterOpen = false
        this.getList()
      } else {
        this.badgeList = this.badgeList.filter((item) => item.value !== clickCode)
        this.productCheckList = this.productCheckList.filter((item) => item !== clickCode)
        this.commercialCheckList = this.commercialCheckList.filter((item) => item !== clickCode)
        this.residentialCheckList = this.residentialCheckList.filter((item) => item !== clickCode)
        this.dictionaryCondition.list.forEach((item, index) => {
          const isCorrect = this.dictionaryCondition.list[index].values.some((item) => item.value === clickCode)
          if (isCorrect) {
            this.dictionaryCondition.list[index].values = this.dictionaryCondition.list[index].values.filter((item) => item.value !== clickCode)
          }
        })
      }
      this.getList()
    },
    handleSearch: debounce(function () {
      this.getList()
    }, 300),
    handleSearchDelete () {
      this.searchInfo.searchText = ''
      this.getList()
    },
    handleClickApply () {
      this.isFilterOpen = false
      this.getList()
    },
    handleClickLoadMore () {
      this.searchInfo.pageSize += 8
      this.getList('isLoadMoreLoading')
    },
    async getList (loadingType) {
      try {
        this[loadingType === undefined ? 'isLoading' : loadingType] = true
        const { data } = await InspirationService.getContentListTap(this.searchInfo.categoryidList, this.dictionaryCondition, this.searchInfo.searchText, 'search_text', this.searchInfo.pageIndex, this.searchInfo.pageSize)

        data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['productList', 'commercialList', 'residentialList', 'templateList', 'usedMaterialList', 'relatedContentsList']
            if (exceptList.includes(item)) {
              data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })

        if (data.totalCount > data.list.length) {
          this.isLoadMore = true
        } else {
          this.isLoadMore = false
        }
        this.pageInfo = data
        this.pageList = data.list
      } catch (e) {
        console.log('에러', e)
      } finally {
        this[loadingType === undefined ? 'isLoading' : loadingType] = false
      }
    },
    setFilterList (list, type) {
      let filterList = []
      /*
        1. Dictionary
        2. Category
       */
      if (type === 1) {
        filterList = list.map((item) => ({
          value: String(item.id.value),
          id: item.text
        }))
      } else {
        filterList = list.map((item) => ({
          value: String(item.id),
          id: item.name
        }))
      }
      return filterList
    },
    getSeletedSegmentsName (pageList) {
      let seletedSegments = []

      if (pageList.commercialList !== undefined) {
        seletedSegments = seletedSegments.concat(pageList.commercialList)
      }
      if (pageList.residentialList !== undefined) {
        seletedSegments = seletedSegments.concat(pageList.residentialList)
      }

      return seletedSegments
    },
    moveToDetail (content) {
      const { id, title, slug } = content
      if (isEmpty(slug)) {
        const seoTitle = title.toLowerCase().replace(' ', '-')
        this.$router.push({ name: 'caseStudiesDetails', params: { contentName: seoTitle, contentId: id } })
      } else {
        this.$router.push({ path: `/case-studies/detail/${slug}` })
      }
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
