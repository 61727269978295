<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
     <!-- content -->
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Professionals</li>
          <li>HIMACS Partners Portal </li>
          <li>Password Expired</li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container  -->
      <div class="container reques" >
         <div class="w-con-02 inner">
          <p class="tt-1 tac">PASSWORD EXPIRED</p>
          <div class="mypg-loginJoin pw-expir-wrap">
            <div class="mypg-forgotPW">
              <div class="ctt ctt-t3 mt-type">You are required to change your password at least 90 days.<br>Please enter a new password below so you may log in.</div>
              <div class="inpt-mypg">
                <ul>
                  <li class="tooTip">
                    <label for="inp03">New Password</label>
                    <div class="password-box">
                      <input type="password" id="inp03" placeholder="New Password"
                        class="inp-text tooltip-inp" v-model="password.value">
                    </div>
                    <div class="tar">
                      <span class="noti-02" v-if="password.value==='' && submitChk === true">Enter Password</span>
                      <span class="noti-02" v-if="submitChk && password.value !== '' && weakConst">Password Does Not Meet The Requirements</span>
                    </div>

                    <!-- 비밀번호 체크 툴팁 -->
                    <div class="toTip">
                      <dl class="stepList">
                        <dt class="ctt ctt-t5">Your password must have:</dt>
                        <dd class="ctt ctt-t5 must-step">
                          <!-- 단계별 active 클래스 추가시 활성화 -->
                          <span class="ctt ctt-t5 cha-10" v-bind:class="{ active: lengthConst.test(password.value) }">10 or more characters</span>
                          <span class="ctt ctt-t5 upper" v-bind:class="{ active: capLetterConst.test(password.value) && smallLetterConst.test(password.value) }">Upper &amp; lowercase letters</span>
                          <span class="ctt ctt-t5 least" v-bind:class="{ active: numberConst.test(password.value) }">At least one number</span>
                        </dd>
                        <dt class="ctt ctt-t5">
                          <span class="ctt ctt-t5">Strength: </span>
                          <!-- 단계 week moderate strong  -->
                          <span class="ctt ctt-t5" v-if="weakConst">weak</span>
                          <span class="ctt ctt-t5" v-if="moderateConst">moderate</span>
                          <span class="ctt ctt-t5" v-if="strongConst">strong</span>
                        </dt>
                        <dd class="progress-area">
                          <div class="progress-bar" v-if="weakConst">
                            <!-- 첫단계 하나만 week 추가  -->
                            <!-- 두번째 단계 두개만 normal 추가  -->
                            <!-- 세번째 단계 모두 strong 추가  -->
                            <div class="step-01 week"></div>
                            <div class="step-02 "></div>
                            <div class="step-03 "></div>
                           </div>
                           <div class="progress-bar" v-if="moderateConst">
                             <div class="step-01 normal"></div>
                             <div class="step-02 normal"></div>
                             <div class="step-03 "></div>
                           </div>
                           <div class="progress-bar" v-if="strongConst">
                             <div class="step-01 strong"></div>
                             <div class="step-02 strong"></div>
                             <div class="step-03 strong"></div>
                           </div>
                        </dd>
                        <dd class="ctt ctt-t5 pw-easy">
                          Avoid using a password that you use
                          with other websites or that might be
                          easy for some one else to guess.
                        </dd>
                        <dd class="ctt ctt-t5 pw-unacceptable" v-if="weakConst && letterNumberCharConst.test(password.value)">
                          Your password contains
                          unacceptable characters.
                        </dd>
                        <dd class="ctt ctt-t5 pw-unacceptable" v-if="weakConst && repeatConst.test(password.value)">
                          Too many consecutive identical
                          characters.
                        </dd>
                      </dl>
                    </div>
                    <!-- 툴팁 끝  -->

                  </li>

                  <li>
                    <label for="inp04">Confirm Password</label>
                    <div class="password-box">
                      <input type="password" id="inp04" placeholder="Confirm Password"
                        class="inp-text" v-model="chkpassword.value">
                    </div>
                    <div class="tar">
                      <span class="noti-02" v-if="chkpassword.value !== password.value && submitChk === true">The Passwords You Entered Do Not Match</span>
                    </div>
                  </li>
                  <!---->
                  <!---->
                </ul>
              </div>
              <div class="btn-mypg"><button type="button"
                  class="btn" @click="confirm()"><span>Reset Password</span></button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->

    <!-- MODAL : Resend-->
    <div class="layer-popup pop-modal-s1 ResetPass">
      <div class="popup">
        <div class="scr-y">
          <div class="tit-box">
            <p class="heading-4">Reset Password</p>
          </div>
          <div class="modal-con">
            <div class="check">
              <img
                src="https://img.lxhausys.com/public/images/img_FindID_success.png"></div>
            <div class="msg01">
                        <span class="txt01">
                           Your password has been changed successfully.
                        </span>
            </div>
          </div>
          <div class="modalBtn-01">
            <button type="button" class="btn-type-large" @click="goLogin()">
                        <span>
                            Return to Login page</span>
            </button>
          </div>
          <button type="button" class="btn-close" @click="closePopup()">close</button>
        </div>

      </div>
    </div>

    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import UserService from '@/server/user.service'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      password: {
        value: '',
        check: true, /* null 체크 */
        length: true /* length 체크 */
      },
      chkpassword: {
        value: '',
        check: true, /* null 체크 */
        match: true /* 매치 체크 */
      },
      token: '',
      Popcount: '',
      submitChk: false,
      valid: false,

      capLetterConst: /(?=.*[A-Z])/,
      smallLetterConst: /(?=.*[a-z])/,
      numberConst: /(?=.*[0-9])/,
      specCharConst: /(?=.*[$@$!%*#?&])/,
      lengthConst: /^.{10,}$/,
      letterNumberCharConst: /(?=.*[^A-Za-z0-9$@$!%*#?&])/,
      repeatConst: /(.)\1{2,}/

    }
  },
  computed: {
    weakConst: function () {
      return (!this.lengthConst.test(this.password.value) || !this.capLetterConst.test(this.password.value) ||
        !this.smallLetterConst.test(this.password.value) || !this.numberConst.test(this.password.value) ||
        this.letterNumberCharConst.test(this.password.value) || this.repeatConst.test(this.password.value)) && this.password.value !== ''
    },
    moderateConst: function () {
      return this.lengthConst.test(this.password.value) && this.capLetterConst.test(this.password.value) &&
        this.smallLetterConst.test(this.password.value) && this.numberConst.test(this.password.value) &&
        !this.specCharConst.test(this.password.value) && !this.letterNumberCharConst.test(this.password.value) &&
        !this.repeatConst.test(this.password.value)
    },
    strongConst: function () {
      return this.lengthConst.test(this.password.value) && this.capLetterConst.test(this.password.value) &&
        this.smallLetterConst.test(this.password.value) && this.numberConst.test(this.password.value) &&
        this.specCharConst.test(this.password.value) && !this.letterNumberCharConst.test(this.password.value) &&
        !this.repeatConst.test(this.password.value)
    }
  },
  async created () {
    const t = this
    t.token = this.$route.params.token
    if (t.token === '') {
      location.href = 'himacsPartnersPortal/requestPartnerAccount'
    }
  },
  mounted () {
    $('.toTip').hide()
    $('.cell-noti.tooTip .inp-text').on('click', function () {
      $('.toTip').show(100)
    })
    $('input.tooltip-inp').focus(function () {
      $('.toTip').show(100)
    })
    $('input.tooltip-inp').blur(function () {
      $('.toTip').hide()
    })
    $('html').click(function (e) {
      if (!$(e.target).hasClass('tooltip-inp')) {
        // alert('영역 밖입니다.');
        $('.toTip').hide()
      }
    })
  },
  methods: {
    passwordTooltip () {
      return $('#passwordTooltip').html()
    },
    async confirm () {
      const t = this

      t.submitChk = true
      if (this.weakConst || this.password.value !== this.chkpassword.value || this.password.value === '' || this.chkpassword.value === '') {
        return false
      }
      const { data } = await UserService.resetPassWordEU(this.password.value, t.token)

      if (data.success == true) {
        LayerPopup.open('ResetPass')

        this.Popcount = setTimeout(() => {
          this.$utils.goLoginEU()
        }, 3000)
      } else {
        alert(data.message)
        return false
      }
    },
    openResetPassPopup () {
      LayerPopup.open('ResetPass')
    },
    closePopup () {
      this.password.value = ''
      this.chkpassword.value = ''
      this.submitChk = false
      clearTimeout(this.Popcount)
      LayerPopup.close()
    },
    isPassWordValid: function () {
      return (this.password.value == '') ? '' : (this.reg.test(this.password.value)) ? this.valid = true : this.valid = false
    },
    goLogin () {
      window.location.href = 'himacsPartnersPortal'
    }
  }
}
</script>
<style scoped>
.toTip {
  left: 40%;
  width: 40%;
}
.toTip::after {
  left: 43%;
}
</style>
