<template>
  <div id="wrap" class="contact-us">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content cntu-view">
      <!-- container (Contact us)-->
      <div class="container cntu-box container_contactus">
        <template v-if="nationCode === '_GL' || nationCode === '_US'">
          <div class="sub_visual_bg full-width">
            <div class="inner_padding">
              <!-- location -->
              <div class="location">
                <ul>
                  <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("contactus.text.home") }}</li>
                  <li>{{ $t("contactus.text.support") }}</li>
                  <li>{{ $t("contactus.text.contactus") }}</li>
                </ul>
              </div>
              <!-- //location -->
              <!-- contact visual title -->
              <div class="row ju_bet">
                <div class="col">
                  <h1 class="tt-1 visual-title">{{ $t("contactus.text.contactus") }}</h1>
                  <p
                    class="ctt ctt-t3 visual-desc"
                    v-html="$t('contactus.text.inner')"
                  ></p>
                </div>
                <div class="col">
                  <div class="find-links-wrap">
                    <div>FIND WHAT YOU NEED BEFORE ASKING</div>
                    <div class="find-links">
                      <a class="item" href="support/faq/all-products">
                        <p>FAQ</p>
                        <div>
                          <img src="../../../public/images/header/old-gnb/gnb/gnb_Arr_right01.png" alt="arrow">
                        </div>
                      </a>
                      <a class="item" href="support/warranty/hflor" v-if="nationCode == '_US'">
                        <p>WARRANTY</p>
                        <div>
                          <img src="../../../public/images/header/old-gnb/gnb/gnb_Arr_right01.png" alt="arrow">
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //contact visual title -->
            </div>
          </div>
          <div v-if="nationCode === '_GL'">
            <contactGL :radioType="radioType"></contactGL>
          </div>
          <div v-else>
            <contactUSGL :radioType="radioType"></contactUSGL>
          </div>
        </template>
        <div v-else>
          <contactEU :radioType="radioType"></contactEU>
        </div>
      </div>
      <!-- //container (Contact us) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import contactUSGL from '@/views/support/contactus_USGL'
import contactGL from '@/views/support/contactus_GL'
import contactEU from '@/views/support/contactus_EU'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import FooterComponent from '@/components/Layout/Footer'
import getDictionaryList from '@/server/support.service'
import getCountryList from '@/server/us.service'
import GnbComponent from '@/components/Layout/GNB'
import global from '@/common/global'
import memberInfoService from '@/server/myPage.service'

export default {
  name: 'contactUs',
  components: {
    contactEU,
    contactUSGL,
    contactGL,
    HeaderComponent,
    QuickMenuComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      radioType: '',
      nationCode: '',
      countryCodeSub: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  created () {
    const _this = this
    _this.radioType = this.$route.params.user_type
    const nationCode = this.$countryCode // 국가 코드(_US, _GL 등)
    _this.nationCode = nationCode

    this.countryCodeSub = this.nationCode.substring(1)
  },
  methods: {
    gotolink (url) {
      location.href = url
    }
  },
  async mounted () {}
}
</script>
