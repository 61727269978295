<template>
  <div class="thumb-list-area">
    <div
      class="item"
      v-for="(it, idx) in images"
      :key="idx"
      @click="() => { onClick(idx) }"
    >
      <div
        v-if="isActive(idx)"
        class="active"
      >
        <img>
      </div>
      <img :src="it">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array
    },
    data: {
      type: Object
    }
  },

  methods: {
    isActive (idx) {
      return idx === this.data.selectIndex
    },
    backFilterClass () {
      return this.isActive ? 'on' : ''
    },
    onClick (idx) {
      this.data.instance.goTo(idx)
    }
  }
}
</script>

<style scoped>
.thumb-list-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 110px;
  position: relative;
  top: 0;
}
@media (min-width: 769px) {
  .thumb-list-area {
    top: -90px;
  }
}

.thumb-list-area .item {
  width: 64px;
  cursor: pointer;
  display: block;
  position: relative;
}

.thumb-list-area .item .active {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);
  animation: blur-in 500ms 2s forwards;
}

@keyframes blur-in {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(2px);
  }
}

.thumb-list-area .item .active img {
  width: 23px;
  height: 24px;
  content: url(https://img.lxhausys.com/public/images/products/carousel/select-icon.png);
}

.thumb-list-area .item img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
