<template>
  <div class="expanded-waste-recycling-table-body">
    <pre class="body-text" v-for="item in bodyList" :key="item" v-html="item"></pre>
  </div>
</template>

<script>
export default {
  props: {
    bodyList: {
      type: Array
    }
  }
}
</script>

<style>
.expanded-waste-recycling-table-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 30px 0px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid #E4E4E4;
  background: #FFF;
}

.expanded-waste-recycling-table-body .body-text {
  color: #000;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}

.expanded-waste-recycling-table-body .body-text b {
  color: #AA2D29;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}
</style>
