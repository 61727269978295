var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vueslicktem" },
    [
      _c(
        "VueSlickCarousel",
        _vm._b(
          {
            ref: "carousel",
            on: { afterChange: _vm.onChange },
            scopedSlots: _vm._u([
              {
                key: "customPaging",
                fn: function (page) {
                  return [_c("DotPaging", { attrs: { currentIndex: page } })]
                },
              },
              {
                key: "prevArrow",
                fn: function () {
                  return [_c("div", { staticClass: "arrow-btn prev" })]
                },
                proxy: true,
              },
              {
                key: "nextArrow",
                fn: function () {
                  return [_c("div", { staticClass: "arrow-btn next" })]
                },
                proxy: true,
              },
            ]),
          },
          "VueSlickCarousel",
          _vm.carouselSetting,
          false
        ),
        [_vm._t("default")],
        2
      ),
      _vm._t("custom", null, null, _vm.customData),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }