/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */

import * as types from './mutation-types'
import UserService from '@/server/user.service'
import utils from '@/common/helper/utils'

export const find = async ({ commit }) => {
  if (utils.getCountryCode().includes('US')) {
    const res = await UserService.userInfo()
    commit(types.FIND, res.data)
    return res
  } else {
    const res = await UserService.userInfoEU()
    commit(types.FIND, res.data)
    return res
  }
}

export default {
  find
}
