var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/ceo_message/top_image.png",
          title: "CEO MESSAGE",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("OverviewSection", [
                    !_vm.isMobile()
                      ? _c("div", { staticClass: "ceo-image-area" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: "images/aboutus/overview/ceo_message/ceo_message.jpg",
                            },
                          }),
                        ])
                      : _c("div", { staticClass: "ceo-image-area" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "keyvisual-hei-600 ceomessage_imgbox",
                            },
                            [
                              _c("img", {
                                staticClass: "img",
                                attrs: {
                                  src: "https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "t-type-01 ceomessage_txtbox" },
                            [
                              _c("br"),
                              _vm._v(
                                " LX Hausys is the largest building & decorative material company in Korea, and is continuously expanding its business to global regions such as the US and Europe. We have established a balanced growth path by diversifying the business portfolio to include Automotive Materials and Industrial Film. We will constantly strive to secure the leading position in the market, and bring innovative customer value by providing high-quality products with superior durability, functionality, and eco-consciousness. "
                              ),
                            ]
                          ),
                        ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3024854244
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/ceo_message/top_image.png",
          title: "CEOメッセージ",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("OverviewSection", [
                  !_vm.isMobile()
                    ? _c("div", { staticClass: "ceo-image-area" }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: {
                            src: "images/aboutus/overview/ceo_message/ceo_message.jpg",
                          },
                        }),
                      ])
                    : _c("div", { staticClass: "ceo-image-area" }, [
                        _c(
                          "div",
                          {
                            staticClass: "keyvisual-hei-600 ceomessage_imgbox",
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: {
                                src: "https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "t-type-01 ceomessage_txtbox" },
                          [
                            _c("br"),
                            _vm._v(
                              " LXハウシスは韓国最大の建築・装飾資材メーカーとして、米国や欧州 などグローバルな地域に事業を拡大しています。 自動車資材や産業用フィルムなど事業ポートフォリオを多元化し、安定的な成長路線を確立 しています。耐久性、機能性、環境配慮性に優れた高品質の製品を提供し、 市場で主導的な地位を確保し、革新的な顧客価値を実現するために絶えず 努力していきます。 "
                            ),
                          ]
                        ),
                      ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/ceo_message/top_image.png",
          title: "Послание генерального директора",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("OverviewSection", [
                  !_vm.isMobile()
                    ? _c("div", { staticClass: "ceo-image-area" }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: {
                            src: "images/aboutus/overview/ceo_message/ceo_message.jpg",
                          },
                        }),
                      ])
                    : _c("div", { staticClass: "ceo-image-area" }, [
                        _c(
                          "div",
                          {
                            staticClass: "keyvisual-hei-600 ceomessage_imgbox",
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: {
                                src: "https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "t-type-01 ceomessage_txtbox" },
                          [
                            _c("br"),
                            _vm._v(
                              " LX Hausys - крупнейшая компания по производству строительных и декоративных материалов в Корее, которая постоянно расширяет свой бизнес в таких глобальных регионах, как США и Европа. Мы создали сбалансированный путь роста, диверсифицировав портфель бизнеса и включив в него автомобильные материалы и пленку для ламинации. Мы будем постоянно стремиться занять лидирующие позиции на рынке и обеспечить инновационные преимущества для клиентов, предоставляя высококачественную продукцию с превосходной долговечностью, функциональностью и экологичностью. "
                            ),
                          ]
                        ),
                      ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/ceo_message/top_image.png",
          title: "CEO 致辞",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("OverviewSection", [
                  !_vm.isMobile()
                    ? _c("div", { staticClass: "ceo-image-area" }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: {
                            src: "images/aboutus/overview/ceo_message/ceo_message.jpg",
                          },
                        }),
                      ])
                    : _c("div", { staticClass: "ceo-image-area" }, [
                        _c(
                          "div",
                          {
                            staticClass: "keyvisual-hei-600 ceomessage_imgbox",
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: {
                                src: "https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "t-type-01 ceomessage_txtbox" },
                          [
                            _c("br"),
                            _vm._v(
                              " LX Hausys作为韩国最大规模的装饰建材企业，成功地在北美、亚洲和欧洲各地设立了销售及 生产基地，并逐步扩张全球化经营的版图。 我们通过多元化业务组合（包括汽车材料和产业用膜）建立了均衡的增长道路。我们将不断努力 确保市场领先地位，并通过提供具有卓越的耐用性、功能性及环保高品质产品带来创新的客户价值。 "
                            ),
                          ]
                        ),
                      ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }