var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-area" }, [
    _c("pre", {
      staticClass: "main-text",
      domProps: { innerHTML: _vm._s(_vm.text) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }