<template>
  <div class="key-performance-table-body">
    <div class="key-performance-table-body-content">
      <span class="key-performance-table-body-number">
        <pre><b>{{ number }}</b>{{ unit }}</pre>
        <img src="images/aboutus/sustainability/sustainability_management/key-performance-arrow-down.png" alt="arrow"
      </span>
      <span class="key-performance-table-body-summary">{{ summary }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String
    },
    unit: {
      type: String
    },
    summary: {
      type: String
    }
  }
}
</script>

<style scoped>
.key-performance-table-body {
  border-bottom: 1px solid #000;
  padding: 40px 32px;
  width: 700px;
}
@media (max-width: 768px) {
  .key-performance-table-body {
    width: 100%;
  }
}

.key-performance-table-body .key-performance-table-body-content {
  gap: 61px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .key-performance-table-body .key-performance-table-body-content {
    flex-direction: column;
    gap: 30px;
  }
}

.key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number {
  gap: 15px;
  justify-content: left;
  display: flex;
}

.key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number pre {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  display: flex;
}

@media (max-width: 768px) {
  .key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number pre {
    font-size: 1rem;
    line-height: 1.3;
  }
}

.key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number b {
  font-size: 38px;
  font-weight: 700;
  line-height: 26px; /* 68.421% */
  margin-right: 10px;
}

@media (max-width: 768px) {
  .key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number b {
    font-size: 1.2916666667rem;
    line-height: 1.3;
  }
}

.key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number img {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .key-performance-table-body .key-performance-table-body-content .key-performance-table-body-number img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.key-performance-table-body .key-performance-table-body-content .key-performance-table-body-summary {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
}

@media (max-width: 768px) {
  .key-performance-table-body .key-performance-table-body-content .key-performance-table-body-summary {
    font-size: 0.6875rem;
    line-height: 1.0416666667rem;
    text-align: center;
  }
}

</style>
