<template>
  <div id="wrap" v-if="nationCode == '_US' || nationCode == '_GL'">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">Story of LX Hausys</h1>

    <div class="content"><!-- 220603 에디터 주석 내용 변경 여기부터 시작 -->
      <div class="story-lx"><!-- location -->
        <div class="location type02">
          <ul>
            <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('globalBusiness.text.home')}}</li>
            <li>About Us</li>
            <li>Why LX Hausys</li>
            <li>History</li>
          </ul>
        </div>
        <!-- //location -->
        <ProductTopImage
          imageSrc="https://img.lxhausys.com/public/images/aboutus/visual_story.png"
          title="Story of LX Hausys"
        />
        <div class="wrap-history">
          <div class="inner">
            <h2 class="section-title">History of Living Space in Korea</h2>
            <p class="ctt ctt-t2 tac">Our journey taken together with customers represents Korea&rsquo;s history of living space evolution.<br />Since 1947, LX Hausys has been supplying various interior materials that served to enhance the value of living spaces in Korea for the past 70 years.<br />Now, LX Hausys has become the largest building materials company in Korea.<br />We will continue to establish ourselves as a company that delivers trust and satisfaction to our customers based on<br />our market-leading products and advanced technology to supply healthy, eco-friendly products & materials,<br />as well as systems and solutions that maximize energy efficiency.<br /></p>
          </div>
          <div class="history-table">
            <dl class="hstr-l hstr-pb-90">
              <dt style="color: #B40F2C;">2023</dt>
              <dd>
                <p style="margin-top: 20px;">Implemented a plan to achieve the “Net<br/>Zero” goal by 2050</p>
                <img src="images/aboutus/s_2023.png" style="width: 99.247px; height: 97.342px; flex-shrink: 0;" class="card-img"/>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pt-180 hstr-pb-104">
              <dt>2022</dt>
              <dd>
                <img src="images/aboutus/s_2022.png" style="flex-shrink: 0;" class="card-img"/>
                <p>Established the ESG Committee</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pt-150 hstr-pb-104">
              <dt>2021</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2021.png" class="card-img"/>
                <p>Changed the company name to LX Hausys</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-150">
              <dt>2020</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2020.png" class="card-img"/>
                <p>Completed expansion of the 3rd production line<br/>for engineered stone in the U.S</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-104">
              <dt>2018</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2018.png" class="card-img"/>
                <p>Completed the 2nd PF insulation board plant</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-300">
              <dt>2017</dt>
              <dd>
                <p style="margin-top: 20px;">Acquired majority stake in c21, a Slovak<br/>automotive materials producer</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2016</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2016.png" class="card-img"/>
                <p>Completed an automotive interior leather plant<br/>in the U.S (Atlanta)</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-90">
              <dt>2015</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2015.png" class="card-img"/>
                <p>Completed a plant in Wuxi, China</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2013</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2013.png" class="card-img"/>
                <!-- <p>Completed a plant in Wuxi,<br/>China</p> -->
                <p>Produced PF boards</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2012</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2012.png" class="card-img"/>
                <p>Completed the Korea's largest Low-E glass plant</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2011</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2011.png" class="card-img"/>
                <p>Completed an engineered stone plant in<br/>the U.S</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2010</dt>
              <dd>
                <img src="images/aboutus/s_2010.png" class="card-img"/>
                <p>Acquired Carbon Footprint Certificate<br/>for the first time in the building materials field</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>2009</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2009.png" class="card-img"/>
                <p>Launched LG Hausys (Spinoff of<br/>industrial materials business of LG Chem)</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2007</dt>
              <dd>
                <img src="images/aboutus/s_2007.png" class="card-img"/>
                <p>Published its first Sustainability report<br/>(by LG chemistry)</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2006</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2006.png" class="card-img"/>
                <p>Launched Z:IN as a premium interior<br/>design brand</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2005</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2005.png" class="card-img"/>
                <p>Completed HIMACS plant in the U.S</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>1995</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1995.png" class="card-img"/>
                <p>Produced the acrylic solid surface<br/>HIMACS</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>1984</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1984.png" class="card-img"/>
                <p>Produced plastic automotive parts</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>1976</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1976.png" class="card-img"/>
                <p>EProduced the first PVC window<br/>(HI-Sash) in Korea</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-180">
              <dt>1969</dt>
              <dd>
                <img src="images/aboutus/s_1969.png" class="card-img"/>
                <p>Produced the first commercial PVC tiles in Korea</p>
              </dd>
            </dl>
            <!-- history-lastest -->
            <dl class="hstr-l" style="padding-bottom: 30px;">
              <dt>1957</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_1957.png" class="card-img"/>
                <p>EProduced the first PVC window<br/>(HI-Sash) in Korea</p>
              </dd>
            </dl>
            <dl class="hstr-r" style="padding-bottom: 25px;">
              <dt>1952</dt>
              <dd>
                <p style="margin-top: 20px; margin-bottom: 120px;">Established Lak Hui Chemical Industrial Corp.<br/>(Currently LG Chem)</p>
              </dd>
              <dt class="hstr-m-area-circle"></dt>
            </dl>
          </div>
          <div class="hstr-m-area">
            <div class="hstr-m">
              <div class="year-area">
                <pre class="year">1947</pre>
              </div>
              <img src="https://img.lxhausys.com/public/images/aboutus/s_1947.png" class="m-card-img"/>
              <p>Established Lak Hui Chemical Industrial<br/>Corp. (Currently LG Chem)</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 220603 에디터 주석 내용 변경 여기까지 끝 --></div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
  <!-- JP -->
  <div id="wrap" v-else-if="nationCode == '_JP'">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">LXハウシスのストーリー</h1>

    <div class="content"><!-- 220603 에디터 주석 내용 변경 여기부터 시작 -->
      <div class="story-lx"><!-- location -->
        <div class="location type02">
          <ul>
            <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('globalBusiness.text.home')}}</li>
            <li>About Us</li>
            <li>Why LX Hausys</li>
            <li>History</li>
          </ul>
        </div>
        <!-- //location -->
        <ProductTopImage
          imageSrc="https://img.lxhausys.com/public/images/aboutus/visual_story.png"
          title="LXハウシスのストーリー"
        />
        <div class="wrap-history">
          <div class="inner">
            <h2 class="section-title">韓国の居住空間の歴史</h2>
            <p class="ctt ctt-t2 tac">お客様とともに歩んできた私たちは、韓国の生活空間の進化の歴史と共に成長しています。<br>1947年以来、LX Hausysは過去70年間、韓国の生活空間の価値を高めるさまざまなインテリア資材を供給してきました。<br>現在、LX Hausysは韓国最大の建築資材会社に成長し、市場をリードする製品と先進技術をベースに、<br>健康的で環境に優しい製品と資材、エネルギー効率を最大化するシステムとソリューションを提供し、<br>お客様に信頼と満足をお届けする企業として確固たる地位を築いていきます。</p>
          </div>
          <div class="history-table">
            <dl class="hstr-l hstr-pb-90">
              <dt style="color: #B40F2C;">2023</dt>
              <dd>
                <p style="margin-top: 20px;">2050年までに「ネットゼロ(NetZero)」<br>目標を達成するための計画を実施</p>
                <img src="images/aboutus/s_2023.png" style="width: 99.247px; height: 97.342px; flex-shrink: 0;" class="card-img"/>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pt-180 hstr-pb-104">
              <dt>2022</dt>
              <dd>
                <img src="images/aboutus/s_2022.png" style="flex-shrink: 0;" class="card-img"/>
                <p>ESG委員会の設置</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pt-150 hstr-pb-104">
              <dt>2021</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2021.png" class="card-img"/>
                <p>社名をLX Hausysに変更</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-150">
              <dt>2020</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2020.png" class="card-img"/>
                <p>米国で第3クォーツストーン<br>生産ラインの拡張を完了</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-104">
              <dt>2018</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2018.png" class="card-img"/>
                <p>第2PF断熱材工場完成</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-300">
              <dt>2017</dt>
              <dd>
                <p style="margin-top: 20px;">スロバキアの自動車材料<br>メーカーc21の過半数株式を取得</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2016</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2016.png" class="card-img"/>
                <p>米国(アトランタ)<br>に自動車内装皮革工場完成</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-90">
              <dt>2015</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2015.png" class="card-img"/>
                <p>中国無錫に工場完成</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2013</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2013.png" class="card-img"/>
                <!-- <p>Completed a plant in Wuxi,<br/>China</p> -->
                <p>建築用PF断熱材の製造</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2012</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2012.png" class="card-img"/>
                <p>韓国最大のLow-Eガラス工場完成</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2011</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2011.png" class="card-img"/>
                <p>米国に3クォーツストーン工場を完成</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2010</dt>
              <dd>
                <img src="images/aboutus/s_2010.png" class="card-img"/>
                <p>建材分野で初<br>めてカーボンフットプリント認証を取得</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>2009</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2009.png" class="card-img"/>
                <p>LG Hausys発足<br>(LG化学の産業資材事業を分社化)</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2007</dt>
              <dd>
                <img src="images/aboutus/s_2007.png" class="card-img"/>
                <p>初のサステナビリティレポート<br>を発行(LG化学)</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2006</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2006.png" class="card-img"/>
                <p>プレミアムインテリアデザインブランド<br>「Z:IN」を立ち上げ</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2005</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2005.png" class="card-img"/>
                <p>米国にHIMACS(人工大理石)工場完成</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>1995</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1995.png" class="card-img"/>
                <p>アクリル固体表面HIMACS<br>(人工大理石)を製造</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>1984</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1984.png" class="card-img"/>
                <p>プラスチック自動車部品の製造</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>1976</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1976.png" class="card-img"/>
                <p>韓国初のPVC窓(HI-Sash)を生産</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-180">
              <dt>1969</dt>
              <dd>
                <img src="images/aboutus/s_1969.png" class="card-img"/>
                <p>韓国初の商業用PVCタイルを生産</p>
              </dd>
            </dl>
            <!-- history-lastest -->
            <dl class="hstr-l" style="padding-bottom: 30px;">
              <dt>1957</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_1957.png" class="card-img"/>
                <p>韓国初のPVC窓(HI-Sash)を生産</p>
              </dd>
            </dl>
            <dl class="hstr-r" style="padding-bottom: 25px;">
              <dt>1952</dt>
              <dd>
                <p style="margin-top: 20px; margin-bottom: 120px;">楽輝化学工業株式会社(現LG化学)設立</p>
              </dd>
              <dt class="hstr-m-area-circle"></dt>
            </dl>
          </div>
          <div class="hstr-m-area">
            <div class="hstr-m">
              <div class="year-area">
                <pre class="year">1947</pre>
              </div>
              <img src="https://img.lxhausys.com/public/images/aboutus/s_1947.png" class="m-card-img"/>
              <p>楽輝化学工業株式会社(現LG化学)設立</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 220603 에디터 주석 내용 변경 여기까지 끝 --></div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
  <!-- RU -->
  <div id="wrap" v-else-if="nationCode == '_RU' ">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">История компании LX Hausys</h1>

    <div class="content"><!-- 220603 에디터 주석 내용 변경 여기부터 시작 -->
      <div class="story-lx"><!-- location -->
        <div class="location type02">
          <ul>
            <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('globalBusiness.text.home')}}</li>
            <li>About Us</li>
            <li>Why LX Hausys</li>
            <li>History</li>
          </ul>
        </div>
        <!-- //location -->
        <ProductTopImage
          imageSrc="https://img.lxhausys.com/public/images/aboutus/visual_story.png"
          title="История компании LX Hausys"
        />
        <div class="wrap-history">
          <div class="inner">
            <h2 class="section-title">История жилых помещений в Корее</h2>
            <p class="ctt ctt-t2 tac">Наш путь, пройденный вместе с клиентами, представляет собой историю эволюции жилого пространства в Корее. <br>Начиная с 1947 года, компания LX Hausys поставляет различные материалы для интерьера, которые повышают <br>ценность жилых помещений в Корее на протяжении последних 70 лет. Сейчас LX Hausys стала крупнейшей компанией<br> по производству строительных материалов в Корее. Мы будем продолжать утверждаться в качестве компании, которая <br>обеспечивает доверие и удовлетворение наших клиентов, основываясь на наших лидирующих на <br>рынке продуктах и передовых технологиях, чтобы поставлять безопасные, экологически чистые <br>продукты и материалы, а также системы и решения, обеспечивающие максимальную энергоэффективность.</p>
          </div>
          <div class="history-table">
            <dl class="hstr-l hstr-pb-90">
              <dt style="color: #B40F2C;">2023</dt>
              <dd>
                <p style="margin-top: 20px;">Реализован план по достижению цели «Net Zero» к 2050 году</p>
                <img src="images/aboutus/s_2023.png" style="width: 99.247px; height: 97.342px; flex-shrink: 0;" class="card-img"/>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pt-180 hstr-pb-104">
              <dt>2022</dt>
              <dd>
                <img src="images/aboutus/s_2022.png" style="flex-shrink: 0;" class="card-img"/>
                <p>Учрежден Комитет ESG</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pt-150 hstr-pb-104">
              <dt>2021</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2021.png" class="card-img"/>
                <p>Изменено название компании на LX Hausys</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-150">
              <dt>2020</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2020.png" class="card-img"/>
                <p>Завершено расширение 3-й производственной линии для производства искусственного камня в США</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-104">
              <dt>2018</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2018.png" class="card-img"/>
                <p>Завершено строительство 2-го завода по производству теплоизоляционных плит PF</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-300">
              <dt>2017</dt>
              <dd>
                <p style="margin-top: 20px;">Приобретен контрольный пакет акций компании c21, словацкого производителя автомобильных материалов</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2016</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2016.png" class="card-img"/>
                <p>Завершено строительство завода по производству автомобильной кожи для внутренней отделки в США (Атланта)</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-90">
              <dt>2015</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2015.png" class="card-img"/>
                <p>Завершено строительство завода в Уси, Китай</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2013</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2013.png" class="card-img"/>
                <!-- <p>Completed a plant in Wuxi,<br/>China</p> -->
                <p>Производство теплоизоляции PF для строительства</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2012</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2012.png" class="card-img"/>
                <p>Завершено строительство крупнейшего в Корее завода по производству низкоэмиссионного стекла</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2011</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2011.png" class="card-img"/>
                <p>Завершено строительство завода по производству искусственного камня в США</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2010</dt>
              <dd>
                <img src="images/aboutus/s_2010.png" class="card-img"/>
                <p>Приобретение сертификата "Углеродный след" впервые в области строительных материалов</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>2009</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2009.png" class="card-img"/>
                <p>Запуск LG Hausys (выделение бизнеса по производству промышленных материалов компании LG Chem)</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2007</dt>
              <dd>
                <img src="images/aboutus/s_2007.png" class="card-img"/>
                <p>Опубликован первый отчет об устойчивом развитии (по материалам LG chemistry)</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2006</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2006.png" class="card-img"/>
                <p>Запуск бренда премиального дизайна интерьера Z:IN </p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2005</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2005.png" class="card-img"/>
                <p>Завершение строительства завода HIMACS в США</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>1995</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1995.png" class="card-img"/>
                <p>Производство твердых акриловых поверхностей HIMACS</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>1984</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1984.png" class="card-img"/>
                <p>Производство пластиковых деталей для автомобилей</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>1976</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1976.png" class="card-img"/>
                <p>Произведено первое окно из ПВХ (HI-Sash) в Корее</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-180">
              <dt>1969</dt>
              <dd>
                <img src="images/aboutus/s_1969.png" class="card-img"/>
                <p>Произведено первое виниловое напольное покрытие в Корее</p>
              </dd>
            </dl>
            <!-- history-lastest -->
            <dl class="hstr-l" style="padding-bottom: 30px;">
              <dt>1957</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_1957.png" class="card-img"/>
                <p>Произведено первое окно из ПВХ (HI-Sash) в Корее</p>
              </dd>
            </dl>
            <dl class="hstr-r" style="padding-bottom: 25px;">
              <dt>1952</dt>
              <dd>
                <p style="margin-top: 20px; margin-bottom: 120px;">Основана компания Lak Hui Chemical Industrial Corp. (в настоящее время LG Chem)</p>
              </dd>
              <dt class="hstr-m-area-circle"></dt>
            </dl>
          </div>
          <div class="hstr-m-area">
            <div class="hstr-m">
              <div class="year-area">
                <pre class="year">1947</pre>
              </div>
              <img src="https://img.lxhausys.com/public/images/aboutus/s_1947.png" class="m-card-img"/>
              <p>Основана компания Lak Hui Chemical Industrial Corp. (в настоящее время LG Chem)</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 220603 에디터 주석 내용 변경 여기까지 끝 --></div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
  <!-- CN -->
  <div id="wrap" v-else-if="nationCode == '_CN'">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">沿革</h1>

    <div class="content"><!-- 220603 에디터 주석 내용 변경 여기부터 시작 -->
      <div class="story-lx"><!-- location -->
        <div class="location type02">
          <ul>
            <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('globalBusiness.text.home')}}</li>
            <li>About Us</li>
            <li>Why LX Hausys</li>
            <li>History</li>
          </ul>
        </div>
        <!-- //location -->
        <ProductTopImage
          imageSrc="https://img.lxhausys.com/public/images/aboutus/visual_story.png"
          title="沿革"
        />
        <div class="wrap-history">
          <div class="inner">
            <h2 class="section-title">韩国生活空间的历史</h2>
            <p class="ctt ctt-t2 tac">一直与国民相件的LX Hausys的历史正是韩国生活空间的历史。LX Hausys自1947年以来,在过去的70余年间,<br>一直致力于提供能够提升生活空间价值的装饰建材,并顺利成长为韩国国内最大规模的装饰建材企业。<br>今后也将继续引领市场,以最优质的产品及不断提升的技术支撑力为基础,努力为顾客提供绿色环保的产品。</p>
          </div>
          <div class="history-table">
            <dl class="hstr-l hstr-pb-90">
              <dt style="color: #B40F2C;">2023</dt>
              <dd>
                <p style="margin-top: 20px;">实施2050年达到《零碳排放》的目标</p>
                <img src="images/aboutus/s_2023.png" style="width: 99.247px; height: 97.342px; flex-shrink: 0;" class="card-img"/>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pt-180 hstr-pb-104">
              <dt>2022</dt>
              <dd>
                <img src="images/aboutus/s_2022.png" style="flex-shrink: 0;" class="card-img"/>
                <p>成立ESG 委员会</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pt-150 hstr-pb-104">
              <dt>2021</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2021.png" class="card-img"/>
                <p>公司更名为LX Hausys</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-150">
              <dt>2020</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2020.png" class="card-img"/>
                <p>美国石英石3号生产线完工</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-104">
              <dt>2018</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2018.png" class="card-img"/>
                <p>建筑隔热材料第二工厂竣工</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-300">
              <dt>2017</dt>
              <dd>
                <p style="margin-top: 20px;">收购斯洛伐克汽车零部件企业c21股份</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2016</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2016.png" class="card-img"/>
                <p>美国（亚特兰大）汽车表皮工厂竣工</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-90">
              <dt>2015</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2015.png" class="card-img"/>
                <p>中国无锡工厂竣工</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2013</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2013.png" class="card-img"/>
                <!-- <p>Completed a plant in Wuxi,<br/>China</p> -->
                <p>生产建筑隔热材料</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2012</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2012.png" class="card-img"/>
                <p>韩国最大规模低辐射玻璃工厂竣工</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2011</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_2011.png" class="card-img"/>
                <p>美国石英石工厂竣工</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2010</dt>
              <dd>
                <img src="images/aboutus/s_2010.png" class="card-img"/>
                <p>在建筑材料领域首次获得碳足迹证书</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>2009</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2009.png" class="card-img"/>
                <p>LG Hausys成立（从LG化学工业材料分立）</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-70">
              <dt>2007</dt>
              <dd>
                <img src="images/aboutus/s_2007.png" class="card-img"/>
                <p>发布首份可持续发展报告（by LG 化学）</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>2006</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2006.png" class="card-img"/>
                <p>推出高端室内装饰品牌（Z:IN）</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>2005</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_2005.png" class="card-img"/>
                <p>美国亚克力人造大理石工厂竣工</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-80">
              <dt>1995</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1995.png" class="card-img"/>
                <p>生产亚克力人造大理石</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-104">
              <dt>1984</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1984.png" class="card-img"/>
                <p>生产汽车塑料零部件</p>
              </dd>
            </dl>
            <dl class="hstr-l hstr-pb-70">
              <dt>1976</dt>
              <dd>
                <img src="https://img.lxhausys.com/public/images/aboutus/s_1976.png" class="card-img"/>
                <p>生产韩国最早的PVC门窗（HI-Sash）</p>
              </dd>
            </dl>
            <dl class="hstr-r hstr-pb-180">
              <dt>1969</dt>
              <dd>
                <img src="images/aboutus/s_1969.png" class="card-img"/>
                <p>生产韩国首次商用PVC地板</p>
              </dd>
            </dl>
            <!-- history-lastest -->
            <dl class="hstr-l" style="padding-bottom: 30px;">
              <dt>1957</dt>
              <dd><img src="https://img.lxhausys.com/public/images/aboutus/s_1957.png" class="card-img"/>
                <p>生产韩国最早的PVC门窗（HI-Sash）</p>
              </dd>
            </dl>
            <dl class="hstr-r" style="padding-bottom: 25px;">
              <dt>1952</dt>
              <dd>
                <p style="margin-top: 20px; margin-bottom: 120px;">成立乐惠化学工业社（现为LG化学）</p>
              </dd>
              <dt class="hstr-m-area-circle"></dt>
            </dl>
          </div>
          <div class="hstr-m-area">
            <div class="hstr-m">
              <div class="year-area">
                <pre class="year">1947</pre>
              </div>
              <img src="https://img.lxhausys.com/public/images/aboutus/s_1947.png" class="m-card-img"/>
              <p>创立乐喜化学工业社（现为LG化学）</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 220603 에디터 주석 내용 변경 여기까지 끝 --></div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
import ProductTopImage from '../../../components/product/ProductTopImage.vue'
export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    ProductTopImage
  },
  data () {
    return {
      story: '',
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  async mounted () {
    const _this = this

    // const contentResInfo = await ProductService.getCategoryCode('STORY_OF_LX_HAUSYS' + _this.$countryCode);
    // const contentRes = await ProductService.getContentList(contentResInfo.data.object.id);
    // _this.story = contentRes.data.list[0].attribute.editor
  },
  methods: {
    gotolink (url) {
      location.href = url
    }
  }
}
</script>

<style scoped>
.section-title {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.25rem;
    line-height: 1.3541666667rem;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.tac{
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .tac {
    font-size: 0.6875rem;
    line-height: 1.0416666667rem;
  }
}

.card-img {
  margin-top: 20px;
  max-height: 290px;
}

.wrap-history .history-table dl dt {
  margin-bottom: 0px;
}

.hstr-m-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hstr-m-area .hstr-m {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .hstr-m-area .hstr-m {
    width: 80%;
    align-items: flex-start;
  }
}

.hstr-m-area .hstr-m p {
  color: #222;
  text-align: right;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 768px) {
  .hstr-m-area .hstr-m p {
    text-align: left;
    font-size: 0.875rem;
    margin-top: 0.8333333333rem;
  }
}

.hstr-m-area .hstr-m .year-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hstr-m-area .hstr-m .year-area .year {
  color: #A09C9B;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 90% */
  margin-top: 20px;
}

@media (max-width: 768px) {
  .hstr-m-area .hstr-m .year-area .year {
    font-size: 1.2916666667rem;
    margin-top: 0px;
  }
}

.m-card-img {
  display: flex;
  width: 469px;
  height: 289px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 20px 0px 20px 0px;
}

.hstr-m-area-circle::after {
  top: -10px !important;
}

@media (max-width: 768px) {
  .m-card-img {
    max-height: 290px;
    width: 100%;
    height: 100%;
    margin: 0px;
  }
  .hstr-m-area-circle::after {
    top: 21px !important;
  }
  .wrap-history .history-table {
    overflow: visible;
  }
}

</style>
