var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "layer-view layer-result popup-v2" }, [
        _c("div", { staticClass: "popup pop-close" }, [
          _c("div", { staticClass: "scr-y" }, [
            _c("p", { staticClass: "popup-title" }, [
              _vm._v("Warranty Registration"),
            ]),
            _c("div", { staticClass: "popup-body" }, [
              _c("div", { staticClass: "frow" }, [
                _c("div", { staticClass: "fcol tac" }, [
                  _c("i", { staticClass: "pcon pcon-check" }),
                  _c("strong", { staticClass: "err-title" }, [
                    _vm._v("Warranty Registration Complete!"),
                  ]),
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(
                      "Installation Date: " +
                        _vm._s(_vm.data.installMonth) +
                        "/" +
                        _vm._s(_vm.data.installDay) +
                        "/" +
                        _vm._s(_vm.data.installYear)
                    ),
                  ]),
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(
                      "Registration Date: " +
                        _vm._s(_vm.$moment().format("MM/DD/YYYY"))
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-close",
              attrs: { type: "button", onClick: "tmpClose('warranty')" },
            },
            [_vm._v("close")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "layer-view layer-result2 popup-v2" }, [
        _c("div", { staticClass: "popup pop-close" }, [
          _c("div", { staticClass: "scr-y" }, [
            _vm._m(0),
            _c("div", { staticClass: "more-box ta-c" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button", id: "yes", value: "yes" },
                  on: {
                    click: function ($event) {
                      return _vm.buttonCheck("yes")
                    },
                  },
                },
                [_c("span", [_vm._v("Yes")])]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-second",
                  attrs: { type: "button", id: "no", value: "no" },
                  on: {
                    click: function ($event) {
                      return _vm.buttonCheck("no")
                    },
                  },
                },
                [_c("span", [_vm._v("No")])]
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-close",
              attrs: { type: "button", onClick: "tmpPopupClose()" },
            },
            [_vm._v("close")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "content cntu-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("warranty.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("warranty.text.support")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("warranty.text.warranty")))]),
          ]),
        ]),
        _vm.nationCode === "_US" || _vm.nationCode === "_GL"
          ? _c(
              "div",
              {
                staticClass: "container cntu-box wa-box us-container-warranty",
              },
              [
                _c("div", { staticClass: "w-con-02" }, [
                  _c("div", { staticClass: "inner" }, [
                    _c("div", { staticClass: "sub-visual-section" }, [
                      _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                        _vm._v(_vm._s(_vm.$t("warranty.text.warranty")) + " "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(" - " + _vm._s(_vm.prdLabel)),
                        ]),
                      ]),
                      _c("h2", { staticClass: "sr-only" }, [
                        _vm._v("LX Hausys Warranty " + _vm._s(_vm.prdLabel)),
                      ]),
                      _c("div", { staticClass: "ctt ctt-t2 tac visual-desc" }, [
                        _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                          _vm._v(_vm._s(_vm.$t("warranty.text.mainTxt1"))),
                        ]),
                        _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                          _vm._v(_vm._s(_vm.$t("warranty.text.mainTxt2"))),
                        ]),
                        _c("div", { staticClass: "btn-box" }, [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "VIATERA") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "Viatera") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "HIMACS") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "TERACANTO") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "HIMACS Sink") ||
                                    (_vm.nationCode == "_US" &&
                                      _vm.selectedContentNm == "HIMACS SINK") ||
                                    _vm.warrantyToRegister === true,
                                  expression:
                                    "(nationCode != '_GL') && (selectedContentNm == 'VIATERA') || (nationCode != '_GL') && (selectedContentNm == 'Viatera') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS') || (nationCode != '_GL') && (selectedContentNm == 'TERACANTO') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS Sink') || (nationCode == '_US') && (selectedContentNm == 'HIMACS SINK') || warrantyToRegister === true",
                                },
                              ],
                              staticClass: "btn btn-reg btn-wa mt-40",
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("warranty.button.warranty_to_register")
                                )
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "VIATERA") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "Viatera") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "TERACANTO") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "HIMACS Sink") ||
                                    (_vm.nationCode == "_US" &&
                                      _vm.selectedContentNm == "HIMACS SINK") ||
                                    (_vm.nationCode != "_GL" &&
                                      _vm.selectedContentNm == "HIMACS") ||
                                    _vm.warrantyToRegister === true,
                                  expression:
                                    "(nationCode != '_GL') && (selectedContentNm == 'VIATERA') || (nationCode != '_GL') && (selectedContentNm == 'Viatera') || (nationCode != '_GL') && (selectedContentNm == 'TERACANTO') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS Sink') || (nationCode == '_US') && (selectedContentNm == 'HIMACS SINK')  || (nationCode != '_GL') && (selectedContentNm == 'HIMACS') || warrantyToRegister === true",
                                },
                              ],
                              staticClass: "btn btn-reg2 btn-wa mt-40",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.goWarrantyClaim()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "warranty.button.submit_warranty_claim"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "care-box" }, [
                    _c("p", { staticClass: "tt-2 mb-32 inner" }, [
                      _vm._v(_vm._s(_vm.$t("warranty.text.warranty_download"))),
                    ]),
                    _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.tabCateList, function (tab, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "btn-tab",
                              attrs: {
                                "data-tab": "tab0" + (index + 1),
                                id: tab.code,
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fntab($event, tab)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(tab.name))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "inner" }, [
                      _c("div", { staticClass: "tab-con tab01 on" }, [
                        _vm.nationCode == "_US"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "sub-tab tab-ui tab-ui-nav tab-ui-gray",
                              },
                              [
                                _vm._m(1),
                                _c("div", { staticClass: "tab-con sub01 on" }, [
                                  _c("p", { staticClass: "ctt ctt-t4 desc" }, [
                                    _vm._v(_vm._s(_vm.contentNeeded)),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "tbl-box type05 box-dl" }, [
                          _c("table", [
                            _c("caption", [
                              _vm._v(
                                _vm._s(_vm.$t("warranty.text.download_form"))
                              ),
                            ]),
                            _vm._m(2),
                            _c(
                              "tbody",
                              _vm._l(_vm.warrantyList, function (warr, index) {
                                return _c("tr", { key: index }, [
                                  warr.parents[1].id === 110
                                    ? _c("th", { staticClass: "dl-title" }, [
                                        _vm._v(
                                          _vm._s(warr.parents[1].name) +
                                            "/" +
                                            _vm._s(warr.parents[0].name)
                                        ),
                                      ])
                                    : _c("th", { staticClass: "dl-title" }, [
                                        _vm._v(_vm._s(warr.parents[0].name)),
                                      ]),
                                  _c(
                                    "td",
                                    { staticClass: "dl-disabled-mobile" },
                                    [_vm._v(_vm._s(warr.content.title))]
                                  ),
                                  _c("td", { staticClass: "ta-c file-type" }, [
                                    _vm._v(
                                      _vm._s(warr.attribute.warranty_file_type)
                                    ),
                                  ]),
                                  _c("td", { staticClass: "file-size" }, [
                                    _vm._v(
                                      _vm._s(warr.attribute.warranty_file_size)
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "share" },
                                    [
                                      _c("ShareComponent", {
                                        attrs: {
                                          childValue: warr,
                                          type: "warranty",
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-down",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadFile(
                                                warr.attribute.warranty_file
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("warranty.text.download")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "pagination" }, [
                          _c(
                            "ul",
                            [
                              _c("li", { staticClass: "btn btn-first" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hasIndex(_vm.firstNumber)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("warranty.text.first_page"))
                                    ),
                                  ]
                                ),
                              ]),
                              _c("li", { staticClass: "btn btn-prev" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.hasIndex(_vm.pageIndex - 1)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("warranty.text.prev")))]
                                ),
                              ]),
                              _vm._l(_vm.pageList, function (index) {
                                return _c(
                                  "li",
                                  {
                                    staticClass: "btn-num",
                                    attrs: {
                                      id: "index_" + index,
                                      "data-chk": index,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hasIndex(index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      { attrs: { type: "button" } },
                                      [_c("span", [_vm._v(_vm._s(index))])]
                                    ),
                                  ]
                                )
                              }),
                              _c("li", { staticClass: "btn btn-next" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hasIndex(_vm.pageIndex + 1)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("warranty.text.next")))]
                                ),
                              ]),
                              _c("li", { staticClass: "btn btn-last" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hasIndex(_vm.lastNumber)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("warranty.text.last_page"))
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      (_vm.nationCode == "_US" &&
                        _vm.selectedContentNm == "VIATERA") ||
                      (_vm.nationCode == "_US" &&
                        _vm.selectedContentNm == "Viatera") ||
                      (_vm.nationCode == "_US" &&
                        _vm.selectedContentNm == "HIMACS") ||
                      (_vm.nationCode == "_US" &&
                        (_vm.selectedContentNm == "TERACANTO" ||
                          _vm.selectedContentNm == "teracanto")) ||
                      (_vm.nationCode == "_US" &&
                        _vm.selectedContentNm == "HIMACS Sink") ||
                      (_vm.nationCode == "_US" &&
                        _vm.selectedContentNm == "HIMACS SINK") ||
                      _vm.warrantyToRegister === true
                        ? _c(
                            "div",
                            { staticClass: "tbl-input-box mt-80 wtr-area" },
                            [
                              _c("fieldset", [
                                _c("legend", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "warranty.button.warranty_to_register"
                                      )
                                    )
                                  ),
                                ]),
                                _c("div", { staticClass: "reg-tbl" }, [
                                  _c("p", { staticClass: "tt-2 mb-40" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "warranty.button.warranty_to_register"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-02" }, [
                                    _c("div", { staticClass: "tbl-01" }, [
                                      _c("div", { staticClass: "tbl-input" }, [
                                        _c("table", [
                                          _c("caption", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "warranty.text.WarrantyRegisterForm"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "must" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.regTxt"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sel-box type02",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-sel",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(_vm.regTxt)
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-list",
                                                        },
                                                        [
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "box",
                                                            },
                                                            _vm._l(
                                                              _vm.userTypeList,
                                                              function (
                                                                reg,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "li",
                                                                  { key: idx },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.clickSelectBox(
                                                                                reg.text,
                                                                                reg
                                                                                  .id
                                                                                  .value,
                                                                                "userType"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            reg.text
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.data.userType == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.select_product"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "must" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.color"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.data.color,
                                                        expression:
                                                          "data.color",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "color",
                                                      placeholder: _vm.$t(
                                                        "warranty.text.input_text"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value: _vm.data.color,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "color",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.color == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          "Enter Product Color"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "tbl-02" }, [
                                      _c(
                                        "div",
                                        { staticClass: "tbl-input type03" },
                                        [
                                          _c("table", [
                                            _c("caption", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.WarrantyRegisterForm"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.categoryTxt"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sel-box type02",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-sel",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.categoryTxt
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-list",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "box",
                                                              },
                                                              _vm._l(
                                                                _vm.categoryIdList,
                                                                function (
                                                                  prd,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key: idx,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickSelectBox(
                                                                                  prd.text,
                                                                                  prd
                                                                                    .id
                                                                                    .value,
                                                                                  "categoryId"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              prd.text
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.categoryId == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_registering"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tbl t-01" }, [
                                  _c("p", { staticClass: "tt-2 mb-40" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("warranty.text.personal_info")
                                      )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-02" }, [
                                    _c("div", { staticClass: "tbl-01" }, [
                                      _c("div", { staticClass: "tbl-input" }, [
                                        _c("table", [
                                          _c("caption", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "warranty.text.personal_info_form"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp01" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.first_name"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.data.firstNm,
                                                        expression:
                                                          "data.firstNm",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp01",
                                                      placeholder: _vm.$t(
                                                        "warranty.text.input_text"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value: _vm.data.firstNm,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "firstNm",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.firstNm == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.Enter_First_Name"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp02" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.last_name"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.data.lastNm,
                                                        expression:
                                                          "data.lastNm",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp02",
                                                      placeholder: _vm.$t(
                                                        "warranty.text.input_text"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value: _vm.data.lastNm,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "lastNm",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.lastNm == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.Enter_Last_Name"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp03" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.address1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.data.addr1,
                                                        expression:
                                                          "data.addr1",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp03",
                                                      placeholder: _vm.$t(
                                                        "warranty.text.input_text"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value: _vm.data.addr1,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "addr1",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.addr1 == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.enter_address"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "inp04" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.Address2"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.data.addr2,
                                                      expression: "data.addr2",
                                                    },
                                                  ],
                                                  staticClass: "inp-text",
                                                  attrs: {
                                                    type: "text",
                                                    id: "inp04",
                                                    placeholder: _vm.$t(
                                                      "warranty.text.input_text"
                                                    ),
                                                  },
                                                  domProps: {
                                                    value: _vm.data.addr2,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.data,
                                                        "addr2",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp05" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.telephone"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.data.phone,
                                                        expression:
                                                          "data.phone",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp05",
                                                      placeholder:
                                                        "000-000-0000",
                                                      oninput:
                                                        "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                                    },
                                                    domProps: {
                                                      value: _vm.data.phone,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "phone",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.phone == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.enter_telephone"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "tbl-02" }, [
                                      _c(
                                        "div",
                                        { staticClass: "tbl-input type03" },
                                        [
                                          _c("table", [
                                            _c("caption", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.personal_info_form"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "must",
                                                        attrs: { for: "inp06" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.email"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.data.email,
                                                          expression:
                                                            "data.email",
                                                        },
                                                      ],
                                                      staticClass: "inp-text",
                                                      attrs: {
                                                        type: "text",
                                                        id: "inp06",
                                                        placeholder: _vm.$t(
                                                          "warranty.text.input_text"
                                                        ),
                                                      },
                                                      domProps: {
                                                        value: _vm.data.email,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.data,
                                                            "email",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    (_vm.data.email == "" &&
                                                      _vm.submitChk == "Y") ||
                                                    _vm.emailValidTxt == false
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.enter_email"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.country"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sel-box type02",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-sel",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.countryTxt
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-list",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "box",
                                                              },
                                                              _vm._l(
                                                                _vm.countryList,
                                                                function (
                                                                  cnt,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key: idx,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickSelectBox(
                                                                                  cnt.countryNm,
                                                                                  cnt.countryCd,
                                                                                  "country"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              cnt.countryNm
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.countryCd == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_country"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.state"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sel-box type02",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-sel",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.stateTxt
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-list",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "box",
                                                              },
                                                              _vm._l(
                                                                _vm.stateList,
                                                                function (
                                                                  state
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickSelectBox(
                                                                                  state.stateNm,
                                                                                  state
                                                                                    .id
                                                                                    .stateCd,
                                                                                  "state"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              state.stateNm
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.stateCd == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_state"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "must",
                                                        attrs: { for: "inp07" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.zip_code"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.data.zipCd,
                                                          expression:
                                                            "data.zipCd",
                                                        },
                                                      ],
                                                      staticClass: "inp-text",
                                                      attrs: {
                                                        type: "text",
                                                        id: "inp07",
                                                        placeholder: _vm.$t(
                                                          "warranty.text.input_text"
                                                        ),
                                                      },
                                                      domProps: {
                                                        value: _vm.data.zipCd,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.data,
                                                            "zipCd",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.data.zipCd == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.enter_zip_code"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "must",
                                                        attrs: { for: "inp08" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.city"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.data.city,
                                                          expression:
                                                            "data.city",
                                                        },
                                                      ],
                                                      staticClass: "inp-text",
                                                      attrs: {
                                                        type: "text",
                                                        id: "inp18",
                                                        placeholder: _vm.$t(
                                                          "warranty.text.input_text"
                                                        ),
                                                      },
                                                      domProps: {
                                                        value: _vm.data.city,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.data,
                                                            "city",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.data.city == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.enter_city"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tbl" }, [
                                  _c("p", { staticClass: "tt-2 mb-40" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("warranty.text.dealer_info")
                                      )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-02" }, [
                                    _c("div", { staticClass: "tbl-01" }, [
                                      _c("div", { staticClass: "tbl-input" }, [
                                        _c("table", [
                                          _c("caption", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "warranty.text.dealer_info_form"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp08" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.dealer"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.data.dealer,
                                                        expression:
                                                          "data.dealer",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp08",
                                                      placeholder: _vm.$t(
                                                        "warranty.text.input_text"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value: _vm.data.dealer,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "dealer",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.dealer == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.enter_dealer"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp09" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.dealer_city"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.data.dealerCity,
                                                        expression:
                                                          "data.dealerCity",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp09",
                                                      placeholder: _vm.$t(
                                                        "warranty.text.input_text"
                                                      ),
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.data.dealerCity,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "dealerCity",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.dealerCity == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.enter_dealer_city"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "must",
                                                      attrs: { for: "inp10" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.dealer_phone"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.data.dealerPhone,
                                                        expression:
                                                          "data.dealerPhone",
                                                      },
                                                    ],
                                                    staticClass: "inp-text",
                                                    attrs: {
                                                      type: "text",
                                                      id: "inp10",
                                                      placeholder:
                                                        "000-000-0000",
                                                      oninput:
                                                        "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.data.dealerPhone,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.data,
                                                          "dealerPhone",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.data.dealerPhone == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.enter_dealer_phone"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "tbl-02" }, [
                                      _c(
                                        "div",
                                        { staticClass: "tbl-input type03" },
                                        [
                                          _c("table", [
                                            _c("caption", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.dealer_info_form"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "must",
                                                        attrs: { for: "inp11" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.fabricator"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.data.installer,
                                                          expression:
                                                            "data.installer",
                                                        },
                                                      ],
                                                      staticClass: "inp-text",
                                                      attrs: {
                                                        type: "text",
                                                        id: "inp11",
                                                        placeholder: _vm.$t(
                                                          "warranty.text.input_text"
                                                        ),
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.data.installer,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.data,
                                                            "installer",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.data.installer == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.enter_fabricator"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "must",
                                                        attrs: { for: "inp12" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.fabricator_phone"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.data
                                                              .installerPhone,
                                                          expression:
                                                            "data.installerPhone",
                                                        },
                                                      ],
                                                      staticClass: "inp-text",
                                                      attrs: {
                                                        type: "text",
                                                        id: "inp12",
                                                        placeholder:
                                                          "000-000-0000",
                                                        oninput:
                                                          "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.data
                                                            .installerPhone,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.data,
                                                            "installerPhone",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.data.installerPhone ==
                                                      "" && _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.enter_fabricator_phone"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.date_of_installation"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "sel-day",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-box type02",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-sel",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.installMonthTxt
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "sel-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "box",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.installMonthList,
                                                                    function (
                                                                      mon,
                                                                      idx
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: idx,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "button",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.clickSelectBox(
                                                                                      mon,
                                                                                      "",
                                                                                      "month"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  mon
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-box type02",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-sel",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.installDayTxt
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "sel-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "box",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.installDayList,
                                                                    function (
                                                                      day,
                                                                      idx
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: idx,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "button",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.clickSelectBox(
                                                                                      day,
                                                                                      "",
                                                                                      "day"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  day
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-box type02",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-sel",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.installYearTxt
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "sel-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "box",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.installYearList,
                                                                    function (
                                                                      year,
                                                                      idx
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: idx,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "button",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.clickSelectBox(
                                                                                      year,
                                                                                      "",
                                                                                      "year"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  year
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.installMonth ==
                                                      "" ||
                                                    _vm.data.installDay == "" ||
                                                    (_vm.data.installYear ==
                                                      "" &&
                                                      _vm.submitChk == "Y")
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_date"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "ta-box" }, [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.memo,
                                          expression: "data.memo",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "warranty.text.comments"
                                        ),
                                      },
                                      domProps: { value: _vm.data.memo },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.data,
                                            "memo",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tbl" }, [
                                  _c("p", { staticClass: "tt-2 mb-40" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("warranty.text.product_info")
                                      )
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-02" }, [
                                    _c("div", { staticClass: "tbl-01" }, [
                                      _c("div", { staticClass: "tbl-input" }, [
                                        _c("table", [
                                          _c("caption", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "warranty.text.product_info_form"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("tbody", [
                                            _c(
                                              "tr",
                                              { staticClass: "dvsn-for-rspns" },
                                              [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.materialsTxt"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sel-box type02",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-sel",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.materialsTxt
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-list",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "box",
                                                              },
                                                              _vm._l(
                                                                _vm.materialList,
                                                                function (
                                                                  mat,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key: idx,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickSelectBox(
                                                                                  mat.text,
                                                                                  mat
                                                                                    .id
                                                                                    .value,
                                                                                  "materials"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              mat.text
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.chkMaterials ==
                                                      "" && _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_materialsTxt"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "must" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.upload_file"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt ctt-t4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.upload_file_brackets"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "inp-file" },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "file01",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.upload"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        ref: "submitImage",
                                                        attrs: {
                                                          type: "file",
                                                          id: "file01",
                                                          multiple: "multiple",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.onInputImage()
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "list-upload-file",
                                                    },
                                                    _vm._l(
                                                      _vm.data.image,
                                                      function (file) {
                                                        return _c("li", [
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                title:
                                                                  "20211129_Viatera_Receipt_1.pdf",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  file.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "button",
                                                            {
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.imageDel(
                                                                      file.name
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("del")]
                                                          ),
                                                        ])
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _vm.data.image.length == 0 &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.select_upload_file"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "tbl-02" }, [
                                      _c(
                                        "div",
                                        { staticClass: "tbl-input type03" },
                                        [
                                          _c("table", [
                                            _c("caption", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.product_info_form"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.sink_type"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sel-box type02",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-sel",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.sinkTypeTxt
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-list",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "box",
                                                              },
                                                              _vm._l(
                                                                _vm.sinkTypeList,
                                                                function (
                                                                  sink,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key: idx,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickSelectBox(
                                                                                  sink.text,
                                                                                  sink
                                                                                    .id
                                                                                    .value,
                                                                                  "sinkType"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              sink.text
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.sinkType == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_sink_type"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "row" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "must" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.hear"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sel-box type02",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-sel",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.hearPathTxt
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sel-list",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "box",
                                                              },
                                                              _vm._l(
                                                                _vm.hearPathList,
                                                                function (
                                                                  hear,
                                                                  idx
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key: idx,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickSelectBox(
                                                                                  hear.text,
                                                                                  hear
                                                                                    .id
                                                                                    .value,
                                                                                  "hearPath"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              hear.text
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.data.hearPath == "" &&
                                                    _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.select_hear"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "mt-64 ta-c" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.submitForm()
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("warranty.button.submit")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.nationCode !== "_GL" && _vm.nationCode !== "_US"
          ? _c(
              "div",
              {
                staticClass:
                  "container cntu-box care-container eu-container-warranty",
              },
              [
                _c("div", { staticClass: "w-con-02" }, [
                  _c("div", { staticClass: "inner" }, [
                    _c("div", { staticClass: "sub-visual-section" }, [
                      _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                        _vm._v(_vm._s(_vm.$t("warranty.text.warranty"))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "tab-ui tab-ui-dark tab-care" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      {
                        staticClass: "tab-con tab01 on inner",
                        attrs: { id: "inner01" },
                      },
                      [
                        _c("div", { staticClass: "tac dis-block" }, [
                          _c("p", { staticClass: "ctt ctt-t1 mb-20" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("warranty.text.hflor_t1")) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "ctt ctt-t2 mb-50 maxw900" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("warranty.text.hflor_t2")) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "down-box wa-box" }, [
                          _c("div", { staticClass: "tit-box" }, [
                            _c("p", { staticClass: "tt-2" }, [
                              _vm._v(
                                _vm._s(_vm.$t("warranty.text.warranty_down"))
                              ),
                            ]),
                            _c("div", { staticClass: "more-box type02-04" }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "tbl-box type05 box-dl hf-tbl" },
                            [
                              _c("table", [
                                _c("caption", [_vm._v("download_form")]),
                                _vm._m(4),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.hFlorContentList,
                                    function (content, index) {
                                      return _c("tr", [
                                        _c("th", { staticClass: "dl-title" }, [
                                          _vm._v(
                                            _vm._s(content.parents[0].name)
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "dl-disabled-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(content.content.title)
                                            ),
                                          ]
                                        ),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "sel-box type02" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-sel",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        content.clickDocLabel
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "sel-list" },
                                                [
                                                  _c(
                                                    "ul",
                                                    { staticClass: "box" },
                                                    _vm._l(
                                                      content.docList,
                                                      function (doc) {
                                                        return _c("li", [
                                                          _c(
                                                            "button",
                                                            {
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.docChange(
                                                                      doc.label,
                                                                      doc.file,
                                                                      doc.type,
                                                                      index,
                                                                      "HFLOR"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  doc.label
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ta-c file-type" },
                                          [_vm._v(_vm._s(content.fileType))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "share-box" },
                                          [
                                            _c("ShareComponent", {
                                              attrs: {
                                                "data-chk": content,
                                                childValue: content,
                                                type: "document",
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-down",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downloadFile(
                                                      content.clickDocFile
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("button.download")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab-con tab02 inner",
                        attrs: { id: "inner02" },
                      },
                      [
                        _c("div", { staticClass: "tac dis-block" }, [
                          _c("p", { staticClass: "ctt ctt-t1 mb-20" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("warranty.text.himacs_t1")) +
                                " "
                            ),
                          ]),
                          _c(
                            "p",
                            { staticClass: "ctt ctt-t2 mb-50 maxw1000" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("warranty.text.himacs_t2")) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "tac mb-50 hm-qc" }, [
                            _c("img", {
                              attrs: { src: `images/support/qc@3x.png` },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "down-box wa-box" }, [
                          _c("div", { staticClass: "tit-box" }, [
                            _c("p", { staticClass: "tt-2" }, [
                              _vm._v(
                                _vm._s(_vm.$t("warranty.text.warranty_down"))
                              ),
                            ]),
                            _c("div", { staticClass: "more-box type02-04" }),
                          ]),
                          _c("div", { staticClass: "tbl-box type05 box-dl" }, [
                            _c("table", [
                              _c("caption", [_vm._v("download_form")]),
                              _vm._m(5),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.hImacsContentList,
                                  function (himacs, index) {
                                    return _c("tr", [
                                      _c("th", { staticClass: "dl-title" }, [
                                        _vm._v(_vm._s(himacs.parents[0].name)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "dl-disabled-mobile" },
                                        [_vm._v(_vm._s(himacs.content.title))]
                                      ),
                                      _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "sel-box type02" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn-sel",
                                                attrs: { type: "button" },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(himacs.clickDocLabel)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "sel-list" },
                                              [
                                                _c(
                                                  "ul",
                                                  { staticClass: "box" },
                                                  _vm._l(
                                                    himacs.docList,
                                                    function (doc) {
                                                      return _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.docChange(
                                                                  doc.label,
                                                                  doc.file,
                                                                  doc.type,
                                                                  index,
                                                                  "HIMACS"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(doc.label)
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "ta-c" }, [
                                        _vm._v(_vm._s(himacs.fileType)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "share-box" },
                                        [
                                          _c("ShareComponent", {
                                            attrs: {
                                              "data-chk": himacs,
                                              childValue: himacs,
                                              type: "document",
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-down",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadFile(
                                                    himacs.clickDocFile
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("button.download")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "frow" }, [
        _c("div", { staticClass: "fcol tac" }, [
          _c("p", { staticClass: "msg" }, [
            _vm._v(" Our Limited Warranty for manufacturing defects excludes"),
            _c("br"),
            _vm._v(" • Etches"),
            _c("br"),
            _vm._v(" • Stains"),
            _c("br"),
            _vm._v(" • Seams"),
            _c("br"),
            _vm._v(" • Chips & Cracks"),
            _c("br"),
            _vm._v(" • Hard Water Marks & Deposits"),
            _c("br"),
            _vm._v(" • Caulking, grout and joint expansion"),
            _c("br"),
            _vm._v(" • Scratches"),
            _c("br"),
            _c("br"),
            _vm._v(
              " These items can be covered by Extended Protection Plan(EPP). Do you want to register for an Extended Protection Plan? By choosing “Yes” or “No” you understand and accept that the above items will not be covered under the LX Hausys Limited Warranty. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "type02" }, [
      _c("ul", { staticClass: "tab-list" }, [
        _c(
          "button",
          {
            staticClass: "arrow disabled tab-arr-prev",
            attrs: { type: "button" },
          },
          [_c("span", { staticClass: "sr-only" })]
        ),
        _c(
          "button",
          { staticClass: "arrow tab-arr-next", attrs: { type: "button" } },
          [_c("span", { staticClass: "sr-only" })]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", { staticClass: "p-ui" }, [
      _c("col", { staticStyle: { width: "285px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "110px" } }),
      _c("col", { staticStyle: { width: "100px" } }),
      _c("col", { staticStyle: { width: "140px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "btns02" }, [
      _c(
        "li",
        {
          staticClass: "btn-tab on",
          attrs: { id: "btn-tab01", "data-tab": "tab01" },
        },
        [_c("button", { attrs: { type: "button" } }, [_vm._v("hflor")])]
      ),
      _c(
        "li",
        {
          staticClass: "btn-tab wide",
          attrs: { id: "btn-tab02", "data-tab": "tab02" },
        },
        [_c("button", { attrs: { type: "button" } }, [_vm._v("himacs")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", { staticClass: "p-ui" }, [
      _c("col", { staticStyle: { width: "280px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "160px" } }),
      _c("col", { staticStyle: { width: "110px" } }),
      _c("col", { staticStyle: { width: "140px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", { staticClass: "p-ui" }, [
      _c("col", { staticStyle: { width: "280px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "160px" } }),
      _c("col", { staticStyle: { width: "110px" } }),
      _c("col", { staticStyle: { width: "140px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }