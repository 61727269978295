var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "achievements-at-design-awards-card" }, [
    _c("div", { staticClass: "text-area" }, [
      _c("pre", { staticClass: "number" }, [_vm._v(_vm._s(_vm.number))]),
      _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]),
    _c("div", { staticClass: "line" }),
    _c(
      "div",
      { staticClass: "item-area" },
      _vm._l(_vm.itemDatas, function (item, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c("img", { staticClass: "item-image", attrs: { src: item.image } }),
          _c("pre", { staticClass: "item-number" }, [
            _vm._v(_vm._s(item.itemNumber)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }