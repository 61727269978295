<template>
  <ul>
    <COMPARE></COMPARE>
    <COLLECTION :collectionList="collectionList" v-model="collection"></COLLECTION>
    <IS_NEW v-model="isNew"></IS_NEW>
    <WOODCOLOR :huewList="dictMap['hue_w']" v-model="hue_w"></WOODCOLOR>
    <WOODSPECIES :treespeciesList="dictMap['tree_species']" v-model="tree_species"></WOODSPECIES>
    <WOODPATTERN :woodpatternList="dictMap['pattern_w']" v-model="pattern_w"></WOODPATTERN>
    <COLOR :colorList="dictMap['hue']" v-model="hue"></COLOR>
    <METALPATTERN :metalpatternList="dictMap['pattern_m']" v-model="pattern_m"></METALPATTERN>
    <STONETYPE :tpyesList="dictMap['type_s']" v-model="type_s"></STONETYPE>
    <TEXTILETYPE :texttileList="dictMap['type_t']" v-model="type_t"></TEXTILETYPE>
    <FIRERESISTANCE :fireResistList="dictMap['fire_resistance']" v-model="fire_resistance"></FIRERESISTANCE>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import COMPARE from './component/COMPARE.vue'
import COLOR from './component/COLOR.vue'
import COLLECTION from './component/COLLECTION.vue'
import IS_NEW from './component/IS_NEW.vue'
import WOODCOLOR from './component/WOODCOLOR.vue'
import WOODSPECIES from './component/WOODSPECIES.vue'
import WOODPATTERN from './component/WOODPATTERN.vue'
import METALPATTERN from './component/METALPATTERN.vue'
import TEXTILETYPE from './component/TEXTILETYPE.vue'
import FIRERESISTANCE from './component/FIRERESISTANCE.vue'
import STONETYPE from './component/STONETYPE.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    COMPARE,
    COLOR,
    COLLECTION,
    WOODCOLOR,
    WOODSPECIES,
    WOODPATTERN,
    METALPATTERN,
    STONETYPE,
    TEXTILETYPE,
    FIRERESISTANCE,
    IS_NEW
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      collection: [],
      hue: [],
      pattern: [],
      finish: [],
      country: [],
      isNew: [],
      hue_w: [],
      tree_species: [],
      pattern_w: [],
      pattern_m: [],
      type_s: [],
      type_t: [],
      fire_resistance: []
    }
  },
  watch: {},
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.collection, vm.hue_w, vm.tree_species, vm.pattern_m, vm.pattern_w, vm.type_t, vm.type_s, vm.fire_resistance, vm.isNew], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {}
}
</script>
