var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "div",
        { staticClass: "pollutant-management-border-box" },
        [
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content1.text, unit: _vm.content1.unit },
          }),
          _c("div", { staticClass: "line" }),
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content2.text, unit: _vm.content2.unit },
          }),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "div",
        { staticClass: "pollutant-management-border-box" },
        [
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content1Jp.text, unit: _vm.content1Jp.unit },
          }),
          _c("div", { staticClass: "line" }),
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content2Jp.text, unit: _vm.content2Jp.unit },
          }),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "div",
        { staticClass: "pollutant-management-border-box" },
        [
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content1Ru.text, unit: _vm.content1Ru.unit },
          }),
          _c("div", { staticClass: "line" }),
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content2Ru.text, unit: _vm.content2Ru.unit },
          }),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "div",
        { staticClass: "pollutant-management-border-box" },
        [
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content1Cn.text, unit: _vm.content1Cn.unit },
          }),
          _c("div", { staticClass: "line" }),
          _c("PollutantManagementBorderBoxContent", {
            attrs: { text: _vm.content2Cn.text, unit: _vm.content2Cn.unit },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }