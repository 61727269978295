<template>
  <OverviewSmallSection title="Building & Decorative Materials" fontWeight="700" fontFamily="Lato" v-if="nationCode == '_US' || nationCode == '_GL'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="materialsCarouselItems" :desc="desc" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- JP -->
  <OverviewSmallSection title="建築・装飾資材" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_JP'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="materialsCarouselItemsJp" :desc="descJp" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- RU -->
  <OverviewSmallSection title="Строительные и декоративные материалы" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_RU'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="materialsCarouselItemsRu" :desc="descRu" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- CN -->
  <OverviewSmallSection title="建筑&装饰材料" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_CN'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="materialsCarouselItemsCn" :desc="descCn" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
</template>

<script>
import OverviewSmallSection from '../../components/OverviewSmallSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import BusinessAreaCarousel from '../components/BusinessAreaCarousel.vue'

export default {
  components: {
    OverviewSmallSection,
    OverviewSectionItem,
    BusinessAreaCarousel
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      materialsCarouselItems: [
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-1.jpg', title: 'Resilient Flooring (HFLOR)' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-2.jpg', title: 'Solid Surface (HIMACS)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-3.jpg', title: 'Quartz Surface (VIATERA)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-4.jpg', title: 'Porcelain Surface (TERACANTO)' }
      ],
      materialsCarouselItemsJp: [
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-1.jpg', title: 'フローリング (HFLOR)' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-2.jpg', title: '人工大理石 (HIMACS)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-3.jpg', title: 'クォーツストーン (VIATERA)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-4.jpg', title: 'ポーセリン (TERACANTO)' }
      ],
      materialsCarouselItemsRu: [
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-1.jpg', title: 'Напольные покрытия (HFLOR)' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-2.jpg', title: 'Акриловый камень (HIMACS)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-3.jpg', title: 'Кварцевый агломерат (VIATERA)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-4.jpg', title: 'Керамогранит (TERACANTO)' }
      ],
      materialsCarouselItemsCn: [
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-1.jpg', title: '地板 (HFLOR)' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-2.jpg', title: '人造大理石 (HIMACS)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-3.jpg', title: '石英石 (VIATERA)', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/materials-interior-4.jpg', title: '岩板 (TERACANTO)' }
      ],
      desc: 'We offer Flooring that embodies market-leading green products, featuring customized designs for every space and a wide array of functions and product features.\n' +
  'Additionally, we provide acrylic solid surfaces and engineered stone materials, which are highly sought after by world-renowned architects.\n' +
  'All of these products are part of our offerings to create healthy and safe spaces.',
      descJp:'当社は、あらゆる空間に合わせたカスタマイズデザインと幅広い機能と製品特性を備えた、市場をリードするグリーン製品を具現化したフローリングを提供しています。\n' + 
  'さらに、世界的に有名な建築家から高い評価を得ている人工大理石とクォーツストーンもお届けしています。これらすべての製品は、\n' +
  '健康的で安全な空間を創造するための当社の製品の一部です。',
      descRu:'Мы предлагаем напольные покрытия, которые воплощают в себе лидирующие на рынке экологически чистые продукты, отличающиеся индивидуальным дизайном для \n' + 
  'любого помещения и широким набором функций и характеристик. Также мы предлагаем акриловые твердые поверхности и материалы из искусственного камня, \n' + 
  'которые пользуются большим спросом у всемирно известных архитекторов.Все эти продукты являются частью наших предложений по созданию \n' + 
  'здоровых и безопасных пространств.',
      descCn:'我们提供的地板体现了市场领先的绿色产品，为每个空间提供定制设计，并具有广泛的功能和产品特性。此外，亚克力人造大理石, \n' + 
  '石英石，深受世界知名建筑师的追捧。LX Hausys产品均为我们创造健康安全空间的产品的一部分。',
      from: 'BuildingDecorativeMaterials',
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.txt {
  background: black;
}
</style>
