<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content pros-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('Document.Text.Home')}}</li>
          <li>{{ $t('Document.Text.Pros') }}</li>
          <li>{{ $t('Document.Text.Documentation')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (documentation) -->
      <div class="container prod-fd-view documentaion">
        <div class="w-con-02">
          <h1 class="tt-1 mb-88 tac inner">{{ $t('Document.Text.Documentation')}} </h1>
        </div>

        <div class="inspr-view fd-view">
          <div class="tab-ui-nav type_line type_one_line">
            <ul id="main_tap_ul">
              <li class="btn-tab" name="mainTap" :id="categoryCode" :value="allCategoryId" @click="mainTaps(allCategoryId, '', 0)"><button type="button">{{ $t('Document.Text.ALL') }}</button></li>
              <li class="btn-tab" name="mainTap" :id="'mainTap_'+cate.id" v-for="(cate, index) in categoryList" @click="mainTaps(cate.id, '', (index+1))"><button type="button">{{cate.name}}</button></li>
            </ul>
          </div>
          <div class="inner">
            <div class="tab-con tab02" v-if="(countryCode == '_US' || countryCode == '_GL') && taps ">
              <div class="tab-ui tab-ui-nav tab-ui-gray">
                <ul id="sub_tap_ul">
                  <button type="button" class="arrow disabled tab-arr-prev">
                    <span class="sr-only">이전</span>
                  </button>
                  <button type="button" class="arrow tab-arr-next">
                    <span class="sr-only">다음</span>
                  </button>
                  <li class="btn-tab on" id="subAllTap" v-if="this.subCategoryList.length > 0"><button type="button" @click="subTap(tapId)">All</button></li>
                  <li class="btn-tab" :id="subCate.code" name="subTap" v-for="subCate in subCategoryList" @click="subTap(subCate.id)"><button type="button">{{ subCate.name }}</button></li>
                </ul>
              </div>
            </div>

            <!-- select result -->
            <div class="pros-box col-02">

              <!-- search 필터 선택 pc전용 -->
              <div class="search-box doc-pc-filter" id="filterSearch" v-if="!isMobile()">
                <div class="search">
                  <input type="text" :placeholder="$t('Document.Text.SearchBar')" id="search" v-on:keyup.enter="search"/>
                  <button type="button" @click="search()" class="icon-search gray">
                    <span class="sr-only">
                      search
                    </span>
                  </button>
                </div>
                <!-- <div class="filter-arae" v-if="isMobile()">
                  <button type="button" @click="clickFilter()">{{ $t('PF.button.filter_by')}}<i></i></button>
                </div> -->

                <div class="drop-list">
                  <ul>
                    <li class="on">
                      <div class="tit">
                        <span>{{ $t("Document.Text.Document_Type") }}</span>
                        <button type="button" class="btn-view" @click="toggleAccordion">list view</button>
                      </div>

                      <div class="l-box cont" :style="{ display: isAccordionOpen ? 'block' : 'none' }">
                        <ul class="i-chk type03">
                          <li v-for="doc in documentType">
                            <label class="inp-chk">
                              <input type="checkbox" class="docBox" :id="'type_'+doc.id.value" @change="[typeClick(doc.id.value, doc.text)]">
                              <span>{{doc.text}}</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //search -->

              <!-- 모바일용 검색창 -->
              <div class="search-box" id="filterSearch" v-show="isMobile()">
                <div class="search">
                  <input type="text" :placeholder="$t('Document.Text.SearchBar')" id="search" v-on:keyup.enter="search"/>
                  <button type="button" @click="search()" class="icon-search gray">
                    <span class="sr-only">
                      search
                    </span>
                  </button>
                </div>
              </div>
              <!-- 모바일용 검색창 end -->

              <div class="prod-list-box doc-list-box">

                <!-- 모바일용 filter by 버튼 s -->
                <div class="filter-arae mb-only">
                  <button type="button" @click="openSearchPopup('layer-search-box')">
                    {{ $t('Document.Button.filter_by')}}<i></i>
                  </button>
                </div>
                <!-- 모바일용 filter by 버튼 e -->

                <div class="tab-ui">

                  <!-- sort -->
                  <div class="sort-box">
                    <div class="sel-box">
                      <button type="button" class="btn-sel"><span>{{ contentSortBy }}</span></button>
                      <div class="sel-list">
                        <ul class="box">
                          <li>
                            <button type="button" @click="sortByChange($t('Document.Button.popu'), 'desc', 'clicks')">{{ $t("Document.Button.popu") }}</button>
                          </li>
                          <li>
                            <button type="button" @click="sortByChange($t('Document.Button.Asc'), 'asc', 'title')">{{ $t("Document.Button.Asc") }}</button>
                          </li>
                          <li>
                            <button type="button" @click="sortByChange($t('Document.Button.Desc'), 'desc', 'title')">{{ $t("Document.Button.Desc") }}</button>
                          </li>
                          <li>
                            <button type="button" @click="sortByChange($t('Document.Button.Newest'), 'desc')">{{ $t("Document.Button.Newest") }}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- //sort -->

                  <!-- S : selected categories -->
                  <div class="select-view">
                    <ul class="list-filter-key">
                      <li class="sel" v-for="(hfolr, index) in HFOLRList">
                        <span>{{hfolr.text}}</span>
                        <button type="button" @click="DeleteType(index, hfolr.id)">&#10005;</button>
                      </li>
                      <li class="sel del_all" v-if="HFOLRList.length > 0">
                        <button type="button" @click="allTypeDelete()">
                          <span>{{ $t("Document.Text.Clear") }} <em>&#10005;</em></span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <!-- E : selected categories -->

                  <!-- S : selectAll & result count -->
                  <div class="result-controler">
                    <label class="inp-chk">
                      <input type="checkbox" id="allCheck" v-model="allSelected" @click="selectedAll()">
                      <span>{{ $t("Document.Text.Select_All") }}</span>
                    </label>
                    <div class="filter-cnt"><strong>{{ totalCount }}</strong> {{ $t("Document.Text.Results") }}</div>
                  </div>
                  <!-- E : selectAll & result count -->

                  <div class="tbl-box type05 dl-dcmt">
                    <table>
                      <caption>Shipping form</caption>
                      <!-- 일반 사이즈 colgroup (1440px이상에서 적용)-->
                      <colgroup class="p-ui doc-p-ui">
                        <col style="width:63px">
                        <col style="width:285px">
                        <col style="width:auto">
                        <col style="width:110px">
                        <col style="width:100px">
                        <col style="width:140px">
                      </colgroup>
                      <!-- 테블릿 사이즈 colgroup (1440px ~ 769px 에서 적용)-->
                      <colgroup class="p-ui doc-p-ui">
                        <col style="width:6.3%">
                        <col style="width:20%">
                        <col style="width:35%">
                        <col style="width:75px">
                        <col style="width:10%">
                        <col style="width:14%">
                      </colgroup>
                      <tbody>
                      <tr class="doc-table-cont-box" v-for="(content, index) in contentList">
                        <td class="tac dcmt-chk">
                          <label class="inp-chk single">
                            <input type="checkbox" name="conBox" :id="'content_'+(index+1)" @change="checkboxSelected(content.content.id, (index+1))">
                            <span>All Select</span>
                          </label>
                        </td>
                        <th class="doc-table-th" v-if="content.parents.length === 5 && (countryCode == '_US' || countryCode == '_GL')">{{ content.parents[1].name }}<br>[{{content.parents[0].name}}]<br> {{ content.attribute.doctypeText }}</th>
                        <th class="doc-table-th" v-else-if="content.parents.length !== 5 && (countryCode == '_US' || countryCode == '_GL')">{{ content.parents[0].name }}<br>{{ content.attribute.doctypeText }}</th>
                        <th class="doc-table-th" v-else-if="(countryCode != '_US' && countryCode != '_GL') && content.parents[0].name == 'HFLOR'">{{ content.parents[0].name }}<br>[{{ $t("Document.Text.lvt") }}]<br>{{ content.attribute.doctypeText }}</th>
                        <th class="doc-table-th" v-else>{{ content.parents[0].name }}<br>{{ content.docType[0].text }}</th>
                        <td class="doc-table-title">
                          <h3>
                            <a class="titleTag doc-table-td-a" v-if="(content.attribute.doc_file_type == 'pdf' || content.attribute.doc_file_type == 'PDF') && (countryCode == '_GL' || countryCode == '_US')" :href="$imagePath + content.attribute.doc_file" target="_blank" style="text-align: left">{{ content.content.title }}</a>
                            <a class="titleTag doc-table-td-a" v-else-if="content.attribute.doc_file_type == 'pdf' && (countryCode != '_GL' && countryCode != '_US')" :href="$imagePath + content.clickDocFile" target="_blank" style="text-align: left">{{ content.content.title }}</a>
                            <a class="titleTag doc-table-td-a" v-else="content.attribute.doc_file_type != 'pdf'" href="javascript:void(0)" style="text-align: left">{{ content.content.title }}</a>
                          </h3>
                        </td>
                        <td class="pro-doc-sel" v-if="countryCode != '_GL' && countryCode != '_US'">
                          <div class="btnn">
                            <ul>
                              <li class="sel-box">
                                <button type="button" class="btn-seg btn-sel">
                                  <span>{{ content.clickDocLabel }}</span>
                                </button>
                                <div class="sel-list" style="display: none">
                                  <ul class="box">
                                    <li v-for="(doc) in content.docList">
                                      <button type="button" class="doc-nonred" @click="docChange(doc.label, doc.file, doc.type, index)">{{ doc.label }}</button>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td class="tac file-type doc-file-type doc-file-type01" v-if="countryCode == '_GL' || countryCode == '_US'">{{ content.attribute.doc_file_type }}</td>
                        <td class="tac file-type doc-file-type" v-else>{{ content.fileType }}</td>
                        <td class="doc-file-size" v-if="countryCode == '_GL' || countryCode == '_US'">{{ content.attribute.doc_file_size }}</td>
                        <!-- us & gl 의 doc-small-btn-box01 버튼갯수 차이로 사이즈가 달라 추가함 -->
                        <td class="doc-small-btn-box01" v-if="countryCode == '_GL' || countryCode == '_US'">
                          <ShareComponent v-bind:childValue="content" :type="'document'"></ShareComponent>
                          <button v-if="countryCode == '_GL' || countryCode == '_US'" type="button" class="btn-down doc-btn-down" @click="downloadFile(content.attribute.doc_file, content.content.id)">download</button>
                          <button v-else type="button" class="btn-down doc-btn-down" @click="downloadFile(content.clickDocFile, content.content.id)">download</button>
                        </td>
                        <!-- eu 의 doc-small-btn-box01 -->
                        <td class="doc-small-btn-box02" v-else>
                          <ShareComponent v-bind:childValue="content" :type="'document'"></ShareComponent>
                          <button v-if="countryCode == '_GL' || countryCode == '_US'" type="button" class="btn-down doc-btn-down" @click="downloadFile(content.attribute.doc_file, content.content.id)">download</button>
                          <button v-else type="button" class="btn-down doc-btn-down" @click="downloadFile(content.clickDocFile, content.content.id)">download</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="more-box type02-03">
                  <button type="button" class="btn btn-block-m btn-down-bk" @click="SelectFileDown()"><span>{{ $t("Document.Text.ZipDown") }}</span></button>
                </div>
                <div class="pagination">
                  <ul>
                    <li class="btn btn-first"><button type="button" @click="hasIndex(firstNumber)">first page</button></li>
                    <li class="btn btn-prev"><button type="button" @click="hasIndex((pageIndex-1))">prev</button></li>
                    <li class="btn-num" :id="'index_'+index" v-for="(index) in pageList" :data-chk="index" @click="hasIndex(index)"><button type="button"><span>{{ index }}</span></button></li>
                    <li class="btn btn-next"><button type="button" @click="hasIndex(pageIndex+1)">next</button></li>
                    <li class="btn btn-last"><button type="button" @click="hasIndex(lastNumber)">last page</button></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- //select result -->
          </div>
        </div>

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb" style="display: none">
          <div class="popup">
            <div class="scr-y">
              <div class="drop-list">
                <p class="tt-2">{{ $t('Document.Button.filter_by')}}</p>
                <button
                  class="filter-clos"
                  @click="closeSearchPopup()"
                ></button>
                <ul>
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('Document.Text.Document_Type')}}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('Document.Text.Document_Type')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk type03">
                        <li v-for="doc in documentType">
                          <label class="inp-chk">
                            <input type="checkbox" class="docBox" :id="'type_'+doc.id.value" @change="[typeClick(doc.id.value, doc.text)]">
                            <span>{{doc.text}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->

      </div>
      <!-- //container (documentation) -->
    </div>
    <form id="downform" name="downform" method="post" target="win_multidownload" action="${config.downloadServerUrl}"></form>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div><!-- //wrap -->
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import ShareComponent from '@/components/Layout/Share'
import categoryService from '@/server/category.service'
import ProsService from '@/server/pros.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
export default {
  name: 'Detail',
  props: ['childValue', 'type'],
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    ShareComponent,
    GnbComponent
  },
  data () {
    return {
      countryCode: '',
      checkedType: [],
      typeSelect: [],
      categoryName: '',
      categoryCode: '',
      categoryId: [],
      allCategoryId: '',
      categoryList: [
        { id: '' },
        { id: '' },
        { id: '' }
      ],
      contentList: [],
      subCategoryList: [],
      dictionaryValues: [],
      HFOLRList: [],
      documentType: [
        { id: { value: '' } }
      ],
      taps: false,
      tapId: '',
      totalCount: '',
      totalPage: '',
      pageIndex: 1,
      pageSize: 20,
      pageList: [],
      firstNumber: 1,
      lastNumber: 10,
      testId: [],
      checkedValues: [],
      allSelected: false,
      contentSortBy: this.$t('Document.Text.sortby'),
      orderType: [],
      orderField: [],
      seoCheck: false,
      seoName: '',
      isAccordionOpen: true,
      countryCodeSub: '',
      subCategory: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  async created () {
    /* select box */

    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  async mounted () {
    const _this = this
    const added = this.$countryCode // 국가코드
    _this.countryCode = added
    if (location.pathname.indexOf('/pros/documentation/DOC') === -1) { // seo url
      if (_this.$route.params.categoryCode === 'all-products') {
        _this.seoName = 'all'
      } else {
        if (_this.$route.params.categoryCode.includes('_')) {
          const categories = _this.$route.params.categoryCode.split('_')
          _this.seoName = categories[0]
          _this.subCategory = _this.$route.params.categoryCode
        } else {
          _this.seoName = _this.$route.params.categoryCode
        }
      }
      const seoRes = await categoryService.getDocSeo('all', 'DOC', 'DOC')
      _this.seoCheck = true
      if (seoRes.data.object !== null) {
        _this.categoryCode = seoRes.data.object.code
        _this.allCategoryId = seoRes.data.object.id
      }
    } else { // 기존 url
      _this.seoCheck = false
      _this.categoryCode = _this.$route.params.categoryCode + _this.countryCode
      const category = await categoryService.getCategoryCode(_this.categoryCode) /* 카테고리 id 탐색 */

      _this.allCategoryId = category.data.object.id /* all 카테고리 id */
    }
    _this.countryCode = added
    const categoryList = await categoryService.getCategoryList(_this.allCategoryId) /* 카테고리 리스트 탐색 */
    _this.categoryList = categoryList.data.page.list /* 카테고리 리스트 */

    const dictionaryCode = 'DOC_TYP' + added
    const documentType = await ProsService.getDictionary(dictionaryCode) /* 데이터사전 탐색 */
    _this.documentType = documentType.data.filter((element) => element.id.value != 'Fabrication_Guide')

    const docType = _this.$route.params.document_type /* DOCUMENT TYPE 코드 */
    const docType2 = _this.$route.params.document_type2

    _this.$nextTick(async function () {
      $('.btn-tab').removeClass('on')
      if (_this.seoCheck) { // seo url
        let doc = {}
        if (docType != undefined) {
          doc = _.filter(_this.documentType, function (c) {
            return _.lowerCase(c.id.value) == _.lowerCase(docType)
          })
        }
        let id = ''
        if (_this.seoName !== 'all') {
          const category = await categoryService.getDocSeo(_this.seoName, 'DOC', 'DOC')
          id = category.data.object.id
          _this.categoryId.push(id)
          _this.$nextTick(async function () {
            console.log('$(`#mainTap_${id}`)',$(`#mainTap_${id}`))

            $(`#mainTap_${id}`).addClass('on')
            if (_this.seoName === 'hflor') {
              _this.taps = true
              const supCateList = await categoryService.getCategoryList(id) /* 서브카테고리 검색 */
              _this.subCategoryList = supCateList.data.page.list
            }
            if (_this.seoName === 'vizuon') {
              _this.taps = true
              const supCateList = await categoryService.getCategoryList(id) /* 서브카테고리 검색 */
              _this.subCategoryList = supCateList.data.page.list
            }
            if (_this.seoName === 'film') {
              _this.taps = true
              const supCateList = await categoryService.getCategoryList(id) /* 서브카테고리 검색 */
              _this.subCategoryList = supCateList.data.page.list
            }
          })
        } else {
          _this.categoryId.push(_this.allCategoryId)
          $('#' + _this.categoryCode).addClass('on')
        }

        _this.$nextTick(async function () {
          if (docType != undefined) {
            const doc = _.filter(_this.documentType, function (c) {
              return _.lowerCase(c.id.value) == _.lowerCase(docType)
            })
            if (docType2 != undefined) {
              const doc2 = _.filter(_this.documentType, function (c) {
                return _.lowerCase(c.id.value) == _.lowerCase(docType2)
              })
              await _this.typeClick(doc[0].id.value, doc[0].text, doc2[0].id.value, doc2[0].text, 'start')
            } else {
              await _this.typeClick(doc[0].id.value, doc[0].text, '', '', 'start')
            }
          } else {
            if (_this.seoName == 'all') {
              _this.pagination('start')
            } else if (_this.seoName == 'hflor') {
              await _this.mainTaps(id, 'start', 1)
            } else if (_this.seoName == 'vizuon') {
              $("#subAllTap").css('display', 'inline-block');
              await _this.mainTaps(id, 'start', 5)
            } else if (_this.seoName === 'film') {
              await _this.mainTaps(id, 'start', 4)
              await $('#subAllTap').removeClass('on');
              for (const category of _this.subCategoryList) {
                const str = 'DOC_' + _this.subCategory + _this.nationCode
                if (category.code.toUpperCase() === str.toUpperCase()) {
                  $("#subAllTap").css('display', 'inline-block');
                  await _this.subTap(category.id)
                  await $('li#' + category.code).addClass('on')
                }
              }
            } else {
              await _this.mainTaps(id, 'start')
            }
          }
        })
      } else { // 기존 url
        if (docType == undefined) {
          if (_this.categoryCode != ('DOC_COUNTRY' + _this.countryCode)) {
            const codeCheck = _this.categoryCode.split('_')
            const category = await categoryService.getCategoryCode(_this.categoryCode) /* 카테고리 id 탐색 */
            const id = category.data.object.id
            _this.categoryId.push(id)
            $('.btn-tab').removeClass('on')
            $('#mainTap_' + id).addClass('on')
            if (codeCheck[1] == 'FLOOR') {
              _this.$nextTick(function () {
                $('#' + added).addClass('on')
              })
              await _this.mainTaps(id, 'start', 1)
            } else {
              await _this.mainTaps(id, 'start')
            }
          } else {
            _this.categoryId.push(_this.allCategoryId)
            _this.$nextTick(function () {
              $('#' + _this.categoryCode).addClass('on')
              _this.pagination('start')
            })
          }
        } else {
          const doc = _.filter(_this.documentType, function (c) {
            return _.lowerCase(c.id.value) == _.lowerCase(docType)
          })

          _this.categoryId.push(_this.allCategoryId)
          $('#' + _this.categoryCode).addClass('on')
          if (docType2 != undefined) {
            if (docType2 == 'HFLOR' || docType2 == 'HIMACS') {
              const docTypeId = _this.categoryList.filter((element) => element.name == docType2)
              _this.categoryId = []
              _this.categoryId.push(docTypeId[0].id)
              if (docType2 == 'HFLOR') {
                _this.taps = true
                const supCateList = await categoryService.getCategoryList(_this.categoryId) /* 서브카테고리 검색 */
                _this.subCategoryList = supCateList.data.page.list
              }
              $('[name=mainTap]').removeClass('on')
              $('#mainTap_' + docTypeId[0].id).addClass('on')
              await _this.typeClick(doc[0].id.value, doc[0].text, '', '', 'start')
            } else {
              const doc2 = _.filter(_this.documentType, function (c) {
                return _.lowerCase(c.id.value) == _.lowerCase(docType2)
              })
              await _this.typeClick(doc[0].id.value, doc[0].text, doc2[0].id.value, doc2[0].text, 'start')
            }
          } else {
            await _this.typeClick(doc[0].id.value, doc[0].text, '', '', 'start')
          }
        }
      }
    })
  },
  beforeDestroy () {
    // 컴포넌트가 제거될 때 observer 해제
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    toggleAccordion () {
      this.isAccordionOpen = !this.isAccordionOpen
    },
    gotolink (url) {
      location.href = url
    },
    async mainTaps (id, type, index) { // 탭 클릭 이벤트
      const _this = this
      _this.categoryId = []
      _this.categoryId.push(id)
      _this.tapId = id
      $('#sub_tap_ul > li').each(function() {
        $(this).removeClass("on");
      });
      $('#subAllTap').addClass('on')
      $('#subAllTap').css('display', 'inline-block');
      if (index == 1 || (index == 4 && _this.countryCode == '_GL') || (index == 5 && _this.countryCode == '_GL')) {
        _this.taps = true
        const supCateList = await categoryService.getCategoryList(id) /* 서브카테고리 검색 */
        _this.subCategoryList = supCateList.data.page.list /* 서브카테고리 리스트 */
        if(index === 1){

          _this.categoryId = []
          _this.categoryId.push(this.subCategoryList[0].id)
        }
      } else {
        _this.taps = false
      }

      this.$nextTick(function () {
        if(index === 1 ){
          $("#subAllTap").css('display', 'none');
          $('#DOC_FLOOR_LUX_GL').addClass('on')
        }
      })
      await _this.pagination(type)
    },
    async subTap (id) { // HFLOR 한정 서브탭 클릭 이벤트
      const _this = this

      $('input:checkbox[id=allCheck]').prop('checked', false)
      $("input:checkbox[name='conBox']").prop('checked', false)

      _this.categoryId = []
      _this.categoryId.push(id)
      await _this.pagination()
    },
    async typeClick (id, text, id2, text2, type) { // type 검색 컨텐츠 변경 이벤트
      const _this = this
      _this.pageIndex = 1
      $('input:checkbox[id=allCheck]').prop('checked', false)
      $("input:checkbox[name='conBox']").prop('checked', false)
      if (type == 'start') {
        $('#type_' + id).prop('checked', true)
      }
      if ($('#type_' + id).is(':checked')) {
        _this.dictionaryValues.push('doctype_' + id)
        _this.HFOLRList.push({ id: id, text: text })
        if (type == 'start' && id2 != '') {
          _this.dictionaryValues.push('doctype_' + id2)
          $('#type_' + id2).prop('checked', true)
          _this.HFOLRList.push({ id: id2, text: text2 })
        }
        await _this.pagination(type)
      } else {
        _this.dictionaryValues = _this.dictionaryValues.filter((element) => element !== 'doctype_' + id)
        _this.HFOLRList = _this.HFOLRList.filter((element) => element.id !== id)
        await _this.pagination(type)
      }
    },
    async DeleteType (idx, text) { // type 검색 조건 삭제 이벤트
      const _this = this
      $('#type_' + text).prop('checked', false)
      _this.dictionaryValues.splice(idx, 1)
      _this.HFOLRList.splice(idx, 1)
      await _this.pagination()
    },
    async allTypeDelete () { // type 검색 조건 전체 삭제 이벤트
      const _this = this
      $("[class*='docBox']").prop('checked', false)
      _this.dictionaryValues = []
      _this.HFOLRList = []
      await _this.pagination()
    },
    async hasIndex (index) { // 페이지 이동 이벤트
      const _this = this
      if (index > 1) {
        if (index <= _this.totalPage) {
          _this.pageIndex = index
        }
      } else {
        _this.pageIndex = 1
      }
      await _this.pagination()
    },
    async search () { // 검색 이벤트
      const _this = this
      _this.checkedValues = []
      _this.pageIndex = 1
      await _this.pagination()
    },
    docChange (label, file, type, index) {
      const _this = this

      _this.contentList[index].clickDocLabel = label
      _this.contentList[index].clickDocFile = file
      _this.contentList[index].clickType = type
      _this.$forceUpdate()
    },
    async pagination (type) { // 컨텐츠 페이징 이벤트
      const _this = this
      const word = $('#search').val() // 검색어
      // 컨텐츠 리스트 api
      const contentList = await ProsService.getDocumentationListByCateId(_this.categoryId, _this.dictionaryValues, word, _this.pageIndex, _this.pageSize, _this.orderField, _this.orderType)
      _this.contentList = contentList.data.list
      _this.totalPage = contentList.data.totalPage // 전체 페이지 수
      _this.totalCount = contentList.data.totalCount // 전체 컨텐츠 수
      const pageGroup = Math.ceil(_this.pageIndex / 10)
      let lastBottomNumber = pageGroup * 10 // 하단 마지막 숫자
      const first = lastBottomNumber - 9
      if (lastBottomNumber > _this.totalPage) {
        lastBottomNumber = _this.totalPage // 총 갯수보다 큰 경우 방지
      }
      _this.firstNumber = first
      _this.lastNumber = lastBottomNumber
      var list = []
      for (var i = _this.firstNumber; i <= _this.lastNumber; i++) {
        list.push(i)
      };
      _this.pageList = list
      if (_this.countryCode != '_US' && _this.countryCode != '_GL') {
        _this.contentList.forEach((content, idx) => {
          const docList = []
          if (_this.countryCode == '_UK') {
            if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
              docList.push({ label: this.$t('Document.Text.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk', fileType: content.attribute.doc_file_uk_type })
            }
          } else {
            if (!_.isEmpty(content.attribute.doc_file)) {
              docList.push({ label: this.$t('Document.Text.EN'), file: content.attribute.doc_file, type: 'doc_file', fileType: content.attribute.doc_file_type })
            }
          }
          if (!_.isEmpty(content.attribute.doc_file_es) && !_.isUndefined(content.attribute.doc_file_es)) {
            docList.push({ label: this.$t('Document.Text.ES'), file: content.attribute.doc_file_es, type: 'doc_file_es', fileType: content.attribute.doc_file_es_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_de) && !_.isUndefined(content.attribute.doc_file_de)) {
            docList.push({ label: this.$t('Document.Text.DE'), file: content.attribute.doc_file_de, type: 'doc_file_de', fileType: content.attribute.doc_file_de_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_fr) && !_.isUndefined(content.attribute.doc_file_fr)) {
            docList.push({ label: this.$t('Document.Text.FR'), file: content.attribute.doc_file_fr, type: 'doc_file_fr', fileType: content.attribute.doc_file_fr_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_it) && !_.isUndefined(content.attribute.doc_file_it)) {
            docList.push({ label: this.$t('Document.Text.IT'), file: content.attribute.doc_file_it, type: 'doc_file_it', fileType: content.attribute.doc_file_it_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_nl) && !_.isUndefined(content.attribute.doc_file_nl)) {
            docList.push({ label: this.$t('Document.Text.NL'), file: content.attribute.doc_file_nl, type: 'doc_file_nl', fileType: content.attribute.doc_file_nl_type })
          }
          if (_this.countryCode == '_UK') {
            if (!_.isEmpty(content.attribute.doc_file)) {
              docList.push({ label: this.$t('Document.Text.EN'), file: content.attribute.doc_file, type: 'doc_file', fileType: content.attribute.doc_file_type })
            }
          } else {
            if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
              docList.push({ label: this.$t('Document.Text.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk', fileType: content.attribute.doc_file_uk_type })
            }
          }
          content.docList = docList
          content.clickDocLabel = docList[0].label
          content.clickType = docList[0].type
          content.clickDocFile = docList[0].file
          content.fileType = docList[0].fileType
          content.docType = _this.documentType.filter((element) => element.id.value == content.attribute.doctype)
        })
      }
      $('.sns-wrp .sns').closest('tr').siblings().children().find('.sns').hide()

      _this.$nextTick(function () {
        $('.btn-num').removeClass('on')
        $('#index_' + _this.pageIndex).addClass('on')
        if (type == 'start') {
          $('#allCheck').prop('checked', false)
          $("input:checkbox[name='conBox']").prop('checked', false)
        }
        $('.sns-wrp .sns').each(function (idx, ele) {
          var $this = $(this)
          $(ele).prev().off('click')
          $this.off()
        })
        $('.sns-wrp .sns').each(function (idx, ele) {
          var $this = $(this)
          $(ele).prev().click(function () {
            $this.slideToggle(300)
            $this.closest('tr').siblings().children().find('.sns').hide()
          })
        })
      })
      activeArrowTab() // gray tab arrow active
    },
    async sortByChange (text, type, field) { // 정렬 방식 변경 이벤트
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      const _this = this
      _this.contentSortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.pagination()
    },
    iOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    },
    async downloadFile (file, id) { // 파일 다운로드 이벤트
      await ProsService.getClickUp(id)
      let url = ''
      url = this.$imagePath + file
      const path = require('path')
      const fileName = path.basename(file)
      if (this.iOS) {
        const _a = document.createElement('a')
        _a.target = '_blank'
        _a.href = url
        document.body.appendChild(_a)
        _a.click()
      } else {
        function toDataURL (url, callback) {
          var xhr = new XMLHttpRequest()
          xhr.onload = function () {
            var reader = new FileReader()
            reader.onloadend = function () {
              callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
          }
          xhr.open('GET', url)
          xhr.responseType = 'blob'
          xhr.response.setHeader()
          xhr.send()
        }
        toDataURL(url, function (dataUrl) {
          const element = document.createElement('a')
          element.setAttribute('href', dataUrl)
          element.setAttribute('download', fileName)
          document.body.appendChild(element)
          element.click()
        })
      }
    },
    selectedAll () {
      if (this.allSelected) {
        $('input:checkbox[name=conBox]').prop('checked', false)
      } else {
        $('input:checkbox[name=conBox]').prop('checked', true)
        for (var i = 0; i < this.contentList.length; i++) {
          this.checkedValues.push(this.contentList[i].content.id)
        }
      }
    },
    checkboxSelected (id, index) {
      const _this = this
      if ($('#content_' + index).is(':checked')) {
        _this.checkedValues.push(id)
      } else {
        _this.checkedValues = _this.checkedValues.filter((element) => element !== id)
      }
      const checkBoxTotal = $('input:checkbox[name=conBox]').length
      const checkedCount = $('input:checkbox[name=conBox]:checked').length
      if (checkBoxTotal == checkedCount) {
        $('#allCheck').prop('checked', true)
        _this.allSelected = true
      } else {
        $('#allCheck').prop('checked', false)
        _this.allSelected = false
      }
    },
    async SelectFileDown () {
      const _this = this
      _this.checkedType = []
      if (this.checkedValues.length == 0) {
        alert("There's no file to download.")
      } else {
        _this.contentList.forEach((content, idx) => {
          if ($('#content_' + (idx + 1)).is(':checked')) {
            _this.checkedType.push(content.clickType + '-' + (idx + 1))
          } else {
            _this.checkedType = _this.checkedType.filter((element) => element !== content.clickType + '-' + (idx + 1))
          }
        })
        ProsService.zipFileDownload(_this.checkedValues, _this.checkedType, _this.countryCode)
      }
    },
    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        navigator.userAgent
      )
    },

    clickFilter () {
      const element = document.getElementById('filterSearch')

      if (this.filterShow == false) {
        element.style.cssText = 'z-index: 99999999'
      } else {
        element.style.cssText = 'display : none'
      }

      return this.filterShow = !this.filterShow
    },

    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow', 'auto')
    }
  }
}

// .tab-ui .btns li:hover button, .tab-ui .btns li:active button
// .prod-fd-view .tab-ui .btns li:hover button span
</script>
<style scoped>
.btn-all-delete {
  /* position: absolute;
  top: 10px;
  right: 0;
  padding: 11px 45px 11px 20px;
  border: 1px solid #dedede;
  font-size: 14px;
  color: #b51313;
  vertical-align: top;
  border-radius: 25px; */
}
</style>
