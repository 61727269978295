import request from './request'
const urlPrefix = ''

const memberInfoService = {
  getEuMember () {
    return request({
      url: `${urlPrefix}/memberEU/getMember/`,
      method: 'get'
    })
  },
  getEuMemberType () {
    return request({
      url: `${urlPrefix}/memberEU/getMemberType/`,
      method: 'get'
    })
  },
  getMember () {
    return request({
      url: `${urlPrefix}/member/getMember/`,
      method: 'get'
    })
  },
  getByMemNo (memNo) {
    return request({
      url: `${urlPrefix}/member/getByMemNo?memNo=${memNo}`,
      method: 'get'
    })
  },
  getByMemId (memId) {
    return request({
      url: `${urlPrefix}/member/getByMemId?memId=${memId}`,
      method: 'get'
    })
  },
  getByMemIdEU (memId) {
    return request({
      url: `${urlPrefix}/memberEU/getByMemId?memId=${memId}`,
      method: 'get'
    })
  },
  isEnabled (memId) {
    return request({
      url: `${urlPrefix}/member/isEnabled?memId=${memId}`,
      method: 'get'
    })
  },
  isEnabledEU (email) {
    return request({
      url: `${urlPrefix}/memberEU/isEnabledEU?email=${email}`,
      method: 'get'
    })
  },
  getByIdAndEmail (memId, memEmail) {
    return request({
      url: `${urlPrefix}/member/getByIdAndEmail?memId=${memId}&memEmail=${memEmail}`,
      method: 'get'
    })
  },
  getByEmail (memEmail) {
    return request({
      url: `${urlPrefix}/member/getByEmail?memEmail=${memEmail}`,
      method: 'get'
    })
  },
  getByEmailEU (memEmail) {
    return request({
      url: `${urlPrefix}/memberEU/getByEmail?memEmail=${memEmail}`,
      method: 'get'
    })
  },
  existEU (memEmail) {
    return request({
      url: `${urlPrefix}/memberEU/exist?email=${memEmail}`,
      method: 'get'
    })
  },
  updateMember (MemberInfo) {
    return request({
      url: `${urlPrefix}/member/updateMember`,
      method: 'post',
      data: MemberInfo,
      params: {
        _csrf: localStorage.getItem('auth_token')
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  checkPassword (password) {
    return request({
      url: `${urlPrefix}/member/checkPassword`,
      method: 'post',
      data: { passwd: password },
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  searchByDate (id, startDate, endDate) {
    return request({
      url: `${urlPrefix}/myPage/search?id=${id}&startDate=${startDate}&endDate=${endDate}`,
      method: 'get'
    })
  },
  getOrder (orderId) {
    return request({
      url: `${urlPrefix}/myPage/getOrder?orderId=${orderId}`,
      method: 'get'
    })
  },
  getListOrderItem (orderId) {
    return request({
      url: `${urlPrefix}/myPage/getListOrderItem?orderId=${orderId}`,
      method: 'get'
    })
  },
  getListContent (orderId) {
    return request({
      url: `${urlPrefix}/myPage/getListContent?orderId=${orderId}`,
      method: 'get'
    })
  },
  getSeqOneContent (postData) {
    return request({
      url: `${urlPrefix}/myPage/getSeqOneContent`,
      method: 'post',
      data: postData
    })
  },
  getContents (postData) {
    return request({
      url: `${urlPrefix}/myPage/getContents`,
      method: 'post',
      data: postData
    })
  },
  getSeqOneOrderItems (postData) {
    return request({
      url: `${urlPrefix}/myPage/getSeqOneOrderItems`,
      method: 'post',
      data: postData
    })
  },
  getAllOrderItems (postData) {
    return request({
      url: `${urlPrefix}/myPage/getAllOrderItems`,
      method: 'post',
      data: postData
    })
  },
  forgotPassword (email) {
    return request({
      url: `${urlPrefix}/myPage/forgotPassword?email=${email}`,
      method: 'post'
    })
  },
  getTotalCredit (id) {
    return request({
      url: `${urlPrefix}/credit/getTotal?id=${id}`,
      method: 'get'
    })
  }
}

export default memberInfoService
