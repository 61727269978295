var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainability_management/top_image.png",
          title: "Sustainability Management",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "OverviewSection",
                    [
                      _c("OverviewText", {
                        attrs: { type: "ft-20", text: _vm.text },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "box-container" }, [
                    _c(
                      "div",
                      { ref: "box1", staticClass: "box-div" },
                      _vm._l(_vm.tabBox1, function (box, index) {
                        return _c("SustainabilityManagementTabBox", {
                          key: index,
                          attrs: {
                            content: box.content,
                            isActive: box.isActive,
                          },
                          on: {
                            move: function ($event) {
                              return _vm.moveToBox("box2")
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                  _c("GhgEmissionReduction"),
                  _c("EnergyComsumptionMinimization"),
                  _c("EnvironmentalInvestment"),
                  _c("div", { staticClass: "box-container" }, [
                    _c(
                      "div",
                      { ref: "box2", staticClass: "box-div" },
                      _vm._l(_vm.tabBox2, function (box, index) {
                        return _c("SustainabilityManagementTabBox", {
                          key: index,
                          attrs: {
                            content: box.content,
                            isActive: box.isActive,
                          },
                          on: {
                            move: function ($event) {
                              return _vm.moveToBox("box1")
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                  _c("PollutantManagement"),
                  _c("ExpandedWasteRecycling"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3359055728
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainability_management/top_image.png",
          title: "サステナビリティ経営",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [
                    _c("OverviewText", {
                      attrs: { type: "ft-20", text: _vm.textJp },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "box-container" }, [
                  _c(
                    "div",
                    { ref: "box1", staticClass: "box-div" },
                    _vm._l(_vm.tabBox1Jp, function (box, index) {
                      return _c("SustainabilityManagementTabBox", {
                        key: index,
                        attrs: { content: box.content, isActive: box.isActive },
                        on: {
                          move: function ($event) {
                            return _vm.moveToBox("box2")
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("GhgEmissionReduction"),
                _c("EnergyComsumptionMinimization"),
                _c("EnvironmentalInvestment"),
                _c("div", { staticClass: "box-container" }, [
                  _c(
                    "div",
                    { ref: "box2", staticClass: "box-div" },
                    _vm._l(_vm.tabBox2Jp, function (box, index) {
                      return _c("SustainabilityManagementTabBox", {
                        key: index,
                        attrs: { content: box.content, isActive: box.isActive },
                        on: {
                          move: function ($event) {
                            return _vm.moveToBox("box1")
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("PollutantManagement"),
                _c("ExpandedWasteRecycling"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainability_management/top_image.png",
          title: "Управление устойчивым развитием",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [
                    _c("OverviewText", {
                      attrs: { type: "ft-20", text: _vm.textRu },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "box-container" }, [
                  _c(
                    "div",
                    { ref: "box1", staticClass: "box-div" },
                    _vm._l(_vm.tabBox1Ru, function (box, index) {
                      return _c("SustainabilityManagementTabBox", {
                        key: index,
                        attrs: { content: box.content, isActive: box.isActive },
                        on: {
                          move: function ($event) {
                            return _vm.moveToBox("box2")
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("GhgEmissionReduction"),
                _c("EnergyComsumptionMinimization"),
                _c("EnvironmentalInvestment"),
                _c("div", { staticClass: "box-container" }, [
                  _c(
                    "div",
                    { ref: "box2", staticClass: "box-div" },
                    _vm._l(_vm.tabBox2Ru, function (box, index) {
                      return _c("SustainabilityManagementTabBox", {
                        key: index,
                        attrs: { content: box.content, isActive: box.isActive },
                        on: {
                          move: function ($event) {
                            return _vm.moveToBox("box1")
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("PollutantManagement"),
                _c("ExpandedWasteRecycling"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/sustainability_management/top_image.png",
          title: "可持续经营",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [
                    _c("OverviewText", {
                      attrs: { type: "ft-20", text: _vm.textCn },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "box-container" }, [
                  _c(
                    "div",
                    { ref: "box1", staticClass: "box-div" },
                    _vm._l(_vm.tabBox1Cn, function (box, index) {
                      return _c("SustainabilityManagementTabBox", {
                        key: index,
                        attrs: { content: box.content, isActive: box.isActive },
                        on: {
                          move: function ($event) {
                            return _vm.moveToBox("box2")
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("GhgEmissionReduction"),
                _c("EnergyComsumptionMinimization"),
                _c("EnvironmentalInvestment"),
                _c("div", { staticClass: "box-container" }, [
                  _c(
                    "div",
                    { ref: "box2", staticClass: "box-div" },
                    _vm._l(_vm.tabBox2Cn, function (box, index) {
                      return _c("SustainabilityManagementTabBox", {
                        key: index,
                        attrs: { content: box.content, isActive: box.isActive },
                        on: {
                          move: function ($event) {
                            return _vm.moveToBox("box1")
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]),
                _c("PollutantManagement"),
                _c("ExpandedWasteRecycling"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }