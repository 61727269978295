var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Energy Comsumption Minimization" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("pre", { staticClass: "small-title" }, [_vm._v("In Our Plant")]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "energy-comsumption-minimization-radius-area" },
              [
                _c("img", {
                  staticClass: "plant-image",
                  attrs: { src: _vm.plant.image },
                }),
              ]
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.plant.text } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "small-title" }, [
              _vm._v("By Our Product"),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "by-our-product" }, [
              _c(
                "div",
                { staticClass: "energy-comsumption-minimization-radius-area" },
                [
                  _c("img", {
                    staticClass: "product-image",
                    attrs: { src: _vm.product.image },
                  }),
                ]
              ),
              _c("div", { staticClass: "by-our-product-text" }, [
                _c("pre", { staticClass: "by-our-product-text-title" }, [
                  _vm._v(_vm._s(_vm.product.title)),
                ]),
                _c("pre", { staticClass: "by-our-product-text-content" }, [
                  _vm._v(_vm._s(_vm.product.content)),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "エネルギー消費の最小化" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("pre", { staticClass: "small-title" }, [
              _vm._v("私たちの工場では"),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "energy-comsumption-minimization-radius-area" },
              [
                _c("img", {
                  staticClass: "plant-image",
                  attrs: { src: _vm.plant.image },
                }),
              ]
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.plantJp.text } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "small-title" }, [_vm._v("当社の製品")]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "by-our-product" }, [
              _c(
                "div",
                { staticClass: "energy-comsumption-minimization-radius-area" },
                [
                  _c("img", {
                    staticClass: "product-image",
                    attrs: { src: _vm.product.image },
                  }),
                ]
              ),
              _c("div", { staticClass: "by-our-product-text" }, [
                _c("pre", { staticClass: "by-our-product-text-title" }, [
                  _vm._v(_vm._s(_vm.productJp.title)),
                ]),
                _c("pre", { staticClass: "by-our-product-text-content" }, [
                  _vm._v(_vm._s(_vm.productJp.content)),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Минимизация энергопотребления" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("pre", { staticClass: "small-title" }, [
              _vm._v("На нашем заводе"),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "energy-comsumption-minimization-radius-area" },
              [
                _c("img", {
                  staticClass: "plant-image",
                  attrs: { src: _vm.plant.image },
                }),
              ]
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.plantRu.text } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "small-title" }, [_vm._v("Наш продукт")]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "by-our-product" }, [
              _c(
                "div",
                { staticClass: "energy-comsumption-minimization-radius-area" },
                [
                  _c("img", {
                    staticClass: "product-image",
                    attrs: { src: _vm.product.image },
                  }),
                ]
              ),
              _c("div", { staticClass: "by-our-product-text" }, [
                _c("pre", { staticClass: "by-our-product-text-title" }, [
                  _vm._v(_vm._s(_vm.productRu.title)),
                ]),
                _c("pre", { staticClass: "by-our-product-text-content" }, [
                  _vm._v(_vm._s(_vm.productRu.content)),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "能源消耗最小化" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("pre", { staticClass: "small-title" }, [
              _vm._v("在LX Hausys的工厂"),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "energy-comsumption-minimization-radius-area" },
              [
                _c("img", {
                  staticClass: "plant-image",
                  attrs: { src: _vm.plant.image },
                }),
              ]
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.plantCn.text } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "small-title" }, [
              _vm._v("通过LX Hausys的产品"),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "by-our-product" }, [
              _c(
                "div",
                { staticClass: "energy-comsumption-minimization-radius-area" },
                [
                  _c("img", {
                    staticClass: "product-image",
                    attrs: { src: _vm.product.image },
                  }),
                ]
              ),
              _c("div", { staticClass: "by-our-product-text" }, [
                _c("pre", { staticClass: "by-our-product-text-title" }, [
                  _vm._v(_vm._s(_vm.productCn.title)),
                ]),
                _c("pre", { staticClass: "by-our-product-text-content" }, [
                  _vm._v(_vm._s(_vm.productCn.content)),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }