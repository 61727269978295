var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg",
          title: "DESIGNER COLLABORATIONS",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("OverviewSection", [
                    _c("div", [
                      _c("pre", { staticClass: "intro-text" }, [
                        _vm._v(
                          "LX Hausys launched its research center in 1989 as a processing technology research center.\nThe institute presents differentiated designs to our customers through\ncollaborations with renowned designers both domestically and internationally.\n          "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "au-view" }, [
                    _c("div", { staticClass: "cwp-box" }, [
                      _c("ul", [
                        _c("li", [
                          _c("div", { staticClass: "w-con" }, [
                            _c("h2", { staticClass: "tt-2" }, [
                              _vm._v("Interior film"),
                            ]),
                          ]),
                          _c("div", { staticClass: "img-wrap" }, [
                            _c("div", { staticClass: "w-con col-02" }, [
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png",
                                      alt: "Karim Rashid",
                                    },
                                  }),
                                ]),
                                _c("strong", [_vm._v("Karim Rashid")]),
                              ]),
                              _c("div", { staticClass: "view" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png",
                                    alt: "Interior film",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "w-con" }, [
                            _c("h2", { staticClass: "tt-2" }, [
                              _vm._v("Z:in Window handle"),
                            ]),
                          ]),
                          _c("div", { staticClass: "img-wrap" }, [
                            _c("div", { staticClass: "w-con col-02" }, [
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png",
                                      alt: "Stefano Giovannoni",
                                    },
                                  }),
                                ]),
                                _c("strong", [_vm._v("Stefano Giovannoni")]),
                              ]),
                              _c("div", { staticClass: "view" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png",
                                    alt: "Z:in Window handle",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "w-con" }, [
                            _c("h2", { staticClass: "tt-2" }, [
                              _vm._v("Flooring, Car fabric"),
                            ]),
                          ]),
                          _c("div", { staticClass: "img-wrap" }, [
                            _c("div", { staticClass: "w-con col-02" }, [
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png",
                                      alt: "Ben Van Berkel",
                                    },
                                  }),
                                ]),
                                _c("strong", [_vm._v("Ben Van Berkel")]),
                              ]),
                              _c("div", { staticClass: "view" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png",
                                    alt: "Flooring, Car fabric",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "w-con" }, [
                            _c("h2", { staticClass: "tt-2" }, [
                              _vm._v("Wallpaper, Car fabric pattern, HIMACS"),
                            ]),
                          ]),
                          _c("div", { staticClass: "img-wrap" }, [
                            _c("div", { staticClass: "w-con col-02" }, [
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png",
                                      alt: "Marcel Wanders",
                                    },
                                  }),
                                ]),
                                _c("strong", [_vm._v("Marcel Wanders")]),
                              ]),
                              _c("div", { staticClass: "view" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png",
                                    alt: "Wallpaper, Car fabric pattern, HIMACS",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "w-con" }, [
                            _c("h2", { staticClass: "tt-2" }, [
                              _vm._v("Flooring, Wallpaper"),
                            ]),
                          ]),
                          _c("div", { staticClass: "img-wrap" }, [
                            _c("div", { staticClass: "w-con col-02" }, [
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png",
                                      alt: "Alexandro Mendini",
                                    },
                                  }),
                                ]),
                                _c("strong", [_vm._v("Alexandro Mendini")]),
                              ]),
                              _c("div", { staticClass: "view" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png",
                                    alt: "Flooring, Wallpaper",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "w-con" }, [
                            _c("h2", { staticClass: "tt-2" }, [
                              _vm._v("Wallpaper"),
                            ]),
                          ]),
                          _c("div", { staticClass: "img-wrap" }, [
                            _c("div", { staticClass: "w-con col-02" }, [
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png",
                                      alt: "Vera Wang",
                                    },
                                  }),
                                ]),
                                _c("strong", [_vm._v("Vera Wang")]),
                              ]),
                              _c("div", { staticClass: "view" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png",
                                    alt: "Wallpaper",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2325737746
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg",
          title: "デザイナーとのコラボレーション",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("OverviewSection", [
                  _c("div", [
                    _c("pre", { staticClass: "intro-text" }, [
                      _vm._v(
                        "          LX Hausys は、1989 年に加工技術研究センターとして研究所を設立しました。\n          この研究所は、国内外の著名なデザイナーとのコラボレーションを通じて、\n          差別化されたデザインをお客様に提供しています。\n          "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "au-view" }, [
                  _c("div", { staticClass: "cwp-box" }, [
                    _c("ul", [
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("インテリアフィルム"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png",
                                    alt: "Karim Rashid",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Karim Rashid")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png",
                                  alt: "Interior film",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("Z:in ウィンドウ ハンドル"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png",
                                    alt: "Stefano Giovannoni",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Stefano Giovannoni")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png",
                                  alt: "Z:in Window handle",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("フローリング、カーファブリック"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png",
                                    alt: "Ben Van Berkel",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Ben Van Berkel")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png",
                                  alt: "Flooring, Car fabric",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("壁紙、カーファブリックパターン、HIMACS"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png",
                                    alt: "Marcel Wanders",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Marcel Wanders")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png",
                                  alt: "Wallpaper, Car fabric pattern, HIMACS",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("フローリング、壁紙"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png",
                                    alt: "Alexandro Mendini",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Alexandro Mendini")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png",
                                  alt: "Flooring, Wallpaper",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [_vm._v("壁紙")]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png",
                                    alt: "Vera Wang",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Vera Wang")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png",
                                  alt: "Wallpaper",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg",
          title: "СОТРУДНИЧЕСТВО С ДИЗАЙНЕРАМИ",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("OverviewSection", [
                  _c("div", [
                    _c("pre", { staticClass: "intro-text" }, [
                      _vm._v(
                        "            LX Hausys открыл свой исследовательский центр в 1989 году как центр изучения технологий обработки.\n            Институт представляет нашим клиентам дифференцированный дизайн благодаря\n            сотрудничеству с известными дизайнерами как внутри страны, так и на международном уровне.\n          "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "au-view" }, [
                  _c("div", { staticClass: "cwp-box" }, [
                    _c("ul", [
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("Интерьерная пленка"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png",
                                    alt: "Karim Rashid",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Karim Rashid")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png",
                                  alt: "Interior film",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("Z:IN Ручка оконная "),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png",
                                    alt: "Stefano Giovannoni",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Stefano Giovannoni")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png",
                                  alt: "Z:in Window handle",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("Напольное покрытие, ткань для автомобиля"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png",
                                    alt: "Ben Van Berkel",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Ben Van Berkel")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png",
                                  alt: "Flooring, Car fabric",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v(
                              "Обои, рисунок на ткани для автомобиля, HIMACS"
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png",
                                    alt: "Marcel Wanders",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Marcel Wanders")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png",
                                  alt: "Wallpaper, Car fabric pattern, HIMACS",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("Напольное покрытие, обои"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png",
                                    alt: "Alexandro Mendini",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Alexandro Mendini")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png",
                                  alt: "Flooring, Wallpaper",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [_vm._v("Обои")]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png",
                                    alt: "Vera Wang",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Vera Wang")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png",
                                  alt: "Wallpaper",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg",
          title: "合作设计师",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("OverviewSection", [
                  _c("div", [
                    _c("pre", { staticClass: "intro-text" }, [
                      _vm._v(
                        "            LX Hausys于1989年成立了研究中心。\n            LX Hausys研究中心通过与国内外知名设计师的合作，向客户展示差异化的设计。\n          "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "au-view" }, [
                  _c("div", { staticClass: "cwp-box" }, [
                    _c("ul", [
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("装饰贴膜"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png",
                                    alt: "Karim Rashid",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Karim Rashid")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png",
                                  alt: "Interior film",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("Z:IN 门把手"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png",
                                    alt: "Stefano Giovannoni",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Stefano Giovannoni")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png",
                                  alt: "Z:in Window handle",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("地板、汽车表皮"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png",
                                    alt: "Ben Van Berkel",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Ben Van Berkel")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png",
                                  alt: "Flooring, Car fabric",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("墙纸、汽车表皮纹路、HIMACS"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png",
                                    alt: "Marcel Wanders",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Marcel Wanders")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png",
                                  alt: "Wallpaper, Car fabric pattern, HIMACS",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [
                            _vm._v("地板、墙纸"),
                          ]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png",
                                    alt: "Alexandro Mendini",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Alexandro Mendini")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png",
                                  alt: "Flooring, Wallpaper",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("div", { staticClass: "w-con" }, [
                          _c("h2", { staticClass: "tt-2" }, [_vm._v("墙纸")]),
                        ]),
                        _c("div", { staticClass: "img-wrap" }, [
                          _c("div", { staticClass: "w-con col-02" }, [
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png",
                                    alt: "Vera Wang",
                                  },
                                }),
                              ]),
                              _c("strong", [_vm._v("Vera Wang")]),
                            ]),
                            _c("div", { staticClass: "view" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png",
                                  alt: "Wallpaper",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }