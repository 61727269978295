/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from 'vue'
import * as types from './mutation-types'
import UserService from '@/server/user.service'

import _ from '@/common/helper/lodash.js'
import memberInfoService from '@/server/myPage.service'
import { $utils } from '@/common/helper'

const count = 0

window.dataLayer = window.dataLayer || [];
export const check = ({ commit }) => {
  commit(types.CHECK)
}

export const login = async ({ commit, dispatch, getters }, { username, password, rememberMe, vm } = {}) => {
  // 국가코드 전역 변수
  // let added = '_US'
  // if (location.pathname.indexOf('us') !== -1) {
  //   added = '_US'
  // }
  // if (location.pathname.indexOf('gl') !== -1) {
  //   added = '_GL'
  // }
  // get count and get enabled

  if (username !== '' && password === '') {
    alert('Please enter a password.')
    return false
  } else if ((username === '' && password !== '') || !(await UserService.exist(username)).data) {
    alert('Please enter a valid ID.')
    return false
  } else {
    const res = await memberInfoService.isEnabled(username)
    const countRes = await UserService.getCount({ username })
    const isEnabled = res.data
    let count = countRes.data
    if (count < 5) {
      if (isEnabled === false && count === 0) {
        alert(vm.$t('login.alert.verify_acc'))
        return false
      } else if (isEnabled === true) {
        const { data, status } = await UserService.login({ username, password, rememberMe })
        if (data.success) {
          count = 0
          const { data, status } = await UserService.login({ username, password, rememberMe })
          await UserService.setCount({ username, count })
          await commit(types.LOGIN, data.result)
          vm.loginSuccess();
        } else {
          count++
          await UserService.setCount({ username, count })
          if (window.confirm('You entered incorrect ID or Password. Please try again(' + count + '/5)')) {
            if (count === 5) {
              await UserService.disable({ username })
            }
            return true
          }
          return false
        }
      }
    } else {
      if (window.confirm('Your account has been locked. Please reset password to unlock your account and login again.')) {
        window.scrollTo({
          top: 700,
          left: 0,
          behavior: 'smooth'
        })
      }
      return false
    }
  }

  // if (data.success && count < 5) {
  //   if (isEnabled === false && count === 0) {
  //     alert(vm.$t('login.alert.verify_acc'))
  //     return false
  //   } else {
  //     count = 0
  //     const { data, status } = await UserService.login({ username, password, rememberMe })
  //     await UserService.setCount({username, count})
  //     await commit(types.LOGIN, data.result)
  //     vm.loginSuccess();
  //   }
  // } else {
  //     if (username !== '' && password === '') {
  //       alert('Please enter a password.')
  //     } else if (username === '' && password !== '') {
  //       alert('Please enter a valid ID.')
  //     }
  //
  //     if (isEnabled === false && count === 0) {
  //       alert(vm.$t('login.alert.verify_acc'))
  //       return false
  //     } else if(isEnabled === true && count >= 0 && count < 5){
  //       count++
  //       await UserService.setCount({username, count})
  //       alert('Your ID or password was entered incorrectly. Please try again. (' + count + '/5)')
  //       return false
  //     } else if(count >= 5){
  //       if(count < 6){
  //         count++
  //       }
  //       await UserService.setCount({username, count})
  //       await UserService.disable({username})
  //       if (window.confirm("You have been locked out of your account. Do you want to reset password to restore access to your account?")) {
  //         window.scrollTo({
  //           top: 700,
  //           left: 0,
  //           behavior: 'smooth'
  //         });
  //       }
  //       return false
  //     }
  //   }
}

export const login_eu = async ({ commit, dispatch, getters }, { email, password, rememberMe, vm } = {}) => {
  // 국가코드 전역 변수
  // let added = '_US'
  // if (location.pathname.indexOf('us') !== -1) {
  //   added = '_US'
  // }
  // if (location.pathname.indexOf('gl') !== -1) {
  //   added = '_GL'
  // }
  // get count and get enabled

  if (email !== '' && password === '') {
    alert('Please enter a password.')
    return false
  } else if ((email === '' && password !== '') || !(await memberInfoService.existEU(email)).data) {
    alert('Please enter a valid email.')
    return false
  } else {
    const res = await memberInfoService.isEnabledEU(email)
    const countRes = await UserService.getCountEU({ email })
    const isEnabled = res.data
    let count = countRes.data
    if (count < 3) {
      if (isEnabled == 0) {
        alert('Your account has not been verified yet!')
        return false
      } else if (isEnabled == 1) {
        const { data, status } = await UserService.login_eu({ email, password, rememberMe })
        if (data.success) {
          if (data.result === 'expired') {
            const token = await UserService.sendEmailPassExpired(email)
            location.href = 'himacsPartnersPortal/passwordExpired/' + token.data
            return false
          }
          count = 0
          await UserService.setCountEU({ email, count })
          await commit(types.LOGIN, data.result)
          vm.loginSuccess()
        } else {
          count++
          await UserService.setCountEU({ email, count })
          if (count === 3) {
            await UserService.disableEU({ email })
          }
          if(window.confirm('You entered incorrect Email or Password. Please try again(' + count + '/3)')){
            return true
          } else {
            return false
          }
        }
      }
    } else if (isEnabled == 2) {
      if (window.confirm('Your account has been blocked due to failed login attempts.')) {
        window.scrollTo({
          top: 850,
          left: 0,
          behavior: 'smooth'
        })
      }
      return false
    }
  }
}

export const logout = async ({ commit }, payload) => {
  const postData = {}
  postData.userId = payload.id
  postData.country = $utils.getCountryCode()
  await UserService.logout(postData)
  commit(types.LOGOUT, payload)
  dataLayer.push({
    event : 'logoutAction'
  })
}

export default {
  check,
  login,
  login_eu,
  logout
}
