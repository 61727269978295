<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">Sitemap</h1>

    <!-- content -->
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">{{$t('sitemap.text.FF0040_001')}}</li>
          <!-- <li>{{$t('sitemap.text.FF0040_002')}}</li> -->
          <li>{{$t('sitemap.text.FF0040_003')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Sitemap) -->
      <div class="container stm-box sitemap">
        <div class="w-con-02 ba-text inner">
          <h1 class="main_tit-sitemapGnb">SITEMAP</h1>

          <!--SEGMENTS(US/GL)-->
          <!-- <div class="sitemap-2dp-box" v-if="$countryCode === '_US'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_004')}}</p>
            <div class="sitemap-gnb-2dp">
              <button
                type="button"
                @click="gotoUrl('segments/world')"
                class="txtUnderline"
              >
                <span>{{$t('sitemap.text.FF0040_005')}}</span>
              </button>
            </div>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_006')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/main/retail')"
                >
                  {{$t('sitemap.text.FF0040_007')}}
                </li>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_008')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/restaurant/dining-area')
                    "
                  >
                    {{$t('sitemap.text.FF0040_009')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/restaurant/order-check-out-area')
                    "
                  >
                    {{$t('sitemap.text.FF0040_010')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/restaurant/public-restroom')
                    "
                  >
                    {{$t('sitemap.text.FF0040_011')}}
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_012')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/fashion/display-area')
                    "
                  >
                    {{$t('sitemap.text.FF0040_013')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/fashion/fitting-room')
                    "
                  >
                    {{$t('sitemap.text.FF0040_014')}}
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_015')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/fitness/changing-room')
                    "
                  >
                    {{$t('sitemap.text.FF0040_016')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/fitness/reception')
                    "
                  >
                    {{$t('sitemap.text.FF0040_017')}}
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_018')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/beauty/reception')
                    "
                  >
                    {{$t('sitemap.text.FF0040_016')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/detail/retail/beauty/treatment-area')
                    "
                  >
                    {{$t('sitemap.text.FF0040_019')}}
                  </li>
                </div>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_020')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/healthcare/reception')"
                >
                  {{$t('sitemap.text.FF0040_016')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/lobby')
                  "
                >
                  {{$t('sitemap.text.FF0040_021')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/corridor')
                  "
                >
                  {{$t('sitemap.text.FF0040_022')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/patient-room-cure')
                  "
                >
                  {{$t('sitemap.text.FF0040_023')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/patient-room-care')
                  "
                >
                  {{$t('sitemap.text.FF0040_024')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/exam-room')
                  "
                >
                  {{$t('sitemap.text.FF0040_025')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/operating-room')
                  "
                >
                  {{$t('sitemap.text.FF0040_026')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/emergency-room')
                  "
                >
                  {{$t('sitemap.text.FF0040_027')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/cafeteria')
                  "
                >
                  {{$t('sitemap.text.FF0040_028')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/healthcare/restroom')
                  "
                >
                  {{$t('sitemap.text.FF0040_029')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_030')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/office/meeting-room-1')
                  "
                >
                  {{$t('sitemap.text.FF0040_031')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/office/meeting-room-1')
                  "
                >
                  {{$t('sitemap.text.FF0040_032')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/office/cafeteria')"
                >
                  {{$t('sitemap.text.FF0040_033')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_034')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/education/classroom-1')
                  "
                >
                  {{$t('sitemap.text.FF0040_035')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/education/classroom-2')
                  "
                >
                  {{$t('sitemap.text.FF0040_036')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/education/gym')"
                >
                  {{$t('sitemap.text.FF0040_037')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/education/corridor')"
                >
                  {{$t('sitemap.text.FF0040_022')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_038')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/hospitality/lobby')"
                >
                  {{$t('sitemap.text.FF0040_021')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/hospitality/room')"
                >
                  {{$t('sitemap.text.FF0040_039')}}
                </li>
              </div>
            </div>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_040')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/public/check-in-area')"
                >
                  {{$t('sitemap.text.FF0040_041')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/public/waiting-area')"
                >
                  {{$t('sitemap.text.FF0040_042')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_043')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/single-family/kitchen-expansive')
                  "
                >
                  {{$t('sitemap.text.FF0040_044')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/single-family/kitchen-spacious')
                  "
                >
                  {{$t('sitemap.text.FF0040_045')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/single-family/kitchen-casual')
                  "
                >
                  {{$t('sitemap.text.FF0040_046')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'segments/detail/single-family/bathroom-casual'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_047')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'segments/detail/single-family/bathroom-spacious'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_048')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'segments/detail/single-family/bathroom-expansive'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_049')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/single-family/living-room')"
                >
                  {{$t('sitemap.text.FF0040_050')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/detail/single-family/garden')"
                >
                  {{$t('sitemap.text.FF0040_051')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_052')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/multi-family/kitchen')
                  "
                >
                  {{$t('sitemap.text.FF0040_053')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/multi-family/living-room')
                  "
                >
                  {{$t('sitemap.text.FF0040_054')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/multi-family/bathroom')
                  "
                >
                  {{$t('sitemap.text.FF0040_055')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/multi-family/bathroom-open')
                  "
                >
                  {{$t('sitemap.text.FF0040_056')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/multi-family/community-lounge')
                  "
                >
                  {{$t('sitemap.text.FF0040_057')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/detail/multi-family/cafeteria')
                  "
                >
                  {{$t('sitemap.text.FF0040_033')}}
                </li>
              </div>
            </div>
          </div> -->
          <!--SEGMENTS(EU/UK)-->
          <!-- <div class="sitemap-2dp-box" v-if="$countryCode === '_EU' || $countryCode === '_EN' || $countryCode === '_FR' || $countryCode === '_DE' || $countryCode === '_IT' || $countryCode === '_ES' || $countryCode === '_UK'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_004')}}</p>
            <div class="sitemap-gnb-2dp">
              <button
                type="button"
                @click="gotoUrl('segments/world')"
                class="txtUnderline"
              >
                <span>{{$t('sitemap.text.FF0040_005')}}</span>
              </button>
            </div>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_034')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsMain/SEG_EDU')
                  "
                >
                  {{$t('sitemap.text.FF0040_150')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_EDU_NURS')
                  "
                >
                  {{$t('sitemap.text.FF0040_035')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_EDU_PRS')"
                >
                  {{$t('sitemap.text.FF0040_036')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_EDU_GYM')"
                >
                  {{$t('sitemap.text.FF0040_037')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_EDU_HLY')"
                >
                  {{$t('sitemap.text.FF0040_022')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_020')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsMain/SEG_HLTH')"
                >
                  {{$t('sitemap.text.FF0040_151')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_REC')
                  "
                >
                  {{$t('sitemap.text.FF0040_016')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_NUR')
                  "
                >
                  {{$t('sitemap.text.FF0040_157')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_HLY')
                  "
                >
                  {{$t('sitemap.text.FF0040_022')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_PAT_ROOMCU')
                  "
                >
                  {{$t('sitemap.text.FF0040_158')}} A
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_PAT_ROOMCA')
                  "
                >
                  {{$t('sitemap.text.FF0040_158')}} B
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_EXAM_ROOM')
                  "
                >
                  {{$t('sitemap.text.FF0040_025')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_OPT_ROOM')
                  "
                >
                  {{$t('sitemap.text.FF0040_026')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_EM_ROOM')
                  "
                >
                  {{$t('sitemap.text.FF0040_027')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_CAFE_REST')
                  "
                >
                  {{$t('sitemap.text.FF0040_028')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_HLTH_PEST_ROOM')
                  "
                >
                  Toilet
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_038')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsMain/SEG_HOS')"
                >
                  {{$t('sitemap.text.FF0040_152')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_HOS_LOB')"
                >
                  {{$t('sitemap.text.FF0040_159')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_HOS_GST')"
                >
                  {{$t('sitemap.text.FF0040_160')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_030')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsMain/SEG_OFC')
                  "
                >
                  {{$t('sitemap.text.FF0040_153')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_OFC_LNG')
                  "
                >
                  {{$t('sitemap.text.FF0040_161')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsDetail/SEG_OFC_FLX')
                  "
                >
                  {{$t('sitemap.text.FF0040_162')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_OFC_CAFE')"
                >
                  {{$t('sitemap.text.FF0040_033')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_163')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsMain/SEG_PUB')"
                >
                  {{$t('sitemap.text.FF0040_154')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_PUB_CHK')"
                >
                  {{$t('sitemap.text.FF0040_164')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsDetail/SEG_PUB_LNG')"
                >
                  {{$t('sitemap.text.FF0040_165')}}
                </li>
              </div>
            </div>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_166')}}</li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('segments/segmentsMain/SEG_RES')
                  "
                >
                  {{$t('sitemap.text.FF0040_155')}}
                </li>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_053')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_KITCHEN_A')
                    "
                  >
                    {{$t('sitemap.text.FF0040_053')}} A
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_KITCHEN_B')
                    "
                  >
                    {{$t('sitemap.text.FF0040_053')}} B
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_KITCHEN_C')
                    "
                  >
                    {{$t('sitemap.text.FF0040_053')}} C
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_KITCHEN_D')
                    "
                  >
                    {{$t('sitemap.text.FF0040_053')}} D
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_055')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_BATHROOM_A')
                    "
                  >
                    {{$t('sitemap.text.FF0040_055')}} A
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_BATHROOM_B')
                    "
                  >
                    {{$t('sitemap.text.FF0040_055')}} B
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_BATHROOM_C')
                    "
                  >
                    {{$t('sitemap.text.FF0040_055')}} C
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_BATHROOM_D')
                    "
                  >
                    {{$t('sitemap.text.FF0040_055')}} D
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_BATHROOM_E')
                    "
                  >
                    {{$t('sitemap.text.FF0040_055')}} E
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_167')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_LVA')
                    "
                  >
                    {{$t('sitemap.text.FF0040_054')}} A
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_LVB')
                    "
                  >
                    {{$t('sitemap.text.FF0040_054')}} B
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_SFAM_GAR')
                    "
                  >
                    {{$t('sitemap.text.FF0040_051')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_MFAM_LOUNGE')
                    "
                  >
                    {{$t('sitemap.text.FF0040_057')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_MFAM_KITCHEN')
                    "
                  >
                    {{$t('sitemap.text.FF0040_168')}}
                  </li>
                </div>
              </div>
              <div class="sitemap-gnb">
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_006')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('segments/segmentsMain/SEG_RETL')"
                >
                  {{$t('sitemap.text.FF0040_156')}}
                </li>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_008')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FB_DIN')
                    "
                  >
                    {{$t('sitemap.text.FF0040_009')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FB_ORD')
                    "
                  >
                    {{$t('sitemap.text.FF0040_010')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FB_PR')
                    "
                  >
                    {{$t('sitemap.text.FF0040_011')}}
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_012')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FAS_DISP')
                    "
                  >
                    {{$t('sitemap.text.FF0040_013')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FAS_FR')
                    "
                  >
                    {{$t('sitemap.text.FF0040_014')}}
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_015')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FIT_REC')
                    "
                  >
                    {{$t('sitemap.text.FF0040_170')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_FIT_CR')
                    "
                  >
                    {{$t('sitemap.text.FF0040_017')}}
                  </li>
                </div>
                <div class="tit-sub">
                  <span class="tit">{{$t('sitemap.text.FF0040_018')}}</span>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_BTY_REC')
                    "
                  >
                    {{$t('sitemap.text.FF0040_171')}}
                  </li>
                  <li
                    @click="
                      gotoUrl('segments/segmentsDetail/SEG_RETL_BTY_SA')
                    "
                  >
                    {{$t('sitemap.text.FF0040_019')}}
                  </li>
                </div>
              </div>
            </div>
          </div> -->
          <!--PRODUCTS(US/GL)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_US' || $countryCode === '_GL'">
            <p class="tit-sitemapGnb">Products</p>

            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>ALL COLORS</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/all_colors')">
                  ALL COLORS
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_059')}}</span>
                    <span>Resilient Flooring</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/hflor/about')">
                  About HFLOR
                </li>
                <li class="sub-list" @click="gotoUrl('products/hflor/hflor-finder/lvt-flooring')">
                  HFLOR Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/HFLOR/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/hflor')">
                  Documentation
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/hflor')">
                  Care &#38; Maintenance
                </li>
                <li class="tit-gnb">TILE</li>
                <li class="sub-list" @click="gotoUrl('products/hflor/lvt')">
                  Luxury Vinyl Tile (LVT)
                </li>
                <li class="sub-list" @click="gotoUrl('products/hflor/looselay')">
                  Looselay
                </li>
                <li class="sub-list" @click="gotoUrl('products/hflor/spc')">
                  SPC Rigid Core
                </li>
                <li class="tit-gnb">SHEET</li>
                <li class="sub-list" @click="gotoUrl('products/hflor/homogeneous')">
                  Homogeneous
                </li>
                <li class="sub-list" @click="gotoUrl('products/hflor/heterogeneous')">
                  Heterogeneous
                </li>
                <li class="sub-list" @click="gotoUrl('products/hflor/specialty')">
                  Specialty
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_069')}}</span>
                    <span>Quartz Surface</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/viatera-quartz-surface')">
                  About VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('products/viatera-quartz-surface/viatera-finder/')">
                  VIATERA Colors
                </li>
                <li class="sub-list" @click="gotoUrl('products/viatera-quartz-surface/sink')">
                  VIATERA Sinks
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/VIATERA/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/viatera')">
                  Documentation
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/viatera')">
                  Care &#38; Maintenance
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_071')}}</span>
                    <span>Solid Surface</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/himacs-solid-surface')">
                  About HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('products/himacs-solid-surface/himacs-finder/')">
                  HIMACS Colors
                </li>
                <li class="sub-list" @click="gotoUrl('products/himacs-solid-surface/sink')">
                  HIMACS Sinks
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/HIMACS/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/himacs')">
                  Documentation
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/himacs')">
                  Care &#38; Maintenance
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_071')}}</span>
                    <span>Solid Surface</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/himacs-solid-surface')">
                  About HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('products/himacs-solid-surface/himacs-finder/')">
                  HIMACS Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/HIMACS/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/himacs')">
                  Documentation
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/himacs')">
                  Care &#38; Maintenance
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_069')}}</span>
                    <span>Quartz Surface</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/viatera-quartz-surface')">
                  About VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('products/viatera-quartz-surface/viatera-finder/')">
                  VIATERA Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/VIATERA/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/viatera')">
                  Documentation
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/viatera')">
                  Care &#38; Maintenance
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US' || $countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>TERACANTO</span>
                    <span>Porcelain Surface</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/teracanto-porcelain-surface')">
                  About TERACANTO
                </li>
                <li class="sub-list" @click="gotoUrl('products/teracanto-porcelain-surface/teracanto-finder/')">
                  TERACANTO Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/TERACANTO/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/teracanto')">
                  Documentation
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/teracanto')">
                  Care &#38; Maintenance
                </li>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>BENIF</span>
                    <span>Interior Film</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/benif-interior-film')">
                  About BENIF
                </li>
                <li class="sub-list" @click="gotoUrl('products/benif-interior-film/benif-finder/')">
                  BENIF Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/BENIF/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/benif')">
                  Documentation
                </li>
              </div>

              <div class="sitemap-gnb url-outbound p-ui" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">

                  </button>
                </div>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>VIZUON</span>
                    <span>Sign &#38; Graphic</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic')">
                  About VIZUON
                </li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/vizuon-finder/illuminated-sign')">
                  VIZUON Colors
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/vizuon')">
                  Documentation
                </li>
                <li class="tit-gnb">APPLICATION</li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/vehicle')">
                  Vehicles and Transportation
                </li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/signs-and-displays')">
                  Signs and Displays
                </li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/illuminated-sign')">
                  Illuminated Sign
                </li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/architecture')">
                  Architecture
                </li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/eco-solutions')">
                  Eco Solutions
                </li>
                <li class="sub-list" @click="gotoUrl('products/vizuon-sign-graphic/corporate-branding')">
                  Corporate Branding
                </li>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Deco Foil</span>
                    <span>Furniture Film</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/deco-foil')">
                  About Deco Foil
                </li>
                <li class="sub-list" @click="gotoUrl('products/deco-foil/deco-finder/')">
                  Deco Foil Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/Deco%20Foil/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/film_deco')">
                  Documentation
                </li>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Exterior Foil</span>
                    <span>Exterior Finish Film</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/exterior-foil')">
                  About Exterior Foil
                </li>
                <li class="sub-list" @click="gotoUrl('products/exterior-foil/exterior-finder/')">
                  Exterior Foil Colors
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/Exterior%20Foil/product_cate')">
                  Inspiration Galleries
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/film_ext')">
                  Documentation
                </li>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Home Appliance<br>Surface Film</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/industrial-film/home-appliance-surface-film')">
                  About Home Appliance Surface Film
                </li>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_US' || $countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_081')}}</span>
                    <span>Leather & Component</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/automotive/automotive-skin')">
                  Vegan Leather
                </li>
                <li class="sub-list" @click="gotoUrl('products/automotive/lightweight-component')">
                  Lightweight Component
                </li>
                <li class="sub-list" @click="gotoUrl('products/automotive/automotive-component')">
                  Automotive Component
                </li>
              </div>

            </div>
          </div>
          <!--PRODUCTS(EU/UK)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_EU' || $countryCode === '_EN' || $countryCode === '_FR' || $countryCode === '_DE' || $countryCode === '_IT' || $countryCode === '_ES' || $countryCode === '_UK'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_058')}}</p>

            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_059')}}</span>
                  </button>
                </div>
                <li class="tit-gnb">{{$t('sitemap.text.FF0040_060')}}</li>
                <li
                  class="sub-list"
                  @click="gotoUrl('products/hflor/FLOOR_LUX')"
                >
                  {{$t('sitemap.text.FF0040_172')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'products/hflor/hFlorFinder/FLOOR_LUX/filter/isNew/true'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_062')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_071')}}</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('products/himacs')">
                  {{$t('sitemap.text.FF0040_173')}}
                </li>
                <li class="sub-list" @click="gotoUrl('products/himacs/himacsFinder/filter/isNew/true')">
                  {{$t('sitemap.text.FF0040_062')}}
                </li>
              </div>
              <div class="sitemap-gnb url-outbound">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_073')}}</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('etc/old?url=http://old.lxhausys.com/products/index.jsp?cid=23', true)">
                  {{$t('sitemap.text.FF0040_074')}}
                </li>
                <li class="sub-list" @click="gotoUrl('etc/old?url=http://old.lxhausys.com/products/index.jsp?cid=101', true)">
                  {{$t('sitemap.text.FF0040_075')}}
                </li>
                <li class="sub-list" @click="gotoUrl('etc/old?url=http://old.lxhausys.com/products/index.jsp?cid=102', true)">
                  {{$t('sitemap.text.FF0040_076')}}
                </li>
                <li class="sub-list" @click="gotoUrl('etc/old?url=http://old.lxhausys.com/products/index.jsp?cid=103', true)">
                  {{$t('sitemap.text.FF0040_077')}}
                </li>
                <li class="sub-list" @click="gotoUrl('', true)">
                  {{$t('sitemap.text.FF0040_174')}}
                </li>
                <li v-if="$countryCode === '_UK'" class="sub-list" @click="gotoUrl('etc/old?url=http://old.lxhausys.com/products/platrom.jsp', true)">
                  {{$t('sitemap.text.FF0040_079')}}
                </li>
              </div>
            </div>
          </div>
          <!--INSPIRATION(US/GL)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_US' || $countryCode === '_GL'">
            <p class="tit-sitemapGnb">Inspiration</p>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb url-outbound">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Inspiration Galleries</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery')">
                  All
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/residential')">
                  Residential
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/gallery/commercial')">
                  Commercial
                </li>
              </div>
              <div class="sitemap-gnb url-outbound">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Case studies</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('inspiration/case-studies')">
                  All
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/case-studies/residential')">
                  Residential
                </li>
                <li class="sub-list" @click="gotoUrl('inspiration/case-studies/commercial')">
                  Commercial
                </li>
              </div>
              <div class="sitemap-gnb url-outbound">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Blog</span>
                  </button>
                </div>
                <li class="sub-list icon_site" @click="goBlog('https://www.lxhausys.com/us/blog/')">
                  <span>Blog</span>
                </li>
              </div>
              <div class="sitemap-gnb url-outbound" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Visualiser</span>
                  </button>
                </div>
                <li class="sub-list" onclick="roomvo.startStandaloneVisualizer('','')">
                  Visualiser
                </li>
              </div>
              <div class="sitemap-gnb url-outbound p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb url-outbound p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb url-outbound p-ui" v-if="$countryCode === '_GL'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--INSPIRATION(EU/UK) Done-->
          <div class="sitemap-2dp-box ins" v-if="$countryCode === '_EU' || $countryCode === '_EN' || $countryCode === '_FR' || $countryCode === '_DE' || $countryCode === '_IT' || $countryCode === '_ES' || $countryCode === '_UK'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_085')}}</p>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('inspiration/inspiration/inspirationGallery')"
                >
                  {{$t('sitemap.text.FF0040_086')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('inspiration/case-studies/INS_CAS')"
                >
                  {{$t('sitemap.text.FF0040_087')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('inspiration/newsRoom/INS_CAS')"
                >
                  {{$t('sitemap.text.FF0040_175')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('inspiration/virtual-showroom')"
                >
                  {{$t('sitemap.text.FF0040_089')}}
                </li>
              </div>
            </div>
          </div>
          <!--PROS(US/GL)-->
          <!-- <div class="sitemap-2dp-box pros" v-if="$countryCode === '_US' || $countryCode === '_GL'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_091')}}</p>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('pros/documentation/all-products')"
                >
                  {{$t('sitemap.text.FF0040_092')}}
                </li>
              </div>
              <div v-if="$countryCode === '_US'" class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('pros/bim-library')"
                >
                  {{$t('sitemap.text.FF0040_093')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li v-if="$countryCode === '_US'"
                  class="sub-list"
                  @click="gotoUrl('pros/fabricator/viatera')"
                >
                  {{$t('sitemap.text.FF0040_094')}}
                </li>
                <li v-if="$countryCode === '_US'"
                  class="sub-list"
                  @click="gotoUrl('pros/fabricator/himacs')"
                >
                  {{$t('sitemap.text.FF0040_095')}}
                </li>
              </div>
              <div v-if="$countryCode === '_US'" class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('pros/ceu-credits/hflor')"
                >
                  {{$t('sitemap.text.FF0040_097')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('pros/ceu-credits/himacs')"
                >
                  {{$t('sitemap.text.FF0040_098')}}
                </li>
              </div>
            </div>
            <div v-if="$countryCode === '_US'" class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('http://partners.lghausysusa.com/index.jsp', true)
                  "
                >
                  {{$t('sitemap.text.FF0040_099')}}
                </li>
              </div>
              <div v-if="$countryCode === '_US'" class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'https://lghausysusa.com/for-trade/payment-invoice.do',
                      true
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_100')}}
                </li>
              </div>
            </div>
          </div> -->
          <!--PROS(EU/UK) Done-->
          <div class="sitemap-2dp-box pros" v-if="$countryCode === '_EU' || $countryCode === '_EN' || $countryCode === '_FR' || $countryCode === '_DE' || $countryCode === '_IT' || $countryCode === '_ES' || $countryCode === '_UK'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_176')}}</p>
            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('pros/documentation/all-products')"
                >
                  {{$t('sitemap.text.FF0040_177')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('pros/documentation/viatera')"
                >
                  {{$t('sitemap.text.FF0040_096')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <li
                  class="sub-list"
                  @click="gotoUrl('himacsPartnersPortal/')"
                >
                  {{$t('sitemap.text.FF0040_178')}}
                </li>
              </div>
            </div>
          </div>
          <!--CONTACT(US/GL)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_US' || $countryCode === '_GL'">
            <p class="tit-sitemapGnb">Contact</p>

            <div class="sitemap-gnb-warp item_7" v-if="$countryCode === '_US'">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Contact Us</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/contactus')">
                  Contact Us
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Where to Buy</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/where-to-buy/global')">
                  Where to Buy
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Find a Sales rep.</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/sales-representative')">
                  Find a Sales rep.
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Our Showrooms</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/ourshowroom')">
                  Our Showrooms
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>FAQ</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/faq/hflor')">
                  HFLOR
                </li>
                <li class="sub-list" @click="gotoUrl('support/faq/viatera')">
                  VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('support/faq/himacs')">
                  HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('support/faq/teracanto')">
                  TERACANTO
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Warranty</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/warranty/hflor')">
                  HFLOR
                </li>
                <li class="sub-list" @click="gotoUrl('support/warranty/viatera')">
                  VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('support/warranty/himacs')">
                  HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('support/warranty/himacssink')">
                  HIMACS Sinks
                </li>
                <li class="sub-list" @click="gotoUrl('support/warranty/teracanto')">
                  TERACANTO
                </li>
                <li class="sub-list" @click="gotoUrl('support/warranty-claim')">
                  Warranty Claim Registration
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Care &#38; Maintenance</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/hflor')">
                  HFLOR
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/viatera')">
                  VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/himacs')">
                  HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/teracanto')">
                  TERACANTO
                </li>
              </div>
            </div>
            <div class="sitemap-gnb-warp" v-if="$countryCode === '_GL'">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Contact Us</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/contactus')">
                  Contact Us
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Where to Buy</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/where-to-buy/global')">
                  Where to Buy
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Find a Sales rep.</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/sales-representative')">
                  Find a Sales rep.
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>FAQ</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/faq/hflor')">
                  HFLOR
                </li>
                <li class="sub-list" @click="gotoUrl('support/faq/viatera')">
                  VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('support/faq/himacs')">
                  HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('support/faq/teracanto')">
                  TERACANTO
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Care &#38; Maintenance</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/hflor')">
                  HFLOR
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/viatera')">
                  VIATERA
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/himacs')">
                  HIMACS
                </li>
                <li class="sub-list" @click="gotoUrl('support/care-and-maintenance/teracanto')">
                  TERACANTO
                </li>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--CONTACT(EU/UK) Done-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_EU' || $countryCode === '_EN' || $countryCode === '_FR' || $countryCode === '_DE' || $countryCode === '_IT' || $countryCode === '_ES' || $countryCode === '_UK'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_128')}}</p>

            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_129')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="gotoUrl('support/contactus/pro')"
                >
                  {{$t('sitemap.text.FF0040_129')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_147')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'support/sales-representative/continenteu/hflor'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_059')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'support/sales-representative/continenteu/himacs'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_071')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_133')}}</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('support/where-to-buy/country/en')" v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'">
                  {{$t('sitemap.text.FF0040_133')}}<!--where to buy-->
                </li>
                <li class="sub-list" @click="gotoUrl('support/where-to-buy/country/uk')" v-if="$countryCode === '_UK'">
                  {{$t('sitemap.text.FF0040_133')}}<!--where to buy-->
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_179')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="gotoUrl('support/faq/himacs')"
                >
                  {{$t('sitemap.text.FF0040_132')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('support/care-and-maintenance/himacs')"
                >
                  {{$t('sitemap.text.FF0040_149')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('support/warranty/himacs')"
                >
                  {{$t('sitemap.text.FF0040_148')}}
                </li>
              </div>
            </div>
          </div>
          <!--FOR PROFESSIONALS(US/GL)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_US' || $countryCode === '_GL'">
            <p class="tit-sitemapGnb">For Professionals</p>
            <div class="sitemap-gnb-warp" v-if="$countryCode === '_US'">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Documentation</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products')">
                  View All
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/catalogue')">
                  Catalog
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/technical-datasheet')">
                  Technical Datasheet
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/installation-guide')">
                  Installation Guide
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/warranty')">
                  Warranty
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/care')">
                  Care &#38; Maintenance
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/certification')">
                  Certification
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>BIM Library</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('pros/bim-library')">
                  <span>BIM Library</span>
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Design Lab</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('inspiration/design-lab')">
                  <span>Design Lab</span>
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Earn CEU Credits</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('pros/ceu-credits/hflor')">
                  <span>HFLOR</span>
                </li>
                <li class="sub-list" @click="gotoUrl('pros/ceu-credits/viatera')">
                  <span>VIATERA</span>
                </li>
                <li class="sub-list" @click="gotoUrl('pros/ceu-credits/himacs')">
                  <span>HIMACS</span>
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Partner's Portal</span>
                  </button>
                </div>
                <li class="sub-list icon_site" @click="gotoUrl('https://partners.lxhausysusa.com/index.jsp')">
                  <span>Partner's Portal</span>
                </li>
              </div>
              <div class="sitemap-gnb" v-if="$countryCode === '_US'">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Invoice Payment</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('https://lxhausysusa.com/for-trade/payment-invoice.do')">
                  <span>Invoice Payment</span>
                </li>
              </div>
            </div>
            <div class="sitemap-gnb-warp" v-if="$countryCode === '_GL'">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Documentation</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products')">
                  View All
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/catalogue')">
                  Catalog
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/technical-datasheet')">
                  Technical Datasheet
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/fabrication')">
                  Fabrication
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/installation-guide')">
                  Installation Guide
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/warranty')">
                  Warranty
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/care')">
                  Care &#38; Maintenance
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/certification')">
                  Certification
                </li>
                <li class="sub-list" @click="gotoUrl('pros/documentation/all-products/test-report')">
                  Test Report
                </li>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--ABOUT US(GL)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_GL'">
            <p class="tit-sitemapGnb">About us</p>

            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Why LX Hausys</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/ceo-message')">
                  CEO Message
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/vision-and-strategy')">
                  Vision &#38; Strategies
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/story-of-lxhausys')">
                  History
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/business-area')">
                  Business Area
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/global-network')">
                  Global Network
                </li>
                <li class="sub-list sitemap-gnb-3dp">
                  R&#38;D
                  <ul>
                    <li class="sub-list" @click="gotoUrl('aboutus/r-and-d-center/market-leading-design')">
                      Market-leading design
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/r-and-d-center/designer-collaborations')">
                      Designer Collaborations
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/r-and-d-center/winning-design-awards')">
                      Awards &#38; Achievements
                    </li>
                  </ul>
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/performance')">
                  Performance
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Our Sustainability</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/sustainability/esg-strategy-system')">
                  ESG Strategies
                </li>
                <li class="sub-list sitemap-gnb-3dp">
                  Sustainability
                  <ul>
                    <li class="sub-list" @click="gotoUrl('aboutus/sustainability/net-zero')">
                      Net Zero 2050
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/sustainability/sustainability-management')">
                      Sustainability Management
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/sustainability/sustainable-products')">
                      Sustainable Products
                    </li>
                  </ul>
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/sustainability/social-responsibility')">
                  Social responsibility
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/sustainability/esg-reports')">
                  ESG reports
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>News &#38; Event</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/news-event/list')">
                  News &#38; Event
                </li>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--ABOUT US(US)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_US'">
            <p class="tit-sitemapGnb">About us</p>

            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Why LX Hausys</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/ceo-message')">
                  CEO Message
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/vision-and-strategy')">
                  Vision &#38; Strategies
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/story-of-lxhausys')">
                  History
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/business-area')">
                  Business Area
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/global-network')">
                  Global Network
                </li>
                <li class="sub-list sitemap-gnb-3dp">
                  R&#38;D
                  <ul>
                    <li class="sub-list" @click="gotoUrl('aboutus/r-and-d-center/market-leading-design')">
                      Market-leading design
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/r-and-d-center/designer-collaborations')">
                      Designer Collaborations
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/r-and-d-center/winning-design-awards')">
                      Awards &#38; Achievements
                    </li>
                  </ul>
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/why-lx-hausys/performance')">
                  Performance
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Our Sustainability</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/sustainability/esg-strategy-system')">
                  ESG Strategies
                </li>
                <li class="sub-list sitemap-gnb-3dp">
                  Sustainability
                  <ul>
                    <li class="sub-list" @click="gotoUrl('aboutus/sustainability/net-zero')">
                      Net Zero 2050
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/sustainability/sustainability-management')">
                      Sustainability Management
                    </li>
                    <li class="sub-list" @click="gotoUrl('aboutus/sustainability/sustainable-products')">
                      Sustainable Products
                    </li>
                  </ul>
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/sustainability/social-responsibility')">
                  Social responsibility
                </li>
                <li class="sub-list" @click="gotoUrl('aboutus/sustainability/esg-reports')">
                  ESG reports
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>News &#38; Event</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/news-event/list')">
                  News &#38; Event
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>Career</span>
                  </button>
                </div>
                <li class="sub-list" @click="gotoUrl('aboutus/career')">
                  Career
                </li>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
              <div class="sitemap-gnb p-ui">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--ABOUT US(EU/UK)-->
          <div class="sitemap-2dp-box" v-if="$countryCode === '_JP' || $countryCode === '_RU' || $countryCode === '_CN'">
            <p class="tit-sitemapGnb">{{$t('sitemap.text.FF0040_101')}}</p>

            <div class="sitemap-gnb-warp">
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_102')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/why-lx-hausys/businessArea')"
                >
                  {{$t('sitemap.text.FF0040_103')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/why-lx-hausys/globalBusiness')"
                >
                  {{$t('sitemap.text.FF0040_104')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/why-lx-hausys/storyofLXHausys')"
                >
                  {{$t('sitemap.text.FF0040_105')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/why-lx-hausys/ceoMessage')"
                >
                  {{$t('sitemap.text.FF0040_106')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_107')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/investorRelation/financialStatement')
                  "
                >
                  {{$t('sitemap.text.FF0040_108')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_109')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/rndCenter/rndAchivementnActivity')
                  "
                >
                  {{$t('sitemap.text.FF0040_110')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_111')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'aboutus/sustainability/sustainabilityManagementSystem'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_112')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/sustainability/greenManagement')
                  "
                >
                  {{$t('sitemap.text.FF0040_113')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/sustainability/ecoFriendlyProduct')
                  "
                >
                  {{$t('sitemap.text.FF0040_114')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/sustainability/jeongDoManagement')
                  "
                >
                  {{$t('sitemap.text.FF0040_115')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl(
                      'aboutus/sustainability/globalHumanRightsnLaborPolicy'
                    )
                  "
                >
                  {{$t('sitemap.text.FF0040_116')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/sustainability/csrReport')"
                >
                  {{$t('sitemap.text.FF0040_117')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/sustainability/qualityManagement')
                  "
                >
                  {{$t('sitemap.text.FF0040_118')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/sustainability/partnership')"
                >
                  {{$t('sitemap.text.FF0040_119')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/sustainability/social')"
                >
                  {{$t('sitemap.text.FF0040_120')}}
                </li>
              </div>
              <div class="sitemap-gnb">
                <div class="sitemap-gnb-2dp">
                  <button type="button" class="cursor-none">
                    <span>{{$t('sitemap.text.FF0040_121')}}</span>
                  </button>
                </div>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/design/designInnovation')"
                >
                  {{$t('sitemap.text.FF0040_122')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/design/collaborationWithSpacing')
                  "
                >
                  {{$t('sitemap.text.FF0040_123')}}
                </li>
                <li
                  class="sub-list"
                  @click="
                    gotoUrl('aboutus/design/developmentOfMarketLeading')
                  "
                >
                  {{$t('sitemap.text.FF0040_124')}}
                </li>
                <li
                  class="sub-list"
                  @click="gotoUrl('aboutus/design/designAwards')"
                >
                  {{$t('sitemap.text.FF0040_125')}}
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (Sitemap) -->

    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'sitemap',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      country: null
    }
  },
  async created () {
    // this.$countryCode = '_GL'
    // const country = this.$countryCode
    // this.country = country
  },
  async mounted () {
  },
  methods: {
    evaluate (val) {
      if (_.isUndefined(val)) {
        return false
      } else {
        return eval(val)
      }
    },
    gotoUrl (url, isNewTab) {
      if (url !== null) {
        if (this.evaluate(isNewTab)) {
          this.$utils.openUrl(url)
        } else {
          this.$utils.gotoUrl(url)
        }
      }
    },
    goBlog (url) {
      window.open(url, '_blank')
    }
  }
}
</script>
