/*
 * @Author: yuah
 * @LastEditors: yuah
 * @Description: file content
 */
import request from './request'
const urlPrefix = ''
export function getContentList (categoryId) {
  return request({
    url: `${urlPrefix}/content/search`,
    method: 'post',
    data: {
      categoryIds: [categoryId]
    }
  })
}
export function getContentListChild (categoryId) {
  return request({
    url: `${urlPrefix}/content/search`,
    method: 'post',
    data: {
      categoryIds: [categoryId],
      containChildCategory: true
    }
  })
}
export function getContent (id) {
  return request({
    url: `${urlPrefix}/content/${id}`,
    method: 'get'
  })
}
export function getContentAttr (id) {
  return request({
    url: `${urlPrefix}/directive/contentAttribute?id=${id}`,
    method: 'get'
  })
}

export function getContentFileList (contentId) {
  return request({
    url: `${urlPrefix}/directive/contentFileList?contentId=${contentId}`,
    method: 'get'
  })
}

export function getContentRelatedList (contentId) {
  return request({
    url: `${urlPrefix}/directive/contentRelatedList?contentId=${contentId}`,
    method: 'get'
  })
}
