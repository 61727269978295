var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "intro-area" }, [
    _c("div", { staticClass: "text-area" }, [
      _c("div", { staticClass: "text" }, [_vm._t("text")], 2),
    ]),
    !_vm.isEmpty(_vm.youtubeId) || _vm.useImage
      ? _c("div", { staticClass: "content-area" }, [
          !_vm.isEmpty(_vm.youtubeId)
            ? _c("iframe", {
                attrs: {
                  src: `https://www.youtube.com/embed/${_vm.youtubeId}?enablejsapi=1&origin=http%3A%2F%2Flocalhost%3A3000&widgetid=1`,
                  width: "100%",
                  frameborder: "0",
                },
              })
            : _vm._e(),
          _vm.useImage ? _c("img", { attrs: { src: _vm.imageSrc } }) : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }