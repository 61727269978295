<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content pros-view">

      <!-- location -->
      <div class="location">
        <ul>
        <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('Ceu.Text.Home')}}</li>
        <li>{{ $t('Ceu.Text.Pros')}}</li>
        <li>{{ $t('Ceu.Text.Ceu')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (CEU Credits) -->
      <div class="container for-box ceu-credits">
        <div class="w-con-02">
          <h1 class="tt-1 tac mb-88 inner">EARN Ceu credits</h1>
          <div class="tab-ui tab-ui-dark">
            <ul class="btns02">
                <li class="btn-tab" data-tab="tab01" id="tab01"><button type="button">{{ $t('Ceu.Text.HFLOR')}}</button></li>
                <li class="btn-tab" data-tab="tab02" id="tab02"><button type="button">{{ $t('Ceu.Text.HIMACS')}}</button></li>
                <li class="btn-tab" data-tab="tab03" id="tab03"><button type="button">{{ $t('Ceu.Text.VIATERA')}}</button></li>
            </ul>
            <!-- HFLOR -->
            <div v-for="(Content, index) in contentList" :id="'tap_'+(index+1)" :class="'inner tab-con tab0'+(index+1)">
                <div class="img-box">
                    <img :src="$imagePath+ Content.attribute.pc_img" alt="HFLOR" />
                </div>

                <div class="t-type-01">
                    {{ Content.content.description }}
                </div>

                <div class="c-txt-box">
                    <strong>
                        {{ Content.attribute.explanation_title }}
                    </strong>

                    <div class="list-text list-ab">
                        <ul>
                            <li v-if="Content.attribute.explanation[0] != null"><span class="ico">{{ Content.attribute.explanation[0].title }}</span>{{ Content.attribute.explanation[0].description }}</li>
                            <li v-if="Content.attribute.explanation[0] != null"><span class="ico">{{ Content.attribute.explanation[1].title }}</span>{{ Content.attribute.explanation[1].description }}</li>
                            <li v-if="Content.attribute.explanation[0] != null"><span class="ico">{{ Content.attribute.explanation[2].title }}</span>{{ Content.attribute.explanation[2].description }}</li>
                            <li v-if="Content.attribute.explanation[0] != null"><span class="ico">{{ Content.attribute.explanation[3].title }}</span>{{ Content.attribute.explanation[3].description }}</li>
                        </ul>
                    </div>
                </div>

                <div class="c-txt-box" v-if="Content.attribute.Information[0] != null">
                    <strong>{{ Content.attribute.Information[0].title }}</strong>

                    <div class="list-text">
                        <ul>
                            <li>{{ Content.attribute.Information[0].description }}</li>
                        </ul>
                    </div>
                </div>

                <div class="c-txt-box" v-if="Content.attribute.Information[1] != null">
                    <strong>{{ Content.attribute.Information[1].title }}</strong>

                    <div class="list-text">
                        <ul>
                            <li>{{ Content.attribute.Information[1].description }}</li>
                        </ul>
                    </div>
                </div>

                <div class="c-txt-box" v-if="Content.attribute.Information[2] != null">
                    <strong>{{ Content.attribute.Information[2].title }}</strong>

                    <div class="list-text">
                        <ul>
                            <li>{{ Content.attribute.Information[2].description }}</li>
                        </ul>
                    </div>
                </div>

                <div class="c-txt-box" v-if="Content.attribute.Information[3] != null">
                    <strong>{{ Content.attribute.Information[3].title }}</strong>

                    <div class="list-text">
                        <ul>
                            <li>{{ Content.attribute.Information[3].description }}</li>
                        </ul>
                    </div>
                </div>

                <div class="c-txt-box" v-if="Content.attribute.Information[4] != null">
                    <strong>{{ Content.attribute.Information[4].title }}</strong>

                    <div class="list-text">
                        <ul>
                            <li>{{ Content.attribute.Information[4].description }}</li>
                        </ul>
                    </div>
                </div>

                <div class="c-txt-box" v-if="Content.content.title != 'HFLOR'">
                  <strong>For Live Presentations, Please Contact Our Local
                    <a style="width: auto; text-decoration: underline;font-size: 38px;font-weight: 500;"
                       onClick="window.open('https://www.lxhausys.com/us/support/sales-representative/global?elqTrackId=E027B1034D0F56748FBA5F61D9F268DC&elqTrack=true')">
                      Representatives</a>
                  </strong>
                </div>

                <div class="more-box">
                    <button type="button" class="btn btn-block-m" onClick="window.open('https://www.greence.com/Free_Courses/Floor-Yours-Safe-and')" v-if="Content.content.title == 'HFLOR'"><span>{{ Content.content.title }} {{ $t('Ceu.Text.Ceu')}}</span></button>
                    <button type="button" class="btn btn-block-m" onClick="window.open('https://www.aecdaily.com/course.php?node_id=2173355')" v-if="Content.content.title == 'HIMACS'"><span>{{ Content.content.title }} {{ $t('Ceu.Text.Ceu')}}</span></button>
                    <button type="button" class="btn btn-block-m" onClick="window.open('https://www.aecdaily.com/course.php?node_id=2173623')" v-if="Content.content.title == 'VIATERA'"><span>{{ Content.content.title }} {{ $t('Ceu.Text.Ceu')}}</span></button>
                </div>
            </div>
            <!-- //HFLOR -->

              <!-- HIMACS -->
<!--                    <div class="tab-con tab02">-->
<!--                        <div class="img-box">-->
<!--                          <img :src="$imagePath+HIMACSContent.attribute.pc_img" alt="HFLOR" />-->
<!--                        </div>-->

<!--                        <div class="t-type-01">-->
<!--                          {{ HIMACSContent.content.description }}-->
<!--                        </div>-->

<!--                        <div class="c-txt-box">-->
<!--                          <strong>-->
<!--                            {{ HIMACSContent.attribute.explanation_title }}-->
<!--                          </strong>-->

<!--                          <div class="list-text list-ab">-->
<!--                            <ul>-->
<!--                              <li v-if="HIMACSContent.attribute.explanation[0] != null"><span class="ico">{{ HIMACSContent.attribute.explanation[0].title }}</span>{{ HIMACSContent.attribute.explanation[0].description }}</li>-->
<!--                              <li v-if="HIMACSContent.attribute.explanation[0] != null"><span class="ico">{{ HIMACSContent.attribute.explanation[1].title }}</span>{{ HIMACSContent.attribute.explanation[1].description }}</li>-->
<!--                              <li v-if="HIMACSContent.attribute.explanation[0] != null"><span class="ico">{{ HIMACSContent.attribute.explanation[2].title }}</span>{{ HIMACSContent.attribute.explanation[2].description }}</li>-->
<!--                              <li v-if="HIMACSContent.attribute.explanation[0] != null"><span class="ico">{{ HIMACSContent.attribute.explanation[3].title }}</span>{{ HIMACSContent.attribute.explanation[3].description }}</li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="c-txt-box" v-if="HIMACSContent.attribute.Information[0] != null">-->
<!--                          <strong>{{ HIMACSContent.attribute.Information[0].title }}</strong>-->

<!--                          <div class="list-text">-->
<!--                            <ul>-->
<!--                              <li>{{ HIMACSContent.attribute.Information[0].description }}</li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="c-txt-box" v-if="HIMACSContent.attribute.Information[1] != null">-->
<!--                          <strong>{{ HIMACSContent.attribute.Information[1].title }}</strong>-->

<!--                          <div class="list-text">-->
<!--                            <ul>-->
<!--                              <li>{{ HIMACSContent.attribute.Information[1].description }}</li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="c-txt-box" v-if="HIMACSContent.attribute.Information[2] != null">-->
<!--                          <strong>{{ HIMACSContent.attribute.Information[2].title }}</strong>-->

<!--                          <div class="list-text">-->
<!--                            <ul>-->
<!--                              <li>{{ HIMACSContent.attribute.Information[2].description }}</li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="c-txt-box" v-if="HIMACSContent.attribute.Information[3] != null">-->
<!--                          <strong>{{ HIMACSContent.attribute.Information[3].title }}</strong>-->

<!--                          <div class="list-text">-->
<!--                            <ul>-->
<!--                              <li>{{ HIMACSContent.attribute.Information[3].description }}</li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="c-txt-box" v-if="HIMACSContent.attribute.Information[4] != null">-->
<!--                          <strong>{{ HIMACSContent.attribute.Information[4].title }}</strong>-->

<!--                          <div class="list-text">-->
<!--                            <ul>-->
<!--                              <li>{{ HIMACSContent.attribute.Information[4].description }}</li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                        <div class="more-box">-->
<!--                            <button type="button" onClick="window.open('https://www.greence.com/Free_Courses/Solidly-Sustainable')"><span>HIMACS CEU Credits</span></button>-->
<!--                        </div>-->
<!--                    </div>-->
              <!-- //HIMACS -->
          </div>
        </div>
      </div>
      <!-- //container (CEU Credits) -->

    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
  <FooterComponent></FooterComponent>
</div><!-- //wrap -->
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import categoryService from '@/server/category.service'
import ProsService from '@/server/pros.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      contentList: [],
      field: [],
      type: [],
      cateName: '',
      metaDescription: '',
      countryCode: '',
      countryCodeSub: ''
    }
  },
  created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this
    const categoryName = this.$route.params.category_name
    _this.cateName = categoryName.toUpperCase()
    const categoryCode = 'PROS_CEU'
    const category = await categoryService.getCategoryCode(categoryCode) /* 카테고리 */
    const categoryId = category.data.object.id /* 카테고리 아이디 */
    _this.field.push('sort')
    _this.type.push('asc')
    const contentList = await ProsService.getContentListSort(categoryId, _this.field, _this.type) /* 콘텐츠 */
    _this.contentList = contentList.data.list /* 콘텐츠 리스트 */
    // _this.Content = _this.contentList[0];                          /* HFLOR 콘텐츠 */
    // _this.HIMACSContent = _this.contentList[1];                         /* HIMACS 콘텐츠 */
    _this.$nextTick(function () {
      if (categoryName == 'HFLOR' || categoryName == 'hflor') {
        const meta = _this.contentList.filter((element) => element.content.title == 'HFLOR')
        _this.metaDescription = meta[0].attribute.meta
        $('#tap_1').addClass('on')
        $('#tab01').addClass('on')
      } else if (categoryName == 'HIMACS' || categoryName == 'himacs') {
        const meta = _this.contentList.filter((element) => element.content.title == 'HIMACS')
        _this.metaDescription = meta[0].attribute.meta
        $('#tap_2').addClass('on')
        $('#tab02').addClass('on')
      } else {
        const meta = _this.contentList.filter((element) => element.content.title == 'VIATERA')
        _this.metaDescription = meta[0].attribute.meta
        $('#tap_3').addClass('on')
        $('#tab03').addClass('on')
      }
    })
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  methods: {
    gotolink (url) {
      location.href = url
    }
  }
}
</script>
