<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <!-- <div class="content inspr-dt-view"> -->
    <div class="content inspr-view">
      <!-- container (Case Studies Details) -->
      <div class="container inspr-box case-studies-detail">

        <div class="gv-side-header main p-ui">
          <div>
            <!-- location -->
            <div class="location">
              <ul>
                <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">
                  {{ $t('caseStudies.text.home') }}
                </li>
                <li>{{ $t('caseStudies.text.inspiration') }}</li>
                <li v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/inspiration/case-studies`)">
                  {{ $t('caseStudies.text.caseStudies') }}
                </li>
                <li>{{ $t('caseStudies.text.caseStudies_detail') }}</li>
              </ul>
            </div>
            <!-- //location -->
            <h1 class="con_title tt-3" v-html="contentDetail.content.title"></h1>
            <div class="gv-side-root">
              <template v-for="item in getSeletedSegmentsName(contentDetail.attribute)">
                <span class="ctt">{{ item.text }}</span>
                <span></span>
              </template>
            </div>
          </div>
        </div>

        <article class="gv-wrap">
          <section class="gv-view">
            <div class="gv-side-header m-ui">
              <p class="tt-3" v-html="contentDetail.content.title"/>
              <div class="gv-side-root">
                <template v-for="item in getSeletedSegmentsName(contentDetail.attribute)">
                  <span class="ctt">{{ item.text }}</span>
                  <span></span>
                </template>
              </div>
            </div>
            <template v-if="contentDetail.attribute">
              <div class="case_study_temp_first"
                @mouseover="onMouseOver"
                @mouseout="onMouseOut"
              >
                <div class="img-wrap first" v-if="contentDetail.attribute.mainThumbnail !== ''">
                  <img :src="`${$imagePath}${contentDetail.attribute.mainThumbnail}`"
                       :alt="contentDetail.attribute.metaTitle">
                  <div class="btn-group">
                    <button type="button" class="btn_download_large" @click="imgDownload(`${$imagePath}${contentDetail.attribute.mainThumbnail}`)">
                      <span class="icon"></span>
                    </button>
                    <ShareComponent
                      v-bind:childValue="contentDetail"
                      :type="'product'"
                    ></ShareComponent>
                    <button type="button" class="btn_view_large m-ui" @click="isLayerModal=true">
                      <span class="icon"></span>
                    </button>
                  </div>
                  <div class="center_btn" v-bind:class="{ on: isHovered }">
                    <button type="button" class="btn_view_large" @click="isLayerModal=true">
                      <span class="icon"></span>
                    </button>
                  </div>
                </div>
                <div class="btm_tit" v-if="contentDetail.attribute.lead !=='' ">
                  <h2 class="title" v-html="contentDetail.attribute.lead"/>
                </div>
                <div class="btm_desc">
                  <p class="ctt ctt-t4" v-html="contentDetail.attribute.description"/>
                </div>
              </div>
            </template>
            <!-- 캐이스 스터디 템플릿  -->
            <template v-if="contentDetail.attribute">
              <template v-for=" (key,value) in contentDetail.attribute">
                <template v-if="value === 'templateList'">
                  <template v-for=" (item,index) in key">
                    <template v-if="item.templateType === 1">
                      <div class="case_study_temp1">
                        <div class="flex_wrap">
                          <div class="img-wrap" v-if=" item.templateThumbnail !== '' ">
                            <img :src="`${$imagePath}${item.templateThumbnail}`" :alt="item.templateThumbnail"
                                 @click="handleClickTemplateThumbnail(index)">
                          </div>
                          <div class="txt-wrap">
                            <div class="btm_tit" v-if=" item.templateTitle !== '' ">
                              <p class="title" v-html="item.templateTitle"/>
                            </div>
                            <div class="btm_desc" v-if=" item.templateDescription !== '' ">
                              <p v-html="item.templateDescription"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.templateType === 2">
                      <div class="case_study_temp2">
                        <div class="img-wrap" v-if=" item.templateThumbnail !== '' ">
                          <img :src="`${$imagePath}${item.templateThumbnail}`" :alt="item.templateThumbnail"
                               @click="handleClickTemplateThumbnail(index)">
                        </div>
                        <div class="btm_tit" v-if=" item.templateTitle !== '' ">
                          <p class="title" v-html="item.templateTitle"/>
                        </div>
                        <div class="btm_desc" v-if=" item.templateDescription !== '' ">
                          <p v-html="item.templateDescription"/>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.templateType === 3">
                      <div class="case_study_temp3">
                        <div class="flex_wrap reverse">
                          <div class="txt-wrap">
                            <div class="btm_tit" v-if=" item.templateTitle !== '' ">
                              <p class="title" v-html="item.templateTitle"/>
                            </div>
                            <div class="btm_desc" v-if=" item.templateDescription !== '' ">
                              <p v-html="item.templateDescription"/>
                            </div>
                          </div>
                          <div class="img-wrap" v-if=" item.templateThumbnail !== '' ">
                            <img :src="`${$imagePath}${item.templateThumbnail}`" :alt="item.templateThumbnail"
                                 @click="handleClickTemplateThumbnail(index)">
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.templateType === '4-1'">
                      <div class="case_study_temp4">
                        <div class="flex_wrap">
                          <div class="min_cont">
                            <div class="img-wrap" v-if=" item.templateThumbnail !== ''">
                              <img :src="`${$imagePath}${item.templateThumbnail}`" :alt="item.templateThumbnail"
                                   @click="handleClickTemplateThumbnail(index)">
                            </div>
                            <div>
                              <div class="btm_tit" v-if=" item.templateTitle !== ''">
                                <p class="title" v-html="item.templateTitle"/>
                              </div>
                              <div class="btm_desc" v-if=" item.templateDescription !== ''">
                                <p v-html="item.templateDescription"/>
                              </div>
                            </div>
                          </div>
                          <div class="min_cont">
                            <div class="img-wrap" v-if=" key[index+1].templateThumbnail !== ''">
                              <img :src="`${$imagePath}${key[index+1].templateThumbnail}`"
                                   :alt="key[index+1].templateThumbnail" @click="handleClickTemplateThumbnail(index+1)">
                            </div>
                            <div>
                              <div class="btm_tit" v-if=" key[index+1].templateTitle !== ''">
                                <p class="title" v-html="key[index+1].templateTitle"/>
                              </div>
                              <div class="btm_desc" v-if=" key[index+1].templateDescription !== ''">
                                <p v-html="key[index+1].templateDescription"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </template>
            <!-- 캐이스 스터디 템플릿  -->
          </section>
          <section class="gv-side show" v-if="contentDetail.attribute">
            <div class="gv-side-wrap">
              <div class="gv-side-table">
                <ul class="gv-side-tablewrap" v-if="contentDetail.attribute.metaDataList">
                  <template v-for=" item in contentDetail.attribute.metaDataList">
                    <template v-if="item.content || item.content !== ''">
                      <li>
                        <div class="tit" v-html="item.name"/>
                        <div class="cont" v-html="item.content"/>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
              <template v-if="contentDetail.attribute.usedMaterialList">
                <div class="gv-side-header">
                  <p class="tt-3" v-if="contentDetail.attribute.usedMaterialList.length > 0 ">Used Material</p>
                </div>
                <div class="gv-side-list">
                  <template v-if="contentDetail.attribute"
                            v-for="itemList in contentDetail.attribute.sideUsedMaterialList">
                    <div class="gv-side-title_area">
                      <span class="gv-side-title" v-if="itemList[0].detail.parents"
                            v-html="getCategoryName(itemList[0].detail.parents, 1)"/>
                      <span class="gv-side-txt" v-if="itemList[0].detail.parents"
                            v-html="getCategoryName(itemList[0].detail.parents, 2)"/>
                    </div>
                    <ul>
                      <li v-for="(itemList, index) in itemList" v-bind:key="index">
                        <div class="thumb on" @click="moveToContentDetail(itemList)">
                          <a href="javascript:void(0)" v-if="itemList.detail">
                            <img :src="`${$imagePath}${itemList.detail.attribute.pf_thumb}`" alt="product">
                          </a>
                          <template v-if="itemList.detail">
                          <span class="tag"
                                v-if="itemList.detail.attribute.discontinued_color === 'true' ||
                                      itemList.detail.attribute.dictClr ==='true' ">Discontinued</span>
                          </template>
                        </div>
                        <div class="info">
                          <strong v-html="itemList.title"/>
                          <strong/>
                          <span/>
                          <!--                          <template v-if="itemList.detail">
                                                      <span v-html="itemList.detail.attribute.code"/>
                                                    </template>-->
                        </div>
                      </li>
                    </ul>
                  </template>
                </div>
              </template>
            </div>
          </section>
        </article>

        <!-- s: slide -->
        <template v-if="contentDetail.attribute.relatedContentsList">
          <template v-if="contentDetail.attribute.relatedContentsList.length !== 0">
            <div class="ins-box">
              <p class="prod-title">Related Projects</p>
              <div class="related_slider">
                <VueSlickCarousel v-bind="slickSettings">
                  <a href="javascript:void(0)" class="item"
                     v-for=" (item, index) in contentDetail.attribute.relatedContentsList" v-if="index <= 2">
                    <div class="imgwrap" @click="handleClickrelatedContent(item.content)">
                      <img :src="`${$imagePath}${item.attribute.mainThumbnail}`" :alt="item.content.title"/>
                    </div>
                    <div class="txtwrap">
                      <ul>
                        <li class="type">
                          <template v-for=" segmentsItem in getSeletedSegmentsName(item.attribute)">
                            <span v-html="segmentsItem.text"/>
                          </template>
                        </li>
                        <li class="tit">
                          <span v-html="item.content.title"/>
                        </li>
                      </ul>
                    </div>
                    <div class="tagwrap">
                      <template v-if="item.attribute.productList">
                      <span v-html="item.attribute.productList.value"/>
                      <span class="line"></span>
                      </template>
                    </div>
                  </a>
                </VueSlickCarousel>
              </div>
            </div>
          </template>
        </template>
        <!-- e: slide -->

        <!-- s: popup -->
        <transition name="fade">
          <div v-if="isLayerModal" class="layer-popup layer-gallery-img" :style="{ display:'block'}">
            <div class="popup pop-close">
              <div class="ins-box">
                <VueSlickCarousel v-bind="slickPopSettings" ref="slickModal">
                  <template v-if="contentDetail.attribute">
                    <div class="slider">
                      <div class="img-box">
                        <a href="javascript:void(0)">
                          <img :src="`${$imagePath}${contentDetail.attribute.mainThumbnail}`" alt="thumb"/>
                        </a>
                      </div>
                    </div>
                    <template v-for=" item in contentDetail.attribute.templateList">
                      <div class="slider">
                        <div class="img-box">
                          <a href="javascript:void(0)">
                            <img :src="`${$imagePath}${item.templateThumbnail}`" alt="thumb"/>
                          </a>
                        </div>
                      </div>
                    </template>
                  </template>
                </VueSlickCarousel>
              </div>
              <button type="button" @click="isLayerModal=false" class="btn-close"> close</button>
            </div>
          </div>
        </transition>
        <!-- e: popup -->
      </div>
      <!-- //container (Case Studies Details) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import ShareComponent from '@/components/Layout/Share'
import InspirationService from '@/server/inspiration.service'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex'

export default {
  name: 'Detail',
  props: ['childValue', 'type'],
  components: {
    GnbComponent,
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    FooterComponent,
    VueSlickCarousel,
    ShareComponent
  },
  data () {
    return {
      slug: '',
      cateCode: '',
      isLayerModal: false,
      contentDetail: {
        content: {},
        attribute: {
          usedMaterialList: [],
          mainThumbnail: '',
          lead: ''
        }
      },
      contentId: '',
      nationCode: '',
      slickSettings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              variableWidth: true,
              infinite: false
            }
          }
        ]
      },
      slickPopSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        draggable: false
      },
      countryCodeSub: '',
      isHovered: false
    }
  },
  computed: {
    ...mapState({})
  },
  async created () {
    this.countryCode = this.$countryCode.slice(1)
    this.cateCode = this.countryCode + '_' + this.$route.meta.code

    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  async mounted () {
    this.contentId = this.$route.params.contentId
    this.slug = this.$route.params.slug
    this.getDetail()
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    handleClickrelatedContent (content) {
      const { id, title } = content
      this.$router.push({ name: 'caseStudiesDetails', params: { contentName: title, contentId: id } })
    },
    async getDetail () {
      const _this = this
      try {
        const exceptList = ['productList', 'commercialList', 'residentialList', 'templateList', 'usedMaterialList', 'metaDataList', 'relatedContentsList']
        let data
        if ((isUndefined(_this.contentId) || isEmpty(_this.contentId)) && !isUndefined(_this.slug)) {
          const cateCode = _this.countryCode + '_' + _this.$route.meta.code
          const contentRes = await InspirationService.getInspirationDetailBySlugAndCategory(cateCode, encodeURIComponent(_this.slug))
          data = contentRes.data
        } else {
          const contentRes = await InspirationService.getInspirationDetail(_this.contentId)
          data = contentRes.data
        }

        // 구글태깅
        digitalData.case = {
          case_title: data.content?.title ?? '',
          case_segment: Array.isArray(data.attribute.commercialListSelectedText)
            ? data.attribute.commercialListSelectedText.join(' | ')
            : '',
          case_prd_brand: Array.isArray(data.attribute.productListSelectedText)
            ? data.attribute.productListSelectedText.join(' | ')
            : '',
          // 로케이션 협의 이후 진행 현재 텍스트로 입력하게 되어있음
          // case_location2: data.attribute?.location,
          case_location3: data.attribute?.location ?? ''
        }

        Object.keys(data.attribute).forEach((item, index) => {
          if (exceptList.includes(item)) {
            data.attribute[item] = JSON.parse(data.attribute[item])
          }
        })

        if (data.attribute.usedMaterialList !== undefined) {
          const usedMaterialList = data.attribute?.usedMaterialList.map((item) => item.id)
          const res = await InspirationService.getContentByIds(usedMaterialList)

          data.attribute.sideUsedMaterialList = data.attribute?.usedMaterialList.reduce((result, item) => {
            const findItem = res.data.find((findItem) => findItem.content.id === item.id)
            item.detail = findItem
            item.detailName = _this.getCategoryName(findItem.parents, 1)
            result.push(item)
            return result
          }, [])

          data.attribute.sideUsedMaterialList = _.groupBy(data.attribute?.sideUsedMaterialList, 'detailName')

          if (data.attribute.relatedContentsList !== undefined) {
            const relatedContentsList = data.attribute.relatedContentsList.map((item) => item.id)
            const res = await InspirationService.getContentByIds(relatedContentsList)
            res.data.forEach((itemList, itemListIndex) => {
              Object.keys(itemList.attribute).forEach((item, index) => {
                if (exceptList.includes(item)) {
                  res.data[itemListIndex].attribute[item] = JSON.parse(res.data[itemListIndex].attribute[item])
                }
              })
            })
            data.attribute.relatedContentsList = res.data
          }

          if ((!isUndefined(data.attribute.usedMaterialList) && !_.isEmpty(data.attribute.usedMaterialList))) {
            let size = 0
            const relatedContentsLength = data.attribute.relatedContentsList?.length
            const dictionaryCondition = {
              operator: 'AND',
              list: [{ operator: 'OR', values: [] }]
            }

            if (relatedContentsLength === 1) {
              size = 3
            } else if (relatedContentsLength === 2) {
              size = 2
            } else {
              size = 4
              data.attribute.relatedContentsList = []
            }

            dictionaryCondition.list[0].values = data.attribute.usedMaterialList.map((item) => ({
              id: 'productListSelected',
              value: item.detailName
            }))
            const relatedRes = await InspirationService.getContentListTap([data.content.categoryId], dictionaryCondition, '', 'search_text', 1, size)
            relatedRes.data.list.forEach((listItem, listIndex) => {
              Object.keys(listItem.attribute).forEach((item, index) => {
                if (exceptList.includes(item)) {
                  relatedRes.data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
                }
              })
            })
            data.attribute.relatedContentsList = data.attribute.relatedContentsList?.concat(relatedRes.data.list).filter((item) => item.content.id !== data.content.id)
          }
        }
        this.contentDetail = data
        this.categoryTree = data.parents?.map((item) => item.name).reverse()
        console.log('this.contentDetail', this.contentDetail)
      } catch (e) {
        console.error('caseStudiesDetailsError : ', e)
        this.$router.push({ name: 'notFound' })
      } finally {

      }
    },
    getSeletedSegmentsName (pageList) {
      let seletedSegments = []
      if (pageList.commercialList !== undefined) {
        seletedSegments = seletedSegments.concat(pageList.commercialList)
      }
      if (pageList.residentialList !== undefined) {
        seletedSegments = seletedSegments.concat(pageList.residentialList)
      }
      return seletedSegments
    },
    getCategoryName (list, type) {
      let categoryName = ''
      let categorySideName = ''
      const categoryType = ['HFLOR', 'HIMACS', 'VIATERA', 'TERACANTO', 'BENIF', 'VIZUON', 'Deco Foil', 'Exterior Foil']
      const categorySideType = categoryType.slice(0, 4)

      list.forEach((item) => {
        if (categoryType.includes(item.name)) {
          categoryName = item.name
        }
        if (categoryType.includes(item.name)) {
          const sideItem = {
            HFLOR: 'Resilient Flooring',
            HIMACS: 'Solid Surface',
            VIATERA: 'Quartz Surface',
            BENIF: 'Interior Film',
            VIZUON: 'Sign & Graphic'
          }
          categorySideName = sideItem[item.name]
        }
      })
      return type === 1 ? categoryName : categorySideName
    },
    hasTemplateListItems (templateListItem) {
      const { templateDescription, templateThumbnail, templateTitle } = templateListItem
      return !_.isEmpty(templateDescription) && !_.isEmpty(templateThumbnail) && !_.isEmpty(templateTitle)
    },
    handleClickTemplateThumbnail (index) {
      this.isLayerModal = true
      this.$nextTick(function () {
        this.$refs.slickModal.goTo(index + 1)
      })
    },
    moveToContentDetail (item) {
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      if (item.detail.attribute.discontinued_color === 'true' || item.detail.attribute.dictClr === 'true') {
        return false
      }

      const urlList = {
        HFLOR: 'hflor',
        HIMACS: 'himacs-solid-surface',
        VIATERA: 'viatera-quartz-surface',
        TERACANTO: 'teracanto-porcelain-surface',
        BENIF: 'benif-interior-film',
        VIZUON: 'Sign & Graphic',
        DecoFoil: 'deco-foil',
        ExteriorFoil: 'exterior-foil',
        BORTE: 'borte-premium-panel'
      }

      const hflorType = {
        luxuryvinyltile: 'lvt-flooring',
        homogeneoussheet: 'homogeneous-sheet',
        heterogeneoussheet: 'heterogeneous-sheet',
        looselay: 'looselay',
        spc: 'spc-click',
        specialty: 'specialty'
      }
      const vizuonType = {
        VehiclesandTransportation: 'vehicles-and-transportation',
        SignsandDisplays: 'signs-and-displays',
        IlluminatedSign: 'illuminated-sign',
        Architecture: 'architecture',
        CorporateBranding: 'corporate-branding'
      }
      const productType = this.getCategoryName(item.detail.parents, 1)
      const productTypeUrl = urlList[productType.replace(/\s+/g, '')]
      const title = item.title.toLowerCase()
      const id = item.id

      if (['HFLOR', 'VIZUON'].includes(productType)) {
        const productTypeObject = productType === 'HFLOR' ? hflorType : vizuonType
        let productTypeDetail = ''
        item.detail.parents.forEach((item) => {
          const itemName = item.name.replaceAll(' ', '').toLowerCase()
          if (productTypeObject[itemName] !== undefined) {
            productTypeDetail = productTypeObject[itemName]
          }
        })
        location.href = `/${countryCode}/products/${productTypeUrl}/${productTypeDetail}/${title}/${id}`
      } else {
        location.href = `/${countryCode}/products/${productTypeUrl}/${title}/${id}`
      }

      /* 분기
      *
      * ◆ HFLOR
      * Luxury Vinyl Tile / lvt-flooring
      * Homogeneous Sheet / homogeneous-sheet
      * Heterogeneous Sheet / heterogeneous-sheet
      * Sports / sports-flooring
      * - /products/hflor/저위 4개중하나/타이틀/id
      * ◆ HIMACS
      * - /products/himacs-solid-surface/타이틀/id
      * ◆ VIATERA
      * - /products/viatera-quartz-surface/타이틀/id
      * ◆ TERACANTO
      * ???? 이것도 id
      * ◆ BENIF
      * - /products/benif-interior-film/타이틀/id
      * ◆ VIZUON
      * SIGN & GRAPHIClist view
      * Vehicles and Transportation / vehicles-and-transportation
      * Signs and Displays / signs-and-displays
      * Illuminated Sign / illuminated-sign
      * Architecture /architecture
      * Corporate Branding /corporate-branding
      * -/products/vizuon-sign-graphic/위중하나/타이틀/id
      * ◆ Deco Foil
      * - /products/deco-film/타이틀/아이디
      * ◆ Exterior Foil
      * - /products/exterior-film/타이틀/아이디
      * ◆ Home Appliance Surface Film
      * - Home Appliance Surface Film : /products/industrial-film/home-appliance-surface-film
      * ◆ Automotive
      * - Automotive skin : /products/automotive/automotive-skin
      * - Lightweight Component : /products/automotive/lightweight-component
      * - Automotive Component : /products/automotive/automotive-component
      * */
    },
    onMouseOver() {
      this.isHovered = true
    },
    onMouseOut() {
      this.isHovered = false
    },
    imgDownload(url){
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      function toDataURL(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
          const reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

      toDataURL(url, function(dataUrl) {
        const element = document.createElement('a');
        element.setAttribute('href', dataUrl);
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element); // 클릭 후 요소 제거
      });
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
