/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import { FIND } from './mutation-types'

/* eslint-disable no-param-reassign */
export default {
  [FIND] (state, account) {
    state.id = account.id
    state.email = account.email
    state.name = account.name
  }
}
