var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    staticStyle: { display: "block", width: "100vw", height: "100vh" },
    attrs: {
      src: "https://indd.adobe.com/view/c514787d-a468-45da-9ccb-96a5efe19e39?startpage=1&allowFullscreen=false",
      frameborder: "0",
      allowfullscreen: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }