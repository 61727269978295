<template>
  <div class="expanded-waste-recycling-table-header">
    <pre class="header-text" v-for="item in headerList" :key="item">{{ item }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    headerList: {
      type: Array
    }
  }
}
</script>

<style>
.expanded-waste-recycling-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 30px 0px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid #888;
  border-bottom: 1px solid #E4E4E4;
  background: #ECECEC;
}

.expanded-waste-recycling-table-header .header-text {
  color: #000;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
}
</style>
