var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabCount > 0
    ? _c("div", { staticClass: "tab-con on" }, [
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("VIATERA ("),
              _c("em", [_vm._v(_vm._s(_vm.viatera.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-type-view row-04" }, [
            _vm.viatera && _vm.viatera.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.viatera.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-product-cont-box" },
                      [
                        _c("div", { staticClass: "over" }, [
                          _c("div", { staticClass: "img-box" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoDetail(sr, "viatera")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.$imagePath + sr.attribute.pf_thumb,
                                    alt: sr.content.title,
                                  },
                                }),
                              ]
                            ),
                            _vm._m(0, true),
                          ]),
                        ]),
                        sr.attribute.isNew === true
                          ? _c("i", { staticClass: "badge badge-new" }, [
                              _c("span", [_vm._v("NEW")]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetail(sr, "viatera")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(sr.content.title))]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-03" }, [
                              _vm._v(_vm._s(sr.parents[0].name)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.viatera.lastPage
            ? _c("div", { staticClass: "mb-64 ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-arr-down",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreViatera()
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("HIMACS ("),
              _c("em", [_vm._v(_vm._s(_vm.himacs.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-type-view row-04" }, [
            _vm.himacs && _vm.himacs.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.himacs.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-product-cont-box" },
                      [
                        _c("div", { staticClass: "over" }, [
                          _c("div", { staticClass: "img-box" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoDetail(sr, "HIMACS")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.$imagePath + sr.attribute.pf_thumb,
                                    alt: sr.content.title,
                                  },
                                }),
                              ]
                            ),
                            _vm._m(1, true),
                          ]),
                        ]),
                        sr.attribute.isNew === true
                          ? _c("i", { staticClass: "badge badge-new" }, [
                              _c("span", [_vm._v("NEW")]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _vm._v(_vm._s(sr.content.title)),
                            ]),
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetail(sr, "HIMACS")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(sr.attribute.code))]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-03" }, [
                              _vm._v(_vm._s(sr.parents[0].name)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.himacs.lastPage
            ? _c("div", { staticClass: "mb-64 ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-arr-down",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreHimacs()
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _c("div", { staticClass: "no-found-wrap bd-top" }, [_vm._m(2)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("VIATERA")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("HIMACS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tac" }, [
      _c("i", { staticClass: "icon-no-result" }, [
        _c("img", {
          attrs: { src: "images/icon-no-result-found.png", alt: "no result" },
        }),
      ]),
      _c("div", { staticClass: "msg" }, [
        _c("ul", [
          _c("li", { staticClass: "ctt ctt-t1 result-tit" }, [
            _vm._v("No Result Found"),
          ]),
          _c("li", { staticClass: "ctt ctt-t4" }, [
            _vm._v("Try again using more general search keywords"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }