<template>
  <div class="vinyl-coated-material-construction-area">
    <!-- 제목 -->
    <ProductSectionHeader title="Construction" />

    <!-- Tab 영역 -->
    <ProductTab
      class="mt-50"
      :item="[
        { title: 'Metallic Film', component: 'MetallicFilm' },
        { title: 'Pearl & Patterned Film', component: 'PatternedFilm' },
      ]"
    >
      <template #MetallicFilm>
        <MetallicFilm class="mt-50 film-area" />
      </template>

      <template #PatternedFilm>
        <PatternedFilm class="mt-50 film-area" />
      </template>
    </ProductTab>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductTab from '@/components/product/tab'
import MetallicFilm from './Film/MetallicFilm'
import PatternedFilm from './Film/PatternedFilm'

export default {
  components: {
    ProductSectionHeader,
    ProductTab,
    MetallicFilm,
    PatternedFilm
  }
}
</script>

<style scoped>
.vinyl-coated-material-construction-area {
  display: flex;
  flex-direction: column;
}

.vinyl-coated-material-construction-area .film-area {
  height: auto;
  max-height: 650px;
  background: #F1F1F5;
}
</style>
