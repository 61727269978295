var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quick_sticky" }, [
    _c("div", { staticClass: "quick_menu_wrap" }, [
      _c("div", { staticClass: "quick_link_group" }, [
        _c(
          "a",
          {
            staticClass: "q_item quick_link quick_contact",
            attrs: { href: _vm.getContactUsHref(_vm.country) },
          },
          [
            _c("span", { staticClass: "txt" }, [_vm._v("Contact us")]),
            _c("span", { staticClass: "icon" }),
          ]
        ),
        _vm.quickExpand
          ? _c(
              "a",
              {
                staticClass: "q_item quick_link quick_where",
                attrs: { href: _vm.getWheresHref(_vm.country) },
              },
              [
                _c("span", { staticClass: "txt" }, [_vm._v("Where to buy")]),
                _c("span", { staticClass: "icon" }),
              ]
            )
          : _vm._e(),
        _vm.quickExpand && (_vm.country === "_US" || _vm.country === "_GL")
          ? _c(
              "a",
              {
                staticClass: "q_item quick_link quick_news",
                on: {
                  click: function ($event) {
                    return _vm.openNewsletterPopup()
                  },
                },
              },
              [
                _c("span", { staticClass: "txt" }, [_vm._v("Newsletter")]),
                _c("span", { staticClass: "icon" }),
              ]
            )
          : _vm._e(),
        _vm.quickExpand &&
        (_vm.country === "_EN" ||
          _vm.country === "_FR" ||
          _vm.country === "_DE" ||
          _vm.country === "_IT" ||
          _vm.country === "_ES" ||
          _vm.country === "_UK")
          ? _c(
              "a",
              {
                staticClass: "q_item quick_link quick_news",
                on: {
                  click: function ($event) {
                    return _vm.openNewsletterPopupEu("layer-newsletter-form")
                  },
                },
              },
              [
                _c("span", { staticClass: "txt" }, [_vm._v("Newsletter")]),
                _c("span", { staticClass: "icon" }),
              ]
            )
          : _vm._e(),
        _vm.quickExpand
          ? _c(
              "a",
              {
                staticClass: "q_item quick_link quick_color",
                attrs: { href: _vm.getHref(_vm.country) },
              },
              [
                _c("span", { staticClass: "txt" }, [_vm._v("All colors")]),
                _c("span", { staticClass: "icon" }),
              ]
            )
          : _vm._e(),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "q_item quick_top", attrs: { type: "button" } },
      [_c("span", { staticClass: "icon icon_quick_top" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }