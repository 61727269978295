<template>
</template>
<script>
export default ({
  name: 'europe',
  data () {
    return {
      country: ''
    }
  },
  mounted () {
    const _this = this
    _this.country = _this.$route.params.country
    location.href = 'https://www.lxhausys.com/eu-' + _this.country
  },
  methods: {}
})
</script>
