var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "core-values-card-area" }, [
    _c("div", { staticClass: "title-area" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "title-desc" }, [_vm._v(_vm._s(_vm.titleDesc))]),
      _c("div", { staticClass: "bottom-line" }),
    ]),
    _c("img", { staticClass: "icon", attrs: { src: _vm.iconSrc } }),
    _c(
      "div",
      { staticClass: "desc-area" },
      _vm._l(_vm.desc, function (it, idx) {
        return _c("div", { key: idx, staticClass: "child" }, [
          _vm._v(" " + _vm._s(it) + " "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }