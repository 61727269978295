
<template>
  <!-- tab-con Products -->
  <div class="tab-con on" v-if="tabCount > 0">

    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt">
        <span>HFLOR (<em>{{hflor.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="hflor && hflor.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in hflor.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'HFLOR')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoHFlorDetail(sr)"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">HFLOR</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'HFLOR')">wish list</a>
                <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" tabindex="0" v-if="getSessionContent != null && nationCode == '_GL'" v-bind:class="{ on: getSessionContent.includes(sr.content.id) }" @click="toggleLike(sr, 'HFLOR')">wish list</a>
                <a class="icon-wish-shadow" tabindex="0" v-else-if="getSessionContent == null && nationCode == '_GL'" @click="toggleLike(sr, 'HFLOR')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoHFlorDetail(sr)">{{ sr.attribute.disp_code != undefined?sr.attribute.disp_code:sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
                <li class="txt-04">{{ sr.parents[1].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!hflor.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreHflor()">
          <span>{{ $t('see_more')}}</span>
        </button> -->
        <button type="button" class="btn_load_more" @click="loadMoreHflor()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>

    <div class="sch-res-prod prod-fd-view" v-if="showViatera()">
      <div class="res-prod-cnt">
        <span>VIATERA (<em>{{viatera.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="viatera && viatera.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in viatera.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'VIATERA')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'viatera')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">VIATERA</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <a class="icon-wish-shadow" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'Viatera')">wish list / 1</a>
              <a class="icon-wish-shadow" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list / 2</a>
              <a :id="'Like_' + sr.content.id" class="icon-wish-shadow" v-if="getSessionContent != null && nationCode == '_GL'" v-bind:class="{on: getSessionContent.includes(sr.content.id)}" @click="toggleLike(sr, 'Viatera')">wish list / 3</a>
              <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode == '_GL'" @click="toggleLike(sr, 'Viatera')">wish list / 4</a>
            </div>

            <div class="info">
              <ul>
                <li class="txt-01">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'viatera')">{{ sr.content.title }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!viatera.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreViatera()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreViatera()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>

    <!-- Viatera other products -->
    <div class="sch-res-prod prod-fd-view result-other-pd" v-if="showViateraOther()">
      <div class="res-prod-cnt">
        <span>VIATERA other products (<em>{{viateraOther.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="viateraOther && viateraOther.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in viateraOther.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'V-OTHER')">
              <div class="img-box img-other-pd">
                <a href="javascript:void(0);">
                  <!-- <img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title" @click="popUpView(content)"> -->
                  <img :src="$imagePath + sr.attribute.pc_thunb" :alt="sr.content.title" @click="popUpView(sr)">
                </a>
                <div class="over-cg">
                  <p class="ico-cg">VIATERA</p>
                </div>
              </div>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!viateraOther.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreViateraOther()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreViateraOther()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>

    <div class="layer-popup layer-other us-layer-other">
      <div class="popup pop-close">
        <div class="pop-other scr-y">
          <div class="inspr-view prod-fd-view prod-op-view">
            <div class="list-type-view col-02">
              <div>
                <div class="img-box tab-con other01 on">
                  <img :src="$imagePath+ contentAttribute.pc_thunb" alt="images" style="width: 100%"/>
                </div>
              </div>
              <div class="info">
                <div class="wide-proDeteail-label">
                  <span class="pro">Kitchen</span>
                </div>
                <p class="tt-1">{{ contentContent.title }}</p>
                <div class="tbl-box">
                  <table>
                    <caption>Product Specs</caption>
                    <colgroup>
                      <col style="width:35%">
                      <col style="width:auto">
                    </colgroup>
                    <tbody>
                    <tr v-if="contentAttribute.overall !== undefined">
                      <th scope="row">{{ $t("overall") }}</th>
                      <td>{{ contentAttribute.overall }}</td>
                    </tr>
                    <tr v-if="contentAttribute.inner !== undefined">
                      <th scope="row">{{ $t("inner") }}</th>
                      <td>{{ contentAttribute.inner }}</td>
                    </tr>
                    <tr v-if="contentAttribute.option !== undefined">
                      <th colspan="2">{{ contentAttribute.option }}</th>
                    </tr>
                    <tr v-if="contentAttribute.caution !== undefined">
                      <th colspan="2">{{ contentAttribute.caution }}</th>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="btns dwonarea">
                  <p class="ctt ctt-t2">Documentation</p>
                  <div >
                    <button v-if="contentAttribute.pdf" type="button" class="btn-down" @click="download(contentAttribute.pdf)"><span>pdf</span></button>
                    <button v-if="contentAttribute.dxf" type="button" class="btn-down" @click="download(contentAttribute.dxf)"><span>DXF</span></button>
                  </div>
                  <div >
                    <button type="button" class="btn btn-line" @click="contactUs()"><span>{{ $t("contactus") }}</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t("close") }}</button>
        </div>
      </div>
    </div>
    <!-- //popup : US Viatera Other Products -->

    <!-- HIMACS -->
    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt">
        <span>HIMACS (<em>{{himacs.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="himacs && himacs.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in himacs.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'HIMACS')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'HIMACS')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">HIMACS</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'HIMACS')">wish list</a>
                <a class="icon-wish-shadow" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{on: getSessionContent.includes(sr.content.id)}" @click="toggleLike(sr, 'HIMACS')">wish list</a>
                <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(sr, 'HIMACS')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'HIMACS')">{{ sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!himacs.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreHimacs()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreHimacs()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>

    <!-- HIMACS other products -->
    <div class="sch-res-prod prod-fd-view result-other-pd" v-if="showHimacsOther()">
      <div class="res-prod-cnt">
        <span>HIMACS other products (<em>{{himacsOther.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="himacsOther && himacsOther.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in himacsOther.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'H-OTHER')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="popUpView(sr)">
                  <img :src="$imagePath + sr.attribute.pc_thunb" :alt="sr.content.title">
                </a>
                <div class="over-cg">
                  <p class="ico-cg">HIMACS</p>
                </div>
              </div>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!himacsOther.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreHimacsOther()">
          <span>{{ $t('see_more')}}</span>
        </button> -->
        <button type="button" class="btn_load_more" @click="loadMoreHimacsOther()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>
    <!-- //HIMACS other products -->

    <!-- TERACANTO -->
    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt">
        <span>TERACANTO (<em>{{teracanto.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="teracanto && teracanto.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in teracanto.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'TERA')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'TERACANTO')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">TERACANTO</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'TERACANTO')">wish list</a>
                <a class="icon-wish-shadow" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{on: getSessionContent.includes(sr.content.id)}" @click="toggleLike(sr, 'TERACANTO')">wish list</a>
                <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(sr, 'TERACANTO')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'TERACANTO')">{{ sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!teracanto.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreTeracanto()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreTeracanto()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>
    <!-- //TERACANTO -->

    <!-- BENIF -->
    <div class="sch-res-prod prod-fd-view" v-if="nationCode == '_GL'">
      <div class="res-prod-cnt">
        <span>BENIF (<em>{{benif.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="benif && benif.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in benif.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'BENIF')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'BENIF')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">BENIF</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'BENIF')">wish list</a>
                <a class="icon-wish-shadow" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{on: getSessionContent.includes(sr.content.id)}" @click="toggleLike(sr, 'BENIF')">wish list</a>
                <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(sr, 'BENIF')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'BENIF')">{{ sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!benif.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreBenif()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreBenif()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>
    <!-- //BENIF -->

    <!-- VIZUON -->
    <div class="sch-res-prod prod-fd-view" v-if="nationCode == '_GL'">
      <div class="res-prod-cnt">
        <span>VIZUON (<em>{{vizuon.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="vizuon && vizuon.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in vizuon.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'VIZUON')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'VIZUON')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">VIZUON</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'VIZUON')">wish list</a>
                <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" tabindex="0" v-if="getSessionContent != null && nationCode == '_GL'" v-bind:class="{ on: getSessionContent.includes(sr.content.id) }" @click="toggleLike(sr, 'VIZUON')">wish list</a>
                <a class="icon-wish-shadow" tabindex="0" v-else-if="getSessionContent == null && nationCode == '_GL'" @click="toggleLike(sr, 'VIZUON')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'VIZUON')">{{ sr.attribute.disp_code != undefined?sr.attribute.disp_code:sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
                <li class="txt-04">{{ sr.parents[1].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!vizuon.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreVizuon()">
          <span>{{ $t('see_more')}}</span>
        </button> -->
        <button type="button" class="btn_load_more" @click="loadMoreVizuon()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>
    <!-- //VIZUON -->

    <!-- DECO -->
    <div class="sch-res-prod prod-fd-view" v-if="nationCode == '_GL'">
      <div class="res-prod-cnt">
        <span>Deco Foil (<em>{{deco.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="deco && deco.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in deco.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'DECO')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'DECO')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">Deco Foil</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'DECO')">wish list</a>
                <a class="icon-wish-shadow" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{on: getSessionContent.includes(sr.content.id)}" @click="toggleLike(sr, 'DECO')">wish list</a>
                <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(sr, 'DECO')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'DECO')">{{ sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!deco.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreDeco()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreDeco()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>
    <!-- //Deco -->

    <!-- EXTERIOR -->
    <div class="sch-res-prod prod-fd-view" v-if="nationCode == '_GL'">
      <div class="res-prod-cnt">
        <span>Exterior Foil (<em>{{exterior.totalCount}}</em>)</span>
      </div>
      <div class="list-type-view row-04">
        <ul v-if="exterior && exterior.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in exterior.list">
            <div class="over" :data-ec-product ="makeDataLayerForm(sr, 'EXTER')">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'EXTERIOR')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">Exterior Foil</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="view">
              <template v-if="canOrder(sr)">
                <a href="javascript:void(0);"  @click="openSampleOrder(sr.content.id)"  class="icon-cart-shadow">add cart</a>
              </template>
              <template>
                <a class="icon-wish-shadow" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'EXTERIOR')">wish list</a>
                <a class="icon-wish-shadow" v-if="authenticated == false && nationCode == '_US'" href="login/" @click="loginalertLike()">wish list</a>
                <a class="icon-wish-shadow" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{on: getSessionContent.includes(sr.content.id)}" @click="toggleLike(sr, 'EXTERIOR')">wish list</a>
                <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(sr, 'EXTERIOR')">wish list</a>
              </template>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'EXTERIOR')">{{ sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.attribute.grade.toUpperCase().replace('PLUS','+') }}</li>
                <li class="txt-04">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!exterior.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreExterior()"><span>{{ $t('see_more')}}</span></button> -->
        <button type="button" class="btn_load_more" @click="loadMoreExterior()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>
    <!-- //Exterior -->

    <!-- popup : EU HIMACS Other Products -->
    <div class="layer-popup layer-other hi-op-pop eu-layer-other">
      <div class="popup pop-close">
        <div class="pop-other scr-y">
          <div class="op-pop-pic">
            <div class="op-pop-slide-top">
              <div class="op-pop-imgbox on" id="popimage_01" name="popImage">
                <img :src="$imagePath + contentAttribute.pc_thunb" alt="slider_img01">
              </div>
            </div>

          </div>
          <div class="info">
            <ul>
              <li class="txt-01">{{ popUpCateName }}</li>
              <li class="txt-02 tt-1">{{ contentContent.title }}</li>
            </ul>
            <div class="tbl-box op-pop-tbl-box">
              <table>
                <caption>Product Specs</caption>
                <colgroup>
                  <col>
                  <col>
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row">{{ $t("prd_ref") }}</th>
                  <td>{{ contentAttribute.prd_code }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t("dim") }}</th>
                  <td><span class="ctt ctt-t5" style="color: #666;">{{ contentAttribute.prd_L }}(L) x {{ contentAttribute.prd_W }}(W)</span><span class="ctt ctt-t5" style="color: #666;" v-if="contentAttribute.prd_H != undefined"> x {{ contentAttribute.prd_H }}(H) </span><span class="ctt ctt-t5" style="color: #666;"> mm</span></td>
                </tr>
                <tr>
                  <th scope="row">{{ $t("install") }}</th>
                  <td>{{ contentAttribute.cate_installText }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t("more") }}</th>
                  <td>{{ contentAttribute.more }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="op-pop-doc">
            <p class="tt-sub">{{ $t("documentation") }}</p>
            <div class="cont">
              <ul>
                <li class="doc-drop-li" v-if="contentAttribute.pdf != undefined">
                  <span class="ctt ctt-t4">{{ $t("kbb") }}</span>
                  <button type="button" class="download" @click="download(contentAttribute.pdf)"></button>
                </li>
                <li class="doc-drop-li" v-if="contentAttribute.dxf != undefined">
                  <span class="ctt ctt-t4">{{ contentAttribute.dxf_orgName }}</span>
                  <button type="button" class="download" @click="download(contentAttribute.dxf)"></button>
                </li>
                <li class="doc-drop-li" v-if="contentAttribute.dxf2 != undefined">
                  <span class="ctt ctt-t4">{{ contentAttribute.dxf2_orgName }}</span>
                  <button type="button" class="download" @click="download(contentAttribute.dxf2)"></button>
                </li>
                <li class="doc-drop-li" v-if="contentAttribute.dxf3 != undefined">
                  <span class="ctt ctt-t4">{{ contentAttribute.dxf3_orgName }}</span>
                  <button type="button" class="download" @click="download(contentAttribute.dxf3)"></button>
                </li>
              </ul>
            </div>
          </div>

          <div class="btns claer">
            <div class="fl-l">
              <button class="btn btn-line btn-xs" @click="linkUrl(contentAttribute.prd_code)"><span>{{ $t("contactus") }}</span></button>
            </div>
          </div>
          <button type="button" class="btn-close" onclick="LayerPopup.close();"></button>
        </div>
      </div>
    </div>
    <!-- //popup : EU HIMACS Other Products -->

  </div>
  <div class="no-found-wrap bd-top" v-else>
<!--    {{tabCount}}-->
    <div class="tac">
      <i class="icon-no-result"><img src="images/icon-no-result-found.png" alt="no result"></i>
      <div class="msg">
        <ul>
          <li class="ctt ctt-t1 result-tit">No Result Found</li>
          <li class="ctt ctt-t4">Try again using more general search keywords</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'
import ProductService from '@/server/product.service.js'

import { mapState } from 'vuex'
import _ from '@/common/helper/lodash'
import { getEcomPrdData } from '@/views/products/js/product-detail'

export default {
  name: 'SearchProduct',
  props: ['searchResult', 'searchWord'],
  watch: {
    searchResult () {
      this.initSearchResult()
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  data () {
    return {
      seoCheck: false,
      nationCode: '',
      tabCount: 0,
      hflor: { totalCount: 0 },
      himacs: { totalCount: 0 },
      viatera: { totalCount: 0 },
      himacsOther: { totalCount: 0 },
      viateraOther: { totalCount: 0 },
      teracanto: { totalCount: 0 },
      benif: { totalCount: 0 },
      vizuon: { totalCount: 0 },
      deco: { totalCount: 0 },
      exterior: { totalCount: 0 },
      likeList: [],
      getSessionContent: [] /* GL : 불러오는 값 */,
      content: '',
      contentContent: '',
      contentAttribute: '',
      popUpliked: '',
      contentFile: [],
      popUpCateName: ''
    }
  },
  created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      t.seoCheck = true
    }
    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },
  async mounted () {
    this.initSearchResult()
  },
  methods: {
    contactUs () {
      location.href = '/' + this.$region + '/support/contactus'
    },
    popupImage (index) {
      $("[name*='popImage']").fadeOut(300)
      $('#popimage_0' + index).fadeIn(300)
    },
    async download (file) {
      const url = this.$imagePath + file
      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }
      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', url)
        document.body.appendChild(element)
        element.click()
      })
    },
    linkUrl (comment) {
      if (comment == 'room') {
        location.href = 'inspiration/virtual-showroom'
      } else {
        location.href = 'support/contactus/himacs/' + comment
      }
    },
    async popUpView (content) { /* 콘텐츠 상세보기 팝업 */
      await ProductService.getClickUp(content.content.id)
      const _this = this
      _this.content = content
      _this.contentContent = content.content
      _this.contentAttribute = content.attribute
      _this.popUpCateName = content.parents[0].name
      _this.popUpliked = content.content.liked
      if (_this.nationCode == '_US') {
        $("[name*='popImage']").fadeOut(300)
        $('#popimage_01').fadeIn(300)

        // LayerPopupGal.open('hi-op-pop2');
        LayerPopupGal.open('us-layer-other')
      } else {
        const contentFile = await ProductService.getContentFileList(content.content.id)
        _this.contentFile = contentFile.data.page.list
        $("[name*='popImage']").fadeOut(300)
        $('#popimage_01').fadeIn(300)

        // LayerPopupGal.open('hi-op-pop');
        LayerPopupGal.open('eu-layer-other')
      }
      // _this.$nextTick(function(e){
      //
      //   e.stopPropagation();
      // })
      return false
    },
    loginalertLike () {
      alert('Log in to save to your Likes')
    },
    showViatera () {
      const country = this.$countryCode.substring(1)
      return country == 'GL' || country == 'US'
    },
    showViateraOther () {
      const country = this.$countryCode.substring(1)
      return country == 'US'
    },
    showHimacsOther () {
      const country = this.$countryCode.substring(1)
      return country == 'US'
    },
    // sample order 재고 확인
    canOrder (item) {
      if (!item || !item.attribute) return false
      const price = item.attribute.price
      if (!_.isArray(price)) return false

      const filtered = price.filter(obj => _.toNumber(obj.price) > 0 && (_.isUndefined(obj.use) || obj.use == true))
      return filtered.length > 0
    },
    async loadMoreViatera () {
      this.loadMore('viatera')
    },
    loadMoreHflor () {
      this.loadMore('hflor')
    },
    loadMoreHimacs () {
      this.loadMore('himacs')
    },
    loadMoreHimacsOther () {
      this.loadMore('himacsOther')
    },
    loadMoreViateraOther () {
      this.loadMore('viateraOther')
    },
    loadMoreTeracanto () {
      this.loadMore('teracanto')
    },
    loadMoreBenif () {
      this.loadMore('benif')
    },
    loadMoreDeco () {
      this.loadMore('deco')
    },
    loadMoreVizuon () {
      this.loadMore('vizuon')
    },
    loadMoreExterior () {
      this.loadMore('exterior')
    },
    gotoDetail (sr, type) {
      if (this.seoCheck) {
        if (type == 'viatera' || type == 'VIATERA') {
          document.location.href = 'products/viatera-quartz-surface/' + sr.content.title + '/' + sr.content.id
        } else if (type == 'HIMACS' || type == 'himacs') {
          document.location.href = 'products/himacs-solid-surface/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        } else if (type == 'TERACANTO' || type == 'teracanto') {
          document.location.href = 'products/teracanto-porcelain-surface/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        } else if (type == 'BENIF' || type == 'benif') {
          document.location.href = 'products/benif-interior-film/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        } else if (type == 'DECO' || type == 'deco') {
          document.location.href = 'products/deco-foil/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        } else if (type == 'EXTERIOR' || type == 'exterior') {
          document.location.href = 'products/exterior-foil/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        } else if (type == 'VIZUON' || type == 'vizuon') {
          document.location.href = 'products/vizuon-sign-graphic/' + sr.parents[1].name.toLowerCase().replaceAll(' ', '-') + '/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        }
      } else {
        document.location.href = 'products/' + type + '/' + sr.content.title + '/' + sr.content.id
      }
    },
    gotoHFlorDetail (sr) {
      const _this = this
      const data = sr.parents[0].code
      let main_collectionNm = ''
      if (this.seoCheck) {
        if (data.includes('FLOOR_LUX_DECOLEY')) {
          main_collectionNm = 'looselay'
        } else if (data.includes('FLOOR_LOS_STYLE')) {
          main_collectionNm = 'looselay'
        } else if (data.includes('FLOOR_LUX')) {
          main_collectionNm = 'lvt-flooring'
        } else if (data.includes('FLOOR_HOM')) {
          main_collectionNm = 'homogeneous-sheet'
        } else if (data.includes('FLOOR_HET')) {
          main_collectionNm = 'heterogeneous-sheet'
        } else if (data.includes('FLOOR_SPC')) {
          main_collectionNm = 'spc-click'
        } else if (data.includes('FLOOR_SPT')) {
          main_collectionNm = 'specialty'
        }
        _this.$utils.gotoUrl('products/hflor/' + main_collectionNm + '/' + _this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id)
      } else {
        document.location.href = 'products/hflor/' + sr.parents[1].name + '/' + sr.content.title + '/' + sr.content.id
      }
    },
    async loadMore (subType) {
      const search = this.searchResult[subType]
      const pageIndex = search.pageIndex + 1
      const pageSize = search.pageSize
      const s = this.searchWord
      const r = this.$countryCode.substring(1)

      const { data } = await SearchService.byType({ pageIndex, pageSize, s, r, type: 'product', subType })

      search.lastPage = data.lastPage
      search.pageIndex = data.pageIndex
      search.list.push(...data.list)
    },

    async initSearchResult () {
      this.hflor = this.searchResult.hflor
      this.himacs = this.searchResult.himacs
      this.viatera = this.searchResult.viatera
      this.himacsOther = this.searchResult.himacsOther
      this.viateraOther = this.searchResult.viateraOther
      this.teracanto = this.searchResult.teracanto
      this.benif = this.searchResult.benif
      this.deco = this.searchResult.deco
      this.vizuon = this.searchResult.vizuon
      this.exterior = this.searchResult.exterior
      this.tabCount = this.searchResult.hflor.totalCount +
        this.searchResult.himacs.totalCount +
        this.searchResult.viatera.totalCount +
        this.searchResult.himacsOther.totalCount +
        this.searchResult.viateraOther.totalCount +
        this.searchResult.teracanto.totalCount +
        this.searchResult.benif.totalCount +
        this.searchResult.deco.totalCount +
        this.searchResult.vizuon.totalCount +
        this.searchResult.exterior.totalCount
    },
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode === '_GL') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            t.getSessionContent.push(o.id)
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },
    makeDataLayerForm (data, type) {
      let prdVariant = ''
      let prdType = ''
      let prdPrice = ''
      let hueText = ''
      if ((data.attribute.price != null || undefined) && data.attribute.price.length < 10) {
        prdVariant = _.join(_.flatMap(data.attribute.price, function (v) {
          return v.size
        }), '|')

        prdPrice = _.join(_.flatMap(data.attribute.price, function (v) {
          return v.price
        }), '|')
      }
      if (type == 'HFLOR' || type == 'VIZUON') {
        const cat = data.parents[1].code
        if (_.includes(cat, 'FLOOR_LUX') || _.includes(cat, 'FLOOR_HET')) {
          if (data.attribute.prdTypeText !== undefined) {
            if (Array.isArray(data.attribute.prdTypeText)) {
              prdType = data.attribute.prdTypeText.join('|')
            } else if (typeof data.attribute.prdTypeText === 'string') {
              prdType = data.attribute.prdTypeText
            } else {
              prdType = '' // 또는 다른 기본값
            }
          }
        }
        if (!_.isUndefined(data.attribute.hueText)) {
          hueText = data.attribute.hueText
        }
      }

      const { ecom_prd_brand, ecom_prd_category } = getEcomPrdData(data)
      return JSON.stringify({
        ecom_prd_id: data.attribute.code == '' ? data.content.id : data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand,
        ecom_prd_category, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: prdVariant, // price over 1 items
        ecom_prd_type: prdType, // HFLOR only over 1 items
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: hueText,
        ecom_prd_price: prdPrice, // price over 1 items
        ecom_currency_code: 'USD'
      })
    }
  }
}

function tmpPopup (popClass) {
  LayerPopupGal.open(popClass)
}
function tmpClose () {
  LayerPopupGal.close()
}
</script>
