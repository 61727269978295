<template>
  <div class="main-box seg-box">
    <h2 class="tit-main">{{ $t("main.text.segment") }}</h2>
    <div class="tab-ui tab-ui-nav">
      <ul class="justify-center">
        <li class="btn-tab on" data-tab="seg-all" @click="sliderReset('seg-all')"><button type="button">{{ $t("main.text.all") }}</button></li>
        <li class="btn-tab" data-tab="seg-com" @click="sliderReset('seg-com')"><button type="button">{{ $t("main.text.seg_com") }}</button></li>
        <li class="btn-tab" data-tab="seg-res" @click="sliderReset('seg-res')"><button type="button">{{ $t("main.text.seg_res") }}</button></li>
      </ul>
      <div class="slider-margin-0">
        <div class="tab-con seg-all on">
          <div class="overscr-list-wrap">
            <div class="overscr-list">
              <!-- EU일때 클래스 추가 euSlide -->
              <ul class="drag_segments" v-bind:class="{euSlide: nationCode !== '_GL' && nationCode !== '_US'}">
              <!-- <ul class="drag_segments"> -->
                <li class="vod" data-aos="fade-up" data-aos-duration="600" data-aos-delay="750">
                  <img :src="$imagePath+'/hausys/US/image/main/segment/seg-01.jpg'" alt="segments img" />
                  <a href="segments/world" class="seg-menu-tit"><span>{{ $t("main.text.hland") }}</span></a>
                </li>
                <li data-aos="fade-up" data-aos-duration="600" data-aos-delay="500" v-for="segment in categoryList">
                  <img :src="$imagePath + segment.categoryAttribute.img" alt="segments img" />
                  <a href="javascript:void(0)" class="seg-menu-tit">
                    <div class="menu-box" style="padding-top: 120px;">
                      <ul>
                        <li v-for="child in segment.related" v-if="segment.category.code !== 'GNB_SEG_HW_RET'+nationCode && segment.category.code !== 'GNB_SEG_HW_RES'+nationCode">
                          <a href="javascript:void(0)" v-on:click="$utils.gotoUrl(child.url);">{{ child.name }}</a>
                        </li>
                        <li v-for="(retail) in retailList" v-if="segment.category.code === 'GNB_SEG_HW_RET'+nationCode">
                          <a href="javascript:void(0)" v-on:click="$utils.gotoUrl(retail.category.url);"  v-if="retail.category.name !== 'Reception'">{{ retail.category.name }}</a>
                          <a href="javascript:void(0)" v-on:click="$utils.gotoUrl(retail.category.url);" v-else-if="retail.category.name === 'Reception' && retail.category.code === 'GNB_SEG_HW_RET_BEU_REC'+nationCode">{{ retail.category.name }} {{ $t("main.text.retail_bea") }}</a>
                          <a href="javascript:void(0)" v-on:click="$utils.gotoUrl(retail.category.url);" v-else-if="retail.category.name === 'Reception' && retail.category.code === 'GNB_SEG_HW_RET_FIT_REC'+nationCode">{{ retail.category.name }} {{ $t("main.text.retail_fit") }}</a>
                        </li>
                        <li v-for="(residential) in residential" v-if="segment.category.code === 'GNB_SEG_HW_RES'+nationCode">
                          <a href="javascript:void(0)" v-on:click="$utils.gotoUrl(residential.category.url);">{{ residential.category.name }}</a>
                        </li>
                      </ul>
                    </div>
                    <span v-on:click="$utils.gotoUrl(segment.category.url);">{{ segment.category.name }}</span>
                  </a>
                  <div class="txt-box"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainService from "@/server/main.service";
import {element} from "screenfull";

export default {
  name: "app",
  components: {
  },
  data () {
    return{
      nationCode : '',
      categoryCode: '',
      categoryList: [],
      retailList: [],
      commerList : [],
      residList : [],
      residential: [],
      allCateList : [],
    }
  },
  async mounted() {
    const _this = this;
    _this.nationCode = _this.$countryCode;
    let categoryCode = 'GNB_SEG_HW' + _this.nationCode;
    let category = await mainService.getCategoryListAttr(categoryCode);
    let categoryList = await mainService.getCategoryChildList(category.data.object.id, 'About');
    _this.allCateList = categoryList.data.list;
    _this.categoryList = _this.allCateList;
    _this.commerList = _.filter(_this.categoryList, function(c){
      return c.categoryAttribute.segmentType === '001';
    })
    _this.residList = _.filter(_this.categoryList, function(c){
      return c.categoryAttribute.segmentType === '002';
    })
    if(_this.nationCode === '_GL' || _this.nationCode === '_US'){
      let retailList = await mainService.getSegmentCategoryList(_this.categoryList[0].category.id, 'Retail');
      _this.retailList = _.filter(retailList.data.list, function(c){
        return c.category.parentId !== _this.categoryList[0].category.id && c.category.id !== _this.categoryList[0].category.id;
      })
    } else {
      let retailId = _this.categoryList.filter((element) => element.category.code === "GNB_SEG_HW_RET"+_this.nationCode);
      let retailList = await mainService.getSegmentCategoryList(retailId[0].category.id, 'Retail')
      _this.retailList = _.filter(retailList.data.list, function(c){
        return c.parents.length === 8;
      })
      let residenId = _this.categoryList.filter((element) => element.category.code === "GNB_SEG_HW_RES"+_this.nationCode);
      let residential = await mainService.getSegmentCategoryList(residenId[0].category.id, 'Retail')
      _this.residential = _.filter(residential.data.list, function(c){
        return c.parents.length === 8;
      })
    }

    this.$nextTick(function(){
      // (function ($, undefined) {
      //   "use strict";
      $(".seg-all .drag_segments").slick({
        arrows: true,
        dots: false,
        infinite: true,
        variableWidth:true,
        // prevArrow: $('.slider-prev'),
        // nextArrow: $('.slider-next'),
        prevArrow : "<button type='button' class='slick-prev slick-arrow'></button>",		// 이전 화살표 모양 설정
        nextArrow : "<button type='button' class='slick-next slick-arrow'></button>",		// 다음 화살표 모양 설정
        // slidesToShow:3,
        // Slider to show get maximum Colum
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerMode: true
            }
          }
        ]
      });
      // $(".seg-com .drag_segments").slick({
      //   arrows: false,
      //   dots: false,
      //   infinite: true,
      //   variableWidth:true,
      //   // slidesToShow:3,
      //   // Slider to show get maximum Colum
      //   swipeToSlide: true,
      //   responsive: [
      //     {
      //       breakpoint: 768,
      //       settings: {
      //         centerMode: true
      //       }
      //     }
      //   ]
      // });
      // $(".seg-res .drag_segments").slick({
      //   arrows: false,
      //   dots: false,
      //   infinite: false,
      //   draggable:false,
      //   variableWidth:true,
      //   // slidesToShow:2,
      //   // Slider to show get maximum Colum
      //   swipeToSlide: false,
      //   responsive: [
      //     {
      //       breakpoint: 768,
      //       settings: {
      //         infinite: true,
      //         centerMode: true
      //       }
      //     }
      //   ]
      // });
      //   $(".carouselTicker").carouselTicker({
      //     reverse: true
      //   });
      // })(jQuery);
    });
  },
  methods: {
    activeSlider(){

    },
    sliderReset(type){
      let _this = this;
      _this.categoryList = [];
      if(type === 'seg-all'){
        _this.categoryList = _this.allCateList;
      } else if (type === 'seg-com') {
        _this.categoryList = _this.commerList;
      } else if (type === 'seg-res') {
        _this.categoryList = _this.residList;
      }
      _this.$forceUpdate()
      $('.seg-all .drag_segments').slick("unslick");
      _this.$nextTick(function (){
        if(type === 'seg-res'){
          $(".seg-all .drag_segments").slick({
            arrows: false,
            dots: false,
            infinite: false,
            draggable:false,
            variableWidth:true,
            // slidesToShow:2,
            // Slider to show get maximum Colum
            swipeToSlide: false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  infinite: true,
                  centerMode: true
                }
              }
            ]
          });
        } else {
          $(".seg-all .drag_segments").slick({
            arrows: false,
            dots: false,
            infinite: true,
            variableWidth:true,
            // slidesToShow:3,
            // Slider to show get maximum Colum
            swipeToSlide: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  centerMode: true
                }
              }
            ]
          });
        }
      })
    },
  }
}
</script>

