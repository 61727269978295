<template>
  <!-- order sample -->
  <div class="layer-view layer-popup popup-w780 cart-ordsample">
    <div class="popup pop-close">
      <div class="pop_cont">
        <p class="popup-title">Order Sample</p>
        <div class="cart-sampleInfo" v-if="productAvailable">
          <div class="row">
            <div class="pop_con_img">
              <div class="img-box">
                <img v-if="image" :src="image" alt="sample">
              </div>
              <div class="info">
                <ul class="flex-column">
                  <li class="txt-prod">{{content.content.title}}</li>
                  <li class="txt-comp">{{content.parents.length > 0 ? content.parents[0].name : ''}} Collection
                  </li>
                </ul>
              </div>
            </div>
            <div class="pop_con_input">
              <div class="pop_con_scroll">
                <div class="info">
                  <ul class="flex-column">
                    <li class="txt-opt">
                      <div id="selSize" class="sel-box type02">
                        <button id="selSize_open" type="button" class="btn-sel"><span>{{msg.selSize}}</span></button>
                        <div id="selSize_list" class="sel-list">
                          <ul class="box">
                            <li v-for="(orderSpec, index) in availableSizes" :key="index">
                              <button type="button" @click="setSize(orderSpec,index)">{{orderSpec.size}}</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- S : 선택한 샘플 리스트 - Size 미등록 상품 숨김처리 & 사이즈 선택시에 보임 -->
                <div class="select-opt" v-if="orderList.length > 0">
                  <div class="opt-list">
                    <ul>
                      <li v-for="(item, idx) in orderList" :key="item.cartId">
                        <div class="opt-txt">
                          <div class="size">
                            {{item.sampleInfo.size}}
                          </div>
                          <div class="row ju_bet al_center">
                            <div class="spin-ui">
                              <button type="button" class="btn-m" :class="(item.qty == 1) ? 'disable' : '' " @click="modifyQty(item, 'minus')"><span>minus</span></button>
                              <input type="number" class="inp-qty" title="input quantity" :value="item.qty">
                              <button type="button" class="btn-p"  :class="item.qty >= 10 ? 'disable' : '' " @click="modifyQty(item, 'plus')"><span>plus</span></button>
                            </div>
                            <div class="price">
                              ${{calcOrder(item)}}
                              <button class="list-del" @click="deleteSample(item)"><span>delete</span></button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- E : 선택한 샘플 리스트 -->
              </div>
              <!-- Size 미등록 상품 숨김처리 -->
              <div class="more-box btn_area" v-if="orderList.length > 0">
                <button type="button" class="button h55 btn_add_cart" @click="setOrder('cart')">
                  <span class="txt">Add To Cart</span>
                </button>
                <button type="button" class="button h55 btn_order_now" @click="setOrder('order')">
                  <span class="txt">Order Now</span>
                </button>
              </div>
              <!-- //Size 미등록 상품 숨김처리 -->
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-close" @click="closePopup()">close</button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from '@/common/helper/lodash.js'
import sampleOrderService from '@/server/sampleOrder.service.js'

export default {
  name: 'SampleOrderPopComponent',
  data () {
    return {
      content: {},
      orderList: [],
      msg: {
        selSize: 'Select Size'
      }
    }
  },
  created: function () {
    // 다른곳에서 부를 수 있도록 지정.
    this.$root.$refs.sampleOrderView = this
  },
  computed: {
    productAvailable () {
      return Object.hasOwn(this.content, 'content')
    },
    availableSizes () {
      const orderSpecList = this.content.attribute.price
      if (!_.isArray(orderSpecList)) return []

      return orderSpecList
        .filter(orderSpec => {
          return _.toNumber(orderSpec.price) > 0 && (_.isUndefined(orderSpec.use) || orderSpec.use == true)
        })
    },
    image () {
      let image = null
      if (this.productAvailable) {
        if (!_.isEmpty(this.content.attribute.image)) {
          image = this.content.attribute.image
        } else if (!_.isEmpty(this.content.cover)) {
          image = this.content.cover
        } else if (this.content.orgContent && !_.isEmpty(this.content.orgContent.cover)) {
          image = this.content.orgContent.cover
        }
      }

      return image == null ? null : this.$imagePath + image
    }
  },

  methods: {
    async openPopup (id) {
      this.content = {}
      this.orderList = []

      LayerPopup.open('cart-ordsample')

      const res = await sampleOrderService.getProductInfo(id)
      this.content = res.data
    },
    closePopup () {
      LayerPopup.close()
      $('body').removeClass('scrolllock')
    },
    setSize (orderSpec, index) {
      let orderInfo = this.orderList.find((order) => order.sampleInfo.size == orderSpec.size)
      if (!orderInfo) {
        orderInfo = {
          cartId: null,
          content: null,
          contentId: 0,
          memNo: null,
          qty: 1,
          sampleInfo: orderSpec
        }

        orderInfo.cartId = this.$options.filters.createCartId()
        orderInfo.content = this.content
        orderInfo.contentId = this.content.id
        orderInfo.sampleInfo = orderSpec
        this.orderList.push(orderInfo)
      } else {
        this.modifyQty(orderInfo, 'plus')
      }
    },
    async sendGtm () {
      // GTM - add cart
      digitalData.addToCart = {
        ecom_currency_code: 'USD',
        products: []
      }
      _.forEach(this.orderList, function (v) {
        let brand = ''
        let category = ''
        let size = ''
        let type = ''
        let color = ''
        let collection = ''
        let cateCode = ''
        if (_.includes(v.content.content.modelId, 'HFLOR')) {
          // HFLOR
          brand = v.content.parents[2].name
          category = v.content.parents[1].name
          collection = v.content.parents[0].name
          type = ''// HFLOR only
          cateCode = v.content.parents[1].code
          if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
            type = v.content.attribute.prdTypeText
          }
        } else {
          // Viatera, Himacs
          brand = v.content.parents[1].name
          category = v.content.parents[0].name
          collection = v.content.parents[0].name
          color = v.content.attribute.hueText // himacs, viatera only
        }
        size = v.sampleInfo.size
        const obj = {
          ecom_prd_id: v.content.attribute.code == '' ? v.content.content.id : v.content.attribute.code,
          ecom_prd_name: v.content.content.title,
          ecom_prd_brand: brand,
          ecom_prd_category: category,
          ecom_prd_variant: size,
          ecom_prd_type: type,
          ecom_prd_collection: collection,
          ecom_prd_color: color,
          ecom_prd_price: v.price,
          ecom_prd_qty: v.qty
        }
        digitalData.addToCart.products.push(obj)
      })
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({ event: 'ecAddCart' })
      }
    },
    async setOrder (type) {
      // sample size 정보가 없을 경우
      if (this.orderList.length === 0) {
        alert('Please Select Option')
        return
      }

      this.sendGtm()

      _.forEach(this.orderList, function (v) {
        let category = null
        if (_.includes(v.content.content.modelId, 'HFLOR')) {
          category = v.content.parents[2]
        } else {
          category = v.content.parents[1]
        }
        v.categoryId = category.id
      })
      if (type === 'cart') {
        this.$store.dispatch('order/addCartItem', this.orderList)

        // cart 화면열기
        this.$root.$refs.cartView.openPopup()
      } else {
        this.$store.dispatch('order/setOrderItem', this.orderList)

        location.href = 'order/info'
      }
      this.closePopup()
    },
    modifyQty (item, mode) {
      // 수량 변경
      if (mode === 'plus') {
        if (item.qty < 10) {
          item.qty++
        } else {
          alert('You can not order up to 10 samples')
        }
      } else {
        if (item.qty !== 1) {
          item.qty--
        }
      }
    },
    calcOrder (item) {
      const price = item.sampleInfo.price
      const qty = item.qty
      // sample 당 가격산출
      return Number(price) * Number(qty)
    },
    deleteSample (item) {
      this.orderList = this.orderList.filter((order) => order.sampleInfo.size !== item.sampleInfo.size)
    }
  }
}

</script>
