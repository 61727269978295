var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "about_us_point", class: { us_point: _vm.isUSCode() } },
    [
      _c(
        "div",
        {
          staticClass: "pop_up",
          style: _vm.popupStyle,
          on: {
            mouseover: _vm.point_mouse_over,
            mousedown: _vm.point_mouse_down,
          },
        },
        _vm._l(this.pointInfo.popupList, function (popup) {
          return _c(
            "div",
            { key: popup.id, class: { gray_text: _vm.isGrayText() } },
            [_vm._v(" " + _vm._s(popup.value) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }