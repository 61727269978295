<template>
  <section class="eco-friendly-products-tab-header">
    <div
      class="header-item"
      v-for="(item, index) in headerInfo"
      :key="index"
      :class="item.isActive ? 'active' : ''"
      @click="onClickHeader(index)"
    >
      <pre
        class="text"
      >{{ item.title }}</pre>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tabDatas: {
      type: Array
    }
  },
  data: function () {
    return {
      selectedIndex: 0
    }
  },
  computed: {
    headerInfo () {
      return this.tabDatas.map((item, index) => {
        const isActive = index == this.selectedIndex ? true : false
        return {
          title: item.title,
          isActive: isActive
        }
      })
    }
  },
  methods: {
    onClickHeader (index) {
      this.selectedIndex = index
      this.$emit('selected-tab', index)
    }
  }
}
</script>

<style scoped>
.eco-friendly-products-tab-header {
  border-collapse: collapse;
  display: table;
}

@media (max-width: 768px) {
  .eco-friendly-products-tab-header {
    width: 100%;
  }
}

.eco-friendly-products-tab-header .header-item {
  padding: 20px 50px;
  text-align: center;
  border: 1px solid #393939;
  background: #FFF;
  display: table-cell;
  cursor: pointer;
}

.eco-friendly-products-tab-header .header-item .text {
  color: #393939;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 125% */
}

.eco-friendly-products-tab-header .header-item.active {
  padding: 20px 50px;
  text-align: center;
  border: 1px solid #393939;
  background: #393939;
}

@media (max-width: 768px) {
  .eco-friendly-products-tab-header .header-item {
    display: table-row;
  }
  .eco-friendly-products-tab-header .header-item.active {
    display: table-row;
  }
}

.eco-friendly-products-tab-header .header-item.active .text {
  color: #FFF;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 125% */
}

@media (max-width: 768px) {
  .eco-friendly-products-tab-header .header-item .text {
    font-size: 0.875rem;
    line-height: 2rem;
  }
  .eco-friendly-products-tab-header .header-item.active .text {
    font-size: 0.875rem;
    line-height: 2rem;
  }
}

</style>
