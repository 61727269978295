var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "film-item-area" }, [
    _c("div", { staticClass: "content-area" }, [
      _c("div", { staticClass: "text-area" }, [
        _c("span", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.title))]),
        _c("br"),
        _c("span", [_vm._v(_vm._s(_vm.description))]),
      ]),
      _c("div", { staticClass: "image" }, [
        _vm._m(0),
        _c("img", { attrs: { src: _vm.layerImageSrc, draggable: "false" } }),
      ]),
    ]),
    _c("div", { staticClass: "exmaple-image-area" }, [
      _c("img", { attrs: { src: _vm.exampleImageSrc, draggable: "false" } }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-text" }, [
      _c("span", { staticClass: "highlight" }, [_vm._v("* Printed")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }