import request from './request'

const urlPrefix = ''
const AccessLogService = {
  accessLog (path, title) {
    return request({
      url: `${urlPrefix}/access${path}?title=${title}`,
      method: 'get'
    })
  }
}
export default AccessLogService
