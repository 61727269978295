<template>
  <ul>
    <COMPARE></COMPARE>
    <COLOR :colorList="dictMap['hue']" v-model="hue"></COLOR>
    <COLLECTION :collectionList="collectionList" v-model="collection" :category="category"></COLLECTION>
    <PRD_PATTERN :patternList="dictMap['pattern']" v-model="pattern"></PRD_PATTERN>
    <FINISH :finishList="dictMap['finish']" v-model="finish"></FINISH>
    <THICKNESS :thickList="dictMap['thickness']" v-model="thickness"></THICKNESS>
    <BOOKMATCH v-if="$countryCode==='_US'"  :bookmatchList="dictMap['bookmatch']" v-model="bookmatch"></BOOKMATCH>
    <REMARK v-if="$countryCode==='_US'"  :remarkList="dictMap['remark']" v-model="remark"></REMARK>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import COMPARE from './component/COMPARE.vue'
import COLOR from './component/COLOR.vue'
import PRD_PATTERN from './component/PATTERN.vue'
import FINISH from './component/FINISH.vue'
import IS_NEW from './component/IS_NEW.vue'
import FEATURE from './component/FEATURE.vue'
import THICKNESS from './component/THICKNESS.vue'
import COLLECTION from './component/COLLECTION.vue'
import BOOKMATCH from './component/BOOKMATCH.vue'
import REMARK from './component/REMARK.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    COLLECTION,
    COMPARE,
    PRD_PATTERN,
    COLOR,
    FINISH,
    THICKNESS,
    BOOKMATCH,
    REMARK
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      hue: [],
      pattern: [],
      finish: [],
      isNew: [],
      feature: [],
      thickness: [],
      collection: [],
      bookmatch: [],
      remark: []
    }
  },
  watch: {
  },
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.features, vm.pattern, vm.finish, vm.isNew,vm.finish, vm.thickness,vm.bookmatch, vm.remark], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {
  }
}
</script>
