<template>
  <div class="key-activities-card">
    <div class="number-area">
      <pre class="number">{{ number }}</pre>
    </div>
    <div class="text-area">
      <pre class="text">{{ text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
.key-activities-card {
  border-radius: 30px 0px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  width: 334px;
  height: 327px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .key-activities-card {
    width: 100%;
    height: 100%;
    padding-bottom: 3%;
  }
}

.key-activities-card .number-area {
  width: 156px;
  height: 162px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.key-activities-card .number-area .number {
  color: rgba(232, 232, 232, 0.70);
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 150px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -7.5px;
  position: absolute;
  top: 0;
  right: 41px;
}

.key-activities-card .text-area {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.key-activities-card .text-area .text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .key-activities-card .text-area .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
</style>
