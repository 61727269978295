import request from './request'
const urlPrefix = ''

const mainService = {
  getCategoryCode (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: 'get'
    })
  },
  getContentList (categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true
      }
    })
  },
  getContentListSort (categoryId, pageIndex, pageSize, field, type) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true,
        orderField : [field],
        orderType: [type]
      }
    })
  },
  getContentListInspired (categoryId, pageIndex, pageSize, field, type) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        containChildCategory: true,
        orderField : [field],
        orderType: [type]
      }
    })
  },
  getContentListInspiredType (categoryId, pageIndex, pageSize, field, type, dictionaryValues) {
    return request({
      url: `${urlPrefix}/inspiration/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        containChildCategory: true,
        dictionaryCondition: dictionaryValues,
        orderField : [field],
        orderType: [type]
      }
    })
  },
  getContentListTap (categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        containChildCategory: true
      }
    })
  },
  getCategoryList (parentId) {
    return request({
      url: `${urlPrefix}/directive/categoryList?parentId=${parentId}`,
      method: 'get'
    })
  },
  getCategoryListAttr (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}?containsAttribute=true`,
      method: 'get'
    })
  },
  getContentListSegments (categoryId, text, pageIndex, pageSize, dictionaryValues) {
    return request({
      url: `${urlPrefix}/inspiration/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true,
        dictionaryValues: [dictionaryValues],
        text: text
      }
    })
  },

  getCategoryChildList (id, filterCode) {
    return request({
      url: `${urlPrefix}/category/categoryChild`,
      method: 'post',
      data: {
        parentId: id,
        childType: true,
        filterCode : filterCode
      }
    })
  },
  getSegmentCategoryList (id, filterCode) {
    return request({
      url: `${urlPrefix}/category/segmentsList`,
      method: 'post',
      data: {
        categoryId: [id],
        containChild:true,
        childType: true,
        filterCode : filterCode
      }
    })
  },
  getCategoryAttributeList (id) {
    return request({
      url: `${urlPrefix}/category/list`,
      method: 'post',
      data: {
        parentId: id
      }
    })
  }
}

export default mainService
