<template>
</template>
<script>
export default ({
  name: 'uk-hflor',
  data () {
    return {}
  },
  mounted () {
    location.href = 'https://www.lxhausys.com/uk-en'
  },
  methods: {}
})
</script>
