var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "key-activities-card" }, [
    _c("div", { staticClass: "number-area" }, [
      _c("pre", { staticClass: "number" }, [_vm._v(_vm._s(_vm.number))]),
    ]),
    _c("div", { staticClass: "text-area" }, [
      _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }