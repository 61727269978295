var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reduction-plan-card-area" }, [
    _c(
      "div",
      [
        _c("OverviewText", {
          attrs: { type: "ft-20 fw-700", text: _vm.title },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "desc-area" },
      _vm._l(_vm.desc, function (it, idx) {
        return _c("OverviewText", {
          key: idx,
          attrs: {
            type: "ft-16",
            textAlign: "left",
            enableListStyle: true,
            text: it,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }