import axios from 'axios'

import Vue from 'vue'
import {debounce} from "lodash";
Vue.prototype.$ajax = axios

// 로딩 상태 및 진행률을 다른 컴포넌트에서 접근할 수 있도록 export
let loadingCounter = 0; // 로딩 상태 카운터
export const getLoadingState = () => loadingState;

function getAppBaseApi () {
  let baseUrl = process.env.VUE_APP_BASE_API
  try {
    // 'eu-en', 'eu-fr' 등
    const pathPrefix = location.pathname.split('/')[1]
    if (pathPrefix === 'jp') {
      baseUrl = '/gl/api'
    }
    if (pathPrefix === 'ca-en' || pathPrefix === 'ca-fr') {
      baseUrl = '/us/api'
    }
  } catch (e) {
    console.error('Error determining baseUrl:', e);
  }

  return baseUrl
}

const service = axios.create({
  baseURL: getAppBaseApi(), // api  base_url
  withCredentials: true, // Sent on cross-domain request cookies
  timeout: 1000 * 60 // request timeout
})

service.interceptors.request.use((config) => {
  showLoadingBar();
  return config
}, (error) => {
  hideLoadingBar();
  return Promise.reject(error)
})

service.interceptors.response.use((res) => {
  hideLoadingBar();
  if (res.data && res.data.code == 500) {
    // document.location.href = 'etc.html#/internalError';
    return Promise.reject(res)
  }
  return res
}, (error) => {
  hideLoadingBar();
  if (error.response && error.response.status === 500) {
    // document.location.href = 'etc.html#/internalError';
    return Promise.reject(error.response)
  } else {
    return Promise.reject(error.response)
  }
})

// 로딩 상태를 관리할 Vue 인스턴스의 데이터
const loadingState = Vue.observable({
  loading: false, // 로딩 상태
  progressValue: 0 // 로딩 진행률
});

let debouncedUpdateProgress = null; // debounce 함수 초기화

function initializeDebounce() {
  debouncedUpdateProgress = debounce(() => {
    if (loadingState.loading) {
      increaseProgressGradually();
    } else {
      Vue.prototype.$updateProgress(0);
    }
  }, 300); // 300ms debounce
}
initializeDebounce(); // 초기 debounce 함수 호출

// 로딩바 시작 함수
function showLoadingBar() {
  loadingCounter++;
  if (loadingCounter === 1) {
    loadingState.loading = true;
    Vue.prototype.$updateProgress(30); // 진행률을 30으로 설정
    debouncedUpdateProgress();
  }
}

// 로딩바 종료 함수
function hideLoadingBar() {
  loadingCounter--;
  if (loadingCounter <= 0) {
    loadingCounter = 0;
    setTimeout(() => {
      Vue.prototype.$updateProgress(100); // 진행률을 100%로 설정
      setTimeout(() => {
        Vue.prototype.$updateProgress(0); // 0.5초 후에 진행률을 0으로 설정하여 로딩바를 사라지게 함
      }, 500);
    }, 400); // 400ms 딜레이 후에 진행률을 100%로 설정
    loadingState.loading = false;
  }
}

// Vue 인스턴스에 진행률 업데이트 메서드 추가
Vue.prototype.$updateProgress = function(value) {
  loadingState.progressValue = value;
};

function increaseProgressGradually() {
  let progress = loadingState.progressValue || 10;
  const target = 99;
  const intervalTime = 10; // 10ms 간격
  const increment = 0.297; // 증가 단위

  const interval = setInterval(() => {
    if (progress < target) {
      progress += increment;
      Vue.prototype.$updateProgress(progress);
    } else {
      clearInterval(interval); // 99에 도달하면 증가 멈춤
    }
  }, intervalTime);
}
export default service
