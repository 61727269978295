<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content content-finder">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home">{{ $t('PCD.text.home')}}</li>
          <li>{{ $t('PCD.text.products')}}</li>
          <li>{{ $t('PCD.text.hflor')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (hFlorFinder EU) -->
      <div class="container prod-fd-view hflor-finder">
        <!-- visual -->
        <!-- start -->
        <div class="visual visual-detail">
          <div class="visual-image" v-if="pfBanImg" v-bind:style="{ backgroundImage: 'url( ' + $imagePath + pfBanImg + ')' }">
            <div class="txt-box">
              <h1 class="tt-26"><span class="sr-only">HFLOR - </span> {{ subject }} </h1>
              <p class="t-type-05" v-if="brief != null" v-html="brief"></p>
              <button type="button" class="btn btn-more-point" @click="goLink()">
                <span>{{ $t('PCD.button.learnMore')}}</span>
              </button>
            </div>
            <div class="bgdim"></div>
          </div>
        </div>
        <!-- end -->

        <!-- <div class="visual">
         <div class="bg"><img :src="$imagePath + pfBanImg" alt="background" /><div class="bgdim"></div></div>

         <div class="text-box">
           <div class="t-type-04">{{ subject }}</div>

           <div class="t-type-05" v-if="brief != null" v-html="brief"></div>

           <button type="button" class="btn btn-more-point" @click="goLink()">
             <span>Learn more</span>
           </button>
         </div>
       </div> -->
        <!-- //visual -->

        <!-- EU 포토크레딧 추가 -->
        <p class="ctt photo-credits ta-r" v-if="nationCode != '_US' && nationCode != '_GL'">
          <span class="pc-br" v-if="photoCredit != null && photoCredit != undefined && photoCredit != ''" v-html="photoCredit" />
        </p>

        <!-- product Finder -->
        <div class="col-02 inspr-view fd-view">
          <!-- <p class="euTit ta-r inner" v-if="photoCredit !== undefined" v-html="photoCredit"></p> -->

          <!-- search 필터 선택 pc전용 -->
          <div id="filterSearch" class="search-box">
            <div class="search">
              <input id="textSearch" type="text" :placeholder="this.$t('PF.text.searchText')" @input="textSearch($event)"/>
              <!-- <button type="button" id="searchBtn" @click="textSearchDel()">search</button> -->
              <button class="icon-search gray" id="searchBtn" @click="textSearchDel()"></button>
            </div>

            <div class="drop-list inner" >
<!--              <p class="tt-2" v-show="isMobile() || filterShow">{{ $t('PF.text.filter_by')}}</p>-->
<!--              <button  v-show="isMobile() || filterShow" class="filter-clos" @click="clickFilter()"></button>-->
              <ul v-if="!isMobile()">
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PCD.text.hflor')}}</span>
                    <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                  </div>

                  <div class="i-box type01 cont" style="display:block;">
                    <ul>
                      <li>
                        <label class="inp-rdo">
                          <input type="radio" name="rdo00" :checked="categoryCode === 'FLOOR_LUX' + nationCode">
                          <span>{{ $t('PF.button.lux')}}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>

                <!--                &lt;!&ndash; Tone &ndash;&gt;-->
                <!--                <li class="on">-->
                <!--                  <div class="tit">-->
                <!--                    <span class="tt-sub">Tone</span>-->
                <!--                    <button type="button" class="btn-view btn-tone">Tone</button>-->
                <!--                  </div>-->

                <!--                  <div class="i-box type02 cont" style="display:block;">-->
                <!--                    <ul class="i-chk">-->
                <!--                      <li class="Tone" v-for="tone in leftTone" :data-chk="tone.id.value">-->
                <!--                        <label class="inp-chk">-->
                <!--                          <input type="checkbox" class="tone" :id="cateCode+'_tone_'+ tone.id.value" @click="[fnSearch('tone',tone.text, tone.id.value,'Tone', cateCode)]">-->
                <!--                          <span>{{ tone.text }}</span>-->
                <!--                        </label>-->
                <!--                      </li>-->
                <!--                    </ul>-->
                <!--                  </div>-->
                <!--                </li>-->
                <!--                &lt;!&ndash; Tone &ndash;&gt;-->

                <!-- Collection -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PCD.text.collection')}}</span>
                    <button type="button" class="btn-view btn-collection">{{ $t('PCD.text.collection')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul>
                      <li v-for="clt in leftCltList">
                        <label class="inp-chk">
                          <input type="checkbox" class="Collection" :id="clt.category.code" @change="[fnCateSearch(clt.category.name, clt.category.id, '',  clt.category.code)]" >
                          <span>{{ clt.category.name }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Collection -->

                <!-- Patterns -->
                <li class="on" v-if="leftPattns.length > 0">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PF.text.patterns')}}</span>
                    <button type="button" class="btn-view btn-patterns">{{ $t('PF.text.patterns')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul class="i-chk type02">
                      <li v-for="pattn in leftPattns">
                        <label class="inp-chk chk-thumb">
                          <input type="checkbox" class="pattern" :id="cateCode+'_pattern_'+ pattn.id.value" @change="[fnSearch('pattern', pattn.text, pattn.id.value, 'Patterns' ,cateCode)]">>
                          <span><i :style="'background-image : url(images/products/'+ cateCode + '_'+ pattn.id.value+'.jpg)'"/>{{ pattn.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Patterns -->

                <!-- New -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PF.text.new')}}</span>
                    <button type="button" class="btn-view btn-new">{{ $t('PF.text.new')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul>
                      <li>
                        <label class="inp-chk type03">
                          <input type="checkbox" :id="cateCode+'_isNew_true'" @click="[fnSearch('isNew','NEW COLORS', 'true', 'New' ,cateCode) ]">
                          <span>{{ $t('PF.text.new_colors')}}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- New -->

                <!-- Installation Method -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">Installation Method</span>
                    <button type="button" class="btn-view btn-install">Installation Method</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;"> <!-- himacsfinder.vue 참고해주세요 -->
                    <ul class="i-chk">
                      <li class="Install" v-for="install in leftInstall" :data-chk="install.id.value">
                        <label class="inp-chk">
                          <input type="checkbox" class="ins_Method" :id="cateCode+'_ins_Method_'+ install.id.value" @click="[fnSearch('ins_Method',install.text, install.id.value, 'Installation Method', cateCode)]">
                          <span>{{ install.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Installation Method -->

                <!-- Thickness -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PF.text.thickness')}}</span>
                    <button type="button" class="btn-view btn-thickness">{{ $t('PF.text.thickness')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul class="i-chk">
                      <li class="Thickness" v-for="thick in leftThick" :data-chk="thick.id.value">
                        <label class="inp-chk">
                          <input type="checkbox" class="thick" :id="cateCode+'_thick_'+thick.id.value" @click="[fnSearch('thick',thick.text, thick.id.value,'Thickness', cateCode)]">
                          <span>{{ thick.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Thickness -->

                <!-- Wear Layer -->
                <li class="on" v-if="leftWear.length != 0">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PF.text.wear_layer')}}</span>
                    <button type="button" class="btn-view btn-wareLayer">{{ $t('PF.text.wear_layer')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul class="i-chk">
                      <li class="WearLayer" v-for="wear in leftWear" :data-chk="wear.id.value">
                        <label class="inp-chk">
                          <input type="checkbox" class="wearLayer" :id="cateCode+'_wearLayer_'+wear.id.value" @click="[fnSearch('wearLayer',wear.text, wear.id.value,'WearLayer', cateCode)]">
                          <span>{{ wear.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Ware Layer -->

                <!-- Format -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">Format</span>
                    <button type="button" class="btn-view btn-format">Format</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul class="i-chk">
                      <li class="Format" v-for="format in leftFormat" :data-chk="format.id.value">
                        <label class="inp-chk">
                          <input type="checkbox" class="format" :id="cateCode+'_format_'+format.id.value" @click="[fnSearch('format',format.text, format.id.value,'Format', cateCode)]">
                          <span>{{ format.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Format -->

                <!-- Dimension -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t('PF.text.dimension')}}</span>
                    <button type="button" class="btn-view btn-dimension">{{ $t('PF.text.dimension')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul class="i-chk">
                      <li class="Dimension" v-for="dimen in leftDimen" :data-chk="dimen.id.value">
                        <label class="inp-chk">
                          <input type="checkbox" class="dimension" :id="cateCode+'_dimension_'+dimen.id.value" @click="[fnSearch('dimension',dimen.text, dimen.id.value,'Dimension', cateCode)]">
                          <span>{{ dimen.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Dimension -->

                <!--                &lt;!&ndash; Embossing &ndash;&gt;-->
                <!--                <li class="on">-->
                <!--                  <div class="tit">-->
                <!--                    <span class="tt-sub">Embossing</span>-->
                <!--                    <button type="button" class="btn-view btn-embossing">Embossing</button>-->
                <!--                  </div>-->

                <!--                  <div class="i-box type02 cont" style="display:block;">-->
                <!--                    <ul class="i-chk">-->
                <!--                      <li class="Embossing" v-for="emboss in leftEmboss" :data-chk="emboss.id.value">-->
                <!--                        <label class="inp-chk">-->
                <!--                          <input type="checkbox" class="embossing" :id="cateCode+'_embossing_'+emboss.id.value"-->
                <!--                                 @click="[fnSearch('embossing',emboss.text, emboss.id.value,'Embossing', cateCode)]">-->
                <!--                          <span>{{ emboss.text }}</span>-->
                <!--                        </label>-->
                <!--                      </li>-->
                <!--                    </ul>-->
                <!--                  </div>-->
                <!--                </li>-->
                <!--                &lt;!&ndash; Embossing &ndash;&gt;-->

                <!-- Performance -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">Performance</span>
                    <button type="button" class="btn-view btn-performance">Performance</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul class="i-chk">
                      <li class="Performance" v-for="perform in leftPerform" :data-chk="perform.id.value">
                        <label class="inp-chk">
                          <input type="checkbox" class="performance" :id="cateCode+'_performance_'+ perform.id.value"
                                 @click="[fnSearch('performance',perform.text, perform.id.value,'Performance', cateCode)]">
                          <span>{{ perform.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Performance -->
              </ul>
            </div>
          </div>
          <!-- //search 필터 선택 pc전용 -->

          <div class="prod-list-box">
            <div class="tab-ui tab-ui-dark">
              <ul>
                <li class="btn-tab on" id="btn-tab01" data-tab="tab01">
                  <button type="button">{{ $t('PF.button.all_products')}} ({{ contentLength }})</button>
                </li>
                <li class="btn-tab" id="btn-tab02" data-tab="tab02">
                  <button type="button">{{ $t('PF.button.grouped_by_collections')}} ({{ collectionLength }})</button>
                </li>
              </ul>
            </div>

            <!-- 모바일용 검색창 s -->
            <div class="fd-view-mb search-area inner">
              <div class="search">
                <input id="textSearchM" type="text" :placeholder="this.$t('PF.text.searchText') " @input="textSearch($event)">
                <button id="searchBtnM" class="icon-search gray" @click="textSearchDel()"></button>
              </div>
            </div>
            <!-- 모바일용 검색창 e -->

            <div class="tab-ui inner">

              <!-- 모바일용 filter by 버튼 s -->
              <div class="filter-arae">
                <button type="button" @click="openSearchPopup('layer-search-box')">
                  {{ $t('PF.button.filter_by')}}<i></i>
                </button>
              </div>
              <!-- 모바일용 filter by 버튼 e -->

              <div class="select-view" v-if="colorList.length > 0">
                <ul class="list-filter-key">
                  <li class="sel" v-for="(color, index) in colorList"  @click="[colorDelete(color.subject, color.name, color.id, color.code)]">
                    <span v-if="color.subject == 'Collection'">{{color.subject}}</span>
                    <span v-else>{{color.type}}</span>
                    : <span> {{color.name}} </span>
                    <button type="button">&#10005;</button>
                  </li>
                  <li class="sel del_all"> <!-- 1개 이상일때 나타나게 해주세요-->
                    <button class="btn-all-delete" v-if="colorList.length > 0" @click="clearAll()">
                      <span>{{ $t('PF.button.clear_all')}} <em>&#10005;</em></span>
                    </button>
                  </li>
                </ul>
              </div>

              <!-- sort -->
              <div class="sort-box">
                <div class="sel-box">
                  <button type="button" class="btn-sel"><span>{{ sortBy }}</span></button>
                  <div class="sel-list">
                    <ul class="box">
                      <li>
                        <button type="button" @click="sortByChange('Popularity', 'desc', 'clicks')">{{ $t('PF.button.Popularity')}}</button>
                      </li>
                      <li>
                        <button type="button" @click="sortByChange('New Products first', 'desc')">{{ $t('PF.button.new_products_first')}}</button>
                      </li>
                      <li>
                        <button type="button" @click="sortByChange('Products A to Z', 'asc', 'title')">{{ $t('PF.button.products_a_to_z')}}</button>
                      </li>
                      <li>
                        <button type="button" @click="sortByChange('Products Z to A', 'desc', 'title')">{{ $t('PF.button.products_z_to_a')}}</button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="more-box type03 comprae-box">
                  <button type="button" class="btn"  id=compare @click="getCompareList()"><span>{{ $t('PF.button.compare_your_colors')}} ({{compareArr.length}})</span></button>
                </div>
              </div>
              <!-- //sort -->

              <!-- All products -->
              <div class="tab-con tab01 on">
                <div class="list-type-view row-04">
                  <ul class="">
                    <li v-for="(item, idx) in contentList" :key="idx">
                      <a :href="'products/hflor/'+ categoryCode + '/' + item.content.title +'/'+item.content.id" tabindex="0"><!-- :data-ec-product ="makeDataLayerForm(item)" -->
                        <div class="over">
                          <div class="img-box ">
                            <span class="new" v-if="item.attribute.isNew === 'true'">{{ $t('PF.text.new')}}</span>
                            <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.content.title"/>
                          </div>
                        </div>
                      </a>
                      <div class="view">
                        <a @click="openSampleOrder(item.content.id)" class="icon-cart-shadow" tabindex="0" v-if="nationCode === '_US' || nationCode === '_UK'">{{ $t('PCD.text.cart')}}</a>

                        <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: item.content.liked }" @click="toggleLike(item, 'HFLOR')">{{ $t('PCD.text.wish_list')}}</a>
                        <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == false && nationCode == '_US'" href="javascript:void(0)" @click="alertAndGoLogin()" >{{ $t('PCD.text.wish_list')}}</a>

                        <a class="icon-wish-shadow" tabindex="0" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{ on: getSessionContent.includes(item.content.id) }" @click="toggleLike(item, 'HFLOR')">{{ $t('PCD.text.wish_list')}}</a>
                        <a class="icon-wish-shadow" tabindex="0" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(item, 'HFLOR')">{{ $t('PCD.text.wish_list')}}</a>
                      </div>

                      <div class="info02">
                        <ul>
                          <li class="txt-02">
                            <router-link
                              :to="'/'+ 'hflor/' + categoryCode + '/' + item.content.title + '/' + item.content.id">
                              {{ item.content.title }}
                            </router-link>
                          </li>
                          <li class="txt-01" v-if="item.attribute.disp_code != undefined">{{ item.attribute.disp_code }}</li>
                          <li class="txt-01" v-else>{{ item.attribute.code }}</li>
                          <li class="txt-03">{{ item.parents[0].name }}</li>
                          <li class="txt-04">{{ item.parents[1].name }}</li>
                        </ul>
                        <label class="inp-chk">
                          <input type="checkbox" v-model="compareArr" :id="item.content.id" :value="item.content.id" @change="addCompare(item.content.id)">
                          <span>{{ $t('PF.button.compare')}}</span>
                        </label>
                      </div>
                    </li>

                  </ul>
                </div>

                <!-- See More 버튼  -->
                <div class="more-box ta-c" v-if="contentLength > 16 && pageIndex == 1">
                  <button type="button" class="btn btn-arr-down" @click="loadMore(pageIndex+1)"><span>{{ $t('PF.button.see_more')}}</span></button>
                </div>
                <div class="more-box ta-c" v-if="contentLength > (pageIndex/16)*16*16 && pageIndex != 1 ">
                  <button type="button" class="btn btn-arr-down" @click="loadMore(pageIndex+1)"><span>{{ $t('PF.button.see_more')}}</span></button>
                </div>

              </div>
              <!-- //All products -->

              <!-- Grouped by collections -->
              <div class="tab-con tab02">
                <div class="list-type-col" v-for="(coll, index) in checkCollectionList" v-bind:key="index">

                  <ul>
                    <li class="col-02">
                      <div class="img-box">
                        <img :src="$imagePath+coll.categoryAttribute.image"/>
                      </div>

                      <div class="txt-box hf-txt-box">
                        <p class="tt-3 mb-20" v-if="!coll.category.name.includes('Collection')">{{coll.category.name}} {{ $t('PCD.text.collection')}}</p>
                        <p class="tt-3 mb-20" v-else>{{coll.category.name}}</p>
                        <div class="scroll">
                          <div class="ctt ctt-t4">
                            {{coll.categoryAttribute.Des}}
                          </div>
                        </div>
                        <div class="more-box type02-02">
                          <button type="button" class="btn btn-sm btn-second" v-if="categoryUsId[0] == categoryId" @click="fnCateSearch(coll.category.name, coll.category.id, 'click', coll.category.code)"><span>{{ $t('PF.button.see_all_colors')}}</span></button>
                          <button type="button" class="btn btn-sm btn-second" v-if="categoryUsId[0] != categoryId" @click="fnCateSearch()"><span>{{ $t('PF.button.see_all_colors')}}</span></button>
                          <!--                            <button type="button" class="btn btn-line"><router-link :to="'/hflor/'+ cateCode"><span>Online Book</span></router-link></button>-->
                          <button type="button" class="btn btn-sm btn-line" v-if="nationCode === '_US' && coll.categoryAttribute.onlineBook != null" @click="goLink('book', coll.categoryAttribute.onlineBook)">
                            <span>{{ $t('PCD.text.online_book')}}</span>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //Grouped by collections -->
            </div>

            <!-- short cut -->
            <div class="short-link type02 mb-64 inner">
              <a :href="'support/contactus'"><span class="ico-07">{{ $t('PCD.text.contact_us')}}</span></a>
              <a :href="'pros/documentation/hflor'"><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a>
              <a :href="'support/sales-representative/continenteu/hflor'">
                <span class="ico-19">{{ $t('PCD.button.find_a_sales_rep')}}</span>
              </a>
              <a :href="'inspiration/virtual-showroom'">
                <span class="ico-06">{{ $t('PD.button.virtual_Showroom')}}</span>
              </a>
            </div>
            <!-- //short cut -->
          </div>
        </div>

        <!-- popup : alert -->
        <div class="layer-popup layer-alert type02">
          <div class="popup pop-close">
            <div class="pop-alert inner">
              <div class="t-wrap">
                <div class="tit-box">
                  <p class="ctt ctt-t4 artTit">{{ $t('PF.popup.your_compare_is_full')}}</p>
                </div>

                <div class="txt-box">
                  <i class="ico-alert"></i>
                  <p class="tt-2">{{ $t('PF.popup.compare_tool')}}</p>

                  <div class="ctt ctt-t2">
                    {{ $t('PF.popup.compare_alert1')}}<br/>
                    {{ $t('PF.popup.compare_alert2')}}
                  </div>
                </div>
              </div>

              <div class="more-box ">

                <button type="button" id="replace" class="btn" @click="replace()"><span>{{ $t('PF.popup.replace')}}</span></button>
              </div>
              <div class="alert_txt">
                <button id="button editCompare" class="" @click="getCompareList()"><span>{{ $t('PF.popup.edit_products_in_compare')}}</span></button>
              </div>
              <button type="button" class="btn-close btn" onclick="LayerPopup.close();">{{ $t('PF.popup.replace')}}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : alert -->

        <!-- popup : Compare -->
        <div class="layer-popup layer-compare euSite" id="pdf_wrap">
          <div class="popup pop-close">
            <div class="pop-compare scr-y">
              <p class="tt-sub-gray">{{ $t('PF.popup.compare_hflor_products')}}</p>
              <p class="ta-c">{{ $t('PF.popup.characteristics_technical_performance')}}</p>
              <div class="prod-list tbl-compare">
                <table>
                  <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                  <colgroup>
                    <col style="width:auto">
                    <col style="width:auto">
                    <col style="width:auto">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                  <tr>
                    <td v-for="(item,idx) in compareList">
                      <div class="prod-box">
                        <div class="prod">
                          <div class="img-box over">
                            <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.content.title">
                          </div>

                          <div class="view" >
                            <a class="icon-wish-shadow" tabindex="0" v-if="getSessionContent != null && nationCode != '_US'" v-bind:class="{ on: getSessionContent.includes(item.content.id) }" @click="toggleLike(item, 'HFLOR')"/>
                            <a class="icon-wish-shadow" tabindex="0" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(item, 'HFLOR')"/>
                          </div>
                        </div>

                        <div class="popinfo">
                          <ul>
                            <li class="tt-sub">{{ item.content.title }}</li>
                            <li class="ctt ctt-t4">{{ item.attribute.code }}</li>
                            <li class="txt-01">{{item.parents[0].name}}</li>
                            <li class="txt-01">{{item.parents[1].name}}</li>
                          </ul>
                          <button type="button" class="btn-remove02" @click="removeCompare(item.content.id)"><span>{{ $t('PF.button.remove')}}</span></button>
                        </div>
                      </div>

                      <div class="tbl-box type02">
                        <table>
                          <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                          <colgroup>
                            <col style="width:auto">
                          </colgroup>
                          <tbody>
                          <tr>
                            <th scope="col">{{ $t('PD.text.dimension')}}</th>
                          </tr>
                          <tr v-if="item.attribute.dimensionText != undefined">
                            <td>{{ item.attribute.dimensionText }}</td>
                          </tr>
                          <td v-else>{{ }}</td>
                          <tr>
                            <th scope="col">Installation Method</th>
                          </tr>
                          <tr>
                            <td>{{item.parents[0].name}}</td>
                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.total_thickness')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.thickWearLayer != undefined">
                              {{ getThick(item.attribute.thickWearLayer).join(', ') }}
                            </td>
                            <td v-else>{{ item.attribute.thickText.join(', ') }}</td>

                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.wear_layer')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.thickWearLayer != undefined">
                              {{ getWearLayer(item.attribute.thickWearLayer).join(', ') }}</td>
                            <td v-else>{{ item.attribute.wearLayerText }}</td>
                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.pattern')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.patternText != null">{{ item.attribute.patternText.join(', ') }}</td>
                            <td v-else>{{  }}</td>
                          </tr>
<!--                          <tr> 아직 정의된 게 없어서 주석-->
<!--                            <th scope="col">Emboss</th>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td v-if="item.attribute.embossText != null">{{ item.attribute.embossText }}</td>-->
<!--                            <td v-else>{{  }}</td>-->
<!--                          </tr>-->
                          <tr>
                            <th scope="col">Packaging</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.pack_pcsText != null &&
                                item.attribute.pack_pcsText != null">
                              {{ item.attribute.pack_pcsText +'pcs / Box [' + item.attribute.pack_sqmText +' sq.m]' }}
                            </td>
                            <td v-else>{{  }}</td>
                          </tr>
<!--                          <tr> 아직은 정의된 게 없어서 주석 .... -->
<!--                            <th scope="col">Performance</th>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td v-if="item.attribute.performText != null">{{ item.attribute.performText }}</td>-->
<!--                            <td v-else>{{  }}</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <th scope="col">Key Features</th>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td v-if="item.attribute.keyFeatText != null">{{ item.attribute.keyFeatText }}</td>-->
<!--                            <td v-else>{{  }}</td>-->
<!--                          </tr>-->
                          </tbody>
                        </table>
                      </div>
                    </td>
                    <td v-if="compareList.length < 4">
                      <div class="btn-add hf-btn-add">
                        <button type="button" onclick="LayerPopup.close();"><span>{{ $t('PF.button.add_more')}}</span></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="btn-download">
                <button type="button" class="btn dwn load" @click="makePDF()">
                  <span>{{ $t('PF.button.download')}}</span>
                </button>
              </div>
              <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t('PCD.button.close')}}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : Compare -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb" style="display: none">
          <div class="popup">
            <div class="scr-y">

              <div class="drop-list">
                <p class="tt-2">{{ $t('PF.button.filter_by')}}</p>
                <button
                  class="filter-clos"
                  @click="closeSearchPopup()"
                ></button>
                <ul>
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PCD.text.hflor')}}</span>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="i-box type01 cont" style="display:block;">
                      <ul>
                        <li>
                          <label class="inp-rdo">
                            <input type="radio" name="rdo01" :checked="categoryCode === 'FLOOR_LUX' + nationCode">
                            <span>{{ $t('PF.button.lux')}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <!--                &lt;!&ndash; Tone &ndash;&gt;-->
                  <!--                <li class="on">-->
                  <!--                  <div class="tit">-->
                  <!--                    <span class="tt-sub">Tone</span>-->
                  <!--                    <button type="button" class="btn-view btn-tone">Tone</button>-->
                  <!--                  </div>-->

                  <!--                  <div class="i-box type02 cont" style="display:block;">-->
                  <!--                    <ul class="i-chk">-->
                  <!--                      <li class="Tone" v-for="tone in leftTone" :data-chk="tone.id.value">-->
                  <!--                        <label class="inp-chk">-->
                  <!--                          <input type="checkbox" class="tone" :id="cateCode+'_tone_'+ tone.id.value" @click="[fnSearch('tone',tone.text, tone.id.value,'Tone', cateCode)]">-->
                  <!--                          <span>{{ tone.text }}</span>-->
                  <!--                        </label>-->
                  <!--                      </li>-->
                  <!--                    </ul>-->
                  <!--                  </div>-->
                  <!--                </li>-->
                  <!--                &lt;!&ndash; Tone &ndash;&gt;-->

                  <!-- Collection -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PCD.text.collection')}}</span>
                      <button type="button" class="btn-view btn-collection">{{ $t('PCD.text.collection')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul>
                        <li v-for="clt in leftCltList">
                          <label class="inp-chk">
                            <input type="checkbox" class="Collection" :id="clt.category.code" @change="[fnCateSearch(clt.category.name, clt.category.id, '',  clt.category.code)]" >
                            <span>{{ clt.category.name }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Collection -->

                  <!-- Patterns -->
                  <li class="on" v-if="leftPattns.length > 0">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.patterns')}}</span>
                      <button type="button" class="btn-view btn-patterns">{{ $t('PF.text.patterns')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk type02">
                        <li v-for="pattn in leftPattns">
                          <label class="inp-chk chk-thumb">
                            <input type="checkbox" class="pattern" :id="cateCode+'_pattern_'+ pattn.id.value" @change="[fnSearch('pattern', pattn.text, pattn.id.value, 'Patterns' ,cateCode)]">>
                            <span><i :style="'background-image : url(images/products/'+ cateCode + '_'+ pattn.id.value+'.jpg)'"/>{{ pattn.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Patterns -->

                  <!-- New -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.new')}}</span>
                      <button type="button" class="btn-view btn-new">{{ $t('PF.text.new')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul>
                        <li>
                          <label class="inp-chk type03">
                            <input type="checkbox" :id="cateCode+'_isNew_true'" @click="[fnSearch('isNew','NEW COLORS', 'true', 'New' ,cateCode) ]">
                            <span>{{ $t('PF.text.new_colors')}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- New -->

                  <!-- Installation Method -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">Installation Method</span>
                      <button type="button" class="btn-view btn-install">Installation Method</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;"> <!-- himacsfinder.vue 참고해주세요 -->
                      <ul class="i-chk">
                        <li class="Install" v-for="install in leftInstall" :data-chk="install.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="ins_Method" :id="cateCode+'_ins_Method_'+ install.id.value" @click="[fnSearch('ins_Method',install.text, install.id.value, 'Installation Method', cateCode)]">
                            <span>{{ install.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Installation Method -->

                  <!-- Thickness -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.thickness')}}</span>
                      <button type="button" class="btn-view btn-thickness">{{ $t('PF.text.thickness')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="Thickness" v-for="thick in leftThick" :data-chk="thick.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="thick" :id="cateCode+'_thick_'+thick.id.value" @click="[fnSearch('thick',thick.text, thick.id.value,'Thickness', cateCode)]">
                            <span>{{ thick.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Thickness -->

                  <!-- Wear Layer -->
                  <li class="on" v-if="leftWear.length != 0">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.wear_layer')}}</span>
                      <button type="button" class="btn-view btn-wareLayer">{{ $t('PF.text.wear_layer')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="WearLayer" v-for="wear in leftWear" :data-chk="wear.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="wearLayer" :id="cateCode+'_wearLayer_'+wear.id.value" @click="[fnSearch('wearLayer',wear.text, wear.id.value,'WearLayer', cateCode)]">
                            <span>{{ wear.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Ware Layer -->

                  <!-- Format -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">Format</span>
                      <button type="button" class="btn-view btn-format">Format</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="Format" v-for="format in leftFormat" :data-chk="format.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="format" :id="cateCode+'_format_'+format.id.value" @click="[fnSearch('format',format.text, format.id.value,'Format', cateCode)]">
                            <span>{{ format.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Format -->

                  <!-- Dimension -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.dimension')}}</span>
                      <button type="button" class="btn-view btn-dimension">{{ $t('PF.text.dimension')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="Dimension" v-for="dimen in leftDimen" :data-chk="dimen.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="dimension" :id="cateCode+'_dimension_'+dimen.id.value" @click="[fnSearch('dimension',dimen.text, dimen.id.value,'Dimension', cateCode)]">
                            <span>{{ dimen.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Dimension -->

                  <!--                &lt;!&ndash; Embossing &ndash;&gt;-->
                  <!--                <li class="on">-->
                  <!--                  <div class="tit">-->
                  <!--                    <span class="tt-sub">Embossing</span>-->
                  <!--                    <button type="button" class="btn-view btn-embossing">Embossing</button>-->
                  <!--                  </div>-->

                  <!--                  <div class="i-box type02 cont" style="display:block;">-->
                  <!--                    <ul class="i-chk">-->
                  <!--                      <li class="Embossing" v-for="emboss in leftEmboss" :data-chk="emboss.id.value">-->
                  <!--                        <label class="inp-chk">-->
                  <!--                          <input type="checkbox" class="embossing" :id="cateCode+'_embossing_'+emboss.id.value"-->
                  <!--                                 @click="[fnSearch('embossing',emboss.text, emboss.id.value,'Embossing', cateCode)]">-->
                  <!--                          <span>{{ emboss.text }}</span>-->
                  <!--                        </label>-->
                  <!--                      </li>-->
                  <!--                    </ul>-->
                  <!--                  </div>-->
                  <!--                </li>-->
                  <!--                &lt;!&ndash; Embossing &ndash;&gt;-->

                  <!-- Performance -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">Performance</span>
                      <button type="button" class="btn-view btn-performance">Performance</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="Performance" v-for="perform in leftPerform" :data-chk="perform.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="performance" :id="cateCode+'_performance_'+ perform.id.value"
                                   @click="[fnSearch('performance',perform.text, perform.id.value,'Performance', cateCode)]">
                            <span>{{ perform.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Performance -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->

      </div>
      <!-- //container (hFlorFinder EU) -->
    </div>
    <!-- //content -->

    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import ProductService from '@/server/product.service'
import { getCategoryAttributeList } from '@/server/category'
import _ from '@/common/helper/lodash'
import GnbComponent from '@/components/Layout/GNB'
import { mapState } from 'vuex'

export default {
  name: 'hFlorFinder_EUK',
  props: ['PfContentId', 'PfCateCode', 'filterNm', 'filterVal',
    'filterNm1', 'filterVal1', 'filterNm2', 'filterVal2'],
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  watch: {
    compareArr: function () {
      sessionStorage.setItem('compare' + this.nationCode, JSON.stringify(this.compareArr))
    }
  },
  data () {
    return {
      categoryCode: [], /* 카테고리 코드 */
      categoryName: [], /* 카테고리 이름 */
      categoryId: '', /* 카테고리 아이디 */
      categoryUsId: [], /* 해당 카테고리 ID 값 가져오기. */
      categoryUsName: [],
      nationCode: '', /* 국가 코드 */

      subject: '', /* 제목 */
      brief: '', /* 설명 */
      pfBanImg: '', /* 제목 */
      photoCredit: '', /* 포토크레딧 */

      collectionList: [], /* 컬렉션 리스트 */
      collectionLength: '', /* 해당 컬렉션 길이 체크 (그룹 바이 컬렉션에 박을꺼임) */
      checkCollectionList: [],
      colorList: [],
      contentList: [],
      contentLength: '', /* 해당 컨텐츠 길이 체크 ( All Product에 박을꺼임) */
      value: [],
      pageSize: 16,
      pageIndex: 1,
      orderField: [],
      orderType: [],

      /* left menu */
      searchText: '', /* 검색어 */
      leftCltList: [], /* 컬렉션 리스트 */
      leftTone: [], /* 컬러 리스트 */
      leftPattns: [], /* 패턴 리스트 */
      leftInstall: [], /* 상품타입 리스트 */
      leftThick: [], /* 두께 리스트 */
      leftWear: [], /* 겹 리스트 */
      leftFormat: [], /* Tile,plank 리스트 */
      leftDimen: [], /* 치수 리스트 */
      leftEmboss: [], /* 양각 리스트 */
      leftPerform: [],
      scrollSize: 4,
      /* left menu */

      getSessionContent: [], /* GL : 불러오는 값 */

      clickCollIds: [],
      filterCollList: [],
      sortBy: 'Sort by',
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          }
        ]
      },

      /* compare */
      compareArr: [],
      compareRes: { list: [] },
      compareDetail: [],
      compareList: [],
      compareId: '',
      cateCode: '',
      replaceId: '',

      filterShow: false

    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */

    // if(t.$route.query.compare != null){
    //   t.compareArr = JSON.parse(t.$route.query.compare);
    // }

    if (sessionStorage.getItem('compare' + this.nationCode) != null) {
      t.compareArr = JSON.parse(sessionStorage.getItem('compare' + this.nationCode))
    }

    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },
  async mounted () {
    const _this = this

    _this.nationCode = _this.$countryCode
    _this.cateCode = _this.PfCateCode
    _this.categoryCode = _this.PfCateCode + _this.nationCode

    /* 해당하는 배너 정보를 가져오자! */
    _this.banCode = 'BAN_FLOORING_LUX_CAT' + _this.nationCode

    const banCateRes = await ProductService.getCategoryAttr(_this.banCode)
    _this.brief = banCateRes.data.attribute.brief
    _this.subject = banCateRes.data.attribute.subject
    _this.pfBanImg = banCateRes.data.attribute.pf_image
    _this.photoCredit = banCateRes.data.attribute.photoCredit

    const categoryRes = await ProductService.getCategoryCode(_this.categoryCode)

    _this.categoryId = categoryRes.data.object.id
    _this.categoryName = categoryRes.data.object.name

    const getCateClt = await getCategoryAttributeList(_this.categoryId)

    _this.leftCltList = getCateClt.data.list

    /* Finder 시작 */
    // let dicList = await ProductService.getDictionaryValueList(  <!-- EN,FR,DE로 각각 만들어야 할 경우 -->
    //   ['HFLOR_TONE_LUX'+_this.nationCode,'HFLOR_PATTERN_LUX'+_this.nationCode,'HFLOR_INSTALL_LUX'+_this.nationCode,
    //     'HFLOR_THICK_LUX'+_this.nationCode,'HFLOR_WARE_LUX'+_this.nationCode,'HFLOR_FORMAT'+_this.nationCode,
    //     'HFLOR_DIMEN_LUX'+_this.nationCode, 'HFLOR_EMBOSS' +_this.nationCode, 'HFLOR_PERFORM_LUX' +_this.nationCode]);
    if (_this.nationCode === '_UK') {
      const dicList = await ProductService.getDictionaryValueList(
        ['HFLOR_TONE_LUX_UK', 'HFLOR_PATTERN_LUX_UK', 'HFLOR_INSTALL_LUX_UK',
          'HFLOR_THICK_LUX_UK', 'HFLOR_WARE_LUX_UK', 'HFLOR_FORMAT_UK',
          'HFLOR_DIMEN_LUX_UK', 'HFLOR_EMBOSS_UK', 'HFLOR_PERFORM_LUX_UK'])
      _this.leftTone = dicList.data.dicList[0].list /* HFLOR_TONE_LUX DictionaryValue */
      _this.leftPattns = dicList.data.dicList[1].list /* HFLOR_PATTERN_LUX DictionaryValue */
      _this.leftInstall = dicList.data.dicList[2].list /* HFLOR_INSTALL_LUX DictionaryValue */
      _this.leftThick = dicList.data.dicList[3].list /* HFLOR_THICK_LUX DictionaryValue */
      _this.leftWear = dicList.data.dicList[4].list /* HFLOR_WARE_LUX DictionaryValue */
      _this.leftFormat = dicList.data.dicList[5].list /* HFLOR_FORMAT DictionaryValue */
      _this.leftDimen = dicList.data.dicList[6].list /* HFLOR_DIMEN_LUX DictionaryValue */
      _this.leftEmboss = dicList.data.dicList[7].list /* HFLOR_EMBOSS DictionaryValue */
      _this.leftPerform = dicList.data.dicList[8].list /* HFLOR_PERFORM_LUX DictionaryValue */
    } else {
      const dicList = await ProductService.getDictionaryValueList(
        ['HFLOR_TONE_LUX_EU', 'HFLOR_PATTERN_LUX_EU', 'HFLOR_INSTALL_LUX_EU',
          'HFLOR_THICK_LUX_EU', 'HFLOR_WARE_LUX_EU', 'HFLOR_FORMAT_EU',
          'HFLOR_DIMEN_LUX_EU', 'HFLOR_EMBOSS_EU', 'HFLOR_PERFORM_LUX_EU'])
      _this.leftTone = dicList.data.dicList[0].list /* HFLOR_TONE_LUX DictionaryValue */
      _this.leftPattns = dicList.data.dicList[1].list /* HFLOR_PATTERN_LUX DictionaryValue */
      _this.leftInstall = dicList.data.dicList[2].list /* HFLOR_INSTALL_LUX DictionaryValue */
      _this.leftThick = dicList.data.dicList[3].list /* HFLOR_THICK_LUX DictionaryValue */
      _this.leftWear = dicList.data.dicList[4].list /* HFLOR_WARE_LUX DictionaryValue */
      _this.leftFormat = dicList.data.dicList[5].list /* HFLOR_FORMAT DictionaryValue */
      _this.leftDimen = dicList.data.dicList[6].list /* HFLOR_DIMEN_LUX DictionaryValue */
      _this.leftEmboss = dicList.data.dicList[7].list /* HFLOR_EMBOSS DictionaryValue */
      _this.leftPerform = dicList.data.dicList[8].list /* HFLOR_PERFORM_LUX DictionaryValue */
    }

    _this.categoryUsId.push(_this.categoryId)
    const collectionList = await ProductService.getCategoryAttributeList(categoryRes.data.object.id)

    _this.collectionList = collectionList.data.list
    _this.checkCollectionList = collectionList.data.list
    _this.collectionLength = collectionList.data.list.length

    // URL 조건 1개 param
    const filterNm = this.filterNm
    const filterVal = this.filterVal

    // URL 조건 2개 param
    const filterNm1 = this.filterNm1
    const filterVal1 = this.filterVal1
    const filterNm2 = this.filterNm2
    const filterVal2 = this.filterVal2

    if (filterNm != undefined || filterNm1 != undefined) {
      const hflorFilter = JSON.parse(sessionStorage.getItem('hflorFilter' + this.nationCode))
      if (hflorFilter == undefined && hflorFilter == null) {
        // URL 조건 1개 일 경우
        if (filterNm != undefined) {
          this.findFilter(filterNm, filterVal)

          // URL 조건 2개 일 경우
        } else if (filterNm1 != undefined) {
          this.findFilter(filterNm1, filterVal1)
          this.findFilter(filterNm2, filterVal2)
        }
      } else {
        if (hflorFilter.length === 0) {
          // URL 조건 1개 일 경우
          if (filterNm != undefined) {
            this.findFilter(filterNm, filterVal)

            // URL 조건 2개 일 경우
          } else if (filterNm1 != undefined) {
            this.findFilter(filterNm1, filterVal1)
            this.findFilter(filterNm2, filterVal2)
          }
        } else {
          this.bringFilter()
          await this.getItem(1, 16)
        }
      }
    } else {
      this.bringFilter()
      // 조건 없을 경우
      await this.getItem(1, 16)
    }

    this.$nextTick(function () {
      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      $('.slide-pd01 .slick-container').slick({
        setPosition: 0, // 깨짐방지
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        draggable: false,
        // centerPadding: '35px',
        prevArrow: $('.slide-pd01 .arrow-prev'),
        nextArrow: $('.slide-pd01 .arrow-next'),
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true
            }
          }
        ]
      })

      $(document).on('click', '.select-view .btn-del', function () {
        if ($('.select-view > ul > li').length === 1) {
          $('.select-view > ul').remove()
          $('.btn-all').remove()
        } else {
          $(this).closest('.sel').remove()
        }
      })
      $(document).on('click', '.btn-all button', function () {
        $('.select-view > ul').remove()
        $('.btn-all').remove()
      })
    })
  },
  methods: {
    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },
    findFilter: function (filterNm, filterVal) {
      const _this = this

      if (filterNm === 'collection') {
        $('#' + filterVal).click()
      } else if (filterNm === 'isNew') {
        $('#' + _this.cateCode + '_isNew_true').click()
      } else { /* pattern, install(==prdType), thick, wearLayer, dimension */
        $('#' + _this.cateCode + '_' + filterNm + '_' + filterVal).click()
      }
    },
    bringFilter: function () {
      const _this = this
      // 뒤로가기 세션
      const hflorFilter = JSON.parse(sessionStorage.getItem('hflorFilter' + this.nationCode))
      if (hflorFilter !== undefined && hflorFilter != null) {
        for (let i = 0; i < hflorFilter.length; i++) {
          if (hflorFilter[i].subject === 'Collection') {
            $('#' + hflorFilter[i].code).click()
          } else {
            $('#' + _this.cateCode + '_' + hflorFilter[i].subject + '_' + hflorFilter[i].id).click()
          }
        }
      }
    },
    fnSearch: async function (searchCd, selectSeach, value, type, cateType) {
      const _this = this
      _this.pageIndex = 1
      if ($('#' + cateType + '_' + searchCd + '_' + value).is(':checked')) {
        if (searchCd === 'tone') {
          _this.dictionaryCondition.list[0].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'pattern') {
          _this.dictionaryCondition.list[1].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'isNew') {
          _this.dictionaryCondition.list[2].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'ins_Method') {
          _this.dictionaryCondition.list[3].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'thick') {
          _this.dictionaryCondition.list[4].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'wearLayer') {
          _this.dictionaryCondition.list[5].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'format') {
          _this.dictionaryCondition.list[6].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'dimension') {
          _this.dictionaryCondition.list[7].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'embossing') {
          _this.dictionaryCondition.list[8].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'performance') {
          _this.dictionaryCondition.list[9].values.push({ id: searchCd, value: value })
        }
        _this.colorList.push({ subject: searchCd, name: selectSeach, id: value, type: type })
      } else {
        _.remove(_this.colorList, function (v) {
          return v.subject === searchCd && v.id === value
        })
        _.remove(_this.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value === value && v.id === searchCd
          })
        })
      }
      // }
      await _this.getItem(1, 16)
    },

    textSearch (event) {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/delete.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/delete.png) center center' })
      }
      this.searchText = event.target.value
      this.getItem(1, 16)
    },

    textSearchDel () {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/ico_search.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/ico_search.png) center center ' })
      }
      this.searchText = ''
      if (this.isMobile()) {
        $('#textSearchM').val('')
      } else {
        $('#textSearch').val('')
      }
      this.getItem(1, 16)
    },

    fnCateSearch: async function (name, id, type, code) {
      const t = this
      t.pageIndex = 1
      if (name == 'CateAll') {
        if ($('#CateAll').is(':checked')) {
          $('.Collection').prop('checked', false)
        }
        t.categoryUsId = []
        _.remove(t.colorList, function (c) {
          return c.subject == 'Collection'
        })
        t.categoryUsId.push(t.categoryId)
        t.filterCollList = []
        t.clickCollIds = []
        t.checkCollectionList = t.collectionList
        t.collectionLength = t.checkCollectionList.length
      } else {
        if (type == 'click' && $('#' + code).is(':checked') == false) {
          $('#' + code).prop('checked', true)
        } else if (type == 'click' && $('#' + code).is(':checked') == true) {
          $('#' + code).prop('checked', false)
        }
        if ($('#' + code).is(':checked')) {
          // let checkBoxTotal = $('input:checkbox[class=Collection]').length;
          // let checkedCount = $('input:checkbox[class=Collection]:checked').length;
          // if(checkBoxTotal == checkedCount && checkBoxTotal > 1){
          //   _.remove(t.colorList, function(v) {
          //     return v.subject == 'Collection';
          //   })
          //   t.categoryUsId = [];
          //   t.categoryUsId.push(t.categoryId);
          //   $("#CateAll").prop("checked", true);
          //   $(".Collection").prop("checked", false);
          // } else if (checkedCount > 0){
          //   $("#CateAll").prop("checked", false);
          if (t.categoryUsId[0] == t.categoryId) {
            t.categoryUsId = []
          }
          t.categoryUsId.push(id)
          t.categoryUsName.push(name)
          t.colorList.push({ subject: 'Collection', name: name, id: id, code: code })
          // 필터링된 id
          this.clickCollIds.push(id)
          t.filtering = _.filter(t.collectionList, function (o, idx) {
            return o.category.id === id
          })
          // 첫검색할땐 필터링된 배열 리셋
          if (this.clickCollIds.length === 1) {
            t.filterCollList = []
            t.filterCollList.push(t.filtering[0])
          } else {
            t.filterCollList.push(t.filtering[0])
          }
          t.checkCollectionList = t.filterCollList
          t.collectionLength = t.checkCollectionList.length
          // } else {
          //   $("#CateAll").prop("checked", true);
          // }
        } else {
          const checkedCount = $('input:checkbox[class=Collection]:checked').length
          if (checkedCount == 0) {
            $('#CateAll').prop('checked', true)
          }
          _.remove(t.categoryUsId, function (c) {
            return c == id
          })
          _.remove(t.colorList, function (v) {
            return v.subject == 'Collection' && v.id == id
          })

          t.clickCollIds = _.filter(t.clickCollIds, function (o) {
            return o !== id
          })
          if (t.clickCollIds.length === 0) {
            t.checkCollectionList = t.collectionList
          } else {
            // 필터링 된 collectionList에서 선택한 collection 값 삭제
            t.checkCollectionList = _.filter(t.checkCollectionList, function (o) {
              return o.category.id !== id
            })
          }
          t.collectionLength = t.checkCollectionList.length
        }
      }
      if ($('#btn-tab01').attr('class') != 'btn-tab on') {
        $('#btn-tab01').click()
      }
      await t.getItem(1, 16)
    },
    colorDelete: async function (subject, name, id, code) {
      const t = this

      _.remove(t.colorList, function (v) {
        return v.subject == subject && v.id == id
      })
      if (subject === 'Collection') {
        $('#' + code).prop('checked', false)
        const checkedCount = $('input:checkbox[class=Collection]:checked').length
        if (checkedCount == 0) {
          $('#CateAll').prop('checked', true)
        }
        _.remove(t.categoryUsId, function (c) {
          return c == id
        })

        t.clickCollIds = _.filter(t.clickCollIds, function (o) {
          return o !== id
        })
        if (t.clickCollIds.length === 0) {
          t.checkCollectionList = t.collectionList
        } else {
          // 필터링 된 collectionList에서 선택한 collection 값 삭제
          t.checkCollectionList = _.filter(t.checkCollectionList, function (o) {
            return o.category.id !== id
          })
        }
        t.collectionLength = t.checkCollectionList.length
      } else {
        $('#' + t.cateCode + '_' + subject + '_' + id).prop('checked', false)
        const checkedCount = $('input:checkbox[class=' + subject + ']:checked').length
        if (checkedCount == 0) {
          $('#' + subject + 'All').prop('checked', true)
        }
        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == id
          })
        })
      }
      await t.getItem(1, 16)
    },
    async getItem (pageIndex, pageSize) {
      const t = this

      if (t.colorList !== undefined) {
        sessionStorage.setItem('hflorFilter' + this.nationCode, JSON.stringify(t.colorList))
      }

      if (t.categoryUsId == '') {
        t.categoryUsId.push(t.categoryId)
      }
      t.pageIndex = 1
      t.pageSize = pageSize

      const res = await ProductService.getDictionaryCondition(t.categoryUsId, t.dictionaryCondition, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType, ['title', 'search_text'])
      t.contentList = res.data.list
      t.contentLength = res.data.totalCount
    },
    async clearAll () {
      const _this = this
      _this.categoryUsId = []
      _this.filterCollList = []
      _this.colorList = []
      _this.clickCollIds = []
      _this.dictionaryCondition.list[0].values = []
      _this.dictionaryCondition.list[1].values = []
      _this.dictionaryCondition.list[2].values = []
      _this.dictionaryCondition.list[3].values = []
      _this.dictionaryCondition.list[4].values = []
      _this.dictionaryCondition.list[5].values = []
      _this.dictionaryCondition.list[6].values = []
      _this.dictionaryCondition.list[7].values = []
      _this.dictionaryCondition.list[8].values = []
      _this.dictionaryCondition.list[9].values = []
      _this.categoryUsId = []
      _this.categoryUsId.push(_this.categoryId)
      _this.checkCollectionList = _this.collectionList
      _this.collectionLength = _this.checkCollectionList.length
      $('input:checkbox').prop('checked', false)
      $("input:checkbox[id*='All']").prop('checked', true)
      await _this.getItem(1, 16)
    },
    async sortByChange (text, type, field) {
      const _this = this
      _this.sortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.getItem(1, 16)
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    async loadMore (index) {
      const t = this

      t.pageIndex += 1
      t.pageSize = 16

      const res = await ProductService.getDictionaryCondition(t.categoryUsId, t.dictionaryCondition, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType, ['title', 'search_text'])
      for (let i = 0; i < res.data.list.length; i++) {
        t.contentList.push(res.data.list[i])
      }
      t.contentLength = res.data.totalCount
    },
    goLink (type, url) {
      if (type === undefined) {
        location.href = 'products/hflor/' + this.PfCateCode
      } else if (type === 'book') {
        window.open(url)
      }
    },
    clickCate (code) {
      // this.$router.push({path: '/hflor/hFlorFinder/'+ code, query: { compare: JSON.stringify(this.compareArr)}}).catch(()=>{});
      this.$router.push({ path: '/hflor/hFlorFinder/' + code }).catch(() => {})
    },
    makePDF () {
      html2canvas($('#pdf_wrap')[0], {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight
      }).then(function (canvas) {
        var filename = 'HFLOR_COMPARE_' + Date.now() + '.pdf'
        var doc = new jsPDF('p', 'mm', 'a4')
        var imgData = canvas.toDataURL('image/png')
        var imgWidth = 210
        var pageHeight = 295
        var imgHeight = canvas.height * imgWidth / canvas.width
        var heightLeft = imgHeight
        var position = 0
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
        window.scrollTo(0, 0)
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(filename)
      })
    },
    addCompare (id) {
      const _this = this
      if (_this.compareArr.length < 5) {
      } else {
        _this.replaceId = id
        $('#' + id).prop('checked', false)
        this.removeCompare(id)
        LayerPopup.open('layer-alert')
        return false
      }
    },
    removeCompare (id) {
      const _this = this
      if (_this.compareList != null) {
        const idx = _this.compareList.findIndex(function (v) {
          return v.content.id === id
        })
        _this.compareArr.splice(idx, 1)
        _this.compareList.splice(idx, 1)
      }
    },
    async getCompareList () {
      const _this = this
      const target = document.getElementById('replace')
      _this.compareList = []
      _this.compareRes = _this.compareArr
      LayerPopup.close() // alert 클로즈
      _this.compareDetail = await ProductService.getContentByIds(_this.compareRes)
      _this.compareList = _this.compareDetail.data
      LayerPopup.open('layer-compare')
      if (target.disabled) {
        target.disabled = false
      }
      return false
    },
    async replace () {
      const _this = this
      const target = document.getElementById('replace')
      target.disabled = true
      if (_this.compareArr != null) {
        _this.compareArr.splice(0, 1)
        _this.compareList.splice(0, 1)
      }
      _this.compareArr.push(_this.replaceId)
      _this.getCompareList()
    },
    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },

    alertAndGoLogin () {
      alert(this.$t('PCD.alert.loginLike'))
      this.$utils.goLogin()
    },

    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode != '_US') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (o.nationCode == t.nationCode) {
              t.getSessionContent.push(o.id)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    getThick (item) {
      const arr = new Set(item.map(a => a.thickText))
      const thick = [...arr]
      return thick
    },

    getWearLayer (item) {
      const arr = new Set(item.map(a => a.wearLayerText))
      const wearLayer = [...arr]
      return wearLayer
    },

    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    clickFilter () {
      const element = document.getElementById('filterSearch')

      if (this.filterShow === false) {
        element.style.cssText = 'z-index: 99999999'
      }

      if (this.filterShow === true) {
        element.style.cssText = 'display : none'
      }

      return this.filterShow = !this.filterShow
    }

    // makeDataLayerForm(data) {
    //   const cat = data.parents[1].code
    //   let prdVariant = ''
    //   let prdType = ''
    //   let prdPrice = ''
    //   let hueText = ''
    //   if (_.includes(cat, 'FLOOR_LUX') || _.includes(cat, 'FLOOR_HET')) {
    //     prdType = data.attribute.prdTypeText.join('|')
    //   }
    //
    //   prdVariant = _.join(_.flatMap(data.attribute.price, function(v) {
    //     return v.size
    //   }), '|')
    //
    //   prdPrice = _.join(_.flatMap(data.attribute.price, function(v) {
    //     return v.price
    //   }), '|')
    //
    //   if (!_.isUndefined(data.attribute.hueText)) {
    //     hueText = data.attribute.hueText
    //   }
    //
    //   return JSON.stringify({
    //     ecom_prd_id: data.attribute.code,
    //     ecom_prd_name: data.content.title,
    //     ecom_prd_brand: data.parents[1].name,
    //     ecom_prd_category: data.parents[2].name, //HFLOR - Luxury, homo, hete, sports..
    //     ecom_prd_variant: prdVariant, //price over 1 items
    //     ecom_prd_type: prdType, // HFLOR only over 1 items
    //     ecom_prd_collection: data.parents[0].name,
    //     ecom_prd_color: hueText,
    //     ecom_prd_price: prdPrice, //price over 1 items
    //     ecom_currency_code: 'USD'
    //   })
    // }
  }
}
</script>
