/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  imgLibCnt: 0,
  likeCnt: 0
}
