<template>
  <div id="wrap" class="">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content prod-dt-cate pcd_content">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">
            {{ $t('PCD.text.home') }}
          </li>
          <li>{{ $t('PCD.text.products') }}</li>
          <li>{{ $t(`PCD.text.${convertToCamelCase}`) }}</li>
          <li v-if="isSubPage">{{ $t(`PCD.text.sub.${$route.meta.type.toLowerCase()}`) }}</li>
          <li v-if="isAboutPage">{{ $t(`PCD.text.about.${$route.meta.type.toLowerCase()}`) }}</li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container (PCD) -->
      <div :class="pcdContainerCssClass">
        <div class="visual visual-detail" v-if="pcdContentAttribute.main_use_yn === 'true'">
          <div class="visual-image full_width center"
               v-bind:style="{ backgroundImage: `url('${$imagePath}${pcdContentAttribute.main_img}')` }">
            <div class="inner_width">
              <div class="txt-box">
                <h1>
                  <div class="title">
                    <span v-if="visualImageLoadError" class="txt">{{ pcdContentAttribute.main_title }}</span>
                    <img v-if="!visualImageLoadError"
                         :src="$imagePath +'/2024/images/main/logo/' + $route.meta.type.toUpperCase() + '.svg'"
                         alt="about product" class="visual_logo" @error="visualImageLoadError=true"
                         @load="visualImageLoadError=false"/>
                    <!-- 이미지일때 -->
                    <!-- <img src="/images/products/pcd_viatera_logo.svg" alt="viatera" class="pcd_tit_logo"> -->
                    <!--$imagePath +'/2024/images/main/logo/' + banner.attribute.MAIN_VISUAL_PRODUCTText + '.png'-->
                  </div>
                  <p class="txt-02">{{ pcdContentAttribute.main_desc }}</p>
                </h1>
              </div>
            </div>
          </div>
        </div>

        <!-- 본문 시작 -->
        <!-- video -->
        <div class="video-box full_width" v-if="pcdContentAttribute.intrd_use_yn === 'true'">
          <div class="inner_width">
            <div class="row">
              <div class="video-area">
                <div class="video">
                  <video v-if="(movie && !youtube) || (movie && youtube)"
                         controlsList="nodownload"
                         oncontextmenu="return false"
                         autoplay="autoplay"
                         muted="muted"
                         controls
                         :src="movie"
                         title="YouTube video player"
                  ></video>
                  <!-- 23-09-20 youtube 기능 추가 무비, 유튜브 두개 다 있을 경우 무비 노출-->
                  <iframe v-if="!movie && youtube" autoplay="autoplay" muted="muted" controls :src="youtube"
                          title="YouTube video player"/>
                  <img v-if="!movie && !youtube" :src="$imagePath + pcdContentAttribute.intrd_img" class="etc_img"/>
                </div>
              </div>
              <div class="txt_area">
                <p class="con_title ctt ctt-t2">{{ pcdContentAttribute.intrd_title }}</p>
                <h2 class="con_subject tt-1">{{ pcdContentAttribute.intrd_sub_title }}</h2>
                <p class="ctt ctt-t3">{{ pcdContentAttribute.intrd_desc }}</p>
                <div class="btn_area">
                  <button type="button" class="button h44 btn_view_prod_category" @click="goToPfPage()">
                    <span class="txt">See all Designs</span>
                    <span class="icon icon_arrow_right"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //video -->

        <!-- WHAT'S NEW slider -->
        <div class="banner_box whatsnew_section" v-if="pcdContentAttribute.mb_use_yn === 'true'">
          <div class="full_width">
            <div class="inner_width">
              <div class="content_tit_area">
                <h2 class="con_title tt-1">{{ pcdContentAttribute.mb_title }}</h2>
              </div>
              <div class="whatsnew_slider_wrap">
                <div class="whatsnew_slider">
                  <div class="item" v-for="(middleItem, index) in pcdMiddleBannerData">
                    <div class="img_wrap">
                      <img :src="$imagePath + middleItem.image" :alt="middleItem.title">
                    </div>
                    <div class="over_txt_box">
                      <h2 class="title tt-2">{{ middleItem.title }}</h2>
                      <p class="txt-01">{{ middleItem.description }}</p>
                      <a :href="middleItem.url" v-if="!isEmpty(middleItem.url)" class="btn-more" :target="pcdMenuType == 'HFLOR' ? '_blank' : '_self'">
                        <span>View more</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //WHAT'S NEW slider -->

        <!-- Collection -->
        <div class="collection_section full_width" v-if="pcdContentAttribute.collection_use_yn === 'true'">
          <div class="inner_width">
            <div class="content_tit_area">
              <h2 class="con_title tt-1">{{ pcdContentAttribute.collection_title }}</h2>
              <div class="more" @click="goToPfPage()">
                <!--            <a :href="`products${pcdMenuPath}/${pcdMenuType.toLowerCase()}-finder/`" class="view_more">-->
                <a href="javascript:void(0);" class="view_more">
                  <span>View more</span>
                  <span class="icon"></span>
                </a>
              </div>
            </div>
            <div class="collection_content">
              <ul>
                <li v-for="(coll, index) in collectionPageList" v-bind:key="index">
                  <div class="tit_area">
                    <span class="tag h30 new" v-if="coll.categoryAttribute.new_yn === 'true'">new</span>
                    <span class="tag h30 best" v-if="coll.categoryAttribute.best_yn === 'true'">best</span>
                    <template v-if="coll.categoryAttribute.title_name">
                      <span class="collection_title tt-24">{{ coll.categoryAttribute.title_name }}</span>
                    </template>
                    <template v-else>
                      <span class="collection_title tt-24">{{ coll.category.name }} {{
                          $t("PCD.text.collection")
                        }}</span>
                    </template>
                  </div>
                  <div class="thumb_area">
                    <div class="thumb_list">
                      <ul>
                        <li v-for="(coll2, index2) in collectionschildList[coll.category.id]" v-bind:key="index2">
                          <a
                            href="javascript:void(0);" style="cursor:pointer;">
                            <template v-if="!isEmpty(coll2.orgContent)">
                              <img :src="$imagePath+coll2.orgContent.cover" @click="goToPdPage(coll, coll2)"/>
                            </template>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn_area">
                      <button type="button" class="btn_collection_plus" :data-cate-id="coll.category.id"
                              v-on:click="showCollectionContent">
                        <span class="icon icon_plus"></span>
                      </button>
                    </div>
                    <div class="collection_pop" v-if="collectionPopCateId === coll.category.id">
                      <div class="col_pop_tit_area">
                        <span class="title">Browse Colors</span>
                        <button type="button" class="btn_collection_close" v-on:click="hideCollectionContent">
                          <span class="icon icon_close"></span>
                        </button>
                      </div>
                      <div class="col_pop_con_area">
                        <div class="collection_list">
                          <ul>
                            <li v-for="(coll2, index2) in collectionschildList[coll.category.id]" v-bind:key="index2">
                              <div class="img_wrap">
                                <a href="javascript:void(0);" style="cursor:pointer;">
                                  <template v-if="!isEmpty(coll2.orgContent)">
                                    <img :src="$imagePath+coll2.orgContent.cover" @click="goToPdPage(coll, coll2)"/>
                                  </template>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="img-box">
                    <a @click="goToFinder(coll)">
                      <img :src="$imagePath + coll.categoryAttribute.image"
                           :alt="`${coll.categoryAttribute.title_name}`"/>
                    </a>
                  </div>
                  <div class="txt-box">
                    <p class="comt ctt ctt-t4">{{ coll.categoryAttribute.Des }}</p>
                  </div>
                  <div class="btn_area">
                    <button type="button" class="button h44 btn_explore_collection" @click="goToFinder(coll)">
                      <span class="txt">{{ $t("PCD.button.explore_collection") }}</span>
                      <span class="icon icon_arrow_right"></span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="btn_area" v-if="isVisibleCollectionLoadMore()">
              <button type="button" class="btn_load_more" @click="loadMore()">
                <span class="txt">Load more</span>
                <span class="icon icon_plus"></span>
              </button>
            </div>
            <div class="height-70" v-else></div>
          </div>
        </div>
        <!-- //Collection -->

        <!-- pcd why section  -->
        <div class="why_section" v-if="pcdContentAttribute.template_use_yn === 'true'">
          <div class="full_width">
            <div class="inner_width">
              <div class="content_tit_area">
                <h2 class="con_title tt-1">{{ pcdContentAttribute.template_title }}</h2>
              </div>
            </div>
          </div>
          <!-- 이미지 있을때만 노출 -->
          <div class="why_top_bg full_width" v-if="pcdTemplateFieldData.image.length">
            <div class="inner_width">
              <template v-for="(images, index) in Object.keys(pcdTemplateFieldData.image)">
                <div :class="`why_row col_0${imageKey}`"
                     v-for="(imageKey, index2) in Object.keys(pcdTemplateFieldData.image[index])">
                  <div class="why_item" v-for="(imageItem, index3) in pcdTemplateFieldData.image[index][imageKey]">
                    <!-- 20240701 기획요청 - 3개짜리 아이템일때 [one_third] 클래스 추가 -->
                    <div class="img">
                      <img :src="$imagePath + imageItem.img" :alt="imageItem.title"/>
                    </div>
                    <div class="description_box">
                      <p class="title tt-3">{{ imageItem.title }}</p>
                      <p class="txt ctt ctt-t3">{{ imageItem.desc }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- 퀄리티 콘텐츠가 있을때만 노출 -->
          <div class="w-con full_width" v-if="pcdTemplateFieldData.icon.length">
            <div class="inner_width">
              <div class="row combo_area">
                <template v-for="(iconItem, index) in pcdTemplateFieldData.icon">
                  <div class="content_item" v-if="iconItem.img">
                    <div class="icon_box">
                      <img :src="$imagePath + iconItem.img" :alt="iconItem.title">
                    </div>
                    <div class="description_box">
                      <p class="title tt-30">{{ iconItem.title }}</p>
                      <p class="txt ctt ctt-t3">{{ iconItem.desc }}</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- certification -->
        <div class="certification_section full_width" v-if="pcdContentAttribute.cert_use_yn === 'true'">
          <div class="inner_width">
            <div class="content_tit_area">
              <h2 class="con_title tt-1">{{ pcdContentAttribute.cert_title }}</h2>
            </div>
            <div class="certi_group">
              <ul>
                <li v-for="(certItem, index) in pcdCertificationData">
                  <div class="item">
                    <img :src="$imagePath + certItem.image" :alt="certItem.description"/>
                  </div>
                </li>
              </ul>
              <div class="btn_area">
                <template v-if="pcdCertificationData.length && checkCertificationDescription('description')">
                  <button type="button" class="btn_learn_more" @click="openModalCerti()">
                    <span class="txt">Learn more</span>
                    <span class="icon icon_arrow_right"></span>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- inspiration gallery  -->
        <div class="inspiration_section full_width" v-if="pcdContentAttribute.inpg_use_yn === 'true'">
          <div class="inner_width">
            <div class="content_tit_area">
              <h2 class="con_title tt-1">{{ pcdContentAttribute.inpg_title }}</h2>
              <div class="more">
                <a :href="`inspiration/gallery/${getPcdTypeName(pcdMenuType)}/product_cate`" class="view_more">
                  <span>View more</span>
                  <span class="icon"></span>
                </a>
              </div>
            </div>
            <!-- gallery -->
            <div class="gallery">
              <div class="grid-box">
                <ul class="ins_grid">
                  <li class="gal-grid-cont" v-for="(inspirationItem, index) in pcdInspirationList">
                    <button type="button" class="g-box"
                            @click="gotolink(`/${countryCode.toLowerCase()}/inspiration/gallery-detail?contentId=${inspirationItem.content.id}`)">
                      <div class="img-box">
                        <img
                          :src="$imagePath + inspirationItem.attribute.mainThumbnail"
                          :alt="inspirationItem.content.title"
                        />
                      </div>
                      <div class="txt-box">
                        <p class="txt-cate">
                          <template v-for="(productItem, productIndex) in inspirationItem.attribute.product_cateText">
                            <span v-html="productItem"/>
                          </template>
                        </p>
                        <p class="content_title">{{ inspirationItem.content.title }}</p>
                        <div class="hashtag_group">
                          <span class="hashtag"
                                v-for="(tagItem, tagIndex) in inspirationItem.attribute.applicationList">#{{
                              tagItem.text
                            }}</span>
                        </div>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <!-- //gallery -->
          </div>
        </div>

        <!-- Product pcd slider -->
        <div class="related_section" v-if="featPrds.length">
          <div class="full_width">
            <div class="inner_width">
              <div class="content_tit_area">
                <h2 class="con_title tt-1">related products</h2>
              </div>
              <div class="slider-related flex pcd_rel_prod_slider" v-show="featShow === true">
                <div class="item" v-for="(feat, index) in featPrds" v-bind:key="index">
                  <div class="over-box" :data-ec-product="makeDataLayerForm(feat)">
                    <div @click="goToPcdPage(feat)" style="cursor: pointer;">
                      <div class="over">
                        <img :src="$imagePath + feat.attribute.main_img" tabindex="0" :alt="feat.attribute.main_title"/>
                      </div>
                    </div>
                    <div class="view">
                      <!--<a @click="openSampleOrder(feat.orgContent.id)" class="icon-cart-shadow"  tabindex="0" v-if="nationCode === '_US'">{{ $t('PCD.text.cart')}}</a>-->
                    </div>
                  </div>
                  <div class="info">
                    <ul>
                      <li class="txt-02">
                        <span>{{ feat.attribute.main_title }}</span>
                      </li>
                      <li class="txt-03">{{ feat.attribute.main_desc }}</li>
                    </ul>
                    <div class="more_btn_area" @click="goToPcdPage(feat)" style="cursor: pointer;">
                  <span class="view_more">
                    <span class="txt">view more</span>
                    <span class="icon icon_arrow"></span>
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //Product pcd slider -->

        <!-- S : popup : certification -->
        <div class="layer-view layer-pop popup-w1200 modal-certification"
             v-if="pcdContentAttribute.cert_use_yn === 'true'">
          <div class="popup pop-close">
            <div class="scr-y">
              <h3 class="popup-title p-ui">Certification Description</h3>
              <h3 class="popup-title m-ui">Certification <br> Description</h3>
              <div class="popup-body">
                <div class="certi_pop_con">
                  <ul>
                    <li v-for="(certItem, index) in pcdCertificationData">
                      <div class="row" v-if="certItem.description">
                        <div class="img_wrap">
                          <img :src="$imagePath + certItem.image" :alt="certItem.description"/>
                        </div>
                        <div class="txt_wrap">
                          <div class="tit_box">{{ certItem.title }}</div>
                          <div class="txt_box">{{ certItem.description }}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button type="button" class="btn-close" @click="closeModalCerti()">close</button>
          </div>
        </div>
        <!-- E : popup : certification -->
      </div>
      <!-- //container (himacs PCD) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import { $utils } from '@/common/helper'
import _ from '@/common/helper/lodash'
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import categoryService from '@/server/category.service'
import InspirationService from '@/server/inspiration.service'
import ProductService from '@/server/product.service.js'
import { getEcomPrdData } from "@/views/products/js/product-detail"
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { mapState } from 'vuex'
/* import Breadcrumb from "@/components/Breadcrumb.vue"; */

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    $utils () {
      return $utils
    },
    ...mapState({
      authenticated: state => state.auth.authenticated
    }),
    basePath () {
      const pathArray = this.$route.path.split('/')
      console.log('pathArray:', pathArray)
      return pathArray[1] ? pathArray[1].toLowerCase() : ''
    },
    convertToCamelCase () {
      return this.basePath.replace(/-/g, '').split('/')[0].toLowerCase()
    },
    isSubPage () {
      const currentPath = this.$route.path
      return this.SubPaths.includes(currentPath)
    },
    isAboutPage () {
      const currentPath = this.$route.path
      console.log('Current Path:', currentPath)// 현재 경로 콘솔 로그
      console.log('Is About Page:', this.aboutPaths.includes(currentPath)) // 매칭 여부 콘솔 로그
      // 현재 경로가 aboutPaths 배열에 포함된 경우 true 반환
      return this.aboutPaths.includes(currentPath)
    }

  },
  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  data () {
    return {
      aboutPaths: ['/hflor/about',
        '/himacs-solid-surface',
        '/viatera-quartz-surface',
        '/teracanto-porcelain-surface',
        '/benif-interior-film',
        '/vizuon-sign-graphic',
        '/deco-foil',
        '/exterior-foil'],
      SubPaths: ['/hflor/lvt',
        '/hflor/looselay',
        '/hflor/spc',
        '/hflor/homogeneous',
        '/hflor/heterogeneous',
        '/hflor/specialty',
        '/vizuon-sign-graphic/vehicle',
        '/vizuon-sign-graphic/signs-and-displays',
        '/vizuon-sign-graphic/illuminated-sign',
        '/vizuon-sign-graphic/architecture',
        '/vizuon-sign-graphic/eco-solutions',
        '/vizuon-sign-graphic/corporate-branding'],

      collectionPopCateId: 0,

      pcdTitle: '',
      pcdContent: [],
      pcdContentAttribute: {},
      pcdTemplateData: {},
      pcdTemplateFieldData: { image: [], icon: [] },
      pcdCertificationData: [],
      pcdMiddleBannerData: [],
      pcdInspirationList: [],
      pcdFeatPrdList: [],
      pcdContainerCssClass: '',
      pcdMenuTitle: '',
      pcdMenuType: '',
      pcdMenuPath: '',

      visualImageLoadError: false,
      isVisibleLoadMoreButton: true,
      bannerList: [{ attribute: '' }, { attribute: '' }],
      youtube: '',
      subject: '',
      subject2: '',
      brief: '',
      movie: '',
      collectionList: [],
      featPrds: [],
      featPrdsconts: [],
      certifications: [],
      getfeaturesList: [],
      countryOfOrigin: [],
      countryCode: '',
      countryCodeSub: '',

      collectionPageList: [],
      collectionschildList: [],
      collectionsThumChildList: [],
      videoFlag: '',

      nationCode: '',

      getSessionContent: [], /* GL : 불러오는 값 */

      totalPage: '',
      contentLength: '',
      lastPage: '',
      pageIndex: 1,
      pageSize: 4,
      totalCount: 0,

      featShow: false,

      photoCredit: '',
      photoCredit2: '',

      /* EU sustainability TAB DATA */
      sustainability_de: '', // Download Sustainability Brochure 사용
      sustainability_en: '', // Download Sustainability Brochure 사용
      sustainability_es: '', // Download Sustainability Brochure 사용
      sustainability_fr: '', // Download Sustainability Brochure 사용
      sustainability_it: '', // Download Sustainability Brochure 사용
      sustainability_uk: '', // Download Sustainability Brochure 사용
      sustainability_movie: '', // sustainability TAB 동영상 파일
      sustainability_tube: '', // sustainability TAB Youtube Link
      /* END => EU sustainability TAB DATA */

      /* EU collection brochure DATA */
      bro_english: '',
      bro_dutch: '',
      bro_french: '',
      bro_german: '',
      bro_italian: '',
      bro_spanish: '',
      bro_uk: '',
      /* END => EU collection brochure DATA */
      about: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
    if (!_.isEqual(t.nationCode, '_US')) { /* 테스트중 / 개발 끝나면 GL 로 변경해야 함. */
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (_.isEqual(o.nationCode, t.nationCode)) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  async mounted () {
    this.pcdMenuPath = this.$route.path
    this.pcdMenuTitle = this.$route.meta.mainTitle
    this.pcdMenuType = this.$route.meta.parentType ? this.$route.meta.parentType : this.$route.meta.type
    this.pcdContainerCssClass = `container prod-dt-view pcd_container ${this.pcdMenuType.toLowerCase()}-pcd`

    this.HFLOR = ['LVT', 'LOS', 'SPC', 'HOMO', 'HET', 'SPT', 'HFLOR']
    this.VIZUON = ['VEH', 'SD', 'ILLUM', 'ARCH', 'CORP', 'VIZUON_A', 'ECO']
    this.urlPart = {
      FLOOR_LUX_DECOLEY: 'looselay',
      FLOOR_LOS: 'looselay',
      FLOOR_LUX: 'lvt-flooring',
      FLOOR_HOM: 'homogeneous-sheet',
      FLOOR_HET: 'heterogeneous-sheet',
      FLOOR_SPC: 'spc-click',
      FLOOR_SPT: 'specialty',
      VIZUON_VT: 'vehicles-and-transportation',
      VIZUON_SD: 'signs-and-displays',
      VIZUON_IS: 'illuminated-sign',
      VIZUON_AR: 'architecture',
      VIZUON_ES: 'eco-solutions',
      VIZUON_CB: 'corporate-branding',
      LVT: 'lvt-flooring',
      LOS: 'looselay',
      SPC: 'spc-click',
      HOMO: 'homogeneous-sheet',
      HET: 'heterogeneous-sheet',
      SPT: 'specialty',
      VIZUON_A: 'vehicles-and-transportation',
      VEH: 'vehicles-and-transportation',
      SD: 'signs-and-displays',
      ILLUM: 'illuminated-sign',
      ARCH: 'architecture',
      ECO: 'eco-solutions',
      CORP: 'corporate-branding'
    }
    /* about gnb tab params */
    this.about = this.$route.params.about
    this.countryCode = this.nationCode.substring(1)

    if (!_.isUndefined(this.$route.meta.parentType)) {
      this.pcdTitle = this.$route.meta.type
    }

    const _this = this
    const t = this

    /* codeList */
    let codes = []
    /* ==================================================== [ US 컨텐츠 ] ======================================================== */

    const contentTypeCode = this.$route.meta.parentType ? this.$route.meta.type : t.pcdMenuType
    const contentCategoryCode = `${t.countryCode}_PCD_${contentTypeCode}`
    await categoryService.getCategoryCode(contentCategoryCode)
      .then(async (res) => {
        const categoryId = res.data.object.id
        const content = await ProductService.getContentSearchList(categoryId)
        const contentData = content.data[0]
        const { id } = contentData

        _this.pcdContent = await ProductService.getContent(id)
        _this.pcdContentAttribute = _this.pcdContent.data.attribute
        _this.pcdCertificationData = !_.isUndefined(_this.pcdContentAttribute.cert_image_data)
          ? JSON.parse(_this.pcdContentAttribute.cert_image_data)
          : []
        _this.pcdMiddleBannerData = !_.isUndefined(_this.pcdContentAttribute.mb_pcd_data)
          ? JSON.parse(_this.pcdContentAttribute.mb_pcd_data)
          : []
        _this.pcdTemplateData = !_.isUndefined(_this.pcdContentAttribute.template_pcd_data)
          ? JSON.parse(_this.pcdContentAttribute.template_pcd_data)
          : []

        if (!_.isUndefined(_this.pcdContentAttribute.template_pcd_data)) {
          if (!_.isUndefined(_this.pcdTemplateData.fieldData)) {
            _this.pcdTemplateFieldData = _this.pcdTemplateData.fieldData
          }
          _this.pcdTemplateFieldData.image = Object.entries(
            _this.pcdTemplateFieldData.image.reduce((acc, item) => {
              // id의 첫번째 자리
              const key = item.id.toString()[0]
              // 키가 없으면 새로운 배열 생성
              acc[key] = acc[key] || []
              acc[key].push(item)
              return acc
            }, {})
            // 키-값 쌍을 객체로 변환
          ).map(([key, value]) => ({ [key]: value }))
        }
      })

    const colorId = this.getColorId()
    codes = [
      `${t.countryCode}_PCD`,
      `FEAT_${t.pcdMenuType}${t.nationCode}`,
      colorId,
      `${t.countryCode}_INS_GAL_TOBE`
    ]
    await categoryService
      .getCategoryCodeList(codes)
      .then(async (res) => {
        _this.collCateId = res.data.list[2].id
        _this.youtube = !_.isEmpty(_this.pcdContentAttribute.intrd_youtube_url)
          ? _this.pcdContentAttribute.intrd_youtube_url
          : ''
        _this.movie = !_.isEmpty(_this.pcdContentAttribute.intrd_video)
          ? _this.$imagePath + _this.pcdContentAttribute.intrd_video
          : ''

        // start ######################################## COLLECTION LIST
        console.log('_this.collCateId_this.collCateId', _this.collCateId)
        const collectionList2 = await ProductService.getCollectionList(_this.collCateId)
        console.log('컬렉션 리스트2', collectionList2)
        const collectionMapList = collectionList2.data.list.map((item) => {
          return {
            name: item.category.name,
            sort: item.category.sort,
            newYn: item.categoryAttribute.new_yn ? item.categoryAttribute.new_yn : 'false',
            bestYn: item.categoryAttribute.best_yn ? item.categoryAttribute.best_yn : 'false',
            ...item
          }
        })
        _this.collectionList = _this.sortArrayByTwoKeysAndName(collectionMapList, 'newYn', 'bestYn', 'true', 'true')
        console.log('컬렉션 리스트', _this.collectionList)
        _this.collectionPageList = _.slice(_this.collectionList, 0, _this.pageSize)
        console.log('컬렉션 페이지', _this.collectionPageList)

        _this.totalCount = collectionList2.data.totalCount
        console.log(_this.collectionList)
        console.log(_this.totalCount)
        // end ######################################## COLLECTION LIST

        // start ######################################## collection colors
        const collectionIds = _this.collectionPageList.map((item) => {
          return item.category.id
        })
        const collectionsChild = await ProductService.getEachCategoryContent(collectionIds, 1, 5)
        _this.collectionschildList = Object.groupBy(collectionsChild.data.list, (c) => c.content.categoryId)
        console.log('컬렉션 차일드', _this.collectionschildList)
        console.log(_this.collectionschildList)
        // end ######################################## collection colors

        // start ######################################## INSPIRATION LIST
        const dictionaryCondition = {
          operator: 'AND',
          list: [{ operator: 'OR', values: [{ id: 'product_cate', value: this.getPcdTypeName(this.pcdMenuType) }] }]
        }
        const { data } = await InspirationService.getContentListTap([res.data.list[3].id], dictionaryCondition, '', 'search_text', 1, 8)
        data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['applicationList', 'productList', 'subThumbnailList']
            if (exceptList.includes(item)) {
              data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })
        _this.pcdInspirationList = data.list
        console.log(_this.pcdInspirationList)
        // end ######################################## INSPIRATION LIST

        // start ######################################## FEATURED PRODUCTS
        const cateId = res.data.list[0].id
        this.featPrdList = await ProductService.getContentListChildType(cateId)
        if (this.featPrdList.data.list.length) {
          this.featPrds = this.featPrdList.data.list
          console.log(this.featPrds)
          switch (this.pcdMenuType) {
            case 'HFLOR':
            case 'LVT':
            case 'LOS':
            case 'SPC':
            case 'HOMO':
            case 'HET':
            case 'SPT':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'LVT') ||
                _.includes(content.title, 'LOS') ||
                _.includes(content.title, 'SPC') ||
                _.includes(content.title, 'HOMO') ||
                _.includes(content.title, 'HET') ||
                _.includes(content.title, 'SPT')
              ).filter(({ content }) =>
                !_.includes(content.title, this.$route.meta.type)
              )
              break
            case 'HIMACS':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'VIATERA') ||
                _.includes(content.title, 'TERACANTO')
              )
              break
            case 'VIATERA':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'HIMACS') ||
                _.includes(content.title, 'TERACANTO')
              )
              break
            case 'TERACANTO':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'HIMACS') ||
                _.includes(content.title, 'VIATERA')
              )
              break
            case 'VIZUON':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'BENIF') ||
                _.includes(content.title, 'DECO') ||
                _.includes(content.title, 'EXTERIOR')
              )
              break
            case 'BENIF':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'DECO') ||
                _.includes(content.title, 'EXTERIOR') ||
                _.includes(content.title, 'VIZUON')
              )
              break
            case 'EXTERIOR':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'BENIF') ||
                _.includes(content.title, 'DECO') ||
                _.includes(content.title, 'VIZUON')
              )
              break
            case 'DECO':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'BENIF') ||
                _.includes(content.title, 'EXTERIOR') ||
                _.includes(content.title, 'VIZUON')
              )
            case 'BORTE':
              this.featPrds = this.featPrds.filter(({ content }) =>
                _.includes(content.title, 'DECO') ||
                _.includes(content.title,  'EXTERIOR') ||
                _.includes(content.title, 'BENIF')
              )
              break
          }
          console.log(this.featPrds)
        }
        // end ######################################## FEATURED PRODUCTS
      })

    await _this.$nextTick(function () {
      // GNB 클릭해서 오면 포커싱
      if (_this.about !== undefined && _this.about !== null && _this.about !== '' && _this.nationCode !== '_US' && _this.nationCode !== '_GL') {
        if (this.about === 'sustainability') {
          $('#sus-box').click()
          $('#sus-box-01').click()
        } else if (this.about === 'certifications') {
          $('#cert-box').click() // feature & benefits tab
          $('#cert-box-01').click()
        } else {
          $('#fb-box').click()
          $('#fb-box-01').click()
        }
        // 탭 영역으로 스크롤
        var offset = $('.cate-tab-box').offset().top - $('header').height() + 500
        $('html, body').animate({ scrollTop: offset }, 400)
      } else {
        $('#tab01').addClass('on')
        $('#tab001').addClass('on')
      }

      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        dots: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      })

      /* product pcd btm slider */
      if (_this.featPrds.length > 3) {
        $('.slider-related')
          .removeClass('flex')
          .slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            draggable: false,
            autoplay: true,
            autoplaySpeed: 4000,
            centerMode: true,
            centerPadding: '80px',
            variableWidth: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: true,
                  draggable: false,
                  centerMode: true,
                  centerPadding: '20px'
                }
              }
            ]
          })
        $('.related_section').append($('.slider-related'))
      }
      var width = window.innerWidth
      if (width < 769) {
        $('.pcd_rel_prod_slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          draggable: false,
          // autoplay: true,
          // autoplaySpeed: 4000,
          centerMode: true,
          centerPadding: '20px',
          // variableWidth: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                draggable: false,
                centerMode: true,
                centerPadding: '25px'
              }
            }
          ]
        })
      }

      // whatsnew_slider
      $('.whatsnew_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      })

      /* collection slider > Common */
      $('.clt-box .tab-con > ul > li').each(function () {
        $(this).find('.info strong').text($(this).find('.slider').not('.slick-cloned').length)
      })

      _this.featShow = true

      // certi 팝업 바깥 클릭시 팝업 닫힘
      $('.modal-certification').on('click', function (e) {
        if ($('.modal-certification > .popup').has(e.target).length === 0) {
          $('.modal-certification').css({ display: 'none' })
          $('body').removeClass('scrolllock')
        }
      })

      // 텍스트 한줄일 시 중앙으로 위치시키기(텍스트 한줄 높이 25.4px)
      const contentItems = document.querySelectorAll('.content_item')

      contentItems.forEach(contentItem => {
        const txts = contentItem.querySelectorAll('.txt')

        txts.forEach(txt => {
          if (txt.offsetHeight < 26) {
            contentItem.classList.add('al_center')
          }
        })
      })
    })
  },
  methods: {
    isEmpty,
    isUndefined,
    checkCertificationDescription (key) {
      // 배열 안에 특정 값을 가진 객체가 있는지 확인
      return this.pcdCertificationData.some(item => !isEmpty(item[key]))
    },

    isVisibleCollectionLoadMore () {
      if (this.totalCount <= 4) {
        return
      }
      return this.isVisibleLoadMoreButton
    },

    getPcdTypeName (type) {
      let resultString
      switch (type) {
        case 'DECO':
          resultString = 'Deco Foil'
          break
        case 'EXTERIOR':
          resultString = 'Exterior Foil'
          break
        default:
          resultString = type
          break
      }
      return resultString
    },

    getColorId () {
      let contentColorCode
      const type = this.$route.meta.type
      switch (type) {
        case 'HIMACS':
        case 'VIATERA':
        case 'TERACANTO':
        case 'BENIF':
        case 'DECO':
        case 'EXTERIOR':
        case 'BORTE':
          contentColorCode = `${type}_COLOR${this.nationCode}`
          break
        case 'HFLOR':
          contentColorCode = `PRD_FLOORING${this.nationCode}`
          break
        case 'HOMO':
          contentColorCode = `FLOOR_HOM${this.nationCode}`
          break
        case 'LVT':
          contentColorCode = `FLOOR_LUX${this.nationCode}`
          break
        case 'LOS':
        case 'SPC':
        case 'HET':
        case 'SPT':
          contentColorCode = `FLOOR_${type}${this.nationCode}`
          break
        case 'VIZUON_A':
          contentColorCode = `VIZUON_COLOR${this.nationCode}`
          break
        case 'VEH':
          contentColorCode = `VIZUON_VT${this.nationCode}`
          break
        case 'SD':
          contentColorCode = `VIZUON_SD${this.nationCode}`
          break
        case 'ILLUM':
          contentColorCode = `VIZUON_IS${this.nationCode}`
          break
        case 'ARCH':
          contentColorCode = `VIZUON_AR${this.nationCode}`
          break
        case 'CORP':
          contentColorCode = `VIZUON_CB${this.nationCode}`
          break
        case 'ECO':
          contentColorCode = `VIZUON_ES${this.nationCode}`
          break
        case 'HASF':
          contentColorCode = 'PRD_VINYL_GL'
          break
      }
      return contentColorCode
    },

    async hideCollectionContent ({ currentTarget }) {
      this.collectionPopCateId = null
    },
    async showCollectionContent ({ currentTarget }) {
      this.collectionPopCateId = Number(currentTarget.getAttribute('data-cate-id'))

      if (this.collectionschildList[this.collectionPopCateId].length <= 5) {
        const collectionsChild = await ProductService.getEachCategoryContent([this.collectionPopCateId], 1, 300)
        const list = Object.groupBy(collectionsChild.data.list, (c) => c.content.categoryId)
        this.collectionschildList[this.collectionPopCateId] = list[this.collectionPopCateId]
      }
    },

    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },

    // CERTIFICATION 팝업 열기
    openModalCerti () {
      LayerPopup.open('modal-certification')
      $('body').addClass('scrolllock')
    },

    // CERTIFICATION 팝업 닫기
    closeModalCerti () {
      $('.modal-certification').css('display', 'none')
      $('body').removeClass('scrolllock')
      LayerPopup.close()
    },

    toggleLike (sr, cateType, productId) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (!_.isEqual(t.nationCode, '_US')) {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, productId, result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (_.isEqual(o.nationCode, t.nationCode)) {
              t.getSessionContent.push(o.id)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (_.isEqual(this.nationCode, '_US')) {
        this.$utils.toggleLike(sr, 'PD', cateType, this, productId)
      }
    },

    async loadMore () {
      const t = this
      t.pageSize = 4
      t.pageIndex += 1

      const data = _.slice(t.collectionList, t.collectionPageList.length, t.pageSize * t.pageIndex)

      for (let i = 0; i < data.length; i++) {
        t.collectionPageList.push(data[i])
      }

      const collectionIds = data.map((item) => {
        return item.category.id
      })

      const collectionsChild = await ProductService.getEachCategoryContent(collectionIds, 1, 5)
      t.collectionschildList = { ...t.collectionschildList, ...Object.groupBy(collectionsChild.data.list, (c) => c.content.categoryId) }

      t.isVisibleLoadMoreButton = !_.isEqual(t.totalCount, t.collectionPageList.length)
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    goToFinder: async function (coll) {
      let selectedUrl = ''
      const type = this.$route.meta.type
      const { data } = await ProductService.getCategoryId(coll.category.parentId)

      this.$store.commit('SET_FILTER_STATE', null)
      this.$store.commit('SET_CATECODE_STATE', null)
      this.$store.commit('SET_SELECTED_FILTERS', [])

      const categoryName = coll.category.name
        .replace(/[\s/]+/g, '-')
        .toLowerCase();

      coll.parents.forEach((item) => {
        if (!isUndefined(this.urlPart[item.code.replaceAll(this.nationCode, '')])) {
          selectedUrl = this.urlPart[item.code.replaceAll(this.nationCode, '')]
        }
      })
      if (this.HFLOR.includes(type)) {
        this.$router.push({ path: `${this.pcdMenuType.toLowerCase()}-finder/all_colors/${selectedUrl}/filter/collection/${categoryName}` })
        return false
      } else if (this.VIZUON.includes(type)) {
        location.href = `/products${this.pcdMenuPath}/${this.pcdMenuType.toLowerCase()}-finder/${coll.name.toLowerCase().replaceAll(' ', '-')}`
        return false
      } else {
        sessionStorage.removeItem(`${this.pcdMenuType.toLowerCase()}Filter` + this.nationCode)
        if (_.isEqual(this.nationCode, '_US') || _.isEqual(this.nationCode, '_GL')) {
          this.$router.push({ path: `${this.pcdMenuPath}/${this.pcdMenuType.toLowerCase()}-finder/filter/collection/${categoryName}` })
        } else {
          /*  나중에 중국러시아 라우터 푸쉬로 바꿔야함 */
          location.href = `products/${this.pcdMenuType.toLowerCase()}/${this.pcdMenuType.toLowerCase()}Finder/filter/collection/` + categoryName
        }
      }
    },
    goLink (type, url) {
      if (_.isEqual(type, 'coll')) {
        location.href = url
      } else {
        window.open(url)
      }
    },
    gotolink (url) {
      location.href = url
    },
    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      const {ecom_prd_brand, ecom_prd_category} = getEcomPrdData(data);
      return JSON.stringify({
        ecom_prd_id: data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand,
        ecom_prd_category, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    },

    sortArrayByTwoKeysAndName (array, key1, key2, value1, value2) {
      return array.sort((a, b) => {
        // 우선 key1의 값을 기준으로 정렬
        if (a[key1] === value1 && b[key1] !== value1) {
          return -1
        } else if (a[key1] !== value1 && b[key1] === value1) {
          return 1
        }
        // 다음으로 key2의 값을 기준으로 정렬
        if (a[key2] === value2 && b[key2] !== value2) {
          return -1
        } else if (a[key2] !== value2 && b[key2] === value2) {
          return 1
        }
        // 나머지 객체들은 이름으로 정렬
        return a.sort - b.sort
      })
    },

    goToPcdPage (data) {
      const type = data.parents[0].code.split(`${this.countryCode}_PCD_`)[1]
      const route = this.$router.getRoutes().find((item) =>
        _.isEqual(item.meta.pageType, 'PCD') && _.isEqual(item.meta.type, type)
      )
      location.href = `products${route.path}`
    },

    goToPfPage () {
      const type = this.$route.meta.type
      console.log('type . type ')

      if (this.HFLOR.includes(type)) {
        if (type === 'HFLOR') {
          this.$router.push({ path: `${this.pcdMenuType.toLowerCase()}-finder/all_colors` })
        } else {
          this.$router.push({ path: `${this.pcdMenuType.toLowerCase()}-finder/${this.urlPart[type]}` })
        }
        return false
      }
      if (this.VIZUON.includes(type)) {
        const vizuonUrlPart = type === 'VIZUON_A' ? `${this.pcdMenuPath}/` : ''
        this.$router.push({ path: `${vizuonUrlPart}${this.pcdMenuType.toLowerCase()}-finder/${this.urlPart[type]}` })
        return false
      }
      this.$router.push({ path: `${this.pcdMenuPath}/${this.pcdMenuType.toLowerCase()}-finder` })
    },
    goToPdPage (coll, coll2) {
      let selectedUrl = ''
      const type = this.$route.meta.type
      coll.parents.forEach((item) => {
        if (!isUndefined(this.urlPart[item.code.replaceAll(this.nationCode, '')])) {
          selectedUrl = this.urlPart[item.code.replaceAll(this.nationCode, '')]
        }
      })
      if (this.HFLOR.includes(type) || this.VIZUON.includes(type)) {
        this.$router.push({ path: `${selectedUrl}/${coll2.content.title.replace(' ', '-').toLowerCase()}/${coll2.content.id}` })
        return false
      }
      this.$router.push({ path: `${this.pcdMenuPath}/${coll2.content.title.replace(' ', '-').toLowerCase()}/${coll2.content.id}` })
    }
  }
}

// 모바일에서 줄바꿈 태그 삭제
$(document).ready(function () {
  var windowWidth = window.outerWidth
  if (windowWidth < 769) {
    $('.ctt.ctt-t3').find('br').remove()
  }
})

// // pcd 헤더 변경 임시 퍼블
// $(document).ready(function () {
//   $('.scr-hder').removeClass('on')
// })

// // 스크롤시 헤더 타입 변경 임시 퍼블
// $(document).ready(function () {
//   var lastScrollTop = 0
//   $(window).scroll(function () {
//     var thisTop = $(this).scrollTop()
//     if ($('.scr-hder').length) {
//       if (thisTop < 30) {
//         $('.scr-hder').removeClass('on')
//       } else if (thisTop > 100) {
//         $('.scr-hder').addClass('on')
//       }
//     }
//   })
// })

</script>
