var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content order-view" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "container order-inp-box order-sample-info" },
          [
            _c(
              "div",
              {
                staticClass: "w-con-02 inner",
                staticStyle: { width: "100%", height: "100%" },
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "tt-2",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v("Successfully Ordered")]
                ),
                _c("div", { staticClass: "more-box ta-c mb-20" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goHome()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Home")])]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
        _c("li", [_vm._v("Header")]),
        _c("li", [_vm._v("Order Sample")]),
        _c("li", [_vm._v("Success")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }