var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "Environmental Investment",
            color: "black",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: true } },
            [
              _c("EnvironmentalInvestmentTextCard", {
                attrs: {
                  year: _vm.textCard.year,
                  unit: _vm.textCard.unit,
                  text: _vm.textCard.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area" },
              _vm._l(_vm.imageCards, function (card, index) {
                return _c("EnvironmentalInvestmentImageCard", {
                  key: index,
                  attrs: {
                    icon: card.icon,
                    title: card.title,
                    imageCardContentList: card.imageCardContentList,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "環境投資",
            color: "black",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: true } },
            [
              _c("EnvironmentalInvestmentTextCard", {
                attrs: {
                  year: _vm.textCardJp.year,
                  unit: _vm.textCardJp.unit,
                  text: _vm.textCardJp.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area" },
              _vm._l(_vm.imageCardsJp, function (card, index) {
                return _c("EnvironmentalInvestmentImageCard", {
                  key: index,
                  attrs: {
                    icon: card.icon,
                    title: card.title,
                    imageCardContentList: card.imageCardContentList,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "Инвестиции в экологию",
            color: "black",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: true } },
            [
              _c("EnvironmentalInvestmentTextCard", {
                attrs: {
                  year: _vm.textCardRu.year,
                  unit: _vm.textCardRu.unit,
                  text: _vm.textCardRu.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area" },
              _vm._l(_vm.imageCardsRu, function (card, index) {
                return _c("EnvironmentalInvestmentImageCard", {
                  key: index,
                  attrs: {
                    icon: card.icon,
                    title: card.title,
                    imageCardContentList: card.imageCardContentList,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "环境投资",
            color: "black",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: true } },
            [
              _c("EnvironmentalInvestmentTextCard", {
                attrs: {
                  year: _vm.textCardCn.year,
                  unit: _vm.textCardCn.unit,
                  text: _vm.textCardCn.text,
                },
              }),
            ],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "image-card-area" },
              _vm._l(_vm.imageCardsCn, function (card, index) {
                return _c("EnvironmentalInvestmentImageCard", {
                  key: index,
                  attrs: {
                    icon: card.icon,
                    title: card.title,
                    imageCardContentList: card.imageCardContentList,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }