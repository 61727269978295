<template>
  <div class="layer-compare" id="pdf_wrap">
    <div class="pop-compare scr-y">
      <p class="heading-3">{{ $t('PF.popup.compare_hflor_products')}}</p>
      <p class="heading-4 ta-c">{{ $t('PF.popup.characteristics_technical_performance')}}</p>

      <!-- start -->
      <div class="slide-wrapper slide-pd">
        <button class="arrow-prev">Prev</button>
        <button class="arrow-next">Next</button>
        <div class="slick-container">
          <!-- item -->
          <div class="slider prod-list">
            <div class="prod-box">
              <div class="prod">
                <div class="img-box"><img
                    :src="$imagePath + '/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png'"
                    alt="Vintage"></div>
                <div class="info">
                  <ul>
                    <li class="txt-01"><a href="javascript:void(0);">Vintage</a></li>
                    <li class="txt-02">DTW9135 Glue Down</li>
                    <li class="txt-03">Savana</li>
                    <li class="txt-04">Luxury Vinyl Tile</li>
                  </ul>
                </div>
              </div>
              <div class="btn-remove"><button type="button"><span>Remove</span></button></div>
            </div>
            <div class="tbl-box type02">
              <table>
                <caption>Characteristics &amp; Technical Performance</caption>
                <colgroup>
                  <col style="width: auto;">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">Product Type</th>
                  </tr>
                  <tr>
                    <td>Glue Down</td>
                  </tr>
                  <tr>
                    <th scope="col">Collection</th>
                  </tr>
                  <tr>
                    <td>Savana</td>
                  </tr>
                  <tr>
                    <th scope="col">Total Thickness</th>
                  </tr>
                  <tr>
                    <td> 2mm </td>
                  </tr>
                  <tr>
                    <th scope="col">Wear Layer</th>
                  </tr>
                  <tr>
                    <td> 2mm : 0.3mm</td>
                  </tr>
                  <tr>
                    <th scope="col">Pattern</th>
                  </tr>
                  <tr>
                    <td>Wood</td>
                  </tr>
                  <tr>
                    <th scope="col">Dimension</th>
                  </tr>
                  <tr>
                    <td>6" x 48"</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end item -->
          <!-- item -->
          <div class="slider prod-list">
            <div class="prod-box">
              <div class="prod">
                <div class="img-box"><img
                    src="https://img.lxhausys.com/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png"
                    alt="Vintage"></div>
                <div class="info">
                  <ul>
                    <li class="txt-01"><a href="javascript:void(0);">Vintage</a></li>
                    <li class="txt-02">DTW9135 Glue Down</li>
                    <li class="txt-03">Savana</li>
                    <li class="txt-04">Luxury Vinyl Tile</li>
                  </ul>
                </div>
              </div>
              <div class="btn-remove"><button type="button"><span
                  >Remove</span></button></div>
            </div>
            <div class="tbl-box type02">
              <table>
                <caption>Characteristics &amp; Technical Performance</caption>
                <colgroup>
                  <col style="width: auto;">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">Product Type</th>
                  </tr>
                  <tr>
                    <td>Glue Down</td>
                  </tr>
                  <tr>
                    <th scope="col">Collection</th>
                  </tr>
                  <tr>
                    <td>Savana</td>
                  </tr>
                  <tr>
                    <th scope="col">Total Thickness</th>
                  </tr>
                  <tr>
                    <td> 2mm </td>
                  </tr>
                  <tr>
                    <th scope="col">Wear Layer</th>
                  </tr>
                  <tr>
                    <td> 2mm : 0.3mm</td>
                  </tr>
                  <tr>
                    <th scope="col">Pattern</th>
                  </tr>
                  <tr>
                    <td>Wood</td>
                    <!---->
                  </tr>
                  <tr>
                    <th scope="col">Dimension</th>
                  </tr>
                  <tr>
                    <td>6" x 48"</td>
                    <!---->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="slider prod-list">
            <div class="prod-box">
              <div class="prod">
                <div class="img-box"><img
                    src="https://img.lxhausys.com/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png"
                    alt="Vintage"></div>
                <div class="info">
                  <ul>
                    <li class="txt-01"><a href="javascript:void(0);">Vintage</a></li>
                    <li class="txt-02">DTW9135 Glue Down</li>
                    <li class="txt-03">Savana</li>
                    <li class="txt-04">Luxury Vinyl Tile</li>
                  </ul>
                </div>
              </div>
              <div class="btn-remove"><button type="button"><span
                  >Remove</span></button></div>
            </div>
            <div class="tbl-box type02">
              <table>
                <caption>Characteristics &amp; Technical Performance</caption>
                <colgroup>
                  <col style="width: auto;">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">Product Type</th>
                  </tr>
                  <tr>
                    <td>Glue Down</td>
                  </tr>
                  <tr>
                    <th scope="col">Collection</th>
                  </tr>
                  <tr>
                    <td>Savana</td>
                  </tr>
                  <tr>
                    <th scope="col">Total Thickness</th>
                  </tr>
                  <tr>
                    <td> 2mm </td>
                  </tr>
                  <tr>
                    <th scope="col">Wear Layer</th>
                  </tr>
                  <tr>
                    <td> 2mm : 0.3mm</td>
                  </tr>
                  <tr>
                    <th scope="col">Pattern</th>
                  </tr>
                  <tr>
                    <td>Wood</td>
                    <!---->
                  </tr>
                  <tr>
                    <th scope="col">Dimension</th>
                  </tr>
                  <tr>
                    <td>6" x 48"</td>
                    <!---->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="slider prod-list">
            <div class="prod-box">
              <div class="prod">
                <div class="img-box"><img
                    src="https://img.lxhausys.com/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png"
                    alt="Vintage"></div>
                <div class="info">
                  <ul>
                    <li class="txt-01"><a href="javascript:void(0);">Vintage</a></li>
                    <li class="txt-02">DTW9135 Glue Down</li>
                    <li class="txt-03">Savana</li>
                    <li class="txt-04">Luxury Vinyl Tile</li>
                  </ul>
                </div>
              </div>
              <div class="btn-remove"><button type="button"><span
                  >Remove</span></button></div>
            </div>
            <div class="tbl-box type02">
              <table>
                <caption>Characteristics &amp; Technical Performance</caption>
                <colgroup>
                  <col style="width: auto;">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">Product Type</th>
                  </tr>
                  <tr>
                    <td>Glue Down</td>
                  </tr>
                  <tr>
                    <th scope="col">Collection</th>
                  </tr>
                  <tr>
                    <td>Savana</td>
                  </tr>
                  <tr>
                    <th scope="col">Total Thickness</th>
                  </tr>
                  <tr>
                    <td> 2mm </td>
                  </tr>
                  <tr>
                    <th scope="col">Wear Layer</th>
                  </tr>
                  <tr>
                    <td> 2mm : 0.3mm</td>
                  </tr>
                  <tr>
                    <th scope="col">Pattern</th>
                  </tr>
                  <tr>
                    <td>Wood</td>
                    <!---->
                  </tr>
                  <tr>
                    <th scope="col">Dimension</th>
                  </tr>
                  <tr>
                    <td>6" x 48"</td>
                    <!---->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end item -->

        </div>
      </div>
      <!-- end -->

      <div class="prod-list tbl-compare">
        <table>
          <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
          <colgroup>
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:auto">
          </colgroup>
          <tbody>
          <tr>
            <td v-for="(item,idx) in compareList">
              <div class="prod-box">
                <div class="prod">
                  <div class="img-box">
                    <img v-if="item.attribute.pf_thumb != undefined" :src="$imagePath + item.attribute.pf_thumb" :alt="item.content.title">
                  </div>

                  <div class="info">
                    <ul>
                      <li class="txt-01"><a href="javascript:void(0);">{{ item.content.title }}</a></li>
                      <li class="txt-02" v-if="item.attribute.disp_code != undefined">{{ item.attribute.disp_code }}</li>
                      <li class="txt-02" v-else>{{ item.attribute.code }}</li>
                      <li class="txt-03">{{item.parents[0].name}}</li>
                      <li class="txt-04">{{item.parents[1].name}}</li>
                    </ul>
                  </div>
                </div>

                <div class="btn-remove">
                  <button type="button"><span>{{ $t('PF.button.remove')}}</span></button>
                </div>
              </div>

              <div class="tbl-box type02">
                <table>
                  <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                  <colgroup>
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th scope="col">{{ $t('PF.text.product_type')}}</th>
                  </tr>
                  <tr v-if="item.attribute.prdTypeText != undefined">
                    <td>{{ item.attribute.prdTypeText.join(', ') }}</td>
                  </tr>
                  <td v-else>{{ }}</td>
                  <tr>
                    <th scope="col">{{ $t('PCD.text.collection')}}</th>
                  </tr>
                  <tr>
                    <td>{{item.parents[0].name}}</td>
                  </tr>
                  <tr>
                    <th scope="col">{{ $t('PD.text.total_thickness')}}</th>
                  </tr>
                  <tr>
                    <td v-if="item.attribute.thickWearLayer != undefined">
                      {{ getThick(item.attribute.thickWearLayer).join(', ') }}
                    </td>
                    <td v-else>{{ item.attribute.thickText.join(', ') }}</td>

                  </tr>
                  <tr>
                    <th scope="col">{{ $t('PD.text.wear_layer')}}</th>
                  </tr>
                  <tr>
                    <td v-if="item.attribute.thickWearLayer != undefined">
                      {{ getWearLayer(item.attribute.thickWearLayer).join(', ') }}</td>
                    <td v-else>{{ item.attribute.wearLayerText }}</td>
                  </tr>
                  <tr>
                    <th scope="col">{{ $t('PD.text.pattern')}}</th>
                  </tr>
                  <tr>
                    <td v-if="item.attribute.patternText != null">{{ item.attribute.patternText.join(', ') }}</td>
                    <td v-if="item.attribute.patternText == null">{{  }}</td>
                  </tr>
                  <tr>
                    <th scope="col">{{ $t('PD.text.dimension')}}</th>
                  </tr>
                  <tr>
                    <td v-if="item.attribute.dimensionText != null">{{ item.attribute.dimensionText.join(', ') }}</td>
                    <td v-if="item.attribute.dimensionText == null">{{  }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="btn-download">
        <button type="button" class="btn dwn load">
          <span>{{ $t('PD.button.download')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProductService from '@/server/product.service'
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'

export default {
  name: 'compare',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      compareRes: [6830, 6828, 6826, 6822],
      compareList: []
    }
  },
  async mounted () {
    const _this = this
    const compareRes = await ProductService.getContentByIds(_this.compareRes)
    _this.compareList = compareRes.data

    this.$nextTick(function () {
      $('.slide-pd .slick-container').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        draggable: false,
        // centerPadding: '35px',
        prevArrow: $('.slide-pd .arrow-prev'),
        nextArrow: $('.slide-pd .arrow-next'),
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true
            }
          }
        ]
      })
    })
  },

  methods: {
    getThick (item) {
      const arr = new Set(item.map(a => a.thickText))
      const thick = [...arr]
      return thick
    },

    getWearLayer (item) {
      return item.map(a => [a.thickText, a.wearLayerText].join(' : '))
    }
  }
}
</script>
