var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "seadragon_custom", attrs: { id: "imgViewer" } }),
    _vm._m(0),
    _c("div", { staticClass: "btn-box" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "div",
          { staticClass: "toolbarItem btn-out", attrs: { id: "btn-out" } },
          [_vm._v(_vm._s(_vm.$t("PD.text.zoom_out")))]
        ),
        _c(
          "div",
          { staticClass: "toolbarItem btn-in", attrs: { id: "btn-in" } },
          [_vm._v(_vm._s(_vm.$t("PD.text.zoom_in")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit" }, [
      _c("p", [_vm._v(" For illustration purposes only ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }