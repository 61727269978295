var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "strong-point-area", class: _vm.type }, [
    _c("div", { staticClass: "icon-area" }, [
      _c("img", { attrs: { src: _vm.iconSrc } }),
    ]),
    _c("div", { staticClass: "text-area" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { style: _vm.titleFontStyle }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "description" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }