var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabCount > 0
    ? _c("div", { staticClass: "tab-con on" }, [
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("HFLOR ("),
              _c("em", [_vm._v(_vm._s(_vm.hflor.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-type-view row-04" }, [
            _vm.hflor && _vm.hflor.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.hflor.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-product-cont-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "over",
                            attrs: {
                              "data-ec-product": _vm.makeDataLayerForm(
                                sr,
                                "HFLOR"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "img-box" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoHFlorDetail(sr)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath + sr.attribute.pf_thumb,
                                      alt: sr.content.title,
                                    },
                                  }),
                                ]
                              ),
                              _vm._m(0, true),
                            ]),
                          ]
                        ),
                        sr.attribute.isNew === true
                          ? _c("i", { staticClass: "badge badge-new" }, [
                              _c("span", [_vm._v("NEW")]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "view" },
                          [
                            _vm.canOrder(sr)
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon-cart-shadow",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openSampleOrder(
                                            sr.content.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("add cart")]
                                  ),
                                ]
                              : _vm._e(),
                            [
                              _vm.authenticated == true &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: { on: sr.content.liked },
                                      attrs: { tabindex: "0" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "HFLOR")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                              _vm.authenticated == false &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      attrs: { tabindex: "0", href: "login/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loginalertLike()
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                              _vm.getSessionContent != null &&
                              _vm.nationCode == "_GL"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: {
                                        on: _vm.getSessionContent.includes(
                                          sr.content.id
                                        ),
                                      },
                                      attrs: { tabindex: "0" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "HFLOR")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm.getSessionContent == null &&
                                  _vm.nationCode == "_GL"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      attrs: { tabindex: "0" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "HFLOR")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                            ],
                          ],
                          2
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _vm._v(_vm._s(sr.content.title)),
                            ]),
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoHFlorDetail(sr)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      sr.attribute.disp_code != undefined
                                        ? sr.attribute.disp_code
                                        : sr.attribute.code
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-03" }, [
                              _vm._v(_vm._s(sr.parents[0].name)),
                            ]),
                            _c("li", { staticClass: "txt-04" }, [
                              _vm._v(_vm._s(sr.parents[1].name)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.hflor.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn_load_more",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreHflor()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("Load More")]),
                    _c("span", { staticClass: "icon icon_plus" }),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm.showViatera()
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v("VIATERA ("),
                  _c("em", [_vm._v(_vm._s(_vm.viatera.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-type-view row-04" }, [
                _vm.viatera && _vm.viatera.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.viatera.list, function (sr) {
                        return _c(
                          "li",
                          { staticClass: "search-product-cont-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "over",
                                attrs: {
                                  "data-ec-product": _vm.makeDataLayerForm(
                                    sr,
                                    "VIATERA"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "viatera")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            sr.attribute.pf_thumb,
                                          alt: sr.content.title,
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._m(1, true),
                                ]),
                              ]
                            ),
                            sr.attribute.isNew === true
                              ? _c("i", { staticClass: "badge badge-new" }, [
                                  _c("span", [_vm._v("NEW")]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "view" },
                              [
                                _vm.canOrder(sr)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "icon-cart-shadow",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                sr.content.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("add cart")]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.authenticated == true &&
                                _vm.nationCode == "_US"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "icon-wish-shadow",
                                        class: { on: sr.content.liked },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleLike(sr, "Viatera")
                                          },
                                        },
                                      },
                                      [_vm._v("wish list / 1")]
                                    )
                                  : _vm._e(),
                                _vm.authenticated == false &&
                                _vm.nationCode == "_US"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "icon-wish-shadow",
                                        attrs: { href: "login/" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.loginalertLike()
                                          },
                                        },
                                      },
                                      [_vm._v("wish list / 2")]
                                    )
                                  : _vm._e(),
                                _vm.getSessionContent != null &&
                                _vm.nationCode == "_GL"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "icon-wish-shadow",
                                        class: {
                                          on: _vm.getSessionContent.includes(
                                            sr.content.id
                                          ),
                                        },
                                        attrs: { id: "Like_" + sr.content.id },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleLike(sr, "Viatera")
                                          },
                                        },
                                      },
                                      [_vm._v("wish list / 3")]
                                    )
                                  : _vm.getSessionContent == null &&
                                    _vm.nationCode == "_GL"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "icon-wish-shadow",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleLike(sr, "Viatera")
                                          },
                                        },
                                      },
                                      [_vm._v("wish list / 4")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "viatera")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(sr.content.title))]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(_vm._s(sr.parents[0].name)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.viatera.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn_load_more",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreViatera()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("Load More"),
                        ]),
                        _c("span", { staticClass: "icon icon_plus" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.showViateraOther()
          ? _c(
              "div",
              { staticClass: "sch-res-prod prod-fd-view result-other-pd" },
              [
                _c("div", { staticClass: "res-prod-cnt" }, [
                  _c("span", [
                    _vm._v("VIATERA other products ("),
                    _c("em", [_vm._v(_vm._s(_vm.viateraOther.totalCount))]),
                    _vm._v(")"),
                  ]),
                ]),
                _c("div", { staticClass: "list-type-view row-04" }, [
                  _vm.viateraOther && _vm.viateraOther.totalCount > 0
                    ? _c(
                        "ul",
                        _vm._l(_vm.viateraOther.list, function (sr) {
                          return _c(
                            "li",
                            { staticClass: "search-product-cont-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "over",
                                  attrs: {
                                    "data-ec-product": _vm.makeDataLayerForm(
                                      sr,
                                      "V-OTHER"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "img-box img-other-pd" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.$imagePath +
                                                sr.attribute.pc_thunb,
                                              alt: sr.content.title,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.popUpView(sr)
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._m(2, true),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "info" }, [
                                _c("ul", [
                                  _c("li", { staticClass: "txt-01" }, [
                                    _vm._v(_vm._s(sr.content.title)),
                                  ]),
                                  _c("li", { staticClass: "txt-03" }, [
                                    _vm._v(_vm._s(sr.parents[0].name)),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                !_vm.viateraOther.lastPage
                  ? _c("div", { staticClass: "more-box ta-c" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_load_more",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.loadMoreViateraOther()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "txt" }, [
                            _vm._v("Load More"),
                          ]),
                          _c("span", { staticClass: "icon icon_plus" }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "layer-popup layer-other us-layer-other" }, [
          _c("div", { staticClass: "popup pop-close" }, [
            _c("div", { staticClass: "pop-other scr-y" }, [
              _c(
                "div",
                { staticClass: "inspr-view prod-fd-view prod-op-view" },
                [
                  _c("div", { staticClass: "list-type-view col-02" }, [
                    _c("div", [
                      _c("div", { staticClass: "img-box tab-con other01 on" }, [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: _vm.$imagePath + _vm.contentAttribute.pc_thunb,
                            alt: "images",
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _vm._m(3),
                      _c("p", { staticClass: "tt-1" }, [
                        _vm._v(_vm._s(_vm.contentContent.title)),
                      ]),
                      _c("div", { staticClass: "tbl-box" }, [
                        _c("table", [
                          _c("caption", [_vm._v("Product Specs")]),
                          _vm._m(4),
                          _c("tbody", [
                            _vm.contentAttribute.overall !== undefined
                              ? _c("tr", [
                                  _c("th", { attrs: { scope: "row" } }, [
                                    _vm._v(_vm._s(_vm.$t("overall"))),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.contentAttribute.overall)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.contentAttribute.inner !== undefined
                              ? _c("tr", [
                                  _c("th", { attrs: { scope: "row" } }, [
                                    _vm._v(_vm._s(_vm.$t("inner"))),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.contentAttribute.inner)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.contentAttribute.option !== undefined
                              ? _c("tr", [
                                  _c("th", { attrs: { colspan: "2" } }, [
                                    _vm._v(_vm._s(_vm.contentAttribute.option)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.contentAttribute.caution !== undefined
                              ? _c("tr", [
                                  _c("th", { attrs: { colspan: "2" } }, [
                                    _vm._v(
                                      _vm._s(_vm.contentAttribute.caution)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "btns dwonarea" }, [
                        _c("p", { staticClass: "ctt ctt-t2" }, [
                          _vm._v("Documentation"),
                        ]),
                        _c("div", [
                          _vm.contentAttribute.pdf
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-down",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(
                                        _vm.contentAttribute.pdf
                                      )
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("pdf")])]
                              )
                            : _vm._e(),
                          _vm.contentAttribute.dxf
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-down",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(
                                        _vm.contentAttribute.dxf
                                      )
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("DXF")])]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-line",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.contactUs()
                                },
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("contactus")))])]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn-close",
                  attrs: { type: "button", onclick: "LayerPopup.close();" },
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("HIMACS ("),
              _c("em", [_vm._v(_vm._s(_vm.himacs.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-type-view row-04" }, [
            _vm.himacs && _vm.himacs.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.himacs.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-product-cont-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "over",
                            attrs: {
                              "data-ec-product": _vm.makeDataLayerForm(
                                sr,
                                "HIMACS"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "img-box" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetail(sr, "HIMACS")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath + sr.attribute.pf_thumb,
                                      alt: sr.content.title,
                                    },
                                  }),
                                ]
                              ),
                              _vm._m(5, true),
                            ]),
                          ]
                        ),
                        sr.attribute.isNew === true
                          ? _c("i", { staticClass: "badge badge-new" }, [
                              _c("span", [_vm._v("NEW")]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "view" },
                          [
                            _vm.canOrder(sr)
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon-cart-shadow",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openSampleOrder(
                                            sr.content.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("add cart")]
                                  ),
                                ]
                              : _vm._e(),
                            [
                              _vm.authenticated == true &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: { on: sr.content.liked },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "HIMACS")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                              _vm.authenticated == false &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      attrs: { href: "login/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loginalertLike()
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                              _vm.getSessionContent != null &&
                              _vm.nationCode != "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: {
                                        on: _vm.getSessionContent.includes(
                                          sr.content.id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "HIMACS")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm.getSessionContent == null &&
                                  _vm.nationCode != "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "HIMACS")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                            ],
                          ],
                          2
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _vm._v(_vm._s(sr.content.title)),
                            ]),
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetail(sr, "HIMACS")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(sr.attribute.code))]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-03" }, [
                              _vm._v(_vm._s(sr.parents[0].name)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.himacs.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn_load_more",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreHimacs()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("Load More")]),
                    _c("span", { staticClass: "icon icon_plus" }),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm.showHimacsOther()
          ? _c(
              "div",
              { staticClass: "sch-res-prod prod-fd-view result-other-pd" },
              [
                _c("div", { staticClass: "res-prod-cnt" }, [
                  _c("span", [
                    _vm._v("HIMACS other products ("),
                    _c("em", [_vm._v(_vm._s(_vm.himacsOther.totalCount))]),
                    _vm._v(")"),
                  ]),
                ]),
                _c("div", { staticClass: "list-type-view row-04" }, [
                  _vm.himacsOther && _vm.himacsOther.totalCount > 0
                    ? _c(
                        "ul",
                        _vm._l(_vm.himacsOther.list, function (sr) {
                          return _c(
                            "li",
                            { staticClass: "search-product-cont-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "over",
                                  attrs: {
                                    "data-ec-product": _vm.makeDataLayerForm(
                                      sr,
                                      "H-OTHER"
                                    ),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.popUpView(sr)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.$imagePath +
                                              sr.attribute.pc_thunb,
                                            alt: sr.content.title,
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._m(6, true),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "info" }, [
                                _c("ul", [
                                  _c("li", { staticClass: "txt-01" }, [
                                    _vm._v(_vm._s(sr.content.title)),
                                  ]),
                                  _c("li", { staticClass: "txt-03" }, [
                                    _vm._v(_vm._s(sr.parents[0].name)),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                !_vm.himacsOther.lastPage
                  ? _c("div", { staticClass: "more-box ta-c" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_load_more",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.loadMoreHimacsOther()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "txt" }, [
                            _vm._v("Load More"),
                          ]),
                          _c("span", { staticClass: "icon icon_plus" }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("TERACANTO ("),
              _c("em", [_vm._v(_vm._s(_vm.teracanto.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-type-view row-04" }, [
            _vm.teracanto && _vm.teracanto.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.teracanto.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-product-cont-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "over",
                            attrs: {
                              "data-ec-product": _vm.makeDataLayerForm(
                                sr,
                                "TERA"
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "img-box" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetail(sr, "TERACANTO")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath + sr.attribute.pf_thumb,
                                      alt: sr.content.title,
                                    },
                                  }),
                                ]
                              ),
                              _vm._m(7, true),
                            ]),
                          ]
                        ),
                        sr.attribute.isNew === true
                          ? _c("i", { staticClass: "badge badge-new" }, [
                              _c("span", [_vm._v("NEW")]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "view" },
                          [
                            _vm.canOrder(sr)
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon-cart-shadow",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openSampleOrder(
                                            sr.content.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("add cart")]
                                  ),
                                ]
                              : _vm._e(),
                            [
                              _vm.authenticated == true &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: { on: sr.content.liked },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "TERACANTO")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                              _vm.authenticated == false &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      attrs: { href: "login/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loginalertLike()
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                              _vm.getSessionContent != null &&
                              _vm.nationCode != "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: {
                                        on: _vm.getSessionContent.includes(
                                          sr.content.id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "TERACANTO")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm.getSessionContent == null &&
                                  _vm.nationCode != "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(sr, "TERACANTO")
                                        },
                                      },
                                    },
                                    [_vm._v("wish list")]
                                  )
                                : _vm._e(),
                            ],
                          ],
                          2
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _vm._v(_vm._s(sr.content.title)),
                            ]),
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoDetail(sr, "TERACANTO")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(sr.attribute.code))]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-03" }, [
                              _vm._v(_vm._s(sr.parents[0].name)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.teracanto.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn_load_more",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreTeracanto()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("Load More")]),
                    _c("span", { staticClass: "icon icon_plus" }),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm.nationCode == "_GL"
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v("BENIF ("),
                  _c("em", [_vm._v(_vm._s(_vm.benif.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-type-view row-04" }, [
                _vm.benif && _vm.benif.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.benif.list, function (sr) {
                        return _c(
                          "li",
                          { staticClass: "search-product-cont-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "over",
                                attrs: {
                                  "data-ec-product": _vm.makeDataLayerForm(
                                    sr,
                                    "BENIF"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "BENIF")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            sr.attribute.pf_thumb,
                                          alt: sr.content.title,
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._m(8, true),
                                ]),
                              ]
                            ),
                            sr.attribute.isNew === true
                              ? _c("i", { staticClass: "badge badge-new" }, [
                                  _c("span", [_vm._v("NEW")]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "view" },
                              [
                                _vm.canOrder(sr)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "icon-cart-shadow",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                sr.content.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("add cart")]
                                      ),
                                    ]
                                  : _vm._e(),
                                [
                                  _vm.authenticated == true &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: { on: sr.content.liked },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(sr, "BENIF")
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.authenticated == false &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: { href: "login/" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loginalertLike()
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.getSessionContent != null &&
                                  _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: {
                                            on: _vm.getSessionContent.includes(
                                              sr.content.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(sr, "BENIF")
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm.getSessionContent == null &&
                                      _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(sr, "BENIF")
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                ],
                              ],
                              2
                            ),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _vm._v(_vm._s(sr.content.title)),
                                ]),
                                _c("li", { staticClass: "txt-02" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "BENIF")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(sr.attribute.code))]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(_vm._s(sr.parents[0].name)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.benif.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn_load_more",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreBenif()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("Load More"),
                        ]),
                        _c("span", { staticClass: "icon icon_plus" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.nationCode == "_GL"
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v("VIZUON ("),
                  _c("em", [_vm._v(_vm._s(_vm.vizuon.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-type-view row-04" }, [
                _vm.vizuon && _vm.vizuon.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.vizuon.list, function (sr) {
                        return _c(
                          "li",
                          { staticClass: "search-product-cont-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "over",
                                attrs: {
                                  "data-ec-product": _vm.makeDataLayerForm(
                                    sr,
                                    "VIZUON"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "VIZUON")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            sr.attribute.pf_thumb,
                                          alt: sr.content.title,
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._m(9, true),
                                ]),
                              ]
                            ),
                            sr.attribute.isNew === true
                              ? _c("i", { staticClass: "badge badge-new" }, [
                                  _c("span", [_vm._v("NEW")]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "view" },
                              [
                                _vm.canOrder(sr)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "icon-cart-shadow",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                sr.content.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("add cart")]
                                      ),
                                    ]
                                  : _vm._e(),
                                [
                                  _vm.authenticated == true &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: { on: sr.content.liked },
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                sr,
                                                "VIZUON"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.authenticated == false &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: {
                                            tabindex: "0",
                                            href: "login/",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loginalertLike()
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.getSessionContent != null &&
                                  _vm.nationCode == "_GL"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: {
                                            on: _vm.getSessionContent.includes(
                                              sr.content.id
                                            ),
                                          },
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                sr,
                                                "VIZUON"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm.getSessionContent == null &&
                                      _vm.nationCode == "_GL"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                sr,
                                                "VIZUON"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                ],
                              ],
                              2
                            ),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _vm._v(_vm._s(sr.content.title)),
                                ]),
                                _c("li", { staticClass: "txt-02" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "VIZUON")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          sr.attribute.disp_code != undefined
                                            ? sr.attribute.disp_code
                                            : sr.attribute.code
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(_vm._s(sr.parents[0].name)),
                                ]),
                                _c("li", { staticClass: "txt-04" }, [
                                  _vm._v(_vm._s(sr.parents[1].name)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.vizuon.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn_load_more",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreVizuon()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("Load More"),
                        ]),
                        _c("span", { staticClass: "icon icon_plus" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.nationCode == "_GL"
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v("Deco Foil ("),
                  _c("em", [_vm._v(_vm._s(_vm.deco.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-type-view row-04" }, [
                _vm.deco && _vm.deco.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.deco.list, function (sr) {
                        return _c(
                          "li",
                          { staticClass: "search-product-cont-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "over",
                                attrs: {
                                  "data-ec-product": _vm.makeDataLayerForm(
                                    sr,
                                    "DECO"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "DECO")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            sr.attribute.pf_thumb,
                                          alt: sr.content.title,
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._m(10, true),
                                ]),
                              ]
                            ),
                            sr.attribute.isNew === true
                              ? _c("i", { staticClass: "badge badge-new" }, [
                                  _c("span", [_vm._v("NEW")]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "view" },
                              [
                                _vm.canOrder(sr)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "icon-cart-shadow",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                sr.content.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("add cart")]
                                      ),
                                    ]
                                  : _vm._e(),
                                [
                                  _vm.authenticated == true &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: { on: sr.content.liked },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(sr, "DECO")
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.authenticated == false &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: { href: "login/" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loginalertLike()
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.getSessionContent != null &&
                                  _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: {
                                            on: _vm.getSessionContent.includes(
                                              sr.content.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(sr, "DECO")
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm.getSessionContent == null &&
                                      _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(sr, "DECO")
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                ],
                              ],
                              2
                            ),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _vm._v(_vm._s(sr.content.title)),
                                ]),
                                _c("li", { staticClass: "txt-02" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "DECO")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(sr.attribute.code))]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(_vm._s(sr.parents[0].name)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.deco.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn_load_more",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreDeco()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("Load More"),
                        ]),
                        _c("span", { staticClass: "icon icon_plus" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.nationCode == "_GL"
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v("Exterior Foil ("),
                  _c("em", [_vm._v(_vm._s(_vm.exterior.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-type-view row-04" }, [
                _vm.exterior && _vm.exterior.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.exterior.list, function (sr) {
                        return _c(
                          "li",
                          { staticClass: "search-product-cont-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "over",
                                attrs: {
                                  "data-ec-product": _vm.makeDataLayerForm(
                                    sr,
                                    "EXTER"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "img-box" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "EXTERIOR")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            sr.attribute.pf_thumb,
                                          alt: sr.content.title,
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._m(11, true),
                                ]),
                              ]
                            ),
                            sr.attribute.isNew === true
                              ? _c("i", { staticClass: "badge badge-new" }, [
                                  _c("span", [_vm._v("NEW")]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "view" },
                              [
                                _vm.canOrder(sr)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "icon-cart-shadow",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                sr.content.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("add cart")]
                                      ),
                                    ]
                                  : _vm._e(),
                                [
                                  _vm.authenticated == true &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: { on: sr.content.liked },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                sr,
                                                "EXTERIOR"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.authenticated == false &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: { href: "login/" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loginalertLike()
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                  _vm.getSessionContent != null &&
                                  _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: {
                                            on: _vm.getSessionContent.includes(
                                              sr.content.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                sr,
                                                "EXTERIOR"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm.getSessionContent == null &&
                                      _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                sr,
                                                "EXTERIOR"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("wish list")]
                                      )
                                    : _vm._e(),
                                ],
                              ],
                              2
                            ),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _vm._v(_vm._s(sr.content.title)),
                                ]),
                                _c("li", { staticClass: "txt-02" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoDetail(sr, "EXTERIOR")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(sr.attribute.code))]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(
                                    _vm._s(
                                      sr.attribute.grade
                                        .toUpperCase()
                                        .replace("PLUS", "+")
                                    )
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-04" }, [
                                  _vm._v(_vm._s(sr.parents[0].name)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.exterior.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn_load_more",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreExterior()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "txt" }, [
                          _vm._v("Load More"),
                        ]),
                        _c("span", { staticClass: "icon icon_plus" }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "layer-popup layer-other hi-op-pop eu-layer-other" },
          [
            _c("div", { staticClass: "popup pop-close" }, [
              _c("div", { staticClass: "pop-other scr-y" }, [
                _c("div", { staticClass: "op-pop-pic" }, [
                  _c("div", { staticClass: "op-pop-slide-top" }, [
                    _c(
                      "div",
                      {
                        staticClass: "op-pop-imgbox on",
                        attrs: { id: "popimage_01", name: "popImage" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.$imagePath + _vm.contentAttribute.pc_thunb,
                            alt: "slider_img01",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("ul", [
                    _c("li", { staticClass: "txt-01" }, [
                      _vm._v(_vm._s(_vm.popUpCateName)),
                    ]),
                    _c("li", { staticClass: "txt-02 tt-1" }, [
                      _vm._v(_vm._s(_vm.contentContent.title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "tbl-box op-pop-tbl-box" }, [
                    _c("table", [
                      _c("caption", [_vm._v("Product Specs")]),
                      _vm._m(12),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("prd_ref"))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contentAttribute.prd_code)),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("dim"))),
                          ]),
                          _c("td", [
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5",
                                staticStyle: { color: "#666" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.contentAttribute.prd_L) +
                                    "(L) x " +
                                    _vm._s(_vm.contentAttribute.prd_W) +
                                    "(W)"
                                ),
                              ]
                            ),
                            _vm.contentAttribute.prd_H != undefined
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "ctt ctt-t5",
                                    staticStyle: { color: "#666" },
                                  },
                                  [
                                    _vm._v(
                                      " x " +
                                        _vm._s(_vm.contentAttribute.prd_H) +
                                        "(H) "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5",
                                staticStyle: { color: "#666" },
                              },
                              [_vm._v(" mm")]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("install"))),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.contentAttribute.cate_installText)
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("more"))),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.contentAttribute.more))]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "op-pop-doc" }, [
                  _c("p", { staticClass: "tt-sub" }, [
                    _vm._v(_vm._s(_vm.$t("documentation"))),
                  ]),
                  _c("div", { staticClass: "cont" }, [
                    _c("ul", [
                      _vm.contentAttribute.pdf != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.$t("kbb"))),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.pdf)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.contentAttribute.dxf != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.contentAttribute.dxf_orgName)),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.dxf)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.contentAttribute.dxf2 != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.contentAttribute.dxf2_orgName)),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.dxf2)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.contentAttribute.dxf3 != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.contentAttribute.dxf3_orgName)),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.dxf3)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btns claer" }, [
                  _c("div", { staticClass: "fl-l" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-line btn-xs",
                        on: {
                          click: function ($event) {
                            return _vm.linkUrl(_vm.contentAttribute.prd_code)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("contactus")))])]
                    ),
                  ]),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: { type: "button", onclick: "LayerPopup.close();" },
                }),
              ]),
            ]),
          ]
        ),
      ])
    : _c("div", { staticClass: "no-found-wrap bd-top" }, [_vm._m(13)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("HFLOR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("VIATERA")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("VIATERA")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wide-proDeteail-label" }, [
      _c("span", { staticClass: "pro" }, [_vm._v("Kitchen")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("HIMACS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("HIMACS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("TERACANTO")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("BENIF")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("VIZUON")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("Deco Foil")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "over-cg" }, [
      _c("p", { staticClass: "ico-cg" }, [_vm._v("Exterior Foil")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col"), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tac" }, [
      _c("i", { staticClass: "icon-no-result" }, [
        _c("img", {
          attrs: { src: "images/icon-no-result-found.png", alt: "no result" },
        }),
      ]),
      _c("div", { staticClass: "msg" }, [
        _c("ul", [
          _c("li", { staticClass: "ctt ctt-t1 result-tit" }, [
            _vm._v("No Result Found"),
          ]),
          _c("li", { staticClass: "ctt ctt-t4" }, [
            _vm._v("Try again using more general search keywords"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }