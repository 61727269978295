var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SeatCorverLayout", {
    staticClass: "mt-50",
    attrs: { imageSrc: "images/automotive/skin/LUSSINO.png" },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("LUSSINO™")]),
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [
                _vm._v(
                  "• [Premium grade] High-perfomance and sustainable silicone based leather"
                ),
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "• Composition of silicone based material (Topcoat, Skin, Binder)"
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }