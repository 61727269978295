var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content cntu-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c("li", {
              directives: [{ name: "hover-cursor", rawName: "v-hover-cursor" }],
              staticClass: "ico-home",
              on: {
                click: function ($event) {
                  _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                },
              },
            }),
            _c("li", [_vm._v("Contact")]),
            _c("li", [_vm._v("Our Showrooms")]),
          ]),
        ]),
        _c("div", { staticClass: "sub_visual_bg full-width" }, [
          _c("div", { staticClass: "inner_padding showroom" }, [
            _c("h1", [_vm._v("Our Showrooms")]),
            _c("div", { staticClass: "showroom-wrap" }, [
              _c(
                "div",
                { staticClass: "showroom-tabs" },
                _vm._l(_vm.bannerList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "showroom-tab",
                        { on: _vm.selectedTab === index },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.handleTapClick(index)
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(item.attribute.city_state),
                          },
                        }),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(item.attribute.title) },
                        }),
                      ]),
                      _vm._m(0, true),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "showroom-contents" },
                _vm._l(_vm.bannerList, function (banner, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      ref: "content-" + index,
                      refInFor: true,
                      class: ["showroom-content", `showroom${index + 1}`],
                    },
                    [
                      _c("div", { staticClass: "showroom-info" }, [
                        _c("p", [_vm._v(_vm._s(banner.attribute.city_state))]),
                        _c("h4", [_vm._v(_vm._s(banner.attribute.title))]),
                        _c("div", { staticClass: "showroom-info-content" }, [
                          _c("div", { staticClass: "showroom-more card1" }, [
                            _c("p", [
                              _vm._v(_vm._s(banner.attribute.addr1)),
                              _c("br"),
                              _vm._v(" " + _vm._s(banner.attribute.addr2)),
                            ]),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goDrtn(banner.attribute.map_url)
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v("Directions")]),
                                _c("img", {
                                  attrs: {
                                    src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                                    alt: "arrow",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "showroom-more card2" }, [
                              _c("p", [
                                _vm._v(_vm._s(banner.attribute.oper_date)),
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(banner.attribute.oper_time)
                                ),
                              ]),
                              "appointment_url" in banner.attribute
                                ? _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDrtn(
                                              banner.attribute.appointment_url
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Book an Appointment"),
                                        ]),
                                        _c("img", {
                                          attrs: {
                                            src: "https://img.lxhausys.com/2024/images/support/right_arrow_222.png",
                                            alt: "arrow",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "showroom-more card3" }, [
                              _c("div", [
                                _c("p", [
                                  _vm._v(_vm._s(banner.attribute.phone_num)),
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(banner.attribute.email)),
                                ]),
                              ]),
                              "serv_offered" in banner.attribute ||
                              "dis_products" in banner.attribute
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "showroom-detail",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openService(banner)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("Service Offered")]),
                                      _c("img", {
                                        attrs: {
                                          src: "https://img.lxhausys.com/2024/images/support/showroom_info.png",
                                          alt: "img",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "offered",
                              class: { on: banner.isClicked },
                            },
                            [
                              _c("div", [
                                _c("p", [_vm._v("SERVICE OFFERED​")]),
                                _c("div", { staticClass: "offered_close" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "https://img.lxhausys.com/2024/images/svg_icon/icon_pop_close.svg",
                                      alt: "close",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeService(banner)
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    banner.attribute.serv_offered
                                  ),
                                },
                              }),
                              _c("p", [_vm._v("DISPLAYED PRODUCTS")]),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    banner.attribute.dis_products
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      banner.imgList.length > 0
                        ? _c("div", { staticClass: "showroom-carousel" }, [
                            banner.imgList.length > 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "VueSlickCarousel",
                                      _vm._b(
                                        { attrs: { "swipe-to-slide": true } },
                                        "VueSlickCarousel",
                                        _vm.showroomSetting,
                                        false
                                      ),
                                      _vm._l(
                                        banner.imgList,
                                        function (img, idx) {
                                          return _c("div", { key: idx }, [
                                            _c("div", [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.$imagePath +
                                                    img.subThumbnailPath,
                                                  alt: img.subThumbnailName,
                                                },
                                              }),
                                            ]),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath +
                                        banner.imgList[0].subThumbnailPath,
                                      alt: banner.imgList[0].subThumbnailName,
                                    },
                                  }),
                                ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "room-arrow-c",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_666.png",
        },
      }),
      _c("img", {
        staticClass: "room-arrow",
        attrs: {
          src: "https://img.lxhausys.com/2024/images/support/right_arrow_999.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }