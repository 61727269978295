<template>
  <div class="overview-info-txt">
    <span
      :class="spanClass "
      :style="`text-align: ${textAlign};`"
    >{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    textAlign: {
      type: String,
      default: 'center'
    },
    enableListStyle: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    spanClass () {
      const listClass = this.enableListStyle ? 'sep' : ''

      return `${this.type} ${listClass}`
    }
  }
}
</script>

<style scoped>
.overview-info-txt {
  display: grid;
}

.overview-info-txt span {
  display: block;
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  white-space: pre-wrap;
}

.overview-info-txt span.sep::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 15px;
  background-color: black;
  vertical-align: middle;
}

.overview-info-txt span.ft-25 {
  font-size: 25px;
  line-height: 29px;
}
@media (max-width: 768px) {
  .overview-info-txt span.ft-25 {
    font-size: 1.5rem;
    line-height: 2.0833333333rem;
  }
}

.overview-info-txt span.ft-20 {
  font-size: 20px;
  line-height: 36px;
}
@media (max-width: 1440px) {
  .overview-info-txt span.ft-20 {
    font-size: 14px;
    line-height: 1.4;
  }
}
@media (max-width: 768px) {
  .overview-info-txt span.ft-20 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.overview-info-txt span.ft-18 {
  font-size: 18px;
  line-height: 20px;
}
@media (max-width: 768px) {
  .overview-info-txt span.ft-18 {
    font-size: 1rem;
  }
}

.overview-info-txt span.ft-16 {
  font-size: 16px;
  line-height: 20px;
}
@media (max-width: 768px) {
  .overview-info-txt span.ft-16 {
    font-size: 0.8rem;
  }
}

</style>
