var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carbon_neutrality_strategy_card_area" }, [
    _c("div", { staticClass: "image-area" }, [
      _c("img", { attrs: { src: _vm.imageSrc } }),
    ]),
    _c(
      "div",
      { staticClass: "title-area" },
      [
        _c("OverviewText", {
          attrs: { text: _vm.title, type: "ft-25 fw-700", textAlign: "left" },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "desc-area" },
      _vm._l(_vm.desc, function (it, idx) {
        return _c("OverviewText", {
          key: idx,
          attrs: {
            text: it,
            enableListStyle: _vm.enableDot,
            type: "ft-16",
            textAlign: "left",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }