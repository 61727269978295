<template>
  <div class="lightweight-component-area">
    <ProductSectionHeader
      title="Lightweight Component"
    />

    <div class="mt-50">
      <!-- <img src="images/automotive/lightweight/component-image.png"> -->
      <img src="https://img.lxhausys.com/2024/images/products/light_component-image.png" alt="lightweight">
    </div>

    <ProductComponentGroup
      class="mt-100 mx-30"
      :left="leftComponentInfo"
      :right="rightComponentInfo"
    />
    <p class="ctt ctt-t4">※ Weight reduction is based on simulation results of automotive design analysis programs (CATIA, LS-DYNA), and may vary depending on specifications when the actual product is manufactured.</p>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductComponentGroup from '@/components/product/ProductComponentGroup'

export default {
  components: {
    ProductSectionHeader,
    ProductComponentGroup
  },

  data: () => ({
    leftComponentInfo: [
      {
        imageSrc: 'images/automotive/lightweight/component-04.png',
        title: 'Bumper Beam',
        description: [
          '- Reducing weight by 25% against conventional steel',
          '- Protecting passengers on impact by shock absorption'
        ]
      },
      {
        imageSrc: 'images/automotive/lightweight/component-01.png',
        title: 'Knee Bolster',
        description: [
          '- Reducing weight by 56% against conventional steel',
          '- Protecting knees on impact'
        ]
      }

    ],
    rightComponentInfo: [
      // {
      //   imageSrc: 'images/automotive/lightweight/component-02.png',
      //   title: 'Roof Rack',
      //   description: [
      //     '- Reducing weight by 26% against aluminum',
      //     '- Allowing injection molding process'
      //   ]
      // },
      {
        imageSrc: 'images/automotive/lightweight/component-03.png',
        title: 'Seat Back',
        description: [
          '- Reducing weight by 30% against conventional steel',
          '- minimization the number of components',
          '- Improving the impact safety'
        ]
      },
      {
        imageSrc: 'images/automotive/lightweight/component-05.png',
        title: 'Battery Pack Bottom Panel',
        description: [
          '- Reducing weight by 30% against alumimun',
          '- Protecting the lower part of the electric vehicle battery pack'
        ]
      }
    ]
  })
}
</script>

<style scoped>
.lightweight-component-area {
  display: flex;
  flex-direction: column;
}

.lightweight-component-area .component-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lightweight-component-area .component-area .sec {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.lightweight-component-area .component-area .divider {
  flex-basis: 60px;
}

.lightweight-component-area .component-area .sec .item {
  margin-bottom: 50px;
}

.ctt-t4{
  color: #666;
}

</style>
