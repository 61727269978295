var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "himac", attrs: { id: "wrap" } }, [
    _c(
      "div",
      { staticClass: "w-con-02" },
      [
        _c("div", { staticClass: "sub-visual-section" }, [
          _c("h1", { staticClass: "tt-1 visual-title tac" }, [
            _vm._v(_vm._s(_vm.$t("contactus.text.contactus"))),
          ]),
          _c("p", {
            staticClass: "ctt ctt-t2 visual-desc tac inner glSite",
            domProps: { innerHTML: _vm._s(_vm.$t("contactus.text.inner")) },
          }),
        ]),
        _c("ContactusEUForm"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }