var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [
          _c("div", { staticClass: "header" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [
          _c("div", { staticClass: "table-body" }, [
            _c("img", { attrs: { src: _vm.iconSrc1 } }),
            _c("span", [_vm._v(_vm._s(_vm.mainTxt1))]),
          ]),
        ]),
      ]),
      _c("tr", [
        _c("td", [
          _c("div", { staticClass: "table-body" }, [
            _c("img", { attrs: { src: _vm.iconSrc2 } }),
            _c("span", [_vm._v(_vm._s(_vm.mainTxt2))]),
          ]),
        ]),
      ]),
      _c("tr", [
        _c("td", [
          _c("div", { staticClass: "table-body" }, [
            _c("img", { attrs: { src: _vm.iconSrc3 } }),
            _c("span", [_vm._v(_vm._s(_vm.mainTxt3))]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }