<template>
  <div class="vueslicktem">
    <VueSlickCarousel
      ref="carousel"
      v-bind="carouselSetting"
      @afterChange="onChange"
    >
      <slot></slot>

      <template #customPaging="page">
        <DotPaging
          :currentIndex="page"
        />
      </template>

      <template #prevArrow>
        <div class="arrow-btn prev"></div>
      </template>

      <template #nextArrow>
        <div class="arrow-btn next"></div>
      </template>
    </VueSlickCarousel>

    <slot name="custom" v-bind="customData"></slot>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

import DotPaging from './DotPaging'

export default {
  components: {
    VueSlickCarousel,
    DotPaging
  },

  props: {
    useDot: {
      type: Boolean,
      default: true
    },
    useArrows: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    speed: {
      type: Number,
      default: 1000
    }
  },

  data: () => ({
    selectIndex: 0,
    instance: {}
  }),

  mounted () {
    this.instance = this.$refs.carousel
  },

  computed: {
    carouselSetting () {
      return {
        autoplay: this.autoplay,
        dots: this.useDot,
        arrows: this.useArrows,
        // edgeFriction: 0.35,
        infinite: true,
        speed: this.speed
      }
    },

    customData () {
      return {
        selectIndex: this.selectIndex,
        instance: this.instance
      }
    }
  },

  methods: {
    onChange (idx) {
      this.selectIndex = idx
      this.$emit('change', idx)
    }
  }
}
</script>

<style scoped>
/* .slick-prev:before {
  content: '' !important;
}

.slick-next:before {
  content: '' !important;
}

.arrow-btn{
  width: 12px;
  height: 18px;
}

.arrow-btn.prev {
  background-image: url(https://img.lxhausys.com/public/images/products/carousel/icon_left.png);
}

.arrow-btn.next {
  background-image: url(https://img.lxhausys.com/public/images/products/carousel/icon_right.png);
} */

</style>
