var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-cate" }, [
        _c("div", { staticClass: "location type02" }, [
          _c(
            "ul",
            [
              _c(
                "li",
                {
                  directives: [
                    { name: "hover-cursor", rawName: "v-hover-cursor" },
                  ],
                  staticClass: "ico-home",
                  on: {
                    click: function ($event) {
                      _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("PCD.text.home")))]
              ),
              _vm._l(_vm.location, function (loc, idx) {
                return _c("li", { key: idx }, [_vm._v(" " + _vm._s(loc) + " ")])
              }),
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "container prod-dt-view" },
          [_vm._t("content")],
          2
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("div", { staticClass: "short-link type_sub" }, [
        _c(
          "a",
          {
            staticClass: "item where",
            attrs: { href: _vm.getWheresHref(_vm.$countryCode) },
          },
          [_vm._m(0)]
        ),
        _c(
          "a",
          { staticClass: "item contact", attrs: { href: "support/contactus" } },
          [_vm._m(1)]
        ),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "anchor" }, [
      _c("span", { staticClass: "text" }, [_vm._v("where to buy")]),
      _c("span", { staticClass: "icon_arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "anchor" }, [
      _c("span", { staticClass: "text" }, [_vm._v("contact us")]),
      _c("span", { staticClass: "icon_arrow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }