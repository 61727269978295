var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("COLOR", {
        attrs: { colorList: _vm.dictMap["hue"] },
        model: {
          value: _vm.hue,
          callback: function ($$v) {
            _vm.hue = $$v
          },
          expression: "hue",
        },
      }),
      _c("COLLECTION", {
        attrs: { collectionList: _vm.collectionList, category: _vm.category },
        model: {
          value: _vm.collection,
          callback: function ($$v) {
            _vm.collection = $$v
          },
          expression: "collection",
        },
      }),
      _c("PRD_PATTERN", {
        attrs: { patternList: _vm.dictMap["pattern"] },
        model: {
          value: _vm.pattern,
          callback: function ($$v) {
            _vm.pattern = $$v
          },
          expression: "pattern",
        },
      }),
      _c("FINISH", {
        attrs: { finishList: _vm.dictMap["finish"] },
        model: {
          value: _vm.finish,
          callback: function ($$v) {
            _vm.finish = $$v
          },
          expression: "finish",
        },
      }),
      _c("THICKNESS", {
        attrs: { thickList: _vm.dictMap["thickness"] },
        model: {
          value: _vm.thickness,
          callback: function ($$v) {
            _vm.thickness = $$v
          },
          expression: "thickness",
        },
      }),
      _vm.$countryCode === "_US"
        ? _c("BOOKMATCH", {
            attrs: { bookmatchList: _vm.dictMap["bookmatch"] },
            model: {
              value: _vm.bookmatch,
              callback: function ($$v) {
                _vm.bookmatch = $$v
              },
              expression: "bookmatch",
            },
          })
        : _vm._e(),
      _vm.$countryCode === "_US"
        ? _c("REMARK", {
            attrs: { remarkList: _vm.dictMap["remark"] },
            model: {
              value: _vm.remark,
              callback: function ($$v) {
                _vm.remark = $$v
              },
              expression: "remark",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }