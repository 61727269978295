<template>
  <div class="carousel-dot-area">
    <img />
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

.carousel-dot-area {
  width: 15px;
  height: 15px;
}

.carousel-dot-area img {
  content: url(https://img.lxhausys.com/public/images/products/carousel/dot-default.png);
  width: 100%;
}

.slick-active .carousel-dot-area img {
  content: url(https://img.lxhausys.com/public/images/products/carousel/dot-active.png);
  width: 100%;
}
</style>
