var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c(
        "div",
        { staticClass: "content inspr-view" },
        [
          _vm.contentDetail.content
            ? _c(
                "div",
                { staticClass: "container inspr-box gallery-detail" },
                [
                  _c("div", { staticClass: "gv-side-header main p-ui" }, [
                    _c("div", [
                      _c("div", { staticClass: "location" }, [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hover-cursor",
                                  rawName: "v-hover-cursor",
                                },
                              ],
                              staticClass: "ico-home",
                              on: {
                                click: function ($event) {
                                  _vm.gotolink(
                                    `/${_vm.countryCode.toLowerCase()}/index`
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("gallery.text.home")) + " ")]
                          ),
                          _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("gallery.text.inspiration")) +
                                " "
                            ),
                          ]),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hover-cursor",
                                  rawName: "v-hover-cursor",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  _vm.gotolink(
                                    `/${_vm.countryCode.toLowerCase()}/inspiration/gallery`
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("gallery.text.inspiration_gallery")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "gallery.text.inspiration_gallery_detail"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("h1", { staticClass: "con_title tt-3" }, [
                        _vm._v(_vm._s(_vm.contentDetail.content.title)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "gv-side-root" },
                        [
                          [
                            _vm.segmentsType
                              ? [
                                  _c("span", {
                                    staticClass: "ctt",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.segmentsType.type),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c("span"),
                            _c("span", {
                              staticClass: "ctt",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.contentDetail.attribute.segments
                                ),
                              },
                            }),
                            _c("span"),
                          ],
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("article", { staticClass: "gv-wrap" }, [
                    _c(
                      "section",
                      { staticClass: "gv-view" },
                      [
                        _c("div", { staticClass: "gv-side-header m-ui" }, [
                          _c("p", {
                            staticClass: "tt-3",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.contentDetail.content.title
                              ),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "gv-side-root" },
                            [
                              [
                                _vm.segmentsType
                                  ? [
                                      _c("span", {
                                        staticClass: "ctt",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.segmentsType.type
                                          ),
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _c("span"),
                                _c("span", {
                                  staticClass: "ctt",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.contentDetail.attribute.segments
                                    ),
                                  },
                                }),
                                _c("span"),
                              ],
                            ],
                            2
                          ),
                        ]),
                        _c("div", { staticClass: "img-wrap first" }, [
                          _vm.contentDetail.attribute
                            ? _c("img", {
                                attrs: {
                                  src: `${_vm.$imagePath}${_vm.contentDetail.attribute.mainThumbnail}`,
                                  alt: _vm.contentDetail.content.title,
                                },
                              })
                            : _vm._e(),
                          _vm.contentDetail.attribute
                            ? _c(
                                "div",
                                { staticClass: "num_group" },
                                [
                                  _vm._l(
                                    _vm.contentDetail.attribute.productList,
                                    function (item) {
                                      return item.contentMarkerIndex !==
                                        "none" ||
                                        item.contentMarkerIndex === undefined ||
                                        item.contentMarkerIndex === null
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "num_btn_wrap",
                                                style: {
                                                  top: item.contentTop,
                                                  left: item.contentLeft,
                                                },
                                                on: {
                                                  mouseleave: function (
                                                    $event
                                                  ) {
                                                    _vm.selectMarkerNumber =
                                                      null
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "on",
                                                    style: {
                                                      background:
                                                        _vm.selectMarkerNumber ===
                                                        item.contentMarkerIndex
                                                          ? "rgba(0, 0, 0, 1)"
                                                          : "rgba(0, 0, 0, 0.5)",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.selectMarkerNumber =
                                                          item.contentMarkerIndex
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.contentMarkerIndex
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "transition",
                                                  { attrs: { name: "fade" } },
                                                  [
                                                    item.contentMarkerIndex ===
                                                    _vm.selectMarkerNumber
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "btn_bubble_wrap",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "left",
                                                                  },
                                                                  [
                                                                    item.detail
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "img_wrap",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: `${_vm.$imagePath}${item.detail.attribute.pf_thumb}`,
                                                                                    alt: "thumb",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "right",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "txt_wrap",
                                                                      },
                                                                      [
                                                                        item.detail
                                                                          ? [
                                                                              item
                                                                                .detail
                                                                                .attribute
                                                                                .discontinued_color ===
                                                                                "true" ||
                                                                              item
                                                                                .detail
                                                                                .attribute
                                                                                .dictClr ===
                                                                                "true"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "tag",
                                                                                      domProps:
                                                                                        {
                                                                                          innerHTML:
                                                                                            _vm._s(
                                                                                              "Discontinued"
                                                                                            ),
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          : _vm._e(),
                                                                        item.detail
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "name",
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        _vm.getCategoryName(
                                                                                          item
                                                                                            .detail
                                                                                            .parents,
                                                                                          1
                                                                                        )
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "gray",
                                                                            domProps:
                                                                              {
                                                                                innerHTML:
                                                                                  _vm._s(
                                                                                    item.title
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                        item.detail
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "gray",
                                                                                domProps:
                                                                                  {
                                                                                    innerHTML:
                                                                                      _vm._s(
                                                                                        item
                                                                                          .detail
                                                                                          .attribute
                                                                                          .code
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "bottom",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.moveToContentDetail(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "btn_wrap",
                                                                    attrs: {
                                                                      href: "javascript:void(0)",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "View more"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "btn-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn_view_large",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.isLayerModal = true
                                  },
                                },
                              },
                              [_c("span", { staticClass: "icon" })]
                            ),
                          ]),
                        ]),
                        _vm.contentDetail.attribute
                          ? _vm._l(
                              _vm.contentDetail.attribute.subThumbnailList,
                              function (item, index) {
                                return _c("div", { staticClass: "img-wrap" }, [
                                  _c("img", {
                                    attrs: {
                                      src: `${_vm.$imagePath}${item.subThumbnail}`,
                                      alt: "thumb",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickSubThumbnail(
                                          index
                                        )
                                      },
                                    },
                                  }),
                                ])
                              }
                            )
                          : _vm._e(),
                        _vm.contentDetail.attribute
                          ? _c("div", { staticClass: "btm_desc" }, [
                              _c("p", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.contentDetail.attribute.description
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.contentDetail.attribute
                          ? _c(
                              "div",
                              { staticClass: "inspiration_gallery_hash m-ui" },
                              [
                                _c(
                                  "ul",
                                  [
                                    _vm._l(
                                      _vm.contentDetail.attribute
                                        .applicationList,
                                      function (item) {
                                        return [
                                          _c("li", [
                                            _c("div", [
                                              _c("a", {
                                                attrs: {
                                                  href: "javascript:void(0)",
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(item.text),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleClcikBadge(
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.contentDetail.attribute
                      ? _c("section", { staticClass: "gv-side show" }, [
                          _c("div", { staticClass: "gv-side-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "gv-side-list" },
                              [
                                _vm._l(
                                  _vm.contentDetail.attribute.sideProductList,
                                  function (itemList) {
                                    return _vm.contentDetail.attribute
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gv-side-title_area",
                                            },
                                            [
                                              itemList[0].detail
                                                ? _c("span", {
                                                    staticClass:
                                                      "gv-side-title",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getCategoryName(
                                                          itemList[0].detail
                                                            .parents,
                                                          1
                                                        )
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              itemList[0].detail
                                                ? _c("span", {
                                                    staticClass: "gv-side-txt",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getCategoryName(
                                                          itemList[0].detail
                                                            .parents,
                                                          2
                                                        )
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              itemList,
                                              function (itemList, index) {
                                                return _c(
                                                  "li",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "thumb on",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.moveToContentDetail(
                                                              itemList
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        itemList.detail
                                                          ? _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: `${_vm.$imagePath}${itemList.detail.attribute.pf_thumb}`,
                                                                    alt: "thumb",
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        itemList.detail
                                                          ? [
                                                              itemList.detail
                                                                .attribute
                                                                .discontinued_color ===
                                                                "true" ||
                                                              itemList.detail
                                                                .attribute
                                                                .dictClr ===
                                                                "true"
                                                                ? _c("span", {
                                                                    staticClass:
                                                                      "tag",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          "Discontinued"
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "info" },
                                                      [
                                                        _c("strong", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              itemList.title
                                                            ),
                                                          },
                                                        }),
                                                        _c("strong"),
                                                        _c("span"),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      : _vm._e()
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.contentDetail.attribute
                    ? _c(
                        "div",
                        { staticClass: "inspiration_gallery_hash p-ui" },
                        [
                          _c(
                            "ul",
                            [
                              _vm._l(
                                _vm.contentDetail.attribute.applicationList,
                                function (item) {
                                  return [
                                    _c("li", [
                                      _c("div", [
                                        _c("a", {
                                          attrs: { href: "javascript:void(0)" },
                                          domProps: {
                                            innerHTML: _vm._s(item.text),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClcikBadge(item)
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.contentDetail.related
                    ? [
                        _c("div", { staticClass: "ins-box" }, [
                          _c("h2", { staticClass: "prod-title" }, [
                            _vm._v("Related Contents"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "related_slider" },
                            [
                              _c(
                                "VueSlickCarousel",
                                _vm._b(
                                  {},
                                  "VueSlickCarousel",
                                  _vm.slickSettings,
                                  false
                                ),
                                _vm._l(
                                  _vm.contentDetail.related,
                                  function (item, index) {
                                    return index <= 2
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "imgwrap",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleClickrelatedContent(
                                                      item.content
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `${_vm.$imagePath}${item.attribute.mainThumbnail}`,
                                                    alt: item.content.title,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "txtwrap" },
                                              [
                                                _c("ul", [
                                                  _c(
                                                    "li",
                                                    { staticClass: "type" },
                                                    [
                                                      _vm._l(
                                                        item.attribute
                                                          .product_cateText,
                                                        function (productItem) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    productItem
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "li",
                                                    { staticClass: "tit" },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.content.title
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "li",
                                                    { staticClass: "tag" },
                                                    [
                                                      _vm._l(
                                                        item.attribute
                                                          .applicationList,
                                                        function (appItem) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    `#${appItem.text}`
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.isLayerModal
                      ? _c(
                          "div",
                          {
                            staticClass: "layer-popup layer-gallery-img",
                            style: { display: "block" },
                          },
                          [
                            _c("div", { staticClass: "popup pop-close" }, [
                              _c(
                                "div",
                                { staticClass: "ins-box" },
                                [
                                  _c(
                                    "VueSlickCarousel",
                                    _vm._b(
                                      { ref: "slickModal" },
                                      "VueSlickCarousel",
                                      _vm.slickPopSettings,
                                      false
                                    ),
                                    [
                                      _vm.contentDetail.attribute
                                        ? [
                                            _c("div", { staticClass: "item" }, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `${_vm.$imagePath}${_vm.contentDetail.attribute.mainThumbnail}`,
                                                      alt: "thumb",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                            _vm._l(
                                              _vm.contentDetail.attribute
                                                .subThumbnailList,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  { staticClass: "item" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: `${_vm.$imagePath}${item.subThumbnail}`,
                                                            alt: "thumb",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-close",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.isLayerModal = false
                                    },
                                  },
                                },
                                [_vm._v(" close")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _c("SampleOrderPopComponent"),
        ],
        1
      ),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }