<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content au-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">Home</li>
          <li>About us</li>
          <li>Career</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Career) -->
      <div class="container au-box au-box02">
        <div class="w-con-02 ba-text">
          <div class="sub-visual-section">
            <h1 class="visual-title tt-1 tac">Career</h1>
          </div>
          <!-- <div class="t-list-box">
            <p class="tit-03"></p>
          </div> -->
          <div class="t-list-box t-type-01">
            We’re looking for the best people to work at LX Hausys.
          </div>
        </div>

        <div class="cr-box">
          <div class="w-con">
            <iframe src="https://recruiting.paylocity.com/recruiting/jobs/List/5268/LG-HAUSYS-AMERICA-INC" height="1200" width="100%" style="border:0;"></iframe>
          </div>
        </div>
      </div>
      <!-- //container (Career) -->
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
export default {
  name: 'career',
  components: {
    HeaderComponent,
    GnbComponent,
    FooterComponent
  },

  data () {
    return {
      location: [],
      countryCode: '',
      countryCodeSub: ''
    }
  },

  methods: {
    gotolink (url) {
      location.href = url
    }
  },

  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  }
}
</script>

<style scoped>

</style>
