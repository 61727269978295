import request from './request'

const urlPrefix = ''
const ProductService = {
  getClickUp (id) {
    return request({
      url: `${urlPrefix}/product/clickUp?id=${id}`,
      method: 'get'
    })
  },
  getContent (id) {
    return request({
      url: `${urlPrefix}/product/bundleDetail?id=${id}`,
      method: 'get'
    })
  },
  getContentBySlugAndCategory (cateCode, slug) {
    return request({
      url: `${urlPrefix}/product/bundleDetail?cateCode=${cateCode}&slug=${slug}`,
      method: 'get'
    })
  },
  getContentByIds (ids) {
    return request({
      url: `${urlPrefix}/product/details`,
      method: 'post',
      data: ids
    })
  },
  getCompareList (ids) {
    return request({
      url: `${urlPrefix}/product/compareDetails`,
      method: 'post',
      data: ids
    })
  },
  getContentByIdsLike (ids, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/detailsLike?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: ids
    })
  },
  getContentList (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId]
      }
    })
  },
  getContentSearchList (categoryId) {
    return request({
      url: `${urlPrefix}/product/searchList`,
      method: 'post',
      data: {
        categoryIds: [categoryId]
      }
    })
  },
  getContentListType (categoryId) {
    return request({
      url: `${urlPrefix}/product/searchType`,
      method: 'post',
      data: {
        searchType: true,
        categoryIds: [categoryId]
      }
    })
  },
  getContentListChildCate (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true
      }
    })
  },
  getSearch (categoryId, text, dictionaryValues) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        dictionaryValues: dictionaryValues,
        text: text
      }
    })
  },
  getContentListChild (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true,
        orderField: ['sort', 'createdAt'],
        orderType: ['asc', 'desc']
      }
    })
  },
  getContentListChildType (categoryId) {
    return request({
      url: `${urlPrefix}/product/searchType`,
      method: 'post',
      data: {
        searchType: true,
        categoryIds: [categoryId],
        containChildCategory: true,
        orderField: ['sort', 'createdAt'],
        orderType: ['asc', 'desc']
      }
    })
  },
  getContentListTap (categoryId, dictionaryValues, text, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryValues: dictionaryValues,
        text: text,
        containChildCategory: true
      }
    })
  },
  getContentListTapField (categoryId, dictionaryValues, text, pageIndex, pageSize, field, type) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryValues: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField: field,
        orderType: type
      }
    })
  },
  getDictionaryCondition (categoryId, dictionaryValues, text, pageIndex, pageSize, field, type, fields) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryCondition: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField: field,
        orderType: type,
        fields: fields
      }
    })
  },

  getAllProduct (categoryId, dictionaryValues, text, pageIndex, pageSize, field, type, fields) {
    return request({
      url: `${urlPrefix}/product/searchAll?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryCondition: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField: field,
        orderType: type,
        fields: fields
      }
    })
  },
  getAllProductByCode (cateCodes, dictionaryValues, text, pageIndex, pageSize, field, type, fields) {
    return request({
      url: `${urlPrefix}/product/searchAll?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryCodes: cateCodes,
        dictionaryCondition: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField: field,
        orderType: type,
        fields: fields
      }
    })
  },
  getDictionaryContent (categoryId, dictionaryValues, text, pageIndex, pageSize, field, type, fields) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        dictionaryCondition: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField: field,
        orderType: type,
        fields: fields
      }
    })
  },
  getCollection (code) {
    return request({
      url: `${urlPrefix}/product/collection?code=${code}`,
      method: 'get'
    })
  },
  getContentFileList (contentId) {
    return request({
      url: `${urlPrefix}/directive/contentFileList?contentId=${contentId}`,
      method: 'get'
    })
  },
  getCategoryCode (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: 'get'
    })
  },
  getCategoryListAttr (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}?containsAttribute=true`,
      method: 'get'
    })
  },
  getCategoryAttr (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}?containsAttribute=true`,
      method: 'get'
    })
  },
  getCategoryId (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}`,
      method: 'get'
    })
  },
  getDictionary (code) {
    return request({
      url: `${urlPrefix}/product/dict?code=${code}`,
      method: 'get'
    })
  },
  getDictionaryList (code) {
    return request({
      url: `${urlPrefix}/product/dictList?code=${code}`,
      method: 'get'
    })
  },

  getCateChildren (code) {
    return request({
      url: `${urlPrefix}/product/cateChildren?code=${code}`,
      method: 'get'
    })
  },

  getCateChildrenAttr (id) {
    return request({
      url: `${urlPrefix}/product/cateChildrenAttr?id=${id}`,
      method: 'get'
    })
  },
  getContentTap (categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        containChildCategory: true
      }
    })
  },
  getContentAttr (categoryId, dictionaryValues) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        dictionaryValues: dictionaryValues,
        containChildCategory: true
      }
    })
  },
  getCategoryAttributeList (id) {
    return request({
      url: `${urlPrefix}/category/list`,
      method: 'post',
      data: {
        parentId: id
      }
    })
  },
  getCategoryChildList (id) {
    return request({
      url: `${urlPrefix}/category/list`,
      method: 'post',
      data: {
        categoryId: id
      }
    })
  },
  getCategoryAttributeTap (id, pageIndex, pageSize) {
    const pageIndexString = pageIndex ? `?pageIndex=${pageIndex}` : ''
    const pageSizeString = pageSize ? `&pageSize=${pageSize}` : ''

    return request({
      url: `${urlPrefix}/category/list${pageIndexString}${pageSizeString}`,
      method: 'post',
      data: {
        typeCode: 'COLLECTION',
        containChild: true,
        parentId: id
      }
    })
  },

  getCollectionList (id, pageIndex, pageSize) {
    const pageIndexString = pageIndex ? `?pageIndex=${pageIndex}` : ''
    const pageSizeString = pageSize ? `&pageSize=${pageSize}` : ''

    return request({
      url: `${urlPrefix}/category/collectionList${pageIndexString}${pageSizeString}`,
      method: 'post',
      data: {
        containChild: true,
        parentId: id
      }
    })
  },

  getEachCategoryContent (categoryIds, pageIndex, pageSize) {
    const pageIndexString = pageIndex ? `?pageIndex=${pageIndex}` : ''
    const pageSizeString = pageSize ? `&pageSize=${pageSize}` : ''
    return request({
      url: `${urlPrefix}/product/searchEachCategory${pageIndexString}${pageSizeString}`,
      method: 'post',
      data: {
        categoryIds
      }
    })
  },

  getDictionaryValueList (code) {
    return request({
      url: `${urlPrefix}/product/dictionaryList?code=${code}`,
      method: 'post'
    })
  }
}

export default ProductService
