<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>METAL PATTERN</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box color_square">
          <span class="checkbox_more no_line">
              <li v-for="patternmtl in metalpatternList" :data-chk="patternmtl.id.value">
                <input type="checkbox" :id="'pattern_m_' + patternmtl.id.value" name="METALPATTERN" v-model="selected" :value="patternmtl.id.value" />
                <label :for="'pattern_m_'+`${patternmtl.id.value}`">
                <span></span>
                {{ patternmtl.text }}
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'MetalPatternFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'metalpatternList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-metalPattern-filter', this.removeMetalPattern)
    EventBus.$on('update-metalPattern-filters', this.updateMetalPatterns)

    if (this.$store.state.filterState && this.$store.state.filterState.pattern_m) {
      this.selected = this.$store.state.filterState.pattern_m
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-metalPattern-filter', this.removeMetalPattern)
    EventBus.$on('update-metalPattern-filters', this.updateMetalPatterns)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeMetalPattern (metalPatternValue) {
      this.selected = this.selected.filter(value => value !== metalPatternValue)
    },
    updateMetalPatterns (metalPatterns) {
      this.selected = metalPatterns
    }
  }
}
</script>
