var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content order-view" }, [
        _vm._m(0),
        _c("div", { staticClass: "container order-inp-box oder-del-box" }, [
          _vm.orderList.length > 0
            ? _c("div", { staticClass: "w-con-02 inner" }, [
                _c("h1", { staticClass: "tt-1" }, [_vm._v("Order Summary")]),
                _c(
                  "div",
                  { staticClass: "col-02 order-tbl order-tbl-big-box" },
                  [
                    _c("div", { staticClass: "tbl-01 order-tbl01" }, [
                      _c("div", { staticClass: "tbl-box type03" }, [
                        _c("table", [
                          _c("caption", [_vm._v("Shipping form")]),
                          _vm._m(1),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "order-small-word order-tbl-box",
                                  attrs: { scope: "row" },
                                },
                                [_vm._v("Email Address")]
                              ),
                              _c("td", { staticClass: "order-tbl-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "btn-ab order-edit-btn-box" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "t-type-01 order-small-word",
                                      },
                                      [_vm._v(_vm._s(_vm.memberInfo.email))]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-r btn btn-line-gray order-edit-btn",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editInfo()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "no-arrow order-small-word",
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "order-small-word order-tbl-box",
                                  attrs: { scope: "row" },
                                },
                                [_vm._v("Shipping Address")]
                              ),
                              _c("td", { staticClass: "order-tbl-box" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "t-type-01 btn-ab order-edit-btn-box order-small-word",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(_vm.memberInfo.addr1)),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.memberInfo.addr2)),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.memberInfo.city)),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getStateNm(
                                            _vm.memberInfo.stateCd,
                                            _vm.memberInfo.country
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(_vm.memberInfo.zipCd)),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getCountryNm(
                                            _vm.memberInfo.country
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-r btn btn-line-gray order-edit-btn",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editInfo()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "no-arrow order-small-word",
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._m(2),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "tbl-02 order-tbl02" }, [
                      _c("div", { staticClass: "tbl-input type02" }, [
                        _c("table", [
                          _c("caption", [_vm._v("order form (Product)")]),
                          _vm._m(3),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.orderList, function (item, index) {
                                return _c("tr", { key: item.content.id }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "prod-box order-tbl-box",
                                      attrs: { colspan: "2" },
                                    },
                                    [
                                      _c("div", { staticClass: "col-02" }, [
                                        _c("div", { staticClass: "img-box" }, [
                                          _c("img", {
                                            staticStyle: { width: "210px" },
                                            attrs: {
                                              src: _vm.getImage(item),
                                              alt: "image",
                                            },
                                          }),
                                        ]),
                                        _c("div", { staticClass: "txt-box" }, [
                                          _c("ul", [
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "txt-01 order-small-word",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.content.attribute.code
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "txt-02 order-small-word",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.content.content.title
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "txt order-small-word",
                                              },
                                              [
                                                _c("span", [_vm._v("Size")]),
                                                _vm._v(
                                                  _vm._s(item.sampleInfo.size)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "txt order-small-word",
                                              },
                                              [
                                                _c("span", [_vm._v("Qty")]),
                                                _vm._v(_vm._s(item.qty)),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "txt order-small-word",
                                              },
                                              [
                                                _c("span", [_vm._v("Each")]),
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      item.sampleInfo.price
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "txt-price order-small-word",
                                            },
                                            [
                                              _vm._v(
                                                "$ " +
                                                  _vm._s(
                                                    _vm.calcOrder(
                                                      item.sampleInfo.price,
                                                      item.qty
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "order-small-word order-tbl-box",
                                    attrs: { scope: "row" },
                                  },
                                  [_vm._v("Subtotal")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "order-small-word order-tbl-box",
                                  },
                                  [_vm._v("$ " + _vm._s(_vm.calcSubTotal()))]
                                ),
                              ]),
                              _vm._m(4),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "tbl-input type02 tbl-total" }, [
                        _c("table", [
                          _c("caption", [
                            _vm._v("order form (Product Grand Total)"),
                          ]),
                          _vm._m(5),
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "order-small-word order-tbl-box",
                                  attrs: { scope: "row" },
                                },
                                [_vm._v("Grand Total")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "order-small-word order-tbl-box",
                                },
                                [_vm._v("$ " + _vm._s(_vm.calcSubTotal()))]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.order()
                              },
                            },
                          },
                          [_c("span", [_vm._v("Pay Now")])]
                        ),
                      ]),
                      _c("label", { staticClass: "inp-chk" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agree,
                              expression: "agree",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.agree)
                              ? _vm._i(_vm.agree, null) > -1
                              : _vm.agree,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.agree,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.agree = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.agree = $$c
                              }
                            },
                          },
                        }),
                        _c("span", [
                          _vm._v("I have read and agree to the "),
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.openPrivacy()
                                },
                              },
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                          _vm._v(" and Website "),
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.openTerms()
                                },
                              },
                            },
                            [_vm._v("Terms of Use")]
                          ),
                          _vm._v("."),
                        ]),
                      ]),
                      _c(
                        "form",
                        {
                          attrs: {
                            id: "paypalForm",
                            action: _vm.getPaypalUrl() + "/cgi-bin/webscr",
                            method: "post",
                          },
                        },
                        [
                          _vm._l(_vm.paypalForm, function (val, prop) {
                            return [
                              prop !== "items"
                                ? _c("input", {
                                    attrs: { type: "hidden", name: prop },
                                    domProps: { value: val },
                                  })
                                : _vm._e(),
                            ]
                          }),
                          _vm._l(_vm.paypalForm.items, function (item, idx) {
                            return [
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "item_name_" + (idx + 1),
                                },
                                domProps: { value: item.item_name },
                              }),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "item_number_" + (idx + 1),
                                },
                                domProps: { value: item.item_number },
                              }),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "amount_" + (idx + 1),
                                },
                                domProps: { value: item.amount },
                              }),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "quantity_" + (idx + 1),
                                },
                                domProps: { value: item.quantity },
                              }),
                            ]
                          }),
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: "bn",
                              value:
                                "PP-BuyNowBF:btn_buynowCC_LG.gif:NonHosted",
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: {
                              type: "image",
                              src: "https://www.paypalobjects.com/en_US/GB/i/btn/btn_buynowCC_LG.gif",
                              border: "0",
                              name: "submit",
                              alt: "PayPal – The safer, easier way to pay online!",
                            },
                          }),
                          _c("img", {
                            attrs: {
                              alt: "pixel",
                              border: "0",
                              src: "https://www.paypalobjects.com/en_GB/i/scr/pixel.gif",
                              width: "1",
                              height: "1",
                            },
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
        _c("li", [_vm._v("Header")]),
        _c("li", [_vm._v("Cart")]),
        _c("li", [_vm._v("Order Sample")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "185px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticClass: "order-small-word order-tbl-box",
          attrs: { scope: "row" },
        },
        [_vm._v("Payment Method")]
      ),
      _c("td", { staticClass: "order-tbl-box" }, [
        _c("div", { staticClass: "btn-ab" }, [
          _c("label", { staticClass: "inp-rdo" }, [
            _c("input", {
              attrs: { type: "radio", name: "rdo00", checked: "" },
            }),
            _c("span", [
              _vm._v(
                "Paypal (Guest checkout available using debit or credit card)"
              ),
            ]),
          ]),
          _c("button", { staticClass: "btn-pp", attrs: { type: "button" } }, [
            _vm._v("PayPal"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "250px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        {
          staticClass: "order-small-word order-tbl-box",
          attrs: { scope: "row" },
        },
        [_vm._v("Shipping & Handling")]
      ),
      _c("td", { staticClass: "order-small-word order-tbl-box" }, [
        _vm._v("FedEx Ground"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "250px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }