<template>
  <ul>
    <COMPARE></COMPARE>
    <PETFURNITUREFILM :petList="dictMap['pet']" v-model="pet"></PETFURNITUREFILM>
    <PVCDECOFOIL :pvcList="dictMap['pvc']" v-model="pvc"></PVCDECOFOIL>
    <ACRYLICFURNITUREFILM :acrylicList="dictMap['acrylic']" v-model="acrylic"></ACRYLICFURNITUREFILM>
    <IS_NEW v-model="isNew"></IS_NEW>
    <SOLIDCOLOR :colorsList="dictMap['hue_sld']" v-model="hue_sld"></SOLIDCOLOR>
    <METALCOLOR :mtlcolorList="dictMap['hue_m']" v-model="hue_m"></METALCOLOR>
    <WOODCOLOR :huewList="dictMap['hue_w']" v-model="hue_w"></WOODCOLOR>
    <STONECOLOR :stncolorList="dictMap['hue_stn']" v-model="hue_stn"></STONECOLOR>
    <SOLIDPATTERN :sldpatternList="dictMap['pattern_sld']" v-model="pattern_sld"></SOLIDPATTERN>
    <METALPATTERN :metalpatternList="dictMap['pattern_m']" v-model="pattern_m"></METALPATTERN>
    <WOODPATTERN :woodpatternList="dictMap['pattern_w']" v-model="pattern_w"></WOODPATTERN>
    <STONEPATTERN :stnpatternList="dictMap['pattern_stn']" v-model="pattern_stn"></STONEPATTERN>

  </ul>
</template>

<script>
import { mapState } from 'vuex'
import COMPARE from './component/COMPARE.vue'
import COLLECTION from './component/COLLECTION.vue'
import IS_NEW from './component/IS_NEW.vue'
import SOLIDCOLOR from './component/SOLIDCOLOR.vue'
import METALCOLOR from './component/METALCOLOR.vue'
import WOODCOLOR from './component/WOODCOLOR.vue'
import STONECOLOR from './component/STONECOLOR.vue'
import SOLIDPATTERN from './component/SOLIDPATTERN.vue'
import METALPATTERN from './component/METALPATTERN.vue'
import WOODPATTERN from './component/WOODPATTERN.vue'
import STONEPATTERN from './component/STONEPATTERN.vue'
import PVCDECOFOIL from './component/PVCDECOFOIL.vue'
import PETFURNITUREFILM from './component/PETFURNITUREFILM.vue'
import ACRYLICFURNITUREFILM from './component/ACRYLICFURNITUREFILM.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    WOODCOLOR,
    COMPARE,
    COLLECTION,
    IS_NEW,
    SOLIDCOLOR,
    METALCOLOR,
    STONECOLOR,
    SOLIDPATTERN,
    METALPATTERN,
    WOODPATTERN,
    STONEPATTERN,
    PVCDECOFOIL,
    PETFURNITUREFILM,
    ACRYLICFURNITUREFILM
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      collection: [],
      pattern: [],
      isNew: [],
      hue_sld: [],
      hue_m: [],
      hue_w: [],
      hue_stn: [],
      pattern_sld: [],
      pattern_m: [],
      pattern_w: [],
      pattern_stn: [],
      pvc: [],
      pet: [],
      acrylic: []
    }
  },
  watch: {},
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.collection, vm.hue_sld, vm.hue_m, vm.hue_w,
      vm.hue_stn, vm.pattern_sld, vm.pattern_m, vm.pattern_w, vm.pattern_stn, vm.pet, vm.pvc, vm.acrylic, vm.isNew], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {}
}
</script>
