var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "eu-otherproducts" }, [
        _c("div", { staticClass: "location type03" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("other.text.home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.himacs")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.otherPD")))]),
          ]),
        ]),
        _c("div", { staticClass: "container prod-fd-view himacs-op" }, [
          _c("div", { staticClass: "visual visual-detail" }, [
            _c(
              "div",
              {
                staticClass: "visual-image",
                style: {
                  backgroundImage:
                    "url( " + _vm.$imagePath + _vm.pfBanImg + ")",
                },
              },
              [
                _c("div", { staticClass: "txt-box" }, [
                  _c("h1", { staticClass: "tt-26" }, [
                    _vm._v(_vm._s(_vm.pageTitle)),
                  ]),
                  _c("p", {
                    staticClass: "t-type-05 tac",
                    domProps: { innerHTML: _vm._s(_vm.brief) },
                  }),
                  _vm._m(0),
                ]),
                _c("div", { staticClass: "bgdim" }),
              ]
            ),
          ]),
          _c("p", { staticClass: "ctt photo-credits ta-r" }, [
            _vm.photoCredit != ""
              ? _c("span", { staticClass: "pc-br" }, [
                  _vm._v(" " + _vm._s(_vm.photoCredit) + " "),
                ])
              : _vm._e(),
            _vm.photoCredit2 != ""
              ? _c("span", { staticClass: "pc-br" }, [
                  _vm._v(" " + _vm._s(_vm.photoCredit2) + " "),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "col-02 inspr-view fd-view" }, [
            _c(
              "div",
              {
                staticClass: "search-box pc-only",
                attrs: { id: "filterSearch" },
              },
              [
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    attrs: {
                      id: "textSearch",
                      type: "text",
                      placeholder: _vm.$t("PF.text.searchText"),
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                  }),
                  _c("i", {
                    staticClass: "icon-search gray",
                    attrs: { id: "searchBtn" },
                  }),
                ]),
                _c("div", { staticClass: "drop-list inner" }, [
                  _c("ul", [
                    _c("li", { staticClass: "on" }, [
                      _c("div", { staticClass: "tit" }, [
                        _c("span", { staticClass: "tt-sub" }, [
                          _vm._v(_vm._s(_vm.$t("other.text.otherPD"))),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn-view",
                            attrs: { type: "button" },
                          },
                          [_vm._v("list view")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "i-box type01 cont",
                          staticStyle: { display: "block" },
                        },
                        [
                          _c(
                            "ul",
                            [
                              _c("li", [
                                _c("label", { staticClass: "inp-rdo" }, [
                                  _c("input", {
                                    attrs: { type: "radio", name: "rdo00" },
                                    domProps: {
                                      checked: _vm.cateName === "ALL",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cateTypeChange(
                                          "ALL",
                                          0,
                                          _vm.allCategoryId
                                        )
                                      },
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("other.text.all"))),
                                  ]),
                                ]),
                              ]),
                              _vm._l(_vm.otherTypeList, function (type, index) {
                                return _c("li", [
                                  _c("label", { staticClass: "inp-rdo" }, [
                                    _c("input", {
                                      attrs: {
                                        type: "radio",
                                        name: "rdo0" + (index + 1),
                                      },
                                      domProps: {
                                        checked:
                                          _vm.cateName ===
                                          type.category.code.split("_")[2],
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.cateTypeChange(
                                            type.category.code.split("_")[2],
                                            index + 1,
                                            type.category.id
                                          )
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(type.category.name)),
                                    ]),
                                  ]),
                                ])
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                    _c("li", { staticClass: "on" }, [
                      _c("div", { staticClass: "tit" }, [
                        _c("span", { staticClass: "tt-sub" }, [
                          _vm._v(_vm._s(_vm.$t("other.text.use"))),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn-view btn-collection",
                            attrs: { type: "button" },
                          },
                          [_vm._v("USE")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "i-box type02 cont",
                          staticStyle: { display: "block" },
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(_vm.typeUSE, function (use, index) {
                              return _c(
                                "li",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.typeSearch(
                                        "cate_use",
                                        use.text,
                                        use.id.value,
                                        "use"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("label", { staticClass: "inp-chk" }, [
                                    _c("input", {
                                      staticClass: "Collection",
                                      class: {
                                        checked: _vm.typeUSE.length == 1,
                                      },
                                      attrs: {
                                        type: "checkbox",
                                        id: "use_" + use.id.value,
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(use.text))]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.inqShow,
                            expression: "inqShow",
                          },
                        ],
                        staticClass: "on",
                      },
                      [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", { staticClass: "tt-sub" }, [
                            _vm._v(_vm._s(_vm.$t("other.text.install"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-collection",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("other.text.install")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(
                                _vm.typeInstall,
                                function (install, index) {
                                  return _c(
                                    "li",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.typeSearch(
                                            "cate_install",
                                            install.text,
                                            install.id.value,
                                            "install"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "Collection",
                                          attrs: {
                                            type: "checkbox",
                                            id: "install_" + install.id.value,
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(install.text)),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isMobile() || _vm.filterShow,
                        expression: "isMobile() || filterShow",
                      },
                    ],
                    staticClass: "filter-clos",
                    on: {
                      click: function ($event) {
                        return _vm.clickFilter()
                      },
                    },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "prod-list-box other-pf" }, [
              _vm._m(1),
              _c("div", { staticClass: "tab-ui inner" }, [
                _c("div", { staticClass: "filter-arae mb-only" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openSearchPopup("layer-search-box")
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("PF.button.filter_by"))),
                      _c("i"),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "sort-box mt-0" }, [
                  _c("div", { staticClass: "sel-box" }, [
                    _c(
                      "button",
                      { staticClass: "btn-sel", attrs: { type: "button" } },
                      [_c("span", [_vm._v(" " + _vm._s(_vm.sortBy) + " ")])]
                    ),
                    _c("div", { staticClass: "sel-list" }, [
                      _c("ul", { staticClass: "box" }, [
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.sortByChange(
                                    _vm.$t("other.text.popu"),
                                    "desc",
                                    "clicks"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("other.text.popu")))]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.sortByChange(
                                    _vm.$t("other.text.Asc"),
                                    "asc",
                                    "title"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("other.text.Asc")))]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.sortByChange(
                                    _vm.$t("other.text.Desc"),
                                    "desc",
                                    "title"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("other.text.Desc")))]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.sortByChange(
                                    _vm.$t("other.text.Newest"),
                                    "desc"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("other.text.Newest")))]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "select-view" }, [
                  _c(
                    "ul",
                    { staticClass: "list-filter-key" },
                    [
                      _vm._l(_vm.dicTextList, function (filter) {
                        return _c(
                          "li",
                          {
                            staticClass: "sel",
                            on: {
                              click: function ($event) {
                                return _vm.colorDelete(
                                  filter.subject,
                                  filter.name,
                                  filter.id,
                                  filter.type
                                )
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(filter.name))]),
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v("✕"),
                            ]),
                          ]
                        )
                      }),
                      _vm.dicTextList.length > 0
                        ? _c("li", { staticClass: "sel del_all" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-all-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.allTypeDelete()
                                  },
                                },
                              },
                              [_vm._m(2)]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm.textShow
                  ? _c("div", { staticClass: "please-note-box" }, [
                      _c("p", { staticClass: "pn-title" }, [
                        _vm._v(_vm._s(_vm.$t("other.text.note")) + " :"),
                      ]),
                      this.cateName !== "BASINS"
                        ? _c("ol", { staticClass: "pn-num-list" }, [
                            _c("li", [
                              _vm._v(_vm._s(_vm.$t("other.text.noteText1"))),
                            ]),
                            _c("li", [
                              _vm._v(_vm._s(_vm.$t("other.text.noteText2"))),
                            ]),
                          ])
                        : _c("ol", { staticClass: "pn-num-list" }, [
                            _c("li", [
                              _vm._v(
                                _vm._s(_vm.$t("other.text.noteText_basins"))
                              ),
                            ]),
                          ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "tab-con tab01 on" }, [
                  _c("div", { staticClass: "list-type-view row-04" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.contentList, function (content, index) {
                        return _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "over",
                              on: {
                                click: function ($event) {
                                  return _vm.openPopUp(content)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "img-box cursor-pointer" },
                                [
                                  _c("span", { staticClass: "new" }, [
                                    _vm._v(_vm._s(content.parents[0].name)),
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath +
                                        content.attribute.pc_thunb_prd,
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "info" }, [
                            _c("ul", [
                              _c("li", { staticClass: "txt-01" }, [
                                _vm._v(
                                  " " + _vm._s(content.content.title) + " "
                                ),
                              ]),
                              _c("li", { staticClass: "txt-03" }, [
                                _vm._v(
                                  " " + _vm._s(content.attribute.prd_code) + " "
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _vm.contentLength > (_vm.pageIndex / 12) * 12 * 12
                    ? _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-arr-down",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.loadMore(_vm.pageIndex + 1)
                              },
                            },
                          },
                          [_c("span", [_vm._v("See More")])]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "short-link type02 mb-64" }, [
                _c("a", { attrs: { href: "support/contactus" } }, [
                  _c("span", { staticClass: "ico-07" }, [
                    _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                  ]),
                ]),
                _c("a", { attrs: { href: "pros/documentation/viatera" } }, [
                  _c("span", { staticClass: "ico-02" }, [
                    _vm._v(_vm._s(_vm.$t("PCD.text.documentation"))),
                  ]),
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "support/sales-representative/continenteu/himacs",
                    },
                  },
                  [
                    _c("span", { staticClass: "ico-19" }, [
                      _vm._v(_vm._s(_vm.$t("other.text.sales_rep"))),
                    ]),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.lxhausysusa.com/showroom/index.html",
                    },
                  },
                  [
                    _c("span", { staticClass: "ico-06" }, [
                      _vm._v(_vm._s(_vm.$t("other.text.showroom"))),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "layer-popup layer-other hi-op-pop" }, [
            _c("div", { staticClass: "popup pop-close" }, [
              _c("div", { staticClass: "pop-other scr-y" }, [
                _c("div", { staticClass: "op-pop-pic" }, [
                  _c(
                    "div",
                    { staticClass: "op-pop-slide-top" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "op-pop-imgbox on",
                          attrs: { id: "popimage_01", name: "popImage" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.$imagePath +
                                _vm.contentAttribute.pc_thunb_prd,
                              alt: "slider_img01",
                            },
                          }),
                        ]
                      ),
                      _vm._l(_vm.contentFile, function (image, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "op-pop-imgbox",
                            attrs: {
                              id: "popimage_0" + (index + 2),
                              name: "popImage",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.$imagePath + image.filePath,
                                alt: "slider_img0" + (index + 1),
                              },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "op-pop-slide-bottom" },
                    [
                      _c("div", { staticClass: "op-pop-imgbox" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.$imagePath +
                              _vm.contentAttribute.pc_thunb_prd,
                            alt: "slider_img01",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.popupImage(1, this)
                            },
                          },
                        }),
                      ]),
                      _vm._l(_vm.contentFile, function (image, index) {
                        return _c("div", { staticClass: "op-pop-imgbox" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.$imagePath + image.filePath,
                              alt: "slider_img0" + (index + 1),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.popupImage(index + 2, this)
                              },
                            },
                          }),
                        ])
                      }),
                      _vm._m(3),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("ul", [
                    _c("li", { staticClass: "txt-01" }, [
                      _vm._v(_vm._s(_vm.popUpCateName)),
                    ]),
                    _c("li", { staticClass: "txt-02 tt-1" }, [
                      _vm._v(_vm._s(_vm.contentContent.title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "tbl-box op-pop-tbl-box" }, [
                    _c("table", [
                      _c("caption", [_vm._v("Product Specs")]),
                      _vm._m(4),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("other.text.prd_ref"))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contentAttribute.prd_code)),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("other.text.dim"))),
                          ]),
                          _c("td", [
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5",
                                staticStyle: { color: "#666" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.contentAttribute.prd_L) +
                                    "(L) x " +
                                    _vm._s(_vm.contentAttribute.prd_W) +
                                    "(W)"
                                ),
                              ]
                            ),
                            _vm.contentAttribute.prd_H != undefined
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "ctt ctt-t5",
                                    staticStyle: { color: "#666" },
                                  },
                                  [
                                    _vm._v(
                                      " x " +
                                        _vm._s(_vm.contentAttribute.prd_H) +
                                        "(H) "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5",
                                staticStyle: { color: "#666" },
                              },
                              [_vm._v(" mm")]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("other.text.install"))),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.contentAttribute.cate_installText)
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("other.text.more"))),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.contentAttribute.more))]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "op-pop-doc" }, [
                  _c("p", { staticClass: "tt-sub" }, [
                    _vm._v(_vm._s(_vm.$t("PCD.text.documentation"))),
                  ]),
                  _c("div", { staticClass: "cont" }, [
                    _c("ul", [
                      _vm.contentAttribute.pdf != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.$t("other.text.kbb"))),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.pdf)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.contentAttribute.dxf != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.contentAttribute.dxf_orgName)),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.dxf)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.contentAttribute.dxf2 != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.contentAttribute.dxf2_orgName)),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.dxf2)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.contentAttribute.dxf3 != undefined
                        ? _c("li", { staticClass: "doc-drop-li" }, [
                            _c("span", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(_vm._s(_vm.contentAttribute.dxf3_orgName)),
                            ]),
                            _c("button", {
                              staticClass: "download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.download(_vm.contentAttribute.dxf3)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btns claer" }, [
                  _c("div", { staticClass: "fl-l" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-line btn-xs",
                        on: {
                          click: function ($event) {
                            return _vm.linkUrl(_vm.contentAttribute.prd_code)
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("other.text.contactus"))),
                        ]),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-second",
                        on: {
                          click: function ($event) {
                            return _vm.linkUrl("room")
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("other.text.showroom"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: { type: "button", onclick: "LayerPopup.close();" },
                }),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
              staticStyle: { display: "none" },
              attrs: { id: "layer-search-box" },
            },
            [
              _c("div", { staticClass: "popup" }, [
                _c("div", { staticClass: "scr-y" }, [
                  _c("div", { staticClass: "drop-list" }, [
                    _c("p", { staticClass: "tt-2" }, [_vm._v("Filter By")]),
                    _c("button", {
                      staticClass: "filter-clos",
                      on: {
                        click: function ($event) {
                          return _vm.closeSearchPopup()
                        },
                      },
                    }),
                    _c("ul", [
                      _c("li", { staticClass: "on" }, [
                        _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                          ? _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-color",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.button.color")) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                          ? _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Tones"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-color",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("PF.text.Tones")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                          ? _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk type03" },
                                  _vm._l(_vm.lefthue, function (lefthue) {
                                    return _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "inp-chk chk-color" },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              name: "color",
                                              id: "hue_" + lefthue.id.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                ;[
                                                  _vm.fnSearch(
                                                    "hue",
                                                    lefthue.text,
                                                    lefthue.id.value,
                                                    "Color"
                                                  ),
                                                ]
                                              },
                                            },
                                          }),
                                          lefthue.text === "Other"
                                            ? _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-image : url(images/products/" +
                                                    lefthue.id.value +
                                                    ".png)",
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ])
                                            : _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-color:#" +
                                                    lefthue.id.value,
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ]),
                                          _c("span", [
                                            _vm._v(_vm._s(lefthue.text)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                          ? _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk type03" },
                                  _vm._l(_vm.lefthue, function (lefthue) {
                                    return _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "inp-chk chk-color" },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              name: "color",
                                              id: "hue_" + lefthue.id.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                ;[
                                                  _vm.fnSearch(
                                                    "hue",
                                                    lefthue.text,
                                                    lefthue.id.value,
                                                    "Tone"
                                                  ),
                                                ]
                                              },
                                            },
                                          }),
                                          lefthue.text === "Other"
                                            ? _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-image : url(images/products/" +
                                                    lefthue.id.value +
                                                    ".png)",
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ])
                                            : _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-color:#" +
                                                    lefthue.id.value,
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ]),
                                          _c("span", [
                                            _vm._v(_vm._s(lefthue.text)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-collection",
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("PF.button.collection")) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.leftcollection, function (coll) {
                                return _c("li", [
                                  coll.category.hidden === false
                                    ? _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "Collection",
                                          attrs: {
                                            type: "checkbox",
                                            id: coll.category.id,
                                          },
                                          on: {
                                            change: function ($event) {
                                              ;[
                                                _vm.fnCateSearch(
                                                  coll.category.name,
                                                  coll.category.id
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(coll.category.name)),
                                          coll.category.name === "Eden Plus"
                                            ? _c(
                                                "strong",
                                                { staticClass: "ico-g" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("PF.text.Recycled")
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-patterns",
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PF.button.patterns")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk type02" },
                              _vm._l(_vm.leftpattern, function (leftpattern) {
                                return _c("li", [
                                  _c(
                                    "label",
                                    { staticClass: "inp-chk chk-thumb" },
                                    [
                                      _c("input", {
                                        staticClass: "pattern",
                                        attrs: {
                                          type: "checkbox",
                                          id: "pattern_" + leftpattern.id.value,
                                        },
                                        on: {
                                          change: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "pattern",
                                                leftpattern.text,
                                                leftpattern.id.value,
                                                "Pattern"
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _vm._v("> "),
                                      _c("span", [
                                        _c("i", {
                                          style:
                                            "background-image : url(images/products/" +
                                            leftpattern.id.value +
                                            ".png)",
                                        }),
                                        _vm._v(_vm._s(leftpattern.text)),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm.nationCode == "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.country"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.button.country")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(
                                    _vm.leftCountry,
                                    function (leftCountry) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "country",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "country_" +
                                                  leftCountry.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "country",
                                                      leftCountry.text,
                                                      leftCountry.id.value,
                                                      "Country"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(leftCountry.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", [_vm._v(_vm._s(_vm.$t("PF.text.new")))]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-new",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PF.button.new")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c("ul", [
                              _c("li", [
                                _c("label", { staticClass: "inp-chk" }, [
                                  _c("input", {
                                    staticClass: "new",
                                    attrs: {
                                      type: "checkbox",
                                      id: "isNew_true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        ;[
                                          _vm.fnSearch(
                                            "isNew",
                                            "NEW COLORS",
                                            "true",
                                            "New"
                                          ),
                                        ]
                                      },
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PF.text.new_colors"))
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Use"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("PF.text.Use")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(_vm.leftUse, function (leftUse) {
                                    return _c("li", [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "USE",
                                          attrs: {
                                            type: "checkbox",
                                            id: "USE_" + leftUse.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "USE",
                                                  leftUse.text,
                                                  leftUse.id.value,
                                                  "USE"
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(leftUse.text)),
                                        ]),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Recycled"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.text.Recycled")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(
                                    _vm.leftRecycled,
                                    function (leftRecycled) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "recycled",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "recycled_" +
                                                  leftRecycled.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "recycled",
                                                      leftRecycled.text,
                                                      leftRecycled.id.value,
                                                      "Recycled"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(leftRecycled.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Thickness"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.text.Thickness")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(
                                    _vm.leftThickness,
                                    function (leftThickness) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "thickness",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "thickness_" +
                                                  leftThickness.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "thickness",
                                                      leftThickness.text,
                                                      leftThickness.id.value,
                                                      "Thickness"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(leftThickness.text)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "more-box ta-c mb-only" }, [
      _c(
        "button",
        { staticClass: "btn btn-more-point", attrs: { type: "button" } },
        [_c("span", [_vm._v("See More")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fd-view-mb search-area" }, [
      _c("div", { staticClass: "search" }, [
        _c("input", {
          attrs: {
            id: "textSearchM",
            type: "text",
            placeholder: "Enter Item Number or Name",
          },
        }),
        _c("i", {
          staticClass: "icon-search gray",
          attrs: { id: "searchBtnM" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_vm._v("Clear All "), _c("em", [_vm._v("X")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "op-pop-slide-arrow" }, [
      _c("button", { staticClass: "slide-arrow-left" }, [
        _c("span", [
          _c("img", {
            attrs: {
              src: "https://img.lxhausys.com/public/images/arrw_left_05.png",
              alt: "arrow-left",
            },
          }),
        ]),
      ]),
      _c("button", { staticClass: "slide-arrow-right" }, [
        _c("span", [
          _c("img", {
            attrs: {
              src: "https://img.lxhausys.com/public/images/arrw_right_17.png",
              alt: "arrow-right",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col"), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }