var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content au-view glSite" }, [
        _c("div", { staticClass: "container au-box glob-box" }, [
          _c("div", { staticClass: "gloabalBuni visual-detail" }, [
            _c("div", { staticClass: "visual-image full_width center" }, [
              _c("div", { staticClass: "inner_width" }, [
                _c("div", { staticClass: "location type02" }, [
                  _c("ul", [
                    _c("li", { staticClass: "ico-home" }, [
                      _vm._v(_vm._s(_vm.$t("globalBusiness.text.home"))),
                    ]),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$t("globalBusiness.text.about_us"))),
                    ]),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$t("globalBusiness.text.overview"))),
                    ]),
                    _c("li", [
                      _vm._v(
                        _vm._s(_vm.$t("globalBusiness.text.global_business"))
                      ),
                    ]),
                  ]),
                ]),
                _c("h1", { staticClass: "txt-box" }, [
                  _vm.nationCode == "_DE"
                    ? _c("p", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            src: "images/aboutus/about_global-business_DE.png",
                            alt: "globalbusiness",
                          },
                        }),
                      ])
                    : _vm.nationCode == "_ES"
                    ? _c("p", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            src: "images/aboutus/about_global-business_ES.png",
                            alt: "globalbusiness",
                          },
                        }),
                      ])
                    : _vm.nationCode == "_IT"
                    ? _c("p", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            src: "images/aboutus/about_global-business_IT.png",
                            alt: "globalbusiness",
                          },
                        }),
                      ])
                    : _vm.nationCode == "_FR"
                    ? _c("p", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            src: "images/aboutus/about_global-business_FR.png",
                            alt: "globalbusiness",
                          },
                        }),
                      ])
                    : _c("p", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            src: "images/aboutus/about_global-business.png",
                            alt: "globalbusiness",
                          },
                        }),
                      ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "w-con-02 ba-text inner" }, [
            _c("div", { staticClass: "t-list-box" }, [
              _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                _vm._v(_vm._s(_vm.$t("globalBusiness.text.tab_01_title"))),
              ]),
              _c("p", { staticClass: "ctt ctt-t4 mb-64" }, [
                _vm._v(_vm._s(_vm.$t("globalBusiness.text.tab_01_brief"))),
              ]),
              _vm._m(0),
            ]),
            _c("ul", { staticClass: "glmap-List frow frow-between" }, [
              _c("li", [
                _c("dl", { staticClass: "country" }, [
                  _c("dt", [
                    _c("span", { staticClass: "gb_part02_circle" }, [
                      _vm._v("A"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_A_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("dd", { staticClass: "ctt ctt-t5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_A_brief")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("dl", { staticClass: "country" }, [
                  _c("dt", [
                    _c("span", { staticClass: "gb_part02_circle" }, [
                      _vm._v("B"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_B_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("dd", { staticClass: "ctt ctt-t5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_B_brief")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("dl", { staticClass: "country" }, [
                  _c("dt", [
                    _c("span", { staticClass: "gb_part02_circle" }, [
                      _vm._v("C"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_C_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("dd", { staticClass: "ctt ctt-t5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_C_brief")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("dl", { staticClass: "country" }, [
                  _c("dt", [
                    _c("span", { staticClass: "gb_part02_circle" }, [
                      _vm._v("D"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_D_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("dd", { staticClass: "ctt ctt-t5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_D_brief")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("li", [
                _c("dl", { staticClass: "country" }, [
                  _c("dt", [
                    _c("span", { staticClass: "gb_part02_circle" }, [
                      _vm._v("E"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("globalBusiness.text.tab_01_info_01_E_title")
                        ) +
                        " "
                    ),
                  ]),
                  _c("dd", { staticClass: "ctt ctt-t5" }, [
                    _vm._v(
                      " In emerging countries in the Middle East and Southeast Asia, acrylic solid surface, flooring, and industrial film businesses are growing in the market. LX Hausys provides products that meet the needs of various markets through differentiated marketing activities for each country. "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "glBuiz" }, [
              _c("h3", { staticClass: "tt-30" }, [
                _vm._v(_vm._s(_vm.$t("globalBusiness.text.tab_02_title"))),
              ]),
              _c("div", { staticClass: "tableArea tbl-box type04 field-01" }, [
                _c("table", [
                  _vm._m(1),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_01_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_01_brief")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tbody", { attrs: { id: "" } }, [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_02_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_02_brief")
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_03_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_03_brief")
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_04_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_04_brief")
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_05_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_05_brief")
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_06_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_06_brief")
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_07_title")
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "ctt ctt-t4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_02_info_07_brief")
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "glBuiz_imgbox" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/img_global01.png",
                  alt: "global_business",
                },
              }),
              _c("p", { staticClass: "ctt ctt-t3 ta-r" }, [
                _vm._v(_vm._s(_vm.$t("globalBusiness.text.tab_03_title"))),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "overseas" }, [
            _c("h2", { staticClass: "tt-sub-gray" }, [
              _vm._v(_vm._s(_vm.$t("globalBusiness.text.tab_04_title"))),
            ]),
            _c("div", { staticClass: "slide-wrapper slide-pd" }, [
              _c("button", { staticClass: "arrow-prev" }, [_vm._v("Prev")]),
              _c("button", { staticClass: "arrow-next" }, [_vm._v("Next")]),
              _c("div", { staticClass: "slick-container" }, [
                _c("div", { staticClass: "slider" }, [
                  _c("div", { staticClass: "listU" }, [
                    _c("section", { staticClass: "overseas-map" }, [
                      _c("p", { staticClass: "ctt ctt-t2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_04_info_01_title")
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "sub-coment" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_04_info_01_brief")
                          )
                        ),
                      ]),
                      _vm._m(2),
                    ]),
                    _c("section", { staticClass: "overseas-lis" }, [
                      _c("dl", [
                        _c("div", { staticClass: "ctt ctt-t3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("globalBusiness.text.tab_05_title"))
                          ),
                        ]),
                        _c("div", { staticClass: "overseas-line" }),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_05_info_01_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_05_info_01_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_05_info_01_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_05_info_01_brief_03"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_05_info_03_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_05_info_03_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_05_info_03_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_05_info_03_brief_03"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "slider" }, [
                  _c("div", { staticClass: "listU" }, [
                    _c("section", { staticClass: "overseas-map" }, [
                      _c("p", { staticClass: "ctt ctt-t2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_04_info_02_title")
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "sub-coment" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_04_info_02_brief")
                          )
                        ),
                      ]),
                      _vm._m(3),
                    ]),
                    _c("section", { staticClass: "overseas-lis" }, [
                      _c("dl", [
                        _c("div", { staticClass: "ctt ctt-t3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("globalBusiness.text.tab_06_title"))
                          ),
                        ]),
                        _c("div", { staticClass: "overseas-line" }),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_01_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_01_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_01_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_01_brief_03"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_02_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_02_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_02_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_02_brief_03"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "globalBusiness.text.tab_06_info_02_title_01"
                              )
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_02_brief_01_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_02_brief_02_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_02_brief_03_01"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_03_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_03_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_03_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_03_brief_03"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_03_brief_04"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_04_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_04_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_04_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_04_brief_03"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_04_brief_04"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_05_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_05_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_05_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_05_brief_03"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_06_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_06_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_06_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_06_brief_03"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_06_brief_04"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_06_info_07_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_07_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_07_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_07_brief_03"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_06_info_07_brief_04"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_07_info_02_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_02_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_02_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_02_brief_03"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "slider" }, [
                  _c("div", { staticClass: "listU" }, [
                    _c("section", { staticClass: "overseas-map" }, [
                      _c("p", { staticClass: "ctt ctt-t2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_04_info_03_title")
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "sub-coment" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("globalBusiness.text.tab_04_info_03_brief")
                          ) + " "
                        ),
                      ]),
                      _vm._m(4),
                    ]),
                    _c("section", { staticClass: "overseas-lis" }, [
                      _c("dl", [
                        _c("div", { staticClass: "ctt ctt-t3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("globalBusiness.text.tab_07_title"))
                          ),
                        ]),
                        _c("div", { staticClass: "overseas-line" }),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("globalBusiness.text.tab_07_info_01_title")
                            )
                          ),
                        ]),
                        _c("dd", { staticClass: "ctt ctt-t5" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_01_brief_01"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_01_brief_02"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_01_brief_03"
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "globalBusiness.text.tab_07_info_01_brief_04"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v("Australia"),
                        ]),
                        _vm._m(5),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v("Taiwan"),
                        ]),
                        _vm._m(6),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v("Thailand"),
                        ]),
                        _vm._m(7),
                        _c("dt", { staticClass: "ctt ctt-t4" }, [
                          _vm._v("Vietnam"),
                        ]),
                        _vm._m(8),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("article", {}, [
      _c("p", [
        _c("img", { attrs: { src: "images/aboutus/map.png", alt: "map" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "auto%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "img" }, [
      _c("img", {
        attrs: { src: "images/aboutus/us-overseas.png", alt: "us-overseas" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "img" }, [
      _c("img", {
        attrs: { src: "images/aboutus/eu-overseas.png", alt: "eu-overseas" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "images/aboutus/china-overseas.png",
          alt: "china-overseas",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dd", { staticClass: "ctt ctt-t5" }, [
      _vm._v(" BLACK FOX GROUP PTY LTD."),
      _c("br"),
      _vm._v(" 17 James Street, South Windsor NSW, Australia"),
      _c("br"),
      _vm._v(" tel. 61-438-532-226 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dd", { staticClass: "ctt ctt-t5" }, [
      _vm._v(" TAODE ENTERPRISE LTD."),
      _c("br"),
      _vm._v(
        " 1F., No. 25, Ln. 101, Sec. 2, Roosevelt Rd., Da’an Dist., Taipei City"
      ),
      _c("br"),
      _vm._v(" , Taiwan (ZIP code: 106012)"),
      _c("br"),
      _vm._v(" tel: 886-2-2368-6618 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dd", { staticClass: "ctt ctt-t5" }, [
      _vm._v(" CK Coopperation Co., Ltd"),
      _c("br"),
      _vm._v(
        " 77/3 Village No.4, Bueng Kham Phroi Sub-District, Lam Luk Ka District,"
      ),
      _c("br"),
      _vm._v(" Pathum Thani Province 12150, Thailand"),
      _c("br"),
      _vm._v(" tel. 6695-7982565 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dd", { staticClass: "ctt ctt-t5" }, [
      _vm._v(" TARA VIET NAM CONSULTING AND DEVELOPMENT.,LTD"),
      _c("br"),
      _vm._v(" Room 302, Sunlight house building, 12 street 1,"),
      _c("br"),
      _vm._v(" Tan Phu ward, District 7, Ho Chi Minh city, Viet Nam"),
      _c("br"),
      _vm._v(" tel. 84-28-36200047 (Office)"),
      _c("br"),
      _vm._v(" tel. 84-9762-10411 (Mobile) "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }