var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode === "_US" || _vm.nationCode === "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "" } },
        [
          _c("div", { staticClass: "main-text-area" }, [
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "LX Hausys operates 6 overseas sales corporations, 3 overseas manufacturing corporations, and 1 overseas representative office, mainly in the U.S., China, and Europe, as well as the domestic headquarters and manufacturing plants.\n      "
              ),
            ]),
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        We are pursuing a customer-oriented marketing strategy in the key markets including the U.S., China, and Europe. We are focusing on expanding our global network by localizing our products with the manufacturing and sales bases located in the U.S. and China.\n      "
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "map-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/overview/global_network/global_network_map.png",
                  alt: "global map",
                },
              }),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "region-area" },
              _vm._l(_vm.items, function (it, idx) {
                return _c("RegionCard", {
                  key: idx,
                  attrs: {
                    cardType: it.type,
                    iconTxt: it.iconTxt,
                    mapImageSrc: it.mapImageSrc,
                    title: it.title,
                    desc: it.desc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "" } },
        [
          _c("div", { staticClass: "main-text-area" }, [
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        LXハウシスは、国内本社・製造工場のほか、米国、中国、欧州を中心に海外販売法人6社、海外製造法人3社、海外駐在員事務所1社を展開しています。米国、中国、欧州などの主要市場で顧客志向のマーケティング戦略を展開しています。\n      "
              ),
            ]),
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        米国と中国に製造・販売拠点を置き、製品の現地化を進め、グローバルネットワークの拡大に注力しています。      \n      "
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "map-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/overview/global_network/global_network_map.png",
                  alt: "global map",
                },
              }),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "region-area" },
              _vm._l(_vm.itemsJp, function (it, idx) {
                return _c("RegionCard", {
                  key: idx,
                  attrs: {
                    cardType: it.type,
                    iconTxt: it.iconTxt,
                    mapImageSrc: it.mapImageSrc,
                    title: it.title,
                    desc: it.desc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "" } },
        [
          _c("div", { staticClass: "main-text-area" }, [
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        LX Hausys управляет 6 зарубежными торговыми корпорациями, 3 зарубежными производственными корпорациями и 1 зарубежным представительством, в США, Китае и Европе, а также головным офисом и производственными предприятиями внутри страны.\n      "
              ),
            ]),
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        Мы придерживаемся маркетинговой стратегии, ориентированной на клиента, на ключевых рынках, включая США, Китай и Европу. Мы стремимся расширить нашу глобальную сеть за счет локализации нашей продукции на производственных и торговых базах, расположенных в США и Китае.\n      "
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "map-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/overview/global_network/global_network_map.png",
                  alt: "global map",
                },
              }),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "region-area" },
              _vm._l(_vm.itemsRu, function (it, idx) {
                return _c("RegionCard", {
                  key: idx,
                  attrs: {
                    cardType: it.type,
                    iconTxt: it.iconTxt,
                    mapImageSrc: it.mapImageSrc,
                    title: it.title,
                    desc: it.desc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "" } },
        [
          _c("div", { staticClass: "main-text-area" }, [
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        LX Hausys不仅止步于韩国总部和生产工厂，还延伸至海外。通过在北美、亚洲、欧洲等区域设立1家海外分支机构、6家销售法人以及3家生产法人，实现了全球性链接。\n      "
              ),
            ]),
            _c("pre", { staticClass: "ctt-t2" }, [
              _vm._v(
                "        还在美国、中国和欧洲等主要市场奉行以客户为中心的营销战略，已经在美国和中国建立了生产和销售基地, 致力于通过产品本地化扩大全球网络。\n      "
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "map-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/overview/global_network/global_network_map.png",
                  alt: "global map",
                },
              }),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "region-area" },
              _vm._l(_vm.itemsCn, function (it, idx) {
                return _c("RegionCard", {
                  key: idx,
                  attrs: {
                    cardType: it.type,
                    iconTxt: it.iconTxt,
                    mapImageSrc: it.mapImageSrc,
                    title: it.title,
                    desc: it.desc,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }