<template>
  <OverviewSection
      type="minor"
      title="Innovation for Customers, ESG for the Future"
      titleMarginTop="54px"
      v-if="nationCode == '_US' || nationCode == '_GL'"
    >
      <template #header>
        <div class="esg-vision">
          <img src="images/aboutus/sustainability/esg_strategy_system/esg_vision.png" alt="img"/>
        </div>
      </template>

      <OverviewSectionItem
        isUnderSectionItem
      >
        <StrategicDirections>
          <div class="contents-area">
            <div class="contents"
              v-for="(it, idx) in cardData"
              :key="idx"
            >
              <EsgImageCard
                :firstTitle="it.firstTitle"
                :title="it.title"
                :content="it.content"
                :iconSrc="it.iconSrc"
              />

              <EsgTextCard
                v-for="(item, index) in it.textCardData"
                :key="index"
                :text="item.text"
              />
            </div>
          </div>
        </StrategicDirections>
      </OverviewSectionItem>

    </OverviewSection>
<!-- JP -->
  <OverviewSection
    type="minor"
    title="顧客のためのイノベーション、未来のためのESG"
    titleMarginTop="54px"
    v-else-if="nationCode == '_JP'"
  >
    <template #header>
      <div class="esg-vision">
        <img src="images/aboutus/sustainability/esg_strategy_system/esg_vision.png" alt="img"/>
      </div>
    </template>

    <OverviewSectionItem
      isUnderSectionItem
    >
      <StrategicDirections>
        <div class="contents-area">
          <div class="contents"
               v-for="(it, idx) in cardDataJp"
               :key="idx"
          >
            <EsgImageCard
              :firstTitle="it.firstTitle"
              :title="it.title"
              :content="it.content"
              :iconSrc="it.iconSrc"
            />

            <EsgTextCard
              v-for="(item, index) in it.textCardData"
              :key="index"
              :text="item.text"
            />
          </div>
        </div>
      </StrategicDirections>
    </OverviewSectionItem>

  </OverviewSection>
<!-- RU -->
  <OverviewSection
    type="minor"
    title="Инновации для клиентов, ESG для будущего"
    titleMarginTop="54px"
    v-else-if="nationCode == '_RU'"
  >
    <template #header>
      <div class="esg-vision">
        <img src="images/aboutus/sustainability/esg_strategy_system/esg_vision.png" alt="img"/>
      </div>
    </template>

    <OverviewSectionItem
      isUnderSectionItem
    >
      <StrategicDirections>
        <div class="contents-area">
          <div class="contents"
               v-for="(it, idx) in cardDataRu"
               :key="idx"
          >
            <EsgImageCard
              :firstTitle="it.firstTitle"
              :title="it.title"
              :content="it.content"
              :iconSrc="it.iconSrc"
            />

            <EsgTextCard
              v-for="(item, index) in it.textCardData"
              :key="index"
              :text="item.text"
            />
          </div>
        </div>
      </StrategicDirections>
    </OverviewSectionItem>

  </OverviewSection>
<!-- CN -->
  <OverviewSection
    type="minor"
    title="为客户创新，ESG面向未来"
    titleMarginTop="54px"
    v-else-if="nationCode == '_CN'"
  >
    <template #header>
      <div class="esg-vision">
        <img src="images/aboutus/sustainability/esg_strategy_system/esg_vision.png" alt="img"/>
      </div>
    </template>

    <OverviewSectionItem
      isUnderSectionItem
    >
      <StrategicDirections>
        <div class="contents-area">
          <div class="contents"
               v-for="(it, idx) in cardDataCn"
               :key="idx"
          >
            <EsgImageCard
              :firstTitle="it.firstTitle"
              :title="it.title"
              :content="it.content"
              :iconSrc="it.iconSrc"
            />

            <EsgTextCard
              v-for="(item, index) in it.textCardData"
              :key="index"
              :text="item.text"
            />
          </div>
        </div>
      </StrategicDirections>
    </OverviewSectionItem>

  </OverviewSection>
</template>

<script>
import EsgImageCard from './EsgImageCard.vue'
import EsgTextCard from './EsgTextCard.vue'
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import StrategicDirections from './StrategicDirections.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'

export default {
  components: {
    EsgImageCard,
    EsgTextCard,
    OverviewSection,
    StrategicDirections,
    OverviewSectionItem
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardData: [
        {
          firstTitle: 'E',
          title: 'co-friendly',
          content: 'Considering the Environment',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon1.png',
          textCardData: [
            {
              text: 'Enhance the environmental management'
            },
            {
              text: 'Establish an environmental impact\nmanagement system'
            },
            {
              text: 'Establish a climate change response system'
            }
          ]
        },
        {
          firstTitle: 'S',
          title: 'hared value',
          content: 'Moving Forward With Our Stakeholders',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon2.png',
          textCardData: [
            {
              text: 'Establish safe business sites'
            },
            {
              text: 'Increase the happiness of stakeholders',
              padding: '30px 15px'
            },
            {
              text: 'Increase the generation of social value'
            }
          ]
        },
        {
          firstTitle: 'G',
          title: 'ood governance',
          content: 'Considering the Environment',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon3.png',
          textCardData: [
            {
              text: 'Internalize ESG management'
            },
            {
              text: 'Strengthen risk management and responses',
              padding: '30px 15px'
            },
            {
              text: 'Enhance the transparency based on communication'
            }
          ]
        }
      ],
      cardDataJp: [
        {
          firstTitle: '環',
          title: '境に優しい',
          content: '環境への配慮',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon1.png',
          textCardData: [
            {
              text: '環境マネジメントの強化'
            },
            {
              text: '環境影響管理体制の構築'
            },
            {
              text: '気候変動対応体制の構築'
            }
          ]
        },
        {
          firstTitle: '共',
          title: '通の価値',
          content: 'ステークホルダーとともに前進',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon2.png',
          textCardData: [
            {
              text: '安全な事業所の確立'
            },
            {
              text: 'ステークホルダーの幸福度の向上',
              padding: '30px 15px'
            },
            {
              text: '社会的価値の創出の向上'
            }
          ]
        },
        {
          firstTitle: '良',
          title: 'いガバナンス',
          content: '環境への配慮',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon3.png',
          textCardData: [
            {
              text: 'ESG経営の内在化'
            },
            {
              text: 'リスク管理と対応の強化',
              padding: '30px 15px'
            },
            {
              text: 'コミュニケーションによる透明性の向上'
            }
          ]
        }
      ],
      cardDataRu: [
        {
          firstTitle: 'Э',
          title: 'кологически чистый',
          content: 'Учитывая экологию',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon1.png',
          textCardData: [
            {
              text: 'Усиление экологического менеджмента'
            },
            {
              text: 'Создание системы управления воздействия на окружающую среду'
            },
            {
              text: 'Создание системы реагирования на изменение климата'
            }
          ]
        },
        {
          firstTitle: 'О',
          title: 'бщая ценность',
          content: 'Движение вперед вместе с нашими заинтересованными сторонами',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon2.png',
          textCardData: [
            {
              text: 'Создание безопасных мест для ведения бизнеса'
            },
            {
              text: 'Повышение уровня удовлетворения заинтересованных сторон',
              padding: '30px 15px'
            },
            {
              text: 'Усиление социальной ценности'
            }
          ]
        },
        {
          firstTitle: 'Э',
          title: 'ффективное управление',
          content: 'Учитывая экологию',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon3.png',
          textCardData: [
            {
              text: 'Внутреннее управление ESG'
            },
            {
              text: 'Усиление управления рисками и ответных мер',
              padding: '30px 15px'
            },
            {
              text: 'Повышение прозрачности на основе коммуникаций'
            }
          ]
        }
      ],
      cardDataCn: [
        {
          firstTitle: '环',
          title: '保',
          content: '考虑环境',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon1.png',
          textCardData: [
            {
              text: '加强环境管理'
            },
            {
              text: '建立环境影响管理制度'
            },
            {
              text: '建立气候变化应对体系'
            }
          ]
        },
        {
          firstTitle: '共',
          title: '享价值',
          content: '与我们的利益相关者共同前进',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon2.png',
          textCardData: [
            {
              text: '建立安全的商业场所'
            },
            {
              text: '提高利益相关者的幸福感',
              padding: '30px 15px'
            },
            {
              text: '增加社会价值的产生'
            }
          ]
        },
        {
          firstTitle: '良',
          title: '好治理',
          content: '考虑环境',
          iconSrc: 'images/aboutus/sustainability/esg_strategy_system/esg_icon3.png',
          textCardData: [
            {
              text: 'ESG管理内部化'
            },
            {
              text: '加强风险管理和应对',
              padding: '30px 15px'
            },
            {
              text: '基于沟通提高透明度'
            }
          ]
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.esg-vision {
  display: flex;
  justify-content: center;
}

.esg-vision img {
  width: 286px;
  height: 136px;
}

.contents-area {
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.contents-area .contents {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media (min-width: 1440px) {
  .contents-area .contents {
    min-width: 446px;
  }
}

</style>
