<template>
  <SeatCorverLayout
    class="mt-50"
    imageSrc="images/automotive/skin/LUSSINO.png"
  >
    <template #text>
      <div class="title">
        <span>LUSSINO™</span>
      </div>
      <div class="description">
        <span>• [Premium grade] High-perfomance and sustainable silicone based leather</span>
        <br />
        <span>• Composition of silicone based material (Topcoat, Skin, Binder)</span>
      </div>
    </template>
  </SeatCorverLayout>
</template>

<script>
import SeatCorverLayout from './SeatCorverLayout.vue'

export default {
  components: {
    SeatCorverLayout
  }
}
</script>
