var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Achievements at Design Awards",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("AchievementsAtDesignAwardsCard", {
                attrs: {
                  number: _vm.cardData.number,
                  text: _vm.cardData.text,
                  itemDatas: _vm.cardData.itemDatas,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "デザイン賞での実績", color: "#222", size: "56px" } },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("AchievementsAtDesignAwardsCard", {
                attrs: {
                  number: _vm.cardData.number,
                  text: _vm.cardData.textJp,
                  itemDatas: _vm.cardData.itemDatas,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Достижения на премии Design Awards",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("AchievementsAtDesignAwardsCard", {
                attrs: {
                  number: _vm.cardData.number,
                  text: _vm.cardData.textRu,
                  itemDatas: _vm.cardData.itemDatas,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "所获设计奖项", color: "#222", size: "56px" } },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("AchievementsAtDesignAwardsCard", {
                attrs: {
                  number: _vm.cardData.number,
                  text: _vm.cardData.textCn,
                  itemDatas: _vm.cardData.itemDatas,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }