<template>
  <div
    class="visual-image-area"
    :style="backgroundStyle"
  >
    <slot name="content"></slot>

    <div class="visual-image-area-title-box">
      <h1 class="title">
        <span class="txt title-font" :style="{'color':titleColor}">{{ title }}</span>
      </h1>
      <!-- <div class="sub-title-font mt-50">{{ subTitle }}</div> -->
      <div class="description-font">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    imageHeight: {
      type: String,
      required: false
    },
    imageWidth: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    descriptionWidth: {
      type: String,
      required: false
    },
    titleColor: {
      type: String,
      default: '#FFF'
    }
  },

  computed: {
    backgroundStyle () {
      let heightStyle = ''
      let widthStyle = ''

      if (this.imageHeight) {
        heightStyle = `height: ${this.imageHeight};`
      }
      if (this.imageWidth) {
        widthStyle = `width: ${this.imageWidth};`
      }

      return `background-image: url(${this.imageSrc}); ${heightStyle}; ${widthStyle};`
    },
    descriptionStyle () {
      const width = this.descriptionWidth ?? '100%'
      return `width: ${width};`
    }
  }
}
</script>

<style scoped>
.visual-image-area {
  width: 100%;
  display: grid;
  align-content: center;
  height: 400px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .visual-image-area {
    height: 400px;
    background-size: cover;
  }
}

.visual-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
  border: 0px;
}

.visual-image-area-title-box {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  z-index: 10;
  align-items: center;
}

@media (max-width: 768px) {
  .visual-image-area-title-box {
    padding: 0rem 1.25rem;
    top: 25%;
  }
}

.title-font{
  color: #FFF;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif;; */
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
}

@media (max-width: 768px) {
  .title-font {
    font-size: 2.5rem;
  }
}

.sub-title-font {
  color: #FFF;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 768px) {
  .sub-title-font {
    font-size: 1.3rem;
  }
}

.description-font {
  color: #FFF;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 24px;
  font-weight: 500;
  margin-top: 26px;
}
@media (max-width: 768px) {
  .description-font {
    font-size: 18px;
  }
}

</style>
