<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>WEAR LAYER</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
            <span class="checkbox_more no_line">
              <li v-for="wearlayer in wearLayerList" :data-chk="wearlayer.id.value">
                <input type="checkbox" :id="'wearLayer_'+wearlayer.id.value" name="WearLayer" v-model="selected" :value="wearlayer.id.value" />
                <label :for="'wearLayer_'+`${wearlayer.id.value}`">
                <span></span>
                {{ wearlayer.text }}
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'wearLayerList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-wearlayer-filter', this.removeWearlayer)
    EventBus.$on('update-wearlayer-filters', this.updateWearlayers)

    if (this.$store.state.filterState && this.$store.state.filterState.wear_layer) {
      this.selected = this.$store.state.filterState.wear_layer
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-wearlayer-filter', this.removeWearlayer)
    EventBus.$on('update-wearlayer-filters', this.updateWearlayers)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeWearlayer (wearlayerValue) {
      this.selected = this.selected.filter(value => value !== wearlayerValue)
    },
    updateWearlayers (wearlayers) {
      this.selected = wearlayers
    }
  }
}
</script>
