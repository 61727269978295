var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "key-social-contribution-initiatives-table" },
    [
      _c(
        "div",
        { staticClass: "header-area" },
        _vm._l(_vm.headers, function (item, index) {
          return _c("SocialResponsibilityTableHeader", {
            key: index,
            attrs: { text: item.text, background: item.background },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "body-area" },
        _vm._l(_vm.bodies, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "body" },
            [
              _c("SocialResponsibilityTableBody", {
                attrs: { category: item.category, bodyCells: item.bodyCells },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }