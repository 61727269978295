var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "micro-page", attrs: { id: "wrap" } },
    [
      _c(
        "header",
        { staticClass: "scr-hder on micro" },
        [
          _c("div", { staticClass: "clear homecenter-header" }, [
            _vm._m(0),
            _c("div", { staticClass: "fl-c" }),
            _c("div", { staticClass: "fl-r" }, [
              _c("div", { staticClass: "hd-btns01" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass: "search-line",
                      on: {
                        click: function ($event) {
                          return _vm.openSearch()
                        },
                      },
                    },
                    [
                      _c("input", {
                        staticClass: "inp-text",
                        attrs: {
                          type: "text",
                          placeholder: "Enter Item Name or Color",
                        },
                      }),
                      _c("a", {
                        staticClass: "ico-01",
                        attrs: {
                          href: "javascript:void(0);",
                          title: "Open Search Popup",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "clear homecenter-tab" }, [
            _vm._m(1),
            _c("div", { staticClass: "fl-c" }),
            _c("div", { staticClass: "fl-r" }, [
              _c("div", { staticClass: "virtual-showroom-btn-area" }, [
                _c(
                  "button",
                  {
                    staticClass: "vs-btn",
                    attrs: { type: "button", tabindex: "0" },
                    on: {
                      click: function ($event) {
                        return _vm.goToSchedule()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "upper" }, [
                      _vm._v("SCHEDULE CONSULTATION"),
                    ]),
                    _c("img", {
                      staticClass: "arrow-right",
                      attrs: {
                        src: "https://img.lxhausys.com/public/images/arrw_right_09.png",
                        alt: "arrow icon",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("HDPSearchPopComponent"),
          _c("CartPopComponent"),
        ],
        1
      ),
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Home Depot Viatera")]),
      _c("div", { staticClass: "content prod-dt-cate no-nav" }, [
        _c("div", { staticClass: "pro-dt-warp" }, [
          _c(
            "div",
            {
              staticClass:
                "container prod-dt-view himacs-pd micro-himacs-pd micro_pd_container",
            },
            [
              _c("div", { staticClass: "w-con micro_product_visual" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "prod-img" }, [
                    _c(
                      "div",
                      { staticClass: "slider-prod viartW" },
                      _vm._l(_vm.imgPage, function (fileName, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imgShow === true,
                                expression: "imgShow === true",
                              },
                            ],
                            key: index,
                            staticClass: "slider pdf-inslide03",
                          },
                          [
                            _c("div", { staticClass: "img_wrap" }, [
                              fileName.image != null &&
                              fileName.image != undefined &&
                              fileName.image != "" &&
                              !fileName.image.startsWith(
                                "https://www.youtube.com"
                              )
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.$imagePath + fileName.image,
                                      alt: "homecenter viatera images",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            fileName.image != null &&
                            fileName.image != undefined &&
                            fileName.image != "" &&
                            fileName.image.startsWith("https://www.youtube.com")
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "yt-video",
                                    style:
                                      "max-width:" +
                                      _vm.youtubeWidth +
                                      ";" +
                                      "margin: 0 auto",
                                  },
                                  [
                                    _c("iframe", {
                                      staticClass: "yt_player_iframe",
                                      staticStyle: {
                                        width: "auto",
                                        "min-width": "100%",
                                        "min-height": "100%",
                                      },
                                      attrs: {
                                        autoplay: "autoplay",
                                        muted: "muted",
                                        controls: "",
                                        allowscriptaccess: "always",
                                        src: fileName.image + "?enablejsapi=1",
                                        alt: "youtube",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "slider-prod-nav navWide tac",
                        class: {
                          euNavWide:
                            _vm.nationCode != "_GL" && _vm.nationCode != "_US",
                        },
                      },
                      _vm._l(_vm.imgPage, function (fileName, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imgShow === true,
                                expression: "imgShow === true",
                              },
                            ],
                            key: index,
                            staticClass: "slider",
                          },
                          [
                            fileName.image != null &&
                            fileName.image != undefined &&
                            fileName.image != "" &&
                            fileName.thumb != null &&
                            fileName.thumb != ""
                              ? _c("img", {
                                  attrs: {
                                    "data-chk": index,
                                    src: _vm.$imagePath + fileName.thumb,
                                    alt: "images thumbnail",
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "prod-info" }, [
                    _c("div", { staticClass: "prod_info_head" }, [
                      _c("div", { staticClass: "info-01" }, [
                        _vm.isNew == "true"
                          ? _c("span", { staticClass: "tag h30 new" }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.new"))),
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "tag h30 viatera" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.viatera"))),
                        ]),
                        _vm.isExclusive == "true"
                          ? _c("span", { staticClass: "tag h30 exclusive" }, [
                              _vm._v("hd exclusive"),
                            ])
                          : _vm._e(),
                        _vm.discontinued == "true"
                          ? _c("span", { staticClass: "tag h30 disColor" }, [
                              _vm._v(
                                _vm._s(_vm.$t("PD.text.discontinued_Color"))
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "btns" }, [
                        _c("ul", [
                          _c("li", { attrs: { id: "imgDownload" } }, [
                            _c(
                              "a",
                              {
                                staticClass: "icon-download-bk",
                                on: {
                                  click: function ($event) {
                                    return _vm.imgDownload(_vm.Tslider)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PD.text.image_download_view"))
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "li",
                            [
                              _c("ShareComponent", {
                                attrs: {
                                  childValue: _vm.contentList,
                                  type: "product",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "prod_info_body" }, [
                      _c("h1", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.contentName)),
                      ]),
                      _c("div", { staticClass: "info-01" }, [
                        _c("span", { staticClass: "prod_collection" }, [
                          _vm._v(
                            _vm._s(_vm.collection) +
                              " " +
                              _vm._s(_vm.$t("PCD.text.collection"))
                          ),
                        ]),
                      ]),
                      _c("p", {
                        staticClass: "info-03 ctt ctt-t4",
                        domProps: { innerHTML: _vm._s(_vm.brief) },
                      }),
                    ]),
                    _c("div", { staticClass: "info-01 v-al-md" }, [
                      _vm.nationCode != "_US" &&
                      _vm.nationCode != "_GL" &&
                      _vm.Aurora == true
                        ? _c("span", { staticClass: "aurora-icon" }, [
                            _c("img", {
                              attrs: {
                                src: `images/products/PD_Aurora_icon.png`,
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "txt" }, [
                        _vm._v(_vm._s(_vm.colorCode)),
                      ]),
                    ]),
                    _c("div", {}, [
                      _c("div", [
                        _c("div", { staticClass: "info-02 us-info-02" }, [
                          _c("div", { staticClass: "over-box" }, [
                            _c(
                              "button",
                              {
                                staticClass: "ico-fi over",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showGuide(_vm.showFinish)
                                  },
                                },
                              },
                              [
                                _vm._l(_vm.finish, function (item, index) {
                                  return _c(
                                    "span",
                                    { staticClass: "ctt ctt-t4" },
                                    [
                                      _vm._v(_vm._s(item) + " "),
                                      _vm.finish.length > 1 &&
                                      _vm.finish.length != index + 1
                                        ? _c("span", [_vm._v(" / ")])
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                _c("span", { staticClass: "ctt ctt-t4" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("PD.text.finish"))
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showFinish,
                                    expression: "showFinish",
                                  },
                                ],
                                staticClass: "view guide-box",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.finishLayer()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "ctt ctt-t4" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.text.finishGuide"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                            ? _c("div", { staticClass: "ico-color" }, [
                                _c("div", {
                                  staticClass: "color",
                                  style: {
                                    borderColor: "#999",
                                    backgroundColor: _vm.hue,
                                  },
                                }),
                                _c("div", { staticClass: "txtinfo colorTxt" }, [
                                  _vm.nationCode == "_US" ||
                                  _vm.nationCode == "_GL"
                                    ? _c(
                                        "span",
                                        { staticClass: "ctt ctt-t4" },
                                        [_vm._v(_vm._s(_vm.hueText))]
                                      )
                                    : _vm._e(),
                                  _c("span", { staticClass: "ctt ctt-t4" }, [
                                    _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.nationCode != "_US" &&
                          _vm.nationCode != "_GL" &&
                          _vm.lucent == "true"
                            ? _c("div", { staticClass: "ico-pro-lucent" }, [
                                _c("span", { staticClass: "ctt ctt-t4" }, [
                                  _vm._v(_vm._s(_vm.$t("PD.text.Lucent"))),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.nationCode != "_US" &&
                          _vm.nationCode != "_GL" &&
                          _vm.veined == "true"
                            ? _c("div", { staticClass: "ico-pro-veined" }, [
                                _c("span", { staticClass: "ctt ctt-t4" }, [
                                  _vm._v(_vm._s(_vm.$t("PD.text.Veined"))),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "w-con" }, [
                _c("div", [
                  _c("div", { staticClass: "tbl-box-prodetail" }, [
                    _c("table", [
                      _c("caption", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("PD.text.product_Specs")) + " "
                        ),
                      ]),
                      _vm._m(2),
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col", colspan: "2" } }, [
                            _c("h2", [
                              _vm._v(_vm._s(_vm.$t("PD.text.product_Specs"))),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("PD.text.dimension"))),
                          ]),
                          _c("td", { staticClass: "ctt ctt-t5" }, [
                            _vm._v(_vm._s(_vm.dimension)),
                          ]),
                        ]),
                        _vm.source == "true"
                          ? _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(_vm._s(_vm.$t("PD.text.remark"))),
                              ]),
                              _c("td", { staticClass: "ctt ctt-t5" }, [
                                _vm._v(_vm._s(_vm.remark)),
                              ]),
                            ])
                          : _vm._e(),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("PD.text.pattern"))),
                          ]),
                          _c("td", { staticClass: "ctt ctt-t5" }, [
                            _vm._v(_vm._s(_vm.pattern)),
                          ]),
                        ]),
                        _vm.nationCode === "_GL"
                          ? _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("PD.text.available_Countries"))
                                ),
                              ]),
                              _c("td", { staticClass: "ctt ctt-t5" }, [
                                _vm._v(" " + _vm._s(_vm.countryCode) + " "),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._m(3),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "w-con inner" }, [
                _c("div", { staticClass: "proDtail" }, [
                  _c("div", { staticClass: "short-link short-link-micro" }, [
                    _c(
                      "a",
                      {
                        staticClass: "p-link",
                        attrs: { target: "_blank", href: _vm.homedep_link },
                      },
                      [
                        _c("span", { staticClass: "ico-01" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.order_a_sample"))),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "p-link",
                        attrs: { target: "_blank", href: _vm.buildnbuy_link },
                      },
                      [
                        _c("span", { staticClass: "ico-build-buy" }, [
                          _vm._v("BUILD & BUY"),
                        ]),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "p-link",
                        attrs: { target: "_blank", href: _vm.schedule_link },
                      },
                      [
                        _c("span", { staticClass: "ico-sche-con" }, [
                          _vm._v("SCHEDULE CONSULTATION"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "w-con certification" }, [
                _vm.pcdContentAttribute.cert_use_yn === "true"
                  ? _c(
                      "div",
                      { staticClass: "certification_section full_width" },
                      [
                        _c("div", { staticClass: "inner_width" }, [
                          _c("div", { staticClass: "content_tit_area" }, [
                            _c("h2", { staticClass: "con_title tt-2" }, [
                              _vm._v(
                                _vm._s(_vm.pcdContentAttribute.cert_title)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "certi_group" }, [
                            _c(
                              "ul",
                              _vm._l(
                                _vm.pcdCertificationData,
                                function (certItem, index) {
                                  return _c("li", [
                                    _c("div", { staticClass: "item" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.$imagePath + certItem.image,
                                          alt: certItem.description,
                                        },
                                      }),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                            _vm.pcdCertificationData.length &&
                            _vm.checkCertificationDescription("description")
                              ? _c("div", { staticClass: "btn_area" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn_learn_more",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModalCerti()
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "txt" }, [
                                        _vm._v("Learn more"),
                                      ]),
                                      _c("span", {
                                        staticClass: "icon icon_arrow_right",
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "layer-popup layer-guide" }, [
                  _c("div", { staticClass: "popup pop-close" }, [
                    _c("div", { staticClass: "pop-guide scr-y" }, [
                      _c("p", { staticClass: "tit-02" }, [
                        _vm._v(_vm._s(_vm.$t("PCD.popup.finishInfo"))),
                      ]),
                      _c("div", { staticClass: "txt-01" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("PCD.popup.finishTitle"))),
                        ]),
                        _c("p", { staticClass: "t-type-02" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("PCD.popup.finishBrief")) + " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-02" }, [
                        _c("div", [
                          _c("p", { staticClass: "tt-3" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.popup.polished"))),
                          ]),
                          _c("p", { staticClass: "ctt ctt-t4" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.popup.polished_brief"))),
                          ]),
                          _vm._m(4),
                        ]),
                        _c("div", [
                          _c("p", { staticClass: "tt-3" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.popup.brushed"))),
                          ]),
                          _c("p", { staticClass: "ctt ctt-t4" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("PCD.popup.brushed_brief")) +
                                " "
                            ),
                          ]),
                          _vm._m(5),
                        ]),
                      ]),
                      _vm.nationCode == "_GL"
                        ? _c("div", { staticClass: "col-02" }, [
                            _c("div", [
                              _c("p", { staticClass: "tt-3" }, [
                                _vm._v(_vm._s(_vm.$t("PCD.popup.leather"))),
                              ]),
                              _c("p", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PCD.popup.leather_brief")) +
                                    " "
                                ),
                              ]),
                              _vm._m(6),
                            ]),
                            _c("div", [
                              _c("p", { staticClass: "tt-3" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("PCD.popup.velvet_Touch"))
                                ),
                              ]),
                              _c("p", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("PCD.popup.velvet_Touch_brief")
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._m(7),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "txt-02" }, [
                        _c("strong", { staticClass: "logo gltype" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.popup.finish_viatera"))),
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("PCD.popup.finish_viatera_info"))
                          ),
                        ]),
                        _c("p", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("PCD.popup.finish_viatera_brief")) +
                              " "
                          ),
                        ]),
                        _vm._m(8),
                        _c("p", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("PCD.popup.finish_viatera_brief_01")
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("PCD.popup.finish_viatera_brief_02")
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", { staticClass: "ctt ctt-t4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("PCD.popup.finish_viatera_brief_03")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: {
                          type: "button",
                          onclick: "LayerPopup.close();",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("PCD.button.close")) + " ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "layer-popup layer-enlarge" }, [
                  _c(
                    "div",
                    { staticClass: "popup" },
                    [
                      _c("div", { staticClass: "title-box" }, [
                        _c("p", [_vm._v(_vm._s(_vm.contentName))]),
                        _c(
                          "div",
                          { staticClass: "btns" },
                          [
                            _vm._l(_vm.finish, function (item) {
                              return _c(
                                "a",
                                {
                                  staticClass: "ico-fi",
                                  attrs: { href: "javascript:void(0);" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item) +
                                      " " +
                                      _vm._s(_vm.$t("PD.text.finish"))
                                  ),
                                ]
                              )
                            }),
                            _c(
                              "a",
                              {
                                staticClass: "ico-st",
                                attrs: { href: "javascript:void(0);" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PD.text.standard")) +
                                    " - " +
                                    _vm._s(_vm.dimension)
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                      _vm.showDragon
                        ? _c("SeaDragon", {
                            attrs: {
                              imagePath: _vm.$imagePath + _vm.imgPage[0].detail,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn-close",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.enlargeLayer()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("PCD.button.close")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.pcdContentAttribute.cert_use_yn === "true"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "layer-view layer-pop popup-w1200 modal-certification",
                      },
                      [
                        _c("div", { staticClass: "popup pop-close" }, [
                          _c("div", { staticClass: "scr-y" }, [
                            _c("h3", { staticClass: "popup-title p-ui" }, [
                              _vm._v("Certification Description"),
                            ]),
                            _vm._m(9),
                            _c("div", { staticClass: "popup-body" }, [
                              _c("div", { staticClass: "certi_pop_con" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.pcdCertificationData,
                                    function (certItem, index) {
                                      return _c("li", [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "img_wrap" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.$imagePath +
                                                    certItem.image,
                                                  alt: certItem.description,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "txt_wrap" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "tit_box" },
                                                [_vm._v(_vm._s(certItem.title))]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "txt_box" },
                                                [
                                                  _vm._v(
                                                    _vm._s(certItem.description)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeModalCerti()
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]),
      _c("FooterComponent", { attrs: { isMicrosite: true } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fl-l" }, [
      _c("div", { staticClass: "logo" }, [
        _c(
          "a",
          {
            staticClass: "go_home_for_pc",
            attrs: { href: "homecenter/viatera" },
          },
          [_vm._v("LX Hausys")]
        ),
        _c(
          "a",
          {
            staticClass: "go_home_for_mobile",
            staticStyle: { display: "none" },
            attrs: { href: "homecenter/viatera" },
          },
          [_vm._v("LX Hausys")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fl-l" }, [
      _c("div", { staticClass: "header-tab" }, [
        _c("div", { staticClass: "tab-himacs" }, [
          _c("a", { attrs: { href: "homecenter/himacs" } }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/images/HomeDepot/himacs_off.png",
                alt: "himacs",
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "tab-viatera" }, [
          _c("a", { attrs: { href: "homecenter/viatera" } }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/images/HomeDepot/viatera.png",
                alt: "viatera",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "70%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ta-c prd-btn-more", attrs: { colspan: "2" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: { src: "images/products/@img_finish_01.png", alt: "POLISHED" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: { src: "images/products/@img_finish_02.png", alt: "BRUSHED" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: { src: "images/products/@img_finish_04.png", alt: "Leather" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: {
          src: "images/products/@img_finish_05.png",
          alt: "Velvet Touch",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: {
          src: "images/products/@img_finish_03.png",
          alt: "Brushed Finish Care & Maintenance",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "popup-title m-ui" }, [
      _vm._v("Certification "),
      _c("br"),
      _vm._v(" Description"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }