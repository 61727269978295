var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box ftrd-prod-box height100" }, [
    _c("h2", { staticClass: "tit-main" }, [
      _vm._v(_vm._s(_vm.$t("main.text.featured"))),
    ]),
    _c("div", { staticClass: "tab-ui tab-ui-nav" }, [
      _c(
        "ul",
        { staticClass: "justify-center" },
        [
          _c(
            "li",
            {
              staticClass: "btn-tab on",
              attrs: { "data-tab": "fpd-all" },
              on: {
                click: function ($event) {
                  return _vm.ListChange("ALL")
                },
              },
            },
            [
              _c("button", { attrs: { type: "button" } }, [
                _vm._v(_vm._s(_vm.$t("main.text.all"))),
              ]),
            ]
          ),
          _vm._l(_vm.categoryList, function (cate) {
            return _c(
              "li",
              {
                staticClass: "btn-tab",
                attrs: { "data-tab": "fpd-" + cate.name },
                on: {
                  click: function ($event) {
                    return _vm.ListChange(cate.name)
                  },
                },
              },
              [
                _c("button", { attrs: { type: "button" } }, [
                  _vm._v(_vm._s(cate.name)),
                ]),
              ]
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "tab-con fpd-all slider-margin-0 on" }, [
        _c("div", { staticClass: "overscr-list-wrap" }, [
          _c("div", { staticClass: "overscr-list" }, [
            _c(
              "ul",
              {
                staticClass: "drag-products",
                class: {
                  euSlide:
                    _vm.countryCode !== "_GL" && _vm.countryCode !== "_US",
                },
              },
              _vm._l(_vm.productsList, function (product) {
                return _c(
                  "li",
                  {
                    staticClass: "aos-init aos-animate",
                    attrs: {
                      "data-aos": "fade-up",
                      "data-aos-duration": "600",
                      "data-aos-delay": "750",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.$imagePath + product.attribute.mainImage,
                        alt: "product info",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "txt-box on",
                        attrs: {
                          "data-ec-product": _vm.makeDataLayerForm(product),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goFeat(product, _vm.seo)
                          },
                        },
                      },
                      [
                        product.parents[0].name === "HFLOR"
                          ? _c("span", { staticClass: "info-st" }, [
                              _vm._v(_vm._s(_vm.$t("main.text.hflor_text"))),
                            ])
                          : product.parents[0].name.toUpperCase() === "VIATERA"
                          ? _c("span", { staticClass: "info-st" }, [
                              _vm._v(_vm._s(_vm.$t("main.text.viatera_text"))),
                            ])
                          : product.parents[0].name === "HIMACS"
                          ? _c("span", { staticClass: "info-st" }, [
                              _vm._v(_vm._s(_vm.$t("main.text.himacs_text"))),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "center-box" }, [
                          _c("span", { staticClass: "info-ti" }, [
                            _vm._v(_vm._s(product.orgContent.title)),
                          ]),
                          _vm.countryCode === "_GL" || _vm.countryCode === "_US"
                            ? _c("span", { staticClass: "info-ki" }, [
                                _vm._v(
                                  _vm._s(
                                    product.attribute.org_contentContent
                                      .parents[0].name
                                  )
                                ),
                              ])
                            : _c("span", { staticClass: "info-ki" }, [
                                _vm._v(
                                  _vm._s(
                                    product.attribute.orgContentParents[0].name
                                  )
                                ),
                              ]),
                        ]),
                        product.parents[0].name === "HFLOR" && _vm.seo
                          ? _c("a", {
                              staticClass: "btn-golmore",
                              attrs: {
                                href:
                                  "products/hflor/" +
                                  _vm.makeSeoMainClt(
                                    product.attribute.orgContentParents[1].code
                                  ) +
                                  "/" +
                                  _vm.$utils.getSeoName(product, "seg") +
                                  "/" +
                                  product.orgContent.id,
                              },
                            })
                          : product.parents[0].name === "HFLOR" && !_vm.seo
                          ? _c("a", {
                              staticClass: "btn-golmore",
                              attrs: {
                                href:
                                  "products/hflor/" +
                                  product.attribute.orgContentParents[1].code +
                                  "/" +
                                  product.orgContent.title +
                                  "/" +
                                  product.orgContent.id,
                              },
                            })
                          : product.parents[0].name.toUpperCase() === "VIATERA"
                          ? _c("a", {
                              staticClass: "btn-golmore",
                              attrs: {
                                href:
                                  "products/viatera-quartz-surface/" +
                                  _vm.$utils.getSeoName(product, "seg") +
                                  "/" +
                                  product.orgContent.id,
                              },
                            })
                          : product.parents[0].name === "HIMACS" && _vm.seo
                          ? _c("a", {
                              staticClass: "btn-golmore",
                              attrs: {
                                href:
                                  "products/himacs-solid-surface/" +
                                  _vm.$utils.getSeoName(product, "seg") +
                                  "/" +
                                  product.orgContent.id,
                              },
                            })
                          : product.parents[0].name === "HIMACS" && !_vm.seo
                          ? _c("a", {
                              staticClass: "btn-golmore",
                              attrs: {
                                href:
                                  "products/himacs/" +
                                  product.attribute.orgContentParents[0].name +
                                  "/" +
                                  product.orgContent.id,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }