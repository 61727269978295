import { render, staticRenderFns } from "./Verno.vue?vue&type=template&id=d59fb208"
import script from "./Verno.vue?vue&type=script&lang=js"
export * from "./Verno.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/STG_US_FRONT/synccms-front/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d59fb208')) {
      api.createRecord('d59fb208', component.options)
    } else {
      api.reload('d59fb208', component.options)
    }
    module.hot.accept("./Verno.vue?vue&type=template&id=d59fb208", function () {
      api.rerender('d59fb208', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/automotive/AutomotiveSkin/components/seatCorver/Verno.vue"
export default component.exports