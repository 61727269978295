<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>STONE COLOR</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box color_round">
            <span class="checkbox_more no_line">
            <li v-for="stncolor in stncolorList" :key="stncolor.id.value">
              <input type="checkbox" :id="'hue_stn_'+stncolor.id.value" name="StoneColor" v-model="selected" :value="stncolor.id.value" />
              <i v-if="stncolor.text === 'Other'">
                <span :style="'background-image : url(https://img.lxhausys.com/images/products/' + stncolor.id.value + '.png)'"> </span>{{ stncolor.text }}</i>
                <label :for="`hue_stn_${stncolor.id.value}`">
                <span class="round"></span>
                <span class="text">{{ stncolor.text }}</span>
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'stncolorList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-stoneColor-filter', this.removeStoneColor)
    EventBus.$on('update-stoneColor-filters', this.updateStoneColors)
    if (this.$store.state.filterState && this.$store.state.filterState.hue_stn) {
      this.selected = this.$store.state.filterState.hue_stn
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-stoneColor-filter', this.removeStoneColor)
    EventBus.$on('update-stoneColor-filters', this.updateStoneColors)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeStoneColor (stoneColorValue) {
      this.selected = this.selected.filter(value => value !== stoneColorValue)
    },
    updateStoneColors (stoneColors) {
      this.selected = stoneColors
    },
  }
}
</script>
