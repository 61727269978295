var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application-area" },
    [
      _c(
        "ProductContent",
        [
          [
            _c("ProductSectionHeader", {
              staticClass: "mt-100",
              attrs: { title: "Application" },
            }),
            _c(
              "div",
              { staticClass: "carousel-area" },
              [
                _c("div", { staticClass: "text-area" }, [
                  _c("span", [_vm._v("Home Appliance Surface Film")]),
                  _c("br"),
                  _c("br"),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.selectedItem.title)),
                  ]),
                ]),
                _c(
                  "ProductCarousel",
                  {
                    attrs: { useDot: false, useArrows: true },
                    on: { change: _vm.onChange },
                    scopedSlots: _vm._u([
                      {
                        key: "custom",
                        fn: function (pageData) {
                          return [
                            _c("ThumbSelector", {
                              attrs: {
                                images: _vm.thumbImages,
                                data: pageData,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  },
                  _vm._l(_vm.item, function (it, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "main-image-area" },
                      [
                        _c("img", {
                          attrs: { src: it.main, draggable: "false" },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }