<template>
  <div class="overview-top-image-area">
    <ProductTopImage
      :imageSrc="imageSrc"
      :imageHeight="imageHeight"
      :imageWidth="imageWidth"
    >
      <template #content>
        <pre class="ctt-pre ctt-small desc-add-font-style">{{ description }}</pre>
      </template>
    </ProductTopImage>
  </div>
</template>

<script>
import ProductTopImage from '@/components/product/ProductTopImage.vue';

export default {
  components: {
    ProductTopImage
  },

  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    imageHeight: {
      type: String,
      required: false,
      default: '500px'
    },
    imageWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    description: {
      type: String,
      default: ''
    },
    descriptionWidth: {
      type: String,
      required: false
    }
  }

}
</script>

<style scoped>
.overview-top-image-area {
  display: flex; /* Flex 컨테이너로 설정 */
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */
  margin-top: 54px;
}

.desc-add-font-style {
  color: #FFF;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

@media (max-width: 768px) {
  .desc-add-font-style {
    font-size: 90%;
    font-weight: 330;
    white-space: pre-wrap;
  }
}
</style>
