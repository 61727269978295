var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "social-contribution-strategy-system-vision" },
    [
      _c("SocialResponsibilityBorderTitle", {
        attrs: { title: _vm.borderTitle, padding: "15px 80px" },
      }),
      _c("img", { staticClass: "point", attrs: { src: _vm.point } }),
      _c("div", { staticClass: "border-box-area" }, [
        _c("div", { staticClass: "content-area" }, [
          _c("div", { staticClass: "border-circle" }, [
            _c("div", { staticClass: "red-circle" }, [
              _c("pre", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            ]),
          ]),
          _c("div", { staticClass: "image-area" }, [
            _c("img", { attrs: { src: _vm.image } }),
          ]),
          _c("div", { staticClass: "text-area" }, [
            _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }