var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "business-area-carousel-arrow-area" }, [
    _c("div", { staticClass: "container-area" }, [
      _c("div", {
        staticClass: "arrow-area",
        staticStyle: {
          "background-image":
            "url(images/aboutus/overview/business_area/arrw_left.png)",
        },
        on: {
          click: function ($event) {
            return _vm.onClickPrev($event)
          },
        },
      }),
      _c("div", {
        staticClass: "arrow-area",
        staticStyle: {
          "background-image":
            "url(images/aboutus/overview/business_area/arrw_right.png)",
        },
        on: {
          click: function ($event) {
            return _vm.onClickNext($event)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }