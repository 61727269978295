<template>
  <div class="pollutant-management-image-card-title">
    <img class="image" :src="image">
    <pre class="text">{{ text }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
.pollutant-management-image-card-title {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media (max-width: 768px) {
  .pollutant-management-image-card-title {
    gap: 20px;
  }
}

.pollutant-management-image-card-title .image {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.pollutant-management-image-card-title .text {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 105.882% */
}

@media (max-width: 768px) {
  .pollutant-management-image-card-title .text {
    font-size: 1.0833333333rem;
    line-height: 1.3;
    white-space: pre-wrap;
  }
}
</style>
