var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "financial-statement-table-area" }, [
    _c(
      "table",
      { staticClass: "financial-statement-table" },
      [
        _c("FinancialStatementTableHeader", {
          attrs: { categoryList: _vm.categoryList },
        }),
        _c("FinancialStatementTableColumn", {
          attrs: { dataList: _vm.dataList },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }