var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "design_trend_section" }, [
    _c(
      "div",
      { staticClass: "design_trend_slider" },
      _vm._l(_vm.bannerList, function (banner) {
        return _c(
          "div",
          { staticClass: "item", attrs: { value: banner.content.title } },
          [
            _c("div", { staticClass: "txt-box" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(banner.content.title)),
              ]),
              _c("p", { staticClass: "txt-01" }, [
                _vm._v(_vm._s(banner.attribute.MAIN_BANNER_TOBE_DESCRIPTION)),
              ]),
              banner.attribute.MAIN_BANNER_TOBE_URL
                ? _c(
                    "a",
                    {
                      staticClass: "btn-more",
                      on: {
                        click: function ($event) {
                          return _vm.openLink(banner)
                        },
                      },
                    },
                    [_c("span", [_vm._v("View more")])]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "bannerGradient" }),
            _c("img", {
              staticClass: "slider_bg",
              attrs: {
                src: _vm.$imagePath + banner.attribute.MAIN_BANNER_TOBE_IMAGE,
                alt: "designtrend",
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }