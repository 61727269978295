var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "Social Contribution Strategy System",
            color: "#222",
            size: "56px",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("SustainabilityText", { attrs: { text: _vm.text } }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            450859715
          ),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("SocialContributionStrategySystemVision", {
                attrs: {
                  point: _vm.vision.point,
                  title: _vm.vision.title,
                  text: _vm.vision.text,
                  image: _vm.vision.image,
                  borderTitle: _vm.vision.borderTitle,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemKey", {
                attrs: {
                  borderTitle: _vm.key.borderTitle,
                  cardItems: _vm.key.cardItems,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemGoal", {
                attrs: {
                  borderTitle: _vm.goal.borderTitle,
                  cardItems: _vm.goal.cardItems,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "社会貢献戦略システム",
            color: "#222",
            size: "56px",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("SustainabilityText", { attrs: { text: _vm.text_jp } }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("SocialContributionStrategySystemVision", {
                attrs: {
                  point: _vm.vision_jp.point,
                  title: _vm.vision_jp.title,
                  text: _vm.vision_jp.text,
                  image: _vm.vision_jp.image,
                  borderTitle: _vm.vision_jp.borderTitle,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemKey", {
                attrs: {
                  borderTitle: _vm.key_jp.borderTitle,
                  cardItems: _vm.key_jp.cardItems,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemGoal", {
                attrs: {
                  borderTitle: _vm.goal_jp.borderTitle,
                  cardItems: _vm.goal_jp.cardItems,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "Система стратегий социального вклада",
            color: "#222",
            size: "56px",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("SustainabilityText", { attrs: { text: _vm.text_ru } }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("SocialContributionStrategySystemVision", {
                attrs: {
                  point: _vm.vision_ru.point,
                  title: _vm.vision_ru.title,
                  text: _vm.vision_ru.text,
                  image: _vm.vision_ru.image,
                  borderTitle: _vm.vision_ru.borderTitle,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemKey", {
                attrs: {
                  borderTitle: _vm.key_ru.borderTitle,
                  cardItems: _vm.key_ru.cardItems,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemGoal", {
                attrs: {
                  borderTitle: _vm.goal_ru.borderTitle,
                  cardItems: _vm.goal_ru.cardItems,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "minor",
            title: "社会贡献战略体系",
            color: "#222",
            size: "56px",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("SustainabilityText", { attrs: { text: _vm.text_cn } }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("SocialContributionStrategySystemVision", {
                attrs: {
                  point: _vm.vision_cn.point,
                  title: _vm.vision_cn.title,
                  text: _vm.vision_cn.text,
                  image: _vm.vision_cn.image,
                  borderTitle: _vm.vision_cn.borderTitle,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemKey", {
                attrs: {
                  borderTitle: _vm.key_cn.borderTitle,
                  cardItems: _vm.key_cn.cardItems,
                },
              }),
            ],
            1
          ),
          _c(
            "OverviewSectionItem",
            [
              _c("SocialContributionStrategySystemGoal", {
                attrs: {
                  borderTitle: _vm.goal_cn.borderTitle,
                  cardItems: _vm.goal_cn.cardItems,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }