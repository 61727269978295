<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="eu-otherproducts">
      <!-- location -->
      <div class="location type03">
        <ul>
           <li class="ico-home">{{ $t("other.text.home") }}</li>
           <li>{{ $t("other.text.products") }}</li>
           <li>{{ $t("other.text.himacs") }}</li>
           <li>{{ $t("other.text.otherPD") }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (EU HIMACS other product Finder) -->
      <div class="container prod-fd-view himacs-op">
        <!-- visual -->
        <!-- start -->
        <div class="visual visual-detail">
           <div class="visual-image" v-bind:style="{ backgroundImage: 'url( ' + $imagePath + pfBanImg + ')' }">
            <div class="txt-box">
              <h1 class="tt-26">{{ pageTitle }}</h1>
              <p class="t-type-05 tac" v-html="brief"></p>
              <div class="more-box ta-c mb-only">
                <button type="button" class="btn btn-more-point">
                  <span>See More</span>
                </button>
              </div>
            </div>
            <div class="bgdim"></div>
          </div>
        </div>
        <!-- end -->

        <!-- EU 포토크레딧 추가 -->
        <p class="ctt photo-credits ta-r">
          <span class="pc-br" v-if="photoCredit != ''">
            {{ photoCredit }}
          </span>
          <span class="pc-br" v-if="photoCredit2 != ''">
            {{ photoCredit2 }}
          </span>
        </p>

        <!-- product 내용 -->
        <div class="col-02 inspr-view fd-view">
          <!-- <div class="eu_inspTit">
            <p v-html="photoCredit" />
          </div> -->

          <!-- search 필터 선택 pc전용 s -->
          <div id="filterSearch" class="search-box pc-only">
            <div class="search">
              <input id="textSearch" type="text" :placeholder="$t('PF.text.searchText')" v-on:keyup.enter="search"/>
              <!-- <button type="button" id="searchBtn" @click="textSearchDel()">search</button> -->
              <i class="icon-search gray" id="searchBtn"></i>
            </div>

            <!-- EU 페이지 좌측 - Other products (라디오) / Other products (체크박스) -->
            <div class="drop-list inner">
              <ul>
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t("other.text.otherPD") }}</span>
                    <button type="button" class="btn-view">list view</button>
                  </div>

                  <div class="i-box type01 cont" style="display:block;">
                    <ul>
                      <li>
                        <label class="inp-rdo">
                          <input type="radio" name="rdo00" :checked="cateName === 'ALL'" @click="cateTypeChange('ALL' , 0, allCategoryId)">
                          <span>{{ $t("other.text.all") }}</span>
                        </label>
                      </li>
                      <li v-for="(type, index) in otherTypeList">
                        <label class="inp-rdo">
                          <input type="radio" :name="'rdo0'+(index+1)" :checked="cateName === type.category.code.split('_')[2]" @click="cateTypeChange(type.category.code.split('_')[2], (index+1), type.category.id)">
                          <span>{{ type.category.name }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- use -->
                <li class="on">
                  <div class="tit">
                    <span class="tt-sub">{{ $t("other.text.use") }}</span>
                    <button type="button" class="btn-view btn-collection">USE</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul>
                      <li v-for="(use, index) in typeUSE" @change="typeSearch('cate_use', use.text, use.id.value, 'use')">
                        <label class="inp-chk">
                          <input type="checkbox" class="Collection" :id="'use_'+use.id.value" :class="{ checked : typeUSE.length == 1}">
                          <span>{{ use.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="on" v-show="inqShow">
                  <div class="tit">
                    <span class="tt-sub">{{ $t("other.text.install") }}</span>
                    <button type="button" class="btn-view btn-collection">{{ $t("other.text.install") }}</button>
                  </div>

                  <div class="i-box type02 cont" style="display:block;">
                    <ul>
                      <li v-for="(install, index) in typeInstall" @change="typeSearch('cate_install', install.text, install.id.value, 'install')">
                        <label class="inp-chk">
                          <input type="checkbox" class="Collection" :id="'install_'+install.id.value">
                          <span>{{ install.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Collection -->
              </ul>
              <button
                class="filter-clos"
                v-show="isMobile() || filterShow"
                @click="clickFilter()"
              ></button>
            </div>
          </div>
          <!-- // search 필터 선택 pc전용 e -->

          <div class="prod-list-box other-pf">

            <!-- 모바일용 검색창 s -->
            <div class="fd-view-mb search-area">
              <div class="search">
                <input id="textSearchM" type="text" placeholder="Enter Item Number or Name">
                <i id="searchBtnM" class="icon-search gray"></i>
              </div>
            </div>
            <!-- 모바일용 검색창 e -->

            <div class="tab-ui inner">

              <!-- 모바일용 filter by 버튼 s -->
              <div class="filter-arae mb-only">
                <!-- <button type="button" @click="clickFilter()"> -->
                <button type="button" @click="openSearchPopup('layer-search-box')">
                  {{ $t('PF.button.filter_by')}}<i></i>
                </button>
              </div>
              <!-- 모바일용 filter by 버튼 e -->

              <!-- sort -->
              <div class="sort-box mt-0">
                <div class="sel-box">
                  <button type="button" class="btn-sel">
                    <span>
                      {{ sortBy }}
                    </span>
                  </button>
                  <div class="sel-list">
                    <ul class="box">
                      <li>
                        <button type="button" @click="sortByChange( $t('other.text.popu'), 'desc', 'clicks')">{{ $t("other.text.popu") }}</button>
                      </li>
                      <li>
                        <button type="button" @click="sortByChange( $t('other.text.Asc'), 'asc', 'title')">{{ $t("other.text.Asc") }}</button>
                      </li>
                      <li>
                        <button type="button" @click="sortByChange( $t('other.text.Desc'), 'desc', 'title')">{{ $t("other.text.Desc") }}</button>
                      </li>
                      <li>
                        <button type="button" @click="sortByChange( $t('other.text.Newest'), 'desc')">{{ $t("other.text.Newest") }}</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- //sort -->
              <div class="select-view">
                <!-- 퍼블작업시 임시 숨김 아래에 작성 -->
                <ul class="list-filter-key">
                  <li class="sel" v-for="filter in dicTextList" @click="colorDelete(filter.subject, filter.name, filter.id, filter.type)">
                    <span>{{filter.name}}</span>
                    <button type="button" >&#10005;</button>
                  </li>
                  <li class="sel del_all" v-if="dicTextList.length > 0">
                    <button class="btn-all-delete" @click="allTypeDelete()">
                      <span>Clear All <em>X</em></span>
                    </button>
                  </li>
                </ul>
              </div>

              <!-- BATHS와 SHOWER TRAYS 선택시만 노출 -->
              <div class="please-note-box" v-if="textShow">
                <p class="pn-title">{{ $t("other.text.note") }} :</p>
                <ol class="pn-num-list" v-if="this.cateName !== 'BASINS'">
                  <li>{{ $t("other.text.noteText1") }}</li>
                  <li>{{ $t("other.text.noteText2") }}</li>
                </ol>
                <ol class="pn-num-list" v-else>
                  <li>{{ $t("other.text.noteText_basins") }}</li>
                </ol>
              </div>

              <!-- All products -->
              <div class="tab-con tab01 on">
                <div class="list-type-view row-04">
                  <ul>
                   <li v-for="(content, index) in contentList">
                      <!-- onclick="tmpPopup('hi-op-pop')"-->
                      <div class="over" @click="openPopUp(content)">

                        <div class="img-box cursor-pointer">
                          <span class="new">{{ content.parents[0].name }}</span>
                          <!--<img :src='`../images/products/no-image.png`'>-->
                          <img :src="$imagePath+content.attribute.pc_thunb_prd"/>
                        </div>
                      </div>
                      <div class="info">
                        <ul>
                          <li class="txt-01">
                            {{ content.content.title }}
                          </li>
                          <li class="txt-03">
                            {{ content.attribute.prd_code }}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- See More 버튼  -->
                <div class="more-box ta-c" v-if="contentLength > (pageIndex/12)*12*12">
                  <button type="button" class="btn btn-arr-down" @click="loadMore(pageIndex+1)"><span>See More</span></button>
                </div>
              </div>
              <!-- //All products -->

              <!-- Grouped by collections -->
            </div>

            <!-- short cut -->
            <div class="short-link type02 mb-64">
              <a :href="'support/contactus'"><span class="ico-07">{{ $t('PCD.text.contact_us')}}</span></a>
              <a href="pros/documentation/viatera"><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a>
              <a href="support/sales-representative/continenteu/himacs"><span class="ico-19">{{ $t('other.text.sales_rep')}}</span></a>
              <a href="https://www.lxhausysusa.com/showroom/index.html"><span class="ico-06">{{ $t('other.text.showroom')}}</span></a>
            </div>
            <!-- //short cut -->
          </div>
        </div>
        <!-- //product 내용 -->

        <!-- popup : Other Products -->
        <div class="layer-popup layer-other hi-op-pop">
          <div class="popup pop-close">
            <div class="pop-other scr-y">
              <!-- sldier start -->
              <div class="op-pop-pic">
                <div class="op-pop-slide-top">
                  <div class="op-pop-imgbox on" id="popimage_01" name="popImage">
                    <img :src="$imagePath + contentAttribute.pc_thunb_prd" alt="slider_img01">
                  </div>
                  <div class="op-pop-imgbox" :id="'popimage_0'+(index+2)" name="popImage" v-for="(image, index) in contentFile">
                    <img :src="$imagePath + image.filePath" :alt="'slider_img0'+(index+1)">
                  </div>
                </div>
                <div class="op-pop-slide-bottom">
                  <div class="op-pop-imgbox">
                    <img :src="$imagePath + contentAttribute.pc_thunb_prd" alt="slider_img01" @click="popupImage(1, this)">
                  </div>
                  <div class="op-pop-imgbox" v-for="(image, index) in contentFile">
                    <img :src="$imagePath + image.filePath" :alt="'slider_img0'+(index+1)" @click="popupImage(index+2, this)">
                  </div>
                  <div class="op-pop-slide-arrow">
                    <button class="slide-arrow-left"><span><img src="https://img.lxhausys.com/public/images/arrw_left_05.png" alt="arrow-left"></span></button>
                    <button class="slide-arrow-right"><span><img src="https://img.lxhausys.com/public/images/arrw_right_17.png" alt="arrow-right"></span></button>
                  </div>
                </div>
              </div>
              <!-- sldier end -->
              <div class="info">
                <ul>
                  <li class="txt-01">{{ popUpCateName }}</li>
                  <li class="txt-02 tt-1">{{ contentContent.title }}</li>
                </ul>
                <div class="tbl-box op-pop-tbl-box">
                  <table>
                    <caption>Product Specs</caption>
                    <colgroup>
                      <!-- <col style="width:187px">
                      <col style="width:auto"> -->
                      <col>
                      <col>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th scope="row">{{ $t("other.text.prd_ref") }}</th>
                      <td>{{ contentAttribute.prd_code }}</td>
                    </tr>
                    <tr>
                      <!-- 수정 필요 -->
                      <th scope="row">{{ $t("other.text.dim") }}</th>
                      <td><span class="ctt ctt-t5" style="color: #666;">{{ contentAttribute.prd_L }}(L) x {{ contentAttribute.prd_W }}(W)</span><span class="ctt ctt-t5" style="color: #666;" v-if="contentAttribute.prd_H != undefined"> x {{ contentAttribute.prd_H }}(H) </span><span class="ctt ctt-t5" style="color: #666;"> mm</span></td>
                      <!-- <td v-if="contentAttribute.prd_H != undefined"> x {{ contentAttribute.prd_H }}(H)</td>
                      <td>mm</td> -->
                    </tr>
                    <tr>
                      <th scope="row">{{ $t("other.text.install") }}</th>
                      <td>{{ contentAttribute.cate_installText }}</td>
                    </tr>
                    <tr>
                      <!-- 두줄로나옴 수정 필요 -->
                      <th scope="row">{{ $t("other.text.more") }}</th>
                      <td>{{ contentAttribute.more }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="op-pop-doc">
                <p class="tt-sub">{{ $t("PCD.text.documentation") }}</p>
                <div class="cont">
                  <ul>
                    <li class="doc-drop-li" v-if="contentAttribute.pdf != undefined">
                      <span class="ctt ctt-t4">{{ $t("other.text.kbb") }}</span>
                      <button type="button" class="download" @click="download(contentAttribute.pdf)"></button>
                    </li>
                    <li class="doc-drop-li" v-if="contentAttribute.dxf != undefined">
                      <span class="ctt ctt-t4">{{ contentAttribute.dxf_orgName }}</span>
                      <button type="button" class="download" @click="download(contentAttribute.dxf)"></button>
                    </li>
                    <li class="doc-drop-li" v-if="contentAttribute.dxf2 != undefined">
                      <span class="ctt ctt-t4">{{ contentAttribute.dxf2_orgName }}</span>
                      <button type="button" class="download" @click="download(contentAttribute.dxf2)"></button>
                    </li>
                    <li class="doc-drop-li" v-if="contentAttribute.dxf3 != undefined">
                      <span class="ctt ctt-t4">{{ contentAttribute.dxf3_orgName }}</span>
                      <button type="button" class="download" @click="download(contentAttribute.dxf3)"></button>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="btns claer">
                <div class="fl-l">
                  <button class="btn btn-line btn-xs" @click="linkUrl(contentAttribute.prd_code)"><span>{{ $t("other.text.contactus") }}</span></button>
                  <button class="btn btn-sm btn-second" @click="linkUrl('room')"><span>{{ $t("other.text.showroom") }}</span></button>
                </div>
              </div>
              <button type="button" class="btn-close" onclick="LayerPopup.close();"></button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : Other Products -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb" style="display: none">
          <div class="popup">
            <div class="scr-y">
              <!-- <div class="search">
                <input
                  id="textSearch"
                  type="text"
                  placeholder="Enter Item Number or Name"
                  @input="textSearch($event)"
                />
                <button
                  type="button"
                  id="searchBtn"
                  class="icon-search gray"
                  @click="textSearchDel()"
                >
                  search
                </button>
              </div> -->

              <!-- color / us | gl -->
              <div class="drop-list">
                <p class="tt-2">Filter By</p>
                <button
                  class="filter-clos"
                  @click="closeSearchPopup()"
                ></button>
                <ul>
                  <li class="on">

                    <!-- us / gl title -->
                    <div class="tit" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <span>{{ $t('PD.text.color')}}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.button.color')}}
                      </button>
                    </div>

                    <!-- eu / uk title -->
                    <div class="tit" v-if="nationCode != '_GL' && nationCode != '_US'">
                      <span>{{ $t('PF.text.Tones')}}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.text.Tones')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <ul class="i-chk type03">
                        <li v-for="lefthue in lefthue">
                          <label class="inp-chk chk-color">
                            <input
                              type="checkbox"
                              name="color"
                              :id="'hue_' + lefthue.id.value"
                              @change="
                                [
                                  fnSearch(
                                    'hue',
                                    lefthue.text,
                                    lefthue.id.value,
                                    'Color'
                                  ),
                                ]
                              "
                            />
                            <i v-if="lefthue.text === 'Other'"
                            ><span
                              :style="
                                  'background-image : url(images/products/' +
                                  lefthue.id.value +
                                  '.png)'
                                "
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <i v-else
                            ><span
                              :style="'background-color:#' + lefthue.id.value"
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <span>{{ lefthue.text }}</span>
                          </label>
                          <!-- 이방식으로 교체합니다-->
                        </li>
                      </ul>
                    </div>
                    <!-- color / us | gl end -->

                    <!-- tone / eu | uk -->
                    <div class="i-box type02 cont" style="display: block"  v-if="nationCode != '_GL' && nationCode != '_US'">
                      <ul class="i-chk type03">
                        <li v-for="lefthue in lefthue">
                          <label class="inp-chk chk-color">
                            <input
                              type="checkbox"
                              name="color"
                              :id="'hue_' + lefthue.id.value"
                              @change="
                                [
                                  fnSearch(
                                    'hue',
                                    lefthue.text,
                                    lefthue.id.value,
                                    'Tone'
                                  ),
                                ]
                              "
                            />
                            <i v-if="lefthue.text === 'Other'"
                            ><span
                              :style="
                                  'background-image : url(images/products/' +
                                  lefthue.id.value +
                                  '.png)'
                                "
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <i v-else
                            ><span
                              :style="'background-color:#' + lefthue.id.value"
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <span>{{ lefthue.text }}</span>
                          </label>
                          <!-- 이방식으로 교체합니다-->
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- tone / eu | uk end -->

                  <!-- Collection -->
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('PCD.text.collection')}}</span>
                      <button type="button" class="btn-view btn-collection">
                        {{ $t('PF.button.collection')}}
                      </button>
                    </div>
                    <div class="i-box type02 cont" style="display: block">
                      <ul>
                        <!--                      <li>
                                                <label class="inp-chk">
                                                  <input type="checkbox" checked id="CateAll" @click="[fnCateSearch('CateAll','CateAll')]">
                                                  <span>All</span>
                                                </label>
                                              </li>-->

                        <li v-for="coll in leftcollection">
                          <label
                            class="inp-chk"
                            v-if="coll.category.hidden === false"
                          >
                            <input
                              type="checkbox"
                              class="Collection"
                              :id="coll.category.id"
                              @change="
                                [
                                  fnCateSearch(
                                    coll.category.name,
                                    coll.category.id
                                  ),
                                ]
                              "
                            />
                            <span
                            >{{ coll.category.name
                              }}<strong
                                class="ico-g"
                                v-if="coll.category.name === 'Eden Plus'"
                              >{{ $t('PF.text.Recycled')}}</strong
                              ></span
                            >
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Collection -->

                  <!-- Patterns -->
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('PF.text.patterns')}}</span>
                      <button type="button" class="btn-view btn-patterns">
                        {{ $t('PF.button.patterns')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk type02">
                        <!--                      <li>
                                                <label class="inp-chk">
                                                  <input type="checkbox" checked id="PatternAll"  @click="[fnSearch('Pattern','PatternAll')]">
                                                  <span class="ico-all">All</span>
                                                </label>
                                              </li>-->

                        <li v-for="leftpattern in leftpattern">
                          <label class="inp-chk chk-thumb">
                            <input
                              type="checkbox"
                              class="pattern"
                              :id="'pattern_' + leftpattern.id.value"
                              @change="
                                [
                                  fnSearch(
                                    'pattern',
                                    leftpattern.text,
                                    leftpattern.id.value,
                                    'Pattern'
                                  ),
                                ]
                              "
                            />>
                            <span
                            ><i
                              :style="
                                  'background-image : url(images/products/' +
                                  leftpattern.id.value +
                                  '.png)'
                                "
                            ></i
                            >{{ leftpattern.text }}</span
                            >
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Patterns -->

                  <!-- Country -->
                  <li v-if="nationCode == '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.country')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.button.country')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftCountry in leftCountry">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="country"
                              :id="'country_' + leftCountry.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'country',
                                    leftCountry.text,
                                    leftCountry.id.value,
                                    'Country'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftCountry.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Country -->

                  <!-- New -->
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('PF.text.new')}}</span>
                      <button type="button" class="btn-view btn-new">{{ $t('PF.button.new')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul>
                        <li>
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="new"
                              :id="'isNew_true'"
                              @click="
                                [fnSearch('isNew', 'NEW COLORS', 'true', 'New')]
                              "
                            />
                            <span>{{ $t('PF.text.new_colors')}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- New -->

                  <!-- USE -->
                  <li v-if="nationCode != '_US' && nationCode != '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.Use')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.text.Use')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftUse in leftUse">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="USE"
                              :id="'USE_' + leftUse.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'USE',
                                    leftUse.text,
                                    leftUse.id.value,
                                    'USE'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftUse.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- USE -->

                  <!-- Recycled -->
                  <li v-if="nationCode != '_US' && nationCode != '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.Recycled')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.text.Recycled')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftRecycled in leftRecycled">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="recycled"
                              :id="'recycled_' + leftRecycled.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'recycled',
                                    leftRecycled.text,
                                    leftRecycled.id.value,
                                    'Recycled'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftRecycled.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Recycled -->

                  <!-- Thickness -->
                  <li v-if="nationCode != '_US' && nationCode != '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.Thickness')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.text.Thickness')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftThickness in leftThickness">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="thickness"
                              :id="'thickness_' + leftThickness.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'thickness',
                                    leftThickness.text,
                                    leftThickness.id.value,
                                    'Thickness'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftThickness.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Thickness -->

                  <!-- <li>
                      <div class="tit">
                          <span>Segments</span>
                          <button type="button" class="btn-view btn-segments">Segments</button>
                      </div>

                      <div class="l-box cont">
                          <ul>
                              <li v-for="(segName, index) in segNames" v-bind:key="index">
                                  <button type="button" @click="[fnSearch('Segments',segName)]">{{segName.name}}</button>
                              </li>
                          </ul>
                      </div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->

      </div>
      <!-- //container (EU HIMACS other product Finder) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import ProductService from '@/server/product.service'
import categoryService from '@/server/category.service'
import global from '@/common/global'
import _ from '@/common/helper/lodash'
import { mapState } from 'vuex'
import { element } from 'screenfull'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'otherProducts',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      code: '',
      start: false,
      textShow: false,
      banner: [],
      brief: '',
      pfBanImg: '',
      photoCredit: '',
      photoCredit2: '',
      pageTitle: '',
      contentFile: [],
      content: {},
      contentContent: {},
      contentAttribute: {},
      popUpCateName: '',
      popUpliked: '',
      categoryList: [],
      bannerAttribute: '',
      otherTypeList: [],
      typeUSE: [],
      orgTypeUSE: [],
      typeInstall: [],
      inqShow: false,
      cateName: 'ALL',
      allCategoryId: '',
      categoryId: '',
      nationCode: '',
      sortBy: this.$t('other.text.sortby'),
      contentLength: '',
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          }
        ]
      },
      contentList: [],
      dicTextList: [],
      // pagination
      totalCount: '',
      totalPage: '',
      pageIndex: 1,
      pageSize: 12,
      pageList: [],
      firstNumber: 1,
      lastNumber: 10,
      orderField: [],
      orderType: [],

      filterShow: false

    }
  },
  computed: {
    subText () {
      return this.bannerAttribute.replace('\n', '<br/>')
    },
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
  },
  async mounted () {
    const _this = this

    _this.cateName = _this.$route.params.category_name
    // let cateType = _this.$route.params.category_type;
    const cateCode = 'HIMACS_OTHER' + _this.nationCode
    _this.code = cateCode
    const categoryRes = await categoryService.getCategoryCode(_this.code) /* 배너 카테고리 */
    const contentRes = await ProductService.getCategoryListAttr(categoryRes.data.object.id) /* 배너 콘텐츠 */
    _this.categoryList.push({ category: contentRes.data.object, categoryAttribute: contentRes.data.attribute }) /* 배너 콘텐츠 리스트 */
    _this.allCategoryId = categoryRes.data.object.id
    /* all 카테고리 id */
    const categoryList = await categoryService.getCategoryAttributeList(_this.allCategoryId) /* 카테고리 리스트 탐색 */
    _this.otherTypeList = categoryList.data.list
    categoryList.data.list.forEach((cate, idx) => {
      _this.categoryList.push(cate)
    })
    if (_this.cateName == 'ALL') {
      _this.banner.push(_this.categoryList[0])
    } else {
      _this.banner = _this.categoryList.filter((element) => element.category.code.split('_')[2] === _this.cateName)
    }
    _this.brief = _this.banner[0].categoryAttribute.brief
    _this.pfBanImg = _this.banner[0].categoryAttribute.pf_image
    _this.photoCredit = _this.banner[0].categoryAttribute.photo_credit
    _this.photoCredit2 = _this.banner[0].categoryAttribute.photo_credit2
    _this.pageTitle = _this.banner[0].categoryAttribute.page_title
    _this.categoryId = _this.banner[0].category.id
    /* compare */
    const dictionary = await ProductService.getDictionaryValueList(['OTHER_USE' + _this.nationCode, 'OTHER_INSTALL' + _this.nationCode])
    _this.orgTypeUSE = dictionary.data.dicList[0].list
    _this.typeUSE = _this.orgTypeUSE
    _this.typeInstall = dictionary.data.dicList[1].list.filter((element) => element.id.value != 'flush')
    _this.$nextTick(function () {
      if (_this.cateName == 'ALL') {
        _this.cateTypeChange(_this.cateName, 0, _this.banner[0].category.id, 'start')
      } else {
        _this.cateTypeChange(_this.cateName, _this.banner[0].category.sort, _this.banner[0].category.id, 'start')
      }
      _this.start = true
      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })
    })
  },
  methods: {
    linkUrl (comment) {
      if (comment == 'room') {
        location.href = 'inspiration/virtual-showroom'
      } else {
        location.href = 'support/contactus/himacs/' + comment
      }
    },

    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow', 'auto')
    },

    popupImage (index) {
      $("[name*='popImage']").fadeOut(300)
      $('#popimage_0' + index).fadeIn(300)
    },
    async download (file) {
      const url = this.$imagePath + file
      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }
      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', url)
        document.body.appendChild(element)
        element.click()
      })
    },
    async openPopUp (content) {
      await ProductService.getClickUp(content.content.id)
      const _this = this
      const contentFile = await ProductService.getContentFileList(content.content.id)
      _this.contentFile = contentFile.data.page.list
      _this.content = content
      _this.contentContent = content.content
      _this.contentAttribute = content.attribute

      _this.popUpCateName = content.parents[0].name
      _this.popUpliked = content.content.liked
      // _this.$nextTick(function(e){
      //
      //   e.stopPropagation();
      // })
      $("[name*='popImage']").fadeOut(300)
      $('#popimage_01').fadeIn(300)
      LayerPopupGal.open('hi-op-pop')
      return false
    },
    cancelPropagation (event) {
      if (event.stopPropagation) {
        event.stopPropagation()
      } else {
        event.cancelBubble = true
      }
    },
    async loadMore (index) {
      const _this = this
      let word = '' // 검색어
      if (_this.isMobile()) {
        word = $('#textSearchM').val()
      } else {
        word = $('#textSearch').val()
      }
      _this.pageIndex = index
      _this.pageSize = _this.pageSize

      const contentList = await ProductService.getDictionaryContent(_this.categoryId, _this.dictionaryCondition, word, _this.pageIndex, _this.pageSize, _this.orderField, _this.orderType, ['title', 'search_text'])
      for (let i = 0; i < contentList.data.list.length; i++) {
        _this.contentList.push(contentList.data.list[i])
      }
      _this.contentLength = contentList.data.totalCount
    },
    async search () { // 검색 이벤트
      const _this = this
      _this.pageIndex = 1
      await _this.getItem(1, 12)
    },
    colorDelete (searchCd, name, value, type) {
      const _this = this
      $('#' + type + '_' + value).prop('checked', false)
      _.remove(_this.dicTextList, function (v) {
        return v.subject == searchCd && v.id == value
      })
      _.remove(_this.dictionaryCondition.list, function (c) {
        _.remove(c.values, function (v) {
          return v.value == value && v.id == searchCd
        })
      })
      _this.getItem(1, 12)
    },
    allTypeDelete () {
      const _this = this
      _this.dicTextList = []
      _this.dictionaryCondition.list[0].values = []
      _this.dictionaryCondition.list[1].values = []
      $('input:checkbox').prop('checked', false)

      _this.getItem(1, 12)
    },
    typeSearch (searchCd, text, value, type) {
      const _this = this
      if ($('#' + type + '_' + value).is(':checked')) {
        if (type === 'use') {
          _this.dictionaryCondition.list[0].values.push({
            id: searchCd,
            value: value
          })
        } else if (type === 'install') {
          _this.dictionaryCondition.list[1].values.push({
            id: searchCd,
            value: value
          })
        }
        _this.dicTextList.push({
          subject: searchCd,
          name: text,
          id: value,
          type: type
        })
      } else {
        _.remove(_this.dicTextList, function (v) {
          return v.subject == searchCd && v.id == value
        })
        _.remove(_this.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == value && v.id == searchCd
          })
        })
      }
      _this.getItem(1, 12)
    },
    async cateTypeChange (cateType, index, id, start) {
      const _this = this
      _this.categoryId = id
      _this.cateName = cateType
      _this.dicTextList = []
      _this.dictionaryCondition.list[0].values = []
      _this.dictionaryCondition.list[1].values = []
      if (start != 'start') {
        if (_this.cateName == 'ALL') {
          _this.banner = []
          _this.banner.push(_this.categoryList[0])
        } else {
          _this.banner = _this.categoryList.filter((element) => element.category.code.split('_')[2] === _this.cateName)
        }
        _this.brief = _this.banner[0].categoryAttribute.brief
        _this.pfBanImg = _this.banner[0].categoryAttribute.pf_image
        _this.photoCredit = _this.banner[0].categoryAttribute.photo_credit
        _this.pageTitle = _this.banner[0].categoryAttribute.page_title
        _this.categoryId = _this.banner[0].category.id
      }

      $('input:checkbox').prop('checked', false)
      if (index == 0) {
        _this.typeUSE = _this.orgTypeUSE
      } else if (index == 1) {
        _this.typeUSE = _this.orgTypeUSE.filter((element) => element.id.value !== 'bath')
      } else if (index == 5) {
        _this.typeUSE = _this.orgTypeUSE.filter((element) => element.id.value === 'health')
      } else if (index == 3) {
        _this.typeUSE = _this.orgTypeUSE.filter((element) => element.id.value === 'bath')
      } else {
        _this.typeUSE = _this.orgTypeUSE.filter((element) => element.id.value === 'bath')
      }
      if (cateType == 'BASINS') {
        _this.inqShow = true
      } else {
        _this.inqShow = false
      }
      if (cateType == 'BATHS' || cateType == 'SHOWER' || cateType == 'BASINS') {
        _this.textShow = true
      } else {
        _this.textShow = false
      }
      _this.$nextTick(function () {
        _this.getItem(1, 12)
      })
    },
    async getItem (pageIndex, pageSize) {
      const _this = this

      let word = '' // 검색어
      if (_this.isMobile()) {
        word = $('#textSearchM').val()
      } else {
        word = $('#textSearch').val()
      }
      _this.pageIndex = 1
      _this.pageSize = pageSize

      // let res = await ProductService.getContentListTapField(t.categoryUsId, t.value, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType)
      const contentList = await ProductService.getDictionaryContent(_this.categoryId, _this.dictionaryCondition, word, _this.pageIndex, _this.pageSize, _this.orderField, _this.orderType, ['title', 'search_text'])
      _this.contentList = contentList.data.list
      _this.contentLength = contentList.data.totalCount
    },
    async sortByChange (text, type, field) {
      const _this = this
      _this.sortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.getItem(1, 12)
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */

    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    clickFilter () {
      const element = document.getElementById('filterSearch')

      if (this.filterShow === false) {
        element.style.cssText = 'z-index: 99999999'
      }

      if (this.filterShow === true) {
        element.style.cssText = 'display : none'
      }

      return this.filterShow = !this.filterShow
    }

  }
}

// slide script
// $(function(){
//   $(".hi-op-pop .op-pop-pic .op-pop-slide-top .op-pop-imgbox").eq(0).fadeIn(300);
//   $(".hi-op-pop .op-pop-pic .op-pop-slide-bottom .op-pop-imgbox").click(function(){
//
//     var i=$(this).index();
//
//     $(".hi-op-pop .op-pop-pic .op-pop-slide-top .op-pop-imgbox").fadeOut(300);
//     $(".hi-op-pop .op-pop-pic .op-pop-slide-top .op-pop-imgbox").eq(i).fadeIn(300);
//   });
// });

// slide script end

</script>
