var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Direction of Strategy",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "border-card-area" },
              [
                _c("DirectionOfStrategyBorderCard", {
                  attrs: { txt: _vm.borderCardItem.txt },
                }),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  _vm._l(_vm.cardItem, function (it, idx) {
                    return _c("DirectionOfStrategyCard", {
                      key: idx,
                      attrs: { idx: idx, txt: it.txt },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "戦略の方向性",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "border-card-area" },
              [
                _c("DirectionOfStrategyBorderCard", {
                  attrs: { txt: _vm.borderCardItem_jp.txt },
                }),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  _vm._l(_vm.cardItem_jp, function (it, idx) {
                    return _c("DirectionOfStrategyCard", {
                      key: idx,
                      attrs: { idx: idx, txt: it.txt },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Направление стратегии",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "border-card-area" },
              [
                _c("DirectionOfStrategyBorderCard", {
                  attrs: { txt: _vm.borderCardItem_ru.txt },
                }),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  _vm._l(_vm.cardItem_ru, function (it, idx) {
                    return _c("DirectionOfStrategyCard", {
                      key: idx,
                      attrs: { idx: idx, txt: it.txt },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSmallSection",
        { attrs: { title: "战略方向", fontFamily: "Lato", fontWeight: "700" } },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "border-card-area" },
              [
                _c("DirectionOfStrategyBorderCard", {
                  attrs: { txt: _vm.borderCardItem_cn.txt },
                }),
                _c(
                  "div",
                  { staticClass: "card-area" },
                  _vm._l(_vm.cardItem_cn, function (it, idx) {
                    return _c("DirectionOfStrategyCard", {
                      key: idx,
                      attrs: { idx: idx, txt: it.txt },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }