<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- 스크린리더에만 읽힘 페이지에 안보임 -->
    <h1 class="sr-only">LX Hausys</h1>

    <div class="content cntu-view">
      <!-- location -->
      <!-- <div class="location">
        <ul>
          <li class="ico-home">{{ $t("contactus.text.home") }}</li>
          <li>{{ $t("contactus.text.support") }}</li>
          <li>{{ $t("contactus.text.contactus") }}</li>
        </ul>
      </div> -->
      <!-- //location -->

      <div class="keyvisual-hei-750" v-if="keyVisual" v-html="keyVisual">
      </div>

      <div v-if="featuresBenefits">
        <p class="tt-4 tac mb-80">FEATURES & BENEFITS</p>

        <div class="wrapper-cms-cnm type-col-03">
          <div class="ico-box" v-html="featuresBenefits">
          </div>
        </div>
      </div>

      <p class="tt-4 tac mb-80">GET INSPIRED</p>
      <div class="google-ads-inspr inner" v-if="inspired">
        <div class="slide-wrapper">
          <button class="arrow-prev">Prev</button>
          <button class="arrow-next">Next</button>
          <div class="slick-container">
            <!-- item -->
            <div class="slider" v-if="inspired.airports">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.airports')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.airports" :alt="$t('google_ads.airports')">
                  <span class="ads-inspr-tit">{{$t('google_ads.airports')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.bathroom">
              <div class="thumb">
                <a :href="'inspiration/inspirationGallery/' + $t('google_ads.bathroom')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.bathroom" :alt="$t('google_ads.bathroom')">
                  <span class="ads-inspr-tit">{{$t('google_ads.bathroom')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.education">
              <div class="thumb">
                <a :href="'inspiration/inspiration/inspirationGallery/' + $t('google_ads.education')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.education" :alt="$t('google_ads.education')">
                  <span class="ads-inspr-tit">{{$t('google_ads.education')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.façade">
              <div class="thumb">
                <a :href="'inspiration/inspiration/inspirationGallery/' +$t('google_ads.façade')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.façade" :alt="$t('google_ads.façade')">
                  <span class="ads-inspr-tit">{{$t('google_ads.façade')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.furniture">
              <div class="thumb">
                <a :href="'inspiration/inspiration/inspirationGallery/' + $t('google_ads.furniture')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.furniture" :alt="$t('google_ads.furniture')">
                  <span class="ads-inspr-tit">{{$t('google_ads.furniture')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.healthcare">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' +$t('google_ads.healthcare')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.healthcare" alt="$t('google_ads.healthcare')">
                  <span class="ads-inspr-tit">{{$t('google_ads.healthcare')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.hospitality">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.hospitality')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.hospitality" :alt="$t('google_ads.hospitality')">
                  <span class="ads-inspr-tit">{{$t('google_ads.hospitality')}}</span>
                </a>
              </div>
            </div>
            <div class="slider" v-if="inspired.kitchen">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.kitchen')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.kitchen" :alt="$t('google_ads.kitchen')">
                  <span class="ads-inspr-tit">{{$t('google_ads.kitchen')}}</span>
                </a>
              </div>
            </div>

            <div class="slider" v-if="inspired.offices">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.offices')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.offices" :alt="$t('google_ads.offices')">
                  <span class="ads-inspr-tit">{{$t('google_ads.offices')}}</span>
                </a>
              </div>
            </div>

            <div class="slider" v-if="inspired.public_buildings">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.public_buildings')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.public_buildings" :alt="$t('google_ads.public_buildings')">
                  <span class="ads-inspr-tit">{{$t('google_ads.public_buildings')}}</span>
                </a>
              </div>
            </div>

            <div class="slider" v-if="inspired.retail">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.retail')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.retail" :alt="$t('google_ads.retail')">
                  <span class="ads-inspr-tit">{{$t('google_ads.retail')}}</span>
                </a>
              </div>
            </div>

            <div class="slider" v-if="inspired.marine_transport">
              <div class="thumb">
                <a :href="'inspiration/case-studies/INS_CAS/segment/' + $t('google_ads.marine_transport')" style="width: 240px; height: 360px;">
                  <img :src="$imagePath + inspired.marine_transport" :alt="$t('google_ads.marine_transport')">
                  <span class="ads-inspr-tit">{{$t('google_ads.marine_transport')}}</span>
                </a>
              </div>
            </div>
            <!-- end item -->
          </div>
        </div>
      </div>

      <div class="wrap-kv-colours" v-if="colours" v-html="colours">
      </div>

      <div class="google-ads-cntu">
        <p class="tt-4 tac mb-80 inner">Contact Us</p>
        <ContactEUForm :product-type="productType" :is-google-ads="true" v-if="productType"></ContactEUForm>
      </div>
    </div>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import ContactEUForm from '@/views/support/contactus_EUForm'
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GoogleAdsService from '@/server/google.ads.service'
import GnbComponent from '@/components/Layout/GNB'

export default {
  name: 'GoogleAds',
  components: {
    ContactEUForm,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      productType: null,
      keyVisual: null,
      featuresBenefits: null,
      colours: null,
      inspired: null
    }
  },
  created () {
  },
  async mounted () {
    this.getContent()
  },
  methods: {
    async getContent () {
      this.productType = this.$route.meta.type

      const cateCode = 'GOOGLE_ADS_' + this.productType + this.$countryCode
      const res = await GoogleAdsService.getContentList(cateCode)
      const contentList = res.data.list
      const keyVisual = contentList.find(c => c.attribute.type === 'KEY_VISUAL')
      const featuresBenefits = contentList.find(c => c.attribute.type === 'FEATURE_BENEFIT')
      const colours = contentList.find(c => c.attribute.type === 'COLOURS')
      const inspired = contentList.find(c => c.content.modelId === 'GOOGLE_ADS_INSPIRED')

      this.keyVisual = keyVisual.attribute.content
      this.featuresBenefits = featuresBenefits.attribute.content
      this.colours = colours.attribute.content
      this.inspired = inspired.attribute

      const _this = this
      this.$nextTick(function () {
        _this.slickSlider()
      })
    },
    slickSlider () {
      $('.slick-container').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        draggable: true,
        // centerPadding: '35px',
        prevArrow: $('.slide-wrapper .arrow-prev'),
        nextArrow: $('.slide-wrapper .arrow-next'),
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      })
    }
  }
}
</script>
