var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "environmental-investment-image-card" }, [
    _c("div", { staticClass: "card-area" }, [
      _c("div", { staticClass: "title-area" }, [
        _c("img", { staticClass: "icon", attrs: { src: _vm.icon } }),
        _c("pre", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "div",
        { staticClass: "text-area" },
        _vm._l(_vm.imageCardContentList, function (item) {
          return _c("SustainabilityTitleAndText", {
            key: item,
            attrs: {
              contentTitle: item.contentTitle,
              contentText: item.contentText,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }