var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "materiality-assessment-image-area" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "text-area" },
      [
        _vm.nationCode == "_US" || _vm.nationCode == "_GL"
          ? _c("OverviewText", {
              attrs: {
                type: "ft-20",
                text: "We identify key ESG issues through a dual-materiality assessment that considers the extent of ESG factors on corporate finance and the Renvironmental and social impact of corporate activities.\nWe then utilize the resultin the establishment of the strategic system and planning of this report. LX Hausys will continue to manage key ESG issues to enhance our ESG management practices.",
              },
            })
          : _vm.nationCode == "_JP"
          ? _c("OverviewText", {
              attrs: {
                type: "ft-20",
                text: "当社は、企業財務に対するESG要因の程度と企業活動の環境および社会への影響を考慮した二重の重要性評価を通じて、主要なESG問題を特定します。\n        その後、その結果を戦略システムの確立と本レポートの計画に活用します。LX Hausysは、ESG管理の実践を強化するために、引き続き主要なESG問題を管理していきます。",
              },
            })
          : _vm.nationCode == "_RU"
          ? _c("OverviewText", {
              attrs: {
                type: "ft-20",
                text: "Мы определяем ключевые вопросы ESG с помощью двойной оценки существенности, которая учитывает степень влияния \n        факторов ESG на корпоративные финансы, а также экологическое и социальное воздействие деятельности компании.\n        Полученные результаты мы используем при создании стратегической системы и планировании данного отчета. \n        LX Hausys продолжит управлять ключевыми вопросами ESG, чтобы улучшить наши методы управления.",
              },
            })
          : _vm.nationCode == "_CN"
          ? _c("OverviewText", {
              attrs: {
                type: "ft-20",
                text: "通过双重重要性评估来确定ESG关键问题，该评估考虑了ESG因素对企业财务的影响程度以及企业活动对环境和社会的影响。\n        我们根据结果建立本报告的战略体系和规划。LX Hausys将持续经营ESG关键问题，以加强我们的ESG管理实践。",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image-area" }, [
      _c("img", {
        attrs: {
          src: "images/aboutus/sustainability/esg_strategy_system/materiality_assessment_image.jpg",
          alt: "img",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }