<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content cntu-view reques">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Professionals</li>
          <li>HIMACS Partners Portal</li>
          <li>Request a Partner Account</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Sign Up) -->
      <div class="container cntu-box inner">
        <div class="w-con-02">
          <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" id="salesForm">
            <input type=hidden name="oid" v-model="oid">
            <input type=hidden name="retURL" v-model="retURL">
            <input type=hidden id="00N6900000LFMAl" name="00N6900000LFMAl" title="ProductCategory" value="HIMACS">
            <h1 class="tt-1 mb-64 tac">Request a Partner Account</h1>
            <div class="tab-ui tab-ui-dark">
              <div class="cntu">
                <div class="tbl-wrap">
                  <div class="tab-con tab01 on">
                    <div cass="tbl-01">
                      <div class="tbl-input">
                        <table>
                          <caption>REQUEST A PARTNER ACCOUNT</caption>
                          <colgroup>
                            <col style="width:23%" class="p-ui">
                            <col style="width:auto">
                          </colgroup>
                          <tbody>
                          <tr>
                            <th scope="row"><span class="must">Type</span></th>
                            <td class="cell-noti">
                              <div class="sel-box type02">
                                <button type="button" class="btn-sel">
                                  <span v-if="selectedType">{{selectedType}}</span>
                                  <span v-else>Select Type</span>
                                </button>
                                <div class="sel-list">
                                  <ul class="box">
                                    <li v-for="type in types"><button type="button" @click="selectType(type)">{{type.text}}</button></li>
                                  </ul>
                                </div>
                                <input type="hidden" id="00N1r00000KOg1c" name="00N1r00000KOg1c" title="Type" value="">
                              </div>
                              <p class="glSite ta-r" v-if="btnCheck && selectedType===''">Select Type</p>
                            </td>
                          </tr>
                          <tr v-show="selectedType === 'Quality Club'">
                            <th scope="row"><label class="must" for="00N6900000LFNzB">Quality Club<br>Number</label></th>
                            <td class="inp-btn-ab cell-noti">
                              <div class="btn-ab">
                                <input type="text" class="inp-text" id="00N6900000LFNzB" maxlength="80" name="00N6900000LFNzB" size="20" placeholder="Input Text" v-model="euPartner.qcNum"/>
                              </div>
                              <p class="glSite ta-r" v-if="btnCheck && selectedType === 'Quality Club' && euPartner.qcNum === ''">Enter Quality Club Number</p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"><label class="must" for="company" >Company</label></th>
                            <td class="cell-noti">
                              <input type="text" class="inp-text" id="company" name="company" maxlength="40" size="20" placeholder="Input Text" v-model="euPartner.companyNm"/>
                            <p class="glSite ta-r" v-if="btnCheck && euPartner.companyNm === ''">Enter Company</p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"><label class="must" for="last_name">Last Name</label></th>
                            <td class="cell-noti">
                              <input type="text" class="inp-text" id="last_name" maxlength="80" size="20" name="last_name" placeholder="Input Text" v-model="euPartner.lastNm"/>
                              <p class="glSite ta-r" v-if="btnCheck && euPartner.lastNm === ''">Enter Last Name</p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"><label for="first_name">First Name</label></th>
                            <td class="cell-noti">
                              <input type="text" class="inp-text" id="first_name" name="first_name" maxlength="40" size="20" placeholder="Input Text" v-model="euPartner.firstNm"/>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"><span class="must" >Country</span></th>
                            <td class="cell-noti">
                              <div class="sel-box type02">
                                <button type="button" class="btn-sel">
                                  <span v-if="selectedCountry">{{selectedCountry}}</span>
                                  <span v-else>Select Country</span>
                                </button>
                                <div class="sel-list">
                                  <ul class="box">
                                    <li v-for="country in countries"><button type="button" @click="selectCountry(country)">{{country.countryNm}}</button></li>
                                  </ul>
                                </div>
                              </div>
                              <input type="hidden" id="country_code" name="country_code" v-model="euPartner.country">
                              <p class="glSite ta-r" v-if="btnCheck && selectedCountry === ''">Select Country</p>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                        <!-- <button class="g-recaptcha"
                                data-sitekey="reCAPTCHA_site_key"
                                data-callback='onSubmit'
                                data-action='submit'>Submit</button> -->

                      </div>
                    </div>

                    <div class="tbl-02 mb-200">
                      <div class="tbl-input mb-bt-0">
                        <table>
                          <caption>order form</caption>
                          <colgroup>
                            <col style="width:23%" class="p-ui">
                            <col style="width:23%" class="m-ui">
                            <col style="width:auto">
                          </colgroup>
                          <tbody>
                          <tr>
                            <th scope="row"><label for="email" class="must">E-mail</label></th>
                            <td class="cell-noti">
                              <input type="text" class="inp-text" id="email" name="email" maxlength="80" size="20" placeholder="Input Text" v-model="euPartner.memId"/>
                              <p class="glSite ta-r" v-if="btnCheck && euPartner.memId === ''">Enter E-mail</p>
                            </td>
                          </tr>
                          <tr>
                            <th >
                            </th>
                            <td class="txtadd">
                              <p class="ctt ctt-t4">
                                A valid e-mail address. All e-mails from the system will be sent to
                                this address. The e-mail address is not made public and will only
                                be used if you wish to receive a new password or wish to receive
                                certain news or notifications by e-mail.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"><label class="must" for="inp07">Password</label></th>
                            <td class="cell-noti tooTip">
                              <input type="password" class="inp-text tooltip-inp" id="inp07" placeholder="Input Text" v-model="euPartner.passwd" v-tooltip.bottom-start="passwordTooltip()" v-on:click="tooltipOpened = true" v-click-outside="onClickOutside"/>
                              <p class="glSite ta-r" v-if="btnCheck && euPartner.passwd === ''">Enter Password</p>
                              <p class="glSite ta-r" v-if="btnCheck && euPartner.passwd !== '' && weakConst">Password Does Not Meet The Requirements</p>

                              <!-- 비밀번호 체크 툴팁 -->
                              <div class="toTip" v-show="tooltipOpened">
                                <dl class="stepList">
                                  <dt class="ctt ctt-t5">Your password must have:</dt>
                                  <dd class="ctt ctt-t5 must-step">
                                    <!-- 단계별 active 클래스 추가시 활성화 -->
                                    <span class="ctt ctt-t5 cha-10" v-bind:class="{ active: lengthConst.test(euPartner.passwd) }">10 or more characters</span>
                                    <span class="ctt ctt-t5 upper" v-bind:class="{ active: capLetterConst.test(euPartner.passwd) && smallLetterConst.test(euPartner.passwd) }">Upper &amp; lowercase letters</span>
                                    <span class="ctt ctt-t5 least" v-bind:class="{ active: numberConst.test(euPartner.passwd) }">At least one number</span>
                                  </dd>
                                  <dt class="ctt ctt-t5">
                                    <span class="ctt ctt-t5">Strength: </span>
                                    <!-- 단계 week moderate strong  -->
                                    <span class="ctt ctt-t5" v-if="weakConst">weak</span>
                                    <span class="ctt ctt-t5" v-if="moderateConst">moderate</span>
                                    <span class="ctt ctt-t5" v-if="strongConst">strong</span>
                                  </dt>
                                  <dd class="progress-area">
                                    <div class="progress-bar" v-if="weakConst">
                                      <!-- 첫단계 하나만 week 추가  -->
                                      <!-- 두번째 단계 두개만 normal 추가  -->
                                      <!-- 세번째 단계 모두 strong 추가  -->
                                      <div class="step-01 week"></div>
                                      <div class="step-01"></div>
                                      <div class="step-01"></div>
                                    </div>
                                    <div class="progress-bar" v-if="moderateConst">
                                      <div class="step-01 normal"></div>
                                      <div class="step-01 normal"></div>
                                      <div class="step-01"></div>
                                    </div>
                                    <div class="progress-bar" v-if="strongConst">
                                      <div class="step-01 strong"></div>
                                      <div class="step-01 strong"></div>
                                      <div class="step-01 strong"></div>
                                    </div>
                                  </dd>
                                  <dd class="ctt ctt-t5 pw-easy" >
                                    Avoid using a password that you use
                                    with other websites or that might be
                                    easy for some one else to guess.
                                  </dd>
                                  <dd class="ctt ctt-t5 pw-unacceptable" v-if="weakConst && letterNumberCharConst.test(euPartner.passwd)">
                                    Your password contains
                                    unacceptable characters.
                                  </dd>
                                  <dd class="ctt ctt-t5 pw-unacceptable" v-if="weakConst && repeatConst.test(euPartner.passwd)">
                                    Too many consecutive identical
                                    characters.
                                  </dd>
                                </dl>
                              </div>
                              <!-- 툴팁 끝  -->

                            </td>
                          </tr>

                          <tr>
                            <th scope="row"><label for="inp09" class="must">Confirm Password</label></th>
                            <td class="cell-noti">

                              <input type="password" class="inp-text" id="inp09" placeholder="Input Text" v-model="passwdConfirm"/>
                              <p class="glSite ta-r" v-if="btnCheck && euPartner.passwd !== passwdConfirm">The password you entered do not match</p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" class="txtT"><label for="inp09" class="must">Data Usage</label></th>
                            <td class="txtadd cell-noti">
                              <div class="more-chk">
                                <label class="inp-chk" for="00N1r00000KNGM0">
                                  <input type="checkbox" v-model="euPartner.dataUsage" :value="euPartner.dataUsage" id="00N1r00000KNGM0" name="00N1r00000KNGM0" true-value="1" false-value="0">
                                  <span class="tt ctt-t4">I hereby agree to the terms of use of my personal information in this form as detailed in the <a href="etc/privacy">Privacy Policy.</a></span>
                                </label>
                                <div class="noti-02" v-if="btnCheck && euPartner.dataUsage == 0">Please check the Privacy policy
                                </div>
                              </div>
                            </td>
                          </tr>
                          <input id="phone" type=hidden maxlength="40" name="phone" size="20" value="" />
                          <input id="00N1r00000J5YJM" type=hidden name="00N1r00000J5YJM" title="Language" v-model="euPartner.language"/> <!-- country code에 따른 선택 필요. -->
                          <input id="00N1r00000J5YJg" type=hidden name="00N1r00000J5YJg" title="Web Form Type" v-model="form_type" />
                          <input id="00N1r00000KMnBx" type=hidden maxlength="80" name="00N1r00000KMnBx" size="20" value="Registration form"/>
                          <input id="lead_source"  type=hidden name="lead_source" value="Website">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="account-recaptcha-box ta-c">
              <div class="robot">
                <g-recaptcha
                  :data-sitekey="key"
                  :data-validate="validate"
                  :data-callback="callback"
                  data-size="invisible"
                />
              </div>
  <!--            <button class="btn"-->
  <!--              data-sitekey="reCAPTCHA_site_key"-->
  <!--              data-callback='onSubmit'-->
  <!--              data-action='submit'><span>Submit</span></button>-->
  <!--            <g-recaptcha-->
  <!--              :data-sitekey="key"-->
  <!--              :data-validate="validate"-->
  <!--              :data-callback="callback"-->
  <!--              data-size="invisible"-->
  <!--            />-->

              <!-- <button type="button" class="btn">
                <span>Submit</span>
              </button> -->
            </div>
          </form>
          <!-- <div class="mypg-sign-help">
            <div class="ms-tit">
              <p>Do you need help?</p>
            </div>
            <div class="ms-link-email">
              <a href="javascript:void(0)">
                <p>EMAIL</p>
                <span>websupport@lxhausys.com</span>
              </a>
            </div>
            <div class="ms-link-faq">
              <a href="javascript:void(0)">
                <p>FAQ</p>
                <span>If you need help, please visit our FAQ</span>
              </a>
            </div>
          </div> -->
        </div>
      </div>
      <iframe id="ifrm_salesforce" name="ifrm_salesforce" width="0" height="0" frameborder="0" style="display: none"></iframe>
      <!-- //container (Sign Up) -->
    </div>
    <!-- //content -->

    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import ProductService from '@/server/product.service'
import EuService from '@/server/eu.service'
import InspirationService from '@/server/inspiration.service'
import UserService from '@/server/user.service'
import gRecaptcha from '@finpo/vue2-recaptcha-invisible'
import memberInfoService from '@/server/myPage.service'
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'requestPartnerAccount',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    gRecaptcha
  },
  data () {
    return {
      tooltipOpened: false,
      msg: 'asddasd',
      euPartner: {
        memGroup: '', // current type
        memId: '', // email
        fstGroupCd: '', // first chosen type
        passwd: '',
        firstNm: '',
        lastNm: '',
        companyNm: '',
        email: '',
        country: '',
        language: '',
        dataUsage: 0,
        confirmPasswd: '',
        qcNum: '' // quality number text
      },
      form_type: 'Registration Form',
      oid: '00D1r000002CYo2',
      retURL: '',
      website: 'Website',

      types: [],
      countries: [],
      selectedType: '',
      selectedCountry: '',
      passwdConfirm: '',

      capLetterConst: /(?=.*[A-Z])/,
      smallLetterConst: /(?=.*[a-z])/,
      numberConst: /(?=.*[0-9])/,
      specCharConst: /(?=.*[$@$!%*#?&])/,
      lengthConst: /^.{10,}$/,
      letterNumberCharConst: /(?=.*[^A-Za-z0-9$@$!%*#?&])/,
      repeatConst: /(.)\1{2,}/,

      btnCheck: false,

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,

      key: '6LfS1_0eAAAAAEs1Z8-OsrUoysKDQRw7ZtpMwayq'

    }
  },
  computed: {
    weakConst: function () {
      return (!this.lengthConst.test(this.euPartner.passwd) || !this.capLetterConst.test(this.euPartner.passwd) ||
      !this.smallLetterConst.test(this.euPartner.passwd) || !this.numberConst.test(this.euPartner.passwd) ||
      this.letterNumberCharConst.test(this.euPartner.passwd) || this.repeatConst.test(this.euPartner.passwd)) && this.euPartner.passwd !== ''
    },
    moderateConst: function () {
      return this.lengthConst.test(this.euPartner.passwd) && this.capLetterConst.test(this.euPartner.passwd) &&
      this.smallLetterConst.test(this.euPartner.passwd) && this.numberConst.test(this.euPartner.passwd) &&
      !this.specCharConst.test(this.euPartner.passwd) && !this.letterNumberCharConst.test(this.euPartner.passwd) &&
      !this.repeatConst.test(this.euPartner.passwd)
    },
    strongConst: function () {
      return this.lengthConst.test(this.euPartner.passwd) && this.capLetterConst.test(this.euPartner.passwd) &&
      this.smallLetterConst.test(this.euPartner.passwd) && this.numberConst.test(this.euPartner.passwd) &&
      this.specCharConst.test(this.euPartner.passwd) && !this.letterNumberCharConst.test(this.euPartner.passwd) &&
      !this.repeatConst.test(this.euPartner.passwd)
    }
  },
  async created () {
    this.euPartner.language = this.$countryCode.substring(1).toLowerCase()
    if (this.euPartner.language == 'uk') {
      this.euPartner.language = 'en'
    }
    const typesRes = await ProductService.getDictionaryList('MBR_GRP_EU')
    const countriesRes = await EuService.getCountryList()
    this.types = typesRes.data
    this.countries = countriesRes.data
    this.retURL = 'http://'
  },
  mounted () {
    // $(".toTip").hide();
    $('.cell-noti.tooTip .inp-text').on('click', function () {
      $('.toTip').show(100)
    })
    $('input.tooltip-inp').focus(function () {
      $('.toTip').show(100)
    })
    $('input.tooltip-inp').blur(function () {
      $('.toTip').hide()
    })
    $('html').click(function (e) {
      if (!$(e.target).hasClass('tooltip-inp')) {
        // alert('영역 밖입니다.');
        $('.toTip').hide()
      }
    })
  },
  methods: {
    onClickOutside (event) {
      this.tooltipOpened = false
    },
    validate () {
      this.btnCheck = true
      if (this.euPartner.memGroup === '') {
        return false
      }
      if (this.euPartner.qcNum === '' && this.euPartner.memGroup === '003') {
        return false
      }
      if (this.euPartner.companyNm === '') {
        return false
      }
      if (this.euPartner.lastNm === '') {
        return false
      }
      if (this.euPartner.country === '') {
        return false
      }
      if (this.euPartner.memId === '') {
        return false
      }
      if (!this.reg.test(this.euPartner.memId)) {
        alert('Please include "@" in your email address.\n')
        return false
      }
      if (this.euPartner.passwd === '') {
        return false
      }
      if (this.weakConst) {
        return false
      }
      if (this.euPartner.passwd !== this.passwdConfirm) {
        return false
      }
      if (this.euPartner.dataUsage == 0) {
        return false
      }
      return true
    },
    passwordTooltip () {
      return $('#toolTip1').html()
    },
    async callback (token) {
      const exist = await memberInfoService.existEU(this.euPartner.memId)
      if (exist.data === true) {
        alert('This email address is already in use.')
        return false
      }
      if (token) {
        this.btnCheck = true
        this.euPartner.fstGroupCd = this.euPartner.memGroup
        this.euPartner.email = this.euPartner.memId
        if (this.passwdConfirm !== this.euPartner.passwd) {
          // passwords are not matching
          return false
        }
        // console.log("111111", this.euPartner.dataUsage)
        await UserService.saveEU(this.euPartner)
        await UserService.sendNewRequestEmailEU(this.euPartner.email)
        await this.setSalesForce()
      } else {
        alert('please check you are not robot')
      }
    },
    selectType (type) {
      this.selectedType = type.text
      this.euPartner.memGroup = type.id.value
      this.euPartner.fstGroupCd = type.id.value

      if (this.selectedType !== 'Quality Club') {
        this.euPartner.qcNum = ''
      }
    },
    selectCountry (country) {
      this.selectedCountry = country.countryNm
      this.euPartner.country = country.countryCd
    },
    async setSalesForce () {
      $('#salesForm').attr('target', 'ifrm_salesforce')
      // submit 지연이 일어나기 때문에 iframe load event 후 페이지 이동.
      $('#ifrm_salesforce').on('load', function () {
        location.href = 'himacsPartnersPortal/requestCompleted'
      })
      $('#salesForm').submit()
    }
  }
}
</script>

<style scoped>
@media (max-width: 768px){
  .tbl-input th {
    width: 23% !important;
  }
}

</style>
