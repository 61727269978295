var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content" }, [
        _vm._m(0),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "w-con-02 inner" }, [
            _vm._m(1),
            _c("dl", { staticClass: "privacy" }, [
              _c("dt", { staticClass: "ctt ctt-t1" }, [
                _vm._v("Manage Your Personal Information"),
              ]),
              _c("dd", { staticClass: "ctt ctt-t4" }, [
                _vm._v(
                  " The California Consumer Privacy Act (CCPA) is the law that provides privacy rights to consumer. At LX Hausys, we extend CCPA privacy rights to all US residents. "
                ),
              ]),
              _c("dt", { staticClass: "ctt ctt-t1" }, [
                _vm._v("Your Privacy Rights"),
              ]),
              _vm._m(2),
              _c("dt", { staticClass: "ctt ctt-t1" }, [
                _vm._v("Do Not Sell My Personal Information"),
              ]),
              _c("dd", { staticClass: "donotsell-info" }, [
                _c("div", { staticClass: "col-02" }, [
                  _c("div", { staticClass: "tbl-01" }, [
                    _c("div", { staticClass: "tbl-input type03" }, [
                      _c("table", [
                        _vm._m(3),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Download Form"),
                            ]),
                            _c("td", { staticClass: "cell-noti" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-down-line",
                                  on: {
                                    click: function ($event) {
                                      return _vm.fileDown()
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("Download")])]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "tbl-02" }, [
                    _c("div", { staticClass: "tbl-input type03" }, [
                      _c("table", [
                        _vm._m(4),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Upload File"),
                            ]),
                            _c("td", { staticClass: "cell-noti" }, [
                              _c("span", { staticClass: "inp-file" }, [
                                _c("label", { attrs: { for: "file01" } }, [
                                  _vm._v("Upload"),
                                ]),
                                _c("input", {
                                  ref: "submitImage",
                                  attrs: {
                                    type: "file",
                                    id: "file01",
                                    multiple: "multiple",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fileUpload()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Attach document"),
                            ]),
                            _c("td", { staticClass: "cell-noti" }, [
                              _c(
                                "ul",
                                { staticClass: "list-upload-file" },
                                _vm._l(
                                  _vm.data.fileList,
                                  function (file, index) {
                                    return _c("li", [
                                      _c(
                                        "span",
                                        { attrs: { title: "filename" } },
                                        [_vm._v(_vm._s(file.name))]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fileDel(file.name)
                                            },
                                          },
                                        },
                                        [_vm._v("del")]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "submit-box ta-c" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "submit", id: "submitForm" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_c("span", [_vm._v("submit")])]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }),
        _c("li", [_vm._v("Do Not Sell My Personal Information")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sub-visual-section" }, [
      _c("h1", { staticClass: "tt-1 visual-title tac" }, [
        _vm._v("Do Not Sell My Personal Information"),
      ]),
      _c("h2", { staticClass: "sr-only" }, [
        _vm._v("LX Hausys Do Not Sell My Personal Information"),
      ]),
      _c("p", { staticClass: "ctt ctt-t2 visual-desc tac" }, [
        _vm._v(
          " Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services that you do not want them to track your online activities. Website does not currently support Do Not Track requests. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dd", [
      _c("ul", { staticClass: "dotList" }, [
        _c("li", { staticClass: "ctt ctt-t4" }, [
          _vm._v("ACCESS "),
          _c("ul", { staticClass: "lineList" }, [
            _c("li", { staticClass: "ctt ctt-t5" }, [
              _vm._v(
                "YOU CAN REQUEST ACCESS TO THE PERSONAL INFORMATION THAT LX HAUSYS HAS COLLETED ABOUT YOU"
              ),
            ]),
          ]),
        ]),
        _c("li", { staticClass: "ctt ctt-t4" }, [
          _vm._v("Deletion "),
          _c("ul", { staticClass: "lineList" }, [
            _c("li", { staticClass: "ctt ctt-t5" }, [
              _vm._v(
                "YOU CAN REQUEST LX Hausys to delete personal information abut you"
              ),
            ]),
          ]),
        ]),
        _c("li", { staticClass: "ctt ctt-t4" }, [
          _vm._v("Do Not Sell "),
          _c("ul", { staticClass: "lineList" }, [
            _c("li", { staticClass: "ctt ctt-t5" }, [
              _vm._v(
                "You can opt out of having your personal information shared with third parties"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "170px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "250px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }