<template>
  <div class="main-box ourprod-box">
    <div class="title_area">
      <h2 class="tit-main ourproduct_tit">{{ $t("main.text.our_prd") }}</h2>
      <div class="more">
        <a href="products/all_colors" class="view_more">
          <span>View more</span>
          <span class="icon"></span>
        </a>
      </div>
    </div>

    <div class="our_product_slider_wrap">
      <div class="our_product_slider_main"> <!-- 왼쪽 큰이미지 부분 -->
        <div class="slider-target">
          <div v-for="ourProduct in ourProductList" :key="ourProduct.attribute.MAIN_OUR_PRODUCTS_TITLE">
            <div class="imgbox">
              <img :src="$imagePath + ourProduct.attribute.MAIN_OUR_PRODUCTS_IMAGES" alt="prod img">
            </div>
          </div>
        </div>
      </div>

      <div class="our_product_slider_nav">  <!-- 오른쪽 작은 슬라이드 부분 -->
        <div class="slider-nav">
          <div v-for="ourProduct in ourProductList" :key="ourProduct.attribute.MAIN_OUR_PRODUCTS_TITLE">
            <div class="imgbox">
              <img :src="$imagePath + ourProduct.attribute.MAIN_OUR_PRODUCTS_IMAGES" alt="our product nav">
            </div>
            <div class="txtbox">
              <div class="logowrap">
                <img
                  v-if="!ourProduct.imageLoadError && !ourProduct.imageBlackLoadError"
                  :src="$imagePath + '/2024/images/main/logo/black_' + ourProduct.attribute.MAIN_OUR_PRODUCTS_TYPEText + '.svg'"
                  alt="prod logo"
                  @error="ourProduct.imageLoadError = true"
                  @load="ourProduct.imageLoadError = false"
                  class="logo_img_bk"
                >
                <img
                  v-if="!ourProduct.imageLoadError && !ourProduct.imageBlackLoadError"
                  :src="$imagePath + '/2024/images/main/logo/' + ourProduct.attribute.MAIN_OUR_PRODUCTS_TYPEText + '.svg'"
                  alt="prod logo"
                  @error="ourProduct.imageBlackLoadError = true"
                  @load="ourProduct.imageBlackLoadError = false"
                  class="logo_img_wh"
                >
                <span class="logo" v-if="ourProduct.imageLoadError || ourProduct.imageBlackLoadError">
                  {{ ourProduct.content.title }}
                </span> <!-- 로고 텍스트 있을 시 위의 이미지 로고 불필요 -->
                <span class="sub_tit">{{ ourProduct.attribute.MAIN_OUR_PRODUCTS_SUB_TITLE }}</span>
                <span class="txt_cont">
                  {{ ourProduct.attribute.MAIN_OUR_RODUCTS_DESCRIPTION }}
                </span>
              </div>
              <div class="btnwrap">
                <a @click="openLink(ourProduct)" class="btn-more">
                  <span>View more</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainService from '@/server/main.service'

export default {
  name: 'app',
  data () {
    return {
      ourProductId: '',
      ourProductList: [],
      countryCode: ''
    }
  },
  async mounted () {
    const _this = this
    // Retrieve the country code
    const nationCode = this.$countryCode
    this.countryCode = nationCode
    this.categoryCode = 'MAIN_OUR_PRODUCTS' + nationCode

    // Fetch the category code
    const ourProductRes = await mainService.getCategoryCode(this.categoryCode)
    this.ourProductId = ourProductRes.data.object.id

    // Fetch the product list
    const ourProductList = await mainService.getContentListSort(this.ourProductId, 1, 10, 'sort', 'asc')
    this.ourProductList = ourProductList.data.list

    // Initialize image load error flags and preload images
    const preloadPromises = this.ourProductList.map(ourProduct => {
      this.$set(ourProduct, 'imageLoadError', false)
      this.$set(ourProduct, 'imageBlackLoadError', false)
      return this.preloadImage(ourProduct)
    })

    // Wait for all images to preload before initializing sliders
    Promise.all(preloadPromises).then(() => {
      this.initializeSliders()
    })
    
    // 스크롤 감시중
    const io = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        const $target = e.target;
        if (e.isIntersecting) {
          _this.$nextTick(function () {
            $('.slider-target').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              asNavFor: '.slider-nav',
              autoplay: true,
              autoplaySpeed: 4000,
              fade: true
            })
            $('.slider-nav').slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              asNavFor: '.slider-target',
              focusOnSelect: true,
              variableWidth: true,
              dots: true,
              autoplay: true,
              autoplaySpeed: 4000,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]
            })
          })
        } else {
        }
      });
    });
    
    // 옵저버할 대상을 선택하여 관찰 시작
    const $items = document.querySelectorAll(".ourproduct_tit");
    $items.forEach((item) => {
      io.observe(item);
    });
    
  },
  methods: {
    openLink (windowOpenValue) {
      var windowOpen
      try {
        windowOpen = JSON.parse(windowOpenValue.attribute.MAIN_OUR_PRODUCTS_WINDOW)
      } catch (error) {
        // 파싱 실패나 값이 없을 경우 기존창으로 강제 셋팅
        windowOpen = { value: '002' } // 기본값 설정
      }

      var value = windowOpen.value

      if (!windowOpenValue.attribute.MAIN_OUR_PRODUCTS_URL) {
        return // Exit the function early if URL is empty
      }

      if (value === '001') {
        // 기존 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_OUR_PRODUCTS_URL, '_self') // '기존 창 URL'을 적절히 변경해주세요.
      } else if (value === '002') {
        // 새 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_OUR_PRODUCTS_URL, '_blank') // '새 창 URL'을 적절히 변경해주세요.
      }
    },
    // Preload images and set error flags if images fail to load
    preloadImage (ourProduct) {
      return new Promise((resolve) => {
        const img = new Image()
        const imgBlack = new Image()

        img.src = this.$imagePath + '/2024/images/main/logo/' + ourProduct.attribute.MAIN_OUR_PRODUCTS_TYPEText + '.svg'
        imgBlack.src = this.$imagePath + '/2024/images/main/logo/black_' + ourProduct.attribute.MAIN_OUR_PRODUCTS_TYPEText + '.svg'

        // Resolve the promise when the image is loaded successfully
        img.onload = () => resolve()
        imgBlack.onload = () => resolve()

        // Set error flag and resolve the promise if an error occurs while loading the image
        img.onerror = () => {
          this.$set(ourProduct, 'imageLoadError', true)
          resolve()
        }
        imgBlack.onerror = () => {
          this.$set(ourProduct, 'imageBlackLoadError', true)
          resolve()
        }
      })
    },
    // Initialize the sliders
    initializeSliders () {
      this.$nextTick(function () {
        
      })
    }
  }
}
</script>
