var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box new_events" }, [
    _vm._m(0),
    _c("div", { staticClass: "newevent_slider_wrap" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "center_slider" },
        _vm._l(_vm.newEventList, function (newEvent, index) {
          return _c(
            "div",
            {
              staticClass: "item",
              attrs: { value: newEvent.content.title },
              on: {
                click: function ($event) {
                  return _vm.handleImageClick(newEvent, index)
                },
              },
            },
            [
              _c("div", { staticClass: "img" }, [
                _c("img", {
                  staticClass: "new_events_img",
                  attrs: {
                    src: _vm.$imagePath + newEvent.attribute.MAIN_NEWS_IMAGE,
                    alt: "new event",
                  },
                }),
              ]),
              _c("div", { staticClass: "contents" }, [
                _c("div", { staticClass: "sort" }, [
                  _vm._v(_vm._s(newEvent.attribute.MAIN_NEWS_CATEGORYText)),
                ]),
                _c("div", { staticClass: "description" }, [
                  _c("p", { staticClass: "des1" }, [
                    _vm._v(_vm._s(newEvent.content.title)),
                  ]),
                  _c("p", { staticClass: "des2" }, [
                    _vm._v(_vm._s(newEvent.attribute.MAIN_NEWS_SUB_TITLE)),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit_area" }, [
      _c("h2", { staticClass: "tit-main" }, [_vm._v("WHAT’S NEW")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "custom_btn_area" }, [
      _c(
        "button",
        {
          staticClass: "btn_slick_prev new_prev",
          attrs: { "aria-label": "Previous", type: "button" },
        },
        [_vm._v("Previous")]
      ),
      _c(
        "button",
        {
          staticClass: "btn_slick_next new_next",
          attrs: { "aria-label": "Next", type: "button" },
        },
        [_vm._v("Next")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }