<template>
  <div class="overview-section-item-area" :style="{'margin-top': marginTop}">
    <div
      v-if="title"
      class="title-area"
    >
      <pre>{{ title }}</pre>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isUnderSectionItem: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },
  computed: {
    marginTop () {
      let marginTop = '54px'

      if (this.isUnderSectionItem) {
        marginTop = '64px'
      }
      return marginTop
    }
  }
}
</script>

<style scoped>
.overview-section-item-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.overview-section-item-area .title-area {
  margin-bottom: 54px;
}

.overview-section-item-area .title-area pre {
  color: #A09C9B;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 100% */
  align-self: stretch;
}
@media (max-width: 768px) {
  .overview-section-item-area .title-area pre {
    font-size: 1.25rem;
    line-height: 1.3541666667rem;
  }
}

</style>
