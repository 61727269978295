<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content cntu-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{$t("care_maintenance.text.home")}}</li>
          <li v-if="nationCode === '_US' || nationCode === '_GL'">{{$t("care_maintenance.text.contact")}}</li>
          <li>{{$t("care_maintenance.text.care_maintenance")}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- US container (Care and Maintenance) -->
      <div class="container cntu-box us-container-careandmaintenance" v-if="nationCode === '_US' || nationCode === '_GL'">
        <div class="w-con-02">
          <div class="sub-visual-section">
            <h1 class="tt-1 visual-title tac inner">{{$t("care_maintenance.text.care_maintenance")}} </h1>
            <!-- <h2 class="sr-only">LX Hausys Care and Maintenance</h2> -->
          </div>

          <div class="tab-ui tab-ui-dark">
            <ul class="btns03" v-if="nationCode === '_US' || nationCode === '_GL'">
              <li class="btn-tab"
                  v-for="(tab, index) in cateList"
                  :key="tab.code"
                  :id="tab.code"
                  :data-tab="'tab0' + (index + 1)"
                  @click="mainTab(tab.code)">
                <button type="button">{{ tab.name }}</button>
              </li>
            </ul>
          </div>

          <!-- s : inner  -->
          <div class="inner">
            <div class="tab-ui tab-ui-nav tab-ui-gray tab01 tab-ui-hastext on">
              <div class="sub-tab" v-if="categoryCode === 'CNM_HFLOR'+ nationCode">
                <ul>
                  <button type="button" class="arrow disabled tab-arr-prev">
                    <span class="sr-only">{{$t("care_maintenance.button.prev")}}</span>
                  </button>
                  <button type="button" class="arrow tab-arr-next">
                    <span class="sr-only">{{$t("care_maintenance.button.next")}}</span>
                  </button>
                </ul>
                <div class="tab-ui tab-ui-nav tab-ui-gray sub01 on">
                  <div class="ctt ctt-t2 tac">{{ summary }}</div>
                </div>
              </div>

              <div class="ctt ctt-t2 tac" v-if="categoryCode !== 'CNM_HFLOR'+nationCode">
                {{ summary }}
              </div>
              <!--              <div class="t-type-01">-->
              <!--                {{ summary }}-->
              <!--              </div>-->
            </div>
            <div class="down-box wa-box">
              <div class="tit-box">
                <p class="tt-2">{{$t("care_maintenance.text.Download")}}</p>
                <div class="more-box type02-04">
                  <button type="button" class="btn btn-line" @click="goDocu(categoryCode)"><span>{{$t("care_maintenance.text.more")}}</span></button>
                </div>
              </div>

              <div class="tbl-box type05 box-dl">
                <table>
                  <caption>{{$t("care_maintenance.text.download_form")}}</caption>
                  <colgroup class="p-ui">
                    <col style="width:auto">
                    <col style="width:100px">
                    <col style="width:100px">
                    <col style="width:140px">
                  </colgroup>
                  <tbody>
                  <tr v-for="file in fileList">
                    <td :href="$imagePath+file.filePath" class="dl-title" target="_blank">{{ getDesc(file) }}</td>
                    <td class="file-type">{{ (file.filePath).substring((file.filePath).lastIndexOf('.')+1,(file.filePath).length) }}</td>
                    <td>{{ transFileSize(file.fileSize) }}</td>
                    <td class="td-share">
                      <ShareComponent :data-chk="file" v-bind:childValue="file" :type="'care'"></ShareComponent>
                      <button type="button" class="btn-down" @click="downloadFile(file.filePath)">{{$t("care_maintenance.button.download")}}</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="wrapper-cms-cnm" v-if="editor != ''" v-html="editor"></div>
          </div>
          <!-- e : inner  -->
        </div>
      </div>
      <!-- //container (Care and Maintenance) -->

      <!-- EU container (Care and Maintenance) -->
      <div class="container cntu-box care-container eu-container-careandmaintenance" v-if="nationCode !== '_US' && nationCode !== '_GL'">
        <div class="w-con-02">
          <div class="sub-visual-section">
            <h1 class="tt-1 visual-title tac inner">{{$t("care_maintenance.text.care_maintenance")}}</h1>
          </div>

          <div class="tab-ui tab-ui-dark tab-care">
            <ul class="btns02">
              <li class="btn-tab" :id="'btn-tab0'+(idx+1)" :data-tab="'tab0'+(idx+1)" v-for="(care, idx) in careCateList">
                <button type="button" @click="getEU(care)">{{care.name}}</button>
              </li>
            </ul>

            <!-- hflor -->
            <div class="tab-con tab01 on inner" id="inner01">
              <div v-if="euContent.attribute !== undefined" v-html="euContent.attribute.desEditor"></div>
              <div class="down-box wa-box">
                <div class="tit-box">
                  <p class="tt-2">{{$t("care_maintenance.text.Download")}}</p>
                  <div class="more-box type02-04">
                    <button type="button" class="btn btn-line" @click="goDocu(categoryCode)"><span>{{$t("care_maintenance.text.more")}}</span></button>
                  </div>
                </div>

                <div class="tbl-box type05 box-dl hf-tbl">
                  <table>
                    <caption>download_form</caption>
                    <colgroup class="p-ui">
                      <col style="width:auto">
                      <col style="width:160px">
                      <col style="width:100px">
                      <col style="width:140px">
                    </colgroup>
                    <tbody>
                    <tr v-for="(file, index) in euDocFileList">
                      <td class="dl-title">{{ file.content.title }}</td>
                      <td class="td-select-lang">
                        <div class="sel-box type02">
                          <button type="button" class="btn-sel">
                            <span :id="'hflor_'+ index">English(UK)</span>
                          </button>
                          <div class="sel-list" style="display: none">
                            <ul class="box">
                              <li><button type="button" @click="downloadTab('English', 'hflor_'+index)">English</button></li>
                              <li><button type="button" @click="downloadTab('German', 'hflor_'+index)">German</button></li>
                              <li><button type="button" @click="downloadTab('France', 'hflor_'+index)">France</button></li>
                              <li><button type="button" @click="downloadTab('Italian', 'hflor_'+index)">Italian</button></li>
                              <li><button type="button" @click="downloadTab('Spanish', 'hflor_'+index)">Spanish</button></li>
                              <li><button type="button" @click="downloadTab('Dutch', 'hflor_'+index)">Dutch</button></li>
                              <li><button type="button" @click="downloadTab('English (UK)', 'hflor_'+index)">English (UK)</button></li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="ta-c file-type eu-file-type">PDF</td>
                      <td class="td-share">
                        <ShareComponent :data-chk="file" v-bind:childValue="file" :type="'document'"></ShareComponent>
                        <button type="button" class="btn-down" @click="downloadDocument(file, 'hflor_'+index)">button.download</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- // hflor -->

            <!-- himacs -->
            <div class="tab-con tab02 inner" id="inner02">
              <div v-if="euContent.attribute !== undefined" v-html="euContent.attribute.desEditor"></div>
              <div class="down-box wa-box">
                <div class="tit-box">
                  <p class="tt-2">{{$t("care_maintenance.text.Download")}}</p>
                  <div class="more-box type02-04">
                    <button type="button" class="btn btn-line" @click="goDocu(categoryCode)"><span>{{$t("care_maintenance.text.more")}}</span></button>
                  </div>
                </div>
                <div class="tbl-box type05 box-dl">
                  <table>
                    <caption>download_form</caption>
                    <colgroup class="p-ui">
                      <col style="width:auto">
                      <col style="width:160px">
                      <col style="width:100px">
                      <col style="width:140px">
                    </colgroup>
                    <tbody>
                    <tr v-for="(file, index) in euDocFileList">
                      <td class="dl-title">{{ file.content.title }}</td>
                      <td class="td-select-lang">
                        <div class="sel-box type02">
                          <button type="button" class="btn-sel">
                            <span :id="'himacs_'+ index">English(UK)</span>
                          </button>
                          <div class="sel-list" style="display: none">
                            <ul class="box">
                              <li><button type="button" @click="downloadTab('English', 'himacs_'+index)">English</button></li>
                              <li><button type="button" @click="downloadTab('German', 'himacs_'+index)">German</button></li>
                              <li><button type="button" @click="downloadTab('France', 'himacs_'+index)">France</button></li>
                              <li><button type="button" @click="downloadTab('Italian', 'himacs_'+index)">Italian</button></li>
                              <li><button type="button" @click="downloadTab('Spanish', 'himacs_'+index)">Spanish</button></li>
                              <li><button type="button" @click="downloadTab('Dutch', 'himacs_'+index)">Dutch</button></li>
                              <li><button type="button" @click="downloadTab('English (UK)', 'himacs_'+index)">English (UK)</button></li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="ta-c file-type eu-file-type">PDF</td>
                      <td class="td-share">
                        <ShareComponent :data-chk="file" v-bind:childValue="file" :type="'document'"></ShareComponent>
                        <button type="button" class="btn-down" @click="downloadDocument(file, 'himacs_'+index)">button.download</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="care-dt-box bg-d7" v-if="euContent.attribute.btmEditor1 !== undefined" v-html="euContent.attribute.btmEditor1"></div>
              <div class="care-dt-box bg-d7" v-if="euContent.attribute.btmEditor2 !== undefined" v-html="euContent.attribute.btmEditor2"></div>
              <div class="care-dt-box bg-d7" v-if="euContent.attribute.btmEditor3 !== undefined" v-html="euContent.attribute.btmEditor3"></div>
            </div>
          </div>

        </div>
      </div>
      <!-- //container EU (Care and Maintenance) -->

    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import { getCategoryCode, getCategoryList } from '@/server/category'
import ProductService from '@/server/product.service'
import global from '@/common/global'
import { element } from 'screenfull'
import GnbComponent from '@/components/Layout/GNB'
import ShareComponent from '@/components/Layout/Share'
import _ from '@/common/helper/lodash'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'care',
  props: ['childValue', 'type'],
  components: {
    QuickMenuComponent,
    GnbComponent,
    HeaderComponent,
    FooterComponent,
    ShareComponent
  },
  data () {
    return {
      categoryCode: '',
      categoryId: '',
      contentId: '',
      nationCode: '',
      cateList: [],
      hFlorList: [],
      viateraList: [],
      himacsList: [],
      teracantoList: [],
      hFlor: '',
      viatera: '',
      himacs: '',
      teracanto: '',
      summary: '',
      editor: '',
      fileCate: '',
      fileList: [],
      fileSize: '',
      countryCode: '',
      countryCodeSub: '',

      /* EU,UK 추가 */
      euContent: { attribute: [] },
      careCateList: [],
      euDocFileList: [],
      seoCheck: false,
      seoName: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this

    if (location.pathname.indexOf('support/careAndMaintenance/CNM') === -1) {
      _this.seoName = _this.$route.params.categoryCode.toUpperCase()
      _this.seoCheck = true
    } else {
      _this.seoCheck = false
    }

    _this.nationCode = _this.$countryCode /* 국가 코드 */
    if (_this.seoCheck) {
      _this.categoryCode = 'CNM_' + _this.$route.params.categoryCode.toUpperCase() + _this.nationCode
    } else {
      _this.categoryCode = _this.$route.params.categoryCode + _this.nationCode /* 카테고리 코드 */
    }
    const categoryRes = await getCategoryCode(_this.categoryCode) /* 카테고리 id 탐색 */
    _this.categoryId = categoryRes.data.object.id

    /** ---------------------------------- US_GL Start----------------------------------------**/
    if (_this.nationCode === '_US' || _this.nationCode === '_GL') {
      const categoryList = await getCategoryList(categoryRes.data.object.parentId) /* 카테고리 리스트 탐색 */
      _this.hFlor = categoryList.data.page.list[0] /* 카테고리 */
      _this.viatera = categoryList.data.page.list[1] /* 카테고리 */
      _this.himacs = categoryList.data.page.list[2]
      _this.teracanto = categoryList.data.page.list[3]

      // 카테고리 리스트를 설정
      _this.cateList = [_this.hFlor, _this.viatera, _this.himacs, _this.teracanto]

      const list = await ProductService.getContentListChildCate(categoryRes.data.object.parentId) /* 서브카테고리 검색 */

      _this.hFlorList = _.filter(list.data.list, function (o) {
        return o.content.categoryId == _this.hFlor.id
      })

      _this.viateraList = _.filter(list.data.list, function (o) {
        return o.content.categoryId == _this.viatera.id
      })

      _this.himacsList = _.filter(list.data.list, function (o) {
        return o.content.categoryId == _this.himacs.id
      })

      _this.teracantoList = _.filter(list.data.list, function (o) {
        return o.content.categoryId == _this.teracanto.id
      })

      // const subCateList = await ProductService.getContentList(_this.hFlor.id) /* 서브카테고리 검색 */
      // _this.hFlorList = subCateList.data.list
      //
      // const viateraList = await ProductService.getContentList(_this.viatera.id) /* 서브카테고리 검색 */
      // _this.viateraList = viateraList.data.list
      //
      // const himacsList = await ProductService.getContentList(_this.himacs.id) /* 서브카테고리 검색 */
      // _this.himacsList = himacsList.data.list

      if (_this.categoryCode === 'CNM_HFLOR' + _this.nationCode) {
        _this.contentId = _this.hFlorList[0].content.id
      } else if (_this.categoryCode === 'CNM_VIATERA' + _this.nationCode) {
        _this.contentId = _this.viateraList[0].content.id
      } else if (_this.categoryCode === 'CNM_HIMACS' + _this.nationCode) {
        _this.contentId = _this.himacsList[0].content.id
      } else if (_this.categoryCode === 'CNM_TERACANTO' + _this.nationCode) {
        _this.contentId = _this.teracantoList[0].content.id
      }

      await _this.getContent(_this.categoryCode)
    }

    // activeArrowTab();

    /** ---------------------------------- EU_UK Start ----------------------------------------**/
    if (_this.nationCode !== '_US' && _this.nationCode !== '_GL') {
      const careCateList = await ProductService.getCateChildren('CNM' + _this.nationCode)
      _this.careCateList = careCateList.data
      // const euCategoryList = await ProductService.getContentList(_this.categoryId);
      // _this.euContent = euCategoryList.data.list[0];                                              /* 카테고리 */
      // const euDocFile = await ProductService.getContent(euCategoryList.data.list[0].content.id);  /* 다국어 파일 가져오기 */
      // _this.euDocFileList = euDocFile.data.related.DOCUMENTS_UK;
      if (_this.categoryCode === 'CNM_HFLOR' + _this.nationCode) {
        this.getEU(_this.careCateList[0])
      } else {
        this.getEU(_this.careCateList[1])
      }
    }

    _this.$nextTick(function () {
      $('#' + _this.categoryCode).addClass('on')
      if (_this.nationCode === '_US' || _this.nationCode === '_GL') {
        $('#sub01').addClass('on')
      }
      if (_this.nationCode !== '_US' && _this.nationCode !== '_GL') {
        if (_this.categoryCode === 'CNM_HFLOR' + _this.nationCode) {
          $('#btn-tab01').click()
        } else {
          $('#btn-tab02').click()
        }
      }
    })
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async mainTab (code) {
      const _this = this
      _this.categoryCode = code
      if (code === 'CNM_HFLOR' + _this.nationCode) {
        _this.contentId = _this.hFlorList[0].content.id
      } else if (code === 'CNM_VIATERA' + _this.nationCode) {
        _this.contentId = _this.viateraList[0].content.id
      } else if (code === 'CNM_HIMACS' + _this.nationCode) {
        _this.contentId = _this.himacsList[0].content.id
      } else if (code === 'CNM_TERACANTO' + _this.nationCode) {
        _this.contentId = _this.teracantoList[0].content.id
      }

      _this.$nextTick(function () {
        $('#sub01').addClass('on')
      })
      await _this.getContent(code)
    },
    async subTab (id, name) { // [21-11-22] 1차때는 hflor로 통합해서 사용하기로함. 향후를 위해 주석 처리.
      const _this = this
      _this.contentId = id
      await _this.getContent(name)
    },
    async getContent (code) {
      const _this = this

      if (_this.categoryCode === 'CNM_HFLOR' + _this.nationCode) {
        _this.fileList = []
        _this.hFlorList.forEach(async (hflor) => {
          const contentRes = await ProductService.getContent(hflor.content.id)
          _this.summary = contentRes.data.attribute.summary
          _this.editor = contentRes.data.attribute.careEditor
          const contentFileRes = await ProductService.getContentFileList(hflor.content.id)
          _this.fileList.push(...contentFileRes.data.page.list)
        })
      } else if (_this.contentId !== null) {
        const contentRes = await ProductService.getContent(_this.contentId)
        _this.summary = contentRes.data.attribute.summary
        _this.editor = contentRes.data.attribute.careEditor
        const contentFileRes = await ProductService.getContentFileList(_this.contentId)
        _this.fileList = contentFileRes.data.page.list
      }

      _this.$nextTick(function () {
        // $('.sns-wrp .sns').each(function (idx, ele) {
        //   var $this = $(this)
        //   $(ele).prev().off().on('click', function () {
        //     $this.slideToggle(300)
        //     $this.closest('tr').siblings().children().find('.sns').hide()
        //   })
        // })
      })
    },
    downloadFile (file) {
      if (confirm(this.$t('care_maintenance.alert.confirm'))) {
        const url = this.$imagePath + file

        function toDataURL (url, callback) {
          var xhr = new XMLHttpRequest()
          xhr.onload = function () {
            var reader = new FileReader()
            reader.onloadend = function () {
              callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
          }
          xhr.open('GET', url)
          xhr.responseType = 'blob'
          xhr.send()
        }

        toDataURL(url, function (dataUrl) {
          const element = document.createElement('a')
          element.setAttribute('href', dataUrl)
          element.setAttribute('download', file)
          document.body.appendChild(element)
          element.click()
        })
        window.open(url)
      }
    },
    transFileSize (file) {
      var size = ''
      if (file < 1024) size = file + ' B'
      else if (file < 1024 * 1024) size = parseInt(file / 1024) + ' KB'
      else if (file < 1024 * 1024 * 1024) size = parseInt(file / (1024 * 1024)) + ' M'
      else size = parseInt(file / (1024 * 1024 * 1024)) + ' G'
      return size
    },
    getDesc (file) {
      var desc
      if (_.isEmpty(file.description)) {
        desc = _.split(_.last(_.split(file.filePath, '/')), '.')[0]
      } else {
        desc = file.description
      }
      return desc
    },
    goDocu (id) {
      if (this.nationCode === '_US' || this.nationCode === '_GL') {
        if (id.includes('HFLOR')) {
          location.href = 'pros/documentation/hflor'
        } else if (id.includes('VIATERA')) {
          location.href = 'pros/documentation/viatera'
        } else if (id.includes('HIMACS')) {
          location.href = 'pros/documentation/himacs'
        } else if (id.includes('TERACANTO')) {
          location.href = 'pros/documentation/teracanto'
        }
      } else {
        if (id.includes('HFLOR')) {
          location.href = 'pros/documentation/hflor/warranty'
        } else {
          location.href = 'pros/documentation/himacs/warranty'
        }
      }
    },
    async getEU (item) {
      const euCategoryList = await ProductService.getContentList(item.id)
      this.euContent = euCategoryList.data.list[0] /* 카테고리 */
      const euDocFile = await ProductService.getContent(euCategoryList.data.list[0].content.id) /* 다국어 파일 가져오기 */
      this.euDocFileList = euDocFile.data.related.DOCUMENTS_UK
      this.$nextTick(function () {
        // $('.sns-wrp .sns').each(function (idx, ele) {
        //   var $this = $(this)
        //   $(ele).prev().off().on('click', function () {
        //     $this.slideToggle(300)
        //     $this.closest('tr').siblings().children().find('.sns').hide()
        //   })
        // })
      })
    },
    downloadTab: function (lang, div) {
      $('#' + div).text(lang)
    },
    downloadDocument: function (file, div) {
      const text = $('#' + div).text()
      let fileName = ''

      if (text == 'English') { // EN
        fileName = file.attribute.doc_file
      }
      if (text == 'German') { // DE
        fileName = file.attribute.doc_file_de
      }
      if (text == 'France') { // FR
        fileName = file.attribute.doc_file_fr
      }
      if (text == 'Italian') { // IT
        fileName = file.attribute.doc_file_it
      }
      if (text == 'Spanish') { // ES
        fileName = file.attribute.doc_file_es
      }
      if (text == 'Dutch') { // NL
        fileName = file.attribute.doc_file_nl
      }
      if (text == 'English (UK)') { // UK
        fileName = file.attribute.doc_file_uk
      }

      if (fileName != null && fileName != '' && fileName != undefined) {
        const url = this.$imagePath + fileName

        try {
          function toDataURL (url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
              var reader = new FileReader()
              reader.onloadend = function () {
                callback(reader.result)
              }
              reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
          }

          toDataURL(url, function (dataUrl) {
            const element = document.createElement('a')
            element.setAttribute('href', dataUrl)
            element.setAttribute('download', file.content.title)
            document.body.appendChild(element)
            element.click()
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        return false
      }
    }

  }
}
</script>
