<template>
  <div class="softwhere-point-image" :style="flexDirection">
    <img class="image" :src="image">
    <div class="text-area">
      <img class="point" :style="pointPosition" :src="point">
      <pre class="title" :style="textAlign">{{ title }}</pre>
      <pre class="text" :style="textAlign">{{ text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String
    },
    point: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    direction: {
      type: String,
      default: 'left'
    }
  },
  computed: {
    flexDirection () {
      if (this.isMobile()) {
        return 'flex-direction: column;'
      }
      return this.direction === 'right' ? 'flex-direction: row-reverse;' : ''
    },
    textAlign () {
      if (this.isMobile()) {
        return 'text-align: center;'
      }
      return this.direction === 'right' ? '' : 'text-align: right;'
    },
    pointPosition () {
      return this.direction === 'right' ? 'left: 161px; transform: scaleX(-1);' : 'right: 161px;'
    }
  },
  methods: {
    isMobile () {
      var screenWidth = window.innerWidth
      return screenWidth <= 768
    }
  }
}
</script>

<style scoped>
.softwhere-point-image {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .softwhere-point-image {
    flex-direction: column;
    align-items: center;
  }
}

.softwhere-point-image .image {
  width: fit-content;
}

@media (max-width: 768px) {
  .softwhere-point-image .image {
    width: 70%;
    object-fit: contain;
  }
}

.softwhere-point-image .text-area {
  margin-top: 80px;
  position: relative;
}

@media (max-width: 768px) {
  .softwhere-point-image .text-area {
    margin-top: 5%;
  }
}

.softwhere-point-image .text-area .point {
  width: fit-content;
  position: absolute;
  top: 10px;
}

@media (max-width: 768px) {
  .softwhere-point-image .text-area .point {
    display: none;
  }
}

.softwhere-point-image .text-area .title {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
}

.softwhere-point-image .text-area .text {
  margin-top: 30px;
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

@media (max-width: 768px) {
  .softwhere-point-image .text-area .title {
    font-size: 1.0833333333rem;
    white-space: pre-wrap;
    line-height: 1.0833333333rem;
  }
  .softwhere-point-image .text-area .text {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
    white-space: pre-wrap;
    margin-top: 10%;
  }
}
</style>
