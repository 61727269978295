<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>INSTALLATION</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
            <span class="checkbox_more no_line">
              <li v-for="install in installList" :data-chk="install.id.value">
                <input type="checkbox" :id="'install_'+install.id.value" name="Installation" v-model="selected" :value="install.id.value" />
                <label :for="'install_'+`${install.id.value}`">
                <span></span>
                {{ install.text }}
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'InstallFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'installList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-install-filter', this.removeInstall)
    EventBus.$on('update-install-filters', this.updateInstalls)

    if (this.$store.state.filterState && this.$store.state.filterState.install) {
      this.selected = this.$store.state.filterState.install
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$off('remove-install-filter', this.removeColor)
    EventBus.$off('update-install-filters', this.updateColors)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeInstall (installValue) {
      this.selected = this.selected.filter(value => value !== installValue)
    },
    updateInstalls (installs) {
      this.selected = installs
    }
  }
}
</script>
