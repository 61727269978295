var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "key-social-contribution-initiatives-image-card" },
    [
      _c(
        "div",
        { staticClass: "image-area" },
        _vm._l(_vm.images, function (item) {
          return _c("img", { key: item, attrs: { src: item } })
        }),
        0
      ),
      _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }