<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content order-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Header</li>
          <li>Order Sample</li>
          <li>Confirm</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Order Confirmation) -->
      <div class="container order-inp-box">
        <div class="w-con-02">
          <div class="sub-visual-section">
            <h1 class="visual-title tt-1 tac">Order Confirmation</h1>
            <p class="ctt ctt-t2 visual-desc tac">
              <span class="pc-br">Your order has been placed.</span>
              <span class="pc-br">Thank you for choosing LX Hausys.</span>
            </p>
          </div>
          <div class="hc-os-dvcfm">
            <div class="tbl-input type02" v-bind:class="{uktbl: $countryCode === '_UK'}">
              <table>
                <caption>Order Confirmation form</caption>
                <colgroup>
                  <col style="width:250px" class="p-ui">
                  <col style="width:auto" class="p-ui">
                  <col style="width:30%" class="m-ui">
                  <col style="width:70%" class="m-ui">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">Order Number</th>
                    <td>{{form.orderId}}</td>
                  </tr>
                  <tr v-if="$countryCode === '_US'">
                    <th scope="row">Subtotal</th>
                    <td>$ {{form.subtotal}}</td>
                  </tr>
                  <tr v-if="$countryCode === '_US'">
                    <th scope="row">Shipping & Handling</th>
                    <td>FedEx Ground</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="tbl-input type02 tbl-total" v-if="$countryCode === '_US'">
              <table>
                <caption>order form (Product Grand Total)</caption>
                <colgroup>
                  <col style="width:250px" class="p-ui">
                  <col style="width:auto" class="m-ui">
                  <col style="width:auto">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">Grand Total</th>
                    <td>
                      <button type="button" class="btn-pp">
                        <span>PayPal</span>
                      </button>
                      <span>$ {{form.subtotal}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="more-box ta-c">
              <button type="button" class="btn" @click="gotoUrl()"><span>Continue Shopping</span></button>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (Order Confirmation) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import sampleOrderService from '@/server/sampleOrder.service'
import usService from '@/server/us.service'
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import _ from '@/common/helper/lodash.js'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'confirm',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      searchValue: {
        countries: [],
        states: {
          US: [],
          CA: [],
          99: []
        },
        stateCd: ''
      },
      order: {},
      form: {
        orderId: '',
        subtotal: 0,
        credit: 0
      }
    }
  },
  async created () {
    this.searchValue.countries = [
      { countryCd: 'US', countryNm: 'USA' },
      { countryCd: 'CA', countryNm: 'CANADA' }
    ]
    const promiseArr = [
      usService.getStateList('US'),
      usService.getStateList('CA'),
      usService.getStateList('99')
    ]
    const stateRes = await Promise.all(promiseArr)
    this.searchValue.states.US = stateRes[0].data
    this.searchValue.states.CA = stateRes[1].data
    this.searchValue.states['99'] = stateRes[2].data
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const _this = this

      const resOrder = await sampleOrderService.getOrder(this.$route.query.orderId)
      _this.order = resOrder.data
      // GTM Purchase init
      digitalData.orderComplete = {
        ecom_currency_code: 'USD',
        actionField: {
          ecom_tran_id: this.$route.query.orderId,
          ecom_tran_revenue: _this.order.totAmt,
          ecom_tran_shipping: _this.order.shipAmt,
          ecom_order_country: this.getCountryNm(_this.order.country),
          ecom_order_state: this.getStateNm(_this.order.stateCd, _this.order.country),
          ecom_order_city: _this.order.city,
          ecom_order_zipcode: _this.order.zipCd
        },
        products: []
      }
      // 총 가격 산출
      let tot = 0
      _.forEach(_this.order.itemList, (v) => {
        const qty = v.qty
        const price = v.price
        tot += qty * price
        // GTM purchase push
        digitalData.orderComplete.products.push(_this.makeDataLayerForm(v))
      })
      // GTM Purchase dataLayer
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({ event: 'ecPurchase' })
      }
      _this.form.orderId = _this.$route.query.orderId
      _this.form.subtotal = tot.toFixed(2)
      _this.$nextTick(function () {
        _this.confirmOrder()
      })
    },
    async confirmOrder () {
      const postData = {}
      postData.orderId = this.$route.query.orderId
      postData.payerId = this.$route.query.PayerID
      const res = await sampleOrderService.confirmOrder(postData)
      console.log('order confirm result : ' + res.data.result)
    },
    gotoUrl () {
      location.href = 'index'
    },
    getStateNm (stateCd, countryCd) {
      const found = _.find(this.searchValue.states[countryCd], (v) => {
        return v.id.countryCd === countryCd && v.id.stateCd === stateCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.stateNm
    },
    getCountryNm (countryCd) {
      const found = _.find(this.searchValue.countries, (v) => {
        return v.countryCd === countryCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.countryNm
    },
    makeDataLayerForm (v) {
      let brand = ''
      let category = ''
      let size = ''
      let type = ''
      let color = ''
      let collection = ''
      let cateCode = ''

      if (_.includes(v.content.content.modelId, 'HFLOR')) {
        // HFLOR
        brand = v.content.parents[2].name
        category = v.content.parents[1].name
        collection = v.content.parents[0].name
        cateCode = v.content.parents[1].code
        if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
          const prdType = v.content.attribute.prdTypeText
          type = _.isArray(prdType) ? prdType.join('|') : prdType
        }
      } else {
        // Viatera, Himacs
        brand = v.content.parents[1].name
        category = ''
        collection = v.content.parents[0].name
        color = v.content.attribute.hueText // himacs, viatera only
      }
      size = v.size
      const info = {
        ecom_prd_id: v.content.attribute.code == '' ? v.content.content.id : v.content.attribute.code,
        ecom_prd_name: v.content.content.title,
        ecom_prd_brand: brand,
        ecom_prd_category: category,
        ecom_prd_variant: size,
        ecom_prd_type: type,
        ecom_prd_collection: collection,
        ecom_prd_color: color,
        ecom_prd_price: v.price,
        ecom_prd_qty: v.qty
      }
      return info
    }
  }
}
</script>
