var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expanded-waste-recycling-table-area" }, [
    _c("pre", { staticClass: "table-title" }, [
      _vm._v(_vm._s(_vm.tableAreaData.tableTitle)),
    ]),
    _c(
      "div",
      { staticClass: "scroll-box" },
      [
        _c("ExpandedWasteRecyclingTable", {
          attrs: { tableData: _vm.tableData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }