import request from './request'
const urlPrefix = ''

const categoryService = {
  getCategoryList (parentId) {
    return request({
      url: `${urlPrefix}/directive/categoryList?parentId=${parentId}`,
      method: 'get'
    })
  },
  getCategoryId (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}`,
      method: 'get'
    })
  },
  getCategoryCode (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: 'get'
    })
  },
  getCategoryBySeo (seoName, code, parentCode) {
    return request({
      url: `${urlPrefix}/category/seo/${seoName}/${code}/${parentCode}`,
      method: 'get'
    })
  },
  getDocSeo (seoName, code, parentCode) {
    return request({
      url: `${urlPrefix}/category/docseo/${seoName}/${code}/${parentCode}`,
      method: 'get'
    })
  },
  getSegSeo (seoName, seoName2, code, parentCode) {
    return request({
      url: `${urlPrefix}/category/segseo/${seoName}/${seoName2}/${code}/${parentCode}`,
      method: 'get'
    })
  },
  getCategoryAndCollection (categoryId) {
    return request({
      url: `${urlPrefix}/category/product/${categoryId}`,
      method: 'get'
    })
  },
  getCategoryCodeList (code) {
    return request({
      url: `${urlPrefix}/category/codeList/${code.join(',')}`,
      method: 'get'
    })
  },
  getCategoryListAttr (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}?containsAttribute=true`,
      method: 'get'
    })
  },
  getCategoryAttributeList (id) {
    return request({
      url: `${urlPrefix}/category/list`,
      method: 'post',
      data: {
        parentId: id
      }
    })
  }
}

export default categoryService
