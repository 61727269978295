<template>
  <div class="key-social-contribution-initiatives-table">
    <div class="header-area">
      <SocialResponsibilityTableHeader
        v-for="(item, index) in headers"
        :key="index"
        :text="item.text"
        :background="item.background"
      />
    </div>
    <div class="body-area">
      <div class="body"
        v-for="(item, index) in bodies"
        :key="index"
      >
        <SocialResponsibilityTableBody
        :category="item.category"
        :bodyCells="item.bodyCells"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SocialResponsibilityTableBody from './SocialResponsibilityTableBody.vue'
import SocialResponsibilityTableHeader from './SocialResponsibilityTableHeader.vue'
export default {
  components: {
    SocialResponsibilityTableHeader,
    SocialResponsibilityTableBody
  },
  props: {
    headers: {
      type: Array
    },
    bodies: {
      type: Array
    }
  }

}
</script>

<style>
.key-social-contribution-initiatives-table .header-area {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 231px 340px 868px;
}

.key-social-contribution-initiatives-table .body-area .body {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 231px 340px 868px;
}

</style>
