<template>
    <thead class="financial-statement-table-thead">
        <tr class="">
            <th v-for="item in categoryList" :key="item">
                <div class="financial-statement-table-category">{{ item }}</div>
            </th>
        </tr>
    </thead>
</template>

<script>

export default {
  props: {
    categoryList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.financial-statement-table-thead {
    border-top: 2px solid #000;
    border-bottom: 1px solid #000;
}

.financial-statement-table-category {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
</style>
