<template>
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/social_responsibility/top_image.png"
    title="Social Responsibility"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
  <template #content>
    <SocialContributionStrategySystem />
    <SocialContributionActivities />
    <KeySocialContributionInitiatives />
  </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/social_responsibility/top_image.png"
    title="社会的責任"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <SocialContributionStrategySystem />
      <SocialContributionActivities />
      <KeySocialContributionInitiatives />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/social_responsibility/top_image.png"
    title="Социальная ответственность"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <SocialContributionStrategySystem />
      <SocialContributionActivities />
      <KeySocialContributionInitiatives />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/social_responsibility/top_image.png"
    title="社会责任"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <SocialContributionStrategySystem />
      <SocialContributionActivities />
      <KeySocialContributionInitiatives />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import KeySocialContributionInitiatives from './components/KeySocialContributionInitiatives.vue'
import SocialContributionActivities from './components/SocialContributionActivities.vue'
import SocialContributionStrategySystem from './components/SocialContributionStrategySystem.vue'

export default {
  components: {
    OverviewLayout,
    SocialContributionStrategySystem,
    SocialContributionActivities,
    KeySocialContributionInitiatives
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style>

</style>
