<template>
  <div class="text-card-box">
    <OverviewText
      type="ft-18"
      :text="text"
    />
  </div>
</template>

<script>
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewText
  },
  props: {
    text: {
      type: String
    },
    padding: {
      type: String,
      default: '20px 15px'
    }
  }
}
</script>

<style scoped>
.text-card-box {
  display: grid;
  place-items: center;
  border-radius: 10px;
  border: 1px solid #DCDCDC;
  min-height: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
