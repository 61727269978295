<template>
  <div class="eco-friendly-products-contents">
    <div class="product-picture-area">
      <img class="product-picture" :src="picture">
    </div>
    <div class="text-area">
      <img class="tab-icon" :src="icon">
      <pre class="text">{{ text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    picture: {
      type: String
    },
    icon: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style scoped>
.eco-friendly-products-contents .product-picture-area .product-picture {
  width: 1440px;
  height: 500px;
}

@media (max-width: 768px) {
  .eco-friendly-products-contents .product-picture-area .product-picture {
    width: 100%;
    height: 100%;
  }
}

.eco-friendly-products-contents .text-area {
  background: #F5F5F5;
  display: flex;
  padding: 30px;
  gap: 40px;
  align-items: center;
}

.eco-friendly-products-contents .text-area .tab-icon {
  object-fit: none;
  width: fit-content;
}

.eco-friendly-products-contents .text-area .text {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .eco-friendly-products-contents .text-area {
    flex-direction: column;
  }
  .eco-friendly-products-contents .text-area .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
}
</style>
