var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("search_results")))]),
          ]),
        ]),
        _c("div", { staticClass: "container search-result" }, [
          _vm.searchResult
            ? _c("div", { staticClass: "sch-res-wrap" }, [
                _c("div", { staticClass: "hd-close" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-hd-close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "blind" }, [
                        _vm._v("Close Button"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "w-con-02" }, [
                  _c("div", { staticClass: "sch-top" }, [
                    _c("div", { staticClass: "sch-frm" }, [
                      _c(
                        "label",
                        { staticClass: "blind", attrs: { for: "inpHdSch" } },
                        [_vm._v("Input Search Keyword")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchWord,
                            expression: "searchWord",
                          },
                        ],
                        staticClass: "ipt-hd-sch wrd-on",
                        attrs: {
                          type: "text",
                          id: "inpHdSch",
                          placeholder: _vm.$t("search.enter_search_word"),
                        },
                        domProps: { value: _vm.searchWord },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchWord = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "btn-hd-sch",
                          attrs: { type: "button" },
                          on: { click: _vm.doSearch },
                        },
                        [
                          _c("span", { staticClass: "blind" }, [
                            _vm._v("Search Button"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                !(_vm.showSearchResult && _vm.searchHomeWords.length > 0)
                  ? _c("div", { staticClass: "sch-btm inner" }, [
                      _c("div", { staticClass: "w-con" }, [
                        _vm.searchHomeWords.length > 0
                          ? _c("div", { staticClass: "sch-recent" }, [
                              _c("p", { staticClass: "sch-rcnt-tit" }, [
                                _vm._v(_vm._s(_vm.$t("search.recent_search"))),
                              ]),
                              _c(
                                "ul",
                                { staticClass: "sch-rcnt-list" },
                                _vm._l(_vm.searchHomeWords, function (sw) {
                                  return _c("li", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "sch-rcnt-keyw",
                                        on: {
                                          click: function ($event) {
                                            _vm.searchWord = sw
                                            _vm.doSearch()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(sw))]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-rcnt-del",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRecent(sw)
                                          },
                                        },
                                      },
                                      [_vm._v("delete keyword button")]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm.popularWords.length > 0
                          ? _c(
                              "div",
                              { staticClass: "sch-recent sch-popular" },
                              [
                                _c("p", { staticClass: "sch-rcnt-tit" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("search.popular_search"))
                                  ),
                                ]),
                                _c(
                                  "ul",
                                  { staticClass: "sch-rcnt-list" },
                                  _vm._l(_vm.popularWords, function (sw) {
                                    return _c(
                                      "li",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.searchWord = sw
                                            _vm.doSearch()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "sch-rcnt-keyw" },
                                          [_vm._v(_vm._s(sw))]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm.seeContent
                  ? _c("div", { staticClass: "w-con-02" }, [
                      _c("h1", { staticClass: "tit mb-88 tac inner" }, [
                        _c("span", [_vm._v(_vm._s(_vm.totalCount()))]),
                        _vm._v(" " + _vm._s(_vm.$t("result_for")) + " "),
                        _c("strong", [
                          _vm._v("“" + _vm._s(_vm.searchWord2) + "”"),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.seeContent
                  ? _c(
                      "div",
                      { staticClass: "inner search-inner" },
                      [
                        _vm.searchResult
                          ? _c("router-view", {
                              attrs: {
                                searchResult: _vm.searchResult,
                                searchWord: _vm.searchWord,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "sch-btm" }, [
                      _c("div", { staticClass: "w-con" }, [
                        _c("div", { staticClass: "sch-no" }, [
                          _c("p", { staticClass: "sch-no-info" }, [
                            _c("span", { staticClass: "no-re-tit" }, [
                              _vm._v(
                                _vm._s(_vm.$t("search.no_search_result")) + ' "'
                              ),
                              _c("em", { staticClass: "sch-keyw color-red" }, [
                                _vm._v(_vm._s(_vm.searchWord2)),
                              ]),
                              _vm._v('" '),
                            ]),
                            _c("span", { staticClass: "no-re-desc" }, [
                              _vm._v(_vm._s(_vm.$t("search.no_search_guide"))),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "scr-hder on micro" }, [
      _c("div", { staticClass: "clear" }, [
        _c("div", { staticClass: "fl-l" }, [
          _c("div", { staticClass: "logo" }, [
            _c(
              "a",
              {
                staticClass: "go_home_for_pc",
                attrs: { href: "homecenter/viatera" },
              },
              [_vm._v("LX Hausys")]
            ),
            _c(
              "a",
              {
                staticClass: "go_home_for_mobile",
                staticStyle: { display: "none" },
                attrs: { href: "homecenter/viatera" },
              },
              [_vm._v("LX Hausys")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "fl-c" }),
        _c("div", { staticClass: "fl-r" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }