var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-area-carousel" },
    [
      _c(
        "Carousel",
        {
          attrs: { useDot: false, useArrows: false },
          scopedSlots: _vm._u([
            {
              key: "custom",
              fn: function (pageData) {
                return [
                  _c("BusinessAreaCarouselArrow", {
                    attrs: { data: pageData },
                  }),
                ]
              },
            },
          ]),
        },
        _vm._l(_vm.items, function (it, idx) {
          return _c("BusinessAreaCarouselItem", {
            key: idx,
            attrs: { imageSrc: it.imageSrc, title: it.title, color: it.color },
          })
        }),
        1
      ),
      _c("div", { staticClass: "desc-area" }, [
        _vm.from === "BuildingDecorativeMaterials"
          ? _c("pre", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))])
          : _vm._e(),
        _vm.from === "IndustrialFilm"
          ? _c("pre", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))])
          : _vm._e(),
        _vm.from === "Automotive"
          ? _c("pre", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }