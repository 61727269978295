<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>PVC</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
          <span class="checkbox_more no_line">
            <li v-for="pvc in pvcList" :data-chk="pvc.id.value">
              <input type="checkbox" :id="pvc.id.value.replaceAll(' ', '_').toLowerCase()" name="PVC" v-model="selected" :value="pvc.id.value" />
              <label :for="`${pvc.id.value.replaceAll(' ', '_').toLowerCase()}`">
              <span></span>
              {{ pvc.text }}
              </label>
            </li>
          </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'pvcList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-pvc-filter', this.removePvc)
    EventBus.$on('update-pvc-filters', this.updateremovePvcs)
    if (this.$store.state.filterState && this.$store.state.filterState.pvc) {
      this.selected = this.$store.state.filterState.pvc
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-pvc-filter', this.removePvc)
    EventBus.$on('update-pvc-filters', this.updateremovePvcs)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removePvc (pvcValue) {
      this.selected = this.selected.filter(value => value !== pvcValue)
    },
    updateremovePvcs (pvcs) {
      this.selected = pvcs
    }
  }
}
</script>
