var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "container error400-con" }, [
        _c("div", { staticClass: "layer-view layer-imglib popup-v2" }, [
          _c("div", { staticClass: "popup" }, [
            _c("div", { staticClass: "scr-y" }, [
              _c("div", { staticClass: "popup-body" }, [
                _c("div", { staticClass: "frow" }, [
                  _c("div", { staticClass: "fcol tac" }, [
                    _c("i", { staticClass: "pcon" }, [
                      _c("img", {
                        staticClass: "er-icon",
                        attrs: {
                          src: "https://img.lxhausys.com/public/images/icon-error400.png",
                          alt: "error400 icon",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "msg" }, [
                      _c("ul", [
                        _c("li", { staticClass: "tt-2" }, [
                          _vm._v("Sorry, Page Not Found"),
                        ]),
                        _c("li", { staticClass: "ctt ctt-t2" }, [
                          _vm._v("The Page you’re looking for can’t be found."),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "popup-footer hasDouble" }, [
                _c("div", { staticClass: "frow" }, [
                  _c("div", { staticClass: "fcol" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        attrs: {
                          type: "button",
                          onclick:
                            "location.href='https://www.lxhausys.com/us/index'",
                        },
                      },
                      [_c("span", [_vm._v("Go Home")])]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-second",
                        attrs: { type: "button", onclick: "history.back()" },
                      },
                      [_c("span", [_vm._v("Go Back")])]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }