<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content himacs-pf">

      <!-- location -->
      <div class="location type03">
        <ul>
          <li class="ico-home">{{ $t('PCD.text.home')}}</li>
          <li>{{ $t('PCD.text.products')}}</li>
          <li>{{ $t('PCD.text.himacs')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (himacs Finder) -->
      <div class="container prod-fd-view himacs-finder">

        <!-- visual -->
        <div class="visual visual-detail">
          <div
            class="visual-image"
            v-if="pfBanImg"
            v-bind:style="{
              backgroundImage: 'url( ' + $imagePath + pfBanImg + ')',
            }"
          >
            <div class="txt-box">
              <h1 class="tt-26">
                {{subject}} {{subject2}}
              </h1>
              <div class="t-type-05" v-if="brief != null" v-html="brief"></div>

              <button type="button" class="btn btn-more-point">
                <router-link to="/himacs">
                  <span>{{ $t('PCD.button.learnMore')}}</span>
                </router-link>
              </button>
            </div>
          </div>
          <div class="bgdim"></div>
        </div>
        <!-- //visual -->

        <!-- EU 포토크레딧 추가 -->
        <p class="ctt photo-credits ta-r" v-if="nationCode != '_US' && nationCode != '_GL'">
          <span class="pc-br" v-if="photoCredit != null && photoCredit != undefined && photoCredit != ''" v-html="photoCredit" />
          <span class="pc-br" v-if="photoCredit2 != null && photoCredit2 != undefined && photoCredit2 != ''" v-html="photoCredit2" />
        </p>

        <div class="col-02 inspr-view fd-view">

          <!-- search 필터 선택 pc전용 -->
          <div class="search-box p-ui" id="filterSearch">

            <div class="search">
              <input
                id="textSearch"
                type="text"
                :placeholder="this.$t('PF.text.searchText')"
                @input="textSearch($event)"
              />
              <button
                type="button"
                id="searchBtn"
                class="icon-search gray"
                @click="textSearchDel()"
              >
                search
              </button>
            </div>

            <!-- color / us | gl -->
            <div class="drop-list inner">
              <ul v-if="!isMobile()">
                <li class="on">

                  <!-- us / gl title -->
                  <div class="tit" v-if="nationCode == '_GL' || nationCode == '_US'">
                    <span>{{ $t('PD.text.color')}}</span>
                    <button type="button" class="btn-view btn-color">
                      {{ $t('PF.button.color')}}
                    </button>
                  </div>

                  <!-- eu / uk title -->
                  <div class="tit" v-if="nationCode != '_GL' && nationCode != '_US'">
                    <span>{{ $t('PF.text.Tones')}}</span>
                    <button type="button" class="btn-view btn-color">
                      {{ $t('PF.text.Tones')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block" v-if="nationCode == '_GL' || nationCode == '_US'">
                    <ul class="i-chk type03">
                      <li v-for="lefthue in lefthue">
                        <label class="inp-chk chk-color">
                          <input
                            type="checkbox"
                            name="color"
                            :id="'hue_' + lefthue.id.value"
                            @change="
                              [
                                fnSearch(
                                  'hue',
                                  lefthue.text,
                                  lefthue.id.value,
                                  'Color'
                                ),
                              ]
                            "
                          />
                          <i v-if="lefthue.text === 'Other'"
                          ><span
                            :style="
                                'background-image : url(images/products/' +
                                lefthue.id.value +
                                '.png)'
                              "
                          ></span
                          >{{ lefthue.text }}</i
                          >
                          <i v-else
                          ><span
                            :style="'background-color:#' + lefthue.id.value"
                          ></span
                          >{{ lefthue.text }}</i
                          >
                          <span>{{ lefthue.text }}</span>
                        </label>
                        <!-- 이방식으로 교체합니다-->
                      </li>
                    </ul>
                  </div>
                  <!-- color / us | gl end -->

                  <!-- tone / eu | uk -->
                  <div class="i-box type02 cont" style="display: block"  v-if="nationCode != '_GL' && nationCode != '_US'">
                    <ul class="i-chk type03">
                      <li v-for="lefthue in lefthue">
                        <label class="inp-chk chk-color">
                          <input
                            type="checkbox"
                            name="color"
                            :id="'hue_' + lefthue.id.value"
                            @change="
                              [
                                fnSearch(
                                  'hue',
                                  lefthue.text,
                                  lefthue.id.value,
                                  'Tone'
                                ),
                              ]
                            "
                          />
                          <i v-if="lefthue.text === 'Other'"
                          ><span
                            :style="
                                'background-image : url(images/products/' +
                                lefthue.id.value +
                                '.png)'
                              "
                          ></span
                          >{{ lefthue.text }}</i
                          >
                          <i v-else
                          ><span
                            :style="'background-color:#' + lefthue.id.value"
                          ></span
                          >{{ lefthue.text }}</i
                          >
                          <span>{{ lefthue.text }}</span>
                        </label>
                        <!-- 이방식으로 교체합니다-->
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- tone / eu | uk end -->

                <!-- Collection -->
                <li class="on">
                  <div class="tit">
                    <span>{{ $t('PCD.text.collection')}}</span>
                    <button type="button" class="btn-view btn-collection">
                      {{ $t('PF.button.collection')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul>
                      <!--                      <li>
                                              <label class="inp-chk">
                                                <input type="checkbox" checked id="CateAll" @click="[fnCateSearch('CateAll','CateAll')]">
                                                <span>All</span>
                                              </label>
                                            </li>-->

                      <li v-for="coll in leftcollection">
                        <label
                          class="inp-chk"
                          v-if="coll.category.hidden === false"
                        >
                          <input
                            type="checkbox"
                            class="Collection"
                            :id="coll.category.id"
                            @change="
                              [
                                fnCateSearch(
                                  coll.category.name,
                                  coll.category.id
                                ),
                              ]
                            "
                          />
                          <span
                          >{{ coll.category.name
                            }}<strong
                              class="ico-g"
                              v-if="coll.category.name === 'Eden Plus'"
                            >{{ $t('PF.text.Recycled')}}</strong
                            ></span
                          >
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Collection -->

                <!-- Patterns -->
                <li class="on">
                  <div class="tit">
                    <span>{{ $t('PF.text.patterns')}}</span>
                    <button type="button" class="btn-view btn-patterns">
                      {{ $t('PF.button.patterns')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul class="i-chk type02">
                      <!--                      <li>
                                              <label class="inp-chk">
                                                <input type="checkbox" checked id="PatternAll"  @click="[fnSearch('Pattern','PatternAll')]">
                                                <span class="ico-all">All</span>
                                              </label>
                                            </li>-->

                      <li v-for="leftpattern in leftpattern">
                        <label class="inp-chk chk-thumb">
                          <input
                            type="checkbox"
                            class="pattern"
                            :id="'pattern_' + leftpattern.id.value"
                            @change="
                              [
                                fnSearch(
                                  'pattern',
                                  leftpattern.text,
                                  leftpattern.id.value,
                                  'Pattern'
                                ),
                              ]
                            "
                          />>
                          <span
                          ><i
                            :style="
                                'background-image : url(images/products/' +
                                leftpattern.id.value +
                                '.png)'
                              "
                          ></i
                          >{{ leftpattern.text }}</span
                          >
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Patterns -->

                <!-- Country -->
                <li v-if="nationCode == '_GL'">
                  <div class="tit">
                    <span>{{ $t('PF.text.country')}}</span>
                    <button type="button" class="btn-view btn-country">
                      {{ $t('PF.button.country')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul class="i-chk">
                      <li v-for="leftCountry in leftCountry">
                        <label class="inp-chk">
                          <input
                            type="checkbox"
                            class="country"
                            :id="'country_' + leftCountry.id.value"
                            @click="
                              [
                                fnSearch(
                                  'country',
                                  leftCountry.text,
                                  leftCountry.id.value,
                                  'Country'
                                ),
                              ]
                            "
                          />
                          <span>{{ leftCountry.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Country -->

                <!-- New -->
                <li class="on">
                  <div class="tit">
                    <span>{{ $t('PF.text.new')}}</span>
                    <button type="button" class="btn-view btn-new">{{ $t('PF.button.new')}}</button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul>
                      <li>
                        <label class="inp-chk">
                          <input
                            type="checkbox"
                            class="new"
                            :id="'isNew_true'"
                            @click="
                              [fnSearch('isNew', 'NEW COLORS', 'true', 'New')]
                            "
                          />
                          <span>{{ $t('PF.text.new_colors')}}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- New -->

                <!-- USE -->
                <li v-if="nationCode != '_US' && nationCode != '_GL'">
                  <div class="tit">
                    <span>{{ $t('PF.text.Use')}}</span>
                    <button type="button" class="btn-view btn-country">
                      {{ $t('PF.text.Use')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul class="i-chk">
                      <li v-for="leftUse in leftUse">
                        <label class="inp-chk">
                          <input
                            type="checkbox"
                            class="USE"
                            :id="'USE_' + leftUse.id.value"
                            @click="
                              [
                                fnSearch(
                                  'USE',
                                  leftUse.text,
                                  leftUse.id.value,
                                  'USE'
                                ),
                              ]
                            "
                          />
                          <span>{{ leftUse.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- USE -->

                <!-- Recycled -->
                <li v-if="nationCode != '_US' && nationCode != '_GL'">
                  <div class="tit">
                    <span>{{ $t('PF.text.Recycled')}}</span>
                    <button type="button" class="btn-view btn-country">
                      {{ $t('PF.text.Recycled')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul class="i-chk">
                      <li v-for="leftRecycled in leftRecycled">
                        <label class="inp-chk">
                          <input
                            type="checkbox"
                            class="recycled"
                            :id="'recycled_' + leftRecycled.id.value"
                            @click="
                              [
                                fnSearch(
                                  'recycled',
                                  leftRecycled.text,
                                  leftRecycled.id.value,
                                  'Recycled'
                                ),
                              ]
                            "
                          />
                          <span>{{ leftRecycled.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Recycled -->

                <!-- Thickness -->
                <li v-if="nationCode != '_US' && nationCode != '_GL'">
                  <div class="tit">
                    <span>{{ $t('PF.text.Thickness')}}</span>
                    <button type="button" class="btn-view btn-country">
                      {{ $t('PF.text.Thickness')}}
                    </button>
                  </div>

                  <div class="i-box type02 cont" style="display: block">
                    <ul class="i-chk">
                      <li v-for="leftThickness in leftThickness">
                        <label class="inp-chk">
                          <input
                            type="checkbox"
                            class="thickness"
                            :id="'thickness_' + leftThickness.id.value"
                            @click="
                              [
                                fnSearch(
                                  'thickness',
                                  leftThickness.text,
                                  leftThickness.id.value,
                                  'Thickness'
                                ),
                              ]
                            "
                          />
                          <span>{{ leftThickness.text }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </li>
                <!-- Thickness -->

                <!-- <li>
                    <div class="tit">
                        <span>Segments</span>
                        <button type="button" class="btn-view btn-segments">Segments</button>
                    </div>

                    <div class="l-box cont">
                        <ul>
                            <li v-for="(segName, index) in segNames" v-bind:key="index">
                                <button type="button" @click="[fnSearch('Segments',segName)]">{{segName.name}}</button>
                            </li>
                        </ul>
                    </div>
                </li> -->
              </ul>
              <button
                class="filter-clos"
                @click="clickFilter()"
              ></button>
            </div>

          </div>
          <!-- //search 필터 선택 pc전용 -->

          <div class="prod-list-box">
            <div class="tab-ui tab-ui-dark">
              <ul>
                <li class="btn-tab on" id="btn-tab01" data-tab="tab01">
                  <button type="button">
                    {{ $t('PF.button.all_products')}} ({{ contentLength }})
                  </button>
                </li>
                <li class="btn-tab" id="btn-tab02" data-tab="tab02">
                  <button type="button">
                    {{ $t('PF.button.grouped_by_collections')}} ({{ collectionLength }})
                  </button>
                </li>
              </ul>
            </div>

            <!-- 모바일용 검색창 s -->
            <div class="fd-view-mb search-area">
              <div class="search">
                <input
                  id="textSearchM"
                  type="text"
                  :placeholder="this.$t('PF.text.searchText')" @input="textSearch($event)"/>
                <i id="searchBtnM" class="icon-search gray" @click="textSearchDel()"></i>
              </div>
            </div>
            <!-- 모바일용 검색창 e -->

            <div class="tab-ui inner">
              <!-- 모바일용 filter by 버튼 s -->
              <div class="filter-arae mb-only">
                <!-- <button type="button" @click="clickFilter()"> -->
                <button type="button" @click="openSearchPopup('layer-search-box')">
                  {{ $t('PF.button.filter_by')}}<i></i>
                </button>
              </div>
              <!-- 모바일용 filter by 버튼 e -->

              <!-- sort US / GL -->
              <div class="sort-box" v-if="nationCode == '_US' || nationCode == '_GL'">
                <div class="sel-box">
                  <button type="button" class="btn-sel">
                    <span>{{ sortBy }}</span>
                  </button>
                  <div class="sel-list">
                    <ul class="box">
                      <li>
                        <button
                          type="button"
                          @click="
                            sortByChange('Products A to Z', 'asc', 'title')
                          "
                        >
                          {{ $t('PF.button.products_a_to_z')}}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          @click="
                            sortByChange('Products Z to A', 'desc', 'title')
                          "
                        >
                          {{ $t('PF.button.products_z_to_a')}}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          @click="sortByChange('New Products first', 'desc')"
                        >
                          {{ $t('PF.button.new_products_first')}}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- //sort -->

              <!-- sort EU / UK -->
              <div class="sort-box" v-if="nationCode != '_US' && nationCode != '_GL'">
                <div class="sel-box">
                  <button type="button" class="btn-sel">
                    <span>{{ sortBy }}</span>
                  </button>
                  <div class="sel-list">
                    <ul class="box">

                      <li>
                        <button
                          type="button"
                          @click="sortByChange('Popularity', 'desc', 'clicks')"
                        >
                          {{ $t('PF.button.Popularity')}}
                        </button>
                      </li>

                      <li>
                        <button
                          type="button"
                          @click="sortByChange('New Products first', 'desc')"
                        >
                          {{ $t('PF.button.new_products_first')}}
                        </button>
                      </li>

                      <li>
                        <button
                          type="button"
                          @click="
                            sortByChange('Products A to Z', 'asc', 'title')
                          "
                        >
                          {{ $t('PF.button.products_a_to_z')}}
                        </button>
                      </li>

                      <li>
                        <button
                          type="button"
                          @click="
                            sortByChange('Products Z to A', 'desc', 'title')
                          "
                        >
                          {{ $t('PF.button.products_z_to_a')}}
                        </button>
                      </li>

                    </ul>
                  </div>
                </div>

                <div class="more-box type03 comprae-box">
                  <button type="button" class="btn"  id=compare @click="getCompareList()"><span>{{ $t('PF.button.compare_your_colors')}} ({{compareArr.length}})</span></button>
                </div>

              </div>
              <!-- //sort -->

              <!-- 2022.04.08 필터값 선택이 안돼었을때 select-view 안보여야 합니다. pc 와 모바일 동일  -->
              <div class="select-view" v-if="colorList.length > 0">
                <ul class="list-filter-key">
                  <li class="sel" v-for="(color, index) in colorList"
                      @click="[colorDelete(color.subject, color.name, color.id)]">
                    <span v-if="color.subject == 'Collection'">{{ color.subject}}</span>
                    <span v-else>{{ color.type }}</span>
                    : <span> {{ color.name }} </span>
                     <button type="button">&#10005;</button>
                  </li>
                  <li class="sel del_all">
                    <button type="button" class="btn-all-delete" v-if="colorList.length > 0" @click="clearAll()">
                      <span>{{ $t('PF.button.clear_all')}} <em>&#10005;</em></span>
                    </button>
                  </li>
                </ul>
              </div>

              <!-- All products -->
              <div class="tab-con tab01 on">
                <div class="list-type-view row-04">
                  <ul>
                    <li v-for="(item,index) in contentList" :key="index" class="himacs-pfd">
                      <a v-if="nationCode != '_US' && nationCode != '_GL'"
                        :href="
                           'products/himacs-solid-surface/' +
                            $utils.getSeoName(item, 'content') +
                            '/' +
                            item.content.id
                          "
                        :data-ec-product ="makeDataLayerForm(item)"
                      >
                        <div class="img-box over">
                          <span class="new" v-if="item.attribute.isNew === 'true'">{{ $t('PF.text.new')}}</span>
                          <span class="new" v-if="item.attribute.label != '' && item.attribute.label != undefined && item.attribute.label != null"
                                style="background-color: #595555;border-color: #595555;">{{ item.attribute.label }}</span>
                          <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb" @click="gotoPD(item)" style="cursor:pointer;" :data-ec-product ="makeDataLayerForm(item)">
                          <div :class="'auraBtns aurora-'+item.attribute.text_color" v-if="nationCode != '_US' && nationCode != '_GL'">
                          <!-- auraBtns aurora-black 이미지가 흰색이고 아이콘이 검은색 -->
                          <!-- auraBtns aurora-white 이미지가 검은색이고 아이콘이 흰색 -->
                          <!-- <div class="auraBtns aurora-white" v-if="nationCode != '_US' && nationCode != '_GL'"> -->
                            <button type="button" class="icon-Aura-shadow" v-if="item.attribute.auroa == 'true'">
                              <span class="sr-only">aura</span>
                            </button>
                            <button type="button" :class="'icon-'+icon.id+'-shadow'" v-for="(icon, index) in item.iconList" v-if="index < 2">
                              <span class="sr-only">{{ icon.id }}</span>
                            </button>
                            <!--                            <button type="button" class="icon-exterior-shadow" v-if="item.parents[0].name.includes('Exteria')">-->
                            <!--                              <span class="sr-only">exterior</span>-->
                            <!--                            </button>-->
                            <!--                            &lt;!&ndash; 퍼블 시작 = Himacs Product Spec 아이콘 추가 &ndash;&gt;-->
                            <!--                            <button type="button" class="icon-veined-shadow" v-if="item.attribute.veined == 'true'">-->
                            <!--                              <span class="sr-only">veined</span>-->
                            <!--                            </button>-->
                            <!--                            <button type="button" class="icon-translucent-shadow" v-if="item.attribute.semiTranslucent == 'true'">-->
                            <!--                              <span class="sr-only">translucent</span>-->
                            <!--                            </button>-->
                            <!--                            <button type="button" class="icon-recycled-shadow" v-if="item.attribute.recycled != null && item.attribute.recycled != undefined && item.attribute.recycled.length != 0">-->
                            <!--                              <span class="sr-only">recycled</span>-->
                            <!--                            </button>-->
                            <!--                            <button type="button" class="icon-notice-shadow" v-if="item.attribute.notice == 'true'">-->
                            <!--                              <span class="sr-only">notice</span>-->
                            <!--                            </button>-->
                            <!-- 퍼블 끝 = Himacs Product Spec 아이콘 추가 -->
                          </div>
                        </div>
                      </a>
                      <a v-else
                         :href="
                            'products/himacs-solid-surface/' +
                            $utils.getSeoName(item, 'content') +
                            '/' +
                            item.content.id
                          "
                         :data-ec-product ="makeDataLayerForm(item)"
                      >
                        <div class="img-box over">
                          <span class="new" v-if="item.attribute.isNew === 'true'">{{ $t('PF.text.new')}}</span>
                          <span class="new" v-if="item.attribute.label != '' && item.attribute.label != undefined && item.attribute.label != null"
                          style="background-color: #595555;border-color: #595555;">{{ item.attribute.label }}</span>
                          <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb" @click="gotoPD(item)" style="cursor:pointer;" :data-ec-product ="makeDataLayerForm(item)">
                          <div :class="'auraBtns aurora-'+item.attribute.text_color" v-if="nationCode != '_US' && nationCode != '_GL'">
                            <!-- auraBtns aurora-black 이미지가 흰색이고 아이콘이 검은색 -->
                            <!-- auraBtns aurora-white 이미지가 검은색이고 아이콘이 흰색 -->
                            <!-- <div class="auraBtns aurora-white" v-if="nationCode != '_US' && nationCode != '_GL'"> -->
                            <button type="button" class="icon-Aura-shadow" v-if="item.attribute.auroa == 'true'">
                              <span class="sr-only">aura</span>
                            </button>
                            <button type="button" :class="'icon-'+icon.id+'-shadow'" v-for="(icon, index) in item.iconList" v-if="index < 2">
                              <span class="sr-only">{{ icon.id }}</span>
                            </button>
                            <!--                            <button type="button" class="icon-exterior-shadow" v-if="item.parents[0].name.includes('Exteria')">-->
                            <!--                              <span class="sr-only">exterior</span>-->
                            <!--                            </button>-->
                            <!--                            &lt;!&ndash; 퍼블 시작 = Himacs Product Spec 아이콘 추가 &ndash;&gt;-->
                            <!--                            <button type="button" class="icon-veined-shadow" v-if="item.attribute.veined == 'true'">-->
                            <!--                              <span class="sr-only">veined</span>-->
                            <!--                            </button>-->
                            <!--                            <button type="button" class="icon-translucent-shadow" v-if="item.attribute.semiTranslucent == 'true'">-->
                            <!--                              <span class="sr-only">translucent</span>-->
                            <!--                            </button>-->
                            <!--                            <button type="button" class="icon-recycled-shadow" v-if="item.attribute.recycled != null && item.attribute.recycled != undefined && item.attribute.recycled.length != 0">-->
                            <!--                              <span class="sr-only">recycled</span>-->
                            <!--                            </button>-->
                            <!--                            <button type="button" class="icon-notice-shadow" v-if="item.attribute.notice == 'true'">-->
                            <!--                              <span class="sr-only">notice</span>-->
                            <!--                            </button>-->
                            <!-- 퍼블 끝 = Himacs Product Spec 아이콘 추가 -->
                          </div>
                        </div>
                      </a>
                      <div class="view" >
                        <a
                          @click="openSampleOrder(item.content.id)"
                          class="icon-cart-shadow"
                          v-if="nationCode === '_US'"
                          >{{ $t('PCD.text.cart')}}</a
                        >

                        <a
                          class="icon-wish-shadow"
                          v-if="authenticated == true && nationCode == '_US'"
                          v-bind:class="{ on: item.content.liked }"
                          @click="toggleLike(item, 'HIMACS')"
                          >{{ $t('PCD.text.wish_list')}}</a
                        >
                        <a
                          class="icon-wish-shadow"
                          v-if="authenticated == false && nationCode == '_US'"
                          href="login/"
                          @click="loginalertLike()"
                          >{{ $t('PCD.text.wish_list')}}</a
                        >

                        <a
                          class="icon-wish-shadow"
                          v-if="
                            getSessionContent != null && nationCode != '_US'
                          "
                          v-bind:class="{
                            on: getSessionContent.includes(item.content.id),
                          }"
                          @click="toggleLike(item, 'HIMACS')"
                          >{{ $t('PCD.text.wish_list')}}</a
                        >
                        <a
                          class="icon-wish-shadow"
                          v-else-if="
                            getSessionContent == null && nationCode != '_US'
                          "
                          @click="toggleLike(item, 'HIMACS')"
                          >{{ $t('PCD.text.wish_list')}}</a
                        >

                        <div class="prod-info-ico">
                          <p
                            class="ico-b"
                            v-if="item.attribute.exclusive === 'true'"
                          >
                            {{ $t('PCD.text.exclusive')}}
                          </p>
                          <!--                          <p class="ico-r" >New</p>-->
                        </div>
                      </div>

                      <div class="info02">
                        <ul>
                          <li class="txt-02" v-if="item.orgContent != null && nationCode != '_US' && nationCode != '_GL'" >
                            <router-link
                              :to="
                                '/' +
                                item.orgContent.modelId +
                                '/' +
                                item.orgContent.title +
                                '/' +
                                item.content.id
                              "
                            >
                              {{ item.content.title }}
                            </router-link>
                          </li>
                          <li class="txt-02" v-else-if="item.orgContent == null && nationCode != '_US' && nationCode != '_GL'">
                            <router-link
                              :to="
                                '/' +
                                item.content.modelId +
                                '/' +
                                item.content.title +
                                '/' +
                                item.content.id
                              "
                            >
                              {{ item.content.title }}
                            </router-link>
                          </li>
                          <li class="txt-02" v-if="item.orgContent != null && (nationCode == '_US' || nationCode == '_GL')" >
                            <router-link
                              :to="
                                '/' +
                                'himacs-solid-surface' +
                                '/' +
                                $utils.getSeoName(item, 'content') +
                                '/' +
                                item.content.id
                              "
                            >
                              {{ item.content.title }}
                            </router-link>
                          </li>
                          <li class="txt-02" v-else-if="item.orgContent == null && (nationCode == '_US' || nationCode == '_GL')">
                            <router-link
                              :to="
                                '/' +
                                'himacs-solid-surface' +
                                '/' +
                                $utils.getSeoName(item, 'content') +
                                '/' +
                                item.content.id
                              "
                            >
                              {{ item.content.title }}
                            </router-link>
                          </li>
                          <li class="txt-01">{{ item.attribute.code }}</li>
                          <li class="txt-03" v-if="nationCode == '_US' || nationCode == '_GL'">{{ item.parents[0].name }}</li>
                          <!-- eu 일시  txt-03 대신 txt-04 변경-->
                          <li class="txt-04" v-if="nationCode != '_US' && nationCode != '_GL'">
                            {{ item.parents[0].name }}
                          </li>
                          <li class="txt-04" v-if="nationCode != '_US' && nationCode != '_GL' && item.attribute.collectionText != null && item.attribute.collectionText != undefined">
                            {{ item.attribute.collectionText }}
                          </li>
                          <li class="txt-04" v-if="nationCode != '_US' && nationCode != '_GL' && item.attribute.collection1Text != null && item.attribute.collection1Text != undefined">
                            {{ item.attribute.collection1Text }}
                          </li>
                          <!-- eu 일시  txt-03 대신 txt-04 변경 -->
                        </ul>

                        <!-- eu 일시 추가-->
                        <label class="inp-chk btn-compare" v-if="nationCode != '_US' && nationCode != '_GL'">
                          <input type="checkbox" v-model="compareArr" :id="item.content.id" :value="item.content.id" @change="addCompare(item.content.id)">
                          <span>{{ $t('PF.button.compare')}}</span>
                        </label>
                        <!-- eu 일시 추가-->

                      </div>
                    </li>
                  </ul>
                </div>

                <!-- See More 버튼  -->
                <div
                  class="more-box ta-c"
                  v-if="contentLength > 16 && pageIndex == 1"
                >
                  <button
                    type="button"
                    class="btn btn-arr-down"
                    @click="loadMore(pageIndex + 1)"
                  >
                    <span>{{ $t('PF.button.see_more')}}</span>
                  </button>
                </div>
                <div
                  class="more-box ta-c"
                  v-if="
                    contentLength > (pageIndex / 16) * 16 * 16 && pageIndex != 1
                  "
                >
                  <button
                    type="button"
                    class="btn btn-arr-down"
                    @click="loadMore(pageIndex + 1)"
                  >
                    <span>{{ $t('PF.button.see_more')}}</span>
                  </button>
                </div>

                <!--                <infinite-loading @infinite="infiniteHandler" spinner="circles" ref="infiniteLoading" v-if="mount === 'mounted'"> &lt;!&ndash;v-if="contentLength > 12"&ndash;&gt;-->
                <!--                  <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">NO MORE DATA</div>-->
                <!--                </infinite-loading>-->
              </div>
              <!-- //All products -->

              <!-- Grouped by collections -->
              <div class="tab-con tab02">
                <div
                  class="list-type-col"
                  v-for="(coll, index) in checkCollectionList"
                  v-bind:key="index"
                  v-if="coll.category.hidden === false"
                >
                  <ul>
                    <li class="col-02">
                      <div class="img-box">
                        <img :src="$imagePath + coll.categoryAttribute.image" />
                      </div>

                      <div class="txt-box bd-0">
                        <p class="tt-3 mb-20">{{ coll.category.name }} {{ $t('PCD.text.collection')}}</p>
                        <div class="scroll">
                          <div class="t-type-01">
                            {{ coll.categoryAttribute.Des }}
                          </div>
                        </div>
                        <div class="more-box type02-02">
                          <!--                          <button type="button"><router-link to="/himacsFinder"><span>See all colors</span></router-link></button>-->
                          <button
                            type="button"
                            class="btn btn-sm btn-second"
                            @click="
                              fnCateSearch(
                                coll.category.name,
                                coll.category.id,
                                'click'
                              )
                            "
                          >
                            <span>{{ $t('PF.button.see_all_colors')}}</span>
                          </button>
                          <!-- himacs pf 퍼블 브로셔 버튼 추가 -->
                          <div class="sel-box brochure-sel" v-if="nationCode!='_US' && nationCode !='_GL' &&
                          coll.categoryAttribute.bro_english != null && coll.categoryAttribute.bro_english != undefined && coll.categoryAttribute.bro_english != ''
                        || coll.categoryAttribute.bro_german != null && coll.categoryAttribute.bro_german != undefined && coll.categoryAttribute.bro_german != ''
                           || coll.categoryAttribute.bro_french != null && coll.categoryAttribute.bro_french != undefined && coll.categoryAttribute.bro_french != ''
                             || coll.categoryAttribute.bro_italian != null && coll.categoryAttribute.bro_italian != undefined && coll.categoryAttribute.bro_italian != ''
                               || coll.categoryAttribute.bro_spanish != null && coll.categoryAttribute.bro_spanish != undefined && coll.categoryAttribute.bro_spanish != ''
                                 || coll.categoryAttribute.bro_dutch != null && coll.categoryAttribute.bro_dutch != undefined && coll.categoryAttribute.bro_dutch != ''
                                    || coll.categoryAttribute.bro_uk != null && coll.categoryAttribute.bro_uk != undefined && coll.categoryAttribute.bro_uk != ''">
                            <button type="button" class="btn btn-line btn-sel btn-arr-down-bk"><span>Brochure</span></button>
                            <div class="sel-list">
                              <ul class="box" v-if="nationCode != '_US' && nationCode != '_GL' && nationCode != '_UK'">
                                <li v-if="coll.categoryAttribute.bro_english != null && coll.categoryAttribute.bro_english != undefined && coll.categoryAttribute.bro_english != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_english)">{{ $t('PCD.button.English')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_german != null && coll.categoryAttribute.bro_german != undefined && coll.categoryAttribute.bro_german != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_german)">{{ $t('PCD.button.German')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_french != null && coll.categoryAttribute.bro_french != undefined && coll.categoryAttribute.bro_french != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_french)">{{ $t('PCD.button.French')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_italian != null && coll.categoryAttribute.bro_italian != undefined && coll.categoryAttribute.bro_italian != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_italian)">{{ $t('PCD.button.Italian')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_spanish != null && coll.categoryAttribute.bro_spanish != undefined && coll.categoryAttribute.bro_spanish != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_spanish)">{{ $t('PCD.button.Spanish')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_dutch != null && coll.categoryAttribute.bro_dutch != undefined && coll.categoryAttribute.bro_dutch != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_dutch)">{{ $t('PCD.button.Dutch')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_uk != null && coll.categoryAttribute.bro_uk != undefined && coll.categoryAttribute.bro_uk != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_uk)">{{ $t('PCD.button.EnglishUK')}}</button>
                                </li>
                              </ul>

                              <ul class="box" v-if="nationCode == '_UK'">
                                <li v-if="coll.categoryAttribute.bro_uk != null && coll.categoryAttribute.bro_uk != undefined && coll.categoryAttribute.bro_uk != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_uk)">{{ $t('PCD.button.EnglishUK')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_german != null && coll.categoryAttribute.bro_german != undefined && coll.categoryAttribute.bro_german != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_german)">{{ $t('PCD.button.German')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_french != null && coll.categoryAttribute.bro_french != undefined && coll.categoryAttribute.bro_french != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_french)">{{ $t('PCD.button.French')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_italian != null && coll.categoryAttribute.bro_italian != undefined && coll.categoryAttribute.bro_italian != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_italian)">{{ $t('PCD.button.Italian')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_spanish != null && coll.categoryAttribute.bro_spanish != undefined && coll.categoryAttribute.bro_spanish != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_spanish)">{{ $t('PCD.button.Spanish')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_dutch != null && coll.categoryAttribute.bro_dutch != undefined && coll.categoryAttribute.bro_dutch != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_dutch)">{{ $t('PCD.button.Dutch')}}</button>
                                </li>
                                <li v-if="coll.categoryAttribute.bro_english != null && coll.categoryAttribute.bro_english != undefined && coll.categoryAttribute.bro_english != ''">
                                  <button type="button"
                                          @click="gotolink(coll.categoryAttribute.bro_english)">{{ $t('PCD.button.English')}}</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <!--                          <button type="button" class="btn btn-line"><router-link to="/himacs"><span>Online Book</span></router-link></button>-->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //Grouped by collections -->
            </div>

            <!-- short cut -->
            <div class="short-link type02 mb-88 inner">
              <a @click="openCart()" v-if="nationCode === '_US'"
                ><span class="ico-01">{{ $t('PCD.text.order_a_sample')}}</span></a
              >
              <a v-if="nationCode === '_GL'" :href="'support/contactus'"><span class="ico-07 ctt ctt-t3">{{ $t('PCD.text.contact_us')}}</span></a>
              <a v-if="nationCode != '_US' && nationCode != '_GL'" :href="'support/contactus'"><span class="ico-07 ctt ctt-t3">{{ $t('PCD.text.contact_us')}}</span></a>
              <a href="pros/documentation/himacs"
                ><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a
              >
              <a v-if="nationCode == '_US' || nationCode == '_GL'" href="support/care-and-maintenance/himacs"
                ><span class="ico-03">{{ $t('PCD.text.care_maintenance')}}</span></a
              >
              <a v-if="nationCode === '_US'" href="support/warranty/himacs"
                ><span class="ico-04">{{ $t('PCD.text.warranty')}}</span></a
              >
              <a
                v-if="nationCode === '_US'"
                :href="'support/where-to-buy/country/us'"
                ><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a
              >
              <a
                v-else-if="nationCode === '_GL'"
                :href="'support/where-to-buy/country/gl'"
                ><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a
              >
              <a v-else :href="'support/sales-representative/continenteu/himacs'">
                <span class="ico-19">{{ $t('PCD.button.find_a_sales_rep')}}</span>
              </a>
              <a v-if="nationCode != '_US' && nationCode != '_GL'" href="inspiration/virtual-showroom">
                <span class="ico-06">{{ $t('PD.button.virtual_Showroom')}}</span>
              </a>
            </div>
            <!-- //short cut -->
          </div>
        </div>

        <!-- eu popup : Compare -->
        <div class="layer-popup layer-compare euSite" id="pdf_wrap">
          <div class="popup pop-close">
            <div class="pop-compare scr-y">
              <p class="tt-sub-gray">{{ $t('PF.popup.compare_himacs_products')}}</p>
              <p class="ta-c ">{{ $t('PF.popup.characteristics_technical_performance')}}</p>

              <div class="prod-list tbl-compare">
                <table>
                  <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                  <colgroup>
                    <col style="width:auto">
                    <col style="width:auto">
                    <col style="width:auto">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                  <tr>
                    <td v-for="(item,idx) in compareList">
                      <div class="prod-box">
                        <div class="prod">
                          <div class="img-box over">
                            <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.content.title">
                            <!--eu 일때-->
                             <div :class="'auraBtns aurora'+item.attribute.text_color" v-if="nationCode != '_GL' && nationCode != '_US'">
                               <button type="button" class="icon-aurora ctp-aura" v-if="item.attribute.auroa == 'true'">
                                 <span class="sr-only">aura</span>
                               </button>
                               <button type="button" class="icon-lucent-shadow" v-else-if="item.attribute.lucent == 'true'">
                                 <span class="sr-only">lucent</span>
                               </button>
                               <button type="button" class="icon-exterior-shadow" v-else-if="item.parents[0].name.includes('Exteria')">
                                 <span class="sr-only">exterior</span>
                               </button>
                               <button type="button" class="icon-veined-shadow" v-else-if="item.attribute.veined == 'true'">
                                 <span class="sr-only">veined</span>
                               </button>
                               <button type="button" class="icon-translucent-shadow" v-else-if="item.attribute.semiTranslucent == 'true'">
                                 <span class="sr-only">translucent</span>
                               </button>
                               <button type="button" class="icon-recycled-shadow" v-else-if="item.attribute.recycled != null && item.attribute.recycled != undefined && item.attribute.recycled.length != 0 && (item.attribute.auroa == 'false' && item.attribute.lucent == 'false' && !item.parents[0].name.includes('Exteria') && item.attribute.veined == 'false' && item.attribute.semiTranslucent == 'false')">
                                 <span class="sr-only">recycled</span>
                               </button>
                               <button type="button" class="icon-notice-shadow" v-else-if="item.attribute.notice == 'true' && (item.attribute.auroa == 'false' && item.attribute.lucent == 'false' && !item.parents[0].name.includes('Exteria') && item.attribute.veined == 'false' && item.attribute.semiTranslucent == 'false' && (item.attribute.recycled == null || item.attribute.recycled == undefined || item.attribute.recycled.length == 0) )">
                                 <span class="sr-only">notice</span>
                               </button>
                            </div>
                            <!--eu 일때-->
                          </div>

                          <div class="view" >
                            <a class="icon-wish-shadow" v-if=" getSessionContent != null && nationCode != '_US' " v-bind:class="{ on: getSessionContent.includes(item.content.id) }" @click="toggleLike(item, 'HIMACS')"/>
                            <a class="icon-wish-shadow" v-else-if="getSessionContent == null && nationCode != '_US'" @click="toggleLike(item, 'HIMACS')"/>
                          </div>
                        </div>

                        <div class="popinfo">
                          <ul>
                            <li class="tt-sub"> {{ item.content.title }} </li>
                            <li class="ctt ctt-t4"> {{ item.attribute.code }} </li>
                            <li class="txt-01">{{item.parents[0].name}}</li>
                            <li class="txt-01" v-if="item.attribute.collectionText != null && item.attribute.collectionText != undefined">{{ item.attribute.collectionText }}</li>
                          </ul>
                          <button type="button" class="btn-remove02" @click="removeCompare(item.content.id)"><span class="remove-char">{{ $t('PF.button.remove')}}</span></button>
                        </div>
                      </div>

                      <div class="tbl-box type02">
                        <table>
                          <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                          <colgroup>
                            <col style="width:auto">
                          </colgroup>
                          <tbody>

                          <tr v-if="item.attribute.thicknessText != '' && item.attribute.thicknessText != undefined && item.attribute.thicknessText != null">
                            <th scope="col">{{ $t('PD.text.Thickness')}}</th>
                          </tr>
                          <tr v-if="item.attribute.thicknessText != '' && item.attribute.thicknessText != undefined && item.attribute.thicknessText != null">
                            <td v-if="item.attribute.thicknessText.length > 1">{{ item.attribute.thicknessText.join(', ') }}</td>
                            <td v-else>{{ item.attribute.thicknessText[0] }}</td>
                          </tr>

                          <tr v-if="item.attribute.USEText != '' && item.attribute.USEText != undefined && item.attribute.USEText != null">
                            <th scope="col">{{ $t('PD.text.Use')}}</th>
                          </tr>
                          <tr v-if="item.attribute.USEText != '' && item.attribute.USEText != undefined && item.attribute.USEText != null">
                            <td v-if="item.attribute.USEText.length > 1">{{ item.attribute.USEText.join(', ')
                              .replace('Outdoor', 'Suitable for exterior use. Please check conditions about level of suitability and warranty per colour')
                                .replace('Indoor', 'Indoor')
                                 .replace('Kitchen and Bathroom', 'Suitable for Kitchen & Bathroom')
                              }}
                            </td>
                            <td v-else>{{ item.attribute.USEText[0] }}</td>
                          </tr>

                          <tr>
                            <th scope="col">{{ $t('PD.text.Pattern')}}</th>
                          </tr>
                          <tr>
                            <td>{{ item.attribute.patternText }}</td>
                          </tr>

                          <tr v-if="item.attribute.tonesText != '' && item.attribute.tonesText != undefined && item.attribute.tonesText != null">
                            <th scope="col">{{ $t('PF.text.Tones')}}</th>
                          </tr>
                          <tr v-if="item.attribute.tonesText != '' && item.attribute.tonesText != undefined && item.attribute.tonesText != null">
                            <td v-if="item.attribute.tonesText.length > 1">{{ item.attribute.tonesText.join(', ') }}</td>
                            <td v-else>{{ item.attribute.tonesText[0] }}</td>
                          </tr>

                          <tr v-if="item.attribute.notice == 'true'">
                            <th scope="col">{{ $t('PD.text.Notice')}}</th>
                          </tr>
                          <tr v-if="item.attribute.notice == 'true'">
                            <td class="tb-more-wrap" v-if="item.attribute.notice == 'true'">{{ $t('PD.text.Notice_brief')}}</td>
                          </tr>

                          <tr v-if="item.attribute.semiTranslucent == 'true'">
                            <th scope="col">{{ $t('PD.text.Semi_Translucent')}}</th>
                          </tr>
                          <tr v-if="item.attribute.semiTranslucent == 'true'">
                            <td v-if="item.attribute.semiTranslucent == 'true'">{{ $t('PD.text.Semi_Translucent_brief')}}</td>
                          </tr>

                          <tr v-if="item.attribute.recycledText != '' && item.attribute.recycledText != undefined && item.attribute.recycledText != null">
                            <th scope="col">{{ $t('PD.text.Recycled')}}</th>
                          </tr>
                          <tr v-if="item.attribute.recycledText != '' && item.attribute.recycledText != undefined && item.attribute.recycledText != null">
                            <td v-if="item.attribute.recycledText != null">{{ $t('PD.text.Recycled_brief')}} {{ item.attribute.recycledText }}</td>
                          </tr>

                          <tr v-if="item.attribute.marcelWanders == 'true'">
                            <th scope="col">{{ $t('PD.text.Designer')}}</th>
                          </tr>
                          <tr v-if="item.attribute.marcelWanders == 'true'">
                            <td v-if="item.attribute.marcelWanders == 'true'">{{ $t('PD.text.Designer_brief')}}</td>
                          </tr>

                          <tr v-if="item.attribute.lucent == 'true'">
                            <th scope="col">{{ $t('PD.text.Lucent')}}</th>
                          </tr>
                          <tr v-if="item.attribute.lucent == 'true'">
                            <td v-if="item.attribute.lucent == 'true'">{{ $t('PD.text.Lucent_brief')}}</td>
                          </tr>

                          <tr v-if="item.attribute.veined == 'true'">
                            <th scope="col">{{ $t('PD.text.Veined')}}</th>
                          </tr>
                          <tr v-if="item.attribute.veined == 'true'">
                            <td class="tb-more-wrap" v-if="item.attribute.veined == 'true'">{{ $t('PD.text.Veined_brief')}}</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </td>
                    <td v-if="compareList.length < 4">
                      <div class="btn-add hf-btn-add">
                        <button type="button" onclick="LayerPopup.close();"><span>{{ $t('PF.button.add_more')}}</span></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="btn-download">
                <button type="button" class="btn dwn load" @click="makePDF()"><span>{{ $t('PF.button.download') }}</span></button>
              </div>
              <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t('PCD.button.close')}}</button>
            </div>
          </div>
        </div>
        <!-- //eu popup : Compare -->

        <!-- popup : alert -->
        <div class="layer-popup layer-alert type02">
          <div class="popup pop-close">
            <div class="pop-alert inner">
              <div class="t-wrap">
                <div class="tit-box">
                  <p class="ctt ctt-t4 artTit">{{ $t('PF.popup.your_compare_is_full')}}</p>
                </div>

                <div class="txt-box">
                  <i class="ico-alert"></i>
                  <p class="tt-2">{{ $t('PF.popup.compare_tool')}}</p>

                  <div class="ctt ctt-t2">
                    {{ $t('PF.popup.compare_alert1')}}<br/>
                    {{ $t('PF.popup.compare_alert2')}}
                  </div>
                </div>
              </div>

              <div class="more-box ">
                <button type="button" id="replace" class="btn" @click="replace()"><span>{{ $t('PF.popup.replace')}}</span></button>
              </div>
              <div class="alert_txt">
                <button id="button editCompare" class="" @click="getCompareList()"><span>{{ $t('PF.popup.edit_products_in_compare')}}</span></button>
              </div>
              <button type="button" class="btn-close btn" onclick="LayerPopup.close();">{{ $t('PF.popup.replace')}}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : alert -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb" style="display: none">
          <div class="popup">
            <div class="scr-y">
              <!-- <div class="search">
                <input
                  id="textSearch"
                  type="text"
                  placeholder="Enter Item Number or Name"
                  @input="textSearch($event)"
                />
                <button
                  type="button"
                  id="searchBtn"
                  class="icon-search gray"
                  @click="textSearchDel()"
                >
                  search
                </button>
              </div> -->

              <!-- color / us | gl -->
              <div class="drop-list">
                <p class="tt-2">Filter By</p>
                <button
                  class="filter-clos"
                  @click="closeSearchPopup()"
                ></button>
                <ul>
                  <li class="on">

                    <!-- us / gl title -->
                    <div class="tit" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <span>{{ $t('PD.text.color')}}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.button.color')}}
                      </button>
                    </div>

                    <!-- eu / uk title -->
                    <div class="tit" v-if="nationCode != '_GL' && nationCode != '_US'">
                      <span>{{ $t('PF.text.Tones')}}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.text.Tones')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <ul class="i-chk type03">
                        <li v-for="lefthue in lefthue">
                          <label class="inp-chk chk-color">
                            <input
                              type="checkbox"
                              name="color"
                              :id="'hue_' + lefthue.id.value"
                              @change="
                                [
                                  fnSearch(
                                    'hue',
                                    lefthue.text,
                                    lefthue.id.value,
                                    'Color'
                                  ),
                                ]
                              "
                            />
                            <i v-if="lefthue.text === 'Other'"
                            ><span
                              :style="
                                  'background-image : url(images/products/' +
                                  lefthue.id.value +
                                  '.png)'
                                "
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <i v-else
                            ><span
                              :style="'background-color:#' + lefthue.id.value"
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <span>{{ lefthue.text }}</span>
                          </label>
                          <!-- 이방식으로 교체합니다-->
                        </li>
                      </ul>
                    </div>
                    <!-- color / us | gl end -->

                    <!-- tone / eu | uk -->
                    <div class="i-box type02 cont" style="display: block"  v-if="nationCode != '_GL' && nationCode != '_US'">
                      <ul class="i-chk type03">
                        <li v-for="lefthue in lefthue">
                          <label class="inp-chk chk-color">
                            <input
                              type="checkbox"
                              name="color"
                              :id="'hue_' + lefthue.id.value"
                              @change="
                                [
                                  fnSearch(
                                    'hue',
                                    lefthue.text,
                                    lefthue.id.value,
                                    'Tone'
                                  ),
                                ]
                              "
                            />
                            <i v-if="lefthue.text === 'Other'"
                            ><span
                              :style="
                                  'background-image : url(images/products/' +
                                  lefthue.id.value +
                                  '.png)'
                                "
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <i v-else
                            ><span
                              :style="'background-color:#' + lefthue.id.value"
                            ></span
                            >{{ lefthue.text }}</i
                            >
                            <span>{{ lefthue.text }}</span>
                          </label>
                          <!-- 이방식으로 교체합니다-->
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- tone / eu | uk end -->

                  <!-- Collection -->
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('PCD.text.collection')}}</span>
                      <button type="button" class="btn-view btn-collection">
                        {{ $t('PF.button.collection')}}
                      </button>
                    </div>
                    <div class="i-box type02 cont" style="display: block">
                      <ul>
                        <!--                      <li>
                                                <label class="inp-chk">
                                                  <input type="checkbox" checked id="CateAll" @click="[fnCateSearch('CateAll','CateAll')]">
                                                  <span>All</span>
                                                </label>
                                              </li>-->

                        <li v-for="coll in leftcollection">
                          <label
                            class="inp-chk"
                            v-if="coll.category.hidden === false"
                          >
                            <input
                              type="checkbox"
                              class="Collection"
                              :id="coll.category.id"
                              @change="
                                [
                                  fnCateSearch(
                                    coll.category.name,
                                    coll.category.id
                                  ),
                                ]
                              "
                            />
                            <span
                            >{{ coll.category.name
                              }}<strong
                                class="ico-g"
                                v-if="coll.category.name === 'Eden Plus'"
                              >{{ $t('PF.text.Recycled')}}</strong
                              ></span
                            >
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Collection -->

                  <!-- Patterns -->
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('PF.text.patterns')}}</span>
                      <button type="button" class="btn-view btn-patterns">
                        {{ $t('PF.button.patterns')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk type02">
                        <!--                      <li>
                                                <label class="inp-chk">
                                                  <input type="checkbox" checked id="PatternAll"  @click="[fnSearch('Pattern','PatternAll')]">
                                                  <span class="ico-all">All</span>
                                                </label>
                                              </li>-->

                        <li v-for="leftpattern in leftpattern">
                          <label class="inp-chk chk-thumb">
                            <input
                              type="checkbox"
                              class="pattern"
                              :id="'pattern_' + leftpattern.id.value"
                              @change="
                                [
                                  fnSearch(
                                    'pattern',
                                    leftpattern.text,
                                    leftpattern.id.value,
                                    'Pattern'
                                  ),
                                ]
                              "
                            />>
                            <span
                            ><i
                              :style="
                                  'background-image : url(images/products/' +
                                  leftpattern.id.value +
                                  '.png)'
                                "
                            ></i
                            >{{ leftpattern.text }}</span
                            >
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Patterns -->

                  <!-- Country -->
                  <li v-if="nationCode == '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.country')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.button.country')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftCountry in leftCountry">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="country"
                              :id="'country_' + leftCountry.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'country',
                                    leftCountry.text,
                                    leftCountry.id.value,
                                    'Country'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftCountry.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Country -->

                  <!-- New -->
                  <li class="on">
                    <div class="tit">
                      <span>{{ $t('PF.text.new')}}</span>
                      <button type="button" class="btn-view btn-new">{{ $t('PF.button.new')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul>
                        <li>
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="new"
                              :id="'isNew_true'"
                              @click="
                                [fnSearch('isNew', 'NEW COLORS', 'true', 'New')]
                              "
                            />
                            <span>{{ $t('PF.text.new_colors')}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- New -->

                  <!-- USE -->
                  <li v-if="nationCode != '_US' && nationCode != '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.Use')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.text.Use')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftUse in leftUse">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="USE"
                              :id="'USE_' + leftUse.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'USE',
                                    leftUse.text,
                                    leftUse.id.value,
                                    'USE'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftUse.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- USE -->

                  <!-- Recycled -->
                  <li v-if="nationCode != '_US' && nationCode != '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.Recycled')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.text.Recycled')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftRecycled in leftRecycled">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="recycled"
                              :id="'recycled_' + leftRecycled.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'recycled',
                                    leftRecycled.text,
                                    leftRecycled.id.value,
                                    'Recycled'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftRecycled.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Recycled -->

                  <!-- Thickness -->
                  <li v-if="nationCode != '_US' && nationCode != '_GL'">
                    <div class="tit">
                      <span>{{ $t('PF.text.Thickness')}}</span>
                      <button type="button" class="btn-view btn-country">
                        {{ $t('PF.text.Thickness')}}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block">
                      <ul class="i-chk">
                        <li v-for="leftThickness in leftThickness">
                          <label class="inp-chk">
                            <input
                              type="checkbox"
                              class="thickness"
                              :id="'thickness_' + leftThickness.id.value"
                              @click="
                                [
                                  fnSearch(
                                    'thickness',
                                    leftThickness.text,
                                    leftThickness.id.value,
                                    'Thickness'
                                  ),
                                ]
                              "
                            />
                            <span>{{ leftThickness.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Thickness -->

                  <!-- <li>
                      <div class="tit">
                          <span>Segments</span>
                          <button type="button" class="btn-view btn-segments">Segments</button>
                      </div>

                      <div class="l-box cont">
                          <ul>
                              <li v-for="(segName, index) in segNames" v-bind:key="index">
                                  <button type="button" @click="[fnSearch('Segments',segName)]">{{segName.name}}</button>
                              </li>
                          </ul>
                      </div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->

      </div>
      <!-- //container (product Finder) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>

</template>

<script>
import crliutils, { time } from 'crliutils'
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import ProductService from '@/server/product.service.js'
import GnbComponent from '@/components/Layout/GNB'
import InfiniteLoading from 'vue-infinite-loading'
import { getCategoryAttributeList } from '@/server/category'
import global from '@/common/global'
import _ from '@/common/helper/lodash'
import LikeService from '@/server/like.service'
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { forEach } from 'lodash/fp/_util'
import categoryService from '@/server/category.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    InfiniteLoading,
    GnbComponent
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      classValue: 'short-link type02',
      categoryUsId: [] /* 해당 카테고리 ID 값 가져오기. */,
      categoryUsName: [],
      categoryId: '',
      collectionList: [] /* 컬렉션 불러오자! */,
      checkCollectionList: [],
      collectionLength:
        '' /* 해당 컬렉션 길이 체크 (그룹 바이 컬렉션에 박을꺼임) */,
      contentList: [],
      contentLength: '' /* 해당 컨텐츠 길이 체크 ( All Product에 박을꺼임) */,
      searchText: '' /* 검색했을때 의 input 값 */,
      value: [],
      colorList: [],
      totGalList: [],
      totGalListLen: 0,
      pageSize: 16,
      pageIndex: 1,
      orderField: [],
      orderType: [],
      filterHue: [],
      brief: [],
      subject: '',
      subject2: '',
      pfBanImg: '',
      photoCredit: '',
      photoCredit2: '',

      /* Filters */
      filterNm: '',
      filterCd: '',
      filterNm1: '',
      filterCd1: '',
      filterNm2: '',
      filterCd2: '',

      /* left menu */
      leftcollection: [],
      lefthue: [],
      leftfnsh: [],
      leftpattern: [],
      leftCountry: [],

      /* left menu / EU/UK 전용 */
      leftUse: [],
      leftRecycled: [],
      leftThickness: [],

      limit: 0,
      scrollSize: 4,
      /* left menu */

      getSessionContent: [] /* GL : 불러오는 값 */,

      clickCollIds: [],
      filterCollList: [],
      sortBy: 'Sort by',

      mount: '',
      totalPage: 0,

      filterShow: false,

      /* compare */
      compareArr: [],
      compareRes: { list: [] },
      compareDetail: [],
      compareList: [],
      compareId: '',
      cateCode: '',
      replaceId: '',

      /* EU / UK 스펙 */

      thickness: [],
      tones: [],
      USE: [],
      notice: '',
      semi_translucent_colour: '',
      recycled: '',
      designer_series: '',
      lucent: '',
      veined: '',

      indoor: '',
      outdoor: '',
      kitchen_and_bathroom: '',

      /* EU / UK 스펙 */

      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }, // 1
          {
            operator: 'OR',
            values: []
          }, // 2
          {
            operator: 'OR',
            values: []
          }, // 3
          {
            operator: 'OR',
            values: []
          }, // 4
          {
            operator: 'OR',
            values: []
          }, // 5
          {
            operator: 'OR',
            values: []
          }, // 6
          {
            operator: 'OR',
            values: []
          }, // 7
          {
            operator: 'OR',
            values: []
          } // 8
        ]
      },
      titleSeo: ''
    }
  },
  watch: {
    compareArr: function () {
      sessionStorage.setItem('himacsCompare' + this.nationCode, JSON.stringify(this.compareArr))
    },
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */

    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      if (sessionStorage.getItem('himacsCompare' + this.nationCode) != null) {
        t.compareArr = JSON.parse(sessionStorage.getItem('himacsCompare' + this.nationCode))
      }
    }

    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },
  async mounted () {
    const t = this

    /* 해당하는 배너 US 정보를 가져오자! */
    const banCateUs = await ProductService.getCategoryAttr(
      'BAN_HIMACS_CAT' + t.nationCode
    )
    t.brief = banCateUs.data.attribute.brief
    t.subject = banCateUs.data.attribute.subject
    if (banCateUs.data.attribute.subject2 != null && banCateUs.data.attribute.subject2 != undefined && banCateUs.data.attribute.subject2 != '') {
      t.subject2 = banCateUs.data.attribute.subject2
    }
    t.pfBanImg = banCateUs.data.attribute.pf_image
    t.photoCredit = banCateUs.data.attribute.photo_credit
    t.photoCredit2 = banCateUs.data.attribute.photo_credit2
    /* 배너 & 배너내용 가져오자! */

    // 기존 mounted
    /* 해당하는 HIMACS US 컨텐츠 정보를 가져오자! */
    const categoryUs2 = await ProductService.getCategoryCode(
      'HIMACS_COLOR' + t.nationCode
    )
    // t.categoryUsId.push(categoryUs2.data.object.id);
    t.categoryId = categoryUs2.data.object.id

    const getcategorycoll = await getCategoryAttributeList(t.categoryId)

    t.leftcollection = getcategorycoll.data.list

    let dicList = ''

    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'HIMACS_HUE',
        'HIMACS_PATTERN',
        'HIMACS_CONTY'
      ])
    } else {
      dicList = await ProductService.getDictionaryValueList([
        'HIMACS_EU_TONES',
        'HIMACS_PATTERN_EU',
        'HIMACS_CONTY',
        'HIMACS_EU_USE',
        'HIMACS_EU_RECYCLED',
        'HIMACS_EU_THICKNESS'
      ])
    }

    t.lefthue = dicList.data.dicList[0].list /* HIMACS_HUE DictionaryValue */
    t.leftpattern =
      dicList.data.dicList[1].list /* HIMACS_PATTERN DictionaryValue */

    t.leftCountry =
      dicList.data.dicList[2].list /* HIMACS_CONTY DictionaryValue */

    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      t.leftUse = dicList.data.dicList[3].list /* HIMACS_USE DictionaryValue */
      t.leftRecycled = dicList.data.dicList[4].list /* HIMACS_RECYCLED DictionaryValue */
      t.leftThickness = dicList.data.dicList[5].list /* HIMACS_THICKNESS DictionaryValue */
    }

    /* 해당하는 배너 US 정보를 가져오자! */
    t.categoryUsId.push(t.categoryId)
    // await this.getItem(t.pageIndex, t.pageSize);
    const collectionList2 = await ProductService.getCategoryAttributeList(
      categoryUs2.data.object.id
    )
    t.collectionList = collectionList2.data.list
    t.checkCollectionList = collectionList2.data.list

    const totLength = _.filter(this.collectionList, function (o) {
      return o.category.hidden != true
    })

    t.collectionLength = totLength.length

    const filterCd = this.$route.params.filterCd
    const filterCd1 = this.$route.params.filterCd1
    const filterNm1 = this.$route.params.filterNm1

    // title seo name
    if (filterCd !== undefined) {
      this.titleSeo = filterCd
    } else if (filterCd === undefined && filterCd1 !== undefined) {
      this.titleSeo = filterCd1
    } else if (filterNm1 !== undefined && filterNm1 === 'isnew') {
      this.titleSeo = 'New Colors - Solid Surface Countertops'
    } else {
      this.titleSeo = 'himacs-solid-surface'
    }

    if (location.pathname.indexOf('/himacs-solid-surface/himacs-finder') !== -1) {
      // URL 조건 1개 param
      this.filterNm = this.$route.params.filterNm
      this.filterCd = this.$route.params.filterCd
      // URL 조건 2개 param
      this.filterNm1 = this.$route.params.filterNm1
      this.filterCd1 = this.$route.params.filterCd1
      this.filterNm2 = this.$route.params.filterNm2
      this.filterCd2 = this.$route.params.filterCd2

      if (this.filterNm1 == 'collection') {
        const seoFilterRes = await categoryService.getCategoryBySeo(this.filterCd1, 'COL', 'HIMACS')
        this.filterCd1 = seoFilterRes.data.object.name
      }
      if (this.filterNm2 == 'collection') {
        const seoFilterRes = await categoryService.getCategoryBySeo(this.filterCd2, 'COL', 'HIMACS')
        this.filterCd2 = seoFilterRes.data.object.name
      }
    } else {
      // URL 조건 1개 param
      this.filterNm = this.$route.params.filterNm
      this.filterCd = this.$route.params.filterCd

      // URL 조건 2개 param
      this.filterNm1 = this.$route.params.filterNm1
      this.filterCd1 = this.$route.params.filterCd1
      this.filterNm2 = this.$route.params.filterNm2
      this.filterCd2 = this.$route.params.filterCd2
    }

    if (this.filterNm != undefined || this.filterNm1 != undefined) {
      const himacsFinder = JSON.parse(sessionStorage.getItem('himacsFilter' + this.nationCode))
      if (himacsFinder == undefined || himacsFinder == null) {
        // URL 조건 1개 일 경우
        if (this.filterNm != undefined) {
          await this.findFilter(this.filterNm, this.filterCd)

          // URL 조건 2개 일 경우
        } else if (this.filterNm1 != undefined) {
          await this.findFilter(this.filterNm1, this.filterCd1)
          await this.findFilter(this.filterNm2, this.filterCd2)
        }
      } else {
        if (himacsFinder.length === 0) {
          await t.getItem(1, 16)
        } else {
          this.bringFilter()
          await t.getItem(1, 16)
        }
      }
    } else {
      this.bringFilter()
      // 조건 없을 경우
      await t.getItem(1, 16)
    }

    // const categoryUs = await ProductService.getCategoryCode('BAN_HIMACS_CAT_US')

    // const contentUs = await ProductService.getContentList(categoryUs2.data.object.id)
    // console.log("contentUs", contentUs)
    // t.collectionList = contentUs.data.parents[0].name
    // t.collectionLength = contentUs.data.list.length

    this.mount = 'mounted'

    this.$nextTick(function () {
      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      $(document).on('click', '.select-view .btn-del', function () {
        if ($('.select-view > ul > li').length === 1) {
          $('.select-view > ul').remove()
          $('.btn-all').remove()
        } else {
          $(this).closest('.sel').remove()
        }
      })
      $(document).on('click', '.btn-all button', function () {
        $('.select-view > ul').remove()
        $('.btn-all').remove()
      })
    })
  },
  methods: {
    loginalertLike () {
      alert(this.$t('PCD.alert.loginLike'))
    },

    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },

    findFilter: async function (filterNm, filterCd) {
      const t = this
      let collectionId
      // let collectionId
      if (filterNm == 'collection') {
        if (t.nationCode !== '_US' && t.nationCode !== '_GL') {
          if (filterCd == 'Aurora') {
            filterCd = 'Aurora & Marmo'
          }
          if (filterCd == 'Granite') {
            filterCd = 'Granite, Sparkle, Quartz, Sand & Pearl'
          }
          if (filterCd == 'Solid') {
            filterCd = 'Solids'
          }
        }
        const collectionId = _.filter(t.leftcollection, function (o) {
          return o.category.name == filterCd
        })
        // const seoFilterRes = await categoryService.getCategoryBySeo(filterCd, 'COL', 'HIMACS');
        // if (t.nationCode == '_US' || t.nationCode == '_GL') {
        //   if (filterCd == "eden-plus") {
        //     collectionId = _.filter(t.leftcollection, function (o) {
        //       return o.category.code == "COL_EDEN" + t.nationCode
        //     })
        //   } else {
        //     collectionId = _.filter(t.leftcollection, function (o) {
        //       return o.category.name.toLowerCase() == filterCd
        //     })
        //   }
        // } else {
        //   collectionId = _.filter(t.leftcollection, function (o) {
        //     return o.category.name == filterCd
        //   })
        // }
        this.fnCateSearch(filterCd, collectionId[0].category.id, 'click')
      } else if (filterNm == 'hue') {
        if (t.nationCode != '_US' && t.nationCode != '_GL') {
          const hueCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
          const hueId = _.filter(t.lefthue, function (o) {
            return o.text == hueCd
          })
          $('#hue_' + hueId[0].id.value).prop('checked', true)
          this.fnSearch('hue', hueId[0].text, hueId[0].id.value, 'Color', 'first')
        } else {
          const hueCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
          const hueId = _.filter(t.lefthue, function (o) {
            return o.text == hueCd
          })
          $('#hue_' + hueId[0].id.value).prop('checked', true)
          this.fnSearch('hue', hueId[0].text, hueId[0].id.value, 'Color', 'first')
        }
      } else if (filterNm === 'pattern') {
        const patternCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
        const patternId = _.filter(t.leftpattern, function (o) {
          return o.text == patternCd
        })
        $('#pattern_' + patternId[0].id.value).prop('checked', true)
        this.fnSearch('pattern', patternId[0].text, patternId[0].id.value, 'Pattern', 'first')
      } else if (filterNm === 'isNew' || filterNm === 'isnew') {
        $('#isNew_true').click()
      }
    },
    bringFilter: function () {
      // 뒤로가기 세션
      const himacsFilter = JSON.parse(sessionStorage.getItem('himacsFilter' + this.nationCode))
      if (himacsFilter !== undefined && himacsFilter != null) {
        for (let i = 0; i < himacsFilter.length; i++) {
          if (himacsFilter[i].subject === 'Collection') {
            $('#' + himacsFilter[i].id).click()
          } else {
            $('#' + himacsFilter[i].subject + '_' + himacsFilter[i].id).click()
          }
        }
      }
    },
    gotoPD: function (item) {
      if (item.orgContent != null) {
        location.href = 'products/himacs/' + item.orgContent.title + '/' + item.content.id
      } else {
        location.href = 'products/himacs/' + item.content.title + '/' + item.content.id
      }
    },

    fnSearch: async function (searchCd, selectSeach, value, type, mount) {
      const t = this
      // if(selectSeach == searchCd+'All'){
      //   if($('#' + selectSeach).is(":checked")){
      //     $("."+searchCd).prop("checked", false);
      //     _.remove(t.value, function(c) {
      //       let t = c.split('_')
      //       return t[0] == searchCd
      //     })
      //     _.remove(t.colorList, function(v) {
      //       return v.subject == searchCd
      //     })
      //   }
      // } else {
      //   let checkBoxTotal = $('input:checkbox[class='+searchCd+']').length;
      //   let checkedCount = $('input:checkbox[class='+searchCd+']:checked').length;
      //   if(checkBoxTotal == checkedCount){
      //     _.remove(t.dictionaryCondition.list, function(c) {
      //       _.remove(c.values, function(v) {
      //         return v.id == searchCd
      //       })
      //     })
      //     _.remove(t.colorList, function(v) {
      //       return v.subject == searchCd
      //     })
      //     $("."+searchCd).prop("checked", false);
      //     $("#"+searchCd+"All").prop('checked', true);
      //   } else if (checkedCount > 0){
      //     $("#"+searchCd+"All").prop('checked', false);
      //   } else {
      //     $("#"+searchCd+"All").prop('checked', true);
      //   }
      if ($('#' + searchCd + '_' + value).is(':checked')) {
        if (searchCd == 'hue') {
          if (t.nationCode != '_US' && t.nationCode != '_GL') {
            searchCd = 'tones'
          }

          t.dictionaryCondition.list[0].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'pattern') {
          t.dictionaryCondition.list[1].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'country') {
          t.dictionaryCondition.list[2].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'isNew') {
          t.dictionaryCondition.list[3].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'USE') { // 4 에는 컬렉션 들어감
          t.dictionaryCondition.list[5].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'recycled') {
          t.dictionaryCondition.list[6].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'thickness') {
          t.dictionaryCondition.list[7].values.push({
            id: searchCd,
            value: value
          })
        }
        // t.value.push(value)
        t.colorList.push({
          subject: searchCd,
          name: selectSeach,
          id: value,
          type: type
        })
      } else {
        _.remove(t.colorList, function (v) {
          return v.subject == searchCd && v.id == value
        })
        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == value && v.id == searchCd
          })
        })
      }
      // }

      await t.getItem(1, 16)

      // t.pageIndex = 1
      // t.pageSize = 16
      // t.contentList = []

      // if(mount != 'first'){
      //   infiniteHandler 초기화
      //   this.$refs.infiniteLoading.stateChanger.reset()
      // }
    },

    textSearch (event) {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/delete.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/delete.png) center center' })
      }
      this.searchText = event.target.value

      this.getItem(1, 16)

      // this.pageIndex = 1
      // this.pageSize = 16
      // this.contentList = []

      // infiniteHandler 초기화
      // this.$refs.infiniteLoading.stateChanger.reset()
    },

    textSearchDel () {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/ico_search.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/ico_search.png) center center ' })
      }
      this.searchText = ''
      if (this.isMobile()) {
        $('#textSearchM').val('')
      } else {
        $('#textSearch').val('')
      }

      this.getItem(1, 16)

      // this.pageIndex = 1
      // this.pageSize = 16
      // this.contentList = []

      // infiniteHandler 초기화
      // this.$refs.infiniteLoading.stateChanger.reset()
    },

    /* Compare 시작 점 */

    addCompare (id) {
      const _this = this
      if (_this.compareArr.length < 5) {
      } else {
        _this.replaceId = id
        $('#' + id).prop('checked', false)
        this.removeCompare(id)
        LayerPopup.open('layer-alert')
        return false
      }
    },
    removeCompare (id) {
      const _this = this
      if (_this.compareList != null) {
        const idx = _this.compareList.findIndex(function (v) {
          return v.content.id === id
        })
        _this.compareArr.splice(idx, 1)
        _this.compareList.splice(idx, 1)
      }
    },
    async getCompareList () {
      const _this = this
      const target = document.getElementById('replace')
      _this.compareList = []
      _this.compareRes = _this.compareArr
      LayerPopup.close() // alert 클로즈
      _this.compareDetail = await ProductService.getContentByIds(_this.compareRes)
      _this.compareList = _this.compareDetail.data
      LayerPopup.open('layer-compare')
      if (target.disabled) {
        target.disabled = false
      }
      return false
    },
    async replace () {
      const _this = this
      const target = document.getElementById('replace')
      target.disabled = true
      if (_this.compareArr != null) {
        _this.compareArr.splice(0, 1)
        _this.compareList.splice(0, 1)
      }
      _this.compareArr.push(_this.replaceId)
      _this.getCompareList()
    },

    /* Compare 끝 */

    fnCateSearch: async function (name, id, type) {
      const t = this
      if (name == 'eden-plus') {
        name = 'Eden Plus'
      } else {
        name = name.charAt(0).toUpperCase() + name.slice(1)
      }
      if (name == 'CateAll') {
        if ($('#CateAll').is(':checked')) {
          $('.Collection').prop('checked', false)
        }
        t.categoryUsId = []
        _.remove(t.colorList, function (c) {
          return c.subject == 'Collection'
        })
        t.categoryUsId.push(t.categoryId)
        t.filterCollList = []
        t.clickCollIds = []
        t.checkCollectionList = t.collectionList
        t.collectionLength = t.checkCollectionList.length
      } else {
        if (type == 'click' && $('#' + id).is(':checked') == false) {
          $('#' + id).prop('checked', true)
        } else if (type == 'click' && $('#' + id).is(':checked') == true) {
          $('#' + id).prop('checked', false)
        }
        if ($('#' + id).is(':checked')) {
          const checkBoxTotal = $('input:checkbox[class=Collection]').length
          const checkedCount = $(
            'input:checkbox[class=Collection]:checked'
          ).length

          if (checkBoxTotal == checkedCount) {
            _.remove(t.colorList, function (v) {
              return v.subject == 'Collection'
            })
            t.categoryUsId = []
            t.categoryUsId.push(t.categoryId)
            $('#CateAll').prop('checked', true)
            $('.Collection').prop('checked', false)
          } else if (checkedCount > 0) {
            $('#CateAll').prop('checked', false)
            if (t.categoryUsId[0] == t.categoryId) {
              t.categoryUsId = []
            }

            t.categoryUsId.push(id)
            t.categoryUsName.push(name)
            t.colorList.push({ subject: 'Collection', name: name, id: id })

            t.dictionaryCondition.list[4].values.push({
              id: 'collection',
              value: id
            })

            // 필터링된 id
            this.clickCollIds.push(id)
            t.filtering = _.filter(t.collectionList, function (o, idx) {
              return o.category.id === id
            })
            // 첫검색할땐 필터링된 배열 리셋
            if (this.clickCollIds.length === 1) {
              t.filterCollList = []
              t.filterCollList.push(t.filtering[0])
            } else {
              t.filterCollList.push(t.filtering[0])
            }
            t.checkCollectionList = t.filterCollList
            t.collectionLength = t.checkCollectionList.length
          } else {
            $('#CateAll').prop('checked', true)
          }
        } else {
          const checkedCount = $(
            'input:checkbox[class=Collection]:checked'
          ).length
          if (checkedCount == 0) {
            $('#CateAll').prop('checked', true)
          }

          _.remove(t.categoryUsId, function (c) {
            return c == id
          })
          _.remove(t.colorList, function (v) {
            return v.subject == 'Collection' && v.id == id
          })

          _.remove(t.dictionaryCondition.list, function (c) {
            _.remove(c.values, function (v) {
              return v.value == id && v.id == 'collection'
            })
          })

          t.clickCollIds = _.filter(t.clickCollIds, function (o) {
            return o !== id
          })
          if (t.clickCollIds.length === 0) {
            t.checkCollectionList = t.collectionList
          } else {
            // 필터링 된 collectionList에서 선택한 collection 값 삭제
            t.checkCollectionList = _.filter(
              t.checkCollectionList,
              function (o) {
                return o.category.id !== id
              }
            )
          }
          t.collectionLength = t.checkCollectionList.length
        }
      }
      if ($('#btn-tab01').attr('class') != 'btn-tab on') {
        $('#btn-tab01').click()
      }

      await t.getItem(1, 16)

      // t.pageIndex = 1
      // t.pageSize =16
      // t.contentList = []

      // infiniteHandler 초기화
      // this.$refs.infiniteLoading.stateChanger.reset()
    },
    colorDelete: async function (subject, name, id) {
      const t = this

      _.remove(t.colorList, function (v) {
        return v.subject == subject && v.id == id
      })

      if (subject === 'Collection') {
        $('#' + id).prop('checked', false)
        const checkedCount = $('input:checkbox[class=Collection]:checked').length
        if (checkedCount == 0) {
          $('#CateAll').prop('checked', true)
        }
        _.remove(t.categoryUsId, function (c) {
          return c == id
        })

        t.clickCollIds = _.filter(t.clickCollIds, function (o) {
          return o !== id
        })
        if (t.clickCollIds.length === 0) {
          t.checkCollectionList = t.collectionList
        } else {
          // 필터링 된 collectionList에서 선택한 collection 값 삭제
          t.checkCollectionList = _.filter(t.checkCollectionList, function (o) {
            return o.category.id !== id
          })
        }
        t.collectionLength = t.checkCollectionList.length

        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == id && v.id == 'collection'
          })
        })
      } else {
        if (t.nationCode != '_US' && t.nationCode != '_GL' && subject == 'tones') {
          subject = 'hue'
        }

        $('#' + subject + '_' + id).prop('checked', false)
        const checkedCount = $(
          'input:checkbox[class=' + subject + ']:checked'
        ).length
        if (checkedCount == 0) {
          $('#' + subject + 'All').prop('checked', true)
        }
        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            if (t.nationCode != '_US' && t.nationCode != '_GL' && subject == 'hue') {
              subject = 'tones'
            }
            return v.value == id
          })
        })
      }
      await t.getItem(1, 16)

      // t.pageIndex = 1
      // t.pageSize =16
      // t.contentList = []

      // infiniteHandler 초기화
      // this.$refs.infiniteLoading.stateChanger.reset()
    },

    // 무제한 스크롤 숨김
    // async infiniteHandler ($state)  {
    //   const t = this;
    //
    //   const {data} = await ProductService.getDictionaryCondition([t.categoryId], t.dictionaryCondition, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType, ['title', 'search_text'])
    //
    //     t.contentLength = data.totalCount
    //     t.totalPage = data.totalPage
    //     t.totalCount = data.totalCount
    //
    //     setTimeout(() => {
    //       //console.log("t.pageIndex <= t.totalPage", t.pageIndex, Math.floor((t.totalCount-12)/4))
    //
    //       // console.log("t.contentList.length <= t.totalCount", t.contentList.length, t.totalCount)
    //       // console.log("t.contentList", t.contentList)
    //
    //       //if(t.pageIndex <= Math.floor((t.totalCount-12)/4)) {
    //       if(t.contentList.length < t.totalCount) {
    //           t.pageSize = 4
    //
    //         t.pageIndex += 1
    //         if(t.pageIndex === 2){
    //           t.pageIndex = 4
    //         }
    //
    //         for (let i = 0; i < data.list.length; i++) {
    //           t.contentList.push(data.list[i])
    //         }
    //
    //         $state.loaded()
    //
    //       } else {
    //           $state.complete();
    //         }
    //     }, 2500)
    //
    // },

    // 리스트 개수 변경 버튼 숨김
    // listType(pageIndex, pageSize, scrollSize){
    //   let t = this;
    //   t.scrollSize = scrollSize;
    //   //this.$refs.infiniteLoading.stateChanger.reset()
    //   this.$nextTick(function(){
    //
    //     t.pageIndex = pageIndex
    //     t.pageSize = pageSize
    //     t.contentList = []
    //
    //     //infiniteHandler 초기화
    //     //this.$refs.infiniteLoading.stateChanger.reset()
    //
    //     t.getItem(pageIndex,pageSize)
    //   })
    // },

    async getItem (pageIndex, pageSize) {
      const t = this
      if (t.categoryUsId == '') {
        t.categoryUsId.push(t.categoryId)
      }
      t.pageIndex = 1
      t.pageSize = pageSize

      if (t.colorList !== undefined) {
        sessionStorage.setItem('himacsFilter' + this.nationCode, JSON.stringify(t.colorList))
      }

      // let res = await ProductService.getContentListTapField(t.categoryUsId, t.value, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType)
      const res = await ProductService.getDictionaryCondition(
        t.categoryUsId,
        t.dictionaryCondition,
        t.searchText,
        t.pageIndex,
        t.pageSize,
        t.orderField,
        t.orderType
      )
      t.contentList = res.data.list
      t.contentList.forEach((content, index) => {
        const iconList = []
        const int = 0
        if (content.attribute.lucent == 'true') {
          iconList.push({ id: 'lucent' })
        }
        if (content.parents[0].name.includes('Exteria')) {
          iconList.push({ id: 'exterior' })
        }
        if (content.attribute.veined == 'true') {
          iconList.push({ id: 'veined' })
        }
        if (content.attribute.semiTranslucent == 'true') {
          iconList.push({ id: 'translucent' })
        }
        if (content.attribute.recycled != null && content.attribute.recycled != undefined && content.attribute.recycled.length != 0) {
          iconList.push({ id: 'recycled' })
        }
        if (content.attribute.notice == 'true') {
          iconList.push({ id: 'notice' })
        }
        content.iconList = iconList
      })
      t.contentLength = res.data.totalCount
    },
    async clearAll () {
      const t = this
      t.categoryUsId = []
      t.filterCollList = []
      t.colorList = []
      t.clickCollIds = []
      t.dictionaryCondition.list[0].values = []
      t.dictionaryCondition.list[1].values = []
      t.dictionaryCondition.list[2].values = []
      t.dictionaryCondition.list[3].values = []
      t.dictionaryCondition.list[4].values = [] // 컬렉션 초기화
      t.dictionaryCondition.list[5].values = [] // USE 초기화
      t.dictionaryCondition.list[6].values = [] // RECYCLED 초기화
      t.dictionaryCondition.list[7].values = [] // THICKNESS 초기화
      t.categoryUsId = []
      t.categoryUsId.push(t.categoryId)
      t.checkCollectionList = t.collectionList
      t.collectionLength = t.checkCollectionList.length
      $('input:checkbox').prop('checked', false)
      $("input:checkbox[id*='All']").prop('checked', true)

      await t.getItem(1, 16)

      // t.pageIndex = 1
      // t.pageSize =16
      // t.contentList = []

      // infiniteHandler 초기화
      // this.$refs.infiniteLoading.stateChanger.reset()
    },
    async sortByChange (text, type, field) {
      const _this = this
      _this.sortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.getItem(1, 16)

      // _this.pageIndex = 1
      // _this.pageSize =16
      // _this.contentList = []

      // infiniteHandler 초기화
      // this.$refs.infiniteLoading.stateChanger.reset()
    },

    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },

    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode != '_US') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (o.nationCode == t.nationCode) {
              t.getSessionContent.push(o.id)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    openCart () {
      this.$root.$refs.cartView.openPopup()
    },

    async loadMore (index) {
      const t = this

      t.pageIndex += 1
      t.pageSize = 16

      const res = await ProductService.getDictionaryCondition(
        t.categoryUsId,
        t.dictionaryCondition,
        t.searchText,
        t.pageIndex,
        t.pageSize,
        t.orderField,
        t.orderType,
        ['search_text']
      )
      const seeMoreContentList = res.data.list
      seeMoreContentList.forEach((content, index) => {
        const iconList = []
        const int = 0
        if (content.attribute.lucent == 'true') {
          iconList.push({ id: 'lucent' })
        }
        if (content.parents[0].name.includes('Exteria')) {
          iconList.push({ id: 'exterior' })
        }
        if (content.attribute.veined == 'true') {
          iconList.push({ id: 'veined' })
        }
        if (content.attribute.semiTranslucent == 'true') {
          iconList.push({ id: 'translucent' })
        }
        if (content.attribute.recycled != null && content.attribute.recycled != undefined && content.attribute.recycled.length != 0) {
          iconList.push({ id: 'recycled' })
        }
        if (content.attribute.notice == 'true') {
          iconList.push({ id: 'notice' })
        }
        content.iconList = iconList
      })
      for (let i = 0; i < seeMoreContentList.length; i++) {
        t.contentList.push(seeMoreContentList[i])
      }
      t.contentLength = res.data.totalCount
    },

    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        navigator.userAgent
      )
    },

    clickFilter () {
      const element = document.getElementById('filterSearch')

      if (this.filterShow == false) {
        element.style.cssText = 'z-index: 99999999'
      } else {
        element.style.cssText = 'display : none'
      }

      return this.filterShow = !this.filterShow
    },

    makePDF () {
      html2canvas($('#pdf_wrap')[0], {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight
      }).then(function (canvas) {
        var filename = 'HIMACS_COMPARE_' + Date.now() + '.pdf'
        var doc = new jsPDF('p', 'mm', 'a4')
        var imgData = canvas.toDataURL('image/png')
        var imgWidth = 210
        var pageHeight = 295
        var imgHeight = canvas.height * imgWidth / canvas.width
        var heightLeft = imgHeight
        var position = 0
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
        window.scrollTo(0, 0)
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(filename)
      })
    },
    gotolink (url) {
      window.open(url)
    },
    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      return JSON.stringify({
        ecom_prd_id: data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand: data.parents[1].name,
        ecom_prd_category: "", // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    }
  }
}

</script>
