/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export default {
  getImgLibItemArr (state) {
    let result
    try {
      result = JSON.parse(sessionStorage.getItem('imgLibItem'))
    } catch (e) {
      console.log(e)
      result = []
    }
    if (result === null) {
      result = []
    }
    return result
  }
}
