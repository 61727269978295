<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content pros-view">
        <!-- location -->
        <div class="location">
            <ul>
                <li class="ico-home">Home</li>
                <li>Pros</li>
                <li>For Installer</li>
            </ul>
        </div>
        <!-- //location -->

        <!-- container (For Installer) -->
        <div class="container for-box">
            <div class="w-con-02">
                <div class="tab-ui tab-ui-dark">
                  <ul class="btns02">
<!--                            <li class="btn-tab on" id="btn-tab01" data-tab="tab01"><button type="button">HFLOR</button></li>-->
<!--                            <li class="btn-tab" id="btn-tab02" data-tab="tab02"><button type="button">HIMACS</button></li>-->
                    <li class="btn-tab" :id="'btn-tab0'+(index+1)" v-for="(tab, index) in tabCateList" v-bind:key="index">
                      <button type="button" @click="fnTab(tab)">
                        {{tab.name}}
                      </button>
                    </li>
                  </ul>

                    <!-- HFLOR -->
                    <div class="tab-con tab01 on">
                        <div class="guide-box">
                            <div class="g-list col-02">

                              <div class="bd-gray" v-for="gal in totGalList">
                                <strong>{{ gal.attribute.document_pickContent.content.title }}</strong>
                                <div class="col-02">
                                  <div class="txt-box">
                                    <div class="t-type-01">
                                      {{ gal.attribute.document_pickContent.attribute.doctypeText }}
                                    </div>
                                    <button type="button" @click="downloadFile(gal.attribute.document_pickContent.attribute.doc_file)" >Download</button>
                                  </div>

                                  <div class="img-box" v-if="gal.attribute.document_pickContent.attribute.doc_thumb == null || gal.attribute.document_pickContent.attribute.doc_thumb == '' ">
                                    <img src="images/pros/@img_03.png" alt="2021 Viatera Fabrication Guide" />
                                  </div>

                                  <div class="img-box" v-else>
                                    <img :src="gal.attribute.document_pickContent.attribute.doc_thumb" />
                                  </div>

                                </div>
                              </div>

                            </div>
                        </div>
                    </div>
                    <!-- //HFLOR -->

<!--                  <PageComponent v-for="i in totGalList" v-bind:totGalListLen = i> {{ totGalListLen }}</PageComponent>-->

                  <!-- HIMACS -->
                    <div class="tab-con tab02">
                        <div class="guide-box">
                            <div class="g-list col-02">

                              <div class="bd-gray" v-for="gal in totGalList">
                                {{ gal.length }}
                                <strong>{{ gal.attribute.document_pickContent.content.title }}</strong>
                                <div class="col-02">
                                  <div class="txt-box">
                                    <div class="t-type-01">
                                      {{ gal.attribute.document_pickContent.attribute.doctypeText }}
                                    </div>
                                    <button type="button" @click="downloadFile(gal.attribute.document_pickContent.attribute.doc_file)" >Download</button>
                                  </div>

                                  <div class="img-box" v-if="gal.attribute.document_pickContent.attribute.doc_thumb == null || gal.attribute.document_pickContent.attribute.doc_thumb == '' ">
                                    <img src="images/pros/@img_03.png" alt="2021 Viatera Fabrication Guide" />
                                  </div>

                                  <div class="img-box" v-else>
                                    <img :src="gal.attribute.document_pickContent.attribute.doc_thumb" />
                                  </div>

                                </div>
                              </div>

                            </div>
                        </div>
                    </div>
                    <!-- //HIMACS -->
                </div>
            </div>
        </div>
        <!-- //container (For Installer) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
</div><!-- //wrap -->
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import _ from '@/common/helper/lodash'
import GnbComponent from '@/components/Layout/GNB'
import InfiniteLoading from 'vue-infinite-loading'
import global from '@/common/global'
import ProductService from '@/server/product.service'
import { getCategoryCode } from '@/server/category'
import InspirationService from '@/server/inspiration.service'
import PageComponent from '@/views/pros/components/installerComponent'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    PageComponent,
    GnbComponent,
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      nationCode: [],
      tabCateList: [],
      searchValues: [],
      pageSize: 9999,
      pageIndex: 1,
      totGalListLen: [],
      totGalList: [],
      childIds: []
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode
  },
  async mounted () {
    const t = this

    // #######################카테고리 탭
    const cateList = await ProductService.getCateChildren('DEAL' + t.nationCode)
    t.tabCateList = cateList.data

    const categoryName = this.$route.params.category_name

    if (categoryName == 'HFLOR' || categoryName == 'HIMACS') {
      t.childIds = []
      $('.btn-tab').removeClass('on')
      if (categoryName == 'HFLOR') {
        this.$nextTick(function () {
          $('#btn-tab01').click()
          _.forEach(t.tabCateList, (v) => {
            if (v.name == 'HFLOR') {
              t.childIds.push(v.id)
            }
          })
          this.getItem()
        })
      }
      if (categoryName == 'HIMACS') {
        this.$nextTick(function () {
          $('#btn-tab02').click()
          _.forEach(t.tabCateList, (v) => {
            if (v.name == 'HIMACS') {
              t.childIds.push(v.id)
            }
          })

          this.getItem()
        })
      }
    } else {
      this.$nextTick(function () {
        t.childIds.push(t.tabCateList[0].id)
        $('#btn-tab01').addClass('on')
        $('#btn-tab01').click()
        this.getItem()
      })
    }
  },
  methods: {

    fnTab: async function (data, event) {
      const t = this
      this.tagList = []
      this.selectedTab = data.code

      this.childIds = []
      this.childIds.push(data.id)

      this.pageIndex = 1
      this.pageSize = 9999
      this.getItem()
    },

    async getItem () {
      const t = this
      this.res = await InspirationService.getContentListSearch(this.childIds, this.searchText, this.pageIndex, this.pageSize)
      t.totGalList = this.res.data.list
      t.totGalListLen = this.res.data.totalCount
    },

    downloadFile (file) {
      const url = this.$imagePath + file
      try {
        const element = document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        element.setAttribute('target', file)
        element.click()
      } catch (error) {
        console.log(error)
      }
    }

    /*    infiniteHandler($state) {

      setTimeout(() => {
        console.log(this.totGalListLen)
        if (this.pageIndex < totGalListLen) {
          this.pageIndex += 1
          $state.loaded()
        } else {
          $state.complete()
        }
      }, 3000)
    }, */

  }

}
</script>
