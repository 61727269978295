var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "softwhere-point-image", style: _vm.flexDirection },
    [
      _c("img", { staticClass: "image", attrs: { src: _vm.image } }),
      _c("div", { staticClass: "text-area" }, [
        _c("img", {
          staticClass: "point",
          style: _vm.pointPosition,
          attrs: { src: _vm.point },
        }),
        _c("pre", { staticClass: "title", style: _vm.textAlign }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("pre", { staticClass: "text", style: _vm.textAlign }, [
          _vm._v(_vm._s(_vm.text)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }