var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pollutant-management-image-card" },
    [
      _c("PollutantManagementImageCardTitle", {
        attrs: {
          image: _vm.imageCardTitle.image,
          text: _vm.imageCardTitle.text,
        },
      }),
      _c(
        "div",
        { staticClass: "content-area" },
        _vm._l(_vm.imageCardContentList, function (item, idx) {
          return _c("SustainabilityTitleAndText", {
            key: idx,
            attrs: {
              contentTitle: item.contentTitle,
              contentText: item.contentText,
              titleColor: item.titleColor,
              textColor: item.textColor,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }