var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode === "_US" || _vm.nationCode === "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png",
          title: "Market Leading Design",
          titleColor: "#222",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "OverviewSection",
                    [_c("SustainabilityText", { attrs: { text: _vm.text } })],
                    1
                  ),
                  _c("ProductDevelopmentBased"),
                  _c("AnnualTrendshipSeminar"),
                  _c("TrendshipSoftwhere"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2219024312
        ),
      })
    : _vm.nationCode === "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png",
          title: "マーケットリードデザイン",
          titleColor: "#222",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [_c("SustainabilityText", { attrs: { text: _vm.textJp } })],
                  1
                ),
                _c("ProductDevelopmentBased"),
                _c("AnnualTrendshipSeminar"),
                _c("TrendshipSoftwhere"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode === "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png",
          title: "Ведущий дизайн на рынке",
          titleColor: "#222",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [_c("SustainabilityText", { attrs: { text: _vm.textRu } })],
                  1
                ),
                _c("ProductDevelopmentBased"),
                _c("AnnualTrendshipSeminar"),
                _c("TrendshipSoftwhere"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode === "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png",
          title: "市场领先的设计",
          titleColor: "#222",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [_c("SustainabilityText", { attrs: { text: _vm.textCn } })],
                  1
                ),
                _c("ProductDevelopmentBased"),
                _c("AnnualTrendshipSeminar"),
                _c("TrendshipSoftwhere"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }