var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content order-view" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "container order-inp-box order-sample-info" },
          [
            _c("div", { staticClass: "w-con-02 inner" }, [
              _c("p", { staticClass: "tt-2" }, [_vm._v("Project Information")]),
              _c("p", { staticClass: "noti ta-r" }, [
                _vm._v("This is a required field"),
              ]),
              _c("div", { staticClass: "col-02 order-tbl order-tbl-big-box" }, [
                _c("div", { staticClass: "tbl-01 order-tbl01" }, [
                  _c("div", { staticClass: "tbl-input" }, [
                    _c("table", [
                      _c("caption", [_vm._v("Project Information left form")]),
                      _vm._m(1),
                      _c("tbody", { attrs: { id: "itemList" } }, [
                        _c("tr", [
                          _vm._m(2),
                          _c("td", { staticClass: "cell-noti" }, [
                            _vm._m(3),
                            _vm.state == "" && _vm.submitChk == "Y"
                              ? _c("p", { staticClass: "noti tar" }, [
                                  _vm._v("Select"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(4),
                          _c("td", { staticClass: "cell-noti" }, [
                            _vm._m(5),
                            _vm.state == "" && _vm.submitChk == "Y"
                              ? _c("p", { staticClass: "noti tar" }, [
                                  _vm._v("Select"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "tbl-01 order-tbl02" }, [
                  _c("div", { staticClass: "tbl-input" }, [
                    _c("table", [
                      _c("caption", [_vm._v("Contact form2")]),
                      _vm._m(6),
                      _c("tbody", [
                        _c("tr", [
                          _vm._m(7),
                          _c("td", { staticClass: "cell-noti" }, [
                            _vm._m(8),
                            _vm.state == "" && _vm.submitChk == "Y"
                              ? _c("p", { staticClass: "noti tar" }, [
                                  _vm._v("Select"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", [
                          _vm._m(9),
                          _c("td", { staticClass: "cell-noti" }, [
                            _vm._m(10),
                            _vm.state == "" && _vm.submitChk == "Y"
                              ? _c("p", { staticClass: "noti tar" }, [
                                  _vm._v("Select"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "more-box ta-c mb-20" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.Submit()
                      },
                    },
                  },
                  [_c("span", [_vm._v("Submit")])]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
        _c("li", [_vm._v("Header")]),
        _c("li", [_vm._v("Cart")]),
        _c("li", [_vm._v("Order Sample")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Type of building")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sel-box type02", attrs: { id: "" } }, [
      _c(
        "button",
        { staticClass: "btn-sel", attrs: { id: "btnState", type: "button" } },
        [_c("span", { staticClass: "placeholder" }, [_vm._v("select")])]
      ),
      _c("div", { staticClass: "sel-list", attrs: { id: "" } }, [
        _c("ul", { staticClass: "box" }, [
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option01 ")]),
          ]),
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option02 ")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Project maturity")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sel-box type02", attrs: { id: "" } }, [
      _c(
        "button",
        { staticClass: "btn-sel", attrs: { id: "btnState", type: "button" } },
        [_c("span", { staticClass: "placeholder" }, [_vm._v("select")])]
      ),
      _c("div", { staticClass: "sel-list", attrs: { id: "" } }, [
        _c("ul", { staticClass: "box" }, [
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option01 ")]),
          ]),
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option02 ")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Completion date")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sel-box type02", attrs: { id: "" } }, [
      _c(
        "button",
        { staticClass: "btn-sel", attrs: { id: "btnState", type: "button" } },
        [_c("span", { staticClass: "placeholder" }, [_vm._v("select")])]
      ),
      _c("div", { staticClass: "sel-list", attrs: { id: "" } }, [
        _c("ul", { staticClass: "box" }, [
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option01 ")]),
          ]),
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option02 ")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Project size")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sel-box type02", attrs: { id: "" } }, [
      _c(
        "button",
        { staticClass: "btn-sel", attrs: { id: "btnState", type: "button" } },
        [_c("span", { staticClass: "placeholder" }, [_vm._v("select")])]
      ),
      _c("div", { staticClass: "sel-list", attrs: { id: "" } }, [
        _c("ul", { staticClass: "box" }, [
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option01 ")]),
          ]),
          _c("li", [
            _c("button", { attrs: { type: "button" } }, [_vm._v(" option02 ")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }