var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OverviewSection", [
    _c("div", { staticClass: "korea-company-area" }, [
      _c("div", { staticClass: "glBuiz" }, [
        _c("h3", { staticClass: "tt-30" }, [
          _vm._v(_vm._s(_vm.$t("globalBusiness.text.tab_02_title"))),
        ]),
        _c("div", { staticClass: "tableArea tbl-box type04 field-01" }, [
          _c("table", [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "30%" } }),
              _c("col", { staticStyle: { width: "auto%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_01_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_01_brief"))
                  ),
                ]),
              ]),
            ]),
            _c("tbody", { attrs: { id: "" } }, [
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_02_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_02_brief"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_03_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_03_brief"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_04_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_04_brief"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_05_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_05_brief"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_06_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_06_brief"))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_07_title"))
                  ),
                ]),
                _c("td", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("globalBusiness.text.tab_02_info_07_brief"))
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }