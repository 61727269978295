var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content order-view" }, [
        _vm._m(0),
        _c("div", { staticClass: "container order-inp-box" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _vm._m(1),
            _c("div", { staticClass: "hc-os-dvcfm" }, [
              _c(
                "div",
                {
                  staticClass: "tbl-input type02",
                  class: { uktbl: _vm.$countryCode === "_UK" },
                },
                [
                  _c("table", [
                    _c("caption", [_vm._v("Order Confirmation form")]),
                    _vm._m(2),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v("Order Number"),
                        ]),
                        _c("td", [_vm._v(_vm._s(_vm.form.orderId))]),
                      ]),
                      _vm.$countryCode === "_US"
                        ? _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Subtotal"),
                            ]),
                            _c("td", [
                              _vm._v("$ " + _vm._s(_vm.form.subtotal)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.$countryCode === "_US"
                        ? _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Shipping & Handling"),
                            ]),
                            _c("td", [_vm._v("FedEx Ground")]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _vm.$countryCode === "_US"
                ? _c("div", { staticClass: "tbl-input type02 tbl-total" }, [
                    _c("table", [
                      _c("caption", [
                        _vm._v("order form (Product Grand Total)"),
                      ]),
                      _vm._m(3),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Grand Total"),
                          ]),
                          _c("td", [
                            _vm._m(4),
                            _c("span", [
                              _vm._v("$ " + _vm._s(_vm.form.subtotal)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoUrl()
                      },
                    },
                  },
                  [_c("span", [_vm._v("Continue Shopping")])]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
        _c("li", [_vm._v("Header")]),
        _c("li", [_vm._v("Order Sample")]),
        _c("li", [_vm._v("Confirm")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sub-visual-section" }, [
      _c("h1", { staticClass: "visual-title tt-1 tac" }, [
        _vm._v("Order Confirmation"),
      ]),
      _c("p", { staticClass: "ctt ctt-t2 visual-desc tac" }, [
        _c("span", { staticClass: "pc-br" }, [
          _vm._v("Your order has been placed."),
        ]),
        _c("span", { staticClass: "pc-br" }, [
          _vm._v("Thank you for choosing LX Hausys."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticClass: "p-ui", staticStyle: { width: "250px" } }),
      _c("col", { staticClass: "p-ui", staticStyle: { width: "auto" } }),
      _c("col", { staticClass: "m-ui", staticStyle: { width: "30%" } }),
      _c("col", { staticClass: "m-ui", staticStyle: { width: "70%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticClass: "p-ui", staticStyle: { width: "250px" } }),
      _c("col", { staticClass: "m-ui", staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn-pp", attrs: { type: "button" } }, [
      _c("span", [_vm._v("PayPal")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }