var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProductLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("ProductTopImage", {
              attrs: {
                title: "Automotive Component",
                description:
                  "Various components applied to the exterior and interior of automobiles",
                imageSrc: "images/automotive/component/top.png",
              },
            }),
            _c(
              "ProductContent",
              [
                [
                  _c("ProductSectionHeader", {
                    staticClass: "mt-80",
                    attrs: {
                      subTitle:
                        "We make innovative automotive components with a multitude of material formulas. Our cutting-edge technology improve energy efficiency and reduce weight of autos.",
                      subTitleWidth: "1050px",
                    },
                  }),
                  _c("ProductStrongGroup", {
                    staticClass: "mt-100",
                    attrs: {
                      iconType: "vertical",
                      info: [
                        {
                          iconSrc:
                            "images/automotive/lightweight/strong-point-icon-1.png",
                          title: "Energy Efficiency",
                          description:
                            "Reducing weight to <br/> improve energy efficiency",
                        },
                        {
                          iconSrc:
                            "images/automotive/lightweight/strong-point-icon-2.png",
                          title: "High Quality",
                          description:
                            "Excellent quality by a <br/> cutting edge technology",
                        },
                        {
                          iconSrc:
                            "images/automotive/lightweight/strong-point-icon-3.png",
                          title: "Superior Design",
                          description:
                            "Realizing the sophiscated and luxury patterns for your driving space",
                        },
                      ],
                    },
                  }),
                  _c("PartGroup", { staticClass: "mt-100" }),
                  _c("InteriorPart", { staticClass: "mt-100" }),
                ],
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }