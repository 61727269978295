var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Trendship 2023",
            italicTitle: " 'SOFTWHERE'",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.text } })],
            1
          ),
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "softwhere-point-image-area" },
              _vm._l(_vm.pointImages, function (item, index) {
                return _c("SoftwherePointImage", {
                  key: index,
                  attrs: {
                    image: item.image,
                    point: item.point,
                    title: item.title,
                    text: item.text,
                    direction: item.direction,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "2023年のトレンド",
            italicTitle: "「SOFTWHERE」",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textJp } })],
            1
          ),
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "softwhere-point-image-area" },
              _vm._l(_vm.pointImagesJp, function (item, index) {
                return _c("SoftwherePointImage", {
                  key: index,
                  attrs: {
                    image: item.image,
                    point: item.point,
                    title: item.title,
                    text: item.text,
                    direction: item.direction,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Trendship 2023",
            italicTitle: " 'SOFTWHERE'",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textRu } })],
            1
          ),
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "softwhere-point-image-area" },
              _vm._l(_vm.pointImagesRu, function (item, index) {
                return _c("SoftwherePointImage", {
                  key: index,
                  attrs: {
                    image: item.image,
                    point: item.point,
                    title: item.title,
                    text: item.text,
                    direction: item.direction,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Trendship 2023",
            italicTitle: " 'SOFTWHERE'",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textCn } })],
            1
          ),
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "softwhere-point-image-area" },
              _vm._l(_vm.pointImagesCn, function (item, index) {
                return _c("SoftwherePointImage", {
                  key: index,
                  attrs: {
                    image: item.image,
                    point: item.point,
                    title: item.title,
                    text: item.text,
                    direction: item.direction,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }