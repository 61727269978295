<template>
  <OverviewSection>
    <div class="overseas-company-area" v-if="nationCode === '_US' || nationCode === '_GL'">
      <div
        class="l-item"
        v-for="(it, idx) in items"
        :key="idx"
      >
        <!-- 제목 영역 -->
        <div class="title-area tt-30">{{ it.title }}</div>

        <!-- 줄 -->
        <div class="line"></div>

        <!-- 위치 -->
        <div
          v-for="(innerIt, innerIdx) in it.desc"
          :key="innerIdx + innerIt.location"
          class="desc-area"
        >
          <div class="desc-area-location">
            <pre class="ctt ctt-4">{{ innerIt.location }}</pre>
          </div>
          <div class="desc">
            <pre class="ctt-t5">{{ innerIt.desc }}</pre>
          </div>
        </div>
      </div>
    </div>
<!--  JP  -->
    <div class="overseas-company-area" v-else-if="nationCode === '_JP'">
      <div
        class="l-item"
        v-for="(it, idx) in items"
        :key="idx"
      >
        <!-- 제목 영역 -->
        <div class="title-area tt-30">{{ it.title }}</div>

        <!-- 줄 -->
        <div class="line"></div>

        <!-- 위치 -->
        <div
          v-for="(innerIt, innerIdx) in it.desc"
          :key="innerIdx + innerIt.location"
          class="desc-area"
        >
          <div class="desc-area-location">
            <pre class="ctt ctt-4">{{ innerIt.location }}</pre>
          </div>
          <div class="desc">
            <pre class="ctt-t5">{{ innerIt.desc }}</pre>
          </div>
        </div>
      </div>
    </div>
<!--  RU  -->
    <div class="overseas-company-area" v-else-if="nationCode === '_RU'">
      <div
        class="l-item"
        v-for="(it, idx) in itemsRu"
        :key="idx"
      >
        <!-- 제목 영역 -->
        <div class="title-area tt-30">{{ it.title }}</div>

        <!-- 줄 -->
        <div class="line"></div>

        <!-- 위치 -->
        <div
          v-for="(innerIt, innerIdx) in it.desc"
          :key="innerIdx + innerIt.location"
          class="desc-area"
        >
          <div class="desc-area-location">
            <pre class="ctt ctt-4">{{ innerIt.location }}</pre>
          </div>
          <div class="desc">
            <pre class="ctt-t5">{{ innerIt.desc }}</pre>
          </div>
        </div>
      </div>
    </div>
<!--  CN  -->
    <div class="overseas-company-area" v-else-if="nationCode === '_CN'">
      <div
        class="l-item"
        v-for="(it, idx) in items"
        :key="idx"
      >
        <!-- 제목 영역 -->
        <div class="title-area tt-30">{{ it.title }}</div>

        <!-- 줄 -->
        <div class="line"></div>

        <!-- 위치 -->
        <div
          v-for="(innerIt, innerIdx) in it.desc"
          :key="innerIdx + innerIt.location"
          class="desc-area"
        >
          <div class="desc-area-location">
            <pre class="ctt ctt-4">{{ innerIt.location }}</pre>
          </div>
          <div class="desc">
            <pre class="ctt-t5">{{ innerIt.desc }}</pre>
          </div>
        </div>
      </div>
    </div>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'

export default {
  components: {
    OverviewSection
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      items: [
        {
          title: 'Manufacturing Corporation',
          desc: [
            {
              location: 'U.S. (Adairsville)',
              desc: `LX Hausys America, Inc.
310 LG Dr SE, Adairsville, GA 30103
Tel. 1-678-486-8220`
            },
            {
              location: 'CHINA (Wuxi)',
              desc: `LX Hausys (Wuxi) Co., Ltd.
NO.2 Hongxiang Road, New District Wuxi, China
Tel. 86-510-6668-6888`
            }
          ]
        },
        {
          title: 'Sales Corporation',
          desc: [
            {
              location: 'U.S. (Alpharetta)',
              desc: `LX Hausys America, Inc.
3480 Preston Ridge Road, Suite 350, Alpharetta, Georgia 30005
Tel. 1-678-486-8220`
            },
            {
              location: 'CANADA (Toronto)',
              desc: `LX Hausys Canada, Inc.
20 Norelco Drive, North York, Ontario, M9L 2X6, Canada
Tel. 1-905-660-4746`
            },
            {
              location: 'CHINA (Wuxi)',
              desc: `LX Hausys (Wuxi) Co., Ltd.
13F Building C, Huirong Plaza, Hefeng Road 26,
New District Wuxi, China
Tel. 86-510-8233-6988`
            },
            {
              location: 'EUROPE (Frankfurt)',
              desc: `LXHausys Europe GMBH
Lyoner Str. 15 Atricom C6, 60528 Frankfurt am Main,
Germany
Tel. +49-69-583029-0`
            },
            {
              location: 'RUSSIA (Moscow)',
              desc: `LXHausys Rus, LLC
Lesnaya Plaza 8th floor, 4, 4th Lesnoy pereulok, Moscow,
125047, Russia
Tel. 7-495-663-39-58`
            },
            {
              location: 'INDIA (Gurgaon)',
              desc: `LXHausys India Private Limited
7F, Emmar MGF Digital Green, Tower B, Golf Course Ext.
Road, Sector 61, Gurgaon-122009,Haryana, India
Tel. 91-124-421-2590`
            }
          ]
        },
        {
          title: 'Authorized Agency',
          desc: [
            {
              location: 'JAPAN (Tokyo)',
              desc: `LXHausys Tokyo Branch
100-0004 Tokyo Sankei Building 25F, 1-7-2 Otemachi,
Chiyoda-ku, Tokyo, Japan
Tel. 81-3-5299-4533`
            }
          ]
        }
      ],
      itemsRu: [
        {
          title: 'Производственная корпорация',
          desc: [
            {
              location: 'США (Адерсвилл)',
              desc: `LX Hausys America, Inc.
310 LG Драйв ЮВ, Адэрсвилл, Джорджия 30103
Тел. 1-678-486-8220`
            },
            {
              location: 'КИТАЙ (Уси)',
              desc: `LX Hausys (Wuxi) Co., Ltd.
NO.2 Хунсян Роуд, Новый район Уси, Китай
Тел. 86-510-6668-6888`
            }
          ]
        },
        {
          title: 'Торговая корпорация',
          desc: [
            {
              location: 'США (Альфаретта)',
              desc: `LX Hausys America, Inc.
3480 Preston Ridge Road, Suite 350, Alpharetta, Georgia 30005
Тел. 1-678-486-8220`
            },
            {
              location: 'КАНАДА (Торонто)',
              desc: `LX Hausys Canada, Inc.
500 Эпплвуд Кресент, Конкорд, Онтарио, L4K 4B4, Канада
Тел. 1-905-669-0110`
            },
            {
              location: 'МЕКСИКА (Монтеррей)',
              desc: `LX Hausys Mexico LTD S. LTD S. LTD S. de R.L. de C.V.
Шоссе Аподака до Вилья-де-Хуарес 300, Кол. Хуинала, 
Промышленный парк, П.К. 66645, Аподака, 
Н.Л., Монтеррей, Мексика
Тел. 1-905-669-0110`
            },
            {
              location: 'КИТАЙ (Уси)',
              desc: `LX Hausys (Wuxi) Co., Ltd.
13-й этаж Корпус С, Хуйжун Плаза, Хэфэн Роуд 26, 
Новый район Уси, Китай
Тел. 86-510-8233-6988`
            },
            {
              location: 'ЕВРОПА (Франкфурт)',
              desc: `LXHausys Europe GMBH
Лионер ул. 15 Атриком С6, 60528 Франкфурт-на-Майне, 
Германия
Тел. +49-69-583029-0`
            },
            {
              location: 'РОССИЯ (Москва)',
              desc: `LXHausys Rus, LLC
Лесная Плаза, 125047, Россия, г. Москва, 4-й Лесной переулок, 
дом 4, 8-й этаж
Тел. 7-495-663-39-58`
            },
            {
              location: 'ИНДИЯ (Гургаон)',
              desc: `LXHausys India Private Limited
7-й этаж, Emaar MGF Цифровые Грины, Башня B, ул. доб. 
поля для гольфа, Сектор 61, Гургаон-122009, Харьяна, Индия
Тел. 91-124-421-2590`
            }
          ]
        },
        {
          title: 'Уполномоченное агентство',
          desc: [
            {
              location: 'ЯПОНИЯ (Токио)',
              desc: `LXHausys Tokyo Branch
100-0004 Здание Токио Санкэй, 25-й этаж, 1-7-2 Отемачи,
Чиёда-ку, Токио, Япония
Тел. 81-3-5299-4533`
            }
          ]
        }
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.overseas-company-area {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;font-family: "NotoSans" , sans-serif;
}

@media (max-width: 768px) {
  .overseas-company-area {
    flex-direction: column;
    align-items: center;
  }
}

.overseas-company-area .l-item {
  width: 460px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .overseas-company-area .l-item {
    width: 90%;
  }
}

.overseas-company-area .line {
  margin-top: 22px;
  margin-bottom: 25px;
  height: 1px;
  background-color: #2C2C2D;
}

.overseas-company-area .desc-area {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.overseas-company-area .desc-area .desc-area-location {
  margin-bottom: 15px;
}

.overseas-company-area .desc-area .desc-area-location .ctt.ctt-4::before {
  content: "• ";
}

.overseas-company-area .desc-area .desc {
  margin-left: 12px;
}

.overseas-company-area .desc-area .desc .ctt-t5 {
  font-size: 13px;
  line-height: 1.54;
  color: #555;
}

@media (max-width: 768px) {
  .overseas-company-area .desc-area .desc .ctt-t5 {
    white-space: pre-wrap;
  }
}
</style>
