import request from './request'
const urlPrefix = ''
const AboutUsService = {
  getContent (id) {
    return request({
      url: `${urlPrefix}/product/detail?id=${id}`,
      method: 'get'
    })
  },
  getCategoryCode (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: 'get'
    })
  },
  getContentList (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
      }
    })
  },
  getContentListPage (categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
      }
    })
  },
  getContentListChild (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true
      }
    })
  },
  getContentListTapField (categoryId, dictionaryValues, text, pageIndex, pageSize, field, type) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        dictionaryCondition: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField : [field],
        orderType: [type]
      }
    })
  },
  getContentNews (categoryId, dictionaryValues, text, pageIndex, pageSize, field, type, date) {
    return request({
      url: `${urlPrefix}/product/searchNews?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        dictionaryCondition: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField : [field],
        orderType: [type],
        createdAt : date
      }
    })
  },
}
export default AboutUsService
