var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/social_responsibility/top_image.png",
          title: "Social Responsibility",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("SocialContributionStrategySystem"),
                  _c("SocialContributionActivities"),
                  _c("KeySocialContributionInitiatives"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2613301393
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/social_responsibility/top_image.png",
          title: "社会的責任",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("SocialContributionStrategySystem"),
                _c("SocialContributionActivities"),
                _c("KeySocialContributionInitiatives"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/social_responsibility/top_image.png",
          title: "Социальная ответственность",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("SocialContributionStrategySystem"),
                _c("SocialContributionActivities"),
                _c("KeySocialContributionInitiatives"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/sustainability/social_responsibility/top_image.png",
          title: "社会责任",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("SocialContributionStrategySystem"),
                _c("SocialContributionActivities"),
                _c("KeySocialContributionInitiatives"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }