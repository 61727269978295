var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _vm._m(0),
    _c("div", { staticClass: "filter_checkbox_cont" }, [
      _c("div", { staticClass: "checkbox_box" }, [
        _c(
          "span",
          { staticClass: "checkbox_more no_line" },
          _vm._l(_vm.formatList, function (format) {
            return _c(
              "li",
              { key: format.id.value, attrs: { "data-chk": format.id.value } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "format_" + format.id.value,
                    name: "Format",
                  },
                  domProps: {
                    value: format.id.value,
                    checked: Array.isArray(_vm.selected)
                      ? _vm._i(_vm.selected, format.id.value) > -1
                      : _vm.selected,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = format.id.value,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selected = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selected = $$c
                      }
                    },
                  },
                }),
                _c("label", { attrs: { for: "format_" + format.id.value } }, [
                  _c("span"),
                  _vm._v(" " + _vm._s(format.text) + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("FORMAT")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }