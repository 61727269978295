<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>APPLICATION</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
          <span class="checkbox_more no_line">
            <li v-for="app in appList" :data-chk="app.id.value">
              <input type="checkbox" :id="'application_'+app.id.value" name="Application" v-model="selected" :value="app.id.value" />
              <label :for="'application_'+`${app.id.value}`">
              <span></span>
              {{ app.id.value }}
              </label>
            </li>
          </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'appList'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: function () {
      const selectedApps = this.selected.map(appId => {
        const app = this.appList.find(app => app.id.value === appId)
        return app ? app.id.value : null
      }).filter(appId => appId !== null)
      this.$emit('update', selectedApps)
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    EventBus.$on('reset-pf-filter', function () { _this.selected = [] })
    if (this.$store.state.filterState !== null) {
      this.selected = this.$store.state.filterState.application
    }

  },
  methods: {
  }
}
</script>
