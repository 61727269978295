<template>
  <OverviewLayout
    imageSrc="images/aboutus/overview/vision_strategies/top_image.png"
    title="Vision & Strategies"
    v-if="nationCode === '_US' || nationCode === '_GL'"
  >
    <template #content>
      <NatureHumanAndSpace />
      <CustomerValues />
      <CoreValues />
      <ManagementStrategy />
      <DirectionOfStrategy />
      <KeyProject />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/vision_strategies/top_image.png"
    title="ビジョン及び戦略"
    v-else-if="nationCode === '_JP'"
  >
    <template #content>
      <NatureHumanAndSpace />
      <CustomerValues />
      <CoreValues />
      <ManagementStrategy />
      <DirectionOfStrategy />
      <KeyProject />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/vision_strategies/top_image.png"
    title="Видение и стратегии"
    v-else-if="nationCode === '_RU'"
  >
    <template #content>
      <NatureHumanAndSpace />
      <CustomerValues />
      <CoreValues />
      <ManagementStrategy />
      <DirectionOfStrategy />
      <KeyProject />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/vision_strategies/top_image.png"
    title="愿景 & 战略"
    v-else-if="nationCode === '_CN'"
  >
    <template #content>
      <NatureHumanAndSpace />
      <CustomerValues />
      <CoreValues />
      <ManagementStrategy />
      <DirectionOfStrategy />
      <KeyProject />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../components/OverviewLayout.vue'
import CoreValues from './components/CoreValues.vue'
import CustomerValues from './components/CustomerValues.vue'
import DirectionOfStrategy from './components/DirectionOfStrategy.vue'
import KeyProject from './components/KeyProject.vue'
import ManagementStrategy from './components/ManagementStrategy.vue'
import NatureHumanAndSpace from './components/natureHumanAndSpace.vue'

export default {
  components: {
    OverviewLayout,
    NatureHumanAndSpace,
    CustomerValues,
    CoreValues,
    ManagementStrategy,
    DirectionOfStrategy,
    KeyProject
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>
