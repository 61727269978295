<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>COMPARE</span>
    </div>
    <div class="compare_wrap m-ui">
      <input type="checkbox" id="compare_btn2" class="compare_btn" name="compare_button" value="">
      <label for="compare_btn2">
        <span class="txt">ON</span>
        <span class="btn"></span>
      </label>
    </div>
  </li>
</template>

<script>
import { EventBus } from '@/utils/event_bus'
import { mapState } from 'vuex'

export default {
  name: 'SearchFilter',
  props: [
  ],
  components: {
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
  },
  async created () {

  },
  async mounted () {
    const _this = this
    EventBus.$on('reset-pf-filter', function () { _this.selected = [] })
  },
  methods: {
  }
}
</script>
