var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layer-view layer-popup popup-w780 cart-ordsample" },
    [
      _c("div", { staticClass: "popup pop-close" }, [
        _c("div", { staticClass: "pop_cont" }, [
          _c("p", { staticClass: "popup-title" }, [_vm._v("Order Sample")]),
          _vm.productAvailable
            ? _c("div", { staticClass: "cart-sampleInfo" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "pop_con_img" }, [
                    _c("div", { staticClass: "img-box" }, [
                      _vm.image
                        ? _c("img", {
                            attrs: { src: _vm.image, alt: "sample" },
                          })
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("ul", { staticClass: "flex-column" }, [
                        _c("li", { staticClass: "txt-prod" }, [
                          _vm._v(_vm._s(_vm.content.content.title)),
                        ]),
                        _c("li", { staticClass: "txt-comp" }, [
                          _vm._v(
                            _vm._s(
                              _vm.content.parents.length > 0
                                ? _vm.content.parents[0].name
                                : ""
                            ) + " Collection "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "pop_con_input" }, [
                    _c("div", { staticClass: "pop_con_scroll" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("ul", { staticClass: "flex-column" }, [
                          _c("li", { staticClass: "txt-opt" }, [
                            _c(
                              "div",
                              {
                                staticClass: "sel-box type02",
                                attrs: { id: "selSize" },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: {
                                      id: "selSize_open",
                                      type: "button",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.msg.selSize)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sel-list",
                                    attrs: { id: "selSize_list" },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "box" },
                                      _vm._l(
                                        _vm.availableSizes,
                                        function (orderSpec, index) {
                                          return _c("li", { key: index }, [
                                            _c(
                                              "button",
                                              {
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setSize(
                                                      orderSpec,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(orderSpec.size))]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm.orderList.length > 0
                        ? _c("div", { staticClass: "select-opt" }, [
                            _c("div", { staticClass: "opt-list" }, [
                              _c(
                                "ul",
                                _vm._l(_vm.orderList, function (item, idx) {
                                  return _c("li", { key: item.cartId }, [
                                    _c("div", { staticClass: "opt-txt" }, [
                                      _c("div", { staticClass: "size" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.sampleInfo.size) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "row ju_bet al_center" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "spin-ui" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-m",
                                                  class:
                                                    item.qty == 1
                                                      ? "disable"
                                                      : "",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modifyQty(
                                                        item,
                                                        "minus"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("span", [_vm._v("minus")])]
                                              ),
                                              _c("input", {
                                                staticClass: "inp-qty",
                                                attrs: {
                                                  type: "number",
                                                  title: "input quantity",
                                                },
                                                domProps: { value: item.qty },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-p",
                                                  class:
                                                    item.qty >= 10
                                                      ? "disable"
                                                      : "",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modifyQty(
                                                        item,
                                                        "plus"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("span", [_vm._v("plus")])]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "price" }, [
                                            _vm._v(
                                              " $" +
                                                _vm._s(_vm.calcOrder(item)) +
                                                " "
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "list-del",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteSample(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("span", [_vm._v("delete")])]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.orderList.length > 0
                      ? _c("div", { staticClass: "more-box btn_area" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button h55 btn_add_cart",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setOrder("cart")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "txt" }, [
                                _vm._v("Add To Cart"),
                              ]),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button h55 btn_order_now",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setOrder("order")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "txt" }, [
                                _vm._v("Order Now"),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closePopup()
                },
              },
            },
            [_vm._v("close")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }