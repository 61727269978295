import request from './request'
import _ from "@/common/helper/lodash";

const urlPrefix = ''
const MetaService = {
  getMeta (code, hasRegion, countryCode) {
    if( (_.isBoolean(hasRegion) && hasRegion) || !_.isBoolean(hasRegion))
    {
      code = code + "_"+ countryCode;
    }
    return request({
      url: `${urlPrefix}/meta?code=${code}`,
      method: 'get'
    })
  },
  getMetaById (cateId) {
    return request({
      url: `${urlPrefix}/meta/${cateId}`,
      method: 'get'
    })
  }

}
export default MetaService
