<template>
  <div class="key-performance-table" v-if="nationCode == '_US' || nationCode == '_GL'">
    <div class="ulsan-plant">
      <KeyPerformanceTableHeader
        :title="header1.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body1"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
    <div class="cheongju-plant">
      <KeyPerformanceTableHeader
        :title="header2.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body2"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
  </div>
<!-- JP -->
  <div class="key-performance-table" v-else-if="nationCode == '_JP'">
    <div class="ulsan-plant">
      <KeyPerformanceTableHeader
        :title="header1Jp.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body1Jp"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
    <div class="cheongju-plant">
      <KeyPerformanceTableHeader
        :title="header2Jp.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body2Jp"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
  </div>
<!-- RU -->
  <div class="key-performance-table" v-else-if="nationCode == '_RU'">
    <div class="ulsan-plant">
      <KeyPerformanceTableHeader
        :title="header1Ru.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body1Ru"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
    <div class="cheongju-plant">
      <KeyPerformanceTableHeader
        :title="header2Ru.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body2Ru"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
  </div>
<!-- CN -->
  <div class="key-performance-table" v-else-if="nationCode == '_CN'">
    <div class="ulsan-plant">
      <KeyPerformanceTableHeader
        :title="header1Cn.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body1Cn"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
    <div class="cheongju-plant">
      <KeyPerformanceTableHeader
        :title="header2Cn.title"
      />
      <KeyPerformanceTableBody
        v-for="(item, idx) in body2Cn"
        :key="idx"
        :number="item.number"
        :unit="item.unit"
        :summary="item.summary"
      />
    </div>
  </div>
</template>

<script>
import KeyPerformanceTableBody from './KeyPerformanceTableBody.vue'
import KeyPerformanceTableHeader from './KeyPerformanceTableHeader.vue'
export default {
  components: {
    KeyPerformanceTableHeader,
    KeyPerformanceTableBody
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      header1: {
        title: 'Ulsan Plant'
      },
      header2: {
        title: 'Cheongju Plant'
      },
      header1Jp: {
        title: '蔚山工場'
      },
      header2Jp: {
        title: '清州工場'
      },
      header1Ru: {
        title: 'Завод в Ульсане'
      },
      header2Ru: {
        title: 'Завод в Чхонджу'
      },
      header1Cn: {
        title: '蔚山工厂'
      },
      header2Cn : {
        title: '清州工厂'
      },
      body1: [
        {
          number: '-158',
          unit: 'tCO2',
          summary: 'Smaller high-efficiency Boilers'
        },
        {
          number: '-207',
          unit: 'tCO2',
          summary: 'The thermal storage material of RTO'
        },
        {
          number: '-150',
          unit: 'tCO2',
          summary: 'By reducing LNG consumption'
        }
      ],
      body2: [
        {
          number: '-126',
          unit: 'tCO2',
          summary: 'Improvement of Air compressor pressure control'
        },
        {
          number: '-249',
          unit: 'tCO2',
          summary: 'Optimization of Idle operation'
        }
      ],
      body1Jp: [
        {
          number: '-158',
          unit: 'tCO2',
          summary: '小型高効率ボイラー'
        },
        {
          number: '-207',
          unit: 'tCO2',
          summary: 'RTOの蓄熱材'
        },
        {
          number: '-150',
          unit: 'tCO2',
          summary: 'LNG消費量を削減すること'
        }
      ],
      body2Jp: [
        {
          number: '-126',
          unit: 'tCO2',
          summary: 'エアコンプレッサーの圧力制御の改善'
        },
        {
          number: '-249',
          unit: 'tCO2',
          summary: 'アイドル操作の最適化'
        }
      ],
      body1Ru: [
        {
          number: '-158',
          unit: 'tCO2',
          summary: 'Малые высокоэффективные котлы'
        },
        {
          number: '-207',
          unit: 'tCO2',
          summary: 'Теплоаккумулирующий материал RTO'
        },
        {
          number: '-150',
          unit: 'tCO2',
          summary: 'Снижение потребления СПГ'
        }
      ],
      body2Ru: [
        {
          number: '-126',
          unit: 'tCO2',
          summary: 'Улучшение управления давлением воздушного компрессора'
        },
        {
          number: '-249',
          unit: 'tCO2',
          summary: 'Оптимизация работы на холостом ходу'
        }
      ],
      body1Cn: [
        {
          number: '-158',
          unit: 'tCO2',
          summary: '小型高效锅炉'
        },
        {
          number: '-207',
          unit: 'tCO2',
          summary: 'RTO储热材料'
        },
        {
          number: '-150',
          unit: 'tCO2',
          summary: '减少液化天然气消耗'
        }
      ],
      body2Cn: [
        {
          number: '-126',
          unit: 'tCO2',
          summary: '改善空压机压力控制'
        },
        {
          number: '-249',
          unit: 'tCO2',
          summary: '优化怠速运行'
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style>
.key-performance-table {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .key-performance-table {
    width: 100%;
  }
  .ulsan-plant {
    width: 100%;
  }
  .cheongju-plant {
    width: 100%;
  }
}
</style>
