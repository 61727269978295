<template>
  <div class="product-tab-area">
    <TabHeader
      :item="headerTitle"
      @change="onChangeActive"
    />

    <div class="item">
      <slot :name="componentName"></slot>
    </div>

  </div>
</template>

<script>
import TabHeader from './header'

export default {
  components: {
    TabHeader
  },

  props: {
    item: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    selectedIndex: 0
  }),

  computed: {
    headerTitle () {
      return this.item.map((it) => {
        return { title: it.title }
      })
    },
    componentName () {
      return this.item[this.selectedIndex].component
    }
  },

  methods: {
    onChangeActive (idx) {
      this.selectedIndex = idx
    }
  }
}
</script>

<style scoped>
.product-tab-area {
  display: flex;
  flex-direction: column;
}

.product-tab-area .item-area {
  width: 100%;
}
</style>
