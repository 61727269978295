var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content cntu-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("faq.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("faq.text.support")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("faq.text.faq")))]),
          ]),
        ]),
        _c("div", { staticClass: "container cntu-box container-faq" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _c("div", { staticClass: "sub-visual-section" }, [
              _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                _vm._v(_vm._s(_vm.$t("faq.text.faq")) + " "),
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(" - " + _vm._s(_vm.prdLabel)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "faq_search_wrap" }, [
              _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                _vm._v(_vm._s(_vm.$t("faq.text.inner1"))),
              ]),
              _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                _vm._v(_vm._s(_vm.$t("faq.text.inner2")) + " "),
                _c(
                  "a",
                  {
                    staticClass: "ctt ctt-t2 link-ctt",
                    attrs: { href: "support/contactus" },
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm.$t("faq.text.contactus")))])]
                ),
              ]),
              _c("div", { staticClass: "inner" }, [
                _c("div", { staticClass: "search-box inline" }, [
                  _c("div", { staticClass: "search" }, [
                    _c("input", {
                      staticClass: "ta-r",
                      attrs: {
                        type: "text",
                        id: "search",
                        placeholder: _vm.$t("faq.text.searchBar"),
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search.apply(null, arguments)
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("faq.text.search")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "prod-dt-view inspr-view" }, [
              _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
                _c(
                  "ul",
                  {
                    staticClass: "all-chk-box",
                    attrs: { "data-chk": "chk01" },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "btn-tab btn-chk",
                        attrs: { id: "mainTap" },
                        on: {
                          click: function ($event) {
                            return _vm.tapClick("all")
                          },
                        },
                      },
                      [
                        _c("button", { attrs: { type: "button" } }, [
                          _vm._v(" " + _vm._s(_vm.$t("faq.text.ALL")) + " "),
                        ]),
                      ]
                    ),
                    _vm._l(_vm.categoryList, function (tap, index) {
                      return _c(
                        "li",
                        {
                          staticClass: "btn-tab btn-chk chk-list chk01",
                          class: { on: _vm.prdLabel === tap.name },
                          attrs: {
                            "data-chk": tap.id,
                            name: "mainTap",
                            id: tap.name,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tapClick(tap.name)
                            },
                          },
                        },
                        [
                          _c("button", { attrs: { type: "button" } }, [
                            _vm._v(" " + _vm._s(tap.name) + " "),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "inner" }, [
                _c("div", { staticClass: "tab-ui tab-ui-nav tab-ui-gray" }, [
                  _vm._m(0),
                  _vm._m(1),
                  _c(
                    "ul",
                    {
                      staticClass: "all-chk-box",
                      attrs: { "data-chk": "chk02" },
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "btn-tab btn-chk inp-chk-every on",
                          attrs: { id: "subTap", name: "subTap" },
                          on: {
                            click: function ($event) {
                              return _vm.subTapClick("ALL")
                            },
                          },
                        },
                        [
                          _c("button", { attrs: { type: "button" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("faq.text.ALL")) + " "),
                          ]),
                        ]
                      ),
                      _vm._l(_vm.subCategoryList, function (subTap) {
                        return _c(
                          "li",
                          {
                            staticClass: "btn-tab btn-chk chk-list chk02",
                            attrs: {
                              name: "subTap",
                              id: "subTap_" + subTap.id.value,
                              "data-chk": subTap.id.value,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.subTapClick(
                                  "subTap_" + subTap.id.value
                                )
                              },
                            },
                          },
                          [
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v(" " + _vm._s(subTap.text) + " "),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "faq-box" }, [
                  _c("div", { staticClass: "drop-list bd-gray" }, [
                    _vm.contentList.length > 0
                      ? _c(
                          "ul",
                          _vm._l(_vm.contentList, function (faq) {
                            return _c("li", { staticClass: "accordion-wrap" }, [
                              _vm.countryCode === "_US" ||
                              _vm.countryCode === "_GL"
                                ? _c("div", { staticClass: "tit" }, [
                                    _c(
                                      "div",
                                      { staticClass: "faq-pro-type wrap" },
                                      [
                                        _c("div", [
                                          _c("p", [_vm._v("Q")]),
                                          _c(
                                            "div",
                                            { staticClass: "badges" },
                                            [
                                              faq.attribute.typeText != null
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: `badge ${faq.parents[0].name.toLowerCase()}`,
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "uppercase",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                faq.parents[0]
                                                                  .name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: `badge ${faq.parents[0].name.toLowerCase()}`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              faq.parents[0]
                                                                .name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._m(2, true),
                                                  ],
                                              faq.attribute.typeText
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "badge etc",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            faq.attribute
                                                              .typeText
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "faq-accordion-title",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(faq.content.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-view",
                                        attrs: { type: "button" },
                                      },
                                      [_vm._v("list view")]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "faq_accordion_content" },
                                [
                                  _c("b", [_vm._v("A")]),
                                  _c("div", {
                                    staticClass: "faq_more",
                                    domProps: {
                                      innerHTML: _vm._s(faq.attribute.editor),
                                    },
                                  }),
                                ]
                              ),
                            ])
                          }),
                          0
                        )
                      : _c("div", [_vm._m(3)]),
                  ]),
                  _c("div", { staticClass: "pagination" }, [
                    _c(
                      "ul",
                      [
                        _c("li", { staticClass: "btn btn-first" }, [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.hasIndex(_vm.firstNumber)
                                },
                              },
                            },
                            [_vm._v("first page")]
                          ),
                        ]),
                        _c("li", { staticClass: "btn btn-prev" }, [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.hasIndex(_vm.pageIndex - 1)
                                },
                              },
                            },
                            [_vm._v("prev")]
                          ),
                        ]),
                        _vm._l(_vm.pageList, function (index) {
                          return _c(
                            "li",
                            {
                              staticClass: "btn-num",
                              attrs: {
                                id: "index_" + index,
                                "data-chk": index,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.hasIndex(index)
                                },
                              },
                            },
                            [
                              _c("button", { attrs: { type: "button" } }, [
                                _c("span", [_vm._v(_vm._s(index))]),
                              ]),
                            ]
                          )
                        }),
                        _c("li", { staticClass: "btn btn-next" }, [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.hasIndex(_vm.pageIndex + 1)
                                },
                              },
                            },
                            [_vm._v("next")]
                          ),
                        ]),
                        _c("li", { staticClass: "btn btn-last" }, [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.hasIndex(_vm.totalPage)
                                },
                              },
                            },
                            [_vm._v("last page")]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "arrow disabled tab-arr-prev", attrs: { type: "button" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("이전")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "arrow tab-arr-next", attrs: { type: "button" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("다음")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "badge etc" }, [
      _c("span", [_vm._v("ALL")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no_result" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/public/images/support/no_result.png",
          alt: "noresult",
        },
      }),
      _c("p", [_vm._v("No Result found")]),
      _c("span", [
        _vm._v("If you do not find what you are looking for, please"),
      ]),
      _c("div", { staticClass: "more-box contact" }, [
        _c("a", { attrs: { href: "support/contactus" } }, [
          _c("button", [_vm._v("Contact Us")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }