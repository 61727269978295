var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "skin-strong-point-area" },
    _vm._l(_vm.info, function (it, idx) {
      return _c("ProductStrongPoint", {
        key: idx,
        attrs: {
          iconSrc: it.iconSrc,
          title: it.title,
          description: it.description,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }