var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabCount > 0
    ? _c("div", { staticClass: "tab-con on" }, [
        !_vm.isEuUk()
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("news_event")) + " ("),
                  _c("em", [_vm._v(_vm._s(_vm.NEWS.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-type-news" }, [
                _vm.NEWS && _vm.NEWS.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.NEWS.list, function (sr) {
                        return _c("li", [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoNewsDetail(sr)
                                },
                              },
                            },
                            [
                              _c("b", { staticClass: "ctt ctt-t1" }, [
                                _vm._v(_vm._s(sr.content.title)),
                              ]),
                              _c("p", {
                                directives: [
                                  { name: "plaintext", rawName: "v-plaintext" },
                                ],
                                staticClass: "ctt ctt-t4",
                                staticStyle: {
                                  "overflow-y": "hidden",
                                  "max-height": "55px",
                                },
                                domProps: {
                                  innerHTML: _vm._s(sr.attribute.topicEditor),
                                },
                              }),
                            ]
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.NEWS.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-arr-down",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreNEWS()
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", { staticClass: "sch-res-prod prod-dt-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("FAQ ("),
              _c("em", [_vm._v(_vm._s(_vm.FAQ.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "faq-box" }, [
            _c("div", { staticClass: "search-result drop-list bd-gray" }, [
              _vm.FAQ && _vm.FAQ.totalCount > 0
                ? _c(
                    "ul",
                    _vm._l(_vm.FAQ.list, function (faq) {
                      return _c("li", [
                        _c("div", { staticClass: "tit" }, [
                          faq.attribute.typeText != null
                            ? _c("strong", [
                                _c("span", { staticClass: "product-type" }, [
                                  _vm._v(_vm._s(faq.parents[0].name)),
                                ]),
                                _vm._v(" / "),
                                _c("span", { staticClass: "category-name" }, [
                                  _vm._v(_vm._s(faq.attribute.typeText)),
                                ]),
                              ])
                            : _vm._e(),
                          faq.attribute.typeText == null
                            ? _c("strong", [
                                _c("span", { staticClass: "product-type" }, [
                                  _vm._v(_vm._s(faq.parents[0].name)),
                                ]),
                                _vm._v(" / "),
                                _c("span", { staticClass: "category-name" }, [
                                  _vm._v("ALL"),
                                ]),
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "faq-title" }, [
                            _vm._v(_vm._s(faq.content.title)),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleDetail($event)
                                },
                              },
                            },
                            [_vm._v("list view")]
                          ),
                        ]),
                        _c("div", {
                          staticClass: "t-type-01 cont",
                          domProps: { innerHTML: _vm._s(faq.attribute.editor) },
                        }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "no-found-wrap bd-top" }, [_vm._m(0)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tac" }, [
      _c("i", { staticClass: "icon-no-result" }, [
        _c("img", {
          attrs: { src: "images/icon-no-result-found.png", alt: "no result" },
        }),
      ]),
      _c("div", { staticClass: "msg" }, [
        _c("ul", [
          _c("li", { staticClass: "ctt ctt-t1 result-tit" }, [
            _vm._v("No Result Found"),
          ]),
          _c("li", { staticClass: "ctt ctt-t4" }, [
            _vm._v("Try again using more general search keywords"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }