/* ============
 * Actions for the Search module
 * ============
 *
 * The actions that are available on the
 * search module.
 */


import * as types from './mutation-types';

export const addSearchWord = async ({ commit, dispatch, getters }, {searchWord} = {}) => {
  await commit(types.ADD_SEARCH_WORD, searchWord);
};

export const deleteSearchWord = async ({ commit, dispatch, getters }, {searchWord} = {}) => {
  await commit(types.DELETE_SEARCH_WORD, searchWord);
};

export const addSearchHomeWord = async ({ commit, dispatch, getters }, {searchWord} = {}) => {
  await commit(types.ADD_SEARCH_HOME_WORD, searchWord);
};

export const deleteSearchHomeWord = async ({ commit, dispatch, getters }, {searchWord} = {}) => {
  await commit(types.DELETE_SEARCH_HOME_WORD, searchWord);
};

export default {
  addSearchWord,
  deleteSearchWord,
  addSearchHomeWord,
  deleteSearchHomeWord
};
