var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", [
          _c("div", { staticClass: "header" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
      ]),
    ]),
    _c("tbody", [
      _c("tr", [
        _c("td", [
          _c("div", { staticClass: "table-body" }, [
            _c(
              "ul",
              _vm._l(_vm.textList1, function (item) {
                return _c("li", { key: item }, [_vm._v(_vm._s(item))])
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("tr", [
        _c("td", [
          _c("div", { staticClass: "table-body" }, [
            _c(
              "ul",
              _vm._l(_vm.textList2, function (item) {
                return _c("li", { key: item }, [_vm._v(_vm._s(item))])
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("tr", [
        _c("td", [
          _c("div", { staticClass: "table-body" }, [
            _c(
              "ul",
              _vm._l(_vm.textList3, function (item) {
                return _c("li", { key: item }, [_vm._v(_vm._s(item))])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }