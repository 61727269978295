<template>
  <OverviewSection title="Financial Performance 2023" color="black" size="56px" v-if="nationCode == '_US' || nationCode == '_GL'">
    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <OverviewCardWithIcon
          v-for="(it, idx) in items"
          :key="idx"
          :title="it.title"
          :mainTxt="it.mainTxt"
          :addTxt="it.addTxt"
          :iconSrc="it.iconSrc"
        />
        <OverviewBigCardWithIcon
          v-for="(it, idx) in bigItems"
          :key="idx"
          :title1="it.title1"
          :mainTxt1="it.mainTxt1"
          :title2="it.title2"
          :mainTxt2="it.mainTxt2"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="financial-performance-2022-txt ctt-t2">
LX Hausys recorded a 2.4% decrease in revenue to $2.61 billion in 2023 compared to
the previous year. However, operating profit surger by 635.1% to $81.3 million.
While reveneu slightly declined due to a contraction in the domestic construction
and real estate makets, operating profit improved due to a decline in key raw
material prices and improved profitability of overseas operations.</pre
      >
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection title="2023年の財務実績" color="black" size="56px" v-else-if="nationCode == '_JP'">
    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <OverviewCardWithIcon
          v-for="(it, idx) in items_jp"
          :key="idx"
          :title="it.title"
          :mainTxt="it.mainTxt"
          :addTxt="it.addTxt"
          :iconSrc="it.iconSrc"
        />
        <OverviewBigCardWithIcon
          v-for="(it, idx) in bigItems_jp"
          :key="idx"
          :title1="it.title1"
          :mainTxt1="it.mainTxt1"
          :title2="it.title2"
          :mainTxt2="it.mainTxt2"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="financial-performance-2022-txt ctt-t2">
        LX Hausysは、2023年の売上高が前年比2.4%減の26億1,000万ドルとなった。しかし、営業利益は635.1%
        増の8,130万ドルとなった。国内の建設・不動産市場の縮小により売上高は若干減少したが、主要原材料価格
        の低下と海外事業の収益性向上により営業利益は改善した。</pre>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection title="Финансовые показатели 2023" color="black" size="56px" v-else-if="nationCode == '_RU'">
    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <OverviewCardWithIcon
          v-for="(it, idx) in items_ru"
          :key="idx"
          :title="it.title"
          :mainTxt="it.mainTxt"
          :addTxt="it.addTxt"
          :iconSrc="it.iconSrc"
        />
        <OverviewBigCardWithIcon
          v-for="(it, idx) in bigItems_ru"
          :key="idx"
          :title1="it.title1"
          :mainTxt1="it.mainTxt1"
          :title2="it.title2"
          :mainTxt2="it.mainTxt2"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="financial-performance-2022-txt ctt-t2">
В 2023 году выручка LX Hausys снизилась на 2,4% по сравнению с предыдущим годом и 
составила 2,61 миллиарда долларов. Однако операционная прибыль выросла на 635,1 % до 
81,3 млн долларов. В то время как выручка несколько снизилась из-за сокращения внутреннего 
строительного рынка и недвижимости, операционная прибыль выросла благодаря снижению цен 
на основное сырье и повышению рентабельности за рубежом. Снижение цен на основное сырье 
и повышение рентабельности зарубежных операций привели к росту операционной прибыли.</pre>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection title="2023 财务绩效" color="black" size="56px" v-else-if="nationCode == '_CN'">
    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <OverviewCardWithIcon
          v-for="(it, idx) in items_cn"
          :key="idx"
          :title="it.title"
          :mainTxt="it.mainTxt"
          :addTxt="it.addTxt"
          :iconSrc="it.iconSrc"
        />
        <OverviewBigCardWithIcon
          v-for="(it, idx) in bigItems_cn"
          :key="idx"
          :title1="it.title1"
          :mainTxt1="it.mainTxt1"
          :title2="it.title2"
          :mainTxt2="it.mainTxt2"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="financial-performance-2022-txt ctt-t2">
2023年，LX Hausys实现了26.1亿美元的销售额，较去年同期下降2.4%。尽管如此，营业利润却达到了8130万美元，
同比增长635.1%。由于韩国建筑和房地产市场等终端市场的收缩，销售额较2022年略有下滑。但得益于核心原材料
成本的降低、高性能建筑隔热材料销量的提升，以及海外业务盈利能力的增强，营业利润实现了提升。</pre>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewBigCardWithIcon from "../../components/OverviewBigCardWithIcon.vue";
import OverviewCardWithIcon from "../../components/OverviewCardWithIcon.vue";
import OverviewSection from "../../components/OverviewSection.vue";
import OverviewSectionItem from "../../components/OverviewSectionItem.vue";

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    OverviewCardWithIcon,
    OverviewBigCardWithIcon,
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      items: [
        {
          title: "Total Sales",
          mainTxt: "$2.61 billion",
          addTxt: "(KRW 3525 billion)",
          iconSrc: "images/aboutus/overview/performance/total_sales_icon.svg",
        },
        {
          title: "Profits",
          mainTxt: "$81.3 million",
          addTxt: "(KRW 109.8 billion)",
          iconSrc: "images/aboutus/overview/performance/profits_icon.svg",
        },
        {
          title: "Employees in Korea",
          mainTxt: "2,886 people",
          iconSrc: "images/aboutus/overview/performance/employees_in_korea_icon.svg",
        },
        {
          title: "Employees overseas",
          mainTxt: "830 people",
          iconSrc: "images/aboutus/overview/performance/employees_overseas_icon.svg",
        },
      ],
      bigItems: [
        {
          title1: "Overseas Sales",
          mainTxt1: "$681 million",
          title2: "Ratio of Overseas sales",
          mainTxt2: "26%",
          iconSrc: "images/aboutus/overview/performance/big_card_icon.svg",
        },
      ],
      items_jp: [
        {
          title: "総売上高",
          mainTxt: "$2.61 billion",
          addTxt: "(KRW 3525 billion)",
          iconSrc: "images/aboutus/overview/performance/total_sales_icon.svg",
        },
        {
          title: "利益",
          mainTxt: "$81.3 million",
          addTxt: "(KRW 109.8 billion)",
          iconSrc: "images/aboutus/overview/performance/profits_icon.svg",
        },
        {
          title: "韓国の従業員",
          mainTxt: "2,886 people",
          iconSrc: "images/aboutus/overview/performance/employees_in_korea_icon.svg",
        },
        {
          title: "海外従業員",
          mainTxt: "830 people",
          iconSrc: "images/aboutus/overview/performance/employees_overseas_icon.svg",
        },
      ],
      bigItems_jp: [
        {
          title1: "海外販売",
          mainTxt1: "$681 million",
          title2: "海外売上高比率",
          mainTxt2: "26%",
          iconSrc: "images/aboutus/overview/performance/big_card_icon.svg",
        },
      ],
      items_ru: [
        {
          title: "Общий объем продаж",
          mainTxt: "$2.61 billion",
          addTxt: "(KRW 3525 billion)",
          iconSrc: "images/aboutus/overview/performance/total_sales_icon.svg",
        },
        {
          title: "Прибыль",
          mainTxt: "$81.3 million",
          addTxt: "(KRW 109.8 billion)",
          iconSrc: "images/aboutus/overview/performance/profits_icon.svg",
        },
        {
          title: "Сотрудники в Корее",
          mainTxt: "2,886 people",
          iconSrc: "images/aboutus/overview/performance/employees_in_korea_icon.svg",
        },
        {
          title: "Сотрудники за рубежом",
          mainTxt: "830 people",
          iconSrc: "images/aboutus/overview/performance/employees_overseas_icon.svg",
        },
      ],
      bigItems_ru: [
        {
          title1: "Продажи за рубежом",
          mainTxt1: "$681 million",
          title2: "Доля зарубежных продаж",
          mainTxt2: "26%",
          iconSrc: "images/aboutus/overview/performance/big_card_icon.svg",
        },
      ],
      items_cn: [
        {
          title: "总销售额",
          mainTxt: "$2.61 billion",
          addTxt: "(KRW 3525 billion)",
          iconSrc: "images/aboutus/overview/performance/total_sales_icon.svg",
        },
        {
          title: "营业利润",
          mainTxt: "$81.3 million",
          addTxt: "(KRW 109.8 billion)",
          iconSrc: "images/aboutus/overview/performance/profits_icon.svg",
        },
        {
          title: "韩国员工",
          mainTxt: "2,886 people",
          iconSrc: "images/aboutus/overview/performance/employees_in_korea_icon.svg",
        },
        {
          title: "海员员工",
          mainTxt: "830 people",
          iconSrc: "images/aboutus/overview/performance/employees_overseas_icon.svg",
        },
      ],
      bigItems_cn: [
        {
          title1: "海外销售",
          mainTxt1: "$681 million",
          title2: "海外销售额比例",
          mainTxt2: "26%",
          iconSrc: "images/aboutus/overview/performance/big_card_icon.svg",
        },
      ],
    };
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
};
</script>

<style scoped>
.financial-performance-2022-txt.ctt-t2 {
  text-align: center;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

.card-area {
  display: flex;
  width: 1440px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .card-area {
    flex-direction: column;
    width: 90%;
  }
  .financial-performance-2022-txt.ctt-t2 {
    white-space: pre-wrap;
    line-height: 1.25rem;
  }
}
</style>
