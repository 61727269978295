var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("orderHistory.detail.text.home")))]
            ),
            _c("li", [
              _vm._v(_vm._s(_vm.$t("orderHistory.detail.text.header"))),
            ]),
            _c("li", [
              _vm._v(_vm._s(_vm.$t("orderHistory.detail.text.order_history"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "container mypg_order_history" }, [
          _c("div", { staticClass: "w-con" }, [
            _c("div", { staticClass: "mypg-tab-con" }, [
              _c("div", { staticClass: "mypg-ordhstry" }, [
                _c("div", { staticClass: "mypg-oh-dtinfo" }, [
                  _c("ul", [
                    _c("li", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("orderHistory.detail.text.order_number")
                          )
                        ),
                        _c("em", [_vm._v(_vm._s(_vm.orderId))]),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("orderHistory.detail.text.order_date"))
                        ),
                        _c("em", [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                _vm.order.registDate,
                                "MM.DD.YYYY HH:mm:ss"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("orderHistory.detail.text.status"))
                        ),
                        _c("em", [_vm._v(_vm._s(_vm.selectedStatus.text))]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "tbl-box type05" }, [
                  _c("table", [
                    _c("caption", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("orderHistory.detail.text.order_history_list")
                        )
                      ),
                    ]),
                    _vm._m(0),
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "ta-c" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("orderHistory.detail.text.product_info")
                            )
                          ),
                        ]),
                        _c("th", { staticClass: "ta-c" }, [
                          _vm._v(
                            _vm._s(_vm.$t("orderHistory.detail.text.option"))
                          ),
                        ]),
                        _c("th", { staticClass: "ta-c" }, [
                          _vm._v(
                            _vm._s(_vm.$t("orderHistory.detail.text.quantity"))
                          ),
                        ]),
                        _c("th", { staticClass: "ta-c" }, [
                          _vm._v(
                            _vm._s(_vm.$t("orderHistory.detail.text.each"))
                          ),
                        ]),
                        _c("th", { staticClass: "ta-c" }, [
                          _vm._v(
                            _vm._s(_vm.$t("orderHistory.detail.text.total"))
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.itemList, function (item) {
                        return _c("tr", [
                          _c("td", [
                            _c("div", { staticClass: "mypg-oh-detail" }, [
                              _c("div", { staticClass: "img-box" }, [
                                item.image
                                  ? _c("img", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        src: _vm.$imagePath + item.image,
                                        alt: "Product image",
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "info" }, [
                                _c("ul", [
                                  !item.category
                                    ? _c("li", { staticClass: "txt-comp" })
                                    : _c("li", { staticClass: "txt-comp" }, [
                                        _vm._v(_vm._s(item.category + "®")),
                                      ]),
                                  _c("li", { staticClass: "txt-code" }, [
                                    _vm._v(_vm._s(item.colorCode)),
                                  ]),
                                  _c("li", { staticClass: "txt-prod" }, [
                                    _vm._v(_vm._s(item.colorNm)),
                                  ]),
                                  _c("li", { staticClass: "txt-cate" }, [
                                    _vm._v(_vm._s(item.collection)),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("td", { staticClass: "ta-c" }, [
                            _vm._v(_vm._s(item.sampleSize)),
                          ]),
                          _c("td", { staticClass: "ta-c" }, [
                            _vm._v(_vm._s(item.qty)),
                          ]),
                          _c("td", { staticClass: "ta-c" }, [
                            _vm._v(_vm._s(item.price)),
                          ]),
                          !item.totalPrice
                            ? _c("td", { staticClass: "ta-c txt-tot" })
                            : _c("td", { staticClass: "ta-c txt-tot" }, [
                                _vm._v(_vm._s("$ " + item.totalPrice)),
                              ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "mypg-ohdt-info" }, [
                  _c("p", { staticClass: "tt-4" }, [
                    _vm._v(
                      _vm._s(_vm.$t("orderHistory.detail.text.payment_info"))
                    ),
                  ]),
                  _c("div", { staticClass: "tbl-01" }, [
                    _c("div", { staticClass: "tbl-box type03" }, [
                      _c("table", [
                        _c("caption", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("orderHistory.detail.text.shipping_form")
                            )
                          ),
                        ]),
                        _vm._m(1),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "orderHistory.detail.text.order_subtotal"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "ta-r" }, [
                              _vm._v(_vm._s("$" + _vm.grandTotal)),
                            ]),
                            _c(
                              "th",
                              { attrs: { scope: "row", rowspan: "2" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "orderHistory.detail.text.grand_total"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ta-r txt-tot",
                                attrs: { rowspan: "2" },
                              },
                              [_vm._v(_vm._s("$" + _vm.grandTotal))]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "mypg-ohdt-info" }, [
                  _c("p", { staticClass: "tt-4" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("orderHistory.detail.text.shipping_loc_info")
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "tbl-box type02" }, [
                    _c("table", [
                      _c("caption", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("orderHistory.detail.text.product_specs")
                          )
                        ),
                      ]),
                      _vm._m(2),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("orderHistory.detail.text.first_name")
                              )
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.order.firstNm))]),
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("orderHistory.detail.text.last_name")
                              )
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.order.lastNm))]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("orderHistory.detail.text.email"))
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.order.email))]),
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("orderHistory.detail.text.phone"))
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.order.phone))]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("orderHistory.detail.text.zip"))
                            ),
                          ]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(_vm._s(_vm.order.zipCd)),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "orderHistory.detail.text.country_n_state"
                                )
                              )
                            ),
                          ]),
                          !_vm.selectedCountry.countryNm ||
                          !_vm.selectedState.stateNm
                            ? _c("td")
                            : _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectedCountry.countryNm +
                                      " / " +
                                      _vm.selectedState.stateNm
                                  )
                                ),
                              ]),
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("orderHistory.detail.text.city"))
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.order.city))]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("orderHistory.detail.text.addr1"))
                            ),
                          ]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(_vm._s(_vm.order.addr1)),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("orderHistory.detail.text.addr2"))
                            ),
                          ]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _vm._v(_vm._s(_vm.order.addr2)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col", { staticStyle: { width: "150px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "185px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "185px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "185px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "185px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }