<template>
  <ProductLayout>
    <template #content>
      <!-- 상단 이미지 -->
      <ProductTopImage
        title="Lightweight Component"
        description="Eco friendly, fuel-efficient lightweight car components with high steel rigidity"
        descriptionWidth="200px"
        imageSrc="images/automotive/lightweight/top.png"
      />

      <!-- 제품 상세 영역 -->
      <ProductContent>
        <template>
          <!-- 소개 영역 -->
          <ProductSectionHeader
            class="mt-80"
            subTitle="We provide lightweight materials consisting of reinforced plastics, which maintain the strength of conventional steel and contribute to cut fuel consumption by reducing the weight of components."
            subTitleWidth="850px"
          />

          <!-- 제품 강점 영역 -->
          <ProductStrongGroup
            class="mt-100"
            iconType="vertical"
            :info="[
              {
                iconSrc: 'images/automotive/lightweight/strong-point-icon-1.png',
                title: 'Lightweight',
                description: 'Reducing weight by 25% against conventional steel'
              },
              {
                iconSrc: 'images/automotive/lightweight/strong-point-icon-2.png',
                title: 'High strength',
                description: 'Maintaining the strength of conventional steel'
              },
              {
                iconSrc: 'images/automotive/lightweight/strong-point-icon-3.png',
                title: 'Cost reduction',
                description: 'Reducing the number of parts through design optimization'
              }
            ]"
          />

          <!-- Lightweight Component 영역 -->
          <LightweightComponent class="mt-100" />

          <!-- Lightweight Material 영역 -->
          <LightweightMaterial class="mt-100" />

          <!-- 하단 소개 페이지 -->
          <!-- <FooterNavigation /> -->
        </template>
      </ProductContent>
    </template>
  </ProductLayout>
</template>

<script>
import ProductLayout from '@/components/Layout/ProductLayout'
import ProductTopImage from '@/components/product/ProductTopImage'
import ProductContent from '@/components/product/ProductContent'
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductStrongGroup from '@/components/product/ProductStrongGroup'
import LightweightComponent from './components/LightweightComponent'
import LightweightMaterial from './components/LightweightMaterial'
import FooterNavigation from '@/views/products/automotive/AutomotiveSkin/components/FooterNavigation.vue'

export default {
  components: {
    FooterNavigation,
    ProductLayout,
    ProductTopImage,
    ProductContent,
    ProductSectionHeader,
    ProductStrongGroup,
    LightweightComponent,
    LightweightMaterial
  }
}

</script>
