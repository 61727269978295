<template>
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainability_management/top_image.png"
    title="Sustainability Management"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
  <template #content>
    <OverviewSection>
      <OverviewText
        type="ft-20"
        :text="text"
      />
    </OverviewSection>

    <div class="box-container">
      <div class="box-div" ref="box1">
        <SustainabilityManagementTabBox @move="moveToBox('box2')"
          v-for="(box, index) in tabBox1" 
          :key="index"
          :content="box.content"
          :isActive="box.isActive"
        />
      </div>
    </div>

    <GhgEmissionReduction />
    <EnergyComsumptionMinimization />
    <EnvironmentalInvestment />

    <div class="box-container">
      <div class="box-div" ref="box2">
        <SustainabilityManagementTabBox @move="moveToBox('box1')"
          v-for="(box, index) in tabBox2"
          :key="index"
          :content="box.content"
          :isActive="box.isActive"
        />
      </div>
    </div>

    <PollutantManagement />
    <ExpandedWasteRecycling />
  </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainability_management/top_image.png"
    title="サステナビリティ経営"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <OverviewSection>
        <OverviewText
          type="ft-20"
          :text="textJp"
        />
      </OverviewSection>

      <div class="box-container">
        <div class="box-div" ref="box1">
          <SustainabilityManagementTabBox @move="moveToBox('box2')"
                                          v-for="(box, index) in tabBox1Jp"
                                          :key="index"
                                          :content="box.content"
                                          :isActive="box.isActive"
          />
        </div>
      </div>

      <GhgEmissionReduction />
      <EnergyComsumptionMinimization />
      <EnvironmentalInvestment />

      <div class="box-container">
        <div class="box-div" ref="box2">
          <SustainabilityManagementTabBox @move="moveToBox('box1')"
                                          v-for="(box, index) in tabBox2Jp"
                                          :key="index"
                                          :content="box.content"
                                          :isActive="box.isActive"
          />
        </div>
      </div>

      <PollutantManagement />
      <ExpandedWasteRecycling />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainability_management/top_image.png"
    title="Управление устойчивым развитием"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <OverviewSection>
        <OverviewText
          type="ft-20"
          :text="textRu"
        />
      </OverviewSection>

      <div class="box-container">
        <div class="box-div" ref="box1">
          <SustainabilityManagementTabBox @move="moveToBox('box2')"
                                          v-for="(box, index) in tabBox1Ru"
                                          :key="index"
                                          :content="box.content"
                                          :isActive="box.isActive"
          />
        </div>
      </div>

      <GhgEmissionReduction />
      <EnergyComsumptionMinimization />
      <EnvironmentalInvestment />

      <div class="box-container">
        <div class="box-div" ref="box2">
          <SustainabilityManagementTabBox @move="moveToBox('box1')"
                                          v-for="(box, index) in tabBox2Ru"
                                          :key="index"
                                          :content="box.content"
                                          :isActive="box.isActive"
          />
        </div>
      </div>

      <PollutantManagement />
      <ExpandedWasteRecycling />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainability_management/top_image.png"
    title="可持续经营"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <OverviewSection>
        <OverviewText
          type="ft-20"
          :text="textCn"
        />
      </OverviewSection>

      <div class="box-container">
        <div class="box-div" ref="box1">
          <SustainabilityManagementTabBox @move="moveToBox('box2')"
                                          v-for="(box, index) in tabBox1Cn"
                                          :key="index"
                                          :content="box.content"
                                          :isActive="box.isActive"
          />
        </div>
      </div>

      <GhgEmissionReduction />
      <EnergyComsumptionMinimization />
      <EnvironmentalInvestment />

      <div class="box-container">
        <div class="box-div" ref="box2">
          <SustainabilityManagementTabBox @move="moveToBox('box1')"
                                          v-for="(box, index) in tabBox2Cn"
                                          :key="index"
                                          :content="box.content"
                                          :isActive="box.isActive"
          />
        </div>
      </div>

      <PollutantManagement />
      <ExpandedWasteRecycling />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import OverviewText from '../../overview/components/OverviewText.vue'
import EnergyComsumptionMinimization from './components/EnergyComsumptionMinimization.vue'
import EnvironmentalInvestment from './components/EnvironmentalInvestment.vue'
import ExpandedWasteRecycling from './components/ExpandedWasteRecycling.vue'
import GhgEmissionReduction from './components/GhgEmissionReduction.vue'
import PollutantManagement from './components/PollutantManagement.vue'
import OverviewSection from '../../overview/components/OverviewSection.vue'
import SustainabilityManagementTabBox from './components/SustainabilityManagementTabBox.vue'

export default {
  components: {
    OverviewLayout,
    GhgEmissionReduction,
    EnergyComsumptionMinimization,
    EnvironmentalInvestment,
    PollutantManagement,
    ExpandedWasteRecycling,
    OverviewSection,
    SustainabilityManagementTabBox,
    OverviewText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'LX Hausys is striving to fulfill its social responsibility through eco-friendly management by manufacturing products\n' +
            'with recycled and reused materials and developing products based on the Life Cycle Assessment,\n' +
            'thereby enhancing eco-friendliness in the product development and production process. All business sites of\n' +
            'LX Hausys implement efforts to save energy and use renewable energy based on its annual energy\n' +
            'reduction target and will continue to strengthen their eco-friendly business activities through an increase in the use of\n' +
            'recycled materials and substitution of chemical substances.',
      textJp:'LX Hausysは、リサイクル・リユース材料を使用した製品の製造やライフサイクルアセスメントに基づいた製品開発など、\n' +
              '環境に配慮した経営を通じて社会的責任を果たすよう努め、製品開発・生産プロセスにおける環境配慮性を高めています。\n' +
              'LX Hausysの全事業所では、年間エネルギー削減目標に基づき、省エネや再生可能エネルギーの利用に取り組んでおり、\n' +
              'リサイクル材料の使用増加や化学物質の代替など、環境に配慮した事業活動を強化していきます。' ,
      textRu:'LX Hausys стремится реализовывать свою социальную ответственность через экологичное управление, производя продукцию\n'+
              'из переработанных и повторно используемых материалов и разрабатывая продукцию на основе оценки жизненного цикла,\n'+
              'тем самым повышая экологичность процесса разработки и производства продукции. Все предприятия компании\n'+
              'LX Hausys предпринимают усилия по экономии энергии и использованию возобновляемых \n' +
              'источников энергии в соответствии с годовым планом по снижению энергопотребления. \n'+ 
              'И будут продолжать укреплять свою экологическую деятельность путем увеличения использования\n'+ 
              'переработанных материалов и замены химических веществ.', 
      textCn:'LX Hausys通过环保管理履行其社会责任，使用可回收和可重复使用的材料制造产品，\n'+
              '并根据生命周期评估开发产品，从而增强产品开发和生产过程中的生态友好性。\n'+
              'LX Hausys的所有营业场所都根据其年度节能目标，努力节约能源和使用可再生能源，\n'+
              '并将通过增加回收材料的使用和化学物质的替代品，继续加强其环保业务活动。\n',
      tabBox1: [
        {
          content: 'Responses to Climate Change',
          isActive: true
        },
        {
          content: 'Reduced Environmental Impacts',
          isActive: false
        }
      ],
      tabBox2: [
        {
          content: 'Responses to Climate Change',
          isActive: false
        },
        {
          content: 'Reduced Environmental Impacts',
          isActive: true
        }
      ],
      tabBox1Jp: [
        {
          content: '気候変動への対応',
          isActive: true
        },
        {
          content: '環境への影響の軽減',
          isActive: false
        }
      ],
      tabBox2Jp: [
        {
          content: '気候変動への対応',
          isActive: false
        },
        {
          content: '環境への影響の軽減',
          isActive: true
        }
      ],
      tabBox1Ru: [
        {
          content: 'Реакция на изменение климата',
          isActive: true
        },
        {
          content: 'Снижение воздействия на окружающую среду',
          isActive: false
        }
      ],
      tabBox2Ru: [
        {
          content: 'Реакция на изменение климата',
          isActive: false
        },
        {
          content: 'Снижение воздействия на окружающую среду',
          isActive: true
        }
      ],
      tabBox1Cn: [
        {
          content: '应对气候变化',
          isActive: true
        },
        {
          content: '减少环境影响',
          isActive: false
        }
      ],
      tabBox2Cn: [
        {
          content: '应对气候变化',
          isActive: false
        },
        {
          content: '减少环境影响',
          isActive: true
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  methods: {
    moveToBox (element) {
      this.$refs[element].scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style scoped>
.box-container {
  width: 1440px;
  margin: 0 auto;
}

.box-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
}

@media (max-width: 768px) {
  .box-container {
    width: 90%;
  }
  .box-div {
    align-items: normal;
  }
}
</style>
