<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">Sustainability Management System</h1>

    <!-- content -->
    <div class="content" v-html="sustainabilityManagementSystem"></div>

    <!-- content -->

<!--    <div class="content au-view">&lt;!&ndash; location &ndash;&gt;-->
<!--      <div class="location">-->
<!--        <ul>-->
<!--          <li class="ico-home">Home</li>-->
<!--          <li>About us</li>-->
<!--          <li>Sustainability</li>-->
<!--          <li>Sustainability Management System</li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      &lt;!&ndash; //location &ndash;&gt; &lt;!&ndash; container (Sustainability Management System) &ndash;&gt;-->
<!--      <div class="container au-box au-box02">-->
<!--        <div class="w-con-02 ba-text sms-box">-->
<!--          <p class="tt-1 tac">Sustainability Management System</p>-->
<!--          <div class="t-list-box t-type-01 mt-50">LX Hausys&rsquo; sustainability management takes the company&rsquo;s vision, &ldquo;creating human-friendly, eco-conscious living spaces&rdquo;, as its basis. We strive to fulfill our social responsibility while at the same time effectively controlling risks and providing peerless value to stakeholders.</div>-->
<!--          <div class="t-list-box">-->
<!--            <h2 class="tit-04">Sustainability Management Promotion System</h2>-->
<!--            <div class="slider-sms">-->
<!--              <div class="slider">-->
<!--                <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_vision.png" alt="Vision" /></div>-->
<!--                <div class="img-box m-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_m_vision.png" alt="Vision" /></div>-->
<!--                <div class="txt-box">-->
<!--                  <p class="tit ico-01">Vision</p>-->
<!--                  <div class="txt">We create human-friendly, <br />eco-conscious living spaces</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="slider">-->
<!--                <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_goal.png" alt="GOAL" /></div>-->
<!--                <div class="img-box m-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_m_goal.png" alt="GOAL" /></div>-->
<!--                <div class="txt-box">-->
<!--                  <p class="tit ico-02">GOAL</p>-->
<!--                  <div class="txt">To achieve a sustainable company <br />in harmony with stakeholders</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="slider slider-tasks">-->
<!--                <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_tasks_01.png" alt="TASKS 01" /></div>-->
<!--                <div class="img-box m-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_m_tasks_01.png" alt="TASKS 01" /></div>-->
<!--                <div class="txt-box">-->
<!--                  <p class="tit ico-03">TASKS</p>-->
<!--                  <div class="txt">-->
<!--                    <p>Risk Management</p>-->
<!--                    <div class="t-type-03">LX Hausys conduct thorough and effective risk management activities in order to early identify and solve any issues that arise. Nine areas are subject to review: Governance, Ethics management, Shared growth, Fair trade, Customer value, Social contribution, Labor conditions and Human rights, Safety and health, and Environmental management. Immediate action was taken to resolve any risks predicted and/or discerned through such inspections/diagnosis, and these areas are subject to regular monitoring to ensure the issues(s) have been effectively resolved.</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="slider slider-tasks">-->
<!--                <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_tasks_02.png" alt="TASKS 02" /></div>-->
<!--                <div class="img-box m-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_m_tasks_02.png" alt="TASKS 02" /></div>-->
<!--                <div class="txt-box">-->
<!--                  <p class="tit ico-03">TASKS</p>-->
<!--                  <div class="txt">-->
<!--                    <p>Social Contribution</p>-->
<!--                    <div class="t-type-03">LX Hausys has carried out various CSR activities. We are acutely aware of the impact of our business activities on society and people and utilize our CSR activities to nurture our relationships with local communities.</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="slider slider-tasks">-->
<!--                <div class="img-box p-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_tasks_03.png" alt="TASKS 03" /></div>-->
<!--                <div class="img-box m-ui"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/Sustainability/img_m_tasks_03.png" alt="TASKS 03" /></div>-->
<!--                <div class="txt-box">-->
<!--                  <p class="tit ico-03">TASKS</p>-->
<!--                  <div class="txt">-->
<!--                    <p>Stakeholder Communication</p>-->
<!--                    <div class="t-type-03">LX Hausys communicates with its major stakeholders - customers, shareholders and investors, business partners, employees, and local communities &ndash;through tailored channels. We ensure transparency by making widely available literature on the activities and outcomes of our business and other activities (eg. Sustainability reports, Business reports, Environmental information reports). Such transparent information disclosure enables us to gain the trust and respect of our valued stakeholders, and promote our CSR activities and various training programs.</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; //container (Sustainability Management System) &ndash;&gt;</div>-->

    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div><!-- //wrap -->
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import ProductService from '@/server/product.service.js'
import GnbComponent from '@/components/Layout/GNB'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      sustainabilityManagementSystem: ''
    }
  },
  async mounted () {
    const _this = this

    const contentResInfo = await ProductService.getCategoryCode('SUSTAINABILITY_MANAGEMENT_SYSTEM' + _this.$countryCode)
    const contentRes = await ProductService.getContentList(contentResInfo.data.object.id)
    _this.sustainabilityManagementSystem = contentRes.data.list[0].attribute.editor

    this.$nextTick(function () {
      if (this.isMobile()) {
        /* slide */
        $('.slider-sms').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          draggable: false
        })
        console.log('mobile')
      } else {
        /* slide */
        $('.slider-sms').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          draggable: false
        })
        console.log('pc')
      }

      $('.slick-dots li:nth-child(4), .slick-dots li:nth-child(5)').addClass('btn-tasks')

      $('.slick-arrow, .btn-tasks').click(function () {
        $('.slick-dots li:nth-child(3)').removeClass('on')

        if ($('.slider-tasks').hasClass('slick-active')) {
          $('.slick-dots li:nth-child(3)').addClass('on')
        }
      })
    })
  },
  methods: {
    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    }
  }
}
</script>
