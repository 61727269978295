<template>
  <div id="wrap" class="micro-page">
    <header class="scr-hder on micro">
      <div class="clear homecenter-header">
        <div class="fl-l">
          <div class="logo">
            <a href="homecenter/viatera" class="go_home_for_pc">LX Hausys</a>
            <a href="homecenter/viatera" class="go_home_for_mobile" style="display: none">LX Hausys</a>
          </div>
        </div>
        <div class="fl-c">
        </div>
        <div class="fl-r">
          <div class="hd-btns01">
            <ul>
              <li class="search-line" @click="openSearch()">
                <input type="text" class="inp-text" placeholder="Enter Item Name or Color">
                <a href="javascript:void(0);" class="ico-01" title="Open Search Popup">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="clear homecenter-tab">
        <div class="fl-l">
          <div class="header-tab">
            <div class="tab-himacs">
              <a href="homecenter/himacs">
                <img
                  src="https://img.lxhausys.com/images/HomeDepot/himacs_off.png"
                  alt="himacs"
                />
              </a>
            </div>
            <div class="tab-viatera">
              <a href="homecenter/viatera">
                <img
                  src="https://img.lxhausys.com/images/HomeDepot/viatera.png"
                  alt="viatera"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="fl-c"></div>
        <div class="fl-r">
          <div class="virtual-showroom-btn-area">
            <button class="vs-btn" type="button" tabindex="0" @click="goToSchedule()">
              <span class="upper">SCHEDULE CONSULTATION</span>
              <img src="https://img.lxhausys.com/public/images/arrw_right_09.png" alt="arrow icon" class="arrow-right">
            </button>
          </div>
        </div>
      </div>
      <HDPSearchPopComponent></HDPSearchPopComponent>
      <CartPopComponent></CartPopComponent>
    </header>

    <!-- 스크린리더에만 읽힘 페이지에 안보임 -->
    <h1 class="sr-only">Home Depot Viatera</h1>

    <!-- content -->
    <div class="content prod-dt-cate no-nav">

      <!-- container (micro pf) -->
      <div class="pro-dt-warp">
        <div class="container prod-dt-view himacs-pd micro-himacs-pd micro_pd_container">

          <!-- visual slide -->
          <div class="w-con micro_product_visual">
            <div class="row">
              <!-- images -->
              <div class="prod-img">
                <div class="slider-prod viartW">
                  <div
                    class="slider pdf-inslide03"
                    v-for="(fileName, index) in imgPage"
                    v-bind:key="index"
                    v-show="imgShow === true"
                  >
                    <div class="img_wrap">
                      <img
                        v-if="fileName.image != null && fileName.image != undefined && fileName.image != ''  && !fileName.image.startsWith('https://www.youtube.com')"
                        :src="$imagePath + fileName.image"
                        alt="homecenter viatera images"
                      />
                    </div>
                    <div class="yt-video"
                         v-if="fileName.image != null && fileName.image != undefined && fileName.image != '' && fileName.image.startsWith('https://www.youtube.com')"
                         :style="'max-width:'+youtubeWidth+';' + 'margin: 0 auto'">
                      <iframe autoplay="autoplay"
                              muted="muted"
                              controls
                              allowscriptaccess="always"
                              style="width:auto; min-width: 100%; min-height: 100%"
                              :src="fileName.image + '?enablejsapi=1'"
                              alt="youtube"
                              class="yt_player_iframe"
                      />
                    </div>

                  </div>
                </div>
                <!-- //images -->

                <!-- thumbnail -->
                <div class="slider-prod-nav navWide tac"
                     v-bind:class="{euNavWide: nationCode != '_GL' && nationCode != '_US'}">
                  <div
                    class="slider"
                    v-for="(fileName, index) in imgPage"
                    v-bind:key="index"
                    v-show="imgShow === true"
                  >
                    <img
                      :data-chk="index"
                      v-if="fileName.image != null && fileName.image != undefined && fileName.image != '' && fileName.thumb != null && fileName.thumb != '' "
                      :src="$imagePath + fileName.thumb"
                      alt="images thumbnail"
                    />
                  </div>
                </div>
                <!-- //thumbnail -->
              </div>
              <!-- product info -->
              <div class="prod-info">
                <div class="prod_info_head">
                  <div class="info-01">
                    <span class="tag h30 new" v-if="isNew == 'true'">{{ $t('PCD.text.new') }}</span>
                    <span class="tag h30 viatera">{{ $t('PCD.text.viatera') }}</span>
                    <span class="tag h30 exclusive" v-if="isExclusive == 'true'">hd exclusive</span>
                    <span class="tag h30 disColor" v-if="discontinued == 'true'"
                    >{{ $t('PD.text.discontinued_Color') }}</span
                    >
                  </div>
                  <div class="btns">
                    <ul>
                      <li id="imgDownload">
                        <a class="icon-download-bk" @click="imgDownload(Tslider)"
                        >{{ $t('PD.text.image_download_view') }}</a
                        >
                      </li>
                      <li>
                        <ShareComponent
                          v-bind:childValue="contentList"
                          :type="'product'"
                        ></ShareComponent>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="prod_info_body">
                  <h1 class="title">{{ contentName }}</h1>
                  <div class="info-01">
                    <span class="prod_collection">{{ collection }} {{ $t('PCD.text.collection') }}</span>
                  </div>
                  <p class="info-03 ctt ctt-t4" v-html="brief"></p>
                </div>

                <div class="info-01 v-al-md">
                  <span class="aurora-icon" v-if="nationCode != '_US' && nationCode != '_GL' && Aurora == true">
                    <img :src='`images/products/PD_Aurora_icon.png`'>
                  </span>
                  <span class="txt">{{ colorCode }}</span>
                </div>

                <div class="">
                  <div>
                    <div class="info-02 us-info-02">
                      <div class="over-box">
                        <button
                          type="button"
                          class="ico-fi over"
                          @click="showGuide(showFinish)"
                        >
                          <span class="ctt ctt-t4" v-for="(item, index) in finish">{{ item }}
                            <span
                              v-if="
                                finish.length > 1 && finish.length != index + 1
                              "
                            >
                              /
                            </span>
                          </span>
                          <span class="ctt ctt-t4"> {{ $t('PD.text.finish') }}</span>
                        </button>
                        <div class="view guide-box" v-show="showFinish">
                          <button
                            type="button" class="btn"
                            @click="finishLayer()"
                          >
                            <span class="ctt ctt-t4">{{ $t('PD.text.finishGuide') }}</span>
                          </button>
                        </div>
                      </div>

                      <div class="ico-color" v-if="nationCode == '_US' || nationCode == '_GL'">
                        <div
                          class="color"
                          v-bind:style="{
                            borderColor: '#999',
                            backgroundColor: hue,
                          }"
                        ></div>
                        <div class="txtinfo colorTxt">
                          <span class="ctt ctt-t4" v-if="nationCode == '_US' || nationCode == '_GL'">{{
                              hueText
                            }}</span>
                          <span class="ctt ctt-t4">{{ $t('PD.text.color') }}</span>
                        </div>
                      </div>
                      <div class="ico-pro-lucent" v-if="nationCode != '_US' && nationCode != '_GL' && lucent == 'true'">
                        <span class="ctt ctt-t4">{{ $t('PD.text.Lucent') }}</span>
                      </div>
                      <div class="ico-pro-veined" v-if="nationCode != '_US' && nationCode != '_GL' && veined == 'true'">
                        <span class="ctt ctt-t4">{{ $t('PD.text.Veined') }}</span>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <!-- //product info -->
            </div>
          </div>
          <!-- //visual slide -->

          <div class="w-con">
            <div>
              <div class="tbl-box-prodetail">
                <table>
                  <caption>
                    {{ $t('PD.text.product_Specs') }}
                  </caption>
                  <colgroup>
                    <col style="width: 30%"/>
                    <col style="width: 70%"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th scope="col" colspan="2"><h2>{{ $t('PD.text.product_Specs') }}</h2></th>
                  </tr>
                  </thead>

                  <!-- US / GL 스펙 전용 -->
                  <tbody>
                  <tr>
                    <th scope="row">{{ $t('PD.text.dimension') }}</th>
                    <td class="ctt ctt-t5">{{ dimension }}</td>
                  </tr>
                  <tr v-if="source == 'true'">
                    <th scope="row">{{ $t('PD.text.remark') }}</th>
                    <td class="ctt ctt-t5">{{ remark }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t('PD.text.pattern') }}</th>
                    <td class="ctt ctt-t5">{{ pattern }}</td>
                  </tr>
                  <tr v-if="nationCode === '_GL'">
                    <th scope="row">{{ $t('PD.text.available_Countries') }}</th>
                    <td class="ctt ctt-t5">
                      {{ countryCode }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="ta-c prd-btn-more">
                      <!-- <button type="button" class="btn btn-text btn-text-more">
                        <span>See More</span>
                      </button> -->
                    </td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

          <div class="w-con inner">
            <!-- short cut -->
            <div class="proDtail">
              <div class="short-link short-link-micro">
                <a class="p-link" target="_blank" :href="homedep_link"><span
                  class="ico-01">{{ $t('PCD.text.order_a_sample') }}</span></a>
                <a class="p-link" target="_blank" :href="buildnbuy_link">
                  <span class="ico-build-buy">BUILD & BUY</span>
                </a>
                <a class="p-link" target="_blank" :href="schedule_link">
                  <span class="ico-sche-con">SCHEDULE CONSULTATION</span>
                </a>
                <!-- <a class="p-link" @click="goToVirtual()">
                  <span class="ico-06">{{ $t('PD.button.virtual_Showroom')}}</span>
                </a> -->
              </div>
            </div>
            <!-- //short cut -->
          </div>

          <div class="w-con certification">
            <!-- certification -->
            <div class="certification_section full_width" v-if="pcdContentAttribute.cert_use_yn === 'true'">
              <div class="inner_width">
                <div class="content_tit_area">
                  <h2 class="con_title tt-2">{{ pcdContentAttribute.cert_title }}</h2>
                </div>
                <div class="certi_group">
                  <ul>
                    <li v-for="(certItem, index) in pcdCertificationData">
                      <div class="item">
                        <img :src="$imagePath + certItem.image" :alt="certItem.description"/>
                      </div>
                    </li>
                  </ul>
                  <div class="btn_area"
                       v-if="pcdCertificationData.length && checkCertificationDescription('description')">
                    <!-- <template > -->
                    <button type="button" class="btn_learn_more" @click="openModalCerti()">
                      <span class="txt">Learn more</span>
                      <span class="icon icon_arrow_right"></span>
                    </button>
                    <!-- </template> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- //certification -->

            <!-- popup : Finish guide -->
            <div class="layer-popup layer-guide">
              <div class="popup pop-close">
                <div class="pop-guide scr-y ">
                  <p class="tit-02">{{ $t('PCD.popup.finishInfo') }}</p>

                  <div class="txt-01">
                    <p>{{ $t('PCD.popup.finishTitle') }}</p>

                    <p class="t-type-02">
                      {{ $t('PCD.popup.finishBrief') }}
                    </p>
                  </div>

                  <div class="col-02">
                    <div>
                      <p class="tt-3">{{ $t('PCD.popup.polished') }}</p>

                      <p class="ctt ctt-t4">{{ $t('PCD.popup.polished_brief') }}</p>

                      <div class="img-box">
                        <img src="images/products/@img_finish_01.png" alt="POLISHED"/>
                      </div>
                    </div>

                    <div>
                      <p class="tt-3">{{ $t('PCD.popup.brushed') }}</p>

                      <p class="ctt ctt-t4">
                        {{ $t('PCD.popup.brushed_brief') }}
                      </p>

                      <div class="img-box">
                        <img src="images/products/@img_finish_02.png" alt="BRUSHED"/>
                      </div>
                    </div>
                  </div>

                  <div class="col-02 " v-if="nationCode == '_GL'">
                    <div>
                      <p class="tt-3">{{ $t('PCD.popup.leather') }}</p>

                      <p class="ctt ctt-t4">
                        {{ $t('PCD.popup.leather_brief') }}
                      </p>

                      <div class="img-box">
                        <img src="images/products/@img_finish_04.png" alt="Leather"/>
                      </div>
                    </div>

                    <div>
                      <p class="tt-3">{{ $t('PCD.popup.velvet_Touch') }}</p>

                      <p class="ctt ctt-t4">
                        {{ $t('PCD.popup.velvet_Touch_brief') }}
                      </p>
                      <div class="img-box">
                        <img src="images/products/@img_finish_05.png" alt="Velvet Touch"/>
                      </div>
                    </div>
                  </div>

                  <div class="txt-02">
                    <!-- gl site class = gltype -->
                    <strong class="logo gltype">{{ $t('PCD.popup.finish_viatera') }}</strong>

                    <p>{{ $t('PCD.popup.finish_viatera_info') }}</p>

                    <p class="ctt ctt-t4">
                      {{ $t('PCD.popup.finish_viatera_brief') }}
                    </p>

                    <div class="img-box">
                      <img src="images/products/@img_finish_03.png" alt="Brushed Finish Care & Maintenance"/>
                    </div>

                    <p class="ctt ctt-t4">
                      {{ $t('PCD.popup.finish_viatera_brief_01') }}
                    </p>

                    <p class="ctt ctt-t4">
                      {{ $t('PCD.popup.finish_viatera_brief_02') }}
                    </p>

                    <p class="ctt ctt-t4">
                      {{ $t('PCD.popup.finish_viatera_brief_03') }}
                    </p>
                  </div>
                </div>

                <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t('PCD.button.close') }}
                </button>
              </div>
              <!-- //popup -->
            </div>
            <!-- //popup : Finish guide -->

            <!-- popup : enlarge -->
            <div class="layer-popup layer-enlarge">
              <!-- 이미지가 밝을 경우 'type02' 클래스를 추가해주세요. -->
              <div class="popup">
                <div class="title-box">
                  <p>{{ contentName }}</p>
                  <div class="btns">
                    <a
                      href="javascript:void(0);"
                      class="ico-fi"
                      v-for="item in finish"
                    >{{ item }} {{ $t('PD.text.finish') }}</a
                    >
                    <a href="javascript:void(0);" class="ico-st"
                    >{{ $t('PD.text.standard') }} - {{ dimension }}</a
                    >
                  </div>
                </div>

                <SeaDragon v-bind:imagePath="$imagePath + imgPage[0].detail" v-if="showDragon"></SeaDragon>

                <button
                  type="button"
                  class="btn-close"
                  @click="enlargeLayer()"
                >
                  {{ $t('PCD.button.close') }}
                </button>
              </div>
              <!-- //popup -->
            </div>
            <!-- //popup : enlarge -->
            <!-- S : popup : certification -->
            <div class="layer-view layer-pop popup-w1200 modal-certification"
                 v-if="pcdContentAttribute.cert_use_yn === 'true'">
              <div class="popup pop-close">
                <div class="scr-y">
                  <h3 class="popup-title p-ui">Certification Description</h3>
                  <h3 class="popup-title m-ui">Certification <br> Description</h3>
                  <div class="popup-body">
                    <div class="certi_pop_con">
                      <ul>
                        <li v-for="(certItem, index) in pcdCertificationData">
                          <div class="row">
                            <div class="img_wrap">
                              <img :src="$imagePath + certItem.image" :alt="certItem.description"/>
                            </div>
                            <div class="txt_wrap">
                              <div class="tit_box">{{ certItem.title }}</div>
                              <div class="txt_box">{{ certItem.description }}</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn-close" @click="closeModalCerti()">close</button>
              </div>
            </div>
            <!-- E : popup : certification -->
          </div>

        </div>
      </div>
      <!-- //container (wide ver) -->
    </div>
    <!-- //content -->
    <FooterComponent :isMicrosite=true></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import ShareComponent from '@/components/Layout/Share'
import CartPopComponent from '@/components/Popup/Cart'
import SampleOrderPopComponent from '@/components/Popup/SampleOrder'
import HDPSearchPopComponent from '@/components/Popup/Search/indexHDP'
import categoryService from '@/server/category.service'
import InspirationService from '@/server/inspiration.service'
import ProductService from '@/server/product.service.js'
import { isEmpty } from "lodash"
import { debounce } from 'vue-debounce'
import { mapState } from 'vuex'
import SeaDragon from './SeaDragon'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    CartPopComponent,
    HDPSearchPopComponent,
    FooterComponent,
    SampleOrderPopComponent,
    GnbComponent,
    ShareComponent,
    SeaDragon
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data() {
    return {
      metaDescription: '',
      is_show: false,
      is_show_02: false,
      is_show_03: false,
      is_show_04: false,
      is_show_05: false,
      youtubeWidth: '',
      youtubeHeight: '',
      youtubeCheck: false,
      filedown: '',
      sliderLength: '',
      homedep_link: '',
      buildnbuy_link: 'https://custom.homedepot.com/p/custom-countertops/Custom-Quartz-Countertop/310171064',
      schedule_link: 'https://www.homedepot.com/services/c/countertop-installation/6228e49a9?vfrom=tile',
      isExclusive: '',
      visibleImage: false,
      showDragon: false,
      contentName: '' /* 본 컨텐츠 내용 */,
      hue: '' /* 데이터 사전에서 받아온 값 */,
      hueText: '',
      collection: [] /* 데이터 사전에서 받아온 값  */,
      relhue: '',

      /* Us content */
      brief: '',
      dimension: '',
      remark: '',
      pattern: '',
      adCode: '',
      adName: '',
      countryCode: '',
      contentId: '',
      finish: '',
      showFinish: false,
      source: [],
      /* Us content end */

      /* APPLICATIONS 표시 여부 */
      applications: {list: []},
      /* CERTIFICATIONS 표시 여부 */
      certifications: {list: []},
      certList: [],
      imgPage: {list: []} /* 이미지 불러오기 */,
      Tslider: 0 /* 슬라이더 번호 추출 */,
      imgWidth: 0 /* 이미지 가로 */,
      imgHeight: 0 /* 이미지 세로 */,

      /* Related Flooring content */
      relContentList: [] /* 상품 관련 콘텐츠 리스트 */,
      /* Related Flooring content end */

      // inspiration List
      inspirationList: [],
      categoryCode: '',

      // document List
      installationGuideList: {disabled: false, list: []},
      warrantyList: {disabled: false, list: []},
      catalogueList: {disabled: false, list: []},
      technicalList: {disabled: false, list: []},
      fabricationList: {disabled: false, list: []},
      certificationList: {disabled: false, list: []},
      testReportList: {disabled: false, list: []},
      careMaintenanceList: {disabled: false, list: []},

      getfeaturesList: [],

      imgShow: false,

      nationCode: '',
      chkdata: [],
      isNew: '',
      discontinued: '',
      madeInUsa: '',
      colorCode: '',
      exclusive: '',
      imgThumb: [],
      imgImage: [],
      imgDetail: [],
      oldSource: {},

      featShow: false,

      contentList: [],

      thisContentLike: {content: {liked: false}},

      getSessionContent: [] /* GL : 불러오는 값 */,

      full_slap: [],
      cover: [],

      /* spec see more */
      flag: false,
      /* spec see more end */

      /* EU / UK 스펙 */

      thickness: [],
      tones: [],
      USE: [],
      notice: '',
      semi_translucent_colour: '',
      recycled: '',
      designer_series: '',
      lucent: '',
      veined: '',
      Aurora: '',

      indoor: '',
      outdoor: '',
      kitchen_and_bathroom: '',

      catalogues: {disabled: false, list: []},
      technical_datasheets: {disabled: false, list: []},
      Warranty: {disabled: false, list: []},
      Care_Maintenance: {disabled: false, list: []},
      Specification_Documents: {disabled: false, list: []},

      /* END -> EU / UK 스펙 */

      /* EU / UK FEATURES & BENEFITS */

      bene_designText: [],
      bene_fabricationText: [],
      bene_performanceText: [],
      bene_sustainText: [],

      /* END => EU / UK FEATURES & BENEFITS */

      /* EU / UK Certifications */

      certifi_environText: [],
      certifi_exteriaText: [],
      certifi_hygieneText: [],
      certifi_processText: [],
      certifi_rating: [],

      /* END => EU / UK Certifications */

      /* 하단 컨텐즈 */
      pcdContent: [],
      pcdContentAttribute: {},
      pcdCertificationData: [],
      countryCodeSub: ''

    }
  },
  watch: {
    $route(to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  async created() {
    const t = this
    t.nationCode = t.$countryCode
    t.contentId = this.$route.params.contentId
    t.countryCodeSub = t.nationCode.substring(1)

    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },

  async mounted() {
    const t = this
    const _this = this
    /* ==================================================== start [ US 컨텐츠 ] ======================================================== */

    /* URL 주소 에서 값 받아온다 */
    this.contentId = this.$route.params.contentId
    /* content 원본 / US 내용 가져온다. */
    const contentRes = await ProductService.getContent(this.contentId)
    t.thisContentLike = contentRes.data
    t.metaDescription = contentRes.data.attribute.meta
    const certifications = contentRes.data.attribute.cert
    t.homedep_link = contentRes.data.attribute.homedep_link
    t.source = contentRes.data.attribute.source
    if (t.source == 'true') {
      t.remark = 'This color is made in USA with US and imported parts.'
    }
    // GTM - product detail
    this.makeDataLayerForm(contentRes.data)

    /* ==================================================== start [ PCD 컨텐츠 ] ======================================================== */
    const contentCategoryCode = `${t.countryCodeSub}_PCD_VIATERA`
    await categoryService.getCategoryCode(contentCategoryCode)
      .then(async (res) => {
        const categoryId = res.data.object.id

        const content = await ProductService.getContentSearchList(categoryId)
        if (content.data.length > 0) {
          const contentData = content.data[0]

          const {id} = contentData
          console.log(contentData)

          _this.pcdContent = await ProductService.getContent(id)
          _this.pcdContentAttribute = _this.pcdContent.data.attribute
          _this.pcdCertificationData = !_.isUndefined(_this.pcdContentAttribute.cert_image_data)
            ? JSON.parse(_this.pcdContentAttribute.cert_image_data)
            : []
        }
      })
    /* ==================================================== end [ PCD 컨텐츠 ] ======================================================== */

    /* ------ 관련 콘텐츠 ------ */
    if (t.nationCode === '_US') {
      t.relContentList = contentRes.data.related.HIMACS_US
    }
    if (t.nationCode === '_GL') {
      t.relContentList = contentRes.data.related.HIMACS_GL
    }
    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      t.relContentList = contentRes.data.related.HIMACS_EU
    }

    // const collectionList = await ProductService.getCollection('HIMACS_COLOR'+t.nationCode)

    t.colorCode = contentRes.data.attribute.code

    /* contentName */
    t.contentName = contentRes.data.content.title

    t.discontinued = contentRes.data.attribute.discontinued
    /* 카테고리 collection|hue 정보 가져오기 */

    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      t.hue = '#' + contentRes.data.attribute.hue
      t.hueText = contentRes.data.attribute.hueText
    } else {
      t.hue = '#fff'
      t.hueText = contentRes.data.attribute.tonesText
    }

    /* 스펙 가져오기 */
    t.brief = contentRes.data.attribute.brief
    t.dimension = contentRes.data.attribute.dimensionText
    t.isNew = contentRes.data.attribute.isNew
    t.exclusive = contentRes.data.attribute.exclusive
    t.isExclusive = contentRes.data.attribute.isExclusive

    if (t.nationCode === '_US') {
      t.madeInUsa = contentRes.data.attribute.made_in_usa
    }

    // // US이면서 미국생산
    // if(t.nationCode === '_US'){
    //   t.remark = contentRes.data.attribute.remark;
    // }

    t.pattern = contentRes.data.attribute.patternText
    t.adCode = contentRes.data.attribute.adhesive_code
    t.adName = contentRes.data.attribute.adhesive_name
    if (t.nationCode === '_GL' && contentRes.data.attribute.countryText != null && contentRes.data.attribute.countryText != undefined) {
      // t.countryCode = contentRes.data.attribute.country.replace(/,/gim, ", ").replace(/, $/, '')
      t.countryCode = contentRes.data.attribute.countryText.join(', ')
    }
    t.collection = contentRes.data.parents[0].name

    if (contentRes.data.attribute.collectionText != null && contentRes.data.attribute.collectionText != undefined) {
      t.collection = contentRes.data.attribute.collectionText
    }

    if (contentRes.data.attribute.collection1Text != null && contentRes.data.attribute.collection1Text != undefined) {
      t.collection = contentRes.data.attribute.collection1Text
    }

    /* 이미지 찾고 불러오기 */

    let fileList = []

    if (
      contentRes.data.attribute.color_image != null &&
      contentRes.data.attribute.color_image != '' &&
      contentRes.data.attribute.color_image.image != '' &&
      contentRes.data.attribute.color_image.thumb != '' &&
      contentRes.data.attribute.color_image != '[]' // 임시 [] 배열이 데이터로 가져온 값이라 해결이 되지 않음
    ) {
      fileList = JSON.parse(JSON.stringify(contentRes.data.attribute.color_image))

      if (fileList[0].image == null || fileList[0].image == '') {
        fileList = []
      }
    }

    if (
      contentRes.data.attribute.full_slap_thumb != null &&
      contentRes.data.attribute.full_slap_thumb != ''
    ) {
      t.full_slap = {
        thumb: contentRes.data.attribute.full_slap_thumb,
        image: contentRes.data.attribute.full_slap_image,
        detail: contentRes.data.attribute.full_slap_detail
      }
    }
    if (
      contentRes.data.attribute.image != null &&
      contentRes.data.attribute.image != ''
    ) {
      if (t.nationCode == '_US' || t.nationCode == '_GL') {
        t.cover = {
          thumb: contentRes.data.content.cover,
          image: contentRes.data.attribute.image,
          detail: contentRes.data.attribute.detail_image
        }
      }
    }
    if (t.cover != null && t.cover.thumb != null) {
      if (fileList != '[]') { // 임시 [] 배열이 데이터로 가져온 값이라 해결이 되지 않음
        fileList.unshift(t.cover)
      } else {
        fileList[0] = {
          thumb: contentRes.data.attribute.cover,
          image: contentRes.data.attribute.image,
          detail: contentRes.data.attribute.detail_image
        }
      }
    }
    if (t.full_slap != null && t.full_slap.thumb != null) {
      fileList.unshift(t.full_slap)
    }
    t.imgPage = fileList

    // 확대기능 레이어 팝업에 들어가는 이미지 크기 추출해오기!
    if (fileList.length != 0 && fileList[0].pc != null) {
      t.imgWidth = fileList[0].width
      t.imgHeight = fileList[0].height
    }

    this.youtubeWidth = '832px'
    this.youtubeHeight = '600px'

    /* //==================================================== end [ US 컨텐츠 ] ======================================================== */

    // start ######################################## FEATURES & BENEFITS
    const features = await ProductService.getCategoryCode(
      'FEAT_HIMACS' + t.nationCode
    )

    const getfeatures = await ProductService.getContentList(
      features.data.object.id
    )
    this.getfeaturesList = getfeatures.data.list
    // end ######################################## FEATURES & BENEFITS

    /* GETTING FINISH START */
    if (
      contentRes.data.attribute.finishText != undefined &&
      contentRes.data.attribute.finishText.length > 0
    ) {
      t.finish = contentRes.data.attribute.finishText
    }
    /* GETTING FINISH END */

    /* ==================================================== start [ inspiration 컨텐츠 ] ======================================================== */

    this.categoryCode = 'INS_CAS_HIMACS' + t.nationCode
    const categoryRes = await InspirationService.getCategoryCode(
      this.categoryCode
    ) /* 카테고리 정보 */
    const cateId = categoryRes.data.object.id /* 카테고리 아이디 */
    // this.inspirationRes = await InspirationService.getContentListChild(cateId) /* 콘텐츠 */
    this.inspirationRes = await InspirationService.getContentListSearch(
      [cateId],
      '',
      1,
      6
    )

    this.inspirationList = this.inspirationRes.data.list

    /* //==================================================== end [ inspiration 컨텐츠 ] ======================================================== */

    this.$nextTick(function () {
      this.featShow = true

      $('.sns-wrp .sns').each(function (idx, ele) {
        var $this = $(this)
        $(ele)
          .prev()
          .click(function () {
            $this.slideToggle(300)
            $this.closest('tr').siblings().children().find('.sns').hide()
          })
      })

      t.imgShow = true

      $('#tab01').addClass('on')

      $('#tab001').addClass('on')

      // if ($(".over-box").length) {
      //   $(".over-box .view").hide();
      //
      //   $(".over-box .over").mouseenter(function () {
      //     $(this).next(".view").fadeIn();
      //   });
      //
      //   $(".over-box").mouseleave(function () {
      //     $(".over-box .view").fadeOut(300);
      //   });
      // }

      /* visual slide */
      $('.slide-pd .slick-container').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        // centerPadding: '35px',
        // centerMode: true,
        prevArrow: $('.slide-pd .arrow-prev'),
        nextArrow: $('.slide-pd .arrow-next'),
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      })

      $('.slider-prod').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        draggable: false,
        asNavFor: '.slider-prod-nav'
      })

      /* visual slide > nav */
      // var prodImgL = $(".slider-prod .slider").length;
      // $(".slider-prod-nav").slick({
      //   slidesToShow: prodImgL,
      //   slidesToScroll: 1,
      //   asNavFor: ".slider-prod",
      //   focusOnSelect: true,
      // });

      t.sliderLength = $('.slider-prod .slider').length

      this.prodImgSlider()
      window.addEventListener('resize', this.prodImgSlider)

      $('.slider-prod').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.yt_player_iframe').each(function () {
          this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        })
      })

      // documentation 영역으로 스크롤
      $('.short-link .btn-doc').click(function () {
        var offset = $('.doc-box').offset().top - $('header').height() - 100
        $('html, body').animate({scrollTop: offset}, 400)
      })

      /* Related Products slide */
      $('.slider-related').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        centerPadding: '35px',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      })

      // $(".related-prod").slick({
      //   slidesToShow: 5,
      //   slidesToScroll: 1,
      //   arrows: true,
      //   draggable: false,
      //   centerPadding: "35px",
      //   responsive: [
      //     {
      //       breakpoint: 767,
      //       settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 1,
      //         arrows : true,
      //         centerPadding:"0",
      //         draggable:true
      //       }
      //     }
      //   ]
      // });

      /* Inspiration slide */
      $('.slider-ins').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        centerPadding: '30px',
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      })

      for (let i = t.imgPage.length - 1; i > 0; i--) {
        if (t.imgPage[i].image != null && !t.imgPage[i].image.startsWith('https://www.youtube.com')) {
          t.imageChange(t.imgPage[i].image)
          break
        }
        if (t.imgPage[i].image != null && t.imgPage[i].image != undefined && t.imgPage[i].image != '' && t.imgPage[i].image.startsWith('https://www.youtube.com')) {
          t.youtubeCheck = true
        }
      }
    })

    // On before slide change
    $('.slider-prod').on(
      'beforeChange',
      function (event, slick, currentSlide, nextSlide) {
        t.Tslider = nextSlide

        if (t.youtubeCheck == true) {
          if (t.Tslider == t.sliderLength - 1) {
            $('#imgDownload').hide()
          } else {
            $('#imgDownload').show()
          }// t.Tslider 가 최고 끝일 때
        } // t.youtube 가 있을 때
      }
    )
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.prodImgSlider)
  },
  methods: {
    goToSchedule () {
      window.open('https://www.homedepot.com/services/c/countertop-installation/6228e49a9?vfrom=tile', '_blank')
    },
    checkCertificationDescription(key) {
      // 배열 안에 특정 값을 가진 객체가 있는지 확인
      return this.pcdCertificationData.some(item => !isEmpty(item[key]))
    },
    openModalCerti() {
      LayerPopup.open('modal-certification')
      $('body').addClass('scrolllock')
    },

    // CERTIFICATION 팝업 닫기
    closeModalCerti() {
      $('.modal-certification').css('display', 'none')
      $('body').removeClass('scrolllock')
      LayerPopup.close()
    },
    openSearchPopup(str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup() {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },
    openSearch() {
      // 검색창 열릴때 모바일 메뉴 열려 있으면 닫기
      $('.m-gnb-container').removeClass('on')
      // 모바일메뉴 버튼 원래대로
      $('.icon-hamburger').removeClass('closed')
      hdSec.open('.sch-ui')
    },
    goToVirtual() {
      window.open('https://www.lghausysusa.com/showroom/index.html', '_blank')
    },
    loginalertLike() {
      alert(this.$t('PCD.alert.loginLike'))
    },
    handle_toggle: function () {
      this.is_show = !this.is_show
    },
    handle_toggle_02: function () {
      this.is_show_02 = !this.is_show_02
    },
    handle_toggle_03: function () {
      this.is_show_03 = !this.is_show_03
    },
    handle_toggle_04: function () {
      this.is_show_04 = !this.is_show_04
    },
    handle_toggle_05: function () {
      this.is_show_05 = !this.is_show_05
    },
    /**
     * popup 열기
     */
    openSampleOrder() {
      location.href = this.homedep_link
    },

    /**
     * popup 닫기
     */
    closeSampleOrder() {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },
    finishLayer() {
      LayerPopup.open('layer-guide')
      this.showFinish = false
      return false
    },
    showGuide(content) {
      if (content == true) {
        this.showFinish = false
      } else {
        this.showFinish = true
      }
    },
    hideGuide() {
      this.showFinish = false
    },
    toggleLike(sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode != '_US') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (o.nationCode == t.nationCode) {
              t.getSessionContent.push(o.id)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    // inspiration detail 이동
    goInsDetail: function (clickId) {
      location.href =
        'inspiration/case-studies/' + this.categoryCode + '/' + clickId
    },
    goToDocu: function () {
      location.href = 'pros/documentation/viatera'
    },

    downloadTab: function (lang, div) {
      $('#' + div).text(lang)
    },

    downloadDocument: function (file, div) {
      const text = $('#' + div).text()
      let fileName = ''

      if (text == 'English') { // EN
        fileName = file.attribute.doc_file
      }
      if (text == 'German') { // DE
        fileName = file.attribute.doc_file_de
      }
      if (text == 'France') { // FR
        fileName = file.attribute.doc_file_fr
      }
      if (text == 'Italian') { // IT
        fileName = file.attribute.doc_file_it
      }
      if (text == 'Spanish') { // ES
        fileName = file.attribute.doc_file_es
      }
      if (text == 'Dutch') { // NL
        fileName = file.attribute.doc_file_nl
      }
      if (text == 'English (UK)') { // UK
        fileName = file.attribute.doc_file_uk
      }

      if (fileName != null && fileName != '' && fileName != undefined) {
        const url = this.$imagePath + fileName

        try {
          function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
              var reader = new FileReader()
              reader.onloadend = function () {
                callback(reader.result)
              }
              reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
          }

          toDataURL(url, function (dataUrl) {
            const element = document.createElement('a')
            element.setAttribute('href', dataUrl)
            element.setAttribute('download', file.content.title)
            document.body.appendChild(element)
            element.click()
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        return false
      }
    },

    // 배너 이미지 다운로드
    imgDownload: function (sliderIdx) {
      const imgDownPath = this.imgPage[sliderIdx].image
      const url = this.$imagePath + imgDownPath

      function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }

      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', imgDownPath)
        document.body.appendChild(element)
        element.click()
      })
    },

    download: function (data) {
      const url = this.$imagePath + data.attribute.doc_file

      try {
        function toDataURL(url, callback) {
          var xhr = new XMLHttpRequest()
          xhr.onload = function () {
            var reader = new FileReader()
            reader.onloadend = function () {
              callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
          }
          xhr.open('GET', url)
          xhr.responseType = 'blob'
          xhr.send()
        }

        toDataURL(url, function (dataUrl) {
          const element = document.createElement('a')
          element.setAttribute('href', dataUrl)
          element.setAttribute('download', data.content.title)
          document.body.appendChild(element)
          element.click()
        })
      } catch (error) {
        console.log(error)
      }
    },

    enlargeLayer(open) {
      const LayerPopup = {
        open: function (obj) {
          const layerObj = $('.' + obj)

          layerObj.fadeIn(500)
          layerObj.attr('tabindex', 1)
          layerObj.css('z-index', '10000')
          $('div.layer-popup.layer-enlarge').css('visibility', 'unset')
        },
        close: function () {
          $('div.layer-popup.layer-enlarge').removeAttr('tabindex')
          $('div.layer-popup.layer-enlarge').css('visibility', 'hidden')
        }
      }

      if (open == 'open') {
        LayerPopup.open('layer-enlarge')
        this.showDragon = true
      } else {
        LayerPopup.close()
        this.showDragon = false
      }
    },

    imageChange: async function (content) {
      if (this.imgPage) {
        // 이미지 파일 크기 체크
        const getImageSize = await this.getImageSize(content)
        if (window.outerWidth < 600) {
          this.youtubeWidth = window.outerWidth + 'px'
          this.youtubeHeight = $('.slider-prod .slick-list').height() + 'px'
        } else {
          this.youtubeWidth = getImageSize.width + 'px'
          this.youtubeHeight = getImageSize.height + 'px'
        }
      }
    },

    getImageSize: async function (image) {
      // let _URL = window.URL || window.webkitURL;
      const _URL = 'https://img.lxhausys.com'
      // console.log(image, "image")
      return new Promise((resolve, reject) => {
        const img = new Image()
        // console.log(img, "img")
        img.onload = () => resolve({
          width: img.width,
          height: img.height
        })
        img.onerror = reject
        img.src = _URL + image
      })
    },

    specShow(flag) {
      this.flag = !flag
    },

    prodImgSlider: debounce(function () {
      /* visual slide > nav */
      const prodImgL = $('.slider-prod .slider').length
      if (prodImgL > 5) {
        $('.slider-prod-nav').addClass('navWide5')
      }
      if (prodImgL == 5) {
        $('.slider-prod-nav').addClass('navWide5')
      }
      if (prodImgL == 4) {
        $('.slider-prod-nav').addClass('navWide4')
      }
      if (prodImgL == 3) {
        $('.slider-prod-nav').addClass('navWide3')
      }
      if (prodImgL == 2) {
        $('.slider-prod-nav').addClass('navWide2')
      }
      if (prodImgL == 1) {
        $('.slider-prod-nav').addClass('navWide1')
      }

      if (prodImgL < 5) {
        /* visual slide > nav */
        $('.slider-prod-nav').slick({
          slide: 'div',
          infinite: true,
          slidesToShow: prodImgL,
          slidesToScroll: 1,
          variableWidth: true,
          arrows: false,
          draggable: false,
          // centerMode: true,
          asNavFor: '.slider-prod',
          focusOnSelect: true,
          prevArrow: "<button type='button' class='slick-prevtt'></button>",		// 이전 화살표 모양 설정
          nextArrow: "<button type='button' class='slick-nexttt'></button>",		// 다음 화살표 모양 설정
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1020,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
          ]
        })
      }

      /* visual slide > nav */
      $('.slider-prod-nav').not('.slick-initialized').slick({
        slide: 'div',
        infinite: true,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        draggable: false,
        // centerMode: true,
        asNavFor: '.slider-prod',
        focusOnSelect: true,
        prevArrow: "<button type='button' class='slick-prevtt'></button>",		// 이전 화살표 모양 설정
        nextArrow: "<button type='button' class='slick-nexttt'></button>",		// 다음 화살표 모양 설정
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          }
        ]
      })

      // 비주얼 썸네일 슬라이드 클릭시 보더 표시
      $('div.slick-slide').on('click', function () {
        $('div.slick-slide').removeClass('slick-current')
        $(this).addClass('slick-current')
      })
    }, '400ms'),

    makeDataLayerForm(data) {
      // let prdVariant = ''
      // let prdPrice = ''
      // if((data.attribute.price != null || undefined) && data.attribute.price.length < 10) {
      //   prdVariant = _.join(_.flatMap(data.attribute.price, function (v) {
      //     return v.size
      //   }), '|')
      //
      //   prdPrice = _.join(_.flatMap(data.attribute.price, function (v) {
      //     return v.price
      //   }), '|')
      // }
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({
          event: 'ecDetail'
        })
      }
    }
  }

}

</script>
