<template>
  <OverviewSection
    title="Eco-Friendly Certification"
    size="56px"
    color="#222"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="eco-friendly-certification-card-area">
        <EcoFriendlyCertificationCard
          v-for="(item, index) in cardDatas"
          :key="index"
          :title="item.title"
          :image="item.image"
          :text="item.text"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText :text="text"/>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="環境に優しい認証"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="eco-friendly-certification-card-area">
        <EcoFriendlyCertificationCard
          v-for="(item, index) in cardDatasJp"
          :key="index"
          :title="item.title"
          :image="item.image"
          :text="item.text"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText :text="textJp"/>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Экологически чистая сертификация"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="eco-friendly-certification-card-area">
        <EcoFriendlyCertificationCard
          v-for="(item, index) in cardDatasRu"
          :key="index"
          :title="item.title"
          :image="item.image"
          :text="item.text"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText :text="textRu"/>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="环保认证"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <div class="eco-friendly-certification-card-area">
        <EcoFriendlyCertificationCard
          v-for="(item, index) in cardDatasCn"
          :key="index"
          :title="item.title"
          :image="item.image"
          :text="item.text"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText :text="textCn"/>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../components/SustainabilityText.vue'
import EcoFriendlyCertificationCard from './EcoFriendlyCertificationCard.vue'
export default {
  components: {
    OverviewSection,
    EcoFriendlyCertificationCard,
    OverviewSectionItem,
    SustainabilityText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardDatas: [
        {
          title: 'Eco-Certifications and Environmental Labels',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image1.png',
          text: 'As consumer demands on not only product quality and safety but also\n' +
                'environmental friendliness rise, LX Hausys actively aims to promote\n' +
                'healthy lives and help preserve the environment by developing\n' +
                'products that can acquire relevant environmental certificates.'
        },
        {
          title: 'ISCC PLUS Certification',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image2.png',
          text: 'In 2021, the Cheongju factory achieved the first domestic construction\n' +
                'materials industry\'s \'ISCC* PLUS\' certificate. This globally recognized\n' +
                'certification acknowledges their sustainable and eco-friendly production\n' +
                'methods, which involved replacing petrochemical-based PVC with\n' +
                'bio-balanced PVC made from plant-derived materials for windows and\n' +
                'flooring products.'
        }
      ],
      cardDatasJp: [
        {
          title: 'エコ認証と環境ラベル',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image1.png',
          text: '製品の品質と安全性だけでなく、環境への配慮に対する消費者の要求が高まる中、\n' +
                'LX Hausysは、関連する環境認証を取得できる製品を開発することで、\n' +
                '健康的な生活を促進し、環境の保全に貢献することを積極的に目指しています。'
        },
        {
          title: 'ISCC PLUS 認定',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image2.png',
          text: '2021年、清州工場は国内の建設資材業界で初めて‘ISCC* PLUS’認証を取得しました。\n' +
                'この世界的に認められた認証は、\n' +
                '窓や床材製品に石油化学由来のPVCを植物由来の材料で作られた\n' +
                'バイオバランスPVCに置き換えるという、\n' +
                '持続可能で環境に優しい生産方法を認めたものです。'
        }
      ],
      cardDatasRu: [
        {
          title: 'Экологические сертификаты и экологические маркировки',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image1.png',
          text: 'По мере того как растут требования потребителей не только к качеству и \n' +
                'безопасности продукции, но и к\n' +
                'экологичности, LX Hausys активно стремится содействовать\n' +
                'здоровой жизни и помочь сохранить окружающую среду путем разработки\n' +
                'продуктов, которые могут получить соответствующие экологические сертификаты.'
        },
        {
          title: 'Сертификация ISCC PLUS',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image2.png',
          text: 'В 2021 году завод в Чхонджу получил первый в стране сертификат «ISCC* PLUS».\n' +
                'Этот всемирно признанный\n' +
                'сертификат подтверждает устойчивость и экологичность производства и \n' +
                'методы производства, включающие замену ПВХ на основе нефтехимикатов на\n' +
                'био-сбалансированные ПВХ, изготовленные из растительного сырья, для\n' +
                'производства окон инапольных покрытий.'
        }
      ],
      cardDatasCn: [
        {
          title: '生态认证和环境标签',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image1.png',
          text: '随着消费者对产品质量和安全以及环保性的要求越来越高\n' +
                'LX Hausys积极致力于通过开发可以获得\n' +
                '相关环境证书的产品来促进健康生活和保护环境。'
        },
        {
          title: 'ISCC PLUS 认证',
          image: 'images/aboutus/sustainability/sustainable_products/eco-friendly-certification-image2.png',
          text: '2021年，清州工厂获得了韩国建筑材料业界的首个国际可持续环保材\n' +
                '料认证“ISCC PLUS(International Sustainability&Carbon \n' +
                'Certification PLUS)认证”。这一全球公认的认证认可了LX Hausys\n' +
                '的可持续和环保生产方法，其中包括用植物衍生材料制成的生物平衡\n' +
                'PVC代替石化基PVC，用于窗户和地板产品。'
        }
      ],
      text: 'At LX Hausys, we provide quantifiable environmental information at each stage of the product life cycle from the production,\n' +
            'distribution, use, and discharge through the life cycle assessment (LCA).\n' +
            'We are aware of our environmental impacts that fall under the seven major impact categories identified\n' +
            'by EPD certification, and we will continue to expand our range of products under this management.',

      textJp: 'LX Hausys では、ライフサイクル アセスメント (LCA) を通じて、生産、流通、使用、\n' +
            '排出といった製品ライフサイクルの各段階で定量化可能な環境情報を提供しています。\n' +
            '当社は、EPD 認証で特定された 7 つの主要な影響カテゴリに該当する環境影響を認識しており、\n' +
            'この管理の下で製品の範囲を拡大し続けます。',

      textRu: 'Компания LX Hausys предоставляет количественную экологическую информацию на каждом этапе жизненного цикла продукции - \n' +
            'от производства, распределения, использования и сброса через оценку жизненного цикла (LCA).\n' +
            'Мы знаем о нашем воздействии на окружающую среду, которое попадает под семь основных категорий воздействия, \n' +
            'определенныхEPD, и мы продолжим расширять ассортимент нашей продукции под этим управлением.',

      textCn: '在LX Hausys，我们通过生命周期评估（LCA）在产品生命周期的每个阶段提供可量化的环境信息，从生产、分销、使用和排放。\n' +
            '我们意识到环境影响属于环保署认证确定的七大影响类别，我们将继续扩大管理下的产品范围。',
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.eco-friendly-certification-card-area {
  display: flex;
  gap: 30px;
  justify-content: center;
}

@media (max-width: 768px) {
  .eco-friendly-certification-card-area {
    flex-direction: column;
    width: 100%;
  }
}
</style>
