<template>
  <div class="social-responsibility-table-body-cell">
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }

}
</script>

<style>
.social-responsibility-table-body-cell {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  max-height: 48px;
}

.social-responsibility-table-body-cell .text {
  color: #666;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
</style>
