var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OverviewSection", [
    _vm.nationCode === "_US" || _vm.nationCode === "_GL"
      ? _c(
          "div",
          { staticClass: "overseas-company-area" },
          _vm._l(_vm.items, function (it, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "l-item" },
              [
                _c("div", { staticClass: "title-area tt-30" }, [
                  _vm._v(_vm._s(it.title)),
                ]),
                _c("div", { staticClass: "line" }),
                _vm._l(it.desc, function (innerIt, innerIdx) {
                  return _c(
                    "div",
                    {
                      key: innerIdx + innerIt.location,
                      staticClass: "desc-area",
                    },
                    [
                      _c("div", { staticClass: "desc-area-location" }, [
                        _c("pre", { staticClass: "ctt ctt-4" }, [
                          _vm._v(_vm._s(innerIt.location)),
                        ]),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _c("pre", { staticClass: "ctt-t5" }, [
                          _vm._v(_vm._s(innerIt.desc)),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          0
        )
      : _vm.nationCode === "_JP"
      ? _c(
          "div",
          { staticClass: "overseas-company-area" },
          _vm._l(_vm.items, function (it, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "l-item" },
              [
                _c("div", { staticClass: "title-area tt-30" }, [
                  _vm._v(_vm._s(it.title)),
                ]),
                _c("div", { staticClass: "line" }),
                _vm._l(it.desc, function (innerIt, innerIdx) {
                  return _c(
                    "div",
                    {
                      key: innerIdx + innerIt.location,
                      staticClass: "desc-area",
                    },
                    [
                      _c("div", { staticClass: "desc-area-location" }, [
                        _c("pre", { staticClass: "ctt ctt-4" }, [
                          _vm._v(_vm._s(innerIt.location)),
                        ]),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _c("pre", { staticClass: "ctt-t5" }, [
                          _vm._v(_vm._s(innerIt.desc)),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          0
        )
      : _vm.nationCode === "_RU"
      ? _c(
          "div",
          { staticClass: "overseas-company-area" },
          _vm._l(_vm.itemsRu, function (it, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "l-item" },
              [
                _c("div", { staticClass: "title-area tt-30" }, [
                  _vm._v(_vm._s(it.title)),
                ]),
                _c("div", { staticClass: "line" }),
                _vm._l(it.desc, function (innerIt, innerIdx) {
                  return _c(
                    "div",
                    {
                      key: innerIdx + innerIt.location,
                      staticClass: "desc-area",
                    },
                    [
                      _c("div", { staticClass: "desc-area-location" }, [
                        _c("pre", { staticClass: "ctt ctt-4" }, [
                          _vm._v(_vm._s(innerIt.location)),
                        ]),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _c("pre", { staticClass: "ctt-t5" }, [
                          _vm._v(_vm._s(innerIt.desc)),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          0
        )
      : _vm.nationCode === "_CN"
      ? _c(
          "div",
          { staticClass: "overseas-company-area" },
          _vm._l(_vm.items, function (it, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "l-item" },
              [
                _c("div", { staticClass: "title-area tt-30" }, [
                  _vm._v(_vm._s(it.title)),
                ]),
                _c("div", { staticClass: "line" }),
                _vm._l(it.desc, function (innerIt, innerIdx) {
                  return _c(
                    "div",
                    {
                      key: innerIdx + innerIt.location,
                      staticClass: "desc-area",
                    },
                    [
                      _c("div", { staticClass: "desc-area-location" }, [
                        _c("pre", { staticClass: "ctt ctt-4" }, [
                          _vm._v(_vm._s(innerIt.location)),
                        ]),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _c("pre", { staticClass: "ctt-t5" }, [
                          _vm._v(_vm._s(innerIt.desc)),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }