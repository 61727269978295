var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "We create human-friendly, eco-conscious living spaces",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "In 2022, LX Hausys established the ESG Committee along with the ESG Vision and Strategy System in order to organize its ESG management.\nIn pursuing the ESG Vision slogan of “Innovations for Consumers, ESG for Future,”\nwe express our firm commitment to creating continued innovation and sustainable growth by actively\nimplementing our 3 strategic directions and 9 strategic tasks.",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "人間に優しく、環境に配慮した生活空間を創造します",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "LX Hausysは、2022年にESG経営を組織化するために、ESGビジョンと戦略システムとともにESG委員会を設立しました。\n        「消費者のためのイノベーション、未来のためのESG」というESGビジョンのスローガンを追求し、\n        3つの戦略方向と9つの戦略タスクを積極的に実行することで、\n        継続的なイノベーションと持続可能な成長を生み出すという確固たる決意を表明しています。\n        ",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title:
              "Мы создаем безопасные для человека и экологически чистые жилые пространства.",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "В 2022 году компания LX Hausys создала Комитет ESG, а также систему видения и стратегии ESG для организации управления ESG.\nСледуя лозунгу ESG Vision «Инновации для потребителей, ESG для будущего».\nМы выражаем нашу твердую приверженность созданию непрерывных инноваций и устойчивому росту, \nактивно реализуя 3 стратегических направления и 9 стратегических задач.",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "创造与自然相融合的人类幸福生活空间",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("OverviewText", {
                attrs: {
                  type: "ft-20",
                  text: "2022年，LX Hausys成立了ESG委员会以及ESG愿景和战略体系，以组织其ESG经营。\n在追求ESG愿景“为客户创新，ESG面向未来”的过程中\nLX Hausys积极实施3个战略方向和9个战略任务，创造持续创新和可持续增长。\n        ",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }