var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box insp-box inspiration_gallery" }, [
    _c("div", { staticClass: "inner_content" }, [
      _c("div", { staticClass: "title_area" }, [
        _c("h2", { staticClass: "tit-main" }, [
          _vm._v("Inspiration galleries"),
        ]),
        _c(
          "div",
          { staticClass: "more", on: { click: _vm.handelClickViewMore } },
          [_vm._m(0)]
        ),
      ]),
      _c("div", { staticClass: "tab-ui tab-ui-nav type_line tab-insp" }, [
        _c("ul", { staticClass: "tab-ui-inspired" }, [
          _c(
            "li",
            { staticClass: "btn-tab on", attrs: { "data-tab": "insp-all" } },
            [
              _c("button", {
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("Residential") },
                on: {
                  click: function ($event) {
                    return _vm.tabClick("residential")
                  },
                },
              }),
            ]
          ),
          _c(
            "li",
            { staticClass: "btn-tab", attrs: { "data-tab": "insp-all" } },
            [
              _c("button", {
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("Commercial") },
                on: {
                  click: function ($event) {
                    return _vm.tabClick("commercial")
                  },
                },
              }),
            ]
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "tab-con insp-all on" }, [
          _c("div", { staticClass: "insplist-wrap" }, [
            _vm.nationCode === "_GL" || _vm.nationCode === "_US"
              ? _c(
                  "ul",
                  _vm._l(_vm.featuredPJTList, function (inspired) {
                    return _c(
                      "li",
                      {
                        attrs: {
                          "data-aos": "fade-up",
                          "data-aos-duration": "600",
                          "data-aos-delay": "500",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goToUrl(inspired)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: `${_vm.$imagePath}${inspired.attribute.mainThumbnail}`,
                            alt: inspired.content.title,
                          },
                        }),
                        _c("div", { staticClass: "txt-info" }, [
                          _c("span", {
                            staticClass: "tinfo-cg",
                            domProps: {
                              innerHTML: _vm._s(inspired.content.title),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "dim-box" }, [
                          _c(
                            "div",
                            { staticClass: "ins_prod_type" },
                            [
                              _vm._l(
                                inspired.attribute.product_cateText,
                                function (cateItem) {
                                  return [
                                    _c("span", {
                                      staticClass: "tinfo-tit",
                                      domProps: { innerHTML: _vm._s(cateItem) },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _c("p", {
                            staticClass: "content_title",
                            domProps: {
                              innerHTML: _vm._s(inspired.content.title),
                            },
                          }),
                          inspired.attribute.applicationList
                            ? _c(
                                "div",
                                { staticClass: "hashtag_group" },
                                [
                                  _vm._l(
                                    inspired.attribute.applicationList,
                                    function (appItem) {
                                      return [
                                        _c("span", {
                                          staticClass: "hashtag",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `#${appItem.text}`
                                            ),
                                          },
                                        }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "ul",
                  _vm._l(_vm.featuredPJTList, function (inspired) {
                    return _c(
                      "li",
                      {
                        attrs: {
                          "data-aos": "fade-up",
                          "data-aos-duration": "600",
                          "data-aos-delay": "500",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goToUrl(inspired)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.$imagePath + inspired.attribute.pc_thumb,
                            alt: "segments img",
                          },
                        }),
                        _c("div", { staticClass: "txt-info" }, [
                          _c("span", { staticClass: "tinfo-cg" }, [
                            _vm._v(
                              _vm._s(inspired.parents[0].name) +
                                " / " +
                                _vm._s(inspired.attribute.segments1Text)
                            ),
                          ]),
                          _c("span", { staticClass: "tinfo-tit" }, [
                            _vm._v(_vm._s(inspired.content.title)),
                          ]),
                        ]),
                        _c("div", { staticClass: "dim-box" }, [
                          _c(
                            "span",
                            {
                              staticClass: "tinfo-cg",
                              on: {
                                click: function ($event) {
                                  return _vm.caseStudiesLink()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(inspired.attribute.case_category) +
                                  " / " +
                                  _vm._s(inspired.attribute.segments1Text)
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "tinfo-tit" }, [
                            _vm._v(_vm._s(inspired.content.title)),
                          ]),
                          _c("span", { staticClass: "tinfo-place" }, [
                            _vm._v(_vm._s(inspired.attribute.location)),
                          ]),
                          _c("span", {
                            directives: [
                              { name: "plaintext", rawName: "v-plaintext" },
                            ],
                            staticClass: "tinfo-cmt",
                            domProps: {
                              innerHTML: _vm._s(inspired.attribute.summary),
                            },
                          }),
                          _c("a", {
                            staticClass: "btn-golmore",
                            attrs: {
                              href:
                                "inspiration/case-studies/INS_CAS" +
                                _vm.nationCode +
                                "/" +
                                inspired.content.id,
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "view_more", attrs: { href: "javascript:void(0)" } },
      [_c("span", [_vm._v("View more")]), _c("span", { staticClass: "icon" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }