<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>RECYCLED</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
        <span class="checkbox_more no_line">
          <li>
            <input type="checkbox" id="isRecycled_true" v-model="isRecycledSelected" />
            <label for="isRecycled_true">
              <span></span>
              {{ $t('PF.text.Recycled') }}
            </label>
          </li>
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'RecycledFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['value'],
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    }),
    isRecycledSelected: {
      get () {
        return this.selected.length > 0 && this.selected[0] === true
      },
      set (value) {
        this.selected = value ? [true] : []
        this.$emit('input', this.selected)
      }
    }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    value: {
      handler (newValue) {
        if (JSON.stringify(this.selected) !== JSON.stringify(newValue)) {
          this.selected = Array.isArray(newValue) ? [...newValue] : []
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    EventBus.$on('reset-pf-filter', this.resetCheckbox)
    EventBus.$on('update-recycled-filter', this.updateRecycled)

    if (this.$store.state.filterState && this.$store.state.filterState.isRecycled !== undefined) {
      this.selected = [this.$store.state.filterState.isRecycled]
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckbox)
    EventBus.$off('update-recycled-filter', this.updateRecycled)
  },
  methods: {
    resetCheckbox () {
      this.selected = []
      this.$emit('input', this.selected)
    },
    updateRecycled (isRecycled) {
      this.selected = isRecycled ? [true] : []
      this.$emit('input', this.selected)
    }
  }
}
</script>
