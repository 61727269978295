var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-section-header-area", class: _vm.directionClass },
    [
      _vm.title
        ? _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.title))]),
          ])
        : _vm._e(),
      _vm.description
        ? _c("div", { staticClass: "description", class: _vm.directionClass }, [
            _c("span", [_vm._v(_vm._s(_vm.description))]),
          ])
        : _vm._e(),
      _vm.subTitle
        ? _c("div", { staticClass: "sub-title", style: _vm.subTitleStyle }, [
            _c("span", [_vm._v(_vm._s(_vm.subTitle))]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }