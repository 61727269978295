var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Key Project",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem, function (it, idx) {
                return _c("KeyProjectCard", {
                  key: idx,
                  attrs: { imageSrc: it.imageSrc, txt: it.txt },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "主要プロジェクト",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem_jp, function (it, idx) {
                return _c("KeyProjectCard", {
                  key: idx,
                  attrs: { imageSrc: it.imageSrc, txt: it.txt },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Основной проект",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem_ru, function (it, idx) {
                return _c("KeyProjectCard", {
                  key: idx,
                  attrs: { imageSrc: it.imageSrc, txt: it.txt },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSmallSection",
        { attrs: { title: "重点项目", fontFamily: "Lato", fontWeight: "700" } },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem_cn, function (it, idx) {
                return _c("KeyProjectCard", {
                  key: idx,
                  attrs: { imageSrc: it.imageSrc, txt: it.txt },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }