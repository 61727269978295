var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "auto",
        display: "flex",
        "justify-content": "center",
      },
    },
    [
      _c(
        "div",
        { staticClass: "lx_worlds" },
        [
          _c("img", {
            staticClass: "image_size first_image",
            class: { us_image: _vm.isUSCode() },
            attrs: { src: "images/lxworld/about_us/about_us_overview.png" },
          }),
          _c("img", {
            staticClass: "image_size second_image",
            class: { us_image: _vm.isUSCode() },
            attrs: { src: "images/lxworld/about_us/LX_AboutUs_Labelling.png" },
          }),
          _vm._l(_vm.pointInfoList, function (pointInfo) {
            return _c("AboutUsPoint", {
              key: pointInfo.id,
              attrs: { pointInfo: pointInfo, hidePointer: _vm.hidePointer },
            })
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }