<template>
  <div id="wrap">
   <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content cust-spr-wrap">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home"></li>
          <!-- <li>Footer</li> -->
          <li>{{$t('imprint.FF0050_001')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <div class="container imprint">
        <div class="w-con-02 inner">
          <h1 class="tt-1 mb-64 tac">{{$t('imprint.FF0050_001')}}</h1>
          <div class="cust-box">
            <p class="ctt ctt-t1">{{$t('imprint.FF0050_002')}}</p>
            <p class="ctt ctt-t4">
              {{$t('imprint.FF0050_003')}}<br />
              {{$t('imprint.FF0050_004')}}<br />
              {{$t('imprint.FF0050_005')}}<br />
              {{$t('imprint.FF0050_006')}}
            </p>
          </div>
          <div class="cust-box">
            <p class="ctt ctt-t1">{{$t('imprint.FF0050_007')}}</p>
            <p class="ctt ctt-t4">
              {{$t('imprint.FF0050_008')}}
            </p>
          </div>
          <div class="cust-box">
            <p class="ctt ctt-t1">{{$t('imprint.FF0050_009')}}</p>
            <p class="ctt ctt-t4">
              {{$t('imprint.FF0050_010')}}<br />
              {{$t('imprint.FF0050_011')}}<br />
              {{$t('imprint.FF0050_012')}}<br />
              {{$t('imprint.FF0050_013')}}<br />
              {{$t('imprint.FF0050_014')}}<br />
            </p>
          </div>
          <div class="cust-box">
            <p class="ctt ctt-t1" v-html="$t('imprint.FF0050_015')"></p>
            <!-- <p class="ctt ctt-t4">
              details.ch
            </p> -->
          </div>
          <div class="cust-box">
            <p class="ctt ctt-t1">{{$t('imprint.FF0050_016')}}</p>
            <p class="ctt ctt-t4">
              {{$t('imprint.FF0050_017')}}<br />{{$t('imprint.FF0050_018')}}
            </p>
          </div>

        </div>
      </div>

    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'app',
  //   mixins: [axiosService],
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
    }
  },
  async created () {
  },
  mounted () {
    // alert('Imprint')
  },
  methods: {
    async some () {
    }
  }
}
</script>
