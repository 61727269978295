var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "direction-of-strategy-card" }, [
    _c("span", { staticClass: "text-area" }, [_vm._v(_vm._s(_vm.txt))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }