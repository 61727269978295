<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content prod-dt-wide">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">{{ $t('PCD.text.home')}}</li>
          <li>{{ $t('PCD.text.products')}}</li>
          <li>{{ categoryName }}</li>
          <li>{{ contentName }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (wide ver) -->
      <div class="pro-dt-warp">
        <div class="container prod-dt-view pd_container himacs-pd">

          <!-- visual slide -->
          <div class="prod-img">
            <!-- images -->
            <div class="slider-prod viartW">
              <div
                class="slider pdf-inslide03"
                v-for="(fileName, index) in imgPage"
                v-bind:key="index"
                v-show="imgShow === true"
              >
                <img
                  v-if="fileName.image != null && fileName.image != undefined && fileName.image != ''  && !fileName.image.startsWith('https://www.youtube.com')"
                  :src="$imagePath + fileName.image"
                  alt="himacs images"
                />
                <div class="yt-video" v-if="fileName.image != null && fileName.image != undefined && fileName.image != '' && fileName.image.startsWith('https://www.youtube.com')" :style="'max-width:'+youtubeWidth+';' + 'margin: 0 auto'">
                <!-- <div class="yt-video" v-if="fileName.image != null && fileName.image != undefined && fileName.image != '' && fileName.image.startsWith('https://www.youtube.com')" :style="'height:'+youtubeHeight+'; margin: 0 auto'"> -->
                  <iframe autoplay="autoplay"
                          muted="muted"
                          controls
                          allowscriptaccess="always"
                          style="width:auto; min-width: 100%; min-height: 100%"
                          :src="fileName.image + '?enablejsapi=1'"
                          alt="youtube"
                          class="yt_player_iframe"
                  />
                </div>

                <p class="ctt photo-credits ta-r" v-if="nationCode != '_US' && nationCode != '_GL'">
                  <span class="pc-br" v-html="fileName.description "/>
                </p>

              </div>
              <!-- EU 포토크레딧 추가 -->
            </div>
            <!-- //images -->

            <!-- thumbnail -->
            <div class="slider-prod-nav navWide tac" v-bind:class="{euNavWide: nationCode != '_GL' && nationCode != '_US'}">
              <div
                class="slider"
                v-for="(fileName, index) in imgPage"
                v-bind:key="index"
                style="position: relative"
                v-show="imgShow === true"
              >
                <img
                  :data-chk="index"
                  v-if="fileName.image != null && fileName.image != undefined && fileName.image != '' && fileName.thumb != null && fileName.thumb != '' "
                  :src="$imagePath + fileName.thumb"
                  alt="himacs thumbnail"
                />
                <div v-if="fileName.image != null && fileName.image != undefined && fileName.image != '' && fileName.image.startsWith('https://www.youtube.com')">
                  <img style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 50px; height: 50px" src="https://img.lxhausys.com/public/images/youtube.png"/>
                </div>
              </div>
            </div>
            <!-- //thumbnail -->
          </div>
          <!-- //visual slide -->

          <div class="w-con inner">
            <!-- product info -->
            <div class="prod-info">
              <div class="wide-proDeteail-label">
                <span class="pro">{{ $t('PCD.text.himacs')}}</span>
                <span class="new" v-if="isNew == 'true'">{{ $t('PCD.text.new')}}</span>
                <span class="new" v-if="label != '' && label != undefined && label != null"
                      style="background-color: #595555;border-color: #595555;">{{ label }}</span>
                <span class="disColor" v-if="discontinued == 'true'"
                >{{ $t('PD.text.discontinued_Color')}}</span
                >
              </div>

              <div class="prod-img">
                <!-- btns  US / GL 시작 -->
                <div class="btns" v-if="nationCode == '_US' || nationCode == '_GL'">
                  <ul>
                    <!--<li>
                      <div>
                        <a href="#" class="btns-txtbtn">See This In My Room</a>
                        <a href="#" class="btns-txtbtn">View in 3D</a>
                      </div>
                    </li>-->
                    <li v-if="Tslider === 0">
                      <a
                        class="icon-view-gray"
                        href="javascript:void(0);" @click="enlargeLayer('open')"
                      >{{ $t('PD.text.image_enlarged_view')}}</a
                      >
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="icon-download-gray"
                        @click="imgDownload(Tslider)"
                      >{{ $t('PD.text.image_download_view')}}</a
                      >
                    </li>
                    <li>
                      <a class="">
                        <ShareComponent v-bind:childValue="contentList" :type="'product'"></ShareComponent>
                      </a>
                    </li>
                    <li>
                      <a
                        class="icon-like2-gray"
                        v-if="authenticated == true && nationCode == '_US'"
                        v-bind:class="{ on: thisContentLike.content.liked }"
                        @click="toggleLike(thisContentLike, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >
                      <a
                        class="icon-like2-gray"
                        v-if="authenticated == false && nationCode == '_US'"
                        href="login/"
                        @click="loginalertLike()"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >

                      <a
                        class="icon-like2-gray"
                        v-if="getSessionContent != null && nationCode == '_GL'"
                        v-bind:class="{
                          on: getSessionContent.includes(
                            thisContentLike.content.id
                          ),
                        }"
                        @click="toggleLike(thisContentLike, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >
                      <a
                        class="icon-like2-gray"
                        v-else-if="
                          getSessionContent == null && nationCode == '_GL'
                        "
                        @click="toggleLike(thisContentLike, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- btns  US / GL 끝 -->

                <!-- btns  EU / UK 시작 -->
                <div class="btns" v-if="nationCode != '_US' && nationCode != '_GL'">
                  <ul>

                    <li v-if="Tslider === 0">
                      <a
                        class="icon-view-gray"
                        href="javascript:void(0);" @click="enlargeLayer('open')"
                      >{{ $t('PD.text.image_enlarged_view')}}</a
                      >
                    </li>

                    <li>
                      <a
                        href="javascript:void(0);"
                        class="icon-download-gray"
                        @click="imgDownload(Tslider)"
                      >{{ $t('PD.text.image_download_view')}}</a
                      >
                    </li>

                    <li>
                      <a class="">
                        <ShareComponent v-bind:childValue="contentList" :type="'product'"></ShareComponent>
                      </a>
                    </li>

                    <li>
                      <a
                        class="icon-like2-gray"
                        v-if="getSessionContent != null"
                        v-bind:class="{
                          on: getSessionContent.includes(
                            thisContentLike.content.id
                          ),
                        }"
                        @click="toggleLike(thisContentLike, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a>
                      <a
                        class="icon-like2-gray"
                        v-else-if="
                          getSessionContent == null
                        "
                        @click="toggleLike(thisContentLike, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a>
                    </li>

                  </ul>
                </div>
                <!-- btns  EU / UK 끝 -->

              </div>

              <div class="info-01 v-al-md">
                <span class="aurora-icon" v-if="nationCode != '_US' && nationCode != '_GL' && Aurora == true">
                  <img :src='`images/products/PD_Aurora_icon.png`'>
                </span>
                <span class="txt">{{ colorCode }}</span>
              </div>

              <div class="col-02">
                <div>
                  <h1 class="tt-1">{{ contentName }}</h1>
                  <div class="info-02 eu-info-02">
                    <!-- <div class="ico-cal">
                      <span>{{ collection }} Collection</span>
                    </div> -->

                    <div class="ico-pro-collection">
                      <!-- 2022.04.11 -->

                      <!-- 컬렉션 두줄일때 -->
                      <span class="ctt" v-if="nationCode != '_US' && nationCode != '_GL' && collection[0] != null && collection[0] != undefined && collection[0] != '' && collection[0].includes('Granite, Sparkle, Quartz, Sand & Pearl')">{{ collection[0] }} {{ $t('PCD.text.collection')}}</span>
                      <!-- 컬렉션 한줄일때 -->
                      <span class="ctt lh-40" v-else>{{ collection[0] }} {{ $t('PCD.text.collection')}}</span>

                      <!-- 컬렉션 두줄일때 -->
                      <span class="ctt ctt-t4" v-if="nationCode != '_US' && nationCode != '_GL' && collection[1] != null && collection[1] != undefined && collection[1] != '' && collection[1].includes('Granite, Sparkle, Quartz, Sand & Pearl')">{{ collection[1] }} {{ $t('PCD.text.collection')}}</span>
                      <!-- 컬렉션 한줄일때 -->
                      <span class="ctt ctt-t4 lh-40" v-else-if="nationCode != '_US' && nationCode != '_GL' && collection[1] != null && collection[1] != undefined && collection[1] != ''">{{ collection[1] }} {{ $t('PCD.text.collection')}}</span>

                      <!-- 컬렉션 두줄일때 -->
                      <span class="ctt ctt-t4" v-if="collection[2] != null && collection[2] != undefined && collection[2] != '' && collection[2].includes('Granite, Sparkle, Quartz, Sand & Pearl')">{{ collection[2] }} {{ $t('PCD.text.collection')}}</span>
                      <!-- 컬렉션 한줄일때 -->
                      <span class="ctt ctt-t4 lh-40" v-else-if="nationCode != '_US' && nationCode != '_GL' && collection[2] != null && collection[2] != undefined && collection[2] != ''">{{ collection[2] }} {{ $t('PCD.text.collection')}}</span>
                    </div>

                    <div class="ico-color" v-if="nationCode == '_US' || nationCode == '_GL'">
                      <div v-if="hueText === 'Other'"
                        class="color"
                        v-bind:style="{
                          borderColor: '#999',
                          backgroundImage : 'url(images/products/' + relhue +'.png',
                        }"
                      ></div>
                      <div v-else
                        class="color"
                        v-bind:style="{
                          borderColor: '#999',
                          backgroundColor: hue,
                        }"
                      ></div>
                      <div class="txtinfo">
                        <span class="ctt ctt-t4" v-if="nationCode == '_US' || nationCode == '_GL'">{{ hueText }}</span>
                        <span class="ctt ctt-t4">{{ $t('PD.text.color')}}</span>
                      </div>
                    </div>
                    <div class="ico-pro-lucent" v-if="nationCode != '_US' && nationCode != '_GL' && lucent == 'true'">
                      <span class="ctt ctt-t4">{{ $t('PD.text.Lucent')}}</span>
                    </div>
                    <div class="ico-pro-veined" v-if="nationCode != '_US' && nationCode != '_GL' && veined == 'true'">
                      <span class="ctt ctt-t4">{{ $t('PD.text.Veined')}}</span>
                    </div>
                  </div>
                  <!-- <div class="info-03 ctt ctt-t4" v-html="brief" /> -->
                  <p class="info-03 ctt ctt-t4" v-html="brief"></p>
                </div>

                <div>
                  <div class="tbl-box-prodetail" v-bind:class="{euTblBox: nationCode != '_GL' && nationCode != '_US'}">
                    <table>
                      <caption>
                        {{ $t('PD.text.product_Specs')}}
                      </caption>
                      <colgroup>
                        <col style="width: 30%" />
                        <col style="width: 70%" />
                      </colgroup>
                      <thead>
                      <tr>
                        <th scope="col" colspan="2"><h2>{{ $t('PD.text.product_Specs')}}</h2></th>
                      </tr>
                      </thead>

                      <!-- US / GL 스펙 전용 -->
                      <tbody v-if="nationCode == '_US' || nationCode == '_GL'">
                      <tr>
                        <!-- 국가별 단위 다름-->
                        <th scope="row">{{ $t('PD.text.dimension1')}} <br>{{ $t('PD.text.weight')}}</th>
                        <td class="ctt ctt-t5">{{ dimension }}</td>

                      </tr>
                      <tr v-if="remark !== undefined && remark !== ''" >
                        <!--remark 데이터 있을 경우만 visible-->
                        <th scope="row">{{ $t('PD.text.remark')}}</th>
                        <td class="ctt ctt-t5">{{ remark }}</td>
                      </tr>
                      <tr>
                        <th scope="row">{{ $t('PD.text.pattern')}}</th>
                        <td class="ctt ctt-t5">{{ pattern }}</td>
                      </tr>
                      <tr v-if="nationCode === '_GL'">
                        <th scope="row">{{ $t('PD.text.available_Countries')}}</th>
                        <td class="ctt ctt-t5">
                          {{ countryCode }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{{ $t('PD.text.adhesive_code')}}</th>
                        <td class="ctt ctt-t5">{{ adCode }}</td>
                      </tr>
                      <tr v-show="flag === true">
                        <th scope="row">{{ $t('PD.text.adhesive_name')}}</th>
                        <td class="ctt ctt-t5">{{ adName }}</td>
                      </tr>
                      <tr v-if="flag === false" class="bo-btm-0">
                        <td colspan="2" class="ta-c prd-btn-more">
                          <button type="button" class="btn btn-text btn-text-more">
                              <span @click="specShow(flag)">
                                {{ $t('PD.button.see_more')}}
                              </span>
                          </button>
                        </td>
                      </tr>
                      <tr v-if="flag === true" class="bo-btm-0">
                        <td colspan="2" class="ta-c prd-btn-more">
                          <button type="button" class="btn btn-text btn-text-more on" @click="specShow(flag)">
                            <!--                              <span >-->
                            <!--                                {{ $t('PD.button.see_more')}} 고객 요청 삭제 -->
                            <!--                              </span>-->
                          </button>
                        </td>
                      </tr>
                      </tbody>

                      <!-- EU / UK 스펙 전용 -->
                      <tbody v-if="nationCode != '_US' && nationCode != '_GL'">

                      <tr v-if="thickness != undefined && thickness.length != 0">
                        <th scope="row">{{ $t('PD.text.Thickness')}}</th>
                        <td class="ctt ctt-t5" v-if="thickness.length > 1">{{ thickness.join(', ') }}</td>
                        <td class="ctt ctt-t5" v-else v-for="thickness in thickness">{{ thickness }}</td>
                      </tr>

                      <tr v-if="USE != undefined && USE.length != 0">
                        <th scope="row">{{ $t('PD.text.Use')}}</th>
                        <td class="ctt ctt-t5" v-if="USE.length > 1">{{ USE.join(', ') }}</td>
                        <td class="ctt ctt-t5" v-else v-for="USE in USE">{{ USE }}</td>
                      </tr>

                      <tr v-if="pattern != undefined && pattern != null">
                        <th scope="row">{{ $t('PD.text.Pattern')}}</th>
                        <td class="ctt ctt-t5">{{ pattern }}</td>
                      </tr>

                      <tr>
                        <th scope="row">{{ $t('PF.text.Tones')}}</th>
                        <td class="ctt ctt-t5" v-if="tones.length > 1">{{ tones.join(', ') }}</td>
                        <td class="ctt ctt-t5" v-else v-for="tones in tones">{{ tones }}</td>
                      </tr>

                      <tr v-if="notice == 'true'">
                        <th scope="row">{{ $t('PD.text.Notice')}}</th>
                        <td class="ctt ctt-t5">
                          {{ $t('PD.text.Notice_brief')}}
                        </td>
                      </tr>

                      <tr v-if="semi_translucent_colour == 'true'">
                        <th scope="row">{{ $t('PD.text.Semi_Translucent')}}</th>
                        <td class="ctt ctt-t5">
                          {{ $t('PD.text.Semi_Translucent_brief')}}
                        </td>
                      </tr>

                      <tr v-if="recycled != undefined && recycled != null && recycled != ''">
                        <th scope="row">{{ $t('PD.text.Recycled')}}</th>
                        <td class="ctt ctt-t5">
                          {{ $t('PD.text.Recycled_brief')}} {{ recycled }}
                        </td>
                      </tr>

                      <tr v-if="designer_series == 'true'">
                        <th scope="row">{{ $t('PD.text.Designer')}}</th>
                        <td class="ctt ctt-t5">
                          {{ $t('PD.text.Designer_brief')}}
                        </td>
                      </tr>

                      <tr v-if="lucent == 'true'">
                        <th scope="row">{{ $t('PD.text.Lucent')}}</th>
                        <td class="ctt ctt-t5">
                          {{ $t('PD.text.Lucent_brief')}}
                        </td>
                      </tr>

                      <tr v-if="veined == 'true'">
                        <th scope="row">{{ $t('PD.text.Veined')}}</th>
                        <td class="ctt ctt-t5">
                          {{ $t('PD.text.Veined_brief')}}
                        </td>
                      </tr>
                      <!-- <tr><td></td></tr> -->

                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- //product info -->

            <!-- short cut -->
            <div class="proDtail">
              <div class="short-link short-link-outline">
                <a @click="openSampleOrder(contentId)" v-if="nationCode === '_US'"><span class="ico-01">{{ $t('PCD.text.order_a_sample')}}</span></a>
                <a v-if="nationCode === '_GL'" :href="'support/contactus'"><span class="ico-07 ctt ctt-t3">{{ $t('PCD.text.contact_us')}}</span></a>
                <a v-if="nationCode != '_US' && nationCode != '_GL'" :href="'support/contactus'"><span class="ico-07 ctt ctt-t3">{{ $t('PCD.text.contact_us')}}</span></a>
                <a href="javascript:void(0);" class="btn-doc"><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a>
                <!--            <a href="pros/pros/documentation/DOC_VIATERA"><span class="ico-03">Documentation</span></a>-->
                <a v-if="nationCode === '_US'" :href="'support/where-to-buy/country/us'">
                  <span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span>
                </a>
                <a v-else-if="nationCode === '_GL'" :href="'support/where-to-buy/country/gl'">
                  <span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span>
                </a>
                <a v-else :href="'support/sales-representative/continenteu/himacs'">
                  <span class="ico-19">{{ $t('PCD.button.find_a_sales_rep')}}</span>
                </a>
                <a href="inspiration/virtual-showroom">
                  <span class="ico-06">{{ $t('PD.button.virtual_Showroom')}}</span>
                </a>
              </div>
            </div>
            <!-- //short cut -->
          </div>

          <!-- Related Products -->
          <div class="related-prod inner">
            <h2 class="tt-sub-gray ta-c">{{ $t('PD.text.related_Products')}}</h2>
            <!-- start -->
            <div class="slide-wrapper slide-pd" v-show="featShow === true">
              <button class="arrow-prev">{{ $t('PD.button.prev')}}</button>
              <button class="arrow-next">{{ $t('PD.button.next')}}</button>
              <div class="slick-container">
                <!-- item -->
                <div class="slider"  v-for="(item, index) in relContentList"
                     v-bind:key="index">
                  <div class="thumb" :data-ec-product="makeDataLayerFormList(item)">
                    <router-link v-if="nationCode != '_US' && nationCode != '_GL'"
                      :to="
                          '/' +
                          categoryName +
                          '/' +
                          item.orgContent.title +
                          '/' +
                          item.content.id
                        "
                    >
                      <div class="over">
                        <img
                          :src="$imagePath + item.attribute.pf_thumb"
                          alt="PORTLAND"
                        />
                      </div>
                    </router-link>
                    <router-link v-else
                                 :to="
                          '/' +
                          'himacs-solid-surface' +
                          '/' +
                          $utils.getSeoName(item, 'content') +
                          '/' +
                          item.content.id
                        "
                    >
                      <div class="over">
                        <img
                          :src="$imagePath + item.attribute.pf_thumb"
                          alt="PORTLAND"
                        />
                      </div>
                    </router-link>
                    <div class="view">
                      <a
                        @click="openSampleOrder(item.content.id)"
                        class="icon-cart-shadow"
                        v-if="nationCode === '_US'"
                      >{{ $t('PCD.text.cart')}}</a
                      >

                      <a
                        class="icon-wish-shadow" tabindex="0"
                        v-if="authenticated == true && nationCode == '_US'"
                        v-bind:class="{ on: item.content.liked }"
                        @click="toggleLike(item, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >
                      <a
                        class="icon-wish-shadow" tabindex="0"
                        v-if="authenticated == false && nationCode == '_US'"
                        href="login/"
                        @click="loginalertLike()"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >

                      <a
                        class="icon-wish-shadow" tabindex="0"
                        v-if="getSessionContent != null && nationCode != '_US'"
                        v-bind:class="{
                          on: getSessionContent.includes(item.content.id),
                        }"
                        @click="toggleLike(item, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >
                      <a
                        class="icon-wish-shadow" tabindex="0"
                        v-else-if="
                          getSessionContent == null && nationCode != '_US'
                        "
                        @click="toggleLike(item, 'HIMACS')"
                      >{{ $t('PCD.text.wish_list')}}</a
                      >
                    </div>
                    <div class="prod-info-ico new">
                      <p class="ico-r" v-if="item.attribute.isNew === 'true'">
                        {{ $t('PCD.text.new')}}
                      </p>
                      <span class="new" v-if="item.attribute.label != '' && item.attribute.label != undefined && item.attribute.label != null"
                            style="background-color: #595555;border-color: #595555;padding: 3px 13px 3px;color:white">{{ item.attribute.label }}</span>
                    </div>
                  </div>
                  <div class="info">
                    <ul>
                      <li class="txt-02">
                        <router-link v-if="nationCode != '_US' && nationCode != '_GL'"
                          :to="
                            '/' +
                            categoryName +
                            '/' +
                            item.orgContent.title +
                            '/' +
                            item.content.id
                          "
                        >{{ item.content.title }}</router-link
                        >
                        <router-link v-else
                          :to="
                            '/' +
                            'himacs-solid-surface' +
                            '/' +
                            $utils.getSeoName(item, 'content') +
                            '/' +
                            item.content.id
                          "
                        >{{ item.content.title }}</router-link
                        >
                      </li>
                      <li class="ctt ctt-t4">{{ item.attribute.code }}</li>
                      <li class="txt-03">{{ item.parents[0].name }}</li>
                    </ul>

                    <div class="ico-color" v-if="nationCode == '_US' || nationCode == '_GL'">
                      <span v-if="hueText === 'Other'"
                        class="color"
                        v-bind:style="{
                          borderColor: '#999',
                          backgroundImage : 'url(images/products/' + relhue +'.png',
                        }"
                      ></span>
                      <span v-else
                        class="color"
                        v-bind:style="{
                          borderColor: '#999',
                          backgroundColor: '#' + item.attribute.hue,
                        }"
                      ></span>
                      <span>{{ item.attribute.hueText }}</span>
                    </div>
                  </div>
                </div>
                <!-- end item -->
              </div>
            </div>
            <!-- end -->

            <!-- <div class="slider-related" >
              <div
                class="slider"
                v-for="(item, index) in relContentList"
                v-bind:key="index"
              >
                <div class="over-box">
                  <div class="over">
                    <router-link
                      :to="
                        '/' +
                        categoryName +
                        '/' +
                        item.orgContent.title +
                        '/' +
                        item.content.id
                      "
                    >
                      <img
                        :src="$imagePath + item.attribute.pf_thumb"
                        alt="PORTLAND"
                      />
                    </router-link>
                  </div>

                  <div class="view">
                    <a
                      @click="openSampleOrder(item.content.id)"
                      class="icon-cart-shadow"
                      v-if="nationCode === '_US'"
                    >cart</a
                    >

                    <a
                      class="icon-wish-shadow" tabindex="0"
                      v-if="authenticated == true && nationCode == '_US'"
                      v-bind:class="{ on: item.content.liked }"
                      @click="toggleLike(item, 'HIMACS')"
                    >wish list</a
                    >
                    <a
                      class="icon-wish-shadow" tabindex="0"
                      v-if="authenticated == false && nationCode == '_US'"
                      href="login/"
                      onclick="alert('Log in to save to your Likes');"
                    >wish list</a
                    >

                    <a
                      class="icon-wish-shadow" tabindex="0"
                      v-if="getSessionContent != null && nationCode == '_GL'"
                      v-bind:class="{
                        on: getSessionContent.includes(item.content.id),
                      }"
                      @click="toggleLike(item, 'HIMACS')"
                    >wish list</a
                    >
                    <a
                      class="icon-wish-shadow" tabindex="0"
                      v-else-if="
                        getSessionContent == null && nationCode == '_GL'
                      "
                      @click="toggleLike(item, 'HIMACS')"
                    >wish list</a
                    >
                  </div>

                  <div class="prod-info-ico">
                    <p class="ico-r" v-if="item.attribute.isNew === 'true'">
                      New
                    </p>
                  </div>
                </div>

                <div class="info">
                  <ul>
                    <li class="txt-02">
                      <router-link
                        :to="
                          '/' +
                          categoryName +
                          '/' +
                          item.orgContent.title +
                          '/' +
                          item.content.id
                        "
                      >{{ item.orgContent.title }}</router-link
                      >
                    </li>
                    <li class="ctt ctt-T1">{{ item.attribute.code }}</li>
                    <li class="txt-03">{{ item.parents[0].name }}</li>
                  </ul>

                  <div class="ico-color">
                    <span
                      class="color"
                      v-bind:style="{
                        borderColor: '#999',
                        backgroundColor: '#' + item.attribute.hue,
                      }"
                    ></span>
                    <span>{{ item.attribute.hueText }}</span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- //Related Products -->

          <div class="w-con">

            <!-- Features & Benefits -->
            <div class="fb-box" v-if="nationCode == '_US' || nationCode == '_GL'">
              <h2 class="tt-sub-gray ta-c">{{ $t('PD.text.features_Benefits')}}</h2>

              <div class="tab-ui">
                <!--              <div class="btns">-->
                <!--                <ul>-->
                <!--                  <li class="btn-tab" :data-tab="'tab0'+(index+1)" :id="'tab0'+(index+1)"-->
                <!--                      v-for="(coll, index) in getfeaturesList" v-bind:key="index">-->
                <!--                    <button type="button">{{coll.content.title}}</button>-->
                <!--                  </li>-->
                <!--                </ul>-->
                <!--              </div>-->

                <ul class="himac_list inner">
                  <li class="bd-gray"
                      v-for="(coll, index) in getfeaturesList"
                      :data-tab="'tab0' + (index + 1)"
                      :id="'tab0' + (index + 1)"
                  >
                    <dl>
                      <dt class="tt-2">
                        <span
                          class="ico-01"
                          v-bind:style="{
                            background:
                              'url( ' +
                              $imagePath +
                              coll.attribute.img +
                              ') center center no-repeat',
                          }"
                        ></span
                        >{{ coll.content.title }}
                      </dt>
                      <dd class="ctt ctt-t4">{{ coll.attribute.brief }}</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>

            <div class="fb-box eu-fb-box inner" v-if="nationCode != '_US' && nationCode != '_GL'">
              <h2 class="tt-sub-gray ta-c">{{ $t('PD.text.features_Benefits')}}</h2>

              <div class="tab-ui tab-ui-nav tab-ui-gray">
                <button type="button" class="arrow disabled tab-arr-prev arrow-short">
                  <span class="sr-only">이전</span>
                </button>
                <button type="button" class="arrow tab-arr-next arrow-short">
                  <span class="sr-only">다음</span>
                </button>
                <ul>
                  <li class="btn-tab on" data-tab="fb-box-01">
                    <button type="button">{{ $t('PCD.text.TAB_Quality_Design_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="fb-box-02">
                    <button type="button">{{ $t('PCD.text.TAB_Quality_Performance_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="fb-box-03">
                    <button type="button">{{ $t('PCD.text.TAB_Quality_Fabrication_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="fb-box-04">
                    <button type="button">{{ $t('PCD.text.TAB_Quality_Sustainability_title')}}</button>
                  </li>
                </ul>
              </div>

              <!-- Quality Design 탭 시작 -->
              <div class="tab-con fb-box-01 on"  v-if="nationCode != '_US' && nationCode != '_GL'">
                <div class="bd-gray">
                  <div v-for="design in bene_designText">

                    <div class="frow tc-list" v-if="design == '1'"> <!-- Quality Design 탭 1 -->
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-wide@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Design_title1')}}</p>
                          <p class="ctt tc-des">{{ $t('PCD.text.Design_brief1')}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="frow tc-list" v-if="design == '2'"> <!-- Quality Design 탭 2 -->
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-dramatic@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Design_title2')}}</p>
                          <p class="ctt tc-des">
                            {{ $t('PCD.text.Design_brief2')}}
                            {{ $t('PCD.text.Design_brief2_1')}}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="frow tc-list" v-if="design == '3'"> <!-- Quality Design 탭 3 -->
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-3-dimens@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Design_title3')}}</p>
                          <p class="ctt tc-des">
                            {{ $t('PCD.text.Design_brief3')}}
                            {{ $t('PCD.text.Design_brief3_1')}}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="frow tc-list tc-list-last" v-if="design == '4'"> <!-- Quality Design 탭 4 -->
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-unlimited@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Design_title4')}}</p>
                          <p class="ctt tc-des">
                            {{ $t('PCD.text.Design_brief4')}}
                            {{ $t('PCD.text.Design_brief4_1')}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Quality Design 탭 끝 -->

              <!-- Quality Performance 탭 시작 -->
              <div class="tab-con fb-box-02"  v-if="nationCode != '_US' && nationCode != '_GL'">
                <div class="bd-gray">

                  <div v-for="performance in bene_performanceText">
                    <div class="frow tc-list" v-if="performance == '1'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-strong@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Performance_title1')}}</p>
                          <p class="ctt tc-des">{{ $t('PCD.text.Performance_brief1')}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="frow tc-list" v-if="performance == '2'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-fire@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Performance_title2')}}</p>
                          <p class="ctt tc-des">
                            {{ $t('PCD.text.Performance_brief2')}}
                            {{ $t('PCD.text.Performance_brief2_1')}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="frow tc-list" v-if="performance == '3'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-hygienic@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Performance_title3')}}</p>
                          <p class="ctt tc-des">
                            <span class="pc-br">
                              {{ $t('PCD.text.Performance_brief3')}}
                              {{ $t('PCD.text.Performance_brief3_1')}}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="frow tc-list tc-list-last" v-if="performance == '4'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-water@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Performance_title4')}}</p>
                          <p class="ctt tc-des">
                            <span class="pc-br">
                              {{ $t('PCD.text.Performance_brief4')}}
                              {{ $t('PCD.text.Performance_brief4_1')}}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Quality Performance 탭 끝 -->

              <!-- Quality Fabrication 탭 시작 -->
              <div class="tab-con fb-box-03"  v-if="nationCode != '_US' && nationCode != '_GL'">
                <div class="bd-gray">

                  <div v-for="fabrication in bene_fabricationText">

                    <div class="frow tc-list" v-if="fabrication == '1'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-fabrication@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fabrication_title1')}}</p>
                          <p class="ctt tc-des">{{ $t('PCD.text.Fabrication_brief1')}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="frow tc-list" v-if="fabrication == '2'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-simple@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fabrication_title2')}}</p>
                          <p class="ctt tc-des">
                            <span class="pc-br">
                              {{ $t('PCD.text.Fabrication_brief2')}}
                              {{ $t('PCD.text.Fabrication_brief2_1')}}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="frow tc-list tc-list-last" v-if="fabrication == '3'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-seamless@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fabrication_title3')}}</p>
                          <p class="ctt tc-des">
                            <span class="pc-br">
                              {{ $t('PCD.text.Fabrication_brief3')}}
                              {{ $t('PCD.text.Fabrication_brief3_1')}}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Quality Fabrication 탭 끝 -->

              <!-- Quality Sustainability 탭 시작 -->
              <div class="tab-con fb-box-04"  v-if="nationCode != '_US' && nationCode != '_GL'">
                <div class="bd-gray">

                  <div v-for="sustainability in bene_sustainText">

                    <div class="frow tc-list" v-if="sustainability == '1'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-renewable@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Sustainability_title1')}}</p>
                          <p class="ctt tc-des">
                            <span class="pc-br">
                              {{ $t('PCD.text.Sustainability_brief1')}}
                              {{ $t('PCD.text.Sustainability_brief1_1')}}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="frow tc-list tc-list-last" v-if="sustainability == '2'">
                      <div class="fcol fcol-fixed" style="width: 100px;">
                        <img :src='`images/icon-himacs-sustainable@3x.png`'>
                      </div>
                      <div class="fcol">
                        <div class="tc-box">
                          <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Sustainability_title2')}}</p>
                          <p class="ctt tc-des">{{ $t('PCD.text.Sustainability_brief2')}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Quality Sustainability 탭 끝 -->

            </div>

            <!-- //Features & Benefits -->

            <!-- Certifications EU | UK -->
            <div class="eu-cer-box inner" v-if="nationCode != '_US' && nationCode != '_GL'">
              <h2 class="tt-sub-gray ta-c">{{ $t('PD.text.certifications')}}</h2>

              <div class="tab-ui tab-ui-nav tab-ui-gray">
                <button type="button" class="arrow disabled tab-arr-prev">
                  <span class="sr-only">이전</span>
                </button>
                <button type="button" class="arrow tab-arr-next">
                  <span class="sr-only">다음</span>
                </button>
                <ul>
                  <li class="btn-tab on" data-tab="cert-box-01">
                    <button type="button">{{ $t('PCD.text.TAB_Environment_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="cert-box-02">
                    <button type="button">{{ $t('PCD.text.TAB_Fire_Rating_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="cert-box-03">
                    <button type="button">{{ $t('PCD.text.TAB_HIMACS_Exteria_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="cert-box-04">
                    <button type="button">{{ $t('PCD.text.TAB_Hygiene_title')}}</button>
                  </li>
                  <li class="btn-tab" data-tab="cert-box-05">
                    <button type="button">{{ $t('PCD.text.TAB_Process_title')}}</button>
                  </li>
                </ul>
              </div>

              <!-- Environment 탭 시작 -->
              <div class="tab-con cert-box-01 on eu-cert-env" v-if="nationCode != '_US' && nationCode != '_GL'">
                <div class="bd-gray">
                  <div class="frow">
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '1'"><img :src='`images/icon-himacs-ministry@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '2'"><img :src='`images/icon-himacs-m-1-black@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '3'"><img :src='`images/green-building-council-black@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '4'"><img :src='`images/icon-himacs-iso-14001@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '5'"><img :src='`images/greenguard-black@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '6'"><img :src='`images/hbm-black@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '7'"><img :src='`images/dgnb@3x.png`'></span>
                    <span class="fcol" v-for="environment in certifi_environText" v-if="environment == '8'"><img :src='`images/breeam-black@3x.png`'></span>
                  </div>
                </div>
                <!-- Environment 팝업버튼 -->
                <div class="more-box tac">
                  <button type="button" class="btn" @click = "handle_toggle">
                    <span>Learn More</span>
                  </button>
                </div>
                <!-- Environment 팝업내용 -->
                <div class="layer-view layer-wide-example popup-v2 popup-v2-wide" v-show="is_show">
                  <div class="popup pop-close">
                    <div class="scr-y">
                      <div class="">

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-ministry@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title1')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief1')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-m-1-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title2')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief2')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/green-building-council-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title3')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief3')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-iso-14001@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title4')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief4')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/greenguard-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title5')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief5')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/hbm-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title6')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief6')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/dgnb@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title7')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief7')}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="frow tc-list tc-list-last">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/breeam-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Environment_title8')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Environment_brief8')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn-close" @click="handle_toggle">close</button>
                  </div>
                </div>

              </div>

              <!-- Environment 탭 끝 -->

              <!-- Fire Rating 탭 시작 -->
              <div class="tab-con cert-box-02 eu-cert-fir">
                <div class="bd-gray">
                  <div class="frow">
                    <span class="fcol" v-for="rating in certifi_rating" v-if="rating == '1'"><img :src='`images/icon-himacs-nfp-92-501@3x.png`'></span>
                    <span class="fcol" v-for="rating in certifi_rating" v-if="rating == '2'"><img :src='`images/icon-himacs-en-45545@3x.png`'></span>
                    <span class="fcol" v-for="rating in certifi_rating" v-if="rating == '3'"><img :src='`images/icon-himacs-en-13501@3x.png`'></span>
                    <span class="fcol" v-for="rating in certifi_rating" v-if="rating == '4'"><img :src='`images/icon-himacs-din-4102-1@3x.png`'></span>
                  </div>
                </div>
                <!-- Fire Rating 팝업버튼 -->
                <div class="more-box tac">
                  <button type="button" class="btn" @click = "handle_toggle_02">
                    <span>Learn More</span>
                  </button>
                </div>
                <!-- Fire Rating 팝업내용 -->
                <div class="layer-view layer-wide-example popup-v2 popup-v2-wide" v-show="is_show_02">
                  <div class="popup pop-close">
                    <div class="scr-y">
                      <div class="">
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-nfp-92-501@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fire_Rating_title1')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Fire_Rating_brief1')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-en-45545@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fire_Rating_title2')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Fire_Rating_brief2')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-en-13501@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fire_Rating_title3')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Fire_Rating_brief3')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list tc-list-last">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-din-4102-1@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Fire_Rating_title4')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Fire_Rating_brief4')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn-close" @click="handle_toggle_02">close</button>
                  </div>
                </div>
              </div>
              <!-- Fire Rating 탭 끝 -->

              <!-- HIMACS Exteria Certificates 탭 시작 -->
              <div class="tab-con cert-box-03 eu-cert-him">
                <div class="bd-gray">
                  <div class="frow">
                    <span class="fcol" v-for="exteria in certifi_exteriaText" v-if="exteria == '1'"><img :src='`images/icon-himacs-imo-med@3x.png`'></span>
                    <span class="fcol" v-for="exteria in certifi_exteriaText" v-if="exteria == '2'"><img :src='`images/icon-himacs-eota-black@3x.png`'></span>
                    <span class="fcol" v-for="exteria in certifi_exteriaText" v-if="exteria == '3'"><img :src='`images/icon-himacs-cstb-black@3x.png`'></span>
                  </div>
                </div>
                <!-- HIMACS Exteria Certificates 팝업버튼 -->
                <div class="more-box tac">
                  <button type="button" class="btn" @click = "handle_toggle_03">
                    <span>Learn More</span>
                  </button>
                </div>
                <!-- HIMACS Exteria Certificates 팝업내용 -->
                <div class="layer-view layer-wide-example popup-v2 popup-v2-wide" v-show="is_show_03">
                  <div class="popup pop-close">
                    <div class="scr-y">
                      <div class="">
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-imo-med@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Exteria_title1')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Exteria_brief1')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-eota-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Exteria_title2')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Exteria_brief2')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list tc-list-last">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-cstb-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Exteria_title3')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Exteria_brief3')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn-close" @click="handle_toggle_03">close</button>
                  </div>
                </div>
              </div>
              <!-- HIMACS Exteria Certificates 탭 끝 -->

              <!-- Hygiene 탭 시작 -->
              <div class="tab-con cert-box-04 eu-cert-hyg">
                <div class="bd-gray">
                  <div class="frow">
                    <span class="fcol" v-for="hygiene in certifi_hygieneText" v-if="hygiene == '1'">
                        <img :src='`images/icon-himacs-nsf-black@3x.png`'>
                    </span>
                    <span class="fcol" v-for="hygiene in certifi_hygieneText" v-if="hygiene == '2'">
                        <img :src='`images/icon-himacs-lga-black@3x.png`'>
                    </span>
                  </div>
                </div>
                <!-- Hygiene 팝업버튼 -->
                <div class="more-box tac">
                  <button type="button" class="btn" @click = "handle_toggle_04">
                    <span>Learn More</span>
                  </button>
                </div>
                <!-- Hygiene 팝업내용 -->
                <div class="layer-view layer-wide-example popup-v2 popup-v2-wide" v-show="is_show_04">
                  <div class="popup pop-close">
                    <div class="scr-y">
                      <div class="">
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-nsf-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Hygiene_title1')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Hygiene_brief1')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list tc-list-last">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-lga-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Hygiene_title2')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Hygiene_brief2')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn-close" @click="handle_toggle_04">close</button>
                  </div>
                </div>
              </div>
              <!-- Hygiene 탭 끝 -->

              <!-- Process 탭 시작 -->
              <div class="tab-con cert-box-05 eu-cert-pro">
                <div class="bd-gray">
                  <div class="frow">
                    <span class="fcol" v-for="process in certifi_processText" v-if="process == '1'">
                      <img :src='`images/icon-himacs-ohsas-18001-black@3x.png`'>
                    </span>
                    <span class="fcol" v-for="process in certifi_processText" v-if="process == '2'">
                      <img :src='`images/icon-himacs-iso-9001-black@3x.png`'>
                    </span>
                  </div>
                </div>
                <!-- Process 팝업버튼 -->
                <div class="more-box tac">
                  <button type="button" class="btn" @click = "handle_toggle_05">
                    <span>Learn More</span>
                  </button>
                </div>
                <!-- Process 팝업내용 -->
                <div class="layer-view layer-wide-example popup-v2 popup-v2-wide" v-show="is_show_05">
                  <div class="popup pop-close">
                    <div class="scr-y">
                      <div class="">
                        <div class="frow tc-list">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-ohsas-18001-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Process_title1')}}</p>
                              <p class="ctt tc-des">{{ $t('PCD.text.Process_brief1')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="frow tc-list tc-list-last">
                          <div class="fcol fcol-fixed" style="width: 100px;">
                            <img :src='`images/icon-himacs-iso-9001-black@3x.png`'>
                          </div>
                          <div class="fcol">
                            <div class="tc-box">
                              <p class="ctt ctt-t2 tc-tit">{{ $t('PCD.text.Process_title2')}}</p>
                              <p class="ctt tc-des">
                                <span>{{ $t('PCD.text.Process_brief2')}}</span>
                              <ul class="ul-bullet-disc">
                                <li>{{ $t('PCD.text.Process_brief3')}} </li>
                                <li>{{ $t('PCD.text.Process_brief4')}} </li>
                                <li>{{ $t('PCD.text.Process_brief5')}} </li>
                              </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn-close" @click="handle_toggle_05">close</button>
                  </div>
                </div>
              </div>
              <!-- Process 탭 끝 -->
            </div>
            <!-- //Certifications -->

            <!-- Certifications -->
            <div class="cer-box inner " v-if="nationCode == '_US' || nationCode == '_GL'">
              <h2 class="tt-sub-gray ta-c">{{ $t('PD.text.certifications')}}</h2>

              <div class="bd-gray">
                <ul>
                  <li v-for="(iconCert, index) in certList" v-bind:key="index">
                    <img :src="$imagePath + iconCert.img" alt="HB" />
                  </li>
                </ul>
              </div>
            </div>
            <!-- //Certifications -->

            <!-- Documentation US | GL 전용 -->
            <div class="doc-box inner" v-if="nationCode == '_US' || nationCode == '_GL'">
              <h2 class="tt-sub-gray ta-c ">{{ $t('PD.text.documentation_technical_details')}}</h2>

              <div class="drop-list bd-gray">
                <ul>
                  <li v-if="catalogueList.list.length > 0"><!-- 변경 전 cms 등록 시 감추기 조건 v-if="catalogueList.disabled != true" -->
                    <div class="tit">
                      <h3>{{ $t('PD.text.catalogue')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul>
                        <li v-for="(cata, index) in catalogueList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" class="download" @click="download(cata)" >{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li v-if="technicalList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.technical_datasheet')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul >
                        <li v-for="(cata, index) in technicalList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li v-if="nationCode === '_GL' && fabricationList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.fabrication')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul >
                        <li v-for="(cata, index) in fabricationList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li v-if="installationGuideList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.installation_guide')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul>
                        <li v-for="(cata, index) in installationGuideList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li v-if="warrantyList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.warranty')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul>
                        <li v-for="(cata, index) in warrantyList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li v-if="careMaintenanceList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.care_maintenance')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul>
                        <li v-for="(cata, index) in careMaintenanceList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

<!--                  <li v-if="nationCode == '_GL' && fabricationList.disabled != true" >-->
<!--                    <div class="tit">-->
<!--                      <a href="javascript:void(0);">{{ $t('PD.text.fabrication')}}</a>-->
<!--                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>-->
<!--                    </div>-->

<!--                    <div class="cont bd-0">-->
<!--                      <ul >-->
<!--                        <li v-for="(cata, index) in fabricationList.list" v-bind:key="index">-->
<!--                          <span>{{ cata.title }}</span>-->
<!--                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </li>-->

<!--                  <li v-if="nationCode == '_GL' && testReportList.disabled != true">-->
<!--                    <div class="tit">-->
<!--                      <a href="javascript:void(0);">{{ $t('PD.text.test_report')}}</a>-->
<!--                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>-->
<!--                    </div>-->

<!--                    <div class="cont bd-0">-->
<!--                      <ul>-->
<!--                        <li v-for="(cata, index) in testReportList.list" v-bind:key="index">-->
<!--                          <span>{{ cata.title }}</span>-->
<!--                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </li>-->

                  <li v-if="certificationList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.certifications')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul>
                        <li v-for="(cata, index) in certificationList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li v-if="nationCode === '_GL' && testReportList.list.length > 0">
                    <div class="tit">
                      <h3>{{ $t('PD.text.test_report')}}</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul >
                        <li v-for="(cata, index) in testReportList.list" v-bind:key="index">
                          <span>{{ cata.content.title }}</span>
                          <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                        </li>
                      </ul>
                    </div>
                  </li>

                </ul>
              </div>

              <div class="more-box ta-c">
                <button type="button" class="btn" @click="goToDocu()">
                  <span>{{ $t('PD.button.see_all_documentation')}}</span>
                </button>
              </div>
            </div>

            <!-- EU | UK 전용 - documentation -->
            <div class="doc-box inner eu-doc-box" v-if="nationCode != '_US' && nationCode != '_GL'">
              <h2 class="tt-sub-gray ta-c ">Documentation</h2>
              <div class="drop-list bd-gray">
                <ul>

                  <!-- catalogues -->
                  <li v-if="catalogues.disabled != true">
                    <div class="tit">
                      <h3 href="javascript:void(0);">catalogues</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>
                    <div class="cont bd-0">
                      <ul>

                        <li class="doc-drop-li eu-ddl" v-for="(cata, index) in catalogues.list" v-bind:key="index">
                          <div class="flex-wrap">
                            <span class="doc-con-tit">{{ cata.content.title }}</span>
                            <div class="btns">
                              <ul>
                                <li class="sel-box">
                                  <button type="button" class="btn-seg btn-sel">
                                    <span class="lang-sel-btn-txt" :id="'catalogues_'+index">English</span>
                                  </button>
                                  <div  class="sel-list" style="display: none">
                                    <ul class="box">
                                      <li><button type="button" @click="downloadTab('English', 'catalogues_'+index)">English</button></li>
                                      <li><button type="button" @click="downloadTab('German', 'catalogues_'+index)">German</button></li>
                                      <li><button type="button" @click="downloadTab('France', 'catalogues_'+index)">France</button></li>
                                      <li><button type="button" @click="downloadTab('Italian', 'catalogues_'+index)">Italian</button></li>
                                      <li><button type="button" @click="downloadTab('Spanish', 'catalogues_'+index)">Spanish</button></li>
                                      <li><button type="button" @click="downloadTab('Dutch', 'catalogues_'+index)">Dutch</button></li>
                                      <li><button type="button" @click="downloadTab('English (UK)', 'catalogues_'+index)">English (UK)</button></li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <button type="button" class="download eu-download" @click="downloadDocument(cata, 'catalogues_'+index)" >{{ $t('PD.button.download')}}</button>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </li>

                  <!-- Warranty -->
                  <li v-if="Warranty.disabled != true">
                    <div class="tit">
                      <h3 href="javascript:void(0);">Warranty</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>
                    <div class="cont bd-0">
                      <ul>

                        <li class="doc-drop-li eu-ddl" v-for="(cata, index) in Warranty.list" v-bind:key="index">
                          <div class="flex-wrap">
                            <span class="doc-con-tit">{{ cata.content.title }}</span>
                            <div class="btns">
                              <ul>
                                <li class="sel-box">
                                  <button type="button" class="btn-seg btn-sel">
                                    <span class="lang-sel-btn-txt" :id="'Warranty_'+index">English</span>
                                  </button>
                                  <div class="sel-list" style="display: none">
                                    <ul class="box">
                                      <li><button type="button" @click="downloadTab('English', 'Warranty_'+index)">English</button></li>
                                      <li><button type="button" @click="downloadTab('German', 'Warranty_'+index)">German</button></li>
                                      <li><button type="button" @click="downloadTab('France', 'Warranty_'+index)">France</button></li>
                                      <li><button type="button" @click="downloadTab('Italian', 'Warranty_'+index)">Italian</button></li>
                                      <li><button type="button" @click="downloadTab('Spanish', 'Warranty_'+index)">Spanish</button></li>
                                      <li><button type="button" @click="downloadTab('Dutch', 'Warranty_'+index)">Dutch</button></li>
                                      <li><button type="button" @click="downloadTab('English (UK)', 'Warranty_'+index)">English (UK)</button></li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <button type="button" class="download eu-download" @click="downloadDocument(cata, 'Warranty_'+index)" >{{ $t('PD.button.download')}}</button>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </li>

                  <!-- Care & Maintenance -->
                  <li v-if="Care_Maintenance.disabled != true">
                    <div class="tit">
                      <h3 href="javascript:void(0);">Care &#38; Maintenance</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>
                    <div class="cont bd-0">
                      <ul>

                        <li class="doc-drop-li eu-ddl" v-for="(cata, index) in Care_Maintenance.list" v-bind:key="index">
                          <div class="flex-wrap">
                            <span class="doc-con-tit">{{ cata.content.title }}</span>
                            <div class="btns">
                              <ul>
                                <li class="sel-box">
                                  <button type="button" class="btn-seg btn-sel">
                                    <span class="lang-sel-btn-txt" :id="'Care_Maintenance_'+index">English</span>
                                  </button>
                                  <div class="sel-list" style="display: none">
                                    <ul class="box">
                                      <li><button type="button" @click="downloadTab('English', 'Care_Maintenance_'+index)">English</button></li>
                                      <li><button type="button" @click="downloadTab('German', 'Care_Maintenance_'+index)">German</button></li>
                                      <li><button type="button" @click="downloadTab('France', 'Care_Maintenance_'+index)">France</button></li>
                                      <li><button type="button" @click="downloadTab('Italian', 'Care_Maintenance_'+index)">Italian</button></li>
                                      <li><button type="button" @click="downloadTab('Spanish', 'Care_Maintenance_'+index)">Spanish</button></li>
                                      <li><button type="button" @click="downloadTab('Dutch', 'Care_Maintenance_'+index)">Dutch</button></li>
                                      <li><button type="button" @click="downloadTab('English (UK)', 'Care_Maintenance_'+index)">English (UK)</button></li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <button type="button" class="download eu-download" @click="downloadDocument(cata, 'Care_Maintenance_'+index)" >{{ $t('PD.button.download')}}</button>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </li>

                  <!-- Specification Documents -->
                  <li v-if="Specification_Documents.disabled != true">
                    <div class="tit">
                      <h3 href="javascript:void(0);">Specification Documents</h3>
                      <button type="button" class="btn-view">{{ $t('PD.button.list_view')}}</button>
                    </div>

                    <div class="cont bd-0">
                      <ul>

                        <li class="doc-drop-li eu-ddl" v-for="(cata, index) in Specification_Documents.list" v-bind:key="index">
                          <div class="flex-wrap">
                            <span class="doc-con-tit">{{ cata.content.title }}</span>
                            <div class="btns">
                              <ul>
                                <li class="sel-box">
                                  <button type="button" class="btn-seg btn-sel">
                                    <span class="lang-sel-btn-txt" :id="'Specification_'+index">English</span>
                                  </button>
                                  <div class="sel-list" style="display: none">
                                    <ul class="box">
                                      <li><button type="button" @click="downloadTab('English', 'Specification_'+index)">English</button></li>
                                      <li><button type="button" @click="downloadTab('German', 'Specification_'+index)">German</button></li>
                                      <li><button type="button" @click="downloadTab('France', 'Specification_'+index)">France</button></li>
                                      <li><button type="button" @click="downloadTab('Italian', 'Specification_'+index)">Italian</button></li>
                                      <li><button type="button" @click="downloadTab('Spanish', 'Specification_'+index)">Spanish</button></li>
                                      <li><button type="button" @click="downloadTab('Dutch', 'Specification_'+index)">Dutch</button></li>
                                      <li><button type="button" @click="downloadTab('English (UK)', 'Specification_'+index)">English (UK)</button></li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <button type="button" class="download eu-download" @click="downloadDocument(cata, 'Specification_'+index)" >{{ $t('PD.button.download')}}</button>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </li>

                </ul>
              </div>

              <div class="more-box ta-c">
                <button type="button" class="btn" @click="goToDocu()">
                  <span>{{ $t('PD.button.see_all_documentation')}}</span>
                </button>
              </div>
            </div>
            <!-- //Documentation -->

          </div>

          <!-- Inspiration -->
          <div class="ins-box">
            <h2 class="tt-sub-gray ta-c">{{ $t('PD.text.inspiration')}}</h2>

            <div class="slider-ins himacs-pd-ins">
              <!-- @click="$router.push('/case-studies/'+ categoryCode + '/'+ item.content.id)" -->
              <div
                class="slider"
                v-for="(insp, index) in inspirationList"
                v-bind:key="index"
                v-if="index < 6"
                @click="goInsDetail(insp)"
              >
                <div class="img-box">
                  <img
                    :src="$imagePath + insp.attribute.pc_thumb"
                    alt="Dr. Leahu Dental Czlinic in Oradea"
                  />
                  <p class="badge">{{ insp.parents[0].name }}</p>
                </div>

                <div class="txt-box">
                  <ul>
                    <li class="txt-01">
                      <a href="javascript:void(0);">{{ insp.content.title }}</a>
                    </li>
                    <li class="txt-02">{{ insp.attribute.segments1Text }}</li>
                    <li class="txt-03">{{ insp.attribute.location }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="more-box ta-c">
              <button type="button" class="btn">
                <a href="inspiration/case-studies/himacs"
                ><span>{{ $t('PD.button.see_all_case_studies')}}</span></a
                >
              </button>
            </div>
          </div>
          <!--//Inspiration-->

          <!-- popup : enlarge -->
          <div class="layer-popup layer-enlarge">
            <!-- 이미지가 밝을 경우 'type02' 클래스를 추가해주세요. -->
            <div class="popup">
              <div class="title-box">
                <p class="product-name">{{ contentName }}</p>
                <div class="btns">
                  <a href="javascript:void(0);" class="ico-cal" v-if="nationCode == '_US' || nationCode == '_GL'">{{ collection[0] }} Collection  </a>
                  <a href="javascript:void(0);" class="ico-cal" v-if="nationCode != '_US' && nationCode != '_GL' && collection.length < 1 ">{{ collection[0] }} Collection  </a>
                  <a href="javascript:void(0);" class="ico-cal" v-if="nationCode != '_US' && nationCode != '_GL' && collection.length > 1 ">{{ collection.join(' / ') }}</a>
                  <a href="javascript:void(0);" class="ico-st">Pattern - {{ pattern }}</a>
                </div>
              </div>

              <SeaDragon v-bind:imagePath="$imagePath + imgPage[0].detail" v-if="showDragon"></SeaDragon>

              <button
                type="button"
                class="btn-close"
                @click="enlargeLayer()"
              >
                {{ $t('PCD.button.close')}}
              </button>
            </div>
            <!-- //popup -->
          </div>
          <!-- //popup : enlarge -->
        </div>
      </div>
      <!-- //container (wide ver) -->
    </div>
    <!-- //content -->
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import ShareComponent from '@/components/Layout/Share'
import SampleOrderPopComponent from '@/components/Popup/SampleOrder'
import categoryService from '@/server/category.service'
import InspirationService from '@/server/inspiration.service'
import ProductService from '@/server/product.service.js'
import ProsService from '@/server/pros.service'
import { debounce } from 'vue-debounce'
import { mapState } from 'vuex'
import SeaDragon from './SeaDragon'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    FooterComponent,
    SampleOrderPopComponent,
    GnbComponent,
    ShareComponent,
    SeaDragon
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      is_show: false,
      is_show_02: false,
      is_show_03: false,
      is_show_04: false,
      is_show_05: false,
      youtubeWidth: '',
      youtubeHeight: '',
      youtubeCheck: false,
      filedown: '',
      sliderLength: '',

      visibleImage: false,
      showDragon: false,
      categoryName: '' /* 카테고리 */,
      contentName: '' /* 본 컨텐츠 내용 */,
      hue: '' /* 데이터 사전에서 받아온 값 */,
      hueText: '',
      collection: [] /* 데이터 사전에서 받아온 값  */,
      relhue: '',

      /* Us content */
      brief: '',
      dimension: '',
      remark: '',
      pattern: '',
      adCode: '',
      adName: '',
      countryCode: '',
      contentId: '',

      /* Us content end */

      /* APPLICATIONS 표시 여부 */
      applications: { list: [] },
      /* CERTIFICATIONS 표시 여부 */
      certifications: { list: [] },
      certList: [],
      imgPage: { list: [] } /* 이미지 불러오기 */,
      Tslider: 0 /* 슬라이더 번호 추출 */,
      imgWidth: 0 /* 이미지 가로 */,
      imgHeight: 0 /* 이미지 세로 */,

      /* Related Flooring content */
      relContentList: [] /* 상품 관련 콘텐츠 리스트 */,
      /* Related Flooring content end */

      // inspiration List
      inspirationList: [],
      categoryCode: '',

      // document List
      installationGuideList: { disabled: false, list: [] },
      warrantyList: { disabled: false, list: [] },
      catalogueList: { disabled: false, list: [] },
      technicalList: { disabled: false, list: [] },
      fabricationList: { disabled: false, list: [] },
      certificationList: { disabled: false, list: [] },
      testReportList: { disabled: false, list: [] },
      careMaintenanceList: { disabled: false, list: [] },

      getfeaturesList: [],

      imgShow: false,

      nationCode: '',
      chkdata: [],
      isNew: '',
      label: '',
      discontinued: '',
      madeInUsa: '',
      colorCode: '',
      exclusive: '',
      imgThumb: [],
      imgImage: [],
      imgDetail: [],
      oldSource: {},

      featShow: false,

      contentList: [],

      thisContentLike: { content: { liked: false } },

      getSessionContent: [] /* GL : 불러오는 값 */,

      full_slap: [],
      cover: [],

      /* spec see more */
      flag: false,
      /* spec see more end */

      /* EU / UK 스펙 */

      thickness: [],
      tones: [],
      USE: [],
      notice: '',
      semi_translucent_colour: '',
      recycled: '',
      designer_series: '',
      lucent: '',
      veined: '',
      Aurora: '',

      indoor: '',
      outdoor: '',
      kitchen_and_bathroom: '',

      catalogues: { disabled: false, list: [] },
      technical_datasheets: { disabled: false, list: [] },
      Warranty: { disabled: false, list: [] },
      Care_Maintenance: { disabled: false, list: [] },
      Specification_Documents: { disabled: false, list: [] },

      /* END -> EU / UK 스펙 */

      /* EU / UK FEATURES & BENEFITS */

      bene_designText: [],
      bene_fabricationText: [],
      bene_performanceText: [],
      bene_sustainText: [],

      /* END => EU / UK FEATURES & BENEFITS */

      /* EU / UK Certifications */

      certifi_environText: [],
      certifi_exteriaText: [],
      certifi_hygieneText: [],
      certifi_processText: [],
      certifi_rating: [],
      /* END => EU / UK Certifications */
      metaDescription: ''

    }
  },
  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  async created () {
    const t = this

    t.nationCode = t.$countryCode
    t.contentId = this.$route.params.contentId

    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },

  async mounted () {
    var tabInstance = $('.tab-ui-nav')
    $.each(tabInstance, function (key, value) {
      var windowWidth = $(window).width()
      var arrows = $(tabInstance[key]).find('.arrow')
      var prevArrow = arrows.filter('.tab-arr-prev')
      var nextArrow = arrows.filter('.tab-arr-next')
      var box = $(tabInstance[key]).find('ul')
      var x = 0
      var mx = 0
      var maxScrollWidth = box[0].scrollWidth - windowWidth / 2 - box.width() / 2

      $(arrows).on('click', function () {
        if ($(this).hasClass('tab-arr-next')) {
          x = box.width() / 2 + box.scrollLeft()
          box.animate({
            scrollLeft: x
          })
        } else {
          x = box.width() / 2 - box.scrollLeft()
          box.animate({
            scrollLeft: -x
          })
        }
      })

      $(box).on({
        mousemove: function (e) {
          var mx2 = e.pageX - this.offsetLeft
          if (mx) this.scrollLeft = this.sx + mx - mx2
        },
        mousedown: function (e) {
          this.sx = this.scrollLeft
          mx = e.pageX - this.offsetLeft
        },
        scroll: function () {
          toggleArrows()
        }
      })

      $(document).on('mouseup', function () {
        mx = 0
      })

      function toggleArrows () {
        if (box.scrollLeft() > maxScrollWidth) {
          nextArrow.addClass('disabled')
        } else if (box.scrollLeft() < 5) {
          prevArrow.addClass('disabled')
        } else {
          // both are enabled
          nextArrow.removeClass('disabled')
          prevArrow.removeClass('disabled')
        }
      }
    })
    const t = this
    /* ==================================================== start [ US 컨텐츠 ] ======================================================== */

    /* URL 주소 에서 값 받아온다 */
    this.contentId = this.$route.params.contentId
    /* content 원본 / US 내용 가져온다. */
    const contentRes = await ProductService.getContent(this.contentId)
    t.metaDescription = contentRes.data.attribute.meta
    t.thisContentLike = contentRes.data
    t.contentList = contentRes.data
    const certifications = contentRes.data.attribute.cert

    // GTM - product detail
    this.makeDataLayerForm(contentRes.data)

    if (typeof (dataLayer) !== 'undefined') {
      dataLayer.push({ event: 'ecDetail' })
    }

    if (t.nationCode == '_US' || t.nationCode == '_GL') { // US | GL 일 때 조건
      // Documentation List  -- 도큐먼테이션 전부 다 가져오는 걸로 변경요청.22-05-10
      const orderType = []
      const orderField = []
      const pageIndex = 1
      const pageSize = 9999

      const docContentRes = await ProsService.getDocumentationList('DOC_HIMACS' + this.nationCode, [], '', pageIndex, pageSize, orderField, orderType)

      if (docContentRes.data.list !== null && docContentRes.data.list !== undefined && docContentRes.data.list !== '') {
        _.filter(docContentRes.data.list, function (o) {
          if (o.attribute.doctype === 'Catalogue') {
            t.catalogueList.list.push(o)
          }
          if (o.attribute.doctype === 'Technical_Datasheet') {
            t.technicalList.list.push(o)
          }
          if (o.attribute.doctype === 'Installation_Guide') {
            t.installationGuideList.list.push(o)
          }
          if (o.attribute.doctype === 'Warranty') {
            t.warrantyList.list.push(o)
          }
          if (o.attribute.doctype === 'Care') {
            t.careMaintenanceList.list.push(o)
          }
          if (o.attribute.doctype === 'Certification') {
            t.certificationList.list.push(o)
          }
          if (t.nationCode === '_GL') {
            if (o.attribute.doctype === 'Fabrication') {
              t.fabricationList.list.push(o)
            }
            if (o.attribute.doctype === 'Test_Report') {
              t.testReportList.list.push(o)
            }
          }
        })
      }
    }

    if (t.nationCode != '_GL' && t.nationCode != '_US') { // EU | UK 일 때 조건
      const orderType = []
      const orderField = []
      const pageIndex = 1
      const pageSize = 9999

      const contentRes = await ProsService.getDocumentationList('DOC_HIMACS' + this.nationCode, ['doctype_Catalogues', 'doctype_Warranty', 'doctype_Care', 'doctype_Specification'], '', pageIndex, pageSize, orderField, orderType)

      if (contentRes.data.list != null && contentRes.data.list != undefined && contentRes.data.list != '') {
        _.filter(contentRes.data.list, function (o) {
          if (o.attribute.doctypeText == 'Catalogues') {
            t.catalogues.list.push(o)
          }
          if (o.attribute.doctypeText == 'Warranty') {
            t.Warranty.list.push(o)
          }
          if (o.attribute.doctypeText == 'Care & Maintenance') {
            t.Care_Maintenance.list.push(o)
          }
          if (o.attribute.doctypeText == 'Specification Documents') {
            t.Specification_Documents.list.push(o)
          }
        })
      }
    }

    /* 화면 중간쯤 컨텐츠 가져오기 */
    if (certifications != null) {
      t.certifications = contentRes.data.attribute.cert.split(',')
    }

    // CERTI_HIMACS_US
    const certifi = await ProductService.getCategoryCode(
      'CERTI_HIMACS' + t.nationCode
    )

    const getcertifi = await ProductService.getContentList(
      certifi.data.object.id
    )
    t.iconCertList = getcertifi.data

    for (let i = 0; i < getcertifi.data.list.length; i++) {
      const selectedCert = _.filter(t.certifications, function (o) {
        return o === t.iconCertList.list[i].content.title
      })
      if (selectedCert[0] !== undefined) {
        this.certList.push({
          title: selectedCert[0],
          img: t.iconCertList.list[i].attribute.img
        })
      }
    }

    /* ------ 관련 콘텐츠 ------ */
    if (t.nationCode === '_US') {
      t.relContentList = contentRes.data.related.HIMACS_US
    }
    if (t.nationCode === '_GL') {
      t.relContentList = contentRes.data.related.HIMACS_GL
    }
    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      t.relContentList = contentRes.data.related.HIMACS_EU
    }

    // const collectionList = await ProductService.getCollection('HIMACS_COLOR'+t.nationCode)

    /* categoryName */
    t.categoryName = contentRes.data.orgContent.modelId
    t.colorCode = contentRes.data.attribute.code

    /* contentName */
    t.contentName = contentRes.data.content.title

    t.discontinued = contentRes.data.attribute.discontinued
    /* 카테고리 collection|hue 정보 가져오기 */

    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      t.hue = '#' + contentRes.data.attribute.hue
      t.relhue = contentRes.data.attribute.hue
      t.hueText = contentRes.data.attribute.hueText
    } else {
      t.hue = '#fff'
      t.hueText = contentRes.data.attribute.tonesText
    }

    /* 스펙 가져오기 */
    t.brief = contentRes.data.attribute.brief
    t.dimension = contentRes.data.attribute.dimension
    t.isNew = contentRes.data.attribute.isNew
    t.label = contentRes.data.attribute.label
    t.exclusive = contentRes.data.attribute.exclusive

    /* EU / UK 스펙 가져오기 */

    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      if (contentRes.data.attribute.thicknessText != null && contentRes.data.attribute.thicknessText != undefined) {
        t.thickness = contentRes.data.attribute.thicknessText
      }

      if (contentRes.data.attribute.USEText != null && contentRes.data.attribute.USEText != undefined) {
        const usetext = contentRes.data.attribute.USEText

        _.filter(usetext, function (o) {
          if (o == 'Outdoor') {
            t.outdoor = 'Suitable for exterior use. Please check conditions about level of suitability and warranty per colour'
            t.USE.push(t.outdoor)
          }
          if (o == 'Indoor') {
            t.indoor = 'Indoor'
            t.USE.push(t.indoor)
          }
          if (o == 'Kitchen and Bathroom') {
            t.kitchen_and_bathroom = 'Suitable for Kitchen & Bathroom'
            t.USE.push(t.kitchen_and_bathroom)
          }
        })
      }

      if (contentRes.data.attribute.tonesText != null && contentRes.data.attribute.tonesText != undefined) {
        t.tones = contentRes.data.attribute.tonesText
      }

      if (contentRes.data.attribute.recycledText != null && contentRes.data.attribute.recycledText != undefined) {
        t.recycled = contentRes.data.attribute.recycledText
      }

      t.notice = contentRes.data.attribute.notice
      t.semi_translucent_colour = contentRes.data.attribute.semiTranslucent
      // t.outdoor = contentRes.data.attribute.semiTranslucent;
      t.designer_series = contentRes.data.attribute.marcelWanders
      t.lucent = contentRes.data.attribute.lucent
      t.veined = contentRes.data.attribute.veined
      t.Aurora = contentRes.data.attribute.auroa

      // FEATURES & BENEFITS EU | UK
      t.bene_designText = contentRes.data.attribute.bene_design
      t.bene_fabricationText = contentRes.data.attribute.bene_fabrication
      t.bene_performanceText = contentRes.data.attribute.bene_performance
      t.bene_sustainText = contentRes.data.attribute.bene_sustain
      // END => FEATURES & BENEFITS EU | UK

      // Certifications EU | UK
      t.certifi_environText = contentRes.data.attribute.certifi_environ
      t.certifi_exteriaText = contentRes.data.attribute.certifi_exteria
      t.certifi_hygieneText = contentRes.data.attribute.certifi_hygiene
      t.certifi_processText = contentRes.data.attribute.certifi_process
      t.certifi_rating = contentRes.data.attribute.certifi_rating
      // END => Certifications EU | UK
    }

    /* EU / UK 스펙 가져오기 끝 */

    if (t.nationCode === '_US') {
      t.madeInUsa = contentRes.data.attribute.made_in_usa
    }

    // US이면서 미국생산
    if (t.nationCode === '_US') {
      t.remark = contentRes.data.attribute.remark
    }

    t.pattern = contentRes.data.attribute.patternText
    t.adCode = contentRes.data.attribute.adhesive_code
    t.adName = contentRes.data.attribute.adhesive_name
    if (t.nationCode === '_GL' && contentRes.data.attribute.countryText != null && contentRes.data.attribute.countryText != undefined) {
      // t.countryCode = contentRes.data.attribute.country.replace(/,/gim, ", ").replace(/, $/, '')
      t.countryCode = contentRes.data.attribute.countryText.join(', ')
    }
    t.collection.push(contentRes.data.parents[0].name)

    if (contentRes.data.attribute.collectionText != null && contentRes.data.attribute.collectionText != undefined) {
      t.collection.push(contentRes.data.attribute.collectionText)
    }

    if (contentRes.data.attribute.collection1Text != null && contentRes.data.attribute.collection1Text != undefined) {
      t.collection.push(contentRes.data.attribute.collection1Text)
    }

    /* 이미지 찾고 불러오기 */

    let fileList = []

    if (
      contentRes.data.attribute.color_image != null &&
      contentRes.data.attribute.color_image != '' &&
      contentRes.data.attribute.color_image.image != '' &&
      contentRes.data.attribute.color_image.thumb != '' &&
      contentRes.data.attribute.color_image != '[]' // 임시 [] 배열이 데이터로 가져온 값이라 해결이 되지 않음
    ) {
      fileList = JSON.parse(JSON.stringify(contentRes.data.attribute.color_image))

      _.forEach(fileList, async (v) => {
        if (v.image && v.image.startsWith('https://www.youtube.com')) {
          _.remove(fileList, function (c) { /* 중복 제거 및 youtube 영상은 swatch 뒤에 위치 시켜야 함. */
            if (c.image === v.image) {
              return c
            }
          })
          fileList.unshift({ thumb: v.thumb, image: v.image, detail: v.detail })
        }
      })

      if (fileList[0].image == null || fileList[0].image == '') {
        fileList = []
      }
    }

    if (
      contentRes.data.attribute.full_slap_thumb != null &&
      contentRes.data.attribute.full_slap_thumb != ''
    ) {
      t.full_slap = {
        thumb: contentRes.data.attribute.full_slap_thumb,
        image: contentRes.data.attribute.full_slap_image,
        detail: contentRes.data.attribute.full_slap_detail
      }
    }
    if (
      contentRes.data.attribute.image != null &&
      contentRes.data.attribute.image != ''
    ) {
      if (t.nationCode == '_US' || t.nationCode == '_GL') {
        t.cover = {
          thumb: contentRes.data.orgContent.cover,
          image: contentRes.data.attribute.image,
          detail: contentRes.data.attribute.detail_image
        }
      }

      if (t.nationCode != '_US' && t.nationCode != '_GL' && contentRes.data.orgContent.cover != null &&
        contentRes.data.orgContent.cover != '' && contentRes.data.orgContent.cover != undefined) {
        t.cover = {
          thumb: contentRes.data.orgContent.cover,
          image: contentRes.data.attribute.image,
          detail: contentRes.data.attribute.detail_image
        }
      } else if (t.nationCode != '_US' && t.nationCode != '_GL' && contentRes.data.content.cover != null &&
        contentRes.data.content.cover != '' && contentRes.data.content.cover != undefined) {
        t.cover = {
          thumb: contentRes.data.content.cover,
          image: contentRes.data.attribute.image,
          detail: contentRes.data.attribute.detail_image
        }
      }
    }
    if (t.cover != null && t.cover.thumb != null) {
      if (fileList != '[]') { // 임시 [] 배열이 데이터로 가져온 값이라 해결이 되지 않음
        fileList.unshift(t.cover)
      } else {
        fileList[0] = { thumb: contentRes.data.orgContent.cover, image: contentRes.data.attribute.image, detail: contentRes.data.attribute.detail_image }
      }
    }
    if (t.full_slap != null && t.full_slap.thumb != null) {
      fileList.unshift(t.full_slap)
    }
    t.imgPage = fileList

    // 확대기능 레이어 팝업에 들어가는 이미지 크기 추출해오기!
    if (fileList.length != 0 && fileList[0].pc != null) {
      t.imgWidth = fileList[0].width
      t.imgHeight = fileList[0].height
    }

    this.youtubeWidth = '900px'
    this.youtubeHeight = '600px'

    /* //==================================================== end [ US 컨텐츠 ] ======================================================== */

    // start ######################################## FEATURES & BENEFITS
    const features = await ProductService.getCategoryCode(
      'FEAT_HIMACS' + t.nationCode
    )

    const getfeatures = await ProductService.getContentList(
      features.data.object.id
    )
    this.getfeaturesList = getfeatures.data.list
    // end ######################################## FEATURES & BENEFITS

    /* ==================================================== start [ inspiration 컨텐츠 ] ======================================================== */

    this.categoryCode = 'INS_CAS_HIMACS' + t.nationCode
    const categoryRes = await InspirationService.getCategoryCode(
      this.categoryCode
    ) /* 카테고리 정보 */
    const cateId = categoryRes.data.object.id /* 카테고리 아이디 */
    // this.inspirationRes = await InspirationService.getContentListChild(cateId) /* 콘텐츠 */
    this.inspirationRes = await InspirationService.getContentListSearch(
      [cateId],
      '',
      1,
      6
    )

    this.inspirationList = this.inspirationRes.data.list

    /* //==================================================== end [ inspiration 컨텐츠 ] ======================================================== */

    const _this = this
    this.$nextTick(function () {
      this.featShow = true

      $('.sns-wrp .sns').each(function (idx, ele) {
        var $this = $(this)
        $(ele)
          .prev()
          .click(function () {
            $this.slideToggle(300)
            $this.closest('tr').siblings().children().find('.sns').hide()
          })
      })

      t.imgShow = true

      $('#tab01').addClass('on')

      $('#tab001').addClass('on')

      /* visual slide */
      $('.slide-pd .slick-container').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        // centerPadding: '35px',
        // centerMode: true,
        prevArrow: $('.slide-pd .arrow-prev'),
        nextArrow: $('.slide-pd .arrow-next'),
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      })

      $('.slider-prod').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        draggable: false,
        asNavFor: '.slider-prod-nav'
      })

      t.sliderLength = $('.slider-prod .slider').length

      this.prodImgSlider()
      window.addEventListener('resize', this.prodImgSlider)

      $('.slider-prod').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.yt_player_iframe').each(function () {
          this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        })
      })

      // documentation 영역으로 스크롤
      $('.short-link .btn-doc').click(function () {
        var offset = $('.doc-box').offset().top - $('header').height() - 100
        $('html, body').animate({ scrollTop: offset }, 400)
      })

      /* Related Products slide */
      $('.slider-related').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        centerPadding: '35px',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      })

      /* Inspiration slide */
      $('.slider-ins').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        centerPadding: '30px',
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      })

      for (let i = t.imgPage.length - 1; i > 0; i--) {
        if (t.imgPage[i].image != null && t.imgPage[i].image != undefined && t.imgPage[i].image != '' && t.imgPage[i].image.startsWith('https://www.youtube.com')) {
          t.youtubeCheck = true
        }
      }
    })

    // On before slide change
    $('.slider-prod').on(
      'beforeChange',
      function (event, slick, currentSlide, nextSlide) {
        t.Tslider = nextSlide

        if (t.youtubeCheck == true) {
          if (t.Tslider == t.sliderLength - 1) {
            $('#imgDownload').hide()
          } else {
            $('#imgDownload').show()
          }// t.Tslider 가 최고 끝일 때
        } // t.youtube 가 있을 때
      }
    )
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.prodImgSlider)
  },
  methods: {
    loginalertLike () {
      alert(this.$t('PCD.alert.loginLike'))
    },
    handle_toggle: function () {
      this.is_show = !this.is_show
    },
    handle_toggle_02: function () {
      this.is_show_02 = !this.is_show_02
    },
    handle_toggle_03: function () {
      this.is_show_03 = !this.is_show_03
    },
    handle_toggle_04: function () {
      this.is_show_04 = !this.is_show_04
    },
    handle_toggle_05: function () {
      this.is_show_05 = !this.is_show_05
    },
    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },

    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },

    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode != '_US') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (o.nationCode == t.nationCode) {
              t.getSessionContent.push(o.id)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    // inspiration detail 이동
    goInsDetail: function (clickId) {
      location.href =
        'inspiration/case-studies-detail/' + this.$utils.getSeoName(clickId, 'content') + '/' + clickId.content.id
    },
    goToDocu: function () {
      location.href = 'pros/documentation/himacs'
    },

    downloadTab: function (lang, div) {
      $('#' + div).text(lang)
    },

    downloadDocument: function (file, div) {
      const text = $('#' + div).text()
      let fileName = ''

      if (text == 'English') { // EN
        fileName = file.attribute.doc_file
      }
      if (text == 'German') { // DE
        fileName = file.attribute.doc_file_de
      }
      if (text == 'France') { // FR
        fileName = file.attribute.doc_file_fr
      }
      if (text == 'Italian') { // IT
        fileName = file.attribute.doc_file_it
      }
      if (text == 'Spanish') { // ES
        fileName = file.attribute.doc_file_es
      }
      if (text == 'Dutch') { // NL
        fileName = file.attribute.doc_file_nl
      }
      if (text == 'English (UK)') { // UK
        fileName = file.attribute.doc_file_uk
      }

      if (fileName != null && fileName != '' && fileName != undefined) {
        const url = this.$imagePath + fileName

        try {
          function toDataURL (url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
              var reader = new FileReader()
              reader.onloadend = function () {
                callback(reader.result)
              }
              reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
          }

          toDataURL(url, function (dataUrl) {
            const element = document.createElement('a')
            element.setAttribute('href', dataUrl)
            element.setAttribute('download', file.content.title)
            document.body.appendChild(element)
            element.click()
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        return false
      }
    },

    // 배너 이미지 다운로드
    imgDownload: function (sliderIdx) {
      const imgDownPath = this.imgPage[sliderIdx].image
      const url = this.$imagePath + imgDownPath

      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }

      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', imgDownPath)
        document.body.appendChild(element)
        element.click()
      })
    },

    download: function (data) {
      const url = this.$imagePath + data.attribute.doc_file

      try {
        function toDataURL (url, callback) {
          var xhr = new XMLHttpRequest()
          xhr.onload = function () {
            var reader = new FileReader()
            reader.onloadend = function () {
              callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
          }
          xhr.open('GET', url)
          xhr.responseType = 'blob'
          xhr.send()
        }

        toDataURL(url, function (dataUrl) {
          const element = document.createElement('a')
          element.setAttribute('href', dataUrl)
          element.setAttribute('download', data.content.title)
          document.body.appendChild(element)
          element.click()
        })
      } catch (error) {
        console.log(error)
      }
    },

    enlargeLayer (open) {
      const LayerPopup = {
        open: function (obj) {
          const layerObj = $('.' + obj)

          layerObj.fadeIn(500)
          layerObj.attr('tabindex', 1)
          layerObj.css('z-index', '10000')
          $('div.layer-popup.layer-enlarge').css('visibility', 'unset')
        },
        close: function () {
          $('div.layer-popup.layer-enlarge').removeAttr('tabindex')
          $('div.layer-popup.layer-enlarge').css('visibility', 'hidden')
        }
      }

      if (open == 'open') {
        LayerPopup.open('layer-enlarge')
        this.showDragon = true
      } else {
        LayerPopup.close()
        this.showDragon = false
      }
    },

    imageChange: async function (content) {
      if (this.imgPage) {
        // 이미지 파일 크기 체크
        const getImageSize = await this.getImageSize(content)
        if (window.outerWidth < 600) {
          this.youtubeWidth = window.outerWidth + 'px'
          this.youtubeHeight = $('.slider-prod .slick-list').height() + 'px'
        } else {
          this.youtubeWidth = getImageSize.width + 'px'
          this.youtubeHeight = getImageSize.height + 'px'
        }
      }
    },

    getImageSize: async function (image) {
      // let _URL = window.URL || window.webkitURL;
      const _URL = 'https://img.lxhausys.com'
      // console.log(image, "image")
      return new Promise((resolve, reject) => {
        const img = new Image()
        // console.log(img, "img")
        img.onload = () => resolve({
          width: img.width,
          height: img.height
        })
        img.onerror = reject
        img.src = _URL + image
      })
    },

    specShow (flag) {
      this.flag = !flag
    },

    prodImgSlider: debounce(function () {
      /* visual slide > nav */
      const prodImgL = $('.slider-prod .slider').length
      if (prodImgL > 5) {
        $('.slider-prod-nav').addClass('navWide5')
      }
      if (prodImgL == 5) {
        $('.slider-prod-nav').addClass('navWide5')
      }
      if (prodImgL == 4) {
        $('.slider-prod-nav').addClass('navWide4')
      }
      if (prodImgL == 3) {
        $('.slider-prod-nav').addClass('navWide3')
      }
      if (prodImgL == 2) {
        $('.slider-prod-nav').addClass('navWide2')
      }
      if (prodImgL == 1) {
        $('.slider-prod-nav').addClass('navWide1')
      }

      if (prodImgL < 5) {
        /* visual slide > nav */
        $('.slider-prod-nav').slick({
          slide: 'div',
          infinite: true,
          slidesToShow: prodImgL,
          slidesToScroll: 1,
          variableWidth: true,
          arrows: false,
          draggable: false,
          // centerMode: true,
          asNavFor: '.slider-prod',
          focusOnSelect: true,
          prevArrow: "<button type='button' class='slick-prevtt'></button>",		// 이전 화살표 모양 설정
          nextArrow: "<button type='button' class='slick-nexttt'></button>",		// 다음 화살표 모양 설정
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1020,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
          ]
        })
      }

      /* visual slide > nav */
      $('.slider-prod-nav').not('.slick-initialized').slick({
        slide: 'div',
        infinite: true,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        draggable: false,
        // centerMode: true,
        asNavFor: '.slider-prod',
        focusOnSelect: true,
        prevArrow: "<button type='button' class='slick-prevtt'></button>",		// 이전 화살표 모양 설정
        nextArrow: "<button type='button' class='slick-nexttt'></button>",		// 다음 화살표 모양 설정
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1
            }
          }
        ]
      })

      // 비주얼 썸네일 슬라이드 클릭시 보더 표시
      $('div.slick-slide').on('click', function () {
        $('div.slick-slide').removeClass('slick-current')
        $(this).addClass('slick-current')
      })
    }, '400ms'),
    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      console.log('makeDataLayerForm')
      digitalData.productInfo = {
        ecom_prd_id: data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand: data.parents[1].name,
        ecom_prd_category: "", // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only over 1 items
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      }
    },
    makeDataLayerFormList (data) {
      return JSON.stringify({
        ecom_prd_id: data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand: data.parents[1].name,
        ecom_prd_category: "", // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    }
  }

}

// 모바일에서 탭 화살표 클릭시 이동
// $(function(){
//   $(".fb-box .fb-tab-box .tab-ui .arrow-short").eq(0).click(function(){
//     $(".fb-box .fb-tab-box .tab-ui ul").stop().animate({left: "+=100px"},300);
//   });

//   $(".fb-box .fb-tab-box .tab-ui .arrow-short").eq(1).click(function(){
//     $(".fb-box .fb-tab-box .tab-ui ul").stop().animate({left: "-=100px"},300);
//   });
// });
</script>
