var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "short-link type02" }, [
      _c("a", { attrs: { href: "support/contactus" } }, [
        _c("span", { staticClass: "ico-07" }, [_vm._v("Contact Us")]),
      ]),
      _c("a", { attrs: { href: "pros/documentation/auto" } }, [
        _c("span", { staticClass: "ico-02" }, [_vm._v("Documentation")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }