var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("PETFURNITUREFILM", {
        attrs: { petList: _vm.dictMap["pet"] },
        model: {
          value: _vm.pet,
          callback: function ($$v) {
            _vm.pet = $$v
          },
          expression: "pet",
        },
      }),
      _c("PVCDECOFOIL", {
        attrs: { pvcList: _vm.dictMap["pvc"] },
        model: {
          value: _vm.pvc,
          callback: function ($$v) {
            _vm.pvc = $$v
          },
          expression: "pvc",
        },
      }),
      _c("ACRYLICFURNITUREFILM", {
        attrs: { acrylicList: _vm.dictMap["acrylic"] },
        model: {
          value: _vm.acrylic,
          callback: function ($$v) {
            _vm.acrylic = $$v
          },
          expression: "acrylic",
        },
      }),
      _c("IS_NEW", {
        model: {
          value: _vm.isNew,
          callback: function ($$v) {
            _vm.isNew = $$v
          },
          expression: "isNew",
        },
      }),
      _c("SOLIDCOLOR", {
        attrs: { colorsList: _vm.dictMap["hue_sld"] },
        model: {
          value: _vm.hue_sld,
          callback: function ($$v) {
            _vm.hue_sld = $$v
          },
          expression: "hue_sld",
        },
      }),
      _c("METALCOLOR", {
        attrs: { mtlcolorList: _vm.dictMap["hue_m"] },
        model: {
          value: _vm.hue_m,
          callback: function ($$v) {
            _vm.hue_m = $$v
          },
          expression: "hue_m",
        },
      }),
      _c("WOODCOLOR", {
        attrs: { huewList: _vm.dictMap["hue_w"] },
        model: {
          value: _vm.hue_w,
          callback: function ($$v) {
            _vm.hue_w = $$v
          },
          expression: "hue_w",
        },
      }),
      _c("STONECOLOR", {
        attrs: { stncolorList: _vm.dictMap["hue_stn"] },
        model: {
          value: _vm.hue_stn,
          callback: function ($$v) {
            _vm.hue_stn = $$v
          },
          expression: "hue_stn",
        },
      }),
      _c("SOLIDPATTERN", {
        attrs: { sldpatternList: _vm.dictMap["pattern_sld"] },
        model: {
          value: _vm.pattern_sld,
          callback: function ($$v) {
            _vm.pattern_sld = $$v
          },
          expression: "pattern_sld",
        },
      }),
      _c("METALPATTERN", {
        attrs: { metalpatternList: _vm.dictMap["pattern_m"] },
        model: {
          value: _vm.pattern_m,
          callback: function ($$v) {
            _vm.pattern_m = $$v
          },
          expression: "pattern_m",
        },
      }),
      _c("WOODPATTERN", {
        attrs: { woodpatternList: _vm.dictMap["pattern_w"] },
        model: {
          value: _vm.pattern_w,
          callback: function ($$v) {
            _vm.pattern_w = $$v
          },
          expression: "pattern_w",
        },
      }),
      _c("STONEPATTERN", {
        attrs: { stnpatternList: _vm.dictMap["pattern_stn"] },
        model: {
          value: _vm.pattern_stn,
          callback: function ($$v) {
            _vm.pattern_stn = $$v
          },
          expression: "pattern_stn",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }