var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "container bbs-box inspr-view case-studies" },
          [
            _c("div", { staticClass: "sub_title_area full_width" }, [
              _c("div", { staticClass: "inner_width" }, [
                _c("div", { staticClass: "location" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        staticClass: "ico-home",
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/index`
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("caseStudies.text.home")) + " "
                        ),
                      ]
                    ),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$t("caseStudies.text.inspiration"))),
                    ]),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$t("caseStudies.text.caseStudies"))),
                    ]),
                  ]),
                ]),
                _c("h1", { staticClass: "page_title tt-1" }, [
                  _vm._v(_vm._s(_vm.$t("caseStudies.text.caseStudies")) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "w-con" }, [
              _c(
                "div",
                { staticClass: "gallery" },
                [
                  _c(
                    "div",
                    { staticClass: "casestudy_sliderwrap" },
                    [
                      _vm.topImageSlideList.length
                        ? _c(
                            "VueSlickCarousel",
                            _vm._b(
                              {},
                              "VueSlickCarousel",
                              _vm.slickSettings,
                              false
                            ),
                            _vm._l(_vm.topImageSlideList, function (item) {
                              return _c(
                                "div",
                                {
                                  staticClass: "item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveToDetail(item.content)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "imgwrap" }, [
                                    _c("img", {
                                      attrs: {
                                        src: `${_vm.$imagePath}${item.attribute.mainThumbnail}`,
                                        alt: item.content.title,
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "txtwrap" }, [
                                    _c("ul", [
                                      _c(
                                        "li",
                                        { staticClass: "type" },
                                        [
                                          _vm._l(
                                            _vm.getSeletedSegmentsName(
                                              item.attribute
                                            ),
                                            function (segmentsItem) {
                                              return [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      segmentsItem.text
                                                    ),
                                                  },
                                                }),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c("li", { staticClass: "tit" }, [
                                        _c("span", {
                                          staticClass: "tit",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.content.title
                                            ),
                                          },
                                        }),
                                      ]),
                                      _c("li", { staticClass: "location" }, [
                                        _c("span", {
                                          staticClass: "where",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.attribute.location
                                            ),
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                  item.attribute.productList
                                    ? _c("div", { staticClass: "tagwrap" }, [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.attribute.productList.value
                                            ),
                                          },
                                        }),
                                        _c("span", { staticClass: "line" }),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      { ref: "filterArea", staticClass: "filter_area" },
                      [
                        _c("div", { staticClass: "filter_wrap" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn_filter",
                              class: { on: _vm.isFilterOpen },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.isFilterOpen = !_vm.isFilterOpen
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon icon_filter" }),
                              _c("span", { staticClass: "txt p-ui" }, [
                                _vm._v("Filter"),
                              ]),
                              _c("span", { staticClass: "txt m-ui" }, [
                                _vm._v("Filter by"),
                              ]),
                              _c("span", {
                                staticClass: "icon icon_arrow_down",
                              }),
                            ]
                          ),
                        ]),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.isFilterOpen
                            ? _c("div", { staticClass: "filter_pop on" }, [
                                _c("div", { staticClass: "filter_tit" }, [
                                  _c("p", [_vm._v("FILTER BY")]),
                                  _c("button", {
                                    staticClass: "btn_close",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isFilterOpen = !_vm.isFilterOpen
                                      },
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "filter_top" }, [
                                  _c("ul", [
                                    _c("li", [
                                      _c(
                                        "div",
                                        { staticClass: "filter_checkbox_tit" },
                                        [_c("span", [_vm._v("PRODUCT")])]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "filter_checkbox_cont" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "checkbox_box" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "checkbox_all" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.isCheckAllProduct,
                                                        expression:
                                                          "isCheckAllProduct",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "product_checkall",
                                                      name: "product",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.isCheckAllProduct
                                                      )
                                                        ? _vm._i(
                                                            _vm.isCheckAllProduct,
                                                            null
                                                          ) > -1
                                                        : _vm.isCheckAllProduct,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              _vm.isCheckAllProduct,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.isCheckAllProduct =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.isCheckAllProduct =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.isCheckAllProduct =
                                                              $$c
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.handleCheckBoxAll(
                                                            $event,
                                                            "productList",
                                                            "productCheckList",
                                                            0
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "product_checkall",
                                                      },
                                                    },
                                                    [_vm._v("Check All")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "checkbox_more",
                                                },
                                                _vm._l(
                                                  _vm.productList,
                                                  function (item, index) {
                                                    return _c("span", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.productCheckList,
                                                            expression:
                                                              "productCheckList",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: `${item.id}${index}`,
                                                        },
                                                        domProps: {
                                                          value: item.value,
                                                          checked:
                                                            Array.isArray(
                                                              _vm.productCheckList
                                                            )
                                                              ? _vm._i(
                                                                  _vm.productCheckList,
                                                                  item.value
                                                                ) > -1
                                                              : _vm.productCheckList,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  _vm.productCheckList,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    item.value,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    (_vm.productCheckList =
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      ))
                                                                } else {
                                                                  $$i > -1 &&
                                                                    (_vm.productCheckList =
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        ))
                                                                }
                                                              } else {
                                                                _vm.productCheckList =
                                                                  $$c
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.handleCheckBox(
                                                                $event,
                                                                "productList",
                                                                "productCheckList",
                                                                "isCheckAllProduct",
                                                                0
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c("label", {
                                                        attrs: {
                                                          for: `${item.id}${index}`,
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.id
                                                          ),
                                                        },
                                                      }),
                                                    ])
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "div",
                                        { staticClass: "filter_checkbox_tit" },
                                        [_c("span", [_vm._v("SEGMENTS")])]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "filter_checkbox_cont" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "checkbox_box" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "checkbox_all" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "checkbox_subtit",
                                                    },
                                                    [_vm._v("Residential")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.isCheckAllResidential,
                                                        expression:
                                                          "isCheckAllResidential",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "residential_checkall",
                                                      name: "residential",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.isCheckAllResidential
                                                      )
                                                        ? _vm._i(
                                                            _vm.isCheckAllResidential,
                                                            null
                                                          ) > -1
                                                        : _vm.isCheckAllResidential,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              _vm.isCheckAllResidential,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.isCheckAllResidential =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.isCheckAllResidential =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.isCheckAllResidential =
                                                              $$c
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.handleCheckBoxAll(
                                                            $event,
                                                            "residentialList",
                                                            "residentialCheckList",
                                                            2
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "residential_checkall",
                                                      },
                                                    },
                                                    [_vm._v("Check All")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "checkbox_more",
                                                },
                                                _vm._l(
                                                  _vm.residentialList,
                                                  function (item, index) {
                                                    return _c("span", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.residentialCheckList,
                                                            expression:
                                                              "residentialCheckList",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: `${item.id}${index}`,
                                                        },
                                                        domProps: {
                                                          value: item.value,
                                                          checked:
                                                            Array.isArray(
                                                              _vm.residentialCheckList
                                                            )
                                                              ? _vm._i(
                                                                  _vm.residentialCheckList,
                                                                  item.value
                                                                ) > -1
                                                              : _vm.residentialCheckList,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  _vm.residentialCheckList,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    item.value,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    (_vm.residentialCheckList =
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      ))
                                                                } else {
                                                                  $$i > -1 &&
                                                                    (_vm.residentialCheckList =
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        ))
                                                                }
                                                              } else {
                                                                _vm.residentialCheckList =
                                                                  $$c
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.handleCheckBox(
                                                                $event,
                                                                "residentialList",
                                                                "residentialCheckList",
                                                                "isCheckAllResidential",
                                                                2
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c("label", {
                                                        attrs: {
                                                          for: `${item.id}${index}`,
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.id
                                                          ),
                                                        },
                                                      }),
                                                    ])
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "checkbox_box" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "checkbox_all" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "checkbox_subtit",
                                                    },
                                                    [_vm._v("Commercial")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.isCheckAllCommercial,
                                                        expression:
                                                          "isCheckAllCommercial",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "commercial_checkall",
                                                      name: "commercial",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.isCheckAllCommercial
                                                      )
                                                        ? _vm._i(
                                                            _vm.isCheckAllCommercial,
                                                            null
                                                          ) > -1
                                                        : _vm.isCheckAllCommercial,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              _vm.isCheckAllCommercial,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.isCheckAllCommercial =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.isCheckAllCommercial =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.isCheckAllCommercial =
                                                              $$c
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.handleCheckBoxAll(
                                                            $event,
                                                            "commercialList",
                                                            "commercialCheckList",
                                                            1
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "commercial_checkall",
                                                      },
                                                    },
                                                    [_vm._v("Check All")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "checkbox_more",
                                                },
                                                _vm._l(
                                                  _vm.commercialList,
                                                  function (item, index) {
                                                    return _c("span", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.commercialCheckList,
                                                            expression:
                                                              "commercialCheckList",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: `${item.id}${index}`,
                                                        },
                                                        domProps: {
                                                          value: item.value,
                                                          checked:
                                                            Array.isArray(
                                                              _vm.commercialCheckList
                                                            )
                                                              ? _vm._i(
                                                                  _vm.commercialCheckList,
                                                                  item.value
                                                                ) > -1
                                                              : _vm.commercialCheckList,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  _vm.commercialCheckList,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    item.value,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    (_vm.commercialCheckList =
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      ))
                                                                } else {
                                                                  $$i > -1 &&
                                                                    (_vm.commercialCheckList =
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        ))
                                                                }
                                                              } else {
                                                                _vm.commercialCheckList =
                                                                  $$c
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.handleCheckBox(
                                                                $event,
                                                                "commercialList",
                                                                "commercialCheckList",
                                                                "isCheckAllCommercial",
                                                                1
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c("label", {
                                                        attrs: {
                                                          for: `${item.id}${index}`,
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.id
                                                          ),
                                                        },
                                                      }),
                                                    ])
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "filter_bottom" }, [
                                  _c("div", { staticClass: "btn_box" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn_wh",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCloseBadge("all")
                                          },
                                        },
                                      },
                                      [_vm._v("Clear All ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn_bk",
                                        attrs: { type: "button" },
                                        on: { click: _vm.handleClickApply },
                                      },
                                      [_vm._v("Apply")]
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "search" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchInfo.searchText,
                                expression: "searchInfo.searchText",
                              },
                            ],
                            ref: "search",
                            attrs: {
                              type: "text",
                              id: "textSearch",
                              placeholder: "Enter Item Number or Name",
                              value: "",
                            },
                            domProps: { value: _vm.searchInfo.searchText },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.searchInfo,
                                    "searchText",
                                    $event.target.value
                                  )
                                },
                                _vm.handleSearch,
                              ],
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleSearch.apply(null, arguments)
                              },
                            },
                          }),
                          _vm.searchInfo.searchText.length !== 0
                            ? _c("button", {
                                staticClass: "btn_cancel",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSearchDelete()
                                  },
                                },
                              })
                            : _c("button", {
                                staticClass: "btn_search",
                                attrs: { type: "button" },
                                on: { click: _vm.handleSearch },
                              }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "sort_area no_bottom_line" },
                      [
                        _vm.badgeList.length > 0
                          ? [
                              _c("div", { staticClass: "select-view" }, [
                                _c(
                                  "ul",
                                  { staticClass: "list-filter-key" },
                                  [
                                    _vm._l(_vm.badgeList, function (item) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass: "sel",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCloseBadge(
                                                item.value
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v(_vm._s(item.id))]),
                                          _c("button", {
                                            attrs: { type: "button" },
                                          }),
                                        ]
                                      )
                                    }),
                                    _vm.badgeList.length > 0
                                      ? _c(
                                          "li",
                                          {
                                            staticClass: "sel del_all",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCloseBadge(
                                                  "all"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._m(0)]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "count_area" }, [
                      _c("div", { staticClass: "filter-cnt" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.pageInfo.totalCount)),
                        ]),
                        _c("span", [_vm._v(" ")]),
                        _c("span", { staticClass: "txt" }, [
                          _vm._v(_vm._s(_vm.$t("gallery.text.results"))),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.isLoading
                    ? [_vm._m(1)]
                    : [
                        _vm.pageList.length === 0 ? [_vm._m(2)] : _vm._e(),
                        [
                          _c("div", { staticClass: "grid-box" }, [
                            _c(
                              "ul",
                              { staticClass: "ins_grid" },
                              [
                                _vm._l(_vm.pageList, function (item, index) {
                                  return [
                                    _c("li", { staticClass: "cs-grid-cont" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "g-box",
                                          attrs: {
                                            type: "button",
                                            "data-case":
                                              _vm.makeDataLayerForm(item),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.moveToDetail(
                                                item.content
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "img-box" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: `${_vm.$imagePath}${item.attribute.mainThumbnail}`,
                                                  alt: "thumb",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "txt-box" },
                                            [
                                              item.attribute
                                                ? _c(
                                                    "p",
                                                    { staticClass: "txt-cate" },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.attribute
                                                              .location
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("p", {
                                                staticClass: "content_title",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.attribute.lead
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          item.attribute.productList
                                            ? _c(
                                                "div",
                                                { staticClass: "tagwrap" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .productList.value
                                                      )
                                                    ),
                                                  ]),
                                                  _c("span", {
                                                    staticClass: "line",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("div", { staticClass: "cont_name" }, [
                                        _c(
                                          "div",
                                          { staticClass: "tag" },
                                          [
                                            item.attribute
                                              ? [
                                                  _vm._l(
                                                    _vm.getSeletedSegmentsName(
                                                      item.attribute
                                                    ),
                                                    function (segmentsItem) {
                                                      return [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              segmentsItem.text
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    }
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _c("div", { staticClass: "name" }, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                item.content.title
                                              ),
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]),
                          _vm.pageList.length !== 0 && _vm.isLoadMore
                            ? _c(
                                "div",
                                { staticClass: "btn_area" },
                                [
                                  _vm.isLoadMoreLoading
                                    ? [_vm._m(3)]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn_load_more",
                                            attrs: { type: "button" },
                                            on: {
                                              click: _vm.handleClickLoadMore,
                                            },
                                          },
                                          [
                                            _c("span", { staticClass: "txt" }, [
                                              _vm._v("Load More"),
                                            ]),
                                            _c("span", {
                                              staticClass: "icon icon_plus",
                                            }),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                      ],
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn-all-delete", attrs: { type: "button" } },
      [_c("span", [_vm._v("Clear All")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "grid-box" }, [
      _c("div", { staticClass: "no_result" }, [
        _c("div", { staticClass: "loading_area" }, [
          _c("img", {
            attrs: {
              src: "https://img.lxhausys.com/public/images/icon/loading.gif",
              alt: "loading",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "grid-box" }, [
      _c("div", { staticClass: "no_result" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/public/images/support/no_result.png",
            alt: "noresult",
          },
        }),
        _c("p", [_vm._v("No Result found")]),
        _c("span", [
          _vm._v("If you do not find what you are looking for, please"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_area" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/public/images/icon/loading.gif",
          alt: "loading",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }