var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "social-responsibility-table-body" }, [
    _c(
      "div",
      { staticClass: "body-category" },
      [
        _c("SocialResponsibilityTableBodyCategory", {
          attrs: { text: _vm.categoryText },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "body-content" },
      _vm._l(_vm.bodyCells, function (item, index) {
        return _c("div", { key: index, staticClass: "content-line" }, [
          _c(
            "div",
            { staticClass: "text-area" },
            [
              _c("SocialResponsibilityTableBodyCell", {
                attrs: { text: item.text },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "desc-text-area" },
            [
              _c("SocialResponsibilityTableBodyCell", {
                attrs: { text: item.descText },
              }),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }