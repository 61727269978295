<template>
  <div class="reduction-plan-card-area">
    <div>
      <OverviewText
        type="ft-20 fw-700"
        :text="title"
      />
    </div>
    <div class="desc-area">
      <OverviewText
        v-for="(it, idx) in desc"
        :key="idx"
        type="ft-16"
        textAlign="left"
        :enableListStyle="true"
        :text="it"
      />
    </div>
  </div>
</template>

<script>
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewText
  },
  props: {
    title: {
      type: String
    },
    desc: {
      type: Array
    }
  }
}
</script>

<style scoped>
.reduction-plan-card-area {
  display: flex;
  padding: 45px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 20px;
  border-top: 1px solid #2C2C2D;
  background: #F9F9F9;
  min-height: 244px;
  max-width: 650px;
}

.reduction-plan-card-area .desc-area {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: left;
}
</style>
