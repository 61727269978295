<template>
    <tbody class="financial-statement-table-column-area">
        <tr v-for="item in dataList" :key="item">
            <td :style="{'background': item.valueBackground}">
                <div
                class="financial-statement-table-column-item"
                :style="{'font-weight': item.valueFontWeight}"
                >
                    {{ item.item }}
                </div>
            </td>
            <td :style="{'background': item.valueBackground}">
                <div
                class="financial-statement-table-column-value"
                :style="{'font-weight': item.valueFontWeight}"
                >
                    {{ item.value1 }}
                </div>
            </td>
            <td :style="{'background': item.valueBackground}">
                <div
                class="financial-statement-table-column-value"
                :style="{'font-weight': item.valueFontWeight}"
                >
                    {{ item.value2 }}
                </div>
            </td>
            <td :style="{'background': item.valueBackground}">
                <div
                class="financial-statement-table-column-value"
                :style="{'font-weight': item.valueFontWeight}"
                >
                    {{ item.value3 }}
                </div>
            </td>
            <td :style="{'background': item.valueBackground}">
                <div
                class="financial-statement-table-column-value"
                :style="{'font-weight': item.valueFontWeight}"
                >
                    {{ item.value4 }}
                </div>
            </td>
            <td :style="{'background': item.valueBackground}">
                <div
                class="financial-statement-table-column-value"
                :style="{'font-weight': item.valueFontWeight}"
                >
                    {{ item.value5 }}
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>

export default {
  props: {
    dataList: [
      {
        item: {
          type: String,
          default: ''
        },
        value1: {
          type: String,
          default: ''
        },
        value2: {
          type: String,
          default: ''
        },
        value3: {
          type: String,
          default: ''
        },
        value4: {
          type: String,
          default: ''
        },
        value5: {
          type: String,
          default: ''
        },
        valueFontWeight: {
          type: String,
          require: false,
          default: '400'
        },
        valueBackground: {
          type: String,
          require: false,
          default: '#FFF'
        }
      }
    ]
  }
}

</script>

<style scoped>
.financial-statement-table-column-item, .financial-statement-table-column-value {
    margin: 18px 20px 18px 20px;
}

.financial-statement-table-column-value {
    text-align: right;
}

tr {
    border-bottom: 1px solid #EAEAEA;
}
</style>
