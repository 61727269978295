var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "div",
        { attrs: { id: "wrap" } },
        [
          _c("HeaderComponent"),
          _c("GnbComponent"),
          _c("h1", { staticClass: "sr-only" }, [_vm._v("Story of LX Hausys")]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "story-lx" },
              [
                _c("div", { staticClass: "location type02" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        staticClass: "ico-home",
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/index`
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("globalBusiness.text.home")))]
                    ),
                    _c("li", [_vm._v("About Us")]),
                    _c("li", [_vm._v("Why LX Hausys")]),
                    _c("li", [_vm._v("History")]),
                  ]),
                ]),
                _c("ProductTopImage", {
                  attrs: {
                    imageSrc:
                      "https://img.lxhausys.com/public/images/aboutus/visual_story.png",
                    title: "Story of LX Hausys",
                  },
                }),
                _vm._m(0),
              ],
              1
            ),
          ]),
          _c("QuickMenuComponent"),
          _c("FooterComponent"),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "div",
        { attrs: { id: "wrap" } },
        [
          _c("HeaderComponent"),
          _c("GnbComponent"),
          _c("h1", { staticClass: "sr-only" }, [
            _vm._v("LXハウシスのストーリー"),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "story-lx" },
              [
                _c("div", { staticClass: "location type02" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        staticClass: "ico-home",
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/index`
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("globalBusiness.text.home")))]
                    ),
                    _c("li", [_vm._v("About Us")]),
                    _c("li", [_vm._v("Why LX Hausys")]),
                    _c("li", [_vm._v("History")]),
                  ]),
                ]),
                _c("ProductTopImage", {
                  attrs: {
                    imageSrc:
                      "https://img.lxhausys.com/public/images/aboutus/visual_story.png",
                    title: "LXハウシスのストーリー",
                  },
                }),
                _vm._m(1),
              ],
              1
            ),
          ]),
          _c("QuickMenuComponent"),
          _c("FooterComponent"),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "div",
        { attrs: { id: "wrap" } },
        [
          _c("HeaderComponent"),
          _c("GnbComponent"),
          _c("h1", { staticClass: "sr-only" }, [
            _vm._v("История компании LX Hausys"),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "story-lx" },
              [
                _c("div", { staticClass: "location type02" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        staticClass: "ico-home",
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/index`
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("globalBusiness.text.home")))]
                    ),
                    _c("li", [_vm._v("About Us")]),
                    _c("li", [_vm._v("Why LX Hausys")]),
                    _c("li", [_vm._v("History")]),
                  ]),
                ]),
                _c("ProductTopImage", {
                  attrs: {
                    imageSrc:
                      "https://img.lxhausys.com/public/images/aboutus/visual_story.png",
                    title: "История компании LX Hausys",
                  },
                }),
                _vm._m(2),
              ],
              1
            ),
          ]),
          _c("QuickMenuComponent"),
          _c("FooterComponent"),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "div",
        { attrs: { id: "wrap" } },
        [
          _c("HeaderComponent"),
          _c("GnbComponent"),
          _c("h1", { staticClass: "sr-only" }, [_vm._v("沿革")]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "story-lx" },
              [
                _c("div", { staticClass: "location type02" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          { name: "hover-cursor", rawName: "v-hover-cursor" },
                        ],
                        staticClass: "ico-home",
                        on: {
                          click: function ($event) {
                            _vm.gotolink(
                              `/${_vm.countryCodeSub.toLowerCase()}/index`
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("globalBusiness.text.home")))]
                    ),
                    _c("li", [_vm._v("About Us")]),
                    _c("li", [_vm._v("Why LX Hausys")]),
                    _c("li", [_vm._v("History")]),
                  ]),
                ]),
                _c("ProductTopImage", {
                  attrs: {
                    imageSrc:
                      "https://img.lxhausys.com/public/images/aboutus/visual_story.png",
                    title: "沿革",
                  },
                }),
                _vm._m(3),
              ],
              1
            ),
          ]),
          _c("QuickMenuComponent"),
          _c("FooterComponent"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap-history" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h2", { staticClass: "section-title" }, [
          _vm._v("History of Living Space in Korea"),
        ]),
        _c("p", { staticClass: "ctt ctt-t2 tac" }, [
          _vm._v(
            "Our journey taken together with customers represents Korea’s history of living space evolution."
          ),
          _c("br"),
          _vm._v(
            "Since 1947, LX Hausys has been supplying various interior materials that served to enhance the value of living spaces in Korea for the past 70 years."
          ),
          _c("br"),
          _vm._v(
            "Now, LX Hausys has become the largest building materials company in Korea."
          ),
          _c("br"),
          _vm._v(
            "We will continue to establish ourselves as a company that delivers trust and satisfaction to our customers based on"
          ),
          _c("br"),
          _vm._v(
            "our market-leading products and advanced technology to supply healthy, eco-friendly products & materials,"
          ),
          _c("br"),
          _vm._v(
            "as well as systems and solutions that maximize energy efficiency."
          ),
          _c("br"),
        ]),
      ]),
      _c("div", { staticClass: "history-table" }, [
        _c("dl", { staticClass: "hstr-l hstr-pb-90" }, [
          _c("dt", { staticStyle: { color: "#B40F2C" } }, [_vm._v("2023")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v("Implemented a plan to achieve the “Net"),
              _c("br"),
              _vm._v("Zero” goal by 2050"),
            ]),
            _c("img", {
              staticClass: "card-img",
              staticStyle: {
                width: "99.247px",
                height: "97.342px",
                "flex-shrink": "0",
              },
              attrs: { src: "images/aboutus/s_2023.png" },
            }),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pt-180 hstr-pb-104" }, [
          _c("dt", [_vm._v("2022")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              staticStyle: { "flex-shrink": "0" },
              attrs: { src: "images/aboutus/s_2022.png" },
            }),
            _c("p", [_vm._v("Established the ESG Committee")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pt-150 hstr-pb-104" }, [
          _c("dt", [_vm._v("2021")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2021.png",
              },
            }),
            _c("p", [_vm._v("Changed the company name to LX Hausys")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-150" }, [
          _c("dt", [_vm._v("2020")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2020.png",
              },
            }),
            _c("p", [
              _vm._v("Completed expansion of the 3rd production line"),
              _c("br"),
              _vm._v("for engineered stone in the U.S"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-104" }, [
          _c("dt", [_vm._v("2018")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2018.png",
              },
            }),
            _c("p", [_vm._v("Completed the 2nd PF insulation board plant")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-300" }, [
          _c("dt", [_vm._v("2017")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v("Acquired majority stake in c21, a Slovak"),
              _c("br"),
              _vm._v("automotive materials producer"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2016")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2016.png",
              },
            }),
            _c("p", [
              _vm._v("Completed an automotive interior leather plant"),
              _c("br"),
              _vm._v("in the U.S (Atlanta)"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-90" }, [
          _c("dt", [_vm._v("2015")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2015.png",
              },
            }),
            _c("p", [_vm._v("Completed a plant in Wuxi, China")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2013")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2013.png",
              },
            }),
            _c("p", [_vm._v("Produced PF boards")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2012")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2012.png",
              },
            }),
            _c("p", [
              _vm._v("Completed the Korea's largest Low-E glass plant"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2011")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2011.png",
              },
            }),
            _c("p", [
              _vm._v("Completed an engineered stone plant in"),
              _c("br"),
              _vm._v("the U.S"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2010")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2010.png" },
            }),
            _c("p", [
              _vm._v("Acquired Carbon Footprint Certificate"),
              _c("br"),
              _vm._v("for the first time in the building materials field"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("2009")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2009.png",
              },
            }),
            _c("p", [
              _vm._v("Launched LG Hausys (Spinoff of"),
              _c("br"),
              _vm._v("industrial materials business of LG Chem)"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2007")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2007.png" },
            }),
            _c("p", [
              _vm._v("Published its first Sustainability report"),
              _c("br"),
              _vm._v("(by LG chemistry)"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2006")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2006.png",
              },
            }),
            _c("p", [
              _vm._v("Launched Z:IN as a premium interior"),
              _c("br"),
              _vm._v("design brand"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2005")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2005.png",
              },
            }),
            _c("p", [_vm._v("Completed HIMACS plant in the U.S")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("1995")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1995.png",
              },
            }),
            _c("p", [
              _vm._v("Produced the acrylic solid surface"),
              _c("br"),
              _vm._v("HIMACS"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("1984")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1984.png",
              },
            }),
            _c("p", [_vm._v("Produced plastic automotive parts")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("1976")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1976.png",
              },
            }),
            _c("p", [
              _vm._v("EProduced the first PVC window"),
              _c("br"),
              _vm._v("(HI-Sash) in Korea"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-180" }, [
          _c("dt", [_vm._v("1969")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_1969.png" },
            }),
            _c("p", [
              _vm._v("Produced the first commercial PVC tiles in Korea"),
            ]),
          ]),
        ]),
        _c(
          "dl",
          { staticClass: "hstr-l", staticStyle: { "padding-bottom": "30px" } },
          [
            _c("dt", [_vm._v("1957")]),
            _c("dd", [
              _c("img", {
                staticClass: "card-img",
                attrs: {
                  src: "https://img.lxhausys.com/public/images/aboutus/s_1957.png",
                },
              }),
              _c("p", [
                _vm._v("EProduced the first PVC window"),
                _c("br"),
                _vm._v("(HI-Sash) in Korea"),
              ]),
            ]),
          ]
        ),
        _c(
          "dl",
          { staticClass: "hstr-r", staticStyle: { "padding-bottom": "25px" } },
          [
            _c("dt", [_vm._v("1952")]),
            _c("dd", [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-bottom": "120px",
                  },
                },
                [
                  _vm._v("Established Lak Hui Chemical Industrial Corp."),
                  _c("br"),
                  _vm._v("(Currently LG Chem)"),
                ]
              ),
            ]),
            _c("dt", { staticClass: "hstr-m-area-circle" }),
          ]
        ),
      ]),
      _c("div", { staticClass: "hstr-m-area" }, [
        _c("div", { staticClass: "hstr-m" }, [
          _c("div", { staticClass: "year-area" }, [
            _c("pre", { staticClass: "year" }, [_vm._v("1947")]),
          ]),
          _c("img", {
            staticClass: "m-card-img",
            attrs: {
              src: "https://img.lxhausys.com/public/images/aboutus/s_1947.png",
            },
          }),
          _c("p", [
            _vm._v("Established Lak Hui Chemical Industrial"),
            _c("br"),
            _vm._v("Corp. (Currently LG Chem)"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap-history" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h2", { staticClass: "section-title" }, [
          _vm._v("韓国の居住空間の歴史"),
        ]),
        _c("p", { staticClass: "ctt ctt-t2 tac" }, [
          _vm._v(
            "お客様とともに歩んできた私たちは、韓国の生活空間の進化の歴史と共に成長しています。"
          ),
          _c("br"),
          _vm._v(
            "1947年以来、LX Hausysは過去70年間、韓国の生活空間の価値を高めるさまざまなインテリア資材を供給してきました。"
          ),
          _c("br"),
          _vm._v(
            "現在、LX Hausysは韓国最大の建築資材会社に成長し、市場をリードする製品と先進技術をベースに、"
          ),
          _c("br"),
          _vm._v(
            "健康的で環境に優しい製品と資材、エネルギー効率を最大化するシステムとソリューションを提供し、"
          ),
          _c("br"),
          _vm._v(
            "お客様に信頼と満足をお届けする企業として確固たる地位を築いていきます。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "history-table" }, [
        _c("dl", { staticClass: "hstr-l hstr-pb-90" }, [
          _c("dt", { staticStyle: { color: "#B40F2C" } }, [_vm._v("2023")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v("2050年までに「ネットゼロ(NetZero)」"),
              _c("br"),
              _vm._v("目標を達成するための計画を実施"),
            ]),
            _c("img", {
              staticClass: "card-img",
              staticStyle: {
                width: "99.247px",
                height: "97.342px",
                "flex-shrink": "0",
              },
              attrs: { src: "images/aboutus/s_2023.png" },
            }),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pt-180 hstr-pb-104" }, [
          _c("dt", [_vm._v("2022")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              staticStyle: { "flex-shrink": "0" },
              attrs: { src: "images/aboutus/s_2022.png" },
            }),
            _c("p", [_vm._v("ESG委員会の設置")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pt-150 hstr-pb-104" }, [
          _c("dt", [_vm._v("2021")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2021.png",
              },
            }),
            _c("p", [_vm._v("社名をLX Hausysに変更")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-150" }, [
          _c("dt", [_vm._v("2020")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2020.png",
              },
            }),
            _c("p", [
              _vm._v("米国で第3クォーツストーン"),
              _c("br"),
              _vm._v("生産ラインの拡張を完了"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-104" }, [
          _c("dt", [_vm._v("2018")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2018.png",
              },
            }),
            _c("p", [_vm._v("第2PF断熱材工場完成")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-300" }, [
          _c("dt", [_vm._v("2017")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v("スロバキアの自動車材料"),
              _c("br"),
              _vm._v("メーカーc21の過半数株式を取得"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2016")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2016.png",
              },
            }),
            _c("p", [
              _vm._v("米国(アトランタ)"),
              _c("br"),
              _vm._v("に自動車内装皮革工場完成"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-90" }, [
          _c("dt", [_vm._v("2015")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2015.png",
              },
            }),
            _c("p", [_vm._v("中国無錫に工場完成")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2013")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2013.png",
              },
            }),
            _c("p", [_vm._v("建築用PF断熱材の製造")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2012")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2012.png",
              },
            }),
            _c("p", [_vm._v("韓国最大のLow-Eガラス工場完成")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2011")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2011.png",
              },
            }),
            _c("p", [_vm._v("米国に3クォーツストーン工場を完成")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2010")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2010.png" },
            }),
            _c("p", [
              _vm._v("建材分野で初"),
              _c("br"),
              _vm._v("めてカーボンフットプリント認証を取得"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("2009")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2009.png",
              },
            }),
            _c("p", [
              _vm._v("LG Hausys発足"),
              _c("br"),
              _vm._v("(LG化学の産業資材事業を分社化)"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2007")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2007.png" },
            }),
            _c("p", [
              _vm._v("初のサステナビリティレポート"),
              _c("br"),
              _vm._v("を発行(LG化学)"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2006")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2006.png",
              },
            }),
            _c("p", [
              _vm._v("プレミアムインテリアデザインブランド"),
              _c("br"),
              _vm._v("「Z:IN」を立ち上げ"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2005")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2005.png",
              },
            }),
            _c("p", [_vm._v("米国にHIMACS(人工大理石)工場完成")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("1995")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1995.png",
              },
            }),
            _c("p", [
              _vm._v("アクリル固体表面HIMACS"),
              _c("br"),
              _vm._v("(人工大理石)を製造"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("1984")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1984.png",
              },
            }),
            _c("p", [_vm._v("プラスチック自動車部品の製造")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("1976")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1976.png",
              },
            }),
            _c("p", [_vm._v("韓国初のPVC窓(HI-Sash)を生産")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-180" }, [
          _c("dt", [_vm._v("1969")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_1969.png" },
            }),
            _c("p", [_vm._v("韓国初の商業用PVCタイルを生産")]),
          ]),
        ]),
        _c(
          "dl",
          { staticClass: "hstr-l", staticStyle: { "padding-bottom": "30px" } },
          [
            _c("dt", [_vm._v("1957")]),
            _c("dd", [
              _c("img", {
                staticClass: "card-img",
                attrs: {
                  src: "https://img.lxhausys.com/public/images/aboutus/s_1957.png",
                },
              }),
              _c("p", [_vm._v("韓国初のPVC窓(HI-Sash)を生産")]),
            ]),
          ]
        ),
        _c(
          "dl",
          { staticClass: "hstr-r", staticStyle: { "padding-bottom": "25px" } },
          [
            _c("dt", [_vm._v("1952")]),
            _c("dd", [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-bottom": "120px",
                  },
                },
                [_vm._v("楽輝化学工業株式会社(現LG化学)設立")]
              ),
            ]),
            _c("dt", { staticClass: "hstr-m-area-circle" }),
          ]
        ),
      ]),
      _c("div", { staticClass: "hstr-m-area" }, [
        _c("div", { staticClass: "hstr-m" }, [
          _c("div", { staticClass: "year-area" }, [
            _c("pre", { staticClass: "year" }, [_vm._v("1947")]),
          ]),
          _c("img", {
            staticClass: "m-card-img",
            attrs: {
              src: "https://img.lxhausys.com/public/images/aboutus/s_1947.png",
            },
          }),
          _c("p", [_vm._v("楽輝化学工業株式会社(現LG化学)設立")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap-history" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h2", { staticClass: "section-title" }, [
          _vm._v("История жилых помещений в Корее"),
        ]),
        _c("p", { staticClass: "ctt ctt-t2 tac" }, [
          _vm._v(
            "Наш путь, пройденный вместе с клиентами, представляет собой историю эволюции жилого пространства в Корее. "
          ),
          _c("br"),
          _vm._v(
            "Начиная с 1947 года, компания LX Hausys поставляет различные материалы для интерьера, которые повышают "
          ),
          _c("br"),
          _vm._v(
            "ценность жилых помещений в Корее на протяжении последних 70 лет. Сейчас LX Hausys стала крупнейшей компанией"
          ),
          _c("br"),
          _vm._v(
            " по производству строительных материалов в Корее. Мы будем продолжать утверждаться в качестве компании, которая "
          ),
          _c("br"),
          _vm._v(
            "обеспечивает доверие и удовлетворение наших клиентов, основываясь на наших лидирующих на "
          ),
          _c("br"),
          _vm._v(
            "рынке продуктах и передовых технологиях, чтобы поставлять безопасные, экологически чистые "
          ),
          _c("br"),
          _vm._v(
            "продукты и материалы, а также системы и решения, обеспечивающие максимальную энергоэффективность."
          ),
        ]),
      ]),
      _c("div", { staticClass: "history-table" }, [
        _c("dl", { staticClass: "hstr-l hstr-pb-90" }, [
          _c("dt", { staticStyle: { color: "#B40F2C" } }, [_vm._v("2023")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v(
                "Реализован план по достижению цели «Net Zero» к 2050 году"
              ),
            ]),
            _c("img", {
              staticClass: "card-img",
              staticStyle: {
                width: "99.247px",
                height: "97.342px",
                "flex-shrink": "0",
              },
              attrs: { src: "images/aboutus/s_2023.png" },
            }),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pt-180 hstr-pb-104" }, [
          _c("dt", [_vm._v("2022")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              staticStyle: { "flex-shrink": "0" },
              attrs: { src: "images/aboutus/s_2022.png" },
            }),
            _c("p", [_vm._v("Учрежден Комитет ESG")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pt-150 hstr-pb-104" }, [
          _c("dt", [_vm._v("2021")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2021.png",
              },
            }),
            _c("p", [_vm._v("Изменено название компании на LX Hausys")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-150" }, [
          _c("dt", [_vm._v("2020")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2020.png",
              },
            }),
            _c("p", [
              _vm._v(
                "Завершено расширение 3-й производственной линии для производства искусственного камня в США"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-104" }, [
          _c("dt", [_vm._v("2018")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2018.png",
              },
            }),
            _c("p", [
              _vm._v(
                "Завершено строительство 2-го завода по производству теплоизоляционных плит PF"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-300" }, [
          _c("dt", [_vm._v("2017")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v(
                "Приобретен контрольный пакет акций компании c21, словацкого производителя автомобильных материалов"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2016")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2016.png",
              },
            }),
            _c("p", [
              _vm._v(
                "Завершено строительство завода по производству автомобильной кожи для внутренней отделки в США (Атланта)"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-90" }, [
          _c("dt", [_vm._v("2015")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2015.png",
              },
            }),
            _c("p", [_vm._v("Завершено строительство завода в Уси, Китай")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2013")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2013.png",
              },
            }),
            _c("p", [
              _vm._v("Производство теплоизоляции PF для строительства"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2012")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2012.png",
              },
            }),
            _c("p", [
              _vm._v(
                "Завершено строительство крупнейшего в Корее завода по производству низкоэмиссионного стекла"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2011")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2011.png",
              },
            }),
            _c("p", [
              _vm._v(
                "Завершено строительство завода по производству искусственного камня в США"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2010")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2010.png" },
            }),
            _c("p", [
              _vm._v(
                'Приобретение сертификата "Углеродный след" впервые в области строительных материалов'
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("2009")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2009.png",
              },
            }),
            _c("p", [
              _vm._v(
                "Запуск LG Hausys (выделение бизнеса по производству промышленных материалов компании LG Chem)"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2007")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2007.png" },
            }),
            _c("p", [
              _vm._v(
                "Опубликован первый отчет об устойчивом развитии (по материалам LG chemistry)"
              ),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2006")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2006.png",
              },
            }),
            _c("p", [
              _vm._v("Запуск бренда премиального дизайна интерьера Z:IN "),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2005")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2005.png",
              },
            }),
            _c("p", [_vm._v("Завершение строительства завода HIMACS в США")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("1995")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1995.png",
              },
            }),
            _c("p", [
              _vm._v("Производство твердых акриловых поверхностей HIMACS"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("1984")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1984.png",
              },
            }),
            _c("p", [
              _vm._v("Производство пластиковых деталей для автомобилей"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("1976")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1976.png",
              },
            }),
            _c("p", [
              _vm._v("Произведено первое окно из ПВХ (HI-Sash) в Корее"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-180" }, [
          _c("dt", [_vm._v("1969")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_1969.png" },
            }),
            _c("p", [
              _vm._v("Произведено первое виниловое напольное покрытие в Корее"),
            ]),
          ]),
        ]),
        _c(
          "dl",
          { staticClass: "hstr-l", staticStyle: { "padding-bottom": "30px" } },
          [
            _c("dt", [_vm._v("1957")]),
            _c("dd", [
              _c("img", {
                staticClass: "card-img",
                attrs: {
                  src: "https://img.lxhausys.com/public/images/aboutus/s_1957.png",
                },
              }),
              _c("p", [
                _vm._v("Произведено первое окно из ПВХ (HI-Sash) в Корее"),
              ]),
            ]),
          ]
        ),
        _c(
          "dl",
          { staticClass: "hstr-r", staticStyle: { "padding-bottom": "25px" } },
          [
            _c("dt", [_vm._v("1952")]),
            _c("dd", [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-bottom": "120px",
                  },
                },
                [
                  _vm._v(
                    "Основана компания Lak Hui Chemical Industrial Corp. (в настоящее время LG Chem)"
                  ),
                ]
              ),
            ]),
            _c("dt", { staticClass: "hstr-m-area-circle" }),
          ]
        ),
      ]),
      _c("div", { staticClass: "hstr-m-area" }, [
        _c("div", { staticClass: "hstr-m" }, [
          _c("div", { staticClass: "year-area" }, [
            _c("pre", { staticClass: "year" }, [_vm._v("1947")]),
          ]),
          _c("img", {
            staticClass: "m-card-img",
            attrs: {
              src: "https://img.lxhausys.com/public/images/aboutus/s_1947.png",
            },
          }),
          _c("p", [
            _vm._v(
              "Основана компания Lak Hui Chemical Industrial Corp. (в настоящее время LG Chem)"
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap-history" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h2", { staticClass: "section-title" }, [
          _vm._v("韩国生活空间的历史"),
        ]),
        _c("p", { staticClass: "ctt ctt-t2 tac" }, [
          _vm._v(
            "一直与国民相件的LX Hausys的历史正是韩国生活空间的历史。LX Hausys自1947年以来,在过去的70余年间,"
          ),
          _c("br"),
          _vm._v(
            "一直致力于提供能够提升生活空间价值的装饰建材,并顺利成长为韩国国内最大规模的装饰建材企业。"
          ),
          _c("br"),
          _vm._v(
            "今后也将继续引领市场,以最优质的产品及不断提升的技术支撑力为基础,努力为顾客提供绿色环保的产品。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "history-table" }, [
        _c("dl", { staticClass: "hstr-l hstr-pb-90" }, [
          _c("dt", { staticStyle: { color: "#B40F2C" } }, [_vm._v("2023")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v("实施2050年达到《零碳排放》的目标"),
            ]),
            _c("img", {
              staticClass: "card-img",
              staticStyle: {
                width: "99.247px",
                height: "97.342px",
                "flex-shrink": "0",
              },
              attrs: { src: "images/aboutus/s_2023.png" },
            }),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pt-180 hstr-pb-104" }, [
          _c("dt", [_vm._v("2022")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              staticStyle: { "flex-shrink": "0" },
              attrs: { src: "images/aboutus/s_2022.png" },
            }),
            _c("p", [_vm._v("成立ESG 委员会")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pt-150 hstr-pb-104" }, [
          _c("dt", [_vm._v("2021")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2021.png",
              },
            }),
            _c("p", [_vm._v("公司更名为LX Hausys")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-150" }, [
          _c("dt", [_vm._v("2020")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2020.png",
              },
            }),
            _c("p", [_vm._v("美国石英石3号生产线完工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-104" }, [
          _c("dt", [_vm._v("2018")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2018.png",
              },
            }),
            _c("p", [_vm._v("建筑隔热材料第二工厂竣工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-300" }, [
          _c("dt", [_vm._v("2017")]),
          _c("dd", [
            _c("p", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v("收购斯洛伐克汽车零部件企业c21股份"),
            ]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2016")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2016.png",
              },
            }),
            _c("p", [_vm._v("美国（亚特兰大）汽车表皮工厂竣工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-90" }, [
          _c("dt", [_vm._v("2015")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2015.png",
              },
            }),
            _c("p", [_vm._v("中国无锡工厂竣工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2013")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2013.png",
              },
            }),
            _c("p", [_vm._v("生产建筑隔热材料")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2012")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2012.png",
              },
            }),
            _c("p", [_vm._v("韩国最大规模低辐射玻璃工厂竣工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2011")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2011.png",
              },
            }),
            _c("p", [_vm._v("美国石英石工厂竣工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2010")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2010.png" },
            }),
            _c("p", [_vm._v("在建筑材料领域首次获得碳足迹证书")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("2009")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2009.png",
              },
            }),
            _c("p", [_vm._v("LG Hausys成立（从LG化学工业材料分立）")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-70" }, [
          _c("dt", [_vm._v("2007")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_2007.png" },
            }),
            _c("p", [_vm._v("发布首份可持续发展报告（by LG 化学）")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("2006")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2006.png",
              },
            }),
            _c("p", [_vm._v("推出高端室内装饰品牌（Z:IN）")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("2005")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_2005.png",
              },
            }),
            _c("p", [_vm._v("美国亚克力人造大理石工厂竣工")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-80" }, [
          _c("dt", [_vm._v("1995")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1995.png",
              },
            }),
            _c("p", [_vm._v("生产亚克力人造大理石")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-104" }, [
          _c("dt", [_vm._v("1984")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1984.png",
              },
            }),
            _c("p", [_vm._v("生产汽车塑料零部件")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-l hstr-pb-70" }, [
          _c("dt", [_vm._v("1976")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: {
                src: "https://img.lxhausys.com/public/images/aboutus/s_1976.png",
              },
            }),
            _c("p", [_vm._v("生产韩国最早的PVC门窗（HI-Sash）")]),
          ]),
        ]),
        _c("dl", { staticClass: "hstr-r hstr-pb-180" }, [
          _c("dt", [_vm._v("1969")]),
          _c("dd", [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "images/aboutus/s_1969.png" },
            }),
            _c("p", [_vm._v("生产韩国首次商用PVC地板")]),
          ]),
        ]),
        _c(
          "dl",
          { staticClass: "hstr-l", staticStyle: { "padding-bottom": "30px" } },
          [
            _c("dt", [_vm._v("1957")]),
            _c("dd", [
              _c("img", {
                staticClass: "card-img",
                attrs: {
                  src: "https://img.lxhausys.com/public/images/aboutus/s_1957.png",
                },
              }),
              _c("p", [_vm._v("生产韩国最早的PVC门窗（HI-Sash）")]),
            ]),
          ]
        ),
        _c(
          "dl",
          { staticClass: "hstr-r", staticStyle: { "padding-bottom": "25px" } },
          [
            _c("dt", [_vm._v("1952")]),
            _c("dd", [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-bottom": "120px",
                  },
                },
                [_vm._v("成立乐惠化学工业社（现为LG化学）")]
              ),
            ]),
            _c("dt", { staticClass: "hstr-m-area-circle" }),
          ]
        ),
      ]),
      _c("div", { staticClass: "hstr-m-area" }, [
        _c("div", { staticClass: "hstr-m" }, [
          _c("div", { staticClass: "year-area" }, [
            _c("pre", { staticClass: "year" }, [_vm._v("1947")]),
          ]),
          _c("img", {
            staticClass: "m-card-img",
            attrs: {
              src: "https://img.lxhausys.com/public/images/aboutus/s_1947.png",
            },
          }),
          _c("p", [_vm._v("创立乐喜化学工业社（现为LG化学）")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }