var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pollutant-management-border-box-content" }, [
    _c("pre", {
      staticClass: "text",
      domProps: { innerHTML: _vm._s(_vm.text) },
    }),
    _c("pre", {
      staticClass: "unit",
      domProps: { innerHTML: _vm._s(_vm.unit) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }