var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-palette-area mt-100" },
    [
      _c("ProductSectionHeader", {
        attrs: { type: "horizontal", title: "LXELLO™ Color Palette" },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-50" }, [
      _c("img", {
        attrs: { src: "images/automotive/skin/color-palette.png", alt: "img" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }