var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loadingState.loading
    ? _c("progress", {
        staticClass: "lx_prog",
        attrs: { max: "100" },
        domProps: { value: _vm.progressValue },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }