<template>
  <div style= "width: 100%; height:auto; display:flex; justify-content: center;">
    <div class = "lx_worlds">
      <img class = "image_size first_image" :class="{us_image : isUSCode()}" src="images/lxworld/about_us/about_us_overview.png" />
      <img class = "image_size second_image" :class="{us_image : isUSCode()}" src="images/lxworld/about_us/LX_AboutUs_Labelling.png" />

      <AboutUsPoint
        v-for="pointInfo in pointInfoList"
        :key="pointInfo.id"
        :pointInfo="pointInfo"
        :hidePointer="hidePointer"
      />
      <!-- <div v-if=isUSCode() class = "us_additional_text">LX Hausys products available in the US:
        <li class = "listStyle">HFLOR(Resilient Flooring)</li>
        <li class = "listStyle">VIATERA(Quartz Surface)</li>
        <li class = "listStyle">HIMACS(Solid Surface)</li>
      </div> -->
    </div>
  </div>
</template>
<script>
import AboutUsPoint from '@/components/map/AboutUsPoint'

export default {
  name: 'Interior',
  components: {
    AboutUsPoint
  },
  props: {
    hidePointer: {
      type: Boolean
    }
  },
  data () {
    return {
      pointInfoList: [
        {
          id: 1,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Window') }],
          x1: 78.5,
          y1: 6.3,
          type: 'right',
          url: 'aboutus/overview/business-area/window',
          gray: true
        },
        {
          id: 2,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Interior_Film01') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Interior_Film02') }],
          x1: 16.5,
          y1: 22,
          type: 'left',
          url: 'aboutus/overview/business-area/interior-film',
          gray: true
        },
        {
          id: 3,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Kitchen') }],
          x1: 20.5,
          y1: 0.4,
          type: 'left',
          url: 'aboutus/overview/business-area/kitchen',
          gray: true
        },
        {
          id: 4,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Insulated_Glass01') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Insulated_Glass02') }],
          x1: 78.5,
          y1: 22.5,
          type: 'right',
          url: 'aboutus/overview/business-area/insulated-glass-coated-glass',
          gray: true
        },
        {
          id: 5,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Home_Appliance') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Materials') }],
          x1: 18,
          y1: 13,
          type: 'left',
          url: 'aboutus/overview/business-area/home-appliance-materials',
          gray: true
        },
        {
          id: 6,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Flooring') }],
          x1: 78.5,
          y1: 16.7,
          type: 'right',
          url: 'aboutus/overview/business-area/flooring',
          gray: false
        },
        {
          id: 7,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Bath') }],
          x1: 88,
          y1: 27,
          type: 'right',
          url: 'aboutus/overview/business-area/bath',
          gray: true
        },
        {
          id: 8,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Insulation_Materials01') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Insulation_Materials02') }],
          x1: -5,
          y1: 36,
          type: 'bottom',
          url: 'aboutus/overview/business-area/insulation-materials-buildings',
          gray: true
        },
        {
          id: 9,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Interior_Door01') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Interior_Door02') }],
          x1: 14.5,
          y1: 66.8,
          type: 'left',
          url: 'aboutus/overview/business-area/interior-door',
          gray: true
        },
        {
          id: 10,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Solid_Surface') }],
          x1: 88.4,
          y1: 81.5,
          type: 'right',
          url: 'aboutus/overview/business-area/acrylic-solid-surface',
          gray: false
        },
        {
          id: 11,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Quartz_Surface') }],
          x1: 57.5,
          y1: 0.5,
          type: 'right',
          url: 'aboutus/overview/business-area/acrylic-solid-surface',
          gray: false
        },
        {
          id: 12,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Wallcovering') }],
          x1: 88.5,
          y1: 57,
          type: 'right',
          url: 'aboutus/overview/business-area/wallcovering',
          gray: true
        },
        {
          id: 13,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Automotive_Interior_Leather01') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Automotive_Interior_Leather02') }],
          x1: 15,
          y1: 80,
          type: 'left',
          url: 'aboutus/overview/business-area/automotive-interior-leather',
          gray: true
        },
        {
          id: 14,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Automotive_Lightweight_Materials01') }, { id: 2, value: this.$t('BusinessArea.text.ThreeD_Automotive_Lightweight_Materials02') }, { id: 3, value: this.$t('BusinessArea.text.ThreeD_Automotive_Lightweight_Materials03') }],
          x1: 42,
          y1: 90.7,
          type: 'right',
          url: 'aboutus/overview/business-area/automotive-lightweight-materials',
          gray: true
        },
        {
          id: 15,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Automotive_Parts') }],
          x1: 16.5,
          y1: 87.5,
          type: 'left',
          url: 'aboutus/overview/business-area/automotive-parts',
          gray: true
        },
        {
          id: 16,
          popupList: [{ id: 1, value: this.$t('BusinessArea.text.ThreeD_Sign_Graphics') }],
          x1: 14.8,
          y1: 74.2,
          type: 'left',
          url: 'aboutus/overview/business-area/sign',
          gray: true
        }
      ],
      nationCode: ''
    }
  },
  async mounted () {
    const _this = this
    _this.nationCode = _this.$countryCode
  },
  methods: {
    isUSCode () {
      return (this.nationCode === '_US')
    }
  },
  computed: {}
}
</script>

<style>
.image_size {
  width : 1161px;
  height : 772px;
  position:absolute;
  top: 0px;
  left: 0px;
}
.us_image {
  /* left:-200px; */
}
.lx_worlds {
  width : 1161px;
  height : 772px;
  display:inline-block;
  position:relative;
}
.us_additional_text {
  left: 961px;
  top: 310px;
  width : 300px;
  position:absolute;
}
.listStyle {
  list-style-type:unset;
  text-align: left;
}
@media (max-width: 768px) {
  .image_size{
    left: 0px;
    top: 0px;
    padding-bottom: 0px;
    width: 100%;
    height: auto;
  }
  .first_image {
    position: relative;
  }
  .second_image {
    left: 50%;
    transform: translateX(-50%);
  }
  .lx_worlds {
    width : 80%;
    height : auto;
    display: flex;
    justify-content: center;
  }
}
</style>
