var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eco-friendly-products-tab" },
    [
      _c("EcoFriendlyProductsTabHeader", {
        attrs: { tabDatas: _vm.tabDatas },
        on: { "selected-tab": _vm.onClickHeader },
      }),
      _vm._t("item", null, null, _vm.tabBody),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }