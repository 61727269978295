<template>
  <ul>
    <COMPARE></COMPARE>
    <COLLECTION :collectionList="collectionList" v-model="collection" :category="category"></COLLECTION>
    <IS_NEW v-model="isNew"></IS_NEW>
    <COLOR :colorList="dictMap['hue']" v-model="hue"></COLOR>
    <PRD_PATTERN :patternList="dictMap['pattern']" v-model="pattern"></PRD_PATTERN>
    <FINISH :finishList="dictMap['finish']" v-model="finish"></FINISH>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import COMPARE from './component/COMPARE.vue'
import COLOR from './component/COLOR.vue'
import PRD_PATTERN from './component/PATTERN.vue'
import FINISH from './component/FINISH.vue'
import COLLECTION from './component/COLLECTION.vue'
import IS_NEW from './component/IS_NEW.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    IS_NEW,
    COLLECTION,
    COMPARE,
    PRD_PATTERN,
    COLOR,
    FINISH,
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      collection: [],
      hue: [],
      pattern: [],
      finish: [],
      country: [],
      isNew: [],
      thickness: [],
      slab_size: []
    }
  },
  watch: {
  },
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.collection, vm.pattern, vm.finish, vm.thickness, vm.slab_size], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {
    handleCheckBox (event, listName, checkListName, index) {
      this[listName][index][checkListName] = event.target.checked
      this.$emit('filter-changed', this.$data)
    }
  }
}
</script>
