<template>
  <!-- Nature, Human and Space 영역 -->
  <OverviewSection
    title="Nature, Human and Space"
    color="black"
    v-if="nationCode === '_US' || nationCode === 'GL'"
  >
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2">LX Hausys presents the vision of <b>“Creating human-friendly, eco-conscious living spaces”.</b>
This represents our goal to provide eco-conscious and energy-efficient materials and products as a trendsetter in creating a
future space realizing an aesthetically-pleasing and human-friendly design. This also shows our will to create living spaces that
bring contentment to our customers by placing customer satisfaction as the top priority.</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItem"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :description="it.description"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  <!--JP-->
  <OverviewSection
    title="自然、人間、そして空間"
    color="black"
    v-else-if="nationCode === '_JP'"
  >
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2">LX Hausysは、<b>「人に優しく、環境に配慮した生活空間の創造」</b>
        というビジョンを掲げています。これは、環境に配慮した省エネ素材や製品を提供し、美しさと人に
        優しいデザインを実現する未来の空間創造のトレンドセッターとなることを目指しています。
        また、顧客満足を第一に考え、お客様に満足いただける生活空間を創造していくという意志を表しています。</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItem_jp"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :description="it.description"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  <!--RU-->
  <OverviewSection
    title="Природа, человек и пространство"
    color="black"
    v-else-if="nationCode === '_RU'"
  >
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2">LX Hausys представляет концепцию <b>«Создание дружественных человеку, экологически безопасных жилых пространств»</b>.
        Это представляет собой нашу цель - обеспечить экологически чистые и энергоэффективные материалы и продукты как законодателя 
        моды в создании будущего пространства, реализующего эстетически приятный и дружественный человеку дизайн. Это также 
        демонстрирует наше стремление создавать жилые пространства, которые приносят удовлетворение нашим клиентам, 
        и это наш главный приоритет.</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItem_ru"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :description="it.description"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  <!--CN-->
  <OverviewSection
    title="自然、人类于空间"
    color="black"
    v-else-if="nationCode === '_CN'"
  >
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2">LX Hausys以<b>“创造与自然相融合的人类幸福生活空间”</b>为愿景，
        展示出企业为顾客提供环保节能的原料与产品，同时打造赏心悦目又不失人性化设计的空间，更将引领未来室内装修潮流的美好寓意。
        不管何时何地，都将以顾客满意为宗旨，致力于打造能带给顾客幸福感的生活空间。</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItem_cn"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :description="it.description"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewCardWithImage from '../../components/OverviewCardWithImage.vue'
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'

export default {
  components: {
    OverviewSection,
    OverviewCardWithImage,
    OverviewSectionItem
  },
  data: function () {
    return { // 객체형태
      countryCode: '',
      countryCodeSub: '',
      cardItem: [
        { imageSrc: 'images/aboutus/overview/vision_strategies/nature.png', description: 'We deliver a healthy and flourishing life to our customers by designing spaces that harmonize with nature through eco-friendly and energy-efficient materials.' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/human.png', description: 'We improve the quality of life and establish future trends in living spaces through aesthetically-pleasing, emotional and human-friendly designs.' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/space.png', description: 'We help to create spaces that bring contentment to our customers.' }
      ],
      cardItem_jp: [
        { imageSrc: 'images/aboutus/overview/vision_strategies/nature.png', description: '私たちは、環境に優しく、省エネ性に優れた素材を使い、自然と調和した空間をデザインすることで、お客様に健康で豊かな暮らしをお届けします。' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/human.png', description: '私たちは、美しく、感情に訴え、人間に優しいデザインを通じて、生活の質を向上させ、生活空間の将来のトレンドを確立します。' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/space.png', description: 'お客様に満足していただける空間づくりをお手伝いいたします。' }
      ],
      cardItem_ru: [
        { imageSrc: 'images/aboutus/overview/vision_strategies/nature.png', description: 'Мы обеспечиваем нашим клиентам здоровую и процветающую жизнь, проектируя помещения, гармонирующие с природой, с помощью экологичных и энергоэффективных материалов.' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/human.png', description: 'Мы улучшаем качество жизни и определяем будущие тенденции в жилых помещениях с помощью эстетически приятных и дружественных человеку дизайнов.' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/space.png', description: 'Мы помогаем создавать пространства, которые приносят удовлетворение нашим клиентам.' }
      ],
      cardItem_cn: [
        { imageSrc: 'images/aboutus/overview/vision_strategies/nature.png', description: '通过供应环保材料，提升能源性能等致力于营造与自然和谐的空间，为顾客开创健康、富饶的生活。' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/human.png', description: '通过美丽、感性、人类友好的设计，提升顾客的生活价值引领未来生活空间的发展潮流。' },
        { imageSrc: 'images/aboutus/overview/vision_strategies/space.png', description: '随时随地都将以满足顾客需求为首任，努力为顾客营造幸福的生活空间。' }
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.ctt-t2 {
  text-align: center;
  font-weight: 400;
}

.ctt-t2 b {
  font-weight: 700;
  font-size: 20px;
}

.section-item-area {
  margin: 0 auto;
  margin-bottom: 54px;
}

.section-item-area.last {
  margin-bottom: 0px;
}

.card-area {
  display: flex;
  flex-direction: row;
  gap: 28px;
}

@media (max-width: 768px) {
  .ctt-t2 {
    text-align: center;
    white-space: pre-wrap;
    margin-left: 5%;
    margin-right: 5%;
  }
  .ctt-t2 b {
    font-size: 16px;
    line-height: 1.25rem;
  }
  .card-area {
    flex-direction: column;
    gap: 0px;
  }
  .ctt-pre.ctt-t2{
    margin-left: 0;
    margin-right: 0;
    font-size: 16px;
    white-space: normal;
    word-break: break-word;
  }
}
</style>
