var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "key-project-card-area" }, [
    _c("div", { staticClass: "image-area" }, [
      _c("img", { attrs: { src: _vm.imageSrc } }),
    ]),
    _c("div", { staticClass: "text-area" }, [
      _c("span", [_vm._v(_vm._s(_vm.txt))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }