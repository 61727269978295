<template>
  <div class="core-values-card-area">
    <div class="title-area">
      <div class="title">{{ title }}</div>
      <div class="title-desc">{{ titleDesc }}</div>
      <div class="bottom-line"></div>
    </div>
    <img :src=iconSrc class="icon">
    <div class="desc-area">
      <div class="child"
        v-for="(it, idx) in desc"
        :key="idx"
      >
      {{ it }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    titleDesc: {
      type: String
    },
    desc: {
      type: Array
    },
    iconSrc: {
      type: String
    }
  }
}
</script>

<style scoped>
.core-values-card-area {
  display: flex;
  width: 700px;
  height: 338px;
  padding: 45px;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  flex-shrink: 0;
  background: #F4F4F4;
  border-radius: 20px;
  position: relative;
}

.core-values-card-area .title-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.core-values-card-area .title-area .title {
  color: #333;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 112.5% */
}

.core-values-card-area .title-area .title-desc {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.title-desc {
  margin-bottom: 25px;
}

.core-values-card-area .title-area .bottom-line {
  width: 200px;
  height: 1px;
  background-color: #333;
}

.core-values-card-area .desc-area {
  margin-left: 25px;
}

.core-values-card-area .desc-area .child {
  display: list-item;
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.desc-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.icon-area {
  position: relative;
}
.icon {
  position: absolute;
  right: 30px;
  width: 80px;
  height: 80px;
}

@media (max-width: 768px) {
  .core-values-card-area {
    width: 90%;
    height: 100%;
    gap: 20px;
    padding: 45px 45px 45px 35px;
  }

  .core-values-card-area .title-area .title {
    font-size: 1.6rem;
  }

  .core-values-card-area .title-area .title-desc {
    font-size: 1rem;
  }

  .icon {
    right: 10px;
    width: 55px;
    height: 55px;
    top: 3%;
  }

  .core-values-card-area .desc-area .child {
    font-size: 0.8rem;
  }
}
</style>
