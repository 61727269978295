<template>
  <ProductLayout>
    <template #content>
      <!-- 상단 이미지 -->
      <ProductTopImage
        title="Vegan Leather"
        subTitle="Sustainable & Innovative"
        description="Sustainable Products, Innovative Technology for Environment"
        imageSrc="images/automotive/skin/top-image.png"
      >
      </ProductTopImage>

      <!-- 제품 상세 영역 -->
      <ProductContent>
        <template>
          <!-- 제품 intro -->
          <ProductIntro
            class="mt-100"
            youtubeId="rxGl8ZxgpXY"
          >
            <template #text>
              <span class="bold">
                LX Hausys
              </span>
              <span>
                is a major supplier of interior skin for various automotive applications. With our advanced technology and innovative design, we are able to offer you a total interior solutions to decorate your vehicle beyond your expectations.
              </span>
            </template>
          </ProductIntro>

          <!-- 제품 특장점 -->
          <StrongPoingGroup
            class="mt-100"
            :info="[
              {
                iconSrc: 'images/automotive/skin/strong-point-icon-1.png',
                title: 'SAFETY',
                description: 'STANDARD 100 by OEKO-TEX® certified products have been tested for harmful substances to protect your health (Green Label)'
              },
              {
                iconSrc: 'images/automotive/skin/strong-point-icon-2.png',
                title: 'Sustainable Materials',
                description: 'Sustainable development by using bio-based and post-consumer recycled materials (Bio / Eco Label)'
              }
            ]"
          />

          <!-- 예시 이미지 -->
          <div class="mt-100">
            <img src="images/automotive/skin/example-center.jpg" alt="example">
          </div>

          <!-- Seat Cover 영역 -->
          <SeatCorver />

          <!-- Design 영역 -->
          <DesignArea />

          <!-- Commercial Vegan Leather 영역 -->
          <CommercialVeganLeather />

          <!-- LXELLO™ Color Palette 영역 -->
          <LxelloColorPalette />

          <!-- 하단 소개 페이지 -->
          <!-- <FooterNavigation /> -->
        </template>
      </ProductContent>
    </template>
  </ProductLayout>
</template>

<script>
import ProductLayout from '@/components/Layout/ProductLayout'
import ProductTopImage from '@/components/product/ProductTopImage'
import ProductContent from '@/components/product/ProductContent'
import ProductIntro from '@/components/product/ProductIntro'
import StrongPoingGroup from './components/StrongPoingGroup'
import SeatCorver from './components/SeatCorver'
import CommercialVeganLeather from './components/CommercialVeganLeather'
import DesignArea from './components/DesignArea'
import LxelloColorPalette from './components/LxelloColorPalette'
import FooterNavigation from './components/FooterNavigation'

export default {
  name: 'AutomotiveComponent',
  components: {
    ProductLayout,
    ProductTopImage,
    ProductContent,
    StrongPoingGroup,
    SeatCorver,
    CommercialVeganLeather,
    DesignArea,
    LxelloColorPalette,
    FooterNavigation,
    ProductIntro
  }
}
</script>

<style scoped>
</style>
