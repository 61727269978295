<template>
  <div class="hsrd-list HSRT02" v-bind:class="{ on: isOn }">
    <p class="blind">VIATERA</p>
    <ul v-if="searchResult && searchResult.totalCount > 0">
      <li v-for="sr in searchResult.list">
        <ul class="hsrd-list-detail">
          <li class="info00">
            <img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.title" @click="gotoDetail(sr, 'viatera')">
            <!-- <span>Viatera</span> -->
          </li>
          <li class="info02">
            <a href="javascript:void(0)" @click="gotoDetail(sr, 'viatera')">{{ sr.content.title }}</a>
          </li>
          <li class="info03"><span>{{ sr.parents[0].name }}</span></li>
        </ul>
      </li>
    </ul>
    <div class="hsrd-btn-area more-box ta-r">
      <button type="button" class="btn" v-on:click="$emit('search-page-event')"><span>{{ $t('search.search_view_all') }}</span></button>
    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'

export default {
  name: 'SearchViateraHomeComponent',
  props: {
    isOn: false,
    searchResult: {
      totalCount: 0,
      list: []
    }
  },
  methods: {
    gotoDetail (sr, type) {
      hdSec.close('.sch-ui')
      document.location.href = 'homecenter/' + type + '/' + sr.content.title + '/' + sr.content.id
    }
  }
}
</script>
