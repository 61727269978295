/*
 * @Author: ots
 * @Date: 2021-09-29
 * @LastEditors: ots
 * @Description: common script file load
 */

// 공용 css
// require('@/utils/pcrem.js')
// require('@/utils/js/jquery-3.5.1.min.js')
// require('@/utils/js/jquery-ui.js')
// require('@/utils/js/slick.min.js')
// require('@/utils/js/common.js')
