<template>

  <div class="full_width">
    <div class="contactus_inp_group inner_width">
      <form action="#none" class="">
        <fieldset>
          <legend>{{ $t("contactus.text.contactus") }}</legend>
          <div class="tbl-wrap">
            <div class="tab-con tab01 on">
              <div class="tbl-01">
                <h2 class="tt-2 mb-40" style="display:none">{{ $t("contactus.text.submitform") }}</h2>
                <div class="tbl-input">
                  <table>
                    <caption>Contact us For Professionals form</caption>
                    <colgroup>
                      <col style="width:50%">
                      <col style="width:50%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <!-- area -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.area") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ areaName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <!--<li v-for="area in areaList">
                                <button type="button" @click="proSelectCheck('area', area.areaCd, area.areaName)">{{ area.areaName }}</button>
                                </li>-->
                                <li><button type="button" @click="categoryType('Asia', 'pro', 'AREA_SOUTHASIA_PRO')">{{ $t("contactus.text.south_asia") }}</button></li>
                                <li><button type="button" @click="categoryType('Middle East', 'pro', 'AREA_MIDDLEEAST_PRO')">{{ $t("contactus.text.middle_east") }}</button></li>
                                <li><button type="button" @click="categoryType('Latin America', 'pro', 'AREA_LAAMERICA_PRO')">{{ $t("contactus.text.latin_ameria") }}</button></li>
                                <li><button type="button" @click="categoryType('Oceania', 'pro', 'AREA_OCEANIA_PRO')">{{ $t("contactus.text.oceania") }}</button></li>
                                <li><button type="button" @click="categoryType('Africa', 'pro', 'AREA_AFRICA_PRO')">{{ $t("contactus.text.africa") }}</button></li>
                                <li><button type="button" @click="categoryType('Turkey', 'pro', 'AREA_TURKEY_PRO')">{{ $t("contactus.text.turkey") }}</button></li>
                                <li><button type="button" @click="categoryType('Russia & CIS', 'pro', 'AREA_RUCIS_PRO')">{{ $t("contactus.text.ru_cis") }}</button></li>
                                <li><button type="button" @click="categoryType('China', 'pro', 'AREA_CHINA_PRO')">{{ $t("contactus.text.china") }}</button></li>
                                <li><button type="button" @click="categoryType('Japan', 'pro', 'AREA_JAPAN_PRO')">{{ $t("contactus.text.japan") }}</button></li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.area == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkArea") }}</p>
                      </td>
                      <!-- country -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.country") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ proCountryName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="country in glCountryList">
                                <button type="button" @click="proSelectCheck('country', country.id.value, country.text, country.id.dictionaryId, country.sort)">{{ country.text }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.country == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkCountry") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- type of inquiry -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.inquiry") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ proInquiryName }}</span></button>
                          <div class="sel-list">
                            <!--<ul class="box">
                              <li v-for="typeInq in proTypeInquiryList">
                                <button type="button" @click="proSelectCheck('inquiry', typeInq.id.value, typeInq.text, typeInq.id.dictionaryId, typeInq.sort)">{{typeInq.text}}</button>
                              </li>
                            </ul>-->
                            <ul class="box">
                              <li class="box_inquiry"><button type="button" @click="proSelectCheck('Become a Partner', 'INQ_PARTNER_GL', 'Become a Partner')">{{ $t("contactus.text.PARTNER") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="proSelectCheck('Commercial Project (A&D)', 'INQ_COMPROJECT_GL', 'Commercial Project (A&D)')">{{ $t("contactus.text.COMPROJECT") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="proSelectCheck('Sample', 'INQ_SAMPLE_GL', 'Sample')">{{ $t("contactus.text.SAMPLE") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="proSelectCheck('B2B Customers',  'INQ_B2BCUSTOMERS_GL', 'B2B Customers')">{{ $t("contactus.text.B2BCUSTOMERS") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="proSelectCheck('Technical Inquiry', 'INQ_TECHINQUIRY_GL', 'Technical Inquiry')">{{ $t("contactus.text.TECHINQUIRY") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="proSelectCheck('Others', 'INQ_OTHERS_GL', 'Others')">{{ $t("contactus.text.OTHERS") }}</button></li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.inquiry == '' && proSubmitChk == 'Y'">{{ $t("contactus.text.checkInquiry") }}</p>
                      </td>
                      <!-- selectBox product category -->
                      <!--
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.prd_cate") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ proCateName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="productCate in proProductList">
                                <button type="button" @click="proSelectCheck('productType', productCate.id.value, productCate.text, productCate.id.dictionaryId, productCate.sort)">{{productCate.text}}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.productCate == '' && proSubmitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                      </td>
                      -->
                    </tr>
                    <!-- checkBox product category -->
                    <tr class="product_wrap">
                      <td class="cell_padding">
                        <label class="tbl_label must products">Products</label>
                        <div class="product_checkbox_cont">
                          <div class="checkbox_more" v-for="productCate in proProductList" :key="productCate.id.value">
                            <span>
                              <input type="checkbox" :id="productCate.id.value" :value="{ id: productCate.id.value, text: productCate.text }" v-model="selectedItems">
                              <label :for="productCate.id.value">{{ productCate.text }}</label>
                            </span>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="selectedItems.length == 0 && proSubmitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- first name -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp01">{{ $t("contactus.text.firstName") }}</label>
                        <input type="text" class="inp-text" id="inp01" v-model="proData.firstName"/>
                        <p class="glSite required-msg" v-if="proData.firstName == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkFirstName") }}</p>
                      </td>
                      <!-- last name -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp02">{{ $t("contactus.text.lastName") }}</label>
                        <input type="text" class="inp-text" id="inp02" v-model="proData.lastName"/>
                        <p class="glSite required-msg" v-if="proData.lastName == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkLastName") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- email -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp03">{{ $t("contactus.text.email") }}</label>
                        <input type="text" class="inp-text" id="inp03" v-model="proData.email"/>
                        <p class="glSite required-msg" v-if="proData.email == '' && proSubmitChk == 'Y' || proSubmitChk == 'Y' && !emailRule.test(proData.email)">{{ $t("contactus.text.checkEmail") }}</p>
                      </td>
                      <!-- phone -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp04">{{ $t("contactus.text.phone") }}</label>
                        <input type="text" class="inp-text" id="inp04" v-model="proData.phone" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');"/>
                        <p class="glSite required-msg" v-if="proData.phone == '' && proSubmitChk == 'Y'">{{ $t("contactus.text.checkPhone") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- occupation -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.occupation") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ occName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="occ in occList">
                                <button type="button" @click="proSelectCheck('occupation', occ.id.value, occ.text)">{{occ.text}}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.occupation == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkOcc") }}</p>
                      </td>
                      <!-- job title (Optional) -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.jobTitle") }}</label>
                        <input type="text" class="inp-text" v-model="proData.jobTitle"/>
                      </td>
                    </tr>
                    <tr>
                      <!-- company name (Optional) -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.companyNm") }}</label>
                        <input type="text" class="inp-text" v-model="proData.company"/>
                      </td>
                      <!-- company website (Optional) -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.companyWebs") }}</label>
                        <input type="text" class="inp-text" v-model="proData.companyWebs"/>
                      </td>
                    </tr>
                    <tr>
                      <!-- company address (Optional) -->
                      <td colspan="2" class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.companyAddr") }}</label>
                        <input type="text" class="inp-text" v-model="proData.companyAddr"/>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tbl-02">
                <div class="tbl-input">
                  <table>
                    <tbody>
                    <tr>
                      <!-- comment -->
                      <td class="cell_padding">
                        <label class="tbl_label">{{ $t("contactus.text.comment") }}</label>
                        <div class="ta-box">
                          <textarea v-model="proData.comment" maxlength="1000"></textarea>
                        </div>
                        <p class="glSite required-msg" v-if="proData.comment == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkCommentGl") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="inp-chk">
                          <input type="checkbox" id="checkSubmit" v-model="consent">
                          <span>{{ $t("contactus.text.consent1") }} <span>{{ $t("contactus.text.submit") }}</span>{{ $t("contactus.text.consent2") }}<a href="javascript:void(0);" @click="openPrivacy()">{{ $t("contactus.text.policy") }}</a> and <a href="javascript:void(0);" @click="openTerms()">{{ $t("contactus.text.terms") }}</a>. <span>*</span></span>
                        </label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <br/>
                <div class="noti-02" v-if="consent == false && proSubmitChk == 'Y'">{{ $t("contactus.text.checkComment") }}</div>
                <div class="more-box ta-r">
                  <button type="button" @click="formSubmit()" :disabled="isSubmitting" class="btn">
                    <span>{{ isSubmitting ? 'submitting...' : $t("contactus.button.submit") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import getDictionaryList from '@/server/support.service'
import getCountryList from '@/server/us.service'

export default {
  name: 'contactus_GL',
  props: ['pcdCateCode'],
  components: {
  },
  data () {
    return {
      isSubmitting: false,
      guid: '',
      proCateNumber: '',
      selectedItems: [],
      nationCode: '',
      proGeneralChk: 'N',
      proSubmitChk: 'N',
      radio: 'proCheck',
      productName: this.$t('contactus.text.select'),
      proInquiryName: this.$t('contactus.text.select'),
      proCateName: [],
      proCountryName: this.$t('contactus.text.select'),
      areaName: this.$t('contactus.text.select'),
      occName: this.$t('contactus.text.select'),
      proTypeInquiryList: [],
      proProductList: [],
      proHflorInquiry: [],
      proViateraInquiry: [],
      proHimacsInquiry: [],
      proBenifInquiry: [],
      proDecoInquiry: [],
      proVizuonInquiry: [],
      proExteriorInquiry: [],
      glCountryList: [],
      occList: [],
      nextOccSelect: [],
      comment: '',
      areaList: [],
      mktChk: 'N',
      inqDictValue: '',
      inquirySelected: false,
      consent: false,
      infoProCateName: '',
      proData: {
        productCate: '',
        dictionaryId: '',
        inquiry: '',
        firstName: '',
        lastName: '',
        email: '',
        area: '',
        areaCd: '',
        phone: '',
        country: '',
        countryCd: '',
        occupation: '',
        company: '',
        companyWebs: '',
        companyAddr: '',
        jobTitle: '',
        comment: '',
        address: ''
      },
      siteType: [
        {
          id: '_US',
          value: 'US'
        },
        {
          id: '_GL',
          value: 'GL'
        }
      ],
      siteTp: '',
      //  메일 발송용 text
      inquiryText: '',
      countryText: '',
      areaText: '',
      occText: '',
      TypeText: '',
      proDepartment: '',
      proInquirySort: '',
      proProCateSort: '',
      checkZip: '',
      emailRule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/ // 이메일 정규식
    }
  },
  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  created () {
    const _this = this
    const nationCode = this.$countryCode // 국가 코드(_US, _GL 등)
    _this.nationCode = nationCode
    _this.$nextTick(function () {
    })
  },
  async mounted () {
    const _this = this
    // GL, US 구분
    _this.siteTp = _.filter(_this.siteType, function (c) {
      return c.id == _this.nationCode
    })

    // 데이터 사전 불러오기
    const dictionary = await getDictionaryList.getDictionaryValueList([
      'AREA_SOUTHASIA_PRO', 'AREA_MIDDLEEAST_PRO', 'AREA_LAAMERICA_PRO', 'AREA_OCEANIA_PRO', 'AREA_AFRICA_PRO', 'AREA_TURKEY_PRO', 'AREA_RUCIS_PRO', 'AREA_CHINA_PRO', 'AREA_JAPAN_PRO',
      'INQ_PARTNER_GL', 'INQ_OTHERS_GL', 'INQ_TECHINQUIRY_GL', 'INQ_B2BCUSTOMERS_GL', 'INQ_SAMPLE_GL', 'INQ_COMPROJECT_GL',
      'INQ_HFLOR_GL', 'INQ_VIATERA_GL', 'INQ_HIMACS_GL', 'INQ_TERACANTO_GL', 'INQ_BENIF_GL', 'INQ_VIZUON_GL', 'INQ_DECOFOIL_GL', 'INQ_EXTERIORFOIL_GL', 'INQ_HOMESURFA_GL', 'INQ_AUTOMOTIVE_GL',
      'OCCUPATION'
    ])
    _this.proSouthAsiaArea = dictionary.data.dicList[0].list // Pro Inquiry Type (Professionals Southeast Asia 조회 종류)
    _this.proMiddleEastArea = dictionary.data.dicList[1].list // Pro Inquiry Type (Professionals Middle East 조회 종류)
    _this.proLatinAmericaArea = dictionary.data.dicList[2].list // Pro Inquiry Type (Professionals Latin America 조회 종류)
    _this.proOceaniaArea = dictionary.data.dicList[3].list // Pro Inquiry Type (Professionals Oceania 조회 종류)
    _this.proAfricaArea = dictionary.data.dicList[4].list // Pro Inquiry Type (Professionals Africa 조회 종류)
    _this.proTurkeyArea = dictionary.data.dicList[5].list // Pro Inquiry Type (Professionals Turkey 조회 종류)
    _this.proRuCisArea = dictionary.data.dicList[6].list // Pro Inquiry Type (Professionals Russia & CIS 조회 종류)
    _this.proChinaArea = dictionary.data.dicList[7].list // Pro Inquiry Type (Professionals China 조회 종류)
    _this.proJapanArea = dictionary.data.dicList[8].list // Pro Inquiry Type (Professionals Japan 조회 종류)

    _this.Partner = dictionary.data.dicList[9].list
    _this.Others = dictionary.data.dicList[10].list
    _this.TechInquiry = dictionary.data.dicList[11].list
    _this.B2BCustomers = dictionary.data.dicList[12].list
    _this.Sample = dictionary.data.dicList[13].list
    _this.ComProject = dictionary.data.dicList[14].list

    _this.hflor = dictionary.data.dicList[15].list
    _this.viatera = dictionary.data.dicList[16].list
    _this.himacs = dictionary.data.dicList[17].list
    _this.teracanto = dictionary.data.dicList[18].list
    _this.benif = dictionary.data.dicList[19].list
    _this.vizuon = dictionary.data.dicList[20].list
    _this.decoFoil = dictionary.data.dicList[21].list
    _this.exteriorFoil = dictionary.data.dicList[22].list
    _this.homeSurface = dictionary.data.dicList[23].list
    _this.automotive = dictionary.data.dicList[24].list

    _this.nextOccSelect = dictionary.data.dicList[25].list // Pro Occupation List(직종 리스트)
  },
  methods: {
    generateGuid() {
      const _this = this
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
      return _this.guid
    },
    async categoryType (type, submitType, code) { // Product Category SelectBox 클릭 이벤트
      const _this = this
      _this.guid = await this.generateGuid()
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()

      if (submitType == 'pro') {
        _this.areaName = type
        _this.proData.area = code
        _this.proCountryName = 'Select'
        _this.proData.country = ''
        _this.proInquiryName = 'Select'
        _this.proData.inquiry = ''
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.occName = 'Select'
        _this.proData.occupation = ''
        if (type == 'Asia') {
          _this.glCountryList = _this.proSouthAsiaArea
          _this.proData.areaCd = 'AS'
        } else if (type == 'Middle East') {
          _this.glCountryList = _this.proMiddleEastArea
          _this.proData.areaCd = 'ME'
        } else if (type == 'Latin America') {
          _this.glCountryList = _this.proLatinAmericaArea
          _this.proData.areaCd = 'SA'
        } else if (type == 'Oceania') {
          _this.glCountryList = _this.proOceaniaArea
          _this.proData.areaCd = 'OC'
        } else if (type == 'Africa') {
          _this.glCountryList = _this.proAfricaArea
          _this.proData.areaCd = 'AF'
        } else if (type == 'Turkey') {
          _this.glCountryList = _this.proTurkeyArea
          _this.proData.areaCd = 'TK'
        } else if (type == 'Russia & CIS') {
          _this.glCountryList = _this.proRuCisArea
          _this.proData.areaCd = 'RU'
        } else if (type == 'China') {
          _this.glCountryList = _this.proChinaArea
          _this.proData.areaCd = 'CN'
        } else if (type == 'Japan') {
          _this.glCountryList = _this.proJapanArea
          _this.proData.areaCd = 'JP'
        }
      }
    },

    async proSelectCheck (type, code, value, dictionary, sort) { // Professionals 일 때 selectBox 클릭 이벤트
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      const _this = this

      if (type == 'country') {
        _this.proData.country = code
        _this.proCountryName = value
        const countryListCd = await getCountryList.getGLCountryCd(value)
        _this.proData.countryCd = countryListCd.data[0].countryCd
        _this.proInquiryName = 'Select'
        _this.proData.inquiry = ''
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.occList = _this.nextOccSelect
        // Type of inquiry
      } else if (type == 'Become a Partner') {
        _this.proInquiryName = type
        _this.proData.inquiry = code
        _this.proData.dictionaryId = code
        _this.proProductList = _this.Partner
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.proCateNumber = '001'
      } else if (type == 'Commercial Project (A&D)') {
        _this.proInquiryName = type
        _this.proData.inquiry = code
        _this.proData.dictionaryId = code
        _this.proProductList = _this.ComProject
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.proCateNumber = '002'
      } else if (type == 'Sample') {
        _this.proInquiryName = type
        _this.proData.inquiry = code
        _this.proData.dictionaryId = code
        _this.proProductList = _this.Sample
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.proCateNumber = '003'
      } else if (type == 'B2B Customers') {
        _this.proInquiryName = type
        _this.proData.inquiry = code
        _this.proData.dictionaryId = code
        _this.proProductList = _this.B2BCustomers
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.proCateNumber = '004'
      } else if (type == 'Technical Inquiry') {
        _this.proInquiryName = type
        _this.proData.inquiry = code
        _this.proData.dictionaryId = code
        _this.proProductList = _this.TechInquiry
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.proCateNumber = '005'
      } else if (type == 'Others') {
        _this.proInquiryName = type
        _this.proData.inquiry = code
        _this.proData.dictionaryId = code
        _this.proProductList = _this.Others
        _this.selectedItems = []
        _this.proData.productCate = ''
        _this.proCateNumber = '006'
      } else if (type == 'occupation') {
        _this.proData.occupation = code
        _this.occName = value
      }
    },
    async formSubmit() {
      const _this = this
      const submitData = []
      _this.proSubmitChk = 'Y'
      if (_this.isSubmitting) return
      else _this.isSubmitting = true

      try {
          // comment 글자수 체크
        if (_this.proData.comment.length > 1000) {
          alert('The maximum number of characters is 1,000 characters.')
          return false
        }
        // 동의여부 체크
        if (!$('#checkSubmit').is(':checked')) {
          alert('Please check whether you agree to collect personal information.')
        }
        for (const item of  _this.selectedItems) {
          const formData = await _this.formSubmit_data(item)
          if (formData === false) return
          else submitData.push(formData)
        }
        // 동의 여부 체크 및 저장, 메일 발송
        if ($('#checkSubmit').is(':checked')) {
          const result = await getDictionaryList.getContactUsSave(submitData)
          if (result.data.Type == true) {
            this.makeDataLayerForm()
            setTimeout(function () {location.href = 'support/contactus-close'}, 1000)
          } else {
            alert('Error')
          }
        }
      } catch (error) {
        //console.error('Submission error:', error)
        alert('An error occurred during submission. Please try again.')
      } finally {
        _this.isSubmitting = false
      }
    },
    async formSubmit_data (item) {
      const _this = this
      const formData = {}
      _this.proCateName = item.text
      _this.mktChk = 'N'

      if (_this.consent == true) {
        _this.mktChk = 'Y'
      }
      _this.proSubmitChk = 'Y'
      _this.inqDictValue = item.id
      _this.infoProCateName += item.text + '|'

      formData.reqType = ('Pro')
      formData.categoryCode = (_this.proCateName)
      formData.inqDictId = (_this.proData.dictionaryId)
      formData.inqDictVal = (_this.inqDictValue)
      formData.countryCd = (_this.proData.countryCd)
      formData.areaCd = (_this.proData.areaCd)
      formData.firstNm = (_this.proData.firstName)
      formData.lastNm = (_this.proData.lastName)
      formData.email = (_this.proData.email)
      formData.phone = (_this.proData.phone)
      formData.occupation = (_this.proData.occupation)
      formData.jobTitle = (_this.proData.jobTitle)
      formData.companyNm = (_this.proData.company)
      formData.companyWebs = (_this.proData.companyWebs)
      formData.companyAddr = (_this.proData.companyAddr)
      formData.comment = (_this.proData.comment)
      formData.siteType = (_this.siteTp[0].value)
      formData.mktChk = (_this.mktChk)
      formData.addr1 = (_this.proData.address)
      formData.guid = (_this.guid)
      //  메일 발송용 text
      formData.typeText = ('Professionals')
      formData.productName = (_this.proCateName)
      formData.inquiryText = (_this.proInquiryName)
      formData.countryText = (_this.proCountryName)
      formData.areaText = (_this.areaName)
      formData.occText = (_this.occName)

      var emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식

      // 빈값 체크
      for (const [key, value] of Object.entries(formData)) {
      //for (const pair of formData.entries()) {
        if (key === 'companyNm' || key === 'companyWebs' || key === 'companyAddr' || key === 'jobTitle' || key === 'addr1') {
          continue
        }
        if (key === 'email') {
          if (!emailRule.test(value)) {
            alert('Please check your email.')
            return false
          }
        } else if (value === '') {
          alert('Please check again!')
          return false
        }
      }
      return formData
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    openPrivacy () {
      window.open('etc/privacy?pop=Y', 'privacy', 'width:800px')
    },
    openTerms () {
      window.open('etc/terms?pop=Y', 'terms', 'width:800px')
    },
    // GTM dataLayer - contact us
    makeDataLayerForm () {
      let contactType = ''
      if (this.nationCode === '_GL') {
        contactType = 'COMMON'
      }
      let email = ''
      email = this.proData.email

      let contactArea = ''
      if (this.nationCode === '_GL') {
        contactArea = this.areaName
      }

      let contactCountry = ''
      contactCountry = this.proCountryName

      let contactCompanyNm = ''
      contactCompanyNm = this.proData.company

      const info = {
        event: 'contactUsComp',
        contact_email: this.$utils.makeMd5hash(email),
        contact_type: contactType,
        contact_product_category: this.infoProCateName.slice(0, -1),
        contact_type_of_inquiry: this.proInquiryName,
        contact_area: contactArea,
        contact_country: contactCountry,
        contact_state: '',
        contact_zipcode: '',
        contact_occupation: this.occName,
        contact_company: contactCompanyNm,
        contact_marketing_flag: this.mktChk
      }
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push(info)
      }
    }
  }
}
</script>
