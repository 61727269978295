<template>
  <div class="header-area">
    <div
      v-for="(it, idx) in headerInfo"
      :key="idx"
      class="item"
      :class="it.active ? 'active' : ''"
      @click="onHeaderClick(idx)"
    >
      <span>{{ it.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    selectedIndex: 0
  }),

  computed: {
    headerInfo () {
      return this.item.map((it, idx) => {
        const isActive = idx === this.selectedIndex
        return {
          title: it.title,
          active: isActive
        }
      })
    }
  },

  methods: {
    onHeaderClick (idx) {
      this.selectedIndex = idx
      this.$emit('change', idx)
    }
  }
}
</script>

<style scoped>
.product-tab-area .header-area {
  display: flex;
  flex-direction: row;
  border: 1px solid #000;
  border-width: 1px 0 0 1px;
}

.product-tab-area .header-area .item {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  border-collapse: collapse;
  background: #FFF;
  color: #000;
  cursor: pointer;
}

.product-tab-area .header-area .item.active {
  border: 1px solid #000;
  background: #000;
  color: #FFF;
}

.product-tab-area .header-area .item span {
  color: inherit;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}
@media (max-width: 768px) {
  .product-tab-area .header-area .item span {
    font-size: 0.8rem;
  }
}
</style>
