var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overview-small-section" },
    [
      _vm.showTitle
        ? [
            _c("div", { staticClass: "title" }, [
              _c(
                "span",
                {
                  style: {
                    "font-size": _vm.size,
                    color: _vm.color,
                    "font-family": _vm.fontFamily,
                    "font-weight": _vm.fontWeight,
                  },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ]),
          ]
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }