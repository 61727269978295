<template>
  <OverviewSection
  title="Management Strategy"
  color="black"
  v-if="nationCode == '_US' || nationCode == '_GL'">
    <OverviewTopImage
        v-if="!isMobile()"
        imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
        description="LX Hausys is conducting its business on the basis of the major strategic direction of
 “the advancement of the business portfolio and strengthening of business structure”.
We aim to prepare for the market changes and accomplish solid growth in the market by focusing on
strengthening its solutions for enhanced customer value, R&D based on market & customer needs,
and activities for innovating manufacturing to achieve the highest level globally."
        imageWidth='1440px'
        imageHeight='360px'
    />

    <OverviewTopImage
        v-if="isMobile()"
        imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
        imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys is conducting its business on the basis of the major strategic direction of
“the advancement of the business portfolio and strengthening of business structure”.
We aim to prepare for the market changes and accomplish solid growth in the market by focusing on
strengthening its solutions for enhanced customer value, R&D based on market & customer needs,
and activities for innovating manufacturing to achieve the highest level globally.</pre>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="経営戦略"
    color="black"
    v-else-if="nationCode == '_JP'">
    <OverviewTopImage
      v-if="!isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
      description="LX Hausysは、「事業ポートフォリオの高度化と事業構造の強化」を主要戦略方針として
掲げ、顧客価値向上のためのソリューション強化、市場・顧客ニーズに基づく研究開発、世界最高水準の
モノづくり革新活動に注力し、市場変化に備え、市場での着実な成長を目指します。"
      imageWidth='1440px'
      imageHeight='360px'
    />

    <OverviewTopImage
      v-if="isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
      imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
        LX Hausysは、「事業ポートフォリオの高度化と事業構造の強化」を主要戦略方針として掲げ、顧客価値向上のためのソリューション強化、市場・顧客ニーズに基づく研究開発、世界最高水準のモノづくり革新活動に注力し、市場変化に備え、市場での着実な成長を目指します。</pre>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Стратегия управления"
    color="black"
    v-else-if="nationCode == '_RU'">
    <OverviewTopImage
      v-if="!isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
      description="LX Hausys ведет деятельность на основе основного стратегического направления
«развитие бизнес-портфеля и укрепление структуры бизнеса».
Мы стремимся подготовиться к изменениям на рынке и добиться уверенного роста на нем, сосредоточившись на 
укреплении своих решений для повышения потребительской ценности, исследованиях и разработках, основанных на 
потребностях рынка и клиентов, и деятельности по внедрению инноваций в производство для достижения 
наивысшего уровня во всем мире."
      imageWidth='1440px'
      imageHeight='360px'
    />

    <OverviewTopImage
      v-if="isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
      imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
        LX Hausys ведет деятельность на основе основного стратегического направления «развитие бизнес-портфеля и укрепление структуры бизнеса». Мы стремимся подготовиться к изменениям на рынке и добиться уверенного роста на нем, сосредоточившись на укреплении своих решений для повышения потребительской ценности, исследованиях и разработках, основанных на потребностях рынка и клиентов, и деятельности по внедрению инноваций в производство для достижения наивысшего уровня во всем мире.</pre>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="经营策略"
    color="black"
    v-else-if="nationCode == '_CN'">
    <OverviewTopImage
      v-if="!isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
      description="LX Hausys坚持以优化事业组合和强化事业本质为核心战略方向开展事业。建筑装饰材料事业方面
      将进一步扩大韩国B2C业务和高附加值产品的销售。汽车材料配件和工业用薄膜事业方面将致力于强化竞争力
      和改善收益性。LX Hausys将集中精力精力强化解决方案, 提高客户价值；开展以市场和客户需求为基础的研发
      以及全球一流水平的制造创新活动, 旨在积极应对市场变化, 实现稳健增长。"
      imageWidth='1440px'
      imageHeight='360px'
    />

    <OverviewTopImage
      v-if="isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/management-strategy-back.png"
      imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
LXLX Hausys坚持以优化事业组合和强化事业本质为核心战略方向开展事业。建筑装饰材料事业方面将进一步扩大韩国B2C业务和高附加值产品的销售。汽车材料配件和工业用薄膜事业方面将致力于强化竞争力和改善收益性。LX Hausys将集中精力精力强化解决方案, 提高客户价值；开展以市场和客户需求为基础的研发以及全球一流水平的制造创新活动, 旨在积极应对市场变化, 实现稳健增长。</pre>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import OverviewTopImage from '../../components/OverviewTopImage.vue'

export default {
  components: {
    OverviewSection,
    OverviewTopImage,
    OverviewSectionItem
  },
  methods: {
    isMobile () {
      const screenWidth = window.innerWidth
      return screenWidth <= 768
    }
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .ctt-t2 {
    white-space: pre-wrap;
  }
}
</style>
