<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content cntu-view inner glSite where">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('wheretobuy.text.FC0030_003') }}</li>
          <li>{{ $t('wheretobuy.text.FC0030_004') }}</li>
          <li>{{ $t('wheretobuy.text.FC0030_005') }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Where to Buy) -->
      <div class="container cntu-box where">
        <h1>WHERE TO BUY</h1>
        <div class="where-search-box">
          <div>
            <div>
              <a @click="setLocation()">
                <img src="https://img.lxhausys.com/2024/images/support/ico_location.png" alt="location">
              </a>
              <input id="searchInput" type="text" @keyup.enter="goSearch()" v-model="searchValue.keyword"
                     placeholder="Enter County, Zipcode, or Specific address"/>
            </div>
            <div class="product-select-wrap">
              <div class="product-select-title">
                <p>Products</p>
                <img src="https://img.lxhausys.com/2024/images/svg_icon/where_arrow_down.svg" alt="arrow">
              </div>
              <div class="product-select">
                <div>
                  <p>PRODUCT CATEGORY</p>
                  <span>Searching for a store that carries<br>
                      all the chosen products.</span>
                </div>
                <div class="product-check" v-for="(productCategoryItem, index) in options.searchProductCategory"
                     :key="productCategoryItem.value">
                  <label class="inp-chk">
                    <input type="checkbox" :value="productCategoryItem.value"
                           v-model="options.selectSearchProductCategory"/>
                    <span>{{ productCategoryItem.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="more-box">
              <button @click="goSearch" class="btn_search">Search</button>
            </div>
          </div>
        </div>
        <!-- <div class="sub-visual-section">
          <h1 class="tt-1 visual-title tac">
            {{ $t('wheretobuy.text.FC0030_002') }}
          </h1>
          <h2 class="sr-only">LX Hausys Store Location</h2>
        </div> -->
        <div class="where-site-wrap">
          <div class="where-site-info">
            <div class="store-type-wrap">
              <div class="store-type-title" @click="clickStoreTypeTit()">
                <p class="bold">Select Store Type</p>
                <img src="https://img.lxhausys.com/2024/images/svg_icon/faq_arrow_up.svg" alt="arrow">
              </div>
              <div class="store-type box">
                <div class="store-check-box">
                  <div class="store-checks">
                    <div class="store-check" v-if="storeItem.type.includes($countryCode.substring(1))"
                         v-for="(storeItem, storeIndex) in options.searchDealerType" :key="storeItem.value">
                      <label class="inp-chk">
                        <template v-if="storeItem.name === 'Distributor'">
                          <input type="checkbox" :value="storeItem.value && options.isSelectHflor" v-model="options.selectSearchDealerType"
                                 :disabled="!options.isSelectHflor"/>
                        </template>
                        <template v-else>
                          <input type="checkbox" :value="storeItem.value" v-model="options.selectSearchDealerType"/>
                        </template>
                        <div>
                          <p>{{ storeItem.name }}</p>
                          <span v-html="storeItem.desc"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="more-box contact">
                  <button @click="clearDealerType()" class="btn_clear">Clear All</button>
                  <button @click="goSearch" class="btn_apply">Apply</button>
                </div>
              </div>
            </div>
            <p>{{ viewList.length }}<span> Results</span></p>
            <div class="store-cards">
              <template v-if="viewList.length">
                <div class="store-card" v-for="(viewItem, viewIndex) in viewList"
                     @click="goStore(viewIndex, viewItem.latitude, viewItem.longitude, true)">
                  <div>
                    <div class="profile">
                      <img src="https://img.lxhausys.com/2024/images/support/ico_person.png" alt="icon">
                      <p>{{ getDealerTypeName(viewItem.dealerType) }}</p>
                    </div>
                    <div class="tags">
                      <template v-for="(tagItem, tagIndex) in viewItem.categoryCode.split(',')">
                        <div :class="`tag h22 ${getTagName(tagItem)}`">
                          {{ getTagName(tagItem, 'upper') }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="store-more">
                    <div>
                      <p>{{ viewItem.company }}</p>
                      <p>{{ viewItem.addr1 }}</p>
                      <span v-if="viewItem.city">{{ getCountryNm(viewItem.countryCd) }} {{ viewItem.city }}</span>
                      <template v-if="viewItem.zipCd">
                        <span>{{ viewItem.zipCd }}</span>
                      </template>
                      <template v-else-if="viewItem.addr1.match(/\b\d{5}\b/)">
                        <span>{{ viewItem.addr1.match(/\b\d{5}\b/)[0] }}</span>
                      </template>
                      <div class="store-more-phone" v-if="viewItem.tel">
                        <img src="https://img.lxhausys.com/2024/images/support/ico_phone.png" alt="icon">
                        <span>{{ viewItem.tel }}</span>
                      </div>
                    </div>
                    <div v-if="viewItem.description">
                      {{ viewItem.description }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- no_result -->
                <div class="no_result" v-if="isNoResult">
                  <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult"/>
                  <p>No Result found</p>
                  <span>If you do not find what you are looking for, please</span>
                  <div class="where_noresult">
                    <div class="more-box contact">
                      <a href="support/contactus">
                        <button>Contact Us</button>
                      </a>
                    </div>
                    <b>or</b>
                    <div class="more-box contact">
                      <a href="support/sales-representative">
                        <button>Find a Sales Representative</button>
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="where-site-map">
            <div>
              <div id="map" class="map-sticky"></div>
            </div>
          </div>
        </div>
        <div class="where-toggle"></div>
      </div>
      <!-- //container (Where to Buy) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import supportService from '@/server/support.service'
import usService from '@/server/us.service'
import _ from '@/common/helper/lodash.js'
import { $utils } from '@/common/helper'
import metaService from '@/server/meta.service'
import QuickMenuComponent from '@/components/Layout/Quick'
import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'whereToBuy',
  components: { HeaderComponent, FooterComponent, GnbComponent, QuickMenuComponent },
  data () {
    return {
      map: null,
      mapApiKey: 'AIzaSyB2ooYRIpcXi_NqxCyaQ85apZkhUpGbRoI',
      geocoder: null,
      autocompleteService: null,
      postData: null,
      isSearch: false,
      isNoResult: false,
      customOverlay: null,
      options: {
        _US: {
          US: {
            zoom: 9,
            latitude: 37.09024,
            longitude: -95.712891
          },
          CA: {
            zoom: 9,
            latitude: 56.130366,
            longitude: -106.346771
          }
        },
        _GL: {
          GL: {
            zoom: 2,
            latitude: 37.09024,
            longitude: -95.712891
          }
        },
        selectSearchProductCategory: [],
        searchProductCategory: [
          {
            name: 'HFLOR',
            value: `PRD_FLOORING${this.$countryCode}`
          },
          {
            name: 'VIATERA',
            value: `VIATERA_COLOR${this.$countryCode}`
          },
          {
            name: 'HIMACS',
            value: `HIMACS_COLOR${this.$countryCode}`
          },
          {
            name: 'TERACANTO',
            value: `TERACANTO_COLOR${this.$countryCode}`
          }
        ],
        selectSearchDealerType: [],
        searchDealerType: [
          {
            name: 'Dealer',
            type: 'GL,US',
            value: 'dlr_001',
            desc: 'Find LX Hausys product locally through our network of North American dealers.'
          },
          {
            name: 'Distributor',
            type: 'US',
            value: 'dlr_004',
            desc: 'Contact a distributor in your area who carries & stocks HFLOR products.'
          },
          {
            name: 'Home Center',
            type: 'US',
            value: 'dlr_002',
            desc: 'View a vast selection of LX Hausys products at a <a href="/us/homecenter/viatera" style="color: blue;text-decoration: underline;">Home Center</a> store near you.'
          },
          {
            name: 'Retail Store',
            type: 'US',
            value: 'dlr_005',
            desc: 'Explore HFLOR product options at flooring retailers throughout the U.S.'
          },
          {
            name: 'Showroom',
            type: 'US',
            value: 'dlr_003',
            desc: 'Visit a LX Hausys showroom to experience our products in-person.'
          },
          {
            name: 'Redirect',
            type: 'GL',
            value: 'dlr_006',
            desc: 'Guides users to localized websites for relevant LX Hausys product information.'
          }
        ],
        isSelectHflor: false,
        styles: {
          default: [],
          silver: [
            {
              elementType: 'geometry',
              stylers: [{ color: '#f5f5f5' }]
            },
            {
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [{ color: '#616161' }]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#f5f5f5' }]
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#bdbdbd' }]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [{ color: '#eeeeee' }]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#757575' }]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{ color: '#e5e5e5' }]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9e9e9e' }]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ color: '#ffffff' }]
            },
            {
              featureType: 'road.arterial',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#757575' }]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{ color: '#dadada' }]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#616161' }]
            },
            {
              featureType: 'road.local',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9e9e9e' }]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry',
              stylers: [{ color: '#e5e5e5' }]
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [{ color: '#eeeeee' }]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#c9c9c9' }]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9e9e9e' }]
            }
          ]
        }
      },
      images: {
        dlr_001: {
          imgNm: 'hausys/US/contents/icon/dealer/icon_WhereToBuy_Dealers.v1.png'
        },
        dlr_002: {
          imgNm: 'hausys/US/contents/icon/dealer/icon_WhereToBuy_Home Center.png'
        },
        dlr_003: {
          imgNm: 'hausys/US/contents/icon/dealer/icon_WhereToBuy_Showroom.svg'
        },
        dlr_004: {
          imgNm: 'hausys/US/contents/icon/dealer/icon_WhereToBuy_Distributor.svg'
        },
        dlr_005: {
          imgNm: 'hausys/US/contents/icon/dealer/icon_WhereToBuy_Retail Store.v1.png'
        },
        dlr_006: {
          imgNm: 'hausys/GL/contents/icon/dealer/icon_WhereToBuy_Redirect.svg'
        },
        dlr_007: { /* eu 추가 */
          imgNm: 'hausys/GL/contents/icon/dealer/icon-where-to-buy-homedepot.svg'
        }
      },
      searchValue: {
        mile: 10,
        keyword: '',
        latitude: 0,
        longitude: 0,
        areas: [],
        countries: [],
        stateCity: [],
        states: {
          US: [],
          CA: [],
          99: []
        },
        stateCd: '',
        storeChecked: [],
        dealTypChkCnt: {
          dlr_001: 0,
          dlr_002: 0,
          dlr_003: 0,
          dlr_004: 0,
          dlr_005: 0,
          dlr_006: 0,
          dlr_007: 0
        },
        categoryCode: '',
        areaCd: ''
      },
      form: {
        hflorStores: [],
        himacsStores: [],
        viateraStores: [],
        viewStores: [],
        storeTypeViewFlag: false,
        selectedCountry: this.$t('wheretobuy.text.FC0030_007'),
        selectedArea: this.$t('wheretobuy.text.FC0030_006'),
        searchPlaceHolder: this.$t('wheretobuy.text.FC0030_006'),
        selectedAreaCountry: '',
        selectedStateCity: 'Selected State/City'
      },
      oldIdx: 0,
      prevCateCode: '',
      routeParam: null,
      markers: [],
      viewList: [],
      orgViewList: [],
      metaTitle: '',
      metaDescription: '',
      countryCode: '',
      countryCodeSub: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
          el.style.cursor = 'default'
        })
      }
    }
  },

  watch: {
    'options.selectSearchDealerType' (newVal) {
      console.log('Selected StoreType:', newVal)
    },
    'options.selectSearchProductCategory' (newVal) {
      console.log('Selected StoreType:', newVal)
      this.options.isSelectHflor = _.includes(newVal, `PRD_FLOORING${this.$countryCode}`)
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)

    // route로부터 전달받는 값.
    this.routeParam = this.$route.params
    this.routeParam.country = t.countryCodeSub
    const added = this.$countryCode// 국가코드
    const _this = this
    let type = ''
    if (location.pathname.indexOf('/whereToBuy/country') !== -1 && added === '_US') {
      // 신규 seo url일 경우
      if (this.routeParam.country === 'us') {
        this.routeParam.country = 'US'
      }
    }

    // 판매처종류 목록
    const postData = {}
    postData.codes = ['DLR_TYPE_COUNTRY' + added]
    postData.siteType = [added]

    const resPageCont = await supportService.getPageContentWtb(postData)
    this.form.hflorStores = resPageCont.data.hflorList
    this.form.himacsStores = resPageCont.data.himacsList
    this.form.viateraStores = resPageCont.data.viateraList
    if (this.routeParam.country === 'US' || this.routeParam.country === 'GL') {
      // store 종류 표시
      this.form.viewStores = this.form.viateraStores
    } else {
      // store 종류 표시
      this.form.viewStores = this.form.hflorStores
    }

    // 국가코드, 주코드
    this.searchValue.stateCity = [
      { stateCityCd: 'STATECITY', stateCityNm: 'State/City' },
      { stateCityCd: 'ZIPCODE', stateCityNm: 'ZipCode' }
    ]
    if (this.routeParam.country === 'US') {
      this.searchValue.countries = [
        { countryCd: 'US', countryNm: 'USA' }
      ]
      this.form.selectedCountry = _.find(this.searchValue.countries, { countryCd: this.routeParam.country }).countryNm
      this.form.selectedStateCity = this.searchValue.stateCity[0].stateCityNm
      // this.form.selectedStateCity = '';
    } else {
      this.searchValue.areas = resPageCont.data.areaList

      if (this.routeParam.country !== 'GL') {
        this.form.selectedArea = _.find(this.searchValue.areas, { areaCd: this.routeParam.country }).areaName
      }
      this.searchValue.areaCd = this.routeParam.country
      if (this.routeParam.country === 'CN' || this.routeParam.country === 'EU' || this.routeParam.country == 'UK' || this.routeParam.country == 'RU' || this.routeParam.country == 'AM') {
        this.searchValue.countries = [{
          countryCd: 'NON',
          countryName: _.find(this.searchValue.areas, { areaCd: this.routeParam.country }).areaName
        }]
      }

      if (this.routeParam.country !== 'GL') {
        this.searchValue.categoryCode = 'PRD_FLOORING'
        this.getList()
      }
    }

    if (this.routeParam.country === 'US' || this.routeParam.country === 'CA') {
      const promiseArr = [
        usService.getStateList('US'),
        usService.getStateList('CA'),
        usService.getStateList('99')
      ]
      const stateRes = await Promise.all(promiseArr)
      this.searchValue.states.US = stateRes[0].data
      this.searchValue.states.CA = stateRes[1].data
      this.searchValue.states['99'] = stateRes[2].data
    }
    if (added === '_GL' || added === '_US') {
      if (added === '_US') {
        this.oldIdx = 1
      } else {
        this.oldIdx = 2
      }
    }
    _this.$nextTick(async function () {
      if (this.$countryCode === '_US') {
        type = 'US'
        const { data } = await metaService.getMeta('PAGE_META_WHERE_TO_BUY_' + type, '', 'US')
        _this.metaDescription = data.description
        _this.metaTitle = data.title
      }
    })
  },
  async mounted () {
    // info window는 vue의 외부에 있으므로 global function 지정.
    window.gotoUrl = this.gotoUrl
    window.gotoDirection = this.gotoDirection
    // 초기화
    this.init()
    // 지도 초기화
    this.initMap()
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    handleClickApply () {
      $('.store-type-wrap').removeClass('on')
      this.goSearch()
    },
    initMap () {
      const that = this
      const language = _.isEqual(that.$countryCode, '_US') ? 'en' : 'ko'
      const loader = new Loader({
        apiKey: that.mapApiKey,
        language
      })

      loader.load().then(async () => {
        const { Map } = await google.maps.importLibrary('maps')
        const { AutocompleteService } = await google.maps.importLibrary('places')

        // map 객체 세팅.
        if (that.$countryCode === '_US') {
          that.map = new Map(document.getElementById('map'), {
            center: {
              lat: that.options[that.$countryCode][that.routeParam.country].latitude,
              lng: that.options[that.$countryCode][that.routeParam.country].longitude
            },
            zoom: that.options[that.$countryCode][that.routeParam.country].zoom
          })
        } else {
          that.map = new Map(document.getElementById('map'), {
            center: {
              lat: 40.729257762411116,
              lng: -73.42451316033706
            },
            zoom: 5
          })
        }

        // 지도 이벤트
        that.map.addListener('idle', async () => {
          // 반경입력
          that.searchValue.mile = await that.getCalculateDistanceToMile()

          if (that.isSearch) {
            await that.getData()
            that.isSearch = false
          }
        })

        that.map.addListener('dragstart', () => {
          if (that.customOverlay) {
            that.customOverlay.setMap(null)
          }
        })

        // 위치 기반
        that.geocoder = new google.maps.Geocoder()
        const searchBox = new google.maps.places.SearchBox(document.getElementById('searchInput'))

        // 초기 컬러를 회색
        that.map.setOptions({ styles: this.options.styles.silver })

        let timer
        searchBox.addListener('places_changed', function () {
          window.clearTimeout(timer)
          timer = window.setTimeout(function () {
            const place = searchBox.getPlaces()
            if (place.length !== 0) {
              that.searchValue.keyword = place[0].formatted_address
            }
          }, 250)
        })
        // 주소
        that.autocompleteService = new AutocompleteService()

        that.checkLocationPermission()
      })
    },
    checkLocationPermission () {
      const that = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            that.searchValue.latitude = pos.lat
            that.searchValue.longitude = pos.lng

            that.map.setCenter(pos)
            that.map.setZoom(13)

            that.getList() // 현재 위치 기반으로 검색 실행
          },
          function (error) {
            // const pos = {
            //   lat: 40.729257762411116,
            //   lng: -73.42451316033706
            // }

            let pos = {
              lat: 26.2083785,
              lng: 50.6831642
            }
            if (that.$countryCode === '_US') {
              pos = {
                lat: 40.729257762411116,
                lng: -73.42451316033706
              }
            }

            that.searchValue.latitude = pos.lat
            that.searchValue.longitude = pos.lng

            that.map.setCenter(pos)
            that.map.setZoom(13)

            that.getList() // 현재 위치 기반으로 검색 실행
          }
        )
      } else {
        console.log('Geolocation is not supported by this browser.')
        // 지오로케이션을 지원하지 않는 브라우저의 경우 기본 위치로 검색
        that.getList()
      }
    },
    async init () {
      const that = this
      // jquery script
      this.$nextTick(function () {

      })
    },

    getTagName (tagItem, type) {
      const resultString = tagItem.includes('FLOORING') ? 'HFLOR' : tagItem.split(`_COLOR${this.$countryCode}`)[0]
      return type !== 'upper' ? resultString.toLowerCase() : resultString.toUpperCase()
    },

    goSearch () {
      const that = this
      this.geocoder.geocode({ address: this.searchValue.keyword }, function (results, status) {
        if (status === 'OK') {
          // 찾았을 경우, 해당 위치의 latitude, longtitude 를 hidden input에 저장한 후 이를 가지고 DB의 판매처 목록을 검색한다.
          const position = results[0].geometry.location
          // 주 정보 입력
          const state = _.filter(results[0].address_components, (v) => {
            if (_.indexOf(v.types, 'administrative_area_level_1') !== -1) {
              return v
            }
          })
          if (state.length > 0) {
            that.searchValue.stateCd = state[0].short_name
          } else {
            that.searchValue.stateCd = ''
          }
          console.log('lat : lng = ' + position.lat() + ' : ' + position.lng())
          // 좌표입력
          that.searchValue.latitude = position.lat()
          that.searchValue.longitude = position.lng()

          // 목록 출력
          that.getList()

          that.map.fitBounds(results[0].geometry.viewport)
        } else {
          if (that.searchValue.keyword.length != 0) {
            alert('No locations match your search. Please try again.')
          }
          that.searchValue.latitude = ''
          that.searchValue.longitude = ''
          that.searchValue.stateCd = ''
        }
      })
    },

    // 스토어타입 열기 닫기
    closeStoreType () {
      $('.store-type-wrap').removeClass('on')
    },
    openStoreType () {
      $('.store-type-wrap').addClass('on')
    },
    clickStoreTypeTit () {
      const storeFlag = $('.store-type-wrap').hasClass('on')
      if (storeFlag) {
        this.closeStoreType()
      } else {
        this.openStoreType()
      }
    },

    async getCalculateDistanceToMile () {
      const map = this.map

      // 현재 지도 경계 가져오기
      const bounds = map.getBounds()

      // 현재 지도 중심 가져오기
      const center = map.getCenter()

      // 경계의 오른쪽위 아래왼쪽 모서리 가져오기
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()

      // 가장 먼 모서리 오른쪽 위까지의 거리를 계산
      const { spherical } = await google.maps.importLibrary('geometry')
      const distanceToNE = spherical.computeDistanceBetween(center, ne)
      const distanceToSW = spherical.computeDistanceBetween(center, sw)

      // 중심에서 가장 먼 거리
      const maxDistance = Math.max(distanceToNE, distanceToSW)

      // 거리를 마일로 변환
      const distanceInMiles = maxDistance * 0.000621371

      console.log(`Distance: ${distanceInMiles.toFixed(2)} miles`)
      return distanceInMiles
    },

    async selectArea (areaCd, areaName) {
      this.form.selectedArea = areaName
      this.searchValue.areaCd = areaCd

      this.form.selectedCountry = this.$t('wheretobuy.text.FC0030_007')
      this.searchValue.stateCd = ''
      this.searchValue.countries = ''
      const stateList = await usService.getGLCountryList(areaCd)

      if (stateList.data.length === 0) {
        this.searchValue.countries = [{ countryCd: 'NON', countryName: areaName }]
      } else {
        this.searchValue.countries = stateList.data
      }

      // store type checkBox setting
      this.setStoreTypeChecked()
      this.getList()
    },
    clearDealerType () {
      this.options.selectSearchDealerType = []
    },
    getDealerTypeName (dealerType) {
      let resultString
      switch (dealerType) {
        case 'dlr_001':
          resultString = 'DEALER'
          break
        case 'dlr_002':
          resultString = 'Home Center'
          break
        case 'dlr_003':
          resultString = 'Showroom'
          break
        case 'dlr_004':
          resultString = 'Distributor'
          break
        case 'dlr_005':
          resultString = 'Retail Store'
          break
        case 'dlr_006':
          resultString = 'Redirect'
          break
      }
      return resultString
    },
    getDealerType (dealerType) {
      const arr = _.concat(this.form.hflorStores, this.form.himacsStores)
      const found = _.find(arr, function (o) {
        return o.attribute.deal_val === dealerType
      })
      if (_.isUndefined(found)) {
        return ''
      } else {
        return found.content.title
      }
    },
    getStateNm (stateCd, countryCd) {
      const found = _.find(this.searchValue.states[countryCd], (v) => {
        return v.id.countryCd === countryCd && v.id.stateCd === stateCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.stateNm
    },
    getCountryNm (countryCd) {
      const found = _.find(this.searchValue.countries, (v) => {
        return v.countryCd === countryCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return `${found.countryNm},`
    },
    async getList () {
      const that = this
      const idx = 0

      that.isSearch = true
      that.isNoResult = true

      // 검색데이터 세팅
      that.postData = {}
      that.postData.categoryCode = this.searchValue.categoryCode
      that.postData.siteType = this.$countryCode.substring(1)

      // dealer type checkbox init
      const defaultCnt = {
        dlr_001: 0,
        dlr_002: 0,
        dlr_003: 0,
        dlr_004: 0,
        dlr_005: 0,
        dlr_006: 0,
        dlr_007: 0
      }
      _.assign(this.searchValue.dealTypChkCnt, { ...defaultCnt })

      // 컬러를 기본색
      this.map.setOptions({ styles: this.options.styles.default })
    },
    async getData () {
      const _this = this
      this.viewList = []
      this.searchValue.storeChecked = []
      this.postData.locLat = this.searchValue.latitude + ''
      this.postData.locLng = this.searchValue.longitude + ''
      this.postData.mileRange = this.searchValue.mile + ''
      this.postData.dealerTypes = this.options.selectSearchDealerType.join(',')
      this.postData.categoryCodes = this.options.selectSearchProductCategory.join(',')

      // 검색
      const res = await supportService.getStoreList(this.postData)

      // 조회된 목록을 보여주는 목록과 저장목록에 저장함.
      this.viewList = res.data.storeList
      this.orgViewList = res.data.storeList

      // 저장된 목록을 기준으로 맵 마커 작성
      this.clearOverlays()
      _.forEach(this.viewList, (v, idx) => {
        _this.markMap(v, idx)
        // 첫번째 검색결과일 경우 검색 맵 이동용 좌표값으로 저장
        if (!idx) {
          _this.searchValue.latitude = v.latitude
          _this.searchValue.longitude = v.longitude
        }
        idx++
      })

      // 마커 클러스터링
      if (this.markerClusterer) {
        await this.markerClusterer.clearMarkers();
      }
      this.markerClusterer = new MarkerClusterer({ map: this.map, markers: this.markers })

      this.setStoreTypeChecked()
      this.makeDataLayerForm()

      // 맨처음 index의 정보일 경우 지도에 바로 이동
      await this.goStore(0, this.searchValue.latitude, this.searchValue.longitude)
    },
    makeDataLayerForm () {
      const _this = this

      const info = {
        event: 'wherebuyComp',
        where_buy_search_con: document.getElementById('searchInput').value,
        where_buy_prd_category: _this.postData.categoryCodes ? _this.postData.categoryCodes.replace(/,/g, '|') : '',
        where_buy_result_cnt: _this.viewList.length
      }

      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push(info)
      }
    },
    makeDataListForm (data) {
      let location1 = ''
      let location2 = ''
      let categoryTitle = ''
      let storeType = ''
      if (this.$countryCode === '_US') {
        location1 = this.form.selectedCountry
        location2 = this.searchValue.keyword
      } else {
        location1 = this.form.selectedArea
        location2 = this.form.selectedCountry
      }
      if (this.searchValue.categoryCode === 'PRD_FLOORING') {
        categoryTitle = 'HFLOR'
      } else if (this.searchValue.categoryCode === 'VIATERA_COLOR') {
        categoryTitle = 'VIATERA'
      } else if (this.searchValue.categoryCode === 'HIMACS_COLOR') {
        categoryTitle = 'HIMACS'
      }
      if (this.searchValue.storeChecked.length > 0) {
        _.forEach(this.searchValue.storeChecked, function (v, index) {
          var typeText = ''
          if (v.valueOf() === 'dlr_001') {
            typeText = 'DEALER'
          } else if (v.valueOf() === 'dlr_002') {
            typeText = 'Home Center'
          } else if (v.valueOf() === 'dlr_003') {
            typeText = 'Showroom'
          } else if (v.valueOf() === 'dlr_004') {
            typeText = 'Distributor'
          } else if (v.valueOf() === 'dlr_005') {
            typeText = 'Retail Store'
          } else if (v.valueOf() === 'dlr_006') {
            typeText = 'Redirect'
          }
          if (index == 0) {
            storeType = typeText
          } else {
            storeType.concat(', ', typeText)
          }
        })
      }
      return JSON.stringify({
        where_buy_store_name: data.company,
        where_buy_store_address: data.addr1 + data.addr2,
        where_buy_location_1: location1,
        where_buy_location_2: location2,
        where_buy_prd_category: categoryTitle,
        where_buy_store_type: storeType
      })
    },
    setStoreTypeChecked () {
      // 저장된 목록에서 counting 된 delaer type checkbox용 count에 따라 checkbox check
      _.forIn(this.searchValue.dealTypChkCnt, (v, k) => {
        if (v > 0) {
          this.searchValue.storeChecked.push(k)
          $('#' + k).prop('disabled', false)
        } else {
          $('#' + k).prop('disabled', true)
        }
      })
    },
    gotoUrl (url) {
      if (url.length > 0) {
        if (!/^(http:|https:)/i.test(url)) {
          url = 'http://' + url
        }
        window.open(url, '_blank')
      }
    },
    async gotoDirection (latitude, longitude) {
      // geocoder를 통해 목적지의 진짜 주소를 알아냄.
      let curLocUrl = 'https://www.google.com/maps?saddr='
      const resArv = await this.geocoder.geocode({
        location: {
          lat: this.searchValue.latitude,
          lng: this.searchValue.longitude
        }
      })
      if (resArv.results.length === 0) {
        alert('cannot found direction')
        return
      }
      curLocUrl += resArv.results[0].formatted_address

      const resDest = await this.geocoder.geocode({ location: { lat: latitude, lng: longitude } })
      if (resDest.results.length === 0) {
        alert('cannot found direction')
        return
      }
      curLocUrl += '&daddr=' + resDest.results[0].formatted_address
      window.open(curLocUrl, '_blank')
    },
    async markMap (data, idx) {
      const that = this
      let location1 = ''
      let location2 = ''
      let categoryTitle = ''
      let storeType = ''
      if (this.$countryCode === '_US') {
        location1 = this.form.selectedCountry
        location2 = this.searchValue.keyword
      } else {
        location1 = this.form.selectedArea
        location2 = this.form.selectedCountry
      }
      if (this.searchValue.categoryCode === 'PRD_FLOORING') {
        categoryTitle = 'HFLOR'
      } else if (this.searchValue.categoryCode === 'VIATERA_COLOR') {
        categoryTitle = 'VIATERA'
      } else if (this.searchValue.categoryCode === 'HIMACS_COLOR') {
        categoryTitle = 'HIMACS'
      }
      if (this.searchValue.storeChecked.length > 0) {
        _.forEach(this.searchValue.storeChecked, function (v, index) {
          var typeText = ''
          if (v.valueOf() === 'dlr_001') {
            typeText = 'DEALER'
          } else if (v.valueOf() === 'dlr_002') {
            typeText = 'Home Center'
          } else if (v.valueOf() === 'dlr_003') {
            typeText = 'Showroom'
          } else if (v.valueOf() === 'dlr_004') {
            typeText = 'Distributor'
          } else if (v.valueOf() === 'dlr_005') {
            typeText = 'Retail Store'
          } else if (v.valueOf() === 'dlr_006') {
            typeText = 'Redirect'
          }
          if (!index) {
            storeType = typeText
          } else {
            storeType.concat(', ', typeText)
          }
        })
      }

      // const html = "<span style='font-size:30px'>" + data.company + '</span>'
      // const infowindow = new google.maps.InfoWindow({
      //   disableAutoPan: true,
      //   content: html,
      // });
      // 실제 마커 객체
      // const imagePath = this.$imagePath + '/' + that.images[data.dealerType].imgNm;
      const imagePath = 'https://img.lxhausys.com/public/images/map/map-icon.png'

      const iconObj = {
        url: imagePath, // url
        scaledSize: new google.maps.Size(50, 50), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
      }
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(data.latitude, data.longitude), // 전달받은 판매처 정보의 lat, lng 정보를 위치로 저장.
        map: that.map, // initMap function에서 선언된 map 객체.
        icon: iconObj // marker에 표시해줄 icon을 customize
      })

      // infowindow.open(that.map, marker);

      // 마커 객체에 이벤트 리스너를 배정한다. (실제자체로는 click 이벤트를 받는 방법이 없는 듯)
      marker.addListener('click', function () {
        // 기존의 정보팝업을 지움.
        $('.gm-style-iw-a').parent().hide()

        // 마커의 정보 팝업 열기. map객체와 상단에 선언된 marker 객체를 전달.
        // infowindow.open(that.map, marker);

        // map객체에 판매처 목록의 lat, lng 정보를 넣어 지도 중앙으로 이동.
        that.map.setCenter({ lat: data.latitude, lng: data.longitude })

        $('.store-box').removeClass('on')// store-box 전체 on 제거

        const rstht = 250
        const rstnb = idx
        // 기준치 * index로 높이 찾기.
        $('.wtb-result-box').animate({ scrollTop: rstht * rstnb }, 300)
        $('.store-box').eq(rstnb).addClass('on')

        that.showCustomOverlay(data, marker.getPosition())
      })

      // marker 객체를 markers array에 저장.
      this.markers.push(marker)
    },
    async goStore (idx, latitude, longitude, isManualZoom) {
      // idx는 markers array에 저장된 marker객체를 index 순으로 가져옴.
      this.map.setCenter({ lat: latitude, lng: longitude })
      if (isManualZoom) {
        this.map.setZoom(13)
      }
      await google.maps.event.trigger(this.markers[idx], 'click')
    },
    clearOverlays () {
      _.forEach(this.markers, (v) => {
        v.setMap(null)
      })
      if (this.customOverlay) {
        this.customOverlay.setMap(null)
      }
      this.markers = []
    },
    setLocation () {
      // use my current location 버튼을 클릭시 현재 본인의 위치로 세팅한 후 매장목록을 다시 불러온다.
      const that = this
      // const infoWindow = new google.maps.InfoWindow()
      if (navigator.geolocation) { // 구글에서 위치정보를 획득하려 하는 걸 ok 해야만 가능.
        navigator.geolocation.getCurrentPosition(function (position) {
          console.log(position)
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          that.searchValue.latitude = pos.lat
          that.searchValue.longitude = pos.lng
          that.searchValue.keyword = ''

          that.map.setCenter({ lat: pos.lat, lng: pos.lng })
          that.map.setZoom(13)

          that.getList()
        }, function (e) {
          const str = that.$t('wheretobuy.alert.FC0030_003')
          alert(str)
        })
      } else {
        const str = that.$t('wheretobuy.alert.FC0030_004')
        alert(str)
      }
    },
    isEmpty (val) {
      return $utils.isEmpty(val)
    },

    showCustomOverlay (data, position) {
      const _this = this

      if (_this.customOverlay) {
        _this.customOverlay.setMap(null)
      }

      _this.customOverlay = new google.maps.OverlayView()
      _this.customOverlay.onAdd = function () {
        const exportZipCode = data.addr1.match(/\b\d{5}\b/) ? '<span>' + data.addr1.match(/\b\d{5}\b/)[0] + '</span>' : '';
        const div = document.createElement('div')
        div.className = 'map-marker'
        div.innerHTML = `<div class="map-marker-card">
          <div class="store-card">
          <div>
          <div class="profile">
          <img src="https://img.lxhausys.com/public/images/map/ico_person.png" alt="icon">
          <p>${_this.getDealerTypeName(data.dealerType)}</p>
          </div>
          <div class="store-more">
          <div>
          <p>${data.company}</p>
          <p>${data.addr1} ${data.addr2 || ''}</p>
          ${!isEmpty(_this.getCountryNm(data.countryCd)) ? '<span>' + _this.getCountryNm(data.countryCd) + ' ' + data.city + '</span>' : ''}
          ${!isEmpty(data.zipCd) ? '<span>' + data.zipCd + '</span>' : exportZipCode }
          <div class="store-more-phone">
          <img src="https://img.lxhausys.com/public/images/map/ico_phone.png" alt="icon">
          <span>${data.tel}</span>
          </div>
          </div>
          </div>
          </div>
          </div>
          <div class="map-buttons">
          <a class="map-button-visit" onclick="gotoUrl('${data.url}')">Visit Website</a>
          <a class="map-button-direc" onclick="gotoDirection(${data.latitude},${data.longitude})">Directions</a>
          </div>
          </div>`

        if (isEmpty(data.url)) {
          div.querySelector('.map-button-visit').classList.add('d-none')
        }

        this.div = div

        const panes = this.getPanes()
        panes.floatPane.appendChild(div)
      }

      _this.customOverlay.draw = function () {
        const overlayProjection = this.getProjection()
        const sw = overlayProjection.fromLatLngToDivPixel(position)
        const div = this.div
        div.style.left = sw.x + 'px'
        // div.style.top = sw.y + 'px'
        div.style.top = (sw.y - 375) + 'px' // 마커 보다 위에 나오기 위해 450 추가
      }

      _this.customOverlay.onRemove = function () {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }

      _this.customOverlay.setMap(_this.map)
    }
  },
  beforeDestroy () {
    if (this.scrollListener) {
      window.removeEventListener('scroll', this.scrollListener)
    }
    if (this.customOverlay) {
      this.customOverlay.setMap(null)
    }
  },
  updated () {
    // 검색전에 store type checkbox disable
    const that = this
    this.$nextTick(function () {
      if (!that.storeTypeViewFlag) {
        $('.chk-list .over-box li input:checkbox').each(function () {
          $(this).prop('disabled', true)
        })

        that.storeTypeViewFlag = true
      }
    })
  }
}

$(document).ready(function () {
  // $('.store-type-title').click(function () {
  //   $(this).toggleClass('on')
  //   $('.store-type.box').toggleClass('on')
  // })
  $('.product-select-title').click(function () {
    $(this).toggleClass('on')
    $('.product-select').toggleClass('on')
  })
  $('.where-toggle').click(function () {
    $(this).toggleClass('active')
    $('.where-site-wrap').toggleClass('active')
  })
})
</script>
