<template>
  <OverviewSection title="" v-if="nationCode === '_US' || nationCode === '_GL'">
    <!-- 바로 텍스트부터 시작 layer 출력 -->
    <div class="main-text-area">
      <pre class="ctt-t2">
LX Hausys operates 6 overseas sales corporations, 3 overseas manufacturing corporations, and 1 overseas representative office, mainly in the U.S., China, and Europe, as well as the domestic headquarters and manufacturing plants.
      </pre>
      <pre class="ctt-t2">
        We are pursuing a customer-oriented marketing strategy in the key markets including the U.S., China, and Europe. We are focusing on expanding our global network by localizing our products with the manufacturing and sales bases located in the U.S. and China.
      </pre>
    </div>

    <OverviewSectionItem>
      <div class="map-area">
        <img src="images/aboutus/overview/global_network/global_network_map.png" alt="global map" />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="region-area">
        <RegionCard
          v-for="(it, idx) in items"
          :key="idx"
          :cardType="it.type"
          :iconTxt="it.iconTxt"
          :mapImageSrc="it.mapImageSrc"
          :title="it.title"
          :desc="it.desc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection title="" v-else-if="nationCode === '_JP'">
    <!-- 바로 텍스트부터 시작 layer 출력 -->
    <div class="main-text-area">
      <pre class="ctt-t2">
        LXハウシスは、国内本社・製造工場のほか、米国、中国、欧州を中心に海外販売法人6社、海外製造法人3社、海外駐在員事務所1社を展開しています。米国、中国、欧州などの主要市場で顧客志向のマーケティング戦略を展開しています。
      </pre>
      <pre class="ctt-t2">
        米国と中国に製造・販売拠点を置き、製品の現地化を進め、グローバルネットワークの拡大に注力しています。      
      </pre>
    </div>

    <OverviewSectionItem>
      <div class="map-area">
        <img src="images/aboutus/overview/global_network/global_network_map.png" alt="global map" />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="region-area">
        <RegionCard
          v-for="(it, idx) in itemsJp"
          :key="idx"
          :cardType="it.type"
          :iconTxt="it.iconTxt"
          :mapImageSrc="it.mapImageSrc"
          :title="it.title"
          :desc="it.desc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection title="" v-else-if="nationCode === '_RU'">
    <!-- 바로 텍스트부터 시작 layer 출력 -->
    <div class="main-text-area">
      <pre class="ctt-t2">
        LX Hausys управляет 6 зарубежными торговыми корпорациями, 3 зарубежными производственными корпорациями и 1 зарубежным представительством, в США, Китае и Европе, а также головным офисом и производственными предприятиями внутри страны.
      </pre>
      <pre class="ctt-t2">
        Мы придерживаемся маркетинговой стратегии, ориентированной на клиента, на ключевых рынках, включая США, Китай и Европу. Мы стремимся расширить нашу глобальную сеть за счет локализации нашей продукции на производственных и торговых базах, расположенных в США и Китае.
      </pre>
    </div>

    <OverviewSectionItem>
      <div class="map-area">
        <img src="images/aboutus/overview/global_network/global_network_map.png" alt="global map" />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="region-area">
        <RegionCard
          v-for="(it, idx) in itemsRu"
          :key="idx"
          :cardType="it.type"
          :iconTxt="it.iconTxt"
          :mapImageSrc="it.mapImageSrc"
          :title="it.title"
          :desc="it.desc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection title="" v-else-if="nationCode === '_CN'">
    <!-- 바로 텍스트부터 시작 layer 출력 -->
    <div class="main-text-area">
      <pre class="ctt-t2">
        LX Hausys不仅止步于韩国总部和生产工厂，还延伸至海外。通过在北美、亚洲、欧洲等区域设立1家海外分支机构、6家销售法人以及3家生产法人，实现了全球性链接。
      </pre>
      <pre class="ctt-t2">
        还在美国、中国和欧洲等主要市场奉行以客户为中心的营销战略，已经在美国和中国建立了生产和销售基地, 致力于通过产品本地化扩大全球网络。
      </pre>
    </div>

    <OverviewSectionItem>
      <div class="map-area">
        <img src="images/aboutus/overview/global_network/global_network_map.png" alt="global map" />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="region-area">
        <RegionCard
          v-for="(it, idx) in itemsCn"
          :key="idx"
          :cardType="it.type"
          :iconTxt="it.iconTxt"
          :mapImageSrc="it.mapImageSrc"
          :title="it.title"
          :desc="it.desc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import RegionCard from '../components/RegionCard.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    RegionCard
  },

  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      items: [
        {
          type: 'vertical',
          iconTxt: 'A',
          mapImageSrc: 'images/aboutus/overview/global_network/map_ko.png',
          title: 'South Korea',
          desc: 'Our two manufacturing plants in Ulsan and Cheongju are producing world-class product. Also, we are leading the market across the globe as well as in Korea by operating a Design Center that sets the trend in design and an R&D Center to respond to future needs.'
        },
        {
          type: 'vertical',
          iconTxt: 'B',
          mapImageSrc: 'images/aboutus/overview/global_network/map_na.png',
          title: 'North America',
          desc: 'In North America, our focus lies in acrylic solid surface engineered stone and automotive interior leather, with our manufacturing plant situated in Georgia. In 2017, we established a sales corporation in Canada to solidify our growth momentum all throughout the North American market. In 2020, the expansion of the 3rd production line for engineered stone was completed for the improved production capacity and differentiated product development. Based on this, it is expected that LX Hausys will continue its rapid growth in the U.S. engineered stone market.'
        },
        {
          type: 'vertical',
          iconTxt: 'C',
          mapImageSrc: 'images/aboutus/overview/global_network/map_cn.png',
          title: 'China',
          desc: 'In China, we are conducting our business centered on flooring, industrial films, and automotive materials & components. Tiles, sheet-type flooring, and high-gloss films for home appliances occupy a high market share, which is expected to continue to grow in the future. Through the manufacturing plants located in Wuxi, we are providing products and services that are localized across the production to distribution, and are practicing environment and safety management by continuously improving the related systems and facilities in response to the increasingly stricter environment and safety regulations.'
        },
        {
          type: 'horizontal',
          iconTxt: 'D',
          mapImageSrc: 'images/aboutus/overview/global_network/map_eu.png',
          title: 'Europe',
          desc: 'In Europe, we’ve laid the foundation for our business focusing on acrylic solid surface and fu rniture film. In particular, we’ve increased our market share by reinforcing the market for commercial acrylic solid surface to boost growth. We plan to grow further, with the successfully launched PVC flooring as a driving force in the years to come.'
        },
        {
          type: 'text-only',
          iconTxt: 'E',
          mapImageSrc: '',
          title: 'Other Regions',
          desc: 'In emerging countries in the Middle East and Southeast Asia, acrylic solid surface, flooring, and industrial film businesses are growing in the market. LX Hausys provides products that meet the needs of various markets through differentiated marketing activities for each country.'
        }
      ],
      itemsJp: [
        {
          type: 'vertical',
          iconTxt: 'A',
          mapImageSrc: 'images/aboutus/overview/global_network/map_ko.png',
          title: '韓国',
          desc: '当社は蔚山(ウルサン)と清州(チョンジュ)の2つの製造工場で世界トップクラスの製品を生産しています。また、デザインのトレンドを先導するデザインセンターと将来のニーズに応えるR&Dセンターを運営し、韓国だけでなく世界の市場をリードしています。'
        },
        {
          type: 'vertical',
          iconTxt: 'B',
          mapImageSrc: 'images/aboutus/overview/global_network/map_na.png',
          title: '北米',
          desc: '北米では、アクリル系人工大理石、クォーツストーンと自動車内装レザーに注力しており、製造工場はジョージア州にあります。2017年には、北米市場全体での成長の勢いを強めるために、カナダに販売会社を設立しました。2020年には、生産能力の向上と差別化された製品開発のために、人工石の第3生産ラインの拡張が完了しました。これにより、LX Hausysは米国の人工石市場で急成長を続けることが期待されます。'
        },
        {
          type: 'vertical',
          iconTxt: 'C',
          mapImageSrc: 'images/aboutus/overview/global_network/map_cn.png',
          title: '中国',
          desc: '中国では、床材、産業用フィルム、自動車部品を中心に事業を展開しています。床タイル、床シート、家電向け高光沢フィルムは高いシェアを占めており、今後も成長が見込まれています。無錫にある製造工場では、生産から販売まで現地化した製品・サービスを提供しており、厳しさを増す環境・安全規制に対応して、関連するシステムや設備を継続的に改善し、環境・安全管理を実践しています。'
        },
        {
          type: 'horizontal',
          iconTxt: 'D',
          mapImageSrc: 'images/aboutus/overview/global_network/map_eu.png',
          title: 'ヨーロッパ',
          desc: '欧州では、アクリル系人工大理石と家具用フィルムを軸に事業基盤を構築し、特に人工大理石の市場強化によりシェアを拡大し、成長を牽引してきました。今後は、上市に成功した床材を原動力に、さらなる成長を目指します。'
        },
        {
          type: 'text-only',
          iconTxt: 'E',
          mapImageSrc: '',
          title: 'その他の地域',
          desc: '中東や東南アジアなどの新興国では、アクリル系人工大理石、床材、産業用フィルム事業が市場拡大しています。LXハウシスは、国ごとに差別化されたマーケティング活動を通じて、さまざまな市場のニーズに応える製品を提供しています。'
        }
      ],
      itemsRu: [
        {
          type: 'vertical',
          iconTxt: 'A',
          mapImageSrc: 'images/aboutus/overview/global_network/map_ko.png',
          title: 'Южная Корея',
          desc: 'Два наших завода в Ульсане и Чонджу выпускают продукцию мирового класса. Кроме того, мы занимаем лидирующие позиции на рынке по всему миру и в Корее благодаря наличию дизайнерского центра, задающего тенденции в дизайне, и центра исследований и разработок, отвечающего будущим потребностям.'
        },
        {
          type: 'vertical',
          iconTxt: 'B',
          mapImageSrc: 'images/aboutus/overview/global_network/map_na.png',
          title: 'Северная Америка',
          desc: 'В Северной Америке мы специализируемся на производстве акрилового камня и кожи для салонов автомобилей, а наш производственный завод расположен в Джорджии. В 2017 году мы создали торговую корпорацию в Канаде, чтобы укрепить наш импульс роста на всем североамериканском рынке. В 2020 году было завершено расширение 3-й производственной линии по выпуску искусственного камня для улучшения производственных мощностей и разработки дифференцированной продукции. Ожидается, что LX Hausys продолжит стремительный рост на американском рынке искусственного камня.'
        },
        {
          type: 'vertical',
          iconTxt: 'C',
          mapImageSrc: 'images/aboutus/overview/global_network/map_cn.png',
          title: 'Китай',
          desc:'В Китае мы ведем бизнес, ориентируясь на напольные покрытия, пленки для ламинации, автомобильные материалы и компоненты. Плитка, листовые напольные покрытия и пленки для бытовой техники занимают большую долю рынка, которая, как ожидается, будет расти и в будущем. Благодаря производственным предприятиям, расположенным в Уси, мы предоставляем продукцию и услуги, локализованные на всех этапах производства и сбыта, а также практикуем управление охраной окружающей среды и безопасностью, постоянно совершенствуя системы и оборудование в соответствии с ужесточающимися нормами охраны окружающей среды и безопасности.'
        },
        {
          type: 'horizontal',
          iconTxt: 'D',
          mapImageSrc: 'images/aboutus/overview/global_network/map_eu.png',
          title: 'Европа',
          desc: 'В Европе мы заложили основу нашего бизнеса, сосредоточившись на производстве акрилового камня и мебельной пленки. В частности, мы увеличили свою долю на рынке, укрепив рынок акрилового камня. В ближайшие годы мы планируем дальнейший рост за счет успешно запущенного производства напольных покрытий из ПВХ.'
        },
        {
          type: 'text-only',
          iconTxt: 'E',
          mapImageSrc: '',
          title: 'Другие регионы',
          desc: 'В развивающихся странах Ближнего Востока и Юго-Восточной Азии растет рынок акрилового камня, напольных покрытий и пленок для ламинации. LX Hausys предлагает продукцию, отвечающую потребностям различных рынков, благодаря дифференцированной маркетинговой деятельности для каждой страны.'
        }
      ],
      itemsCn: [
        {
          type: 'vertical',
          iconTxt: 'A',
          mapImageSrc: 'images/aboutus/overview/global_network/map_ko.png',
          title: 'South Korea',
          desc: 'Our two manufacturing plants in Ulsan and Cheongju are producing world-class product. Also, we are leading the market across the globe as well as in Korea by operating a Design Center that sets the trend in design and an R&D Center to respond to future needs.'
        },
        {
          type: 'vertical',
          iconTxt: 'B',
          mapImageSrc: 'images/aboutus/overview/global_network/map_na.png',
          title: 'North America',
          desc: 'In North America, our focus lies in acrylic solid surface engineered stone and automotive interior leather, with our manufacturing plant situated in Georgia. In 2017, we established a sales corporation in Canada to solidify our growth momentum all throughout the North American market. In 2020, the expansion of the 3rd production line for engineered stone was completed for the improved production capacity and differentiated product development. Based on this, it is expected that LX Hausys will continue its rapid growth in the U.S. engineered stone market.'
        },
        {
          type: 'vertical',
          iconTxt: 'C',
          mapImageSrc: 'images/aboutus/overview/global_network/map_cn.png',
          title: 'China',
          desc: 'In China, we are conducting our business centered on flooring, industrial films, and automotive materials & components. Tiles, sheet-type flooring, and high-gloss films for home appliances occupy a high market share, which is expected to continue to grow in the future. Through the manufacturing plants located in Wuxi, we are providing products and services that are localized across the production to distribution, and are practicing environment and safety management by continuously improving the related systems and facilities in response to the increasingly stricter environment and safety regulations.'
        },
        {
          type: 'horizontal',
          iconTxt: 'D',
          mapImageSrc: 'images/aboutus/overview/global_network/map_eu.png',
          title: 'Europe',
          desc: 'In Europe, we’ve laid the foundation for our business focusing on acrylic solid surface and fu rniture film. In particular, we’ve increased our market share by reinforcing the market for commercial acrylic solid surface to boost growth. We plan to grow further, with the successfully launched PVC flooring as a driving force in the years to come.'
        },
        {
          type: 'text-only',
          iconTxt: 'E',
          mapImageSrc: '',
          title: 'Other Regions',
          desc: 'In emerging countries in the Middle East and Southeast Asia, acrylic solid surface, flooring, and industrial film businesses are growing in the market. LX Hausys provides products that meet the needs of various markets through differentiated marketing activities for each country.'
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.main-text-area {
  display: flex;
  flex-direction: column;
  align-items: center;font-family: "NotoSans" , sans-serif;
}

.main-text-area pre.ctt-t2 {
  font-weight: 400;
  white-space: pre-wrap;
  text-align: center;
  max-width: 1080px;font-family: "NotoSans" , sans-serif;line-height: 1.5;
}

.map-area {
  max-width: 1440px;
}

.region-area {
  width: 1440px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px 30px;
}

@media (max-width: 768px) {
  .region-area {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
}

</style>
