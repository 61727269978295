var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ProductCarousel",
        { attrs: { autoplay: true, speed: 1000 } },
        _vm._l(_vm.itemInfo, function (it, idx) {
          return _c("FilmItem", {
            key: idx,
            attrs: {
              title: it.title,
              description: it.description,
              layerImageSrc: it.layerImageSrc,
              exampleImageSrc: it.exampleImageSrc,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }