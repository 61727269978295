var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thumb-list-area" },
    _vm._l(_vm.images, function (it, idx) {
      return _c(
        "div",
        {
          key: idx,
          staticClass: "item",
          on: {
            click: () => {
              _vm.onClick(idx)
            },
          },
        },
        [
          _vm.isActive(idx)
            ? _c("div", { staticClass: "active" }, [_c("img")])
            : _vm._e(),
          _c("img", { attrs: { src: it } }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }