var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    staticStyle: { display: "block", width: "100vw", height: "100vh" },
    attrs: {
      src: "https://indd.adobe.com/view/c152b365-7140-4a7e-9f51-cde5fcd311d3?startpage=1&allowFullscreen=false",
      frameborder: "0",
      allowfullscreen: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }