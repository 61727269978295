<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content pros-view">

      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li v-if="nationCode == '_US' || nationCode == '_GL'">Pros</li>
          <li v-if="nationCode != '_US' && nationCode != '_GL'">{{ $t('forfabricator.Text.Pros')}}</li>
          <li v-if="nationCode == '_US' || nationCode == '_GL'">For Fabricator</li>
          <li v-if="nationCode != '_US' && nationCode != '_GL'">{{ $t('forfabricator.Text.for_fabricator')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (For Fabricator) -->
      <!-- US | GL s -->
      <div class="container for-box cont-fabri" v-if="nationCode == '_US' || nationCode == '_GL'">
        <div class="w-con-02">
          <h1 class="tt-1 tac">{{ $t('forfabricator.Text.for_fabricator')}} </h1>
          <div class="tab-ui tab-ui-dark">
            <ul class="btns02">
              <li class="btn-tab on" id="btn-tab01" data-tab="tab01"><button type="button">VIATERA</button></li>
              <li class="btn-tab" id="btn-tab02" data-tab="tab02"><button type="button">HIMACS</button></li>
            </ul>

            <!-- Viatera -->
            <div class="tab-con tab01 on inner">
              <div class="fabri-editor-box" v-html="viateraList.attribute.editor1">

              </div>
              <div class="thum-box">
                <p class="tt-sub-gray">Benefits of becoming a LX Hausys Fabricator:</p>
                <ul class="ul-for-fabricator">
                  <li>
                    <div class="img-box">
                      <img :src="$imagePath+viateraList.attribute.benefits_img1" alt="PLACED ON THE FABRICATOR LOCATOR">
                    </div>
                    <div class="txt-box">
                      {{ viateraList.attribute.benefits_text1 }}
                    </div>
                  </li>
                  <li>
                    <div class="img-box">
                      <img :src="$imagePath+viateraList.attribute.benefits_img2" alt="PLACED ON THE FABRICATOR LOCATOR">
                    </div>
                    <div class="txt-box">
                      {{ viateraList.attribute.benefits_text2 }}
                    </div>
                  </li>
                  <li>
                    <div class="img-box">
                      <img :src="$imagePath+viateraList.attribute.benefits_img3" alt="PLACED ON THE FABRICATOR LOCATOR">
                    </div>
                    <div class="txt-box">
                      {{ viateraList.attribute.benefits_text3 }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="info-box col-02">
                <div class="txt-box" v-html="viateraList.attribute.editor2">

                </div>
                <div class="img-box">
                  <img :src="$imagePath+viateraList.attribute.fabricator_img" >
                </div>
              </div>
              <div class="guide-box">
                <p class="tit-sub ta-l">Fabrication Guide</p>
                <div class="g-list col-02">
                  <div class="bd-gray" v-if="viateraList.attribute.fabrication_guide1Content != null && viateraList.attribute.fabrication_guide1Content != '' && viateraList.attribute.fabrication_guide1Content.content != null && viateraList.attribute.fabrication_guide1Content.content != '' ">
                    <strong>{{ viateraList.attribute.fabrication_guide1Content.content.title }}.pdf</strong>
                    <div class="col-02">
                      <div class="txt-box">
                        <div class="t-type-01">
                          {{ viateraList.attribute.fabrication_guide1Content.attribute.doctypeText }}
                        </div>
                        <button type="button" @click="downloadFile(viateraList.attribute.fabrication_guide1Content.attribute.doc_file)" >Download</button>
                      </div>
                      <div class="img-box">
                        <img src="images/pros/@img_03.png" alt="2021 Viatera Fabrication Guide" />
                      </div>
                    </div>
                  </div>
                  <div class="bd-gray" v-if="viateraList.attribute.fabrication_guide2Content != null && viateraList.attribute.fabrication_guide2Content != '' && viateraList.attribute.fabrication_guide2Content.content != null && viateraList.attribute.fabrication_guide2Content.content != '' ">
                    <strong>{{ viateraList.attribute.fabrication_guide2Content.content.title }}.pdf</strong>
                    <div class="col-02">
                      <div class="txt-box">
                        <div class="t-type-01">
                          {{ viateraList.attribute.fabrication_guide2Content.attribute.doctypeText }}
                        </div>
                        <button type="button" @click="downloadFile(viateraList.attribute.fabrication_guide2Content.attribute.doc_file)" >Download</button>
                      </div>
                      <div class="img-box" v-if="viateraList.attribute.fabrication_guide2Content.attribute.doc_thumb == null || viateraList.attribute.fabrication_guide2Content.attribute.doc_thumb == '' ">
                        <img src="images/pros/@img_03.png" alt="2021 Viatera Fabrication Guide" />
                      </div>
                      <div class="img-box" v-else>
                        <img :src="viateraList.attribute.fabrication_guide2Content.attribute.doc_thumb"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //Viatera -->

            <!-- HIMACS -->
            <div class="tab-con tab02 inner">
              <div class="fabri-editor-box" v-html="himacsList.attribute.editor1">

              </div>
              <div class="thum-box">
                <p class="tt-sub-gray">Benefits of becoming a LX Hausys Fabricator:</p>
                <ul class="ul-for-fabricator">
                  <li>
                    <div class="img-box">
                      <img :src="$imagePath+himacsList.attribute.benefits_img1" alt="PLACED ON THE FABRICATOR LOCATOR">
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.benefits_text1 }}
                    </div>
                  </li>
                  <li>
                    <div class="img-box">
                      <img :src="$imagePath+himacsList.attribute.benefits_img2" alt="PLACED ON THE FABRICATOR LOCATOR">
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.benefits_text2 }}
                    </div>
                  </li>
                  <li>
                    <div class="img-box">
                      <img :src="$imagePath+himacsList.attribute.benefits_img3" alt="PLACED ON THE FABRICATOR LOCATOR">
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.benefits_text3 }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="info-box col-02">
                <div class="txt-box" v-html="himacsList.attribute.editor2"></div>
                <div class="img-box">
                  <img :src="$imagePath+himacsList.attribute.fabricator_img" alt="PLACED ON THE FABRICATOR LOCATOR">
                </div>
              </div>
              <div class="guide-box">
                <p class="tit-sub ta-l">Fabrication Guide</p>
                <div class="g-list col-02">
                  <div class="bd-gray"  v-if="himacsList.attribute.fabrication_guide1Content != null && himacsList.attribute.fabrication_guide1Content != '' && himacsList.attribute.fabrication_guide1Content.content != null && himacsList.attribute.fabrication_guide1Content.content != '' ">
                    <strong>{{ himacsList.attribute.fabrication_guide1Content.content.title }}.pdf</strong>
                    <div class="col-02">
                      <div class="txt-box">
                        <div class="t-type-01">
                          {{ himacsList.attribute.fabrication_guide1Content.attribute.doctypeText }}
                        </div>
                        <button type="button" @click="downloadFile(himacsList.attribute.fabrication_guide1Content.attribute.doc_file)" >Download</button>
                      </div>
                      <div class="img-box" v-if="himacsList.attribute.fabrication_guide1Content.attribute.doc_thumb == null || himacsList.attribute.fabrication_guide1Content.attribute.doc_thumb == '' ">
                        <img src="images/pros/@img_04.png" alt="2021 Viatera Fabrication Guide" />
                      </div>
                      <div class="img-box" v-else>
                        <img :src="himacsList.attribute.fabrication_guide1Content.attribute.doc_thumb" />
                      </div>
                    </div>
                  </div>
                  <div class="bd-gray" v-if="himacsList.attribute.fabrication_guide2Content != null && himacsList.attribute.fabrication_guide2Content != '' && himacsList.attribute.fabrication_guide2Content.content != null && himacsList.attribute.fabrication_guide2Content.content != '' ">
                    <strong>{{ himacsList.attribute.fabrication_guide2Content.content.title }}.pdf</strong>
                    <div class="col-02">
                      <div class="txt-box">
                        <div class="t-type-01">
                          {{ himacsList.attribute.fabrication_guide2Content.attribute.doctypeText }}
                        </div>
                        <button type="button" @click="downloadFile(himacsList.attribute.fabrication_guide2Content.attribute.doc_file)" >Download</button>
                      </div>
                      <div class="img-box" v-if="himacsList.attribute.fabrication_guide2Content.attribute.doc_thumb == null || himacsList.attribute.fabrication_guide2Content.attribute.doc_thumb == '' ">
                        <img src="images/pros/@img_04.png" alt="2021 Viatera Fabrication Guide" />
                      </div>
                      <div class="img-box" v-else>
                        <img :src="himacsList.attribute.fabrication_guide2Content.attribute.doc_thumb" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-text">
                <p class="tit-sub">HIMACS is not just another material</p>
                <div class="t-type-01">
                  One idea lay behind the development of HIMACS: what would it be like to make a material similar to stone available to carpenters? A material that can easily be worked using existing traditional woodworking tools. A material that enables craftsmen to translate the most exciting visions of designers and architects into reality.
                </div>
              </div>
              <div class="info-text">
                <p class="tit-sub">Starting to work with HIMACS is easy.</p>
                <div class="t-type-01">
                  As a woodworking expert, you only need to open your workshop to find everything you need: talented craftsmen and tools for working with hardwood. That's all you need to saw, drill and sand this material. And if you have a pre-heating oven or a heating press, then you'll also find it much easier to heat up the material for thermoforming. That's all there is to it: the carpentry workshop that once specialised in timber materials, is transformed in an instant into a company that can also offer modern stone products as well.
                </div>
              </div>
              <div class="vdo-list">
                <p class="tit-sub">HIMACS BRINGS SO MANY GREAT FEATURES</p>
                <ul class="ul-for-fabricator">
                  <li>
                    <div class="video">
                      <div class="video-container">
                        <iframe  class="result-iframe" autoplay="autoplay" muted="muted" controls :src="himacsList.attribute.himacs_youtube1" title="YouTube video player"/>
                      </div>
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.himacs_youtube_text1 }}
                    </div>
                  </li>
                  <li>
                    <div class="video">
                      <div class="video-container">
                        <iframe  class="result-iframe" autoplay="autoplay" muted="muted" controls :src="himacsList.attribute.himacs_youtube2" title="YouTube video player"/>
                      </div>
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.himacs_youtube_text2 }}
                    </div>
                  </li>
                  <li>
                    <div class="video">
                      <div class="video-container">
                        <iframe  class="result-iframe" autoplay="autoplay" muted="muted" controls :src="himacsList.attribute.himacs_youtube3" title="YouTube video player"/>
                      </div>
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.himacs_youtube_text3 }}
                    </div>
                  </li>
                  <li>
                    <div class="video">
                      <div class="video-container">
                        <iframe  class="result-iframe" autoplay="autoplay" muted="muted" controls :src="himacsList.attribute.himacs_youtube4" title="YouTube video player"/>
                      </div>
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.himacs_youtube_text4 }}
                    </div>
                  </li>
                  <li>
                    <div class="video">
                      <div class="video-container">
                        <iframe  class="result-iframe" autoplay="autoplay" muted="muted" controls :src="himacsList.attribute.himacs_youtube5" title="YouTube video player"/>
                      </div>
                    </div>
                    <div class="txt-box">
                      {{ himacsList.attribute.himacs_youtube_text5 }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- //HIMACS -->
          </div>
        </div>
      </div>
      <!-- //US | GL e -->

      <!-- EN | UK -->
      <div class="container for-box cont-fabri euSite" v-if="nationCode != '_US' && nationCode != '_GL'">
        <div class="w-con-01">
          <div class="w-con-02 inner">
            <h1 class="tt-1 tac">{{ $t('forfabricator.Text.for_fabricator')}}</h1>
            <!-- sub tab-ui -->
            <div id="demoTab3" class="tab-ui tab-ui-nav">
              <button type="button" class="arrow disabled tab-arr-prev">
                <span class="sr-only">이전</span>
              </button>
              <button type="button" class="arrow tab-arr-next">
                <span class="sr-only">다음</span>
              </button>
              <ul>
                <li class="btn-tab on" id="tab_fabrication" data-tab="fabrication">
                  <button type="button">{{ $t('forfabricator.button.Fabrication_tab')}}</button>
                </li>
                <li class="btn-tab" id="tab_workshops" data-tab="workshops">
                  <button type="button">{{ $t('forfabricator.button.Workshops_tab')}}</button>
                </li>
                <li class="btn-tab" id="tab_quality" data-tab="quality">
                  <button type="button">{{ $t('forfabricator.button.Quality_tab')}}</button>
                </li>
                <li class="btn-tab" id="tab_become" data-tab="become">
                  <button type="button">{{ $t('forfabricator.button.Become_tab')}}</button>
                </li>
                <li class="btn-tab" id="tab_warranty" data-tab="warranty">
                  <button type="button">{{ $t('forfabricator.button.Warranty_tab')}}</button>
                </li>
              </ul>
            </div>
            <!-- EOT sub tab-ui -->
          </div>

          <div id="fabrication" class="tab-con fabrication on">
            <div class="w-con-02 inner pt-0">
              <div v-html="FabricationList.editor"></div>

              <div class="forFabr-img">
                <img :src="'https://img.lxhausys.com'+ FabricationList.image" alt="images">
                <p class="comtt ctt ctt-t5" v-html="FabricationList.photo_credit"></p>
              </div>
            </div>
            <!-- slider -->
            <div id="page" class="hiforSlider inner">
              <p class="tt-sub-gray">{{ $t('forfabricator.Text.Fabrication_slider')}}</p>
              <div class="row">
                <div class="column small-11 small-centered">

                  <div class="slider slider-single"> <!-- 슬라이더 이미지 뿌려주는 곳 -->
                    <div v-for="fabricationList in FabricationList.color_image"><iframe  class="result-iframe" style="width:1110px; height: 616px;" autoplay="autoplay" muted="muted" controls :src="fabricationList.image" title="YouTube video player"/> </div>
                  </div>

                  <div class="slider slider-nav"> <!-- 슬라이더 썸네일 뿌려주는 곳 -->
                    <div v-for="fabricationList in FabricationList.color_image"><span><img :src="'https://img.lxhausys.com'+fabricationList.thumb" alt="thumb"> </span></div>
                  </div>

                </div>
              </div>
            </div>
            <!-- slider -->

          </div>

          <div id="workshops" class="tab-con workshops" >
            <div class="w-con-02 inner pt-0">

              <div v-html="WorkshopsList.editor"></div>

              <p class="ctt ctt-t4 txtunder" @click="gotoTab('become')" style="cursor:pointer" >{{ $t('forfabricator.button.workshops_link')}}</p>

              <div class="forFabr-img">
                <img :src="'https://img.lxhausys.com'+ WorkshopsList.image" alt="image">
                <p class="comtt ctt ctt-t5" v-html="WorkshopsList.photo_credit"></p>
              </div>
            </div>
          </div>

          <div id="quality" class="tab-con quality" >

            <div class="w-con-02 inner pt-0">

              <div v-html="QualityList.editor"></div>

              <div class="forFabr-img">
                <p class="qc">
                  <img src="images/pros/qc.png" alt="qc">
                </p>
                <img :src="'https://img.lxhausys.com'+ QualityList.image" alt="image">
                <p class="comtt ctt ctt-t5" v-html="QualityList.photo_credit"></p>
              </div>
            </div>
          </div>

          <div id="become" class="tab-con become" >
            <div class="w-con-02 inner pt-0">
<!--              <p class="tt-2">BECOME A HIMACS FABRICATOR/ INSTALLER NOW</p>-->
<!--              <p class="ctt ctt-t4">-->
<!--                HIMACS is continuously developing and offers customers throughout Europe a superior level of service with its network of professional partners. Joining the HIMACS network as a fabricator or installer means playing an active part in the rapid growth of the brand that was established in 2003, remaining competitive and offering your customers only the very best: a durable product with an exclusive installation warranty of 15 years, that will win over your customers with its appearance as well as its functionality right across the board.The versatile fabrication techniques of the material inspire craftsmen to try something new every time, to push back the boundaries of creativity.-->
<!--              </p>-->

               <div v-html="BecomeList.editor"></div>

              <ul class="becomeList">
                <li>
                  <dl>
                    <dt class="ctt ctt-t2">{{ $t('forfabricator.Text.becomeList_title1')}}</dt>
                    <dd class="ctt ctt-t4">{{ $t('forfabricator.Text.becomeList_brief1_1')}}<span style="cursor: pointer" class="ctt ctt-t4" onclick="location.href='support/contactus/himacs/request/become'">{{ $t('forfabricator.Text.becomeList_brief1_2')}}</span></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt class="ctt ctt-t2">{{ $t('forfabricator.Text.becomeList_title2')}}</dt>
                    <dd class="ctt ctt-t4">{{ $t('forfabricator.Text.becomeList_brief2_1')}}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt class="ctt ctt-t2">{{ $t('forfabricator.Text.becomeList_title3')}}</dt>
                    <dd class="ctt ctt-t4">
                      {{ $t('forfabricator.Text.becomeList_brief3_1')}}
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt class="ctt ctt-t2">{{ $t('forfabricator.Text.becomeList_title4')}}</dt>
                    <dd class="ctt ctt-t4">
                      {{ $t('forfabricator.Text.becomeList_brief4_1')}}
                      {{ $t('forfabricator.Text.becomeList_brief4_2')}}
                      <br>
                      <span class="ctt ctt-t4" style="cursor: pointer" @click="gotoTab('quality')">{{ $t('forfabricator.Text.becomeList_brief4_3')}}</span>
                    </dd>
                  </dl>
                </li>
              </ul>
              <div class="forFabr-img">
                <img :src="'https://img.lxhausys.com'+ BecomeList.image" alt="image">
                <p class="comtt ctt ctt-t5" v-html="BecomeList.photo_credit"></p>
              </div>
            </div>
          </div>

          <div id="warranty" class="tab-con warranty" >
            <div class="w-con-02 inner pt-0">

              <div v-html="WarrantyList.editor"></div>

              <div class="forFabr-img">
                <p class="qc">
                  <img src="images/pros/qc.png" alt="qc">
                </p>
                <img :src="'https://img.lxhausys.com'+ WarrantyList.image" alt="image">
                <p class="comtt ctt ctt-t5" v-html="WarrantyList.photo_credit"></p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- //EN | UK e -->
      <!-- //container (For Fabricator) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
</div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import _ from '@/common/helper/lodash'
import GnbComponent from '@/components/Layout/GNB'
import InfiniteLoading from 'vue-infinite-loading'
import global from '@/common/global'
import ProductService from '@/server/product.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    GnbComponent,
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      nationCode: [],
      categoryId: [],
      content22: [],
      viateraList: { attribute: { fabrication_guide1Content: { content: {}, attribute: { doctypeText: '' } }, fabrication_guide2Content: { content: {}, attribute: { doctypeText: '' } } } },
      himacsList: { attribute: { fabrication_guide1Content: { content: {}, attribute: { doctypeText: '' } }, fabrication_guide2Content: { content: {}, attribute: { doctypeText: '' } } } },

      FabricationList: { editor: '', image: '', photoCredit: '' },
      QualityList: { editor: '', image: '', photoCredit: '' },
      BecomeList: { editor: '', image: '', photoCredit: '' },
      WorkshopsList: { editor: '', image: '', photoCredit: '' },
      WarrantyList: { editor: '', image: '', photoCredit: '' }
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode

    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      const category = await ProductService.getCategoryCode('FAB' + t.nationCode)
      t.categoryId = category.data.object.id

      const content = await ProductService.getContentListChild(t.categoryId)

      _.forEach(content.data.list, (v) => {
        if (v.content.title == 'HIMACS Fabrication') {
          t.FabricationList = v.attribute
        }
        if (v.content.title == 'HIMACS Workshops') {
          t.WorkshopsList = v.attribute
        }
        if (v.content.title == 'HIMACS Quality Club') {
          t.QualityList = v.attribute
        }
        if (v.content.title == 'Become a HIMACS Fabricator / Installer Now') {
          t.BecomeList = v.attribute
        }
        if (v.content.title == 'Warranty') {
          t.WarrantyList = v.attribute
        }
      })
    }

    /* 불러와 지나 테스트 중 2021-11-26 */

    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      const category = await ProductService.getCategoryCode('FAB' + t.nationCode)
      t.categoryId = category.data.object.id

      const content = await ProductService.getContentListChild(t.categoryId)

      _.forEach(content.data.list, (v) => {
        if (v.content.title == 'Viatera') {
          t.viateraList = v
        }
        if (v.content.title == 'HIMACS') {
          t.himacsList = v
        }
      })

      /* 불러와 지나 테스트 중 2021-11-26 */
      const categoryName = this.$route.params.category_name

      if (categoryName == 'Viatera' || categoryName == 'HIMACS') {
        $('.btn-tab').removeClass('on')
        if (categoryName == 'Viatera') {
          this.$nextTick(function () {
            $('#btn-tab01').click()
          })
        }
        if (categoryName == 'HIMACS') {
          this.$nextTick(function () {
            $('#btn-tab02').click()
          })
        }
      }
    } // US || GL 조건 / URL PARAM

    if (t.nationCode != '_US' && t.nationCode != '_GL') {
      this.$nextTick(function () {
        $('.tab-ui-nav ul li').click(function () {
          var tab_id = $(this).attr('data-tab')

          $('.tab-ui-nav ul li').removeClass('on')
          $('.tab-con').removeClass('on')

          $(this).addClass('on')
          $('#' + tab_id).addClass('on')
        })

        var tabInstance = $('.tab-ui-nav')
        $.each(tabInstance, function (key, value) {
          var windowWidth = $(window).width()
          var arrows = $(tabInstance[key]).find('.arrow')
          var prevArrow = arrows.filter('.tab-arr-prev')
          var nextArrow = arrows.filter('.tab-arr-next')
          var box = $(tabInstance[key]).find('ul')
          var x = 0
          var mx = 0
          var maxScrollWidth = box[0].scrollWidth - windowWidth / 2 - box.width() / 2

          $(arrows).on('click', function () {
            if ($(this).hasClass('tab-arr-next')) {
              x = box.width() / 2 + box.scrollLeft()
              box.animate({
                scrollLeft: x
              })
            } else {
              x = box.width() / 2 - box.scrollLeft()
              box.animate({
                scrollLeft: -x
              })
            }
          })

          $(box).on({
            mousemove: function (e) {
              var mx2 = e.pageX - this.offsetLeft
              if (mx) this.scrollLeft = this.sx + mx - mx2
            },
            mousedown: function (e) {
              this.sx = this.scrollLeft
              mx = e.pageX - this.offsetLeft
            },
            scroll: function () {
              toggleArrows()
            }
          })

          $(document).on('mouseup', function () {
            mx = 0
          })

          function toggleArrows () {
            if (box.scrollLeft() > maxScrollWidth) {
              nextArrow.addClass('disabled')
            } else if (box.scrollLeft() < 5) {
              prevArrow.addClass('disabled')
            } else {
              // both are enabled
              nextArrow.removeClass('disabled')
              prevArrow.removeClass('disabled')
            }
          }
        })

        // tab
        $('.slider-single').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: false,
          adaptiveHeight: true,
          infinite: false,
          useTransform: true,
          speed: 400,
          cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)'
        })

        $('.slider-nav')
          .on('init', function (event, slick) {
            $('.slider-nav .slick-slide.slick-current').addClass('is-active')
          })
          .slick({
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: false,
            focusOnSelect: false,
            arrows: false,
            infinite: false,
            responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5
              }
            }, {
              breakpoint: 640,
              settings: {
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }, {
              breakpoint: 420,
              settings: {
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }]
          })

        $('.slider-single').on('afterChange', function (event, slick, currentSlide) {
          $('.slider-nav').slick('slickGoTo', currentSlide)
          var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]'
          $('.slider-nav .slick-slide.is-active').removeClass('is-active')
          $(currrentNavSlideElem).addClass('is-active')
        })

        $('.slider-nav').on('click', '.slick-slide', function (event) {
          event.preventDefault()
          var goToSingleSlide = $(this).data('slick-index')

          $('.slider-single').slick('slickGoTo', goToSingleSlide)
        })
      })
    }
  },

  methods: {
    downloadFile (file) {
      const url = this.$imagePath + file
      try {
        const element = document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        element.setAttribute('target', file)
        element.click()
      } catch (error) {
        console.log(error)
      }
    },
    gotoTab (content) {
      $('.tab-ui-nav ul li').removeClass('on')
      $('.tab-con').removeClass('on')

      $('#tab_' + content).addClass('on')
      $('#' + content).addClass('on')
    }
  }

}
</script>
<style scoped>
.pros-view {
  padding-bottom: 0;
}
.js .slider-single > div:nth-child(1n+2) { display: none }
.js .slider-single.slick-initialized > div:nth-child(1n+2) { display: block }

</style>
