var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Management Strategy", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  description:
                    "LX Hausys is conducting its business on the basis of the major strategic direction of\n “the advancement of the business portfolio and strengthening of business structure”.\nWe aim to prepare for the market changes and accomplish solid growth in the market by focusing on\nstrengthening its solutions for enhanced customer value, R&D based on market & customer needs,\nand activities for innovating manufacturing to achieve the highest level globally.",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "LX Hausys is conducting its business on the basis of the major strategic direction of\n“the advancement of the business portfolio and strengthening of business structure”.\nWe aim to prepare for the market changes and accomplish solid growth in the market by focusing on\nstrengthening its solutions for enhanced customer value, R&D based on market & customer needs,\nand activities for innovating manufacturing to achieve the highest level globally."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "経営戦略", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  description:
                    "LX Hausysは、「事業ポートフォリオの高度化と事業構造の強化」を主要戦略方針として\n掲げ、顧客価値向上のためのソリューション強化、市場・顧客ニーズに基づく研究開発、世界最高水準の\nモノづくり革新活動に注力し、市場変化に備え、市場での着実な成長を目指します。",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "        LX Hausysは、「事業ポートフォリオの高度化と事業構造の強化」を主要戦略方針として掲げ、顧客価値向上のためのソリューション強化、市場・顧客ニーズに基づく研究開発、世界最高水準のモノづくり革新活動に注力し、市場変化に備え、市場での着実な成長を目指します。"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Стратегия управления", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  description:
                    "LX Hausys ведет деятельность на основе основного стратегического направления\n«развитие бизнес-портфеля и укрепление структуры бизнеса».\nМы стремимся подготовиться к изменениям на рынке и добиться уверенного роста на нем, сосредоточившись на \nукреплении своих решений для повышения потребительской ценности, исследованиях и разработках, основанных на \nпотребностях рынка и клиентов, и деятельности по внедрению инноваций в производство для достижения \nнаивысшего уровня во всем мире.",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "        LX Hausys ведет деятельность на основе основного стратегического направления «развитие бизнес-портфеля и укрепление структуры бизнеса». Мы стремимся подготовиться к изменениям на рынке и добиться уверенного роста на нем, сосредоточившись на укреплении своих решений для повышения потребительской ценности, исследованиях и разработках, основанных на потребностях рынка и клиентов, и деятельности по внедрению инноваций в производство для достижения наивысшего уровня во всем мире."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "经营策略", color: "black" } },
        [
          !_vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  description:
                    "LX Hausys坚持以优化事业组合和强化事业本质为核心战略方向开展事业。建筑装饰材料事业方面\n      将进一步扩大韩国B2C业务和高附加值产品的销售。汽车材料配件和工业用薄膜事业方面将致力于强化竞争力\n      和改善收益性。LX Hausys将集中精力精力强化解决方案, 提高客户价值；开展以市场和客户需求为基础的研发\n      以及全球一流水平的制造创新活动, 旨在积极应对市场变化, 实现稳健增长。",
                  imageWidth: "1440px",
                  imageHeight: "360px",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewTopImage", {
                attrs: {
                  imageSrc:
                    "images/aboutus/overview/vision_strategies/management-strategy-back.png",
                  imageHeight: "revert-layer",
                },
              })
            : _vm._e(),
          _vm.isMobile()
            ? _c("OverviewSectionItem", [
                _c(
                  "pre",
                  {
                    staticClass: "ctt ctt-t2",
                    staticStyle: {
                      "text-align": "center",
                      "font-weight": "400",
                    },
                  },
                  [
                    _vm._v(
                      "LXLX Hausys坚持以优化事业组合和强化事业本质为核心战略方向开展事业。建筑装饰材料事业方面将进一步扩大韩国B2C业务和高附加值产品的销售。汽车材料配件和工业用薄膜事业方面将致力于强化竞争力和改善收益性。LX Hausys将集中精力精力强化解决方案, 提高客户价值；开展以市场和客户需求为基础的研发以及全球一流水平的制造创新活动, 旨在积极应对市场变化, 实现稳健增长。"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }