var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "box-area",
      class: _vm.isActive ? "active" : "",
      on: { click: _vm.moveByActive },
    },
    [
      _c(
        "pre",
        { staticClass: "box-content", class: _vm.isActive ? "active" : "" },
        [_vm._v(_vm._s(_vm.content))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }