<template>
  <div class="overview-section" :class="'mt'">
    <slot name="header"></slot>

    <!-- 제목 영역 -->
    <template v-if="showTitle">
      <div class="title" :style="{'margin-top': marginTop}">
        <span>
          {{ title }}
          <i>{{ italicTitle }}</i>
        </span>
      </div>
    </template>

    <!-- 컨텐츠 영역 -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    italicTitle: {
      type: String
    },
    size: {
      type: String,
      require: false,
      default: '44px'
    },
    color: {
      type: String,
      require: false,
      default: '#A09C9B'
    },
    fontWeight: {
      type: String,
      default: '700'
    },
    fontFamily: {
      type: String,
      default: 'Lato'
    },
    type: {
      type: String,
      default: 'major'
    },
    titleMarginTop: {
      type: String
    },
    sectionMarginTop: {
      type: String,
      default: '130px'
    }
  },
  computed: {
    marginTop () {
      let marginTop = '0px'

      switch (this.type) {
        case 'major':
          marginTop = '0px'
          break
        case 'minor':
          marginTop = '64px'
          break
        default:
          marginTop = '0px'
          break
      }

      if (this.titleMarginTop) {
        marginTop = this.titleMarginTop
      }

      return marginTop
    },

    showTitle () {
      if (this.title || this.italicTitle) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.overview-section {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1440px) {
  .overview-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.overview-section .title {
  display: grid;
  place-items: center;
}

.overview-section .title span {
  text-align: center;
  font-style: normal;
  line-height: normal;
}

.overview-section .title span {
  color: #222;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.overview-section .title span i {
  font-size: 56px;
  font-family: 'NotoSans' , sans-serif !important;
  font-weight: 700;
  font-style: italic;
}

@media (max-width: 768px) {
  .overview-section .title span {
    font-size: 2.0833333333rem;
  }
  .overview-section .title span i {
    font-size: 2.0833333333rem;
  }
}

.overview-section .content {
  display: flex;
  flex-direction: column;
}

.overview-section.mt {
  margin-top: 130px;
}
@media (max-width: 768px) {
  .overview-section.mt {
    margin-top: 60px;
  }
}
</style>
