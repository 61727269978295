var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "Key Social Contribution Initiatives",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [_c("SustainabilityText", { attrs: { text: _vm.text } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "scroll-box" },
              [
                _c("KeySocialContributionInitiativesTable", {
                  attrs: {
                    headers: _vm.table.headers,
                    bodies: _vm.table.bodies,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("KeySocialContributionInitiativesImageCard", {
                attrs: {
                  images: _vm.imageCard.images,
                  text: _vm.imageCard.text,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "主な社会貢献活動",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [_c("SustainabilityText", { attrs: { text: _vm.text_jp } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "scroll-box" },
              [
                _c("KeySocialContributionInitiativesTable", {
                  attrs: {
                    headers: _vm.table_jp.headers,
                    bodies: _vm.table_jp.bodies,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("KeySocialContributionInitiativesImageCard", {
                attrs: {
                  images: _vm.imageCard_jp.images,
                  text: _vm.imageCard_jp.text,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "Ключевые инициативы по социальному вкладу",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [_c("SustainabilityText", { attrs: { text: _vm.text_ru } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "scroll-box" },
              [
                _c("KeySocialContributionInitiativesTable", {
                  attrs: {
                    headers: _vm.table_ru.headers,
                    bodies: _vm.table_ru.bodies,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("KeySocialContributionInitiativesImageCard", {
                attrs: {
                  images: _vm.imageCard_ru.images,
                  text: _vm.imageCard_ru.text,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "关键社会贡献倡议",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [_c("SustainabilityText", { attrs: { text: _vm.text_cn } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "scroll-box" },
              [
                _c("KeySocialContributionInitiativesTable", {
                  attrs: {
                    headers: _vm.table_cn.headers,
                    bodies: _vm.table_cn.bodies,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            { attrs: { isUnderSectionItem: "" } },
            [
              _c("KeySocialContributionInitiativesImageCard", {
                attrs: {
                  images: _vm.imageCard_cn.images,
                  text: _vm.imageCard_cn.text,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }