<template>
  <div class="tab-con on sch-docu"  v-if="tabCount > 0">
    <div class="prod-fd-view">
      <!-- sort -->
      <div class="sort-box">
        <div class="sel-box">
          <button type="button" class="btn-sel"><span>{{ contentSortBy }}</span></button>
          <div class="sel-list">
            <ul class="box">
              <li><button type="button" @click="sortByNew">{{ newText }}</button></li>
              <li><button type="button" @click="sortByAsc">{{ aTozText }}</button></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- //sort -->

      <!-- S : selectAll & result count -->
      <div class="result-controler">
        <label class="inp-chk">
          <input type="checkbox" id="allCheck" v-model="allSelected" @click="selectedAll()">
          <span>{{ $t('all_select')}}</span>
        </label>
      </div>
      <!-- E : selectAll & result count -->

      <div class="tbl-box type05 dl-dcmt box-dl" v-if="$countryCode === '_US' || $countryCode === '_GL'">
        <table>
          <caption>{{ $t('shipping_form')}}</caption>
          <colgroup class="like-doc-colg-us">
            <col style="width:63px">
            <col style="width:285px">
            <col style="width:auto">
            <col style="width:110px">
            <col style="width:160px">
            <col style="width:140px">
          </colgroup>
          <colgroup class="like-doc-colg-us">
            <col style="width:4.375%">
            <col style="width:21%">
            <col style="width:auto">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:13%">
          </colgroup>
          <tbody>
          <tr v-for="(d, index) in document.list">
            <td class="ta-r dcmt-chk like-doc-cont-box">
              <label class="inp-chk single">
                <input type="checkbox" name="checkDocument" :id="'content_'+(index+1)" v-model="d.checked" :value="d.attribute.doc_file" @change="checkboxSelected(d.content.id, (index+1))">
                <span>All Select</span>
              </label>
            </td>
            <th class="like-doc-cont-box product-type" v-if="d.parents.length === 4">{{ d.parents[0].name }}</th>
            <th class="like-doc-cont-box doc-title" v-else-if="d.parents.length > 4">{{ d.parents[0].name }} / {{ d.attribute.doctypeText }}</th>
            <th class="like-doc-cont-box" v-else></th>
            <td class="like-doc-cont-box">
              <a class="titleTag doc-table-td-a  like-doc-td-a" v-if="d.attribute.doc_file_type == 'pdf' && (countryCode == '_GL' || countryCode == '_US')" :href="$imagePath + d.attribute.doc_file" target="_blank" style="text-align: left">
                {{ d.content.title }}
              </a>
              <a class="titleTag doc-table-td-a  like-doc-td-a" v-if="d.attribute.doc_file_type == 'pdf' && (countryCode != '_GL' && countryCode != '_US')" :href="$imagePath + d.clickDocFile" target="_blank" style="text-align: left">
                {{ d.content.title }}
              </a>
              <a class="titleTag doc-table-td-a  like-doc-td-a" v-if="d.attribute.doc_file_type != 'pdf'" href="javascript:void(0)" style="text-align: left">
                {{ d.content.title }}
              </a>
            </td>
            <td class="ta-c like-doc-cont-box file-type">{{ d.attribute.doc_file_type }}</td>
            <td class="ta-c like-doc-cont-box" v-if="countryCode == '_GL' || countryCode == '_US'">{{ d.attribute.doc_file_size }}</td>
            <td  class="like-doc-cont-box" v-if="countryCode != '_GL' && countryCode != '_US'">
              <div class="sel-box type02">
                <ul>
                  <li class="sel-box">
                    <button type="button" class="btn-seg btn-sel">
                      <span>{{ d.clickDocLabel }}</span>
                    </button>
                    <div class="sel-list" style="display: none">
                      <ul class="box">
                        <li v-for="(doc) in d.docList">
                          <button type="button" class="doc-nonred" @click="docChange(doc.label, doc.file, doc.type, index)">{{ doc.label }}</button>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </td>
            <td class="last-btns like-doc-cont-box">
              <span class="sns-wrp">
                <button type="button" class="btn-share icon-share">sharing</button>
                <div class="sns sns_share_pop" style="width: 80px;">
                  <ul>
                    <li><a href="javascript:;" class="ico-e" @click="email(d)">email</a></li>
                    <li><a href="javascript:;" class="ico-v" @click="clip(d)">url</a></li>
                  </ul>
                </div>
              </span>
              <!-- <button type="button" class="btn-share">sharing</button> -->
              <button type="button" class="btn-down like-btn-down-us" @click="downloadFile(d.attribute.doc_file, d.content.id)">{{ $t('download')}}</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="tbl-box type05 box-dl" v-else>
        <table>
          <caption>table</caption>
          <colgroup class="like-doc-colg">
            <col style="width:70px"/>
            <col style="width:200px"/>
            <col style="width:auto"/>
            <col style="width:110px"/>
            <col style="width:160px"/>
            <col style="width:140px"/>
          </colgroup>
          <colgroup class="like-doc-colg">
            <col style="width:4.861%"/>
            <col style="width:11%"/>
            <col style="width:auto"/>
            <col style="width:7.638%"/>
            <col style="width:120px"/>
            <col style="width:9.72%"/>
          </colgroup>
          <tbody>
          <tr v-for="(d, index) in document.list">
            <td class="ta-r dcmt-chk like-doc-cont-box">
              <label class="inp-chk single">
                <input type="checkbox" name="checkDocument" :id="'content_'+(index+1)" v-model="d.checked" :value="d.attribute.doc_file" @change="checkboxSelected(d.content.id, (index+1))">
                <span>All Select</span>
              </label>
            </td>
            <th class="like-doc-cont-box product-type" v-if="d.parents.length === 4">{{ d.parents[0].name }}</th>
            <th class="like-doc-cont-box doc-title" v-else-if="d.parents.length > 4">{{ d.parents[0].name }} / {{ d.attribute.doctypeText }}</th>
            <th class="like-doc-cont-box" v-else></th>
            <td class="like-doc-cont-box">
              <a class="titleTag doc-table-td-a like-doc-td-a" v-if="d.attribute.doc_file_type == 'pdf' && (countryCode == '_GL' || countryCode == '_US')" :href="$imagePath + d.attribute.doc_file" target="_blank" style="text-align: left">
                {{ d.content.title }}
              </a>
              <a class="titleTag doc-table-td-a like-doc-td-a" v-if="d.attribute.doc_file_type == 'pdf' && (countryCode != '_GL' && countryCode != '_US')" :href="$imagePath + d.clickDocFile" target="_blank" style="text-align: left">
                {{ d.content.title }}
              </a>
              <a class="titleTag doc-table-td-a like-doc-td-a" v-if="d.attribute.doc_file_type != 'pdf'" href="javascript:void(0)" style="text-align: left">
                {{ d.content.title }}
              </a>
            </td>
            <td class="ta-c like-doc-cont-box file-type">{{ d.attribute.doc_file_type }}</td>
            <td class="ta-c like-doc-cont-box" v-if="countryCode == '_GL' || countryCode == '_US'">{{ d.attribute.doc_file_size }}</td>
            <td class="like-doc-cont-box" v-if="countryCode != '_GL' && countryCode != '_US'">
              <div class="sel-box type02">
                <ul>
                  <li class="sel-box">
                    <button type="button" class="btn-seg btn-sel">
                      <span>{{ d.clickDocLabel }}</span>
                    </button>
                    <div class="sel-list" style="display: none">
                      <ul class="box">
                        <li v-for="(doc) in d.docList">
                          <button type="button" class="doc-nonred" @click="docChange(doc.label, doc.file, doc.type, index)">{{ doc.label }}</button>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </td>
            <td class="last-btns like-doc-cont-box">
              <span class="sns-wrp">
                <button type="button" class="btn-share icon-share">sharing</button>
                <div class="sns sns_share_pop" style="width: 80px;">
                  <ul>
                    <li><a href="javascript:;" class="ico-e" @click="email(d)">email</a></li>
                    <li><a href="javascript:;" class="ico-v" @click="clip(d)">url</a></li>
                  </ul>
                </div>
              </span>
              <!-- <button type="button" class="btn-share">sharing</button> -->
              <button v-if="countryCode == '_GL' || countryCode == '_US'" type="button" class="btn-down" @click="downloadFile(d.attribute.doc_file, d.content.id)">download</button>
              <button v-else type="button" class="btn-down" @click="downloadFile(d.clickDocFile, d.content.id)">{{ $t('download')}}</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="more-box tac mt-30">
        <button type="button" class="btn btn-down btn-down-white" @click="SelectFileDown()"><span>{{ $t('select_file_download')}}</span></button>
      </div>

    </div>
  </div>
  <div class="no-found-wrap bd-top" v-else>
    <div class="tac">
      <i class="icon-no-result"><img src="images/icon-no-result-found.png" alt="no result"></i>
      <div class="msg">
        <ul>
          <li class="ctt ctt-t1 result-tit">No Result Found</li>
          <li class="ctt ctt-t4">Try again using more general search keywords</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import ProsService from '@/server/pros.service'

import { mapState } from 'vuex'
import _ from '@/common/helper/lodash'

export default {
  name: 'SearchDocumentation',
  props: ['searchResult', 'searchWord'],
  watch: {
    searchResult () {
      this.initSearchResult()
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  data () {
    return {
      document: {},
      checkedValues: [],
      checkedType: [],
      orderField: [],
      orderType: [],
      tabCount: 0,
      allSelected: false,
      countryCode: this.$countryCode,
      contentSortBy: this.$t('sort_by'),
      aTozText: this.$t('products_a_to_z'),
      newText: this.$t('new_products_first')
    }
  },
  async mounted () {
    this.initSearchResult()
    this.$nextTick(function () {
      // $('.sns-wrp .sns').each(function (idx, ele) {
      //   var $this = $(this)
      //   $(ele)
      //     .prev()
      //     .click(function () {
      //       $this.slideToggle(300)
      //       $this.closest('tr').siblings().children().find('.sns').hide()
      //     })
      // })
    })
    // this.$nextTick(function(){
    //   $('.sns-wrp .btn-share').on('click', function () {
    //     // console.log("click");
    //     // alert("dasfjlasdjf");
    //     $('.sns-wrp .sns').slideToggle(300);
    //     $('.sns-wrp .sns').closest('tr').siblings().children().find('.sns').hide()
    //   });
    // })
  },
  methods: {
    docChange (label, file, type, index) {
      const _this = this

      _this.document.list[index].clickDocLabel = label
      _this.document.list[index].clickDocFile = file
      _this.document.list[index].clickType = type
      _this.$forceUpdate()
    },
    initSearchResult () {
      const _this = this
      this.document = this.searchResult.document
      if (_this.countryCode != '_US' && _this.countryCode != '_GL') {
        _this.document.list.forEach((content, idx) => {
          const docList = []
          if (_this.countryCode == '_UK') {
            if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
              docList.push({ label: this.$t('Text.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk', fileType: content.attribute.doc_file_uk_type })
            }
          } else {
            if (!_.isEmpty(content.attribute.doc_file)) {
              docList.push({ label: this.$t('Text.EN'), file: content.attribute.doc_file, type: 'doc_file', fileType: content.attribute.doc_file_type })
            }
          }
          if (!_.isEmpty(content.attribute.doc_file_es) && !_.isUndefined(content.attribute.doc_file_es)) {
            docList.push({ label: this.$t('Text.ES'), file: content.attribute.doc_file_es, type: 'doc_file_es', fileType: content.attribute.doc_file_es_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_de) && !_.isUndefined(content.attribute.doc_file_de)) {
            docList.push({ label: this.$t('Text.DE'), file: content.attribute.doc_file_de, type: 'doc_file_de', fileType: content.attribute.doc_file_de_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_fr) && !_.isUndefined(content.attribute.doc_file_fr)) {
            docList.push({ label: this.$t('Text.FR'), file: content.attribute.doc_file_fr, type: 'doc_file_fr', fileType: content.attribute.doc_file_fr_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_it) && !_.isUndefined(content.attribute.doc_file_it)) {
            docList.push({ label: this.$t('Text.IT'), file: content.attribute.doc_file_it, type: 'doc_file_it', fileType: content.attribute.doc_file_it_type })
          }
          if (!_.isEmpty(content.attribute.doc_file_nl) && !_.isUndefined(content.attribute.doc_file_nl)) {
            docList.push({ label: this.$t('Text.NL'), file: content.attribute.doc_file_nl, type: 'doc_file_nl', fileType: content.attribute.doc_file_nl_type })
          }
          if (_this.countryCode == '_UK') {
            if (!_.isEmpty(content.attribute.doc_file)) {
              docList.push({ label: this.$t('Text.EN'), file: content.attribute.doc_file, type: 'doc_file', fileType: content.attribute.doc_file_type })
            }
          } else {
            if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
              docList.push({ label: this.$t('Text.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk', fileType: content.attribute.doc_file_uk_type })
            }
          }
          content.docList = docList
          content.clickDocLabel = docList[0].label
          content.clickType = docList[0].type
          content.clickDocFile = docList[0].file
          content.fileType = docList[0].fileType
        })
      }
      this.tabCount = this.searchResult.document.totalCount
    },
    selectedAll () {
      if (this.allSelected) {
        $('input:checkbox[name=checkDocument]').prop('checked', false)
      } else {
        $('input:checkbox[name=checkDocument]').prop('checked', true)
        for (var i = 0; i < this.contentList.length; i++) {
          this.checkedValues.push(this.contentList[i].content.id)
        }
      }
    },
    sortByAsc () {
      this.contentSortBy = this.aTozText
      this.document.list.sort((a, b) => {
        if (a.content.title && b.content.title) {
          return a.content.title.localeCompare(b.content.title)
        }
        return 0
      })
    },
    sortByNew () {
      this.contentSortBy = this.newText
      this.document.list.sort((a, b) => {
        if (a.content.createDate && b.content.createDate) {
          return new Date(b.content.createDate) - new Date(a.content.createDate)
        }
        return 0
      })
    },
    checkAll () {
      const checked = this.allSelected

      this.document.list.forEach(function (d) {
        d.checked = !checked
      })
    },
    async downloadFile (file, id) {
      await ProsService.getClickUp(id)
      let url = ''
      url = this.$imagePath + file
      const path = require('path')
      const fileName = path.basename(file)
      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }
      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', fileName)
        document.body.appendChild(element)
        element.click()
      })
    },
    checkboxSelected (id, index) {
      const _this = this
      if ($('#content_' + index).is(':checked')) {
        _this.checkedValues.push(id)
      } else {
        _this.checkedValues = _this.checkedValues.filter((element) => element !== id)
      }
      const checkBoxTotal = $('input:checkbox[name=checkDocument]').length
      const checkedCount = $('input:checkbox[name=checkDocument]:checked').length
      if (checkBoxTotal == checkedCount) {
        $('#allCheck').prop('checked', true)
        _this.allSelected = true
      } else {
        $('#allCheck').prop('checked', false)
        _this.allSelected = false
      }
    },
    async SelectFileDown () {
      const _this = this
      _this.checkedType = []
      if (this.checkedValues.length == 0) {
        alert("There's no file to download.")
      } else {
        _this.document.list.forEach((content, idx) => {
          if ($('#content_' + (idx + 1)).is(':checked')) {
            _this.checkedType.push(content.clickType + '-' + (idx + 1))
          } else {
            _this.checkedType = _this.checkedType.filter((element) => element !== content.clickType + '-' + (idx + 1))
          }
        })
        ProsService.zipFileDownload(_this.checkedValues, _this.checkedType, _this.countryCode)
      }
    },
    clip (content) {
      var url = this.$imagePath + content.attribute.doc_file
      var textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      textarea.value = url
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      alert('URL Copied')
    },
    async email (content) {
      const title = content.content.title
      const url = this.$imagePath + content.attribute.doc_file

      var email_url = 'mailto:?subject=' + title + '&body=' + url
      location.href = email_url
    }
    /*
    async SelectFileDown(){
      let checkedValues = [];
      this.document.list.forEach(function(d){
        if(d.checked)
        {
          checkedValues.push(d.content.id);
        }
      })

      if(checkedValues.length == 0){
        alert("There's no file to download.")
      } else {
        location.href = 'api/download/zip?ids='+checkedValues.join(',');
      }
    }
     */
  }
}

</script>
<style scoped>
.like-doc-cont-box a.titleTag{
  white-space: break-spaces;
}
</style>
