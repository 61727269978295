var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "environmental-investment-text-card" }, [
    _c("div", { staticClass: "title-area" }, [
      _c("pre", { staticClass: "year" }, [_vm._v(_vm._s(_vm.year))]),
      _c("pre", { staticClass: "unit" }, [_vm._v(_vm._s(_vm.unit))]),
    ]),
    _c("div", { staticClass: "text-area" }, [
      _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }