var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BusinessAreaCarouselTopImage", {
    attrs: { imageSrc: _vm.imageSrc, imageHeight: _vm.imageHeight },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "business-area-carousel-item-area" }, [
              _c(
                "div",
                { staticClass: "title-area", style: { color: _vm.color } },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }