<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- unfolded 클래스로 폼형식일때와 지도형식일때 레이아웃을 나눴습니다 -->
    <div class="content cntu-view fasr-view" :class="{'unfolded': showMap}" >
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Contact</li>
          <li>Find a Sales Representative</li>
        </ul>
      </div>
      <div class="container cntu-box">
        <div class="w-con-02">
          <h1 class="tt-1 mb-88 tac">Find a Sales Representative </h1>
          <div class="t-type ta-c"></div>
          <form action="#none">
            <fieldset>
              <legend>Contact us</legend>
              <div class="cntu">
                <div class="tbl-wrap">
                  <div class="tab-ui tab-ui-dark">
                    <ul>
                      <li data-tab="cate-hf" class="btn-tab" @click="selTab('HFLOR','category')" :class="{'on': searchValue.categoryCode === 'HFLOR'}">
                        <button type="button">HFLOR</button>
                      </li>
                      <li id="vh-button" data-tab="cate-vi" class="btn-tab" @click="selTab('HIMACS,VIATERA','category')" :class="{'on': searchValue.categoryCode === 'HIMACS,VIATERA'}">
                        <button  type="button">VIATERA/ HIMACS</button>
                      </li>
                    </ul>
                  </div>

                  <div class="inner">

                    <!-- Gray Tab -->
                    <div class="tab-ui tab-ui-nav tab-ui-gray">
                      <button type="button" class="arrow disabled tab-arr-prev">
                        <span class="sr-only">이전</span>
                      </button>
                      <button type="button" class="arrow tab-arr-next">
                        <span class="sr-only">다음</span>
                      </button>
                      <ul>
                        <li v-if="searchValue.categoryCode === 'HFLOR'" data-tab="btn-dis" class="btn-tab" :class="{'on': searchValue.dept === 'DIST'}">
                          <button type="button" @click="selTab('DIST','dept')">
                            For Distributor
                          </button>
                        </li>
                        <li v-else data-tab="btn-rss" class="btn-tab" :class="{'on': searchValue.dept === 'RSS'}">
                          <button type="button" @click="selTab('RSS','dept')">
                            For K&B
                          </button>
                        </li>
                        <li data-tab="btn-sm" class="btn-tab" :class="{'on': searchValue.dept === 'SM'}">
                          <button type="button" @click="selTab('SM','dept')">
                            For A&D
                          </button>
                        </li>
                        <li data-tab="btn-tm" class="btn-tab" v-if="searchValue.categoryCode !== 'HFLOR'" :class="{'on': searchValue.dept === 'TM'}">
                          <button type="button" @click="selTab('TM','dept')">
                            For Fabricator/Installer
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- Gray Tab -->

                    <div class="ctt ctt-t2 tac notice-fasr">
                        <!-- 폼형식과 지도형식에 따라 출력 문구가 달라집니다 : CSS after로 처리하였습니다. 210115 이강형 -->
                    </div>

                    <!-- S : 폼 형식 -->
                    <div class="fasr-type-form">
                      <div class="tab-con">
                        <div class="tbl-01">
                          <div class="tbl-input tabLine">
                            <table>
                              <caption>
                                Contact us For Professionals form
                              </caption>
                              <colgroup>
                                <col style="width: 180px" />
                                <col style="width: auto" />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    <span class="must">Type of inquiry</span>
                                  </th>
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel">
                                        <span class="placeholder">{{form.selectedInquiry}}</span>
                                      </button>
                                      <div class="sel-list" v-if="searchValue.categoryCode === 'HFLOR'">
                                        <ul class="box">
                                          <li v-for="(item, idx) in form.hflorInqList" :key="item.id.value">
                                            <button type="button" @click="selVal(item.id.value, item.text, 'inquiry')">{{item.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="sel-list" v-else>
                                        <ul class="box">
                                          <li v-for="(item, idx) in form.viaHiInqList" :key="item.id.value">
                                            <button type="button" @click="selVal(item.id.value, item.text, 'inquiry')">{{item.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="form.execValidFlag && !validation.inqDictVal.value && form.contact.inqDictVal === ''">{{validation.inqDictVal.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="firstNm" class="must">First Name</label>
                                  </th>
                                  <td class="cell-noti">
                                    <input type="text" id="firstNm" class="inp-text" v-model="form.contact.firstNm" placeholder="Input Text"/><!--이름-->
                                    <p v-if="form.execValidFlag && !validation.firstNm.value && form.contact.firstNm === ''">{{validation.firstNm.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="lastNm" class="must">Last Name</label>
                                  </th>
                                  <td class="cell-noti">
                                    <input type="text" id="lastNm" class="inp-text" v-model="form.contact.lastNm" placeholder="Input Text"/><!--성-->
                                    <p v-if="form.execValidFlag && !validation.lastNm.value && form.contact.lastNm === ''">{{validation.lastNm.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="email" class="must">Email</label>
                                  </th>
                                  <td class="cell-noti">
                                    <input type="text" id="email" class="inp-text" v-model="form.contact.email" placeholder="Input Text"/><!--email-->
                                    <p v-if="form.execValidFlag && !validation.email.value && !emailRule.test(form.contact.email)">{{validation.email.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="phone" class="must">Phone</label>
                                  </th>
                                  <td class="cell-noti">
                                    <input type="text" id="phone" class="inp-text" v-model="form.contact.phone" placeholder="Input Text"/><!--phone-->
                                    <p v-if="form.execValidFlag && !validation.phone.value && form.contact.phone === ''">{{validation.phone.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <span class="must">Country</span>
                                  </th>
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel">
                                        <span class="placeholder">{{form.selectedCountry}}</span>
                                      </button>
                                      <div class="sel-list">
                                        <ul class="box">
                                          <li v-for="(item, idx) in form.countries" :key="item.countryCd">
                                            <button type="button" @click="selVal(item.countryCd, item.countryNm, 'country')">{{item.countryNm}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="form.execValidFlag && !validation.country.value && form.contact.countryCd === ''">{{validation.country.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <span class="must">State/Province</span>
                                  </th>
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel">
                                        <span class="placeholder">{{form.selectedState}}</span>
                                      </button>
                                      <div class="sel-list">
                                        <ul class="box">
                                          <li v-for="(item, idx) in form.states" :key="item.id.stateCd">
                                            <button type="button" @click="selVal(item.id.stateCd, item.stateNm, 'state')">{{item.stateNm}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="form.execValidFlag && !validation.stateCd.value && form.contact.stateCd === ''">{{validation.stateCd.msg}}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="tbl-02 ">
                          <div class="tbl-input type03 mob-bt-0 tabLine">
                            <table>
                              <caption>
                                Contact us For Professionals form
                              </caption>
                              <colgroup>
                                <col style="width: 180px" />
                                <col style="width: auto" />
                              </colgroup>
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    <label for="zipCd" class="must">Zip/Postal Code</label>
                                  </th>
                                  <td class="cell-noti">
                                    <input type="text" id="zipCd" class="inp-text" v-model="form.contact.zipCd" placeholder="Input Text"/><!--zip Code-->
                                    <p v-if="form.execValidFlag && !validation.zipCd.value && form.contact.zipCd === ''">{{validation.zipCd.msg}}</p>

                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="companyNm" class="must">Company Name</label>
                                  </th>
                                  <td class="cell-noti" >
                                    <input type="text" id="companyNm" class="inp-text" v-model="form.contact.companyNm" placeholder="http://"/>
                                    <p v-if="form.execValidFlag && !validation.companyNm.value && form.contact.companyNm === ''">{{validation.companyNm.msg}}</p>

                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="companyAddr" class="must">Company Address</label>
                                  </th>
                                  <td class="cell-noti" >

                                    <input type="text" id="companyAddr" class="inp-text" v-model="form.contact.companyAddr"  placeholder="Input Text"/>
                                    <p v-if="form.execValidFlag && !validation.companyAddr.value && form.contact.companyAddr === ''">{{validation.companyAddr.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="companyWeb">Company Website</label>
                                  </th>
                                  <td>
                                    <input type="text" id="companyWeb" placeholder="http://" class="inp-text" v-model="form.contact.companyWeb"/>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <span class="must">Occupation</span>
                                  </th>
                                  <td class="cell-noti">
                                    <div class="sel-box type02">
                                      <button type="button" class="btn-sel">
                                        <span class="placeholder">{{form.selectedOccupation}}</span>
                                      </button>
                                      <div class="sel-list" v-if="searchValue.dept === 'RSS'">
                                        <ul class="box">
                                          <li v-for="(item, idx) in form.rssOccupations" :key="item.id.value">
                                            <button type="button" @click="selVal(item.id.value, item.text, 'occupation')">{{item.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="sel-list" v-else-if="searchValue.dept ==='TM'">
                                        <ul class="box">
                                          <li v-for="(item, idx) in form.tmOccupations" :key="item.id.value">
                                            <button type="button" @click="selVal(item.id.value, item.text, 'occupation')">{{item.text}}</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <p v-if="form.execValidFlag && !validation.occupation.value && form.contact.occupation === ''">{{validation.occupation.msg}}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <label for="job">Job Title</label>
                                  </th>
                                  <td>
                                    <input type="text" id="job" class="inp-text" v-model="form.contact.job" placeholder="Select" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- S : 텍스트박스 -->
                      <div class="ta-box">
                        <textarea placeholder="Input Comment" maxlength="1000" v-model="form.contact.comment"></textarea>
                      </div>
                      <label for="agree" class="inp-chk">
                        <input type="checkbox" id="agree" v-model="form.agree"/>
                        <span>By providing your details and clicking Sign Up/In, you acknowledge and agree to our website
                          <a href="javascript:void(0);" @click="openPrivacy()">Privacy Policy</a> and
                          <a href="javascript:void(0);" @click="openTerms()">Terms of Use</a>.
                          <span class="fc-red">*</span></span>
                      </label><!--marketting agreement-->
                      <div class="more-box ta-c">
                        <button type="button" class="btn" @click="confirm()"><span>Submit</span></button>
                      </div>
                      <!-- E : 텍스트박스 -->
                    </div>
                    <!-- E : 폼 형식 -->

                    <!-- S : 지도 형식 -->
                    <div class="fasr-type-map">
                      <div class="fasr-map">
                        <!-- 검색박스 -->
                        <div class="search-box-wtb in-map">
                          <div class="btns">
                            <ul>
                              <li class="sel-box">
                                <button type="button" class="btn-sel">
                                  <span class="placeholder">{{form.selectedMapCountry}}</span>
                                </button>
                                <div class="sel-list">
                                  <ul class="box">
                                    <li v-for="(item, idx) in form.countries" :key="item.countryCd">
                                      <button type="button" @click="selMap(item.countryCd, item.countryNm)">{{item.countryNm}}</button>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="sch-kw-box">
                            <div class="search">
                              <input type="text" id="searchInput" v-model="searchValue.keyword" placeholder="Search by Address, City, State, Zip Code" class="pac-target-input" autocomplete="off"/>
                              <button type="button" class="btn-search" @click="goSearch()"> search </button>
                            </div>
                          </div>
                          <!-- 검색박스 -->
                        </div>
                        <!-- 지도 API 들어갈 영역 -->
                        <div id='map' v-show="searchValue.countryCd === 'US'"></div>
                      </div>
                      <div v-if="searchValue.countryCd === 'US'" class="fasr-store-list">
                        <!-- S : 스토어 박스 -->
                        <dl v-for="(item) in viewList" :key="item.id">
                          <dt v-if="searchValue.dept === 'SM'">
                            {{item.salesRep.repName}}
                          </dt>
                          <dt v-if="searchValue.dept === 'DIST'">
                            {{item.company}}
                          </dt>
                          <dd v-if="searchValue.dept === 'SM'">
                            <div>{{item.salesRep.positionTitle}}</div>
                            <div>{{item.zipCode}}</div>
                            <div>{{item.salesRep.repTel}}</div>
                            <div>{{item.salesRep.repEmail}}</div>
                          </dd>
                          <dd v-if="searchValue.dept === 'DIST'">
                            <div>{{item.tel}}</div>
                            <div>{{item.url}}</div>
                            <div>{{item.addr1}}</div>
                            <div>{{item.addr2}}</div>
                          </dd>
                        </dl>
                        <!-- S : 스토어 박스 -->
                        <!-- S : 스토어  -->
                        <!-- S : No result -->
                        <dl class="fasr-no-result" v-if="viewList.length === 0">
                          <dt>No Result Found</dt>
                          <dd>
                            <div>Please try again using different information</div>
                          </dd>
                        </dl>
                        <!-- E : No result -->
                      </div>
                      <div v-else class="fasr-store-list">
                        <!-- S : 스토어 박스 -->
                        <dl v-for="(item) in viewList" :key="item.id">
                          <dt>
                            {{item.repName}}
                          </dt>
                          <dd>
                            <div>{{item.positionTitle}}</div>
                            <div>{{item.repTel}}</div>
                            <div>{{item.repEmail}}</div>
                          </dd>
                        </dl>
                        <!-- S : 스토어 박스 -->
                        <!-- S : 스토어  -->
                        <!-- S : No result -->
                        <dl class="fasr-no-result" v-if="viewList.length === 0">
                          <dt>No Result Found</dt>
                          <dd>
                            <div>Please try again using different information</div>
                          </dd>
                        </dl>
                        <!-- E : No result -->
                      </div>
                    </div>
                    <div class="wrapper-git">
                      <p class="tt-2">Get in touch with LX Hausys</p>
                      <div class="box-git">
                        <table>
                          <colgroup>
                            <col width="120" />
                            <col width="*" />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>US</th>
                              <td>commercialusa@lxhausys.com</td>
                            </tr>
                            <tr>
                              <th>Canada</th>
                              <td>salescanada@lxhausys.com</td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- <table>
                          <colgroup>
                            <col width="120" />
                            <col width="*" />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>Contact</th>
                              <td>
                                Got a question about our HIMACS® Or you just want
                                to talk with us. We are always up for a
                                conversation!
                              </td>
                            </tr>
                          </tbody>
                        </table> -->
                        <!--<button type="button" class="btn btn-icon btn-go" @click="url()">
                          <span>Contact Us</span>
                        </button>-->
                      </div>
                    </div>
                    <!-- E : 지도 형식 -->

                  </div>
                </div>
                <div class="inner">
                  <div class="find-btns">
                    <p class="heading-5">Find what you Need</p>
                    <ul>
                      <li>
                        <a href="javascript:void(0);" @click="openCart()"><img width="56" height="61" src="images/svg_icon/icon_quick_order.svg"/>
                          <span>Order a sample</span>
                        </a>
                      </li>
                      <li>
                        <a href="support/where-to-buy/country/us">
                          <img width="37" height="61" src="images/svg_icon/icon_quick_where.svg"/>
                          <span>Where to buy</span>
                        </a>
                      </li>
                      <li>
                        <a href="support/faq/all-products">
                          <img width="57" height="55" src="images/svg_icon/icon_quick_faq.svg"/>
                          <span>FAQ</span>
                        </a>
                      </li>
                      <li>
                        <a href="pros/documentation/all-products">
                          <img width="48" height="58" src="images/svg_icon/icon_quick_document.svg"/>
                          <span>Documentation</span>
                        </a>
                      </li>
                      <li>
                        <a href="support/warranty/hflor">
                          <img width="53" height="62" src="images/svg_icon/icon_quick_warranty.svg"/>
                          <span>Warranty</span>
                        </a>
                      </li>
                      <li>
                        <a href="support/care-and-maintenance/hflor">
                          <img width="53" height="59" src="images/svg_icon/icon_quick_care.svg"/>
                          <span>Care &amp; Maintenance</span>
                        </a>
                      </li>
                      <li>
                        <a href="pros/documentation/all-products/installation-guide">
                          <img width="52" height="53" src="images/svg_icon/icon_quick_install.svg"/>
                          <span>Installation Guide</span>
                        </a>
                      </li>
                      <li>
                        <a href="support/sales-representative/continentus/us">
                          <img width="53" height="50" src="images/svg_icon/icon_quick_sales.svg"/>
                          <span>Find a Sales Representative</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import usService from '@/server/us.service'
import supportService from '@/server/support.service'
import _ from '@/common/helper/lodash.js'
import metaService from '@/server/meta.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'salesRepUS',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      typeTitle: '',
      metaDescription: '',
      metaTitle: '',
      map: null,
      geocoder: null,
      routeParam: null,
      markers: [],
      viewList: [],
      orgViewList: [],
      options: {
        _US: {
          US: {
            zoom: 9,
            latitude: 37.09024,
            longitude: -95.712891
          },
          CA: {
            zoom: 9,
            latitude: 56.130366,
            longitude: -106.346771
          }
        },
        styles: {
          default: [],
          silver: [
            {
              elementType: 'geometry',
              stylers: [{ color: '#f5f5f5' }]
            },
            {
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [{ color: '#616161' }]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#f5f5f5' }]
            },
            {
              featureType: 'administrative.land_parcel',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#bdbdbd' }]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [{ color: '#eeeeee' }]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#757575' }]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{ color: '#e5e5e5' }]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9e9e9e' }]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ color: '#ffffff' }]
            },
            {
              featureType: 'road.arterial',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#757575' }]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{ color: '#dadada' }]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#616161' }]
            },
            {
              featureType: 'road.local',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9e9e9e' }]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry',
              stylers: [{ color: '#e5e5e5' }]
            },
            {
              featureType: 'transit.station',
              elementType: 'geometry',
              stylers: [{ color: '#eeeeee' }]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#c9c9c9' }]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9e9e9e' }]
            }
          ]
        }
      },
      searchValue: {
        categoryCode: '',
        dept: '',
        mile: 30,
        keyword: '',
        latitude: 0,
        longitude: 0,
        countryCd: '',
        stateCd: ''
      },
      form: {
        selectedInquiry: '',
        hflorInqList: [],
        viaHiInqList: [],
        selectedOccupation: '',
        rssOccupations: [],
        tmOccupations: [],
        selectedCountry: '',
        countries: [],
        selectedState: '',
        states: [],
        statesData: {
          US: [],
          CA: [],
          org: [{ id: { stateCd: '' }, stateNm: 'Select' }]
        },
        selectedMapCountry: '',
        agree: false,
        contact: {
          categoryCode: '',
          inqDictId: '',
          inqDictVal: '',
          firstNm: '',
          lastNm: '',
          email: '',
          phone: '',
          countryCd: '',
          stateCd: '',
          zipCd: '',
          companyNm: '',
          companyAddr: '',
          companyWeb: '',
          occupation: '',
          job: '',
          comment: '',
          siteType: 'US',
          mktChk: 'Y',
          reqType: 'Pro',
          countryText: '',
          stateText: '',
          productName: '',
          inquiryText: '',
          occText: '',
          Department: '',
          zipType: 'N',
          typeText: 'Professionals'
        },
        execValidFlag: false
      },
      orgValue: {
        selectedInquiry: 'Select',
        selectedOccupation: 'Select',
        selectedCountry: 'Select',
        selectedState: 'Select',
        agree: false,
        selectedMapCountry: '',
        contact: {
          inqDictVal: '',
          firstNm: '',
          lastNm: '',
          email: '',
          phone: '',
          countryCd: '',
          stateCd: '',
          zipCd: '',
          companyNm: '',
          companyAddr: '',
          companyWeb: '',
          occupation: '',
          job: '',
          comment: '',
          mktChk: 'N',
          countryText: '',
          stateText: '',
          productName: '',
          inquiryText: '',
          occText: '',
          Department: '',
          zipType: 'Y',
          typeText: 'Professionals'
        },
        execValidFlag: false
      },
      validation: {
        inqDictVal: {
          value: false,
          msg: 'Select Type of inquiry'
        },
        firstNm: {
          value: false,
          msg: 'Enter First Name'
        },
        lastNm: {
          value: false,
          msg: 'Enter Last Name'
        },
        email: {
          value: false,
          msg: 'Enter Email'
        },
        phone: {
          value: false,
          msg: 'Enter Phone'
        },
        country: {
          value: false,
          msg: 'Select Country'
        },
        stateCd: {
          value: false,
          msg: 'Select State'
        },
        zipCd: {
          value: false,
          msg: 'Enter ZIP Code'
        },
        companyNm: {
          value: false,
          msg: 'Enter Company Name'
        },
        companyAddr: {
          value: false,
          msg: 'Enter Company Address'
        },
        occupation: {
          value: false,
          msg: 'Select Occupation'
        }
      },
      emailRule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/ // 이메일 정규식
    }
  },
  async created () {
    // route로부터 전달받는 값.
    this.routeParam = this.$route.params
    const _this = this
    let type = ''
    if (location.pathname.indexOf('/sales-representative/continentus') !== -1 && this.$countryCode === '_US') {
      // 신규 seo url일 경우
      if (this.routeParam.countryCd === 'us') {
        this.routeParam.countryCd = 'US'
        type = 'US'
      } else if (this.routeParam.countryCd === 'canada') {
        this.routeParam.countryCd = 'CA'
        type = 'CA'
      }
    }
    // 데이터 사전 불러오기
    const dictionary = await supportService.getDictionaryValueList(['INQ_HFLOR_PRO', 'INQ_HIMACS_PRO', 'SALES_REP_OCP'])
    this.form.hflorInqList = dictionary.data.dicList[0].list // Pro Inquiry Type (Professionals Viatera 조회 종류)
    this.form.viaHiInqList = dictionary.data.dicList[1].list// Pro Inquiry Type (Professionals Himacs 조회 종류)
    const occList = dictionary.data.dicList[2].list// 직업
    // seperate rss, tm occupation list
    this.form.rssOccupations = _.filter(occList, function (v) {
      return _.includes(v.id.value, 'RSS')
    })
    this.form.tmOccupations = _.filter(occList, function (v) {
      return _.includes(v.id.value, 'TM')
    })

    // form base value setting
    this.form.contact.inqDictId = 'INQ_HIMACS_PRO' // dictionary Id setting
    this.form.contact.categoryCode = 'HIMACS'
    this.form.contact.countryCd = this.routeParam.countryCd
    // form message setting
    this.form.selectedInquiry = 'Select'
    this.form.selectedOccupation = 'Select'
    this.form.selectedCountry = 'Select'
    this.form.selectedState = 'Select'
    _this.$nextTick(async function () {
      const { data } = await metaService.getMeta('PAGE_META_FIND_SALES_' + type, '', 'US')
      _this.metaDescription = data.description
      _this.metaTitle = data.title
    })
  },
  mounted () {
    // 지도 초기화
    this.initMap()
    this.init()
    activeArrowTab() // common.js
  },
  methods: {
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    async init () {
      const that = this
      // 국가, 주
      const promiseArr = []
      promiseArr.push(usService.getStateList('US'))
      promiseArr.push(usService.getStateList('CA'))
      const res = await Promise.all(promiseArr)
      // country, state
      this.form.countries = [
        { countryCd: 'US', countryNm: 'USA' },
        { countryCd: 'CA', countryNm: 'Canada' }
      ]
      this.form.statesData.US = res[0].data
      this.form.statesData.CA = res[1].data

      // search Value setting
      // this.searchValue.categoryCode = 'HFLOR'
      // this.searchValue.dept = 'DIST'

      this.$nextTick(function () {
        that.selTab('HIMACS,VIATERA', 'category')
        that.selTab('RSS', 'dept')

        if (this.routeParam.countryCd === 'CA') {
          that.selMap('CA', 'Canada')
        } else {
          that.selMap('US', 'USA')
        }
      })
    },
    url () {
      location.href = 'support/contactus/pro'
    },
    initMap () {
      const that = this
      // map 객체 세팅.
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: {
          lat: that.options[that.$countryCode][that.routeParam.countryCd].latitude,
          lng: that.options[that.$countryCode][that.routeParam.countryCd].longitude
        },
        zoom: that.options[that.$countryCode][that.routeParam.countryCd].zoom
      })
      // 위치 기반
      this.geocoder = new google.maps.Geocoder()
      // 위치검색
      const searchBox = new google.maps.places.SearchBox(document.getElementById('searchInput'))
      // 초기 컬러를 회색
      this.map.setOptions({ styles: this.options.styles.silver })

      google.maps.event.addListener(searchBox, 'places_changed', function () {
        const place = searchBox.getPlaces()
        if (place.length !== 0) {
          that.searchValue.keyword = place[0].formatted_address
        }
        that.goSearch()
      })
    },
    selTab (code, type) {
      const that = this
      const orgVal = _.cloneDeep(this.orgValue)// prevent corrupt data
      _.mergeWith(this.form, orgVal, function (tgtValue, srcValue, key) {
        if (key === 'contact') {
          // if there is no key in orgValue, form data return
          _.forIn(tgtValue, function (v, k) {
            if (_.isUndefined(srcValue[k])) {
              return v
            }
          })
        } else {
          return srcValue
        }
      })// form init setting
      if (type === 'category') {
        this.searchValue.categoryCode = code
        if (code === 'HFLOR') {
          this.form.contact.inqDictId = 'INQ_HFLOR_PRO' // dictionary Id setting
          this.searchValue.dept = 'DIST'// subtab first setting
          this.form.contact.categoryCode = 'HFLOR'
          this.form.contact.productName = code
        } else {
          this.form.contact.inqDictId = 'INQ_HIMACS_PRO' // dictionary Id setting
          this.searchValue.dept = 'RSS'// subtab first setting
          this.form.contact.categoryCode = 'HIMACS'
          this.form.contact.productName = code
        }
      } else if (type === 'dept') {
        if (this.searchValue.categoryCode === 'HFLOR') {
          this.form.contact.inqDictId = 'INQ_HFLOR_PRO' // dictionary Id setting
        } else {
          this.form.contact.inqDictId = 'INQ_HIMACS_PRO' // dictionary Id setting
        }
        if (code == 'RSS') {
          this.typeTitle = 'For K&B'
        } else if (code == 'TM') {
          this.typeTitle = 'For Fabricator/Installer'
        }
        this.searchValue.dept = code
      }
      // every tab action selected countryCd
      const country = _.find(this.form.countries, (v) => {
        return v.countryCd === that.routeParam.countryCd
      })
      if (!_.isUndefined(country)) {
        this.form.selectedCountry = country.countryNm
        this.form.contact.countryCd = country.countryCd
        this.form.contact.countryText = country.countryNm
      }
      this.countryChanged() // state change
      if (this.routeParam.countryCd === 'CA') {
        that.selMap('CA', 'Canada')
      } else {
        this.selMap('US', 'USA') // map default
      }
      // 화면클리어
      this.clearOverlays()
      this.viewList = []
    },
    selVal (value, text, type) {
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()

      if (type === 'inquiry') {
        this.form.selectedInquiry = text
        this.form.contact.inqDictVal = value
        this.form.contact.inquiryText = text
      } else if (type === 'occupation') {
        this.form.selectedOccupation = text
        this.form.contact.occText = text
        this.form.contact.occupation = value.split('_')[0]
      } else if (type === 'country') {
        this.form.selectedCountry = text
        this.form.contact.countryCd = value
        this.form.contact.countryText = text
      } else if (type === 'state') {
        this.form.selectedState = text
        this.form.contact.stateCd = value
        this.form.contact.stateText = text
      }
      // department setting
      // HFLOR -> inquiry (X), only category Code
      // ViA/Hi -> inquiry (O), occupation (O)
      if (this.form.contact.countryCd === 'US') {
        // us
        if (this.searchValue.categoryCode === 'HFLOR') {
          // hflor only one to one relation.
          this.form.contact.Department = 'HFL'
          this.form.contact.zipType = 'N'
        } else {
          const val = this.form.contact.inqDictVal
          let deptVal = ''
          this.form.contact.zipType = 'N'
          if (_.includes(val, '007')) { // inquiry is 007 (TechService)
            deptVal = 'TEC'
          } else if (_.includes(val, '008')) { // inquiry is 008 (Warranty)
            deptVal = 'WAR'
          } else if (_.includes(val, '009')) { // inquiry is 009 (Other, marketting)
            deptVal = 'MKT'
          } else {
            if (this.searchValue.dept === 'SM' || this.searchValue.dept === 'RSS' || this.searchValue.dept === 'TM') {
              this.form.contact.zipType = 'Y' // zipCode search available
              deptVal = this.searchValue.dept
            }
          }
          this.form.contact.Department = deptVal
        }
      } else {
        // canada (categoryCode seperate)
        this.form.contact.Department = 'CAN'
      }
    },
    selMap (value, text) {
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()

      this.form.selectedMapCountry = text
      this.searchValue.countryCd = value
      this.searchValue.keyword = ''
      // map clear
      this.clearOverlays()
      // move default coordinate of country
      const pos = {
        lat: this.options[this.$countryCode][value].latitude,
        lng: this.options[this.$countryCode][value].longitude
      }
      this.map.setCenter(pos)
      this.viewList = []
      if (value === 'CA') {
        if (this.searchValue.categoryCode === 'HFLOR') {
          if (this.searchValue.dept !== 'TM') {
            this.goSearch()
          }
        } else {
          if (this.searchValue.dept === 'SM') {
            this.goSearch()
          }
        }
        $('.fasr-map').css('height', '150px')
        $('.sch-kw-box').css('display', 'none')
      } else {
        $('.fasr-map').css('height', '650px')
        $('.sch-kw-box').css('display', '')
      }
    },
    async countryChanged () {
      const that = this
      if (this.form.contact.countryCd && this.form.contact.countryCd.length > 0) {
        this.form.states = this.form.statesData[this.form.contact.countryCd]
        const found = _.find(this.form.states, function (v) {
          return v.id.stateCd === that.form.contact.stateCd
        })
        if (!_.isUndefined(found)) {
          this.form.selectedState = found.stateNm
          this.form.contact.stateCd = found.stateCd
        } else {
          this.form.selectedState = 'Select '// 셀렉트 박스 표시 초기화
          this.form.contact.stateCd = ''
        }
      } else {
        this.form.states = this.form.statesData.org
        this.form.selectedState = 'Select '// 셀렉트 박스 표시 초기화
      }
    },
    validFunction (name) {
      const that = this
      return {
        inqDictVal: function () {
          if (!_.isEmpty(that.form.contact.inqDictVal)) {
            that.validation.inqDictVal.value = true
          } else {
            that.validation.inqDictVal.value = false
          }
        },
        firstNm: function () {
          if (!_.isEmpty(that.form.contact.firstNm)) {
            that.validation.firstNm.value = true
          } else {
            that.validation.firstNm.value = false
          }
        },
        lastNm: function () {
          if (!_.isEmpty(that.form.contact.lastNm)) {
            that.validation.lastNm.value = true
          } else {
            that.validation.lastNm.value = false
          }
        },
        email: function () {
          const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식
          that.validation.email.value = regex.test(that.form.contact.email)
        },
        phone: function () {
          if (!_.isEmpty(that.form.contact.phone)) {
            that.validation.phone.value = true
          } else {
            that.validation.phone.value = false
          }
        },
        country: function () {
          if (!_.isEmpty(that.form.contact.countryCd)) {
            that.validation.country.value = true
          } else {
            that.validation.country.value = false
          }
        },
        stateCd: function () {
          if (!_.isEmpty(that.form.contact.stateCd)) {
            that.validation.stateCd.value = true
          } else {
            that.validation.stateCd.value = false
          }
        },
        zipCd: function () {
          if (!_.isEmpty(that.form.contact.zipCd)) {
            that.validation.zipCd.value = true
          } else {
            that.validation.zipCd.value = false
          }
        },
        companyNm: function () {
          if (!_.isEmpty(that.form.contact.companyNm)) {
            that.validation.companyNm.value = true
          } else {
            that.validation.companyNm.value = false
          }
        },
        companyAddr: function () {
          if (!_.isEmpty(that.form.contact.companyAddr)) {
            that.validation.companyAddr.value = true
          } else {
            that.validation.companyAddr.value = false
          }
        },
        occupation: function () {
          if (!_.isEmpty(that.form.contact.occupation)) {
            that.validation.occupation.value = true
          } else {
            that.validation.occupation.value = false
          }
        }
      }[name]()
    },
    validateForm () {
      const that = this
      let flagCnt = 0
      _.forIn(this.validation, function (v, k) {
        that.validFunction(k)
        if (!v.value) {
          flagCnt++
        }
      })
      this.form.execValidFlag = true
      return !(flagCnt > 0)
    },
    goSearch () {
      const that = this

      if (that.searchValue.countryCd == 'US') {
        const t = this.geocoder
        this.geocoder.geocode({ address: this.searchValue.keyword }, function (results, status) {
          if (status === 'OK') {
            // 찾았을 경우, 해당 위치의 latitude, longtitude 를 hidden input에 저장한 후 이를 가지고 DB의 판매처 목록을 검색한다.
            const position = results[0].geometry.location
            // 주 정보 입력
            const state = _.filter(results[0].address_components, (v) => {
              if (_.indexOf(v.types, 'administrative_area_level_1') !== -1) {
                return v
              }
            })

            if (state.length > 0) {
              that.searchValue.stateCd = state[0].short_name
            } else {
              that.searchValue.stateCd = ''
            }
            // console.log('lat : lng = ' + position.lat() + ' : ' + position.lng())
            // 좌표입력
            that.searchValue.latitude = position.lat()
            that.searchValue.longitude = position.lng()
            // zip code
            const zip_code = _.filter(results[0].address_components, (v) => {
              if (_.indexOf(v.types, 'postal_code') !== -1) {
                return v
              }
            })

            if (zip_code.length > 0) {
              that.searchValue.zip = zip_code[0].short_name
            } else {
              // zipcode 없을 경우 위도, 경도로 가져오기
              t.geocode({ location: results[0].geometry.location }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                  var result = results[0].address_components
                  for (var i = 0; i < result.length; ++i) {
                    if (result[i].types[0] == 'postal_code') {
                      that.searchValue.zip = result[i].long_name
                    }
                  }
                } else {
                  that.searchValue.zip = ''
                }
              })
            }
            // 목록 출력
            that.getList()
          } else {
            that.searchValue.latitude = ''
            that.searchValue.longitude = ''
            that.searchValue.stateCd = ''
            that.searchValue.zip = ''
            alert('No locations match your search. Please try again.')
          }
        })
      } else {
        that.getListCa()
      }
    },
    async getList () {
      // 검색데이터 세팅
      const postData = {}
      postData.locLat = this.searchValue.latitude + ''
      postData.locLng = this.searchValue.longitude + ''
      postData.mileRange = this.searchValue.mile + ''
      postData.repType = this.searchValue.dept
      postData.stateCd = this.searchValue.stateCd
      postData.categoryCode = this.searchValue.categoryCode
      postData.siteType = this.$countryCode.substring(1)
      postData.zipType = this.searchValue.zip

      // US site, HFLOR 일때 state 정보가 없을 경우 검색 불가처리.
      if (postData.stateCd === '' && postData.siteType === 'US') {
        alert('state info not found')
        return
      }

      // 화면클리어
      this.clearOverlays()
      this.viewList = []

      // 조회된 목록을 보여주는 목록과 저장목록에 저장함.
      let res
      if (this.searchValue.dept === 'DIST') {
        postData.categoryCode = 'PRD_FLOORING'
        res = await supportService.getStoreList(postData)
        const strList = res.data.storeList
        this.viewList = _.filter(strList, function (v) {
          return v.dealerType === 'dlr_004' // distributor filter
        })
        this.orgViewList = _.cloneDeep(this.viewList)
        // 저장된 목록을 기준으로 맵 마커 작성
        _.forEach(this.viewList, (v) => {
          this.markMap(v)
        })
      } else {
        res = await supportService.getListSalesRepByZip(postData)
        this.viewList = res.data.repZipList
        this.orgViewList = res.data.repZipList

        if (this.viewList.length > 0) {
          if (this.viewList.length == 1) {
            if (this.viewList[0].latitude == null || this.viewList[0].longitude == null) {
              this.markMap(this.searchValue)
            } else {
              this.markMap(this.viewList[0])
            }
          } else {
            // 저장된 목록을 기준으로 맵 마커 작성
            _.forEach(this.viewList, (v) => {
              this.markMap(v)
            })
          }
        }
      }

      // 맨처음 index의 정보일 경우 지도에 바로 이동
      this.goStore(0, this.searchValue.latitude, this.searchValue.longitude)
      // 컬러를 기본색
      this.map.setOptions({ styles: this.options.styles.default })
    },

    async getListCa () {
      // 검색데이터 세팅
      const postData = {}
      postData.reqType = 'Pro'
      postData.countryCd = this.searchValue.countryCd
      postData.categoryCode = this.searchValue.categoryCode
      postData.siteType = this.$countryCode.substring(1)

      // 화면클리어
      this.clearOverlays()
      this.viewList = []

      // 조회된 목록을 보여주는 목록과 저장목록에 저장함.
      const res = await supportService.getListFindRep(postData)
      this.viewList = res.data.list
      this.orgViewList = res.data.list
    },

    markMap (data) {
      const that = this
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(data.latitude, data.longitude), // 전달받은 판매처 정보의 lat, lng 정보를 위치로 저장.
        map: that.map // initMap function에서 선언된 map 객체.
      })

      // 마커 객체에 이벤트 리스너를 배정한다. (실제자체로는 click 이벤트를 받는 방법이 없는 듯)
	  	marker.addListener('click', function () {
        setTimeout(function () {}, 1000)

        $('.gm-style-iw-a').parent().hide()// 기존의 정보팝업을 지움.
        // infowindow.open(that.map, marker)// 마커의 정보 팝업 열기. map객체와 상단에 선언된 marker 객체를 전달.
        that.map.setCenter({ lat: data.latitude, lng: data.longitude })// map객체에 판매처 목록의 lat, lng 정보를 넣어 지도 중앙으로 이동.
      })

      this.markers.push(marker)// marker 객체를 markers array에 저장.
    },
    goStore (idx, latitude, longitude, event) {
      // idx는 markers array에 저장된 marker객체를 index 순으로 가져옴.
      this.map.setCenter({ lat: latitude, lng: longitude })
      this.map.setZoom(11)
      google.maps.event.trigger(this.markers[idx], 'click')
    },
    clearOverlays () {
      _.forEach(this.markers, (v) => {
        v.setMap(null)
      })
      this.markers = []
    },
    async confirm () {
      // validation
      if (!this.validateForm()) {
        return
      }
      // comment 글자수 체크
      if (this.form.contact.comment.length > 1000) {
        alert('The maximum number of characters is 1,000 characters.')
        return
      }
      // zipCode check
      const checkZip = await supportService.getCheckZip(this.form.contact.zipCd)
      if (!checkZip.data.type) {
        alert('There is no matched zipcode')
        return
      }

      // 동의 여부 체크 및 저장, 메일 발송
      if (this.form.agree) {
        const formData = new FormData()
        _.forIn(this.form.contact, function (v, k) {
          formData.append(k, v)
        })
        const result = await supportService.getContactUsSave(formData)
        if (result.data.Type === true) {
          const info = {
            event: 'findaSalesRepComp',
            sales_rep_brand: 'VIATERA/HIMACS',
            sales_rep_type: this.typeTitle,
            sales_rep_type_of_inquiry: formData.get('inquiryText'),
            sales_rep_country: formData.get('countryText'),
            sales_rep_state_province: formData.get('stateText'),
            sales_rep_zip_postal_code: formData.get('zipCd'),
            sales_rep_company_name: formData.get('companyNm'),
            sales_rep_company_address: formData.get('companyAddr'),
            sales_rep_company_website: formData.get('companyWeb'),
            sales_rep_occupation: formData.get('occText'),
            sales_rep_job_title: formData.get('job')
          }
          if (typeof (dataLayer) !== 'undefined') {
            dataLayer.push(info)
          }
          alert('The application has been completed')
          location.reload()
        } else {
          alert('Error')
        }
      } else {
        alert('Please check whether you agree to collect personal information.')
      }
    },
    openPrivacy () {
      window.open('etc/privacy?pop=Y', 'privacy', 'width:800px')
    },
    openTerms () {
      window.open('etc/terms?pop=Y', 'terms', 'width:800px')
    }
  },
  computed: {
    showMap () {
      // HFLOR-DIST, HFLOR-SM, VIA/HI-SM -> true
      if ((this.searchValue.categoryCode === 'HFLOR' && (this.searchValue.dept === 'SM' || this.searchValue.dept === 'DIST')) ||
       (this.searchValue.categoryCode === 'HIMACS,VIATERA' && this.searchValue.dept === 'SM')) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    deep: true,
    'form.contact.countryCd': {
      deep: true,
      handler () {
        this.countryChanged()
      }
    },
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
        // this.initMap()
        // this.init()
        // activeArrowTab()
      }
    }
  }
}
</script>
