var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box seg-box" }, [
    _c("h2", { staticClass: "tit-main" }, [
      _vm._v(_vm._s(_vm.$t("main.text.segment"))),
    ]),
    _c("div", { staticClass: "tab-ui tab-ui-nav" }, [
      _c("ul", { staticClass: "justify-center" }, [
        _c(
          "li",
          {
            staticClass: "btn-tab on",
            attrs: { "data-tab": "seg-all" },
            on: {
              click: function ($event) {
                return _vm.sliderReset("seg-all")
              },
            },
          },
          [
            _c("button", { attrs: { type: "button" } }, [
              _vm._v(_vm._s(_vm.$t("main.text.all"))),
            ]),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "btn-tab",
            attrs: { "data-tab": "seg-com" },
            on: {
              click: function ($event) {
                return _vm.sliderReset("seg-com")
              },
            },
          },
          [
            _c("button", { attrs: { type: "button" } }, [
              _vm._v(_vm._s(_vm.$t("main.text.seg_com"))),
            ]),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "btn-tab",
            attrs: { "data-tab": "seg-res" },
            on: {
              click: function ($event) {
                return _vm.sliderReset("seg-res")
              },
            },
          },
          [
            _c("button", { attrs: { type: "button" } }, [
              _vm._v(_vm._s(_vm.$t("main.text.seg_res"))),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "slider-margin-0" }, [
        _c("div", { staticClass: "tab-con seg-all on" }, [
          _c("div", { staticClass: "overscr-list-wrap" }, [
            _c("div", { staticClass: "overscr-list" }, [
              _c(
                "ul",
                {
                  staticClass: "drag_segments",
                  class: {
                    euSlide:
                      _vm.nationCode !== "_GL" && _vm.nationCode !== "_US",
                  },
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "vod",
                      attrs: {
                        "data-aos": "fade-up",
                        "data-aos-duration": "600",
                        "data-aos-delay": "750",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.$imagePath +
                            "/hausys/US/image/main/segment/seg-01.jpg",
                          alt: "segments img",
                        },
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "seg-menu-tit",
                          attrs: { href: "segments/world" },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("main.text.hland"))),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._l(_vm.categoryList, function (segment) {
                    return _c(
                      "li",
                      {
                        attrs: {
                          "data-aos": "fade-up",
                          "data-aos-duration": "600",
                          "data-aos-delay": "500",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.$imagePath + segment.categoryAttribute.img,
                            alt: "segments img",
                          },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "seg-menu-tit",
                            attrs: { href: "javascript:void(0)" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "menu-box",
                                staticStyle: { "padding-top": "120px" },
                              },
                              [
                                _c(
                                  "ul",
                                  [
                                    _vm._l(segment.related, function (child) {
                                      return segment.category.code !==
                                        "GNB_SEG_HW_RET" + _vm.nationCode &&
                                        segment.category.code !==
                                          "GNB_SEG_HW_RES" + _vm.nationCode
                                        ? _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$utils.gotoUrl(
                                                      child.url
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(child.name))]
                                            ),
                                          ])
                                        : _vm._e()
                                    }),
                                    _vm._l(_vm.retailList, function (retail) {
                                      return segment.category.code ===
                                        "GNB_SEG_HW_RET" + _vm.nationCode
                                        ? _c("li", [
                                            retail.category.name !== "Reception"
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0)",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$utils.gotoUrl(
                                                          retail.category.url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        retail.category.name
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : retail.category.name ===
                                                  "Reception" &&
                                                retail.category.code ===
                                                  "GNB_SEG_HW_RET_BEU_REC" +
                                                    _vm.nationCode
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0)",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$utils.gotoUrl(
                                                          retail.category.url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        retail.category.name
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "main.text.retail_bea"
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : retail.category.name ===
                                                  "Reception" &&
                                                retail.category.code ===
                                                  "GNB_SEG_HW_RET_FIT_REC" +
                                                    _vm.nationCode
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0)",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$utils.gotoUrl(
                                                          retail.category.url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        retail.category.name
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "main.text.retail_fit"
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e()
                                    }),
                                    _vm._l(
                                      _vm.residential,
                                      function (residential) {
                                        return segment.category.code ===
                                          "GNB_SEG_HW_RES" + _vm.nationCode
                                          ? _c("li", [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$utils.gotoUrl(
                                                        residential.category.url
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      residential.category.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e()
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$utils.gotoUrl(
                                      segment.category.url
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(segment.category.name))]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "txt-box" }),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }