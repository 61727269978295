<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content content-finder">
      <!-- location -->
      <div class="location" :class="{ 'type02': nationCode === '_GL' }">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{
              $t('PCD.text.home')
            }}
          </li>
          <li>{{ $t('PCD.text.products') }}</li>
          <li>{{ $t(`PF.text.${pcdMenuType.toLowerCase()}`) }}</li>
          <li>{{ $t(`PF.text.allColors.${$route.meta.parentType.toLowerCase()}`) }}</li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container (Finder) -->
      <div class="container prod-fd-view container_pf vizuon-pf">
        <h1 class="title_hidden" v-if="nationCode==='_US'">{{ $t(`PF.text.allColors.${$route.meta.parentType.toLowerCase()}`) }}</h1>
        <!-- visual -->
        <!-- start -->
        <div class="visual visual-detail" v-if="pcdContentAttribute.main_use_yn === 'true' && nationCode==='_GL'">
          <div class="visual-image full_width center"
               v-bind:style="{ backgroundImage: `url('${$imagePath}${pcdContentAttribute.main_img}')` }">
            <div class="inner_width">
              <div class="txt-box" v-if="nationCode !== '_US'">
                <h1>
                  <div class="title">
                    <span v-if="visualImageLoadError" class="txt">{{ pcdContentAttribute.main_title }}</span>
                    <img v-if="!visualImageLoadError"
                         :src="$imagePath +'/2024/images/main/logo/' + $route.meta.type.toUpperCase() + '.svg'"
                         alt="about product" class="visual_logo" @error="visualImageLoadError=true"
                         @load="visualImageLoadError=false"/>
                    <!--                    <img v-if="!visualImageLoadError" :src="$imagePath +'/2024/images/main/logo/' + pcdMenuType.toLowerCase() + '.svg'" alt="product" class="pf_visual_logo" @error="visualImageLoadError=true" @load="visualImageLoadError=false"/>-->
                  </div>
                  <p class="txt-02">{{ pcdContentAttribute.main_desc }}</p>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->

        <div class="drop-list inner">
          <div class="w-con-02">
            <div class="gallery">
              <div class="search-box">
                <div class="filter_area">
                  <div class="filter_wrap">
                    <button type="button" class="btn_filter" @click="clickFilterBtn()">
                      <span class="icon icon_filter"></span>
                      <span class="txt p-ui">Filter</span>
                      <span class="txt m-ui">Filter by</span>
                      <span class="icon icon_arrow_down"></span>
                    </button>
                  </div>
                  <div class="compare_wrap">
                    <input type="checkbox" id="compare_btn" class="compare_btn" name="compare_button" value="">
                    <label for="compare_btn">
                      <span class="txt">ON</span>
                      <span class="btn"></span>
                    </label>
                    <span>Compare</span>
                  </div>
                  <div class="search">
                    <input type="text" id="textSearch" :placeholder="this.$t('PF.text.searchText')" v-model="searchText"
                           @input="textSearch"
                           @keyup.enter="textSearch"
                    />

                    <button type="button" class="btn_cancel" v-if=" searchText.length !== 0"
                            @click="textSearchDel()"
                    />
                    <button type="button" id="searchBtn" class="icon_search" @click="textSearchDel()" v-else>
                      {{ $t('PF.button.search') }}
                    </button>
                  </div>
                  <!-- s: filter popup -->
                  <transition name="fade">
                    <div class="filter_pop">
                      <div class="filter_tit"><p>FILTER BY</p>
                        <button type="button" class="btn_close" @click="closeFilterPop()"></button>
                      </div>
                      <div class="filter_top">
                        <component
                          v-if="category.id"
                          :is='filterComponent'
                          :category="category"
                          :collectionList="collectionList"
                          :dictMap="dictMap"
                          :countryCode="countryCode"
                          v-model="filter"
                          @category-changed="handleCategoryChanged"
                          @category-changed-vizuon="handleCategoryChangedVizuon"
                          @change="handleCheckBox($event,'productList','productCheckList',0)"/>
                      </div>
                      <!--gl-->
                      <div class="filter_bottom">
                        <div class="btn_box">
                          <button class="btn_wh" @click="filterClearAll">Clear All</button>
                          <button class="btn_bk" @click="applyFilter">Apply</button>
                        </div>
                      </div>
                    </div>
                  </transition>
                  <!-- e: filter popup -->
                </div>
                <div class="sort_area">
                  <div class="select-view" v-if="selectedFilters.length > 0 || showCategory()">
                    <ul class="list-filter-key">
                      <li class="sel" v-if="showCategory()">
                        <span>CATEGORY: {{ category.name }}</span>
                        <button type="button" @click="handleCategoryChanged('PRD_FLOORING')"></button>
                      </li>
                      <li class="sel" v-for="filter in selectedFilters" :key="filter.name">
                        <span>{{ getFilterLabel(filter) }}</span>
                        <button type="button" @click="removeFilter(filter)"></button>
                      </li>
                      <li class="sel del_all">
                        <button type="button" class="btn-all-delete" @click="clearSelectedFilters">
                          <span>{{ $t('PF.button.clear_all') }}</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="btns">
                    <ul>
                      <li class="sel-box">
                        <button type="button" class="btn-sel">
                          <span>Most Recent</span>
                        </button>
                        <div class="sel-list">
                          <ul class="box">
                            <li>
                              <button type="button" @click="
                                sortByChange('Products A to Z', 'asc', 'title')
                                ">
                                {{ $t('PF.button.products_a_to_z') }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="
                                sortByChange('Products Z to A', 'desc', 'title')
                                ">
                                {{ $t('PF.button.products_z_to_a') }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="sortByChange('Most Recent', 'asc')">
                                {{ $t('PF.button.new_products_first') }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="gallery_layout_btns">
                    <button type="button" class="layout_grid on">
                      <span class="icon icon_grid"></span>
                    </button>
                    <button type="button" class="layout_list">
                      <span class="icon icon_list"></span>
                    </button>
                  </div>
                </div>
                <div class="count_area">
                  <div class="filter-cnt">
                    <span class="num">{{ contentLength }}</span>
                    <span>&nbsp;</span>
                    <span class="txt">Results</span>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="isInitialLoad">
              <div class="loading_area">
                <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
              </div>
            </template>
            <template v-else-if="!isLoading && contentList.length === 0">
              <div class="grid-box">
                <div class="no_result">
                  <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult">
                  <p>No Result found</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="list-type-view row-04">
                <ul>
                  <li v-for="(item, index) in contentList" :key="index">
                    <router-link :to="makeUrl(item)" :data-ec-product="makeDataLayerForm(item)">
                      <div class="img-box">
                        <span class="tag h30 new" v-if="item.attribute.isNew == 'true'">{{ $t('PF.text.new') }}</span>
                        <div class="image_name_and_tag">
                          <span class="image_name">{{ item.content.title }}</span>
                        </div>
                        <div class="icon_tag_wrap">
                          <i class="icon-no-fire"
                             v-if="item.attribute.fire_resistance === '001'"><span>no-fire</span></i>
                          <i class="icon-recycled-chips"
                             v-if="item.attribute.isRecycled === 'true'"><span>recycled</span></i>
                        </div>
                        <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb"/>
                      </div>
                    </router-link>
                    <div class="view" v-if="nationCode === '_US' && canOrder(item)">
                      <a @click="openSampleOrder(item.content.id)" class="icon_cart">{{ $t('PCD.text.cart') }}</a>
                    </div>
                    <div class="pf_cont_compare_wrap">
                      <input type="checkbox" id="item.content.id">
                      <label class="inp-chk">
                        <input type="checkbox" class="pf_cont_compare" v-model="compareArr" :id="item.content.id"
                               :value="item.content.id" @change="addCompare(item.content.id)">
                        <span>{{ $t('PF.button.compare') }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="loading_area" v-if="isLoading">
                <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
              </div>
            </template>
          </div>
        </div>

        <!-- popup : alert -->
        <div class="layer-popup layer-alert">
          <div class="popup">
            <div class="pop-alert">
              <div>
                <div class="tit-box">
                  <p class="popup_title artTit">{{ $t('PF.popup.your_compare_is_full') }}</p>
                </div>

                <div class="txt-box">
                  <div class="imgwrap">
                    <img src="https://img.lxhausys.com/images/svg_icon/icon_alert_pop_check.svg"
                         alt="Your comparator is full">
                  </div>

                  <div class="txt">
                    <p>
                      Do you want to replace the first Products <br>
                      you added by the one you selected?
                    </p>
                  </div>

                  <div class="btn-box">
                    <button type="button" id="replace" class="btn h44" @click="replace()">
                      <span>{{ $t('PF.popup.replace') }}</span></button>
                  </div>
                </div>
              </div>

              <div class="bottom-box">
                <a @click="getCompareList()">
                  <span>Edit products in compare</span>
                </a>
              </div>

              <button type="button" class="btn-close" onclick="LayerPopup.close();">
                close
              </button>
            </div>
          </div>
          <!-- //popup -->
        </div>
        <!-- //popup : alert -->

        <!-- popup : Compare -->
        <div class="layer-popup layer-compare" id="pdf_wrap">
          <div class="popup pop-close">
            <div class="pop-compare scr-y">
              <div class="tit-box">
                <p>COMPARE {{ $t(`PF.text.${pcdMenuType.toLowerCase()}`) }} PRODUCTS</p>
              </div>
            </div>
            <div class="popup-compare-cont">
              <div class="subtit-box">
                <p>Characteristics &#38; Technical Performance</p>
              </div>
              <div class="prod-list tbl-compare">
                <ul>
                  <li v-for="(item,idx) in compareList">
                    <div class="prod-box">
                      <div class="prod">
                        <div class="img-box">
                          <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb"/>
                          <div class="btn-remove">
                            <button type="button" class="btn-remove02" @click="removeCompare(item.content.id)">
                              <span>{{ $t('PF.button.remove') }}</span></button>
                          </div>
                        </div>

                        <div class="info">
                          <div class="name">
                            <span>{{ item.content.title }}</span>
                          </div>
                          <div class="tag">
                            <span>{{ item.parents[0].name }}</span>
                          </div>
                        </div>

                        <div class="tbl_wrap">
                          <table>
                            <template v-if="pcdMenuTitle === 'HIMACS'">
                              <tr>
                                <th><span>Slab Size (inch)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.dimensionText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness (inch)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thicknessText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Adhesive code</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.adhesive_code || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Weight (lbs)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.weightText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Adhesive name</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.adhesive_name || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'VIATERA'">
                              <tr>
                                <th><span>Finish</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.finishText[0] || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Slab Size (inch)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span v-html="getAllSlabSizes(item)"></span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness (cm)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span v-html="getAllThicknesses(item)"></span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Weight (lbs)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span v-html="getAllWeights(item)"></span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Remark</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.remark && item.remark !== '[]' ? item.remark : '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'TERACANTO'">
                              <tr>
                                <th><span>Finish</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span v-html="getAllFinishes(item)"></span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Dimension (mm)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span v-html="getAllDimensions(item)"></span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span v-html="getAllTeraThicknesses(item)"></span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Bookmatch</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.bookmatchText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Remark</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.remark || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'HFLOR'">
                              <tr>
                                <th><span>Dimensions</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.dimensionText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thicknessText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Wear Layer</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.wear_layerText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Pattern</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.patternText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Installation</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.prdTypeText || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'Deco Foil'">
                              <tr>
                                <th><span>PET</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.pet ? 'O' : '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>PVC</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.pvc ? 'O' : '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>ACRYLIC</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.acrylic ? 'O' : '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thicknessText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Width</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.widthText || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'BENIF'">
                              <tr>
                                <th><span>Dimensions</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.dimensionText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Repeat Image</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.repeat || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thicknessText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Fire Resistance</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.fire_resistanceText || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'VIZUON'">
                              <tr>
                                <th><span>Film Type Material</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.material || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Film Type Thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thickness || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Film Type Color</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.ft_color || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Film Type Adhesive Type</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.adhesive_typeText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Adhesive Type Color</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.adhesive_color || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Adhesive Type Air Free</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.air_freeText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Liner</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.liner || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'Exterior Foil'">
                              <tr>
                                <th><span>Grade</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.gradeText[0] || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thicknessText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Width</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>
                                    {{
                                      (item.attribute.widthText ?
                                        `${item.attribute.widthText}${item.attribute.width2Text ? ` / ${item.attribute.width2Text}` : ''}`
                                        : '-')
                                    }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Length</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.lengthText || '-' }}</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else-if="pcdMenuTitle === 'BORTE'">
                              <tr>
                                <th><span>Dimension</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.dimensionText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Surface material</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.surfaceMaterialText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Total thickness</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.thicknessText || '-' }}</span>
                                </td>
                              </tr>
                              <tr>
                                <th><span>Formaldehyde emission (mg/l)</span></th>
                              </tr>
                              <tr>
                                <td>
                                  <span>{{ item.attribute.emissionText || '-' }}</span>
                                </td>
                              </tr>
                          </template>
                          </table>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="add_more">
                    <div class="prod-box">
                      <div class="prod" v-if="compareList.length < 4">
                        <div class="img-box">
                          <div class="btn-add hf-btn-add">
                            <button type="button" onclick="LayerPopup.close();">
                              <span>{{ $t('PF.button.add_more') }}</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="btn-download p-ui">
                <button type="button" class="button h44 btn_download" v-if="compareList.length >= 1" @click="makePDF()">
                  <span class="txt">Download</span>
                  <span class="icon icon_download"></span>
                </button>
              </div>

              <button type="button" class="btn-close" onclick="LayerPopup.close();$('body').removeClass('scrolllock');">
                close
              </button>
            </div>

            <div class="btn-download m-ui">
              <button type="button" class="button h44 btn_download" v-if="compareList.length >= 1" @click="makePDF()">
                <span class="txt">Download</span>
                <span class="icon icon_download"></span>
              </button>
            </div>

          </div>
        </div>
        <!-- //popup : Compare -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb"
             style="display: none">
          <div class="popup">
            <div class="scr-y">
              <!-- color / us | gl -->
              <div class="drop-list">
                <p class="tt-2">Filter By</p>
                <button class="filter-clos" @click="closeSearchPopup()"></button>
                <ul>
                  <li class="on">

                    <!-- us / gl title -->
                    <div class="tit" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <span>{{ $t('PD.text.color') }}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.button.color') }}
                      </button>
                    </div>
                    <!--  기존 as-is 소스 여서 주석처리                   -->
                    <!--                    <div class="i-box type02 cont" style="display: block"
                                             v-if="nationCode != '_GL' && nationCode != '_US'">
                                          <ul class="i-chk type03">
                                            <li v-for="lefthue in lefthue">
                                              <label class="inp-chk chk-color">
                                                <input type="checkbox" name="color" :id="'hue_' + lefthue.id.value" @change="
                                                    [
                                                      fnSearch(
                                                        'hue',
                                                        lefthue.text,
                                                        lefthue.id.value,
                                                        'Tone'
                                                      ),
                                                    ]
                                                  "/>
                                                <span>{{ lefthue.text }}</span>
                                              </label>
                                              &lt;!&ndash; 이방식으로 교체합니다&ndash;&gt;
                                            </li>
                                          </ul>
                                        </div>-->
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->
      </div>
      <!-- //container (product Finder) -->
    </div>
    <!-- //content -->
    <!-- s: 화면 우측 compare button -->
    <div class="more-box type02 type03 compare_box">
      <button type="button" class="button h55 btn_compare" id="compare" @click="getCompareList()">
        <span class="txt">{{ $t('PF.button.compare_your_colors') }}</span>
        <span class="bubble_num">
          <span>{{ compareArr.length }}</span>
        </span>
      </button>
    </div>
    <!-- s: 화면 우측 compare button -->
    <QuickMenuComponent></QuickMenuComponent>
    <!--    <ShortComponent></ShortComponent>-->
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import { getCollectionList } from '@/server/category'
import categoryService from "@/server/category.service"
import ProductService from '@/server/product.service'
import { EventBus } from '@/utils/event_bus'

import _ from '@/common/helper/lodash'
import {
getCategoryCodeByPath,
getDictionaryMapByCateCode,
getPcdCategoryCodeByPath, getProductInfoByPath,
getProductNameByPath
} from '@/views/products/finder/finder'
import { getEcomPrdData } from "@/views/products/js/product-detail"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { mapState } from 'vuex'

export default {
  name: 'CommonProductFinder',
  props: ['PfContentId', 'PfCateCode', 'filterVal',
    'filterVal1',  'filterVal2', 'filterSeo', 'filterSeo1', 'filterNm3', 'filterVal3'],
  components: {
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    ...mapState(['filterState']),
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    }),
    uniqueColors() {
      return _.uniqBy(this.colorList, 'name')
    },

    filterComponent() {
      const cateCode = this.category.code
      return () => import(`./finder/${cateCode}.vue`)
    },
    filterState: {
      get() {
        return this.$store.state.filterState;
      },
      set(value) {
        this.$store.commit('SET_FILTER_STATE', value);
      }
    },
    hflorCateCode: {
      get() {
        return this.$store.state.hflorCateCode;
      },
      set(value) {
        this.$store.commit('SET_CATECODE_STATE', value);
      }
    },
  },
  data() {
    return {
      category: {code: ''},
      collectionList: [] /* 컬렉션 불러오자! */,
      dictMap: {},
      showPopup: false,
      storeFilter: {},
      selectedFilters: [],
      isInitialLoad: true, /* 첫페이지 로딩 때 noresult 안뜨게 */
      selectedCategory: 'PRD_FLOORING',

      // hflorCateCode: '',
      pcdContent: [],
      pcdContentAttribute: {},
      visualImageLoadError: false,
      pcdMenuTitle: '',
      pcdMenuType: '',
      pcdMenuPath: '',

      filter: {},
      pageSize: 16,
      pageIndex: 1,
      orderField: [],
      orderType: [],
      selected: {},
      contentList: [],
      contentLength: 0 /* 해당 컨텐츠 길이 체크 ( All Product에 박을꺼임) */,

      pfBanImg: null,
      isLoading: false,
      nationCode: '', /* 국가 코드 */
      categoryId: '',
      checkCollectionList: [],
      collectionLength: '',

      searchText: '' /* 검색했을때 의 input 값 */,
      /* compare */
      compareArr: [],
      compareRes: {list: []},
      compareDetail: [],
      compareList: [],
      compareId: '',

      replaceId: '',

      sortBy: 'Sort by',

      titleSeo: '',
      countryCode: '',
      countryCodeSub: '',
      filterNm: '',
      filterCd: '',
      hflorFilterCd: '',
      filterNm1: '',
      filterCd1: '',
      filterNm2: '',
      filterCd2: '',
    }
  },
  watch: {
    compareArr: function () {
      sessionStorage.setItem('compare' + this.nationCode, JSON.stringify(this.compareArr))
    }
  },

  async created() {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
    if (this.$route.meta.rememberedCategory) {
      this.selectedCategory = this.$route.meta.rememberedCategory;
      this.handleCategoryChanged(this.selectedCategory,true);
    }
  },
  directives: {
    hoverCursor: {
      bind(el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer';
        });
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default';
        });
      }
    }
  },
  async mounted() {
    this.loadBanner();
    this.route = this.$route.meta
    this.pcdMenuPath = this.$route.path;
    this.pcdMenuTitle = this.$route.meta.mainTitle;
    this.pcdMenuType = this.$route.meta.parentType ? this.$route.meta.parentType : this.$route.meta.type;
    // URL 조건 1개 param
    this.filterNm = this.$route.params.filterNm
    this.filterCd = this.$route.params.filterCd
    this.hflorFilterCd = this.$route.params.hflorFilterCd;
    // URL 조건 2개 param
    this.filterNm1 = this.$route.params.filterNm1
    this.filterCd1 = this.$route.params.filterCd1
    this.filterNm2 = this.$route.params.filterNm2
    this.filterCd2 = this.$route.params.filterCd2

    this.hflorCateCode = this.$store.state.hflorCateCode

    try {
      const t = this;
      t.category = await this.getCurrentCategory();
      t.collectionList = await this.getCurrentCollection(t.category.id);
      t.dictMap = await this.getDictList();
      // this.setSeoTitle();
      if (this.filterState) {
        this.filter = this.filterState;
        this.selectedFilters = this.$store.state.selectedFilters;
        this.restoreFilterSelections();
      }
      window.addEventListener('scroll', this.handleScroll);
    } catch (e) {
      console.log(e);
    }


    // title seo name
    /*    if (this.filterCd !== undefined) {
          this.titleSeo = this.filterCd;
        } else if (this.filterCd === undefined && this.filterCd1 !== undefined) {
          this.titleSeo = filterCd1;
        } else if (this.filterNm !== undefined && this.filterNm === 'isnew') {
          this.titleSeo = 'New Colors';
        } else {
          this.titleSeo = 'viatera-quartz-surface';
        }*/

    if (this.filterNm !== undefined || this.filterNm1 !== undefined) {
      const viateraFilter = JSON.parse(sessionStorage.getItem('viateraFilter' + this.nationCode));
      // URL 조건 1개 일 경우
      if (this.filterNm !== undefined) {
        await this.findFilter()
        // URL 조건 2개 일 경우
      } else if (this.filterNm1 !== undefined) {
        await this.findFilter()
      }
    } else {
      // 조건 없을 경우
      await this.getItem();
    }

    this.$nextTick(function () {
      // 필터 4줄 / 3줄 노출버튼
      $('.layout_grid').click(function () {
        $(this).addClass('on')
        $('.layout_list').removeClass('on')

        $('.list-type-view').removeClass('row-03')
        $('.list-type-view').addClass('row-04')
      });
      $('.layout_list').click(function () {
        $(this).addClass('on')
        $('.layout_grid').removeClass('on')

        $('.list-type-view').removeClass('row-04')
        $('.list-type-view').addClass('row-03')
      });

      // compare 버튼 위치값
      function compare_btn_position() {
        let quick_menu_top = $('.quick_link_group').offset().top;
        let compare_btn_top = $('.compare_box');

        compare_btn_top.offset({top: quick_menu_top - 80});
      }

      $(window).scroll(function () {
        if ($('.quick_sticky').hasClass('on')) {
          compare_btn_position();
        } else {
          $(".compare_box").offset({top: 624});
        }
      });
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    isHflor() {
      const prodInfo = getProductInfoByPath(this.$route.path);
      return prodInfo && prodInfo.name == 'HFLOR';
    },
    showCategory() {
      return this.isHflor() && this.category.code != ('PRD_FLOORING' + this.nationCode);
    },
    gotolink(url) {
      location.href = url
    },
    restoreFilterSelections() {
      Object.keys(this.filter).forEach(key => {
        if (Array.isArray(this.filter[key])) {
          this.filter[key].forEach(value => {
            this.$nextTick(function () {
              this.selectedFilters.forEach((item) => {
                if (key === 'collection') {
                  const checkbox = document.getElementById(item.name.replaceAll(' ', '_').toLowerCase());
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'pattern') {
                  const checkboxId = this.$route.path.includes('/hflor/hflor-finder/') === true ? `pattern_${item.value}${this.nationCode}` : `pattern_${item.value}`;
                  const checkbox = document.getElementById(checkboxId);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'bookmatch') {
                  const checkbox = document.getElementById(`bookmatch_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'remark') {
                  const checkbox = document.getElementById(`remark_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'hue') {
                  const checkbox = document.getElementById(`hue_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'hue_w') {
                  const checkbox = document.getElementById(`hue_w_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'hue_m') {
                  const checkbox = document.getElementById(`hue_m_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'hue_sld') {
                  const checkbox = document.getElementById(`hue_sld_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'hue_stn') {
                  const checkbox = document.getElementById(`hue_stn_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'finish') {
                  const checkbox = document.getElementById(`finish_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'thickness') {
                  const checkbox = document.getElementById(`thick_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'slab_size') {
                  const checkbox = document.getElementById(item.value);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'isNew') {
                  const checkbox = document.getElementById(`isNew_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'tree_species') {
                  const checkbox = document.getElementById(`tree_species_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'pattern_w') {
                  const checkbox = document.getElementById(`pattern_w_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'pattern_m') {
                  const checkbox = document.getElementById(`pattern_m_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'pattern_stn') {
                  const checkbox = document.getElementById(`pattern_stn_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'type_s') {
                  const checkbox = document.getElementById(`type_s_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'type_t') {
                  const checkbox = document.getElementById(`type_t_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'fire_resistance') {
                  const checkbox = document.getElementById(`fire_resistance_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'adhesive_type') {
                  const checkbox = document.getElementById(`adhesive_type_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'prd_class') {
                  const checkbox = document.getElementById(`prd_class_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'pet') {
                  const checkbox = document.getElementById(`pet_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'pvc') {
                  const checkbox = document.getElementById(`pvc_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'acrylic_') {
                  const checkbox = document.getElementById(`acrylic_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'dimension') {
                  const checkbox = document.getElementById(`dimension_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                if (key === 'prdType') {
                  const checkbox = document.getElementById(`prdType_${item.value}`);
                  checkbox.checked = checkbox?.checked !== null ? true : false;
                }
                // else {
                //   const checkboxId = `${key}_${value}`;
                //   const checkbox = document.getElementById(checkboxId);
                //   checkbox.checked = checkbox?.checked !== null ? true : false;
                // }
              })
            })
          });
        }
      });
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if ((scrollTop + clientHeight) / scrollHeight >= 0.8) {
        this.loadMore();
      }
    },
    //HLFOR 카테고리 변경입니다.
    async handleCategoryChanged(cateCode,isClick) {
      this.selectedCategory = cateCode;
      // 현재 라우트의 메타 데이터를 업데이트
      this.$route.meta.rememberedCategory = cateCode;
      const t = this;
      // 새로운 카테고리 코드로 카테고리 정보를 가져옵니다.
      const res = await ProductService.getCategoryCode(cateCode + t.nationCode);
      const newCategory = res.data.object;

      // 새 카테고리 ID로 컬렉션 리스트를 가져옵니다.
      const newCollectionList = await this.getCurrentCollection(newCategory.id);

      // 상태를 업데이트합니다.
      t.category = newCategory;
      t.collectionList = newCollectionList;

      // 새로운 카테고리에 맞는 딕셔너리 맵을 가져옵니다.
      const newDictMap = await this.getDictList();
      t.dictMap = newDictMap;
      // 선택된 필터가 있는지 확인합니다.
      if(isClick===undefined){
        t.filter = {};
        t.selectedFilters = [];
        this.$store.commit('SET_FILTER_STATE', {});
        this.$store.commit('SET_SELECTED_FILTERS', []);
      }
      const hasSelectedFilters = Object.keys(t.filter).length > 0 || t.selectedFilters.length > 0;

      if (!hasSelectedFilters) {
        // 선택된 필터가 없을 경우에만 필터를 초기화합니다.
        t.filter = {};
        t.selectedFilters = [];

      }

      this.$store.commit('SET_CATECODE_STATE', newCategory);
      if (this.hflorCateCode !== null) {
        // 기존 선택된 필터를 유지합니다.
        if (!hasSelectedFilters) {
          this.selectedFilters = this.$store.state.selectedFilters;
        }
      }
      // 새로운 카테고리로 아이템을 다시 로드합니다.
      await t.getItem();
    },
    beforeRouteLeave(to, from, next) {
      // 현재 선택된 카테고리를 메타 데이터에 저장
      this.$route.meta.rememberedCategory = this.selectedCategory;
      next();
    },
    handleCategoryChangedVizuon(cateCode) {
      // 필터 초기화
      this.filter = {};
      this.selectedFilters = [];
      this.$store.commit('SET_FILTER_STATE', {});
      this.$store.commit('SET_SELECTED_FILTERS', []);

      // 카테고리 변경
      this.category = { code: cateCode };

      // 새로운 카테고리에 맞는 컬렉션 리스트와 딕셔너리 맵을 가져오는 로직 추가
      this.getCurrentCollection(this.category.id).then(newCollectionList => {
        this.collectionList = newCollectionList;
      });

      this.getDictList().then(newDictMap => {
        this.dictMap = newDictMap;
      });
      // 아이템 다시 로드
      this.getItem();

      // 라우터 푸시
      this.$router.push({path: '/vizuon-sign-graphic/vizuon-finder/' + this.makeVizuonSeoMainClt(cateCode)});
    },
    getProductName() {
      return getProductNameByPath(this.$route.path, this.$countryCode)
    },
    getProductNameClass() {
      const classMap = {
        'VIATERA': 'viatera',
        'HIMACS': 'himacs',
        'HFLOR': 'hflor',
        'TERACANTO': 'teracanto',
        'BENIF': 'benif',
        'VIZUON': 'etc',
        'DECO FOIL': 'etc',
        'EXTERIOR FOIL': 'etc'
      }
      return classMap[getProductNameByPath(this.$route.path, this.$countryCode)]
    },
    async loadBanner() {
      const _this = this;
      const cateCode = getPcdCategoryCodeByPath(this.$route.path, this.$countryCode)
      const res = await categoryService.getCategoryCode(cateCode)
      const categoryId = res.data.object.id;
      const content = await ProductService.getContentSearchList(categoryId);
      if (content.data.length > 0) {
        const contentData = content.data[0];
        const {id} = contentData;
        _this.pcdContent = await ProductService.getContent(id);
        _this.pcdContentAttribute = _this.pcdContent.data.attribute;
      }
      // }
    },
    async getCurrentCategory() {
      const cateCode = getCategoryCodeByPath(this.$route.path, this.$countryCode)
      const res = await ProductService.getCategoryCode(cateCode)
      return res.data.object;
    },
    async getCurrentCollection(cateId) {
      const res = await getCollectionList(cateId)
      return res.data.list;
    },
    async getDictList() {
      const nationCode = this.$countryCode /* 국가 코드 */
      const cateCode = this.category.code
      const dictMap = getDictionaryMapByCateCode(cateCode, nationCode)
      const res = await ProductService.getDictionaryValueList(dictMap.map(d => d.code))
      const dictListList = res.data.dicList.map(d => d.list);
      const dictListMap = {};

      for (const dictList of dictListList) {
        if (dictList.length > 0) {
          const dictInfo = dictMap.find((dm) => _.isArray(dm.code) ? dm.code.includes(dictList[0].id.dictionaryId) : dictList[0].id.dictionaryId == dm.code);

          if (dictInfo.filter) {
            dictListMap[dictInfo.type] = dictList.filter(dictInfo.filter)
          } else {
            const valueList = dictList.map(d => d.id.value)
            const newDictList = [];
            for (let i = 0; i < dictList.length; i++) {
              const found = newDictList.find(d => d.id.value === dictList[i].id.value);
              if (!found) {
                newDictList.push(dictList[i]);
              }
            }
            dictListMap[dictInfo.type] = newDictList;
          }
        } else {
          dictListMap[dictInfo.type] = [];
        }
      }
      return dictListMap;
    },

    openSearchPopup(str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup() {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },

    /**
     * popup 열기
     */
    openSampleOrder(id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
      $('body').addClass('scrolllock');
    },
    /**
     * popup 닫기
     */
    closeSampleOrder() {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
      $('body').removeClass('scrolllock');
    },

    textSearch: _.debounce(function (event) {
      this.searchText = event.target.value;
      this.pageIndex = 1; // 페이지 인덱스를 1로 초기화
      this.contentList = []; // 기존 컨텐츠 리스트를 초기화
      this.getItem(1, this.pageSize);
    }, 300),

    textSearchDel() {
      this.searchText = '';
      if (this.isMobile()) {
        $('#textSearchM').val('');
      } else {
        $('#textSearch').val('');
      }
      this.pageIndex = 1; // 페이지 인덱스를 1로 초기화
      this.contentList = []; // 기존 컨텐츠 리스트를 초기화
      this.getItem(1, this.pageSize);
    },

    async getItem() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.isInitialLoad = false;

      try {
        const t = this;

        const search = this.getSearchCondition();
        const res = await ProductService.getDictionaryCondition(
          search.categoryIds,
          search.dictionaryCondition,
          t.searchText,
          t.pageIndex,
          t.pageSize,
          t.orderField,
          t.orderType,
          ['search_text']
        );

        if (t.pageIndex === 1) {
          t.contentList = res.data.list;
        } else {
          t.contentList.push(...res.data.list);
        }

        t.contentLength = res.data.totalCount;
      } catch (error) {
        console.error('Error in getItem:', error);
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
      } finally {
        this.isLoading = false;
        this.isInitialLoad = false;
      }
    },
    closeFilterPop() {
      $('.filter_pop').removeClass('on');
      //스크롤 막기풀기
      $('body').removeClass('scrolllock');
    },
    openFilterPop() {
      $('.filter_pop').addClass('on');
      //모바일 사이즈 시 스크롤 막기
      if($(window).width() <= 769) {
        $('body').addClass('scrolllock');
      }
    },
    clickFilterBtn() {
      const filterFlag = $('.filter_pop').hasClass('on')
      if (filterFlag) {
        this.closeFilterPop()
      } else {
        this.openFilterPop()
      }
    },
    getSearchCondition() {
      const searchCondition = {
        categoryIds: [],
        dictionaryCondition: {operator: 'AND', list: []}
      };

      const t = this;
      if (this.filter && typeof this.filter === 'object') {
        Object.keys(this.filter).forEach(function (key) {
          if (Array.isArray(t.filter[key])) {
            if (key === 'collection') {
              searchCondition.categoryIds = t.filter[key];
            } else {
              const values = t.filter[key].map((f) => {
                return {id: key, value: f}
              });
              if (values.length > 0) {
                searchCondition.dictionaryCondition.list.push({operator: 'OR', values: values});
              }
            }
          }
        });
      }

      // HFLOR에 대한 처리 수정
      if (this.$route.path.includes('/hflor/hflor-finder/')) {
        if (searchCondition.categoryIds.length === 0) {
          searchCondition.categoryIds = [this.category.id];
        }
      } else if (this.$route.path.includes('/vizuon-sign-graphic/vizuon-finder/')) {
        if (searchCondition.categoryIds.length === 0) {
          searchCondition.categoryIds = [this.category.id];
        }
      } else if (searchCondition.categoryIds.length == 0) {
        searchCondition.categoryIds = t.collectionList.map(c => c.category.id);
      }

      return searchCondition;
    },
    async applyFilter() {
      // HFLOR 제품군인 경우 필터 선택 여부와 관계없이 검색 허용
      if (this.$route.path.includes('/hflor/hflor-finder/') || this.$route.path.includes('/vizuon-sign-graphic/vizuon-finder/')) {
        this.updateSelectedFilters();
        this.filterState = {
          filter: this.filter,
          selectedFilters: this.selectedFilters
        };
        this.$store.commit('SET_FILTER_STATE', this.filter);
        this.$store.commit('SET_SELECTED_FILTERS', this.selectedFilters);
        this.getItem();
        this.toggleFilterPopup(false);
        this.closeFilterPop();
        return;
      }

      // 기존 로직: 다른 제품군에 대해서는 필터 선택 필요
      const hasSelectedFilter = Object.values(this.filter).some(values => values.length > 0);
      if (!hasSelectedFilter) {
        alert('Please Select Option')
        return // 함수 종료
      }
      this.updateSelectedFilters();

      this.filterState = {
        filter: this.filter,
        selectedFilters: this.selectedFilters
      };
      this.$store.commit('SET_FILTER_STATE', this.filter);
      this.$store.commit('SET_SELECTED_FILTERS', this.selectedFilters);
      //필터 적용 및 삭제 시 뱃지 남아있을 때 확인 할 코드
      // EventBus.$emit('update-color-filters', this.filter.hue || []);
      // EventBus.$emit('update-collection-filters', this.filter.collection || []);
      // EventBus.$emit('update-acrylic-filters', this.filter.acrylic || []);
      // EventBus.$emit('update-pattern-filters', this.filter.pattern || []);
      // EventBus.$emit('update-format-filters', this.filter.format || []);
      // EventBus.$emit('update-install-filters', this.filter.install || []);
      // EventBus.$emit('update-prdType-filters', this.filter.prdType || []);
      // EventBus.$emit('update-finish-filters', this.filter.finish || []);

      this.pageIndex = 1 // 페이지 인덱스 초기화
      this.contentList = [] // 기존 리스트 초기화
      await this.getItem()
      this.toggleFilterPopup(false);
      this.closeFilterPop();
    },
    // 필터 조건 초기화
    async filterClearAll() {
      this.filter = {};
      this.selected = {};
      this.selectedFilters = [];
      EventBus.$emit('reset-pf-filter')

      // HFLOR인 경우 카테고리 초기화
      if (this.isHflor()) {
        await this.handleCategoryChanged('PRD_FLOORING');
      }

      this.pageIndex = 1 // 페이지 인덱스 초기화
      this.contentList = [] // 기존 리스트 초기화
      await this.getItem()
    },
    async sortByChange(text, type, field) {
      const _this = this
      // 선택된 정렬 옵션의 버튼에 'on' 클래스 추가
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .btn-sel span').text(text)
      $('.sel-box .btn-sel').addClass('on')

      // 정렬 옵션 선택 목록 숨김
      $('.sel-box .sel-list').slideUp()

      _this.sortBy = text
      _this.orderType = [type]
      _this.orderField = field ? [field] : []
      _this.pageIndex = 1 // 페이지 인덱스 초기화
      _this.contentList = [] // 기존 리스트 초기화

      await _this.getItem()
    },
    handleCheckBox(event, listName, checkListName, index) {

      this.$children.find(child => child.$options.name === 'VIATERA_COLOR')
        .handleCheckBox(event, listName, checkListName, index);
      //this.updateSelectedFilters();
    },
    updateSelectedFilters() {
      this.selectedFilters = [];

      Object.keys(this.filter).forEach(key => {
        if (Array.isArray(this.filter[key])) {
          this.filter[key].forEach(item => {

            if (key === 'hue') {
              const color = this.dictMap['hue'].find(color => color.id.value === item);
              const colorText = color ? color.text : '';
              this.selectedFilters.push({type: 'COLOR', name: colorText, value: item});
            }
            if (key === 'bookmatch') {
              const bookmatch = this.dictMap['bookmatch'].find(bookmatch => bookmatch.id.value === item);
              const bookmatchText = bookmatch ? bookmatch.text : '';
              this.selectedFilters.push({type: 'bookmatch', name: bookmatchText, value: item});
            }
            if (key === 'remark') {
              const remark = this.dictMap['remark'].find(remark => remark.id.value === item);
              const remarkText = remark ? remark.text : '';
              this.selectedFilters.push({type: 'remark', name: remarkText, value: item});
            }
            if (key === 'finish') {
              const finish = this.dictMap['finish'].find(finish => finish.id.value === item);
              const finishText = finish ? finish.text : '';
              this.selectedFilters.push({type: 'FINISH', name: finishText, value: item});
            }
            if (key === 'slab_size') {
              const slab = this.dictMap['slab_size'].find(slab => slab.id.value === item);
              const slabText = slab ? slab.text : '';
              this.selectedFilters.push({type: 'SLABSIZE', name: slabText, value: item});
            }
            if (key === 'pattern') {
              const pattern = this.dictMap['pattern'].find(pattern => pattern.id.value === item);
              const patternText = pattern ? pattern.text : '';
              this.selectedFilters.push({type: 'PATTERN', name: patternText, value: item});
            }
            if (key === 'tree_species') {
              const woodSpecies = this.dictMap['tree_species'].find(woodSpecies => woodSpecies.id.value === item);
              const woodSpeciesText = woodSpecies ? woodSpecies.text : '';
              this.selectedFilters.push({type: 'TREE SPECIES', name: woodSpeciesText, value: item});
            }
            if (key === 'pattern_w') {
              const woodPattern = this.dictMap['pattern_w'].find(woodPattern => woodPattern.id.value === item);
              const woodPatternText = woodPattern ? woodPattern.text : '';
              this.selectedFilters.push({type: 'WOOD PATTERN', name: woodPatternText, value: item});
            }
            if (key === 'pattern_m') {
              const metalPattern = this.dictMap['pattern_m'].find(metalPattern => metalPattern.id.value === item);
              const metalPatternText = metalPattern ? metalPattern.text : '';
              this.selectedFilters.push({type: 'METAL PATTERN', name: metalPatternText, value: item});
            }
            if (key === 'hue_w') {
              const woodColor = this.dictMap['hue_w'].find(woodColor => woodColor.id.value === item);
              const woodColorText = woodColor ? woodColor.text : '';
              this.selectedFilters.push({type: 'WOOD COLOR', name: woodColorText, value: item});
            }

            if (key === 'type_s') {
              const stoneType = this.dictMap['type_s'].find(stoneType => stoneType.id.value === item);
              const stoneTypeText = stoneType ? stoneType.text : '';
              this.selectedFilters.push({type: 'STONE TYPE', name: stoneTypeText, value: item});
            }
            if (key === 'type_t') {
              const textileType = this.dictMap['type_t'].find(textileType => textileType.id.value === item);
              const textileTypeText = textileType ? textileType.text : '';
              this.selectedFilters.push({type: 'TEXTILE TYPE', name: textileTypeText, value: item});
            }
            if (key === 'fire_resistance') {
              const fireResist = this.dictMap['fire_resistance'].find(fireResist => fireResist.id.value === item);
              const fireResistText = fireResist ? fireResist.text : '';
              this.selectedFilters.push({type: 'FIRE RESISTANCE', name: fireResistText, value: item});
            }
            if (key === 'hue_sld') {
              const solidcolor = this.dictMap['hue_sld'].find(solidcolor => solidcolor.id.value === item);
              const solidcolorText = solidcolor ? solidcolor.text : '';
              this.selectedFilters.push({type: 'SOLID COLOR', name: solidcolorText, value: item});
            }
            if (key === 'hue_m') {
              const metalColor = this.dictMap['hue_m'].find(metalColor => metalColor.id.value === item);
              const metalColorText = metalColor ? metalColor.text : '';
              this.selectedFilters.push({type: 'METAL COLOR', name: metalColorText, value: item});
            }
            if (key === 'hue_stn') {
              const stoneColor = this.dictMap['hue_stn'].find(stoneColor => stoneColor.id.value === item);
              const stoneColorText = stoneColor ? stoneColor.text : '';
              this.selectedFilters.push({type: 'STONE COLOR', name: stoneColorText, value: item});
            }
            if (key === 'pattern_sld') {
              const solidPattern = this.dictMap['pattern_sld'].find(solidPattern => solidPattern.id.value === item);
              const solidPatternText = solidPattern ? solidPattern.text : '';
              this.selectedFilters.push({type: 'SOLID PATTERN', name: solidPatternText, value: item});
            }
            if (key === 'pattern_stn') {
              const stonePattern = this.dictMap['pattern_stn'].find(stonePattern => stonePattern.id.value === item);
              const stonePatternText = stonePattern ? stonePattern.text : '';
              this.selectedFilters.push({type: 'STONE PATTERN', name: stonePatternText, value: item});
            }
            if (key === 'pvc') {
              const pvc = this.dictMap['pvc'].find(pvc => pvc.id.value === item);
              const pvcText = pvc ? pvc.text : '';
              this.selectedFilters.push({type: 'PVC', name: pvcText, value: item});
            }
            if (key === 'pet') {
              const pet = this.dictMap['pet'].find(pet => pet.id.value === item);
              const petText = pet ? pet.text : '';
              this.selectedFilters.push({type: 'PET', name: petText, value: item});
            }
            if (key === 'acrylic') {
              const acrylic = this.dictMap['acrylic'].find(acrylic => acrylic.id.value === item);
              const acrylicText = acrylic ? acrylic.text : '';
              this.selectedFilters.push({type: 'ACRYLIC FURNITURE FILM', name: acrylicText, value: item});
            }
            if (key === 'application') {
              const app = this.dictMap['application'].find(app => app.id.value === item);
              const appText = app ? app.text : '';
              this.selectedFilters.push({type: 'APPLICATION', name: appText, value: item});
            }
            if (key === 'prdType') { // VIZUON
              const prdType = this.dictMap['prdType'].find(prdType => prdType.id.value === item);
              const prdTypeText = prdType ? prdType.text : '';
              this.selectedFilters.push({type: 'PRODUCT TYPE', name: prdTypeText, value: item});
            }
            if (key === 'find_prdType') { //HFLOR
              const prdType = this.dictMap['find_prdType'].find(prdType => prdType.id.value === item);
              const prdTypeText = prdType ? prdType.text : '';
              this.selectedFilters.push({type: 'PRODUCT TYPE', name: prdTypeText, value: item});
            }
            if (key === 'adhesive_type') {
              const adhType = this.dictMap['adhesive_type'].find(adhType => adhType.id.value === item);
              const adhTypeText = adhType ? adhType.text : '';
              this.selectedFilters.push({type: 'ADHESIVE TYPE', name: adhTypeText, value: item});
            }
            if (key === 'air_free') {
              const airfree = this.dictMap['air_free'].find(airfree => airfree.id.value === item);
              const airfreeText = airfree ? airfree.text : '';
              this.selectedFilters.push({type: 'AIR FREE', name: airfreeText, value: item});
            }
            if (key === 'prd_class') {
              const pdc = this.dictMap['prd_class'].find(pdc => pdc.id.value === item);
              const pdcText = pdc ? pdc.text : '';
              this.selectedFilters.push({type: 'PRODUCT CLASSIFICATION', name: pdcText, value: item});
            }
            if (key === 'durability') {
              const weatherAbility = this.dictMap['durability'].find(weatherAbility => weatherAbility.id.value === item);
              const weatherAbilityText = weatherAbility ? weatherAbility.text : '';
              this.selectedFilters.push({type: 'WEATHERABILITY', name: weatherAbilityText, value: item});
            }
            if (key === 'thick') {
              const thick = this.dictMap['thick'].find(thick => thick.id.value === item);
              const thickText = thick ? thick.text : '';
              this.selectedFilters.push({type: 'THICK', name: thickText, value: item});
            }
            if (key === 'thickness') {
              const thickness = this.dictMap['thickness'].find(thickness => thickness.id.value === item);
              const thicknessText = thickness ? thickness.text : '';
              this.selectedFilters.push({type: 'THICKNESS', name: thicknessText, value: item});
            }
            if (key === 'dimension') {
              const dimension = this.dictMap['dimension'].find(dimension => dimension.id.value === item);
              const dimensionText = dimension ? dimension.text : '';
              this.selectedFilters.push({type: 'DIMENSION', name: dimensionText, value: item});
            }
            if (key === 'wear_layer') {
              const wearLayer = this.dictMap['wear_layer'].find(wearLayer => wearLayer.id.value === item);
              const wearLayerText = wearLayer ? wearLayer.text : '';
              this.selectedFilters.push({type: 'WEARLAYER', name: wearLayerText, value: item});
            }
            if (key === 'format') {
              const format = this.dictMap['format'].find(format => format.id.value === item);
              const formatText = format ? format.text : '';
              this.selectedFilters.push({type: 'FORMAT', name: formatText, value: item});
            }
            if (key === 'isNew') {
              if (item === true) {
                this.selectedFilters.push({type: 'NEW', name: 'NEW COLORS', value: item});
              }
            }
            if (key === 'isRecycled') {
              if (item === true) {
                this.selectedFilters.push({type: 'RECYCLED', name: 'RECYCLED', value: item});
              }
            }
            if (key === 'collection' && this.collectionList) {
              const collection = this.collectionList.find(c => c.category.id === item);
              if (collection) {
                this.selectedFilters.push({
                  type: 'COLLECTION',
                  name: collection.category.name,
                  value: item
                });
              }
            }
          });
        }
      });
    },
    getFilterLabel(filter) {
      if (filter.type === 'COLOR') {
        return `COLOR : ${filter.name}`;
      }
      return `${filter.type}: ${filter.name}`;
    },
    //뱃지개별삭제
    async removeFilter(filter) {
      const index = this.selectedFilters.findIndex(
        item => item.type === filter.type && item.name === filter.name
      );
      this.selectedFilters.splice(index, 1);

      if (filter.type === 'COLOR') {
        this.filter.hue = this.filter.hue.filter(item => item !== filter.value);
        const checkboxId = `hue_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
        // EventBus를 통해 COLOR 컴포넌트에 변경 사항 알림
        EventBus.$emit('remove-color-filter', filter.value);
      }
      if (filter.type === 'REMARK') {
        this.filter.remark = this.filter.hue.filter(item => item !== filter.value);
        const checkboxId = `remark_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'BOOKMATCH') {
        this.filter.bookmatch = this.filter.bookmatch.filter(item => item !== filter.value);
        const checkboxId = `bookmatch_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'PATTERN') {
        this.filter.pattern = this.filter.pattern.filter(item => item !== filter.value);
        const checkboxId = this.$route.path.includes('/hflor/hflor-finder/') === true ? `pattern_${filter.value}${this.nationCode}` : `pattern_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
        EventBus.$emit('remove-pattern-filter', filter.value);
      }
      if (filter.type === 'TREE SPECIES') {
        this.filter.tree_species = this.filter.tree_species.filter(item => item !== filter.value);
        const checkboxId = `tree_species_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'WOOD PATTERN') {
        this.filter.pattern_w = this.filter.pattern_w.filter(item => item !== filter.value);
        const checkboxId = `pattern_w_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'WOOD COLOR') {
        this.filter.hue_w = this.filter.hue_w.filter(item => item !== filter.value);
        const checkboxId = `hue_w_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'METAL PATTERN') {
        this.filter.pattern_m = this.filter.pattern_m.filter(item => item !== filter.value);
        const checkboxId = `pattern_m_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'STONE TYPE') {
        this.filter.type_s = this.filter.type_s.filter(item => item !== filter.value);
        const checkboxId = `type_s_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'TEXTILE TYPE') {
        this.filter.type_t = this.filter.type_t.filter(item => item !== filter.value);
        const checkboxId = `type_t_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'FIRE RESISTANCE') {
        this.filter.fire_resistance = this.filter.fire_resistance.filter(item => item !== filter.value);
        const checkboxId = `fire_resistance_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'SOLID COLOR') {
        this.filter.hue_sld = this.filter.hue_sld.filter(item => item !== filter.value);
        const checkboxId = `hue_sld_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'METAL COLOR') {
        this.filter.hue_m = this.filter.hue_m.filter(item => item !== filter.value);
        const checkboxId = `hue_m_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'STONE COLOR') {
        this.filter.hue_stn = this.filter.hue_stn.filter(item => item !== filter.value);
        const checkboxId = `hue_stn_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'SOLID PATTERN') {
        this.filter.pattern_sld = this.filter.pattern_sld.filter(item => item !== filter.value);
        const checkboxId = `pattern_sld_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      } else if (filter.type === 'STONE PATTERN') {
        this.filter.pattern_stn = this.filter.pattern_stn.filter(item => item !== filter.value);
        const checkboxId = `pattern_stn_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'PVC') {
        this.filter.pvc = this.filter.pvc.filter(item => item !== filter.value);
        const checkboxId = `pvc_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'PET') {
        this.filter.pet = this.filter.pet.filter(item => item !== filter.value);
        const checkboxId = `pet_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'ACRYLIC FURNITURE FILM') {
        this.filter.acrylic = this.filter.acrylic.filter(item => item !== filter.value);
        const checkboxId = `acrylic_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'APPLICATION') {
        this.filter.application = this.filter.application.filter(item => item !== filter.value);
        const checkboxId = `application_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'PRODUCT TYPE') {
        this.filter.prdType = this.filter.prdType.filter(item => item !== filter.value);
        const checkboxId = `prdType_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
        EventBus.$emit('remove-prdType-filter', filter.value);

      }
      if (filter.type === 'ADHESIVE TYPE') {
        this.filter.adhesive_type = this.filter.adhesive_type.filter(item => item !== filter.value);
        const checkboxId = `adhesive_type_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'AIR FREE') {
        this.filter.air_free = this.filter.air_free.filter(item => item !== filter.value);
        const checkboxId = `air_free_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'PRODUCT CLASSIFICATION') {
        this.filter.prd_class = this.filter.prd_class.filter(item => item !== filter.value);
        const checkboxId = `prd_class_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'WEATHERABILITY') {
        this.filter.durability = this.filter.durability.filter(item => item !== filter.value);
        const checkboxId = `durability_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'THICK') {
        this.filter.thick = this.filter.thick.filter(item => item !== filter.value);
        const checkboxId = `thick_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'THICKNESS') { // 비아테라 티크니ㅡㅅ
        this.filter.thickness = this.filter.thickness.filter(item => item !== filter.value);
        const checkboxId = `thick_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'DIMENSION') {
        this.filter.dimension = this.filter.dimension.filter(item => item !== filter.value);
        const checkboxId = `dimension_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'FINISH') {
        this.filter.finish = this.filter.finish.filter(item => item !== filter.value);
        const checkboxId = `finish_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
        // EventBus.$emit('remove-finish-filter', filter.value);
      }
      if (filter.type === 'WEARLAYER') {
        this.filter.wear_layer = this.filter.wear_layer.filter(item => item !== filter.value);
        const checkboxId = `wear_layer_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'COLLECTION') {
        this.filter.collection = this.filter.collection.filter(item => item !== filter.value);
        const checkboxId = filter.name.replaceAll(' ', '_').toLowerCase()
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'NEW') {
        this.filter.isNew = this.filter.collection.filter(item => item !== filter.value);
        const checkboxId = `isNew_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'RECYCLED') {
        this.filter.isRecycled = this.filter.collection.filter(item => item !== filter.value);
        const checkboxId = `isRecycled_${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'SLABSIZE') {
        this.filter.slab_size = this.filter.slab_size.filter(item => item !== filter.value);
        const checkboxId = `${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      if (filter.type === 'FORMAT') {
        this.filter.format = this.filter.format.filter(item => item !== filter.value);
        const checkboxId = `${filter.value}`;
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      this.$store.commit('SET_FILTER_STATE', this.filter);
      this.$store.commit('SET_SELECTED_FILTERS', this.selectedFilters);
      await this.getItem();
      EventBus.$emit('remove-format-filter', filter.value);
    },
    //뱃지초기화
    async clearSelectedFilters() {
      this.selectedFilters = [];
      this.filter = {};
      this.filterState = null;
      this.$store.commit('SET_FILTER_STATE', null);
      this.$store.commit('SET_SELECTED_FILTERS', []);
      if(this.isHflor())
      {
        await this.handleCategoryChanged('PRD_FLOORING');
      }
      await this.filterClearAll();
    },
    openCart() {
      this.$root.$refs.cartView.openPopup()
    },

    goLink(type, url) {
      location.href = 'products/viatera-quartz-surface'
    },

    isMobile() {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    toggleFilterPopup(closePopup = true) {
      // 컴포넌트 데이터로 관리하는 경우
      this.showPopup = !this.showPopup
    },

    async loadMore() {
      if (this.contentList.length < this.contentLength && !this.isLoading) {
        this.pageIndex += 1
        await this.getItem(true) // true를 전달하여 추가 로딩임을 표시
      }
    },
    fnCateSearch: async function (name, id, type) {
      const t = this;
      t.pageIndex = 1;
      if (name == "CateAll") {
        if ($("#CateAll").is(":checked")) {
          $(".Collection").prop("checked", false);
        }
        t.categoryUsId = [];
        _.remove(t.colorList, function (c) {
          return c.subject == "Collection";
        });
        t.categoryUsId.push(t.categoryId);
        t.filterCollList = [];
        t.clickCollIds = [];
        t.checkCollectionList = t.collectionList;
        t.collectionLength = t.checkCollectionList.length;
      } else {
        if (type == "click" && $("#" + name).is(":checked") == false) {
          $("#" + name).prop("checked", true);
        } else if (type == "click" && $("#" + name).is(":checked") == true) {
          $("#" + name).prop("checked", false);
        }
        if ($("#" + name).is(":checked")) {
          if (t.categoryUsId[0] == t.categoryId) {
            t.categoryUsId = [];
          }
          t.categoryUsId.push(id);
          t.categoryUsName.push(name);
          t.colorList.push({subject: "Collection", name: name, id: id});
          // 필터링된 id
          this.clickCollIds.push(id);
          t.filtering = _.filter(t.collectionList, function (o, idx) {
            return o.category.id === id;
          });
          //첫검색할땐 필터링된 배열 리셋
          if (this.clickCollIds.length === 1) {
            t.filterCollList = [];
            t.filterCollList.push(t.filtering[0]);
          } else {
            t.filterCollList.push(t.filtering[0]);
          }
          t.checkCollectionList = t.filterCollList;
          t.collectionLength = t.checkCollectionList.length;
          /*
          } else {
            $("#CateAll").prop("checked", true);
          }
*/
        } else {
          let checkedCount = $(
            "input:checkbox[class=Collection]:checked"
          ).length;
          if (checkedCount == 0) {
            $("#CateAll").prop("checked", true);
          }
          _.remove(t.categoryUsId, function (c) {
            return c == id;
          });
          _.remove(t.colorList, function (v) {
            return v.subject == "Collection" && v.id == id;
          });

          t.clickCollIds = _.filter(t.clickCollIds, function (o) {
            return o !== id;
          });
          if (t.clickCollIds.length === 0) {
            t.checkCollectionList = t.collectionList;
          } else {
            // 필터링 된 collectionList에서 선택한 collection 값 삭제
            t.checkCollectionList = _.filter(
              t.checkCollectionList,
              function (o) {
                return o.category.id !== id;
              }
            );
          }
          t.collectionLength = t.checkCollectionList.length;
        }
      }
      if ($("#btn-tab01").attr("class") != "btn-tab on") {
        $("#btn-tab01").click();
      }
      await t.getItem();
    },
    async applyFilterFromUrl() {
      if (this.filterNm !== undefined) {
        await this.findFilter();
      } else if (this.filterNm1 !== undefined) {
        await this.findFilter();
      }
      // 필터 적용 후 화면 업데이트
      this.$forceUpdate();
    },
    async findFilter() {
      const t = this;
      if (this.hflorFilterCd !== undefined) {
        let categoryData = []
        let catagoryList = await categoryService.getCategoryAttributeList(this.category.id);

        let categoryCode = '';

        const codeMap = {
          'lvt-flooring': 'FLOOR_LUX',
          'looselay': 'FLOOR_LOS',
          'spc-click': 'FLOOR_SPC',
          'homogeneous-sheet': 'FLOOR_HOM',
          'heterogeneous-sheet': 'FLOOR_HET',
          'specialty': 'FLOOR_SPT'
        };

        categoryCode = codeMap[this.hflorFilterCd] || '';

        let cateCode = '';
        categoryData = catagoryList.data.list.find(item => {
          const itemCode = item.category.code.replace('_GL', '').replace('_US', '');
          if (itemCode === categoryCode) {
            cateCode = itemCode;
            return true;
          }
          return false;
        });

        this.$nextTick(function () {
          this.handleCategoryChanged(cateCode,true)
        })
      }
      if (this.filterNm === 'collection') {
        let collectionItem = t.collectionList?.find((item) => {
          const categoryName = item.category.name
            .replace(/[\s/]+/g, '-')
            .toLowerCase()
          return categoryName === this.filterCd
        })

        if (collectionItem) {
          // 필터 선택
          if (!t.filter.collection) {
            t.filter.collection = [];
          }
          // 중복 체크: 이미 같은 이름의 컬렉션이 선택되어 있는지 확인
          const isDuplicate = t.selectedFilters.some((item) =>
            (item.type === 'COLLECTION' || item.type === 'PET' || item.type === 'PVC' || item.type === 'ACRYLIC') && item.name === collectionItem.category.name
          );
          if (!isDuplicate) {
            if (this.$route.path.includes('/deco-foil/deco-finder/')) {
              const PET = ['SOLID/SMR', 'Metal/Pearl', 'S Texture', 'G Texture']
              const PVC = ['Painted Wood', 'Best', 'Misty', 'PEARL/SOLID']
              const ACRYLIC = ['Solid']
              if (PET.includes(collectionItem.category.name)) {
                t.filter.pet.push(collectionItem.category.name.toLowerCase().replaceAll('/', '-').replaceAll(' ', '-'));
                this.selectedFilters.push({
                  type: 'PET',
                  name: collectionItem.category.name,
                  value: collectionItem.category.id
                });
              }
              if (PVC.includes(collectionItem.category.name)) {
                t.filter.pvc.push(collectionItem.category.name.toLowerCase().replaceAll('/', '-').replaceAll(' ', '-'));
                this.selectedFilters.push({
                  type: 'PVC',
                  name: collectionItem.category.name,
                  value: collectionItem.category.id
                });
              }
              if (ACRYLIC.includes(collectionItem.category.name)) {
                t.filter.acrylic.push(collectionItem.category.name.toLowerCase().replaceAll('/', '-'));
                this.selectedFilters.push({
                  type: 'ACRYLIC',
                  name: collectionItem.category.name,
                  value: collectionItem.category.id
                });
              }
            } else {
              t.filter.collection.push(collectionItem.category.id);
              this.selectedFilters.push({
                type: 'COLLECTION',
                name: collectionItem.category.name,
                value: collectionItem.category.id
              });
            }


            // 필터 상태 업데이트
            this.$store.commit('SET_FILTER_STATE', this.filter);
            this.$store.commit('SET_SELECTED_FILTERS', this.selectedFilters);
            await this.getItem();
          } else {
            await this.getItem();
          }
        }
      }
      // 다른 필터 타입 처리
    },
    filterReNaming(str) {
      return str.toLowerCase().replace(/[^a-z0-9/]/g, '').replace(/\//g, '_');
    },
    async makePDF() {
      const originalStyles = {
        maxHeight: $('.popup-compare-cont').css('max-height'),
        addMoreDisplay: $('.popup-compare-cont .prod-list.tbl-compare > ul > .add_more').css('display'),
        btnRemoveDisplay: $('.popup-compare-cont .prod-list.tbl-compare > ul .btn-remove').css('display'),
        downloadBtnDisplay: $('.popup-compare-cont .btn-download').css('display')
      };

      $('.popup-compare-cont').css('max-height', 'unset');
      $('.popup-compare-cont .prod-list.tbl-compare > ul > .add_more').hide();
      $('.popup-compare-cont .prod-list.tbl-compare > ul .btn-remove').hide();
      $('.popup-compare-cont .btn-download').hide();

      try {
        html2canvas($('#pdf_wrap')[0], {
          allowTaint: true,
          useCORS: true,
          logging: false,
          height: window.outerHeight + window.innerHeight,
          windowHeight: window.outerHeight + window.innerHeight
        }).then(function (canvas) {
          var filename = 'PRODUCT_COMPARE_' + Date.now() + '.pdf'
          var doc = new jsPDF('p', 'mm', 'a4')
          var imgData = canvas.toDataURL('image/png')
          var imgWidth = 210
          var pageHeight = 295
          var imgHeight = canvas.height * imgWidth / canvas.width
          var heightLeft = imgHeight
          var position = 0
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
          window.scrollTo(0, 0)
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight
            doc.addPage()
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
          }

          const pdfBlob = doc.output('blob');
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank');
          doc.save(filename)
        })
        const _this = this
        for (var i = 0; i < _this.compareList.length; i++) {
          if (typeof (dataLayer) !== 'undefined') {
            let prdVariant = ''
            if ((_this.compareList[i].attribute.price != null || undefined) && _this.compareList[i].attribute.price.length < 10) {
              prdVariant = _.join(_.flatMap(_this.compareList[i].attribute.price, function (v) {
                return v.size || ''
              }), '|')
            }

            const {ecom_prd_brand, ecom_prd_category} = getEcomPrdData(_this.compareList[i]);
            dataLayer.push({
              event: 'product_compare',
              event_type: 'Download Comp',
              ecom_prd_id: _this.compareList[i]?.attribute?.code || '',
              ecom_prd_name: _this.compareList[i]?.content?.title || '',
              ecom_prd_brand,
              ecom_prd_category,
              ecom_prd_variant: prdVariant || '',
              ecom_prd_type: Array.isArray(_this.compareList[i]?.attribute?.patternText)
                ? _this.compareList[i]?.attribute?.patternText?.join(', ')
                : _this.compareList[i]?.attribute?.patternText || '',
              ecom_prd_collection: _this.compareList[i]?.parents?.[0]?.name || '',
              ecom_prd_color: ''
            })
          }
        }
      } finally {
        $('.popup-compare-cont').css('max-height', originalStyles.maxHeight);
        $('.popup-compare-cont .prod-list.tbl-compare > ul > .add_more').css('display', originalStyles.addMoreDisplay);
        $('.popup-compare-cont .prod-list.tbl-compare > ul .btn-remove').css('display', originalStyles.btnRemoveDisplay);
        $('.popup-compare-cont .btn-download').css('display', originalStyles.downloadBtnDisplay);

        this.$forceUpdate();

        setTimeout(() => {
          $('.popup-compare-cont .prod-list.tbl-compare > ul .btn-remove').show();
          this.$forceUpdate();
        }, 100);
      }
      //
      // // PDF 생성 완료 후 원래 CSS 스타일 복구
      // $('.popup-compare-cont').css({
      //   'max-height': originalMaxHeight
      // });
      // $('.popup-compare-cont .prod-list.tbl-compare > ul > li').css('display', originalProductListVisibility);
      // $('.popup-compare-cont .btn-download').css('display', originalDownloadBtnVisibility);
      //
      // $('.popup-compare-cont .prod-list.tbl-compare > ul > .add_more').show();
      // $('.popup-compare-cont .prod-list.tbl-compare > ul .btn-remove').show();
    },
    addCompare(id) {
      const _this = this

      if (_this.compareArr.length < 5) {

      } else {
        _this.replaceId = id
        $('#' + id).prop('checked', false)
        this.removeCompare(id)
        LayerPopup.open('layer-alert')
        return false
      }
    },
    removeCompare(id) {
      const _this = this
      if (_this.compareList != null) {
        const idx = _this.compareList.findIndex(function (v) {
          return v.content.id === id
        })
        _this.compareArr.splice(idx, 1)
        _this.compareList.splice(idx, 1)
      }
    },
    // compare 팝업 호출1
    async getCompareList() {
      const t = this
      const _this = this
      const target = document.getElementById('replace')

      _this.compareList = []
      _this.compareRes = _this.compareArr

      LayerPopup.close() // alert 클로즈
      try {
        const response = await ProductService.getCompareList(_this.compareRes)

        if (response.status === 200 && Array.isArray(response.data)) {
          _this.compareList = response.data
          if (_this.compareList.length > 0) {
            LayerPopup.open('layer-compare');
            $('body').addClass('scrolllock');

            if (target.disabled) {
              target.disabled = false
            }
          } else {
            alert('No items to compare.')
          }
        } else if (response.status === 404) {
          alert('No items found for comparison.')
        } else {
          alert('An unexpected error occurred. Please try again later.')
        }
      } catch (error) {
        alert('An error occurred while fetching the compare list. Please try again later.')
      }
    },
    async replace() {
      const _this = this
      const target = document.getElementById('replace')
      target.disabled = true
      if (_this.compareArr != null) {
        _this.compareArr.splice(0, 1)
        _this.compareList.splice(0, 1)
      }
      _this.compareArr.push(_this.replaceId)
      _this.getCompareList()
    },
    vizuonSeoTocate(code) {
      let cateCode = ''
      if (code === 'vehicles-and-transportation') {
        cateCode = 'VIZUON_VT'
      } else if (code === 'signs-and-displays') {
        cateCode = 'VIZUON_SD'
      } else if (code === 'illuminated-sign') {
        cateCode = 'VIZUON_IS'
      } else if (code === 'architecture') {
        cateCode = 'VIZUON_AR'
      } else if (code === 'eco-solutions') {
        cateCode = 'VIZUON_ES'
      } else {
        cateCode = 'VIZUON_CB'
      }
      return cateCode
    },
    loginalertLike() {
      alert(this.$t('PCD.alert.loginLike'))
    },
    // 구글 애널리틱스 이벤트
    makeDataLayerForm(data) {
      const {ecom_prd_brand, ecom_prd_category} = getEcomPrdData(data);
      return JSON.stringify({
        ecom_prd_id: data.attribute.code == '' ? data.content.id : data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand,
        ecom_prd_category, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    },
    /* 화면 url 생성 */
    makeUrl(item) {
      let url
      if (!item || !item.content || !item.content.id) {
        return ''
      }
      const prodInfo = getProductInfoByPath(this.$route.path);
      const detailUrl = item.content.slug ? 'detail/' + item.content.slug : this.$utils.getSeoName(item, 'content') + '/' + item.content.id;
      if (prodInfo.name === 'HFLOR') { // VIATERA
        url = '/hflor/' + this.makeHflorSeoMainClt(item.parents[0].code) + "/" + detailUrl
      } else if (prodInfo.name === 'VIZUON') {
        url = '/vizuon-sign-graphic/' + this.makeVizuonSeoMainClt(this.category.code) + "/" + detailUrl
      } else {
        url = prodInfo.url + detailUrl
      }
      return url
    },
    makeVizuonSeoMainClt(data) {
      let main_collectionNm = '';

      if (data.includes('VIZUON_VT')) {
        main_collectionNm = 'vehicles-and-transportation';
      } else if (data.includes('VIZUON_SD')) {
        main_collectionNm = 'signs-and-displays';
      } else if (data.includes('VIZUON_IS')) {
        main_collectionNm = 'illuminated-sign';
      } else if (data.includes('VIZUON_AR')) {
        main_collectionNm = 'architecture';
      } else if (data.includes('VIZUON_ES')) {
        main_collectionNm = 'eco-solutions';
      } else {
        main_collectionNm = 'corporate-branding';
      }
      return main_collectionNm;
    },
    makeHflorSeoMainClt(data) {
      let main_collectionNm = ''

      if (data.includes('FLOOR_LUX_DECOLEY')) {
        main_collectionNm = 'looselay'
      } else if (data.includes('FLOOR_LOS_STYLE')) {
        main_collectionNm = 'looselay'
      } else if (data.includes('FLOOR_LUX')) {
        main_collectionNm = 'lvt-flooring'
      } else if (data.includes('FLOOR_HOM')) {
        main_collectionNm = 'homogeneous-sheet'
      } else if (data.includes('FLOOR_HET')) {
        main_collectionNm = 'heterogeneous-sheet'
      } else if (data.includes('FLOOR_SPC')) {
        main_collectionNm = 'spc-click'
      } else if (data.includes('FLOOR_SPT')) {
        main_collectionNm = 'specialty'
      }


      return main_collectionNm
    },

    // sample order 재고 확인
    canOrder (item) {
      if (!item || !item.attribute) return false
      const price = item.attribute.price
      if(!_.isArray(price)) return false

      const filtered = price.filter(obj => _.toNumber(obj.price) > 0 && (_.isUndefined(obj.use) || obj.use == true))
      return filtered.length > 0
    },
    parseBundleProduct(item) {
      if (item.attribute.bundle_product && Array.isArray(item.attribute.bundle_product)) {
        return item.attribute.bundle_product.map(bundleItem => bundleItem.attribute);
      }
      return [];
    },
    getAllSlabSizes(item) {
      const sizes = new Set([item.attribute.slab_sizeText]);
      this.parseBundleProduct(item).forEach(bundleItem => {
        if (bundleItem.slab_sizeText) sizes.add(bundleItem.slab_sizeText);
      });
      return Array.from(sizes).filter(Boolean).join(' / ') || '-';
    },
    getAllThicknesses(item) {
      const thicknesses = new Set([item.attribute.thicknessText]);
      this.parseBundleProduct(item).forEach(bundleItem => {
        if (bundleItem.thicknessText) thicknesses.add(bundleItem.thicknessText);
      });
      return Array.from(thicknesses).filter(Boolean).join(' / ') || '-';
    },
    getAllWeights(item) {
      const weights = new Set([item.attribute.weightText]);
      this.parseBundleProduct(item).forEach(bundleItem => {
        if (bundleItem.weightText) weights.add(bundleItem.weightText);
      });
      return Array.from(weights).filter(Boolean).join(' / ') || '-';
    },
    getAllFinishes(item) {
      const finishes = new Set([item.attribute.finishText[0]]);
      this.parseBundleProduct(item).forEach(bundleItem => {
        if (bundleItem.finishText && bundleItem.finishText[0]) finishes.add(bundleItem.finishText[0]);
      });
      return Array.from(finishes).filter(Boolean).join(' / ') || '-';
    },

    getAllDimensions(item) {
      const dimensions = new Set([item.attribute.dimension]);
      this.parseBundleProduct(item).forEach(bundleItem => {
        if (bundleItem.dimension) dimensions.add(bundleItem.dimension);
      });
      return Array.from(dimensions).filter(Boolean).join(' / ') || '-';
    },
    getAllTeraThicknesses(item) {
      const thicknesses = new Set([item.attribute.thickness]);
      this.parseBundleProduct(item).forEach(bundleItem => {
        if (bundleItem.thickness) thicknesses.add(bundleItem.thickness);
      });
      return Array.from(thicknesses).filter(Boolean).join(' / ') || '-';
    },
    capitalizeWithExceptions(str) {
      return str
        .toLowerCase()
        .replace(/(?:^|\s|-|\.)\S/g, (match) => {
          if (match.toLowerCase() === 'p' && str.toLowerCase().startsWith('prestg')) {
            return 'P';
          }
          return match.toUpperCase();
        })
        .replace(/^prestg\b/i, 'PRESTG')
        .replace(/-(\d+(\.\d+)?)/g, ' $1')  // 숫자 앞의 하이픈을 공백으로 변경
        .replace(/-/g, ' ')  // 나머지 하이픈을 공백으로 변경
        .replace(/\babc\b/gi, 'ABC')  // 'abc'를 'ABC'로 변경
        .replace(/\bmini\b/gi, 'MINI')  // 'mini'를 'MINI'로 변경
        .replace(/PRESTG SPC Click/i, 'PRESTG SPC Click')
        .replace(/EQ Floor/i, 'EQ Floor')
        .replace(/\bwoodhaven\b/gi, 'WOODHAVEN')
        .replace(/PRESTG XL/i, 'PRESTG XL');
    }
  }
}


$(document).ready(function () {
  $('.scr-hder').addClass('on')
})

</script>
