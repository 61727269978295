<template>
  <div class="achievements-at-design-awards-card">
    <div class="text-area">
      <pre class="number">{{ number }}</pre>
      <pre class="text">{{ text }}</pre>
    </div>
    <div class="line"></div>
    <div class="item-area">
      <div class="item"
        v-for="(item, index) in itemDatas" :key="index"
      >
        <img class="item-image" :src="item.image">
        <pre class="item-number">{{ item.itemNumber }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String
    },
    text: {
      type: String
    },
    itemDatas: {
      type: Array
    }
  }
}
</script>

<style scoped>
.achievements-at-design-awards-card {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .achievements-at-design-awards-card {
    flex-direction: column;
    width: 90%;
  }
}

.achievements-at-design-awards-card .text-area .number {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 64.286% */
}

.achievements-at-design-awards-card .text-area .text {
  color: #907E72;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 200% */
}

@media (max-width: 768px) {
  .achievements-at-design-awards-card .text-area .number {
    font-size: 2.0833333333rem;
  }

  .achievements-at-design-awards-card .text-area .text {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }
}

.achievements-at-design-awards-card .line {
  margin: 0px 48px 0px 56px;
  width: 1px;
  height: 169px;
  background: #907E72;
}

@media (max-width: 768px) {
  .achievements-at-design-awards-card .line {
    height: 1px;
    width: 70%;
    margin: 30px 0px;
  }
}

.achievements-at-design-awards-card .item-area {
  display: flex;
  gap: 60px;
}

@media (max-width: 768px) {
  .achievements-at-design-awards-card .item-area {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.achievements-at-design-awards-card .item-area .item .item-image {
  width: fit-content;
}

.achievements-at-design-awards-card .item-area .item .item-number {
  margin-top: 20px;
  color: #907E72;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 100% */
}

@media (max-width: 768px) {
  .achievements-at-design-awards-card .item-area .item .item-number {
    font-size: 1.25rem;
    line-height: 1.3541666667rem;
  }
}
</style>
