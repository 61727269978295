var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Building & Decorative Materials",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.materialsCarouselItems,
                  desc: _vm.desc,
                  from: _vm.from,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "建築・装飾資材",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.materialsCarouselItemsJp,
                  desc: _vm.descJp,
                  from: _vm.from,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Строительные и декоративные материалы",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.materialsCarouselItemsRu,
                  desc: _vm.descRu,
                  from: _vm.from,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "建筑&装饰材料",
            fontWeight: "700",
            fontFamily: "Lato",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [
              _c("BusinessAreaCarousel", {
                attrs: {
                  items: _vm.materialsCarouselItemsCn,
                  desc: _vm.descCn,
                  from: _vm.from,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }