<template>
  <div class="social-responsibility-border-title-area">
    <div class="social-responsibility-border-title" :style="{'padding':padding}">
      <pre class="title">{{ title }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    padding: {
      type: String,
      default: '15px 40px'
    }
  }
}
</script>

<style>
.social-responsibility-border-title-area {
  display: flex;
  justify-content: center;
}

.social-responsibility-border-title {
  display: inline-flex;
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #A5968C;
  background: #FFF;
}

.social-responsibility-border-title .title {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .social-responsibility-border-title .title {
    font-size: 1.25rem;
    white-space: pre-wrap;
    text-align: center;
  }
}
</style>
