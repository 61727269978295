<template>
            <!-- 화면보시면 y축이 조금 밀리는데 {{GTM}}이 실시간으로 뜹니다. 저게없으면 높이가 정상이 됩니다. -->

  <div class="wrap">
    <div class="container error400-con">
      <div class="layer-view layer-imglib popup-v2">
        <div class="popup">
          <div class="scr-y">
            <div class="popup-body">
              <div class="frow">
                <div class="fcol tac">
                  <i class="pcon"><img class="er-icon" src="https://img.lxhausys.com/public/images/icon-error400.png" alt="error400 icon"></i>
                  <div class="msg">
                    <ul>
                      <li class="tt-2">Sorry, Page Not Found</li>
                      <li class="ctt ctt-t2">The Page you’re looking for can’t be found.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="popup-footer hasDouble">
              <div class="frow">
                <div class="fcol">
                  <button type="button" onclick="location.href='https://www.lxhausys.com/us/index'" class="btn">
                    <span>Go Home</span>
                  </button>
                  <button type="button" onclick="history.back()" class="btn btn-second">
                    <span>Go Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: 'notFound',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
})
</script>
