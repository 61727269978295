/* ============
 * Auth Module
 * ============
 */

import mutations from './mutations'
import state from './state'
import getters from './getters'

export default {
  namespaced: true,
  mutations,
  getters,
  state
}