<template>
  <CartPopComponent></CartPopComponent>
</template>

<script>
import CartPopComponent from '@/components/Popup/Cart'
export default {
  name: 'cart',
  components: {
    CartPopComponent
  },
  data () {
    return {}
  },
  async mounted () {
    this.openCart()
  },
  methods: {
    openCart () {
      this.$root.$refs.cartView.openPopup(true)
    }
  }
}
</script>
