<template>
  <div
    class="product-section-header-area"
    :class="directionClass"
  >
    <div v-if="title" class="title">
      <span>{{ title }}</span>
    </div>
    <div
      v-if="description"
      class="description"
      :class="directionClass"
    >
      <span>{{ description }}</span>
    </div>
    <div
      v-if="subTitle"
      :style="subTitleStyle"
      class="sub-title"
    >
      <span>{{ subTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    subTitleWidth: {
      type: String,
      required: false
    }
  },

  computed: {
    directionClass () {
      return this.type === 'horizontal' ? 'horizontal' : ''
    },
    subTitleStyle () {
      const width = this.subTitleWidth ?? '100%'
      return `width: ${width};`
    }
  }

}
</script>

<style scoped>
.product-section-header-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-section-header-area.horizontal {
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}
@media (max-width: 768px) {
  .product-section-header-area.horizontal {
    flex-wrap: wrap;
  }
}

.product-section-header-area .title {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-self: center;
  min-width: 163px;
}

.product-section-header-area .title span {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.33;
}
@media (min-width: 769px) {
  .product-section-header-area .title span {
    font-size: 44px;
  }
}


.product-section-header-area .description {
  margin-top: 20px;
  padding: 0px 210px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}
@media (max-width: 768px) {
  .product-section-header-area .description {
    padding: 0px;
  }
}

.product-section-header-area .description.horizontal {
  margin-top: 0px;
  margin-left: 48px;
  padding: 0px;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  text-align: left;
}
@media (max-width: 768px) {
  .product-section-header-area .description.horizontal {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.product-section-header-area .description span {
  color: #222;
  display: flex;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .product-section-header-area .description span {
    font-size: 20px;
    line-height: 36px;
  }
}

.product-section-header-area .sub-title {
  width: unset !important;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}

.product-section-header-area .sub-title.horizontal {
  margin-top: 0px;
  margin-left: 48px;
  padding: 0px;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  text-align: left;
}
@media (max-width: 768px) {
  .product-section-header-area .sub-title.horizontal {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.product-section-header-area .sub-title span {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}
@media (min-width: 769px) {
  .product-section-header-area .sub-title span {
    font-size: 30px;
    line-height: 46px;
  }
}

</style>
