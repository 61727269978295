var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "layer-view layer-result popup-v2" }, [
        _c("div", { staticClass: "popup pop-close" }, [
          _c("div", { staticClass: "scr-y" }, [
            _c("p", { staticClass: "popup-title" }, [
              _vm._v("Warranty Claim Registration"),
            ]),
            _c("div", { staticClass: "popup-body" }, [
              _c("div", { staticClass: "frow" }, [
                _c("div", { staticClass: "fcol tac" }, [
                  _c("i", { staticClass: "pcon pcon-check" }),
                  _c("strong", { staticClass: "err-title" }, [
                    _vm._v("Warranty Claim Registration Complete!"),
                  ]),
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(
                      "Installation Date: " +
                        _vm._s(_vm.data.installMonth) +
                        "/" +
                        _vm._s(_vm.data.installDay) +
                        "/" +
                        _vm._s(_vm.data.installYear)
                    ),
                  ]),
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(
                      "Registration Date: " +
                        _vm._s(_vm.$moment().format("MM/DD/YYYY"))
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-close",
              attrs: { type: "button", onClick: "tmpClose('warranty')" },
            },
            [_vm._v("close")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "content cntu-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("warranty.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("contactus.text.contactus")))]),
            _vm.nationCode !== "_GL" && _vm.nationCode !== "_US"
              ? _c("li", [_vm._v(_vm._s(_vm.$t("warranty.text.support")))])
              : _vm._e(),
            _c("li", [
              _vm._v(_vm._s(_vm.$t("warranty.text.warranty")) + " Claim"),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container cntu-box wa-box us-container-warranty" },
          [
            _c("div", { staticClass: "w-con-02" }, [
              _c("div", { staticClass: "inner" }, [
                _c("div", { staticClass: "sub-visual-section" }, [
                  _c("h1", { staticClass: "tt-1 visual-title tac" }, [
                    _vm._v(
                      _vm._s(_vm.$t("warranty.text.warranty")) + " Claim "
                    ),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(" - " + _vm._s(_vm.prdLabel)),
                    ]),
                  ]),
                  _c("h2", { staticClass: "sr-only" }, [
                    _vm._v("LX Hausys Warranty " + _vm._s(_vm.prdLabel)),
                  ]),
                  _c("div", { staticClass: "ctt ctt-t2 tac visual-desc" }, [
                    _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                      _vm._v(_vm._s(_vm.$t("warranty.text.mainTxt1"))),
                    ]),
                    _c("span", { staticClass: "ctt ctt-t2 pc-br" }, [
                      _vm._v(_vm._s(_vm.$t("warranty.text.mainTxt2"))),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "care-box" }, [
                _c("div", { staticClass: "inner" }, [
                  _c("div", { staticClass: "tbl-input-box mt-80 wtr-area" }, [
                    _c(
                      "form",
                      {
                        attrs: {
                          id: "warrantyClaim",
                          action:
                            "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
                          method: "POST",
                        },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submitForm.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "orgid",
                            value: "00D410000005ywy",
                          },
                        }),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "retURL",
                            value:
                              "https://www.lxhausys.com/us/support/warranty-claim",
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.color,
                              expression: "data.color",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuu",
                            name: "00N2M00000fYBuu",
                          },
                          domProps: { value: _vm.data.color },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "color", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.product,
                              expression: "data.product",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuw",
                            name: "00N2M00000fYBuw",
                          },
                          domProps: { value: _vm.data.product },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "product", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.firstNm,
                              expression: "data.firstNm",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuj",
                            name: "00N2M00000fYBuj",
                          },
                          domProps: { value: _vm.data.firstNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "firstNm", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.lastNm,
                              expression: "data.lastNm",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBun",
                            name: "00N2M00000fYBun",
                          },
                          domProps: { value: _vm.data.lastNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "lastNm", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.countryCd,
                              expression: "data.countryCd",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuX",
                            name: "00N2M00000fYBuX",
                          },
                          domProps: { value: _vm.data.countryCd },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "countryCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.stateCd,
                              expression: "data.stateCd",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv5",
                            name: "00N2M00000fYBv5",
                          },
                          domProps: { value: _vm.data.stateCd },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "stateCd", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.addr1,
                              expression: "data.addr1",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv6",
                            name: "00N2M00000fYBv6",
                          },
                          domProps: { value: _vm.data.addr1 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "addr1", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.zipCd,
                              expression: "data.zipCd",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBur",
                            name: "00N2M00000fYBur",
                          },
                          domProps: { value: _vm.data.zipCd },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "zipCd", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.phone,
                              expression: "data.phone",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBut",
                            name: "00N2M00000fYBut",
                          },
                          domProps: { value: _vm.data.phone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "phone", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.mobile,
                              expression: "data.mobile",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBup",
                            name: "00N2M00000fYBup",
                          },
                          domProps: { value: _vm.data.mobile },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "mobile", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.email,
                              expression: "data.email",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuc",
                            name: "00N2M00000fYBuc",
                          },
                          domProps: { value: _vm.data.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "email", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.city,
                              expression: "data.city",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuR",
                            name: "00N2M00000fYBuR",
                          },
                          domProps: { value: _vm.data.city },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "city", $event.target.value)
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.fabricator,
                              expression: "data.fabricator",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuh",
                            name: "00N2M00000fYBuh",
                          },
                          domProps: { value: _vm.data.fabricator },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "fabricator",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.fabricatorContactNm,
                              expression: "data.fabricatorContactNm",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBue",
                            name: "00N2M00000fYBue",
                          },
                          domProps: { value: _vm.data.fabricatorContactNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "fabricatorContactNm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.fabricatorPhone,
                              expression: "data.fabricatorPhone",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBug",
                            name: "00N2M00000fYBug",
                          },
                          domProps: { value: _vm.data.fabricatorPhone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "fabricatorPhone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.fabricatorEmail,
                              expression: "data.fabricatorEmail",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuf",
                            name: "00N2M00000fYBuf",
                          },
                          domProps: { value: _vm.data.fabricatorEmail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "fabricatorEmail",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.location,
                              expression: "data.location",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuo",
                            name: "00N2M00000fYBuo",
                          },
                          domProps: { value: _vm.data.location },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "location",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.retailer,
                              expression: "data.retailer",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv2",
                            name: "00N2M00000fYBv2",
                          },
                          domProps: { value: _vm.data.retailer },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "retailer",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.retailerContactNm,
                              expression: "data.retailerContactNm",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuz",
                            name: "00N2M00000fYBuz",
                          },
                          domProps: { value: _vm.data.retailerContactNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "retailerContactNm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.retailerPhone,
                              expression: "data.retailerPhone",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv1",
                            name: "00N2M00000fYBv1",
                          },
                          domProps: { value: _vm.data.retailerPhone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "retailerPhone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.retailerEmail,
                              expression: "data.retailerEmail",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv0",
                            name: "00N2M00000fYBv0",
                          },
                          domProps: { value: _vm.data.retailerEmail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "retailerEmail",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.initialCompliant,
                              expression: "data.initialCompliant",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBul",
                            name: "00N2M00000fYBul",
                          },
                          domProps: { value: _vm.data.initialCompliant },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "initialCompliant",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.data.installMonth +
                                "/" +
                                _vm.data.installDay +
                                "/" +
                                _vm.data.installYear,
                              expression:
                                "data.installMonth + '/' + data.installDay + '/' + data.installYear",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBum",
                            name: "00N2M00000fYBum",
                          },
                          domProps: {
                            value:
                              _vm.data.installMonth +
                              "/" +
                              _vm.data.installDay +
                              "/" +
                              _vm.data.installYear,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data.installMonth +
                                  "/" +
                                  _vm.data.installDay +
                                  "/" +
                                  _vm.data,
                                "installYear",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.colorMatchAvailable,
                              expression: "data.colorMatchAvailable",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBuU",
                            name: "00N2M00000fYBuU",
                          },
                          domProps: { value: _vm.data.colorMatchAvailable },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "colorMatchAvailable",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.sinkType,
                              expression: "data.sinkType",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv4",
                            name: "00N2M00000fYBv4",
                          },
                          domProps: { value: _vm.data.sinkType },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "sinkType",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.sinkColor,
                              expression: "data.sinkColor",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv3",
                            name: "00N2M00000fYBv3",
                          },
                          domProps: { value: _vm.data.sinkColor },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "sinkColor",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.sinkThick,
                              expression: "data.sinkThick",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYBv8",
                            name: "00N2M00000fYBv8",
                          },
                          domProps: { value: _vm.data.sinkThick },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "sinkThick",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.other,
                              expression: "data.other",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            id: "00N2M00000fYmpx",
                            name: "00N2M00000fYmpx",
                          },
                          domProps: { value: _vm.data.other },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "other", $event.target.value)
                            },
                          },
                        }),
                        _c("fieldset", [
                          _c("legend", [_vm._v("Warranty Claim Registration")]),
                          _c("div", { staticClass: "reg-tbl" }, [
                            _c("p", { staticClass: "tt-2 mb-40" }, [
                              _vm._v(
                                _vm._s(_vm.$t("warranty.text.personal_info"))
                              ),
                            ]),
                            _c("div", { staticClass: "col-02" }, [
                              _c("div", { staticClass: "tbl-01" }, [
                                _c("div", { staticClass: "tbl-input" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "warranty.text.personal_info_form"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c("span", { staticClass: "must" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("warranty.text.country")
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c(
                                            "div",
                                            { staticClass: "sel-box type02" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-sel",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.countryTxt)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "sel-list" },
                                                [
                                                  _c(
                                                    "ul",
                                                    { staticClass: "box" },
                                                    _vm._l(
                                                      _vm.countryList,
                                                      function (cnt, idx) {
                                                        return _c(
                                                          "li",
                                                          { key: idx },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clickSelectBox(
                                                                        cnt.countryNm ==
                                                                          "CANADA"
                                                                          ? "Canada"
                                                                          : "United States",
                                                                        cnt.countryCd,
                                                                        "country"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    cnt.countryNm ==
                                                                      "CANADA"
                                                                      ? "Canada"
                                                                      : "United States"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.data.countryCd == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.select_country"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "must",
                                              attrs: { for: "inp01" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.first_name"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.firstNm,
                                                expression: "data.firstNm",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp01",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.firstNm,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "firstNm",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.firstNm == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.Enter_First_Name"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "must",
                                              attrs: { for: "inp02" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.last_name"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.lastNm,
                                                expression: "data.lastNm",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp02",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.lastNm,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "lastNm",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.lastNm == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.Enter_Last_Name"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(0),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.addr1,
                                                expression: "data.addr1",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp03",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: { value: _vm.data.addr1 },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "addr1",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.addr1 == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.enter_address"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "must",
                                              attrs: { for: "inp08" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("warranty.text.city")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.city,
                                                expression: "data.city",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "100",
                                              id: "inp18",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: { value: _vm.data.city },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "city",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.city == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.enter_city"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "tbl-02" }, [
                                _c("div", { staticClass: "tbl-input" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "warranty.text.personal_info_form"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _vm._m(1),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c(
                                            "div",
                                            { staticClass: "sel-box type02" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-sel",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.stateTxt)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "sel-list" },
                                                [
                                                  _c(
                                                    "ul",
                                                    { staticClass: "box" },
                                                    _vm._l(
                                                      _vm.stateList,
                                                      function (state) {
                                                        return _c("li", [
                                                          _c(
                                                            "button",
                                                            {
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clickSelectBox(
                                                                      state.stateNm,
                                                                      state.id
                                                                        .stateCd,
                                                                      "state"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  state.stateNm
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.data.stateCd == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.select_state"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "must",
                                              attrs: { for: "inp07" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "warranty.text.zip_code"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.zipCd,
                                                expression: "data.zipCd",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "7",
                                              id: "inp07",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: { value: _vm.data.zipCd },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "zipCd",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.zipCd == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.enter_zip_code"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(2),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.phone,
                                                expression: "data.phone",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "40",
                                              id: "inp05",
                                              placeholder: "000-000-0000",
                                              oninput:
                                                "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                            },
                                            domProps: { value: _vm.data.phone },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "phone",
                                                  _vm.data.phone
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "phone",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          (_vm.data.phone == "" &&
                                            _vm.submitChk == "Y") ||
                                          !_vm.checkValid(
                                            "phone",
                                            _vm.data.phone
                                          )
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.enter_telephone"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(3),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.mobile,
                                                expression: "data.mobile",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "40",
                                              id: "inp05",
                                              placeholder: "000-000-0000",
                                              oninput:
                                                "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                            },
                                            domProps: {
                                              value: _vm.data.mobile,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "phone",
                                                  _vm.data.mobile
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "mobile",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.mobile != "" &&
                                          !_vm.checkValid(
                                            "phone",
                                            _vm.data.mobile
                                          )
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "warranty.text.enter_telephone"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "must",
                                              attrs: { for: "inp06" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("warranty.text.email")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.email,
                                                expression: "data.email",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "80",
                                              id: "inp06",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: { value: _vm.data.email },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "email",
                                                  _vm.data.email
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          (_vm.data.email == "" &&
                                            _vm.submitChk == "Y") ||
                                          !_vm.checkValid(
                                            "email",
                                            _vm.data.email
                                          )
                                            ? _c("p", [
                                                _vm._v(
                                                  "Enter Email Address – This is how you will be contacted. Claim cannot process without it."
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "tbl" }, [
                            _c("p", { staticClass: "tt-2 mb-40" }, [
                              _vm._v("Fabricator/Retailer Information"),
                            ]),
                            _c("div", { staticClass: "col-02" }, [
                              _c("div", { staticClass: "tbl-01" }, [
                                _c("div", { staticClass: "tbl-input" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v(
                                        "Fabricator/Retailer Information form"
                                      ),
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _vm._m(4),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.fabricator,
                                                expression: "data.fabricator",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp11",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.fabricator,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "fabricator",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.fabricator == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v("Enter Fabricator"),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(5),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.data.fabricatorContactNm,
                                                expression:
                                                  "data.fabricatorContactNm",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp11",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value:
                                                _vm.data.fabricatorContactNm,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "fabricatorContactNm",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(6),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.fabricatorPhone,
                                                expression:
                                                  "data.fabricatorPhone",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "40",
                                              id: "inp12",
                                              placeholder: "000-000-0000",
                                              oninput:
                                                "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                            },
                                            domProps: {
                                              value: _vm.data.fabricatorPhone,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "phone",
                                                  _vm.data.fabricatorPhone
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "fabricatorPhone",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.fabricatorPhone != "" &&
                                          !_vm.checkValid(
                                            "phone",
                                            _vm.data.fabricatorPhone
                                          )
                                            ? _c("p", [
                                                _vm._v(
                                                  "Enter Fabricator Phone"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(7),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.fabricatorEmail,
                                                expression:
                                                  "data.fabricatorEmail",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "80",
                                              id: "inp06",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.fabricatorEmail,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "email",
                                                  _vm.data.fabricatorEmail
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "fabricatorEmail",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.fabricatorEmail != "" &&
                                          !_vm.checkValid(
                                            "email",
                                            _vm.data.fabricatorEmail
                                          )
                                            ? _c("p", [
                                                _vm._v(
                                                  "Enter Fabricator Email"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(8),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.location,
                                                expression: "data.location",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp11",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.location,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "location",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "tbl-02" }, [
                                _c("div", { staticClass: "tbl-input type03" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v("Fabricator/Retailer Information"),
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _vm._m(9),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.retailer,
                                                expression: "data.retailer",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp08",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.retailer,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "retailer",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.retailer == "" &&
                                          _vm.submitChk == "Y"
                                            ? _c("p", [
                                                _vm._v("Enter Retailer"),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(10),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.data.retailerContactNm,
                                                expression:
                                                  "data.retailerContactNm",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "255",
                                              id: "inp09",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.retailerContactNm,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "retailerContactNm",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(11),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.retailerPhone,
                                                expression:
                                                  "data.retailerPhone",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "40",
                                              id: "inp10",
                                              placeholder: "000-000-0000",
                                              oninput:
                                                "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                            },
                                            domProps: {
                                              value: _vm.data.retailerPhone,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "phone",
                                                  _vm.data.retailerPhone
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "retailerPhone",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.retailerPhone != "" &&
                                          !_vm.checkValid(
                                            "phone",
                                            _vm.data.retailerPhone
                                          )
                                            ? _c("p", [
                                                _vm._v("Enter Retailer Phone"),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _vm._m(12),
                                        _c("td", { staticClass: "cell-noti" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.retailerEmail,
                                                expression:
                                                  "data.retailerEmail",
                                              },
                                            ],
                                            staticClass: "inp-text",
                                            attrs: {
                                              type: "text",
                                              maxlength: "80",
                                              id: "inp06",
                                              placeholder: _vm.$t(
                                                "warranty.text.input_text"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.data.retailerEmail,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkValidAlert(
                                                  "email",
                                                  _vm.data.retailerEmail
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.data,
                                                  "retailerEmail",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm.data.retailerEmail != "" &&
                                          !_vm.checkValid(
                                            "email",
                                            _vm.data.retailerEmail
                                          )
                                            ? _c("p", [
                                                _vm._v("Enter Retailer Email"),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "tbl product_info_wrap" }, [
                            _c("p", { staticClass: "tt-2 mb-40" }, [
                              _vm._v(
                                _vm._s(_vm.$t("warranty.text.product_info"))
                              ),
                            ]),
                            _c("div", { staticClass: "col-02" }, [
                              _c("div", { staticClass: "tbl-01" }, [
                                _c("div", { staticClass: "tbl-input" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "warranty.text.product_info_form"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("tbody", [
                                      _c(
                                        "tr",
                                        { staticClass: "warranty_tbl_tr" },
                                        [
                                          _c("td", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "must" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.date_of_installation"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "sel-day" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-box type02",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.installMonthTxt
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                _vm._l(
                                                                  _vm.installMonthList,
                                                                  function (
                                                                    mon,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: idx,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.clickSelectBox(
                                                                                    mon,
                                                                                    "",
                                                                                    "month"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                mon
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-box type02",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.installDayTxt
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                _vm._l(
                                                                  _vm.installDayList,
                                                                  function (
                                                                    day,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: idx,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.clickSelectBox(
                                                                                    day,
                                                                                    "",
                                                                                    "day"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                day
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-box type02",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.installYearTxt
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                _vm._l(
                                                                  _vm.installYearList,
                                                                  function (
                                                                    year,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: idx,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.clickSelectBox(
                                                                                    year,
                                                                                    "",
                                                                                    "year"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                year
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.data.installMonth == "" ||
                                                  _vm.data.installDay == "" ||
                                                  (_vm.data.installYear == "" &&
                                                    _vm.submitChk == "Y")
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.select_date"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "tr",
                                        { staticClass: "warranty_tbl_tr" },
                                        [
                                          _c("td", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "row" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "must" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.categoryTxt"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sel-box type02",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-sel",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.productTxt
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-list",
                                                        },
                                                        [
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "box",
                                                            },
                                                            _vm._l(
                                                              _vm.productList,
                                                              function (
                                                                prd,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "li",
                                                                  { key: idx },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.clickSelectBox(
                                                                                prd.text,
                                                                                prd
                                                                                  .id
                                                                                  .value,
                                                                                "product"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            prd.text
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.data.product == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "warranty.text.select_registering"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                          _c("td", [
                                            _c("tr", [
                                              !_vm.sinkRequired
                                                ? _c(
                                                    "th",
                                                    { attrs: { scope: "row" } },
                                                    [
                                                      _c(
                                                        "label",
                                                        { staticClass: "must" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "warranty.text.color"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "th",
                                                    { attrs: { scope: "row" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "warranty.text.color"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                              _c(
                                                "td",
                                                { staticClass: "cell-noti" },
                                                [
                                                  !_vm.sinkRequired
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.data.color,
                                                            expression:
                                                              "data.color",
                                                          },
                                                        ],
                                                        staticClass: "inp-text",
                                                        attrs: {
                                                          type: "text",
                                                          id: "color",
                                                          disabled:
                                                            _vm.sinkRequired,
                                                          placeholder: _vm.$t(
                                                            "warranty.text.input_text"
                                                          ),
                                                        },
                                                        domProps: {
                                                          value: _vm.data.color,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.data,
                                                              "color",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !_vm.sinkRequired &&
                                                  _vm.data.color == "" &&
                                                  _vm.submitChk == "Y"
                                                    ? _c("p", [
                                                        _vm._v(
                                                          "Enter Product Color"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "tr",
                                        {
                                          staticClass:
                                            "warranty_tbl_tr warranty_tbl_tr_big",
                                        },
                                        [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "2" } },
                                            [
                                              _c("tr", [
                                                _vm._m(13),
                                                _c(
                                                  "td",
                                                  { staticClass: "cell-noti" },
                                                  [
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.data
                                                              .initialCompliant,
                                                          expression:
                                                            "data.initialCompliant",
                                                        },
                                                      ],
                                                      attrs: {
                                                        placeholder: _vm.$t(
                                                          "warranty.text.input_text"
                                                        ),
                                                        id: "inp11",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.data
                                                            .initialCompliant,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.data,
                                                            "initialCompliant",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.data.initialCompliant ==
                                                      "" && _vm.submitChk == "Y"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Enter Initial Compliant"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tr",
                                        { staticClass: "warranty_tbl_tr" },
                                        [
                                          !_vm.sinkRequired && !_vm.otherThick
                                            ? _c("td", [
                                                _c("tr", [
                                                  _vm._m(14),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "cell-noti",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-box type02",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-sel",
                                                              style: [
                                                                _vm.sinkRequired ||
                                                                _vm.otherThick
                                                                  ? {
                                                                      "background-color":
                                                                        "#E4E4E4",
                                                                    }
                                                                  : {
                                                                      "background-color":
                                                                        "transparent",
                                                                    },
                                                              ],
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  _vm.sinkRequired ||
                                                                  _vm.otherThick,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  style: [
                                                                    _vm.sinkRequired ||
                                                                    _vm.otherThick
                                                                      ? {
                                                                          color:
                                                                            "#a0a0a0",
                                                                        }
                                                                      : {
                                                                          color:
                                                                            "initial",
                                                                        },
                                                                  ],
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.sinkThickTxt
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                            },
                                                            [
                                                              !_vm.sinkRequired &&
                                                              !_vm.otherThick &&
                                                              _vm.showHimacsBox
                                                                ? _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "box",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.hmThickSelBox,
                                                                      function (
                                                                        thick,
                                                                        idx
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: idx,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "button",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.clickSelectBox(
                                                                                        thick.text,
                                                                                        thick
                                                                                          .id
                                                                                          .value,
                                                                                        "sinkThick"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    thick.text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : !_vm.sinkRequired &&
                                                                  !_vm.otherThick &&
                                                                  _vm.showViateraBox
                                                                ? _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "box",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.vtThickSelBox,
                                                                      function (
                                                                        thick,
                                                                        idx
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: idx,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "button",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.clickSelectBox(
                                                                                        thick.text,
                                                                                        thick
                                                                                          .id
                                                                                          .value,
                                                                                        "sinkThick"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    thick.text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : !_vm.sinkRequired &&
                                                                  !_vm.otherThick &&
                                                                  _vm.showTeracantoBox
                                                                ? _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "box",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.tcThickSelBox,
                                                                      function (
                                                                        thick,
                                                                        idx
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: idx,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "button",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.clickSelectBox(
                                                                                        thick.text,
                                                                                        thick
                                                                                          .id
                                                                                          .value,
                                                                                        "sinkThick"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    thick.text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "box",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.sinkThickList,
                                                                      function (
                                                                        thick,
                                                                        idx
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: idx,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "button",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.clickSelectBox(
                                                                                        thick.text,
                                                                                        thick
                                                                                          .id
                                                                                          .value,
                                                                                        "sinkThick"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    thick.text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      !_vm.sinkRequired &&
                                                      !_vm.otherThick &&
                                                      _vm.data.sinkThick ==
                                                        "" &&
                                                      _vm.submitChk == "Y"
                                                        ? _c("p", [
                                                            _vm._v(
                                                              "Enter Thickness"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                          _vm.sinkRequired
                                            ? _c("td", [
                                                _c("tr", [
                                                  _vm._m(15),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "cell-noti",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-box type02",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.sinkTypeTxt
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                _vm._l(
                                                                  _vm.sinkTypeList,
                                                                  function (
                                                                    sinktype,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: idx,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.clickSelectBox(
                                                                                    sinktype.text,
                                                                                    sinktype
                                                                                      .id
                                                                                      .value,
                                                                                    "sinkType"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                sinktype.text
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.sinkRequired &&
                                                      _vm.data.sinkType == "" &&
                                                      _vm.submitChk == "Y"
                                                        ? _c("p", [
                                                            _vm._v(
                                                              "Enter Sink Type"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                          _vm.showHimacsBox
                                            ? _c("td", [
                                                _c("tr", [
                                                  _vm._m(16),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "cell-noti",
                                                    },
                                                    [
                                                      _vm.showHimacsBox
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-box type02",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-sel",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.colorMatchTxt
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "sel-list",
                                                                },
                                                                [
                                                                  _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "box",
                                                                    },
                                                                    _vm._l(
                                                                      _vm.colorMatchList,
                                                                      function (
                                                                        match,
                                                                        idx
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: idx,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "button",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.clickSelectBox(
                                                                                        match.text,
                                                                                        match
                                                                                          .id
                                                                                          .value,
                                                                                        "colorMatch"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    match.text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.showHimacsBox &&
                                                      _vm.data
                                                        .colorMatchAvailable ==
                                                        "" &&
                                                      _vm.submitChk == "Y"
                                                        ? _c("p", [
                                                            _vm._v(
                                                              "Enter Color Match Available"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                          _vm.sinkRequired &&
                                          _vm.data.sinkType != "Other"
                                            ? _c("td", [
                                                _c("tr", [
                                                  _vm._m(17),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "cell-noti",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "sel-box type02",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.sinkColorTxt
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                _vm._l(
                                                                  _vm.sinkColorList,
                                                                  function (
                                                                    sink,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: idx,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.clickSelectBox(
                                                                                    sink.text,
                                                                                    sink
                                                                                      .id
                                                                                      .value,
                                                                                    "sinkColor"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                sink.text
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.sinkRequired &&
                                                      _vm.data.sinkColor ==
                                                        "" &&
                                                      _vm.submitChk == "Y" &&
                                                      _vm.data.sinkType !=
                                                        "Other"
                                                        ? _c("p", [
                                                            _vm._v(
                                                              "Enter Sink Color"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.data.sinkType == "Other"
                                        ? _c(
                                            "tr",
                                            {
                                              staticClass:
                                                "warranty_tbl_tr warranty_tbl_tr_big",
                                            },
                                            [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [
                                                  _c("tr", [
                                                    _vm.data.product ==
                                                      "Sink" &&
                                                    _vm.data.sinkType == "Other"
                                                      ? _c(
                                                          "th",
                                                          {
                                                            attrs: {
                                                              scope: "row",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "must",
                                                                attrs: {
                                                                  for: "inp11",
                                                                },
                                                              },
                                                              [_vm._v("Other")]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "cell-noti",
                                                      },
                                                      [
                                                        _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.data.other,
                                                              expression:
                                                                "data.other",
                                                            },
                                                          ],
                                                          attrs: {
                                                            maxlength: "255",
                                                            placeholder: _vm.$t(
                                                              "warranty.text.input_text"
                                                            ),
                                                            id: "inp11",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.data.other,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm.data,
                                                                "other",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm.data.product ==
                                                          "Sink" &&
                                                        _vm.data.sinkType ==
                                                          "Other" &&
                                                        _vm.data.other == ""
                                                          ? _c("p", [
                                                              _vm._v(
                                                                "Enter Other"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "mt-64 ta-c" }, [
                            _c("button", { staticClass: "btn" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("warranty.button.submit"))
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { staticClass: "must", attrs: { for: "inp03" } }, [
        _vm._v("Street Address"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("State/Province")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { staticClass: "must", attrs: { for: "inp05" } }, [
        _vm._v("Primary Phone"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp05" } }, [_vm._v("Mobile")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { staticClass: "must", attrs: { for: "inp11" } }, [
        _vm._v("Installer"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp11" } }, [
        _vm._v("Fabricator Contact Name"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp12" } }, [_vm._v("Fabricator Phone")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp06" } }, [_vm._v("Fabricator Email")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp11" } }, [_vm._v("Location")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { staticClass: "must", attrs: { for: "inp08" } }, [
        _vm._v("Retailer purchased from"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp09" } }, [
        _vm._v("Retailer Contact Name"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp10" } }, [_vm._v("Retailer Phone")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { attrs: { for: "inp06" } }, [_vm._v("Retailer Email")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { staticClass: "must", attrs: { for: "inp11" } }, [
        _vm._v("Initial Compliant"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Thickness")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Sink Type")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("label", { staticClass: "must", attrs: { for: "inp09" } }, [
        _vm._v("Color Match Available"),
        _c("br"),
        _vm._v("(HIMACS Only)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "row" } }, [
      _c("span", { staticClass: "must" }, [_vm._v("Sink Color")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }