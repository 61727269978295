<template>
  <div class="social-responsibility-table-header" :style="{'background':background}">
    <pre class="header-text">{{ text }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    background: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>
.social-responsibility-table-header {
  display: flex;
  border-top: 1px solid #888;
  border-bottom: 1px solid #EAEAEA;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.social-responsibility-table-header .header-text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  margin: 12px 0px;
}
</style>
