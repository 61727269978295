import request from './request'
const urlPrefix = ''

const sampleOrderService = {
  getProductInfo (contId) {
    return request({
      url: `${urlPrefix}/sample/getProductInfo/${contId}`,
      method: 'get'
    })
  },
  getStateList (countrycd) {
    return request({
      url: `${urlPrefix}/sample/pageCont/state?countryCd=${countrycd}`,
      method: 'get'
    })
  },
  getCountryList () {
    return request({
      url: `${urlPrefix}/sample/pageCont/country`,
      method: 'get'
    })
  },
  saveOrder (postData) {
    return request({
      url: `${urlPrefix}/sample/save`,
      method: 'post',
      data: postData
    })
  },
  cancelOrder (postData) {
    return request({
      url: `${urlPrefix}/sample/cancel`,
      method: 'post',
      data: postData
    })
  },
  confirmOrder (postData) {
    return request({
      url: `${urlPrefix}/sample/confirm`,
      method: 'post',
      data: postData
    })
  },
  getOrder (orderId) {
    return request({
      url: `${urlPrefix}/sample/orderInfo/${orderId}`,
      method: 'get'
    })
  },
}

export default sampleOrderService
