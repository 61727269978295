var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "key-performance-table-body" }, [
    _c("div", { staticClass: "key-performance-table-body-content" }, [
      _c("span", { staticClass: "key-performance-table-body-number" }, [
        _c("pre", [
          _c("b", [_vm._v(_vm._s(_vm.number))]),
          _vm._v(_vm._s(_vm.unit)),
        ]),
      ]),
      _c("span", { staticClass: "key-performance-table-body-summary" }, [
        _vm._v(_vm._s(_vm.summary)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }