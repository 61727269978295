var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabCount > 0
    ? _c("div", { staticClass: "tab-con on" }, [
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("HFLOR ("),
              _c("em", [_vm._v(_vm._s(_vm.FLOOR.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-search-collection" }, [
            _vm.FLOOR && _vm.FLOOR.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.FLOOR.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-collection-cont-box" },
                      [
                        _c("div", { staticClass: "img-box" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoHflorDetail(sr)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + sr.cover,
                                  alt: sr.title,
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _c(
                                "a",
                                { attrs: { href: "javascript:void(0);" } },
                                [
                                  _vm._v("["),
                                  _c("span", [
                                    _vm._v(_vm._s(sr.categories[1].name)),
                                  ]),
                                  _vm._v("] "),
                                  _c("span", [_vm._v(_vm._s(sr.title))]),
                                ]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoHflorDetail(sr)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("learn_more_collection"))
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.FLOOR.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-arr-down",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreHflor()
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                ),
              ])
            : _vm._e(),
        ]),
        _vm.showViatera()
          ? _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
              _c("div", { staticClass: "res-prod-cnt" }, [
                _c("span", [
                  _vm._v("VIATERA ("),
                  _c("em", [_vm._v(_vm._s(_vm.VIATERA.totalCount))]),
                  _vm._v(")"),
                ]),
              ]),
              _c("div", { staticClass: "list-search-collection" }, [
                _vm.VIATERA && _vm.VIATERA.totalCount > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.VIATERA.list, function (sr) {
                        return _c(
                          "li",
                          { staticClass: "search-collection-cont-box" },
                          [
                            _c("div", { staticClass: "img-box" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoViateraDetail(sr)
                                    },
                                  },
                                },
                                [
                                  sr.cover
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.$imagePath + sr.cover,
                                          alt: sr.title,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _c(
                                    "a",
                                    { attrs: { href: "javascript:void(0);" } },
                                    [
                                      _vm._v("["),
                                      _c("span", [
                                        _vm._v(_vm._s(sr.categories[1].name)),
                                      ]),
                                      _vm._v("] "),
                                      _c("span", [_vm._v(_vm._s(sr.title))]),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-02" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoViateraDetail(sr)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("see_all_colors")))]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.gotoViateraDetail(sr)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("learn_more_collection"))
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              !_vm.VIATERA.lastPage
                ? _c("div", { staticClass: "more-box ta-c" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-arr-down",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.loadMoreViatera()
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", { staticClass: "sch-res-prod prod-fd-view" }, [
          _c("div", { staticClass: "res-prod-cnt" }, [
            _c("span", [
              _vm._v("HIMACS ("),
              _c("em", [_vm._v(_vm._s(_vm.HIMACS.totalCount))]),
              _vm._v(")"),
            ]),
          ]),
          _c("div", { staticClass: "list-search-collection" }, [
            _vm.HIMACS && _vm.HIMACS.totalCount > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.HIMACS.list, function (sr) {
                    return _c(
                      "li",
                      { staticClass: "search-collection-cont-box" },
                      [
                        _c("div", { staticClass: "img-box" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoHimacsDetail(sr)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + sr.cover,
                                  alt: sr.title,
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _c(
                                "a",
                                { attrs: { href: "javascript:void(0);" } },
                                [
                                  _vm._v("["),
                                  _c("span", [
                                    _vm._v(_vm._s(sr.categories[1].name)),
                                  ]),
                                  _vm._v("] "),
                                  _c("span", [_vm._v(_vm._s(sr.title))]),
                                ]
                              ),
                            ]),
                            _c("li", { staticClass: "txt-02" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoHimacsDetail(sr)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("see_all_colors")))]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoHimacsDetail(sr)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("learn_more_collection"))
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.HIMACS.lastPage
            ? _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-arr-down",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.loadMoreHimacs()
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("see_more")))])]
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _c("div", { staticClass: "no-found-wrap bd-top" }, [_vm._m(0)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tac" }, [
      _c("i", { staticClass: "icon-no-result" }, [
        _c("img", {
          attrs: { src: "images/icon-no-result-found.png", alt: "no result" },
        }),
      ]),
      _c("div", { staticClass: "msg" }, [
        _c("ul", [
          _c("li", { staticClass: "ctt ctt-t1 result-tit" }, [
            _vm._v("No Result Found"),
          ]),
          _c("li", { staticClass: "ctt ctt-t4" }, [
            _vm._v("Try again using more general search keywords"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }