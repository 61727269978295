var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Key Performance 2023",
            color: "black",
            size: "56px",
          },
        },
        [
          _c("OverviewTopImage", {
            staticClass: "key-performance-image",
            attrs: {
              imageSrc:
                "images/aboutus/overview/performance/key-performance-2022-image.png",
              imageHeight: "revert-layer",
              imageWidth: "1440px",
            },
          }),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "scroll-box" }, [
              _c(
                "div",
                { staticClass: "table-area" },
                [
                  _c("KeyPerformanceIconTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "Tasks",
                      iconSrc1:
                        "images/aboutus/overview/performance/key-performance-icon1.png",
                      mainTxt1: "Enhancement of B2C business competitiveness",
                      iconSrc2:
                        "images/aboutus/overview/performance/key-performance-icon2.png",
                      mainTxt2: "Increase in high-value products",
                      iconSrc3:
                        "images/aboutus/overview/performance/key-performance-icon3.png",
                      mainTxt3: "Enhancement of R&D competitiveness",
                    },
                  }),
                  _c("KeyPerformanceTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "Key performances",
                      textList1: _vm.textList1,
                      textList2: _vm.textList2,
                      textList3: _vm.textList3,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "2023年の主要業績", color: "black", size: "56px" } },
        [
          _c("OverviewTopImage", {
            staticClass: "key-performance-image",
            attrs: {
              imageSrc:
                "images/aboutus/overview/performance/key-performance-2022-image.png",
              imageHeight: "revert-layer",
              imageWidth: "1440px",
            },
          }),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "scroll-box" }, [
              _c(
                "div",
                { staticClass: "table-area" },
                [
                  _c("KeyPerformanceIconTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "タスク",
                      iconSrc1:
                        "images/aboutus/overview/performance/key-performance-icon1.png",
                      mainTxt1: "B2Cビジネスの競争力強化",
                      iconSrc2:
                        "images/aboutus/overview/performance/key-performance-icon2.png",
                      mainTxt2: "高付加価値製品の増加",
                      iconSrc3:
                        "images/aboutus/overview/performance/key-performance-icon3.png",
                      mainTxt3: "研究開発競争力の強化",
                    },
                  }),
                  _c("KeyPerformanceTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "主なパフォーマンス",
                      textList1: _vm.textList1_jp,
                      textList2: _vm.textList2_jp,
                      textList3: _vm.textList3_jp,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Ключевые показатели 2023",
            color: "black",
            size: "56px",
          },
        },
        [
          _c("OverviewTopImage", {
            staticClass: "key-performance-image",
            attrs: {
              imageSrc:
                "images/aboutus/overview/performance/key-performance-2022-image.png",
              imageHeight: "revert-layer",
              imageWidth: "1440px",
            },
          }),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "scroll-box" }, [
              _c(
                "div",
                { staticClass: "table-area" },
                [
                  _c("KeyPerformanceIconTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "Задачи",
                      iconSrc1:
                        "images/aboutus/overview/performance/key-performance-icon1.png",
                      mainTxt1: "Повышение конкурентоспособности бизнеса B2C",
                      iconSrc2:
                        "images/aboutus/overview/performance/key-performance-icon2.png",
                      mainTxt2:
                        "Увеличение объема продукции с высокой добавленной стоимостью",
                      iconSrc3:
                        "images/aboutus/overview/performance/key-performance-icon3.png",
                      mainTxt3: "Повышение конкурентоспособности R&D ",
                    },
                  }),
                  _c("KeyPerformanceTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "Основные показатели",
                      textList1: _vm.textList1_ru,
                      textList2: _vm.textList2_ru,
                      textList3: _vm.textList3_ru,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "2023 主要成果", color: "black", size: "56px" } },
        [
          _c("OverviewTopImage", {
            staticClass: "key-performance-image",
            attrs: {
              imageSrc:
                "images/aboutus/overview/performance/key-performance-2022-image.png",
              imageHeight: "revert-layer",
              imageWidth: "1440px",
            },
          }),
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "scroll-box" }, [
              _c(
                "div",
                { staticClass: "table-area" },
                [
                  _c("KeyPerformanceIconTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "课题",
                      iconSrc1:
                        "images/aboutus/overview/performance/key-performance-icon1.png",
                      mainTxt1: "强化B2C业务竞争力",
                      iconSrc2:
                        "images/aboutus/overview/performance/key-performance-icon2.png",
                      mainTxt2: "扩大高附加值 产品规模",
                      iconSrc3:
                        "images/aboutus/overview/performance/key-performance-icon3.png",
                      mainTxt3: "加强研发领域的竞争优势",
                    },
                  }),
                  _c("KeyPerformanceTextTable", {
                    staticClass: "key-performance-table",
                    attrs: {
                      title: "主要成果",
                      textList1: _vm.textList1_cn,
                      textList2: _vm.textList2_cn,
                      textList3: _vm.textList3_cn,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }