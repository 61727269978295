<template>
  <div class="main-box ftrd-prod-box height100">
    <h2 class="tit-main">{{ $t("main.text.featured") }}</h2>
    <div class="tab-ui tab-ui-nav">
      <ul class="justify-center">
        <li class="btn-tab on" data-tab="fpd-all" @click="ListChange('ALL')"><button type="button">{{ $t("main.text.all") }}</button></li>
        <li class="btn-tab" :data-tab="'fpd-'+cate.name" v-for="cate in categoryList" @click="ListChange(cate.name)">
        <!--  @click="initSlick('fpd-all')" @click="initSlick(`fpd-${cate.name}`)" -->
          <button type="button">{{ cate.name }}</button>
        </li>
      </ul>

      <div class="tab-con fpd-all slider-margin-0 on">
        <div class="overscr-list-wrap">
          <div class="overscr-list">
            <!-- EU일때 클래스 추가 euSlide -->
            <ul class="drag-products" v-bind:class="{euSlide: countryCode !== '_GL' && countryCode !== '_US'}">
            <!-- <ul class="drag-products"> -->
              <li class="aos-init aos-animate" v-for="(product) in productsList" data-aos="fade-up" data-aos-duration="600" data-aos-delay="750">
                <img :src="$imagePath + product.attribute.mainImage" alt="product info"/>
                <div class="txt-box on" @click="goFeat(product, seo)" :data-ec-product ="makeDataLayerForm(product)">
                  <span class="info-st" v-if="product.parents[0].name === 'HFLOR'">{{ $t("main.text.hflor_text") }}</span>
                  <span class="info-st" v-else-if="product.parents[0].name.toUpperCase() === 'VIATERA'">{{ $t("main.text.viatera_text") }}</span>
                  <span class="info-st" v-else-if="product.parents[0].name === 'HIMACS'">{{ $t("main.text.himacs_text") }}</span>

                  <div class="center-box">
                    <span class="info-ti">{{ product.orgContent.title }}</span>
                    <span class="info-ki" v-if="countryCode === '_GL' || countryCode === '_US'">{{ product.attribute.org_contentContent.parents[0].name }}</span>
                    <span class="info-ki" v-else>{{ product.attribute.orgContentParents[0].name }}</span>
                    <!--<a data-v-1bd5625a="" href="javascript:void(0)" class="btn-golmore"></a>-->
                  </div>
                  <a v-if="product.parents[0].name === 'HFLOR' && seo" :href="'products/hflor/'+ makeSeoMainClt(product.attribute.orgContentParents[1].code) +'/'+ $utils.getSeoName(product ,'seg') +'/'+product.orgContent.id" class="btn-golmore"/>
                  <a v-else-if="product.parents[0].name === 'HFLOR' && !seo" :href="'products/hflor/'+ product.attribute.orgContentParents[1].code +'/'+ product.orgContent.title +'/'+product.orgContent.id" class="btn-golmore"/>
                  <a v-else-if="product.parents[0].name.toUpperCase() === 'VIATERA'" :href="'products/viatera-quartz-surface/'+ $utils.getSeoName(product, 'seg') + '/' + product.orgContent.id" class="btn-golmore"/>
                  <a v-else-if="product.parents[0].name === 'HIMACS' && seo" :href="'products/himacs-solid-surface/'+ $utils.getSeoName(product, 'seg') +'/'+ product.orgContent.id" class="btn-golmore"/>
                  <a v-else-if="product.parents[0].name === 'HIMACS' && !seo" :href="'products/himacs/'+ product.attribute.orgContentParents[0].name +'/'+ product.orgContent.id" class="btn-golmore"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainService from "@/server/main.service";
import _ from "@/common/helper/lodash";
import {getEcomPrdData} from "@/views/products/js/product-detail";
export default {
  name: 'productsComponent',
  data () {
    return {
      pdCategoryId : [],
      categoryList : [],
      hflorList: [],
      viateraList : [],
      himacsList: [],
      productsList : [],
      allPrdList: [],
      countryCode : '',
      seo : false,
    }
  },
  async created(){
    const _this = this;
    const added = this.$countryCode
    _this.countryCode = added;

    if(_this.countryCode === '_US' || _this.countryCode === '_GL'){
      _this.seo = true;
    }

    let cateCode = 'PRODUCTS' + added
    const allId = await mainService.getCategoryCode(cateCode);
    const categoryList = await mainService.getCategoryList(allId.data.object.id);
    _this.categoryList = categoryList.data.page.list;
    const productsList = await mainService.getContentListSort(allId.data.object.id, 1, 15, 'sort', 'asc');
    _this.allPrdList = productsList.data.list;
    _this.productsList = _this.allPrdList;
    for(var i = 0; i < _this.productsList.length; i++){
      if(_this.productsList[i].parents[0].name === 'HFLOR'){
        _this.hflorList.push(_this.productsList[i]);
      } else if(_this.productsList[i].parents[0].name === 'HIMACS') {
        _this.himacsList.push(_this.productsList[i]);
      } else if(_this.productsList[i].parents[0].name.toUpperCase() === 'VIATERA') {
        _this.viateraList.push(_this.productsList[i]);
      }
    }
     _this.$nextTick(function(){
        $(`.drag-products`).slick({
          arrows: true,
          dots: false,
          infinite: true,
          variableWidth:true,
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide: true,
          prevArrow : "<button type='button' class='slick-prev slick-arrow'></button>",		// 이전 화살표 모양 설정
          nextArrow : "<button type='button' class='slick-next slick-arrow'></button>",		// 다음 화살표 모양 설정
          responsive: [
            {
              breakpoint: 768,
              settings: {
                centerMode: true
              }
            }
          ]
        });
     });
  },

  methods: {
    goFeat(product, seo){
      $(function() {
        var isDragging = false;
        $(".txt-box")
          .mousedown(function () {
            isDragging = false;
          })
          .mousemove(function () {
            isDragging = true;
          })
          .mouseup(function () {
            var wasDragging = isDragging;
            isDragging = false;
            if (!wasDragging && seo) {
              if (product.parents[0].name === 'HFLOR') {
                location.href = 'products/hflor/' + makeSeoMainClt(product.attribute.orgContentParents[1].code) + '/' + makeSeoName(product.orgContent.title) + '/' + product.orgContent.id;
              } else if (product.parents[0].name.toUpperCase() === 'VIATERA') {
                location.href = 'products/viatera-quartz-surface/' + makeSeoName(product.orgContent.title) + '/' + product.orgContent.id;
              } else if (product.parents[0].name === 'HIMACS') {
                location.href = 'products/himacs-solid-surface/' + makeSeoName(product.orgContent.title) + '/' + product.orgContent.id;
              }
            } else if (!wasDragging && !seo) {
              if (product.parents[0].name === 'HFLOR') {
                location.href = 'products/hflor/' + product.attribute.orgContentParents[1].code + '/' + product.orgContent.title + '/' + product.orgContent.id;
              } else if (product.parents[0].name.toUpperCase() === 'VIATERA') {
                location.href = 'products/viatera/' + product.orgContent.title + '/' + product.orgContent.id;
              } else if (product.parents[0].name === 'HIMACS') {
                location.href = 'products/himacs/' + product.attribute.orgContentParents[0].name + '/' + product.orgContent.id;
              }
            }
            function makeSeoMainClt(data) {
              let main_collectionNm = '';
              if (data.includes('FLOOR_LUX') ) {
                main_collectionNm = 'lvt-flooring';
              } else if (data.includes('FLOOR_HOM')) {
                main_collectionNm = 'homogeneous-sheet';
              } else if (data.includes('FLOOR_HET')) {
                main_collectionNm = 'heterogeneous-sheet';
              } else {
                main_collectionNm = 'sports-flooring';
              }
              return main_collectionNm;
            };
            function makeSeoName (str) {
              if(str !== undefined){
                const filtered1 = str.replace(/\s/gi, '-')
                const filtered2 = filtered1.toLowerCase()
                return filtered2.replace(/[\\''+?*&,]+/gi, '')
              }
            }
          });
      });
    },
    ListChange(cate){
      let _this = this;
      _this.productsList = [];
      $('.drag-products').slick("unslick");
      if(cate == 'ALL'){
        _this.productsList = _this.allPrdList;
      } else if (cate === 'HFLOR') {
        _this.productsList = _this.hflorList;
      } else if (cate === 'HIMACS') {
        _this.productsList = _this.himacsList;
      } else if (cate.toUpperCase() === "VIATERA") {
        _this.productsList = _this.viateraList;
      }
      _this.$forceUpdate()
      _this.$nextTick(function(){
        $(`.drag-products`).slick({
          arrows: false,
          dots: false,
          infinite: true,
          variableWidth:true,
          slidesToShow:4,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                centerMode: true
              }
            }
          ]
        });
      });
    },
    activeDrag(type){
      switch (type){
        case type:
        $(`.${type}.on .drag-products`).not('.slick-initialized').slick({
          arrows: false,
          dots: false,
          infinite: false,
          slidesToShow:4,
          swipeToSlide: true,
        });
        default :
         $(`.${type}.on .drag-products`).unslick()
      }
    },
    makeSeoMainClt(data) {
      let main_collectionNm = '';

      if(data === 'FLOOR_LUX'){
        main_collectionNm = 'lvt-flooring';
      } else if(data === 'FLOOR_HOM'){
        main_collectionNm = 'homogeneous-sheet';
      } else if(data === 'FLOOR_HET'){
        main_collectionNm = 'heterogeneous-sheet';
      } else {
        main_collectionNm = 'sports-flooring';
      }

      return main_collectionNm;
    },
    makeDataLayerForm(data) {
      const {ecom_prd_brand, ecom_prd_category} = getEcomPrdData(data);
      return JSON.stringify({
        ecom_prd_id: data.attribute.code == '' ? data.content.id : data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand,
        ecom_prd_category, //HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: "", //price over 1 items
        ecom_prd_type: "", // HFLOR only
        ecom_prd_collection: data.attribute.orgContentParents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: "", //price over 1 items
        ecom_currency_code: 'USD'
      })
    }
  }
}
</script>
<style scoped>
  .ftrd-prod-box .txt-box > .info-tx {
    display: -webkit-box;
    overflow:hidden;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:3;
  }
</style>
