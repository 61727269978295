<template>
  <div class="business-area-carousel">
    <Carousel
      :useDot="false"
      :useArrows="false"
    >
      <BusinessAreaCarouselItem
        v-for="(it, idx) in items"
        :key="idx"
        :imageSrc="it.imageSrc"
        :title="it.title"
        :color="it.color"
      />

      <template #custom="pageData">
        <BusinessAreaCarouselArrow :data="pageData"/>
      </template>

    </Carousel>
    <div class="desc-area">
      <pre v-if="from === 'BuildingDecorativeMaterials'" class="desc" >{{ desc }}</pre>
      <pre v-if="from === 'IndustrialFilm'" class="desc" >{{ desc }}</pre>
      <pre v-if="from === 'Automotive'" class="desc" >{{ desc }}</pre>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/product/carousel'
import BusinessAreaCarouselItem from './BusinessAreaCarouselItem.vue'
import BusinessAreaCarouselArrow from './BusinessAreaCarouselArrow.vue'

export default {
  components: {
    Carousel,
    BusinessAreaCarouselItem,
    BusinessAreaCarouselArrow
  },

  props: {
    items: {
      type: Array
    },
    desc: {
      type: String
    },
    from: {
      type: String
    }
  }
}
</script>

<style scoped>
.business-area-carousel {
  width: 1440px;
  background: #F5F5F5;
}

.business-area-carousel .desc-area {
  background: #F5F5F5;
  padding-bottom: 30px;
}

.business-area-carousel .desc-area .desc {
  margin-left: 40px;
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

@media (max-width: 768px) {
  .business-area-carousel {
    width: 90%;
  }

  .business-area-carousel .desc-area .desc {
    white-space: pre-wrap;
    font-size: 0.9rem;
    margin-left: 3%;
    margin-right: 3%;
  }
}
</style>
