export default {
  US: {
    header: {
      choose_lang: 'Choose another country or region to see content specific to your location.',
      go: 'Go'
    },
    footer: {
      career: "Career",
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Connect with us',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      viatera3: 'VIATERA(Quartz Surface)',
      himacs: 'HIMACS',
      himacs2: 'HIMACS(Solid Surface)',
      hflor: 'HFLOR',
      hflor2: 'HFLOR(Resilient Flooring)',
      teracanto: 'TERACANTO (Porcelain Surface)',
      benif: 'BENIF(Interior Film)',
      vizuon: 'VIZUON(Sign & Graphic)',
      all_brands: 'All Brands',
      interior_film: 'Interior Film',
      exterior_foil: 'Exterior Foil',
      window: 'Window',
      sign_graphic: 'Sign & Graphic',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Vinyl Coated Material',
      wallcovering: 'Wallcovering',
      deco_foil: 'Deco Foil',
      automotive: 'Automotive',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Company',
      about_us: 'About Us',
      careers: 'Careers',
      sustainability: 'Sustainability',
      help: 'Help',
      contact_us: 'Contact Us',
      faq: 'FAQ',
      where_to_buy: 'Where to Buy',
      find_a_sales_representative: 'Find a Sales Representative',
      site_map: 'Site Map',
      sign_up_newsletter: 'Sign up for our Newsletter',
      enter_your_email: 'Enter your email here',
      join: 'JOIN',
      customer_support: 'Customer Support',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: 'COPYRIGHT ⓒ 2022 LX Hausys. ALL RIGHTS RESERVED.',
      privacy: 'PRIVACY',
      terms: 'TERMS',
      newsletter: 'Newsletter',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Find a Sales Rep.',
      order_form: 'order form',
      email: 'Email',
      email_required: 'A valid email address is required',
      country: 'Country',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Product(s) of interest',
      plz_sel_prd: 'Please select the products you are interested in',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Privacy Policy',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Select Country',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Distributor',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Distributor',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'Policy',
      cust_support: 'Customer Support',
      imprint: 'Imprint',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Cookie Policy',
      right_of_the_data_subject: 'Right of the Data Subject',
      newsletter_001: 'Subscribe to our newsletter and find out more about our latest design and architecture projects and trends, events and new products.',
      newsletter_002: 'Yes, I agree with the terms of use as described in the <a href="etc.html#/privacyPolicy">Privacy Policy.</a>',
      newsletter_003: 'For more details related to processing your personal data, please refer to the Company’s Privacy Policy. If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469. <span class="color-red">* Mandatory fields</span>',
      newsletter_004: 'If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469.',
      newsletter_005: 'Mandatory fields',
      newsletter_006: 'Please check your email to confirm your subscription',
      newsletter_007: 'Thank you!',
      newsletter_007_1: 'Now we just have to check your email address, and to do that we sent a message to your email with a confirmation link. To complete the registration, open the message that we sent you and click on the confirmation link.',
      newsletter_008: 'Go back to the homepage',
      newsletter_009: 'Never Miss Out Again!',
      newsletter_010: 'Sign up for our newsletter to stay up to date with',
      newsletter_011: 'the latest HFLOR/HIMACS design trends and news.',
      newsletter_012: 'Click to Register',
      email_alert: 'Please include "@" in your email address.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: 'Strictly Necessary Cookies',
      analytical_cookies: 'Analytical Cookies',
      marketing_cookies: 'Marketing Cookies',
      strictly_sub: 'These cookies are essential and necessary for the operation of our site, including cookies that allow the users to access secure areas of our website. Blocking these cookies may impact your experience on the site.',
      analytical_sub: 'These cookies let us count the number of visitors and recognize how they move around our site. The information collected is used for the site usability and performance improvement only. These cookies do not collect information that identifies the individuals, keeping the user information anonymous.',
      marketing_sub: 'These cookies are used to track visitors across websites and analyze the contents they view, the links they follow, the browsers and devices they use, and IP addresses. The collected data will be used to display ads that are relevant and engaging for the individual users and thereby more valuable for publishers and third party advertisers.',
      privacy_policy: 'Privacy Policy',
      allow_selection: 'Allow Selection',
      allow_all: 'Allow All Cookies'
    },
    search: {
      enter_search_word: 'Enter product name, code, collection, keyword',
      recent_search: 'Recent Searches',
      popular_search: 'Popular Searches',
      no_search_result: "We weren't able to find a match for",
      no_search_guide: 'To improve your results, check your spelling or use a more general keywords',
      search_view_all: 'View All Results'
    },
    gnb: {
      segments: 'SEGMENTS',
      products: 'PRODUCTS',
      inspiration: 'INSPIRATION',
      prod: 'PROS',
      aboutUs: 'ABOUT US',
      contact: 'CONTACT'
    }

  },

  GL: {
    header: {
      choose_lang: 'Choose another country or region to see content specific to your location.',
      go: 'Go'
    },
    footer: {
      career: "Career",
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Connect with us',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      viatera3: 'VIATERA(Quartz Surface)',
      himacs: 'HIMACS',
      himacs2: 'HIMACS(Solid Surface)',
      hflor: 'HFLOR',
      hflor2: 'HFLOR(Resilient Flooring)',
      teracanto: 'TERACANTO (Porcelain Surface)',
      benif: 'BENIF(Interior Film)',
      vizuon: 'VIZUON(Sign & Graphic)',
      all_brands: 'All Brands',
      interior_film: 'Interior Film',
      exterior_foil: 'Exterior Foil',
      window: 'Window',
      sign_graphic: 'Sign & Graphic',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Vinyl Coated Material',
      wallcovering: 'Wallcovering',
      deco_foil: 'Deco Foil',
      automotive: 'Automotive',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Company',
      about_us: 'About Us',
      careers: 'Careers',
      sustainability: 'Sustainability',
      help: 'Help',
      contact_us: 'Contact Us',
      faq: 'FAQ',
      where_to_buy: 'Where to Buy',
      find_a_sales_representative: 'Find a Sales Representative',
      site_map: 'Site Map',
      sign_up_newsletter: 'Sign up for our Newsletter',
      enter_your_email: 'Enter your email here',
      join: 'JOIN',
      customer_support: 'Customer Support',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: 'COPYRIGHT ⓒ 2022 LX Hausys. ALL RIGHTS RESERVED.',
      privacy: 'PRIVACY',
      terms: 'TERMS',
      newsletter: 'Newsletter',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Find a Sales Rep.',
      order_form: 'order form',
      email: 'Email',
      email_required: 'A valid email address is required',
      country: 'Country',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Product(s) of interest',
      plz_sel_prd: 'Please select the products you are interested in',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Privacy Policy',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Select Country',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Distributor',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Distributor',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'Policy',
      cust_support: 'Customer Support',
      imprint: 'Imprint',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Cookie Policy',
      right_of_the_data_subject: 'Right of the Data Subject',
      newsletter_001: 'Subscribe to our newsletter and find out more about our latest design and architecture projects and trends, events and new products.',
      newsletter_002: 'Yes, I agree with the terms of use as described in the <a href="etc.html#/privacyPolicy">Privacy Policy.</a>',
      newsletter_003: 'For more details related to processing your personal data, please refer to the Company’s Privacy Policy. If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469. <span class="color-red">* Mandatory fields</span>',
      newsletter_004: 'If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469.',
      newsletter_005: 'Mandatory fields',
      newsletter_006: 'Please check your email to confirm your subscription',
      newsletter_007: 'Thank you!',
      newsletter_007_1: 'Now we just have to check your email address, and to do that we sent a message to your email with a confirmation link. To complete the registration, open the message that we sent you and click on the confirmation link.',
      newsletter_008: 'Go back to the homepage',
      newsletter_009: 'Never Miss Out Again!',
      newsletter_010: 'Sign up for our newsletter to stay up to date with',
      newsletter_011: 'the latest HFLOR/HIMACS design trends and news.',
      newsletter_012: 'Click to Register',
      email_alert: 'Please include "@" in your email address.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: 'Strictly Necessary Cookies',
      analytical_cookies: 'Analytical Cookies',
      marketing_cookies: 'Marketing Cookies',
      strictly_sub: 'These cookies are essential and necessary for the operation of our site, including cookies that allow the users to access secure areas of our website. Blocking these cookies may impact your experience on the site.',
      analytical_sub: 'These cookies let us count the number of visitors and recognize how they move around our site. The information collected is used for the site usability and performance improvement only. These cookies do not collect information that identifies the individuals, keeping the user information anonymous.',
      marketing_sub: 'These cookies are used to track visitors across websites and analyze the contents they view, the links they follow, the browsers and devices they use, and IP addresses. The collected data will be used to display ads that are relevant and engaging for the individual users and thereby more valuable for publishers and third party advertisers.',
      privacy_policy: 'Privacy Policy',
      allow_selection: 'Allow Selection',
      allow_all: 'Allow All Cookies'
    },
    search: {
      enter_search_word: 'Enter product name, code, collection, keyword',
      recent_search: 'Recent Searches',
      popular_search: 'Popular Searches',
      no_search_result: "We weren't able to find a match for",
      no_search_guide: 'To improve your results, check your spelling or use a more general keywords',
      search_view_all: 'View All Results'
    },
    gnb: {
      segments: 'SEGMENTS',
      products: 'PRODUCTS',
      inspiration: 'INSPIRATION',
      prod: 'PROS',
      aboutUs: 'ABOUT US',
      contact: 'CONTACT'
    }
  },

  JP: {
    header: {
      choose_lang: 'Choose another country or region to see content specific to your location.',
      go: 'Go'
    },
    footer: {
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Connect with us',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      viatera3: 'VIATERA(Quartz Surface)',
      himacs: 'HIMACS',
      himacs2: 'HIMACS(Solid Surface)',
      hflor: 'HFLOR',
      hflor2: 'HFLOR(Resilient Flooring)',
      teracanto: 'TERACANTO(Porcelain Surface)',
      benif: 'BENIF(Interior Film)',
      vizuon: 'VIZUON(Sign & Graphic)',
      all_brands: 'All Brands',
      interior_film: 'Interior Film',
      exterior_foil: 'Exterior Foil',
      window: 'Window',
      sign_graphic: 'Sign & Graphic',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Vinyl Coated Material',
      wallcovering: 'Wallcovering',
      deco_foil: 'Deco Foil',
      automotive: 'Automotive',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Company',
      about_us: 'About Us',
      careers: 'Careers',
      sustainability: 'Sustainability',
      help: 'Help',
      contact_us: 'Contact Us',
      faq: 'FAQ',
      where_to_buy: 'Where to Buy',
      find_a_sales_representative: 'Find a Sales Representative',
      site_map: 'Site Map',
      sign_up_newsletter: 'Sign up for our Newsletter',
      enter_your_email: 'Enter your email here',
      join: 'JOIN',
      customer_support: 'Customer Support',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: 'COPYRIGHT ⓒ 2022 LX Hausys. ALL RIGHTS RESERVED.',
      privacy: 'PRIVACY',
      terms: 'TERMS',
      newsletter: 'Newsletter',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Find a Sales Rep.',
      order_form: 'order form',
      email: 'Email',
      email_required: 'A valid email address is required',
      country: 'Country',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Product(s) of interest',
      plz_sel_prd: 'Please select the products you are interested in',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Privacy Policy',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Select Country',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Distributor',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Distributor',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'Policy',
      cust_support: 'Customer Support',
      imprint: 'Imprint',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Cookie Policy',
      right_of_the_data_subject: 'Right of the Data Subject',
      newsletter_001: 'Subscribe to our newsletter and find out more about our latest design and architecture projects and trends, events and new products.',
      newsletter_002: 'Yes, I agree with the terms of use as described in the <a href="etc.html#/privacyPolicy">Privacy Policy.</a>',
      newsletter_003: 'For more details related to processing your personal data, please refer to the Company’s Privacy Policy. If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469. <span class="color-red">* Mandatory fields</span>',
      newsletter_004: 'If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469.',
      newsletter_005: 'Mandatory fields',
      newsletter_006: 'Please check your email to confirm your subscription',
      newsletter_007: 'Thank you!',
      newsletter_007_1: 'Now we just have to check your email address, and to do that we sent a message to your email with a confirmation link. To complete the registration, open the message that we sent you and click on the confirmation link.',
      newsletter_008: 'Go back to the homepage',
      newsletter_009: 'Never Miss Out Again!',
      newsletter_010: 'Sign up for our newsletter to stay up to date with',
      newsletter_011: 'the latest HFLOR/HIMACS design trends and news.',
      newsletter_012: 'Click to Register',
      email_alert: 'Please include "@" in your email address.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: 'Strictly Necessary Cookies',
      analytical_cookies: 'Analytical Cookies',
      marketing_cookies: 'Marketing Cookies',
      strictly_sub: 'These cookies are essential and necessary for the operation of our site, including cookies that allow the users to access secure areas of our website. Blocking these cookies may impact your experience on the site.',
      analytical_sub: 'These cookies let us count the number of visitors and recognize how they move around our site. The information collected is used for the site usability and performance improvement only. These cookies do not collect information that identifies the individuals, keeping the user information anonymous.',
      marketing_sub: 'These cookies are used to track visitors across websites and analyze the contents they view, the links they follow, the browsers and devices they use, and IP addresses. The collected data will be used to display ads that are relevant and engaging for the individual users and thereby more valuable for publishers and third party advertisers.',
      privacy_policy: 'Privacy Policy',
      allow_selection: 'Allow Selection',
      allow_all: 'Allow All Cookies'
    },
    search: {
      enter_search_word: 'Enter product name, code, collection, keyword',
      recent_search: 'Recent Searches',
      popular_search: 'Popular Searches',
      no_search_result: "We weren't able to find a match for",
      no_search_guide: 'To improve your results, check your spelling or use a more general keywords',
      search_view_all: 'View All Results'
    },
    gnb: {
      segments: 'SEGMENTS',
      products: 'PRODUCTS',
      inspiration: 'INSPIRATION',
      prod: 'PROFESSIONALS',
      aboutUs: 'ABOUT US',
      contact: 'CONTACT US'
    }
  },

  CA_FR: {
    header: {
      choose_lang: 'Choisissez un autre région pour voir le contenu spécifique à votre pays',
      go: 'Confirmer'
    },
    footer: {
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Restez connecté',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      himacs: 'HIMACS',
      hflor: 'HFLOR',
      teracanto: 'TERACANTO',
      benif: 'BENIF',
      vizuon: 'VIZUON',
      all_brands: 'All Brands',
      interior_film: 'Interior Film',
      exterior_foil: 'Exterior Foil',
      window: 'Window',
      sign_graphic: 'Sign & Graphics',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Vinyl Coated Material',
      wallcovering: 'Revêtement mural',
      deco_foil: 'Deco Foil',
      automotive: 'Autoteile',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Société',
      about_us: 'A propos',
      careers: 'Careers',
      sustainability: 'Écologie',
      help: 'Aide',
      contact_us: 'Contactez-nous',
      faq: 'FAQ',
      where_to_buy: 'Où acheter',
      find_a_sales_representative: 'Trouver un représentant',
      site_map: 'Carte du site',
      sign_up_newsletter: 'Inscrivez-vous à notre newsletter',
      enter_your_email: 'Geben Sie hier Ihre E-Mail ein',
      join: 'Machen Sie mit',
      customer_support: 'Kundendienst',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: 'COPYRIGHT ⓒ 2022 LX Hausys. ALL RIGHTS RESERVED.',
      privacy: 'Politique',
      terms: 'confidentialité',
      newsletter: 'Newsletter',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Trouver un représentant',
      order_form: 'order form',
      email: 'Email',
      email_required: 'Eine gültige E-Mail Adresse ist erforderlich',
      country: 'Pays',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Produkt(e) von Interesse (Bitte wählen Sie aus, was Sie interessiert)',
      plz_sel_prd: 'Bitte wählen Sie die Produkte aus, an denen Sie interessiert sind',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Politique de confidentialité',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Sélectionner le pays',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Vertriebshändler',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Vertriebshändler',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'policy',
      cust_support: 'Assistance client',
      imprint: 'Mentions légales',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Politique en matière de cookies',
      right_of_the_data_subject: 'Droit de la personne concernée',
      newsletter_001: 'Abonnez-vous à la newsletter HIMACS pour découvrir nos derniers projets en matière de design et d\'architecture, les dates des événements à venir, et nos nouveaux produits.',
      newsletter_002: 'Oui, j\'accepte les conditions d\'utilisation décrites dans la <a href="etc.html#/privacyPolicy">politique de confidentialité</a>',
      newsletter_003: 'Pour plus de détails concernant le traitement de vos données personnelles, veuillez-vous référer à la politique de confidentialité de la société. Si vous avez des questions ou des inquiétudes concernant l’envoi d\'informations personnelles en ligne, veuillez nous contacter au (49)-69-583-029 469. <span class="color-red">* Champs obligatoires</span>',
      newsletter_004: 'Si vous avez des questions ou des inquiétudes concernant l’envoi d\'informations personnelles en ligne, veuillez nous contacter au (49)-69-583-029 469.',
      newsletter_005: 'Champs obligatoires',
      newsletter_006: 'Veuillez vérifier votre courrier électronique pour confirmer votre inscription',
      newsletter_007: 'Merci!',
      newsletter_007_1: 'Il ne nous reste plus qu\'à vérifier votre adresse électronique, et pour ce faire, nous avons envoyé un message à l\'adresse que vous avez indiquée avec un lien de confirmation. Pour terminer l\'inscription, ouvrez le message que nous vous avons envoyé et cliquez sur le lien de confirmation.',
      newsletter_008: 'Retourner à la page d\'accueil',
      newsletter_009: 'Ne manquez plus jamais rien!',
      newsletter_010: 'Inscrivez-vous à notre bulletin d\'information pour rester au courant',
      newsletter_011: 'des dernières tendances et nouvelles du design HFLOR/HIMACS.',
      newsletter_012: 'Cliquez pour vous inscrire',
      email_alert: 'Veuillez inclure ≪@≫ dans votre adresse electronique.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: 'Cookies strictement nécessaires',
      analytical_cookies: 'Cookies analytiques',
      marketing_cookies: 'Cookies marketing',
      strictly_sub: 'Ces cookies sont essentiels et nécessaires au fonctionnement de notre Site web, y compris les cookies qui vous permettent d\'accéder à des zones sécurisées du Site web. Sans ces cookies, les services que vous avez demandés ne peuvent pas être fournis.',
      analytical_sub: 'Ils nous permettent de reconnaître et de compter le nombre de visiteurs et de voir comment les visiteurs se déplacent sur notre Site web lorsqu\'ils l\'utilisent. Cela nous aide à améliorer le fonctionnement de notre Site web, par exemple en veillant à ce que les utilisateurs trouvent facilement ce qu\'ils recherchent. Ces cookies ne collectent pas d\'informations permettant de vous identifier. Toutes les informations que ces cookies collectent sont anonymes et ne sont utilisées que pour améliorer le fonctionnement du site web.',
      marketing_sub: 'Ces cookies sont utilisés pour suivre les visiteurs sur les sites web et collecter des informations concernant votre visite sur notre site, notamment le contenu que vous avez consulté, les liens que vous avez suivis ainsi que des informations sur votre navigateur, votre appareil et votre adresse IP. L\'objectif est d\'afficher des publicités pertinentes et attrayantes pour chaque utilisateur individuel et donc plus intéressantes pour les éditeurs et les annonceurs tiers.',
      privacy_policy: 'Politique de confidentialité',
      allow_selection: 'Autoriser choix',
      allow_all: 'Autoriser Tous Cookies'
    },
    search: {
      enter_search_word: 'Entrez le nom du produit, le code, la collection, le mot clé',
      recent_search: 'Recherches récentes',
      popular_search: 'Recherches populaires',
      no_search_result: "Nous n'avons pas trouvé de correspondance pour",
      no_search_guide: 'Pour améliorer vos résultats, vérifiez votre orthographe ou utilisez un mot-clé plus général',
      search_view_all: 'Afficher tous les résultats'
    },
    gnb: {
      segments: 'SEGMENTS',
      products: 'PRODUITS',
      inspiration: 'INSPIRATION',
      prod: 'PROFESSIONNELS',
      aboutUs: 'A PROPOS',
      contact: 'CONTACTEZ-NOUS'
    }
  },

  CA_EN: {
    header: {
      choose_lang: 'Wählen Sie ein anderes Land oder eine andere Region, um Inhalte zu sehen, die für Ihren Standort gelten.',
      go: 'Los'
    },
    footer: {
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Verbinden Sie sich mit uns',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      himacs: 'HIMACS',
      hflor: 'HFLOR',
      teracanto: 'TERACANTO',
      benif: 'BENIF',
      vizuon: 'VIZUON',
      all_brands: 'All Brands',
      interior_film: 'Innenfolie',
      exterior_foil: 'Außenfolie',
      window: 'Window',
      sign_graphic: 'Schilder & Zeichnungen',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Material mit Vinylbeschichtung',
      wallcovering: 'Wandverkleidungen',
      deco_foil: 'Dekofolie',
      automotive: 'Automotive',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Unternehmen',
      about_us: 'Über uns',
      careers: 'Careers',
      sustainability: 'Nachhaltigkeit',
      help: 'Hilfe',
      contact_us: 'Kontaktieren Sie uns',
      faq: 'FAQ',
      where_to_buy: 'Bezugsquellen',
      find_a_sales_representative: 'Finden Sie einen Vertriebsmitarbeiter',
      site_map: 'SiteMap',
      sign_up_newsletter: 'Abonnieren Sie unseren Newsletter',
      enter_your_email: 'Saisissez votre adresse électronique ici',
      join: 'Sinscrire',
      customer_support: 'Assistance client',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: '© 2022 LX Hausys. TOUS DROITS RÉSERVÉS.',
      privacy: 'Datenschutz',
      terms: 'Geschäftsbedingungen',
      newsletter: 'Newsletter',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Finden Sie einen Vertriebsmitarbeiter',
      order_form: 'order form',
      email: 'Email',
      email_required: 'Une adresse électronique valide est requise',
      country: 'Lands',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Produit(s) qui vous intéresse(nt) (Veuillez sélectionner tous ceux qui vous intéressent)',
      plz_sel_prd: 'Veuillez sélectionner les produits qui vous intéressent',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Datenschutzrichtlinien',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Land auswählen',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Distributeur',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Distributeur',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'Richtlinien',
      cust_support: 'Kundendienst',
      imprint: 'Impressum',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Cookie-Richtlinien',
      right_of_the_data_subject: 'Rechte des Datensubjekts',
      newsletter_001: 'Abonnieren Sie jetzt den HIMACS-Newsletter und erfahren Sie mehr über aktuelle Design- und Architektur-Projekte, anstehende Messen und Events, neue Produkte und über all das, was sich sonst noch rund um das Solid-Surface-Material tut.',
      newsletter_002: 'Ja, ich stimme der im <a href="etc.html#/privacyPolicy">Datenschutzhinweis</a> beschriebenen Verwendung meiner Daten zu',
      newsletter_003: 'Weitere Informationen zur Verarbeitung Ihrer persönlichen Daten finden Sie in den Datenschutzhinweisen. Falls Sie Fragen oder Bedenken haben Ihre persönlichen Daten online zu übermitteln, kontaktieren Sie uns bitte per Telefon (49)-69-583-029 469. <span class="color-red">* Pflichtfelder</span>',
      newsletter_004: 'Falls Sie Fragen oder Bedenken haben Ihre persönlichen Daten online zu übermitteln, kontaktieren Sie uns bitte per Telefon (49)-69-583-029 469.',
      newsletter_005: 'Pflichtfelder',
      newsletter_006: 'Bitte kontrollieren Sie Ihre E-Mail-Postfach, um Ihr Abonnement zu bestätigen',
      newsletter_007: 'Vielen Dank!',
      newsletter_007_1: 'Jetzt müssen wir nur noch Ihre E-Mail-Adresse überprüfen. Zu diesem Zweck haben wir eine Nachricht mit einem Bestätigungslink an Ihre E-Mail-Adresse geschickt. Um die Registrierung abzuschließen, öffnen Sie die Nachricht, die wir Ihnen geschickt haben, und klicken Sie auf den Bestätigungslink.',
      newsletter_008: 'Zurück zur Homepage',
      newsletter_009: 'Nie mehr etwas verpassen!',
      newsletter_010: 'Abonnieren Sie unseren Newsletter, um über die neuesten HFLOR/HIMACS',
      newsletter_011: 'Designtrends und Neuigkeiten auf dem Laufenden zu bleiben.',
      newsletter_012: 'Zum Registrieren hier klicken',
      email_alert: 'Bitte verwenden Sie ein "@" in Ihrer E-Mail Adresse.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: ' Unbedingt notwendige Cookies',
      analytical_cookies: 'Analytische Cookies',
      marketing_cookies: 'Marketing Cookies',
      strictly_sub: 'Diese Cookies sind unerlässlich und notwendig für den Betrieb unserer Website, darunter auch Cookies, die Ihnen den Zugang zu sicheren Bereichen der Website ermöglichen. Ohne diese Cookies können die von Ihnen angeforderten Dienstleistungen nicht erbracht werden.',
      analytical_sub: 'Damit können wir die Anzahl der Besucher erkennen und zählen und sehen, wie sich der Besucher auf unserer Website bewegt, wenn er sie nutzt. So können wir die Funktionsweise unserer Website verbessern, indem wir z.B. sicherstellen, dass der Nutzer leicht findet, was er sucht. Diese Cookies sammeln keine Informationen, mit denen Sie identifiziert werden können. Alle Informationen, die diese Cookies sammeln, sind anonym und werden nur verwendet, um die Funktionsweise der Webseite zu verbessern.',
      marketing_sub: 'Diese Cookies werden verwendet, um Besucher über mehrere Webseiten hinweg zu verfolgen und Informationen über Ihren Besuch auf unserer Website zu sammeln, darunter die Inhalte, die Sie angesehen haben, die Links, denen Sie gefolgt sind, sowie Informationen über Ihren Browser, Ihr Gerät und Ihre IP-Adresse. Ziel ist es, Anzeigen zu schalten, die für den einzelnen Nutzer relevant und ansprechend sind und damit für Herausgeber und Drittanbieter wertvoller sind.',
      privacy_policy: 'Datenschutzrichtlinien',
      allow_selection: 'Zulassen Auswahl',
      allow_all: 'Zulassen Alle Cookies'
    },
    search: {
      enter_search_word: 'Eingabe von Produktname, Code, Kollektion, Stichwort',
      recent_search: 'Letzte Suchanfragen',
      popular_search: 'beliebte Suchanfragen',
      no_search_result: 'Wir konnten keine Übereinstimmung finden für',
      no_search_guide: 'Um Ihre Treffer zu erhöhen, überprüfen Sie Ihre Rechtschreibung oder verwenden Sie allgemeinere Schlüsselwörter',
      search_view_all: 'Alle Ergebnisse anzeigen'
    },
    gnb: {
      segments: 'SEGMENTEN',
      products: 'PRODUKTE',
      inspiration: 'INSPIRATION',
      prod: 'FACHLEUTE',
      aboutUs: 'ÜBER UNS',
      contact: 'KONTAKTIEREN SIE UNS'
    }
  },

  CN: {
    header: {
      choose_lang: 'Seleziona un altro paese o zona per visualizzare i contenuti specifici per la tua posizione.',
      go: 'Vai'
    },
    footer: {
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Comunica con noi',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      himacs: 'HIMACS',
      hflor: 'HFLOR',
      teracanto: 'TERACANTO',
      benif: 'BENIF',
      vizuon: 'VIZUON',
      all_brands: 'All Brands',
      interior_film: 'Interior Film',
      exterior_foil: 'Exterior Foil',
      window: 'Window',
      sign_graphic: 'Sign & Graphics',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Vinyl Coated Material',
      wallcovering: 'Rivestimento murale',
      deco_foil: 'Deco Foil',
      automotive: 'Automotive',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Società',
      about_us: 'Su di noi',
      careers: 'Careers',
      sustainability: 'Sostenibilità',
      help: 'Aiuto',
      contact_us: 'Contattaci',
      faq: 'FAQ',
      where_to_buy: 'Rete di rivenditori / distributori',
      find_a_sales_representative: 'Trova un rappresentante vendite',
      site_map: 'Mappa del sito',
      sign_up_newsletter: 'Iscriviti alla nostra newsletter',
      enter_your_email: 'Inserisci la tua e-mail qui',
      join: 'Iscriviti',
      customer_support: 'Servizio clienti',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: 'COPYRIGHT © 2022 LX Hausys. TUTTI I DIRITTI RISERVATI.',
      privacy: 'Privacy',
      terms: 'Termini',
      newsletter: 'Newsletter',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Trova un rappresentante vendite',
      order_form: 'order form',
      email: 'Email',
      email_required: 'È necessario un indirizzo e-mail valido',
      country: 'Paese',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Prodotto/i di interesse (seleziona tutto ciò che ti interessa)',
      plz_sel_prd: 'Seleziona i prodotti che ti interessano',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Informativa sulla privacy',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Seleziona paese',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Distributore',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Distributore',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'privacy',
      cust_support: 'Servizio clienti',
      imprint: 'Impronta',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Informativa sui cookie',
      right_of_the_data_subject: 'Diritti degli interessati',
      newsletter_001: 'Iscriveti alla nostra newsletter per ricevere le news con gli ultimi trend di HIMACS nel campo dell’arredo e dell’architettura.',
      newsletter_002: 'Sì, accetto i termini di utilizzo come descritto nell\'<a href="etc.html#/privacyPolicy">informativa sulla privacy</a>',
      newsletter_003: 'Per ulteriori dettagli relativi al trattamento dei dati personali, si prega di fare riferimento alla Informativa sulla privacy della società. Se avete domande o dubbi sull\'invio di informazioni personali, vi preghiamo di contattarci tramite il numero di telefono (49)-69-583-029 469. <span class="color-red">* Campi obbligatori</span>',
      newsletter_004: 'Se avete domande o dubbi sull\'invio di informazioni personali, vi preghiamo di contattarci tramite il numero di telefono (49)-69-583-029 469.',
      newsletter_005: 'Campi obbligatori',
      newsletter_006: 'Controlla la tua e-mail per confermare l’iscrizione',
      newsletter_007: 'Grazie!',
      newsletter_007_1: 'Ora non ci resta che controllare il tuo indirizzo e-mail. Abbiamo inviato un messaggio alla tua e-mail contenente un link di conferma. Per completare la registrazione, apri il messaggio che ti abbiamo inviato e fai clic sul link di conferma.',
      newsletter_008: 'Torna alla homepage',
      newsletter_009: 'Non perderti mai più niente!',
      newsletter_010: 'Iscriviti alla nostra newsletter per rimanere aggiornato/a sulle',
      newsletter_011: 'ultime tendenze e novità del design di HFLOR/HIMACS.',
      newsletter_012: 'Fai clic per registrarti',
      email_alert: 'Ricorda di includere la "@" nel tuo indirizzo e-mail.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: 'Cookie strettamente necessari',
      analytical_cookies: 'Cookie analitici',
      marketing_cookies: 'Cookie di marketing',
      strictly_sub: 'Questi cookie sono essenziali e necessari per il funzionamento del nostro sito e comprendono i cookie che consentono di accedere alle aree protette del sito. Senza questi cookie i servizi richiesti dall\'utente non possono essere forniti.',
      analytical_sub: 'Ci consentono di riconoscere e contare il numero di visitatori e di vedere come i visitatori si muovono all interno del nostro sito quando lo utilizzano. Questo ci aiuta a migliorare il modo in cui funziona il nostro sito, per esempio assicurandoci che gli utenti trovino facilmente ciò che stanno cercando. Questi cookie non raccolgono informazioni che possono identificare l’utente. Tutte le informazioni raccolte da questi cookie sono anonime e vengono utilizzate solo per migliorare il funzionamento del sito.',
      marketing_sub: 'Questi cookie vengono utilizzati per tracciare i visitatori sui siti e raccogliere informazioni sulla visita al nostro sito, inclusi i contenuti visualizzati, i link cliccati e le informazioni sul browser, sul dispositivo e l\'indirizzo IP. Lo scopo è quello di proporre annunci pubblicitari pertinenti e coinvolgenti per il singolo utente e quindi più utili per editori e inserzionisti terzi.',
      privacy_policy: 'Informativa sulla privacy',
      allow_selection: 'Consenti selezione',
      allow_all: 'Consenti Tutto Cookies'
    },
    search: {
      enter_search_word: 'Inserisci il nome del prodotto, il codice, la collezione, la parola chiave',
      recent_search: 'Ricerche recenti',
      popular_search: 'Ricerche popolari',
      no_search_result: 'Impossibile trovare una corrispondenza per',
      no_search_guide: "Per migliorare i risultati, controlla l'ortografia o utilizza parole chiave più generiche",
      search_view_all: 'Visualizza tutti i risultati'
    },
    gnb: {
      segments: 'SEGMENTI',
      products: 'PRODOTTI',
      inspiration: 'ISPIRAZIONE',
      prod: 'PROFESSIONISTI',
      aboutUs: 'SU DI NOI',
      contact: 'CONTATTACI'
    }
  },

  RU: {
    header: {
      choose_lang: 'Elegir otro país o zona para ver contenidos específicos de su ubicación.',
      go: 'Ir'
    },
    footer: {
      top: 'TOP',
      lx_hausys: 'LX Hausys',
      connect_with_us: 'Contactar con nosotros',
      viatera: 'VIATERA',
      viatera2: 'Viatera',
      himacs: 'HIMACS',
      hflor: 'HFLOR',
      teracanto: 'TERACANTO',
      benif: 'BENIF',
      vizuon: 'VIZUON',
      all_brands: 'All Brands',
      interior_film: 'Interior Film',
      exterior_foil: 'Exterior Foil',
      window: 'Window',
      sign_graphic: 'Sign & Graphics',
      func_glass: 'Functional Glass',
      vinyl_coated_material: 'Vinyl Coated Material',
      wallcovering: 'Revestimientos de pared',
      deco_foil: 'Deco  Foil',
      automotive: 'Automoción',
      instagram: 'instagram',
      pinterest: 'pinterest',
      houzz: 'houzz',
      youtube: 'youtube',
      ytb: 'ytb',
      facebook: 'facebook',
      company: 'Empresa',
      about_us: 'Sobre nosotros',
      careers: 'Careers',
      sustainability: 'Sostenibilidad',
      help: 'Soporte',
      contact_us: 'Contacto',
      faq: 'FAQs',
      where_to_buy: 'Dónde comprar',
      find_a_sales_representative: 'Contactar con un comercial',
      site_map: 'Mapa del sitio',
      sign_up_newsletter: 'Suscríbete a nuestra Newsletter',
      enter_your_email: 'Introduzca aquí su dirección de e-mai',
      join: 'Unirse',
      customer_support: 'Atenció8n al cliente',
      websupport_email: 'websupport@lxhausys.com',
      websupport_phone: '( +1 866-544-4622 )',
      copyright: 'COPYRIGHT © 2022 LX Hausys. RESERVADOS TODOS LOS DERECHOS.',
      privacy: 'privacidad',
      terms: 'Política',
      newsletter: 'boletín ',
      thank_you_for_subscribing1: 'Thank you for subscribing',
      thank_you_for_subscribing2: 'Thank you for subscribing to our E-newsletter. If you have any questions or comments about the content you’re receiving,<br />please <a href="support.html#/contactUs/pro">CONTACT US</a> we will respond to your inquiry promptly.',
      visit_lx_hausys: 'Visit LX Hausys',
      close: 'close',
      list_view: 'list view',
      find_a_sales_rep: 'Contactar con un comercial',
      order_form: 'order form',
      email: 'Correo electrónico',
      email_required: 'Es necesaria una dirección de e-mail válida',
      country: 'Pais',
      please_select_country: 'Please select Country',
      iam: 'I am',
      please_select_occupation: 'Please select Occupation',
      products: 'Producto(s) de su interés (marque todo lo que le interese)',
      plz_sel_prd: 'Seleccione los productos que le interesen',
      yes_agree_terms: 'Yes, I agree with the terms of use as described in the',
      privacy_policy: 'Política de privacidad',
      please_check_agreement: 'Please Check the agreement box which the given terms above',
      submit: 'Submit',
      select_country: 'Seleccionar país',
      select_occupation: 'Select Occupation',
      usa: 'USA',
      canada: 'CANADA',
      lx_hausys_distributor: 'LX Hausys Distribuidor',
      lx_hausys_dealer: 'LX Hausys Dealer',
      distributor: 'Distribuidor',
      dealer: 'Dealer',
      architect_designer: 'Architect &amp; Designer',
      end_user: 'End User',
      linkedin: 'linkedin',
      twitter: 'twitter',
      policy: 'Política',
      cust_support: 'Atención al cliente',
      imprint: 'Huella',
      slave_stmt: 'Modern Slavery Statement',
      cookie_policy: 'Política de cookies',
      right_of_the_data_subject: 'Derechos del interesado',
      newsletter_001: 'Suscríbete a nuestra Newsletter para saber más acerca de nuestros más recientes proyectos de arquitectura y diseño, próximos eventos y de nuestros nuevos productos.',
      newsletter_002: 'Sí, acepto los términos de uso descritos en la <a href="etc.html#/privacyPolicy">política de privacidad</a>',
      newsletter_003: 'Para obtener más detalles relacionados con el procesamiento de sus datos personales, consulta la Política de Privacidad de la empresa. Si tienes alguna pregunta o inquietud sobre el envío de información personal, contáctanos a través del siguiente número de teléfono: (49)-69-583-029 469. <span class="color-red">* Campos obligatorios</span>',
      newsletter_004: 'Si tienes alguna pregunta o inquietud sobre el envío de información personal, contáctanos a través del siguiente número de teléfono: (49)-69-583-029 469.',
      newsletter_005: 'Campos obligatorios',
      newsletter_006: 'Compruebe su e-mail para confirmar su suscripción',
      newsletter_007: '¡Gracias!',
      newsletter_007_1: 'Ahora ya sólo tenemos que verificar su dirección de e-mail, y para ello le enviaremos un mensaje a su buzón con un enlace de confirmación. Para finalizar el registro, abra el mensaje que le hemos enviado y pulse el enlace de confirmación.',
      newsletter_008: 'Volver a la página de inicio',
      newsletter_009: '¡No vuelva a perderse nada!',
      newsletter_010: 'Suscríbase a nuestro boletín para mantenerse al tanto de las últimas',
      newsletter_011: 'tendencias de diseño y de las novedades de HFLOR/HIMACS.',
      newsletter_012: 'Pulse para registrarse',
      email_alert: 'No olvide incluir el signo ≪@≫ en su direccion de e-mail.',
    },
    cookie: {
      cookie_setting: 'COOKIE SETTINGS',
      cookie_sub: 'Our website uses cookies to make your browsing experience better and more personal.',
      strictly_cookies: 'Cookies estrictamente necesarias',
      analytical_cookies: 'Cookies de análisis',
      marketing_cookies: 'Cookies de marketing',
      strictly_sub: 'Estas cookies son esenciales y necesarias para el funcionamiento de nuestro sitio web, e incluyen las que permiten al usuario acceder a las zonas seguras. Sin estas cookies, no podríamos prestar los servicios que el usuario solicite.',
      analytical_sub: 'Nos permiten reconocer y contar la cantidad de visitantes, y ver cómo se mueven por nuestro sitio web al utilizarlo. Esto nos ayuda a mejorar el funcionamiento del sitio web, por ejemplo, asegurándonos de que los usuarios encuentran fácilmente lo que buscan. Estas cookies no recopilan información que sirva para identificar al usuario. Todos los datos que recopilan estas cookies son anónimos, y solamente se utilizan para mejorar el funcionamiento del sitio web.',
      marketing_sub: 'Estas cookies se utilizan para hacer un seguimiento de los visitantes a través de los sitios web, y recopilan información relativa a la visita a nuestro sitio web, incluido el contenido que haya visto y los enlaces que haya seguido, e información sobre su navegador, dispositivo y dirección IP. La finalidad es mostrar anuncios que resulten pertinentes y atractivos para el usuario individual, y que por tanto tengan más valor para los editores y terceras partes anunciantes.',
      privacy_policy: 'Política de privacidad',
      allow_selection: 'Permitir selección',
      allow_all: 'Permitir Todo Cookies'
    },
    search: {
      enter_search_word: 'Introducir la denominación del producto, el código, la colección o la palabra clave',
      recent_search: 'Búsquedas recientes',
      popular_search: 'Búsquedas populares',
      no_search_result: 'No se ha podido encontrar ninguna coincidencia para',
      no_search_guide: 'Para mejorar los resultados, revise su ortografía o utilice unas palabras clave más generales',
      search_view_all: 'Ver todos los resultados'
    },
    gnb: {
      segments: 'SEGMENTOS',
      products: 'PRODUCTOS',
      inspiration: 'INSPIRACIÓN',
      prod: 'PROFESIONALES',
      aboutUs: 'SOBRE NOSOTROS',
      contact: 'CONTACTO'
    }
  },

}
