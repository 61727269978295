var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expanded-waste-recycling-table" },
    [
      _c("ExpandedWasteRecyclingTableHeader", {
        attrs: { headerList: _vm.headerList },
      }),
      _vm._l(_vm.tableData.bodyDataList, function (item) {
        return _c("ExpandedWasteRecyclingTableBody", {
          key: item,
          attrs: { bodyList: item },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }