<template>

<!--  <div :class="'tab-con tab0'+(index+1)" v-for="(content, index) in totGalList" v-bind:key="index">
    <div class="guide-box">
      <div class="g-list col-02">

        <div class="bd-gray">
          <strong>{{ content.attribute.document_pickContent.content.title }}</strong>
          <div class="col-02">
            <div class="txt-box">
              <div class="t-type-01">
                {{ content.attribute.document_pickContent.attribute.doctypeText }}
              </div>
              <button type="button" @click="downloadFile(content.attribute.document_pickContent.attribute.doc_file)" >Download</button>
            </div>

            <div class="img-box" v-if="content.attribute.document_pickContent.attribute.doc_thumb == null || content.attribute.document_pickContent.attribute.doc_thumb == '' ">
              <img src="images/pros/@img_03.png" alt="2021 Viatera Fabrication Guide" />
            </div>

            <div class="img-box" v-else>
              <img :src="content.attribute.document_pickContent.attribute.doc_thumb" />
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>-->


</template>

<script>
import LikeService from "@/server/like.service";
import ProductService from "@/server/product.service";
import _ from "@/common/helper/lodash";
import InspirationService from "@/server/inspiration.service";

export default {
  name: "app",
  props: ["totGalList"],
  props2: ["totGalListLen"],
  components: {},
  data() {
    return {
      nationCode: [],
      categoryId: [],
      content22: [],
      hflorList: [],
      himacsList: [],
      totGalList:[],
      pageSize: '',
    }
  },
  async created() {
    const t = this
    t.nationCode = t.$countryCode;
  },
  async mounted() {
    const t = this
    t.pageSize = 2
    t.pageIndex = this.pageIndex

    this.res = await InspirationService.getContentListSearch(this.childIds, this.searchText, this.pageIndex, this.pageSize)
    console.log(this.res)
    this.totGalList = this.res.data.list
    this.totGalListLen = this.res.data.totalCount


  },
  methods: {

  }

}

</script>

