var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "full_width" }, [
    _c("div", { staticClass: "contactus_inp_group inner_width" }, [
      _c("form", { attrs: { action: "#none" } }, [
        _c("fieldset", [
          _c("legend", [_vm._v(_vm._s(_vm.$t("contactus.text.contactus")))]),
          _c("div", { staticClass: "tbl-wrap" }, [
            _c("div", { staticClass: "tab-con tab01 on" }, [
              _c("div", { staticClass: "tbl-01" }, [
                _c(
                  "h2",
                  {
                    staticClass: "tt-2 mb-40",
                    staticStyle: { display: "none" },
                  },
                  [_vm._v(_vm._s(_vm.$t("contactus.text.submitform")))]
                ),
                _c("div", { staticClass: "tbl-input" }, [
                  _c("table", [
                    _c("caption", [
                      _vm._v("Contact us For Professionals form"),
                    ]),
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.area"))),
                          ]),
                          _c("div", { staticClass: "sel-box" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-sel",
                                attrs: { type: "button" },
                              },
                              [
                                _c("span", { staticClass: "placeholder" }, [
                                  _vm._v(_vm._s(_vm.areaName)),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "sel-list" }, [
                              _c("ul", { staticClass: "box" }, [
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Asia",
                                            "pro",
                                            "AREA_SOUTHASIA_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("contactus.text.south_asia")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Middle East",
                                            "pro",
                                            "AREA_MIDDLEEAST_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("contactus.text.middle_east")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Latin America",
                                            "pro",
                                            "AREA_LAAMERICA_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("contactus.text.latin_ameria")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Oceania",
                                            "pro",
                                            "AREA_OCEANIA_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.oceania"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Africa",
                                            "pro",
                                            "AREA_AFRICA_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.africa"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Turkey",
                                            "pro",
                                            "AREA_TURKEY_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.turkey"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Russia & CIS",
                                            "pro",
                                            "AREA_RUCIS_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.ru_cis"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "China",
                                            "pro",
                                            "AREA_CHINA_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.china"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.categoryType(
                                            "Japan",
                                            "pro",
                                            "AREA_JAPAN_PRO"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.japan"))
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm.proData.area == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkArea"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.country"))),
                          ]),
                          _c("div", { staticClass: "sel-box" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-sel",
                                attrs: { type: "button" },
                              },
                              [
                                _c("span", { staticClass: "placeholder" }, [
                                  _vm._v(_vm._s(_vm.proCountryName)),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "sel-list" }, [
                              _c(
                                "ul",
                                { staticClass: "box" },
                                _vm._l(_vm.glCountryList, function (country) {
                                  return _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proSelectCheck(
                                              "country",
                                              country.id.value,
                                              country.text,
                                              country.id.dictionaryId,
                                              country.sort
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(country.text))]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                          _vm.proData.country == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkCountry"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.inquiry"))),
                          ]),
                          _c("div", { staticClass: "sel-box" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-sel",
                                attrs: { type: "button" },
                              },
                              [
                                _c("span", { staticClass: "placeholder" }, [
                                  _vm._v(_vm._s(_vm.proInquiryName)),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "sel-list" }, [
                              _c("ul", { staticClass: "box" }, [
                                _c("li", { staticClass: "box_inquiry" }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.proSelectCheck(
                                            "Become a Partner",
                                            "INQ_PARTNER_GL",
                                            "Become a Partner"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.PARTNER"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "box_inquiry" }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.proSelectCheck(
                                            "Commercial Project (A&D)",
                                            "INQ_COMPROJECT_GL",
                                            "Commercial Project (A&D)"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("contactus.text.COMPROJECT")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "box_inquiry" }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.proSelectCheck(
                                            "Sample",
                                            "INQ_SAMPLE_GL",
                                            "Sample"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.SAMPLE"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "box_inquiry" }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.proSelectCheck(
                                            "B2B Customers",
                                            "INQ_B2BCUSTOMERS_GL",
                                            "B2B Customers"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("contactus.text.B2BCUSTOMERS")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "box_inquiry" }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.proSelectCheck(
                                            "Technical Inquiry",
                                            "INQ_TECHINQUIRY_GL",
                                            "Technical Inquiry"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("contactus.text.TECHINQUIRY")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "box_inquiry" }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.proSelectCheck(
                                            "Others",
                                            "INQ_OTHERS_GL",
                                            "Others"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("contactus.text.OTHERS"))
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm.proData.inquiry == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkInquiry"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("tr", { staticClass: "product_wrap" }, [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c(
                            "label",
                            { staticClass: "tbl_label must products" },
                            [_vm._v("Products")]
                          ),
                          _c(
                            "div",
                            { staticClass: "product_checkbox_cont" },
                            _vm._l(_vm.proProductList, function (productCate) {
                              return _c(
                                "div",
                                {
                                  key: productCate.id.value,
                                  staticClass: "checkbox_more",
                                },
                                [
                                  _c("span", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectedItems,
                                          expression: "selectedItems",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: productCate.id.value,
                                      },
                                      domProps: {
                                        value: {
                                          id: productCate.id.value,
                                          text: productCate.text,
                                        },
                                        checked: Array.isArray(
                                          _vm.selectedItems
                                        )
                                          ? _vm._i(_vm.selectedItems, {
                                              id: productCate.id.value,
                                              text: productCate.text,
                                            }) > -1
                                          : _vm.selectedItems,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.selectedItems,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = {
                                                id: productCate.id.value,
                                                text: productCate.text,
                                              },
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selectedItems = $$a.concat(
                                                  [$$v]
                                                ))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selectedItems = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selectedItems = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      { attrs: { for: productCate.id.value } },
                                      [_vm._v(_vm._s(productCate.text))]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm.selectedItems.length == 0 &&
                          _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkPrd_cate"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c(
                            "label",
                            {
                              staticClass: "tbl_label must",
                              attrs: { for: "inp01" },
                            },
                            [_vm._v(_vm._s(_vm.$t("contactus.text.firstName")))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.firstName,
                                expression: "proData.firstName",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: { type: "text", id: "inp01" },
                            domProps: { value: _vm.proData.firstName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "firstName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.proData.firstName == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("contactus.text.checkFirstName")
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "cell_padding" }, [
                          _c(
                            "label",
                            {
                              staticClass: "tbl_label must",
                              attrs: { for: "inp02" },
                            },
                            [_vm._v(_vm._s(_vm.$t("contactus.text.lastName")))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.lastName,
                                expression: "proData.lastName",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: { type: "text", id: "inp02" },
                            domProps: { value: _vm.proData.lastName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "lastName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.proData.lastName == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkLastName"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c(
                            "label",
                            {
                              staticClass: "tbl_label must",
                              attrs: { for: "inp03" },
                            },
                            [_vm._v(_vm._s(_vm.$t("contactus.text.email")))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.email,
                                expression: "proData.email",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: { type: "text", id: "inp03" },
                            domProps: { value: _vm.proData.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          (_vm.proData.email == "" &&
                            _vm.proSubmitChk == "Y") ||
                          (_vm.proSubmitChk == "Y" &&
                            !_vm.emailRule.test(_vm.proData.email))
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkEmail"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "cell_padding" }, [
                          _c(
                            "label",
                            {
                              staticClass: "tbl_label must",
                              attrs: { for: "inp04" },
                            },
                            [_vm._v(_vm._s(_vm.$t("contactus.text.phone")))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.phone,
                                expression: "proData.phone",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: {
                              type: "text",
                              id: "inp04",
                              oninput:
                                "this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\\..*)\\./g, '$1');",
                            },
                            domProps: { value: _vm.proData.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.proData.phone == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkPhone"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.occupation"))),
                          ]),
                          _c("div", { staticClass: "sel-box" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn-sel",
                                attrs: { type: "button" },
                              },
                              [
                                _c("span", { staticClass: "placeholder" }, [
                                  _vm._v(_vm._s(_vm.occName)),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "sel-list" }, [
                              _c(
                                "ul",
                                { staticClass: "box" },
                                _vm._l(_vm.occList, function (occ) {
                                  return _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proSelectCheck(
                                              "occupation",
                                              occ.id.value,
                                              occ.text
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(occ.text))]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                          _vm.proData.occupation == "" &&
                          _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("contactus.text.checkOcc"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.jobTitle"))),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.jobTitle,
                                expression: "proData.jobTitle",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: { type: "text" },
                            domProps: { value: _vm.proData.jobTitle },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "jobTitle",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.companyNm"))),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.company,
                                expression: "proData.company",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: { type: "text" },
                            domProps: { value: _vm.proData.company },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "company",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label must" }, [
                            _vm._v(
                              _vm._s(_vm.$t("contactus.text.companyWebs"))
                            ),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.proData.companyWebs,
                                expression: "proData.companyWebs",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: { type: "text" },
                            domProps: { value: _vm.proData.companyWebs },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.proData,
                                  "companyWebs",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "cell_padding",
                            attrs: { colspan: "2" },
                          },
                          [
                            _c("label", { staticClass: "tbl_label must" }, [
                              _vm._v(
                                _vm._s(_vm.$t("contactus.text.companyAddr"))
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.proData.companyAddr,
                                  expression: "proData.companyAddr",
                                },
                              ],
                              staticClass: "inp-text",
                              attrs: { type: "text" },
                              domProps: { value: _vm.proData.companyAddr },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.proData,
                                    "companyAddr",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "tbl-02" }, [
                _c("div", { staticClass: "tbl-input" }, [
                  _c("table", [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "cell_padding" }, [
                          _c("label", { staticClass: "tbl_label" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.comment"))),
                          ]),
                          _c("div", { staticClass: "ta-box" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.proData.comment,
                                  expression: "proData.comment",
                                },
                              ],
                              attrs: { maxlength: "1000" },
                              domProps: { value: _vm.proData.comment },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.proData,
                                    "comment",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm.proData.comment == "" && _vm.proSubmitChk == "Y"
                            ? _c("p", { staticClass: "glSite required-msg" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("contactus.text.checkCommentGl")
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _c("label", { staticClass: "inp-chk" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.consent,
                                  expression: "consent",
                                },
                              ],
                              attrs: { type: "checkbox", id: "checkSubmit" },
                              domProps: {
                                checked: Array.isArray(_vm.consent)
                                  ? _vm._i(_vm.consent, null) > -1
                                  : _vm.consent,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.consent,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.consent = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.consent = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.consent = $$c
                                  }
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("contactus.text.consent1")) + " "
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("contactus.text.submit"))),
                              ]),
                              _vm._v(_vm._s(_vm.$t("contactus.text.consent2"))),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openPrivacy()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("contactus.text.policy"))
                                  ),
                                ]
                              ),
                              _vm._v(" and "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTerms()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("contactus.text.terms")))]
                              ),
                              _vm._v(". "),
                              _c("span", [_vm._v("*")]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("br"),
                _vm.consent == false && _vm.proSubmitChk == "Y"
                  ? _c("div", { staticClass: "noti-02" }, [
                      _vm._v(_vm._s(_vm.$t("contactus.text.checkComment"))),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "more-box ta-r" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button", disabled: _vm.isSubmitting },
                      on: {
                        click: function ($event) {
                          return _vm.formSubmit()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.isSubmitting
                              ? "submitting..."
                              : _vm.$t("contactus.button.submit")
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "50%" } }),
      _c("col", { staticStyle: { width: "50%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }