import request from './request'
const urlPrefix = ''

const EuService = {
  getCountryList () {
    return request({
      url: `${urlPrefix}/eu/countryList`,
      method: 'get'
    })
  },
  getTypeCountryList (code) {
    return request({
      url: `${urlPrefix}/eu/typeCountryList?code=${code}`,
      method: 'get'
    })
  },
}

export default EuService
