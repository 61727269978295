<template>
  <div class="business-area-carousel-arrow-area">
    <div class="container-area">
      <div
        class="arrow-area"
        style="background-image: url(images/aboutus/overview/business_area/arrw_left.png);"
        @click="onClickPrev($event)"
      >
      </div>
      <div
        class="arrow-area"
        style="background-image: url(images/aboutus/overview/business_area/arrw_right.png);"
        @click="onClickNext($event)"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  },

  methods: {
    onClickPrev (_e) {
      this.data.instance.prev()
    },
    onClickNext (_e) {
      this.data.instance.next()
    }
  }
}
</script>

<style scoped>
.business-area-carousel-arrow-area {
  display: inline;
  position: relative;
  z-index: 99;
  top: -60px;
}

.business-area-carousel-arrow-area .container-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.business-area-carousel-arrow-area .arrow-area {
  width: 80px;
  height: 30px;
  margin: 0 50px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .business-area-carousel-arrow-area .arrow-area {
    margin: 0 3%;
  }
}

</style>
