var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sustainability-title-and-text" }, [
    _vm.contentTitle
      ? _c(
          "pre",
          { staticClass: "content-title", style: { color: _vm.titleColor } },
          [_vm._v(_vm._s(_vm.contentTitle))]
        )
      : _vm._e(),
    _vm.contentText
      ? _c(
          "pre",
          { staticClass: "content-text", style: { color: _vm.textColor } },
          [_vm._v(_vm._s(_vm.contentText))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }