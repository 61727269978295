<template>
  <div class="quick_sticky">
    <div class="quick_menu_wrap">
      <div class="quick_link_group">
        <!-- 러시아 텔레그램 -->
        <!-- <a :href="getContactUsHref(country)" class="q_item quick_link quick_tel">
          <span class="txt">telegram</span>
          <span class="icon"></span>
        </a> -->
        <!-- 중국 위쳇 -->
        <!-- <a :href="getContactUsHref(country)" class="q_item quick_link quick_wechat">
          <span class="txt">Wechat</span>
          <span class="icon"></span>
        </a> -->
        <a :href="getContactUsHref(country)" class="q_item quick_link quick_contact">
          <span class="txt">Contact us</span>
          <span class="icon"></span>
        </a>
        <a :href="getWheresHref(country)" v-if="quickExpand" class="q_item quick_link quick_where">
          <span class="txt">Where to buy</span>
          <span class="icon"></span>
        </a>
        <a @click="openNewsletterPopup()" v-if="quickExpand && (country === '_US' || country === '_GL')" class="q_item quick_link quick_news">
          <span class="txt">Newsletter</span>
          <span class="icon"></span>
        </a>
        <a @click="openNewsletterPopupEu('layer-newsletter-form')" v-if="quickExpand && (country === '_EN' || country === '_FR'|| country === '_DE' || country === '_IT'|| country === '_ES' || country === '_UK')" class="q_item quick_link quick_news">
          <span class="txt">Newsletter</span>
          <span class="icon"></span>
        </a>
        <a :href="getHref(country)" v-if="quickExpand" class="q_item quick_link quick_color">
          <span class="txt">All colors</span>
          <span class="icon"></span>
        </a>
      </div>
      <!-- <button v-if="quickExpand" type="button" class="q_item quick_expand p-ui" v-on:click="quickExpand=false">
        <span class="icon icon_quick_contract" ></span>
      </button>
      <button v-else type="button" class="q_item quick_expand p-ui" v-on:click="quickExpand=true">
        <span class="icon icon_quick_expand"></span>
      </button> -->
      <button type="button" class="q_item quick_top"><span class="icon icon_quick_top"></span></button>
    </div>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash.js'

const popupId = null
export default {
  name: 'FooterComponent',
  props: {
    isMicrosite: Boolean
  },
  components: {
  },
  data () {
    return {
      country: null,
      quickExpand: true, // 퀵메뉴 확장시 true
      is_show: false
    }
  },
  async created () {
    this.country = this.$countryCode
  },
  async mounted () {
  },
  computed: {
  },
  methods: {
    init () {
      this.form = _.cloneDeep(this.orgValue) // form setting
      // eloquoa 국가별 hidden form value
      if (this.country === '_US') {
        this.form.eloqua.name = 'lxhausys.com_US_NewsletterSubscription'
        this.form.eloqua.elqFormName = 'lxhausys.com_US_NewsletterSubscription'
        this.form.eloqua.countryName = 'country'
        this.form.eloqua.interestName = 'products_of_interest'
        this.form.eloqua.occuName = 'occupation1'
        this.form.eloqua.prvcName = ''
        this.form.eloqua.email = this.joinMail
      } else if (country === '_GL') {
        this.form.eloqua.name = 'lxhausys.com_NewsletterSubscription'
        this.form.eloqua.elqFormName = 'lxhausys.com_NewsletterSubscription'
        this.form.eloqua.countryName = 'country'
        this.form.eloqua.interestName = 'products_of_interest'
        this.form.eloqua.occuName = 'occupation1'
        this.form.eloqua.prvcName = 'privacyPolicy1'
        this.form.eloqua.email = this.joinMail
      } else {
        this.form.eu.email = this.joinMail
      }
      this.$nextTick(function () {})
    },
    getHref (country) {
      const lowercaseCountry = country.toLowerCase().replace(/^_/, '')
      return `/${lowercaseCountry}/products/all_colors`
    },
    getContactUsHref (country) {
      const lowercaseCountry = country.toLowerCase().replace(/^_/, '')
      return `/${lowercaseCountry}/support/contactus`
    },
    getWheresHref (country) {
      const lowercaseCountry = country.toLowerCase().replace(/^_/, '')

      if (country === '_US') {
        return `/${lowercaseCountry}/support/where-to-buy/country/us`
      } else {
        return `/${lowercaseCountry}/support/where-to-buy/global`
      }

    },
    openNewsletterPopup () {
      // alert('It is currently under maintenance.');
      // return;
      LayerPopup.open('popNewsletter')
      this.init()
    },
    openNewsletterPopupEu (str) {
      alert()
      if (str == 'layer-newsletter-form') {
        if (!_.isEmpty($('#inpNews').val()) && !this.emailRule.test($('#inpNews').val())) {
          alert(this.$t('footer.email_alert'))
          return
        }
      }

      LayerPopup.open(str)
      this.init()
      // $('body').css("overflow", "hidden");
    }
  }
}
</script>
