/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  searchWords: JSON.parse(localStorage.getItem('searchWord') || '[]'),
  searchHomeWords: JSON.parse(localStorage.getItem('searchHomeWords') || '[]'),
};
