<template>
  <div class="expanded-waste-recycling-table-area">
    <pre class="table-title">{{ tableAreaData.tableTitle }}</pre>
    <div class="scroll-box">
      <ExpandedWasteRecyclingTable
        :tableData="tableData"
      />
    </div>
  </div>
</template>

<script>
import ExpandedWasteRecyclingTable from './ExpandedWasteRecyclingTable.vue'
export default {
  components: {
    ExpandedWasteRecyclingTable
  },
  props: {
    tableAreaData: {
      type: Object
    }
  },
  data: function () {
    return {
      tableData: this.tableAreaData.tableData
    }
  }

}
</script>

<style>
.expanded-waste-recycling-table-area {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 30px;
}

@media (max-width: 768px) {
  .expanded-waste-recycling-table-area {
    padding: 0px;
    width: 100%;
  }
}

.expanded-waste-recycling-table-area .table-title {
  color: #222;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 113.333% */
}

@media (max-width: 768px) {
  .expanded-waste-recycling-table-area .table-title {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.expanded-waste-recycling-table-area .scroll-box {
    width: 100%;
  }

@media (max-width: 768px) {
  .expanded-waste-recycling-table-area .scroll-box {
    overflow: scroll;
    width: 100%;
  }
}
</style>
