var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content" }, [
        _vm._m(0),
        _c("div", { staticClass: "container reques" }, [
          _c("div", { staticClass: "w-con-02 inner" }, [
            _c("p", { staticClass: "tt-1 tac" }, [_vm._v("PASSWORD EXPIRED")]),
            _c("div", { staticClass: "mypg-loginJoin pw-expir-wrap" }, [
              _c("div", { staticClass: "mypg-forgotPW" }, [
                _vm._m(1),
                _c("div", { staticClass: "inpt-mypg" }, [
                  _c("ul", [
                    _c("li", { staticClass: "tooTip" }, [
                      _c("label", { attrs: { for: "inp03" } }, [
                        _vm._v("New Password"),
                      ]),
                      _c("div", { staticClass: "password-box" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password.value,
                              expression: "password.value",
                            },
                          ],
                          staticClass: "inp-text tooltip-inp",
                          attrs: {
                            type: "password",
                            id: "inp03",
                            placeholder: "New Password",
                          },
                          domProps: { value: _vm.password.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.password,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "tar" }, [
                        _vm.password.value === "" && _vm.submitChk === true
                          ? _c("span", { staticClass: "noti-02" }, [
                              _vm._v("Enter Password"),
                            ])
                          : _vm._e(),
                        _vm.submitChk &&
                        _vm.password.value !== "" &&
                        _vm.weakConst
                          ? _c("span", { staticClass: "noti-02" }, [
                              _vm._v("Password Does Not Meet The Requirements"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "toTip" }, [
                        _c("dl", { staticClass: "stepList" }, [
                          _c("dt", { staticClass: "ctt ctt-t5" }, [
                            _vm._v("Your password must have:"),
                          ]),
                          _c("dd", { staticClass: "ctt ctt-t5 must-step" }, [
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5 cha-10",
                                class: {
                                  active: _vm.lengthConst.test(
                                    _vm.password.value
                                  ),
                                },
                              },
                              [_vm._v("10 or more characters")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5 upper",
                                class: {
                                  active:
                                    _vm.capLetterConst.test(
                                      _vm.password.value
                                    ) &&
                                    _vm.smallLetterConst.test(
                                      _vm.password.value
                                    ),
                                },
                              },
                              [_vm._v("Upper & lowercase letters")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ctt ctt-t5 least",
                                class: {
                                  active: _vm.numberConst.test(
                                    _vm.password.value
                                  ),
                                },
                              },
                              [_vm._v("At least one number")]
                            ),
                          ]),
                          _c("dt", { staticClass: "ctt ctt-t5" }, [
                            _c("span", { staticClass: "ctt ctt-t5" }, [
                              _vm._v("Strength: "),
                            ]),
                            _vm.weakConst
                              ? _c("span", { staticClass: "ctt ctt-t5" }, [
                                  _vm._v("weak"),
                                ])
                              : _vm._e(),
                            _vm.moderateConst
                              ? _c("span", { staticClass: "ctt ctt-t5" }, [
                                  _vm._v("moderate"),
                                ])
                              : _vm._e(),
                            _vm.strongConst
                              ? _c("span", { staticClass: "ctt ctt-t5" }, [
                                  _vm._v("strong"),
                                ])
                              : _vm._e(),
                          ]),
                          _c("dd", { staticClass: "progress-area" }, [
                            _vm.weakConst
                              ? _c("div", { staticClass: "progress-bar" }, [
                                  _c("div", { staticClass: "step-01 week" }),
                                  _c("div", { staticClass: "step-02" }),
                                  _c("div", { staticClass: "step-03" }),
                                ])
                              : _vm._e(),
                            _vm.moderateConst
                              ? _c("div", { staticClass: "progress-bar" }, [
                                  _c("div", { staticClass: "step-01 normal" }),
                                  _c("div", { staticClass: "step-02 normal" }),
                                  _c("div", { staticClass: "step-03" }),
                                ])
                              : _vm._e(),
                            _vm.strongConst
                              ? _c("div", { staticClass: "progress-bar" }, [
                                  _c("div", { staticClass: "step-01 strong" }),
                                  _c("div", { staticClass: "step-02 strong" }),
                                  _c("div", { staticClass: "step-03 strong" }),
                                ])
                              : _vm._e(),
                          ]),
                          _c("dd", { staticClass: "ctt ctt-t5 pw-easy" }, [
                            _vm._v(
                              " Avoid using a password that you use with other websites or that might be easy for some one else to guess. "
                            ),
                          ]),
                          _vm.weakConst &&
                          _vm.letterNumberCharConst.test(_vm.password.value)
                            ? _c(
                                "dd",
                                { staticClass: "ctt ctt-t5 pw-unacceptable" },
                                [
                                  _vm._v(
                                    " Your password contains unacceptable characters. "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.weakConst &&
                          _vm.repeatConst.test(_vm.password.value)
                            ? _c(
                                "dd",
                                { staticClass: "ctt ctt-t5 pw-unacceptable" },
                                [
                                  _vm._v(
                                    " Too many consecutive identical characters. "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("label", { attrs: { for: "inp04" } }, [
                        _vm._v("Confirm Password"),
                      ]),
                      _c("div", { staticClass: "password-box" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.chkpassword.value,
                              expression: "chkpassword.value",
                            },
                          ],
                          staticClass: "inp-text",
                          attrs: {
                            type: "password",
                            id: "inp04",
                            placeholder: "Confirm Password",
                          },
                          domProps: { value: _vm.chkpassword.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.chkpassword,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "tar" }, [
                        _vm.chkpassword.value !== _vm.password.value &&
                        _vm.submitChk === true
                          ? _c("span", { staticClass: "noti-02" }, [
                              _vm._v("The Passwords You Entered Do Not Match"),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn-mypg" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.confirm()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Reset Password")])]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "layer-popup pop-modal-s1 ResetPass" }, [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "scr-y" }, [
            _vm._m(2),
            _vm._m(3),
            _c("div", { staticClass: "modalBtn-01" }, [
              _c(
                "button",
                {
                  staticClass: "btn-type-large",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goLogin()
                    },
                  },
                },
                [_c("span", [_vm._v(" Return to Login page")])]
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closePopup()
                  },
                },
              },
              [_vm._v("close")]
            ),
          ]),
        ]),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Professionals")]),
        _c("li", [_vm._v("HIMACS Partners Portal ")]),
        _c("li", [_vm._v("Password Expired")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ctt ctt-t3 mt-type" }, [
      _vm._v("You are required to change your password at least 90 days."),
      _c("br"),
      _vm._v("Please enter a new password below so you may log in."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tit-box" }, [
      _c("p", { staticClass: "heading-4" }, [_vm._v("Reset Password")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-con" }, [
      _c("div", { staticClass: "check" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/public/images/img_FindID_success.png",
          },
        }),
      ]),
      _c("div", { staticClass: "msg01" }, [
        _c("span", { staticClass: "txt01" }, [
          _vm._v(" Your password has been changed successfully. "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }