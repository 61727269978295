var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expanded-waste-recycling-table-body" },
    _vm._l(_vm.bodyList, function (item) {
      return _c("pre", {
        key: item,
        staticClass: "body-text",
        domProps: { innerHTML: _vm._s(item) },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }