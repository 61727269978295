<template>
  <div class="text-area">
    <pre class="main-text" v-html="text"></pre>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }
}
</script>

<style scoped>
.text-area .main-text {
  color: #000;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

.text-area .main-text b {
  font-weight: 700;
}

@media (max-width: 768px) {
  .text-area .main-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
  .text-area .main-text b {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
}
</style>
