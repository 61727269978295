<template>
  <div>
    <ProductCarousel :autoplay="true" :speed="1000">
      <FilmItem
        v-for="(it, idx) in itemInfo"
        :key="idx"
        :title="it.title"
        :description="it.description"
        :layerImageSrc="it.layerImageSrc"
        :exampleImageSrc="it.exampleImageSrc"
      />
    </ProductCarousel>
  </div>
</template>

<script>
import ProductCarousel from '@/components/product/carousel'
import FilmItem from './FilmItem'

export default {
  components: {
    ProductCarousel,
    FilmItem
  },

  data: () => ({
    itemInfo: [
      {
        title: 'Hi-Gloss',
        description: 'Excellent printing technology can offer customized patterns.',
        layerImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/hi-gross-layer.png',
        exampleImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/hi-gross-example.png'
      },
      {
        title: 'Galaxy',
        description: 'Various pearl effects will allow fine and glittery surface.',
        layerImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/galaxy-layer.png',
        exampleImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/galaxy-example.png'
      },
      {
        title: 'Soft-matte',
        description: 'Soft-matte Textures with various color or customized patterns',
        layerImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/soft-matte-layer.png',
        exampleImageSrc: 'images/industrialFilm/VinylCoatedMaterial/film/soft-matte-example.png'
      }
    ]
  })
}
</script>

<style scoped>

</style>
