<template>
  <div class="seat-corver-area">
    <div class="top-area">
      <div class="image">
        <img :src="imageSrc">
      </div>

      <div class="text">
        <slot name="text"></slot>
      </div>
    </div>

    <div v-if="existAnnotaion" class="divider"></div>

    <div class="annotation-area">
      <slot name="annotation"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true
    }
  },

  data: () => ({
    existAnnotaion: false
  }),

  mounted () {
    this.existAnnotaion = this.$slots.annotation
  }
}
</script>

<style scoped>
.seat-corver-area {
  display: flex;
  flex-direction: column;
  background-color: #F1F1F5;
}

.seat-corver-area .top-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.seat-corver-area .top-area .image {
  flex: 1;
  padding-top: 87px;
  padding-left: 50px;
}
@media (max-width: 768px) {
  .seat-corver-area .top-area .image {
    padding: 10px;
  }
}

.seat-corver-area .top-area .image img {
  object-fit: cover;
  width: 100%;
}

.seat-corver-area .top-area .text {
  flex: 1;
  padding-top: 60px;
  padding-left: 33px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .seat-corver-area .top-area .text {
    padding: 10px;
    min-width: 360px;
  }
}

.seat-corver-area .top-area .text .title {
  margin-bottom: 10px;
  margin-top: 22px;
}

.seat-corver-area .top-area .description span {
  color: #222;
  font-family: 'NotoSans' , sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .seat-corver-area .top-area .description span {
    font-size: 20px;
    line-height: 28px;
  }
}

.seat-corver-area .top-area .text .title span {
  color: #222;
  font-family: 'NotoSans' , sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .seat-corver-area .top-area .text .title span {
    font-size: 20px;
  }
}

.seat-corver-area .divider {
  height: 1px;
  background: #D1D1D1;
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 50px;
  margin-right: 50px;
}
@media (max-width: 768px) {
  .seat-corver-area .divider {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.seat-corver-area .annotation-area {
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .seat-corver-area .annotation-area {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.seat-corver-area .annotation-area span {
  color: #222;
  font-family: 'NotoSans' , sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .seat-corver-area .annotation-area span {
    font-size: 20px;
    line-height: 40px;
  }
}

.seat-corver-area .annotation-area span.spacing {
  padding-left: 12px;
}
</style>
