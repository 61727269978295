var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "social-contribution-strategy-system-key" },
    [
      _c("SocialResponsibilityBorderTitle", {
        attrs: { title: _vm.borderTitle, padding: "15px 80px" },
      }),
      _c("OverviewSectionItem", [
        _c(
          "div",
          { staticClass: "social-contribution-strategy-system-key-content" },
          _vm._l(_vm.cardItems, function (item, index) {
            return _c("KeyActivitiesCard", {
              key: index,
              attrs: { number: item.number, text: item.text },
            })
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }