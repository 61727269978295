<template>
  <OverviewSection
      title="We create human-friendly, eco-conscious living spaces"
      color="#222"
      size="56px"
      v-if="nationCode == '_US' || nationCode == '_GL'"
    >

    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text="In 2022, LX Hausys established the ESG Committee along with the ESG Vision and Strategy System in order to organize its ESG management.
In pursuing the ESG Vision slogan of “Innovations for Consumers, ESG for Future,”
we express our firm commitment to creating continued innovation and sustainable growth by actively
implementing our 3 strategic directions and 9 strategic tasks."
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="人間に優しく、環境に配慮した生活空間を創造します"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >

    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text="LX Hausysは、2022年にESG経営を組織化するために、ESGビジョンと戦略システムとともにESG委員会を設立しました。
        「消費者のためのイノベーション、未来のためのESG」というESGビジョンのスローガンを追求し、
        3つの戦略方向と9つの戦略タスクを積極的に実行することで、
        継続的なイノベーションと持続可能な成長を生み出すという確固たる決意を表明しています。
        "
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Мы создаем безопасные для человека и экологически чистые жилые пространства."
    color="#222"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >

    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text="В 2022 году компания LX Hausys создала Комитет ESG, а также систему видения и стратегии ESG для организации управления ESG.
Следуя лозунгу ESG Vision «Инновации для потребителей, ESG для будущего».
Мы выражаем нашу твердую приверженность созданию непрерывных инноваций и устойчивому росту, 
активно реализуя 3 стратегических направления и 9 стратегических задач."
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="创造与自然相融合的人类幸福生活空间"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >

    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text="2022年，LX Hausys成立了ESG委员会以及ESG愿景和战略体系，以组织其ESG经营。
在追求ESG愿景“为客户创新，ESG面向未来”的过程中
LX Hausys积极实施3个战略方向和9个战略任务，创造持续创新和可持续增长。
        "
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    OverviewText
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}
</style>
