<template>
  <div class="strong-point-area" :class="type">
    <div class="icon-area">
      <img :src="iconSrc">
    </div>
    <div class="text-area">
      <div class="title">
        <span :style="titleFontStyle">{{ title }}</span>
      </div>

      <div class="divider"></div>

      <div class="description">
        <span v-html="description"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'horizontal'
    },
    titleFontSize: {
      type: String,
      required: false
    }
  },

  computed: {
    titleFontStyle () {
      return this.titleFontSize ? `font-size: ${this.titleFontSize};` : ''
    }
  }
}
</script>

<style scoped>
.strong-point-area {
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .strong-point-area {
    margin-bottom: 20px;
  }
}

.strong-point-area.horizontal {
  width: 540px;
  flex-direction: row;
}

.strong-point-area.horizontal .icon-area {
  flex-basis: 75px;
}

.strong-point-area.horizontal .icon-area img {
  width: 75px;
}

.strong-point-area.horizontal .text-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.strong-point-area.horizontal .text-area .title {
  padding-left: 30px;
}

.strong-point-area.horizontal .text-area .title span {
  color: #222;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
}
@media (min-width: 769px) {
  .strong-point-area.horizontal .text-area .title span {
    font-size: 30px;
  }
}

.strong-point-area.horizontal .text-area .divider {
  height: 1px;
  background-color: #A8A9AD;
  margin-top: 10px;
  margin-bottom: 15px;
}

.strong-point-area.horizontal .text-area .description {
  padding-left: 30px;
}

.strong-point-area.horizontal .text-area .description span {
  color: #666;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
}
@media (min-width: 769px) {
  .strong-point-area.horizontal .text-area .description span {
    font-size: 20px;
    line-height: 28px;
  }
}

.strong-point-area.vertical {
  width: 100%;
  flex-direction: column;
}
@media (min-width: 769px) {
  .strong-point-area.vertical {
    width: 320px;
    flex-direction: column;
  }
}

.strong-point-area.vertical .icon-area {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
}

.strong-point-area.vertical .icon-area img {
  width: 75px;
  object-fit: cover;
}

.strong-point-area.vertical .text-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.strong-point-area.vertical .text-area .title {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.strong-point-area.vertical .text-area .title span {
  color: #000;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.strong-point-area.vertical .text-area .divider {
  height: 1px;
  background-color: #A8A9AD;
  margin-top: 17px;
  margin-bottom: 15px;
}

.strong-point-area.vertical .text-area .description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}

.strong-point-area.vertical .text-area .description span {
  color: #555;
  font-family: 'NotoSans' , sans-serif;;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
</style>
