<template>
    <OverviewSection
    title="Key Performance 2023"
    color="black"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
    >
    <OverviewTopImage class="key-performance-image"
        imageSrc="images/aboutus/overview/performance/key-performance-2022-image.png"
        imageHeight="revert-layer"
        imageWidth="1440px"
    />

      <OverviewSectionItem>
        <div class="scroll-box">
          <div class="table-area">
            <KeyPerformanceIconTextTable class="key-performance-table"
            title="Tasks"
            iconSrc1="images/aboutus/overview/performance/key-performance-icon1.png"
            mainTxt1="Enhancement of B2C business competitiveness"
            iconSrc2="images/aboutus/overview/performance/key-performance-icon2.png"
            mainTxt2="Increase in high-value products"
            iconSrc3="images/aboutus/overview/performance/key-performance-icon3.png"
            mainTxt3="Enhancement of R&D competitiveness"
            />
            <KeyPerformanceTextTable class="key-performance-table"
            title="Key performances"
            :textList1="textList1"
            :textList2="textList2"
            :textList3="textList3"
            />
          </div>
        </div>
      </OverviewSectionItem>
    </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="2023年の主要業績"
    color="black"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewTopImage class="key-performance-image"
                      imageSrc="images/aboutus/overview/performance/key-performance-2022-image.png"
                      imageHeight="revert-layer"
                      imageWidth="1440px"
    />

    <OverviewSectionItem>
      <div class="scroll-box">
        <div class="table-area">
          <KeyPerformanceIconTextTable class="key-performance-table"
                                       title="タスク"
                                       iconSrc1="images/aboutus/overview/performance/key-performance-icon1.png"
                                       mainTxt1="B2Cビジネスの競争力強化"
                                       iconSrc2="images/aboutus/overview/performance/key-performance-icon2.png"
                                       mainTxt2="高付加価値製品の増加"
                                       iconSrc3="images/aboutus/overview/performance/key-performance-icon3.png"
                                       mainTxt3="研究開発競争力の強化"
          />
          <KeyPerformanceTextTable class="key-performance-table"
                                   title="主なパフォーマンス"
                                   :textList1="textList1_jp"
                                   :textList2="textList2_jp"
                                   :textList3="textList3_jp"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Ключевые показатели 2023"
    color="black"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewTopImage class="key-performance-image"
                      imageSrc="images/aboutus/overview/performance/key-performance-2022-image.png"
                      imageHeight="revert-layer"
                      imageWidth="1440px"
    />

    <OverviewSectionItem>
      <div class="scroll-box">
        <div class="table-area">
          <KeyPerformanceIconTextTable class="key-performance-table"
                                       title="Задачи"
                                       iconSrc1="images/aboutus/overview/performance/key-performance-icon1.png"
                                       mainTxt1="Повышение конкурентоспособности бизнеса B2C"
                                       iconSrc2="images/aboutus/overview/performance/key-performance-icon2.png"
                                       mainTxt2="Увеличение объема продукции с высокой добавленной стоимостью"
                                       iconSrc3="images/aboutus/overview/performance/key-performance-icon3.png"
                                       mainTxt3="Повышение конкурентоспособности R&D "
          />
          <KeyPerformanceTextTable class="key-performance-table"
                                   title="Основные показатели"
                                   :textList1="textList1_ru"
                                   :textList2="textList2_ru"
                                   :textList3="textList3_ru"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="2023 主要成果"
    color="black"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewTopImage class="key-performance-image"
                      imageSrc="images/aboutus/overview/performance/key-performance-2022-image.png"
                      imageHeight="revert-layer"
                      imageWidth="1440px"
    />

    <OverviewSectionItem>
      <div class="scroll-box">
        <div class="table-area">
          <KeyPerformanceIconTextTable class="key-performance-table"
                                       title="课题"
                                       iconSrc1="images/aboutus/overview/performance/key-performance-icon1.png"
                                       mainTxt1="强化B2C业务竞争力"
                                       iconSrc2="images/aboutus/overview/performance/key-performance-icon2.png"
                                       mainTxt2="扩大高附加值 产品规模"
                                       iconSrc3="images/aboutus/overview/performance/key-performance-icon3.png"
                                       mainTxt3="加强研发领域的竞争优势"
          />
          <KeyPerformanceTextTable class="key-performance-table"
                                   title="主要成果"
                                   :textList1="textList1_cn"
                                   :textList2="textList2_cn"
                                   :textList3="textList3_cn"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  </template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import OverviewTopImage from '../../components/OverviewTopImage.vue'
import KeyPerformanceIconTextTable from './KeyPerformanceIconTextTable.vue'
import KeyPerformanceTextTable from './KeyPerformanceTextTable.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    OverviewTopImage,
    KeyPerformanceIconTextTable,
    KeyPerformanceTextTable
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      textList1: [
        'Reinforced product lineup (e.g., kitchen/bath products)',
        'Increased rollout to largest integrated showrooms',
        'REnhanced construction and service capability'
      ],
      textList2: [
        'Continued to develop insulation materials and engineered stone',
        'Increased the weight of highvalue products such as flooring materials'
      ],
      textList3: [
        'Continued to develop high-insulation and high-fire resistant insulation materials',
        'Enhanced product competitiveness with R&D capability'
      ],
      textList1_jp: [
        '製品ラインナップの強化（キッチン/バス用品など）',
        '最大規模の統合ショールームへの展開拡大',
        '施工およびサービス能力の強化'
      ],
      textList2_jp: [
        '断熱材や人工石の開発を継続',
        '床材などの高付加価値製品の重量を増加'
      ],
      textList3_jp: [
        '高断熱・高耐火断熱材の開発を継続',
        '研究開発力で製品競争力を強化'
      ],
      textList1_ru: [
        'Усиление ассортимента продукции (например, товаров для кухни/ванной)',
        'Увеличение числа крупнейших интегрированных шоу-румов',
        'Расширение возможностей по строительству и обслуживанию'
      ],
      textList2_ru: [
        'Продолжение разработки изоляционных материалов и искусственного камня',
        'Увеличение веса дорогостоящих продуктов, таких как напольные покрытия'
      ],
      textList3_ru: [
        'Продолжение разработки высокоизоляционных и огнестойких изоляционных материалов',
        'Повышение конкурентоспособности продукции за счет развития научно-исследовательского потенциала'
      ],
      textList1_cn: [
        '强化产品阵容（如厨房/浴室产品）',
        '增强对综合展厅的推广',
        '增强建设及服务能力'
      ],
      textList2_cn: [
        '加强隔热材料与人造石的市场竞争力',
        '增加瓷砖与地板材料的占比'
      ],
      textList3_cn: [
        '不断开发环保型隔热材料',
        '凭借研发能力增强产品竞争力'
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
@media (max-width: 768px) {
  .scroll-box {
    overflow: scroll;
    width: 90%;
    height: 100%;
  }
  .table-area {
    width: 1440px;
  }
}

</style>
