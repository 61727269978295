import request from './request'
const menuService = {

  getMenuTrees (region) {
    return request({
      url: '/menu/getFrontTrees?siteType=' + region,
      method: 'get',
      data: { siteType:region },
      timeout: 100000
    })
  },
  getMenuTSTrees (id, region) {
    return request({
      url: '/menu/getFrontTSTrees?parentId=' + id + '&siteType=' + region,
      method: 'get',
      data: { parentId: id, siteType:region },
      timeout: 100000
    })
  }
}

export default menuService
