<template>
  <div class="key-performance-table-header">
    <OverviewText type="ft-18 fw-700" :text="title" />
  </div>
</template>

<script>
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewText
  },

  props: {
    title: {
      type: String
    }
  }
}
</script>

<style>
.key-performance-table-header {
  display: flex;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #2C2C2D;
  border-bottom: 1px solid #2C2C2D;
}
</style>
