<template>
  <div class="border-box-area">
    <div class="text-area">
      <OverviewText
        type="ft-25 fw-700"
        :text="title"
        textAlign="left"
      />
      <div class="line"></div>
      <div><pre class="border-box-content" v-html="content"></pre></div>
    </div>
  </div>
</template>

<script>
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewText
  },

  props: {
    title: {
      type: String
    },
    content: {
      type: String
    }
  }
}
</script>

<style>
.border-box-area {
  display: flex;
  padding: 45px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  border-top: 1px solid #2C2C2D;
  background: #F9F9F9;
  width: 692.5px;
  min-height: 258px;
}
@media (max-width: 700px) {
  .border-box-area {
    width: 100%;
  }
}

.border-box-area .text-area {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}

.border-box-area .text-area .line {
  width: 100%;
  height: 1px;
  background: #2C2C2D;
}

.border-box-area .text-area .border-box-content {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .border-box-area .text-area .border-box-content {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }
}

.border-box-area .text-area .border-box-content b {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .border-box-area .text-area .border-box-content b {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }
}
</style>
