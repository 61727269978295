<template>
  <OverviewLayout
    imageSrc="images/aboutus/overview/ceo_message/top_image.png"
    title="CEO MESSAGE"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
  <template #content>
    <OverviewSection>
      <div class="ceo-image-area" v-if="!isMobile()">
        <img src="images/aboutus/overview/ceo_message/ceo_message.jpg" class="img">
      </div>
      <div class="ceo-image-area" v-else>
        <div class="keyvisual-hei-600 ceomessage_imgbox">
          <img src="https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg" class="img"/>
        </div>
        <div class="t-type-01 ceomessage_txtbox">
          <br/>
          LX Hausys is the largest building & decorative
          material company in Korea, and is continuously
          expanding its business to global regions such as the
          US and Europe. We have established a balanced
          growth path by diversifying the business portfolio to
          include Automotive Materials and Industrial Film. We
          will constantly strive to secure the leading position in
          the market, and bring innovative customer value by
          providing high-quality products with superior
          durability, functionality, and eco-consciousness.
        </div>
      </div>
    </OverviewSection>
  </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/ceo_message/top_image.png"
    title="CEOメッセージ"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <OverviewSection>
        <div class="ceo-image-area" v-if="!isMobile()">
          <img src="images/aboutus/overview/ceo_message/ceo_message.jpg" class="img">
        </div>
        <div class="ceo-image-area" v-else>
          <div class="keyvisual-hei-600 ceomessage_imgbox">
            <img src="https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg" class="img"/>
          </div>
          <div class="t-type-01 ceomessage_txtbox">
            <br/>
            LXハウシスは韓国最大の建築・装飾資材メーカーとして、米国や欧州
            などグローバルな地域に事業を拡大しています。
            自動車資材や産業用フィルムなど事業ポートフォリオを多元化し、安定的な成長路線を確立
            しています。耐久性、機能性、環境配慮性に優れた高品質の製品を提供し、
            市場で主導的な地位を確保し、革新的な顧客価値を実現するために絶えず
            努力していきます。
          </div>
        </div>
      </OverviewSection>
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/ceo_message/top_image.png"
    title="Послание генерального директора"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <OverviewSection>
        <div class="ceo-image-area" v-if="!isMobile()">
          <img src="images/aboutus/overview/ceo_message/ceo_message.jpg" class="img">
        </div>
        <div class="ceo-image-area" v-else>
          <div class="keyvisual-hei-600 ceomessage_imgbox">
            <img src="https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg" class="img"/>
          </div>
          <div class="t-type-01 ceomessage_txtbox">
            <br/>
            LX Hausys - крупнейшая компания по производству строительных и декоративных 
            материалов в Корее, которая постоянно расширяет свой бизнес в таких глобальных 
            регионах, как США и Европа. 
            Мы создали сбалансированный путь роста, диверсифицировав портфель бизнеса и 
            включив в него автомобильные материалы и пленку для ламинации. Мы будем постоянно 
            стремиться занять лидирующие позиции на рынке и обеспечить инновационные преимущества 
            для клиентов, предоставляя высококачественную продукцию с превосходной долговечностью, 
            функциональностью и экологичностью.
          </div>
        </div>
      </OverviewSection>
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/ceo_message/top_image.png"
    title="CEO 致辞"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <OverviewSection>
        <div class="ceo-image-area" v-if="!isMobile()">
          <img src="images/aboutus/overview/ceo_message/ceo_message.jpg" class="img">
        </div>
        <div class="ceo-image-area" v-else>
          <div class="keyvisual-hei-600 ceomessage_imgbox">
            <img src="https://img.lxhausys.com/public/images/aboutus/ceo_message_mo.jpg" class="img"/>
          </div>
          <div class="t-type-01 ceomessage_txtbox">
            <br/>
            LX Hausys作为韩国最大规模的装饰建材企业，成功地在北美、亚洲和欧洲各地设立了销售及
            生产基地，并逐步扩张全球化经营的版图。
            我们通过多元化业务组合（包括汽车材料和产业用膜）建立了均衡的增长道路。我们将不断努力
            确保市场领先地位，并通过提供具有卓越的耐用性、功能性及环保高品质产品带来创新的客户价值。
          </div>
        </div>
      </OverviewSection>
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from './components/OverviewLayout.vue'
import OverviewSection from './components/OverviewSection.vue'

export default {
  name: 'Detail',
  components: {
    OverviewLayout,
    OverviewSection
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  methods: {
    isMobile(){
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
    },
  }
}
</script>
