var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    [
      !_vm.currentUrl.includes("/etc/privacy")
        ? _c(
            "div",
            {
              staticClass: "wrapper-cokkie-dim",
              staticStyle: { display: "none" },
            },
            [
              _c("div", { staticClass: "wrapper-cokkie" }, [
                _c("div", { staticClass: "titwrap-" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.cookiesBtn(0)
                        },
                      },
                    },
                    [_c("span", { staticClass: "sr-only" }, [_vm._v("close")])]
                  ),
                  _c("p", { staticClass: "tt-sub-gray" }, [
                    _vm._v(_vm._s(_vm.$t("cookie.cookie_setting"))),
                  ]),
                  _c("p", { staticClass: "tt-sub" }, [
                    _vm._v(_vm._s(_vm.$t("cookie.cookie_sub"))),
                  ]),
                ]),
                _c("div", { staticClass: "container-cokkie" }, [
                  _c("ul", { staticClass: "chk-List" }, [
                    _c("li", [
                      _c("div", { staticClass: "chkArea strictly" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("label", { staticClass: "inp-chk" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.cookie_check,
                                  expression: "cookie_check",
                                },
                              ],
                              staticClass: "chk foot-cok-checked",
                              attrs: {
                                type: "checkbox",
                                id: "strictlyChk",
                                value: "1",
                                disabled: "",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.cookie_check)
                                  ? _vm._i(_vm.cookie_check, "1") > -1
                                  : _vm.cookie_check,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.cookie_check,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "1",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.cookie_check = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.cookie_check = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.cookie_check = $$c
                                  }
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("cookie.strictly_cookies")) +
                                  " : "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("p", { staticClass: "ctt ctt-t4 sub-tit" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("cookie.strictly_sub")) + " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "chkArea analytical" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("label", { staticClass: "inp-chk" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.cookie_check,
                                  expression: "cookie_check",
                                },
                              ],
                              staticClass: "chk",
                              attrs: {
                                type: "checkbox",
                                id: "analyticalChk",
                                value: "2",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.cookie_check)
                                  ? _vm._i(_vm.cookie_check, "2") > -1
                                  : _vm.cookie_check,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.cookie_check,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "2",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.cookie_check = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.cookie_check = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.cookie_check = $$c
                                  }
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("cookie.analytical_cookies")) +
                                  " : "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("p", { staticClass: "ctt ctt-t4 sub-tit" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("cookie.analytical_sub")) + " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "chkArea marketing" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("label", { staticClass: "inp-chk" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.cookie_check,
                                  expression: "cookie_check",
                                },
                              ],
                              staticClass: "chk",
                              attrs: {
                                type: "checkbox",
                                id: "marketingChk",
                                value: "3",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.cookie_check)
                                  ? _vm._i(_vm.cookie_check, "3") > -1
                                  : _vm.cookie_check,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.cookie_check,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "3",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.cookie_check = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.cookie_check = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.cookie_check = $$c
                                  }
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("cookie.marketing_cookies")) +
                                  " : "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("p", { staticClass: "ctt ctt-t4 sub-tit" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("cookie.marketing_sub")) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "cokkieBtn" }, [
                    _c("ul", { staticClass: "frow" }, [
                      _c("li", { attrs: { clas: "fcol fcol-6" } }, [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.cookiesBtn(1)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("cookie.privacy_policy"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", { attrs: { clas: "fcol fcol-6" } }, [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.cookiesBtn(2)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("cookie.allow_selection"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", { attrs: { clas: "fcol fcol-12" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn_bk",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.cookiesBtn(3)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("cookie.allow_all"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pc-view",
          class: {
            euSite:
              _vm.$countryCode === "_EN" ||
              _vm.$countryCode === "_FR" ||
              _vm.$countryCode === "_DE" ||
              _vm.$countryCode === "_IT" ||
              _vm.$countryCode === "_ES" ||
              _vm.$countryCode === "_UK",
          },
        },
        [
          _c("div", { staticClass: "footer-cont" }, [
            _c("div", { staticClass: "inner_line" }, [
              _c("div", { staticClass: "logo-box" }, [
                _c("div", { staticClass: "logo" }, [
                  _vm._v(_vm._s(_vm.$t("footer.lx_hausys"))),
                ]),
              ]),
              _c("div", { staticClass: "comp-box" }, [
                _c("div", { staticClass: "com-01" }, [
                  _c("div", { staticClass: "heading-2 footer-tit" }, [
                    _vm._v(" COMPANY "),
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm.isMicrosite
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.gotoUrl(
                                    "https://www.lxhausys.com/us/aboutus/overview/business-area",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.about_us")))]
                          )
                        : _c(
                            "a",
                            {
                              attrs: { href: "aboutus/overview/business-area" },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.about_us")))]
                          ),
                    ]),
                    _vm.$countryCode === "_US"
                      ? _c("li", [
                          _vm.isMicrosite
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.lxhausys.com/us/aboutus/career",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.career")))]
                              )
                            : _c("a", { attrs: { href: "aboutus/career" } }, [
                                _vm._v(_vm._s(_vm.$t("footer.career"))),
                              ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _vm.isMicrosite
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.gotoUrl(
                                    "https://www.lxhausys.com/us/aboutus/sustainability/sustainability-management",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.sustainability")))]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: "aboutus/sustainability/sustainability-management",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.sustainability")))]
                          ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "com-02" }, [
                  _c("div", { staticClass: "heading-2 footer-tit" }, [
                    _vm._v(" " + _vm._s(_vm.$t("footer.help")) + " "),
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm.isMicrosite
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.gotoUrl(
                                    "https://www.lxhausys.com/us/support/faq/all-products",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.faq")))]
                          )
                        : _c(
                            "a",
                            { attrs: { href: "support/faq/all-products" } },
                            [_vm._v(_vm._s(_vm.$t("footer.faq")))]
                          ),
                    ]),
                    _c("li", [
                      _vm.isMicrosite
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.gotoUrl(
                                    "https://www.lxhausys.com/us/support/contactus",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.contact_us")))]
                          )
                        : _vm.$countryCode === "_GL" ||
                          _vm.$countryCode === "_US"
                        ? _c("a", { attrs: { href: "support/contactus" } }, [
                            _vm._v(_vm._s(_vm.$t("footer.contact_us"))),
                          ])
                        : _c(
                            "a",
                            { attrs: { href: "support/contactus/pro" } },
                            [_vm._v(_vm._s(_vm.$t("footer.contact_us")))]
                          ),
                    ]),
                    _vm.$countryCode === "_EN" ||
                    _vm.$countryCode === "_FR" ||
                    _vm.$countryCode === "_DE" ||
                    _vm.$countryCode === "_IT" ||
                    _vm.$countryCode === "_ES" ||
                    _vm.$countryCode === "_UK"
                      ? _c("li", [
                          _vm.isMicrosite
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.lxhausys.com/us/etc/custSupport",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.cust_support")))]
                              )
                            : _c("a", { attrs: { href: "etc/custSupport" } }, [
                                _vm._v(_vm._s(_vm.$t("footer.cust_support"))),
                              ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _vm.$countryCode === "_US" && _vm.isMicrosite
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.gotoUrl(
                                    "https://www.lxhausys.com/us/support/where-to-buy/country/us",
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                          )
                        : _vm.$countryCode === "_US" && !_vm.isMicrosite
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "support/where-to-buy/country/us",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                          )
                        : _vm._e(),
                      _vm.$countryCode === "_GL"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "support/where-to-buy/country/gl",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                          )
                        : _vm._e(),
                      _vm.$countryCode === "_EN" ||
                      _vm.$countryCode === "_FR" ||
                      _vm.$countryCode === "_DE" ||
                      _vm.$countryCode === "_IT" ||
                      _vm.$countryCode === "_ES"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "support/where-to-buy/country/en",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                          )
                        : _vm._e(),
                      _vm.$countryCode === "_UK"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "support/where-to-buy/country/uk",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                          )
                        : _vm._e(),
                    ]),
                    _vm.$countryCode === "_US"
                      ? _c("li", [
                          _vm.isMicrosite
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.lxhausys.com/us/support/sales-representative",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("footer.find_a_sales_rep"))
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/us/support/sales-representative",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("footer.find_a_sales_rep"))
                                  ),
                                ]
                              ),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_GL"
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/gl/support/sales-representative",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.find_a_sales_rep")))]
                          ),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_EN" ||
                    _vm.$countryCode === "_FR" ||
                    _vm.$countryCode === "_DE" ||
                    _vm.$countryCode === "_IT" ||
                    _vm.$countryCode === "_ES" ||
                    _vm.$countryCode === "_UK"
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "support/sales-representative/continenteu/hflor",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.find_a_sales_rep")))]
                          ),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_US"
                      ? _c("li", [
                          _vm.isMicrosite
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.lxhausys.com/us/etc/sitemap",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.site_map")))]
                              )
                            : _c("a", { attrs: { href: "/us/etc/sitemap" } }, [
                                _vm._v(_vm._s(_vm.$t("footer.site_map"))),
                              ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_GL"
                      ? _c("li", [
                          _c("a", { attrs: { href: "/gl/etc/sitemap" } }, [
                            _vm._v(_vm._s(_vm.$t("footer.site_map"))),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "com-03 conct-us" }, [
                  _c("div", { staticClass: "heading-2 footer-tit" }, [
                    _vm._v("FOLLOW US"),
                  ]),
                  _c("div", { staticClass: "cnctus" }, [
                    _vm.$countryCode === "_US"
                      ? _c("div", { staticClass: "cnctus01" }, [
                          _c("ul", [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link insta_link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickInstaLink()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_insta" },
                                    [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "footer_sns_pop" }, [
                                _c("ul", { staticClass: "sns" }, [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "sns_link",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoUrl(
                                              "https://www.instagram.com/hflorusa/",
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v("hflor"),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "sns_link",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoUrl(
                                              "https://www.instagram.com/viaterausa/",
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v("viatera"),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "sns_link",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoUrl(
                                              "https://www.instagram.com/himacsusa/",
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v("himacs"),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "sns_link",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoUrl(
                                              "https://www.instagram.com/teracantousa/",
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v("teracanto"),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://pin.it/6iWoROd8r ",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_pinter" },
                                    [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.youtube.com/@LXHausysUSA",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_youtube" },
                                    [_vm._v(_vm._s(_vm.$t("footer.youtube")))]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.linkedin.com/company/lxhausysusa/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_link" },
                                    [_vm._v("linkedin")]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_GL"
                      ? _c("div", { staticClass: "cnctus01" }, [
                          _c("ul", [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link insta_link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.instagram.com/lxhausys_global/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_insta" },
                                    [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://kr.pinterest.com/LX_Hausys/_created/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_pinter" },
                                    [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.youtube.com/@lxhausysglobal",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_youtube" },
                                    [_vm._v(_vm._s(_vm.$t("footer.youtube")))]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.facebook.com/LXHausys",
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon icon_facebook" },
                                    [_vm._v("facebook")]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_EN" ||
                    _vm.$countryCode === "_FR" ||
                    _vm.$countryCode === "_DE" ||
                    _vm.$countryCode === "_IT" ||
                    _vm.$countryCode === "_ES"
                      ? _c("div", { staticClass: "cnctus01" }, [
                          _c("div", { staticClass: "heading-3" }, [
                            _vm._v(_vm._s(_vm.$t("footer.himacs"))),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-i",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.instagram.com/himacseurope/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-f",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.facebook.com/HIMACS",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-in",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.linkedin.com/company/himacs",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.linkedin")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-t",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://twitter.com/himacseurope",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.twitter")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-p",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "http://www.pinterest.com/HIMACS",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-y",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.youtube.com/himacseurope",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.youtube")))]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_EN" ||
                    _vm.$countryCode === "_FR" ||
                    _vm.$countryCode === "_DE" ||
                    _vm.$countryCode === "_IT" ||
                    _vm.$countryCode === "_ES"
                      ? _c("div", { staticClass: "cnctus02" }, [
                          _c("div", { staticClass: "heading-3" }, [
                            _vm._v(_vm._s(_vm.$t("footer.hflor"))),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-i",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.instagram.com/hfloreurope/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-f",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.facebook.com/HFLOR-Europe-102610215614027",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-in",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.linkedin.com/company/80914597/admin/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.linkedin")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-p",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.pinterest.de/hfloreurope/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_UK"
                      ? _c("div", { staticClass: "cnctus01" }, [
                          _c("div", { staticClass: "heading-3" }, [
                            _vm._v(_vm._s(_vm.$t("footer.himacs"))),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-i",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.instagram.com/himacseurope/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-f",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.facebook.com/HIMACS",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-in",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.linkedin.com/company/himacs",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.linkedin")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-t",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://twitter.com/himacseurope",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.twitter")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-p",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "http://www.pinterest.com/HIMACS",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-y",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.youtube.com/himacseurope",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.youtube")))]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_UK"
                      ? _c("div", { staticClass: "cnctus02" }, [
                          _c("div", { staticClass: "heading-3" }, [
                            _vm._v(_vm._s(_vm.$t("footer.hflor"))),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-i",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.instagram.com/hflor.uk/",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "ico-f",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoUrl(
                                        "https://www.facebook.com/HFLOR-UK-105446461990804",
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "com-05 display-eu" }, [
                  _c("div", { staticClass: "heading-2 footer-tit" }, [
                    _vm._v(" " + _vm._s(_vm.$t("footer.policy")) + " "),
                  ]),
                  _c("ul", [
                    _c("li", [
                      _c("a", { attrs: { href: "etc/privacyPolicy" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("footer.privacy_policy")) + " "
                        ),
                      ]),
                    ]),
                    _vm.$countryCode !== "_UK"
                      ? _c("li", [
                          _c("a", { attrs: { href: "etc/imprint" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("footer.imprint")) + " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.$countryCode === "_UK"
                      ? _c("li", [
                          _c("a", { attrs: { href: "etc/slaveStmt" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("footer.slave_stmt")) + " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("a", { attrs: { href: "etc/cookiePolicy" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("footer.cookie_policy")) + " "
                        ),
                      ]),
                    ]),
                    _vm.$countryCode !== "_UK"
                      ? _c("li", [
                          _c(
                            "a",
                            { attrs: { href: "etc/rightOfDataRqstForm" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("footer.right_of_the_data_subject")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "newsletter_box" }, [
                _c(
                  "label",
                  {
                    staticClass: "heading-2 footer-tit",
                    attrs: { for: "inpNews" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("footer.sign_up_newsletter")) + " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "news" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.joinMail,
                        expression: "joinMail",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "inpNews",
                      placeholder: "Enter your email",
                    },
                    domProps: { value: _vm.joinMail },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.openNewsletterPopup()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.joinMail = $event.target.value
                      },
                    },
                  }),
                  _vm.$countryCode === "_US" || _vm.$countryCode === "_GL"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn_newsletter",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.openNewsletterPopup()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("footer.join")))]
                      )
                    : _vm._e(),
                  _vm.$countryCode === "_EN" ||
                  _vm.$countryCode === "_FR" ||
                  _vm.$countryCode === "_DE" ||
                  _vm.$countryCode === "_IT" ||
                  _vm.$countryCode === "_ES" ||
                  _vm.$countryCode === "_UK"
                    ? _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.openNewsletterPopupEu(
                                "layer-newsletter-form"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("footer.join")))]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "cs-box" }, [
                _c("div", { staticClass: "heading-2" }, [
                  _vm._v(_vm._s(_vm.$t("footer.customer_support"))),
                ]),
                _c("p", [
                  _c(
                    "a",
                    { attrs: { href: "mailto: websupport@lxhausys.com" } },
                    [_vm._v(_vm._s(_vm.$t("footer.websupport_email")))]
                  ),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("footer.websupport_phone"))),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer-copy" }, [
            _c("div", { staticClass: "fcopy-wrap" }, [
              _c("ul", [
                _vm.$countryCode === "_US"
                  ? _c("li", [
                      _c("a", { attrs: { href: "etc/donotsell" } }, [
                        _vm._v("Do Not Sell My Personal Information"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.$countryCode === "_US" || _vm.$countryCode === "_GL"
                  ? _c("li", [
                      _c("a", { attrs: { href: "etc/privacy" } }, [
                        _vm._v(_vm._s(_vm.$t("footer.privacy"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm.$countryCode === "_US" || _vm.$countryCode === "_GL"
                  ? _c("li", [
                      _c("a", { attrs: { href: "etc/terms" } }, [
                        _vm._v(_vm._s(_vm.$t("footer.terms"))),
                      ]),
                    ])
                  : _vm._e(),
                _c("li", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cookieSettings()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cookie.cookie_setting")))]
                  ),
                ]),
                _vm.$countryCode === "_US"
                  ? _c("li", [
                      _c(
                        "a",
                        { attrs: { href: "etc/rendering-imagedisclaimer" } },
                        [_vm._v("DISCLAIMER")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "p",
                {
                  staticClass: "copyright",
                  class: { uscopyright: _vm.$countryCode === "_US" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("footer.copyright")) + " ")]
              ),
            ]),
          ]),
          _c("iframe", {
            staticStyle: { display: "none" },
            attrs: { name: "ifrm", width: "0", height: "0", frameborder: "0" },
          }),
        ]
      ),
      _c("div", { staticClass: "mo-view" }, [
        _c("div", { staticClass: "logo-box" }, [
          _c("div", { staticClass: "logo" }, [
            _vm._v(_vm._s(_vm.$t("footer.lx_hausys"))),
          ]),
        ]),
        _c("div", { staticClass: "doc-box" }, [
          _c("div", { staticClass: "fb-box" }, [
            _c("div", { staticClass: "drop-list" }, [
              _c("ul", [
                _c("li", [
                  _c("div", { staticClass: "tit" }, [
                    _c(
                      "a",
                      {
                        staticClass: "foot-tab",
                        attrs: { href: "javascript:void(0);" },
                      },
                      [_vm._v("ABOUT US")]
                    ),
                    _c(
                      "button",
                      { staticClass: "btn-view", attrs: { type: "button" } },
                      [_vm._v(_vm._s(_vm.$t("footer.list_view")))]
                    ),
                  ]),
                  _c("div", { staticClass: "cont" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          { attrs: { href: "aboutus/overview/business-area" } },
                          [_vm._v(_vm._s(_vm.$t("footer.about_us")))]
                        ),
                      ]),
                      _vm.$countryCode === "_US"
                        ? _c("li", [
                            _c("a", { attrs: { href: "aboutus/career" } }, [
                              _vm._v(_vm._s(_vm.$t("footer.careers"))),
                            ]),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "aboutus/sustainability/sustainability-management",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("footer.sustainability")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("div", { staticClass: "tit" }, [
                    _c(
                      "a",
                      {
                        staticClass: "foot-tab",
                        attrs: { href: "javascript:void(0);" },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.help")))]
                    ),
                    _c(
                      "button",
                      { staticClass: "btn-view", attrs: { type: "button" } },
                      [_vm._v(_vm._s(_vm.$t("footer.list_view")))]
                    ),
                  ]),
                  _c("div", { staticClass: "cont" }, [
                    _c("ul", [
                      _vm.$countryCode === "_GL" || _vm.$countryCode === "_US"
                        ? _c("li", [
                            _c("a", { attrs: { href: "support/contactus" } }, [
                              _vm._v(_vm._s(_vm.$t("footer.contact_us"))),
                            ]),
                          ])
                        : _c("li", [
                            _c(
                              "a",
                              { attrs: { href: "support/contactus/pro" } },
                              [_vm._v(_vm._s(_vm.$t("footer.contact_us")))]
                            ),
                          ]),
                      _vm.$countryCode === "_EN" ||
                      _vm.$countryCode === "_FR" ||
                      _vm.$countryCode === "_DE" ||
                      _vm.$countryCode === "_IT" ||
                      _vm.$countryCode === "_ES" ||
                      _vm.$countryCode === "_UK"
                        ? _c("li", [
                            _c("a", { attrs: { href: "etc/custSupport" } }, [
                              _vm._v(_vm._s(_vm.$t("footer.cust_support"))),
                            ]),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c(
                          "a",
                          { attrs: { href: "support/faq/all-products" } },
                          [_vm._v(_vm._s(_vm.$t("footer.faq")))]
                        ),
                      ]),
                      _c("li", [
                        _vm.$countryCode === "_US"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: "support/where-to-buy/country/us",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                            )
                          : _vm._e(),
                        _vm.$countryCode === "_GL"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: "support/where-to-buy/country/gl",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                            )
                          : _vm._e(),
                        _vm.$countryCode === "_EN" ||
                        _vm.$countryCode === "_FR" ||
                        _vm.$countryCode === "_DE" ||
                        _vm.$countryCode === "_IT" ||
                        _vm.$countryCode === "_ES"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: "support/where-to-buy/country/en",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                            )
                          : _vm._e(),
                        _vm.$countryCode === "_UK"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: "support/where-to-buy/country/uk",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("footer.where_to_buy")))]
                            )
                          : _vm._e(),
                      ]),
                      _vm.$countryCode === "_US"
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/us/support/sales-representative",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("footer.find_a_sales_rep"))
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.$countryCode === "_GL"
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/gl/support/sales-representative",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("footer.find_a_sales_representative")
                                  )
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.$countryCode === "_EN" ||
                      _vm.$countryCode === "_FR" ||
                      _vm.$countryCode === "_DE" ||
                      _vm.$countryCode === "_IT" ||
                      _vm.$countryCode === "_ES" ||
                      _vm.$countryCode === "_UK"
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/us/support/sales-representative",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("footer.find_a_sales_rep"))
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("div", { staticClass: "tit" }, [
                    _c(
                      "a",
                      {
                        staticClass: "foot-tab",
                        attrs: { href: "javascript:void(0);" },
                      },
                      [_vm._v("FOLLOW US")]
                    ),
                    _c(
                      "button",
                      { staticClass: "btn-view", attrs: { type: "button" } },
                      [_vm._v(_vm._s(_vm.$t("footer.list_view")))]
                    ),
                  ]),
                  _c("div", { staticClass: "footer_sns_pop" }, [
                    _c("ul", { staticClass: "sns" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "sns_link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl(
                                  "https://www.instagram.com/lxhausys_global/",
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("hflor"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "sns_link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl(
                                  "https://www.instagram.com/lxhausys_global/",
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("viatera"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "sns_link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl(
                                  "https://www.instagram.com/lxhausys_global/",
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("himacs"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "sns_link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl(
                                  "https://www.instagram.com/lxhausys_global/",
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("teracanto"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "cont follow_us_con" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "sns_item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link insta_link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.clickInstaLink()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "icon icon_insta" }, [
                              _vm._v(_vm._s(_vm.$t("footer.instagram"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "sns_item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl(
                                  "https://www.pinterest.co.kr/LX_Hausys/_created/ ",
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "icon icon_pinter" }, [
                              _vm._v(_vm._s(_vm.$t("footer.pinterest"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "sns_item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl(
                                  "https://www.youtube.com/channel/UC1BQZepu44PYt1qu7eoaF-w",
                                  true
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "icon icon_youtube" }, [
                              _vm._v(_vm._s(_vm.$t("footer.youtube"))),
                            ]),
                          ]
                        ),
                      ]),
                      _vm.$countryCode === "_US"
                        ? _c("div", { staticClass: "sns_item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoUrl(
                                      "https://www.houzz.com/hznb/professionals/kitchen-and-bath-designers/hi-macs-usa-pfvwus-pf~1670062394?",
                                      true
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "icon icon_link" }, [
                                  _vm._v("linkedin"),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.$countryCode === "_GL"
                        ? _c("div", { staticClass: "sns_item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoUrl(
                                      "https://www.houzz.com/hznb/professionals/kitchen-and-bath-designers/hi-macs-usa-pfvwus-pf~1670062394?",
                                      true
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "icon icon_facebook" },
                                  [_vm._v("facebook")]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm.$countryCode === "_EN" ||
                _vm.$countryCode === "_FR" ||
                _vm.$countryCode === "_DE" ||
                _vm.$countryCode === "_IT" ||
                _vm.$countryCode === "_ES" ||
                _vm.$countryCode === "_UK"
                  ? _c("li", [
                      _c("div", { staticClass: "tit" }, [
                        _c(
                          "a",
                          {
                            staticClass: "foot-tab",
                            attrs: { href: "javascript:void(0);" },
                          },
                          [_vm._v(_vm._s(_vm.$t("footer.policy")))]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn-view",
                            attrs: { type: "button" },
                          },
                          [_vm._v(_vm._s(_vm.$t("footer.list_view")))]
                        ),
                      ]),
                      _c("div", { staticClass: "cont" }, [
                        _c("ul", [
                          _c("li", [
                            _c(
                              "a",
                              { attrs: { href: "etc.html#/privacyPolicy" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("footer.privacy_policy")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _vm.$countryCode !== "_UK"
                            ? _c("li", [
                                _c(
                                  "a",
                                  { attrs: { href: "etc.html#/imprint" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("footer.imprint")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.$countryCode === "_UK"
                            ? _c("li", [
                                _c(
                                  "a",
                                  { attrs: { href: "etc.html#/slaveStmt" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("footer.slave_stmt")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("li", [
                            _c(
                              "a",
                              { attrs: { href: "etc.html#/cookiePolicy" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("footer.cookie_policy")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _vm.$countryCode !== "_UK"
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "etc.html#/rightOfDataRqstForm",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "footer.right_of_the_data_subject"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("li", [
                  _c("div", { staticClass: "tit" }, [
                    _vm.$countryCode === "_US" || _vm.$countryCode === "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "foot-tab",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.openNewsletterPopup()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("footer.sign_up_newsletter")))]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      { staticClass: "btn_plus", attrs: { type: "button" } },
                      [_vm._v(_vm._s(_vm.$t("footer.list_view")))]
                    ),
                  ]),
                  _vm.$countryCode === "_EN" ||
                  _vm.$countryCode === "_FR" ||
                  _vm.$countryCode === "_DE" ||
                  _vm.$countryCode === "_IT" ||
                  _vm.$countryCode === "_ES" ||
                  _vm.$countryCode === "_UK"
                    ? _c("div", { staticClass: "tit bd-b-1 m-footer-arr" }, [
                        _c(
                          "a",
                          {
                            staticClass: "foot-tab",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.openNewsletterPopupEu(
                                  "layer-newsletter-form"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("footer.sign_up_newsletter")))]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mo-warp inner" }, [
          _vm.$countryCode === "_EN" ||
          _vm.$countryCode === "_FR" ||
          _vm.$countryCode === "_DE" ||
          _vm.$countryCode === "_IT" ||
          _vm.$countryCode === "_ES"
            ? _c("div", { staticClass: "cnctus" }, [
                _c("p", { staticClass: "heading-5" }, [
                  _vm._v(_vm._s(_vm.$t("footer.himacs"))),
                ]),
                _c("ul", { staticClass: "foot-ul" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-i",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.instagram.com/himacseurope/",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.instagram")) + " x")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-f",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.facebook.com/HIMACS",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-in",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.linkedin.com/company/himacs",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.linkedin")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-t",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://twitter.com/himacseurope",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.twitter")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-p",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "http://www.pinterest.com/HIMACS",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-ytb",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.youtube.com/himacseurope",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.ytb")))]
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "heading-5" }, [
                  _vm._v(_vm._s(_vm.$t("footer.hflor"))),
                ]),
                _c("ul", { staticClass: "foot-ul" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-i",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.instagram.com/hfloreurope/",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-f",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.facebook.com/HFLOR-Europe-102610215614027",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-in",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.linkedin.com/company/80914597/admin/",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.linkedin")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-p",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.pinterest.de/hfloreurope/",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.$countryCode === "_UK"
            ? _c("div", { staticClass: "cnctus" }, [
                _c("p", { staticClass: "heading-5" }, [
                  _vm._v(_vm._s(_vm.$t("footer.himacs"))),
                ]),
                _c("ul", { staticClass: "foot-ul" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-i",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.instagram.com/himacseurope/",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.instagram")) + " x")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-f",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.facebook.com/HIMACS",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-in",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.linkedin.com/company/himacs",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.linkedin")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-t",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://twitter.com/himacseurope",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.twitter")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-p",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "http://www.pinterest.com/HIMACS",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.pinterest")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-ytb",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.youtube.com/himacseurope",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.ytb")))]
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "heading-5" }, [
                  _vm._v(_vm._s(_vm.$t("footer.hflor"))),
                ]),
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-i",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.instagram.com/hflor.uk/",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.instagram")))]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "ico-f",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoUrl(
                              "https://www.facebook.com/HFLOR-UK-105446461990804",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("footer.facebook")))]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.$countryCode === "_US"
            ? _c(
                "div",
                {
                  staticClass: "policy",
                  class: { uspolicy: _vm.$countryCode === "_US" },
                },
                [
                  _vm._m(0),
                  _c("span", { staticClass: "privacy" }, [
                    _c("a", { attrs: { href: "etc/privacy" } }, [
                      _vm._v(_vm._s(_vm.$t("footer.privacy"))),
                    ]),
                  ]),
                  _c("span", { staticClass: "clear-line" }),
                  _c("span", { staticClass: "terms" }, [
                    _c("a", { attrs: { href: "etc/terms" } }, [
                      _vm._v(_vm._s(_vm.$t("footer.terms"))),
                    ]),
                  ]),
                  _c("span", { staticClass: "cookie" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.cookieSettings()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("cookie.cookie_setting")))]
                    ),
                  ]),
                  _vm._m(1),
                ]
              )
            : _vm._e(),
          _vm.$countryCode === "_GL"
            ? _c("div", { staticClass: "policy" }, [
                _c("span", { staticClass: "privacy" }, [
                  _c("a", { attrs: { href: "etc/privacy" } }, [
                    _vm._v(_vm._s(_vm.$t("footer.privacy"))),
                  ]),
                ]),
                _c("span", {}, [
                  _c("a", { attrs: { href: "etc/terms" } }, [
                    _vm._v(_vm._s(_vm.$t("footer.terms"))),
                  ]),
                ]),
                _c("span", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cookieSettings()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cookie.cookie_setting")))]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("p", { staticClass: "copyright" }, [
            _vm._v(" " + _vm._s(_vm.$t("footer.copyright")) + " "),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "layer-popup pop-footer-news popNewsletter_RES" },
        [
          _c("div", { staticClass: "popup" }, [
            _c("div", { staticClass: "scr-y" }, [
              _c("div", { staticClass: "tit-box" }, [
                _c("div", { staticClass: "heading-4" }, [
                  _vm._v(_vm._s(_vm.$t("footer.newsletter"))),
                ]),
              ]),
              _c("div", { staticClass: "cont-box ta-c" }, [
                _c("strong", { staticClass: "ico-news-res" }, [
                  _vm._v(_vm._s(_vm.$t("footer.thank_you_for_subscribing1"))),
                ]),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("footer.thank_you_for_subscribing2")
                    ),
                  },
                }),
              ]),
              _c("div", { staticClass: "more-box ta-c" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-type-large",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.closePopup()
                        _vm.gotoUrl("index", false)
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("footer.visit_lx_hausys"))),
                    ]),
                  ]
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-close",
                  attrs: { type: "button", onclick: "LayerPopup.close();" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("footer.close")) + " ")]
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "layer-popup pop-footer-news popNewsletter" }, [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "scr-y" }, [
            _c("div", { staticClass: "tit-box" }, [
              _c("p", { staticClass: "pop_title tt-24" }, [
                _vm._v(_vm._s(_vm.$t("footer.newsletter"))),
              ]),
            ]),
            _c("div", { staticClass: "frm-box" }, [
              _c(
                "form",
                {
                  staticClass: "elq-form",
                  attrs: {
                    method: "post",
                    name: _vm.form.eloqua.name,
                    action: "https://s1110900.t.eloqua.com/e/f2",
                    id: "form68",
                  },
                },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: "elqFormName" },
                    domProps: { value: _vm.form.eloqua.elqFormName },
                  }),
                  _c("input", {
                    attrs: {
                      value: "1110900",
                      type: "hidden",
                      name: "elqSiteId",
                    },
                  }),
                  _c("input", {
                    attrs: { name: "elqCampaignId", type: "hidden" },
                  }),
                  _c("div", { staticClass: "tbl-input" }, [
                    _c("table", [
                      _c("caption", [
                        _vm._v(" " + _vm._s(_vm.$t("footer.order_form")) + " "),
                      ]),
                      _vm._m(2),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _c(
                              "label",
                              { staticClass: "must", attrs: { for: "inp09" } },
                              [_vm._v(_vm._s(_vm.$t("footer.email")))]
                            ),
                          ]),
                          _c("td", { staticClass: "cell-noti" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.eloqua.email,
                                  expression: "form.eloqua.email",
                                },
                              ],
                              staticClass: "inp-text",
                              attrs: {
                                type: "text",
                                id: "fe743",
                                name: "emailAddress",
                                placeholder: "Input Your Email",
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.form.eloqua.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form.eloqua,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.form.execValidFlag &&
                            !_vm.validation.email.value &&
                            !_vm.emailRule.test(_vm.form.eloqua.email)
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("footer.email_required")) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _c("span", { staticClass: "must" }, [
                              _vm._v(_vm._s(_vm.$t("footer.country"))),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell-noti" }, [
                            _c(
                              "div",
                              {
                                staticClass: "sel-box type02",
                                attrs: { id: "countryBox" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.eloqua.country,
                                      expression: "form.eloqua.country",
                                    },
                                  ],
                                  attrs: {
                                    type: "hidden",
                                    id: "fe744",
                                    name: _vm.form.eloqua.countryName,
                                  },
                                  domProps: { value: _vm.form.eloqua.country },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form.eloqua,
                                        "country",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: { id: "btnCountry", type: "button" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.form.selectedCountry)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sel-list",
                                    attrs: { id: "countryList" },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "box" },
                                      _vm._l(
                                        _vm.form.countries,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            { key: item.countryNm },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectCountry(
                                                        item.countryNm
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.countryNm) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.form.execValidFlag &&
                            !_vm.validation.country.value &&
                            _vm.form.eloqua.country == ""
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("footer.please_select_country")
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _c("span", { staticClass: "must" }, [
                              _vm._v(_vm._s(_vm.$t("footer.iam"))),
                            ]),
                          ]),
                          _c("td", { staticClass: "cell-noti" }, [
                            _c(
                              "div",
                              {
                                staticClass: "sel-box type02",
                                attrs: { id: "occBox" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.eloqua.occupation,
                                      expression: "form.eloqua.occupation",
                                    },
                                  ],
                                  attrs: {
                                    type: "hidden",
                                    id: "fe746",
                                    name: _vm.form.eloqua.occuName,
                                  },
                                  domProps: {
                                    value: _vm.form.eloqua.occupation,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form.eloqua,
                                        "occupation",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: { id: "btnOcc", type: "button" },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.form.selectedOccupation
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sel-list",
                                    attrs: { id: "occList" },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "box" },
                                      _vm._l(
                                        _vm.form.occupations,
                                        function (item, index) {
                                          return _c("li", { key: item.val }, [
                                            _c("button", {
                                              attrs: { type: "button" },
                                              domProps: {
                                                innerHTML: _vm._s(item.name),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectOccupation(
                                                    item.name
                                                  )
                                                },
                                              },
                                            }),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.form.execValidFlag &&
                            !_vm.validation.occupation.value &&
                            _vm.form.eloqua.occupation == ""
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "footer.please_select_occupation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("tr", { staticClass: "news-tr-interest" }, [
                          _c("th", { attrs: { scope: "row" } }, [
                            _c(
                              "label",
                              { staticClass: "must", attrs: { for: "inp10" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("footer.products")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "cell-noti" }, [
                            _c("ul", { staticClass: "prod-interest" }, [
                              _vm.$countryCode === "_US"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-3_puvm05n",
                                          value: "HIMACS",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "HIMACS"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "HIMACS",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.himacs2"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_US"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-20_9rbu3hf4",
                                          value: "Viatera",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Viatera"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Viatera",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.viatera3"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_US"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-21_d4xvqbo",
                                          value: "HFLOR",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "HFLOR"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "HFLOR",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("footer.hflor2"))),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_US"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-TERACANTO_giypwupk",
                                          value: "TERACANTO",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "TERACANTO"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "TERACANTO",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.teracanto"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-3_puvm05n",
                                          value: "HIMACS",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "HIMACS"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "HIMACS",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.himacs2"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-20_9rbu3hf4",
                                          value: "Viatera",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Viatera"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Viatera",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.viatera3"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-21_d4xvqbo",
                                          value: "HFLOR",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "HFLOR"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "HFLOR",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("footer.hflor2"))),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-TERACANTO_wofq8iam",
                                          value: "TERACANTO",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "TERACANTO"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "TERACANTO",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.teracanto"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-BENIF_46o16bqq",
                                          value: "BENIF",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "BENIF"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "BENIF",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("footer.benif"))),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-101_uodyayoq",
                                          value: "Deco Foil",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Deco Foil"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Deco Foil",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.deco_foil"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-102_nhhfa3ye",
                                          value: "Exterior Foil",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Exterior Foil"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Exterior Foil",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.exterior_foil"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-103_emupuoh",
                                          value: "Sign & Graphic",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Sign & Graphic"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Sign & Graphic",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("footer.vizuon"))),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-139_r1v7h47a",
                                          value: "Vinyl Coated Material",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Vinyl Coated Material"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Vinyl Coated Material",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "footer.vinyl_coated_material"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-19_09gvgi2s",
                                          value: "Automotive",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "Automotive"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "Automotive",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.automotive"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.$countryCode === "_GL"
                                ? _c("li", [
                                    _c("label", { staticClass: "inp-chk" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.eloqua.interest,
                                            expression: "form.eloqua.interest",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-All Brands_l3qie7mg",
                                          value: "All Brands",
                                          name: _vm.form.eloqua.interestName,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.form.eloqua.interest
                                          )
                                            ? _vm._i(
                                                _vm.form.eloqua.interest,
                                                "All Brands"
                                              ) > -1
                                            : _vm.form.eloqua.interest,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.form.eloqua.interest,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "All Brands",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.form.eloqua,
                                                    "interest",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.form.eloqua,
                                                "interest",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("footer.all_brands"))
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm.form.execValidFlag &&
                            !_vm.validation.interest.value &&
                            _vm.form.eloqua.interest.length === 0
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("footer.plz_sel_prd")) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.$countryCode === "_GL"
                    ? _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "source",
                          id: "fe1038",
                          value: "LXHausys.com",
                        },
                      })
                    : _vm._e(),
                  _c("div", [
                    _c("label", { staticClass: "inp-chk news-agree" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.agree,
                            expression: "form.agree",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          name: _vm.form.eloqua.prvcName,
                        },
                        domProps: {
                          checked: Array.isArray(_vm.form.agree)
                            ? _vm._i(_vm.form.agree, null) > -1
                            : _vm.form.agree,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.form.agree,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(_vm.form, "agree", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "agree",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "agree", $$c)
                            }
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("footer.yes_agree_terms")) + " "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.openPrivacy()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("footer.privacy_policy")) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(". "),
                      ]),
                    ]),
                    _vm.form.execValidFlag &&
                    !_vm.validation.agree.value &&
                    _vm.form.agree == ""
                      ? _c("p", { staticClass: "noti-02" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("footer.please_check_agreement")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "more-box" }, [
              _c(
                "button",
                {
                  staticClass: "button h55 btn_submit",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "txt" }, [
                    _vm._v(_vm._s(_vm.$t("footer.submit"))),
                  ]),
                ]
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closePopup()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("footer.close")) + " ")]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "layer-view popup-v2 popup-v2-w1280 layer-newsletter-ad",
        },
        [
          _c("div", { staticClass: "popup pop-close" }, [
            _c("div", { staticClass: "scr-y" }, [
              _c("div", { staticClass: "col-02" }, [
                _c("div", { staticClass: "tac" }, [
                  _c("div", { staticClass: "popup-title footer-pop-tit" }, [
                    _vm._v(_vm._s(_vm.$t("footer.newsletter_009"))),
                  ]),
                  _c("div", { staticClass: "popup-body" }, [
                    _c("p", { staticClass: "ctt ctt-t2" }, [
                      _c("span", { staticClass: "ctt-t2" }, [
                        _vm._v(_vm._s(_vm.$t("footer.newsletter_010")) + " "),
                      ]),
                      _c("span", { staticClass: "ctt-t2" }, [
                        _vm._v(_vm._s(_vm.$t("footer.newsletter_011"))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "popup-footer" }, [
                    _c("div", { staticClass: "frow" }, [
                      _c("div", { staticClass: "fcol" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.clickToReg()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("footer.newsletter_012"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._m(3),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeNewsletterAdPopup()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.close")))]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "layer-view layer-newsletter-form popup-v2 popup-v2-w740",
        },
        [
          _c("div", { staticClass: "popup pop-close" }, [
            _c("div", { staticClass: "scr-y" }, [
              _c("div", { staticClass: "popup-head" }, [
                _c("div", { staticClass: "tt-2" }, [
                  _vm._v(_vm._s(_vm.$t("footer.newsletter"))),
                ]),
                _c("p", { staticClass: "ctt ctt-t4" }, [
                  _vm._v(_vm._s(_vm.$t("footer.newsletter_001"))),
                ]),
              ]),
              _c("div", { staticClass: "popup-body" }, [
                _c("div", { staticClass: "frow" }, [
                  _c("div", { staticClass: "fcol" }, [
                    _c("div", { staticClass: "email-area" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _vm._m(4),
                        _c("div", { staticClass: "inp-control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.eu.email,
                                expression: "form.eu.email",
                              },
                            ],
                            staticClass: "inp-text",
                            attrs: {
                              type: "text",
                              id: "x1",
                              placeholder: "Inputlxinfo@lxhausys.com",
                            },
                            domProps: { value: _vm.form.eu.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form.eu,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "alert",
                              class: {
                                on:
                                  _vm.form.execValidFlag &&
                                  !_vm.validationEu.email.value &&
                                  !_vm.emailRule.test(_vm.form.eu.email),
                              },
                            },
                            [_vm._v("A valid email address is required")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "interest-area" }, [
                      _c("div", { staticClass: "input-group input-chk" }, [
                        _c(
                          "label",
                          { staticClass: "required", attrs: { for: "x2" } },
                          [
                            _c("span", { staticClass: "news-con-tit-pd" }, [
                              _vm._v(_vm._s(_vm.$t("footer.products"))),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "inp-control" }, [
                          _c("label", { staticClass: "inp-chk" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.eu.newsletterHflor,
                                  expression: "form.eu.newsletterHflor",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.form.eu.newsletterHflor
                                )
                                  ? _vm._i(_vm.form.eu.newsletterHflor, null) >
                                    -1
                                  : _vm.form.eu.newsletterHflor,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.form.eu.newsletterHflor,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.form.eu,
                                          "newsletterHflor",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.form.eu,
                                          "newsletterHflor",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.form.eu,
                                      "newsletterHflor",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span", [_vm._v("HFLOR")]),
                          ]),
                          _c("label", { staticClass: "inp-chk" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.eu.newsletterHimacs,
                                  expression: "form.eu.newsletterHimacs",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.form.eu.newsletterHimacs
                                )
                                  ? _vm._i(_vm.form.eu.newsletterHimacs, null) >
                                    -1
                                  : _vm.form.eu.newsletterHimacs,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.form.eu.newsletterHimacs,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.form.eu,
                                          "newsletterHimacs",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.form.eu,
                                          "newsletterHimacs",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.form.eu,
                                      "newsletterHimacs",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span", [_vm._v("HIMACS")]),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "alert",
                              class: {
                                on:
                                  _vm.form.execValidFlag &&
                                  !_vm.validationEu.interest.value &&
                                  _vm.form.eu.newsletterHflor == 0 &&
                                  _vm.form.eu.newsletterHimacs == 0,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("footer.plz_sel_prd")))]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "agree-box" }, [
                      _c("label", { staticClass: "inp-chk" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.agree,
                              expression: "form.agree",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.agree)
                              ? _vm._i(_vm.form.agree, null) > -1
                              : _vm.form.agree,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.agree,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "agree",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "agree",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "agree", $$c)
                              }
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "ctt ctt-t4",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("footer.newsletter_002")),
                          },
                        }),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "alert",
                          class: {
                            on:
                              _vm.form.execValidFlag &&
                              !_vm.validationEu.agree.value &&
                              _vm.form.agree == "",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("footer.please_check_agreement"))
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "more-detail" }, [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("footer.newsletter_003")),
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "popup-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submitFormEu()
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("footer.submit")))])]
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closePopupEu()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.close")))]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "layer-view layer-newsletter-result popup-v2 popup-v2-w740",
        },
        [
          _c("div", { staticClass: "popup pop-close" }, [
            _c("div", { staticClass: "scr-y" }, [
              _c("div", { staticClass: "popup-head" }, [
                _c("div", { staticClass: "tt-2" }, [
                  _vm._v(_vm._s(_vm.$t("footer.newsletter"))),
                ]),
              ]),
              _c("div", { staticClass: "popup-body" }, [
                _c("div", { staticClass: "frow" }, [
                  _c("div", { staticClass: "fcol tac" }, [
                    _c("i", { staticClass: "pcon pcon-check" }),
                    _c("p", { staticClass: "ctt ctt-t1" }, [
                      _vm._v(_vm._s(_vm.$t("footer.newsletter_006"))),
                    ]),
                    _c("p", { staticClass: "pb-des" }, [
                      _c("span", { staticClass: "tac ctt ctt-t1" }, [
                        _vm._v(_vm._s(_vm.$t("footer.newsletter_007"))),
                      ]),
                      _c("span", { staticClass: "ctt ctt-t5" }, [
                        _vm._v(_vm._s(_vm.$t("footer.newsletter_007_1"))),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "popup-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoHome()
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("footer.newsletter_008"))),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn-close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closePopupEu()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.close")))]
            ),
          ]),
        ]
      ),
      _c("SampleOrderPopComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "donotsell" }, [
      _c("a", { attrs: { href: "etc/donotsell" } }, [
        _vm._v("Do Not Sell My Personal Information"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "disclaimer noline" }, [
      _c("a", { attrs: { href: "etc/rendering-imagedisclaimer" } }, [
        _vm._v("DISCLAIMER"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "210px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "newsletter-bg" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/public/images/img-newsletter.png",
          alt: "newsletter background",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "required", attrs: { for: "x1" } }, [
      _c("span", [_vm._v("Email")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }