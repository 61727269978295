<template>
  <SeatCorverLayout
    class="mt-50"
    imageSrc="images/automotive/skin/Calfpelle.png"
  >
    <template #text>
      <div class="title">
        <span>Calfpelle®</span>
      </div>
      <div class="description">
        <span>• Suede with excellent surface quality</span>
        <br />
        <span>• Soft haptic and superior durability</span>
      </div>

      <div class="title">
        <span>Calfpelle® Bio</span>
      </div>
      <div class="description">
        <span>• Environmental Claim Validation of UL-Solutions for biobased content</span>
        <br />
        <span>• Bio-based content ≥ 10%</span>
      </div>
    </template>
  </SeatCorverLayout>
</template>

<script>
import SeatCorverLayout from './SeatCorverLayout.vue'

export default {
  components: {
    SeatCorverLayout
  }
}
</script>
