var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "social-responsibility-border-title-area" }, [
    _c(
      "div",
      {
        staticClass: "social-responsibility-border-title",
        style: { padding: _vm.padding },
      },
      [_c("pre", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }