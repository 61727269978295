var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "overview-section-item-area",
      style: { "margin-top": _vm.marginTop },
    },
    [
      _vm.title
        ? _c("div", { staticClass: "title-area" }, [
            _c("pre", [_vm._v(_vm._s(_vm.title))]),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }