/* ============
 * Getters for the search module
 * ============
 *
 * The getters that are available on the
 * search module.
 */

export default {
  getRecentSearch (state) {
    let searchWord
    try {
      searchWord = JSON.parse(localStorage.getItem('searchWord'))
    } catch (e) {
      console.log(e)
    }
    return searchWord || []
  }
}
