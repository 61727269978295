import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { $utils } from '@/common/helper'

Vue.use(VueI18n)

const files1 = require.context('.', true, /\.js$/)
const files2 = require.context('../../../common/locales', true, /\.js$/)

const customUS = {}
const customGL = {}
const customEN = {}
const customFR = {}
const customDE = {}
const customIT = {}
const customES = {}
const customUK = {}

const locale = {}

files1.keys().forEach((key) => {
  if (key === './index.js') return
  Object.assign(customUS, files1(key).default.US)
  Object.assign(customGL, files1(key).default.GL)
  Object.assign(customEN, files1(key).default.EN)
  Object.assign(customFR, files1(key).default.FR)
  Object.assign(customDE, files1(key).default.DE)
  Object.assign(customIT, files1(key).default.IT)
  Object.assign(customES, files1(key).default.ES)
  Object.assign(customUK, files1(key).default.UK)
})
files2.keys().forEach((key) => {
  if (key === './index.js') return
  Object.assign(customUS, files2(key).default.US)
  Object.assign(customGL, files2(key).default.GL)
  Object.assign(customEN, files2(key).default.EN)
  Object.assign(customFR, files2(key).default.FR)
  Object.assign(customDE, files2(key).default.DE)
  Object.assign(customIT, files2(key).default.IT)
  Object.assign(customES, files2(key).default.ES)
  Object.assign(customUK, files2(key).default.UK)
})

function addLang (key, a, b) {
  locale[key] = Object.assign(a, b)
}
addLang('US', customUS, {})
addLang('GL', customGL, {})
addLang('EN', customEN, {})
addLang('FR', customFR, {})
addLang('DE', customDE, {})
addLang('IT', customIT, {})
addLang('ES', customES, {})
addLang('UK', customUK, {})

/* inject i18n */
const lang = $utils.getCountryCode()

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || lang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'EN',
  messages: locale
})

// export default locale
