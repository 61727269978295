<template>

<div class="direction-of-strategy-card">
    <div class="txt-area">
        {{ txt }}
    </div>
</div>

</template>

<script>
export default {
  props: {
    txt: {
      type: String,
      default: ''
    }
  }
}

</script>

<style scoped>
.direction-of-strategy-card {
    display: flex;
    justify-content: center;
    width: 1368px;
    height: 116px;
    padding: 45px 0px;
    align-items: flex-start;
    border-radius: 15px;
    border-top: 2px solid #D0CECD;
    background: #F4F4F4;
}
.txt-area {
    color: #333;
    text-align: center;
    font-family: 'NotoSans' , sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
}

@media (max-width: 768px) {
  .direction-of-strategy-card {
    width: 90%;
    height: 100%;
  }

  .txt-area {
    font-size: 1rem;
  }
}

</style>
