<template>
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png"
    title="Market Leading Design"
    titleColor="#222"
    v-if="nationCode === '_US' || nationCode === '_GL'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="text"
        />
      </OverviewSection>
      <ProductDevelopmentBased />
      <AnnualTrendshipSeminar />
      <TrendshipSoftwhere />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png"
    title="マーケットリードデザイン"
    titleColor="#222"
    v-else-if="nationCode === '_JP'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="textJp"
        />
      </OverviewSection>
      <ProductDevelopmentBased />
      <AnnualTrendshipSeminar />
      <TrendshipSoftwhere />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png"
    title="Ведущий дизайн на рынке"
    titleColor="#222"
    v-else-if="nationCode === '_RU'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="textRu"
        />
      </OverviewSection>
      <ProductDevelopmentBased />
      <AnnualTrendshipSeminar />
      <TrendshipSoftwhere />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/r-and-d-center/marketLeadingDesign/top_image.png"
    title="市场领先的设计"
    titleColor="#222"
    v-else-if="nationCode === '_CN'"
  >
    <template #content>
      <OverviewSection>
        <SustainabilityText
          :text="textCn"
        />
      </OverviewSection>
      <ProductDevelopmentBased />
      <AnnualTrendshipSeminar />
      <TrendshipSoftwhere />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import OverviewSection from '../../overview/components/OverviewSection.vue'
import SustainabilityText from '../../sustainability/components/SustainabilityText.vue'
import AnnualTrendshipSeminar from './components/AnnualTrendshipSeminar.vue'
import ProductDevelopmentBased from './components/ProductDevelopmentBased.vue'
import TrendshipSoftwhere from './components/TrendshipSoftwhere.vue'

export default {
  components: {
    OverviewLayout,
    OverviewSection,
    SustainabilityText,
    ProductDevelopmentBased,
    AnnualTrendshipSeminar,
    TrendshipSoftwhere
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'At LX Hausys, we take the customer’s perspective on understanding market trends and designing products that\n' +
            'customers most desire. We are building our own design capabilities in our Design Center, which is the largest in size in\n' +
            'the domestic building & decorative materials industry, presenting globally competitive designs.',
      textJp:'LXハウシスでは、お客様の視点に立って市場動向を把握し、お客様が最も望む製品を設計します。\n' + 
      '国内建築・装飾資材業界最大規模のデザインセンターで独自の設計力を構築し、世界的に競争力のあるデザインを提案します。',
      textRu:'В компании LX Hausys мы учитываем мнение клиента при понимании тенденций рынка и разработке продуктов,\n' + 
      'которые больше всего нравятся покупателям. Мы создаем собственные дизайнерские возможности в нашем дизайн-центре,\n' + 
      'который является самым большим по размеру вв отечественной индустрии строительных и декоративных материалов,\n' + 
      'представляя конкурентоспособные на мировом уровне проекты.',
      textCn :'在LX Hausys，我们从客户的角度了解市场趋势，设计客户最想要的产品。\n' +
      'LX Hausys设计中心是韩国国内建筑和装饰材料行业规模最大的，提供具有全球竞争力的设计。',
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}

$(document).ready(function(){
  $('.location').removeClass('type02');
})

</script>

<style scoped>

</style>
