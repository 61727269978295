var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-cate" }, [
        _c("div", { staticClass: "location type02" }, [
          _c(
            "ul",
            [
              _c(
                "li",
                {
                  directives: [
                    { name: "hover-cursor", rawName: "v-hover-cursor" },
                  ],
                  staticClass: "ico-home",
                  on: {
                    click: function ($event) {
                      _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("PCD.text.home")))]
              ),
              _vm._l(_vm.location, function (loc, idx) {
                return _c("li", { key: idx }, [_vm._v(" " + _vm._s(loc) + " ")])
              }),
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "container prod-dt-view" },
          [
            _c("ProductTopImage", {
              attrs: {
                imageSrc: _vm.imageSrc,
                title: _vm.title,
                titleColor: _vm.titleColor,
              },
            }),
            _vm._t("content"),
          ],
          2
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }