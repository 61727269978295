<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>PATTERNS</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box color_square">
        <span class="checkbox_more no_line">
          <li v-for="pattern in patternList" :key="pattern.id.value">
            <input
              type="checkbox"
              :id="getPatternId(pattern)"
              v-model="selected"
              :value="getPatternValue(pattern)"
              name="Patterns"
            />
            <label :for="getPatternId(pattern)">
              <span></span>
              {{ pattern.text }}
            </label>
          </li>
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'PatternFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['patternList', 'value'],
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    }),
    isHflorPath () {
      return this.$route.path.includes('/hflor/hflor-finder')
    }
  },
  data () {
    return {
      selected: [],
      nationCode: ''
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        const selectedPatterns = newSelected.map(patternId => {
          return this.isHflorPath ? patternId.replace(this.nationCode, '') : patternId;
        }).filter(patternId => patternId !== null);

        if (JSON.stringify(selectedPatterns) !== JSON.stringify(this.value)) {
          this.$emit('input', selectedPatterns);
        }
      },
      deep: true
    },
    value: {
      handler (newValue) {
        if (JSON.stringify(this.selected) !== JSON.stringify(newValue)) {
          this.selected = this.isHflorPath
            ? newValue.map(v => v + this.nationCode)
            : [...newValue];
        }
      },
      deep: true
    }
  },
  created () {
    this.nationCode = this.$countryCode /* 국가 코드 */
  },
  mounted () {
    this.selected = this.isHflorPath
      ? this.value.map(v => v + this.nationCode)
      : [...this.value];

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-pattern-filter', this.removePattern)
    EventBus.$on('update-pattern-filters', this.updatePatterns)

    if (this.$store.state.filterState && this.$store.state.filterState.pattern) {
      this.selected = this.isHflorPath
        ? this.$store.state.filterState.pattern.map(v => v + this.nationCode)
        : [...this.$store.state.filterState.pattern];
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$off('remove-pattern-filter', this.removePattern)
    EventBus.$off('update-pattern-filters', this.updatePatterns)
  },
  methods: {
    getPatternId (pattern) {
      if (this.isHflorPath) {
        return `pattern_${pattern.id.value}${this.nationCode}`
      }
      return `pattern_${pattern.id.value}`
    },
    getPatternValue (pattern) {
      if (this.isHflorPath) {
        return `${pattern.id.value}${this.nationCode}`
      }
      return pattern.id.value
    },
    resetCheckboxes () {
      this.selected = []
    },
    removePattern (patternId) {
      if (this.isHflorPath) {
        this.selected = this.selected.filter(id =>
          id !== patternId + this.nationCode &&
          id.replace(this.nationCode, '') !== patternId
        );
      } else {
        this.selected = this.selected.filter(id => id !== patternId);
      }
    },
    updatePatterns (patterns) {
      if (this.isHflorPath) {
        this.selected = patterns.map(pattern =>
          pattern.endsWith(this.nationCode) ? pattern : pattern + this.nationCode
        );
      } else {
        this.selected = [...patterns];
      }
    }
  }
}
</script>
