var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Eco-Friendly Certification",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "eco-friendly-certification-card-area" },
              _vm._l(_vm.cardDatas, function (item, index) {
                return _c("EcoFriendlyCertificationCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    image: item.image,
                    text: item.text,
                  },
                })
              }),
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.text } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "環境に優しい認証", size: "56px", color: "#222" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "eco-friendly-certification-card-area" },
              _vm._l(_vm.cardDatasJp, function (item, index) {
                return _c("EcoFriendlyCertificationCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    image: item.image,
                    text: item.text,
                  },
                })
              }),
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textJp } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Экологически чистая сертификация",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "eco-friendly-certification-card-area" },
              _vm._l(_vm.cardDatasRu, function (item, index) {
                return _c("EcoFriendlyCertificationCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    image: item.image,
                    text: item.text,
                  },
                })
              }),
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textRu } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "环保认证", size: "56px", color: "#222" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c(
              "div",
              { staticClass: "eco-friendly-certification-card-area" },
              _vm._l(_vm.cardDatasCn, function (item, index) {
                return _c("EcoFriendlyCertificationCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    image: item.image,
                    text: item.text,
                  },
                })
              }),
              1
            ),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textCn } })],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }