<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('home')}}</li>
          <li>{{ $t('search_results')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container  -->
      <div class="container search-result">
        <div class="sch-res-wrap" v-if="searchResult">
          <div class="w-con-02">
            <div class="sch-top">
              <div class="sch-frm">
                <label class="blind" for="inpHdSch">Input Search Keyword</label>
                <input type="text"
                       class="ipt-hd-sch wrd-on"
                       id="inpHdSch"
                       :placeholder="$t('search.enter_search_word')"
                       v-model="searchWord"
                       v-on:keyup.enter="doSearch"/>
                <button type="button" class="btn-hd-sch" v-on:click="doSearch">
                  <span class="blind">Search Button</span>
                </button>
              </div>
            </div>
          </div>
          <div class="sch-btm inner" v-if="!(showSearchResult && searchWords.length > 0)">
            <div class="w-con">
              <div class="sch-recent" v-if="searchWords.length > 0">
                <p class="sch-rcnt-tit">{{ $t('search.recent_search') }}</p>
                <ul class="sch-rcnt-list">
                  <template  v-for="sw in searchWords">
                    <li v-if="!isEmpty(sw)">
                      <span class="sch-rcnt-keyw" v-on:click="searchWord=sw;doSearch()">{{sw}}</span>
                      <button type="button" class="btn-rcnt-del" v-on:click="deleteRecent(sw)">delete keyword button</button>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          <div class="w-con-02" v-if="seeContent">
            <h1 class="tit mb-88 tac inner">
              <span>{{totalCount()}}</span> {{ $t('result_for')}} <strong>“{{searchWord2}}”</strong>
            </h1>
          </div>
          <div class="tab-ui tab-ui-nav type_line" v-if="seeContent">
            <ul>
              <li class="btn-tab" v-bind:class="[tab =='product' ? 'on' : '']">
                <router-link :to="'/product/'+searchWord" tag="button">{{ $t('products')}}({{productTotalCount()}})</router-link>
              </li>
              <li class="btn-tab" v-bind:class="[tab =='inspiration' ? 'on' : '']">
                <router-link :to="'/inspiration/'+searchWord" tag="button">{{ $t('inspiration')}}({{inspirationTotalCount()}})</router-link>
              </li>
              <li class="btn-tab" v-bind:class="[tab =='documentation' ? 'on' : '']">
                <router-link :to="'/documentation/'+searchWord" tag="button">{{ $t('documentation')}}({{searchResult['document'].totalCount}})</router-link>
              </li>
              <li class="btn-tab" v-bind:class="[tab =='other' ? 'on' : '']">
                <router-link :to="'/other/'+searchWord" tag="button">{{ $t('other')}}({{otherTotalCount()}})</router-link>
              </li>
            </ul>
          </div>

          <!-- tab-con  -->
          <div class="inner search-inner" v-if="seeContent">
            <router-view
              v-if="searchResult"
              v-bind:searchResult="searchResult"
              v-bind:searchWord="searchWord"
            />
          </div>

          <div class="sch-btm" v-else>
            <div class="w-con">
              <div class="sch-no">
                <p class="sch-no-info">
                  <span class="no-re-tit">{{ $t('search.no_search_result') }} "<em class="sch-keyw color-red">{{searchWord2}}</em>" </span>
                  <span class="no-re-desc">{{ $t('search.no_search_guide') }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- //tab-con  -->

        </div>
      </div>
      <!-- //container  -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import SearchService from '@/server/search.service'
import GnbComponent from '@/components/Layout/GNB'
import { mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'app',
  components: {
    QuickMenuComponent,
    GnbComponent,
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      showSearchResult: false,
      tab: this.$route.name,
      searchWord: '',
      searchWord2: '',
      searchResult: null,
      popularWords: [],
      seeContent: true,
      countryCode: '',
      countryCodeSub: ''
    }
  },
  computed: {
    ...mapState({
      searchWords: state => state.search.searchWords
    })
  },
  watch: {
    '$route.params.s': {
      handler: function (s) {
        this.searchWord = this.$route.params.s
        this.doSearch()
      },
      deep: true,
      immediate: true
    },
    '$route.name': {
      handler: function (s) {
        this.tab = this.$route.name
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        if ($(mutationRecord.target).css('display') === 'none') {
          _this.showSearchResult = false
        }
      })
    })

    const target = $('.hd-sec-cont.sch-area')[0]
    observer.observe(target, { attributes: true, attributeFilter: ['style'] })

    $('#inpHdSch').autocomplete({
      source: async function (request, response) {
        const res = await SearchService.suggestion(request.term)
        response(res.data)
      },
      minLength: 2
    })

    const res = await SearchService.popular()
    this.popularWords = res.data
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    isEmpty,
    deleteRecent (searchWord) {
      this.$store.dispatch('search/deleteSearchWord', { searchWord: searchWord, vm: this })
    },
    isEuUk () {
      const country = this.$countryCode.substring(1)
      return country != 'GL' && country != 'US'
    },
    productTotalCount () {
      return (
        this.searchResult.hflor.totalCount +
        this.searchResult.himacs.totalCount +
        this.searchResult.viatera.totalCount +
        this.searchResult.himacsOther.totalCount +
        this.searchResult.viateraOther.totalCount +
        this.searchResult.teracanto.totalCount +
        this.searchResult.benif.totalCount +
        this.searchResult.vizuon.totalCount +
        this.searchResult.deco.totalCount +
        this.searchResult.exterior.totalCount)
    },
    otherTotalCount () {
      return (
        this.searchResult.NEWS.totalCount +
        this.searchResult.CSR.totalCount +
        this.searchResult.FAQ.totalCount)
    },
    collectionTotalCount () {
      return (
        this.searchResult.FLOOR.totalCount +
        this.searchResult.HIMACS.totalCount +
        this.searchResult.VIATERA.totalCount)
    },
    inspirationTotalCount () {
      return (
        this.searchResult.INSP.totalCount +
        this.searchResult.CASE.totalCount)
    },
    totalCount () {
      let totalCount = 0
      for (const search in this.searchResult) {
        totalCount += this.searchResult[search].totalCount
      }

      return totalCount
    },
    async doSearch () {
      const state = { searchWord: this.searchWord }
      const url = 'search/product/' + this.searchWord

      history.pushState(state, '', url)

      this.$route.params.s = this.searchWord
      if (this.searchWord == '') {
        alert('Please enter some search keyword')
        return false
      }
      const res = await SearchService.all({
        s: this.searchWord,
        r: this.$countryCode.substring(1),
        pageIndex: 1,
        pageSize: 4
      })

      this.searchResult = res.data
      if (this.isEuUk()) {
        this.searchResult.NEWS = { totalCount: 0, list: [] }
        this.searchResult.viatera = { totalCount: 0, list: [] }
      }
      if (this.nationCode === '_GL') {
        this.searchResult.himacsOther = { totalCount: 0, list: [] }
        this.searchResult.viateraOther = { totalCount: 0, list: [] }
      }
      if (this.searchWord != '') {
        this.$store.dispatch('search/addSearchWord', { searchWord: this.searchWord, vm: this })
      }
      if (this.totalCount() == 0) {
        this.seeContent = false
      } else {
        this.seeContent = true
      }
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({
          event: 'searchComp',
          search_keyword: this.searchWord,
          search_result_cnt: this.totalCount()
        })
      }
      this.searchWord2 = this.searchWord
    }
  }
}
</script>
