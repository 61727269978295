<template>
  <div class="sustainability-title-and-text">
    <pre class="content-title" v-if="contentTitle" :style="{'color': titleColor}">{{ contentTitle }}</pre>
    <pre class="content-text" v-if="contentText" :style="{'color': textColor}">{{ contentText }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    contentTitle: {
      type: String
    },
    contentText: {
      type: String
    },
    titleColor: {
      type: String,
      default: '#000'
    },
    textColor: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style scoped>
.sustainability-title-and-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.sustainability-title-and-text .content-title {
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
}

@media (max-width: 768px) {
  .sustainability-title-and-text .content-title {
    font-size: 1rem;
    line-height: 1.3;
  }
}

.sustainability-title-and-text .content-text {
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .sustainability-title-and-text .content-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
}
</style>
