<template>
  <div class="social-contribution-activities-card"
  :style="{'flex-direction': setFlexDirection}"
  >
    <div class="text-area">
      <pre class="title">{{ title }}</pre>
      <pre class="text">{{ text }}</pre>
    </div>
    <img :src="image">
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    image: {
      type: String
    },
    flexDirection: {
      type: String,
      default: 'row'
    }
  },
  computed: {
    setFlexDirection () {
      let flexDirection = this.flexDirection
      if (this.isMobile()) {
        flexDirection = 'column'
      }
      return flexDirection
    }
  },
  methods: {
    isMobile () {
      const screenWidth = window.innerWidth
      return screenWidth <= 768
    }
  }
}
</script>

<style>
.social-contribution-activities-card {
  display: flex;
  gap: 30px;
  width: 1440px;
  justify-content: center;
}

@media (max-width: 768px) {
  .social-contribution-activities-card {
    width: 100%;
  }
}

.social-contribution-activities-card .text-area {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social-contribution-activities-card .text-area .title {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  align-self: stretch;
}

.social-contribution-activities-card .text-area .text {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
  align-self: stretch;
}

@media (max-width: 768px) {
  .social-contribution-activities-card .text-area .title {
    font-size: 1rem;
    white-space: pre-wrap;
    line-height: 1rem;
  }
  .social-contribution-activities-card .text-area .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
}

.social-contribution-activities-card img {
  border-radius: 20px;
  object-fit: none;
  width: auto;
}
</style>
