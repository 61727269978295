var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content pros-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
            _vm.nationCode == "_US" || _vm.nationCode == "_GL"
              ? _c("li", [_vm._v("Pros")])
              : _vm._e(),
            _vm.nationCode != "_US" && _vm.nationCode != "_GL"
              ? _c("li", [_vm._v(_vm._s(_vm.$t("forfabricator.Text.Pros")))])
              : _vm._e(),
            _vm.nationCode == "_US" || _vm.nationCode == "_GL"
              ? _c("li", [_vm._v("For Fabricator")])
              : _vm._e(),
            _vm.nationCode != "_US" && _vm.nationCode != "_GL"
              ? _c("li", [
                  _vm._v(_vm._s(_vm.$t("forfabricator.Text.for_fabricator"))),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm.nationCode == "_US" || _vm.nationCode == "_GL"
          ? _c("div", { staticClass: "container for-box cont-fabri" }, [
              _c("div", { staticClass: "w-con-02" }, [
                _c("h1", { staticClass: "tt-1 tac" }, [
                  _vm._v(
                    _vm._s(_vm.$t("forfabricator.Text.for_fabricator")) + " "
                  ),
                ]),
                _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "tab-con tab01 on inner" }, [
                    _c("div", {
                      staticClass: "fabri-editor-box",
                      domProps: {
                        innerHTML: _vm._s(_vm.viateraList.attribute.editor1),
                      },
                    }),
                    _c("div", { staticClass: "thum-box" }, [
                      _c("p", { staticClass: "tt-sub-gray" }, [
                        _vm._v("Benefits of becoming a LX Hausys Fabricator:"),
                      ]),
                      _c("ul", { staticClass: "ul-for-fabricator" }, [
                        _c("li", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.viateraList.attribute.benefits_img1,
                                alt: "PLACED ON THE FABRICATOR LOCATOR",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.viateraList.attribute.benefits_text1
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.viateraList.attribute.benefits_img2,
                                alt: "PLACED ON THE FABRICATOR LOCATOR",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.viateraList.attribute.benefits_text2
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.viateraList.attribute.benefits_img3,
                                alt: "PLACED ON THE FABRICATOR LOCATOR",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.viateraList.attribute.benefits_text3
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info-box col-02" }, [
                      _c("div", {
                        staticClass: "txt-box",
                        domProps: {
                          innerHTML: _vm._s(_vm.viateraList.attribute.editor2),
                        },
                      }),
                      _c("div", { staticClass: "img-box" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.$imagePath +
                              _vm.viateraList.attribute.fabricator_img,
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "guide-box" }, [
                      _c("p", { staticClass: "tit-sub ta-l" }, [
                        _vm._v("Fabrication Guide"),
                      ]),
                      _c("div", { staticClass: "g-list col-02" }, [
                        _vm.viateraList.attribute.fabrication_guide1Content !=
                          null &&
                        _vm.viateraList.attribute.fabrication_guide1Content !=
                          "" &&
                        _vm.viateraList.attribute.fabrication_guide1Content
                          .content != null &&
                        _vm.viateraList.attribute.fabrication_guide1Content
                          .content != ""
                          ? _c("div", { staticClass: "bd-gray" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.viateraList.attribute
                                      .fabrication_guide1Content.content.title
                                  ) + ".pdf"
                                ),
                              ]),
                              _c("div", { staticClass: "col-02" }, [
                                _c("div", { staticClass: "txt-box" }, [
                                  _c("div", { staticClass: "t-type-01" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.viateraList.attribute
                                            .fabrication_guide1Content.attribute
                                            .doctypeText
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.viateraList.attribute
                                              .fabrication_guide1Content
                                              .attribute.doc_file
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Download")]
                                  ),
                                ]),
                                _vm._m(1),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.viateraList.attribute.fabrication_guide2Content !=
                          null &&
                        _vm.viateraList.attribute.fabrication_guide2Content !=
                          "" &&
                        _vm.viateraList.attribute.fabrication_guide2Content
                          .content != null &&
                        _vm.viateraList.attribute.fabrication_guide2Content
                          .content != ""
                          ? _c("div", { staticClass: "bd-gray" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.viateraList.attribute
                                      .fabrication_guide2Content.content.title
                                  ) + ".pdf"
                                ),
                              ]),
                              _c("div", { staticClass: "col-02" }, [
                                _c("div", { staticClass: "txt-box" }, [
                                  _c("div", { staticClass: "t-type-01" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.viateraList.attribute
                                            .fabrication_guide2Content.attribute
                                            .doctypeText
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.viateraList.attribute
                                              .fabrication_guide2Content
                                              .attribute.doc_file
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Download")]
                                  ),
                                ]),
                                _vm.viateraList.attribute
                                  .fabrication_guide2Content.attribute
                                  .doc_thumb == null ||
                                _vm.viateraList.attribute
                                  .fabrication_guide2Content.attribute
                                  .doc_thumb == ""
                                  ? _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: "images/pros/@img_03.png",
                                          alt: "2021 Viatera Fabrication Guide",
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.viateraList.attribute
                                            .fabrication_guide2Content.attribute
                                            .doc_thumb,
                                        },
                                      }),
                                    ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "tab-con tab02 inner" }, [
                    _c("div", {
                      staticClass: "fabri-editor-box",
                      domProps: {
                        innerHTML: _vm._s(_vm.himacsList.attribute.editor1),
                      },
                    }),
                    _c("div", { staticClass: "thum-box" }, [
                      _c("p", { staticClass: "tt-sub-gray" }, [
                        _vm._v("Benefits of becoming a LX Hausys Fabricator:"),
                      ]),
                      _c("ul", { staticClass: "ul-for-fabricator" }, [
                        _c("li", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.himacsList.attribute.benefits_img1,
                                alt: "PLACED ON THE FABRICATOR LOCATOR",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.benefits_text1
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.himacsList.attribute.benefits_img2,
                                alt: "PLACED ON THE FABRICATOR LOCATOR",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.benefits_text2
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.himacsList.attribute.benefits_img3,
                                alt: "PLACED ON THE FABRICATOR LOCATOR",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.benefits_text3
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info-box col-02" }, [
                      _c("div", {
                        staticClass: "txt-box",
                        domProps: {
                          innerHTML: _vm._s(_vm.himacsList.attribute.editor2),
                        },
                      }),
                      _c("div", { staticClass: "img-box" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.$imagePath +
                              _vm.himacsList.attribute.fabricator_img,
                            alt: "PLACED ON THE FABRICATOR LOCATOR",
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "guide-box" }, [
                      _c("p", { staticClass: "tit-sub ta-l" }, [
                        _vm._v("Fabrication Guide"),
                      ]),
                      _c("div", { staticClass: "g-list col-02" }, [
                        _vm.himacsList.attribute.fabrication_guide1Content !=
                          null &&
                        _vm.himacsList.attribute.fabrication_guide1Content !=
                          "" &&
                        _vm.himacsList.attribute.fabrication_guide1Content
                          .content != null &&
                        _vm.himacsList.attribute.fabrication_guide1Content
                          .content != ""
                          ? _c("div", { staticClass: "bd-gray" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.himacsList.attribute
                                      .fabrication_guide1Content.content.title
                                  ) + ".pdf"
                                ),
                              ]),
                              _c("div", { staticClass: "col-02" }, [
                                _c("div", { staticClass: "txt-box" }, [
                                  _c("div", { staticClass: "t-type-01" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.himacsList.attribute
                                            .fabrication_guide1Content.attribute
                                            .doctypeText
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.himacsList.attribute
                                              .fabrication_guide1Content
                                              .attribute.doc_file
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Download")]
                                  ),
                                ]),
                                _vm.himacsList.attribute
                                  .fabrication_guide1Content.attribute
                                  .doc_thumb == null ||
                                _vm.himacsList.attribute
                                  .fabrication_guide1Content.attribute
                                  .doc_thumb == ""
                                  ? _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: "images/pros/@img_04.png",
                                          alt: "2021 Viatera Fabrication Guide",
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.himacsList.attribute
                                            .fabrication_guide1Content.attribute
                                            .doc_thumb,
                                        },
                                      }),
                                    ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.himacsList.attribute.fabrication_guide2Content !=
                          null &&
                        _vm.himacsList.attribute.fabrication_guide2Content !=
                          "" &&
                        _vm.himacsList.attribute.fabrication_guide2Content
                          .content != null &&
                        _vm.himacsList.attribute.fabrication_guide2Content
                          .content != ""
                          ? _c("div", { staticClass: "bd-gray" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.himacsList.attribute
                                      .fabrication_guide2Content.content.title
                                  ) + ".pdf"
                                ),
                              ]),
                              _c("div", { staticClass: "col-02" }, [
                                _c("div", { staticClass: "txt-box" }, [
                                  _c("div", { staticClass: "t-type-01" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.himacsList.attribute
                                            .fabrication_guide2Content.attribute
                                            .doctypeText
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(
                                            _vm.himacsList.attribute
                                              .fabrication_guide2Content
                                              .attribute.doc_file
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Download")]
                                  ),
                                ]),
                                _vm.himacsList.attribute
                                  .fabrication_guide2Content.attribute
                                  .doc_thumb == null ||
                                _vm.himacsList.attribute
                                  .fabrication_guide2Content.attribute
                                  .doc_thumb == ""
                                  ? _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: "images/pros/@img_04.png",
                                          alt: "2021 Viatera Fabrication Guide",
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.himacsList.attribute
                                            .fabrication_guide2Content.attribute
                                            .doc_thumb,
                                        },
                                      }),
                                    ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._m(2),
                    _vm._m(3),
                    _c("div", { staticClass: "vdo-list" }, [
                      _c("p", { staticClass: "tit-sub" }, [
                        _vm._v("HIMACS BRINGS SO MANY GREAT FEATURES"),
                      ]),
                      _c("ul", { staticClass: "ul-for-fabricator" }, [
                        _c("li", [
                          _c("div", { staticClass: "video" }, [
                            _c("div", { staticClass: "video-container" }, [
                              _c("iframe", {
                                staticClass: "result-iframe",
                                attrs: {
                                  autoplay: "autoplay",
                                  muted: "muted",
                                  controls: "",
                                  src: _vm.himacsList.attribute.himacs_youtube1,
                                  title: "YouTube video player",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.himacs_youtube_text1
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "video" }, [
                            _c("div", { staticClass: "video-container" }, [
                              _c("iframe", {
                                staticClass: "result-iframe",
                                attrs: {
                                  autoplay: "autoplay",
                                  muted: "muted",
                                  controls: "",
                                  src: _vm.himacsList.attribute.himacs_youtube2,
                                  title: "YouTube video player",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.himacs_youtube_text2
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "video" }, [
                            _c("div", { staticClass: "video-container" }, [
                              _c("iframe", {
                                staticClass: "result-iframe",
                                attrs: {
                                  autoplay: "autoplay",
                                  muted: "muted",
                                  controls: "",
                                  src: _vm.himacsList.attribute.himacs_youtube3,
                                  title: "YouTube video player",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.himacs_youtube_text3
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "video" }, [
                            _c("div", { staticClass: "video-container" }, [
                              _c("iframe", {
                                staticClass: "result-iframe",
                                attrs: {
                                  autoplay: "autoplay",
                                  muted: "muted",
                                  controls: "",
                                  src: _vm.himacsList.attribute.himacs_youtube4,
                                  title: "YouTube video player",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.himacs_youtube_text4
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("div", { staticClass: "video" }, [
                            _c("div", { staticClass: "video-container" }, [
                              _c("iframe", {
                                staticClass: "result-iframe",
                                attrs: {
                                  autoplay: "autoplay",
                                  muted: "muted",
                                  controls: "",
                                  src: _vm.himacsList.attribute.himacs_youtube5,
                                  title: "YouTube video player",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.himacsList.attribute.himacs_youtube_text5
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.nationCode != "_US" && _vm.nationCode != "_GL"
          ? _c("div", { staticClass: "container for-box cont-fabri euSite" }, [
              _c("div", { staticClass: "w-con-01" }, [
                _c("div", { staticClass: "w-con-02 inner" }, [
                  _c("h1", { staticClass: "tt-1 tac" }, [
                    _vm._v(_vm._s(_vm.$t("forfabricator.Text.for_fabricator"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tab-ui tab-ui-nav",
                      attrs: { id: "demoTab3" },
                    },
                    [
                      _vm._m(4),
                      _vm._m(5),
                      _c("ul", [
                        _c(
                          "li",
                          {
                            staticClass: "btn-tab on",
                            attrs: {
                              id: "tab_fabrication",
                              "data-tab": "fabrication",
                            },
                          },
                          [
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.button.Fabrication_tab")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "btn-tab",
                            attrs: {
                              id: "tab_workshops",
                              "data-tab": "workshops",
                            },
                          },
                          [
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.button.Workshops_tab")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "btn-tab",
                            attrs: { id: "tab_quality", "data-tab": "quality" },
                          },
                          [
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.button.Quality_tab")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "btn-tab",
                            attrs: { id: "tab_become", "data-tab": "become" },
                          },
                          [
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.button.Become_tab")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "btn-tab",
                            attrs: {
                              id: "tab_warranty",
                              "data-tab": "warranty",
                            },
                          },
                          [
                            _c("button", { attrs: { type: "button" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.button.Warranty_tab")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "tab-con fabrication on",
                    attrs: { id: "fabrication" },
                  },
                  [
                    _c("div", { staticClass: "w-con-02 inner pt-0" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.FabricationList.editor),
                        },
                      }),
                      _c("div", { staticClass: "forFabr-img" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://img.lxhausys.com" +
                              _vm.FabricationList.image,
                            alt: "images",
                          },
                        }),
                        _c("p", {
                          staticClass: "comtt ctt ctt-t5",
                          domProps: {
                            innerHTML: _vm._s(_vm.FabricationList.photo_credit),
                          },
                        }),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "hiforSlider inner",
                        attrs: { id: "page" },
                      },
                      [
                        _c("p", { staticClass: "tt-sub-gray" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("forfabricator.Text.Fabrication_slider")
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "column small-11 small-centered" },
                            [
                              _c(
                                "div",
                                { staticClass: "slider slider-single" },
                                _vm._l(
                                  _vm.FabricationList.color_image,
                                  function (fabricationList) {
                                    return _c("div", [
                                      _c("iframe", {
                                        staticClass: "result-iframe",
                                        staticStyle: {
                                          width: "1110px",
                                          height: "616px",
                                        },
                                        attrs: {
                                          autoplay: "autoplay",
                                          muted: "muted",
                                          controls: "",
                                          src: fabricationList.image,
                                          title: "YouTube video player",
                                        },
                                      }),
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "slider slider-nav" },
                                _vm._l(
                                  _vm.FabricationList.color_image,
                                  function (fabricationList) {
                                    return _c("div", [
                                      _c("span", [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              "https://img.lxhausys.com" +
                                              fabricationList.thumb,
                                            alt: "thumb",
                                          },
                                        }),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-con workshops",
                    attrs: { id: "workshops" },
                  },
                  [
                    _c("div", { staticClass: "w-con-02 inner pt-0" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.WorkshopsList.editor),
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticClass: "ctt ctt-t4 txtunder",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.gotoTab("become")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("forfabricator.button.workshops_link")
                            )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "forFabr-img" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://img.lxhausys.com" +
                              _vm.WorkshopsList.image,
                            alt: "image",
                          },
                        }),
                        _c("p", {
                          staticClass: "comtt ctt ctt-t5",
                          domProps: {
                            innerHTML: _vm._s(_vm.WorkshopsList.photo_credit),
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tab-con quality", attrs: { id: "quality" } },
                  [
                    _c("div", { staticClass: "w-con-02 inner pt-0" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.QualityList.editor) },
                      }),
                      _c("div", { staticClass: "forFabr-img" }, [
                        _vm._m(6),
                        _c("img", {
                          attrs: {
                            src:
                              "https://img.lxhausys.com" +
                              _vm.QualityList.image,
                            alt: "image",
                          },
                        }),
                        _c("p", {
                          staticClass: "comtt ctt ctt-t5",
                          domProps: {
                            innerHTML: _vm._s(_vm.QualityList.photo_credit),
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tab-con become", attrs: { id: "become" } },
                  [
                    _c("div", { staticClass: "w-con-02 inner pt-0" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.BecomeList.editor) },
                      }),
                      _c("ul", { staticClass: "becomeList" }, [
                        _c("li", [
                          _c("dl", [
                            _c("dt", { staticClass: "ctt ctt-t2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.Text.becomeList_title1")
                                )
                              ),
                            ]),
                            _c("dd", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "forfabricator.Text.becomeList_brief1_1"
                                  )
                                )
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ctt ctt-t4",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    onclick:
                                      "location.href='support/contactus/himacs/request/become'",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "forfabricator.Text.becomeList_brief1_2"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("dl", [
                            _c("dt", { staticClass: "ctt ctt-t2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.Text.becomeList_title2")
                                )
                              ),
                            ]),
                            _c("dd", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "forfabricator.Text.becomeList_brief2_1"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("dl", [
                            _c("dt", { staticClass: "ctt ctt-t2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.Text.becomeList_title3")
                                )
                              ),
                            ]),
                            _c("dd", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "forfabricator.Text.becomeList_brief3_1"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("li", [
                          _c("dl", [
                            _c("dt", { staticClass: "ctt ctt-t2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forfabricator.Text.becomeList_title4")
                                )
                              ),
                            ]),
                            _c("dd", { staticClass: "ctt ctt-t4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "forfabricator.Text.becomeList_brief4_1"
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.$t(
                                      "forfabricator.Text.becomeList_brief4_2"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  staticClass: "ctt ctt-t4",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoTab("quality")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "forfabricator.Text.becomeList_brief4_3"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "forFabr-img" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://img.lxhausys.com" + _vm.BecomeList.image,
                            alt: "image",
                          },
                        }),
                        _c("p", {
                          staticClass: "comtt ctt ctt-t5",
                          domProps: {
                            innerHTML: _vm._s(_vm.BecomeList.photo_credit),
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-con warranty",
                    attrs: { id: "warranty" },
                  },
                  [
                    _c("div", { staticClass: "w-con-02 inner pt-0" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.WarrantyList.editor),
                        },
                      }),
                      _c("div", { staticClass: "forFabr-img" }, [
                        _vm._m(7),
                        _c("img", {
                          attrs: {
                            src:
                              "https://img.lxhausys.com" +
                              _vm.WarrantyList.image,
                            alt: "image",
                          },
                        }),
                        _c("p", {
                          staticClass: "comtt ctt ctt-t5",
                          domProps: {
                            innerHTML: _vm._s(_vm.WarrantyList.photo_credit),
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "btns02" }, [
      _c(
        "li",
        {
          staticClass: "btn-tab on",
          attrs: { id: "btn-tab01", "data-tab": "tab01" },
        },
        [_c("button", { attrs: { type: "button" } }, [_vm._v("VIATERA")])]
      ),
      _c(
        "li",
        {
          staticClass: "btn-tab",
          attrs: { id: "btn-tab02", "data-tab": "tab02" },
        },
        [_c("button", { attrs: { type: "button" } }, [_vm._v("HIMACS")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: {
          src: "images/pros/@img_03.png",
          alt: "2021 Viatera Fabrication Guide",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-text" }, [
      _c("p", { staticClass: "tit-sub" }, [
        _vm._v("HIMACS is not just another material"),
      ]),
      _c("div", { staticClass: "t-type-01" }, [
        _vm._v(
          " One idea lay behind the development of HIMACS: what would it be like to make a material similar to stone available to carpenters? A material that can easily be worked using existing traditional woodworking tools. A material that enables craftsmen to translate the most exciting visions of designers and architects into reality. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-text" }, [
      _c("p", { staticClass: "tit-sub" }, [
        _vm._v("Starting to work with HIMACS is easy."),
      ]),
      _c("div", { staticClass: "t-type-01" }, [
        _vm._v(
          " As a woodworking expert, you only need to open your workshop to find everything you need: talented craftsmen and tools for working with hardwood. That's all you need to saw, drill and sand this material. And if you have a pre-heating oven or a heating press, then you'll also find it much easier to heat up the material for thermoforming. That's all there is to it: the carpentry workshop that once specialised in timber materials, is transformed in an instant into a company that can also offer modern stone products as well. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "arrow disabled tab-arr-prev", attrs: { type: "button" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("이전")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "arrow tab-arr-next", attrs: { type: "button" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("다음")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "qc" }, [
      _c("img", { attrs: { src: "images/pros/qc.png", alt: "qc" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "qc" }, [
      _c("img", { attrs: { src: "images/pros/qc.png", alt: "qc" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }