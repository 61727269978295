var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainpage", attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("h1", { staticClass: "sr-only" }, [_vm._v("LX Hausys")]),
      _c("div", { staticClass: "main content prod-dt-cate" }, [
        _c(
          "div",
          { staticClass: "container prod-dt-view" },
          [
            _c("VisualComponent"),
            _c("NewEventComponent"),
            _c("OurproductsComponent"),
            _c("INSPIREDComponent"),
            _c("BannerComponent"),
            _vm.nationCode === "_GL"
              ? _c(
                  "div",
                  { staticClass: "main-box main_case_studies_section" },
                  [
                    _c("div", { staticClass: "title_area" }, [
                      _c("h2", { staticClass: "tit-main" }, [
                        _vm._v("CASE STUDIES"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "more",
                          on: { click: _vm.handelClickViewMore },
                        },
                        [_vm._m(0)]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "case_studies_slider" },
                      [
                        _vm.pageList.length
                          ? _c(
                              "VueSlickCarousel",
                              _vm._b(
                                {},
                                "VueSlickCarousel",
                                _vm.slickSettings,
                                false
                              ),
                              _vm._l(_vm.pageList, function (item) {
                                return _c("div", { staticClass: "item" }, [
                                  _c("div", { staticClass: "img_wrap" }, [
                                    _c("img", {
                                      attrs: {
                                        src: `${_vm.$imagePath}${item.attribute.mainThumbnail}`,
                                        alt: item.content.title,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveToDetail(item)
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "contents" }, [
                                    _c("div", { staticClass: "link_area" }, [
                                      _c("span", {
                                        staticClass: "title",
                                        domProps: {
                                          innerHTML: _vm._s(item.content.title),
                                        },
                                      }),
                                      _c("span", { staticClass: "icon" }),
                                    ]),
                                  ]),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.nationCode !== "_GL"
              ? _c("div", { staticClass: "short_cut_box full_width" }, [
                  _c("div", { staticClass: "short-link" }, [
                    _vm.nationCode !== "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "item",
                            attrs: {
                              href: "javascript:roomvo.startStandaloneVisualizer('','');",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon icon_visualiser m-ui",
                            }),
                            _vm._m(1),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Visualizer"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.nationCode !== "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "item",
                            attrs: { href: "/us/support/ourshowroom" },
                          },
                          [
                            _c("span", {
                              staticClass: "icon icon_showroom m-ui",
                            }),
                            _vm._m(2),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Showroom"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.nationCode !== "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "item",
                            attrs: { href: "/us/support/contactus" },
                          },
                          [
                            _c("span", {
                              staticClass: "icon icon_contactus m-ui",
                            }),
                            _vm._m(3),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Contact us"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.nationCode !== "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "item",
                            attrs: {
                              href: "/us/support/where-to-buy/country/us",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon icon_wheretobuy m-ui",
                            }),
                            _vm._m(4),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(_vm._s(_vm.$t("main.text.where_to_buy"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.nationCode === "_GL"
              ? _c("div", { staticClass: "short_cut_box full_width" }, [
                  _c("div", { staticClass: "short-link" }, [
                    _vm.nationCode === "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "item",
                            attrs: { href: "/gl/support/contactus" },
                          },
                          [
                            _c("span", {
                              staticClass: "icon icon_contactus m-ui",
                            }),
                            _vm._m(5),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Contact us"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.nationCode === "_GL"
                      ? _c(
                          "a",
                          {
                            staticClass: "item",
                            attrs: {
                              href: "/gl/support/where-to-buy/country/global",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon icon_wheretobuy m-ui",
                            }),
                            _vm._m(6),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(_vm._s(_vm.$t("main.text.where_to_buy"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "view_more", attrs: { href: "javascript:void(0)" } },
      [_c("span", [_vm._v("View more")]), _c("span", { staticClass: "icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_group short_visualiser_area p-ui" }, [
      _c("span", { staticClass: "icon icon_visual_cube" }),
      _c("span", { staticClass: "icon icon_visual_btm" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_group short_showroom_area p-ui" }, [
      _c("span", { staticClass: "icon icon_showroom_arrow" }),
      _c("span", { staticClass: "icon icon_showroom_btm" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_group short_contact_area p-ui" }, [
      _c("span", { staticClass: "icon icon_contact_mail" }),
      _c("span", { staticClass: "icon icon_contact_btm" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_group short_where_area p-ui" }, [
      _c("span", { staticClass: "icon icon_where_dot" }),
      _c("span", { staticClass: "icon icon_where_btm" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_group short_contact_area p-ui" }, [
      _c("span", { staticClass: "icon icon_contact_mail" }),
      _c("span", { staticClass: "icon icon_contact_btm" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_group short_where_area p-ui" }, [
      _c("span", { staticClass: "icon icon_where_dot" }),
      _c("span", { staticClass: "icon icon_where_btm" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }