<template>
  <OverviewSection
    title="Pollutant Management"
    class="mt-64"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <pre class="targets">2023 Targets</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <PollutantManagementBorderBox />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area1">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard1.imageCardTitle"
          :imageCardContentList="imageCard1.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard2.imageCardTitle"
          :imageCardContentList="imageCard2.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <PollutantManagementImage
        :image1="pollutantManagementImage.image1"
        :image2="pollutantManagementImage.image2"
        :text="pollutantManagementImage.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area2">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard3.imageCardTitle"
          :imageCardContentList="imageCard3.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard4.imageCardTitle"
          :imageCardContentList="imageCard4.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
</OverviewSection>
<!-- JP -->
  <OverviewSection
    title="汚染物質管理"
    class="mt-64"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <pre class="targets">2023年の目標</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <PollutantManagementBorderBox />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area1">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard1Jp.imageCardTitle"
          :imageCardContentList="imageCard1Jp.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard2Jp.imageCardTitle"
          :imageCardContentList="imageCard2Jp.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <PollutantManagementImage
        :image1="pollutantManagementImage.image1"
        :image2="pollutantManagementImage.image2"
        :text="pollutantManagementImage.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area2">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard3Jp.imageCardTitle"
          :imageCardContentList="imageCard3Jp.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard4Jp.imageCardTitle"
          :imageCardContentList="imageCard4Jp.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Регулирование выбросов загрязняющих веществ"
    class="mt-64"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <pre class="targets">Целевые показатели на 2023 год</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <PollutantManagementBorderBox />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area1">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard1Ru.imageCardTitle"
          :imageCardContentList="imageCard1Ru.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard2Ru.imageCardTitle"
          :imageCardContentList="imageCard2Ru.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <PollutantManagementImage
        :image1="pollutantManagementImage.image1"
        :image2="pollutantManagementImage.image2"
        :text="pollutantManagementImage.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area2">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard3Ru.imageCardTitle"
          :imageCardContentList="imageCard3Ru.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard4Ru.imageCardTitle"
          :imageCardContentList="imageCard4Ru.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="污染物管理"
    class="mt-64"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <pre class="targets">2023 目标</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <PollutantManagementBorderBox />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area1">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard1Cn.imageCardTitle"
          :imageCardContentList="imageCard1Cn.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard2Cn.imageCardTitle"
          :imageCardContentList="imageCard2Cn.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <PollutantManagementImage
        :image1="pollutantManagementImage.image1"
        :image2="pollutantManagementImage.image2"
        :text="pollutantManagementImage.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="image-card-area2">
        <PollutantManagementImageCard
          :imageCardTitle="imageCard3Cn.imageCardTitle"
          :imageCardContentList="imageCard3Cn.imageCardContentList"
        />
        <div class="line-area">
          <div class="line"></div>
        </div>
        <PollutantManagementImageCard
          :imageCardTitle="imageCard4Cn.imageCardTitle"
          :imageCardContentList="imageCard4Cn.imageCardContentList"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import PollutantManagementBorderBox from './PollutantManagementBorderBox.vue'
import PollutantManagementImage from './PollutantManagementImage.vue'
import PollutantManagementImageCard from './PollutantManagementImageCard.vue'
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'

export default {
  components: {
    PollutantManagementBorderBox,
    PollutantManagementImageCard,
    PollutantManagementImage,
    OverviewSection,
    OverviewSectionItem
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      /* imagecard1 */
      imageCard1: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon1.png',
          text: 'Air Pollutant Management'
        },
        imageCardContentList: [
          {
            contentTitle: 'Air Pollution Prevention Facilities',
            contentText: 'RTO (Regenerative Thermal Oxidizer) and electric dust collectors',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: 'Monitoring System',
            contentText: 'Monitor the discharge of air pollutants to check the operations\nstatus of the facilities in real-time',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard1Jp: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon1.png',
          text: '大気汚染物質管理'
        },
        imageCardContentList: [
          {
            contentTitle: '大気汚染防止施設',
            contentText: 'RTO（再生熱酸化装置）と電気集塵機',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: '監視システム',
            contentText: '大気汚染物質の排出を監視し、\n施設の稼働状況をリアルタイムで確認する',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard1Ru: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon1.png',
          text: 'Борьба с загрязнителями воздуха'
        },
        imageCardContentList: [
          {
            contentTitle: 'Объекты для предотвращения загрязнения воздуха',
            contentText: 'RTO (регенеративный термический окислитель\nи электрические пылеуловители',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: 'Система мониторинга',
            contentText: 'Мониторинг выбросов загрязняющих веществ в атмосферу для\nпроверки работыи состояния объектов в режиме реального времени',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard1Cn: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon1.png',
          text: '空气污染物管理'
        },
        imageCardContentList: [
          {
            contentTitle: '大气污染防治设施',
            contentText: 'RTO（蓄热式热氧化器）和电除尘器',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: '监控系统',
            contentText: '监测大气污染物排放，\n实时检查设施运行状况',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      /* imagecard2 */
      imageCard2: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon2.png',
          text: 'Water Pollutant Management'
        },
        imageCardContentList: [
          {
            contentTitle: 'Purification',
            contentText: 'The entire volume of contaminated water generated during\nproduct production is first purified at our own wastewater\ntreatment plant.' +
                         'purified at our own wastewater treatment plant.',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: 'Analyzation',
            contentText: 'We analyze major water pollutant indicators such as BOD, TOC,\nSS, TN, and TP with our own equipment in addition to all water\n' +
                         'pollutants stipulated by domestic law to confirm that our water\npollutant concentration level is lower than 50% of the regulatory\n' +
                         'permissible discharge level.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard2Jp: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon2.png',
          text: '水質汚染物質管理'
        },
        imageCardContentList: [
          {
            contentTitle: '精製',
            contentText: '製品生産時に発生する汚染水は、\nまず全量を自社の廃水処理場で浄化します。\n自社の廃水処理場で浄化します',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: '分析',
            contentText: '韓国国内法で定められたすべての水質汚濁物質に加え、\nBOD、TOC、SS、TN、TPなどの主要な水質汚濁指標を自社設備で分析し、\n水質汚濁物質の濃度が規制排出許容値の50％\n未満であることを確認しています。',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard2Ru: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon2.png',
          text: 'Управление загрязнением воды'
        },
        imageCardContentList: [
          {
            contentTitle: 'Очистка',
            contentText: 'Весь объем загрязненной воды, образующейся\nв процессе производства продукции, сначала\n' +
                         'очищается на собственных очистных сооружениях.',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: 'Анализ',
            contentText: 'Мы анализируем основные показатели загрязнения воды,\nтакие как BOD, TOC,. SS, TN и TP с нашими собственными,\n' +
                         'чтобы подтвердить, что уровень концентрации\nзагрязнителя ниже 50% от\n' +
                         'нормативного и допустимого уровня.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard2Cn: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon2.png',
          text: '水污染物管理'
        },
        imageCardContentList: [
          {
            contentTitle: '净化',
            contentText: '产品生产过程中产生的全部污水首先在我们自己的污水处理厂进行净化。',
            titleColor: '#222',
            textColor: '#222'
          },
          {
            contentTitle: '分析',
            contentText: '除了韩国国内法律规定的所有水污染物外，\n' +
                         '还使用自己的设备分析BOD、TOC、SS、TN和TP等主要水污染物指标，\n' +
                         '以确认我们的水污染物浓度水平低于监管允许排放水平的50%。',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      /* pollutant */
      pollutantManagementImage: {
        image1: 'images/aboutus/sustainability/sustainability_management/pollutant-management-image2.png',
        image2: 'images/aboutus/sustainability/sustainability_management/pollutant-management-image1.png',
        text: 'Hoeya River Water Quality Survey'
      },
      /* imagecard3 */
      imageCard3: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon4.png',
          text: 'Resource Recycling'
        },
        imageCardContentList: [
          {
            contentText: 'We strive to recycle all byproducts from our business\nactivities. To this end, we set an independent recycling rate\n' +
                           'target and intensity (generated volume/production volume)\ntarget and manage our performance to minimize the\n' +
                           'generation of byproducts.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard3Jp: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon4.png',
          text: '資源リサイクル'
        },
        imageCardContentList: [
          {
            contentText: '当社は、事業活動から発生するすべての副\n'+
                        '産物のリサイクルに努めています。\n' + 
                        'このため、独自のリサイクル率目標と原単位'+
                        '（発生量/生産量）目標を設定し、\n' +
                        '副産物の発生を最小限に抑えるようパフォーマンスを管理しています。',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard3Ru: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon4.png',
          text: 'Переработка ресурсов'
        },
        imageCardContentList: [
          {
            contentText: 'Мы стремимся перерабатывать все побочные продукты нашей\nдеятельности. Для этого мы установили независимый уровень\n' +
                           'переработки,целевой показатель и интенсивность\n(объем образования/объем производства)\n' +
                           'и контролируем свою деятельность, чтобы минимизировать\n' + 'образование побочных продуктов.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard3Cn: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon4.png',
          text: '资源回收利用'
        },
        imageCardContentList: [
          {
            contentText: 'LX Hausys努力回收商业活动中的所有副产品。\n' +
                           '为此，我们设定了一个独立的回收率目标和强度（产生量/产量）\n' +
                           '目标，并管理我们的绩效，以尽量减少副产品的产生。',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      /* imagecard4 */
      imageCard4: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon3.png',
          text: 'Waste Management'
        },
        imageCardContentList: [
          {
            contentText: 'LX Hausys sets waste discharge intensity targets for each\nbusiness site to foster waste reduction and monitor monthly\n' +
                           'performances. We also operate a separate waste storage area\nwithin business sites for lawful waste storage and processing\n' +
                           'management.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard4Jp: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon3.png',
          text: '廃棄物管理'
        },
        imageCardContentList: [
          {
            contentText: 'LX Hausys は、廃棄物の削減を促進し、\n' + 
                        '毎月のパフォーマンスを監視するために、\n' + 
                        '各事業所ごとに廃棄物排出強度の目標を設定しています。また、\n' + 
                        '合法的な廃棄物の保管と処理管理のために、\n' + 
                        '事業所内に別の廃棄物保管エリアを運営しています。',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard4Ru: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon3.png',
          text: 'Утилизация отходов'
        },
        imageCardContentList: [
          {
            contentText: 'LX Hausys устанавливает целевые показатели интенсивности сброса\nотходов для каждого предприятия, чтобы стимулировать\n' +
                           'сокращение отходов и ежемесячно отслеживать\nпоказатели. Мы также используем отдельную зону\n' +
                           'хранения отходовна территории предприятий\n' + 'для законного хранения и переработки отходов.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
      imageCard4Cn: {
        imageCardTitle: {
          image: 'images/aboutus/sustainability/sustainability_management/pollutant-management-icon3.png',
          text: '废物管理'
        },
        imageCardContentList: [
          {
            contentText: 'LX Hausys为每个业务场所设定废物排放强度目标，\n以促进废物减少并监测月度绩效。\n' +
                           '我们还在商业场所内运营一个单独的废物储存区，\n' +
                           '用于合法的废物储存和处理管理。.',
            titleColor: '#222',
            textColor: '#222'
          }
        ]
      },
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
.targets {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
}

@media (max-width: 768px) {
  .targets {
    font-size: 1.25rem;
    line-height: 1.3541666667rem;
  }
}

.image-card-area1 {
  display: flex;
}

@media (max-width: 768px) {
  .image-card-area1 {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.image-card-area1 .line-area {
  margin: 10px 20px;
}

@media (max-width: 768px) {
  .image-card-area1 .line-area {
    width: 100%;
    margin: 40px 0px;
  }
}

.image-card-area2 {
  display: flex;
}

@media (max-width: 768px) {
  .image-card-area2 {
    flex-direction: column;
  }
}

.image-card-area2 .line-area {
  margin: 10px 54px;
}

@media (max-width: 768px) {
  .image-card-area2 .line-area {
    width: 100%;
    margin: 40px 0px;
  }
}

.image-card-area1 .line-area .line {
  width: 1px;
  height: 510px;
  background: #D4D4D4;
}

.image-card-area2 .line-area .line {
  width: 1px;
  height: 300px;
  background: #D4D4D4;
}

@media (max-width: 768px) {
  .image-card-area1 .line-area .line {
    height: 1px;
    width: 100%;
  }
  .image-card-area2 .line-area .line {
    height: 1px;
    width: 90%;
  }
}

</style>
