<template>
  <div class="mt-100">
    <ProductSectionHeader
      title="Commercial Vegan Leather"
      description="We have applied automotive seat fabric technology to achieve excellent surface quality in terms of durability, resistance to contamination, and other factors. We are also able to provide products using bio-based materials such as Kenaf."
    />

    <ProductTab
      class="mt-50"
      :item="[
        { title: 'LXELLO™', component: 'LXELLO' },
      ]"
    >
      <template #LXELLO>
        <Seatcorverlayout imageSrc="images/automotive/skin/LXELLO.png">
          <template #text>
            <div class="title">
              <span>LXELLO™ Green</span>
            </div>
            <div class="description">
              <span>Comply with Class 1 of the OEKO-TEX® Standard 100, which attests that the material is safe for babies and small children</span>
            </div>

            <div class="title">
              <span>LXELLO™ Bio</span>
            </div>
            <div class="description">
              <span>The skin and foam layers contain bio based materials such as kenaf</span>
            </div>

            <div class="title">
              <span>LXELLO™ Eco</span>
            </div>
            <div class="description">
              <span>• RCS (Recycled Claim Standard) certificated</span>
              <br />
              <span>• Recycled content 35 wt% (Recycled Post-Consumer Polyester)</span>
            </div>
          </template>

          <template #annotation>
            <span>• The skin and foam layers contain bio based materials such as kenaf</span>
            <br>
            <span>- Kenaf is one of the highest CO<sub>2</sub> absorption rates among plants 3 to 10 times higher than average ¹⁾</span>
            <br>
            <span>• Designed to meet automotive interior demands including that of stain resistance</span>
            <br>
            <span>- Excellent anti-staining performance enables bright interior design</span>
            <br>
            <span>1) Source: Ministry of Environment's 'Analytic Report on Eco-friendly Crops' (2019)</span>
            <br>
          </template>

        </Seatcorverlayout>
      </template>
    </ProductTab>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'
import ProductTab from '@/components/product/tab'
import Seatcorverlayout from './seatCorver/SeatCorverLayout'

export default {
  components: {
    ProductSectionHeader,
    ProductTab,
    Seatcorverlayout
  }
}

</script>

<style scoped></style>
