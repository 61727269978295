<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <h1 class="sr-only">Global Human Rights &amp; Labor Policy</h1>

    <!-- content -->
    <div class="global-human-rights-and-labor-policy" v-html="globalHumanRights"></div>
<!--    <div class="content">-->

<!--      &lt;!&ndash; 220603 에디터 주석 내용 변경 여기부터 시작 &ndash;&gt;-->
<!--      <div class="au-view inner">-->
<!--      &lt;!&ndash; location &ndash;&gt;-->
<!--      <div class="location">-->
<!--      <ul>-->
<!--      <li class="ico-home">Home</li>-->
<!--      <li>About us</li>-->
<!--      <li>Sustainability</li>-->
<!--      <li>Global Human Rights &amp; Labor Policy</li>-->
<!--      </ul>-->
<!--      </div>-->
<!--      &lt;!&ndash; //location &ndash;&gt;-->
<!--      &lt;!&ndash; container (Global Human Rights & Labor Policy) &ndash;&gt;-->
<!--      <div class="container au-box">-->
<!--      <div class="w-con-02">-->
<!--      <div class="sub-visual-section">-->
<!--      <p class="visual-title tt-1 tac">Global Human Rights &amp; Labor Policy</p>-->
<!--      </div>-->
<!--      <div class="t-list-box t-type-01">Under the corporate management principle, “People-Oriented Management”, LX Hausys fulfills its basic responsibility to uphold human rights and the right for freedom and happiness. LX Hausys supports the international standards including Universal Declaration of Human Rights, Human Rights and Labor Principles of UN Global Compact(UNGC), UN Guiding Principles on Business and Human Rights(UNGP), International Labor Organization(ILO), and is in compliance with the laws of the countries we operate in. LX Hausys applies the policy on all overseas business sites, and also constantly monitors and reduces any risks regarding to human rights. Furthermore, LX Hausys shares the policy with our stakeholders including employees, customers and suppliers and contributes to improving and expanding their awareness of the policy.</div>-->
<!--      <div class="tbl-box type04 mt-50 field-01">-->
<!--      <table style="table-layout: auto;"><caption>Global Human Rights &amp; Labor Policy</caption><colgroup> <col style="width: 250px;"> <col style="width: auto;"> </colgroup>-->
<!--      <tbody>-->
<!--      <tr>-->
<!--      <th scope="row">Respect for Human Dignity</th>-->
<!--      <td>LX Hausys respects all employees and strives to create secure working environment by prohibiting workplace violence, including abusive language, psychological or physical coercion, etc.</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--      <th scope="row">Avoidance of Forced Labor</th>-->
<!--      <td>LX Hausys prohibits all forms of involuntary labor, including forced labor and restriction of psychological and physical freedom of employees. Original copy of identification, passport, and work permits is not requested upon hiring</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--      <th scope="row">Prohibition of Child Labor</th>-->
<!--      <td>LX Hausys complies with the local labor laws on minimum age of employment and prohibits the child labor below the age of 16. Employees under the age of 18 will not be permitted to tasks that jeopardize their health or safety, including night shifts and overtime.</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--      <th scope="row">Non-Discrimination Policy</th>-->
<!--      <td>LX Hausys provides equal opportunities for hiring, promoting, remunerating, and training. We strictly prohibit all forms of discrimination including gender, age, race, religion, disability, marital status, pregnancy, labor union activities, and social status.</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--      <th scope="row">Working Hours</th>-->
<!--      <td>LX Hausys complies with local laws on working hours(regular/overtime hours, holidays) and shall not force employees to work overtime. Employees are to be paid based on labor laws when working overtime.</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--      <th scope="row">Wages and Benefits</th>-->
<!--      <td>All employees are to be paid above minimum wage prescribed by local laws.</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--      <th scope="row">Freedom of Association</th>-->
<!--      <td>LX Hausys respects the employee’s freedom of association and collective bargaining in accordance with local labor laws. Employees can communicate with management regarding their working conditions without any fear of discrimination or retaliation. Employees will not be disadvantaged for joining, participating, or organizing labor unions.</td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--      </table>-->
<!--      </div>-->
<!--      </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; //container (Global Human Rights & Labor Policy) &ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; 220603 에디터 주석 내용 변경 여기까지 끝 &ndash;&gt;-->

<!--      -->
<!--    </div>-->
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import ProductService from '@/server/product.service.js'
import GnbComponent from '@/components/Layout/GNB'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      globalHumanRights: ''
    }
  },
  async mounted () {
    const _this = this

    const contentResInfo = await ProductService.getCategoryCode('GLOBAL_HUMAN_RIGHTS_AND_LABOR_POLICY' + _this.$countryCode)
    const contentRes = await ProductService.getContentList(contentResInfo.data.object.id)
    _this.globalHumanRights = contentRes.data.list[0].attribute.editor
  },
  methods: {
  }
}
</script>
