var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expanded-waste-recycling-grid-and-table" },
    [
      _c("ExpandedWasteRecyclingGrid", { attrs: { gridData: _vm.gridData } }),
      _c("ExpandedWasteRecyclingTableArea", {
        attrs: { tableAreaData: _vm.tableAreaData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }