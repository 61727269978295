<template>
  <div class="environmental-investment-image-card">
    <div class="card-area">
      <div class="title-area">
        <img class="icon" :src="icon">
        <pre class="title">{{ title }}</pre>
      </div>
      <div class="text-area">
        <SustainabilityTitleAndText
          v-for="item in imageCardContentList"
          :key="item"
          :contentTitle="item.contentTitle"
          :contentText="item.contentText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SustainabilityTitleAndText from '../../components/SustainabilityTitleAndText.vue'

export default {
  components: {
    SustainabilityTitleAndText
  },
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    imageCardContentList: {
      type: Array
    }
  }

}
</script>

<style scoped>
.environmental-investment-image-card {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 30px;
  border: 1px solid #B9B9B9;
  background: #FFF;
}

.environmental-investment-image-card .card-area {
  display: flex;
  width: 504px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.environmental-investment-image-card .card-area .title-area {
  display: flex;
  height: 128px;
  align-items: flex-start;
  gap: 30px;
  align-items: center;
}

@media (max-width: 768px) {
  .environmental-investment-image-card .card-area .title-area {
    height: auto;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
  }
}

.environmental-investment-image-card .card-area .title-area .icon {
  width: 100px;
  height: 100px;
}

.environmental-investment-image-card .card-area .title-area .title {
  color: #000;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 105.882% */
}

@media (max-width: 768px) {
  .environmental-investment-image-card .card-area .title-area .title {
    font-size: 1.4166666667rem;
    line-height: 1.3;
  }
}

.environmental-investment-image-card .card-area .text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}
</style>
