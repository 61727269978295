var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-component-area" }, [
    _c("div", { staticClass: "image-area" }, [
      _c("img", { attrs: { src: _vm.info.imageSrc } }),
      _c("div", { staticClass: "text" }, [
        _c("span", [_vm._v(_vm._s(_vm.info.title))]),
      ]),
    ]),
    _c("div", { staticClass: "divider" }),
    _c(
      "div",
      { staticClass: "description-area" },
      _vm._l(_vm.info.description, function (it, idx) {
        return _c("span", { key: idx }, [_vm._v(" " + _vm._s(it) + " ")])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }