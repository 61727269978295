var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "social-contribution-activities-card",
      style: { "flex-direction": _vm.setFlexDirection },
    },
    [
      _c("div", { staticClass: "text-area" }, [
        _c("pre", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
      ]),
      _c("img", { attrs: { src: _vm.image } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }