var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border-box-area" }, [
    _c(
      "div",
      { staticClass: "text-area" },
      [
        _c("OverviewText", {
          attrs: { type: "ft-25 fw-700", text: _vm.title, textAlign: "left" },
        }),
        _c("div", { staticClass: "line" }),
        _c("div", [
          _c("pre", {
            staticClass: "border-box-content",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }