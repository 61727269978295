<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content au-view">
      <!-- location -->
      <div class="location">
        <ul v-if="nationCode == '_US' || nationCode == '_GL'">
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{$t('csrReport.text.home')}}</li>
          <li>{{$t('csrReport.text.about_us')}}</li>
          <li>Our Sustainability</li>
          <li>ESG Reports</li>
          <li></li>
        </ul>
      <!-- JP  -->
        <ul v-else-if="nationCode == '_JP'">
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{$t('csrReport.text.home')}}</li>
          <li>{{$t('csrReport.text.about_us')}}</li>
          <li>Our Sustainability</li>
          <li>ESG Reports</li>
          <li></li>
        </ul>
      <!-- RU  -->
        <ul v-else-if="nationCode == '_RU'">
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{$t('csrReport.text.home')}}</li>
          <li>{{$t('csrReport.text.about_us')}}</li>
          <li>Our Sustainability</li>
          <li>ESG Reports</li>
          <li></li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (CSR Report) -->
      <div class="container au-box inner container_esg_reports">
        <div class="w-con-02 ba-text">
          <div class="sub-visual-section">
            <h1 class="visual-title tt-1 tac" v-if="nationCode == '_US' || nationCode == '_GL'">ESG Reports</h1>
            <h1 class="visual-title tt-1 tac" v-else-if="nationCode == '_JP'">ESG Reports</h1>
            <h1 class="visual-title tt-1 tac" v-else-if="nationCode == '_RU'">ESG Reports</h1>
          </div>

          <div class="t-list-box" v-if="nationCode == '_US' || nationCode == '_GL'">
            <h2 class="tit-04">Sustainability Report</h2>
          </div>
          <div class="t-list-box" v-if="nationCode == '_JP'">
            <h2 class="tit-04">サステナビリティレポート</h2>
          </div>
          <div class="t-list-box" v-if="nationCode == '_RU'">
            <h2 class="tit-04">Отчет об устойчивом развитии</h2>
          </div>
        </div>

        <div class="crs-box">
          <div class="w-con col-02">
            <div class="img-box bd-gray">
              <img v-bind:src=mainReportImage alt="report" />
            </div>

            <div class="txt-box bd-gray">
              <div class="tit-box">
                <strong>{{mainReportYear}}</strong>
                <p>{{mainReportYearTitle}}</p>
              </div>

              <div class="txt ta-c">
                <strong>{{mainReportDescHeader}}</strong>
                <div class="t-type-03">
                  {{mainReportDesc}}
                </div>
              </div>

              <div class="btns">
                <button type="button" @click="downloadFile(mainReportEngFile)"><span>{{$t('csrReport.text.english')}}</span></button>
                <button type="button" @click="downloadFile(mainReportCnFile)"><span>{{$t('csrReport.text.chinese')}}</span></button>
              </div>
            </div>
          </div>
        </div>

        <div class="w-con crs-list">
          <ul>
            <li v-for="report in reports">
              <div class="bd-gray">
                <div class="info">
                  <strong>{{report.attribute.year}}</strong>
                  <p>
                    {{report.attribute.title}}
                  </p>
                </div>

                <div class="img-box">
                  <img v-bind:src="$imagePath + report.attribute.image" alt="2020 Sustainability Report" />
                </div>

                <button type="button" class="btn-down" @click="downloadFile(report.attribute.file)">{{$t('csrReport.text.download')}}</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- //container (CSR Report) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
import ProductService from '@/server/product.service.js'
export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      mainReportImage: '',
      mainReportYear: '',
      mainReportYearTitle: '',
      mainReportDescHeader: '',
      mainReportDesc: '',
      mainReportEngFile: '',
      mainReportCnFile: '',
      reports: [],
      countryCode: '',
      countryCodeSub: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this
    const contentResInfo = await ProductService.getCategoryCode('CSR_REPORT' + _this.$countryCode)
    const contentRes = await ProductService.getContentList(contentResInfo.data.object.id)
    const mainReportRes = await ProductService.getCategoryListAttr(contentResInfo.data.object.id)
    const mainReport = mainReportRes.data
    this.mainReportImage = this.$imagePath + mainReport.attribute.image // imagePath new thing. It doesn`t work now, will work later
    this.mainReportYear = mainReport.attribute.year
    this.mainReportYearTitle = mainReport.attribute.year_title
    this.mainReportDescHeader = mainReport.attribute.desc_header
    this.mainReportDesc = mainReport.attribute.desc
    this.mainReportEngFile = mainReport.attribute.english_file
    this.mainReportCnFile = mainReport.attribute.chinese_file 

    this.reports = contentRes.data.list
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async downloadFile (file) {
      const url = this.$imagePath + file
      try {
        const element = await document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        // element.setAttribute('download', file);
        element.click()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped>
@media only screen and (max-width: 767px) {
  .crs-list ul li { width: 100%;}
}
</style>
