var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _vm._m(0),
    _c("div", { staticClass: "filter_checkbox_cont" }, [
      _c("div", { staticClass: "checkbox_box color_round" }, [
        _c(
          "span",
          { staticClass: "checkbox_more no_line" },
          _vm._l(_vm.stncolorList, function (stncolor) {
            return _c("li", { key: stncolor.id.value }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  id: "hue_stn_" + stncolor.id.value,
                  name: "StoneColor",
                },
                domProps: {
                  value: stncolor.id.value,
                  checked: Array.isArray(_vm.selected)
                    ? _vm._i(_vm.selected, stncolor.id.value) > -1
                    : _vm.selected,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.selected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = stncolor.id.value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selected = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selected = $$c
                    }
                  },
                },
              }),
              stncolor.text === "Other"
                ? _c("i", [
                    _c("span", {
                      style:
                        "background-image : url(https://img.lxhausys.com/images/products/" +
                        stncolor.id.value +
                        ".png)",
                    }),
                    _vm._v(_vm._s(stncolor.text)),
                  ])
                : _vm._e(),
              _c("label", { attrs: { for: `hue_stn_${stncolor.id.value}` } }, [
                _c("span", { staticClass: "round" }),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(stncolor.text)),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("STONE COLOR")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }