<template>
  <div class="design-area mt-100">
    <ProductSectionHeader
      type="horizontal"
      title="Design"
      description="LX Hausys develops automotive fabrics that not only offer high quality but also research and develop surface texture designs that match the vehicle's concept. By utilizing various patterns and design post-processing techniques, we provide design solutions that cater to our customers' preferences."
    />

    <div class="mt-50">
      <img src="images/automotive/skin/design-image.png" alt="img">
    </div>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'

export default {
  components: {
    ProductSectionHeader
  }
}

</script>

<style scoped>
.design-area {
  display: flex;
  flex-direction: column;
}

.design-area .image-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .design-area .image-area {
    justify-content: center;
  }
}

.design-area .image-area .item {
  width: 290px;
  height: 292px;
  background-color: aqua;
}
@media (max-width: 768px) {
  .design-area .image-area .item {
    margin-bottom: 10px;
  }
}

</style>
