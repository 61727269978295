<template>
  <OverviewSection
    class="mt-64"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <pre class="expanded-waste-recycling-title">Expanded Waste Recycling Activities Performance in 2022</pre>
    <div class="grid-table-area">
      <ExpandedWasteRecyclingGridAndTable
        v-for="(card, index) in gridAndTableCards"
        :key="index"
        :gridAndTable="card"
      />
    </div>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    class="mt-64"
    v-else-if="nationCode == '_JP'"
  >
    <pre class="expanded-waste-recycling-title">2022年の廃棄物リサイクル活動の拡大実績</pre>
    <div class="grid-table-area">
      <ExpandedWasteRecyclingGridAndTable
        v-for="(card, index) in gridAndTableCardsJp"
        :key="index"
        :gridAndTable="card"
      />
    </div>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    class="mt-64"
    v-else-if="nationCode == '_RU'"
  >
    <pre class="expanded-waste-recycling-title">Расширение деятельности по переработке отходов в 2022 году</pre>
    <div class="grid-table-area">
      <ExpandedWasteRecyclingGridAndTable
        v-for="(card, index) in gridAndTableCardsRu"
        :key="index"
        :gridAndTable="card"
      />
    </div>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    class="mt-64"
    v-else-if="nationCode == '_CN'"
  >
    <pre class="expanded-waste-recycling-title">2022年扩大废物回收活动绩效</pre>
    <div class="grid-table-area">
      <ExpandedWasteRecyclingGridAndTable
        v-for="(card, index) in gridAndTableCardsCn"
        :key="index"
        :gridAndTable="card"
      />
    </div>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import ExpandedWasteRecyclingGridAndTable from './ExpandedWasteRecyclingGridAndTable.vue'

export default {
  components: {
    ExpandedWasteRecyclingGridAndTable,
    OverviewSection
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      gridAndTableCards: [
        {
          gridData: {
            gridTitle: 'Cheongju Plant',
            item: 'Key Activities',
            itemList: [
              'Recycle PVA films (previously landfilled)',
              'Recycle incinerated waste'
            ]
          },
          tableAreaData: {
            tableTitle: 'Performance',
            tableData: {
              headerList: [
                'Category', 'Goal', 'Performance'
              ],
              bodyDataList: [
                [
                  'Recycling rate', '89.3%', '<b>89.9%</b>'
                ],
                [
                  'Cost Saved (annual)', '$ 80,000', '<b>$ 304,000</b>'
                ]
              ]
            }
          }
        },
        {
          gridData: {
            gridTitle: 'Ulsan Plant',
            item: 'Key Activities',
            itemList: [
              'Recycle incinerated/landfilled waste',
              'Stabilize the processing of newly generated waste, etc.'
            ]
          },
          tableAreaData: {
            tableTitle: 'Performance',
            tableData: {
              headerList: [
                'Category', 'Goal', 'Performance'
              ],
              bodyDataList: [
                [
                  'Recycling rate', '87.3%', '<b>90.0%</b>'
                ],
                [
                  'Cost Saved (annual)', '$ 80,000', '<b>$ 197,600</b>'
                ]
              ]
            }
          }
        }
      ],
      gridAndTableCardsJp: [
        {
          gridData: {
            gridTitle: '清州工場',
            item: '主要な活動',
            itemList: [
              'PVA フィルム (以前は埋め立てられていた) をリサイクル',
              '焼却廃棄物をリサイクル'
            ]
          },
          tableAreaData: {
            tableTitle: 'パフォーマンス',
            tableData: {
              headerList: [
                'カテゴリー', 'ゴール', 'パフォーマンス'
              ],
              bodyDataList: [
                [
                  'リサイクル率	', '89.3%', '<b>89.9%</b>'
                ],
                [
                  '節約コスト（年間）', '$ 80,000', '<b>$ 304,000</b>'
                ]
              ]
            }
          }
        },
        {
          gridData: {
            gridTitle: '蔚山工場',
            item: '主要な活動',
            itemList: [
              '焼却・埋立廃棄物のリサイクル',
              '新たに発生する廃棄物等の処理の安定化'
            ]
          },
          tableAreaData: {
            tableTitle: 'パフォーマンス',
            tableData: {
              headerList: [
                'カテゴリー', 'ゴール', 'パフォーマンス'
              ],
              bodyDataList: [
                [
                  'リサイクル率', '87.3%', '<b>90.0%</b>'
                ],
                [
                  '節約コスト（年間）', '$ 80,000', '<b>$ 197,600</b>'
                ]
              ]
            }
          }
        }
      ],
      gridAndTableCardsRu: [
        {
          gridData: {
            gridTitle: 'Завод в Чхонджу',
            item: 'Основные мероприятия',
            itemList: [
              'Утилизация пленок ПВА (ранее вывезенных на свалку)',
              'Переработка сжигаемых отходов'
            ]
          },
          tableAreaData: {
            tableTitle: 'Производительность',
            tableData: {
              headerList: [
                'Категория', 'Цель', 'Производительность'
              ],
              bodyDataList: [
                [
                  'Уровень утилизации', '89.3%', '<b>89.9%</b>'
                ],
                [
                  'Сэкономленные\nзатраты (в год)', '$ 80,000', '<b>$ 304,000</b>'
                ]
              ]
            }
          }
        },
        {
          gridData: {
            gridTitle: 'Завод в Ульсане',
            item: 'Основные мероприятия',
            itemList: [
              'Переработка сжигаемых отходов',
              'Стабилизировать переработку\n' + 
              'вновь образующихся отходов и т.д.'
            ]
          },
          tableAreaData: {
            tableTitle: 'Производительность',
            tableData: {
              headerList: [
                'Категория', 'Цель', 'Производительность'
              ],
              bodyDataList: [
                [
                  'Уровень утилизации', '87.3%', '<b>90.0%</b>'
                ],
                [
                  'Сэкономленные\nзатраты (в год)', '$ 80,000', '<b>$ 197,600</b>'
                ]
              ]
            }
          }
        }
      ],
      gridAndTableCardsCn: [
        {
          gridData: {
            gridTitle: '清州工厂',
            item: '主要活动',
            itemList: [
              '回收PVA薄膜（之前填埋）',
              '回收焚烧废物'
            ]
          },
          tableAreaData: {
            tableTitle: '业绩',
            tableData: {
              headerList: [
                '类别', '目标', '业绩'
              ],
              bodyDataList: [
                [
                  '回收率', '89.3%', '<b>89.9%</b>'
                ],
                [
                  '所节省成本（每年）', '$ 80,000', '<b>$ 304,000</b>'
                ]
              ]
            }
          }
        },
        {
          gridData: {
            gridTitle: '蔚山工厂',
            item: '主要活动',
            itemList: [
              '回收焚烧/填埋废物',
              '稳定新产生的废物等处理'
            ]
          },
          tableAreaData: {
            tableTitle: '业绩',
            tableData: {
              headerList: [
                '类别', '目标', '业绩'
              ],
              bodyDataList: [
                [
                  '回收率', '87.3%', '<b>90.0%</b>'
                ],
                [
                  '所节省成本（每年）', '$ 80,000', '<b>$ 197,600</b>'
                ]
              ]
            }
          }
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style>
.expanded-waste-recycling-title {
  color: #222;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 768px) {
  .expanded-waste-recycling-title {
    font-size: 1.4166666667rem;
    line-height: 1.3;
    white-space: pre-wrap;
  }
}

.grid-table-area {
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 64px;
}

@media (max-width: 768px) {
  .grid-table-area {
    width: 100%;
  }
}
</style>
