<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>SOLID PATTERNS</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box color_square">
          <span class="checkbox_more no_line">
          <li v-for="patternsld in sldpatternList">
            <input type="checkbox" :id="'pattern_sld_' + patternsld.id.value" v-model="selected" :value="patternsld.id.value" name="SolidPatterns" />
            <label :for="`pattern_sld_${patternsld.id.value}`">
              <span></span>
              {{ patternsld.text }}
            </label>
          </li>
          </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'sldpatternList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-solidPattern-filter', this.removeSolidPattern)
    EventBus.$on('update-solidPattern-filters', this.updateSolidPatterns)
    if (this.$store.state.filterState && this.$store.state.filterState.pattern_sld) {
      this.selected = this.$store.state.filterState.pattern_sld
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-solidPattern-filter', this.removeSolidPattern)
    EventBus.$on('update-solidPattern-filters', this.updateSolidPatterns)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeSolidPattern (solidPatternValue) {
      this.selected = this.selected.filter(value => value !== solidPatternValue)
    },
    updateSolidPatterns (solidPatterns) {
      this.selected = solidPatterns
    },
  }
}
</script>
