import request from './request'

const urlPrefix = ''
const ProsService = {
  getClickUp (id) {
    return request({
      url: `${urlPrefix}/product/clickUp?id=${id}`,
      method: 'get'
    })
  },
  getContent (id) {
    return request({
      url: `${urlPrefix}/product/detail?id=${id}`,
      method: 'get'
    })
  },
  zipFileDownload(files, type, country){
    if(country == '_GL' || country == "_US" ){
      document.location.href=`api/download/zip?ids=` + files.join(",") + `&type=`
    } else {
      document.location.href=`api/download/zip?ids=` + files.join(",") + `&type=` + type.join(",")
    }
  },
  getContentList (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId]
      }
    })
  },
  getContentListChild (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true
      }
    })
  },
  getContentListTap (categoryId, dictionaryValues, text, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryValues: dictionaryValues,
        text: text,
        containChildCategory: true
      }
    })
  },
  getContentListSort(categoryId, field, type) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        orderField : field,
        orderType: type
      }
    })
  },
  getDocumentationList (categoryCode, dictionaryValues, text, pageIndex, pageSize, field, type) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryCodes: [categoryCode],
        dictionaryValues: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField : field,
        orderType: type
      }
    })
  },
  getDocumentationListByCateId (categoryIds, dictionaryValues, text, pageIndex, pageSize, field, type) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryIds,
        dictionaryValues: dictionaryValues,
        text: text,
        containChildCategory: true,
        orderField : field,
        orderType: type
      }
    })
  },
  getContentFileList (contentId) {
    return request({
      url: `${urlPrefix}/directive/contentFileList?contentId=${contentId}`,
      method: 'get'
    })
  },
  getCategoryCode (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: 'get'
    })
  },
  getCategoryListAttr (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}?containsAttribute=true`,
      method: 'get'
    })
  },
  getCategoryId (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}`,
      method: 'get'
    })
  },
  getDictionary (code) {
    return request({
      url: `${urlPrefix}/product/dict?code=${code}`,
      method: 'get'
    })
  },
  getContentTap (categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        containChildCategory: true
      }
    })
  },

  getCategoryAttributeList (id) {
    return request({
      url: `${urlPrefix}/category/list`,
      method: 'post',
      data: {
        parentId: id
      }
    })
  },
}

export default ProsService
