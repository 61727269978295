<template>
  <div class="skin-strong-point-area">
    <ProductStrongPoint
      v-for="(it, idx) in info"
      :key="idx"
      :iconSrc="it.iconSrc"
      :title="it.title"
      :description="it.description"
    />
  </div>
</template>

<script>
import ProductStrongPoint from '@/components/product/ProductStrongPoint'

export default {
  components: {
    ProductStrongPoint
  },

  props: {
    info: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.skin-strong-point-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 768px) {
  .skin-strong-point-area {
    padding-left: 0px;
    padding-right: 0px;
  }
}

</style>
