<template>
  <div class="environmental-investment-text-card">
    <div class="title-area">
      <pre class="year">{{ year }}</pre>
      <pre class="unit">{{ unit }}</pre>
    </div>
    <div class="text-area">
      <pre class="text">{{ text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    year: {
      type: String
    },
    unit: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
.environmental-investment-text-card {
  display: flex;
  padding: 43px 29px 43px 70px;
  align-items: center;
  align-self: stretch;
  border-radius: 30px;
  background: #F5F5F5;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .environmental-investment-text-card {
    width: 100%;
    flex-direction: column;
    padding: 30px;
    align-items: flex-start;
  }
}

.environmental-investment-text-card .title-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}

.environmental-investment-text-card .title-area .year {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .environmental-investment-text-card .title-area .year {
    font-size: 0.875rem;
    line-height: 1.4583333333rem;
  }
}

.environmental-investment-text-card .title-area .unit {
  color: #B40F2C;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 94.737% */
}

@media (max-width: 768px) {
  .environmental-investment-text-card .title-area .unit {
    font-size: 1.2916666667rem;
    line-height: 1.3;
  }
}

.environmental-investment-text-card .text-area {
  margin-left: 80px;
}

@media (max-width: 768px) {
  .environmental-investment-text-card .text-area {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.environmental-investment-text-card .text-area .text {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .environmental-investment-text-card .text-area .text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
    text-align: center;
  }
}
</style>
