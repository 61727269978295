var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expanded-waste-recycling-grid" }, [
    _c("pre", { staticClass: "grid-title" }, [
      _vm._v(_vm._s(_vm.gridData.gridTitle)),
    ]),
    _c("div", { staticClass: "item-area" }, [
      _c("pre", { staticClass: "item" }, [_vm._v(_vm._s(_vm.gridData.item))]),
      _c(
        "ul",
        { staticClass: "item-list" },
        _vm._l(_vm.gridData.itemList, function (item) {
          return _c("li", { key: item }, [
            _c("span", { domProps: { innerHTML: _vm._s(item) } }),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }