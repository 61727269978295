import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import endsWith from 'lodash/endsWith'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import flatMap from 'lodash/flatMap'
import flattenDepth from 'lodash/flattenDepth'
import forEach from 'lodash/forEach'
import forIn from 'lodash/forIn'
import isUndefined from 'lodash/isUndefined'
import isBoolean from 'lodash/isBoolean'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import indexOf from 'lodash/indexOf'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import map from 'lodash/map'
import assign from 'lodash/assign'
import assignIn from 'lodash/assignIn'
import assignInWith from 'lodash/assignInWith'
import remove from 'lodash/remove'
import concat from 'lodash/concat'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import eq from 'lodash/eq'
import split from 'lodash/split'
import upperCase from 'lodash/upperCase'
import lowerCase from 'lodash/lowerCase'
import join from 'lodash/join'
import slice from 'lodash/slice'
import head from 'lodash/head'
import last from 'lodash/last'
import toNumber from 'lodash/toNumber'
import unionBy from 'lodash/unionBy'


export default {
  assign,
  assignIn,
  assignInWith,
  clone,
  cloneDeep,
  endsWith,
  debounce,
  throttle,
  find,
  isEmpty,
  flatten,
  flatMap,
  flattenDepth,
  forEach,
  forIn,
  isUndefined,
  isBoolean,
  isEqual,
  isArray,
  indexOf,
  toNumber,
  unionBy,
  groupBy,
  sumBy,
  map,
  remove,
  concat,
  merge,
  mergeWith,
  filter,
  includes,
  uniqBy,
  get,
  eq,
  upperCase,
  lowerCase,
  join,
  slice,
  split,
  head,
  last
}
