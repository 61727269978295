<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content order-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Header</li>
          <li>Cart</li>
          <li>Order Sample</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (UK Project Information) -->
      <div class="container order-inp-box order-sample-info">
        <div class="w-con-02 inner">
          <p class="tt-2">Project Information</p>
          <p class="noti ta-r">This is a required field</p>
          <div class="col-02 order-tbl order-tbl-big-box">
            <div class="tbl-01 order-tbl01">
              <div class="tbl-input">
                <table>
                  <caption>Project Information left form</caption>
                  <colgroup>
                    <col style="width:200px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody id="itemList">
                    <tr>
                      <th scope="row"><span class="must">Type of building</span></th>
                      <td class="cell-noti">
                        <div id="" class="sel-box type02">
                          <button id="btnState" type="button" class="btn-sel">
                            <span class="placeholder">select</span>
                          </button>
                          <div id="" class="sel-list">
                            <ul class="box">
                              <li>
                                <button type="button">
                                  option01
                                </button>
                              </li>
                              <li>
                                <button type="button">
                                  option02
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="noti tar" v-if="state == '' && submitChk == 'Y'">Select</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><span class="must">Project maturity</span></th>
                      <td class="cell-noti">
                        <div id="" class="sel-box type02">
                          <button id="btnState" type="button" class="btn-sel">
                            <span class="placeholder">select</span>
                          </button>
                          <div id="" class="sel-list">
                            <ul class="box">
                              <li>
                                <button type="button">
                                  option01
                                </button>
                              </li>
                              <li>
                                <button type="button">
                                  option02
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="noti tar" v-if="state == '' && submitChk == 'Y'">Select</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tbl-01 order-tbl02">
              <div class="tbl-input">
                <table>
                  <caption>Contact form2</caption>
                  <colgroup>
                    <col style="width:200px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row"><span class="must">Completion date</span></th>
                      <td class="cell-noti">
                        <div id="" class="sel-box type02">
                          <button id="btnState" type="button" class="btn-sel">
                            <span class="placeholder">select</span>
                          </button>
                          <div id="" class="sel-list">
                            <ul class="box">
                              <li>
                                <button type="button">
                                  option01
                                </button>
                              </li>
                              <li>
                                <button type="button">
                                  option02
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="noti tar" v-if="state == '' && submitChk == 'Y'">Select</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><span class="must">Project size</span></th>
                      <td class="cell-noti">
                        <div id="" class="sel-box type02">
                          <button id="btnState" type="button" class="btn-sel">
                            <span class="placeholder">select</span>
                          </button>
                          <div id="" class="sel-list">
                            <ul class="box">
                              <li>
                                <button type="button">
                                  option01
                                </button>
                              </li>
                              <li>
                                <button type="button">
                                  option02
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="noti tar" v-if="state == '' && submitChk == 'Y'">Select</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- E : col-02 -->

          <div class="more-box ta-c mb-20">
            <button type="button" class="btn" @click="Submit()"><span>Submit</span></button>
          </div>

        </div>
      </div>
      <!-- //container (UK Project Information) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import sampleOrderService from '@/server/sampleOrder.service'
import usService from '@/server/us.service'
import _ from '@/common/helper/lodash.js'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'OrderInfo',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      viewList: [],
      form: {
        viewList: [],
        countries: [],
        states: []
      },
      statesData: {
        US: [],
        CA: [],
        99: [],
        org: [{ id: { stateCd: '' }, stateNm: 'Select State/Province' }]
      },
      selectedCountry: 'Select Country',
      selectedCountryCd: '',
      selectedState: 'Select State/Province',
      selectedStateCd: '',
      memberInfo: {
        addr1: '',
        addr2: '',
        city: '',
        cmsName: null,
        companyNm: null,
        country: '',
        email: null,
        firstNm: '',
        id: '',
        job: null,
        lastNm: '',
        memGroup: '',
        memId: '',
        mktAgreeDate: null,
        mktAgreeYn: false,
        phone: '',
        stateCd: '',
        zipCd: ''
      },
      validation: {
        firstNm: {
          value: false,
          msg: 'Enter First Name'
        },
        lastNm: {
          value: false,
          msg: 'Enter Last Name'
        },
        addr1: {
          value: false,
          msg: 'Enter Street Address'
        },
        country: {
          value: false,
          msg: 'Select Country'
        },
        stateCd: {
          value: false,
          msg: 'Select State'
        },
        city: {
          value: false,
          msg: 'Enter City'
        },
        zipCd: {
          value: false,
          msg: 'Enter ZIP/Postal Code'
        },
        email: {
          value: false,
          msg: 'Enter Email Address'
        },
        phone: {
          value: false,
          msg: 'Phone number required for shipping purposes'
        }
      }
    }
  },
  async mounted () {
    this.init()
  },
  methods: {
    async init () {
      const promiseArr = []
      const that = this
      // 제품 선택이 없으면 메인으로
      this.viewList = this.$store.getters['order/getOrderItemList']

      // page content 정보
      promiseArr.push(sampleOrderService.getCountryList())
      promiseArr.push(usService.getStateList('US'))
      promiseArr.push(usService.getStateList('CA'))
      promiseArr.push(usService.getStateList('99'))
      const res = await Promise.all(promiseArr)
      // country, state
      this.form.countries = res[0].data.countries
      _.remove(this.form.countries, function (v) {
        return v.countryCd === '99'
      })
      this.statesData.US = res[1].data
      this.statesData.CA = res[2].data
      this.statesData['99'] = res[3].data

      // route로부터 받은 게 없을 경우 세션조회.
      const routeParams = this.$route.params.orderInfo
      // 인적사항 정보 화면에 출력
      if (!_.isUndefined(routeParams)) {
        _.assign(this.memberInfo, { ...routeParams })
      }

      // selected country
      const country = _.find(this.form.countries, (v) => {
        return v.countryCd === that.memberInfo.country
      })
      if (!_.isUndefined(country)) {
        this.selectedCountry = country.countryNm
        this.selectedCountryCd = country.countryCd
      }
      // selected state
      const stateUS = _.find(this.statesData.US, (v) => {
        return v.id.stateCd === that.memberInfo.stateCd
      })
      const stateCA = _.find(this.statesData.CA, (v) => {
        return v.id.stateCd === that.memberInfo.stateCd
      })
      const stateOT = _.find(this.statesData['99'], (v) => {
        return v.id.stateCd === that.memberInfo.stateCd
      })
      if (!_.isUndefined(stateUS)) {
        this.selectedStateCd = stateUS.id.stateCd
        this.selectedState = stateUS.stateNm
        this.form.states = this.statesData.US
      } else if (!_.isUndefined(stateCA)) {
        this.selectedStateCd = stateCA.id.stateCd
        this.selectedState = stateCA.stateNm
        this.form.states = this.statesData.CA
      } else if (!_.isUndefined(stateOT)) {
        this.selectedStateCd = stateOT.id.stateCd
        this.selectedState = stateOT.stateNm
        this.form.states = this.statesData['99']
      } else {
        this.form.states = this.statesData.org
      }

      // GTM checkout init
      digitalData.checkout = {
        ecom_currency_code: 'USD',
        actionField: {
          ecom_checkout_step: '1',
          ecom_checkout_option: 'paypal',
          ecom_order_country: this.getCountryNm(this.memberInfo.country),
          ecom_order_state: this.getStateNm(this.memberInfo.stateCd, this.memberInfo.country),
          ecom_order_city: this.memberInfo.city,
          ecom_order_zipcode: this.memberInfo.zipCd
        },
        products: []
      }
      _.forEach(this.viewList, function (v) {
        // GTM checkout push
        digitalData.checkout.products.push(that.makeDataLayerForm(v))
      })

      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({ event: 'ecCheckOut' })
      }
    },
    Submit () {
      location.href = 'order/confirm'
    },
    selectCountry (country) {
      this.selectedCountry = country.countryNm
      this.memberInfo.country = country.countryCd
      this.selectedCountryCd = country.countryCd
    },
    selectState (state) {
      this.selectedState = state.stateNm
      this.memberInfo.stateCd = state.id.stateCd
    },
    getStateNm (stateCd, countryCd) {
      const found = _.find(this.form.states[countryCd], (v) => {
        return v.id.countryCd === countryCd && v.id.stateCd === stateCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.stateNm
    },
    getCountryNm (countryCd) {
      const found = _.find(this.form.countries, (v) => {
        return v.countryCd === countryCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.countryNm
    },
    async countryChanged () {
      const that = this
      if (this.memberInfo.country && this.memberInfo.country.length > 0) {
        this.form.states = this.statesData[this.memberInfo.country]
        const found = _.find(this.form.states, function (v) {
          return v.id.stateCd === that.memberInfo.stateCd
        })
        if (!_.isUndefined(found)) {
          this.selectedState = found.stateNm
        } else {
          this.selectedState = 'Select State/Province'// 셀렉트 박스 표시 초기화
        }
      } else {
        this.form.states = this.statesData.org
        this.selectedState = 'Select State/Province'// 셀렉트 박스 표시 초기화
      }
    },
    calcOrder (price, qty) {
      // sample 당 가격산출
      return Number(price) * Number(qty)
    },
    calcSubTotal () {
      // 총 가격 산출
      let tot = 0
      _.forEach(this.viewList, (v) => {
        const priceList = v.content.attribute.price
        const qty = v.qty
        const price = priceList[v.sizeSeq - 1].price
        tot += qty * price
      })
      return tot.toFixed(2)
    },
    validFunction (name) {
      const that = this
      return {
        firstNm: function () {
          if (!_.isEmpty(that.memberInfo.firstNm)) {
            that.validation.firstNm.value = true
          } else {
            that.validation.firstNm.value = false
          }
        },
        lastNm: function () {
          if (!_.isEmpty(that.memberInfo.lastNm)) {
            that.validation.lastNm.value = true
          } else {
            that.validation.lastNm.value = false
          }
        },
        addr1: function () {
          if (!_.isEmpty(that.memberInfo.addr1)) {
            that.validation.addr1.value = true
          } else {
            that.validation.addr1.value = false
          }
        },
        country: function () {
          if (!_.isEmpty(that.memberInfo.country)) {
            that.validation.country.value = true
          } else {
            that.validation.country.value = false
          }
        },
        stateCd: function () {
          if (!_.isEmpty(that.memberInfo.stateCd)) {
            that.validation.stateCd.value = true
          } else {
            that.validation.stateCd.value = false
          }
        },
        city: function () {
          if (!_.isEmpty(that.memberInfo.city)) {
            that.validation.city.value = true
          } else {
            that.validation.city.value = false
          }
        },
        zipCd: function () {
          if (!_.isEmpty(that.memberInfo.zipCd)) {
            that.validation.zipCd.value = true
          } else {
            that.validation.zipCd.value = false
          }
        },
        email: function () {
          const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식
          that.validation.email.value = regex.test(that.memberInfo.email)
        },
        phone: function () {
          if (!_.isEmpty(that.memberInfo.phone)) {
            that.validation.phone.value = true
          } else {
            that.validation.phone.value = false
          }
        }
      }[name]()
    },
    checkout () {
      if (this.validateForm()) {
        this.$router.push({ name: 'checkout', params: { orderInfo: this.memberInfo } })
      }
    },
    validateForm () {
      const that = this
      let flagCnt = 0
      _.forIn(this.validation, function (v, k) {
        that.validFunction(k)
        if (!v.value) {
          alert(v.msg)
          flagCnt++
          return false
        }
      })
      return !(flagCnt > 0)
    },
    makeDataLayerForm (v) {
      let brand = ''
      let category = ''
      let size = ''
      let type = ''
      let color = ''
      let collection = ''
      let cateCode = ''

      if (_.includes(v.content.content.modelId, 'HFLOR')) {
        // HFLOR
        brand = v.content.parents[2].name
        category = v.content.parents[1].name
        collection = v.content.parents[0].name
        cateCode = v.content.parents[1].code
        if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
          const prdType = v.content.attribute.prdTypeText
          type = _.isArray(prdType) ? prdType.join('|') : prdType
        }
      } else {
        // Viatera, Himacs
        brand = v.content.parents[1].name
        category = ''
        collection = v.content.parents[0].name
        color = v.content.attribute.hueText // himacs, viatera only
      }
      size = v.sampleInfo.size
      const info = {
        ecom_prd_id: v.content.attribute.code == '' ? v.content.content.id : v.content.attribute.code,
        ecom_prd_name: v.content.content.title,
        ecom_prd_brand: brand,
        ecom_prd_category: category,
        ecom_prd_variant: size,
        ecom_prd_type: type,
        ecom_prd_collection: collection,
        ecom_prd_color: color,
        ecom_prd_price: v.content.attribute.price[v.sizeSeq - 1].price,
        ecom_prd_qty: v.qty
      }
      return info
    }
  },
  watch: {
    'memberInfo.country' (newVal) {
      this.countryChanged()
    }
  }
}
</script>
