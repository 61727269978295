<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content prod-dt-cate">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("other.text.home") }}</li>
          <li>{{ $t("other.text.products") }}</li>
          <li>{{ $t("other.text.himacs") }}</li>
          <li>{{ $t("other.text.hotherPD") }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Himacs Other Products) -->
      <div class="container prod-dt-view prod-op-view himacs-op container_pf">
        <!-- visual -->
        <div class="visual visual-detail">
          <div class="visual-image full_width center" v-if="bannerList[0].attribute.pc_img" v-bind:style="{ 'background-image' : 'url( ' + $imagePath + bannerList[0].attribute.pc_img + ')' }"> <!-- 요기 -->
            <div class="inner_width">
              <div class="txt-box">
                <h1 class="title">
                  <span class="txt">{{ bannerList[0].attribute.description_1 }}</span>
                </h1>
                <p v-html="subText" class="txt-02" id="text" style="line-height: 1.4"></p>
              </div>
            </div>
            <!-- <div class="bgdim"></div> -->
          </div>
        </div>

        <div class="inspr-view prod-fd-view w-con">
          <div class="t-type-01">
            <!-- <div v-html="subText" id="text" style="font-weight: 300; line-height: 30px;"></div>-->
            <div class="color-box">
              <ul>
                <li style="background-color:#fafbf8;"><p>{{ $t("other.text.arctic") }}</p></li>
                <li style="background-color:#fbfbf1;"><p>{{ $t("other.text.ivory") }}</p></li>
                <li style="background-color:#f8f4de;"><p>{{ $t("other.text.cream") }}</p></li>
                <li style="background-color:#e5d7ba;"><p>{{ $t("other.text.almond") }}</p></li>
              </ul>
            </div>
          </div>
          <div class="tab-ui tab-ui-dark">
            <ul>
              <li class="btn-tab on" id="all" name="tapMenu"><button type="button" @click="tapClick('HIMACS_OTHER')">{{ $t("other.text.all") }}</button></li>
              <li class="btn-tab" id="kit" name="tapMenu"><button type="button" @click="tapClick('HIMACS_OTHER_KIT')">{{ $t("other.text.kitchen") }}</button></li>
              <li class="btn-tab" id="bath" name="tapMenu"><button type="button" @click="tapClick('HIMACS_OTHER_BAT')">{{ $t("other.text.bathroom") }}</button></li>
              <li class="btn-tab" id="ada" name="tapMenu"><button type="button" @click="tapClick('HIMACS_OTHER_ADA')">{{ $t("other.text.ada") }}</button></li>
            </ul>
          </div>
          <div class="list-type-view row-04 inner">
            <ul class="list-thumb-demo">
              <li v-for="(content, index) in contentList">
                <div class="img-box">
                  <img v-if="content.attribute.pc_thunb !== undefined" :src="$imagePath+content.attribute.pc_thunb" alt="other product img" @click="popUpView(content)" />
                  <!--                  <p class="ico" :name="'content'+index">{{ content.parents[0].name }}</p>-->
                  <!--                    <a class="ico-wish" v-bind:class="{ on: content.content.liked }" @click="toggleLike(content, 'HIMACS')" v-if="authenticated">wish list</a>-->
                  <!--                    <a class="ico-wish" v-else href="login/" onclick="alert('로그인 후 이용해주세요.');">wish list</a>-->
                </div>

                <div class="info02">
                  <ul>
                    <li class="txt-02"><a href="javascript:void(0);">{{ content.content.title }}</a></li>
                    <li class="txt-03">{{ content.attribute.code }}</li>
                    <li class="txt-04">{{ content.parents[0].name }}</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="short-link type02 inner">
          <a @click="openCart()" v-if="nationCode === '_US'"><span class="ico-01">{{ $t('PCD.text.order_a_sample')}}</span></a>
          <a v-if="nationCode === '_GL'" :href="'support/contactus'"><span class="ico-07">{{ $t('PCD.text.contact_us')}}</span></a>
          <a href="pros/documentation/viatera"><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a>
          <a href="support/care-and-maintenance/viatera"><span class="ico-03">{{ $t('PCD.text.care_maintenance')}}</span></a>
          <a v-if="nationCode === '_US'" href="support/warranty/viatera"><span class="ico-04">{{ $t('PCD.text.warranty')}}</span></a>
          <a v-if="nationCode === '_US'" :href="'support/where-to-buy/country/us'"><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a>
          <a v-if="nationCode === '_GL'" :href="'support/where-to-buy/country/gl'"><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a>
        </div> -->

        <!-- popup : Other Products -->
        <div class="layer-popup layer-other">
          <div class="popup pop-close">
            <div class="pop-other scr-y">
              <p class="pop-other-title">{{ contentContent.title }}</p>
              <div class="inspr-view prod-fd-view prod-op-view">
                <div class="list-type-view tab-ui col-02">
                  <div>
                    <div class="img-box tab-con other01 on">
                      <img v-if="contentAttribute.pc_thunb !== undefined" :src="$imagePath+ contentAttribute.pc_thunb" alt="images" style="width: 100%"/>
                      <p class="ico">{{ popUpCateName }}</p>
                      <!--                      <a class="ico-wish" v-bind:class="{ on: contentContent.liked }" @click="toggleLike(content, 'HIMACS')" v-if="authenticated">wish list</a>-->
                      <!--                      <a class="ico-wish" v-else href="login/" onclick="alert('로그인 후 이용해주세요.');">wish list</a>-->
                    </div>
                  </div>
                  <div class="info">
                    <ul>
                      <li class="txt-01">{{ contentContent.title }}</li>
                      <li class="txt-02">{{ contentAttribute.code }}</li>
                    </ul>
                    <div class="tbl-box">
                      <table>
                        <caption>Product Specs</caption>
                        <colgroup>
                          <col style="width:187px">
                          <col style="width:auto">
                        </colgroup>
                        <tbody>
                        <tr v-if="contentAttribute.overall !== undefined">
                          <th scope="row">{{ $t("other.text.overall") }}</th>
                          <td>{{ contentAttribute.overall }}</td>
                        </tr>
                        <tr v-if="contentAttribute.inner !== undefined">
                          <th scope="row">{{ $t("other.text.inner") }}</th>
                          <td>{{ contentAttribute.inner }}</td>
                        </tr>
                        <tr v-if="contentAttribute.option !== undefined">
                          <th colspan="2">{{ contentAttribute.option }}</th>
                        </tr>
                        <tr v-if="contentAttribute.caution !== undefined">
                          <th colspan="2">{{ contentAttribute.caution }}</th>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="btns claer">
                      <div class="fl-l">
                        <button v-if="contentAttribute.pdf" type="button" class="btn-down" @click="downloadFile(contentAttribute.pdf)"><span>pdf</span></button>
                        <button v-if="contentAttribute.dxf" type="button" class="btn-down" @click="downloadFile(contentAttribute.dxf)"><span>DXF</span></button>
                      </div>
                      <div class="fl-r">
                        <button type="button" class="btn-cont" @click="contactUs()"><span>{{ $t("other.text.contactus") }}</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t("other.text.close") }}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : Other Products -->
      </div>
      <!-- //container (Other Products) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import ProductService from '@/server/product.service'
import categoryService from '@/server/category.service'
import global from '@/common/global'
import _ from '@/common/helper/lodash'
import LikeService from '@/server/like.service'
import { mapState } from 'vuex'

export default {
  name: 'OtherProducts',
  components: {
    HeaderComponent,
    QuickMenuComponent,
    ShortComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      nationCode: '',
      countryCodeSub: '',
      categoryName: '', /* 분류 코드 */
      bannerList: [{ attribute: '' }, { attribute: '' }], /* 배너 목록 */
      bannerAttribute: '', /* 배너 설명 */
      pc_img: '', /* 배너 이미지 */
      allList: [],
      contentList: [], /* other product 콘텐츠 리스트 */
      kitchenList: [],
      bathList: [],
      adaList: [],
      contentContent: {},
      contentAttribute: {},
      content: {},
      popUpliked: '',
      popUpTitle: '', /* (팝업)콘텐츠 제목 */
      popUpCode: '', /* (팝업)콘텐츠 코드 */
      popUpCateName: '', /* (팝업)콘텐츠 분류 이름 */
      popUpOverall: '', /* (팝업)콘텐츠 전체 치수  */
      popUpInner: '', /* (팝업)콘텐츠 내부 치수  */
      popUpOption: '',
      popUpDescription: '', /* (팝업)콘텐츠 설명  */
      popUpImage: '', /* (팝업)콘텐츠 이미지  */
      popUpPDF: '', /* (팝업)콘텐츠 PDF파일  */
      popUpDXF: '' /* (팝업)콘텐츠 DXF파일  */

    }
  },
  computed: {
    subText () {
      return this.bannerAttribute.replace('\n', '<br/>')
    },
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1) /* 국가 코드 */
  },
  async mounted () {
    const _this = this

    const categoryOther = 'HIMACS_OTHER' /* otherProduct 카테고리 코트 */
    const added = this.$countryCode // 국가코드
    _this.nationCode = added
    _this.categoryName = categoryOther + added
    if (categoryOther == 'HIMACS_OTHER_KIT') {
      $('li[name=tapMenu]').removeClass('on')
      $('#kit').addClass('on')
    } else if (categoryOther == 'HIMACS_OTHER_BAT') {
      $('li[name=tapMenu]').removeClass('on')
      $('#bath').addClass('on')
    } else if (categoryOther == 'HIMACS_OTHER_ADA') {
      $('li[name=tapMenu]').removeClass('on')
      $('#ada').addClass('on')
    } else {
      $('li[name=tapMenu]').removeClass('on')
      $('#all').addClass('on')
    }

    /* banner 리스트 */
    const bannerCode = 'BAN_HIMACS_OTHER' + added
    const categoryRes = await categoryService.getCategoryCode(bannerCode) /* 배너 카테고리 */
    const categoryId = categoryRes.data.object.id /* 배너 카테고리 아이디 */
    const contentRes = await ProductService.getContentList(categoryId) /* 배너 콘텐츠 */
    // let bannerAttribute = await categoryService.getCategoryListAttr(categoryId)        /* 배너 attribute */
    _this.bannerList = contentRes.data.list /* 배너 콘텐츠 리스트 */
    _this.bannerAttribute = _this.bannerList[0].attribute.description_2 /* 배너 attribute */

    /* other product 리스트 */
    const otherCategory = await categoryService.getCategoryCode(_this.categoryName) /* otherProduct 카테고리 */
    const otherCateId = otherCategory.data.object.id /* otherProduct 카테고리 아이디 */
    const otherContentList = await ProductService.getContentListChild(otherCateId) /* otherProduct 콘텐츠 */
    _this.allList = otherContentList.data.list /* otherProduct 콘텐츠 리스트 */
    _this.contentList = _this.allList

    /* visual slide */
    _this.$nextTick(function () {
      _this.kitchenList = _.filter(_this.allList, function (c) {
        return c.parents[0].code == 'HIMACS_OTHER_KIT' + added
      })
      _this.bathList = _.filter(_this.allList, function (c) {
        return c.parents[0].code == 'HIMACS_OTHER_BAT' + added
      })
      _this.adaList = _.filter(_this.allList, function (c) {
        return c.parents[0].code == 'HIMACS_OTHER_ADA' + added
      })

      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      /* visual slide > button pause */
      $('.slick-dots').append('<li class="btn-pause"><button type="button">pause</button></li>')
      $('.slick-dots').append('<li class="btn-play"><button type="button">pause</button></li>')

      $('.slick-dots .btn-play').hide()

      $('.btn-pause').click(function () {
        $('.slider-prod').slick('slickPause')
        $(this).hide()
        $('.slick-dots .btn-play').show()
      })

      $('.btn-play').click(function () {
        $('.slider-prod').slick('slickPlay')
        $(this).hide()
        $('.slick-dots .btn-pause').show()
      })
    })
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async popUpView (content) { /* 콘텐츠 상세보기 팝업 */
      const _this = this
      _this.content = content
      _this.contentContent = content.content
      _this.contentAttribute = content.attribute
      _this.popUpCateName = content.parents[0].name
      _this.popUpliked = content.content.liked
      LayerPopup.open('layer-other')
      return false
    },
    async downloadFile (file) {
      const url = this.$imagePath + file
      try {
        const element = document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        element.setAttribute('download', file)
        element.click()
      } catch (error) {
        console.log(error)
      }
    },
    async tapClick (category) {
      const _this = this
      const added = this.$countryCode
      _this.categoryName = category + added /* otherProduct 카테고리 코트 */
      if (category == 'HIMACS_OTHER_KIT') {
        $('li[name=tapMenu]').removeClass('on')
        $('#kit').addClass('on')
        _this.contentList = _this.kitchenList
      } else if (category == 'HIMACS_OTHER_BAT') {
        $('li[name=tapMenu]').removeClass('on')
        $('#bath').addClass('on')
        _this.contentList = _this.bathList
      } else if (category == 'HIMACS_OTHER_ADA') {
        $('li[name=tapMenu]').removeClass('on')
        $('#ada').addClass('on')
        _this.contentList = _this.adaList
      } else {
        $('li[name=tapMenu]').removeClass('on')
        $('#all').addClass('on')
        _this.contentList = _this.allList
      }
      // let otherCategory = await categoryService.getCategoryCode(_this.categoryName);     /* otherProduct 카테고리 */
      // let otherCateId = otherCategory.data.object.id;                    /* otherProduct 카테고리 아이디 */
      // let otherContentList = await ProductService.getContentListChild(otherCateId, '');     /* otherProduct 콘텐츠 */
      // _this.contentList = otherContentList.data.list;                    /* otherProduct 콘텐츠 리스트 */
    },
    contactUs () {
      location.href = 'support/contactus'
    },
    toggleLike (sr, cateType) {
      this.$utils.toggleLike(sr, 'PD', cateType, this)
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    }
  }
}
</script>
