var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "social-responsibility-table-header",
      style: { background: _vm.background },
    },
    [_c("pre", { staticClass: "header-text" }, [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }