var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-cate pcd_content" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("PCD.text.home")) + " ")]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            _c("li", [
              _vm._v(_vm._s(_vm.$t(`PCD.text.${_vm.convertToCamelCase}`))),
            ]),
            _vm.isSubPage
              ? _c("li", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        `PCD.text.sub.${_vm.$route.meta.type.toLowerCase()}`
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm.isAboutPage
              ? _c("li", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        `PCD.text.about.${_vm.$route.meta.type.toLowerCase()}`
                      )
                    )
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { class: _vm.pcdContainerCssClass }, [
          _vm.pcdContentAttribute.main_use_yn === "true"
            ? _c("div", { staticClass: "visual visual-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "visual-image full_width center",
                    style: {
                      backgroundImage: `url('${_vm.$imagePath}${_vm.pcdContentAttribute.main_img}')`,
                    },
                  },
                  [
                    _c("div", { staticClass: "inner_width" }, [
                      _c("div", { staticClass: "txt-box" }, [
                        _c("h1", [
                          _c("div", { staticClass: "title" }, [
                            _vm.visualImageLoadError
                              ? _c("span", { staticClass: "txt" }, [
                                  _vm._v(
                                    _vm._s(_vm.pcdContentAttribute.main_title)
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.visualImageLoadError
                              ? _c("img", {
                                  staticClass: "visual_logo",
                                  attrs: {
                                    src:
                                      _vm.$imagePath +
                                      "/2024/images/main/logo/" +
                                      _vm.$route.meta.type.toUpperCase() +
                                      ".svg",
                                    alt: "about product",
                                  },
                                  on: {
                                    error: function ($event) {
                                      _vm.visualImageLoadError = true
                                    },
                                    load: function ($event) {
                                      _vm.visualImageLoadError = false
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c("p", { staticClass: "txt-02" }, [
                            _vm._v(_vm._s(_vm.pcdContentAttribute.main_desc)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.intrd_use_yn === "true"
            ? _c("div", { staticClass: "video-box full_width" }, [
                _c("div", { staticClass: "inner_width" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "video-area" }, [
                      _c("div", { staticClass: "video" }, [
                        (_vm.movie && !_vm.youtube) ||
                        (_vm.movie && _vm.youtube)
                          ? _c("video", {
                              attrs: {
                                controlsList: "nodownload",
                                oncontextmenu: "return false",
                                autoplay: "autoplay",
                                muted: "muted",
                                controls: "",
                                src: _vm.movie,
                                title: "YouTube video player",
                              },
                              domProps: { muted: true },
                            })
                          : _vm._e(),
                        !_vm.movie && _vm.youtube
                          ? _c("iframe", {
                              attrs: {
                                autoplay: "autoplay",
                                muted: "muted",
                                controls: "",
                                src: _vm.youtube,
                                title: "YouTube video player",
                              },
                            })
                          : _vm._e(),
                        !_vm.movie && !_vm.youtube
                          ? _c("img", {
                              staticClass: "etc_img",
                              attrs: {
                                src:
                                  _vm.$imagePath +
                                  _vm.pcdContentAttribute.intrd_img,
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "txt_area" }, [
                      _c("p", { staticClass: "con_title ctt ctt-t2" }, [
                        _vm._v(_vm._s(_vm.pcdContentAttribute.intrd_title)),
                      ]),
                      _c("h2", { staticClass: "con_subject tt-1" }, [
                        _vm._v(_vm._s(_vm.pcdContentAttribute.intrd_sub_title)),
                      ]),
                      _c("p", { staticClass: "ctt ctt-t3" }, [
                        _vm._v(_vm._s(_vm.pcdContentAttribute.intrd_desc)),
                      ]),
                      _c("div", { staticClass: "btn_area" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button h44 btn_view_prod_category",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goToPfPage()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("See all Designs"),
                            ]),
                            _c("span", {
                              staticClass: "icon icon_arrow_right",
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.mb_use_yn === "true"
            ? _c("div", { staticClass: "banner_box whatsnew_section" }, [
                _c("div", { staticClass: "full_width" }, [
                  _c("div", { staticClass: "inner_width" }, [
                    _c("div", { staticClass: "content_tit_area" }, [
                      _c("h2", { staticClass: "con_title tt-1" }, [
                        _vm._v(_vm._s(_vm.pcdContentAttribute.mb_title)),
                      ]),
                    ]),
                    _c("div", { staticClass: "whatsnew_slider_wrap" }, [
                      _c(
                        "div",
                        { staticClass: "whatsnew_slider" },
                        _vm._l(
                          _vm.pcdMiddleBannerData,
                          function (middleItem, index) {
                            return _c("div", { staticClass: "item" }, [
                              _c("div", { staticClass: "img_wrap" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.$imagePath + middleItem.image,
                                    alt: middleItem.title,
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "over_txt_box" }, [
                                _c("h2", { staticClass: "title tt-2" }, [
                                  _vm._v(_vm._s(middleItem.title)),
                                ]),
                                _c("p", { staticClass: "txt-01" }, [
                                  _vm._v(_vm._s(middleItem.description)),
                                ]),
                                !_vm.isEmpty(middleItem.url)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "btn-more",
                                        attrs: {
                                          href: middleItem.url,
                                          target:
                                            _vm.pcdMenuType == "HFLOR"
                                              ? "_blank"
                                              : "_self",
                                        },
                                      },
                                      [_c("span", [_vm._v("View more")])]
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.collection_use_yn === "true"
            ? _c("div", { staticClass: "collection_section full_width" }, [
                _c("div", { staticClass: "inner_width" }, [
                  _c("div", { staticClass: "content_tit_area" }, [
                    _c("h2", { staticClass: "con_title tt-1" }, [
                      _vm._v(_vm._s(_vm.pcdContentAttribute.collection_title)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "more",
                        on: {
                          click: function ($event) {
                            return _vm.goToPfPage()
                          },
                        },
                      },
                      [_vm._m(0)]
                    ),
                  ]),
                  _c("div", { staticClass: "collection_content" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.collectionPageList, function (coll, index) {
                        return _c("li", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "tit_area" },
                            [
                              coll.categoryAttribute.new_yn === "true"
                                ? _c("span", { staticClass: "tag h30 new" }, [
                                    _vm._v("new"),
                                  ])
                                : _vm._e(),
                              coll.categoryAttribute.best_yn === "true"
                                ? _c("span", { staticClass: "tag h30 best" }, [
                                    _vm._v("best"),
                                  ])
                                : _vm._e(),
                              coll.categoryAttribute.title_name
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "collection_title tt-24" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            coll.categoryAttribute.title_name
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "span",
                                      { staticClass: "collection_title tt-24" },
                                      [
                                        _vm._v(
                                          _vm._s(coll.category.name) +
                                            " " +
                                            _vm._s(
                                              _vm.$t("PCD.text.collection")
                                            )
                                        ),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _c("div", { staticClass: "thumb_area" }, [
                            _c("div", { staticClass: "thumb_list" }, [
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.collectionschildList[coll.category.id],
                                  function (coll2, index2) {
                                    return _c("li", { key: index2 }, [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                        },
                                        [
                                          !_vm.isEmpty(coll2.orgContent)
                                            ? [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      _vm.$imagePath +
                                                      coll2.orgContent.cover,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goToPdPage(
                                                        coll,
                                                        coll2
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c("div", { staticClass: "btn_area" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn_collection_plus",
                                  attrs: {
                                    type: "button",
                                    "data-cate-id": coll.category.id,
                                  },
                                  on: { click: _vm.showCollectionContent },
                                },
                                [_c("span", { staticClass: "icon icon_plus" })]
                              ),
                            ]),
                            _vm.collectionPopCateId === coll.category.id
                              ? _c("div", { staticClass: "collection_pop" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col_pop_tit_area" },
                                    [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v("Browse Colors"),
                                      ]),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn_collection_close",
                                          attrs: { type: "button" },
                                          on: {
                                            click: _vm.hideCollectionContent,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "icon icon_close",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col_pop_con_area" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "collection_list" },
                                        [
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.collectionschildList[
                                                coll.category.id
                                              ],
                                              function (coll2, index2) {
                                                return _c(
                                                  "li",
                                                  { key: index2 },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "img_wrap",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                          },
                                                          [
                                                            !_vm.isEmpty(
                                                              coll2.orgContent
                                                            )
                                                              ? [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        _vm.$imagePath +
                                                                        coll2
                                                                          .orgContent
                                                                          .cover,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.goToPdPage(
                                                                            coll,
                                                                            coll2
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "img-box" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.goToFinder(coll)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.$imagePath +
                                      coll.categoryAttribute.image,
                                    alt: `${coll.categoryAttribute.title_name}`,
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "txt-box" }, [
                            _c("p", { staticClass: "comt ctt ctt-t4" }, [
                              _vm._v(_vm._s(coll.categoryAttribute.Des)),
                            ]),
                          ]),
                          _c("div", { staticClass: "btn_area" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button h44 btn_explore_collection",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToFinder(coll)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("PCD.button.explore_collection")
                                    )
                                  ),
                                ]),
                                _c("span", {
                                  staticClass: "icon icon_arrow_right",
                                }),
                              ]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _vm.isVisibleCollectionLoadMore()
                    ? _c("div", { staticClass: "btn_area" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn_load_more",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.loadMore()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Load more"),
                            ]),
                            _c("span", { staticClass: "icon icon_plus" }),
                          ]
                        ),
                      ])
                    : _c("div", { staticClass: "height-70" }),
                ]),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.template_use_yn === "true"
            ? _c("div", { staticClass: "why_section" }, [
                _c("div", { staticClass: "full_width" }, [
                  _c("div", { staticClass: "inner_width" }, [
                    _c("div", { staticClass: "content_tit_area" }, [
                      _c("h2", { staticClass: "con_title tt-1" }, [
                        _vm._v(_vm._s(_vm.pcdContentAttribute.template_title)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm.pcdTemplateFieldData.image.length
                  ? _c("div", { staticClass: "why_top_bg full_width" }, [
                      _c(
                        "div",
                        { staticClass: "inner_width" },
                        [
                          _vm._l(
                            Object.keys(_vm.pcdTemplateFieldData.image),
                            function (images, index) {
                              return _vm._l(
                                Object.keys(
                                  _vm.pcdTemplateFieldData.image[index]
                                ),
                                function (imageKey, index2) {
                                  return _c(
                                    "div",
                                    { class: `why_row col_0${imageKey}` },
                                    _vm._l(
                                      _vm.pcdTemplateFieldData.image[index][
                                        imageKey
                                      ],
                                      function (imageItem, index3) {
                                        return _c(
                                          "div",
                                          { staticClass: "why_item" },
                                          [
                                            _c("div", { staticClass: "img" }, [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.$imagePath +
                                                    imageItem.img,
                                                  alt: imageItem.title,
                                                },
                                              }),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "description_box",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "title tt-3" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(imageItem.title)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "txt ctt ctt-t3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(imageItem.desc)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                }
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.pcdTemplateFieldData.icon.length
                  ? _c("div", { staticClass: "w-con full_width" }, [
                      _c("div", { staticClass: "inner_width" }, [
                        _c(
                          "div",
                          { staticClass: "row combo_area" },
                          [
                            _vm._l(
                              _vm.pcdTemplateFieldData.icon,
                              function (iconItem, index) {
                                return [
                                  iconItem.img
                                    ? _c(
                                        "div",
                                        { staticClass: "content_item" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "icon_box" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.$imagePath +
                                                    iconItem.img,
                                                  alt: iconItem.title,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "description_box" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "title tt-30" },
                                                [_vm._v(_vm._s(iconItem.title))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "txt ctt ctt-t3",
                                                },
                                                [_vm._v(_vm._s(iconItem.desc))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.cert_use_yn === "true"
            ? _c("div", { staticClass: "certification_section full_width" }, [
                _c("div", { staticClass: "inner_width" }, [
                  _c("div", { staticClass: "content_tit_area" }, [
                    _c("h2", { staticClass: "con_title tt-1" }, [
                      _vm._v(_vm._s(_vm.pcdContentAttribute.cert_title)),
                    ]),
                  ]),
                  _c("div", { staticClass: "certi_group" }, [
                    _c(
                      "ul",
                      _vm._l(
                        _vm.pcdCertificationData,
                        function (certItem, index) {
                          return _c("li", [
                            _c("div", { staticClass: "item" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + certItem.image,
                                  alt: certItem.description,
                                },
                              }),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "btn_area" },
                      [
                        _vm.pcdCertificationData.length &&
                        _vm.checkCertificationDescription("description")
                          ? [
                              _c(
                                "button",
                                {
                                  staticClass: "btn_learn_more",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openModalCerti()
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v("Learn more"),
                                  ]),
                                  _c("span", {
                                    staticClass: "icon icon_arrow_right",
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.inpg_use_yn === "true"
            ? _c("div", { staticClass: "inspiration_section full_width" }, [
                _c("div", { staticClass: "inner_width" }, [
                  _c("div", { staticClass: "content_tit_area" }, [
                    _c("h2", { staticClass: "con_title tt-1" }, [
                      _vm._v(_vm._s(_vm.pcdContentAttribute.inpg_title)),
                    ]),
                    _c("div", { staticClass: "more" }, [
                      _c(
                        "a",
                        {
                          staticClass: "view_more",
                          attrs: {
                            href: `inspiration/gallery/${_vm.getPcdTypeName(
                              _vm.pcdMenuType
                            )}/product_cate`,
                          },
                        },
                        [
                          _c("span", [_vm._v("View more")]),
                          _c("span", { staticClass: "icon" }),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "gallery" }, [
                    _c("div", { staticClass: "grid-box" }, [
                      _c(
                        "ul",
                        { staticClass: "ins_grid" },
                        _vm._l(
                          _vm.pcdInspirationList,
                          function (inspirationItem, index) {
                            return _c("li", { staticClass: "gal-grid-cont" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "g-box",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.gotolink(
                                        `/${_vm.countryCode.toLowerCase()}/inspiration/gallery-detail?contentId=${
                                          inspirationItem.content.id
                                        }`
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.$imagePath +
                                          inspirationItem.attribute
                                            .mainThumbnail,
                                        alt: inspirationItem.content.title,
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "txt-box" }, [
                                    _c(
                                      "p",
                                      { staticClass: "txt-cate" },
                                      [
                                        _vm._l(
                                          inspirationItem.attribute
                                            .product_cateText,
                                          function (productItem, productIndex) {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML:
                                                    _vm._s(productItem),
                                                },
                                              }),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c("p", { staticClass: "content_title" }, [
                                      _vm._v(
                                        _vm._s(inspirationItem.content.title)
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "hashtag_group" },
                                      _vm._l(
                                        inspirationItem.attribute
                                          .applicationList,
                                        function (tagItem, tagIndex) {
                                          return _c(
                                            "span",
                                            { staticClass: "hashtag" },
                                            [_vm._v("#" + _vm._s(tagItem.text))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.featPrds.length
            ? _c("div", { staticClass: "related_section" }, [
                _c("div", { staticClass: "full_width" }, [
                  _c("div", { staticClass: "inner_width" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.featShow === true,
                            expression: "featShow === true",
                          },
                        ],
                        staticClass: "slider-related flex pcd_rel_prod_slider",
                      },
                      _vm._l(_vm.featPrds, function (feat, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "over-box",
                              attrs: {
                                "data-ec-product": _vm.makeDataLayerForm(feat),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToPcdPage(feat)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "over" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.$imagePath +
                                          feat.attribute.main_img,
                                        tabindex: "0",
                                        alt: feat.attribute.main_title,
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "view" }),
                            ]
                          ),
                          _c("div", { staticClass: "info" }, [
                            _c("ul", [
                              _c("li", { staticClass: "txt-02" }, [
                                _c("span", [
                                  _vm._v(_vm._s(feat.attribute.main_title)),
                                ]),
                              ]),
                              _c("li", { staticClass: "txt-03" }, [
                                _vm._v(_vm._s(feat.attribute.main_desc)),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "more_btn_area",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToPcdPage(feat)
                                  },
                                },
                              },
                              [_vm._m(2, true)]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.pcdContentAttribute.cert_use_yn === "true"
            ? _c(
                "div",
                {
                  staticClass:
                    "layer-view layer-pop popup-w1200 modal-certification",
                },
                [
                  _c("div", { staticClass: "popup pop-close" }, [
                    _c("div", { staticClass: "scr-y" }, [
                      _c("h3", { staticClass: "popup-title p-ui" }, [
                        _vm._v("Certification Description"),
                      ]),
                      _vm._m(3),
                      _c("div", { staticClass: "popup-body" }, [
                        _c("div", { staticClass: "certi_pop_con" }, [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.pcdCertificationData,
                              function (certItem, index) {
                                return _c("li", [
                                  certItem.description
                                    ? _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "img_wrap" }, [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.$imagePath + certItem.image,
                                              alt: certItem.description,
                                            },
                                          }),
                                        ]),
                                        _c("div", { staticClass: "txt_wrap" }, [
                                          _c(
                                            "div",
                                            { staticClass: "tit_box" },
                                            [_vm._v(_vm._s(certItem.title))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "txt_box" },
                                            [
                                              _vm._v(
                                                _vm._s(certItem.description)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.closeModalCerti()
                          },
                        },
                      },
                      [_vm._v("close")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "view_more", attrs: { href: "javascript:void(0);" } },
      [_c("span", [_vm._v("View more")]), _c("span", { staticClass: "icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_tit_area" }, [
      _c("h2", { staticClass: "con_title tt-1" }, [_vm._v("related products")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "view_more" }, [
      _c("span", { staticClass: "txt" }, [_vm._v("view more")]),
      _c("span", { staticClass: "icon icon_arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "popup-title m-ui" }, [
      _vm._v("Certification "),
      _c("br"),
      _vm._v(" Description"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }