<template>
  <OverviewSection
    type="minor"
    title="Social Contribution Strategy System"
    color="#222"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >

  <template #header>
    <SustainabilityText :text="text"/>
  </template>

  <OverviewSectionItem isUnderSectionItem>
    <SocialContributionStrategySystemVision
      :point="vision.point"
      :title="vision.title"
      :text="vision.text"
      :image="vision.image"
      :borderTitle="vision.borderTitle"
    />
  </OverviewSectionItem>

  <OverviewSectionItem>
    <SocialContributionStrategySystemKey
      :borderTitle="key.borderTitle"
      :cardItems="key.cardItems"
    />
  </OverviewSectionItem>

  <OverviewSectionItem>
    <SocialContributionStrategySystemGoal
      :borderTitle="goal.borderTitle"
      :cardItems="goal.cardItems"
    />
  </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    type="minor"
    title="社会貢献戦略システム"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >

    <template #header>
      <SustainabilityText :text="text_jp"/>
    </template>

    <OverviewSectionItem isUnderSectionItem>
      <SocialContributionStrategySystemVision
        :point="vision_jp.point"
        :title="vision_jp.title"
        :text="vision_jp.text"
        :image="vision_jp.image"
        :borderTitle="vision_jp.borderTitle"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SocialContributionStrategySystemKey
        :borderTitle="key_jp.borderTitle"
        :cardItems="key_jp.cardItems"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SocialContributionStrategySystemGoal
        :borderTitle="goal_jp.borderTitle"
        :cardItems="goal_jp.cardItems"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    type="minor"
    title="Система стратегий социального вклада"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >

    <template #header>
      <SustainabilityText :text="text_ru"/>
    </template>

    <OverviewSectionItem isUnderSectionItem>
      <SocialContributionStrategySystemVision
        :point="vision_ru.point"
        :title="vision_ru.title"
        :text="vision_ru.text"
        :image="vision_ru.image"
        :borderTitle="vision_ru.borderTitle"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SocialContributionStrategySystemKey
        :borderTitle="key_ru.borderTitle"
        :cardItems="key_ru.cardItems"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SocialContributionStrategySystemGoal
        :borderTitle="goal_ru.borderTitle"
        :cardItems="goal_ru.cardItems"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    type="minor"
    title="社会贡献战略体系"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >

    <template #header>
      <SustainabilityText :text="text_cn"/>
    </template>

    <OverviewSectionItem isUnderSectionItem>
      <SocialContributionStrategySystemVision
        :point="vision_cn.point"
        :title="vision_cn.title"
        :text="vision_cn.text"
        :image="vision_cn.image"
        :borderTitle="vision_cn.borderTitle"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SocialContributionStrategySystemKey
        :borderTitle="key_cn.borderTitle"
        :cardItems="key_cn.cardItems"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SocialContributionStrategySystemGoal
        :borderTitle="goal_cn.borderTitle"
        :cardItems="goal_cn.cardItems"
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../components/SustainabilityText.vue'
import SocialContributionStrategySystemGoal from './SocialContributionStrategySystemGoal.vue'
import SocialContributionStrategySystemKey from './SocialContributionStrategySystemKey.vue'
import SocialContributionStrategySystemVision from './SocialContributionStrategySystemVision.vue'
export default {
  components: {
    SocialContributionStrategySystemVision,
    SocialContributionStrategySystemKey,
    SocialContributionStrategySystemGoal,
    OverviewSection,
    OverviewSectionItem,
    SustainabilityText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'At LX Hausys, we undertake social contribution initiatives by utilizing the capabilities of the company and its employees\n' +
            'with the goal of mutually-benefi cial growth with the local community.\n' +
            'We are bringing changes for the better to our society through various activities such as improving the\n' +
            'environment of remembrance facilities,improving the living environment of people of national merit,\n' +
            'preserving the environment of cultural heritage, and sharing activities with the local community.',
      vision: {
        point: 'images/aboutus/sustainability/social_responsibility/point.png',
        title: 'Nature,\nHuman and\n Space',
        text: 'Social contribution initiatives that transform\npeople and their environments for the better',
        image: 'images/aboutus/sustainability/social_responsibility/plus.png',
        borderTitle: 'VISION'
      },
      key: {
        borderTitle: 'Key Activities',
        cardItems: [
          {
            number: '01',
            text: 'Activities to improve the\nenvironment of\nremembrance facilities'
          },
          {
            number: '02',
            text: 'Activities to improve the\nliving environment of\npeople of national merit'
          },
          {
            number: '03',
            text: 'Environmental preservation\nactivities for\ncultural heritage'
          },
          {
            number: '04',
            text: 'Sharing activities for\nlocal communities'
          }
        ]
      },
      goal: {
        borderTitle: 'GOAL',
        cardItems: [
          {
            number: '01',
            text: 'Consistently fulfill social responsibility using strengths of LX Hausys'
          },
          {
            number: '02',
            text: 'Seek partnership through creation of social values rather than simple donations'
          },
          {
            number: '03',
            text: 'Improve relationship with local community'
          }
        ]
      },
      text_jp: 'LXハウシスでは、地域社会との共存共栄を目指し、会社と従業員の力を活かした社会貢献活動に取り組んでいます。\n'+
      '慰霊施設の環境整備、国家功労者の生活環境整備、文化遺産の環境保全、地域社会との活動共有など、\n'+
      'さまざまな活動を通じて社会に良い変化をもたらしています。',
      vision_jp: {
        point: 'images/aboutus/sustainability/social_responsibility/point.png',
        title: '自然、\n人間、\nそして宇宙',
        text: '人と環境をより良い方向へ\n変える社会貢献活動',
        image: 'images/aboutus/sustainability/social_responsibility/plus.png',
        borderTitle: 'ビジョン'
      },
      key_jp: {
        borderTitle: '主要な活動',
        cardItems: [
          {
            number: '01',
            text: '追悼施設の\n環境改善活動'
          },
          {
            number: '02',
            text: '国家功労者等の\n生活環境の改善を図る活動'
          },
          {
            number: '03',
            text: '文化遺産の\n環境保全活動'
          },
          {
            number: '04',
            text: '地域社会のための\n活動の共有'
          }
        ]
      },
      goal_jp: {
        borderTitle: 'ゴール',
        cardItems: [
          {
            number: '01',
            text: 'LX Hausysの強みを活かして社会的責任を一貫して果たす'
          },
          {
            number: '02',
            text: '単なる寄付ではなく社会的価値の創造を通じてパートナーシップを模索する'
          },
          {
            number: '03',
            text: '地域社会との関係を改善する'
          }
        ]
      },
      text_ru: 'В LX Hausys мы реализуем инициативы по социальному участию, используя возможности компании и ее сотрудников\n'+
      'с целью взаимовыгодного развития с местным сообществом. Мы привносим позитивные изменения в наше общество\n'+ 
      'посредством различных мероприятий, таких как улучшение среды памятных мест, улучшение среды проживания людей,\n'+
      'имеющих национальные заслуги, сохранение культурного наследия и совместная деятельность с местным сообществом.',
      vision_ru: {
        point: 'images/aboutus/sustainability/social_responsibility/point.png',
        title: 'Природа, \nчеловек и \nпространство',
        text: 'Социальные инициативы, которые преобразуют \nлюдей и их среду в лучшую сторону',
        image: 'images/aboutus/sustainability/social_responsibility/plus.png',
        borderTitle: 'Видение'
      },
      key_ru: {
        borderTitle: 'Основные мероприятия',
        cardItems: [
          {
            number: '01',
            text: 'Мероприятия по улучшению \nобстановки в мемориальных \nкомплексах'
          },
          {
            number: '02',
            text: 'Мероприятия по улучшению \nусловий жизни людей \nс национальными заслугами'
          },
          {
            number: '03',
            text: 'Мероприятия по сохранению \nокружающей среды для \nкультурного наследия'
          },
          {
            number: '04',
            text: 'Улучшение отношений \nс местным сообществом'
          }
        ]
      },
      goal_ru: {
        borderTitle: 'Цель',
        cardItems: [
          {
            number: '01',
            text: 'Последовательно выполнять социальную ответственность, используя сильные стороны LX Hausys'
          },
          {
            number: '02',
            text: 'Стремитесь к партнерству через создание социальных ценностей, а не через простые пожертвования'
          },
          {
            number: '03',
            text: 'Улучшение отношений с местным сообществом'
          }
        ]
      },
      text_cn: 'LX Hausys以与社区的共赢为目标，正开展体现工作特性和优势的社会贡献活动。\n'+
      'LX Hausys通过稳定的贡献活动创造社会价值，为社区的积极变化做出贡献，如改善纪念设施及国家功臣居住环境、\n'+
      '保护文化遗产环境，社会贡献活动。',
      vision_cn: {
        point: 'images/aboutus/sustainability/social_responsibility/point.png',
        title: '自然、\n人类与空间',
        text: '为自然和人类的空间带来幸福变化的社会贡献活动',
        image: 'images/aboutus/sustainability/social_responsibility/plus.png',
        borderTitle: '愿景'
      },
      key_cn: {
        borderTitle: '关键活动',
        cardItems: [
          {
            number: '01',
            text: '改善纪念设施\n环境的活动'
          },
          {
            number: '02',
            text: '改善国民生活\n环境的活动'
          },
          {
            number: '03',
            text: '文化遗产环境\n保护活动'
          },
          {
            number: '04',
            text: '社会贡献活动'
          }
        ]
      },
      goal_cn: {
        borderTitle: '目标',
        cardItems: [
          {
            number: '01',
            text: '凭借LX Hausys的优势，持续履行社会责任'
          },
          {
            number: '02',
            text: '超越单纯的捐赠，创造社会价值，追求共赢'
          },
          {
            number: '03',
            text: '改善社区关系'
          }
        ]
      }
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style>
.social-contribution-strategy-system {
  display: grid;
  justify-content: center;
}
</style>
