var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Financial Performance 2023",
            color: "black",
            size: "56px",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              [
                _vm._l(_vm.items, function (it, idx) {
                  return _c("OverviewCardWithIcon", {
                    key: idx,
                    attrs: {
                      title: it.title,
                      mainTxt: it.mainTxt,
                      addTxt: it.addTxt,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
                _vm._l(_vm.bigItems, function (it, idx) {
                  return _c("OverviewBigCardWithIcon", {
                    key: idx,
                    attrs: {
                      title1: it.title1,
                      mainTxt1: it.mainTxt1,
                      title2: it.title2,
                      mainTxt2: it.mainTxt2,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
              ],
              2
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "financial-performance-2022-txt ctt-t2" },
              [
                _vm._v(
                  "LX Hausys recorded a 2.4% decrease in revenue to $2.61 billion in 2023 compared to\nthe previous year. However, operating profit surger by 635.1% to $81.3 million.\nWhile reveneu slightly declined due to a contraction in the domestic construction\nand real estate makets, operating profit improved due to a decline in key raw\nmaterial prices and improved profitability of overseas operations."
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "2023年の財務実績", color: "black", size: "56px" } },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              [
                _vm._l(_vm.items_jp, function (it, idx) {
                  return _c("OverviewCardWithIcon", {
                    key: idx,
                    attrs: {
                      title: it.title,
                      mainTxt: it.mainTxt,
                      addTxt: it.addTxt,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
                _vm._l(_vm.bigItems_jp, function (it, idx) {
                  return _c("OverviewBigCardWithIcon", {
                    key: idx,
                    attrs: {
                      title1: it.title1,
                      mainTxt1: it.mainTxt1,
                      title2: it.title2,
                      mainTxt2: it.mainTxt2,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
              ],
              2
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "financial-performance-2022-txt ctt-t2" },
              [
                _vm._v(
                  "        LX Hausysは、2023年の売上高が前年比2.4%減の26億1,000万ドルとなった。しかし、営業利益は635.1%\n        増の8,130万ドルとなった。国内の建設・不動産市場の縮小により売上高は若干減少したが、主要原材料価格\n        の低下と海外事業の収益性向上により営業利益は改善した。"
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Финансовые показатели 2023",
            color: "black",
            size: "56px",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              [
                _vm._l(_vm.items_ru, function (it, idx) {
                  return _c("OverviewCardWithIcon", {
                    key: idx,
                    attrs: {
                      title: it.title,
                      mainTxt: it.mainTxt,
                      addTxt: it.addTxt,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
                _vm._l(_vm.bigItems_ru, function (it, idx) {
                  return _c("OverviewBigCardWithIcon", {
                    key: idx,
                    attrs: {
                      title1: it.title1,
                      mainTxt1: it.mainTxt1,
                      title2: it.title2,
                      mainTxt2: it.mainTxt2,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
              ],
              2
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "financial-performance-2022-txt ctt-t2" },
              [
                _vm._v(
                  "В 2023 году выручка LX Hausys снизилась на 2,4% по сравнению с предыдущим годом и \nсоставила 2,61 миллиарда долларов. Однако операционная прибыль выросла на 635,1 % до \n81,3 млн долларов. В то время как выручка несколько снизилась из-за сокращения внутреннего \nстроительного рынка и недвижимости, операционная прибыль выросла благодаря снижению цен \nна основное сырье и повышению рентабельности за рубежом. Снижение цен на основное сырье \nи повышение рентабельности зарубежных операций привели к росту операционной прибыли."
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "2023 财务绩效", color: "black", size: "56px" } },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              [
                _vm._l(_vm.items_cn, function (it, idx) {
                  return _c("OverviewCardWithIcon", {
                    key: idx,
                    attrs: {
                      title: it.title,
                      mainTxt: it.mainTxt,
                      addTxt: it.addTxt,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
                _vm._l(_vm.bigItems_cn, function (it, idx) {
                  return _c("OverviewBigCardWithIcon", {
                    key: idx,
                    attrs: {
                      title1: it.title1,
                      mainTxt1: it.mainTxt1,
                      title2: it.title2,
                      mainTxt2: it.mainTxt2,
                      iconSrc: it.iconSrc,
                    },
                  })
                }),
              ],
              2
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "financial-performance-2022-txt ctt-t2" },
              [
                _vm._v(
                  "2023年，LX Hausys实现了26.1亿美元的销售额，较去年同期下降2.4%。尽管如此，营业利润却达到了8130万美元，\n同比增长635.1%。由于韩国建筑和房地产市场等终端市场的收缩，销售额较2022年略有下滑。但得益于核心原材料\n成本的降低、高性能建筑隔热材料销量的提升，以及海外业务盈利能力的增强，营业利润实现了提升。"
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }