<template>
  <div class="pollutant-management-border-box-content">
    <pre class="text" v-html="text"></pre>
    <pre class="unit" v-html="unit"></pre>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    unit: {
      type: String
    }
  }
}
</script>

<style>
.pollutant-management-border-box-content {
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1 0 0;
  width: 600px;
  height: 36px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .pollutant-management-border-box-content {
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 15px;
  }
}

.pollutant-management-border-box-content .text {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .pollutant-management-border-box-content .text {
    font-size: 1.25rem;
    line-height: 1.3;
    text-align: center;
  }
}

.pollutant-management-border-box-content .text b {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 30px;
  font-style: normal;
  line-height: 36px; /* 180% */
  font-weight: 700;
}

@media (max-width: 768px) {
  .pollutant-management-border-box-content .text b {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.pollutant-management-border-box-content .unit {
  color: #B40F2C;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 94.737% */
}

@media (max-width: 768px) {
  .pollutant-management-border-box-content .unit {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.pollutant-management-border-box-content .unit b {
  color: #B40F2C;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 40px;
  font-style: normal;
  line-height: 36px; /* 94.737% */
  font-weight: 700;
}

@media (max-width: 768px) {
  .pollutant-management-border-box-content .unit b {
    font-size: 1.2916666667rem;
    line-height: 1.3;
  }
}
</style>
