<template>
  <div class="expanded-waste-recycling-table">
    <ExpandedWasteRecyclingTableHeader
    :headerList="headerList"
    />
    <ExpandedWasteRecyclingTableBody
    v-for="item in tableData.bodyDataList"
    :key="item"
    :bodyList="item"
    />
  </div>
</template>

<script>
import ExpandedWasteRecyclingTableBody from './ExpandedWasteRecyclingTableBody.vue'
import ExpandedWasteRecyclingTableHeader from './ExpandedWasteRecyclingTableHeader.vue'
export default {
  components: {
    ExpandedWasteRecyclingTableHeader,
    ExpandedWasteRecyclingTableBody
  },
  props: {
    tableData: {
      type: Object
    }
  },
  data: function () {
    return {
      headerList: this.tableData.headerList
    }
  }

}
</script>

<style>
@media (max-width: 768px) {
  .expanded-waste-recycling-table {
    width: 1000px;
  }
}
</style>
