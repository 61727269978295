<template>
  <div class="overview-card-icon-area-root">
    <div class="overview-card-icon-area">
      <span class="text-area">
        <span class="title-area">
          {{ title }}
        </span>
        <span class="main-area">
          <span class="main-text-area">
            {{ mainTxt }}
          </span>
          <span class="add-text-area">
            {{ addTxt }}
          </span>
        </span>
      </span>
      <span class="icon-area">
        <img :src="iconSrc" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    mainTxt: {
      type: String,
      default: "",
    },
    addTxt: {
      type: String,
      default: "",
    },
    iconSrc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.overview-card-icon-area-root {
  display: flex;
  width: 710px;
  height: 216px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border: 5px solid #a09c9b;
}

@media (max-width: 768px) {
  .overview-card-icon-area-root {
    width: 100%;
    height: 100%;
  }
}

.overview-card-icon-area {
  margin-left: 90px;
  margin-top: 45px;
  margin-bottom: 45px;
  height: 126px;
  width: 620px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .overview-card-icon-area {
    margin-left: 0px;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
}

.text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

@media (max-width: 768px) {
  .text-area {
    align-items: center;
  }
}

.title-area {
  color: #222;
  font-family: "NotoSans", sans-serif !important;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .title-area {
    text-align: center;
    font-size: 1.46rem;
  }
}

.main-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

@media (max-width: 768px) {
  .main-area {
    align-items: center;
  }
}

.main-text-area {
  color: #aa2d29;
  font-family: "NotoSans", sans-serif !important;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
}

@media (max-width: 768px) {
  .main-text-area {
    text-align: center;
    font-size: 1.7rem;
  }
}

.add-text-area {
  color: #a09c9b;
  font-family: "NotoSans", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

@media (max-width: 768px) {
  .add-text-area {
    text-align: center;
  }
}

.icon-area {
  margin-left: 191px;
  margin-top: 13px;
  margin-bottom: 13px;
  display: flex;
  width: 100px;
  height: 100px;
}
:lang(ru) .icon-area {
  margin-left: 131px;
  margin-top: 13px;
  margin-bottom: 13px;
  display: flex;
  width: 100px;
  height: 100px;
}

@media (max-width: 768px) {
  .icon-area {
    width: 80px;
    height: 80px;
    margin-right: 3%;
    margin-left: 6%;
  }
}
</style>
