var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "content himacPorta_veiw" }, [
          _c("div", { staticClass: "location" }, [
            _c("ul", [
              _c("li", { staticClass: "ico-home" }, [
                _vm._v(_vm._s(_vm.$t("contactus.text.home"))),
              ]),
              _c("li", [_vm._v(_vm._s(_vm.$t("contactus.text.support")))]),
              _c("li", [_vm._v(_vm._s(_vm.$t("contactus.text.contactus")))]),
              _c("li", [_vm._v(_vm._s(_vm.$t("contactus.text.completed")))]),
            ]),
          ]),
          _c("div", { staticClass: "container equest_content ta-c" }, [
            _c("div", { staticClass: "w-con-02" }, [
              _c("p", { staticClass: "mb-64" }),
              _c("p", { staticClass: "tt-1 mb-64 tac inner" }, [
                _vm._v(_vm._s(_vm.$t("contactus.text.compTitle"))),
              ]),
              _c("p", {
                staticClass: "tt-sub mb-88",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("contactus.text.compText")),
                },
              }),
              _c("div", { staticClass: "more-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    staticStyle: { width: "inherit" },
                    attrs: {
                      type: "button",
                      onclick: "location.href='index/'",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("contactus.text.goToMain"))),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }