var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-us", attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content cntu-view" }, [
        _c(
          "div",
          { staticClass: "container cntu-box container_contactus" },
          [
            _vm.nationCode === "_GL" || _vm.nationCode === "_US"
              ? [
                  _c("div", { staticClass: "sub_visual_bg full-width" }, [
                    _c("div", { staticClass: "inner_padding" }, [
                      _c("div", { staticClass: "location" }, [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hover-cursor",
                                  rawName: "v-hover-cursor",
                                },
                              ],
                              staticClass: "ico-home",
                              on: {
                                click: function ($event) {
                                  _vm.gotolink(
                                    `/${_vm.countryCodeSub.toLowerCase()}/index`
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("contactus.text.home")))]
                          ),
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("contactus.text.support"))),
                          ]),
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("contactus.text.contactus"))),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "row ju_bet" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h1", { staticClass: "tt-1 visual-title" }, [
                            _vm._v(_vm._s(_vm.$t("contactus.text.contactus"))),
                          ]),
                          _c("p", {
                            staticClass: "ctt ctt-t3 visual-desc",
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("contactus.text.inner")),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "find-links-wrap" }, [
                            _c("div", [
                              _vm._v("FIND WHAT YOU NEED BEFORE ASKING"),
                            ]),
                            _c("div", { staticClass: "find-links" }, [
                              _vm._m(0),
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "item",
                                      attrs: { href: "support/warranty/hflor" },
                                    },
                                    [_c("p", [_vm._v("WARRANTY")]), _vm._m(1)]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.nationCode === "_GL"
                    ? _c(
                        "div",
                        [
                          _c("contactGL", {
                            attrs: { radioType: _vm.radioType },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("contactUSGL", {
                            attrs: { radioType: _vm.radioType },
                          }),
                        ],
                        1
                      ),
                ]
              : _c(
                  "div",
                  [_c("contactEU", { attrs: { radioType: _vm.radioType } })],
                  1
                ),
          ],
          2
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "item", attrs: { href: "support/faq/all-products" } },
      [
        _c("p", [_vm._v("FAQ")]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("../../../public/images/header/old-gnb/gnb/gnb_Arr_right01.png"),
              alt: "arrow",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../public/images/header/old-gnb/gnb/gnb_Arr_right01.png"),
          alt: "arrow",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }