<template>
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/net_zero/top_image.jpg"
    title="Net Zero 2050"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <template #content>
      <!-- 상단 텍스트 영역 -->
      <OverviewSection>
        <OverviewText
          type="ft-20"
          text = "The climate crisis is a serious issue facing not only countries and corporations around the globe, but also humanity as a whole.
To this end, LX Hausys is keenly aware of its responsibilities towards climate change and
established the 2050 Net-Zero Roadmap in an effort to participate in the global actions against climate change.
We establish GHG emissions reduction plans every year and manage them in order to achieve our annual reduction
targets based on the Roadmap, while upgrading outdated facilities and implementing energy-saving activities
to focus on the reduction of GHG emissions."
        />
      </OverviewSection>

      <CarbonNeutralityRoadmap />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/net_zero/top_image.jpg"
    title="Net Zero 2050"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <!-- 상단 텍스트 영역 -->
      <OverviewSection>
        <OverviewText
          type="ft-20"
          text = "気候危機は、世界中の国や企業だけでなく、人類全体が直面している深刻な問題です。
このため、LX Hausysは気候変動に対する責任を強く認識し、2050年ネットゼロロードマップを策定し、気候変動に対する世界的な取り組みに参加しています。
私たちは、ロードマップに基づいて年間削減目標を達成するために、毎年GHG排出削減計画を策定し、
管理するとともに、老朽化し​​た設備の更新や省エネ活動を実施し、GHG排出削減に注力しています。"
        />
      </OverviewSection>

      <CarbonNeutralityRoadmap />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/net_zero/top_image.jpg"
    title="Net Zero 2050"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <!-- 상단 텍스트 영역 -->
      <OverviewSection>
        <OverviewText
          type="ft-20"
          text = "Климатический кризис - это серьезная проблема, с которой сталкиваются не только страны и корпорации по всему миру, но и все человечество в целом.
В связи с этим компания LX Hausys прекрасно осознает свою ответственность за изменение климата и
в целях участия в глобальных действиях по борьбе с изменением климата компания разработала дорожную карту «2050 Net-Zero».
Мы ежегодно разрабатываем планы по сокращению выбросов ПГ и управляем ими, чтобы достичь ежегодных целей по сокращению выбросов.
В соответствии с Дорожной картой, модернизируя устаревшие объекты и осуществляя энергосберегающие мероприятия, мы сосредоточены на сокращении выбросов ПГ."
        />
      </OverviewSection>

      <CarbonNeutralityRoadmap />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/net_zero/top_image.jpg"
    title="Net Zero 2050"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <!-- 상단 텍스트 영역 -->
      <OverviewSection>
        <OverviewText
          type="ft-20"
          text = "气候危机不仅是全球各国和企业面临的一个严重问题，也是整个人类面临的问题。
为此，LX Hausys敏锐地意识到其对气候变化的责任，并制定了Net Zero 2050，以参与应对气候变化的全球行动。
LX Hausys每年制定并管理温室气体减排计划，以实现基于路线图的年度减排目标，同时升级过时的设施并实施节能活动，重点减少温室气体排放。"
        />
      </OverviewSection>

      <CarbonNeutralityRoadmap />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import OverviewSection from '../../overview/components/OverviewSection.vue'
import OverviewText from '../../overview/components/OverviewText.vue'
import CarbonNeutralityRoadmap from './components/CarbonNeutralityRoadmap.vue'

export default {
  components: {
    OverviewLayout,
    OverviewSection,
    OverviewText,
    CarbonNeutralityRoadmap
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>

</style>
