var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: { title: "Financial Statement", color: "black", size: "56px" },
        },
        [
          _c("OverviewSmallSection", {
            attrs: { title: "Consolidated Profit and Loss Statement" },
          }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList1,
                  dataList: _vm.dataList1,
                },
              }),
            ],
            1
          ),
          _c("OverviewSmallSection", {
            attrs: { title: "Consolidated Financial Statement" },
          }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList2,
                  dataList: _vm.dataList2,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "財務諸表", color: "black", size: "56px" } },
        [
          _c("OverviewSmallSection", { attrs: { title: "連結損益計算書" } }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList1_jp,
                  dataList: _vm.dataList1_jp,
                },
              }),
            ],
            1
          ),
          _c("OverviewSmallSection", { attrs: { title: "連結財務諸表" } }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList2_jp,
                  dataList: _vm.dataList2_jp,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Финансовая отчетность",
            color: "black",
            size: "56px",
          },
        },
        [
          _c("OverviewSmallSection", {
            attrs: { title: "Консолидированный отчет о прибылях и убытках" },
          }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList1_ru,
                  dataList: _vm.dataList1_ru,
                },
              }),
            ],
            1
          ),
          _c("OverviewSmallSection", {
            attrs: { title: "Консолидированная финансовая отчетность" },
          }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList2_ru,
                  dataList: _vm.dataList2_ru,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "财务报表", color: "black", size: "56px" } },
        [
          _c("OverviewSmallSection", { attrs: { title: "合并损益表" } }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList1_cn,
                  dataList: _vm.dataList1_cn,
                },
              }),
            ],
            1
          ),
          _c("OverviewSmallSection", { attrs: { title: "合并财务报表" } }),
          _c("FinancialStatementTableUnit", {
            attrs: { content: "(Unit : KRW 0.1 billion)" },
          }),
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c("FinancialStatementTable", {
                attrs: {
                  categoryList: _vm.categoryList2_cn,
                  dataList: _vm.dataList2_cn,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }