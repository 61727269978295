<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content prod-dt-cate">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('PCD.text.home')}}</li>
          <li
            v-for="(loc, idx) in location"
            v-bind:key="idx"
          >
            {{ loc }}
          </li>
        </ul>
      </div>
      <!-- //location -->

      <div class="container prod-dt-view">
        <slot name="content"></slot>
      </div>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <div class="short-link type_sub">
      <a class="item where" :href="getWheresHref($countryCode)">
        <div class="anchor">
          <span class="text">where to buy</span>
          <span class="icon_arrow"></span>
        </div>
      </a>
      <a class="item contact" :href="'support/contactus'">
        <div class="anchor">
          <span class="text">contact us</span>
          <span class="icon_arrow"></span>
        </div>
      </a>
    </div>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'

export default {
  components: {
    HeaderComponent,
    QuickMenuComponent,
    FooterComponent,
    GnbComponent
  },

  data () {
    return {
      location: [],
      countryCode: '',
      countryCodeSub: ''
    }
  },

  methods: {
    getWheresHref (country) {
      const lowercaseCountry = country.toLowerCase().replace(/^_/, '')

      if (country === '_US') {
        return `/${lowercaseCountry}/support/where-to-buy/country/us`
      } else {
        return `/${lowercaseCountry}/support/where-to-buy/global`
      }

    },
    gotolink (url) {
      location.href = url
    }
  },

  directives:{
    hoverCursor:{
      bind(el){
        el.addEventListener('mouseover', ()=>{
          el.style.cursor = 'pointer';
        });
        el.addEventListener('mouseout', ()=>{
          el.style.cursor = 'default';
        });
      }
    }
  },

  created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

  mounted () {
    this.location = this.$route.meta.location
  }
}
</script>
