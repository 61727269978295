<template>
  <OverviewSection title="Customer-Oriented Products" color="black" v-if="nationCode === '_US' || nationCode === '_GL'">
    <!-- 이미지 영역 -->
    <OverviewSectionItem>
      <AboutUsOverview :hidePointer="true" />
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="customer-oriented-products-txt ctt-t2">
LX Hausys provides a variety of solutions that can revitalize a space through
its business in the fields of building & decorative materials, industrial films, and automotive materials & components.
With our commitment to creating value-added spaces where humans and nature exist in harmony,
we strive to present innovative products that cater to the taste, interest, and lifestyle of our customers.</pre>
    </OverviewSectionItem>

    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItem"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :title="it.title"
            :descriptionList="it.descriptionList"
            :addtionalInfo="it.addtionalInfo"
          />
        </div>
      </div>
    </OverviewSectionItem>

  </OverviewSection>
<!-- JP -->
  <OverviewSection title="顧客志向の製品" color="black" v-else-if="nationCode === '_JP'">
    <!-- 이미지 영역 -->
    <OverviewSectionItem>
      <AboutUsOverview :hidePointer="true" />
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="customer-oriented-products-txt ctt-t2">
        LXハウシスは、建築・装飾資材、産業用フィルム、自動車資材・部品の分野で事業を展開し、
        空間を活性化させるさまざまなソリューションを提供しています。
        人と自然が調和した付加価値の高い空間づくりを目指し、
        お客様の嗜好や興味、ライフスタイルに合わせた革新的な製品を提案してまいります。</pre>
    </OverviewSectionItem>

    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItemJp"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :title="it.title"
            :descriptionList="it.descriptionList"
            :addtionalInfo="it.addtionalInfo"
          />
        </div>
      </div>
    </OverviewSectionItem>

  </OverviewSection>
<!-- RU -->
  <OverviewSection title="Продукты, ориентированные на клиента" color="black" v-else-if="nationCode === '_RU'">
    <!-- 이미지 영역 -->
    <OverviewSectionItem>
      <AboutUsOverview :hidePointer="true" />
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="customer-oriented-products-txt ctt-t2">
        LX Hausys предлагает разнообразные решения, способные оживить пространство.
Компания работает в области строительных и декоративных материалов, пленок для ламинации, автомобильных материалов и компонентов.
Стремясь создавать пространства, где человек и природа существуют в гармонии,
мы представляем инновационные продукты, которые отвечают вкусу, интересам и стилю жизни наших клиентов.</pre>
    </OverviewSectionItem>

    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItemRu"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :title="it.title"
            :descriptionList="it.descriptionList"
            :addtionalInfo="it.addtionalInfo"
          />
        </div>
      </div>
    </OverviewSectionItem>

  </OverviewSection>
<!-- CN -->
  <OverviewSection title="以顾客为中心的产品" color="black" v-else-if="nationCode === '_CN'">
    <!-- 이미지 영역 -->
    <OverviewSectionItem>
      <AboutUsOverview :hidePointer="true" />
    </OverviewSectionItem>

    <!-- 텍스트 영역 -->
    <OverviewSectionItem>
      <pre class="customer-oriented-products-txt ctt-t2">
        LX Hausys通过建筑装饰材料, 产业用膜, 
        汽车配件材料等多项事业，致力于为各种空间注入活力并积极提供多样化的解决方案。
        以打造人与自然和谐共存的空间为基础，致力研发承载顾客喜好, 
        关注点及生活方式的核心产品。</pre>
    </OverviewSectionItem>

    <!-- 카드 영역 -->
    <OverviewSectionItem>
      <div class="card-area">
        <div
          v-for="it in cardItemCn"
          :key="it.imageSrc"
        >
          <OverviewCardWithImage
            :imageSrc="it.imageSrc"
            :title="it.title"
            :descriptionList="it.descriptionList"
            :addtionalInfo="it.addtionalInfo"
          />
        </div>
      </div>
    </OverviewSectionItem>

  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import AboutUsOverview from '@/components/map/AboutUsOverview'
import OverviewCardWithImage from '../../components/OverviewCardWithImage.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    AboutUsOverview,
    OverviewCardWithImage
  },
  data: function () {
    return { // 객체형태
      countryCode: '',
      countryCodeSub: '',
      cardItem: [
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-1.png',
          title: `Building &
Decorative Materials`,
          descriptionList: [
            'Solid Surface',
            'Quartz Surface',
            'Porcelain Surface',
            'Flooring'
          ],
          addtionalInfo: {
            title: 'for Domestic Sales only',
            desc: `Window / Door / Kitchen /
Wallcovering / Insulated materials`
          }
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-2.png',
          title: 'Industrial Film',
          descriptionList: [
            'Interior Film',
            'Deco Foil',
            'Exterior Foil',
            'Sign & Graphic',
            'Home appliance surface material'
          ]
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-3.png',
          title: 'Automotive',
          descriptionList: [
            'Automotive Skin',
            'Lightweight Component',
            'Automotive Component'
          ]
        }
      ],
      cardItemJp: [
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-1.png',
          title: `建築・装飾資材`,
          descriptionList: [
            '人工大理石',
            'クォーツストーン',
            'ポーセリン',
            'フローリング'
          ],
          addtionalInfo: {
            title: '国内販売のみ',
            desc: `窓 / ドア / キッチン /
壁紙 / 断熱材`
          }
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-2.png',
          title: '産業用フィルム',
          descriptionList: [
            'インテリアフィルム',
            '装飾フィルム',
            'エクステリアフィルム',
            'サイン＆グラフィック',
            '家電表面材'
          ]
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-3.png',
          title: '自動車',
          descriptionList: [
            '自動車用スキン',
            '軽量コンポーネント',
            '自動車用コンポーネント'
          ]
        }
      ],
      cardItemRu: [
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-1.png',
          title: `Строительство и
декоративные материалы`,
          descriptionList: [
            'Акриловый камень',
            'Кварцевый агломерат',
            'Керамогранит',
            'Напольные покрытия'
          ],
          addtionalInfo: {
            title: `Только для продажи на 
внутреннем рынке`,
            desc: `Окна / двери / кухни /
Обои / Изоляционные материалы`
          }
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-2.png',
          title: 'Пленки для ламинации',
          descriptionList: [
            'Пленки для интерьера',
            'Пленки для мебели и декора',
            'Пленки для ламинации',
            'Материалы для рекламы',
            'Материал поверхности бытовой техники'
          ]
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-3.png',
          title: 'Для автомобиля',
          descriptionList: [
            'Кожа для салона автомобиля',
            'Легкие пластиковые компоненты',
            'Автомобильные компоненты'
          ]
        }
      ],
      cardItemCn: [
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-1.png',
          title: `建筑&装饰材料`,
          descriptionList: [
            '人造大理石',
            '石英石',
            '岩板',
            '地板'
          ],
          addtionalInfo: {
            title: '仅限国内销售',
            desc: `门窗 / 门 / 厨房 /
墙纸 / 隔热材料`
          }
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-2.png',
          title: '产业用膜',
          descriptionList: [
            '装饰贴膜',
            '家具膜',
            '户外膜',
            '广告材',
            '家电膜'
          ]
        },
        {
          imageSrc: 'images/aboutus/overview/business_area/product-card-3.png',
          title: '汽车配件材料',
          descriptionList: [
            '汽车表皮',
            '汽车轻量化部件',
            '汽车零部件'
          ]
        }
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.customer-oriented-products-txt.ctt-t2 {
  text-align: center;
  font-weight: 400;
}

.card-area {
  display: flex;
  flex-direction: row;
  gap: 28px;
}

@media (max-width: 768px) {
  .customer-oriented-products-txt.ctt-t2 {
    white-space: pre-wrap;
  }

  .card-area {
    flex-direction: column;
    width: 90%;
  }
}

</style>
