var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("VIZUON", {
        attrs: { category: _vm.category },
        on: { "category-changed-vizuon": _vm.handleCategoryChangedVizuon },
      }),
      _c("COLLECTION", {
        attrs: { collectionList: _vm.collectionList, category: _vm.category },
        model: {
          value: _vm.collection,
          callback: function ($$v) {
            _vm.collection = $$v
          },
          expression: "collection",
        },
      }),
      _c("IS_NEW", {
        model: {
          value: _vm.isNew,
          callback: function ($$v) {
            _vm.isNew = $$v
          },
          expression: "isNew",
        },
      }),
      _c("ADHESIVETYPE", {
        attrs: { adhesiveList: _vm.dictMap["adhesive_type"] },
        model: {
          value: _vm.adhesive_type,
          callback: function ($$v) {
            _vm.adhesive_type = $$v
          },
          expression: "adhesive_type",
        },
      }),
      _c("PRODUCTCLASSIFICATION", {
        attrs: { prdcfcList: _vm.dictMap["prd_class"] },
        model: {
          value: _vm.prd_class,
          callback: function ($$v) {
            _vm.prd_class = $$v
          },
          expression: "prd_class",
        },
      }),
      _c("AIRFREE", {
        attrs: { airfreeList: _vm.dictMap["air_free"] },
        model: {
          value: _vm.air_free,
          callback: function ($$v) {
            _vm.air_free = $$v
          },
          expression: "air_free",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }