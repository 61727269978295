export default {
  US: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Search by Address, City, State, Zip Code',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_002: 'state info not found',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        select: "Select",
        inputText : "Input Text",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        Dealer: "Dealer / Kitchen&Bath / Fabricator",
        AnD: "A&D / Commercial Project",
        Sample: "Sample",
        homeCenter: "Home Center Account Question",
        technicalInquiry: "Technical Inquiry",
        others: "Others",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name",
        checkCompanyNm: "Enter Company Name",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentUs: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469."
      },
      button: {
        submit: "Submit",
      }
    },

    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering?",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Phone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State/Province",
        select_state: "Select State",
        zip_code: "Zip/Postal Code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "Where is this Product Installed?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase ",
        upload_file_brackets: '(attach a receipt that shows the consumer’s name, address, and material installed)',
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty Registration",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        contact : "Contact",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  GL: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Select Area',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_003: 'Unable to retrieve location information. Please check Geolocation permissions or try later.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        south_asia: "Asia",
        middle_east: "Middle East",
        latin_ameria: "Latin America",
        oceania: "Oceania",
        africa: "Africa",
        turkey: "Turkey",
        ru_cis: "Russia & CIS",
        china: "China",
        japan: "Japan",
        PARTNER: "Become a Partner",
        COMPROJECT: "Commercial Project (A&D)",
        SAMPLE: "Sample",
        B2BCUSTOMERS: "B2B Customers",
        TECHINQUIRY: "Technical Inquiry",
        OTHERS: "Others",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        BENIF: "BENIF",
        deco_foil: "Deco Foil",
        VIZUON: "VIZUON",
        exterior_foil: "Exterior Foil",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name (Optional)",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentGl: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        inputText : "Input Text",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469.",
        select: "Select",
      },
      button: {
        submit: "Submit",
      }
    },
    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering this Product Select",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Telephone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State",
        select_state: "Select State",
        zip_code: "Zip code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        support : "Support",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        contact : "Contact",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }

  },

  EN: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please Select Country first to see where you can find LX Hausys material.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact Us',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Select Country',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select product category to see the store list near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'Get Direction'
      },
      alert: {
        FC0030_001: 'Please select country first',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner: "Your request will be directly sent to the Country Manager of your region",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product category",
        checkPrd_cate: " Select Product category",
        inquiry: "Type of Request",
        checkInquiry: "Select Type of Request",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company Name",
        address: "Address",
        comment: "Your request, Comment",
        checkComment: "Please check privacy policy and Terms of use",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        inputText: "Input Text",
        select: "Select",
        products: "Products",
        google_ads_notice1: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.",
        google_ads_notice2: "If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469.",
        google_ads_red: "Mandatory fields",
        subscribe_himacs: "Subscribe to HIMACS newsletter",
        subscribe_hflor: "Subscribe to HFLOR newsletter",
        newsletter: "Subscribe to newsletter (Please select all that you’re interested in)",
        policy_0: "I acknowledge the acts of data processing as described in the ",
        feeling: "FEELING LIKE CONTACTING US BY PHONE ?",
        request: "Your Request",
        checkPolicy: "Please check Privacy Policy",
      },
      button: {
        submit: "Submit",
      }
    },
    warranty: {
      text: {
        home: "Home",
        support: "Support",
        warranty: "Warranty",
        warranty_down: "Warranty Download",
        mainTxt1: "Care for your HFLOR. Protect your flooring for long lasting beauty and endurance.",
        mainTxt2: "",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
        hflor_t1: "HFLOR stands by its products, bringing the beauty and the confidence to your projects.",
        hflor_t2: "Bringing together design, function and safety, HFLOR offers a comprehensive package of warranties to guarantees the high-performance flooring system. All HFLOR products are backed by warranties that ensure both quality and additional peace of mind.",
        himacs_t1: "Quality pays for itself: With its 15 year limited installation warranty, HIMACS offers the longest warranty of any Solid Surface manufacturer on the market.",
        himacs_t2: "Every HIMACS product (except external facades) has a warranty of 15 years when the fabrication and installation is done by a Quality Club Member.",
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        support : "Support",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        contact : "Contact",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  DE: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'Bezugsquellen',
        FC0030_003: 'Startseit',
        FC0030_004: 'Kontaktieren Sie uns',
        FC0030_005: 'Bezugsquellen',
        FC0030_006: 'Land auswählen',
        FC0030_007: 'Land auswählen',
        FC0030_008: 'Meien aktuellen Standort verwenden',
        FC0030_009: 'Miles',
        FC0030_010: 'Bitte wählen Sie eine Produktkategorie aus, um die Liste der Geschäfte in Ihrer Nähe zu erhalten',
        FC0030_011: 'Produktkategorie',
        FC0030_012: 'Ladentyp',
        FC0030_013: 'Widerstandsfähiger Bodenbelag',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Gesamt',
        FC0030_017: 'Ergebnisse',
        FC0030_018: 'Forhandler for staten',
        FC0030_019: 'Website besuchen',
        FC0030_020: 'Wegbescheribung'
      },
      alert: {
        FC0030_001: 'Bitte wählen Sie zuerst das Land aus',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Startseit',
        FC0040_002: 'Kontaktieren Sie',
        FC0040_003: 'Finden Sie einen Vertriebsmitarbeiter',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Sie Konnen Auch Unsere Hauptgeschaftsstelle Kontaktieren',
        FC0040_024: 'Land auswahlen',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Startseit",
        support: "Kontaktieren Sie",
        faq: "FAQ",
        contactus: "KONTAKTIEREN SIE UNS",
        inner1: "Hier finden Sie Antworten auf die am häufigsten gestellten Fragen zu LX Hausys.",
        inner2: " Wenn Sie nicht finden, was Sie suchen, kontaktieren Sie uns bitte.",
        ALL: "Alle",
        search: "Suche",
        searchBar: "Geben Sie ein Schlüsselwort ein, um eine Antwort zu erhalten",
      },
      button: {
        ALL: "Alle"
      },
    },
    contactus: {
      text: {
        home:"Abschicken",
        support: "Kontaktieren Sie",
        contactus: "Kontaktieren Sie uns",
        completed: "Abgeschlossen",
        compTitle: "Danke!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner: "Ihre Anfrage wird direkt an den Country Manager Ihrer Region weitergeleitet",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "Allgemein",
        Other: "Weitere Produkte",
        prd_cate: "Produktkategorie",
        checkPrd_cate: "Wählen Sie eine Produktkategorie",
        inquiry: "Art der Anfrage",
        checkInquiry: "Art der Anfrage auswählen",
        firstName: "Vorname",
        checkFirstName: "Vornamen eingeben",
        lastName: "Nachname",
        checkLastName: "Nachname eingeben",
        email: "E-Mail-Adresse eingeben",
        checkEmail: "E-Mail-Adresse eingeben",
        phone: "Telefonnummer",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Land",
        checkCountry: "Land auswählen",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Adresse und Postleitzahl",
        checkZip: "Adresse und Postleitzahl eingeben",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Name des Unternehmens",
        address: "Address",
        comment: "Ihre Anfrage, Kommentar",
        checkComment: "Bitte prüfen Sie die Datenschutzbestimmungen und Nutzungsbedingungen",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Datenschutzhinweisen.',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Bezugsquellen",
        faq: "FAQ",
        document: "Southeast Asia",
        warranty: "Garantie",
        care_main: "Pflege und Instandhaltung",
        fab_guide: "Leitfaden für Installation und Herstellung",
        guide: "Technische Datenblätter",
        find_sales: "Finden Sie einen Vertriebsmitarbeiter",
        inputText: "Text eingeben",
        select: "Auswählen",
        products: "Produkte",
        google_ads_notice1: "Weitere Informationen zur Verarbeitung Ihrer persönlichen Daten finden Sie in den Datenschutzhinweisen.",
        google_ads_notice2: "Falls Sie Fragen oder Bedenken haben Ihre persönlichen Daten online zu übermitteln, kontaktieren Sie uns bitte per Telefon (49)-69-583-029 469.",
        google_ads_red: "Pflichtfelder",
        subscribe_himacs: "Subscribe to HIMACS newsletter",
        subscribe_hflor: "Subscribe to HFLOR newsletter",
        newsletter: "Abonnieren Sie jetzt den HIMACS Newsletter (Bitte wählen Sie die Produkte aus, an denen Sie interessiert sind)",
        policy_0: "Ja, ich stimme der im Datenschutzhinweis beschriebenen Verwendung meiner Daten zu.",
        feeling: "MÖCHTEN SIE UNS TELEFONISCH KONTAKTIEREN?",
        request: "Ihre Anfrage",
        checkPolicy: "Bitte prüfen Sie die Datenschutzbestimmungen",
      },
      button: {
        submit: "Abschicken",
      }
    },
    warranty: {
      text: {
        home: "Startseit",
        support: "Support",
        warranty: "Garantie",
        warranty_down: "Garantie Download",
        mainTxt1: "Pflege Ihres HFLOR. Schützen Sie Ihren Bodenbelag für dauerhafte Schönheit und Beständigkeit.",
        mainTxt2: "",
        warranty_download: "WWarranty Download",
        download_form: "Formular herunterladen",
        download: "Herunterladen",
        first_page: "first page",
        prev: "Zurück",
        next: "Weiter",
        last_page: "last page",
        EN: "Englisch",
        UK: "Englisch (UK)",
        DE: "Deutsch",
        FR: "Französisch",
        IT: "Italienisch",
        ES: "Spanisch",
        NL: "Niederländisch",
        lvt: "Edle Vinylfliesen",
        hflor_t1: " HFLOR steht zu seinen Produkten und bringt Schönheit und Zuverlässigkeit in Ihre Projekte.",
        hflor_t2: "HFLOR vereint Design, Funktion und Sicherheit und bietet ein umfassendes Paket an \n" +
          "Garantien, die ein hochwertiges Bodenbelagssystem garantieren.",
        himacs_t1: "Qualität zahlt sich aus: Mit der 15 Jahre beschränkten Installationsgarantie bietet HIMACS die höchste Garantie aller Solid Surface Anbieter auf dem Markt.",
        himacs_t2: "Jedem HIMACS Produkt gewähren wir eine Garantie von 15 Jahren, wenn der Einbau durch ein Mitglied des  Quality Clubs vollzogen und auf www.himacs.eu registriert wurde. Melden Sie sich dafür auf www.himacs.eu an und füllen Sie das entsprechende Formular aus.",
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Abschicken",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "Une adresse électronique valide est requise.",

      }
    },
    care_maintenance:{
      text: {
        home : "Startseit",
        support : "Support",
        care_maintenance : "Pflege & Instandhaltung",
        Download: "Herunterladen",
        more: "More",
        download_form: "Formular herunterladen",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "'Zurück",
        next: "Weiter",
        download: "Herunterladen",
      }
    },
    google_ads: {
      'airports': 'Flughafen',
      'bathroom':  'Flughafen',
      'education': 'Bildung',
      'façade': 'Fassade',
      'furniture': 'Fassade',
      'healthcare': 'Gesundheitswesen',
      'hospitality': 'Gastronomie',
      'kitchen': 'Küche',
      'offices': 'Büro',
      'public_buildings': 'Öffentliche Gebäude',
      'retail': 'Einzelhandel',
      'marine_transport':'Marine & Transport',
    }
  },

  FR: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'Où acheter',
        FC0030_003: 'Accueil',
        FC0030_004: 'Contactez-nous',
        FC0030_005: 'Où acheter',
        FC0030_006: 'choisissez le pays',
        FC0030_007: 'choisissez le pays',
        FC0030_008: 'Utiliser ma position actuelle',
        FC0030_009: 'Miles',
        FC0030_010: 'Veuillez sélectionner la catégorie de produits pour afficher la liste des magasins près de chez vous.',
        FC0030_011: 'Catégorie de produits',
        FC0030_012: 'Type de magasin',
        FC0030_013: 'Sol résistant',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Résultats ',
        FC0030_018: "Distributeur pour l'État",
        FC0030_019: 'Visiter le site',
        FC0030_020: "Indications d'accès"
      },
      alert: {
        FC0030_001: "Veuillez d'abord sélectionner le pays",
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Accueil',
        FC0040_002: 'Contactez',
        FC0040_003: 'Trouver un représentant',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Vous Pouvez Egalement Contacter Nos Sieges Sociaux',
        FC0040_024: 'Selectionner le pays',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Accueil",
        support: "Contactez",
        faq: "FAQ",
        contactus: "NOUS CONTACTER",
        inner1: "Vous trouverez ici les réponses aux questions les plus fréquemment posées sur LX Hausys.",
        inner2: "Si vous ne trouvez pas ce que vous cherchez, n'hésitez pas à nous contacter.",
        ALL: "Tout",
        search: "Rechercher ",
        searchBar: "Saisissez un mot-clé pour trouver votre réponse",
      },
      button: {
        ALL: "Tout"
      },
    },
    contactus: {
      text: {
        home:"Accueil",
        support: "Contactez",
        contactus: "Contactez-nous",
        completed: "Complété",
        compTitle: "Merci!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner: "votre demande va être transmise directement au Responsable pays de votre région",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "Général",
        Other: "Autres produits",
        prd_cate: "catégorie de produits",
        checkPrd_cate: "Sélectionner la catégorie de produits",
        inquiry: "Type de demande",
        checkInquiry: "Sélectionnez le type de demande",
        firstName: "Prénom",
        checkFirstName: "Entrer le prénom",
        lastName: "Nom de famille",
        checkLastName: "Entrer le Nom de famille",
        email: "l'adresse électronique",
        checkEmail: "Saisir l'adresse électronique",
        phone: "Téléphone",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Pays",
        checkCountry: "Sélectionner le pays",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "le code postal/zip",
        checkZip: "Saisir le code postal/zip",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Nom de la société",
        address: "Address",
        comment: "Votre demande, commentaire",
        checkComment: "Veuillez consulter notre politique de confidentialité et nos conditions d'utilisation",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'la politique de confidentialité',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Trouvez ce dont vous avez besoin",
        orderSample: "Order Sample",
        wheretobuy: "Où acheter",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Garantie",
        care_main: "Entretien et utilisation",
        fab_guide: "Guide d'installation et de fabrication",
        guide: "Fiches techniques",
        find_sales: "Trouver un représentant",
        inputText: "Saisir du texte",
        select: "Sélectionner",
        products: "produits",
        google_ads_notice1: "Pour plus de détails concernant le traitement de vos données personnelles, veuillez-vous référer à la politique de confidentialité de la société.",
        google_ads_notice2: "Si vous avez des questions ou des inquiétudes concernant l’envoi d'informations personnelles en ligne, veuillez nous contacter au (49)-69-583-029 469.",
        google_ads_red: "Champs obligatoires",
        subscribe_himacs: "Subscribe to HIMACS newsletter",
        subscribe_hflor: "Subscribe to HFLOR newsletter",
        newsletter: "Abonnez-vous maintenant à la newsletter (Veuillez sélectionner les produits qui vous intéressent)",
        policy_0: "J'accepte le traitement de données tel que décrit dans",
        feeling: "ENVIE DE NOUS CONTACTER PAR TÉLÉPHONE ?",
        request: "Votre demande",
        checkPolicy: "Veuillez consulter notre politique de confidentialité",
      },
      button: {
        submit: "Envoyer",
      }
    },
    warranty: {
      text: {
        home: "Accueil",
        support: "Support",
        warranty: "Garantie",
        warranty_down: "Garantie Téléchargement",
        mainTxt1: "Entretien de votre HFLOR. Protégez votre revêtement de sol pour qu'il reste beau et durable. ",
        mainTxt2: "",
        warranty_download: "Téléchargement de la garantie",
        download_form: "Télécharger le formulaire",
        download: "Téléchargement",
        first_page: "first page",
        prev: "Précédent",
        next: "Suivant",
        last_page: "last page",
        EN: "Anglais",
        UK: "Anglais (UK)",
        DE: "Allemand",
        FR: "Français",
        IT: "Italien",
        ES: "Espagnol",
        NL: "Néerlandais",
        lvt: "Carreau de vinyle de luxe",
        hflor_t1: "HFLOR soutient ses produits, apportant beauté et confiance à vos projets.",
        hflor_t2: "En réunissant design, fonction et sécurité, HFLOR offre un ensemble complet de garanties pour vous promettre un système de revêtement de sol haute performance. ",
        himacs_t1: "Termes de la Garantie HIMACS de 15 ans limitée à l'installation.",
        himacs_t2: "Tous les produits HIMACS (à part les produits exterieurs) bénéficient d'une garantie de 15 ans quand la fabrication et l'installtion ont été realisé par un Membre Quality Club.",
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Envoyer",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "Une adresse électronique valide est requise.",

      }
    },
    care_maintenance:{
      text: {
        home : "Accueil",
        support : "Support",
        care_maintenance : "Entretien et maintenance",
        Download: "Téléchargement",
        more: "More",
        download_form: "Téléchargement",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "Téléchargement",
        next: "Suivant",
        download: "Téléchargement",
      }
    },
    google_ads: {
      'airports': 'Aéroports',
      'bathroom':  'Salle de bain',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Mobilier',
      'healthcare': 'Santé',
      'hospitality': 'Hotellerie et Restauration',
      'kitchen': 'Cuisine',
      'offices': 'Bureaux',
      'public_buildings': 'Batiments publiques',
      'retail': 'Batiments publiques',
      'marine_transport':'Batiments publiques',
    }
  },

  IT: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'Rete di rivenditori / distributori',
        FC0030_003: 'Home',
        FC0030_004: 'Contattaci',
        FC0030_005: 'Rete di rivenditori / distributori',
        FC0030_006: 'Seleziona il paese',
        FC0030_007: 'Seleziona il paese',
        FC0030_008: 'Usa la mia posizione attuale',
        FC0030_009: 'Miles',
        FC0030_010: "Seleziona la categoria del prodotto per vedere l'elenco dei negozi vicino a te",
        FC0030_011: 'Categoria prodotto',
        FC0030_012: 'Tipo di negozio',
        FC0030_013: 'Pavimentazione resiliente',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Totale',
        FC0030_017: 'Risultati',
        FC0030_018: 'Distributore per lo Stato',
        FC0030_019: 'Visita il sito',
        FC0030_020: 'Dove siamo'
      },
      alert: {
        FC0030_001: 'Si prega di selezionare prima il paese',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contattaci',
        FC0040_003: 'Trova un rappresentante vendite',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'In Alternativa, Puoi Contattare La Nostra Sede',
        FC0040_024: 'Seleziona il paese',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contattaci",
        faq: "FAQ",
        contactus: "CONTATTI",
        inner1: "Qui troverai le risposte alle domande più frequenti su LX Hausys. ",
        inner2: "Se non trovi quello che stai cercando, contattaci.",
        ALL: "Tutti",
        search: "Ricerca",
        searchBar: "Inserisci una parola chiave per trovare la risposta",
      },
      button: {
        ALL: "Tutti"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contattaci",
        contactus: "CONTATTI",
        completed: "Completato",
        compTitle: "Grazie!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner: "La tua richiesta sarà inviata direttamente al Country Manager della tua zona",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "Generale",
        Other: "Altri prodotti",
        prd_cate: " Categoria di prodotto",
        checkPrd_cate: " Seleziona la categoria del prodotto",
        inquiry: "Tipo di richiesta",
        checkInquiry: "Seleziona tipo di richiesta",
        firstName: "Nome",
        checkFirstName: "Inserisci nome",
        lastName: "Cognome",
        checkLastName: "Inserisci Cognome",
        email: "e-mail",
        checkEmail: "Inserisci e-mail",
        phone: "Telefono",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Paese",
        checkCountry: "Seleziona paese",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "CAP",
        checkZip: "Inserisci CAP",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Nome dell’azienda",
        address: "Address",
        comment: "La tua richiesta, commento",
        checkComment: "Consulta l'Informativa sulla privacy e i Termini di utilizzo",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: ' Informativa sulla privacy',
        terms: 'Terms of Use',
        submit: "Invia",
        find: "Trova ciò che ti serve",
        orderSample: "Order Sample",
        wheretobuy: "Rete di rivenditori / distributori",
        faq: "FAQ",
        document: "Documentazione",
        warranty: "Garanzia",
        care_main: "Cura & Manutenzione",
        fab_guide: "Guida all'installazione e alla fabbricazione",
        guide: "Schede tecniche",
        find_sales: "Trova un rappresentante vendite",
        inputText: "Inserisci testo",
        select: "Seleziona",
        products: "prodotti",
        google_ads_notice1: "Per ulteriori dettagli relativi al trattamento dei dati personali, si prega di fare riferimento alla Informativa sulla privacy della società.",
        google_ads_notice2: "Se avete domande o dubbi sull'invio di informazioni personali, vi preghiamo di contattarci tramite il numero di telefono (49)-69-583-029 469.",
        google_ads_red: "Campi obbligatori",
        subscribe_himacs: "Subscribe to HIMACS newsletter",
        subscribe_hflor: "Subscribe to HFLOR newsletter",
        newsletter: "Iscriviti alla Newsletter HIMACS adesso (Seleziona i prodotti che ti interessano)",
        policy_0: "Accetto gli atti del trattamento dei dati così come descritti nell’",
        feeling: "PREFERISCI CONTATTARCI TELEFONICAMENTE?",
        request: "La tua richiesta",
        checkPolicy: "Consulta l'Informativa sulla privacy",
      },
      button: {
        submit: "Invia",
      }
    },
    warranty: {
      text: {
        home: "Home",
        support: "Supporto",
        warranty: "Garanzia",
        warranty_down: "Garanzia Download",
        mainTxt1: "Cura di HFLOR. Proteggi il tuo pavimento per mantenerlo bello e resistente a lungo.",
        mainTxt2: "",
        warranty_download: "Download della garanzia",
        download_form: "Scarica il modulo",
        download: "Scarica",
        first_page: "first page",
        prev: "Indietro",
        next: "Avanti",
        last_page: "last page",
        EN: "Inglese",
        UK: "Inglese (UK)",
        DE: "Tedesco",
        FR: "Francese",
        IT: "Italiano",
        ES: "Spagnolo",
        NL: "Olandese",
        lvt: "Piastrella vinilica di lusso",
        hflor_t1: "HFLOR resta al fianco dei suoi prodotti, portando bellezza e sicurezza nei tuoi progetti.",
        hflor_t2: "Unendo design, funzionalità e sicurezza, HFLOR offre un pacchetto completo di\n" +
          "garanzie per il sistema di pavimentazione ad alte prestazioni. ",
        himacs_t1: "Garanzia HIMACS di 15 anni limitata all'installazione",
        himacs_t2: "Ogni prodotto HIMACS (tranne applicazioni esterne) ha una garanzia di 15 anni, quando la fabbricazione e l'installazione è realizzata da un membro del \"Quality Club\".",
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Invia",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "È necessario un indirizzo e-mail valido.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        support : "Support",
        care_maintenance : "Cura e manutenzione",
        Download: "Scarica",
        more: "More",
        download_form: "Scarica il modulo",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "'Indietro",
        next: "Avanti",
        download: "Scarica",
      }
    },
    google_ads: {
      'airports': 'Aeroporti',
      'bathroom':  'Bagno',
      'education': 'Istruzione',
      'façade': 'Istruzione',
      'furniture': 'Arredo',
      'healthcare': 'Sanità ',
      'hospitality': 'Horeca',
      'kitchen': 'Cucina',
      'offices': 'Uffici',
      'public_buildings': 'Edifici pubblici ',
      'retail': 'Retail',
      'marine_transport':'Nautica & Trasporti',
    }
  },

  ES: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'Dónde comprar',
        FC0030_003: 'Inicio',
        FC0030_004: 'Contacto',
        FC0030_005: 'Dónde comprar',
        FC0030_006: 'Seleccionar país',
        FC0030_007: 'Seleccionar país',
        FC0030_008: 'Utilizar mi ubicación actual',
        FC0030_009: 'Miles',
        FC0030_010: 'Seleccionar la categoría de producto para ver la lista de almacenes más próximos',
        FC0030_011: 'Categoría de producto',
        FC0030_012: 'Clase de establecimiento',
        FC0030_013: 'Pavimentos',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Resultados',
        FC0030_018: 'Distribuidor por Estado',
        FC0030_019: 'Visitar sitio web',
        FC0030_020: 'Obtener dirección'
      },
      alert: {
        FC0030_001: 'Seleccione el país primero',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Inicio',
        FC0040_002: 'Contacto',
        FC0040_003: 'Contactar con un comercial',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Tambien Puede Ponerse En Contacto Con Nuestras Oficinas Centrales',
        FC0040_024: 'Seleccionar pais',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Inicio",
        support: "Contacto",
        faq: "FAQs",
        contactus: "CONTACTAR CON NOSOTROS",
        inner1: " Aquí encontrará las respuestas a las preguntas más frecuentes sobre LX Hausys.",
        inner2: "Si no encuentra lo que busca, diríjase al apartado «Contactar con nosotros».",
        ALL: "Todos",
        search: "Búsqueda",
        searchBar: "Escriba una palabra clave para encontrar su respuesta",
      },
      button: {
        ALL: "Todos"
      },
    },
    contactus: {
      text: {
        home:"Inicio",
        support: "Contacto",
        contactus: "Contacto",
        completed: "Terminado",
        compTitle: "Gracias!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner: "su solicitud se enviará directamente al Director del País correspondiente",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Otros Productos",
        prd_cate: "Categoría de producto",
        checkPrd_cate: "Seleccionar la categoría de producto",
        inquiry: "Tipo de solicitud",
        checkInquiry: "Seleccionar clase de solicitud",
        firstName: "Nombre",
        checkFirstName: "Escribir nombre",
        lastName: "Apellidos",
        checkLastName: "Escribir Apellidos",
        email: "e-mail",
        checkEmail: "Indicar e-mail",
        phone: "Teléfono",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Pais",
        checkCountry: "Seleccionar país",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "código postal",
        checkZip: "Indicar código postal",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Nombre de la empresa",
        address: "Address",
        comment: "Su solicitud, Comentario",
        checkComment: "Consulte la Política de Privacidad y las Condiciones de Uso",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Política de Privacidad',
        terms: 'Terms of Use',
        submit: "Enviar",
        find: "Encuentre lo que necesita",
        orderSample: "Order Sample",
        wheretobuy: "Dónde comprar",
        faq: "FAQs",
        document: "Información",
        warranty: "Garantía",
        care_main: "Mantenimiento y cuidados",
        fab_guide: "Guía de instalación y fabricación",
        guide: "Información técnica",
        find_sales: "Contactar con un comercial",
        inputText: "Escribir texto",
        select: "Seleccionar",
        products: "Productos",
        google_ads_notice1: "Para obtener más detalles relacionados con el procesamiento de sus datos personales, consulta la Política de Privacidad de la empresa.",
        google_ads_notice2: "Si tienes alguna pregunta o inquietud sobre el envío de información personal, contáctanos a través del siguiente número de teléfono: (49)-69-583-029 469.",
        google_ads_red: "Campos obligatorios",
        subscribe_himacs: "Subscribe to HIMACS newsletter",
        subscribe_hflor: "Subscribe to HFLOR newsletter",
        newsletter: "Suscríbete ahora a la newsletter (Seleccione los productos que le interesen)",
        policy_0: "Acepto el tratamiento de los datos personales tal y como se describe en la ",
        feeling: "¿DESEA PONERSE EN CONTACTO CON NOSOTROS POR TELÉFONO?",
        request: "Su solicitud",
        checkPolicy: "Consulte la Política de Privacidad",
      },
      button: {
        submit: "Enviar",
      }
    },
    warranty: {
      text: {
        home: "Inicio",
        support: "Soporte",
        warranty: "Garantía",
        warranty_down: "Garantía Descargar",
        mainTxt1: "Cuide de su HFLOR. Proteja sus suelos para que conserven mucho tiempo su belleza y resistencia.",
        mainTxt2: "",
        warranty_download: "Descarga de garantía ",
        download_form: "Descargar el formulario",
        download: "Descargar",
        first_page: "first page",
        prev: "Anterior",
        next: "Siguiente",
        last_page: "last page",
        EN: "Inglés",
        UK: "Inglés (UK)",
        DE: "Alemán",
        FR: "Francés",
        IT: "Italiano",
        ES: "Español",
        NL: "Holandés",
        lvt: "Losetas vinílicas de lujo",
        hflor_t1: "HFLOR cuida sus productos, aportando belleza y confianza a los proyectos de sus clientes.",
        hflor_t2: "Al aunar diseño, funcionalidad y seguridad, HFLOR brinda un paquete de garantías exhaustivo para ofrecer un sistema de suelos de alto rendimiento. ",
        himacs_t1: "HIMACS ofrece una garantía de 15 años, la más amplia del mercado de las superficies sólidas.",
        himacs_t2: "Cada producto HIMACS (excepto  aplicaciones en exteriores) tiene una garantía de 15 años, cuando la fabricación y la instalación se hace por un miembro del \"Quality Club\".",
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Enviar",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "Es necesaria una dirección de e-mail válida.",

      }
    },
    care_maintenance:{
      text: {
        home : "Inicio",
        support : "Soporte",
        care_maintenance : "Cuidado y mantenimiento",
        Download: "Descargar",
        more: "More",
        download_form: "Descargar el formulario",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "'Anterior",
        next: "Siguiente",
        download: "Descargar",
      }
    },
    google_ads: {
      'airports': 'Aeropuertos',
      'bathroom':  'Baños',
      'education': 'Educación',
      'façade': 'Fachadas',
      'furniture': 'Mobiliario',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Cocinas',
      'offices': 'Oficinas',
      'public_buildings': 'Oficinas',
      'retail': 'Retail',
      'marine_transport':'Oficinas',
    }
  },

  UK: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please Select Country first to see where you can find LX Hausys material.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact Us',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Select Country',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select product category to see the store list near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'Get Direction'
      },
      alert: {
        FC0030_001: 'Please select country first',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner: "Your request will be directly sent to the Country Manager of your region",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product category",
        checkPrd_cate: " Select Product category",
        inquiry: "Type of Request",
        checkInquiry: "Select Type of Request",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company Name",
        address: "Address",
        comment: "Your request, Comment",
        checkComment: "Please check privacy policy and Terms of use",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        inputText: "Input Text",
        select: "Select",
        producst: "Products",
        google_ads_notice1: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.",
        google_ads_notice2: "If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469.",
        google_ads_red: "Mandatory fields",
        subscribe_himacs: "Subscribe to HIMACS newsletter",
        subscribe_hflor: "Subscribe to HFLOR newsletter",
        newsletter: "Subscribe to newsletter (Please select all that you’re interested in)",
        policy_0: "I acknowledge the acts of data processing as described in the ",
        feeling: "FEELING LIKE CONTACTING US BY PHONE ?",
        request: "Your Request",
        checkPolicy: "Please check privacy policy",
      },
      button: {
        submit: "Submit",
      }
    },
    warranty: {
      text: {
        home: "Home",
        support: "Support",
        warranty: "Warranty",
        warranty_down: "Warranty Download",
        mainTxt1: "Care for your HFLOR. Protect your flooring for long lasting beauty and endurance.",
        mainTxt2: "",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
        hflor_t1: "HFLOR stands by its products, bringing the beauty and the confidence to your projects.",
        hflor_t2: "Bringing together design, function and safety, HFLOR offers a comprehensive package of warranties to guarantees the high-performance flooring system. All HFLOR products are backed by warranties that ensure both quality and additional peace of mind.",
        himacs_t1: "Quality pays for itself: With its 15 year limited installation warranty, HIMACS offers the longest warranty of any Solid Surface manufacturer on the market.",
        himacs_t2: "Every HIMACS product (except external facades) has a warranty of 15 years when the fabrication and installation is done by a Quality Club Member."
      },
      button: {
        warranty_to_register: "Warranty to Register",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        support : "Support",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        contact : "Contact",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  CA: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Search by Address, City, State, Zip Code',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_002: 'state info not found',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        select: "Select",
        inputText : "Input Text",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        Dealer: "Dealer / Kitchen&Bath / Fabricator",
        AnD: "A&D / Commercial Project",
        Sample: "Sample",
        homeCenter: "Home Center Account Question",
        technicalInquiry: "Technical Inquiry",
        others: "Others",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name",
        checkCompanyNm: "Enter Company Name",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentUs: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469."
      },
      button: {
        submit: "Submit",
      }
    },

    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering?",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Phone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State/Province",
        select_state: "Select State",
        zip_code: "Zip/Postal Code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "Where is this Product Installed?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase ",
        upload_file_brackets: '(attach a receipt that shows the consumer’s name, address, and material installed)',
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty Registration",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        contact : "Contact",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  CF: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Search by Address, City, State, Zip Code',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_002: 'state info not found',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        select: "Select",
        inputText : "Input Text",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        Dealer: "Dealer / Kitchen&Bath / Fabricator",
        AnD: "A&D / Commercial Project",
        Sample: "Sample",
        homeCenter: "Home Center Account Question",
        technicalInquiry: "Technical Inquiry",
        others: "Others",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name",
        checkCompanyNm: "Enter Company Name",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentUs: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469."
      },
      button: {
        submit: "Submit",
      }
    },

    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering?",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Phone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State/Province",
        select_state: "Select State",
        zip_code: "Zip/Postal Code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "Where is this Product Installed?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase ",
        upload_file_brackets: '(attach a receipt that shows the consumer’s name, address, and material installed)',
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty Registration",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        contact : "Contact",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  JP: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Search by Address, City, State, Zip Code',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_002: 'state info not found',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        select: "Select",
        inputText : "Input Text",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        Dealer: "Dealer / Kitchen&Bath / Fabricator",
        AnD: "A&D / Commercial Project",
        Sample: "Sample",
        homeCenter: "Home Center Account Question",
        technicalInquiry: "Technical Inquiry",
        others: "Others",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name",
        checkCompanyNm: "Enter Company Name",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentUs: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469."
      },
      button: {
        submit: "Submit",
      }
    },

    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering?",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Phone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State/Province",
        select_state: "Select State",
        zip_code: "Zip/Postal Code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "Where is this Product Installed?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase ",
        upload_file_brackets: '(attach a receipt that shows the consumer’s name, address, and material installed)',
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty Registration",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        contact : "Contact",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  RU: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Search by Address, City, State, Zip Code',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_002: 'state info not found',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        select: "Select",
        inputText : "Input Text",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        Dealer: "Dealer / Kitchen&Bath / Fabricator",
        AnD: "A&D / Commercial Project",
        Sample: "Sample",
        homeCenter: "Home Center Account Question",
        technicalInquiry: "Technical Inquiry",
        others: "Others",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name",
        checkCompanyNm: "Enter Company Name",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentUs: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469."
      },
      button: {
        submit: "Submit",
      }
    },

    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering?",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Phone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State/Province",
        select_state: "Select State",
        zip_code: "Zip/Postal Code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "Where is this Product Installed?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase ",
        upload_file_brackets: '(attach a receipt that shows the consumer’s name, address, and material installed)',
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty Registration",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        contact : "Contact",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },

  CN: {
    wheretobuy: {
      text: {
        FC0030_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.',
        FC0030_002: 'WHERE TO BUY',
        FC0030_003: 'Home',
        FC0030_004: 'Contact',
        FC0030_005: 'Where to Buy',
        FC0030_006: 'Search by Address, City, State, Zip Code',
        FC0030_007: 'Select Country',
        FC0030_008: 'Use my current location',
        FC0030_009: 'Miles',
        FC0030_010: 'Please select a Product to see a list of stores near you.',
        FC0030_011: 'Product Category',
        FC0030_012: 'Store Type',
        FC0030_013: 'Resilient Flooring',
        FC0030_014: 'Quartz Surface',
        FC0030_015: 'Solid Surface',
        FC0030_016: 'Total',
        FC0030_017: 'Results',
        FC0030_018: 'Distributor for State',
        FC0030_019: 'Visit Site',
        FC0030_020: 'GET DIRECTION'
      },
      alert: {
        FC0030_001: 'Enter search keyword',
        FC0030_002: 'state info not found',
        FC0030_003: 'Geolocation service got error.',
        FC0030_004: 'Geolocation service not supported browser. Please change your browser.'
      }
    },
    findASalesRep: {
      text: {
        FC0040_001: 'Home',
        FC0040_002: 'Contact',
        FC0040_003: 'Find a Sales Representative',
        FC0040_004: 'Hover over the map to find our representative contact information.',
        FC0040_005: 'HFLOR',
        FC0040_006: 'HIMACS',
        FC0040_007: 'Viatera',
        FC0040_008: 'Europe',
        FC0040_009: 'Africa',
        FC0040_010: 'Russia & Cis',
        FC0040_011: 'OCEANIA',
        FC0040_012: 'North America',
        FC0040_013: 'Latin America',
        FC0040_014: 'UNITED KINGDOM',
        FC0040_015: 'TURKEY',
        FC0040_016: 'MIDDLE EAST',
        FC0040_017: 'INDIA',
        FC0040_018: 'CHINA',
        FC0040_019: 'SOUTHEAST ASIA',
        FC0040_020: 'JAPAN',
        FC0040_021: 'Please visit website',
        FC0040_022: 'Select Area/Country',
        FC0040_023: 'Alternatively, Feel Free to Contact Our Head Offices',
        FC0040_024: 'Select Country',
      },
      button: {
        //FC0040_001: 'Please select Country, and then enter Street Address, City, State, or Zip Code.'
      },
      alert: {
        //FC0040_001: 'Enter search keyword'
      }
    },
    faq: {
      text: {
        home: "Home",
        support: "Contact",
        faq: "FAQ",
        contactus: "Contact Us",
        inner1: "Here, you'll find answers to the most common questions people ask LX Hausys.",
        inner2: "If you do not find what you are looking for, please ",
        ALL: "ALL",
        search: "search",
        searchBar: "Enter a Keyword to find your answer",
      },
      button: {
        ALL: "ALL"
      },
    },
    contactus: {
      text: {
        home:"Home",
        support: "Contact",
        contactus: "Contact Us",
        completed: "Completed",
        compTitle: "Thank you!",
        compText: "Thank you for contacting us, <br> we'll be in touch very soon",
        goToMain: "Go Back to the Homepage",
        inner:"Let’s talk about your next project.<br>" +
          "Please fill out the form below and we will contact you promptly.<br>" +
          "Your reply will be sent to the email address that you submit.",
        for_pro: "For Professionals",
        for_home: "For Homeowners",
        submitform: "Submit Form",
        pro_form: "Contact us For Professionals form",
        home_form: "Contact us For Homeowners form",
        select: "Select",
        inputText : "Input Text",
        HFLOR: "HFLOR",
        HIMACS: "HIMACS",
        Viatera: "VIATERA",
        General: "General",
        Other: "Other Products",
        prd_cate: "Product Category",
        checkPrd_cate: "Select Product",
        inquiry: "Type of inquiry",
        checkInquiry: "Select Type of inquiry",
        Dealer: "Dealer / Kitchen&Bath / Fabricator",
        AnD: "A&D / Commercial Project",
        Sample: "Sample",
        homeCenter: "Home Center Account Question",
        technicalInquiry: "Technical Inquiry",
        others: "Others",
        firstName: "First Name",
        checkFirstName: "Enter First Name",
        lastName: "Last Name",
        checkLastName: "Enter Last Name",
        email: "Email",
        checkEmail: "Enter Email",
        phone: "Phone Number",
        checkPhone: "Enter Phone",
        requiredField : "This is a required field",
        area: "Area",
        checkArea: "Select Area",
        country: "Country",
        checkCountry: "Select Country",
        state: "State/Province",
        checkState: "Select State/Province",
        zip: "Zip/Postal Code",
        checkZip: "Enter Valid Zipcode",
        occupation: "Occupation",
        checkOcc: "Select Occupation",
        company: "Company",
        companyNm: "Company Name",
        checkCompanyNm: "Enter Company Name",
        companyWebs: "Company Website (Optional)",
        companyAddr: "Company Address (Optional)",
        jobTitle: "Job Title (Optional)",
        address: "Address",
        comment: "Comment",
        checkCommentUs: "Enter Comment",
        checkComment: "Check Privacy Policy and Terms of use Agreement",
        consent1: 'By providing your details and clicking',
        consent2: ', you acknowledge and agree to our website ',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        submit: "Submit",
        find: "Find what you Need",
        orderSample: "Order Sample",
        wheretobuy: "Where to buy",
        faq: "FAQ",
        document: "Documentation",
        warranty: "Warranty",
        care_main: "Care & Maintenance",
        fab_guide: "Fabrication & Installation Guide",
        guide: "Installation Guide",
        find_sales: "Find a Sales Representative",
        google_ads_notice: "For more details related to processing your personal data, please refer to the Company’s Privacy Policy.<br />If you have any question or have concern in submitting personal information online, please contact us via Main Phone Number at (49)-69-583-029 469."
      },
      button: {
        submit: "Submit",
      }
    },

    warranty: {
      text: {
        home: "Home",
        support: "Contact",
        warranty: "Warranty",
        mainTxt1: "LX Hausys believes in the unparalleled quality of our products,",
        mainTxt2: "which is why we proudly offer one of the industry's best fully-transferable warranties.",
        warranty_download: "Warranty Download",
        download_form: "download form",
        download: "download",
        first_page: "first page",
        prev: "prev",
        next: "next",
        last_page: "last page",
        WarrantyRegisterForm: "Warranty to Register form",
        regTxt: "Who is Registering?",
        select_product: "Select Product",
        color: "What is the Color of the Product?",
        categoryTxt: "What Product are You Registering",
        select_registering: "Select Registering",
        personal_info: "Personal Information",
        personal_info_form : "Personal Information form",
        first_name: "First Name",
        Enter_First_Name: "Enter First Name",
        last_name: "Last Name",
        Enter_Last_Name: "Enter Last Name",
        address1 : "Street Address 1",
        enter_address: "Enter Street Address",
        Address2 : "Street Address 2",
        telephone: "Phone",
        enter_telephone: "Enter Telephone",
        email: "Email Address",
        enter_email: "Enter Email Address",
        country: "Country",
        select_country: "Select Country",
        state: "State/Province",
        select_state: "Select State",
        zip_code: "Zip/Postal Code",
        enter_zip_code: "Enter Zip code",
        city: "City",
        enter_city: "Enter City",
        dealer_info: "Dealer/Installer Information",
        dealer_info_form: "Dealer/Installer Information form",
        dealer: "Dealer/Builder",
        enter_dealer: "Enter Dealer/Builder",
        dealer_city: "Dealer/Builder’s City",
        enter_dealer_city: "Enter Dealer/Builder’s City",
        dealer_phone: "Dealer/Builder’s Phone",
        enter_dealer_phone: "Enter Dealer/Builder’s Phone",
        fabricator: "Fabricator/Installer",
        enter_fabricator: "Enter Fabricator/Installer",
        fabricator_phone: "Fabricator/Installer's Phone",
        enter_fabricator_phone: "Enter Fabricator/Installer's Phone",
        date_of_installation: "Date of Installation",
        select_date: "Select Date of Installation",
        product_info: "Product Information",
        product_info_form: "Product Information form",
        materialsTxt: "Where is this Product Installed?",
        select_materialsTxt: "What locations was the LX Hausys material installed that you are registering?",
        upload_file: "Proof of Purchase ",
        upload_file_brackets: '(attach a receipt that shows the consumer’s name, address, and material installed)',
        select_upload_file: "Enter Proof of Purchase (attached receipt that shows the consumers name address and material installed)",
        sink_type: "Which LX Hausys sink was installed?",
        select_sink_type: "Select Which Type of Sink and Bowl Did You Use?",
        hear :"How Did You Hear About LX Hausys Materials?",
        select_hear: "Select How Did You Hear About LX Hausys Materials?",
        input_text: "Input Text",
        comments: "Comments",
        upload: "upload",
        EN: "English",
        UK: "English (UK)",
        DE: "German",
        FR: "French",
        IT: "Italian",
        ES: "Spanish",
        NL: "Dutch",
        lvt: "Luxury Vinyl Tile",
      },
      button: {
        warranty_to_register: "Warranty Registration",
        submit_warranty_claim: "Submit Warranty Claim",
        submit: "Submit",
      },
      alert: {
        file1: "Up to 10 files can be attached.",
        file2: "The file size can be up to 5MB.",
        success: "The application has been completed",
        fail: "Sorry! An Error occurred. lease try again.",
        email: "A Valid email address is required.",

      }
    },
    care_maintenance:{
      text: {
        home : "Home",
        contact : "Contact",
        care_maintenance : "Care and Maintenance",
        Download: "Download",
        more: "More",
        download_form: "download form",
      },
      alert:{
        confirm: "Do you want to download the file?",
      },
      button: {
        prev: "prev",
        next: "next",
        download: "download",
      }
    },
    google_ads: {
      'airports': 'Airports',
      'bathroom':  'Bathroom',
      'education': 'Education',
      'façade': 'Façade',
      'furniture': 'Furniture',
      'healthcare': 'Healthcare',
      'hospitality': 'Hospitality',
      'kitchen': 'Kitchen',
      'offices': 'Offices',
      'public_buildings': 'Public Buildings',
      'retail': 'Retail',
      'marine_transport':'Marine & Transport',
    }
  },
}
