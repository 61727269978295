var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content content-finder" }, [
        _c(
          "div",
          {
            staticClass: "location",
            class: { type02: _vm.nationCode === "_GL" },
          },
          [
            _c("ul", [
              _c(
                "li",
                {
                  directives: [
                    { name: "hover-cursor", rawName: "v-hover-cursor" },
                  ],
                  staticClass: "ico-home",
                  on: {
                    click: function ($event) {
                      _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("PCD.text.home")) + " ")]
              ),
              _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$t(`PF.text.${_vm.pcdMenuType.toLowerCase()}`))
                ),
              ]),
              _c("li", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      `PF.text.allColors.${_vm.$route.meta.parentType.toLowerCase()}`
                    )
                  )
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "container prod-fd-view container_pf vizuon-pf" },
          [
            _vm.nationCode === "_US"
              ? _c("h1", { staticClass: "title_hidden" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        `PF.text.allColors.${_vm.$route.meta.parentType.toLowerCase()}`
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm.pcdContentAttribute.main_use_yn === "true" &&
            _vm.nationCode === "_GL"
              ? _c("div", { staticClass: "visual visual-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass: "visual-image full_width center",
                      style: {
                        backgroundImage: `url('${_vm.$imagePath}${_vm.pcdContentAttribute.main_img}')`,
                      },
                    },
                    [
                      _c("div", { staticClass: "inner_width" }, [
                        _vm.nationCode !== "_US"
                          ? _c("div", { staticClass: "txt-box" }, [
                              _c("h1", [
                                _c("div", { staticClass: "title" }, [
                                  _vm.visualImageLoadError
                                    ? _c("span", { staticClass: "txt" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.pcdContentAttribute.main_title
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.visualImageLoadError
                                    ? _c("img", {
                                        staticClass: "visual_logo",
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            "/2024/images/main/logo/" +
                                            _vm.$route.meta.type.toUpperCase() +
                                            ".svg",
                                          alt: "about product",
                                        },
                                        on: {
                                          error: function ($event) {
                                            _vm.visualImageLoadError = true
                                          },
                                          load: function ($event) {
                                            _vm.visualImageLoadError = false
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c("p", { staticClass: "txt-02" }, [
                                  _vm._v(
                                    _vm._s(_vm.pcdContentAttribute.main_desc)
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "drop-list inner" }, [
              _c(
                "div",
                { staticClass: "w-con-02" },
                [
                  _c("div", { staticClass: "gallery" }, [
                    _c("div", { staticClass: "search-box" }, [
                      _c(
                        "div",
                        { staticClass: "filter_area" },
                        [
                          _c("div", { staticClass: "filter_wrap" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn_filter",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickFilterBtn()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "icon icon_filter" }),
                                _c("span", { staticClass: "txt p-ui" }, [
                                  _vm._v("Filter"),
                                ]),
                                _c("span", { staticClass: "txt m-ui" }, [
                                  _vm._v("Filter by"),
                                ]),
                                _c("span", {
                                  staticClass: "icon icon_arrow_down",
                                }),
                              ]
                            ),
                          ]),
                          _vm._m(0),
                          _c("div", { staticClass: "search" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchText,
                                  expression: "searchText",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "textSearch",
                                placeholder: this.$t("PF.text.searchText"),
                              },
                              domProps: { value: _vm.searchText },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.searchText = $event.target.value
                                  },
                                  _vm.textSearch,
                                ],
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.textSearch.apply(null, arguments)
                                },
                              },
                            }),
                            _vm.searchText.length !== 0
                              ? _c("button", {
                                  staticClass: "btn_cancel",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.textSearchDel()
                                    },
                                  },
                                })
                              : _c(
                                  "button",
                                  {
                                    staticClass: "icon_search",
                                    attrs: { type: "button", id: "searchBtn" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.textSearchDel()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.search")) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]),
                          _c("transition", { attrs: { name: "fade" } }, [
                            _c("div", { staticClass: "filter_pop" }, [
                              _c("div", { staticClass: "filter_tit" }, [
                                _c("p", [_vm._v("FILTER BY")]),
                                _c("button", {
                                  staticClass: "btn_close",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeFilterPop()
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "filter_top" },
                                [
                                  _vm.category.id
                                    ? _c(_vm.filterComponent, {
                                        tag: "component",
                                        attrs: {
                                          category: _vm.category,
                                          collectionList: _vm.collectionList,
                                          dictMap: _vm.dictMap,
                                          countryCode: _vm.countryCode,
                                        },
                                        on: {
                                          "category-changed":
                                            _vm.handleCategoryChanged,
                                          "category-changed-vizuon":
                                            _vm.handleCategoryChangedVizuon,
                                          change: function ($event) {
                                            return _vm.handleCheckBox(
                                              $event,
                                              "productList",
                                              "productCheckList",
                                              0
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.filter,
                                          callback: function ($$v) {
                                            _vm.filter = $$v
                                          },
                                          expression: "filter",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "filter_bottom" }, [
                                _c("div", { staticClass: "btn_box" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn_wh",
                                      on: { click: _vm.filterClearAll },
                                    },
                                    [_vm._v("Clear All")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn_bk",
                                      on: { click: _vm.applyFilter },
                                    },
                                    [_vm._v("Apply")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "sort_area" }, [
                        _vm.selectedFilters.length > 0 || _vm.showCategory()
                          ? _c("div", { staticClass: "select-view" }, [
                              _c(
                                "ul",
                                { staticClass: "list-filter-key" },
                                [
                                  _vm.showCategory()
                                    ? _c("li", { staticClass: "sel" }, [
                                        _c("span", [
                                          _vm._v(
                                            "CATEGORY: " +
                                              _vm._s(_vm.category.name)
                                          ),
                                        ]),
                                        _c("button", {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCategoryChanged(
                                                "PRD_FLOORING"
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.selectedFilters,
                                    function (filter) {
                                      return _c(
                                        "li",
                                        {
                                          key: filter.name,
                                          staticClass: "sel",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getFilterLabel(filter))
                                            ),
                                          ]),
                                          _c("button", {
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFilter(filter)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _c("li", { staticClass: "sel del_all" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-all-delete",
                                        attrs: { type: "button" },
                                        on: { click: _vm.clearSelectedFilters },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PF.button.clear_all")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "btns" }, [
                          _c("ul", [
                            _c("li", { staticClass: "sel-box" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "sel-list" }, [
                                _c("ul", { staticClass: "box" }, [
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Products A to Z",
                                              "asc",
                                              "title"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.products_a_to_z"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Products Z to A",
                                              "desc",
                                              "title"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.products_z_to_a"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sortByChange(
                                              "Most Recent",
                                              "asc"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PF.button.new_products_first"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._m(2),
                      ]),
                      _c("div", { staticClass: "count_area" }, [
                        _c("div", { staticClass: "filter-cnt" }, [
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.contentLength)),
                          ]),
                          _c("span", [_vm._v(" ")]),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v("Results"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.isInitialLoad
                    ? [_vm._m(3)]
                    : !_vm.isLoading && _vm.contentList.length === 0
                    ? [_vm._m(4)]
                    : [
                        _c("div", { staticClass: "list-type-view row-04" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.contentList, function (item, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: _vm.makeUrl(item),
                                        "data-ec-product":
                                          _vm.makeDataLayerForm(item),
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "img-box" }, [
                                        item.attribute.isNew == "true"
                                          ? _c(
                                              "span",
                                              { staticClass: "tag h30 new" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("PF.text.new"))
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "image_name_and_tag" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "image_name" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.content.title)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "icon_tag_wrap" },
                                          [
                                            item.attribute.fire_resistance ===
                                            "001"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass: "icon-no-fire",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("no-fire"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.attribute.isRecycled === "true"
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "icon-recycled-chips",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("recycled"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.$imagePath +
                                              item.attribute.pf_thumb,
                                            alt: item.attribute.pf_thumb,
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                  _vm.nationCode === "_US" && _vm.canOrder(item)
                                    ? _c("div", { staticClass: "view" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "icon_cart",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openSampleOrder(
                                                  item.content.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("PCD.text.cart"))
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "pf_cont_compare_wrap" },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          id: "item.content.id",
                                        },
                                      }),
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.compareArr,
                                              expression: "compareArr",
                                            },
                                          ],
                                          staticClass: "pf_cont_compare",
                                          attrs: {
                                            type: "checkbox",
                                            id: item.content.id,
                                          },
                                          domProps: {
                                            value: item.content.id,
                                            checked: Array.isArray(
                                              _vm.compareArr
                                            )
                                              ? _vm._i(
                                                  _vm.compareArr,
                                                  item.content.id
                                                ) > -1
                                              : _vm.compareArr,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = _vm.compareArr,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = item.content.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.compareArr =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.compareArr = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.compareArr = $$c
                                                }
                                              },
                                              function ($event) {
                                                return _vm.addCompare(
                                                  item.content.id
                                                )
                                              },
                                            ],
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("PF.button.compare"))
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm.isLoading
                          ? _c("div", { staticClass: "loading_area" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://img.lxhausys.com/public/images/icon/loading.gif",
                                  alt: "loading",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "layer-popup layer-alert" }, [
              _c("div", { staticClass: "popup" }, [
                _c("div", { staticClass: "pop-alert" }, [
                  _c("div", [
                    _c("div", { staticClass: "tit-box" }, [
                      _c("p", { staticClass: "popup_title artTit" }, [
                        _vm._v(_vm._s(_vm.$t("PF.popup.your_compare_is_full"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "txt-box" }, [
                      _vm._m(5),
                      _vm._m(6),
                      _c("div", { staticClass: "btn-box" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn h44",
                            attrs: { type: "button", id: "replace" },
                            on: {
                              click: function ($event) {
                                return _vm.replace()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.popup.replace"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "bottom-box" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.getCompareList()
                          },
                        },
                      },
                      [_c("span", [_vm._v("Edit products in compare")])]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(" close ")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "layer-popup layer-compare",
                attrs: { id: "pdf_wrap" },
              },
              [
                _c("div", { staticClass: "popup pop-close" }, [
                  _c("div", { staticClass: "pop-compare scr-y" }, [
                    _c("div", { staticClass: "tit-box" }, [
                      _c("p", [
                        _vm._v(
                          "COMPARE " +
                            _vm._s(
                              _vm.$t(`PF.text.${_vm.pcdMenuType.toLowerCase()}`)
                            ) +
                            " PRODUCTS"
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "popup-compare-cont" }, [
                    _vm._m(7),
                    _c("div", { staticClass: "prod-list tbl-compare" }, [
                      _c(
                        "ul",
                        [
                          _vm._l(_vm.compareList, function (item, idx) {
                            return _c("li", [
                              _c("div", { staticClass: "prod-box" }, [
                                _c("div", { staticClass: "prod" }, [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.$imagePath +
                                          item.attribute.pf_thumb,
                                        alt: item.attribute.pf_thumb,
                                      },
                                    }),
                                    _c("div", { staticClass: "btn-remove" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-remove02",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCompare(
                                                item.content.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.button.remove"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "info" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(item.content.title)),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "tag" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(item.parents[0].name)),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "tbl_wrap" }, [
                                    _c(
                                      "table",
                                      [
                                        _vm.pcdMenuTitle === "HIMACS"
                                          ? [
                                              _vm._m(8, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .dimensionText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(9, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thicknessText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(10, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .adhesive_code || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(11, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .weightText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(12, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .adhesive_name || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "VIATERA"
                                          ? [
                                              _vm._m(13, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .finishText[0] || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(14, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getAllSlabSizes(
                                                          item
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                              ]),
                                              _vm._m(15, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getAllThicknesses(
                                                          item
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                              ]),
                                              _vm._m(16, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getAllWeights(item)
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                              ]),
                                              _vm._m(17, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.remark &&
                                                          item.remark !== "[]"
                                                          ? item.remark
                                                          : "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "TERACANTO"
                                          ? [
                                              _vm._m(18, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getAllFinishes(item)
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                              ]),
                                              _vm._m(19, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getAllDimensions(
                                                          item
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                              ]),
                                              _vm._m(20, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getAllTeraThicknesses(
                                                          item
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]),
                                              ]),
                                              _vm._m(21, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .bookmatchText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(22, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.remark ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "HFLOR"
                                          ? [
                                              _vm._m(23, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .dimensionText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(24, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thicknessText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(25, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .wear_layerText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(26, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .patternText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(27, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .prdTypeText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "Deco Foil"
                                          ? [
                                              _vm._m(28, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.pet
                                                          ? "O"
                                                          : "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(29, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.pvc
                                                          ? "O"
                                                          : "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(30, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.acrylic
                                                          ? "O"
                                                          : "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(31, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thicknessText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(32, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .widthText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "BENIF"
                                          ? [
                                              _vm._m(33, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .dimensionText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(34, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.repeat ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(35, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thicknessText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(36, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .fire_resistanceText ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "VIZUON"
                                          ? [
                                              _vm._m(37, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .material || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(38, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thickness || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(39, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .ft_color || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(40, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .adhesive_typeText ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(41, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .adhesive_color || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(42, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .air_freeText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(43, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.liner ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "Exterior Foil"
                                          ? [
                                              _vm._m(44, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .gradeText[0] || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(45, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thicknessText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(46, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.attribute
                                                            .widthText
                                                            ? `${
                                                                item.attribute
                                                                  .widthText
                                                              }${
                                                                item.attribute
                                                                  .width2Text
                                                                  ? ` / ${item.attribute.width2Text}`
                                                                  : ""
                                                              }`
                                                            : "-"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(47, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .lengthText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm.pcdMenuTitle === "BORTE"
                                          ? [
                                              _vm._m(48, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .dimensionText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(49, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .surfaceMaterialText ||
                                                          "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(50, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .thicknessText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._m(51, true),
                                              _c("tr", [
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute
                                                          .emissionText || "-"
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          }),
                          _c("li", { staticClass: "add_more" }, [
                            _c("div", { staticClass: "prod-box" }, [
                              _vm.compareList.length < 4
                                ? _c("div", { staticClass: "prod" }, [
                                    _c("div", { staticClass: "img-box" }, [
                                      _c(
                                        "div",
                                        { staticClass: "btn-add hf-btn-add" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              attrs: {
                                                type: "button",
                                                onclick: "LayerPopup.close();",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PF.button.add_more")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "btn-download p-ui" }, [
                      _vm.compareList.length >= 1
                        ? _c(
                            "button",
                            {
                              staticClass: "button h44 btn_download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.makePDF()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "txt" }, [
                                _vm._v("Download"),
                              ]),
                              _c("span", { staticClass: "icon icon_download" }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: {
                          type: "button",
                          onclick:
                            "LayerPopup.close();$('body').removeClass('scrolllock');",
                        },
                      },
                      [_vm._v(" close ")]
                    ),
                  ]),
                  _c("div", { staticClass: "btn-download m-ui" }, [
                    _vm.compareList.length >= 1
                      ? _c(
                          "button",
                          {
                            staticClass: "button h44 btn_download",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.makePDF()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Download"),
                            ]),
                            _c("span", { staticClass: "icon icon_download" }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
                staticStyle: { display: "none" },
                attrs: { id: "layer-search-box" },
              },
              [
                _c("div", { staticClass: "popup" }, [
                  _c("div", { staticClass: "scr-y" }, [
                    _c("div", { staticClass: "drop-list" }, [
                      _c("p", { staticClass: "tt-2" }, [_vm._v("Filter By")]),
                      _c("button", {
                        staticClass: "filter-clos",
                        on: {
                          click: function ($event) {
                            return _vm.closeSearchPopup()
                          },
                        },
                      }),
                      _c("ul", [
                        _c("li", { staticClass: "on" }, [
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-color",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.color")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "more-box type02 type03 compare_box" }, [
        _c(
          "button",
          {
            staticClass: "button h55 btn_compare",
            attrs: { type: "button", id: "compare" },
            on: {
              click: function ($event) {
                return _vm.getCompareList()
              },
            },
          },
          [
            _c("span", { staticClass: "txt" }, [
              _vm._v(_vm._s(_vm.$t("PF.button.compare_your_colors"))),
            ]),
            _c("span", { staticClass: "bubble_num" }, [
              _c("span", [_vm._v(_vm._s(_vm.compareArr.length))]),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "compare_wrap" }, [
      _c("input", {
        staticClass: "compare_btn",
        attrs: {
          type: "checkbox",
          id: "compare_btn",
          name: "compare_button",
          value: "",
        },
      }),
      _c("label", { attrs: { for: "compare_btn" } }, [
        _c("span", { staticClass: "txt" }, [_vm._v("ON")]),
        _c("span", { staticClass: "btn" }),
      ]),
      _c("span", [_vm._v("Compare")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn-sel", attrs: { type: "button" } }, [
      _c("span", [_vm._v("Most Recent")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gallery_layout_btns" }, [
      _c(
        "button",
        { staticClass: "layout_grid on", attrs: { type: "button" } },
        [_c("span", { staticClass: "icon icon_grid" })]
      ),
      _c("button", { staticClass: "layout_list", attrs: { type: "button" } }, [
        _c("span", { staticClass: "icon icon_list" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_area" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/public/images/icon/loading.gif",
          alt: "loading",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "grid-box" }, [
      _c("div", { staticClass: "no_result" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/public/images/support/no_result.png",
            alt: "noresult",
          },
        }),
        _c("p", [_vm._v("No Result found")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgwrap" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/images/svg_icon/icon_alert_pop_check.svg",
          alt: "Your comparator is full",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "txt" }, [
      _c("p", [
        _vm._v(" Do you want to replace the first Products "),
        _c("br"),
        _vm._v(" you added by the one you selected? "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtit-box" }, [
      _c("p", [_vm._v("Characteristics & Technical Performance")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Slab Size (inch)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness (inch)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Adhesive code")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Weight (lbs)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Adhesive name")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Finish")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Slab Size (inch)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness (cm)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Weight (lbs)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Remark")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Finish")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Dimension (mm)")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Bookmatch")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Remark")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Dimensions")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Wear Layer")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Pattern")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Installation")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("PET")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("PVC")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("ACRYLIC")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Width")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Dimensions")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Repeat Image")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Fire Resistance")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Film Type Material")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Film Type Thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Film Type Color")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_c("span", [_vm._v("Film Type Adhesive Type")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Adhesive Type Color")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_c("span", [_vm._v("Adhesive Type Air Free")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Liner")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Grade")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Width")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Length")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Dimension")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Surface material")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Total thickness")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_c("span", [_vm._v("Formaldehyde emission (mg/l)")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }