<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content prod-dt-cate">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('PCD.text.home')}}</li>
          <li
            v-for="(loc, idx) in location"
            v-bind:key="idx"
          >
            {{ loc }}
          </li>
        </ul>
      </div>
      <!-- //location -->

      <div class="container prod-dt-view">
        <ProductTopImage
          :imageSrc="imageSrc"
          :title="title"
          :titleColor="titleColor"
        >
        </ProductTopImage>
        <slot name="content"></slot>
      </div>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import ProductTopImage from '@/components/product/ProductTopImage'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    ProductTopImage
  },

  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    titleColor: {
      type: String,
      default: '#FFF'
    }
  },

  data () {
    return {
      location: [],
      countryCode: '',
      countryCodeSub: ''
    }
  },

  methods: {
    gotolink (url) {
      location.href = url
    }
  },

  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

  mounted () {
    this.location = this.$route.meta.location
  }
}
</script>
