var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content cntu-view reques" }, [
        _vm._m(0),
        _c("div", { staticClass: "container cntu-box inner" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _c("h1", { staticClass: "tt-1 mb-64 tac" }, [
              _vm._v(
                _vm._s(_vm.$t("partnersPortal.login.himacs_partners_portal"))
              ),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "mypg-loginJoin" }, [
              _c("div", { staticClass: "mypg-login" }, [
                _c("div", { staticClass: "login-box" }, [
                  _c("ul", [
                    _c("li", [
                      _c("label", { attrs: { for: "inp01" } }, [
                        _vm._v("Email"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "inp-text",
                        attrs: { type: "text", id: "inp01", placeholder: "" },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("li", [
                      _c("label", { attrs: { for: "inp02" } }, [
                        _vm._v("Password"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        staticClass: "inp-text",
                        attrs: {
                          type: "password",
                          id: "inp02",
                          placeholder: "",
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c(
                  "label",
                  { staticClass: "inp-chk w100p", attrs: { for: "chk01" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rememberMe,
                          expression: "rememberMe",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "chk01",
                        checked: "checked",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.rememberMe)
                          ? _vm._i(_vm.rememberMe, null) > -1
                          : _vm.rememberMe,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.rememberMe,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.rememberMe = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.rememberMe = $$c
                          }
                        },
                      },
                    }),
                    _c("span", [_vm._v("Remember me")]),
                    _vm._m(2),
                  ]
                ),
                _c("div", { staticClass: "ta-c mt-40" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.login()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Login")])]
                  ),
                ]),
              ]),
              _vm._m(3),
              _c("div", { staticClass: "mypg-forgotPW tyhima bd-btm" }, [
                _c("p", { staticClass: "tit-04 hpp-title-type-02" }, [
                  _vm._v("Forgot Your Password?"),
                ]),
                _c("div", { staticClass: "inpt-mypg flex-space-between" }, [
                  _c("ul", [
                    _c("li", [
                      _c("label", { attrs: { for: "inp04" } }, [
                        _vm._v("Email"),
                      ]),
                      _c("span", { staticClass: "w390" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.forgotPassEmail,
                              expression: "forgotPassEmail",
                            },
                          ],
                          staticClass: "inp-text",
                          attrs: {
                            type: "text",
                            id: "inp04",
                            placeholder: "Enter your email address",
                          },
                          domProps: { value: _vm.forgotPassEmail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.forgotPassEmail = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "btn-mypg02 dis-in-bloc" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn fl-r",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.forgotPass()
                              },
                            },
                          },
                          [_c("span", [_vm._v("Go")])]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(4),
            ]),
          ]),
        ]),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Home")]),
        _c("li", [_vm._v("Professionals")]),
        _c("li", [_vm._v("HIMACS Partners Portal")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ctt ctt-t2 ta-c" }, [
      _c("span", { staticClass: "pc-br ctt ctt-t2" }, [
        _vm._v(
          "Welcome to the Partner Section, a restricted area dedicated to our affiliates."
        ),
      ]),
      _c("span", { staticClass: "pc-br ctt ctt-t2" }, [
        _vm._v("Already a HIMACS partner? Login to your account."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn btn-text forgot-email", attrs: { type: "button" } },
      [
        _c(
          "span",
          {
            staticClass: "txt-idfind-btn",
            attrs: {
              onclick: "location.href='himacsPartnersPortal/findYourEmail'",
            },
          },
          [_vm._v("Forgot Your Email?")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mypg-sign01" }, [
      _c("p", { staticClass: "tit-04 hpp-title" }, [
        _vm._v("Request a partner account "),
      ]),
      _c("div", { staticClass: "t-type flex-btm-align" }, [
        _c("span", { staticClass: "ctt ctt-t4" }, [
          _vm._v("Only for existing partners"),
        ]),
        _c(
          "button",
          {
            staticClass: "btn fl-r",
            attrs: {
              type: "button",
              onclick:
                "location.href='himacsPartnersPortal/requestPartnerAccount'",
            },
          },
          [_c("span", [_vm._v("Request")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mypg-forgotPW tyhima" }, [
      _c("div", { staticClass: "t-type-03 mt-0 ctt ctt-t4" }, [
        _c("span", { staticClass: "ctt ctt-t4 color-gray" }, [
          _vm._v("Not a partner yet?"),
        ]),
        _vm._v("  "),
        _c(
          "a",
          {
            staticClass: "ctt ctt-t4 color-black underline",
            attrs: { href: "support.html#/contactUs" },
          },
          [_vm._v("Contact Us")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }