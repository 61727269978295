<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content content-finder">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home">{{ $t('PCD.text.home')}}</li>
          <li>{{ $t('PCD.text.products')}}</li>
          <li>{{ $t('PCD.text.hflor')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (hFlorFinder US) -->
      <div class="container prod-fd-view container_pf hflor-finder">
        <!-- visual -->
        <!-- start -->
        <div class="visual visual-detail">
          <div class="visual-image" v-if="pfBanImg" v-bind:style="{ backgroundImage: 'url( ' + $imagePath + pfBanImg + ')' }">
            <div class="txt-box">
              <h1 class="t-type-04"><span class="sr-only">HFLOR - </span> {{ subject }} </h1>
              <p class="t-type-05" v-if="brief != null" v-html="brief">
              </p>
              <button type="button" class="btn btn-more-point" @click="goLink()">
                <span>{{ $t('PCD.button.learnMore')}}</span>
              </button>
            </div>
            <div class="bgdim"></div>
          </div>
        </div>
        <!-- end -->

        <!-- <div class="visual">
         <div class="bg"><img :src="$imagePath + pfBanImg" alt="background" /><div class="bgdim"></div></div>

         <div class="text-box">
           <div class="t-type-04">{{ subject }}</div>

           <div class="t-type-05" v-if="brief != null" v-html="brief"></div>

           <button type="button" class="btn btn-more-point" @click="goLink()">
             <span>Learn more</span>
           </button>
         </div>
       </div> -->
        <!-- //visual -->

        <!-- product Finder -->
<!--        <div class="col-02 inspr-view fd-view">-->

        <!-- search 필터 선택 pc전용 -->
          <div class="drop-list inner">
            <div class="w-con-02">
              <div class="gallery">
                <div class="search-box">
                  <div class="filter_area">
                    <div class="filter_wrap">
                      <button type="button" class="btn_filter">
                        <span class="icon icon_filter"></span>
                        <span class="txt">Filter</span>
                        <span class="icon icon_arrow_down"></span>
                      </button>
                    </div>
                    <div class="compare_wrap">
                      <input type="checkbox" id="compare_btn" class="compare_btn" name="compare_button" value=""
                             v-model="compareOn" true-value="true" false-value="false">
                      <label for="compare_btn">
                        <span class="txt">ON</span>
                        <span class="btn"></span>
                      </label>
                      <span>Compare</span>
                    </div>
                    <div class="search">
                      <input type="text" id="textSearch" :placeholder="this.$t('PF.text.searchText')"
                             @input="textSearch($event)"/>
                      <button type="button" id="searchBtn" class="icon-search gray" @click="textSearchDel()">
                        {{ $t('PF.button.search') }}
                      </button>
                    </div>
                  </div>
                  <div class="filter_pop">
                    <div class="filter_top">
                      <ul v-if="!isMobile()">
                        <li>
                          <div class="tit">
                            <span class="tt-sub">{{ $t('PCD.text.hflor')}}</span>
                          </div>
                          <div class="i-box type01 cont" style="display:block;">
                            <ul>
                              <li>
                                <label class="inp-rdo" @click="clickCate('FLOOR_LUX');" >
                                  <input type="radio" name="rdo00" :checked="categoryCode === 'FLOOR_LUX' + nationCode">
                                  <span>{{ $t('PF.button.lux')}}</span>
                                </label>
                              </li>
                              <li>
                                <label class="inp-rdo" @click="clickCate('FLOOR_HOM');" >
                                  <input type="radio" name="rdo00" :checked="categoryCode === 'FLOOR_HOM' + nationCode">
                                  <span>{{ $t('PF.button.hom')}}</span>
                                </label>
                              </li>
                              <li>
                                <label class="inp-rdo" @click="clickCate('FLOOR_HET');" >
                                  <input type="radio" name="rdo00" :checked="categoryCode === 'FLOOR_HET' + nationCode">
                                  <span>{{ $t('PF.button.het')}}</span>
                                </label>
                              </li>
                              <li>
                                <label class="inp-rdo" @click="clickCate('FLOOR_SPT');" >
                                  <input type="radio" name="rdo00" :checked="categoryCode === 'FLOOR_SPT' + nationCode">
                                  <span>{{ $t('PF.button.spt')}}</span>
                                </label>
                              </li>
                              <!-- Collection -->
                              <li class="on">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PCD.text.collection')}}</span>
                                  <button type="button" class="btn-view btn-collection">{{ $t('PCD.text.collection')}}</button>
                                </div>

                                <div class="i-box type02 cont" style="display:block;">
                                  <ul>
                                    <li v-for="clt in leftCltList">
                                      <label class="inp-chk">
                                        <input type="checkbox" class="Collection" :id="clt.category.code" @change="[fnCateSearch(clt.category.name, clt.category.id, '',  clt.category.code)]" >
                                        <span>{{ clt.category.name }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- Collection -->

                              <!-- Patterns -->
                              <li class="on" v-if="leftPattns.length > 0">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PF.text.patterns')}}</span>
                                  <button type="button" class="btn-view btn-patterns">{{ $t('PF.text.patterns')}}</button>
                                </div>

                                <div class="i-box type02 cont" style="display:block;">
                                  <ul class="i-chk type02">
                                    <li v-for="pattn in leftPattns">
                                      <!-- start -->
                                      <!-- <label class="inp-chk chk-thumb">
                                        <input type="checkbox" id="" />
                                        <span> <i style="background-image: url('https://img.lxhausys.com/public/images/products/img_p_concrete.png')"></i>Wood </span>
                                      </label> -->
                                      <!-- end -->

                                      <label class="inp-chk chk-thumb">
                                        <input type="checkbox" class="pattern" :id="cateCode+'_pattern_'+ pattn.id.value" @change="[fnSearch('pattern', pattn.text, pattn.id.value, 'Pattern' ,cateCode)]">>
                                        <span><i :style="'background-image : url(images/products/'+ categoryCode + '_'+ pattn.id.value+'.jpg)'"/>{{ pattn.text }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- Patterns -->

                              <!-- New -->
                              <li class="on">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PF.text.new')}}</span>
                                  <button type="button" class="btn-view btn-new">{{ $t('PF.text.new')}}</button>
                                </div>

                                <div class="i-box type02 cont" style="display:block;">
                                  <ul>
                                    <li>
                                      <label class="inp-chk type03">
                                        <input type="checkbox" :id="cateCode+'_isNew_true'" @click="[fnSearch('isNew','NEW COLORS', 'true', 'New' ,cateCode) ]">
                                        <span>{{ $t('PF.text.new_colors')}}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- New -->

                              <!-- Product Type -->
                              <li class="on" v-if="leftPrdType.length != 0 && (cateCode === 'FLOOR_LUX' || cateCode === 'FLOOR_HET')">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PF.text.product_type')}}</span>
                                  <button type="button" class="btn-view btn-productType">{{ $t('PF.text.product_type')}}</button>
                                </div>

                                <div class="i-box type02 cont" v-if="categoryCode === 'FLOOR_HET_US' " style="display:block;"> <!-- 2022-01-27 황승빈부장님 요청 hetero 일 때 Commercial 만 보이도록 -->
                                  <ul class="i-chk">
                                    <li class="ProductType" v-for="prdType in leftPrdType" :data-chk="prdType.id.value">
                                      <label class="inp-chk" v-if="prdType.text === 'Commercial' || prdType.text === 'Heavy Commercial'">
                                        <input type="checkbox" class="prdType" :id="cateCode+'_prdType_'+ prdType.id.value" @click="[fnSearch('prdType',prdType.text, prdType.id.value, 'PrdType', cateCode)]">
                                        <span>{{ prdType.text }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                                <div class="i-box type02 cont" style="display:block;" v-else> <!-- himacsfinder.vue 참고해주세요 -->
                                  <ul class="i-chk">
                                    <li class="ProductType" v-for="prdType in leftPrdType" :data-chk="prdType.id.value">
                                      <label class="inp-chk">
                                        <input type="checkbox" class="prdType" :id="cateCode+'_prdType_'+ prdType.id.value" @click="[fnSearch('prdType',prdType.text, prdType.id.value, 'PrdType', cateCode)]">
                                        <span>{{ prdType.text }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- Product Type -->

                              <!-- Thickness -->
                              <li class="on">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PF.text.thickness')}}</span>
                                  <button type="button" class="btn-view btn-thickness">{{ $t('PF.text.thickness')}}</button>
                                </div>

                                <div class="i-box type02 cont" style="display:block;">
                                  <ul class="i-chk">
                                    <li class="Thickness" v-for="thick in leftThick" :data-chk="thick.id.value">
                                      <label class="inp-chk">
                                        <input type="checkbox" class="thick" :id="cateCode+'_thick_'+thick.id.value" @click="[fnSearch('thick',thick.text, thick.id.value,'Thick', cateCode)]">
                                        <span>{{ thick.text }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- Thickness -->

                              <!-- Wear Layer -->
                              <li class="on" v-if="leftWear.length != 0">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PF.text.wear_layer')}}</span>
                                  <button type="button" class="btn-view btn-wareLayer">{{ $t('PF.text.wear_layer')}}</button>
                                </div>

                                <div class="i-box type02 cont" style="display:block;">
                                  <ul class="i-chk">
                                    <li class="WearLayer" v-for="wear in leftWear" :data-chk="wear.id.value">
                                      <label class="inp-chk">
                                        <input type="checkbox" class="wearLayer" :id="cateCode+'_wearLayer_'+wear.id.value" @click="[fnSearch('wearLayer',wear.text, wear.id.value,'WearLayer', cateCode)]">
                                        <span>{{ wear.text }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- Ware Layer -->

                              <!-- Dimension -->
                              <li class="on">
                                <div class="tit">
                                  <span class="tt-sub">{{ $t('PF.text.dimension')}}</span>
                                  <button type="button" class="btn-view btn-dimension">{{ $t('PF.text.dimension')}}</button>
                                </div>

                                <div class="i-box type02 cont" style="display:block;">
                                  <ul class="i-chk">
                                    <li class="Dimension" v-for="dimen in leftDimen" :data-chk="dimen.id.value">
                                      <label class="inp-chk">
                                        <input type="checkbox" class="dimension" :id="cateCode+'_dimension_'+dimen.id.value" @click="[fnSearch('dimension',dimen.text, dimen.id.value,'Dimension', cateCode)]">
                                        <span>{{ dimen.text }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <!-- Dimension -->
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="filter_bottom">
                      <div class="btn_box">
                        <button class="btn_wh" @click="filterClearAll">Clear All</button>
                        <button class="btn_bk" @click="applyFilter">Apply</button>
                      </div>
                    </div>
                </div>
              <div class="sort_area">
                <div class="select-view" v-if="colorList.length > 0">
                  <ul class="list-filter-key">
                    <li class="sel" v-for="(color, index) in colorList"  @click="[colorDelete(color.subject, color.name, color.id, color.code)]">
                      <span v-if="color.subject == 'Collection'">{{color.subject}}</span>
                      <span v-else>{{color.type}}</span>
                      : <span> {{color.name}} </span>
                      <button type="button">&#10005;</button>
                    </li>
                    <li class="sel del_all"> <!-- 1개 이상일때 나타나게 해주세요-->
                      <button class="btn-all-delete" v-if="colorList.length > 0" @click="clearAll()">
                        <span>{{ $t('PF.button.clear_all')}} <em>&#10005;</em></span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="btns">
                  <ul>
                    <li class="sel-box">
                      <button type="button" class="btn-sel">
                        <span>Newest</span>
                      </button>
                      <div class="sel-list">
                        <ul class="box">
                          <li>
                            <button
                              type="button"
                              @click="sortByChange('Products A to Z', 'asc', 'title')
                            ">
                              {{ $t('PF.button.products_a_to_z') }}
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              @click="sortByChange('Products Z to A', 'desc', 'title')
                            ">
                              {{ $t('PF.button.products_z_to_a') }}
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              @click="sortByChange('New Products first', 'desc')"
                            >
                              {{ $t('PF.button.new_products_first') }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="gallery_layout_btns">
                  <button type="button" class="layout_grid on">
                    <span class="icon icon_grid"></span>
                  </button>
                  <button type="button" class="layout_list">
                    <span class="icon icon_list"></span>
                  </button>
                </div>
                <div class="more-box type02 type03 compare_box" v-if="compareOn">
                  <button type="button" class="button h55 btn_compare" id="compare" @click="getCompareList()">
                    <span class="txt">{{ $t('PF.button.compare_your_colors') }}</span>
                    <span class="bubble_num">
                    <span>{{ compareArr.length }}</span>
                  </span>
                  </button>
                </div>
              </div>
              <div class="count_area">
                <div class="filter-cnt">
                  <span class="num">{{ contentLength }}</span>
                  <span>&nbsp;</span>
                  <span class="txt">Results</span>
                </div>
              </div>
            </div>
          </div>
          <!-- //search 필터 선택 pc전용 -->
<!--            <div class="tab-ui tab-ui-dark">-->
<!--              <ul>-->
<!--                <li class="btn-tab on" id="btn-tab01" data-tab="tab01">-->
<!--                  <button type="button">{{ $t('PF.button.all_products')}} ({{ contentLength }})</button>-->
<!--                </li>-->
<!--                <li class="btn-tab" id="btn-tab02" data-tab="tab02">-->
<!--                  <button type="button">{{ $t('PF.button.grouped_by_collections')}} ({{ collectionLength }})</button>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->

            <!-- 모바일용 검색창 s -->
<!--            <div class="fd-view-mb search-area inner mb-only">-->
<!--              <div class="search">-->
<!--                <input id="textSearchM" type="text" :placeholder="this.$t('PF.text.searchText') " @input="textSearch($event)">-->
<!--                <i id="searchBtnM" class="icon-search gray" @click="textSearchDel()"></i>-->
<!--              </div>-->
<!--            </div>-->
            <!-- 모바일용 검색창 e -->
              <!-- 모바일용 filter by 버튼 s -->
              <div class="filter-arae mb-only">
                <button type="button" @click="openSearchPopup('layer-search-box')">
                  {{ $t('PF.button.filter_by')}}<i></i>
                </button>
              </div>
              <!-- All products -->
                <div class="list-type-view row-04">
                  <ul>
                    <li v-for="(item, idx) in contentList" :key="idx" class="viatera-pfd hflor-pfd">
                      <a href="javascript:void(0)" v-on:click="$utils.gotoUrl('products/hflor/'+ makeSeoMainClt(categoryCode) + '/' + $utils.getSeoName(item, 'content') +'/'+item.content.id)" tabindex="0"
                         :data-ec-product ="makeDataLayerForm(item)">
                        <div class="img-box over">
                          <span class="tag h30 black" v-if="item.attribute.isNew == 'true'"
                          >{{ $t('PF.text.new') }}</span>
                            <span class="tag h22 hflor">Hflor</span>
                            <span class="image_name">{{ item.content.title }}</span>
                            <img
                              :src="$imagePath + item.attribute.pf_thumb"
                              :alt="item.attribute.pf_thumb"
                            />
                        </div>
<!--                          <div class="img-box over">-->
<!--                            <span class="new" v-if="item.attribute.isNew === 'true' && item.parents[0].code != undefined && item.parents[0].code !== 'FLOOR_LUX_FRSTFSN_US'">{{ $t('PF.text.new')}}</span>-->
<!--                            <span class="new" v-if="item.attribute.isNew === 'true' && item.parents[0].code != undefined && item.parents[0].code == 'FLOOR_LUX_FRSTFSN_US'">Coming Soon</span>-->
<!--                            <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.content.title"/>-->
<!--                          </div>-->
                      </a>
                      <div class="view">
                        <a @click="openSampleOrder(item.content.id)" class="icon-cart-shadow" tabindex="0" v-if="nationCode === '_US'">{{ $t('PCD.text.cart')}}</a>
                        <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == true && nationCode == '_US'" v-bind:class="{ on: item.content.liked }" @click="toggleLike(item, 'HFLOR')">{{ $t('PCD.text.wish_list')}}</a>
                        <a class="icon-wish-shadow" tabindex="0" v-if="authenticated == false && nationCode == '_US'" href="javascript:void(0)" @click="alertAndGoLogin()" >{{ $t('PCD.text.wish_list')}}</a>
                        <a class="icon-wish-shadow" tabindex="0" v-if="getSessionContent != null && nationCode == '_GL'" v-bind:class="{ on: getSessionContent.includes(item.content.id) }" @click="toggleLike(item, 'HFLOR')">{{ $t('PCD.text.wish_list')}}</a>
                        <a class="icon-wish-shadow" tabindex="0" v-else-if="getSessionContent == null && nationCode == '_GL'" @click="toggleLike(item, 'HFLOR')">{{ $t('PCD.text.wish_list')}}</a>
                      </div>
                      <div class="icon_tag_wrap">
                        <i class="icon-no-fire"><span>no-fire</span></i>
                      </div>
                      <div class="pf_cont_compare_wrap">
                        <input type="checkbox" id="item.content.id">
                        <label class="inp-chk">
                          <input type="checkbox" class="pf_cont_compare" v-model="compareArr" :id="item.content.id"
                                 :value="item.content.id" @change="addCompare(item.content.id)">
                          <span>{{ $t('PF.button.compare') }}</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              <!-- //All products -->

            <!-- short cut -->
            <div class="short-link type02 mb-64 inner">
              <a v-if="nationCode === '_US'" href="javascript:void(0);" @click="openCart();"><span class="ico-01">{{ $t('PCD.text.order_a_sample')}}</span></a>
              <a v-if="nationCode === '_GL'" :href="'support/contactus'">
                <span class="ico-07">{{ $t('PCD.text.contact_us')}}</span></a>
              <a href="pros/documentation/hflor"><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a>
              <a href="support/care-and-maintenance/hflor"><span class="ico-03">{{ $t('PCD.text.care_maintenance')}}</span></a>
              <a v-if="nationCode === '_US'" href="support/warranty/hflor"><span class="ico-04">{{ $t('PCD.text.warranty')}}</span></a>
              <a v-if="nationCode === '_US'" :href="'support/where-to-buy/country/us'"><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a>
              <a v-if="nationCode === '_GL'" :href="'support/where-to-buy/country/gl'"><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a>
            </div>
            <!-- //short cut -->
<!--        </div>-->
        <!-- //product Finder -->

        <!-- popup : alert -->
        <div class="layer-popup layer-alert type02">
          <div class="popup pop-close">
            <div class="pop-alert inner">
              <div class="t-wrap">
                <div class="tit-box">
                  <p class="ctt ctt-t4 artTit">{{ $t('PF.popup.your_compare_is_full')}}</p>
                </div>
                <div class="txt-box">
                  <i class="ico-alert"></i>
                  <p class="tt-2">{{ $t('PF.popup.compare_tool')}}</p>
                  <div class="ctt ctt-t2">
                    {{ $t('PF.popup.compare_alert1')}}<br/>
                    {{ $t('PF.popup.compare_alert2')}}
                  </div>
                </div>
              </div>
              <div class="more-box">
                <button type="button" id="replace" class="btn" @click="replace()"><span>{{ $t('PF.popup.replace')}}</span></button>
              </div>
              <div class="alert_txt">
                <button id="button editCompare" class="" @click="getCompareList()"><span>{{ $t('PF.popup.edit_products_in_compare')}}</span></button>
              </div>
              <button type="button" class="btn-close btn" onclick="LayerPopup.close();">{{ $t('PF.popup.replace')}}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : alert -->

        <!-- popup : Compare -->
        <div class="layer-popup layer-compare euSite" id="pdf_wrap">
          <div class="popup pop-close">
            <div class="pop-compare scr-y">
              <p class="tt-sub-gray">{{ $t('PF.popup.compare_hflor_products')}}</p>
              <p class="ta-c">{{ $t('PF.popup.characteristics_technical_performance')}}</p>

              <div class="prod-list tbl-compare">
                <table>
                  <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                  <colgroup>
                    <col style="width:auto">
                    <col style="width:auto">
                    <col style="width:auto">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                  <tr>
                    <td v-for="(item,idx) in compareList">
                      <div class="prod-box">
                        <div class="prod">
                          <div class="img-box over">
                            <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.content.title">
                          </div>
                        </div>
                        <div class="popinfo">
                          <ul>
                            <li class="tt-sub">{{ item.content.title }}</li>
                            <li class="ctt ctt-t4" v-if="item.attribute.disp_code != undefined">{{ item.attribute.disp_code }}</li>
                            <li class="ctt ctt-t4" v-else>{{ item.attribute.code }}</li>
                            <li class="txt-01">{{item.parents[0].name}}</li>
                            <li class="txt-01">{{item.parents[1].name}}</li>
                          </ul>
                          <button type="button" class="btn-remove02" @click="removeCompare(item.content.id)"><span>{{ $t('PF.button.remove')}}</span></button>
                        </div>
                      </div>

                      <div class="tbl-box type02">
                        <table>
                          <caption>{{ $t('PF.popup.characteristics_technical_performance')}}</caption>
                          <colgroup>
                            <col style="width:auto">
                          </colgroup>
                          <tbody>
                          <tr>
                            <th scope="col">{{ $t('PF.text.product_type')}}</th>
                          </tr>
                          <tr v-if="item.attribute.prdTypeText != undefined">
                            <td>{{ item.attribute.prdTypeText.join(', ') }}</td>
                          </tr>
                          <td v-else>{{ }}</td>
                          <tr>
                            <th scope="col">{{ $t('PCD.text.collection')}}</th>
                          </tr>
                          <tr>
                            <td>{{item.parents[0].name}}</td>
                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.total_thickness')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.thickWearLayer != undefined">
                              {{ getThick(item.attribute.thickWearLayer).join(', ') }}
                            </td>
                            <td v-else>{{ item.attribute.thickText.join(', ') }}</td>

                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.wear_layer')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.thickWearLayer != undefined">
                              {{ getWearLayer(item.attribute.thickWearLayer).join(', ') }}</td>
                            <td v-else>{{ item.attribute.wearLayerText }}</td>
                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.pattern')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.patternText != null">{{ item.attribute.patternText.join(', ') }}</td>
                            <td v-if="item.attribute.patternText == null">{{  }}</td>
                          </tr>
                          <tr>
                            <th scope="col">{{ $t('PD.text.dimension')}}</th>
                          </tr>
                          <tr>
                            <td v-if="item.attribute.dimensionText != null">{{ item.attribute.dimensionText.join(', ') }}</td>
                            <td v-if="item.attribute.dimensionText == null">{{  }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                    <td v-if="compareList.length < 4">
                      <div class="btn-add hf-btn-add">
                        <button type="button" onclick="LayerPopup.close();"><span>{{ $t('PF.button.add_more')}}</span></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="btn-download">
                <button type="button" class="btn dwn load" @click="makePDF()">
                  <span>{{ $t('PF.button.download')}}</span>
                </button>
              </div>
              <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t('PCD.button.close')}}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : Compare -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb" style="display: none">
          <div class="popup">
            <div class="scr-y">
              <div class="drop-list">
                <p class="tt-2">{{ $t('PF.button.filter_by')}}</p>
                <button
                  class="filter-clos"
                  @click="closeSearchPopup()"
                ></button>
                <ul>
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PCD.text.hflor')}}</span>
                      <button type="button" >{{ $t('PD.button.list_view')}}</button>
                    </div>
                    <div class="i-box type01 cont" style="display:block;">
                      <ul>
                        <li>
                          <label class="inp-rdo" @click="clickCate('FLOOR_LUX');" >
                            <input type="radio" name="rdo01" :checked="categoryCode === 'FLOOR_LUX' + nationCode">
                            <span>{{ $t('PF.button.lux')}}</span>
                          </label>
                        </li>
                        <li>
                          <label class="inp-rdo" @click="clickCate('FLOOR_HOM');" >
                            <input type="radio" name="rdo01" :checked="categoryCode === 'FLOOR_HOM' + nationCode">
                            <span>{{ $t('PF.button.hom')}}</span>
                          </label>
                        </li>
                        <li>
                          <label class="inp-rdo" @click="clickCate('FLOOR_HET');" >
                            <input type="radio" name="rdo01" :checked="categoryCode === 'FLOOR_HET' + nationCode">
                            <span>{{ $t('PF.button.het')}}</span>
                          </label>
                        </li>
                        <li>
                          <label class="inp-rdo" @click="clickCate('FLOOR_SPT');" >
                            <input type="radio" name="rdo01" :checked="categoryCode === 'FLOOR_SPT' + nationCode">
                            <span>{{ $t('PF.button.spt')}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <!-- Collection -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PCD.text.collection')}}</span>
                      <button type="button" class="btn-view btn-collection">{{ $t('PCD.text.collection')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul>
                        <!--                      <li>-->
                        <!--                        <label class="inp-chk">-->
                        <!--                          <input type="checkbox" checked id="CateAll" @click="[cateSearch('CateAll','CateAll')]">-->
                        <!--                          <span>All</span>-->
                        <!--                        </label>-->
                        <!--                      </li>-->
                        <li v-for="clt in leftCltList">
                          <label class="inp-chk">
                            <input type="checkbox" class="Collection" :id="clt.category.code" @change="[fnCateSearch(clt.category.name, clt.category.id, '',  clt.category.code)]" >
                            <span>{{ clt.category.name }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Collection -->

                  <!-- Patterns -->
                  <li class="on" v-if="leftPattns.length > 0">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.patterns')}}</span>
                      <button type="button" class="btn-view btn-patterns">{{ $t('PF.text.patterns')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk type02">
                        <li v-for="pattn in leftPattns">
                          <!-- start -->
                          <!-- <label class="inp-chk chk-thumb">
                            <input type="checkbox" id="" />
                            <span> <i style="background-image: url('https://img.lxhausys.com/public/images/products/img_p_concrete.png')"></i>Wood </span>
                          </label> -->
                          <!-- end -->

                          <label class="inp-chk chk-thumb">
                            <input type="checkbox" class="pattern" :id="cateCode+'_pattern_'+ pattn.id.value" @change="[fnSearch('pattern', pattn.text, pattn.id.value, 'Pattern' ,cateCode)]">>
                            <span><i :style="'background-image : url(images/products/'+ categoryCode + '_'+ pattn.id.value+'.jpg)'"/>{{ pattn.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Patterns -->

                  <!-- New -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.new')}}</span>
                      <button type="button" class="btn-view btn-new">{{ $t('PF.text.new')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul>
                        <li>
                          <label class="inp-chk type03">
                            <input type="checkbox" :id="cateCode+'_isNew_true'" @click="[fnSearch('isNew','NEW COLORS', 'true', 'New' ,cateCode) ]">
                            <span>{{ $t('PF.text.new_colors')}}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- New -->

                  <!-- Product Type -->
                  <li class="on" v-if="leftPrdType.length != 0 && (cateCode === 'FLOOR_LUX' || cateCode === 'FLOOR_HET')">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.product_type')}}</span>
                      <button type="button" class="btn-view btn-productType">{{ $t('PF.text.product_type')}}</button>
                    </div>

                    <div class="i-box type02 cont" v-if="categoryCode === 'FLOOR_HET_US' " style="display:block;"> <!-- 2022-01-27 황승빈부장님 요청 hetero 일 때 Commercial 만 보이도록 -->
                      <ul class="i-chk">
                        <li class="ProductType" v-for="prdType in leftPrdType" :data-chk="prdType.id.value">
                          <label class="inp-chk" v-if="prdType.text === 'Commercial' || prdType.text === 'Heavy Commercial'">
                            <input type="checkbox" class="prdType" :id="cateCode+'_prdType_'+ prdType.id.value" @click="[fnSearch('prdType',prdType.text, prdType.id.value, 'PrdType', cateCode)]">
                            <span>{{ prdType.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div class="i-box type02 cont" style="display:block;" v-else> <!-- himacsfinder.vue 참고해주세요 -->
                      <ul class="i-chk">
                        <li class="ProductType" v-for="prdType in leftPrdType" :data-chk="prdType.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="prdType" :id="cateCode+'_prdType_'+ prdType.id.value" @click="[fnSearch('prdType',prdType.text, prdType.id.value, 'PrdType', cateCode)]">
                            <span>{{ prdType.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Product Type -->

                  <!-- Thickness -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.thickness')}}</span>
                      <button type="button" class="btn-view btn-thickness">{{ $t('PF.text.thickness')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="Thickness" v-for="thick in leftThick" :data-chk="thick.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="thick" :id="cateCode+'_thick_'+thick.id.value" @click="[fnSearch('thick',thick.text, thick.id.value,'Thick', cateCode)]">
                            <span>{{ thick.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Thickness -->

                  <!-- Wear Layer -->
                  <li class="on" v-if="leftWear.length != 0">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.wear_layer')}}</span>
                      <button type="button" class="btn-view btn-wareLayer">{{ $t('PF.text.wear_layer')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="WearLayer" v-for="wear in leftWear" :data-chk="wear.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="wearLayer" :id="cateCode+'_wearLayer_'+wear.id.value" @click="[fnSearch('wearLayer',wear.text, wear.id.value,'WearLayer', cateCode)]">
                            <span>{{ wear.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Ware Layer -->

                  <!-- Dimension -->
                  <li class="on">
                    <div class="tit">
                      <span class="tt-sub">{{ $t('PF.text.dimension')}}</span>
                      <button type="button" class="btn-view btn-dimension">{{ $t('PF.text.dimension')}}</button>
                    </div>

                    <div class="i-box type02 cont" style="display:block;">
                      <ul class="i-chk">
                        <li class="Dimension" v-for="dimen in leftDimen" :data-chk="dimen.id.value">
                          <label class="inp-chk">
                            <input type="checkbox" class="dimension" :id="cateCode+'_dimension_'+dimen.id.value" @click="[fnSearch('dimension',dimen.text, dimen.id.value,'Dimension', cateCode)]">
                            <span>{{ dimen.text }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- Dimension -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
        <!-- //search filter popup -->
        </div>
      </div>
      <!-- //container (hFlorFinder US) -->
    </div>
    <!-- //content -->

    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import { $utils } from '@/common/helper'
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import { getCategoryAttributeList } from '@/server/category'
import ProductService from '@/server/product.service'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { mapState } from 'vuex'

export default {
  name: 'hFlorFinder_USGL',
  props: ['PfContentId', 'PfCateCode', 'filterNm', 'filterVal',
    'filterNm1', 'filterVal1', 'filterNm2', 'filterVal2', 'filterSeo', 'filterSeo1', 'filterNm3', 'filterVal3'],
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  watch: {
    compareArr: function () {
      sessionStorage.setItem('compare' + this.nationCode, JSON.stringify(this.compareArr))
    }
  },
  data () {
    return {
      categoryCode: [], /* 카테고리 코드 */
      categoryName: [], /* 카테고리 이름 */
      categoryId: '', /* 카테고리 아이디 */
      categoryUsId: [], /* 해당 카테고리 ID 값 가져오기. */
      categoryUsName: [],
      nationCode: '', /* 국가 코드 */

      subject: '', /* 제목 */
      brief: '', /* 설명 */
      pfBanImg: '', /* 제목 */

      collectionList: [], /* 컬렉션 리스트 */
      collectionLength: '', /* 해당 컬렉션 길이 체크 (그룹 바이 컬렉션에 박을꺼임) */
      checkCollectionList: [],
      colorList: [],
      contentList: [],
      contentLength: '', /* 해당 컨텐츠 길이 체크 ( All Product에 박을꺼임) */
      value: [],
      pageSize: 16,
      pageIndex: 1,
      orderField: [],
      orderType: [],

      /* left menu */
      searchText: '', /* 검색어 */
      leftCltList: [], /* 컬렉션 리스트 */
      leftColor: [], /* 컬러 리스트 */
      leftPattns: [], /* 패턴 리스트 */
      leftPrdType: [], /* 상품타입 리스트 */
      leftThick: [], /* 두께 리스트 */
      leftWear: [], /* 겹 리스트 */
      leftDimen: [], /* 치수 리스트 */
      scrollSize: 4,
      /* left menu */

      getSessionContent: [], /* GL : 불러오는 값 */

      clickCollIds: [],
      filterCollList: [],
      sortBy: 'Sort by',
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          },
          {
            operator: 'OR',
            values: [
            ]
          }
        ]
      },

      /* compare */
      compareArr: [],
      compareRes: { list: [] },
      compareDetail: [],
      compareList: [],
      compareId: '',
      cateCode: '',
      replaceId: '',
      compareOn: false, /* compare 토글버튼 on 일때 */

      filterShow: false,
      historyBack: false,
      titleSeo: ''

    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */

    // if(t.$route.query.compare != null){
    //   t.compareArr = JSON.parse(t.$route.query.compare);
    // }

    if (sessionStorage.getItem('compare' + this.nationCode) != null) {
      t.compareArr = JSON.parse(sessionStorage.getItem('compare' + this.nationCode))
    }

    if (t.nationCode === '_GL') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },
  async mounted () {
    const _this = this

    _this.nationCode = _this.$countryCode
    _this.cateCode = _this.PfCateCode
    _this.categoryCode = _this.PfCateCode + _this.nationCode

    /* 해당하는 배너 정보를 가져오자! */
    _this.banCode = 'BAN_FLOORING_' + _this.cateCode.replace('FLOOR_', '') + '_CAT' + _this.nationCode
    const banCateRes = await ProductService.getCategoryAttr(_this.banCode)
    _this.brief = banCateRes.data.attribute.brief
    _this.subject = banCateRes.data.attribute.subject
    _this.pfBanImg = banCateRes.data.attribute.pf_image

    const categoryRes = await ProductService.getCategoryCode(_this.categoryCode)

    _this.categoryId = categoryRes.data.object.id
    _this.categoryName = categoryRes.data.object.name

    const getCateClt = await getCategoryAttributeList(_this.categoryId)

    _this.leftCltList = getCateClt.data.list

    /* Finder 시작 */
    if (_this.categoryCode.includes('LUX')) {
      const dicList = await ProductService.getDictionaryValueList(
        ['FLRNG_ClrTone', 'HFLOR_PATTERN_LUX' + _this.nationCode, 'FLRNG_PRDTYPE',
          'HFLOR_THICK_LUX' + _this.nationCode, 'HFLOR_WARE_LUX' + _this.nationCode, 'HFLOR_DIMEN_LUX' + _this.nationCode])
      _this.leftColor = dicList.data.dicList[0].list /* FLRNG_ClrTone DictionaryValue */
      _this.leftPattns = dicList.data.dicList[1].list /* FLRNG_PATTERN DictionaryValue */
      _this.leftPrdType = _.filter(dicList.data.dicList[2].list, function (o) {
        return o.id.value.split('_')[0] === 'lux'
      }) /* FLRNG_PRDTYPE DictionaryValue */
      _this.leftThick = dicList.data.dicList[3].list /* FLRNG_THICKNESS DictionaryValue */
      _this.leftWear = dicList.data.dicList[4].list /* FLRNG_WEAR DictionaryValue */
      _this.leftDimen = dicList.data.dicList[5].list /* FLRNG_DIMENSION DictionaryValue */
    } else if (_this.categoryCode.includes('HOM')) {
      const dicList = await ProductService.getDictionaryValueList(
        ['FLRNG_ClrTone', 'FLRNG_PRDTYPE', 'HFLOR_THICK_HOM' + _this.nationCode, 'HFLOR_DIMEN_HOM' + _this.nationCode])
      _this.leftColor = dicList.data.dicList[0].list /* FLRNG_ClrTone DictionaryValue */
      _this.leftPrdType = _.filter(dicList.data.dicList[1].list, function (o) {
        return o.id.value.split('_')[0] === 'hom'
      }) /* FLRNG_PRDTYPE DictionaryValue */
      _this.leftThick = dicList.data.dicList[2].list /* FLRNG_THICKNESS DictionaryValue */
      _this.leftDimen = dicList.data.dicList[3].list /* FLRNG_DIMENSION DictionaryValue */
    } else if (_this.categoryCode.includes('HET')) {
      const dicList = await ProductService.getDictionaryValueList(
        ['FLRNG_ClrTone', 'HFLOR_PATTERN_HET' + _this.nationCode, 'FLRNG_PRDTYPE', 'HFLOR_THICK_HET' + _this.nationCode,
          'HFLOR_WARE_HET' + _this.nationCode, 'HFLOR_DIMEN_HET' + _this.nationCode])
      _this.leftColor = dicList.data.dicList[0].list /* FLRNG_ClrTone DictionaryValue */
      _this.leftPattns = dicList.data.dicList[1].list /* FLRNG_PATTERN DictionaryValue */
      _this.leftPrdType = _.filter(dicList.data.dicList[2].list, function (o) {
        return o.id.value.split('_')[0] === 'het'
      }) /* FLRNG_PRDTYPE DictionaryValue */
      _this.leftThick = dicList.data.dicList[3].list /* FLRNG_THICKNESS DictionaryValue */
      _this.leftWear = dicList.data.dicList[4].list /* FLRNG_WEAR DictionaryValue */
      _this.leftDimen = dicList.data.dicList[5].list /* FLRNG_DIMENSION DictionaryValue */
    } else if (_this.categoryCode.includes('SPT')) {
      const dicList = await ProductService.getDictionaryValueList(
        ['FLRNG_ClrTone', 'HFLOR_PATTERN_SPT' + _this.nationCode, 'FLRNG_PRDTYPE', 'HFLOR_THICK_SPT' + _this.nationCode,
          'HFLOR_WARE_SPT' + _this.nationCode, 'HFLOR_DIMEN_SPT' + _this.nationCode])
      _this.leftColor = dicList.data.dicList[0].list /* FLRNG_ClrTone DictionaryValue */
      _this.leftPattns = dicList.data.dicList[1].list /* FLRNG_PATTERN DictionaryValue */
      _this.leftPrdType = _.filter(dicList.data.dicList[2].list, function (o) {
        return o.id.value.split('_')[0] === 'spt'
      }) /* FLRNG_PRDTYPE DictionaryValue */
      _this.leftThick = dicList.data.dicList[3].list /* FLRNG_THICKNESS DictionaryValue */
      _this.leftWear = dicList.data.dicList[4].list /* FLRNG_WEAR DictionaryValue */
      _this.leftDimen = dicList.data.dicList[5].list /* FLRNG_DIMENSION DictionaryValue */
    }

    _this.categoryUsId.push(_this.categoryId)
    const collectionList = await ProductService.getCategoryAttributeList(categoryRes.data.object.id)

    _this.collectionList = collectionList.data.list
    _this.checkCollectionList = collectionList.data.list
    _this.collectionLength = collectionList.data.list.length

    // URL 조건 1개 param
    const filterNm = this.filterNm
    const filterVal = this.filterVal

    // URL 조건 2개 param
    const filterNm1 = this.filterNm1
    const filterVal1 = this.filterVal1
    const filterNm2 = this.filterNm2
    const filterVal2 = this.filterVal2
    const filterNm3 = this.filterNm3
    const filterVal3 = this.filterVal3

    // title seo name
    if (this.filterSeo !== undefined && this.filterSeo !== '') {
      this.titleSeo = this.filterSeo
    } else if (this.filterSeo === undefined && this.filterSeo1 !== undefined) {
      this.titleSeo = this.filterSeo1
    } else if (filterNm === 'isNew') {
      this.titleSeo = 'New Colors - ' + this.makeSeoMainClt(this.categoryCode)
    } else {
      this.titleSeo = this.makeSeoMainClt(this.categoryCode)
    }

    if (filterNm !== undefined || filterNm1 !== undefined) {
      const hflorFilter = JSON.parse(sessionStorage.getItem('hflorFilter' + this.nationCode))
      if (hflorFilter === undefined || hflorFilter === null) {
        // URL 조건 1개 일 경우
        if (filterNm !== undefined) {
          this.findFilter(filterNm, filterVal)

          // URL 조건 2개 일 경우
        } else if (filterNm1 !== undefined) {
          console.log(filterNm3, filterVal3)
          if (filterNm3 !== undefined) {
            this.findFilter(filterNm1, filterVal1)
            this.findFilter(filterNm2, filterVal2)
            this.findFilter(filterNm3, filterVal3)
          } else {
            this.findFilter(filterNm1, filterVal1)
            this.findFilter(filterNm2, filterVal2)
            console.log(filterNm2, filterVal2)
          }
          // URL 조건 3개 일 경우
        }
      } else {
        if (hflorFilter.length === 0) {
          await _this.getItem(1, 16)
        } else {
          this.bringFilter()
          await _this.getItem(1, 16)
        }
      }
    } else {
      // filter 조건이 없는 경우
      this.bringFilter()
      // 조건 없을 경우
      await _this.getItem(1, 16)
    }

    this.$nextTick(function () {
      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      $('.slide-pd01 .slick-container').slick({
        setPosition: 0, // 깨짐방지
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        draggable: false,
        // centerPadding: '35px',
        prevArrow: $('.slide-pd01 .arrow-prev'),
        nextArrow: $('.slide-pd01 .arrow-next'),
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true
            }
          }
        ]
      })

      $(document).on('click', '.select-view .btn-del', function () {
        if ($('.select-view > ul > li').length === 1) {
          $('.select-view > ul').remove()
          $('.btn-all').remove()
        } else {
          $(this).closest('.sel').remove()
        }
      })
      $(document).on('click', '.btn-all button', function () {
        $('.select-view > ul').remove()
        $('.btn-all').remove()
      })
      // 필터버튼 팝업
      $('.btn_filter').click(function () {
        $('.filter_pop').toggleClass('on')
      })

      // 필터 4줄 / 3줄 노출버튼
      $('.layout_grid').click(function () {
        $(this).addClass('on')
        $('.layout_list').removeClass('on')

        $('.list-type-view').removeClass('row-03')
        $('.list-type-view').addClass('row-04')
      })
      $('.layout_list').click(function () {
        $(this).addClass('on')
        $('.layout_grid').removeClass('on')

        $('.list-type-view').removeClass('row-04')
        $('.list-type-view').addClass('row-03')
      })
    })
  },
  methods: {
    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },
    findFilter: function (filterNm, filterVal) {
      const _this = this

      if (filterNm === 'collection') {
        $('#' + filterVal).click()
      } else if (filterNm === 'isNew') {
        $('#' + _this.cateCode + '_isNew_true').click()
      } else { /* pattern, prdType, thick, wearLayer, dimension */
        $('#' + _this.cateCode + '_' + filterNm + '_' + filterVal).click()
      }
    },
    bringFilter: function () {
      const _this = this
      // 뒤로가기 세션
      const hflorFilter = JSON.parse(sessionStorage.getItem('hflorFilter' + this.nationCode))
      if (hflorFilter !== undefined && hflorFilter != null) {
        for (let i = 0; i < hflorFilter.length; i++) {
          if (hflorFilter[i].subject === 'Collection') {
            $('#' + hflorFilter[i].code).click()
          } else {
            $('#' + _this.cateCode + '_' + hflorFilter[i].subject + '_' + hflorFilter[i].id).click()
          }
        }
      }
    },
    fnSearch: async function (searchCd, selectSeach, value, type, cateType) {
      const _this = this
      _this.pageIndex = 1
      // if(selectSeach == searchCd+'All'){
      //   if($('#' + selectSearch).is(":checked")){
      //     $("."+searchCd).prop("checked", false);
      //     _.remove(_this.value, function(c) {
      //       let t = c.split('_')
      //       return t[0] == searchCd
      //     })
      //     _.remove(_this.colorList, function(v) {
      //       return v.subject == searchCd
      //     })
      //   }
      // } else {
      //   let checkBoxTotal = $('input:checkbox[class='+searchCd+']').length;
      //   let checkedCount = $('input:checkbox[class='+searchCd+']:checked').length;
      //   if(checkBoxTotal == checkedCount){
      //     _.remove(_this.dictionaryCondition.list, function(c) {
      //       _.remove(c.values, function(v) {
      //         return v.id == searchCd
      //       })
      //     })
      //     _.remove(_this.colorList, function(v) {
      //       return v.subject == searchCd
      //     })
      //     $("."+searchCd).prop("checked", false);
      //     $("#"+searchCd+"All").prop('checked', true);
      //   } else if (checkedCount > 0){
      //     $("#"+searchCd+"All").prop('checked', false);
      //   } else {
      //     $("#"+searchCd+"All").prop('checked', true);
      //   }
      if ($('#' + cateType + '_' + searchCd + '_' + value).is(':checked')) {
        if (searchCd === 'hue') {
          _this.dictionaryCondition.list[0].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'pattern') {
          _this.dictionaryCondition.list[1].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'isNew') {
          _this.dictionaryCondition.list[2].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'prdType') {
          _this.dictionaryCondition.list[3].values.push({ id: 'find_' + searchCd, value: value })
        } else if (searchCd === 'thick') {
          _this.dictionaryCondition.list[4].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'wearLayer') {
          _this.dictionaryCondition.list[5].values.push({ id: searchCd, value: value })
        } else if (searchCd === 'dimension') {
          _this.dictionaryCondition.list[6].values.push({ id: searchCd, value: value })
        }
        _this.colorList.push({ subject: searchCd, name: selectSeach, id: value, type: type })
      } else {
        _.remove(_this.colorList, function (v) {
          return v.subject === searchCd && v.id === value
        })
        _.remove(_this.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            if (searchCd === 'prdType') {
              return v.value === value && v.id === 'find_' + searchCd
            } else {
              return v.value === value && v.id === searchCd
            }
          })
        })
      }
      // }
      await _this.getItem(1, 16)
    },

    textSearch (event) {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/delete.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/delete.png) center center' })
      }
      this.searchText = event.target.value
      this.getItem(1, 16)
    },

    textSearchDel () {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/ico_search.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/ico_search.png) center center ' })
      }
      this.searchText = ''
      if (this.isMobile()) {
        $('#textSearchM').val('')
      } else {
        $('#textSearch').val('')
      }
      this.getItem(1, 16)
    },

    fnCateSearch: async function (name, id, type, code) {
      const t = this
      t.pageIndex = 1
      if (name == 'CateAll') {
        if ($('#CateAll').is(':checked')) {
          $('.Collection').prop('checked', false)
        }
        t.categoryUsId = []
        _.remove(t.colorList, function (c) {
          return c.subject === 'Collection'
        })
        t.categoryUsId.push(t.categoryId)
        t.filterCollList = []
        t.clickCollIds = []
        t.checkCollectionList = t.collectionList
        t.collectionLength = t.checkCollectionList.length
      } else {
        if (type == 'click' && $('#' + code).is(':checked') == false) {
          $('#' + code).prop('checked', true)
        } else if (type == 'click' && $('#' + code).is(':checked') == true) {
          $('#' + code).prop('checked', false)
        }
        if ($('#' + code).is(':checked')) {
          // let checkBoxTotal = $('input:checkbox[class=Collection]').length;
          // let checkedCount = $('input:checkbox[class=Collection]:checked').length;
          // if(checkBoxTotal == checkedCount && checkBoxTotal > 1){
          //   _.remove(t.colorList, function(v) {
          //     return v.subject == 'Collection';
          //   })
          //   t.categoryUsId = [];
          //   t.categoryUsId.push(t.categoryId);
          //   $("#CateAll").prop("checked", true);
          //   $(".Collection").prop("checked", false);
          // } else if (checkedCount > 0){
          //   $("#CateAll").prop("checked", false);
          if (t.categoryUsId[0] == t.categoryId) {
            t.categoryUsId = []
          }
          t.categoryUsId.push(id)
          t.categoryUsName.push(name)
          t.colorList.push({ subject: 'Collection', name: name, id: id, code: code })
          // 필터링된 id
          this.clickCollIds.push(id)
          t.filtering = _.filter(t.collectionList, function (o, idx) {
            return o.category.id === id
          })
          // 첫검색할땐 필터링된 배열 리셋
          if (this.clickCollIds.length === 1) {
            t.filterCollList = []
            t.filterCollList.push(t.filtering[0])
          } else {
            t.filterCollList.push(t.filtering[0])
          }
          t.checkCollectionList = t.filterCollList
          t.collectionLength = t.checkCollectionList.length
          // } else {
          //   $("#CateAll").prop("checked", true);
          // }
        } else {
          const checkedCount = $('input:checkbox[class=Collection]:checked').length
          if (checkedCount == 0) {
            $('#CateAll').prop('checked', true)
          }
          _.remove(t.categoryUsId, function (c) {
            return c == id
          })
          _.remove(t.colorList, function (v) {
            return v.subject == 'Collection' && v.id == id
          })

          t.clickCollIds = _.filter(t.clickCollIds, function (o) {
            return o !== id
          })
          if (t.clickCollIds.length === 0) {
            t.checkCollectionList = t.collectionList
          } else {
            // 필터링 된 collectionList에서 선택한 collection 값 삭제
            t.checkCollectionList = _.filter(t.checkCollectionList, function (o) {
              return o.category.id !== id
            })
          }
          t.collectionLength = t.checkCollectionList.length
        }
      }
      if ($('#btn-tab01').attr('class') != 'btn-tab on') {
        $('#btn-tab01').click()
      }
      await t.getItem(1, 16)
    },
    colorDelete: async function (subject, name, id, code) {
      const t = this

      _.remove(t.colorList, function (v) {
        return v.subject == subject && v.id == id
      })
      if (subject === 'Collection') {
        $('#' + code).prop('checked', false)
        const checkedCount = $('input:checkbox[class=Collection]:checked').length
        if (checkedCount == 0) {
          $('#CateAll').prop('checked', true)
        }
        _.remove(t.categoryUsId, function (c) {
          return c == id
        })

        t.clickCollIds = _.filter(t.clickCollIds, function (o) {
          return o !== id
        })
        if (t.clickCollIds.length === 0) {
          t.checkCollectionList = t.collectionList
        } else {
          // 필터링 된 collectionList에서 선택한 collection 값 삭제
          t.checkCollectionList = _.filter(t.checkCollectionList, function (o) {
            return o.category.id !== id
          })
        }
        t.collectionLength = t.checkCollectionList.length
      } else {
        $('#' + t.cateCode + '_' + subject + '_' + id).prop('checked', false)
        const checkedCount = $('input:checkbox[class=' + subject + ']:checked').length
        if (checkedCount == 0) {
          $('#' + subject + 'All').prop('checked', true)
        }
        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == id
          })
        })
      }
      await t.getItem(1, 16)
    },
    applyFilter () {
    },
    async filterClearAll () {
    },
    async getItem (pageIndex, pageSize) {
      const t = this

      if (t.colorList !== undefined) {
        sessionStorage.setItem('hflorFilter' + this.nationCode, JSON.stringify(t.colorList))
      }

      if (t.categoryUsId == '') {
        t.categoryUsId.push(t.categoryId)
      }
      t.pageIndex = 1
      t.pageSize = pageSize

      const res = await ProductService.getDictionaryCondition(t.categoryUsId, t.dictionaryCondition, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType, ['title', 'search_text'])
      t.contentList = res.data.list
      t.contentLength = res.data.totalCount
    },
    async clearAll () {
      const _this = this
      _this.categoryUsId = []
      _this.filterCollList = []
      _this.colorList = []
      _this.clickCollIds = []
      _this.dictionaryCondition.list[0].values = []
      _this.dictionaryCondition.list[1].values = []
      _this.dictionaryCondition.list[2].values = []
      _this.dictionaryCondition.list[3].values = []
      _this.dictionaryCondition.list[4].values = []
      _this.dictionaryCondition.list[5].values = []
      _this.dictionaryCondition.list[6].values = []
      _this.categoryUsId = []
      _this.categoryUsId.push(_this.categoryId)
      _this.checkCollectionList = _this.collectionList
      _this.collectionLength = _this.checkCollectionList.length
      $('input:checkbox').prop('checked', false)
      $("input:checkbox[id*='All']").prop('checked', true)
      await _this.getItem(1, 16)
    },
    async sortByChange (text, type, field) {
      const _this = this
      _this.sortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.getItem(1, 16)
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    async loadMore (index) {
      const t = this

      t.pageIndex += 1
      t.pageSize = 16

      const res = await ProductService.getDictionaryCondition(t.categoryUsId, t.dictionaryCondition, t.searchText, t.pageIndex, t.pageSize, t.orderField, t.orderType, ['title', 'search_text'])
      for (let i = 0; i < res.data.list.length; i++) {
        t.contentList.push(res.data.list[i])
      }
      t.contentLength = res.data.totalCount
    },
    goLink (type, url) {
      if (type === undefined) {
        location.href = 'products/hflor/' + this.makeSeoMainClt(this.PfCateCode)
      } else if (type === 'book') {
        window.open(url)
      }
    },
    clickCate (code) {
      // this.$router.push({path: '/hflor/hFlorFinder/'+ code, query: { compare: JSON.stringify(this.compareArr)}}).catch(()=>{});
      this.$router.push({ path: '/hflor/hflor-finder/' + this.makeSeoMainClt(code) }).catch(() => {})
    },
    async makePDF () {
      html2canvas($('#pdf_wrap')[0], {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight
      }).then(function (canvas) {
        var filename = 'HFLOR_COMPARE_' + Date.now() + '.pdf'
        var doc = new jsPDF('p', 'mm', 'a4')
        var imgData = canvas.toDataURL('image/png')
        var imgWidth = 210
        var pageHeight = 295
        var imgHeight = canvas.height * imgWidth / canvas.width
        var heightLeft = imgHeight
        var position = 0
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
        window.scrollTo(0, 0)
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(filename)
      })
      const _this = this
      for (var i = 0; i < _this.compareList.length; i++) {
        if (typeof (dataLayer) !== 'undefined') {
          let prdVariant = ''
          if ((_this.compareList[i].attribute.price != null || undefined) && _this.compareList[i].attribute.price.length < 10) {
            prdVariant = _.join(_.flatMap(_this.compareList[i].attribute.price, function (v) {
              return v.size || ''
            }), '|')
          }
          dataLayer.push({
            event: 'product_compare',
            event_type: 'Download Comp',
            ecom_prd_id: _this.compareList[i]?.attribute?.code || '',
            ecom_prd_name: _this.compareList[i]?.content?.title || '',
            ecom_prd_brand: 'HFLOR',
            ecom_prd_category: _this.compareList[i]?.parents?.[i]?.name || '',
            ecom_prd_variant: prdVariant || '',
            ecom_prd_type: Array.isArray(_this.compareList[i]?.attribute?.patternText)
              ? _this.compareList[i]?.attribute?.patternText?.join(', ')
              : _this.compareList[i]?.attribute?.patternText || '',
            ecom_prd_collection: _this.compareList[i]?.parents?.[0]?.name || '',
            ecom_prd_color: ''
          })
        }
      }
    },
    addCompare (id) {
      const _this = this

      if (_this.compareArr.length < 5) {

      } else {
        _this.replaceId = id
        $('#' + id).prop('checked', false)
        this.removeCompare(id)
        LayerPopup.open('layer-alert')
        return false
      }
    },
    removeCompare (id) {
      const _this = this
      if (_this.compareList != null) {
        const idx = _this.compareList.findIndex(function (v) {
          return v.content.id === id
        })
        _this.compareArr.splice(idx, 1)
        _this.compareList.splice(idx, 1)
      }
    },
    async getCompareList () {
      const _this = this
      const target = document.getElementById('replace')
      _this.compareList = []
      _this.compareRes = _this.compareArr
      LayerPopup.close() // alert 클로즈
      _this.compareDetail = await ProductService.getContentByIds(_this.compareRes)
      _this.compareList = _this.compareDetail.data
      LayerPopup.open('layer-compare')
      if (target.disabled) {
        target.disabled = false
      }
    },
    async replace () {
      const _this = this
      const target = document.getElementById('replace')
      target.disabled = true
      if (_this.compareArr != null) {
        _this.compareArr.splice(0, 1)
        _this.compareList.splice(0, 1)
      }
      _this.compareArr.push(_this.replaceId)
      _this.getCompareList()
    },
    // async prdFilter(value){
    //   // return value.filter(v => v.includes('prdType'));
    //   let prd = await ProductService.getContentAttr('FLRNG_PRDTYPE',value);
    //   return prd;
    // },
    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },

    alertAndGoLogin () {
      alert(this.$t('PCD.alert.loginLike'))
      this.$utils.goLogin()
    },

    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode === '_GL') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            if (o.nationCode == t.nationCode) {
              t.getSessionContent.push(o.id)
            }
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    getThick (item) {
      const arr = new Set(item.map(a => a.thickText))
      const thick = [...arr]
      return thick
    },

    getWearLayer (item) {
      return item.map(a => [a.thickText, a.wearLayerText].join(' : '))
    },

    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    clickFilter () {
      const element = document.getElementById('filterSearch')

      if (this.filterShow === false) {
        element.style.cssText = 'z-index: 99999999'
      }

      if (this.filterShow === true) {
        element.style.cssText = 'display : none'
      }

      return this.filterShow = !this.filterShow
    },
    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      const cat = data.parents[1].code
      let prdType = ''
      let hueText = ''
      if (_.includes(cat, 'FLOOR_LUX') || _.includes(cat, 'FLOOR_HET')) {
        if (data.attribute.prdTypeText !== undefined) {
          prdType = data.attribute.prdTypeText.join('|')
        }
      }
      if (!_.isUndefined(data.attribute.hueText)) {
        hueText = data.attribute.hueText
      }
      return JSON.stringify({
        ecom_prd_id: data.content.title,
        ecom_prd_name: data.attribute.code,
        ecom_prd_brand: data.parents[1].name,
        ecom_prd_category: data.parents[2].name, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: prdType, // HFLOR only over 1 items
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    },

    makeSeoMainClt (data) {
      let main_collectionNm = ''

      if (data.includes('FLOOR_LUX')) {
        main_collectionNm = 'lvt-flooring'
      } else if (data.includes('FLOOR_HOM')) {
        main_collectionNm = 'homogeneous-sheet'
      } else if (data.includes('FLOOR_HET')) {
        main_collectionNm = 'heterogeneous-sheet'
      } else {
        main_collectionNm = 'sports-flooring'
      }

      return main_collectionNm
    }
  }
}
</script>
