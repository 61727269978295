import request from './request'
const urlPrefix = ''

const CountryService = {
  setCountryCode (countryCode) {
    return request({
      url: `${urlPrefix}/country/code?code=${countryCode}`,
      method: 'get'
    })
  }
}

export default CountryService
