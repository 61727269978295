var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vinyl-coated-material-construction-area" },
    [
      _c("ProductSectionHeader", { attrs: { title: "Construction" } }),
      _c("ProductTab", {
        staticClass: "mt-50",
        attrs: {
          item: [
            { title: "Metallic Film", component: "MetallicFilm" },
            { title: "Pearl & Patterned Film", component: "PatternedFilm" },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "MetallicFilm",
            fn: function () {
              return [_c("MetallicFilm", { staticClass: "mt-50 film-area" })]
            },
            proxy: true,
          },
          {
            key: "PatternedFilm",
            fn: function () {
              return [_c("PatternedFilm", { staticClass: "mt-50 film-area" })]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }