<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content">

      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Header</li>
          <li>{{$t('imageLib.FH0190_000')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <div class="container">
        <div class="w-con-02">
          <h1 class="tt-1 mb-40 tac inner">{{$t('imageLib.FH0190_000')}}</h1>
          <div class="ctt ctt-t2 tac mb-88 inner">
            {{$t('imageLib.FH0190_001')}}<br /> {{$t('imageLib.FH0190_002')}}<br /> {{$t('imageLib.FH0190_002_1')}}<br />
            <!-- <a class="link-ctt" @click = "modalterm = true"> -->
            <!-- <a class="link-ctt" @click = "handle_toggle"> -->
            <a class="link-ctt" @click="openTermsPopup('layer-terms-copy')">
            <!-- <a class="link-ctt" @click="openModal(item.name)"> -->
              <strong>{{$t('imageLib.FH0190_003')}}</strong>
            </a>
          </div>

          <!-- 팝업 추가 -->
          <div class="layer-view layer-dialog popup-v2 layer-imglib">
            <div class="popup pop-close">
              <div class="scr-y">
                <p class="popup-title">{{$t('imageLib.FH0190_000')}}</p>
                <div class="popup-body">
                  <div class="frow">
                    <div class="fcol tac">
                      <i class="pcon pcon-bang"></i>
                      <p class="msg" v-html="$t('imageLib.FH0190_004')"></p>
                    </div>
                  </div>
                </div>
                <div class="popup-footer hasDouble">
                  <div class="frow">
                    <div class="fcol">
                      <button type="button" class="btn btn-imglib-no" @click="confirmJournal(false)">
                        <span>No</span>
                      </button>
                      <button type="button" class="btn" @click="confirmJournal(true)">
                        <span>Yes</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- close -->
              <button type="button" class="btn-close" onClick="tmpClose()">close</button>
            </div>
          </div>

          <div class="layer-view layer-dialog popup-v2 term-modal layer-terms-copy">
            <div class="popup pop-close">
              <div class="scr-y">
                <p class="popup-title">{{$t('imageLib.FH0190_003')}}</p>
                <div class="popup-body">
                  <div>
                    <p class="popup-con-tt ctt-t4">
                      {{$t('imageLib.FH0190_017')}}:
                    </p>
                    <ul class="ul-popup-circle">
                      <li class="ctt-t4">
                        {{$t('imageLib.FH0190_018')}}
                      </li>
                      <li class="ctt-t4">
                        {{$t('imageLib.FH0190_019')}}
                      </li>
                      <li class="ctt-t4">
                        {{$t('imageLib.FH0190_019_1')}}
                      </li>
                      <li class="ctt-t4">
                        {{$t('imageLib.FH0190_020')}}
                      </li>
                      <li class="ctt-t4">
                        {{$t('imageLib.FH0190_021')}}
                      </li>
                      <li class="ctt-t4">
                        {{$t('imageLib.FH0190_022')}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- close -->
              <button type="button" class="btn-close" @click="closeTermsPopup('')">close</button>
            </div>
          </div>
          <!-- 팝업 끝 -->

          <div class="tab-ui tab-ui-dark">
            <ul>
              <li class="btn-tab on">
                <button type="button" @click="selectTab('HFLOR')">HFLOR ({{listCount('HFLOR')}})</button>
              </li>
              <li class="btn-tab">
                <button type="button" @click="selectTab('HIMACS')">HIMACS ({{listCount('HIMACS')}})</button>
              </li>
            </ul>
          </div>

          <!-- S : list Empty -->
          <div class="empty-img-lib" v-show="(this.selectedTab == 'HFLOR' && listCount('HFLOR') == 0) || (this.selectedTab == 'HIMACS' && listCount('HIMACS') == 0)">
            <i></i>
            <p>{{$t('imageLib.FH0190_015')}}</p>
          </div>
          <!-- E : list Empty -->

          <div class="cntrl-img-lib mb-40 inner" v-show="(this.selectedTab == 'HFLOR' && listCount('HFLOR') > 0) || (this.selectedTab == 'HIMACS' && listCount('HIMACS') > 0)">
            <label class="inp-chk">
              <input type="checkbox" @click="selectAll()" v-model="allSelected">
              <span>{{$t('imageLib.FH0190_023')}}</span>
            </label>
            <button type="button" class="btn btn-text btn-text-md" @click="delchk()">
              <span>{{$t('imageLib.FH0190_024')}}</span>
            </button>
            <button type="button" class="btn btn-req btn-block-m" @click="sendRequest()">
              <span>{{$t('imageLib.FH0190_011')}}</span>
            </button>
            <!-- <div>Checked names: {{ chkList }}</div> -->
          </div>
          <div class="list-lib img-library inner" v-show="this.selectedTab == 'HFLOR'">
            <ul>
              <li v-for="(item, index) in hflorList" :key="index">
                <label class="inp-chk" v-html="item.attribute.summary">
                  <input type="checkbox" v-model="chkList" :value="item.categoryName+'^'+item.contentId+'^'+item.imgPathNm">
                </label>
                <div class="img-lib" v-bind:style="{ backgroundImage: 'url( '+$imagePath+item.imgPathNm+')'}"></div>
                <p class="title-lib">{{item.imgName}}</p>
                <p class="file-lib"></p>
                <i class="on"></i>
              </li>
            </ul>
            <div class="tac">
              <button type="button" class="btn btn-arr-down" v-if="this.hflorList.length > 0 && listCount('HFLOR') - this.hflorListSize > 0" @click="seeMore('HFLOR')">
                <span>{{$t('imageLib.FH0190_016')}}</span>
              </button>
            </div>
          </div>
          <div class="list-lib img-library inner" v-show="this.selectedTab == 'HIMACS'">
            <ul>
              <li v-for="(item, index) in himacsList" :key="index">
                <label class="inp-chk">
                  <input type="checkbox" v-model="chkList" :value="item.categoryName+'^'+item.contentId+'^'+item.imgPathNm">
                </label>
                <div class="img-lib" v-bind:style="{ backgroundImage: 'url( '+$imagePath+item.imgPathNm+')'}"></div>
                <p class="title-lib">{{item.imgName}}</p>
                <p class="file-lib"></p>
                <i class="on"></i>
              </li>
            </ul>
            <div class="tac">
              <button type="button" class="btn btn-arr-down" v-if="this.himacsList.length > 0 && listCount('HIMACS') - this.himacsListSize > 0" @click="seeMore('HIMACS')">
                <span>{{$t('imageLib.FH0190_016')}}</span>
              </button>
            </div>
          </div>
          <div class="wrapper-git d-block mt-80 inner">
            <div class="box-git for-img-lib">

              <div class="col-02 p-ui">
                <div class="img-lib-txt">{{$t('imageLib.FH0190_013')}}</div>
                <div class="img-lib-btn tar">
                  <button type="button" class="btn btn-block-m" @click="gotoUrl('inspiration/newsRoom/INS_CAS')">
                    <span>{{$t('imageLib.FH0190_014')}}</span>
                  </button>
                </div>
              </div>

              <table class="m-ui">
                <tbody>
                  <tr>
                    <td>
                      {{$t('imageLib.FH0190_013')}}
                      <button type="button" class="btn btn-block-m" @click="gotoUrl('inspiration/newsRoom/INS_CAS')">
                        <span>{{$t('imageLib.FH0190_014')}}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>
      </div>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'app',
  //   mixins: [axiosService],
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      categoryName: 'HFLOR',
      selectedTab: 'HFLOR',
      allList: [], // 전체 목록
      hflorList: [], // 화면에 보여줄 목록
      himacsList: [], // 화면에 보여줄 목록,
      hflorListSize: 6,
      himacsListSize: 6,
      chkList: [],
      allSelected: false,
      nationCode: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
  },
  mounted () {
    // alert('imageLib')
    this.init()
    if (this.$store.getters['header/getImgLibtPopup']) {
      tmpPopup('layer-imglib')
    }
  },
  methods: {
    openTermsPopup (str) {
      LayerPopup.open(str)
      this.init()
      $('body').css('overflow', 'hidden')
    },
    closeTermsPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow', 'auto')
    },
    async init () {
      const filterAllList = this.$store.getters['imglib/getImgLibItemArr']
      this.allList = filterAllList.filter((element) => element.nationCode === this.nationCode)

      this.hflorListSize = 6
      this.himacsListSize = 6
      this.setData('HFLOR')
      this.setData('HIMACS')
    },
    setData (cateNm) {
      if (cateNm === 'HFLOR') {
        this.hflorList = _.take(_.filter(this.allList, ['categoryName', cateNm]), this.hflorListSize)
      } else {
        this.himacsList = _.take(_.filter(this.allList, ['categoryName', cateNm]), this.himacsListSize)
      }
    },
    seeMore (cateNm) {
      if (cateNm === 'HFLOR') {
        this.hflorListSize = this.hflorListSize + 3
      } else {
        this.himacsListSize = this.himacsListSize + 3
      }
      this.setData(cateNm)
    },
    listCount (cateNm) {
      return _.filter(this.allList, ['categoryName', cateNm]).length
    },
    delchk () {
      const t = this
      if (confirm(this.$t('imageLib.FH0190_025'))) {
        const imgLibObj = { pushArr: this.$store.getters['imglib/getImgLibItemArr'] }

        _.forEach(this.chkList, function (v) {
          _.remove(imgLibObj.pushArr, function (c) {
            return c.nationCode === t.nationCode && (c.categoryName === _.split(v, '^')[0] && c.contentId === _.split(v, '^')[1] && c.imgPathNm === _.split(v, '^')[2])
          })
        })

        this.$store.commit('imglib/pushItem', imgLibObj)
        this.$store.dispatch('header/getTotalImgLibCnt', { vm: this })

        this.init()
        alert(this.$t('imageLib.FH0190_026'))
      }
    },
    selectTab (cateName) {
      this.selectedTab = cateName
      this.chkList = []
      this.allSelected = false
    },
    selectAll () {
      this.chkList = []

      if (!this.allSelected) {
        const delArray = []
        if (this.selectedTab === 'HFLOR') {
          $('.img-library').eq(0).find('input[type="checkbox"]').each(function () {
            delArray.push($(this).val())
          })
        } else {
          $('.img-library').eq(1).find('input[type="checkbox"]').each(function () {
            delArray.push($(this).val())
          })
        }
        this.chkList = delArray
      }
    },
    sendRequest () {
      const t = this
      if (this.chkList.length === 0) {
        alert(this.$t('imageLib.FH0190_012'))
      } else {
        const imgLibObj = { pushArr: this.$store.getters['imglib/getImgLibItemArr'] }
        const list = this.chkList
        // image 목록의 모든 item의 order value를 N으로 초기화
        _.forEach(imgLibObj.pushArr, function (v) {
          v.orderYn = 'N'
        })
        // check된 item 들 중에서 session storage 의 내용을 필터링
        _.forEach(list, function (c) {
          const filtered = _.filter(imgLibObj.pushArr, function (v) {
            return v.nationCode === t.nationCode && (v.contentId === _.split(c, '^')[1] && v.imgPathNm === _.split(c, '^')[2] && v.categoryName === _.split(c, '^')[0])
          })
          console.log(filtered)
          _.forEach(filtered, function (d) {
            d.orderYn = 'Y'
          })
        })
        this.$store.commit('imglib/pushItem', imgLibObj)

        window.location.href = 'inspiration/orderHDImage/' + this.selectedTab
      }
    },
    confirmJournal (isYn) {
      if (isYn) {
        this.$store.commit('header/setImgLibPopupClose')
        tmpClose('layer-imglib')
      } else {
        history.back()
      }
    },
    gotoUrl (url, isNewTab) {
      if (isNewTab) {
        window.open(url, '_blank')
      } else {
        location.href = url
      }
    }
  }
}

</script>
