<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- S : complete popup-->
    <div class="layer-view layer-result popup-v2">
      <div class="popup pop-close">
        <div class="scr-y">
          <p class="popup-title">Warranty Registration</p>
          <div class="popup-body">
            <div class="frow">
              <div class="fcol tac">
                <i class="pcon pcon-check"></i>
                <strong class="err-title">Warranty Registration Complete!</strong>
                <p class="msg">Installation Date: {{data.installMonth}}/{{data.installDay}}/{{data.installYear}}</p>
                <p class="msg">Registration Date: {{$moment().format('MM/DD/YYYY')}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- close -->
        <button type="button" class="btn-close" onClick="tmpClose('warranty')">close</button>
      </div>
    </div>
    <!-- E : complete popup-->

    <!-- S : confirm popup-->
    <div class="layer-view layer-result2 popup-v2">
      <div class="popup pop-close">
        <div class="scr-y">
          <!--          <p class="popup-title">Warranty Registration</p>-->
          <div class="popup-body">
            <div class="frow">
              <div class="fcol tac">
                <!--<i class="pcon pcon-check"></i>-->
                <!--strong class="err-title">Warranty Registration Complete!</strong>-->
                <p class="msg">
                  Our Limited Warranty for manufacturing defects excludes<br>
                  • Etches<br>
                  • Stains<br>
                  • Seams<br>
                  • Chips & Cracks<br>
                  • Hard Water Marks & Deposits<br>
                  • Caulking, grout and joint expansion<br>
                  • Scratches<br>
                  <br>
                  These items can be covered by Extended Protection Plan(EPP).
                  Do you want to register for an Extended Protection Plan?
                  By choosing “Yes” or “No” you understand and accept that the above items will not be covered under the LX Hausys Limited Warranty.
                </p>
              </div>
            </div>
          </div>
          <div class="more-box ta-c">
            <button type="button" class="btn" id="yes" value="yes" @click="buttonCheck('yes')"><span>Yes</span></button>
            <button type="button" class="btn btn-second" id="no" value="no" @click="buttonCheck('no')"><span>No</span></button>
          </div>
        </div>
        <button type="button" class="btn-close" onClick="tmpPopupClose()">close</button>
      </div>
    </div>
    <!-- E : confirm popup-->

    <div class="content cntu-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("warranty.text.home") }}</li>
          <li>{{ $t("warranty.text.support") }}</li>
          <li>{{ $t("warranty.text.warranty") }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- US container (Warranty) -->
      <div class="container cntu-box wa-box us-container-warranty" v-if="nationCode === '_US' || nationCode === '_GL'">
        <div class="w-con-02">
          <div class="inner">
            <div class="sub-visual-section">
              <h1 class="tt-1 visual-title tac">{{ $t("warranty.text.warranty") }} <span class="sr-only"> - {{ prdLabel }}</span></h1>
              <h2 class="sr-only">LX Hausys Warranty {{ prdLabel }}</h2>
              <div class="ctt ctt-t2 tac visual-desc">
                <span class="ctt ctt-t2 pc-br">{{ $t("warranty.text.mainTxt1") }}</span>
                <span class="ctt ctt-t2 pc-br">{{ $t("warranty.text.mainTxt2") }}</span>
                <div class="btn-box">
                  <button type="button" class="btn btn-reg btn-wa mt-40" v-show="(nationCode != '_GL') && (selectedContentNm == 'VIATERA') || (nationCode != '_GL') && (selectedContentNm == 'Viatera') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS') || (nationCode != '_GL') && (selectedContentNm == 'TERACANTO') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS Sink') || (nationCode == '_US') && (selectedContentNm == 'HIMACS SINK') || warrantyToRegister === true">{{ $t("warranty.button.warranty_to_register") }}</button>
                  <button type="button" class="btn btn-reg2 btn-wa mt-40" v-show="(nationCode != '_GL') && (selectedContentNm == 'VIATERA') || (nationCode != '_GL') && (selectedContentNm == 'Viatera') || (nationCode != '_GL') && (selectedContentNm == 'TERACANTO') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS Sink') || (nationCode == '_US') && (selectedContentNm == 'HIMACS SINK')  || (nationCode != '_GL') && (selectedContentNm == 'HIMACS') || warrantyToRegister === true" @click="goWarrantyClaim();">{{ $t("warranty.button.submit_warranty_claim") }}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="care-box">
            <p class="tt-2 mb-32 inner">{{ $t("warranty.text.warranty_download") }}</p>
            <div class="tab-ui tab-ui-dark">
              <ul>
                <li class="btn-tab" :data-tab="'tab0'+(index+1)" :id="tab.code" v-for="(tab, index) in tabCateList" v-bind:key="index">
                  <button type="button" @click="fntab($event, tab)">{{tab.name}}</button>
                </li>
              </ul>
            </div>
            <!--S : inner-->
            <div class="inner">
              <!-- HFLOR -->
              <div class="tab-con tab01 on">
                <div class="sub-tab tab-ui tab-ui-nav tab-ui-gray" v-if="nationCode == '_US'">
                  <div class="type02">
                    <ul class="tab-list">
                      <button type="button" class="arrow disabled tab-arr-prev">
                        <span class="sr-only"></span>
                      </button>
                      <button type="button" class="arrow tab-arr-next">
                        <span class="sr-only"></span>
                      </button>
                      <!--                    <li class="btn-tab" :data-tab="'sub0'+(index+1)" :id="'sub0'+(index+1)" v-for="(hflor, index) in hflor2List" v-bind:key="index">-->
<!--                      <li class="btn-tab" :data-tab="'sub0'+(index+1)" :id="hflor.code" v-for="(hflor, index) in hflor2List" v-bind:key="index">
                        <input type="hidden" :id="hflor.id" :value="hflor.name"/>
                        &lt;!&ndash;                      <input type="hidden" :value="hflor.id" />&ndash;&gt;
                        <button type="button" @click="fntab($event, hflor)">{{hflor.name}}</button>
                      </li>-->
                    </ul>
                  </div>

                  <!-- Luxury Vinyl Tile -->
                  <div class="tab-con sub01 on">
                    <p class="ctt ctt-t4 desc">{{contentNeeded}}</p>
                  </div>
                  <!-- //Luxury Vinyl Tile -->

                </div>

                <div class="tbl-box type05 box-dl">
                  <table>
                    <caption>{{ $t("warranty.text.download_form") }}</caption>
                    <colgroup class="p-ui">
                      <col style="width:285px">
                      <col style="width:auto">
                      <col style="width:110px">
                      <col style="width:100px">
                      <col style="width:140px">
                    </colgroup>
                    <tbody>
                    <tr v-for="(warr, index) in warrantyList" v-bind:key="index">
                      <th class="dl-title" v-if="warr.parents[1].id === 110">{{warr.parents[1].name}}/{{warr.parents[0].name}}</th>
                      <th class="dl-title" v-else>{{warr.parents[0].name}}</th>
                      <td class="dl-disabled-mobile">{{warr.content.title}}</td>
                      <td class="ta-c file-type">{{warr.attribute.warranty_file_type}}</td>
                      <td class="file-size">{{warr.attribute.warranty_file_size}}</td>
                      <td class="share">
                        <ShareComponent v-bind:childValue="warr" :type="'warranty'"></ShareComponent>
                        <button type="button" class="btn-down" @click="downloadFile(warr.attribute.warranty_file)">{{ $t("warranty.text.download") }}</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="pagination">
                  <ul>
                    <li class="btn btn-first"><button type="button" @click="hasIndex(firstNumber)">{{ $t("warranty.text.first_page") }}</button></li>
                    <li class="btn btn-prev"><button type="button" @click="hasIndex((pageIndex-1))">{{ $t("warranty.text.prev") }}</button></li>
                    <li class="btn-num" :id="'index_'+index" v-for="(index) in pageList" :data-chk="index" @click="hasIndex(index)"><button type="button"><span>{{ index }}</span></button></li>
                    <li class="btn btn-next"><button type="button" @click="hasIndex(pageIndex+1)">{{ $t("warranty.text.next") }}</button></li>
                    <li class="btn btn-last"><button type="button" @click="hasIndex(lastNumber)">{{ $t("warranty.text.last_page") }}</button></li>
                  </ul>
                </div>
              </div>
              <!-- //HFLOR -->

              <!-- Warranty to Register 레지스터-->
              <!--            <div class="tbl-input-box mt-140 wtr-area" v-show="(nationCode != '_GL') && (selectedContentNm == 'Viatera') || (nationCode != '_GL') && (selectedContentNm == 'HIMACS') || warrantyToRegister === true">-->
              <div class="tbl-input-box mt-80 wtr-area" v-if="(nationCode == '_US') && (selectedContentNm == 'VIATERA') || (nationCode == '_US') && (selectedContentNm == 'Viatera') || (nationCode == '_US') && (selectedContentNm == 'HIMACS') || (nationCode == '_US') && (selectedContentNm == 'TERACANTO' || selectedContentNm == 'teracanto') || (nationCode == '_US') && (selectedContentNm == 'HIMACS Sink') || (nationCode == '_US') && (selectedContentNm == 'HIMACS SINK') || warrantyToRegister === true">
                <!--                <form action="#none" class="tab-ui tab-ui-dark"> &lt;!&ndash;@submit.prevent="submitForm"&ndash;&gt;-->
                <fieldset>
                  <legend>{{ $t("warranty.button.warranty_to_register") }}</legend>

                  <div class="reg-tbl">
                    <p class="tt-2 mb-40">{{ $t("warranty.button.warranty_to_register") }}</p>

                    <div class="col-02">
                      <div class="tbl-01">
                        <div class="tbl-input">
                          <table>
                            <caption>{{ $t("warranty.text.WarrantyRegisterForm") }}</caption>
                            <!-- <colgroup>
                              <col style="width:185px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.regTxt") }} </span></th>
                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{regTxt}}</span></button>
                                  <div class="sel-list">
                                    <ul class="box" >
                                      <li v-for="(reg, idx) in userTypeList" :key="idx">
                                        <button type="button" @click="clickSelectBox(reg.text,reg.id.value, 'userType')">{{reg.text}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.userType == '' && submitChk == 'Y'">{{ $t("warranty.text.select_product") }}</p>
                              </td>
                            </tr>

                            <tr>
                              <th scope="row"><label class="must">{{ $t("warranty.text.color") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="color" v-model="data.color" :placeholder="$t('warranty.text.input_text')"/>
                                <p v-if="data.color == '' && submitChk == 'Y'">Enter Product Color</p>
                              </td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="tbl-02">
                        <div class="tbl-input type03">
                          <table>
                            <caption>{{ $t("warranty.text.WarrantyRegisterForm") }}</caption>
                            <!-- <colgroup>
                              <col style="width:185px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.categoryTxt") }}</span></th>
                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{categoryTxt}}</span></button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li v-for="(prd, idx) in categoryIdList" :key="idx">
                                        <button type="button" @click="clickSelectBox(prd.text,prd.id.value, 'categoryId')">{{prd.text}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.categoryId == '' && submitChk == 'Y'">{{ $t("warranty.text.select_registering") }}</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tbl t-01">
                    <p class="tt-2 mb-40">{{ $t("warranty.text.personal_info") }}</p>
                    <div class="col-02">
                      <div class="tbl-01">
                        <div class="tbl-input">
                          <table>
                            <caption>{{ $t("warranty.text.personal_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:160px" class="p-ui">
                              <col style="width:30%" class="m-ui">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must" for="inp01">{{ $t("warranty.text.first_name") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp01" v-model="data.firstNm" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.firstNm == '' && submitChk == 'Y'">{{ $t("warranty.text.Enter_First_Name") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp02">{{ $t("warranty.text.last_name") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp02" v-model="data.lastNm" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.lastNm == '' && submitChk == 'Y'">{{ $t("warranty.text.Enter_Last_Name") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp03">{{ $t("warranty.text.address1") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp03" v-model="data.addr1" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.addr1 == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_address") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label for="inp04">{{ $t("warranty.text.Address2") }}</label></th>
                              <td>
                                <input type="text" class="inp-text" id="inp04" v-model="data.addr2" :placeholder="$t('warranty.text.input_text')" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp05">{{ $t("warranty.text.telephone") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp05" v-model="data.phone" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');"/>
                                <p v-if="data.phone == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_telephone") }}</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="tbl-02">
                        <div class="tbl-input type03">
                          <table>
                            <caption>{{ $t("warranty.text.personal_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:130px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must" for="inp06">{{ $t("warranty.text.email") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp06" v-model="data.email" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.email == '' && submitChk == 'Y' || emailValidTxt == false">{{ $t("warranty.text.enter_email") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.country") }}</span></th>
                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{countryTxt}}</span></button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li v-for="(cnt, idx) in countryList" :key="idx">
                                        <button type="button" @click="clickSelectBox(cnt.countryNm, cnt.countryCd, 'country')">{{cnt.countryNm}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.countryCd == '' && submitChk == 'Y'">{{ $t("warranty.text.select_country") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.state") }}</span></th>
                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{ stateTxt }}</span></button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li v-for="state in stateList">
                                        <button type="button" @click="clickSelectBox(state.stateNm, state.id.stateCd, 'state')">{{ state.stateNm }}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.stateCd == '' && submitChk == 'Y'">{{ $t("warranty.text.select_state") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp07">{{ $t("warranty.text.zip_code") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp07" v-model="data.zipCd" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.zipCd == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_zip_code") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp08">{{ $t("warranty.text.city") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp18" v-model="data.city" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.city == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_city") }}</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tbl">
                    <p class="tt-2 mb-40">{{ $t("warranty.text.dealer_info") }}</p>

                    <div class="col-02">
                      <div class="tbl-01">
                        <div class="tbl-input">
                          <table>
                            <caption>{{ $t("warranty.text.dealer_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:220px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must" for="inp08">{{ $t("warranty.text.dealer") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp08" v-model="data.dealer" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.dealer == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_dealer") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp09">{{ $t("warranty.text.dealer_city") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp09" v-model="data.dealerCity" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.dealerCity == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_dealer_city") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp10">{{ $t("warranty.text.dealer_phone") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp10" v-model="data.dealerPhone" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');"/>
                                <p v-if="data.dealerPhone == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_dealer_phone") }}</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="tbl-02">
                        <div class="tbl-input type03">
                          <table>
                            <caption>{{ $t("warranty.text.dealer_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:260px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><label class="must" for="inp11">{{ $t("warranty.text.fabricator") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp11" v-model="data.installer" :placeholder="$t('warranty.text.input_text')" />
                                <p v-if="data.installer == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_fabricator") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><label class="must" for="inp12">{{ $t("warranty.text.fabricator_phone") }}</label></th>
                              <td class="cell-noti">
                                <input type="text" class="inp-text" id="inp12" v-model="data.installerPhone" placeholder="000-000-0000" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');"/>
                                <p v-if="data.installerPhone == '' && submitChk == 'Y'">{{ $t("warranty.text.enter_fabricator_phone") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.date_of_installation") }}</span></th>
                              <td class="cell-noti">
                                <div class="sel-day">
                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{installMonthTxt}}</span></button>
                                    <div class="sel-list">
                                      <ul class="box">
                                        <li v-for="(mon, idx) in installMonthList" :key="idx">
                                          <button type="button" @click="clickSelectBox(mon,'', 'month')">{{mon}}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{installDayTxt}}</span></button>
                                    <div class="sel-list">
                                      <ul class="box">
                                        <li v-for="(day, idx) in installDayList" :key="idx">
                                          <button type="button" @click="clickSelectBox(day,'', 'day')">{{day}}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div class="sel-box type02">
                                    <button type="button" class="btn-sel"><span>{{installYearTxt}}</span></button>
                                    <div class="sel-list">
                                      <ul class="box" >
                                        <li v-for="(year, idx) in installYearList" :key="idx">
                                          <button type="button" @click="clickSelectBox(year,'', 'year')">{{year}}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <p v-if="data.installMonth == '' || data.installDay == '' || data.installYear == '' && submitChk == 'Y'">{{ $t("warranty.text.select_date") }}</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="ta-box">
                      <textarea :placeholder="$t('warranty.text.comments')" v-model="data.memo"></textarea>
                    </div>
                  </div>

                  <div class="tbl">
                    <p class="tt-2 mb-40">{{ $t("warranty.text.product_info") }}</p>

                    <div class="col-02">
                      <div class="tbl-01">
                        <div class="tbl-input">
                          <table>
                            <caption>{{ $t("warranty.text.product_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:320px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <!--                              <tr>-->
                            <!--                                <th scope="row"><label class="must" for="inp13">Overall Cost Of Remodeling Project</label></th>-->
                            <!--                                <td class="cell-noti">-->
                            <!--                                  <input type="text" class="inp-text" id="inp13" v-model="data.overallCost" placeholder="Input Text" />-->
                            <!--                                  <p v-if="data.overallCost == '' && submitChk == 'Y'">Enter Overall Cost Of Remodeling Project</p>-->
                            <!--                                </td>-->
                            <!--                              </tr>-->
                            <tr class="dvsn-for-rspns">
                              <th scope="row"><span class="must">{{ $t("warranty.text.materialsTxt") }}</span></th>
                              <!--                                <td class="cell-noti">-->
                              <!--                                  <ul class="chk-list">-->
                              <!--                                    <li v-for="(mat, idx) in materialList" :key="idx">-->
                              <!--                                      <label class="inp-chk">-->
                              <!--                                        <input type="checkbox" v-model="data.chkMaterials" :value="mat.id.value">-->
                              <!--                                        <span >{{mat.text}}</span>-->
                              <!--                                      </label>-->
                              <!--                                    </li>-->
                              <!--                                  </ul>-->
                              <!--                                  <p v-if="data.chkMaterials == '' && submitChk == 'Y'">Enter LX Hausys materials</p>-->
                              <!--                                </td>-->

                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{materialsTxt}}</span></button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li v-for="(mat, idx) in materialList" :key="idx">
                                        <button type="button" @click="clickSelectBox(mat.text,mat.id.value, 'materials')">{{mat.text}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.chkMaterials == '' && submitChk == 'Y'">{{ $t("warranty.text.select_materialsTxt") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                  <span class="must">
                                    {{ $t("warranty.text.upload_file") }}
                                  </span>
                                <p class="ctt ctt-t4">{{$t('warranty.text.upload_file_brackets')}}</p>
                              </th>
                              <td class="cell-noti">
                                  <span class="inp-file">
                                      <label for="file01">{{ $t("warranty.text.upload") }}</label>
                                      <input type="file" id="file01" @change="onInputImage()" ref="submitImage" multiple="multiple"/>
                                  </span>

                                <!-- S : 업로드 된 파일 리스트 -->
                                <ul class="list-upload-file">
                                  <li v-for="file in data.image">
                                    <span title="20211129_Viatera_Receipt_1.pdf">{{ file.name }}</span>
                                    <button type="button" @click="imageDel(file.name)">del</button>
                                  </li>
                                </ul>
                                <!-- E : 업로드 된 파일 리스트 -->

                                <p v-if="data.image.length == 0 && submitChk == 'Y'">{{ $t("warranty.text.select_upload_file") }}</p>
                              </td>
                            </tr>
                            <!--                              <tr>-->
                            <!--                                <th scope="row"><label class="must" for="inp14">If "Other" Selected, Please Describe</label></th>-->
                            <!--                                <td class="cell-noti">-->
                            <!--                                  <input type="text" class="inp-text" id="inp14" v-model="data.describe" placeholder="Input Text" />-->
                            <!--                                  <p v-if="data.describe == '' && submitChk == 'Y'">Enter If "Other" Selected, Please Describe</p>-->
                            <!--                                </td>-->
                            <!--                              </tr>-->
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="tbl-02">
                        <div class="tbl-input type03">
                          <table>
                            <caption>{{ $t("warranty.text.product_info_form") }}</caption>
                            <!-- <colgroup>
                              <col style="width:410px">
                              <col style="width:auto">
                            </colgroup> -->
                            <tbody>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.sink_type") }}</span></th>
                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{sinkTypeTxt}}</span></button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li v-for="(sink, idx) in sinkTypeList" :key="idx">
                                        <button type="button" @click="clickSelectBox(sink.text,sink.id.value, 'sinkType')">{{sink.text}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.sinkType == '' && submitChk == 'Y'">{{ $t("warranty.text.select_sink_type") }}</p>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><span class="must">{{ $t("warranty.text.hear") }}</span></th>
                              <td class="cell-noti">
                                <div class="sel-box type02">
                                  <button type="button" class="btn-sel"><span>{{hearPathTxt}}</span></button>
                                  <div class="sel-list">
                                    <ul class="box">
                                      <li v-for="(hear, idx) in hearPathList" :key="idx">
                                        <button type="button" @click="clickSelectBox(hear.text,hear.id.value, 'hearPath')">{{hear.text}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <p v-if="data.hearPath == '' && submitChk == 'Y'">{{ $t("warranty.text.select_hear") }}</p>
                              </td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-64 ta-c">
                    <button class="btn" @click="submitForm()"><span>{{ $t("warranty.button.submit") }}</span></button>
                  </div>
                </fieldset>
                <!--                </form>-->
              </div>
              <!-- //Warranty to Register -->
            </div>
            <!--E : inner-->
          </div>
        </div>
      </div>
      <!-- //US container (Warranty) -->

      <!-- EU container (Warranty) -->
      <div class="container cntu-box care-container eu-container-warranty" v-if="nationCode !== '_GL' && nationCode !== '_US'">
        <div class="w-con-02">
          <div class="inner">
            <div class="sub-visual-section">
              <h1 class="tt-1 visual-title tac">{{ $t("warranty.text.warranty") }}</h1>
            </div>
          </div>
          <div class="tab-ui tab-ui-dark tab-care">
            <ul class="btns02">
              <li class="btn-tab on" id="btn-tab01" data-tab="tab01"><button type="button">hflor</button></li>
              <li class="btn-tab wide" id="btn-tab02" data-tab="tab02"><button type="button">himacs</button></li>
            </ul>

            <!-- hflor -->
            <div class="tab-con tab01 on inner" id="inner01">
              <div class="tac dis-block">
                <p class="ctt ctt-t1 mb-20">
                  {{ $t("warranty.text.hflor_t1") }}
                </p>
                <p class="ctt ctt-t2 mb-50 maxw900">
                  {{ $t("warranty.text.hflor_t2") }}
                  <!-- [Contact us> Support > Warranty] 텍스트 줄바꿈 수정 -->
                  <!-- <span class="pc-br">Bringing together design, function and safety, HFLOR offers a comprehensive package of warranties to guarantees the high-performance flooring system.</span>
                  <span class="pc-br">All HFLOR products are backed by warranties that ensure both quality and additional peace of mind.</span> -->
                </p>
              </div>
              <div class="down-box wa-box">
                <div class="tit-box">
                  <p class="tt-2">{{  $t("warranty.text.warranty_down") }}</p>
                  <div class="more-box type02-04">
                    <!-- <button type="button" class="btn btn-line">
                      <span>more</span>
                    </button> -->
                  </div>
                </div>

                <div class="tbl-box type05 box-dl hf-tbl">
                  <table>
                    <caption>download_form</caption>
                    <colgroup class="p-ui">
                      <col style="width:280px">
                      <col style="width:auto">
                      <col style="width:160px">
                      <col style="width:110px">
                      <col style="width:140px">
                    </colgroup>
                    <tbody>
                    <tr v-for="(content,index) in hFlorContentList">
                      <th class="dl-title">{{ content.parents[0].name }}</th>
                      <td class="dl-disabled-mobile">{{ content.content.title }}</td>
                      <td>
                        <div class="sel-box type02">
                          <button type="button" class="btn-sel"><span>{{ content.clickDocLabel }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="(doc) in content.docList">
                                <button type="button" @click="docChange(doc.label, doc.file, doc.type, index, 'HFLOR')">{{ doc.label }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="ta-c file-type">{{ content.fileType }}</td>
                      <td class="share-box">
                        <ShareComponent :data-chk="content" v-bind:childValue="content" :type="'document'"></ShareComponent>
                        <!-- <ShareComponent></ShareComponent> -->
                        <!--                          <span class="sns-wrp">-->
                        <!--                            <button type="button" class="btn-share icon-share">sharing</button>-->
                        <!--                            <div class="sns">-->
                        <!--                              <ul>-->
                        <!--                                <li><a href="javascript:;" class="ico-e">email</a></li>-->
                        <!--                                <li><a href="javascript:;" class="ico-v">url</a></li>-->
                        <!--                              </ul>-->
                        <!--                            </div>-->
                        <!--                          </span>-->
                        <button type="button" class="btn-down" @click="downloadFile(content.clickDocFile)">button.download</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- // hflor -->

            <!-- himacs -->
            <div class="tab-con tab02 inner" id="inner02">
              <div class="tac dis-block">
                <!-- <p class="ctt ctt-t1 tac mb-20">
                  Solid Surface at its best : With the right care HIMACS will still look astonishingly new after years.
                </p> -->
                <p class="ctt ctt-t1 mb-20">
                  {{ $t("warranty.text.himacs_t1") }}
                </p>
                <p class="ctt ctt-t2 mb-50 maxw1000">
                  {{ $t("warranty.text.himacs_t2") }}
                </p>
                <div class="tac mb-50 hm-qc">
                  <img :src='`images/support/qc@3x.png`'>
                </div>
              </div>
              <div class="down-box wa-box">
                <div class="tit-box">
                  <p class="tt-2">{{  $t("warranty.text.warranty_down") }}</p>
                  <div class="more-box type02-04">
                    <!-- <button type="button" class="btn btn-line">
                      <span>{more</span>
                    </button> -->
                  </div>
                </div>
                <div class="tbl-box type05 box-dl">
                  <table>
                    <caption>download_form</caption>
                    <colgroup class="p-ui">
                      <col style="width:280px">
                      <col style="width:auto">
                      <col style="width:160px">
                      <col style="width:110px">
                      <col style="width:140px">
                    </colgroup>
                    <tbody>
                    <tr v-for="(himacs, index) in hImacsContentList">
                      <th class="dl-title">{{ himacs.parents[0].name }}</th>
                      <td class="dl-disabled-mobile">{{ himacs.content.title }}</td>
                      <td>
                        <div class="sel-box type02">
                          <button type="button" class="btn-sel"><span>{{ himacs.clickDocLabel }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="(doc) in himacs.docList">
                                <button type="button" @click="docChange(doc.label, doc.file, doc.type, index, 'HIMACS')">{{ doc.label }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="ta-c">{{ himacs.fileType }}</td>
                      <td class="share-box">
                        <ShareComponent :data-chk="himacs" v-bind:childValue="himacs" :type="'document'"></ShareComponent>
                        <!-- <ShareComponent></ShareComponent> -->

                        <button type="button" class="btn-down" @click="downloadFile(himacs.clickDocFile)">button.download</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
      <!-- //EU container (Warranty) -->

    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import ShareComponent from '@/components/Layout/Share'
import {
  getCategoryCode,
  getCategoryList
} from '@/server/category'
import ProductService from '@/server/product.service'
import SupportService from '@/server/support.service'
import getCountryList from '@/server/us.service'
import UserService from '@/server/user.service'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'warranty',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    ShareComponent,
    GnbComponent
  },
  data () {
    return {
      euContentList: [],
      hFlorContentList: [],
      hImacsContentList: [],
      hflor2List: [],
      selectedContentNm: '',
      contentNeeded: '',

      warrantyList: [],
      tabCateList: [],
      cateId: null,

      pageIndex: 1, /* 현재 페이지 */
      pageSize: 5, /* 게시글 수 */
      prdLabel: '' /* 상품 카테고리 이름(hflor) */,
      pageList: [],
      firstNumber: '',
      lastNumber: '',
      totalPage: '',
      totalCount: '',
      cateCode: '',

      // warranty to register
      submitChk: 'N',
      userTypeList: [],
      categoryIdList: [],
      countryList: [],
      sinkTypeList: [],
      hearPathList: [],
      materialList: [],
      stateList: [],
      emailValidTxt: true,
      data: {
        userType: '',
        categoryId: '',
        color: '',
        countryCd: '',
        stateCd: '',
        sinkType: '',
        hearPath: '',

        installYear: '',
        installMonth: '',
        installDay: '',

        chkMaterials: [],

        firstNm: '',
        lastNm: '',
        addr1: '',
        addr2: '',
        phone: '',
        email: '',
        zipCd: '',
        city: '',
        dealer: '',
        dealerCity: '',
        dealerPhone: '',
        installer: '',
        installerPhone: '',
        // overallCost: '',
        // describe: '',
        memo: '',
        image: [],
        fileName: 'Upload'
      },

      regTxt: 'Select',
      categoryTxt: 'Select',
      sinkTypeTxt: 'Select',
      hearPathTxt: 'Select',
      countryTxt: 'Select',
      materialsTxt: 'Select',
      installMonthTxt: 'mm',
      installDayTxt: 'dd',
      installYearTxt: 'yyyy',
      stateTxt: 'Select',
      // 년월일 selectBox
      installYearList: [],
      installMonthList: [],
      installDayList: [],
      hflorcategoryId: null,
      dicValue: [],

      nationCode: '',
      countryCodeSub: '',
      warrantyToRegister: false,
      seoCheck: false,
      seoName: ''
      // submitPopup: false,
    }
  },

  async created () {
    const t = this
    if (location.pathname.indexOf('/support/warranty/WARRANTY') === -1) {
      t.seoName = t.$route.params.categoryCode
      t.seoCheck = true
    } else {
      t.seoCheck = false
    }
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)
    if (t.nationCode == '_GL' || t.nationCode == '_US') {
      const dicList = await ProductService.getDictionaryValueList(['WRNTY_USR_TYP', 'WRNTY_PRD_TYP', 'WHICH_TYPE_DU_USE', 'HOW_DU_HEAR', 'WRNTY_MTRIAL'])
      // register
      this.userTypeList = dicList.data.dicList[0].list
      this.categoryIdList = dicList.data.dicList[1].list
      this.sinkTypeList = dicList.data.dicList[2].list
      this.hearPathList = dicList.data.dicList[3].list
      this.materialList = dicList.data.dicList[4].list

      const countryList = await getCountryList.getCountryList()
      this.countryList = countryList.data
      this.countryList.map(country => country.countryCd === '99' ? this.countryList.splice(this.countryList.indexOf(country)) : null)
      const dt = new Date()
      const com_year = dt.getFullYear()

      // 올해 기준으로 -50년부터 +1년을 보여준다.
      for (let year = (com_year - 15); year <= (com_year + 1); year++) {
        this.installYearList.push(year)
      }

      // 월 뿌려주기(1월부터 12월)
      for (let month = 1; month <= 12; month++) {
        this.installMonthList.push(month)
      }

      // 일 뿌려주기(1일부터 31일)
      for (let day = 1; day <= 31; day++) {
        this.installDayList.push(day)
      }
    }
  },
  async mounted () {
    const _this = this
    activeArrowTab()// 화살표 탭 인터렉션

    if (_this.nationCode == '_GL' || _this.nationCode == '_US') {
      // hflor 하위 카테고리
      const category = await getCategoryCode('WARRANTY_HFLOR' + this.nationCode)
      this.hflorAttr = await getCategoryList(category.data.object.id)
      this.hflor2List = this.hflorAttr.data.page.list

      // hflor || viatera || himacs 탭 카테고리
      const cateList = await ProductService.getCateChildren('WARRANTY' + this.nationCode)
      if (this.nationCode == '_US') {
        this.tabCateList = cateList.data
      } else {
        this.tabCateList = cateList.data.filter((element) => element.name !== 'Viatera')
      }
      if (_this.seoCheck) {
        if (this.$route.params.categoryCode == 'hflor') {
          _this.cateCode = 'WARRANTY_HFLOR' + _this.nationCode
        } else {
          _this.cateCode = 'WARRANTY_' + this.$route.params.categoryCode.toUpperCase() + _this.nationCode
        }
      } else {
        this.cateCode = this.$route.params.categoryCode + this.nationCode
      }
      // WARRANTY_HFLOR_LUX
      // WARRANTY_HFLOR_HOM
      // WARRANTY_VIATERA
      this.cateId = await ProductService.getCategoryCode(_this.cateCode)
      console.log(this.cateId)
      this.hflorcategoryId = this.cateId.data.object.id
      console.log(this.hflorcategoryId)

      /* this.hflorcategoryId.push(this.categoryId)
      console.log(this.hflorcategoryId) */
      this.dicValue = []

      const warrantyList = await ProductService.getContentListTap([this.hflorcategoryId], this.dicValue, '', 1, 5)

      this.warrantyList = warrantyList.data.list
      this.totalPage = warrantyList.data.totalPage
      this.totalCount = warrantyList.data.totalCount

      this.pagination()

      // 탭별 Content Description
      const docCateCode = await ProductService.getCategoryCode(_this.cateCode)
      const docCateId = await ProductService.getCategoryListAttr(docCateCode.data.object.id)
      this.contentNeeded = docCateId.data.attribute.warranty_content

      _this.$nextTick(function () {
        if (_this.seoCheck) {
          if (this.$route.params.categoryCode != 'viatera' && this.$route.params.categoryCode != 'himacs' && this.$route.params.categoryCode != 'teracanto' && this.$route.params.categoryCode != 'himacssink') {
            $('#WARRANTY_HFLOR' + this.nationCode).addClass('on')
            $('#' + this.cateCode).addClass('on')
          } else {
            $('#' + this.cateCode).addClass('on')
            $('.tab-list').hide()
          }

          this.selectedContentNm = $("[class*='btn-tab on']").find('input').val()

          if (this.nationCode != '_GL' && this.$route.params.categoryCode == 'viatera' || this.nationCode != '_GL' && this.$route.params.categoryCode == 'himacs' || this.nationCode != '_GL' && this.$route.params.categoryCode == 'teracanto' || this.nationCode != '_GL' && this.$route.params.categoryCode == 'himacssink') {
            this.warrantyToRegister = true
          }
        } else {
          if (this.$route.params.categoryCode != 'WARRANTY_VIATERA' && this.$route.params.categoryCode != 'WARRANTY_HIMACS' && this.$route.params.categoryCode != 'WARRANTY_TERACANTO' && this.$route.params.categoryCode != 'WARRANTY_HIMACSSINK') {
            $('#WARRANTY_HFLOR' + this.nationCode).addClass('on')
            $('#' + this.cateCode).addClass('on')
          } else {
            $('#' + this.cateCode).addClass('on')
            $('.tab-list').hide()
          }

          this.selectedContentNm = $("[class*='btn-tab on']").find('input').val()

          if (this.nationCode != '_GL' && this.$route.params.categoryCode == 'WARRANTY_VIATERA' || this.nationCode != '_GL' && this.$route.params.categoryCode == 'WARRANTY_HIMACS' || this.nationCode != '_GL' && this.$route.params.categoryCode == 'WARRANTY_TERACANTO' || this.nationCode != '_GL' && this.$route.params.categoryCode == 'WARRANTY_HIMACSSINK') {
            this.warrantyToRegister = true
          }
        }

        // warranty to register button click -> show/hide
        // $('.reg-tbl').hide();
        // $('.btn-reg').click(function(){
        //   $('.reg-tbl').toggle();
        // });

        $('.wa-box .btn-box .btn-reg').click(function () {
          var offset = $('.wtr-area').offset().top - $('header').height() - 70
          $('html, body').animate({ scrollTop: offset }, 400)
        })
      })
    } else {
      let categoryTap
      if (_this.seoCheck) {
        if (_this.$route.params.categoryCode == 'hflor') {
          categoryTap = 'WARRANTY_LUX'
        } else {
          categoryTap = 'WARRANTY_HIMACS'
        }
      } else {
        categoryTap = _this.$route.params.categoryCode
      }
      if (categoryTap == 'WARRANTY_HIMACS') {
        $('#btn-tab01').removeClass('on')
        $('#inner01').removeClass('on')
        $('#btn-tab02').addClass('on')
        $('#inner02').addClass('on')
      } else {
        $('#btn-tab02').removeClass('on')
        $('#inner02').removeClass('on')
        $('#btn-tab01').addClass('on')
        $('#inner01').addClass('on')
      }
      const code = 'WARRANTY' + _this.nationCode
      const cateId = await ProductService.getCategoryCode(code)
      const categoryId = cateId.data.object.id
      const warrantyList = await ProductService.getContentListChildCate(categoryId)
      _this.euContentList = warrantyList.data.list
      _this.euContentList.forEach((content, idx) => {
        const docList = []
        if (_this.countryCode == '_UK') {
          if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
            docList.push({ label: this.$t('warranty.text.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk', fileType: content.attribute.doc_file_uk_type })
          }
        } else {
          if (!_.isEmpty(content.attribute.doc_file)) {
            docList.push({ label: this.$t('warranty.text.EN'), file: content.attribute.doc_file, type: 'doc_file', fileType: content.attribute.doc_file_type })
          }
        }
        if (!_.isEmpty(content.attribute.doc_file_es) && !_.isUndefined(content.attribute.doc_file_es)) {
          docList.push({ label: this.$t('warranty.text.ES'), file: content.attribute.doc_file_es, type: 'doc_file_es', fileType: content.attribute.doc_file_es_type })
        }
        if (!_.isEmpty(content.attribute.doc_file_de) && !_.isUndefined(content.attribute.doc_file_de)) {
          docList.push({ label: this.$t('warranty.text.DE'), file: content.attribute.doc_file_de, type: 'doc_file_de', fileType: content.attribute.doc_file_de_type })
        }
        if (!_.isEmpty(content.attribute.doc_file_fr) && !_.isUndefined(content.attribute.doc_file_fr)) {
          docList.push({ label: this.$t('warranty.text.FR'), file: content.attribute.doc_file_fr, type: 'doc_file_fr', fileType: content.attribute.doc_file_fr_type })
        }
        if (!_.isEmpty(content.attribute.doc_file_it) && !_.isUndefined(content.attribute.doc_file_it)) {
          docList.push({ label: this.$t('warranty.text.IT'), file: content.attribute.doc_file_it, type: 'doc_file_it', fileType: content.attribute.doc_file_it_type })
        }
        if (!_.isEmpty(content.attribute.doc_file_nl) && !_.isUndefined(content.attribute.doc_file_nl)) {
          docList.push({ label: this.$t('warranty.text.NL'), file: content.attribute.doc_file_nl, type: 'doc_file_nl', fileType: content.attribute.doc_file_nl_type })
        }
        if (_this.countryCode == '_UK') {
          if (!_.isEmpty(content.attribute.doc_file)) {
            docList.push({ label: this.$t('warranty.text.EN'), file: content.attribute.doc_file, type: 'doc_file', fileType: content.attribute.doc_file_type })
          }
        } else {
          if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
            docList.push({ label: this.$t('partnersPortal.document.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk', fileType: content.attribute.doc_file_uk_type })
          }
        }
        content.docList = docList
        content.clickDocLabel = docList[0].label
        content.clickType = docList[0].type
        content.clickDocFile = docList[0].file
        content.fileType = docList[0].fileType
      })
      _this.hImacsContentList = _this.euContentList.filter((element) => element.parents[0].name == 'HIMACS')
      _this.hFlorContentList = _this.euContentList.filter((element) => element.parents[0].name == 'HFLOR')
      _this.$nextTick(function () {
        $('#mainTap').click(function () {
          $("[class*='chk-list chk01 on']").removeClass('on')
          $('#mainTap').addClass('on')
          _this.tapClick('all')
        })
        // $('.sns-wrp .sns').each(function(idx,ele){
        //   var $this = $(this);
        //   $(ele).prev().click(function(){
        //     $this.slideToggle(300);
        //     $this.closest('tr').siblings().children().find('.sns').hide();
        //   });
        // })
      })
    }
    // start ##############################################워런티 투 레지스터

    // end ##############################################Warranty to Register
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async tapClick (tap) {
      const _this = this
      if (tap === 'all') {
        _this.prdLabel = ''
      } else {
        _this.prdLabel = tap
      }
      $('.drop-list > ul > li').removeClass('on')
      $('.t-type-01').css({ display: 'none' })
      _this.pageIndex = 1
      if (tap == 'all') {
        $("[class*='chk-list chk01 on']").removeClass('on')
        $('#mainTap').addClass('on')
        _this.categoryId = []
        _this.categoryId.push(_this.allCategoryId)
        $('.btn-num').removeClass('on')
      } else {
        $('#mainTap').removeClass('on')
        $("[class*='chk-list chk01 on']").removeClass('on')
        $('#' + tap).closest('.btn-chk').addClass('on')
        _this.categoryId = []
        const number = $("[class*='chk-list chk01 on']").length
        for (var i = 0; i < number; i++) {
          _this.categoryId.push($("[class*='chk-list chk01 on']").eq(i).attr('data-chk'))
        }
        $('.btn-num').removeClass('on')
      }

      await _this.pagination()
    },
    docChange (label, file, type, index, category) {
      const _this = this
      if (category == 'HIMACS') {
        _this.hImacsContentList[index].clickDocLabel = label
        _this.hImacsContentList[index].clickDocFile = file
        _this.hImacsContentList[index].clickType = type
      } else if (category == 'HFLOR') {
        _this.hFlorContentList[index].clickDocLabel = label
        _this.hFlorContentList[index].clickDocFile = file
        _this.hFlorContentList[index].clickType = type
      }
      _this.$forceUpdate()
    },
    downloadFile (file) { // 파일 다운로드 이벤트
      let url = ''
      url = this.$imagePath + file
      const path = require('path')
      const fileName = path.basename(file)
      window.open(url, '_blank')
      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }
      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', fileName)
        document.body.appendChild(element)
        element.click()
      })
    },
    imageDel (file) {
      const _this = this
      _this.data.image = _this.data.image.filter((element) => element.name !== file)
    },
    onInputImage () {
      if (this.$refs.submitImage.files.length + this.data.image.length > 10) {
        alert(this.$t('warranty.alert.file1'))
        return false
      } else {
        for (var i = 0; i < this.$refs.submitImage.files.length; i++) {
          if (this.$refs.submitImage.files[i].size > 5000000) {
            alert(this.$t('warranty.alert.file2'))
            return false
          } else {
            this.data.image.push(this.$refs.submitImage.files[i])
          }
        }
      }
    },
    async clickSelectBox (txt, id, type) {
      if (type === 'userType') {
        this.regTxt = txt
        this.data.userType = id
      } else if (type === 'categoryId') {
        this.categoryTxt = txt
        this.data.categoryId = id
      } else if (type === 'country') {
        this.countryTxt = txt
        this.data.countryCd = id
        const stateList = await getCountryList.getStateList(id)
        this.stateList = stateList.data
        this.data.zipCd = ''
        if (id === 'US') {
          $("input:text[id='inp07']").off('keyup')
          $("input:text[id='inp07']").on('keyup', function () {
            $(this).val($(this).val().replace(/[^0-9]/g, ''))
          })
        } else {
          $("input:text[id='inp07']").off('keyup')
          $("input:text[id='inp07']").on('keyup', function () {
            $(this).val($(this).val().replace(/\s/g, ''))
          })
        }
      } else if (type === 'sinkType') {
        this.sinkTypeTxt = txt
        this.data.sinkType = id
      } else if (type === 'hearPath') {
        this.hearPathTxt = txt
        this.data.hearPath = id
      } else if (type === 'month') {
        this.installMonthTxt = txt
        this.data.installMonth = txt
      } else if (type === 'day') {
        this.installDayTxt = txt
        this.data.installDay = txt
      } else if (type === 'year') {
        this.installYearTxt = txt
        this.data.installYear = txt
      } else if (type === 'state') {
        this.stateTxt = txt
        this.data.stateCd = id
      } else if (type === 'materials') {
        this.materialsTxt = txt
        this.data.chkMaterials = id
      }

      /* select box mobile */
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
    },

    // 확인창에서 어떤 버튼을 눌렀는지 체크
    async buttonCheck (check) {
      console.log('fffffffffffffff', check)
      // let check = $(".btn").val();
      if (check === 'yes') {
        window.open('https://creatorapp.zohopublic.com/granitegold/protection-plans/page-perma/Payment_Page_Consumer/3N9gfSXS6sAP560TZKap72Q3sqwAOOVExbunDhqm3GhWUpPjR9ZbkNh5BHf61ZH5eFpWVYFkYGghsFfbfqg6e4KX0BDVp2KE844C?ZCRM_ID=4002819000102200004', '_blank')
      }
      tmpClose('layer-result2')// 확인 팝업 닫기
      tmpPopupClose()// 확인 팝업 닫기
      tmpPopup('layer-result')// 완료 팝업 호출
    },

    async submitForm () {
      // const target = document.getElementById('submitForm');
      // target.disabled = true;
      const _this = this
      const formData = new FormData()

      _this.submitChk = 'Y'
      // const imagefile = document.querySelector('#file01');

      formData.append('userType', _this.data.userType)
      formData.append('prdCategory', _this.data.categoryId)
      formData.append('color', _this.data.color)
      formData.append('firstNm', _this.data.firstNm)
      formData.append('lastNm', _this.data.lastNm)
      formData.append('addr1', _this.data.addr1)
      formData.append('addr2', _this.data.addr2)
      formData.append('countryCd', _this.data.countryCd)
      formData.append('stateCd', _this.data.stateCd)
      formData.append('city', _this.data.city)
      formData.append('zipCd', _this.data.zipCd)
      formData.append('email', _this.data.email)
      formData.append('phone', _this.data.phone)
      formData.append('dealer', _this.data.dealer)
      formData.append('dealerCity', _this.data.dealerCity)
      formData.append('dealerPhone', _this.data.dealerPhone)
      formData.append('installer', _this.data.installer)
      formData.append('installerPhone', _this.data.installerPhone)
      formData.append('installYear', _this.data.installYear)
      formData.append('installMonth', _this.data.installMonth)
      formData.append('installDay', _this.data.installDay)
      formData.append('memo', _this.data.memo)
      // formData.append("overallCost", this.data.overallCost);
      // formData.append("describe", this.data.describe);
      formData.append('sinkType', _this.data.sinkType)
      formData.append('hearPath', _this.data.hearPath)

      if (_this.data.image.length > -1) {
        for (let i = 0; i < this.data.image.length; i++) {
          const imageForm = _this.data.image[i]
          formData.append('receipt', imageForm)
        }
      }
      if (_this.data.image.length === 0) {
        return false
      }

      var emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식
      // var regExp = /^\d{2,3}-\d{3,4}-\d{4}$/;
      for (const pair of formData.entries()) {
        if (pair[0] != 'addr2' && pair[0] != 'memo') {
          if (pair[0] == 'email') {
            if (!emailRule.test(pair[1])) {
              this.emailValidTxt = false
              alert(this.$t('warranty.alert.email'))
              return false
            }
          } else if (pair[1] == '') {
            console.log(pair[0])
            return false
          }
          this.emailValidTxt = true
        }
      }
      await UserService.sendEmailWarranty(this.emailValidTxt, this.data.chkMaterials, formData)
      const result = await SupportService.saveWarranty(formData, this.data.chkMaterials)

      if (result.data.success === true) {
        // alert(this.$t('warranty.alert.success'))
        // location.reload();
        tmpPopup('layer-result2')// 확인 팝업 닫기
        /* if(target.disabled){
          target.disabled = false;
        } */
      } else {
        alert(this.$t('warranty.alert.fail'))
      }
    },
    fntab: async function (event, data) {
      this.selectedContentNm = event.currentTarget.innerText
      this.pageIndex = 1
      if (this.nationCode == '_GL' || this.nationCode == '_US') {
        if (this.selectedContentNm === 'HFLOR') {
          $('.btn-tab').removeClass('on')
          // 국가별로 변경 + added
          $('#WARRANTY_HFLOR' + this.nationCode).addClass('on')
          this.selectedContentId = await ProductService.getCategoryCode('WARRANTY_HFLOR' + this.nationCode)
          this.categoryId = this.selectedContentId.data.object.id

          this.warrantyToRegister = false
        } else if (this.selectedContentNm === 'Viatera' || this.selectedContentNm === 'HIMACS' || this.selectedContentNm === 'VIATERA' || this.selectedContentNm === 'TERACANTO' || this.selectedContentNm === 'HIMACS Sink' || this.selectedContentNm === 'HIMACS SINK') {
          $('.tab-list').hide()
          this.categoryId = data.id
        } else {
          this.categoryId = data.id
        }
      } else {
        this.categoryId = data.id
      }

      const warrantyList = await ProductService.getContentListTap([this.categoryId], this.dicValue, '', 1, 5)
      this.totalPage = warrantyList.data.totalPage
      this.totalCount = warrantyList.data.totalCount

      this.warrantyList = warrantyList.data.list
      const docCateId = await ProductService.getCategoryListAttr(this.categoryId)
      this.contentNeeded = docCateId.data.attribute.warranty_content

      this.pagination()
    },

    async hasIndex (index) {
      const _this = this

      if (index > 1) {
        if (index <= _this.totalPage) {
          _this.pageIndex = index
          const warrantyList = await ProductService.getContentListTap([_this.categoryId], this.dicValue, '', _this.pageIndex, _this.pageSize)
          _this.warrantyList = warrantyList.data.list
          _this.totalPage = warrantyList.data.totalPage
          _this.totalCount = warrantyList.data.totalCount
        }
      } else {
        _this.pageIndex = 1
        const warrantyList = await ProductService.getContentListTap([_this.categoryId], this.dicValue, '', 1, _this.pageSize)
        _this.warrantyList = warrantyList.data.list
        _this.totalPage = warrantyList.data.totalPage
        _this.totalCount = warrantyList.data.totalCount
      }

      _this.pagination()
    },

    pagination () {
      const _this = this
      const pageGroup = Math.ceil(_this.pageIndex / 10)
      let lastBottomNumber = pageGroup * 10 // 하단 마지막 숫자
      const first = lastBottomNumber - 9
      if (lastBottomNumber > _this.totalPage) {
        lastBottomNumber = _this.totalPage // 총 갯수보다 큰 경우 방지
      }
      _this.firstNumber = first
      _this.lastNumber = lastBottomNumber
      var list = []
      for (var i = _this.firstNumber; i <= _this.lastNumber; i++) {
        list.push(i)
      }
      _this.pageList = list

      _this.$nextTick(function () {
        $('.btn-num').removeClass('on')
        $('#index_' + _this.pageIndex).addClass('on')

        // 공유하기
        // $('.sns-wrp .sns').each(function(idx,ele){
        //   var $this = $(this);
        //   $(ele).prev().off("click");
        //   $this.off();
        // })
        // $('.sns-wrp .sns').each(function(idx,ele){
        //   var $this = $(this);
        //   $(ele).prev().click(function(){
        //     $this.slideToggle(300);
        //     $this.closest('tr').siblings().children().find('.sns').hide();
        //   });
        // })
      })
    },

    download: function (file) {
      let url = ''
      let type = file
      if (this.countryCode == '_US' || this.countryCode == '_GL') {
        url = this.$imagePath + file
      } else {
        type = $('#select_' + file).val()
        url = this.$imagePath + type
      }

      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }

      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', type)
        document.body.appendChild(element)
        element.click()
      })
    },
    goWarrantyClaim () {
      location.href = 'support/warranty-claim'
    }
  }
}
</script>
