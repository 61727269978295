var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content content-finder" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("PCD.text.home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.hflor")))]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container prod-fd-view container_pf hflor-finder" },
          [
            _c("div", { staticClass: "visual visual-detail" }, [
              _vm.pfBanImg
                ? _c(
                    "div",
                    {
                      staticClass: "visual-image",
                      style: {
                        backgroundImage:
                          "url( " + _vm.$imagePath + _vm.pfBanImg + ")",
                      },
                    },
                    [
                      _c("div", { staticClass: "txt-box" }, [
                        _c("h1", { staticClass: "t-type-04" }, [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("HFLOR - "),
                          ]),
                          _vm._v(" " + _vm._s(_vm.subject) + " "),
                        ]),
                        _vm.brief != null
                          ? _c("p", {
                              staticClass: "t-type-05",
                              domProps: { innerHTML: _vm._s(_vm.brief) },
                            })
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-more-point",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goLink()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PCD.button.learnMore"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "bgdim" }),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "drop-list inner" }, [
              _c("div", { staticClass: "w-con-02" }, [
                _c("div", { staticClass: "gallery" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c("div", { staticClass: "filter_area" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "compare_wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.compareOn,
                              expression: "compareOn",
                            },
                          ],
                          staticClass: "compare_btn",
                          attrs: {
                            type: "checkbox",
                            id: "compare_btn",
                            name: "compare_button",
                            value: "",
                            "true-value": "true",
                            "false-value": "false",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.compareOn)
                              ? _vm._i(_vm.compareOn, "") > -1
                              : _vm._q(_vm.compareOn, "true"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.compareOn,
                                $$el = $event.target,
                                $$c = $$el.checked ? "true" : "false"
                              if (Array.isArray($$a)) {
                                var $$v = "",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.compareOn = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.compareOn = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.compareOn = $$c
                              }
                            },
                          },
                        }),
                        _vm._m(1),
                        _c("span", [_vm._v("Compare")]),
                      ]),
                      _c("div", { staticClass: "search" }, [
                        _c("input", {
                          attrs: {
                            type: "text",
                            id: "textSearch",
                            placeholder: this.$t("PF.text.searchText"),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.textSearch($event)
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "icon-search gray",
                            attrs: { type: "button", id: "searchBtn" },
                            on: {
                              click: function ($event) {
                                return _vm.textSearchDel()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("PF.button.search")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "filter_pop" }, [
                      _c("div", { staticClass: "filter_top" }, [
                        !_vm.isMobile()
                          ? _c("ul", [
                              _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("span", { staticClass: "tt-sub" }, [
                                    _vm._v(_vm._s(_vm.$t("PCD.text.hflor"))),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "i-box type01 cont",
                                    staticStyle: { display: "block" },
                                  },
                                  [
                                    _c("ul", [
                                      _c("li", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "inp-rdo",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickCate(
                                                  "FLOOR_LUX"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "radio",
                                                name: "rdo00",
                                              },
                                              domProps: {
                                                checked:
                                                  _vm.categoryCode ===
                                                  "FLOOR_LUX" + _vm.nationCode,
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.button.lux"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "inp-rdo",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickCate(
                                                  "FLOOR_HOM"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "radio",
                                                name: "rdo00",
                                              },
                                              domProps: {
                                                checked:
                                                  _vm.categoryCode ===
                                                  "FLOOR_HOM" + _vm.nationCode,
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.button.hom"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "inp-rdo",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickCate(
                                                  "FLOOR_HET"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "radio",
                                                name: "rdo00",
                                              },
                                              domProps: {
                                                checked:
                                                  _vm.categoryCode ===
                                                  "FLOOR_HET" + _vm.nationCode,
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.button.het"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "inp-rdo",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickCate(
                                                  "FLOOR_SPT"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "radio",
                                                name: "rdo00",
                                              },
                                              domProps: {
                                                checked:
                                                  _vm.categoryCode ===
                                                  "FLOOR_SPT" + _vm.nationCode,
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.button.spt"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("li", { staticClass: "on" }, [
                                        _c("div", { staticClass: "tit" }, [
                                          _c(
                                            "span",
                                            { staticClass: "tt-sub" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PCD.text.collection")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-view btn-collection",
                                              attrs: { type: "button" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PCD.text.collection")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "i-box type02 cont",
                                            staticStyle: { display: "block" },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.leftCltList,
                                                function (clt) {
                                                  return _c("li", [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "inp-chk",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "Collection",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id: clt.category
                                                              .code,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              ;[
                                                                _vm.fnCateSearch(
                                                                  clt.category
                                                                    .name,
                                                                  clt.category
                                                                    .id,
                                                                  "",
                                                                  clt.category
                                                                    .code
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              clt.category.name
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm.leftPattns.length > 0
                                        ? _c("li", { staticClass: "on" }, [
                                            _c("div", { staticClass: "tit" }, [
                                              _c(
                                                "span",
                                                { staticClass: "tt-sub" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("PF.text.patterns")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-view btn-patterns",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("PF.text.patterns")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "i-box type02 cont",
                                                staticStyle: {
                                                  display: "block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass: "i-chk type02",
                                                  },
                                                  _vm._l(
                                                    _vm.leftPattns,
                                                    function (pattn) {
                                                      return _c("li", [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "inp-chk chk-thumb",
                                                          },
                                                          [
                                                            _c("input", {
                                                              staticClass:
                                                                "pattern",
                                                              attrs: {
                                                                type: "checkbox",
                                                                id:
                                                                  _vm.cateCode +
                                                                  "_pattern_" +
                                                                  pattn.id
                                                                    .value,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    ;[
                                                                      _vm.fnSearch(
                                                                        "pattern",
                                                                        pattn.text,
                                                                        pattn.id
                                                                          .value,
                                                                        "Pattern",
                                                                        _vm.cateCode
                                                                      ),
                                                                    ]
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v("> "),
                                                            _c("span", [
                                                              _c("i", {
                                                                style:
                                                                  "background-image : url(images/products/" +
                                                                  _vm.categoryCode +
                                                                  "_" +
                                                                  pattn.id
                                                                    .value +
                                                                  ".jpg)",
                                                              }),
                                                              _vm._v(
                                                                _vm._s(
                                                                  pattn.text
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("li", { staticClass: "on" }, [
                                        _c("div", { staticClass: "tit" }, [
                                          _c(
                                            "span",
                                            { staticClass: "tt-sub" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.text.new"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-view btn-new",
                                              attrs: { type: "button" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PF.text.new"))
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "i-box type02 cont",
                                            staticStyle: { display: "block" },
                                          },
                                          [
                                            _c("ul", [
                                              _c("li", [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "inp-chk type03",
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "checkbox",
                                                        id:
                                                          _vm.cateCode +
                                                          "_isNew_true",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          ;[
                                                            _vm.fnSearch(
                                                              "isNew",
                                                              "NEW COLORS",
                                                              "true",
                                                              "New",
                                                              _vm.cateCode
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PF.text.new_colors"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _vm.leftPrdType.length != 0 &&
                                      (_vm.cateCode === "FLOOR_LUX" ||
                                        _vm.cateCode === "FLOOR_HET")
                                        ? _c("li", { staticClass: "on" }, [
                                            _c("div", { staticClass: "tit" }, [
                                              _c(
                                                "span",
                                                { staticClass: "tt-sub" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PF.text.product_type"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-view btn-productType",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PF.text.product_type"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm.categoryCode === "FLOOR_HET_US"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "i-box type02 cont",
                                                    staticStyle: {
                                                      display: "block",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      { staticClass: "i-chk" },
                                                      _vm._l(
                                                        _vm.leftPrdType,
                                                        function (prdType) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "ProductType",
                                                              attrs: {
                                                                "data-chk":
                                                                  prdType.id
                                                                    .value,
                                                              },
                                                            },
                                                            [
                                                              prdType.text ===
                                                                "Commercial" ||
                                                              prdType.text ===
                                                                "Heavy Commercial"
                                                                ? _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "inp-chk",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          staticClass:
                                                                            "prdType",
                                                                          attrs:
                                                                            {
                                                                              type: "checkbox",
                                                                              id:
                                                                                _vm.cateCode +
                                                                                "_prdType_" +
                                                                                prdType
                                                                                  .id
                                                                                  .value,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                ;[
                                                                                  _vm.fnSearch(
                                                                                    "prdType",
                                                                                    prdType.text,
                                                                                    prdType
                                                                                      .id
                                                                                      .value,
                                                                                    "PrdType",
                                                                                    _vm.cateCode
                                                                                  ),
                                                                                ]
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              prdType.text
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "i-box type02 cont",
                                                    staticStyle: {
                                                      display: "block",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      { staticClass: "i-chk" },
                                                      _vm._l(
                                                        _vm.leftPrdType,
                                                        function (prdType) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "ProductType",
                                                              attrs: {
                                                                "data-chk":
                                                                  prdType.id
                                                                    .value,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "inp-chk",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    staticClass:
                                                                      "prdType",
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      id:
                                                                        _vm.cateCode +
                                                                        "_prdType_" +
                                                                        prdType
                                                                          .id
                                                                          .value,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          ;[
                                                                            _vm.fnSearch(
                                                                              "prdType",
                                                                              prdType.text,
                                                                              prdType
                                                                                .id
                                                                                .value,
                                                                              "PrdType",
                                                                              _vm.cateCode
                                                                            ),
                                                                          ]
                                                                        },
                                                                    },
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        prdType.text
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                          ])
                                        : _vm._e(),
                                      _c("li", { staticClass: "on" }, [
                                        _c("div", { staticClass: "tit" }, [
                                          _c(
                                            "span",
                                            { staticClass: "tt-sub" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PF.text.thickness")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-view btn-thickness",
                                              attrs: { type: "button" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PF.text.thickness")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "i-box type02 cont",
                                            staticStyle: { display: "block" },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "i-chk" },
                                              _vm._l(
                                                _vm.leftThick,
                                                function (thick) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass: "Thickness",
                                                      attrs: {
                                                        "data-chk":
                                                          thick.id.value,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "inp-chk",
                                                        },
                                                        [
                                                          _c("input", {
                                                            staticClass:
                                                              "thick",
                                                            attrs: {
                                                              type: "checkbox",
                                                              id:
                                                                _vm.cateCode +
                                                                "_thick_" +
                                                                thick.id.value,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                ;[
                                                                  _vm.fnSearch(
                                                                    "thick",
                                                                    thick.text,
                                                                    thick.id
                                                                      .value,
                                                                    "Thick",
                                                                    _vm.cateCode
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(thick.text)
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm.leftWear.length != 0
                                        ? _c("li", { staticClass: "on" }, [
                                            _c("div", { staticClass: "tit" }, [
                                              _c(
                                                "span",
                                                { staticClass: "tt-sub" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PF.text.wear_layer"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-view btn-wareLayer",
                                                  attrs: { type: "button" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PF.text.wear_layer"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "i-box type02 cont",
                                                staticStyle: {
                                                  display: "block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  { staticClass: "i-chk" },
                                                  _vm._l(
                                                    _vm.leftWear,
                                                    function (wear) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "WearLayer",
                                                          attrs: {
                                                            "data-chk":
                                                              wear.id.value,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "inp-chk",
                                                            },
                                                            [
                                                              _c("input", {
                                                                staticClass:
                                                                  "wearLayer",
                                                                attrs: {
                                                                  type: "checkbox",
                                                                  id:
                                                                    _vm.cateCode +
                                                                    "_wearLayer_" +
                                                                    wear.id
                                                                      .value,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      ;[
                                                                        _vm.fnSearch(
                                                                          "wearLayer",
                                                                          wear.text,
                                                                          wear
                                                                            .id
                                                                            .value,
                                                                          "WearLayer",
                                                                          _vm.cateCode
                                                                        ),
                                                                      ]
                                                                    },
                                                                },
                                                              }),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    wear.text
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("li", { staticClass: "on" }, [
                                        _c("div", { staticClass: "tit" }, [
                                          _c(
                                            "span",
                                            { staticClass: "tt-sub" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PF.text.dimension")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-view btn-dimension",
                                              attrs: { type: "button" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PF.text.dimension")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "i-box type02 cont",
                                            staticStyle: { display: "block" },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "i-chk" },
                                              _vm._l(
                                                _vm.leftDimen,
                                                function (dimen) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass: "Dimension",
                                                      attrs: {
                                                        "data-chk":
                                                          dimen.id.value,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "inp-chk",
                                                        },
                                                        [
                                                          _c("input", {
                                                            staticClass:
                                                              "dimension",
                                                            attrs: {
                                                              type: "checkbox",
                                                              id:
                                                                _vm.cateCode +
                                                                "_dimension_" +
                                                                dimen.id.value,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                ;[
                                                                  _vm.fnSearch(
                                                                    "dimension",
                                                                    dimen.text,
                                                                    dimen.id
                                                                      .value,
                                                                    "Dimension",
                                                                    _vm.cateCode
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(dimen.text)
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "filter_bottom" }, [
                        _c("div", { staticClass: "btn_box" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn_wh",
                              on: { click: _vm.filterClearAll },
                            },
                            [_vm._v("Clear All")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn_bk",
                              on: { click: _vm.applyFilter },
                            },
                            [_vm._v("Apply")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "sort_area" }, [
                      _vm.colorList.length > 0
                        ? _c("div", { staticClass: "select-view" }, [
                            _c(
                              "ul",
                              { staticClass: "list-filter-key" },
                              [
                                _vm._l(_vm.colorList, function (color, index) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "sel",
                                      on: {
                                        click: function ($event) {
                                          ;[
                                            _vm.colorDelete(
                                              color.subject,
                                              color.name,
                                              color.id,
                                              color.code
                                            ),
                                          ]
                                        },
                                      },
                                    },
                                    [
                                      color.subject == "Collection"
                                        ? _c("span", [
                                            _vm._v(_vm._s(color.subject)),
                                          ])
                                        : _c("span", [
                                            _vm._v(_vm._s(color.type)),
                                          ]),
                                      _vm._v(" : "),
                                      _c("span", [
                                        _vm._v(" " + _vm._s(color.name) + " "),
                                      ]),
                                      _c(
                                        "button",
                                        { attrs: { type: "button" } },
                                        [_vm._v("✕")]
                                      ),
                                    ]
                                  )
                                }),
                                _c("li", { staticClass: "sel del_all" }, [
                                  _vm.colorList.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn-all-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearAll()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("PF.button.clear_all")
                                              ) + " "
                                            ),
                                            _c("em", [_vm._v("✕")]),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "btns" }, [
                        _c("ul", [
                          _c("li", { staticClass: "sel-box" }, [
                            _vm._m(2),
                            _c("div", { staticClass: "sel-list" }, [
                              _c("ul", { staticClass: "box" }, [
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortByChange(
                                            "Products A to Z",
                                            "asc",
                                            "title"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("PF.button.products_a_to_z")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortByChange(
                                            "Products Z to A",
                                            "desc",
                                            "title"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("PF.button.products_z_to_a")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortByChange(
                                            "New Products first",
                                            "desc"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "PF.button.new_products_first"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._m(3),
                      _vm.compareOn
                        ? _c(
                            "div",
                            {
                              staticClass: "more-box type02 type03 compare_box",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "button h55 btn_compare",
                                  attrs: { type: "button", id: "compare" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCompareList()
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("PF.button.compare_your_colors")
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "bubble_num" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.compareArr.length)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "count_area" }, [
                      _c("div", { staticClass: "filter-cnt" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.contentLength)),
                        ]),
                        _c("span", [_vm._v(" ")]),
                        _c("span", { staticClass: "txt" }, [_vm._v("Results")]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "filter-arae mb-only" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openSearchPopup("layer-search-box")
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("PF.button.filter_by"))),
                      _c("i"),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "list-type-view row-04" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.contentList, function (item, idx) {
                      return _c(
                        "li",
                        { key: idx, staticClass: "viatera-pfd hflor-pfd" },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "javascript:void(0)",
                                tabindex: "0",
                                "data-ec-product": _vm.makeDataLayerForm(item),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.$utils.gotoUrl(
                                    "products/hflor/" +
                                      _vm.makeSeoMainClt(_vm.categoryCode) +
                                      "/" +
                                      _vm.$utils.getSeoName(item, "content") +
                                      "/" +
                                      item.content.id
                                  )
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "img-box over" }, [
                                item.attribute.isNew == "true"
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h30 black" },
                                      [_vm._v(_vm._s(_vm.$t("PF.text.new")))]
                                    )
                                  : _vm._e(),
                                _c("span", { staticClass: "tag h22 hflor" }, [
                                  _vm._v("Hflor"),
                                ]),
                                _c("span", { staticClass: "image_name" }, [
                                  _vm._v(_vm._s(item.content.title)),
                                ]),
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.$imagePath + item.attribute.pf_thumb,
                                    alt: item.attribute.pf_thumb,
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "view" }, [
                            _vm.nationCode === "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-cart-shadow",
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openSampleOrder(
                                          item.content.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.cart")))]
                                )
                              : _vm._e(),
                            _vm.authenticated == true && _vm.nationCode == "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    class: { on: item.content.liked },
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLike(item, "HFLOR")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm._e(),
                            _vm.authenticated == false &&
                            _vm.nationCode == "_US"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    attrs: {
                                      tabindex: "0",
                                      href: "javascript:void(0)",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.alertAndGoLogin()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm._e(),
                            _vm.getSessionContent != null &&
                            _vm.nationCode == "_GL"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    class: {
                                      on: _vm.getSessionContent.includes(
                                        item.content.id
                                      ),
                                    },
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLike(item, "HFLOR")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm.getSessionContent == null &&
                                _vm.nationCode == "_GL"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "icon-wish-shadow",
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleLike(item, "HFLOR")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PCD.text.wish_list")))]
                                )
                              : _vm._e(),
                          ]),
                          _vm._m(4, true),
                          _c("div", { staticClass: "pf_cont_compare_wrap" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "item.content.id",
                              },
                            }),
                            _c("label", { staticClass: "inp-chk" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.compareArr,
                                    expression: "compareArr",
                                  },
                                ],
                                staticClass: "pf_cont_compare",
                                attrs: {
                                  type: "checkbox",
                                  id: item.content.id,
                                },
                                domProps: {
                                  value: item.content.id,
                                  checked: Array.isArray(_vm.compareArr)
                                    ? _vm._i(_vm.compareArr, item.content.id) >
                                      -1
                                    : _vm.compareArr,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.compareArr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.content.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.compareArr = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.compareArr = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.compareArr = $$c
                                      }
                                    },
                                    function ($event) {
                                      return _vm.addCompare(item.content.id)
                                    },
                                  ],
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.compare"))),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "short-link type02 mb-64 inner" }, [
                  _vm.nationCode === "_US"
                    ? _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.openCart()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "ico-01" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.order_a_sample"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.nationCode === "_GL"
                    ? _c("a", { attrs: { href: "support/contactus" } }, [
                        _c("span", { staticClass: "ico-07" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                        ]),
                      ])
                    : _vm._e(),
                  _c("a", { attrs: { href: "pros/documentation/hflor" } }, [
                    _c("span", { staticClass: "ico-02" }, [
                      _vm._v(_vm._s(_vm.$t("PCD.text.documentation"))),
                    ]),
                  ]),
                  _c(
                    "a",
                    { attrs: { href: "support/care-and-maintenance/hflor" } },
                    [
                      _c("span", { staticClass: "ico-03" }, [
                        _vm._v(_vm._s(_vm.$t("PCD.text.care_maintenance"))),
                      ]),
                    ]
                  ),
                  _vm.nationCode === "_US"
                    ? _c("a", { attrs: { href: "support/warranty/hflor" } }, [
                        _c("span", { staticClass: "ico-04" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.warranty"))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.nationCode === "_US"
                    ? _c(
                        "a",
                        { attrs: { href: "support/where-to-buy/country/us" } },
                        [
                          _c("span", { staticClass: "ico-05" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.where_to_buy"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.nationCode === "_GL"
                    ? _c(
                        "a",
                        { attrs: { href: "support/where-to-buy/country/gl" } },
                        [
                          _c("span", { staticClass: "ico-05" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.where_to_buy"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "layer-popup layer-alert type02" }, [
                  _c("div", { staticClass: "popup pop-close" }, [
                    _c("div", { staticClass: "pop-alert inner" }, [
                      _c("div", { staticClass: "t-wrap" }, [
                        _c("div", { staticClass: "tit-box" }, [
                          _c("p", { staticClass: "ctt ctt-t4 artTit" }, [
                            _vm._v(
                              _vm._s(_vm.$t("PF.popup.your_compare_is_full"))
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "txt-box" }, [
                          _c("i", { staticClass: "ico-alert" }),
                          _c("p", { staticClass: "tt-2" }, [
                            _vm._v(_vm._s(_vm.$t("PF.popup.compare_tool"))),
                          ]),
                          _c("div", { staticClass: "ctt ctt-t2" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("PF.popup.compare_alert1"))
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("PF.popup.compare_alert2")) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "more-box" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button", id: "replace" },
                            on: {
                              click: function ($event) {
                                return _vm.replace()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.popup.replace"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "alert_txt" }, [
                        _c(
                          "button",
                          {
                            attrs: { id: "button editCompare" },
                            on: {
                              click: function ($event) {
                                return _vm.getCompareList()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("PF.popup.edit_products_in_compare")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn-close btn",
                          attrs: {
                            type: "button",
                            onclick: "LayerPopup.close();",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("PF.popup.replace")))]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "layer-popup layer-compare euSite",
                    attrs: { id: "pdf_wrap" },
                  },
                  [
                    _c("div", { staticClass: "popup pop-close" }, [
                      _c("div", { staticClass: "pop-compare scr-y" }, [
                        _c("p", { staticClass: "tt-sub-gray" }, [
                          _vm._v(
                            _vm._s(_vm.$t("PF.popup.compare_hflor_products"))
                          ),
                        ]),
                        _c("p", { staticClass: "ta-c" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "PF.popup.characteristics_technical_performance"
                              )
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "prod-list tbl-compare" }, [
                          _c("table", [
                            _c("caption", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "PF.popup.characteristics_technical_performance"
                                  )
                                )
                              ),
                            ]),
                            _vm._m(5),
                            _c("tbody", [
                              _c(
                                "tr",
                                [
                                  _vm._l(_vm.compareList, function (item, idx) {
                                    return _c("td", [
                                      _c("div", { staticClass: "prod-box" }, [
                                        _c("div", { staticClass: "prod" }, [
                                          _c(
                                            "div",
                                            { staticClass: "img-box over" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.$imagePath +
                                                    item.attribute.pf_thumb,
                                                  alt: item.content.title,
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "popinfo" }, [
                                          _c("ul", [
                                            _c(
                                              "li",
                                              { staticClass: "tt-sub" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.content.title)
                                                ),
                                              ]
                                            ),
                                            item.attribute.disp_code !=
                                            undefined
                                              ? _c(
                                                  "li",
                                                  { staticClass: "ctt ctt-t4" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.disp_code
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "li",
                                                  { staticClass: "ctt ctt-t4" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attribute.code
                                                      )
                                                    ),
                                                  ]
                                                ),
                                            _c(
                                              "li",
                                              { staticClass: "txt-01" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.parents[0].name)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              { staticClass: "txt-01" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.parents[1].name)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-remove02",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeCompare(
                                                    item.content.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PF.button.remove")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "tbl-box type02" },
                                        [
                                          _c("table", [
                                            _c("caption", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PF.popup.characteristics_technical_performance"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._m(6, true),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PF.text.product_type"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              item.attribute.prdTypeText !=
                                              undefined
                                                ? _c("tr", [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.attribute.prdTypeText.join(
                                                            ", "
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ])
                                                : _c("td", [_vm._v(_vm._s())]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PCD.text.collection"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.parents[0].name)
                                                  ),
                                                ]),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.text.total_thickness"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                item.attribute.thickWearLayer !=
                                                undefined
                                                  ? _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .getThick(
                                                                item.attribute
                                                                  .thickWearLayer
                                                              )
                                                              .join(", ")
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.attribute.thickText.join(
                                                            ", "
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.text.wear_layer"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                item.attribute.thickWearLayer !=
                                                undefined
                                                  ? _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .getWearLayer(
                                                                item.attribute
                                                                  .thickWearLayer
                                                              )
                                                              .join(", ")
                                                          )
                                                      ),
                                                    ])
                                                  : _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.attribute
                                                            .wearLayerText
                                                        )
                                                      ),
                                                    ]),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.text.pattern"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                item.attribute.patternText !=
                                                null
                                                  ? _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.attribute.patternText.join(
                                                            ", "
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.attribute.patternText ==
                                                null
                                                  ? _c("td", [_vm._v(_vm._s())])
                                                  : _vm._e(),
                                              ]),
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.text.dimension"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("tr", [
                                                item.attribute.dimensionText !=
                                                null
                                                  ? _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.attribute.dimensionText.join(
                                                            ", "
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.attribute.dimensionText ==
                                                null
                                                  ? _c("td", [_vm._v(_vm._s())])
                                                  : _vm._e(),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  _vm.compareList.length < 4
                                    ? _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "btn-add hf-btn-add" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  onclick:
                                                    "LayerPopup.close();",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PF.button.add_more"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "btn-download" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn dwn load",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.makePDF()
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.download"))),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn-close",
                            attrs: {
                              type: "button",
                              onclick: "LayerPopup.close();",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("PCD.button.close")))]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
                    staticStyle: { display: "none" },
                    attrs: { id: "layer-search-box" },
                  },
                  [
                    _c("div", { staticClass: "popup" }, [
                      _c("div", { staticClass: "scr-y" }, [
                        _c("div", { staticClass: "drop-list" }, [
                          _c("p", { staticClass: "tt-2" }, [
                            _vm._v(_vm._s(_vm.$t("PF.button.filter_by"))),
                          ]),
                          _c("button", {
                            staticClass: "filter-clos",
                            on: {
                              click: function ($event) {
                                return _vm.closeSearchPopup()
                              },
                            },
                          }),
                          _c("ul", [
                            _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PCD.text.hflor"))),
                                ]),
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(_vm._s(_vm.$t("PD.button.list_view"))),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type01 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "inp-rdo",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickCate("FLOOR_LUX")
                                            },
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "radio",
                                              name: "rdo01",
                                            },
                                            domProps: {
                                              checked:
                                                _vm.categoryCode ===
                                                "FLOOR_LUX" + _vm.nationCode,
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.button.lux"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "inp-rdo",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickCate("FLOOR_HOM")
                                            },
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "radio",
                                              name: "rdo01",
                                            },
                                            domProps: {
                                              checked:
                                                _vm.categoryCode ===
                                                "FLOOR_HOM" + _vm.nationCode,
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.button.hom"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "inp-rdo",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickCate("FLOOR_HET")
                                            },
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "radio",
                                              name: "rdo01",
                                            },
                                            domProps: {
                                              checked:
                                                _vm.categoryCode ===
                                                "FLOOR_HET" + _vm.nationCode,
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.button.het"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "inp-rdo",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickCate("FLOOR_SPT")
                                            },
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "radio",
                                              name: "rdo01",
                                            },
                                            domProps: {
                                              checked:
                                                _vm.categoryCode ===
                                                "FLOOR_SPT" + _vm.nationCode,
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.button.spt"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-collection",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("PCD.text.collection"))
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.leftCltList, function (clt) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "Collection",
                                              attrs: {
                                                type: "checkbox",
                                                id: clt.category.code,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnCateSearch(
                                                      clt.category.name,
                                                      clt.category.id,
                                                      "",
                                                      clt.category.code
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(clt.category.name)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                            _vm.leftPattns.length > 0
                              ? _c("li", { staticClass: "on" }, [
                                  _c("div", { staticClass: "tit" }, [
                                    _c("span", { staticClass: "tt-sub" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("PF.text.patterns"))
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-view btn-patterns",
                                        attrs: { type: "button" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("PF.text.patterns"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "i-box type02 cont",
                                      staticStyle: { display: "block" },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "i-chk type02" },
                                        _vm._l(
                                          _vm.leftPattns,
                                          function (pattn) {
                                            return _c("li", [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "inp-chk chk-thumb",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "pattern",
                                                    attrs: {
                                                      type: "checkbox",
                                                      id:
                                                        _vm.cateCode +
                                                        "_pattern_" +
                                                        pattn.id.value,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        ;[
                                                          _vm.fnSearch(
                                                            "pattern",
                                                            pattn.text,
                                                            pattn.id.value,
                                                            "Pattern",
                                                            _vm.cateCode
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  }),
                                                  _vm._v("> "),
                                                  _c("span", [
                                                    _c("i", {
                                                      style:
                                                        "background-image : url(images/products/" +
                                                        _vm.categoryCode +
                                                        "_" +
                                                        pattn.id.value +
                                                        ".jpg)",
                                                    }),
                                                    _vm._v(_vm._s(pattn.text)),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PF.text.new"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-new",
                                    attrs: { type: "button" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PF.text.new")))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "inp-chk type03" },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              id: _vm.cateCode + "_isNew_true",
                                            },
                                            on: {
                                              click: function ($event) {
                                                ;[
                                                  _vm.fnSearch(
                                                    "isNew",
                                                    "NEW COLORS",
                                                    "true",
                                                    "New",
                                                    _vm.cateCode
                                                  ),
                                                ]
                                              },
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("PF.text.new_colors")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm.leftPrdType.length != 0 &&
                            (_vm.cateCode === "FLOOR_LUX" ||
                              _vm.cateCode === "FLOOR_HET")
                              ? _c("li", { staticClass: "on" }, [
                                  _c("div", { staticClass: "tit" }, [
                                    _c("span", { staticClass: "tt-sub" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("PF.text.product_type"))
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-view btn-productType",
                                        attrs: { type: "button" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("PF.text.product_type"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm.categoryCode === "FLOOR_HET_US"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "i-box type02 cont",
                                          staticStyle: { display: "block" },
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "i-chk" },
                                            _vm._l(
                                              _vm.leftPrdType,
                                              function (prdType) {
                                                return _c(
                                                  "li",
                                                  {
                                                    staticClass: "ProductType",
                                                    attrs: {
                                                      "data-chk":
                                                        prdType.id.value,
                                                    },
                                                  },
                                                  [
                                                    prdType.text ===
                                                      "Commercial" ||
                                                    prdType.text ===
                                                      "Heavy Commercial"
                                                      ? _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "inp-chk",
                                                          },
                                                          [
                                                            _c("input", {
                                                              staticClass:
                                                                "prdType",
                                                              attrs: {
                                                                type: "checkbox",
                                                                id:
                                                                  _vm.cateCode +
                                                                  "_prdType_" +
                                                                  prdType.id
                                                                    .value,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    ;[
                                                                      _vm.fnSearch(
                                                                        "prdType",
                                                                        prdType.text,
                                                                        prdType
                                                                          .id
                                                                          .value,
                                                                        "PrdType",
                                                                        _vm.cateCode
                                                                      ),
                                                                    ]
                                                                  },
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  prdType.text
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "i-box type02 cont",
                                          staticStyle: { display: "block" },
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "i-chk" },
                                            _vm._l(
                                              _vm.leftPrdType,
                                              function (prdType) {
                                                return _c(
                                                  "li",
                                                  {
                                                    staticClass: "ProductType",
                                                    attrs: {
                                                      "data-chk":
                                                        prdType.id.value,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass: "inp-chk",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "prdType",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              _vm.cateCode +
                                                              "_prdType_" +
                                                              prdType.id.value,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              ;[
                                                                _vm.fnSearch(
                                                                  "prdType",
                                                                  prdType.text,
                                                                  prdType.id
                                                                    .value,
                                                                  "PrdType",
                                                                  _vm.cateCode
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(prdType.text)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                ])
                              : _vm._e(),
                            _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PF.text.thickness"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-thickness",
                                    attrs: { type: "button" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PF.text.thickness")))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(_vm.leftThick, function (thick) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass: "Thickness",
                                          attrs: { "data-chk": thick.id.value },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "inp-chk" },
                                            [
                                              _c("input", {
                                                staticClass: "thick",
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    _vm.cateCode +
                                                    "_thick_" +
                                                    thick.id.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "thick",
                                                        thick.text,
                                                        thick.id.value,
                                                        "Thick",
                                                        _vm.cateCode
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(thick.text)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                            _vm.leftWear.length != 0
                              ? _c("li", { staticClass: "on" }, [
                                  _c("div", { staticClass: "tit" }, [
                                    _c("span", { staticClass: "tt-sub" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("PF.text.wear_layer"))
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-view btn-wareLayer",
                                        attrs: { type: "button" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("PF.text.wear_layer"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "i-box type02 cont",
                                      staticStyle: { display: "block" },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "i-chk" },
                                        _vm._l(_vm.leftWear, function (wear) {
                                          return _c(
                                            "li",
                                            {
                                              staticClass: "WearLayer",
                                              attrs: {
                                                "data-chk": wear.id.value,
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "inp-chk" },
                                                [
                                                  _c("input", {
                                                    staticClass: "wearLayer",
                                                    attrs: {
                                                      type: "checkbox",
                                                      id:
                                                        _vm.cateCode +
                                                        "_wearLayer_" +
                                                        wear.id.value,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        ;[
                                                          _vm.fnSearch(
                                                            "wearLayer",
                                                            wear.text,
                                                            wear.id.value,
                                                            "WearLayer",
                                                            _vm.cateCode
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(_vm._s(wear.text)),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("li", { staticClass: "on" }, [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", { staticClass: "tt-sub" }, [
                                  _vm._v(_vm._s(_vm.$t("PF.text.dimension"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-dimension",
                                    attrs: { type: "button" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("PF.text.dimension")))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(_vm.leftDimen, function (dimen) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass: "Dimension",
                                          attrs: { "data-chk": dimen.id.value },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "inp-chk" },
                                            [
                                              _c("input", {
                                                staticClass: "dimension",
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    _vm.cateCode +
                                                    "_dimension_" +
                                                    dimen.id.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "dimension",
                                                        dimen.text,
                                                        dimen.id.value,
                                                        "Dimension",
                                                        _vm.cateCode
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(dimen.text)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_wrap" }, [
      _c("button", { staticClass: "btn_filter", attrs: { type: "button" } }, [
        _c("span", { staticClass: "icon icon_filter" }),
        _c("span", { staticClass: "txt" }, [_vm._v("Filter")]),
        _c("span", { staticClass: "icon icon_arrow_down" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "compare_btn" } }, [
      _c("span", { staticClass: "txt" }, [_vm._v("ON")]),
      _c("span", { staticClass: "btn" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn-sel", attrs: { type: "button" } }, [
      _c("span", [_vm._v("Newest")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gallery_layout_btns" }, [
      _c(
        "button",
        { staticClass: "layout_grid on", attrs: { type: "button" } },
        [_c("span", { staticClass: "icon icon_grid" })]
      ),
      _c("button", { staticClass: "layout_list", attrs: { type: "button" } }, [
        _c("span", { staticClass: "icon icon_list" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon_tag_wrap" }, [
      _c("i", { staticClass: "icon-no-fire" }, [
        _c("span", [_vm._v("no-fire")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }