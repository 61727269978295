var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "eco-friendly-certification-card" }, [
    _c("pre", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("img", { staticClass: "card-image", attrs: { src: _vm.image } }),
    _c("div", { staticClass: "card-text-area" }, [
      _c("pre", { staticClass: "card-text" }, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }