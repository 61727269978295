<template>
  <OverviewSmallSection
  title="Customer Values"
  fontFamily="Lato"
  fontWeight="700"
  v-if="nationCode == '_US' || nationCode == '_GL'">
    <OverviewSectionItem>
      <div class="card-area">
        <!-- 왼쪽 -->
        <div class="left-card">
          <div class="text-area">
            <div class="child">
              <CustomerValuesText
                title="Eco - friendly"
                desc="Shift to eco-friendly materials by improving diversity in material use (Expand eco-friendly products/materials)"
                descWidth="90%"
              />
            </div>
            <div class="child">
              <CustomerValuesText
                title="Energy Saving"
                desc="Deliver systems & solutions that maximize energy efficiency (High-quality windows/green car materials)"
                descWidth="65%"
              />
            </div>
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/left-card-icon.png" alt="cardicon">
          </div>
        </div>
        <!-- 오른쪽 -->
        <div class="right-card">
          <div class="text-area">
            <CustomerValuesText
                title="Human – friendly"
                desc="Strengthen reliable and impressive contact points with customers (Reinforce brand power and innovation in distribution)"
                descWidth="65%"
              />
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/right-card-icon.png" alt="cardicon">
          </div>
        </div>
      </div>
    </OverviewSectionItem>
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys pursues the three customer values of <b style="font-weight: 700;">Eco-Friendly, Energy Saving, and Human-Friendly.</b>
We provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,
and we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with
outstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,
we are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and
innovation in distribution to expand the customer communication touchpoints.</pre>
    </OverviewSectionItem>
  </OverviewSmallSection>
  <!-- JP -->
  <OverviewSmallSection
    title="顧客価値"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_JP'">
    <OverviewSectionItem>
      <div class="card-area">
        <!-- 왼쪽 -->
        <div class="left-card">
          <div class="text-area">
            <div class="child">
              <CustomerValuesText
                title="環境に優しい（Eco - friendly)"
                desc="素材の多様性向上による環境配慮型素材への転換（環境配慮型製品・素材の拡大）"
                descWidth="90%"
              />
            </div>
            <div class="child">
              <CustomerValuesText
                title="省エネ (Energy Saving)"
                desc="エネルギー効率を最大化するシステムとソリューションを提供（高品質の窓/環境に優しい自動車素材）"
                descWidth="65%"
              />
            </div>
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/left-card-icon.png" alt="cardicon">
          </div>
        </div>
        <!-- 오른쪽 -->
        <div class="right-card">
          <div class="text-area">
            <CustomerValuesText
              title="人間に優しい (Human – friendly)"
              desc="顧客との信頼感・感動接点の強化"
              descWidth="65%"
            />
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/right-card-icon.png" alt="cardicon">
          </div>
        </div>
      </div>
    </OverviewSectionItem>
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys pursues the three customer values of <b style="font-weight: 700;">Eco-Friendly, Energy Saving, and Human-Friendly.</b>
We provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,
and we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with
outstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,
we are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and
innovation in distribution to expand the customer communication touchpoints.</pre>
    </OverviewSectionItem>
  </OverviewSmallSection>
  <!-- RU -->
  <OverviewSmallSection
    title="Customer Values"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_RU'">
    <OverviewSectionItem>
      <div class="card-area">
        <!-- 왼쪽 -->
        <div class="left-card">
          <div class="text-area">
            <div class="child">
              <CustomerValuesText
                title="Экологичность"
                desc="Переход на экологически чистые материалы путем повышения разнообразия в использовании материалов (Расширение ассортимента экологически чистых продуктов/материалов)"
                descWidth="90%"
              />
            </div>
            <div class="child">
              <CustomerValuesText
                title="Энергосбережение"
                desc="Предоставление системных решений, максимально повышающих энергоэффективность (высококачественные окна/материалы для автомобилей)"
                descWidth="65%"
              />
            </div>
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/left-card-icon.png" alt="cardicon">
          </div>
        </div>
        <!-- 오른쪽 -->
        <div class="right-card">
          <div class="text-area">
            <CustomerValuesText
              title="Дружелюбность"
              desc="Укрепление надежных точек контакта с клиентами (Укрепление силы бренда и инновации в дистрибуции)"
              descWidth="65%"
            />
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/right-card-icon.png" alt="cardicon">
          </div>
        </div>
      </div>
    </OverviewSectionItem>
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys pursues the three customer values of <b style="font-weight: 700;">Eco-Friendly, Energy Saving, and Human-Friendly.</b>
We provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,
and we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with
outstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,
we are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and
innovation in distribution to expand the customer communication touchpoints.</pre>
    </OverviewSectionItem>
  </OverviewSmallSection>
  <!-- CN -->
  <OverviewSmallSection
    title="Customer Values"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_CN'">
    <OverviewSectionItem>
      <div class="card-area">
        <!-- 왼쪽 -->
        <div class="left-card">
          <div class="text-area">
            <div class="child">
              <CustomerValuesText
                title="Eco - friendly"
                desc="通过提高材料使用的多样性转向环保材料（扩大环保产品和材料）"
                descWidth="90%"
              />
            </div>
            <div class="child">
              <CustomerValuesText
                title="Energy Saving"
                desc="极大地发挥原效率地系统及解决方案（优质窗户/绿色环保汽车材料）"
                descWidth="65%"
              />
            </div>
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/left-card-icon.png" alt="cardicon">
          </div>
        </div>
        <!-- 오른쪽 -->
        <div class="right-card">
          <div class="text-area">
            <CustomerValuesText
              title="Human – friendly"
              desc="赋予信赖和感动的顾客结合点（品牌竞争力地增强和多种流通渠道地创新）"
              descWidth="65%"
            />
          </div>
          <div class="icon-area">
            <img src="images/aboutus/overview/vision_strategies/right-card-icon.png" alt="cardicon">
          </div>
        </div>
      </div>
    </OverviewSectionItem>
    <OverviewSectionItem>
      <pre class="ctt-pre ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys pursues the three customer values of <b style="font-weight: 700;">Eco-Friendly, Energy Saving, and Human-Friendly.</b>
We provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,
and we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with
outstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,
we are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and
innovation in distribution to expand the customer communication touchpoints.</pre>
    </OverviewSectionItem>
  </OverviewSmallSection>
</template>

<script>
import OverviewSectionItem from '../../components/OverviewSectionItem.vue';
import OverviewSmallSection from '../../components/OverviewSmallSection.vue';
import CustomerValuesText from './CustomerValuesText.vue';

export default {
  components: {
    OverviewSmallSection,
    OverviewSectionItem,
    CustomerValuesText
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.card-area {
  display: flex;
  flex-direction: row;
}

.left-card {
  width: 700px;
  background-color: #F3F2F1;
  margin-right: 30px;
  position: relative;
  border-radius: 20px;
}

.left-card .icon-area {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 80px;
  height: 80px;
}

.left-card .text-area {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-left: 50px;
  margin-bottom: 35px;
}

.left-card .text-area .child:first-child {
  margin-bottom: 25px;
}

.right-card {
  width: 700px;
  background-color: #F3F2F1;
  position: relative;
  border-radius: 20px;
}

.right-card .icon-area {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 80px;
  height: 80px;
}

.right-card .text-area {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-left: 50px;
  margin-bottom: 35px;
}

.ctt-t2 b {
  font-size: 20px;
}

@media (max-width: 768px) {
  .card-area {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .left-card {
    width: 90%;
    margin-right: 0px;
  }

  .right-card {
    width: 90%;
  }

  .left-card .icon-area {
    bottom: 10px;
    right: 10px;
    width: 55px;
    height: 55px;
  }

  .right-card .icon-area {
    bottom: 10px;
    right: 10px;
    width: 55px;
    height: 55px;
  }
  .ctt-t2 {
    text-align: center;
    white-space: pre-wrap;
    margin-left: 5%;
    margin-right: 5%;
  }

  .ctt-t2 b {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

}

</style>
