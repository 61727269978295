import request from './request'
const urlPrefix = ''

const EtcService = {
  newsletterSave (newsletter) {
    return request({
      url: `${urlPrefix}/newsletter/save`,
      method: 'post',
      data: newsletter
    })
  },
  saveDonotsell(data){
    return request({
      url: `${urlPrefix}/personal/save`,
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  saveCookieLog(cookieLog){
    return request({
      url: `${urlPrefix}/cookieLog/save`,
      method: 'post',
      data: cookieLog
    })
  }
}

export default EtcService
