var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content au-view newsEventArea" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("newsEvent.text.home")) + " ")]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("newsEvent.text.about_us")))]),
            _c("li", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("newsEvent.text.news")) +
                  " & " +
                  _vm._s(_vm.$t("newsEvent.text.event")) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "container au-box bbs-box au-box02" }, [
          _c("div", { staticClass: "sub_title_area full_width" }, [
            _c("div", { staticClass: "inner_width" }, [
              _c("h1", { staticClass: "page_title tt-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("newsEvent.text.news")) +
                    " & " +
                    _vm._s(_vm.$t("newsEvent.text.event")) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "newevent_slider_wrap full_width" }, [
            _c("div", { staticClass: "inner_width" }, [
              _vm.sliderNews.length > 0
                ? _c(
                    "div",
                    { staticClass: "center_slider" },
                    _vm._l(_vm.sliderNews, function (topic) {
                      return _c("div", { staticClass: "item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "item_link",
                            attrs: {
                              href:
                                "aboutus/newsNEvent/newsDetail/" +
                                topic.content.id,
                            },
                          },
                          [
                            _c("div", { staticClass: "img" }, [
                              _c("img", {
                                staticClass: "new_events_img",
                                attrs: {
                                  src:
                                    _vm.$imagePath +
                                    topic.attribute.pcThumbnail,
                                  alt: "LX Hausys",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "contents" }, [
                              _c("div", { staticClass: "txt_box" }, [
                                _c("p", { staticClass: "sort ctt ctt-t4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(topic.attribute.topicTypeText) +
                                      " "
                                  ),
                                ]),
                                _c("p", { staticClass: "title tt-30" }, [
                                  _vm._v(_vm._s(topic.content.title)),
                                ]),
                                _c("p", { staticClass: "date ctt ctt-t4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          topic.content.createDate,
                                          "MMM.YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "center_slider" },
                    _vm._l(_vm.recentNews, function (topic) {
                      return _c("div", { staticClass: "item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "item_link",
                            attrs: {
                              href:
                                "aboutus/newsNEvent/newsDetail/" +
                                topic.content.id,
                            },
                          },
                          [
                            _c("div", { staticClass: "img" }, [
                              _c("img", {
                                staticClass: "new_events_img",
                                attrs: {
                                  src:
                                    _vm.$imagePath +
                                    topic.attribute.pcThumbnail,
                                  alt: "LX Hausys",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "contents" }, [
                              _c("div", { staticClass: "txt_box" }, [
                                _c("p", { staticClass: "sort ctt ctt-t4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(topic.attribute.topicTypeText) +
                                      " "
                                  ),
                                ]),
                                _c("p", { staticClass: "title tt-30" }, [
                                  _vm._v(_vm._s(topic.content.title)),
                                ]),
                                _c("p", { staticClass: "date ctt ctt-t4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          topic.content.createDate,
                                          "MMM.YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
            ]),
          ]),
          _c("div", { staticClass: "w-con" }, [
            _c("div", { staticClass: "search-box" }, [
              _c("div", { staticClass: "filter_area" }, [
                _c("div", { staticClass: "filter_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn_filter",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clickFilterBtn()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "icon icon_filter" }),
                      _c("span", { staticClass: "txt p-ui" }, [
                        _vm._v("Filter"),
                      ]),
                      _c("span", { staticClass: "txt m-ui" }, [
                        _vm._v("Filter by"),
                      ]),
                      _c("span", { staticClass: "icon icon_arrow_down" }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchText,
                        expression: "searchText",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "textSearch",
                      placeholder: "Search News & Event",
                    },
                    domProps: { value: _vm.searchText },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchTopics.apply(null, arguments)
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.searchText = $event.target.value
                        },
                        _vm.searchTopics,
                      ],
                    },
                  }),
                  this.searchText.length !== 0
                    ? _c("button", {
                        staticClass: "btn_cancel",
                        attrs: { type: "button" },
                        on: { click: _vm.clearSearch },
                      })
                    : _c("button", {
                        staticClass: "btn_search",
                        attrs: { type: "button", id: "searchBtn" },
                        on: { click: _vm.searchTopics },
                      }),
                ]),
                _c("div", { staticClass: "filter_pop" }, [
                  _c("div", { staticClass: "filter_tit" }, [
                    _c("p", [_vm._v("FILTER BY")]),
                    _c("button", {
                      staticClass: "btn_close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.closeFilterPop()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "filter_top" }, [
                    _c("ul", [
                      _c("li", [
                        _vm._m(0),
                        _c("div", { staticClass: "filter_checkbox_cont" }, [
                          _c("div", { staticClass: "checkbox_box" }, [
                            _c(
                              "ul",
                              { staticClass: "checkbox_more no_line" },
                              [
                                _c("li", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.isCheckAll,
                                        expression: "isCheckAll",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "AllTopics",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.isCheckAll)
                                        ? _vm._i(_vm.isCheckAll, null) > -1
                                        : _vm.isCheckAll,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = _vm.isCheckAll,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.isCheckAll = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.isCheckAll = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.isCheckAll = $$c
                                          }
                                        },
                                        _vm.selectAllFilter,
                                      ],
                                    },
                                  }),
                                  _vm._m(1),
                                ]),
                                _vm._l(_vm.topicList, function (item) {
                                  return _c("li", { key: item.id.value }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectedMobTopic,
                                          expression: "selectedMobTopic",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "mobile_" + item.text,
                                      },
                                      domProps: {
                                        value: item.id.value,
                                        checked: Array.isArray(
                                          _vm.selectedMobTopic
                                        )
                                          ? _vm._i(
                                              _vm.selectedMobTopic,
                                              item.id.value
                                            ) > -1
                                          : _vm.selectedMobTopic,
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a = _vm.selectedMobTopic,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = item.id.value,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.selectedMobTopic =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.selectedMobTopic = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.selectedMobTopic = $$c
                                            }
                                          },
                                          _vm.selectMobTopic,
                                        ],
                                      },
                                    }),
                                    _c(
                                      "label",
                                      { attrs: { for: "mobile_" + item.text } },
                                      [
                                        _c("span", { staticClass: "round" }),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(_vm._s(item.text)),
                                        ]),
                                      ]
                                    ),
                                  ])
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "filter_bottom" }, [
                    _c("div", { staticClass: "btn_box" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_wh",
                          on: { click: _vm.clearAllFilter },
                        },
                        [_vm._v(" Clear All ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn_bk",
                          on: { click: _vm.searchByFilter },
                        },
                        [_vm._v(" Apply ")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "filter_dimmed" }),
              ]),
              _c("div", { staticClass: "sort_area" }, [
                _c("div", { staticClass: "filter_top" }, [
                  _c("ul", [
                    _c("li", [
                      _vm._m(2),
                      _c("div", { staticClass: "filter_checkbox_cont" }, [
                        _c("div", { staticClass: "checkbox_box" }, [
                          _c(
                            "div",
                            { staticClass: "checkbox_more" },
                            [
                              _c("span", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isCheckAll,
                                      expression: "isCheckAll",
                                    },
                                  ],
                                  attrs: { type: "checkbox", id: "All Topics" },
                                  domProps: {
                                    checked: Array.isArray(_vm.isCheckAll)
                                      ? _vm._i(_vm.isCheckAll, null) > -1
                                      : _vm.isCheckAll,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.isCheckAll,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.isCheckAll = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.isCheckAll = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.isCheckAll = $$c
                                        }
                                      },
                                      _vm.selectTopicAll,
                                    ],
                                  },
                                }),
                                _c("label", { attrs: { for: "All Topics" } }, [
                                  _vm._v("All"),
                                ]),
                              ]),
                              _vm._l(_vm.topicList, function (item) {
                                return _c("span", { key: item.id.value }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedTopic,
                                        expression: "selectedTopic",
                                      },
                                    ],
                                    attrs: { type: "checkbox", id: item.text },
                                    domProps: {
                                      value: item.id.value,
                                      checked: Array.isArray(_vm.selectedTopic)
                                        ? _vm._i(
                                            _vm.selectedTopic,
                                            item.id.value
                                          ) > -1
                                        : _vm.selectedTopic,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = _vm.selectedTopic,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = item.id.value,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selectedTopic = $$a.concat(
                                                  [$$v]
                                                ))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selectedTopic = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selectedTopic = $$c
                                          }
                                        },
                                        _vm.selectTopic,
                                      ],
                                    },
                                  }),
                                  _c("label", { attrs: { for: item.text } }, [
                                    _vm._v(_vm._s(item.text)),
                                  ]),
                                ])
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm.isMobile && _vm.selectedMobTopic.length > 0
                ? _c("div", { staticClass: "select-view" }, [
                    _c(
                      "ul",
                      { staticClass: "list-filter-key" },
                      [
                        _vm._l(_vm.selectedMobTopic, function (item) {
                          return _c(
                            "li",
                            {
                              key: item,
                              staticClass: "sel",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFilter(item)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "TOPIC : " +
                                    _vm._s(_vm.getTopicTextById(item))
                                ),
                              ]),
                              _c("button", { attrs: { type: "button" } }),
                            ]
                          )
                        }),
                        _c("li", { staticClass: "sel del_all" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn-all-delete",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFilter()
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.clear_all"))),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "count_area" }, [
                _c("div", { staticClass: "filter-cnt" }, [
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.resultCount)),
                  ]),
                  _c("span", [_vm._v(" ")]),
                  _c("span", { staticClass: "txt" }, [_vm._v("Results")]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "bbs-list" }, [
              _c(
                "ul",
                _vm._l(_vm.news, function (topic) {
                  return _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "item_link",
                        attrs: {
                          href:
                            "aboutus/newsNEvent/newsDetail/" + topic.content.id,
                        },
                      },
                      [
                        _c("div", { staticClass: "img-box" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.$imagePath + topic.attribute.pcThumbnail,
                              alt: "LX Hausys",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "txt-box" }, [
                          _c("ul", [
                            _c("li", { staticClass: "t-01" }, [
                              _vm._v(_vm._s(topic.attribute.topicTypeText)),
                            ]),
                            _c("li", { staticClass: "t-02" }, [
                              _c("strong", [
                                _vm._v(_vm._s(topic.content.title)),
                              ]),
                            ]),
                            _c("li", { staticClass: "t-03" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.truncate(topic.attribute.title, 250)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("li", { staticClass: "t-04" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      topic.content.createDate,
                                      "MMM.YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "btn_area" },
              [_vm.isLoadMoreLoading ? [_vm._m(3)] : _vm._e()],
              2
            ),
            _c("div", { staticClass: "btn_area" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isMore,
                      expression: "isMore",
                    },
                  ],
                  staticClass: "btn_load_more",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.showMore()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "txt" }, [_vm._v("Load more")]),
                  _c("span", { staticClass: "icon icon_plus" }),
                ]
              ),
            ]),
            _vm.showNoResult
              ? _c("div", { staticClass: "no_result" }, [
                  _c("img", {
                    attrs: {
                      src: "https://img.lxhausys.com/public/images/support/no_result.png",
                      alt: "noresult",
                    },
                  }),
                  _c("p", [_vm._v("No Result found")]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("TOPIC​")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "AllTopics" } }, [
      _c("span", { staticClass: "round" }),
      _c("span", { staticClass: "text" }, [_vm._v("All")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("TOPIC")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_area" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/public/images/icon/loading.gif",
          alt: "loading",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }