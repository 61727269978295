<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>FEATURE</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
            <span class="checkbox_more no_line">
              <li v-for="feature in featureList" :data-chk="feature.id.value">
                <input type="checkbox" :id="'features_' + feature.id.value" name="Feature" v-model="selected" :value="feature.id.value" />
                <label :for="'features_'+`${feature.id.value}`">
                <span></span>
                {{ feature.text }}
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'featureList'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: function () {
      this.$emit('update', this.selected)
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    EventBus.$on('reset-pf-filter', function () { _this.selected = [] })
    if (this.$store.state.filterState !== null) {
      this.selected = this.$store.state.filterState.feature
    }
  },
  methods: {
  }
}
</script>
