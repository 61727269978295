var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box height100 visual" }, [
    _c(
      "div",
      { staticClass: "slider-prod main-slider" },
      _vm._l(_vm.bannerList, function (banner) {
        return _c(
          "div",
          {
            staticClass: "slider",
            attrs: { value: banner.attribute.MAIN_VISUAL_PRODUCTText },
          },
          [
            _c("div", { staticClass: "txt-box" }, [
              !banner.imageLoadError
                ? _c("p", { staticClass: "img_wrap" }, [
                    _c("img", {
                      staticClass: "visual_logo",
                      attrs: {
                        src:
                          _vm.$imagePath +
                          "/2024/images/main/logo/" +
                          banner.attribute.MAIN_VISUAL_PRODUCTText +
                          ".svg",
                        alt: "logo",
                      },
                      on: {
                        error: function ($event) {
                          banner.imageLoadError = true
                        },
                        load: function ($event) {
                          banner.imageLoadError = false
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              banner.imageLoadError
                ? _c("p", {
                    staticClass: "txt_wrap",
                    domProps: { innerHTML: _vm._s(banner.content.title) },
                  })
                : _vm._e(),
              _c("p", {
                staticClass: "txt-01",
                domProps: {
                  innerHTML: _vm._s(banner.attribute.MAIN_VISUAL_SUB_TITLE),
                },
              }),
              _c(
                "a",
                {
                  staticClass: "btn-more",
                  on: {
                    click: function ($event) {
                      return _vm.openLink(banner)
                    },
                  },
                },
                [_c("span", [_vm._v("View more")])]
              ),
            ]),
            _c("div", { staticClass: "bannerGradient" }),
            banner.attribute.MAIN_VISUAL_SELECT == "001"
              ? _c("img", {
                  staticClass: "slider_bg",
                  attrs: {
                    src: _vm.$imagePath + banner.attribute.MAIN_VISUAL_IMAGE,
                    alt: "lxhausys",
                  },
                })
              : _vm._e(),
            banner.attribute.MAIN_VISUAL_SELECT == "002"
              ? _c("div", { staticClass: "video-wrapper" }, [
                  _c("video", {
                    staticClass: "slide_video",
                    attrs: {
                      src: _vm.$imagePath + banner.attribute.MAIN_VISUAL_VIDEO,
                      muted: "",
                      autoplay: "",
                      playsinline: "",
                      loop: "4",
                    },
                    domProps: { muted: true },
                  }),
                ])
              : _vm._e(),
            banner.attribute.MAIN_VISUAL_SELECT == "003"
              ? _c("div", { staticClass: "youtube-wrapper" }, [
                  _c("iframe", {
                    attrs: {
                      src:
                        banner.attribute.MAIN_VISUAL_YOUTUBE +
                        "?autoplay=1&mute=1&loop=1",
                      allow: "autoplay; encrypted-media",
                    },
                  }),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "main_slider-nav" },
      _vm._l(_vm.bannerList, function (banner) {
        return _c(
          "div",
          {
            staticClass: "item",
            attrs: { value: banner.attribute.MAIN_VISUAL_PRODUCTText },
          },
          [
            _c("div", { staticClass: "dot" }, [
              _c("span", {
                staticClass: "txt1",
                domProps: { innerHTML: _vm._s(banner.content.title) },
              }),
              _c("span", {
                staticClass: "txt2",
                domProps: {
                  innerHTML: _vm._s(banner.attribute.MAIN_VISUAL_TAB_SUB_TITLE),
                },
              }),
              _c("div", { staticClass: "bottom_line" }),
            ]),
            _c("div", { staticClass: "shadow" }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }