<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <!--지역별 백그라운드 이미지 출력 여부 확인-->
    <div class="content content-finder">
      <!-- location -->
      <div class="location" :class="{ 'type02': nationCode === '_GL' }">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('PCD.text.home') }}</li>
          <li>{{ $t('PCD.text.products') }}</li>
          <li>ALL COLORS</li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container (viatera Finder) -->
      <div class="container prod-fd-view container_pf viatera-pf">
        <h1 class="title_hidden" v-if="nationCode==='_US'">All Colors</h1>
        <div class="visual visual-detail" v-if="pcdContentAttribute.main_use_yn === 'true' && nationCode==='_GL'">
          <div class="visual-image full_width center" v-bind:style="{ backgroundImage: `url('${$imagePath}${pcdContentAttribute.main_img}')` }">

            <div class="inner_width">
              <div class="txt-box">
                <h1>
                  <div class="title">
                    <span v-if="visualImageLoadError" class="txt">{{ pcdContentAttribute.main_title }}</span>
                    <!-- 이미지일때 -->
                    <!-- <img src="/images/products/pcd_viatera_logo.svg" alt="viatera" class="pcd_tit_logo"> -->
                    <img v-if="!visualImageLoadError" :src="$imagePath +'/2024/images/main/logo/' + pcdMenuType.toLowerCase() + '.svg'" alt="" class="visual_logo" @error="visualImageLoadError=true" @load="visualImageLoadError=false"/>
                  </div>
                  <p class="txt-02">{{ pcdContentAttribute.main_desc }}</p>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <!--지역별 백그라운드 이미지 출력 여부 확인-->
        <!-- 필터 노출 -->
        <div class="drop-list inner">
          <div class="w-con-02">
            <div class="gallery">
              <div class="search-box">
                <div class="filter_area">
                  <div class="filter_wrap">
                    <button type="button" class="btn_filter" @click="clickFilterBtn()">
                      <span class="icon icon_filter"></span>
                      <span class="txt">Filter</span>
                      <span class="icon icon_arrow_down"></span>
                    </button>
                  </div>
                  <div class="search">
                    <input type="text" id="textSearch" :placeholder="this.$t('PF.text.searchText')" @input="textSearch($event)"/>
                    <!-- <button type="button" class="btn_cancel"/> -->
                    <button type="button" id="searchBtn" class="icon_search" @click="textSearchDel()">
                      {{ $t('PF.button.search') }}
                    </button>
                  </div>
                  <!-- s: filter popup -->
                  <div class="filter_pop">
                    <div class="filter_tit"><p>FILTER BY</p>
                      <button type="button" class="btn_close" @click="close_popup()"></button>
                    </div>
                    <div class="filter_top">
                      <ul>
                        <!--VIEWALL-->
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>BRAND</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box">
                              <ul class="checkbox_more no_line">
                                <li v-for="brand in filteredBrands" :key="brand.id">
                                  <!-- 브랜드 -->
                                  <input type="checkbox" :id="'brand_' + brand.id" v-model="selectedBrands" :value="brand.id"/>
                                  <label :for="'brand_' + brand.id">
                                    <span class="round"></span>
                                    <span class="text">{{ brand.name }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="filter_checkbox_tit">
                            <span>COLOR</span>
                          </div>
                          <div class="filter_checkbox_cont">
                            <div class="checkbox_box color_round">
<!--                              <ul class="checkbox_more no_line viewall_list" :class="{ 'item_10': nationCode === '_GL', 'item_10': nationCode === '_US' }">-->
                              <ul class="checkbox_more no_line viewall_list item_10">
                                <li v-for="color in uniqueColors" :key="color.value">
                                  <input type="checkbox" :id="'hue_'+color.value" name="color" v-model="selectedHues" :value="color.value" />
                                  <label :for="`hue_${color.value}`">
                                    <span class="round" :style="{ backgroundColor: '#' + color.value }"></span>
                                    <span class="text">{{ color.text }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <!--VIEWALL-->
                      </ul>
                    </div>
                    <!--gl-->
                    <div class="filter_bottom">
                      <div class="btn_box">
                        <button class="btn_wh" @click="filterClearAll">Clear All</button>
                        <button class="btn_bk" @click="applyFilter">Apply</button>
                      </div>
                    </div>
                  </div>
                  <!-- e: filter popup -->
                </div>
                <div class="sort_area">
                  <div class="select-view" v-if="selectedBrands.length > 0 || selectedHues.length > 0">
                    <ul class="list-filter-key">
                      <li class="sel" v-for="filter in selectedBrands" @click="removeFilterBrand(filter)">
                        <span class="brand">{{ getBrandName(filter) }}</span>
                        <button type="button"></button>
                      </li>
                      <li class="sel" v-for="filter in selectedHues" @click="removeFilterHue(filter)">
                        <span class="color">{{ getColorName(filter) }}</span>
                        <button type="button"></button>
                      </li>
                      <li class="sel del_all">
                        <button type="button" class="btn-all-delete" @click="removeFilterAll">
                          <span>clear all</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="btns">
                    <ul>
                      <li class="sel-box">
                        <button type="button" class="btn-sel">
                          <span>Most Recent</span>
                        </button>
                        <div class="sel-list">
                          <ul class="box">
                            <li>
                              <button type="button" @click="
                                sortByChange('Products A to Z', 'asc', 'title')
                                ">
                                {{ $t('PF.button.products_a_to_z') }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="
                                sortByChange('Products Z to A', 'desc', 'title')
                                ">
                                {{ $t('PF.button.products_z_to_a') }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="sortByChange('Most Recent', 'desc')">
                                {{ $t('PF.button.new_products_first') }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="gallery_layout_btns">
                    <button type="button" class="layout_grid on">
                      <span class="icon icon_grid"></span>
                    </button>
                    <button type="button" class="layout_list">
                      <span class="icon icon_list"></span>
                    </button>
                  </div>
                </div>
                <div class="count_area">
                  <div class="filter-cnt">
                    <span class="num">{{ contentLength }}</span>
                    <span>&nbsp;</span>
                    <span class="txt">Results</span>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="isInitialLoad">
              <div class="loading_area">
                <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
              </div>
            </template>
            <template v-else-if="!isLoading && contentList.length === 0">
              <div class="grid-box">
                <div class="no_result">
                  <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult">
                  <p>No Result found</p>
                  <span>If you do not find what you are looking for, please try different search terms or filters.</span>
                </div>
              </div>
            </template>
            <!--상품상세페이지이동-->
            <template v-else>
              <div class="list-type-view row-04">
                <ul>
                  <li v-for="(item, index) in contentList" :key="index">
                    <a :href="getProductDetailUrl(item)" :data-ec-product="makeDataLayerForm(item)">
                      <div class="img-box">
                        <span class="tag h30 new" v-if="item.attribute.isNew == 'true'">{{ $t('PF.text.new') }}</span>
                        <div class="image_name_and_tag">
                          <template>
                            <span v-if="getProductDetailUrl(item).includes('viatera')" class="tag h22 viatera">VIATERA</span>
                            <span v-else-if="getProductDetailUrl(item).includes('himacs')" class="tag h22 himacs">HIMACS</span>
                            <span v-else-if="getProductDetailUrl(item).includes('hflor')" class="tag h22 hflor">HFLOR</span>
                            <span v-else-if="getProductDetailUrl(item).includes('teracanto')" class="tag h22 teracanto">TERACANTO</span>
                            <span v-else-if="getProductDetailUrl(item).includes('benif')" class="tag h22 benif">BENIF</span>
                            <span v-else-if="getProductDetailUrl(item).includes('deco')" class="tag h22 etc">DECO FOIL</span>
                            <span v-else-if="getProductDetailUrl(item).includes('exterior')" class="tag h22 etc">EXTERIOR FOIL</span>
                            <span v-else-if="getProductDetailUrl(item).includes('borte')" class="tag h22 etc">BORTE</span>
                            <span v-else-if="getProductDetailUrl(item).includes('vizuon')" class="tag h22 etc">VIZUON</span>
                          </template>
                          <span class="image_name">{{ item.content.title }}</span>
                        </div>
                        <div class="icon_tag_wrap">
                          <i class="icon-no-fire" v-if="item.attribute.fire_resistance === '001'"><span>no-fire</span></i>
                        </div>
                        <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb"/>
                      </div>
                    </a>
                    <div class="view">
                      <a v-if="nationCode === '_US' && canOrder(item)" @click="openSampleOrder(item.content.id)" class="icon_cart">{{ $t('PCD.text.cart') }}</a>
                    </div>
                  </li>
                </ul>
              </div>
                <div class="loading_area" v-if="isLoading">
                  <img src="https://img.lxhausys.com/public/images/icon/loading.gif" alt="loading">
                </div>
            </template>
            <!--상품상세페이지이동-->
          </div>
        </div>
        <!-- popup : alert -->
        <div class="layer-popup layer-alert">
          <div class="popup">
            <div class="pop-alert">
              <div>
                <div class="tit-box">
                  <p class="ctt ctt-t4 artTit">{{ $t('PF.popup.your_compare_is_full') }}</p>
                </div>

                <div class="txt-box">
                  <div class="imgwrap">
                    <img src="https://img.lxhausys.com/images/svg_i
                    con/icon_alert_pop_check.svg"
                         alt="Your comparator is full">
                  </div>

                  <div class="txt">
                    <p>
                      Do you want to replace the first Products <br>
                      you added by the one you selected?
                    </p>
                  </div>

                  <div class="btn-box">
                    <button type="button" id="replace" class="btn" @click="replace()">
                      <span>{{ $t('PF.popup.replace') }}</span></button>
                  </div>
                </div>
              </div>

              <button type="button" class="btn-close" onclick="LayerPopup.close();">
                close
              </button>
            </div>
          </div>
          <!-- //popup -->
        </div>
        <!-- //popup : alert -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb"
             style="display: none">
          <div class="popup">
            <div class="scr-y">
              <!-- color / us | gl -->
              <div class="drop-list">
                <p class="tt-2">Filter By</p>
                <button class="filter-clos" @click="closeSearchPopup()"></button>
                <ul>
                  <li class="on">

                    <!-- us / gl title -->
                    <div class="tit" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <span>{{ $t('PD.text.color') }}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.button.color') }}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->

      </div>
      <!-- //container (product Finder) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import categoryService from '@/server/category.service'
import ProductService from '@/server/product.service'
import InfiniteLoading from 'vue-infinite-loading'
import { mapState } from 'vuex'
import { getEcomPrdData } from '@/views/products/js/product-detail'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    QuickMenuComponent,
    FooterComponent,
    InfiniteLoading,
    GnbComponent
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    }),
    uniqueColors () {
      const colorMap = new Map()
      const colorOrder = this.nationCode === '_US' ? this.colorOrderUS : this.colorOrderGL

      for (const hue of this.lefthue) {
        // if (!this.excludedColors.includes(hue.text)) {
        const mappedText = this.colorMapping[hue.text] || hue.text
        if (!colorMap.has(mappedText)) {
          colorMap.set(mappedText, {
            text: mappedText,
            values: new Set(),
            firstValue: hue.id.value,
            originalText: hue.text
          })
        }
        colorMap.get(mappedText).values.add(hue.id.value)
        // }
      }

      return colorOrder
        .filter(color => colorMap.has(this.colorMapping[color] || color))
        .map(color => {
          const mappedColor = this.colorMapping[color] || color
          const colorData = colorMap.get(mappedColor)
          return {
            text: colorData.text,
            values: Array.from(colorData.values),
            value: colorData.firstValue,
            originalText: colorData.originalText
          }
        })
    },
    filteredBrands () {
      return this.updateFilteredBrands()
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  data () {
    return {
      selectedBrands: [],
      selectedHues: [],
      tmpSelectedBrands: [],
      tmpSelectedHues: [],
      isInitialLoad: true, /* 첫페이지 로딩 때 noresult 안뜨게 */
      hasMore: true, // 더 불러올 데이터가 있는지 여부
      excludedColors: ['Dark Gray', 'Golden', 'Gold', 'Dark Green', 'Light Gray', 'Navy', 'Silver', 'Khaki', 'Dark Blue', 'Dark Grey', 'Bronze', 'Black Brown', 'Dark blue', 'Dark green', 'Mid grey'],
      colorMapping: {
      },
      colorOrderUS: ['White', 'Ivory', 'Cream', 'Beige', 'Brown', 'Gray', 'Black', 'Green', 'Blue'],
      colorOrderGL: ['White', 'Cream', 'Beige', 'Brown', 'Gray', 'Black', 'Green', 'Blue', 'Pink', 'Purple'],
      // colorOrder: ['White', 'Ivory', 'Cream', 'Beige', 'Brown', 'Red / Redish', 'Yellow / Yellowish', 'Dark Brown', 'Gray', 'Black', 'Green', 'Blue', 'Pink', 'Purple'],
      pcdContent: [],
      pcdContentAttribute: {},
      visualImageLoadError: false,
      pcdMenuTitle: '',
      pcdMenuType: '',
      pcdMenuPath: '',
      brandCondition: {
        operator: 'OR',
        values: []
      },
      /* filter menu */
      lefthue: [],
      /* filter menu */
      brands: {
        US: [ // US 브랜드
          { id: 'PRD_FLOORING_US', name: 'HFLOR' },
          { id: 'VIATERA_COLOR_US', name: 'VIATERA' },
          { id: 'HIMACS_COLOR_US', name: 'HIMACS' },
          { id: 'TERACANTO_COLOR_US', name: 'TERACANTO' }
          , { id: 'BORTE_COLOR_US', name: 'BORTE' }
        ],
        GL: [ // GL 브랜드
          { id: 'PRD_FLOORING_GL', name: 'HFLOR' },
          { id: 'HIMACS_COLOR_GL', name: 'HIMACS' },
          { id: 'VIATERA_COLOR_GL', name: 'VIATERA' },
          { id: 'TERACANTO_COLOR_GL', name: 'TERACANTO' },
          { id: 'BENIF_COLOR_GL', name: 'BENIF' },
          { id: 'DECO_COLOR_GL', name: 'DECO FOIL' },
          { id: 'EXTERIOR_COLOR_GL', name: 'EXTERIOR FOIL' }
          , { id: 'BORTE_COLOR_GL', name: 'BORTE' }
        ]
      },
      nationCode: null, /* 국가 코드 */
      scrollPercent: {
        x: 0,
        y: 0
      },
      isLoading: false,
      contentList: [],
      contentLength: 0 /* 해당 컨텐츠 길이 체크 ( All Product에 박을꺼임) */,
      scrollPosition: 0,
      threshold: 200, // 스크롤 임계값 (픽셀)
      searchText: '' /* 검색했을때 의 input 값 */,
      pageSize: 6,
      pageIndex: 1,
      orderField: [],
      orderType: [],
      /* compare */
      historyBack: false,
      scrollSize: 4,
      getSessionContent: [] /* GL : 불러오는 값 */,
      sortBy: 'Sort by'
    }
  },
  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    },
    nationCode () {
      this.updateFilteredBrands()
    },
    selectedBrands: {
      handler: function (val, oldVal) {

      },
      deep: true
    },
    selectedHues: {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  },
  async created () {
    // ...
    this.allHues = []
    const dicList = await ProductService.getDictionaryValueList([
      'VTR_HUE',
      // 'HIMACS_HUE',
      // 'FLRNG_ClrTone',
      // 'TCT_HUE',
      'BENIF_HUE',
      'DECO_HUE_SLD',
      'DECO_HUE_M',
      'DECO_HUE_W',
      'DECO_HUE_STN',
      'EXTERIOR_HUE'
    ])
    dicList.data.dicList.forEach(item => {
      this.allHues = _.unionBy(this.allHues, item.list, 'id.value')
    })
    this.lefthue = this.allHues
  },
  async mounted () {
    const t = this
    // Hflor
    // Hflor
    this.nationCode = this.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)

    this.loadBanner()
    this.restoreFilterState()

    // 추가로, Red 관련 항목만 필터링하여 로그 출력
    // const redItems = this.lefthue.filter(item =>
    //   item.text.toLowerCase().includes('red') ||
    //   item.id.value.toLowerCase().includes('red')
    // )

    if (performance.navigation.type === 1) {
      // 새로고침인 경우
      sessionStorage.removeItem('productFilterState')
    }

    this.restoreFilterState()

    this.$nextTick(function () {
      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      function resetFilters (mutationsList, observer) {
        mutationsList.forEach((mutation) => {
          if (mutation.attributeName === 'class') {
            if (!mutation.target.className.includes('on')) {
              t.selectedBrands = t.tmpSelectedBrands
              t.selectedHues = t.tmpSelectedHues
            }
          }
        })
      }

      const mutationObserver = new MutationObserver(resetFilters)
      mutationObserver.observe(document.getElementsByClassName('filter_pop')[0], { attributes: true })

      $(document).on('click', '.select-view .btn-del', function () {
        if ($('.select-view > ul > li').length === 1) {
          $('.select-view > ul').remove()
          $('.btn-all').remove()
        } else {
          $(this).closest('.sel').remove()
        }
      })
      $(document).on('click', '.btn-all button', function () {
        $('.select-view > ul').remove()
        $('.btn-all').remove()
      })

      // 필터 4줄 / 3줄 노출버튼
      $('.layout_grid').click(function () {
        $(this).addClass('on')
        $('.layout_list').removeClass('on')

        $('.list-type-view').removeClass('row-03')
        $('.list-type-view').addClass('row-04')
      })
      $('.layout_list').click(function () {
        $(this).addClass('on')
        $('.layout_grid').removeClass('on')

        $('.list-type-view').removeClass('row-04')
        $('.list-type-view').addClass('row-03')
      })

      t.getItem(1, 8)
    })

    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    // 새로고침
    saveFilterState () {
      const filterState = {
        selectedBrands: this.selectedBrands,
        selectedHues: this.selectedHues,
        searchText: this.searchText,
        sortBy: this.sortBy
      }
      sessionStorage.setItem('productFilterState', JSON.stringify(filterState))
    },
    restoreFilterState () {
      // 새로고침 여부 확인
      if (performance.navigation.type !== 1) {
        const savedState = sessionStorage.getItem('productFilterState')
        if (savedState) {
          const filterState = JSON.parse(savedState)
          this.selectedBrands = filterState.selectedBrands
          this.selectedHues = filterState.selectedHues
          this.searchText = filterState.searchText
          this.sortBy = filterState.sortBy

          // 복원된 상태로 제품 목록 다시 불러오기
          // this.getItem(1, 16)

          // 검색 입력 필드에 저장된 검색어 설정
          if (this.isMobile()) {
            $('#textSearchM').val(this.searchText)
          } else {
            $('#textSearch').val(this.searchText)
          }
        }
      } else {
        // 새로고침인 경우 필터 초기화
        this.selectedBrands = []
        this.selectedHues = []
        this.searchText = ''
        this.sortBy = 'Sort by'
        this.getItem(1, 16)

        // 검색 입력 필드 초기화
        if (this.isMobile()) {
          $('#textSearchM').val('')
        } else {
          $('#textSearch').val('')
        }
      }
    },
    // 뱃지
    getBrandName (id) {
      return `BRAND: ${this.filteredBrands.find((b) => b.id === id).name}`
    },
    getColorName (id) {
      return `COLOR: ${this.lefthue.find((b) => b.id.value === id).text}`
    },
    // 뱃지
    async handleScroll () {
      this.scrollPercent = this.getScrollPercentage()
      if (this.scrollPercent.y = 70) {
        this.infiniteHandler()
      }
    },
    updateFilteredBrands () {
      switch (this.nationCode) {
        case '_US':
          return this.brands.US
        case '_GL':
          return this.brands.GL
        default:
          return []
      }
    },
    // 배너이미지
    async loadBanner () {
      const _this = this
      let cateCode = ''

      if (this.nationCode === '_US') {
        cateCode = 'US_PCD_ALL'
      } else if (this.nationCode === '_GL') {
        cateCode = 'GL_PCD_ALL'
      }

      const res = await categoryService.getCategoryCode(cateCode)
      const categoryId = res.data.object.id
      const content = await ProductService.getContentSearchList(categoryId)
      const contentData = content.data[0]

      // if(contentData){
      const { id } = contentData

      _this.pcdContent = await ProductService.getContent(id)
      _this.pcdContentAttribute = _this.pcdContent.data.attribute

      // }
    },
    closeFilterPop (reset) {
      if (reset) {
        this.selectedBrands = this.tmpSelectedBrands
        this.selectedHues = this.tmpSelectedHues
      } else {
        this.tmpSelectedBrands = this.selectedBrands
        this.tmpSelectedHues = this.selectedHues
      }
      $('.filter_pop').removeClass('on')
    },
    openFilterPop () {
      this.tmpSelectedBrands = this.selectedBrands
      this.tmpSelectedHues = this.selectedHues

      $('.filter_pop').addClass('on')
    },
    clickFilterBtn () {
      const filterFlag = $('.filter_pop').hasClass('on')
      if (filterFlag) {
        this.closeFilterPop(true)
      } else {
        this.openFilterPop()
      }
    },
    removeFilterHue (id) {
      const t = this
      t.selectedHues = t.selectedHues.filter(hueId => hueId !== id)
      t.getItem(1, 16)
    },
    removeFilterBrand (id) {
      const t = this
      t.selectedBrands = t.selectedBrands.filter(brandId => brandId !== id)
      t.getItem(1, 16)
    },
    removeFilterAll () {
      // 선택된 브랜드와 색상 필터 초기화
      this.selectedBrands = []
      this.selectedHues = []

      // 검색 실행
      this.getItem(1, 16)
    },

    getScrollPercentage () {
      // 현재 스크롤 위치
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft
      const scrollY = window.pageYOffset || document.documentElement.scrollTop

      // 문서의 전체 스크롤 가능한 크기
      const scrollWidth = document.documentElement.scrollWidth - document.documentElement.clientWidth
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight

      // 백분율 계산 (소수점 둘째 자리까지)
      const scrollPercentX = scrollWidth ? Math.round((scrollX / scrollWidth) * 10000) / 100 : 0
      const scrollPercentY = scrollHeight ? Math.round((scrollY / scrollHeight) * 10000) / 100 : 0

      return {
        x: scrollPercentX,
        y: scrollPercentY
      }
    },
    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },
    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },
    // 검색어
    textSearch: _.debounce(function (event) {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'center/contain no-repeat url(https://img.lxhausys.com/public/images/svg_icon/icon_pop_close.svg)' })
      } else {
        $('#searchBtn').css({ background: 'center/contain no-repeat url(https://img.lxhausys.com/public/images/svg_icon/icon_pop_close.svg)' })
      }
      this.searchText = event.target.value
      this.pageIndex = 1 // 페이지 인덱스를 1로 초기화
      this.contentList = [] // 기존 컨텐츠 리스트를 초기화
      this.getItem(1, this.pageSize)
      this.saveFilterState() // 검색어 변경 시 필터 상태 저장
    }, 300),

    textSearchDel () {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/svg_icon/icon_search_black.svg) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/svg_icon/icon_search_black.svg) center center ' })
      }
      this.searchText = ''
      if (this.isMobile()) {
        $('#textSearchM').val('')
      } else {
        $('#textSearch').val('')
      }
      this.pageIndex = 1 // 페이지 인덱스를 1로 초기화
      this.contentList = [] // 기존 컨텐츠 리스트를 초기화
      this.getItem(1, 16)
    },
    async infiniteHandler ($state) {
      if (this.isLoading) {
        return
      }

      if (!this.hasMore) {
        $state.complete()
        return
      }

      this.isLoading = true
      await this.getItem(this.pageIndex, 8)

      if (this.contentList.length < this.contentLength) {
        $state.loaded()
      } else {
        this.hasMore = false
        $state.complete()
      }

      this.isLoading = false
    },
    // PF 중요한 함수입니다
    async getItem (pageIndex, pageSize) {
      const t = this
      t.pageIndex = pageIndex
      t.pageSize = pageSize

      const dictionaryCondition = {
        operator: 'AND',
        list: []
      }
      dictionaryCondition.list.push({
        operator: 'OR',
        values: []
      })

      for (const hue of t.selectedHues) {
        const hueValues = ['hue_sld', 'hue_m', 'hue_w', 'hue', 'hue_stn']
        hueValues.forEach(hueValue => {
          dictionaryCondition.list[0].values.push({
            id: hueValue,
            value: hue
          })
        })
      }

      let cateCodes = []
      if (t.selectedBrands.length == 0) {
        cateCodes = this.updateFilteredBrands().map(b => b.id)
      } else {
        cateCodes = t.selectedBrands
      }
      try {
        const res = await ProductService.getAllProductByCode(
          cateCodes,
          dictionaryCondition,
          t.searchText,
          t.pageIndex,
          t.pageSize,
          t.orderField,
          t.orderType,
          ['search_text']
        )

        if (t.pageIndex === 1) {
          t.contentList = res.data.list
          t.isInitialLoad = false
        } else {
          t.contentList = [...t.contentList, ...res.data.list]
        }
        t.contentLength = res.data.totalCount // 총 개수입니다.
        t.pageIndex++

        this.hasMore = this.contentList.length < this.contentLength
      } catch (err) {
        console.error('Error fetching data:', err)
        t.error = 'Failed to load data. Please try again.'
        this.hasMore = false
      } finally {
        t.isInitialLoad = false
        t.isLoading = false
      }
    },
    // 어플라이 눌렀을 때 실행되는 함수입니다.
    applyFilter () {
      // 검색 실행
      this.hasMore = true
      this.saveFilterState()
      this.getItem(1, 16)
      this.closeFilterPop(false)
    },

    // 필터 조건 초기화
    async filterClearAll () {
      // 모든 선택 옵션 초기화
      this.selectedBrands = []
      this.selectedHues = []

      $('input:checkbox').prop('checked', false)
      $("input:checkbox[id*='All']").prop('checked', true)
    },
    // 뱃지 초기화
    async clearAll () {
      this.filterClearAll()
      const t = this

      await t.getItem(1, 16)
    },
    async sortByChange (text, type, field) {
      const _this = this

      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .btn-sel span').text(text)
      $('.sel-box .btn-sel').addClass('on')

      // 정렬 옵션 선택 목록 숨김
      $('.sel-box .sel-list').slideUp()

      _this.sortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.getItem(1, 16)
    },

    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode === '_GL') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            t.getSessionContent.push(o.id)
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    openCart () {
      this.$root.$refs.cartView.openPopup()
    },

    goLink (type, url) {
      location.href = 'products/viatera-quartz-surface'
    },

    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      const { ecom_prd_brand, ecom_prd_category } = getEcomPrdData(data)
      return JSON.stringify({
        ecom_prd_id: data.attribute.code == '' ? data.content.id : data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand,
        ecom_prd_category, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    },
    // 제품의 상세 페이지로 가는 함수입니다.
    getProductDetailUrl (item) {
      this.saveFilterState()
      const modelId = item.content.modelId
      const parentsCode = item.parents[0].code
      const urlPrefixes = {
        VIATERA: 'products/viatera-quartz-surface',
        HIMACS: 'products/himacs-solid-surface',
        TERACANTO: 'products/teracanto-porcelain-surface',
        BENIF: 'products/benif-interior-film',
        DECO: 'products/deco-foil',
        BORTE: 'products/borte-premium-panel',
        EXTERIOR: 'products/exterior-foil',
        HFLOR_LUX: 'products/hflor/lvt-flooring',
        HFLOR_HOM: 'products/hflor/homogeneous-sheet',
        HFLOR_HET: 'products/hflor/heterogeneous-sheet',
        HFLOR_LOS: 'products/hflor/looselay',
        HFLOR_SPT: 'products/hflor/specialty',
        HFLOR_SPC: 'products/hflor/spc-click'
      }

      const modelPrefix = Object.keys(urlPrefixes).find((prefix) => {
        if (parentsCode.startsWith('FLOOR_LUX_DECOLEY_GL') || parentsCode.startsWith('FLOOR_LUX_DECOLEY_US')) {
          return prefix === 'HFLOR_LOS'
        }
        return modelId.startsWith(prefix)
      })

      if (modelPrefix) {
        const detailUrl = item.content.slug ? '/detail/' + item.content.slug : '/' + this.$utils.getSeoName(item, 'content') + '/' + item.content.id
        const urlPrefix = urlPrefixes[modelPrefix]
        return `${urlPrefix}${detailUrl}`
      } else {
        return ''
      }
    },
    // sample order 재고 확인
    canOrder (item) {
      if (!item || !item.attribute) return false
      const price = item.attribute.price
      if (!_.isArray(price)) return false

      const filtered = price.filter(obj => _.toNumber(obj.price) > 0 && (_.isUndefined(obj.use) || obj.use == true))
      return filtered.length > 0
    }
  }
}
</script>
