var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overview-top-image-area" },
    [
      _c("ProductTopImage", {
        attrs: {
          imageSrc: _vm.imageSrc,
          imageHeight: _vm.imageHeight,
          imageWidth: _vm.imageWidth,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "pre",
                  { staticClass: "ctt-pre ctt-small desc-add-font-style" },
                  [_vm._v(_vm._s(_vm.description))]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }