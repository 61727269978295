<template>
  <div class="main-box insp-box inspiration_gallery">
    <div class="inner_content">
      <div class="title_area">
        <h2 class="tit-main">Inspiration galleries</h2>
        <div class="more" @click="handelClickViewMore">
          <a href="javascript:void(0)" class="view_more">
            <span>View more</span>
            <span class="icon"></span>
          </a>
        </div>
      </div>
      <div class="tab-ui tab-ui-nav type_line tab-insp">
        <ul class="tab-ui-inspired">
          <li class="btn-tab on" data-tab="insp-all">
            <button type="button" @click="tabClick('residential')" v-html="'Residential'"/>
          </li>
          <li class="btn-tab" data-tab="insp-all">
            <button type="button" @click="tabClick('commercial')" v-html="'Commercial'"/>
          </li>
        </ul>
      </div>
      <div>
        <div class="tab-con insp-all on">
          <div class="insplist-wrap">
            <ul v-if="nationCode === '_GL' || nationCode === '_US'"><!-- US / GL -->
              <li data-aos="fade-up" data-aos-duration="600" data-aos-delay="500" v-for="inspired in featuredPJTList"
                  @click="goToUrl(inspired)">
                <img :src="`${$imagePath}${inspired.attribute.mainThumbnail}`" :alt="inspired.content.title"/>
                <div class="txt-info">
                  <span class="tinfo-cg" v-html="inspired.content.title"/>
                </div>
                <div class="dim-box">
                  <div class="ins_prod_type">
                    <template v-for=" cateItem in inspired.attribute.product_cateText">
                      <span class="tinfo-tit"  v-html="cateItem"/>
                    </template>
                  </div>
                  <p class="content_title" v-html="inspired.content.title"/>
                  <div class="hashtag_group" v-if="inspired.attribute.applicationList">
                    <template v-for=" appItem in inspired.attribute.applicationList">
                      <span class="hashtag" v-html="`#${appItem.text}`"/>
                    </template>
                  </div>
                </div>
              </li>
            </ul>
            <ul v-else><!-- EU / UK -->
              <li data-aos="fade-up" data-aos-duration="600" data-aos-delay="500" v-for="inspired in featuredPJTList"
                  @click="goToUrl(inspired)">
                <img :src="$imagePath + inspired.attribute.pc_thumb" alt="segments img"/>
                <div class="txt-info">
                  <span class="tinfo-cg">{{ inspired.parents[0].name }} / {{ inspired.attribute.segments1Text }}</span>
                  <span class="tinfo-tit">{{ inspired.content.title }}</span>
                </div>
                <div class="dim-box">
                  <span class="tinfo-cg" @click="caseStudiesLink()">{{
                      inspired.attribute.case_category
                    }} / {{ inspired.attribute.segments1Text }}</span>
                  <span class="tinfo-tit">{{ inspired.content.title }}</span>
                  <span class="tinfo-place">{{ inspired.attribute.location }}</span>
                  <span class="tinfo-cmt" v-html="inspired.attribute.summary" v-plaintext></span>
                  <a :href="'inspiration/case-studies/INS_CAS'+ nationCode +'/'+inspired.content.id"
                     class="btn-golmore"></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainService from '@/server/main.service'
import {getCategoryCode} from '@/server/category'
import InspirationService from '@/server/inspiration.service'

export default {
  name: 'app',
  components: {},
  data() {
    return {
      categoryId: [],
      categoryMainId: [],
      euCateId: [],
      caseUrl: '',
      nationCode: '',
      categoryCode: [],
      categoryCodeMainGAL: '',
      featuredPJTList: [],
      segmentType: 'ALL',
      hflor2List: [],
      residentialList: [],
      commercialList: [],
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      seo: false
    }
  },
  async created() {
    const _this = this
    const nationCode = _this.$countryCode // 국가코드

    _this.nationCode = nationCode // 국가코드

    if (nationCode === '_US' || nationCode === '_GL') {
      _this.seo = true
    }

    if (nationCode !== '_GL' && nationCode !== '_US') {
      _this.categoryCode = 'INS_CAS' + nationCode
      const countryCode = nationCode.slice(1)
      const categoryRes = await mainService.getCategoryCode(`${countryCode}_INS_GAL_TOBE`) //  컨텐츠를 불러오기 위한 categoryId code로 가져오기!

      const cateIdList = (categoryRes.data.object.childIds.split(','))

      for (var i = 0; i < cateIdList.length; i++) {
        _this.categoryId.push(cateIdList[i])
      }
      const pjtList = await mainService.getContentListInspired(_this.categoryId, 1, 8, 'createdAt', 'desc') // 컨텐츠 리스트 api
      _this.featuredPJTList = pjtList.data.list
    } else {

    }

    _this.$nextTick(function () {
      $(window).resize(function () {
        checkSlick(this.nationCode)
      })
    })

    checkSlick(this.nationCode)
  },
  async mounted() {
    checkSlick(this.nationCode)
    const countryCode = this.$countryCode.slice(1)
    const hflorCode = await getCategoryCode(`${countryCode}_INS_GAL_TOBE`)
    this.categoryId.push(hflorCode.data.object.id)
    const commercialData = await InspirationService.getDictionary('CMMR')
    this.commercialList = commercialData.data
    const residentialData = await InspirationService.getDictionary('RSDN')
    this.residentialList = residentialData.data

    var tabInstance = $('.tab-ui-nav')
    $.each(tabInstance, function (key, value) {
      var windowWidth = $(window).width()
      var arrows = $(tabInstance[key]).find('.arrow')
      var prevArrow = arrows.filter('.tab-arr-prev')
      var nextArrow = arrows.filter('.tab-arr-next')
      var box = $(tabInstance[key]).find('ul')
      var x = 0
      var mx = 0
      var maxScrollWidth = box[0].scrollWidth - windowWidth / 2 - box.width() / 2

      $(arrows).on('click', function () {
        if ($(this).hasClass('tab-arr-next')) {
          x = box.width() / 2 + box.scrollLeft()
          box.animate({
            scrollLeft: x
          })
        } else {
          x = box.width() / 2 - box.scrollLeft()
          box.animate({
            scrollLeft: -x
          })
        }
      })

      $(box).on({
        mousemove: function (e) {
          var mx2 = e.pageX - this.offsetLeft
          if (mx) this.scrollLeft = this.sx + mx - mx2
        },
        mousedown: function (e) {
          this.sx = this.scrollLeft
          mx = e.pageX - this.offsetLeft
        },
        scroll: function () {
          toggleArrows()
        }
      })

      $(document).on('mouseup', function () {
        mx = 0
      })

      function toggleArrows() {
        if (box.scrollLeft() > maxScrollWidth) {
          nextArrow.addClass('disabled')
        } else if (box.scrollLeft() < 5) {
          prevArrow.addClass('disabled')
        } else {
          // both are enabled
          nextArrow.removeClass('disabled')
          prevArrow.removeClass('disabled')
        }
      }
    })
    this.$nextTick(function () {
      this.tabClick('residential')
    })
  },
  methods: {
    goToUrl(item) {
      const {id} = item.content
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      location.href = `/${countryCode}/inspiration/gallery-detail?contentId=${id}`
    },
    handelClickViewMore() {
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      location.href = `/${countryCode}/inspiration/gallery`
    },
    async getList() {
      const _this = this
      // 최대 8개만 불러올 예정 // 불러온 갯수 에서 8을 뺌
      _this.categoryCodeMainGAL = 'MAIN_INSPIRED' + this.$countryCode
      const categoryResMainGAL = await mainService.getCategoryCode(_this.categoryCodeMainGAL)

      _this.categoryMainId.push(categoryResMainGAL.data.object.id)

      const dataList = await InspirationService.getContentListTap(_this.categoryMainId, this.dictionaryCondition, '', 'search_text', 1, 8)

      let inpCnt = 8

      if (dataList.data.list.length > 0) {
        inpCnt = inpCnt - dataList.data.list.length
      }

      let insList
      if (inpCnt !== 0) {
        insList = await InspirationService.getContentListTap(this.categoryId, this.dictionaryCondition, '', 'search_text', 1, inpCnt)

        insList.data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['applicationList', 'productList', 'subThumbnailList']
            if (exceptList.includes(item)) {
              insList.data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })

        dataList.data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['applicationList', 'productList', 'subThumbnailList']
            if (exceptList.includes(item)) {
              dataList.data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })

        this.featuredPJTList = dataList.data.list.concat(insList.data.list)
      } else {
        dataList.data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['applicationList', 'productList', 'subThumbnailList']
            if (exceptList.includes(item)) {
              dataList.data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })
        this.featuredPJTList = dataList.data.list
      }
    },
    caseStudiesLink: function () { // See All 버튼 분류 탭에 따라 url 변경
      const _this = this
      if (_this.seo) {
        if (_this.segmentType === 'ALL') {
          location.href = 'inspiration/case-studies'
        } else {
          if (_this.nationCode !== '_GL' && _this.nationCode !== '_US') {
            location.href = _this.caseUrl
          } else {
            location.href = 'inspiration/case-studies/segment/' + _this.segmentType.toLowerCase()
          }
        }
      } else {
        if (_this.segmentType === 'ALL') {
          location.href = 'inspiration/case-studies/INS_CAS'
        } else {
          if (_this.nationCode !== '_GL' && _this.nationCode !== '_US') {
            location.href = _this.caseUrl
          } else {
            location.href = 'inspiration/case-studies/INS_CAS/segment/' + _this.segmentType
          }
        }
      }
    },
    async tabClick(item) {
      if (item === 'residential') {
        this.dictionaryCondition.list[0].values = this.residentialList.map((item) => (
          {id: 'segments', value: item.text}
        ))
      } else {
        this.dictionaryCondition.list[0].values = this.commercialList.map((item) => (
          {id: 'segments', value: item.text}
        ))
      }
      this.getList()
    }
  }
}

function checkSlick(nationCode) {
  var width = window.innerWidth
  // EU일때 pcArrow 클래스 추가
  if (nationCode != '_GL' && nationCode != '_US') {
    $('.tab-insp').addClass('pcArrow')
    activeArrowTab()
  } else {
    if (width <= 1110 && $('.tab-insp').hasClass('pcArrow') == false) {
      $('.tab-insp').addClass('pcArrow')
      activeArrowTab()
    } else if (width > 1120 && $('.tab-insp').hasClass('pcArrow')) {
      $('.tab-insp').removeClass('pcArrow')
    } else {
    }
  }
}
</script>
