<template>
  <div class="film-item-area">
    <div class="content-area">
      <div class="text-area">
        <span class="bold">{{ title }}</span><br />
        <span>{{ description }}</span>
      </div>
      <div class="image">
        <div class="over-text">
          <span class="highlight">* Printed</span>
        </div>
        <img :src="layerImageSrc" draggable="false">
      </div>
    </div>

    <div class="exmaple-image-area">
      <img :src="exampleImageSrc" draggable="false">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    layerImageSrc: {
      type: String
    },
    exampleImageSrc: {
      type: String
    }
  }
}

</script>

<style scoped>
.film-item-area {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;

  padding: 60px 100px;
}
@media (max-width: 768px) {
  .film-item-area {
    padding: 10px !important;
  }
}

.film-item-area .content-area {
  flex-basis: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.film-item-area .content-area .text-area {
  margin-bottom: 21px;
}

.film-item-area .content-area .text-area span {
  color: #000;
  font-family: 'NotoSans' , sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.film-item-area .content-area .text-area span.bold {
  color: #000;
  font-family: 'NotoSans' , sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.film-item-area .content-area .text-area .image img {
  width: 100%;
  object-fit: cover;
}

.film-item-area .content-area .text-area .image .over-text {
  position: relative;
}

span.highlight {
  color: #D70000;
  font-family: 'NotoSans' , sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.film-item-area .content-area .exmaple-image-area {
  flex-basis: 450px;
}

.film-item-area .content-area .exmaple-image-area img {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

</style>
