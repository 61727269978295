
<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content pros-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">Home</li>
          <li>Pros</li>
          <li>BIM Library</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (BIM Library) -->
      <div class="container prod-fd-view">
        <div class="w-con-02">
          <h1 class="tt-1 mb-88 tac">BIM Library</h1>
        </div>
        <div class="inspr-view fd-view">
          <iframe height="1200" src="https://market.bimsmith.com/microsite/lx-hausys" width="100%" style="border: none;"></iframe>
        </div>
      </div>
      <!-- //container (BIM Library) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div><!-- //wrap -->
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import global from '@/common/global'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'

export default {
  name: 'Detail',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  data () {
    return {
      nationCode: '',
      countryCodeSub: ''
    }
  },
  created () {
    /* select box */

    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this
    _this.nationCode = this.$countryCode // 국가코드
  },
  methods: {
    gotolink (url) {
      location.href = url
    }
  }
}
</script>
