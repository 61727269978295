var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layer-compare", attrs: { id: "pdf_wrap" } },
    [
      _c("div", { staticClass: "pop-compare scr-y" }, [
        _c("p", { staticClass: "heading-3" }, [
          _vm._v(_vm._s(_vm.$t("PF.popup.compare_hflor_products"))),
        ]),
        _c("p", { staticClass: "heading-4 ta-c" }, [
          _vm._v(
            _vm._s(_vm.$t("PF.popup.characteristics_technical_performance"))
          ),
        ]),
        _c("div", { staticClass: "slide-wrapper slide-pd" }, [
          _c("button", { staticClass: "arrow-prev" }, [_vm._v("Prev")]),
          _c("button", { staticClass: "arrow-next" }, [_vm._v("Next")]),
          _c("div", { staticClass: "slick-container" }, [
            _c("div", { staticClass: "slider prod-list" }, [
              _c("div", { staticClass: "prod-box" }, [
                _c("div", { staticClass: "prod" }, [
                  _c("div", { staticClass: "img-box" }, [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.$imagePath +
                          "/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png",
                        alt: "Vintage",
                      },
                    }),
                  ]),
                  _vm._m(0),
                ]),
                _vm._m(1),
              ]),
              _vm._m(2),
            ]),
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
          ]),
        ]),
        _c("div", { staticClass: "prod-list tbl-compare" }, [
          _c("table", [
            _c("caption", [
              _vm._v(
                _vm._s(_vm.$t("PF.popup.characteristics_technical_performance"))
              ),
            ]),
            _vm._m(6),
            _c("tbody", [
              _c(
                "tr",
                _vm._l(_vm.compareList, function (item, idx) {
                  return _c("td", [
                    _c("div", { staticClass: "prod-box" }, [
                      _c("div", { staticClass: "prod" }, [
                        _c("div", { staticClass: "img-box" }, [
                          item.attribute.pf_thumb != undefined
                            ? _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + item.attribute.pf_thumb,
                                  alt: item.content.title,
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("ul", [
                            _c("li", { staticClass: "txt-01" }, [
                              _c(
                                "a",
                                { attrs: { href: "javascript:void(0);" } },
                                [_vm._v(_vm._s(item.content.title))]
                              ),
                            ]),
                            item.attribute.disp_code != undefined
                              ? _c("li", { staticClass: "txt-02" }, [
                                  _vm._v(_vm._s(item.attribute.disp_code)),
                                ])
                              : _c("li", { staticClass: "txt-02" }, [
                                  _vm._v(_vm._s(item.attribute.code)),
                                ]),
                            _c("li", { staticClass: "txt-03" }, [
                              _vm._v(_vm._s(item.parents[0].name)),
                            ]),
                            _c("li", { staticClass: "txt-04" }, [
                              _vm._v(_vm._s(item.parents[1].name)),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "btn-remove" }, [
                        _c("button", { attrs: { type: "button" } }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("PF.button.remove"))),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "tbl-box type02" }, [
                      _c("table", [
                        _c("caption", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "PF.popup.characteristics_technical_performance"
                              )
                            )
                          ),
                        ]),
                        _vm._m(7, true),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("PF.text.product_type"))),
                            ]),
                          ]),
                          item.attribute.prdTypeText != undefined
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      item.attribute.prdTypeText.join(", ")
                                    )
                                  ),
                                ]),
                              ])
                            : _c("td", [_vm._v(_vm._s())]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.parents[0].name))]),
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("PD.text.total_thickness"))),
                            ]),
                          ]),
                          _c("tr", [
                            item.attribute.thickWearLayer != undefined
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getThick(
                                            item.attribute.thickWearLayer
                                          )
                                          .join(", ")
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("td", [
                                  _vm._v(
                                    _vm._s(item.attribute.thickText.join(", "))
                                  ),
                                ]),
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("PD.text.wear_layer"))),
                            ]),
                          ]),
                          _c("tr", [
                            item.attribute.thickWearLayer != undefined
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getWearLayer(
                                            item.attribute.thickWearLayer
                                          )
                                          .join(", ")
                                      )
                                  ),
                                ])
                              : _c("td", [
                                  _vm._v(_vm._s(item.attribute.wearLayerText)),
                                ]),
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("PD.text.pattern"))),
                            ]),
                          ]),
                          _c("tr", [
                            item.attribute.patternText != null
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      item.attribute.patternText.join(", ")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            item.attribute.patternText == null
                              ? _c("td", [_vm._v(_vm._s())])
                              : _vm._e(),
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("PD.text.dimension"))),
                            ]),
                          ]),
                          _c("tr", [
                            item.attribute.dimensionText != null
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      item.attribute.dimensionText.join(", ")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            item.attribute.dimensionText == null
                              ? _c("td", [_vm._v(_vm._s())])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn-download" }, [
          _c(
            "button",
            { staticClass: "btn dwn load", attrs: { type: "button" } },
            [_c("span", [_vm._v(_vm._s(_vm.$t("PD.button.download")))])]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info" }, [
      _c("ul", [
        _c("li", { staticClass: "txt-01" }, [
          _c("a", { attrs: { href: "javascript:void(0);" } }, [
            _vm._v("Vintage"),
          ]),
        ]),
        _c("li", { staticClass: "txt-02" }, [_vm._v("DTW9135 Glue Down")]),
        _c("li", { staticClass: "txt-03" }, [_vm._v("Savana")]),
        _c("li", { staticClass: "txt-04" }, [_vm._v("Luxury Vinyl Tile")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btn-remove" }, [
      _c("button", { attrs: { type: "button" } }, [
        _c("span", [_vm._v("Remove")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tbl-box type02" }, [
      _c("table", [
        _c("caption", [_vm._v("Characteristics & Technical Performance")]),
        _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })]),
        _c("tbody", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Type")]),
          ]),
          _c("tr", [_c("td", [_vm._v("Glue Down")])]),
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Collection")]),
          ]),
          _c("tr", [_c("td", [_vm._v("Savana")])]),
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Total Thickness")]),
          ]),
          _c("tr", [_c("td", [_vm._v(" 2mm ")])]),
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Wear Layer")]),
          ]),
          _c("tr", [_c("td", [_vm._v(" 2mm : 0.3mm")])]),
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Pattern")]),
          ]),
          _c("tr", [_c("td", [_vm._v("Wood")])]),
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Dimension")]),
          ]),
          _c("tr", [_c("td", [_vm._v('6" x 48"')])]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slider prod-list" }, [
      _c("div", { staticClass: "prod-box" }, [
        _c("div", { staticClass: "prod" }, [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png",
                alt: "Vintage",
              },
            }),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("ul", [
              _c("li", { staticClass: "txt-01" }, [
                _c("a", { attrs: { href: "javascript:void(0);" } }, [
                  _vm._v("Vintage"),
                ]),
              ]),
              _c("li", { staticClass: "txt-02" }, [
                _vm._v("DTW9135 Glue Down"),
              ]),
              _c("li", { staticClass: "txt-03" }, [_vm._v("Savana")]),
              _c("li", { staticClass: "txt-04" }, [
                _vm._v("Luxury Vinyl Tile"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn-remove" }, [
          _c("button", { attrs: { type: "button" } }, [
            _c("span", [_vm._v("Remove")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "tbl-box type02" }, [
        _c("table", [
          _c("caption", [_vm._v("Characteristics & Technical Performance")]),
          _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })]),
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Type")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Glue Down")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Collection")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Savana")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v("Total Thickness"),
              ]),
            ]),
            _c("tr", [_c("td", [_vm._v(" 2mm ")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Wear Layer")]),
            ]),
            _c("tr", [_c("td", [_vm._v(" 2mm : 0.3mm")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Pattern")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Wood")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Dimension")]),
            ]),
            _c("tr", [_c("td", [_vm._v('6" x 48"')])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slider prod-list" }, [
      _c("div", { staticClass: "prod-box" }, [
        _c("div", { staticClass: "prod" }, [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png",
                alt: "Vintage",
              },
            }),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("ul", [
              _c("li", { staticClass: "txt-01" }, [
                _c("a", { attrs: { href: "javascript:void(0);" } }, [
                  _vm._v("Vintage"),
                ]),
              ]),
              _c("li", { staticClass: "txt-02" }, [
                _vm._v("DTW9135 Glue Down"),
              ]),
              _c("li", { staticClass: "txt-03" }, [_vm._v("Savana")]),
              _c("li", { staticClass: "txt-04" }, [
                _vm._v("Luxury Vinyl Tile"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn-remove" }, [
          _c("button", { attrs: { type: "button" } }, [
            _c("span", [_vm._v("Remove")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "tbl-box type02" }, [
        _c("table", [
          _c("caption", [_vm._v("Characteristics & Technical Performance")]),
          _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })]),
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Type")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Glue Down")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Collection")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Savana")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v("Total Thickness"),
              ]),
            ]),
            _c("tr", [_c("td", [_vm._v(" 2mm ")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Wear Layer")]),
            ]),
            _c("tr", [_c("td", [_vm._v(" 2mm : 0.3mm")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Pattern")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Wood")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Dimension")]),
            ]),
            _c("tr", [_c("td", [_vm._v('6" x 48"')])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slider prod-list" }, [
      _c("div", { staticClass: "prod-box" }, [
        _c("div", { staticClass: "prod" }, [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: {
                src: "https://img.lxhausys.com/product/images/HFLOR/LuxuryVinylTile/Savana/DTW9135/DTW9135_0000006829_prd_pf_thumb_0001_v2.png",
                alt: "Vintage",
              },
            }),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("ul", [
              _c("li", { staticClass: "txt-01" }, [
                _c("a", { attrs: { href: "javascript:void(0);" } }, [
                  _vm._v("Vintage"),
                ]),
              ]),
              _c("li", { staticClass: "txt-02" }, [
                _vm._v("DTW9135 Glue Down"),
              ]),
              _c("li", { staticClass: "txt-03" }, [_vm._v("Savana")]),
              _c("li", { staticClass: "txt-04" }, [
                _vm._v("Luxury Vinyl Tile"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn-remove" }, [
          _c("button", { attrs: { type: "button" } }, [
            _c("span", [_vm._v("Remove")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "tbl-box type02" }, [
        _c("table", [
          _c("caption", [_vm._v("Characteristics & Technical Performance")]),
          _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })]),
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Product Type")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Glue Down")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Collection")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Savana")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v("Total Thickness"),
              ]),
            ]),
            _c("tr", [_c("td", [_vm._v(" 2mm ")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Wear Layer")]),
            ]),
            _c("tr", [_c("td", [_vm._v(" 2mm : 0.3mm")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Pattern")]),
            ]),
            _c("tr", [_c("td", [_vm._v("Wood")])]),
            _c("tr", [
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Dimension")]),
            ]),
            _c("tr", [_c("td", [_vm._v('6" x 48"')])]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }