var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/winning-design-awards/top_image.png",
          title: "Winning Design Awards",
          titleColor: "#222",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "OverviewSection",
                    [_c("SustainabilityText", { attrs: { text: _vm.text } })],
                    1
                  ),
                  _c("AchievementsAtDesignAwards"),
                  _c("MajorDesignPerformance"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1474973157
        ),
      })
    : _vm.nationCode == "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/winning-design-awards/top_image.png",
          title: "デザイン賞受賞",
          titleColor: "#222",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [_c("SustainabilityText", { attrs: { text: _vm.textJp } })],
                  1
                ),
                _c("AchievementsAtDesignAwards"),
                _c("MajorDesignPerformance"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/winning-design-awards/top_image.png",
          title: "Награда за лучший дизайн",
          titleColor: "#222",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [_c("SustainabilityText", { attrs: { text: _vm.textRu } })],
                  1
                ),
                _c("AchievementsAtDesignAwards"),
                _c("MajorDesignPerformance"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode == "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc:
            "images/aboutus/r-and-d-center/winning-design-awards/top_image.png",
          title: "斩获设计大奖",
          titleColor: "#222",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "OverviewSection",
                  [_c("SustainabilityText", { attrs: { text: _vm.textCn } })],
                  1
                ),
                _c("AchievementsAtDesignAwards"),
                _c("MajorDesignPerformance"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }