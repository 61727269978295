<template>
  <OverviewSection
    title="LX Hausys Carbon Neutrality Roadmap"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <!-- chart 이미지 -->
    <OverviewSectionItem>
      <img
        src="images/aboutus/sustainability/net_zero/chart.svg"
        style="max-width: 1440px;"
      >
    </OverviewSectionItem>

    <!-- 차트 설명문 -->
    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text = "LX Hausys reflects the national 2050 Carbon Neutral Nation Declaration and Nationally Determined Contributions (NDC) target in its setup of the expected
mid-to long-term GHG emissions by 2050 and targets to achieve Net Zero by 2050 in consideration of its GHG emissions characteristic and reduction plan.
We employ various means to meet our reduction plan goals and realize carbon neutrality, thereby fulfilling our corporate social responsibility."
      />
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "GHG Reduction Plan"
    >
      <div class="reduction-plan-area">
        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2030.title"
            :desc="card2030.desc"
          />
        </div>

        <div class="arrow-area">
          <img src="images/aboutus/sustainability/net_zero/arrow.svg" alt="img"/>
        </div>

        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2050.title"
            :desc="card2050.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "Carbon Neutrality Strategy"
    >
      <div class="carbon-neutrality-strategy">
        <div
          v-for="(it, idx) in strategy"
          :key="idx"
          class="card-area"
        >
          <CarbonNeutralityStrategyCard
            :imageSrc="it.imageSrc"
            :title="it.title"
            :desc="it.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="LX Hausys カーボン ニュートラル ロードマップ"
    v-else-if="nationCode == '_JP'"
  >
    <!-- chart 이미지 -->
    <OverviewSectionItem>
      <img
        src="images/aboutus/sustainability/net_zero/chart.svg"
        style="max-width: 1440px;"
      >
    </OverviewSectionItem>

    <!-- 차트 설명문 -->
    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text = "LX Hausysは、2050年までの中長期温室効果ガス排出量の想定設定において、
        国家の2050年カーボンニュートラル国家宣言および国別決定貢献（NDC）目標を反映し、温室効果ガス排出特性と削減計画を考慮し、
        2050年までにネットゼロを達成することを目標としています。当社は、削減計画目標を達成し、
        カーボンニュートラルを実現するためにさまざまな手段を講じ、企業の社会的責任を果たしています。"
      />
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "温室効果ガス削減計画"
    >
      <div class="reduction-plan-area">
        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2030Jp.title"
            :desc="card2030Jp.desc"
          />
        </div>

        <div class="arrow-area">
          <img src="images/aboutus/sustainability/net_zero/arrow.svg" alt="img"/>
        </div>

        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2050Jp.title"
            :desc="card2050Jp.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "カーボンニュートラル戦略"
    >
      <div class="carbon-neutrality-strategy">
        <div
          v-for="(it, idx) in strategyJp"
          :key="idx"
          class="card-area"
        >
          <CarbonNeutralityStrategyCard
            :imageSrc="it.imageSrc"
            :title="it.title"
            :desc="it.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Дорожная карта углеродной нейтральности LX Hausys"
    v-else-if="nationCode == '_RU'"
  >
    <!-- chart 이미지 -->
    <OverviewSectionItem>
      <img
        src="images/aboutus/sustainability/net_zero/chart.svg"
        style="max-width: 1440px;"
      >
    </OverviewSectionItem>

    <!-- 차트 설명문 -->
    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text = "LX Hausys отражает национальную декларацию «Углеродная нейтральность» на 2050 год и цель «Национально определенные вклады» 
        (NDC) при определении ожидаемых средне- и долгосрочных выбросов ПГ к 2050 году и ставит перед собой задачу достичь «Net Zero» к 2050 году с 
        учетом характеристики и плана сокращения выбросов ПГ. Мы используем различные средства для достижения целей нашего плана по 
        сокращению выбросов и реализации углеродной нейтральности, тем самым выполняя свою корпоративную социальную ответственность."
      />
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "План по сокращению выбросов парниковых газов"
    >
      <div class="reduction-plan-area">
        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2030Ru.title"
            :desc="card2030Ru.desc"
          />
        </div>

        <div class="arrow-area">
          <img src="images/aboutus/sustainability/net_zero/arrow.svg" alt="img"/>
        </div>

        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2050Ru.title"
            :desc="card2050Ru.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "Стратегия углеродной нейтральности"
    >
      <div class="carbon-neutrality-strategy">
        <div
          v-for="(it, idx) in strategyRu"
          :key="idx"
          class="card-area"
        >
          <CarbonNeutralityStrategyCard
            :imageSrc="it.imageSrc"
            :title="it.title"
            :desc="it.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="LX Hausys碳中和路线图"
    v-else-if="nationCode == '_CN'"
  >
    <!-- chart 이미지 -->
    <OverviewSectionItem>
      <img
        src="images/aboutus/sustainability/net_zero/chart.svg"
        style="max-width: 1440px;"
      >
    </OverviewSectionItem>

    <!-- 차트 설명문 -->
    <OverviewSectionItem>
      <OverviewText
        type="ft-20"
        text = "LX Hausys在制定到2050年的预计中长期温室气体排放量时，反映了国家2050年碳中和的国家宣言和国家自主贡献（NDC）目标，
        并考虑到其温室气体排放特性和减排计划，制定了到2050年实现净零排放的目标。我们采用各种手段来实现我们的减排计划目标，
        实现碳中和，从而履行我们的企业社会责任。"
      />
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "温室气体减排计划"
    >
      <div class="reduction-plan-area">
        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2030Cn.title"
            :desc="card2030Cn.desc"
          />
        </div>

        <div class="arrow-area">
          <img src="images/aboutus/sustainability/net_zero/arrow.svg" alt="img"/>
        </div>

        <div class="card-area">
          <GhgReductionPlanCard
            :title="card2050Cn.title"
            :desc="card2050Cn.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem
      title = "碳中和战略"
    >
      <div class="carbon-neutrality-strategy">
        <div
          v-for="(it, idx) in strategyCn"
          :key="idx"
          class="card-area"
        >
          <CarbonNeutralityStrategyCard
            :imageSrc="it.imageSrc"
            :title="it.title"
            :desc="it.desc"
          />
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import OverviewText from '../../../overview/components/OverviewText.vue'
import GhgReductionPlanCard from './GhgReductionPlanCard.vue'
import CarbonNeutralityStrategyCard from './CarbonNeutralityStrategyCard.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    OverviewText,
    GhgReductionPlanCard,
    CarbonNeutralityStrategyCard
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      card2030: {
        title: 'By 2030',
        desc: [
          'Systematic calculation of fuel transition and emissions amount',
          'Introduction of 20% of renewable energy to the power consumption',
          'Purchase GHG emissions credit'
        ]
      },
      card2030Jp: {
        title: '2030年までに',
        desc: [
          '燃料転換と排出量の体系的な算出',
          '電力消費量に再生可能エネルギーを20%導入',
          '温室効果ガス排出クレジットを購入'
        ]
      },
      card2030Ru: {
        title: 'К 2030 году',
        desc: [
          'Систематический расчет перехода на другое топливо и количества выбросов',
          'Внедрение 20% возобновляемых источников энергии в энергопотребление',
          'Приобретение кредитов на выбросы ПГ'
        ]
      },
      card2030Cn: {
        title: 'By 2030',
        desc: [
          '燃料转换和排放量的系统计算',
          '在电力消耗中引入20%的可再生能源',
          '购买温室气体排放信用额度'
        ]
      },
      card2050: {
        title: 'By 2050',
        desc: [
          'Introduction of eco-friendly power generation facilities (e.g., solar panels)',
          'Introduction of 100% of renewable energy to the power consumption',
          'Purchase GHG emissions credit'
        ]
      },
      card2050Jp: {
        title: '2050年までに',
        desc: [
          '環境に優しい発電設備（太陽光パネルなど）の導入',
          '電力消費量に再生可能エネルギーを100%導入',
          '温室効果ガス排出クレジットを購入'
        ]
      },
      card2050Ru: {
        title: 'К 2050 году',
        desc: [
          'Внедрение экологически чистых средств производства электроэнергии (например, солнечных батарей)',
          'Внедрение 100% возобновляемой энергии в энергопотребление',
          'Приобретение кредитов на выбросы ПГ'
        ]
      },
      card2050Cn: {
        title: 'By 2050',
        desc: [
          '引入环保发电设施（如太阳能电池板）',
          '将100%可再生能源引入电力消费',
          '购买温室气体排放信用额度'
        ]
      },
      strategy: [
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-1.svg',
          title: `Reduce carbon emissions
in business sites`,
          desc: [
            `Install solar power generation facilities in
business sites`
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-2.svg',
          title: `Increase consumption
of renewable energy`,
          desc: [
            'Introduce renewable energy',
            'Change company-owned vehicles to electric vehicles'
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-3.svg',
          title: `Increase low-carbon
product range`,
          desc: [
            'Increase low-carbon product range'
          ]
        }
      ],
      strategyJp: [
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-1.svg',
          title: `事業所における炭素排出量の削減`,
          desc: [
            `事業所内に太陽光発電設備を設置する`
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-2.svg',
          title: `再生可能エネルギーの消費を増やす`,
          desc: [
            '再生可能エネルギーを導入する',
            '社有車を電気自動車に変更'
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-3.svg',
          title: `低炭素製品の範囲を拡大`,
          desc: [
            '低炭素製品の範囲を拡大'
          ]
        }
      ],
      strategyRu: [
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-1.svg',
          title: `Сократить выбросы углекислого газа
на территории предприятий`,
          desc: [
            `Установить солнечные электростанции на
территории предприятий`
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-2.svg',
          title: `Увеличить потребление
возобновляемых источников энергии`,
          desc: [
            'Внедрить возобновляемые источники энергии',
            'Заменить принадлежащие компании автомобили на электромобили'
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-3.svg',
          title: `Увеличить ассортимент продукции с низким содержанием углерода`,
          desc: [
            'Расширить ассортимент низкоуглеродной продукции'
          ]
        }
      ],
      strategyCn: [
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-1.svg',
          title: `减少工作场的碳排放`,
          desc: [
            `在工作场所安装太阳能发电设施`
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-2.svg',
          title: `增加可再生能源消费`,
          desc: [
            '引入可再生能源',
            '将公司所有的汽车改为电动汽车'
          ]
        },
        {
          imageSrc: 'images/aboutus/sustainability/net_zero/strategy-icon-3.svg',
          title: `增加低碳产品范围`,
          desc: [
            '增加低碳产品范围'
          ]
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.reduction-plan-area {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1440px) {
  .reduction-plan-area {
    flex-direction: column;
  }
}

.reduction-plan-area .card-area {
  width: 650px;
}
@media (max-width: 650px) {
  .reduction-plan-area .card-area {
    width: 100%;
  }
}

.reduction-plan-area .arrow-area {
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1440px) {
  .reduction-plan-area .arrow-area {
    min-height: 100px;
  }
}

.reduction-plan-area .arrow-area img {
  width: 40px;
  height: 40px;
}
@media (max-width: 1440px) {
  .reduction-plan-area .arrow-area img {
    transform: rotate(90deg);
  }
}

.carbon-neutrality-strategy {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.carbon-neutrality-strategy .card-area {
  width: 467px;
  border-radius: 20px;
  background-color: #EBEBEB;
}
@media (max-width: 467px) {
  .carbon-neutrality-strategy .card-area {
    width: 100%;
  }
}

</style>
