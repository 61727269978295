import request from './request'
const urlPrefix = ''

const SearchService = {
  product (data) {
    return request({
      url: `${urlPrefix}/search/product`,
      method: 'get',
      params: data
    })
  },
  homedepot (data) {
    return request({
      url: `${urlPrefix}/search/homedepot`,
      method: 'get',
      params: data
    })
  },
  byType (data) {
    return request({
      url: `${urlPrefix}/search/type`,
      method: 'get',
      params: data
    })
  },
  collectionByType (data) {
    return request({
      url: `${urlPrefix}/search/collectionType`,
      method: 'get',
      params: data
    })
  },
  collection (data) {
    return request({
      url: `${urlPrefix}/search/collection`,
      method: 'get',
      params: data
    })
  },
  all (data) {
    return request({
      url: `${urlPrefix}/search/all`,
      method: 'get',
      params: data
    })
  },
  suggestion (word) {
    return request({
      url: `${urlPrefix}/search/suggestion?word=${word}`,
      method: 'get'
    })
  },
  popular() {
  return request({
    url: `${urlPrefix}/search/popular`,
    method: 'get'
  })
},
}

export default SearchService
