var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("progressComponent"),
      _c(
        "header",
        {
          staticClass: "scr-hder",
          class: {
            main:
              _vm.link === "/" ||
              _vm.link === "/" + _vm.countryType + "/index" ||
              _vm.link === "/index" ||
              _vm.link === "/index/" ||
              _vm.link === "/" + _vm.countryType + "/index/",
            on: true,
            isEU: _vm.languageWrapper,
          },
        },
        [
          _c("div", { staticClass: "top_bar" }, [
            _c(
              "ul",
              [
                _vm._l(_vm.utilityList, function (utility) {
                  return _c("li", { attrs: { value: utility.content.title } }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openLink(utility)
                          },
                        },
                      },
                      [_vm._v(_vm._s(utility.content.title))]
                    ),
                  ])
                }),
                _vm.$countryCode == "_US"
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "javascript:roomvo.startStandaloneVisualizer('','');",
                          },
                        },
                        [_vm._v("Visualizer")]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "clear" }, [
            _c("div", { staticClass: "fl-l" }, [
              _vm._m(0),
              _c("div", { staticClass: "icon_lang_wrap" }, [
                _c("a", {
                  staticClass: "icon_lang",
                  attrs: { id: "btnLang", type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.handleHeaderLang()
                    },
                  },
                }),
                _c("div", { staticClass: "select_lang_pop mobile_lang_pop" }, [
                  _c("div", { staticClass: "country_area" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "gl" },
                            attrs: { href: "/gl/index" },
                          },
                          [_vm._v("Global")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "us" },
                            attrs: { href: "/us/index" },
                          },
                          [_vm._v("United States")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "eu" },
                            on: {
                              click: function ($event) {
                                _vm.selectEuLang = !_vm.selectEuLang
                              },
                            },
                          },
                          [_vm._v("Europe")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "uk" },
                            attrs: { href: "/uk-en/index" },
                          },
                          [_vm._v("UK")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "cn" },
                            attrs: { href: "http://lxhausys-cn.com/" },
                          },
                          [_vm._v("China")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "ru" },
                            attrs: { href: "https://lxhausys.com.ru/" },
                          },
                          [_vm._v("Russia")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "ru-himacs" },
                            attrs: { href: "https://hi-macs.ru/" },
                          },
                          [_vm._v("Russia_HIMACS")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "in" },
                            attrs: { href: "/gl/index" },
                          },
                          [_vm._v("India")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { on: _vm.currentRegion === "ko" },
                            attrs: { href: "/gl/index" },
                          },
                          [_vm._v("Korea")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.selectEuLang
                    ? _c("div", { staticClass: "lang_area" }, [_vm._m(1)])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "lang_dimmed" }),
            ]),
            _c("div", { staticClass: "fl-c" }, [
              _c(
                "ul",
                {
                  staticClass: "menu wide-pc",
                  class: {
                    main: _vm.link == "/" + _vm.countryType + "/index.html",
                  },
                },
                _vm._l(_vm.menuTree.data, function (tree, menuTreeIdx) {
                  return _c(
                    "li",
                    { key: tree.id, attrs: { "data-gnb": "mlist-" + tree.id } },
                    [
                      _vm._v("> "),
                      _c("a", { attrs: { href: "javascript:void(0);" } }, [
                        _c("span", { staticClass: "menu_text_1depth" }, [
                          _vm._v(_vm._s(tree.title)),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "fl-r" }, [
              _c("div", { staticClass: "hd-btns01" }, [
                _c("ul", [
                  _c("li", [
                    _c("a", {
                      staticClass: "icon_search",
                      attrs: {
                        href: "javascript:void(0);",
                        title: "Open Search Popup",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openSearch()
                        },
                      },
                    }),
                  ]),
                  _c("li", { staticStyle: { display: "none" } }, [
                    _vm.country == "_US"
                      ? _c(
                          "a",
                          {
                            staticClass: "icon_like",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoLikeUrl(
                                  "like/header/headerLikes"
                                )
                              },
                            },
                          },
                          [
                            _vm.likeCnt > 0
                              ? _c("span", [_vm._v(_vm._s(_vm.likeCnt))])
                              : _vm._e(),
                          ]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "icon_like",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoLikeUrl(
                                  "like/header/headerLikesPD"
                                )
                              },
                            },
                          },
                          [
                            _vm.likeCnt > 0
                              ? _c("span", [_vm._v(_vm._s(_vm.likeCnt))])
                              : _vm._e(),
                          ]
                        ),
                  ]),
                  _vm.country == "_US"
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "icon_cart bubble_on",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.openCart()
                              },
                            },
                          },
                          [
                            _vm.cartCount < 100
                              ? _c("span", { staticClass: "bubble_num" }, [
                                  _c("span", [_vm._v(_vm._s(_vm.cartCount))]),
                                ])
                              : _c("span", { staticClass: "bubble_num" }, [
                                  _c("span", [_vm._v("99")]),
                                  _c("span", [_vm._v("+")]),
                                ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.country !== "_US" && _vm.country !== "_GL"
                    ? _c("li", { staticClass: "p-ui" }, [
                        _c(
                          "a",
                          {
                            staticClass: "ico-11",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoUrl("imglib/imageLib")
                              },
                            },
                          },
                          [
                            _vm.imgLibCnt > 0
                              ? _c("span", [_vm._v(_vm._s(_vm.imgLibCnt))])
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.country !== "_US" && _vm.country !== "_GL"
                    ? _c("li", [
                        _c("a", {
                          staticClass: "ico-05",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              _vm.gotoUrl(
                                "support/where-to-buy/country/" +
                                  _vm.$countryCode.substring(1).toLowerCase()
                              )
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("li", { ref: "langetc", staticClass: "icon_lang_wrap" }, [
                    _c("a", {
                      staticClass: "icon_lang",
                      attrs: { id: "btnLang", type: "button" },
                      on: {
                        click: [
                          function ($event) {
                            return _vm.handleHeaderLang()
                          },
                          function ($event) {
                            _vm.selectEuLang = false
                          },
                        ],
                      },
                    }),
                    _c("div", { staticClass: "select_lang_pop" }, [
                      _c("div", { staticClass: "country_area" }, [
                        _c("ul", [
                          _c("li", [
                            _c(
                              "a",
                              {
                                class: { on: _vm.country === "_GL" },
                                attrs: {
                                  href: "https://www.lxhausys.com/gl/index",
                                },
                              },
                              [_vm._v("Global")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                class: { on: _vm.country === "_US" },
                                attrs: {
                                  href: "https://www.lxhausys.com/us/index",
                                },
                              },
                              [_vm._v("United States")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.selectEuLang = !_vm.selectEuLang
                                  },
                                },
                              },
                              [_vm._v("Europe")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._m(3),
                          _vm._m(4),
                          _vm._m(5),
                          _vm._m(6),
                          _vm._m(7),
                        ]),
                      ]),
                      _vm.selectEuLang
                        ? _c("div", { staticClass: "lang_area" }, [_vm._m(8)])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("li", { attrs: { id: "m-menu" } }, [
                    _c("a", {
                      staticClass: "icon-hamburger",
                      on: {
                        click: function ($event) {
                          return _vm.mobile_gnbSlideDown()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("SearchPopComponent"),
          _c("CartPopComponent"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("a", { staticClass: "go_home_for_pc", attrs: { href: "index" } }, [
        _vm._v("LX Hausys"),
      ]),
      _c("a", { staticClass: "go_home_for_mobile", attrs: { href: "index" } }, [
        _vm._v("LX Hausys"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { staticClass: "on", attrs: { href: "/gl/europe/en" } }, [
          _vm._v("English"),
        ]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/fr" } }, [_vm._v("Francais")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/de" } }, [_vm._v("Deutsch")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/it" } }, [_vm._v("Italiano")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/es" } }, [_vm._v("Espanol")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/gl/uk-hflor" } }, [_vm._v("UK")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "http://lxhausys-cn.com/" } }, [
        _vm._v("China"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "https://lxhausys.com.ru/" } }, [
        _vm._v("Russia"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "https://hi-macs.ru/" } }, [
        _vm._v("Russia_HIMACS"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "https://www.lxhausys.com/gl/index" } }, [
        _vm._v("India"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "https://www.lxzin.com/" } }, [_vm._v("Korea")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/en" } }, [_vm._v("English")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/fr" } }, [_vm._v("Francais")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/de" } }, [_vm._v("Deutsch")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/it" } }, [_vm._v("Italiano")]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "/gl/europe/es" } }, [_vm._v("Espanol")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }