<template>
  <OverviewSmallSection title="Automotive" fontWeight="700" fontFamily="Lato" v-if="nationCode == '_US' || nationCode == '_GL'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItems" :desc="desc" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- JP -->
  <OverviewSmallSection title="自動車関連" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_JP'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItemsJp" :desc="descJp" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- RU -->
  <OverviewSmallSection title="Для автомобиля" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_RU'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItemsRu" :desc="descRu" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- CN -->
  <OverviewSmallSection title="汽车配件材料" fontWeight="700" fontFamily="Lato" v-else-if="nationCode == '_CN'">
    <OverviewSectionItem>
      <BusinessAreaCarousel :items="carouselItemsCn" :desc="desc" :from="from"/>
    </OverviewSectionItem>
  </OverviewSmallSection>
</template>

<script>
import OverviewSmallSection from '../../components/OverviewSmallSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import BusinessAreaCarousel from '../components/BusinessAreaCarousel.vue'

export default {
  components: {
    OverviewSmallSection,
    OverviewSectionItem,
    BusinessAreaCarousel
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      carouselItems: [
        { imageSrc: 'images/aboutus/overview/business_area/automotive-1.jpg', title: 'Automotive Component', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-2.jpg', title: 'Vegan Leather' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-3.jpg', title: 'Lightweight Component' }
      ],
      carouselItemsJp: [
        { imageSrc: 'images/aboutus/overview/business_area/automotive-1.jpg', title: '自動車用コンポーネント', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-2.jpg', title: '自動車用スキン' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-3.jpg', title: '軽量コンポーネント' }
      ],
      carouselItemsRu: [
        { imageSrc: 'images/aboutus/overview/business_area/automotive-1.jpg', title: 'Автомобильные компоненты', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-2.jpg', title: 'Кожа для салона автомобиля' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-3.jpg', title: 'Легкие пластиковые компоненты' }
      ],
      carouselItemsCn: [
        { imageSrc: 'images/aboutus/overview/business_area/automotive-1.jpg', title: '汽车零部件', color: 'white' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-2.jpg', title: '汽车表皮' },
        { imageSrc: 'images/aboutus/overview/business_area/automotive-3.jpg', title: '汽车轻量化部件' }
      ],
      desc: 'We provide lightweight parts that contribute to the enhancement of fuel efficiency by reducing the weight of automobiles, as well as a diversified interior design for automobiles.\n' +
  'We materialize a safe and comfortable driving space through automotive interior leather and interior parts that have been well acknowledged as eco-friendly products.\n',
      descJp:'自動車の軽量化による燃費向上や、自動車の多様化する内装デザインに貢献する軽量化部品を提供しています。\n' +
      '環境に配慮した製品として評価の高い自動車内装皮革や内装部品を通じて、安全で快適なドライブ空間を実現します。',
      descRu:'Мы предлагаем легкие детали, которые способствуют повышению топливной эффективности за счет снижения веса автомобилей,\n' + 
      'а также разнообразный дизайн интерьера для автомобилей. Мы создаем безопасное и комфортное пространство для вождения благодаря производству\n' + 
      'кожи для салона и деталей интерьера, которые получили признание как экологически чистые продукты.',
      descCn:'通过减少汽车重量来优化燃料消耗率的轻量化零部件，为汽车内部提供多样化设计并通过环保产品认证的汽车表皮及零部件，\n' + 
      'LX Hausys致力于为顾客提供安全舒适的驾驶空间。',
      from: 'Automotive'
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>
