var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FLOOR_SPT", {
    attrs: {
      category: _vm.category,
      collectionList: _vm.collectionList,
      dictMap: _vm.dictMap,
    },
    on: { "category-changed": _vm.handleCategoryChanged },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }