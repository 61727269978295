var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hsrd-list HSRT02", class: { on: _vm.isOn } },
    [
      _c("p", { staticClass: "blind" }, [_vm._v("VIATERA")]),
      _vm.searchResult && _vm.searchResult.totalCount > 0
        ? _c(
            "ul",
            _vm._l(_vm.searchResult.list, function (sr) {
              return _c("li", [
                _c("ul", { staticClass: "hsrd-list-detail" }, [
                  _c("li", { staticClass: "info00" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.$imagePath + sr.attribute.pf_thumb,
                        alt: sr.title,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.gotoDetail(sr, "viatera")
                        },
                      },
                    }),
                  ]),
                  _c("li", { staticClass: "info02" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoDetail(sr, "viatera")
                          },
                        },
                      },
                      [_vm._v(_vm._s(sr.content.title))]
                    ),
                  ]),
                  _c("li", { staticClass: "info03" }, [
                    _c("span", [_vm._v(_vm._s(sr.parents[0].name))]),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "hsrd-btn-area more-box ta-r" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("search-page-event")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("search.search_view_all")))])]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }