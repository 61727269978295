<template>
</template>
<script>
export default ({
  name: 'eu-hflor',
  data () {
    return {}
  },
  mounted () {
    location.href = 'http://www.lxhausys.com/eu-en'
  },
  methods: {}
})
</script>
