<template>
  <div class="expanded-waste-recycling-grid">
    <pre class="grid-title">{{ gridData.gridTitle }}</pre>
    <div class="item-area">
      <pre class="item">{{ gridData.item }}</pre>
      <ul class="item-list">
        <li v-for="item in gridData.itemList" :key="item"><span v-html="item"></span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gridData: {
      type: Object
    }
  }
}
</script>

<style>
.expanded-waste-recycling-grid {
  display: flex;
  height: 304px;
  padding: 0px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 20px;
  background: #ECECEC;
  gap: 30px;
}

@media (max-width: 768px) {
  .expanded-waste-recycling-grid {
    width: 100%;
    align-items: center;
    padding: auto;
  }
}

.expanded-waste-recycling-grid .grid-title {
  color: #222;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 113.333% */
}

@media (max-width: 768px) {
  .expanded-waste-recycling-grid .grid-title {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.expanded-waste-recycling-grid .item-area {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.expanded-waste-recycling-grid .item-area .item {
  color: #222;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 170% */
}

@media (max-width: 768px) {
  .expanded-waste-recycling-grid .item-area .item {
    font-size: 0.875rem;
    line-height: 1.4583333333rem;
  }
}

.expanded-waste-recycling-grid .item-area .item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  list-style: square;
}

.expanded-waste-recycling-grid .item-area .item-list li::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: black;
  margin-right: 5px;
  /* margin-left: 20px; */
  /* margin-bottom: 3px */
}

.expanded-waste-recycling-grid .item-area .item-list span {
  color: #222;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

@media (max-width: 768px) {
  .expanded-waste-recycling-grid .item-area .item-list span {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }
}
</style>
