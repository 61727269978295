var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content content-finder" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("PCD.text.home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            _c("li", [_vm._v("VIATERA")]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container prod-fd-view container_pf viatera-pf" },
          [
            _c("div", { staticClass: "visual visual-detail" }, [
              _vm.pfBanImg
                ? _c(
                    "div",
                    {
                      staticClass: "visual-image full_width center",
                      style: {
                        backgroundImage:
                          "url( " + _vm.$imagePath + _vm.pfBanImg + ")",
                      },
                    },
                    [_vm._m(0)]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "drop-list inner" }, [
              _c("div", { staticClass: "w-con-02" }, [
                _c("div", { staticClass: "gallery" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c("div", { staticClass: "filter_area" }, [
                      _c("div", { staticClass: "filter_wrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn_filter",
                            attrs: { type: "button" },
                            on: { click: _vm.toggleFilterPopup },
                          },
                          [
                            _c("span", { staticClass: "icon icon_filter" }),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v("Filter"),
                            ]),
                            _c("span", { staticClass: "icon icon_arrow_down" }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "compare_wrap" }, [
                        _c("input", {
                          staticClass: "compare_btn",
                          attrs: {
                            type: "checkbox",
                            id: "compare_btn",
                            name: "compare_button",
                            value: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.compareOn = !_vm.compareOn
                            },
                          },
                        }),
                        _vm._m(1),
                        _c("span", [_vm._v("Compare")]),
                      ]),
                      _c("div", { staticClass: "search" }, [
                        _c("input", {
                          attrs: {
                            type: "text",
                            id: "textSearch",
                            placeholder: this.$t("PF.text.searchText"),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.textSearch($event)
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "icon_search",
                            attrs: { type: "button", id: "searchBtn" },
                            on: {
                              click: function ($event) {
                                return _vm.textSearchDel()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("PF.button.search")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm.showPopup
                      ? _c("div", { staticClass: "filter_pop" }, [
                          _c("div", { staticClass: "filter_tit" }, [
                            _c("p", [_vm._v("FILTER BY")]),
                            _c("button", {
                              staticClass: "btn_close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.close_popup()
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "filter_top" }, [
                            _c("ul", [
                              _vm._m(2),
                              _vm.useCategoryId === 483
                                ? _c("li", [
                                    _vm._m(3),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "checkbox_box color_round",
                                          },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              _vm._l(
                                                _vm.lefthue,
                                                function (lefthue) {
                                                  return _c("li", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.selectedHues,
                                                          expression:
                                                            "selectedHues",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                        id:
                                                          "hue_" +
                                                          lefthue.id.value,
                                                        name: "color",
                                                      },
                                                      domProps: {
                                                        value: lefthue.id.value,
                                                        checked: Array.isArray(
                                                          _vm.selectedHues
                                                        )
                                                          ? _vm._i(
                                                              _vm.selectedHues,
                                                              lefthue.id.value
                                                            ) > -1
                                                          : _vm.selectedHues,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.selectedHues,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v =
                                                                lefthue.id
                                                                  .value,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.selectedHues =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.selectedHues =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.selectedHues =
                                                              $$c
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    lefthue.text === "Other"
                                                      ? _c("i", [
                                                          _c("span", {
                                                            style:
                                                              "background-image : url(images/products/" +
                                                              lefthue.id.value +
                                                              ".png)",
                                                          }),
                                                          _vm._v(
                                                            _vm._s(lefthue.text)
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: `hue_${lefthue.id.value}`,
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "round",
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                lefthue.text
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.useCategoryId === 483
                                ? _c("li", [
                                    _vm._m(4),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "checkbox_box color_round",
                                          },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              _vm._l(
                                                _vm.lefthue,
                                                function (lefthue) {
                                                  return _c("li", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.selectedHues,
                                                          expression:
                                                            "selectedHues",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                        id:
                                                          "hue_" +
                                                          lefthue.id.value,
                                                        name: "color",
                                                      },
                                                      domProps: {
                                                        value: lefthue.id.value,
                                                        checked: Array.isArray(
                                                          _vm.selectedHues
                                                        )
                                                          ? _vm._i(
                                                              _vm.selectedHues,
                                                              lefthue.id.value
                                                            ) > -1
                                                          : _vm.selectedHues,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.selectedHues,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v =
                                                                lefthue.id
                                                                  .value,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.selectedHues =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.selectedHues =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.selectedHues =
                                                              $$c
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    lefthue.text === "Other"
                                                      ? _c("i", [
                                                          _c("span", {
                                                            style:
                                                              "background-image : url(images/products/" +
                                                              lefthue.id.value +
                                                              ".png)",
                                                          }),
                                                          _vm._v(
                                                            _vm._s(lefthue.text)
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: `hue_${lefthue.id.value}`,
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "round",
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                lefthue.text
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("li", [
                                _vm._m(5),
                                _c(
                                  "div",
                                  { staticClass: "filter_checkbox_cont" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "checkbox_box color_round",
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "checkbox_more no_line",
                                          },
                                          _vm._l(
                                            _vm.lefthue,
                                            function (lefthue) {
                                              return _c("li", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.selectedHues,
                                                      expression:
                                                        "selectedHues",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    id:
                                                      "hue_" + lefthue.id.value,
                                                    name: "color",
                                                  },
                                                  domProps: {
                                                    value: lefthue.id.value,
                                                    checked: Array.isArray(
                                                      _vm.selectedHues
                                                    )
                                                      ? _vm._i(
                                                          _vm.selectedHues,
                                                          lefthue.id.value
                                                        ) > -1
                                                      : _vm.selectedHues,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.selectedHues,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v =
                                                            lefthue.id.value,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.selectedHues =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.selectedHues =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.selectedHues = $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                lefthue.text === "Other"
                                                  ? _c("i", [
                                                      _c("span", {
                                                        style:
                                                          "background-image : url(images/products/" +
                                                          lefthue.id.value +
                                                          ".png)",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(lefthue.text)
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: `hue_${lefthue.id.value}`,
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "round",
                                                    }),
                                                    _c(
                                                      "span",
                                                      { staticClass: "text" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(lefthue.text)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("li", [
                                _vm._m(6),
                                _c(
                                  "div",
                                  { staticClass: "filter_checkbox_cont" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "checkbox_box color_square",
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "checkbox_more no_line",
                                          },
                                          _vm._l(
                                            _vm.leftpattern,
                                            function (leftpattern) {
                                              return _c("li", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedPatterns,
                                                      expression:
                                                        "selectedPatterns",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    id:
                                                      "pattern_" +
                                                      leftpattern.id.value,
                                                    name: "Patterns",
                                                  },
                                                  domProps: {
                                                    value: leftpattern.id.value,
                                                    checked: Array.isArray(
                                                      _vm.selectedPatterns
                                                    )
                                                      ? _vm._i(
                                                          _vm.selectedPatterns,
                                                          leftpattern.id.value
                                                        ) > -1
                                                      : _vm.selectedPatterns,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.selectedPatterns,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v =
                                                            leftpattern.id
                                                              .value,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.selectedPatterns =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.selectedPatterns =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.selectedPatterns =
                                                          $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: `pattern_${leftpattern.id.value}`,
                                                    },
                                                  },
                                                  [
                                                    _c("span"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          leftpattern.text
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("li", [
                                _vm._m(7),
                                _c(
                                  "div",
                                  { staticClass: "filter_checkbox_cont" },
                                  [
                                    _c("div", { staticClass: "checkbox_box" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "checkbox_more no_line",
                                        },
                                        _vm._l(
                                          _vm.leftcollection,
                                          function (coll) {
                                            return _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selectedCollections,
                                                    expression:
                                                      "selectedCollections",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: coll.category.name,
                                                  name: "collection",
                                                },
                                                domProps: {
                                                  value: coll.category.name,
                                                  checked: Array.isArray(
                                                    _vm.selectedCollections
                                                  )
                                                    ? _vm._i(
                                                        _vm.selectedCollections,
                                                        coll.category.name
                                                      ) > -1
                                                    : _vm.selectedCollections,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.selectedCollections,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v =
                                                          coll.category.name,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.selectedCollections =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.selectedCollections =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.selectedCollections =
                                                        $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: `${coll.category.name}`,
                                                  },
                                                },
                                                [
                                                  _c("span"),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        coll.category.name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm.useCategoryId === 502
                                ? _c("li", [
                                    _vm._m(8),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox_box" },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              _vm._l(
                                                _vm.leftfnsh,
                                                function (leftfnsh) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      attrs: {
                                                        "data-chk":
                                                          leftfnsh.id.value,
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.selectedFinishes,
                                                            expression:
                                                              "selectedFinishes",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "finish_" +
                                                            leftfnsh.id.value,
                                                          name: "Finish",
                                                        },
                                                        domProps: {
                                                          value:
                                                            leftfnsh.id.value,
                                                          checked:
                                                            Array.isArray(
                                                              _vm.selectedFinishes
                                                            )
                                                              ? _vm._i(
                                                                  _vm.selectedFinishes,
                                                                  leftfnsh.id
                                                                    .value
                                                                ) > -1
                                                              : _vm.selectedFinishes,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.selectedFinishes,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  leftfnsh.id
                                                                    .value,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.selectedFinishes =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.selectedFinishes =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.selectedFinishes =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for:
                                                              "finish_" +
                                                              `${leftfnsh.id.value}`,
                                                          },
                                                        },
                                                        [
                                                          _c("span"),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                leftfnsh.text
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.nationCode == "_GL"
                                ? _c("li", [
                                    _vm._m(9),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox_box" },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              _vm._l(
                                                _vm.leftCountry,
                                                function (leftCountry) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      attrs: {
                                                        "data-chk":
                                                          leftCountry.id.value,
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.selectedCountry,
                                                            expression:
                                                              "selectedCountry",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "country_" +
                                                            leftCountry.id
                                                              .value,
                                                          name: "Country",
                                                        },
                                                        domProps: {
                                                          value:
                                                            leftCountry.id
                                                              .value,
                                                          checked:
                                                            Array.isArray(
                                                              _vm.selectedCountry
                                                            )
                                                              ? _vm._i(
                                                                  _vm.selectedCountry,
                                                                  leftCountry.id
                                                                    .value
                                                                ) > -1
                                                              : _vm.selectedCountry,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.selectedCountry,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  leftCountry.id
                                                                    .value,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.selectedCountry =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.selectedCountry =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.selectedCountry =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for:
                                                              "country_" +
                                                              `${leftCountry.id.value}`,
                                                          },
                                                        },
                                                        [
                                                          _c("span"),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                leftCountry.id
                                                                  .value
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("li", [
                                _vm._m(10),
                                _c(
                                  "div",
                                  { staticClass: "filter_checkbox_cont" },
                                  [
                                    _c("div", { staticClass: "checkbox_box" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "checkbox_more no_line",
                                        },
                                        [
                                          _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.selectedNewColors,
                                                  expression:
                                                    "selectedNewColors",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "isNew_true",
                                                value: "true",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectedNewColors
                                                )
                                                  ? _vm._i(
                                                      _vm.selectedNewColors,
                                                      "true"
                                                    ) > -1
                                                  : _vm.selectedNewColors,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.selectedNewColors,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "true",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.selectedNewColors =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.selectedNewColors =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.selectedNewColors = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              { attrs: { for: "isNew_true" } },
                                              [
                                                _c("span"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PF.text.new_colors"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm.useCategoryId === 483
                                ? _c("li", [
                                    _vm._m(11),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox_box" },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              [
                                                _c("li", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.selectedNewColors,
                                                        expression:
                                                          "selectedNewColors",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "isNew_true",
                                                      value: "true",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.selectedNewColors
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedNewColors,
                                                            "true"
                                                          ) > -1
                                                        : _vm.selectedNewColors,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.selectedNewColors,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = "true",
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedNewColors =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedNewColors =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.selectedNewColors =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "isNew_true",
                                                      },
                                                    },
                                                    [
                                                      _c("span"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PF.text.new_colors"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._m(12),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox_box" },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              [
                                                _c("li", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.selectedNewColors,
                                                        expression:
                                                          "selectedNewColors",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "isNew_true",
                                                      value: "true",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.selectedNewColors
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedNewColors,
                                                            "true"
                                                          ) > -1
                                                        : _vm.selectedNewColors,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.selectedNewColors,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = "true",
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedNewColors =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedNewColors =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.selectedNewColors =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "isNew_true",
                                                      },
                                                    },
                                                    [
                                                      _c("span"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PF.text.new_colors"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._m(13),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox_box" },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              [
                                                _c("li", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.selectedNewColors,
                                                        expression:
                                                          "selectedNewColors",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "isNew_true",
                                                      value: "true",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.selectedNewColors
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedNewColors,
                                                            "true"
                                                          ) > -1
                                                        : _vm.selectedNewColors,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.selectedNewColors,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = "true",
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedNewColors =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedNewColors =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.selectedNewColors =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "isNew_true",
                                                      },
                                                    },
                                                    [
                                                      _c("span"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PF.text.new_colors"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._m(14),
                                    _c(
                                      "div",
                                      { staticClass: "filter_checkbox_cont" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox_box" },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "checkbox_more no_line",
                                              },
                                              [
                                                _c("li", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.selectedNewColors,
                                                        expression:
                                                          "selectedNewColors",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: "isNew_true",
                                                      value: "true",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.selectedNewColors
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedNewColors,
                                                            "true"
                                                          ) > -1
                                                        : _vm.selectedNewColors,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.selectedNewColors,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = "true",
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedNewColors =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedNewColors =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.selectedNewColors =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "isNew_true",
                                                      },
                                                    },
                                                    [
                                                      _c("span"),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PF.text.new_colors"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "filter_bottom" }, [
                            _c("div", { staticClass: "btn_box" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn_wh",
                                  on: { click: _vm.filterClearAll },
                                },
                                [_vm._v("Clear All")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn_bk",
                                  on: { click: _vm.applyFilter },
                                },
                                [_vm._v("Apply")]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "sort_area" }, [
                      _vm.colorList.length > 0
                        ? _c("div", { staticClass: "select-view" }, [
                            _c(
                              "ul",
                              { staticClass: "list-filter-key" },
                              [
                                _vm._l(_vm.uniqueColors, function (color) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass: "sel",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.colorDelete(
                                            color.subject,
                                            color.name,
                                            color.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      color.subject == "Collection"
                                        ? _c("span", [
                                            _vm._v(_vm._s(color.subject)),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        { attrs: { vamasa: "color.type" } },
                                        [_vm._v(_vm._s(color.type))]
                                      ),
                                      _vm._v(" : "),
                                      _c("span", [
                                        _vm._v(" " + _vm._s(color.name) + " "),
                                      ]),
                                      _c(
                                        "button",
                                        { attrs: { type: "button" } },
                                        [_vm._v("✕")]
                                      ),
                                    ]
                                  )
                                }),
                                _c("li", { staticClass: "sel del_all" }, [
                                  _vm.colorList.length > 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn-all-delete",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearAll()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("PF.button.clear_all")
                                              ) + " "
                                            ),
                                            _c("em", [_vm._v("✕")]),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "btns" }, [
                        _c("ul", [
                          _c("li", { staticClass: "sel-box" }, [
                            _vm._m(15),
                            _c("div", { staticClass: "sel-list" }, [
                              _c("ul", { staticClass: "box" }, [
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortByChange(
                                            "Products A to Z",
                                            "asc",
                                            "title"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("PF.button.products_a_to_z")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortByChange(
                                            "Products Z to A",
                                            "desc",
                                            "title"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("PF.button.products_z_to_a")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortByChange(
                                            "New Products first",
                                            "desc"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "PF.button.new_products_first"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._m(16),
                      _vm.compareOn
                        ? _c(
                            "div",
                            {
                              staticClass: "more-box type02 type03 compare_box",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "button h55 btn_compare",
                                  attrs: { type: "button", id: "compare" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCompareList()
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("PF.button.compare_your_colors")
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "bubble_num" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.compareArr.length)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "count_area" }, [
                      _c("div", { staticClass: "filter-cnt" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.contentLength)),
                        ]),
                        _c("span", [_vm._v(" ")]),
                        _c("span", { staticClass: "txt" }, [_vm._v("Results")]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-type-view row-04" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.contentList, function (item, index) {
                      return _c(
                        "li",
                        { key: index },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.makeUrl(item),
                                "data-ec-product": _vm.makeDataLayerForm(item),
                              },
                            },
                            [
                              _c("div", { staticClass: "img-box over" }, [
                                item.attribute.isNew == "true"
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h30 black" },
                                      [_vm._v(_vm._s(_vm.$t("PF.text.new")))]
                                    )
                                  : _vm._e(),
                                _vm.useCategoryId === 502
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h22 viatera" },
                                      [_vm._v("VIATERA")]
                                    )
                                  : _vm.useCategoryId === 484
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h22 himacs" },
                                      [_vm._v("HI-MACS")]
                                    )
                                  : _vm.useCategoryId === 483
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h22 hflor" },
                                      [_vm._v("HFLOR")]
                                    )
                                  : _vm.useCategoryId === 543
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h22 teracanto" },
                                      [_vm._v("TERACANTO")]
                                    )
                                  : _vm.useCategoryId === 9246
                                  ? _c(
                                      "span",
                                      { staticClass: "tag h22 benif" },
                                      [_vm._v("BENIF")]
                                    )
                                  : _vm.useCategoryId === 9308
                                  ? _c("span", { staticClass: "tag h22 etc" }, [
                                      _vm._v("DECO FOIL"),
                                    ])
                                  : _vm.useCategoryId === 9469
                                  ? _c("span", { staticClass: "tag h22 etc" }, [
                                      _vm._v("EXTERIOR FOIL"),
                                    ])
                                  : _vm.useCategoryId === 9350
                                  ? _c("span", { staticClass: "tag h22 etc" }, [
                                      _vm._v("VIZUON"),
                                    ])
                                  : _vm._e(),
                                _c("span", { staticClass: "image_name" }, [
                                  _vm._v(_vm._s(item.content.title)),
                                ]),
                                _c("div", { staticClass: "icon_tag_wrap" }, [
                                  _c("i", { staticClass: "icon-no-fire" }, [
                                    _c("span", [_vm._v("no-fire")]),
                                  ]),
                                ]),
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.$imagePath + item.attribute.pf_thumb,
                                    alt: item.attribute.pf_thumb,
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "view" }),
                          _c("div", { staticClass: "pf_cont_compare_wrap" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "item.content.id",
                              },
                            }),
                            _c("label", { staticClass: "inp-chk" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.compareArr,
                                    expression: "compareArr",
                                  },
                                ],
                                staticClass: "pf_cont_compare",
                                attrs: {
                                  type: "checkbox",
                                  id: item.content.id,
                                },
                                domProps: {
                                  value: item.content.id,
                                  checked: Array.isArray(_vm.compareArr)
                                    ? _vm._i(_vm.compareArr, item.content.id) >
                                      -1
                                    : _vm.compareArr,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.compareArr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.content.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.compareArr = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.compareArr = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.compareArr = $$c
                                      }
                                    },
                                    function ($event) {
                                      return _vm.addCompare(item.content.id)
                                    },
                                  ],
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.button.compare"))),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "layer-popup layer-alert" }, [
              _c("div", { staticClass: "popup" }, [
                _c("div", { staticClass: "pop-alert" }, [
                  _c("div", [
                    _c("div", { staticClass: "tit-box" }, [
                      _c("p", { staticClass: "ctt ctt-t4 artTit" }, [
                        _vm._v(_vm._s(_vm.$t("PF.popup.your_compare_is_full"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "txt-box" }, [
                      _vm._m(17),
                      _vm._m(18),
                      _c("div", { staticClass: "btn-box" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button", id: "replace" },
                            on: {
                              click: function ($event) {
                                return _vm.replace()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.popup.replace"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "bottom-box" }, [
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.getCompareList()
                          },
                        },
                      },
                      [_c("span", [_vm._v("Edit products in compare")])]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(" close ")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "layer-popup layer-compare",
                attrs: { id: "pdf_wrap" },
              },
              [
                _c("div", { staticClass: "popup pop-close" }, [
                  _vm._m(19),
                  _c("div", { staticClass: "popup-compare-cont" }, [
                    _vm._m(20),
                    _c("div", { staticClass: "prod-list tbl-compare" }, [
                      _c("ul", [
                        _c(
                          "tr",
                          _vm._l(_vm.compareList, function (item, idx) {
                            return _c("td", [
                              _c("li", [
                                _c("div", { staticClass: "prod-box" }, [
                                  _c("div", { staticClass: "prod" }, [
                                    _c("div", { staticClass: "img-box" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            item.attribute.pf_thumb,
                                          alt: item.attribute.pf_thumb,
                                        },
                                      }),
                                      _c("div", { staticClass: "btn-remove" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn-remove02",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeCompare(
                                                  item.content.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PF.button.remove")
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "info" }, [
                                      _c("div", { staticClass: "name" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(item.content.title)),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "tag" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(item.parents[0].name)),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "tbl_wrap" }, [
                                      _c("table", [
                                        _vm._m(21, true),
                                        _c("tr", [
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.attribute.dimensionText
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _vm._m(22, true),
                                        _c("tr", [
                                          item.attribute.prdTypeText !=
                                          undefined
                                            ? _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute.prdTypeText
                                                    )
                                                  ),
                                                ]),
                                              ])
                                            : _c("td", [
                                                _c("span", [_vm._v("-")]),
                                              ]),
                                        ]),
                                        _vm._m(23, true),
                                        _c("tr", [
                                          item.attribute.thickWearLayer !=
                                          undefined
                                            ? _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute
                                                        .thickWearLayer
                                                    )
                                                  ),
                                                ]),
                                              ])
                                            : _c("td", [
                                                _c("span", [_vm._v("-")]),
                                              ]),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ])
                          }),
                          0
                        ),
                        _c("li", [
                          _c("div", { staticClass: "prod-box" }, [
                            _c("div", { staticClass: "prod" }, [
                              _c("div", { staticClass: "img-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "btn-add hf-btn-add" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        attrs: {
                                          type: "button",
                                          onclick: "LayerPopup.close();",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("PF.button.add_more"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "btn-download" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button h44 btn_download",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.makePDF()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "txt" }, [
                            _vm._v("Download"),
                          ]),
                          _c("span", { staticClass: "icon icon_download" }),
                        ]
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: {
                          type: "button",
                          onclick: "LayerPopup.close();",
                        },
                      },
                      [_vm._v(" close ")]
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
                staticStyle: { display: "none" },
                attrs: { id: "layer-search-box" },
              },
              [
                _c("div", { staticClass: "popup" }, [
                  _c("div", { staticClass: "scr-y" }, [
                    _c("div", { staticClass: "drop-list" }, [
                      _c("p", { staticClass: "tt-2" }, [_vm._v("Filter By")]),
                      _c("button", {
                        staticClass: "filter-clos",
                        on: {
                          click: function ($event) {
                            return _vm.closeSearchPopup()
                          },
                        },
                      }),
                      _c("ul", [
                        _c("li", { staticClass: "on" }, [
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-color",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.color")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                            ? _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk type03" },
                                    _vm._l(_vm.lefthue, function (lefthue) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk chk-color" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                name: "color",
                                                id: "hue_" + lefthue.id.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "hue",
                                                      lefthue.text,
                                                      lefthue.id.value,
                                                      "Tone"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            lefthue.text === "Other"
                                              ? _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-image : url(images/products/" +
                                                      lefthue.id.value +
                                                      ".png)",
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ])
                                              : _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-color:#" +
                                                      lefthue.id.value,
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ]),
                                            _c("span", [
                                              _vm._v(_vm._s(lefthue.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }),
          ],
          1
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "inner_width" }, [
      _c("h1", { staticClass: "txt-box" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "txt" }, [_vm._v("ALL COLORS")]),
        ]),
        _c("p", { staticClass: "txt-02" }, [
          _vm._v("Inspired by the Moment of Life, Inspired by Nature."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "compare_btn" } }, [
      _c("span", { staticClass: "txt" }, [_vm._v("ON")]),
      _c("span", { staticClass: "btn" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("div", { staticClass: "filter_checkbox_tit" }, [
        _c("span", [_vm._v("COMPARE")]),
      ]),
      _c("div", { staticClass: "compare_wrap" }, [
        _c("input", {
          staticClass: "compare_btn",
          attrs: {
            type: "checkbox",
            id: "compare_btn",
            name: "compare_button",
            value: "",
          },
        }),
        _c("label", { attrs: { for: "compare_btn" } }, [
          _c("span", { staticClass: "txt" }, [_vm._v("ON")]),
          _c("span", { staticClass: "btn" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("TILE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("SHEET")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("COLOR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("PATTERNS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("COLLECTION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("FINISH")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("COUNTRY")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("NEW")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("PRODUCT TYPE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("THICKNESS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("WEAR LAYER")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter_checkbox_tit" }, [
      _c("span", [_vm._v("DIMENSIONS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn-sel", attrs: { type: "button" } }, [
      _c("span", [_vm._v("Newest")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gallery_layout_btns" }, [
      _c(
        "button",
        { staticClass: "layout_grid on", attrs: { type: "button" } },
        [_c("span", { staticClass: "icon icon_grid" })]
      ),
      _c("button", { staticClass: "layout_list", attrs: { type: "button" } }, [
        _c("span", { staticClass: "icon icon_list" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgwrap" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/images/svg_icon/icon_alert_pop_check.svg",
          alt: "Your comparator is full",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "txt" }, [
      _c("p", [
        _vm._v(" Do you want to replace the first Products "),
        _c("br"),
        _vm._v(" you added by the one you selected? "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pop-compare scr-y" }, [
      _c("div", { staticClass: "tit-box" }, [
        _c("p", [_vm._v("COMPARE VIATERA PRODUCTS")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtit-box" }, [
      _c("p", [_vm._v("Characteristics & Technical Performance")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Dimensions")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("ProductType")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_c("span", [_vm._v("Thickness")])])])
  },
]
render._withStripped = true

export { render, staticRenderFns }