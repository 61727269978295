<template>
  <OverviewLayout
    imageSrc="images/aboutus/overview/global_network/top_image.png"
    title="Global Network"
    v-if="nationCode === '_US' || nationCode === '_GL'"
  >
    <template #content>
      <MapSection />
      <SouthKoreaCompanySection />
      <OverseasCompanySection />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/global_network/top_image.png"
    title="グローバルネットワーク"
    v-else-if="nationCode === '_JP'"
  >
    <template #content>
      <MapSection />
      <SouthKoreaCompanySection />
      <OverseasCompanySection />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/global_network/top_image.png"
    title="Глобальная сеть"
    v-else-if="nationCode === '_RU'"
  >
    <template #content>
      <MapSection />
      <SouthKoreaCompanySection />
      <OverseasCompanySection />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/global_network/top_image.png"
    title="全球网络"
    v-else-if="nationCode === '_CN'"
  >
    <template #content>
      <MapSection />
      <SouthKoreaCompanySection />
      <OverseasCompanySection />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../components/OverviewLayout.vue'
import MapSection from './components/MapSection.vue'
import SouthKoreaCompanySection from './components/SouthKoreaCompanySection.vue'
import OverseasCompanySection from './components/OverseasCompanySection.vue'

export default {
  components: {
    OverviewLayout,
    MapSection,
    SouthKoreaCompanySection,
    OverseasCompanySection
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
</style>
