<template>
  <div class="design_trend_section">
    <div class="design_trend_slider">
      <div class="item" v-for="banner in bannerList" :value="banner.content.title">
        <div class="txt-box">
          <p class="title">{{banner.content.title}}</p>
          <p class="txt-01">{{banner.attribute.MAIN_BANNER_TOBE_DESCRIPTION}}</p>
          <a @click="openLink(banner)" class="btn-more" v-if="banner.attribute.MAIN_BANNER_TOBE_URL">
            <span>View more</span>
          </a>
<!--          -->
<!--          <a class="btn-more" :href="banner.attribute.MAIN_BANNER_TOBE_URL || '#'" :target="banner.attribute.MAIN_BANNER_TOBE_WINDOW ? '_blank' : ''">-->
<!--            <span>View more</span>-->
<!--          </a>-->
        </div>
        <div class="bannerGradient" />
        <img :src="$imagePath + banner.attribute.MAIN_BANNER_TOBE_IMAGE" alt="designtrend" class="slider_bg" />
      </div>
    </div>
  </div>
</template>

<script>
import mainService from '@/server/main.service'

export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      photo_credit: '',
      bannerId: '',
      bannerList: [],
      countryCode: ''
    }
  },
  async mounted () {
    const _this = this

    const nationCode = this.$countryCode
    _this.countryCode = nationCode
    // _this.categoryCode = 'MAIN_BANNER_TOBE' + nationCode
    _this.categoryCode = 'MAIN_BANNER_TOBE' + nationCode

    const categoryRes = await mainService.getCategoryCode(_this.categoryCode)
    _this.bannerId = categoryRes.data.object.id

    const bannerList = await mainService.getContentListSort(_this.bannerId, 1, 4, 'sort', 'asc')
    _this.bannerList = bannerList.data.list

    _this.$nextTick(function () {
      $('.design_trend_slider').slick({
        autoplay: true,
        autoplaySpeed: 4500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        fade: false,
        speed: 1000,
        cssEase: 'linear',
        dots: true,
        draggable: true
      })
    })
  },
  methods: {
    openLink (windowOpenValue) {
      var windowOpen
      try {
        windowOpen = JSON.parse(windowOpenValue.attribute.MAIN_BANNER_TOBE_WINDOW)
      } catch (error) {
        // 파싱 실패나 값이 없을 경우 기존창으로 강제 셋팅
        windowOpen = { value: '002' } // 기본값 설정
      }

      if (!windowOpenValue.attribute.MAIN_BANNER_TOBE_URL) {
        return // Exit the function early if URL is empty
      }

      var value = windowOpen.value

      if (value === '001') {
        // 기존 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_BANNER_TOBE_URL, '_self') // '기존 창 URL'을 적절히 변경해주세요.
      } else if (value === '002') {
        // 새 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_BANNER_TOBE_URL, '_blank') // '새 창 URL'을 적절히 변경해주세요.
      }
    }
  }
}
</script>
