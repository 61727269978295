<template>
    <OverviewSection title="Financial Statement"
    color="black"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
    >

    <OverviewSmallSection title="Consolidated Profit and Loss Statement"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList1" :dataList="dataList1" />
    </div>

    <OverviewSmallSection title="Consolidated Financial Statement"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList2" :dataList="dataList2" />
    </div>

    </OverviewSection>
<!-- JP -->
  <OverviewSection title="財務諸表"
                   color="black"
                   size="56px"
                   v-else-if="nationCode == '_JP'">

    <OverviewSmallSection title="連結損益計算書"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList1_jp" :dataList="dataList1_jp" />
    </div>

    <OverviewSmallSection title="連結財務諸表"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList2_jp" :dataList="dataList2_jp" />
    </div>

  </OverviewSection>
<!-- RU -->
  <OverviewSection title="Финансовая отчетность"
                   color="black"
                   size="56px"
                   v-else-if="nationCode == '_RU'">

    <OverviewSmallSection title="Консолидированный отчет о прибылях и убытках"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList1_ru" :dataList="dataList1_ru" />
    </div>

    <OverviewSmallSection title="Консолидированная финансовая отчетность"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList2_ru" :dataList="dataList2_ru" />
    </div>

  </OverviewSection>
<!-- CN -->
  <OverviewSection title="财务报表"
                   color="black"
                   size="56px"
                   v-else-if="nationCode == '_CN'">

    <OverviewSmallSection title="合并损益表"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList1_cn" :dataList="dataList1_cn" />
    </div>

    <OverviewSmallSection title="合并财务报表"/>
    <FinancialStatementTableUnit content="(Unit : KRW 0.1 billion)"/>
    <div class="scroll-box">
      <FinancialStatementTable :categoryList="categoryList2_cn" :dataList="dataList2_cn" />
    </div>

  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSmallSection from '../../components/OverviewSmallSection.vue'
import FinancialStatementTable from './FinancialStatementTable.vue'
import FinancialStatementTableUnit from './FinancialStatementTableUnit.vue'

export default {
  components: {
    OverviewSection,
    OverviewSmallSection,
    FinancialStatementTableUnit,
    FinancialStatementTable
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      categoryList1: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList1: [
        {
          item: 'Sales Revenue',
          value1: '31,868',
          value2: '30,380',
          value3: '34,598',
          value4: '36,112',
          value5: '35,258',
          valueFontWeight: '700'
        },
        {
          item: 'COGS',
          value1: '24,401',
          value2: '22,848',
          value3: '26,276',
          value4: '28,511',
          value5: '26,582',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Gross Profit',
          value1: '7,468',
          value2: '7,532',
          value3: '8,322',
          value4: '7,601',
          value5: '8,406',
          valueFontWeight: '700'
        },
        {
          item: 'Selling & administrative expenses',
          value1: '6,780',
          value2: '6,822',
          value3: '7,617',
          value4: '7,452',
          value5: '7,308',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Operaing Income',
          value1: '688',
          value2: '710',
          value3: '705',
          value4: '149',
          value5: '1,098',
          valueFontWeight: '700'
        },
        {
          item: 'Other Income',
          value1: '161',
          value2: '670',
          value3: '387',
          value4: '476',
          value5: '225',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Continuing business profit before tax',
          value1: '138',
          value2: '-922',
          value3: '217',
          value4: '-1,059',
          value5: '706',
          valueFontWeight: '700'
        },
        {
          item: 'Corporate tax',
          value1: '-10',
          value2: '-123',
          value3: '69',
          value4: '54',
          value5: '66',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Net Income',
          value1: '116',
          value2: '-795',
          value3: '127',
          value4: '-1,177',
          value5: '618',
          valueFontWeight: '700'
        }
      ],
      categoryList2: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList2: [
        {
          item: 'Total assetes',
          value1: '25,656',
          value2: '23,854',
          value3: '25,474',
          value4: '24,837',
          value5: '23,848',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Current assets',
          value1: '10,224',
          value2: '10,727',
          value3: '11,487',
          value4: '10,788',
          value5: '11,053'
        },
        {
          item: 'Non-current assets',
          value1: '15,431',
          value2: '13,127',
          value3: '13,987',
          value4: '14,049',
          value5: '12,795'
        },
        {
          item: 'Total Liabilities',
          value1: '16,496',
          value2: '15,482',
          value3: '16,840',
          value4: '17,038',
          value5: '15,518',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Current liabilities',
          value1: '9,745',
          value2: '9,715',
          value3: '12,201',
          value4: '10,606',
          value5: '10,416'
        },
        {
          item: 'Non-current liabilities',
          value1: '6,751',
          value2: '5,766',
          value3: '4,639',
          value4: '6,432',
          value5: '5,102',
        },
        {
          item: 'Non-current liabilities',
          value1: '9,160',
          value2: '8,372',
          value3: '8,634',
          value4: '7,799',
          value5: '8,330',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Paid-in Capital',
          value1: '500',
          value2: '500',
          value3: '500',
          value4: '500',
          value5: '500'
        },
        {
          item: 'Capital surplus',
          value1: '6,165',
          value2: '6,165',
          value3: '6,161',
          value4: '6,158',
          value5: '6,158'
        },
        {
          item: 'Aggregate of other universal profit & loss',
          value1: '-193',
          value2: '-267',
          value3: '-124',
          value4: '-37',
          value5: '-0'
        },
        {
          item: 'Retained earnings',
          value1: '2,836',
          value2: '2,061',
          value3: '2,180',
          value4: '1,259',
          value5: '1,754'
        },
        {
          item: 'Other capital items',
          value1: '-165',
          value2: '-82',
          value3: '-82',
          value4: '-82',
          value5: '-82'
        }
      ],
      categoryList1_jp: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList1_jp: [
        {
          item: '売上高',
          value1: '31,868',
          value2: '30,380',
          value3: '34,598',
          value4: '36,112',
          value5: '35,258',
          valueFontWeight: '700'
        },
        {
          item: '売上原価',
          value1: '24,401',
          value2: '22,848',
          value3: '26,276',
          value4: '28,511',
          value5: '26,582',
          valueBackground: '#F9F9F9'
        },
        {
          item: '粗利益',
          value1: '7,468',
          value2: '7,532',
          value3: '8,322',
          value4: '7,601',
          value5: '8,406',
          valueFontWeight: '700'
        },
        {
          item: '販売費及び一般管理費',
          value1: '6,780',
          value2: '6,822',
          value3: '7,617',
          value4: '7,452',
          value5: '7,308',
          valueBackground: '#F9F9F9'
        },
        {
          item: '営業利益',
          value1: '688',
          value2: '710',
          value3: '705',
          value4: '149',
          value5: '1,098',
          valueFontWeight: '700'
        },
        {
          item: 'その他の収益',
          value1: '161',
          value2: '670',
          value3: '387',
          value4: '476',
          value5: '225',
          valueBackground: '#F9F9F9'
        },
        {
          item: '継続事業税引前利益',
          value1: '138',
          value2: '-922',
          value3: '217',
          value4: '-1,059',
          value5: '706',
          valueFontWeight: '700'
        },
        {
          item: '法人税',
          value1: '-10',
          value2: '-123',
          value3: '69',
          value4: '54',
          value5: '66',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '純利益',
          value1: '116',
          value2: '-795',
          value3: '127',
          value4: '-1,177',
          value5: '618',
          valueFontWeight: '700'
        }
      ],
      categoryList2_jp: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList2_jp: [
        {
          item: '総資産',
          value1: '25,656',
          value2: '23,854',
          value3: '25,474',
          value4: '24,837',
          value5: '23,848',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '流動資産',
          value1: '10,224',
          value2: '10,727',
          value3: '11,487',
          value4: '10,788',
          value5: '11,053'
        },
        {
          item: '非流動資産',
          value1: '15,431',
          value2: '13,127',
          value3: '13,987',
          value4: '14,049',
          value5: '12,795'
        },
        {
          item: '負債合計',
          value1: '16,496',
          value2: '15,482',
          value3: '16,840',
          value4: '17,038',
          value5: '15,518',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '流動負債',
          value1: '9,745',
          value2: '9,715',
          value3: '12,201',
          value4: '10,606',
          value5: '10,416'
        },
        {
          item: '非流動負債',
          value1: '6,751',
          value2: '5,766',
          value3: '4,639',
          value4: '6,432',
          value5: '5,102',
        },
        {
          item: '非流動負債',
          value1: '9,160',
          value2: '8,372',
          value3: '8,634',
          value4: '7,799',
          value5: '8,330',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '払込資本',
          value1: '500',
          value2: '500',
          value3: '500',
          value4: '500',
          value5: '500'
        },
        {
          item: '資本剰余金',
          value1: '6,165',
          value2: '6,165',
          value3: '6,161',
          value4: '6,158',
          value5: '6,158'
        },
        {
          item: 'その他一般損益の合計',
          value1: '-193',
          value2: '-267',
          value3: '-124',
          value4: '-37',
          value5: '-0'
        },
        {
          item: '利益剰余金',
          value1: '2,836',
          value2: '2,061',
          value3: '2,180',
          value4: '1,259',
          value5: '1,754'
        },
        {
          item: 'その他の資本項目',
          value1: '-165',
          value2: '-82',
          value3: '-82',
          value4: '-82',
          value5: '-82'
        }
      ],
      categoryList1_ru: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList1_ru: [
        {
          item: 'Выручка от реализации',
          value1: '31,868',
          value2: '30,380',
          value3: '34,598',
          value4: '36,112',
          value5: '35,258',
          valueFontWeight: '700'
        },
        {
          item: 'Себестоимость реализованной продукции',
          value1: '24,401',
          value2: '22,848',
          value3: '26,276',
          value4: '28,511',
          value5: '26,582',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Валовая прибыль',
          value1: '7,468',
          value2: '7,532',
          value3: '8,322',
          value4: '7,601',
          value5: '8,406',
          valueFontWeight: '700'
        },
        {
          item: 'Коммерческие и административные расходы',
          value1: '6,780',
          value2: '6,822',
          value3: '7,617',
          value4: '7,452',
          value5: '7,308',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Операционные доходы',
          value1: '688',
          value2: '710',
          value3: '705',
          value4: '149',
          value5: '1,098',
          valueFontWeight: '700'
        },
        {
          item: 'Прочие доходы',
          value1: '161',
          value2: '670',
          value3: '387',
          value4: '476',
          value5: '225',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Прибыль от продолжающейся деятельности до налогообложения',
          value1: '138',
          value2: '-922',
          value3: '217',
          value4: '-1,059',
          value5: '706',
          valueFontWeight: '700'
        },
        {
          item: 'Корпоративный налог',
          value1: '-10',
          value2: '-123',
          value3: '69',
          value4: '54',
          value5: '66',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Чистая прибыль',
          value1: '116',
          value2: '-795',
          value3: '127',
          value4: '-1,177',
          value5: '618',
          valueFontWeight: '700'
        }
      ],
      categoryList2_ru: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList2_ru: [
        {
          item: 'Итого активы',
          value1: '25,656',
          value2: '23,854',
          value3: '25,474',
          value4: '24,837',
          value5: '23,848',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Оборотные активы',
          value1: '10,224',
          value2: '10,727',
          value3: '11,487',
          value4: '10,788',
          value5: '11,053'
        },
        {
          item: 'Внеоборотные активы',
          value1: '15,431',
          value2: '13,127',
          value3: '13,987',
          value4: '14,049',
          value5: '12,795'
        },
        {
          item: 'Итого обязательства',
          value1: '16,496',
          value2: '15,482',
          value3: '16,840',
          value4: '17,038',
          value5: '15,518',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Текущие обязательства',
          value1: '9,745',
          value2: '9,715',
          value3: '12,201',
          value4: '10,606',
          value5: '10,416'
        },
        {
          item: 'Долгосрочные обязательства',
          value1: '6,751',
          value2: '5,766',
          value3: '4,639',
          value4: '6,432',
          value5: '5,102',
        },
        {
          item: 'Долгосрочные обязательства',
          value1: '9,160',
          value2: '8,372',
          value3: '8,634',
          value4: '7,799',
          value5: '8,330',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: 'Оплаченный капитал',
          value1: '500',
          value2: '500',
          value3: '500',
          value4: '500',
          value5: '500'
        },
        {
          item: 'Капитальный излишек',
          value1: '6,165',
          value2: '6,165',
          value3: '6,161',
          value4: '6,158',
          value5: '6,158'
        },
        {
          item: 'Совокупность прочих универсальных прибылей и убытков',
          value1: '-193',
          value2: '-267',
          value3: '-124',
          value4: '-37',
          value5: '-0'
        },
        {
          item: 'Нераспределенная прибыль',
          value1: '2,836',
          value2: '2,061',
          value3: '2,180',
          value4: '1,259',
          value5: '1,754'
        },
        {
          item: 'Прочие статьи капитала',
          value1: '-165',
          value2: '-82',
          value3: '-82',
          value4: '-82',
          value5: '-82'
        }
      ],
      categoryList1_cn: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList1_cn: [
        {
          item: '销售收入',
          value1: '31,868',
          value2: '30,380',
          value3: '34,598',
          value4: '36,112',
          value5: '35,258',
          valueFontWeight: '700'
        },
        {
          item: 'COGS',
          value1: '24,401',
          value2: '22,848',
          value3: '26,276',
          value4: '28,511',
          value5: '26,582',
          valueBackground: '#F9F9F9'
        },
        {
          item: '毛利润',
          value1: '7,468',
          value2: '7,532',
          value3: '8,322',
          value4: '7,601',
          value5: '8,406',
          valueFontWeight: '700'
        },
        {
          item: '销售&管理费用',
          value1: '6,780',
          value2: '6,822',
          value3: '7,617',
          value4: '7,452',
          value5: '7,308',
          valueBackground: '#F9F9F9'
        },
        {
          item: '营业收入',
          value1: '688',
          value2: '710',
          value3: '705',
          value4: '149',
          value5: '1,098',
          valueFontWeight: '700'
        },
        {
          item: '其他收入',
          value1: '161',
          value2: '670',
          value3: '387',
          value4: '476',
          value5: '225',
          valueBackground: '#F9F9F9'
        },
        {
          item: '税前持续经营利润',
          value1: '138',
          value2: '-922',
          value3: '217',
          value4: '-1,059',
          value5: '706',
          valueFontWeight: '700'
        },
        {
          item: '企业税',
          value1: '-10',
          value2: '-123',
          value3: '69',
          value4: '54',
          value5: '66',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '净利润增长',
          value1: '116',
          value2: '-795',
          value3: '127',
          value4: '-1,177',
          value5: '618',
          valueFontWeight: '700'
        }
      ],
      categoryList2_cn: [
        'Item',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023'
      ],
      dataList2_cn: [
        {
          item: '总资产',
          value1: '25,656',
          value2: '23,854',
          value3: '25,474',
          value4: '24,837',
          value5: '23,848',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '流动资产',
          value1: '10,224',
          value2: '10,727',
          value3: '11,487',
          value4: '10,788',
          value5: '11,053'
        },
        {
          item: '非流动资产',
          value1: '15,431',
          value2: '13,127',
          value3: '13,987',
          value4: '14,049',
          value5: '12,795'
        },
        {
          item: '总负债增长',
          value1: '16,496',
          value2: '15,482',
          value3: '16,840',
          value4: '17,038',
          value5: '15,518',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '流动负债',
          value1: '9,745',
          value2: '9,715',
          value3: '12,201',
          value4: '10,606',
          value5: '10,416'
        },
        {
          item: '非流动负债',
          value1: '6,751',
          value2: '5,766',
          value3: '4,639',
          value4: '6,432',
          value5: '5,102',
        },
        {
          item: '非流动负债',
          value1: '9,160',
          value2: '8,372',
          value3: '8,634',
          value4: '7,799',
          value5: '8,330',
          valueFontWeight: '700',
          valueBackground: '#F9F9F9'
        },
        {
          item: '实收资本',
          value1: '500',
          value2: '500',
          value3: '500',
          value4: '500',
          value5: '500'
        },
        {
          item: '资本盈余',
          value1: '6,165',
          value2: '6,165',
          value3: '6,161',
          value4: '6,158',
          value5: '6,158'
        },
        {
          item: '其他综合损益合计',
          value1: '-193',
          value2: '-267',
          value3: '-124',
          value4: '-37',
          value5: '-0'
        },
        {
          item: '留存收益',
          value1: '2,836',
          value2: '2,061',
          value3: '2,180',
          value4: '1,259',
          value5: '1,754'
        },
        {
          item: '其他资本项目',
          value1: '-165',
          value2: '-82',
          value3: '-82',
          value4: '-82',
          value5: '-82'
        }
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}

</script>

<style scoped>
@media (max-width: 768px) {
  .scroll-box {
    width: 90%;
    height: 100%;
    overflow: scroll;
  }
}
</style>
