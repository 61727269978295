var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overview-info-txt" }, [
    _c(
      "span",
      { class: _vm.spanClass, style: `text-align: ${_vm.textAlign};` },
      [_vm._v(_vm._s(_vm.text))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }