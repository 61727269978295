<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <div class="content au-view glSite">
    Xi'an
  </div>
  </div>
</template>

<script>

import HeaderComponent from '@/components/Layout/Header/index.vue'
import GnbComponent from '@/components/Layout/GNB/index.vue'

export default {
  name: 'FactoryXian',
  components: { GnbComponent, HeaderComponent },
  data () {
    return {
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  methods: {
    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    }
  }
}
</script>
