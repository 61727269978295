<template>
  <div class="eco-friendly-certification-card">
    <pre class="card-title">{{ title }}</pre>
    <img class="card-image" :src="image">
    <div class="card-text-area">
      <pre class="card-text">{{ text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    image: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style scoped>

.eco-friendly-certification-card .card-title {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}

@media (max-width: 768px) {
  .eco-friendly-certification-card .card-title {
    font-size: 1rem;
    line-height: 1rem;
    white-space: pre-wrap;
  }
}

.eco-friendly-certification-card .card-image {
  width: 704px;
  height: 358px;
  margin-top: 54px;
}

@media (max-width: 768px) {
  .eco-friendly-certification-card .card-image {
    width: 100%;
    height: 100%;
  }
}

.eco-friendly-certification-card .card-text-area {
  padding: 20px 40px;
  background: #F9F9F9;
  height: 280px;
  margin-top: 54px;
}

@media (max-width: 768px) {
  .eco-friendly-certification-card .card-text-area {
    height: 100%;
  }
}

.eco-friendly-certification-card .card-text-area .card-text {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 200% */
}

@media (max-width: 768px) {
  .eco-friendly-certification-card .card-text-area .card-text {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
    white-space: pre-wrap;
  }
}
</style>
