<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>VIZUON</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
        <ul class="checkbox_more no_line">
          <li>
            <input type="radio" id="rdo00" name="rdo00" :checked="checked('VIZUON_VT')">
            <label class="inp-rdo" for="rdo00" @click="clickCate('VIZUON_VT');" >
              <span>Vehicles and Transportation</span>
            </label>
          </li>
          <li>
            <input type="radio" id="rdo01" name="rdo01" :checked="checked('VIZUON_SD')">
            <label class="inp-rdo" for="rdo01" @click="clickCate('VIZUON_SD');" >
              <span>Signs and Displays</span>
            </label>
          </li>
          <li>
            <input type="radio" id="rdo02" name="rdo02" :checked="checked('VIZUON_IS')">
            <label class="inp-rdo" for="rdo02" @click="clickCate('VIZUON_IS');" >
              <span>Illuminated Sign</span>
            </label>
          </li>
          <li>
            <input type="radio" id="rdo03" name="rdo03" :checked="checked('VIZUON_AR')">
            <label class="inp-rdo" for="rdo03" @click="clickCate('VIZUON_AR');" >
              <span>Architecture</span>
            </label>
          </li>
          <li>
            <input type="radio" id="rdo04" name="rdo04" :checked="checked('VIZUON_ES')">
            <label class="inp-rdo" for="rdo04" @click="clickCate('VIZUON_ES');" >
              <span>Eco Solution</span>
            </label>
          </li>
          <li>
            <input type="radio" id="rdo04" name="rdo05" :checked="checked('VIZUON_CB')">
            <label class="inp-rdo" for="rdo04" @click="clickCate('VIZUON_CB');" >
              <span>Corporate Branding</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SearchFilter',
  props: [
    'category'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
    }
  },
  watch: {
  },
  async created () {

  },
  async mounted () {
  },
  methods: {
    checked (cateCode) {
      return this.category.code && this.category.code.includes(cateCode)
    },
    clickCate (cateCode) {
      if (!this.checked(cateCode)) {
        this.$emit('category-changed-vizuon', cateCode)
      }
    }
  }
}
</script>
