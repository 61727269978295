<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content order-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Header</li>
          <li>Order Sample</li>
          <li>Info</li>
        </ul>
      </div>
      <!-- //location -->

      <div class="container order-inp-box order-sample-info" v-if="$countryCode === '_US'">
        <div class="w-con-02 inner">
          <h1 class="tt-2">Your Information</h1>
          <p class="ctt ctt-basic">Where is this order being shipped to?</p>
          <p class="noti ta-r">This is a required field</p>
          <div class="col-02 order-tbl order-tbl-big-box">
            <div class="tbl-01 order-tbl01">
              <div class="tbl-input">
                <table>
                  <caption>order form</caption>
                  <colgroup>
                    <col style="width:200px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody id="itemList">
                  <tr>
                    <th scope="row"><label class="must" for="inp01">First Name</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp01" placeholder="Input First Name" v-model="memberInfo.firstNm" @blur="validFunction('firstNm')"/>
                      <p class="noti tar" v-if="validation.firstNm.showMsg">{{validation.firstNm.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label class="must" for="inp02">Last Name</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp02" placeholder="Input Last Name" v-model="memberInfo.lastNm" @blur="validFunction('lastNm')"/>
                      <p class="noti tar" v-if="validation.lastNm.showMsg">{{validation.lastNm.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label class="must" for="inp03">Street Address1</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp03" placeholder="Input Street Address1" v-model="memberInfo.addr1" @blur="validFunction('addr1')"/>
                      <p class="noti tar" v-if="validation.addr1.showMsg">{{validation.addr1.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label for="inp04">Street Address2</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp04" placeholder="Input Street Address2" v-model="memberInfo.addr2"/>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label class="must">Country</label></th>
                    <td class="cell-noti">
                      <div id="countryBox" class="sel-box type02">
                        <button id="btnCountry" type="button" class="btn-sel">
                          <span :class="$_.isEmpty(memberInfo.country) ? 'placeholder' : ''">{{ selectedCountry }}</span>
                        </button>
                        <div id="countryList" class="sel-list">
                          <ul class="box">
                            <li v-for="country in form.countries" :key="country.countryCd">
                              <button type="button" @click="selectCountry(country)">
                                {{country.countryNm}}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p class="noti tar" v-if="validation.country.showMsg">{{validation.country.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label class="must">State/Province</label></th>
                    <td class="cell-noti">
                      <div id="stateBox" class="sel-box type02">
                        <button id="btnState" type="button" class="btn-sel" >
                          <span :class="$_.isEmpty(memberInfo.stateCd) ? 'placeholder' : ''">{{ selectedState }}</span>
                        </button>
                        <div id="stateList" class="sel-list">
                          <ul class="box">
                            <li v-for="state in form.states" :key="state.id.stateCd">
                              <button type="button"  @click="selectState(state)">
                                {{ state.stateNm }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p class="noti tar" v-if="validation.stateCd.showMsg">{{validation.stateCd.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label for="inp05" class="must">City</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp05" placeholder="Input City" v-model="memberInfo.city" @blur="validFunction('city')"/>
                      <p class="noti tar" v-if="validation.city.showMsg">{{validation.city.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label for="inp06" class="must">ZIP/Postal Code</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp06" placeholder="Input Zip Code" v-model="memberInfo.zipCd" @blur="validFunction('zipCd')"/>
                      <p class="noti tar" v-if="validation.zipCd.showMsg">{{validation.zipCd.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label for="inp07" class="must">Email Address</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp07" placeholder="Input Email" v-model="memberInfo.email" @blur="validFunction('email')"/>
                      <p class="noti tar" v-if="validation.email.showMsg">{{validation.email.msg}}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><label for="inp08" class="must">Phone</label></th>
                    <td class="cell-noti">
                      <input type="text" class="inp-text" id="inp08" placeholder="Input Phone" v-model="memberInfo.phone" @blur="validFunction('phone')"/>
                      <p class="noti tar" v-if="validation.phone.showMsg">{{validation.phone.msg}}</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tbl-01 order-tbl02">
              <div class="tbl-input">
                <table>
                  <caption>Order Form (Product)</caption>
                  <colgroup>
                    <col style="width:200px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                  <tr v-for="(item, index) in orderList" :key="item.cartId">
                    <td colspan="2" class="prod-box">
                      <div class="col-02">
                        <div class="img-box">
                          <img :src="getImage(item)" alt="image" style="width: 210px">
                        </div>
                        <div class="txt-box">
                          <ul>
                            <li class="txt-01">{{item.content.attribute.code}}</li>
                            <li class="txt-02">{{item.content.content.title}}</li>
                            <li class="txt"><span>Option</span>{{item.sampleInfo.size}}</li>
                            <li class="txt"><span>Qty</span>{{item.qty}}</li>
                            <li class="txt"><span>Each</span>${{item.sampleInfo.price}}</li>
                          </ul>
                          <p class="txt-price">$ {{calcOrder(item.sampleInfo.price, item.qty)}}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="tbl-input type02">
                <table>
                  <caption>order form (Product Grand Total)</caption>
                  <colgroup>
                    <col style="width:230px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th scope="row">Subtotal</th>
                    <td>$ {{ calcSubTotal()}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Shipping & Handling</th>
                    <td>FedEx Ground</td>
                  </tr>
                  <tr>
                    <th scope="row">Grand Total</th>
                    <td>$ {{ calcSubTotal()}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- E : col-02 -->

          <div class="more-box ta-c mb-20">
            <button type="button" class="btn" @click="checkout()"><span>Checkout</span></button>
          </div>
          <p class="t-type-03 tac">
            Refunds will not be provided for any sample purchase.
          </p>

        </div>
      </div>
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import sampleOrderService from '@/server/sampleOrder.service'
import usService from '@/server/us.service'
import _ from '@/common/helper/lodash.js'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'OrderInfo',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    ...mapState({
      orderList: state => state.order.orderList,
      orderMemberInfo: state => state.order.memberInfo
    })
  },
  data () {
    return {
      form: {
        countries: [],
        states: []
      },
      statesData: {
        US: [],
        CA: [],
        99: [],
        org: [{ id: { stateCd: '' }, stateNm: 'Select State/Province' }]
      },
      selectedCountry: 'Select Country',
      selectedCountryCd: '',
      selectedState: 'Select State/Province',
      selectedStateCd: '',
      memberInfo: { country: '' },
      validation: {
        firstNm: {
          value: false,
          showMsg: false,
          msg: 'Enter First Name'
        },
        lastNm: {
          value: false,
          showMsg: false,
          msg: 'Enter Last Name'
        },
        addr1: {
          value: false,
          showMsg: false,
          msg: 'Enter Street Address'
        },
        country: {
          value: false,
          showMsg: false,
          msg: 'Select Country'
        },
        stateCd: {
          value: false,
          showMsg: false,
          msg: 'Select State'
        },
        city: {
          value: false,
          showMsg: false,
          msg: 'Enter City'
        },
        zipCd: {
          value: false,
          showMsg: false,
          msg: 'Enter ZIP/Postal Code'
        },
        email: {
          value: false,
          showMsg: false,
          msg: 'Enter Email Address'
        },
        phone: {
          value: false,
          showMsg: false,
          msg: 'Phone number required for shipping purposes'
        }
      }
    }
  },
  async mounted () {
    this.init()
  },
  methods: {
    getImage (item) {
      let image = null
      if (!_.isEmpty(item.content.attribute.pf_thumb)) {
        image = item.content.attribute.pf_thumb
      } else if (!_.isEmpty(item.content.cover)) {
        image = item.content.cover
      } else if (item.content.orgContent && !_.isEmpty(item.content.orgContent.cover)) {
        image = item.content.orgContent.cover
      }

      return image == null ? null : this.$imagePath + image
    },
    async sendGtm () {
      const that = this
      // GTM checkout init
      digitalData.checkout = {
        ecom_currency_code: 'USD',
        actionField: {
          ecom_checkout_step: '1',
          ecom_checkout_option: 'paypal',
          ecom_order_country: this.getCountryNm(this.memberInfo.country),
          ecom_order_state: this.getStateNm(this.memberInfo.stateCd, this.memberInfo.country),
          ecom_order_city: this.memberInfo.city,
          ecom_order_zipcode: this.memberInfo.zipCd
        },
        products: []
      }
      _.forEach(this.orderList, function (v) {
        // GTM checkout push
        digitalData.checkout.products.push(that.makeDataLayerForm(v))
      })

      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({ event: 'ecCheckOut' })
      }
    },
    async init () {
      const promiseArr = []
      const that = this

      this.memberInfo = _.cloneDeep(this.orderMemberInfo)

      // page content 정보
      promiseArr.push(sampleOrderService.getCountryList())
      promiseArr.push(usService.getStateList('US'))
      promiseArr.push(usService.getStateList('CA'))
      promiseArr.push(usService.getStateList('99'))
      const res = await Promise.all(promiseArr)
      // country, state
      this.form.countries = res[0].data.countries
      _.remove(this.form.countries, function (v) {
        return v.countryCd === '99'
      })
      this.statesData.US = res[1].data
      this.statesData.CA = res[2].data
      this.statesData['99'] = res[3].data

      // selected country
      const country = _.find(this.form.countries, (v) => {
        return v.countryCd === that.memberInfo.country
      })
      if (!_.isUndefined(country)) {
        this.selectedCountry = country.countryNm
        this.selectedCountryCd = country.countryCd
      }
      // selected state
      const stateUS = _.find(this.statesData.US, (v) => {
        return v.id.stateCd === that.memberInfo.stateCd
      })
      const stateCA = _.find(this.statesData.CA, (v) => {
        return v.id.stateCd === that.memberInfo.stateCd
      })
      const stateOT = _.find(this.statesData['99'], (v) => {
        return v.id.stateCd === that.memberInfo.stateCd
      })
      if (!_.isUndefined(stateUS)) {
        this.selectedStateCd = stateUS.id.stateCd
        this.selectedState = stateUS.stateNm
        this.form.states = this.statesData.US
      } else if (!_.isUndefined(stateCA)) {
        this.selectedStateCd = stateCA.id.stateCd
        this.selectedState = stateCA.stateNm
        this.form.states = this.statesData.CA
      } else if (!_.isUndefined(stateOT)) {
        this.selectedStateCd = stateOT.id.stateCd
        this.selectedState = stateOT.stateNm
        this.form.states = this.statesData['99']
      } else {
        this.form.states = this.statesData.org
      }

      this.sendGtm()
    },
    selectCountry (country) {
      this.selectedCountry = country.countryNm
      this.memberInfo.country = country.countryCd
      this.selectedCountryCd = country.countryCd
      this.validFunction('country')
      this.countryChanged()
    },
    selectState (state) {
      this.selectedState = state.stateNm
      this.memberInfo.stateCd = state.id.stateCd
      this.validFunction('stateCd')
    },
    getStateNm (stateCd, countryCd) {
      const found = _.find(this.form.states[countryCd], (v) => {
        return v.id.countryCd === countryCd && v.id.stateCd === stateCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.stateNm
    },
    getCountryNm (countryCd) {
      const found = _.find(this.form.countries, (v) => {
        return v.countryCd === countryCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.countryNm
    },
    async countryChanged () {
      const that = this
      if (this.memberInfo.country && this.memberInfo.country.length > 0) {
        this.form.states = this.statesData[this.memberInfo.country]
        const found = _.find(this.form.states, function (v) {
          return v.id.stateCd === that.memberInfo.stateCd
        })
        if (!_.isUndefined(found)) {
          this.selectedState = found.stateNm
        } else {
          this.selectedState = 'Select State/Province'// 셀렉트 박스 표시 초기화
        }
      } else {
        this.form.states = this.statesData.org
        this.selectedState = 'Select State/Province'// 셀렉트 박스 표시 초기화
      }
    },
    calcOrder (price, qty) {
      // sample 당 가격산출
      return Number(price) * Number(qty)
    },
    calcSubTotal () {
      // 총 가격 산출
      let tot = 0
      _.forEach(this.orderList, (v) => {
        const qty = v.qty
        const price = v.sampleInfo.price
        tot += qty * price
      })
      return tot.toFixed(2)
    },
    validFunction (name) {
      const that = this
      const validRule = {
        country: function () {
          if (!_.isEmpty(that.memberInfo.country)) {
            that.validation.country.value = true
            that.validation.country.showMsg = false
          } else {
            that.validation.country.showMsg = true
            that.validation.country.value = false
          }
        },
        stateCd: function () {
          if (!_.isEmpty(that.memberInfo.stateCd)) {
            that.validation.stateCd.value = true
            that.validation.stateCd.showMsg = false
          } else {
            that.validation.stateCd.showMsg = true
            that.validation.stateCd.value = false
          }
        },
        firstNm: function () {
          if (!_.isEmpty(that.memberInfo.firstNm)) {
            that.validation.firstNm.value = true
            that.validation.firstNm.showMsg = false
          } else {
            that.validation.firstNm.value = false
            that.validation.firstNm.showMsg = true
          }
        },
        lastNm: function () {
          if (!_.isEmpty(that.memberInfo.lastNm)) {
            that.validation.lastNm.value = true
            that.validation.lastNm.showMsg = false
          } else {
            that.validation.lastNm.value = false
            that.validation.lastNm.showMsg = true
          }
        },
        addr1: function () {
          if (!_.isEmpty(that.memberInfo.addr1)) {
            that.validation.addr1.value = true
            that.validation.addr1.showMsg = false
          } else {
            that.validation.addr1.value = false
            that.validation.addr1.showMsg = true
          }
        },
        city: function () {
          if (!_.isEmpty(that.memberInfo.city)) {
            that.validation.city.value = true
            that.validation.city.showMsg = false
          } else {
            that.validation.city.value = false
            that.validation.city.showMsg = true
          }
        },
        zipCd: function () {
          if (!_.isEmpty(that.memberInfo.zipCd)) {
            that.validation.zipCd.value = true
            that.validation.zipCd.showMsg = false
          } else {
            that.validation.zipCd.value = false
            that.validation.zipCd.showMsg = true
          }
        },
        email: function () {
          const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식
          that.validation.email.value = regex.test(that.memberInfo.email)
          that.validation.email.showMsg = (that.validation.email.value == false)
        },
        phone: function () {
          if (!_.isEmpty(that.memberInfo.phone)) {
            that.validation.phone.value = true
            that.validation.phone.showMsg = false
          } else {
            that.validation.phone.value = false
            that.validation.phone.showMsg = true
          }
        }
      }

      validRule[name]()
    },
    checkout () {
      if (this.validateForm()) {
        this.$store.dispatch('order/setOrderItem', this.orderList)
        this.$store.dispatch('order/setMemberInfo', this.memberInfo)
        this.$router.push({ name: 'checkout' })
      }
    },
    validateForm () {
      const that = this
      let hasError = false
      _.forIn(this.validation, function (v, k) {
        that.validFunction(k)
        if (!hasError && !v.value) {
          alert(v.msg)
          hasError = true
        }
      })
      return !hasError
    },
    makeDataLayerForm (v) {
      let brand = ''
      let category = ''
      let size = ''
      let type = ''
      let color = ''
      let collection = ''
      let cateCode = ''

      if (_.includes(v.content.content.modelId, 'HFLOR')) {
        // HFLOR
        brand = v.content.parents[2].name
        category = v.content.parents[1].name
        collection = v.content.parents[0].name
        cateCode = v.content.parents[1].code
        if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
          const prdType = v.content.attribute.prdTypeText
          type = _.isArray(prdType) ? prdType.join('|') : prdType
        }
      } else {
        // Viatera, Himacs
        brand = v.content.parents[1].name
        category = ''
        collection = v.content.parents[0].name
        color = v.content.attribute.hueText // himacs, viatera only
      }
      size = v.sampleInfo.size
      const info = {
        ecom_prd_id: v.content.attribute.code == '' ? v.content.content.id : v.content.attribute.code,
        ecom_prd_name: v.content.content.title,
        ecom_prd_brand: brand,
        ecom_prd_category: category,
        ecom_prd_variant: size,
        ecom_prd_type: type,
        ecom_prd_collection: collection,
        ecom_prd_color: color,
        ecom_prd_price: v.sampleInfo.price,
        ecom_prd_qty: v.qty
      }
      return info
    }
  }

}
</script>
