import request from './request'
import Promise from "lodash/_Promise";

const urlPrefix = ''
const SupportService = {
  getContent(id) {
    return request({
      url: `${urlPrefix}/product/detail?id=${id}`,
      method: "get",
    });
  },
  getContentList(categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: "post",
      data: {
        categoryIds: [categoryId],
      },
    });
  },
  getContentListSort(categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: "post",
      data: {
        categoryIds: [categoryId],
        orderField: ["sort", "createdAt"],
        orderType: ["asc", "desc"],
      },
    });
  },
  getCheckZip(zipCode) {
    return request({
      url: `${urlPrefix}/contactus/checkZip?zipCode=${zipCode}`,
      method: "post",
    });
  },
  getTerritory(stateCd) {
    return request({
      url: `${urlPrefix}/contactus/territory?stateCd=${stateCd}`,
      method: "post",
    });
  },
  getSearch(categoryId, text, dictionaryValues) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: "post",
      data: {
        categoryIds: [categoryId],
        dictionaryValues: dictionaryValues,
        text: text,
      },
    });
  },
  getContentListChild(categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: "post",
      data: {
        categoryIds: [categoryId],
        containChildCategory: true,
      },
    });
  },
  getContentListTap(categoryId, dictionaryValues, text, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: "post",
      data: {
        categoryIds: categoryId,
        dictionaryValues: dictionaryValues,
        text: text,
        containChildCategory: true,
      },
    });
  },

  getCollection(code) {
    return request({
      url: `${urlPrefix}/product/collection?code=${code}`,
      method: "get",
    });
  },
  getContentFileList(contentId) {
    return request({
      url: `${urlPrefix}/directive/contentFileList?contentId=${contentId}`,
      method: "get",
    });
  },
  getCategoryCode(code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: "get",
    });
  },
  getCategoryListAttr(id) {
    return request({
      url: `${urlPrefix}/category/id/${id}?containsAttribute=true`,
      method: "get",
    });
  },
  getCategoryId(id) {
    return request({
      url: `${urlPrefix}/category/id/${id}`,
      method: "get",
    });
  },
  getDictionary(code) {
    return request({
      url: `${urlPrefix}/product/dict?code=${code}`,
      method: "get",
    });
  },
  getDictionaryList(code) {
    return request({
      url: `${urlPrefix}/product/dictList?code=${code}`,
      method: "get",
    });
  },

  getCateChildren(code) {
    return request({
      url: `${urlPrefix}/product/cateChildren?code=${code}`,
      method: "get",
    });
  },

  getCateChildrenAttr(id) {
    return request({
      url: `${urlPrefix}/product/cateChildrenAttr?id=${id}`,
      method: "get",
    });
  },
  getContentTap(categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/product/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: "post",
      data: {
        categoryIds: categoryId,
        containChildCategory: true,
      },
    });
  },

  getCategoryAttributeList(id) {
    return request({
      url: `${urlPrefix}/category/list`,
      method: "post",
      data: {
        parentId: id,
      },
    });
  },
  saveWarranty(data, Materials) {
    return request({
      url: `${urlPrefix}/warranty/save?Material=${Materials}`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getPageContentWtb(data) {
    return request({
      url: `${urlPrefix}/store/pageCont`,
      method: "post",
      data: data,
    });
  },
  getStoreList(data) {
    return request({
      url: `${urlPrefix}/store/list`,
      method: "post",
      data: data,
      timeout: 100000,
    });
  },
  getSendEmail(memId, memEmail) {
    return request({
      url: `${urlPrefix}/user/sendEmail?memId=${memId}&memEmail=${memEmail}`,
      method: "post",
    });
  },
  getSendEmailEU(memId) {
    return request({
      url: `${urlPrefix}/user_eu/sendEmail?memId=${memId}`,
      method: "post",
    });
  },
  getContactUsSave(data) {
    return request({
      url: `${urlPrefix}/contactus/saveUs`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getEUContactusSave(data) {
    return request({
      url: `${urlPrefix}/contactusEU/saveUs`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getMailCheck(data) {
    return request({
      url: `${urlPrefix}/contactusEU/mailCheck`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getDictionaryValueList(code) {
    return request({
      url: `${urlPrefix}/product/dictionaryList?code=${code}`,
      method: "post",
    });
  },
  getPageContFindRep(data) {
    return request({
      url: `${urlPrefix}/saleRep/pageCont`,
      method: "post",
      data: data,
    });
  },
  getPageContFindRepEu(data) {
    return request({
      url: `${urlPrefix}/saleRep/pageContEu`,
      method: "post",
      data: data,
    });
  },
  getListFindRep(data) {
    return request({
      url: `${urlPrefix}/saleRep/list`,
      method: "post",
      data: data,
    });
  },
  getRepsList(selectedPrd, areaCd, countryCd, siteType, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/saleRep/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: "post",
      data: {
        categoryCode: selectedPrd,
        areaCd: areaCd,
        countryCd: countryCd,
        siteType:siteType
      }
    })
  },
  getListFindRepUs(selectedPrd, text, div,siteType, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/usSalesRep/search`,
      method: "post",
      data: {
        selectedPrd: selectedPrd,
        searchWord: text,
        repsDiv: div,
        siteType: siteType,
        pageIndex: pageIndex,
        pageSize: pageSize
      }
    });
  },
  getListFindRepEu(data) {
    return request({
      url: `${urlPrefix}/saleRep/list/eu`,
      method: "post",
      data: data,
    });
  },
  getListSalesRepByZip(data) {
    return request({
      url: `${urlPrefix}/saleRep/list/zip`,
      method: "post",
      data: data,
    });
  }
};

export default SupportService
