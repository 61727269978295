<template>
  <ul>
    <COMPARE></COMPARE>
    <COLOR :colorList="dictMap['hue']" v-model="hue"></COLOR>
    <COLLECTION :collectionList="collectionList" v-model="collection" :category="category"></COLLECTION>
    <PRD_PATTERN :patternList="dictMap['pattern']" v-model="pattern"></PRD_PATTERN>
<!--    <COUNTRY :countryList="dictMap['country']" v-model="country"></COUNTRY>-->
    <IS_RECYCLED v-model="isRecycled"></IS_RECYCLED>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import COMPARE from './component/COMPARE.vue'
import COLOR from './component/COLOR.vue'
import PRD_PATTERN from './component/PATTERN.vue'
import COLLECTION from './component/COLLECTION.vue'
import IS_RECYCLED from './component/IS_RECYCLED.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    COMPARE,
    PRD_PATTERN,
    COLOR,
    COLLECTION,
    IS_RECYCLED
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      collection: [],
      hue: [],
      pattern: [],
      finish: [],
      country: [],
      isRecycled: []
    }
  },
  watch: {
  },
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.collection, vm.pattern, vm.country, vm.isRecycled], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {
  }
}
</script>
