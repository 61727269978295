import dayjs from 'dayjs'
import moment from 'vue-moment'
import Vue from 'vue'
import vueDebounce from 'vue-debounce'
Vue.use(moment)
Vue.use(vueDebounce)

export default {
  dateTimeConvert (time) {
    return time && dayjs(time).format('YYYY-MM-DD HH:mm:ss')
  },
  dateConvert (time) {
    return time && dayjs(time).format('YYYY-MM-DD')
  },
  createCartId () {
    return Vue.moment().format('YYYYMMDDHHmmssSSSSS')
  }
}
