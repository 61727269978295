<template>
  <OverviewLayout imageSrc="images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg"
    title="DESIGNER COLLABORATIONS" v-if="nationCode == '_US' || nationCode == '_GL'">
    <template #content>
      <!-- text 영역 -->
      <OverviewSection>
        <div>
          <pre class="intro-text">
LX Hausys launched its research center in 1989 as a processing technology research center.
The institute presents differentiated designs to our customers through
collaborations with renowned designers both domestically and internationally.
          </pre>
        </div>
      </OverviewSection>

      <div class="au-view">
        <div class="cwp-box">
          <ul>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Interior film</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                        src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png" alt="Karim Rashid">
                    </div>
                    <strong>Karim Rashid</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png"
                      alt="Interior film"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Z:in Window handle</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                        src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png"
                        alt="Stefano Giovannoni"></div>
                    <strong>Stefano Giovannoni</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png"
                      alt="Z:in Window handle"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Flooring, Car fabric</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                        src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png"
                        alt="Ben Van Berkel"></div>
                    <strong>Ben Van Berkel</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png"
                      alt="Flooring, Car fabric"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Wallpaper, Car fabric pattern, HIMACS</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                        src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png"
                        alt="Marcel Wanders"></div>
                    <strong>Marcel Wanders</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png"
                      alt="Wallpaper, Car fabric pattern, HIMACS"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Flooring, Wallpaper</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                        src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png"
                        alt="Alexandro Mendini"></div>
                    <strong>Alexandro Mendini</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png"
                      alt="Flooring, Wallpaper"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Wallpaper</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                        src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png" alt="Vera Wang">
                    </div>
                    <strong>Vera Wang</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png"
                      alt="Wallpaper">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout imageSrc="images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg"
                  title="デザイナーとのコラボレーション" v-else-if="nationCode == '_JP'">
    <template #content>
      <!-- text 영역 -->
      <OverviewSection>
        <div>
          <pre class="intro-text">
          LX Hausys は、1989 年に加工技術研究センターとして研究所を設立しました。
          この研究所は、国内外の著名なデザイナーとのコラボレーションを通じて、
          差別化されたデザインをお客様に提供しています。
          </pre>
        </div>
      </OverviewSection>

      <div class="au-view">
        <div class="cwp-box">
          <ul>
            <li>
              <div class="w-con">
                <h2 class="tt-2">インテリアフィルム</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png" alt="Karim Rashid">
                    </div>
                    <strong>Karim Rashid</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png"
                                         alt="Interior film"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Z:in ウィンドウ ハンドル</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png"
                      alt="Stefano Giovannoni"></div>
                    <strong>Stefano Giovannoni</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png"
                                         alt="Z:in Window handle"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">フローリング、カーファブリック</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png"
                      alt="Ben Van Berkel"></div>
                    <strong>Ben Van Berkel</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png"
                                         alt="Flooring, Car fabric"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">壁紙、カーファブリックパターン、HIMACS</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png"
                      alt="Marcel Wanders"></div>
                    <strong>Marcel Wanders</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png"
                                         alt="Wallpaper, Car fabric pattern, HIMACS"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">フローリング、壁紙</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png"
                      alt="Alexandro Mendini"></div>
                    <strong>Alexandro Mendini</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png"
                                         alt="Flooring, Wallpaper"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">壁紙</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png" alt="Vera Wang">
                    </div>
                    <strong>Vera Wang</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png"
                                         alt="Wallpaper">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout imageSrc="images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg"
                  title="СОТРУДНИЧЕСТВО С ДИЗАЙНЕРАМИ" v-else-if="nationCode == '_RU'">
    <template #content>
      <!-- text 영역 -->
      <OverviewSection>
        <div>
          <pre class="intro-text">
            LX Hausys открыл свой исследовательский центр в 1989 году как центр изучения технологий обработки.
            Институт представляет нашим клиентам дифференцированный дизайн благодаря
            сотрудничеству с известными дизайнерами как внутри страны, так и на международном уровне.
          </pre>
        </div>
      </OverviewSection>

      <div class="au-view">
        <div class="cwp-box">
          <ul>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Интерьерная пленка</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png" alt="Karim Rashid">
                    </div>
                    <strong>Karim Rashid</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png"
                                         alt="Interior film"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Z:IN Ручка оконная </h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png"
                      alt="Stefano Giovannoni"></div>
                    <strong>Stefano Giovannoni</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png"
                                         alt="Z:in Window handle"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Напольное покрытие, ткань для автомобиля</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png"
                      alt="Ben Van Berkel"></div>
                    <strong>Ben Van Berkel</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png"
                                         alt="Flooring, Car fabric"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Обои, рисунок на ткани для автомобиля, HIMACS</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png"
                      alt="Marcel Wanders"></div>
                    <strong>Marcel Wanders</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png"
                                         alt="Wallpaper, Car fabric pattern, HIMACS"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Напольное покрытие, обои</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png"
                      alt="Alexandro Mendini"></div>
                    <strong>Alexandro Mendini</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png"
                                         alt="Flooring, Wallpaper"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Обои</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png" alt="Vera Wang">
                    </div>
                    <strong>Vera Wang</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png"
                                         alt="Wallpaper">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout imageSrc="images/aboutus/r-and-d-center/designerCollaborations/top_image.jpg"
                  title="合作设计师" v-else-if="nationCode == '_CN'">
    <template #content>
      <!-- text 영역 -->
      <OverviewSection>
        <div>
          <pre class="intro-text">
            LX Hausys于1989年成立了研究中心。
            LX Hausys研究中心通过与国内外知名设计师的合作，向客户展示差异化的设计。
          </pre>
        </div>
      </OverviewSection>

      <div class="au-view">
        <div class="cwp-box">
          <ul>
            <li>
              <div class="w-con">
                <h2 class="tt-2">装饰贴膜</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_01.png" alt="Karim Rashid">
                    </div>
                    <strong>Karim Rashid</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_02.png"
                                         alt="Interior film"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">Z:IN 门把手</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_03.png"
                      alt="Stefano Giovannoni"></div>
                    <strong>Stefano Giovannoni</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_04.png"
                                         alt="Z:in Window handle"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">地板、汽车表皮</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_05.png"
                      alt="Ben Van Berkel"></div>
                    <strong>Ben Van Berkel</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_06.png"
                                         alt="Flooring, Car fabric"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">墙纸、汽车表皮纹路、HIMACS</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_07.png"
                      alt="Marcel Wanders"></div>
                    <strong>Marcel Wanders</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_08.png"
                                         alt="Wallpaper, Car fabric pattern, HIMACS"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">地板、墙纸</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_09.png"
                      alt="Alexandro Mendini"></div>
                    <strong>Alexandro Mendini</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_10.png"
                                         alt="Flooring, Wallpaper"></div>
                </div>
              </div>
            </li>
            <li>
              <div class="w-con">
                <h2 class="tt-2">墙纸</h2>
              </div>
              <div class="img-wrap">
                <div class="w-con col-02">
                  <div class="info">
                    <div class="img-box"><img
                      src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_11.png" alt="Vera Wang">
                    </div>
                    <strong>Vera Wang</strong>
                  </div>
                  <div class="view"><img src="https://img.lxhausys.com/hausys/ALL/About_Us/designCenter/img_cwp_12.png"
                                         alt="Wallpaper">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import OverviewSection from '../../overview/components/OverviewSection.vue'

export default {
  components: {
    OverviewLayout,
    OverviewSection
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.intro-text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 180% */
}

@media (max-width: 768px) {
  .intro-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: pre-wrap;
  }
}
</style>
