var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overview-section", class: "mt" },
    [
      _vm._t("header"),
      _vm.showTitle
        ? [
            _c(
              "div",
              { staticClass: "title", style: { "margin-top": _vm.marginTop } },
              [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                  _c("i", [_vm._v(_vm._s(_vm.italicTitle))]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }