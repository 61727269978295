var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "short-link type_sub" }, [
    _c(
      "a",
      {
        staticClass: "item where",
        attrs: { href: _vm.getWheresHref(_vm.$countryCode) },
      },
      [_vm._m(0)]
    ),
    _c(
      "a",
      { staticClass: "item contact", attrs: { href: "support/contactus" } },
      [_vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "anchor" }, [
      _c("span", { staticClass: "text" }, [_vm._v("where to buy")]),
      _c("span", { staticClass: "icon_arrow" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "anchor" }, [
      _c("span", { staticClass: "text" }, [_vm._v("contact us")]),
      _c("span", { staticClass: "icon_arrow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }