<template>
  <div class="key-project-card-area">
    <div class="image-area">
      <img :src="imageSrc">
    </div>
    <div class="text-area">
      <span>{{ txt }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String
    },
    txt: {
      type: String
    }
  }
}
</script>

<style scoped>
.key-project-card-area {
  width: 423px;
  height: 344px;
  border: 1px solid #CCC;
  display: flex;
  padding: 60px 45px 60px 45px;
  gap: 70px;
  flex-direction: column;
  align-items: center;
}

.image-area {
  max-width: 77px;
  justify-content: center;
  display: flex;
}

.key-project-card-area .text-area {
  color: #222;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
}
:lang(ru) .key-project-card-area .text-area {
  line-height: 1.4; /* 200% */
}

@media (max-width: 768px) {
  .key-project-card-area {
    width: 90%;
    height: 100%;
  }

  .key-project-card-area .text-area pre {
    white-space: pre-wrap;
    font-size: 1rem;
  }
}
</style>
