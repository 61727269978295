var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c("div", { staticClass: "key-performance-table" }, [
        _c(
          "div",
          { staticClass: "ulsan-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header1.title },
            }),
            _vm._l(_vm.body1, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "cheongju-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header2.title },
            }),
            _vm._l(_vm.body2, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
      ])
    : _vm.nationCode == "_JP"
    ? _c("div", { staticClass: "key-performance-table" }, [
        _c(
          "div",
          { staticClass: "ulsan-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header1Jp.title },
            }),
            _vm._l(_vm.body1Jp, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "cheongju-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header2Jp.title },
            }),
            _vm._l(_vm.body2Jp, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
      ])
    : _vm.nationCode == "_RU"
    ? _c("div", { staticClass: "key-performance-table" }, [
        _c(
          "div",
          { staticClass: "ulsan-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header1Ru.title },
            }),
            _vm._l(_vm.body1Ru, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "cheongju-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header2Ru.title },
            }),
            _vm._l(_vm.body2Ru, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
      ])
    : _vm.nationCode == "_CN"
    ? _c("div", { staticClass: "key-performance-table" }, [
        _c(
          "div",
          { staticClass: "ulsan-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header1Cn.title },
            }),
            _vm._l(_vm.body1Cn, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "cheongju-plant" },
          [
            _c("KeyPerformanceTableHeader", {
              attrs: { title: _vm.header2Cn.title },
            }),
            _vm._l(_vm.body2Cn, function (item, idx) {
              return _c("KeyPerformanceTableBody", {
                key: idx,
                attrs: {
                  number: item.number,
                  unit: item.unit,
                  summary: item.summary,
                },
              })
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }