<template>
  <div class="vinyl-coated-material-storng-area">
    <div
      v-for="(it, idx) in strongInfo"
      :key="idx"
      class="item"
      :class="getItemClassByIndex(idx)"
    >
      <ProductStrongPoint
        :iconSrc="it.iconSrc"
        :title="it.title"
        :description="it.description"

        type="vertical"
        titleFontSize="24px"
      />
    </div>
  </div>
</template>

<script>
import ProductStrongPoint from '@/components/product/ProductStrongPoint';

export default {
  components: {
    ProductStrongPoint
  },

  data: () => ({
    strongInfo: [
      {
        iconSrc: 'images/industrialFilm/VinylCoatedMaterial/strong-point-icon-1.png',
        title: 'Customizing Design Solution',
        description: 'Based on Global Trend Research, development capabilities of customizing design solution for local customers has been recognized around the world, particularly in India, America, China, Southeast Asia and Europe.'
      },
      {
        iconSrc: 'images/industrialFilm/VinylCoatedMaterial/strong-point-icon-2.png',
        title: 'Global No.1',
        description: 'LX Hausys’ Home Appliance Surface Film is running for the 1st M/S as a partner for Global major home appliance companies.'
      },
      {
        iconSrc: 'images/industrialFilm/VinylCoatedMaterial/strong-point-icon-3.png',
        title: 'Excellent Quality',
        description: 'LX Hausys’ technology provides Anti Finger prints, Contamination Resistance, Anti Scratch and High Elongation for customers to use home appliances beautifully for a long time'
      }
    ]
  }),

  methods: {
    getItemClassByIndex (idx) {
      return idx === 1 ? 'center' : ''
    }
  }
}
</script>

<style scoped>
.vinyl-coated-material-storng-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 769px) {
  .vinyl-coated-material-storng-area {
    flex-direction: row;
  }
}

.vinyl-coated-material-storng-area .item {
  margin-top: 20px;
}
@media (min-width: 769px) {
  .vinyl-coated-material-storng-area .item {
    margin-top: 140px;
  }
}

.vinyl-coated-material-storng-area .item.center {
  margin-top: 0px;
}
</style>
