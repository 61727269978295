<template>
  <div>
    <!-- <div id="imgViewer"></div> -->

    <div id="imgViewer" class="seadragon_custom">

    </div>

    <div class="tit">
      <p>
        For illustration purposes only
        <!-- {{ $t('PD.text.image_viewer')}} -->
        <!-- <strong>{{ imgWidth }} * {{ imgHeight }} {{ $t('PD.text.in')}}</strong> -->
      </p>
    </div>

    <div class="btn-box">
      <!-- <p>
        {{ $t('PD.text.image_viewer')}}
        <strong>{{ imgWidth }} * {{ imgHeight }} {{ $t('PD.text.in')}}</strong>
      </p> -->

      <div class="btns">
        <div class="toolbarItem btn-out" id="btn-out">{{ $t('PD.text.zoom_out')}}</div>
        <div class="toolbarItem btn-in" id="btn-in">{{ $t('PD.text.zoom_in')}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SeaDragon',
  props: ['imagePath'],
  data () {
    return {
      imgWidth: 0 /* 이미지 가로 */,
      imgHeight: 0 /* 이미지 세로 */
    }
  },
  async mounted () {
    const _this = this

    window.addEventListener('resize', this.handleResize)

    /* Product detail enlarge */
    const viewer = OpenSeadragon({
      id: 'imgViewer',
      prefixUrl: 'images/products/images/',
      tileSources: {
        type: 'image',
        url: _this.imagePath
      },
      showNavigator: true,
      navigatorAutoFade: false,
      fullScreen: true,
      defaultZoomLevel: 1,
      minZoomLevel: 1,
      maxZoomLevel: 10,
      visibilityRatio: 1.0,
      constrainDuringPan: true,
      homeFillsViewer: true,
      navigatorPosition: 'ABSOLUTE',
      // navigatorTop: 'calc(100% - 324px)',
      // navigatorLeft: 'calc(100% - 440px)', // center align
      // navigatorWidth: '360px',
      navigatorHeight: '180px',
      zoomInButton: 'btn-in',
      zoomOutButton: 'btn-out',
      current: true,
      setPreload: true
    })

    viewer.addHandler('canvas-drag', (event) => {
      event.preventDefaultAction = true
    })

    viewer.addHandler('open', (event) => {
      this.imgHeight = viewer.world.getItemAt(0).source.dimensions.y
      this.imgWidth = viewer.world.getItemAt(0).source.dimensions.x

      _this.resizeGuide()
    })
  },
  methods: {
    handleResize () {
      this.resizeGuide()
    },
    resizeGuide () {
      const nav = $('.OpenSeadragon-container')
      nav.css({
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        left: '0px',
        top: '0px',
        'text-align': 'left'
      })
    }
  },
  beforeDestroy () {
    // console.log("beforeDestroy...");
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
