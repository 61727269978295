var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { staticClass: "mt-64", attrs: { title: "GHG Emission Reduction" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("div", { staticClass: "graph-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "border-box-div" },
            _vm._l(_vm.borderBoxDatas, function (data, index) {
              return _c("GhgEmissionReductionBorderBox", {
                key: index,
                attrs: { title: data.title, content: data.content },
              })
            }),
            1
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "Key Performance 2022" } },
            [_c("KeyPerformanceTable")],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { staticClass: "mt-64", attrs: { title: "温室効果ガス排出削減" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("div", { staticClass: "graph-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "border-box-div" },
            _vm._l(_vm.borderBoxDatasJp, function (data, index) {
              return _c("GhgEmissionReductionBorderBox", {
                key: index,
                attrs: { title: data.title, content: data.content },
              })
            }),
            1
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "2022年の主な業績" } },
            [_c("KeyPerformanceTable")],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          staticClass: "mt-64",
          attrs: { title: "Сокращение выбросов парниковых газов" },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("div", { staticClass: "graph-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "border-box-div" },
            _vm._l(_vm.borderBoxDatasRu, function (data, index) {
              return _c("GhgEmissionReductionBorderBox", {
                key: index,
                attrs: { title: data.title, content: data.content },
              })
            }),
            1
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "Ключевые показатели 2022 года" } },
            [_c("KeyPerformanceTable")],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { staticClass: "mt-64", attrs: { title: "温室气体减排" } },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: true } }, [
            _c("div", { staticClass: "graph-area" }, [
              _c("img", {
                attrs: {
                  src: "images/aboutus/sustainability/sustainability_management/ghg-emission-reduction-graph.png",
                  alt: "img",
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "border-box-div" },
            _vm._l(_vm.borderBoxDatasCn, function (data, index) {
              return _c("GhgEmissionReductionBorderBox", {
                key: index,
                attrs: { title: data.title, content: data.content },
              })
            }),
            1
          ),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "2022年关键业绩" } },
            [_c("KeyPerformanceTable")],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }