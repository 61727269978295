<template>
  <OverviewSection
  title="Core Values"
  color="black"
  v-if="nationCode == '_US' || nationCode == '_GL'">
    <OverviewTopImage
        v-if="!isMobile()"
        imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
        description="LX Hausys shares the four core values of “sensitivity to customers”,
“teamwork based on individual strength”, “world-class expertise”,and “challenge
based on creativity and autonomy” with our stakeholders
in order to fulfill its management philosophy and vision."
        imageWidth='1440px'
        imageHeight='360px'
    />

    <OverviewTopImage
        v-if="isMobile()"
        imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
        imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys shares the four core values of “sensitivity to customers”,
“teamwork based on individual strength”, “world-class expertise”,and “challenge
based on creativity and autonomy” with our stakeholders
in order to fulfill its management philosophy and vision.</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <CoreValuesCard
          v-for="(it, idx) in cardData"
          :key="idx"
          :title="it.title"
          :titleDesc="it.titleDesc"
          :desc="it.desc"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  <!-- JP -->
  <OverviewSection
    title="主な価値"
    color="black"
    v-else-if="nationCode == '_JP'">
    <OverviewTopImage
      v-if="!isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
      description="LXハウシスは、経営理念とビジョンを実現するために、「顧客への敏感さ」
「個の力に基づくチームワーク」「世界トップクラスの専門性」「創造性と自律性に基づく挑戦」
という4つのコアバリューをステークホルダーと共有しています。"
      imageWidth='1440px'
      imageHeight='360px'
    />

    <OverviewTopImage
      v-if="isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
      imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
LXハウシスは、経営理念とビジョンを実現するために、「顧客への敏感さ」「個の力に基づくチームワーク」「世界トップクラスの専門性」「創造性と自律性に基づく挑戦」という4つのコアバリューをステークホルダーと共有しています。</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <CoreValuesCard
          v-for="(it, idx) in cardData_jp"
          :key="idx"
          :title="it.title"
          :titleDesc="it.titleDesc"
          :desc="it.desc"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  <!-- RU -->
  <OverviewSection
    title="Главные ценности"
    color="black"
    v-else-if="nationCode == '_RU'">
    <OverviewTopImage
      v-if="!isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
      description="LX Hausys разделяет четыре основные ценности: «чуткое отношение к клиентам»,
«командная работа, основанная на индивидуальной силе», «экспертиза мирового уровня» и «вызов, 
основанный на творчестве и автономии», с нашими заинтересованными сторонами для того, чтобы 
воплотить в жизнь философию и видение."
      imageWidth='1440px'
      imageHeight='360px'
    />

    <OverviewTopImage
      v-if="isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
      imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys разделяет четыре основные ценности: «чуткое отношение к клиентам», «командная работа, основанная на индивидуальной силе», «экспертиза мирового уровня» и «вызов, основанный на творчестве и автономии», с нашими заинтересованными сторонами для того, чтобы воплотить в жизнь философию и видение.</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <CoreValuesCard
          v-for="(it, idx) in cardData_ru"
          :key="idx"
          :title="it.title"
          :titleDesc="it.titleDesc"
          :desc="it.desc"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
  <!-- CN -->
  <OverviewSection
    title="核心价值"
    color="black"
    v-else-if="nationCode == '_CN'">
    <OverviewTopImage
      v-if="!isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
      description="LX Hausys为了实现自身的经营理念与愿景，始终坚持“与顾客共情”，
“基于个人优势的团队合作”，“世界级的专业性”以及“以创新与自由为基础的挑战精神”等4条核心价值。"
      imageWidth='1440px'
      imageHeight='360px'
    />

    <OverviewTopImage
      v-if="isMobile()"
      imageSrc="images/aboutus/overview/vision_strategies/core-values-back.png"
      imageHeight="revert-layer"
    />

    <OverviewSectionItem v-if="isMobile()">
      <pre class="ctt ctt-t2" style="text-align: center; font-weight: 400;">
LX Hausys为了实现自身的经营理念与愿景，始终坚持“与顾客共情”，“基于个人优势的团队合作”，“世界级的专业性”以及“以创新与自由为基础的挑战精神”等4条核心价值。</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="card-area">
        <CoreValuesCard
          v-for="(it, idx) in cardData_cn"
          :key="idx"
          :title="it.title"
          :titleDesc="it.titleDesc"
          :desc="it.desc"
          :iconSrc="it.iconSrc"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../components/OverviewSection.vue'
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import OverviewTopImage from '../../components/OverviewTopImage.vue'
import CoreValuesCard from '../components/CoreValuesCard.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    OverviewTopImage,
    CoreValuesCard
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardData: [
        {
          title: 'Sensitivity',
          titleDesc: 'to the voice of customers',
          desc: [
            'Understanding customer needs and market changes',
            'Realization of potential customer needs / wants'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-sensitivity.png'
        },
        {
          title: 'Teamwork',
          titleDesc: 'based on individual strengths',
          desc: [
            'Self-confidence / Positive Thinking / Individuality',
            'Creation of a pleasant work culture for young employees',
            'Integration of their diverse individual strengths'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-teamwork.png'
        },
        {
          title: 'Expertise',
          titleDesc: 'beyond world class standards',
          desc: [
            'Customer acknowledgement as the world’s best',
            'Competition with the world’s best companies'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-expertise.png'
        },
        {
          title: 'Challenge',
          titleDesc: 'based on creativity and autonomy',
          desc: [
            'Environment that fosters creativity based on expert knowledge',
            'Establishing an environment where employees can take responsibilities for their actions and manage themselves to grow via personal development'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-challenge.png'
        }
      ],
      cardData_jp: [
        {
          title: '顧客の声に敏感',
          titleDesc: '',
          desc: [
            '顧客ニーズと市場の変化の理解',
            '潜在的な顧客ニーズ/要望の実現'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-sensitivity.png'
        },
        {
          title: '個人の強みに基づいたチームワーク',
          titleDesc: '',
          desc: [
            '自信・ポジティブ思考・個性',
            '若手社員が働きやすい職場環境の創出',
            '多様な個人の強みの融合'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-teamwork.png'
        },
        {
          title: '世界水準を超える専門知識',
          titleDesc: '',
          desc: [
            '世界最高峰としてお客様から認められる',
            '世界最高峰の企業との競争'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-expertise.png'
        },
        {
          title: '創造性と自主性に基づく挑戦',
          titleDesc: '',
          desc: [
            '専門知識に基づいた創造性を育む環境',
            '従業員が自らの行動に責任を持ち、自己啓発を通じて成長できる環境の整備'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-challenge.png'
        }
      ],
      cardData_ru: [
        {
          title: 'Чувствительность',
          titleDesc: 'к мнению клиентов',
          desc: [
            'Понимание потребностей клиентов и изменений на рынке',
            'Реализация потенциальных потребностей/желаний клиентов'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-sensitivity.png'
        },
        {
          title: 'Командная работа',
          titleDesc: 'на основе индивидуальных преимуществ',
          desc: [
            'Уверенность в себе / Позитивное мышление / Индивидуальность',
            'Создание приятной культуры труда для молодых сотрудников',
            'Интеграция их разнообразных индивидуальных способностей'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-teamwork.png'
        },
        {
          title: 'Экспертиза',
          titleDesc: 'за пределами стандартов мирового уровня',
          desc: [
            'Признание клиентов как лучших в мире',
            'Конкуренция с лучшими компаниями мира'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-expertise.png'
        },
        {
          title: 'Вызов',
          titleDesc: 'основанный на творчестве и автономности',
          desc: [
            'Среда, способствующая творчеству, основанному на экспертных знаниях',
            'Создание среды, в которой сотрудники могут брать на себя ответственность за свои действия и управлять собой, чтобы расти посредством личного развития'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-challenge.png'
        }
      ],
      cardData_cn: [
        {
          title: '对客户声音的敏感度',
          titleDesc: '',
          desc: [
            '能够感知顾客需求及市场变化',
            '发掘 并实现顾客潜在需求/想顾客所想'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-sensitivity.png'
        },
        {
          title: '优秀人才间的团队协作',
          titleDesc: '',
          desc: [
            '自信/积极的想法/个性',
            '为年轻员工创造愉快的工作文化',
            '发挥个人优势及融会贯通'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-teamwork.png'
        },
        {
          title: '世界级的专业性',
          titleDesc: 'beyond world class standards',
          desc: [
            '顾客认可的世界最高水准',
            '能与世界一流企业竞争'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-expertise.png'
        },
        {
          title: '创意和自律为基础的挑战性',
          titleDesc: 'based on creativity and autonomy',
          desc: [
            '以专业知识为基础 / 能够发挥创意性的环境',
            '积极提升自我 / 能够对所做过的决定负责'
          ],
          iconSrc: 'images/aboutus/overview/vision_strategies/core-values-challenge.png'
        }
      ]
    }
  },
  methods: {
    isMobile () {
      const screenWidth = window.innerWidth
      return screenWidth <= 768
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.card-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 35px 29px;
  flex-wrap: wrap;
  width: 1440px;
}

@media (max-width: 768px) {
  .card-area {
    width: 100%;
  }
  .ctt-t2 {
    white-space: pre-wrap;
  }
}
</style>
