<template>
  <OverviewSection
    title="Eco-Friendly Products"
    size="56px"
    color="#222"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
  <OverviewSectionItem isUnderSection>
    <EcoFriendlyProductsTab
      :tabDatas="tabDatas"
    >
      <template #item="{ picture, icon, text }">
        <EcoFriendlyProductsContent
          :picture="picture"
          :icon="icon"
          :text="text"
        />
      </template>
    </EcoFriendlyProductsTab>
  </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="環境に優しい製品"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSection>
      <EcoFriendlyProductsTab
        :tabDatas="tabDatasJp"
      >
        <template #item="{ picture, icon, text }">
          <EcoFriendlyProductsContent
            :picture="picture"
            :icon="icon"
            :text="text"
          />
        </template>
      </EcoFriendlyProductsTab>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Экологически чистые продукты"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSection>
      <EcoFriendlyProductsTab
        :tabDatas="tabDatasRu"
      >
        <template #item="{ picture, icon, text }">
          <EcoFriendlyProductsContent
            :picture="picture"
            :icon="icon"
            :text="text"
          />
        </template>
      </EcoFriendlyProductsTab>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="环保产品"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSection>
      <EcoFriendlyProductsTab
        :tabDatas="tabDatasCn"
      >
        <template #item="{ picture, icon, text }">
          <EcoFriendlyProductsContent
            :picture="picture"
            :icon="icon"
            :text="text"
          />
        </template>
      </EcoFriendlyProductsTab>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import EcoFriendlyProductsContent from './EcoFriendlyProductsContent.vue'
import EcoFriendlyProductsTab from './EcoFriendlyProductsTab.vue'
export default {
  components: {
    OverviewSection,
    EcoFriendlyProductsTab,
    OverviewSectionItem,
    EcoFriendlyProductsContent
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      tabDatas: [
        {
          title: 'Solid Surface',
          picture: 'images/aboutus/sustainability/sustainable_products/products-solid.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon1.png',
          text: 'We obtained an environmental certification from SCS Global Services, a U.S. international certification entity,\n' +
                'for our solid surface products. These products incorporate over 10% recycled raw materials',
          isActive: false
        },
        {
          title: 'Flooring',
          picture: 'images/aboutus/sustainability/sustainable_products/products-flooring.png',
          text: 'HFLOR acquired the ISCC PLUS eco-friendly material certificate by substituting the petrochemical-based PVC raw materials\n' +
                'with bio-balanced PVC sourced from plant-derived materials.',
          isActive: false
        },
        {
          title: 'Industrial Film',
          picture: 'images/aboutus/sustainability/sustainable_products/products-industrial.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon3.png',
          text: 'PET received the Global Recycled Standard (GRS) Version 4.0. It contains recycled post-consumer polyester,\n' +
                'ranging from 53% to 84%. Remarkably, It is worth reusing 9 PET bottles (500ml) per one kitchen door by rPET. ',
          isActive: false
        },
        {
          title: 'Automotive',
          picture: 'images/aboutus/sustainability/sustainable_products/products-automotive.png',
          text: 'At LX Hausys, we actively employ bio materials such as materials derived f rom sugar c ane to o btain and continually renew Environmental\n' +
                'Claim Validation (ECV) of UL Solutions. Our Bio PU leather (VERNO Bio) is verified to contain 19% of bio materials while our rBio PU Suede\n' +
                '(Calfpelle Bio) contain 10% of bio materials. Going forward, we will diversify the range of sustainable bio materials such as kenaf, eucalyptus,\n' +
                'cactus, and shells (e.g., oyster shells) and direct research activities to maximize their content.',
          isActive: false
        }
      ],
      tabDatasJp: [
        {
          title: '人工大理石',
          picture: 'images/aboutus/sustainability/sustainable_products/products-solid.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon1.png',
          text: '当社の人工大理石製品では、米国の国際認証機関であるSCS Global Servicesから環境認証を取得しました。\n' +
                'これらの製品には、10%を超えるリサイクル原材料が使用されています。',
          isActive: false
        },
        {
          title: '床材',
          picture: 'images/aboutus/sustainability/sustainable_products/products-flooring.png',
          text: 'HFLORは、石油化学ベースのPVC原材料を植物由来の材料から得られるバイオバランスPVCに置き換えることで\n' +
                'ISCC PLUS環境に優しい材料認証を取得しました。',
          isActive: false
        },
        {
          title: '産業用フィルム',
          picture: 'images/aboutus/sustainability/sustainable_products/products-industrial.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon3.png',
          text: 'PET は、Global Recycled Standard (GRS) バージョン 4.0 を取得しています。\n' +
                'リサイクルされた使用済みポリエステルが 53% ～ 84% 含まれています。\n' +
                '驚くべきことに、rPET を使用すると、キッチンドア 1 枚につき 9 本の PET ボトル (500ml) を再利用する価値があります。',
          isActive: false
        },
        {
          title: '自動車用',
          picture: 'images/aboutus/sustainability/sustainable_products/products-automotive.png',
          text: 'LX Hausysでは、サトウキビ由来の素材などのバイオ素材を積極的に採用し、\n' +
                'ULソリューションの環境クレーム検証（ECV）を取得し、継続的に更新しています。\n' +
                '当社のバイオPUレザー（VERNO Bio）には19％のバイオ素材が含まれていることが検証されており、\n' +
                'rBio PUスエード（Calfpelle Bio）には10％のバイオ素材が含まれています。今後は、ケナフ、\n' +
                'ユーカリ、サボテン、貝殻（カキ殻など）などの持続可能なバイオ素材の範囲を多様化し、\n' +
                'その含有量を最大化するための研究活動を推進していきます。',
          isActive: false
        }
      ],
      tabDatasRu: [
        {
          title: 'Акриловый камень',
          picture: 'images/aboutus/sustainability/sustainable_products/products-solid.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon1.png',
          text: 'Мы получили экологический сертификат от SCS Global Services, американской \n' +
                'международной сертификационной организации, на нашу продукцию для твердых \n' + 
                'поверхностей. В состав этих изделий входит более 10%  переработанного сырья.',
          isActive: false
        },
        {
          title: 'Напольные покрытия',
          picture: 'images/aboutus/sustainability/sustainable_products/products-flooring.png',
          text: 'Продукция HFLOR получила сертификат экологически чистого материала ISCC PLUS,\n' +
                'заменив сырье ПВХ на основе нефтехимических продуктов\n' +
                'на био-сбалансированный ПВХ, полученный из растительного сырья.',
          isActive: false
        },
        {
          title: 'Пленки для ламинации',
          picture: 'images/aboutus/sustainability/sustainable_products/products-industrial.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon3.png',
          text: 'ПЭТ получил сертификат Global Recycled Standard (GRS) версии 4.0. Он содержит \n' +
                'переработанный после потребителя полиэстер от 53 до 84 %. Примечательно, что\n' +
                'для одной кухонной дверцы из ПЭТ можно повторно использовать 9 бутылок ПЭТ (500 мл).',
          isActive: false
        },
        {
          title: 'Для автомобиля',
          picture: 'images/aboutus/sustainability/sustainable_products/products-automotive.png',
          text: 'В компании LX Hausys мы активно используем биоматериалы, например, полученные из сахарного тростника,\n' +
                'чтобы получить и постоянно обновлять сертификат экологической валидации (ECV) UL Solutions. Наша биокожа PU (VERNO Bio)\n' +
                'содержит 19% биоматериалов, а наша замша rBio PU (Calfpelle Bio) содержит 10% биоматериалов.\n' +
                'В дальнейшем мы будем расширять ассортимент устойчивых биоматериалов, таких как кенаф, эвкалипт,\n' +
                'кактус и раковин (например, устричных раковин), а также направлять \n' +
                'исследовательскую деятельность на максимальное увеличение их содержания.',
          isActive: false
        }
      ],
      tabDatasCn: [
        {
          title: '人造大理石',
          picture: 'images/aboutus/sustainability/sustainable_products/products-solid.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon1.png',
          text: '人造大理石产品获得了美国国际认证实体SCS Global Services的环境认证。\n' +
                '这些产品含有超过10%的可回收原材料。',
          isActive: false
        },
        {
          title: '地板',
          picture: 'images/aboutus/sustainability/sustainable_products/products-flooring.png',
          text: 'HFLOR采用来自植物来源的生物平衡PVC替代石化基PVC原料，\n' +
                '获得了ISCC PLUS环保材料证书。',
          isActive: false
        },
        {
          title: '产业用膜',
          picture: 'images/aboutus/sustainability/sustainable_products/products-industrial.png',
          icon: 'images/aboutus/sustainability/sustainable_products/tab-icon3.png',
          text: 'PET获得了全球回收标准（GRS）4.0版。它含有回收的消费后聚酯纤维，含量从53%到84%不等。\n' +
                '值得注意的是，通过rPET，每个厨房门可以重复使用9个PET瓶（500ml）。',
          isActive: false
        },
        {
          title: '汽车配件材料',
          picture: 'images/aboutus/sustainability/sustainable_products/products-automotive.png',
          text: 'LX Hausys积极采用生物材料，如从糖衍生的材料，以获得并不断更新UL Solutions的环境索赔验证（ECV）。\n' +
                '我们的生物PU皮革（VERNO Bio）经验证含有19%的生物材料，\n' +
                '而我们的rBio PU绒面革（Calfpelle Bio）含有10%的生物材料。展望未来，\n' +
                '我们将使红麻、桉树、仙人掌和贝壳（如牡蛎壳）等可持续生物材料的范围多样化，并指导研究活动以最大限度地提高其含量。',
          isActive: false
        }
      ],
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
</style>
