<template>
  <div class="overview-card-image-area-root">
    <div class="overview-card-image-area">
      <div class="image-area" :style="backgroundStyle">
        <div v-if="title" class="title-area">
          <pre>{{ title }}</pre>
        </div>
      </div>
      <div class="text-area">
        <pre class="ctt ctt-t3 description-txt">{{ description }}</pre>

        <div class="child" v-for="(it, idx) in descriptionList" :key="idx">
          {{ `• ${it}` }}
        </div>
      </div>
    </div>

    <div
      v-if="addtionalInfo"
      class="addtional-area"
    >
      <div class="additional-title-area">
        <pre>{{ addtionalInfo.title }}</pre>
      </div>

      <div class="additional-desc-area">
        <pre>{{ addtionalInfo.desc }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    descriptionList: {
      type: Array
    },
    addtionalInfo: {
      type: Object
    }
  },

  computed: {
    backgroundStyle () {
      return `background-image: url(${this.imageSrc});`
    }
  }
}
</script>

<style scoped>
.overview-card-image-area {
  width: 460px;
  height: 484px;
}

.overview-card-image-area .image-area {
  position: relative;
  width: 420px;
  height: 280px;
  z-index: 1;
  display: flex;
}

.overview-card-image-area .image-area .title-area {
  margin: auto;
}

.overview-card-image-area .image-area .title-area pre {
  color: #FFF;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  /* 130.769% */
}

.overview-card-image-area .text-area {
  position: relative;
  left: 40px;
  top: -165px;
  width: 420px;
  height: 370px;
  background-color: rgba(215, 211, 207, 0.30);
  padding-left: 35px;
  padding-top: 196px;
}

.description-txt {
  max-width: 320px;
  white-space: pre-wrap;
}

.overview-card-image-area .text-area .desc-area .child {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 228.571% */
}

.child {
  margin-bottom: 10px;
}

.overview-card-image-area-root {
  display: flex;
  flex-direction: column;
}

.overview-card-image-area-root .addtional-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-left: 40px;
  padding-left: 45px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 420px;
  flex-shrink: 0;
  background-color: rgba(215, 211, 207, 0.30);
}

.overview-card-image-area-root .title-area pre {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 228.571% */
}

.overview-card-image-area-root pre {
  color: #222;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 228.571% */
}

.additional-title-area pre {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 180% */
}

.additional-desc-area pre {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

@media (max-width: 768px) {
  .overview-card-image-area-root {
    align-items: center;
  }
  .overview-card-image-area {
    width: 90%;
    height: 400px;
  }

  .overview-card-image-area .image-area {
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    height: 45%;
    background-size: cover;
    min-height: 210px;
  }

  .overview-card-image-area .image-area .title-area pre {
    white-space: pre-wrap;
  }

  .description-txt {
    width: 75%;
  }

  .overview-card-image-area .text-area {
    width: 90%;
    padding-top: 126px;
    top: -110px;
    height: 55%;
    min-height: 267px;
  }
  .overview-card-image-area-root pre {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }

  .child {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }

  .addtional-area {
    width: 90% !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}
</style>
