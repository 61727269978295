<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content content-finder">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home">{{ $t('PCD.text.home') }}</li>
          <li>{{ $t('PCD.text.products') }}</li>
          <li>VIATERA</li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container (viatera Finder) -->
      <div class="container prod-fd-view container_pf viatera-pf">
        <!-- visual -->
        <!-- start -->
        <div class="visual visual-detail">
          <div class="visual-image full_width center" v-if="pfBanImg" v-bind:style="{
              backgroundImage: 'url( ' + $imagePath + pfBanImg + ')',
            }">
            <!-- <div class="bgdim"></div> -->
            <div class="inner_width">
              <h1 class="txt-box">
                <div class="title">
                  <span class="txt">ALL COLORS</span>
                </div>
                <p class="txt-02">Inspired by the Moment of Life, Inspired by Nature.</p>
              </h1>
            </div>
          </div>
        </div>
        <!-- end -->

        <!-- color -->
        <div class="drop-list inner">
          <div class="w-con-02">
            <div class="gallery">
              <div class="search-box">
                <div class="filter_area">
                  <div class="filter_wrap">
                    <button type="button" class="btn_filter" @click="toggleFilterPopup">
                      <span class="icon icon_filter"></span>
                      <span class="txt">Filter</span>
                      <span class="icon icon_arrow_down"></span>
                    </button>
                  </div>
                  <div class="compare_wrap">
                    <input type="checkbox" id="compare_btn" class="compare_btn" name="compare_button" value="" v-on:click="compareOn=!compareOn">
                    <label for="compare_btn">
                      <span class="txt">ON</span>
                      <span class="btn"></span>
                    </label>
                    <span>Compare</span>
                  </div>
                  <div class="search">
                    <input type="text" id="textSearch" :placeholder="this.$t('PF.text.searchText')" @input="textSearch($event)" />
                    <button type="button" id="searchBtn" class="icon_search" @click="textSearchDel()">
                      {{ $t('PF.button.search') }}
                    </button>
                  </div>
                </div>
                <!-- s: filter popup -->
                <div v-if="showPopup" class="filter_pop">
                  <div class="filter_tit"><p>FILTER BY</p><button type="button" class="btn_close" @click="close_popup()"></button></div>
                  <div class="filter_top">
                    <ul>
                      <li>
                        <div class="filter_checkbox_tit">
                          <span>COMPARE</span>
                        </div>
                        <div class="compare_wrap">
                          <input type="checkbox" id="compare_btn" class="compare_btn" name="compare_button" value="">
                          <label for="compare_btn">
                            <span class="txt">ON</span>
                            <span class="btn"></span>
                          </label>
                        </div>
                      </li>
                    <!--HFLOR-->
                    <li v-if="useCategoryId === 483">
                      <div class="filter_checkbox_tit">
                        <span>TILE</span>
                      </div>
                      <div class="filter_checkbox_cont">
                        <div class="checkbox_box color_round">
                          <ul class="checkbox_more no_line">
                            <li v-for="lefthue in lefthue">
                            <input type="checkbox" :id="'hue_'+lefthue.id.value" name="color" v-model="selectedHues" :value="lefthue.id.value" />
                            <i v-if="lefthue.text === 'Other'">
                              <span :style="'background-image : url(images/products/' + lefthue.id.value + '.png)'"> </span>{{ lefthue.text }}</i>
                              <label :for="`hue_${lefthue.id.value}`">
                              <span class="round"></span>
                              <span class="text">{{ lefthue.text }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                      <li v-if="useCategoryId === 483">
                        <div class="filter_checkbox_tit">
                          <span>SHEET</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box color_round">
                          <ul class="checkbox_more no_line">
                            <li v-for="lefthue in lefthue">
                            <input type="checkbox" :id="'hue_'+lefthue.id.value" name="color" v-model="selectedHues" :value="lefthue.id.value" />
                            <i v-if="lefthue.text === 'Other'">
                              <span :style="'background-image : url(images/products/' + lefthue.id.value + '.png)'"> </span>{{ lefthue.text }}</i>
                              <label :for="`hue_${lefthue.id.value}`">
                              <span class="round"></span>
                              <span class="text">{{ lefthue.text }}</span>
                              </label>
                            </li>
                          </ul>
                          </div>
                        </div>
                      </li>
                    <!--HFLOR-->
                    <!-- 색상 필터 옵션 -->
                    <li>
                      <div class="filter_checkbox_tit">
                        <span>COLOR</span>
                      </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box color_round">
                            <ul class="checkbox_more no_line">
                              <li v-for="lefthue in lefthue">
                              <input type="checkbox" :id="'hue_'+lefthue.id.value" name="color" v-model="selectedHues" :value="lefthue.id.value" />
                              <i v-if="lefthue.text === 'Other'">
                                <span :style="'background-image : url(images/products/' + lefthue.id.value + '.png)'"> </span>{{ lefthue.text }}</i>
                                <label :for="`hue_${lefthue.id.value}`">
                                <span class="round"></span>
                                <span class="text">{{ lefthue.text }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                      </div>
                    </li>
                    <!-- 패턴 필터 옵션 -->
                    <li>
                      <div class="filter_checkbox_tit">
                        <span>PATTERNS</span>
                      </div>
                      <div class="filter_checkbox_cont">
                        <div class="checkbox_box color_square">
                          <ul class="checkbox_more no_line">
                          <li v-for="leftpattern in leftpattern">
                            <input type="checkbox" :id="'pattern_' + leftpattern.id.value" v-model="selectedPatterns" :value="leftpattern.id.value" name="Patterns" />
                            <label :for="`pattern_${leftpattern.id.value}`">
                              <span></span>
                              {{ leftpattern.text }}
                            </label>
                          </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                      <!-- collection -->
                      <li>
                        <div class="filter_checkbox_tit">
                          <span>COLLECTION</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li v-for="coll in leftcollection">
                                <input type="checkbox" :id="coll.category.name" name="collection" v-model="selectedCollections" :value="coll.category.name" />
                                <label :for="`${coll.category.name}`">
                                    <span></span>
                                    {{ coll.category.name }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <!-- finish -->
                      <li v-if="useCategoryId === 502">
                        <div class="filter_checkbox_tit">
                          <span>FINISH</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li v-for="leftfnsh in leftfnsh" :data-chk="leftfnsh.id.value">
                                <input type="checkbox" :id="'finish_'+leftfnsh.id.value" name="Finish" v-model="selectedFinishes" :value="leftfnsh.id.value" />
                                <label :for="'finish_'+`${leftfnsh.id.value}`">
                                <span></span>
                                {{ leftfnsh.text }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li v-if="nationCode == '_GL'">
                        <div class="filter_checkbox_tit">
                          <span>COUNTRY</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li v-for="leftCountry in leftCountry" :data-chk="leftCountry.id.value">
                                <input type="checkbox" :id="'country_'+leftCountry.id.value" name="Country" v-model="selectedCountry" :value="leftCountry.id.value" />
                                <label :for="'country_'+`${leftCountry.id.value}`">
                                <span></span>
                                {{ leftCountry.id.value }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="filter_checkbox_tit">
                          <span>NEW</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li>
                                <input type="checkbox" id="isNew_true" v-model="selectedNewColors" value="true" />
                                <label for="isNew_true">
                                <span></span>
                                {{ $t('PF.text.new_colors') }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <!--HFLOR-->
                      <li v-if="useCategoryId === 483">
                        <div class="filter_checkbox_tit">
                          <span>PRODUCT TYPE</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li>
                                <input type="checkbox" id="isNew_true" v-model="selectedNewColors" value="true" />
                                <label for="isNew_true">
                                <span></span>
                                {{ $t('PF.text.new_colors') }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="filter_checkbox_tit">
                          <span>THICKNESS</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li>
                                <input type="checkbox" id="isNew_true" v-model="selectedNewColors" value="true" />
                                <label for="isNew_true">
                                <span></span>
                                {{ $t('PF.text.new_colors') }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="filter_checkbox_tit">
                          <span>WEAR LAYER</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li>
                                <input type="checkbox" id="isNew_true" v-model="selectedNewColors" value="true" />
                                <label for="isNew_true">
                                <span></span>
                                {{ $t('PF.text.new_colors') }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="filter_checkbox_tit">
                          <span>DIMENSIONS</span>
                        </div>
                        <div class="filter_checkbox_cont">
                          <div class="checkbox_box">
                            <ul class="checkbox_more no_line">
                              <li>
                                <input type="checkbox" id="isNew_true" v-model="selectedNewColors" value="true" />
                                <label for="isNew_true">
                                <span></span>
                                {{ $t('PF.text.new_colors') }}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <!--HFLOR-->
                    </ul>
                  </div>
                  <!--gl-->
                  <div class="filter_bottom">
                    <div class="btn_box">
                      <button class="btn_wh" @click="filterClearAll">Clear All</button>
                      <button class="btn_bk" @click="applyFilter">Apply</button>
                    </div>
                  </div>
                </div>
                <!-- e: filter popup -->
                <div class="sort_area">
                  <div class="select-view" v-if="colorList.length > 0">
                    <ul class="list-filter-key">
                      <li class="sel" v-for="color in uniqueColors" @click="colorDelete(color.subject, color.name, color.id)" style="cursor: pointer">
                        <span v-if="color.subject == 'Collection'">{{ color.subject }}</span>
                        <span vamasa="color.type">{{ color.type }}</span>
                        : <span> {{ color.name }} </span>
                        <button type="button">&#10005;</button>
                      </li>
                      <li class="sel del_all">
                        <button type="button" class="btn-all-delete" v-if="colorList.length > 0" @click="clearAll()">
                          <span>{{ $t('PF.button.clear_all') }} <em>&#10005;</em></span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="btns">
                    <ul>
                      <li class="sel-box">
                        <button type="button" class="btn-sel">
                          <span>Newest</span>
                        </button>
                        <div class="sel-list">
                          <ul class="box">
                            <li>
                              <button type="button" @click="
                                sortByChange('Products A to Z', 'asc', 'title')
                                ">
                                {{ $t('PF.button.products_a_to_z') }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="
                                sortByChange('Products Z to A', 'desc', 'title')
                                ">
                                {{ $t('PF.button.products_z_to_a') }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="sortByChange('New Products first', 'desc')">
                                {{ $t('PF.button.new_products_first') }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="gallery_layout_btns">
                    <button type="button" class="layout_grid on">
                      <span class="icon icon_grid"></span>
                    </button>
                    <button type="button" class="layout_list">
                      <span class="icon icon_list"></span>
                    </button>
                  </div>
                  <div class="more-box type02 type03 compare_box" v-if="compareOn">
                    <button type="button" class="button h55 btn_compare" id="compare" @click="getCompareList()">
                      <span class="txt">{{ $t('PF.button.compare_your_colors') }}</span>
                      <span class="bubble_num">
                    <span>{{ compareArr.length }}</span>
                </span>
                    </button>
                  </div>
                </div>
                <div class="count_area">
                  <div class="filter-cnt">
                    <span class="num">{{ contentLength }}</span>
                    <span>&nbsp;</span>
                    <span class="txt">Results</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="list-type-view row-04">
              <ul>
                <li v-for="(item, index) in contentList" :key="index">
                  <router-link :to ='makeUrl(item)' :data-ec-product="makeDataLayerForm(item)">
                    <div class="img-box over">
                      <span class="tag h30 black" v-if="item.attribute.isNew == 'true'">{{ $t('PF.text.new') }}</span>
                      <span v-if="useCategoryId === 502" class="tag h22 viatera">VIATERA</span>
                      <span v-else-if="useCategoryId === 484" class="tag h22 himacs">HI-MACS</span>
                      <span v-else-if="useCategoryId === 483" class="tag h22 hflor">HFLOR</span>
                      <span v-else-if="useCategoryId === 543" class="tag h22 teracanto">TERACANTO</span>
                      <span v-else-if="useCategoryId === 9246" class="tag h22 benif">BENIF</span>
                      <span v-else-if="useCategoryId === 9308" class="tag h22 etc">DECO FOIL</span>
                      <span v-else-if="useCategoryId === 9469" class="tag h22 etc">EXTERIOR FOIL</span>
                      <span v-else-if="useCategoryId === 9350"  class="tag h22 etc">VIZUON</span>
                      <span class="image_name">{{ item.content.title }}</span>
                      <div class="icon_tag_wrap">
                        <i class="icon-no-fire"><span>no-fire</span></i>
                      </div>
                      <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb" />
                    </div>
                  </router-link>
                  <div class="view">
                    <!--<a @click="openSampleOrder(item.content.id)" class="icon_cart">{{ $t('PCD.text.cart') }}</a>-->
                  </div>
                  <div class="pf_cont_compare_wrap">
                    <input type="checkbox" id="item.content.id">
                    <label class="inp-chk">
                      <input type="checkbox" class="pf_cont_compare" v-model="compareArr" :id="item.content.id" :value="item.content.id" @change="addCompare(item.content.id)">
                      <span>{{ $t('PF.button.compare') }}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- popup : alert -->
        <div class="layer-popup layer-alert">
          <div class="popup">
            <div class="pop-alert">
              <div>
                <div class="tit-box">
                  <p class="ctt ctt-t4 artTit">{{ $t('PF.popup.your_compare_is_full') }}</p>
                </div>

                <div class="txt-box">
                  <div class="imgwrap">
                    <img src="https://img.lxhausys.com/images/svg_icon/icon_alert_pop_check.svg" alt="Your comparator is full">
                  </div>

                  <div class="txt">
                    <p>
                      Do you want to replace the first Products <br>
                      you added by the one you selected?
                    </p>
                  </div>

                  <div class="btn-box">
                    <button type="button" id="replace" class="btn" @click="replace()">
                      <span>{{ $t('PF.popup.replace') }}</span></button>
                  </div>
                </div>
              </div>

              <div class="bottom-box">
                <button @click="getCompareList()">
                  <span>Edit products in compare</span>
                </button>
              </div>

              <button type="button" class="btn-close" onclick="LayerPopup.close();">
                close
              </button>
            </div>
          </div>
          <!-- //popup -->
        </div>
        <!-- //popup : alert -->

        <!-- popup : Compare -->
        <div class="layer-popup layer-compare" id="pdf_wrap">
          <div class="popup pop-close">
            <div class="pop-compare scr-y">
              <div class="tit-box">
                <p>COMPARE VIATERA PRODUCTS</p>
              </div>
            </div>
            <div class="popup-compare-cont">
              <div class="subtit-box">
                <p>Characteristics &#38; Technical Performance</p>
              </div>
              <div class="prod-list tbl-compare">
                <ul>
                  <tr>
                    <td v-for="(item,idx) in compareList">
                      <li>
                        <div class="prod-box">
                          <div class="prod">
                            <div class="img-box">
                              <img :src="$imagePath + item.attribute.pf_thumb" :alt="item.attribute.pf_thumb" />
                              <div class="btn-remove">
                                <button type="button" class="btn-remove02" @click="removeCompare(item.content.id)">
                                  <span>{{ $t('PF.button.remove') }}</span></button>
                              </div>
                            </div>

                            <div class="info">
                              <div class="name">
                                <span>{{ item.content.title }}</span>
                              </div>
                              <div class="tag">
                                <span>{{ item.parents[0].name }}</span>
                              </div>
                            </div>

                            <div class="tbl_wrap">
                              <table>
                                <tr>
                                  <th><span>Dimensions</span></th>
                                </tr>
                                <tr>
                                  <td>
                                    <span>{{ item.attribute.dimensionText }}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <th><span>ProductType</span></th>
                                </tr>
                                <tr>
                                  <td v-if="item.attribute.prdTypeText != undefined">
                                    <span>{{ item.attribute.prdTypeText }}</span>
                                  </td>
                                  <td v-else><span>-</span></td>
                                </tr>
                                <tr>
                                  <th><span>Thickness</span></th>
                                </tr>
                                <tr>
                                  <td v-if="item.attribute.thickWearLayer != undefined">
                                    <span>{{ item.attribute.thickWearLayer }}</span>
                                  </td>
                                  <td v-else><span>-</span></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </li>
                    </td>
                  </tr>
                  <li>
                    <div class="prod-box">
                      <div class="prod">
                        <div class="img-box">
                          <div class="btn-add hf-btn-add">
                            <button type="button" onclick="LayerPopup.close();">
                              <span>{{ $t('PF.button.add_more') }}</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="btn-download">
                <button type="button" class="button h44 btn_download" @click="makePDF()">
                  <span class="txt">Download</span>
                  <span class="icon icon_download"></span>
                </button>
              </div>

              <button type="button" class="btn-close" onclick="LayerPopup.close();">
                close
              </button>
            </div>
          </div>
        </div>
        <!-- //popup : Compare -->

        <!-- search filter popup -->
        <!-- 모바일에서 filter by 버튼 클릭시 팝업으로 나와야 함 -->
        <div id="layer-search-box" class="search-box layer-view layer-search-box popup-v2 popup-v2-mb" style="display: none">
          <div class="popup">
            <div class="scr-y">
              <!-- color / us | gl -->
              <div class="drop-list">
                <p class="tt-2">Filter By</p>
                <button class="filter-clos" @click="closeSearchPopup()"></button>
                <ul>
                  <li class="on">

                    <!-- us / gl title -->
                    <div class="tit" v-if="nationCode == '_GL' || nationCode == '_US'">
                      <span>{{ $t('PD.text.color') }}</span>
                      <button type="button" class="btn-view btn-color">
                        {{ $t('PF.button.color') }}
                      </button>
                    </div>

                    <div class="i-box type02 cont" style="display: block" v-if="nationCode != '_GL' && nationCode != '_US'">
                      <ul class="i-chk type03">
                        <li v-for="lefthue in lefthue">
                          <label class="inp-chk chk-color">
                            <input type="checkbox" name="color" :id="'hue_' + lefthue.id.value" @change="
                                [
                                  fnSearch(
                                    'hue',
                                    lefthue.text,
                                    lefthue.id.value,
                                    'Tone'
                                  ),
                                ]
                              " />
                            <i v-if="lefthue.text === 'Other'"><span :style="
                                  'background-image : url(images/products/' +
                                  lefthue.id.value +
                                  '.png)'
                                "></span>{{ lefthue.text }}</i>
                            <i v-else><span :style="'background-color:#' + lefthue.id.value"></span>{{ lefthue.text }}</i>
                            <span>{{ lefthue.text }}</span>
                          </label>
                          <!-- 이방식으로 교체합니다-->
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //search filter popup -->
        <infinite-loading @infinite="infiniteHandler"></infinite-loading>
      </div>
      <!-- //container (product Finder) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import { getCategoryAttributeList } from '@/server/category'
import ProductService from '@/server/product.service'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import InfiniteLoading from 'vue-infinite-loading'
import { mapState } from 'vuex'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    QuickMenuComponent,
    FooterComponent,
    InfiniteLoading,
    GnbComponent
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    }),
    uniqueColors () {
      return _.uniqBy(this.colorList, 'name')
    },
    currentFilterData () {
      return this.filterData[this.useCategoryId] || {}
    }
  },
  data () {
    return {
      showPopup: true,
      filterPopupVisible: false,
      categoryCode: [], /* 카테고리 코드 */
      categoryName: [], /* 카테고리 이름 */
      nationCode: '', /* 국가 코드 */
      scrollPercent: {
        x: 0,
        y: 0
      },
      filterData: {},
      useCategoryId: null,
      isLoading: false,
      filterShow: false,
      compareOn: false, /* compare 토글버튼 on 일때 */
      classValue: 'short-link type02',
      categoryUsId: [] /* 해당 카테고리 ID 값 가져오기. */,
      categoryUsName: [],
      categoryId: '',
      collectionList: [] /* 컬렉션 불러오자! */,
      checkCollectionList: [],
      collectionLength:
        '' /* 해당 컬렉션 길이 체크 (그룹 바이 컬렉션에 박을꺼임) */,
      contentList: [],
      contentLength: 0 /* 해당 컨텐츠 길이 체크 ( All Product에 박을꺼임) */,
      scrollPosition: 0,
      threshold: 200, // 스크롤 임계값 (픽셀)
      searchText: '' /* 검색했을때 의 input 값 */,
      value: [],
      res: [],
      colorList: [],
      totGalList: [],
      totGalListLen: 0,
      pageSize: 6,
      pageIndex: 1,
      orderField: [],
      orderType: [],
      bannerList: '' /* 배너 목록 */,
      selectedPatterns: [],
      selectedCollections: [],
      selectedFinishes: [],
      selectedApplications: [],
      selectedHues: [],
      selectedCountry: [],
      selectedNewColors: [],
      categoryIds: [],

      filterHue: [],
      colorName: [],

      /* compare */
      compareArr: [],
      compareRes: { list: [] },
      compareDetail: [],
      compareList: [],
      compareId: '',
      cateCode: '',
      replaceId: '',
      historyBack: false,

      mount: '',

      brief: [],
      subject: [],
      pfBanImg: '',

      /* filter menu */
      leftCltList: [], /* 컬렉션 리스트 */
      leftColor: [], /* 컬러 리스트 */
      leftPattns: [], /* 패턴 리스트 */
      leftPrdType: [], /* 상품타입 리스트 */
      leftThick: [], /* 두께 리스트 */
      leftWear: [], /* 겹 리스트 */
      leftDimen: [], /* 치수 리스트 */
      leftcollection: [],
      lefthue: [],
      leftfnsh: [],
      leftpattern: [],
      leftCountry: [],
      leftAPP: [], /* Application 리스트 */
      leftPT: [], /* Product Type 리스트 */
      leftAT: [], /* Adhesive Type 리스트 */
      leftAIR: [], /* Air-Free 리스트 */
      leftPC: [], /* Product Classification 리스트 */
      leftWEAT: [], /* Weatherability 리스트 */
      lefthuesld: [],
      lefthuem: [],
      lefthuew: [],
      lefthuestn: [],
      leftpatternsld: [],
      leftpatternm: [],
      leftpatternw: [],
      leftpatternstn: [],
      leftpvc: [],
      leftpet: [],
      leftacrylic: [],
      leftapplication: [],
      leftspecies: [],
      leftwarranty: [],
      limit: 0,
      scrollSize: 4,
      /* filter menu */

      getSessionContent: [] /* GL : 불러오는 값 */,

      clickCollIds: [],
      filterCollList: [],
      sortBy: 'Sort by',

      likeInfoArr: [],
      getlike: { list: [] },
      getlikecontentid: [],
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          },
          {
            operator: 'OR',
            values: []
          },
          {
            operator: 'OR',
            values: []
          },
          {
            operator: 'OR',
            values: []
          },
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      titleSeo: ''
    }
  },
  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    },
    compareArr: function () {
      sessionStorage.setItem('compare' + this.nationCode, JSON.stringify(this.compareArr))
    }
  },
  async created () {
    const t = this
    const promiseArr = []
    t.nationCode = t.$countryCode /* 국가 코드 */

    if (t.nationCode == '_GL') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        t.getSessionContent.push(o.id)
      })
    }
  },
  async mounted () {
    const t = this
    // Hflor
    // Hflor
    const nationCode = t.$countryCode
    t.seoName = t.$route.params.main_collection_name
    t.cateCode = t.vizuonSeoTocate(t.seoName) /* 카테고리 코드 */
    /* 해당하는 VIATERA US 컨텐츠 정보를 가져오자! */
    const categoryUs2 = await ProductService.getCategoryCode(
      'VIATERA_COLOR' + nationCode
    )
    // 현재 페이지 URL 확인
    const currentPath = this.$route.path

    let categoryCode = ''
    if (currentPath.includes('viatera-finder')) {
      categoryCode = 'VIATERA_COLOR' + nationCode
    } else if (currentPath.includes('himacs-finder')) {
      categoryCode = 'HIMACS_COLOR' + nationCode
    } else if (currentPath.includes('hflor-finder')) {
      categoryCode = 'PRD_FLOORING' + t.nationCode
    } else if (currentPath.includes('teracanto-finder')) {
      categoryCode = 'TERACANTO_COLOR' + t.nationCode
    } else if (currentPath.includes('benif-finder')) {
      categoryCode = 'BENIF_COLOR' + t.nationCode
    } else if (currentPath.includes('vizuon-finder')) {
      categoryCode = 'VIZUON_VT' + t.nationCode
    } else if (currentPath.includes('deco-foil-finder')) {
      categoryCode = 'DECO_COLOR' + t.nationCode
    } else if (currentPath.includes('exterior-finder')) {
      categoryCode = 'EXTERIOR_COLOR' + t.nationCode
    }

    console.log('categoryCode:', categoryCode) // 로그 추가

    // categoryId 설정
    const categoryData = await ProductService.getCategoryCode(categoryCode)
    t.useCategoryId = categoryData.data.object.id
    t.categoryId = categoryUs2.data.object.id

    console.log('categoryId:', t.useCategoryId)
    console.log('useCategoryId:', this.useCategoryId)
    console.log('filterData:', this.filterData)

    const getcategorycoll = await getCategoryAttributeList(t.useCategoryId)

    console.log('getcategorycoll:', getcategorycoll) // 로그 추가

    t.leftcollection = getcategorycoll.data.list

    let dicList

    if (categoryCode === 'VIATERA_COLOR_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'VTR_HUE',
        'VTR_FNSH' + nationCode,
        'VTR_PATTERN',
        'VTR_COUNTRY'
      ])

      t.lefthue = dicList.data.dicList[0].list /* VTR_HUE DictionaryValue */
      t.leftfnsh = dicList.data.dicList[1].list /* VTR_FNSH DictionaryValue */
      t.leftpattern = dicList.data.dicList[2].list /* VTR_PATTERN DictionaryValue */
      t.leftCountry = dicList.data.dicList[3].list /* VTR_CONTY DictionaryValue */
    } else if (categoryCode === 'HIMACS_COLOR_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'HIMACS_HUE',
        'HIMACS_PATTERN',
        'HIMACS_CONTY'
      ])

      t.lefthue = dicList.data.dicList[0].list /* HIMACS_HUE DictionaryValue */
      t.leftpattern = dicList.data.dicList[1].list /* HIMACS_PATTERN DictionaryValue */
      t.leftCountry = dicList.data.dicList[2].list /* HIMACS_CONTY DictionaryValue */
    } else if (categoryCode === 'PRD_FLOORING_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'FLRNG_ClrTone',
        'HFLOR_PATTERN_LUX' + t.nationCode,
        'FLRNG_PRDTYPE',
        'HFLOR_THICK_LUX' + t.nationCode,
        'HFLOR_WARE_LUX' + t.nationCode,
        'HFLOR_DIMEN_LUX' + t.nationCode
      ])

      t.lefthue = dicList.data.dicList[0].list /* FLRNG_ClrTone DictionaryValue */
      t.leftpattern = dicList.data.dicList[1].list /* FLRNG_PATTERN DictionaryValue */
      t.leftPrdType = _.filter(dicList.data.dicList[2].list, function (o) {
        return o.id.value.split('_')[0] === 'lux'
      }) /* FLRNG_PRDTYPE DictionaryValue */
      t.leftThick = dicList.data.dicList[3].list /* FLRNG_THICKNESS DictionaryValue */
      t.leftWear = dicList.data.dicList[4].list /* FLRNG_WEAR DictionaryValue */
      t.leftDimen = dicList.data.dicList[5].list /* FLRNG_DIMENSION DictionaryValue */
    } else if (categoryCode === 'TERACANTO_COLOR_US') {
      dicList = await ProductService.getDictionaryValueList([
        'TCT_HUE',
        'TCT_FNSH',
        'TCT_PATTERN',
        'TCT_FEATURES'
      ])

      t.lefthue = dicList.data.dicList[0].list /* TCT_HUE DictionaryValue */
      t.leftfnsh = dicList.data.dicList[1].list /* TCT_FNSH DictionaryValue */
      t.leftpattern = dicList.data.dicList[2].list /* TCT_PATTERN DictionaryValue */
      t.leftFeat = dicList.data.dicList[3].list /* TCT_FEATURES DictionaryValue */
    } else if (categoryCode === 'BENIF_COLOR_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'BENIF_HUE',
        'BENIF_HUE_W',
        'BENIF_PATTERN_W',
        'BENIF_PATTERN_M',
        'BENIF_TYPE_S',
        'BENIF_TYPE_T',
        'BENIF_TREE_SPECIES',
        'BENIF_FIRE'])

      t.lefthue = dicList.data.dicList[0].list /* BENIF_HUE DictionaryValue */
      t.lefthuew = dicList.data.dicList[1].list /* BENIF_HUE_W DictionaryValue */
      t.leftpatternw = dicList.data.dicList[2].list /* BENIF_PATTERN_W DictionaryValue */
      t.leftpatternm = dicList.data.dicList[3].list /* BENIF_PATTERN_M DictionaryValue */
      t.lefttypes = dicList.data.dicList[4].list /* BENIF_TYPE_S DictionaryValue */
      t.lefttypet = dicList.data.dicList[5].list /* BENIF_TYPE_T DictionaryValue */
      t.lefttree = dicList.data.dicList[6].list /* BENIF_TREE_SPECIES DictionaryValue */
      t.leftfire = dicList.data.dicList[7].list /* BENIF_Fire DictionaryValue */
    } else if (categoryCode === 'VIZUON_VT') {
      dicList = await ProductService.getDictionaryValueList([
        t.cateCode + '_APP',
        t.cateCode + '_PT',
        t.cateCode + '_AT',
        t.cateCode + '_AIR',
        t.cateCode + '_PC',
        t.cateCode + '_WEAT'])

      t.leftAPP = dicList.data.dicList[0].list /* Applications DictionaryValue */
      t.leftPT = dicList.data.dicList[1].list /* Product Type DictionaryValue */
      t.leftAT = dicList.data.dicList[2].list /* Adhesive Type DictionaryValue */
      t.leftAIR = dicList.data.dicList[3].list /* Air-free DictionaryValue */
      t.leftPC = dicList.data.dicList[4].list /* Product Classification DictionaryValue */
      t.leftWEAT = dicList.data.dicList[5].list /* Weatherability DictionaryValue */
    } else if (categoryCode === 'DECO_COLOR_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'DECO_HUE_SLD',
        'DECO_HUE_M',
        'DECO_HUE_W',
        'DECO_HUE_STN',
        'DECO_PATTERN_SLD',
        'DECO_PATTERN_M',
        'DECO_PATTERN_W',
        'DECO_PATTERN_STN',
        'DECO_PVC',
        'DECO_PET',
        'DECO_ACRYLIC'])

      t.lefthuesld = dicList.data.dicList[0].list /* DECO_HUE_SLD DictionaryValue */
      t.lefthuem = dicList.data.dicList[1].list /* DECO_HUE_M DictionaryValue */
      t.lefthuew = dicList.data.dicList[2].list /* DECO_HUE_W DictionaryValue */
      t.lefthuestn = dicList.data.dicList[3].list /* DECO_HUE_STN DictionaryValue */
      t.leftpatternsld = dicList.data.dicList[4].list /* DECO_PATTERN_SLD DictionaryValue */
      t.leftpatternm = dicList.data.dicList[5].list /* DECO_PATTERN_M DictionaryValue */
      t.leftpatternw = dicList.data.dicList[6].list /* DECO_PATTERN_W DictionaryValue */
      t.leftpatternstn = dicList.data.dicList[7].list /* DECO_PATTERN_STN DictionaryValue */
      t.leftpvc = dicList.data.dicList[8].list /* DECO_PVC DictionaryValue */
      t.leftpet = dicList.data.dicList[9].list /* DECO_PET DictionaryValue */
      t.leftacrylic = dicList.data.dicList[10].list /* DECO_ACRYLIC DictionaryValue */
    } else if (categoryCode === 'EXTERIOR_COLOR_GL') {
      dicList = await ProductService.getDictionaryValueList([
        'EXTERIOR_HUE',
        'EXTERIOR_PATTERN',
        'EXTERIOR_APP',
        'EXTERIOR_SPECIES',
        'EXTERIOR_WARRANTY',
        'EXTERIOR_GRADE',
        'EXTERIOR_PRD'])

      t.lefthue = dicList.data.dicList[0].list /* EXTERIOR_HUE DictionaryValue */
      t.leftpattern = dicList.data.dicList[1].list /* EXTERIOR_PATTERN DictionaryValue */
      t.leftapplication = dicList.data.dicList[2].list /* EXTERIOR_APP DictionaryValue */
      t.leftspecies = dicList.data.dicList[3].list /* EXTERIOR_SPECIES DictionaryValue */
      t.leftwarranty = dicList.data.dicList[4].list /* EXTERIOR_WARRANTY DictionaryValue */
    } else {
      // 그외 적용필요
      dicList = []
    }

    console.log('dicList:', dicList) // 로그 추가

    /* 해당하는 배너 US 정보를 가져오자! */
    const categoryUs = await ProductService.getCategoryAttr(
      'BAN_VIATERA_CAT' + t.nationCode
    )
    t.brief = categoryUs.data.attribute.brief
    t.subject = categoryUs.data.attribute.subject
    t.pfBanImg = categoryUs.data.attribute.pf_image
    /* 배너 & 배너내용 가져오자! */

    t.categoryUsId.push(t.categoryId)
    const collectionList2 = await ProductService.getCategoryAttributeList(
      categoryUs2.data.object.id
    )
    t.collectionList = collectionList2.data.list
    t.checkCollectionList = collectionList2.data.list
    t.collectionLength = collectionList2.data.list.length

    // URL 조건 1개 param
    const filterNm = this.$route.params.filterNm
    const filterCd = this.$route.params.filterCd

    // URL 조건 2개 param
    const filterNm1 = this.$route.params.filterNm1
    const filterCd1 = this.$route.params.filterCd1
    const filterNm2 = this.$route.params.filterNm2
    const filterCd2 = this.$route.params.filterCd2

    // title seo name
    if (filterCd !== undefined) {
      this.titleSeo = filterCd
    } else if (filterCd === undefined && filterCd1 !== undefined) {
      this.titleSeo = filterCd1
    } else if (filterNm !== undefined && filterNm === 'isnew') {
      this.titleSeo = 'New Colors'
    } else {
      this.titleSeo = 'viatera-quartz-surface'
    }

    if (filterNm !== undefined || filterNm1 !== undefined) {
      const viateraFilter = JSON.parse(sessionStorage.getItem('viateraFilter' + this.nationCode))
      if (viateraFilter === undefined || viateraFilter === null) {
        // URL 조건 1개 일 경우
        if (filterNm !== undefined) {
          this.findFilter(filterNm, filterCd)

          // URL 조건 2개 일 경우
        } else if (filterNm1 !== undefined) {
          this.findFilter(filterNm1, filterCd1)
          this.findFilter(filterNm2, filterCd2)
        }
      } else {
        if (viateraFilter.length === 0) {
          await t.getItem(1, 16)
        } else {
          this.bringFilter()
          await t.getItem(1, 16)
        }
      }
    } else {
      this.bringFilter()
      // 조건 없을 경우
      await t.getItem(1, 16)
    }

    this.$nextTick(function () {
      /* visual slide */
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      $(document).on('click', '.select-view .btn-del', function () {
        if ($('.select-view > ul > li').length === 1) {
          $('.select-view > ul').remove()
          $('.btn-all').remove()
        } else {
          $(this).closest('.sel').remove()
        }
      })
      $(document).on('click', '.btn-all button', function () {
        $('.select-view > ul').remove()
        $('.btn-all').remove()
      })

      // 필터버튼 팝업
      $('.btn_filter').click(function () {
        $('.filter_pop').toggleClass('on')
      })

      $('.btn_close').click(function () {
        $('.filter_pop').removeClass('on')
      })

      // 필터 4줄 / 3줄 노출버튼
      $('.layout_grid').click(function () {
        $(this).addClass('on')
        $('.layout_list').removeClass('on')

        $('.list-type-view').removeClass('row-03')
        $('.list-type-view').addClass('row-04')
      })
      $('.layout_list').click(function () {
        $(this).addClass('on')
        $('.layout_grid').removeClass('on')

        $('.list-type-view').removeClass('row-04')
        $('.list-type-view').addClass('row-03')
      })
    })

    this.mount = 'mounted'
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    async handleScroll () {
      this.scrollPercent = this.getScrollPercentage()
      if (this.scrollPercent.y = 70) {
        this.infiniteHandler()
      }
    },
    getScrollPercentage () {
      // 현재 스크롤 위치
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft
      const scrollY = window.pageYOffset || document.documentElement.scrollTop

      // 문서의 전체 스크롤 가능한 크기
      const scrollWidth = document.documentElement.scrollWidth - document.documentElement.clientWidth
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight

      // 백분율 계산 (소수점 둘째 자리까지)
      const scrollPercentX = scrollWidth ? Math.round((scrollX / scrollWidth) * 10000) / 100 : 0
      const scrollPercentY = scrollHeight ? Math.round((scrollY / scrollHeight) * 10000) / 100 : 0

      return {
        x: scrollPercentX,
        y: scrollPercentY
      }
    },
    openSearchPopup (str) {
      LayerPopup.open(str)

      const element = document.getElementById(str)
      element.style.cssText = 'display : block'

      // this.init()
      $('body').css('overflow', 'hidden')
    },
    closeSearchPopup () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      $('body').css('overflow-x', 'hidden').css('overflow-y', 'auto')
    },
    findFilter: function (filterNm, filterCd) {
      const t = this

      if (filterNm == 'collection') {
        const collectionCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
        const collectionId = _.filter(t.leftcollection, function (o) {
          return o.category.name == collectionCd
        })

        t.$nextTick(function () {
          this.fnCateSearch(collectionCd, collectionId[0].category.id, 'click')
        })
      } else if (filterNm == 'hue') {
        const hueCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
        const hueId = _.filter(t.lefthue, function (o) {
          return o.text == hueCd
        })

        t.$nextTick(function () {
          $('#hue_' + hueId[0].id.value).prop('checked', true)

          t.fnSearch(
            'hue',
            hueId[0].text,
            hueId[0].id.value,
            'Color'
          )
        }) // end of fnsearch
      } else if (filterNm === 'pattern') {
        const patternCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
        const patternId = _.filter(t.leftpattern, function (o) {
          return o.text == patternCd
        })

        t.$nextTick(function () {
          $('#pattern_' + patternId[0].id.value).prop('checked', true)

          t.fnSearch(
            'pattern',
            patternId[0].text,
            patternId[0].id.value,
            'Pattern'
          )
        }) // end of fnsearch
      } else if (filterNm === 'finish') {
        const finishCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
        const finishId = _.filter(t.leftfnsh, function (o) {
          return o.text == finishCd
        })

        t.$nextTick(function () {
          $('#finish_' + finishId[0].id.value).prop('checked', true)

          t.fnSearch(
            'finish',
            finishId[0].text,
            finishId[0].id.value,
            'Finish'
          )
        }) // end of fnsearch
      } else if (filterNm === 'isnew' || filterNm === 'isNew') {
        $('#isNew_true').click()
      } else if (filterNm === 'country') {
        const countryCd = filterCd[0].toUpperCase() + filterCd.slice(1, filterCd.length)
        const countryId = _.filter(t.leftCountry, function (o) {
          return o.text == countryCd
        })

        t.$nextTick(function () {
          $('#country_' + countryId[0].id.value).prop('checked', true)

          t.fnSearch(
            'country',
            countryId[0].text,
            countryId[0].id.value,
            'Country'
          )
        }) // end of fnsearch
      }
    },
    bringFilter: function () {
      // 뒤로가기 세션
      const viateraFilter = JSON.parse(sessionStorage.getItem('viateraFilter' + this.nationCode))
      if (viateraFilter !== undefined && viateraFilter != null) {
        for (let i = 0; i < viateraFilter.length; i++) {
          if (viateraFilter[i].subject === 'Collection') {
            $('#' + viateraFilter[i].name).click()
          } else {
            $('#' + viateraFilter[i].subject + '_' + viateraFilter[i].id).click()
          }
        }
      }
    },
    /**
     * popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
    },
    /**
     * popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
    },
    fnSearch: async function (searchCd, selectSeach, value, type, mount) {
      const t = this
      t.pageIndex = 1
      if ($('#' + searchCd + '_' + value).is(':checked')) {
        if (searchCd == 'hue') {
          t.dictionaryCondition.list[0].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'pattern') {
          t.dictionaryCondition.list[1].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'finish') {
          t.dictionaryCondition.list[2].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'country') {
          t.dictionaryCondition.list[3].values.push({
            id: searchCd,
            value: value
          })
        } else if (searchCd == 'isNew') {
          t.dictionaryCondition.list[4].values.push({
            id: searchCd,
            value: value
          })
        }
        // t.value.push(value)
        t.colorList.push({
          subject: searchCd,
          name: selectSeach,
          id: value,
          type: type
        })
        console.log(this.colorList)
      } else {
        _.remove(t.colorList, function (v) {
          return v.subject == searchCd && v.id == value
        })
        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == value && v.id == searchCd
          })
        })
      }
      // }
      await t.getItem(1, 16)
    },

    textSearch (event) {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/inspiration/delete.png) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/inspiration/delete.png) center center' })
      }
      this.searchText = event.target.value
      this.getItem(1, 16)
    },

    textSearchDel () {
      if (this.isMobile()) {
        $('#searchBtnM').css({ background: 'url(images/svg_icon/icon_search_black.svg) center center' })
      } else {
        $('#searchBtn').css({ background: 'url(images/svg_icon/icon_search_black.svg) center center ' })
      }
      this.searchText = ''
      if (this.isMobile()) {
        $('#textSearchM').val('')
      } else {
        $('#textSearch').val('')
      }
      this.getItem(1, 16)
    },

    fnCateSearch: async function (name, id, type) {
      const t = this
      t.pageIndex = 1
      if (name == 'CateAll') {
        if ($('#CateAll').is(':checked')) {
          $('.Collection').prop('checked', false)
        }
        t.categoryUsId = []
        _.remove(t.colorList, function (c) {
          return c.subject == 'Collection'
        })
        t.categoryUsId.push(t.categoryId)
        t.filterCollList = []
        t.clickCollIds = []
        t.checkCollectionList = t.collectionList
        t.collectionLength = t.checkCollectionList.length
      } else {
        if (type == 'click' && $('#' + name).is(':checked') == false) {
          $('#' + name).prop('checked', true)
        } else if (type == 'click' && $('#' + name).is(':checked') == true) {
          $('#' + name).prop('checked', false)
        }
        if ($('#' + name).is(':checked')) {
          if (t.categoryUsId[0] == t.categoryId) {
            t.categoryUsId = []
          }
          t.categoryUsId.push(id)
          t.categoryUsName.push(name)
          t.colorList.push({ subject: 'Collection', name: name, id: id })
          // 필터링된 id
          this.clickCollIds.push(id)
          t.filtering = _.filter(t.collectionList, function (o, idx) {
            return o.category.id === id
          })
          // 첫검색할땐 필터링된 배열 리셋
          if (this.clickCollIds.length === 1) {
            t.filterCollList = []
            t.filterCollList.push(t.filtering[0])
          } else {
            t.filterCollList.push(t.filtering[0])
          }
          t.checkCollectionList = t.filterCollList
          t.collectionLength = t.checkCollectionList.length
          /*
          } else {
            $("#CateAll").prop("checked", true);
          }
*/
        } else {
          const checkedCount = $(
            'input:checkbox[class=Collection]:checked'
          ).length
          if (checkedCount == 0) {
            $('#CateAll').prop('checked', true)
          }
          _.remove(t.categoryUsId, function (c) {
            return c == id
          })
          _.remove(t.colorList, function (v) {
            return v.subject == 'Collection' && v.id == id
          })

          t.clickCollIds = _.filter(t.clickCollIds, function (o) {
            return o !== id
          })
          if (t.clickCollIds.length === 0) {
            t.checkCollectionList = t.collectionList
          } else {
            // 필터링 된 collectionList에서 선택한 collection 값 삭제
            t.checkCollectionList = _.filter(
              t.checkCollectionList,
              function (o) {
                return o.category.id !== id
              }
            )
          }
          t.collectionLength = t.checkCollectionList.length
        }
      }
      if ($('#btn-tab01').attr('class') != 'btn-tab on') {
        $('#btn-tab01').click()
      }
      await t.getItem(1, 16)
    },
    colorDelete: async function (subject, name, id) {
      const t = this

      _.remove(t.colorList, function (v) {
        return v.subject == subject && v.id == id
      })
      if (subject === 'Collection') {
        $('#' + name).prop('checked', false)
        const checkedCount = $('input:checkbox[class=Collection]:checked').length
        if (checkedCount == 0) {
          $('#CateAll').prop('checked', true)
        }
        _.remove(t.categoryUsId, function (c) {
          return c == id
        })

        t.clickCollIds = _.filter(t.clickCollIds, function (o) {
          return o !== id
        })
        if (t.clickCollIds.length === 0) {
          t.checkCollectionList = t.collectionList
        } else {
          // 필터링 된 collectionList에서 선택한 collection 값 삭제
          t.checkCollectionList = _.filter(t.checkCollectionList, function (o) {
            return o.category.id !== id
          })
        }
        t.collectionLength = t.checkCollectionList.length
      } else {
        $('#' + subject + '_' + id).prop('checked', false)
        const checkedCount = $(
          'input:checkbox[class=' + subject + ']:checked'
        ).length
        if (checkedCount == 0) {
          $('#' + subject + 'All').prop('checked', true)
        }
        _.remove(t.dictionaryCondition.list, function (c) {
          _.remove(c.values, function (v) {
            return v.value == id && v.id == subject
          })
        })
      }
      await t.getItem(1, 16)
    },
    async infiniteHandler ($state) {
      if (!$state) {
        console.error('$state is undefined')
        return
      }

      if (this.isLoading) return // 이미 로딩 중이면 함수 실행을 중단

      this.isLoading = true // 로딩 시작
      const t = this

      try {
        const { data } = await ProductService.getDictionaryCondition(
          [t.useCategoryId],
          t.dictionaryCondition,
          t.searchText,
          t.pageIndex,
          t.pageSize,
          t.orderField,
          t.orderType,
          ['search_text']
        )

        console.log('data', data)
        this.contentLength = data.totalCount

        console.log('contentLength', this.contentLength)
        console.log('contentList', this.contentList.length)

        if (this.contentList.length < this.contentLength) {
          t.pageIndex += 1
          if (t.pageIndex === 2) {
            t.pageIndex = 3
          }
          t.pageSize = 4

          this.contentList.push(...data.list)
          $state.loaded()
        } else {
          $state.complete() // 모든 데이터를 로드했을 때
        }
      } catch (error) {
        console.error('Error in infiniteHandler:', error)
        $state.error() // 에러 발생 시
      } finally {
        this.isLoading = false // 로딩 종료 (성공하든 실패하든)
      }
    },
    onScroll () {
      this.scrollPosition = window.scrollY

      // 스크롤 위치가 임계값을 넘으면 콘텐츠 로드 트리거
      if (this.scrollPosition >= this.threshold) {
        this.$refs.infiniteLoading.$emit('$InfiniteLoading:trigger')
      }
    },

    listType (pageIndex, pageSize, scrollSize) {
      const t = this
      t.pageIndex = pageIndex
      t.pageSize = pageSize
      t.scrollSize = scrollSize
      this.$refs.infiniteLoading.stateChanger.reset()
      this.$nextTick(function () {
        t.getItem(pageIndex, pageSize)
      })
    },
    async getItem (pageIndex, pageSize) {
      const t = this
      if (t.categoryUsId == '') {
        t.categoryUsId.push(t.categoryId)
      }
      t.pageIndex = 1
      t.pageSize = pageSize

      if (t.colorList !== undefined) {
        sessionStorage.setItem('viateraFilter' + this.nationCode, JSON.stringify(t.colorList))
      }

      const res = await ProductService.getDictionaryCondition(
        [t.useCategoryId],
        t.dictionaryCondition,
        t.searchText,
        t.pageIndex,
        t.pageSize,
        t.orderField,
        t.orderType,
        ['search_text']
      )
      console.log(res)
      t.contentList = res.data.list
      t.contentLength = res.data.totalCount
    },
    applyFilter () {
      // 선택된 값이 없는 경우 필터링 수행 없이 전체 데이터 검색
      if (
        this.selectedHues.length === 0 &&
        this.selectedPatterns.length === 0 &&
        this.selectedFinishes.length === 0 &&
        this.selectedCollections.length === 0 &&
        this.selectedCountry.length === 0 &&
        this.selectedNewColors.length === 0
      ) {
        // 알림창 표시
        alert('Please Select Option')
        return // 함수 종료
      }
      this.categoryIds = []
      // dictionaryCondition 초기화
      this.dictionaryCondition = {
        operator: 'AND',
        list: [
          { operator: 'OR', values: this.selectedHues.map(v => ({ id: 'hue', value: v })) },
          { operator: 'OR', values: this.selectedPatterns.map(v => ({ id: 'pattern', value: v })) },
          { operator: 'OR', values: this.selectedFinishes.map(v => ({ id: 'finish', value: v })) },
          { operator: 'OR', values: this.selectedCountry.map(v => ({ id: 'country', value: v })) },
          { operator: 'OR', values: this.selectedNewColors.includes('true') ? [{ id: 'isNew', value: true }] : [] }
        ]
      }

      // 선택된 색상 처리
      if (this.selectedHues.length > 0) {
        this.dictionaryCondition.list[0].values = this.selectedHues.map(hueValue => ({ id: 'hue', value: hueValue }))
      }

      // 선택된 패턴 처리
      if (this.selectedPatterns.length > 0) {
        this.dictionaryCondition.list[1].values = this.selectedPatterns.map(patternValue => ({
          id: 'pattern',
          value: patternValue
        }))
      }

      // 선택된 마감재 처리
      if (this.selectedFinishes.length > 0) {
        this.dictionaryCondition.list[2].values = this.selectedFinishes.map(finishValue => ({
          id: 'finish',
          value: finishValue
        }))
      }

      // 선택된 컬렉션 처리
      this.selectedCollections.forEach(collectionName => {
        const collectionObj = this.leftcollection.find(coll => coll.category.name === collectionName)
        if (collectionObj) {
          this.fnCateSearch(collectionObj.category.name, collectionObj.category.id)
          // categoryIds에 컬렉션 ID 추가
          if (!this.categoryIds.includes(collectionObj.category.id)) {
            this.categoryIds.push(collectionObj.category.id)
          }
        }
      })
      if (this.selectedCountry.length > 0) {
        this.dictionaryCondition.list[3].values = this.selectedCountry.map(countryValue => ({
          id: 'country',
          value: countryValue
        }))
      }

      if (this.selectedNewColors.includes('true')) {
        this.dictionaryCondition.list[4].values = [{ id: 'isNew', value: true }]
      }

      // fnSearch 함수 호출
      this.selectedHues.forEach(hueValue => {
        this.fnSearch('hue', hueValue, hueValue, 'Color')
      })

      this.selectedPatterns.forEach(patternValue => {
        this.fnSearch('pattern', patternValue, patternValue, 'Pattern')
      })

      this.selectedFinishes.forEach(finishValue => {
        this.fnSearch('finish', finishValue, finishValue, 'Finish')
      })

      this.selectedCountry.forEach(countryValue => {
        this.fnSearch('country', countryValue, countryValue, 'Country')
      })

      if (this.selectedNewColors.includes('true')) {
        this.fnSearch('isNew', 'New Colors', true, 'IsNew')
      }
      this.toggleFilterPopup(false)
    },
    // 필터 조건 초기화
    async filterClearAll () {
      // 모든 선택 옵션 초기화
      this.categoryUsId = []
      this.filterCollList = []
      this.selectedCollections = []
      this.selectedHues = []
      this.selectedPatterns = []
      this.selectedFinishes = []
      this.selectedCountry = []
      this.selectedNewColors = []
      this.categoryUsId = []
      this.categoryUsId.push(this.categoryId)
      this.checkCollectionList = this.collectionList
      this.collectionLength = this.checkCollectionList.length

      console.log(this.selectedCollections)

      // 체크박스 상태 초기화
      document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
        checkbox.checked = false
      })

      // categoryIds 초기화
      this.categoryIds = []

      // dictionaryCondition 초기화
      this.dictionaryCondition = {
        operator: 'AND',
        list: [
          { operator: 'OR', values: [] },
          { operator: 'OR', values: [] },
          { operator: 'OR', values: [] },
          { operator: 'OR', values: [] },
          { operator: 'OR', values: [] }
        ]
      }
      $('input:checkbox').prop('checked', false)
      $("input:checkbox[id*='All']").prop('checked', true)
    },
    async clearAll () {
      this.filterClearAll()
      const t = this
      t.categoryUsId = []
      t.filterCollList = []
      t.colorList = []
      t.clickCollIds = []
      t.dictionaryCondition.list[0].values = []
      t.dictionaryCondition.list[1].values = []
      t.dictionaryCondition.list[2].values = []
      t.dictionaryCondition.list[3].values = []
      t.dictionaryCondition.list[4].values = []
      t.categoryUsId = []
      t.categoryUsId.push(t.categoryId)
      t.checkCollectionList = t.collectionList
      t.collectionLength = t.checkCollectionList.length
      $('input:checkbox').prop('checked', false)
      $("input:checkbox[id*='All']").prop('checked', true)
      await t.getItem(1, 16)
    },
    async sortByChange (text, type, field) {
      const _this = this

      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()

      _this.sortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.getItem(1, 16)
    },

    toggleLike (sr, cateType) {
      const t = this
      /* GL 일 때 => 세션 으로 저장함. */
      if (this.nationCode === '_GL') {
        const result = JSON.parse(sessionStorage.getItem('PD'))
        this.$utils.toggleLike(sr, 'PD', cateType, this, '', result)

        setTimeout(() => {
          const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
          t.getSessionContent = []
          _.filter(SessionPD, function (o) {
            t.getSessionContent.push(o.id)
          })
        }, 300)
      }
      /* US 일 때 => 로그인 으로 저장함. */
      if (this.nationCode === '_US') {
        this.$utils.toggleLike(sr, 'PD', cateType, this)
      }
    },

    openCart () {
      this.$root.$refs.cartView.openPopup()
    },

    goLink (type, url) {
      location.href = 'products/viatera-quartz-surface'
    },

    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    toggleFilterPopup (closePopup = true) {
      // Vuex 상태 관리를 사용하는 경우
      this.$store.commit('toggleFilterPopup', closePopup)

      // 컴포넌트 데이터로 관리하는 경우
      this.showPopup = !this.showPopup
    },
    clickFilter () {
      const element = document.getElementById('filterSearch')

      if (this.filterShow == false) {
        element.style.cssText = 'z-index: 99999999'
      }

      if (this.filterShow == true) {
        element.style.cssText = 'display : none'
      }

      return this.filterShow = !this.filterShow
    },

    async loadMore () {
      console.log('로드모어함수 실행중 ')
      const t = this
      /*      if(index == 2){
        t.pageIndex = 5;
      }else {
        t.pageIndex +=1
      }
      t.pageSize = 4
*/
      t.pageIndex += 1
      t.pageSize = 16
      const res = await ProductService.getDictionaryCondition(
        [t.useCategoryId],
        t.dictionaryCondition,
        t.searchText,
        t.pageIndex,
        t.pageSize,
        t.orderField,
        t.orderType,
        ['search_text']
      )
      for (let i = 0; i < res.data.list.length; i++) {
        t.contentList.push(res.data.list[i])
      }
      t.contentLength = res.data.totalCount
    },
    async makePDF () {
      html2canvas($('#pdf_wrap')[0], {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight
      }).then(function (canvas) {
        var filename = 'VIATERA_COMPARE_' + Date.now() + '.pdf'
        var doc = new jsPDF('p', 'mm', 'a4')
        var imgData = canvas.toDataURL('image/png')
        var imgWidth = 210
        var pageHeight = 295
        var imgHeight = canvas.height * imgWidth / canvas.width
        var heightLeft = imgHeight
        var position = 0
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
        window.scrollTo(0, 0)
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(filename)
      })
      const _this = this
      for (var i = 0; i < _this.compareList.length; i++) {
        if (typeof (dataLayer) !== 'undefined') {
          let prdVariant = ''
          if ((_this.compareList[i].attribute.price != null || undefined) && _this.compareList[i].attribute.price.length < 10) {
            prdVariant = _.join(_.flatMap(_this.compareList[i].attribute.price, function (v) {
              return v.size || ''
            }), '|')
          }
          dataLayer.push({
            event: 'product_compare',
            event_type: 'Download Comp',
            ecom_prd_id: _this.compareList[i]?.attribute?.code || '',
            ecom_prd_name: _this.compareList[i]?.content?.title || '',
            ecom_prd_brand: '',
            ecom_prd_category: _this.compareList[i]?.parents?.[i]?.name || '',
            ecom_prd_variant: prdVariant || '',
            ecom_prd_type: Array.isArray(_this.compareList[i]?.attribute?.patternText)
              ? _this.compareList[i]?.attribute?.patternText?.join(', ')
              : _this.compareList[i]?.attribute?.patternText || '',
            ecom_prd_collection: _this.compareList[i]?.parents?.[0]?.name || '',
            ecom_prd_color: ''
          })
        }
      }
    },
    addCompare (id) {
      const _this = this

      if (_this.compareArr.length < 5) {

      } else {
        _this.replaceId = id
        $('#' + id).prop('checked', false)
        this.removeCompare(id)
        LayerPopup.open('layer-alert')
        return false
      }
    },
    removeCompare (id) {
      const _this = this
      if (_this.compareList != null) {
        const idx = _this.compareList.findIndex(function (v) {
          return v.content.id === id
        })
        _this.compareArr.splice(idx, 1)
        _this.compareList.splice(idx, 1)
      }
    },
    // compare 팝업 호출1
    async getCompareList () {
      const _this = this
      const target = document.getElementById('replace')
      _this.compareList = []
      _this.compareRes = _this.compareArr
      LayerPopup.close() // alert 클로즈
      _this.compareDetail = await ProductService.getContentByIds(_this.compareRes)
      _this.compareList = _this.compareDetail.data
      LayerPopup.open('layer-compare')
      if (target.disabled) {
        target.disabled = false
      }
    },
    async replace () {
      const _this = this
      const target = document.getElementById('replace')
      target.disabled = true
      if (_this.compareArr != null) {
        _this.compareArr.splice(0, 1)
        _this.compareList.splice(0, 1)
      }
      _this.compareArr.push(_this.replaceId)
      _this.getCompareList()
    },
    extractDynamicPart (url) {
      const regex = /\/products\/([^/]+)\//
      const match = url.match(regex)
      return match ? match[1] : null
    },
    vizuonSeoTocate (code) {
      let cateCode = ''
      if (code === 'vehicles-and-transportation') {
        cateCode = 'VIZUON_VT'
      } else if (code === 'signs-and-displays') {
        cateCode = 'VIZUON_SD'
      } else if (code === 'illuminated-sign') {
        cateCode = 'VIZUON_IS'
      } else if (code === 'architecture') {
        cateCode = 'VIZUON_AR'
      } else {
        cateCode = 'VIZUON_CB'
      }
      return cateCode
    },
    loginalertLike () {
      alert(this.$t('PCD.alert.loginLike'))
    },
    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      return JSON.stringify({
        ecom_prd_id: data.attribute.code == '' ? data.content.id : data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand: data.parents[1].name,
        ecom_prd_category: data.parents[1].name, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      })
    },
    /* 화면 url 생성 */
    makeUrl (item) {
      let url
      if (this.useCategoryId === 502) { // VIATERA
        url = '/viatera-quartz-surface/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 484) { // HIMACS
        url = '/himacs-solid-surface/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 483) { // HFLOR
        url = '/hflor/' +
          this.makeSeoMainClt(this.categoryCode) +
          '/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 543) { // TERACANTO
        url = '/teracanto-porcelain-surface/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 9246) { // BENIF
        url = '/benif-interior-film/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 9308) { // DECO
        url = '/deco-film/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 9469) { // EXTERIOR
        url = '/exterior-foil/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.useCategoryId === 9350) { // VIZUON
        url = '/vizuon-sign-graphic/' +
          this.seoName +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      }
      return url
    },
    makeSeoMainClt (data) {
      let seoName = ''

      // HFLOR
      if (this.useCategoryId === 483) {
        if (data.includes('FLOOR_LUX')) {
          seoName = 'lvt-flooring'
        } else if (data.includes('FLOOR_HOM')) {
          seoName = 'homogeneous-sheet'
        } else if (data.includes('FLOOR_HET')) {
          seoName = 'heterogeneous-sheet'
        } else {
          seoName = 'sports-flooring'
        }
        return seoName
      }

      // VIZUON
      if (this.useCategoryId === 9350) {
        if (data.includes('vehicles-and-transportation')) {
          seoName = 'VIZUON_VT'
        } else if (data.includes('signs-and-displays')) {
          seoName = 'VIZUON_SD'
        } else if (data.includes('illuminated-sign')) {
          seoName = 'VIZUON_IS'
        } else if (data.includes('architecture')) {
          seoName = 'VIZUON_AR'
        } else {
          seoName = 'VIZUON_CB'
        }
        return seoName
      }

      return seoName
    }
  }
}
</script>
