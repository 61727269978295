var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content pros-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v("Home")]
            ),
            _c("li", [_vm._v("Pros")]),
            _c("li", [_vm._v("BIM Library")]),
          ]),
        ]),
        _vm._m(0),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container prod-fd-view" }, [
      _c("div", { staticClass: "w-con-02" }, [
        _c("h1", { staticClass: "tt-1 mb-88 tac" }, [_vm._v("BIM Library")]),
      ]),
      _c("div", { staticClass: "inspr-view fd-view" }, [
        _c("iframe", {
          staticStyle: { border: "none" },
          attrs: {
            height: "1200",
            src: "https://market.bimsmith.com/microsite/lx-hausys",
            width: "100%",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }