<template>
  <OverviewSection
    title="Annual Trendship Seminar"
    size="56px"
    color="#222"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="text"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="年次トレンドシップセミナー"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="textJp"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Ежегодный семинар Trendship"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="textRu"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="年度趋势研讨会"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem>
      <SustainabilityText
        :text="textCn"
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../../sustainability/components/SustainabilityText.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    SustainabilityText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'Our annual Design Trend Seminar is attended by many architects and interior design experts to share information on domestic and\n' +
            'international socio-cultural issues, consumer lifestyles, and global design trends so as to forecast future design trends.\n' +
            'With this seminar initiated by LX Hausys for the first time in the industry,\n' +
            'we aim to identify changing conditions and trends and integrate them into our design to exert even broader influence in the industry.\n' +
            'Since 2023, we have been holding an interior design trend exhibition forum event \'TRENDSHIP\'.',
      textJp:'毎年開催されるデザイントレンドセミナーには、多くの建築家やインテリアデザインの専門家が参加し、国内外の社会文化問題、\n' + 
            '消費者のライフスタイル、世界のデザイントレンドに関する情報を共有し、将来のデザイントレンドを予測します。\n' +
            'LX Hausysが業界で初めて主催するこのセミナーでは、変化する状況やトレンドを把握し、それをデザインに取り入れることで、\n' +
            '業界にさらに大きな影響を与えることを目指しています。\n' +
            '2023年からは、インテリアデザインのトレンド展示フォーラムイベント「TRENDSHIP」を開催しています。',
      textRu:'Наш ежегодный семинар по тенденциям в дизайне посещают многие архитекторы и эксперты по дизайну интерьеров, чтобы\n' + 
'обмениваться информацией о национальных и международных социально-культурных проблемах, образе жизни потребителей, \n' +
'и глобальных тенденциях дизайна, чтобы спрогнозировать будущие тенденции в дизайне.\n' +
'Этот семинар проводится по инициативе LX Hausys впервые в отрасли,\n' +
'мы стремимся определить меняющиеся условия и тенденции и интегрировать их в наш дизайн, чтобы \n' +
'чтобы оказывать еще большее влияние на отрасль.\n' +
'С 2023 года мы проводим выставку-форум «TRENDSHIP», посвященную тенденциям в дизайне интерьера.',
      textCn:'设计趋势研讨会是每年由建筑装饰方面的专家参与，以讨论国内外社会文化热点，\n' + 
      '消费者生活方式及全球设计走向为基础，同时展望未来设计趋势的活动，LX Hausys是业内最早开展研讨会活动的公司。\n' + 
      'LX Hausys在今后也将继续解读环境变化及趋势走向，并将其与设计紧密结合，进一步扩大在业界的影响力。\n' +
      '2023年起，将扩大为普通顾客也可以参与的室内设计趋势展示论坛活动"TRENDSHIP"。',
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
</style>
