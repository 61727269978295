var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content himacs-pf" }, [
        _c("div", { staticClass: "location type03" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("PCD.text.home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.himacs")))]),
          ]),
        ]),
        _c("div", { staticClass: "container prod-fd-view himacs-finder" }, [
          _c("div", { staticClass: "visual visual-detail" }, [
            _vm.pfBanImg
              ? _c(
                  "div",
                  {
                    staticClass: "visual-image",
                    style: {
                      backgroundImage:
                        "url( " + _vm.$imagePath + _vm.pfBanImg + ")",
                    },
                  },
                  [
                    _c("div", { staticClass: "txt-box" }, [
                      _c("h1", { staticClass: "tt-26" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.subject) +
                            " " +
                            _vm._s(_vm.subject2) +
                            " "
                        ),
                      ]),
                      _vm.brief != null
                        ? _c("div", {
                            staticClass: "t-type-05",
                            domProps: { innerHTML: _vm._s(_vm.brief) },
                          })
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-more-point",
                          attrs: { type: "button" },
                        },
                        [
                          _c("router-link", { attrs: { to: "/himacs" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PCD.button.learnMore"))),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "bgdim" }),
          ]),
          _vm.nationCode != "_US" && _vm.nationCode != "_GL"
            ? _c("p", { staticClass: "ctt photo-credits ta-r" }, [
                _vm.photoCredit != null &&
                _vm.photoCredit != undefined &&
                _vm.photoCredit != ""
                  ? _c("span", {
                      staticClass: "pc-br",
                      domProps: { innerHTML: _vm._s(_vm.photoCredit) },
                    })
                  : _vm._e(),
                _vm.photoCredit2 != null &&
                _vm.photoCredit2 != undefined &&
                _vm.photoCredit2 != ""
                  ? _c("span", {
                      staticClass: "pc-br",
                      domProps: { innerHTML: _vm._s(_vm.photoCredit2) },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "col-02 inspr-view fd-view" }, [
            _c(
              "div",
              { staticClass: "search-box p-ui", attrs: { id: "filterSearch" } },
              [
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    attrs: {
                      id: "textSearch",
                      type: "text",
                      placeholder: this.$t("PF.text.searchText"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.textSearch($event)
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "icon-search gray",
                      attrs: { type: "button", id: "searchBtn" },
                      on: {
                        click: function ($event) {
                          return _vm.textSearchDel()
                        },
                      },
                    },
                    [_vm._v(" search ")]
                  ),
                ]),
                _c("div", { staticClass: "drop-list inner" }, [
                  !_vm.isMobile()
                    ? _c("ul", [
                        _c("li", { staticClass: "on" }, [
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-color",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.color")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                            ? _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PF.text.Tones"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-color",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.text.Tones")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                            ? _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk type03" },
                                    _vm._l(_vm.lefthue, function (lefthue) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk chk-color" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                name: "color",
                                                id: "hue_" + lefthue.id.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "hue",
                                                      lefthue.text,
                                                      lefthue.id.value,
                                                      "Color"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            lefthue.text === "Other"
                                              ? _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-image : url(images/products/" +
                                                      lefthue.id.value +
                                                      ".png)",
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ])
                                              : _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-color:#" +
                                                      lefthue.id.value,
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ]),
                                            _c("span", [
                                              _vm._v(_vm._s(lefthue.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                            ? _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk type03" },
                                    _vm._l(_vm.lefthue, function (lefthue) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk chk-color" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                name: "color",
                                                id: "hue_" + lefthue.id.value,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "hue",
                                                      lefthue.text,
                                                      lefthue.id.value,
                                                      "Tone"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            lefthue.text === "Other"
                                              ? _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-image : url(images/products/" +
                                                      lefthue.id.value +
                                                      ".png)",
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ])
                                              : _c("i", [
                                                  _c("span", {
                                                    style:
                                                      "background-color:#" +
                                                      lefthue.id.value,
                                                  }),
                                                  _vm._v(_vm._s(lefthue.text)),
                                                ]),
                                            _c("span", [
                                              _vm._v(_vm._s(lefthue.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-collection",
                                attrs: { type: "button" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PF.button.collection")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                _vm._l(_vm.leftcollection, function (coll) {
                                  return _c("li", [
                                    coll.category.hidden === false
                                      ? _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "Collection",
                                              attrs: {
                                                type: "checkbox",
                                                id: coll.category.id,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  ;[
                                                    _vm.fnCateSearch(
                                                      coll.category.name,
                                                      coll.category.id
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(coll.category.name)
                                              ),
                                              coll.category.name === "Eden Plus"
                                                ? _c(
                                                    "strong",
                                                    { staticClass: "ico-g" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PF.text.Recycled"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-patterns",
                                attrs: { type: "button" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PF.button.patterns")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "i-chk type02" },
                                _vm._l(_vm.leftpattern, function (leftpattern) {
                                  return _c("li", [
                                    _c(
                                      "label",
                                      { staticClass: "inp-chk chk-thumb" },
                                      [
                                        _c("input", {
                                          staticClass: "pattern",
                                          attrs: {
                                            type: "checkbox",
                                            id:
                                              "pattern_" + leftpattern.id.value,
                                          },
                                          on: {
                                            change: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "pattern",
                                                  leftpattern.text,
                                                  leftpattern.id.value,
                                                  "Pattern"
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _vm._v("> "),
                                        _c("span", [
                                          _c("i", {
                                            style:
                                              "background-image : url(images/products/" +
                                              leftpattern.id.value +
                                              ".png)",
                                          }),
                                          _vm._v(_vm._s(leftpattern.text)),
                                        ]),
                                      ]
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                        _vm.nationCode == "_GL"
                          ? _c("li", [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PF.text.country"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-country",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.button.country")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(
                                      _vm.leftCountry,
                                      function (leftCountry) {
                                        return _c("li", [
                                          _c(
                                            "label",
                                            { staticClass: "inp-chk" },
                                            [
                                              _c("input", {
                                                staticClass: "country",
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "country_" +
                                                    leftCountry.id.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "country",
                                                        leftCountry.text,
                                                        leftCountry.id.value,
                                                        "Country"
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(leftCountry.text)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("li", { staticClass: "on" }, [
                          _c("div", { staticClass: "tit" }, [
                            _c("span", [_vm._v(_vm._s(_vm.$t("PF.text.new")))]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-view btn-new",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(_vm.$t("PF.button.new")))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "i-box type02 cont",
                              staticStyle: { display: "block" },
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c("label", { staticClass: "inp-chk" }, [
                                    _c("input", {
                                      staticClass: "new",
                                      attrs: {
                                        type: "checkbox",
                                        id: "isNew_true",
                                      },
                                      on: {
                                        click: function ($event) {
                                          ;[
                                            _vm.fnSearch(
                                              "isNew",
                                              "NEW COLORS",
                                              "true",
                                              "New"
                                            ),
                                          ]
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("PF.text.new_colors"))
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                          ? _c("li", [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PF.text.Use"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-country",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("PF.text.Use")) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(_vm.leftUse, function (leftUse) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "USE",
                                              attrs: {
                                                type: "checkbox",
                                                id: "USE_" + leftUse.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "USE",
                                                      leftUse.text,
                                                      leftUse.id.value,
                                                      "USE"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(leftUse.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                          ? _c("li", [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PF.text.Recycled"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-country",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.text.Recycled")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(
                                      _vm.leftRecycled,
                                      function (leftRecycled) {
                                        return _c("li", [
                                          _c(
                                            "label",
                                            { staticClass: "inp-chk" },
                                            [
                                              _c("input", {
                                                staticClass: "recycled",
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "recycled_" +
                                                    leftRecycled.id.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "recycled",
                                                        leftRecycled.text,
                                                        leftRecycled.id.value,
                                                        "Recycled"
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(leftRecycled.text)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                          ? _c("li", [
                              _c("div", { staticClass: "tit" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("PF.text.Thickness"))),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-view btn-country",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PF.text.Thickness")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "i-box type02 cont",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "i-chk" },
                                    _vm._l(
                                      _vm.leftThickness,
                                      function (leftThickness) {
                                        return _c("li", [
                                          _c(
                                            "label",
                                            { staticClass: "inp-chk" },
                                            [
                                              _c("input", {
                                                staticClass: "thickness",
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "thickness_" +
                                                    leftThickness.id.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.fnSearch(
                                                        "thickness",
                                                        leftThickness.text,
                                                        leftThickness.id.value,
                                                        "Thickness"
                                                      ),
                                                    ]
                                                  },
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(leftThickness.text)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("button", {
                    staticClass: "filter-clos",
                    on: {
                      click: function ($event) {
                        return _vm.clickFilter()
                      },
                    },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "prod-list-box" }, [
              _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab on",
                      attrs: { id: "btn-tab01", "data-tab": "tab01" },
                    },
                    [
                      _c("button", { attrs: { type: "button" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("PF.button.all_products")) +
                            " (" +
                            _vm._s(_vm.contentLength) +
                            ") "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab",
                      attrs: { id: "btn-tab02", "data-tab": "tab02" },
                    },
                    [
                      _c("button", { attrs: { type: "button" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("PF.button.grouped_by_collections")) +
                            " (" +
                            _vm._s(_vm.collectionLength) +
                            ") "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "fd-view-mb search-area" }, [
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    attrs: {
                      id: "textSearchM",
                      type: "text",
                      placeholder: this.$t("PF.text.searchText"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.textSearch($event)
                      },
                    },
                  }),
                  _c("i", {
                    staticClass: "icon-search gray",
                    attrs: { id: "searchBtnM" },
                    on: {
                      click: function ($event) {
                        return _vm.textSearchDel()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "tab-ui inner" }, [
                _c("div", { staticClass: "filter-arae mb-only" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openSearchPopup("layer-search-box")
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("PF.button.filter_by"))),
                      _c("i"),
                    ]
                  ),
                ]),
                _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                  ? _c("div", { staticClass: "sort-box" }, [
                      _c("div", { staticClass: "sel-box" }, [
                        _c(
                          "button",
                          { staticClass: "btn-sel", attrs: { type: "button" } },
                          [_c("span", [_vm._v(_vm._s(_vm.sortBy))])]
                        ),
                        _c("div", { staticClass: "sel-list" }, [
                          _c("ul", { staticClass: "box" }, [
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "Products A to Z",
                                        "asc",
                                        "title"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("PF.button.products_a_to_z")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "Products Z to A",
                                        "desc",
                                        "title"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("PF.button.products_z_to_a")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "New Products first",
                                        "desc"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("PF.button.new_products_first")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                  ? _c("div", { staticClass: "sort-box" }, [
                      _c("div", { staticClass: "sel-box" }, [
                        _c(
                          "button",
                          { staticClass: "btn-sel", attrs: { type: "button" } },
                          [_c("span", [_vm._v(_vm._s(_vm.sortBy))])]
                        ),
                        _c("div", { staticClass: "sel-list" }, [
                          _c("ul", { staticClass: "box" }, [
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "Popularity",
                                        "desc",
                                        "clicks"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.button.Popularity")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "New Products first",
                                        "desc"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("PF.button.new_products_first")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "Products A to Z",
                                        "asc",
                                        "title"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("PF.button.products_a_to_z")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortByChange(
                                        "Products Z to A",
                                        "desc",
                                        "title"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("PF.button.products_z_to_a")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "more-box type03 comprae-box" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              attrs: { type: "button", id: "compare" },
                              on: {
                                click: function ($event) {
                                  return _vm.getCompareList()
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("PF.button.compare_your_colors")
                                  ) +
                                    " (" +
                                    _vm._s(_vm.compareArr.length) +
                                    ")"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.colorList.length > 0
                  ? _c("div", { staticClass: "select-view" }, [
                      _c(
                        "ul",
                        { staticClass: "list-filter-key" },
                        [
                          _vm._l(_vm.colorList, function (color, index) {
                            return _c(
                              "li",
                              {
                                staticClass: "sel",
                                on: {
                                  click: function ($event) {
                                    ;[
                                      _vm.colorDelete(
                                        color.subject,
                                        color.name,
                                        color.id
                                      ),
                                    ]
                                  },
                                },
                              },
                              [
                                color.subject == "Collection"
                                  ? _c("span", [_vm._v(_vm._s(color.subject))])
                                  : _c("span", [_vm._v(_vm._s(color.type))]),
                                _vm._v(" : "),
                                _c("span", [
                                  _vm._v(" " + _vm._s(color.name) + " "),
                                ]),
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v("✕"),
                                ]),
                              ]
                            )
                          }),
                          _c("li", { staticClass: "sel del_all" }, [
                            _vm.colorList.length > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-all-delete",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearAll()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("PF.button.clear_all")) +
                                          " "
                                      ),
                                      _c("em", [_vm._v("✕")]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "tab-con tab01 on" }, [
                  _c("div", { staticClass: "list-type-view row-04" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.contentList, function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "himacs-pfd" },
                          [
                            _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "products/himacs-solid-surface/" +
                                        _vm.$utils.getSeoName(item, "content") +
                                        "/" +
                                        item.content.id,
                                      "data-ec-product":
                                        _vm.makeDataLayerForm(item),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "img-box over" }, [
                                      item.attribute.isNew === "true"
                                        ? _c("span", { staticClass: "new" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.text.new"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.label != "" &&
                                      item.attribute.label != undefined &&
                                      item.attribute.label != null
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "new",
                                              staticStyle: {
                                                "background-color": "#595555",
                                                "border-color": "#595555",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.attribute.label)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("img", {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            item.attribute.pf_thumb,
                                          alt: item.attribute.pf_thumb,
                                          "data-ec-product":
                                            _vm.makeDataLayerForm(item),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoPD(item)
                                          },
                                        },
                                      }),
                                      _vm.nationCode != "_US" &&
                                      _vm.nationCode != "_GL"
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                "auraBtns aurora-" +
                                                item.attribute.text_color,
                                            },
                                            [
                                              item.attribute.auroa == "true"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-Aura-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("aura")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                item.iconList,
                                                function (icon, index) {
                                                  return index < 2
                                                    ? _c(
                                                        "button",
                                                        {
                                                          class:
                                                            "icon-" +
                                                            icon.id +
                                                            "-shadow",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "sr-only",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(icon.id)
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "products/himacs-solid-surface/" +
                                        _vm.$utils.getSeoName(item, "content") +
                                        "/" +
                                        item.content.id,
                                      "data-ec-product":
                                        _vm.makeDataLayerForm(item),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "img-box over" }, [
                                      item.attribute.isNew === "true"
                                        ? _c("span", { staticClass: "new" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.text.new"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.label != "" &&
                                      item.attribute.label != undefined &&
                                      item.attribute.label != null
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "new",
                                              staticStyle: {
                                                "background-color": "#595555",
                                                "border-color": "#595555",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.attribute.label)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("img", {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            item.attribute.pf_thumb,
                                          alt: item.attribute.pf_thumb,
                                          "data-ec-product":
                                            _vm.makeDataLayerForm(item),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.gotoPD(item)
                                          },
                                        },
                                      }),
                                      _vm.nationCode != "_US" &&
                                      _vm.nationCode != "_GL"
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                "auraBtns aurora-" +
                                                item.attribute.text_color,
                                            },
                                            [
                                              item.attribute.auroa == "true"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-Aura-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("aura")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                item.iconList,
                                                function (icon, index) {
                                                  return index < 2
                                                    ? _c(
                                                        "button",
                                                        {
                                                          class:
                                                            "icon-" +
                                                            icon.id +
                                                            "-shadow",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "sr-only",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(icon.id)
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                            _c("div", { staticClass: "view" }, [
                              _vm.nationCode === "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-cart-shadow",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openSampleOrder(
                                            item.content.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("PCD.text.cart")))]
                                  )
                                : _vm._e(),
                              _vm.authenticated == true &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: { on: item.content.liked },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(item, "HIMACS")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.authenticated == false &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      attrs: { href: "login/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loginalertLike()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.getSessionContent != null &&
                              _vm.nationCode != "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      class: {
                                        on: _vm.getSessionContent.includes(
                                          item.content.id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(item, "HIMACS")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm.getSessionContent == null &&
                                  _vm.nationCode != "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-wish-shadow",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(item, "HIMACS")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "prod-info-ico" }, [
                                item.attribute.exclusive === "true"
                                  ? _c("p", { staticClass: "ico-b" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("PCD.text.exclusive")) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "info02" }, [
                              _c("ul", [
                                item.orgContent != null &&
                                _vm.nationCode != "_US" &&
                                _vm.nationCode != "_GL"
                                  ? _c(
                                      "li",
                                      { staticClass: "txt-02" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                item.orgContent.modelId +
                                                "/" +
                                                item.orgContent.title +
                                                "/" +
                                                item.content.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.content.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : item.orgContent == null &&
                                    _vm.nationCode != "_US" &&
                                    _vm.nationCode != "_GL"
                                  ? _c(
                                      "li",
                                      { staticClass: "txt-02" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                item.content.modelId +
                                                "/" +
                                                item.content.title +
                                                "/" +
                                                item.content.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.content.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.orgContent != null &&
                                (_vm.nationCode == "_US" ||
                                  _vm.nationCode == "_GL")
                                  ? _c(
                                      "li",
                                      { staticClass: "txt-02" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                "himacs-solid-surface" +
                                                "/" +
                                                _vm.$utils.getSeoName(
                                                  item,
                                                  "content"
                                                ) +
                                                "/" +
                                                item.content.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.content.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : item.orgContent == null &&
                                    (_vm.nationCode == "_US" ||
                                      _vm.nationCode == "_GL")
                                  ? _c(
                                      "li",
                                      { staticClass: "txt-02" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                "himacs-solid-surface" +
                                                "/" +
                                                _vm.$utils.getSeoName(
                                                  item,
                                                  "content"
                                                ) +
                                                "/" +
                                                item.content.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.content.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("li", { staticClass: "txt-01" }, [
                                  _vm._v(_vm._s(item.attribute.code)),
                                ]),
                                _vm.nationCode == "_US" ||
                                _vm.nationCode == "_GL"
                                  ? _c("li", { staticClass: "txt-03" }, [
                                      _vm._v(_vm._s(item.parents[0].name)),
                                    ])
                                  : _vm._e(),
                                _vm.nationCode != "_US" &&
                                _vm.nationCode != "_GL"
                                  ? _c("li", { staticClass: "txt-04" }, [
                                      _vm._v(
                                        " " + _vm._s(item.parents[0].name) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.nationCode != "_US" &&
                                _vm.nationCode != "_GL" &&
                                item.attribute.collectionText != null &&
                                item.attribute.collectionText != undefined
                                  ? _c("li", { staticClass: "txt-04" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.attribute.collectionText
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.nationCode != "_US" &&
                                _vm.nationCode != "_GL" &&
                                item.attribute.collection1Text != null &&
                                item.attribute.collection1Text != undefined
                                  ? _c("li", { staticClass: "txt-04" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.attribute.collection1Text
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                                ? _c(
                                    "label",
                                    { staticClass: "inp-chk btn-compare" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.compareArr,
                                            expression: "compareArr",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: item.content.id,
                                        },
                                        domProps: {
                                          value: item.content.id,
                                          checked: Array.isArray(_vm.compareArr)
                                            ? _vm._i(
                                                _vm.compareArr,
                                                item.content.id
                                              ) > -1
                                            : _vm.compareArr,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = _vm.compareArr,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = item.content.id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.compareArr =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.compareArr = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.compareArr = $$c
                                              }
                                            },
                                            function ($event) {
                                              return _vm.addCompare(
                                                item.content.id
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("PF.button.compare"))
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm.contentLength > 16 && _vm.pageIndex == 1
                    ? _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-arr-down",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.loadMore(_vm.pageIndex + 1)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.button.see_more"))),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.contentLength > (_vm.pageIndex / 16) * 16 * 16 &&
                  _vm.pageIndex != 1
                    ? _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-arr-down",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.loadMore(_vm.pageIndex + 1)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("PF.button.see_more"))),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "tab-con tab02" },
                  _vm._l(_vm.checkCollectionList, function (coll, index) {
                    return coll.category.hidden === false
                      ? _c(
                          "div",
                          { key: index, staticClass: "list-type-col" },
                          [
                            _c("ul", [
                              _c("li", { staticClass: "col-02" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$imagePath +
                                        coll.categoryAttribute.image,
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "txt-box bd-0" }, [
                                  _c("p", { staticClass: "tt-3 mb-20" }, [
                                    _vm._v(
                                      _vm._s(coll.category.name) +
                                        " " +
                                        _vm._s(_vm.$t("PCD.text.collection"))
                                    ),
                                  ]),
                                  _c("div", { staticClass: "scroll" }, [
                                    _c("div", { staticClass: "t-type-01" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(coll.categoryAttribute.Des) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "more-box type02-02" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-second",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fnCateSearch(
                                                coll.category.name,
                                                coll.category.id,
                                                "click"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "PF.button.see_all_colors"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      (_vm.nationCode != "_US" &&
                                        _vm.nationCode != "_GL" &&
                                        coll.categoryAttribute.bro_english !=
                                          null &&
                                        coll.categoryAttribute.bro_english !=
                                          undefined &&
                                        coll.categoryAttribute.bro_english !=
                                          "") ||
                                      (coll.categoryAttribute.bro_german !=
                                        null &&
                                        coll.categoryAttribute.bro_german !=
                                          undefined &&
                                        coll.categoryAttribute.bro_german !=
                                          "") ||
                                      (coll.categoryAttribute.bro_french !=
                                        null &&
                                        coll.categoryAttribute.bro_french !=
                                          undefined &&
                                        coll.categoryAttribute.bro_french !=
                                          "") ||
                                      (coll.categoryAttribute.bro_italian !=
                                        null &&
                                        coll.categoryAttribute.bro_italian !=
                                          undefined &&
                                        coll.categoryAttribute.bro_italian !=
                                          "") ||
                                      (coll.categoryAttribute.bro_spanish !=
                                        null &&
                                        coll.categoryAttribute.bro_spanish !=
                                          undefined &&
                                        coll.categoryAttribute.bro_spanish !=
                                          "") ||
                                      (coll.categoryAttribute.bro_dutch !=
                                        null &&
                                        coll.categoryAttribute.bro_dutch !=
                                          undefined &&
                                        coll.categoryAttribute.bro_dutch !=
                                          "") ||
                                      (coll.categoryAttribute.bro_uk != null &&
                                        coll.categoryAttribute.bro_uk !=
                                          undefined &&
                                        coll.categoryAttribute.bro_uk != "")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sel-box brochure-sel",
                                            },
                                            [
                                              _vm._m(0, true),
                                              _c(
                                                "div",
                                                { staticClass: "sel-list" },
                                                [
                                                  _vm.nationCode != "_US" &&
                                                  _vm.nationCode != "_GL" &&
                                                  _vm.nationCode != "_UK"
                                                    ? _c(
                                                        "ul",
                                                        { staticClass: "box" },
                                                        [
                                                          coll.categoryAttribute
                                                            .bro_english !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_english !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_english != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_english
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.English"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_german !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_german !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_german != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_german
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.German"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_french !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_french !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_french != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_french
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.French"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_italian !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_italian !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_italian != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_italian
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.Italian"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_spanish !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_spanish !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_spanish != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_spanish
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.Spanish"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_dutch !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_dutch !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_dutch != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_dutch
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.Dutch"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_uk != null &&
                                                          coll.categoryAttribute
                                                            .bro_uk !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_uk != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_uk
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.EnglishUK"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.nationCode == "_UK"
                                                    ? _c(
                                                        "ul",
                                                        { staticClass: "box" },
                                                        [
                                                          coll.categoryAttribute
                                                            .bro_uk != null &&
                                                          coll.categoryAttribute
                                                            .bro_uk !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_uk != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_uk
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.EnglishUK"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_german !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_german !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_german != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_german
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.German"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_french !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_french !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_french != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_french
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.French"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_italian !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_italian !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_italian != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_italian
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.Italian"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_spanish !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_spanish !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_spanish != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_spanish
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.Spanish"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_dutch !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_dutch !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_dutch != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_dutch
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.Dutch"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          coll.categoryAttribute
                                                            .bro_english !=
                                                            null &&
                                                          coll.categoryAttribute
                                                            .bro_english !=
                                                            undefined &&
                                                          coll.categoryAttribute
                                                            .bro_english != ""
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.gotolink(
                                                                            coll
                                                                              .categoryAttribute
                                                                              .bro_english
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "PCD.button.English"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "short-link type02 mb-88 inner" }, [
                _vm.nationCode === "_US"
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openCart()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "ico-01" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.order_a_sample"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.nationCode === "_GL"
                  ? _c("a", { attrs: { href: "support/contactus" } }, [
                      _c("span", { staticClass: "ico-07 ctt ctt-t3" }, [
                        _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                  ? _c("a", { attrs: { href: "support/contactus" } }, [
                      _c("span", { staticClass: "ico-07 ctt ctt-t3" }, [
                        _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                      ]),
                    ])
                  : _vm._e(),
                _c("a", { attrs: { href: "pros/documentation/himacs" } }, [
                  _c("span", { staticClass: "ico-02" }, [
                    _vm._v(_vm._s(_vm.$t("PCD.text.documentation"))),
                  ]),
                ]),
                _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                  ? _c(
                      "a",
                      {
                        attrs: { href: "support/care-and-maintenance/himacs" },
                      },
                      [
                        _c("span", { staticClass: "ico-03" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.care_maintenance"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.nationCode === "_US"
                  ? _c("a", { attrs: { href: "support/warranty/himacs" } }, [
                      _c("span", { staticClass: "ico-04" }, [
                        _vm._v(_vm._s(_vm.$t("PCD.text.warranty"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm.nationCode === "_US"
                  ? _c(
                      "a",
                      { attrs: { href: "support/where-to-buy/country/us" } },
                      [
                        _c("span", { staticClass: "ico-05" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.where_to_buy"))),
                        ]),
                      ]
                    )
                  : _vm.nationCode === "_GL"
                  ? _c(
                      "a",
                      { attrs: { href: "support/where-to-buy/country/gl" } },
                      [
                        _c("span", { staticClass: "ico-05" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.where_to_buy"))),
                        ]),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        attrs: {
                          href: "support/sales-representative/continenteu/himacs",
                        },
                      },
                      [
                        _c("span", { staticClass: "ico-19" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.button.find_a_sales_rep"))),
                        ]),
                      ]
                    ),
                _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                  ? _c(
                      "a",
                      { attrs: { href: "inspiration/virtual-showroom" } },
                      [
                        _c("span", { staticClass: "ico-06" }, [
                          _vm._v(_vm._s(_vm.$t("PD.button.virtual_Showroom"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "layer-popup layer-compare euSite",
              attrs: { id: "pdf_wrap" },
            },
            [
              _c("div", { staticClass: "popup pop-close" }, [
                _c("div", { staticClass: "pop-compare scr-y" }, [
                  _c("p", { staticClass: "tt-sub-gray" }, [
                    _vm._v(_vm._s(_vm.$t("PF.popup.compare_himacs_products"))),
                  ]),
                  _c("p", { staticClass: "ta-c" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("PF.popup.characteristics_technical_performance")
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "prod-list tbl-compare" }, [
                    _c("table", [
                      _c("caption", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "PF.popup.characteristics_technical_performance"
                            )
                          )
                        ),
                      ]),
                      _vm._m(1),
                      _c("tbody", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.compareList, function (item, idx) {
                              return _c("td", [
                                _c("div", { staticClass: "prod-box" }, [
                                  _c("div", { staticClass: "prod" }, [
                                    _c("div", { staticClass: "img-box over" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$imagePath +
                                            item.attribute.pf_thumb,
                                          alt: item.content.title,
                                        },
                                      }),
                                      _vm.nationCode != "_GL" &&
                                      _vm.nationCode != "_US"
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                "auraBtns aurora" +
                                                item.attribute.text_color,
                                            },
                                            [
                                              item.attribute.auroa == "true"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-aurora ctp-aura",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("aura")]
                                                      ),
                                                    ]
                                                  )
                                                : item.attribute.lucent ==
                                                  "true"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-lucent-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("lucent")]
                                                      ),
                                                    ]
                                                  )
                                                : item.parents[0].name.includes(
                                                    "Exteria"
                                                  )
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-exterior-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("exterior")]
                                                      ),
                                                    ]
                                                  )
                                                : item.attribute.veined ==
                                                  "true"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-veined-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("veined")]
                                                      ),
                                                    ]
                                                  )
                                                : item.attribute
                                                    .semiTranslucent == "true"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-translucent-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("translucent")]
                                                      ),
                                                    ]
                                                  )
                                                : item.attribute.recycled !=
                                                    null &&
                                                  item.attribute.recycled !=
                                                    undefined &&
                                                  item.attribute.recycled
                                                    .length != 0 &&
                                                  item.attribute.auroa ==
                                                    "false" &&
                                                  item.attribute.lucent ==
                                                    "false" &&
                                                  !item.parents[0].name.includes(
                                                    "Exteria"
                                                  ) &&
                                                  item.attribute.veined ==
                                                    "false" &&
                                                  item.attribute
                                                    .semiTranslucent == "false"
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-recycled-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("recycled")]
                                                      ),
                                                    ]
                                                  )
                                                : item.attribute.notice ==
                                                    "true" &&
                                                  item.attribute.auroa ==
                                                    "false" &&
                                                  item.attribute.lucent ==
                                                    "false" &&
                                                  !item.parents[0].name.includes(
                                                    "Exteria"
                                                  ) &&
                                                  item.attribute.veined ==
                                                    "false" &&
                                                  item.attribute
                                                    .semiTranslucent ==
                                                    "false" &&
                                                  (item.attribute.recycled ==
                                                    null ||
                                                    item.attribute.recycled ==
                                                      undefined ||
                                                    item.attribute.recycled
                                                      .length == 0)
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "icon-notice-shadow",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "sr-only",
                                                        },
                                                        [_vm._v("notice")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "view" }, [
                                      _vm.getSessionContent != null &&
                                      _vm.nationCode != "_US"
                                        ? _c("a", {
                                            staticClass: "icon-wish-shadow",
                                            class: {
                                              on: _vm.getSessionContent.includes(
                                                item.content.id
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleLike(
                                                  item,
                                                  "HIMACS"
                                                )
                                              },
                                            },
                                          })
                                        : _vm.getSessionContent == null &&
                                          _vm.nationCode != "_US"
                                        ? _c("a", {
                                            staticClass: "icon-wish-shadow",
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleLike(
                                                  item,
                                                  "HIMACS"
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "popinfo" }, [
                                    _c("ul", [
                                      _c("li", { staticClass: "tt-sub" }, [
                                        _vm._v(
                                          " " + _vm._s(item.content.title) + " "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "ctt ctt-t4" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.attribute.code) +
                                            " "
                                        ),
                                      ]),
                                      _c("li", { staticClass: "txt-01" }, [
                                        _vm._v(_vm._s(item.parents[0].name)),
                                      ]),
                                      item.attribute.collectionText != null &&
                                      item.attribute.collectionText != undefined
                                        ? _c("li", { staticClass: "txt-01" }, [
                                            _vm._v(
                                              _vm._s(
                                                item.attribute.collectionText
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-remove02",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCompare(
                                              item.content.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "remove-char" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("PF.button.remove"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "tbl-box type02" }, [
                                  _c("table", [
                                    _c("caption", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "PF.popup.characteristics_technical_performance"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._m(2, true),
                                    _c("tbody", [
                                      item.attribute.thicknessText != "" &&
                                      item.attribute.thicknessText !=
                                        undefined &&
                                      item.attribute.thicknessText != null
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PD.text.Thickness")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.thicknessText != "" &&
                                      item.attribute.thicknessText !=
                                        undefined &&
                                      item.attribute.thicknessText != null
                                        ? _c("tr", [
                                            item.attribute.thicknessText
                                              .length > 1
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute.thicknessText.join(
                                                        ", "
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute
                                                        .thicknessText[0]
                                                    )
                                                  ),
                                                ]),
                                          ])
                                        : _vm._e(),
                                      item.attribute.USEText != "" &&
                                      item.attribute.USEText != undefined &&
                                      item.attribute.USEText != null
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("PD.text.Use"))
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.USEText != "" &&
                                      item.attribute.USEText != undefined &&
                                      item.attribute.USEText != null
                                        ? _c("tr", [
                                            item.attribute.USEText.length > 1
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute.USEText.join(
                                                        ", "
                                                      )
                                                        .replace(
                                                          "Outdoor",
                                                          "Suitable for exterior use. Please check conditions about level of suitability and warranty per colour"
                                                        )
                                                        .replace(
                                                          "Indoor",
                                                          "Indoor"
                                                        )
                                                        .replace(
                                                          "Kitchen and Bathroom",
                                                          "Suitable for Kitchen & Bathroom"
                                                        )
                                                    ) + " "
                                                  ),
                                                ])
                                              : _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute.USEText[0]
                                                    )
                                                  ),
                                                ]),
                                          ])
                                        : _vm._e(),
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "col" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Pattern"))
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(item.attribute.patternText)
                                          ),
                                        ]),
                                      ]),
                                      item.attribute.tonesText != "" &&
                                      item.attribute.tonesText != undefined &&
                                      item.attribute.tonesText != null
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PF.text.Tones")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.tonesText != "" &&
                                      item.attribute.tonesText != undefined &&
                                      item.attribute.tonesText != null
                                        ? _c("tr", [
                                            item.attribute.tonesText.length > 1
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute.tonesText.join(
                                                        ", "
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.attribute
                                                        .tonesText[0]
                                                    )
                                                  ),
                                                ]),
                                          ])
                                        : _vm._e(),
                                      item.attribute.notice == "true"
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PD.text.Notice")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.notice == "true"
                                        ? _c("tr", [
                                            item.attribute.notice == "true"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "tb-more-wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.text.Notice_brief"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      item.attribute.semiTranslucent == "true"
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PD.text.Semi_Translucent"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.semiTranslucent == "true"
                                        ? _c("tr", [
                                            item.attribute.semiTranslucent ==
                                            "true"
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.text.Semi_Translucent_brief"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      item.attribute.recycledText != "" &&
                                      item.attribute.recycledText !=
                                        undefined &&
                                      item.attribute.recycledText != null
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PD.text.Recycled")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.recycledText != "" &&
                                      item.attribute.recycledText !=
                                        undefined &&
                                      item.attribute.recycledText != null
                                        ? _c("tr", [
                                            item.attribute.recycledText != null
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.text.Recycled_brief"
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        item.attribute
                                                          .recycledText
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      item.attribute.marcelWanders == "true"
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PD.text.Designer")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.marcelWanders == "true"
                                        ? _c("tr", [
                                            item.attribute.marcelWanders ==
                                            "true"
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.text.Designer_brief"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      item.attribute.lucent == "true"
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PD.text.Lucent")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.lucent == "true"
                                        ? _c("tr", [
                                            item.attribute.lucent == "true"
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PD.text.Lucent_brief"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      item.attribute.veined == "true"
                                        ? _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("PD.text.Veined")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.attribute.veined == "true"
                                        ? _c("tr", [
                                            item.attribute.veined == "true"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "tb-more-wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.text.Veined_brief"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                              ])
                            }),
                            _vm.compareList.length < 4
                              ? _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "btn-add hf-btn-add" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            onclick: "LayerPopup.close();",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("PF.button.add_more")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "btn-download" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn dwn load",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.makePDF()
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("PF.button.download"))),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(_vm._s(_vm.$t("PCD.button.close")))]
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "layer-popup layer-alert type02" }, [
            _c("div", { staticClass: "popup pop-close" }, [
              _c("div", { staticClass: "pop-alert inner" }, [
                _c("div", { staticClass: "t-wrap" }, [
                  _c("div", { staticClass: "tit-box" }, [
                    _c("p", { staticClass: "ctt ctt-t4 artTit" }, [
                      _vm._v(_vm._s(_vm.$t("PF.popup.your_compare_is_full"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "txt-box" }, [
                    _c("i", { staticClass: "ico-alert" }),
                    _c("p", { staticClass: "tt-2" }, [
                      _vm._v(_vm._s(_vm.$t("PF.popup.compare_tool"))),
                    ]),
                    _c("div", { staticClass: "ctt ctt-t2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("PF.popup.compare_alert1"))),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.$t("PF.popup.compare_alert2")) + " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "more-box" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button", id: "replace" },
                      on: {
                        click: function ($event) {
                          return _vm.replace()
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("PF.popup.replace")))])]
                  ),
                ]),
                _c("div", { staticClass: "alert_txt" }, [
                  _c(
                    "button",
                    {
                      attrs: { id: "button editCompare" },
                      on: {
                        click: function ($event) {
                          return _vm.getCompareList()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("PF.popup.edit_products_in_compare"))
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn-close btn",
                    attrs: { type: "button", onclick: "LayerPopup.close();" },
                  },
                  [_vm._v(_vm._s(_vm.$t("PF.popup.replace")))]
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "search-box layer-view layer-search-box popup-v2 popup-v2-mb",
              staticStyle: { display: "none" },
              attrs: { id: "layer-search-box" },
            },
            [
              _c("div", { staticClass: "popup" }, [
                _c("div", { staticClass: "scr-y" }, [
                  _c("div", { staticClass: "drop-list" }, [
                    _c("p", { staticClass: "tt-2" }, [_vm._v("Filter By")]),
                    _c("button", {
                      staticClass: "filter-clos",
                      on: {
                        click: function ($event) {
                          return _vm.closeSearchPopup()
                        },
                      },
                    }),
                    _c("ul", [
                      _c("li", { staticClass: "on" }, [
                        _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                          ? _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-color",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.button.color")) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                          ? _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Tones"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-color",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("PF.text.Tones")) + " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.nationCode == "_GL" || _vm.nationCode == "_US"
                          ? _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk type03" },
                                  _vm._l(_vm.lefthue, function (lefthue) {
                                    return _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "inp-chk chk-color" },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              name: "color",
                                              id: "hue_" + lefthue.id.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                ;[
                                                  _vm.fnSearch(
                                                    "hue",
                                                    lefthue.text,
                                                    lefthue.id.value,
                                                    "Color"
                                                  ),
                                                ]
                                              },
                                            },
                                          }),
                                          lefthue.text === "Other"
                                            ? _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-image : url(images/products/" +
                                                    lefthue.id.value +
                                                    ".png)",
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ])
                                            : _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-color:#" +
                                                    lefthue.id.value,
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ]),
                                          _c("span", [
                                            _vm._v(_vm._s(lefthue.text)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.nationCode != "_GL" && _vm.nationCode != "_US"
                          ? _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk type03" },
                                  _vm._l(_vm.lefthue, function (lefthue) {
                                    return _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "inp-chk chk-color" },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              name: "color",
                                              id: "hue_" + lefthue.id.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                ;[
                                                  _vm.fnSearch(
                                                    "hue",
                                                    lefthue.text,
                                                    lefthue.id.value,
                                                    "Tone"
                                                  ),
                                                ]
                                              },
                                            },
                                          }),
                                          lefthue.text === "Other"
                                            ? _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-image : url(images/products/" +
                                                    lefthue.id.value +
                                                    ".png)",
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ])
                                            : _c("i", [
                                                _c("span", {
                                                  style:
                                                    "background-color:#" +
                                                    lefthue.id.value,
                                                }),
                                                _vm._v(_vm._s(lefthue.text)),
                                              ]),
                                          _c("span", [
                                            _vm._v(_vm._s(lefthue.text)),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("PCD.text.collection"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-collection",
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("PF.button.collection")) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.leftcollection, function (coll) {
                                return _c("li", [
                                  coll.category.hidden === false
                                    ? _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "Collection",
                                          attrs: {
                                            type: "checkbox",
                                            id: coll.category.id,
                                          },
                                          on: {
                                            change: function ($event) {
                                              ;[
                                                _vm.fnCateSearch(
                                                  coll.category.name,
                                                  coll.category.id
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(coll.category.name)),
                                          coll.category.name === "Eden Plus"
                                            ? _c(
                                                "strong",
                                                { staticClass: "ico-g" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("PF.text.Recycled")
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("PF.text.patterns"))),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-patterns",
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PF.button.patterns")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "i-chk type02" },
                              _vm._l(_vm.leftpattern, function (leftpattern) {
                                return _c("li", [
                                  _c(
                                    "label",
                                    { staticClass: "inp-chk chk-thumb" },
                                    [
                                      _c("input", {
                                        staticClass: "pattern",
                                        attrs: {
                                          type: "checkbox",
                                          id: "pattern_" + leftpattern.id.value,
                                        },
                                        on: {
                                          change: function ($event) {
                                            ;[
                                              _vm.fnSearch(
                                                "pattern",
                                                leftpattern.text,
                                                leftpattern.id.value,
                                                "Pattern"
                                              ),
                                            ]
                                          },
                                        },
                                      }),
                                      _vm._v("> "),
                                      _c("span", [
                                        _c("i", {
                                          style:
                                            "background-image : url(images/products/" +
                                            leftpattern.id.value +
                                            ".png)",
                                        }),
                                        _vm._v(_vm._s(leftpattern.text)),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm.nationCode == "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.country"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.button.country")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(
                                    _vm.leftCountry,
                                    function (leftCountry) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "country",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "country_" +
                                                  leftCountry.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "country",
                                                      leftCountry.text,
                                                      leftCountry.id.value,
                                                      "Country"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(leftCountry.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("li", { staticClass: "on" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("span", [_vm._v(_vm._s(_vm.$t("PF.text.new")))]),
                          _c(
                            "button",
                            {
                              staticClass: "btn-view btn-new",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(_vm.$t("PF.button.new")))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "i-box type02 cont",
                            staticStyle: { display: "block" },
                          },
                          [
                            _c("ul", [
                              _c("li", [
                                _c("label", { staticClass: "inp-chk" }, [
                                  _c("input", {
                                    staticClass: "new",
                                    attrs: {
                                      type: "checkbox",
                                      id: "isNew_true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        ;[
                                          _vm.fnSearch(
                                            "isNew",
                                            "NEW COLORS",
                                            "true",
                                            "New"
                                          ),
                                        ]
                                      },
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PF.text.new_colors"))
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Use"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("PF.text.Use")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(_vm.leftUse, function (leftUse) {
                                    return _c("li", [
                                      _c("label", { staticClass: "inp-chk" }, [
                                        _c("input", {
                                          staticClass: "USE",
                                          attrs: {
                                            type: "checkbox",
                                            id: "USE_" + leftUse.id.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;[
                                                _vm.fnSearch(
                                                  "USE",
                                                  leftUse.text,
                                                  leftUse.id.value,
                                                  "USE"
                                                ),
                                              ]
                                            },
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(leftUse.text)),
                                        ]),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Recycled"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.text.Recycled")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(
                                    _vm.leftRecycled,
                                    function (leftRecycled) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "recycled",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "recycled_" +
                                                  leftRecycled.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "recycled",
                                                      leftRecycled.text,
                                                      leftRecycled.id.value,
                                                      "Recycled"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(leftRecycled.text)),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("li", [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("PF.text.Thickness"))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-view btn-country",
                                  attrs: { type: "button" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PF.text.Thickness")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "i-box type02 cont",
                                staticStyle: { display: "block" },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "i-chk" },
                                  _vm._l(
                                    _vm.leftThickness,
                                    function (leftThickness) {
                                      return _c("li", [
                                        _c(
                                          "label",
                                          { staticClass: "inp-chk" },
                                          [
                                            _c("input", {
                                              staticClass: "thickness",
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "thickness_" +
                                                  leftThickness.id.value,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  ;[
                                                    _vm.fnSearch(
                                                      "thickness",
                                                      leftThickness.text,
                                                      leftThickness.id.value,
                                                      "Thickness"
                                                    ),
                                                  ]
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(leftThickness.text)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-line btn-sel btn-arr-down-bk",
        attrs: { type: "button" },
      },
      [_c("span", [_vm._v("Brochure")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }