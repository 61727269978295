<template>
  <OverviewLayout
    imageSrc="images/aboutus/overview/business_area/top_image.png"
    title="Business Area"
    v-if="nationCode === '_US' || nationCode === '_GL'"
  >
    <template #content>
      <CustomerOrientedProducts />
      <BuildingDecorativeMaterials />
      <IndustrialFilm />
      <Automotive />
    </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/business_area/top_image.png"
    title="事業領域"
    v-else-if="nationCode === '_JP'"
  >
    <template #content>
      <CustomerOrientedProducts />
      <BuildingDecorativeMaterials />
      <IndustrialFilm />
      <Automotive />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/business_area/top_image.png"
    title="Сфера деятельности"
    v-else-if="nationCode === '_RU'"
  >
    <template #content>
      <CustomerOrientedProducts />
      <BuildingDecorativeMaterials />
      <IndustrialFilm />
      <Automotive />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/overview/business_area/top_image.png"
    title="事业领域"
    v-else-if="nationCode === '_CN'"
  >
    <template #content>
      <CustomerOrientedProducts />
      <BuildingDecorativeMaterials />
      <IndustrialFilm />
      <Automotive />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../components/OverviewLayout.vue'
import CustomerOrientedProducts from './components/CustomerOrientedProducts.vue'
import BuildingDecorativeMaterials from './components/BuildingDecorativeMaterials.vue'
import IndustrialFilm from './components/IndustrialFilm.vue'
import Automotive from './components/Automotive.vue'

export default {
  components: {
    OverviewLayout,
    CustomerOrientedProducts,
    BuildingDecorativeMaterials,
    IndustrialFilm,
    Automotive
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
</style>
