
export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const SET_CART_ITEM = 'SET_CART_ITEM'
export const CLEAR_CART_ITEM = 'CLEAR_CART_ITEM'
export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM'
export const SET_ORDER_ITEM = 'SET_ORDER_ITEM'
export const CLEAR_ORDER_ITEM = 'CLEAR_ORDER_ITEM'
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO'
export const CLEAR_MEMBER_INFO = 'CLEAR_MEMBER_INFO'

export default {
  ADD_CART_ITEM,
  SET_CART_ITEM,
  CLEAR_CART_ITEM,
  ADD_ORDER_ITEM,
  SET_ORDER_ITEM,
  CLEAR_ORDER_ITEM,
  SET_MEMBER_INFO,
  CLEAR_MEMBER_INFO
}
