
<template>
  <!-- tab-con Products -->
  <div class="tab-con on" v-if="tabCount > 0">
    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt"><span>VIATERA (<em>{{viatera.totalCount}}</em>)</span></div>

      <div class="list-type-view row-04">
        <ul v-if="viatera && viatera.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in viatera.list">
            <div class="over">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'viatera')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">VIATERA</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>

            <div class="info">
              <ul>
                <li class="txt-01">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'viatera')">{{ sr.content.title }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="mb-64 ta-c" v-if="!viatera.lastPage">
        <button type="button" class="btn btn-arr-down" @click="loadMoreViatera()"><span>{{ $t('see_more')}}</span></button>
      </div>
    </div>

    <!-- HIMACS -->
    <div class="sch-res-prod prod-fd-view">
      <div class="res-prod-cnt"><span>HIMACS (<em>{{himacs.totalCount}}</em>)</span></div>

      <div class="list-type-view row-04">
        <ul v-if="himacs && himacs.totalCount > 0">
          <li class="search-product-cont-box" v-for="sr in himacs.list">
            <div class="over">
              <div class="img-box">
                <a href="javascript:void(0);" @click="gotoDetail(sr, 'HIMACS')"><img :src="$imagePath + sr.attribute.pf_thumb" :alt="sr.content.title"></a>
                <div class="over-cg">
                  <p class="ico-cg">HIMACS</p>
                </div>
              </div>
            </div>
            <i class="badge badge-new" v-if="sr.attribute.isNew === true">
              <span>NEW</span>
            </i>
            <div class="info">
              <ul>
                <li class="txt-01">{{ sr.content.title }}</li>
                <li class="txt-02">
                  <a href="javascript:void(0)" @click="gotoDetail(sr, 'HIMACS')">{{ sr.attribute.code }}</a>
                </li>
                <li class="txt-03">{{ sr.parents[0].name }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="mb-64 ta-c" v-if="!himacs.lastPage">
        <button type="button" class="btn btn-arr-down" @click="loadMoreHimacs()"><span>{{ $t('see_more')}}</span></button>
      </div>
    </div>

  </div>
  <div class="no-found-wrap bd-top" v-else>
    <div class="tac">
      <i class="icon-no-result"><img src="images/icon-no-result-found.png" alt="no result"></i>
      <div class="msg">
        <ul>
          <li class="ctt ctt-t1 result-tit">No Result Found</li>
          <li class="ctt ctt-t4">Try again using more general search keywords</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'
import ProductService from '@/server/product.service.js'

import { mapState } from 'vuex'
import _ from '@/common/helper/lodash'

export default {
  name: 'SearchProductHomedepot',
  props: ['searchResult', 'searchWord'],
  watch: {
    searchResult () {
      this.initSearchResult()
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  data () {
    return {
      seoCheck: false,
      nationCode: '',
      tabCount: 0,
      himacs: { totalCount: 0 },
      viatera: { totalCount: 0 },
      getSessionContent: [] /* GL : 불러오는 값 */,
      content: '',
      contentContent: '',
      contentAttribute: '',
      popUpliked: '',
      contentFile: [],
      popUpCateName: ''
    }
  },
  created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      t.seoCheck = true
    }
    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getSessionContent.push(o.id)
        }
      })
    }
  },
  async mounted () {
    this.initSearchResult()
  },
  methods: {
    popupImage (index) {
      $("[name*='popImage']").fadeOut(300)
      $('#popimage_0' + index).fadeIn(300)
    },
    async download (file) {
      const url = this.$imagePath + file
      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }
      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', url)
        document.body.appendChild(element)
        element.click()
      })
    },
    async popUpView (content) { /* 콘텐츠 상세보기 팝업 */
      await ProductService.getClickUp(content.content.id)
      const _this = this
      _this.content = content
      _this.contentContent = content.content
      _this.contentAttribute = content.attribute
      _this.popUpCateName = content.parents[0].name
      _this.popUpliked = content.content.liked
      if (_this.nationCode == '_US') {
        $("[name*='popImage']").fadeOut(300)
        $('#popimage_01').fadeIn(300)

        // LayerPopupGal.open('hi-op-pop2');
        LayerPopupGal.open('us-layer-other')
      } else {
        const contentFile = await ProductService.getContentFileList(content.content.id)
        _this.contentFile = contentFile.data.page.list
        $("[name*='popImage']").fadeOut(300)
        $('#popimage_01').fadeIn(300)

        // LayerPopupGal.open('hi-op-pop');
        LayerPopupGal.open('eu-layer-other')
      }
      // _this.$nextTick(function(e){
      //
      //   e.stopPropagation();
      // })
      return false
    },
    async loadMoreViatera () {
      this.loadMore('viateraHome')
    },
    loadMoreHimacs () {
      this.loadMore('himacsHome')
    },
    gotoDetail (sr, type) {
      if (this.seoCheck) {
        if (type === 'viatera' || type === 'VIATERA') {
          document.location.href = 'homecenter/viatera/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        } else if (type === 'HIMACS' || type === 'himacs') {
          document.location.href = 'homecenter/himacs/' + this.$utils.getSeoName(sr, 'content') + '/' + sr.content.id
        }
      } else {
        document.location.href = 'products/' + type + '/' + sr.content.title + '/' + sr.content.id
      }
    },
    async loadMore (subType) {
      const search = this.searchResult[subType]
      const pageIndex = search.pageIndex + 1
      const pageSize = search.pageSize
      const s = this.searchWord
      const r = this.$countryCode.substring(1)

      const { data } = await SearchService.byType({ pageIndex, pageSize, s, r, type: 'product', subType })

      search.lastPage = data.lastPage
      search.pageIndex = data.pageIndex
      search.list.push(...data.list)
    },

    async initSearchResult () {
      this.himacs = this.searchResult.himacsHome
      this.viatera = this.searchResult.viateraHome
      this.tabCount = this.searchResult.himacsHome.totalCount + this.searchResult.viateraHome.totalCount
    }
  }
}

function tmpPopup (popClass) {
  LayerPopupGal.open(popClass)
}
function tmpClose () {
  LayerPopupGal.close()
}
</script>
