/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export default {
  getImgLibCnt (state) {
    return state.imgLibCnt
  },
  getLikeCnt (state) {
    return state.likeCnt
  },
  getLanguageWrapper (state) {
    if (localStorage.getItem('languageWrapper') === null) {
      return true
    }
    return false
  },
  getNeverMissPopup (state) {
    if (sessionStorage.getItem('neverMissPopupClose') === null) {
      return true
    }
    return false
  },
  getImgLibtPopup (state) {
    if (sessionStorage.getItem('imgLibPopupClose') === null) {
      return true
    }
    return false
  }
}
