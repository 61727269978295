
import * as types from './mutation-types'

export const addCartItem = async ({ commit, dispatch, getters }, cartItemList) => {
  await commit(types.ADD_CART_ITEM, cartItemList)
}

export const setCartItem = async ({ commit, dispatch, getters }, cartItemList) => {
  await commit(types.SET_CART_ITEM, cartItemList)
}

export const clearCartItem = async ({ commit, dispatch, getters }, cartItemList) => {
  await commit(types.CLEAR_CART_ITEM, cartItemList)
}

export const addOrderItem = async ({ commit, dispatch, getters }, orderItemList) => {
  await commit(types.ADD_ORDER_ITEM, orderItemList)
}

export const setOrderItem = async ({ commit, dispatch, getters }, orderInfo) => {
  await commit(types.SET_ORDER_ITEM, orderInfo)
}

export const clearOrderItem = async ({ commit, dispatch, getters }) => {
  await commit(types.SET_CART_ITEM, [])
  await commit(types.CLEAR_ORDER_ITEM)
}

export const setMemberInfo = async ({ commit, dispatch, getters }, memberInfo) => {
  await commit(types.SET_MEMBER_INFO, memberInfo)
}

export const clearMemberInfo = async ({ commit, dispatch, getters }) => {
  await commit(types.CLEAR_MEMBER_INFO)
}

export default {
  addCartItem,
  setCartItem,
  clearCartItem,
  addOrderItem,
  setOrderItem,
  clearOrderItem,
  setMemberInfo,
  clearMemberInfo
}
