var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("COLLECTION", {
        attrs: { collectionList: _vm.collectionList },
        model: {
          value: _vm.collection,
          callback: function ($$v) {
            _vm.collection = $$v
          },
          expression: "collection",
        },
      }),
      _c("IS_NEW", {
        model: {
          value: _vm.isNew,
          callback: function ($$v) {
            _vm.isNew = $$v
          },
          expression: "isNew",
        },
      }),
      _c("COLOR", {
        attrs: { colorList: _vm.dictMap["hue"] },
        model: {
          value: _vm.hue,
          callback: function ($$v) {
            _vm.hue = $$v
          },
          expression: "hue",
        },
      }),
      _c("PRD_PATTERN", {
        attrs: { patternList: _vm.dictMap["pattern"] },
        model: {
          value: _vm.pattern,
          callback: function ($$v) {
            _vm.pattern = $$v
          },
          expression: "pattern",
        },
      }),
      _c("WOODSPECIES", {
        attrs: { treespeciesList: _vm.dictMap["tree_species"] },
        model: {
          value: _vm.tree_species,
          callback: function ($$v) {
            _vm.tree_species = $$v
          },
          expression: "tree_species",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }