var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type == "document"
    ? _c("span", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "btn-share icon-share",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c(
          "div",
          { staticClass: "sns sns_share_pop", staticStyle: { width: "80px" } },
          [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "ico-e",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.email(_vm.content, _vm.type)
                      },
                    },
                  },
                  [_vm._v("email")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "ico-v",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.clip(_vm.content, _vm.type)
                      },
                    },
                  },
                  [_vm._v("url")]
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm.type == "warranty"
    ? _c("span", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "btn-share icon-share",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c(
          "div",
          { staticClass: "sns sns_share_pop", staticStyle: { width: "80px" } },
          [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "ico-e",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.email(_vm.content, _vm.type)
                      },
                    },
                  },
                  [_vm._v("email")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "ico-v",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.clip(_vm.content, _vm.type)
                      },
                    },
                  },
                  [_vm._v("url")]
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm.type === "care"
    ? _c("span", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "btn-share icon-share",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c(
          "div",
          { staticClass: "sns sns_share_pop", staticStyle: { width: "80px" } },
          [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "ico-e",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.email(_vm.content, _vm.type)
                      },
                    },
                  },
                  [_vm._v("email")]
                ),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "ico-v",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.clip(_vm.content, _vm.type)
                      },
                    },
                  },
                  [_vm._v("url")]
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm.type === "product"
    ? _c("div", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "icon-share-bk",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c("div", { staticClass: "sns sns_share_pop" }, [
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-f",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.faceBook(_vm.childValue)
                    },
                  },
                },
                [_vm._v("facebook")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-p",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.pinterest(_vm.childValue)
                    },
                  },
                },
                [_vm._v("pinterest")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-l",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.linkedin(_vm.childValue, _vm.type)
                    },
                  },
                },
                [_vm._v("linkedin")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-t",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.shareTwitter(_vm.childValue)
                    },
                  },
                },
                [_vm._v("twitter")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-e",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.email(_vm.childValue, _vm.type)
                    },
                  },
                },
                [_vm._v("email")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-v",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.clip(_vm.childValue)
                    },
                  },
                },
                [_vm._v("url")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm.type == "news"
    ? _c("div", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "icon-share",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c("div", { staticClass: "sns sns_share_pop" }, [
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-f",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.faceBook(_vm.childValue)
                    },
                  },
                },
                [_vm._v("facebook")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-p",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.pinterest(_vm.childValue)
                    },
                  },
                },
                [_vm._v("pinterest")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-l",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.linkedin(_vm.childValue, _vm.type)
                    },
                  },
                },
                [_vm._v("linkedin")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-t",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.shareTwitter(_vm.childValue)
                    },
                  },
                },
                [_vm._v("twitter")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-e",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.email(_vm.childValue, _vm.type)
                    },
                  },
                },
                [_vm._v("email")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-v",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.clip(_vm.childValue)
                    },
                  },
                },
                [_vm._v("url")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm.type == "case" || _vm.type == "gallery"
    ? _c("div", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "icon-share",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c("div", { staticClass: "sns sns_share_pop" }, [
          _c("ul", [
            _c("li", [
              _c("a", {
                staticClass: "ico-f",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.faceBook(_vm.childValue, _vm.type)
                  },
                },
              }),
            ]),
            _c("li", [
              _c("a", {
                staticClass: "ico-p",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.pinterest(_vm.childValue, _vm.type)
                  },
                },
              }),
            ]),
            _c("li", [
              _c("a", {
                staticClass: "ico-l",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.linkedin(_vm.childValue, _vm.type)
                  },
                },
              }),
            ]),
            _c("li", [
              _c("a", {
                staticClass: "ico-t",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.shareTwitter(_vm.childValue, _vm.type)
                  },
                },
              }),
            ]),
            _c("li", [
              _c("a", {
                staticClass: "ico-e",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.email(_vm.childValue, _vm.type)
                  },
                },
              }),
            ]),
            _c("li", [
              _c("a", {
                staticClass: "ico-v",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.clip(_vm.childValue)
                  },
                },
              }),
            ]),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "sns-wrp" }, [
        _c(
          "button",
          {
            staticClass: "btn-share icon-share",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.clickShareSns()
              },
            },
          },
          [_vm._v("sharing")]
        ),
        _c("div", { staticClass: "sns sns_share_pop" }, [
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-f",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.faceBook(_vm.content, _vm.type)
                    },
                  },
                },
                [_vm._v("facebook")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-p",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.pinterest(_vm.content, _vm.type)
                    },
                  },
                },
                [_vm._v("pinterest")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-l",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.linkedin(_vm.content, _vm.type)
                    },
                  },
                },
                [_vm._v("linkedin")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-t",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.shareTwitter(_vm.content)
                    },
                  },
                },
                [_vm._v("twitter")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-e",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.email(_vm.content, _vm.type)
                    },
                  },
                },
                [_vm._v("email")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "ico-v",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.clip(_vm.content)
                    },
                  },
                },
                [_vm._v("url")]
              ),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }