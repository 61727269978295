<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>COUNTRY</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
          <span class="checkbox_more no_line">
            <li v-for="country in countryList" :data-chk="country.id.value">
              <input type="checkbox" :id="'country_'+country.id.value" name="Country" v-model="selected" :value="country.id.value" />
              <label :for="'country_'+`${country.id.value}`">
              <span></span>
              {{ country.id.value }}
              </label>
            </li>
          </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'countryList'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: function () {
      this.$emit('update', this.selected)
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    EventBus.$on('reset-pf-filter', function () { _this.selected = [] })
  },
  methods: {
  }
}
</script>
