var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "Social Contribution Activities",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "social-contribution-activities-card-div" },
              _vm._l(_vm.cardItems, function (item, index) {
                return _c("SocialContributionActivitiesCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    text: item.text,
                    image: item.image,
                    flexDirection: item.flexDirection,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "社会貢献活動",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "social-contribution-activities-card-div" },
              _vm._l(_vm.cardItems_jp, function (item, index) {
                return _c("SocialContributionActivitiesCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    text: item.text,
                    image: item.image,
                    flexDirection: item.flexDirection,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "Социальный вклад",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "social-contribution-activities-card-div" },
              _vm._l(_vm.cardItems_ru, function (item, index) {
                return _c("SocialContributionActivitiesCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    text: item.text,
                    image: item.image,
                    flexDirection: item.flexDirection,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            type: "major",
            title: "社会贡献活动",
            color: "#222",
            size: "56px",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "social-contribution-activities-card-div" },
              _vm._l(_vm.cardItems_cn, function (item, index) {
                return _c("SocialContributionActivitiesCard", {
                  key: index,
                  attrs: {
                    title: item.title,
                    text: item.text,
                    image: item.image,
                    flexDirection: item.flexDirection,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }