var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-cate" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("other.text.home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.himacs")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("other.text.hotherPD")))]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "container prod-dt-view prod-op-view himacs-op container_pf",
          },
          [
            _c("div", { staticClass: "visual visual-detail" }, [
              _vm.bannerList[0].attribute.pc_img
                ? _c(
                    "div",
                    {
                      staticClass: "visual-image full_width center",
                      style: {
                        "background-image":
                          "url( " +
                          _vm.$imagePath +
                          _vm.bannerList[0].attribute.pc_img +
                          ")",
                      },
                    },
                    [
                      _c("div", { staticClass: "inner_width" }, [
                        _c("div", { staticClass: "txt-box" }, [
                          _c("h1", { staticClass: "title" }, [
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.bannerList[0].attribute.description_1
                                )
                              ),
                            ]),
                          ]),
                          _c("p", {
                            staticClass: "txt-02",
                            staticStyle: { "line-height": "1.4" },
                            attrs: { id: "text" },
                            domProps: { innerHTML: _vm._s(_vm.subText) },
                          }),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "inspr-view prod-fd-view w-con" }, [
              _c("div", { staticClass: "t-type-01" }, [
                _c("div", { staticClass: "color-box" }, [
                  _c("ul", [
                    _c(
                      "li",
                      { staticStyle: { "background-color": "#fafbf8" } },
                      [_c("p", [_vm._v(_vm._s(_vm.$t("other.text.arctic")))])]
                    ),
                    _c(
                      "li",
                      { staticStyle: { "background-color": "#fbfbf1" } },
                      [_c("p", [_vm._v(_vm._s(_vm.$t("other.text.ivory")))])]
                    ),
                    _c(
                      "li",
                      { staticStyle: { "background-color": "#f8f4de" } },
                      [_c("p", [_vm._v(_vm._s(_vm.$t("other.text.cream")))])]
                    ),
                    _c(
                      "li",
                      { staticStyle: { "background-color": "#e5d7ba" } },
                      [_c("p", [_vm._v(_vm._s(_vm.$t("other.text.almond")))])]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "tab-ui tab-ui-dark" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab on",
                      attrs: { id: "all", name: "tapMenu" },
                    },
                    [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.tapClick("HIMACS_OTHER")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("other.text.all")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab",
                      attrs: { id: "kit", name: "tapMenu" },
                    },
                    [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.tapClick("HIMACS_OTHER_KIT")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("other.text.kitchen")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab",
                      attrs: { id: "bath", name: "tapMenu" },
                    },
                    [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.tapClick("HIMACS_OTHER_BAT")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("other.text.bathroom")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "btn-tab",
                      attrs: { id: "ada", name: "tapMenu" },
                    },
                    [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.tapClick("HIMACS_OTHER_ADA")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("other.text.ada")))]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "list-type-view row-04 inner" }, [
                _c(
                  "ul",
                  { staticClass: "list-thumb-demo" },
                  _vm._l(_vm.contentList, function (content, index) {
                    return _c("li", [
                      _c("div", { staticClass: "img-box" }, [
                        content.attribute.pc_thunb !== undefined
                          ? _c("img", {
                              attrs: {
                                src:
                                  _vm.$imagePath + content.attribute.pc_thunb,
                                alt: "other product img",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.popUpView(content)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "info02" }, [
                        _c("ul", [
                          _c("li", { staticClass: "txt-02" }, [
                            _c(
                              "a",
                              { attrs: { href: "javascript:void(0);" } },
                              [_vm._v(_vm._s(content.content.title))]
                            ),
                          ]),
                          _c("li", { staticClass: "txt-03" }, [
                            _vm._v(_vm._s(content.attribute.code)),
                          ]),
                          _c("li", { staticClass: "txt-04" }, [
                            _vm._v(_vm._s(content.parents[0].name)),
                          ]),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _c("div", { staticClass: "layer-popup layer-other" }, [
              _c("div", { staticClass: "popup pop-close" }, [
                _c("div", { staticClass: "pop-other scr-y" }, [
                  _c("p", { staticClass: "pop-other-title" }, [
                    _vm._v(_vm._s(_vm.contentContent.title)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "inspr-view prod-fd-view prod-op-view" },
                    [
                      _c(
                        "div",
                        { staticClass: "list-type-view tab-ui col-02" },
                        [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "img-box tab-con other01 on" },
                              [
                                _vm.contentAttribute.pc_thunb !== undefined
                                  ? _c("img", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        src:
                                          _vm.$imagePath +
                                          _vm.contentAttribute.pc_thunb,
                                        alt: "images",
                                      },
                                    })
                                  : _vm._e(),
                                _c("p", { staticClass: "ico" }, [
                                  _vm._v(_vm._s(_vm.popUpCateName)),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "info" }, [
                            _c("ul", [
                              _c("li", { staticClass: "txt-01" }, [
                                _vm._v(_vm._s(_vm.contentContent.title)),
                              ]),
                              _c("li", { staticClass: "txt-02" }, [
                                _vm._v(_vm._s(_vm.contentAttribute.code)),
                              ]),
                            ]),
                            _c("div", { staticClass: "tbl-box" }, [
                              _c("table", [
                                _c("caption", [_vm._v("Product Specs")]),
                                _vm._m(0),
                                _c("tbody", [
                                  _vm.contentAttribute.overall !== undefined
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("other.text.overall"))
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.contentAttribute.overall)
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.contentAttribute.inner !== undefined
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("other.text.inner"))
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.contentAttribute.inner)
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.contentAttribute.option !== undefined
                                    ? _c("tr", [
                                        _c("th", { attrs: { colspan: "2" } }, [
                                          _vm._v(
                                            _vm._s(_vm.contentAttribute.option)
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.contentAttribute.caution !== undefined
                                    ? _c("tr", [
                                        _c("th", { attrs: { colspan: "2" } }, [
                                          _vm._v(
                                            _vm._s(_vm.contentAttribute.caution)
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "btns claer" }, [
                              _c("div", { staticClass: "fl-l" }, [
                                _vm.contentAttribute.pdf
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-down",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadFile(
                                              _vm.contentAttribute.pdf
                                            )
                                          },
                                        },
                                      },
                                      [_c("span", [_vm._v("pdf")])]
                                    )
                                  : _vm._e(),
                                _vm.contentAttribute.dxf
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-down",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadFile(
                                              _vm.contentAttribute.dxf
                                            )
                                          },
                                        },
                                      },
                                      [_c("span", [_vm._v("DXF")])]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "fl-r" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-cont",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.contactUs()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("other.text.contactus"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-close",
                      attrs: { type: "button", onclick: "LayerPopup.close();" },
                    },
                    [_vm._v(_vm._s(_vm.$t("other.text.close")))]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "187px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }