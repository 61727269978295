<template>
  <div>
    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" id="salesForm">
      <fieldset>
        <legend v-if="$_.isUndefined(productType)">{{ $t("contactus.text.contactus") }}</legend>
        <input type=hidden name="oid" v-model="form.oid">
        <input type=hidden name="retURL" v-model="form.retURL" >
        <div class="cntu">
          <div class="tbl-wrap">

            <div class="inner glSite">
              <div class="tab-con tab01 on">
                <div class="tbl-01">
                  <!-- <p class="tt-2 mb-40"  style="display:none"></p> -->
                  <div class="tbl-input">
                    <table>
                      <caption>Contact us For Professionals form</caption>
                      <colgroup>
                        <col style="width:160px">
                        <col style="width:auto">
                      </colgroup>
                      <tbody>
                      <tr>
                        <th scope="row"><span class="must">{{ $t("contactus.text.prd_cate") }}</span></th>
                        <td class="cell-noti">
                          <div class="sel-box type02">
                            <button type="button" class="btn-sel"><span>{{ productName }}</span></button>
                            <div class="sel-list">
                              <ul class="box">
                                <li><button type="button" @click="categoryType('HFLOR', 'HFLOR')">{{ $t("contactus.text.HFLOR") }}</button></li>
                                <li><button type="button" @click="categoryType('HIMACS', 'HIMACS')">{{ $t("contactus.text.HIMACS") }}</button></li>
                                <li><button type="button" @click="categoryType('OTHER', 'Other Products')">{{ $t("contactus.text.Other") }}</button></li>
                              </ul>
                            </div>
                          </div>
                          <p class="required-msg" v-if="productCate == '' && submitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                        </td>
                      </tr>
                      <input type="hidden" id="00N6900000LFMAl" name="00N6900000LFMAl" v-model="form.category">
                      <tr>
                        <th scope="row"><span class="must">{{ $t("contactus.text.inquiry") }}</span></th>
                        <td class="cell-noti">
                          <div class="sel-box type02">
                            <button type="button" class="btn-sel"><span>{{ requestName }}</span></button>
                            <div class="sel-list">
                              <ul class="box">
                                <li v-for="type in requestList">
                                  <button type="button" @click="proSelectCheck('request', type.id.value, type.text)">{{type.text}}</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p class="required-msg" v-if="request == '' && submitChk == 'Y'">{{ $t("contactus.text.checkInquiry") }}</p>
                        </td>
                      </tr>
                      <input type="hidden" id="00N1r00000KNGM1" name="00N1r00000KNGM1" v-model="form.inquery">
                       <tr>
                        <th scope="row"><label for="inp01">{{ $t("contactus.text.lastName") }}</label></th>
                        <td class="cell-noti">
                          <input type="text" class="inp-text" name="first_name" id="inp01" :placeholder="$t('contactus.text.inputText')" v-model="firstName"/>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label for="inp02">{{ $t("contactus.text.firstName") }}</label></th>
                        <td class="cell-noti">
                          <input type="text" class="inp-text" name="last_name" id="inp02" :placeholder="$t('contactus.text.inputText')" v-model="lastName"/>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label for="inp06">{{ $t("contactus.text.company") }}</label></th>
                        <td>
                          <input type="text" class="inp-text" name="company" id="inp06" :placeholder="$t('contactus.text.inputText')" v-model="company"/>
                        </td>
                      </tr>
                      <tr class="pcView">
                        <th scope="row" colspan="2"><label for="inp08">Your Request</label></th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="tbl-02 glSite">
                  <p class="noti ta-r noti-abs" style="display:none">{{ $t("contactus.text.requiredField") }}</p>
                  <div class="tbl-input mob-bt-0">
                    <table>
                      <caption>{{ $t("contactus.text.pro_form") }}</caption>
                      <colgroup>
                        <col style="width:160px">
                        <col style="width:auto">
                      </colgroup>
                      <tbody>
                      <tr>
                        <th scope="row"><label class="must" for="inp03">{{ $t("contactus.text.email") }}</label></th>
                        <td class="cell-noti">
                          <input type="text" class="inp-text" id="inp03" name="email" :placeholder="$t('contactus.text.inputText')" v-model="email"/>
                          <p class="required-msg" v-if="email == '' && submitChk == 'Y' || submitChk == 'Y' && !emailRule.test(email)">{{ $t("contactus.text.checkEmail") }}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><label class="must" for="inp04">{{ $t("contactus.text.zip") }}</label></th> <!--{{ $t("contactus.text.phone") }}  -->
                        <td class="cell-noti">
                          <input type="text" class="inp-text" name="zip" id="inp04" maxlength="9" :placeholder="$t('contactus.text.inputText')" v-model="zipcode"/>
                          <p class="required-msg" v-if="zipcode == '' && submitChk == 'Y'">Enter Postal / Zip Code</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"><span class="must">{{ $t("contactus.text.country") }}</span></th>
                        <td class="cell-noti">
                          <div class="sel-box type02">
                            <button type="button" class="btn-sel"><span>{{ countryListName }}</span></button>
                            <div class="sel-list">
                              <ul class="box">
                                <li v-for="country in countryList">
                                  <button type="button" @click="proSelectCheck('country', country.countryCd, country.countryNm)">{{ country.countryNm }}</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p class="required-msg" v-if="country == '' && submitChk == 'Y'">{{ $t("contactus.text.checkCountry") }}</p>
                        </td>
                      </tr>
                      <input type="hidden" name="country_code" v-model="form.country_code">
                      <tr>
                        <th scope="row"><span>{{ $t("contactus.text.phone") }}</span></th>
                        <td class="cell-noti">
                          <input type="text" id="inp10" name="phone" :placeholder="$t('contactus.text.inputText')" class="inp-text" v-model="phone">
                          <!-- <p class="glSite" v-if="occupation == '' && submitChk == 'Y'">{{ $t("contactus.text.checkOcc") }}</p> -->
                        </td>
                      </tr>
                      <tr class="mbView">
                        <th scope="row" colspan="2"><label for="inp08">Your Request</label></th>
                        <!-- <div class="ta-box mt-20 mbView">
                          <p class="eu_tit"></p>
                          <textarea :placeholder="$t('contactus.text.comment')" id="inp08" v-model="comment" maxlength="1000"></textarea>
                        </div> -->
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="ta-box text-editor-box">
                <p class="eu_tit"></p>
                <textarea :placeholder="$t('contactus.text.comment')" name="00N1r00000KNGM3" id="inp08" v-model="comment" maxlength="1000"></textarea>
              </div>
              <input id="00N1r00000J5YJM" type=hidden name="00N1r00000J5YJM" title="Language" v-model="form.language"/>

              <input v-if="!isGoogleAds" id="00N1r00000J5YJg" type=hidden name="00N1r00000J5YJg" title="Web Form Type" value="General contact form"/>
              <input v-else id="00N1r00000J5YJg" type=hidden name="00N1r00000J5YJg" title="Web Form Type" value="Google Ads form"/>

              <div>
                <input v-if="!isGoogleAds" id="00N1r00000KMnBx" type=hidden maxlength="80" name="00N1r00000KMnBx" size="20" value="General Contact Form"/>
              </div>

              <div>
                <input v-if="isGoogleAds" id="00N1r00000KMnBx" type=hidden maxlength="80" name="00N1r00000KMnBx" size="20" value="Google Ads form"/>
              </div>

              <div>
                <input  id="lead_source"  type=hidden name="lead_source" value="Website">
              </div>

              <div class="mb-16" v-if="!$_.isUndefined(productType)">
                <label class="inp-chk" v-if="productType=='HIMACS'"><input type="checkbox" id="himacs"/>
                  <span>{{ $t("contactus.text.subscribe_himacs") }}</span>
                </label>
                <label class="inp-chk" v-else-if="productType=='HFLOR'"><input type="checkbox" id="hflor"/>
                  <span>{{ $t("contactus.text.subscribe_hflor") }}</span>
                </label>
              </div>
              <div class="eu_newsletter" v-else>
                <p class="ctt ctt-t4">{{ $t("contactus.text.newsletter") }}</p>
                <ul>
                  <li>
                    <label class="inp-chk fl-l">
                      <input type="checkbox" id="hflor" v-model="newsHflor">
                      <span>HFLOR</span>
                    </label>
                  </li>
                  <li>
                    <label class="inp-chk">
                      <input type="checkbox" id="himacs" v-model="newsHimacs">
                      <span>HIMACS</span>
                    </label>
                  </li>
                  <input type="hidden" name="00N1r00000J5YJH" v-model="form.newsletter">
                </ul>
              </div>

              <label class="inp-chk">
                <input type="checkbox" id="mktChk" v-model="mktChk">
                <input type="hidden" name="00N1r00000KNGM0" v-model="form.data_consent">
                <span>{{ $t("contactus.text.policy_0") }} <a href="javascript:void(0);" @click="openPrivacy()">{{ $t("contactus.text.policy") }}</a><span class="noti-02"></span></span>
              </label>
              <div class="noti-03 chk-required" v-if="submitChk == 'Y' && mktChk == false">Please check Privacy Policy</div>
            </div>
          </div>

          <div class="inner pc-only">
            <div class="find-btns">
              <div class="heading-5">{{ $t("contactus.text.find") }}</div>
              <ul>
                <li>
                  <a href="support/where-to-buy/country/en">
                    <img width="48" height="58" src="images/svg_icon/icon_quick_where.svg"/>
                    <span>{{ $t("contactus.text.wheretobuy") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/faq/hflor">
                    <img width="57" height="55" src="images/svg_icon/icon_quick_faq.svg"/>
                    <span>{{ $t("contactus.text.faq") }}</span>
                  </a>
                </li>
                <li>
                  <a href="pros/documentation/all-products">
                    <img width="48" height="58" src="images/svg_icon/icon_quick_document.svg"/>
                    <span>{{ $t("contactus.text.document") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/warranty/himacs">
                    <img width="53" height="62" src="images/svg_icon/icon_quick_warranty.svg"/>
                    <span>{{ $t("contactus.text.warranty") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/care-and-maintenance/himacs">
                    <img width="53" height="59" src="images/svg_icon/icon_quick_care.svg"/>
                    <span>{{ $t("contactus.text.care_main") }}</span>
                  </a>
                </li>
                <li>
                  <a href="pros/documentation/all-products/installation-guide">
                    <img width="52" height="53" src="images/svg_icon/icon_quick_install.svg"/>
                    <span>{{ $t("contactus.text.guide") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/sales-representative/continenteu/hflor">
                    <img width="53" height="50" src="images/svg_icon/icon_quick_sales.svg"/>
                    <span>{{ $t("contactus.text.find_sales") }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="google-ads-notice p-ui inner">

            <!-- 이 텍스트 보여야한대요  -->
            <p class="eu_bulgaria ctt ctt-t4 ta-c">{{ $t('contactus.text.google_ads_notice1') }}</p><br>
            <p class="eu_bulgaria ctt ctt-t4 ta-c">{{ $t('contactus.text.google_ads_notice2') }}</p><br>
            <p class="eu_bulgaria ctt ctt-t4 ta-c"><span class="noti-02 noti-gads euCount">{{ $t('contactus.text.google_ads_red') }}</span>
            </p>
            <!-- 이 텍스트 보여야한대요  -->

<!--            <p class="ads-notice-detail" v-html="$t('contactus.text.google_ads_notice')">-->
<!--            </p>-->
            <div class="submit-robvot">
              <g-recaptcha
                :data-sitekey="key"
                :data-validate="validate"
                :data-callback="callback"
                data-size="invisible"
                dataBtnClass="recaptchaButton"
              />
            </div>
          </div>

<!--          <div class="submit-robvot" v-else>-->
<!--            <g-recaptcha-->
<!--              :data-sitekey="key"-->
<!--              :data-validate="validate"-->
<!--              :data-callback="callback"-->
<!--              data-size="invisible"-->
<!--            />-->
<!--          </div>-->

          <div class="inner mb-only">
            <div class="find-btns">
              <div class="heading-5">{{ $t("contactus.text.find") }}</div>
              <ul>
                <li>
                  <a href="support/where-to-buy/country/en">
                    <img width="48" height="58" src="images/svg_icon/icon_quick_where.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_where.png"/> -->
                    <span>{{ $t("contactus.text.wheretobuy") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/faq/hflor">
                    <img width="57" height="55" src="images/svg_icon/icon_quick_faq.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_faq.png"/> -->
                    <span>{{ $t("contactus.text.faq") }}</span>
                  </a>
                </li>
                <li>
                  <a href="pros/documentation/all-products">
                    <img width="48" height="58" src="images/svg_icon/icon_quick_document.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_document.png"/> -->
                    <span>{{ $t("contactus.text.document") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/warranty/himacs">
                    <img width="53" height="62" src="images/svg_icon/icon_quick_warranty.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_warranty.png"/> -->
                    <span>{{ $t("contactus.text.warranty") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/care-and-maintenance/himacs">
                    <img width="53" height="59" src="images/svg_icon/icon_quick_care.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_care.png"/> -->
                    <span>{{ $t("contactus.text.care_main") }}</span>
                  </a>
                </li>
                <li>
                  <a href="pros/documentation/all-products/installation-guide">
                    <img width="52" height="53" src="images/svg_icon/icon_quick_install.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_install.png"/> -->
                    <span>{{ $t("contactus.text.guide") }}</span>
                  </a>
                </li>
                <li>
                  <a href="support/sales-representative/continenteu/hflor">
                    <img width="53" height="50" src="images/svg_icon/icon_quick_sales.svg"/>
                    <!-- <img width="74" height="74" src="images/product/icon_quick_sales.png"/> -->
                    <span>{{ $t("contactus.text.find_sales") }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="contacting inner" v-if="!$_.isEmpty(agentList) &&  !isGoogleAds">
            <p class="tt-2">{{ $t("contactus.text.feeling") }}</p>
            <ul>
              <li v-for="content in agentList">
                <img :src="$imagePath + content.content.cover" class="parson_img">
                <dl>
                  <dt class="ctt contact-des-tit">{{ content.content.description }}</dt>
                  <dd class="ctt ctt-t4 mb-5">{{ content.content.title }}</dd>
                  <dd class="ctt ctt-t5">{{ $t("contactus.text.products") }} : {{ content.attribute.pro_type }}</dd>
                  <dd class="ctt ctt-t5">{{ $t("contactus.text.phone") }} : {{ content.attribute.phone }}</dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </fieldset>
    </form>
    <iframe name="ifrm_salesforce" width="0" height="0" frameborder="0" style="display: none"></iframe>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import getDictionaryList from '@/server/support.service'
import getCountryList from '@/server/us.service'
import GnbComponent from '@/components/Layout/GNB'
import global from '@/common/global'
import memberInfoService from '@/server/myPage.service'
import gRecaptcha from '@finpo/vue2-recaptcha-invisible'
import SupportService from '@/server/support.service'
import { getCategoryCode, getCategoryId } from '@/server/category'
import { element } from 'screenfull'
import _ from '@/common/helper/lodash'

export default {
  name: 'contactus_EUForm',
  components: {
    gRecaptcha,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  props: ['productType', 'isGoogleAds'],
  data () {
    return {
      nationCode: '',
      radioType: '',
      categoryId: '',
      key: '6LfS1_0eAAAAAEs1Z8-OsrUoysKDQRw7ZtpMwayq', // recaptcha key
      agentList: [],
      hflorRequest: [],
      himacsRequest: [],
      hflorRequestEN: [],
      himacsRequestEN: [],
      requestList: [],
      countryList: [],
      productCate: '',
      requestName: this.$t('contactus.text.select'),
      requestNameEN: '',
      productName: this.$t('contactus.text.select'),
      newsHimacs: false,
      newsHflor: false,
      submitChk: 'N',
      memId: '',
      memGroup: '',
      countryListName: this.$t('contactus.text.select'),
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      country: '',
      company: '',
      comment: '',
      request: '',
      dictionaryId: '',
      // ---------
      mktChk: false,
      zipCodeCheck: '',
      zipcode: '',
      vaildCheck: false,
      siteType: [
        {
          id: '_EN',
          value: 'EN',
          language: 'en'
        },
        {
          id: '_IT',
          value: 'IT',
          language: 'it'
        },
        {
          id: '_DE',
          value: 'DE',
          language: 'de'
        },
        {
          id: '_FR',
          value: 'FR',
          language: 'fr'
        },
        {
          id: '_UK',
          value: 'UK',
          language: 'en'
        },
        {
          id: '_ES',
          value: 'ES',
          language: 'es'
        }
      ],
      siteTp: '',
      countryText: '',
      productText: '',
      reqType: '',
      //  메일 발송용 text
      checkZip: '',
      emailRule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, // 이메일 정규식
      form: {
        oid: '00D1r000002CYo2',
        retURL: 'http://',
        inquery: '', // inquery
        category: 'HIMACS', // product category
        last_name: '',
        first_name: '',
        company: '',
        email: '',
        zip: '',
        country_code: '',
        phone: '',
        newsletter: 0,
        data_consent: 1, // policy agreement
        textArea: '', // images textarea
        language: '', // language
        form_type: 'General contact form' // form type
      }
    }
  },
  watch: {
    // zipCode 숫자만, 글자수 체크
    zipcode (val) {
      const reg = /^[A-Za-z0-9+]*$/
      if (reg.test(val)) {
        return this.zipCodeCheck = true
      } else {
        return this.zipCodeCheck = false
      }
    },
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  created () {
    const _this = this
    const nationCode = this.$countryCode // 국가 코드(_US, _GL 등)
    _this.nationCode = nationCode
    // US일 경우 탭 선택
    _this.$nextTick(function () {
      $('.recaptchaButton').text(this.$t('contactus.button.submit'))
    })
  },
  async mounted () {
    const _this = this
    // 로그인 여부 확인
    let radioType
    if (this.isGoogleAds) {
      radioType = window.location.pathname
    } else {
      radioType = this.$route.params.user_type
    }
    if (radioType == 'HIMACS' || radioType == 'HFLOR' || radioType == 'OTHER' || radioType == 'himacs' || radioType == 'hflor') {
      _this.radioType = _this.$_.isUndefined(radioType) ? this.productType : radioType
    }

    if (this.productType == 'HIMACS' || this.productType == 'himacs') {
      this.newsHimacs = true
    } else if (this.productType == 'HFLOR' || this.productType == 'hflor') {
      this.newsHflor = true
    }

    const prdCode = this.$route.params.prd_code
    _this.comment = prdCode

    const res = await memberInfoService.getMember()
    if (res.data.code != 401) {
      _this.memId = res.data.id
      _this.memGroup = res.data.memGroup
    }
    // GL, US 구분
    _this.siteTp = _.filter(_this.siteType, function (c) {
      return c.id == _this.nationCode
    })
    _this.form.language = _this.siteTp[0].language
    // 데이터 사전 불러오기
    const dictionary = await getDictionaryList.getDictionaryValueList(['CONTACT_HFLOR' + _this.nationCode, 'CONTACT_HIMACS' + _this.nationCode, 'CONTACT_HFLOR_EN', 'CONTACT_HIMACS_EN'])
    _this.hflorRequest = dictionary.data.dicList[0].list // Pro Inquiry Type (Professionals Himacs 조회 종류)
    _this.himacsRequest = dictionary.data.dicList[1].list // Pro Inquiry Type (Professionals Viatera 조회 종류)
    _this.hflorRequestEN = dictionary.data.dicList[2].list // Pro Inquiry Type (Professionals Himacs 조회 종류, 영어버전 담당자 메일 발송용)
    _this.himacsRequestEN = dictionary.data.dicList[3].list // Pro Inquiry Type (Professionals Himacs 조회 종류, 영어버전 담당자 메일 발송용)

    // 국가 리스트 조회
    const countryList = await getCountryList.getEUCountryList('CU')
    _this.countryList = countryList.data

    _this.countryList = _.filter(countryList.data, function (c) { // Professionals CountryList (밸리데이션을 위해 pro, home 구분)
      return c.countryNm != 'Other' // Other값 제외하고 보여주기
    })
    // 지역 리스트 조회
    const categoryId = await getCategoryCode('CONTACT_SALES' + _this.nationCode)
    _this.categoryId = categoryId.data.object.id
    const agentList = await SupportService.getContentListSort(_this.categoryId)
    _this.agentList = agentList.data.list

    _this.reqType = this.$route.params.reqType
    _this.$nextTick(function () {
      if (radioType == 'HIMACS' || radioType == 'HFLOR' || radioType == 'OTHER' || radioType == 'himacs' || radioType == 'hflor') {
        _this.categoryType(_this.radioType.toUpperCase(), '', 'start')
      }
    })
  },
  methods: {
    tapClick (type) { // Professionals, Homeowners 탭 클릭 이벤트
      const _this = this
      _this.radio = type
    },
    validate () {
      const _this = this
      _this.submitChk = 'Y'
      if (!_this.country) {
        return false
      }
      if (!_this.productCate) {
        return false
      }
      if (!_this.email) {
        return false
      }
      if (!_this.request) {
        return false
      }
      if (!_this.zipcode) {
        return false
      }
      if (!_this.mktChk) {
        return false
      }
      return true
    },
    async callback (token) {
      const _this = this
      // google recaptcha token , then you can pass to backend with your form data .
      const formData = new FormData()
      if (token) {
        let himacsNews = '0'
        let hflorNews = '0'
        if (_this.newsHimacs) {
          _this.form.newsletter = '1'
          himacsNews = '1'
        }
        if (_this.newsHflor) {
          hflorNews = '1'
        }
        formData.append('categoryCode', _this.productCate)
        formData.append('productText', this.productText)
        formData.append('inqId', _this.dictionaryId)
        formData.append('inqVal', _this.request)
        formData.append('lastNm', _this.lastName)
        formData.append('firstNm', _this.firstName)
        formData.append('company', _this.company)
        formData.append('email', _this.email)
        formData.append('zipCd', _this.zipcode)
        formData.append('countryCd', _this.country)
        formData.append('phone', _this.phone)
        formData.append('comment', _this.comment)
        formData.append('memNo', _this.memId)
        formData.append('memGroup', _this.memGroup)
        formData.append('siteType', _this.siteTp[0].value)
        formData.append('countryText', _this.countryListName)
        formData.append('newsHflor', hflorNews)
        formData.append('newsHimacs', himacsNews)
        formData.append('requestName', _this.requestNameEN)
        formData.append('language', this.$countryCode.substring(1))
        var emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식

        // 빈값 체크
        for (const pair of formData.entries()) {
          if (pair[0] == 'categoryCode' && pair[0] == 'inqId' && pair[0] == 'email' && pair[0] == 'zipCd' && pair[0] == 'countryCd') {
            if (pair[1] == '') {
              return false
            }
          } else {
            if (pair[0] == 'email') {
              if (!emailRule.test(pair[1])) {
                return false
              }
            }
          }
        }
        // comment 글자수 체크
        if (_this.comment != undefined) {
          if (_this.comment.length > 1000) {
            alert('The maximum number of characters is 1,000 characters.')
            return false
          }
        }
        // 동의 여부 체크 및 저장, 메일 발송
        if ($('#mktChk').is(':checked')) {
          // GTM - contact us
          this.makeDataLayerForm()
          const mailCheck = await getDictionaryList.getMailCheck(formData)
          if (mailCheck.data.count <= 3) {
            const result = await getDictionaryList.getEUContactusSave(formData).then((response) => {
              if (_this.productCate == 'HIMACS') {
                $('#salesForm').attr('target', 'ifrm_salesforce')
                $('#salesForm').submit()
              }
              location.href = 'support/contactus-close'
            }).catch((error) => {
              console.log(error)
              alert('Error')
            })
            // console.log(result, "222222")
            // if (result.data.success === true) {
            //   if(_this.productCate == 'HIMACS'){
            //     $("#salesForm").attr('target', 'ifrm_salesforce')
            //     $("#salesForm").submit();
            //   }
            //   location.href = 'support/contactus-close'
            // } else {
            //   alert('Error')
            // }
          } else {
            alert('You may not submit another entry at this time.')
          }
        } else {
          alert('Please check whether you agree to collect personal information.')
        }
      }
    },
    categoryType (type, typeText, start) { // Product Category SelectBox 클릭 이벤트
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      const _this = this
      _this.productCate = type
      _this.productText = typeText
      _this.productName = type
      if (type == 'HIMACS') {
        _this.requestList = _this.himacsRequest
        _this.dictionaryId = 'CONTACT_HIMACS' + _this.nationCode
      } else {
        _this.requestList = _this.hflorRequest
        _this.dictionaryId = 'CONTACT_HFLOR' + _this.nationCode
      }
      _this.requestName = _this.$t('contactus.text.select')
      _this.request = ''
      _this.$nextTick(function () {
        if (_this.reqType != undefined && type == 'HIMACS' && start == 'start') {
          _this.proSelectCheck('request', 'req_hi_006', 'BECOME A HIMACS FARICATOR/INSTALLER NOW')
        }
      })
    },
    async proSelectCheck (type, code, value) { // Professionals 일 때 selectBox 클릭 이벤트
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      const _this = this
      if (type == 'request') {
        _this.requestName = value
        _this.request = code
        if (_this.productCate == 'HIMACS') {
          const requestNameEN = _this.himacsRequestEN.filter((element) => element.id.value == code)
          _this.requestNameEN = requestNameEN[0].text
          if (code == 'req_hi_001') {
            _this.form.inquery = 'general'
          } else if (code == 'req_hi_002') {
            _this.form.inquery = 'distributor'
          } else if (code == 'req_hi_003') {
            _this.form.inquery = 'ask-for-sample'
          } else if (code == 'req_hi_004') {
            _this.form.inquery = 'pass-test'
          } else if (code == 'req_hi_005') {
            _this.form.inquery = 'pass-test'
          } else if (code == 'req_hi_006') {
            _this.form.inquery = 'become-himacs-fabricator-installer'
          } else if (code == 'req_hi_007') {
            _this.form.inquery = 'himacs-warranty'
          }
        } else {
          const requestNameEN = _this.hflorRequestEN.filter((element) => element.id.value == code)
          _this.requestNameEN = requestNameEN[0].text
        }
      } else if (type == 'country') {
        _this.countryListName = value
        _this.country = code
        _this.form.country_code = code
      }
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    openPrivacy () {
      window.open('etc/privacy?pop=Y', 'privacy', 'width:800px')
    },
    openTerms () {
      window.open('etc/terms?pop=Y', 'terms', 'width:800px')
    },
    // GTM dataLayer - contact us
    makeDataLayerForm () {
      let contactType = ''
      let email = ''
      contactType = 'PROFESSIONALS'
      email = this.email

      const contactArea = ''

      let contactInquiry = ''
      contactInquiry = this.requestName

      let contactCountry = ''
      contactCountry = this.countryListName

      const contactState = ''

      const contactCompanyNm = this.company

      const info = {
        event: 'contactUsComp',
        contact_email: this.$utils.makeMd5hash(email),
        contact_type: contactType,
        contact_product_category: this.productName,
        contact_type_of_inquiry: contactInquiry,
        contact_area: contactArea,
        contact_country: contactCountry,
        contact_state: contactState,
        contact_zipcode: this.zipcode,
        contact_occupation: this.occName,
        contact_company: contactCompanyNm,
        contact_marketing_flag: this.mktChk
      }
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push(info)
      }
    }
  }
}
</script>
<style>
.euCount {
  margin: 0 auto;
  padding-left: 7px;
}
.submit-robvot  {
  width: 100%;
}

.submit-robvot div {
  margin: 0 auto;
}
.submit-robvot div {
  margin: 0 auto;
}

.submit-robvot div button {
  position: relative;
  margin: 50px auto;
  width: auto;
  display: block;
  font-size: 0.94118rem;
  background: #2c2c2d url(https://img.lxhausys.com/public/images/arrw_right_04.png) 80% center no-repeat;
  color: #fff;
  height: 48px;
  padding: 0 67px 0 24px;
  border-radius: 25px;
  white-space: nowrap;
}

.mbView {
  display: none;
}

@media only screen and (max-width: 767px){
  .pcView{
    display: none;
  }
  .mbView{
    display: block;
  }
}

</style>
