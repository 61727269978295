<template>
  <ul>
    <COMPARE></COMPARE>
    <VIZUON :category="category" v-on:category-changed-vizuon="handleCategoryChangedVizuon"></VIZUON>
    <COLLECTION :collectionList="collectionList" v-model="collection" :category="category"></COLLECTION>
    <IS_NEW v-model="isNew"></IS_NEW>
    <ADHESIVETYPE :adhesiveList="dictMap['adhesive_type']" v-model="adhesive_type"></ADHESIVETYPE>
    <PRODUCTCLASSIFICATION :prdcfcList="dictMap['prd_class']" v-model="prd_class"></PRODUCTCLASSIFICATION>
    <AIRFREE :airfreeList="dictMap['air_free']" v-model="air_free"></AIRFREE>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import VIZUON from '@/views/products/finder/component/VIZUON.vue'
import COMPARE from './component/COMPARE.vue'
import APPLICATION from './component/APPLICATION.vue'
import PRODUCTTYPE from './component/PRODUCTTYPE.vue'
import ADHESIVETYPE from './component/ADHESIVETYPE.vue'
import AIRFREE from './component/AIRFREE.vue'
import WEATHERABILITY from './component/WEATHERABILITY.vue'
import PRODUCTCLASSIFICATION from './component/PRODUCTCLASSIFICATION.vue'
import IS_NEW from './component/IS_NEW.vue'
import COLLECTION from '@/views/products/finder/component/COLLECTION.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    COLLECTION,
    IS_NEW,
    VIZUON,
    COMPARE,
    APPLICATION,
    PRODUCTTYPE,
    ADHESIVETYPE,
    AIRFREE,
    WEATHERABILITY,
    PRODUCTCLASSIFICATION
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      application: [],
      prdType: [],
      adhesive_type: [],
      air_free: [],
      prd_class: [],
      durability: [],
      isNew: [],
      collection: []
    }
  },
  watch: {},
  async created () {

  },
  async mounted () {
    this.$watch(vm => [vm.hue, vm.collection, vm.collection, vm.prdType, vm.finish, vm.country, vm.isNew], val => {
      this.$emit('update', this.$data)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {
    handleCategoryChangedVizuon (cateCode) {
      this.$emit('category-changed-vizuon', cateCode)
    }
  }
}
</script>
