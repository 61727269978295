<template>
  <div class="main-box height100 visual">
    <div class="slider-prod main-slider">
      <div class="slider" v-for="banner in bannerList" :value="banner.attribute.MAIN_VISUAL_PRODUCTText">
        <div class="txt-box">
          <p class="img_wrap" v-if="!banner.imageLoadError">
            <img :src="$imagePath +'/2024/images/main/logo/' + banner.attribute.MAIN_VISUAL_PRODUCTText + '.svg'" alt="logo" class="visual_logo" @error="banner.imageLoadError=true" @load="banner.imageLoadError=false">
          </p>
          <!-- 이미지 없을때 텍스트 추가 -->
          <p class="txt_wrap" v-if="banner.imageLoadError" v-html="banner.content.title"></p>
          <p class="txt-01" v-html="banner.attribute.MAIN_VISUAL_SUB_TITLE"></p>
          <a class="btn-more" @click="openLink(banner)">
            <span>View more</span>
          </a>
        </div>
        <div class="bannerGradient" />
        <img v-if="banner.attribute.MAIN_VISUAL_SELECT == '001'" :src="$imagePath + banner.attribute.MAIN_VISUAL_IMAGE" alt="lxhausys" class="slider_bg">
        <div v-if="banner.attribute.MAIN_VISUAL_SELECT == '002'" class="video-wrapper">
          <!-- <iframe :src="$imagePath + banner.attribute.MAIN_VISUAL_VIDEO"></iframe> -->
          <video :src="$imagePath + banner.attribute.MAIN_VISUAL_VIDEO" class="slide_video" muted autoplay playsinline loop="4"></video>
        </div>
        <div v-if="banner.attribute.MAIN_VISUAL_SELECT == '003'" class="youtube-wrapper">
          <iframe :src="banner.attribute.MAIN_VISUAL_YOUTUBE + '?autoplay=1&mute=1&loop=1'" allow="autoplay; encrypted-media"></iframe>
        </div>
      </div>
    </div>

    <div class="main_slider-nav">
      <div class="item" v-for="banner in bannerList" :value="banner.attribute.MAIN_VISUAL_PRODUCTText">
        <div class="dot">
          <span class="txt1" v-html="banner.content.title"></span>
          <span class="txt2" v-html="banner.attribute.MAIN_VISUAL_TAB_SUB_TITLE"></span>
          <div class="bottom_line"></div>
        </div>
        <div class="shadow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mainService from '@/server/main.service'

export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      photo_credit: '',
      bannerId: '',
      bannerList: [],
      countryCode: ''
    }
  },
  async mounted () {
    const _this = this

    const nationCode = _this.$countryCode
    _this.countryCode = nationCode
    _this.categoryCode = 'MAIN_VISUAL' + nationCode

    const categoryRes = await mainService.getCategoryCode(_this.categoryCode)
    _this.bannerId = categoryRes.data.object.id

    const bannerList = await mainService.getContentListSort(_this.bannerId, 1, 10, 'sort', 'asc')
    _this.bannerList = bannerList.data.list

    _this.bannerList.forEach(banner => {
      _this.$set(banner, 'imageLoadError', false)
    })

    // if (_this.countryCode != '_GL' && _this.countryCode != '_US') {
    //   _this.photo_credit = _this.bannerList[0].attribute.photo_credit
    // }
    _this.$nextTick(function () {
      var width = window.innerWidth
      if (width < 769) {
        var $slider = $('.slider-prod.main-slider')

        $slider.slick({
          autoplay: true,
          autoplaySpeed: 5000,
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover: false,
          arrows: false,
          fade: true,
          speed: 1000,
          cssEase: 'linear',
          dots: false,
          asNavFor: '.main_slider-nav'
        })
        $('.main_slider-nav').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: '.slider-prod.main-slider',
          dots: false,
          focusOnSelect: true,
          arrows: false,
          centerMode: true,
          centerPadding: '80px'
        })
      } else {
        var $slider = $('.slider-prod.main-slider')
      }
      if ($slider.length) {
        if (width >= 769) {
          var currentSlide
          var slidesCount
          var sliderCounter = document.createElement('div')
          sliderCounter.classList.add('slider__counter')

          var updateSliderCounter = function (slick, currentIndex) {
            if (currentIndex != undefined && (_this.countryCode != '_GL' && _this.countryCode != '_US')) {
              _this.photo_credit = _this.bannerList[currentIndex].attribute.photo_credit
            }
            currentSlide = slick.slickCurrentSlide() + 1
            slidesCount = slick.slideCount
            $(sliderCounter).text(currentSlide + '/' + slidesCount)
          }

          $slider.on('init', function (event, slick) {
            $slider.append(sliderCounter)
            //updateSliderCounter(slick)
            // slide_animation();
          })

          $slider.on('afterChange', function (event, slick, currentSlide) {
            //updateSliderCounter(slick, currentSlide)
            // slide_animation();
          })

          /* index visual slider */
          $slider.slick({
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            dots: false,
            asNavFor: '.main_slider-nav'
          })

          $('.main_slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider-prod.main-slider',
            dots: false,
            focusOnSelect: true,
            arrows: true
          })
        }
      }

      // 슬라이드 pagenation 애니메이션
      // function slide_animation(){
      //   let shadow = $('.main_slider-nav .item.slick-current .shadow');
      //   let bottom_line = $('.main_slider-nav .item.slick-current .dot .bottom_line');

      //   shadow.stop().animate({
      //     'bottom':'0'
      //   }, 1000);

      //   setTimeout(function(){
      //     shadow.stop().animate({
      //       'bottom':'0'
      //     }, 3000, function(){
      //       shadow.stop().animate({
      //         'bottom':'-100%'
      //       }, 0);
      //     });

      //     bottom_line.stop().animate({
      //       'width':'100%'
      //     },3000,function(){
      //       bottom_line.stop().animate({
      //         'width':'0'
      //       }, 0);
      //     });
      //   }, 2000);
      // }
    })
  },
  methods: {
    openLink(windowOpenValue) {
      var windowOpen;
      try {
        windowOpen = JSON.parse(windowOpenValue.attribute.MAIN_VISUAL_WINDOWS);
      } catch (error) {
        // 파싱 실패나 값이 없을 경우 기존창으로 강제 셋팅
        windowOpen = { value: "002" }; // 기본값 설정
      }

      var value = windowOpen.value;

      if (!windowOpenValue.attribute.MAIN_VISUAL_URL) {
        return; // Exit the function early if URL is empty
      }

      if (value === "001") {
        // 기존 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_VISUAL_URL, '_self'); // '기존 창 URL'을 적절히 변경해주세요.
      } else if (value === "002") {
        // 새 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_VISUAL_URL, '_blank'); // '새 창 URL'을 적절히 변경해주세요.
      }
    }
  }
}
</script>
