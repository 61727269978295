var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode === "_US" || _vm.nationCode === "GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Nature, Human and Space", color: "black" } },
        [
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "ctt-pre ctt-t2" }, [
              _vm._v("LX Hausys presents the vision of "),
              _c("b", [
                _vm._v(
                  "“Creating human-friendly, eco-conscious living spaces”."
                ),
              ]),
              _vm._v(
                "\nThis represents our goal to provide eco-conscious and energy-efficient materials and products as a trendsetter in creating a\nfuture space realizing an aesthetically-pleasing and human-friendly design. This also shows our will to create living spaces that\nbring contentment to our customers by placing customer satisfaction as the top priority."
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        description: it.description,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "自然、人間、そして空間", color: "black" } },
        [
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "ctt-pre ctt-t2" }, [
              _vm._v("LX Hausysは、"),
              _c("b", [_vm._v("「人に優しく、環境に配慮した生活空間の創造」")]),
              _vm._v(
                "\n        というビジョンを掲げています。これは、環境に配慮した省エネ素材や製品を提供し、美しさと人に\n        優しいデザインを実現する未来の空間創造のトレンドセッターとなることを目指しています。\n        また、顧客満足を第一に考え、お客様に満足いただける生活空間を創造していくという意志を表しています。"
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem_jp, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        description: it.description,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Природа, человек и пространство", color: "black" } },
        [
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "ctt-pre ctt-t2" }, [
              _vm._v("LX Hausys представляет концепцию "),
              _c("b", [
                _vm._v(
                  "«Создание дружественных человеку, экологически безопасных жилых пространств»"
                ),
              ]),
              _vm._v(
                ".\n        Это представляет собой нашу цель - обеспечить экологически чистые и энергоэффективные материалы и продукты как законодателя \n        моды в создании будущего пространства, реализующего эстетически приятный и дружественный человеку дизайн. Это также \n        демонстрирует наше стремление создавать жилые пространства, которые приносят удовлетворение нашим клиентам, \n        и это наш главный приоритет."
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem_ru, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        description: it.description,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "自然、人类于空间", color: "black" } },
        [
          _c("OverviewSectionItem", [
            _c("pre", { staticClass: "ctt-pre ctt-t2" }, [
              _vm._v("LX Hausys以"),
              _c("b", [_vm._v("“创造与自然相融合的人类幸福生活空间”")]),
              _vm._v(
                "为愿景，\n        展示出企业为顾客提供环保节能的原料与产品，同时打造赏心悦目又不失人性化设计的空间，更将引领未来室内装修潮流的美好寓意。\n        不管何时何地，都将以顾客满意为宗旨，致力于打造能带给顾客幸福感的生活空间。"
              ),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem_cn, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        description: it.description,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }