<template>
  <div class="tab-con on" v-if="tabCount > 0">
    <div class="gallery sch-res-prod prod-fd-view pd-0">
      <div class="res-prod-cnt">
        <span>Inspiration Galleries (<em>{{ INSP.totalCount }}</em>)</span>
      </div>
      <div class="list-search-collection">
        <ul v-if="INSP && INSP.totalCount > 0">
          <li v-for="sr in INSP.list">
            <div class="img-box">
              <a href="javascript:void(0);" @click="gotoInspAll(sr)"><img :src="$imagePath + getImage(sr)" :alt="sr.title"></a>
            </div>
            <div class="info">
              <ul>
                <li class="txt-01">
                  <a href="javascript:void(0);">[<span>{{sr.attribute.segmentsText}}</span>] <span>{{sr.content.title}}</span></a>
                </li>
                <li class="txt-02">
                  <a href="javascript:void(0);" @click="gotoInspAll(sr)">{{ $t('learn_more_collection') }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!INSP.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreInsp()">
          <span>{{ $t('see_more')}}</span>
        </button> -->
        <button type="button" class="btn_load_more" @click="loadMoreInsp()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>

    <div class="sch-res-prod prod-fd-view inspr-view">
      <div class="res-prod-cnt">
        <span>Case Studies (<em>{{CASE.totalCount}}</em>)</span>
      </div>
      <div class="articles-list">
        <ul v-if="CASE && CASE.totalCount > 0">
          <li v-for="sr in CASE.list" class="col-02 search-ins-cont-box">
            <div class="txt-box">
              <p v-if="sr.attribute.segmentsText">{{sr.attribute.segmentsText}}</p>
              <p class="tt-3">{{ sr.content.title }}</p>
              <p v-if="sr.attribute.location">{{sr.attribute.location}}</p>
              <div class="text ctt ctt-t4">{{sr.attribute.description}}</div>
              <div class="more-box">
                <button type="button" class="btn_view_more" @click="gotoCaseDetail(sr)">
                  <span>{{ $t('see_more')}}</span>
                </button>
              </div>
            </div>
            <div class="img-box">
              <img :src="$imagePath + getImage(sr)" :alt="sr.title" @click="gotoCaseDetail(sr)">
              <a href="javascript:void(0);" class="icon-like-shadow" v-if="authenticated == true && nationCode == '_US'"  v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'CS')">wish list // 11</a>
              <a href="login/" class="icon-like-shadow" v-if="authenticated == false && nationCode == '_US'" @click="loginalertLike()">wish list // 22</a>
              <a href="javascript:void(0);" class="icon-like-shadow" v-if="getPJSessionContent != null && nationCode != '_US'"  v-bind:class="{ on: getPJSessionContent.includes(sr.content.id) }" @click="toggleLike(sr, 'CS')">wish list // 33</a>
              <a href="javascript:void(0);" class="icon-like-shadow" v-if="getPJSessionContent == null && nationCode != '_US'"  @click="toggleLike(sr, 'CS')">wish list // 44</a>
<!--                <a href="javascript:void(0);" class="icon-like-shadow"  v-bind:class="{ on: sr.content.liked }" @click="toggleLike(sr, 'CS')">wish list</a>-->
              <span class="badge badge-cat">HIMACS</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="more-box ta-c" v-if="!CASE.lastPage">
        <!-- <button type="button" class="btn btn-arr-down" @click="loadMoreCase()">
          <span>{{ $t('see_more')}}</span>
        </button> -->
        <button type="button" class="btn_load_more" @click="loadMoreCase()">
          <span class="txt">Load More</span>
          <span class="icon icon_plus"></span>
        </button>
      </div>
    </div>

  </div>
  <div class="no-found-wrap bd-top" v-else>
    <div class="tac">
      <i class="icon-no-result"><img src="images/icon-no-result-found.png" alt="no result" /></i>
      <div class="msg">
        <ul>
          <li class="ctt ctt-t1 result-tit">No Result Found</li>
          <li class="ctt ctt-t4">Try again using more general search keywords</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SearchService from '@/server/search.service'

import { mapState } from 'vuex'
import LikeService from '@/server/like.service'
import _ from '@/common/helper/lodash'
import {isEmpty} from "lodash";

export default {
  name: 'SearchInspiration',
  props: ['searchResult', 'searchWord'],
  watch: {
    searchResult () {
      this.initSearchResult()
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  data () {
    return {
      seoCheck: false,
      tabCount: 0,
      nationCode: '',
      getPJSessionContent: [] /* GL : PD 불러오는 값 */,
      getPDSessionContent: [] /* GL : PJ 불러오는 값 */,
      INSP: { totalCount: 0 },
      CASE: { totalCount: 0 },
      likeList: [],
      likeContentIdList: []
    }
  },
  async mounted () {
    const t = this
    this.nationCode = this.$countryCode
    if (t.nationCode == '_US' || t.nationCode == '_GL') {
      t.seoCheck = true
    }
    if (t.nationCode != '_US') {
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      const SessionPJ = JSON.parse(sessionStorage.getItem('PJ'))
      _.filter(SessionPD, function (o) {
        if (o.nationCode == t.nationCode) {
          t.getPDSessionContent.push(o.id)
        }
      })
      _.filter(SessionPJ, function (o) {
        if (o.nationCode == t.nationCode) {
          if (o.cateType == 'IG') {
            t.getPJSessionContent.push(o.id)
          }
        }
      })
    }
    this.initSearchResult()
    this.loadLikeList()
  },
  methods: {
    loginalertLike () {
      alert('Log in to save to your Likes')
    },
    gotoInspAll (sr) {
      const slug = sr.content.slug
      const countryCode = this.$countryCode.slice(1).toLowerCase()

      if (isEmpty(slug)) {
        document.location.href = `/${countryCode}/inspiration/gallery-detail?contentId=` + sr.content.id
      } else {
        document.location.href = `/${countryCode}/inspiration/gallery/detail/` + slug
      }

    },
    loadMoreInsp () {
      this.loadMore('INSP')
    },
    gotoCaseDetail (sr) {
      const slug = sr.content.slug
      const countryCode = this.$countryCode.slice(1).toLowerCase()
      if (isEmpty(slug)) {
        document.location.href = `/${countryCode}/inspiration/case-studies/detail?contentId=` + sr.content.id
      } else {
        document.location.href = `/${countryCode}/inspiration/case-studies/detail/` + sr.content.slug
      }
    },
    loadMoreCase () {
      this.loadMore('CASE')
    },
    getImage (sr) {
      return sr.attribute.mainThumbnail
    },
    async loadMore (subType) {
      const search = this.searchResult[subType]
      const pageIndex = search.pageIndex + 1
      const pageSize = search.pageSize
      const s = this.searchWord
      const r = this.$countryCode.substring(1)

      const { data } = await SearchService.byType({ pageIndex, pageSize, s, r, type: 'inspiration', subType })

      search.lastPage = data.lastPage
      search.pageIndex = data.pageIndex
      search.list.push(...data.list)
    },
    initSearchResult () {
      this.INSP = this.searchResult.INSP
      this.CASE = this.searchResult.CASE
      this.tabCount = this.searchResult.INSP.totalCount + this.searchResult.CASE.totalCount
    },

    async toggleLike (sr, cateType) {
      this.$utils.toggleLike(sr, 'PJ', cateType, this)
    },

    async loadLikeList () {
      const contentIds =
      [
        ...this.INSP.list.map(i => i.content.id),
        ...this.CASE.list.map(i => i.content.id)
      ]

      const query = { contentIds }
      const { data } = await LikeService.getLikeList(query)
      this.likeList = data.list || []
      this.likeContentIdList = this.likeList.map(x => x.contentId)
    }
  }
}
</script>
