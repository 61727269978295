var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "design-area mt-100" },
    [
      _c("ProductSectionHeader", {
        attrs: {
          type: "horizontal",
          title: "Design",
          description:
            "LX Hausys develops automotive fabrics that not only offer high quality but also research and develop surface texture designs that match the vehicle's concept. By utilizing various patterns and design post-processing techniques, we provide design solutions that cater to our customers' preferences.",
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-50" }, [
      _c("img", {
        attrs: { src: "images/automotive/skin/design-image.png", alt: "img" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }