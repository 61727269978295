<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content pros-himacs">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">{{ $t('partnersPortal.document.home') }}</li>
          <li>{{ $t('partnersPortal.document.pros') }}</li>
          <li>{{ $t('partnersPortal.document.main_title') }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (HIMACS Partners Portal) -->
      <div class="container for-box himacs-partners-portal">
        <div class="w-con-02" id="w-con-02">
          <!-- <p class="tt-1 mb-88 tac inner">{{ $t('partnersPortal.document.title') }}</p> -->
          <h1 class="tt-1 mb-88 tac inner">
            <span class="tt-1 mb-br">{{ $t('partnersPortal.document.main_title') }}</span>
          </h1>
          <div class="section-acc mb-24">
            <div class="frow">
              <div class="fcol">
                <!-- <i class="icon-profile-email"></i> -->
                <span class="email">
                  {{ userEmail }}
                </span>
              </div>
              <div class="fcol ta-r">
                <div>
                  <button type="button" class="btn btn-line btn-logout" @click="logout()">
                    <span>{{ $t('partnersPortal.document.logout') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-ui tab-ui-dark">
            <ul class="btns02" style="overflow-x: auto;">
              <li class="btn-tab on" id="btn-tab01" data-tab="tab01"><button type="button">{{ $t('partnersPortal.document.reading') }}</button></li>
              <li class="btn-tab wide" id="btn-tab02" data-tab="tab02"><button class="pp-tab-btn" type="button" style="white-space: nowrap;">{{ $t('partnersPortal.document.doc_download') }}</button></li>
            </ul>

            <!-- YOUR READING BOX -->
            <div class="tab-con tab01 on inner" id="inner01">
              <div class="readingbox-con">
                <p class="ctt ctt-t2 ta-c">
                  {{ $t('partnersPortal.document.inner') }}
                </p>
              </div>
              <div class="list-box">
                <ul class="ul-for-partners-portal ctt">
                  <li v-for="reading in readingList">
                    <div class="li-bo-con">
                      <div class="frow">
                        <a class="fcol subj under-line" @click="docTypeClick(reading)">
                          {{ reading.content.title }}
                        </a>
                        <span class="fcol date ta-r">
                          {{ reading.content.createDate | moment('YYYY-MM-DD')}}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="tab-btm mt-140 ta-c">
                <button type="button" class="btn" @click="seeAll()">
                  <span>{{ $t("partnersPortal.document.seeAll") }}</span>
                </button>
              </div>
            </div>
            <!-- //YOUR READING BOX -->

            <!-- DOCUMENTS TO DOWNLOAD -->
            <div class="tab-con tab02 inner" id="inner02">

              <!-- product Finder -->
              <div class="inspr-view fd-view documents-box col-02">
                <!-- search -->
                <div id="filterSearch" class="search-box">
                  <div class="search">
                    <input id="textSearch" type="text" :placeholder="$t('partnersPortal.document.searchbar')" v-on:keyup.enter="search"/>
                    <i class="icon-search gray" @click="search()"></i>
                  </div>

                  <!-- drop-list pc에서만 보임 -->
                  <div class="drop-list inner">
                    <ul class="ul-type">

                      <!-- Document Type -->
                      <li class="on">
                        <div class="tit">
                          <span class="tt-sub">{{ $t('partnersPortal.document.doc_type') }}</span>
                        </div>

                        <div class="i-box type01 cont" style="display:block;">
                          <ul class="ul-type">
                            <li v-for="(type, index) in typeList">
                              <label class="inp-rdo" @click="typeChange(type.category.id)">
                                <input type="radio" name="rdo00" :id="'type_'+type.category.id" :checked="index == 0">
                                <span>{{ type.category.name }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <!-- //Document Type -->

                      <!-- CATEGORY -->
                      <li class="on">
                        <div class="tit">
                          <span class="tt-sub">{{ $t('partnersPortal.document.category') }}</span>
                          <button type="button" class="btn-view btn-collection"></button>
                        </div>

                        <div class="i-box type01 cont" style="display:block;">
                          <ul class="ul-type">
                            <li v-for="(cate, index) in cateList" @click="cateChange(cate.category.id)">
                              <label class="inp-rdo">
                                <input type="radio" name="rdo01" :id="'cate_'+cate.category.id" :checked="index == 0">
                                <span>{{ cate.category.name }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <!-- //CATEGORY -->

                      <!-- SUB-CATEGORY -->
                      <li class="on" v-if="subCateList.length > 0">
                        <div class="tit">
                          <span class="tt-sub">{{ $t('partnersPortal.document.subCate') }}</span>
                          <button type="button" class="btn-view btn-patterns"></button>
                        </div>

                        <div class="i-box type02 cont" style="display:block;">
                          <ul class="ul-type">
                            <li v-for="(sub, index) in subCateList">
                              <label class="inp-chk">
                                <input type="checkbox" name="subCate" :id="'sub_'+sub.category.id" @change="subCheck(sub.category.id, index, sub.category.name)" :checked="sub.category.id === endId">
                                <span>{{ sub.category.name }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <!-- //SUB-CATEGORY -->

                    </ul>
                  </div>
                </div>
                <!-- //search -->

                <!-- btn-filter mobile에서만 보임 -->
                <div class="prod-list-box">
                  <!-- btn-filter mobile에서만 보임 -->
                  <button type="button" class="btn btn-block btn-filter mb-only">
                    <span>Filter By</span>
                    <i class="icon-finder"></i>
                  </button>

                  <!-- sort -->
                  <div class="sort-box">
                    <div class="sel-box">
                      <button type="button" class="btn-sel"><span> {{ contentSortBy }} </span></button>
                      <div class="sel-list">
                        <ul class="box">
                          <li>
                            <button type="button" @click="sortByChange($t('partnersPortal.document.popu'), 'desc', 'clicks')">{{ $t("partnersPortal.document.popu") }}</button>
                          </li>
                          <li>
                            <button type="button" @click="sortByChange($t('partnersPortal.document.Asc'), 'asc', 'title')">{{ $t("partnersPortal.document.Asc") }}</button>
                          </li>
                          <li>
                            <button type="button" @click="sortByChange($t('partnersPortal.document.Desc'), 'desc', 'title')">{{ $t("partnersPortal.document.Desc") }}</button>
                          </li>
                          <li>
                            <button type="button" @click="sortByChange($t('partnersPortal.document.Newest'), 'desc', 'createdAt')">{{ $t("partnersPortal.document.Newest") }}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- //sort -->

                  <div>
                    <div class="select-view">
                      <ul class="list-filter-key">
                        <li class="sel" v-for="filter in filterList" @click="subDelete(filter.id)">
                          <span>{{filter.name}}</span>
                          <button type="button">&#10005;</button>
                        </li>
                        <li class="sel del_all" v-if="filterList.length > 0">
                          <button class="btn-all-delete" @click="allTypeDelete()">
                            <span>{{ $t("partnersPortal.document.Clear") }} <em>X</em></span>
                          </button>
                        </li>
                      </ul>
                    </div>

                    <!-- All products -->
                    <div class="el-section">
                      <!-- 체크박스 -->
                      <div class="mb-40">
                        <label class="inp-chk">
                          <input type="checkbox" id="allCheck" v-model="allSelected" @click="selectedAll()" />
                          <span class="big-ch-txt">{{ $t("partnersPortal.document.ALL") }}</span>
                        </label>
                        <span class="gray-line">|</span>
                        <!-- <span>Download Selected</span> -->
                        <button type="button" class="btn btn-text btn-text-md" @click="SelectFileDown()">
                          <span>{{ $t("partnersPortal.document.downSelect") }}</span>
                        </button>
                      </div>
                      <div>
                        <div class="list-type-view row-04">
                          <ul class="ul-type">
                            <li v-for="(content, index) in contentList">
                              <div class="over">
                                <div class="img-box">
                                  <a href="javascript:void(0)" tabindex="0" >
                                    <img :src='$imagePath + content.attribute.doc_img'>
                                  </a>
                                </div>
                              </div>

                              <div class="info">
                                <div class="thumb-tit">{{ content.content.title }}</div>
                                <div class="lang-box" v-if="content.docList && content.docList.length > 0">
                                  <div class="sel-box lang-select">
                                    <button type="button" class="btn-seg btn-sel">
                                      <span>{{ content.clickDocLabel }}</span>
                                    </button>
                                    <div class="sel-list" style="display: none">
                                      <ul class="box">
                                        <li v-for="doc in content.docList" @click="docChange(doc.label, doc.file, doc.type, index)">
                                          <button type="button">{{ doc.label }}</button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="btn-down-dgray" @click="download(content.clickDocFile, content.content.id)"><i class="icon-download-dgray"></i></div>
                                </div>
                                <label class="inp-chk">
                                  <input type="checkbox" name="conBox" :id="'content_'+(index+1)" @change="checkboxSelected(content.content.id, (index+1))">
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="pagination">
                        <ul class="ul-type">
                          <li class="btn btn-first"><button type="button" @click="hasIndex(firstNumber)">first page</button></li>
                          <li class="btn btn-prev"><button type="button" @click="hasIndex((pageIndex-1))">prev</button></li>
                          <li class="btn-num" :id="'index_'+index" v-for="(index) in pageList" :data-chk="index" @click="hasIndex(index)"><button type="button"><span>{{ index }}</span></button></li>
                          <li class="btn btn-next"><button type="button" @click="hasIndex(pageIndex+1)">next</button></li>
                          <li class="btn btn-last"><button type="button" @click="hasIndex(lastNumber)">last page</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //product Finder -->

            </div>
            <!-- //DOCUMENTS TO DOWNLOAD -->

          </div>
        </div>
      </div>
      <!-- //container (HIMACS Partners Portal) -->

    </div>
    <!-- //content -->
    <FooterComponent></FooterComponent>
  </div><!-- //wrap -->
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import categoryService from '@/server/category.service'
import ProductService from '@/server/product.service'
import _ from '@/common/helper/lodash'
import ProsService from '@/server/pros.service'
import { element } from 'screenfull'
import global from '@/common/global'
import memberInfoService from '@/server/myPage.service'
import path from 'path'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      contentSortBy: this.$t('partnersPortal.document.sortby'),
      readingList: [],
      countryCode: '',
      typeId: '',
      categoryId: '',
      subCateId: '',
      endId: '',
      typeList: [],
      cateList: [],
      subCateList: [],
      cateIdList: [],
      subParentId: '',
      checkedType: [],
      memberType: [],
      checkedValues: [],
      filterList: [],
      allSelected: false,
      dictionaryValues: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: [
            ]
          }
        ]
      },
      contentList: [],
      // pagination
      totalCount: '',
      totalPage: '',
      pageIndex: 1,
      pageSize: 12,
      pageList: [],
      firstNumber: 1,
      lastNumber: 10,
      orderField: [],
      orderType: [],

      userEmail: '',
      memGroup: '',
      memId: ''
    }
  },
  async created () {
    const _this = this
    _this.countryCode = this.$countryCode

    const res = await memberInfoService.getEuMemberType()
    if (res.data.code === 401) {
      this.$utils.goLoginEU()
    } else {
      _this.memId = res.data.user.id
      _this.userEmail = res.data.user.memId
      _this.memGroup = res.data.user.memGroup
      res.data.groups.forEach((type, idx) => {
        _this.dictionaryValues.list[0].values.push({
          id: 'mem_type',
          value: type.rollId
        })
      })
    }
  },
  async mounted () {
    const _this = this
    const categoryCode = 'PROS_PORTAL' + _this.countryCode
    const category = await categoryService.getCategoryCode(categoryCode) /* 카테고리 id 탐색 */
    _this.typeId = category.data.object.id // document type list 를 위한 아이디
    const contentList = await ProductService.getDictionaryCondition([_this.typeId], _this.dictionaryValues, '', _this.pageIndex, 10, ['createdAt'], ['desc'], ['title', 'search_text'])
    _this.readingList = contentList.data.list
    const typeList = await categoryService.getCategoryAttributeList(_this.typeId)
    _this.typeList = typeList.data.list
    _this.categoryId = _this.typeList[0].category.id // document type 아이디
    const categoryList = await categoryService.getCategoryAttributeList(_this.categoryId)
    _this.cateList = categoryList.data.list
    _this.subCateId = _this.cateList[0].category.id // category 아이디
    _this.subParentId = _this.subCateId // category 아이디를 초기화할때 사용
    const subCateList = await categoryService.getCategoryAttributeList(_this.subCateId)
    _this.subCateList = subCateList.data.list
    _this.cateIdList.push(_this.subCateId)

    _this.$nextTick(async function () {
      await _this.pagination()
    })
  },
  methods: {
    seeAll () {
      $('#btn-tab01').removeClass('on')
      $('#inner01').removeClass('on')
      $('#btn-tab02').addClass('on')
      $('#inner02').addClass('on')
      $('#w-con-02').addClass('w100p')
    },
    subDelete (id) {
      const _this = this
      $('#sub_' + id).prop('checked', false)
      _this.filterList = _this.filterList.filter((element) => element.id !== id)
      _this.cateIdList = _this.cateIdList.filter((element) => element !== id)
      _this.pagination()
    },
    async docTypeClick (content) {
      const _this = this
      _this.cateIdList = []
      $('#textSearch').val('')
      if (content.parents.length == 4) {
        _this.categoryId = content.parents[1].id // document type 아이디
        _this.subCateId = content.parents[0].id // category 아이디

        const categoryList = await categoryService.getCategoryAttributeList(content.parents[1].id) // category List 탐색
        _this.cateList = categoryList.data.list
        _this.cateIdList.push(content.parents[0].id)
        $('#type_' + content.parents[1].id).prop('checked', true)
        $('#cate_' + content.parents[0].id).prop('checked', true)
      } else {
        _this.categoryId = content.parents[2].id // document type 아이디
        _this.subCateId = content.parents[1].id // category 아이디
        _this.endId = content.parents[0].id // sub category 아이디
        const categoryList = await categoryService.getCategoryAttributeList(content.parents[2].id) // category List 탐색
        _this.cateList = categoryList.data.list
        const subCateList = await categoryService.getCategoryAttributeList(content.parents[1].id) // sub category List 탐색
        _this.subCateList = subCateList.data.list
        _this.cateIdList.push(content.parents[0].id)
        $('#type_' + content.parents[2].id).prop('checked', true)
        $('#cate_' + content.parents[1].id).prop('checked', true)
      }
      _this.$nextTick(function () {
        $('#btn-tab01').removeClass('on')
        $('#btn-tab02').addClass('on')
        $('#inner01').removeClass('on')
        $('#inner02').addClass('on')
        _this.pagination()
      })
    },
    docChange (label, file, type, index) {
      const _this = this

      _this.contentList[index].clickDocLabel = label
      _this.contentList[index].clickDocFile = file
      _this.contentList[index].clickType = type

      _this.$forceUpdate()
    },
    async subCheck (id, index, name) {
      const _this = this
      if (_this.subParentId == _this.cateIdList[0]) {
        _this.cateIdList = []
      }
      if ($('#sub_' + id).is(':checked')) {
        _this.filterList.push({ id: id, name: name })
        _this.cateIdList.push(id)
      } else {
        _this.cateIdList = _this.cateIdList.filter((element) => element !== id)
        _this.filterList = _this.filterList.filter((element) => element.id !== id)
        if (_this.cateIdList.length == 0) {
          _this.cateIdList.push(_this.subParentId)
        }
      }
      await _this.pagination()
    },
    async allTypeDelete () { // type 검색 조건 전체 삭제 이벤트
      const _this = this
      _this.cateIdList = []
      $("[name*='subCate']").prop('checked', false)
      _this.cateIdList.push(_this.subParentId)
      _this.filterList = []
      await _this.pagination()
    },
    async typeChange (id) {
      const _this = this
      _this.cateIdList = []
      const categoryList = await categoryService.getCategoryAttributeList(id)
      _this.cateList = categoryList.data.list
      _this.subCateId = _this.cateList[0].category.id
      const subCateList = await categoryService.getCategoryAttributeList(_this.subCateId)
      _this.cateIdList.push(_this.subCateId)
      _this.subCateList = subCateList.data.list
      _this.filterList = []
      $("[name*='subCate']").prop('checked', false)
      $("[id*='cate_']").prop('checked', false)
      $('#cate_' + _this.cateList[0].category.id).prop('checked', true)
      await _this.pagination()
    },
    async cateChange (id) {
      const _this = this
      _this.cateIdList = []
      const subCateList = await categoryService.getCategoryAttributeList(id)
      _this.subCateList = subCateList.data.list
      _this.subParentId = id
      _this.cateIdList.push(id)
      _this.filterList = []
      $("[name*='subCate']").prop('checked', false)
      await _this.pagination()
    },
    async hasIndex (index) { // 페이지 이동 이벤트
      const _this = this
      if (index > 1) {
        if (index <= _this.totalPage) {
          _this.pageIndex = index
        }
      } else {
        _this.pageIndex = 1
      }
      await _this.pagination()
    },
    async search () { // 검색 이벤트
      const _this = this
      _this.pageIndex = 1
      await _this.pagination()
    },
    async pagination (type) { // 컨텐츠 페이징 이벤트
      const _this = this
      const word = $('#textSearch').val() // 검색어
      // 컨텐츠 리스트 api
      const contentList = await ProductService.getDictionaryCondition(_this.cateIdList, _this.dictionaryValues, word, _this.pageIndex, _this.pageSize, _this.orderField, _this.orderType, ['title', 'search_text'])
      _this.contentList = contentList.data.list
      _this.contentList.forEach((content, idx) => {
        const docList = []
        if (_this.countryCode == '_UK') {
          if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
            docList.push({ label: this.$t('partnersPortal.document.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk' })
          }
        } else {
          if (!_.isEmpty(content.attribute.doc_file)) {
            docList.push({ label: this.$t('partnersPortal.document.EN'), file: content.attribute.doc_file, type: 'doc_file' })
          }
        }
        if (!_.isEmpty(content.attribute.doc_file_es) && !_.isUndefined(content.attribute.doc_file_es)) {
          docList.push({ label: this.$t('partnersPortal.document.ES'), file: content.attribute.doc_file_es, type: 'doc_file_es' })
        }
        if (!_.isEmpty(content.attribute.doc_file_de) && !_.isUndefined(content.attribute.doc_file_de)) {
          docList.push({ label: this.$t('partnersPortal.document.DE'), file: content.attribute.doc_file_de, type: 'doc_file_de' })
        }
        if (!_.isEmpty(content.attribute.doc_file_fr) && !_.isUndefined(content.attribute.doc_file_fr)) {
          docList.push({ label: this.$t('partnersPortal.document.FR'), file: content.attribute.doc_file_fr, type: 'doc_file_fr' })
        }
        if (!_.isEmpty(content.attribute.doc_file_it) && !_.isUndefined(content.attribute.doc_file_it)) {
          docList.push({ label: this.$t('partnersPortal.document.IT'), file: content.attribute.doc_file_it, type: 'doc_file_it' })
        }
        if (!_.isEmpty(content.attribute.doc_file_nl) && !_.isUndefined(content.attribute.doc_file_nl)) {
          docList.push({ label: this.$t('partnersPortal.document.NL'), file: content.attribute.doc_file_nl, type: 'doc_file_nl' })
        }
        if (_this.countryCode == '_UK') {
          if (!_.isEmpty(content.attribute.doc_file)) {
            docList.push({ label: this.$t('partnersPortal.document.EN'), file: content.attribute.doc_file, type: 'doc_file' })
          }
        } else {
          if (!_.isEmpty(content.attribute.doc_file_uk) && !_.isUndefined(content.attribute.doc_file_uk)) {
            docList.push({ label: this.$t('partnersPortal.document.UK'), file: content.attribute.doc_file_uk, type: 'doc_file_uk' })
          }
        }
        content.docList = docList
        content.clickDocLabel = docList[0].label
        content.clickType = docList[0].type
        content.clickDocFile = docList[0].file
      })

      _this.totalPage = contentList.data.totalPage // 전체 페이지 수
      _this.totalCount = contentList.data.totalCount // 전체 컨텐츠 수
      const pageGroup = Math.ceil(_this.pageIndex / 10)
      let lastBottomNumber = pageGroup * 10 // 하단 마지막 숫자
      const first = lastBottomNumber - 9
      if (lastBottomNumber > _this.totalPage) {
        lastBottomNumber = _this.totalPage // 총 갯수보다 큰 경우 방지
      }
      _this.firstNumber = first
      _this.lastNumber = lastBottomNumber
      var list = []
      for (var i = _this.firstNumber; i <= _this.lastNumber; i++) {
        list.push(i)
      };
      _this.pageList = list

      _this.$nextTick(function () {
        $('.btn-num').removeClass('on')
        $('#index_' + _this.pageIndex).addClass('on')
        if (type == 'start') {
          $('#allCheck').prop('checked', false)
          $("input:checkbox[name='conBox']").prop('checked', false)
        }
      })
      activeArrowTab() // gray tab arrow active
    },
    async sortByChange (text, type, field) { // 정렬 방식 변경 이벤트
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      const _this = this
      _this.contentSortBy = text
      _this.orderType = []
      _this.orderField = []
      _this.orderType.push(type)
      if (field != null) {
        _this.orderField.push(field)
      }
      await _this.pagination()
    },
    async download (file, id) { // 파일 다운로드 이벤트
      await ProsService.getClickUp(id)
      let url = ''
      url = this.$imagePath + file
      const path = require('path')
      const fileName = path.basename(file)
      function toDataURL (url, callback) {
        var xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            callback(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      }
      toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', fileName)
        document.body.appendChild(element)
        element.click()
      })
    },
    selectedAll () {
      if (this.allSelected) {
        $('input:checkbox[name=conBox]').prop('checked', false)
      } else {
        $('input:checkbox[name=conBox]').prop('checked', true)
        for (var i = 0; i < this.contentList.length; i++) {
          this.checkedValues.push(this.contentList[i].content.id)
        }
      }
    },
    checkboxSelected (id, index) {
      const _this = this
      if ($('#content_' + index).is(':checked')) {
        _this.checkedValues.push(id)
      } else {
        _this.checkedValues = _this.checkedValues.filter((element) => element !== id)
      }
      const checkBoxTotal = $('input:checkbox[name=conBox]').length
      const checkedCount = $('input:checkbox[name=conBox]:checked').length
      if (checkBoxTotal == checkedCount) {
        $('#allCheck').prop('checked', true)
      } else {
        $('#allCheck').prop('checked', false)
      }
    },
    async logout () {
      // GTM - logout
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({
          event: 'logoutAction'
        })
      }

      this.$store.dispatch('auth/logout', { id: this.memId, vm: this }).then(() => {
        setTimeout(() => {
          location.href = 'index'
        }, 700)
      })
    },
    async SelectFileDown () {
      const _this = this
      _this.checkedType = []
      if (_this.checkedValues.length == 0) {
        alert("There's no file to download.")
      } else {
        _this.contentList.forEach((content, idx) => {
          if ($('#content_' + (idx + 1)).is(':checked')) {
            _this.checkedType.push(content.clickType + '-' + (idx + 1))
          } else {
            _this.checkedType = _this.checkedType.filter((element) => element !== content.clickType + '-' + (idx + 1))
          }
        })
        ProsService.zipFileDownload(_this.checkedValues, _this.checkedType, _this.countryCode)
      }
    }
  }
}

// 2022-03-16 탭 스크립트 추가
$(document).ready(function () {
  $('.btn-tab').on('click', function () {
    if ($(this).hasClass('wide') === true) {
      $(this).parent().parent().parent().addClass('w100p')
    } else {
      $(this).parent().parent().parent().removeClass('w100p')
    }
  })
})
</script>
