<template>
  <iframe style="display:block; width:100vw; height: 100vh;" src="https://indd.adobe.com/embed/20e91402-3510-406c-a7d4-854c65c423d2?startpage=1&allowFullscreen=false" frameborder="0" allowfullscreen=""></iframe>
</template>
<script>
export default ({
  name: 'prime-en',
  data () {
    return {}
  },
  mounted () {
  },
  methods: {}
})
</script>
