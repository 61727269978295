<template>
  <div class="social-responsibility-table-body">
    <div class="body-category">
      <SocialResponsibilityTableBodyCategory
      :text="categoryText"
      />
    </div>
    <div class="body-content">
      <div class="content-line"
      v-for="(item, index) in bodyCells"
      :key="index"
      >
        <div class="text-area">
          <SocialResponsibilityTableBodyCell
          :text="item.text"
          />
        </div>
        <div class="desc-text-area">
          <SocialResponsibilityTableBodyCell
          :text="item.descText"
          />
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import SocialResponsibilityTableBodyCategory from './SocialResponsibilityTableBodyCategory.vue'
import SocialResponsibilityTableBodyCell from './SocialResponsibilityTableBodyCell.vue'
export default {
  components: {
    SocialResponsibilityTableBodyCategory,
    SocialResponsibilityTableBodyCell
  },
  props: {
    category: {
      type: Object
    },
    bodyCells: {
      type: Array
    }
  },
  data: function () {
    return {
      categoryText: this.category.text
    }
  }

}
</script>

<style>
.social-responsibility-table-body {
  display: flex;
}

.social-responsibility-table-body .body-category {
  min-width: 231px;
  display: flex;
}

.social-responsibility-table-body .body-content {
  flex-direction: column;
  display: flex;
}

.social-responsibility-table-body .body-content .content-line {
  display: flex;
}

.social-responsibility-table-body .body-content .content-line .text-area {
  min-width: 340px;
}

.social-responsibility-table-body .body-content .content-line .desc-text-area {
  min-width: 868px;
}
</style>
