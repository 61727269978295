/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */
import Vue from 'vue'
import Vuex from 'vuex'

import createLogger from 'vuex/dist/logger'

// Modules
import account from '@/common/store/modules/account'
import auth from '@/common/store/modules/auth'
import order from '@/common/store/modules/order'
import search from '@/common/store/modules/search'
import header from '@/common/store/modules/header'
import imglib from '@/common/store/modules/imglib'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    account,
    auth,
    order,
    search,
    header,
    imglib
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug ? [createLogger()] : []
})
