<template>
  <div class="main-box new_events">
    <div class="tit_area">
      <h2 class="tit-main">WHAT’S NEW</h2>
      <!-- <p>Watch Hausys' Latest News</p> -->
    </div>
    <div class="newevent_slider_wrap">
      <div class="custom_btn_area">
        <button class="btn_slick_prev new_prev" aria-label="Previous" type="button">Previous</button>
        <button class="btn_slick_next new_next" aria-label="Next" type="button">Next</button>
      </div>
      <div class="center_slider">
        <div class="item" v-for="(newEvent, index) in newEventList" :value="newEvent.content.title" @click="handleImageClick(newEvent, index)">
          <div class="img">
              <img :src="$imagePath+newEvent.attribute.MAIN_NEWS_IMAGE" class="new_events_img" alt="new event">
          </div>
          <div class="contents">
            <div class="sort">{{ newEvent.attribute.MAIN_NEWS_CATEGORYText }}</div>
            <div class="description">
              <p class="des1">{{ newEvent.content.title }}</p>
              <p class="des2">{{ newEvent.attribute.MAIN_NEWS_SUB_TITLE }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainService from '@/server/main.service'

export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      newEventId: '',
      newEventList: [],
      countryCode: ''
    }
  },
  async mounted () {
    const _this = this

    const nationCode = _this.$countryCode
    _this.countryCode = nationCode
    _this.categoryCode = 'MAIN_NEWS' + nationCode

    const newEventRes = await mainService.getCategoryCode(_this.categoryCode)
    _this.newEventId = newEventRes.data.object.id

    const newEventList = await mainService.getContentListSort(_this.newEventId, 1, 10, 'sort', 'asc')
    _this.newEventList = newEventList.data.list

    _this.$nextTick(function () {
      $('.center_slider').slick({
        centerMode: true,
        centerPadding: '80px',
        slidesToShow: 3,
        // autoplay: true,
        // autoplaySpeed: 4000,
        draggable: true,
        variableWidth: true,
        waitForAnimate: false,
        arrows: false,
        gap: 132,
        speed: 0,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 1
            }
          }
        ]
      })
      // 슬라이더 버튼
      $('.new_next').click(function () {
        $('.center_slider').slick('slickNext')
      })
      $('.new_prev').click(function () {
        $('.center_slider').slick('slickPrev')
      })
      // 드래그 시 클릭방지
      function blockWhileDragging(isDragging) {
        let slides = document.querySelectorAll(".center_slider .item");
          
        if (isDragging) {
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.add("dragging"); // is-dragging 클래스 추가
          }
        } else {
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.remove("dragging"); // is-dragging 클래스 제거
            }
        }
      }

      // mousedown 여부를 판단할 변수
      let isMouseDown = false;

      // mousedown 이벤트에서 isMouseDown을 true로 변환
      document.addEventListener("mousedown", () => {
        isMouseDown = true;
      });

      // mousemove 이벤트에서 isMouseDown을 판단해 blockWhileDragging 함수 실행
      document.addEventListener("mousemove", () => {
        if (isMouseDown) {
            blockWhileDragging(true);
          } else {
            blockWhileDragging(false);
          }
      });

      // mouseup 이벤트에서 mousedown 이전 상태로 초기화
      document.addEventListener("mouseup", () => {
        isMouseDown = false;
          blockWhileDragging(false);
      });
    })
  },
  methods: {
    handleImageClick (windowOpenValue, index) {
      const centerIndex = $('.center_slider').slick('slickCurrentSlide')

      if (index === centerIndex) {
        var windowOpen
        try {
          windowOpen = JSON.parse(windowOpenValue.attribute.MAIN_NEWS_WINDOW)
        } catch (error) {
          // 파싱 실패나 값이 없을 경우 기존창으로 강제 셋팅
          windowOpen = { value: '002' } // 기본값 설정
        }

        if (!windowOpenValue.attribute.MAIN_NEWS_URL) {
          return // Exit the function early if URL is empty
        }

        console.log(windowOpen.value)
        var value = windowOpen.value

        if (value === '001') {
          // 기존 창에서 열기
          window.open(windowOpenValue.attribute.MAIN_NEWS_URL, '_self') // '기존 창 URL'을 적절히 변경해주세요.
        } else if (value === '002') {
          // 새 창에서 열기
          window.open(windowOpenValue.attribute.MAIN_NEWS_URL, '_blank') // '새 창 URL'을 적절히 변경해주세요.
        }
      }
    }
  }
}
</script>
