var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [_vm._m(0), _vm._t("default"), _vm._m(1)], 2)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "open-line-area" }, [
      _c("img", {
        staticClass: "open-line",
        attrs: {
          src: "images/aboutus/sustainability/esg_strategy_system/esg_open_line.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "close-line-area" }, [
      _c("img", {
        staticClass: "close-line",
        attrs: {
          src: "images/aboutus/sustainability/esg_strategy_system/esg_close_line.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }