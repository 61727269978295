<template>
  <div>
    <progressComponent></progressComponent>
  <header class="scr-hder" v-bind:class="{
    main: link === '/' || link === '/' + countryType + '/index' || link === '/index' || link === '/index/' || link === '/' +  countryType + '/index/'
  , on : true
  , isEU : languageWrapper}">
  <!-- , hasSelectLang: country === '_GL' -->

    <!--class="scr-hder" v-bind link != '/'+countryType+'/index', '' -->
    <!-- on scr-hder -->

    <!-- S : select language -->
<!--    <div class="wrapper-select-lang inner">
      <span class="notice-lang">{{$t('header.choose_lang')}}</span>
      <span class="sel-box sel-nation">
        <button type="button" class="btn-sel">
          <span :class="form.selectedNclass">{{form.selectedNation}}</span>
        </button>
        <div class="sel-list">
          <ul class="box">
              <li v-for="(item , idx) in form.nations" :key="item.val">
                <button type="button" :class="item.icoClass" @click="selectNation(item)">{{item.name}}</button>
              </li>
          </ul>
        </div>
      </span>
      <span class="sel-box sel-lang" :class="{disabled : form.languages[form.langKey].length === 1}">
          <button type="button" class="btn-sel"><span>{{form.selectedLanguage}}</span></button>
          <div class="sel-list">
              <ul class="box">
                  <li v-for="(item , idx) in form.languages[form.langKey]" :key="item.val">
                    <button type="button" @click="selectLanguage(item)">{{item.name}}</button>
                  </li>
              </ul>
          </div>
      </span>
      <button type="button" class="btn btn-go-lang" @click="gotoSite(form.selectedUrl)"><span>{{$t('header.go')}}</span></button>
      <button type="button" class="btn-close-lang" @click="closeHeaderLang()"></button>
    </div>-->
    <!-- E : select language -->

    <div class="top_bar">
      <ul>
        <li v-for="utility in utilityList" :value="utility.content.title">
          <a @click="openLink(utility)">{{ utility.content.title }}</a>
        </li>
        <li v-if="$countryCode == '_US'">
          <a href="javascript:roomvo.startStandaloneVisualizer('','');">Visualizer</a>
        </li>
      </ul>
    </div>

    <div class="clear">
      <div class="fl-l">
        <!-- 모바일 메뉴 오픈시 로고 사라짐 -->
        <div class="logo">
          <a href="index" class="go_home_for_pc">LX Hausys</a>
          <a href="index" class="go_home_for_mobile">LX Hausys</a>
        </div>
        <!-- 모바일 메뉴 오픈시 언어선택 나옴 -->
        <div class="icon_lang_wrap">
          <a id="btnLang" type="button" @click="handleHeaderLang()" class="icon_lang"></a>
          <!-- <a id="btnLang" type="button" class="icon_lang" v-on:click='selectLangPop = !selectLangPop'></a> -->
          <!-- 언어선택 팝업 -->
          <div class="select_lang_pop mobile_lang_pop">
            <div class="country_area">
              <ul>
                <li><a :class="{ 'on': currentRegion === 'gl' }"  href="/gl/index">Global</a></li>
                <!-- 글로벌일때 on 클래스 -->
                <li><a :class="{ 'on': currentRegion === 'us' }" href="/us/index">United States</a></li>
                <li><a :class="{ 'on': currentRegion === 'eu' }" v-on:click="selectEuLang=!selectEuLang">Europe</a></li>
                <li><a :class="{ 'on': currentRegion === 'uk' }" href="/uk-en/index">UK</a></li>
                <li><a :class="{ 'on': currentRegion === 'cn' }" href="http://lxhausys-cn.com/">China</a></li>
                <li><a :class="{ 'on': currentRegion === 'ru' }" href="https://lxhausys.com.ru/">Russia</a></li>
                <li><a :class="{ 'on': currentRegion === 'ru-himacs' }" href="https://hi-macs.ru/">Russia_HIMACS</a></li>
                <li><a :class="{ 'on': currentRegion === 'in' }" href="/gl/index">India</a></li>
                <li><a :class="{ 'on': currentRegion === 'ko' }" href="/gl/index">Korea</a></li>
              </ul>
            </div>
            <div class="lang_area" v-if="selectEuLang">
              <ul>
                <li><a class="on" href="/gl/europe/en">English</a></li>
                <li><a href="/gl/europe/fr">Francais</a></li>
                <li><a href="/gl/europe/de">Deutsch</a></li>
                <li><a href="/gl/europe/it">Italiano</a></li>
                <li><a href="/gl/europe/es">Espanol</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="lang_dimmed"></div>
      </div>

      <div class="fl-c">
        <ul class="menu wide-pc" v-bind:class="{ main : link == '/'+countryType+'/index.html' }">
          <li v-for="(tree, menuTreeIdx) in menuTree.data" :key="tree.id" :data-gnb="'mlist-' + tree.id">>
            <a href="javascript:void(0);">
              <span class="menu_text_1depth">{{ tree.title }}</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="fl-r">
        <div class="hd-btns01">
          <ul>
            <li>
              <a href="javascript:void(0);" @click="openSearch()" class="icon_search" title="Open Search Popup">
              </a>
            </li>
            <li class="" style="display: none;">
              <!-- US 일 때 -->
              <a v-if="country == '_US'" href="javascript:void(0);" class="icon_like" @click="gotoLikeUrl('like/header/headerLikes')">
                <span v-if="likeCnt > 0">{{likeCnt}}</span>
              </a>
              <!-- GL 일 때 -->
              <a v-else href="javascript:void(0);" class="icon_like" @click="gotoLikeUrl('like/header/headerLikesPD')">
                <span v-if="likeCnt > 0">{{likeCnt}}</span>
              </a>
            </li>

            <!-- CART -->
            <li v-if="country == '_US'">
              <a href="javascript:void(0);" class="icon_cart bubble_on" @click="openCart()">
                <span v-if="cartCount < 100"  class="bubble_num">
                  <span>{{cartCount}}</span>
                </span>
                <span v-else class="bubble_num">
                  <span>99</span>
                  <span>+</span>
                </span>
              </a>
            </li>

            <!-- S : Image Library Icon -->
            <li class="p-ui" v-if="country !== '_US' && country !== '_GL'">
              <a href="javascript:void(0);" class="ico-11" @click="gotoUrl('imglib/imageLib')">
                <span v-if="imgLibCnt > 0">{{imgLibCnt}}</span>
              </a>
            </li>

            <!-- Pc view, US, GL where To buy -->
            <!-- <li v-if="country === '_US' || country === '_GL'">
              <a href="javascript:void(0);" class="ico-05" @click="gotoUrl('support/where-to-buy/country/' + $countryCode.substring(1).toLowerCase())">
              </a>
            </li> -->

            <!-- mobile view EU, UK where To buy  -->
            <li v-if="country !== '_US' && country !== '_GL'">
              <a href="javascript:void(0);" class="ico-05" @click="gotoUrl('support/where-to-buy/country/' + $countryCode.substring(1).toLowerCase())">
              </a>
            </li>

            <!-- 언어선택 시작 -->
            <li class="icon_lang_wrap" ref="langetc">
              <a id="btnLang" type="button" @click="handleHeaderLang()" class="icon_lang" v-on:click="selectEuLang=false"></a>
              <!-- <a id="btnLang" type="button" class="icon_lang" v-on:click='selectLangPop = !selectLangPop'></a> -->
              <!-- 언어선택 팝업 -->
              <div class="select_lang_pop">
                <div class="country_area">
                  <ul>
                    <li><a :class="{'on': country === '_GL'}" href="https://www.lxhausys.com/gl/index">Global</a></li>
                    <!-- 글로벌일때 on 클래스 -->
                    <li><a :class="{'on': country === '_US'}" href="https://www.lxhausys.com/us/index">United States</a></li>
                    <li><a v-on:click="selectEuLang=!selectEuLang">Europe</a></li>  <!-- 4개 있는거 뭔지 몰라도 다 걸어줘야됨-->
                    <li><a href="/gl/uk-hflor">UK</a></li>
                    <li><a href="http://lxhausys-cn.com/">China</a></li>
                    <li><a href="https://lxhausys.com.ru/">Russia</a></li>
                    <li><a href="https://hi-macs.ru/">Russia_HIMACS</a></li>
                    <li><a href="https://www.lxhausys.com/gl/index">India</a></li>
                    <li><a href="https://www.lxzin.com/">Korea</a></li>
                  </ul>
                </div>
                <div class="lang_area" v-if="selectEuLang">
                  <ul>
                    <li><a href="/gl/europe/en">English</a></li>
                    <li><a href="/gl/europe/fr">Francais</a></li>
                    <li><a href="/gl/europe/de">Deutsch</a></li>
                    <li><a href="/gl/europe/it">Italiano</a></li>
                    <li><a href="/gl/europe/es">Espanol</a></li>
                  </ul>
                </div>
              </div>
            </li>

            <li id="m-menu">
              <a class="icon-hamburger" @click="mobile_gnbSlideDown()"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <SearchPopComponent></SearchPopComponent>
    <CartPopComponent></CartPopComponent>
  </header>
  </div>
  <!-- //header -->
</template>

<script>
import { $utils } from '@/common/helper'
import _ from '@/common/helper/lodash'
import CartPopComponent from '@/components/Popup/Cart'
import SearchPopComponent from '@/components/Popup/Search'
import mainService from '@/server/main.service'
import menuService from '@/server/menu.service'
import memberInfoService from '@/server/myPage.service'
import { mapGetters, mapState } from 'vuex'
import progressComponent from '@/views/index/component/progressComponent.vue'

export default {
  name: 'HeaderComponent',
  components: {
    progressComponent,
    CartPopComponent,
    SearchPopComponent
  },
  data () {
    return {
      menuTree: [],
      utilityList: [],
      selectEuLang: false, // 유럽 언어 클릭시 열림
      link: document.location.pathname,
      width: 390,
      country: null,
      countryType: '',
      currentRegion: this.getCurrentRegion(),
      // selectLangPop: false, // 언어선택 팝업
      form: {
        selectedNation: '',
        selectedNclass: '',
        nations: [
          { name: 'Global', val: 'GL', icoClass: 'simbol_nation_gl' },
          { name: 'United States', val: 'US', icoClass: 'simbol_nation_us' },
          { name: 'Europe', val: 'EU', icoClass: 'simbol_nation_eu' },
          // { name: 'Europe_HFLOR', val: 'EU_HF', icoClass: 'simbol_nation_eu' }, // open 시 삭제
          { name: 'UK', val: 'UK', icoClass: 'simbol_nation_uk' },
          { name: 'China', val: 'CN', icoClass: 'simbol_nation_cn' },
          { name: 'Russia', val: 'RU', icoClass: 'simbol_nation_rs' },
          { name: 'Russia_HIMACS', val: 'RU_HI', icoClass: 'simbol_nation_rs' },
          { name: 'India', val: 'IN', icoClass: 'simbol_nation_in' }
          // { name: 'Turkey_HFLOR', val: 'TK', icoClass: 'simbol_nation_tk' }
        ],
        selectedLanguage: '',
        langKey: '',
        languages: {
          GL: [
            { name: 'English', val: 'GL', url: '/gl/index' }
          ],
          US: [
            { name: 'English', val: 'US', url: '/us/index' }
          ],
          EU: [
            // { name: 'English', val: 'EN', url: '/eu-en/index' },
            // { name: 'Français', val: 'FR', url: '/eu-fr/index' },
            // { name: 'Deutsch', val: 'DE', url: '/eu-de/index' },
            // { name: 'Italiano', val: 'IT', url: '/eu-it/index' },
            // { name: 'Español', val: 'ES', url: '/eu-es/index' }
            // { name: 'English', val: 'EN', url: 'http://himacs.eu/en' },
            // { name: 'Français', val: 'FR', url: 'http://himacs.eu/fr' },
            // { name: 'Deutsch', val: 'DE', url: 'http://himacs.eu/de' },
            // { name: 'Italiano', val: 'IT', url: 'http://himacs.eu/it' },
            // { name: 'Español', val: 'ES', url: 'http://himacs.eu/es' }
          ],
          EU_HF: [
            { name: 'English', val: 'EU_HF', url: 'https://www.lxhausys.com/eu-en' }
          ],
          UK: [
            // { name: 'English', val: 'US', url: '/uk-en/index' }
            // { name: 'English', val: 'UK', url: 'https://www.lghausys-floors.co.uk' }
          ],
          RU: [
            { name: 'русский язык', val: 'RU', url: 'https://lxhausys.com.ru/' }
          ],
          RU_HI: [
            { name: 'русский язык', val: 'RU_HI', url: 'https://hi-macs.ru/' }
          ],
          IN: [
            { name: 'English', val: 'IN', url: '/gl/index' }
          ],
          CN: [
            { name: '中文', val: 'CN', url: 'http://lxhausys-cn.com' }
          ],
          TK: [
            { name: 'Turkish', val: 'TK', url: 'http://www.hflor.com' }
          ]
        },
        selectedUrl: ''
      }
    }
  },
  async created () {
    this.getMenuTrees()
    this.getMainUtility()

    const that = this
    const country = this.$countryCode
    let nation = ''
    this.country = country
    if (country === '_GL') {
      this.countryType = 'gl'
      this.form.langKey = 'GL'
      nation = 'GL'
    } else if (country === '_JP') {
      this.countryType = 'jp'
      this.form.langKey = 'JP'
      nation = 'JP'
    } else if (country === '_US') {
      this.countryType = 'us'
      this.form.langKey = 'US'
      nation = 'US'
    } else if (country === '_CA') {
      this.countryType = 'ca'
      this.form.langKey = 'CA'
      nation = 'CA'
    } else if (country === '_CF') {
      this.countryType = 'cf'
      this.form.langKey = 'CF'
      nation = 'CF'
    } else if (country === '_UK') {
      this.countryType = 'uk-en'
      this.form.langKey = 'UK'
      nation = 'UK'
    } else if (
      country === '_EU' ||
      country === '_EN' ||
      country === '_FR' ||
      country === '_DE' ||
      country === '_IT' ||
      country === '_ES') {
      this.countryType = 'eu-' + _.lowerCase(this.$countryCode.substring(1))
      this.form.langKey = 'EU'
      nation = 'EU'
    }
    // 서버 (stg, prd) 에 따른 EU languages 데이터 변경
    if (location.origin.includes('prd-gcms') ||
    location.origin.includes('localhost') || location.origin.includes('127.0.0.1')) {
      this.form.languages.EU = [
        { name: 'English', val: 'EN', url: '/europe/en' },
        { name: 'Français', val: 'FR', url: '/europe/fr' },
        { name: 'Deutsch', val: 'DE', url: '/europe/de' },
        { name: 'Italiano', val: 'IT', url: '/europe/it' },
        { name: 'Español', val: 'ES', url: '/europe/es' }
      ]
      this.form.languages.UK = [
        { name: 'English', val: 'UK', url: '/uk-hflor' }
      ]
      this.form.languages.CN = [
        { name: 'China', val: 'CN', url: '/cn' }
      ]
      this.form.languages.JP = [
        { name: 'Japan', val: 'JP', url: '/jp' }
      ]
      this.form.languages.CA = [
        { name: 'English', val: 'EN', url: '/ca-en' }
      ]
      this.form.languages.CF = [
        { name: 'Français', val: 'FR', url: '/ca-fr' }
      ]
    } else if (location.origin.includes('stg-gcms')) {
      this.form.languages.EU = [
        { name: 'English', val: 'EN', url: 'https://stg-gcms.lxhausys.com/eu/europe/en' },
        { name: 'Français', val: 'FR', url: 'https://stg-gcms.lxhausys.com/eu/europe/fr' },
        { name: 'Deutsch', val: 'DE', url: 'https://stg-gcms.lxhausys.com/eu/europe/de' },
        { name: 'Italiano', val: 'IT', url: 'https://stg-gcms.lxhausys.com/eu/europe/it' },
        { name: 'Español', val: 'ES', url: 'https://stg-gcms.lxhausys.com/eu/europe/es' }
      ]
      this.form.languages.UK = [
        { name: 'English', val: 'UK', url: 'https://stg-gcms.lxhausys.com/uk/uk-hflor' }
      ]
      this.form.languages.CN = [
        { name: 'China', val: 'CN', url: 'https://stg-gcms.lxhausys.com/cn' }
      ]
      this.form.languages.JP = [
        { name: 'Japan', val: 'JP', url: 'https://stg-gcms.lxhausys.com/jp' }
      ]
      this.form.languages.CA = [
        { name: 'English', val: 'EN', url: 'https://stg-gcms.lxhausys.com/ca-en' }
      ]
      this.form.languages.CF = [
        { name: 'Français', val: 'FR', url: 'https://stg-gcms.lxhausys.com/ca-fr' }
      ]
    } else {
      this.form.languages.EU = [
        { name: 'English', val: 'EN', url: 'https://www.lxhausys.com/eu/europe/en' },
        { name: 'Français', val: 'FR', url: 'https://www.lxhausys.com/eu/europe/fr' },
        { name: 'Deutsch', val: 'DE', url: 'https://www.lxhausys.com/eu/europe/de' },
        { name: 'Italiano', val: 'IT', url: 'https://www.lxhausys.com/eu/europe/it' },
        { name: 'Español', val: 'ES', url: 'https://www.lxhausys.com/eu/europe/es' }
      ]
      this.form.languages.UK = [
        { name: 'English', val: 'UK', url: 'https://www.lxhausys.com/uk/uk-hflor' }
      ]
      this.form.languages.CN = [
        { name: 'China', val: 'CN', url: 'https://www.lxhausys.com/cn' }
      ]
      this.form.languages.JP = [
        { name: 'Japan', val: 'JP', url: 'https://www.lxhausys.com/jp' }
      ]
      this.form.languages.CA = [
        { name: 'English', val: 'EN', url: 'https://www.lxhausys.com/ca-en' }
      ]
      this.form.languages.CF = [
        { name: 'Français', val: 'FR', url: 'https://www.lxhausys.com/ca-fr' }
      ]
    }

    // 접속지에 따른 nation 표시
    const fndNation = _.find(this.form.nations, function (v) {
      return nation === v.val
    })
    console.log(fndNation)
    this.form.selectedNation = fndNation.name
    this.form.selectedNclass = fndNation.icoClass
    // 선택된 Nation에 따른 language  표시
    const fndLang = _.find(this.form.languages[this.form.langKey], function (v) {
      return that.country.substring(1) === v.val
    })

    this.form.selectedLanguage = fndLang.name
    // nation 및 language 선택에 따른 url 배정
    this.form.selectedUrl = fndLang.url
  },
  mounted () {
    this.init()

    // GTM digtalData global varibale
    window.digitalData = window.digitalData || {}
    // GTM country info
    digitalData.pageInfo = {
      profile: {
        profileInfo: {
          behavior_country: _.upperCase(this.countryType)
        }
      }
    }

    // GTM - datalayer pageInfo
    if (typeof (dataLayer) !== 'undefined') {
      dataLayer.push({
        event: 'pageLoaded',
        pageFullUrl: location.href,
        behavior_country: _.upperCase(this.countryType)
      })
    }

    this.$nextTick(function () {
    })

    if ($('header').hasClass('isEU')) {
      setTimeout(function () {
        $('header').addClass('hasSelectLang')
      }, 4000)
    }

    // 스크롤 다운시 top_bar 숨김
    const top_bar = $('.top_bar')
    const gnb_wrap = $('.gnb-wrap')
    const pubHeader = $('.scr-hder')

    $(window).scroll(function () {
      if ($(window).scrollTop() == 0) {
        top_bar.removeClass('on')
        gnb_wrap.removeClass('on')
        pubHeader.removeClass('scroll_down')
        // pubHeader.removeClass('on');
      } else {
        top_bar.addClass('on')
        gnb_wrap.addClass('on')
        pubHeader.addClass('scroll_down')
        // pubHeader.addClass('on');
      }
    })

    // 메뉴 이외 영역 클릭시 닫힘
    $(document).on('click', 'body', function (e) {
      if (!$(e.target).parents().is('.gnb-header , .scr-hder')) {
        // console.log("영역 밖입니다");
        // $('.gnb-header').slideUp();
        $('.gnb-header').removeClass('on')
        $('.menu > li').removeClass('on')
      } else {
        // console.log("영역 안입니다");
      }
    })

    // 언어선택 이외 영역 클릭시 닫힘
    $(document).on('click', 'body', function (e) {
      if (!$(e.target).parents().hasClass('icon_lang_wrap')) {
      // console.log("영역 밖입니다");

      } else {
      // console.log("영역 안입니다");
      }
    })

    // 스크롤 이벤트 등록
    document.addEventListener('scroll', this.scrollEvents, true)
  },
  methods: {
    // 현재 접속한 리전 정보 가져오기
    getCurrentRegion () {
      const url = window.location.href
      if (url.includes('/gl/')) {
        return 'gl'
      } else if (url.includes('/us/')) {
        console.log('currentRegion is set to us')
        return 'us'
      } else if (url.includes('/ca/')) {
        return 'ca'
      } else if (url.includes('/uk/')) {
        return 'uk'
      } else if (url.includes('/cn/')) {
        return 'cn'
      } else if (url.includes('/ru/')) {
        return 'ru'
      } else if (url.includes('/hi-macs.ru/')) {
        return 'ru-himacs'
      } else if (url.includes('/in/')) {
        return 'in'
      } else if (url.includes('/jp/')) {
        return 'jp'
      } else if (url.includes('/ko/')) {
        return 'ko'
      }
    },
    openLink (windowOpenValue) {
      var windowOpen
      try {
        windowOpen = JSON.parse(windowOpenValue.attribute.MAIN_UTILITY_WINDOWS)
      } catch (error) {
        // 파싱 실패나 값이 없을 경우 기존창으로 강제 셋팅
        windowOpen = { value: '002' } // 기본값 설정
      }

      var value = windowOpen.value

      if (value === '002') {
        // 기존 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_UTILITY_URL, '_self') // '기존 창 URL'을 적절히 변경해주세요.
      } else if (value === '001') {
        // 새 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_UTILITY_URL, '_blank') // '새 창 URL'을 적절히 변경해주세요.
      }
    },
    async getMenuTrees () {
      const countryCodeReplace = this.$countryCode.replace(/^_/, '');
      this.menuTree = await menuService.getMenuTrees(countryCodeReplace);
    },
    async getMainUtility () {
      const _this = this

      const nationCode = _this.$countryCode
      _this.countryCode = nationCode
      _this.categoryCode = 'MAIN_UTILITY' + nationCode

      const categoryRes = await mainService.getCategoryCode(_this.categoryCode)
      const utilityId = categoryRes.data.object.id

      // 추후 USEYN true만  있는 API개발해서 넣어야됨 >> v-show로 현재 처리 해둠 LOS
      const utilityList = await mainService.getContentListSort(utilityId, 1, 10, 'sort', 'asc')
      _this.utilityList = utilityList.data.list
    },
    async init () {
      this.$store.dispatch('header/getTotalLikeCnt', { vm: this })
      this.$store.dispatch('header/getTotalImgLibCnt', { vm: this })
      this.$store.dispatch('account/find')
    },
    gotoUrl (url, isNewTab) {
      //   this.$router.push({ path: url })
      if (url.length > 0) {
        if (this.evaluate(isNewTab)) {
          window.open(url, '_blank')
        } else {
          window.location.href = url
        }
      }
      $('body').removeAttr('style')
    },
    gotoSite (url) {
      this.closeHeaderLang()
      this.gotoUrl(url)
    },
    async gotoLikeUrl (url, isNewTab) {
      const memberRes = await memberInfoService.getMember()
      if (memberRes.data.code === 401 && this.country == '_US') {
        this.$utils.goLogin()
      } else {
        window.location.href = url
      }
      $('body').removeAttr('style')
    },
    async logout () {
      // GTM - logout
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({
          event: 'logoutAction'
        })
      }
      // erase cart - US only
      if (this.$countryCode === '_US') {
        this.$store.commit('order/clearCartItem')// order store 클리어
      }
      // logout store action
      this.$store.dispatch('auth/logout', { id: this.loginData.id, vm: this }).then(() => {
        setTimeout(() => {
          location.href = 'index'
        }, 700)
      })
    },
    openCart () {
      // 카트 열릴때 모바일 메뉴 열려 있으면 닫기
      $('.m-gnb-container').removeClass('on')
      // 모바일메뉴 버튼 원래대로
      $('.icon-hamburger').removeClass('closed')
      this.$root.$refs.cartView.openPopup()
    },
    openSearch () {
      // 검색창 열릴때 모바일 메뉴 열려 있으면 닫기
      $('.m-gnb-container').removeClass('on')
      // 모바일메뉴 버튼 원래대로
      $('.icon-hamburger').removeClass('closed')
      hdSec.open('.sch-ui')
    },
    evaluate (val) {
      // "true", "false" 를 변환
      if (this.$_.isUndefined(val)) {
        return false
      } else {
        return eval(val)
      }
    },
    isEmpty (val) {
      return $utils.isEmpty(val)
    },
    mobile_gnbSlideDown () {
      const header = $('header')
      const mobile_menu = $('.m-gnb-container')
      const closed = $('.icon-hamburger')

      if (mobile_menu.hasClass('on')) {
        mobile_menu.removeClass('on')
        if ((header.hasClass('main')) && window.scrollY == 0) {
          // 메인에 스크롤0일때만 실행
          header.removeClass('on')
        }
        closed.removeClass('closed')
        $('body').removeAttr('style')
        $('.logo').removeClass('hidden')
        $('.icon_lang_wrap').removeClass('visible')
      } else {
        mobile_menu.addClass('on')
        header.addClass('on')
        closed.addClass('closed')
        $('body').css('overflow', 'hidden')
        $('.logo').addClass('hidden')
        $('.icon_lang_wrap').addClass('visible')
      }
    },
    selectNation (item) {
      // selectbox 동작
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      // 선택된 내용 세팅
      this.form.selectedNation = item.name // 국가선택
      this.form.selectedNclass = item.icoClass
      this.form.langKey = item.val // form.language 선택 변경
      const found = _.head(this.form.languages[this.form.langKey])
      this.form.selectedLanguage = found.name
      this.form.selectedUrl = found.url
    },
    selectLanguage (item) {
      // selectbox 동작
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()
      // 선택된 내용 세팅
      this.form.selectedLanguage = item.name
      this.form.selectedUrl = item.url
    },
    closeHeaderLang () {
      // $('header').removeClass('hasSelectLang')
      $('.icon_lang_wrap').removeClass('pop_open')
      $('.lang_dimmed').removeClass('on')
      // session storage에 language 선택창을 닫은 정보를 기억.
      // this.$store.commit('header/setLanguageWrapper')
    },
    openHeaderLang () {
      // session storage에 language 선택창을 닫은 정보를 기억.
      // $('header').addClass('hasSelectLang')
      $('.icon_lang_wrap').addClass('pop_open')
      $('.lang_dimmed').addClass('on')
      // this.$store.commit('header/removeLanguageWrapper')
    },
    handleHeaderLang () {
      // const classFlag = $('header').hasClass('hasSelectLang')
      const classFlag = $('.icon_lang_wrap').hasClass('pop_open')
      if (classFlag) {
        this.closeHeaderLang()
      } else {
        this.openHeaderLang()
      }
    },
    scrollEvents (e) {
      // console.log("스크롤될때마다 이 함수 실행?",e)
      $('.icon_lang_wrap').removeClass('pop_open')
      $('.lang_dimmed').removeClass('on')
    }
  },
  computed: {
    ...mapGetters({
      imgLibCnt: 'header/getImgLibCnt',
      likeCnt: 'header/getLikeCnt',
      languageWrapper: 'header/getLanguageWrapper',
      loginData: 'account/getLoginStatus'
    }),
    ...mapState({
      cartList: state => state.order.cartList
    }),
    cartCount () {
      return this.cartList.length
    }
  }
}
</script>
