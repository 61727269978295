<template>
  <OverviewSection
    title="Achievements at Design Awards"
    color="#222"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <AchievementsAtDesignAwardsCard
        :number="cardData.number"
        :text="cardData.text"
        :itemDatas="cardData.itemDatas"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="デザイン賞での実績"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <AchievementsAtDesignAwardsCard
        :number="cardData.number"
        :text="cardData.textJp"
        :itemDatas="cardData.itemDatas"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Достижения на премии Design Awards"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <AchievementsAtDesignAwardsCard
        :number="cardData.number"
        :text="cardData.textRu"
        :itemDatas="cardData.itemDatas"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="所获设计奖项"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <AchievementsAtDesignAwardsCard
        :number="cardData.number"
        :text="cardData.textCn"
        :itemDatas="cardData.itemDatas"
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import AchievementsAtDesignAwardsCard from './AchievementsAtDesignAwardsCard.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    AchievementsAtDesignAwardsCard
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardData: {
        number: '59',
        text: '(As of July 2022)',
        textJp: '(2022年7月現在)',
        textRu: '(Июль 2022 года)',
        textCn: '(截至2022年7月)',
        itemDatas: [
          {
            image: 'images/aboutus/r-and-d-center/winning-design-awards/awards-item-image1.png',
            itemNumber: '37'
          },
          {
            image: 'images/aboutus/r-and-d-center/winning-design-awards/awards-item-image2.png',
            itemNumber: '14'
          },
          {
            image: 'images/aboutus/r-and-d-center/winning-design-awards/awards-item-image3.png',
            itemNumber: '8'
          }
        ]
      }
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>

</style>
