var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vinyl-coated-material-storng-area" },
    _vm._l(_vm.strongInfo, function (it, idx) {
      return _c(
        "div",
        { key: idx, staticClass: "item", class: _vm.getItemClassByIndex(idx) },
        [
          _c("ProductStrongPoint", {
            attrs: {
              iconSrc: it.iconSrc,
              title: it.title,
              description: it.description,
              type: "vertical",
              titleFontSize: "24px",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }