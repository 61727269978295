<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content cntu-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home"></li>
          <li>Disclaimer</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Rendering/Image Disclaimer) -->
      <div class="container cntu-box">
        <div class="w-con-02">
          <div class="inner">
            <div class="sub-visual-section">
              <h1 class="tt-1 visual-title tac">RESPIRABLE SILICA WARNING</h1>
              <div class="tableArea tbl-box type09">
                <table>
                  <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                  </colgroup>
                  <thead>
                  <tr>
                    <th scope="row">Product</th>
                    <th scope="row">Chemical</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="ctt ctt-t3">VIATERA and TERACANTO</td>
                    <td class="ctt ctt-t3">Silica, crystalline (airborne particles of respirable size)</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- <p class="ctt ctt-t2 mb-88"></p> -->
            </div>
            <div class="t-type">
              <!-- <p class="tt-24">{{$t('privacy.text.FF0020_005')}}</p> -->
              <p class="ctt ctt-t2 tac">
                <span class="ctt ctt-t2 pc-br">[WARNING]</span>
                <span class="ctt ctt-t2 pc-br">Grinding, cutting, and polishing this material can release airborne respirable (breathable) silica which is a known health hazard. See OSHA/NIOSH Hazard Alert <a style="all: unset; cursor: pointer;" href="https://www.osha.gov/silica-crystalline/general-industry-maritime#gi_maritime_resources">https://www.osha.gov/silica-crystalline/general-industry-maritime#gi_maritime_resources</a></span>
              </p>
            </div>
          </div>
          <div class="inner">
            <div class="sub-visual-section">
              <h1 class="tt-1 visual-title tac">PROPOSITION 65</h1>
              <div class="tableArea tbl-box type09">
                <table>
                  <colgroup>
                    <col style="width:20%">
                    <col style="width:80%">
                  </colgroup>
                  <thead>
                  <tr>
                    <th scope="row">Product</th>
                    <th scope="row">Chemical</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="ctt ctt-t3">HIMACS and VIATERA</td>
                    <td class="ctt ctt-t3">Carbon black (airborne, unbound particles of respirable size)</td>
                  </tr>
                  <tr>
                    <td class="ctt ctt-t3">HIMACS and VIATERA</td>
                    <td class="ctt ctt-t3">Titanium dioxide (airborne, unbound particles of respirable size)</td>
                  </tr>
                  <tr>
                    <td class="ctt ctt-t3">VIATERA and TERACANTO</td>
                    <td class="ctt ctt-t3">Silica, crystalline (airborne particles of respirable size)</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- <p class="ctt ctt-t2 mb-88"></p> -->
            </div>
            <div class="t-type">
              <!-- <p class="tt-24">{{$t('privacy.text.FF0020_005')}}</p> -->
              <p class="ctt ctt-t2 tac">
                <span class="ctt ctt-t2 pc-br">[WARNING]</span>
                <span class="ctt ctt-t2 pc-br">This product can expose you to chemicals including carbon black, titanium dioxide, and crystalline silica which are known to the State of California to cause cancer. For more information go to <a style="all: unset; cursor: pointer;" href="http://www.P65Warnings.ca.gov">http://www.P65Warnings.ca.gov</a>.</span>
                <span class="ctt ctt-t2 pc-br" style="margin-top:20px;">[ADVERTENCIA]</span>
                <span class="ctt ctt-t2 pc-br">Este producto puede exponerle a químicos incluyendo negro de carbón, dióxido de titanio y sílice cristalina, que son  conocidos por el Estado de California como causantes de cáncer.  Para mayor información, visite <a style="all: unset; cursor: pointer;" href="http://www.P65Warnings.ca.gov">http://www.P65Warnings.ca.gov</a>.</span>
              </p>
            </div>
          </div>
        </div>
        <div class="w-con-02 mb-150">
          <div class="inner">
            <div class="sub-visual-section">
              <h1 class="tt-1 visual-title tac">Disclaimer</h1>
              <!-- <p class="ctt ctt-t2 mb-88"></p> -->
            </div>
            <div class="t-type">
              <!-- <p class="tt-24">{{$t('privacy.text.FF0020_005')}}</p> -->
              <p class="ctt ctt-t2 tac">
                <span class="ctt ctt-t2 pc-br">CGI renderings are shown for illustration purpose only.</span>
                <span class="ctt ctt-t2 pc-br">Digital representation of color may vary from the actual product.</span>
                <span class="ctt ctt-t2 pc-br">Please view the actual product prior to purchase.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (Rendering/Image Disclaimer) -->

    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import QuickMenuComponent from "@/components/Layout/Quick/index.vue";
export default {
  name: 'rendering.vue',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  }
}
</script>

<style scoped>
a {
  color: #b40f2c !important;
}

.w-con-02 .inner + .inner {
  padding-top: 100px;
}

.w-con-02 + .w-con-02 {
  margin-top: 150px;
  border-top: solid 2px #e4e4e4;
}
</style>
