var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode === "_US" || _vm.nationCode === "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Customer-Oriented Products", color: "black" } },
        [
          _c(
            "OverviewSectionItem",
            [_c("AboutUsOverview", { attrs: { hidePointer: true } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "customer-oriented-products-txt ctt-t2" },
              [
                _vm._v(
                  "LX Hausys provides a variety of solutions that can revitalize a space through\nits business in the fields of building & decorative materials, industrial films, and automotive materials & components.\nWith our commitment to creating value-added spaces where humans and nature exist in harmony,\nwe strive to present innovative products that cater to the taste, interest, and lifestyle of our customers."
                ),
              ]
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItem, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        title: it.title,
                        descriptionList: it.descriptionList,
                        addtionalInfo: it.addtionalInfo,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "顧客志向の製品", color: "black" } },
        [
          _c(
            "OverviewSectionItem",
            [_c("AboutUsOverview", { attrs: { hidePointer: true } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "customer-oriented-products-txt ctt-t2" },
              [
                _vm._v(
                  "        LXハウシスは、建築・装飾資材、産業用フィルム、自動車資材・部品の分野で事業を展開し、\n        空間を活性化させるさまざまなソリューションを提供しています。\n        人と自然が調和した付加価値の高い空間づくりを目指し、\n        お客様の嗜好や興味、ライフスタイルに合わせた革新的な製品を提案してまいります。"
                ),
              ]
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItemJp, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        title: it.title,
                        descriptionList: it.descriptionList,
                        addtionalInfo: it.addtionalInfo,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Продукты, ориентированные на клиента",
            color: "black",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("AboutUsOverview", { attrs: { hidePointer: true } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "customer-oriented-products-txt ctt-t2" },
              [
                _vm._v(
                  "        LX Hausys предлагает разнообразные решения, способные оживить пространство.\nКомпания работает в области строительных и декоративных материалов, пленок для ламинации, автомобильных материалов и компонентов.\nСтремясь создавать пространства, где человек и природа существуют в гармонии,\nмы представляем инновационные продукты, которые отвечают вкусу, интересам и стилю жизни наших клиентов."
                ),
              ]
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItemRu, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        title: it.title,
                        descriptionList: it.descriptionList,
                        addtionalInfo: it.addtionalInfo,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode === "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "以顾客为中心的产品", color: "black" } },
        [
          _c(
            "OverviewSectionItem",
            [_c("AboutUsOverview", { attrs: { hidePointer: true } })],
            1
          ),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              { staticClass: "customer-oriented-products-txt ctt-t2" },
              [
                _vm._v(
                  "        LX Hausys通过建筑装饰材料, 产业用膜, \n        汽车配件材料等多项事业，致力于为各种空间注入活力并积极提供多样化的解决方案。\n        以打造人与自然和谐共存的空间为基础，致力研发承载顾客喜好, \n        关注点及生活方式的核心产品。"
                ),
              ]
            ),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "div",
              { staticClass: "card-area" },
              _vm._l(_vm.cardItemCn, function (it) {
                return _c(
                  "div",
                  { key: it.imageSrc },
                  [
                    _c("OverviewCardWithImage", {
                      attrs: {
                        imageSrc: it.imageSrc,
                        title: it.title,
                        descriptionList: it.descriptionList,
                        addtionalInfo: it.addtionalInfo,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }