var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Product Development Based on Design Trends",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("img", { staticClass: "image", attrs: { src: _vm.image } }),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.text } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "デザイントレンドに基づいた製品開発",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("img", { staticClass: "image", attrs: { src: _vm.image } }),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textJp } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Разработка продуктов на основе тенденций дизайна",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("img", { staticClass: "image", attrs: { src: _vm.image } }),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textRu } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "以设计趋势为基础进行产品开发",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c("OverviewSectionItem", { attrs: { isUnderSectionItem: "" } }, [
            _c("img", { staticClass: "image", attrs: { src: _vm.image } }),
          ]),
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textCn } })],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }