<template>
  <div class="pollutant-management-image">
    <div class="image-area">
      <img :src="image1">
      <img :src="image2">
    </div>
    <pre class="text">{{ text }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    image1: {
      type: String
    },
    image2: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
.pollutant-management-image {
  display: grid;
  gap: 30px;
  justify-content: center;
}

.pollutant-management-image .image-area {
  display: inline-flex;
  align-items: flex-start;
  gap: 20px;
  width: 1440px;
  height: 394px;
}

@media (max-width: 768px) {
  .pollutant-management-image .image-area {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.pollutant-management-image .image-area img {
  border-radius: 20px;
}

.pollutant-management-image .text {
  color: #000;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}

@media (max-width: 768px) {
  .pollutant-management-image .text {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
  }
}
</style>
