<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <div class="content au-view glSite">

      <div class="location">
        <ul>
          <li class="ico-home">Home </li>
          <li>关于我们</li>
          <li>LX Hausys 中国</li>
          <li>销售网络</li>
        </ul>
      </div>

      <div class="container factory_list">
        <div class="inner_padding showroom">
          <h1>销售网络</h1>
          <div class="showroom-wrap">
            <!-- 왼쪽버튼 -->
            <div class="showroom-tabs">
              <div class="showroom-tab on" @click="moveTab">
                <div>
                  <p>无锡 (工厂)</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>无锡 (总部)</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>北京</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>上海</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>广州</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>青岛</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>武汉</p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
              <div class="showroom-tab" @click="moveTab">
                <div>
                  <p>西安</p> 
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
            </div>
            <div class="showroom-contents">
              <!-- content -->
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>无锡 (工厂)</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>无锡市新吴区鸿山街道鸿祥路2号</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-0510-66686888</p>
                        <p>传真 : 86-0510-66686880</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="showroom-carousel">
                  <div>
                    <VueSlickCarousel :swipe-to-slide="true" v-bind="showroomSetting">
                      <div>
                        <img src="https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png" alt="factory image">
                      </div>
                      <div>
                        <img src="https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png" alt="factory image">
                      </div>
                      <div>
                        <img src="https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png" alt="factory image">
                      </div>
                      <div>
                        <img src="https://img.lxhausys.com/2024/images/factory/img_factory_wuxi.png" alt="factory image">
                      </div>
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>无锡 (总部)</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>无锡市新吴区和风路26号新发汇融广场C栋13层</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-0510-82336988</p>
                        <p>传真 : 86-0510-82356588</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>北京</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>北京市通州区新华北路绿地中央广场一期写字楼903-904室</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-010-5990 4422</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>上海</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>上海市闵行区上坤路77弄16号融信上坤中心T3栋702-703室</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-021-5241-6600</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>广州</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>广东省广州市黄浦区黄埔大道东路856号(A-2)308室</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-020-3877 1620</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>青岛</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>青岛市城阳区黑龙江中路187号盛文大厦一栋C单元602室</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-0532-5557 6408</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>武汉</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>武汉市江汉区常青路恒大中心1409室</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-027-6885 0585</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showroom-content">
                <div class="showroom-info">
                  <h4>西安</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>西安市雁塔区高新区锦业一路56号研祥城市广场B座3026室</p>
                      <a href="javascript:void(0)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>电话 : 86-029-8187 0551</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB/index.vue'
import HeaderComponent from '@/components/Layout/Header/index.vue'
import QuickMenuComponent from '@/components/Layout/Quick'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'FactoryList',
  components: { 
    GnbComponent, 
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    FooterComponent,
    VueSlickCarousel
  },
  data () {
    return {
      showroomSetting: {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        arrows: false,
        dots: true,
        swipeToSlide: true
        // dotsClass:"slick-dots custom-dot-class",
      },
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  methods: {
    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },


    moveTab: function(e) {
      let thisIndex = $(e.target).index();
      console.log(thisIndex);

      // 텝 클릭시 on 클래스 토글
      $('.showroom-tab').removeClass('on');
      $(e.target).addClass('on');

      // 탭 해당 우측 콘텐츠 이동
      // 선택한 콘텐츠 화면기준 위치
      let contentHeight = $('.showroom-content').eq(thisIndex).offset().top;
      // 이동위치
      let windowMove = contentHeight - 200;

      $(window).scrollTop(windowMove);
    }
  }
}
</script>
