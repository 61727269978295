<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>THICKNESS</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
            <span class="checkbox_more no_line">
              <li v-for="thick in thickList" :data-chk="thick.id.value">
                <input type="checkbox" :id="'thick_'+thick.id.value" name="Thickness" v-model="selected" :value="thick.id.value" />
                <label :for="'thick_'+`${thick.id.value}`">
                <span></span>
                {{ thick.text }}
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'thickList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-thickness-filter', this.removeThickness)
    EventBus.$on('update-thickness-filters', this.updateremoveThickness)

    if (this.$store.state.filterState && this.$store.state.filterState.thickness) {
      this.selected = this.$store.state.filterState.thickness
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-thickness-filter', this.removeThickness)
    EventBus.$on('update-thickness-filters', this.updateremoveThickness)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeThickness (thicknessValue) {
      this.selected = this.selected.filter(value => value !== thicknessValue)
    },
    updateremoveThickness (thicknesss) {
      this.selected = thicknesss
    }
  }
}
</script>
