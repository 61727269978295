<template>
  <VIZUON_IS
    :category="category"
    :collectionList="collectionList"
    :dictMap="dictMap"
    v-model="selected"
    v-on:category-changed-vizuon="handleCategoryChangedVizuon">
  </VIZUON_IS>
</template>

<script>
import { mapState } from 'vuex'
import VIZUON_IS from './VIZUON_IS.vue'

export default {
  name: 'SearchFilter',
  model: {
    prop: 'selected',
    event: 'update'
  },
  props: [
    'category',
    'collectionList',
    'dictMap'
  ],
  components: {
    VIZUON_IS
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data() {
    return {
      selected: {}
    }
  },
  watch: {},
  async created() {

  },
  async mounted() {
    this.$watch(vm => [vm.selected], val => {
      this.$emit('update', this.selected)
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  methods: {
    handleCategoryChangedVizuon (cateCode) {
      this.$emit('category-changed-vizuon', cateCode)
    }
  }
}
</script>
