var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overview-card-image-area-root" }, [
    _c("div", { staticClass: "overview-card-image-area" }, [
      _c("div", { staticClass: "image-area", style: _vm.backgroundStyle }, [
        _vm.title
          ? _c("div", { staticClass: "title-area" }, [
              _c("pre", [_vm._v(_vm._s(_vm.title))]),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "text-area" },
        [
          _c("pre", { staticClass: "ctt ctt-t3 description-txt" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
          _vm._l(_vm.descriptionList, function (it, idx) {
            return _c("div", { key: idx, staticClass: "child" }, [
              _vm._v(" " + _vm._s(`• ${it}`) + " "),
            ])
          }),
        ],
        2
      ),
    ]),
    _vm.addtionalInfo
      ? _c("div", { staticClass: "addtional-area" }, [
          _c("div", { staticClass: "additional-title-area" }, [
            _c("pre", [_vm._v(_vm._s(_vm.addtionalInfo.title))]),
          ]),
          _c("div", { staticClass: "additional-desc-area" }, [
            _c("pre", [_vm._v(_vm._s(_vm.addtionalInfo.desc))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }