<template>
  <OverviewSection
    title="Product Development Based on Design Trends"
    size="56px"
    color="#222"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <img class="image" :src="image">
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="text"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="デザイントレンドに基づいた製品開発"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <img class="image" :src="image">
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="textJp"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Разработка продуктов на основе тенденций дизайна"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <img class="image" :src="image">
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="textRu"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="以设计趋势为基础进行产品开发"
    size="56px"
    color="#222"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <img class="image" :src="image">
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="textCn"
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../../sustainability/components/SustainabilityText.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    SustainabilityText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'LX Hausys makes a great effort into analyzing spaces desired by our customers.\n' +
            'We analyze the causes and flows of design changes from diversified perspectives while considering domestic and foreign\n' +
            'design conditions and consumer needs, and reflect them in the development of new product designs and contents.',
      textJp:'LX Hausysは、お客様が求める空間の分析に力を入れています。国内外のデザイン事情や消費者ニーズを考慮しながら、\n' + 
      'デザイン変化の原因や流れを多種多様に分析し、新商品デザインやコンテンツの開発に反映させています。',
      textRu:'LX Hausys уделяет большое внимание анализу помещений, желаемых нашими клиентами.\n' +
             'Мы анализируем изменения в дизайне с различных точек зрения,\n' + 
             'учитывая отечественные и зарубежные стандарты дизайна и потребности потребителей,\n' + 
             'и отражаем их в разработке дизайна и новых продуктах.',
      textCn:'LX Hausys一直致力于分析顾客理想的空间设计方案，并结合国内外设计环境及消费者需求等多个方面，\n' + 
      '以多角度的视野分析设计更迭的原因及趋势，并反馈到新品设计及内容开发上。',
      image: 'images/aboutus/r-and-d-center/marketLeadingDesign/product-development-based-image.png'
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
.image {
  width: fit-content;
}

@media (max-width: 768px) {
  .image {
    width: 90%;
  }
}
</style>
