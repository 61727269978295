/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
import _ from '@/common/helper/lodash.js'

/* eslint-disable no-param-reassign */
export default {
  setImgLibCount (state, payload) {
    state.imgLibCnt = payload
  },
  setLikeCount (state, payload) {
    state.likeCnt = payload.likeCnt
    // _.assign(state, {...payload})
    // state.likeCnt = _.cloneDeep(payload)
  },
  setLanguageWrapper () {
    localStorage.setItem('languageWrapper', 'closed')
  },
  removeLanguageWrapper () {
    localStorage.removeItem('languageWrapper')
  },
  setNeverMissPopupClose () {
    sessionStorage.setItem('neverMissPopupClose', 'closed')
  },
  setImgLibPopupClose () {
    sessionStorage.setItem('imgLibPopupClose', 'closed')
  }
}
