var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProductLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("ProductTopImage", {
              attrs: {
                title: "Vegan Leather",
                subTitle: "Sustainable & Innovative",
                description:
                  "Sustainable Products, Innovative Technology for Environment",
                imageSrc: "images/automotive/skin/top-image.png",
              },
            }),
            _c(
              "ProductContent",
              [
                [
                  _c("ProductIntro", {
                    staticClass: "mt-100",
                    attrs: { youtubeId: "rxGl8ZxgpXY" },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(" LX Hausys "),
                            ]),
                            _c("span", [
                              _vm._v(
                                " is a major supplier of interior skin for various automotive applications. With our advanced technology and innovative design, we are able to offer you a total interior solutions to decorate your vehicle beyond your expectations. "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("StrongPoingGroup", {
                    staticClass: "mt-100",
                    attrs: {
                      info: [
                        {
                          iconSrc:
                            "images/automotive/skin/strong-point-icon-1.png",
                          title: "SAFETY",
                          description:
                            "STANDARD 100 by OEKO-TEX® certified products have been tested for harmful substances to protect your health (Green Label)",
                        },
                        {
                          iconSrc:
                            "images/automotive/skin/strong-point-icon-2.png",
                          title: "Sustainable Materials",
                          description:
                            "Sustainable development by using bio-based and post-consumer recycled materials (Bio / Eco Label)",
                        },
                      ],
                    },
                  }),
                  _c("div", { staticClass: "mt-100" }, [
                    _c("img", {
                      attrs: {
                        src: "images/automotive/skin/example-center.jpg",
                        alt: "example",
                      },
                    }),
                  ]),
                  _c("SeatCorver"),
                  _c("DesignArea"),
                  _c("CommercialVeganLeather"),
                  _c("LxelloColorPalette"),
                ],
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }