<template>
  <div class="overview-small-section">
    <!-- 제목 영역 -->
    <template v-if="showTitle">
      <div class="title">
        <span :style="{'font-size': size, 'color': color, 'font-family': fontFamily, 'font-weight': fontWeight}">{{ title }}</span>
      </div>
    </template>

    <!-- 컨텐츠 영역 -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    size: {
      type: String,
      require: false,
      default: '36px'
    },
    color: {
      type: String,
      require: false,
      default: '#A09C9B'
    },
    fontFamily: {
      type: String,
      require: false,
      default: 'NotoSans'
    },
    fontWeight: {
      type: String,
      require: false,
      default: '400'
    }
  },

  computed: {
    showTitle: function () {
      if (this.title) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.overview-small-section {
  display: flex;
  flex-direction: column;
  margin-top: 54px;
}

.overview-small-section .title {
  margin-left: auto;
  margin-right: auto;
}

.overview-small-section .title span {
  color: #A09C9B;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}

.overview-small-section .content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .overview-small-section .title {
    text-align: center;
  }
  .overview-small-section .title span {
    white-space: pre-wrap;
    font-size: 1.5rem !important;
  }
}
</style>
