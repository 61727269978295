<template>
  <div class ="about_us_point" :class="{us_point : isUSCode()}">
    <div @mouseover="point_mouse_over" @mousedown="point_mouse_down" class="pop_up" :style="popupStyle">
      <div :class="{gray_text : isGrayText()}" v-for="popup in this.pointInfo.popupList"
      :key="popup.id">
        {{popup.value}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'interialPoint',
  props: {
    pointInfo: {
      id: '',
      popupList: '', // popup될 글씨 이름
      x1: 0,
      y1: 0,
      type: '',
      url: '',
      gray: false
    },
    hidePointer: {
      type: Boolean
    }
  },
  data () {
    return {
      isSelected: false,
      nationCode: ''
    }
  },
  async mounted () {
    const _this = this
    _this.nationCode = _this.$countryCode
  },
  methods: {
    point_mouse_down (e) {
      if (this.isDisablePoint) {
        return
      }

      this.$utils.gotoUrl(this.pointInfo.url)
    },
    point_mouse_over (e) {
      // this.isSelected = true
      // console.log('haha')
      // document.getElementsByClassName('slash')[0].style.visibility = 'visible'
      // this.$emit('change_nav_html', this.pointInfo.navInnerHtml)
      // debugger
    },

    point_mouse_out (e) {
      // e.target.getElementsByClassName('pop_up')[0].style.visibility = 'hidden'
    },
    isGrayText () {
      if (this.pointInfo.gray === false) { return false }
      if (this.nationCode === '_GL') { return false }
      return true
    },
    isUSCode () {
      return (this.nationCode === '_US')
    }
  },
  computed: {
    popupStyle () {
      if (this.pointInfo.type == 'right') { return 'top:' + (this.pointInfo.y1) + '%; left:' + (this.pointInfo.x1) + '%;' + 'transform: translateY(-50%);' + this.pointStyle }
      if (this.pointInfo.type == 'left') { return 'top:' + (this.pointInfo.y1) + '%; left:' + (this.pointInfo.x1) + '%;' + 'transform: translate(-100%,-50%);' + this.pointStyle }
      if (this.pointInfo.type == 'bottom') { return 'top:' + (this.pointInfo.y1) + '%; left:' + (this.pointInfo.x1) + '%;' + 'transform: translateX(+50%);' + this.pointStyle }
    },
    isDisablePoint () {
      let flag = false

      if (this.hidePointer === true) {
        flag = true
      }

      return flag
    },
    pointStyle () {
      let cusorStyle = ''

      if (this.isDisablePoint) {
        cusorStyle = 'cursor: default;'
      }

      return cusorStyle
    }

  }
}
</script>

<style scoped>
@font-face {
font-family: 'popup_font';
src: url('/fonts/NotoSansKR-Regular.otf') format('truetype')
}

.pop_up {
  position: absolute;
  background-color:transparent;
  opacity: 0.8;
  z-index: 10;
  pointer-events:auto;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}

div {
  font-family: 'popup_font';
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.about_us_point{
  left : -0;
  top : 0px;
  position:absolute;
  z-index : 1;
  pointer-events: none;
  width : 1161px;
  height : 772px;
}
.us_point{
  /* left: -200px; */
}
.gray_text{
  color: rgb(167, 167, 167);
}
@media (max-width: 768px) {
  .about_us_point{
    width: 100%;
    height: 100%;
  }
  div {
    font-size: 6px;
  }
}
</style>
