<template>
  <BusinessAreaCarouselTopImage
    :imageSrc="imageSrc"
    :imageHeight=imageHeight
  >
  <template #content>
    <div class="business-area-carousel-item-area">
      <div class="title-area" :style="{'color': color}" >{{ title }}</div>
    </div>
  </template>
</BusinessAreaCarouselTopImage>
</template>

<script>
import BusinessAreaCarouselTopImage from './BusinessAreaCarouselTopImage.vue'

export default {
  components: {
    BusinessAreaCarouselTopImage
  },

  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#222'
    }
  },

  data: function () {
    return {
      height: '650px'
    }
  },
  computed: {
    imageHeight () {
      const screenWidth = window.innerWidth
      let imageHeight = '650px'

      if (screenWidth <= 768) {
        imageHeight = ''
      }

      return imageHeight
    }
  }
}
</script>

<style scoped>
.business-area-carousel-item-area {
  display: flex;
  margin-left: 40px;
  margin-top: 40px;
  height: 100%;
  width: 100%;
}

.business-area-carousel-item-area .title-area {
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .business-area-carousel-item-area {
    width: fit-content;
    margin-left: 3%;
    margin-top: 4%;
  }

  .business-area-carousel-item-area .title-area {
    font-size: 1.25rem;
  }
}

</style>
