<template>
  <div class="color-palette-area mt-100">
    <ProductSectionHeader
      type="horizontal"
      title="LXELLO™ Color Palette"
    />

    <div class="mt-50">
      <img src="images/automotive/skin/color-palette.png" alt="img">
    </div>
  </div>
</template>

<script>
import ProductSectionHeader from '@/components/product/ProductSectionHeader'

export default {
  components: {
    ProductSectionHeader
  }
}

</script>

<style scoped>
.color-palette-area {
  display: flex;
  flex-direction: column;
}

.color-palette-area .image-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.color-palette-area .image-area .item {
  width: 210px;
  height: 250px;
  background-color: aqua;
  margin-bottom: 30px;
}

</style>
