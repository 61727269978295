var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Customer Values",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "card-area" }, [
              _c("div", { staticClass: "left-card" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "Eco - friendly",
                          desc: "Shift to eco-friendly materials by improving diversity in material use (Expand eco-friendly products/materials)",
                          descWidth: "90%",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "Energy Saving",
                          desc: "Deliver systems & solutions that maximize energy efficiency (High-quality windows/green car materials)",
                          descWidth: "65%",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/left-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "right-card" }, [
                _c(
                  "div",
                  { staticClass: "text-area" },
                  [
                    _c("CustomerValuesText", {
                      attrs: {
                        title: "Human – friendly",
                        desc: "Strengthen reliable and impressive contact points with customers (Reinforce brand power and innovation in distribution)",
                        descWidth: "65%",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/right-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              {
                staticClass: "ctt-pre ctt-t2",
                staticStyle: { "text-align": "center", "font-weight": "400" },
              },
              [
                _vm._v("LX Hausys pursues the three customer values of "),
                _c("b", { staticStyle: { "font-weight": "700" } }, [
                  _vm._v("Eco-Friendly, Energy Saving, and Human-Friendly."),
                ]),
                _vm._v(
                  "\nWe provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,\nand we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with\noutstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,\nwe are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and\ninnovation in distribution to expand the customer communication touchpoints."
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSmallSection",
        { attrs: { title: "顧客価値", fontFamily: "Lato", fontWeight: "700" } },
        [
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "card-area" }, [
              _c("div", { staticClass: "left-card" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "環境に優しい（Eco - friendly)",
                          desc: "素材の多様性向上による環境配慮型素材への転換（環境配慮型製品・素材の拡大）",
                          descWidth: "90%",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "省エネ (Energy Saving)",
                          desc: "エネルギー効率を最大化するシステムとソリューションを提供（高品質の窓/環境に優しい自動車素材）",
                          descWidth: "65%",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/left-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "right-card" }, [
                _c(
                  "div",
                  { staticClass: "text-area" },
                  [
                    _c("CustomerValuesText", {
                      attrs: {
                        title: "人間に優しい (Human – friendly)",
                        desc: "顧客との信頼感・感動接点の強化",
                        descWidth: "65%",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/right-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              {
                staticClass: "ctt-pre ctt-t2",
                staticStyle: { "text-align": "center", "font-weight": "400" },
              },
              [
                _vm._v("LX Hausys pursues the three customer values of "),
                _c("b", { staticStyle: { "font-weight": "700" } }, [
                  _vm._v("Eco-Friendly, Energy Saving, and Human-Friendly."),
                ]),
                _vm._v(
                  "\nWe provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,\nand we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with\noutstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,\nwe are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and\ninnovation in distribution to expand the customer communication touchpoints."
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Customer Values",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "card-area" }, [
              _c("div", { staticClass: "left-card" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "Экологичность",
                          desc: "Переход на экологически чистые материалы путем повышения разнообразия в использовании материалов (Расширение ассортимента экологически чистых продуктов/материалов)",
                          descWidth: "90%",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "Энергосбережение",
                          desc: "Предоставление системных решений, максимально повышающих энергоэффективность (высококачественные окна/материалы для автомобилей)",
                          descWidth: "65%",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/left-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "right-card" }, [
                _c(
                  "div",
                  { staticClass: "text-area" },
                  [
                    _c("CustomerValuesText", {
                      attrs: {
                        title: "Дружелюбность",
                        desc: "Укрепление надежных точек контакта с клиентами (Укрепление силы бренда и инновации в дистрибуции)",
                        descWidth: "65%",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/right-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              {
                staticClass: "ctt-pre ctt-t2",
                staticStyle: { "text-align": "center", "font-weight": "400" },
              },
              [
                _vm._v("LX Hausys pursues the three customer values of "),
                _c("b", { staticStyle: { "font-weight": "700" } }, [
                  _vm._v("Eco-Friendly, Energy Saving, and Human-Friendly."),
                ]),
                _vm._v(
                  "\nWe provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,\nand we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with\noutstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,\nwe are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and\ninnovation in distribution to expand the customer communication touchpoints."
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSmallSection",
        {
          attrs: {
            title: "Customer Values",
            fontFamily: "Lato",
            fontWeight: "700",
          },
        },
        [
          _c("OverviewSectionItem", [
            _c("div", { staticClass: "card-area" }, [
              _c("div", { staticClass: "left-card" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "Eco - friendly",
                          desc: "通过提高材料使用的多样性转向环保材料（扩大环保产品和材料）",
                          descWidth: "90%",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "child" },
                    [
                      _c("CustomerValuesText", {
                        attrs: {
                          title: "Energy Saving",
                          desc: "极大地发挥原效率地系统及解决方案（优质窗户/绿色环保汽车材料）",
                          descWidth: "65%",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/left-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "right-card" }, [
                _c(
                  "div",
                  { staticClass: "text-area" },
                  [
                    _c("CustomerValuesText", {
                      attrs: {
                        title: "Human – friendly",
                        desc: "赋予信赖和感动的顾客结合点（品牌竞争力地增强和多种流通渠道地创新）",
                        descWidth: "65%",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "icon-area" }, [
                  _c("img", {
                    attrs: {
                      src: "images/aboutus/overview/vision_strategies/right-card-icon.png",
                      alt: "cardicon",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("OverviewSectionItem", [
            _c(
              "pre",
              {
                staticClass: "ctt-pre ctt-t2",
                staticStyle: { "text-align": "center", "font-weight": "400" },
              },
              [
                _vm._v("LX Hausys pursues the three customer values of "),
                _c("b", { staticStyle: { "font-weight": "700" } }, [
                  _vm._v("Eco-Friendly, Energy Saving, and Human-Friendly."),
                ]),
                _vm._v(
                  "\nWe provide eco-friendly materials through diversification of materials to pursue the value of Eco-friendliness,\nand we’ve been accomplishing Energy Saving by enhancing energy performance with windows and doors with\noutstanding insulating capability, high-performance insulation materials, and lightweight automotive parts.In addition,\nwe are realizing the Human-Friendliness by raising the value of living through our human-friendly designs and\ninnovation in distribution to expand the customer communication touchpoints."
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }