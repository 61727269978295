<template>
  <div class="direction-of-strategy-card">
    <span class="text-area">{{ txt }}</span>
  </div>
</template>

<script>
export default {
  props: {
    txt: {
      type: String,
      default: ''
    }
  }
}

</script>

<style scoped>
.direction-of-strategy-card {
  border-radius: 15px;
  justify-content: center;
  border: 0px solid #D0CECD;
  background: #F4F4F4;
  display: flex;
  padding: 45px 0px;
  align-items: flex-start;
  flex: 1 0 0;
}

.text-area {
  color: #333;
  text-align: center;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 768px) {
  .direction-of-strategy-card {
    width: 90%;
    padding-left: 3px;
  }
  .text-area {
    white-space: pre-wrap;
    font-size: 1rem;
  }
}
</style>
