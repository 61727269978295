<template>
  <div class="eco-friendly-products-tab">
    <EcoFriendlyProductsTabHeader
      :tabDatas="tabDatas"
      @selected-tab="onClickHeader"
    />
    <slot name="item" v-bind="tabBody"></slot>
  </div>
</template>

<script>
import EcoFriendlyProductsTabHeader from './EcoFriendlyProductsTabHeader.vue'
export default {
  components: {
    EcoFriendlyProductsTabHeader
  },
  props: {
    tabDatas: {
      type: Array
    }
  },
  data: function () {
    return {
      selectedIndex: 0
    }
  },
  computed: {
    tabBody () {
      return this.tabDatas[this.selectedIndex]
    }
  },
  methods: {
    onClickHeader (index) {
      this.selectedIndex = index
    }
  }

}
</script>

<style scoped>
.eco-friendly-products-tab {
  width: 1440px;
}

@media (max-width: 768px) {
  .eco-friendly-products-tab {
    width: 100%;
  }
}
</style>
