var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content au-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v("Home")]
            ),
            _c("li", [_vm._v("About us")]),
            _c("li", [_vm._v("Career")]),
          ]),
        ]),
        _vm._m(0),
      ]),
      _c("footer-component"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container au-box au-box02" }, [
      _c("div", { staticClass: "w-con-02 ba-text" }, [
        _c("div", { staticClass: "sub-visual-section" }, [
          _c("h1", { staticClass: "visual-title tt-1 tac" }, [
            _vm._v("Career"),
          ]),
        ]),
        _c("div", { staticClass: "t-list-box t-type-01" }, [
          _vm._v(" We’re looking for the best people to work at LX Hausys. "),
        ]),
      ]),
      _c("div", { staticClass: "cr-box" }, [
        _c("div", { staticClass: "w-con" }, [
          _c("iframe", {
            staticStyle: { border: "0" },
            attrs: {
              src: "https://recruiting.paylocity.com/recruiting/jobs/List/5268/LG-HAUSYS-AMERICA-INC",
              height: "1200",
              width: "100%",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }