<template>
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainable_products/top_image.png"
    title="Sustainable Products"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
  <template #content>
    <LifeCycleAssessment />
    <EcoFriendlyCertification />
    <EcoFriendlyProducts />
  </template>
  </OverviewLayout>
<!-- JP -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainable_products/top_image.png"
    title="サステナブル製品"
    v-else-if="nationCode == '_JP'"
  >
    <template #content>
      <LifeCycleAssessment />
      <EcoFriendlyCertification />
      <EcoFriendlyProducts />
    </template>
  </OverviewLayout>
<!-- RU -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainable_products/top_image.png"
    title="Экологически чистые продукты"
    v-else-if="nationCode == '_RU'"
  >
    <template #content>
      <LifeCycleAssessment />
      <EcoFriendlyCertification />
      <EcoFriendlyProducts />
    </template>
  </OverviewLayout>
<!-- CN -->
  <OverviewLayout
    imageSrc="images/aboutus/sustainability/sustainable_products/top_image.png"
    title="可持续产品"
    v-else-if="nationCode == '_CN'"
  >
    <template #content>
      <LifeCycleAssessment />
      <EcoFriendlyCertification />
      <EcoFriendlyProducts />
    </template>
  </OverviewLayout>
</template>

<script>
import OverviewLayout from '../../overview/components/OverviewLayout.vue'
import EcoFriendlyCertification from './components/EcoFriendlyCertification.vue'
import EcoFriendlyProducts from './components/EcoFriendlyProducts.vue'
import LifeCycleAssessment from './components/LifeCycleAssessment.vue'

export default {
  components: {
    OverviewLayout,
    LifeCycleAssessment,
    EcoFriendlyCertification,
    EcoFriendlyProducts
  },
  data () {
    return {
      countryCode: '',
      countryCodeSub: ''
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>

</style>
