var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode === "_US" || _vm.nationCode === "_GL"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/global_network/top_image.png",
          title: "Global Network",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("MapSection"),
                  _c("SouthKoreaCompanySection"),
                  _c("OverseasCompanySection"),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1531818649
        ),
      })
    : _vm.nationCode === "_JP"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/global_network/top_image.png",
          title: "グローバルネットワーク",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("MapSection"),
                _c("SouthKoreaCompanySection"),
                _c("OverseasCompanySection"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode === "_RU"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/global_network/top_image.png",
          title: "Глобальная сеть",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("MapSection"),
                _c("SouthKoreaCompanySection"),
                _c("OverseasCompanySection"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm.nationCode === "_CN"
    ? _c("OverviewLayout", {
        attrs: {
          imageSrc: "images/aboutus/overview/global_network/top_image.png",
          title: "全球网络",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("MapSection"),
                _c("SouthKoreaCompanySection"),
                _c("OverseasCompanySection"),
              ]
            },
            proxy: true,
          },
        ]),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }