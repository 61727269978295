var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Annual Trendship Seminar",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.text } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "年次トレンドシップセミナー",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textJp } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        {
          attrs: {
            title: "Ежегодный семинар Trendship",
            size: "56px",
            color: "#222",
          },
        },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textRu } })],
            1
          ),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "年度趋势研讨会", size: "56px", color: "#222" } },
        [
          _c(
            "OverviewSectionItem",
            [_c("SustainabilityText", { attrs: { text: _vm.textCn } })],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }