<template>
    <progress v-if="loadingState.loading" class="lx_prog"  max="100" :value="progressValue"></progress>
</template>

<script>
// import { getLoadingState } from '@/axios-service.js';
import { getLoadingState } from "@/server/request";

export default {
  data() {
    return {
      loadingState: getLoadingState()
    };
  },
  computed: {
    progressValue() {
      return this.loadingState.progressValue;
    }
  }
};
</script>

