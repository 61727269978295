import LikeService from '@/server/like.service'
import _ from '@/common/helper/lodash'
import VueRouter from 'vue-router'
import CountryService from '@/server/country.service'
import categoryService from '@/server/category.service'
import AccessLogService from '../../server/accessLog.service'

import md5 from 'md5'

if (typeof String.prototype.startsWith !== 'function') {
  Window.String.prototype.startsWith = function (prefix) {
    return this.slice(0, prefix.length) === prefix
  }
}

export default {
  async toggleLike (sr, likeType, cateType, vm, orgContent, sessionItem) {
    vm.nationCode = vm.$countryCode /* 국가 코드 */

    if (vm.nationCode == '_US') {
      if (sr.orgContent != null && sr.orgContent.liked || sr.content.liked) {
        if (sr.content.liked) {
          const { data } = await LikeService.deleteByContent(sr.content.id)
          sr.content.liked = !data.success
        }
        if (orgContent != null) {
          const { data } = await LikeService.deleteByContent(orgContent)
          sr.orgContent.liked = !data.success
        }
      } else {
        const like = {
          likeType: likeType,
          cateType,
          categoryId: sr.content.categoryId,
          contentId: sr.content.id
        }
        if (orgContent != null) {
          like.contentId = orgContent
        }
        const { data } = await LikeService.saveLike(like)
        if (orgContent != null) {
          sr.orgContent.liked = data.success
        } else {
          sr.content.liked = data.success
        }
      }
    }
    if (vm.nationCode != '_US') {
      let arrayList = []
      const likeChk = []
      let likedoubleChk = true

      if (orgContent != null && orgContent != '' && vm.nationCode != null && vm.nationCode != undefined && vm.nationCode != '') {
        if (sessionItem != null) {
          arrayList = sessionItem

          _.filter(sessionItem, function (o) {
            likeChk.push(o)
          })
        } /* SessionItem 널이 아닐때 */

        if (sessionItem != null) {
          _.filter(likeChk, function (o) {
            if (o.id == orgContent) { // 아이디 중복 값 걸러 줌
              if (o.cateType == cateType) { // IG | CS | TR | NEWS
                _.remove(arrayList, function (c) {
                  if (c.nationCode == vm.nationCode && c.cateType == cateType && c.id == orgContent) {
                    likedoubleChk = false // 중복 제거 는 저장 안하게 걸러준다.
                    return c.nationCode == vm.nationCode && c.cateType == cateType && c.id == orgContent // 같은 데이터 삭제 시켜서 저장 하게 됨.
                  }
                })
              }
            }
          })

          if (likedoubleChk == true) { // 중복제거 된 애들 제외 하고 저장 하게 된다.
            arrayList.push({ id: orgContent, cateType: cateType, nationCode: vm.nationCode })
          }
        } else {
          arrayList.push({ id: orgContent, cateType: cateType, nationCode: vm.nationCode })
        }

        if (likeType == 'PD') { /* PD / ORG 컨텐츠 정보가 들어 갔을때 */
          sessionStorage.setItem('PD', JSON.stringify(arrayList))
          // vm.$store.commit('like/pushLikePDItem', sessionPDOrgItem) / 구현 해야함 !
        }
        if (likeType == 'PJ') {
          sessionStorage.setItem('PJ', JSON.stringify(arrayList))
          // vm.$store.commit('like/pushLikePJItem', sessionPJOrgItem) / 구현 해야함 !
        }
      } else {
        if (sessionItem != null) {
          arrayList = sessionItem

          _.filter(sessionItem, function (o) {
            likeChk.push(o)
          })
        } /* SessionItem 널이 아닐때 */

        if (sessionItem != null && vm.nationCode != null && vm.nationCode != undefined && vm.nationCode != '') {
          _.filter(likeChk, function (o) {
            if (o.id == sr.content.id) { // 아이디 중복 값 걸러 줌
              if (o.cateType == cateType) { // IG | CS | TR | NEWS
                _.remove(arrayList, function (c) {
                  if (c.nationCode == vm.nationCode && c.cateType == cateType && c.id == sr.content.id) {
                    likedoubleChk = false // 중복 제거 는 저장 안하게 걸러준다.
                    return c.nationCode == vm.nationCode && c.cateType == cateType && c.id == sr.content.id // 같은 데이터 삭제 시켜서 저장 하게 됨.
                  }
                })
              }
            }
          })

          if (likedoubleChk == true) { // 중복제거 된 애들 제외 하고 저장 하게 된다.
            arrayList.push({ id: sr.content.id, cateType: cateType, nationCode: vm.nationCode })
          }
        } else {
          arrayList.push({ id: sr.content.id, cateType: cateType, nationCode: vm.nationCode })
        }

        if (likeType == 'PD') { /* PD / ORG 컨텐츠 정보가 안들어 갔을때 */
          sessionStorage.setItem('PD', JSON.stringify(arrayList))
          // vm.$store.commit('like/pushLikePDItem', sessionPDItem) / 구현 해야함 !
        }
        if (likeType == 'PJ') { /* IG, CS, TR = > PJ / ORG 컨텐츠 정보가 안들어 갔을때 */
          sessionStorage.setItem('PJ', JSON.stringify(arrayList))
          // vm.$store.commit('like/pushLikePJItem', sessionPJItem) / 구현 해야함 !
        }
        // const sessionItem = await sampleOrderService.getProductInfo(sr.content.id) / 개발 전
        // sessionStorage.setItem(`${sr.content.id}`, JSON.stringify(sessionItem)); / 개발 전
      }
    } /* end of 국가코드 => GL 일때 */

    vm.$store.dispatch('header/getTotalLikeCnt')
  }, /* end of toggleLike */
  createRouter (routes, base) {
    const baseWithoutSlash = base.split('/').filter(element => element)[0]
    const region = window.location.pathname.split('/').filter(element => element)[0]

    let regionBase = '/' + region + base
    if (region === baseWithoutSlash) {
      regionBase = base
    }

    const router = new VueRouter({
      mode: 'history',
      base: regionBase,
      routes,
      scrollBehavior  (to, from, savedPosition) {
        // return desired position
        return { x: 0, y: 0 }
      }
    })

    router.beforeEach(async (to, from, next) => {
      next()
    })

    router.afterEach((to, from) => {
      try {
        console.log('access log : ' + to.path)
        const title = document.title
        if (title) {
          AccessLogService.accessLog(to.path, title)
        } else {
          setTimeout(() => {
            AccessLogService.accessLog(to.path, document.title)
          }, 1000)
        }
      } catch (e) {
        console.log(e)
      }
    })

    return router
  }, /* end of createRouter */
  getCountryCode () {
    let countryCode = process.env.VUE_APP_COUNTRY_CODE
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const region = this.getRegion().toUpperCase()
      if (urlParams.get('countryCode') != null) {
        countryCode = urlParams.get('countryCode')
        sessionStorage.setItem('countryCode', countryCode)
        CountryService.setCountryCode(countryCode)
      } else if (sessionStorage.getItem('countryCode') != null) {
        countryCode = sessionStorage.getItem('countryCode')
      } else if (countryCode !== region) {
        countryCode = region
      }
    } catch (e) { console.log(e) }

    return countryCode
  }, /* end of getCountryCode */
  getRegion () {
    const region = window.location.pathname.split('/')[1]
    const supportedRegions = {
      'ca-en': 'ca',
      'ca-fr': 'cf',
      'us': 'us',
      'gl': 'gl',
      'jp': 'jp',
      'ru': 'ru',
      'cn': 'cn'
    }
    return supportedRegions[region] || process.env.VUE_APP_COUNTRY_CODE.toLowerCase()

  }, /* end of getRegion */
  isEmpty (val) {
    if (_.isUndefined(val) || val === null || val === '') {
      return true
    } else {
      return false
    }
  },
  goLogin () {
    const redirectUrl = window.location
    sessionStorage.setItem('afterLoginRedirect', redirectUrl)
    sessionStorage.setItem('afterLoginRedirectSetTime', Date.now())
    document.location.href = 'login'
  },
  goLoginEU () {
    const redirectUrl = window.location
    sessionStorage.setItem('afterLoginRedirect', redirectUrl)
    sessionStorage.setItem('afterLoginRedirectSetTime', Date.now())
    document.location.href = 'himacsPartnersPortal'
  },
  redirectAfterLogin () {
    let redirectUrl = sessionStorage.getItem('afterLoginRedirect')
    const redirectUrlSetTime = sessionStorage.getItem('afterLoginRedirectSetTime')
    if (!_.isEmpty(redirectUrl) && !_.isEmpty(redirectUrlSetTime)) {
      if (Date.now() - redirectUrlSetTime > 1000 * 60 * 5) /* 5 분 */
      {
        redirectUrl = 'index'
      }
    } else {
      redirectUrl = 'index'
    }

    sessionStorage.removeItem('afterLoginRedirect')
    sessionStorage.removeItem('afterLoginRedirectSetTime')

    if (redirectUrl.indexOf('login') > -1) {
      redirectUrl = 'index'
    }
    document.location.href = redirectUrl
  },
  makeMd5hash (str) {
    if (this.isEmpty(str)) {
      return str
    }
    return md5(str)
  },
  gotoUrl (url) {
    if (url.indexOf('.html#/') >= 0) {
      url = url.replace('.html#/', '/')
    }

    document.location.href = url
  },
  openUrl (url) {
    if (url.indexOf('.html#/') >= 0) {
      url = url.replace('.html#/', '/')
    }
    window.open(url, '_blank')
  },
  async getProductCategory () {
    const country = this.getCountryCode()
    const codes = [
      'HIMACS_COLOR_' + country,
      'VIATERA_COLOR_' + country,
      'PRD_FLOORING_' + country,
      'TERACANTO_COLOR_' + country
    ]

    let result = null
    try {
      result = JSON.parse(sessionStorage.getItem('prdCateId'))
    } catch (e) {
      console.log(e)
    }

    if (this.isEmpty(result) || this.isEmpty(result[country])) {
      const res = await categoryService.getCategoryCodeList(codes)

      const prdCateId = result || {}
      prdCateId[country] = {}
      prdCateId[country].himacsId = res.data.list[0].id
      prdCateId[country].viateraId = res.data.list[1].id
      prdCateId[country].hflorId = res.data.list[2].id
      prdCateId[country].teracantoId = res.data.list[3].id

      sessionStorage.setItem('prdCateId', JSON.stringify(prdCateId))
    }
  },
  getPrdCateId (cateId) {
    let result
    const country = this.getCountryCode()
    try {
      result = JSON.parse(sessionStorage.getItem('prdCateId'))
    } catch (e) {
      console.log(e)
      return 0
    }
    if (result === null) {
      return 0
    }
    if (this.isEmpty(result[country])) {
      return 0
    }
    return Number(result[country][cateId])
  },
  getSeoName (data, type) {
    let seoName = ''

    // 내부 function
    function _makeSeoName (str) {
      if (str !== undefined) {
        // 공백제거 & 특수문자 제거
        const filtered1 = str.replace(/\s/gi, '-')
        const filtered2 = filtered1.toLowerCase()
        return filtered2.replace(/[\\''+?*&,]+/gi, '')
      }
    }

    if (type === 'content') {
      // content
      if (this.isEmpty(data.attribute)) {
        // attribute 가 없을 때
        seoName = _makeSeoName(data.content.title)
      } else {
        if (this.isEmpty(data.attribute.seo_name)) {
          // attribute seo_name 가 없을 때
          seoName = _makeSeoName(data.content.title)
        } else {
          seoName = data.attribute.seo_name
        }
      }
    } else if (type === 'news') {
      if (this.isEmpty(data.attribute.seo_name)) {
        // attribute seo_name 가 없을 때
        seoName = _makeSeoName(data.attribute.topicTypeText)
      } else {
        seoName = data.attribute.seo_name
      }
    } else if (type === 'seg') {
      if (this.isEmpty(data.attribute.seo_name)) {
        // attribute seo_name 가 없을 때
        seoName = _makeSeoName(data.orgContent.title)
      } else {
        seoName = data.attribute.seo_name
      }
    } else {
      // category
      if (this.isEmpty(data.attribute)) {
        // attribute 가 없을 때
        seoName = _makeSeoName(data.name)
      } else {
        if (this.isEmpty(data.attribute.seo_name)) {
          // attribute seo_name 가 없을 때
          seoName = _makeSeoName(data.name)
        } else {
          seoName = data.attribute.seo_name
        }
      }
    }
    return seoName
  }
}
