var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box ourprod-box" }, [
    _c("div", { staticClass: "title_area" }, [
      _c("h2", { staticClass: "tit-main ourproduct_tit" }, [
        _vm._v(_vm._s(_vm.$t("main.text.our_prd"))),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "our_product_slider_wrap" }, [
      _c("div", { staticClass: "our_product_slider_main" }, [
        _c(
          "div",
          { staticClass: "slider-target" },
          _vm._l(_vm.ourProductList, function (ourProduct) {
            return _c(
              "div",
              { key: ourProduct.attribute.MAIN_OUR_PRODUCTS_TITLE },
              [
                _c("div", { staticClass: "imgbox" }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.$imagePath +
                        ourProduct.attribute.MAIN_OUR_PRODUCTS_IMAGES,
                      alt: "prod img",
                    },
                  }),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "our_product_slider_nav" }, [
        _c(
          "div",
          { staticClass: "slider-nav" },
          _vm._l(_vm.ourProductList, function (ourProduct) {
            return _c(
              "div",
              { key: ourProduct.attribute.MAIN_OUR_PRODUCTS_TITLE },
              [
                _c("div", { staticClass: "imgbox" }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.$imagePath +
                        ourProduct.attribute.MAIN_OUR_PRODUCTS_IMAGES,
                      alt: "our product nav",
                    },
                  }),
                ]),
                _c("div", { staticClass: "txtbox" }, [
                  _c("div", { staticClass: "logowrap" }, [
                    !ourProduct.imageLoadError &&
                    !ourProduct.imageBlackLoadError
                      ? _c("img", {
                          staticClass: "logo_img_bk",
                          attrs: {
                            src:
                              _vm.$imagePath +
                              "/2024/images/main/logo/black_" +
                              ourProduct.attribute.MAIN_OUR_PRODUCTS_TYPEText +
                              ".svg",
                            alt: "prod logo",
                          },
                          on: {
                            error: function ($event) {
                              ourProduct.imageLoadError = true
                            },
                            load: function ($event) {
                              ourProduct.imageLoadError = false
                            },
                          },
                        })
                      : _vm._e(),
                    !ourProduct.imageLoadError &&
                    !ourProduct.imageBlackLoadError
                      ? _c("img", {
                          staticClass: "logo_img_wh",
                          attrs: {
                            src:
                              _vm.$imagePath +
                              "/2024/images/main/logo/" +
                              ourProduct.attribute.MAIN_OUR_PRODUCTS_TYPEText +
                              ".svg",
                            alt: "prod logo",
                          },
                          on: {
                            error: function ($event) {
                              ourProduct.imageBlackLoadError = true
                            },
                            load: function ($event) {
                              ourProduct.imageBlackLoadError = false
                            },
                          },
                        })
                      : _vm._e(),
                    ourProduct.imageLoadError || ourProduct.imageBlackLoadError
                      ? _c("span", { staticClass: "logo" }, [
                          _vm._v(" " + _vm._s(ourProduct.content.title) + " "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub_tit" }, [
                      _vm._v(
                        _vm._s(ourProduct.attribute.MAIN_OUR_PRODUCTS_SUB_TITLE)
                      ),
                    ]),
                    _c("span", { staticClass: "txt_cont" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            ourProduct.attribute.MAIN_OUR_RODUCTS_DESCRIPTION
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "btnwrap" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn-more",
                        on: {
                          click: function ($event) {
                            return _vm.openLink(ourProduct)
                          },
                        },
                      },
                      [_c("span", [_vm._v("View more")])]
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "more" }, [
      _c(
        "a",
        { staticClass: "view_more", attrs: { href: "products/all_colors" } },
        [_c("span", [_vm._v("View more")]), _c("span", { staticClass: "icon" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }