<template>
  <!-- footer -->
  <footer>
    <!-- S : cookie -->
    <div class="wrapper-cokkie-dim" style="display: none;" v-if="!currentUrl.includes('/etc/privacy')">
      <div class="wrapper-cokkie">
        <div class="titwrap-">
          <button type="button" class="btn-close" @click="cookiesBtn(0);">
            <span class="sr-only">close</span>
          </button>
          <p class="tt-sub-gray">{{ $t('cookie.cookie_setting')}}</p>
          <p class="tt-sub">{{ $t('cookie.cookie_sub')}}</p>
        </div>
        <div class="container-cokkie">
          <ul class="chk-List">
            <li>
              <div class="chkArea strictly">
                <div class="tit">
                  <label class="inp-chk">
                    <input type="checkbox" class="chk foot-cok-checked" id="strictlyChk" v-model="cookie_check" value="1" disabled>
                    <span>{{ $t('cookie.strictly_cookies')}} : </span>
                  </label>
                  <!-- <button type="button" class="btn-view" v-on:click="cookieStrOpen"></button> -->
                </div>
                <p class="ctt ctt-t4 sub-tit">
                  {{ $t('cookie.strictly_sub')}}
                </p>
              </div>
            </li>
            <li>
              <div class="chkArea analytical">
                <div class="tit">
                  <label class="inp-chk">
                    <input type="checkbox" class="chk" id="analyticalChk" v-model="cookie_check" value="2">
                    <span>{{ $t('cookie.analytical_cookies')}} : </span>
                  </label>
                  <!-- <button type="button" class="btn-view" v-on:click="cookieAnalOpen"></button> -->
                </div>
                <p class="ctt ctt-t4 sub-tit">
                  {{ $t('cookie.analytical_sub')}}
                </p>
              </div>
            </li>
            <li>
              <div class="chkArea marketing">
                <div class="tit">
                  <label class="inp-chk">
                    <input type="checkbox" class="chk" id="marketingChk" v-model="cookie_check" value="3">
                    <span>{{ $t('cookie.marketing_cookies')}} : </span>
                  </label>
                  <!-- <button type="button" class="btn-view" v-on:click="cookieMarOpen"></button> -->
                </div>
                <p class="ctt ctt-t4 sub-tit">
                  {{ $t('cookie.marketing_sub')}}
                </p>
              </div>
            </li>
          </ul>
          <div class="cokkieBtn">
            <ul class="frow">
              <li clas="fcol fcol-6"><button type="button" class="" @click="cookiesBtn(1);"><span>{{ $t('cookie.privacy_policy')}}</span></button></li>
              <li clas="fcol fcol-6"><button type="button" class="" @click="cookiesBtn(2);"><span>{{ $t('cookie.allow_selection')}}</span></button></li>
              <li clas="fcol fcol-12"> <button type="button" class="btn_bk" @click="cookiesBtn(3);"><span>{{ $t('cookie.allow_all')}}</span></button></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- E : cookie -->

    <!-- pc -->
    <div class="pc-view" v-bind:class="{euSite: $countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'}">
      <div class="footer-cont">
        <div class="inner_line">
          <div class="logo-box">
            <div class="logo">{{ $t('footer.lx_hausys')}}</div>
          </div>
          <div class="comp-box">
            <div class="com-01">
              <div class="heading-2 footer-tit">
                COMPANY
              </div>
              <ul>
                <li>
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/aboutus/overview/business-area', true)">{{ $t('footer.about_us')}}</a>
                  <a v-else href="aboutus/overview/business-area">{{ $t('footer.about_us')}}</a>
                </li>
                <li v-if="$countryCode === '_US'">
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/aboutus/career', true)">{{ $t('footer.career')}}</a>
                  <a v-else href="aboutus/career">{{ $t('footer.career')}}</a>
                </li>
                <li>
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/aboutus/sustainability/sustainability-management', true)">{{ $t('footer.sustainability')}}</a>
                  <a v-else href="aboutus/sustainability/sustainability-management">{{ $t('footer.sustainability')}}</a>
                </li>
              </ul>
            </div>
            <div class="com-02">
              <div class="heading-2 footer-tit">
                <!-- <a class="footer-tit" href="javascript:void(0);"> -->
                {{ $t('footer.help')}}
                <!-- </a> -->
              </div>
              <ul>
                <li>
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/support/faq/all-products', true)">{{ $t('footer.faq')}}</a>
                  <a v-else href="support/faq/all-products">{{ $t('footer.faq')}}</a>
                </li>
                <li>
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/support/contactus', true)">{{ $t('footer.contact_us')}}</a>
                  <a v-else-if="$countryCode === '_GL' || $countryCode === '_US'" href="support/contactus">{{ $t('footer.contact_us')}}</a>
                  <a v-else href="support/contactus/pro">{{ $t('footer.contact_us')}}</a>
                </li>
                <li v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'">
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/etc/custSupport', true)">{{ $t('footer.cust_support')}}</a>
                  <a v-else href="etc/custSupport">{{ $t('footer.cust_support')}}</a>
                </li>
                <li>
                  <a v-if="$countryCode === '_US' && isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/support/where-to-buy/country/us', true)">{{ $t('footer.where_to_buy')}}</a>
                  <a v-else-if="$countryCode === '_US' && !isMicrosite" href="support/where-to-buy/country/us">{{ $t('footer.where_to_buy')}}</a>
                  <a v-if="$countryCode === '_GL'" href="support/where-to-buy/country/gl">{{ $t('footer.where_to_buy')}}</a>
                  <a v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'" href="support/where-to-buy/country/en">{{ $t('footer.where_to_buy')}}</a>
                  <a v-if="$countryCode === '_UK'" href="support/where-to-buy/country/uk">{{ $t('footer.where_to_buy')}}</a>
                </li>
                <li v-if="$countryCode === '_US'">
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/support/sales-representative', true)">{{ $t('footer.find_a_sales_rep')}}</a>
                  <a v-else href="/us/support/sales-representative">{{ $t('footer.find_a_sales_rep')}}</a>
                </li>
                <li v-if="$countryCode === '_GL'">
                  <a href="/gl/support/sales-representative">{{ $t('footer.find_a_sales_rep')}}</a>
                </li>
                <li v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'">
                  <a href="support/sales-representative/continenteu/hflor">{{ $t('footer.find_a_sales_rep')}}</a>
                </li>
                <li v-if="$countryCode === '_US'">
                  <a v-if="isMicrosite" @click="gotoUrl('https://www.lxhausys.com/us/etc/sitemap', true)">{{  $t('footer.site_map')}}</a>
                  <a v-else href="/us/etc/sitemap">{{  $t('footer.site_map')}}</a>
                </li>
                <li v-if="$countryCode === '_GL'">
                  <a href="/gl/etc/sitemap">{{  $t('footer.site_map')}}</a>
                </li>
              </ul>
            </div>
            <div class="com-03 conct-us">
              <div class="heading-2 footer-tit">FOLLOW US</div>
              <div class="cnctus">
                <div class="cnctus01" v-if="$countryCode === '_US'">
                  <!-- <div class="heading-3">{{ $t('footer.viatera')}}</div> -->
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="link insta_link" @click="clickInstaLink()">
                        <span class="icon icon_insta">{{ $t('footer.instagram')}}</span>
                      </a>
                      <div class="footer_sns_pop">
                        <ul class="sns">
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/hflorusa/', true)">
                              <span class="text">hflor</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/viaterausa/', true)">
                              <span class="text">viatera</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/himacsusa/', true)">
                              <span class="text">himacs</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/teracantousa/', true)">
                              <span class="text">teracanto</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://pin.it/6iWoROd8r ', true)">
                        <span class="icon icon_pinter">{{ $t('footer.pinterest')}}</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.youtube.com/@LXHausysUSA', true)">
                        <span class="icon icon_youtube">{{ $t('footer.youtube')}}</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.linkedin.com/company/lxhausysusa/', true)">
                        <span class="icon icon_link">linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="cnctus01" v-if="$countryCode === '_GL'">
                  <!-- <div class="heading-3">{{ $t('footer.viatera')}}</div> -->
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="link insta_link" @click="gotoUrl('https://www.instagram.com/lxhausys_global/', true)">
                        <span class="icon icon_insta">{{ $t('footer.instagram')}}</span>
                      </a>
                      <!-- <div class="footer_sns_pop">
                        <ul class="sns">
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/hflorusa/', true)">
                              <span class="text">hflor</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/viaterausa/', true)">
                              <span class="text">viatera</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/himacsusa/', true)">
                              <span class="text">himacs</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/teracantousa/', true)">
                              <span class="text">teracanto</span>
                            </a>
                          </li>
                        </ul>
                      </div> -->
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://kr.pinterest.com/LX_Hausys/_created/', true)">
                        <span class="icon icon_pinter">{{ $t('footer.pinterest')}}</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.youtube.com/@lxhausysglobal', true)">
                        <span class="icon icon_youtube">{{ $t('footer.youtube')}}</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.facebook.com/LXHausys', true)">
                        <span class="icon icon_facebook">facebook</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="cnctus01" v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'">
                  <div class="heading-3">{{ $t('footer.himacs')}}</div>
                  <ul>
                    <li><a href="javascript:void(0);" class="ico-i"  @click="gotoUrl('https://www.instagram.com/himacseurope/', true)">{{ $t('footer.instagram')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-f"  @click="gotoUrl('https://www.facebook.com/HIMACS', true)">{{ $t('footer.facebook')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-in" @click="gotoUrl('https://www.linkedin.com/company/himacs', true)">{{ $t('footer.linkedin')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-t"  @click="gotoUrl('https://twitter.com/himacseurope', true)">{{ $t('footer.twitter')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-p"  @click="gotoUrl('http://www.pinterest.com/HIMACS', true)">{{ $t('footer.pinterest')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-y"  @click="gotoUrl('https://www.youtube.com/himacseurope', true)">{{ $t('footer.youtube')}}</a></li>
                  </ul>
                </div>
                <div class="cnctus02" v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'">
                  <div class="heading-3">{{ $t('footer.hflor')}}</div>
                  <ul>
                    <li><a href="javascript:void(0);" class="ico-i"  @click="gotoUrl('https://www.instagram.com/hfloreurope/', true)">{{ $t('footer.instagram')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-f"  @click="gotoUrl('https://www.facebook.com/HFLOR-Europe-102610215614027', true)">{{ $t('footer.facebook')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-in" @click="gotoUrl('https://www.linkedin.com/company/80914597/admin/', true)">{{ $t('footer.linkedin')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-p"  @click="gotoUrl('https://www.pinterest.de/hfloreurope/', true)">{{ $t('footer.pinterest')}}</a></li>
                  </ul>
                </div>
                <div class="cnctus01" v-if="$countryCode === '_UK'">
                  <div class="heading-3">{{ $t('footer.himacs')}}</div>
                  <ul>
                    <li><a href="javascript:void(0);" class="ico-i"  @click="gotoUrl('https://www.instagram.com/himacseurope/', true)">{{ $t('footer.instagram')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-f"  @click="gotoUrl('https://www.facebook.com/HIMACS', true)">{{ $t('footer.facebook')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-in" @click="gotoUrl('https://www.linkedin.com/company/himacs', true)">{{ $t('footer.linkedin')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-t"  @click="gotoUrl('https://twitter.com/himacseurope', true)">{{ $t('footer.twitter')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-p"  @click="gotoUrl('http://www.pinterest.com/HIMACS', true)">{{ $t('footer.pinterest')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-y"  @click="gotoUrl('https://www.youtube.com/himacseurope', true)">{{ $t('footer.youtube')}}</a></li>
                  </ul>
                </div>
                <div class="cnctus02" v-if="$countryCode === '_UK'">
                  <div class="heading-3">{{ $t('footer.hflor')}}</div>
                  <ul>
                    <li><a href="javascript:void(0);" class="ico-i"  @click="gotoUrl('https://www.instagram.com/hflor.uk/', true)">{{ $t('footer.instagram')}}</a></li>
                    <li><a href="javascript:void(0);" class="ico-f"  @click="gotoUrl('https://www.facebook.com/HFLOR-UK-105446461990804', true)">{{ $t('footer.facebook')}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="com-05 display-eu">
              <div class="heading-2 footer-tit">
                <!-- <a class="footer-tit" href="javascript:void(0)"> -->
                {{ $t('footer.policy')}}
                <!-- </a> -->
              </div>
              <ul>
                <li>
                  <a href="etc/privacyPolicy">
                    {{ $t('footer.privacy_policy')}}
                  </a>
                </li>
                <li v-if="$countryCode !== '_UK'">
                  <a href="etc/imprint">
                    {{ $t('footer.imprint')}}
                  </a>
                </li>
                <li v-if="$countryCode === '_UK'">
                  <a href="etc/slaveStmt">
                    {{ $t('footer.slave_stmt')}}
                  </a>
                </li>
                <li>
                  <a href="etc/cookiePolicy">
                    {{ $t('footer.cookie_policy')}}
                  </a>
                </li>
                <li v-if="$countryCode !== '_UK'">
                  <a href="etc/rightOfDataRqstForm">
                    {{ $t('footer.right_of_the_data_subject')}}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="newsletter_box">
            <label for="inpNews" class="heading-2 footer-tit">
              {{ $t('footer.sign_up_newsletter')}}
            </label>
            <div class="news">
              <input
                type="text"
                id="inpNews"
                v-model="joinMail"
                placeholder="Enter your email"
                @keyup.enter="openNewsletterPopup()"
              />
              <button type="button" class="btn_newsletter" @click="openNewsletterPopup()" v-if="$countryCode === '_US' || $countryCode === '_GL'">{{ $t('footer.join')}}</button>
              <button type="button" @click="openNewsletterPopupEu('layer-newsletter-form')" v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'">{{ $t('footer.join')}}</button>
            </div>
          </div>

          <div class="cs-box">
            <div class="heading-2">{{ $t('footer.customer_support')}}</div>
            <p>
              <a href="mailto: websupport@lxhausys.com"
              >{{ $t('footer.websupport_email')}}</a
              >
              <span>{{ $t('footer.websupport_phone')}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="footer-copy">
        <div class="fcopy-wrap">
          <ul>
            <li v-if="$countryCode === '_US'"><a href="etc/donotsell">Do Not Sell My Personal Information</a></li>
            <li v-if="$countryCode === '_US' || $countryCode === '_GL'"><a href="etc/privacy">{{ $t('footer.privacy')}}</a></li>
            <li v-if="$countryCode === '_US' || $countryCode === '_GL'"><a href="etc/terms">{{ $t('footer.terms')}}</a></li>
            <li><a @click="cookieSettings()">{{ $t('cookie.cookie_setting')}}</a></li>
            <li v-if="$countryCode === '_US'"><a href="etc/rendering-imagedisclaimer">DISCLAIMER</a></li>
          </ul>
          <p class="copyright" v-bind:class="{uscopyright: $countryCode === '_US'}">
            {{ $t('footer.copyright')}}
          </p>
        </div>
      </div>

      <iframe
        name="ifrm"
        width="0"
        height="0"
        frameborder="0"
        style="display: none"
      ></iframe>
    </div>

    <!-- mobile -->
    <div class="mo-view">
      <div class="logo-box">
        <div class="logo">{{ $t('footer.lx_hausys')}}</div>
      </div>
      <div class="doc-box">
        <div class="fb-box">
          <div class="drop-list">
            <ul>
              <li>
                <div class="tit">
                  <a class="foot-tab" href="javascript:void(0);">ABOUT US</a>
                  <button type="button" class="btn-view">{{ $t('footer.list_view')}}</button>
                </div>
                <div class="cont">
                  <ul>
                    <li>
                      <a href="aboutus/overview/business-area">{{ $t('footer.about_us')}}</a>
                    </li>
                    <li v-if="$countryCode === '_US'"><a href="aboutus/career">{{ $t('footer.careers')}}</a></li>
                    <li>
                      <a href="aboutus/sustainability/sustainability-management">{{ $t('footer.sustainability')}}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div class="tit">
                  <a class="foot-tab" href="javascript:void(0);">{{ $t('footer.help')}}</a>
                  <button type="button" class="btn-view">{{ $t('footer.list_view')}}</button>
                </div>
                <div class="cont">
                  <ul>
                    <li v-if="$countryCode === '_GL' || $countryCode === '_US'">
                      <a href="support/contactus">{{ $t('footer.contact_us')}}</a>
                    </li>
                    <li v-else>
                      <a href="support/contactus/pro">{{ $t('footer.contact_us')}}</a>
                    </li>
                    <li v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'">
                      <a href="etc/custSupport">{{ $t('footer.cust_support')}}</a>
                    </li>
                    <li>
                      <a href="support/faq/all-products">{{ $t('footer.faq')}}</a>
                    </li>
                    <li>
                      <a v-if="$countryCode === '_US'" href="support/where-to-buy/country/us">{{ $t('footer.where_to_buy')}}</a>
                      <a v-if="$countryCode === '_GL'" href="support/where-to-buy/country/gl">{{ $t('footer.where_to_buy')}}</a>
                      <a v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'" href="support/where-to-buy/country/en">{{ $t('footer.where_to_buy')}}</a>
                      <a v-if="$countryCode === '_UK'" href="support/where-to-buy/country/uk">{{ $t('footer.where_to_buy')}}</a>
                    </li>
                    <li v-if="$countryCode === '_US'">
                      <a href="/us/support/sales-representative">{{ $t('footer.find_a_sales_rep')}}</a>
                    </li>
                    <li v-if="$countryCode === '_GL'">
                      <a href="/gl/support/sales-representative">{{ $t('footer.find_a_sales_representative')}}</a>
                    </li>
                    <li v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'">
                      <a href="/us/support/sales-representative">{{ $t('footer.find_a_sales_rep')}}</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div class="tit">
                  <a class="foot-tab" href="javascript:void(0);">FOLLOW US</a>
                  <button type="button" class="btn-view">{{ $t('footer.list_view')}}</button>
                </div>
                <div class="footer_sns_pop">
                  <ul class="sns">
                    <li>
                      <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/lxhausys_global/', true)">
                        <span class="text">hflor</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/lxhausys_global/', true)">
                        <span class="text">viatera</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/lxhausys_global/', true)">
                        <span class="text">himacs</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="sns_link" @click="gotoUrl('https://www.instagram.com/lxhausys_global/', true)">
                        <span class="text">teracanto</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="cont follow_us_con">
                  <div class="row">
                    <div class="sns_item">
                      <a href="javascript:void(0);" class="link insta_link" @click="clickInstaLink()">
                        <span class="icon icon_insta">{{ $t('footer.instagram')}}</span>
                      </a>
                    </div>
                    <div class="sns_item">
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.pinterest.co.kr/LX_Hausys/_created/ ', true)">
                        <span class="icon icon_pinter">{{ $t('footer.pinterest')}}</span>
                      </a>
                    </div>
                    <div class="sns_item">
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.youtube.com/channel/UC1BQZepu44PYt1qu7eoaF-w', true)">
                        <span class="icon icon_youtube">{{ $t('footer.youtube')}}</span>
                      </a>
                    </div>
                    <div class="sns_item" v-if="$countryCode === '_US'">
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.houzz.com/hznb/professionals/kitchen-and-bath-designers/hi-macs-usa-pfvwus-pf~1670062394?', true)">
                        <span class="icon icon_link">linkedin</span>
                      </a>
                    </div>
                    <div class="sns_item" v-if="$countryCode === '_GL'">
                      <a href="javascript:void(0);" class="link" @click="gotoUrl('https://www.houzz.com/hznb/professionals/kitchen-and-bath-designers/hi-macs-usa-pfvwus-pf~1670062394?', true)">
                        <span class="icon icon_facebook">facebook</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>

              <li v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'|| $countryCode === '_UK'">
                <div class="tit">
                  <a class="foot-tab" href="javascript:void(0);">{{ $t('footer.policy')}}</a>
                  <button type="button" class="btn-view">{{ $t('footer.list_view')}}</button>
                </div>
                <div class="cont">
                  <ul>
                    <li>
                      <a href="etc.html#/privacyPolicy">
                        {{ $t('footer.privacy_policy')}}
                      </a>
                    </li>
                    <li v-if="$countryCode !== '_UK'">
                      <a href="etc.html#/imprint">
                        {{ $t('footer.imprint')}}
                      </a>
                    </li>
                    <li v-if="$countryCode === '_UK'">
                      <a href="etc.html#/slaveStmt">
                        {{ $t('footer.slave_stmt')}}
                      </a>
                    </li>
                    <li>
                      <a href="etc.html#/cookiePolicy">
                        {{ $t('footer.cookie_policy')}}
                      </a>
                    </li>
                    <li v-if="$countryCode !== '_UK'">
                      <a href="etc.html#/rightOfDataRqstForm">
                        {{ $t('footer.right_of_the_data_subject')}}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div class="tit">
                  <a class="foot-tab" href="javascript:void(0);" @click="openNewsletterPopup()" v-if="$countryCode === '_US' || $countryCode === '_GL'">{{ $t('footer.sign_up_newsletter')}}</a>
                  <button type="button" class="btn_plus">{{ $t('footer.list_view')}}</button>
                </div>
                <div class="tit bd-b-1 m-footer-arr" v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES' || $countryCode === '_UK'">
                  <a class="foot-tab" href="javascript:void(0);" @click="openNewsletterPopupEu('layer-newsletter-form')" >{{ $t('footer.sign_up_newsletter')}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mo-warp inner">
        <!-- <div class="cnctus" v-if="$countryCode === '_US'">
          <p class="heading-5">{{ $t('footer.viatera')}}</p>
          <ul class="foot-ul">
            <li><a href="javascript:void(0);" class="ico-i" @click="gotoUrl('https://www.instagram.com/viaterausa/', true)">{{ $t('footer.instagram')}} x</a></li>
            <li><a href="javascript:void(0);" class="ico-p" @click="gotoUrl('https://www.pinterest.com/viaterausa/', true)">{{ $t('footer.pinterest')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-h" @click="gotoUrl('https://www.houzz.com/professionals/tile-stone-and-countertops/viatera-usa-pfvwus-pf~1133191478?', true)">{{ $t('footer.houzz')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-ytb" @click="gotoUrl('https://www.youtube.com/channel/UC1BQZepu44PYt1qu7eoaF-w', true)">{{ $t('footer.ytb')}}</a></li>
          </ul>
          <p class="heading-5">{{ $t('footer.himacs')}}</p>
          <ul class="foot-ul">
            <li><a href="javascript:void(0);" class="ico-i" @click="gotoUrl('https://www.instagram.com/himacsusa/', true)">{{ $t('footer.instagram')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-p" @click="gotoUrl('https://www.pinterest.com/himacsusa/', true)">{{ $t('footer.pinterest')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-h" @click="gotoUrl('https://www.houzz.com/hznb/professionals/kitchen-and-bath-designers/hi-macs-usa-pfvwus-pf~1670062394?', true ) ">{{ $t('footer.houzz')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-ytb" @click="gotoUrl('https://www.youtube.com/channel/UC1BQZepu44PYt1qu7eoaF-w', true)">{{ $t('footer.ytb')}}</a></li>
          </ul>
        </div> -->

        <div class="cnctus" v-if="$countryCode === '_EN' || $countryCode === '_FR'|| $countryCode === '_DE' || $countryCode === '_IT'|| $countryCode === '_ES'">
          <p class="heading-5">{{ $t('footer.himacs')}}</p>
          <ul class="foot-ul">
            <li><a href="javascript:void(0);" class="ico-i"   @click="gotoUrl('https://www.instagram.com/himacseurope/', true)">{{ $t('footer.instagram')}} x</a></li>
            <li><a href="javascript:void(0);" class="ico-f"   @click="gotoUrl('https://www.facebook.com/HIMACS', true)">{{ $t('footer.facebook')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-in"  @click="gotoUrl('https://www.linkedin.com/company/himacs', true)">{{ $t('footer.linkedin')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-t"   @click="gotoUrl('https://twitter.com/himacseurope', true)">{{ $t('footer.twitter')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-p"   @click="gotoUrl('http://www.pinterest.com/HIMACS', true)">{{ $t('footer.pinterest')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-ytb" @click="gotoUrl('https://www.youtube.com/himacseurope', true)">{{ $t('footer.ytb')}}</a></li>
          </ul>
          <p class="heading-5">{{ $t('footer.hflor')}}</p>
          <ul class="foot-ul">
            <li><a href="javascript:void(0);" class="ico-i"   @click="gotoUrl('https://www.instagram.com/hfloreurope/', true)">{{ $t('footer.instagram')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-f"   @click="gotoUrl('https://www.facebook.com/HFLOR-Europe-102610215614027', true)">{{ $t('footer.facebook')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-in"  @click="gotoUrl('https://www.linkedin.com/company/80914597/admin/', true)">{{ $t('footer.linkedin')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-p"   @click="gotoUrl('https://www.pinterest.de/hfloreurope/', true)">{{ $t('footer.pinterest')}}</a></li>
          </ul>
        </div>
        <div class="cnctus" v-if="$countryCode === '_UK'">
          <p class="heading-5">{{ $t('footer.himacs')}}</p>
          <ul class="foot-ul">
            <li><a href="javascript:void(0);" class="ico-i"   @click="gotoUrl('https://www.instagram.com/himacseurope/', true)">{{ $t('footer.instagram')}} x</a></li>
            <li><a href="javascript:void(0);" class="ico-f"   @click="gotoUrl('https://www.facebook.com/HIMACS', true)">{{ $t('footer.facebook')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-in"  @click="gotoUrl('https://www.linkedin.com/company/himacs', true)">{{ $t('footer.linkedin')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-t"   @click="gotoUrl('https://twitter.com/himacseurope', true)">{{ $t('footer.twitter')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-p"   @click="gotoUrl('http://www.pinterest.com/HIMACS', true)">{{ $t('footer.pinterest')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-ytb" @click="gotoUrl('https://www.youtube.com/himacseurope', true)">{{ $t('footer.ytb')}}</a></li>
          </ul>
          <p class="heading-5">{{ $t('footer.hflor')}}</p>
          <ul>
            <li><a href="javascript:void(0);" class="ico-i"   @click="gotoUrl('https://www.instagram.com/hflor.uk/', true)">{{ $t('footer.instagram')}}</a></li>
            <li><a href="javascript:void(0);" class="ico-f"   @click="gotoUrl('https://www.facebook.com/HFLOR-UK-105446461990804', true)">{{ $t('footer.facebook')}}</a></li>
          </ul>
        </div>
        <div class="policy" v-bind:class="{uspolicy: $countryCode === '_US'}" v-if="$countryCode === '_US'">
          <span class="donotsell"><a href="etc/donotsell">Do Not Sell My Personal Information</a></span>
          <span class="privacy"><a href="etc/privacy">{{ $t('footer.privacy')}}</a></span>
          <span class="clear-line"></span>
          <span class="terms"><a href="etc/terms">{{ $t('footer.terms')}}</a></span>
          <span class="cookie"><a @click="cookieSettings();">{{ $t('cookie.cookie_setting')}}</a></span>
          <span class="disclaimer noline"><a href="etc/rendering-imagedisclaimer">DISCLAIMER</a></span>
        </div>
        <!-- <div class="policy" v-if="$countryCode === '_GL'">
        </div> -->
        <div class="policy" v-if="$countryCode === '_GL'">
          <!-- <span><a href="etc/donotsell">Do Not Sell My Personal Information</a></span> -->
          <span class="privacy"><a href="etc/privacy">{{ $t('footer.privacy')}}</a></span>
          <span class=""><a href="etc/terms">{{ $t('footer.terms')}}</a></span>
          <span><a @click="cookieSettings();">{{ $t('cookie.cookie_setting')}}</a></span>
          <!-- <span class=""><a href="etc/rendering-imagedisclaimer">DISCLAIMER</a></span> -->
        </div>
        <p class="copyright">
          {{ $t('footer.copyright')}}
        </p>
      </div>
    </div>

    <!-- S : popup : Sign Up Newsletter Result -->
    <div class="layer-popup pop-footer-news popNewsletter_RES">
      <div class="popup">
        <div class="scr-y">
          <div class="tit-box">
            <div class="heading-4">{{ $t('footer.newsletter')}}</div>
          </div>
          <div class="cont-box ta-c">
            <strong class="ico-news-res">{{ $t('footer.thank_you_for_subscribing1')}}</strong>
            <p v-html="$t('footer.thank_you_for_subscribing2')"></p>
          </div>
          <div class="more-box ta-c">
            <button
              type="button"
              class="btn btn-type-large"
              @click=" closePopup(); gotoUrl('index', false);"
            >
              <span>{{ $t('footer.visit_lx_hausys')}}</span>
            </button>
          </div>
          <button
            type="button"
            class="btn-close"
            onclick="LayerPopup.close();"
          >
            {{ $t('footer.close')}}
          </button>
        </div>
      </div>
    </div>
    <!-- E : popup : Sign Up Newsletter Result -->

    <!---------- S : popup - Sign Up Newsletter ---------->
    <div class="layer-popup pop-footer-news popNewsletter">
      <div class="popup">
        <div class="scr-y">
          <div class="tit-box">
            <p class="pop_title tt-24">{{ $t('footer.newsletter')}}</p>
          </div>
          <div class="frm-box">
            <form method="post" :name="form.eloqua.name" action="https://s1110900.t.eloqua.com/e/f2" id="form68" class="elq-form">
              <input :value="form.eloqua.elqFormName" type="hidden" name="elqFormName"/>
              <input value="1110900" type="hidden" name="elqSiteId" />
              <input name="elqCampaignId" type="hidden" />
              <div class="tbl-input">
                <table>
                  <caption>
                    {{ $t('footer.order_form')}}
                  </caption>
                  <colgroup>
                    <col style="width: 210px" />
                    <col style="width: auto" />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th scope="row">
                      <label for="inp09" class="must">{{ $t('footer.email')}}</label>
                    </th>
                    <td class="cell-noti">
                      <input type="text" id="fe743" name="emailAddress"
                             class="inp-text" v-model="form.eloqua.email" placeholder="Input Your Email" autocomplete="off"/>
                      <!--  @keyup="validFunction('email')" -->
                      <p v-if="form.execValidFlag && !validation.email.value && !emailRule.test(form.eloqua.email)">
                        {{ $t('footer.email_required')}}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><span class="must">{{ $t('footer.country')}}</span></th>
                    <td class="cell-noti">
                      <div id="countryBox" class="sel-box type02">
                        <input type="hidden" id="fe744" :name="form.eloqua.countryName" v-model="form.eloqua.country"/>
                        <button id="btnCountry" type="button" class="btn-sel">
                          <span>{{ form.selectedCountry }}</span>
                        </button>
                        <div id="countryList" class="sel-list">
                          <ul class="box">
                            <li v-for="(item, index) in form.countries" :key="item.countryNm">
                              <button type="button" @click="selectCountry(item.countryNm)">
                                {{ item.countryNm }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p v-if="form.execValidFlag && !validation.country.value && form.eloqua.country == ''">
                        {{ $t('footer.please_select_country')}}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><span class="must">{{ $t('footer.iam')}}</span></th>
                    <td class="cell-noti">
                      <div id="occBox" class="sel-box type02">
                        <input type="hidden" id="fe746" :name="form.eloqua.occuName" v-model="form.eloqua.occupation"/>
                        <button id="btnOcc" type="button" class="btn-sel">
                          <span v-html="form.selectedOccupation"></span>
                        </button>
                        <div id="occList" class="sel-list">
                          <ul class="box">
                            <li v-for="(item, index) in form.occupations" :key="item.val">
                              <button type="button" @click="selectOccupation(item.name)" v-html="item.name">
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p v-if="form.execValidFlag && !validation.occupation.value && form.eloqua.occupation == ''">
                        {{ $t('footer.please_select_occupation')}}
                      </p>
                    </td>
                  </tr>
                  <tr class="news-tr-interest">
                    <th scope="row">
                      <label for="inp10" class="must">
                        {{ $t('footer.products')}}
                      </label>
                    </th>
                    <td class="cell-noti">
                      <ul class="prod-interest">
                        <!--US Start-->
                        <li v-if="$countryCode === '_US'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-3_puvm05n" value="HIMACS" :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.himacs2')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_US'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-20_9rbu3hf4" value="Viatera" :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.viatera3')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_US'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-21_d4xvqbo" value="HFLOR" :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.hflor2')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_US'"><!-- 2023/09/18 teracanto 추가 -->
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-TERACANTO_giypwupk" value="TERACANTO" :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.teracanto')}}</span>
                          </label>
                        </li>
                        <!--GL Start-->
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-3_puvm05n" value="HIMACS"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.himacs2')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-20_9rbu3hf4" value="Viatera"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.viatera3')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-21_d4xvqbo" value="HFLOR"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.hflor2')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'"><!-- 2023/09/18 teracanto GL 추가 -->
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-TERACANTO_wofq8iam" value="TERACANTO"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.teracanto')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'"><!-- 2023/09/18 benif 추가 -->
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-BENIF_46o16bqq" value="BENIF"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.benif')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-101_uodyayoq" value="Deco Foil"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.deco_foil')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-102_nhhfa3ye" value="Exterior Foil"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.exterior_foil')}}</span>
                          </label>
                        </li>
                        <!--                          <li v-if="$countryCode === '_GL'"> &lt;!&ndash; 2023/09/18 new 폼대로 제외 &ndash;&gt;-->
                        <!--                            <label class="inp-chk">-->
                        <!--                              <input type="checkbox" id="checkbox-23_eavphx5h" value="Interior Film"-->
                        <!--                                :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>-->
                        <!--                              <span>{{ $t('footer.interior_film')}}</span>-->
                        <!--                            </label>-->
                        <!--                          </li>-->
                        <!--                            <li v-if="$countryCode === '_GL'">-->
                        <!--                              <label class="inp-chk">-->
                        <!--                                <input type="checkbox" id="checkbox-24_ouvutq1r" value="Window"-->
                        <!--                                  :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>-->
                        <!--                                <span>{{ $t('footer.window')}}</span>-->
                        <!--                              </label>-->
                        <!--                            </li>-->
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-103_emupuoh" value="Sign & Graphic"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.vizuon')}}</span>
                          </label>
                        </li>
                        <!--                          <li v-if="$countryCode === '_GL'"> &lt;!&ndash; 2023/09/18 new 폼대로 제외 &ndash;&gt;-->
                        <!--                            <label class="inp-chk">-->
                        <!--                              <input type="checkbox" id="checkbox-25_xq62whi" value="Functional Glass"-->
                        <!--                                :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>-->
                        <!--                              <span>{{ $t('footer.func_glass')}}</span>-->
                        <!--                            </label>-->
                        <!--                          </li>-->
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-139_r1v7h47a" value="Vinyl Coated Material"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.vinyl_coated_material')}}</span>
                          </label>
                        </li>
                        <!--                          <li v-if="$countryCode === '_GL'"> &lt;!&ndash; 2023/09/18 new 폼대로 제외 &ndash;&gt;-->
                        <!--                            <label class="inp-chk">-->
                        <!--                              <input type="checkbox" id="checkbox-22_apr80rws" value="Wallcovering"-->
                        <!--                                :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>-->
                        <!--                              <span>{{ $t('footer.wallcovering')}}</span>-->
                        <!--                            </label>-->
                        <!--                          </li>-->
                        <li v-if="$countryCode === '_GL'">
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-19_09gvgi2s" value="Automotive"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.automotive')}}</span>
                          </label>
                        </li>
                        <li v-if="$countryCode === '_GL'"><!-- 2023/09/18 All Brands 추가 -->
                          <label class="inp-chk">
                            <input type="checkbox" id="checkbox-All Brands_l3qie7mg" value="All Brands"
                                   :name="form.eloqua.interestName" v-model="form.eloqua.interest"/>
                            <span>{{ $t('footer.all_brands')}}</span>
                          </label>
                        </li>
                      </ul>
                      <p v-if="form.execValidFlag && !validation.interest.value && form.eloqua.interest.length === 0">
                        {{ $t('footer.plz_sel_prd')}}
                      </p>
                    </td>
                  </tr>
                  <!-- <tr v-if="$countryCode === '_US'">
                    <td class="cell-noti" colspan="2">
                      <p class="ta-l">Please select all that you’re interested in</p>
                    </td>
                  </tr> -->
                  </tbody>
                </table>
              </div>
              <input type="hidden" v-if="$countryCode === '_GL'" name="source" id="fe1038" value="LXHausys.com"/>
              <div>
                <label class="inp-chk news-agree">
                  <input type="checkbox" v-model="form.agree" :name="form.eloqua.prvcName"/>
                  <span >{{ $t('footer.yes_agree_terms')}}
                  <a href="javascript:void(0);" @click="openPrivacy()">
                    {{ $t('footer.privacy_policy')}}
                  </a>.
                </span>
                </label>
                <p v-if="form.execValidFlag && !validation.agree.value && form.agree == ''" class="noti-02">
                  {{ $t('footer.please_check_agreement')}}
                </p>
              </div>
            </form>
          </div>
          <div class="more-box">
            <button type="button" class="button h55 btn_submit" @click="submitForm()">
              <span class="txt">{{ $t('footer.submit')}}</span>
            </button>
          </div>
          <button type="button" class="btn btn-close" @click="closePopup()">
            {{ $t('footer.close')}}
          </button>
        </div>
      </div>
    </div>
    <!---------- E : popup - Sign Up Newsletter ---------->

    <!---------- S : popup - newsletter ad ---------->
    <div class="layer-view popup-v2 popup-v2-w1280 layer-newsletter-ad">
      <div class="popup pop-close">
        <div class="scr-y">
          <div class="col-02">
            <div class="tac">
              <div class="popup-title footer-pop-tit">{{$t('footer.newsletter_009')}}</div>
              <div class="popup-body">
                <p class="ctt ctt-t2">
                  <span class="ctt-t2">{{$t('footer.newsletter_010')}}&nbsp;</span>
                  <span class="ctt-t2">{{$t('footer.newsletter_011')}}</span>
                </p>
              </div>
              <div class="popup-footer">
                <div class="frow">
                  <div class="fcol">
                    <button type="button" class="btn" @click="clickToReg()">
                      <span>{{$t('footer.newsletter_012')}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="newsletter-bg">
              <img src='https://img.lxhausys.com/public/images/img-newsletter.png' alt="newsletter background">
            </div>
          </div>
        </div>
        <!-- close -->
        <button type="button" class="btn-close" @click="closeNewsletterAdPopup()">{{ $t('footer.close')}}</button>
      </div>
    </div>
    <!---------- E : popup - newsletter ad ---------->

    <!---------- S : popup - newsletter form ---------->
    <div class="layer-view layer-newsletter-form popup-v2 popup-v2-w740">
      <div class="popup pop-close">
        <div class="scr-y">
          <div class="popup-head">
            <div class="tt-2">{{$t('footer.newsletter')}}</div>
            <p class="ctt ctt-t4">{{$t('footer.newsletter_001')}}</p>
          </div>
          <div class="popup-body">
            <div class="frow">
              <div class="fcol">
                <div class="email-area">
                  <div class="input-group">
                    <label for="x1" class="required">
                      <span>Email</span>
                    </label>
                    <div class="inp-control">
                      <input type="text" id="x1" class="inp-text" placeholder="Inputlxinfo@lxhausys.com" v-model="form.eu.email"/>
                      <span class="alert" v-bind:class="{on: form.execValidFlag && !validationEu.email.value && !emailRule.test(form.eu.email)}">A valid email address is required</span>
                    </div>
                  </div>
                </div>
                <div class="interest-area">
                  <div class="input-group input-chk">
                    <label for="x2" class="required">
                      <span class="news-con-tit-pd">{{$t('footer.products')}}</span>
                    </label>
                    <div class="inp-control">
                      <label class="inp-chk">
                        <input type="checkbox" v-model="form.eu.newsletterHflor"/>
                        <span>HFLOR</span>
                      </label>
                      <label class="inp-chk">
                        <input type="checkbox" v-model="form.eu.newsletterHimacs"/>
                        <span>HIMACS</span>
                      </label>
                      <span class="alert" v-bind:class="{on: form.execValidFlag && !validationEu.interest.value && form.eu.newsletterHflor == 0 && form.eu.newsletterHimacs == 0}">{{$t('footer.plz_sel_prd')}}</span>
                    </div>
                  </div>
                </div>
                <div class="agree-box">
                  <label class="inp-chk">
                    <input type="checkbox" v-model="form.agree">

                    <!-- [모바일][Footer > Newsletter] Newsletter 폼 클래스 추가 -->
                    <!-- 기존코드 -->
                    <span class="ctt ctt-t4" v-html="$t('footer.newsletter_002')"></span>
                    <!-- 수정할 코드 -->
                    <!-- <span class="tt ctt-t4">Yes, I agree with the terms of use as described in the <a class="color-red required" href="etc.html#/privacyPolicy">Privacy Policy.</a></span> -->

                  </label>
                  <span class="alert" v-bind:class="{on: form.execValidFlag && !validationEu.agree.value && form.agree == ''}">{{$t('footer.please_check_agreement')}}</span>
                </div>
                <div class="more-detail">
                  <p v-html="$t('footer.newsletter_003')"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button type="button" class="btn" @click="submitFormEu()">
              <span>{{$t('footer.submit')}}</span>
            </button>
          </div>
        </div>
        <!-- close -->
        <button type="button" class="btn-close" @click="closePopupEu()">{{ $t('footer.close')}}</button>
      </div>
    </div>
    <!---------- E : popup - newsletter form ---------->

    <!---------- S : popup - newsletter result ---------->
    <div class="layer-view layer-newsletter-result popup-v2 popup-v2-w740">
      <div class="popup pop-close">
        <div class="scr-y">
          <div class="popup-head">
            <div class="tt-2">{{$t('footer.newsletter')}}</div>
          </div>
          <div class="popup-body">
            <div class="frow">
              <div class="fcol tac">
                <i class="pcon pcon-check"></i>
                <p class="ctt ctt-t1">{{$t('footer.newsletter_006')}}</p>
                <p class="pb-des">
                  <span class="tac ctt ctt-t1">{{$t('footer.newsletter_007')}}</span>
                  <span class="ctt ctt-t5">{{$t('footer.newsletter_007_1')}}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <button type="button" class="btn" @click="gotoHome()">
              <span>{{$t('footer.newsletter_008')}}</span>
            </button>
          </div>
        </div>
        <!-- close -->
        <button type="button" class="btn-close" @click="closePopupEu()">{{ $t('footer.close')}}</button>
      </div>
    </div>
    <!---------- E : popup - newsletter result ---------->

    <SampleOrderPopComponent></SampleOrderPopComponent>

    <!-- S : contact us floating button -->
    <!-- <a class="btn-cntu-float" href="support/contactus" v-if="$countryCode != '_US' && $countryCode != '_GL'">Contact Us</a> -->
    <!-- E : contact us floating button -->
  </footer>
  <!-- //footer -->
</template>
<script>
import _ from '@/common/helper/lodash.js'
import SampleOrderPopComponent from '@/components/Popup/SampleOrder'
import etcService from '@/server/etc.service'
import mailchimpService from '@/server/mailchimp.service'
import usService from '@/server/us.service'
import { mapGetters } from 'vuex'

let popupId = null
export default {
  name: 'FooterComponent',
  props: {
    isMicrosite: Boolean
  },
  components: {
    SampleOrderPopComponent
  },
  data () {
    return {
      is_show: false,
      snsInsta: false, // 인스타 아이콘 클릭시 팝업
      snsPinter: false,
      snsYoutube: false,
      snsLink: false,
      form: {
        agree: false,
        countries: [],
        occupations: [],
        selectedCountry: this.$t('footer.select_country'),
        selectedOccupation: this.$t('footer.select_occupation'),
        eloqua: {
          name: '',
          elqFormName: '',
          email: '',
          country: '',
          occupation: '',
          interest: [],
          countryName: '',
          interestName: '',
          prvcName: '',
          occuName: ''
        },
        eu: {
          email: '',
          newsletterHflor: '',
          newsletterHimacs: ''
        },
        execValidFlag: false
      },
      joinMail: '',
      validation: {
        email: {
          value: false
        },
        country: {
          value: false
        },
        occupation: {
          value: false
        },
        interest: {
          value: false
        },
        agree: {
          value: false
        }
      },
      validationEu: {
        email: {
          value: false
        },
        interest: {
          value: false
        },
        agree: {
          value: false
        }
      },
      orgValue: {
        agree: false,
        countries: [],
        occupations: [],
        selectedCountry: this.$t('footer.select_country'),
        selectedOccupation: this.$t('footer.select_occupation'),
        eloqua: {
          name: '',
          elqFormName: '',
          email: '',
          country: '',
          occupation: '',
          interest: [],
          countryName: '',
          interestName: '',
          prvcName: '',
          occuName: ''
        },
        eu: {
          email: '',
          audience: '',
          newsletterHflor: '',
          newsletterHimacs: ''
        },
        execValidFlag: false
      },
      emailRule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, // 이메일 정규식
      cookie_check: [],
      currentUrl: ''
    }
  },
  async created () {
    if (this.$countryCode === '_US') {
      this.form.countries = [
        { countryCode: 'USA', countryNm: this.$t('footer.usa') },
        { countryCode: 'CANADA', countryNm: this.$t('footer.canada') }
      ]
      this.orgValue.countries = [
        { countryCode: 'USA', countryNm: this.$t('footer.usa') },
        { countryCode: 'CANADA', countryNm: this.$t('footer.canada') }
      ]
    } else {
      // 국가 전체
      const res = await usService.getWorldCountryList()
      this.form.countries = res.data
      this.orgValue.countries = res.data
    }
    this.currentUrl = location.href
    this.form.occupations = [
      { val: this.$t('footer.lx_hausys_distributor'), name: this.$t('footer.lx_hausys_distributor') },
      { val: this.$t('footer.lx_hausys_dealer'), name: this.$t('footer.lx_hausys_dealer') },
      { val: this.$t('footer.distributor'), name: this.$t('footer.distributor') },
      { val: this.$t('footer.dealer'), name: this.$t('footer.dealer') },
      { val: this.$t('footer.architect_designer'), name: this.$t('footer.architect_designer') },
      { val: this.$t('footer.end_user'), name: this.$t('footer.end_user') }
    ]
    this.orgValue.occupations = [
      { val: this.$t('footer.lx_hausys_distributor'), name: this.$t('footer.lx_hausys_distributor') },
      { val: this.$t('footer.lx_hausys_dealer'), name: this.$t('footer.lx_hausys_dealer') },
      { val: this.$t('footer.distributor'), name: this.$t('footer.distributor') },
      { val: this.$t('footer.dealer'), name: this.$t('footer.dealer') },
      { val: this.$t('footer.architect_designer'), name: this.$t('footer.architect_designer') },
      { val: this.$t('footer.end_user'), name: this.$t('footer.end_user') }
    ]

    // 쿠키 페이지 끄기버튼(.btn-close)
    $('.wrapper-cokkie .btn-close').on('click', function () {
      $('.wrapper-cokkie-dim').css({ display: 'none' });
      $('body').removeClass('scrolllock');
    })

    // 쿠키 자세히보기 화살표 클릭시 열림
    $('.btn-view').click(function () {
      if ($(this).hasClass('on')) {
        $(this).parent().parent().find('.sub-tit').css('display', '')
        $(this).removeClass('on')
      } else {
        $('.wrapper-cokkie').find('.sub-tit').css('display', '')
        $('.wrapper-cokkie').find('.btn-view').removeClass('on')
        $(this).parent().parent().find('.sub-tit').css('display', 'block')
        $(this).addClass('on')
      }
    })
  },
  async mounted () {
    let value, storage
    console.log('countryCode : ' + this.$countryCode)
    if (this.$countryCode === '_GL') {
      value = this.getCookie('layerCookiePopGL')
      storage = localStorage.getItem('layerCookiePopGL')
    } else {
      value = this.getCookie('layerCookiePop')
      storage = localStorage.getItem('layerCookiePop')
    }

    if ((!value && this.cookie_check.length == 0 && !storage) || (!storage && !value)) {
      setTimeout(() => {
        $('.wrapper-cokkie-dim').show();
        $('body').addClass('scrolllock');
      }, 5000)
    }
    if (this.$countryCode === '_EN' || this.$countryCode === '_FR' || this.$countryCode === '_DE' || this.$countryCode === '_IT' || this.$countryCode === '_ES' || this.$countryCode === '_UK') {
      if (this.neverMissPopupYn) {
        LogOutTimer.limit = 1000 * 30
        LogOutTimer.start()
      } else {
        LogOutTimer.is_pop = false
      }
    } else {
      LogOutTimer = null
    }

    // newsletter layer popup open
    popupId = this.$route.query.popupId
    if (popupId !== null && popupId !== undefined) {
      if (this.$countryCode === '_US') {
        if (popupId === 'popNewsletter') {
          this.openNewsletterPopup()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      neverMissPopupYn: 'header/getNeverMissPopup'
    })
  },
  methods: {
    init () {
      this.form = _.cloneDeep(this.orgValue) // form setting
      // eloquoa 국가별 hidden form value
      if (this.$countryCode === '_US') {
        this.form.eloqua.name = 'lxhausys.com_US_NewsletterSubscription'
        this.form.eloqua.elqFormName = 'lxhausys.com_US_NewsletterSubscription'
        this.form.eloqua.countryName = 'country'
        this.form.eloqua.interestName = 'products_of_interest'
        this.form.eloqua.occuName = 'occupation1'
        this.form.eloqua.prvcName = ''
        this.form.eloqua.email = this.joinMail
      } else if (this.$countryCode === '_GL') {
        this.form.eloqua.name = 'lxhausys.com_NewsletterSubscription'
        this.form.eloqua.elqFormName = 'lxhausys.com_NewsletterSubscription'
        this.form.eloqua.countryName = 'country'
        this.form.eloqua.interestName = 'products_of_interest'
        this.form.eloqua.occuName = 'occupation1'
        this.form.eloqua.prvcName = 'privacyPolicy1'
        this.form.eloqua.email = this.joinMail
      } else {
        this.form.eu.email = this.joinMail
      }
      this.$nextTick(function () {})
    },
    gotoUrl (url, isNewTab) {
      if (isNewTab) {
        window.open(url, '_blank')
      } else {
        location.href = url
      }
    },
    openPrivacy () {
      window.open('etc/privacy?pop=Y', 'privacy', 'width:800px')
    },
    openPrivacyEu () {
      window.open('etc/privacyPolicy?pop=Y', 'privacy', 'width:800px')
    },
    openNewsletterPopup () {
      // alert('It is currently under maintenance.');
      // return;
      LayerPopup.open('popNewsletter')
      this.init()
    },
    openNewsletterPopupEu (str) {
      if (str == 'layer-newsletter-form') {
        if (!_.isEmpty($('#inpNews').val()) && !this.emailRule.test($('#inpNews').val())) {
          alert(this.$t('footer.email_alert'))
          return
        }
      }

      LayerPopup.open(str)
      this.init()
      // $('body').css("overflow", "hidden");
    },
    closeNewsletterAdPopup () {
      this.setSessStorage()
      this.closePopupEu()
    },
    selectCountry (countryNm) {
      this.form.eloqua.country = countryNm
      this.form.selectedCountry = countryNm
    },
    selectOccupation (name) {
      this.form.eloqua.occupation = name
      this.form.selectedOccupation = name
    },
    validFunction (name) {
      const that = this
      return {
        email: function () {
          const regex =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식
          that.validation.email.value = regex.test(that.form.eloqua.email)
        },
        country: function () {
          if (that.form.eloqua.country !== '') {
            that.validation.country.value = true
          } else {
            that.validation.country.value = false
          }
        },
        occupation: function () {
          if (that.form.eloqua.occupation !== '') {
            that.validation.occupation.value = true
          } else {
            that.validation.occupation.value = false
          }
        },
        interest: function () {
          // if (that.$countryCode === '_US') {
          if (that.form.eloqua.interest.length > 0) {
            that.validation.interest.value = true
          } else {
            that.validation.interest.value = false
          }
          // } else {
          //   that.validation.interest.value = true
          // }
        },
        agree: function () {
          if (that.form.agree) {
            that.validation.agree.value = true
          } else {
            that.validation.agree.value = false
          }
        }
      }[name]()
    },
    validFunctionEu (name) {
      const that = this
      return {
        email: function () {
          const regex =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식
          that.validationEu.email.value = regex.test(that.form.eu.email)
        },
        interest: function () {
          if ((that.form.eu.newsletterHflor ? 1 : 0) + (that.form.eu.newsletterHimacs ? 1 : 0) > 0) {
            that.validationEu.interest.value = true
          } else {
            that.validationEu.interest.value = false
          }
        },
        agree: function () {
          if (that.form.agree) {
            that.validationEu.agree.value = true
          } else {
            that.validationEu.agree.value = false
          }
        }
      }[name]()
    },
    validateForm () {
      const that = this
      let flagCnt = 0
      _.forIn(this.validation, function (v, k) {
        that.validFunction(k)
        if (!v.value) {
          flagCnt++
        }
      })
      this.form.execValidFlag = true
      return !(flagCnt > 0)
    },
    validateFormEu () {
      const that = this
      let flagCnt = 0
      _.forIn(this.validationEu, function (v, k) {
        that.validFunctionEu(k)
        if (!v.value) {
          flagCnt++
        }
      })
      this.form.execValidFlag = true
      return !(flagCnt > 0)
    },
    submitForm () {
      if (!this.validateForm()) {
        return
      }
      // elpqua form submit
      $('#form68').attr('target', 'ifrm')
      $('#form68').submit()

      // GTM - Newsletter
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({
          event: 'newsLetterComp',
          news_country: this.form.eloqua.country,
          news_occupation: this.form.eloqua.occupation,
          news_product_interest: this.form.eloqua.interest.join('|') || ''
        })
      }

      this.closePopup()
      LayerPopup.open('popNewsletter_RES')
    },
    closeInstaPop () {
      $('.footer_sns_pop').removeClass('visible')
    },
    openInstaPop () {
      $('.footer_sns_pop').addClass('visible')
    },
    clickInstaLink () {
      const instaFlag = $('.footer_sns_pop').hasClass('visible')
      if (instaFlag) {
        this.closeInstaPop()
      } else {
        this.openInstaPop()
      }
    },
    async submitFormEu () {
      if (!this.validateFormEu()) {
        return
      }
      const postData = {}
      postData.formType = 'FT'
      postData.email = this.form.eu.email
      postData.audience = this.$countryCode
      postData.newsletterHflor = this.form.eu.newsletterHflor ? 1 : 0
      postData.newsletterHimacs = this.form.eu.newsletterHimacs ? 1 : 0

      const res = await etcService.newsletterSave(postData)

      if (res) {
        this.setMailchimp()
        this.closePopupEu()
        LayerPopup.open('layer-newsletter-result')
        this.setSessStorage()
      }
    },
    async setMailchimp () {
      const postData = {}
      postData.email = this.form.eu.email
      postData.inquery_cam = 'a0T6900000Y28NQEAZ'
      postData.type = 'newsletter'
      if (this.form.eu.newsletterHflor == 1) {
        postData.language = 'EN'
        postData.product = 'HFLOR'
        await mailchimpService.subscribe(postData)
      }
      if (this.form.eu.newsletterHimacs == 1) {
        postData.language = this.$countryCode.replace('_', '')
        postData.product = 'HIMACS'
        await mailchimpService.subscribe(postData)
      }
    },
    closePopup () {
      this.joinMail = ''
      LayerPopup.close()
    },
    closePopupEu () {
      $('div.layer-view').each(function (index, item) {
        $(this).fadeOut(300)
        $(this).removeAttr('tabindex')
      })
      // $('body').css("overflow", "scroll");
    },
    clickToReg () {
      this.setSessStorage()
      this.closePopupEu()
      this.openNewsletterPopupEu('layer-newsletter-form')
    },
    gotoHome () {
      this.closePopupEu()
      this.gotoUrl('index')
    },
    setSessStorage () {
      this.$store.commit('header/setNeverMissPopupClose')
      LogOutTimer.is_pop = false
    },
    cookiesBtn (i) {
      let cookieVal
      if (this.$countryCode === '_GL') {
        cookieVal = this.getCookie('layerCookiePopGL')
      } else {
        cookieVal = this.getCookie('layerCookiePop')
      }

      let cookieChk = false
      if ((i === 0 || i === 1) && cookieVal) {
        cookieChk = true
      }
      const date = new Date()
      date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
      const expiresVal = date.toGMTString()
      let cookieConsentType = ''
      if (i === 0) {
        // 쿠키 미동의(url만 저장)
        cookieConsentType = 'deny'
        $('.wrapper-cokkie-dim').css({ display: 'none' });
        $('body').removeClass('scrolllock');
      } else if (i === 1) {
        // privacy policy(url만 저장)
        cookieConsentType = 'deny'
        location.href = 'etc/privacy'
        $('.wrapper-cokkie-dim').css({ display: 'none' });
        $('body').removeClass('scrolllock');
      } else if (i === 2) {
        // 쿠키 선택 동의
        cookieConsentType = 'check_allow'
        let chkval = ''
        let allowVal = ''
        $('.chk.foot-cok-checked').prop('checked', true)
        $('.chk:checked').each(function (index) {
          chkval = $(this).val()
          if (chkval == 1) {
            // 필수체크
            allowVal = '_1'
          } else if (chkval == 2) {
            allowVal += '_2'
            var value = document.cookie.match('(^|;) ?_ga=([^;]*)(;|$)')
            if (value) {
              document.cookie = '_ga=' + value[2] + ';path=/;expires=' + expiresVal
            }
          } else {
            // marketing_cookies
            allowVal += '_3'
          }
        })
        if (this.$countryCode === '_GL') {
          localStorage.setItem('layerCookiePopGL', 'done' + allowVal)
        } else {
          localStorage.setItem('layerCookiePop', 'done' + allowVal)
        }
        $('.wrapper-cokkie-dim').hide()
        $('body').removeClass('scrolllock');
        let adStorageType = 'denied'
        let analyticsStorage = 'denied'

        if (expiresVal != '' && allowVal.includes('2')) {
          analyticsStorage = 'granted'
        }
        if (allowVal !== '' && allowVal.includes('3')) {
          adStorageType = 'granted'
        }
        document.cookie = 'analytics_storage=' + analyticsStorage + ';expires=' + expiresVal + ';path=/'
        document.cookie = 'ad_storage=' + adStorageType + ';expires=' + expiresVal + ';path=/'
        if (typeof (dataLayer) !== 'undefined') {
          dataLayer.push({
            event: 'update_consent_status',
            ad_storage: adStorageType,
            analytics_storage: analyticsStorage
          })
        }
      } else {
        // 쿠키 모두 동의
        cookieConsentType = 'all_allow'
        if (this.$countryCode === '_GL') {
          localStorage.setItem('layerCookiePopGL', 'done_1_2_3')
        } else {
          localStorage.setItem('layerCookiePop', 'done_1_2_3')
        }
        document.cookie = 'ad_storage=granted;expires=' + expiresVal + ';path=/'
        document.cookie = 'analytics_storage=granted;expires=' + expiresVal + ';path=/'
        const value = document.cookie.match('(^|;) ?_ga=([^;]*)(;|$)')
        if (value) {
          document.cookie = '_ga=' + value[2] + ';path=/;expires=' + expiresVal
        }
        $('.wrapper-cokkie-dim').hide();
        $('body').removeClass('scrolllock');
        if (typeof (dataLayer) !== 'undefined') {
          dataLayer.push({
            event: 'update_consent_status',
            ad_storage: 'granted',
            analytics_storage: 'granted'
          })
        }
      }
      // 쿠키 동의한 사람일 경우 x나 정책 클릭 시 DB 적재 X
      if (cookieChk) {
        return false
      } else {
        this.saveCookieLog(cookieConsentType)
      }
    },
    // 쿠키 로그 저장
    async saveCookieLog (cookieConsentType) {
      const postData = {}
      postData.$countryCode = this.$countryCode
      postData.url = location.href
      postData.consentType = cookieConsentType

      if (cookieConsentType === 'deny') {
        // 쿠키 미동의(url만 저장)
        postData.strictlyNecessary = false
        postData.analytical = false
        postData.marketing = false
      } else if (cookieConsentType === 'all_allow') {
        // 쿠키 전체 동의
        postData.strictlyNecessary = true
        postData.analytical = true
        postData.marketing = true
      } else {
        postData.strictlyNecessary = true
        // 쿠키 선택 동의
        if (this.cookie_check.includes('2')) {
          // ANALYTICAL COOKIES 동의
          postData.analytical = true
        }
        if (this.cookie_check.includes('3')) {
          // MARKETING COOKIES 동의
          postData.marketing = true
        }
      }
      await etcService.saveCookieLog(postData)
    },
    // us 쿠키 버튼클릭시 상세내용 열림
    // cookieStrOpen: function (event) {
    //   $('.strictly').toggleClass('down-open')
    //   $('.analytical').removeClass('down-open')
    //   $('.marketing').removeClass('down-open')
    //   $('.strictly .btn-view').toggleClass('on')
    //   $('.analytical .btn-view').removeClass('on')
    //   $('.marketing .btn-view').removeClass('on')
    // },
    // cookieAnalOpen: function (event) {
    //   $('.strictly').removeClass('down-open')
    //   $('.analytical').toggleClass('down-open')
    //   $('.marketing').removeClass('down-open')
    //   $('.analytical .btn-view').toggleClass('on')
    //   $('.strictly .btn-view').removeClass('on')
    //   $('.marketing .btn-view').removeClass('on')
    // },
    // cookieMarOpen: function (event) {
    //   $('.strictly').removeClass('down-open')
    //   $('.analytical').removeClass('down-open')
    //   $('.marketing').toggleClass('down-open')
    //   $('.marketing .btn-view').toggleClass('on')
    //   $('.strictly .btn-view').removeClass('on')
    //   $('.analytical .btn-view').removeClass('on')
    // },
    // footer COOKIE SETTINGS
    cookieSettings: function () {
      $('.wrapper-cokkie-dim').show();
      $('body').addClass('scrolllock');
      var cookieVal
      if (this.$countryCode === '_GL') {
        cookieVal = this.getCookie('layerCookiePopGL')
      } else {
        cookieVal = this.getCookie('layerCookiePop')
      }
      if (cookieVal.includes('_1')) {
        $('#strictlyChk').prop('checked', true)
      }
      if (cookieVal.includes('_2')) {
        $('#analyticalChk').prop('checked', true)
      }
      if (cookieVal.includes('_3')) {
        $('#marketingChk').prop('checked', true)
      }
    },
    getCookie (name) {
      const nameEQ = name + '='
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    }
  },
  beforeDestroy () {
    // 컴포넌트가 파괴되기 전에 clearInterval을 호출하여 메모리 누수 방지

  }
}

let LogOutTimer = (function () {
  const S = {
    timer: null,
    limit: 1000 * 60 * 600,
    is_pop: true,
    fnc: function () {},
    start: function () {
      S.timer = window.setTimeout(S.fnc, S.limit)
    },
    reset: function () {
      window.clearTimeout(S.timer)
      S.start()
    }
  }
  document.onmousemove = function () { S.reset() }
  document.onkeydown = function () { S.reset() }
  return S
}())

LogOutTimer.fnc = function () {
  if (!LogOutTimer.is_pop) return
  if ($('div.layer-view').css('display') == 'none' && $('div.layer-newsletter-form').css('display') == 'none') {
    LayerPopup.close()
    $('div.layer-view').each(function (index, item) {
      $(this).fadeOut(300)
      $(this).removeAttr('tabindex')
    })
    LayerPopup.open('layer-newsletter-ad')
  }
}
</script>
