<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>HFLOR</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
        <ul class="checkbox_more no_line">
          <li v-for="(cate, index) in categories" :key="index">
            <input type="radio" :id="'rdo'+index" :name="'rdo'+index" :checked="checked(cate.code)">
            <label class="inp-rdo" :for="'rdo'+index" @click="clickCate(cate.code);">
              <span>{{ $t(cate.label) }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'SearchFilter',
  props: ['category'],
  data () {
    return {
      categories: [
        { code: 'PRD_FLOORING', label: 'PF.button.all' },
        { code: 'FLOOR_LUX', label: 'PF.button.lvt' },
        { code: 'FLOOR_LOS', label: 'PF.button.los' },
        { code: 'FLOOR_SPC', label: 'PF.button.spc' },
        { code: 'FLOOR_HOM', label: 'PF.button.hom' },
        { code: 'FLOOR_HET', label: 'PF.button.het' },
        { code: 'FLOOR_SPT', label: 'PF.button.spt' }
      ]
    }
  },
  methods: {
    checked (cateCode) {
      return this.category.code && this.category.code.includes(cateCode)
    },
    clickCate (cateCode) {
      if (!this.checked(cateCode)) {
        this.$emit('category-changed', cateCode, true)
      }
    }
  }
}
</script>
