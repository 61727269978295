var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content prod-dt-wide" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c("li", { staticClass: "ico-home" }, [
              _vm._v(_vm._s(_vm.$t("PCD.text.home"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("PCD.text.products")))]),
            _c("li", [_vm._v(_vm._s(_vm.categoryName))]),
            _c("li", [_vm._v(_vm._s(_vm.contentName))]),
          ]),
        ]),
        _c("div", { staticClass: "pro-dt-warp" }, [
          _c(
            "div",
            { staticClass: "container prod-dt-view pd_container himacs-pd" },
            [
              _c("div", { staticClass: "prod-img" }, [
                _c(
                  "div",
                  { staticClass: "slider-prod viartW" },
                  _vm._l(_vm.imgPage, function (fileName, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imgShow === true,
                            expression: "imgShow === true",
                          },
                        ],
                        key: index,
                        staticClass: "slider pdf-inslide03",
                      },
                      [
                        fileName.image != null &&
                        fileName.image != undefined &&
                        fileName.image != "" &&
                        !fileName.image.startsWith("https://www.youtube.com")
                          ? _c("img", {
                              attrs: {
                                src: _vm.$imagePath + fileName.image,
                                alt: "himacs images",
                              },
                            })
                          : _vm._e(),
                        fileName.image != null &&
                        fileName.image != undefined &&
                        fileName.image != "" &&
                        fileName.image.startsWith("https://www.youtube.com")
                          ? _c(
                              "div",
                              {
                                staticClass: "yt-video",
                                style:
                                  "max-width:" +
                                  _vm.youtubeWidth +
                                  ";" +
                                  "margin: 0 auto",
                              },
                              [
                                _c("iframe", {
                                  staticClass: "yt_player_iframe",
                                  staticStyle: {
                                    width: "auto",
                                    "min-width": "100%",
                                    "min-height": "100%",
                                  },
                                  attrs: {
                                    autoplay: "autoplay",
                                    muted: "muted",
                                    controls: "",
                                    allowscriptaccess: "always",
                                    src: fileName.image + "?enablejsapi=1",
                                    alt: "youtube",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                          ? _c("p", { staticClass: "ctt photo-credits ta-r" }, [
                              _c("span", {
                                staticClass: "pc-br",
                                domProps: {
                                  innerHTML: _vm._s(fileName.description),
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "slider-prod-nav navWide tac",
                    class: {
                      euNavWide:
                        _vm.nationCode != "_GL" && _vm.nationCode != "_US",
                    },
                  },
                  _vm._l(_vm.imgPage, function (fileName, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imgShow === true,
                            expression: "imgShow === true",
                          },
                        ],
                        key: index,
                        staticClass: "slider",
                        staticStyle: { position: "relative" },
                      },
                      [
                        fileName.image != null &&
                        fileName.image != undefined &&
                        fileName.image != "" &&
                        fileName.thumb != null &&
                        fileName.thumb != ""
                          ? _c("img", {
                              attrs: {
                                "data-chk": index,
                                src: _vm.$imagePath + fileName.thumb,
                                alt: "himacs thumbnail",
                              },
                            })
                          : _vm._e(),
                        fileName.image != null &&
                        fileName.image != undefined &&
                        fileName.image != "" &&
                        fileName.image.startsWith("https://www.youtube.com")
                          ? _c("div", [
                              _c("img", {
                                staticStyle: {
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "50px",
                                  height: "50px",
                                },
                                attrs: {
                                  src: "https://img.lxhausys.com/public/images/youtube.png",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "w-con inner" }, [
                _c("div", { staticClass: "prod-info" }, [
                  _c("div", { staticClass: "wide-proDeteail-label" }, [
                    _c("span", { staticClass: "pro" }, [
                      _vm._v(_vm._s(_vm.$t("PCD.text.himacs"))),
                    ]),
                    _vm.isNew == "true"
                      ? _c("span", { staticClass: "new" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.new"))),
                        ])
                      : _vm._e(),
                    _vm.label != "" &&
                    _vm.label != undefined &&
                    _vm.label != null
                      ? _c(
                          "span",
                          {
                            staticClass: "new",
                            staticStyle: {
                              "background-color": "#595555",
                              "border-color": "#595555",
                            },
                          },
                          [_vm._v(_vm._s(_vm.label))]
                        )
                      : _vm._e(),
                    _vm.discontinued == "true"
                      ? _c("span", { staticClass: "disColor" }, [
                          _vm._v(_vm._s(_vm.$t("PD.text.discontinued_Color"))),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "prod-img" }, [
                    _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                      ? _c("div", { staticClass: "btns" }, [
                          _c("ul", [
                            _vm.Tslider === 0
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon-view-gray",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.enlargeLayer("open")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("PD.text.image_enlarged_view")
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-download-gray",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.imgDownload(_vm.Tslider)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("PD.text.image_download_view")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {},
                                [
                                  _c("ShareComponent", {
                                    attrs: {
                                      childValue: _vm.contentList,
                                      type: "product",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("li", [
                              _vm.authenticated == true &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-like2-gray",
                                      class: {
                                        on: _vm.thisContentLike.content.liked,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(
                                            _vm.thisContentLike,
                                            "HIMACS"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.authenticated == false &&
                              _vm.nationCode == "_US"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-like2-gray",
                                      attrs: { href: "login/" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loginalertLike()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.getSessionContent != null &&
                              _vm.nationCode == "_GL"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-like2-gray",
                                      class: {
                                        on: _vm.getSessionContent.includes(
                                          _vm.thisContentLike.content.id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(
                                            _vm.thisContentLike,
                                            "HIMACS"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm.getSessionContent == null &&
                                  _vm.nationCode == "_GL"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-like2-gray",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(
                                            _vm.thisContentLike,
                                            "HIMACS"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                      ? _c("div", { staticClass: "btns" }, [
                          _c("ul", [
                            _vm.Tslider === 0
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "icon-view-gray",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.enlargeLayer("open")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("PD.text.image_enlarged_view")
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-download-gray",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.imgDownload(_vm.Tslider)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("PD.text.image_download_view")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {},
                                [
                                  _c("ShareComponent", {
                                    attrs: {
                                      childValue: _vm.contentList,
                                      type: "product",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("li", [
                              _vm.getSessionContent != null
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-like2-gray",
                                      class: {
                                        on: _vm.getSessionContent.includes(
                                          _vm.thisContentLike.content.id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(
                                            _vm.thisContentLike,
                                            "HIMACS"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm.getSessionContent == null
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "icon-like2-gray",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleLike(
                                            _vm.thisContentLike,
                                            "HIMACS"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PCD.text.wish_list"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "info-01 v-al-md" }, [
                    _vm.nationCode != "_US" &&
                    _vm.nationCode != "_GL" &&
                    _vm.Aurora == true
                      ? _c("span", { staticClass: "aurora-icon" }, [
                          _c("img", {
                            attrs: {
                              src: `images/products/PD_Aurora_icon.png`,
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "txt" }, [
                      _vm._v(_vm._s(_vm.colorCode)),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-02" }, [
                    _c("div", [
                      _c("h1", { staticClass: "tt-1" }, [
                        _vm._v(_vm._s(_vm.contentName)),
                      ]),
                      _c("div", { staticClass: "info-02 eu-info-02" }, [
                        _c("div", { staticClass: "ico-pro-collection" }, [
                          _vm.nationCode != "_US" &&
                          _vm.nationCode != "_GL" &&
                          _vm.collection[0] != null &&
                          _vm.collection[0] != undefined &&
                          _vm.collection[0] != "" &&
                          _vm.collection[0].includes(
                            "Granite, Sparkle, Quartz, Sand & Pearl"
                          )
                            ? _c("span", { staticClass: "ctt" }, [
                                _vm._v(
                                  _vm._s(_vm.collection[0]) +
                                    " " +
                                    _vm._s(_vm.$t("PCD.text.collection"))
                                ),
                              ])
                            : _c("span", { staticClass: "ctt lh-40" }, [
                                _vm._v(
                                  _vm._s(_vm.collection[0]) +
                                    " " +
                                    _vm._s(_vm.$t("PCD.text.collection"))
                                ),
                              ]),
                          _vm.nationCode != "_US" &&
                          _vm.nationCode != "_GL" &&
                          _vm.collection[1] != null &&
                          _vm.collection[1] != undefined &&
                          _vm.collection[1] != "" &&
                          _vm.collection[1].includes(
                            "Granite, Sparkle, Quartz, Sand & Pearl"
                          )
                            ? _c("span", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(
                                  _vm._s(_vm.collection[1]) +
                                    " " +
                                    _vm._s(_vm.$t("PCD.text.collection"))
                                ),
                              ])
                            : _vm.nationCode != "_US" &&
                              _vm.nationCode != "_GL" &&
                              _vm.collection[1] != null &&
                              _vm.collection[1] != undefined &&
                              _vm.collection[1] != ""
                            ? _c("span", { staticClass: "ctt ctt-t4 lh-40" }, [
                                _vm._v(
                                  _vm._s(_vm.collection[1]) +
                                    " " +
                                    _vm._s(_vm.$t("PCD.text.collection"))
                                ),
                              ])
                            : _vm._e(),
                          _vm.collection[2] != null &&
                          _vm.collection[2] != undefined &&
                          _vm.collection[2] != "" &&
                          _vm.collection[2].includes(
                            "Granite, Sparkle, Quartz, Sand & Pearl"
                          )
                            ? _c("span", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(
                                  _vm._s(_vm.collection[2]) +
                                    " " +
                                    _vm._s(_vm.$t("PCD.text.collection"))
                                ),
                              ])
                            : _vm.nationCode != "_US" &&
                              _vm.nationCode != "_GL" &&
                              _vm.collection[2] != null &&
                              _vm.collection[2] != undefined &&
                              _vm.collection[2] != ""
                            ? _c("span", { staticClass: "ctt ctt-t4 lh-40" }, [
                                _vm._v(
                                  _vm._s(_vm.collection[2]) +
                                    " " +
                                    _vm._s(_vm.$t("PCD.text.collection"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                          ? _c("div", { staticClass: "ico-color" }, [
                              _vm.hueText === "Other"
                                ? _c("div", {
                                    staticClass: "color",
                                    style: {
                                      borderColor: "#999",
                                      backgroundImage:
                                        "url(images/products/" +
                                        _vm.relhue +
                                        ".png",
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "color",
                                    style: {
                                      borderColor: "#999",
                                      backgroundColor: _vm.hue,
                                    },
                                  }),
                              _c("div", { staticClass: "txtinfo" }, [
                                _vm.nationCode == "_US" ||
                                _vm.nationCode == "_GL"
                                  ? _c("span", { staticClass: "ctt ctt-t4" }, [
                                      _vm._v(_vm._s(_vm.hueText)),
                                    ])
                                  : _vm._e(),
                                _c("span", { staticClass: "ctt ctt-t4" }, [
                                  _vm._v(_vm._s(_vm.$t("PD.text.color"))),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.nationCode != "_US" &&
                        _vm.nationCode != "_GL" &&
                        _vm.lucent == "true"
                          ? _c("div", { staticClass: "ico-pro-lucent" }, [
                              _c("span", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(_vm._s(_vm.$t("PD.text.Lucent"))),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.nationCode != "_US" &&
                        _vm.nationCode != "_GL" &&
                        _vm.veined == "true"
                          ? _c("div", { staticClass: "ico-pro-veined" }, [
                              _c("span", { staticClass: "ctt ctt-t4" }, [
                                _vm._v(_vm._s(_vm.$t("PD.text.Veined"))),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("p", {
                        staticClass: "info-03 ctt ctt-t4",
                        domProps: { innerHTML: _vm._s(_vm.brief) },
                      }),
                    ]),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "tbl-box-prodetail",
                          class: {
                            euTblBox:
                              _vm.nationCode != "_GL" &&
                              _vm.nationCode != "_US",
                          },
                        },
                        [
                          _c("table", [
                            _c("caption", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("PD.text.product_Specs")) +
                                  " "
                              ),
                            ]),
                            _vm._m(0),
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  { attrs: { scope: "col", colspan: "2" } },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.text.product_Specs"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                              ? _c("tbody", [
                                  _c("tr", [
                                    _c("th", { attrs: { scope: "row" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.text.dimension1")) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(_vm._s(_vm.$t("PD.text.weight"))),
                                    ]),
                                    _c("td", { staticClass: "ctt ctt-t5" }, [
                                      _vm._v(_vm._s(_vm.dimension)),
                                    ]),
                                  ]),
                                  _vm.remark !== undefined && _vm.remark !== ""
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.remark"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [_vm._v(_vm._s(_vm.remark))]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("tr", [
                                    _c("th", { attrs: { scope: "row" } }, [
                                      _vm._v(_vm._s(_vm.$t("PD.text.pattern"))),
                                    ]),
                                    _c("td", { staticClass: "ctt ctt-t5" }, [
                                      _vm._v(_vm._s(_vm.pattern)),
                                    ]),
                                  ]),
                                  _vm.nationCode === "_GL"
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "PD.text.available_Countries"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.countryCode) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("tr", [
                                    _c("th", { attrs: { scope: "row" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.text.adhesive_code"))
                                      ),
                                    ]),
                                    _c("td", { staticClass: "ctt ctt-t5" }, [
                                      _vm._v(_vm._s(_vm.adCode)),
                                    ]),
                                  ]),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.flag === true,
                                          expression: "flag === true",
                                        },
                                      ],
                                    },
                                    [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("PD.text.adhesive_name")
                                          )
                                        ),
                                      ]),
                                      _c("td", { staticClass: "ctt ctt-t5" }, [
                                        _vm._v(_vm._s(_vm.adName)),
                                      ]),
                                    ]
                                  ),
                                  _vm.flag === false
                                    ? _c("tr", { staticClass: "bo-btm-0" }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "ta-c prd-btn-more",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-text btn-text-more",
                                                attrs: { type: "button" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.specShow(
                                                          _vm.flag
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PD.button.see_more"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.flag === true
                                    ? _c("tr", { staticClass: "bo-btm-0" }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "ta-c prd-btn-more",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _c("button", {
                                              staticClass:
                                                "btn btn-text btn-text-more on",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.specShow(_vm.flag)
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                              ? _c("tbody", [
                                  _vm.thickness != undefined &&
                                  _vm.thickness.length != 0
                                    ? _c(
                                        "tr",
                                        [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.text.Thickness")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.thickness.length > 1
                                            ? _c(
                                                "td",
                                                { staticClass: "ctt ctt-t5" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.thickness.join(", ")
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._l(
                                                _vm.thickness,
                                                function (thickness) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      staticClass: "ctt ctt-t5",
                                                    },
                                                    [_vm._v(_vm._s(thickness))]
                                                  )
                                                }
                                              ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.USE != undefined && _vm.USE.length != 0
                                    ? _c(
                                        "tr",
                                        [
                                          _c(
                                            "th",
                                            { attrs: { scope: "row" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PD.text.Use"))
                                              ),
                                            ]
                                          ),
                                          _vm.USE.length > 1
                                            ? _c(
                                                "td",
                                                { staticClass: "ctt ctt-t5" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.USE.join(", "))
                                                  ),
                                                ]
                                              )
                                            : _vm._l(_vm.USE, function (USE) {
                                                return _c(
                                                  "td",
                                                  { staticClass: "ctt ctt-t5" },
                                                  [_vm._v(_vm._s(USE))]
                                                )
                                              }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.pattern != undefined &&
                                  _vm.pattern != null
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Pattern"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [_vm._v(_vm._s(_vm.pattern))]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "tr",
                                    [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(_vm._s(_vm.$t("PF.text.Tones"))),
                                      ]),
                                      _vm.tones.length > 1
                                        ? _c(
                                            "td",
                                            { staticClass: "ctt ctt-t5" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tones.join(", "))
                                              ),
                                            ]
                                          )
                                        : _vm._l(_vm.tones, function (tones) {
                                            return _c(
                                              "td",
                                              { staticClass: "ctt ctt-t5" },
                                              [_vm._v(_vm._s(tones))]
                                            )
                                          }),
                                    ],
                                    2
                                  ),
                                  _vm.notice == "true"
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Notice"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("PD.text.Notice_brief")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.semi_translucent_colour == "true"
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PD.text.Semi_Translucent")
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "PD.text.Semi_Translucent_brief"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.recycled != undefined &&
                                  _vm.recycled != null &&
                                  _vm.recycled != ""
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Recycled"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "PD.text.Recycled_brief"
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(_vm.recycled) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.designer_series == "true"
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Designer"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "PD.text.Designer_brief"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.lucent == "true"
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Lucent"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("PD.text.Lucent_brief")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.veined == "true"
                                    ? _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("PD.text.Veined"))
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "ctt ctt-t5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("PD.text.Veined_brief")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "proDtail" }, [
                  _c("div", { staticClass: "short-link short-link-outline" }, [
                    _vm.nationCode === "_US"
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openSampleOrder(_vm.contentId)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "ico-01" }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.order_a_sample"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.nationCode === "_GL"
                      ? _c("a", { attrs: { href: "support/contactus" } }, [
                          _c("span", { staticClass: "ico-07 ctt ctt-t3" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                      ? _c("a", { attrs: { href: "support/contactus" } }, [
                          _c("span", { staticClass: "ico-07 ctt ctt-t3" }, [
                            _vm._v(_vm._s(_vm.$t("PCD.text.contact_us"))),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "a",
                      {
                        staticClass: "btn-doc",
                        attrs: { href: "javascript:void(0);" },
                      },
                      [
                        _c("span", { staticClass: "ico-02" }, [
                          _vm._v(_vm._s(_vm.$t("PCD.text.documentation"))),
                        ]),
                      ]
                    ),
                    _vm.nationCode === "_US"
                      ? _c(
                          "a",
                          {
                            attrs: { href: "support/where-to-buy/country/us" },
                          },
                          [
                            _c("span", { staticClass: "ico-05" }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.where_to_buy"))),
                            ]),
                          ]
                        )
                      : _vm.nationCode === "_GL"
                      ? _c(
                          "a",
                          {
                            attrs: { href: "support/where-to-buy/country/gl" },
                          },
                          [
                            _c("span", { staticClass: "ico-05" }, [
                              _vm._v(_vm._s(_vm.$t("PCD.text.where_to_buy"))),
                            ]),
                          ]
                        )
                      : _c(
                          "a",
                          {
                            attrs: {
                              href: "support/sales-representative/continenteu/himacs",
                            },
                          },
                          [
                            _c("span", { staticClass: "ico-19" }, [
                              _vm._v(
                                _vm._s(_vm.$t("PCD.button.find_a_sales_rep"))
                              ),
                            ]),
                          ]
                        ),
                    _c(
                      "a",
                      { attrs: { href: "inspiration/virtual-showroom" } },
                      [
                        _c("span", { staticClass: "ico-06" }, [
                          _vm._v(_vm._s(_vm.$t("PD.button.virtual_Showroom"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "related-prod inner" }, [
                _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                  _vm._v(_vm._s(_vm.$t("PD.text.related_Products"))),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.featShow === true,
                        expression: "featShow === true",
                      },
                    ],
                    staticClass: "slide-wrapper slide-pd",
                  },
                  [
                    _c("button", { staticClass: "arrow-prev" }, [
                      _vm._v(_vm._s(_vm.$t("PD.button.prev"))),
                    ]),
                    _c("button", { staticClass: "arrow-next" }, [
                      _vm._v(_vm._s(_vm.$t("PD.button.next"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "slick-container" },
                      _vm._l(_vm.relContentList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "slider" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "thumb",
                                attrs: {
                                  "data-ec-product":
                                    _vm.makeDataLayerFormList(item),
                                },
                              },
                              [
                                _vm.nationCode != "_US" &&
                                _vm.nationCode != "_GL"
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/" +
                                            _vm.categoryName +
                                            "/" +
                                            item.orgContent.title +
                                            "/" +
                                            item.content.id,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "over" }, [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.$imagePath +
                                                item.attribute.pf_thumb,
                                              alt: "PORTLAND",
                                            },
                                          }),
                                        ]),
                                      ]
                                    )
                                  : _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/" +
                                            "himacs-solid-surface" +
                                            "/" +
                                            _vm.$utils.getSeoName(
                                              item,
                                              "content"
                                            ) +
                                            "/" +
                                            item.content.id,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "over" }, [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.$imagePath +
                                                item.attribute.pf_thumb,
                                              alt: "PORTLAND",
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                _c("div", { staticClass: "view" }, [
                                  _vm.nationCode === "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-cart-shadow",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSampleOrder(
                                                item.content.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("PCD.text.cart"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.authenticated == true &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: { on: item.content.liked },
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                item,
                                                "HIMACS"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("PCD.text.wish_list"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.authenticated == false &&
                                  _vm.nationCode == "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: {
                                            tabindex: "0",
                                            href: "login/",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loginalertLike()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("PCD.text.wish_list"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.getSessionContent != null &&
                                  _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          class: {
                                            on: _vm.getSessionContent.includes(
                                              item.content.id
                                            ),
                                          },
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                item,
                                                "HIMACS"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("PCD.text.wish_list"))
                                          ),
                                        ]
                                      )
                                    : _vm.getSessionContent == null &&
                                      _vm.nationCode != "_US"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "icon-wish-shadow",
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleLike(
                                                item,
                                                "HIMACS"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("PCD.text.wish_list"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "prod-info-ico new" },
                                  [
                                    item.attribute.isNew === "true"
                                      ? _c("p", { staticClass: "ico-r" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("PCD.text.new")) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.attribute.label != "" &&
                                    item.attribute.label != undefined &&
                                    item.attribute.label != null
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "new",
                                            staticStyle: {
                                              "background-color": "#595555",
                                              "border-color": "#595555",
                                              padding: "3px 13px 3px",
                                              color: "white",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.attribute.label))]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "info" }, [
                              _c("ul", [
                                _c(
                                  "li",
                                  { staticClass: "txt-02" },
                                  [
                                    _vm.nationCode != "_US" &&
                                    _vm.nationCode != "_GL"
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                _vm.categoryName +
                                                "/" +
                                                item.orgContent.title +
                                                "/" +
                                                item.content.id,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.content.title))]
                                        )
                                      : _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/" +
                                                "himacs-solid-surface" +
                                                "/" +
                                                _vm.$utils.getSeoName(
                                                  item,
                                                  "content"
                                                ) +
                                                "/" +
                                                item.content.id,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.content.title))]
                                        ),
                                  ],
                                  1
                                ),
                                _c("li", { staticClass: "ctt ctt-t4" }, [
                                  _vm._v(_vm._s(item.attribute.code)),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(_vm._s(item.parents[0].name)),
                                ]),
                              ]),
                              _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                                ? _c("div", { staticClass: "ico-color" }, [
                                    _vm.hueText === "Other"
                                      ? _c("span", {
                                          staticClass: "color",
                                          style: {
                                            borderColor: "#999",
                                            backgroundImage:
                                              "url(images/products/" +
                                              _vm.relhue +
                                              ".png",
                                          },
                                        })
                                      : _c("span", {
                                          staticClass: "color",
                                          style: {
                                            borderColor: "#999",
                                            backgroundColor:
                                              "#" + item.attribute.hue,
                                          },
                                        }),
                                    _c("span", [
                                      _vm._v(_vm._s(item.attribute.hueText)),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "w-con" }, [
                _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                  ? _c("div", { staticClass: "fb-box" }, [
                      _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                        _vm._v(_vm._s(_vm.$t("PD.text.features_Benefits"))),
                      ]),
                      _c("div", { staticClass: "tab-ui" }, [
                        _c(
                          "ul",
                          { staticClass: "himac_list inner" },
                          _vm._l(_vm.getfeaturesList, function (coll, index) {
                            return _c(
                              "li",
                              {
                                staticClass: "bd-gray",
                                attrs: {
                                  "data-tab": "tab0" + (index + 1),
                                  id: "tab0" + (index + 1),
                                },
                              },
                              [
                                _c("dl", [
                                  _c("dt", { staticClass: "tt-2" }, [
                                    _c("span", {
                                      staticClass: "ico-01",
                                      style: {
                                        background:
                                          "url( " +
                                          _vm.$imagePath +
                                          coll.attribute.img +
                                          ") center center no-repeat",
                                      },
                                    }),
                                    _vm._v(_vm._s(coll.content.title) + " "),
                                  ]),
                                  _c("dd", { staticClass: "ctt ctt-t4" }, [
                                    _vm._v(_vm._s(coll.attribute.brief)),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                  ? _c("div", { staticClass: "fb-box eu-fb-box inner" }, [
                      _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                        _vm._v(_vm._s(_vm.$t("PD.text.features_Benefits"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "tab-ui tab-ui-nav tab-ui-gray" },
                        [
                          _vm._m(1),
                          _vm._m(2),
                          _c("ul", [
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab on",
                                attrs: { "data-tab": "fb-box-01" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "PCD.text.TAB_Quality_Design_title"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "fb-box-02" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "PCD.text.TAB_Quality_Performance_title"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "fb-box-03" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "PCD.text.TAB_Quality_Fabrication_title"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "fb-box-04" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "PCD.text.TAB_Quality_Sustainability_title"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("div", { staticClass: "tab-con fb-box-01 on" }, [
                            _c(
                              "div",
                              { staticClass: "bd-gray" },
                              _vm._l(_vm.bene_designText, function (design) {
                                return _c("div", [
                                  design == "1"
                                    ? _c(
                                        "div",
                                        { staticClass: "frow tc-list" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fcol fcol-fixed",
                                              staticStyle: { width: "100px" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: `images/icon-himacs-wide@3x.png`,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("div", { staticClass: "fcol" }, [
                                            _c(
                                              "div",
                                              { staticClass: "tc-box" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "ctt ctt-t2 tc-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PCD.text.Design_title1"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "ctt tc-des" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PCD.text.Design_brief1"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  design == "2"
                                    ? _c(
                                        "div",
                                        { staticClass: "frow tc-list" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fcol fcol-fixed",
                                              staticStyle: { width: "100px" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: `images/icon-himacs-dramatic@3x.png`,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("div", { staticClass: "fcol" }, [
                                            _c(
                                              "div",
                                              { staticClass: "tc-box" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "ctt ctt-t2 tc-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PCD.text.Design_title2"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "ctt tc-des" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Design_brief2"
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Design_brief2_1"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  design == "3"
                                    ? _c(
                                        "div",
                                        { staticClass: "frow tc-list" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fcol fcol-fixed",
                                              staticStyle: { width: "100px" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: `images/icon-himacs-3-dimens@3x.png`,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("div", { staticClass: "fcol" }, [
                                            _c(
                                              "div",
                                              { staticClass: "tc-box" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "ctt ctt-t2 tc-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PCD.text.Design_title3"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "ctt tc-des" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Design_brief3"
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Design_brief3_1"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  design == "4"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "frow tc-list tc-list-last",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fcol fcol-fixed",
                                              staticStyle: { width: "100px" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: `images/icon-himacs-unlimited@3x.png`,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("div", { staticClass: "fcol" }, [
                                            _c(
                                              "div",
                                              { staticClass: "tc-box" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "ctt ctt-t2 tc-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PCD.text.Design_title4"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "ctt tc-des" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Design_brief4"
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Design_brief4_1"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("div", { staticClass: "tab-con fb-box-02" }, [
                            _c(
                              "div",
                              { staticClass: "bd-gray" },
                              _vm._l(
                                _vm.bene_performanceText,
                                function (performance) {
                                  return _c("div", [
                                    performance == "1"
                                      ? _c(
                                          "div",
                                          { staticClass: "frow tc-list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-strong@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Performance_title1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Performance_brief1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    performance == "2"
                                      ? _c(
                                          "div",
                                          { staticClass: "frow tc-list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-fire@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Performance_title2"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PCD.text.Performance_brief2"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PCD.text.Performance_brief2_1"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    performance == "3"
                                      ? _c(
                                          "div",
                                          { staticClass: "frow tc-list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-hygienic@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Performance_title3"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "pc-br",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Performance_brief3"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Performance_brief3_1"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    performance == "4"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "frow tc-list tc-list-last",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-water@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Performance_title4"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "pc-br",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Performance_brief4"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Performance_brief4_1"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("div", { staticClass: "tab-con fb-box-03" }, [
                            _c(
                              "div",
                              { staticClass: "bd-gray" },
                              _vm._l(
                                _vm.bene_fabricationText,
                                function (fabrication) {
                                  return _c("div", [
                                    fabrication == "1"
                                      ? _c(
                                          "div",
                                          { staticClass: "frow tc-list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-fabrication@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Fabrication_title1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Fabrication_brief1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    fabrication == "2"
                                      ? _c(
                                          "div",
                                          { staticClass: "frow tc-list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-simple@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Fabrication_title2"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "pc-br",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Fabrication_brief2"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Fabrication_brief2_1"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    fabrication == "3"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "frow tc-list tc-list-last",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-seamless@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Fabrication_title3"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "pc-br",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Fabrication_brief3"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Fabrication_brief3_1"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c("div", { staticClass: "tab-con fb-box-04" }, [
                            _c(
                              "div",
                              { staticClass: "bd-gray" },
                              _vm._l(
                                _vm.bene_sustainText,
                                function (sustainability) {
                                  return _c("div", [
                                    sustainability == "1"
                                      ? _c(
                                          "div",
                                          { staticClass: "frow tc-list" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-renewable@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Sustainability_title1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "pc-br",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Sustainability_brief1"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PCD.text.Sustainability_brief1_1"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    sustainability == "2"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "frow tc-list tc-list-last",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "fcol fcol-fixed",
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-sustainable@3x.png`,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("div", { staticClass: "fcol" }, [
                                              _c(
                                                "div",
                                                { staticClass: "tc-box" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "ctt ctt-t2 tc-tit",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Sustainability_title2"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ctt tc-des",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Sustainability_brief2"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                  ? _c("div", { staticClass: "eu-cer-box inner" }, [
                      _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                        _vm._v(_vm._s(_vm.$t("PD.text.certifications"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "tab-ui tab-ui-nav tab-ui-gray" },
                        [
                          _vm._m(3),
                          _vm._m(4),
                          _c("ul", [
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab on",
                                attrs: { "data-tab": "cert-box-01" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("PCD.text.TAB_Environment_title")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "cert-box-02" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("PCD.text.TAB_Fire_Rating_title")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "cert-box-03" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "PCD.text.TAB_HIMACS_Exteria_title"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "cert-box-04" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("PCD.text.TAB_Hygiene_title"))
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "btn-tab",
                                attrs: { "data-tab": "cert-box-05" },
                              },
                              [
                                _c("button", { attrs: { type: "button" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("PCD.text.TAB_Process_title"))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-con cert-box-01 on eu-cert-env",
                            },
                            [
                              _c("div", { staticClass: "bd-gray" }, [
                                _c(
                                  "div",
                                  { staticClass: "frow" },
                                  [
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "1"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-ministry@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "2"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-m-1-black@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "3"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/green-building-council-black@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "4"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/icon-himacs-iso-14001@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "5"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/greenguard-black@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "6"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/hbm-black@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "7"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/dgnb@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    _vm._l(
                                      _vm.certifi_environText,
                                      function (environment) {
                                        return environment == "8"
                                          ? _c(
                                              "span",
                                              { staticClass: "fcol" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: `images/breeam-black@3x.png`,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c("div", { staticClass: "more-box tac" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handle_toggle },
                                  },
                                  [_c("span", [_vm._v("Learn More")])]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.is_show,
                                      expression: "is_show",
                                    },
                                  ],
                                  staticClass:
                                    "layer-view layer-wide-example popup-v2 popup-v2-wide",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "popup pop-close" },
                                    [
                                      _c("div", { staticClass: "scr-y" }, [
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/icon-himacs-ministry@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title1"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief1"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/icon-himacs-m-1-black@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title2"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief2"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/green-building-council-black@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title3"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief3"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/icon-himacs-iso-14001@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title4"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief4"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/greenguard-black@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title5"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief5"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/hbm-black@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title6"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief6"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "frow tc-list" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/dgnb@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title7"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief7"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "frow tc-list tc-list-last",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fcol fcol-fixed",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: `images/breeam-black@3x.png`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fcol" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "tc-box" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt ctt-t2 tc-tit",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_title8"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "ctt tc-des",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PCD.text.Environment_brief8"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-close",
                                          attrs: { type: "button" },
                                          on: { click: _vm.handle_toggle },
                                        },
                                        [_vm._v("close")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "tab-con cert-box-02 eu-cert-fir" },
                        [
                          _c("div", { staticClass: "bd-gray" }, [
                            _c(
                              "div",
                              { staticClass: "frow" },
                              [
                                _vm._l(_vm.certifi_rating, function (rating) {
                                  return rating == "1"
                                    ? _c("span", { staticClass: "fcol" }, [
                                        _c("img", {
                                          attrs: {
                                            src: `images/icon-himacs-nfp-92-501@3x.png`,
                                          },
                                        }),
                                      ])
                                    : _vm._e()
                                }),
                                _vm._l(_vm.certifi_rating, function (rating) {
                                  return rating == "2"
                                    ? _c("span", { staticClass: "fcol" }, [
                                        _c("img", {
                                          attrs: {
                                            src: `images/icon-himacs-en-45545@3x.png`,
                                          },
                                        }),
                                      ])
                                    : _vm._e()
                                }),
                                _vm._l(_vm.certifi_rating, function (rating) {
                                  return rating == "3"
                                    ? _c("span", { staticClass: "fcol" }, [
                                        _c("img", {
                                          attrs: {
                                            src: `images/icon-himacs-en-13501@3x.png`,
                                          },
                                        }),
                                      ])
                                    : _vm._e()
                                }),
                                _vm._l(_vm.certifi_rating, function (rating) {
                                  return rating == "4"
                                    ? _c("span", { staticClass: "fcol" }, [
                                        _c("img", {
                                          attrs: {
                                            src: `images/icon-himacs-din-4102-1@3x.png`,
                                          },
                                        }),
                                      ])
                                    : _vm._e()
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c("div", { staticClass: "more-box tac" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                attrs: { type: "button" },
                                on: { click: _vm.handle_toggle_02 },
                              },
                              [_c("span", [_vm._v("Learn More")])]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.is_show_02,
                                  expression: "is_show_02",
                                },
                              ],
                              staticClass:
                                "layer-view layer-wide-example popup-v2 popup-v2-wide",
                            },
                            [
                              _c("div", { staticClass: "popup pop-close" }, [
                                _c("div", { staticClass: "scr-y" }, [
                                  _c("div", {}, [
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-nfp-92-501@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Fire_Rating_title1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Fire_Rating_brief1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-en-45545@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Fire_Rating_title2"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Fire_Rating_brief2"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-en-13501@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Fire_Rating_title3"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Fire_Rating_brief3"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "frow tc-list tc-list-last",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fcol fcol-fixed",
                                            staticStyle: { width: "100px" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: `images/icon-himacs-din-4102-1@3x.png`,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "fcol" }, [
                                          _c("div", { staticClass: "tc-box" }, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ctt ctt-t2 tc-tit",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Fire_Rating_title4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "ctt tc-des" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Fire_Rating_brief4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-close",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handle_toggle_02 },
                                  },
                                  [_vm._v("close")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "tab-con cert-box-03 eu-cert-him" },
                        [
                          _c("div", { staticClass: "bd-gray" }, [
                            _c(
                              "div",
                              { staticClass: "frow" },
                              [
                                _vm._l(
                                  _vm.certifi_exteriaText,
                                  function (exteria) {
                                    return exteria == "1"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-imo-med@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                                _vm._l(
                                  _vm.certifi_exteriaText,
                                  function (exteria) {
                                    return exteria == "2"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-eota-black@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                                _vm._l(
                                  _vm.certifi_exteriaText,
                                  function (exteria) {
                                    return exteria == "3"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-cstb-black@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _c("div", { staticClass: "more-box tac" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                attrs: { type: "button" },
                                on: { click: _vm.handle_toggle_03 },
                              },
                              [_c("span", [_vm._v("Learn More")])]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.is_show_03,
                                  expression: "is_show_03",
                                },
                              ],
                              staticClass:
                                "layer-view layer-wide-example popup-v2 popup-v2-wide",
                            },
                            [
                              _c("div", { staticClass: "popup pop-close" }, [
                                _c("div", { staticClass: "scr-y" }, [
                                  _c("div", {}, [
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-imo-med@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Exteria_title1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Exteria_brief1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-eota-black@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Exteria_title2"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Exteria_brief2"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "frow tc-list tc-list-last",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fcol fcol-fixed",
                                            staticStyle: { width: "100px" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: `images/icon-himacs-cstb-black@3x.png`,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "fcol" }, [
                                          _c("div", { staticClass: "tc-box" }, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ctt ctt-t2 tc-tit",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Exteria_title3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "ctt tc-des" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Exteria_brief3"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-close",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handle_toggle_03 },
                                  },
                                  [_vm._v("close")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "tab-con cert-box-04 eu-cert-hyg" },
                        [
                          _c("div", { staticClass: "bd-gray" }, [
                            _c(
                              "div",
                              { staticClass: "frow" },
                              [
                                _vm._l(
                                  _vm.certifi_hygieneText,
                                  function (hygiene) {
                                    return hygiene == "1"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-nsf-black@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                                _vm._l(
                                  _vm.certifi_hygieneText,
                                  function (hygiene) {
                                    return hygiene == "2"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-lga-black@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _c("div", { staticClass: "more-box tac" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                attrs: { type: "button" },
                                on: { click: _vm.handle_toggle_04 },
                              },
                              [_c("span", [_vm._v("Learn More")])]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.is_show_04,
                                  expression: "is_show_04",
                                },
                              ],
                              staticClass:
                                "layer-view layer-wide-example popup-v2 popup-v2-wide",
                            },
                            [
                              _c("div", { staticClass: "popup pop-close" }, [
                                _c("div", { staticClass: "scr-y" }, [
                                  _c("div", {}, [
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-nsf-black@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Hygiene_title1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Hygiene_brief1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "frow tc-list tc-list-last",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fcol fcol-fixed",
                                            staticStyle: { width: "100px" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: `images/icon-himacs-lga-black@3x.png`,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "fcol" }, [
                                          _c("div", { staticClass: "tc-box" }, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ctt ctt-t2 tc-tit",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Hygiene_title2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "ctt tc-des" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Hygiene_brief2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-close",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handle_toggle_04 },
                                  },
                                  [_vm._v("close")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "tab-con cert-box-05 eu-cert-pro" },
                        [
                          _c("div", { staticClass: "bd-gray" }, [
                            _c(
                              "div",
                              { staticClass: "frow" },
                              [
                                _vm._l(
                                  _vm.certifi_processText,
                                  function (process) {
                                    return process == "1"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-ohsas-18001-black@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                                _vm._l(
                                  _vm.certifi_processText,
                                  function (process) {
                                    return process == "2"
                                      ? _c("span", { staticClass: "fcol" }, [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-iso-9001-black@3x.png`,
                                            },
                                          }),
                                        ])
                                      : _vm._e()
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _c("div", { staticClass: "more-box tac" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                attrs: { type: "button" },
                                on: { click: _vm.handle_toggle_05 },
                              },
                              [_c("span", [_vm._v("Learn More")])]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.is_show_05,
                                  expression: "is_show_05",
                                },
                              ],
                              staticClass:
                                "layer-view layer-wide-example popup-v2 popup-v2-wide",
                            },
                            [
                              _c("div", { staticClass: "popup pop-close" }, [
                                _c("div", { staticClass: "scr-y" }, [
                                  _c("div", {}, [
                                    _c("div", { staticClass: "frow tc-list" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fcol fcol-fixed",
                                          staticStyle: { width: "100px" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: `images/icon-himacs-ohsas-18001-black@3x.png`,
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "fcol" }, [
                                        _c("div", { staticClass: "tc-box" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "ctt ctt-t2 tc-tit",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Process_title1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "ctt tc-des" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "PCD.text.Process_brief1"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "frow tc-list tc-list-last",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fcol fcol-fixed",
                                            staticStyle: { width: "100px" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: `images/icon-himacs-iso-9001-black@3x.png`,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "fcol" }, [
                                          _c("div", { staticClass: "tc-box" }, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ctt ctt-t2 tc-tit",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PCD.text.Process_title2"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "ctt tc-des" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "PCD.text.Process_brief2"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "ul-bullet-disc",
                                                  },
                                                  [
                                                    _c("li", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Process_brief3"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Process_brief4"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                    _c("li", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PCD.text.Process_brief5"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-close",
                                    attrs: { type: "button" },
                                    on: { click: _vm.handle_toggle_05 },
                                  },
                                  [_vm._v("close")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                  ? _c("div", { staticClass: "cer-box inner" }, [
                      _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                        _vm._v(_vm._s(_vm.$t("PD.text.certifications"))),
                      ]),
                      _c("div", { staticClass: "bd-gray" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.certList, function (iconCert, index) {
                            return _c("li", { key: index }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + iconCert.img,
                                  alt: "HB",
                                },
                              }),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                  ? _c("div", { staticClass: "doc-box inner" }, [
                      _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("PD.text.documentation_technical_details")
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "drop-list bd-gray" }, [
                        _c("ul", [
                          _vm.catalogueList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(_vm._s(_vm.$t("PD.text.catalogue"))),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.catalogueList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "download",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.technicalList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("PD.text.technical_datasheet")
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.technicalList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.nationCode === "_GL" &&
                          _vm.fabricationList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PD.text.fabrication"))
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.fabricationList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.installationGuideList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("PD.text.installation_guide")
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.installationGuideList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.warrantyList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(_vm._s(_vm.$t("PD.text.warranty"))),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.warrantyList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.careMaintenanceList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PD.text.care_maintenance"))
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.careMaintenanceList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.certificationList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PD.text.certifications"))
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.certificationList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.nationCode === "_GL" &&
                          _vm.testReportList.list.length > 0
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("PD.text.test_report"))
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.testReportList.list,
                                      function (cata, index) {
                                        return _c("li", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(cata.content.title)),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(cata)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("PD.button.download")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goToDocu()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("PD.button.see_all_documentation")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.nationCode != "_US" && _vm.nationCode != "_GL"
                  ? _c("div", { staticClass: "doc-box inner eu-doc-box" }, [
                      _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                        _vm._v("Documentation"),
                      ]),
                      _c("div", { staticClass: "drop-list bd-gray" }, [
                        _c("ul", [
                          _vm.catalogues.disabled != true
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c(
                                    "h3",
                                    { attrs: { href: "javascript:void(0);" } },
                                    [_vm._v("catalogues")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.catalogues.list,
                                      function (cata, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "doc-drop-li eu-ddl",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-wrap" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "doc-con-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(cata.content.title)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "btns" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "sel-box",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-seg btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "lang-sel-btn-txt",
                                                                  attrs: {
                                                                    id:
                                                                      "catalogues_" +
                                                                      index,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "English"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                              staticStyle: {
                                                                display: "none",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                [
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "English",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "German",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "German"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "France",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "France"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Italian",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Italian"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Spanish",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Spanish"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Dutch",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Dutch"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.downloadTab(
                                                                                "English (UK)",
                                                                                "catalogues_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English (UK)"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "download eu-download",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadDocument(
                                                          cata,
                                                          "catalogues_" + index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.button.download"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.Warranty.disabled != true
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c(
                                    "h3",
                                    { attrs: { href: "javascript:void(0);" } },
                                    [_vm._v("Warranty")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.Warranty.list,
                                      function (cata, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "doc-drop-li eu-ddl",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-wrap" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "doc-con-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(cata.content.title)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "btns" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "sel-box",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-seg btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "lang-sel-btn-txt",
                                                                  attrs: {
                                                                    id:
                                                                      "Warranty_" +
                                                                      index,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "English"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                              staticStyle: {
                                                                display: "none",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                [
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "English",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "German",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "German"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "France",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "France"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Italian",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Italian"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Spanish",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Spanish"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Dutch",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Dutch"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.downloadTab(
                                                                                "English (UK)",
                                                                                "Warranty_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English (UK)"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "download eu-download",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadDocument(
                                                          cata,
                                                          "Warranty_" + index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.button.download"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.Care_Maintenance.disabled != true
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c(
                                    "h3",
                                    { attrs: { href: "javascript:void(0);" } },
                                    [_vm._v("Care & Maintenance")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.Care_Maintenance.list,
                                      function (cata, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "doc-drop-li eu-ddl",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-wrap" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "doc-con-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(cata.content.title)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "btns" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "sel-box",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-seg btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "lang-sel-btn-txt",
                                                                  attrs: {
                                                                    id:
                                                                      "Care_Maintenance_" +
                                                                      index,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "English"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                              staticStyle: {
                                                                display: "none",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                [
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "English",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "German",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "German"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "France",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "France"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Italian",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Italian"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Spanish",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Spanish"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Dutch",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Dutch"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.downloadTab(
                                                                                "English (UK)",
                                                                                "Care_Maintenance_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English (UK)"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "download eu-download",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadDocument(
                                                          cata,
                                                          "Care_Maintenance_" +
                                                            index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.button.download"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.Specification_Documents.disabled != true
                            ? _c("li", [
                                _c("div", { staticClass: "tit" }, [
                                  _c(
                                    "h3",
                                    { attrs: { href: "javascript:void(0);" } },
                                    [_vm._v("Specification Documents")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-view",
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("PD.button.list_view"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "cont bd-0" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.Specification_Documents.list,
                                      function (cata, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "doc-drop-li eu-ddl",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-wrap" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "doc-con-tit",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(cata.content.title)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "btns" },
                                                  [
                                                    _c("ul", [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "sel-box",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-seg btn-sel",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "lang-sel-btn-txt",
                                                                  attrs: {
                                                                    id:
                                                                      "Specification_" +
                                                                      index,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "English"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sel-list",
                                                              staticStyle: {
                                                                display: "none",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "box",
                                                                },
                                                                [
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "English",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "German",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "German"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "France",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "France"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Italian",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Italian"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Spanish",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Spanish"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTab(
                                                                                "Dutch",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Dutch"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.downloadTab(
                                                                                "English (UK)",
                                                                                "Specification_" +
                                                                                  index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "English (UK)"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "download eu-download",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadDocument(
                                                          cata,
                                                          "Specification_" +
                                                            index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PD.button.download"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "more-box ta-c" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goToDocu()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("PD.button.see_all_documentation")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "ins-box" }, [
                _c("h2", { staticClass: "tt-sub-gray ta-c" }, [
                  _vm._v(_vm._s(_vm.$t("PD.text.inspiration"))),
                ]),
                _c(
                  "div",
                  { staticClass: "slider-ins himacs-pd-ins" },
                  _vm._l(_vm.inspirationList, function (insp, index) {
                    return index < 6
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "slider",
                            on: {
                              click: function ($event) {
                                return _vm.goInsDetail(insp)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "img-box" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.$imagePath + insp.attribute.pc_thumb,
                                  alt: "Dr. Leahu Dental Czlinic in Oradea",
                                },
                              }),
                              _c("p", { staticClass: "badge" }, [
                                _vm._v(_vm._s(insp.parents[0].name)),
                              ]),
                            ]),
                            _c("div", { staticClass: "txt-box" }, [
                              _c("ul", [
                                _c("li", { staticClass: "txt-01" }, [
                                  _c(
                                    "a",
                                    { attrs: { href: "javascript:void(0);" } },
                                    [_vm._v(_vm._s(insp.content.title))]
                                  ),
                                ]),
                                _c("li", { staticClass: "txt-02" }, [
                                  _vm._v(_vm._s(insp.attribute.segments1Text)),
                                ]),
                                _c("li", { staticClass: "txt-03" }, [
                                  _vm._v(_vm._s(insp.attribute.location)),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
                _c("div", { staticClass: "more-box ta-c" }, [
                  _c(
                    "button",
                    { staticClass: "btn", attrs: { type: "button" } },
                    [
                      _c(
                        "a",
                        { attrs: { href: "inspiration/case-studies/himacs" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("PD.button.see_all_case_studies"))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "layer-popup layer-enlarge" }, [
                _c(
                  "div",
                  { staticClass: "popup" },
                  [
                    _c("div", { staticClass: "title-box" }, [
                      _c("p", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(_vm.contentName)),
                      ]),
                      _c("div", { staticClass: "btns" }, [
                        _vm.nationCode == "_US" || _vm.nationCode == "_GL"
                          ? _c(
                              "a",
                              {
                                staticClass: "ico-cal",
                                attrs: { href: "javascript:void(0);" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.collection[0]) + " Collection "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.nationCode != "_US" &&
                        _vm.nationCode != "_GL" &&
                        _vm.collection.length < 1
                          ? _c(
                              "a",
                              {
                                staticClass: "ico-cal",
                                attrs: { href: "javascript:void(0);" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.collection[0]) + " Collection "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.nationCode != "_US" &&
                        _vm.nationCode != "_GL" &&
                        _vm.collection.length > 1
                          ? _c(
                              "a",
                              {
                                staticClass: "ico-cal",
                                attrs: { href: "javascript:void(0);" },
                              },
                              [_vm._v(_vm._s(_vm.collection.join(" / ")))]
                            )
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            staticClass: "ico-st",
                            attrs: { href: "javascript:void(0);" },
                          },
                          [_vm._v("Pattern - " + _vm._s(_vm.pattern))]
                        ),
                      ]),
                    ]),
                    _vm.showDragon
                      ? _c("SeaDragon", {
                          attrs: {
                            imagePath: _vm.$imagePath + _vm.imgPage[0].detail,
                          },
                        })
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.enlargeLayer()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("PCD.button.close")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "70%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "arrow disabled tab-arr-prev arrow-short",
        attrs: { type: "button" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("이전")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "arrow tab-arr-next arrow-short",
        attrs: { type: "button" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("다음")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "arrow disabled tab-arr-prev", attrs: { type: "button" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("이전")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "arrow tab-arr-next", attrs: { type: "button" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("다음")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }