<template>
  <div class="social-contribution-strategy-system-goal">
    <SocialResponsibilityBorderTitle
      :title="borderTitle"
      padding="15px 80px"
    />
    <OverviewSectionItem>
      <div class="social-contribution-strategy-system-goal-content">
        <GoalCard
          v-for="(item, index) in cardItems"
          :key="index"
          :number="item.number"
          :text="item.text"
        />
      </div>
    </OverviewSectionItem>
  </div>
</template>

<script>
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import GoalCard from './GoalCard.vue'
import SocialResponsibilityBorderTitle from './SocialResponsibilityBorderTitle.vue'

export default {
  components: {
    SocialResponsibilityBorderTitle,
    GoalCard,
    OverviewSectionItem
  },
  props: {
    borderTitle: {
      type: String
    },
    cardItems: {
      type: Array
    }
  }

}
</script>

<style>
.social-contribution-strategy-system-goal .social-contribution-strategy-system-goal-content {
  display: flex;
  gap: 10px;
  width: 1402px;
  flex-direction: column;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-goal {
    width: 100%;
  }
  .social-contribution-strategy-system-goal .social-contribution-strategy-system-goal-content {
    width: 100%;
  }
}
</style>
