var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lightweight-material-area" },
    [
      _c("ProductSectionHeader", { attrs: { title: "Lightweight Material" } }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lwm_wrap mt-30" }, [
      _c("div", { staticClass: "tab-ui tab-ui-nav type_line type_full" }, [
        _c("ul", [
          _c(
            "li",
            { staticClass: "btn-tab on", attrs: { "data-tab": "litemax" } },
            [_c("button", { attrs: { type: "button" } }, [_vm._v("LiteMax")])]
          ),
          _c(
            "li",
            { staticClass: "btn-tab", attrs: { "data-tab": "lcorepanel" } },
            [
              _c("button", { attrs: { type: "button" } }, [
                _vm._v("L-Core Panel"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "tab-con litemax on" }, [
          _c("div", { staticClass: "lwm_content" }, [
            _c("div", { staticClass: "prod_img_area" }, [
              _c("div", { staticClass: "img_wrap" }, [
                _c("img", {
                  attrs: {
                    src: "images/automotive/lightweight/litemax.png",
                    alt: "litemax",
                  },
                }),
              ]),
              _c("div", { staticClass: "tit_area" }, [
                _c("p", { staticClass: "lwm_title" }, [_vm._v("LiteMax")]),
                _c("span", { staticClass: "lwm_desc" }, [
                  _vm._v(
                    "LX Hausys' Thermoplastic Continuous Glass Fiber Sheet"
                  ),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "lwm_table" }, [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "15.25%" } }),
                _c("col", { staticStyle: { width: "auto" } }),
              ]),
              _c("tr", [
                _c("th", [_vm._v("LiteMax PPGF60")]),
                _c("td", [
                  _c("p", [
                    _vm._v("- Polypropylene impregnated glass fiber sheet"),
                  ]),
                  _c("p", [_vm._v("- Glass fiber content : 60wt%")]),
                  _c("p", [_vm._v("- Best for lightweight designs")]),
                  _c("p", [_vm._v("- Application : Rear bumper beam")]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("LiteMax PPGF80")]),
                _c("td", [
                  _c("p", [
                    _vm._v("- Polypropylene impregnated glass fiber sheet"),
                  ]),
                  _c("p", [_vm._v("- Glass fiber content : 80wt%")]),
                  _c("p", [
                    _vm._v("- High glass fiber content for high stiffness"),
                  ]),
                  _c("p", [
                    _vm._v("- Application : Bettery pack bottom panel"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("LiteMax PAGF60")]),
                _c("td", [
                  _c("p", [
                    _vm._v("- Polyamide 6 impregnated glass fiber sheet"),
                  ]),
                  _c("p", [_vm._v("- Glass fiber content : 60wt%")]),
                  _c("p", [
                    _vm._v("- Excellent thermal and impact resistance"),
                  ]),
                  _c("p", [_vm._v("- Application : Seat back frame")]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "tab-con lcorepanel" }, [
          _c("div", { staticClass: "lwm_content" }, [
            _c("div", { staticClass: "prod_img_area" }, [
              _c("div", { staticClass: "img_wrap" }, [
                _c("img", {
                  attrs: {
                    src: "images/automotive/lightweight/lcorepanel.png",
                    alt: "l-core panel",
                  },
                }),
              ]),
              _c("div", { staticClass: "tit_area" }, [
                _c("p", { staticClass: "lwm_title" }, [_vm._v("L-Core Panel")]),
                _c("span", { staticClass: "lwm_desc" }, [
                  _vm._v(
                    "Lightweight sandwich panels with fiber core are used as a wall for trailers in North America. L-core panels are lighter, more corrosion-resistant, and more resilient than HDPE Core panels."
                  ),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "lcore_table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("td", { attrs: { colspan: "2" } }, [_vm._v("HDPE Panel")]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v("L-Core Panel"),
                  ]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("th"),
                  _c(
                    "td",
                    { staticClass: "steel_wrap", attrs: { colspan: "2" } },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: "images/automotive/lightweight/hdpepanel_steel.png",
                            alt: "HOPE Panel",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "td",
                    { staticClass: "steel_wrap", attrs: { colspan: "2" } },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: "images/automotive/lightweight/lcorepanel_steel.png",
                            alt: "L-Core Panel",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Thickness")]),
                  _c("td", [_vm._v("6.5 mm")]),
                  _c("td", [_vm._v("0.256 in")]),
                  _c("td", [_vm._v("6.5 mm")]),
                  _c("td", [_vm._v("0.256 in")]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Unit weight")]),
                  _c("td", [
                    _c("p", [_vm._v("11.03 kg/m"), _c("sup", [_vm._v("2")])]),
                  ]),
                  _c("td", [_vm._v("2.26 lbs/ft"), _c("sup", [_vm._v("2")])]),
                  _c("td", [_vm._v("9.30 kg/m"), _c("sup", [_vm._v("2")])]),
                  _c("td", [_vm._v("1.90 lbs/ft"), _c("sup", [_vm._v("2")])]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Weight reduction")]),
                  _c("td", [_vm._v("-")]),
                  _c("td", [_vm._v("-")]),
                  _c("td", [_vm._v("12.8%")]),
                  _c("td", [_vm._v("12.8%")]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Corrosion resistance")]),
                  _c("td", [_vm._v("-")]),
                  _c("td", [_vm._v("-")]),
                  _c("td", [_vm._v("Excellent")]),
                  _c("td", [_vm._v("Excellent")]),
                ]),
                _c("tr", [
                  _c("th", [
                    _vm._v("Deformation"),
                    _c("br"),
                    _vm._v(" before and after loading"),
                  ]),
                  _c("td", [_vm._v("10.0 mm")]),
                  _c("td", [_vm._v("0.394 in")]),
                  _c("td", [_vm._v("3.5 mm")]),
                  _c("td", [_vm._v("0.138 in")]),
                ]),
              ]),
            ]),
            _c("span", [
              _vm._v(
                "*Based on our high temperature and humidity accelerated life test"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }