<template>
    <div class="financial-statement-table-area">
        <table class="financial-statement-table">
            <FinancialStatementTableHeader :categoryList="categoryList" />
            <FinancialStatementTableColumn :dataList="dataList"/>
        </table>
    </div>
</template>

<script>
import FinancialStatementTableColumn from './FinancialStatementTableColumn.vue'
import FinancialStatementTableHeader from './FinancialStatementTableHeader.vue'

export default {
  components: {
    FinancialStatementTableHeader,
    FinancialStatementTableColumn
  },
  props: {
    categoryList: {
      type: Array,
      default: () => []
    },
    dataList: {
      type: Array,
      default: () => []
    }
  }
}

</script>

<style scoped>
.financial-statement-table-area {
    margin: 0 auto;
    width: 1440px;
}

</style>
