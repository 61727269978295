var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("div", { staticClass: "filter_checkbox_tit" }, [
        _c("span", [_vm._v("COMPARE")]),
      ]),
      _c("div", { staticClass: "compare_wrap m-ui" }, [
        _c("input", {
          staticClass: "compare_btn",
          attrs: {
            type: "checkbox",
            id: "compare_btn2",
            name: "compare_button",
            value: "",
          },
        }),
        _c("label", { attrs: { for: "compare_btn2" } }, [
          _c("span", { staticClass: "txt" }, [_vm._v("ON")]),
          _c("span", { staticClass: "btn" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }