var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "region-card-area", class: _vm.cardType }, [
    _c("div", { staticClass: "number-area" }, [
      _c("span", [_vm._v(_vm._s(_vm.iconTxt))]),
    ]),
    _c("div", { staticClass: "content-area", class: _vm.cardType }, [
      _c("div", { staticClass: "image-area" }, [
        _c("img", { attrs: { src: _vm.mapImageSrc } }),
      ]),
      _c("div", { staticClass: "text-area" }, [
        _c("div", { staticClass: "title-area" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
        ]),
        _c("div", { staticClass: "desc-area" }, [
          _c("pre", [_vm._v(_vm._s(_vm.desc))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }