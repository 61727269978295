<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content prod-dt-cate">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t('PCD.text.home')}}</li>
          <li>{{ $t('PCD.text.products')}}</li>
          <li v-if="this.pdCategoryType === 'DECO'">
            Deco Foil
          </li>
          <li v-else>{{ pdCategoryType}}</li>
          <li v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/products/${getlink()}`)">{{$t(`PF.text.allColors.${$route.meta.parentType.toLowerCase()}`)}}</li>
          <li v-if="this.pdCategoryType === 'HFLOR'"  v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/products/${getSublink()}`)">{{ prdLabel }}</li>
          <li v-if="this.pdCategoryType === 'VIZUON'" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/products/${getSublink()}`)">{{$t(`PCD.text.sub.${$route.meta.type.toLowerCase()}`)}}</li>
          <li>{{ contentName }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (viatera detail) -->
      <div class="pro-dt-warp viatera-pd">
        <div :class="pdContainerCssClass">

          <!-- product pd visual slide  -->
          <div class="w-con product_pd_mt">
            <div class="row">
              <div class="prod-img">
                <div class="slider-prod viartW pd-slider">
                  <div
                    class="slider pdf-inslide03"
                    v-for="(fileName, index) in imageInfo.imgPage"
                    v-bind:key="index"
                    v-show="imgLoaded === true"
                  >
                    <div class="detail_view_wrap" v-if="fileName.enlarge">
                      <div class="bubble_pop">
                        <span>Detailed view</span>
                      </div>
                      <button type="button" class="btn_detail_view" @click="enlargeLayer('open')">
                        <span class="icon icon_detail"></span>
                      </button>
                    </div>
                    <div class="img_wrap" v-if="fileName.youtube != true">
                      <div class="big_look_wrap">
                        <button type="button" class="btn_big_look" @click="aBigLookLayer('open',fileName, $imagePath, index)">
                          <span class="icon icon_look"></span>
                        </button>
                      </div>
                      <img
                        :src="$imagePath + fileName.image"
                        alt="product" class="pd_product_img"
                      />
                    </div>
                    <div class="yt-video" v-if="fileName.youtube == true" :style="'max-width:'+youtubeWidth+';' + 'margin: 0 auto'">
                      <iframe autoplay="autoplay"
                              muted="muted"
                              controls
                              allowscriptaccess="always"
                              style="width:auto; min-width: 100%; min-height: 100%"
                              :src="fileName.image + '?enablejsapi=1'"
                              alt="youtube"
                              class="yt_player_iframe"
                      />
                    </div>
                  </div>
                </div>

                <div class="slider-prod-nav navWide tac">
                  <div
                    class="slider"
                    v-for="(fileName, index) in imageInfo.imgPage"
                    v-bind:key="index"
                    style="position: relative"
                    v-show="imgLoaded === true"
                  >
                    <img
                      :data-chk="index"
                      v-if="!$_.isEmpty(fileName.image) && !$_.isEmpty(fileName.thumb )"
                      :src="$imagePath + fileName.thumb"
                      alt="thumbnail"
                    />
                    <div v-if="fileName.youtube == true" class="youtube_thumb">
                      <i class="icon_youtube_play"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- product info -->
              <div class="prod-info">
                <div class="prod_info_head">
                  <div class="info-01">
                    <span class="tag h30 new" v-if="newColor == 'true'">{{ $t('PCD.text.new')}}</span>
                  </div>
                  <div class="btns">
                    <ul>
                      <li id="imgDownload" v-if="showImgDownload()">
                        <a class="icon-download-bk" @click="imgDownload()"
                        >{{ $t('PD.text.image_download_view')}}</a
                        >
                      </li>
                      <li>
                        <ShareComponent
                          v-bind:childValue="contentList"
                          :type="'product'"
                        ></ShareComponent>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="prod_info_body">
                  <h1 class="title">{{ contentName }}</h1>
                  <div class="info-01">
                    <span v-if="colorCode && colorCode.trim() !== '' && pdCategoryType !== 'VIATERA' && pdCategoryType !== 'TERACANTO' && pdCategoryType !== 'BENIF'" class="prod_code">{{ colorCode }}</span>
                    <span v-if="colorCode && colorCode.trim() !== '' && pdCategoryType !== 'VIATERA' && pdCategoryType !== 'TERACANTO' && pdCategoryType !== 'BENIF'" class="separate_line">|</span>
                    <span class="prod_collection">{{ collection }} {{ $t('PCD.text.collection')}}</span>
                  </div>
                  <p class="info-03 ctt ctt-t4" v-html="brief"></p>
                </div>
                <div class="prod_info_foot">
                  <div class="btn_area">
                    <div class="row roomvo-button-area" v-if="nationCode === '_US'" style="visibility: hidden;" :data-product-id="contentId" :data-sku="sku">
                      <div class="roomvo-container">
                        <button type="button" class="button h55 btn_visualizer roomvo-stimr-mobile roomvo-stimr">
                          <span class="icon icon_visual"></span>
                          <span class="txt">Visualizer</span>
                        </button>
                      </div>
                      <div class="roomvo-container_3d">
                        <button type="button" class="button h55 btn_view3d roomvo-product-display-button-mobile roomvo-product-display-button">
                          <span class="icon icon_view3d"></span>
                          <span class="txt">View in 3D</span>
                        </button>
                      </div>
                    </div>
                    <div v-if="nationCode === '_US' && !canOrder(contentDetail)">
                      <button type="button" class="button h55 btn_sample not_available">
                        <span class="icon icon_sample on">
                        </span>
                        <span class="txt">Sample Order</span>
                      </button>
                      <span class="ctt ctt-t5 not_yet">Not yet available</span>
                    </div>
                    <button type="button" class="button h55 btn_sample" v-if="nationCode === '_US' && canOrder(contentDetail)" @click="openSampleOrder(contentId)">
                      <span class="icon icon_sample on"></span>
                      <span class="txt">Sample Order</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- //product info -->
            </div>
          </div>

          <!-- *prod-specs -->
          <div class="prod-wrap specs">
            <div class="content_tit_area">
              <h2 class="con_title tt-2">PRODUCT SPECS</h2>
            </div>
            <div class="row_scroll">
              <table>
                <colgroup v-if="(this.pdCategoryType === 'BENIF') || (this.pdCategoryType === 'EXTERIOR') || (this.pdCategoryType === 'BORTE')">
                  <col style="width:20%">
                  <col style="width:20%">
                  <col style="width:20%">
                  <col style="width:20%">
                  <col style="width:20%">
                </colgroup>
                <colgroup v-else-if="(this.pdCategoryType === 'VIATERA') || (this.pdCategoryType === 'HIMACS') || (this.pdCategoryType === 'HFLOR') || (this.pdCategoryType === 'TERACANTO') || (this.pdCategoryType === 'DECO')">
                  <col style="width:17%">
                  <col style="width:17%">
                  <col style="width:17%">
                  <col style="width:16%">
                  <col style="width:16%">
                  <col style="width:16%">
                </colgroup>
                <colgroup v-else-if="(this.pdCategoryType === 'VIZUON')">
                  <col style="width:14%">
                  <col style="width:14%">
                  <col style="width:14%">
                  <col style="width:14%">
                  <col style="width:14%">
                  <col style="width:14%">
                  <col style="width:14%">
                  <col style="width:15%">
                </colgroup>
                <thead v-if="this.pdCategoryType === 'VIZUON'">
                <tr v-if="productSpecs.length > 0">
                  <th></th>
                  <th>Film Type<br>Material</th>
                  <th>Film Type<br>Thickness</th>
                  <th>Film Type<br>Color</th>
                  <th>Film Type<br>Adhesive Type</th>
                  <th>Adhesive Type<br>Color</th>
                  <th>Adhesive Type<br>Air Free</th>
                  <th>Liner</th>
                </tr>
                </thead>
                <thead v-else>
                <tr v-if="productSpecs.length > 0">
                  <th v-for="item in productSpecs[0]">
                    <template v-for="(value, key) in item">
                      {{ key }}
                    </template>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="spec in productSpecs">
                  <td v-for="item in spec">
                    <template v-for="(value, key) in item">
                      {{ value }}
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- //prod-specs -->

          <!-- *prod-docu -->
          <div class="prod-wrap docu">
            <div class="content_tit_area">
              <h2 class="con_title tt-2">DOCUMENTATIONS &#38; TECHNICAL DETAIL</h2>

              <div class="more">
                <a :href="`/${countryCodeSub.toLowerCase()}/pros/documentation/${getDocName(pdCategoryType)}`" class="view_more">
                  <span>View more</span>
                  <span class="icon"></span>
                </a>
              </div>
            </div>
            <div class="prod-content docu">
              <div class="row">
                <div class="half_item">
                  <div class="docu-accordion">
                    <div class="docu-title">
                      <p>CATALOG</p>
                      <div class="docu-arrow">

                      </div>
                    </div>
                    <div class="docu-contents">
                      <ul>
                        <li v-if="Array.isArray(documentation.catalogueList.list) && documentation.catalogueList.list.length > 0" v-for="(cata, index) in documentation.catalogueList.list.slice(0,10)" v-bind:key="index">
                          <div class="docu-content">
                            <p>{{ cata.content.title }}</p>
                            <div class="btn_download_icon">
                              <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                            </div>
                          </div>
                        </li>
                        <li v-if="documentation.catalogueList.list.length == 0" class="nodatalist">
                          <span class="nodata">No Data</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="docu-accordion">
                    <div class="docu-title">
                      <p>TECHNICAL DATASHEET</p>
                      <div class="docu-arrow">

                      </div>
                    </div>
                    <div class="docu-contents">
                      <ul>
                        <li v-if="Array.isArray(documentation.technicalList.list) && documentation.technicalList.list.length > 0" v-for="(cata, index) in documentation.technicalList.list.slice(0,10)" v-bind:key="index">
                          <div class="docu-content">
                            <p>{{ cata.content.title }}</p>
                            <div class="btn_download_icon">
                              <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                            </div>
                          </div>
                        </li>
                        <li v-if="documentation.technicalList.list.length == 0" class="nodatalist">
                          <span class="nodata">No Data</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="docu-accordion">
                    <div class="docu-title">
                      <p>WARRANTY</p>
                      <div class="docu-arrow">

                      </div>
                    </div>
                    <div class="docu-contents">
                      <ul>
                        <li v-if="Array.isArray(documentation.warrantyList.list) && documentation.warrantyList.list.length > 0" v-for="(cata, index) in documentation.warrantyList.list.slice(0,10)" v-bind:key="index">
                          <div class="docu-content">
                            <p>{{ cata.content.title }}</p>
                            <div class="btn_download_icon">
                              <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                            </div>
                          </div>
                        </li>
                        <li v-if="documentation.warrantyList.list.length == 0" class="nodatalist">
                          <span class="nodata">No Data</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="half_item">
                  <div class="docu-accordion">
                    <div class="docu-title">
                      <p>CERTIFICATION</p>
                      <div class="docu-arrow">

                      </div>
                    </div>
                    <div class="docu-contents">
                      <ul>
                        <li v-if="Array.isArray(documentation.certificationList.list) && documentation.certificationList.list.length > 0" v-for="(cata, index) in documentation.certificationList.list.slice(0,10)" v-bind:key="index">
                          <div class="docu-content">
                            <p>{{ cata.content.title }}</p>
                            <div class="btn_download_icon">
                              <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                            </div>
                          </div>
                        </li>
                        <li v-if="documentation.certificationList.list.length == 0" class="nodatalist">
                          <span class="nodata">No Data</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="docu-accordion">
                    <div class="docu-title">
                      <p>INSTALLATION GUIDE</p>
                      <div class="docu-arrow">

                      </div>
                    </div>
                    <div class="docu-contents">
                      <ul>
                        <li v-if="Array.isArray(documentation.installationGuideList.list) && documentation.installationGuideList.list.length > 0" v-for="(cata, index) in documentation.installationGuideList.list.slice(0,10)" v-bind:key="index">
                          <div class="docu-content">
                            <p>{{ cata.content.title }}</p>
                            <div class="btn_download_icon">
                              <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                            </div>
                          </div>
                        </li>
                        <li v-if="documentation.installationGuideList.list.length == 0" class="nodatalist">
                          <span class="nodata">No Data</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="docu-accordion">
                    <div class="docu-title">
                      <p>CARE &#38; MAINTENANCE</p>
                      <div class="docu-arrow">

                      </div>
                    </div>
                    <div class="docu-contents">
                      <ul>
                        <li v-if="Array.isArray(documentation.careList.list) && documentation.careList.list.length > 0" v-for="(cata, index) in documentation.careList.list.slice(0,10)" v-bind:key="index">
                          <div class="docu-content">
                            <p>{{ cata.content.title }}</p>
                            <div class="btn_download_icon">
                              <button type="button" @click="download(cata)">{{ $t('PD.button.download')}}</button>
                            </div>
                          </div>
                        </li>
                        <li v-if="documentation.careList.list.length == 0" class="nodatalist">
                          <span class="nodata">No Data</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- //prod-docu -->

          <!-- *prod-edge -->
          <!-- 통이미지 영역 hold -->
          <div class="prod-wrap edge" v-if="collectionTongTitle && collectionTongtongImagePath">
            <div class="content_tit_area">
              <h2 class="con_title tt-2" v-html="collectionTongTitle"></h2>
            </div>
            <div class="row_scroll">
              <div class="prod-content edge">
                <img :src="$imagePath+collectionTongtongImagePath" alt="">
              </div>
            </div>
          </div>
          <!-- //prod-edge -->

          <!-- *prod-related -->
          <div class="prod-wrap related" v-if="relContentList != null && relContentList != undefined && relContentList != ''">
            <div class="content_tit_area">
              <h2 class="con_title tt-2">RELATED PRODUCTS</h2>
            </div>
            <button class="arrow-prev">pr</button>
            <button class="arrow-next">ne</button>
            <div class="row_scroll">
              <div class="prod-content related mo_pd_rela_slide">
                <template  v-if="isMobile()">
                  <div class="related-content" v-for="(item, index) in relContentList">
                    <div
                      class="thumb"
                      v-bind:key="index">
                      <router-link :to="makeUrl(item)" :data-ec-product="makeDataLayerForm(item)">
                        <div class="over">
                          <span class="tag h30 new" v-if="item.attribute.isNew === 'true'">
                            {{ $t('PCD.text.new')}}
                          </span>
                          <span class="content_title">{{ item.content.title }}</span>
                          <img
                            :src="$imagePath + item.attribute.pf_thumb"
                            alt="thumb"
                          />
                        </div>
                      </router-link>
                      <span v-if="nationCode === '_US' && canOrder(item)"
                            @click="openSampleOrder(item.content.id)"
                            class="icon_cart" tabindex="0"
                      >{{ $t('PCD.text.cart')}}</span>
                    </div>
                  </div>
                </template>
                <div class="related-content" v-else v-for="i in Math.ceil(relContentList.length / 8)" :key="i">
                  <div
                    class="thumb"
                    v-for="(item, index) in relContentList.slice((i - 1) * 8, i * 8)"
                    v-bind:key="index">
                    <router-link :to="makeUrl(item)" :data-ec-product="makeDataLayerForm(item)">
                      <div class="over">
                        <span class="tag h30 new" v-if="item.attribute.isNew === 'true'">
                          {{ $t('PCD.text.new')}}
                        </span>
                        <span class="content_title">{{ item.content.title }}</span>
                        <img
                          :src="$imagePath + item.attribute.pf_thumb"
                          alt="thumb"
                        />
                      </div>
                    </router-link>
                    <span v-if="nationCode === '_US' && canOrder(item)"
                          @click="openSampleOrder(item.content.id)"
                          class="icon_cart" tabindex="0"
                    >{{ $t('PCD.text.cart')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- //prod-related -->

          <!-- pcd why section  -->
          <div class="why_section" v-if="pcdContentAttribute.template_use_yn === 'true'">
            <div class="why_top_bg full_width">
              <div class="inner_width">
                <div class="content_tit_area">
                  <h2 class="con_title tt-2">{{ pcdContentAttribute.template_title }}</h2>
                </div>
                <template v-for="(images, index) in Object.keys(pcdTemplateFieldData.image)">
                  <div :class="`why_row col_0${imageKey}`" v-for="(imageKey, index2) in Object.keys(pcdTemplateFieldData.image[index])">
                    <div class="why_item" v-for="(imageItem, index3) in pcdTemplateFieldData.image[index][imageKey]">
                      <!-- 20240701 기획요청 - 3개짜리 아이템일때 [one_third] 클래스 추가 -->
                      <div class="img">
                        <img :src="$imagePath + imageItem.img" :alt="imageItem.title"/>
                      </div>
                      <div class="description_box">
                        <p class="title tt-3">{{ imageItem.title }}</p>
                        <p class="txt ctt ctt-t3">
                          {{ imageItem.desc }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>

              </div>
            </div>
            <!-- 퀄리티 콘텐츠가 있을때만 노출 -->
            <div class="w-con" v-if="pcdTemplateFieldData.icon.length">
              <div class="row combo_area">
                <template v-for="(iconItem, index) in pcdTemplateFieldData.icon">
                  <div class="content_item" v-if="iconItem.img">
                    <div class="icon_box">
                      <img :src="$imagePath + iconItem.img" :alt="iconItem.title">
                    </div>
                    <div class="description_box">
                      <p class="title tt-30">{{ iconItem.title }}</p>
                      <p class="txt ctt ctt-t3">{{ iconItem.desc }}</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- certification -->
          <div class="certification_section full_width" v-if="pcdContentAttribute.cert_use_yn === 'true'">
            <div class="inner_width">
              <div class="content_tit_area">
                <h2 class="con_title tt-2">{{ pcdContentAttribute.cert_title }}</h2>
              </div>
              <div class="certi_group">
                <ul>
                  <li v-for="(certItem, index) in pcdCertificationData">
                    <div class="item">
                      <img :src="$imagePath + certItem.image" :alt="certItem.description" />
                    </div>
                  </li>
                </ul>
                <div class="btn_area" v-if="pcdCertificationData.length && checkCertificationDescription('description')">
                  <!-- <template > -->
                    <button type="button" class="btn_learn_more" @click="openModalCerti()">
                      <span class="txt">Learn more</span>
                      <span class="icon icon_arrow_right"></span>
                    </button>
                  <!-- </template> -->
                </div>
              </div>
            </div>
          </div>

          <!-- inspiration gallery  -->
          <div class="inspiration_section full_width" v-if="pcdContentAttribute.inpg_use_yn === 'true'">
            <div class="inner_width">
              <div class="content_tit_area">
                <h2 class="con_title tt-2">{{ pcdContentAttribute.inpg_title }} </h2>
                <div class="more">
                  <a :href="`inspiration/gallery/${getPcdTypeName(pdCategoryType)}/product_cate`" class="view_more">
                    <span>View more</span>
                    <span class="icon"></span>
                  </a>
                </div>
              </div>
              <!-- gallery -->
              <div class="gallery">
                <div class="grid-box">
                  <ul class="ins_grid">
                    <li class="gal-grid-cont" v-for="(inspirationItem, index) in pcdInspirationList">
                      <button type="button" class="g-box" @click="gotolink(`/${countryCodeSub.toLowerCase()}/inspiration/gallery-detail?contentId=${inspirationItem.content.id}`)">
                        <div class="img-box">
                          <img
                            :src="$imagePath + inspirationItem.attribute.mainThumbnail"
                            :alt="inspirationItem.content.title"
                          />
                        </div>
                        <div class="txt-box">
                          <p class="txt-cate">
                            <template  v-for="productItem in inspirationItem.attribute.product_cateText">
                              <span v-html="productItem"/>
                            </template>
                          </p>
                          <p class="content_title">{{ inspirationItem.content.title }}</p>
                          <div class="hashtag_group">
                            <span class="hashtag" v-for="(tagItem, tagIndex) in inspirationItem.attribute.applicationList">#{{ tagItem.text }}</span>
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //gallery -->
            </div>
          </div>
        </div>

        <!-- popup : enlarge -->
        <div class="layer-popup layer-enlarge">
          <div class="popup">
            <div class="title-box">
              <p class="product-name">{{ contentName }}</p>
            </div>

            <SeaDragon v-bind:imagePath="$imagePath + imageInfo.imgPage[0].detail" v-if="showDragon"></SeaDragon>

            <button
              type="button"
              class="btn-close"
              @click="enlargeLayer()"
            >
              {{ $t('PCD.button.close')}}
            </button>
          </div>
          <!-- //popup -->
        </div>
        <!-- S : popup : big look -->
        <div class="layer-view layer-pop popup-w1440 modal-big-look">
          <button type="button" class="btn-close m-ui" onClick="tmpClose()">close</button>
          <div class="popup pop-close">
            <div class="scr-y">
              <div class="popup-body no_padding">
                <div class="popup_slider">
                </div>
              </div>
            </div>
            <button type="button" class="btn-close p-ui" onClick="tmpClose()">close</button>
          </div>
        </div>
        <!-- E : popup : big look -->

        <!-- S : popup : certification -->
        <div class="layer-view layer-pop popup-w1200 modal-certification" v-if="pcdContentAttribute.cert_use_yn === 'true'">
          <div class="popup pop-close">
            <div class="scr-y">
              <h3 class="popup-title p-ui">Certification Description</h3>
              <h3 class="popup-title m-ui">Certification <br> Description</h3>
              <div class="popup-body">
                <div class="certi_pop_con">
                  <ul>
                    <li v-for="(certItem, index) in pcdCertificationData">
                      <div class="row">
                        <div class="img_wrap">
                          <img :src="$imagePath + certItem.image" :alt="certItem.description"/>
                        </div>
                        <div class="txt_wrap">
                          <div class="tit_box">{{ certItem.title }}</div>
                          <div class="txt_box">{{ certItem.description }}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button type="button" class="btn-close" @click="closeModalCerti()">close</button>
          </div>
        </div>
        <!-- E : popup : certification -->

      </div>
      <!-- //container (himacs PCD) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import ShareComponent from '@/components/Layout/Share'
import categoryService from '@/server/category.service'
import InspirationService from '@/server/inspiration.service'
import ProductService from '@/server/product.service'
import product from '@/views/search/product.vue'
import { debounce } from 'vue-debounce'
import { mapState } from 'vuex'
import SeaDragon from './SeaDragon'
import {
getDocumentation, getEcomPrdData, getImageInfo,
getProductSpec
} from './js/product-detail'

export default {
  name: 'Detail',
  props: ['childValue', 'type'],
  components: {
    GnbComponent,
    HeaderComponent,
    ShortComponent,
    QuickMenuComponent,
    FooterComponent,
    ShareComponent,
    SeaDragon
  },
  computed: {
    product () {
      return product
    },
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      /* 공통 */
      youtubeWidth: '',
      youtubeHeight: '',
      youtubeCheck: false,
      showDragon: false,
      nationCode: '' /* 국가 코드 */,
      pdCategoryType: '' /* 카테고리 */,
      prdLabel: '',
      contentName: '' /* 본 컨텐츠 명 viatera,himacs */,
      collection: '' /* 데이터 사전에서 받아온 값 | EX : Musica */,
      productSpecs: [],
      collectionTongTitle: '',
      collectionTongtongImagePath: '',
      brief: '' /* 해당 내용 설명 */,
      contentDetail: {},

      imageInfo: { full_slap: {}, cover: {}, imgPage: [] },

      Tslider: 0 /* 슬라이더 번호 추출 */,
      newColor: '',
      colorCode: '',

      /* Related content */
      relContentList: [] /* 상품 관련 콘텐츠 리스트 */,
      /* Related content end */
      selConId: 0,

      categoryCode: '',

      // document List
      documentation: {
        catalogueList: { disabled: false, list: [] },
        technicalList: { disabled: false, list: [] },
        installationGuideList: { disabled: false, list: [] },
        warrantyList: { disabled: false, list: [] },
        careList: { disabled: false, list: [] },
        certificationList: { disabled: false, list: [] }
      },

      /* 배너 URL => Finder 찾기 위한 여정 */
      contentId: '' /* 본 컨텐츠 아이디 */,
      slug: '' /* 본 컨텐츠 Slug */,
      sku: '',

      getSessionContent: [] /* GL : 불러오는 값 */,

      contentList: [],
      imgLoaded: false,
      pdCategoryName: '',

      /* 하단 컨텐츠 */
      pcdContent: [],
      pcdContentAttribute: {},
      pcdTemplateData: {},
      pcdTemplateFieldData: { image: [], icon: [] },
      pcdCertificationData: [],
      pcdMiddleBannerData: [],
      pcdInspirationList: [],
      pdContainerCssClass: '',
      countryCodeSub: ''
    }
  },

  watch: {
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)

    /* URL 주소 에서 값 받아온다 == 92 */

    if (t.$route.params.prd_name == 'detail') {
      t.slug = this.$route.params.contentId
    } else {
      t.contentId = this.$route.params.contentId
    }

    if (!t.contentId && !t.slug) {
      // contentId가 없는 경우 404 에러 페이지로 이동
      this.$router.push({ name: 'notFound' })
      return
    }

    if (t.nationCode != '_US') {
      /* 테스트중 / 개발 끝나면 GL 로 변경해야 함. */
      const SessionPD = JSON.parse(sessionStorage.getItem('PD'))
      _.filter(SessionPD, function (o) {
        t.getSessionContent.push(o.id)
      })
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  async mounted () {
    const t = this
    const _this = this
    t.pdCategoryName = t.$route.params.category_name
    if (t.$route.meta.code == 'PRD_FLOORING') {
      t.pdCategoryType = 'HFLOR'
    } else if (t.$route.meta.code == 'PRD_VIZUON') {
      t.pdCategoryType = 'VIZUON'
    } else {
      t.pdCategoryType = t.$route.meta.code.substring(0, t.$route.meta.code.indexOf('_COLOR'))
    }
    this.pdContainerCssClass = `container prod-dt-view pd_container ${t.pdCategoryType.toLowerCase()}_pd`
    /* codeList */
    let codes = []

    // try {
    let contentRes

    if ((_.isUndefined(t.contentId) || _.isEmpty(t.contentId)) && !_.isUndefined(t.slug)) {
      const cateCode = t.$route.meta.code + t.nationCode
      contentRes = await ProductService.getContentBySlugAndCategory(cateCode, t.slug)
      t.contentId = contentRes.data.content.id
    } else {
      contentRes = await ProductService.getContent(t.contentId)
    }

    t.contentList = contentRes.data
    t.imageInfo = getImageInfo(contentRes.data)

    t.sku = contentRes.data.attribute.sku

    /* 스펙 가져오기 prod-specs */
    t.productSpecs = getProductSpec(t.pdCategoryType, contentRes.data, t.nationCode)

    t.contentName = contentRes.data.content.title
    t.brief = contentRes.data.attribute.brief
    t.newColor = contentRes.data.attribute.isNew
    t.colorCode = contentRes.data.attribute.code
    t.contentDetail = contentRes.data
    /* 카테고리 collection 정보 가져오기 */
    t.collection = contentRes.data.parents[0].name

    this.$nextTick(function () {
      t.imgLoaded = true

      this.prodImgSlider()
    })

    // 콜렉션 정보 가져오기
    const collectionInfo = await ProductService.getCategoryListAttr(
      contentRes.data.content.categoryId
    )

    if (collectionInfo && collectionInfo.data && collectionInfo.data.attribute) {
      this.collectionTongTitle = collectionInfo.data.attribute.tong_title || ''
      this.collectionTongtongImagePath = collectionInfo.data.attribute.tong_imagePath || ''
    }

    // GTM - product detail
    this.makeDataLayerForm(contentRes.data)
    if (typeof (dataLayer) !== 'undefined') {
      dataLayer.push({ event: 'ecDetail' })
    }

    /* ------ 관련 콘텐츠 ------ */
    t.relContentList = getAllRelatedContent(contentRes.data)

    /* 카테고리 코드 */
    if (t.pdCategoryType === 'HFLOR') {
      t.categoryCode = contentRes.data.parents[1].code
      t.prdLabel = contentRes.data.parents[1].name /* 카테고리 이름 */
      t.pdCategoryType = contentRes.data.parents[2].name /* 카테고리 이름 */
    } else if (t.pdCategoryType === 'VIZUON') {
      t.categoryCode = contentRes.data.parents[0].code
    }

    /* YouTube 크기 */
    if (t.pdCategoryType === 'VIZUON') {
      this.youtubeWidth = '832px'
      this.youtubeHeight = '600px'
    } else {
      this.youtubeWidth = '900px'
      this.youtubeHeight = '600px'
    }

    /* ==================================================== start [ documentation 컨텐츠 ] ======================================================== */
    if (contentRes.data.parents[1].code.startsWith('VIZUON_')) {
      t.documentation = await getDocumentation(contentRes.data.parents[0].code)
    } else {
      t.documentation = await getDocumentation(contentRes.data.parents[1].code)
    }
    /* ==================================================== end [ documentation 컨텐츠 ] ======================================================== */

    /* ==================================================== start [ PCD 컨텐츠 ] ======================================================== */
    const contentTypeCode = this.$route.meta.parentType ? this.$route.meta.type : t.pdCategoryType
    const contentCategoryCode = `${t.countryCodeSub}_PCD_${contentTypeCode}`
    await categoryService.getCategoryCode(contentCategoryCode)
      .then(async (res) => {
        const categoryId = res.data.object.id

        const content = await ProductService.getContentSearchList(categoryId)
        if (content.data.length > 0) {
          const contentData = content.data[0]

          const { id } = contentData

          _this.pcdContent = await ProductService.getContent(id)
          _this.pcdContentAttribute = _this.pcdContent.data.attribute
          _this.pcdCertificationData = !_.isUndefined(_this.pcdContentAttribute.cert_image_data)
            ? JSON.parse(_this.pcdContentAttribute.cert_image_data)
            : []
          _this.pcdMiddleBannerData = !_.isUndefined(_this.pcdContentAttribute.mb_pcd_data)
            ? JSON.parse(_this.pcdContentAttribute.mb_pcd_data)
            : []
          _this.pcdTemplateData = !_.isUndefined(_this.pcdContentAttribute.template_pcd_data)
            ? JSON.parse(_this.pcdContentAttribute.template_pcd_data)
            : []

          if (!_.isUndefined(_this.pcdContentAttribute.template_pcd_data)) {
            if (!_.isUndefined(_this.pcdTemplateData.fieldData)) {
              _this.pcdTemplateFieldData = _this.pcdTemplateData.fieldData
            }
            _this.pcdTemplateFieldData.image = Object.entries(
              _this.pcdTemplateFieldData.image.reduce((acc, item) => {
                // id의 첫번째 자리
                const key = item.id.toString()[0]
                // 키가 없으면 새로운 배열 생성
                acc[key] = acc[key] || []
                acc[key].push(item)
                return acc
              }, {})
              // 키-값 쌍을 객체로 변환
            ).map(([key, value]) => ({ [key]: value }))
          }
        }
      })

    codes = [
      `${t.countryCodeSub}_INS_GAL_TOBE`
    ]
    await categoryService
      .getCategoryCodeList(codes)
      .then(async (res) => {
        // start ######################################## INSPIRATION LIST
        const dictionaryCondition = {
          operator: 'AND',
          list: [{ operator: 'OR', values: [{ id: 'product_cate', value: this.getPcdTypeName(this.pdCategoryType) }] }]
        }
        const { data } = await InspirationService.getContentListTap([res.data.list[0].id], dictionaryCondition, '', 'search_text', 1, 8)
        data.list.forEach((listItem, listIndex) => {
          Object.keys(listItem.attribute).forEach((item, index) => {
            const exceptList = ['applicationList', 'productList', 'subThumbnailList']
            if (exceptList.includes(item)) {
              data.list[listIndex].attribute[item] = JSON.parse(listItem.attribute[item])
            }
          })
        })
        _this.pcdInspirationList = data.list
      })
    /* ==================================================== end [ PCD 컨텐츠 ] ======================================================== */

    this.$nextTick(function () {
      $('#tab01').addClass('on')

      $('#tab001').addClass('on')
      // RELATED PRODUCTS
      t.relatedsliderLength = $('.prod-content.related .related-content').length
      if (t.relatedsliderLength > 4) {
        $('.prod-content.related').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: false,
          arrow: true,
          prevArrow: $('.prod-wrap.related .arrow-prev'),
          nextArrow: $('.prod-wrap.related .arrow-next'),
          responsive: [
            {
              breakpoint: 768,
              settings: {
                rows: 2,
                slidesToShow: 2,
                slidesToScroll: 2,
                slidesPerRow: 1,
                infinite: true,
                variableWidth: true,
                arrows: true,
                draggable: true,
                swipe: true,
                focusOnSelect: true,
                dots: true
              }
            }
          ]
        })
      } else {
        $('.prod-content.related').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: false,
          arrow: true,
          prevArrow: $('.prod-wrap.related .arrow-prev'),
          nextArrow: $('.prod-wrap.related .arrow-next'),
          responsive: [
            {
              breakpoint: 768,
              settings: {
                rows: 2,
                slidesToShow: 2,
                slidesToScroll: 2,
                slidesPerRow: 1,
                // infinite: true,
                variableWidth: true,
                arrows: true,
                // draggable: true,
                // swipe: true,
                focusOnSelect: true
              }
            }
          ]
        })
      }

      // certi 팝업 바깥 클릭시 팝업 닫힘
      $('.modal-certification').on('click', function (e) {
        if ($('.modal-certification > .popup').has(e.target).length === 0) {
          $('.modal-certification').css({ display: 'none' })
          $('body').removeClass('scrolllock')
        }
      })
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.prodImgSlider)
  },
  methods: {
    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    },
    checkCertificationDescription (key) {
      // 배열 안에 특정 값을 가진 객체가 있는지 확인
      return this.pcdCertificationData.some(item => !_.isEmpty(item[key]))
    },
    // brief html 태그 제외시키기
    stripTags (html) {
      if (html === undefined || html === null) {
        return ''
      }
      return String(html).replace(/<[^>]*>/g, '')
    },
    gotolink (url) {
      location.href = url
    },
    makeVizuonSeoMain (data) {
      let mainVizuonColNm = ''

      if (data.includes('VIZUON_VT')) {
        mainVizuonColNm = 'vehicles-and-transportation'
      } else if (data.includes('VIZUON_SD')) {
        mainVizuonColNm = 'signs-and-displays'
      } else if (data.includes('VIZUON_IS')) {
        mainVizuonColNm = 'illuminated-sign'
      } else if (data.includes('VIZUON_AR')) {
        mainVizuonColNm = 'architecture'
      } else if (data.includes('VIZUON_CB')) {
        mainVizuonColNm = 'corporate-branding'
      } else if (data.includes('VIZUON_ES')) {
        mainVizuonColNm = 'eco-solutions'
      }

      return mainVizuonColNm
    },
    /* 링크 추가 */
    getlink () {
      let url
      if (this.pdCategoryType === 'VIATERA') {
        url = 'viatera-quartz-surface/viatera-finder/'
      } else if (this.pdCategoryType === 'HIMACS') {
        url = 'himacs-solid-surface/himacs-finder/'
      } else if (this.pdCategoryType === 'HFLOR') {
        url = 'hflor/hflor-finder/all_colors'
      } else if (this.pdCategoryType === 'TERACANTO') { // TERACANTO
        url = 'teracanto-porcelain-surface/teracanto-finder/'
      } else if (this.pdCategoryType === 'BENIF') { // BENIF
        url = 'benif-interior-film/benif-finder/'
      } else if (this.pdCategoryType === 'DECO') { // DECO
        url = 'deco-foil/deco-finder/'
      } else if (this.pdCategoryType === 'EXTERIOR') { // EXTERIOR
        url = 'exterior-foil/exterior-finder/'
      } else if (this.pdCategoryType === 'VIZUON') { // VIZUON
        url = 'vizuon-sign-graphic/vizuon-finder/vehicles-and-transportation'
      } else if (this.pdCategoryType === 'BORTE') { // BORTE
        url = 'borte-premium-panel/borte-finder/'
      }
      return url
    },
    getSublink () {
      let url
      if (this.pdCategoryType === 'HFLOR') {
        url = 'hflor/hflor-finder/' +
          this.makeHflorSeoMainClt(this.categoryCode)
      } else if (this.pdCategoryType === 'VIZUON') {
        url = 'vizuon-sign-graphic/vizuon-finder/' +
          this.makeVizuonSeoMain(this.categoryCode)
      }

      return url
    },
    // inspiration detail 이동
    goInsDetail: function (clickId) {
      location.href =
        'inspiration/case-studies-detail/' + this.$utils.getSeoName(clickId, 'content') + '/' + clickId.content.id
    },
    showImgDownload: function () {
      const sliderIdx = $('.slider-prod-nav .slick-current').attr('data-slick-index')

      return !_.isUndefined(sliderIdx) && this.imageInfo.imgPage[sliderIdx].youtube !== true
    },
    toDataURL: function (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        const reader = new FileReader()
        reader.onloadend = function () {
          if (callback) callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    },
    // 배너 이미지 다운로드
    imgDownload: function () {
      const sliderIdx = $('.slider-prod-nav .slick-current').attr('data-slick-index')

      const imgDownPath = this.imageInfo.imgPage[sliderIdx].image
      const fileName = imgDownPath.substring(imgDownPath.lastIndexOf('/') + 1)
      const url = this.$imagePath + imgDownPath
      this.toDataURL(url, function (dataUrl) {
        const element = document.createElement('a')
        element.setAttribute('href', dataUrl)
        element.setAttribute('download', fileName)
        document.body.appendChild(element)
        element.click()
      })
    },
    download: function (data) {
      const url = this.$imagePath + data.attribute.doc_file

      try {
        this.toDataURL(url, (dataUrl) => {
          // Open the downloaded file in a new tab
          window.open(url, '_blank')
        })
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * sample order popup 열기
     */
    openSampleOrder (id) {
      this.selConId = id
      this.$root.$refs.sampleOrderView.openPopup(id)
      $('body').addClass('scrolllock')
    },
    /**
     * sample order popup 닫기
     */
    closeSampleOrder () {
      this.selConId = 0
      this.$root.$refs.sampleOrderView.closePopup()
      $('body').removeClass('scrolllock')
    },

    // CERTIFICATION 팝업 열기
    openModalCerti () {
      LayerPopup.open('modal-certification')
      $('body').addClass('scrolllock')
    },

    // CERTIFICATION 팝업 닫기
    closeModalCerti () {
      $('.modal-certification').css('display', 'none')
      $('body').removeClass('scrolllock')
      LayerPopup.close()
    },

    enlargeLayer (open) {
      const LayerPopup = {
        open: function (obj) {
          const layerObj = $('.' + obj)

          layerObj.fadeIn(500)
          layerObj.attr('tabindex', 1)
          layerObj.css('z-index', '10000')
          $('div.layer-popup.layer-enlarge').css('visibility', 'unset')
        },
        close: function () {
          $('div.layer-popup.layer-enlarge').removeAttr('tabindex')
          $('div.layer-popup.layer-enlarge').css('visibility', 'hidden')
        }
      }

      if (open === 'open') {
        LayerPopup.open('layer-enlarge')
        this.showDragon = true
      } else {
        LayerPopup.close()
        this.showDragon = false
      }
    },

    aBigLookLayer (open, fileName, imagePath, index) {
      const t = this
      const LayerPopup = {
        open: function (obj) {
          const layerObj = $('.' + obj)
          const _URL = imagePath

          layerObj.fadeIn(500)
          layerObj.attr('tabindex', 1)
          layerObj.css('z-index', '10000')
          $('div.layer-view.layer-pop.popup-w1440.modal-big-look').css('visibility', 'unset')
          // 모든 이미지를 슬라이더에 추가
          $('.popup_slider').empty()

          t.imageInfo.imgPage.forEach((img, idx) => {
            if (img && (img.detail || img.image)) { // 유효한 이미지인지 확인
              if (img.youtube) {
                $('.popup_slider').append(`
                  <div class="big_look_video">
                    <iframe autoplay="autoplay" muted="muted" controls="controls" allowscriptaccess="always" src="${img.image}" alt="youtube" class="yt_player_iframe"></iframe>
                  </div>
                `)
              } else {
                $('.popup_slider').append(`
                  <div class="big_look_img">
                    <img src="${_URL + (img.detail || img.image)}" alt="bigimg ${idx}">
                  </div>
                `)
              }
            }
          })

          // Slick 슬라이더 초기화 (이미 초기화되어 있다면 새로고침)
          if ($('.popup_slider').hasClass('slick-initialized')) {
            $('.popup_slider').slick('unslick')
          }
          $('.popup_slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            draggable: true,
            adaptiveHeight: true,
            lazyLoad: 'ondemand'

          })

          // 클릭된 이미지에 해당하는 슬라이드로 이동
          $('.popup_slider').slick('slickGoTo', index, true)
          console.log('Total slides:', $('.popup_slider').slick('getSlick').slideCount)
          console.log('Moved to slide:', index)
        },
        close: function () {
          $('div.layer-view.layer-pop.popup-w1440.modal-big-look').removeAttr('tabindex')
          $('div.layer-view.layer-pop.popup-w1440.modal-big-look').css('visibility', 'hidden')
          // $('div.big_look_img img').attr('src', '')
          // Slick 슬라이더 제거
          if ($('.popup_slider').hasClass('slick-initialized')) {
            $('.popup_slider').slick('unslick')
          }
          $('.popup_slider').empty() // 모든 내용 제거
        }
      }

      if (open == 'open') {
        LayerPopup.open('modal-big-look')
        this.showDragon = true
      } else {
        LayerPopup.close()
        this.showDragon = false
      }
    },

    getPcdTypeName (type) {
      let resultString
      switch (type) {
        case 'DECO':
          resultString = 'Deco Foil'
          break
        case 'EXTERIOR':
          resultString = 'Exterior Foil'
          break
        default:
          resultString = type
          break
      }
      return resultString
    },

    getDocName (type) {
      let resultString
      switch (type) {
        case 'HFLOR':
          resultString = 'hflor'
          break
        case 'HIMACS':
          resultString = 'himacs'
          break
        case 'VIATERA':
          resultString = 'viatera'
          break
        case 'TERACANTO':
          resultString = 'teracanto'
          break
        case 'BENIF':
          resultString = 'film_int'
          break
        case 'VIZUON':
          resultString = 'vizuon'
          break
        case 'DECO':
          resultString = 'film_deco'
          break
        case 'EXTERIOR':
          resultString = 'film_ext'
          break
      }
      return resultString
    },

    // 구글 애널리틱스 이벤트
    makeDataLayerForm (data) {
      const { ecom_prd_brand, ecom_prd_category } = getEcomPrdData(data)
      digitalData.productInfo = {
        ecom_prd_id: data.attribute.code === '' ? data.content.id : data.attribute.code,
        ecom_prd_name: data.content.title,
        ecom_prd_brand,
        ecom_prd_category, // HFLOR - Luxury, homo, hete, sports..
        ecom_prd_variant: '', // price over 1 items
        ecom_prd_type: '', // HFLOR only over 1 items
        ecom_prd_collection: data.parents[0].name,
        ecom_prd_color: data.attribute.hueText,
        ecom_prd_price: '', // price over 1 items
        ecom_currency_code: 'USD'
      }
      return JSON.stringify(digitalData.productInfo)
    },

    makeHflorSeoMainClt (data) {
      let main_collectionNm = ''

      if (data.includes('FLOOR_LOS')) {
        main_collectionNm = 'looselay'
      } else if (data.includes('FLOOR_LUX')) {
        main_collectionNm = 'lvt-flooring'
      } else if (data.includes('FLOOR_HOM')) {
        main_collectionNm = 'homogeneous-sheet'
      } else if (data.includes('FLOOR_HET')) {
        main_collectionNm = 'heterogeneous-sheet'
      } else if (data.includes('FLOOR_SPC')) {
        main_collectionNm = 'spc-click'
      } else if (data.includes('FLOOR_SPT')) {
        main_collectionNm = 'specialty'
      }

      return main_collectionNm
    },

    /* 화면 url 생성 */
    makeUrl (item) {
      let url
      if (this.pdCategoryType === 'VIATERA') {
        url = '/viatera-quartz-surface/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'HIMACS') {
        url = '/himacs-solid-surface/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'HFLOR') {
        url = '/hflor/' +
          this.makeHflorSeoMainClt(item.parents[0].code) +
          '/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'TERACANTO') { // TERACANTO
        url = '/teracanto-porcelain-surface/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'BENIF') { // BENIF
        url = '/benif-interior-film/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'DECO') { // DECO
        url = '/deco-foil/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'EXTERIOR') { // EXTERIOR
        url = '/exterior-foil/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'VIZUON') { // VIZUON
        url = '/vizuon-sign-graphic/' +
          this.makeVizuonSeoMain(item.content.modelId) +
          '/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      } else if (this.pdCategoryType === 'BORTE') { // BORTE
        url = '/borte-premium-panel/' +
          this.$utils.getSeoName(item, 'content') +
          '/' +
          item.content.id
      }
      return url
    },

    // sample order 재고 확인
    canOrder (item) {
      if (!item || !item.attribute) return false
      const price = item.attribute.price
      if (!_.isArray(price)) return false

      const filtered = price.filter(obj => _.toNumber(obj.price) > 0 && (_.isUndefined(obj.use) || obj.use == true))
      return filtered.length > 0
    },

    prodImgSlider: debounce(function () {
      const slidesToShow = $('.slider-prod .slider').length
      const navWideClass = slidesToShow > 6 ? 'navWide8' : 'navWide' + slidesToShow
      $('.slider-prod-nav').addClass(navWideClass)

      $('.slider-prod').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        draggable: false,
        asNavFor: '.slider-prod-nav'
      })

      if (slidesToShow > 6) {
        $('.slider-prod-nav').not('.slick-initialized').slick({
          slide: 'div',
          asNavFor: '.slider-prod',
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          variableWidth: true,
          arrows: true,
          draggable: false,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1020,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                draggable: true
              }
            }
          ]
        })
      } else {
        $('.slider-prod-nav').not('.slick-initialized').slick({
          slide: 'div',
          asNavFor: '.slider-prod',
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          infinite: false,
          centerMode: false,
          variableWidth: true,
          arrows: false,
          draggable: false,
          focusOnSelect: false
        })
      }

      // 비주얼 썸네일 슬라이드 클릭시 보더 표시
      $('.slider-prod-nav div.slick-slide').off('click').on('click', function () {
        $('.slider-prod-nav div.slick-slide').removeClass('slick-current')
        $(this).addClass('slick-current')

        const sliderIdx = $('.slider-prod-nav .slick-current').attr('data-slick-index')

        $('.slider-prod').slick('slickGoTo', sliderIdx)
      })

      $('.slider-prod').off('beforeChange').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.yt_player_iframe').each(function () {
          this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        })
      })

      $(window).off('resize').on('resize', this.prodImgSlider)
    }, '400ms')
  }
}

function tmpPopup (popClass) {
  LayerPopup.open(popClass)
}

function tmpClose () {
  LayerPopup.close()
}

/* 관련컨텐츠 NEW 기준으로 정렬 */
function getAllRelatedContent (data) {
  if (!data.related) {
    return []
  }

  const relatedKeys = Object.keys(data.related)

  if (relatedKeys.length === 0) {
    return []
  }

  // 모든 related 콘텐츠를 하나의 배열로 합칩니다
  let allRelatedContent = relatedKeys.reduce((acc, key) => {
    if (Array.isArray(data.related[key])) {
      return acc.concat(data.related[key])
    } else {
      return acc
    }
  }, [])

  // isNew가 true인 항목과 나머지 항목을 분리합니다
  const newItems = allRelatedContent.filter(item =>
    item.attribute && 'isNew' in item.attribute && item.attribute.isNew === 'true'
  )
  const otherItems = allRelatedContent.filter(item =>
    !(item.attribute && 'isNew' in item.attribute && item.attribute.isNew === 'true')
  )
  // 두 배열을 합칩니다
  allRelatedContent = [...newItems, ...otherItems]

  return allRelatedContent
}
</script>
