import request from './request'

const urlPrefix = ''
const GoogleAdsService = {
  getContentList (categoryCode) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryCodes: [categoryCode]
      }
    })
  },
}

export default GoogleAdsService
