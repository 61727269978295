<template>
  <div class="product-component-area">
    <div class="image-area">
      <img :src="info.imageSrc">
      <div class="text">
        <span>{{ info.title }}</span>
      </div>
    </div>

    <div class="divider"></div>

    <div class="description-area">
      <span
        v-for="(it, idx) in info.description"
        :key="idx"
      >
        {{ it }}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.product-component-area {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.product-component-area .image-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
}
@media (min-width: 769px) {
  .product-component-area .image-area {
    width: unset;
  }
}


.product-component-area .image-area img {
  object-fit: cover;
  width: 100px;
}
@media (min-width: 769px) {
  .product-component-area .image-area img {
    object-fit: cover;
    width: 200px;
  }
}


.product-component-area .image-area .text {
  margin-top: 17px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}

.product-component-area .image-area text span {
  color: #2C2C2D;
  text-align: center;
  font-family: 'NotoSans' , sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-component-area .divider {
  width: 1px;
  background-color: #A8A9AD;
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 769px) {
  .product-component-area .divider {
    width: 1px;
    background-color: #A8A9AD;
    margin-left: 20px;
    margin-right: 30px;
  }
}

.product-component-area .description-area {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.product-component-area .description-area span {
  color: #666;
  font-family: 'NotoSans' , sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
}
@media (min-width: 769px) {
  .product-component-area .description-area span {
    font-size: 20px;
    line-height: 28px;
  }
}

</style>
