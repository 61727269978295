var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lca" },
    [
      _c("SocialResponsibilityBorderTitle", { attrs: { title: _vm.title } }),
      _c("div", { staticClass: "lca-content" }, [
        _c(
          "div",
          { staticClass: "lca-text-card-area" },
          _vm._l(_vm.textCard, function (item, index) {
            return _c("div", { key: index, staticClass: "lca-text-card" }, [
              _c("pre", { staticClass: "number" }, [
                _vm._v(_vm._s(item.number)),
              ]),
              _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(item.text))]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }