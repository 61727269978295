<template>
  <ProductLayout>
    <template #content>
      <!-- 상단 이미지 -->
      <ProductTopImage
        title="Home Appliance Surface Film"
        description="Film laminated steel surface finishing material of home appliances, UBR and Elevator."
        imageSrc="images/industrialFilm/VinylCoatedMaterial/top.png"
      />

      <!-- 제품 상세 영역 -->
      <ProductContent>
        <template>
          <!-- 소개문 영역 -->
          <ProductIntro
            class="mt-100"
            imageSrc="images/industrialFilm/VinylCoatedMaterial/intro.png"
          >
            <template #text>
              <span>
                LX Hausys Home Appliance Surface Film is a high quality surface material with a variety of colors and patterns for refrigerators, washing machines and bathrooms.
              </span>
              <br /><br />
              <span>
                Our superb products are already chosen by global appliance companies, because of its excellent anti-pollution, anti-scratch and anti-corrosion characteristics.
              </span>
            </template>
          </ProductIntro>

          <!-- 장점 영역 -->
          <StrongPointGroup class="mt-100" />

          <!-- 예시 이미지 -->
          <div class="mt-100">
            <img src="images/industrialFilm/VinylCoatedMaterial/example-center.jpg" alt="example">
          </div>

          <!-- Construction 영역 -->
          <ConstructionArea class="mt-100" />

        </template>
      </ProductContent>

      <!-- Application area -->
      <ApplicationArea class="mt-100"/>
    </template>
  </ProductLayout>
</template>

<script>
import ProductLayout from '@/components/Layout/ProductLayout'
import ProductTopImage from '@/components/product/ProductTopImage'
import ProductContent from '@/components/product/ProductContent'
import ProductIntro from '@/components/product/ProductIntro'
import StrongPointGroup from './components/StrongPointGroup'
import ConstructionArea from './components/Construction/ConstructionArea'
import ApplicationArea from './components/Application'

export default {
  components: {
    ProductLayout,
    ProductTopImage,
    ProductContent,
    ProductIntro,
    StrongPointGroup,
    ConstructionArea,
    ApplicationArea
  }
}

</script>
