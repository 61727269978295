import request from './request'

const urlPrefix = ''
const InspirationService = {
  getCollectionList (code) {
    return request({
      url: `${urlPrefix}/inspiration/collection?code=${code}`,
      method: 'get'
    })
  },
  AlertSave (user) {
    return request({
      url: `${urlPrefix}/alert/save`,
      method: 'post',
      data: user
    })
  },
  mediaSave (user) {
    return request({
      url: `${urlPrefix}/media/save`,
      method: 'post',
      data: user
    })
  },
  HDImageOrderSave (user) {
    return request({
      url: `${urlPrefix}/hdimage/save`,
      method: 'post',
      data: user
    })
  },
  getSegmentsList () {
    return request({
      url: `${urlPrefix}/inspiration/segments`,
      method: 'get',
      containChildCategory: true
    })
  },
  getCaseSegmentsList () {
    return request({
      url: `${urlPrefix}/inspiration/caseSegments`,
      method: 'get',
      containChildCategory: true
    })
  },
  getEuSegmentsList () {
    return request({
      url: `${urlPrefix}/inspiration/euSegments`,
      method: 'get',
      containChildCategory: true
    })
  },
  getEuNewsSegmentsList () {
    return request({
      url: `${urlPrefix}/inspiration/euNewsSegments`,
      method: 'get',
      containChildCategory: true
    })
  },
  getSpaceList (cateId) {
    return request({
      url: `${urlPrefix}/inspiration/space?cateId=${cateId}`,
      method: 'get'
    })
  },

  getDictionary (code) {
    return request({
      url: `${urlPrefix}/inspiration/dict?code=${code}`,
      method: 'get'
    })
  },

  getDictionaryList (code) {
    return request({
      url: `${urlPrefix}/product/dictList?code=${code}`,
      method: 'get'
    })
  },

  getInspirationList (categoryId, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/inspiration/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true
      }
    })
  },
  getInspirationDetail (id) {
    return request({
      url: `${urlPrefix}/inspiration/detail?id=${id}`,
      method: 'get'
    })
  },
  getInspirationDetailBySlugAndCategory (cateCode, slug) {
    return request({
      url: `${urlPrefix}/inspiration/detail?cateCode=${cateCode}&slug=${slug}`,
      method: 'get'
    })
  },
  getContentListChild (categoryId) {
    return request({
      url: `${urlPrefix}/inspiration/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true
      }
    })
  },
  getCategoryCode (code) {
    return request({
      url: `${urlPrefix}/category/code/${code}`,
      method: 'get'
    })
  },
  getCategoryId (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}`,
      method: 'get'
    })
  },
  getContentDetail (id) {
    return request({
      url: `${urlPrefix}/product/detail?id=${id}`,
      method: 'get'
    })
  },
  getContentByIds (ids) {
    return request({
      url: `${urlPrefix}/product/details`,
      method: 'post',
      data: ids
    })
  },
  getContentListSearch (categoryId, text, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/inspiration/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        containChildCategory: true,
        text: text
      }
    })
  },
  getContentListTap (categoryId, dictionaryCondition, text, fields, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/inspiration/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryCondition: dictionaryCondition,
        text: text,
        fields: [fields],
        containChildCategory: true,
        orderField: ['sort', 'createdAt'],
        orderType: ['asc', 'desc']
      }
    })
  },
  getDocumentList (categoryId, dictionaryValues) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: categoryId,
        dictionaryValues: dictionaryValues,
        containChildCategory: true
      }
    })
  },
  getCategoryListAttr (id) {
    return request({
      url: `${urlPrefix}/category/id/${id}?containsAttribute=true`,
      method: 'get'
    })
  },
  getContentListSegments (categoryId, text, pageIndex, pageSize, dictionaryValues) {
    return request({
      url: `${urlPrefix}/inspiration/search?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: 'post',
      data: {
        categoryIds: [categoryId],
        containChildCategory: true,
        dictionaryValues: [dictionaryValues],
        text: text
      }
    })
  },
  getCateChildren (code) {
    return request({
      url: `${urlPrefix}/product/cateChildren?code=${code}`,
      method: 'get'
    })
  },
  getContentList (categoryId) {
    return request({
      url: `${urlPrefix}/product/search`,
      method: 'post',
      data: {
        categoryIds: [categoryId]
      }
    })
  },
  getContentFileList (contentId) {
    return request({
      url: `${urlPrefix}/directive/contentFileList?contentId=${contentId}`,
      method: 'get'
    })
  },
  getTopImageSlideList (categoryId) {
    return request({
      url: `${urlPrefix}/inspiration/topImageSlideList`,
      method: 'post',
      data: {
        text: '"featured" : "true"',
        categoryId: categoryId
      }
    })
  }
}
export default InspirationService
