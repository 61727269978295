<template>
  <OverviewSection
    type="major"
    title="Key Social Contribution Initiatives"
    color="#222"
    size="56px"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <SustainabilityText
      :text="text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="scroll-box">
        <KeySocialContributionInitiativesTable
        :headers="table.headers"
        :bodies="table.bodies"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <KeySocialContributionInitiativesImageCard
      :images="imageCard.images"
      :text="imageCard.text"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    type="major"
    title="主な社会貢献活動"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <SustainabilityText
        :text="text_jp"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="scroll-box">
        <KeySocialContributionInitiativesTable
          :headers="table_jp.headers"
          :bodies="table_jp.bodies"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <KeySocialContributionInitiativesImageCard
        :images="imageCard_jp.images"
        :text="imageCard_jp.text"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    type="major"
    title="Ключевые инициативы по социальному вкладу"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <SustainabilityText
        :text="text_ru"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="scroll-box">
        <KeySocialContributionInitiativesTable
          :headers="table_ru.headers"
          :bodies="table_ru.bodies"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <KeySocialContributionInitiativesImageCard
        :images="imageCard_ru.images"
        :text="imageCard_ru.text"
      />
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    type="major"
    title="关键社会贡献倡议"
    color="#222"
    size="56px"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem isUnderSectionItem>
      <SustainabilityText
        :text="text_cn"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="scroll-box">
        <KeySocialContributionInitiativesTable
          :headers="table_cn.headers"
          :bodies="table_cn.bodies"
        />
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem isUnderSectionItem>
      <KeySocialContributionInitiativesImageCard
        :images="imageCard_cn.images"
        :text="imageCard_cn.text"
      />
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../components/SustainabilityText.vue'
import KeySocialContributionInitiativesImageCard from './KeySocialContributionInitiativesImageCard.vue'
import KeySocialContributionInitiativesTable from './KeySocialContributionInitiativesTable.vue'
export default {
  components: {
    SustainabilityText,
    KeySocialContributionInitiativesTable,
    KeySocialContributionInitiativesImageCard,
    OverviewSection,
    OverviewSectionItem
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      text: 'LX Hausys engages in diverse social contribution activities such as cleaning the neighborhoods around our manufacturing\n' +
            'plants in Korea and overseas, assisting with the maintenance of facilities in such areas,\n' +
            'lending a strong helping hand with the recovery efforts in flood damaged areas, and contributing to the development of local communities',
      table: {
        headers: [
          {
            text: 'Workplace',
            background: '#F6F6F6'
          },
          {
            text: 'Initiative'
          },
          {
            text: 'Description'
          }
        ],
        bodies: [
          {
            category: {
              text: 'Ulsan plant'
            },
            bodyCells: [
              {
                text: 'One Company, One Village',
                descText: 'Supported the events held in villages near the plant and purchased agricultural produce from them from these villages.'
              },
              {
                text: 'One Company, One Stream',
                descText: 'Inspected the water quality of the Hoeya River near the plant and conducted clean-ups along the river.'
              },
              {
                text: 'One Company, One Mountain',
                descText: 'Cleaned up Daeum Mountain with Ulsan City and Ulju-gun.'
              },
              {
                text: 'Happy Day',
                descText: 'Donated kimchi to local children`s centers and elderly persons living alone.'
              }
            ]
          },
          {
            category: {
              text: 'Cheongju plant'
            },
            bodyCells: [
              {
                text: 'One Company, One Millitary Unit1',
                descText: 'Formed a partnership with the 37th Infantry Division of the Rok Army, assisted effort to improve the military\n' +
                          'camp environment, donated appreciation gifts and funds.'
              },
              {
                text: 'One Company, One Village',
                descText: 'Supported the events of neighboring villages.'
              },
              {
                text: 'Support for children from low-income families',
                descText: 'Supported the ninimum living expenses of children form low-income families in conjunction with Child Fund Korea.'
              },
              {
                text: 'Happy Coal Briquette Delivery',
                descText: 'Delivered briquettes to senior citizens living alone in conjunction Chungbuk Biquette Bank.'
              }
            ]
          }
        ]
      },
      imageCard: {
        text: '\'LX happy Day\', annual noluteer activities and charity events',
        images: [
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card1.png',
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card2.png'
        ]
      },
      text_jp: 'LX Hausysは、韓国および海外の製造工場周辺の清掃、当該地域の施設の維持管理支援、\n'+
      '洪水被害地域の復旧活動への強力な支援、地域社会の発展への貢献など、さまざまな社会\n'+
      '貢献活動に取り組んでいます。',
      table_jp: {
        headers: [
          {
            text: '職場',
            background: '#F6F6F6'
          },
          {
            text: '主導権'
          },
          {
            text: '説明'
          }
        ],
        bodies: [
          {
            category: {
              text: '蔚山工場'
            },
            bodyCells: [
              {
                text: 'One Company, One Village',
                descText: '工場近隣の村で開催されるイベントに協賛し、村から農産物を購入しました。'
              },
              {
                text: 'One Company, One Stream',
                descText: '工場付近の Hoeya川の水質検査と川沿いの清掃活動を実施しました。'
              },
              {
                text: 'One Company, One Mountain',
                descText: '蔚山市、蔚州郡と協力してDaeum Mountainの清掃活動を行いました。'
              },
              {
                text: 'Happy Day',
                descText: '地域の児童館や一人暮らしの高齢者にキムチを寄付しました。'
              }
            ]
          },
          {
            category: {
              text: '清州工場'
            },
            bodyCells: [
              {
                text: 'One Company, One Millitary Unit1',
                descText: '韓国陸軍第37歩兵師団と協力関係を結び、駐屯地環境の改善に協力し、感謝の贈り物や資金を寄付した。'
              },
              {
                text: 'One Company, One Village',
                descText: '近隣村のイベントを支援しました。'
              },
              {
                text: 'Support for children from low-income families',
                descText: '韓国児童基金と連携し、低所得世帯の子どもたちの最低生活費を支援しました。'
              },
              {
                text: 'Happy Coal Briquette Delivery',
                descText: 'Chungbuk Biquette Bankと連携し、一人暮らしの高齢者に練炭を配達しました。'
              }
            ]
          }
        ]
      },
      imageCard_jp: {
        text: '「LXハッピーデー」, 毎年恒例のノルテア活動とチャリティーイベント',
        images: [
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card1.png',
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card2.png'
        ]
      },
      text_ru: 'LX Hausys участвует в различных социальных мероприятиях, таких как уборка территорий \n'+
      'вокруг наших заводов в Корее и за рубежом, помощь в восстановлении пострадавших от наводнения \n'+
      'районов. Компания вносит свой вклад в развитие местных сообществ.	',
      table_ru: {
        headers: [
          {
            text: 'Рабочее место',
            background: '#F6F6F6'
          },
          {
            text: 'Инициатива'
          },
          {
            text: 'Описание'
          }
        ],
        bodies: [
          {
            category: {
              text: 'Завод в Ульсане'
            },
            bodyCells: [
              {
                text: 'Одна компания, одна деревня',
                descText: 'Мы поддерживали мероприятия, проводимые в деревнях, расположенных рядом с заводом и закупали у них сельскохозяйственную продукцию из этих деревень.'
              },
              {
                text: 'Одна компания, один поток',
                descText: 'Проверяли качество воды в реке Хой вблизи завода и проводили уборку вдоль реки.'
              },
              {
                text: 'Одна компания, одна гора',
                descText: 'Очистили гору вместе с городом Ульсан и Ульчжу-гуном.'
              },
              {
                text: 'День счастья',
                descText: 'Пожертвовали кимчи местным детским центрам и одиноким пожилым людям.'
              }
            ]
          },
          {
            category: {
              text: 'Завод в Чхонджу'
            },
            bodyCells: [
              {
                text: 'Одна компания, одна воинская часть',
                descText: 'Установили партнерские отношения с 37-й пехотной дивизией армии Рок, оказали содействие в улучшении обстановки в военном лагере, передали благодарственные подарки и средства.'
              },
              {
                text: 'Одна компания, одна деревня',
                descText: 'Поддерживали мероприятия соседних деревень.'
              },
              {
                text: 'Поддержка детей из малообеспеченных семей',
                descText: 'Совместно с корейским фондом Child Fund оплачивали расходы на проживание детей из малообеспеченных семей.'
              },
              {
                text: 'Доставка угольного брикета',
                descText: 'Доставка брикетов одиноким пожилым людям совместно с банком Чунгбука.'
              }
            ]
          }
        ]
      },
      imageCard_ru: {
        text: '"День счастья LX", ежегодные мероприятия и благотворительные акции',
        images: [
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card1.png',
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card2.png'
        ]
      },
      text_cn: 'LX Hausys从事各种社会贡献活动，如协助我司国内外的制造工厂周围社区，\n'+
      '维护地区设施，并为洪水灾区的恢复工作提供有效帮助。',
      table_cn: {
        headers: [
          {
            text: '工厂',
            background: '#F6F6F6'
          },
          {
            text: '倡议'
          },
          {
            text: '内容'
          }
        ],
        bodies: [
          {
            category: {
              text: '蔚山工厂'
            },
            bodyCells: [
              {
                text: '一个公司，一座村庄',
                descText: '通过购买农产品等方式，对周边村庄提供支援。'
              },
              {
                text: '一个公司，一条溪流',
                descText: '对附近的回夜江进行环保清洁工作，并实施水质检测。'
              },
              {
                text: '一个公司，一座山',
                descText: '携手蔚山市和蔚州郡共同清理大云山。'
              },
              {
                text: 'Happy Day',
                descText: '向当地儿童中心和独居老人捐赠泡菜。'
              }
            ]
          },
          {
            category: {
              text: '清州工厂'
            },
            bodyCells: [
              {
                text: '一个公司，一个军事单元',
                descText: '与韩国陆军第37步兵师建立伙伴关系，协助改善军营环境，捐赠感谢礼物和资金。'
              },
              {
                text: '一个公司，一座村庄',
                descText: '支持邻近村庄的活动。'
              },
              {
                text: '对低收入家庭儿童的支持',
                descText: '与韩国儿童基金会合作，支持低收入家庭儿童的最低生活费用。'
              },
              {
                text: '快乐送碳',
                descText: '与Chungbuk Biquette Bank合作，为独居老人送煤球。'
              }
            ]
          }
        ]
      },
      imageCard_cn: {
        text: "'LX happy Day', 年度无休活动和慈善活动",
        images: [
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card1.png',
          'images/aboutus/sustainability/social_responsibility/key-social-contribution-initiatives-image-card2.png'
        ]
      }
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style>
@media (max-width: 768px) {
  .scroll-box {
    width: 100%;
    overflow: scroll;
  }
}
</style>
