var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "seat-corver-area" }, [
    _c("div", { staticClass: "top-area" }, [
      _c("div", { staticClass: "image" }, [
        _c("img", { attrs: { src: _vm.imageSrc } }),
      ]),
      _c("div", { staticClass: "text" }, [_vm._t("text")], 2),
    ]),
    _vm.existAnnotaion ? _c("div", { staticClass: "divider" }) : _vm._e(),
    _c("div", { staticClass: "annotation-area" }, [_vm._t("annotation")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }