var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "eco-friendly-products-contents" }, [
    _c("div", { staticClass: "product-picture-area" }, [
      _c("img", {
        staticClass: "product-picture",
        attrs: { src: _vm.picture },
      }),
    ]),
    _c("div", { staticClass: "text-area" }, [
      _c("img", { staticClass: "tab-icon", attrs: { src: _vm.icon } }),
      _c("pre", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }