var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("HFLOOR", {
        attrs: { category: _vm.category },
        on: { "category-changed": _vm.handleCategoryChanged },
      }),
      _c("COLLECTION", {
        attrs: { collectionList: _vm.collectionList, category: _vm.category },
        model: {
          value: _vm.collection,
          callback: function ($$v) {
            _vm.collection = $$v
          },
          expression: "collection",
        },
      }),
      _c("COLOR", {
        attrs: { colorList: _vm.dictMap["hue"] },
        model: {
          value: _vm.hue,
          callback: function ($$v) {
            _vm.hue = $$v
          },
          expression: "hue",
        },
      }),
      _c("FORMAT", {
        attrs: { formatList: _vm.dictMap["format"] },
        model: {
          value: _vm.format,
          callback: function ($$v) {
            _vm.format = $$v
          },
          expression: "format",
        },
      }),
      _c("PRD_PATTERN", {
        attrs: { patternList: _vm.dictMap["pattern"] },
        model: {
          value: _vm.pattern,
          callback: function ($$v) {
            _vm.pattern = $$v
          },
          expression: "pattern",
        },
      }),
      _c("THICKNESS", {
        attrs: { thickList: _vm.dictMap["thickness"] },
        model: {
          value: _vm.thickness,
          callback: function ($$v) {
            _vm.thickness = $$v
          },
          expression: "thickness",
        },
      }),
      _c("DIMENSIONS", {
        attrs: { dimList: _vm.dictMap["dimension"] },
        model: {
          value: _vm.dimension,
          callback: function ($$v) {
            _vm.dimension = $$v
          },
          expression: "dimension",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }