<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content prod-dt-cate">
      <!-- location -->
      <div class="location type02">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("other.text.home") }}</li>
          <li>{{ $t("other.text.products") }}</li>
          <li>{{ $t("other.text.viatera") }}</li>
          <li>{{ $t("other.text.votherPD") }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (US Viatera Other Products) -->
      <div class="container prod-dt-view prod-op-view viatera-op container_pf">
        <!-- visual -->
        <div class="visual visual-detail">
          <div class="visual-image full_width center" v-if="bannerList[0].attribute.pc_img !== undefined" v-bind:style="{ 'background-image' : 'url( ' + $imagePath + bannerList[0].attribute.pc_img + ')' }"> <!-- 요기 -->
            <div class="inner_width">
              <div class="txt-box">
                <h1 class="title">
                  <span class="txt">{{ bannerList[0].attribute.description_1 }}</span>
                </h1>
                <p v-html="subText" class="txt-02" id="text" style="color:white;"></p>
              </div>
            </div>
            <!-- <div class="bgdim"></div> -->
          </div>
        </div>
        <!--//visual -->

        <!-- visual slide -->
        <!--<div class="slider-prod">
          <div class="slider" v-for="(banner, index) in bannerList" v-bind:key="banner.attribute.org_content">
            <img :src="$imagePath+banner.attribute.pc_img" alt="images"/>
            <div class="txt-box">
              <p class="txt-01">{{ banner.attribute.description_1 }}</p>
              <div class="txt-01">
                <div v-html="subText" id="text" style="font-weight: 300; line-height: 30px; color: white;"></div>
              </div>
            </div>
          </div>
        </div>-->
        <!-- //visual slide -->

        <div class="inspr-view prod-fd-view w-con inner">
          <div></div>
          <div class="tab-ui">
            <div class="list-type-view row-04">
              <ul>
                <li v-for="(content, index) in contentList">
                  <div class="img-box">
                    <img :src="$imagePath+content.attribute.pc_thunb" alt="other product img" @click="popUpView(content)"/>
                    <!--<a class="ico-wish" v-bind:class="{ on: content.content.liked }" @click="toggleLike(content, 'Viatera')" v-if="authenticated">wish list</a>-->
                    <!--<a class="ico-wish" v-else href="login/" onclick="alert('로그인 후 이용해주세요.');">wish list</a>-->
                  </div>
                  <div class="info02">
                    <ul>
                      <li class="txt-02"><a href="javascript:void(0);">{{ content.content.title }}</a></li>
                      <!--<li class="txt-01">{{ content.content.title }}</li>-->
                      <!--<li class="txt-03">{{ content.content.title }}</li>-->
                      <!--<li class="txt-03">#{{ content.attribute.prod_grpText }}</li>-->
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- <div class="short-link type02 inner">
          <a @click="openCart()" v-if="nationCode === '_US'"><span class="ico-01">{{ $t('PCD.text.order_a_sample')}}</span></a>
          <a v-if="nationCode === '_GL'" :href="'support/contactus'"><span class="ico-07">{{ $t('PCD.text.contact_us')}}</span></a>
          <a href="pros/documentation/viatera"><span class="ico-02">{{ $t('PCD.text.documentation')}}</span></a>
          <a href="support/care-and-maintenance/viatera"><span class="ico-03">{{ $t('PCD.text.care_maintenance')}}</span></a>
          <a v-if="nationCode === '_US'" href="support/warranty/viatera"><span class="ico-04">{{ $t('PCD.text.warranty')}}</span></a>
          <a v-if="nationCode === '_US'" :href="'support/where-to-buy/country/us'"><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a>
          <a v-if="nationCode === '_GL'" :href="'support/where-to-buy/country/gl'"><span class="ico-05">{{ $t('PCD.text.where_to_buy')}}</span></a>
        </div> -->

        <!-- popup : Other Products -->
        <div class="layer-popup layer-other">
          <div class="popup pop-close">
            <div class="pop-other scr-y">
              <div class="inspr-view prod-fd-view prod-op-view">
                <div class="list-type-view col-02">
                  <div>
                    <div class="img-box tab-con other01 on">
                      <img v-if="contentAttribute.pc_thunb !== undefined" :src="$imagePath+ contentAttribute.pc_thunb" alt="images" style="width: 100%"/>
<!--                      <a class="ico-wish" v-bind:class="{ on: contentContent.liked }" @click="toggleLike(content, 'Viatera')" v-if="authenticated">wish list</a>-->
<!--                      <a class="ico-wish" v-else href="login/" onclick="alert('로그인 후 이용해주세요.');">wish list</a>-->
                    </div>
                  </div>
                  <div class="info">
                    <div class="wide-proDeteail-label">
                      <span class="pro">Kitchen</span>
                    </div>
                    <p class="tt-1">{{ contentContent.title }}</p>
                    <div class="tbl-box">
                      <table>
                        <caption>Product Specs</caption>
                        <colgroup>
                          <col style="width:35%">
                          <col style="width:auto">
                        </colgroup>
                        <tbody>
                        <tr v-if="contentAttribute.overall !== undefined">
                          <th scope="row">{{ $t("other.text.overall") }}</th>
                          <td>{{ contentAttribute.overall }}</td>
                        </tr>
                        <tr v-if="contentAttribute.inner !== undefined">
                          <th scope="row">{{ $t("other.text.inner") }}</th>
                          <td>{{ contentAttribute.inner }}</td>
                        </tr>
                        <tr v-if="contentAttribute.option !== undefined">
                          <th colspan="2">{{ contentAttribute.option }}</th>
                        </tr>
                        <tr v-if="contentAttribute.caution !== undefined">
                          <th colspan="2">{{ contentAttribute.caution }}</th>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="btns dwonarea">
                      <p class="ctt ctt-t2">Documentation</p>
                      <div>
                        <button v-if="contentAttribute.pdf" type="button" class="btn-down" @click="downloadFile(contentAttribute.pdf)"><span>pdf</span></button>
                        <button v-if="contentAttribute.dxf" type="button" class="btn-down" @click="downloadFile(contentAttribute.dxf)"><span>DXF</span></button>
                      </div>
                      <div>
                        <button type="button" class="btn btn-line" @click="contactUs()"><span>{{ $t("other.text.contactus") }}</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn-close" onclick="LayerPopup.close();">{{ $t("other.text.close") }}</button>
            </div>
          </div><!-- //popup -->
        </div>
        <!-- //popup : Other Products -->
      </div>
      <!-- //container (US Viatera Other Products) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <ShortComponent></ShortComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import ShortComponent from '@/components/Layout/BtmShort'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import categoryService from '@/server/category.service'
import ProductService from '@/server/product.service'
import { mapState } from 'vuex'

export default {
  name: 'OtherProducts',
  components: {
    HeaderComponent,
    QuickMenuComponent,
    ShortComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      nationCode: '',
      countryCodeSub: '',
      categoryName: '', /* 분류 코드 */
      bannerList: [{ attribute: '' }, { attribute: '' }], /* 배너 목록 */
      bannerAttribute: '',
      pc_img: '', /* 배너 이미지 */
      orgContent: '',
      contentList: [], /* other product 콘텐츠 리스트 */
      categoryList: [],
      contentContent: {},
      contentAttribute: {},
      content: {},
      popUpliked: '',
      popUpTitle: '',
      popUpCode: '',
      popUpOverall: '',
      popUpInner: '',
      popUpInner2: '',
      popUpDescription: '',
      popUpImage: '',
      popUpPDF: '',
      popUpDXF: '',
      url: ''
    }
  },
  computed: {
    subText () {
      if (this.bannerList[0] && this.bannerList[0].attribute && this.bannerList[0].attribute.description_2) {
        return this.bannerList[0].attribute.description_2.replace('\n', '<br/>')
      } else {
        return ''
      }
    },
    ...mapState({
      authenticated: state => state.auth.authenticated
    })
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    const _this = this
    const added = this.$countryCode
    _this.nationCode = added
    /* 배너리스트 */
    const categoryCode = 'BAN_VIATERA_OTHER' + added /* 배너 카테고리 코드 */
    const categoryRes = await categoryService.getCategoryCode(categoryCode) /* 배너 카테고리 */
    const categoryId = categoryRes.data.object.id /* 배너 카테고리 아이디 */
    const contentRes = await ProductService.getContentList(categoryId) /* 배너 콘텐츠 */
    _this.bannerList = contentRes.data.list /* 배너 콘텐츠 리스트 */
    _this.bannerAttribute = _this.bannerList[0].attribute.description_2 /* 배너 attribute */

    /* other product 리스트 */
    _this.categoryName = 'VIATERA_OTHER' + added /* otherProduct 카테고리 코트 */
    const otherCategory = await categoryService.getCategoryCode(_this.categoryName) /* otherProduct 카테고리 */
    const otherCateId = otherCategory.data.object.id /* otherProduct 카테고리 아이디 */
    let otherContentList = null

    otherContentList = await ProductService.getContentList(otherCateId) /* otherProduct 콘텐츠 */
    _this.contentList = otherContentList.data.list /* otherProduct 콘텐츠 리스트 */

    /* visual slide */
    _this.$nextTick(function () {
      $('.slider-prod').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      })

      /* visual slide > button pause */
      $('.slick-dots').append('<li class="btn-pause"><button type="button">pause</button></li>')
      $('.slick-dots').append('<li class="btn-play"><button type="button">pause</button></li>')

      $('.slick-dots .btn-play').hide()

      $('.btn-pause').click(function () {
        $('.slider-prod').slick('slickPause')
        $(this).hide()
        $('.slick-dots .btn-play').show()
      })

      $('.btn-play').click(function () {
        $('.slider-prod').slick('slickPlay')
        $(this).hide()
        $('.slick-dots .btn-pause').show()
      })
    })
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    async popUpView (content) { /* 콘텐츠 상세보기 팝업 */
      const _this = this
      _this.content = content
      _this.contentContent = content.content
      _this.contentAttribute = content.attribute
      _this.popUpliked = content.content.liked
      LayerPopup.open('layer-other')
      return false
    },

    async downloadFile (file) {
      const url = this.$imagePath + file
      try {
        const element = document.createElement('a')
        element.href = url
        element.setAttribute('target', '_blank')
        element.setAttribute('download', file)
        element.click()
      } catch (error) {
        console.log(error)
      }
    },
    contactUs () {
      location.href = 'support/contactus'
    },

    toggleLike (sr, cateType) {
      this.$utils.toggleLike(sr, 'PD', cateType, this)
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    }
  }
}
</script>
