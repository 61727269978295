<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content au-view newsEventArea">
      <!-- location -->
      <div class="location">
        <ul>
          <li
            class="ico-home"
            v-hover-cursor
            @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)"
          >
            {{ $t("newsEvent.text.home") }}
          </li>
          <li>{{ $t("newsEvent.text.about_us") }}</li>
          <li>
            {{ $t("newsEvent.text.news") }} & {{ $t("newsEvent.text.event") }}
          </li>
        </ul>
      </div>
      <!-- //location -->
      <!-- container (News & Event) -->
      <div class="container au-box bbs-box au-box02">
        <div class="sub_title_area full_width">
          <div class="inner_width">
            <h1 class="page_title tt-1">
              {{ $t("newsEvent.text.news") }} & {{ $t("newsEvent.text.event") }}
            </h1>
          </div>
        </div>

        <!-- New Events -->
        <!-- 지정된 게시물이 없을시 최신 3건 노출 -->
        <div class="newevent_slider_wrap full_width">
          <div class="inner_width">
            <div class="center_slider" v-if="sliderNews.length > 0">
              <div class="item" v-for="topic in sliderNews">
                <a
                  :href="'aboutus/newsNEvent/newsDetail/' + topic.content.id"
                  class="item_link"
                >
                  <div class="img">
                    <img
                      :src="$imagePath + topic.attribute.pcThumbnail"
                      class="new_events_img"
                      alt="LX Hausys"
                    />
                  </div>
                  <div class="contents">
                    <div class="txt_box">
                      <p class="sort ctt ctt-t4">
                        {{ topic.attribute.topicTypeText }}
                      </p>
                      <p class="title tt-30">{{ topic.content.title }}</p>
                      <p class="date ctt ctt-t4">
                        {{ topic.content.createDate | moment("MMM.YYYY") }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="center_slider" v-else>
              <div class="item" v-for="topic in recentNews">
                <a
                  :href="'aboutus/newsNEvent/newsDetail/' + topic.content.id"
                  class="item_link"
                >
                  <div class="img">
                    <img
                      :src="$imagePath + topic.attribute.pcThumbnail"
                      class="new_events_img"
                      alt="LX Hausys"
                    />
                  </div>
                  <div class="contents">
                    <div class="txt_box">
                      <p class="sort ctt ctt-t4">
                        {{ topic.attribute.topicTypeText }}
                      </p>
                      <p class="title tt-30">{{ topic.content.title }}</p>
                      <p class="date ctt ctt-t4">
                        {{ topic.content.createDate | moment("MMM.YYYY") }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="w-con">
          <!-- 상단 검색영역 -->
          <div class="search-box">
            <div class="filter_area">
              <div class="filter_wrap">
                <button
                  type="button"
                  class="btn_filter"
                  @click="clickFilterBtn()"
                >
                  <span class="icon icon_filter"></span>
                  <span class="txt p-ui">Filter</span>
                  <span class="txt m-ui">Filter by</span>
                  <span class="icon icon_arrow_down"></span>
                </button>
              </div>
              <div class="search">
                <input
                  type="text"
                  id="textSearch"
                  placeholder="Search News & Event"
                  v-model="searchText"
                  @keyup.enter="searchTopics"
                  @input="searchTopics"
                />
                <button type="button" class="btn_cancel" v-if=" this.searchText.length !== 0"
                        @click="clearSearch"
                />
                <button
                  type="button"
                  id="searchBtn"
                  @click="searchTopics"
                  class="btn_search"
                  v-else
                >
                </button>
              </div>
              <!-- s: filter popup -->
              <!-- <transition name="fade"> -->
              <div class="filter_pop">
                <div class="filter_tit">
                  <p>FILTER BY</p>
                  <button
                    type="button"
                    class="btn_close"
                    @click="closeFilterPop()"
                  ></button>
                </div>
                <div class="filter_top">
                  <ul>
                    <!--VIEWALL-->
                    <li>
                      <div class="filter_checkbox_tit">
                        <span>TOPIC​</span>
                      </div>
                      <div class="filter_checkbox_cont">
                        <div class="checkbox_box">
                          <ul class="checkbox_more no_line">
                            <li>
                              <input
                                type="checkbox"
                                id="AllTopics"
                                v-model="isCheckAll"
                                @change="selectAllFilter"
                              />
                              <label for="AllTopics">
                                <span class="round"></span>
                                <span class="text">All</span>
                              </label>
                            </li>
                            <li v-for="item in topicList" :key="item.id.value">
                              <input
                                type="checkbox"
                                :id="'mobile_' + item.text"
                                v-model="selectedMobTopic"
                                :value="item.id.value"
                                @change="selectMobTopic"
                              />
                              <label :for="'mobile_' + item.text">
                                <span class="round"></span>
                                <span class="text">{{ item.text }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <!--VIEWALL-->
                  </ul>
                </div>
                <!--gl-->
                <div class="filter_bottom">
                  <div class="btn_box">
                    <button class="btn_wh" @click="clearAllFilter">
                      Clear All
                    </button>
                    <button class="btn_bk" @click="searchByFilter">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div class="filter_dimmed"></div>
              <!-- </transition> -->
              <!-- e: filter popup -->
            </div>
            <div class="sort_area">
              <div class="filter_top">
                <ul>
                  <li>
                    <div class="filter_checkbox_tit">
                      <span>TOPIC</span>
                    </div>
                    <div class="filter_checkbox_cont">
                      <div class="checkbox_box">
                        <div class="checkbox_more">
                          <span>
                            <input
                              type="checkbox"
                              id="All Topics"
                              v-model="isCheckAll"
                              @change="selectTopicAll"
                            />
                            <label for="All Topics">All</label>
                          </span>
                          <span v-for="item in topicList" :key="item.id.value">
                            <input
                              type="checkbox"
                              :id="item.text"
                              :value="item.id.value"
                              v-model="selectedTopic"
                              @change="selectTopic"
                            />
                            <label :for="item.text">{{ item.text }}</label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="select-view" v-if="isMobile && selectedMobTopic.length > 0">
              <ul class="list-filter-key">
                <li class="sel" v-for="item in selectedMobTopic" :key="item" @click="removeFilter(item)">
                  <span>TOPIC : {{ getTopicTextById(item) }}</span>
                  <button type="button"></button>
                </li>
                <li class="sel del_all">
                  <button type="button" class="btn-all-delete" @click="removeFilter()">
                    <span>{{ $t('PF.button.clear_all') }}</span>
                  </button>
                </li>
              </ul>
            </div>
            <div class="count_area">
              <div class="filter-cnt">
                <span class="num">{{ resultCount }}</span>
                <span>&nbsp;</span>
                <span class="txt">Results</span>
              </div>
            </div>
          </div>

          <!-- 갤러리 -->
          <div class="bbs-list">
            <ul>
              <li v-for="topic in news">
                <a
                  :href="'aboutus/newsNEvent/newsDetail/' + topic.content.id"
                  class="item_link"
                >
                  <div class="img-box">
                    <img
                      :src="$imagePath + topic.attribute.pcThumbnail"
                      alt="LX Hausys"
                    />
                  </div>
                  <div class="txt-box">
                    <ul>
                      <li class="t-01">{{ topic.attribute.topicTypeText }}</li>
                      <li class="t-02">
                        <strong>{{ topic.content.title }}</strong>
                      </li>
                      <li class="t-03">
                        {{ truncate(topic.attribute.title, 250) }}
                      </li>
                      <li class="t-04">
                        {{ topic.content.createDate | moment("MMM.YYYY") }}
                      </li>
                    </ul>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div class="btn_area">
            <template v-if="isLoadMoreLoading">
              <div class="loading_area">
                <img
                  src="https://img.lxhausys.com/public/images/icon/loading.gif"
                  alt="loading"
                />
              </div>
            </template>
          </div>
          <!-- 더보기 -->
          <div class="btn_area">
            <button
              type="button"
              class="btn_load_more"
              v-show="isMore"
              @click="showMore()"
            >
              <span class="txt">Load more</span>
              <span class="icon icon_plus"></span>
            </button>
          </div>
          <div v-if="showNoResult" class="no_result">
            <img
              src="https://img.lxhausys.com/public/images/support/no_result.png"
              alt="noresult"
            />
            <p>No Result found</p>
          </div>
        </div>
      </div>
      <!-- //container (News & Event) -->
    </div>
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import HeaderComponent from '@/components/Layout/Header'
import QuickMenuComponent from '@/components/Layout/Quick'
import AboutUsService from '@/server/aboutus.service'
import ProductService from '@/server/product.service'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    QuickMenuComponent
  },
  data () {
    return {
      isMobile: window.innerWidth <= 768,
      searchText: '',
      date: null,
      selectedTopic: [],
      selectedMobTopic: [],
      selectedYear: null,
      topicList: [],
      recentNews: [],
      sliderNews: [],
      isCheckAll: false,
      isMobCheckAll: false,
      showNoResult: false,
      newsYearType: [],
      news: '',
      resultCount: '',
      isMore: true,
      newsResInfo: '',
      pageIndex: 1,
      pageSize: 6,
      text: '',
      sortField: 'createdAt',
      sortType: 'desc',
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      newsDictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      countryCode: '',
      countryCodeSub: ''
    }
  },
  metaInfo() {
    return {
      title:
        this.$route.meta.title == null
          ? "News & Event | LX Hausys"
          : this.$route.meta.title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            this.$route.meta.description == null
              ? "News & Event - About us | LX Hausys"
              : this.$route.meta.description,
        },
      ],
    };
  },

  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  watch: {
    news (newVal) {
      this.checkMore()
    },
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize)
    this.scrollToTop()
    const _this = this
    this.newsResInfo = await ProductService.getCategoryCode(
      'NEWS_LIST' + this.$countryCode
    )
    const newsTypeRes = await ProductService.getDictionaryList('NEWS_TYP')
    const newsYearTypeRes = await ProductService.getDictionaryList(
      'NEWS_YEAR_TYP'
    )
    this.newsYearType = newsYearTypeRes.data.reverse()
    this.topicList = newsTypeRes.data
    this.dictionaryCondition.list[0].values.push({
      id: 'topicType',
      value: '001'
    })
    this.dictionaryCondition.list[0].values.push({
      id: 'topicType',
      value: '002'
    })
    this.dictionaryCondition.list[0].values.push({
      id: 'topicType',
      value: '003'
    })
    this.dictionaryCondition.list[0].values.push({
      id: 'topicType',
      value: '004'
    })
    this.dictionaryCondition.list[0].values.push({
      id: 'topicType',
      value: '005'
    })
    this.dictionaryCondition.list[0].values.push({
      id: 'topicType',
      value: '006'
    })
    this.newsDictionaryCondition = JSON.parse(
      JSON.stringify(this.dictionaryCondition)
    )
    this.sliderNews = []
    await this.fetchSliderNews()
    await this.fetchNews()

    this.recentNews = this.news.slice(0, 3)
    this.checkMore()
    _this.$nextTick(() => {
      $('.slider-bbs').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
      })
      $(document).ready(function () {
        $(this).scrollTop(0)
      })
    })

    // new event slider 실행
    this.$nextTick(() => {
      $('.center_slider').slick({
        // centerMode: true,
        // centerPadding: '70px',
        slidesToShow: 1,
        // autoplay: true,
        autoplaySpeed: 4000,
        draggable: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              // centerMode: true,
              // centerPadding: '20px',
              slidesToShow: 1
            }
          }
        ]
      })
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.isMobile = window.innerWidth <= 768
      if (this.isMobile) {
        this.selectedMobTopic = [...this.selectedTopic]
      } else {
        this.selectedTopic = [...this.selectedMobTopic]
      }
    },
    gotolink (url) {
      location.href = url
    },
    async fetchNews () {
      const res = await AboutUsService.getContentNews(
        this.newsResInfo.data.object.id,
        this.newsDictionaryCondition,
        this.searchText,
        this.pageIndex,
        this.pageSize,
        this.sortField,
        this.sortType
      )
      this.news = res.data.list
      this.resultCount = res.data.totalCount
      this.showNoResult = this.resultCount === 0
    },

    async fetchSliderNews () {
      this.dictionaryCondition.list.push({
        operator: 'AND',
        values: [{ id: 'sliderYn', value: 'true' }]
      })
      const res = await AboutUsService.getContentNews(
        this.newsResInfo.data.object.id,
        this.dictionaryCondition,
        this.searchText,
        this.pageIndex,
        this.pageSize,
        this.sortField,
        this.sortType
      )
      this.sliderNews = res.data.list
    },

    scrollToTop () {
      window.scrollTo(0, 0)
    },
    truncate (value, limit) {
      if (!value) return ''
      if (value.length <= limit) return value

      let truncated = value.substring(0, limit)
      const lastSpaceIndex = truncated.lastIndexOf(' ')

      // 마지막 공백 이후의 단어를 잘라냄
      if (lastSpaceIndex > 0) {
        truncated = truncated.substring(0, lastSpaceIndex)
      }

      return truncated + '...'
    },
    openNews (topic, id) {
      location.href =
        'aboutus/news-event-detail/' +
        this.$utils.getSeoName(topic, 'news') +
        '/' +
        id
    },
    checkMore () {
      if (this.resultCount === this.news.length) {
        this.isMore = false
      } else {
        this.isMore = true
      }
    },
    async showMore () {
      const res = await AboutUsService.getContentNews(
        this.newsResInfo.data.object.id,
        this.newsDictionaryCondition,
        this.searchText,
        this.pageIndex,
        this.pageSize + 6,
        this.sortField,
        this.sortType
      )
      this.news = res.data.list
      this.pageSize += 6
    },

    removeTopics () {
      this.newsDictionaryCondition.list[0].values = []
    },

    async selectTopic (event) {
      const isChecked = event.target.checked
      if (!isChecked && this.isCheckAll) {
        this.isCheckAll = false
      } else if (
        isChecked &&
        this.selectedTopic.length === this.topicList.length
      ) {
        this.isCheckAll = true
      }
      this.pageSize = 6
      await this.removeTopics()
      this.selectedTopic.forEach((selectedTopic) => {
        this.newsDictionaryCondition.list[0].values.push({
          id: 'topicType',
          value: selectedTopic
        })
      })
      await this.fetchNews()
      this.checkMore()
    },

    async selectMobTopic(event) {
      const isChecked = event.target.checked;
      if (!isChecked && this.isCheckAll) {
        this.isCheckAll = false;
      } else if (isChecked && this.selectedMobTopic.length === this.topicList.length) {
        this.isCheckAll = true;
      }
      this.pageSize = 6;
      await this.removeTopics();
      this.selectedMobTopic.forEach((selectedMobTopic) => {
        this.newsDictionaryCondition.list[0].values.push({
          id: "topicType",
          value: selectedMobTopic,
        });
      });
      await this.fetchNews();
      this.checkMore();
    },

    async selectTopicAll () {
      if (this.isCheckAll) {
        this.selectedTopic = this.topicList.map((item) => item.id.value)
        this.newsDictionaryCondition.list[0].values = this.selectedTopic.map(
          (selectedTopic) => ({ id: 'topicType', value: selectedTopic })
        )
      } else {
        this.selectedTopic = []
      }
      await this.fetchNews()
    },

    async searchTopics () {
      this.pageSize = 6
      await this.fetchNews()
      this.checkMore()
    },
    closeFilterPop () {
      $('.filter_pop').removeClass('on')
      $('body').removeClass('scrolllock')
    },
    openFilterPop () {
      $('.filter_pop').addClass('on')
      $('body').addClass('scrolllock')
    },
    clickFilterBtn () {
      const filterFlag = $('.filter_pop').hasClass('on')
      if (filterFlag) {
        this.closeFilterPop()
      } else {
        this.openFilterPop()
      }
    },
    clearAllFilter () {
      this.selectedMobTopic = []
      this.isCheckAll = false
      this.newsDictionaryCondition.list[0].values = []
    },
    async searchByFilter () {
      this.removeTopics()
      this.selectedMobTopic.forEach((selectedMobTopic) => {
        this.newsDictionaryCondition.list[0].values.push({
          id: 'topicType',
          value: selectedMobTopic
        })
      })
      this.searchText
      this.pageSize = 6
      await this.fetchNews()
      this.checkMore()
      this.closeFilterPop()
    },
    selectAllFilter () {
      if (this.isCheckAll) {
        this.selectedMobTopic = this.topicList.map((item) => item.id.value)
        this.newsDictionaryCondition.list[0].values = this.selectedMobTopic.map(
          (selectedMobTopic) => ({ id: 'topicType', value: selectedMobTopic })
        )
      } else {
        this.selectedMobTopic = []
      }
    },
    getTopicTextById(id) {
      const topic = this.topicList.find(topic => topic.id.value === id);
      return topic ? topic.text : ""
    },
    removeFilter(filterId = null) {
      if (filterId) {
        this.selectedMobTopic = this.selectedMobTopic.filter(id => id !== filterId);
      } else {
        this.selectedMobTopic = [];
        this.isCheckAll = false;
      }
      this.searchByFilter()
    },
    clearSearch() {
      this.searchText = ''
      this.searchTopics()
    }
  }
}

// 필터버튼 팝업
// $(function () {
//   $('.btn_filter').click(function () {
//     $('.filter_pop').toggleClass('on')
//   })
// })

// 레이아웃 버튼
$(document).ready(function () {
  $('.layout_grid ').click(function () {
    $(this).addClass('on')
    $('.layout_list').removeClass('on')
  })
  $('.layout_list ').click(function () {
    $(this).addClass('on')
    $('.layout_grid').removeClass('on')
  })
})
</script>
