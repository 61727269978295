var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content inspr-view" }, [
        _c("div", { staticClass: "location type02" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Document.Text.Home")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("all.text.pros")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("all.text.designLab")))]),
          ]),
        ]),
        _c("div", { staticClass: "container prod-dt-view" }, [
          _c("div", [
            _vm._m(0),
            _c("p"),
            _c("h3", { staticClass: "sr-only" }, [
              _vm._v("How to Use LX Hausys Desgin Lab"),
            ]),
            _c("div", { staticClass: "ins-dl-text w-con col-02 inner" }, [
              _vm._m(1),
              _c("div", { staticClass: "text-box" }, [
                _vm._m(2),
                _c("div", { staticClass: "btn btn-blank" }, [
                  _vm._v("Have Questions or Ready to Order?"),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" +
                          _vm.countryCodeSub.toLowerCase() +
                          "/support/contactus",
                      },
                    },
                    [_c("span", [_vm._v("Contact Us")])]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("ShortComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prod-img ins-at-dt ins-dl-dt" }, [
      _c("div", { staticClass: "img-box p-ui" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/hausys/US/image/inspiration/DesignLab&ShowRoom/@img_design_01.png",
            alt: "images",
          },
        }),
      ]),
      _c("div", { staticClass: "img-box m-ui" }, [
        _c("img", {
          attrs: {
            src: "https://img.lxhausys.com/hausys/US/image/inspiration/DesignLab&ShowRoom/@img_articles_07.png",
            alt: "images",
          },
        }),
      ]),
      _c("div", { staticClass: "dt-text w-con inner" }, [
        _c("h1", { staticClass: "tt-1" }, [_vm._v("Design Lab")]),
        _c("div", { staticClass: "text ctt ctt-t3" }, [
          _vm._v(
            "HIMACS Design Lab provides you the option to choose. Simply select a color and submit the color matching request."
          ),
          _c("br"),
          _vm._v(
            "We will match the color and produce your personalized HIMACS. "
          ),
          _c("h2", { staticClass: "t-02" }, [
            _c("strong", [_vm._v("HIMACS")]),
            _vm._v("Design Lab Benefits"),
          ]),
          _c("div", { staticClass: "ico-box" }, [
            _c("ul", [
              _c("li", { staticClass: "ico-01" }, [
                _c("h3", { staticClass: "design-lab-circle-tit" }, [
                  _vm._v("Low Minimum Order Quantity"),
                ]),
                _c("p", { staticClass: "ctt ctt-t5" }, [
                  _vm._v(
                    "The minimum order quantity requirement is only 40 sheets."
                  ),
                ]),
              ]),
              _c("li", { staticClass: "ico-02" }, [
                _c("h3", { staticClass: "design-lab-circle-tit" }, [
                  _vm._v("Quick Turnaround"),
                ]),
                _c("p", { staticClass: "ctt ctt-t5" }, [
                  _vm._v(
                    "You will receive the product within 6 weeks of order placement."
                  ),
                ]),
              ]),
              _c("li", { staticClass: "ico-03" }, [
                _c("h3", { staticClass: "design-lab-circle-tit" }, [
                  _vm._v("Unlimited Possibility"),
                ]),
                _c("p", { staticClass: "ctt ctt-t5" }, [
                  _vm._v("We can match any solid color imaginable"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box p-ui" }, [
      _c("img", {
        attrs: {
          src: "https://img.lxhausys.com/hausys/US/image/inspiration/DesignLab&ShowRoom/@img_design_02.png",
          alt: "img",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("div", { staticClass: "step" }, [
          _c("span", [_vm._v("Step")]),
          _vm._v(" 01"),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("strong", [_vm._v("Choose Your Color")]),
          _c("p", [
            _vm._v(
              "You may choose any solid color from an available color resource or color swatch. "
            ),
            _c("br"),
            _vm._v(
              "You also have an option to provide a color sample (such as paint, cloth or other "
            ),
            _c("br"),
            _vm._v("materials with solid color) you want us to match"),
          ]),
        ]),
      ]),
      _c("li", [
        _c("div", { staticClass: "step" }, [
          _c("span", [_vm._v("Step")]),
          _vm._v(" 02"),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("strong", [_vm._v("Color Matching & Sample Delivery")]),
          _c("p", [
            _vm._v(
              "Request a sample by contacting LX Hausys territory manager or specification manager. "
            ),
            _c("br"),
            _vm._v(
              "We will start the color matching process and deliver the sample for you to review. "
            ),
            _c("br"),
            _vm._v("There is no charge for color matching."),
          ]),
        ]),
      ]),
      _c("li", [
        _c("div", { staticClass: "step" }, [
          _c("span", [_vm._v("Step")]),
          _vm._v(" 03"),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("strong", [_vm._v("Purchase Order")]),
          _c("p", [
            _vm._v("Confirm your color upon receiving your sample."),
            _c("br"),
            _vm._v(
              "Process your order with LX Hausys territory manager or specification manager. "
            ),
            _c("br"),
            _vm._v(
              "Once the order is placed, allow up to six weeks to receive the ordered product."
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }