<template>
  <div class="expanded-waste-recycling-grid-and-table">
    <ExpandedWasteRecyclingGrid
      :gridData="gridData"
    />
    <ExpandedWasteRecyclingTableArea
      :tableAreaData="tableAreaData"
    />
  </div>
</template>

<script>
import ExpandedWasteRecyclingGrid from './ExpandedWasteRecyclingGrid.vue'
import ExpandedWasteRecyclingTableArea from './ExpandedWasteRecyclingTableArea.vue'
export default {
  components: {
    ExpandedWasteRecyclingGrid,
    ExpandedWasteRecyclingTableArea
  },
  props: {
    gridAndTable: {
      type: Object
    }
  },
  data: function () {
    return {
      gridData: this.gridAndTable.gridData,
      tableAreaData: this.gridAndTable.tableAreaData
    }
  }

}
</script>

<style>
.expanded-waste-recycling-grid-and-table {
  display: flex;
  width: 1440px;
  padding: 50px;
  align-items: flex-start;
  background: linear-gradient(0deg, #F5F5F5 0%, #F5F5F5 100%), #FFF;
  gap: 30px;
}

@media (max-width: 768px) {
  .expanded-waste-recycling-grid-and-table {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }
}
</style>
