var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content himacPorta_veiw" }, [
        _vm._m(0),
        _c("div", { staticClass: "container equest_content ta-c" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _c("p", { staticClass: "mb-64" }),
            _c("p", { staticClass: "tt-1 mb-64 tac inner" }, [
              _vm._v("Thank you!"),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "more-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.goToMain },
                },
                [_c("span", [_vm._v("Go Back to the Homepage")])]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location" }, [
      _c("ul", [
        _c("li", { staticClass: "ico-home" }, [_vm._v("Professionals")]),
        _c("li", [_vm._v("HIMACS Partners Portal")]),
        _c("li", [_vm._v("Request a Partner Account")]),
        _c("li", [_vm._v("Completed")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tt-sub mb-88" }, [
      _vm._v("Thank you for applying for an account."),
      _c("br"),
      _vm._v(
        " Your account is currently pending approval by the site Administrator."
      ),
      _c("br"),
      _vm._v(" In the meantime, a welcome message with further instructions"),
      _c("br"),
      _vm._v(" has been sent to your e-mail address. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }