<template>
  <div id="wrap">
    <HeaderComponent v-if="!popFlag"></HeaderComponent>
    <GnbComponent v-if="!popFlag"></GnbComponent>
    <!-- content -->
    <div class="content cntu-view terms">
      <!-- location -->
      <div class="location" v-if="!popFlag">
        <ul>
          <li class="ico-home">{{$t('terms.text.FF0030_001')}}</li>
          <li>{{$t('terms.text.FF0030_002')}}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Terms and Conditions) -->
      <div class="container cntu-box private-view">
        <div class="w-con-02">
          <div class="termsWrap inner">
            <div class="termsArea">
              <h1 class="tt-1 visual-title tac">{{$t('terms.text.FF0030_002')}}</h1>
              <p class="tt-sub mb-88">{{$t('terms.text.FF0030_003')}}</p>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_004')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_005')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_006')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_007')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_008')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_009')}}</h2>
                <p class="ctt ctt-t4" style="margin-bottom: 0">
                  {{$t('terms.text.FF0030_010')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_011')}}
                </p>
                <!-- <ol>
                  <li>
                    <span>1)</span> You must retain, on all copies of the
                    Materials downloaded, all copyright and other proprietary
                    notices contained in the Materials.
                  </li>
                </ol> -->
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_012')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_013')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_014')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_015')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_016')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_017')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_018')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_019')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_020')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_021')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_022')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_023')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_024')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_025')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_026')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_027')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_028')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_029')}}
                </p>
              </div>
              <div class="t-type">
                <h2 class="ctt ctt-t0 ta-l">{{$t('terms.text.FF0030_030')}}</h2>
                <p class="ctt ctt-t4">
                  {{$t('terms.text.FF0030_031')}}
                  provision.<br /><br />
                  {{$t('terms.text.FF0030_032')}}
                  <br /><br />
                  {{$t('terms.text.FF0030_033')}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (Terms and Conditions) -->
    </div>
    <!-- //content -->
    <FooterComponent v-if="!popFlag"></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'

export default {
  name: 'terms',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      popFlag: false
    }
  },
  mounted () {
    const pop = this.$route.query.pop
    if (pop && pop === 'Y') {
      this.popFlag = true
    }
  }
}
</script>
