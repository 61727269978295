var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-100" },
    [
      _c("ProductSectionHeader", {
        staticClass: "mt-100",
        attrs: {
          title: "Seat Cover",
          description:
            "LX Hausys automotive skin delivers innovative designs through our best-in-class technology so as to bring emotional qualities to the indoor driving space.",
        },
      }),
      _c("ProductTab", {
        staticClass: "mt-50",
        attrs: {
          item: [
            { title: "SOFINO®", component: "SOFINO" },
            { title: "VERNO®", component: "VERNO" },
            { title: "Calfpelle®", component: "Calfpelle" },
            { title: "LUSSINO™", component: "LUSSINO" },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "SOFINO",
            fn: function () {
              return [_c("Sofino")]
            },
            proxy: true,
          },
          {
            key: "VERNO",
            fn: function () {
              return [_c("Verno")]
            },
            proxy: true,
          },
          {
            key: "Calfpelle",
            fn: function () {
              return [_c("Calfpelle")]
            },
            proxy: true,
          },
          {
            key: "LUSSINO",
            fn: function () {
              return [_c("Lussino")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }