<template>
  <div id="wrap">
    <div class="full_width">
    <div class="contactus_inp_group inner_width">
      <form action="#none" class="">
        <fieldset>
          <legend>{{ $t("contactus.text.contactus") }}</legend>
          <div class="tbl-wrap">
            <div class="tab-con tab01 on">
              <div class="tbl-01" v-if="radio === 'proCheck'">
                <h2 class="tt-2 mb-40" style="display:none">{{ $t("contactus.text.submitform") }}</h2>
                <div class="tbl-input">
                  <table>
                    <caption>Contact us For Professionals form</caption>
                    <colgroup>
                      <col style="width:50%">
                      <col style="width:50%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <td class="cell_padding">
                        <label class="tbl_label">I'm a</label>
                        <div class="tbl_check">
                          <input type="radio" name="ima" class="ima" id="ima-p" data-tab="tab01" checked="checked" @click="tapClick('proCheck')">
                          <label for="ima-p">Professional</label>
                          <input type="radio" name="ima" class="ima" id="ima-h" data-tab="tab02" @click="tapClick('homeCheck')">
                          <label for="ima-h">Homeowner</label>
                        </div>
                      </td>
                    </tr>
                      <tr>
                        <!-- type of inquiry -->
                        <td class="cell_padding">
                          <label class="tbl_label must">{{ $t("contactus.text.inquiry") }}</label>
                          <div class="sel-box">
                            <button type="button" class="btn-sel"><span class="placeholder">{{ proInquiryName }}</span></button>
                            <div class="sel-list">
                              <ul class="box">
                                <li class="box_inquiry"><button type="button" @click="categoryType('Dealer / Kitchen&Bath / Fabricator', 'INQ_DEALER_PRO', 'pro')">{{ $t("contactus.text.Dealer") }}</button></li>
                                <li class="box_inquiry"><button type="button" @click="categoryType('A&D / Commercial Project', 'INQ_AND_PRO','pro')">{{ $t("contactus.text.AnD") }}</button></li>
                                <li class="box_inquiry"><button type="button" @click="categoryType('Sample', 'INQ_SAMPLE_PRO', 'pro')">{{ $t("contactus.text.Sample") }}</button></li>
                                <li class="box_inquiry"><button type="button" @click="categoryType('Home Center Account Question', 'INQ_HOMECENTER_PRO','pro')">{{ $t("contactus.text.homeCenter") }}</button></li>
                                <li class="box_inquiry"><button type="button" @click="categoryType('Technical Inquiry', 'INQ_TECH_PRO','pro')">{{ $t("contactus.text.technicalInquiry") }}</button></li>
                                <li class="box_inquiry"><button type="button" @click="categoryType('Warranty', 'INQ_WARRANTY_PRO','pro')">{{ $t("contactus.text.warranty") }}</button></li>
                                <li class="box_inquiry"><button type="button" @click="categoryType('Others', 'INQ_OTHERS_PRO','pro')">{{ $t("contactus.text.others") }}</button></li>
                              </ul>
                            </div>
                          </div>
                          <p class="glSite required-msg" v-if="proData.inquiry == '' && proSubmitChk == 'Y'">{{ $t("contactus.text.checkInquiry") }}</p>
                        </td>
                        <!-- selectBox product category -->
                        <!--
                       <td class="cell_padding">
                         <label class="tbl_label must">{{ $t("contactus.text.prd_cate") }}</label>
                         <div class="sel-box">
                           <button type="button" class="btn-sel"><span class="placeholder">{{ proCateName }}</span></button>
                           <div class="sel-list">
                             <ul class="box">
                               <li v-for="productCate in proProductList">
                                 <button type="button" @click="proSelectCheck('productType', productCate.id.value, productCate.text, productCate.id.dictionaryId, productCate.sort)">{{productCate.text}}</button>
                               </li>
                             </ul>
                           </div>
                         </div>
                         <p class="glSite required-msg" v-if="proData.productCate == '' && proSubmitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                       </td>
                       -->
                    </tr>
                    <!-- checkBox product category -->
                    <tr class="product_wrap">
                      <td class="cell_padding">
                        <label class="tbl_label must">Products</label>
                        <div class="product_checkbox_cont">
                          <div class="checkbox_more" v-for="productCate in proProductList" :key="productCate.id.value">
                            <span>
                              <input type="checkbox" :id="productCate.id.value" :value="{ id: productCate.id.value, text: productCate.text }" v-model="proData.selectedItems">
                              <label :for="productCate.id.value">{{ productCate.text }}</label>
                            </span>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.selectedItems.length == 0 && proSubmitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                      </td>
                    </tr>
                    <!-- country :: 캐나다 사이트 오픈 후 삭제 예정-->
                    <tr>
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.country") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ proCountryName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="country in proCountryList">
                                <button type="button" @click="proSelectCheck('country', country.countryCd, country.countryNm)">{{ country.countryNm }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.country == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkCountry") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- State -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.state") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ proStateName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="state in proStateList">
                                <button type="button" @click="proSelectCheck('state', state.id.stateCd, state.stateNm)">{{ state.stateNm }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.state == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkState") }}</p>
                      </td>
                      <!-- Territory -->
                      <td class="cell_padding" style="display:none">
                        <label class="tbl_label must">Territory</label>
                        <input type="text" class="inp-text" id="inp07" v-model="proTerritoryName"/>
                      </td>
                      <!-- Zip code -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp01">{{ $t("contactus.text.zip") }}</label>
                        <input type="text" class="inp-text" id="inp01" maxlength="6" v-model="zipcode"/>
                        <p class="glSite required-msg" v-if="zipCodeCheck == false  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkZip") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- first name -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp02">{{ $t("contactus.text.firstName") }}</label>
                        <input type="text" class="inp-text" id="inp02" v-model="proData.firstName"/>
                        <p class="glSite required-msg" v-if="proData.firstName == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkFirstName") }}</p>
                      </td>
                      <!-- last name -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp03">{{ $t("contactus.text.lastName") }}</label>
                        <input type="text" class="inp-text" id="inp03" v-model="proData.lastName"/>
                        <p class="glSite required-msg" v-if="proData.lastName == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkLastName") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- email -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp04">{{ $t("contactus.text.email") }}</label>
                        <input type="text" class="inp-text" id="inp04" v-model="proData.email"/>
                        <p class="glSite required-msg" v-if="proData.email == '' && proSubmitChk == 'Y' || proSubmitChk == 'Y' && !emailRule.test(proData.email)">{{ $t("contactus.text.checkEmail") }}</p>
                      </td>
                      <!-- phone -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp05">{{ $t("contactus.text.phone") }}</label>
                        <input type="text" class="inp-text" id="inp05" v-model="proData.phone" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');"/>
                        <p class="glSite required-msg" v-if="proData.phone == '' && proSubmitChk == 'Y'">{{ $t("contactus.text.checkPhone") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- occupation -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.occupation") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ occName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="occ in occSelect">
                                <button type="button" @click="proSelectCheck('occupation', occ.id.value, occ.text)">{{ occ.text }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="proData.occupation == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkOcc") }}</p>
                      </td>
                      <!-- Company -->
                      <td class="cell_padding">
                          <label class="tbl_label must">{{ $t("contactus.text.companyNm") }}</label>
                          <input type="text" class="inp-text" v-model="proData.company"/>
                          <p class="glSite required-msg" v-if="proData.company == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkCompanyNm") }}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tbl-01" v-else-if="radio === 'homeCheck'">
                <h2 class="tt-2 mb-40" style="display:none">{{ $t("contactus.text.submitform") }}</h2>
                <div class="tbl-input">
                  <table>
                    <caption>Contact us For Homeowner form</caption>
                    <colgroup>
                      <col style="width:50%">
                      <col style="width:50%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <td class="cell_padding">
                        <label class="tbl_label">I'm a</label>
                        <div class="tbl_check">
                          <input type="radio" name="ima" class="ima" id="ima-p" data-tab="tab01" @click="tapClick('proCheck')">
                          <label for="ima-p">Professional</label>
                          <input type="radio" name="ima" class="ima" id="ima-h" data-tab="tab02" checked="checked" @click="tapClick('homeCheck')">
                          <label for="ima-h">Homeowner</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <!-- type of inquiry -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.inquiry") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ homeInquiryName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li class="box_inquiry"><button type="button" @click="categoryType('Technical Inquiry', 'INQ_TECH_HOME', 'home')">{{ $t("contactus.text.technicalInquiry") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="categoryType('Warranty', 'INQ_WARRANTY_HOME', 'home')">{{ $t("contactus.text.warranty") }}</button></li>
                              <li class="box_inquiry"><button type="button" @click="categoryType('Others', 'INQ_OTHERS_HOME', 'home')">{{ $t("contactus.text.others") }}</button></li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="homeData.inquiry == '' && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkInquiry") }}</p>
                      </td>
                      <!-- selectBox product category -->
                      <!--
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.prd_cate") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ homeCateName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="productCate in homeProductList">
                                <button type="button" @click="homeSelectCheck('productType', productCate.id.value, productCate.text, productCate.id.dictionaryId, productCate.sort)">{{productCate.text}}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="homeData.productCate == '' && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                      </td>
                      -->
                    </tr>
                    <!-- checkBox product category -->
                    <tr class="product_wrap">
                      <td class="cell_padding">
                        <label class="tbl_label must">Products</label>
                        <div class="product_checkbox_cont">
                          <div class="checkbox_more" v-for="productCate in homeProductList" :key="productCate.id.value">
                            <span>
                              <input type="checkbox" :id="productCate.id.value" :value="{ id: productCate.id.value, text: productCate.text }" v-model="homeData.selectedItems">
                              <label :for="productCate.id.value">{{ productCate.text }}</label>
                            </span>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="homeData.selectedItems.length == 0 && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkPrd_cate") }}</p>
                      </td>
                    </tr>
                    <!-- country :: 캐나다 사이트 오픈 후 삭제 예정-->
                    <tr>
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.country") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ homeCountryName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="country in homeCountryList">
                                <button type="button" @click="homeSelectCheck('country', country.countryCd, country.countryNm)">{{ country.countryNm }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="homeData.country == ''  && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkCountry") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- State -->
                      <td class="cell_padding">
                        <label class="tbl_label must">{{ $t("contactus.text.state") }}</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel"><span class="placeholder">{{ homeStateName }}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="state in homeStateList">
                                <button type="button" @click="homeSelectCheck('state', state.id.stateCd, state.stateNm)">{{ state.stateNm }}</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite required-msg" v-if="homeData.state == ''  && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkState") }}</p>
                      </td>
                      <!-- Territory -->
                      <td class="cell_padding" style="display:none">
                        <label class="tbl_label must">Territory</label>
                        <input type="text" class="inp-text" id="inp07" v-model="homeTerritoryName"/>
                      </td>
                      <!-- Zip code -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp01">{{ $t("contactus.text.zip") }}</label>
                        <input type="text" class="inp-text" id="inp01" maxlength="6" v-model="homeData.zipCode"/>
                        <p class="glSite required-msg" v-if="homeData.zipCode == ''  && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkZip") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- first name -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp02">{{ $t("contactus.text.firstName") }}</label>
                        <input type="text" class="inp-text" id="inp02" v-model="homeData.firstName"/>
                        <p class="glSite required-msg" v-if="homeData.firstName == ''  && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkFirstName") }}</p>
                      </td>
                      <!-- last name -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp03">{{ $t("contactus.text.lastName") }}</label>
                        <input type="text" class="inp-text" id="inp03" v-model="homeData.lastName"/>
                        <p class="glSite required-msg" v-if="homeData.lastName == ''  && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkLastName") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <!-- email -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp04">{{ $t("contactus.text.email") }}</label>
                        <input type="text" class="inp-text" id="inp04" v-model="homeData.email"/>
                        <p class="glSite required-msg" v-if="homeData.email == '' && homeSubmitChk == 'Y' || homeSubmitChk == 'Y' && !emailRule.test(homeData.email)">{{ $t("contactus.text.checkEmail") }}</p>
                      </td>
                      <!-- phone -->
                      <td class="cell_padding">
                        <label class="tbl_label must" for="inp05">{{ $t("contactus.text.phone") }}</label>
                        <input type="text" class="inp-text" id="inp05" v-model="homeData.phone" oninput="this.value = this.value.replace(/[^0-9-.]/g, '').replace(/(\..*)\./g, '$1');"/>
                        <p class="glSite required-msg" v-if="homeData.phone == '' && homeSubmitChk == 'Y'">{{ $t("contactus.text.checkPhone") }}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tbl-02">
                <div class="tbl-input">
                  <table>
                    <tbody>
                    <tr>
                      <!-- comment -->
                      <td class="cell_padding">
                        <label class="tbl_label">{{ $t("contactus.text.comment") }}</label>
                        <div class="ta-box">
                          <textarea v-model="comment" maxlength="1000"></textarea>
                        </div>
                        <p class="glSite required-msg" v-if="comment == ''  && proSubmitChk == 'Y'">{{ $t("contactus.text.checkCommentUs") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="inp-chk">
                          <input type="checkbox" id="checkSubmit" v-model="consent">
                          <span>{{ $t("contactus.text.consent1") }} <span>{{ $t("contactus.text.submit") }}</span>{{ $t("contactus.text.consent2") }}<a href="javascript:void(0);" @click="openPrivacy()">{{ $t("contactus.text.policy") }}</a> and <a href="javascript:void(0);" @click="openTerms()">{{ $t("contactus.text.terms") }}</a>. <span>*</span></span>
                        </label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <br/>
                <div class="noti-02" v-if="consent == false && homeSubmitChk == 'Y' || consent == false && proSubmitChk == 'Y'">{{ $t("contactus.text.checkComment") }}</div>
                <div class="more-box ta-r">
                  <button type="button" @click="formSubmit()" :disabled="isSubmitting" class="btn">
                    <span>{{ isSubmitting ? 'submitting...' : $t("contactus.button.submit") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  </div>
</template>

<script>

import getDictionaryList from '@/server/support.service'
import getCountryList from '@/server/us.service'
import metaService from '@/server/meta.service'

export default {
  name: 'contactus_US',
  props: ['pcdCateCode'],
  components: {
  },
  data () {
    return {
      isSubmitting: false,
      guid: '',
      proCateNumber: '',
      selectedItems: [],
      metaTitle: '',
      metaDescription: '',
      nationCode: '',
      memId: '',
      memGroup: '',
      proGeneralChk: 'N',
      homeGeneralChk: 'N',
      proSubmitChk: 'N',
      homeSubmitChk: 'N',
      radio: 'proCheck',
      proInquiryName: this.$t('contactus.text.select'),
      homeInquiryName: this.$t('contactus.text.select'),
      proCateName: [],
      homeCateName: [],
      proStateName: this.$t('contactus.text.select'),
      homeStateName: this.$t('contactus.text.select'),
      proCountryName: this.$t('contactus.text.select'),
      homeCountryName: this.$t('contactus.text.select'),
      occName: this.$t('contactus.text.select'),
      proTerritoryName: '',
      homeTerritoryName: '',
      proProductList: [],
      homeProductList: [],
      proCountryList: [],
      homeCountryList: [],
      proStateList: [],
      homeStateList: [],
      proDealerCate: [],
      proAndCate: [],
      proSampleCate: [],
      proHomecenterCate: [],
      proTechCate: [],
      proWarrantyCate: [],
      proOthersCate: [],
      homeTechCate: [],
      homeWarrantyCate: [],
      homeOthersCate: [],
      occSelect: [],
      nextOccSelect: [],
      comment: '',
      areaList: '',
      mktChk: 'N',
      zipCodeCheck: '',
      zipcode: '',
      categorySelected: false,
      inquirySelected: false,
      consent: false,
      proData: {
        proCateNumber: '',
        infoProCateName: '',
        selectedItems: [],
        zipCode: '',
        productCate: '',
        inqDictValue: '',
        dictionaryId: '',
        inquiry: '',
        firstName: '',
        lastName: '',
        email: '',
        area: '',
        phone: '',
        country: '',
        state: '',
        occupation: '',
        company: '',
        address: ''
      },
      homeData: {
        proCateNumber: '',
        infoProCateName: '',
        selectedItems: [],
        zipCode: '',
        productCate: '',
        inqDictValue: '',
        dictionaryId: '',
        inquiry: '',
        state: '',
        firstName: '',
        lastName: '',
        country: '',
        email: '',
        phone: ''
      },
      siteType: [
        {
          id: '_US',
          value: 'US'
        },
        {
          id: '_GL',
          value: 'GL'
        }
      ],
      siteTp: '',
      //  메일 발송용 text
      productTypeText: '',   //보류
      inquiryText: '',
      countryText: '',
      stateText: '',
      areaText: '',
      occText: '',
      TypeText: '',
      proDepartment: '',
      homeDepartment: '',
      proCateSort: '',
      homeCateSort: '',
      proZipType: 'N',
      homeZipType: 'N',
      checkZip: '',
      tabType: '',
      emailRule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/ // 이메일 정규식
    }
  },
  watch: {
    // zipCode 숫자만, 글자수 체크
    zipcode (val) {
      const reg = /^[A-Za-z0-9+]*$/
      if (reg.test(val)) {
        /*console.log("유효성검사 = true")*/
        return this.zipCodeCheck = true
      } else {
        /*console.log("유효성검사 = false")*/
        return this.zipCodeCheck = false
      }
      /*console.log("유효성검사 = 성공")*/
    },
    $route (to, form) {
      if (to !== form) {
        this.$router.go(0)
      }
    }
  },
  created () {
    const radioType = this.$route.params.user_type
    const _this = this
    const nationCode = this.$countryCode // 국가 코드(_US, _GL 등)
    _this.nationCode = nationCode
    // US일 경우 탭 선택
    var type = ''
    if (radioType == 'home' && _this.nationCode == '_US') {
      _this.radio = 'homeCheck'
      _this.tabType = 'Homeowners'
      type = 'HOME'
    } else {
      _this.radio = 'proCheck'
      _this.tabType = 'Professionals'
      type = 'PRO'
    }
    _this.$nextTick(async function () {
      if (_this.nationCode === '_US') {
        const { data } = await metaService.getMeta('CONTACT_SALES_' + type, '', 'US')
        _this.metaDescription = data.description
        _this.metaTitle = data.title
      }
    })
  },
  async mounted () {
    const _this = this

    // GL, US 구분
    _this.siteTp = _.filter(_this.siteType, function (c) {
      return c.id == _this.nationCode
    })

    // 데이터 사전 불러오기
    const dictionary = await getDictionaryList.getDictionaryValueList(
      ['INQ_DEALER_PRO', 'INQ_AND_PRO', 'INQ_SAMPLE_PRO', 'INQ_HOMECENTER_PRO', 'INQ_TECH_PRO', 'INQ_WARRANTY_PRO', 'INQ_OTHERS_PRO', 'INQ_TECH_HOME', 'INQ_WARRANTY_HOME', 'INQ_OTHERS_HOME', 'OCCUPATION'])
        /*,'INQ_VIATERA_PRO', 'INQ_HIMACS_PRO', 'INQ_HFLOR_PRO', 'OCCUPATION', 'INQ_VIATERA_HOME', 'INQ_HIMACS_HOME', 'INQ_HFLOR_HOME', 'INQ_TERA_PRO', 'INQ_TERA_HOME'*/
    _this.proDealerCate = dictionary.data.dicList[0].list // Pro Category Type (Professionals Dealer / Kitchen&Bath / Fabricator 조회 종류)
    _this.proAndCate = dictionary.data.dicList[1].list // Pro Category Type (Professionals A&D / Commercial Project 조회 종류)
    _this.proSampleCate = dictionary.data.dicList[2].list // Pro Category Type (Professionals Sample 조회 종류)
    _this.proHomecenterCate = dictionary.data.dicList[3].list // Pro Category List(Professionals Center Account Question 조회 종류)
    _this.proTechCate = dictionary.data.dicList[4].list // Pro Category Type (Professionals Technical Inquiry 조회 종류)
    _this.proWarrantyCate = dictionary.data.dicList[5].list // Pro Category Type (Professionals Warranty 조회 종류)
    _this.proOthersCate = dictionary.data.dicList[6].list // Pro Category Type (Professionals Others 조회 종류)
    _this.homeTechCate = dictionary.data.dicList[7].list // home Category Type (Homeowners Technical Inquiry  조회 종류)
    _this.homeWarrantyCate = dictionary.data.dicList[8].list // home Category Type (Homeowners Warranty 조회 종류)
    _this.homeOthersCate = dictionary.data.dicList[9].list // home Category (Homeowners Others 조회 종류)
    _this.nextOccSelect = dictionary.data.dicList[10].list // Pro Occupation List(직종 리스트)

    // 국가 리스트 조회
    const countryList = await getCountryList.getCountryList()
    _this.proCountryList = _.filter(countryList.data, function (c) { // Professionals CountryList (밸리데이션을 위해 pro, home 구분)
      return c.countryNm != 'Other' // Other값 제외하고 보여주기
    })
    _this.homeCountryList = _.filter(countryList.data, function (c) { // Professionals CountryList (밸리데이션을 위해 pro, home 구분)
      return c.countryNm != 'Other' // Other값 제외하고 보여주기
    })
  },
  methods: {
    generateGuid() {
      const _this = this
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
      return _this.guid
    },
    tapClick (type) { // Professionals, Homeowners 탭 클릭 이벤트
      const _this = this
      _this.radio = type
    },
    async categoryType(type, code, submitType) { // Product Category SelectBox 클릭 이벤트
      const _this = this
      _this.guid = await this.generateGuid()
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()

      if (submitType == 'pro') {
        _this.proData.inquiry = type
        _this.proInquiryName = type
        _this.proData.selectedItems = []
        _this.proData.productCate = ''
        if (type == 'Dealer / Kitchen&Bath / Fabricator') {
          _this.proProductList = _this.proDealerCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '001'
        } else if (type == 'A&D / Commercial Project') {
          _this.proProductList = _this.proAndCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '002'
        } else if (type == 'Sample') {
          _this.proProductList = _this.proSampleCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '003'
        } else if (type == 'Home Center Account Question') {
          _this.proProductList = _this.proHomecenterCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '004'
        } else if (type == 'Technical Inquiry') {
          _this.proProductList = _this.proTechCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '005'
        } else if (type == 'Warranty') {
          _this.proProductList = _this.proWarrantyCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '006'
        } else if (type == 'Others') {
          _this.proProductList = _this.proOthersCate
          _this.proData.dictionaryId = code
          _this.proGeneralChk = 'N'
          _this.proData.proCateNumber = '007'
        }
      } else if (submitType == 'home') {
        _this.homeData.inquiry = type
        _this.homeInquiryName = type
        _this.homeData.selectedItems = []
        _this.homeData.productCate = ''
        if (type == 'Technical Inquiry') {
          _this.homeProductList = _this.homeTechCate
          _this.homeData.dictionaryId = code
          _this.homeGeneralChk = 'N'
          _this.homeData.proCateNumber = '001'
        } else if (type == 'Warranty') {
          _this.homeProductList = _this.homeWarrantyCate
          _this.homeData.dictionaryId = code
          _this.homeGeneralChk = 'N'
          _this.homeData.proCateNumber = '002'
        } else if (type == 'Others') {
          _this.homeProductList = _this.homeOthersCate
          _this.homeData.dictionaryId = code
          _this.homeGeneralChk = 'N'
          _this.homeData.proCateNumber = '003'
        }
      }
    },
    async proSelectCheck(type, code, value, dictionary, sort) { // Professionals 일 때 selectBox 클릭 이벤트
      const _this = this
      $('.sel-box .btn-sel').removeClass('on')
      $('.sel-box .sel-list').slideUp()

      if (type == 'country') {
        _this.proData.country = code
        _this.proCountryName = value
        _this.proStateName = 'Select'
        _this.proData.state = ''
        // SelectBox에서 선택한 국가에 따라 도시 값 변경
        const stateList = await getCountryList.getStateList(code)
        _this.proStateList = _.filter(stateList.data, function (c) {
          return c.stateNm != 'Puerto Rico' // Puerto Rico 제외하고 보여주기
        })
      } else if (type == 'state') {
        _this.proData.state = code
        _this.proStateName = value
        _this.occSelect = _this.nextOccSelect
        const proTerritory = await getCountryList.getTerritory(_this.proData.state)
        _this.proTerritoryName = proTerritory.data[0].territory
      } else if (type == 'occupation') {
        _this.proData.occupation = code
        _this.occName = value
      }
    },
    async homeSelectCheck(type, code, value, dictionary, sort) { // Homeowners 일 때 selectBox 클릭 이벤트
      const _this = this
      if (type == 'country') {
        _this.homeData.country = code
        _this.homeCountryName = value
        _this.homeStateName = 'Select'
        _this.homeData.state = ''
        // SelectBox에서 선택한 국가에 따라 도시 값 변경
        const stateList = await getCountryList.getStateList(code)
        _this.homeStateList = _.filter(stateList.data, function (c) {
          return c.stateNm != 'Puerto Rico' // Puerto Rico 제외하고 보여주기
        })
      } else if (type == 'state') {
        _this.homeData.state = code
        _this.homeStateName = value
        const homeTerritory = await getCountryList.getTerritory(_this.homeData.state)
        _this.homeTerritoryName = homeTerritory.data[0].territory
      }
    },
    async formSubmit() {
      const _this = this
      const submitData = []
      if (_this.isSubmitting) return
      else _this.isSubmitting = true

      try {
        // comment 글자수 체크
        if (_this.comment.length > 1000) {
          alert('The maximum number of characters is 1,000 characters.')
          return false
        }
        // 동의여부 체크
        if (!$('#checkSubmit').is(':checked')) {
          alert('Please check whether you agree to collect personal information.')
        }
        if (_this.radio == 'proCheck') {
          _this.proSubmitChk = 'Y'
          for (const item of _this.proData.selectedItems) {
            const formData = await _this.formSubmit_data(item)
            if (formData === false) return
            else submitData.push(formData)
          }
        } else {
          _this.homeSubmitChk == 'Y'
          for (const item of _this.homeData.selectedItems) {
            const formData = await _this.formSubmit_data(item)
            if (formData === false) return
            else submitData.push(formData)
          }
        }
        // 동의 여부 체크 및 저장, 메일 발송
        if ($('#checkSubmit').is(':checked')) {
          const result = await getDictionaryList.getContactUsSave(submitData)
          if (result.data.Type == true) {
            this.makeDataLayerForm()
            setTimeout(function () {
              location.href = 'support/contactus-close'
            }, 1000)
          } else {
            alert('Error')
          }
        }
      } catch (error) {
          //console.error('Submission error:', error)
          alert('An error occurred during submission. Please try again.')
      } finally {
        _this.isSubmitting = false
      }
    },
    async formSubmit_data (item) {
      const _this = this
      const formData = {}
      _this.mktChk = 'N'

      if (_this.consent == true) {
        _this.mktChk = 'Y'
      }

      if (_this.radio == 'proCheck') {
      // Professionals 저장
        _this.proSubmitChk = 'Y'
        _this.proCateName = item.text

        // 집 코드 값 확인
        if (_this.proData.country == 'US') {
          const checkZip = await getDictionaryList.getCheckZip(_this.zipcode)
          if (checkZip.data.type) {
            _this.checkZip = true
          } else {
            alert('Please check your zip/postal code.')
            _this.checkZip = false
            return false
          }
        } else {
          _this.checkZip = true
        }
        // rep_type 배정
        if (_this.proData.country == 'US') {
            if (_this.proInquiryName == 'Home Center Account Question') {
              _this.proDepartment = 'Home Center'
            } else if (_this.proInquiryName == 'Sample') {
              if (_this.proCateName == 'HFLOR') {
                  _this.proDepartment = 'Sample_HFLOR'
              } else {
                  _this.proDepartment = 'Sample_SURFACE'
              }
            }
            else if (_this.proInquiryName == 'Technical Inquiry') {
                _this.proDepartment = 'Tech'
            } else if (_this.proInquiryName == 'Warranty') {
              if (_this.proTerritoryName == 'East') {
                _this.proDepartment = 'Warranty_East'
              } else {
                _this.proDepartment = 'Warranty_West'
              }
            } else if (_this.proInquiryName == 'Others') {
              if (_this.proCateName == 'Automotive') {
                _this.proDepartment = 'Others_Automotive'
              } else {
                _this.proDepartment = 'Customer_Care'
              }
            } else {
              _this.proZipType = 'Y'
              if (_this.proInquiryName == 'Dealer / Kitchen&Bath / Fabricator') {
                if (_this.proCateName == 'HFLOR') {
                  _this.proDepartment = 'HFLOR'
                } else {
                  _this.proDepartment = 'TM'
                }
              } else {
                /*  A&D / Commercial Project  */
                _this.proDepartment = 'CSM'
              }
            }
        } else {
          // Canada :: 기존 시스템 유지
            if (_this.proCateName == 'TERACANTO') {
              _this.proDepartment = 'TERA'
            } else {
              _this.proDepartment = 'CAN'
            }
        }

        _this.proData.inqDictValue = item.id
        _this.proData.infoProCateName += item.text + '|'

        formData.reqType = ('Pro')
        formData.categoryCode = (_this.proCateName)
        formData.inqDictId = (_this.proData.dictionaryId)
        formData.inqDictVal = (_this.proData.inqDictValue)
        formData.countryCd = (_this.proData.country)
        formData.stateCd = (_this.proData.state)
        formData.city = (_this.proStateName)
        formData.zipCd = (_this.zipcode)
        formData.firstNm = (_this.proData.firstName)
        formData.lastNm = (_this.proData.lastName)
        formData.email = (_this.proData.email)
        formData.phone = (_this.proData.phone)
        formData.comment = (_this.comment)
        formData.siteType = (_this.siteTp[0].value)
        formData.mktChk = (_this.mktChk)
        formData.department = (_this.proDepartment)
        formData.addr1 = (_this.proData.address)
        formData.occupation = (_this.proData.occupation)
        formData.companyNm = (_this.proData.company)
        formData.jobTitle = (' ')
        formData.companyWebs = (' ')
        formData.companyAddr = (' ')
        formData.guid = (_this.guid)
        // 메일 발송용 text
        formData.typeText = ('Professionals')
        formData.inquiryText = (_this.proInquiryName)
        formData.productName = (_this.proCateName)
        formData.countryText = (_this.proCountryName)
        formData.stateText = (_this.proStateName)
        formData.zipType = (_this.proZipType)
        formData.occText = (_this.occName)

      } else {
      // Homeowners 저장
        _this.homeSubmitChk = 'Y'
        _this.homeCateName = item.text

        if (_this.homeData.country == 'US') {
          if (_this.homeInquiryName == 'Warranty') {
            if (_this.homeTerritoryName == 'East') {
              _this.homeDepartment = 'Warranty_East'
            } else {
              _this.homeDepartment = 'Warranty_West'
            }
          } else if (_this.homeInquiryName == 'Others') {
              _this.homeDepartment = 'Customer_Care'
          } else {
            _this.homeDepartment = 'Tech'
          }
        } else {
          // Canada :: 기존 시스템 유지
          if (_this.homeCateName == 'TERACANTO') {
            _this.homeDepartment = 'TERA'
          } else {
            _this.homeDepartment = 'CAN'
          }
        }

        _this.homeData.inqDictValue = item.id
        _this.homeData.infoProCateName += item.text + '|'

        formData.reqType = ('Home')
        formData.inqDictId = (_this.homeData.dictionaryId)
        formData.inqDictVal = (_this.homeData.inqDictValue)
        formData.categoryCode = (_this.homeCateName)
        formData.countryCd = (_this.homeData.country)
        formData.stateCd = (_this.homeData.state)
        formData.city = (_this.homeStateName)
        formData.zipCd = (_this.homeData.zipCode)
        formData.firstNm = (_this.homeData.firstName)
        formData.lastNm = (_this.homeData.lastName)
        formData.email = (_this.homeData.email)
        formData.phone = (_this.homeData.phone)
        formData.comment = (_this.comment)
        formData.siteType = (_this.siteTp[0].value)
        formData.mktChk = (_this.mktChk)
        formData.department = (_this.homeDepartment)
        formData.occupation = (' ')
        formData.companyNm = (' ')
        formData.jobTitle = (' ')
        formData.companyWebs = (' ')
        formData.companyAddr = (' ')
        formData.guid = (_this.guid)
        // 메일 발송용 텍스트
        formData.typeText = ('Homeowners')
        formData.inquiryText = (_this.homeInquiryName)
        formData.productName = (_this.homeCateName)
        formData.countryText = (_this.homeCountryName)
        formData.stateText = (_this.homeStateName)
        formData.zipType = ('N')
      }
      var emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i // 이메일 정규식

      // 빈값 체크
      for (const [key, value] of Object.entries(formData)) {
        if (key === 'addr1') {
          continue
        }
        if (key === 'email') {
          if (!emailRule.test(value)) {
            alert('Please check your email.')
            return false
          }
        } else if (value === '') {
          alert('Please check again!')
          return false
        }
      }
      return formData
    },
    openCart () {
      this.$root.$refs.cartView.openPopup()
    },
    openPrivacy () {
      window.open('etc/privacy?pop=Y', 'privacy', 'width:800px')
    },
    openTerms () {
      window.open('etc/terms?pop=Y', 'terms', 'width:800px')
    },
    // GTM dataLayer - contact us
    makeDataLayerForm () {
      let contactType = ''
      let email = ''
      if (this.radio === 'proCheck' && this.nationCode === '_US') {
        contactType = 'PROFESSIONALS'
        email = this.proData.email
      } else if (this.radio === 'homeCheck' && this.nationCode === '_US') {
        contactType = 'HOMEOWNERS'
        email = this.homeData.email
      }

      let contactInquiry = ''
      if (this.nationCode === '_US') {
        if (this.radio === 'proCheck') {
          contactInquiry = this.proInquiryName
        } else if (this.radio === 'homeCheck') {
          contactInquiry = this.homeInquiryName
        }
      }

      let contactCountry = ''
      if (this.nationCode === '_US') {
        if (this.radio === 'proCheck') {
          contactCountry = this.proCountryName
        } else if (this.radio === 'homeCheck') {
          contactCountry = this.homeCountryName
        }
      } else {
        contactCountry = this.proCountryName
      }

      let contactState = ''
      if (this.nationCode === '_US') {
        if (this.radio === 'proCheck') {
          contactState = this.proStateName
        } else if (this.radio === 'homeCheck') {
          contactState = this.homeStateName
        }
      }

      let contactCompanyNm = ''
      if (this.nationCode === '_US') {
        if (this.radio === 'proCheck') {
          contactCompanyNm = this.proData.company
        } else if (this.radio === 'homeCheck') {
          contactCompanyNm = this.homeData.company
        }
      } else {
        contactCompanyNm = this.proData.company
      }

      const info = {
        event: 'contactUsComp',
        contact_email: this.$utils.makeMd5hash(email),
        contact_type: contactType,
        contact_product_category: this.radio == 'proCheck' ? this.proData.infoProCateName.slice(0, -1) : this.homeData.infoProCateName.slice(0, -1),
        contact_type_of_inquiry: contactInquiry,
        contact_area: '',
        contact_country: contactCountry,
        contact_state: contactState,
        contact_zipcode: this.zipcode || this.homeData.zipCode,
        contact_occupation: this.radio == 'proCheck' ? this.occName : '',
        contact_company: contactCompanyNm,
        contact_marketing_flag: this.mktChk
      }
      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push(info)
      }
    }
  }
}

// 모바일에서 줄바꿈 태그 삭제
$(document).ready(function () {
  var windowWidth = window.outerWidth
  if (windowWidth < 769) {
    $('.visual-desc').find('br').remove()
  }
})

</script>
