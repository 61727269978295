<template>
  <div class="region-card-area" :class="cardType">
    <div class="number-area">
      <span>{{ iconTxt }}</span>
    </div>
    <div class="content-area" :class="cardType">
      <!-- 지구본 이미지 -->
      <div class="image-area">
        <img :src="mapImageSrc" />
      </div>

      <div class="text-area">
        <div class="title-area">
          <span>{{ title }}</span>
        </div>

        <div class="desc-area">
          <pre>{{ desc }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    cardType: {
      type: String,
      default: 'text-only'
    },
    iconTxt: {
      type: String,
      default: ''
    },
    mapImageSrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.region-card-area {
  position: relative;
  border: 1px solid #CCC;font-family: "NotoSans" , sans-serif;
}

.region-card-area .number-area {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.region-card-area .number-area span {
  color: #FFF;
  text-align: center;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 103.448% */
}

.region-card-area .content-area {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
}

.region-card-area .content-area.vertical {
  flex-direction: column;
  align-items: center;
}

.region-card-area .content-area.horizontal {
  flex-direction: row;
  align-items: center;
}

@media (max-width: 768px) {
  .region-card-area .content-area.horizontal {
    flex-direction: column;
  }
  .region-card-area .content-area.text-only {
    margin-left: 0px !important;
    width: 100%;
    margin-top: 35% !important;
  }
}

.region-card-area .content-area.text-only {
  flex-direction: row;
  margin-left: 37px;
  margin-top: 88px;
}

.region-card-area .content-area.text-only .image-area {
  width: 0px;
  height: 0px;
}

.region-card-area .content-area .image-area {
  width: 290px;
  height: 290px;
}

@media (max-width: 768px) {
  .region-card-area .content-area .image-area {
    width: 90%;
    height: 90%;
    max-width: 290px;
  }
}

.region-card-area .content-area.vertical .image-area {
  margin-top: 102px;
}

.region-card-area .content-area.horizontal .image-area {
  margin-left: 85px;
}

@media (max-width: 768px) {
  .region-card-area .content-area.horizontal .image-area {
    margin-left: 0px;
    margin-top: 102px;
  }
}

.region-card-area .content-area .text-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .region-card-area .content-area .text-area {
    margin-top: 5%;
  }
}

.region-card-area .content-area.text-only .text-area {
  align-items: flex-start;
}

@media (max-width: 768px) {
  .region-card-area .content-area.text-only .text-area {
    align-items: center;
  }
}

.region-card-area .content-area.horizontal .text-area {
  margin-left: 97px;
}

@media (max-width: 768px) {
  .region-card-area .content-area.horizontal .text-area {
    margin-left: 0px;
    width: 90%;
    margin-top: 5%;
  }
}

.region-card-area .content-area.horizontal .text-area .title-area {
  margin-top: 0px;
}

.region-card-area .content-area.text-only .text-area .title-area {
  margin-top: 0px;
}

.region-card-area .content-area .text-area .title-area {
  margin-top: 64px;
}

@media (max-width: 768px) {
  .region-card-area .content-area .text-area .title-area {
    margin-top: 0px !important;
  }
}

.region-card-area .content-area .text-area .desc-area {
  margin-top: 30px;
  width: 360px;
}

@media (max-width: 768px) {
  .region-card-area .content-area .text-area .desc-area {
    width: 90%;
    margin-bottom: 40%;
  }
}

.region-card-area .content-area .text-area .desc-area pre {
  color: #000;
  text-align: center;
  font-family: "NotoSans" , sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  /* 178.571% */
  white-space: pre-wrap;
}

:lang(ru) .region-card-area .content-area .text-area .desc-area pre {
  line-height: 1.4;
}

.region-card-area .content-area.text-only .text-area .desc-area pre {
  text-align: left;
}

@media (max-width: 768px) {
  .region-card-area .content-area.text-only .text-area .desc-area pre {
    text-align: center;
  }
}

.region-card-area .content-area .text-area .title-area span {
  color: #000;
  text-align: center;
  font-family: "NotoSans" , sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 96.552% */
}

.region-card-area .content-area.text-only .text-area .title-area span {
  text-align: left;
}

.region-card-area.vertical {
  width: 460px;
  height: 845px;
}

.region-card-area.horizontal {
  width: 950px;
  height: 484px;
}

@media (max-width: 768px) {
  .region-card-area.horizontal {
    width: 100%;
    height: 100%;
  }
  .region-card-area.vertical {
    width: 100%;
    height: 100%;
  }
  .region-card-area.text-only {
    width: 100% !important;
    height: 100% !important;
  }
}

.region-card-area.text-only {
  width: 460px;
  height: 484px;
}
</style>
