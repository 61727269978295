<template>
  <div class="pollutant-management-border-box" v-if="nationCode == '_US' || nationCode == '_GL'">
    <PollutantManagementBorderBoxContent
    :text="content1.text"
    :unit="content1.unit"
    />
    <div class="line"></div>
    <PollutantManagementBorderBoxContent
    :text="content2.text"
    :unit="content2.unit"
    />
  </div>
<!-- JP -->
  <div class="pollutant-management-border-box" v-else-if="nationCode == '_JP'">
    <PollutantManagementBorderBoxContent
      :text="content1Jp.text"
      :unit="content1Jp.unit"
    />
    <div class="line"></div>
    <PollutantManagementBorderBoxContent
      :text="content2Jp.text"
      :unit="content2Jp.unit"
    />
  </div>
<!-- RU -->
  <div class="pollutant-management-border-box" v-else-if="nationCode == '_RU'">
    <PollutantManagementBorderBoxContent
      :text="content1Ru.text"
      :unit="content1Ru.unit"
    />
    <div class="line"></div>
    <PollutantManagementBorderBoxContent
      :text="content2Ru.text"
      :unit="content2Ru.unit"
    />
  </div>
<!-- CN -->
  <div class="pollutant-management-border-box" v-else-if="nationCode == '_CN'">
    <PollutantManagementBorderBoxContent
      :text="content1Cn.text"
      :unit="content1Cn.unit"
    />
    <div class="line"></div>
    <PollutantManagementBorderBoxContent
      :text="content2Cn.text"
      :unit="content2Cn.unit"
    />
  </div>
</template>

<script>
import PollutantManagementBorderBoxContent from './PollutantManagementBorderBoxContent.vue'

export default {
  components: {
    PollutantManagementBorderBoxContent
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      content1: {
        text: 'Goal of <b>Air</b>\nPollutant Emissions',
        unit: '<b>111.56</b> ton'
      },
      content1Jp: {
        text: '大気汚染物質排出目標',
        unit: '<b>111.56</b> ton'
      },
      content1Ru: {
        text: 'Выбросы загрязняющих\nвеществ (в воздух)',
        unit: '<b>111.56</b> ton'
      },
      content1Cn: {
        text: '大气污染物排放目标',
        unit: '<b>111.56</b> ton'
      },
      content2: {
        text: 'Goal of <b>Water</b>\nPollutant Discharges',
        unit: '<b>1.146</b> ton'
      },
      content2Jp: {
        text: '水質汚染物質排出目標',
        unit: '<b>1.146</b> ton'
      },
      content2Ru: {
        text: 'Сбросы загрязняющих\nвеществ (в воду)',
        unit: '<b>1.146</b> ton'
      },
      content2Cn: {
        text: '水污染物排放目标',
        unit: '<b>1.146</b> ton'
      },
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style>
.pollutant-management-border-box {
  display: flex;
  padding: 50px 70px;
  align-items: flex-start;
  gap: 50px;
  flex: 1 0 0;
  border-radius: 20px;
  border-top: 2px solid #2C2C2D;
  background: #F5F5F5;
}

.pollutant-management-border-box .line {
  width: 1px;
  height: 50px;
  background: #B6B6B6;
}

@media (max-width: 768px) {
  .pollutant-management-border-box {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .pollutant-management-border-box .line {
    width: 100%;
    height: 1px;
  }
}

</style>
