var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "list-area" }, [
    _c(
      "ul",
      { staticClass: "square-list" },
      _vm._l(_vm.contentList, function (item) {
        return _c("li", { key: item.index }, [
          _c("span", { domProps: { innerHTML: _vm._s(item.content) } }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }