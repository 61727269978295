<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content cntu-view">
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)"></li>
          <li>Contact</li>
          <li>Our Showrooms</li>
        </ul>
      </div>
      <div class="sub_visual_bg full-width">
        <div class="inner_padding showroom">
          <h1>Our Showrooms</h1>
          <div class="showroom-wrap">
            <!-- 왼쪽버튼 -->
            <div class="showroom-tabs">
              <div :class="['showroom-tab', { on: selectedTab === index }]" v-for="(item,index) in bannerList"
                   :key="index" @click="handleTapClick(index)">
                <div>
                  <span v-html="item.attribute.city_state"/>
                  <p v-html="item.attribute.title"></p>
                </div>
                <div>
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_666.png" class="room-arrow-c">
                  <img src="https://img.lxhausys.com/2024/images/support/right_arrow_999.png" class="room-arrow">
                </div>
              </div>
            </div>
            <div class="showroom-contents">
              <!-- content1 -->
              <div :class="['showroom-content', `showroom${index + 1}`]" v-for="(banner,index) in bannerList"
                   :key="index" :ref="'content-'+index">
                <div class="showroom-info">
                  <p>{{ banner.attribute.city_state }}</p>
                  <h4>{{ banner.attribute.title }}</h4>
                  <div class="showroom-info-content">
                    <div class="showroom-more card1">
                      <p>{{ banner.attribute.addr1 }}<br>
                        {{ banner.attribute.addr2 }}</p>
                      <a href="javascript:void(0)" @click="goDrtn(banner.attribute.map_url)">
                        <span>Directions</span>
                        <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                      </a>
                    </div>
                    <div>
                    <div class="showroom-more card2">
                      <p>{{ banner.attribute.oper_date }}<br>
                        {{ banner.attribute.oper_time }}</p>
                      <div v-if="'appointment_url' in banner.attribute">
                        <a href="javascript:void(0)" @click="goDrtn(banner.attribute.appointment_url)">
                          <span>Book an Appointment</span>
                          <img src="https://img.lxhausys.com/2024/images/support/right_arrow_222.png" alt="arrow">
                        </a>
                      </div>
                    </div>
                    <div class="showroom-more card3">
                      <div>
                        <p>{{ banner.attribute.phone_num }}</p>
                        <p>{{ banner.attribute.email }}</p>
                      </div>
                      <div class="showroom-detail" @click="openService(banner)" v-if="'serv_offered' in banner.attribute || 'dis_products' in banner.attribute">
                        <span>Service Offered</span>
                        <img src="https://img.lxhausys.com/2024/images/support/showroom_info.png" alt="img">
                      </div>
                    </div>
                  </div>
                    <div class="offered" :class="{on: banner.isClicked}">
                      <div>
                        <p>SERVICE OFFERED​</p>
                        <div class="offered_close">
                          <img src="https://img.lxhausys.com/2024/images/svg_icon/icon_pop_close.svg" alt="close"
                               @click="closeService(banner)">
                        </div>
                      </div>
                      <span v-html="banner.attribute.serv_offered" ></span>
                      <p>DISPLAYED PRODUCTS</p>
                      <span v-html="banner.attribute.dis_products"></span>
                    </div>
                  </div>
                </div>
                 <div class="showroom-carousel" v-if="banner.imgList.length > 0">
                  <div v-if="banner.imgList.length > 1">
                    <VueSlickCarousel :swipe-to-slide="true" v-bind="showroomSetting">
                      <div v-for="(img, idx) in banner.imgList" :key="idx">
                        <div><img :src="$imagePath + img.subThumbnailPath" :alt="img.subThumbnailName"></div>
                      </div>
                    </VueSlickCarousel>
                  </div>
                  <div v-else>
                    <img :src="$imagePath + banner.imgList[0].subThumbnailPath" :alt="banner.imgList[0].subThumbnailName">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
// import Carousel from "@/components/product/carousel/index.vue";
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import ProductService from '@/server/product.service'
import QuickMenuComponent from '@/components/Layout/Quick'
import VueSlickCarousel from 'vue-slick-carousel'
import { getContent } from '@/server/content'
import CategoryService from '@/server/category.service'

export default {
  name: 'ourshowroom',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    QuickMenuComponent,
    // Carousel,
    VueSlickCarousel
  },
  data () {
    return {
      cateList: [],
      pageObejct: {},
      bannerList: [],
      nationCode: '',
      locationCode: '',
      categoryCode: '',
      offered: '',
      operations: [],
      rendering: '',
      isLoading: true,
      isClicked: false,
      selectedTab: 0,
      showroomSetting: {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        arrows: false,
        dots: true,
        swipeToSlide: true
        // dotsClass:"slick-dots custom-dot-class",
      },
      countryCode: '',
      countryCodeSub: ''
    }
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  computed: {},
  created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)
  },
  async mounted () {
    try {
      const categoryCode = await CategoryService.getCategoryCode('SHOWROOM' + this.nationCode)
      console.log(categoryCode.data.object.id)
      const { data: contentData } = await ProductService.getContentList(categoryCode.data.object.id)
      contentData.list.sort((a, b) => a.content.sort - b.content.sort)
      contentData.list.forEach(banner => {
        this.imgList(banner)
      })

      this.bannerList = this.bannerList.concat(contentData.list)
      console.log(this.bannerList)
    } catch (error) {
      console.error('error:', error)
    }
  },
  methods: {
    gotolink (url) {
      location.href = url
    },
    handleTapClick (item) {
      this.selectedTab = item
      this.scrollToContent(item)
    },
    scrollToContent (index) {
      this.$nextTick(() => {
        const contentElement = this.$refs['content-' + index][0]
        if (contentElement) {
          const offsetTop = contentElement.offsetTop
          window.scrollTo({
            top: offsetTop - 108, // Adjust the offset as needed
            behavior: 'smooth'
          })
        }
      })
    },
    openService (banner) {
      this.$set(banner, 'isClicked', true)
    },
    closeService (banner) {
      this.$set(banner, 'isClicked', false)
    },
    parseCImg (c_img) {
      if (!c_img) return []
      try {
        return JSON.parse(c_img)
      } catch (err) {
        console.log(err)
        return []
      }
    },
    imgList (banner) {
      banner.imgList = this.parseCImg(banner.attribute.c_img)
    },
    goLink (url) {
      location.href = url
    },
    goDrtn (url) {
      window.open(url, '_blank')
    },
    isMobile () {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    }
  }
}

/*
$(document).ready(function(){
   /!*slide
  $('.showroom-slider.newyork').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots:true,
    prevArrow: $('.slider-prev'),
        nextArrow: $('.slider-next'),
        customPaging: function(slider, i){
          return '<i class="slider-dot" />';
        },
    // prevArrow: $('.slide-pd .arrow-prev'),
    // nextArrow: $('.slide-pd .arrow-next'),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });*!/

  //tab
  /!*$('.showroom-tab').click(function(){
    $(this).addClass('on')
    $(this).siblings().removeClass('on')

    // $('.showroom-content').removeClass('on')
    // $('#' + $(this).attr('data-alt')).addClass('on')
  })*!/

 //nav
   /!*$('.showroom-tab.tab1').click(function(){
    var offset = $(".showroom-content.showroom1").offset();
    $("html").animate({ scrollTop: offset.top - 108}, 400);
   })*!/
})
*/
</script>
