<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>

    <!-- content -->
    <div class="content cntu-view fasr-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home" v-hover-cursor @click="gotolink(`/${countryCodeSub.toLowerCase()}/index`)">{{ $t("findASalesRep.text.FC0040_001") }}</li>
          <li>{{ $t("findASalesRep.text.FC0040_002") }}</li>
          <li>{{ $t("findASalesRep.text.FC0040_003") }}</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Find a Sales Representative) -->
      <div class="container cntu-box">
        <div class="w-con-02">
          <h1 class="tt-1 mb-88 tac inner">
            {{ $t("findASalesRep.text.FC0040_003") }}
          </h1>
          <div v-if="siteCountryCd === '_GL'" class="find_gl_wrap">
            <div class="find-search">
              <div class="tbl-input">
                <table>
                  <tbody>
                    <tr>
                      <td class="find_search_padding">
                        <label class="tbl_label">Product</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel" >
                            <span class="placeholder">{{ prdText }}</span>
                          </button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="item in productList" :key="item.id">
                                <button type="button" @click="selectPrdOption(item.text)">
                                  {{ item.text }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="find_search_padding">
                        <label class="tbl_label">Area</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel" @click="toggleDropdown">
                            <span class="placeholder">{{ areaName }}</span>
                          </button>
                          <div class="sel-list">
                            <ul class="box">
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('Russia & CIS')"
                                >
                                  {{ $t("contactus.text.ru_cis") }}
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('Latin America')"
                                >
                                  {{ $t("contactus.text.latin_ameria") }}
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('Middle East')"
                                >
                                  {{ $t("contactus.text.middle_east") }}
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('Oceania')"
                                >
                                  {{ $t("contactus.text.oceania") }}
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('Asia')"
                                >
                                  {{ $t("contactus.text.south_asia") }}
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('Japan')"
                                >
                                  {{ $t("contactus.text.japan") }}
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  @click="categoryType('China')"
                                >
                                  {{ $t("contactus.text.china") }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="find_search_padding">
                        <label class="tbl_label">Country</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel" @click="toggleDropdown">
                            <span class="placeholder">{{ countryNm }}</span>
                          </button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="country in glCountryList">
                                <button
                                  type="button"
                                  @click="selectCountry(country.id.value, country.text)"
                                >
                                  {{ country.text }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="more-box">
                <button
                  type="button"
                  id="searchBtn"
                  @click="search"
                  class="btn_search h50"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="find-address-wrap">
              <div
                v-for="(row, rowIndex) in groupedSearchResult"
                :key="rowIndex"
                class="row"
              >
                <div
                  class="col"
                  v-for="(item, colIndex) in row"
                  :key="colIndex"
                >
                  <div class="find-address">
                    <div class="title-wrap">
                      <h5>{{ item.repName }}</h5>
                      <div>
                        <img src="../../../public/images/icon-mail.png" alt="mail"/>
                        <a :href="'mailto:' + item.repEmail">{{ item.repEmail }}</a>
                      </div>
                    </div>
                    <div class="content-wrap">
                      <div>
                        <p>Product</p>
                        <div class="badges">
                        <div
                          v-for="(badge, badgeIndex) in item.badgeList"
                          :key="badgeIndex"
                          :class="getBadgeClass(badge)"
                        >
                          {{ badge }}
                        </div>
                      </div>
                      </div>
                      <div>
                        <p>Area</p>
                        <span>{{ item.areaName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="siteCountryCd === '_US'" class="find_us_wrap">
            <div class="find-search">
              <div class="tbl-input">
                <table>
                  <tbody>
                    <tr>
                      <td class="find_search_padding">
                        <label class="tbl_label">Product</label>
                        <div class="sel-box">
                          <button type="button" class="btn-sel" @click="toggleDropdown">
                            <span class="placeholder">{{ prdText }}</span>
                          </button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="item in productList" :key="item.id">
                                <button type="button" @click="selectPrdOption(item.text)">
                                  {{ item.text }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td class="find_search_padding">
                        <label class="tbl_label"
                          >Search by Address, City, State, Zipcode</label
                        >
                        <input
                          type="text"
                          class="inp-text"
                          placeholder="Placeholder"
                          ref="textInput"
                          @keyup.enter="search"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="more-box">
                <button
                  type="button"
                  id="searchBtn"
                  @click="search"
                  class="btn_search h50"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="find-address-wrap">
              <div
                v-for="(row, rowIndex) in groupedSearchResult"
                :key="rowIndex"
                class="row"
              >
                <div
                  class="col"
                  v-for="(item, colIndex) in row"
                  :key="colIndex"
                >
                  <div class="find-address">
                    <div class="title-wrap">
                      <h5>{{ item.repName }}</h5>
                      <h6>{{ item.repTypeName }}</h6>
                      <div>
                        <img src="../../../public/images/icon-mail.png" alt="mail"/>
                        <a :href="'mailto:' + item.repEmail">{{ item.repEmail }}</a>
                      </div>
                    </div>
                    <div class="content-wrap">
                      <div v-if="item.inquiryTypeName">
                        <p>For</p>
                        <span>{{ item.inquiryTypeName }}</span>
                      </div>
                      <div>
                        <p>Product</p>
                        <div class="badges">
                        <div
                          v-for="(badge, badgeIndex) in item.badgeList"
                          :key="badgeIndex"
                          :class="getBadgeClass(badge)"
                        >
                          {{ badge }}
                        </div>
                      </div>
                      </div>
                      <div>
                        <p class="resion_toggle">Region</p>
                        <span class="region_txt">{{ item.stateCdName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
<!--          <div v-if="siteCountryCd === '_CF' || siteCountryCd === '_CA'" class="find_ca_wrap">
            <div class="find-search">
              <div class="tbl-input">
                <table>
                  <tbody>
                  <tr>
                    <td class="find_search_padding">
                      <label class="tbl_label" v-if="siteCountryCd === '_CA'">Product</label>
                      <label class="tbl_label" v-if="siteCountryCd === '_CF'">Produit</label>
                      <div class="sel-box">
                        <button type="button" class="btn-sel" @click="toggleDropdown">
                          <span class="placeholder">{{ prdText }}</span>
                        </button>
                        <div class="sel-list">
                          <ul class="box">
                            <li v-for="item in productList" :key="item.id">
                              <button type="button" @click="selectPrdOption(item.text)">
                                {{ item.text }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td class="find_search_padding">
                      <label class="tbl_label" v-if="siteCountryCd === '_CA'">Province/Territory</label>
                      <label class="tbl_label" v-if="siteCountryCd === '_CF'">Province/Territoire</label>
                      <div class="sel-box">
                        <button type="button" class="btn-sel" >
                          <span class="placeholder">{{ optionText }}</span>
                        </button>
                        <div class="sel-list">
                          <ul class="box">
                            <li v-for="item in optionList" :key="item.id">
                              <button type="button" @click="selectOption(item.text)">
                                {{ item.text }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="more-box">
                <button
                  type="button"
                  id="searchBtn"
                  @click="search"
                  class="btn_search h50"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="find-address-wrap">
              <div
                v-for="(row, rowIndex) in groupedSearchResult"
                :key="rowIndex"
                class="row"
              >
                <div
                  class="col"
                  v-for="(item, colIndex) in row"
                  :key="colIndex"
                >
                  <div class="find-address">
                    <div class="title-wrap">
                      <h5>{{ item.repName }}</h5>
                      <h6>{{ item.repTypeName }}</h6>
                      <div>
                        <img src="../../../public/images/icon-mail.png" alt="mail"/>
                        <a :href="'mailto:' + item.repEmail">{{ item.repEmail }}</a>
                      </div>
                    </div>
                    <div class="content-wrap">
                      <div v-if="item.inquiryTypeName">
                        <p>For</p>
                        <span>{{ item.inquiryTypeName }}</span>
                      </div>
                      <div>
                        <p>Product</p>
                        <div class="badges">
                          <div
                            v-for="(badge, badgeIndex) in item.badgeList"
                            :key="badgeIndex"
                            :class="getBadgeClass(badge)"
                          >
                            {{ badge }}
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="resion_toggle">Region</p>
                        <span class="region_txt">{{ item.stateCdName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div></div>
          <button
            type="button"
            class="btn_learn_more"
            v-show="isMore"
            @click="showMore()"
          >
            <span class="txt">Load more</span>
            <img src="../../../public/images/svg_icon/icon_plus.svg" alt="plus"/>
          </button>
          <!-- no_result -->
          <div v-if="showNoResult" class="no_result">
            <img src="https://img.lxhausys.com/public/images/support/no_result.png" alt="noresult" />
            <p>No Result found</p>
            <span>If you do not find what you are looking for, please</span>
            <div class="where_noresult">
              <div class="more-box contact">
                <a href="support/contactus">
                  <button>Contact Us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (Find a Sales Representative) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import supportService from '@/server/support.service'
import getDictionaryList from '@/server/support.service'
import getCountryList from '@/server/us.service'
import QuickMenuComponent from '@/components/Layout/Quick'

export default {
  name: 'app',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent,
    QuickMenuComponent
  },
  data () {
    return {
      optionText: '',
      optionList: [],
      subOptionList: [],
      selectedOption: '',
      productList: [],
      badgeList: [],
      glCountryList: [],
      text: '',
      resultCount: '',
      isMore: false,
      showNoResult: false,
      pageSize: 6,
      pageIndex: 1,
      searchResult: [],
      areaName: this.$t('contactus.text.select'),
      countryCd: '',
      selectedCountryCd: '',
      siteCountryCd: '',
      countryNm: this.$t('contactus.text.select'),
      selectedPrd: '',
      prdText: this.$t('contactus.text.select'),
      selectedArea: '',
      areaCd: '',
      searchWord: '',
      repsDiv: [],
      isOpen: false,
      dictionaryCondition: {
        operator: 'AND',
        list: [
          {
            operator: 'OR',
            values: []
          }
        ]
      },
      countryCode: '',
      countryCodeSub: ''
    }
  },
  created () {
    const t = this
    t.nationCode = t.$countryCode
    t.countryCodeSub = t.nationCode.substring(1)

    this.siteCountryCd = this.$countryCode
  },
  directives: {
    hoverCursor: {
      bind (el) {
        el.addEventListener('mouseover', () => {
          el.style.cursor = 'pointer'
        })
        el.addEventListener('mouseout', () => {
          el.style.cursor = 'default'
        })
      }
    }
  },

  async mounted () {
    const _this = this
    const productTypeRes = await this.fetchProductType(this.siteCountryCd);
    this.productList = productTypeRes.data
    const optionRes = await this.fetchOptionList(this.siteCountryCd);
    this.optionList = optionRes.data
    // 데이터 사전 불러오기
    const dictionary = await getDictionaryList.getDictionaryValueList([
      'AREA_RUCIS_PRO',
      'AREA_LAAMERICA_PRO',
      'AREA_MIDDLEEAST_PRO',
      'AREA_OCEANIA_PRO',
      'AREA_SOUTHASIA_PRO',
      'AREA_JAPAN_PRO',
      'AREA_CHINA_PRO'
    ])
    _this.proRuCisArea = dictionary.data.dicList[0].list // Pro Inquiry Type (Professionals Russia & CIS 조회 종류)
    _this.proLatinAmericaArea = dictionary.data.dicList[1].list // Pro Inquiry Type (Professionals Latin America 조회 종류)
    _this.proMiddleEastArea = dictionary.data.dicList[2].list // Pro Inquiry Type (Professionals Middle East 조회 종류)
    _this.proOceaniaArea = dictionary.data.dicList[3].list // Pro Inquiry Type (Professionals Oceania 조회 종류)
    _this.proSouthAsiaArea = dictionary.data.dicList[4].list // Pro Inquiry Type (Professionals Southeast Asia 조회 종류)
    _this.proJapanArea = dictionary.data.dicList[5].list // Pro Inquiry Type (Professionals Japan 조회 종류)
    _this.proChinaArea = dictionary.data.dicList[6].list // Pro Inquiry Type (Professionals China 조회 종류)
  },
  computed: {
    groupedSearchResult () {
      const chunkSize = 2
      const grouped = []
      for (let i = 0; i < this.searchResult.length; i += chunkSize) {
        grouped.push(this.searchResult.slice(i, i + chunkSize))
      }
      return grouped
    }
  },
  methods: {
    async fetchProductType(countryCode) {
      const dictionaryMap = {
        '_GL': 'INQ_OTHERS_GL',
        '_US': 'FND_SREP_PDT_US',
        '_JP': 'FND_SREP_PDT_JP',
        '_CN': 'FND_SREP_PDT_CN',
        '_RU': 'FND_SREP_PDT_RU',
        '_CF': 'FND_SREP_PDT_FR_CA',
        '_CA': 'FND_SREP_PDT_EN_CA'
      };

      const dictionaryKey = dictionaryMap[countryCode];
      if (dictionaryKey) {
        return await getDictionaryList.getDictionaryList(dictionaryKey);
      } else {
        return null;
      }
    },
    async fetchOptionList(countryCode) {
      const dictionaryMap = {
        '_GL': 'INQ_OTHERS_GL',
        '_US': 'FND_SREP_PDT_US',
        '_JP': 'FND_SREP_PDT_JP',
        '_CN': 'FND_SREP_PDT_CN',
        '_RU': 'FND_SREP_PDT_RU',
        '_CF': 'FND_SREP_OPT_CA_FR',
        '_CA': 'FND_SREP_OPT_CA_EN'
      };
      const dictionaryKey = dictionaryMap[countryCode];
      if (dictionaryKey) {
        return await getDictionaryList.getDictionaryList(dictionaryKey);
      } else {
        return null;
      }
    },
/*    async fetchSubOptionList(parentOption) {
      const dictionaryMap = {
      };
    },*/
    gotolink (url) {
      location.href = url
    },
    toggleDropdown () {
      this.isOpen = !this.isOpen
    },
    async categoryType (type) {
      const _this = this
      this.isOpen = false
      _this.areaName = type
      _this.countryNm = this.$t('contactus.text.select')
      _this.selectedCountryCd = ''
      if (type == 'Asia') {
        _this.glCountryList = _this.proSouthAsiaArea
        this.areaCd = 'AS'
      } else if (type == 'Middle East') {
        _this.glCountryList = _this.proMiddleEastArea
        this.areaCd = 'ME'
      } else if (type == 'Latin America') {
        _this.glCountryList = _this.proLatinAmericaArea
        this.areaCd = 'SA'
      } else if (type == 'Oceania') {
        _this.glCountryList = _this.proOceaniaArea
        this.areaCd = 'OC'
      } else if (type == 'Europe') {
        _this.glCountryList = _this.proAfricaArea // ???
        this.areaCd = 'EU'
      } else if (type == 'Russia & CIS') {
        _this.glCountryList = _this.proRuCisArea
        this.areaCd = 'RU'
      } else if (type == 'China') {
        _this.glCountryList = _this.proChinaArea
        this.areaCd = 'CN'
      } else if (type == 'Japan') {
        _this.glCountryList = _this.proJapanArea
        this.areaCd = 'JP'
      }
    },

    getBadgeClass (badge) {
      // badge 값에 따라 클래스명을 동적으로 반환
      const classMap = {
        HFLOR: 'badge hflor',
        VIATERA: 'badge viatera',
        BENIF: 'badge benif',
        HIMACS: 'badge himacs',
        TERACANTO: 'badge teracanto',
        Automotive: 'badge etc',
        VIZUON: 'badge etc',
        'Deco Foil': 'badge etc',
        'Exterior Foil': 'badge etc',
        'Home Appliance Surface Film': 'badge etc'
      }
      return classMap[badge] || 'badge'
    },
    selectPrdOption (option) {
      this.isOpen = false
      this.prdText = option
      this.selectedPrd = option
      this.areaName = this.$t('contactus.text.select')
      this.areaCd = ''
      this.countryNm = this.$t('contactus.text.select')
      this.selectedCountryCd = ''
    },
    selectOption (option) {
      this.isOpen = false
      this.optionText = option // 보여지는값
      this.selectedOption = option // 조회할 파라미터 값
    },
    async selectCountry (code, value) {
      const countryListCd = await getCountryList.getGLCountryCd(value)
      this.selectedCountryCd = countryListCd.data[0].countryCd
      this.isOpen = false
      this.countryNm = value
    },

    setRepsDiv () {
      this.repsDiv = []
      if (!this.selectedPrd && this.searchWord) {
        this.repsDiv.push('HFLOR', 'BORTE', 'TM', 'CSM')
      }
      else if (this.selectedPrd) {
        if (this.selectedPrd === 'HFLOR') {
          this.repsDiv.push('HFLOR', 'CSM')
        } else if (['VIATERA', 'HIMACS', 'TERACANTO'].includes(this.selectedPrd)) {
          this.repsDiv.push('TM', 'CSM')
        } else if (this.selectedPrd === 'BORTE') {
          this.repsDiv.push('BORTE')
        }
      }
    },

    async search () {
      this.pageIndex = 1
      this.pageSize = 6
      this.searchWord = this.$refs.textInput
        ? this.$refs.textInput.value.trim()
        : ''

      const isUS = this.siteCountryCd === '_US'
      const isGL = this.siteCountryCd === '_GL'

      if (isUS) {
        if (!this.selectedPrd && !this.searchWord) {
          this.searchResult = []
          this.resultCount = 0
          this.isMore = false
        } else {
          this.setRepsDiv()
          await this.getUSData(true)
        }
      }

      if (isGL) {
        if (!this.selectedPrd && !this.areaCd && !this.selectedCountryCd) {
          this.searchResult = []
          this.resultCount = 0
          this.isMore = false
        } else {
          await this.getGLData(true)
        }
      }
      this.checkMore()

      // 검색 완료 시 데이터 레이어에 정보 푸시
      const productToSend = this.selectedPrd
      const areaToSend = this.areaName === this.$t('contactus.text.select') ? '' : this.areaName
      const countryToSend = this.countryNm === this.$t('contactus.text.select') ? '' : this.countryNm
      let searchCondition = ''
      if (isGL) {
        searchCondition = [areaToSend, countryToSend].filter(Boolean).join('|')
      } else if (isUS) {
        searchCondition = this.searchWord
      }
      this.pushDataLayerEvent(productToSend, searchCondition)
    },

    pushDataLayerEvent (productToSend, searchCondition) {
      dataLayer.push({
        event: 'findaSalesRepComp',
        sales_rep_product: productToSend || '',
        sales_rep_search_con: searchCondition || ''
      })
    },

    async showMore () {
      this.pageIndex += 1 // 더보기 -> 페이지 인덱스 증가
      const res = await this.fetchMoreData()
      this.appendSearchResult(res.data.result)
      this.checkMore()
    },

    async fetchMoreData () {
      const siteType = this.siteCountryCd.slice(1).toUpperCase();
      if (this.siteCountryCd === '_US') {
        const res = await supportService.getListFindRepUs(
          this.selectedPrd,
          this.searchWord,
          this.repsDiv,
          siteType,
          this.pageIndex,
          this.pageSize
        )
        return res
      }
      if (this.siteCountryCd === '_GL') {
        const res = await supportService.getRepsList(
          this.selectedPrd,
          this.areaCd,
          this.selectedCountryCd,
          siteType,
          this.pageIndex,
          this.pageSize
        )
        return res
      }
    },

    async getUSData (isNewSearch = false) {
      const siteType = this.siteCountryCd.slice(1).toUpperCase();
      const res = await supportService.getListFindRepUs(
        this.selectedPrd,
        this.searchWord,
        this.repsDiv,
        siteType,
        this.pageIndex,
        this.pageSize
      )
      this.processSearchResult(res.data.result, isNewSearch)
    },

    async getGLData (isNewSearch = false) {
      const siteType = this.siteCountryCd.slice(1).toUpperCase();
      const res = await supportService.getRepsList(
        this.selectedPrd,
        this.areaCd,
        this.selectedCountryCd,
        siteType,
        this.pageIndex,
        this.pageSize
      )
      this.processSearchResult(res.data.result, isNewSearch)
    },

    processSearchResult (result, isNewSearch = false) {
      const list = result.list || []
      this.resultCount = result.totalCount

      const newResults = list.map((item) => {
        return {
          ...item,
          jobTitle: item.jobTitle ? item.jobTitle.split(' - ')[0] : '',
          badgeList: item.categoryCode ? item.categoryCode.split(',') : []
        }
      })

      if (isNewSearch) {
        this.searchResult = newResults // 새로운 검색이면 기존 검색 결과 초기화
      } else {
        this.searchResult = [...this.searchResult, ...newResults] // 기존 결과에 새 결과를 추가
      }
      this.$nextTick(function () {
        $('.region_txt').each(function () {
          if (this.offsetHeight >= (this.scrollHeight - 3)) {
            $(this).addClass('on')
          }
        })
      })
    },

    appendSearchResult (result) {
      this.processSearchResult(result)
    },

    checkMore () {
      this.isMore = this.resultCount > this.searchResult.length
      this.showNoResult = this.resultCount === 0
    }
  }
}
</script>
