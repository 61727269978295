<template>
  <!--  @mouseleave="gnbSlideUp('gnb-section')" -->
  <div class="gnb-wrap">
    <!-- pc gnb -->
    <div class="gnb-section">
      <div :class="['gnb-all', 'gmlist', 'mlist-'+menuTree.id]" v-for="menuTree in menuTree.data" :key="menuTree.id">
        <!-- gnb-title -->
        <div class="gnb-tab-btns">
          <a :href="getHref(country)" class="all-view-btn" v-if="menuTree.id === 'prod' || menuTree.id === 'prod'+country ">
            <div><img src="https://img.lxhausys.com/images/header/old-gnb/gnb/view_all.png" alt="gnb view all"></div>
            <span v-if="country === '_GL'">ALL COLORS</span>
            <span v-if="country === '_US'">ALL COLORS</span>
            <span v-if="country === '_CA'">ALL COLORS</span>
            <span v-if="country === '_CF'">Toutes les couleurs</span>
            <span v-if="country === '_CN'">所有颜色</span>
            <span v-if="country === '_RU'">Все цвета</span>
            <span v-if="country === '_JP'">すべての柄</span>
          </a>
          <ul class="gnb-tab-wrap">
            <li class="pc-gnb-tab" v-for="(subMenu, index) in menuTree.children" :key="subMenu.id" :class="{ active: index === 0 }" :data-alt="subMenu.id">
              <div v-if="subMenu.children && subMenu.children.length > 0">
                <h4 v-html="subMenu.title"></h4>
                <p v-html="subMenu.subTitle"></p>
              </div>
              <div v-else>
                <a :href="subMenu.redirect || '#'" :target="subMenu.openOption0 === '001' ? '_self' : '_blank'">
                  <div class="title-image-wrapper" style="display: flex; align-items: center;">
                    <h4 v-html="subMenu.title"></h4>
                    <img v-if="subMenu.openOption0 !== '001'" src="https://img.lxhausys.com/public/images/icon/icon_blog.png" style="margin-left: 2ch;">
                  </div>
                  <p v-html="subMenu.subTitle"></p>
                </a>
              </div>
              <div v-if="subMenu.children && subMenu.children.length > 0">
                <img src="https://img.lxhausys.com/images/header/old-gnb/gnb/gnb_Arr_right01.png" alt="arrow">
              </div>
            </li>
          </ul>
        </div>
        <div class="gnb-tabs">
          <!-- gnb-contents -->
          <div class="gnb-contents">
            <div v-for="(subMenu, index) in menuTree.children" :key="subMenu.id" :class="{ 'gnb-content': true, active: index === 0, [subMenu.id]:true }" :id="subMenu.id">
              <div>
                <div class="content-text">
                  <div class="content-text-about">
                    <div class="depth2_about_title" v-if="subMenu.children && subMenu.children.length > 0
                    && (menuTree.id === 'prod' || menuTree.id === 'prod_CA' || menuTree.id === 'prod_CF' || menuTree.id === 'prod_CN' || menuTree.id === 'prod_JP' || menuTree.id === 'prod_RU')">
                      <a :href="subMenu.children[0].redirect || '#none'" class="about-squre" v-html="subMenu.children[0].title"></a>
                    </div>
                    <div class="content-detail-about">
                      <div v-for="(subChildrenMenu, index) in subMenu.children" :key="subChildrenMenu.id" v-if="index !== 0
                      && (menuTree.id === 'prod'|| menuTree.id === 'prod_CA' || menuTree.id === 'prod_CF' || menuTree.id === 'prod_CN' || menuTree.id === 'prod_JP' || menuTree.id === 'prod_RU')
                      && subChildrenMenu.id !== 'tile' && subChildrenMenu.id !== 'tile_CA' && subChildrenMenu.id !== 'tile_CF' && subChildrenMenu.id !== 'tile_JP' && subChildrenMenu.id !== 'tile_CN' && subChildrenMenu.id !== 'tile_RU'
                      && subChildrenMenu.id !== 'sheet' && subChildrenMenu.id !== 'sheet_CA' && subChildrenMenu.id !== 'sheet_CF' && subChildrenMenu.id !== 'sheet_JP' && subChildrenMenu.id !== 'sheet_CN' && subChildrenMenu.id !== 'sheet_RU'
                      && subChildrenMenu.id !== 'Application' && subChildrenMenu.id !== 'Application_CA' && subChildrenMenu.id !== 'Application_CF' && subChildrenMenu.id !== 'Application_JP' && subChildrenMenu.id !== 'Application_CN' && subChildrenMenu.id !== 'Application_RU'">
                        <a v-if="subChildrenMenu.children && subChildrenMenu.children.length > 0" href="#" class="dep-04-tab" @click.prevent="setActiveMenu(subChildrenMenu.id)">
                          <p v-html="subChildrenMenu.title"></p>
                        </a>
                        <a v-else :href="subChildrenMenu.redirect || '#'">
                          <p v-html="subChildrenMenu.title"></p>
                        </a>
                      </div>
                      <div v-for="(subChildrenMenu, index) in subMenu.children" :key="subChildrenMenu.id"
                           v-if="menuTree.id !== 'prod' && menuTree.id !== 'prod_CA' && menuTree.id !== 'prod_CF' && menuTree.id !== 'prod_CN' && menuTree.id !== 'prod_JP' && menuTree.id !== 'prod_RU'">
                        <a v-if="subChildrenMenu.children && subChildrenMenu.children.length > 0" href="#" class="dep-04-tab" @click.prevent="setActiveMenu(subChildrenMenu.id)" >
                          <p v-html="subChildrenMenu.title"></p>
                        </a>
                        <a v-else :href="subChildrenMenu.redirect || '#'">
                          <p v-html="subChildrenMenu.title"></p>
                        </a>
                      </div>
                      <!-- 스타일만들기 위한 임시 p태그 -->
                      <!-- <div v-for="(subChildrenMenu, index) in subMenu.children" :key="subChildrenMenu.id" v-if="menuTree.id !== 'prod'">
                        <p class="dep-03-tab">{{ subChildrenMenu.title }}</p>
                      </div>  -->
                    </div>
                  </div>

                  <!-- TILE, SHEET는 HELOR에만 노출 -->
                  <div class="content-text-more"
                      v-if="subMenu.id === 'HFLOR'
                        || subMenu.id === 'HFLOR_CA'
                        || subMenu.id === 'HFLOR_CF'
                        || subMenu.id === 'HFLOR_CN'
                        || subMenu.id === 'HFLOR_JP'
                        || subMenu.id === 'HFLOR_RU'">
                    <div>
                      <div>
                        <span v-if="subMenu.id === 'HFLOR_JP'">タイル</span>
                        <span v-else-if="subMenu.id === 'HFLOR_RU'">Плитка</span>
                        <span v-else>TILE</span>
                      </div>
                      <div class="content-detail-more">
                        <div v-for="tileList in menuSubTileTree.data" :key="tileList.id">
                          <a :href="tileList.redirect || '#'">
                            <p v-html="tileList.title"></p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span v-if="subMenu.id === 'HFLOR_JP'">シート</span>
                        <span v-else-if="subMenu.id === 'HFLOR_RU'">Лист</span>
                        <span v-else>SHEET</span>
                      </div>
                      <div class="content-detail-more">
                        <div v-for="sheetList in menuSubSheetTree.data" :key="sheetList.id">
                          <a :href="sheetList.redirect || '#'">
                            <p v-html="sheetList.title"></p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="content-text-more"
                       v-if="subMenu.id === 'vizuon'
                       || subMenu.id === 'vizuon_CA'
                       || subMenu.id === 'vizuon_CF'
                       || subMenu.id === 'vizuon_CN'
                       || subMenu.id === 'vizuon_JP'
                       || subMenu.id === 'vizuon_RU'">
                    <div>
                      <div>
                        <span v-if="subMenu.id === 'vizuon_JP'">用途</span>
                        <span v-else-if="subMenu.id === 'vizuon_RU'">Применение</span>
                        <span v-else>Application</span>
                      </div>
                      <div class="content-detail-more">
                        <div v-for="appliList in menuSubApplicationTree.data" :key="appliList.id">
                          <a :href="appliList.redirect || '#'">
                            <p v-html="appliList.title"></p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-for="subChildrenMenu in subMenu.children" :key="subChildrenMenu.id" v-if="subChildrenMenu.children && subChildrenMenu.children.length > 0 && subMenu.id !== 'HFLOR' && subMenu.id !== 'vizuon'">
                    <div class="dep-04" :id="subChildrenMenu.id" v-if="activeMenu === subChildrenMenu.id">
                      <div v-for="sub2ChildrenMenu in subChildrenMenu.children" :key="sub2ChildrenMenu.id">
                        <a :href="sub2ChildrenMenu.redirect || '#'">{{ sub2ChildrenMenu.title }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-img-wrap">
                  <div class="content-img" :class="{ active: subMenu.collectionImage1 ^ subMenu.collectionImage2 }" v-if="subMenu.collectionImage1">
                    <a @click="openImageLink(subMenu.openOption1,subMenu.collectionUrl1)" class="content-imgbox">
                      <img v-if="subMenu.collectionImage1"  :src="$imagePath + subMenu.collectionImage1" alt="gnb content image">
                      <p v-html="subMenu.collectionText1"></p>
                    </a>
                  </div>
                  <div class="content-img" :class="{ active: subMenu.collectionImage1 ^ subMenu.collectionImage2 }" v-if="subMenu.collectionImage2">
                    <a @click="openImageLink(subMenu.openOption2,subMenu.collectionUrl2)" class="content-imgbox">
                      <img v-if="subMenu.collectionImage2" :src="$imagePath + subMenu.collectionImage2" alt="gnb content image">
                      <p v-html="subMenu.collectionText2"></p>
                  </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile gnb -->
    <div class="m-gnb-container">
      <div class="mGnb-all">
        <!-- gnb-title -->
        <div class="list">
          <ul class="mGnb-tab-wrap">
            <li class="mGnb-tab-button" :class="{ active: index === 0 }" v-for="(menuTree, index) in menuTree.data" :key="menuTree.id" :data-alt="menuTree.id">
              <span v-html="menuTree.title"></span>
            </li>
          </ul>
          <div class="gnb-links">
            <div v-for="utility in utilityList" :value="utility.content.title">
              <a @click="openLink(utility)">{{ utility.content.title }}</a>
            </div>
            <div v-if="$countryCode == '_US'">
              <a href="javascript:roomvo.startStandaloneVisualizer('','');">Visualizer</a>
            </div>
          </div>
        </div>
        <!-- mGnb-contents -->
        <div class="mGnb-contents">
          <!-- **product -->
          <div :class="{'mGnb-content': true, [menuTree.id]: true, active: index === 0}" v-for="(menuTree, index) in menuTree.data" :key="menuTree.id" :id="menuTree.id">
            <ul>
              <div class="all-menu mGnb">
                <a :href="getHref(country)" v-if="menuTree.id === 'prod' || menuTree.id === 'prod'+country ">
                  <div></div>
                  <span v-if="country === '_GL'">ALL COLORS</span>
                  <span v-if="country === '_US'">ALL COLORS</span>
                  <span v-if="country === '_CA'">ALL COLORS</span>
                  <span v-if="country === '_CF'">Toutes les couleurs</span>
                  <span v-if="country === '_CN'">所有颜色</span>
                  <span v-if="country === '_RU'">Все цвета</span>
                  <span v-if="country === '_JP'">すべての柄</span>
                </a>
              </div>
              <li :class="['mGnb-'+ subMenu.id+ '-menu']" v-for="(subMenu, index) in menuTree.children" :key="subMenu.id">
                <div v-if="subMenu.children && subMenu.children.length > 0" :class="['mGnb-title', subMenu.children && subMenu.children.length  ? 'more' : '']" @click="mGnbOpen('mGnb-' + (subMenu.id || 'default') + '-menu', subMenu.children && subMenu.children.length ? 'Y' : 'N')">
                  <p v-html="subMenu.title"></p>
                  <span v-html="subMenu.subTitle"></span>
                </div>
                <div v-else :class="['mGnb-title', subMenu.children && subMenu.children.length  ? 'more' : '']" @click="mGnbOpen('mGnb-' + (subMenu.id || 'default') + '-menu', subMenu.children && subMenu.children.length ? 'Y' : 'N')">
                  <a :href="subMenu.redirect || '#'">
                    <p v-html="subMenu.title"></p>
                    <span v-html="subMenu.subTitle"></span>
                  </a>
                </div>
                <div class="m-dep02">
                  <ul>
                    <li :class="{ 'm-dep03': subChildrenMenu.children && subChildrenMenu.children.length > 0 }"  v-for="subChildrenMenu in subMenu.children" :key="subChildrenMenu.id" class="m-dep03"
                        v-if="subChildrenMenu.id !== 'tile' && subChildrenMenu.id !== 'tile_CA' && subChildrenMenu.id !== 'tile_CF' && subChildrenMenu.id !== 'tile_JP' && subChildrenMenu.id !== 'tile_CN' && subChildrenMenu.id !== 'tile_RU'
                        && subChildrenMenu.id !== 'sheet' && subChildrenMenu.id !== 'sheet_CA' && subChildrenMenu.id !== 'sheet_CF' && subChildrenMenu.id !== 'sheet_JP' && subChildrenMenu.id !== 'sheet_CN' && subChildrenMenu.id !== 'sheet_RU'
                        && subChildrenMenu.id !== 'Application' && subChildrenMenu.id !== 'Application_CA' && subChildrenMenu.id !== 'Application_CF' && subChildrenMenu.id !== 'Application_JP' && subChildrenMenu.id !== 'Application_CN' && subChildrenMenu.id !== 'Application_RU'">
                      <a v-bind:href="subChildrenMenu.redirect ? subChildrenMenu.redirect : undefined" @click="toggleDepth04(subChildrenMenu)" :class="{ 'm-dep03_link': true, 'active': subChildrenMenu.isActive }">
                        <h4 v-html="subChildrenMenu.title"></h4>
                      </a>
                      <div class="m-dep04"  v-for="sub2ChildrenMenu in subChildrenMenu.children" :key="sub2ChildrenMenu.id" v-if="subChildrenMenu.isActive && subChildrenMenu.children && subChildrenMenu.children.length > 0">
                        <a :href="sub2ChildrenMenu.redirect || '#'" class="m-dep04_link">
                          <p v-html="sub2ChildrenMenu.title"></p>
                        </a>
                      </div>
                    </li>
                    <li class="m-dep03-sub"
                        v-if="subMenu.id === 'HFLOR'
                        || subMenu.id === 'HFLOR_CA'
                        || subMenu.id === 'HFLOR_CF'
                        || subMenu.id === 'HFLOR_CN'
                        || subMenu.id === 'HFLOR_JP'
                        || subMenu.id === 'HFLOR_RU'">
                      {{ subMenu.id === 'HFLOR_JP' ? 'タイル' : (subMenu.id === 'HFLOR_RU' ? 'Плитка' : 'TILE') }}
                      <ul class="m-dep02-more">
                        <li v-for="tileList in menuSubTileTree.data" :key="tileList.id">
                          <a :href="tileList.redirect || '#'">
                            <p v-html="tileList.title"></p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="m-dep03-sub"
                        v-if="subMenu.id === 'HFLOR'
                        || subMenu.id === 'HFLOR_CA'
                        || subMenu.id === 'HFLOR_CF'
                        || subMenu.id === 'HFLOR_CN'
                        || subMenu.id === 'HFLOR_JP'
                        || subMenu.id === 'HFLOR_RU'">
                      {{ subMenu.id === 'HFLOR_JP' ? 'シート' : (subMenu.id === 'HFLOR_RU' ? 'Лист' : 'SHEET') }}
                      <ul class="m-dep02-more">
                        <li v-for="sheetList in menuSubSheetTree.data" :key="sheetList.id">
                          <a :href="sheetList.redirect || '#'">
                            <p v-html="sheetList.title"></p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="m-dep03-sub"
                        v-if="subMenu.id === 'vizuon'
                       || subMenu.id === 'vizuon_CA'
                       || subMenu.id === 'vizuon_CF'
                       || subMenu.id === 'vizuon_CN'
                       || subMenu.id === 'vizuon_JP'
                       || subMenu.id === 'vizuon_RU'">
                      {{ subMenu.id === 'HFLOR_JP' ? '用途' : (subMenu.id === 'HFLOR_RU' ? 'Применение' : 'Application') }}
                      <ul class="m-dep02-more">
                        <li v-for="appliList in menuSubApplicationTree.data" :key="appliList.id">
                          <a :href="appliList.redirect || '#'">
                            <p v-html="appliList.title"></p>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from '@/common/helper/lodash'
import menuService from '@/server/menu.service'
import { mapGetters, mapState } from 'vuex'
import mainService from '@/server/main.service'

// 주석처리 부분은 예전 소스인걸로 판단됨 추후 QA나 이상없을 경우에 오픈시 삭제 예정 LOS
export default {
  name: 'GnbComponent',
  components: {},
  data () {
    return {
      depth04Open: false, // depth04 열림
      activeMenu: null,
      country: null,
      menuTree: [],
      menuSubTileTree: [],
      menuSubSheetTree: [],
      menuSubApplicationTree: [],
      utilityList: []
    }
  },
  async created () {
    const country = this.$countryCode
    this.country = country
    this.getMenuTrees()
    this.getMainUtility()
  },
  async mounted () {
    const that = this
  },
  methods: {
    toggleDepth04 (subChildrenMenu) {
      if (!this.$set) {
        // Vue 2에서는 this.$set을 사용합니다.
        this.$set(subChildrenMenu, 'isActive', !subChildrenMenu.isActive)
      } else {
        // Vue 3에서는 직접 할당이 가능합니다.
        subChildrenMenu.isActive = !subChildrenMenu.isActive
      }

      // 같은 수준의 다른 메뉴 항목들의 isActive를 false로 설정
      if (subChildrenMenu.parent && subChildrenMenu.parent.children) {
        subChildrenMenu.parent.children.forEach(sibling => {
          if (sibling !== subChildrenMenu) {
            if (!this.$set) {
              this.$set(sibling, 'isActive', false)
            } else {
              sibling.isActive = false
            }
          }
        })
      }
    },
    setActiveMenu (menuId) {
      // Toggle the active menu or set it to the clicked menu
      this.activeMenu = this.activeMenu === menuId ? null : menuId
    },
    // initEvent() {
    // },
    openLink (windowOpenValue) {
      var windowOpen
      try {
        windowOpen = JSON.parse(windowOpenValue.attribute.MAIN_UTILITY_WINDOWS)
      } catch (error) {
        // 파싱 실패나 값이 없을 경우 기존창으로 강제 셋팅
        windowOpen = { value: '002' } // 기본값 설정
      }

      var value = windowOpen.value

      if (value === '002') {
        // 기존 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_UTILITY_URL, '_self') // '기존 창 URL'을 적절히 변경해주세요.
      } else if (value === '001') {
        // 새 창에서 열기
        window.open(windowOpenValue.attribute.MAIN_UTILITY_URL, '_blank') // '새 창 URL'을 적절히 변경해주세요.
      }
    },
    openImageLink (option, url) {
      if (url) {
        if (option === '001') {
          window.open(url, '_self')
        } else if (option === '002') {
          window.open(url, '_blank')
        }
      }
    },
    async getMainUtility () {
      const _this = this

      const nationCode = _this.$countryCode
      _this.countryCode = nationCode
      _this.categoryCode = 'MAIN_UTILITY' + nationCode

      const categoryRes = await mainService.getCategoryCode(_this.categoryCode)
      const utilityId = categoryRes.data.object.id

      // 추후 USEYN true만  있는 API개발해서 넣어야됨 >> v-show로 현재 처리 해둠 LOS
      const utilityList = await mainService.getContentListSort(utilityId, 1, 10, 'sort', 'asc')
      _this.utilityList = utilityList.data.list
    },
    async getMenuTrees () {
      const othersCountries = ['CA', 'CF', 'CN', 'JP', 'RU'];
      const countryCodeReplace = this.$countryCode.replace(/^_/, '');
      this.menuTree = await menuService.getMenuTrees(countryCodeReplace)
      this.addIsActiveProperty(this.menuTree.data)
      console.log("countryCodeReplace = ", countryCodeReplace)

      const tilePrefix = othersCountries.includes(countryCodeReplace) ? `tile_${countryCodeReplace}` : 'tile';
      this.menuSubTileTree = await menuService.getMenuTSTrees(tilePrefix, countryCodeReplace);

      const sheetPrefix = othersCountries.includes(countryCodeReplace) ? `sheet_${countryCodeReplace}` : 'sheet';
      this.menuSubSheetTree = await menuService.getMenuTSTrees(sheetPrefix, countryCodeReplace)

      const applicationPrefix = othersCountries.includes(countryCodeReplace) ? `Application_${countryCodeReplace}` : 'Application';
      this.menuSubApplicationTree = await menuService.getMenuTSTrees(applicationPrefix, countryCodeReplace)
    },
    addIsActiveProperty (menuItems) {
      menuItems.forEach(item => {
        this.$set(item, 'isActive', false)
        if (item.children && item.children.length > 0) {
          this.addIsActiveProperty(item.children)
        }
      })
    },
    includeString (source, compare) {
      return _.includes(source, compare)
    },
    mGnbOpen (className, openYn) {
      // 모바일 GNB 2>3열때
      if (openYn === 'Y') {
        const dep2 = `li.${className} .m-dep02`
        const closed = `li.${className} .mGnb-title`
        if ($(dep2).hasClass('on')) {
          $(dep2).removeClass('on')
          $(closed).removeClass('rotate')
        } else {
          $(dep2).addClass('on')
          $(closed).addClass('rotate')
        }
      }
    },
    mDep2Open (id) {
      const dep3 = `li#${id} .m-dep03`
      const closed = `li#${id} .plus`

      // console.log('#' + id)
      if ($(dep3).hasClass('on')) {
        $(dep3).removeClass('on')
        $(closed).removeClass('rotate')
      } else if (!$(dep3).hasClass('disabled')) {
        $(dep3).addClass('on')
        $(closed).addClass('rotate')
      }
    },
    mDep3Open (id) {
      const depId = `li#${id} .m-dep04`
      const closed = `li#${id} .plus`

      // console.log('#' + id)
      if ($(depId).hasClass('on')) {
        $(depId).removeClass('on')
        $(closed).removeClass('rotate')
      } else {
        $(depId).addClass('on')
        $(closed).addClass('rotate')
      }
    },
    getHref (country) {
      const lowercaseCountry = country.toLowerCase().replace(/^_/, '')
      return `/${lowercaseCountry}/products/all_colors`
    },
    tabClick: function () {
      console.log(this)
      $(this).parent().toggleClass('active')
    }
  },
  updated () {
    this.$nextTick(() => {
      $('.pc-gnb-tab').click(function () {
        $(this).addClass('active')
        $(this).siblings().removeClass('active')
        $('.gnb-content').removeClass('active')
        $('#' + $(this).attr('data-alt')).addClass('active')
      })
      $('.dep-04-tab').click(function () {
        $('.dep-04-tab').removeClass('active')
        $(this).addClass('active')
        $(this).siblings().removeClass('active')
      })
      // GNB 모바일 탭메뉴
      $('.mGnb-tab-button').click(function () {
        $(this).addClass('active')
        $(this).siblings().removeClass('active')
        $('.mGnb-content').removeClass('active')
        $('#' + $(this).attr('data-alt')).addClass('active')
      })
    })
  },
  computed: {
    ...mapGetters({
      likeCnt: 'header/getLikeCnt',
      loginData: 'account/getLoginStatus'
    })
  }
}

// gnb pc버젼에서 태블릿 버젼으로 바뀔때 열려있는 메뉴 숨김 처리
$(window).resize(function () {
  var windowWidth = window.outerWidth
  if (windowWidth <= 1280) {
    $('.gnb-section').removeClass('on').removeClass('gnb-header')
    $('.gnb-section .gmlist').removeClass('on')
    $('.gnb-section li').removeClass('on')
    $('.p-ui.menu li').removeClass('on')
  }
})

// gnb benif "by collection" 콘텐츠 세로 한줄에 8개, 그에 따른 너비 변경
$(function () {
  setTimeout(function () {
    const widthNum = $('.gnb-section .mlist-prod .dep3.dep3-type02 .benif-gnb-box .dep4 > ul > li').width()
    const lengthNum = parseInt($('.gnb-section .mlist-prod .dep3.dep3-type02 .benif-gnb-box .dep4 > ul').children().length / 8)
    $('.gnb-section .benif-gnb-box:nth-child(2)').css({ width: widthNum * lengthNum })
  }, 1000)

  // 화면 사이즈 변경시에도 적용
  $(window).resize(function () {
    const widthNum = $('.gnb-section .mlist-prod .dep3.dep3-type02 .benif-gnb-box .dep4 > ul > li').width()
    const lengthNum = $('.gnb-section .mlist-prod .dep3.dep3-type02 .benif-gnb-box .dep4 > ul').children().length / 8
    $('.gnb-section .benif-gnb-box:nth-child(2)').css({ width: widthNum * lengthNum })
  })
})

</script>
