<template>
  <li>
    <div class="filter_checkbox_tit">
      <span>TEXTIE TYPE</span>
    </div>
    <div class="filter_checkbox_cont">
      <div class="checkbox_box">
            <span class="checkbox_more no_line">
              <li v-for="typet in texttileList" :type_t_-chk="typet.id.value">
                <input type="checkbox" :id="'type_t_' + typet.id.value" name="TexttileType" v-model="selected" :value="typet.id.value" />
                <label :for="'type_t_'+`${typet.id.value}`">
                <span></span>
                {{ typet.text }}
                </label>
              </li>
            </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/event_bus'

export default {
  name: 'textileTypeFilter',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: [
    'texttileList', 'value'
  ],
  components: {

  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated
    })
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected: {
      handler (newSelected) {
        this.$emit('input', newSelected)
      },
      deep: true
    },
    value: {
      handler (newValue) {
        this.selected = newValue
      },
      deep: true
    }
  },
  async created () {

  },
  async mounted () {
    const _this = this
    this.selected = this.value

    EventBus.$on('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-typeTextile-filter', this.removeTextile)
    EventBus.$on('update-typeTextile-filters', this.updateTextiles)

    if (this.$store.state.filterState && this.$store.state.filterState.type_t) {
      this.selected = this.$store.state.filterState.type_t
    }
  },
  beforeDestroy () {
    EventBus.$off('reset-pf-filter', this.resetCheckboxes)
    EventBus.$on('remove-typeTextile-filter', this.removeTextile)
    EventBus.$on('update-typeTextile-filters', this.updateTextiles)
  },
  methods: {
    resetCheckboxes () {
      this.selected = []
    },
    removeTextile (typeTextileValue) {
      this.selected = this.selected.filter(value => value !== typeTextileValue)
    },
    updateTextiles (typeTextiles) {
      this.selected = typeTextiles
    }
  }
}
</script>
