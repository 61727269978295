<template>
            <!-- 화면보시면 y축이 조금 밀리는데 {{GTM}}이 실시간으로 뜹니다. 저게없으면 높이가 정상이 됩니다. -->

  <div class="wrap">
    <div class="container error400-con">
      <div class="layer-view layer-imglib popup-v2">
        <div class="popup">
          <div class="scr-y">
            <div class="popup-body">
              <div class="frow">
                <div class="fcol tac">
                  <i class="pcon"><img class="er-icon" src="https://img.lxhausys.com/public/images/icon-error500.png" alt="error500 icon"></i>
                  <div class="msg">
                    <ul>
                      <li class="tt-2">Internal Server Error</li>
                      <li>
                        <ul>
                            <li class="ctt ctt-t2">There was a temporary error in the system.</li>
                            <li class="ctt ctt-t2">Please try again later.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  name: 'internalError',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
})
</script>
