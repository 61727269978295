var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    [
      _c("COMPARE"),
      _c("COLLECTION", {
        attrs: { collectionList: _vm.collectionList },
        model: {
          value: _vm.collection,
          callback: function ($$v) {
            _vm.collection = $$v
          },
          expression: "collection",
        },
      }),
      _c("IS_NEW", {
        model: {
          value: _vm.isNew,
          callback: function ($$v) {
            _vm.isNew = $$v
          },
          expression: "isNew",
        },
      }),
      _c("WOODCOLOR", {
        attrs: { huewList: _vm.dictMap["hue_w"] },
        model: {
          value: _vm.hue_w,
          callback: function ($$v) {
            _vm.hue_w = $$v
          },
          expression: "hue_w",
        },
      }),
      _c("WOODSPECIES", {
        attrs: { treespeciesList: _vm.dictMap["tree_species"] },
        model: {
          value: _vm.tree_species,
          callback: function ($$v) {
            _vm.tree_species = $$v
          },
          expression: "tree_species",
        },
      }),
      _c("WOODPATTERN", {
        attrs: { woodpatternList: _vm.dictMap["pattern_w"] },
        model: {
          value: _vm.pattern_w,
          callback: function ($$v) {
            _vm.pattern_w = $$v
          },
          expression: "pattern_w",
        },
      }),
      _c("COLOR", {
        attrs: { colorList: _vm.dictMap["hue"] },
        model: {
          value: _vm.hue,
          callback: function ($$v) {
            _vm.hue = $$v
          },
          expression: "hue",
        },
      }),
      _c("METALPATTERN", {
        attrs: { metalpatternList: _vm.dictMap["pattern_m"] },
        model: {
          value: _vm.pattern_m,
          callback: function ($$v) {
            _vm.pattern_m = $$v
          },
          expression: "pattern_m",
        },
      }),
      _c("STONETYPE", {
        attrs: { tpyesList: _vm.dictMap["type_s"] },
        model: {
          value: _vm.type_s,
          callback: function ($$v) {
            _vm.type_s = $$v
          },
          expression: "type_s",
        },
      }),
      _c("TEXTILETYPE", {
        attrs: { texttileList: _vm.dictMap["type_t"] },
        model: {
          value: _vm.type_t,
          callback: function ($$v) {
            _vm.type_t = $$v
          },
          expression: "type_t",
        },
      }),
      _c("FIRERESISTANCE", {
        attrs: { fireResistList: _vm.dictMap["fire_resistance"] },
        model: {
          value: _vm.fire_resistance,
          callback: function ($$v) {
            _vm.fire_resistance = $$v
          },
          expression: "fire_resistance",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }