<template>
  <div class="card-text-area">
    <div class="title-area">
      <pre class="title">{{ title }}</pre>
    </div>
    <div>
      <pre class="desc" :style="descStyle">{{ desc }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    desc: {
      type: String
    },
    descWidth: {
      type: String
    }
  },

  computed: {
    descStyle: function () {
      let widthStyle = ''

      if (this.descWidth) {
        widthStyle = `width: ${this.descWidth};`
      }

      return `white-space: pre-wrap; ${widthStyle}`
    }
  }
}
</script>

<style scoped>
.card-text-area {
  display: flex;
  flex-direction: column;
}

.title-area {
  margin-bottom: 20px;
}

.title {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.desc {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

</style>
