<template>
  <div class="key-social-contribution-initiatives-image-card">
    <div class="image-area">
      <img
      v-for="item in images"
      :key="item"
      :src="item"
      >
    </div>
    <pre class="text">{{ text }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
.key-social-contribution-initiatives-image-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  align-self: stretch;
}

.key-social-contribution-initiatives-image-card .image-area {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

@media (max-width: 768px) {
  .key-social-contribution-initiatives-image-card .image-area {
    flex-direction: column;
  }
}

.key-social-contribution-initiatives-image-card .image-area img {
  border-radius: 14px;
  object-fit: none;
}

@media (max-width: 768px) {
  .key-social-contribution-initiatives-image-card .image-area img {
    object-fit: contain;
  }
}

.key-social-contribution-initiatives-image-card .text {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 200% */
}

@media (max-width: 768px) {
  .key-social-contribution-initiatives-image-card .text {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
    white-space: pre-wrap;
  }
}
</style>
