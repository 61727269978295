<template>
  <div class="carbon_neutrality_strategy_card_area">
    <div class="image-area">
      <img :src="imageSrc">
    </div>

    <div class="title-area">
      <OverviewText
        :text="title"
        type="ft-25 fw-700"
        textAlign="left"
      />
    </div>

    <div class="desc-area">
      <OverviewText
        v-for="(it, idx) in desc"
        :key="idx"
        :text="it"
        :enableListStyle="enableDot"
        type="ft-16"
        textAlign="left"
      />
    </div>
  </div>
</template>

<script>
import OverviewText from '../../../overview/components/OverviewText.vue'

export default {
  components: {
    OverviewText
  },
  props: {
    imageSrc: {
      type: String
    },
    title: {
      type: String
    },
    desc: {
      type: Array
    }
  },

  computed: {
    enableDot () {
      return this.desc.length > 1
    }
  },

  methods: {
    toDescTxt (text) {
      const useDot = this.desc.length > 1

      if (useDot) {
        return `• ${text}`
      } else {
        return text
      }
    }
  }

}
</script>

<style scoped>
.carbon_neutrality_strategy_card_area {
  display: flex;
  padding: 49px 47px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.carbon_neutrality_strategy_card_area .image-area {
  margin-bottom: 34px;
}

.carbon_neutrality_strategy_card_area .image-area img {
  width: 106px;
  height: 66px;
}

.carbon_neutrality_strategy_card_area .title-area {
  margin-bottom: 10px;
}

.carbon_neutrality_strategy_card_area .desc-area {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
