<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <div class="content">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Professionals</li>
          <li>HIMACS Partners Portal</li>
          <li>Find Your Email</li>
        </ul>
      </div>
      <!-- //location -->

      <div class="w-con-02 inner">
        <div class="mypg-loginJoin">
          <h1 class="tt-1 ta-c mb-88">Find Your Email</h1>
          <p class="tt-2">Forgot Your Email Address?</p>
          <p class="ctt ctt-t4">Please enter your information below.</p>
          <div class="mypg-forgotPW euEmail">
            <div class="tbl-01">
              <div class="tbl-input">
                <table>
                  <caption>Contact us For Professionals form</caption>
                  <colgroup>
                    <col style="width: 160px;">
                    <col style="width: auto;">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row"><label for="inp02" class="must">Last Name</label></th>
                      <td class="cell-noti">
                        <input type="text" id="inp02" placeholder="Input Text" class="inp-text" v-model="lastNm">
                        <p class="glSite" v-if="submitChk && lastNm === ''">Enter Last Name</p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><span class="must">Country</span></th>
                      <td class="cell-noti">
                        <div class="sel-box type02"><button type="button" class="btn-sel"><span>{{selectedCountry}}</span></button>
                          <div class="sel-list">
                            <ul class="box">
                              <li v-for="country in countries"><button type="button" @click="chooseCountry(country)">{{country.countryNm}}</button></li>
                            </ul>
                          </div>
                        </div>
                        <p class="glSite" v-if="submitChk && selectedCountry === 'Select'">Select Country</p>
                        <!---->
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"><label for="inp01" class="must">Email</label></th>
                      <td class="cell-noti"><input type="text" id="inp01" placeholder="Input Text" class="inp-text" v-model="email">
                      <p class="glSite" v-if="submitChk && email === ''">Enter Email</p>
                        <p class="glSite" v-if="submitChk && email !== '' && !reg.test(email)">Invalid Email Address</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div class="inpt-mypg">
              <ul>
                <li>
                  <label for="inp04" class="must">Last name</label>
                  <span class="input-area">
                    <input type="text" id="inp04" placeholder="Last name"
                    class="inp-text">
                  </span>
                </li>
                <div class="noti-02">Enter Last Name</div>

                <li>
                  <label for="inp03" class="must">First name</label>
                  <div class="sel-box type02">
                    <button type="button" class="btn-sel">
                      <span>Select</span>
                    </button>
                    <div class="sel-list">
                      <ul class="box">
                        <li><button type="button">UNITED STATES</button></li>
                        <li><button type="button">CANADA</button></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <div class="noti-02">Select Country</div>

                <li>
                  <label for="inp05" class="must">Email</label>
                  <span class="input-area">
                    <input type="text" id="inp05" placeholder="Email"
                    class="inp-text">
                  </span>
                </li>
                <div class="noti-02">Enter Email</div>

              </ul>
            </div> -->
            <div class="btn-mypg"><button type="button" class="btn" @click="submitForm()"><span>Find Your Email</span></button></div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL PopUp : FindId OK-->
    <div class="layer-popup pop-modal-s1 popFindId">
      <div class="popup pop-close">
        <div class="scr-y">
          <div class="tit-box">
            <p class="pop-title">FIND YOUR EMAIL</p>
          </div>
          <div class="modal-con">
            <div class="check"><img src="https://img.lxhausys.com/public/images/img_FindID_success.png" alt="findid"</div>
            <div class="msg01">
              <span class="txt01">Your email address is<span class="txt01-bold">{{ foundEmail }}</span></span>
            </div>
          </div>
          <div class="modalBtn-01">
            <button type="button" class="btn-type-large" @click="goToLoginPage()">
              <span>Return to Login page</span>
            </button>
          </div>
          <button type="button" class="btn-close" @click="closePopup()">close</button>
        </div>
      </div>
    </div>
    <!-- //MODAL PopUp : FindId  OK-->

    <!-- MODAL PopUp : FindId Fail-->
    <div class="layer-popup pop-modal-s1 popFindIdFail">
      <div class="popup pop-close">
        <div class="scr-y">
          <div class="tit-box">
            <p class="pop-title">FIND YOUR EMAIL</p>
          </div>
          <div class="modal-con">
            <div class="check"><img src="https://img.lxhausys.com/public/images/img_FindID_fail.png" alt="fail"/div>
            <div class="msg01">
              <span class="txt02">No Result Found</span>
              <div class="txt03">Please try again using different information</div>
            </div>
          </div>
          <div class="modalBtn-01">
            <button type="button" class="btn-type-large" @click="goToFindEmail()">
              <span>Return to Find Your Email</span>
            </button>
          </div>
          <button type="button" class="btn-close" @click="closePopup()">close</button>
        </div>
      </div>
    </div>
    <!-- //MODAL PopUp : Fail -->

    <FooterComponent></FooterComponent>
  </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import UserService from '@/server/user.service'
import EuService from '@/server/eu.service'

export default {
  name: 'Detail',
  components: {
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  data () {
    return {
      lastNm: '',
      countryCd: '',
      email: '',
      foundEmail: '',
      countries: [],
      selectedCountry: 'Select',
      submitChk: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
    }
  },
  async created () {
    const countryRes = await EuService.getCountryList()
    this.countries = countryRes.data
  },
  mounted () {
  },
  methods: {
    chooseCountry (country) {
      this.selectedCountry = country.countryNm
      this.countryCd = country.countryCd
    },
    goToLoginPage () {
      this.$utils.goLoginEU()
    },
    goToFindEmail () {
      location.reload()
    },
    openFindIdPopup () {
      LayerPopup.open('popFindId')
    },
    openFindIdFail () {
      LayerPopup.open('popFindIdFail')
    },
    closePopup () {
      this.lastNm = ''
      this.email = ''
      this.selectedCountry = 'Select'
      this.submitChk = false
      this.valid = false
      LayerPopup.close()
    },
    async submitForm () {
      this.submitChk = true
      if (this.lastNm === '') {
        return false
      }
      if (this.countryCd === '') {
        return false
      }
      if (this.email === '' || !this.reg.test(this.email)) {
        return false
      }
      const member = await UserService.findEmailEU(this.lastNm, this.countryCd, this.email)
      if (member.data === '') {
        this.openFindIdFail()
      } else {
        this.foundEmail = member.data.memId
        this.openFindIdPopup()
      }
    }
  }
}
</script>
