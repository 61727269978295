<template>
  <div id="wrap">
    <HeaderComponent></HeaderComponent>
    <GnbComponent></GnbComponent>
    <!-- content -->
    <div class="content order-view">
      <!-- location -->
      <div class="location">
        <ul>
          <li class="ico-home">Home</li>
          <li>Header</li>
          <li>Cart</li>
          <li>Order Sample</li>
        </ul>
      </div>
      <!-- //location -->

      <!-- container (Order Sample) -->
      <div class="container order-inp-box oder-del-box">
        <div class="w-con-02 inner" v-if="orderList.length>0">
          <h1 class="tt-1">Order Summary</h1>
          <div class="col-02 order-tbl order-tbl-big-box">
            <div class="tbl-01 order-tbl01">
              <div class="tbl-box type03">
                <table>
                  <caption>Shipping form</caption>
                  <colgroup>
                    <col style="width:185px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="order-small-word order-tbl-box" scope="row">Email Address</th>
                      <td class="order-tbl-box">
                        <div class="btn-ab order-edit-btn-box">
                          <p class="t-type-01 order-small-word">{{memberInfo.email}}</p>
                          <button type="button" class="btn-r btn btn-line-gray order-edit-btn" @click="editInfo()"><span class="no-arrow order-small-word">Edit</span></button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="order-small-word order-tbl-box" scope="row">Shipping Address</th>
                      <td class="order-tbl-box">
                        <div class="t-type-01 btn-ab order-edit-btn-box order-small-word">
                          {{memberInfo.addr1}}<br/>
                          {{memberInfo.addr2}}<br/>
                          {{memberInfo.city}}<br/>
                          {{getStateNm(memberInfo.stateCd, memberInfo.country)}}<br/>
                          {{memberInfo.zipCd}}<br/>
                          {{getCountryNm(memberInfo.country)}}
                          <button type="button" class="btn-r btn btn-line-gray order-edit-btn" @click="editInfo()"><span class="no-arrow order-small-word">Edit</span></button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="order-small-word order-tbl-box" scope="row">Payment Method</th>
                      <td class="order-tbl-box">
                        <div class="btn-ab">
                          <label class="inp-rdo">
                            <input type="radio" name="rdo00" checked>
                            <span>Paypal (Guest checkout available using debit or credit card)</span>
                          </label>
                          <button type="button" class="btn-pp">PayPal</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tbl-02 order-tbl02">
              <div class="tbl-input type02">
                <table>
                  <caption>order form (Product)</caption>
                  <colgroup>
                    <col style="width:250px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                    <tr v-for="(item, index) in orderList" :key="item.content.id">
                      <td colspan="2" class="prod-box order-tbl-box">
                        <div class="col-02">
                          <div class="img-box">
                            <img :src="getImage(item)" alt="image"  style="width: 210px">
                          </div>
                          <div class="txt-box">
                            <ul>
                              <li class="txt-01 order-small-word">{{item.content.attribute.code}}</li>
                              <li class="txt-02 order-small-word">{{item.content.content.title}}</li>
                              <li class="txt order-small-word"><span>Size</span>{{item.sampleInfo.size}}</li>
                              <li class="txt order-small-word"><span>Qty</span>{{item.qty}}</li>
                              <li class="txt order-small-word"><span>Each</span>${{item.sampleInfo.price}}</li>
                            </ul>
                            <p class="txt-price order-small-word">$ {{calcOrder(item.sampleInfo.price, item.qty)}}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="order-small-word order-tbl-box" scope="row">Subtotal</th>
                      <td class="order-small-word order-tbl-box">$ {{ calcSubTotal()}}</td>
                    </tr>
                    <tr>
                      <th class="order-small-word order-tbl-box" scope="row">Shipping & Handling</th>
                      <td class="order-small-word order-tbl-box">FedEx Ground</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="tbl-input type02 tbl-total">
                <table>
                  <caption>order form (Product Grand Total)</caption>
                  <colgroup>
                    <col style="width:250px">
                    <col style="width:auto">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="order-small-word order-tbl-box" scope="row">Grand Total</th>
                      <td class="order-small-word order-tbl-box">$ {{ calcSubTotal()}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="more-box ta-c">
                <button type="button" class="btn" @click="order()"><span>Pay Now</span></button>
              </div>

              <label class="inp-chk">
                <input type="checkbox" v-model="agree">
                <span>I have read and agree to the <a href="javascript:void(0);" @click="openPrivacy()">Privacy Policy</a> and Website <a href="javascript:void(0);" @click="openTerms()">Terms of Use</a>.</span>
              </label>
              <form id="paypalForm" :action="getPaypalUrl() + '/cgi-bin/webscr'" method="post">
                <template v-for="(val, prop) in paypalForm">
                  <input type="hidden" :name="prop" :value="val" v-if="prop !== 'items'">
                </template>
                <template v-for="(item, idx) in paypalForm.items">
                  <input type="hidden" :name="'item_name_' + (idx+1)" :value="item.item_name">
                  <input type="hidden" :name="'item_number_' + (idx+1)" :value="item.item_number">
                  <input type="hidden" :name="'amount_' + (idx+1)" :value="item.amount">
                  <input type="hidden" :name="'quantity_' + (idx+1)" :value="item.quantity">
                </template>
                <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHosted">
                <input v-show="false" type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!">
                <img alt="pixel" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1">
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- //container (Order Sample) -->
    </div>
    <!-- //content -->
    <QuickMenuComponent></QuickMenuComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>

import HeaderComponent from '@/components/Layout/Header'
import FooterComponent from '@/components/Layout/Footer'
import GnbComponent from '@/components/Layout/GNB'
import usService from '@/server/us.service'
import sampleOrderService from '@/server/sampleOrder.service.js'
import _ from '@/common/helper/lodash.js'
import QuickMenuComponent from '@/components/Layout/Quick/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'Checkout',
  components: {
    QuickMenuComponent,
    HeaderComponent,
    FooterComponent,
    GnbComponent
  },
  computed: {
    ...mapState({
      orderList: state => state.order.orderList || [],
      orderMemberInfo: state => state.order.memberInfo || { }
    })
  },
  data () {
    return {
      agree: false,
      submitted: true,
      searchValue: {
        countries: [],
        states: {
          US: [],
          CA: [],
          99: []
        },
        stateCd: ''
      },
      memberInfo: {},
      paypalForm: {}
    }
  },
  async created () {
    this.searchValue.countries = [
      { countryCd: 'US', countryNm: 'USA' },
      { countryCd: 'CA', countryNm: 'CANADA' }
    ]
    const promiseArr = [
      usService.getStateList('US'),
      usService.getStateList('CA'),
      usService.getStateList('99')
    ]
    const stateRes = await Promise.all(promiseArr)
    this.searchValue.states.US = stateRes[0].data
    this.searchValue.states.CA = stateRes[1].data
    this.searchValue.states['99'] = stateRes[2].data
  },
  async mounted () {
    this.init()
  },
  methods: {
    getPaypalUrl () {
      return this.runningEnvironment == 'PRD' ? 'https://www.paypal.com' : 'https://www.sandbox.paypal.com'
    },
    getImage (item) {
      let image = null
      if (!_.isEmpty(item.content.cover)) {
        image = item.content.cover
      } else if (item.content.orgContent && !_.isEmpty(item.content.orgContent.cover)) {
        image = item.content.orgContent.cover
      } else if (!_.isEmpty(item.content.attribute.pf_thumb)) {
        image = item.content.attribute.pf_thumb
      }

      return image == null ? null : this.$imagePath + image
    },
    async init () {
      const _this = this
      // 제품 선택이 없으면 메인으로
      console.log('orderList', this.orderList)
      if (this.orderList.length === 0) {
        this.$nextTick(function () {
          setTimeout(function () {
            alert('No products to order. Go to main')
            _this.goHome()
          }, 1000)
        })
        return
      }

      this.memberInfo = this.orderMemberInfo

      this.sendGtm()
    },
    sendGtm () {
      const that = this
      // GTM checkout init
      digitalData.checkout = {
        ecom_currency_code: 'USD',
        actionField: {
          ecom_checkout_step: '2',
          ecom_checkout_option: 'paypal',
          ecom_order_country: this.getCountryNm(this.memberInfo.country),
          ecom_order_state: this.getStateNm(this.memberInfo.stateCd, this.memberInfo.country),
          ecom_order_city: this.memberInfo.city,
          ecom_order_zipcode: this.memberInfo.zipCd
        },
        products: []
      }

      _.forEach(this.viewList, function (v) {
        // GTM checkout push
        digitalData.checkout.products.push(that.makeDataLayerForm(v))
      })

      if (typeof (dataLayer) !== 'undefined') {
        dataLayer.push({ event: 'ecCheckOut' })
      }
    },
    getStateNm (stateCd, countryCd) {
      const found = _.find(this.searchValue.states[countryCd], (v) => {
        return v.id.countryCd === countryCd && v.id.stateCd === stateCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.stateNm
    },
    getCountryNm (countryCd) {
      const found = _.find(this.searchValue.countries, (v) => {
        return v.countryCd === countryCd
      })
      if (_.isUndefined(found)) {
        return ''
      }
      return found.countryNm
    },
    editInfo () {
      this.$router.push({ name: 'info' })
    },
    calcOrder (price, qty) {
      // sample 당 가격산출
      return Number(price) * Number(qty)
    },
    calcSubTotal () {
      // 총 가격 산출
      let tot = 0
      _.forEach(this.orderList, (v) => {
        const qty = v.qty
        const price = v.sampleInfo.price
        tot += qty * price
      })
      return tot.toFixed(2)
    },
    async order () {
      const _this = this
      if (!_this.agree) {
        alert('agreement required')
        return
      }
      if (_this.submitted) {
        _this.submitted = false
        // GTM checkout step to 3
        digitalData.checkout.actionField.ecom_checkout_step = 3

        const itemList = []

        // order
        _.forEach(this.orderList, function (v) {
          itemList.push({
            contentId: v.content.content.id + '',
            categoryId: v.categoryId,
            sku: v.content.attribute.sku,
            sampleSize: v.sampleInfo.size,
            qty: v.qty + '',
            colorNm: v.content.content.title,
            colorCd: v.content.attribute.code,
            price: v.sampleInfo.price + ''
          })
        })
        const res = await sampleOrderService.saveOrder({ itemList, ..._this.memberInfo })
        if (_.isEmpty(res.data)) {
          alert('Order was failed. Please inform.')
          this.submitted = true
        } else {
          this.paypalForm = res.data

          // GTM checkout dataLayer
          if (typeof (dataLayer) !== 'undefined') {
            dataLayer.push({ event: 'ecCheckOut' })
          }
          // form submit
          this.$store.dispatch('order/clearCartItem')
          this.$store.dispatch('order/clearOrderItem')
          this.$store.dispatch('order/clearMemberInfo')

          this.$nextTick(function () {
            try {
              $('#paypalForm').submit()
              this.submitted = true
            } catch (error) {
              console.log(error)
              this.submitted = true
            }
          })
        }
      } else {
        alert('already submitted!')
      }
    },
    openPrivacy () {
      window.open('etc/privacy?pop=Y', 'privacy', 'width:800px')
    },
    openTerms () {
      window.open('etc/terms?pop=Y', 'terms', 'width:800px')
    },
    makeDataLayerForm (v) {
      let brand = ''
      let category = ''
      let size = ''
      let type = ''
      let color = ''
      let collection = ''
      let cateCode = ''

      if (_.includes(v.content.content.modelId, 'HFLOR')) {
        // HFLOR
        brand = v.content.parents[2].name
        category = v.content.parents[1].name
        collection = v.content.parents[0].name
        cateCode = v.content.parents[1].code
        if (_.includes(cateCode, 'FLOOR_LUX') || _.includes(cateCode, 'FLOOR_HET')) {
          const prdType = v.content.attribute.prdTypeText
          type = _.isArray(prdType) ? prdType.join('|') : prdType
        }
      } else {
        // Viatera, Himacs
        brand = v.content.parents[1].name
        category = ''
        collection = v.content.parents[0].name
        color = v.content.attribute.hueText // himacs, viatera only
      }
      size = v.sampleInfo.size
      const info = {
        ecom_prd_id: v.content.attribute.code == '' ? v.content.content.id : v.content.attribute.code,
        ecom_prd_name: v.content.content.title,
        ecom_prd_brand: brand,
        ecom_prd_category: category,
        ecom_prd_variant: size,
        ecom_prd_type: type,
        ecom_prd_collection: collection,
        ecom_prd_color: color,
        ecom_prd_price: v.sampleInfo.price,
        ecom_prd_qty: v.qty
      }
      return info
    }
  }
}
</script>
