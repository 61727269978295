<template>
  <OverviewSection
    title="Energy Comsumption Minimization"
    v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <pre class="small-title">In Our Plant</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="energy-comsumption-minimization-radius-area">
        <img class="plant-image" :src=plant.image>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="plant.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <pre class="small-title">By Our Product</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="by-our-product">
        <div class="energy-comsumption-minimization-radius-area">
          <img class="product-image" :src="product.image">
        </div>
        <div class="by-our-product-text">
          <pre class="by-our-product-text-title">{{ product.title }}</pre>
          <pre class="by-our-product-text-content">{{ product.content }}</pre>
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- JP -->
  <OverviewSection
    title="エネルギー消費の最小化"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <pre class="small-title">私たちの工場では</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="energy-comsumption-minimization-radius-area">
        <img class="plant-image" :src=plant.image>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="plantJp.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <pre class="small-title">当社の製品</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="by-our-product">
        <div class="energy-comsumption-minimization-radius-area">
          <img class="product-image" :src="product.image">
        </div>
        <div class="by-our-product-text">
          <pre class="by-our-product-text-title">{{ productJp.title }}</pre>
          <pre class="by-our-product-text-content">{{ productJp.content }}</pre>
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- RU -->
  <OverviewSection
    title="Минимизация энергопотребления"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <pre class="small-title">На нашем заводе</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="energy-comsumption-minimization-radius-area">
        <img class="plant-image" :src=plant.image>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="plantRu.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <pre class="small-title">Наш продукт</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="by-our-product">
        <div class="energy-comsumption-minimization-radius-area">
          <img class="product-image" :src="product.image">
        </div>
        <div class="by-our-product-text">
          <pre class="by-our-product-text-title">{{ productRu.title }}</pre>
          <pre class="by-our-product-text-content">{{ productRu.content }}</pre>
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
<!-- CN -->
  <OverviewSection
    title="能源消耗最小化"
    v-else-if="nationCode == '_CN'"
  >
    <OverviewSectionItem
      :isUnderSectionItem="true"
    >
      <pre class="small-title">在LX Hausys的工厂</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="energy-comsumption-minimization-radius-area">
        <img class="plant-image" :src=plant.image>
      </div>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <SustainabilityText
        :text="plantCn.text"
      />
    </OverviewSectionItem>

    <OverviewSectionItem>
      <pre class="small-title">通过LX Hausys的产品</pre>
    </OverviewSectionItem>

    <OverviewSectionItem>
      <div class="by-our-product">
        <div class="energy-comsumption-minimization-radius-area">
          <img class="product-image" :src="product.image">
        </div>
        <div class="by-our-product-text">
          <pre class="by-our-product-text-title">{{ productCn.title }}</pre>
          <pre class="by-our-product-text-content">{{ productCn.content }}</pre>
        </div>
      </div>
    </OverviewSectionItem>
  </OverviewSection>
</template>

<script>
import OverviewSection from '../../../overview/components/OverviewSection.vue'
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import SustainabilityText from '../../components/SustainabilityText.vue'

export default {
  components: {
    OverviewSection,
    OverviewSectionItem,
    SustainabilityText
  },
  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      plant: {
        image: 'images/aboutus/sustainability/sustainability_management/in-our-plant.png',
        text: '<b>In 2022, our Ulsan plant reduced 57MWh of LNG consumption</b>,\n' +
              'which amounts to greater than a 15% in reduction compared to the same time period in 2021.\n' +
              'This metric was achieved through screw compressor investment and aging room heat source improvement.'
      },
      product: {
        image: 'images/aboutus/sustainability/sustainability_management/by-our-product.png',
        title: 'Energy-Saving Insulation Materials',
        content: '‘LX Z:IN PF Board’ offers the same insulation performance\n' +
                 'with a thinner thickness than the relevant regulations require,\n' +
                 'allowing more efficient use of the interior spaces of insulated\n' +
                 'apartment housings for consumers.'
      },
      plantJp: {
        image: 'images/aboutus/sustainability/sustainability_management/in-our-plant.png',
        text: '2022年に、当社の蔚山工場はLNG消費量を57MWh削減しました。\n' +
              'これは、2021年の同時期と比較して15％以上の削減に相当します。\n' +
              'この指標は、スクリューコンプレッサーへの投資と老朽化した部屋の熱源の改善によって達成されました。'
      },
      productJp: {
        image: 'images/aboutus/sustainability/sustainability_management/by-our-product.png',
        title: '省エネ断熱材',
        content: 'LX Z:IN PFボードは、\n' +
                 '関連規制で要求される厚さよりも薄いにもかかわらず、\n' +
                 '同等の断熱性能を提供し、消費者にとって断熱\n' +
                 'されたマンションの内部空間をより効率的に利用できるようにします。'
      },
      plantRu: {
        image: 'images/aboutus/sustainability/sustainability_management/in-our-plant.png',
        text: 'В 2022 году наш завод в Ульсане сократил потребление СПГ на 57 МВт-ч,\n' +
              'что составляет более 15% сокращения по сравнению с аналогичным периодом 2021 года.\n' +
              'Этого показателя удалось достичь благодаря инвестициям в винтовые компрессоры и улучшению источника тепла в подвальном помещении.'
      },
      productRu: {
        image: 'images/aboutus/sustainability/sustainability_management/by-our-product.png',
        title: 'Энергосберегающие\n' + 
                'изоляционные материалы',
        content: ' «LX Z:IN PF Board» обеспечивает такие же изоляционные характеристики\n' +
                 'при меньшей толщине, чем требуют соответствующие нормы,\n' +
                 'это позволяет более эффективно использовать внутреннее\n' +
                 'пространство  утепленных многоквартирных домов.'
      },
      plantCn: {
        image: 'images/aboutus/sustainability/sustainability_management/in-our-plant.png',
        text: '通过螺杆压缩机投资和老化室热源改进，\n' +
              '2022年，蔚山工厂减少了57MWh的液化天然气消耗量，\n' +
              '相比2021年同期减少了15%以上。'
      },
      productCn: {
        image: 'images/aboutus/sustainability/sustainability_management/by-our-product.png',
        title: '节能保温材料',
        content: 'LX Z:IN PF板具有相同的隔热性能，\n' +
                 '厚度比相关规定要求的薄，\n' +
                 '为消费者更有效地利用隔热公寓的内部空间。'
      },
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },

}
</script>

<style scoped>
.small-title {
  color: #A09C9B;
  text-align: center;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 69.444% */
}

@media (max-width: 768px) {
  .small-title {
    font-size: 1.25rem;
    line-height: 1.3541666667rem;
  }
}

.energy-comsumption-minimization-radius-area .plant-image {
  border-radius: 30px;
}

.energy-comsumption-minimization-radius-area .product-image {
  border-radius: 20px;
}

.by-our-product {
  display: flex;
  gap: 35px;
  width: 1440px;
}

@media (max-width: 768px) {
  .by-our-product {
    flex-direction: column;
    width: 100%;
  }
}

.by-our-product .by-our-product-text {
  display: flex;
  gap: 54px;
  flex-direction: column;
  justify-content: center;
}

.by-our-product .by-our-product-text .by-our-product-text-title {
  margin-top: 77.5px;
  color: #000;
  text-align: left;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
}

@media (max-width: 768px) {
  .by-our-product .by-our-product-text .by-our-product-text-title {
    margin-top: 0px;
    font-size: 1.25rem;
    line-height: 1.3541666667rem;
    white-space: pre-wrap;
  }
}

.by-our-product .by-our-product-text .by-our-product-text-content {
  color: #000;
  /* font-family: 'NotoSans' , sans-serif !important; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
}

@media (max-width: 768px) {
  .by-our-product .by-our-product-text .by-our-product-text-content {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    white-space: pre-wrap;
  }
}

.by-our-product .by-our-product-text .by-our-product-title {
  margin-top: 54px;
}
</style>
