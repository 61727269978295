import _ from "@/common/helper/lodash";
import ProductService from "./product.service";

import request from './request'
const urlPrefix = ''

const LikeService = {
  saveLike (postData) {
    return request({
      url: `${urlPrefix}/like/save`,
      method: 'post',
      data: postData
    })
  },
  deleteLike (contentId) {
    return request({
      url: `${urlPrefix}/like/delete`,
      method: 'post',
      headers: { "Content-Type": "application/json" },
      data: contentId
    })
  },
  deleteByContent (contentId) {
    return request({
      url: `${urlPrefix}/like/deleteByContent`,
      method: 'post',
      headers: { "Content-Type": "application/json" },
      data: contentId
    })
  },

  deleteByContentList (contentIdList) {
    return request({
      url: `${urlPrefix}/like/deleteByContentList`,
      method: 'post',
      headers: { "Content-Type": "application/json" },
      data: contentIdList
    })
  },

  getLikeList (query, pageIndex, pageSize, orgContent) {
    return request({
      url: `${urlPrefix}/like/list`,
      method: 'post',
      data: query,
      params: { pageIndex, pageSize, orgContent }
    })
  },


  getUsMultiLikeList (query, pageIndex, pageSize) {
    return request({
      url: `${urlPrefix}/like/multiList`,
      method: 'post',
      data: query,
      params: { pageIndex, pageSize }
    })
  },

  getLastLike (postData) {
    return request({
      url: `${urlPrefix}/like/lastLike`,
      method: 'post',
      data: postData
    })
  },

  getUsTotalCount () {
    return request({
      url: `${urlPrefix}/like/totalCount`,
      method: 'post'
    })
  },

  getGLTotalCount (countryCode) {
    const t = this
    t.nationCode = countryCode;            /* 국가 코드*/

    const pdLikeList = JSON.parse(sessionStorage.getItem("PD")) || [];
    const pjLikeList = JSON.parse(sessionStorage.getItem("PJ")) || [];

    let pdLikeCnt = []
    let pjLikeCnt = []

    _.filter(pdLikeList, function (o) {
      if (o.nationCode == '_'+t.nationCode) {
        pdLikeCnt.push(o.id)
      }
    })

    _.filter(pjLikeList, function (o) {
      if (o.nationCode == '_'+t.nationCode) {
        pjLikeCnt.push(o.id)
      }
    })

    return pdLikeCnt.length + pjLikeCnt.length;
  },

  deleteContentsByNationCode(nationCode, contentIdList, cateType) {
    if(nationCode == '_US')
    {
      return this.deleteByContentList(contentIdList);
    }else{

      let pdLikeList = JSON.parse(sessionStorage.getItem('PD')) || [];
      let pjLikeList = JSON.parse(sessionStorage.getItem('PJ')) || [];

      _.forEach(contentIdList, async (v) => {
        _.remove(pdLikeList, function (c) { /* 중복 제거 */
          if (c.nationCode == nationCode && c.id == v){
            return c.nationCode == nationCode && c.id == v
          } /* end of if */
        })

        _.remove(pjLikeList, function (c) { /* 중복 제거 */
          if (c.nationCode == nationCode &&c.cateType == cateType && c.id == v){
            return c.nationCode == nationCode && c.cateType == cateType && c.id == v
          } /* end of if */
        })
      });

      sessionStorage.setItem(`PJ`, JSON.stringify(pjLikeList));
      sessionStorage.setItem(`PD`, JSON.stringify(pdLikeList));
    }
  },

  async getMultiLikeListByNationCode (nationCode, query, pageIndex, pageSize) {
    if(nationCode == '_US')
    {
      let queryList = [];
      for(let cate of query.cateTypeList)
      {
        queryList.push({likeType: query.likeType, cateType: cate});
      }

      let res =  await this.getUsMultiLikeList(queryList, pageIndex, pageSize);
      return res.data;
    }else{
      return this.getMultiGlLikeList(query, pageIndex, pageSize);
    }
  },

  async getLikeListByNationCode (nationCode, query, pageIndex, pageSize) {
    if(nationCode == '_US')
    {
      return this.getUsLikeList(query, pageIndex, pageSize);
    }else{
      return this.getGlLikeList(query, pageIndex, pageSize);
    }
  },


  async getUsLikeList (query, pageIndex, pageSize, orgContent) {
    let likeInfo = await this.getLikeList (query, pageIndex, pageSize, orgContent)

    return likeInfo.data;
  },

  async getMultiGlLikeList (query, pageIndex, pageSize) {
    let result = {};

    for( let cateType of query.cateTypeList){
      let newQuery = JSON.parse(JSON.stringify(query));
      delete newQuery.cateTypeList;
      newQuery.cateType = cateType
      result[cateType == null ? 'ALL' : cateType] = await this.getGlLikeList(newQuery, pageIndex, pageSize)
    }

    return result;
  },

  async getGlLikeList (query, pageIndex, pageSize) {
    const likeList = JSON.parse(sessionStorage.getItem(query.likeType)) || [];
    const countryLikeList = likeList.filter((element) => element.nationCode == '_'+this.nationCode);
    let totalCount = 0;
    let contentIdList = [];
    if(query.cateType)
    {
      let byCateType = _.groupBy(countryLikeList, function(b) { return b.cateType})
      totalCount = (byCateType[query.cateType] || []).length;

      contentIdList = _.map(byCateType[query.cateType], 'id');
    }else{
      totalCount = countryLikeList.length;
      contentIdList = _.map(countryLikeList, 'id');
    }
    let totalPage = totalCount / pageSize;
    totalPage = parseInt( (0 == totalPage || 0 != totalCount % pageSize) ? ++totalPage : totalPage );

    const startIndex = (pageIndex-1)*pageSize + 1;
    const endIndex = startIndex+pageSize;

    const contentIdListToFetch = _.slice(contentIdList, startIndex-1, endIndex-1);
    let likeResponse = await ProductService.getContentByIds(contentIdListToFetch);

    likeResponse.data.forEach(function (element) {
      element.content.liked = true;
    });

    let likeInfo = {
      totalCount: totalCount,
      pageIndex,
      pageSize,
      totalPage,
      lastPage: pageIndex >= totalPage,
      list: likeResponse.data
    };

    return likeInfo;
  },
}

export default LikeService

