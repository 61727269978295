<template>
  <div class="major-design-performance-card">
    <div class="header">
      <img class="header-icon" :src="icon">
      <pre class="header-text">{{ headerText }}</pre>
    </div>
    <div class="picture-area">
      <img
        class="picture"
        v-for="(item, index) in pictures"
        :key="index"
        :src="item"
        @mouseover="selectPicture(index)"
      >
    </div>
    <div class="text-area">
      <pre class="text">{{ text }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    headerText: {
      type: String
    },
    pictures: {
      type: Array
    },
    texts: {
      type: Array
    }
  },
  data: function () {
    return {
      selectedIndex: 0
    }
  },
  computed: {
    text () {
      return this.texts[this.selectedIndex]
    }
  },
  methods: {
    selectPicture (index) {
      this.selectedIndex = index
    }
  }
}
</script>

<style scoped>
.major-design-performance-card {
  width: 1430px;
}

@media (max-width: 768px) {
  .major-design-performance-card {
    width: 100%;
  }
}

.major-design-performance-card .header {
  display: flex;
  justify-content: space-between;
}

.major-design-performance-card .header .header-icon {
  width: fit-content;
}

@media (max-width: 768px) {
  .major-design-performance-card .header .header-icon {
    width: 40%;
  }
}

.major-design-performance-card .header .header-text {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 180% */
}

.major-design-performance-card .picture-area {
  display: flex;
  gap: 5px;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .major-design-performance-card .picture-area {
    overflow:scroll;
  }
}

.major-design-performance-card .picture-area .picture {
  width: fit-content;
  filter: brightness(50%);
}

@media (max-width: 768px) {
  .major-design-performance-card .picture-area .picture {
    width: 95%;
  }
}

.major-design-performance-card .picture-area .picture:hover {
  filter: brightness(100%);
}

.major-design-performance-card .text-area {
  background: #F9F9F9;
  padding: 30px 0px 30px 30px;
  min-height: 205px;
}

@media (max-width: 768px) {
  .major-design-performance-card .text-area {
    min-height: 0px;
  }
}

.major-design-performance-card .text-area .text {
  color: #000;
  font-family: 'NotoSans' , sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 200% */
}

@media (max-width: 768px) {
  .major-design-performance-card .text-area .text {
    font-size: 0.75rem;
    line-height: 1.0416666667rem;
    white-space: pre-wrap;
  }
}
</style>
