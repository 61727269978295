<template>
  <div class="social-contribution-strategy-system-key">
    <SocialResponsibilityBorderTitle
      :title="borderTitle"
      padding="15px 80px"
    />

    <OverviewSectionItem>
      <div class="social-contribution-strategy-system-key-content">
        <KeyActivitiesCard
          v-for="(item, index) in cardItems"
          :key="index"
          :number="item.number"
          :text="item.text"
        />
      </div>
    </OverviewSectionItem>
  </div>
</template>

<script>
import OverviewSectionItem from '../../../overview/components/OverviewSectionItem.vue'
import KeyActivitiesCard from './KeyActivitiesCard.vue'
import SocialResponsibilityBorderTitle from './SocialResponsibilityBorderTitle.vue'

export default {
  components: {
    SocialResponsibilityBorderTitle,
    KeyActivitiesCard,
    OverviewSectionItem
  },
  props: {
    borderTitle: {
      type: String
    },
    cardItems: {
      type: Array
    }
  }

}
</script>

<style>
.social-contribution-strategy-system-key-content {
  background-image: url( "../../../../../../public/images/aboutus/sustainability/social_responsibility/background-arrow.png" );
  background-repeat: no-repeat;
  background-position: center 100%;
  height: 494px;
  display: flex;
  justify-content: center;
  gap: 25px;
}

@media (max-width: 768px) {
  .social-contribution-strategy-system-key {
    margin-top: 84px;
  }

  .social-contribution-strategy-system-key-content {
    height: 100%;
    flex-direction: column;
    width: 100%;
  }
}
</style>
