<template>
  <OverviewSmallSection
  title="Key Project"
  fontFamily="Lato"
  fontWeight="700"
  v-if="nationCode == '_US' || nationCode == '_GL'"
  >
    <OverviewSectionItem>
      <div class="card-area">
        <KeyProjectCard
          v-for="(it, idx) in cardItem"
          :key="idx"
          :imageSrc="it.imageSrc"
          :txt="it.txt"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- JP -->
  <OverviewSmallSection
    title="主要プロジェクト"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_JP'"
  >
    <OverviewSectionItem>
      <div class="card-area">
        <KeyProjectCard
          v-for="(it, idx) in cardItem_jp"
          :key="idx"
          :imageSrc="it.imageSrc"
          :txt="it.txt"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- RU -->
  <OverviewSmallSection
    title="Основной проект"
    fontFamily="Lato"
    fontWeight="700"
    v-else-if="nationCode == '_RU'"
  >
    <OverviewSectionItem>
      <div class="card-area">
        <KeyProjectCard
          v-for="(it, idx) in cardItem_ru"
          :key="idx"
          :imageSrc="it.imageSrc"
          :txt="it.txt"
        />
      </div>
    </OverviewSectionItem>
  </OverviewSmallSection>
<!-- CN -->
  <OverviewSmallSection
  title="重点项目"
  fontFamily="Lato"
  fontWeight="700"
  v-else-if="nationCode == '_CN'"
>
  <OverviewSectionItem>
    <div class="card-area">
      <KeyProjectCard
        v-for="(it, idx) in cardItem_cn"
        :key="idx"
        :imageSrc="it.imageSrc"
        :txt="it.txt"
      />
    </div>
  </OverviewSectionItem>
</OverviewSmallSection>
</template>

<script>
import OverviewSectionItem from '../../components/OverviewSectionItem.vue'
import OverviewSmallSection from '../../components/OverviewSmallSection.vue'
import KeyProjectCard from './KeyProjectCard.vue'

export default {
  components: {
    OverviewSmallSection,
    OverviewSectionItem,
    KeyProjectCard
  },

  data: function () {
    return {
      countryCode: '',
      countryCodeSub: '',
      cardItem: [
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-1.png',
          txt: `Securing profitability through strengthened
competitiveness in automotive materials
& components and industrial film businesses`
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-2.png',
          txt: 'R&D based on market and customer needs'
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-3.png',
          txt: 'Innovation for global top-level manufacturing'
        }
      ],
      cardItem_jp: [
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-1.png',
          txt: `自動車用部材・産業用フィルム事業の競争力強化による収益確保`
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-2.png',
          txt: '市場と顧客のニーズに基づいた研究開発'
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-3.png',
          txt: '世界トップレベルのものづくりのためのイノベーション'
        }
      ],
      cardItem_ru: [
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-1.png',
          txt: `Обеспечение прибыльности за счет повышения конкурентоспособности в сегменте автомобильных материалов и компонентов, а также промышленной пленки`
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-2.png',
          txt: 'Исследования и разработки, основанные на потребностях рынка и клиентов'
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-3.png',
          txt: 'Инновации для глобального производства высшего уровня'
        }
      ],
      cardItem_cn: [
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-1.png',
          txt: `通过加强汽车材料和零部件以及产业用膜业务的竞争力来确保盈利能力`
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-2.png',
          txt: '立足于市场和客户需求的R&D'
        },
        {
          imageSrc: 'images/aboutus/overview/vision_strategies/key-project-icon-3.png',
          txt: '全球一流水平的制造创新'
        }
      ]
    }
  },
  async created () {
    const t = this
    t.nationCode = t.$countryCode /* 국가 코드 */
    t.countryCodeSub = t.nationCode.substring(1)
  },
}
</script>

<style scoped>
.card-area {
  display: flex;
  flex-direction: row;
  gap: 44px;
}

@media (max-width: 768px) {
  .card-area {
    flex-direction: column;
    align-items: center;
  }
}
</style>
