import Vue from 'vue'

/* -------------------------Init Multi-language------------------------------ */

import Cookies from 'js-cookie'

/* ------------------------Vue Global Variable------------------------------ */
import { $utils, $document, $auth, $lodash, $breakOn } from '@/common/helper'

import Filters from './filters'

import Mixin from './mixins/globalMixin'

const contryCodeStr = $utils.getCountryCode()
const region = $utils.getRegion()
Vue.prototype.$_ = $lodash
Vue.prototype.$utils = $utils
Vue.prototype.$auth = $auth
Vue.prototype.$document = $document
Vue.prototype.$imagePath = process.env.VUE_APP_IMAGE_PATH
Vue.prototype.$countryCode = '_' + contryCodeStr
Vue.prototype.$region = region
Vue.prototype.$baseUrl = region === '' ? '' : '/' + region
Vue.prototype.$breakOn = $breakOn

if (contryCodeStr === 'US' || contryCodeStr === 'GL') {
  // uS, GL has himacs, viatera, hflor
  if ($utils.getPrdCateId('himacsId') === 0 || $utils.getPrdCateId('viateraId') === 0 || $utils.getPrdCateId('hflorId') === 0) {
    $utils.getProductCategory()
  }
} else {
  // EN, FR, DE, IT, ES, UK has himacs, hflor
  if ($utils.getPrdCateId('himacsId') === 0 || $utils.getPrdCateId('hflorId') === 0) {
    $utils.getProductCategory()
  }
}

for (const key in Filters) {
  Vue.filter(key, Filters[key])
}

{
  const cookieBreakOn = Cookies.get('breakOn')
  if (!$lodash.isEmpty(cookieBreakOn)) {
    $breakOn.breakOn(document, cookieBreakOn)
  } else {
    const urlParams = new URLSearchParams(window.location.search)
    const breakOn = urlParams.get('breakOn')
    if (!$lodash.isEmpty(breakOn)) {
      Cookies.set('breakOn', breakOn)
      $breakOn.breakOn(document, breakOn)
    }
  }
}

Vue.directive('plaintext', {
  bind (el, binding, vnode) {
    el.innerHTML = el.innerText
  }
})

$breakOn.breakOn(document, 'title')
