
import ProsService from '@/server/pros.service'
import _ from '@/common/helper/lodash'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import { isArray } from 'lodash'

const CATE_DOCU_MAPPING = {
  TERACANTO_COLOR_GL: 'DOC_TERACANTO_GL',
  HIMACS_COLOR_GL: 'DOC_HIMACS_GL',
  VIATERA_COLOR_GL: 'DOC_VIATERA_GL',
  BENIF_COLOR_GL: 'DOC_FILM_INT_GL',
  DECO_COLOR_GL: 'DOC_FILM_DECO_GL',
  EXTERIOR_COLOR_GL: 'DOC_FILM_EXT_GL',
  BORTE_COLOR_GL: 'DOC_BORTE_GL',

  FLOOR_LUX_GL: 'DOC_FLOOR_LUX_GL',
  FLOOR_HOM_GL: 'DOC_FLOOR_HOM_GL',
  FLOOR_HET_GL: 'DOC_FLOOR_HET_GL',
  FLOOR_SPT_GL: 'DOC_FLOOR_SPT_GL',
  FLOOR_SPC_GL: 'DOC_FLOOR_SPC_GL',
  FLOOR_LOS_GL: 'DOC_FLOOR_LOS_GL',

  VIZUON_VT_GL: 'DOC_VIZUON_VT_GL',
  VIZUON_SD_GL: 'DOC_VIZUON_SD_GL',
  VIZUON_IS_GL: 'DOC_VIZUON_IS_GL',
  VIZUON_AR_GL: 'DOC_VIZUON_AR_GL',
  VIZUON_CB_GL: 'DOC_VIZUON_CB_GL',
  VIZUON_ES_GL: 'DOC_VIZUON_ES_GL',

  PRD_AUTO_GL: 'DOC_AUTO_GL',

  TERACANTO_COLOR_US: 'DOC_TERACANTO_US',
  HIMACS_COLOR_US: 'DOC_HIMACS_US',
  VIATERA_COLOR_US: 'DOC_VIATERA_US',
  BENIF_COLOR_US: 'DOC_FILM_INT_US',
  DECO_COLOR_US: 'DOC_FILM_DECO_US',
  EXTERIOR_COLOR_US: 'DOC_FILM_EXT_US',
  BORTE_COLOR_US: 'DOC_BORTE_US',

  FLOOR_LUX_US: 'DOC_FLOOR_LUX_US',
  FLOOR_HOM_US: 'DOC_FLOOR_HOM_US',
  FLOOR_HET_US: 'DOC_FLOOR_HET_US',
  FLOOR_SPT_US: 'DOC_FLOOR_SPT_US',
  FLOOR_SPC_US: 'DOC_FLOOR_SPC_US',
  FLOOR_LOS_US: 'DOC_FLOOR_LOS_US',

  VIZUON_VT_US: 'DOC_VIZUON_VT_US',
  VIZUON_SD_US: 'DOC_VIZUON_SD_US',
  VIZUON_IS_US: 'DOC_VIZUON_IS_US',
  VIZUON_AR_US: 'DOC_VIZUON_AR_US',
  VIZUON_CB_US: 'DOC_VIZUON_CB_US',
  VIZUON_ES_US: 'DOC_VIZUON_ES_US',

  PRD_AUTO_US: 'DOC_AUTO_US'
}

export function getProductSpec (pdCategoryType, content, nationCode) {
  const productAttribute = [
    { ...content.attribute, title: content.content.title }
  ]
  if (content.attribute.bundle_product) {
    productAttribute.push(...content.attribute.bundle_product.map(b => ({
      ...b.attribute,
      title: b.content.title
    })))
  }

  let productSpecs = []
  /* 스펙 가져오기 prod-specs */
  if (pdCategoryType === 'VIATERA') {
    if (nationCode === '_GL' || nationCode === '_JP' || nationCode === '_CN'|| nationCode === '_RU') {
      productSpecs = productAttribute.map(pa => [
        { '': pa.title },
        { Finish: pa.finishText?.[0] || '-' },
        { 'Slab Size (mm)': pa.slab_sizeText || '-' },
        { 'Thickness (cm)': pa.thicknessText || '-' },
        { 'Weight (kg)': pa.weightText || '-' },
        { Remark: pa.remark && pa.remark !== '[]' ? pa.remark : '-' }
      ])
    } else if (nationCode === '_US' || nationCode === '_CA' || nationCode === '_CF') {
      productSpecs = productAttribute.map(pa => [
        { '': pa.title },
        { Finish: pa.finishText?.[0] || '-' },
        { 'Slab Size (inch)': pa.slab_sizeText || '-' },
        { 'Thickness (cm)': pa.thicknessText || '-' },
        { 'Weight (lbs)': pa.weightText || '-' },
        { Remark: pa.remark && pa.remark !== '[]' ? pa.remark : '-' }
      ])
    }
  } else if (pdCategoryType === 'HIMACS') {
    if (nationCode === '_GL' || nationCode === '_JP' || nationCode === '_CN'|| nationCode === '_RU') {
      productSpecs = productAttribute.map(pa => [
        { '': pa.title },
        { 'Slab Size (mm/inch)': pa.dimensionText || '-' },
        { 'Thickness (mm/inch)': pa.thicknessText || '-' },
        { 'Weight (kg)': pa.weightText || '-' },
        { 'Adhesive code': pa.adhesive_code || '-' },
        { 'Adhesive name': pa.adhesive_name || '-' }
      ])
    } else if (nationCode === '_US' || nationCode === '_CA' || nationCode === '_CF') {
      productSpecs = productAttribute.map(pa => [
        { '': pa.title },
        { 'Slab Size (inch)': pa.dimensionText || '-' },
        { 'Thickness (inch)': pa.thicknessText || '-' },
        { 'Weight (ibs)': pa.weightText || '-' },
        { 'Adhesive code': pa.adhesive_code || '-' },
        { 'Adhesive name': pa.adhesive_name || '-' }
      ])
    }
  } else if (pdCategoryType === 'TERACANTO') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { Finish: pa.finishText || '-' },
      { 'Dimension (mm)': pa.dimension || '-' },
      { Thickness: pa.thicknessText || '-' },
      { Bookmatch: pa.bookmatchText || '-' },
      { Remark: pa.remarkText || '-' }
    ]
    )
  } else if (pdCategoryType === 'HFLOR') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { Dimensions: pa.dimensionText || '-' },
      { Thickness: pa.thicknessText || '-' },
      { 'Wear Layer': pa.wear_layerText || '-' },
      { Pattern: pa.patternText || '-' },
      { Installation: pa.prdType || '-' }
    ]
    )
  } else if (pdCategoryType === 'DECO') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { PET: pa.pet ? 'O' : '-' },
      { PVC: pa.pvc ? 'O' : '-' },
      { ACRYLIC: pa.acrylic ? 'O' : '-' },
      { Thickness: pa.thicknessText || '-' },
      { Width: pa.widthText || '-' }
    ]
    )
  } else if (pdCategoryType === 'BENIF') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { Dimensions: pa.dimensionText || '-' },
      { 'Repeat Image': pa.repeat || '-' },
      { Thickness: pa.thicknessText || '-' },
      { 'Fire Resistance': pa.fire_resistanceText || '-' }
    ]
    )
  } else if (pdCategoryType === 'VIZUON') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { 'Film Type Material': pa.material || '-' },
      { 'Film Type Thickness': pa.thickness || '-' },
      { 'Film Type Color': pa.ft_color || '-' },
      { 'Film Type Adhesive Type': pa.adhesive_typeText || '-' },
      { 'Adhesive Type Color': pa.adhesive_color || '-' },
      { 'Adhesive Type Air Free': pa.air_freeText || '-' },
      { Liner: pa.liner || '-' }
    ]
    )
  } else if (pdCategoryType === 'EXTERIOR') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { Grade: pa.gradeText?.join(', ') || '-' },
      { Thickness: pa.thicknessText || '-' },
      { Width: `${pa.widthText || ''} ${content.attribute?.width2Text ? `/ ${content.attribute?.width2Text}` : ''}` || '-' },
      { Length: pa.lengthText || '-' }
    ]
    )
  } else if (pdCategoryType === 'BORTE') {
    productSpecs = productAttribute.map(pa => [
      { '': pa.title },
      { Dimenssion: pa.dimensionText || '-' },
      { 'Surface material': pa.surfaceMaterialText || '-' },
      { 'Total thickness': pa.thicknessText || '-' },
      { 'Formaldehyde emission (mg/l)': pa.emissionText || '-' }
    ]
    )
  }

  return productSpecs
}

export async function getDocumentation (categoryCode) {
  const docuCateCode = CATE_DOCU_MAPPING[categoryCode]
  const docu = {
    catalogueList: { disabled: false, list: [] },
    technicalList: { disabled: false, list: [] },
    installationGuideList: { disabled: false, list: [] },
    warrantyList: { disabled: false, list: [] },
    careList: { disabled: false, list: [] },
    certificationList: { disabled: false, list: [] }
  }

  if (isUndefined(docuCateCode)) {
    return docu
  }
  const orderType = []
  const orderField = []
  const pageIndex = 1
  const pageSize = 9999

  try {
    const docContentRes = await ProsService.getDocumentationList(docuCateCode, [], '', pageIndex, pageSize, orderField, orderType)

    if (docContentRes && docContentRes.data && Array.isArray(docContentRes.data.list)) {
      docContentRes.data.list.forEach(o => {
        switch (o.attribute.doctype) {
          case 'Catalogue':
            docu.catalogueList.list.push(o)
            break
          case 'Technical_Datasheet':
            docu.technicalList.list.push(o)
            break
          case 'Installation_Guide':
            docu.installationGuideList.list.push(o)
            break
          case 'Warranty':
            docu.warrantyList.list.push(o)
            break
          case 'Care':
            docu.careList.list.push(o)
            break
          case 'Certification':
            docu.certificationList.list.push(o)
            break
        }
      })
    }
  } catch (error) {
    console.error('Error fetching documentation:', error)
  }

  return docu
}

export function getImageInfo (content) {
  const imageInfo = { full_slap: {}, cover: {}, imgPage: [] }

  if (!isEmpty(content.attribute.full_slap_thumb)) {
    imageInfo.full_slap = {
      thumb: content.attribute.full_slap_thumb,
      image: content.attribute.full_slap_image,
      detail: content.attribute.full_slap_detail,
      enlarge: true
    }
    imageInfo.imgPage.push(imageInfo.full_slap)
  }

  if (!isEmpty(content.attribute.image)) {
    const cover = !isEmpty(content.content.cover) ? content.content.cover : content.orgContent.cover

    imageInfo.cover = {
      thumb: cover,
      image: content.attribute.image,
      detail: content.attribute.detail_image
    }
    imageInfo.imgPage.push(imageInfo.cover)
  }

  if (!isEmpty(content.attribute.youtube) && !isEmpty(content.attribute.video_thumb)) {
    const youtube = {
      thumb: content.attribute.video_thumb,
      image: content.attribute.youtube,
      youtube: true
    }
    imageInfo.imgPage.push(youtube)
  }

  if (!isEmpty(content.attribute.color_image)) {
    let colorImage = []
    if (isArray(content.attribute.color_image)) {
      colorImage = content.attribute.color_image
    } else {
      try {
        colorImage = JSON.parse(content.attribute.color_image)
      } catch (e) {}
    }

    if (isArray(colorImage)) {
      _.forEach(colorImage, async (v) => {
        if (!isEmpty(v.image)) {
          imageInfo.imgPage.push(v)
        }
      })
    }
  }
  return imageInfo
}

export function getEcomPrdData (data) {
  let ecom_prd_brand, ecom_prd_category

  if (data.parents.length === 5) {
    ecom_prd_brand = data.parents[2].name
    ecom_prd_category = data.parents[1].name
  } else {
    ecom_prd_brand = data.parents[1].name
    ecom_prd_category = ''
  }
  return {
    ecom_prd_brand,
    ecom_prd_category
  }
}
