var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nationCode == "_US" || _vm.nationCode == "_GL"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Materiality Assessment" } },
        [
          _c("OverviewSectionItem", [_c("MaterialityAssessmentImage")], 1),
          _c(
            "OverviewSectionItem",
            { attrs: { title: "10 Core Material Issues" } },
            [
              _c(
                "div",
                { staticClass: "materiality-assessment-listcard-area" },
                _vm._l(_vm.contentList, function (content, index) {
                  return _c("MaterialityAssessmentListCard", {
                    key: index,
                    attrs: { contentList: content },
                  })
                }),
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm.nationCode == "_JP"
    ? _c(
        "OverviewSection",
        { attrs: { title: "重要性評価" } },
        [
          _c("OverviewSectionItem", [_c("MaterialityAssessmentImage")], 1),
          _c("OverviewSectionItem", { attrs: { title: "10 の主要重要課題" } }, [
            _c(
              "div",
              { staticClass: "materiality-assessment-listcard-area" },
              _vm._l(_vm.contentListJp, function (content, index) {
                return _c("MaterialityAssessmentListCard", {
                  key: index,
                  attrs: { contentList: content },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_RU"
    ? _c(
        "OverviewSection",
        { attrs: { title: "Оценка существенности" } },
        [
          _c("OverviewSectionItem", [_c("MaterialityAssessmentImage")], 1),
          _c("OverviewSectionItem", { attrs: { title: "10 の主要重要課題" } }, [
            _c(
              "div",
              { staticClass: "materiality-assessment-listcard-area" },
              _vm._l(_vm.contentListRu, function (content, index) {
                return _c("MaterialityAssessmentListCard", {
                  key: index,
                  attrs: { contentList: content },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm.nationCode == "_CN"
    ? _c(
        "OverviewSection",
        { attrs: { title: "重要性评估" } },
        [
          _c("OverviewSectionItem", [_c("MaterialityAssessmentImage")], 1),
          _c("OverviewSectionItem", { attrs: { title: "10个核心材料问题" } }, [
            _c(
              "div",
              { staticClass: "materiality-assessment-listcard-area" },
              _vm._l(_vm.contentListCn, function (content, index) {
                return _c("MaterialityAssessmentListCard", {
                  key: index,
                  attrs: { contentList: content },
                })
              }),
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }