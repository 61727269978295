var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "wrap" } },
    [
      _c("HeaderComponent"),
      _c("GnbComponent"),
      _c("div", { staticClass: "content cntu-view fasr-view" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c(
              "li",
              {
                directives: [
                  { name: "hover-cursor", rawName: "v-hover-cursor" },
                ],
                staticClass: "ico-home",
                on: {
                  click: function ($event) {
                    _vm.gotolink(`/${_vm.countryCodeSub.toLowerCase()}/index`)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("findASalesRep.text.FC0040_001")))]
            ),
            _c("li", [_vm._v(_vm._s(_vm.$t("findASalesRep.text.FC0040_002")))]),
            _c("li", [_vm._v(_vm._s(_vm.$t("findASalesRep.text.FC0040_003")))]),
          ]),
        ]),
        _c("div", { staticClass: "container cntu-box" }, [
          _c("div", { staticClass: "w-con-02" }, [
            _c("h1", { staticClass: "tt-1 mb-88 tac inner" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("findASalesRep.text.FC0040_003")) + " "
              ),
            ]),
            _vm.siteCountryCd === "_GL"
              ? _c("div", { staticClass: "find_gl_wrap" }, [
                  _c("div", { staticClass: "find-search" }, [
                    _c("div", { staticClass: "tbl-input" }, [
                      _c("table", [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "find_search_padding" }, [
                              _c("label", { staticClass: "tbl_label" }, [
                                _vm._v("Product"),
                              ]),
                              _c("div", { staticClass: "sel-box" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: { type: "button" },
                                  },
                                  [
                                    _c("span", { staticClass: "placeholder" }, [
                                      _vm._v(_vm._s(_vm.prdText)),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "sel-list" }, [
                                  _c(
                                    "ul",
                                    { staticClass: "box" },
                                    _vm._l(_vm.productList, function (item) {
                                      return _c("li", { key: item.id }, [
                                        _c(
                                          "button",
                                          {
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectPrdOption(
                                                  item.text
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("td", { staticClass: "find_search_padding" }, [
                              _c("label", { staticClass: "tbl_label" }, [
                                _vm._v("Area"),
                              ]),
                              _c("div", { staticClass: "sel-box" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleDropdown },
                                  },
                                  [
                                    _c("span", { staticClass: "placeholder" }, [
                                      _vm._v(_vm._s(_vm.areaName)),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "sel-list" }, [
                                  _c("ul", { staticClass: "box" }, [
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType(
                                                "Russia & CIS"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("contactus.text.ru_cis")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType(
                                                "Latin America"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "contactus.text.latin_ameria"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType(
                                                "Middle East"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "contactus.text.middle_east"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType("Oceania")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("contactus.text.oceania")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType("Asia")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "contactus.text.south_asia"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType("Japan")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("contactus.text.japan")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.categoryType("China")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("contactus.text.china")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("td", { staticClass: "find_search_padding" }, [
                              _c("label", { staticClass: "tbl_label" }, [
                                _vm._v("Country"),
                              ]),
                              _c("div", { staticClass: "sel-box" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleDropdown },
                                  },
                                  [
                                    _c("span", { staticClass: "placeholder" }, [
                                      _vm._v(_vm._s(_vm.countryNm)),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "sel-list" }, [
                                  _c(
                                    "ul",
                                    { staticClass: "box" },
                                    _vm._l(
                                      _vm.glCountryList,
                                      function (country) {
                                        return _c("li", [
                                          _c(
                                            "button",
                                            {
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectCountry(
                                                    country.id.value,
                                                    country.text
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(country.text) + " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "more-box" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_search h50",
                          attrs: { type: "button", id: "searchBtn" },
                          on: { click: _vm.search },
                        },
                        [_vm._v(" Search ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "find-address-wrap" },
                    _vm._l(_vm.groupedSearchResult, function (row, rowIndex) {
                      return _c(
                        "div",
                        { key: rowIndex, staticClass: "row" },
                        _vm._l(row, function (item, colIndex) {
                          return _c(
                            "div",
                            { key: colIndex, staticClass: "col" },
                            [
                              _c("div", { staticClass: "find-address" }, [
                                _c("div", { staticClass: "title-wrap" }, [
                                  _c("h5", [_vm._v(_vm._s(item.repName))]),
                                  _c("div", [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../../public/images/icon-mail.png"),
                                        alt: "mail",
                                      },
                                    }),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "mailto:" + item.repEmail,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.repEmail))]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "content-wrap" }, [
                                  _c("div", [
                                    _c("p", [_vm._v("Product")]),
                                    _c(
                                      "div",
                                      { staticClass: "badges" },
                                      _vm._l(
                                        item.badgeList,
                                        function (badge, badgeIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: badgeIndex,
                                              class: _vm.getBadgeClass(badge),
                                            },
                                            [_vm._v(" " + _vm._s(badge) + " ")]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _c("div", [
                                    _c("p", [_vm._v("Area")]),
                                    _c("span", [_vm._v(_vm._s(item.areaName))]),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.siteCountryCd === "_US"
              ? _c("div", { staticClass: "find_us_wrap" }, [
                  _c("div", { staticClass: "find-search" }, [
                    _c("div", { staticClass: "tbl-input" }, [
                      _c("table", [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "find_search_padding" }, [
                              _c("label", { staticClass: "tbl_label" }, [
                                _vm._v("Product"),
                              ]),
                              _c("div", { staticClass: "sel-box" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-sel",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleDropdown },
                                  },
                                  [
                                    _c("span", { staticClass: "placeholder" }, [
                                      _vm._v(_vm._s(_vm.prdText)),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "sel-list" }, [
                                  _c(
                                    "ul",
                                    { staticClass: "box" },
                                    _vm._l(_vm.productList, function (item) {
                                      return _c("li", { key: item.id }, [
                                        _c(
                                          "button",
                                          {
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectPrdOption(
                                                  item.text
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("td", { staticClass: "find_search_padding" }, [
                              _c("label", { staticClass: "tbl_label" }, [
                                _vm._v(
                                  "Search by Address, City, State, Zipcode"
                                ),
                              ]),
                              _c("input", {
                                ref: "textInput",
                                staticClass: "inp-text",
                                attrs: {
                                  type: "text",
                                  placeholder: "Placeholder",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.search.apply(null, arguments)
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "more-box" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_search h50",
                          attrs: { type: "button", id: "searchBtn" },
                          on: { click: _vm.search },
                        },
                        [_vm._v(" Search ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "find-address-wrap" },
                    _vm._l(_vm.groupedSearchResult, function (row, rowIndex) {
                      return _c(
                        "div",
                        { key: rowIndex, staticClass: "row" },
                        _vm._l(row, function (item, colIndex) {
                          return _c(
                            "div",
                            { key: colIndex, staticClass: "col" },
                            [
                              _c("div", { staticClass: "find-address" }, [
                                _c("div", { staticClass: "title-wrap" }, [
                                  _c("h5", [_vm._v(_vm._s(item.repName))]),
                                  _c("h6", [_vm._v(_vm._s(item.repTypeName))]),
                                  _c("div", [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../../public/images/icon-mail.png"),
                                        alt: "mail",
                                      },
                                    }),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "mailto:" + item.repEmail,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.repEmail))]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "content-wrap" }, [
                                  item.inquiryTypeName
                                    ? _c("div", [
                                        _c("p", [_vm._v("For")]),
                                        _c("span", [
                                          _vm._v(_vm._s(item.inquiryTypeName)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _c("div", [
                                    _c("p", [_vm._v("Product")]),
                                    _c(
                                      "div",
                                      { staticClass: "badges" },
                                      _vm._l(
                                        item.badgeList,
                                        function (badge, badgeIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: badgeIndex,
                                              class: _vm.getBadgeClass(badge),
                                            },
                                            [_vm._v(" " + _vm._s(badge) + " ")]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _c("div", [
                                    _c("p", { staticClass: "resion_toggle" }, [
                                      _vm._v("Region"),
                                    ]),
                                    _c("span", { staticClass: "region_txt" }, [
                                      _vm._v(_vm._s(item.stateCdName)),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _c("div"),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMore,
                    expression: "isMore",
                  },
                ],
                staticClass: "btn_learn_more",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showMore()
                  },
                },
              },
              [
                _c("span", { staticClass: "txt" }, [_vm._v("Load more")]),
                _c("img", {
                  attrs: {
                    src: require("../../../public/images/svg_icon/icon_plus.svg"),
                    alt: "plus",
                  },
                }),
              ]
            ),
            _vm.showNoResult
              ? _c("div", { staticClass: "no_result" }, [
                  _c("img", {
                    attrs: {
                      src: "https://img.lxhausys.com/public/images/support/no_result.png",
                      alt: "noresult",
                    },
                  }),
                  _c("p", [_vm._v("No Result found")]),
                  _c("span", [
                    _vm._v(
                      "If you do not find what you are looking for, please"
                    ),
                  ]),
                  _vm._m(0),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("QuickMenuComponent"),
      _c("FooterComponent"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "where_noresult" }, [
      _c("div", { staticClass: "more-box contact" }, [
        _c("a", { attrs: { href: "support/contactus" } }, [
          _c("button", [_vm._v("Contact Us")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }