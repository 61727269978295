var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProductLayout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("ProductTopImage", {
              attrs: {
                title: "Lightweight Component",
                description:
                  "Eco friendly, fuel-efficient lightweight car components with high steel rigidity",
                descriptionWidth: "200px",
                imageSrc: "images/automotive/lightweight/top.png",
              },
            }),
            _c(
              "ProductContent",
              [
                [
                  _c("ProductSectionHeader", {
                    staticClass: "mt-80",
                    attrs: {
                      subTitle:
                        "We provide lightweight materials consisting of reinforced plastics, which maintain the strength of conventional steel and contribute to cut fuel consumption by reducing the weight of components.",
                      subTitleWidth: "850px",
                    },
                  }),
                  _c("ProductStrongGroup", {
                    staticClass: "mt-100",
                    attrs: {
                      iconType: "vertical",
                      info: [
                        {
                          iconSrc:
                            "images/automotive/lightweight/strong-point-icon-1.png",
                          title: "Lightweight",
                          description:
                            "Reducing weight by 25% against conventional steel",
                        },
                        {
                          iconSrc:
                            "images/automotive/lightweight/strong-point-icon-2.png",
                          title: "High strength",
                          description:
                            "Maintaining the strength of conventional steel",
                        },
                        {
                          iconSrc:
                            "images/automotive/lightweight/strong-point-icon-3.png",
                          title: "Cost reduction",
                          description:
                            "Reducing the number of parts through design optimization",
                        },
                      ],
                    },
                  }),
                  _c("LightweightComponent", { staticClass: "mt-100" }),
                  _c("LightweightMaterial", { staticClass: "mt-100" }),
                ],
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }